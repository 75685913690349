import React from "react";
import { useSelector } from "react-redux";
import store from "../../store/store";
import { updatePopups } from '../../store/actions/popupsActions';
import { IconClose } from "../../images/icons";

import ActivityPopup from "./ActivityPopup";
import LoginPopup from "./LoginPopup";
import LoginPopupSave from "./LoginPopupSave";
import CreateItineraryPopup from "./CreateItineraryPopup";
import LoadItineraryPopup from "./LoadItineraryPopup";
import SaveItineraryPopup from "./SaveItineraryPopup";
import SearchPopup from "./SearchPopup";

const Popup = (props) => {
	const popupType = useSelector((state) => state.popup.type);
	const popupData = useSelector((state) => state.popup.data);
	const popupTab = useSelector((state) => state.popup.tab);

	const togglePopup = () => {
		store.dispatch(updatePopups({ display: false, type: null, data: '' }));
	}
	
	switch (popupType) {
		case "login":
			return <LoginPopup closePopup={togglePopup} />;
		case "getting-started":
		case "activities":
			return <ActivityPopup tab={popupTab} title={popupData} closePopup={togglePopup} />;
		case "login-save":
			return <LoginPopupSave closePopup={togglePopup} />;
		case "create-itinerary":
			return <CreateItineraryPopup closePopup={togglePopup} toggleDashboard={props.toggleDashboard} />;
		case "load-itinerary":
			return <LoadItineraryPopup closePopup={togglePopup} toggleDashboard={props.toggleDashboard} data={popupData} />;
		case "save-itinerary":
			return <SaveItineraryPopup closePopup={togglePopup} />;
		case "search":
			return <SearchPopup closePopup={togglePopup} searchQuery={props.searchQuery} searchResults={props.searchResults} />;
		default:
			return (
				<>
					<div className="popup">
						<div className="popup-inner login-popup">
							<div className="popup-header">
								{props.header}
								<a onClick={togglePopup} className="popup-close">
									<IconClose />
								</a>
							</div>
							<div className="popup-content">{props.content}</div>
						</div>
					</div>
				</>
			);
	}
}

export default Popup;
