import React from "react";

function IconShare(props) {
    return (
        <>
            <svg width={props.width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                <path style={{ stroke: "#000" }} d="M13.74577,11.31222c-.93365,0-1.76619,.44492-2.29874,1.13252l-4.45929-2.49086c.10449-.29998,.16179-.62356,.16179-.95725,0-.33706-.0573-.65726-.16516-.96062l4.45592-2.48749c.52918,.69097,1.36509,1.13926,2.30211,1.13926,1.59766,0,2.90208-1.30105,2.90208-2.90208s-1.30105-2.90208-2.90208-2.90208-2.90208,1.30105-2.90208,2.90208c0,.33706,.0573,.66064,.16516,.96062l-4.45255,2.48749c-.52918-.69434-1.36509-1.13926-2.30211-1.13926-1.59766,0-2.90208,1.30105-2.90208,2.90208s1.30442,2.90208,2.90545,2.90208c.93702,0,1.77293-.44829,2.30548-1.14263l4.45592,2.49086c-.10786,.30335-.16853,.6303-.16853,.96736,0,1.59766,1.30105,2.90208,2.90208,2.90208s2.90208-1.30105,2.90208-2.90208-1.30442-2.90208-2.90545-2.90208Zm0-9.51517c1.09881,0,1.99202,.89321,1.99202,1.99202s-.89321,1.99202-1.99202,1.99202-1.99202-.89321-1.99202-1.99202,.89658-1.99202,1.99202-1.99202ZM4.25419,10.98865c-1.09881,0-1.99202-.89321-1.99202-1.99202s.89321-1.99202,1.99202-1.99202,1.99202,.89321,1.99202,1.99202-.89658,1.99202-1.99202,1.99202Zm9.49158,5.2143c-1.09881,0-1.99202-.89321-1.99202-1.99202s.89321-1.99202,1.99202-1.99202,1.99202,.89321,1.99202,1.99202-.89321,1.99202-1.99202,1.99202Z" />
            </svg>
        </>
    );
}

export default IconShare;
