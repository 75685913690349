import { createReducer } from "@reduxjs/toolkit";
import { startTutorial, endTutorial, updateStep } from "../actions/tutorialActions";
import { vitaDataLayer } from "../../helpers/dataLayer";

const initialState = {
  active: false,
  currentStep: 0
};

const tutorialReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(startTutorial, (state, action) => {
      state.active = true;
      state.currentStep = 0;
      vitaDataLayer('tutorial', 'start_tutorial', true);
    })
    .addCase(endTutorial, (state) => {
      state.active = false;
      state.currentStep = 0;
    })
    .addCase(updateStep, (state, action) => {
      state.currentStep = action.payload;
    });
});

export default tutorialReducer;