
const PonderosaPines = {
    type: "FeatureCollection",
    features: [{
        type: "Feature",
        properties: {
            name: "Ponderosa Pines Scenic Byway",
            "description": "Beginning in Boise, the Ponderosa Pine Scenic Byway meanders through historic Idaho City, up to Lowman and on to the mountain town of Stanley at the base of the Sawtooth Mountains. This route is packed with outdoor adventure and stunning natural scenery, following a portion of the South Fork of the Payette River and passing the Sawtooth wilderness, Boise, Salmon-Challis and Sawtooth National Forests.",
            "link": "https://visitidaho.org/things-to-do/road-trips/ponderosa-pine-scenic-byway/",
            route: [
                "Boise", 
                "Idaho City", 
                "Lowman",
                "Stanley"
            ],
            "shape": "Line"
        },
        geometry: {
            type: "LineString",
            coordinates: [
      [-116.1629, 43.54239],
      [-116.15067, 43.54059],
      [-116.12874, 43.54316],
      [-116.10609, 43.54337],
      [-116.09734, 43.54348],
      [
        -116.09543,
        43.54244
      ],
      [
        -116.09535,
        43.54237
      ],
      [
        -116.09483,
        43.54179
      ],
      [
        -116.09465,
        43.54151
      ],
      [
        -116.09438,
        43.54098
      ],
      [
        -116.09431,
        43.54088
      ],
      [
        -116.09393,
        43.54014
      ],
      [
        -116.09375,
        43.53982
      ],
      [
        -116.09366,
        43.53968
      ],
      [
        -116.09346,
        43.53941
      ],
      [
        -116.09345,
        43.53938
      ],
      [
        -116.09345,
        43.53936
      ],
      [
        -116.09343,
        43.53933
      ],
      [
        -116.09337,
        43.53927
      ],
      [
        -116.09281,
        43.53859
      ],
      [
        -116.09261,
        43.53838
      ],
      [
        -116.09222,
        43.538
      ],
      [
        -116.09174,
        43.53758
      ],
      [
        -116.09105,
        43.53707
      ],
      [
        -116.09066,
        43.53681
      ],
      [
        -116.09049,
        43.53672
      ],
      [
        -116.08954,
        43.53613
      ],
      [
        -116.08908,
        43.53587
      ],
      [
        -116.08892,
        43.53577
      ],
      [
        -116.08875,
        43.53568
      ],
      [
        -116.08827,
        43.53546
      ],
      [
        -116.0878,
        43.53527
      ],
      [
        -116.0869,
        43.53497
      ],
      [
        -116.08101,
        43.53318
      ],
      [
        -116.08052,
        43.53301
      ],
      [
        -116.08041,
        43.53296
      ],
      [
        -116.08002,
        43.53281
      ],
      [
        -116.07713,
        43.53154
      ],
      [
        -116.07666,
        43.53126
      ],
      [
        -116.07646,
        43.53112
      ],
      [
        -116.07644,
        43.5311
      ],
      [
        -116.07628,
        43.53099
      ],
      [
        -116.07592,
        43.53068
      ],
      [
        -116.0758,
        43.53056
      ],
      [
        -116.07542,
        43.53011
      ],
      [
        -116.07433,
        43.5285
      ],
      [
        -116.07423,
        43.52837
      ],
      [
        -116.07392,
        43.52802
      ],
      [
        -116.07365,
        43.52775
      ],
      [
        -116.07338,
        43.52751
      ],
      [
        -116.07297,
        43.52721
      ],
      [
        -116.07255,
        43.52694
      ],
      [
        -116.07208,
        43.52671
      ],
      [
        -116.07162,
        43.52651
      ],
      [
        -116.07123,
        43.52638
      ],
      [
        -116.07084,
        43.52627
      ],
      [
        -116.07015,
        43.52614
      ],
      [
        -116.06974,
        43.52609
      ],
      [
        -116.06918,
        43.52606
      ],
      [
        -116.06675,
        43.52605
      ],
      [
        -116.06597,
        43.52608
      ],
      [
        -116.06558,
        43.52613
      ],
      [
        -116.06512,
        43.52622
      ],
      [
        -116.06477,
        43.52631
      ],
      [
        -116.06448,
        43.5264
      ],
      [
        -116.06406,
        43.52656
      ],
      [
        -116.06386,
        43.52665
      ],
      [
        -116.06345,
        43.52687
      ],
      [
        -116.06323,
        43.52703
      ],
      [
        -116.06315,
        43.52708
      ],
      [
        -116.06277,
        43.5274
      ],
      [
        -116.06211,
        43.52804
      ],
      [
        -116.06205,
        43.52812
      ],
      [
        -116.06205,
        43.52815
      ],
      [
        -116.06174,
        43.52843
      ],
      [
        -116.06151,
        43.52866
      ],
      [
        -116.06127,
        43.52892
      ],
      [
        -116.06124,
        43.52897
      ],
      [
        -116.0612,
        43.52901
      ],
      [
        -116.06116,
        43.52907
      ],
      [
        -116.06112,
        43.52915
      ],
      [
        -116.06112,
        43.52916
      ],
      [
        -116.06108,
        43.52924
      ],
      [
        -116.06099,
        43.5296
      ],
      [
        -116.06095,
        43.52989
      ],
      [
        -116.06104,
        43.53111
      ],
      [
        -116.06103,
        43.53126
      ],
      [
        -116.06101,
        43.53138
      ],
      [
        -116.06096,
        43.53157
      ],
      [
        -116.06092,
        43.53167
      ],
      [
        -116.06069,
        43.53207
      ],
      [
        -116.06052,
        43.53226
      ],
      [
        -116.06037,
        43.53238
      ],
      [
        -116.06015,
        43.53252
      ],
      [
        -116.05996,
        43.53262
      ],
      [
        -116.05976,
        43.5327
      ],
      [
        -116.05936,
        43.53281
      ],
      [
        -116.05886,
        43.53288
      ],
      [
        -116.05873,
        43.53289
      ],
      [
        -116.0582,
        43.53288
      ],
      [
        -116.05509,
        43.53266
      ],
      [
        -116.05465,
        43.53258
      ],
      [
        -116.05444,
        43.53252
      ],
      [
        -116.05431,
        43.53247
      ],
      [
        -116.05421,
        43.53244
      ],
      [
        -116.05418,
        43.53242
      ],
      [
        -116.05411,
        43.5324
      ],
      [
        -116.05402,
        43.53235
      ],
      [
        -116.05377,
        43.53219
      ],
      [
        -116.05316,
        43.5316
      ],
      [
        -116.05297,
        43.53135
      ],
      [
        -116.05247,
        43.53081
      ],
      [
        -116.05175,
        43.53009
      ],
      [
        -116.05173,
        43.53008
      ],
      [
        -116.05163,
        43.52999
      ],
      [
        -116.05133,
        43.52976
      ],
      [
        -116.05103,
        43.52956
      ],
      [
        -116.05081,
        43.52948
      ],
      [
        -116.05038,
        43.52936
      ],
      [
        -116.04997,
        43.5293
      ],
      [
        -116.04959,
        43.52929
      ],
      [
        -116.04939,
        43.5293
      ],
      [
        -116.04902,
        43.52937
      ],
      [
        -116.04857,
        43.52951
      ],
      [
        -116.04842,
        43.52957
      ],
      [
        -116.04808,
        43.52974
      ],
      [
        -116.04794,
        43.52982
      ],
      [
        -116.04766,
        43.53002
      ],
      [
        -116.0475,
        43.53019
      ],
      [
        -116.04735,
        43.53041
      ],
      [
        -116.04726,
        43.53056
      ],
      [
        -116.0472,
        43.53073
      ],
      [
        -116.04716,
        43.53089
      ],
      [
        -116.04713,
        43.53111
      ],
      [
        -116.04713,
        43.53122
      ],
      [
        -116.04716,
        43.53144
      ],
      [
        -116.0472,
        43.5316
      ],
      [
        -116.04727,
        43.53176
      ],
      [
        -116.04749,
        43.53239
      ],
      [
        -116.04766,
        43.53283
      ],
      [
        -116.04775,
        43.53312
      ],
      [
        -116.0478,
        43.53335
      ],
      [
        -116.04781,
        43.53342
      ],
      [
        -116.04782,
        43.53357
      ],
      [
        -116.0478,
        43.53367
      ],
      [
        -116.0478,
        43.53371
      ],
      [
        -116.0474,
        43.53559
      ],
      [
        -116.04728,
        43.53608
      ],
      [
        -116.04702,
        43.53694
      ],
      [
        -116.04676,
        43.5382
      ],
      [
        -116.04632,
        43.53974
      ],
      [
        -116.04576,
        43.5422
      ],
      [
        -116.0457,
        43.54238
      ],
      [
        -116.04519,
        43.54432
      ],
      [
        -116.04484,
        43.54581
      ],
      [
        -116.04454,
        43.54696
      ],
      [
        -116.04408,
        43.54896
      ],
      [
        -116.04405,
        43.54917
      ],
      [
        -116.04403,
        43.54939
      ],
      [
        -116.04402,
        43.54977
      ],
      [
        -116.04404,
        43.55014
      ],
      [
        -116.0441,
        43.55066
      ],
      [
        -116.04457,
        43.55341
      ],
      [
        -116.04461,
        43.55402
      ],
      [
        -116.04457,
        43.55433
      ],
      [
        -116.04445,
        43.55476
      ],
      [
        -116.04425,
        43.55511
      ],
      [
        -116.04406,
        43.55537
      ],
      [
        -116.04388,
        43.55557
      ],
      [
        -116.0415,
        43.55776
      ],
      [
        -116.04111,
        43.55818
      ],
      [
        -116.04085,
        43.55849
      ],
      [
        -116.04009,
        43.55946
      ],
      [
        -116.03986,
        43.55979
      ],
      [
        -116.0394,
        43.56053
      ],
      [
        -116.03914,
        43.56099
      ],
      [
        -116.03898,
        43.5613
      ],
      [
        -116.0388,
        43.56169
      ],
      [
        -116.03858,
        43.56206
      ],
      [
        -116.03751,
        43.56423
      ],
      [
        -116.03732,
        43.56465
      ],
      [
        -116.03627,
        43.5668
      ],
      [
        -116.03615,
        43.56701
      ],
      [
        -116.03608,
        43.56715
      ],
      [
        -116.03596,
        43.56733
      ],
      [
        -116.03596,
        43.56734
      ],
      [
        -116.03569,
        43.56769
      ],
      [
        -116.03554,
        43.56786
      ],
      [
        -116.03521,
        43.56818
      ],
      [
        -116.03501,
        43.56835
      ],
      [
        -116.03483,
        43.56848
      ],
      [
        -116.03463,
        43.56861
      ],
      [
        -116.03409,
        43.56891
      ],
      [
        -116.03379,
        43.56905
      ],
      [
        -116.03178,
        43.56987
      ],
      [
        -116.03164,
        43.56992
      ],
      [
        -116.02921,
        43.57092
      ],
      [
        -116.02902,
        43.57107
      ],
      [
        -116.02828,
        43.57137
      ],
      [
        -116.02803,
        43.57145
      ],
      [
        -116.02678,
        43.57198
      ],
      [
        -116.02524,
        43.57258
      ],
      [
        -116.02463,
        43.57284
      ],
      [
        -116.02308,
        43.57344
      ],
      [
        -116.02262,
        43.57365
      ],
      [
        -116.02193,
        43.57388
      ],
      [
        -116.02127,
        43.57416
      ],
      [
        -116.02053,
        43.57443
      ],
      [
        -116.01608,
        43.57622
      ],
      [
        -116.0154,
        43.57647
      ],
      [
        -116.01475,
        43.57673
      ],
      [
        -116.01399,
        43.57701
      ],
      [
        -116.0127,
        43.57743
      ],
      [
        -116.01168,
        43.5777
      ],
      [
        -116.01125,
        43.57779
      ],
      [
        -116.0107,
        43.57793
      ],
      [
        -116.01038,
        43.57798
      ],
      [
        -116.00998,
        43.57807
      ],
      [
        -116.0093,
        43.5782
      ],
      [
        -116.00749,
        43.57842
      ],
      [
        -116.00583,
        43.57857
      ],
      [
        -116.00539,
        43.57859
      ],
      [
        -116.00079,
        43.57902
      ],
      [
        -116.00007,
        43.57912
      ],
      [
        -115.99984,
        43.57914
      ],
      [
        -115.99956,
        43.5792
      ],
      [
        -115.99937,
        43.57923
      ],
      [
        -115.99899,
        43.57932
      ],
      [
        -115.99849,
        43.57948
      ],
      [
        -115.9979,
        43.57974
      ],
      [
        -115.99783,
        43.57979
      ],
      [
        -115.99747,
        43.57997
      ],
      [
        -115.99722,
        43.58014
      ],
      [
        -115.99693,
        43.58036
      ],
      [
        -115.99675,
        43.58052
      ],
      [
        -115.99657,
        43.5807
      ],
      [
        -115.9963,
        43.581
      ],
      [
        -115.99622,
        43.58112
      ],
      [
        -115.99598,
        43.58138
      ],
      [
        -115.99582,
        43.58169
      ],
      [
        -115.9958,
        43.58176
      ],
      [
        -115.9957,
        43.58203
      ],
      [
        -115.99563,
        43.58226
      ],
      [
        -115.99563,
        43.58229
      ],
      [
        -115.99558,
        43.58251
      ],
      [
        -115.99556,
        43.58274
      ],
      [
        -115.99553,
        43.58491
      ],
      [
        -115.99554,
        43.5852
      ],
      [
        -115.99556,
        43.5854
      ],
      [
        -115.99562,
        43.58575
      ],
      [
        -115.99574,
        43.58616
      ],
      [
        -115.99592,
        43.58655
      ],
      [
        -115.99607,
        43.5868
      ],
      [
        -115.99627,
        43.58707
      ],
      [
        -115.99652,
        43.58736
      ],
      [
        -115.9967,
        43.58754
      ],
      [
        -115.99702,
        43.58783
      ],
      [
        -115.9978,
        43.58849
      ],
      [
        -115.99798,
        43.58863
      ],
      [
        -115.99848,
        43.58907
      ],
      [
        -115.99859,
        43.58918
      ],
      [
        -115.99864,
        43.58924
      ],
      [
        -115.9987,
        43.5893
      ],
      [
        -115.99878,
        43.5894
      ],
      [
        -115.9989,
        43.58958
      ],
      [
        -115.99896,
        43.5897
      ],
      [
        -115.99906,
        43.59001
      ],
      [
        -115.99907,
        43.59009
      ],
      [
        -115.99907,
        43.59015
      ],
      [
        -115.99908,
        43.59021
      ],
      [
        -115.99908,
        43.59029
      ],
      [
        -115.99904,
        43.59057
      ],
      [
        -115.99904,
        43.59059
      ],
      [
        -115.99895,
        43.59087
      ],
      [
        -115.99855,
        43.59193
      ],
      [
        -115.99829,
        43.59247
      ],
      [
        -115.99828,
        43.5925
      ],
      [
        -115.99791,
        43.59326
      ],
      [
        -115.99747,
        43.59398
      ],
      [
        -115.99724,
        43.59424
      ],
      [
        -115.99699,
        43.59447
      ],
      [
        -115.99607,
        43.59524
      ],
      [
        -115.99557,
        43.59569
      ],
      [
        -115.99488,
        43.59627
      ],
      [
        -115.99467,
        43.5965
      ],
      [
        -115.99454,
        43.59667
      ],
      [
        -115.99439,
        43.59691
      ],
      [
        -115.99396,
        43.59784
      ],
      [
        -115.9938,
        43.5981
      ],
      [
        -115.99372,
        43.5982
      ],
      [
        -115.99362,
        43.59831
      ],
      [
        -115.99349,
        43.59843
      ],
      [
        -115.99336,
        43.59852
      ],
      [
        -115.9931,
        43.59868
      ],
      [
        -115.99297,
        43.59875
      ],
      [
        -115.99283,
        43.59881
      ],
      [
        -115.99251,
        43.59892
      ],
      [
        -115.9904,
        43.5995
      ],
      [
        -115.98985,
        43.59967
      ],
      [
        -115.98966,
        43.59975
      ],
      [
        -115.98965,
        43.59976
      ],
      [
        -115.9894,
        43.59988
      ],
      [
        -115.98915,
        43.60002
      ],
      [
        -115.98895,
        43.60016
      ],
      [
        -115.98871,
        43.60036
      ],
      [
        -115.98852,
        43.60056
      ],
      [
        -115.98833,
        43.6008
      ],
      [
        -115.98819,
        43.60102
      ],
      [
        -115.98804,
        43.60132
      ],
      [
        -115.98798,
        43.60154
      ],
      [
        -115.98793,
        43.6018
      ],
      [
        -115.98784,
        43.60248
      ],
      [
        -115.98782,
        43.60284
      ],
      [
        -115.98782,
        43.60314
      ],
      [
        -115.98785,
        43.60326
      ],
      [
        -115.98787,
        43.60341
      ],
      [
        -115.98795,
        43.6036
      ],
      [
        -115.98805,
        43.60378
      ],
      [
        -115.98817,
        43.60393
      ],
      [
        -115.98841,
        43.60416
      ],
      [
        -115.98853,
        43.60426
      ],
      [
        -115.98875,
        43.60441
      ],
      [
        -115.9891,
        43.6046
      ],
      [
        -115.98984,
        43.60494
      ],
      [
        -115.99021,
        43.60515
      ],
      [
        -115.99041,
        43.60528
      ],
      [
        -115.99073,
        43.60561
      ],
      [
        -115.99087,
        43.60585
      ],
      [
        -115.99094,
        43.60604
      ],
      [
        -115.99098,
        43.60621
      ],
      [
        -115.99099,
        43.60629
      ],
      [
        -115.991,
        43.60632
      ],
      [
        -115.991,
        43.6066
      ],
      [
        -115.99095,
        43.60683
      ],
      [
        -115.99086,
        43.60704
      ],
      [
        -115.99075,
        43.60724
      ],
      [
        -115.99068,
        43.60734
      ],
      [
        -115.9906,
        43.60742
      ],
      [
        -115.99055,
        43.60748
      ],
      [
        -115.99052,
        43.6075
      ],
      [
        -115.9903,
        43.6077
      ],
      [
        -115.99014,
        43.60782
      ],
      [
        -115.9899,
        43.60796
      ],
      [
        -115.98936,
        43.60821
      ],
      [
        -115.98902,
        43.60835
      ],
      [
        -115.98843,
        43.60862
      ],
      [
        -115.98806,
        43.60882
      ],
      [
        -115.98759,
        43.60905
      ],
      [
        -115.98756,
        43.60906
      ],
      [
        -115.98698,
        43.60939
      ],
      [
        -115.98682,
        43.60954
      ],
      [
        -115.98678,
        43.60956
      ],
      [
        -115.98665,
        43.6097
      ],
      [
        -115.98657,
        43.6098
      ],
      [
        -115.98649,
        43.60992
      ],
      [
        -115.98636,
        43.61019
      ],
      [
        -115.98631,
        43.61031
      ],
      [
        -115.98628,
        43.6105
      ],
      [
        -115.98627,
        43.6107
      ],
      [
        -115.98629,
        43.61087
      ],
      [
        -115.98632,
        43.61103
      ],
      [
        -115.98652,
        43.6118
      ],
      [
        -115.98665,
        43.61223
      ],
      [
        -115.98679,
        43.61251
      ],
      [
        -115.98709,
        43.61296
      ],
      [
        -115.98724,
        43.61316
      ],
      [
        -115.98741,
        43.61335
      ],
      [
        -115.9878,
        43.61373
      ],
      [
        -115.98809,
        43.61395
      ],
      [
        -115.98851,
        43.61423
      ],
      [
        -115.98873,
        43.61436
      ],
      [
        -115.9894,
        43.61472
      ],
      [
        -115.99024,
        43.6152
      ],
      [
        -115.99027,
        43.61521
      ],
      [
        -115.9906,
        43.61543
      ],
      [
        -115.99124,
        43.61593
      ],
      [
        -115.99154,
        43.61619
      ],
      [
        -115.99172,
        43.6164
      ],
      [
        -115.99177,
        43.61645
      ],
      [
        -115.99214,
        43.61691
      ],
      [
        -115.99243,
        43.61735
      ],
      [
        -115.99274,
        43.61796
      ],
      [
        -115.99296,
        43.6186
      ],
      [
        -115.99298,
        43.6187
      ],
      [
        -115.99298,
        43.61873
      ],
      [
        -115.99307,
        43.61944
      ],
      [
        -115.99308,
        43.61968
      ],
      [
        -115.99305,
        43.62011
      ],
      [
        -115.99299,
        43.62058
      ],
      [
        -115.99295,
        43.62076
      ],
      [
        -115.99285,
        43.62107
      ],
      [
        -115.99265,
        43.62155
      ],
      [
        -115.99243,
        43.62197
      ],
      [
        -115.99223,
        43.62229
      ],
      [
        -115.99209,
        43.62248
      ],
      [
        -115.99191,
        43.6227
      ],
      [
        -115.9917,
        43.62293
      ],
      [
        -115.99133,
        43.62329
      ],
      [
        -115.99129,
        43.62332
      ],
      [
        -115.99108,
        43.62351
      ],
      [
        -115.98995,
        43.62442
      ],
      [
        -115.98992,
        43.62444
      ],
      [
        -115.989,
        43.6252
      ],
      [
        -115.98856,
        43.62559
      ],
      [
        -115.98823,
        43.62593
      ],
      [
        -115.98796,
        43.62627
      ],
      [
        -115.98786,
        43.62642
      ],
      [
        -115.98772,
        43.62666
      ],
      [
        -115.98765,
        43.62681
      ],
      [
        -115.98752,
        43.62717
      ],
      [
        -115.98745,
        43.62745
      ],
      [
        -115.9874,
        43.62781
      ],
      [
        -115.98738,
        43.62806
      ],
      [
        -115.98739,
        43.62919
      ],
      [
        -115.98747,
        43.63095
      ],
      [
        -115.98789,
        43.64444
      ],
      [
        -115.98789,
        43.6456
      ],
      [
        -115.98787,
        43.64573
      ],
      [
        -115.98787,
        43.64578
      ],
      [
        -115.98784,
        43.64593
      ],
      [
        -115.98784,
        43.64594
      ],
      [
        -115.98779,
        43.64611
      ],
      [
        -115.98779,
        43.64612
      ],
      [
        -115.98767,
        43.64645
      ],
      [
        -115.98766,
        43.64646
      ],
      [
        -115.98764,
        43.64651
      ],
      [
        -115.98752,
        43.64674
      ],
      [
        -115.98743,
        43.64687
      ],
      [
        -115.98739,
        43.64694
      ],
      [
        -115.98727,
        43.6471
      ],
      [
        -115.9872,
        43.64717
      ],
      [
        -115.98698,
        43.64743
      ],
      [
        -115.98678,
        43.64762
      ],
      [
        -115.98638,
        43.64793
      ],
      [
        -115.9853,
        43.64863
      ],
      [
        -115.98512,
        43.64876
      ],
      [
        -115.9849,
        43.64895
      ],
      [
        -115.98475,
        43.6491
      ],
      [
        -115.9847,
        43.64914
      ],
      [
        -115.98374,
        43.65021
      ],
      [
        -115.98368,
        43.65023
      ],
      [
        -115.98338,
        43.65049
      ],
      [
        -115.98323,
        43.65059
      ],
      [
        -115.98288,
        43.65078
      ],
      [
        -115.98233,
        43.65094
      ],
      [
        -115.98127,
        43.65107
      ],
      [
        -115.98074,
        43.65117
      ],
      [
        -115.98068,
        43.65121
      ],
      [
        -115.98036,
        43.65133
      ],
      [
        -115.98009,
        43.65147
      ],
      [
        -115.97982,
        43.65167
      ],
      [
        -115.97965,
        43.65184
      ],
      [
        -115.97962,
        43.65189
      ],
      [
        -115.97954,
        43.652
      ],
      [
        -115.97945,
        43.65217
      ],
      [
        -115.97937,
        43.6524
      ],
      [
        -115.97934,
        43.65255
      ],
      [
        -115.97934,
        43.65258
      ],
      [
        -115.97933,
        43.65266
      ],
      [
        -115.97934,
        43.65293
      ],
      [
        -115.9794,
        43.65339
      ],
      [
        -115.97941,
        43.65355
      ],
      [
        -115.97941,
        43.65369
      ],
      [
        -115.97939,
        43.65383
      ],
      [
        -115.97937,
        43.65392
      ],
      [
        -115.97933,
        43.65401
      ],
      [
        -115.97933,
        43.65403
      ],
      [
        -115.97928,
        43.65415
      ],
      [
        -115.97922,
        43.65425
      ],
      [
        -115.97903,
        43.6545
      ],
      [
        -115.97856,
        43.65506
      ],
      [
        -115.9784,
        43.65528
      ],
      [
        -115.97839,
        43.65529
      ],
      [
        -115.97829,
        43.65547
      ],
      [
        -115.97818,
        43.65571
      ],
      [
        -115.97811,
        43.65594
      ],
      [
        -115.9781,
        43.65596
      ],
      [
        -115.97806,
        43.65613
      ],
      [
        -115.97802,
        43.65658
      ],
      [
        -115.97804,
        43.6568
      ],
      [
        -115.97807,
        43.65697
      ],
      [
        -115.97812,
        43.65715
      ],
      [
        -115.97822,
        43.6574
      ],
      [
        -115.97843,
        43.65776
      ],
      [
        -115.97855,
        43.65794
      ],
      [
        -115.97931,
        43.65898
      ],
      [
        -115.97942,
        43.65923
      ],
      [
        -115.97946,
        43.65937
      ],
      [
        -115.97947,
        43.6595
      ],
      [
        -115.97947,
        43.65966
      ],
      [
        -115.97941,
        43.65991
      ],
      [
        -115.97935,
        43.66005
      ],
      [
        -115.97926,
        43.66021
      ],
      [
        -115.97904,
        43.66046
      ],
      [
        -115.97832,
        43.66102
      ],
      [
        -115.97774,
        43.66151
      ],
      [
        -115.9776,
        43.66164
      ],
      [
        -115.97743,
        43.66185
      ],
      [
        -115.97732,
        43.66203
      ],
      [
        -115.97727,
        43.66213
      ],
      [
        -115.97722,
        43.6623
      ],
      [
        -115.9772,
        43.66239
      ],
      [
        -115.97719,
        43.6625
      ],
      [
        -115.9772,
        43.66264
      ],
      [
        -115.97722,
        43.66276
      ],
      [
        -115.97725,
        43.66288
      ],
      [
        -115.97732,
        43.66306
      ],
      [
        -115.97739,
        43.66319
      ],
      [
        -115.97747,
        43.66331
      ],
      [
        -115.97758,
        43.66345
      ],
      [
        -115.97773,
        43.66361
      ],
      [
        -115.97838,
        43.66423
      ],
      [
        -115.97894,
        43.66481
      ],
      [
        -115.97914,
        43.66507
      ],
      [
        -115.97918,
        43.66513
      ],
      [
        -115.97936,
        43.66547
      ],
      [
        -115.97948,
        43.66584
      ],
      [
        -115.97951,
        43.66605
      ],
      [
        -115.9795,
        43.66667
      ],
      [
        -115.97945,
        43.66773
      ],
      [
        -115.97947,
        43.66797
      ],
      [
        -115.97953,
        43.66826
      ],
      [
        -115.97959,
        43.66844
      ],
      [
        -115.97965,
        43.66857
      ],
      [
        -115.97975,
        43.66874
      ],
      [
        -115.97986,
        43.6689
      ],
      [
        -115.98007,
        43.66915
      ],
      [
        -115.98034,
        43.66958
      ],
      [
        -115.98054,
        43.66982
      ],
      [
        -115.98073,
        43.67009
      ],
      [
        -115.98082,
        43.67027
      ],
      [
        -115.98092,
        43.67055
      ],
      [
        -115.98098,
        43.67086
      ],
      [
        -115.981,
        43.67102
      ],
      [
        -115.98098,
        43.67124
      ],
      [
        -115.98089,
        43.67161
      ],
      [
        -115.98066,
        43.67224
      ],
      [
        -115.98029,
        43.67317
      ],
      [
        -115.98023,
        43.67335
      ],
      [
        -115.98022,
        43.67335
      ],
      [
        -115.97924,
        43.67586
      ],
      [
        -115.97916,
        43.67611
      ],
      [
        -115.97905,
        43.67634
      ],
      [
        -115.97898,
        43.67643
      ],
      [
        -115.97872,
        43.67666
      ],
      [
        -115.97865,
        43.67671
      ],
      [
        -115.97858,
        43.67674
      ],
      [
        -115.9785,
        43.67679
      ],
      [
        -115.97826,
        43.67689
      ],
      [
        -115.97724,
        43.67727
      ],
      [
        -115.97693,
        43.67741
      ],
      [
        -115.97558,
        43.67812
      ],
      [
        -115.97545,
        43.67817
      ],
      [
        -115.97518,
        43.67825
      ],
      [
        -115.9748,
        43.67831
      ],
      [
        -115.97469,
        43.67832
      ],
      [
        -115.97432,
        43.67832
      ],
      [
        -115.97403,
        43.67828
      ],
      [
        -115.97387,
        43.67824
      ],
      [
        -115.97355,
        43.67813
      ],
      [
        -115.97336,
        43.67804
      ],
      [
        -115.97211,
        43.67727
      ],
      [
        -115.972,
        43.67721
      ],
      [
        -115.97189,
        43.67716
      ],
      [
        -115.97171,
        43.6771
      ],
      [
        -115.97153,
        43.67707
      ],
      [
        -115.97147,
        43.67705
      ],
      [
        -115.97125,
        43.67702
      ],
      [
        -115.97089,
        43.67702
      ],
      [
        -115.97072,
        43.67703
      ],
      [
        -115.97055,
        43.67705
      ],
      [
        -115.97012,
        43.67713
      ],
      [
        -115.96988,
        43.67719
      ],
      [
        -115.96948,
        43.67731
      ],
      [
        -115.96923,
        43.67741
      ],
      [
        -115.96869,
        43.6777
      ],
      [
        -115.96837,
        43.67795
      ],
      [
        -115.96816,
        43.67815
      ],
      [
        -115.96808,
        43.67824
      ],
      [
        -115.96796,
        43.6784
      ],
      [
        -115.96789,
        43.67854
      ],
      [
        -115.96785,
        43.67869
      ],
      [
        -115.96783,
        43.67882
      ],
      [
        -115.96783,
        43.67894
      ],
      [
        -115.96786,
        43.6791
      ],
      [
        -115.96813,
        43.67987
      ],
      [
        -115.96817,
        43.68006
      ],
      [
        -115.96819,
        43.68026
      ],
      [
        -115.96819,
        43.68043
      ],
      [
        -115.96817,
        43.68068
      ],
      [
        -115.96812,
        43.68106
      ],
      [
        -115.96809,
        43.68122
      ],
      [
        -115.96808,
        43.68131
      ],
      [
        -115.96806,
        43.68139
      ],
      [
        -115.96805,
        43.68141
      ],
      [
        -115.96797,
        43.68172
      ],
      [
        -115.96792,
        43.68184
      ],
      [
        -115.9677,
        43.68221
      ],
      [
        -115.96763,
        43.6823
      ],
      [
        -115.96742,
        43.68253
      ],
      [
        -115.96723,
        43.6827
      ],
      [
        -115.96709,
        43.68281
      ],
      [
        -115.96667,
        43.68307
      ],
      [
        -115.96651,
        43.68313
      ],
      [
        -115.96571,
        43.68348
      ],
      [
        -115.96562,
        43.68353
      ],
      [
        -115.96518,
        43.68374
      ],
      [
        -115.96491,
        43.68389
      ],
      [
        -115.96457,
        43.68411
      ],
      [
        -115.96428,
        43.68433
      ],
      [
        -115.96274,
        43.68565
      ],
      [
        -115.96241,
        43.6859
      ],
      [
        -115.96213,
        43.68607
      ],
      [
        -115.9618,
        43.68624
      ],
      [
        -115.96156,
        43.68634
      ],
      [
        -115.9611,
        43.68649
      ],
      [
        -115.95994,
        43.68675
      ],
      [
        -115.95968,
        43.68685
      ],
      [
        -115.95964,
        43.68686
      ],
      [
        -115.95945,
        43.68696
      ],
      [
        -115.95934,
        43.68704
      ],
      [
        -115.95926,
        43.68709
      ],
      [
        -115.95906,
        43.68731
      ],
      [
        -115.959,
        43.6874
      ],
      [
        -115.95894,
        43.68752
      ],
      [
        -115.9589,
        43.68762
      ],
      [
        -115.95888,
        43.68772
      ],
      [
        -115.95882,
        43.68792
      ],
      [
        -115.9588,
        43.68796
      ],
      [
        -115.95848,
        43.689
      ],
      [
        -115.95839,
        43.68925
      ],
      [
        -115.95832,
        43.6895
      ],
      [
        -115.95828,
        43.68961
      ],
      [
        -115.95827,
        43.68967
      ],
      [
        -115.95822,
        43.68985
      ],
      [
        -115.95817,
        43.69022
      ],
      [
        -115.95817,
        43.69038
      ],
      [
        -115.9582,
        43.69066
      ],
      [
        -115.95822,
        43.69075
      ],
      [
        -115.95824,
        43.6908
      ],
      [
        -115.95826,
        43.69089
      ],
      [
        -115.95834,
        43.69108
      ],
      [
        -115.95845,
        43.69127
      ],
      [
        -115.95889,
        43.69185
      ],
      [
        -115.95911,
        43.69212
      ],
      [
        -115.95928,
        43.69236
      ],
      [
        -115.9593,
        43.6924
      ],
      [
        -115.95941,
        43.69256
      ],
      [
        -115.95949,
        43.69271
      ],
      [
        -115.95957,
        43.69289
      ],
      [
        -115.95964,
        43.69312
      ],
      [
        -115.95969,
        43.69336
      ],
      [
        -115.9597,
        43.69339
      ],
      [
        -115.95973,
        43.69369
      ],
      [
        -115.95973,
        43.6938
      ],
      [
        -115.95972,
        43.69395
      ],
      [
        -115.95968,
        43.69422
      ],
      [
        -115.95961,
        43.69452
      ],
      [
        -115.95959,
        43.69464
      ],
      [
        -115.95909,
        43.69674
      ],
      [
        -115.95905,
        43.697
      ],
      [
        -115.95902,
        43.69731
      ],
      [
        -115.95902,
        43.69738
      ],
      [
        -115.95914,
        43.69823
      ],
      [
        -115.95916,
        43.69855
      ],
      [
        -115.95913,
        43.69879
      ],
      [
        -115.95906,
        43.69896
      ],
      [
        -115.95899,
        43.69909
      ],
      [
        -115.9588,
        43.69929
      ],
      [
        -115.95848,
        43.69951
      ],
      [
        -115.95749,
        43.70003
      ],
      [
        -115.95731,
        43.70016
      ],
      [
        -115.95718,
        43.70028
      ],
      [
        -115.95707,
        43.7004
      ],
      [
        -115.95705,
        43.70044
      ],
      [
        -115.957,
        43.70051
      ],
      [
        -115.95695,
        43.70061
      ],
      [
        -115.9569,
        43.70075
      ],
      [
        -115.95686,
        43.70104
      ],
      [
        -115.95692,
        43.70157
      ],
      [
        -115.95695,
        43.70175
      ],
      [
        -115.95696,
        43.7019
      ],
      [
        -115.95695,
        43.70204
      ],
      [
        -115.95694,
        43.70206
      ],
      [
        -115.9569,
        43.70225
      ],
      [
        -115.95679,
        43.70251
      ],
      [
        -115.95655,
        43.70297
      ],
      [
        -115.95645,
        43.70319
      ],
      [
        -115.95642,
        43.70332
      ],
      [
        -115.9564,
        43.70345
      ],
      [
        -115.95639,
        43.70358
      ],
      [
        -115.9564,
        43.70379
      ],
      [
        -115.95645,
        43.70412
      ],
      [
        -115.95647,
        43.70452
      ],
      [
        -115.95644,
        43.70474
      ],
      [
        -115.95637,
        43.70491
      ],
      [
        -115.95624,
        43.70512
      ],
      [
        -115.95608,
        43.70531
      ],
      [
        -115.95592,
        43.70545
      ],
      [
        -115.95576,
        43.70556
      ],
      [
        -115.95542,
        43.70573
      ],
      [
        -115.95516,
        43.7058
      ],
      [
        -115.95485,
        43.70586
      ],
      [
        -115.95447,
        43.70588
      ],
      [
        -115.94967,
        43.70579
      ],
      [
        -115.94927,
        43.70585
      ],
      [
        -115.94909,
        43.70591
      ],
      [
        -115.94873,
        43.70607
      ],
      [
        -115.94855,
        43.70619
      ],
      [
        -115.94838,
        43.70634
      ],
      [
        -115.94825,
        43.70649
      ],
      [
        -115.94816,
        43.70664
      ],
      [
        -115.94809,
        43.70688
      ],
      [
        -115.94807,
        43.70703
      ],
      [
        -115.94808,
        43.70719
      ],
      [
        -115.94812,
        43.70738
      ],
      [
        -115.94817,
        43.70754
      ],
      [
        -115.94828,
        43.70772
      ],
      [
        -115.94862,
        43.70814
      ],
      [
        -115.94873,
        43.70829
      ],
      [
        -115.94891,
        43.70861
      ],
      [
        -115.94898,
        43.70877
      ],
      [
        -115.94917,
        43.70953
      ],
      [
        -115.9493,
        43.70995
      ],
      [
        -115.94949,
        43.71043
      ],
      [
        -115.94971,
        43.71088
      ],
      [
        -115.94975,
        43.71099
      ],
      [
        -115.94983,
        43.71117
      ],
      [
        -115.94987,
        43.71133
      ],
      [
        -115.94989,
        43.71151
      ],
      [
        -115.94988,
        43.71169
      ],
      [
        -115.94984,
        43.7119
      ],
      [
        -115.94978,
        43.71213
      ],
      [
        -115.9497,
        43.71233
      ],
      [
        -115.94962,
        43.71258
      ],
      [
        -115.94958,
        43.71275
      ],
      [
        -115.94957,
        43.71293
      ],
      [
        -115.94957,
        43.7131
      ],
      [
        -115.94961,
        43.71336
      ],
      [
        -115.94986,
        43.7142
      ],
      [
        -115.94992,
        43.71445
      ],
      [
        -115.95002,
        43.71497
      ],
      [
        -115.95009,
        43.71559
      ],
      [
        -115.95014,
        43.71585
      ],
      [
        -115.95022,
        43.71608
      ],
      [
        -115.95036,
        43.71632
      ],
      [
        -115.95046,
        43.71645
      ],
      [
        -115.95054,
        43.71654
      ],
      [
        -115.95072,
        43.7167
      ],
      [
        -115.95087,
        43.71682
      ],
      [
        -115.95119,
        43.71701
      ],
      [
        -115.95159,
        43.71718
      ],
      [
        -115.95211,
        43.71738
      ],
      [
        -115.95314,
        43.71772
      ],
      [
        -115.95353,
        43.71782
      ],
      [
        -115.95386,
        43.71788
      ],
      [
        -115.95462,
        43.71799
      ],
      [
        -115.95507,
        43.71813
      ],
      [
        -115.95523,
        43.71821
      ],
      [
        -115.95544,
        43.71834
      ],
      [
        -115.9556,
        43.71847
      ],
      [
        -115.95577,
        43.71865
      ],
      [
        -115.95589,
        43.71882
      ],
      [
        -115.95608,
        43.71917
      ],
      [
        -115.95648,
        43.72001
      ],
      [
        -115.95658,
        43.72025
      ],
      [
        -115.95667,
        43.72053
      ],
      [
        -115.95672,
        43.7208
      ],
      [
        -115.95676,
        43.72126
      ],
      [
        -115.95677,
        43.72214
      ],
      [
        -115.95675,
        43.72244
      ],
      [
        -115.95672,
        43.72264
      ],
      [
        -115.95662,
        43.72288
      ],
      [
        -115.95651,
        43.72308
      ],
      [
        -115.95637,
        43.72327
      ],
      [
        -115.95616,
        43.72347
      ],
      [
        -115.95547,
        43.72399
      ],
      [
        -115.95519,
        43.72418
      ],
      [
        -115.95472,
        43.72446
      ],
      [
        -115.95459,
        43.72452
      ],
      [
        -115.95349,
        43.7251
      ],
      [
        -115.95282,
        43.7254
      ],
      [
        -115.95256,
        43.72549
      ],
      [
        -115.95226,
        43.72556
      ],
      [
        -115.95194,
        43.7256
      ],
      [
        -115.95071,
        43.72562
      ],
      [
        -115.94959,
        43.72567
      ],
      [
        -115.94953,
        43.72568
      ],
      [
        -115.94933,
        43.72569
      ],
      [
        -115.94855,
        43.72579
      ],
      [
        -115.94831,
        43.72584
      ],
      [
        -115.94809,
        43.72587
      ],
      [
        -115.94699,
        43.72612
      ],
      [
        -115.94658,
        43.72627
      ],
      [
        -115.94636,
        43.72637
      ],
      [
        -115.94614,
        43.72651
      ],
      [
        -115.94588,
        43.7267
      ],
      [
        -115.94572,
        43.72684
      ],
      [
        -115.94571,
        43.72684
      ],
      [
        -115.94549,
        43.72706
      ],
      [
        -115.94534,
        43.72723
      ],
      [
        -115.94516,
        43.72749
      ],
      [
        -115.94501,
        43.72779
      ],
      [
        -115.94495,
        43.72796
      ],
      [
        -115.94483,
        43.72851
      ],
      [
        -115.94478,
        43.72867
      ],
      [
        -115.94469,
        43.72889
      ],
      [
        -115.9446,
        43.72902
      ],
      [
        -115.94452,
        43.72912
      ],
      [
        -115.94443,
        43.7292
      ],
      [
        -115.94433,
        43.72927
      ],
      [
        -115.9443,
        43.72928
      ],
      [
        -115.94415,
        43.72936
      ],
      [
        -115.94393,
        43.72942
      ],
      [
        -115.94364,
        43.72947
      ],
      [
        -115.94217,
        43.72956
      ],
      [
        -115.94161,
        43.72955
      ],
      [
        -115.94138,
        43.72953
      ],
      [
        -115.94113,
        43.72952
      ],
      [
        -115.94054,
        43.72946
      ],
      [
        -115.94041,
        43.72946
      ],
      [
        -115.94012,
        43.72949
      ],
      [
        -115.93998,
        43.72952
      ],
      [
        -115.93985,
        43.72956
      ],
      [
        -115.93957,
        43.72971
      ],
      [
        -115.93945,
        43.72981
      ],
      [
        -115.93936,
        43.7299
      ],
      [
        -115.93935,
        43.72992
      ],
      [
        -115.93926,
        43.73005
      ],
      [
        -115.93922,
        43.73019
      ],
      [
        -115.9392,
        43.73031
      ],
      [
        -115.9392,
        43.73042
      ],
      [
        -115.93963,
        43.73358
      ],
      [
        -115.93965,
        43.73383
      ],
      [
        -115.93965,
        43.73401
      ],
      [
        -115.93962,
        43.73417
      ],
      [
        -115.93957,
        43.73432
      ],
      [
        -115.93949,
        43.73448
      ],
      [
        -115.93928,
        43.73475
      ],
      [
        -115.93917,
        43.73487
      ],
      [
        -115.93885,
        43.73514
      ],
      [
        -115.93825,
        43.73552
      ],
      [
        -115.93801,
        43.73565
      ],
      [
        -115.93781,
        43.73574
      ],
      [
        -115.93759,
        43.73581
      ],
      [
        -115.9373,
        43.73586
      ],
      [
        -115.93713,
        43.73587
      ],
      [
        -115.93693,
        43.73587
      ],
      [
        -115.93667,
        43.73585
      ],
      [
        -115.93637,
        43.7358
      ],
      [
        -115.9357,
        43.73566
      ],
      [
        -115.9354,
        43.73562
      ],
      [
        -115.93515,
        43.7356
      ],
      [
        -115.93485,
        43.73559
      ],
      [
        -115.93426,
        43.73562
      ],
      [
        -115.93405,
        43.73565
      ],
      [
        -115.93367,
        43.73574
      ],
      [
        -115.93344,
        43.73582
      ],
      [
        -115.93333,
        43.73588
      ],
      [
        -115.93332,
        43.73588
      ],
      [
        -115.9332,
        43.73595
      ],
      [
        -115.93294,
        43.73613
      ],
      [
        -115.93282,
        43.73623
      ],
      [
        -115.93272,
        43.73633
      ],
      [
        -115.93254,
        43.73655
      ],
      [
        -115.93244,
        43.73671
      ],
      [
        -115.93231,
        43.73696
      ],
      [
        -115.9322,
        43.73713
      ],
      [
        -115.93171,
        43.73804
      ],
      [
        -115.93146,
        43.73855
      ],
      [
        -115.93104,
        43.73951
      ],
      [
        -115.93084,
        43.73988
      ],
      [
        -115.93076,
        43.74
      ],
      [
        -115.9305,
        43.74031
      ],
      [
        -115.93036,
        43.74045
      ],
      [
        -115.93018,
        43.74061
      ],
      [
        -115.92997,
        43.74078
      ],
      [
        -115.92952,
        43.74109
      ],
      [
        -115.9291,
        43.74132
      ],
      [
        -115.92851,
        43.74162
      ],
      [
        -115.928,
        43.7419
      ],
      [
        -115.92562,
        43.7431
      ],
      [
        -115.92543,
        43.74321
      ],
      [
        -115.92514,
        43.7434
      ],
      [
        -115.92507,
        43.74346
      ],
      [
        -115.92488,
        43.74366
      ],
      [
        -115.92477,
        43.74384
      ],
      [
        -115.9247,
        43.74398
      ],
      [
        -115.92429,
        43.74495
      ],
      [
        -115.9242,
        43.74514
      ],
      [
        -115.92418,
        43.7452
      ],
      [
        -115.92414,
        43.74527
      ],
      [
        -115.92397,
        43.74562
      ],
      [
        -115.9237,
        43.74604
      ],
      [
        -115.92337,
        43.74646
      ],
      [
        -115.92329,
        43.74655
      ],
      [
        -115.92318,
        43.7467
      ],
      [
        -115.92285,
        43.74707
      ],
      [
        -115.92186,
        43.74808
      ],
      [
        -115.92111,
        43.74897
      ],
      [
        -115.92051,
        43.74976
      ],
      [
        -115.92001,
        43.75053
      ],
      [
        -115.91976,
        43.75098
      ],
      [
        -115.91972,
        43.75108
      ],
      [
        -115.91964,
        43.75136
      ],
      [
        -115.91962,
        43.7515
      ],
      [
        -115.91963,
        43.75177
      ],
      [
        -115.91964,
        43.7518
      ],
      [
        -115.91966,
        43.75192
      ],
      [
        -115.91977,
        43.75238
      ],
      [
        -115.91979,
        43.75251
      ],
      [
        -115.91981,
        43.75276
      ],
      [
        -115.9198,
        43.75291
      ],
      [
        -115.91976,
        43.75311
      ],
      [
        -115.91969,
        43.75331
      ],
      [
        -115.91966,
        43.75338
      ],
      [
        -115.91962,
        43.75344
      ],
      [
        -115.91953,
        43.7536
      ],
      [
        -115.91927,
        43.75398
      ],
      [
        -115.91914,
        43.75415
      ],
      [
        -115.91903,
        43.75433
      ],
      [
        -115.91893,
        43.75459
      ],
      [
        -115.91892,
        43.7546
      ],
      [
        -115.91888,
        43.75486
      ],
      [
        -115.91889,
        43.75499
      ],
      [
        -115.91891,
        43.75512
      ],
      [
        -115.91898,
        43.75534
      ],
      [
        -115.91901,
        43.75548
      ],
      [
        -115.91919,
        43.75609
      ],
      [
        -115.91932,
        43.75668
      ],
      [
        -115.91935,
        43.75691
      ],
      [
        -115.91937,
        43.75717
      ],
      [
        -115.91937,
        43.75779
      ],
      [
        -115.91936,
        43.75804
      ],
      [
        -115.91925,
        43.75875
      ],
      [
        -115.91894,
        43.75989
      ],
      [
        -115.91881,
        43.76027
      ],
      [
        -115.91876,
        43.76047
      ],
      [
        -115.91865,
        43.76082
      ],
      [
        -115.91851,
        43.76115
      ],
      [
        -115.91837,
        43.76137
      ],
      [
        -115.91827,
        43.76149
      ],
      [
        -115.91821,
        43.76154
      ],
      [
        -115.91817,
        43.76159
      ],
      [
        -115.91806,
        43.76168
      ],
      [
        -115.91784,
        43.76183
      ],
      [
        -115.91719,
        43.76217
      ],
      [
        -115.91684,
        43.76237
      ],
      [
        -115.91667,
        43.76249
      ],
      [
        -115.91661,
        43.76255
      ],
      [
        -115.91646,
        43.76275
      ],
      [
        -115.91638,
        43.76293
      ],
      [
        -115.91634,
        43.76312
      ],
      [
        -115.91632,
        43.76341
      ],
      [
        -115.91637,
        43.76518
      ],
      [
        -115.91635,
        43.76539
      ],
      [
        -115.91626,
        43.76567
      ],
      [
        -115.91618,
        43.76581
      ],
      [
        -115.916,
        43.76602
      ],
      [
        -115.91588,
        43.76613
      ],
      [
        -115.91558,
        43.7663
      ],
      [
        -115.91457,
        43.7668
      ],
      [
        -115.9143,
        43.76697
      ],
      [
        -115.9142,
        43.76705
      ],
      [
        -115.91406,
        43.76719
      ],
      [
        -115.91398,
        43.76729
      ],
      [
        -115.91391,
        43.76744
      ],
      [
        -115.91386,
        43.7676
      ],
      [
        -115.91385,
        43.76765
      ],
      [
        -115.91385,
        43.76774
      ],
      [
        -115.91386,
        43.76785
      ],
      [
        -115.91389,
        43.76796
      ],
      [
        -115.91389,
        43.76797
      ],
      [
        -115.91401,
        43.76819
      ],
      [
        -115.91411,
        43.76831
      ],
      [
        -115.91445,
        43.76868
      ],
      [
        -115.91455,
        43.76884
      ],
      [
        -115.9146,
        43.76897
      ],
      [
        -115.91462,
        43.76911
      ],
      [
        -115.91462,
        43.7692
      ],
      [
        -115.91461,
        43.7693
      ],
      [
        -115.91457,
        43.76946
      ],
      [
        -115.9145,
        43.76963
      ],
      [
        -115.91442,
        43.76986
      ],
      [
        -115.91427,
        43.77022
      ],
      [
        -115.91385,
        43.77132
      ],
      [
        -115.91367,
        43.77171
      ],
      [
        -115.91353,
        43.77198
      ],
      [
        -115.91348,
        43.77206
      ],
      [
        -115.91335,
        43.77224
      ],
      [
        -115.91316,
        43.77246
      ],
      [
        -115.91296,
        43.77266
      ],
      [
        -115.91289,
        43.77272
      ],
      [
        -115.9128,
        43.77278
      ],
      [
        -115.9127,
        43.77286
      ],
      [
        -115.91247,
        43.77302
      ],
      [
        -115.91247,
        43.77303
      ],
      [
        -115.91182,
        43.77348
      ],
      [
        -115.91156,
        43.77363
      ],
      [
        -115.91149,
        43.77366
      ],
      [
        -115.91135,
        43.77374
      ],
      [
        -115.91077,
        43.77396
      ],
      [
        -115.9106,
        43.77401
      ],
      [
        -115.91059,
        43.77402
      ],
      [
        -115.90941,
        43.77429
      ],
      [
        -115.90924,
        43.77434
      ],
      [
        -115.90923,
        43.77434
      ],
      [
        -115.90881,
        43.77448
      ],
      [
        -115.90869,
        43.77454
      ],
      [
        -115.90861,
        43.77457
      ],
      [
        -115.90857,
        43.77458
      ],
      [
        -115.90854,
        43.7746
      ],
      [
        -115.90841,
        43.77466
      ],
      [
        -115.90784,
        43.77496
      ],
      [
        -115.90769,
        43.77503
      ],
      [
        -115.90755,
        43.77511
      ],
      [
        -115.89285,
        43.78259
      ],
      [
        -115.89212,
        43.78288
      ],
      [
        -115.89176,
        43.783
      ],
      [
        -115.89138,
        43.7831
      ],
      [
        -115.89085,
        43.78322
      ],
      [
        -115.89042,
        43.7833
      ],
      [
        -115.89003,
        43.78335
      ],
      [
        -115.88922,
        43.7834
      ],
      [
        -115.88654,
        43.78346
      ],
      [
        -115.88634,
        43.78348
      ],
      [
        -115.88624,
        43.7835
      ],
      [
        -115.88591,
        43.7836
      ],
      [
        -115.88574,
        43.78369
      ],
      [
        -115.88565,
        43.78377
      ],
      [
        -115.8856,
        43.7838
      ],
      [
        -115.88553,
        43.78389
      ],
      [
        -115.88548,
        43.78394
      ],
      [
        -115.8854,
        43.78409
      ],
      [
        -115.88536,
        43.78422
      ],
      [
        -115.88533,
        43.78449
      ],
      [
        -115.8853,
        43.78504
      ],
      [
        -115.88528,
        43.78515
      ],
      [
        -115.88524,
        43.78529
      ],
      [
        -115.88516,
        43.78544
      ],
      [
        -115.88508,
        43.78557
      ],
      [
        -115.885,
        43.78567
      ],
      [
        -115.8847,
        43.78592
      ],
      [
        -115.88449,
        43.78604
      ],
      [
        -115.88309,
        43.78668
      ],
      [
        -115.88257,
        43.787
      ],
      [
        -115.88231,
        43.78719
      ],
      [
        -115.88217,
        43.78732
      ],
      [
        -115.88215,
        43.78733
      ],
      [
        -115.88181,
        43.78765
      ],
      [
        -115.88158,
        43.78789
      ],
      [
        -115.88147,
        43.78802
      ],
      [
        -115.88127,
        43.7883
      ],
      [
        -115.88102,
        43.78872
      ],
      [
        -115.88093,
        43.78889
      ],
      [
        -115.88091,
        43.78892
      ],
      [
        -115.87946,
        43.79156
      ],
      [
        -115.87933,
        43.79175
      ],
      [
        -115.87916,
        43.79205
      ],
      [
        -115.87913,
        43.79209
      ],
      [
        -115.87834,
        43.79359
      ],
      [
        -115.87804,
        43.79409
      ],
      [
        -115.8772,
        43.79562
      ],
      [
        -115.87719,
        43.79563
      ],
      [
        -115.87654,
        43.79683
      ],
      [
        -115.87561,
        43.79847
      ],
      [
        -115.87543,
        43.79882
      ],
      [
        -115.87186,
        43.80524
      ],
      [
        -115.87156,
        43.80574
      ],
      [
        -115.87115,
        43.80636
      ],
      [
        -115.87032,
        43.80748
      ],
      [
        -115.86987,
        43.80803
      ],
      [
        -115.86888,
        43.80913
      ],
      [
        -115.8685,
        43.80951
      ],
      [
        -115.86809,
        43.80989
      ],
      [
        -115.86662,
        43.81118
      ],
      [
        -115.86646,
        43.8113
      ],
      [
        -115.86612,
        43.81171
      ],
      [
        -115.86575,
        43.81199
      ],
      [
        -115.86465,
        43.81274
      ],
      [
        -115.8639,
        43.81322
      ],
      [
        -115.86284,
        43.81382
      ],
      [
        -115.8619,
        43.81431
      ],
      [
        -115.86057,
        43.81494
      ],
      [
        -115.83697,
        43.82451
      ],
      [
        -115.83564,
        43.82499
      ],
      [
        -115.83546,
        43.82504
      ],
      [
        -115.83418,
        43.82532
      ],
      [
        -115.8342,
        43.8255
      ],
      [
        -115.83421,
        43.82604
      ],
      [
        -115.8342,
        43.82624
      ],
      [
        -115.83414,
        43.82662
      ],
      [
        -115.83408,
        43.82687
      ],
      [
        -115.83403,
        43.82703
      ],
      [
        -115.83388,
        43.8277
      ],
      [
        -115.8337,
        43.8284
      ],
      [
        -115.83456,
        43.82849
      ],
      [
        -115.8337,
        43.8284
      ],
      [
        -115.83388,
        43.8277
      ],
      [
        -115.83403,
        43.82703
      ],
      [
        -115.83408,
        43.82687
      ],
      [
        -115.83414,
        43.82662
      ],
      [
        -115.8342,
        43.82624
      ],
      [
        -115.83421,
        43.82604
      ],
      [
        -115.8342,
        43.8255
      ],
      [
        -115.83418,
        43.82532
      ],
      [
        -115.83347,
        43.82543
      ],
      [
        -115.83287,
        43.8255
      ],
      [
        -115.83169,
        43.82556
      ],
      [
        -115.83122,
        43.82556
      ],
      [
        -115.83027,
        43.82549
      ],
      [
        -115.82997,
        43.82545
      ],
      [
        -115.82973,
        43.82543
      ],
      [
        -115.82955,
        43.8254
      ],
      [
        -115.82937,
        43.82539
      ],
      [
        -115.82873,
        43.82531
      ],
      [
        -115.82815,
        43.82526
      ],
      [
        -115.82807,
        43.82526
      ],
      [
        -115.8279,
        43.82524
      ],
      [
        -115.82767,
        43.82523
      ],
      [
        -115.82665,
        43.82523
      ],
      [
        -115.8238,
        43.82538
      ],
      [
        -115.82365,
        43.82538
      ],
      [
        -115.82348,
        43.82539
      ],
      [
        -115.822,
        43.82536
      ],
      [
        -115.82068,
        43.82529
      ],
      [
        -115.82048,
        43.82527
      ],
      [
        -115.82027,
        43.82526
      ],
      [
        -115.81898,
        43.82511
      ],
      [
        -115.81884,
        43.82508
      ],
      [
        -115.81811,
        43.82497
      ],
      [
        -115.81676,
        43.8247
      ],
      [
        -115.81342,
        43.82386
      ],
      [
        -115.81295,
        43.82376
      ],
      [
        -115.81268,
        43.82372
      ],
      [
        -115.81252,
        43.82371
      ],
      [
        -115.81219,
        43.82373
      ],
      [
        -115.81209,
        43.82375
      ],
      [
        -115.81204,
        43.82375
      ],
      [
        -115.8118,
        43.82381
      ],
      [
        -115.81178,
        43.82382
      ],
      [
        -115.81166,
        43.82385
      ],
      [
        -115.81055,
        43.82429
      ],
      [
        -115.80999,
        43.82455
      ],
      [
        -115.808,
        43.82567
      ],
      [
        -115.80612,
        43.82677
      ],
      [
        -115.8056,
        43.82703
      ],
      [
        -115.8055,
        43.82706
      ],
      [
        -115.80542,
        43.8271
      ],
      [
        -115.80535,
        43.82711
      ],
      [
        -115.80518,
        43.82716
      ],
      [
        -115.80248,
        43.82753
      ],
      [
        -115.80216,
        43.82755
      ],
      [
        -115.80201,
        43.82755
      ],
      [
        -115.80167,
        43.82752
      ],
      [
        -115.80158,
        43.8275
      ],
      [
        -115.80157,
        43.8275
      ],
      [
        -115.80135,
        43.82744
      ],
      [
        -115.80134,
        43.82744
      ],
      [
        -115.80102,
        43.82731
      ],
      [
        -115.80092,
        43.82728
      ],
      [
        -115.80083,
        43.82724
      ],
      [
        -115.80073,
        43.82722
      ],
      [
        -115.80064,
        43.82719
      ],
      [
        -115.80053,
        43.82717
      ],
      [
        -115.80033,
        43.82715
      ],
      [
        -115.80024,
        43.82715
      ],
      [
        -115.80001,
        43.82717
      ],
      [
        -115.79978,
        43.82723
      ],
      [
        -115.79957,
        43.82733
      ],
      [
        -115.79944,
        43.82742
      ],
      [
        -115.79934,
        43.8275
      ],
      [
        -115.79905,
        43.82779
      ],
      [
        -115.79889,
        43.82793
      ],
      [
        -115.79854,
        43.82827
      ],
      [
        -115.79784,
        43.82886
      ],
      [
        -115.7978,
        43.8289
      ],
      [
        -115.79714,
        43.82938
      ],
      [
        -115.79698,
        43.82951
      ],
      [
        -115.7959,
        43.83026
      ],
      [
        -115.79278,
        43.83235
      ],
      [
        -115.79225,
        43.83273
      ],
      [
        -115.79223,
        43.83274
      ],
      [
        -115.78921,
        43.83477
      ],
      [
        -115.78914,
        43.83481
      ],
      [
        -115.78732,
        43.83606
      ],
      [
        -115.7872,
        43.83616
      ],
      [
        -115.78703,
        43.83634
      ],
      [
        -115.78698,
        43.83641
      ],
      [
        -115.78688,
        43.83659
      ],
      [
        -115.78688,
        43.8366
      ],
      [
        -115.78685,
        43.83667
      ],
      [
        -115.78682,
        43.83676
      ],
      [
        -115.78679,
        43.83694
      ],
      [
        -115.78679,
        43.83698
      ],
      [
        -115.78676,
        43.83718
      ],
      [
        -115.78672,
        43.83731
      ],
      [
        -115.78663,
        43.83746
      ],
      [
        -115.78656,
        43.83755
      ],
      [
        -115.78649,
        43.83761
      ],
      [
        -115.78646,
        43.83763
      ],
      [
        -115.7864,
        43.83768
      ],
      [
        -115.78632,
        43.83773
      ],
      [
        -115.7863,
        43.83773
      ],
      [
        -115.78625,
        43.83776
      ],
      [
        -115.78601,
        43.83785
      ],
      [
        -115.78585,
        43.83789
      ],
      [
        -115.78566,
        43.83792
      ],
      [
        -115.78556,
        43.83792
      ],
      [
        -115.78546,
        43.83793
      ],
      [
        -115.78536,
        43.83793
      ],
      [
        -115.784,
        43.83801
      ],
      [
        -115.7835,
        43.83802
      ],
      [
        -115.78343,
        43.83801
      ],
      [
        -115.78337,
        43.83801
      ],
      [
        -115.78301,
        43.83798
      ],
      [
        -115.78247,
        43.83789
      ],
      [
        -115.78223,
        43.83784
      ],
      [
        -115.78215,
        43.83783
      ],
      [
        -115.782,
        43.8378
      ],
      [
        -115.78192,
        43.8378
      ],
      [
        -115.78183,
        43.83779
      ],
      [
        -115.78175,
        43.83779
      ],
      [
        -115.78159,
        43.83781
      ],
      [
        -115.78134,
        43.83786
      ],
      [
        -115.78116,
        43.83794
      ],
      [
        -115.78095,
        43.83808
      ],
      [
        -115.78078,
        43.83824
      ],
      [
        -115.78068,
        43.83837
      ],
      [
        -115.78002,
        43.83934
      ],
      [
        -115.77976,
        43.83975
      ],
      [
        -115.77942,
        43.84021
      ],
      [
        -115.77934,
        43.8403
      ],
      [
        -115.77928,
        43.84039
      ],
      [
        -115.77921,
        43.84046
      ],
      [
        -115.77909,
        43.84056
      ],
      [
        -115.77887,
        43.8407
      ],
      [
        -115.77874,
        43.84077
      ],
      [
        -115.77856,
        43.84083
      ],
      [
        -115.77847,
        43.84085
      ],
      [
        -115.77841,
        43.84087
      ],
      [
        -115.77835,
        43.84088
      ],
      [
        -115.7783,
        43.84088
      ],
      [
        -115.77825,
        43.84089
      ],
      [
        -115.77816,
        43.84089
      ],
      [
        -115.77811,
        43.8409
      ],
      [
        -115.77805,
        43.84089
      ],
      [
        -115.77781,
        43.84089
      ],
      [
        -115.77732,
        43.84085
      ],
      [
        -115.77719,
        43.84085
      ],
      [
        -115.7771,
        43.84084
      ],
      [
        -115.77683,
        43.84084
      ],
      [
        -115.77676,
        43.84085
      ],
      [
        -115.77668,
        43.84085
      ],
      [
        -115.77651,
        43.84088
      ],
      [
        -115.7764,
        43.84091
      ],
      [
        -115.77635,
        43.84093
      ],
      [
        -115.77631,
        43.84094
      ],
      [
        -115.77616,
        43.84101
      ],
      [
        -115.77606,
        43.84107
      ],
      [
        -115.77599,
        43.84112
      ],
      [
        -115.77587,
        43.84123
      ],
      [
        -115.77582,
        43.8413
      ],
      [
        -115.77578,
        43.84137
      ],
      [
        -115.77572,
        43.84154
      ],
      [
        -115.77572,
        43.84189
      ],
      [
        -115.77574,
        43.84204
      ],
      [
        -115.77575,
        43.84224
      ],
      [
        -115.77576,
        43.84231
      ],
      [
        -115.77576,
        43.84243
      ],
      [
        -115.77577,
        43.84256
      ],
      [
        -115.77575,
        43.84276
      ],
      [
        -115.77569,
        43.843
      ],
      [
        -115.77566,
        43.84309
      ],
      [
        -115.77556,
        43.84331
      ],
      [
        -115.77531,
        43.84372
      ],
      [
        -115.77511,
        43.84395
      ],
      [
        -115.77485,
        43.8442
      ],
      [
        -115.77338,
        43.84539
      ],
      [
        -115.77321,
        43.84555
      ],
      [
        -115.77304,
        43.84573
      ],
      [
        -115.77279,
        43.84607
      ],
      [
        -115.77269,
        43.84625
      ],
      [
        -115.77256,
        43.84656
      ],
      [
        -115.77251,
        43.84671
      ],
      [
        -115.77176,
        43.84977
      ],
      [
        -115.77172,
        43.84988
      ],
      [
        -115.77169,
        43.84999
      ],
      [
        -115.77165,
        43.8501
      ],
      [
        -115.77154,
        43.85031
      ],
      [
        -115.77146,
        43.85043
      ],
      [
        -115.77126,
        43.85066
      ],
      [
        -115.77113,
        43.85079
      ],
      [
        -115.77105,
        43.85084
      ],
      [
        -115.77089,
        43.85096
      ],
      [
        -115.7706,
        43.85114
      ],
      [
        -115.77036,
        43.85126
      ],
      [
        -115.76995,
        43.8515
      ],
      [
        -115.7697,
        43.85167
      ],
      [
        -115.76965,
        43.85171
      ],
      [
        -115.7696,
        43.85176
      ],
      [
        -115.76955,
        43.8518
      ],
      [
        -115.76946,
        43.8519
      ],
      [
        -115.76937,
        43.85202
      ],
      [
        -115.76929,
        43.85216
      ],
      [
        -115.76923,
        43.8523
      ],
      [
        -115.76918,
        43.85245
      ],
      [
        -115.76912,
        43.85272
      ],
      [
        -115.76907,
        43.85307
      ],
      [
        -115.769,
        43.85331
      ],
      [
        -115.76892,
        43.85349
      ],
      [
        -115.7688,
        43.85372
      ],
      [
        -115.76865,
        43.85395
      ],
      [
        -115.7686,
        43.854
      ],
      [
        -115.76853,
        43.85409
      ],
      [
        -115.76846,
        43.85416
      ],
      [
        -115.76814,
        43.85443
      ],
      [
        -115.76776,
        43.85468
      ],
      [
        -115.76769,
        43.85471
      ],
      [
        -115.7676,
        43.85476
      ],
      [
        -115.76759,
        43.85476
      ],
      [
        -115.76737,
        43.85486
      ],
      [
        -115.76692,
        43.85501
      ],
      [
        -115.76613,
        43.85516
      ],
      [
        -115.76575,
        43.8552
      ],
      [
        -115.7657,
        43.8552
      ],
      [
        -115.76541,
        43.85525
      ],
      [
        -115.7652,
        43.8553
      ],
      [
        -115.76504,
        43.85535
      ],
      [
        -115.76478,
        43.85547
      ],
      [
        -115.76461,
        43.85558
      ],
      [
        -115.76446,
        43.85571
      ],
      [
        -115.7643,
        43.85589
      ],
      [
        -115.76422,
        43.85601
      ],
      [
        -115.76414,
        43.85617
      ],
      [
        -115.76412,
        43.85624
      ],
      [
        -115.7641,
        43.85634
      ],
      [
        -115.76409,
        43.85643
      ],
      [
        -115.76408,
        43.857
      ],
      [
        -115.76406,
        43.85714
      ],
      [
        -115.76403,
        43.85724
      ],
      [
        -115.76403,
        43.85725
      ],
      [
        -115.76401,
        43.85729
      ],
      [
        -115.764,
        43.85733
      ],
      [
        -115.76398,
        43.85737
      ],
      [
        -115.76395,
        43.85741
      ],
      [
        -115.76393,
        43.85745
      ],
      [
        -115.76389,
        43.85751
      ],
      [
        -115.7638,
        43.8576
      ],
      [
        -115.76375,
        43.85764
      ],
      [
        -115.7637,
        43.85769
      ],
      [
        -115.76365,
        43.85773
      ],
      [
        -115.7636,
        43.85776
      ],
      [
        -115.76356,
        43.85779
      ],
      [
        -115.76335,
        43.85791
      ],
      [
        -115.76328,
        43.85794
      ],
      [
        -115.76267,
        43.85825
      ],
      [
        -115.76227,
        43.85851
      ],
      [
        -115.76227,
        43.85852
      ],
      [
        -115.76215,
        43.85861
      ],
      [
        -115.76012,
        43.86042
      ],
      [
        -115.75994,
        43.86056
      ],
      [
        -115.75854,
        43.86155
      ],
      [
        -115.75846,
        43.86162
      ],
      [
        -115.75837,
        43.86168
      ],
      [
        -115.75815,
        43.8619
      ],
      [
        -115.7581,
        43.86197
      ],
      [
        -115.75802,
        43.86206
      ],
      [
        -115.75786,
        43.86233
      ],
      [
        -115.75756,
        43.86291
      ],
      [
        -115.75754,
        43.86294
      ],
      [
        -115.75751,
        43.863
      ],
      [
        -115.75745,
        43.86308
      ],
      [
        -115.75736,
        43.86322
      ],
      [
        -115.75723,
        43.86337
      ],
      [
        -115.7571,
        43.8635
      ],
      [
        -115.75708,
        43.86351
      ],
      [
        -115.75696,
        43.86362
      ],
      [
        -115.7568,
        43.86374
      ],
      [
        -115.75662,
        43.86386
      ],
      [
        -115.75653,
        43.86391
      ],
      [
        -115.75644,
        43.86395
      ],
      [
        -115.75635,
        43.864
      ],
      [
        -115.75602,
        43.86413
      ],
      [
        -115.75574,
        43.86422
      ],
      [
        -115.75547,
        43.86429
      ],
      [
        -115.75495,
        43.86439
      ],
      [
        -115.75467,
        43.86446
      ],
      [
        -115.75433,
        43.86459
      ],
      [
        -115.75423,
        43.86464
      ],
      [
        -115.75404,
        43.86475
      ],
      [
        -115.75388,
        43.86486
      ],
      [
        -115.75364,
        43.86505
      ],
      [
        -115.75344,
        43.86525
      ],
      [
        -115.75322,
        43.86559
      ],
      [
        -115.75316,
        43.8657
      ],
      [
        -115.75312,
        43.86579
      ],
      [
        -115.75308,
        43.86586
      ],
      [
        -115.75246,
        43.86719
      ],
      [
        -115.75221,
        43.86767
      ],
      [
        -115.75204,
        43.86794
      ],
      [
        -115.75186,
        43.86815
      ],
      [
        -115.75177,
        43.86824
      ],
      [
        -115.75177,
        43.86825
      ],
      [
        -115.75153,
        43.86846
      ],
      [
        -115.7514,
        43.86856
      ],
      [
        -115.75111,
        43.86875
      ],
      [
        -115.75075,
        43.86894
      ],
      [
        -115.75068,
        43.86897
      ],
      [
        -115.75048,
        43.86908
      ],
      [
        -115.75047,
        43.86909
      ],
      [
        -115.75031,
        43.86919
      ],
      [
        -115.75025,
        43.86924
      ],
      [
        -115.75008,
        43.86941
      ],
      [
        -115.74995,
        43.86957
      ],
      [
        -115.74986,
        43.86971
      ],
      [
        -115.74858,
        43.87223
      ],
      [
        -115.74831,
        43.87271
      ],
      [
        -115.74825,
        43.87279
      ],
      [
        -115.74819,
        43.87289
      ],
      [
        -115.74811,
        43.873
      ],
      [
        -115.74791,
        43.87323
      ],
      [
        -115.74776,
        43.87337
      ],
      [
        -115.74767,
        43.87347
      ],
      [
        -115.74759,
        43.87353
      ],
      [
        -115.74744,
        43.87366
      ],
      [
        -115.74715,
        43.87387
      ],
      [
        -115.74689,
        43.87404
      ],
      [
        -115.74588,
        43.87458
      ],
      [
        -115.74131,
        43.87692
      ],
      [
        -115.74101,
        43.8771
      ],
      [
        -115.74071,
        43.87731
      ],
      [
        -115.74023,
        43.87769
      ],
      [
        -115.74021,
        43.87771
      ],
      [
        -115.73989,
        43.87796
      ],
      [
        -115.73987,
        43.87797
      ],
      [
        -115.73979,
        43.87805
      ],
      [
        -115.73857,
        43.87901
      ],
      [
        -115.73823,
        43.87925
      ],
      [
        -115.73791,
        43.87943
      ],
      [
        -115.73747,
        43.87965
      ],
      [
        -115.73543,
        43.8806
      ],
      [
        -115.73476,
        43.88095
      ],
      [
        -115.73419,
        43.8813
      ],
      [
        -115.73226,
        43.88264
      ],
      [
        -115.73216,
        43.8827
      ],
      [
        -115.73077,
        43.88366
      ],
      [
        -115.73072,
        43.8837
      ],
      [
        -115.72909,
        43.88482
      ],
      [
        -115.72877,
        43.88507
      ],
      [
        -115.72851,
        43.88534
      ],
      [
        -115.72828,
        43.88565
      ],
      [
        -115.72816,
        43.88587
      ],
      [
        -115.72811,
        43.88603
      ],
      [
        -115.7281,
        43.88604
      ],
      [
        -115.72804,
        43.88627
      ],
      [
        -115.72793,
        43.88661
      ],
      [
        -115.72787,
        43.88674
      ],
      [
        -115.72785,
        43.8868
      ],
      [
        -115.72776,
        43.88697
      ],
      [
        -115.72762,
        43.8872
      ],
      [
        -115.72747,
        43.8874
      ],
      [
        -115.72731,
        43.88756
      ],
      [
        -115.727,
        43.88782
      ],
      [
        -115.72676,
        43.888
      ],
      [
        -115.72629,
        43.88838
      ],
      [
        -115.7249,
        43.88943
      ],
      [
        -115.72361,
        43.89046
      ],
      [
        -115.72311,
        43.89083
      ],
      [
        -115.72309,
        43.89085
      ],
      [
        -115.72295,
        43.89095
      ],
      [
        -115.72291,
        43.89097
      ],
      [
        -115.72261,
        43.89118
      ],
      [
        -115.72215,
        43.89145
      ],
      [
        -115.72172,
        43.89167
      ],
      [
        -115.72112,
        43.8919
      ],
      [
        -115.71767,
        43.89304
      ],
      [
        -115.71731,
        43.89317
      ],
      [
        -115.7172,
        43.89322
      ],
      [
        -115.71698,
        43.89334
      ],
      [
        -115.71683,
        43.89344
      ],
      [
        -115.71667,
        43.89357
      ],
      [
        -115.71651,
        43.89373
      ],
      [
        -115.71642,
        43.89385
      ],
      [
        -115.71638,
        43.89392
      ],
      [
        -115.71633,
        43.89399
      ],
      [
        -115.71622,
        43.89428
      ],
      [
        -115.7162,
        43.89439
      ],
      [
        -115.7162,
        43.89441
      ],
      [
        -115.71618,
        43.89453
      ],
      [
        -115.71618,
        43.89459
      ],
      [
        -115.71616,
        43.89475
      ],
      [
        -115.71612,
        43.8949
      ],
      [
        -115.71606,
        43.89501
      ],
      [
        -115.71602,
        43.89507
      ],
      [
        -115.71597,
        43.89512
      ],
      [
        -115.71586,
        43.89521
      ],
      [
        -115.71574,
        43.89528
      ],
      [
        -115.71556,
        43.89534
      ],
      [
        -115.71539,
        43.89538
      ],
      [
        -115.71531,
        43.89538
      ],
      [
        -115.71522,
        43.89539
      ],
      [
        -115.71511,
        43.89539
      ],
      [
        -115.71504,
        43.89538
      ],
      [
        -115.71497,
        43.89538
      ],
      [
        -115.7149,
        43.89536
      ],
      [
        -115.71483,
        43.89535
      ],
      [
        -115.71476,
        43.89533
      ],
      [
        -115.7147,
        43.8953
      ],
      [
        -115.71463,
        43.89528
      ],
      [
        -115.71455,
        43.89524
      ],
      [
        -115.71445,
        43.89517
      ],
      [
        -115.7143,
        43.89509
      ],
      [
        -115.71407,
        43.89501
      ],
      [
        -115.71401,
        43.895
      ],
      [
        -115.7138,
        43.895
      ],
      [
        -115.71365,
        43.89502
      ],
      [
        -115.71354,
        43.89506
      ],
      [
        -115.7135,
        43.89507
      ],
      [
        -115.71341,
        43.89511
      ],
      [
        -115.71332,
        43.89518
      ],
      [
        -115.71328,
        43.89522
      ],
      [
        -115.71325,
        43.89524
      ],
      [
        -115.71323,
        43.89528
      ],
      [
        -115.71318,
        43.89535
      ],
      [
        -115.71315,
        43.89541
      ],
      [
        -115.71313,
        43.89551
      ],
      [
        -115.71313,
        43.89556
      ],
      [
        -115.71314,
        43.89562
      ],
      [
        -115.7132,
        43.89585
      ],
      [
        -115.71321,
        43.89586
      ],
      [
        -115.71323,
        43.89598
      ],
      [
        -115.71323,
        43.8961
      ],
      [
        -115.71321,
        43.89618
      ],
      [
        -115.71317,
        43.89628
      ],
      [
        -115.71313,
        43.89636
      ],
      [
        -115.71302,
        43.89653
      ],
      [
        -115.71291,
        43.89664
      ],
      [
        -115.71288,
        43.89666
      ],
      [
        -115.71283,
        43.89671
      ],
      [
        -115.71272,
        43.89678
      ],
      [
        -115.71268,
        43.8968
      ],
      [
        -115.71267,
        43.89681
      ],
      [
        -115.71257,
        43.89687
      ],
      [
        -115.71037,
        43.89794
      ],
      [
        -115.71022,
        43.89803
      ],
      [
        -115.70969,
        43.89841
      ],
      [
        -115.70955,
        43.89852
      ],
      [
        -115.70898,
        43.89904
      ],
      [
        -115.70879,
        43.89925
      ],
      [
        -115.70832,
        43.89986
      ],
      [
        -115.70752,
        43.90095
      ],
      [
        -115.70708,
        43.90149
      ],
      [
        -115.70707,
        43.90151
      ],
      [
        -115.70695,
        43.90164
      ],
      [
        -115.70676,
        43.90182
      ],
      [
        -115.70664,
        43.90195
      ],
      [
        -115.70663,
        43.90195
      ],
      [
        -115.70645,
        43.90212
      ],
      [
        -115.70632,
        43.90223
      ],
      [
        -115.7063,
        43.90224
      ],
      [
        -115.70617,
        43.90236
      ],
      [
        -115.70584,
        43.90262
      ],
      [
        -115.7046,
        43.90348
      ],
      [
        -115.70393,
        43.90392
      ],
      [
        -115.7024,
        43.905
      ],
      [
        -115.70223,
        43.90514
      ],
      [
        -115.70208,
        43.90528
      ],
      [
        -115.70188,
        43.90551
      ],
      [
        -115.70172,
        43.90574
      ],
      [
        -115.7015,
        43.90612
      ],
      [
        -115.701,
        43.9072
      ],
      [
        -115.7009,
        43.90738
      ],
      [
        -115.70082,
        43.9075
      ],
      [
        -115.7007,
        43.90765
      ],
      [
        -115.70059,
        43.90776
      ],
      [
        -115.70053,
        43.90781
      ],
      [
        -115.70047,
        43.90787
      ],
      [
        -115.70028,
        43.90801
      ],
      [
        -115.70026,
        43.90802
      ],
      [
        -115.70011,
        43.90812
      ],
      [
        -115.70005,
        43.90815
      ],
      [
        -115.69997,
        43.9082
      ],
      [
        -115.69991,
        43.90822
      ],
      [
        -115.69987,
        43.90825
      ],
      [
        -115.69975,
        43.9083
      ],
      [
        -115.69911,
        43.90853
      ],
      [
        -115.69899,
        43.90858
      ],
      [
        -115.69867,
        43.90874
      ],
      [
        -115.69837,
        43.90894
      ],
      [
        -115.69828,
        43.90901
      ],
      [
        -115.69813,
        43.90915
      ],
      [
        -115.69791,
        43.90942
      ],
      [
        -115.69787,
        43.9095
      ],
      [
        -115.69783,
        43.90956
      ],
      [
        -115.69774,
        43.90976
      ],
      [
        -115.69772,
        43.90984
      ],
      [
        -115.69769,
        43.9099
      ],
      [
        -115.69768,
        43.90997
      ],
      [
        -115.69766,
        43.91004
      ],
      [
        -115.69764,
        43.91019
      ],
      [
        -115.69756,
        43.91057
      ],
      [
        -115.69753,
        43.91064
      ],
      [
        -115.69737,
        43.91091
      ],
      [
        -115.6973,
        43.911
      ],
      [
        -115.69689,
        43.9114
      ],
      [
        -115.69683,
        43.91144
      ],
      [
        -115.69677,
        43.9115
      ],
      [
        -115.69656,
        43.91168
      ],
      [
        -115.69647,
        43.91177
      ],
      [
        -115.69619,
        43.91201
      ],
      [
        -115.69617,
        43.91202
      ],
      [
        -115.69594,
        43.91224
      ],
      [
        -115.69588,
        43.91231
      ],
      [
        -115.69583,
        43.91238
      ],
      [
        -115.69582,
        43.9124
      ],
      [
        -115.69576,
        43.91249
      ],
      [
        -115.69572,
        43.91259
      ],
      [
        -115.69568,
        43.91276
      ],
      [
        -115.69563,
        43.91304
      ],
      [
        -115.69558,
        43.91315
      ],
      [
        -115.69558,
        43.91316
      ],
      [
        -115.69541,
        43.91341
      ],
      [
        -115.69535,
        43.91348
      ],
      [
        -115.69507,
        43.91372
      ],
      [
        -115.69495,
        43.9138
      ],
      [
        -115.69476,
        43.91391
      ],
      [
        -115.6947,
        43.91393
      ],
      [
        -115.69467,
        43.91395
      ],
      [
        -115.69456,
        43.914
      ],
      [
        -115.69429,
        43.91408
      ],
      [
        -115.69396,
        43.91415
      ],
      [
        -115.69393,
        43.91415
      ],
      [
        -115.69377,
        43.91418
      ],
      [
        -115.69375,
        43.91418
      ],
      [
        -115.69355,
        43.91422
      ],
      [
        -115.69338,
        43.91424
      ],
      [
        -115.69321,
        43.91427
      ],
      [
        -115.69307,
        43.91431
      ],
      [
        -115.69304,
        43.91431
      ],
      [
        -115.69295,
        43.91434
      ],
      [
        -115.69283,
        43.91437
      ],
      [
        -115.69251,
        43.91451
      ],
      [
        -115.69241,
        43.91457
      ],
      [
        -115.69231,
        43.91462
      ],
      [
        -115.69012,
        43.91598
      ],
      [
        -115.68997,
        43.91612
      ],
      [
        -115.68993,
        43.91618
      ],
      [
        -115.6899,
        43.9163
      ],
      [
        -115.6899,
        43.91638
      ],
      [
        -115.68992,
        43.91646
      ],
      [
        -115.68994,
        43.9165
      ],
      [
        -115.68996,
        43.91653
      ],
      [
        -115.69005,
        43.91662
      ],
      [
        -115.69025,
        43.91671
      ],
      [
        -115.69042,
        43.91673
      ],
      [
        -115.69061,
        43.91671
      ],
      [
        -115.69072,
        43.91668
      ],
      [
        -115.69086,
        43.9166
      ],
      [
        -115.69098,
        43.91649
      ],
      [
        -115.69115,
        43.91629
      ],
      [
        -115.69122,
        43.91622
      ],
      [
        -115.69125,
        43.91618
      ],
      [
        -115.6914,
        43.91606
      ],
      [
        -115.69147,
        43.91602
      ],
      [
        -115.69154,
        43.91599
      ],
      [
        -115.69168,
        43.91595
      ],
      [
        -115.69176,
        43.91594
      ],
      [
        -115.69184,
        43.91594
      ],
      [
        -115.69193,
        43.91595
      ],
      [
        -115.69195,
        43.91596
      ],
      [
        -115.69207,
        43.91599
      ],
      [
        -115.69214,
        43.91602
      ],
      [
        -115.69221,
        43.91608
      ],
      [
        -115.6923,
        43.91619
      ],
      [
        -115.69232,
        43.91625
      ],
      [
        -115.69233,
        43.9163
      ],
      [
        -115.69233,
        43.91637
      ],
      [
        -115.69232,
        43.9164
      ],
      [
        -115.69231,
        43.91645
      ],
      [
        -115.69228,
        43.9165
      ],
      [
        -115.69225,
        43.91654
      ],
      [
        -115.69224,
        43.91654
      ],
      [
        -115.69221,
        43.91658
      ],
      [
        -115.69215,
        43.91662
      ],
      [
        -115.69204,
        43.91668
      ],
      [
        -115.69192,
        43.91672
      ],
      [
        -115.69112,
        43.91688
      ],
      [
        -115.69081,
        43.91697
      ],
      [
        -115.69067,
        43.91702
      ],
      [
        -115.69038,
        43.91717
      ],
      [
        -115.69008,
        43.91736
      ],
      [
        -115.68998,
        43.91744
      ],
      [
        -115.68954,
        43.91774
      ],
      [
        -115.68926,
        43.91791
      ],
      [
        -115.68894,
        43.91808
      ],
      [
        -115.68878,
        43.91815
      ],
      [
        -115.68867,
        43.91819
      ],
      [
        -115.68856,
        43.91824
      ],
      [
        -115.68842,
        43.91829
      ],
      [
        -115.68785,
        43.91846
      ],
      [
        -115.68762,
        43.91851
      ],
      [
        -115.6876,
        43.91852
      ],
      [
        -115.68748,
        43.91854
      ],
      [
        -115.6869,
        43.91867
      ],
      [
        -115.68677,
        43.91871
      ],
      [
        -115.68658,
        43.91879
      ],
      [
        -115.68646,
        43.91886
      ],
      [
        -115.68638,
        43.91892
      ],
      [
        -115.68597,
        43.91929
      ],
      [
        -115.68571,
        43.91948
      ],
      [
        -115.68534,
        43.91968
      ],
      [
        -115.68492,
        43.91984
      ],
      [
        -115.68345,
        43.92048
      ],
      [
        -115.68336,
        43.92053
      ],
      [
        -115.68332,
        43.92056
      ],
      [
        -115.68328,
        43.92058
      ],
      [
        -115.68325,
        43.92061
      ],
      [
        -115.6832,
        43.92065
      ],
      [
        -115.68317,
        43.92069
      ],
      [
        -115.68314,
        43.92074
      ],
      [
        -115.68313,
        43.92077
      ],
      [
        -115.68312,
        43.92082
      ],
      [
        -115.68312,
        43.92093
      ],
      [
        -115.68317,
        43.92103
      ],
      [
        -115.6832,
        43.92107
      ],
      [
        -115.68325,
        43.92112
      ],
      [
        -115.68336,
        43.92119
      ],
      [
        -115.68342,
        43.92122
      ],
      [
        -115.68348,
        43.92123
      ],
      [
        -115.68355,
        43.92125
      ],
      [
        -115.68364,
        43.92126
      ],
      [
        -115.68369,
        43.92126
      ],
      [
        -115.68385,
        43.92124
      ],
      [
        -115.68394,
        43.92122
      ],
      [
        -115.68416,
        43.92114
      ],
      [
        -115.68504,
        43.92075
      ],
      [
        -115.68529,
        43.92066
      ],
      [
        -115.68548,
        43.92062
      ],
      [
        -115.68551,
        43.92061
      ],
      [
        -115.68555,
        43.92061
      ],
      [
        -115.68571,
        43.92059
      ],
      [
        -115.68598,
        43.92063
      ],
      [
        -115.68617,
        43.92069
      ],
      [
        -115.68635,
        43.92079
      ],
      [
        -115.68647,
        43.92089
      ],
      [
        -115.68716,
        43.92155
      ],
      [
        -115.68747,
        43.92178
      ],
      [
        -115.68761,
        43.92187
      ],
      [
        -115.68786,
        43.92199
      ],
      [
        -115.68824,
        43.92212
      ],
      [
        -115.68831,
        43.92216
      ],
      [
        -115.68836,
        43.92218
      ],
      [
        -115.68844,
        43.92224
      ],
      [
        -115.68848,
        43.92228
      ],
      [
        -115.68851,
        43.92232
      ],
      [
        -115.68854,
        43.92237
      ],
      [
        -115.68855,
        43.9224
      ],
      [
        -115.68855,
        43.92244
      ],
      [
        -115.68856,
        43.92247
      ],
      [
        -115.68852,
        43.9226
      ],
      [
        -115.68849,
        43.92263
      ],
      [
        -115.68847,
        43.92266
      ],
      [
        -115.68844,
        43.92269
      ],
      [
        -115.6884,
        43.92272
      ],
      [
        -115.68835,
        43.92275
      ],
      [
        -115.68822,
        43.92279
      ],
      [
        -115.68813,
        43.92281
      ],
      [
        -115.68807,
        43.92281
      ],
      [
        -115.68791,
        43.92279
      ],
      [
        -115.68785,
        43.92277
      ],
      [
        -115.68775,
        43.92271
      ],
      [
        -115.68771,
        43.92268
      ],
      [
        -115.68764,
        43.9226
      ],
      [
        -115.6875,
        43.92239
      ],
      [
        -115.68748,
        43.92235
      ],
      [
        -115.68742,
        43.92228
      ],
      [
        -115.68736,
        43.92219
      ],
      [
        -115.68727,
        43.92209
      ],
      [
        -115.68712,
        43.92195
      ],
      [
        -115.6871,
        43.92194
      ],
      [
        -115.68706,
        43.9219
      ],
      [
        -115.68694,
        43.9218
      ],
      [
        -115.68686,
        43.92175
      ],
      [
        -115.68678,
        43.92168
      ],
      [
        -115.68648,
        43.92148
      ],
      [
        -115.68619,
        43.92132
      ],
      [
        -115.68618,
        43.92132
      ],
      [
        -115.68607,
        43.92128
      ],
      [
        -115.68597,
        43.92126
      ],
      [
        -115.68596,
        43.92125
      ],
      [
        -115.68588,
        43.92124
      ],
      [
        -115.68571,
        43.92124
      ],
      [
        -115.68551,
        43.92127
      ],
      [
        -115.68538,
        43.9213
      ],
      [
        -115.6852,
        43.92137
      ],
      [
        -115.68487,
        43.92155
      ],
      [
        -115.68457,
        43.92175
      ],
      [
        -115.68418,
        43.92204
      ],
      [
        -115.6837,
        43.92245
      ],
      [
        -115.68351,
        43.92263
      ],
      [
        -115.68337,
        43.92275
      ],
      [
        -115.68314,
        43.92298
      ],
      [
        -115.68298,
        43.92318
      ],
      [
        -115.6829,
        43.92331
      ],
      [
        -115.68278,
        43.92354
      ],
      [
        -115.68264,
        43.92372
      ],
      [
        -115.68251,
        43.92383
      ],
      [
        -115.68243,
        43.92388
      ],
      [
        -115.68229,
        43.92394
      ],
      [
        -115.68212,
        43.924
      ],
      [
        -115.68149,
        43.92413
      ],
      [
        -115.68136,
        43.92417
      ],
      [
        -115.68126,
        43.92421
      ],
      [
        -115.68115,
        43.92424
      ],
      [
        -115.68108,
        43.92428
      ],
      [
        -115.68101,
        43.92431
      ],
      [
        -115.68092,
        43.92436
      ],
      [
        -115.68055,
        43.92462
      ],
      [
        -115.67968,
        43.92527
      ],
      [
        -115.67953,
        43.92542
      ],
      [
        -115.6794,
        43.9256
      ],
      [
        -115.67931,
        43.9258
      ],
      [
        -115.67917,
        43.92652
      ],
      [
        -115.67908,
        43.9269
      ],
      [
        -115.67903,
        43.92725
      ],
      [
        -115.67903,
        43.92734
      ],
      [
        -115.67907,
        43.92766
      ],
      [
        -115.67948,
        43.92914
      ],
      [
        -115.67951,
        43.92922
      ],
      [
        -115.67955,
        43.92929
      ],
      [
        -115.67955,
        43.9293
      ],
      [
        -115.67973,
        43.92956
      ],
      [
        -115.67988,
        43.92971
      ],
      [
        -115.68,
        43.92985
      ],
      [
        -115.68004,
        43.92992
      ],
      [
        -115.68006,
        43.92997
      ],
      [
        -115.68006,
        43.93008
      ],
      [
        -115.68002,
        43.93017
      ],
      [
        -115.67999,
        43.9302
      ],
      [
        -115.67997,
        43.93023
      ],
      [
        -115.67987,
        43.9303
      ],
      [
        -115.67985,
        43.93031
      ],
      [
        -115.67982,
        43.93032
      ],
      [
        -115.67978,
        43.93034
      ],
      [
        -115.67968,
        43.93036
      ],
      [
        -115.67956,
        43.93036
      ],
      [
        -115.67938,
        43.9303
      ],
      [
        -115.67937,
        43.9303
      ],
      [
        -115.67931,
        43.93027
      ],
      [
        -115.67884,
        43.92984
      ],
      [
        -115.67864,
        43.92968
      ],
      [
        -115.67848,
        43.92957
      ],
      [
        -115.67798,
        43.92929
      ],
      [
        -115.67797,
        43.92929
      ],
      [
        -115.67774,
        43.92917
      ],
      [
        -115.67754,
        43.92905
      ],
      [
        -115.67735,
        43.92892
      ],
      [
        -115.67725,
        43.92886
      ],
      [
        -115.67704,
        43.92875
      ],
      [
        -115.67685,
        43.92869
      ],
      [
        -115.67662,
        43.92867
      ],
      [
        -115.67641,
        43.92867
      ],
      [
        -115.67612,
        43.92874
      ],
      [
        -115.67601,
        43.92878
      ],
      [
        -115.67557,
        43.92897
      ],
      [
        -115.67495,
        43.92928
      ],
      [
        -115.67221,
        43.93052
      ],
      [
        -115.67163,
        43.93083
      ],
      [
        -115.67144,
        43.93095
      ],
      [
        -115.6711,
        43.93119
      ],
      [
        -115.67109,
        43.9312
      ],
      [
        -115.67099,
        43.93127
      ],
      [
        -115.67055,
        43.93169
      ],
      [
        -115.67041,
        43.9318
      ],
      [
        -115.67027,
        43.93188
      ],
      [
        -115.66997,
        43.93198
      ],
      [
        -115.66985,
        43.932
      ],
      [
        -115.66955,
        43.93203
      ],
      [
        -115.66908,
        43.93203
      ],
      [
        -115.66891,
        43.93204
      ],
      [
        -115.6687,
        43.93204
      ],
      [
        -115.66854,
        43.93205
      ],
      [
        -115.66839,
        43.93207
      ],
      [
        -115.66812,
        43.93213
      ],
      [
        -115.66792,
        43.9322
      ],
      [
        -115.66778,
        43.93226
      ],
      [
        -115.66758,
        43.93236
      ],
      [
        -115.66647,
        43.93297
      ],
      [
        -115.66616,
        43.93318
      ],
      [
        -115.666,
        43.93333
      ],
      [
        -115.66553,
        43.93383
      ],
      [
        -115.66537,
        43.93402
      ],
      [
        -115.66528,
        43.93414
      ],
      [
        -115.66515,
        43.93436
      ],
      [
        -115.6651,
        43.93447
      ],
      [
        -115.66502,
        43.93479
      ],
      [
        -115.66499,
        43.93498
      ],
      [
        -115.66498,
        43.93513
      ],
      [
        -115.665,
        43.93536
      ],
      [
        -115.66501,
        43.93537
      ],
      [
        -115.66503,
        43.9355
      ],
      [
        -115.66508,
        43.93566
      ],
      [
        -115.6651,
        43.93577
      ],
      [
        -115.6651,
        43.93587
      ],
      [
        -115.66509,
        43.93594
      ],
      [
        -115.66507,
        43.936
      ],
      [
        -115.66504,
        43.93605
      ],
      [
        -115.66498,
        43.93612
      ],
      [
        -115.66488,
        43.9362
      ],
      [
        -115.66481,
        43.93624
      ],
      [
        -115.66469,
        43.93628
      ],
      [
        -115.66464,
        43.93629
      ],
      [
        -115.66414,
        43.9363
      ],
      [
        -115.66382,
        43.93633
      ],
      [
        -115.66366,
        43.93637
      ],
      [
        -115.66365,
        43.93638
      ],
      [
        -115.66347,
        43.93645
      ],
      [
        -115.66329,
        43.93657
      ],
      [
        -115.66319,
        43.93665
      ],
      [
        -115.66298,
        43.93685
      ],
      [
        -115.66284,
        43.93702
      ],
      [
        -115.66256,
        43.93731
      ],
      [
        -115.66157,
        43.93849
      ],
      [
        -115.66151,
        43.93855
      ],
      [
        -115.66116,
        43.93895
      ],
      [
        -115.66103,
        43.93913
      ],
      [
        -115.66095,
        43.93927
      ],
      [
        -115.66088,
        43.93944
      ],
      [
        -115.66085,
        43.93963
      ],
      [
        -115.66087,
        43.9399
      ],
      [
        -115.66092,
        43.94011
      ],
      [
        -115.66097,
        43.94023
      ],
      [
        -115.66098,
        43.94023
      ],
      [
        -115.66115,
        43.94051
      ],
      [
        -115.66133,
        43.9407
      ],
      [
        -115.66146,
        43.94086
      ],
      [
        -115.66151,
        43.94095
      ],
      [
        -115.66152,
        43.94103
      ],
      [
        -115.66153,
        43.94104
      ],
      [
        -115.66151,
        43.94116
      ],
      [
        -115.6615,
        43.94119
      ],
      [
        -115.66148,
        43.94123
      ],
      [
        -115.66139,
        43.94132
      ],
      [
        -115.6613,
        43.94138
      ],
      [
        -115.66112,
        43.94146
      ],
      [
        -115.66095,
        43.94149
      ],
      [
        -115.66089,
        43.94149
      ],
      [
        -115.66083,
        43.94148
      ],
      [
        -115.66076,
        43.94148
      ],
      [
        -115.66068,
        43.94146
      ],
      [
        -115.66061,
        43.94143
      ],
      [
        -115.66058,
        43.94141
      ],
      [
        -115.66053,
        43.94139
      ],
      [
        -115.66041,
        43.94127
      ],
      [
        -115.66037,
        43.9412
      ],
      [
        -115.66033,
        43.94111
      ],
      [
        -115.66031,
        43.94104
      ],
      [
        -115.66027,
        43.94082
      ],
      [
        -115.66027,
        43.94069
      ],
      [
        -115.66024,
        43.94032
      ],
      [
        -115.66026,
        43.93991
      ],
      [
        -115.66025,
        43.93969
      ],
      [
        -115.66019,
        43.93942
      ],
      [
        -115.66013,
        43.93926
      ],
      [
        -115.66011,
        43.93909
      ],
      [
        -115.66011,
        43.93893
      ],
      [
        -115.66013,
        43.93882
      ],
      [
        -115.66013,
        43.93881
      ],
      [
        -115.66015,
        43.93873
      ],
      [
        -115.66021,
        43.9386
      ],
      [
        -115.6604,
        43.93826
      ],
      [
        -115.66042,
        43.93813
      ],
      [
        -115.66042,
        43.93807
      ],
      [
        -115.66041,
        43.93801
      ],
      [
        -115.66033,
        43.9379
      ],
      [
        -115.66021,
        43.93781
      ],
      [
        -115.66016,
        43.93779
      ],
      [
        -115.66005,
        43.93776
      ],
      [
        -115.65999,
        43.93775
      ],
      [
        -115.65997,
        43.93774
      ],
      [
        -115.6598,
        43.93774
      ],
      [
        -115.6597,
        43.93775
      ],
      [
        -115.6596,
        43.93777
      ],
      [
        -115.65952,
        43.93781
      ],
      [
        -115.65946,
        43.93785
      ],
      [
        -115.6594,
        43.93791
      ],
      [
        -115.65933,
        43.93802
      ],
      [
        -115.65917,
        43.93841
      ],
      [
        -115.65914,
        43.93847
      ],
      [
        -115.65905,
        43.93862
      ],
      [
        -115.6589,
        43.93878
      ],
      [
        -115.65869,
        43.93894
      ],
      [
        -115.65861,
        43.93898
      ],
      [
        -115.65833,
        43.93908
      ],
      [
        -115.65819,
        43.93911
      ],
      [
        -115.65798,
        43.93914
      ],
      [
        -115.65746,
        43.93914
      ],
      [
        -115.65731,
        43.93913
      ],
      [
        -115.65509,
        43.93909
      ],
      [
        -115.65465,
        43.93913
      ],
      [
        -115.6544,
        43.93919
      ],
      [
        -115.65421,
        43.93925
      ],
      [
        -115.65382,
        43.93943
      ],
      [
        -115.65367,
        43.93952
      ],
      [
        -115.6533,
        43.93982
      ],
      [
        -115.65306,
        43.94003
      ],
      [
        -115.65298,
        43.94009
      ],
      [
        -115.65116,
        43.94177
      ],
      [
        -115.65037,
        43.94246
      ],
      [
        -115.65019,
        43.9426
      ],
      [
        -115.6492,
        43.94319
      ],
      [
        -115.64868,
        43.94345
      ],
      [
        -115.64696,
        43.94405
      ],
      [
        -115.64637,
        43.94421
      ],
      [
        -115.64602,
        43.94432
      ],
      [
        -115.64554,
        43.94453
      ],
      [
        -115.64493,
        43.94489
      ],
      [
        -115.64465,
        43.94509
      ],
      [
        -115.64446,
        43.94528
      ],
      [
        -115.6444,
        43.94535
      ],
      [
        -115.64423,
        43.94552
      ],
      [
        -115.64407,
        43.94573
      ],
      [
        -115.64389,
        43.94604
      ],
      [
        -115.64341,
        43.94676
      ],
      [
        -115.64325,
        43.94704
      ],
      [
        -115.64279,
        43.94753
      ],
      [
        -115.64224,
        43.94806
      ],
      [
        -115.64205,
        43.94823
      ],
      [
        -115.64194,
        43.94834
      ],
      [
        -115.64175,
        43.94851
      ],
      [
        -115.64084,
        43.94941
      ],
      [
        -115.64069,
        43.94962
      ],
      [
        -115.6406,
        43.94984
      ],
      [
        -115.64056,
        43.94997
      ],
      [
        -115.6405,
        43.95022
      ],
      [
        -115.64046,
        43.95032
      ],
      [
        -115.64043,
        43.95037
      ],
      [
        -115.64043,
        43.95038
      ],
      [
        -115.64037,
        43.95045
      ],
      [
        -115.64029,
        43.95053
      ],
      [
        -115.64022,
        43.95059
      ],
      [
        -115.64004,
        43.9507
      ],
      [
        -115.63978,
        43.9508
      ],
      [
        -115.63936,
        43.95092
      ],
      [
        -115.63923,
        43.95095
      ],
      [
        -115.63863,
        43.95113
      ],
      [
        -115.63853,
        43.95115
      ],
      [
        -115.638,
        43.9513
      ],
      [
        -115.63767,
        43.95137
      ],
      [
        -115.63751,
        43.95139
      ],
      [
        -115.6374,
        43.95139
      ],
      [
        -115.63734,
        43.9514
      ],
      [
        -115.63727,
        43.9514
      ],
      [
        -115.63703,
        43.95138
      ],
      [
        -115.63684,
        43.95135
      ],
      [
        -115.6359,
        43.95114
      ],
      [
        -115.63559,
        43.95109
      ],
      [
        -115.63511,
        43.9511
      ],
      [
        -115.6348,
        43.95115
      ],
      [
        -115.63453,
        43.95123
      ],
      [
        -115.63422,
        43.95135
      ],
      [
        -115.63419,
        43.95137
      ],
      [
        -115.63381,
        43.95156
      ],
      [
        -115.63362,
        43.95164
      ],
      [
        -115.6334,
        43.95176
      ],
      [
        -115.63315,
        43.95188
      ],
      [
        -115.63297,
        43.95198
      ],
      [
        -115.63258,
        43.95217
      ],
      [
        -115.63239,
        43.95228
      ],
      [
        -115.63236,
        43.95229
      ],
      [
        -115.63208,
        43.95251
      ],
      [
        -115.63194,
        43.95264
      ],
      [
        -115.63176,
        43.95285
      ],
      [
        -115.63163,
        43.95302
      ],
      [
        -115.63152,
        43.9532
      ],
      [
        -115.63141,
        43.95343
      ],
      [
        -115.63133,
        43.95367
      ],
      [
        -115.63131,
        43.95371
      ],
      [
        -115.63125,
        43.95406
      ],
      [
        -115.63125,
        43.95426
      ],
      [
        -115.63126,
        43.95445
      ],
      [
        -115.63129,
        43.95456
      ],
      [
        -115.63148,
        43.95545
      ],
      [
        -115.63149,
        43.95548
      ],
      [
        -115.63156,
        43.95582
      ],
      [
        -115.6316,
        43.95609
      ],
      [
        -115.6316,
        43.95628
      ],
      [
        -115.63158,
        43.95643
      ],
      [
        -115.63152,
        43.95662
      ],
      [
        -115.63148,
        43.95669
      ],
      [
        -115.63148,
        43.9567
      ],
      [
        -115.63143,
        43.95679
      ],
      [
        -115.63129,
        43.95699
      ],
      [
        -115.6311,
        43.95719
      ],
      [
        -115.63093,
        43.95732
      ],
      [
        -115.6307,
        43.95746
      ],
      [
        -115.63056,
        43.95752
      ],
      [
        -115.63034,
        43.9576
      ],
      [
        -115.63016,
        43.95765
      ],
      [
        -115.63011,
        43.95767
      ],
      [
        -115.62987,
        43.95772
      ],
      [
        -115.62979,
        43.95773
      ],
      [
        -115.62964,
        43.95776
      ],
      [
        -115.62962,
        43.95776
      ],
      [
        -115.6295,
        43.95779
      ],
      [
        -115.62923,
        43.95788
      ],
      [
        -115.62917,
        43.95791
      ],
      [
        -115.62913,
        43.95792
      ],
      [
        -115.62894,
        43.95806
      ],
      [
        -115.62886,
        43.95816
      ],
      [
        -115.62881,
        43.95825
      ],
      [
        -115.62879,
        43.9583
      ],
      [
        -115.62877,
        43.9584
      ],
      [
        -115.62877,
        43.95847
      ],
      [
        -115.62878,
        43.95856
      ],
      [
        -115.62882,
        43.95866
      ],
      [
        -115.62885,
        43.95872
      ],
      [
        -115.62903,
        43.95892
      ],
      [
        -115.62953,
        43.95939
      ],
      [
        -115.62968,
        43.95957
      ],
      [
        -115.62976,
        43.95969
      ],
      [
        -115.6299,
        43.95996
      ],
      [
        -115.63,
        43.96024
      ],
      [
        -115.63005,
        43.96063
      ],
      [
        -115.63002,
        43.96098
      ],
      [
        -115.62997,
        43.96125
      ],
      [
        -115.62977,
        43.96263
      ],
      [
        -115.62975,
        43.96273
      ],
      [
        -115.62973,
        43.96293
      ],
      [
        -115.6297,
        43.96312
      ],
      [
        -115.62964,
        43.9633
      ],
      [
        -115.62957,
        43.96344
      ],
      [
        -115.62948,
        43.96356
      ],
      [
        -115.62926,
        43.96379
      ],
      [
        -115.62895,
        43.96398
      ],
      [
        -115.62877,
        43.96406
      ],
      [
        -115.62858,
        43.96413
      ],
      [
        -115.62756,
        43.96439
      ],
      [
        -115.62742,
        43.96445
      ],
      [
        -115.62731,
        43.96451
      ],
      [
        -115.62716,
        43.96458
      ],
      [
        -115.62708,
        43.96463
      ],
      [
        -115.62685,
        43.96485
      ],
      [
        -115.62675,
        43.96497
      ],
      [
        -115.62667,
        43.96509
      ],
      [
        -115.62658,
        43.96533
      ],
      [
        -115.62654,
        43.96567
      ],
      [
        -115.62649,
        43.96595
      ],
      [
        -115.62648,
        43.96596
      ],
      [
        -115.62644,
        43.96608
      ],
      [
        -115.6264,
        43.96616
      ],
      [
        -115.62633,
        43.96626
      ],
      [
        -115.62616,
        43.96641
      ],
      [
        -115.62601,
        43.96648
      ],
      [
        -115.62584,
        43.96653
      ],
      [
        -115.62572,
        43.96655
      ],
      [
        -115.62553,
        43.96655
      ],
      [
        -115.62533,
        43.96652
      ],
      [
        -115.62528,
        43.9665
      ],
      [
        -115.62519,
        43.96648
      ],
      [
        -115.62501,
        43.9664
      ],
      [
        -115.6249,
        43.96633
      ],
      [
        -115.6248,
        43.96625
      ],
      [
        -115.62471,
        43.96616
      ],
      [
        -115.62456,
        43.96598
      ],
      [
        -115.62454,
        43.96595
      ],
      [
        -115.62427,
        43.96566
      ],
      [
        -115.62419,
        43.96559
      ],
      [
        -115.62393,
        43.96544
      ],
      [
        -115.62379,
        43.96538
      ],
      [
        -115.6233,
        43.9652
      ],
      [
        -115.62326,
        43.96518
      ],
      [
        -115.62323,
        43.96516
      ],
      [
        -115.62317,
        43.96513
      ],
      [
        -115.62301,
        43.96502
      ],
      [
        -115.62292,
        43.96492
      ],
      [
        -115.62272,
        43.96462
      ],
      [
        -115.62271,
        43.96459
      ],
      [
        -115.62261,
        43.96443
      ],
      [
        -115.6226,
        43.9644
      ],
      [
        -115.6224,
        43.96414
      ],
      [
        -115.62214,
        43.9639
      ],
      [
        -115.62185,
        43.96373
      ],
      [
        -115.62163,
        43.96363
      ],
      [
        -115.62128,
        43.96351
      ],
      [
        -115.62117,
        43.96348
      ],
      [
        -115.62086,
        43.96342
      ],
      [
        -115.61919,
        43.96318
      ],
      [
        -115.61875,
        43.96319
      ],
      [
        -115.61835,
        43.96326
      ],
      [
        -115.61803,
        43.96334
      ],
      [
        -115.61802,
        43.96334
      ],
      [
        -115.6176,
        43.96347
      ],
      [
        -115.61495,
        43.96445
      ],
      [
        -115.61466,
        43.96459
      ],
      [
        -115.61463,
        43.9646
      ],
      [
        -115.61393,
        43.96498
      ],
      [
        -115.61314,
        43.96547
      ],
      [
        -115.61288,
        43.96565
      ],
      [
        -115.61275,
        43.96576
      ],
      [
        -115.61253,
        43.96598
      ],
      [
        -115.61173,
        43.96687
      ],
      [
        -115.61161,
        43.96704
      ],
      [
        -115.61148,
        43.96717
      ],
      [
        -115.61137,
        43.96733
      ],
      [
        -115.61136,
        43.96733
      ],
      [
        -115.61116,
        43.96759
      ],
      [
        -115.61066,
        43.96838
      ],
      [
        -115.61029,
        43.96888
      ],
      [
        -115.6099,
        43.96935
      ],
      [
        -115.60982,
        43.96943
      ],
      [
        -115.60968,
        43.96955
      ],
      [
        -115.60936,
        43.96978
      ],
      [
        -115.60929,
        43.96982
      ],
      [
        -115.60922,
        43.96987
      ],
      [
        -115.60907,
        43.96995
      ],
      [
        -115.60906,
        43.96996
      ],
      [
        -115.60867,
        43.97017
      ],
      [
        -115.60866,
        43.97017
      ],
      [
        -115.6079,
        43.97057
      ],
      [
        -115.60764,
        43.97069
      ],
      [
        -115.60761,
        43.97071
      ],
      [
        -115.60754,
        43.97074
      ],
      [
        -115.60728,
        43.97089
      ],
      [
        -115.60724,
        43.97092
      ],
      [
        -115.60722,
        43.97094
      ],
      [
        -115.60719,
        43.97096
      ],
      [
        -115.60717,
        43.97099
      ],
      [
        -115.60715,
        43.97101
      ],
      [
        -115.60712,
        43.97106
      ],
      [
        -115.60711,
        43.97106
      ],
      [
        -115.60707,
        43.97113
      ],
      [
        -115.60704,
        43.9712
      ],
      [
        -115.60702,
        43.97123
      ],
      [
        -115.60699,
        43.97131
      ],
      [
        -115.60696,
        43.97145
      ],
      [
        -115.60696,
        43.97159
      ],
      [
        -115.60697,
        43.97162
      ],
      [
        -115.60697,
        43.97166
      ],
      [
        -115.60702,
        43.97181
      ],
      [
        -115.60704,
        43.97184
      ],
      [
        -115.60706,
        43.97188
      ],
      [
        -115.60712,
        43.97196
      ],
      [
        -115.60719,
        43.97204
      ],
      [
        -115.6073,
        43.97211
      ],
      [
        -115.60748,
        43.9722
      ],
      [
        -115.60755,
        43.97222
      ],
      [
        -115.60771,
        43.97228
      ],
      [
        -115.60794,
        43.97234
      ],
      [
        -115.608,
        43.97235
      ],
      [
        -115.60828,
        43.97245
      ],
      [
        -115.60838,
        43.9725
      ],
      [
        -115.60846,
        43.97253
      ],
      [
        -115.60859,
        43.97261
      ],
      [
        -115.6086,
        43.97262
      ],
      [
        -115.60875,
        43.97272
      ],
      [
        -115.60893,
        43.97288
      ],
      [
        -115.60903,
        43.97298
      ],
      [
        -115.60911,
        43.97308
      ],
      [
        -115.60922,
        43.97324
      ],
      [
        -115.60927,
        43.97334
      ],
      [
        -115.60927,
        43.97335
      ],
      [
        -115.6093,
        43.97341
      ],
      [
        -115.60934,
        43.97354
      ],
      [
        -115.60939,
        43.97382
      ],
      [
        -115.60939,
        43.97388
      ],
      [
        -115.60942,
        43.9741
      ],
      [
        -115.60965,
        43.97647
      ],
      [
        -115.60966,
        43.97666
      ],
      [
        -115.60966,
        43.97692
      ],
      [
        -115.6096,
        43.97774
      ],
      [
        -115.6095,
        43.97873
      ],
      [
        -115.60949,
        43.97895
      ],
      [
        -115.60943,
        43.97961
      ],
      [
        -115.6094,
        43.97978
      ],
      [
        -115.60938,
        43.97984
      ],
      [
        -115.60937,
        43.97989
      ],
      [
        -115.60919,
        43.98034
      ],
      [
        -115.6091,
        43.98054
      ],
      [
        -115.60896,
        43.98093
      ],
      [
        -115.60864,
        43.98173
      ],
      [
        -115.60861,
        43.98179
      ],
      [
        -115.60805,
        43.98314
      ],
      [
        -115.60794,
        43.98351
      ],
      [
        -115.6079,
        43.9837
      ],
      [
        -115.6079,
        43.98372
      ],
      [
        -115.60785,
        43.98402
      ],
      [
        -115.60785,
        43.98433
      ],
      [
        -115.6079,
        43.98469
      ],
      [
        -115.60792,
        43.98478
      ],
      [
        -115.60792,
        43.9848
      ],
      [
        -115.60803,
        43.98556
      ],
      [
        -115.60806,
        43.98597
      ],
      [
        -115.60806,
        43.98612
      ],
      [
        -115.60805,
        43.98621
      ],
      [
        -115.60805,
        43.9863
      ],
      [
        -115.60804,
        43.98641
      ],
      [
        -115.60802,
        43.98652
      ],
      [
        -115.60801,
        43.98664
      ],
      [
        -115.60795,
        43.98704
      ],
      [
        -115.60791,
        43.98721
      ],
      [
        -115.60788,
        43.98729
      ],
      [
        -115.60766,
        43.98817
      ],
      [
        -115.60752,
        43.98858
      ],
      [
        -115.60741,
        43.98881
      ],
      [
        -115.60737,
        43.98891
      ],
      [
        -115.60659,
        43.99013
      ],
      [
        -115.60595,
        43.99119
      ],
      [
        -115.60559,
        43.99175
      ],
      [
        -115.60553,
        43.99186
      ],
      [
        -115.60518,
        43.99262
      ],
      [
        -115.60514,
        43.99273
      ],
      [
        -115.60505,
        43.99307
      ],
      [
        -115.60504,
        43.99309
      ],
      [
        -115.60498,
        43.99332
      ],
      [
        -115.60461,
        43.99501
      ],
      [
        -115.6046,
        43.99504
      ],
      [
        -115.60456,
        43.99523
      ],
      [
        -115.60442,
        43.9957
      ],
      [
        -115.60442,
        43.99584
      ],
      [
        -115.60448,
        43.99609
      ],
      [
        -115.60452,
        43.9962
      ],
      [
        -115.60457,
        43.99629
      ],
      [
        -115.60467,
        43.99643
      ],
      [
        -115.60477,
        43.99654
      ],
      [
        -115.6048,
        43.99658
      ],
      [
        -115.60481,
        43.99658
      ],
      [
        -115.60489,
        43.99665
      ],
      [
        -115.60514,
        43.99681
      ],
      [
        -115.60518,
        43.99683
      ],
      [
        -115.60535,
        43.99694
      ],
      [
        -115.60555,
        43.99705
      ],
      [
        -115.60556,
        43.99706
      ],
      [
        -115.60572,
        43.99717
      ],
      [
        -115.60589,
        43.99733
      ],
      [
        -115.6059,
        43.99733
      ],
      [
        -115.606,
        43.99746
      ],
      [
        -115.6061,
        43.99762
      ],
      [
        -115.60628,
        43.99798
      ],
      [
        -115.60628,
        43.99799
      ],
      [
        -115.60645,
        43.99841
      ],
      [
        -115.60662,
        43.999
      ],
      [
        -115.60675,
        43.99952
      ],
      [
        -115.60678,
        43.99975
      ],
      [
        -115.60678,
        43.99983
      ],
      [
        -115.60677,
        43.9999
      ],
      [
        -115.60678,
        44.00075
      ],
      [
        -115.60679,
        44.00083
      ],
      [
        -115.6068,
        44.00116
      ],
      [
        -115.60687,
        44.00172
      ],
      [
        -115.60687,
        44.00176
      ],
      [
        -115.60689,
        44.00187
      ],
      [
        -115.60693,
        44.00198
      ],
      [
        -115.60696,
        44.0021
      ],
      [
        -115.60702,
        44.00225
      ],
      [
        -115.60703,
        44.00226
      ],
      [
        -115.6071,
        44.00244
      ],
      [
        -115.60717,
        44.00259
      ],
      [
        -115.60719,
        44.00265
      ],
      [
        -115.60727,
        44.00283
      ],
      [
        -115.60732,
        44.00297
      ],
      [
        -115.60738,
        44.00329
      ],
      [
        -115.6074,
        44.00353
      ],
      [
        -115.60738,
        44.00375
      ],
      [
        -115.60731,
        44.00417
      ],
      [
        -115.6073,
        44.00419
      ],
      [
        -115.60727,
        44.00437
      ],
      [
        -115.60725,
        44.00442
      ],
      [
        -115.60695,
        44.00563
      ],
      [
        -115.60692,
        44.00579
      ],
      [
        -115.60692,
        44.00583
      ],
      [
        -115.60687,
        44.0061
      ],
      [
        -115.60685,
        44.00637
      ],
      [
        -115.60685,
        44.00644
      ],
      [
        -115.60684,
        44.00653
      ],
      [
        -115.60685,
        44.00659
      ],
      [
        -115.60685,
        44.00667
      ],
      [
        -115.60686,
        44.00668
      ],
      [
        -115.60686,
        44.00674
      ],
      [
        -115.60688,
        44.00686
      ],
      [
        -115.6069,
        44.00693
      ],
      [
        -115.60693,
        44.00708
      ],
      [
        -115.60697,
        44.0072
      ],
      [
        -115.60697,
        44.00721
      ],
      [
        -115.60707,
        44.0075
      ],
      [
        -115.60723,
        44.00781
      ],
      [
        -115.60731,
        44.00793
      ],
      [
        -115.6075,
        44.00825
      ],
      [
        -115.60755,
        44.00831
      ],
      [
        -115.60792,
        44.00866
      ],
      [
        -115.60803,
        44.00875
      ],
      [
        -115.60841,
        44.00914
      ],
      [
        -115.60868,
        44.00948
      ],
      [
        -115.60872,
        44.00957
      ],
      [
        -115.60875,
        44.00961
      ],
      [
        -115.60881,
        44.00974
      ],
      [
        -115.60887,
        44.0099
      ],
      [
        -115.60892,
        44.01007
      ],
      [
        -115.60893,
        44.01009
      ],
      [
        -115.609,
        44.01058
      ],
      [
        -115.609,
        44.01063
      ],
      [
        -115.60921,
        44.01271
      ],
      [
        -115.60922,
        44.01296
      ],
      [
        -115.60927,
        44.01354
      ],
      [
        -115.60927,
        44.01375
      ],
      [
        -115.60921,
        44.01407
      ],
      [
        -115.60919,
        44.01414
      ],
      [
        -115.60903,
        44.01514
      ],
      [
        -115.60903,
        44.01518
      ],
      [
        -115.60897,
        44.01565
      ],
      [
        -115.60897,
        44.01567
      ],
      [
        -115.60893,
        44.01592
      ],
      [
        -115.60893,
        44.01603
      ],
      [
        -115.60892,
        44.01606
      ],
      [
        -115.60892,
        44.01618
      ],
      [
        -115.60911,
        44.01826
      ],
      [
        -115.6091,
        44.01836
      ],
      [
        -115.6091,
        44.01844
      ],
      [
        -115.60909,
        44.01852
      ],
      [
        -115.60903,
        44.01875
      ],
      [
        -115.60901,
        44.0188
      ],
      [
        -115.609,
        44.01884
      ],
      [
        -115.60892,
        44.019
      ],
      [
        -115.60885,
        44.01911
      ],
      [
        -115.60872,
        44.01928
      ],
      [
        -115.60867,
        44.01933
      ],
      [
        -115.60856,
        44.01946
      ],
      [
        -115.6084,
        44.01961
      ],
      [
        -115.60835,
        44.01967
      ],
      [
        -115.60822,
        44.0198
      ],
      [
        -115.60821,
        44.01982
      ],
      [
        -115.60818,
        44.01986
      ],
      [
        -115.60813,
        44.01991
      ],
      [
        -115.60802,
        44.02007
      ],
      [
        -115.60796,
        44.02017
      ],
      [
        -115.60782,
        44.02048
      ],
      [
        -115.60781,
        44.02052
      ],
      [
        -115.60777,
        44.02061
      ],
      [
        -115.6076,
        44.02118
      ],
      [
        -115.60759,
        44.02118
      ],
      [
        -115.6075,
        44.02145
      ],
      [
        -115.60744,
        44.02159
      ],
      [
        -115.60729,
        44.02186
      ],
      [
        -115.60728,
        44.02187
      ],
      [
        -115.60725,
        44.02192
      ],
      [
        -115.60721,
        44.02197
      ],
      [
        -115.60713,
        44.02205
      ],
      [
        -115.60678,
        44.02235
      ],
      [
        -115.60621,
        44.02266
      ],
      [
        -115.60554,
        44.02295
      ],
      [
        -115.60545,
        44.02301
      ],
      [
        -115.6054,
        44.02305
      ],
      [
        -115.60538,
        44.02308
      ],
      [
        -115.60537,
        44.02308
      ],
      [
        -115.60535,
        44.02311
      ],
      [
        -115.60533,
        44.02313
      ],
      [
        -115.60532,
        44.02315
      ],
      [
        -115.60529,
        44.02324
      ],
      [
        -115.60529,
        44.02325
      ],
      [
        -115.60528,
        44.02329
      ],
      [
        -115.60528,
        44.02341
      ],
      [
        -115.6053,
        44.02347
      ],
      [
        -115.60535,
        44.02356
      ],
      [
        -115.60539,
        44.0236
      ],
      [
        -115.60539,
        44.02361
      ],
      [
        -115.60557,
        44.02379
      ],
      [
        -115.60565,
        44.02383
      ],
      [
        -115.60575,
        44.02387
      ],
      [
        -115.60593,
        44.0239
      ],
      [
        -115.60608,
        44.0239
      ],
      [
        -115.60618,
        44.02389
      ],
      [
        -115.60623,
        44.02389
      ],
      [
        -115.60637,
        44.02385
      ],
      [
        -115.60649,
        44.0238
      ],
      [
        -115.60662,
        44.02371
      ],
      [
        -115.60667,
        44.02366
      ],
      [
        -115.60673,
        44.02361
      ],
      [
        -115.60706,
        44.02327
      ],
      [
        -115.60723,
        44.02311
      ],
      [
        -115.60744,
        44.02296
      ],
      [
        -115.60761,
        44.02286
      ],
      [
        -115.60771,
        44.02281
      ],
      [
        -115.60788,
        44.02275
      ],
      [
        -115.60811,
        44.02269
      ],
      [
        -115.60832,
        44.02266
      ],
      [
        -115.60881,
        44.02265
      ],
      [
        -115.60907,
        44.02262
      ],
      [
        -115.60918,
        44.02259
      ],
      [
        -115.6092,
        44.02259
      ],
      [
        -115.60945,
        44.02249
      ],
      [
        -115.6095,
        44.02245
      ],
      [
        -115.60958,
        44.02237
      ],
      [
        -115.60964,
        44.02229
      ],
      [
        -115.60967,
        44.02224
      ],
      [
        -115.60971,
        44.02219
      ],
      [
        -115.60976,
        44.02206
      ],
      [
        -115.60984,
        44.02173
      ],
      [
        -115.60984,
        44.02169
      ],
      [
        -115.60988,
        44.02156
      ],
      [
        -115.60989,
        44.02151
      ],
      [
        -115.60994,
        44.02142
      ],
      [
        -115.60999,
        44.02136
      ],
      [
        -115.6101,
        44.02127
      ],
      [
        -115.61022,
        44.02119
      ],
      [
        -115.6103,
        44.02116
      ],
      [
        -115.61039,
        44.02111
      ],
      [
        -115.61049,
        44.02108
      ],
      [
        -115.61064,
        44.02105
      ],
      [
        -115.61084,
        44.02103
      ],
      [
        -115.61093,
        44.02103
      ],
      [
        -115.61113,
        44.02105
      ],
      [
        -115.61166,
        44.02113
      ],
      [
        -115.61197,
        44.02114
      ],
      [
        -115.61208,
        44.02115
      ],
      [
        -115.61217,
        44.02114
      ],
      [
        -115.61266,
        44.02113
      ],
      [
        -115.61287,
        44.02116
      ],
      [
        -115.61291,
        44.02117
      ],
      [
        -115.61298,
        44.02118
      ],
      [
        -115.61318,
        44.02126
      ],
      [
        -115.6134,
        44.02141
      ],
      [
        -115.6135,
        44.02151
      ],
      [
        -115.61359,
        44.02164
      ],
      [
        -115.61362,
        44.0217
      ],
      [
        -115.61364,
        44.02177
      ],
      [
        -115.61368,
        44.02201
      ],
      [
        -115.61369,
        44.02228
      ],
      [
        -115.61371,
        44.02237
      ],
      [
        -115.61371,
        44.02241
      ],
      [
        -115.61374,
        44.0226
      ],
      [
        -115.6138,
        44.02274
      ],
      [
        -115.61384,
        44.02281
      ],
      [
        -115.61394,
        44.02294
      ],
      [
        -115.61401,
        44.02301
      ],
      [
        -115.61416,
        44.02313
      ],
      [
        -115.61444,
        44.02328
      ],
      [
        -115.61453,
        44.02331
      ],
      [
        -115.61459,
        44.02334
      ],
      [
        -115.61479,
        44.02341
      ],
      [
        -115.61498,
        44.0235
      ],
      [
        -115.61513,
        44.02356
      ],
      [
        -115.61521,
        44.0236
      ],
      [
        -115.61523,
        44.02362
      ],
      [
        -115.61533,
        44.02367
      ],
      [
        -115.61553,
        44.0238
      ],
      [
        -115.61563,
        44.02388
      ],
      [
        -115.61597,
        44.0242
      ],
      [
        -115.61606,
        44.02431
      ],
      [
        -115.6162,
        44.02446
      ],
      [
        -115.61639,
        44.02464
      ],
      [
        -115.61648,
        44.02471
      ],
      [
        -115.61667,
        44.0248
      ],
      [
        -115.61684,
        44.02482
      ],
      [
        -115.61696,
        44.02482
      ],
      [
        -115.61703,
        44.02481
      ],
      [
        -115.61719,
        44.02477
      ],
      [
        -115.6173,
        44.02472
      ],
      [
        -115.61735,
        44.02469
      ],
      [
        -115.6174,
        44.02465
      ],
      [
        -115.61746,
        44.02459
      ],
      [
        -115.61754,
        44.02446
      ],
      [
        -115.61756,
        44.0244
      ],
      [
        -115.61759,
        44.02421
      ],
      [
        -115.61759,
        44.02418
      ],
      [
        -115.6176,
        44.02416
      ],
      [
        -115.61761,
        44.02409
      ],
      [
        -115.61765,
        44.02399
      ],
      [
        -115.61773,
        44.02385
      ],
      [
        -115.6178,
        44.02378
      ],
      [
        -115.61796,
        44.02366
      ],
      [
        -115.61804,
        44.02363
      ],
      [
        -115.6181,
        44.0236
      ],
      [
        -115.61811,
        44.02359
      ],
      [
        -115.61816,
        44.02358
      ],
      [
        -115.61828,
        44.02354
      ],
      [
        -115.61838,
        44.02353
      ],
      [
        -115.61846,
        44.02353
      ],
      [
        -115.61853,
        44.02352
      ],
      [
        -115.61866,
        44.02352
      ],
      [
        -115.61886,
        44.02356
      ],
      [
        -115.61893,
        44.02358
      ],
      [
        -115.61912,
        44.02366
      ],
      [
        -115.61925,
        44.02375
      ],
      [
        -115.61936,
        44.02386
      ],
      [
        -115.61941,
        44.02393
      ],
      [
        -115.61946,
        44.02407
      ],
      [
        -115.61946,
        44.02409
      ],
      [
        -115.61948,
        44.02418
      ],
      [
        -115.61947,
        44.02428
      ],
      [
        -115.61947,
        44.02434
      ],
      [
        -115.61939,
        44.02461
      ],
      [
        -115.61938,
        44.02463
      ],
      [
        -115.61935,
        44.02479
      ],
      [
        -115.61935,
        44.02486
      ],
      [
        -115.61938,
        44.025
      ],
      [
        -115.61941,
        44.02506
      ],
      [
        -115.61948,
        44.02517
      ],
      [
        -115.61955,
        44.02526
      ],
      [
        -115.61969,
        44.02537
      ],
      [
        -115.61981,
        44.02544
      ],
      [
        -115.62,
        44.02552
      ],
      [
        -115.62024,
        44.02558
      ],
      [
        -115.62273,
        44.02609
      ],
      [
        -115.62311,
        44.02619
      ],
      [
        -115.62312,
        44.02619
      ],
      [
        -115.6232,
        44.02622
      ],
      [
        -115.6235,
        44.02636
      ],
      [
        -115.62366,
        44.02646
      ],
      [
        -115.62374,
        44.02653
      ],
      [
        -115.62381,
        44.02658
      ],
      [
        -115.62391,
        44.02667
      ],
      [
        -115.62407,
        44.02685
      ],
      [
        -115.62415,
        44.02698
      ],
      [
        -115.62424,
        44.02719
      ],
      [
        -115.62427,
        44.02728
      ],
      [
        -115.62429,
        44.02747
      ],
      [
        -115.62427,
        44.02768
      ],
      [
        -115.62418,
        44.02797
      ],
      [
        -115.62385,
        44.02881
      ],
      [
        -115.62384,
        44.02882
      ],
      [
        -115.62378,
        44.02894
      ],
      [
        -115.62372,
        44.02904
      ],
      [
        -115.62369,
        44.02908
      ],
      [
        -115.62365,
        44.02912
      ],
      [
        -115.6236,
        44.02918
      ],
      [
        -115.62349,
        44.02927
      ],
      [
        -115.62348,
        44.02927
      ],
      [
        -115.62345,
        44.0293
      ],
      [
        -115.62328,
        44.02938
      ],
      [
        -115.62327,
        44.02939
      ],
      [
        -115.62319,
        44.02942
      ],
      [
        -115.6231,
        44.02944
      ],
      [
        -115.6227,
        44.02956
      ],
      [
        -115.62262,
        44.02959
      ],
      [
        -115.62254,
        44.02963
      ],
      [
        -115.62241,
        44.02972
      ],
      [
        -115.62239,
        44.02973
      ],
      [
        -115.62233,
        44.02978
      ],
      [
        -115.62229,
        44.02983
      ],
      [
        -115.62225,
        44.02987
      ],
      [
        -115.62222,
        44.02991
      ],
      [
        -115.62217,
        44.03001
      ],
      [
        -115.62215,
        44.03004
      ],
      [
        -115.62207,
        44.03044
      ],
      [
        -115.62206,
        44.03057
      ],
      [
        -115.62204,
        44.03069
      ],
      [
        -115.62197,
        44.03091
      ],
      [
        -115.62197,
        44.03093
      ],
      [
        -115.62191,
        44.03104
      ],
      [
        -115.62188,
        44.03108
      ],
      [
        -115.62188,
        44.03109
      ],
      [
        -115.62177,
        44.03122
      ],
      [
        -115.62169,
        44.03129
      ],
      [
        -115.6216,
        44.03135
      ],
      [
        -115.62158,
        44.03136
      ],
      [
        -115.6215,
        44.03142
      ],
      [
        -115.62138,
        44.03148
      ],
      [
        -115.62119,
        44.03159
      ],
      [
        -115.62098,
        44.03169
      ],
      [
        -115.62072,
        44.03184
      ],
      [
        -115.62044,
        44.03206
      ],
      [
        -115.6203,
        44.03219
      ],
      [
        -115.62024,
        44.03227
      ],
      [
        -115.62019,
        44.03232
      ],
      [
        -115.62013,
        44.03241
      ],
      [
        -115.61988,
        44.03285
      ],
      [
        -115.61968,
        44.03315
      ],
      [
        -115.61965,
        44.03318
      ],
      [
        -115.61961,
        44.03324
      ],
      [
        -115.61941,
        44.03344
      ],
      [
        -115.61902,
        44.03373
      ],
      [
        -115.61902,
        44.03374
      ],
      [
        -115.61709,
        44.03501
      ],
      [
        -115.61679,
        44.03523
      ],
      [
        -115.6166,
        44.03541
      ],
      [
        -115.61642,
        44.03562
      ],
      [
        -115.6163,
        44.03582
      ],
      [
        -115.61629,
        44.03582
      ],
      [
        -115.61619,
        44.03606
      ],
      [
        -115.61616,
        44.03617
      ],
      [
        -115.61613,
        44.03635
      ],
      [
        -115.61612,
        44.03647
      ],
      [
        -115.61612,
        44.03664
      ],
      [
        -115.61619,
        44.03697
      ],
      [
        -115.61622,
        44.03706
      ],
      [
        -115.61638,
        44.0374
      ],
      [
        -115.61643,
        44.03754
      ],
      [
        -115.61645,
        44.03762
      ],
      [
        -115.61645,
        44.03774
      ],
      [
        -115.61643,
        44.03779
      ],
      [
        -115.61642,
        44.03784
      ],
      [
        -115.61638,
        44.0379
      ],
      [
        -115.61637,
        44.03792
      ],
      [
        -115.61626,
        44.03803
      ],
      [
        -115.61618,
        44.03809
      ],
      [
        -115.61608,
        44.03814
      ],
      [
        -115.61601,
        44.03816
      ],
      [
        -115.61599,
        44.03817
      ],
      [
        -115.61593,
        44.03819
      ],
      [
        -115.61581,
        44.03821
      ],
      [
        -115.61574,
        44.03821
      ],
      [
        -115.61567,
        44.03822
      ],
      [
        -115.61506,
        44.03824
      ],
      [
        -115.61469,
        44.03823
      ],
      [
        -115.61462,
        44.03822
      ],
      [
        -115.61445,
        44.03818
      ],
      [
        -115.61431,
        44.03813
      ],
      [
        -115.6142,
        44.03807
      ],
      [
        -115.61416,
        44.03803
      ],
      [
        -115.61412,
        44.038
      ],
      [
        -115.61412,
        44.03799
      ],
      [
        -115.61408,
        44.03795
      ],
      [
        -115.61404,
        44.03789
      ],
      [
        -115.61399,
        44.0378
      ],
      [
        -115.61391,
        44.03756
      ],
      [
        -115.61378,
        44.03707
      ],
      [
        -115.61375,
        44.03689
      ],
      [
        -115.61376,
        44.03682
      ],
      [
        -115.61376,
        44.03676
      ],
      [
        -115.61381,
        44.03661
      ],
      [
        -115.61386,
        44.03652
      ],
      [
        -115.61391,
        44.03646
      ],
      [
        -115.61397,
        44.0364
      ],
      [
        -115.61409,
        44.03631
      ],
      [
        -115.61418,
        44.03626
      ],
      [
        -115.61437,
        44.03617
      ],
      [
        -115.61459,
        44.03608
      ],
      [
        -115.61467,
        44.03604
      ],
      [
        -115.61475,
        44.03601
      ],
      [
        -115.61492,
        44.03592
      ],
      [
        -115.61502,
        44.03585
      ],
      [
        -115.61517,
        44.03573
      ],
      [
        -115.61524,
        44.03566
      ],
      [
        -115.61529,
        44.03559
      ],
      [
        -115.61534,
        44.03554
      ],
      [
        -115.61541,
        44.03543
      ],
      [
        -115.61542,
        44.0354
      ],
      [
        -115.61547,
        44.0353
      ],
      [
        -115.61554,
        44.03511
      ],
      [
        -115.61572,
        44.03474
      ],
      [
        -115.61581,
        44.03459
      ],
      [
        -115.61587,
        44.03451
      ],
      [
        -115.61594,
        44.03443
      ],
      [
        -115.61594,
        44.03442
      ],
      [
        -115.61602,
        44.03434
      ],
      [
        -115.61615,
        44.03424
      ],
      [
        -115.61624,
        44.03418
      ],
      [
        -115.61651,
        44.03404
      ],
      [
        -115.6167,
        44.03396
      ],
      [
        -115.6173,
        44.03378
      ],
      [
        -115.61739,
        44.03376
      ],
      [
        -115.61744,
        44.03374
      ],
      [
        -115.61755,
        44.03371
      ],
      [
        -115.61762,
        44.03368
      ],
      [
        -115.61771,
        44.03363
      ],
      [
        -115.6178,
        44.03354
      ],
      [
        -115.61785,
        44.03348
      ],
      [
        -115.61789,
        44.03338
      ],
      [
        -115.61791,
        44.03327
      ],
      [
        -115.6179,
        44.0332
      ],
      [
        -115.61788,
        44.03314
      ],
      [
        -115.61785,
        44.03308
      ],
      [
        -115.61775,
        44.03298
      ],
      [
        -115.61764,
        44.0329
      ],
      [
        -115.61754,
        44.03285
      ],
      [
        -115.61741,
        44.03281
      ],
      [
        -115.61718,
        44.03279
      ],
      [
        -115.61695,
        44.03283
      ],
      [
        -115.61694,
        44.03284
      ],
      [
        -115.61649,
        44.03295
      ],
      [
        -115.616,
        44.03309
      ],
      [
        -115.61595,
        44.0331
      ],
      [
        -115.61558,
        44.03321
      ],
      [
        -115.61388,
        44.03363
      ],
      [
        -115.61286,
        44.03395
      ],
      [
        -115.61253,
        44.0341
      ],
      [
        -115.6125,
        44.03411
      ],
      [
        -115.61239,
        44.03418
      ],
      [
        -115.6123,
        44.03425
      ],
      [
        -115.61217,
        44.03438
      ],
      [
        -115.61211,
        44.03445
      ],
      [
        -115.61195,
        44.03468
      ],
      [
        -115.6119,
        44.03477
      ],
      [
        -115.61186,
        44.03481
      ],
      [
        -115.61182,
        44.03483
      ],
      [
        -115.61177,
        44.03487
      ],
      [
        -115.61173,
        44.03489
      ],
      [
        -115.61103,
        44.03513
      ],
      [
        -115.61094,
        44.03517
      ],
      [
        -115.61093,
        44.03517
      ],
      [
        -115.61078,
        44.03525
      ],
      [
        -115.61069,
        44.03534
      ],
      [
        -115.61068,
        44.03537
      ],
      [
        -115.61066,
        44.0354
      ],
      [
        -115.61064,
        44.03546
      ],
      [
        -115.61063,
        44.03551
      ],
      [
        -115.61062,
        44.03554
      ],
      [
        -115.61061,
        44.03562
      ],
      [
        -115.61061,
        44.03568
      ],
      [
        -115.61066,
        44.03582
      ],
      [
        -115.61071,
        44.03591
      ],
      [
        -115.61079,
        44.03608
      ],
      [
        -115.61082,
        44.03613
      ],
      [
        -115.61088,
        44.03627
      ],
      [
        -115.611,
        44.03665
      ],
      [
        -115.611,
        44.0367
      ],
      [
        -115.61101,
        44.03678
      ],
      [
        -115.611,
        44.03684
      ],
      [
        -115.611,
        44.03687
      ],
      [
        -115.61098,
        44.03697
      ],
      [
        -115.61095,
        44.03705
      ],
      [
        -115.61091,
        44.03713
      ],
      [
        -115.61084,
        44.03722
      ],
      [
        -115.6106,
        44.03749
      ],
      [
        -115.61036,
        44.03779
      ],
      [
        -115.61026,
        44.03795
      ],
      [
        -115.61025,
        44.03796
      ],
      [
        -115.61023,
        44.03802
      ],
      [
        -115.61022,
        44.0381
      ],
      [
        -115.61022,
        44.03824
      ],
      [
        -115.61025,
        44.0386
      ],
      [
        -115.61027,
        44.03868
      ],
      [
        -115.61027,
        44.03871
      ],
      [
        -115.61029,
        44.03878
      ],
      [
        -115.61032,
        44.03883
      ],
      [
        -115.61041,
        44.03902
      ],
      [
        -115.61054,
        44.03922
      ],
      [
        -115.61058,
        44.03936
      ],
      [
        -115.61058,
        44.03938
      ],
      [
        -115.61059,
        44.03942
      ],
      [
        -115.61056,
        44.03958
      ],
      [
        -115.61054,
        44.03961
      ],
      [
        -115.61051,
        44.03968
      ],
      [
        -115.61044,
        44.03976
      ],
      [
        -115.61039,
        44.03984
      ],
      [
        -115.61034,
        44.03997
      ],
      [
        -115.61036,
        44.04007
      ],
      [
        -115.61037,
        44.0401
      ],
      [
        -115.61045,
        44.04021
      ],
      [
        -115.61049,
        44.04024
      ],
      [
        -115.61054,
        44.04029
      ],
      [
        -115.61061,
        44.04034
      ],
      [
        -115.61062,
        44.04035
      ],
      [
        -115.61079,
        44.04046
      ],
      [
        -115.61097,
        44.04055
      ],
      [
        -115.61107,
        44.04061
      ],
      [
        -115.61114,
        44.04067
      ],
      [
        -115.61125,
        44.04075
      ],
      [
        -115.61129,
        44.0408
      ],
      [
        -115.61132,
        44.04082
      ],
      [
        -115.61135,
        44.04085
      ],
      [
        -115.61138,
        44.04093
      ],
      [
        -115.61139,
        44.04101
      ],
      [
        -115.6114,
        44.04145
      ],
      [
        -115.61142,
        44.04151
      ],
      [
        -115.61151,
        44.04169
      ],
      [
        -115.61153,
        44.04171
      ],
      [
        -115.61159,
        44.04181
      ],
      [
        -115.61165,
        44.04193
      ],
      [
        -115.61167,
        44.04199
      ],
      [
        -115.61169,
        44.0421
      ],
      [
        -115.61169,
        44.04226
      ],
      [
        -115.61162,
        44.04245
      ],
      [
        -115.61158,
        44.04253
      ],
      [
        -115.6115,
        44.04263
      ],
      [
        -115.61121,
        44.04285
      ],
      [
        -115.61112,
        44.04294
      ],
      [
        -115.6111,
        44.04298
      ],
      [
        -115.61107,
        44.04302
      ],
      [
        -115.61103,
        44.04318
      ],
      [
        -115.61102,
        44.04329
      ],
      [
        -115.61109,
        44.04363
      ],
      [
        -115.61109,
        44.04373
      ],
      [
        -115.61107,
        44.04381
      ],
      [
        -115.61106,
        44.04388
      ],
      [
        -115.61096,
        44.0441
      ],
      [
        -115.61094,
        44.04424
      ],
      [
        -115.61094,
        44.04433
      ],
      [
        -115.61095,
        44.04442
      ],
      [
        -115.61099,
        44.04458
      ],
      [
        -115.61118,
        44.04488
      ],
      [
        -115.61122,
        44.04498
      ],
      [
        -115.61122,
        44.04505
      ],
      [
        -115.61121,
        44.04512
      ],
      [
        -115.61117,
        44.04527
      ],
      [
        -115.61108,
        44.04545
      ],
      [
        -115.61098,
        44.0456
      ],
      [
        -115.61087,
        44.04579
      ],
      [
        -115.61085,
        44.0459
      ],
      [
        -115.61086,
        44.04614
      ],
      [
        -115.61085,
        44.04629
      ],
      [
        -115.61092,
        44.04695
      ],
      [
        -115.61093,
        44.0472
      ],
      [
        -115.61094,
        44.0472
      ],
      [
        -115.61096,
        44.04738
      ],
      [
        -115.611,
        44.0476
      ],
      [
        -115.61113,
        44.04791
      ],
      [
        -115.61119,
        44.04801
      ],
      [
        -115.61125,
        44.04809
      ],
      [
        -115.61158,
        44.04848
      ],
      [
        -115.61184,
        44.04875
      ],
      [
        -115.61189,
        44.04884
      ],
      [
        -115.61192,
        44.04887
      ],
      [
        -115.61206,
        44.04914
      ],
      [
        -115.61211,
        44.04926
      ],
      [
        -115.61215,
        44.04942
      ],
      [
        -115.61216,
        44.04952
      ],
      [
        -115.61218,
        44.04959
      ],
      [
        -115.61218,
        44.04972
      ],
      [
        -115.61214,
        44.05
      ],
      [
        -115.61207,
        44.05026
      ],
      [
        -115.61212,
        44.05078
      ],
      [
        -115.61214,
        44.05089
      ],
      [
        -115.61216,
        44.05096
      ],
      [
        -115.61224,
        44.05112
      ],
      [
        -115.61229,
        44.05119
      ],
      [
        -115.61237,
        44.05127
      ],
      [
        -115.61251,
        44.05138
      ],
      [
        -115.61263,
        44.05145
      ],
      [
        -115.61285,
        44.05155
      ],
      [
        -115.61296,
        44.05161
      ],
      [
        -115.61337,
        44.05179
      ],
      [
        -115.61359,
        44.0519
      ],
      [
        -115.61364,
        44.05192
      ],
      [
        -115.61379,
        44.05201
      ],
      [
        -115.6138,
        44.05202
      ],
      [
        -115.61391,
        44.05209
      ],
      [
        -115.614,
        44.05216
      ],
      [
        -115.61413,
        44.05231
      ],
      [
        -115.61418,
        44.05239
      ],
      [
        -115.61434,
        44.0526
      ],
      [
        -115.61436,
        44.05262
      ],
      [
        -115.61456,
        44.05296
      ],
      [
        -115.61499,
        44.05349
      ],
      [
        -115.61514,
        44.05366
      ],
      [
        -115.61621,
        44.05464
      ],
      [
        -115.61625,
        44.0547
      ],
      [
        -115.61627,
        44.05472
      ],
      [
        -115.61637,
        44.05486
      ],
      [
        -115.61642,
        44.05495
      ],
      [
        -115.61652,
        44.0552
      ],
      [
        -115.61652,
        44.05521
      ],
      [
        -115.61656,
        44.05535
      ],
      [
        -115.61658,
        44.05549
      ],
      [
        -115.61658,
        44.05564
      ],
      [
        -115.61662,
        44.05594
      ],
      [
        -115.61668,
        44.05614
      ],
      [
        -115.61673,
        44.05625
      ],
      [
        -115.61674,
        44.05626
      ],
      [
        -115.61678,
        44.05633
      ],
      [
        -115.61689,
        44.05648
      ],
      [
        -115.61703,
        44.05663
      ],
      [
        -115.61715,
        44.05678
      ],
      [
        -115.6172,
        44.05686
      ],
      [
        -115.61723,
        44.05693
      ],
      [
        -115.61724,
        44.05694
      ],
      [
        -115.61725,
        44.05698
      ],
      [
        -115.61727,
        44.05703
      ],
      [
        -115.61729,
        44.05713
      ],
      [
        -115.61729,
        44.05719
      ],
      [
        -115.61728,
        44.05728
      ],
      [
        -115.61692,
        44.05803
      ],
      [
        -115.61676,
        44.05824
      ],
      [
        -115.61665,
        44.05836
      ],
      [
        -115.61639,
        44.05853
      ],
      [
        -115.61634,
        44.05853
      ],
      [
        -115.61625,
        44.05854
      ],
      [
        -115.6161,
        44.05854
      ],
      [
        -115.61592,
        44.05852
      ],
      [
        -115.61568,
        44.05848
      ],
      [
        -115.61538,
        44.0584
      ],
      [
        -115.61537,
        44.0584
      ],
      [
        -115.61518,
        44.05833
      ],
      [
        -115.61497,
        44.05821
      ],
      [
        -115.61461,
        44.05788
      ],
      [
        -115.61448,
        44.05778
      ],
      [
        -115.61437,
        44.05772
      ],
      [
        -115.61428,
        44.05768
      ],
      [
        -115.61424,
        44.05767
      ],
      [
        -115.61413,
        44.05763
      ],
      [
        -115.61399,
        44.0576
      ],
      [
        -115.61375,
        44.05759
      ],
      [
        -115.6134,
        44.05763
      ],
      [
        -115.61307,
        44.05765
      ],
      [
        -115.61291,
        44.05762
      ],
      [
        -115.61275,
        44.05757
      ],
      [
        -115.61257,
        44.05746
      ],
      [
        -115.61216,
        44.05708
      ],
      [
        -115.6121,
        44.05699
      ],
      [
        -115.61208,
        44.05683
      ],
      [
        -115.61209,
        44.05666
      ],
      [
        -115.61213,
        44.05636
      ],
      [
        -115.61213,
        44.05634
      ],
      [
        -115.6121,
        44.05617
      ],
      [
        -115.61199,
        44.05601
      ],
      [
        -115.61186,
        44.05587
      ],
      [
        -115.61169,
        44.05574
      ],
      [
        -115.6115,
        44.05562
      ],
      [
        -115.6112,
        44.05548
      ],
      [
        -115.61096,
        44.05539
      ],
      [
        -115.6107,
        44.05532
      ],
      [
        -115.61039,
        44.05528
      ],
      [
        -115.60961,
        44.05527
      ],
      [
        -115.60947,
        44.05526
      ],
      [
        -115.60922,
        44.05526
      ],
      [
        -115.60898,
        44.05523
      ],
      [
        -115.60842,
        44.05511
      ],
      [
        -115.60803,
        44.055
      ],
      [
        -115.60783,
        44.05492
      ],
      [
        -115.60768,
        44.05482
      ],
      [
        -115.60757,
        44.05471
      ],
      [
        -115.60749,
        44.05454
      ],
      [
        -115.60744,
        44.05437
      ],
      [
        -115.60742,
        44.05425
      ],
      [
        -115.60738,
        44.05417
      ],
      [
        -115.60734,
        44.05411
      ],
      [
        -115.60726,
        44.05404
      ],
      [
        -115.60721,
        44.05401
      ],
      [
        -115.607,
        44.05392
      ],
      [
        -115.60662,
        44.05382
      ],
      [
        -115.60639,
        44.05378
      ],
      [
        -115.60597,
        44.05374
      ],
      [
        -115.60535,
        44.05375
      ],
      [
        -115.60514,
        44.05377
      ],
      [
        -115.60509,
        44.05378
      ],
      [
        -115.60496,
        44.05379
      ],
      [
        -115.6048,
        44.05382
      ],
      [
        -115.60425,
        44.05395
      ],
      [
        -115.60412,
        44.05396
      ],
      [
        -115.60381,
        44.05393
      ],
      [
        -115.60372,
        44.05389
      ],
      [
        -115.60357,
        44.0538
      ],
      [
        -115.60337,
        44.05365
      ],
      [
        -115.60321,
        44.05355
      ],
      [
        -115.60306,
        44.05349
      ],
      [
        -115.60287,
        44.05343
      ],
      [
        -115.60238,
        44.05337
      ],
      [
        -115.60233,
        44.05337
      ],
      [
        -115.60202,
        44.05327
      ],
      [
        -115.60193,
        44.05322
      ],
      [
        -115.60176,
        44.05309
      ],
      [
        -115.60166,
        44.05299
      ],
      [
        -115.60159,
        44.05286
      ],
      [
        -115.60143,
        44.05263
      ],
      [
        -115.60142,
        44.05261
      ],
      [
        -115.60116,
        44.0523
      ],
      [
        -115.60098,
        44.05211
      ],
      [
        -115.60084,
        44.05202
      ],
      [
        -115.6008,
        44.052
      ],
      [
        -115.60074,
        44.05196
      ],
      [
        -115.60069,
        44.05195
      ],
      [
        -115.60064,
        44.05193
      ],
      [
        -115.60049,
        44.05193
      ],
      [
        -115.60041,
        44.05194
      ],
      [
        -115.60039,
        44.05195
      ],
      [
        -115.60036,
        44.05195
      ],
      [
        -115.60025,
        44.05199
      ],
      [
        -115.60019,
        44.05202
      ],
      [
        -115.60006,
        44.05211
      ],
      [
        -115.6,
        44.05216
      ],
      [
        -115.59999,
        44.05218
      ],
      [
        -115.59995,
        44.05224
      ],
      [
        -115.59993,
        44.05229
      ],
      [
        -115.59992,
        44.05234
      ],
      [
        -115.59992,
        44.05238
      ],
      [
        -115.59993,
        44.05242
      ],
      [
        -115.59999,
        44.05253
      ],
      [
        -115.60008,
        44.05267
      ],
      [
        -115.60008,
        44.05269
      ],
      [
        -115.60011,
        44.05276
      ],
      [
        -115.60013,
        44.05283
      ],
      [
        -115.60014,
        44.0529
      ],
      [
        -115.60014,
        44.05301
      ],
      [
        -115.60018,
        44.05356
      ],
      [
        -115.6002,
        44.0537
      ],
      [
        -115.60022,
        44.05377
      ],
      [
        -115.60025,
        44.05384
      ],
      [
        -115.60033,
        44.05396
      ],
      [
        -115.60043,
        44.05406
      ],
      [
        -115.60067,
        44.05422
      ],
      [
        -115.60139,
        44.05457
      ],
      [
        -115.60207,
        44.05484
      ],
      [
        -115.60232,
        44.05497
      ],
      [
        -115.6024,
        44.05502
      ],
      [
        -115.60265,
        44.05525
      ],
      [
        -115.60289,
        44.05543
      ],
      [
        -115.60326,
        44.05564
      ],
      [
        -115.60342,
        44.05572
      ],
      [
        -115.6036,
        44.05584
      ],
      [
        -115.60376,
        44.05596
      ],
      [
        -115.60387,
        44.05608
      ],
      [
        -115.60401,
        44.05631
      ],
      [
        -115.60411,
        44.05645
      ],
      [
        -115.60423,
        44.05659
      ],
      [
        -115.60437,
        44.05672
      ],
      [
        -115.60455,
        44.05683
      ],
      [
        -115.60474,
        44.05692
      ],
      [
        -115.60493,
        44.05698
      ],
      [
        -115.60514,
        44.05706
      ],
      [
        -115.60541,
        44.05718
      ],
      [
        -115.60557,
        44.05728
      ],
      [
        -115.60573,
        44.0574
      ],
      [
        -115.60609,
        44.0577
      ],
      [
        -115.6064,
        44.058
      ],
      [
        -115.60652,
        44.05823
      ],
      [
        -115.60666,
        44.05863
      ],
      [
        -115.60675,
        44.05884
      ],
      [
        -115.6068,
        44.05903
      ],
      [
        -115.60687,
        44.05936
      ],
      [
        -115.60703,
        44.05994
      ],
      [
        -115.60711,
        44.06034
      ],
      [
        -115.60712,
        44.06037
      ],
      [
        -115.60719,
        44.06069
      ],
      [
        -115.60721,
        44.06083
      ],
      [
        -115.60721,
        44.06094
      ],
      [
        -115.60718,
        44.06107
      ],
      [
        -115.60712,
        44.06121
      ],
      [
        -115.60711,
        44.06122
      ],
      [
        -115.60709,
        44.06125
      ],
      [
        -115.60681,
        44.06156
      ],
      [
        -115.60664,
        44.0618
      ],
      [
        -115.60655,
        44.06199
      ],
      [
        -115.60645,
        44.06216
      ],
      [
        -115.60632,
        44.06228
      ],
      [
        -115.60615,
        44.06238
      ],
      [
        -115.606,
        44.06244
      ],
      [
        -115.60571,
        44.06248
      ],
      [
        -115.60535,
        44.06251
      ],
      [
        -115.60506,
        44.06255
      ],
      [
        -115.60408,
        44.0628
      ],
      [
        -115.60403,
        44.06282
      ],
      [
        -115.60352,
        44.06295
      ],
      [
        -115.60328,
        44.06304
      ],
      [
        -115.60313,
        44.06313
      ],
      [
        -115.60302,
        44.06321
      ],
      [
        -115.60295,
        44.06327
      ],
      [
        -115.60291,
        44.06332
      ],
      [
        -115.60289,
        44.06339
      ],
      [
        -115.60289,
        44.06347
      ],
      [
        -115.60291,
        44.06356
      ],
      [
        -115.60293,
        44.0636
      ],
      [
        -115.603,
        44.06368
      ],
      [
        -115.60312,
        44.06374
      ],
      [
        -115.60332,
        44.0638
      ],
      [
        -115.60349,
        44.06382
      ],
      [
        -115.60407,
        44.06385
      ],
      [
        -115.60413,
        44.06386
      ],
      [
        -115.60434,
        44.06387
      ],
      [
        -115.60452,
        44.06391
      ],
      [
        -115.60472,
        44.06399
      ],
      [
        -115.60478,
        44.06403
      ],
      [
        -115.60492,
        44.0641
      ],
      [
        -115.60496,
        44.06414
      ],
      [
        -115.60502,
        44.06419
      ],
      [
        -115.6051,
        44.06429
      ],
      [
        -115.60515,
        44.06439
      ],
      [
        -115.60517,
        44.06446
      ],
      [
        -115.60517,
        44.06449
      ],
      [
        -115.6052,
        44.0646
      ],
      [
        -115.6052,
        44.06471
      ],
      [
        -115.60519,
        44.06481
      ],
      [
        -115.60514,
        44.06495
      ],
      [
        -115.60504,
        44.06515
      ],
      [
        -115.60498,
        44.06534
      ],
      [
        -115.60499,
        44.06546
      ],
      [
        -115.60504,
        44.0656
      ],
      [
        -115.6051,
        44.06568
      ],
      [
        -115.60514,
        44.06572
      ],
      [
        -115.60523,
        44.06579
      ],
      [
        -115.6056,
        44.06594
      ],
      [
        -115.60572,
        44.06597
      ],
      [
        -115.6059,
        44.06605
      ],
      [
        -115.606,
        44.06611
      ],
      [
        -115.60608,
        44.06617
      ],
      [
        -115.60616,
        44.06632
      ],
      [
        -115.60616,
        44.06637
      ],
      [
        -115.60617,
        44.06641
      ],
      [
        -115.60617,
        44.0665
      ],
      [
        -115.60611,
        44.06665
      ],
      [
        -115.60599,
        44.06679
      ],
      [
        -115.60575,
        44.06703
      ],
      [
        -115.60565,
        44.06716
      ],
      [
        -115.60559,
        44.06727
      ],
      [
        -115.60556,
        44.06736
      ],
      [
        -115.60556,
        44.06743
      ],
      [
        -115.60558,
        44.0675
      ],
      [
        -115.60569,
        44.06764
      ],
      [
        -115.60585,
        44.06773
      ],
      [
        -115.60592,
        44.06776
      ],
      [
        -115.60601,
        44.06779
      ],
      [
        -115.60639,
        44.06786
      ],
      [
        -115.6064,
        44.06786
      ],
      [
        -115.60662,
        44.06793
      ],
      [
        -115.60663,
        44.06793
      ],
      [
        -115.60674,
        44.068
      ],
      [
        -115.60681,
        44.06806
      ],
      [
        -115.60683,
        44.06807
      ],
      [
        -115.60689,
        44.06815
      ],
      [
        -115.60692,
        44.06822
      ],
      [
        -115.60693,
        44.06822
      ],
      [
        -115.60696,
        44.06836
      ],
      [
        -115.60698,
        44.06855
      ],
      [
        -115.60699,
        44.06875
      ],
      [
        -115.60701,
        44.06893
      ],
      [
        -115.60703,
        44.06942
      ],
      [
        -115.607,
        44.06958
      ],
      [
        -115.60696,
        44.06966
      ],
      [
        -115.60684,
        44.06982
      ],
      [
        -115.60671,
        44.0699
      ],
      [
        -115.60652,
        44.06996
      ],
      [
        -115.60602,
        44.07008
      ],
      [
        -115.60596,
        44.07011
      ],
      [
        -115.60581,
        44.07017
      ],
      [
        -115.60567,
        44.07025
      ],
      [
        -115.60558,
        44.07036
      ],
      [
        -115.60551,
        44.07052
      ],
      [
        -115.60532,
        44.07085
      ],
      [
        -115.60519,
        44.07101
      ],
      [
        -115.60504,
        44.07115
      ],
      [
        -115.60501,
        44.07117
      ],
      [
        -115.60462,
        44.07149
      ],
      [
        -115.60451,
        44.07161
      ],
      [
        -115.60444,
        44.07171
      ],
      [
        -115.60441,
        44.07186
      ],
      [
        -115.60441,
        44.07192
      ],
      [
        -115.60443,
        44.07196
      ],
      [
        -115.60445,
        44.07202
      ],
      [
        -115.6045,
        44.07208
      ],
      [
        -115.60461,
        44.07218
      ],
      [
        -115.60474,
        44.07227
      ],
      [
        -115.60484,
        44.07232
      ],
      [
        -115.60502,
        44.07238
      ],
      [
        -115.6053,
        44.07245
      ],
      [
        -115.60588,
        44.07257
      ],
      [
        -115.60651,
        44.07268
      ],
      [
        -115.60732,
        44.07287
      ],
      [
        -115.60744,
        44.07291
      ],
      [
        -115.60761,
        44.073
      ],
      [
        -115.60769,
        44.07306
      ],
      [
        -115.60775,
        44.07312
      ],
      [
        -115.60779,
        44.07318
      ],
      [
        -115.60786,
        44.07333
      ],
      [
        -115.60788,
        44.07339
      ],
      [
        -115.60788,
        44.07341
      ],
      [
        -115.60787,
        44.07348
      ],
      [
        -115.60777,
        44.07376
      ],
      [
        -115.60776,
        44.07376
      ],
      [
        -115.60766,
        44.0741
      ],
      [
        -115.60762,
        44.07418
      ],
      [
        -115.60758,
        44.07424
      ],
      [
        -115.60701,
        44.07468
      ],
      [
        -115.60672,
        44.07484
      ],
      [
        -115.60653,
        44.07496
      ],
      [
        -115.60644,
        44.07504
      ],
      [
        -115.60637,
        44.07515
      ],
      [
        -115.60636,
        44.07519
      ],
      [
        -115.60635,
        44.07521
      ],
      [
        -115.60635,
        44.07522
      ],
      [
        -115.60633,
        44.07528
      ],
      [
        -115.60633,
        44.07535
      ],
      [
        -115.60634,
        44.07539
      ],
      [
        -115.60637,
        44.07546
      ],
      [
        -115.6064,
        44.07551
      ],
      [
        -115.60648,
        44.07559
      ],
      [
        -115.60656,
        44.07564
      ],
      [
        -115.60666,
        44.07569
      ],
      [
        -115.60675,
        44.07571
      ],
      [
        -115.60681,
        44.07571
      ],
      [
        -115.60685,
        44.07572
      ],
      [
        -115.607,
        44.07571
      ],
      [
        -115.60715,
        44.07568
      ],
      [
        -115.60727,
        44.07562
      ],
      [
        -115.60728,
        44.07562
      ],
      [
        -115.60743,
        44.07553
      ],
      [
        -115.60753,
        44.07541
      ],
      [
        -115.60766,
        44.07517
      ],
      [
        -115.60768,
        44.0751
      ],
      [
        -115.60768,
        44.0749
      ],
      [
        -115.60771,
        44.07467
      ],
      [
        -115.60773,
        44.07463
      ],
      [
        -115.60776,
        44.07455
      ],
      [
        -115.60788,
        44.07434
      ],
      [
        -115.60855,
        44.0734
      ],
      [
        -115.6086,
        44.07334
      ],
      [
        -115.60877,
        44.07306
      ],
      [
        -115.60879,
        44.07301
      ],
      [
        -115.60882,
        44.0729
      ],
      [
        -115.60884,
        44.07277
      ],
      [
        -115.60881,
        44.07272
      ],
      [
        -115.60878,
        44.07268
      ],
      [
        -115.60877,
        44.07266
      ],
      [
        -115.60873,
        44.07261
      ],
      [
        -115.60868,
        44.07256
      ],
      [
        -115.60864,
        44.07254
      ],
      [
        -115.60861,
        44.07252
      ],
      [
        -115.60859,
        44.0725
      ],
      [
        -115.6084,
        44.07243
      ],
      [
        -115.60816,
        44.07238
      ],
      [
        -115.60813,
        44.07238
      ],
      [
        -115.60794,
        44.07234
      ],
      [
        -115.60792,
        44.07233
      ],
      [
        -115.60782,
        44.07231
      ],
      [
        -115.60762,
        44.07225
      ],
      [
        -115.60748,
        44.07218
      ],
      [
        -115.60743,
        44.07214
      ],
      [
        -115.60738,
        44.07209
      ],
      [
        -115.60735,
        44.07202
      ],
      [
        -115.60735,
        44.07201
      ],
      [
        -115.60733,
        44.07196
      ],
      [
        -115.6073,
        44.07182
      ],
      [
        -115.6073,
        44.07171
      ],
      [
        -115.60731,
        44.07167
      ],
      [
        -115.60732,
        44.07166
      ],
      [
        -115.60735,
        44.0716
      ],
      [
        -115.60747,
        44.07143
      ],
      [
        -115.60759,
        44.07132
      ],
      [
        -115.60773,
        44.07122
      ],
      [
        -115.60788,
        44.07108
      ],
      [
        -115.60789,
        44.07108
      ],
      [
        -115.608,
        44.07095
      ],
      [
        -115.6081,
        44.07076
      ],
      [
        -115.60817,
        44.0706
      ],
      [
        -115.60818,
        44.07059
      ],
      [
        -115.60824,
        44.07049
      ],
      [
        -115.60852,
        44.07011
      ],
      [
        -115.60897,
        44.06961
      ],
      [
        -115.609,
        44.06955
      ],
      [
        -115.60901,
        44.0695
      ],
      [
        -115.60903,
        44.0693
      ],
      [
        -115.60901,
        44.06905
      ],
      [
        -115.60899,
        44.06855
      ],
      [
        -115.609,
        44.06845
      ],
      [
        -115.60903,
        44.06837
      ],
      [
        -115.60906,
        44.06831
      ],
      [
        -115.6091,
        44.06826
      ],
      [
        -115.60919,
        44.06817
      ],
      [
        -115.60929,
        44.06811
      ],
      [
        -115.60939,
        44.06806
      ],
      [
        -115.60956,
        44.06799
      ],
      [
        -115.60978,
        44.06795
      ],
      [
        -115.6098,
        44.06795
      ],
      [
        -115.60993,
        44.06797
      ],
      [
        -115.61002,
        44.068
      ],
      [
        -115.61005,
        44.06802
      ],
      [
        -115.61011,
        44.06805
      ],
      [
        -115.61019,
        44.06813
      ],
      [
        -115.61023,
        44.06818
      ],
      [
        -115.61028,
        44.06828
      ],
      [
        -115.61035,
        44.06857
      ],
      [
        -115.61044,
        44.06906
      ],
      [
        -115.61051,
        44.06935
      ],
      [
        -115.61056,
        44.06965
      ],
      [
        -115.61055,
        44.06981
      ],
      [
        -115.61052,
        44.06989
      ],
      [
        -115.61049,
        44.07003
      ],
      [
        -115.61019,
        44.07065
      ],
      [
        -115.61016,
        44.07075
      ],
      [
        -115.61015,
        44.07087
      ],
      [
        -115.61016,
        44.07095
      ],
      [
        -115.61018,
        44.07103
      ],
      [
        -115.61024,
        44.0711
      ],
      [
        -115.61024,
        44.07111
      ],
      [
        -115.61034,
        44.07123
      ],
      [
        -115.61044,
        44.07131
      ],
      [
        -115.61114,
        44.07178
      ],
      [
        -115.61208,
        44.07255
      ],
      [
        -115.61243,
        44.07282
      ],
      [
        -115.61299,
        44.07313
      ],
      [
        -115.6131,
        44.07318
      ],
      [
        -115.61403,
        44.07369
      ],
      [
        -115.6142,
        44.07382
      ],
      [
        -115.6145,
        44.07409
      ],
      [
        -115.6147,
        44.07429
      ],
      [
        -115.61492,
        44.07454
      ],
      [
        -115.61514,
        44.07487
      ],
      [
        -115.61527,
        44.0751
      ],
      [
        -115.6154,
        44.07538
      ],
      [
        -115.61551,
        44.07558
      ],
      [
        -115.61554,
        44.07581
      ],
      [
        -115.61566,
        44.07632
      ],
      [
        -115.61567,
        44.07639
      ],
      [
        -115.61586,
        44.07708
      ],
      [
        -115.61594,
        44.07719
      ],
      [
        -115.61602,
        44.07727
      ],
      [
        -115.61613,
        44.07734
      ],
      [
        -115.61628,
        44.07742
      ],
      [
        -115.61653,
        44.07752
      ],
      [
        -115.61654,
        44.07752
      ],
      [
        -115.61666,
        44.07755
      ],
      [
        -115.61683,
        44.07758
      ],
      [
        -115.61767,
        44.07769
      ],
      [
        -115.61799,
        44.07776
      ],
      [
        -115.61799,
        44.07777
      ],
      [
        -115.61821,
        44.07784
      ],
      [
        -115.61839,
        44.07792
      ],
      [
        -115.61843,
        44.07793
      ],
      [
        -115.61973,
        44.07848
      ],
      [
        -115.6206,
        44.07882
      ],
      [
        -115.6225,
        44.07966
      ],
      [
        -115.6244,
        44.08066
      ],
      [
        -115.62458,
        44.08077
      ],
      [
        -115.62466,
        44.08083
      ],
      [
        -115.62481,
        44.08101
      ],
      [
        -115.62485,
        44.0811
      ],
      [
        -115.62487,
        44.08124
      ],
      [
        -115.62482,
        44.08144
      ],
      [
        -115.62475,
        44.08155
      ],
      [
        -115.62468,
        44.08162
      ],
      [
        -115.62463,
        44.08165
      ],
      [
        -115.62462,
        44.08166
      ],
      [
        -115.62443,
        44.08175
      ],
      [
        -115.62433,
        44.08178
      ],
      [
        -115.62422,
        44.0818
      ],
      [
        -115.624,
        44.0818
      ],
      [
        -115.62394,
        44.08179
      ],
      [
        -115.62391,
        44.08178
      ],
      [
        -115.62386,
        44.08177
      ],
      [
        -115.62366,
        44.0817
      ],
      [
        -115.62356,
        44.08165
      ],
      [
        -115.62331,
        44.0815
      ],
      [
        -115.62225,
        44.08079
      ],
      [
        -115.62215,
        44.08073
      ],
      [
        -115.62189,
        44.0806
      ],
      [
        -115.62157,
        44.0805
      ],
      [
        -115.62124,
        44.08043
      ],
      [
        -115.62091,
        44.0804
      ],
      [
        -115.62063,
        44.0804
      ],
      [
        -115.62041,
        44.08043
      ],
      [
        -115.62006,
        44.0805
      ],
      [
        -115.61941,
        44.08071
      ],
      [
        -115.618,
        44.08124
      ],
      [
        -115.61665,
        44.08171
      ],
      [
        -115.61611,
        44.08188
      ],
      [
        -115.61574,
        44.08198
      ],
      [
        -115.61508,
        44.08213
      ],
      [
        -115.61444,
        44.08224
      ],
      [
        -115.61248,
        44.08253
      ],
      [
        -115.61243,
        44.08253
      ],
      [
        -115.61145,
        44.08262
      ],
      [
        -115.61095,
        44.08263
      ],
      [
        -115.6109,
        44.08262
      ],
      [
        -115.61074,
        44.08262
      ],
      [
        -115.6103,
        44.08258
      ],
      [
        -115.61011,
        44.08255
      ],
      [
        -115.60962,
        44.08245
      ],
      [
        -115.60945,
        44.0824
      ],
      [
        -115.60943,
        44.0824
      ],
      [
        -115.60889,
        44.0822
      ],
      [
        -115.60877,
        44.08214
      ],
      [
        -115.60856,
        44.08205
      ],
      [
        -115.60707,
        44.08131
      ],
      [
        -115.60685,
        44.08119
      ],
      [
        -115.60674,
        44.08114
      ],
      [
        -115.60662,
        44.08107
      ],
      [
        -115.60568,
        44.08064
      ],
      [
        -115.60192,
        44.07883
      ],
      [
        -115.60168,
        44.07874
      ],
      [
        -115.60128,
        44.07862
      ],
      [
        -115.60004,
        44.07833
      ],
      [
        -115.5996,
        44.07818
      ],
      [
        -115.5994,
        44.0781
      ],
      [
        -115.59904,
        44.07792
      ],
      [
        -115.59892,
        44.07785
      ],
      [
        -115.59844,
        44.07752
      ],
      [
        -115.5981,
        44.0772
      ],
      [
        -115.59787,
        44.07693
      ],
      [
        -115.59786,
        44.07691
      ],
      [
        -115.59724,
        44.07605
      ],
      [
        -115.59709,
        44.07588
      ],
      [
        -115.59684,
        44.07566
      ],
      [
        -115.59652,
        44.07544
      ],
      [
        -115.59625,
        44.0753
      ],
      [
        -115.59581,
        44.07513
      ],
      [
        -115.59546,
        44.07504
      ],
      [
        -115.59524,
        44.075
      ],
      [
        -115.59362,
        44.07483
      ],
      [
        -115.59321,
        44.07476
      ],
      [
        -115.59319,
        44.07475
      ],
      [
        -115.59292,
        44.07469
      ],
      [
        -115.59251,
        44.07458
      ],
      [
        -115.59217,
        44.07444
      ],
      [
        -115.58946,
        44.07323
      ],
      [
        -115.58943,
        44.07322
      ],
      [
        -115.58797,
        44.07256
      ],
      [
        -115.58745,
        44.07239
      ],
      [
        -115.58728,
        44.07235
      ],
      [
        -115.58692,
        44.07231
      ],
      [
        -115.58647,
        44.07232
      ],
      [
        -115.58515,
        44.07246
      ],
      [
        -115.58473,
        44.07248
      ],
      [
        -115.58451,
        44.07247
      ],
      [
        -115.58376,
        44.07239
      ],
      [
        -115.58348,
        44.07234
      ],
      [
        -115.58305,
        44.07224
      ],
      [
        -115.58271,
        44.0721
      ],
      [
        -115.58256,
        44.07201
      ],
      [
        -115.58241,
        44.07189
      ],
      [
        -115.58193,
        44.07141
      ],
      [
        -115.58164,
        44.0712
      ],
      [
        -115.58151,
        44.07113
      ],
      [
        -115.58126,
        44.07103
      ],
      [
        -115.58085,
        44.07097
      ],
      [
        -115.5807,
        44.07097
      ],
      [
        -115.58056,
        44.07098
      ],
      [
        -115.58043,
        44.071
      ],
      [
        -115.58026,
        44.07105
      ],
      [
        -115.58013,
        44.0711
      ],
      [
        -115.57998,
        44.07117
      ],
      [
        -115.57989,
        44.07122
      ],
      [
        -115.57979,
        44.07129
      ],
      [
        -115.57935,
        44.07165
      ],
      [
        -115.57924,
        44.07173
      ],
      [
        -115.57899,
        44.07188
      ],
      [
        -115.57861,
        44.07205
      ],
      [
        -115.57842,
        44.07211
      ],
      [
        -115.578,
        44.0722
      ],
      [
        -115.57769,
        44.07222
      ],
      [
        -115.57733,
        44.07221
      ],
      [
        -115.57717,
        44.07219
      ],
      [
        -115.57684,
        44.07212
      ],
      [
        -115.57682,
        44.07211
      ],
      [
        -115.57645,
        44.07199
      ],
      [
        -115.57581,
        44.07173
      ],
      [
        -115.57439,
        44.07111
      ],
      [
        -115.57416,
        44.07102
      ],
      [
        -115.57383,
        44.07094
      ],
      [
        -115.5737,
        44.07092
      ],
      [
        -115.57345,
        44.0709
      ],
      [
        -115.5732,
        44.0709
      ],
      [
        -115.57318,
        44.07091
      ],
      [
        -115.57296,
        44.07093
      ],
      [
        -115.57278,
        44.07097
      ],
      [
        -115.57249,
        44.07107
      ],
      [
        -115.57248,
        44.07107
      ],
      [
        -115.57231,
        44.07115
      ],
      [
        -115.57188,
        44.07141
      ],
      [
        -115.57173,
        44.07149
      ],
      [
        -115.5717,
        44.07151
      ],
      [
        -115.57135,
        44.07168
      ],
      [
        -115.57104,
        44.07177
      ],
      [
        -115.57093,
        44.07179
      ],
      [
        -115.57089,
        44.07179
      ],
      [
        -115.5708,
        44.07181
      ],
      [
        -115.57067,
        44.07181
      ],
      [
        -115.57055,
        44.07182
      ],
      [
        -115.57045,
        44.07181
      ],
      [
        -115.57037,
        44.07181
      ],
      [
        -115.5703,
        44.0718
      ],
      [
        -115.57028,
        44.0718
      ],
      [
        -115.57012,
        44.07177
      ],
      [
        -115.56985,
        44.0717
      ],
      [
        -115.56961,
        44.0716
      ],
      [
        -115.56954,
        44.07158
      ],
      [
        -115.56951,
        44.07156
      ],
      [
        -115.56923,
        44.07144
      ],
      [
        -115.56889,
        44.07134
      ],
      [
        -115.56875,
        44.07133
      ],
      [
        -115.56869,
        44.07132
      ],
      [
        -115.56844,
        44.07133
      ],
      [
        -115.56829,
        44.07135
      ],
      [
        -115.56815,
        44.07138
      ],
      [
        -115.56763,
        44.07153
      ],
      [
        -115.56756,
        44.07156
      ],
      [
        -115.56728,
        44.0716
      ],
      [
        -115.56712,
        44.07161
      ],
      [
        -115.56694,
        44.0716
      ],
      [
        -115.56666,
        44.07156
      ],
      [
        -115.5657,
        44.07138
      ],
      [
        -115.56537,
        44.07133
      ],
      [
        -115.56508,
        44.07131
      ],
      [
        -115.56505,
        44.07132
      ],
      [
        -115.56492,
        44.07132
      ],
      [
        -115.56475,
        44.07134
      ],
      [
        -115.56459,
        44.07138
      ],
      [
        -115.56456,
        44.07138
      ],
      [
        -115.56445,
        44.07142
      ],
      [
        -115.56424,
        44.07152
      ],
      [
        -115.56414,
        44.07158
      ],
      [
        -115.56413,
        44.07159
      ],
      [
        -115.56402,
        44.07167
      ],
      [
        -115.56392,
        44.07177
      ],
      [
        -115.56386,
        44.07187
      ],
      [
        -115.56381,
        44.07193
      ],
      [
        -115.56373,
        44.07216
      ],
      [
        -115.56371,
        44.07226
      ],
      [
        -115.56368,
        44.07257
      ],
      [
        -115.56367,
        44.0726
      ],
      [
        -115.56362,
        44.07293
      ],
      [
        -115.5636,
        44.07301
      ],
      [
        -115.56352,
        44.07324
      ],
      [
        -115.56302,
        44.0745
      ],
      [
        -115.56295,
        44.07465
      ],
      [
        -115.56286,
        44.07479
      ],
      [
        -115.56271,
        44.07497
      ],
      [
        -115.56259,
        44.07507
      ],
      [
        -115.56249,
        44.07514
      ],
      [
        -115.56226,
        44.07525
      ],
      [
        -115.56215,
        44.07529
      ],
      [
        -115.56194,
        44.07535
      ],
      [
        -115.56168,
        44.07539
      ],
      [
        -115.56122,
        44.07539
      ],
      [
        -115.5611,
        44.07538
      ],
      [
        -115.56085,
        44.07534
      ],
      [
        -115.5585,
        44.07483
      ],
      [
        -115.55785,
        44.07475
      ],
      [
        -115.55633,
        44.07461
      ],
      [
        -115.55593,
        44.07461
      ],
      [
        -115.55574,
        44.07463
      ],
      [
        -115.55556,
        44.07467
      ],
      [
        -115.55521,
        44.07479
      ],
      [
        -115.55472,
        44.075
      ],
      [
        -115.55451,
        44.07507
      ],
      [
        -115.55435,
        44.07511
      ],
      [
        -115.55424,
        44.07513
      ],
      [
        -115.55403,
        44.07515
      ],
      [
        -115.55374,
        44.07514
      ],
      [
        -115.55353,
        44.0751
      ],
      [
        -115.5533,
        44.07504
      ],
      [
        -115.55329,
        44.07504
      ],
      [
        -115.55294,
        44.07487
      ],
      [
        -115.55281,
        44.07479
      ],
      [
        -115.55253,
        44.07458
      ],
      [
        -115.55215,
        44.07426
      ],
      [
        -115.55206,
        44.07416
      ],
      [
        -115.55194,
        44.07399
      ],
      [
        -115.55173,
        44.0735
      ],
      [
        -115.55156,
        44.07323
      ],
      [
        -115.55151,
        44.07317
      ],
      [
        -115.55136,
        44.07303
      ],
      [
        -115.5512,
        44.0729
      ],
      [
        -115.55115,
        44.07285
      ],
      [
        -115.55059,
        44.07244
      ],
      [
        -115.55027,
        44.07226
      ],
      [
        -115.5497,
        44.07209
      ],
      [
        -115.54927,
        44.07204
      ],
      [
        -115.54883,
        44.07204
      ],
      [
        -115.54868,
        44.07205
      ],
      [
        -115.54831,
        44.07211
      ],
      [
        -115.5483,
        44.07212
      ],
      [
        -115.54806,
        44.07218
      ],
      [
        -115.54771,
        44.0723
      ],
      [
        -115.5462,
        44.07294
      ],
      [
        -115.54573,
        44.07312
      ],
      [
        -115.54557,
        44.07316
      ],
      [
        -115.54545,
        44.0732
      ],
      [
        -115.5452,
        44.07324
      ],
      [
        -115.54485,
        44.07327
      ],
      [
        -115.54443,
        44.07326
      ],
      [
        -115.54392,
        44.07319
      ],
      [
        -115.54217,
        44.07283
      ],
      [
        -115.54201,
        44.07279
      ],
      [
        -115.54174,
        44.07269
      ],
      [
        -115.54151,
        44.07255
      ],
      [
        -115.54145,
        44.0725
      ],
      [
        -115.54134,
        44.07239
      ],
      [
        -115.54128,
        44.07232
      ],
      [
        -115.54125,
        44.07226
      ],
      [
        -115.5412,
        44.07218
      ],
      [
        -115.54095,
        44.07161
      ],
      [
        -115.5409,
        44.07152
      ],
      [
        -115.54084,
        44.07144
      ],
      [
        -115.5407,
        44.07129
      ],
      [
        -115.54052,
        44.07116
      ],
      [
        -115.54041,
        44.07109
      ],
      [
        -115.54009,
        44.07092
      ],
      [
        -115.53987,
        44.07082
      ],
      [
        -115.53966,
        44.07075
      ],
      [
        -115.53944,
        44.07071
      ],
      [
        -115.53928,
        44.07069
      ],
      [
        -115.539,
        44.0707
      ],
      [
        -115.53879,
        44.07072
      ],
      [
        -115.53838,
        44.07079
      ],
      [
        -115.53811,
        44.07085
      ],
      [
        -115.53797,
        44.07087
      ],
      [
        -115.53772,
        44.07094
      ],
      [
        -115.53735,
        44.07107
      ],
      [
        -115.53707,
        44.07119
      ],
      [
        -115.53679,
        44.07133
      ],
      [
        -115.53658,
        44.07142
      ],
      [
        -115.53629,
        44.07151
      ],
      [
        -115.53604,
        44.07156
      ],
      [
        -115.53559,
        44.0716
      ],
      [
        -115.53555,
        44.07161
      ],
      [
        -115.53435,
        44.07171
      ],
      [
        -115.53311,
        44.07185
      ],
      [
        -115.53233,
        44.07197
      ],
      [
        -115.53042,
        44.07234
      ],
      [
        -115.5301,
        44.07238
      ],
      [
        -115.52966,
        44.0724
      ],
      [
        -115.52942,
        44.0724
      ],
      [
        -115.52835,
        44.07236
      ],
      [
        -115.5278,
        44.0723
      ],
      [
        -115.52729,
        44.07218
      ],
      [
        -115.527,
        44.07209
      ],
      [
        -115.52666,
        44.07195
      ],
      [
        -115.52628,
        44.07175
      ],
      [
        -115.52605,
        44.0716
      ],
      [
        -115.52563,
        44.07123
      ],
      [
        -115.52509,
        44.07066
      ],
      [
        -115.52508,
        44.07066
      ],
      [
        -115.52484,
        44.07035
      ],
      [
        -115.52471,
        44.07016
      ],
      [
        -115.52462,
        44.07001
      ],
      [
        -115.52446,
        44.06967
      ],
      [
        -115.52424,
        44.06906
      ],
      [
        -115.52416,
        44.06879
      ],
      [
        -115.52408,
        44.06859
      ],
      [
        -115.52401,
        44.06845
      ],
      [
        -115.52391,
        44.0683
      ],
      [
        -115.52368,
        44.06804
      ],
      [
        -115.52356,
        44.06795
      ],
      [
        -115.52324,
        44.06778
      ],
      [
        -115.52323,
        44.06778
      ],
      [
        -115.52306,
        44.06772
      ],
      [
        -115.52282,
        44.06768
      ],
      [
        -115.52264,
        44.06768
      ],
      [
        -115.52252,
        44.06769
      ],
      [
        -115.52245,
        44.06769
      ],
      [
        -115.52215,
        44.06775
      ],
      [
        -115.52188,
        44.06785
      ],
      [
        -115.52172,
        44.06794
      ],
      [
        -115.52158,
        44.06806
      ],
      [
        -115.52148,
        44.06818
      ],
      [
        -115.52139,
        44.06835
      ],
      [
        -115.52137,
        44.06842
      ],
      [
        -115.52136,
        44.0685
      ],
      [
        -115.52136,
        44.0686
      ],
      [
        -115.52137,
        44.06872
      ],
      [
        -115.5214,
        44.06884
      ],
      [
        -115.52141,
        44.06885
      ],
      [
        -115.52146,
        44.06899
      ],
      [
        -115.52148,
        44.06902
      ],
      [
        -115.52155,
        44.06916
      ],
      [
        -115.52203,
        44.06992
      ],
      [
        -115.52212,
        44.0701
      ],
      [
        -115.52217,
        44.07024
      ],
      [
        -115.5222,
        44.07035
      ],
      [
        -115.52222,
        44.0705
      ],
      [
        -115.52222,
        44.07071
      ],
      [
        -115.52219,
        44.07087
      ],
      [
        -115.52215,
        44.071
      ],
      [
        -115.52209,
        44.07112
      ],
      [
        -115.52203,
        44.07122
      ],
      [
        -115.52194,
        44.07134
      ],
      [
        -115.52187,
        44.07142
      ],
      [
        -115.52158,
        44.07167
      ],
      [
        -115.52084,
        44.07225
      ],
      [
        -115.52027,
        44.07267
      ],
      [
        -115.5201,
        44.07277
      ],
      [
        -115.51991,
        44.07286
      ],
      [
        -115.51968,
        44.07295
      ],
      [
        -115.51948,
        44.073
      ],
      [
        -115.51918,
        44.07306
      ],
      [
        -115.51905,
        44.07307
      ],
      [
        -115.51874,
        44.07307
      ],
      [
        -115.51833,
        44.07302
      ],
      [
        -115.51827,
        44.073
      ],
      [
        -115.51809,
        44.07296
      ],
      [
        -115.51729,
        44.07274
      ],
      [
        -115.51679,
        44.07262
      ],
      [
        -115.51639,
        44.07256
      ],
      [
        -115.51593,
        44.07256
      ],
      [
        -115.51557,
        44.0726
      ],
      [
        -115.51527,
        44.07265
      ],
      [
        -115.507,
        44.07486
      ],
      [
        -115.50569,
        44.07517
      ],
      [
        -115.50098,
        44.07614
      ],
      [
        -115.50096,
        44.07614
      ],
      [
        -115.50006,
        44.07636
      ],
      [
        -115.49938,
        44.07656
      ],
      [
        -115.49839,
        44.07689
      ],
      [
        -115.49766,
        44.07716
      ],
      [
        -115.49717,
        44.07737
      ],
      [
        -115.49451,
        44.07863
      ],
      [
        -115.49388,
        44.07895
      ],
      [
        -115.49358,
        44.07912
      ],
      [
        -115.49341,
        44.07923
      ],
      [
        -115.49325,
        44.07935
      ],
      [
        -115.49295,
        44.07961
      ],
      [
        -115.49284,
        44.07972
      ],
      [
        -115.49256,
        44.08006
      ],
      [
        -115.49244,
        44.08025
      ],
      [
        -115.49236,
        44.0804
      ],
      [
        -115.49226,
        44.08063
      ],
      [
        -115.49218,
        44.08087
      ],
      [
        -115.49212,
        44.08112
      ],
      [
        -115.4921,
        44.08132
      ],
      [
        -115.49209,
        44.08154
      ],
      [
        -115.49211,
        44.08183
      ],
      [
        -115.49216,
        44.0822
      ],
      [
        -115.49223,
        44.08257
      ],
      [
        -115.49234,
        44.08299
      ],
      [
        -115.49262,
        44.08383
      ],
      [
        -115.49269,
        44.08408
      ],
      [
        -115.49271,
        44.08426
      ],
      [
        -115.49271,
        44.08441
      ],
      [
        -115.49266,
        44.08464
      ],
      [
        -115.49262,
        44.08475
      ],
      [
        -115.49255,
        44.08489
      ],
      [
        -115.49248,
        44.08501
      ],
      [
        -115.49239,
        44.08514
      ],
      [
        -115.49073,
        44.087
      ],
      [
        -115.49017,
        44.08758
      ],
      [
        -115.49012,
        44.08762
      ],
      [
        -115.49004,
        44.0877
      ],
      [
        -115.48988,
        44.08783
      ],
      [
        -115.48979,
        44.08789
      ],
      [
        -115.48971,
        44.08796
      ],
      [
        -115.48939,
        44.08818
      ],
      [
        -115.48926,
        44.08826
      ],
      [
        -115.48925,
        44.08827
      ],
      [
        -115.48876,
        44.08853
      ],
      [
        -115.48862,
        44.08859
      ],
      [
        -115.48849,
        44.08866
      ],
      [
        -115.48784,
        44.08891
      ],
      [
        -115.48759,
        44.08899
      ],
      [
        -115.48735,
        44.08908
      ],
      [
        -115.48664,
        44.08931
      ],
      [
        -115.48626,
        44.08945
      ],
      [
        -115.48618,
        44.08947
      ],
      [
        -115.483,
        44.09055
      ],
      [
        -115.48205,
        44.09093
      ],
      [
        -115.48177,
        44.09106
      ],
      [
        -115.48111,
        44.0914
      ],
      [
        -115.48042,
        44.09182
      ],
      [
        -115.4804,
        44.09184
      ],
      [
        -115.47756,
        44.09374
      ],
      [
        -115.4773,
        44.0939
      ],
      [
        -115.47654,
        44.09441
      ],
      [
        -115.47631,
        44.09455
      ],
      [
        -115.47615,
        44.09466
      ],
      [
        -115.47545,
        44.09507
      ],
      [
        -115.47458,
        44.09554
      ],
      [
        -115.47245,
        44.09659
      ],
      [
        -115.47209,
        44.09679
      ],
      [
        -115.47161,
        44.0971
      ],
      [
        -115.47131,
        44.09734
      ],
      [
        -115.47108,
        44.09756
      ],
      [
        -115.47082,
        44.09785
      ],
      [
        -115.47067,
        44.09805
      ],
      [
        -115.47054,
        44.09825
      ],
      [
        -115.46996,
        44.09924
      ],
      [
        -115.46974,
        44.09956
      ],
      [
        -115.46965,
        44.09967
      ],
      [
        -115.46959,
        44.09972
      ],
      [
        -115.46953,
        44.09979
      ],
      [
        -115.46945,
        44.09987
      ],
      [
        -115.46912,
        44.10014
      ],
      [
        -115.4687,
        44.1004
      ],
      [
        -115.4683,
        44.10058
      ],
      [
        -115.468,
        44.10068
      ],
      [
        -115.46767,
        44.10077
      ],
      [
        -115.46692,
        44.10087
      ],
      [
        -115.46673,
        44.10088
      ],
      [
        -115.46639,
        44.10088
      ],
      [
        -115.46593,
        44.10084
      ],
      [
        -115.46589,
        44.10084
      ],
      [
        -115.46552,
        44.10078
      ],
      [
        -115.46535,
        44.10074
      ],
      [
        -115.46491,
        44.10061
      ],
      [
        -115.46194,
        44.09951
      ],
      [
        -115.4615,
        44.09938
      ],
      [
        -115.46094,
        44.09927
      ],
      [
        -115.46075,
        44.09925
      ],
      [
        -115.46018,
        44.09922
      ],
      [
        -115.45977,
        44.09923
      ],
      [
        -115.45952,
        44.09925
      ],
      [
        -115.45895,
        44.09933
      ],
      [
        -115.45862,
        44.09941
      ],
      [
        -115.45838,
        44.09948
      ],
      [
        -115.4581,
        44.09958
      ],
      [
        -115.45778,
        44.09971
      ],
      [
        -115.4574,
        44.09991
      ],
      [
        -115.45686,
        44.10027
      ],
      [
        -115.45682,
        44.10031
      ],
      [
        -115.45483,
        44.10199
      ],
      [
        -115.45456,
        44.10224
      ],
      [
        -115.45309,
        44.10348
      ],
      [
        -115.45296,
        44.10358
      ],
      [
        -115.45272,
        44.10379
      ],
      [
        -115.45233,
        44.1041
      ],
      [
        -115.4518,
        44.10447
      ],
      [
        -115.45108,
        44.10491
      ],
      [
        -115.44999,
        44.10545
      ],
      [
        -115.44714,
        44.10671
      ],
      [
        -115.44632,
        44.10705
      ],
      [
        -115.44403,
        44.10813
      ],
      [
        -115.44246,
        44.10896
      ],
      [
        -115.44117,
        44.1097
      ],
      [
        -115.43614,
        44.11275
      ],
      [
        -115.43487,
        44.11343
      ],
      [
        -115.43403,
        44.11382
      ],
      [
        -115.43283,
        44.11433
      ],
      [
        -115.43268,
        44.11438
      ],
      [
        -115.43248,
        44.11446
      ],
      [
        -115.43182,
        44.11469
      ],
      [
        -115.43071,
        44.11504
      ],
      [
        -115.42977,
        44.1153
      ],
      [
        -115.42897,
        44.11549
      ],
      [
        -115.42797,
        44.11569
      ],
      [
        -115.4277,
        44.11573
      ],
      [
        -115.42729,
        44.11581
      ],
      [
        -115.41975,
        44.11702
      ],
      [
        -115.41929,
        44.11712
      ],
      [
        -115.41825,
        44.11739
      ],
      [
        -115.41824,
        44.11739
      ],
      [
        -115.41617,
        44.11795
      ],
      [
        -115.41565,
        44.11806
      ],
      [
        -115.41515,
        44.11814
      ],
      [
        -115.4147,
        44.11819
      ],
      [
        -115.41398,
        44.11824
      ],
      [
        -115.41319,
        44.11824
      ],
      [
        -115.41282,
        44.11822
      ],
      [
        -115.41221,
        44.11816
      ],
      [
        -115.41039,
        44.11789
      ],
      [
        -115.4096,
        44.11782
      ],
      [
        -115.40913,
        44.1178
      ],
      [
        -115.40834,
        44.11779
      ],
      [
        -115.40785,
        44.11781
      ],
      [
        -115.4062,
        44.11793
      ],
      [
        -115.39966,
        44.11804
      ],
      [
        -115.39904,
        44.11809
      ],
      [
        -115.39891,
        44.11811
      ],
      [
        -115.39879,
        44.11812
      ],
      [
        -115.39875,
        44.11813
      ],
      [
        -115.39842,
        44.11817
      ],
      [
        -115.39782,
        44.11829
      ],
      [
        -115.39697,
        44.1185
      ],
      [
        -115.39367,
        44.1195
      ],
      [
        -115.3928,
        44.11973
      ],
      [
        -115.39173,
        44.11996
      ],
      [
        -115.3915,
        44.12
      ],
      [
        -115.39147,
        44.12
      ],
      [
        -115.39119,
        44.12005
      ],
      [
        -115.39055,
        44.12014
      ],
      [
        -115.39031,
        44.12016
      ],
      [
        -115.39027,
        44.12017
      ],
      [
        -115.38895,
        44.12026
      ],
      [
        -115.3878,
        44.12027
      ],
      [
        -115.38729,
        44.12026
      ],
      [
        -115.38702,
        44.12024
      ],
      [
        -115.38689,
        44.12024
      ],
      [
        -115.38658,
        44.12022
      ],
      [
        -115.38556,
        44.12011
      ],
      [
        -115.38484,
        44.12
      ],
      [
        -115.38303,
        44.11963
      ],
      [
        -115.381,
        44.11916
      ],
      [
        -115.38002,
        44.11896
      ],
      [
        -115.3796,
        44.11891
      ],
      [
        -115.37918,
        44.11888
      ],
      [
        -115.37875,
        44.11888
      ],
      [
        -115.37816,
        44.11892
      ],
      [
        -115.37761,
        44.11899
      ],
      [
        -115.37658,
        44.11917
      ],
      [
        -115.37534,
        44.11935
      ],
      [
        -115.37467,
        44.1194
      ],
      [
        -115.374,
        44.1194
      ],
      [
        -115.37337,
        44.11936
      ],
      [
        -115.37261,
        44.11926
      ],
      [
        -115.37111,
        44.11901
      ],
      [
        -115.37087,
        44.11896
      ],
      [
        -115.36966,
        44.11876
      ],
      [
        -115.36869,
        44.11855
      ],
      [
        -115.36414,
        44.11706
      ],
      [
        -115.36396,
        44.11701
      ],
      [
        -115.36352,
        44.11691
      ],
      [
        -115.36326,
        44.11687
      ],
      [
        -115.36323,
        44.11686
      ],
      [
        -115.36293,
        44.11683
      ],
      [
        -115.36239,
        44.11682
      ],
      [
        -115.36185,
        44.11685
      ],
      [
        -115.36154,
        44.1169
      ],
      [
        -115.36102,
        44.11702
      ],
      [
        -115.36059,
        44.11717
      ],
      [
        -115.36028,
        44.1173
      ],
      [
        -115.35999,
        44.11745
      ],
      [
        -115.35544,
        44.12006
      ],
      [
        -115.35525,
        44.12018
      ],
      [
        -115.35147,
        44.12236
      ],
      [
        -115.35126,
        44.12247
      ],
      [
        -115.35085,
        44.12266
      ],
      [
        -115.35027,
        44.12288
      ],
      [
        -115.3497,
        44.12304
      ],
      [
        -115.34939,
        44.1231
      ],
      [
        -115.34871,
        44.1232
      ],
      [
        -115.34814,
        44.12323
      ],
      [
        -115.34769,
        44.12322
      ],
      [
        -115.34739,
        44.1232
      ],
      [
        -115.34734,
        44.12319
      ],
      [
        -115.3461,
        44.12309
      ],
      [
        -115.34557,
        44.12307
      ],
      [
        -115.34533,
        44.12308
      ],
      [
        -115.34515,
        44.1231
      ],
      [
        -115.3448,
        44.12316
      ],
      [
        -115.34478,
        44.12317
      ],
      [
        -115.34463,
        44.1232
      ],
      [
        -115.34433,
        44.12328
      ],
      [
        -115.34401,
        44.12341
      ],
      [
        -115.34367,
        44.12358
      ],
      [
        -115.34366,
        44.12359
      ],
      [
        -115.34337,
        44.12374
      ],
      [
        -115.33569,
        44.12819
      ],
      [
        -115.33476,
        44.1287
      ],
      [
        -115.33363,
        44.12919
      ],
      [
        -115.33237,
        44.12968
      ],
      [
        -115.33202,
        44.1298
      ],
      [
        -115.32913,
        44.13065
      ],
      [
        -115.32858,
        44.13079
      ],
      [
        -115.32798,
        44.131
      ],
      [
        -115.32753,
        44.13125
      ],
      [
        -115.32723,
        44.13152
      ],
      [
        -115.32712,
        44.13165
      ],
      [
        -115.32701,
        44.1318
      ],
      [
        -115.32686,
        44.1321
      ],
      [
        -115.32681,
        44.13228
      ],
      [
        -115.32658,
        44.13297
      ],
      [
        -115.32651,
        44.13315
      ],
      [
        -115.32643,
        44.1333
      ],
      [
        -115.32635,
        44.13343
      ],
      [
        -115.32628,
        44.13352
      ],
      [
        -115.32611,
        44.13371
      ],
      [
        -115.32594,
        44.13386
      ],
      [
        -115.32585,
        44.13392
      ],
      [
        -115.32577,
        44.13399
      ],
      [
        -115.32547,
        44.13416
      ],
      [
        -115.32536,
        44.13421
      ],
      [
        -115.325,
        44.13434
      ],
      [
        -115.32482,
        44.13439
      ],
      [
        -115.32434,
        44.13446
      ],
      [
        -115.32372,
        44.13448
      ],
      [
        -115.32018,
        44.13441
      ],
      [
        -115.3199,
        44.13442
      ],
      [
        -115.31922,
        44.13448
      ],
      [
        -115.31821,
        44.13469
      ],
      [
        -115.31781,
        44.1348
      ],
      [
        -115.31747,
        44.13493
      ],
      [
        -115.31737,
        44.13496
      ],
      [
        -115.31618,
        44.13548
      ],
      [
        -115.31617,
        44.13548
      ],
      [
        -115.31573,
        44.13568
      ],
      [
        -115.31525,
        44.13586
      ],
      [
        -115.31499,
        44.13594
      ],
      [
        -115.31442,
        44.13608
      ],
      [
        -115.31413,
        44.13614
      ],
      [
        -115.31239,
        44.13637
      ],
      [
        -115.31196,
        44.13647
      ],
      [
        -115.31163,
        44.13657
      ],
      [
        -115.31144,
        44.13665
      ],
      [
        -115.31092,
        44.13693
      ],
      [
        -115.31065,
        44.13714
      ],
      [
        -115.30675,
        44.1408
      ],
      [
        -115.3067,
        44.14084
      ],
      [
        -115.30652,
        44.14104
      ],
      [
        -115.30648,
        44.1411
      ],
      [
        -115.30643,
        44.14116
      ],
      [
        -115.30626,
        44.14142
      ],
      [
        -115.30616,
        44.14159
      ],
      [
        -115.30609,
        44.14173
      ],
      [
        -115.30599,
        44.14198
      ],
      [
        -115.30595,
        44.14211
      ],
      [
        -115.30594,
        44.14212
      ],
      [
        -115.30585,
        44.14245
      ],
      [
        -115.30585,
        44.14246
      ],
      [
        -115.30575,
        44.14288
      ],
      [
        -115.30559,
        44.14328
      ],
      [
        -115.30547,
        44.14348
      ],
      [
        -115.30528,
        44.14372
      ],
      [
        -115.30507,
        44.14393
      ],
      [
        -115.30475,
        44.14417
      ],
      [
        -115.30474,
        44.14418
      ],
      [
        -115.30363,
        44.1449
      ],
      [
        -115.30345,
        44.14503
      ],
      [
        -115.30344,
        44.14503
      ],
      [
        -115.30321,
        44.14521
      ],
      [
        -115.30306,
        44.14534
      ],
      [
        -115.3028,
        44.1456
      ],
      [
        -115.30262,
        44.14583
      ],
      [
        -115.30258,
        44.1459
      ],
      [
        -115.30249,
        44.14603
      ],
      [
        -115.30228,
        44.14645
      ],
      [
        -115.30218,
        44.14669
      ],
      [
        -115.30216,
        44.14675
      ],
      [
        -115.30205,
        44.147
      ],
      [
        -115.30183,
        44.14741
      ],
      [
        -115.30171,
        44.14759
      ],
      [
        -115.30149,
        44.14785
      ],
      [
        -115.30118,
        44.14816
      ],
      [
        -115.30076,
        44.14849
      ],
      [
        -115.30056,
        44.14862
      ],
      [
        -115.30014,
        44.14885
      ],
      [
        -115.29994,
        44.14894
      ],
      [
        -115.2998,
        44.14899
      ],
      [
        -115.29964,
        44.14906
      ],
      [
        -115.29945,
        44.14913
      ],
      [
        -115.29866,
        44.14933
      ],
      [
        -115.29865,
        44.14933
      ],
      [
        -115.29833,
        44.14939
      ],
      [
        -115.29815,
        44.14941
      ],
      [
        -115.29802,
        44.14944
      ],
      [
        -115.29791,
        44.14945
      ],
      [
        -115.29721,
        44.14959
      ],
      [
        -115.2971,
        44.14963
      ],
      [
        -115.29694,
        44.14967
      ],
      [
        -115.29653,
        44.14984
      ],
      [
        -115.29652,
        44.14984
      ],
      [
        -115.29645,
        44.14988
      ],
      [
        -115.29632,
        44.14994
      ],
      [
        -115.29631,
        44.14995
      ],
      [
        -115.29613,
        44.15005
      ],
      [
        -115.29534,
        44.15058
      ],
      [
        -115.29521,
        44.15068
      ],
      [
        -115.2932,
        44.15208
      ],
      [
        -115.29293,
        44.15229
      ],
      [
        -115.29271,
        44.15249
      ],
      [
        -115.29245,
        44.15277
      ],
      [
        -115.29229,
        44.15298
      ],
      [
        -115.29192,
        44.15353
      ],
      [
        -115.29181,
        44.15372
      ],
      [
        -115.2918,
        44.15373
      ],
      [
        -115.29164,
        44.15395
      ],
      [
        -115.29159,
        44.15401
      ],
      [
        -115.29139,
        44.15421
      ],
      [
        -115.29114,
        44.15442
      ],
      [
        -115.29071,
        44.15475
      ],
      [
        -115.29042,
        44.15495
      ],
      [
        -115.29032,
        44.15504
      ],
      [
        -115.29017,
        44.15514
      ],
      [
        -115.28958,
        44.1556
      ],
      [
        -115.28917,
        44.15594
      ],
      [
        -115.28888,
        44.15624
      ],
      [
        -115.28876,
        44.15639
      ],
      [
        -115.28787,
        44.15763
      ],
      [
        -115.28751,
        44.15806
      ],
      [
        -115.28738,
        44.1582
      ],
      [
        -115.28736,
        44.15823
      ],
      [
        -115.28638,
        44.15924
      ],
      [
        -115.28623,
        44.15938
      ],
      [
        -115.28609,
        44.15953
      ],
      [
        -115.28513,
        44.16046
      ],
      [
        -115.28467,
        44.16087
      ],
      [
        -115.28412,
        44.16133
      ],
      [
        -115.28136,
        44.16341
      ],
      [
        -115.28093,
        44.16371
      ],
      [
        -115.28038,
        44.16406
      ],
      [
        -115.27991,
        44.16433
      ],
      [
        -115.27947,
        44.16456
      ],
      [
        -115.27881,
        44.16487
      ],
      [
        -115.27823,
        44.16512
      ],
      [
        -115.27648,
        44.16577
      ],
      [
        -115.27463,
        44.1664
      ],
      [
        -115.27459,
        44.16641
      ],
      [
        -115.2737,
        44.16675
      ],
      [
        -115.27335,
        44.16692
      ],
      [
        -115.27302,
        44.16713
      ],
      [
        -115.27293,
        44.1672
      ],
      [
        -115.27284,
        44.16726
      ],
      [
        -115.27225,
        44.16773
      ],
      [
        -115.2719,
        44.16807
      ],
      [
        -115.27165,
        44.16826
      ],
      [
        -115.27143,
        44.16841
      ],
      [
        -115.2713,
        44.16848
      ],
      [
        -115.27129,
        44.16848
      ],
      [
        -115.27119,
        44.16854
      ],
      [
        -115.27094,
        44.16866
      ],
      [
        -115.26763,
        44.16984
      ],
      [
        -115.26751,
        44.16987
      ],
      [
        -115.26749,
        44.16987
      ],
      [
        -115.26728,
        44.16992
      ],
      [
        -115.26716,
        44.16994
      ],
      [
        -115.26699,
        44.16998
      ],
      [
        -115.26671,
        44.17002
      ],
      [
        -115.26641,
        44.17005
      ],
      [
        -115.26411,
        44.17005
      ],
      [
        -115.2637,
        44.17008
      ],
      [
        -115.26355,
        44.1701
      ],
      [
        -115.26341,
        44.17011
      ],
      [
        -115.26291,
        44.17019
      ],
      [
        -115.26228,
        44.17035
      ],
      [
        -115.26165,
        44.17057
      ],
      [
        -115.26111,
        44.17083
      ],
      [
        -115.26071,
        44.17106
      ],
      [
        -115.26034,
        44.17129
      ],
      [
        -115.26025,
        44.17133
      ],
      [
        -115.26015,
        44.17139
      ],
      [
        -115.25994,
        44.1715
      ],
      [
        -115.25922,
        44.17192
      ],
      [
        -115.25882,
        44.17212
      ],
      [
        -115.25844,
        44.17227
      ],
      [
        -115.25842,
        44.17227
      ],
      [
        -115.25833,
        44.17231
      ],
      [
        -115.25802,
        44.1724
      ],
      [
        -115.25747,
        44.17252
      ],
      [
        -115.25694,
        44.1726
      ],
      [
        -115.25649,
        44.17264
      ],
      [
        -115.25641,
        44.17264
      ],
      [
        -115.25615,
        44.17266
      ],
      [
        -115.25224,
        44.17277
      ],
      [
        -115.25154,
        44.17282
      ],
      [
        -115.25136,
        44.17285
      ],
      [
        -115.25098,
        44.17294
      ],
      [
        -115.25063,
        44.17307
      ],
      [
        -115.25055,
        44.17312
      ],
      [
        -115.25043,
        44.17317
      ],
      [
        -115.25028,
        44.17326
      ],
      [
        -115.25005,
        44.17342
      ],
      [
        -115.24986,
        44.17359
      ],
      [
        -115.24978,
        44.17368
      ],
      [
        -115.24967,
        44.17382
      ],
      [
        -115.2493,
        44.17436
      ],
      [
        -115.24928,
        44.1744
      ],
      [
        -115.24891,
        44.17492
      ],
      [
        -115.24874,
        44.17508
      ],
      [
        -115.24864,
        44.17516
      ],
      [
        -115.24863,
        44.17516
      ],
      [
        -115.24853,
        44.17524
      ],
      [
        -115.24832,
        44.17538
      ],
      [
        -115.24822,
        44.17543
      ],
      [
        -115.24766,
        44.17576
      ],
      [
        -115.24741,
        44.17594
      ],
      [
        -115.24716,
        44.17618
      ],
      [
        -115.24707,
        44.17629
      ],
      [
        -115.24692,
        44.17653
      ],
      [
        -115.24686,
        44.17665
      ],
      [
        -115.24673,
        44.17699
      ],
      [
        -115.24672,
        44.17699
      ],
      [
        -115.24648,
        44.17767
      ],
      [
        -115.24645,
        44.17786
      ],
      [
        -115.24645,
        44.1779
      ],
      [
        -115.24644,
        44.17799
      ],
      [
        -115.24645,
        44.17811
      ],
      [
        -115.24651,
        44.17837
      ],
      [
        -115.24655,
        44.17849
      ],
      [
        -115.24662,
        44.17862
      ],
      [
        -115.24679,
        44.17888
      ],
      [
        -115.24703,
        44.17918
      ],
      [
        -115.24727,
        44.17951
      ],
      [
        -115.24742,
        44.17975
      ],
      [
        -115.24751,
        44.17996
      ],
      [
        -115.24756,
        44.18012
      ],
      [
        -115.2476,
        44.1803
      ],
      [
        -115.24782,
        44.182
      ],
      [
        -115.24793,
        44.18271
      ],
      [
        -115.24794,
        44.18271
      ],
      [
        -115.24796,
        44.1828
      ],
      [
        -115.24801,
        44.18292
      ],
      [
        -115.24811,
        44.18312
      ],
      [
        -115.24816,
        44.18319
      ],
      [
        -115.24831,
        44.18337
      ],
      [
        -115.2486,
        44.18366
      ],
      [
        -115.24896,
        44.18408
      ],
      [
        -115.24915,
        44.18435
      ],
      [
        -115.24933,
        44.18469
      ],
      [
        -115.24938,
        44.18481
      ],
      [
        -115.24968,
        44.1857
      ],
      [
        -115.25002,
        44.18657
      ],
      [
        -115.25007,
        44.18673
      ],
      [
        -115.25012,
        44.18698
      ],
      [
        -115.25015,
        44.18742
      ],
      [
        -115.25016,
        44.18749
      ],
      [
        -115.25017,
        44.18786
      ],
      [
        -115.25019,
        44.18814
      ],
      [
        -115.25019,
        44.18849
      ],
      [
        -115.25018,
        44.18865
      ],
      [
        -115.25016,
        44.18875
      ],
      [
        -115.25011,
        44.18891
      ],
      [
        -115.25005,
        44.18907
      ],
      [
        -115.24999,
        44.18918
      ],
      [
        -115.2498,
        44.18946
      ],
      [
        -115.24954,
        44.18973
      ],
      [
        -115.24921,
        44.19002
      ],
      [
        -115.24898,
        44.19024
      ],
      [
        -115.24879,
        44.19045
      ],
      [
        -115.24865,
        44.19064
      ],
      [
        -115.2485,
        44.19087
      ],
      [
        -115.24837,
        44.19119
      ],
      [
        -115.24831,
        44.19143
      ],
      [
        -115.24827,
        44.19168
      ],
      [
        -115.24826,
        44.19186
      ],
      [
        -115.24828,
        44.19261
      ],
      [
        -115.2483,
        44.19283
      ],
      [
        -115.24841,
        44.1934
      ],
      [
        -115.24846,
        44.19355
      ],
      [
        -115.24854,
        44.19375
      ],
      [
        -115.24862,
        44.19392
      ],
      [
        -115.24871,
        44.19408
      ],
      [
        -115.24876,
        44.19419
      ],
      [
        -115.24881,
        44.19428
      ],
      [
        -115.24896,
        44.1945
      ],
      [
        -115.2491,
        44.19468
      ],
      [
        -115.24914,
        44.19474
      ],
      [
        -115.2501,
        44.19583
      ],
      [
        -115.25022,
        44.196
      ],
      [
        -115.25034,
        44.19629
      ],
      [
        -115.25038,
        44.19646
      ],
      [
        -115.25038,
        44.19662
      ],
      [
        -115.25034,
        44.19687
      ],
      [
        -115.25028,
        44.197
      ],
      [
        -115.25022,
        44.1971
      ],
      [
        -115.25011,
        44.19726
      ],
      [
        -115.24995,
        44.19742
      ],
      [
        -115.24977,
        44.19757
      ],
      [
        -115.24973,
        44.19761
      ],
      [
        -115.24938,
        44.19789
      ],
      [
        -115.24919,
        44.19808
      ],
      [
        -115.24913,
        44.19817
      ],
      [
        -115.24903,
        44.19835
      ],
      [
        -115.24898,
        44.19853
      ],
      [
        -115.24896,
        44.19864
      ],
      [
        -115.24897,
        44.1987
      ],
      [
        -115.24897,
        44.19877
      ],
      [
        -115.24898,
        44.19889
      ],
      [
        -115.24901,
        44.19901
      ],
      [
        -115.24917,
        44.19942
      ],
      [
        -115.24922,
        44.19952
      ],
      [
        -115.24926,
        44.19963
      ],
      [
        -115.2493,
        44.19978
      ],
      [
        -115.24932,
        44.19989
      ],
      [
        -115.24933,
        44.19999
      ],
      [
        -115.24933,
        44.20006
      ],
      [
        -115.24931,
        44.20017
      ],
      [
        -115.24926,
        44.20035
      ],
      [
        -115.24918,
        44.20051
      ],
      [
        -115.24904,
        44.20071
      ],
      [
        -115.24862,
        44.2012
      ],
      [
        -115.24848,
        44.2014
      ],
      [
        -115.24838,
        44.20157
      ],
      [
        -115.24827,
        44.20186
      ],
      [
        -115.24819,
        44.20226
      ],
      [
        -115.24814,
        44.20299
      ],
      [
        -115.24814,
        44.20306
      ],
      [
        -115.24812,
        44.20328
      ],
      [
        -115.24804,
        44.20368
      ],
      [
        -115.24798,
        44.20388
      ],
      [
        -115.2479,
        44.20405
      ],
      [
        -115.24785,
        44.20418
      ],
      [
        -115.24753,
        44.20468
      ],
      [
        -115.24739,
        44.20492
      ],
      [
        -115.24667,
        44.20599
      ],
      [
        -115.24655,
        44.20614
      ],
      [
        -115.24639,
        44.20627
      ],
      [
        -115.24618,
        44.20642
      ],
      [
        -115.24551,
        44.20677
      ],
      [
        -115.24519,
        44.20697
      ],
      [
        -115.24517,
        44.20699
      ],
      [
        -115.24492,
        44.20718
      ],
      [
        -115.24388,
        44.20806
      ],
      [
        -115.24384,
        44.20811
      ],
      [
        -115.24373,
        44.20822
      ],
      [
        -115.24361,
        44.20837
      ],
      [
        -115.24358,
        44.2084
      ],
      [
        -115.24341,
        44.20864
      ],
      [
        -115.24322,
        44.20898
      ],
      [
        -115.24308,
        44.20928
      ],
      [
        -115.24283,
        44.20987
      ],
      [
        -115.24282,
        44.20988
      ],
      [
        -115.24265,
        44.21026
      ],
      [
        -115.24247,
        44.21057
      ],
      [
        -115.24226,
        44.21083
      ],
      [
        -115.24216,
        44.21094
      ],
      [
        -115.24176,
        44.2113
      ],
      [
        -115.2415,
        44.21149
      ],
      [
        -115.24123,
        44.21166
      ],
      [
        -115.24083,
        44.21186
      ],
      [
        -115.2405,
        44.21199
      ],
      [
        -115.24036,
        44.21203
      ],
      [
        -115.24007,
        44.21213
      ],
      [
        -115.23996,
        44.21216
      ],
      [
        -115.23937,
        44.21237
      ],
      [
        -115.23889,
        44.2126
      ],
      [
        -115.23873,
        44.21271
      ],
      [
        -115.23839,
        44.21298
      ],
      [
        -115.23838,
        44.213
      ],
      [
        -115.23813,
        44.21328
      ],
      [
        -115.23802,
        44.21344
      ],
      [
        -115.23794,
        44.21359
      ],
      [
        -115.23787,
        44.21377
      ],
      [
        -115.23783,
        44.21391
      ],
      [
        -115.23778,
        44.21417
      ],
      [
        -115.23777,
        44.21434
      ],
      [
        -115.23783,
        44.21513
      ],
      [
        -115.23783,
        44.21525
      ],
      [
        -115.23782,
        44.21539
      ],
      [
        -115.2378,
        44.21549
      ],
      [
        -115.23776,
        44.21559
      ],
      [
        -115.23776,
        44.2156
      ],
      [
        -115.23756,
        44.21591
      ],
      [
        -115.23739,
        44.21612
      ],
      [
        -115.23412,
        44.21944
      ],
      [
        -115.23384,
        44.2197
      ],
      [
        -115.23343,
        44.22003
      ],
      [
        -115.23048,
        44.22204
      ],
      [
        -115.23017,
        44.22227
      ],
      [
        -115.22971,
        44.22267
      ],
      [
        -115.2297,
        44.22267
      ],
      [
        -115.22944,
        44.22293
      ],
      [
        -115.22926,
        44.22313
      ],
      [
        -115.229,
        44.22347
      ],
      [
        -115.22872,
        44.22391
      ],
      [
        -115.2285,
        44.22434
      ],
      [
        -115.22822,
        44.22499
      ],
      [
        -115.22806,
        44.22527
      ],
      [
        -115.22795,
        44.2254
      ],
      [
        -115.22783,
        44.22552
      ],
      [
        -115.22765,
        44.22566
      ],
      [
        -115.22749,
        44.22577
      ],
      [
        -115.2272,
        44.22591
      ],
      [
        -115.22683,
        44.22605
      ],
      [
        -115.22627,
        44.22621
      ],
      [
        -115.22603,
        44.22629
      ],
      [
        -115.2258,
        44.22638
      ],
      [
        -115.22548,
        44.22655
      ],
      [
        -115.22535,
        44.22664
      ],
      [
        -115.22509,
        44.22687
      ],
      [
        -115.22495,
        44.22702
      ],
      [
        -115.22435,
        44.22761
      ],
      [
        -115.22421,
        44.22772
      ],
      [
        -115.22393,
        44.22788
      ],
      [
        -115.2237,
        44.22798
      ],
      [
        -115.22352,
        44.22804
      ],
      [
        -115.22342,
        44.22806
      ],
      [
        -115.22324,
        44.22811
      ],
      [
        -115.22287,
        44.22817
      ],
      [
        -115.22284,
        44.22818
      ],
      [
        -115.22263,
        44.2282
      ],
      [
        -115.22262,
        44.22821
      ],
      [
        -115.22235,
        44.22824
      ],
      [
        -115.222,
        44.2283
      ],
      [
        -115.22166,
        44.22839
      ],
      [
        -115.22141,
        44.22848
      ],
      [
        -115.22115,
        44.2286
      ],
      [
        -115.22085,
        44.22879
      ],
      [
        -115.21949,
        44.22974
      ],
      [
        -115.21928,
        44.22993
      ],
      [
        -115.21919,
        44.23004
      ],
      [
        -115.21904,
        44.23026
      ],
      [
        -115.21897,
        44.23046
      ],
      [
        -115.21895,
        44.23057
      ],
      [
        -115.21893,
        44.2309
      ],
      [
        -115.21894,
        44.23107
      ],
      [
        -115.21894,
        44.23134
      ],
      [
        -115.21892,
        44.23147
      ],
      [
        -115.21892,
        44.23153
      ],
      [
        -115.2189,
        44.23158
      ],
      [
        -115.21889,
        44.23163
      ],
      [
        -115.21883,
        44.23177
      ],
      [
        -115.21872,
        44.23195
      ],
      [
        -115.2185,
        44.23221
      ],
      [
        -115.21826,
        44.23246
      ],
      [
        -115.21807,
        44.23269
      ],
      [
        -115.2177,
        44.23307
      ],
      [
        -115.21756,
        44.23324
      ],
      [
        -115.21745,
        44.2334
      ],
      [
        -115.21711,
        44.23396
      ],
      [
        -115.21688,
        44.23439
      ],
      [
        -115.21683,
        44.23452
      ],
      [
        -115.21677,
        44.23463
      ],
      [
        -115.21673,
        44.23472
      ],
      [
        -115.21657,
        44.23499
      ],
      [
        -115.21643,
        44.23516
      ],
      [
        -115.21632,
        44.23527
      ],
      [
        -115.21606,
        44.2355
      ],
      [
        -115.21604,
        44.23551
      ],
      [
        -115.21577,
        44.23574
      ],
      [
        -115.21558,
        44.23592
      ],
      [
        -115.21538,
        44.23616
      ],
      [
        -115.21535,
        44.23621
      ],
      [
        -115.21533,
        44.23623
      ],
      [
        -115.21527,
        44.23632
      ],
      [
        -115.21515,
        44.23655
      ],
      [
        -115.2151,
        44.23669
      ],
      [
        -115.21507,
        44.23675
      ],
      [
        -115.21501,
        44.23698
      ],
      [
        -115.21496,
        44.23711
      ],
      [
        -115.21493,
        44.23721
      ],
      [
        -115.21483,
        44.23743
      ],
      [
        -115.21478,
        44.23752
      ],
      [
        -115.21464,
        44.23771
      ],
      [
        -115.21439,
        44.23795
      ],
      [
        -115.21422,
        44.23809
      ],
      [
        -115.21408,
        44.23822
      ],
      [
        -115.21393,
        44.23834
      ],
      [
        -115.2134,
        44.23881
      ],
      [
        -115.2132,
        44.23901
      ],
      [
        -115.213,
        44.23925
      ],
      [
        -115.21284,
        44.23946
      ],
      [
        -115.2127,
        44.23968
      ],
      [
        -115.21259,
        44.23989
      ],
      [
        -115.21248,
        44.24018
      ],
      [
        -115.21233,
        44.24065
      ],
      [
        -115.21221,
        44.24132
      ],
      [
        -115.21216,
        44.2415
      ],
      [
        -115.21206,
        44.24196
      ],
      [
        -115.21206,
        44.242
      ],
      [
        -115.21203,
        44.24211
      ],
      [
        -115.21188,
        44.24285
      ],
      [
        -115.21182,
        44.24331
      ],
      [
        -115.21182,
        44.24346
      ],
      [
        -115.21186,
        44.24379
      ],
      [
        -115.21186,
        44.24382
      ],
      [
        -115.21189,
        44.24404
      ],
      [
        -115.21195,
        44.24432
      ],
      [
        -115.212,
        44.24448
      ],
      [
        -115.21207,
        44.24465
      ],
      [
        -115.21245,
        44.24539
      ],
      [
        -115.21342,
        44.24708
      ],
      [
        -115.21383,
        44.24785
      ],
      [
        -115.21403,
        44.2483
      ],
      [
        -115.21417,
        44.24869
      ],
      [
        -115.21418,
        44.24871
      ],
      [
        -115.21431,
        44.24916
      ],
      [
        -115.21439,
        44.24952
      ],
      [
        -115.2144,
        44.24967
      ],
      [
        -115.21444,
        44.24999
      ],
      [
        -115.21447,
        44.2505
      ],
      [
        -115.21447,
        44.25065
      ],
      [
        -115.21448,
        44.25068
      ],
      [
        -115.21452,
        44.25147
      ],
      [
        -115.21452,
        44.25188
      ],
      [
        -115.21454,
        44.25243
      ],
      [
        -115.21456,
        44.2526
      ],
      [
        -115.2146,
        44.25283
      ],
      [
        -115.21472,
        44.25336
      ],
      [
        -115.21493,
        44.25406
      ],
      [
        -115.21501,
        44.25427
      ],
      [
        -115.21521,
        44.25494
      ],
      [
        -115.21566,
        44.25629
      ],
      [
        -115.21583,
        44.25687
      ],
      [
        -115.21585,
        44.25692
      ],
      [
        -115.2161,
        44.25775
      ],
      [
        -115.21616,
        44.258
      ],
      [
        -115.21621,
        44.25829
      ],
      [
        -115.21624,
        44.25887
      ],
      [
        -115.21622,
        44.25931
      ],
      [
        -115.21621,
        44.25936
      ],
      [
        -115.21612,
        44.26013
      ],
      [
        -115.21607,
        44.26042
      ],
      [
        -115.21607,
        44.26067
      ],
      [
        -115.216,
        44.26125
      ],
      [
        -115.216,
        44.26133
      ],
      [
        -115.21602,
        44.26162
      ],
      [
        -115.21605,
        44.26179
      ],
      [
        -115.21611,
        44.26203
      ],
      [
        -115.21628,
        44.26239
      ],
      [
        -115.21629,
        44.2624
      ],
      [
        -115.21642,
        44.26264
      ],
      [
        -115.21659,
        44.26291
      ],
      [
        -115.21682,
        44.26323
      ],
      [
        -115.217,
        44.26346
      ],
      [
        -115.21726,
        44.26385
      ],
      [
        -115.21744,
        44.2642
      ],
      [
        -115.21749,
        44.26436
      ],
      [
        -115.2175,
        44.26438
      ],
      [
        -115.21756,
        44.26461
      ],
      [
        -115.21775,
        44.26558
      ],
      [
        -115.21775,
        44.2656
      ],
      [
        -115.2178,
        44.26593
      ],
      [
        -115.21786,
        44.26621
      ],
      [
        -115.2179,
        44.26634
      ],
      [
        -115.21793,
        44.26647
      ],
      [
        -115.21794,
        44.26649
      ],
      [
        -115.21801,
        44.26673
      ],
      [
        -115.21826,
        44.26732
      ],
      [
        -115.21827,
        44.26736
      ],
      [
        -115.2183,
        44.26742
      ],
      [
        -115.21838,
        44.26764
      ],
      [
        -115.21841,
        44.2677
      ],
      [
        -115.21842,
        44.26776
      ],
      [
        -115.21848,
        44.2679
      ],
      [
        -115.21849,
        44.26796
      ],
      [
        -115.21855,
        44.26814
      ],
      [
        -115.21857,
        44.26823
      ],
      [
        -115.21858,
        44.26825
      ],
      [
        -115.21861,
        44.26837
      ],
      [
        -115.21868,
        44.26883
      ],
      [
        -115.21869,
        44.26902
      ],
      [
        -115.21867,
        44.26951
      ],
      [
        -115.21858,
        44.27055
      ],
      [
        -115.21859,
        44.27074
      ],
      [
        -115.21862,
        44.27097
      ],
      [
        -115.21866,
        44.27115
      ],
      [
        -115.21877,
        44.27148
      ],
      [
        -115.21893,
        44.27187
      ],
      [
        -115.21961,
        44.27314
      ],
      [
        -115.22045,
        44.2748
      ],
      [
        -115.22057,
        44.27512
      ],
      [
        -115.22085,
        44.27606
      ],
      [
        -115.22092,
        44.27637
      ],
      [
        -115.22111,
        44.27705
      ],
      [
        -115.22122,
        44.27738
      ],
      [
        -115.22136,
        44.27771
      ],
      [
        -115.22154,
        44.27805
      ],
      [
        -115.2216,
        44.27814
      ],
      [
        -115.22178,
        44.27846
      ],
      [
        -115.22191,
        44.27866
      ],
      [
        -115.22235,
        44.27922
      ],
      [
        -115.2224,
        44.27926
      ],
      [
        -115.2234,
        44.28037
      ],
      [
        -115.22366,
        44.2807
      ],
      [
        -115.22384,
        44.28095
      ],
      [
        -115.22387,
        44.28098
      ],
      [
        -115.22404,
        44.28128
      ],
      [
        -115.22405,
        44.28129
      ],
      [
        -115.22413,
        44.28145
      ],
      [
        -115.22426,
        44.28168
      ],
      [
        -115.22448,
        44.28223
      ],
      [
        -115.22474,
        44.28303
      ],
      [
        -115.2251,
        44.28405
      ],
      [
        -115.22511,
        44.28409
      ],
      [
        -115.22613,
        44.28703
      ],
      [
        -115.22636,
        44.28778
      ],
      [
        -115.22647,
        44.28827
      ],
      [
        -115.22652,
        44.28855
      ],
      [
        -115.22658,
        44.28922
      ],
      [
        -115.22655,
        44.28978
      ],
      [
        -115.2265,
        44.29018
      ],
      [
        -115.22646,
        44.2904
      ],
      [
        -115.2263,
        44.29111
      ],
      [
        -115.22609,
        44.29163
      ],
      [
        -115.22563,
        44.29255
      ],
      [
        -115.22467,
        44.29432
      ],
      [
        -115.22456,
        44.2946
      ],
      [
        -115.22448,
        44.29489
      ],
      [
        -115.22443,
        44.29513
      ],
      [
        -115.22438,
        44.29564
      ],
      [
        -115.22439,
        44.29572
      ],
      [
        -115.22451,
        44.29634
      ],
      [
        -115.22463,
        44.29671
      ],
      [
        -115.22505,
        44.2978
      ],
      [
        -115.22537,
        44.29855
      ],
      [
        -115.22565,
        44.29903
      ],
      [
        -115.22565,
        44.29904
      ],
      [
        -115.22587,
        44.29935
      ],
      [
        -115.22612,
        44.29966
      ],
      [
        -115.2264,
        44.29997
      ],
      [
        -115.22706,
        44.30065
      ],
      [
        -115.22712,
        44.3007
      ],
      [
        -115.22945,
        44.30322
      ],
      [
        -115.22991,
        44.30369
      ],
      [
        -115.23097,
        44.30485
      ],
      [
        -115.23144,
        44.30539
      ],
      [
        -115.23178,
        44.30583
      ],
      [
        -115.23181,
        44.30586
      ],
      [
        -115.23201,
        44.30613
      ],
      [
        -115.23202,
        44.30614
      ],
      [
        -115.23259,
        44.30694
      ],
      [
        -115.23259,
        44.30695
      ],
      [
        -115.23281,
        44.30727
      ],
      [
        -115.23311,
        44.30776
      ],
      [
        -115.23365,
        44.30874
      ],
      [
        -115.23397,
        44.3094
      ],
      [
        -115.23406,
        44.30962
      ],
      [
        -115.23414,
        44.30979
      ],
      [
        -115.23449,
        44.31064
      ],
      [
        -115.23449,
        44.31065
      ],
      [
        -115.23456,
        44.31083
      ],
      [
        -115.23473,
        44.31133
      ],
      [
        -115.23509,
        44.31265
      ],
      [
        -115.23525,
        44.31347
      ],
      [
        -115.23535,
        44.31419
      ],
      [
        -115.23545,
        44.3154
      ],
      [
        -115.23546,
        44.31607
      ],
      [
        -115.23544,
        44.31689
      ],
      [
        -115.23533,
        44.31796
      ],
      [
        -115.23524,
        44.31858
      ],
      [
        -115.23514,
        44.31911
      ],
      [
        -115.23498,
        44.31982
      ],
      [
        -115.23488,
        44.32019
      ],
      [
        -115.23444,
        44.32153
      ],
      [
        -115.23391,
        44.32275
      ],
      [
        -115.2334,
        44.32374
      ],
      [
        -115.2334,
        44.32375
      ],
      [
        -115.23316,
        44.32416
      ],
      [
        -115.2326,
        44.32505
      ],
      [
        -115.2326,
        44.32506
      ],
      [
        -115.23238,
        44.32537
      ],
      [
        -115.23237,
        44.32538
      ],
      [
        -115.23191,
        44.32602
      ],
      [
        -115.23157,
        44.32646
      ],
      [
        -115.23154,
        44.32649
      ],
      [
        -115.23107,
        44.32706
      ],
      [
        -115.23057,
        44.32761
      ],
      [
        -115.23033,
        44.32785
      ],
      [
        -115.2303,
        44.32789
      ],
      [
        -115.23018,
        44.328
      ],
      [
        -115.22997,
        44.32822
      ],
      [
        -115.22989,
        44.32829
      ],
      [
        -115.22844,
        44.32976
      ],
      [
        -115.22806,
        44.33023
      ],
      [
        -115.22805,
        44.33025
      ],
      [
        -115.22797,
        44.33035
      ],
      [
        -115.22795,
        44.33039
      ],
      [
        -115.2279,
        44.33046
      ],
      [
        -115.22778,
        44.33067
      ],
      [
        -115.22764,
        44.33098
      ],
      [
        -115.22761,
        44.33109
      ],
      [
        -115.22756,
        44.33122
      ],
      [
        -115.22756,
        44.33124
      ],
      [
        -115.2275,
        44.33148
      ],
      [
        -115.22749,
        44.33156
      ],
      [
        -115.22746,
        44.3317
      ],
      [
        -115.22742,
        44.33196
      ],
      [
        -115.22735,
        44.33226
      ],
      [
        -115.22734,
        44.33233
      ],
      [
        -115.22731,
        44.33242
      ],
      [
        -115.22729,
        44.3325
      ],
      [
        -115.22724,
        44.33264
      ],
      [
        -115.22723,
        44.33266
      ],
      [
        -115.22722,
        44.3327
      ],
      [
        -115.22718,
        44.33278
      ],
      [
        -115.22713,
        44.33292
      ],
      [
        -115.22708,
        44.333
      ],
      [
        -115.22675,
        44.33365
      ],
      [
        -115.22666,
        44.33386
      ],
      [
        -115.22655,
        44.3342
      ],
      [
        -115.22646,
        44.33464
      ],
      [
        -115.22643,
        44.33492
      ],
      [
        -115.22642,
        44.33497
      ],
      [
        -115.2263,
        44.33617
      ],
      [
        -115.22629,
        44.33621
      ],
      [
        -115.22627,
        44.33635
      ],
      [
        -115.22626,
        44.3365
      ],
      [
        -115.22625,
        44.33653
      ],
      [
        -115.22611,
        44.33788
      ],
      [
        -115.22605,
        44.33821
      ],
      [
        -115.2259,
        44.33882
      ],
      [
        -115.22582,
        44.33907
      ],
      [
        -115.22563,
        44.33955
      ],
      [
        -115.22541,
        44.34002
      ],
      [
        -115.22527,
        44.34028
      ],
      [
        -115.2252,
        44.34039
      ],
      [
        -115.22502,
        44.34071
      ],
      [
        -115.2249,
        44.3409
      ],
      [
        -115.22463,
        44.34127
      ],
      [
        -115.22432,
        44.34166
      ],
      [
        -115.22431,
        44.34168
      ],
      [
        -115.2242,
        44.34182
      ],
      [
        -115.22406,
        44.34198
      ],
      [
        -115.22331,
        44.34293
      ],
      [
        -115.22324,
        44.34303
      ],
      [
        -115.22205,
        44.34449
      ],
      [
        -115.22203,
        44.34452
      ],
      [
        -115.22147,
        44.34517
      ],
      [
        -115.22113,
        44.34551
      ],
      [
        -115.22052,
        44.34604
      ],
      [
        -115.22012,
        44.34633
      ],
      [
        -115.2201,
        44.34635
      ],
      [
        -115.21986,
        44.34652
      ],
      [
        -115.2186,
        44.34731
      ],
      [
        -115.21846,
        44.34741
      ],
      [
        -115.21829,
        44.34751
      ],
      [
        -115.21776,
        44.34787
      ],
      [
        -115.21769,
        44.34793
      ],
      [
        -115.2174,
        44.34814
      ],
      [
        -115.21702,
        44.34847
      ],
      [
        -115.21694,
        44.34855
      ],
      [
        -115.21687,
        44.34861
      ],
      [
        -115.21687,
        44.34862
      ],
      [
        -115.21681,
        44.34867
      ],
      [
        -115.21645,
        44.34904
      ],
      [
        -115.21611,
        44.34945
      ],
      [
        -115.21605,
        44.34954
      ],
      [
        -115.21601,
        44.34959
      ],
      [
        -115.21589,
        44.34978
      ],
      [
        -115.21585,
        44.34987
      ],
      [
        -115.21577,
        44.35002
      ],
      [
        -115.21575,
        44.35008
      ],
      [
        -115.2157,
        44.35019
      ],
      [
        -115.21569,
        44.3502
      ],
      [
        -115.21565,
        44.35031
      ],
      [
        -115.21562,
        44.35037
      ],
      [
        -115.21544,
        44.35081
      ],
      [
        -115.21538,
        44.35093
      ],
      [
        -115.21534,
        44.35105
      ],
      [
        -115.21533,
        44.35105
      ],
      [
        -115.21524,
        44.35141
      ],
      [
        -115.21524,
        44.35142
      ],
      [
        -115.21521,
        44.35158
      ],
      [
        -115.2152,
        44.35158
      ],
      [
        -115.2152,
        44.35162
      ],
      [
        -115.21518,
        44.35168
      ],
      [
        -115.215,
        44.35271
      ],
      [
        -115.21495,
        44.35294
      ],
      [
        -115.21494,
        44.35301
      ],
      [
        -115.21491,
        44.35311
      ],
      [
        -115.2149,
        44.35319
      ],
      [
        -115.2148,
        44.35354
      ],
      [
        -115.2148,
        44.35355
      ],
      [
        -115.21475,
        44.35372
      ],
      [
        -115.21473,
        44.35377
      ],
      [
        -115.2147,
        44.35387
      ],
      [
        -115.21469,
        44.35389
      ],
      [
        -115.21465,
        44.35402
      ],
      [
        -115.21452,
        44.35434
      ],
      [
        -115.21439,
        44.35462
      ],
      [
        -115.21402,
        44.35527
      ],
      [
        -115.21393,
        44.35539
      ],
      [
        -115.21392,
        44.35541
      ],
      [
        -115.21355,
        44.35591
      ],
      [
        -115.21328,
        44.35623
      ],
      [
        -115.21284,
        44.35669
      ],
      [
        -115.21278,
        44.35674
      ],
      [
        -115.21274,
        44.35679
      ],
      [
        -115.21271,
        44.35681
      ],
      [
        -115.21264,
        44.35688
      ],
      [
        -115.2125,
        44.357
      ],
      [
        -115.21229,
        44.3572
      ],
      [
        -115.21227,
        44.35721
      ],
      [
        -115.21212,
        44.35736
      ],
      [
        -115.21205,
        44.35741
      ],
      [
        -115.21196,
        44.3575
      ],
      [
        -115.21074,
        44.35857
      ],
      [
        -115.21055,
        44.35875
      ],
      [
        -115.21038,
        44.35889
      ],
      [
        -115.20929,
        44.35987
      ],
      [
        -115.20928,
        44.35989
      ],
      [
        -115.20918,
        44.35997
      ],
      [
        -115.20817,
        44.36088
      ],
      [
        -115.20801,
        44.36101
      ],
      [
        -115.20758,
        44.36139
      ],
      [
        -115.20753,
        44.36142
      ],
      [
        -115.20717,
        44.36173
      ],
      [
        -115.20628,
        44.36255
      ],
      [
        -115.20626,
        44.36256
      ],
      [
        -115.20584,
        44.36295
      ],
      [
        -115.20581,
        44.36297
      ],
      [
        -115.20563,
        44.36314
      ],
      [
        -115.2055,
        44.36325
      ],
      [
        -115.20548,
        44.36326
      ],
      [
        -115.20503,
        44.36367
      ],
      [
        -115.20502,
        44.36367
      ],
      [
        -115.20484,
        44.36385
      ],
      [
        -115.20454,
        44.36411
      ],
      [
        -115.20453,
        44.36413
      ],
      [
        -115.20363,
        44.36495
      ],
      [
        -115.20351,
        44.36507
      ],
      [
        -115.20324,
        44.36531
      ],
      [
        -115.20279,
        44.36579
      ],
      [
        -115.20247,
        44.36617
      ],
      [
        -115.20235,
        44.36633
      ],
      [
        -115.20234,
        44.36633
      ],
      [
        -115.20175,
        44.36712
      ],
      [
        -115.20174,
        44.36713
      ],
      [
        -115.20165,
        44.36728
      ],
      [
        -115.20154,
        44.36742
      ],
      [
        -115.20115,
        44.36801
      ],
      [
        -115.20059,
        44.36901
      ],
      [
        -115.20055,
        44.3691
      ],
      [
        -115.20052,
        44.36914
      ],
      [
        -115.20039,
        44.3694
      ],
      [
        -115.20006,
        44.36999
      ],
      [
        -115.19959,
        44.37076
      ],
      [
        -115.19912,
        44.37143
      ],
      [
        -115.19881,
        44.37183
      ],
      [
        -115.19878,
        44.37188
      ],
      [
        -115.19826,
        44.37249
      ],
      [
        -115.19504,
        44.37587
      ],
      [
        -115.19501,
        44.37591
      ],
      [
        -115.19444,
        44.37651
      ],
      [
        -115.19439,
        44.37655
      ],
      [
        -115.1912,
        44.37993
      ],
      [
        -115.19119,
        44.37995
      ],
      [
        -115.18986,
        44.38135
      ],
      [
        -115.18959,
        44.38166
      ],
      [
        -115.18824,
        44.38307
      ],
      [
        -115.18819,
        44.38313
      ],
      [
        -115.18355,
        44.38801
      ],
      [
        -115.18263,
        44.38882
      ],
      [
        -115.18246,
        44.38895
      ],
      [
        -115.18231,
        44.38908
      ],
      [
        -115.18165,
        44.38958
      ],
      [
        -115.18081,
        44.39015
      ],
      [
        -115.17972,
        44.39083
      ],
      [
        -115.1795,
        44.39094
      ],
      [
        -115.17918,
        44.39112
      ],
      [
        -115.17886,
        44.39128
      ],
      [
        -115.17857,
        44.39144
      ],
      [
        -115.17545,
        44.39302
      ],
      [
        -115.17508,
        44.39319
      ],
      [
        -115.17443,
        44.39344
      ],
      [
        -115.17348,
        44.39371
      ],
      [
        -115.17299,
        44.39382
      ],
      [
        -115.1721,
        44.39394
      ],
      [
        -115.17137,
        44.39399
      ],
      [
        -115.17084,
        44.394
      ],
      [
        -115.17016,
        44.39397
      ],
      [
        -115.16979,
        44.39394
      ],
      [
        -115.16898,
        44.39382
      ],
      [
        -115.16834,
        44.39367
      ],
      [
        -115.16777,
        44.39351
      ],
      [
        -115.1675,
        44.39341
      ],
      [
        -115.16731,
        44.39335
      ],
      [
        -115.16699,
        44.39323
      ],
      [
        -115.16637,
        44.39294
      ],
      [
        -115.16624,
        44.39286
      ],
      [
        -115.16604,
        44.39276
      ],
      [
        -115.1655,
        44.39243
      ],
      [
        -115.16542,
        44.39236
      ],
      [
        -115.16535,
        44.39232
      ],
      [
        -115.16518,
        44.39219
      ],
      [
        -115.16514,
        44.39217
      ],
      [
        -115.16512,
        44.39215
      ],
      [
        -115.16499,
        44.39205
      ],
      [
        -115.16469,
        44.39179
      ],
      [
        -115.16436,
        44.39145
      ],
      [
        -115.16403,
        44.39107
      ],
      [
        -115.16364,
        44.39049
      ],
      [
        -115.16357,
        44.39036
      ],
      [
        -115.16337,
        44.38991
      ],
      [
        -115.16323,
        44.38952
      ],
      [
        -115.16271,
        44.38777
      ],
      [
        -115.16268,
        44.38764
      ],
      [
        -115.16233,
        44.38642
      ],
      [
        -115.16233,
        44.38641
      ],
      [
        -115.16202,
        44.38524
      ],
      [
        -115.16196,
        44.38507
      ],
      [
        -115.16192,
        44.38492
      ],
      [
        -115.16166,
        44.38422
      ],
      [
        -115.16134,
        44.38353
      ],
      [
        -115.16123,
        44.38332
      ],
      [
        -115.16093,
        44.38282
      ],
      [
        -115.16083,
        44.38267
      ],
      [
        -115.16076,
        44.38258
      ],
      [
        -115.16059,
        44.38232
      ],
      [
        -115.1601,
        44.38172
      ],
      [
        -115.15971,
        44.38129
      ],
      [
        -115.15932,
        44.3809
      ],
      [
        -115.15897,
        44.38058
      ],
      [
        -115.15834,
        44.38006
      ],
      [
        -115.15766,
        44.37956
      ],
      [
        -115.15321,
        44.3767
      ],
      [
        -115.15167,
        44.37567
      ],
      [
        -115.15161,
        44.37564
      ],
      [
        -115.15152,
        44.37558
      ],
      [
        -115.15143,
        44.37551
      ],
      [
        -115.15091,
        44.37515
      ],
      [
        -115.15072,
        44.37499
      ],
      [
        -115.15044,
        44.37478
      ],
      [
        -115.14981,
        44.37425
      ],
      [
        -115.14912,
        44.37361
      ],
      [
        -115.14853,
        44.373
      ],
      [
        -115.14806,
        44.37247
      ],
      [
        -115.14795,
        44.37233
      ],
      [
        -115.14785,
        44.37222
      ],
      [
        -115.14757,
        44.37186
      ],
      [
        -115.14745,
        44.37172
      ],
      [
        -115.14713,
        44.37127
      ],
      [
        -115.14614,
        44.36977
      ],
      [
        -115.14519,
        44.36824
      ],
      [
        -115.14495,
        44.36788
      ],
      [
        -115.14472,
        44.36749
      ],
      [
        -115.14354,
        44.36576
      ],
      [
        -115.14327,
        44.3654
      ],
      [
        -115.14319,
        44.36528
      ],
      [
        -115.14224,
        44.36408
      ],
      [
        -115.1412,
        44.3629
      ],
      [
        -115.14115,
        44.36286
      ],
      [
        -115.14101,
        44.36271
      ],
      [
        -115.14099,
        44.36268
      ],
      [
        -115.14022,
        44.36188
      ],
      [
        -115.13894,
        44.36068
      ],
      [
        -115.13783,
        44.35972
      ],
      [
        -115.13707,
        44.35912
      ],
      [
        -115.13692,
        44.35899
      ],
      [
        -115.13629,
        44.35851
      ],
      [
        -115.13627,
        44.3585
      ],
      [
        -115.13344,
        44.35648
      ],
      [
        -115.13328,
        44.35635
      ],
      [
        -115.13233,
        44.35565
      ],
      [
        -115.13025,
        44.35403
      ],
      [
        -115.12876,
        44.35276
      ],
      [
        -115.12873,
        44.35274
      ],
      [
        -115.12801,
        44.35212
      ],
      [
        -115.12792,
        44.35203
      ],
      [
        -115.12707,
        44.35127
      ],
      [
        -115.12707,
        44.35126
      ],
      [
        -115.12632,
        44.35057
      ],
      [
        -115.12618,
        44.35042
      ],
      [
        -115.12481,
        44.34912
      ],
      [
        -115.12478,
        44.34908
      ],
      [
        -115.12458,
        44.3489
      ],
      [
        -115.12358,
        44.34792
      ],
      [
        -115.12352,
        44.34787
      ],
      [
        -115.12287,
        44.34723
      ],
      [
        -115.12247,
        44.34686
      ],
      [
        -115.12244,
        44.34682
      ],
      [
        -115.11982,
        44.3443
      ],
      [
        -115.11974,
        44.34421
      ],
      [
        -115.11496,
        44.33959
      ],
      [
        -115.11489,
        44.33953
      ],
      [
        -115.11286,
        44.33757
      ],
      [
        -115.10143,
        44.32564
      ],
      [
        -115.10143,
        44.32563
      ],
      [
        -115.09508,
        44.319
      ],
      [
        -115.09464,
        44.31858
      ],
      [
        -115.09439,
        44.31836
      ],
      [
        -115.09411,
        44.31814
      ],
      [
        -115.09381,
        44.31793
      ],
      [
        -115.09334,
        44.31763
      ],
      [
        -115.09308,
        44.31748
      ],
      [
        -115.09247,
        44.31717
      ],
      [
        -115.09154,
        44.31681
      ],
      [
        -115.09058,
        44.31653
      ],
      [
        -115.08996,
        44.3164
      ],
      [
        -115.08922,
        44.31629
      ],
      [
        -115.08798,
        44.31617
      ],
      [
        -115.08662,
        44.31607
      ],
      [
        -115.08571,
        44.31597
      ],
      [
        -115.08483,
        44.31583
      ],
      [
        -115.08429,
        44.31572
      ],
      [
        -115.08302,
        44.31542
      ],
      [
        -115.07928,
        44.31445
      ],
      [
        -115.06318,
        44.30982
      ],
      [
        -115.06317,
        44.30982
      ],
      [
        -115.06191,
        44.30945
      ],
      [
        -115.06091,
        44.30911
      ],
      [
        -115.05936,
        44.3085
      ],
      [
        -115.0586,
        44.30815
      ],
      [
        -115.05779,
        44.30775
      ],
      [
        -115.05658,
        44.30707
      ],
      [
        -115.05581,
        44.30659
      ],
      [
        -115.05522,
        44.30618
      ],
      [
        -115.0543,
        44.30547
      ],
      [
        -115.04976,
        44.30177
      ],
      [
        -115.04975,
        44.30177
      ],
      [
        -115.04799,
        44.30032
      ],
      [
        -115.04793,
        44.30028
      ],
      [
        -115.02635,
        44.28269
      ],
      [
        -115.02624,
        44.28259
      ],
      [
        -115.02185,
        44.27902
      ],
      [
        -115.01977,
        44.27725
      ],
      [
        -115.01859,
        44.27613
      ],
      [
        -115.01768,
        44.27519
      ],
      [
        -115.01668,
        44.27409
      ],
      [
        -115.01663,
        44.27402
      ],
      [
        -115.01592,
        44.27316
      ],
      [
        -115.01583,
        44.27304
      ],
      [
        -115.01579,
        44.273
      ],
      [
        -115.01534,
        44.27241
      ],
      [
        -115.01532,
        44.27237
      ],
      [
        -115.0152,
        44.27221
      ],
      [
        -115.01424,
        44.27082
      ],
      [
        -115.01417,
        44.27068
      ],
      [
        -115.01335,
        44.26932
      ],
      [
        -115.01314,
        44.26893
      ],
      [
        -115.01312,
        44.2689
      ],
      [
        -115.01256,
        44.26784
      ],
      [
        -115.01221,
        44.26707
      ],
      [
        -115.01192,
        44.26638
      ],
      [
        -115.01191,
        44.26634
      ],
      [
        -115.01185,
        44.26621
      ],
      [
        -115.01159,
        44.26551
      ],
      [
        -115.0106,
        44.2632
      ],
      [
        -115.01053,
        44.26298
      ],
      [
        -115.01014,
        44.26197
      ],
      [
        -115.00967,
        44.26092
      ],
      [
        -115.00807,
        44.25714
      ],
      [
        -115.00799,
        44.25697
      ],
      [
        -115.00672,
        44.25392
      ],
      [
        -115.0066,
        44.25359
      ],
      [
        -115.00635,
        44.25301
      ],
      [
        -115.00634,
        44.253
      ],
      [
        -115.0062,
        44.25266
      ],
      [
        -115.00579,
        44.25155
      ],
      [
        -115.0055,
        44.25066
      ],
      [
        -115.00525,
        44.24978
      ],
      [
        -115.00423,
        44.24561
      ],
      [
        -115.00415,
        44.24522
      ],
      [
        -115.0035,
        44.24259
      ],
      [
        -115.0034,
        44.24225
      ],
      [
        -115.00325,
        44.24183
      ],
      [
        -115.00301,
        44.24133
      ],
      [
        -115.00286,
        44.24107
      ],
      [
        -115.0026,
        44.2407
      ],
      [
        -115.00232,
        44.24033
      ],
      [
        -115.00178,
        44.23977
      ],
      [
        -115.00114,
        44.23923
      ],
      [
        -115.00099,
        44.23913
      ],
      [
        -114.99981,
        44.23827
      ],
      [
        -114.99855,
        44.23741
      ],
      [
        -114.99607,
        44.23579
      ],
      [
        -114.98826,
        44.23112
      ],
      [
        -114.98825,
        44.23111
      ],
      [
        -114.98325,
        44.22812
      ],
      [
        -114.98305,
        44.22801
      ],
      [
        -114.97348,
        44.22231
      ],
      [
        -114.97319,
        44.22215
      ],
      [
        -114.97215,
        44.22163
      ],
      [
        -114.9716,
        44.22142
      ],
      [
        -114.97111,
        44.22125
      ],
      [
        -114.97052,
        44.22107
      ],
      [
        -114.9696,
        44.22085
      ],
      [
        -114.96912,
        44.22076
      ],
      [
        -114.96786,
        44.22059
      ],
      [
        -114.96785,
        44.22058
      ],
      [
        -114.93712,
        44.21725
      ],
      [
        -114.93715,
        44.21714
      ],
      [
        -114.93718,
        44.21697
      ],
      [
        -114.93716,
        44.2152
      ],
      [
        -114.93704,
        44.21519
      ],
      [
        -114.93683,
        44.21519
      ],
      [
        -114.93644,
        44.21522
      ],
      [
        -114.93641,
        44.21523
      ],
      [
        -114.93632,
        44.21523
      ],
      [
        -114.93615,
        44.21525
      ],
      [
        -114.93604,
        44.2153
      ],
      [
        -114.9359,
        44.21545
      ],
      [
        -114.93586,
        44.21551
      ],
      [
        -114.93585,
        44.21556
      ]
            ],
        },
        id: "f54eef73-5b2d-4ce6-ba54-5c29f07b372e"
    }]
}
export default PonderosaPines;



