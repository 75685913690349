import { useDispatch, useSelector } from "react-redux";
import { updateByways } from "../../../store/actions/bywaysActions";
import { groupedByways } from "../../../data/filters-data";
import { useState } from "react";
import { IconCheck } from "../../../images/icons";

const ScenicByways = () => {
	const dispatch = useDispatch();

	const [activeTab, setActiveTab] = useState(0);

	const storeByways = useSelector((state) => state.byways.selectedByways);

	const setBywayActive = (selectedByway) => {
		const selectedValues = selectedByway.split(",");
		const updatedByways = selectedValues.reduce(
			(acc, byway) => {
				if (storeByways.includes(byway)) {
					acc = acc.filter((item) => item !== byway);
				} else {
					acc.push(byway);
				}
				return acc;
			},
			[...storeByways]
		);

		dispatch(updateByways(updatedByways));
	};

	return (
		<div>
			<h2>Regions & Scenic Byways</h2>
			<div className="tabs">
				{groupedByways &&
					groupedByways.map((region, i) => (
						<button
							key={`btn-` + region.value + i}
							className={`tab ${i === activeTab ? "active" : ""}`}
							style={{ fontFamily: "'europa', sans-serif" }}
							onClick={() => setActiveTab(i)}
						>
							{region.label}
						</button>
					))}
			</div>
			<div className="tab-content">
				{groupedByways &&
					groupedByways.map((region, i) => (
						<div
							key={`content-` + region.value + i}
							className={`tab-pane ${
								i === activeTab ? "active" : ""
							}`}
							style={{ padding: "0" }}
						>
							<h3 className="region-label">{region.label}</h3>
							<p style={{ margin: "0 auto" }}>{region.cities}</p>
							<div className="region-description-image-container">
								<img
									src={region.image}
									alt={region.label}
									className="region-image"
								/>
								<p className="region-description">
									{region.description}
								</p>
							</div>

							<p>
								<strong>
									Click on a byway below to highlight it on
									the map, and then select "View on Map" to
									return to the map.
								</strong>
							</p>
							<div className="byways-list" style={{
								display: "flex",
								flexDirection: "column",
								gap: "0.35rem"
							}}>
							{region.options.map((byway, i) => (
								<a
									key={i}
									onClick={() => setBywayActive(byway.value)}
									className={`byway-item ${
										byway.value
											.split(",")
											.every((v) =>
												storeByways.includes(v)
											)
											? "active"
											: ""
									}`}
									style={{
										display: "flex",
										justifyContent: "center",
									}}
								>
									<p
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "center",
											margin: "auto",
										}}
									>
										{byway.value
											.split(",")
											.every((v) =>
												storeByways.includes(v)
											) && (
											<IconCheck width={12} height={12} />
										)}
										{byway.label}
									</p>
								</a>
							))}
							</div>
						</div>
					))}
			</div>
		</div>
	);
};
export default ScenicByways;
