const PanhandleHistoric = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Panhandle Historic Rivers Passage Scenic Byway",
				description: "Encompassing the southern leg of the International Selkirk Loop, the Panhandle Historic Rivers Passage follows the northern shore of the Pend Oreille River and passes scenic forests and farms. Expect to see a variety of wildlife, birds of prey and waterfowl depending on the season. Along the byway, learn how Ice Age and the Great Missoula Floods shaped northern Idaho.",
				link: "https://visitidaho.org/things-to-do/road-trips/panhandle-historic-rivers-passage-scenic-byway/",
				route: [
					"Oldtown", 
					"Priest River", 
					"Dover",
          "Sandpoint"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
          [
            -117.03819,
            48.1788,
            0
          ],
          [
            -117.03821,
            48.1786,
            0
          ],
          [
            -117.0382,
            48.1784,
            0
          ],
          [
            -117.03823,
            48.17824,
            0
          ],
          [
            -117.03821,
            48.17799,
            0
          ],
          [
            -117.03862,
            48.178,
            0
          ],
          [
            -117.03964,
            48.178,
            0
          ],
          [
            -117.03964,
            48.17852,
            0
          ],
          [
            -117.03962,
            48.17892,
            0
          ],
          [
            -117.03962,
            48.17909,
            0
          ],
          [
            -117.0396,
            48.17925,
            0
          ],
          [
            -117.03961,
            48.17952,
            0
          ],
          [
            -117.03961,
            48.17988,
            0
          ],
          [
            -117.0396,
            48.18007,
            0
          ],
          [
            -117.0396,
            48.18032,
            0
          ],
          [
            -117.03958,
            48.18062,
            0
          ],
          [
            -117.03959,
            48.18138,
            0
          ],
          [
            -117.03954,
            48.18327,
            0
          ],
          [
            -117.03954,
            48.18363,
            0
          ],
          [
            -117.03953,
            48.18397,
            0
          ],
          [
            -117.03795,
            48.18396,
            0
          ],
          [
            -117.0379,
            48.18397,
            0
          ],
          [
            -117.03777,
            48.18397,
            0
          ],
          [
            -117.03771,
            48.18398,
            0
          ],
          [
            -117.03763,
            48.18398,
            0
          ],
          [
            -117.03748,
            48.184,
            0
          ],
          [
            -117.0372,
            48.18406,
            0
          ],
          [
            -117.03696,
            48.18413,
            0
          ],
          [
            -117.03676,
            48.1842,
            0
          ],
          [
            -117.03671,
            48.18421,
            0
          ],
          [
            -117.03652,
            48.18427,
            0
          ],
          [
            -117.0363,
            48.18435,
            0
          ],
          [
            -117.03625,
            48.18436,
            0
          ],
          [
            -117.03541,
            48.18468,
            0
          ],
          [
            -117.03518,
            48.18478,
            0
          ],
          [
            -117.03494,
            48.18487,
            0
          ],
          [
            -117.03487,
            48.18489,
            0
          ],
          [
            -117.03401,
            48.18522,
            0
          ],
          [
            -117.03378,
            48.18532,
            0
          ],
          [
            -117.03308,
            48.18559,
            0
          ],
          [
            -117.03301,
            48.18561,
            0
          ],
          [
            -117.03261,
            48.18577,
            0
          ],
          [
            -117.03257,
            48.18579,
            0
          ],
          [
            -117.03215,
            48.18595,
            0
          ],
          [
            -117.03197,
            48.18603,
            0
          ],
          [
            -117.03151,
            48.18621,
            0
          ],
          [
            -117.03096,
            48.18641,
            0
          ],
          [
            -117.03071,
            48.18649,
            0
          ],
          [
            -117.03039,
            48.18658,
            0
          ],
          [
            -117.0303,
            48.1866,
            0
          ],
          [
            -117.03024,
            48.18662,
            0
          ],
          [
            -117.02987,
            48.1867,
            0
          ],
          [
            -117.02933,
            48.18678,
            0
          ],
          [
            -117.02883,
            48.18687,
            0
          ],
          [
            -117.02817,
            48.18693,
            0
          ],
          [
            -117.02806,
            48.18693,
            0
          ],
          [
            -117.02801,
            48.18694,
            0
          ],
          [
            -117.02777,
            48.18694,
            0
          ],
          [
            -117.02772,
            48.18695,
            0
          ],
          [
            -117.02751,
            48.18695,
            0
          ],
          [
            -117.02736,
            48.18694,
            0
          ],
          [
            -117.02666,
            48.18693,
            0
          ],
          [
            -117.02645,
            48.18691,
            0
          ],
          [
            -117.02637,
            48.18691,
            0
          ],
          [
            -117.02614,
            48.18688,
            0
          ],
          [
            -117.02603,
            48.18686,
            0
          ],
          [
            -117.02597,
            48.18686,
            0
          ],
          [
            -117.02587,
            48.18684,
            0
          ],
          [
            -117.02578,
            48.18683,
            0
          ],
          [
            -117.02468,
            48.18664,
            0
          ],
          [
            -117.02111,
            48.18596,
            0
          ],
          [
            -117.0209,
            48.18593,
            0
          ],
          [
            -117.02044,
            48.18584,
            0
          ],
          [
            -117.02031,
            48.18582,
            0
          ],
          [
            -117.0201,
            48.18577,
            0
          ],
          [
            -117.01991,
            48.18574,
            0
          ],
          [
            -117.01972,
            48.1857,
            0
          ],
          [
            -117.01952,
            48.18567,
            0
          ],
          [
            -117.01875,
            48.18552,
            0
          ],
          [
            -117.01856,
            48.18549,
            0
          ],
          [
            -117.01817,
            48.18541,
            0
          ],
          [
            -117.01661,
            48.18513,
            0
          ],
          [
            -117.01643,
            48.18509,
            0
          ],
          [
            -117.01622,
            48.18506,
            0
          ],
          [
            -117.01584,
            48.18498,
            0
          ],
          [
            -117.01565,
            48.18495,
            0
          ],
          [
            -117.01546,
            48.18491,
            0
          ],
          [
            -117.01527,
            48.18488,
            0
          ],
          [
            -117.01507,
            48.18484,
            0
          ],
          [
            -117.01447,
            48.18474,
            0
          ],
          [
            -117.01392,
            48.18463,
            0
          ],
          [
            -117.01373,
            48.1846,
            0
          ],
          [
            -117.01353,
            48.18456,
            0
          ],
          [
            -117.01296,
            48.18447,
            0
          ],
          [
            -117.01241,
            48.18437,
            0
          ],
          [
            -117.01176,
            48.18427,
            0
          ],
          [
            -117.01162,
            48.18424,
            0
          ],
          [
            -117.01157,
            48.18424,
            0
          ],
          [
            -117.01138,
            48.18421,
            0
          ],
          [
            -117.01136,
            48.1842,
            0
          ],
          [
            -117.00807,
            48.18371,
            0
          ],
          [
            -117.00787,
            48.18369,
            0
          ],
          [
            -117.00745,
            48.18363,
            0
          ],
          [
            -117.00706,
            48.18359,
            0
          ],
          [
            -117.00456,
            48.18327,
            0
          ],
          [
            -117.00454,
            48.18326,
            0
          ],
          [
            -117.0025,
            48.18301,
            0
          ],
          [
            -117.00235,
            48.183,
            0
          ],
          [
            -117.00215,
            48.18297,
            0
          ],
          [
            -117.00171,
            48.18292,
            0
          ],
          [
            -117.00164,
            48.18292,
            0
          ],
          [
            -117.0014,
            48.18289,
            0
          ],
          [
            -117.00105,
            48.18286,
            0
          ],
          [
            -117.00082,
            48.18283,
            0
          ],
          [
            -116.99991,
            48.18274,
            0
          ],
          [
            -116.99973,
            48.18273,
            0
          ],
          [
            -116.99959,
            48.18271,
            0
          ],
          [
            -116.99935,
            48.18269,
            0
          ],
          [
            -116.99902,
            48.18265,
            0
          ],
          [
            -116.99898,
            48.18264,
            0
          ],
          [
            -116.99895,
            48.18264,
            0
          ],
          [
            -116.99888,
            48.18263,
            0
          ],
          [
            -116.99857,
            48.18257,
            0
          ],
          [
            -116.99843,
            48.18255,
            0
          ],
          [
            -116.99812,
            48.18248,
            0
          ],
          [
            -116.99802,
            48.18245,
            0
          ],
          [
            -116.99781,
            48.1824,
            0
          ],
          [
            -116.99769,
            48.18236,
            0
          ],
          [
            -116.99756,
            48.18233,
            0
          ],
          [
            -116.9974,
            48.18228,
            0
          ],
          [
            -116.99724,
            48.18222,
            0
          ],
          [
            -116.99714,
            48.18219,
            0
          ],
          [
            -116.99707,
            48.18216,
            0
          ],
          [
            -116.99688,
            48.18209,
            0
          ],
          [
            -116.99678,
            48.18206,
            0
          ],
          [
            -116.99642,
            48.1819,
            0
          ],
          [
            -116.99631,
            48.18184,
            0
          ],
          [
            -116.99587,
            48.18165,
            0
          ],
          [
            -116.9955,
            48.18147,
            0
          ],
          [
            -116.99534,
            48.1814,
            0
          ],
          [
            -116.99533,
            48.1814,
            0
          ],
          [
            -116.99489,
            48.18121,
            0
          ],
          [
            -116.99477,
            48.18117,
            0
          ],
          [
            -116.9947,
            48.18114,
            0
          ],
          [
            -116.99424,
            48.18097,
            0
          ],
          [
            -116.99349,
            48.18076,
            0
          ],
          [
            -116.99277,
            48.1806,
            0
          ],
          [
            -116.99238,
            48.18053,
            0
          ],
          [
            -116.99194,
            48.18047,
            0
          ],
          [
            -116.99189,
            48.18046,
            0
          ],
          [
            -116.99164,
            48.18044,
            0
          ],
          [
            -116.99152,
            48.18042,
            0
          ],
          [
            -116.99082,
            48.18037,
            0
          ],
          [
            -116.98428,
            48.18021,
            0
          ],
          [
            -116.98122,
            48.18019,
            0
          ],
          [
            -116.98102,
            48.1802,
            0
          ],
          [
            -116.98081,
            48.1802,
            0
          ],
          [
            -116.98001,
            48.18023,
            0
          ],
          [
            -116.97987,
            48.18023,
            0
          ],
          [
            -116.97864,
            48.18028,
            0
          ],
          [
            -116.9781,
            48.18032,
            0
          ],
          [
            -116.97764,
            48.18034,
            0
          ],
          [
            -116.97734,
            48.18037,
            0
          ],
          [
            -116.97678,
            48.18041,
            0
          ],
          [
            -116.97652,
            48.18044,
            0
          ],
          [
            -116.97632,
            48.18045,
            0
          ],
          [
            -116.97486,
            48.18059,
            0
          ],
          [
            -116.97398,
            48.18066,
            0
          ],
          [
            -116.9729,
            48.18072,
            0
          ],
          [
            -116.97149,
            48.18077,
            0
          ],
          [
            -116.97134,
            48.18078,
            0
          ],
          [
            -116.96968,
            48.1808,
            0
          ],
          [
            -116.96742,
            48.18078,
            0
          ],
          [
            -116.96725,
            48.18077,
            0
          ],
          [
            -116.96499,
            48.18077,
            0
          ],
          [
            -116.96354,
            48.18075,
            0
          ],
          [
            -116.9615,
            48.18075,
            0
          ],
          [
            -116.96112,
            48.18074,
            0
          ],
          [
            -116.95937,
            48.18074,
            0
          ],
          [
            -116.95907,
            48.18073,
            0
          ],
          [
            -116.95537,
            48.18072,
            0
          ],
          [
            -116.95401,
            48.1807,
            0
          ],
          [
            -116.95119,
            48.1807,
            0
          ],
          [
            -116.95092,
            48.18069,
            0
          ],
          [
            -116.94374,
            48.18067,
            0
          ],
          [
            -116.94321,
            48.18068,
            0
          ],
          [
            -116.94082,
            48.18068,
            0
          ],
          [
            -116.94039,
            48.18067,
            0
          ],
          [
            -116.93956,
            48.18067,
            0
          ],
          [
            -116.93929,
            48.18066,
            0
          ],
          [
            -116.93307,
            48.18064,
            0
          ],
          [
            -116.93274,
            48.18063,
            0
          ],
          [
            -116.9258,
            48.18061,
            0
          ],
          [
            -116.9257,
            48.18062,
            0
          ],
          [
            -116.92345,
            48.18061,
            0
          ],
          [
            -116.92325,
            48.1806,
            0
          ],
          [
            -116.92079,
            48.1806,
            0
          ],
          [
            -116.92041,
            48.18059,
            0
          ],
          [
            -116.91842,
            48.18059,
            0
          ],
          [
            -116.91798,
            48.18058,
            0
          ],
          [
            -116.91727,
            48.18059,
            0
          ],
          [
            -116.91687,
            48.18058,
            0
          ],
          [
            -116.90937,
            48.18056,
            0
          ],
          [
            -116.90897,
            48.18055,
            0
          ],
          [
            -116.90618,
            48.18055,
            0
          ],
          [
            -116.90564,
            48.18054,
            0
          ],
          [
            -116.89736,
            48.18052,
            0
          ],
          [
            -116.89697,
            48.18051,
            0
          ],
          [
            -116.89477,
            48.18051,
            0
          ],
          [
            -116.89426,
            48.18052,
            0
          ],
          [
            -116.89208,
            48.18052,
            0
          ],
          [
            -116.89177,
            48.18053,
            0
          ],
          [
            -116.89135,
            48.18052,
            0
          ],
          [
            -116.89123,
            48.18051,
            0
          ],
          [
            -116.89115,
            48.18051,
            0
          ],
          [
            -116.89101,
            48.1805,
            0
          ],
          [
            -116.89092,
            48.1805,
            0
          ],
          [
            -116.89079,
            48.18049,
            0
          ],
          [
            -116.8907,
            48.18049,
            0
          ],
          [
            -116.89023,
            48.18044,
            0
          ],
          [
            -116.88954,
            48.18032,
            0
          ],
          [
            -116.8894,
            48.18028,
            0
          ],
          [
            -116.88924,
            48.18025,
            0
          ],
          [
            -116.88891,
            48.18016,
            0
          ],
          [
            -116.88832,
            48.17997,
            0
          ],
          [
            -116.88813,
            48.1799,
            0
          ],
          [
            -116.88797,
            48.17983,
            0
          ],
          [
            -116.88786,
            48.17979,
            0
          ],
          [
            -116.88772,
            48.17972,
            0
          ],
          [
            -116.88758,
            48.17966,
            0
          ],
          [
            -116.8875,
            48.17962,
            0
          ],
          [
            -116.88745,
            48.17959,
            0
          ],
          [
            -116.88733,
            48.17953,
            0
          ],
          [
            -116.88691,
            48.17929,
            0
          ],
          [
            -116.88661,
            48.1791,
            0
          ],
          [
            -116.88647,
            48.17902,
            0
          ],
          [
            -116.88572,
            48.17854,
            0
          ],
          [
            -116.88551,
            48.17842,
            0
          ],
          [
            -116.88521,
            48.17823,
            0
          ],
          [
            -116.88486,
            48.17803,
            0
          ],
          [
            -116.8847,
            48.17793,
            0
          ],
          [
            -116.88439,
            48.17776,
            0
          ],
          [
            -116.8843,
            48.1777,
            0
          ],
          [
            -116.88407,
            48.17758,
            0
          ],
          [
            -116.88397,
            48.17752,
            0
          ],
          [
            -116.88386,
            48.17747,
            0
          ],
          [
            -116.88374,
            48.1774,
            0
          ],
          [
            -116.88363,
            48.17735,
            0
          ],
          [
            -116.88352,
            48.17729,
            0
          ],
          [
            -116.88344,
            48.17724,
            0
          ],
          [
            -116.88307,
            48.17706,
            0
          ],
          [
            -116.88296,
            48.177,
            0
          ],
          [
            -116.88289,
            48.17697,
            0
          ],
          [
            -116.8828,
            48.17692,
            0
          ],
          [
            -116.88273,
            48.17689,
            0
          ],
          [
            -116.88266,
            48.17685,
            0
          ],
          [
            -116.88258,
            48.17682,
            0
          ],
          [
            -116.88251,
            48.17678,
            0
          ],
          [
            -116.88243,
            48.17675,
            0
          ],
          [
            -116.88231,
            48.17669,
            0
          ],
          [
            -116.88224,
            48.17666,
            0
          ],
          [
            -116.8821,
            48.17659,
            0
          ],
          [
            -116.88206,
            48.17658,
            0
          ],
          [
            -116.88201,
            48.17655,
            0
          ],
          [
            -116.88196,
            48.17653,
            0
          ],
          [
            -116.88192,
            48.17651,
            0
          ],
          [
            -116.88187,
            48.17649,
            0
          ],
          [
            -116.88181,
            48.17646,
            0
          ],
          [
            -116.88171,
            48.17642,
            0
          ],
          [
            -116.88163,
            48.17638,
            0
          ],
          [
            -116.88156,
            48.17635,
            0
          ],
          [
            -116.88148,
            48.17631,
            0
          ],
          [
            -116.88142,
            48.17629,
            0
          ],
          [
            -116.88135,
            48.17626,
            0
          ],
          [
            -116.88128,
            48.17622,
            0
          ],
          [
            -116.88119,
            48.17619,
            0
          ],
          [
            -116.88111,
            48.17615,
            0
          ],
          [
            -116.88104,
            48.17612,
            0
          ],
          [
            -116.88095,
            48.17609,
            0
          ],
          [
            -116.88089,
            48.17606,
            0
          ],
          [
            -116.88081,
            48.17603,
            0
          ],
          [
            -116.88075,
            48.176,
            0
          ],
          [
            -116.88046,
            48.17589,
            0
          ],
          [
            -116.88012,
            48.17575,
            0
          ],
          [
            -116.87995,
            48.17569,
            0
          ],
          [
            -116.8796,
            48.17555,
            0
          ],
          [
            -116.87942,
            48.17549,
            0
          ],
          [
            -116.87925,
            48.17542,
            0
          ],
          [
            -116.87906,
            48.17536,
            0
          ],
          [
            -116.87889,
            48.17529,
            0
          ],
          [
            -116.87871,
            48.17523,
            0
          ],
          [
            -116.87765,
            48.17484,
            0
          ],
          [
            -116.87748,
            48.17477,
            0
          ],
          [
            -116.8773,
            48.17471,
            0
          ],
          [
            -116.8766,
            48.17445,
            0
          ],
          [
            -116.87605,
            48.17426,
            0
          ],
          [
            -116.87588,
            48.17419,
            0
          ],
          [
            -116.87569,
            48.17413,
            0
          ],
          [
            -116.87552,
            48.17406,
            0
          ],
          [
            -116.87534,
            48.174,
            0
          ],
          [
            -116.87498,
            48.17386,
            0
          ],
          [
            -116.87445,
            48.17368,
            0
          ],
          [
            -116.87428,
            48.17361,
            0
          ],
          [
            -116.87391,
            48.17348,
            0
          ],
          [
            -116.87374,
            48.17341,
            0
          ],
          [
            -116.87338,
            48.17329,
            0
          ],
          [
            -116.87321,
            48.17322,
            0
          ],
          [
            -116.87302,
            48.17316,
            0
          ],
          [
            -116.87267,
            48.17302,
            0
          ],
          [
            -116.87249,
            48.17296,
            0
          ],
          [
            -116.87229,
            48.17288,
            0
          ],
          [
            -116.87172,
            48.17268,
            0
          ],
          [
            -116.87155,
            48.17261,
            0
          ],
          [
            -116.87137,
            48.17255,
            0
          ],
          [
            -116.87118,
            48.17247,
            0
          ],
          [
            -116.87099,
            48.1724,
            0
          ],
          [
            -116.87064,
            48.17225,
            0
          ],
          [
            -116.87046,
            48.17218,
            0
          ],
          [
            -116.8698,
            48.17188,
            0
          ],
          [
            -116.86947,
            48.17172,
            0
          ],
          [
            -116.86929,
            48.17164,
            0
          ],
          [
            -116.86836,
            48.17116,
            0
          ],
          [
            -116.86727,
            48.17053,
            0
          ],
          [
            -116.86711,
            48.17043,
            0
          ],
          [
            -116.86698,
            48.17034,
            0
          ],
          [
            -116.86683,
            48.17025,
            0
          ],
          [
            -116.86654,
            48.17006,
            0
          ],
          [
            -116.86611,
            48.16976,
            0
          ],
          [
            -116.86584,
            48.16956,
            0
          ],
          [
            -116.86572,
            48.16948,
            0
          ],
          [
            -116.86528,
            48.16914,
            0
          ],
          [
            -116.86515,
            48.16903,
            0
          ],
          [
            -116.86502,
            48.16893,
            0
          ],
          [
            -116.86418,
            48.16823,
            0
          ],
          [
            -116.86406,
            48.16812,
            0
          ],
          [
            -116.86366,
            48.16779,
            0
          ],
          [
            -116.86352,
            48.16766,
            0
          ],
          [
            -116.86339,
            48.16756,
            0
          ],
          [
            -116.86327,
            48.16745,
            0
          ],
          [
            -116.8626,
            48.16689,
            0
          ],
          [
            -116.86247,
            48.16677,
            0
          ],
          [
            -116.86204,
            48.16641,
            0
          ],
          [
            -116.86192,
            48.1663,
            0
          ],
          [
            -116.86179,
            48.16619,
            0
          ],
          [
            -116.86166,
            48.16609,
            0
          ],
          [
            -116.86133,
            48.1658,
            0
          ],
          [
            -116.86129,
            48.16576,
            0
          ],
          [
            -116.86079,
            48.16534,
            0
          ],
          [
            -116.86067,
            48.16523,
            0
          ],
          [
            -116.86066,
            48.16523,
            0
          ],
          [
            -116.86053,
            48.16512,
            0
          ],
          [
            -116.86041,
            48.16501,
            0
          ],
          [
            -116.86028,
            48.16491,
            0
          ],
          [
            -116.85983,
            48.16453,
            0
          ],
          [
            -116.85978,
            48.16448,
            0
          ],
          [
            -116.85965,
            48.16438,
            0
          ],
          [
            -116.85953,
            48.16427,
            0
          ],
          [
            -116.85915,
            48.16395,
            0
          ],
          [
            -116.85903,
            48.16384,
            0
          ],
          [
            -116.8589,
            48.16374,
            0
          ],
          [
            -116.85878,
            48.16363,
            0
          ],
          [
            -116.85815,
            48.1631,
            0
          ],
          [
            -116.85802,
            48.16298,
            0
          ],
          [
            -116.85789,
            48.16288,
            0
          ],
          [
            -116.85777,
            48.16277,
            0
          ],
          [
            -116.85764,
            48.16267,
            0
          ],
          [
            -116.85752,
            48.16256,
            0
          ],
          [
            -116.8575,
            48.16255,
            0
          ],
          [
            -116.85739,
            48.16245,
            0
          ],
          [
            -116.85667,
            48.16184,
            0
          ],
          [
            -116.85652,
            48.1617,
            0
          ],
          [
            -116.85614,
            48.16139,
            0
          ],
          [
            -116.85601,
            48.16127,
            0
          ],
          [
            -116.85576,
            48.16106,
            0
          ],
          [
            -116.85564,
            48.16095,
            0
          ],
          [
            -116.85538,
            48.16074,
            0
          ],
          [
            -116.85526,
            48.16063,
            0
          ],
          [
            -116.85438,
            48.15989,
            0
          ],
          [
            -116.85426,
            48.15978,
            0
          ],
          [
            -116.85376,
            48.15936,
            0
          ],
          [
            -116.85364,
            48.15925,
            0
          ],
          [
            -116.85339,
            48.15904,
            0
          ],
          [
            -116.85335,
            48.15901,
            0
          ],
          [
            -116.85301,
            48.15871,
            0
          ],
          [
            -116.85288,
            48.15861,
            0
          ],
          [
            -116.85276,
            48.1585,
            0
          ],
          [
            -116.85187,
            48.15775,
            0
          ],
          [
            -116.85175,
            48.15764,
            0
          ],
          [
            -116.8515,
            48.15743,
            0
          ],
          [
            -116.85137,
            48.15733,
            0
          ],
          [
            -116.85125,
            48.15722,
            0
          ],
          [
            -116.85088,
            48.15691,
            0
          ],
          [
            -116.85076,
            48.1568,
            0
          ],
          [
            -116.85063,
            48.15669,
            0
          ],
          [
            -116.8505,
            48.15657,
            0
          ],
          [
            -116.84948,
            48.15571,
            0
          ],
          [
            -116.84936,
            48.1556,
            0
          ],
          [
            -116.84861,
            48.15497,
            0
          ],
          [
            -116.84849,
            48.15486,
            0
          ],
          [
            -116.84836,
            48.15476,
            0
          ],
          [
            -116.84824,
            48.15465,
            0
          ],
          [
            -116.84786,
            48.15433,
            0
          ],
          [
            -116.8475,
            48.15401,
            0
          ],
          [
            -116.84736,
            48.1539,
            0
          ],
          [
            -116.84497,
            48.15186,
            0
          ],
          [
            -116.84484,
            48.15176,
            0
          ],
          [
            -116.84472,
            48.15165,
            0
          ],
          [
            -116.84422,
            48.15123,
            0
          ],
          [
            -116.8441,
            48.15112,
            0
          ],
          [
            -116.84359,
            48.15069,
            0
          ],
          [
            -116.84347,
            48.15058,
            0
          ],
          [
            -116.84334,
            48.15048,
            0
          ],
          [
            -116.84322,
            48.15037,
            0
          ],
          [
            -116.84309,
            48.15026,
            0
          ],
          [
            -116.84296,
            48.15016,
            0
          ],
          [
            -116.84285,
            48.15006,
            0
          ],
          [
            -116.8422,
            48.14953,
            0
          ],
          [
            -116.84192,
            48.14933,
            0
          ],
          [
            -116.84161,
            48.14913,
            0
          ],
          [
            -116.84127,
            48.14893,
            0
          ],
          [
            -116.84117,
            48.14888,
            0
          ],
          [
            -116.84101,
            48.14879,
            0
          ],
          [
            -116.84084,
            48.14871,
            0
          ],
          [
            -116.84067,
            48.14864,
            0
          ],
          [
            -116.8405,
            48.14856,
            0
          ],
          [
            -116.84033,
            48.14849,
            0
          ],
          [
            -116.83988,
            48.14833,
            0
          ],
          [
            -116.83979,
            48.14831,
            0
          ],
          [
            -116.83952,
            48.14822,
            0
          ],
          [
            -116.83907,
            48.14811,
            0
          ],
          [
            -116.83855,
            48.148,
            0
          ],
          [
            -116.83835,
            48.14797,
            0
          ],
          [
            -116.83824,
            48.14796,
            0
          ],
          [
            -116.83818,
            48.14795,
            0
          ],
          [
            -116.838,
            48.14793,
            0
          ],
          [
            -116.83781,
            48.1479,
            0
          ],
          [
            -116.8377,
            48.1479,
            0
          ],
          [
            -116.83754,
            48.14788,
            0
          ],
          [
            -116.83747,
            48.14788,
            0
          ],
          [
            -116.83743,
            48.14787,
            0
          ],
          [
            -116.83691,
            48.14785,
            0
          ],
          [
            -116.83642,
            48.14785,
            0
          ],
          [
            -116.83633,
            48.14786,
            0
          ],
          [
            -116.83621,
            48.14786,
            0
          ],
          [
            -116.83614,
            48.14787,
            0
          ],
          [
            -116.83607,
            48.14787,
            0
          ],
          [
            -116.83595,
            48.14788,
            0
          ],
          [
            -116.83588,
            48.14788,
            0
          ],
          [
            -116.83586,
            48.14789,
            0
          ],
          [
            -116.83576,
            48.14789,
            0
          ],
          [
            -116.83566,
            48.14791,
            0
          ],
          [
            -116.83553,
            48.14792,
            0
          ],
          [
            -116.83542,
            48.14794,
            0
          ],
          [
            -116.83528,
            48.14795,
            0
          ],
          [
            -116.83518,
            48.14797,
            0
          ],
          [
            -116.83503,
            48.14799,
            0
          ],
          [
            -116.8348,
            48.14803,
            0
          ],
          [
            -116.83463,
            48.14807,
            0
          ],
          [
            -116.83456,
            48.14808,
            0
          ],
          [
            -116.83454,
            48.14808,
            0
          ],
          [
            -116.83449,
            48.1481,
            0
          ],
          [
            -116.83442,
            48.14811,
            0
          ],
          [
            -116.83414,
            48.14818,
            0
          ],
          [
            -116.83374,
            48.1483,
            0
          ],
          [
            -116.83351,
            48.14838,
            0
          ],
          [
            -116.83341,
            48.14842,
            0
          ],
          [
            -116.83334,
            48.14844,
            0
          ],
          [
            -116.83332,
            48.14845,
            0
          ],
          [
            -116.8331,
            48.14853,
            0
          ],
          [
            -116.83304,
            48.14856,
            0
          ],
          [
            -116.83279,
            48.14866,
            0
          ],
          [
            -116.83228,
            48.14889,
            0
          ],
          [
            -116.83049,
            48.14978,
            0
          ],
          [
            -116.83046,
            48.1498,
            0
          ],
          [
            -116.83013,
            48.14996,
            0
          ],
          [
            -116.82978,
            48.15011,
            0
          ],
          [
            -116.82945,
            48.15027,
            0
          ],
          [
            -116.82935,
            48.15031,
            0
          ],
          [
            -116.82914,
            48.15041,
            0
          ],
          [
            -116.82786,
            48.15107,
            0
          ],
          [
            -116.8277,
            48.15114,
            0
          ],
          [
            -116.82767,
            48.15116,
            0
          ],
          [
            -116.82754,
            48.15122,
            0
          ],
          [
            -116.82738,
            48.15131,
            0
          ],
          [
            -116.82723,
            48.15138,
            0
          ],
          [
            -116.82716,
            48.15142,
            0
          ],
          [
            -116.82705,
            48.15147,
            0
          ],
          [
            -116.82687,
            48.15157,
            0
          ],
          [
            -116.82671,
            48.15165,
            0
          ],
          [
            -116.82664,
            48.1517,
            0
          ],
          [
            -116.82657,
            48.15174,
            0
          ],
          [
            -116.82574,
            48.15216,
            0
          ],
          [
            -116.82479,
            48.15267,
            0
          ],
          [
            -116.82456,
            48.15278,
            0
          ],
          [
            -116.82442,
            48.15286,
            0
          ],
          [
            -116.82407,
            48.15302,
            0
          ],
          [
            -116.82399,
            48.15305,
            0
          ],
          [
            -116.82395,
            48.15307,
            0
          ],
          [
            -116.82389,
            48.15309,
            0
          ],
          [
            -116.82382,
            48.15312,
            0
          ],
          [
            -116.82363,
            48.15319,
            0
          ],
          [
            -116.82346,
            48.15326,
            0
          ],
          [
            -116.82329,
            48.15332,
            0
          ],
          [
            -116.82325,
            48.15334,
            0
          ],
          [
            -116.82304,
            48.15341,
            0
          ],
          [
            -116.82295,
            48.15345,
            0
          ],
          [
            -116.82273,
            48.15352,
            0
          ],
          [
            -116.82264,
            48.15356,
            0
          ],
          [
            -116.8225,
            48.1536,
            0
          ],
          [
            -116.82242,
            48.15363,
            0
          ],
          [
            -116.82229,
            48.15367,
            0
          ],
          [
            -116.82224,
            48.15368,
            0
          ],
          [
            -116.82186,
            48.15378,
            0
          ],
          [
            -116.82119,
            48.15393,
            0
          ],
          [
            -116.82111,
            48.15394,
            0
          ],
          [
            -116.82084,
            48.154,
            0
          ],
          [
            -116.82074,
            48.15401,
            0
          ],
          [
            -116.82022,
            48.15411,
            0
          ],
          [
            -116.82013,
            48.15412,
            0
          ],
          [
            -116.81984,
            48.15418,
            0
          ],
          [
            -116.81976,
            48.15419,
            0
          ],
          [
            -116.81925,
            48.15428,
            0
          ],
          [
            -116.81842,
            48.15438,
            0
          ],
          [
            -116.81838,
            48.15438,
            0
          ],
          [
            -116.81831,
            48.15439,
            0
          ],
          [
            -116.81792,
            48.15441,
            0
          ],
          [
            -116.81771,
            48.15443,
            0
          ],
          [
            -116.81626,
            48.15452,
            0
          ],
          [
            -116.81609,
            48.15452,
            0
          ],
          [
            -116.81558,
            48.15456,
            0
          ],
          [
            -116.81547,
            48.15456,
            0
          ],
          [
            -116.81537,
            48.15457,
            0
          ],
          [
            -116.8143,
            48.15463,
            0
          ],
          [
            -116.81409,
            48.15465,
            0
          ],
          [
            -116.81357,
            48.15468,
            0
          ],
          [
            -116.81352,
            48.15468,
            0
          ],
          [
            -116.81333,
            48.1547,
            0
          ],
          [
            -116.81293,
            48.15472,
            0
          ],
          [
            -116.81273,
            48.15474,
            0
          ],
          [
            -116.81233,
            48.15476,
            0
          ],
          [
            -116.81212,
            48.15478,
            0
          ],
          [
            -116.81191,
            48.15479,
            0
          ],
          [
            -116.81171,
            48.15481,
            0
          ],
          [
            -116.8115,
            48.15482,
            0
          ],
          [
            -116.8113,
            48.15484,
            0
          ],
          [
            -116.81109,
            48.15485,
            0
          ],
          [
            -116.8109,
            48.15487,
            0
          ],
          [
            -116.81068,
            48.15488,
            0
          ],
          [
            -116.81026,
            48.15492,
            0
          ],
          [
            -116.81006,
            48.15493,
            0
          ],
          [
            -116.80984,
            48.15495,
            0
          ],
          [
            -116.80965,
            48.15496,
            0
          ],
          [
            -116.80923,
            48.155,
            0
          ],
          [
            -116.80905,
            48.15501,
            0
          ],
          [
            -116.80885,
            48.15503,
            0
          ],
          [
            -116.80865,
            48.15504,
            0
          ],
          [
            -116.80847,
            48.15506,
            0
          ],
          [
            -116.80826,
            48.15507,
            0
          ],
          [
            -116.80785,
            48.15511,
            0
          ],
          [
            -116.80602,
            48.15525,
            0
          ],
          [
            -116.80596,
            48.15525,
            0
          ],
          [
            -116.80582,
            48.15526,
            0
          ],
          [
            -116.80569,
            48.15528,
            0
          ],
          [
            -116.80561,
            48.15528,
            0
          ],
          [
            -116.80545,
            48.1553,
            0
          ],
          [
            -116.80499,
            48.15533,
            0
          ],
          [
            -116.80479,
            48.15535,
            0
          ],
          [
            -116.80461,
            48.15536,
            0
          ],
          [
            -116.8042,
            48.1554,
            0
          ],
          [
            -116.804,
            48.15541,
            0
          ],
          [
            -116.8038,
            48.15543,
            0
          ],
          [
            -116.80361,
            48.15544,
            0
          ],
          [
            -116.80355,
            48.15545,
            0
          ],
          [
            -116.80302,
            48.15548,
            0
          ],
          [
            -116.80282,
            48.1555,
            0
          ],
          [
            -116.80261,
            48.15551,
            0
          ],
          [
            -116.80241,
            48.15553,
            0
          ],
          [
            -116.80207,
            48.15555,
            0
          ],
          [
            -116.80192,
            48.15555,
            0
          ],
          [
            -116.80098,
            48.1556,
            0
          ],
          [
            -116.80081,
            48.1556,
            0
          ],
          [
            -116.8003,
            48.15562,
            0
          ],
          [
            -116.8001,
            48.15562,
            0
          ],
          [
            -116.79985,
            48.15563,
            0
          ],
          [
            -116.79968,
            48.15563,
            0
          ],
          [
            -116.79957,
            48.15564,
            0
          ],
          [
            -116.79943,
            48.15564,
            0
          ],
          [
            -116.79929,
            48.15565,
            0
          ],
          [
            -116.79925,
            48.15565,
            0
          ],
          [
            -116.79837,
            48.15574,
            0
          ],
          [
            -116.79824,
            48.15576,
            0
          ],
          [
            -116.79817,
            48.15578,
            0
          ],
          [
            -116.79802,
            48.1558,
            0
          ],
          [
            -116.79794,
            48.15582,
            0
          ],
          [
            -116.79793,
            48.15582,
            0
          ],
          [
            -116.7978,
            48.15584,
            0
          ],
          [
            -116.79771,
            48.15586,
            0
          ],
          [
            -116.79765,
            48.15588,
            0
          ],
          [
            -116.79757,
            48.1559,
            0
          ],
          [
            -116.79749,
            48.15591,
            0
          ],
          [
            -116.7974,
            48.15594,
            0
          ],
          [
            -116.79725,
            48.15597,
            0
          ],
          [
            -116.79686,
            48.15607,
            0
          ],
          [
            -116.79673,
            48.15611,
            0
          ],
          [
            -116.79664,
            48.15613,
            0
          ],
          [
            -116.79658,
            48.15615,
            0
          ],
          [
            -116.79643,
            48.15619,
            0
          ],
          [
            -116.79641,
            48.15619,
            0
          ],
          [
            -116.7963,
            48.15623,
            0
          ],
          [
            -116.79616,
            48.15626,
            0
          ],
          [
            -116.79602,
            48.15631,
            0
          ],
          [
            -116.79457,
            48.1567,
            0
          ],
          [
            -116.79441,
            48.15675,
            0
          ],
          [
            -116.79427,
            48.15678,
            0
          ],
          [
            -116.79417,
            48.15681,
            0
          ],
          [
            -116.79411,
            48.15682,
            0
          ],
          [
            -116.79394,
            48.15687,
            0
          ],
          [
            -116.79373,
            48.15692,
            0
          ],
          [
            -116.79356,
            48.15697,
            0
          ],
          [
            -116.79338,
            48.15701,
            0
          ],
          [
            -116.79156,
            48.15748,
            0
          ],
          [
            -116.79137,
            48.15752,
            0
          ],
          [
            -116.79119,
            48.15757,
            0
          ],
          [
            -116.79086,
            48.15765,
            0
          ],
          [
            -116.79039,
            48.15778,
            0
          ],
          [
            -116.79017,
            48.15783,
            0
          ],
          [
            -116.78964,
            48.15797,
            0
          ],
          [
            -116.78938,
            48.15803,
            0
          ],
          [
            -116.78799,
            48.15839,
            0
          ],
          [
            -116.78693,
            48.1587,
            0
          ],
          [
            -116.7865,
            48.15885,
            0
          ],
          [
            -116.78642,
            48.15887,
            0
          ],
          [
            -116.786,
            48.15904,
            0
          ],
          [
            -116.78586,
            48.15909,
            0
          ],
          [
            -116.78578,
            48.15913,
            0
          ],
          [
            -116.78561,
            48.1592,
            0
          ],
          [
            -116.78522,
            48.15939,
            0
          ],
          [
            -116.78514,
            48.15942,
            0
          ],
          [
            -116.78124,
            48.16129,
            0
          ],
          [
            -116.78107,
            48.16136,
            0
          ],
          [
            -116.77975,
            48.162,
            0
          ],
          [
            -116.77958,
            48.16207,
            0
          ],
          [
            -116.77859,
            48.16255,
            0
          ],
          [
            -116.77842,
            48.16262,
            0
          ],
          [
            -116.77748,
            48.16308,
            0
          ],
          [
            -116.77674,
            48.16342,
            0
          ],
          [
            -116.77622,
            48.16364,
            0
          ],
          [
            -116.77481,
            48.16419,
            0
          ],
          [
            -116.77279,
            48.16485,
            0
          ],
          [
            -116.77261,
            48.1649,
            0
          ],
          [
            -116.77113,
            48.16538,
            0
          ],
          [
            -116.77095,
            48.16543,
            0
          ],
          [
            -116.7709,
            48.16545,
            0
          ],
          [
            -116.76873,
            48.16614,
            0
          ],
          [
            -116.76855,
            48.16619,
            0
          ],
          [
            -116.76781,
            48.16643,
            0
          ],
          [
            -116.76763,
            48.16648,
            0
          ],
          [
            -116.7669,
            48.16672,
            0
          ],
          [
            -116.76671,
            48.16677,
            0
          ],
          [
            -116.76492,
            48.16734,
            0
          ],
          [
            -116.76475,
            48.1674,
            0
          ],
          [
            -116.76458,
            48.16745,
            0
          ],
          [
            -116.76441,
            48.16751,
            0
          ],
          [
            -116.76424,
            48.16756,
            0
          ],
          [
            -116.76407,
            48.16762,
            0
          ],
          [
            -116.76405,
            48.16762,
            0
          ],
          [
            -116.76373,
            48.16773,
            0
          ],
          [
            -116.76305,
            48.16794,
            0
          ],
          [
            -116.76288,
            48.168,
            0
          ],
          [
            -116.76253,
            48.16811,
            0
          ],
          [
            -116.76237,
            48.16815,
            0
          ],
          [
            -116.76203,
            48.16826,
            0
          ],
          [
            -116.76134,
            48.16846,
            0
          ],
          [
            -116.76117,
            48.16852,
            0
          ],
          [
            -116.76097,
            48.16857,
            0
          ],
          [
            -116.76083,
            48.16862,
            0
          ],
          [
            -116.76071,
            48.16865,
            0
          ],
          [
            -116.75896,
            48.16917,
            0
          ],
          [
            -116.75852,
            48.16931,
            0
          ],
          [
            -116.75841,
            48.16932,
            0
          ],
          [
            -116.75754,
            48.16959,
            0
          ],
          [
            -116.7573,
            48.16965,
            0
          ],
          [
            -116.75655,
            48.16989,
            0
          ],
          [
            -116.75636,
            48.16994,
            0
          ],
          [
            -116.75366,
            48.1708,
            0
          ],
          [
            -116.75352,
            48.17088,
            0
          ],
          [
            -116.75088,
            48.1717,
            0
          ],
          [
            -116.7499,
            48.17202,
            0
          ],
          [
            -116.74968,
            48.1721,
            0
          ],
          [
            -116.74934,
            48.17221,
            0
          ],
          [
            -116.74864,
            48.17246,
            0
          ],
          [
            -116.74801,
            48.1727,
            0
          ],
          [
            -116.74799,
            48.17271,
            0
          ],
          [
            -116.7477,
            48.17282,
            0
          ],
          [
            -116.74758,
            48.17288,
            0
          ],
          [
            -116.74719,
            48.17303,
            0
          ],
          [
            -116.74707,
            48.17309,
            0
          ],
          [
            -116.74685,
            48.17318,
            0
          ],
          [
            -116.74684,
            48.17318,
            0
          ],
          [
            -116.74679,
            48.17321,
            0
          ],
          [
            -116.74673,
            48.17323,
            0
          ],
          [
            -116.74661,
            48.17328,
            0
          ],
          [
            -116.74658,
            48.1733,
            0
          ],
          [
            -116.74655,
            48.17331,
            0
          ],
          [
            -116.74649,
            48.17334,
            0
          ],
          [
            -116.74642,
            48.17336,
            0
          ],
          [
            -116.74567,
            48.17371,
            0
          ],
          [
            -116.74564,
            48.17373,
            0
          ],
          [
            -116.74503,
            48.17402,
            0
          ],
          [
            -116.74487,
            48.17409,
            0
          ],
          [
            -116.74454,
            48.17426,
            0
          ],
          [
            -116.74354,
            48.17474,
            0
          ],
          [
            -116.74353,
            48.17475,
            0
          ],
          [
            -116.74244,
            48.17528,
            0
          ],
          [
            -116.74227,
            48.17537,
            0
          ],
          [
            -116.74097,
            48.176,
            0
          ],
          [
            -116.74082,
            48.17608,
            0
          ],
          [
            -116.74032,
            48.17632,
            0
          ],
          [
            -116.74016,
            48.17639,
            0
          ],
          [
            -116.73984,
            48.17655,
            0
          ],
          [
            -116.73983,
            48.17656,
            0
          ],
          [
            -116.73933,
            48.17679,
            0
          ],
          [
            -116.73917,
            48.17688,
            0
          ],
          [
            -116.73853,
            48.17719,
            0
          ],
          [
            -116.73837,
            48.17726,
            0
          ],
          [
            -116.7382,
            48.17735,
            0
          ],
          [
            -116.73445,
            48.17917,
            0
          ],
          [
            -116.73336,
            48.17976,
            0
          ],
          [
            -116.73276,
            48.18012,
            0
          ],
          [
            -116.73261,
            48.18022,
            0
          ],
          [
            -116.73246,
            48.18031,
            0
          ],
          [
            -116.73203,
            48.18059,
            0
          ],
          [
            -116.73134,
            48.18107,
            0
          ],
          [
            -116.73121,
            48.18118,
            0
          ],
          [
            -116.73094,
            48.18138,
            0
          ],
          [
            -116.73081,
            48.18149,
            0
          ],
          [
            -116.73067,
            48.18159,
            0
          ],
          [
            -116.73004,
            48.18212,
            0
          ],
          [
            -116.72993,
            48.18222,
            0
          ],
          [
            -116.72981,
            48.18232,
            0
          ],
          [
            -116.72969,
            48.18243,
            0
          ],
          [
            -116.72958,
            48.18254,
            0
          ],
          [
            -116.72933,
            48.18277,
            0
          ],
          [
            -116.72911,
            48.18299,
            0
          ],
          [
            -116.72773,
            48.18447,
            0
          ],
          [
            -116.72762,
            48.18458,
            0
          ],
          [
            -116.72752,
            48.1847,
            0
          ],
          [
            -116.72741,
            48.18481,
            0
          ],
          [
            -116.72731,
            48.18493,
            0
          ],
          [
            -116.7272,
            48.18504,
            0
          ],
          [
            -116.72689,
            48.18538,
            0
          ],
          [
            -116.72678,
            48.18549,
            0
          ],
          [
            -116.72668,
            48.18561,
            0
          ],
          [
            -116.72657,
            48.18572,
            0
          ],
          [
            -116.72604,
            48.1863,
            0
          ],
          [
            -116.72593,
            48.18641,
            0
          ],
          [
            -116.72519,
            48.18722,
            0
          ],
          [
            -116.72508,
            48.18733,
            0
          ],
          [
            -116.72467,
            48.18779,
            0
          ],
          [
            -116.72456,
            48.1879,
            0
          ],
          [
            -116.72445,
            48.18802,
            0
          ],
          [
            -116.72443,
            48.18805,
            0
          ],
          [
            -116.72435,
            48.18813,
            0
          ],
          [
            -116.72404,
            48.18847,
            0
          ],
          [
            -116.72384,
            48.18871,
            0
          ],
          [
            -116.72374,
            48.18882,
            0
          ],
          [
            -116.72365,
            48.18894,
            0
          ],
          [
            -116.72355,
            48.18905,
            0
          ],
          [
            -116.7235,
            48.18913,
            0
          ],
          [
            -116.72336,
            48.18929,
            0
          ],
          [
            -116.72318,
            48.18953,
            0
          ],
          [
            -116.72309,
            48.18966,
            0
          ],
          [
            -116.72282,
            48.19002,
            0
          ],
          [
            -116.72274,
            48.19014,
            0
          ],
          [
            -116.72265,
            48.19026,
            0
          ],
          [
            -116.72257,
            48.19038,
            0
          ],
          [
            -116.72248,
            48.1905,
            0
          ],
          [
            -116.72232,
            48.19075,
            0
          ],
          [
            -116.72228,
            48.1908,
            0
          ],
          [
            -116.72128,
            48.19228,
            0
          ],
          [
            -116.72085,
            48.19294,
            0
          ],
          [
            -116.72084,
            48.19295,
            0
          ],
          [
            -116.72076,
            48.19307,
            0
          ],
          [
            -116.7205,
            48.1935,
            0
          ],
          [
            -116.72043,
            48.19365,
            0
          ],
          [
            -116.72036,
            48.19377,
            0
          ],
          [
            -116.72014,
            48.19429,
            0
          ],
          [
            -116.72014,
            48.1943,
            0
          ],
          [
            -116.72008,
            48.19446,
            0
          ],
          [
            -116.71998,
            48.19479,
            0
          ],
          [
            -116.71997,
            48.19486,
            0
          ],
          [
            -116.71991,
            48.19511,
            0
          ],
          [
            -116.71989,
            48.19517,
            0
          ],
          [
            -116.71986,
            48.19536,
            0
          ],
          [
            -116.71984,
            48.19542,
            0
          ],
          [
            -116.71981,
            48.19564,
            0
          ],
          [
            -116.71977,
            48.19612,
            0
          ],
          [
            -116.71977,
            48.1965,
            0
          ],
          [
            -116.71978,
            48.19667,
            0
          ],
          [
            -116.71981,
            48.19699,
            0
          ],
          [
            -116.71983,
            48.19711,
            0
          ],
          [
            -116.71983,
            48.19717,
            0
          ],
          [
            -116.71985,
            48.19725,
            0
          ],
          [
            -116.71988,
            48.19744,
            0
          ],
          [
            -116.71989,
            48.19747,
            0
          ],
          [
            -116.71989,
            48.1975,
            0
          ],
          [
            -116.71993,
            48.19763,
            0
          ],
          [
            -116.71999,
            48.19787,
            0
          ],
          [
            -116.72002,
            48.19794,
            0
          ],
          [
            -116.72005,
            48.19804,
            0
          ],
          [
            -116.72007,
            48.19813,
            0
          ],
          [
            -116.72009,
            48.19818,
            0
          ],
          [
            -116.72031,
            48.19889,
            0
          ],
          [
            -116.7206,
            48.19976,
            0
          ],
          [
            -116.72062,
            48.19985,
            0
          ],
          [
            -116.72152,
            48.20261,
            0
          ],
          [
            -116.72179,
            48.2035,
            0
          ],
          [
            -116.72181,
            48.2036,
            0
          ],
          [
            -116.72184,
            48.20398,
            0
          ],
          [
            -116.72184,
            48.2041,
            0
          ],
          [
            -116.72185,
            48.20418,
            0
          ],
          [
            -116.72184,
            48.20424,
            0
          ],
          [
            -116.72184,
            48.20432,
            0
          ],
          [
            -116.72182,
            48.20456,
            0
          ],
          [
            -116.72176,
            48.20476,
            0
          ],
          [
            -116.72171,
            48.20489,
            0
          ],
          [
            -116.7217,
            48.20494,
            0
          ],
          [
            -116.72168,
            48.20498,
            0
          ],
          [
            -116.72166,
            48.20503,
            0
          ],
          [
            -116.72164,
            48.20507,
            0
          ],
          [
            -116.72163,
            48.2051,
            0
          ],
          [
            -116.72161,
            48.20514,
            0
          ],
          [
            -116.72157,
            48.2052,
            0
          ],
          [
            -116.72156,
            48.20523,
            0
          ],
          [
            -116.72153,
            48.20529,
            0
          ],
          [
            -116.72149,
            48.20535,
            0
          ],
          [
            -116.72142,
            48.20547,
            0
          ],
          [
            -116.72137,
            48.20554,
            0
          ],
          [
            -116.72133,
            48.20561,
            0
          ],
          [
            -116.72103,
            48.20605,
            0
          ],
          [
            -116.72096,
            48.20614,
            0
          ],
          [
            -116.72072,
            48.20648,
            0
          ],
          [
            -116.72065,
            48.20659,
            0
          ],
          [
            -116.72051,
            48.20679,
            0
          ],
          [
            -116.72048,
            48.20684,
            0
          ],
          [
            -116.72039,
            48.20697,
            0
          ],
          [
            -116.72019,
            48.20735,
            0
          ],
          [
            -116.72012,
            48.20752,
            0
          ],
          [
            -116.72009,
            48.20765,
            0
          ],
          [
            -116.72007,
            48.20771,
            0
          ],
          [
            -116.72006,
            48.20776,
            0
          ],
          [
            -116.72004,
            48.20783,
            0
          ],
          [
            -116.72003,
            48.20791,
            0
          ],
          [
            -116.72001,
            48.20799,
            0
          ],
          [
            -116.72,
            48.20806,
            0
          ],
          [
            -116.72,
            48.20812,
            0
          ],
          [
            -116.71999,
            48.20819,
            0
          ],
          [
            -116.71999,
            48.20832,
            0
          ],
          [
            -116.71996,
            48.2089,
            0
          ],
          [
            -116.71994,
            48.20964,
            0
          ],
          [
            -116.71992,
            48.21154,
            0
          ],
          [
            -116.7199,
            48.21181,
            0
          ],
          [
            -116.7199,
            48.21187,
            0
          ],
          [
            -116.71989,
            48.21192,
            0
          ],
          [
            -116.71988,
            48.21206,
            0
          ],
          [
            -116.71987,
            48.21212,
            0
          ],
          [
            -116.71987,
            48.21217,
            0
          ],
          [
            -116.71984,
            48.21235,
            0
          ],
          [
            -116.71979,
            48.21258,
            0
          ],
          [
            -116.71978,
            48.21259,
            0
          ],
          [
            -116.71977,
            48.21263,
            0
          ],
          [
            -116.71977,
            48.21266,
            0
          ],
          [
            -116.71975,
            48.21273,
            0
          ],
          [
            -116.71973,
            48.21277,
            0
          ],
          [
            -116.71967,
            48.21297,
            0
          ],
          [
            -116.71963,
            48.21308,
            0
          ],
          [
            -116.71956,
            48.21322,
            0
          ],
          [
            -116.71954,
            48.21327,
            0
          ],
          [
            -116.71953,
            48.21328,
            0
          ],
          [
            -116.71949,
            48.21338,
            0
          ],
          [
            -116.71944,
            48.21346,
            0
          ],
          [
            -116.71934,
            48.21365,
            0
          ],
          [
            -116.71913,
            48.21396,
            0
          ],
          [
            -116.7188,
            48.21436,
            0
          ],
          [
            -116.71873,
            48.21443,
            0
          ],
          [
            -116.7187,
            48.21447,
            0
          ],
          [
            -116.71848,
            48.21469,
            0
          ],
          [
            -116.71829,
            48.21486,
            0
          ],
          [
            -116.71821,
            48.21494,
            0
          ],
          [
            -116.71817,
            48.21496,
            0
          ],
          [
            -116.71812,
            48.21501,
            0
          ],
          [
            -116.71804,
            48.21508,
            0
          ],
          [
            -116.71796,
            48.21513,
            0
          ],
          [
            -116.71762,
            48.21539,
            0
          ],
          [
            -116.71758,
            48.21541,
            0
          ],
          [
            -116.71751,
            48.21547,
            0
          ],
          [
            -116.71736,
            48.21556,
            0
          ],
          [
            -116.71723,
            48.21566,
            0
          ],
          [
            -116.7168,
            48.21594,
            0
          ],
          [
            -116.71645,
            48.21618,
            0
          ],
          [
            -116.71637,
            48.21623,
            0
          ],
          [
            -116.71629,
            48.2163,
            0
          ],
          [
            -116.71618,
            48.21637,
            0
          ],
          [
            -116.71601,
            48.2165,
            0
          ],
          [
            -116.71594,
            48.21654,
            0
          ],
          [
            -116.7157,
            48.21673,
            0
          ],
          [
            -116.71558,
            48.21684,
            0
          ],
          [
            -116.71547,
            48.21692,
            0
          ],
          [
            -116.7152,
            48.21716,
            0
          ],
          [
            -116.71504,
            48.21732,
            0
          ],
          [
            -116.7149,
            48.21745,
            0
          ],
          [
            -116.71449,
            48.2179,
            0
          ],
          [
            -116.71408,
            48.21843,
            0
          ],
          [
            -116.7139,
            48.21869,
            0
          ],
          [
            -116.7118,
            48.22196,
            0
          ],
          [
            -116.71167,
            48.22214,
            0
          ],
          [
            -116.71153,
            48.22232,
            0
          ],
          [
            -116.71147,
            48.22241,
            0
          ],
          [
            -116.71124,
            48.22268,
            0
          ],
          [
            -116.71117,
            48.22275,
            0
          ],
          [
            -116.71108,
            48.22285,
            0
          ],
          [
            -116.71102,
            48.22291,
            0
          ],
          [
            -116.71097,
            48.22297,
            0
          ],
          [
            -116.71088,
            48.22305,
            0
          ],
          [
            -116.71083,
            48.2231,
            0
          ],
          [
            -116.71076,
            48.22316,
            0
          ],
          [
            -116.7107,
            48.22323,
            0
          ],
          [
            -116.71063,
            48.22329,
            0
          ],
          [
            -116.71059,
            48.22333,
            0
          ],
          [
            -116.71056,
            48.22335,
            0
          ],
          [
            -116.71049,
            48.22342,
            0
          ],
          [
            -116.71038,
            48.22351,
            0
          ],
          [
            -116.71036,
            48.22353,
            0
          ],
          [
            -116.71033,
            48.22355,
            0
          ],
          [
            -116.71025,
            48.22363,
            0
          ],
          [
            -116.71008,
            48.22376,
            0
          ],
          [
            -116.7097,
            48.22408,
            0
          ],
          [
            -116.70957,
            48.22418,
            0
          ],
          [
            -116.70907,
            48.2246,
            0
          ],
          [
            -116.70894,
            48.2247,
            0
          ],
          [
            -116.7088,
            48.22482,
            0
          ],
          [
            -116.70853,
            48.22503,
            0
          ],
          [
            -116.7084,
            48.22514,
            0
          ],
          [
            -116.70827,
            48.22524,
            0
          ],
          [
            -116.70799,
            48.22544,
            0
          ],
          [
            -116.70794,
            48.22547,
            0
          ],
          [
            -116.70742,
            48.22582,
            0
          ],
          [
            -116.70678,
            48.22623,
            0
          ],
          [
            -116.70666,
            48.2263,
            0
          ],
          [
            -116.70636,
            48.2265,
            0
          ],
          [
            -116.70621,
            48.22659,
            0
          ],
          [
            -116.70614,
            48.22664,
            0
          ],
          [
            -116.70606,
            48.22669,
            0
          ],
          [
            -116.70601,
            48.22673,
            0
          ],
          [
            -116.70591,
            48.22679,
            0
          ],
          [
            -116.70576,
            48.22689,
            0
          ],
          [
            -116.70561,
            48.22698,
            0
          ],
          [
            -116.70517,
            48.22727,
            0
          ],
          [
            -116.70473,
            48.22754,
            0
          ],
          [
            -116.70456,
            48.22766,
            0
          ],
          [
            -116.70427,
            48.22784,
            0
          ],
          [
            -116.70236,
            48.22909,
            0
          ],
          [
            -116.70224,
            48.22918,
            0
          ],
          [
            -116.70216,
            48.22923,
            0
          ],
          [
            -116.70196,
            48.2294,
            0
          ],
          [
            -116.70192,
            48.22944,
            0
          ],
          [
            -116.70187,
            48.22948,
            0
          ],
          [
            -116.70159,
            48.22976,
            0
          ],
          [
            -116.70155,
            48.22981,
            0
          ],
          [
            -116.70151,
            48.22985,
            0
          ],
          [
            -116.70143,
            48.22994,
            0
          ],
          [
            -116.70138,
            48.23001,
            0
          ],
          [
            -116.70133,
            48.23007,
            0
          ],
          [
            -116.70118,
            48.23029,
            0
          ],
          [
            -116.70117,
            48.2303,
            0
          ],
          [
            -116.70116,
            48.23033,
            0
          ],
          [
            -116.70113,
            48.23037,
            0
          ],
          [
            -116.70089,
            48.23081,
            0
          ],
          [
            -116.70081,
            48.23098,
            0
          ],
          [
            -116.70067,
            48.23122,
            0
          ],
          [
            -116.70062,
            48.23133,
            0
          ],
          [
            -116.70046,
            48.23163,
            0
          ],
          [
            -116.70031,
            48.23187,
            0
          ],
          [
            -116.70029,
            48.23191,
            0
          ],
          [
            -116.70024,
            48.23197,
            0
          ],
          [
            -116.70022,
            48.23199,
            0
          ],
          [
            -116.70016,
            48.23208,
            0
          ],
          [
            -116.70012,
            48.23212,
            0
          ],
          [
            -116.70003,
            48.23224,
            0
          ],
          [
            -116.69994,
            48.23234,
            0
          ],
          [
            -116.69984,
            48.23244,
            0
          ],
          [
            -116.6998,
            48.23249,
            0
          ],
          [
            -116.69974,
            48.23254,
            0
          ],
          [
            -116.6997,
            48.23258,
            0
          ],
          [
            -116.69965,
            48.23262,
            0
          ],
          [
            -116.69957,
            48.2327,
            0
          ],
          [
            -116.69908,
            48.23314,
            0
          ],
          [
            -116.699,
            48.23322,
            0
          ],
          [
            -116.69887,
            48.23333,
            0
          ],
          [
            -116.69864,
            48.23356,
            0
          ],
          [
            -116.69841,
            48.23382,
            0
          ],
          [
            -116.69837,
            48.23386,
            0
          ],
          [
            -116.69833,
            48.23392,
            0
          ],
          [
            -116.69818,
            48.2341,
            0
          ],
          [
            -116.69813,
            48.23417,
            0
          ],
          [
            -116.69811,
            48.23419,
            0
          ],
          [
            -116.69809,
            48.23423,
            0
          ],
          [
            -116.69805,
            48.23428,
            0
          ],
          [
            -116.69792,
            48.23447,
            0
          ],
          [
            -116.69774,
            48.2348,
            0
          ],
          [
            -116.69771,
            48.23484,
            0
          ],
          [
            -116.6977,
            48.23487,
            0
          ],
          [
            -116.69763,
            48.23499,
            0
          ],
          [
            -116.69758,
            48.2351,
            0
          ],
          [
            -116.69756,
            48.23513,
            0
          ],
          [
            -116.69751,
            48.23527,
            0
          ],
          [
            -116.69748,
            48.23534,
            0
          ],
          [
            -116.69744,
            48.23548,
            0
          ],
          [
            -116.69736,
            48.23571,
            0
          ],
          [
            -116.69691,
            48.23733,
            0
          ],
          [
            -116.6969,
            48.23734,
            0
          ],
          [
            -116.69668,
            48.23816,
            0
          ],
          [
            -116.69661,
            48.23839,
            0
          ],
          [
            -116.69658,
            48.23856,
            0
          ],
          [
            -116.69641,
            48.23936,
            0
          ],
          [
            -116.69637,
            48.23946,
            0
          ],
          [
            -116.69621,
            48.23999,
            0
          ],
          [
            -116.69617,
            48.24016,
            0
          ],
          [
            -116.6961,
            48.24036,
            0
          ],
          [
            -116.69605,
            48.24053,
            0
          ],
          [
            -116.69603,
            48.24058,
            0
          ],
          [
            -116.69519,
            48.24361,
            0
          ],
          [
            -116.69514,
            48.24382,
            0
          ],
          [
            -116.6951,
            48.24393,
            0
          ],
          [
            -116.69507,
            48.24405,
            0
          ],
          [
            -116.69502,
            48.24421,
            0
          ],
          [
            -116.69498,
            48.24431,
            0
          ],
          [
            -116.69498,
            48.24432,
            0
          ],
          [
            -116.69487,
            48.24465,
            0
          ],
          [
            -116.69473,
            48.24499,
            0
          ],
          [
            -116.69469,
            48.2451,
            0
          ],
          [
            -116.69465,
            48.24519,
            0
          ],
          [
            -116.69462,
            48.24524,
            0
          ],
          [
            -116.69455,
            48.2454,
            0
          ],
          [
            -116.69447,
            48.24555,
            0
          ],
          [
            -116.69445,
            48.2456,
            0
          ],
          [
            -116.69442,
            48.24565,
            0
          ],
          [
            -116.6944,
            48.24569,
            0
          ],
          [
            -116.69439,
            48.24573,
            0
          ],
          [
            -116.69435,
            48.24579,
            0
          ],
          [
            -116.69431,
            48.24587,
            0
          ],
          [
            -116.6933,
            48.24748,
            0
          ],
          [
            -116.69317,
            48.2477,
            0
          ],
          [
            -116.69314,
            48.24774,
            0
          ],
          [
            -116.69306,
            48.24787,
            0
          ],
          [
            -116.693,
            48.24798,
            0
          ],
          [
            -116.69294,
            48.24806,
            0
          ],
          [
            -116.69286,
            48.2482,
            0
          ],
          [
            -116.69234,
            48.24904,
            0
          ],
          [
            -116.69202,
            48.24958,
            0
          ],
          [
            -116.69199,
            48.24962,
            0
          ],
          [
            -116.69187,
            48.24982,
            0
          ],
          [
            -116.69176,
            48.24998,
            0
          ],
          [
            -116.69168,
            48.25011,
            0
          ],
          [
            -116.69164,
            48.25016,
            0
          ],
          [
            -116.69159,
            48.25024,
            0
          ],
          [
            -116.6913,
            48.25062,
            0
          ],
          [
            -116.69126,
            48.25066,
            0
          ],
          [
            -116.69123,
            48.2507,
            0
          ],
          [
            -116.69119,
            48.25073,
            0
          ],
          [
            -116.69114,
            48.25079,
            0
          ],
          [
            -116.69108,
            48.25085,
            0
          ],
          [
            -116.69102,
            48.2509,
            0
          ],
          [
            -116.69092,
            48.251,
            0
          ],
          [
            -116.69069,
            48.2512,
            0
          ],
          [
            -116.69037,
            48.25142,
            0
          ],
          [
            -116.69032,
            48.25145,
            0
          ],
          [
            -116.69028,
            48.25147,
            0
          ],
          [
            -116.69023,
            48.25151,
            0
          ],
          [
            -116.69017,
            48.25155,
            0
          ],
          [
            -116.69009,
            48.25159,
            0
          ],
          [
            -116.69003,
            48.25163,
            0
          ],
          [
            -116.68991,
            48.25169,
            0
          ],
          [
            -116.68988,
            48.2517,
            0
          ],
          [
            -116.68971,
            48.25179,
            0
          ],
          [
            -116.6895,
            48.25188,
            0
          ],
          [
            -116.68923,
            48.25198,
            0
          ],
          [
            -116.68919,
            48.25199,
            0
          ],
          [
            -116.68917,
            48.252,
            0
          ],
          [
            -116.68544,
            48.2534,
            0
          ],
          [
            -116.68521,
            48.2535,
            0
          ],
          [
            -116.68486,
            48.25363,
            0
          ],
          [
            -116.68448,
            48.25379,
            0
          ],
          [
            -116.68427,
            48.25387,
            0
          ],
          [
            -116.68427,
            48.25388,
            0
          ],
          [
            -116.68411,
            48.25394,
            0
          ],
          [
            -116.6765,
            48.25717,
            0
          ],
          [
            -116.6761,
            48.25733,
            0
          ],
          [
            -116.67414,
            48.25818,
            0
          ],
          [
            -116.67397,
            48.25826,
            0
          ],
          [
            -116.67391,
            48.25828,
            0
          ],
          [
            -116.67364,
            48.2584,
            0
          ],
          [
            -116.67356,
            48.25843,
            0
          ],
          [
            -116.67349,
            48.25845,
            0
          ],
          [
            -116.6734,
            48.25849,
            0
          ],
          [
            -116.67337,
            48.25851,
            0
          ],
          [
            -116.67277,
            48.25873,
            0
          ],
          [
            -116.67254,
            48.2588,
            0
          ],
          [
            -116.67196,
            48.25894,
            0
          ],
          [
            -116.67181,
            48.25896,
            0
          ],
          [
            -116.67151,
            48.25902,
            0
          ],
          [
            -116.67138,
            48.25903,
            0
          ],
          [
            -116.6713,
            48.25905,
            0
          ],
          [
            -116.67125,
            48.25905,
            0
          ],
          [
            -116.6712,
            48.25906,
            0
          ],
          [
            -116.67113,
            48.25906,
            0
          ],
          [
            -116.67106,
            48.25907,
            0
          ],
          [
            -116.6706,
            48.2591,
            0
          ],
          [
            -116.66991,
            48.2591,
            0
          ],
          [
            -116.66954,
            48.25908,
            0
          ],
          [
            -116.66901,
            48.25902,
            0
          ],
          [
            -116.66892,
            48.259,
            0
          ],
          [
            -116.6687,
            48.25897,
            0
          ],
          [
            -116.66144,
            48.25768,
            0
          ],
          [
            -116.66086,
            48.25756,
            0
          ],
          [
            -116.66078,
            48.25755,
            0
          ],
          [
            -116.66044,
            48.25747,
            0
          ],
          [
            -116.66012,
            48.25738,
            0
          ],
          [
            -116.66008,
            48.25736,
            0
          ],
          [
            -116.66003,
            48.25735,
            0
          ],
          [
            -116.65974,
            48.25726,
            0
          ],
          [
            -116.65958,
            48.2572,
            0
          ],
          [
            -116.65953,
            48.25719,
            0
          ],
          [
            -116.65886,
            48.25694,
            0
          ],
          [
            -116.65876,
            48.25689,
            0
          ],
          [
            -116.65871,
            48.25687,
            0
          ],
          [
            -116.65863,
            48.25683,
            0
          ],
          [
            -116.65855,
            48.2568,
            0
          ],
          [
            -116.65472,
            48.25504,
            0
          ],
          [
            -116.6545,
            48.25493,
            0
          ],
          [
            -116.65149,
            48.25356,
            0
          ],
          [
            -116.65137,
            48.2535,
            0
          ],
          [
            -116.65076,
            48.25325,
            0
          ],
          [
            -116.65043,
            48.25313,
            0
          ],
          [
            -116.65031,
            48.25308,
            0
          ],
          [
            -116.65021,
            48.25305,
            0
          ],
          [
            -116.65004,
            48.25299,
            0
          ],
          [
            -116.6499,
            48.25295,
            0
          ],
          [
            -116.64963,
            48.25286,
            0
          ],
          [
            -116.64935,
            48.25278,
            0
          ],
          [
            -116.6493,
            48.25276,
            0
          ],
          [
            -116.64877,
            48.25262,
            0
          ],
          [
            -116.64834,
            48.25252,
            0
          ],
          [
            -116.64726,
            48.25231,
            0
          ],
          [
            -116.64695,
            48.25226,
            0
          ],
          [
            -116.6467,
            48.25223,
            0
          ],
          [
            -116.64659,
            48.25221,
            0
          ],
          [
            -116.64531,
            48.25209,
            0
          ],
          [
            -116.64516,
            48.25209,
            0
          ],
          [
            -116.64493,
            48.25207,
            0
          ],
          [
            -116.64474,
            48.25207,
            0
          ],
          [
            -116.64455,
            48.25206,
            0
          ],
          [
            -116.64352,
            48.25206,
            0
          ],
          [
            -116.63459,
            48.25218,
            0
          ],
          [
            -116.63432,
            48.25219,
            0
          ],
          [
            -116.61658,
            48.2524,
            0
          ],
          [
            -116.61631,
            48.25241,
            0
          ],
          [
            -116.61522,
            48.25242,
            0
          ],
          [
            -116.61496,
            48.25243,
            0
          ],
          [
            -116.61435,
            48.25243,
            0
          ],
          [
            -116.61425,
            48.25244,
            0
          ],
          [
            -116.61413,
            48.25244,
            0
          ],
          [
            -116.61353,
            48.25248,
            0
          ],
          [
            -116.61311,
            48.25253,
            0
          ],
          [
            -116.61281,
            48.25258,
            0
          ],
          [
            -116.61271,
            48.25259,
            0
          ],
          [
            -116.61262,
            48.25261,
            0
          ],
          [
            -116.61254,
            48.25262,
            0
          ],
          [
            -116.61185,
            48.25276,
            0
          ],
          [
            -116.61173,
            48.25276,
            0
          ],
          [
            -116.61101,
            48.25291,
            0
          ],
          [
            -116.61081,
            48.25294,
            0
          ],
          [
            -116.61077,
            48.25295,
            0
          ],
          [
            -116.61072,
            48.25295,
            0
          ],
          [
            -116.61063,
            48.25297,
            0
          ],
          [
            -116.61042,
            48.25299,
            0
          ],
          [
            -116.61024,
            48.25302,
            0
          ],
          [
            -116.61013,
            48.25303,
            0
          ],
          [
            -116.61005,
            48.25303,
            0
          ],
          [
            -116.60996,
            48.25304,
            0
          ],
          [
            -116.6099,
            48.25304,
            0
          ],
          [
            -116.60984,
            48.25305,
            0
          ],
          [
            -116.60967,
            48.25305,
            0
          ],
          [
            -116.60959,
            48.25306,
            0
          ],
          [
            -116.60949,
            48.25306,
            0
          ],
          [
            -116.60941,
            48.25305,
            0
          ],
          [
            -116.60917,
            48.25305,
            0
          ],
          [
            -116.60909,
            48.25304,
            0
          ],
          [
            -116.609,
            48.25304,
            0
          ],
          [
            -116.60886,
            48.25302,
            0
          ],
          [
            -116.60864,
            48.253,
            0
          ],
          [
            -116.60855,
            48.25298,
            0
          ],
          [
            -116.6084,
            48.25296,
            0
          ],
          [
            -116.60716,
            48.25275,
            0
          ],
          [
            -116.6061,
            48.25259,
            0
          ],
          [
            -116.60568,
            48.25254,
            0
          ],
          [
            -116.6056,
            48.25254,
            0
          ],
          [
            -116.60549,
            48.25253,
            0
          ],
          [
            -116.60514,
            48.25253,
            0
          ],
          [
            -116.60479,
            48.25256,
            0
          ],
          [
            -116.60446,
            48.2526,
            0
          ],
          [
            -116.60415,
            48.25266,
            0
          ],
          [
            -116.604,
            48.2527,
            0
          ],
          [
            -116.60387,
            48.25272,
            0
          ],
          [
            -116.60379,
            48.25278,
            0
          ],
          [
            -116.60354,
            48.25284,
            0
          ],
          [
            -116.60349,
            48.25271,
            0
          ],
          [
            -116.60349,
            48.25266,
            0
          ],
          [
            -116.60353,
            48.25257,
            0
          ],
          [
            -116.60358,
            48.25249,
            0
          ],
          [
            -116.60359,
            48.25247,
            0
          ],
          [
            -116.60376,
            48.2523,
            0
          ],
          [
            -116.6039,
            48.25222,
            0
          ],
          [
            -116.60456,
            48.25188,
            0
          ],
          [
            -116.60486,
            48.25171,
            0
          ],
          [
            -116.60541,
            48.25151,
            0
          ],
          [
            -116.60584,
            48.2514,
            0
          ],
          [
            -116.60618,
            48.25134,
            0
          ],
          [
            -116.60649,
            48.25131,
            0
          ],
          [
            -116.60668,
            48.25132,
            0
          ],
          [
            -116.60699,
            48.25131,
            0
          ],
          [
            -116.60732,
            48.25134,
            0
          ],
          [
            -116.60768,
            48.25135,
            0
          ],
          [
            -116.60778,
            48.25133,
            0
          ],
          [
            -116.6079,
            48.25129,
            0
          ],
          [
            -116.60808,
            48.25122,
            0
          ],
          [
            -116.60815,
            48.25124,
            0
          ],
          [
            -116.60885,
            48.25151,
            0
          ],
          [
            -116.60909,
            48.25159,
            0
          ],
          [
            -116.60955,
            48.25177,
            0
          ],
          [
            -116.60956,
            48.25177,
            0
          ],
          [
            -116.6097,
            48.25179,
            0
          ],
          [
            -116.61005,
            48.25181,
            0
          ],
          [
            -116.61104,
            48.25179,
            0
          ],
          [
            -116.61105,
            48.25159,
            0
          ],
          [
            -116.61104,
            48.25179,
            0
          ],
          [
            -116.61005,
            48.25181,
            0
          ],
          [
            -116.6097,
            48.25179,
            0
          ],
          [
            -116.60956,
            48.25177,
            0
          ],
          [
            -116.60955,
            48.25177,
            0
          ],
          [
            -116.60909,
            48.25159,
            0
          ],
          [
            -116.60885,
            48.25151,
            0
          ],
          [
            -116.60815,
            48.25124,
            0
          ],
          [
            -116.60808,
            48.25122,
            0
          ],
          [
            -116.6079,
            48.25129,
            0
          ],
          [
            -116.60778,
            48.25133,
            0
          ],
          [
            -116.60768,
            48.25135,
            0
          ],
          [
            -116.60732,
            48.25134,
            0
          ],
          [
            -116.60699,
            48.25131,
            0
          ],
          [
            -116.60668,
            48.25132,
            0
          ],
          [
            -116.60649,
            48.25131,
            0
          ],
          [
            -116.60618,
            48.25134,
            0
          ],
          [
            -116.60584,
            48.2514,
            0
          ],
          [
            -116.60541,
            48.25151,
            0
          ],
          [
            -116.60486,
            48.25171,
            0
          ],
          [
            -116.60456,
            48.25188,
            0
          ],
          [
            -116.6039,
            48.25222,
            0
          ],
          [
            -116.60376,
            48.2523,
            0
          ],
          [
            -116.60359,
            48.25247,
            0
          ],
          [
            -116.60358,
            48.25249,
            0
          ],
          [
            -116.60353,
            48.25257,
            0
          ],
          [
            -116.60349,
            48.25266,
            0
          ],
          [
            -116.60349,
            48.25271,
            0
          ],
          [
            -116.60354,
            48.25284,
            0
          ],
          [
            -116.60274,
            48.253,
            0
          ],
          [
            -116.59648,
            48.25435,
            0
          ],
          [
            -116.59533,
            48.25463,
            0
          ],
          [
            -116.59488,
            48.25475,
            0
          ],
          [
            -116.59407,
            48.25499,
            0
          ],
          [
            -116.59286,
            48.25539,
            0
          ],
          [
            -116.59259,
            48.2555,
            0
          ],
          [
            -116.59166,
            48.25585,
            0
          ],
          [
            -116.59126,
            48.25603,
            0
          ],
          [
            -116.59116,
            48.25606,
            0
          ],
          [
            -116.59107,
            48.2561,
            0
          ],
          [
            -116.59096,
            48.25616,
            0
          ],
          [
            -116.59079,
            48.25623,
            0
          ],
          [
            -116.5897,
            48.25674,
            0
          ],
          [
            -116.58794,
            48.2575,
            0
          ],
          [
            -116.58639,
            48.2582,
            0
          ],
          [
            -116.58499,
            48.25888,
            0
          ],
          [
            -116.58255,
            48.26011,
            0
          ],
          [
            -116.58173,
            48.2605,
            0
          ],
          [
            -116.58148,
            48.26063,
            0
          ],
          [
            -116.58141,
            48.26066,
            0
          ],
          [
            -116.58049,
            48.26112,
            0
          ],
          [
            -116.58035,
            48.26118,
            0
          ],
          [
            -116.57759,
            48.26255,
            0
          ],
          [
            -116.57753,
            48.2626,
            0
          ],
          [
            -116.57693,
            48.26288,
            0
          ],
          [
            -116.55841,
            48.27189,
            0
          ],
          [
            -116.55704,
            48.27258,
            0
          ],
          [
            -116.55629,
            48.27292,
            0
          ],
          [
            -116.55619,
            48.27297,
            0
          ],
          [
            -116.55612,
            48.27299,
            0
          ],
          [
            -116.55608,
            48.27301,
            0
          ],
          [
            -116.55602,
            48.27303,
            0
          ],
          [
            -116.55589,
            48.27306,
            0
          ],
          [
            -116.55574,
            48.27308,
            0
          ],
          [
            -116.55567,
            48.27308,
            0
          ],
          [
            -116.5556,
            48.27309,
            0
          ],
          [
            -116.55369,
            48.2731,
            0
          ],
          [
            -116.55338,
            48.27311,
            0
          ],
          [
            -116.55336,
            48.27329,
            0
          ],
          [
            -116.55336,
            48.27351,
            0
          ],
          [
            -116.55337,
            48.27364,
            0
          ],
          [
            -116.55336,
            48.27507,
            0
          ],
          [
            -116.55338,
            48.27523,
            0
          ],
          [
            -116.5534,
            48.27554,
            0
          ],
          [
            -116.55344,
            48.27579,
            0
          ],
          [
            -116.55347,
            48.27611,
            0
          ],
          [
            -116.55347,
            48.27658,
            0
          ]
        ],
			},
			id: "86cd5e29-e6eb-4279-b976-7e5546c42965",
		},
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -117.0381939,
    //       48.1788022,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Oldtown, ID"
    //   },
    //   id: "6c8f2f29-af89-4ec3-bc62-6325a3a1d111"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.909367,
    //       48.1805552,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Priest River, ID"
    //   },
    //   id: "685b01dc-c7e0-41c1-baeb-a3140b6e0d3c"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.6110476,
    //       48.2515874,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Dover, ID"
    //   },
    //   id: "d4088fda-e69c-430a-8ed7-21b6ee4e8dfb"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.5534675,
    //       48.276577,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Sandpoint, ID"
    //   },
    //   id: "e7a7b4c7-2303-4ba0-a75f-a9afb6a6d792"
    // }
	],
};

export default PanhandleHistoric;
