import { Link } from "react-router-dom";
import Logo from "../../images/Logo";

const PageNotFound = () => {
    return (
        <div id="travel-assistant" className="error404">
            <div className="main-content">
                <div className="content-wrapper">
                    <Link to={`/`} className="vi-logo">
                        <Logo />
                    </Link>
                    <h1>Travel Assistant</h1>
                    <h2>404 Error - this page cannot be found.</h2>
                    <Link to={`/`} className="btn btn-green">Return to Itinerary Planner</Link>
                </div>   
            </div>
        </div>
    );
}

export default PageNotFound;