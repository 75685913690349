const Teton = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Teton Scenic Byway",
				description: "Boasting rugged, panoramic views from “the quiet side of the Grand Teton Mountains,” the Teton Scenic Byway grants visitors plenty of opportunities to immerse themselves in Idaho's natural beauty. Sweeping vistas transition to far-reaching fields as you continue along the byway. From waterfalls and wilderness to wide-open spaces, your adventure in eastern Idaho will be an unforgettable one.",
				link: "https://visitidaho.org/things-to-do/road-trips/teton-scenic-byway/",
				route: [
					"Swan Valley", 
					"Victor", 
					"Driggs",
          "Tetonia",
          "Ashton"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
          [
            -111.34225,
            43.45451,
            0
          ],
          [
            -111.34214,
            43.45447,
            0
          ],
          [
            -111.3421,
            43.45443,
            0
          ],
          [
            -111.3414,
            43.45334,
            0
          ],
          [
            -111.34136,
            43.45327,
            0
          ],
          [
            -111.34134,
            43.45321,
            0
          ],
          [
            -111.34136,
            43.45316,
            0
          ],
          [
            -111.34182,
            43.45278,
            0
          ],
          [
            -111.34038,
            43.45175,
            0
          ],
          [
            -111.33993,
            43.4514,
            0
          ],
          [
            -111.33713,
            43.4533,
            0
          ],
          [
            -111.33697,
            43.4534,
            0
          ],
          [
            -111.33604,
            43.45403,
            0
          ],
          [
            -111.33602,
            43.45404,
            0
          ],
          [
            -111.32928,
            43.45858,
            0
          ],
          [
            -111.32902,
            43.45877,
            0
          ],
          [
            -111.32853,
            43.45915,
            0
          ],
          [
            -111.3285,
            43.45917,
            0
          ],
          [
            -111.32795,
            43.45962,
            0
          ],
          [
            -111.32742,
            43.46009,
            0
          ],
          [
            -111.32709,
            43.46042,
            0
          ],
          [
            -111.32695,
            43.46057,
            0
          ],
          [
            -111.3268,
            43.46076,
            0
          ],
          [
            -111.32668,
            43.46093,
            0
          ],
          [
            -111.32664,
            43.46097,
            0
          ],
          [
            -111.32651,
            43.46118,
            0
          ],
          [
            -111.32641,
            43.46138,
            0
          ],
          [
            -111.32622,
            43.46188,
            0
          ],
          [
            -111.32556,
            43.46374,
            0
          ],
          [
            -111.32547,
            43.46404,
            0
          ],
          [
            -111.32545,
            43.46418,
            0
          ],
          [
            -111.32543,
            43.46449,
            0
          ],
          [
            -111.32543,
            43.46462,
            0
          ],
          [
            -111.32547,
            43.46499,
            0
          ],
          [
            -111.3256,
            43.46572,
            0
          ],
          [
            -111.32582,
            43.46678,
            0
          ],
          [
            -111.32582,
            43.46681,
            0
          ],
          [
            -111.32588,
            43.46706,
            0
          ],
          [
            -111.32596,
            43.4675,
            0
          ],
          [
            -111.32603,
            43.46782,
            0
          ],
          [
            -111.32604,
            43.46789,
            0
          ],
          [
            -111.32607,
            43.4683,
            0
          ],
          [
            -111.32605,
            43.46852,
            0
          ],
          [
            -111.32602,
            43.46866,
            0
          ],
          [
            -111.32598,
            43.46879,
            0
          ],
          [
            -111.32597,
            43.46881,
            0
          ],
          [
            -111.32585,
            43.46913,
            0
          ],
          [
            -111.3258,
            43.46924,
            0
          ],
          [
            -111.32571,
            43.46953,
            0
          ],
          [
            -111.32568,
            43.46965,
            0
          ],
          [
            -111.32567,
            43.46977,
            0
          ],
          [
            -111.32567,
            43.46987,
            0
          ],
          [
            -111.32568,
            43.47008,
            0
          ],
          [
            -111.32574,
            43.47032,
            0
          ],
          [
            -111.3258,
            43.4705,
            0
          ],
          [
            -111.326,
            43.471,
            0
          ],
          [
            -111.32616,
            43.47128,
            0
          ],
          [
            -111.32626,
            43.4714,
            0
          ],
          [
            -111.32637,
            43.47152,
            0
          ],
          [
            -111.32645,
            43.47159,
            0
          ],
          [
            -111.32654,
            43.47166,
            0
          ],
          [
            -111.32667,
            43.47175,
            0
          ],
          [
            -111.32677,
            43.4718,
            0
          ],
          [
            -111.32687,
            43.47186,
            0
          ],
          [
            -111.32706,
            43.47195,
            0
          ],
          [
            -111.32755,
            43.4721,
            0
          ],
          [
            -111.33025,
            43.47275,
            0
          ],
          [
            -111.33055,
            43.47281,
            0
          ],
          [
            -111.33105,
            43.47295,
            0
          ],
          [
            -111.33157,
            43.47314,
            0
          ],
          [
            -111.33189,
            43.47329,
            0
          ],
          [
            -111.33199,
            43.47335,
            0
          ],
          [
            -111.3321,
            43.4734,
            0
          ],
          [
            -111.33234,
            43.47353,
            0
          ],
          [
            -111.33274,
            43.47378,
            0
          ],
          [
            -111.33387,
            43.47452,
            0
          ],
          [
            -111.33467,
            43.47502,
            0
          ],
          [
            -111.33544,
            43.47553,
            0
          ],
          [
            -111.33585,
            43.47578,
            0
          ],
          [
            -111.33603,
            43.47587,
            0
          ],
          [
            -111.33642,
            43.47603,
            0
          ],
          [
            -111.33663,
            43.4761,
            0
          ],
          [
            -111.33691,
            43.47618,
            0
          ],
          [
            -111.33768,
            43.47637,
            0
          ],
          [
            -111.3382,
            43.47652,
            0
          ],
          [
            -111.33842,
            43.4766,
            0
          ],
          [
            -111.33845,
            43.4766,
            0
          ],
          [
            -111.33881,
            43.47673,
            0
          ],
          [
            -111.34258,
            43.47827,
            0
          ],
          [
            -111.34296,
            43.47844,
            0
          ],
          [
            -111.34387,
            43.47888,
            0
          ],
          [
            -111.34789,
            43.48104,
            0
          ],
          [
            -111.34836,
            43.48134,
            0
          ],
          [
            -111.34837,
            43.48135,
            0
          ],
          [
            -111.3485,
            43.48145,
            0
          ],
          [
            -111.34865,
            43.48158,
            0
          ],
          [
            -111.34884,
            43.48176,
            0
          ],
          [
            -111.34897,
            43.4819,
            0
          ],
          [
            -111.34914,
            43.48213,
            0
          ],
          [
            -111.34941,
            43.48257,
            0
          ],
          [
            -111.34943,
            43.48262,
            0
          ],
          [
            -111.34952,
            43.48281,
            0
          ],
          [
            -111.34959,
            43.483,
            0
          ],
          [
            -111.34966,
            43.48326,
            0
          ],
          [
            -111.3497,
            43.48348,
            0
          ],
          [
            -111.34973,
            43.48401,
            0
          ],
          [
            -111.34976,
            43.48914,
            0
          ],
          [
            -111.34975,
            43.49,
            0
          ],
          [
            -111.34976,
            43.49051,
            0
          ],
          [
            -111.34977,
            43.49058,
            0
          ],
          [
            -111.34977,
            43.49063,
            0
          ],
          [
            -111.34979,
            43.49084,
            0
          ],
          [
            -111.34982,
            43.49099,
            0
          ],
          [
            -111.34985,
            43.49111,
            0
          ],
          [
            -111.34985,
            43.49112,
            0
          ],
          [
            -111.3499,
            43.49127,
            0
          ],
          [
            -111.34991,
            43.49129,
            0
          ],
          [
            -111.34994,
            43.49139,
            0
          ],
          [
            -111.35005,
            43.49164,
            0
          ],
          [
            -111.35005,
            43.49165,
            0
          ],
          [
            -111.35013,
            43.49179,
            0
          ],
          [
            -111.3502,
            43.49193,
            0
          ],
          [
            -111.35031,
            43.4921,
            0
          ],
          [
            -111.35034,
            43.49213,
            0
          ],
          [
            -111.35051,
            43.49237,
            0
          ],
          [
            -111.35104,
            43.493,
            0
          ],
          [
            -111.3511,
            43.49306,
            0
          ],
          [
            -111.35155,
            43.49358,
            0
          ],
          [
            -111.35167,
            43.49371,
            0
          ],
          [
            -111.35175,
            43.49381,
            0
          ],
          [
            -111.35176,
            43.49382,
            0
          ],
          [
            -111.35177,
            43.49384,
            0
          ],
          [
            -111.35277,
            43.49501,
            0
          ],
          [
            -111.35279,
            43.49504,
            0
          ],
          [
            -111.35279,
            43.49505,
            0
          ],
          [
            -111.3531,
            43.49547,
            0
          ],
          [
            -111.35419,
            43.49708,
            0
          ],
          [
            -111.35464,
            43.49772,
            0
          ],
          [
            -111.35504,
            43.49834,
            0
          ],
          [
            -111.35698,
            43.50124,
            0
          ],
          [
            -111.35708,
            43.5014,
            0
          ],
          [
            -111.35718,
            43.50161,
            0
          ],
          [
            -111.35722,
            43.50168,
            0
          ],
          [
            -111.35736,
            43.50206,
            0
          ],
          [
            -111.35743,
            43.50229,
            0
          ],
          [
            -111.35746,
            43.50243,
            0
          ],
          [
            -111.35752,
            43.50277,
            0
          ],
          [
            -111.35755,
            43.50303,
            0
          ],
          [
            -111.35753,
            43.50346,
            0
          ],
          [
            -111.35746,
            43.50386,
            0
          ],
          [
            -111.35735,
            43.50417,
            0
          ],
          [
            -111.35728,
            43.50432,
            0
          ],
          [
            -111.35714,
            43.50456,
            0
          ],
          [
            -111.357,
            43.50476,
            0
          ],
          [
            -111.35682,
            43.50499,
            0
          ],
          [
            -111.3567,
            43.50512,
            0
          ],
          [
            -111.35667,
            43.50516,
            0
          ],
          [
            -111.35581,
            43.50595,
            0
          ],
          [
            -111.35505,
            43.50668,
            0
          ],
          [
            -111.3549,
            43.50681,
            0
          ],
          [
            -111.35456,
            43.50708,
            0
          ],
          [
            -111.35442,
            43.50718,
            0
          ],
          [
            -111.35415,
            43.50741,
            0
          ],
          [
            -111.35364,
            43.50792,
            0
          ],
          [
            -111.35349,
            43.50806,
            0
          ],
          [
            -111.35341,
            43.50815,
            0
          ],
          [
            -111.353,
            43.50855,
            0
          ],
          [
            -111.3529,
            43.50864,
            0
          ],
          [
            -111.35263,
            43.50892,
            0
          ],
          [
            -111.35262,
            43.50894,
            0
          ],
          [
            -111.35247,
            43.50909,
            0
          ],
          [
            -111.35238,
            43.5092,
            0
          ],
          [
            -111.35227,
            43.50936,
            0
          ],
          [
            -111.35181,
            43.50995,
            0
          ],
          [
            -111.35127,
            43.51031,
            0
          ],
          [
            -111.35116,
            43.51037,
            0
          ],
          [
            -111.35103,
            43.51047,
            0
          ],
          [
            -111.35082,
            43.51066,
            0
          ],
          [
            -111.35052,
            43.51089,
            0
          ],
          [
            -111.35032,
            43.51102,
            0
          ],
          [
            -111.35013,
            43.51112,
            0
          ],
          [
            -111.35001,
            43.51117,
            0
          ],
          [
            -111.34983,
            43.51123,
            0
          ],
          [
            -111.34855,
            43.51161,
            0
          ],
          [
            -111.34796,
            43.51181,
            0
          ],
          [
            -111.34788,
            43.51183,
            0
          ],
          [
            -111.34742,
            43.51201,
            0
          ],
          [
            -111.34689,
            43.51227,
            0
          ],
          [
            -111.34652,
            43.5125,
            0
          ],
          [
            -111.34643,
            43.51255,
            0
          ],
          [
            -111.34615,
            43.51274,
            0
          ],
          [
            -111.34607,
            43.5128,
            0
          ],
          [
            -111.34529,
            43.51327,
            0
          ],
          [
            -111.34501,
            43.51346,
            0
          ],
          [
            -111.34496,
            43.5135,
            0
          ],
          [
            -111.34416,
            43.51403,
            0
          ],
          [
            -111.34405,
            43.51411,
            0
          ],
          [
            -111.34355,
            43.51444,
            0
          ],
          [
            -111.34333,
            43.51457,
            0
          ],
          [
            -111.3417,
            43.51536,
            0
          ],
          [
            -111.34152,
            43.51546,
            0
          ],
          [
            -111.34125,
            43.51564,
            0
          ],
          [
            -111.34111,
            43.51576,
            0
          ],
          [
            -111.34098,
            43.51589,
            0
          ],
          [
            -111.34092,
            43.51596,
            0
          ],
          [
            -111.3408,
            43.51612,
            0
          ],
          [
            -111.34071,
            43.51626,
            0
          ],
          [
            -111.34065,
            43.51641,
            0
          ],
          [
            -111.34055,
            43.51672,
            0
          ],
          [
            -111.34055,
            43.51674,
            0
          ],
          [
            -111.34039,
            43.51727,
            0
          ],
          [
            -111.34034,
            43.51741,
            0
          ],
          [
            -111.33999,
            43.51863,
            0
          ],
          [
            -111.33991,
            43.51885,
            0
          ],
          [
            -111.33983,
            43.51902,
            0
          ],
          [
            -111.33982,
            43.51903,
            0
          ],
          [
            -111.33968,
            43.51924,
            0
          ],
          [
            -111.33957,
            43.51938,
            0
          ],
          [
            -111.3394,
            43.51953,
            0
          ],
          [
            -111.3393,
            43.51961,
            0
          ],
          [
            -111.33916,
            43.51971,
            0
          ],
          [
            -111.33903,
            43.51979,
            0
          ],
          [
            -111.33895,
            43.51983,
            0
          ],
          [
            -111.3387,
            43.51994,
            0
          ],
          [
            -111.33848,
            43.52002,
            0
          ],
          [
            -111.33766,
            43.52036,
            0
          ],
          [
            -111.33747,
            43.52045,
            0
          ],
          [
            -111.33742,
            43.52049,
            0
          ],
          [
            -111.33734,
            43.52053,
            0
          ],
          [
            -111.33724,
            43.52061,
            0
          ],
          [
            -111.33713,
            43.52069,
            0
          ],
          [
            -111.33702,
            43.52079,
            0
          ],
          [
            -111.3369,
            43.52091,
            0
          ],
          [
            -111.33677,
            43.52103,
            0
          ],
          [
            -111.33666,
            43.52115,
            0
          ],
          [
            -111.33607,
            43.5217,
            0
          ],
          [
            -111.33592,
            43.52182,
            0
          ],
          [
            -111.3357,
            43.52198,
            0
          ],
          [
            -111.33556,
            43.52207,
            0
          ],
          [
            -111.33539,
            43.52219,
            0
          ],
          [
            -111.33492,
            43.52246,
            0
          ],
          [
            -111.33491,
            43.52246,
            0
          ],
          [
            -111.3345,
            43.52266,
            0
          ],
          [
            -111.33428,
            43.52275,
            0
          ],
          [
            -111.33427,
            43.52275,
            0
          ],
          [
            -111.33383,
            43.52291,
            0
          ],
          [
            -111.33361,
            43.52298,
            0
          ],
          [
            -111.33356,
            43.523,
            0
          ],
          [
            -111.33338,
            43.52305,
            0
          ],
          [
            -111.33316,
            43.52312,
            0
          ],
          [
            -111.33228,
            43.52337,
            0
          ],
          [
            -111.33223,
            43.52339,
            0
          ],
          [
            -111.33124,
            43.52366,
            0
          ],
          [
            -111.32998,
            43.52398,
            0
          ],
          [
            -111.32916,
            43.52422,
            0
          ],
          [
            -111.32885,
            43.52433,
            0
          ],
          [
            -111.32858,
            43.52444,
            0
          ],
          [
            -111.32821,
            43.52461,
            0
          ],
          [
            -111.32805,
            43.5247,
            0
          ],
          [
            -111.328,
            43.52472,
            0
          ],
          [
            -111.3278,
            43.52483,
            0
          ],
          [
            -111.32649,
            43.52562,
            0
          ],
          [
            -111.32593,
            43.52601,
            0
          ],
          [
            -111.3259,
            43.52604,
            0
          ],
          [
            -111.32573,
            43.52617,
            0
          ],
          [
            -111.32545,
            43.52642,
            0
          ],
          [
            -111.32543,
            43.52643,
            0
          ],
          [
            -111.32449,
            43.52735,
            0
          ],
          [
            -111.32424,
            43.52756,
            0
          ],
          [
            -111.32403,
            43.52772,
            0
          ],
          [
            -111.32383,
            43.52786,
            0
          ],
          [
            -111.3237,
            43.52793,
            0
          ],
          [
            -111.32368,
            43.52795,
            0
          ],
          [
            -111.32321,
            43.52819,
            0
          ],
          [
            -111.32166,
            43.52881,
            0
          ],
          [
            -111.32158,
            43.52886,
            0
          ],
          [
            -111.32145,
            43.52893,
            0
          ],
          [
            -111.32123,
            43.52908,
            0
          ],
          [
            -111.32121,
            43.5291,
            0
          ],
          [
            -111.32109,
            43.52919,
            0
          ],
          [
            -111.32042,
            43.52978,
            0
          ],
          [
            -111.32013,
            43.53008,
            0
          ],
          [
            -111.32007,
            43.53013,
            0
          ],
          [
            -111.31969,
            43.53051,
            0
          ],
          [
            -111.31921,
            43.53097,
            0
          ],
          [
            -111.31879,
            43.5314,
            0
          ],
          [
            -111.31826,
            43.53192,
            0
          ],
          [
            -111.31746,
            43.53267,
            0
          ],
          [
            -111.31696,
            43.5331,
            0
          ],
          [
            -111.31478,
            43.53477,
            0
          ],
          [
            -111.3144,
            43.53504,
            0
          ],
          [
            -111.31363,
            43.53553,
            0
          ],
          [
            -111.31324,
            43.53573,
            0
          ],
          [
            -111.31306,
            43.53583,
            0
          ],
          [
            -111.3072,
            43.53854,
            0
          ],
          [
            -111.30665,
            43.53881,
            0
          ],
          [
            -111.30641,
            43.53895,
            0
          ],
          [
            -111.30602,
            43.53921,
            0
          ],
          [
            -111.30559,
            43.53957,
            0
          ],
          [
            -111.30542,
            43.53973,
            0
          ],
          [
            -111.30234,
            43.5429,
            0
          ],
          [
            -111.3021,
            43.54316,
            0
          ],
          [
            -111.30186,
            43.54346,
            0
          ],
          [
            -111.30175,
            43.54363,
            0
          ],
          [
            -111.30168,
            43.54376,
            0
          ],
          [
            -111.30155,
            43.54407,
            0
          ],
          [
            -111.30145,
            43.5444,
            0
          ],
          [
            -111.30132,
            43.54507,
            0
          ],
          [
            -111.30128,
            43.54522,
            0
          ],
          [
            -111.30127,
            43.54528,
            0
          ],
          [
            -111.30125,
            43.54535,
            0
          ],
          [
            -111.30117,
            43.54556,
            0
          ],
          [
            -111.30111,
            43.54569,
            0
          ],
          [
            -111.30106,
            43.54578,
            0
          ],
          [
            -111.30096,
            43.54593,
            0
          ],
          [
            -111.30085,
            43.54608,
            0
          ],
          [
            -111.30062,
            43.54633,
            0
          ],
          [
            -111.30048,
            43.54645,
            0
          ],
          [
            -111.29905,
            43.54777,
            0
          ],
          [
            -111.29839,
            43.54847,
            0
          ],
          [
            -111.29777,
            43.54917,
            0
          ],
          [
            -111.29769,
            43.54927,
            0
          ],
          [
            -111.29731,
            43.54971,
            0
          ],
          [
            -111.2966,
            43.55059,
            0
          ],
          [
            -111.2963,
            43.55098,
            0
          ],
          [
            -111.29603,
            43.55136,
            0
          ],
          [
            -111.29575,
            43.55178,
            0
          ],
          [
            -111.29546,
            43.55218,
            0
          ],
          [
            -111.29523,
            43.55248,
            0
          ],
          [
            -111.29507,
            43.55265,
            0
          ],
          [
            -111.29495,
            43.55276,
            0
          ],
          [
            -111.29465,
            43.55299,
            0
          ],
          [
            -111.29453,
            43.55307,
            0
          ],
          [
            -111.29432,
            43.55318,
            0
          ],
          [
            -111.29402,
            43.55331,
            0
          ],
          [
            -111.29388,
            43.55336,
            0
          ],
          [
            -111.2938,
            43.55338,
            0
          ],
          [
            -111.29374,
            43.5534,
            0
          ],
          [
            -111.29359,
            43.55344,
            0
          ],
          [
            -111.29357,
            43.55345,
            0
          ],
          [
            -111.29325,
            43.55351,
            0
          ],
          [
            -111.29283,
            43.55355,
            0
          ],
          [
            -111.29253,
            43.55357,
            0
          ],
          [
            -111.29194,
            43.55358,
            0
          ],
          [
            -111.29185,
            43.55359,
            0
          ],
          [
            -111.29131,
            43.5536,
            0
          ],
          [
            -111.29044,
            43.55368,
            0
          ],
          [
            -111.29025,
            43.55372,
            0
          ],
          [
            -111.29002,
            43.55378,
            0
          ],
          [
            -111.28978,
            43.55386,
            0
          ],
          [
            -111.28968,
            43.5539,
            0
          ],
          [
            -111.28939,
            43.55404,
            0
          ],
          [
            -111.28917,
            43.55417,
            0
          ],
          [
            -111.28716,
            43.55553,
            0
          ],
          [
            -111.28654,
            43.55597,
            0
          ],
          [
            -111.28532,
            43.5568,
            0
          ],
          [
            -111.28485,
            43.55706,
            0
          ],
          [
            -111.28451,
            43.55722,
            0
          ],
          [
            -111.28436,
            43.5573,
            0
          ],
          [
            -111.28401,
            43.55744,
            0
          ],
          [
            -111.28362,
            43.55758,
            0
          ],
          [
            -111.2836,
            43.55758,
            0
          ],
          [
            -111.27616,
            43.56026,
            0
          ],
          [
            -111.27583,
            43.56037,
            0
          ],
          [
            -111.27553,
            43.56048,
            0
          ],
          [
            -111.27517,
            43.5606,
            0
          ],
          [
            -111.2733,
            43.5613,
            0
          ],
          [
            -111.2727,
            43.56156,
            0
          ],
          [
            -111.27169,
            43.56196,
            0
          ],
          [
            -111.27134,
            43.56211,
            0
          ],
          [
            -111.27005,
            43.56262,
            0
          ],
          [
            -111.27001,
            43.56263,
            0
          ],
          [
            -111.26935,
            43.56288,
            0
          ],
          [
            -111.26847,
            43.56314,
            0
          ],
          [
            -111.26822,
            43.5632,
            0
          ],
          [
            -111.26784,
            43.56332,
            0
          ],
          [
            -111.26749,
            43.56342,
            0
          ],
          [
            -111.26715,
            43.56353,
            0
          ],
          [
            -111.26648,
            43.56372,
            0
          ],
          [
            -111.26586,
            43.56386,
            0
          ],
          [
            -111.26524,
            43.56395,
            0
          ],
          [
            -111.26494,
            43.56398,
            0
          ],
          [
            -111.2647,
            43.56399,
            0
          ],
          [
            -111.2644,
            43.56399,
            0
          ],
          [
            -111.26411,
            43.56398,
            0
          ],
          [
            -111.26383,
            43.56396,
            0
          ],
          [
            -111.26308,
            43.56386,
            0
          ],
          [
            -111.26251,
            43.56373,
            0
          ],
          [
            -111.26008,
            43.56308,
            0
          ],
          [
            -111.25975,
            43.56298,
            0
          ],
          [
            -111.25879,
            43.56272,
            0
          ],
          [
            -111.25856,
            43.56265,
            0
          ],
          [
            -111.25854,
            43.56264,
            0
          ],
          [
            -111.25829,
            43.56258,
            0
          ],
          [
            -111.25731,
            43.56231,
            0
          ],
          [
            -111.25685,
            43.56214,
            0
          ],
          [
            -111.25658,
            43.56203,
            0
          ],
          [
            -111.25619,
            43.56185,
            0
          ],
          [
            -111.25532,
            43.56136,
            0
          ],
          [
            -111.25511,
            43.56126,
            0
          ],
          [
            -111.25491,
            43.56118,
            0
          ],
          [
            -111.25476,
            43.56113,
            0
          ],
          [
            -111.2546,
            43.56109,
            0
          ],
          [
            -111.25441,
            43.56106,
            0
          ],
          [
            -111.25426,
            43.56105,
            0
          ],
          [
            -111.25402,
            43.56105,
            0
          ],
          [
            -111.25382,
            43.56107,
            0
          ],
          [
            -111.25358,
            43.56112,
            0
          ],
          [
            -111.25334,
            43.5612,
            0
          ],
          [
            -111.25316,
            43.56128,
            0
          ],
          [
            -111.25298,
            43.56138,
            0
          ],
          [
            -111.25281,
            43.5615,
            0
          ],
          [
            -111.25266,
            43.56163,
            0
          ],
          [
            -111.25243,
            43.56185,
            0
          ],
          [
            -111.25222,
            43.56208,
            0
          ],
          [
            -111.25153,
            43.56279,
            0
          ],
          [
            -111.25126,
            43.56305,
            0
          ],
          [
            -111.25102,
            43.56326,
            0
          ],
          [
            -111.25078,
            43.56349,
            0
          ],
          [
            -111.25042,
            43.5638,
            0
          ],
          [
            -111.24975,
            43.56429,
            0
          ],
          [
            -111.24925,
            43.56463,
            0
          ],
          [
            -111.24922,
            43.56464,
            0
          ],
          [
            -111.24829,
            43.56524,
            0
          ],
          [
            -111.24822,
            43.56528,
            0
          ],
          [
            -111.24781,
            43.56554,
            0
          ],
          [
            -111.24753,
            43.5657,
            0
          ],
          [
            -111.24747,
            43.56574,
            0
          ],
          [
            -111.24673,
            43.56618,
            0
          ],
          [
            -111.24593,
            43.56668,
            0
          ],
          [
            -111.24565,
            43.56689,
            0
          ],
          [
            -111.24536,
            43.56713,
            0
          ],
          [
            -111.24524,
            43.56727,
            0
          ],
          [
            -111.24498,
            43.56764,
            0
          ],
          [
            -111.24487,
            43.56783,
            0
          ],
          [
            -111.24474,
            43.56814,
            0
          ],
          [
            -111.24459,
            43.56863,
            0
          ],
          [
            -111.24453,
            43.56887,
            0
          ],
          [
            -111.24447,
            43.56905,
            0
          ],
          [
            -111.24441,
            43.56917,
            0
          ],
          [
            -111.24437,
            43.56924,
            0
          ],
          [
            -111.24429,
            43.56934,
            0
          ],
          [
            -111.24423,
            43.5694,
            0
          ],
          [
            -111.24417,
            43.56945,
            0
          ],
          [
            -111.2441,
            43.5695,
            0
          ],
          [
            -111.24398,
            43.56957,
            0
          ],
          [
            -111.24383,
            43.56964,
            0
          ],
          [
            -111.2437,
            43.56968,
            0
          ],
          [
            -111.24349,
            43.56973,
            0
          ],
          [
            -111.24283,
            43.56976,
            0
          ],
          [
            -111.24261,
            43.56978,
            0
          ],
          [
            -111.24248,
            43.5698,
            0
          ],
          [
            -111.24246,
            43.5698,
            0
          ],
          [
            -111.24236,
            43.56983,
            0
          ],
          [
            -111.2421,
            43.56992,
            0
          ],
          [
            -111.24199,
            43.56998,
            0
          ],
          [
            -111.24189,
            43.57005,
            0
          ],
          [
            -111.24179,
            43.57013,
            0
          ],
          [
            -111.24169,
            43.57024,
            0
          ],
          [
            -111.24161,
            43.57039,
            0
          ],
          [
            -111.24157,
            43.57052,
            0
          ],
          [
            -111.24156,
            43.57064,
            0
          ],
          [
            -111.24156,
            43.57075,
            0
          ],
          [
            -111.24158,
            43.57092,
            0
          ],
          [
            -111.24159,
            43.57111,
            0
          ],
          [
            -111.24157,
            43.57126,
            0
          ],
          [
            -111.24154,
            43.57136,
            0
          ],
          [
            -111.24149,
            43.57147,
            0
          ],
          [
            -111.2414,
            43.57158,
            0
          ],
          [
            -111.24129,
            43.57169,
            0
          ],
          [
            -111.24117,
            43.57177,
            0
          ],
          [
            -111.24106,
            43.57183,
            0
          ],
          [
            -111.24101,
            43.57185,
            0
          ],
          [
            -111.24094,
            43.57189,
            0
          ],
          [
            -111.24084,
            43.57193,
            0
          ],
          [
            -111.24073,
            43.57196,
            0
          ],
          [
            -111.2406,
            43.57198,
            0
          ],
          [
            -111.24047,
            43.57199,
            0
          ],
          [
            -111.24037,
            43.57199,
            0
          ],
          [
            -111.24015,
            43.57197,
            0
          ],
          [
            -111.23993,
            43.57192,
            0
          ],
          [
            -111.2399,
            43.57192,
            0
          ],
          [
            -111.23961,
            43.57185,
            0
          ],
          [
            -111.23927,
            43.5718,
            0
          ],
          [
            -111.23896,
            43.5718,
            0
          ],
          [
            -111.23881,
            43.57182,
            0
          ],
          [
            -111.23868,
            43.57185,
            0
          ],
          [
            -111.23861,
            43.57186,
            0
          ],
          [
            -111.23841,
            43.57192,
            0
          ],
          [
            -111.23817,
            43.57201,
            0
          ],
          [
            -111.23783,
            43.57217,
            0
          ],
          [
            -111.23759,
            43.57226,
            0
          ],
          [
            -111.23754,
            43.57229,
            0
          ],
          [
            -111.23729,
            43.57238,
            0
          ],
          [
            -111.23676,
            43.57252,
            0
          ],
          [
            -111.23607,
            43.57264,
            0
          ],
          [
            -111.23573,
            43.57274,
            0
          ],
          [
            -111.2356,
            43.57281,
            0
          ],
          [
            -111.23548,
            43.57289,
            0
          ],
          [
            -111.23539,
            43.57296,
            0
          ],
          [
            -111.23537,
            43.57299,
            0
          ],
          [
            -111.2352,
            43.57316,
            0
          ],
          [
            -111.23508,
            43.57333,
            0
          ],
          [
            -111.23496,
            43.57348,
            0
          ],
          [
            -111.23483,
            43.57359,
            0
          ],
          [
            -111.23481,
            43.57362,
            0
          ],
          [
            -111.23466,
            43.57371,
            0
          ],
          [
            -111.2345,
            43.57378,
            0
          ],
          [
            -111.2344,
            43.57381,
            0
          ],
          [
            -111.2343,
            43.57383,
            0
          ],
          [
            -111.23419,
            43.57384,
            0
          ],
          [
            -111.23395,
            43.57384,
            0
          ],
          [
            -111.23373,
            43.57382,
            0
          ],
          [
            -111.23359,
            43.57379,
            0
          ],
          [
            -111.23343,
            43.57374,
            0
          ],
          [
            -111.23328,
            43.57368,
            0
          ],
          [
            -111.23308,
            43.57358,
            0
          ],
          [
            -111.23289,
            43.57344,
            0
          ],
          [
            -111.23278,
            43.57334,
            0
          ],
          [
            -111.2326,
            43.57322,
            0
          ],
          [
            -111.23245,
            43.57313,
            0
          ],
          [
            -111.23229,
            43.57307,
            0
          ],
          [
            -111.23214,
            43.57303,
            0
          ],
          [
            -111.23208,
            43.57302,
            0
          ],
          [
            -111.232,
            43.573,
            0
          ],
          [
            -111.23184,
            43.57298,
            0
          ],
          [
            -111.2318,
            43.57297,
            0
          ],
          [
            -111.23151,
            43.57294,
            0
          ],
          [
            -111.23127,
            43.5729,
            0
          ],
          [
            -111.23108,
            43.57286,
            0
          ],
          [
            -111.23088,
            43.57281,
            0
          ],
          [
            -111.23087,
            43.5728,
            0
          ],
          [
            -111.23067,
            43.57274,
            0
          ],
          [
            -111.23041,
            43.57264,
            0
          ],
          [
            -111.22994,
            43.57249,
            0
          ],
          [
            -111.2297,
            43.57245,
            0
          ],
          [
            -111.22949,
            43.57243,
            0
          ],
          [
            -111.22927,
            43.57245,
            0
          ],
          [
            -111.22925,
            43.57245,
            0
          ],
          [
            -111.22902,
            43.5725,
            0
          ],
          [
            -111.22887,
            43.57255,
            0
          ],
          [
            -111.22858,
            43.57268,
            0
          ],
          [
            -111.22821,
            43.57283,
            0
          ],
          [
            -111.22802,
            43.5729,
            0
          ],
          [
            -111.22784,
            43.57294,
            0
          ],
          [
            -111.22757,
            43.57298,
            0
          ],
          [
            -111.22673,
            43.57299,
            0
          ],
          [
            -111.22645,
            43.57302,
            0
          ],
          [
            -111.22617,
            43.57306,
            0
          ],
          [
            -111.22598,
            43.5731,
            0
          ],
          [
            -111.22596,
            43.5731,
            0
          ],
          [
            -111.22565,
            43.57317,
            0
          ],
          [
            -111.22516,
            43.57326,
            0
          ],
          [
            -111.22493,
            43.57328,
            0
          ],
          [
            -111.22452,
            43.57328,
            0
          ],
          [
            -111.22413,
            43.57324,
            0
          ],
          [
            -111.2236,
            43.57313,
            0
          ],
          [
            -111.22323,
            43.57303,
            0
          ],
          [
            -111.22309,
            43.573,
            0
          ],
          [
            -111.22261,
            43.57287,
            0
          ],
          [
            -111.22232,
            43.5728,
            0
          ],
          [
            -111.22203,
            43.57272,
            0
          ],
          [
            -111.22176,
            43.57267,
            0
          ],
          [
            -111.22145,
            43.57263,
            0
          ],
          [
            -111.22061,
            43.57255,
            0
          ],
          [
            -111.22031,
            43.57253,
            0
          ],
          [
            -111.2202,
            43.57251,
            0
          ],
          [
            -111.22004,
            43.57249,
            0
          ],
          [
            -111.21996,
            43.57247,
            0
          ],
          [
            -111.21976,
            43.57244,
            0
          ],
          [
            -111.21949,
            43.57238,
            0
          ],
          [
            -111.21853,
            43.57212,
            0
          ],
          [
            -111.21784,
            43.57195,
            0
          ],
          [
            -111.21574,
            43.5715,
            0
          ],
          [
            -111.21526,
            43.57142,
            0
          ],
          [
            -111.21525,
            43.57141,
            0
          ],
          [
            -111.21502,
            43.57137,
            0
          ],
          [
            -111.21478,
            43.57132,
            0
          ],
          [
            -111.21477,
            43.57132,
            0
          ],
          [
            -111.21454,
            43.57128,
            0
          ],
          [
            -111.21229,
            43.57083,
            0
          ],
          [
            -111.21173,
            43.57073,
            0
          ],
          [
            -111.21166,
            43.57071,
            0
          ],
          [
            -111.21142,
            43.57067,
            0
          ],
          [
            -111.21139,
            43.57066,
            0
          ],
          [
            -111.21118,
            43.57064,
            0
          ],
          [
            -111.21104,
            43.57062,
            0
          ],
          [
            -111.21079,
            43.57061,
            0
          ],
          [
            -111.21053,
            43.57064,
            0
          ],
          [
            -111.21033,
            43.57067,
            0
          ],
          [
            -111.21004,
            43.57077,
            0
          ],
          [
            -111.2099,
            43.57085,
            0
          ],
          [
            -111.20967,
            43.57102,
            0
          ],
          [
            -111.20952,
            43.57116,
            0
          ],
          [
            -111.20926,
            43.57142,
            0
          ],
          [
            -111.20886,
            43.57184,
            0
          ],
          [
            -111.20868,
            43.57205,
            0
          ],
          [
            -111.20853,
            43.57226,
            0
          ],
          [
            -111.20844,
            43.57242,
            0
          ],
          [
            -111.20834,
            43.57262,
            0
          ],
          [
            -111.20828,
            43.5728,
            0
          ],
          [
            -111.2082,
            43.573,
            0
          ],
          [
            -111.20809,
            43.57321,
            0
          ],
          [
            -111.20799,
            43.57336,
            0
          ],
          [
            -111.20794,
            43.57342,
            0
          ],
          [
            -111.20777,
            43.57358,
            0
          ],
          [
            -111.2076,
            43.57369,
            0
          ],
          [
            -111.2075,
            43.57374,
            0
          ],
          [
            -111.2074,
            43.57378,
            0
          ],
          [
            -111.20739,
            43.57378,
            0
          ],
          [
            -111.20721,
            43.57384,
            0
          ],
          [
            -111.20705,
            43.57388,
            0
          ],
          [
            -111.20693,
            43.5739,
            0
          ],
          [
            -111.20689,
            43.57391,
            0
          ],
          [
            -111.20672,
            43.57392,
            0
          ],
          [
            -111.20653,
            43.57392,
            0
          ],
          [
            -111.20618,
            43.57388,
            0
          ],
          [
            -111.20598,
            43.57385,
            0
          ],
          [
            -111.20564,
            43.57377,
            0
          ],
          [
            -111.2055,
            43.57372,
            0
          ],
          [
            -111.20548,
            43.57371,
            0
          ],
          [
            -111.20533,
            43.57365,
            0
          ],
          [
            -111.2052,
            43.57359,
            0
          ],
          [
            -111.20503,
            43.5735,
            0
          ],
          [
            -111.20487,
            43.5734,
            0
          ],
          [
            -111.20477,
            43.57333,
            0
          ],
          [
            -111.20469,
            43.57326,
            0
          ],
          [
            -111.2046,
            43.57319,
            0
          ],
          [
            -111.20441,
            43.57302,
            0
          ],
          [
            -111.2043,
            43.5729,
            0
          ],
          [
            -111.20414,
            43.57275,
            0
          ],
          [
            -111.20375,
            43.57245,
            0
          ],
          [
            -111.20361,
            43.57237,
            0
          ],
          [
            -111.20353,
            43.57233,
            0
          ],
          [
            -111.20345,
            43.57228,
            0
          ],
          [
            -111.20326,
            43.57218,
            0
          ],
          [
            -111.20308,
            43.5721,
            0
          ],
          [
            -111.20261,
            43.57191,
            0
          ],
          [
            -111.20208,
            43.57168,
            0
          ],
          [
            -111.20175,
            43.57156,
            0
          ],
          [
            -111.20138,
            43.57144,
            0
          ],
          [
            -111.2012,
            43.57139,
            0
          ],
          [
            -111.20101,
            43.57135,
            0
          ],
          [
            -111.20051,
            43.57127,
            0
          ],
          [
            -111.20017,
            43.57124,
            0
          ],
          [
            -111.20007,
            43.57124,
            0
          ],
          [
            -111.19992,
            43.57123,
            0
          ],
          [
            -111.19979,
            43.57123,
            0
          ],
          [
            -111.19925,
            43.57121,
            0
          ],
          [
            -111.19877,
            43.57121,
            0
          ],
          [
            -111.19757,
            43.57118,
            0
          ],
          [
            -111.19687,
            43.57118,
            0
          ],
          [
            -111.1967,
            43.5712,
            0
          ],
          [
            -111.19639,
            43.57121,
            0
          ],
          [
            -111.19558,
            43.57131,
            0
          ],
          [
            -111.1953,
            43.57133,
            0
          ],
          [
            -111.19522,
            43.57133,
            0
          ],
          [
            -111.19489,
            43.5713,
            0
          ],
          [
            -111.19469,
            43.57126,
            0
          ],
          [
            -111.19446,
            43.57117,
            0
          ],
          [
            -111.19429,
            43.57109,
            0
          ],
          [
            -111.1941,
            43.57099,
            0
          ],
          [
            -111.19407,
            43.57097,
            0
          ],
          [
            -111.19384,
            43.57084,
            0
          ],
          [
            -111.19367,
            43.57076,
            0
          ],
          [
            -111.19349,
            43.57069,
            0
          ],
          [
            -111.19348,
            43.57068,
            0
          ],
          [
            -111.19333,
            43.57064,
            0
          ],
          [
            -111.19306,
            43.57059,
            0
          ],
          [
            -111.19281,
            43.57057,
            0
          ],
          [
            -111.19269,
            43.57057,
            0
          ],
          [
            -111.19254,
            43.57058,
            0
          ],
          [
            -111.19235,
            43.57061,
            0
          ],
          [
            -111.19205,
            43.57067,
            0
          ],
          [
            -111.19182,
            43.57073,
            0
          ],
          [
            -111.1918,
            43.57074,
            0
          ],
          [
            -111.18971,
            43.57133,
            0
          ],
          [
            -111.18905,
            43.57154,
            0
          ],
          [
            -111.18904,
            43.57154,
            0
          ],
          [
            -111.18884,
            43.57162,
            0
          ],
          [
            -111.18882,
            43.57163,
            0
          ],
          [
            -111.18865,
            43.57169,
            0
          ],
          [
            -111.18834,
            43.57182,
            0
          ],
          [
            -111.18768,
            43.57217,
            0
          ],
          [
            -111.18747,
            43.57229,
            0
          ],
          [
            -111.18685,
            43.5727,
            0
          ],
          [
            -111.18554,
            43.5736,
            0
          ],
          [
            -111.18524,
            43.57383,
            0
          ],
          [
            -111.18427,
            43.57468,
            0
          ],
          [
            -111.18367,
            43.57525,
            0
          ],
          [
            -111.18366,
            43.57525,
            0
          ],
          [
            -111.18351,
            43.5754,
            0
          ],
          [
            -111.18321,
            43.57568,
            0
          ],
          [
            -111.1831,
            43.5758,
            0
          ],
          [
            -111.18306,
            43.57582,
            0
          ],
          [
            -111.1827,
            43.57612,
            0
          ],
          [
            -111.18205,
            43.57661,
            0
          ],
          [
            -111.18204,
            43.57662,
            0
          ],
          [
            -111.18173,
            43.57682,
            0
          ],
          [
            -111.18099,
            43.57738,
            0
          ],
          [
            -111.18068,
            43.57766,
            0
          ],
          [
            -111.18054,
            43.5778,
            0
          ],
          [
            -111.18028,
            43.57812,
            0
          ],
          [
            -111.18011,
            43.57836,
            0
          ],
          [
            -111.17997,
            43.57861,
            0
          ],
          [
            -111.17974,
            43.57922,
            0
          ],
          [
            -111.17961,
            43.57966,
            0
          ],
          [
            -111.17937,
            43.58061,
            0
          ],
          [
            -111.17933,
            43.58072,
            0
          ],
          [
            -111.17891,
            43.58226,
            0
          ],
          [
            -111.1788,
            43.58262,
            0
          ],
          [
            -111.17834,
            43.58434,
            0
          ],
          [
            -111.17799,
            43.58557,
            0
          ],
          [
            -111.17797,
            43.58566,
            0
          ],
          [
            -111.17655,
            43.59087,
            0
          ],
          [
            -111.17643,
            43.59128,
            0
          ],
          [
            -111.17633,
            43.59154,
            0
          ],
          [
            -111.17624,
            43.5917,
            0
          ],
          [
            -111.17618,
            43.59178,
            0
          ],
          [
            -111.17617,
            43.59179,
            0
          ],
          [
            -111.17612,
            43.59187,
            0
          ],
          [
            -111.17591,
            43.59208,
            0
          ],
          [
            -111.17576,
            43.59221,
            0
          ],
          [
            -111.17553,
            43.59234,
            0
          ],
          [
            -111.17539,
            43.59241,
            0
          ],
          [
            -111.17514,
            43.59251,
            0
          ],
          [
            -111.17513,
            43.59251,
            0
          ],
          [
            -111.17486,
            43.59259,
            0
          ],
          [
            -111.17454,
            43.59264,
            0
          ],
          [
            -111.17424,
            43.59266,
            0
          ],
          [
            -111.15588,
            43.59259,
            0
          ],
          [
            -111.15397,
            43.59262,
            0
          ],
          [
            -111.15262,
            43.59258,
            0
          ],
          [
            -111.15155,
            43.59259,
            0
          ],
          [
            -111.13308,
            43.5925,
            0
          ],
          [
            -111.13267,
            43.59252,
            0
          ],
          [
            -111.13214,
            43.5926,
            0
          ],
          [
            -111.13158,
            43.59273,
            0
          ],
          [
            -111.13127,
            43.59283,
            0
          ],
          [
            -111.13096,
            43.59296,
            0
          ],
          [
            -111.13077,
            43.59305,
            0
          ],
          [
            -111.13044,
            43.59322,
            0
          ],
          [
            -111.1303,
            43.59332,
            0
          ],
          [
            -111.13016,
            43.59341,
            0
          ],
          [
            -111.12983,
            43.59367,
            0
          ],
          [
            -111.12945,
            43.59404,
            0
          ],
          [
            -111.12924,
            43.59427,
            0
          ],
          [
            -111.12892,
            43.5946,
            0
          ],
          [
            -111.12292,
            43.60103,
            0
          ],
          [
            -111.12264,
            43.6013,
            0
          ],
          [
            -111.12229,
            43.60159,
            0
          ],
          [
            -111.12211,
            43.60172,
            0
          ],
          [
            -111.12185,
            43.60188,
            0
          ],
          [
            -111.12121,
            43.60218,
            0
          ],
          [
            -111.12063,
            43.60236,
            0
          ],
          [
            -111.12037,
            43.60242,
            0
          ],
          [
            -111.12035,
            43.60243,
            0
          ],
          [
            -111.12018,
            43.60246,
            0
          ],
          [
            -111.11992,
            43.60249,
            0
          ],
          [
            -111.11956,
            43.60252,
            0
          ],
          [
            -111.11915,
            43.60254,
            0
          ],
          [
            -111.11193,
            43.6026,
            0
          ],
          [
            -111.11176,
            43.60261,
            0
          ],
          [
            -111.11134,
            43.60262,
            0
          ],
          [
            -111.11134,
            43.60366,
            0
          ],
          [
            -111.11133,
            43.60407,
            0
          ],
          [
            -111.11134,
            43.60426,
            0
          ],
          [
            -111.11133,
            43.60572,
            0
          ],
          [
            -111.11128,
            43.60615,
            0
          ],
          [
            -111.11123,
            43.60636,
            0
          ],
          [
            -111.11107,
            43.60682,
            0
          ],
          [
            -111.11087,
            43.60753,
            0
          ],
          [
            -111.1108,
            43.6079,
            0
          ],
          [
            -111.11077,
            43.60831,
            0
          ],
          [
            -111.11074,
            43.60937,
            0
          ],
          [
            -111.11074,
            43.61134,
            0
          ],
          [
            -111.11073,
            43.6116,
            0
          ],
          [
            -111.11074,
            43.61203,
            0
          ],
          [
            -111.1107,
            43.62089,
            0
          ],
          [
            -111.11071,
            43.6214,
            0
          ],
          [
            -111.1106,
            43.6476,
            0
          ],
          [
            -111.11061,
            43.64829,
            0
          ],
          [
            -111.1106,
            43.64868,
            0
          ],
          [
            -111.1106,
            43.6492,
            0
          ],
          [
            -111.11058,
            43.64948,
            0
          ],
          [
            -111.11055,
            43.66447,
            0
          ],
          [
            -111.11052,
            43.66623,
            0
          ],
          [
            -111.11048,
            43.6771,
            0
          ],
          [
            -111.11047,
            43.67725,
            0
          ],
          [
            -111.11047,
            43.67805,
            0
          ],
          [
            -111.11046,
            43.67847,
            0
          ],
          [
            -111.11045,
            43.69037,
            0
          ],
          [
            -111.11042,
            43.69231,
            0
          ],
          [
            -111.11044,
            43.69408,
            0
          ],
          [
            -111.1104,
            43.70179,
            0
          ],
          [
            -111.11041,
            43.70219,
            0
          ],
          [
            -111.11038,
            43.70677,
            0
          ],
          [
            -111.11039,
            43.70702,
            0
          ],
          [
            -111.11043,
            43.70754,
            0
          ],
          [
            -111.11052,
            43.70812,
            0
          ],
          [
            -111.11061,
            43.70848,
            0
          ],
          [
            -111.11104,
            43.71043,
            0
          ],
          [
            -111.11108,
            43.71077,
            0
          ],
          [
            -111.11108,
            43.71095,
            0
          ],
          [
            -111.1111,
            43.71134,
            0
          ],
          [
            -111.1111,
            43.71532,
            0
          ],
          [
            -111.11108,
            43.71646,
            0
          ],
          [
            -111.11109,
            43.71674,
            0
          ],
          [
            -111.11106,
            43.71892,
            0
          ],
          [
            -111.11106,
            43.72126,
            0
          ],
          [
            -111.11105,
            43.72165,
            0
          ],
          [
            -111.11105,
            43.72245,
            0
          ],
          [
            -111.11107,
            43.72292,
            0
          ],
          [
            -111.11102,
            43.7273,
            0
          ],
          [
            -111.11101,
            43.73376,
            0
          ],
          [
            -111.11102,
            43.73393,
            0
          ],
          [
            -111.111,
            43.73617,
            0
          ],
          [
            -111.11102,
            43.7377,
            0
          ],
          [
            -111.111,
            43.73853,
            0
          ],
          [
            -111.11101,
            43.74225,
            0
          ],
          [
            -111.111,
            43.7432,
            0
          ],
          [
            -111.11101,
            43.74343,
            0
          ],
          [
            -111.11099,
            43.74692,
            0
          ],
          [
            -111.11098,
            43.74717,
            0
          ],
          [
            -111.11098,
            43.7477,
            0
          ],
          [
            -111.11097,
            43.74812,
            0
          ],
          [
            -111.11098,
            43.74843,
            0
          ],
          [
            -111.11097,
            43.74902,
            0
          ],
          [
            -111.11095,
            43.74945,
            0
          ],
          [
            -111.11091,
            43.7564,
            0
          ],
          [
            -111.11085,
            43.75884,
            0
          ],
          [
            -111.11086,
            43.76526,
            0
          ],
          [
            -111.11085,
            43.76582,
            0
          ],
          [
            -111.11085,
            43.77,
            0
          ],
          [
            -111.11084,
            43.77026,
            0
          ],
          [
            -111.11085,
            43.7712,
            0
          ],
          [
            -111.11083,
            43.77524,
            0
          ],
          [
            -111.11084,
            43.77557,
            0
          ],
          [
            -111.11082,
            43.77875,
            0
          ],
          [
            -111.11081,
            43.77887,
            0
          ],
          [
            -111.11078,
            43.77894,
            0
          ],
          [
            -111.11076,
            43.78118,
            0
          ],
          [
            -111.1108,
            43.78298,
            0
          ],
          [
            -111.11082,
            43.78347,
            0
          ],
          [
            -111.11085,
            43.78357,
            0
          ],
          [
            -111.111,
            43.79343,
            0
          ],
          [
            -111.11103,
            43.80004,
            0
          ],
          [
            -111.11102,
            43.80015,
            0
          ],
          [
            -111.11102,
            43.80181,
            0
          ],
          [
            -111.111,
            43.80307,
            0
          ],
          [
            -111.11101,
            43.80317,
            0
          ],
          [
            -111.11105,
            43.8034,
            0
          ],
          [
            -111.11111,
            43.80366,
            0
          ],
          [
            -111.11118,
            43.80387,
            0
          ],
          [
            -111.11124,
            43.80402,
            0
          ],
          [
            -111.11136,
            43.80426,
            0
          ],
          [
            -111.11154,
            43.80454,
            0
          ],
          [
            -111.11162,
            43.80465,
            0
          ],
          [
            -111.11174,
            43.8048,
            0
          ],
          [
            -111.11193,
            43.805,
            0
          ],
          [
            -111.11219,
            43.80523,
            0
          ],
          [
            -111.11248,
            43.80546,
            0
          ],
          [
            -111.11287,
            43.8057,
            0
          ],
          [
            -111.11321,
            43.80587,
            0
          ],
          [
            -111.11348,
            43.80599,
            0
          ],
          [
            -111.11388,
            43.80613,
            0
          ],
          [
            -111.11426,
            43.80624,
            0
          ],
          [
            -111.1149,
            43.80637,
            0
          ],
          [
            -111.11535,
            43.80641,
            0
          ],
          [
            -111.1158,
            43.80643,
            0
          ],
          [
            -111.11638,
            43.80644,
            0
          ],
          [
            -111.11974,
            43.80644,
            0
          ],
          [
            -111.12008,
            43.80645,
            0
          ],
          [
            -111.13867,
            43.80651,
            0
          ],
          [
            -111.13912,
            43.80653,
            0
          ],
          [
            -111.13945,
            43.80656,
            0
          ],
          [
            -111.13981,
            43.80661,
            0
          ],
          [
            -111.1402,
            43.80669,
            0
          ],
          [
            -111.14054,
            43.80678,
            0
          ],
          [
            -111.14068,
            43.80683,
            0
          ],
          [
            -111.14082,
            43.80686,
            0
          ],
          [
            -111.1411,
            43.80696,
            0
          ],
          [
            -111.14147,
            43.80712,
            0
          ],
          [
            -111.14174,
            43.80725,
            0
          ],
          [
            -111.14199,
            43.80739,
            0
          ],
          [
            -111.14261,
            43.8078,
            0
          ],
          [
            -111.14395,
            43.8088,
            0
          ],
          [
            -111.14444,
            43.8091,
            0
          ],
          [
            -111.14474,
            43.80926,
            0
          ],
          [
            -111.14506,
            43.8094,
            0
          ],
          [
            -111.14541,
            43.80953,
            0
          ],
          [
            -111.14576,
            43.80964,
            0
          ],
          [
            -111.14612,
            43.80973,
            0
          ],
          [
            -111.14647,
            43.8098,
            0
          ],
          [
            -111.14683,
            43.80986,
            0
          ],
          [
            -111.14739,
            43.80991,
            0
          ],
          [
            -111.15002,
            43.81009,
            0
          ],
          [
            -111.15059,
            43.81014,
            0
          ],
          [
            -111.1566,
            43.81057,
            0
          ],
          [
            -111.15723,
            43.81063,
            0
          ],
          [
            -111.15756,
            43.81068,
            0
          ],
          [
            -111.15794,
            43.81077,
            0
          ],
          [
            -111.15811,
            43.81082,
            0
          ],
          [
            -111.15833,
            43.8109,
            0
          ],
          [
            -111.15869,
            43.81108,
            0
          ],
          [
            -111.15876,
            43.81111,
            0
          ],
          [
            -111.15902,
            43.81128,
            0
          ],
          [
            -111.1595,
            43.81166,
            0
          ],
          [
            -111.1598,
            43.81197,
            0
          ],
          [
            -111.15994,
            43.81219,
            0
          ],
          [
            -111.16008,
            43.81248,
            0
          ],
          [
            -111.16013,
            43.81261,
            0
          ],
          [
            -111.16019,
            43.81283,
            0
          ],
          [
            -111.16022,
            43.81307,
            0
          ],
          [
            -111.16023,
            43.81335,
            0
          ],
          [
            -111.16021,
            43.81649,
            0
          ],
          [
            -111.16018,
            43.81765,
            0
          ],
          [
            -111.16018,
            43.81795,
            0
          ],
          [
            -111.16019,
            43.81819,
            0
          ],
          [
            -111.16017,
            43.82127,
            0
          ],
          [
            -111.16018,
            43.82156,
            0
          ],
          [
            -111.16018,
            43.82184,
            0
          ],
          [
            -111.16021,
            43.8221,
            0
          ],
          [
            -111.16031,
            43.82251,
            0
          ],
          [
            -111.16036,
            43.82265,
            0
          ],
          [
            -111.16043,
            43.82281,
            0
          ],
          [
            -111.16048,
            43.82291,
            0
          ],
          [
            -111.16062,
            43.82316,
            0
          ],
          [
            -111.16078,
            43.82336,
            0
          ],
          [
            -111.1608,
            43.8234,
            0
          ],
          [
            -111.16109,
            43.8237,
            0
          ],
          [
            -111.16127,
            43.82384,
            0
          ],
          [
            -111.16152,
            43.82402,
            0
          ],
          [
            -111.16199,
            43.8243,
            0
          ],
          [
            -111.1623,
            43.82445,
            0
          ],
          [
            -111.16252,
            43.82453,
            0
          ],
          [
            -111.16264,
            43.82458,
            0
          ],
          [
            -111.16321,
            43.82475,
            0
          ],
          [
            -111.16367,
            43.82483,
            0
          ],
          [
            -111.164,
            43.82487,
            0
          ],
          [
            -111.16425,
            43.82489,
            0
          ],
          [
            -111.17907,
            43.82487,
            0
          ],
          [
            -111.17957,
            43.82486,
            0
          ],
          [
            -111.17995,
            43.82487,
            0
          ],
          [
            -111.18059,
            43.82487,
            0
          ],
          [
            -111.18059,
            43.82694,
            0
          ],
          [
            -111.18058,
            43.82731,
            0
          ],
          [
            -111.18058,
            43.82797,
            0
          ],
          [
            -111.18057,
            43.82839,
            0
          ],
          [
            -111.18053,
            43.83678,
            0
          ],
          [
            -111.18052,
            43.83708,
            0
          ],
          [
            -111.18037,
            43.86087,
            0
          ],
          [
            -111.18034,
            43.86279,
            0
          ],
          [
            -111.18003,
            43.8728,
            0
          ],
          [
            -111.18002,
            43.87382,
            0
          ],
          [
            -111.17999,
            43.87469,
            0
          ],
          [
            -111.17998,
            43.876,
            0
          ],
          [
            -111.17996,
            43.8767,
            0
          ],
          [
            -111.1799,
            43.88172,
            0
          ],
          [
            -111.17989,
            43.8819,
            0
          ],
          [
            -111.17989,
            43.88376,
            0
          ],
          [
            -111.17981,
            43.89091,
            0
          ],
          [
            -111.1798,
            43.89109,
            0
          ],
          [
            -111.1798,
            43.89145,
            0
          ],
          [
            -111.17979,
            43.89163,
            0
          ],
          [
            -111.17978,
            43.89268,
            0
          ],
          [
            -111.17977,
            43.89286,
            0
          ],
          [
            -111.17979,
            43.8943,
            0
          ],
          [
            -111.17996,
            43.89756,
            0
          ],
          [
            -111.17997,
            43.8976,
            0
          ],
          [
            -111.17998,
            43.89792,
            0
          ],
          [
            -111.17998,
            43.89828,
            0
          ],
          [
            -111.17999,
            43.89846,
            0
          ],
          [
            -111.17999,
            43.89864,
            0
          ],
          [
            -111.17998,
            43.89882,
            0
          ],
          [
            -111.17997,
            43.89936,
            0
          ],
          [
            -111.17992,
            43.90068,
            0
          ],
          [
            -111.17991,
            43.9008,
            0
          ],
          [
            -111.17988,
            43.90197,
            0
          ],
          [
            -111.17987,
            43.90206,
            0
          ],
          [
            -111.17987,
            43.90242,
            0
          ],
          [
            -111.17982,
            43.90394,
            0
          ],
          [
            -111.17983,
            43.90441,
            0
          ],
          [
            -111.17982,
            43.90512,
            0
          ],
          [
            -111.17984,
            43.90601,
            0
          ],
          [
            -111.17984,
            43.90677,
            0
          ],
          [
            -111.17985,
            43.90713,
            0
          ],
          [
            -111.17985,
            43.90774,
            0
          ],
          [
            -111.17986,
            43.90793,
            0
          ],
          [
            -111.17986,
            43.909,
            0
          ],
          [
            -111.17987,
            43.90918,
            0
          ],
          [
            -111.17987,
            43.91044,
            0
          ],
          [
            -111.17989,
            43.91112,
            0
          ],
          [
            -111.17988,
            43.91139,
            0
          ],
          [
            -111.1799,
            43.91465,
            0
          ],
          [
            -111.17991,
            43.91486,
            0
          ],
          [
            -111.17991,
            43.91604,
            0
          ],
          [
            -111.17992,
            43.91628,
            0
          ],
          [
            -111.17992,
            43.91752,
            0
          ],
          [
            -111.17993,
            43.91764,
            0
          ],
          [
            -111.17993,
            43.91832,
            0
          ],
          [
            -111.17994,
            43.9186,
            0
          ],
          [
            -111.17995,
            43.92076,
            0
          ],
          [
            -111.17996,
            43.92105,
            0
          ],
          [
            -111.17996,
            43.92142,
            0
          ],
          [
            -111.17993,
            43.92237,
            0
          ],
          [
            -111.17978,
            43.92307,
            0
          ],
          [
            -111.17972,
            43.92324,
            0
          ],
          [
            -111.17967,
            43.92343,
            0
          ],
          [
            -111.17943,
            43.92409,
            0
          ],
          [
            -111.17931,
            43.92428,
            0
          ],
          [
            -111.17922,
            43.9245,
            0
          ],
          [
            -111.17901,
            43.92515,
            0
          ],
          [
            -111.17874,
            43.92584,
            0
          ],
          [
            -111.17865,
            43.92614,
            0
          ],
          [
            -111.1784,
            43.9267,
            0
          ],
          [
            -111.17835,
            43.92685,
            0
          ],
          [
            -111.17834,
            43.92687,
            0
          ],
          [
            -111.17814,
            43.92737,
            0
          ],
          [
            -111.17813,
            43.92739,
            0
          ],
          [
            -111.17803,
            43.92765,
            0
          ],
          [
            -111.17799,
            43.92774,
            0
          ],
          [
            -111.17787,
            43.92805,
            0
          ],
          [
            -111.17786,
            43.92809,
            0
          ],
          [
            -111.17752,
            43.92895,
            0
          ],
          [
            -111.17731,
            43.92961,
            0
          ],
          [
            -111.17722,
            43.93,
            0
          ],
          [
            -111.17722,
            43.93001,
            0
          ],
          [
            -111.17714,
            43.93039,
            0
          ],
          [
            -111.17706,
            43.93105,
            0
          ],
          [
            -111.17705,
            43.93126,
            0
          ],
          [
            -111.17706,
            43.93185,
            0
          ],
          [
            -111.1772,
            43.93333,
            0
          ],
          [
            -111.17728,
            43.93386,
            0
          ],
          [
            -111.17728,
            43.93388,
            0
          ],
          [
            -111.17738,
            43.9344,
            0
          ],
          [
            -111.17752,
            43.93497,
            0
          ],
          [
            -111.17762,
            43.93531,
            0
          ],
          [
            -111.17775,
            43.93567,
            0
          ],
          [
            -111.1783,
            43.93737,
            0
          ],
          [
            -111.17831,
            43.93742,
            0
          ],
          [
            -111.17842,
            43.93778,
            0
          ],
          [
            -111.17864,
            43.93843,
            0
          ],
          [
            -111.17881,
            43.93908,
            0
          ],
          [
            -111.17904,
            43.93966,
            0
          ],
          [
            -111.17914,
            43.94,
            0
          ],
          [
            -111.17979,
            43.94193,
            0
          ],
          [
            -111.17987,
            43.94219,
            0
          ],
          [
            -111.17996,
            43.94256,
            0
          ],
          [
            -111.18002,
            43.94294,
            0
          ],
          [
            -111.18003,
            43.9431,
            0
          ],
          [
            -111.18003,
            43.95068,
            0
          ],
          [
            -111.18002,
            43.95129,
            0
          ],
          [
            -111.18004,
            43.95173,
            0
          ],
          [
            -111.18007,
            43.95197,
            0
          ],
          [
            -111.18022,
            43.9524,
            0
          ],
          [
            -111.18027,
            43.9525,
            0
          ],
          [
            -111.18032,
            43.95258,
            0
          ],
          [
            -111.1805,
            43.95284,
            0
          ],
          [
            -111.18057,
            43.95293,
            0
          ],
          [
            -111.18072,
            43.95309,
            0
          ],
          [
            -111.18098,
            43.95332,
            0
          ],
          [
            -111.1811,
            43.95341,
            0
          ],
          [
            -111.18125,
            43.95351,
            0
          ],
          [
            -111.1814,
            43.9536,
            0
          ],
          [
            -111.18144,
            43.95362,
            0
          ],
          [
            -111.18152,
            43.95367,
            0
          ],
          [
            -111.18164,
            43.95373,
            0
          ],
          [
            -111.18167,
            43.95374,
            0
          ],
          [
            -111.18189,
            43.95384,
            0
          ],
          [
            -111.18214,
            43.95393,
            0
          ],
          [
            -111.18243,
            43.95402,
            0
          ],
          [
            -111.18255,
            43.95405,
            0
          ],
          [
            -111.1829,
            43.95411,
            0
          ],
          [
            -111.18326,
            43.95415,
            0
          ],
          [
            -111.18355,
            43.95417,
            0
          ],
          [
            -111.19051,
            43.95414,
            0
          ],
          [
            -111.1909,
            43.95413,
            0
          ],
          [
            -111.19116,
            43.95411,
            0
          ],
          [
            -111.19164,
            43.95405,
            0
          ],
          [
            -111.19186,
            43.954,
            0
          ],
          [
            -111.1921,
            43.95393,
            0
          ],
          [
            -111.19231,
            43.95386,
            0
          ],
          [
            -111.19264,
            43.95373,
            0
          ],
          [
            -111.19265,
            43.95373,
            0
          ],
          [
            -111.19309,
            43.95357,
            0
          ],
          [
            -111.19324,
            43.95353,
            0
          ],
          [
            -111.19364,
            43.95344,
            0
          ],
          [
            -111.19381,
            43.95341,
            0
          ],
          [
            -111.19432,
            43.95337,
            0
          ],
          [
            -111.19485,
            43.95338,
            0
          ],
          [
            -111.19522,
            43.95342,
            0
          ],
          [
            -111.19564,
            43.9535,
            0
          ],
          [
            -111.19692,
            43.95379,
            0
          ],
          [
            -111.19727,
            43.95389,
            0
          ],
          [
            -111.19763,
            43.95403,
            0
          ],
          [
            -111.19786,
            43.95413,
            0
          ],
          [
            -111.19801,
            43.95422,
            0
          ],
          [
            -111.19818,
            43.95431,
            0
          ],
          [
            -111.19843,
            43.95447,
            0
          ],
          [
            -111.1986,
            43.95459,
            0
          ],
          [
            -111.19883,
            43.95479,
            0
          ],
          [
            -111.19899,
            43.95495,
            0
          ],
          [
            -111.19928,
            43.95528,
            0
          ],
          [
            -111.19937,
            43.95542,
            0
          ],
          [
            -111.19951,
            43.95569,
            0
          ],
          [
            -111.19958,
            43.95585,
            0
          ],
          [
            -111.19966,
            43.95607,
            0
          ],
          [
            -111.19972,
            43.95635,
            0
          ],
          [
            -111.19982,
            43.95717,
            0
          ],
          [
            -111.19988,
            43.95747,
            0
          ],
          [
            -111.19991,
            43.95758,
            0
          ],
          [
            -111.20003,
            43.95788,
            0
          ],
          [
            -111.20015,
            43.95809,
            0
          ],
          [
            -111.20022,
            43.9582,
            0
          ],
          [
            -111.20055,
            43.95859,
            0
          ],
          [
            -111.20075,
            43.95879,
            0
          ],
          [
            -111.20315,
            43.96134,
            0
          ],
          [
            -111.20322,
            43.96144,
            0
          ],
          [
            -111.20332,
            43.96157,
            0
          ],
          [
            -111.20334,
            43.96159,
            0
          ],
          [
            -111.20341,
            43.9617,
            0
          ],
          [
            -111.20348,
            43.96183,
            0
          ],
          [
            -111.20353,
            43.96194,
            0
          ],
          [
            -111.20358,
            43.96207,
            0
          ],
          [
            -111.20362,
            43.96223,
            0
          ],
          [
            -111.20363,
            43.96235,
            0
          ],
          [
            -111.20363,
            43.9627,
            0
          ],
          [
            -111.20362,
            43.96283,
            0
          ],
          [
            -111.20362,
            43.96297,
            0
          ],
          [
            -111.20358,
            43.96337,
            0
          ],
          [
            -111.20357,
            43.96343,
            0
          ],
          [
            -111.20354,
            43.96386,
            0
          ],
          [
            -111.20344,
            43.9648,
            0
          ],
          [
            -111.20344,
            43.96487,
            0
          ],
          [
            -111.20328,
            43.96653,
            0
          ],
          [
            -111.20327,
            43.9666,
            0
          ],
          [
            -111.20326,
            43.96685,
            0
          ],
          [
            -111.20326,
            43.96701,
            0
          ],
          [
            -111.20327,
            43.96714,
            0
          ],
          [
            -111.20327,
            43.96718,
            0
          ],
          [
            -111.2033,
            43.96733,
            0
          ],
          [
            -111.20338,
            43.96761,
            0
          ],
          [
            -111.20344,
            43.96772,
            0
          ],
          [
            -111.2035,
            43.96785,
            0
          ],
          [
            -111.20368,
            43.96814,
            0
          ],
          [
            -111.20374,
            43.96821,
            0
          ],
          [
            -111.20383,
            43.96834,
            0
          ],
          [
            -111.20396,
            43.96847,
            0
          ],
          [
            -111.20415,
            43.96864,
            0
          ],
          [
            -111.20435,
            43.9688,
            0
          ],
          [
            -111.20446,
            43.96887,
            0
          ],
          [
            -111.2045,
            43.9689,
            0
          ],
          [
            -111.20465,
            43.96899,
            0
          ],
          [
            -111.20473,
            43.96903,
            0
          ],
          [
            -111.20481,
            43.96908,
            0
          ],
          [
            -111.20485,
            43.96909,
            0
          ],
          [
            -111.20497,
            43.96916,
            0
          ],
          [
            -111.20532,
            43.96929,
            0
          ],
          [
            -111.2056,
            43.96938,
            0
          ],
          [
            -111.20574,
            43.96941,
            0
          ],
          [
            -111.20576,
            43.96942,
            0
          ],
          [
            -111.20589,
            43.96945,
            0
          ],
          [
            -111.20607,
            43.96948,
            0
          ],
          [
            -111.20657,
            43.96954,
            0
          ],
          [
            -111.20676,
            43.96955,
            0
          ],
          [
            -111.20695,
            43.96955,
            0
          ],
          [
            -111.20722,
            43.96953,
            0
          ],
          [
            -111.20731,
            43.96953,
            0
          ],
          [
            -111.20758,
            43.9695,
            0
          ],
          [
            -111.20796,
            43.96944,
            0
          ],
          [
            -111.2082,
            43.96941,
            0
          ],
          [
            -111.20845,
            43.96937,
            0
          ],
          [
            -111.20889,
            43.96932,
            0
          ],
          [
            -111.20909,
            43.96929,
            0
          ],
          [
            -111.20917,
            43.96929,
            0
          ],
          [
            -111.20919,
            43.96928,
            0
          ],
          [
            -111.20939,
            43.96927,
            0
          ],
          [
            -111.20943,
            43.96926,
            0
          ],
          [
            -111.20966,
            43.96925,
            0
          ],
          [
            -111.21007,
            43.96925,
            0
          ],
          [
            -111.21033,
            43.96926,
            0
          ],
          [
            -111.21087,
            43.96931,
            0
          ],
          [
            -111.21193,
            43.96951,
            0
          ],
          [
            -111.21237,
            43.96961,
            0
          ],
          [
            -111.21301,
            43.96973,
            0
          ],
          [
            -111.2135,
            43.96978,
            0
          ],
          [
            -111.21381,
            43.96979,
            0
          ],
          [
            -111.21408,
            43.96977,
            0
          ],
          [
            -111.21432,
            43.96976,
            0
          ],
          [
            -111.21464,
            43.96972,
            0
          ],
          [
            -111.21514,
            43.96963,
            0
          ],
          [
            -111.21626,
            43.96946,
            0
          ],
          [
            -111.21657,
            43.96943,
            0
          ],
          [
            -111.21682,
            43.96942,
            0
          ],
          [
            -111.21729,
            43.96942,
            0
          ],
          [
            -111.22367,
            43.9697,
            0
          ],
          [
            -111.22386,
            43.9697,
            0
          ],
          [
            -111.22392,
            43.96971,
            0
          ],
          [
            -111.22613,
            43.9698,
            0
          ],
          [
            -111.22617,
            43.96979,
            0
          ],
          [
            -111.22667,
            43.96979,
            0
          ],
          [
            -111.22719,
            43.96977,
            0
          ],
          [
            -111.22791,
            43.96971,
            0
          ],
          [
            -111.22814,
            43.96968,
            0
          ],
          [
            -111.22816,
            43.96968,
            0
          ],
          [
            -111.2284,
            43.96964,
            0
          ],
          [
            -111.22852,
            43.96963,
            0
          ],
          [
            -111.22893,
            43.96956,
            0
          ],
          [
            -111.22985,
            43.96937,
            0
          ],
          [
            -111.23014,
            43.9693,
            0
          ],
          [
            -111.23344,
            43.9686,
            0
          ],
          [
            -111.23345,
            43.96859,
            0
          ],
          [
            -111.23489,
            43.96831,
            0
          ],
          [
            -111.23538,
            43.96824,
            0
          ],
          [
            -111.23587,
            43.96819,
            0
          ],
          [
            -111.23687,
            43.96814,
            0
          ],
          [
            -111.2372,
            43.96814,
            0
          ],
          [
            -111.23745,
            43.96815,
            0
          ],
          [
            -111.23771,
            43.96815,
            0
          ],
          [
            -111.23828,
            43.96818,
            0
          ],
          [
            -111.24131,
            43.96849,
            0
          ],
          [
            -111.2419,
            43.96854,
            0
          ],
          [
            -111.24283,
            43.96864,
            0
          ],
          [
            -111.24308,
            43.96866,
            0
          ],
          [
            -111.24331,
            43.96869,
            0
          ],
          [
            -111.24431,
            43.96879,
            0
          ],
          [
            -111.24434,
            43.96879,
            0
          ],
          [
            -111.24667,
            43.96903,
            0
          ],
          [
            -111.26855,
            43.97121,
            0
          ],
          [
            -111.26888,
            43.97125,
            0
          ],
          [
            -111.27149,
            43.97151,
            0
          ],
          [
            -111.27173,
            43.97154,
            0
          ],
          [
            -111.27226,
            43.97164,
            0
          ],
          [
            -111.27634,
            43.97261,
            0
          ],
          [
            -111.27672,
            43.97269,
            0
          ],
          [
            -111.2781,
            43.97303,
            0
          ],
          [
            -111.27861,
            43.97314,
            0
          ],
          [
            -111.27884,
            43.97317,
            0
          ],
          [
            -111.27907,
            43.97319,
            0
          ],
          [
            -111.27928,
            43.9732,
            0
          ],
          [
            -111.27956,
            43.9732,
            0
          ],
          [
            -111.27976,
            43.97319,
            0
          ],
          [
            -111.28027,
            43.97313,
            0
          ],
          [
            -111.28062,
            43.97306,
            0
          ],
          [
            -111.28108,
            43.97292,
            0
          ],
          [
            -111.28128,
            43.97284,
            0
          ],
          [
            -111.28148,
            43.97275,
            0
          ],
          [
            -111.28164,
            43.97267,
            0
          ],
          [
            -111.28195,
            43.97248,
            0
          ],
          [
            -111.28477,
            43.97054,
            0
          ],
          [
            -111.28481,
            43.97052,
            0
          ],
          [
            -111.28585,
            43.96979,
            0
          ],
          [
            -111.28652,
            43.96934,
            0
          ],
          [
            -111.28667,
            43.96923,
            0
          ],
          [
            -111.287,
            43.96902,
            0
          ],
          [
            -111.28712,
            43.96896,
            0
          ],
          [
            -111.28724,
            43.96889,
            0
          ],
          [
            -111.28731,
            43.96886,
            0
          ],
          [
            -111.28739,
            43.96881,
            0
          ],
          [
            -111.2875,
            43.96876,
            0
          ],
          [
            -111.28787,
            43.96861,
            0
          ],
          [
            -111.28801,
            43.96856,
            0
          ],
          [
            -111.28838,
            43.96846,
            0
          ],
          [
            -111.28852,
            43.96843,
            0
          ],
          [
            -111.28863,
            43.9684,
            0
          ],
          [
            -111.28877,
            43.96837,
            0
          ],
          [
            -111.2889,
            43.96835,
            0
          ],
          [
            -111.28902,
            43.96834,
            0
          ],
          [
            -111.2892,
            43.96831,
            0
          ],
          [
            -111.28944,
            43.96829,
            0
          ],
          [
            -111.28959,
            43.96829,
            0
          ],
          [
            -111.28973,
            43.96828,
            0
          ],
          [
            -111.29005,
            43.96828,
            0
          ],
          [
            -111.2901,
            43.96829,
            0
          ],
          [
            -111.29039,
            43.96831,
            0
          ],
          [
            -111.29072,
            43.96835,
            0
          ],
          [
            -111.29113,
            43.96843,
            0
          ],
          [
            -111.29145,
            43.96852,
            0
          ],
          [
            -111.29154,
            43.96854,
            0
          ],
          [
            -111.29167,
            43.96859,
            0
          ],
          [
            -111.29178,
            43.96862,
            0
          ],
          [
            -111.29184,
            43.96865,
            0
          ],
          [
            -111.29191,
            43.96867,
            0
          ],
          [
            -111.29199,
            43.96871,
            0
          ],
          [
            -111.29222,
            43.96881,
            0
          ],
          [
            -111.29243,
            43.96892,
            0
          ],
          [
            -111.29249,
            43.96896,
            0
          ],
          [
            -111.29256,
            43.969,
            0
          ],
          [
            -111.29281,
            43.96916,
            0
          ],
          [
            -111.29302,
            43.96932,
            0
          ],
          [
            -111.29334,
            43.9696,
            0
          ],
          [
            -111.29344,
            43.96968,
            0
          ],
          [
            -111.29355,
            43.96978,
            0
          ],
          [
            -111.2936,
            43.96982,
            0
          ],
          [
            -111.29387,
            43.97006,
            0
          ],
          [
            -111.29391,
            43.9701,
            0
          ],
          [
            -111.29608,
            43.97206,
            0
          ],
          [
            -111.29623,
            43.97218,
            0
          ],
          [
            -111.29644,
            43.97238,
            0
          ],
          [
            -111.29648,
            43.97241,
            0
          ],
          [
            -111.29704,
            43.97291,
            0
          ],
          [
            -111.29722,
            43.97308,
            0
          ],
          [
            -111.29734,
            43.97318,
            0
          ],
          [
            -111.29766,
            43.97347,
            0
          ],
          [
            -111.29791,
            43.97368,
            0
          ],
          [
            -111.29804,
            43.97378,
            0
          ],
          [
            -111.29818,
            43.9739,
            0
          ],
          [
            -111.29831,
            43.97399,
            0
          ],
          [
            -111.29844,
            43.97409,
            0
          ],
          [
            -111.29857,
            43.97418,
            0
          ],
          [
            -111.29872,
            43.97427,
            0
          ],
          [
            -111.29873,
            43.97428,
            0
          ],
          [
            -111.29884,
            43.97435,
            0
          ],
          [
            -111.29911,
            43.9745,
            0
          ],
          [
            -111.29926,
            43.97457,
            0
          ],
          [
            -111.2994,
            43.97465,
            0
          ],
          [
            -111.29968,
            43.97477,
            0
          ],
          [
            -111.29974,
            43.9748,
            0
          ],
          [
            -111.30006,
            43.97493,
            0
          ],
          [
            -111.30037,
            43.97503,
            0
          ],
          [
            -111.30041,
            43.97505,
            0
          ],
          [
            -111.30066,
            43.97513,
            0
          ],
          [
            -111.31015,
            43.97756,
            0
          ],
          [
            -111.31115,
            43.97779,
            0
          ],
          [
            -111.31131,
            43.97782,
            0
          ],
          [
            -111.31153,
            43.97787,
            0
          ],
          [
            -111.31181,
            43.97792,
            0
          ],
          [
            -111.31182,
            43.97792,
            0
          ],
          [
            -111.31206,
            43.97797,
            0
          ],
          [
            -111.31229,
            43.97801,
            0
          ],
          [
            -111.31232,
            43.97801,
            0
          ],
          [
            -111.31299,
            43.97812,
            0
          ],
          [
            -111.31327,
            43.97815,
            0
          ],
          [
            -111.31344,
            43.97818,
            0
          ],
          [
            -111.31352,
            43.97819,
            0
          ],
          [
            -111.31358,
            43.97819,
            0
          ],
          [
            -111.31418,
            43.97827,
            0
          ],
          [
            -111.31595,
            43.97847,
            0
          ],
          [
            -111.31599,
            43.97848,
            0
          ],
          [
            -111.31642,
            43.97854,
            0
          ],
          [
            -111.3166,
            43.97858,
            0
          ],
          [
            -111.31687,
            43.97865,
            0
          ],
          [
            -111.31714,
            43.97873,
            0
          ],
          [
            -111.31726,
            43.97878,
            0
          ],
          [
            -111.31736,
            43.97881,
            0
          ],
          [
            -111.31744,
            43.97885,
            0
          ],
          [
            -111.31752,
            43.97888,
            0
          ],
          [
            -111.31777,
            43.97899,
            0
          ],
          [
            -111.3181,
            43.97918,
            0
          ],
          [
            -111.31829,
            43.9793,
            0
          ],
          [
            -111.31839,
            43.97937,
            0
          ],
          [
            -111.31841,
            43.97939,
            0
          ],
          [
            -111.31852,
            43.97947,
            0
          ],
          [
            -111.31861,
            43.97955,
            0
          ],
          [
            -111.3187,
            43.97962,
            0
          ],
          [
            -111.319,
            43.97988,
            0
          ],
          [
            -111.31919,
            43.98006,
            0
          ],
          [
            -111.3209,
            43.98159,
            0
          ],
          [
            -111.32092,
            43.9816,
            0
          ],
          [
            -111.32264,
            43.98314,
            0
          ],
          [
            -111.32315,
            43.98357,
            0
          ],
          [
            -111.32324,
            43.98363,
            0
          ],
          [
            -111.32345,
            43.98379,
            0
          ],
          [
            -111.3237,
            43.98395,
            0
          ],
          [
            -111.324,
            43.98413,
            0
          ],
          [
            -111.3245,
            43.98439,
            0
          ],
          [
            -111.32471,
            43.98448,
            0
          ],
          [
            -111.32507,
            43.98466,
            0
          ],
          [
            -111.32556,
            43.98488,
            0
          ],
          [
            -111.32585,
            43.98502,
            0
          ],
          [
            -111.32598,
            43.98507,
            0
          ],
          [
            -111.32787,
            43.98595,
            0
          ],
          [
            -111.32808,
            43.98604,
            0
          ],
          [
            -111.32811,
            43.98605,
            0
          ],
          [
            -111.32871,
            43.98634,
            0
          ],
          [
            -111.32913,
            43.98653,
            0
          ],
          [
            -111.32916,
            43.98654,
            0
          ],
          [
            -111.32955,
            43.98673,
            0
          ],
          [
            -111.32976,
            43.98682,
            0
          ],
          [
            -111.33039,
            43.98712,
            0
          ],
          [
            -111.33095,
            43.98737,
            0
          ],
          [
            -111.33135,
            43.98756,
            0
          ],
          [
            -111.33164,
            43.98771,
            0
          ],
          [
            -111.33169,
            43.98774,
            0
          ],
          [
            -111.33185,
            43.98782,
            0
          ],
          [
            -111.33195,
            43.98788,
            0
          ],
          [
            -111.33205,
            43.98793,
            0
          ],
          [
            -111.33224,
            43.98804,
            0
          ],
          [
            -111.33234,
            43.98809,
            0
          ],
          [
            -111.33244,
            43.98815,
            0
          ],
          [
            -111.33255,
            43.9882,
            0
          ],
          [
            -111.33264,
            43.98826,
            0
          ],
          [
            -111.33277,
            43.98833,
            0
          ],
          [
            -111.33351,
            43.98879,
            0
          ],
          [
            -111.33422,
            43.98928,
            0
          ],
          [
            -111.33462,
            43.98957,
            0
          ],
          [
            -111.33513,
            43.98997,
            0
          ],
          [
            -111.33517,
            43.98999,
            0
          ],
          [
            -111.33573,
            43.99046,
            0
          ],
          [
            -111.33592,
            43.99063,
            0
          ],
          [
            -111.33597,
            43.99068,
            0
          ],
          [
            -111.33613,
            43.99082,
            0
          ],
          [
            -111.33621,
            43.9909,
            0
          ],
          [
            -111.33628,
            43.99096,
            0
          ],
          [
            -111.33643,
            43.99111,
            0
          ],
          [
            -111.33649,
            43.99116,
            0
          ],
          [
            -111.33687,
            43.99155,
            0
          ],
          [
            -111.33727,
            43.99198,
            0
          ],
          [
            -111.33758,
            43.99233,
            0
          ],
          [
            -111.33806,
            43.99293,
            0
          ],
          [
            -111.33863,
            43.99372,
            0
          ],
          [
            -111.3387,
            43.99385,
            0
          ],
          [
            -111.33912,
            43.9945,
            0
          ],
          [
            -111.33922,
            43.99464,
            0
          ],
          [
            -111.33947,
            43.99502,
            0
          ],
          [
            -111.33973,
            43.99537,
            0
          ],
          [
            -111.34014,
            43.99583,
            0
          ],
          [
            -111.34052,
            43.99618,
            0
          ],
          [
            -111.34073,
            43.99636,
            0
          ],
          [
            -111.34114,
            43.99667,
            0
          ],
          [
            -111.34127,
            43.99675,
            0
          ],
          [
            -111.34156,
            43.99695,
            0
          ],
          [
            -111.34184,
            43.99711,
            0
          ],
          [
            -111.34195,
            43.99718,
            0
          ],
          [
            -111.34257,
            43.99749,
            0
          ],
          [
            -111.34276,
            43.99757,
            0
          ],
          [
            -111.34293,
            43.99765,
            0
          ],
          [
            -111.34317,
            43.99774,
            0
          ],
          [
            -111.34321,
            43.99776,
            0
          ],
          [
            -111.34378,
            43.99796,
            0
          ],
          [
            -111.34433,
            43.99811,
            0
          ],
          [
            -111.34471,
            43.99819,
            0
          ],
          [
            -111.34472,
            43.9982,
            0
          ],
          [
            -111.3449,
            43.99823,
            0
          ],
          [
            -111.34545,
            43.99834,
            0
          ],
          [
            -111.34613,
            43.99843,
            0
          ],
          [
            -111.34642,
            43.99845,
            0
          ],
          [
            -111.34698,
            43.99847,
            0
          ],
          [
            -111.36722,
            43.99848,
            0
          ],
          [
            -111.36747,
            43.99847,
            0
          ],
          [
            -111.37574,
            43.99847,
            0
          ],
          [
            -111.37631,
            43.99846,
            0
          ],
          [
            -111.38527,
            43.99848,
            0
          ],
          [
            -111.38552,
            43.99849,
            0
          ],
          [
            -111.38827,
            43.9985,
            0
          ],
          [
            -111.38852,
            43.99851,
            0
          ],
          [
            -111.38977,
            43.99851,
            0
          ],
          [
            -111.39002,
            43.99852,
            0
          ],
          [
            -111.39467,
            43.99854,
            0
          ],
          [
            -111.39477,
            43.99855,
            0
          ],
          [
            -111.39702,
            43.99856,
            0
          ],
          [
            -111.39742,
            43.99857,
            0
          ],
          [
            -111.40152,
            43.99859,
            0
          ],
          [
            -111.40177,
            43.9986,
            0
          ],
          [
            -111.40498,
            43.99861,
            0
          ],
          [
            -111.40518,
            43.99862,
            0
          ],
          [
            -111.4069,
            43.99863,
            0
          ],
          [
            -111.40708,
            43.99862,
            0
          ],
          [
            -111.40753,
            43.99863,
            0
          ],
          [
            -111.40831,
            43.99863,
            0
          ],
          [
            -111.40865,
            43.99864,
            0
          ],
          [
            -111.4099,
            43.99864,
            0
          ],
          [
            -111.41015,
            43.99865,
            0
          ],
          [
            -111.42001,
            43.99872,
            0
          ],
          [
            -111.42076,
            43.99875,
            0
          ],
          [
            -111.42102,
            43.99877,
            0
          ],
          [
            -111.42162,
            43.99888,
            0
          ],
          [
            -111.42185,
            43.99894,
            0
          ],
          [
            -111.42209,
            43.99902,
            0
          ],
          [
            -111.42246,
            43.99918,
            0
          ],
          [
            -111.42277,
            43.99934,
            0
          ],
          [
            -111.42299,
            43.99948,
            0
          ],
          [
            -111.42321,
            43.99966,
            0
          ],
          [
            -111.42335,
            43.99979,
            0
          ],
          [
            -111.42351,
            43.99995,
            0
          ],
          [
            -111.4236,
            44.00006,
            0
          ],
          [
            -111.42372,
            44.00024,
            0
          ],
          [
            -111.42378,
            44.00034,
            0
          ],
          [
            -111.42388,
            44.00054,
            0
          ],
          [
            -111.42394,
            44.00068,
            0
          ],
          [
            -111.42399,
            44.00086,
            0
          ],
          [
            -111.42401,
            44.00098,
            0
          ],
          [
            -111.42403,
            44.00106,
            0
          ],
          [
            -111.42404,
            44.00122,
            0
          ],
          [
            -111.42405,
            44.00124,
            0
          ],
          [
            -111.42406,
            44.0014,
            0
          ],
          [
            -111.42407,
            44.00396,
            0
          ],
          [
            -111.42409,
            44.00499,
            0
          ],
          [
            -111.4241,
            44.00508,
            0
          ],
          [
            -111.4241,
            44.00603,
            0
          ],
          [
            -111.42411,
            44.00625,
            0
          ],
          [
            -111.42411,
            44.00661,
            0
          ],
          [
            -111.42412,
            44.00679,
            0
          ],
          [
            -111.42414,
            44.00855,
            0
          ],
          [
            -111.42415,
            44.00873,
            0
          ],
          [
            -111.42417,
            44.01006,
            0
          ],
          [
            -111.42419,
            44.01047,
            0
          ],
          [
            -111.42417,
            44.01312,
            0
          ],
          [
            -111.4242,
            44.0155,
            0
          ],
          [
            -111.42421,
            44.01566,
            0
          ],
          [
            -111.42429,
            44.01609,
            0
          ],
          [
            -111.42439,
            44.0164,
            0
          ],
          [
            -111.42466,
            44.01693,
            0
          ],
          [
            -111.42591,
            44.01904,
            0
          ],
          [
            -111.42618,
            44.0194,
            0
          ],
          [
            -111.42639,
            44.01965,
            0
          ],
          [
            -111.42666,
            44.01988,
            0
          ],
          [
            -111.42688,
            44.02005,
            0
          ],
          [
            -111.42726,
            44.02029,
            0
          ],
          [
            -111.42745,
            44.0204,
            0
          ],
          [
            -111.42758,
            44.02046,
            0
          ],
          [
            -111.42796,
            44.02061,
            0
          ],
          [
            -111.4285,
            44.02078,
            0
          ],
          [
            -111.42877,
            44.02084,
            0
          ],
          [
            -111.42932,
            44.02094,
            0
          ],
          [
            -111.43075,
            44.02125,
            0
          ],
          [
            -111.43122,
            44.02133,
            0
          ],
          [
            -111.43189,
            44.02138,
            0
          ],
          [
            -111.43255,
            44.02138,
            0
          ],
          [
            -111.43348,
            44.02132,
            0
          ],
          [
            -111.43517,
            44.02117,
            0
          ],
          [
            -111.43565,
            44.02115,
            0
          ],
          [
            -111.43604,
            44.02115,
            0
          ],
          [
            -111.43631,
            44.02117,
            0
          ],
          [
            -111.4368,
            44.02125,
            0
          ],
          [
            -111.43699,
            44.02131,
            0
          ],
          [
            -111.43701,
            44.02131,
            0
          ],
          [
            -111.43718,
            44.02137,
            0
          ],
          [
            -111.43742,
            44.02147,
            0
          ],
          [
            -111.43757,
            44.02156,
            0
          ],
          [
            -111.4377,
            44.02163,
            0
          ],
          [
            -111.4379,
            44.02177,
            0
          ],
          [
            -111.43801,
            44.02186,
            0
          ],
          [
            -111.43807,
            44.02192,
            0
          ],
          [
            -111.43827,
            44.02215,
            0
          ],
          [
            -111.43835,
            44.02225,
            0
          ],
          [
            -111.43839,
            44.02231,
            0
          ],
          [
            -111.43844,
            44.0224,
            0
          ],
          [
            -111.43848,
            44.02246,
            0
          ],
          [
            -111.43852,
            44.02255,
            0
          ],
          [
            -111.43855,
            44.02263,
            0
          ],
          [
            -111.43862,
            44.02288,
            0
          ],
          [
            -111.43864,
            44.02305,
            0
          ],
          [
            -111.43868,
            44.02427,
            0
          ],
          [
            -111.43867,
            44.02724,
            0
          ],
          [
            -111.43865,
            44.02778,
            0
          ],
          [
            -111.43866,
            44.03123,
            0
          ],
          [
            -111.43864,
            44.03256,
            0
          ],
          [
            -111.43865,
            44.03281,
            0
          ],
          [
            -111.43861,
            44.03453,
            0
          ],
          [
            -111.4386,
            44.03471,
            0
          ],
          [
            -111.4386,
            44.03525,
            0
          ],
          [
            -111.43859,
            44.03543,
            0
          ],
          [
            -111.43853,
            44.04109,
            0
          ],
          [
            -111.43852,
            44.04118,
            0
          ],
          [
            -111.43848,
            44.04179,
            0
          ],
          [
            -111.43845,
            44.04274,
            0
          ],
          [
            -111.43843,
            44.0453,
            0
          ],
          [
            -111.43844,
            44.0462,
            0
          ],
          [
            -111.43853,
            44.04805,
            0
          ],
          [
            -111.43848,
            44.05272,
            0
          ],
          [
            -111.43849,
            44.05446,
            0
          ],
          [
            -111.43848,
            44.05474,
            0
          ],
          [
            -111.43845,
            44.05499,
            0
          ],
          [
            -111.43839,
            44.05525,
            0
          ],
          [
            -111.43833,
            44.05546,
            0
          ],
          [
            -111.43817,
            44.05585,
            0
          ],
          [
            -111.43795,
            44.0562,
            0
          ],
          [
            -111.43782,
            44.05637,
            0
          ],
          [
            -111.4375,
            44.05668,
            0
          ],
          [
            -111.43727,
            44.05687,
            0
          ],
          [
            -111.43666,
            44.05734,
            0
          ],
          [
            -111.43645,
            44.05754,
            0
          ],
          [
            -111.43629,
            44.05771,
            0
          ],
          [
            -111.43612,
            44.05794,
            0
          ],
          [
            -111.43596,
            44.0582,
            0
          ],
          [
            -111.43586,
            44.0584,
            0
          ],
          [
            -111.43571,
            44.05885,
            0
          ],
          [
            -111.43567,
            44.0591,
            0
          ],
          [
            -111.43566,
            44.05939,
            0
          ],
          [
            -111.43576,
            44.06988,
            0
          ],
          [
            -111.43579,
            44.07133,
            0
          ],
          [
            -111.43841,
            44.07133,
            0
          ],
          [
            -111.4388,
            44.07134,
            0
          ],
          [
            -111.44027,
            44.07134,
            0
          ],
          [
            -111.44068,
            44.07133,
            0
          ],
          [
            -111.44829,
            44.07135,
            0
          ]
        ],
			},
			id: "86cd5e29-e6eb-4279-b976-7e5546c42965",
		},
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -111.3422531,
    //       43.4545061,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Swan Valley, ID"
    //   },
    //   id: "1503737d-d3d0-4ed2-9e64-16617bf934c8"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -111.1113352,
    //       43.602625,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Victor, ID"
    //   },
    //   id: "bd631c65-4a00-4809-bb59-cdaa5448b835"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -111.1110688,
    //       43.7231216,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Driggs, ID"
    //   },
    //   id: "dfb50f9f-e163-42cb-bbea-bd5f59301d66"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -111.1602235,
    //       43.8144895,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Tetonia, ID"
    //   },
    //   id: "31f8ce23-a5d0-4d4e-91f5-675d58c8cd2f"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -111.448288,
    //       44.0713503,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Ashton, ID"
    //   },
    //   id: "7cc4fda3-b3a4-46f5-88a5-2df37dc95bc3"
    // }
	],
};

export default Teton;
