import { Svg, G, Path } from "@react-pdf/renderer";
import React from "react";

const IconNationalMonumentsHistoricSites = () => {
	return (
		<>
			<Svg width="28" viewBox="0 0 36 37">
				<G>
					<Path fill="#000" d="M24.1,25h-0.3V11.3h0.3c0.7,0,1.3-0.5,1.3-1.1c0-0.6-0.6-1.1-1.3-1.1H12.9c-0.7,0-1.3,0.5-1.3,1.1s0.6,1.1,1.3,1.1h0.3V25
						h-0.3c-0.7,0-1.3,0.4-1.3,1c0,0.6,0.6,1,1.3,1h11.3c0.7,0,1.3-0.4,1.3-1C25.4,25.5,24.8,25,24.1,25z M23.2,25h-0.5V11.3h0.5V25z
						M16.1,11.3V25h-1.2V11.3C14.9,11.3,16.1,11.3,16.1,11.3z M16.7,11.3h0.7V25h-0.7C16.7,25,16.7,11.3,16.7,11.3z M17.9,11.3h1.1V25
						h-1.1C17.9,25,17.9,11.3,17.9,11.3z M19.6,11.3h0.7V25h-0.7C19.6,25,19.6,11.3,19.6,11.3z M20.9,11.3h1.2V25h-1.2
						C20.9,25,20.9,11.3,20.9,11.3z M12.1,10.2c0-0.3,0.3-0.6,0.7-0.6h11.3c0.4,0,0.7,0.3,0.7,0.6s-0.3,0.6-0.7,0.6H12.9
						C12.5,10.7,12.1,10.5,12.1,10.2z M13.8,11.3h0.5V25h-0.5C13.8,25,13.8,11.3,13.8,11.3z M24.1,26.4H12.9c-0.4,0-0.7-0.2-0.7-0.4
						c0-0.2,0.3-0.4,0.7-0.4h11.3c0.4,0,0.7,0.2,0.7,0.4C24.8,26.2,24.5,26.4,24.1,26.4z"/>
				</G>
			</Svg>
		</>
	);
}

export default IconNationalMonumentsHistoricSites;