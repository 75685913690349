const PendOreille = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Pend Oreille Scenic Byway",
				description: "The rocky northern shoreline of Lake Pend Oreille—Idaho's largest and deepest lake—sets the route for the Pend Oreille Scenic Byway. Revel in impressive alpine vistas, far-reaching forests and sparkling water as far as the eye can see. Accessible year-round, this byway offers magnificent winter views and dramatic fall colors.",
				link: "https://visitidaho.org/things-to-do/road-trips/pend-oreille-scenic-byway/",
				route: [
					"Sandpoint", 
					"Hope",
          "Clark Fork"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
          [
            -116.55347,
            48.27658,
            0
          ],
          [
            -116.55348,
            48.27976,
            0
          ],
          [
            -116.55347,
            48.27984,
            0
          ],
          [
            -116.55347,
            48.27989,
            0
          ],
          [
            -116.55346,
            48.27993,
            0
          ],
          [
            -116.55345,
            48.27995,
            0
          ],
          [
            -116.55345,
            48.27997,
            0
          ],
          [
            -116.55344,
            48.28,
            0
          ],
          [
            -116.55344,
            48.28002,
            0
          ],
          [
            -116.55343,
            48.28005,
            0
          ],
          [
            -116.55342,
            48.28007,
            0
          ],
          [
            -116.5534,
            48.28014,
            0
          ],
          [
            -116.55332,
            48.28033,
            0
          ],
          [
            -116.55316,
            48.28053,
            0
          ],
          [
            -116.55307,
            48.28066,
            0
          ],
          [
            -116.55306,
            48.28069,
            0
          ],
          [
            -116.55303,
            48.28075,
            0
          ],
          [
            -116.55297,
            48.2809,
            0
          ],
          [
            -116.55293,
            48.28111,
            0
          ],
          [
            -116.55274,
            48.28272,
            0
          ],
          [
            -116.55269,
            48.28342,
            0
          ],
          [
            -116.55268,
            48.28374,
            0
          ],
          [
            -116.55269,
            48.28401,
            0
          ],
          [
            -116.55271,
            48.28428,
            0
          ],
          [
            -116.55275,
            48.2845,
            0
          ],
          [
            -116.55305,
            48.2867,
            0
          ],
          [
            -116.55308,
            48.28702,
            0
          ],
          [
            -116.5531,
            48.28716,
            0
          ],
          [
            -116.55315,
            48.2877,
            0
          ],
          [
            -116.55316,
            48.28797,
            0
          ],
          [
            -116.55316,
            48.28813,
            0
          ],
          [
            -116.55315,
            48.28829,
            0
          ],
          [
            -116.55312,
            48.28841,
            0
          ],
          [
            -116.55307,
            48.28854,
            0
          ],
          [
            -116.55301,
            48.28867,
            0
          ],
          [
            -116.55294,
            48.2888,
            0
          ],
          [
            -116.55276,
            48.28903,
            0
          ],
          [
            -116.55256,
            48.28921,
            0
          ],
          [
            -116.55243,
            48.28931,
            0
          ],
          [
            -116.55232,
            48.28938,
            0
          ],
          [
            -116.55211,
            48.28949,
            0
          ],
          [
            -116.55202,
            48.28953,
            0
          ],
          [
            -116.55151,
            48.2897,
            0
          ],
          [
            -116.55126,
            48.28976,
            0
          ],
          [
            -116.55125,
            48.28977,
            0
          ],
          [
            -116.5511,
            48.2898,
            0
          ],
          [
            -116.55057,
            48.28993,
            0
          ],
          [
            -116.54875,
            48.29034,
            0
          ],
          [
            -116.5486,
            48.29039,
            0
          ],
          [
            -116.54801,
            48.29055,
            0
          ],
          [
            -116.54758,
            48.29069,
            0
          ],
          [
            -116.54748,
            48.29073,
            0
          ],
          [
            -116.54729,
            48.29082,
            0
          ],
          [
            -116.54717,
            48.29089,
            0
          ],
          [
            -116.54699,
            48.29103,
            0
          ],
          [
            -116.54689,
            48.29112,
            0
          ],
          [
            -116.54677,
            48.29124,
            0
          ],
          [
            -116.54668,
            48.29138,
            0
          ],
          [
            -116.54664,
            48.29145,
            0
          ],
          [
            -116.54657,
            48.29162,
            0
          ],
          [
            -116.54653,
            48.29177,
            0
          ],
          [
            -116.54651,
            48.29206,
            0
          ],
          [
            -116.54657,
            48.29243,
            0
          ],
          [
            -116.54689,
            48.29399,
            0
          ],
          [
            -116.54692,
            48.29429,
            0
          ],
          [
            -116.54692,
            48.29436,
            0
          ],
          [
            -116.5469,
            48.29452,
            0
          ],
          [
            -116.54687,
            48.29467,
            0
          ],
          [
            -116.54686,
            48.29475,
            0
          ],
          [
            -116.54684,
            48.29483,
            0
          ],
          [
            -116.54674,
            48.29507,
            0
          ],
          [
            -116.54664,
            48.29525,
            0
          ],
          [
            -116.54649,
            48.29546,
            0
          ],
          [
            -116.54641,
            48.29554,
            0
          ],
          [
            -116.54632,
            48.29565,
            0
          ],
          [
            -116.54602,
            48.29593,
            0
          ],
          [
            -116.53599,
            48.30435,
            0
          ],
          [
            -116.53586,
            48.30445,
            0
          ],
          [
            -116.53504,
            48.30515,
            0
          ],
          [
            -116.5348,
            48.30534,
            0
          ],
          [
            -116.53185,
            48.30784,
            0
          ],
          [
            -116.53171,
            48.30797,
            0
          ],
          [
            -116.53164,
            48.30801,
            0
          ],
          [
            -116.53148,
            48.30815,
            0
          ],
          [
            -116.53124,
            48.30833,
            0
          ],
          [
            -116.53115,
            48.30838,
            0
          ],
          [
            -116.53099,
            48.30848,
            0
          ],
          [
            -116.53072,
            48.30862,
            0
          ],
          [
            -116.53039,
            48.30877,
            0
          ],
          [
            -116.53025,
            48.30882,
            0
          ],
          [
            -116.52976,
            48.30894,
            0
          ],
          [
            -116.52947,
            48.309,
            0
          ],
          [
            -116.52944,
            48.30901,
            0
          ],
          [
            -116.52922,
            48.30905,
            0
          ],
          [
            -116.5292,
            48.30905,
            0
          ],
          [
            -116.52886,
            48.30908,
            0
          ],
          [
            -116.52869,
            48.30908,
            0
          ],
          [
            -116.52854,
            48.30909,
            0
          ],
          [
            -116.52344,
            48.30907,
            0
          ],
          [
            -116.5227,
            48.30909,
            0
          ],
          [
            -116.52266,
            48.30908,
            0
          ],
          [
            -116.52258,
            48.30909,
            0
          ],
          [
            -116.51795,
            48.30909,
            0
          ],
          [
            -116.5166,
            48.30907,
            0
          ],
          [
            -116.51639,
            48.30908,
            0
          ],
          [
            -116.51511,
            48.30908,
            0
          ],
          [
            -116.51496,
            48.30909,
            0
          ],
          [
            -116.51364,
            48.30911,
            0
          ],
          [
            -116.51342,
            48.30912,
            0
          ],
          [
            -116.51332,
            48.30912,
            0
          ],
          [
            -116.51292,
            48.30915,
            0
          ],
          [
            -116.51254,
            48.3092,
            0
          ],
          [
            -116.51243,
            48.30922,
            0
          ],
          [
            -116.51233,
            48.30923,
            0
          ],
          [
            -116.51215,
            48.30927,
            0
          ],
          [
            -116.51207,
            48.30928,
            0
          ],
          [
            -116.51111,
            48.30953,
            0
          ],
          [
            -116.50977,
            48.30993,
            0
          ],
          [
            -116.5093,
            48.31006,
            0
          ],
          [
            -116.50841,
            48.31033,
            0
          ],
          [
            -116.5032,
            48.31182,
            0
          ],
          [
            -116.50291,
            48.31191,
            0
          ],
          [
            -116.50275,
            48.31195,
            0
          ],
          [
            -116.49288,
            48.31483,
            0
          ],
          [
            -116.49283,
            48.31485,
            0
          ],
          [
            -116.48551,
            48.31701,
            0
          ],
          [
            -116.48443,
            48.31731,
            0
          ],
          [
            -116.48359,
            48.3175,
            0
          ],
          [
            -116.48331,
            48.31755,
            0
          ],
          [
            -116.48316,
            48.31757,
            0
          ],
          [
            -116.48305,
            48.31759,
            0
          ],
          [
            -116.48294,
            48.3176,
            0
          ],
          [
            -116.48282,
            48.31762,
            0
          ],
          [
            -116.48239,
            48.31766,
            0
          ],
          [
            -116.48229,
            48.31766,
            0
          ],
          [
            -116.48198,
            48.31768,
            0
          ],
          [
            -116.48187,
            48.31768,
            0
          ],
          [
            -116.48168,
            48.31769,
            0
          ],
          [
            -116.48129,
            48.31769,
            0
          ],
          [
            -116.48109,
            48.31768,
            0
          ],
          [
            -116.48092,
            48.31768,
            0
          ],
          [
            -116.4803,
            48.31764,
            0
          ],
          [
            -116.48016,
            48.31762,
            0
          ],
          [
            -116.48004,
            48.31761,
            0
          ],
          [
            -116.47949,
            48.31753,
            0
          ],
          [
            -116.47869,
            48.31737,
            0
          ],
          [
            -116.47535,
            48.31654,
            0
          ],
          [
            -116.47484,
            48.3164,
            0
          ],
          [
            -116.47148,
            48.31556,
            0
          ],
          [
            -116.47123,
            48.31549,
            0
          ],
          [
            -116.46838,
            48.31479,
            0
          ],
          [
            -116.46766,
            48.31466,
            0
          ],
          [
            -116.46694,
            48.31456,
            0
          ],
          [
            -116.46632,
            48.31452,
            0
          ],
          [
            -116.46598,
            48.31451,
            0
          ],
          [
            -116.46537,
            48.31452,
            0
          ],
          [
            -116.46481,
            48.31456,
            0
          ],
          [
            -116.46479,
            48.31457,
            0
          ],
          [
            -116.46459,
            48.31459,
            0
          ],
          [
            -116.46454,
            48.31459,
            0
          ],
          [
            -116.46423,
            48.31463,
            0
          ],
          [
            -116.46401,
            48.31467,
            0
          ],
          [
            -116.46386,
            48.31469,
            0
          ],
          [
            -116.46352,
            48.31476,
            0
          ],
          [
            -116.46349,
            48.31476,
            0
          ],
          [
            -116.46338,
            48.31479,
            0
          ],
          [
            -116.46324,
            48.31482,
            0
          ],
          [
            -116.46323,
            48.31482,
            0
          ],
          [
            -116.4631,
            48.31485,
            0
          ],
          [
            -116.46301,
            48.31488,
            0
          ],
          [
            -116.46292,
            48.3149,
            0
          ],
          [
            -116.46285,
            48.31492,
            0
          ],
          [
            -116.46276,
            48.31494,
            0
          ],
          [
            -116.46246,
            48.31503,
            0
          ],
          [
            -116.46224,
            48.31511,
            0
          ],
          [
            -116.46208,
            48.31516,
            0
          ],
          [
            -116.46199,
            48.3152,
            0
          ],
          [
            -116.46188,
            48.31524,
            0
          ],
          [
            -116.46154,
            48.31538,
            0
          ],
          [
            -116.46125,
            48.31552,
            0
          ],
          [
            -116.4612,
            48.31554,
            0
          ],
          [
            -116.46105,
            48.31562,
            0
          ],
          [
            -116.461,
            48.31564,
            0
          ],
          [
            -116.46087,
            48.31572,
            0
          ],
          [
            -116.4608,
            48.31575,
            0
          ],
          [
            -116.46057,
            48.31589,
            0
          ],
          [
            -116.46056,
            48.3159,
            0
          ],
          [
            -116.46048,
            48.31595,
            0
          ],
          [
            -116.46044,
            48.31598,
            0
          ],
          [
            -116.46029,
            48.31607,
            0
          ],
          [
            -116.46013,
            48.31618,
            0
          ],
          [
            -116.45998,
            48.3163,
            0
          ],
          [
            -116.45992,
            48.31634,
            0
          ],
          [
            -116.45973,
            48.3165,
            0
          ],
          [
            -116.4596,
            48.3166,
            0
          ],
          [
            -116.45951,
            48.31668,
            0
          ],
          [
            -116.45827,
            48.31787,
            0
          ],
          [
            -116.45687,
            48.31918,
            0
          ],
          [
            -116.4568,
            48.31923,
            0
          ],
          [
            -116.45674,
            48.31929,
            0
          ],
          [
            -116.45644,
            48.31953,
            0
          ],
          [
            -116.45642,
            48.31955,
            0
          ],
          [
            -116.45535,
            48.32023,
            0
          ],
          [
            -116.45497,
            48.32043,
            0
          ],
          [
            -116.45485,
            48.3205,
            0
          ],
          [
            -116.45471,
            48.32057,
            0
          ],
          [
            -116.45455,
            48.32064,
            0
          ],
          [
            -116.45443,
            48.3207,
            0
          ],
          [
            -116.4542,
            48.3208,
            0
          ],
          [
            -116.45411,
            48.32085,
            0
          ],
          [
            -116.4539,
            48.32093,
            0
          ],
          [
            -116.45379,
            48.32098,
            0
          ],
          [
            -116.45361,
            48.32104,
            0
          ],
          [
            -116.45337,
            48.32114,
            0
          ],
          [
            -116.45305,
            48.32124,
            0
          ],
          [
            -116.45279,
            48.32134,
            0
          ],
          [
            -116.45233,
            48.32148,
            0
          ],
          [
            -116.45193,
            48.32159,
            0
          ],
          [
            -116.45192,
            48.32159,
            0
          ],
          [
            -116.45148,
            48.3217,
            0
          ],
          [
            -116.45106,
            48.32179,
            0
          ],
          [
            -116.45061,
            48.32187,
            0
          ],
          [
            -116.44979,
            48.32199,
            0
          ],
          [
            -116.44173,
            48.32299,
            0
          ],
          [
            -116.44149,
            48.32301,
            0
          ],
          [
            -116.43607,
            48.32369,
            0
          ],
          [
            -116.43581,
            48.32373,
            0
          ],
          [
            -116.42803,
            48.32469,
            0
          ],
          [
            -116.42776,
            48.32473,
            0
          ],
          [
            -116.41979,
            48.32572,
            0
          ],
          [
            -116.41966,
            48.32573,
            0
          ],
          [
            -116.41806,
            48.32593,
            0
          ],
          [
            -116.4178,
            48.32597,
            0
          ],
          [
            -116.41545,
            48.32627,
            0
          ],
          [
            -116.41515,
            48.3263,
            0
          ],
          [
            -116.41418,
            48.32643,
            0
          ],
          [
            -116.41396,
            48.32647,
            0
          ],
          [
            -116.41379,
            48.32649,
            0
          ],
          [
            -116.41343,
            48.32655,
            0
          ],
          [
            -116.41281,
            48.32667,
            0
          ],
          [
            -116.41269,
            48.3267,
            0
          ],
          [
            -116.41246,
            48.32674,
            0
          ],
          [
            -116.41044,
            48.3272,
            0
          ],
          [
            -116.41021,
            48.32726,
            0
          ],
          [
            -116.4099,
            48.32733,
            0
          ],
          [
            -116.40884,
            48.32761,
            0
          ],
          [
            -116.40695,
            48.32808,
            0
          ],
          [
            -116.40595,
            48.32835,
            0
          ],
          [
            -116.40473,
            48.32865,
            0
          ],
          [
            -116.40305,
            48.32902,
            0
          ],
          [
            -116.40252,
            48.32911,
            0
          ],
          [
            -116.40179,
            48.32921,
            0
          ],
          [
            -116.40114,
            48.32928,
            0
          ],
          [
            -116.40077,
            48.3293,
            0
          ],
          [
            -116.40054,
            48.32932,
            0
          ],
          [
            -116.40031,
            48.32933,
            0
          ],
          [
            -116.39961,
            48.32933,
            0
          ],
          [
            -116.39945,
            48.32932,
            0
          ],
          [
            -116.39914,
            48.32932,
            0
          ],
          [
            -116.39852,
            48.32928,
            0
          ],
          [
            -116.39751,
            48.32917,
            0
          ],
          [
            -116.3962,
            48.32894,
            0
          ],
          [
            -116.39575,
            48.32883,
            0
          ],
          [
            -116.39574,
            48.32883,
            0
          ],
          [
            -116.3956,
            48.32879,
            0
          ],
          [
            -116.3955,
            48.32877,
            0
          ],
          [
            -116.39444,
            48.32841,
            0
          ],
          [
            -116.3938,
            48.32815,
            0
          ],
          [
            -116.39311,
            48.32783,
            0
          ],
          [
            -116.39269,
            48.32761,
            0
          ],
          [
            -116.39249,
            48.32749,
            0
          ],
          [
            -116.39238,
            48.32744,
            0
          ],
          [
            -116.39206,
            48.32724,
            0
          ],
          [
            -116.39158,
            48.32691,
            0
          ],
          [
            -116.39129,
            48.32669,
            0
          ],
          [
            -116.39084,
            48.32631,
            0
          ],
          [
            -116.39019,
            48.32568,
            0
          ],
          [
            -116.38977,
            48.32529,
            0
          ],
          [
            -116.38965,
            48.32519,
            0
          ],
          [
            -116.38959,
            48.32513,
            0
          ],
          [
            -116.38939,
            48.32498,
            0
          ],
          [
            -116.3891,
            48.32478,
            0
          ],
          [
            -116.38899,
            48.32472,
            0
          ],
          [
            -116.38879,
            48.3246,
            0
          ],
          [
            -116.38869,
            48.32455,
            0
          ],
          [
            -116.38862,
            48.32452,
            0
          ],
          [
            -116.38855,
            48.32448,
            0
          ],
          [
            -116.38848,
            48.32445,
            0
          ],
          [
            -116.3884,
            48.32441,
            0
          ],
          [
            -116.38834,
            48.32439,
            0
          ],
          [
            -116.38796,
            48.32424,
            0
          ],
          [
            -116.38787,
            48.32422,
            0
          ],
          [
            -116.38779,
            48.32419,
            0
          ],
          [
            -116.38771,
            48.32417,
            0
          ],
          [
            -116.3876,
            48.32413,
            0
          ],
          [
            -116.38753,
            48.32412,
            0
          ],
          [
            -116.38701,
            48.324,
            0
          ],
          [
            -116.38672,
            48.32396,
            0
          ],
          [
            -116.38662,
            48.32394,
            0
          ],
          [
            -116.38649,
            48.32393,
            0
          ],
          [
            -116.38636,
            48.32391,
            0
          ],
          [
            -116.38216,
            48.32355,
            0
          ],
          [
            -116.3817,
            48.32352,
            0
          ],
          [
            -116.38164,
            48.32351,
            0
          ],
          [
            -116.38105,
            48.32346,
            0
          ],
          [
            -116.38035,
            48.32337,
            0
          ],
          [
            -116.37966,
            48.32323,
            0
          ],
          [
            -116.37943,
            48.32316,
            0
          ],
          [
            -116.37935,
            48.32314,
            0
          ],
          [
            -116.37905,
            48.32304,
            0
          ],
          [
            -116.37895,
            48.323,
            0
          ],
          [
            -116.37826,
            48.32267,
            0
          ],
          [
            -116.37799,
            48.32252,
            0
          ],
          [
            -116.37764,
            48.32231,
            0
          ],
          [
            -116.37754,
            48.32224,
            0
          ],
          [
            -116.37747,
            48.3222,
            0
          ],
          [
            -116.37731,
            48.32209,
            0
          ],
          [
            -116.37705,
            48.32187,
            0
          ],
          [
            -116.37699,
            48.32181,
            0
          ],
          [
            -116.3769,
            48.32173,
            0
          ],
          [
            -116.37687,
            48.32169,
            0
          ],
          [
            -116.37678,
            48.3216,
            0
          ],
          [
            -116.37669,
            48.3215,
            0
          ],
          [
            -116.37655,
            48.32132,
            0
          ],
          [
            -116.37638,
            48.32107,
            0
          ],
          [
            -116.37635,
            48.321,
            0
          ],
          [
            -116.37631,
            48.32094,
            0
          ],
          [
            -116.37628,
            48.32087,
            0
          ],
          [
            -116.37625,
            48.32082,
            0
          ],
          [
            -116.37615,
            48.32062,
            0
          ],
          [
            -116.37613,
            48.32057,
            0
          ],
          [
            -116.37605,
            48.3203,
            0
          ],
          [
            -116.37603,
            48.32025,
            0
          ],
          [
            -116.37599,
            48.3201,
            0
          ],
          [
            -116.37594,
            48.31982,
            0
          ],
          [
            -116.37593,
            48.31971,
            0
          ],
          [
            -116.37589,
            48.31948,
            0
          ],
          [
            -116.37587,
            48.31941,
            0
          ],
          [
            -116.37585,
            48.31932,
            0
          ],
          [
            -116.37583,
            48.31919,
            0
          ],
          [
            -116.37573,
            48.31884,
            0
          ],
          [
            -116.37571,
            48.31879,
            0
          ],
          [
            -116.37568,
            48.31867,
            0
          ],
          [
            -116.37561,
            48.31852,
            0
          ],
          [
            -116.3756,
            48.31848,
            0
          ],
          [
            -116.37554,
            48.31836,
            0
          ],
          [
            -116.37545,
            48.31814,
            0
          ],
          [
            -116.37516,
            48.31765,
            0
          ],
          [
            -116.37499,
            48.31739,
            0
          ],
          [
            -116.37489,
            48.31727,
            0
          ],
          [
            -116.37468,
            48.31699,
            0
          ],
          [
            -116.37454,
            48.31683,
            0
          ],
          [
            -116.37446,
            48.31673,
            0
          ],
          [
            -116.37409,
            48.31636,
            0
          ],
          [
            -116.37351,
            48.31584,
            0
          ],
          [
            -116.37328,
            48.31565,
            0
          ],
          [
            -116.37319,
            48.31556,
            0
          ],
          [
            -116.37314,
            48.31552,
            0
          ],
          [
            -116.37303,
            48.31542,
            0
          ],
          [
            -116.37299,
            48.31537,
            0
          ],
          [
            -116.37287,
            48.31526,
            0
          ],
          [
            -116.3727,
            48.31509,
            0
          ],
          [
            -116.37263,
            48.31501,
            0
          ],
          [
            -116.37256,
            48.31494,
            0
          ],
          [
            -116.37228,
            48.31459,
            0
          ],
          [
            -116.37207,
            48.31425,
            0
          ],
          [
            -116.37203,
            48.31415,
            0
          ],
          [
            -116.372,
            48.31409,
            0
          ],
          [
            -116.37191,
            48.31383,
            0
          ],
          [
            -116.3719,
            48.31377,
            0
          ],
          [
            -116.3717,
            48.31303,
            0
          ],
          [
            -116.3716,
            48.31259,
            0
          ],
          [
            -116.37158,
            48.31248,
            0
          ],
          [
            -116.37128,
            48.31119,
            0
          ],
          [
            -116.37121,
            48.31093,
            0
          ],
          [
            -116.37071,
            48.30876,
            0
          ],
          [
            -116.37067,
            48.30856,
            0
          ],
          [
            -116.37022,
            48.30668,
            0
          ],
          [
            -116.37011,
            48.30628,
            0
          ],
          [
            -116.37006,
            48.30613,
            0
          ],
          [
            -116.36994,
            48.30581,
            0
          ],
          [
            -116.36986,
            48.30566,
            0
          ],
          [
            -116.36983,
            48.30559,
            0
          ],
          [
            -116.36967,
            48.30529,
            0
          ],
          [
            -116.36847,
            48.30317,
            0
          ],
          [
            -116.3679,
            48.30213,
            0
          ],
          [
            -116.36768,
            48.30178,
            0
          ],
          [
            -116.36761,
            48.30164,
            0
          ],
          [
            -116.36748,
            48.30142,
            0
          ],
          [
            -116.36737,
            48.30126,
            0
          ],
          [
            -116.36714,
            48.30097,
            0
          ],
          [
            -116.36663,
            48.30038,
            0
          ],
          [
            -116.36606,
            48.29978,
            0
          ],
          [
            -116.36565,
            48.29937,
            0
          ],
          [
            -116.36496,
            48.29863,
            0
          ],
          [
            -116.36454,
            48.29813,
            0
          ],
          [
            -116.36433,
            48.29786,
            0
          ],
          [
            -116.364,
            48.29741,
            0
          ],
          [
            -116.36372,
            48.29705,
            0
          ],
          [
            -116.36364,
            48.29696,
            0
          ],
          [
            -116.36359,
            48.29691,
            0
          ],
          [
            -116.36353,
            48.29684,
            0
          ],
          [
            -116.36337,
            48.29668,
            0
          ],
          [
            -116.36312,
            48.29649,
            0
          ],
          [
            -116.36297,
            48.29639,
            0
          ],
          [
            -116.36273,
            48.29626,
            0
          ],
          [
            -116.36237,
            48.29609,
            0
          ],
          [
            -116.36164,
            48.29582,
            0
          ],
          [
            -116.36162,
            48.29581,
            0
          ],
          [
            -116.36117,
            48.29566,
            0
          ],
          [
            -116.36105,
            48.29561,
            0
          ],
          [
            -116.36095,
            48.29558,
            0
          ],
          [
            -116.36085,
            48.29553,
            0
          ],
          [
            -116.36075,
            48.29549,
            0
          ],
          [
            -116.36057,
            48.29539,
            0
          ],
          [
            -116.36052,
            48.29535,
            0
          ],
          [
            -116.36046,
            48.29531,
            0
          ],
          [
            -116.3604,
            48.29526,
            0
          ],
          [
            -116.36034,
            48.29522,
            0
          ],
          [
            -116.36029,
            48.29518,
            0
          ],
          [
            -116.36025,
            48.29514,
            0
          ],
          [
            -116.36018,
            48.29508,
            0
          ],
          [
            -116.36014,
            48.29504,
            0
          ],
          [
            -116.36009,
            48.295,
            0
          ],
          [
            -116.36004,
            48.29494,
            0
          ],
          [
            -116.35963,
            48.29449,
            0
          ],
          [
            -116.3593,
            48.29411,
            0
          ],
          [
            -116.35915,
            48.29396,
            0
          ],
          [
            -116.35892,
            48.29375,
            0
          ],
          [
            -116.35864,
            48.29352,
            0
          ],
          [
            -116.35828,
            48.29326,
            0
          ],
          [
            -116.35816,
            48.29318,
            0
          ],
          [
            -116.35795,
            48.29302,
            0
          ],
          [
            -116.35771,
            48.29282,
            0
          ],
          [
            -116.35763,
            48.29276,
            0
          ],
          [
            -116.35753,
            48.29267,
            0
          ],
          [
            -116.35722,
            48.29236,
            0
          ],
          [
            -116.35711,
            48.29224,
            0
          ],
          [
            -116.35675,
            48.29182,
            0
          ],
          [
            -116.35572,
            48.29056,
            0
          ],
          [
            -116.3557,
            48.29053,
            0
          ],
          [
            -116.35547,
            48.29028,
            0
          ],
          [
            -116.35536,
            48.29017,
            0
          ],
          [
            -116.35378,
            48.2887,
            0
          ],
          [
            -116.35358,
            48.2885,
            0
          ],
          [
            -116.35354,
            48.28844,
            0
          ],
          [
            -116.35325,
            48.28807,
            0
          ],
          [
            -116.35305,
            48.28776,
            0
          ],
          [
            -116.35299,
            48.28765,
            0
          ],
          [
            -116.35288,
            48.28747,
            0
          ],
          [
            -116.35272,
            48.28725,
            0
          ],
          [
            -116.35267,
            48.28719,
            0
          ],
          [
            -116.35254,
            48.28706,
            0
          ],
          [
            -116.35214,
            48.28673,
            0
          ],
          [
            -116.35144,
            48.28621,
            0
          ],
          [
            -116.35138,
            48.28616,
            0
          ],
          [
            -116.35122,
            48.28604,
            0
          ],
          [
            -116.35115,
            48.28597,
            0
          ],
          [
            -116.3511,
            48.28594,
            0
          ],
          [
            -116.35087,
            48.28571,
            0
          ],
          [
            -116.35082,
            48.28564,
            0
          ],
          [
            -116.35079,
            48.28561,
            0
          ],
          [
            -116.35074,
            48.28554,
            0
          ],
          [
            -116.35072,
            48.2855,
            0
          ],
          [
            -116.35069,
            48.28545,
            0
          ],
          [
            -116.35065,
            48.2854,
            0
          ],
          [
            -116.35063,
            48.28536,
            0
          ],
          [
            -116.35061,
            48.28533,
            0
          ],
          [
            -116.35058,
            48.28527,
            0
          ],
          [
            -116.35057,
            48.28523,
            0
          ],
          [
            -116.35055,
            48.28519,
            0
          ],
          [
            -116.35053,
            48.28512,
            0
          ],
          [
            -116.3505,
            48.28504,
            0
          ],
          [
            -116.35048,
            48.28496,
            0
          ],
          [
            -116.35045,
            48.28489,
            0
          ],
          [
            -116.35043,
            48.28479,
            0
          ],
          [
            -116.35029,
            48.28423,
            0
          ],
          [
            -116.35026,
            48.28414,
            0
          ],
          [
            -116.35025,
            48.28409,
            0
          ],
          [
            -116.35022,
            48.28403,
            0
          ],
          [
            -116.3502,
            48.28396,
            0
          ],
          [
            -116.35016,
            48.28387,
            0
          ],
          [
            -116.34994,
            48.28345,
            0
          ],
          [
            -116.34958,
            48.28284,
            0
          ],
          [
            -116.34931,
            48.28241,
            0
          ],
          [
            -116.34911,
            48.28212,
            0
          ],
          [
            -116.34905,
            48.28202,
            0
          ],
          [
            -116.34897,
            48.2819,
            0
          ],
          [
            -116.34764,
            48.27971,
            0
          ],
          [
            -116.34702,
            48.27873,
            0
          ],
          [
            -116.34697,
            48.27866,
            0
          ],
          [
            -116.34598,
            48.27702,
            0
          ],
          [
            -116.3459,
            48.2769,
            0
          ],
          [
            -116.34589,
            48.27687,
            0
          ],
          [
            -116.34578,
            48.27667,
            0
          ],
          [
            -116.34549,
            48.27607,
            0
          ],
          [
            -116.34524,
            48.27563,
            0
          ],
          [
            -116.34507,
            48.27537,
            0
          ],
          [
            -116.34497,
            48.27523,
            0
          ],
          [
            -116.34486,
            48.27509,
            0
          ],
          [
            -116.3448,
            48.27503,
            0
          ],
          [
            -116.34472,
            48.27494,
            0
          ],
          [
            -116.34464,
            48.27487,
            0
          ],
          [
            -116.34454,
            48.27477,
            0
          ],
          [
            -116.34446,
            48.2747,
            0
          ],
          [
            -116.34437,
            48.27463,
            0
          ],
          [
            -116.34426,
            48.27453,
            0
          ],
          [
            -116.34424,
            48.27452,
            0
          ],
          [
            -116.34406,
            48.27438,
            0
          ],
          [
            -116.34395,
            48.27431,
            0
          ],
          [
            -116.34372,
            48.27415,
            0
          ],
          [
            -116.34329,
            48.2739,
            0
          ],
          [
            -116.34303,
            48.27377,
            0
          ],
          [
            -116.34289,
            48.27369,
            0
          ],
          [
            -116.34277,
            48.27363,
            0
          ],
          [
            -116.34267,
            48.27357,
            0
          ],
          [
            -116.34236,
            48.27341,
            0
          ],
          [
            -116.34217,
            48.2733,
            0
          ],
          [
            -116.342,
            48.27321,
            0
          ],
          [
            -116.34189,
            48.27313,
            0
          ],
          [
            -116.34161,
            48.27295,
            0
          ],
          [
            -116.34135,
            48.27274,
            0
          ],
          [
            -116.34118,
            48.27258,
            0
          ],
          [
            -116.34116,
            48.27257,
            0
          ],
          [
            -116.34088,
            48.27229,
            0
          ],
          [
            -116.34062,
            48.272,
            0
          ],
          [
            -116.34015,
            48.27144,
            0
          ],
          [
            -116.3397,
            48.27093,
            0
          ],
          [
            -116.33945,
            48.27063,
            0
          ],
          [
            -116.33925,
            48.27041,
            0
          ],
          [
            -116.33904,
            48.27016,
            0
          ],
          [
            -116.33902,
            48.27013,
            0
          ],
          [
            -116.33876,
            48.26984,
            0
          ],
          [
            -116.3383,
            48.26928,
            0
          ],
          [
            -116.33822,
            48.26917,
            0
          ],
          [
            -116.33813,
            48.26906,
            0
          ],
          [
            -116.33805,
            48.26894,
            0
          ],
          [
            -116.33786,
            48.26862,
            0
          ],
          [
            -116.3376,
            48.26812,
            0
          ],
          [
            -116.33705,
            48.26669,
            0
          ],
          [
            -116.3369,
            48.26634,
            0
          ],
          [
            -116.33684,
            48.26623,
            0
          ],
          [
            -116.33682,
            48.2662,
            0
          ],
          [
            -116.33673,
            48.26601,
            0
          ],
          [
            -116.33658,
            48.26575,
            0
          ],
          [
            -116.33649,
            48.26561,
            0
          ],
          [
            -116.33624,
            48.26526,
            0
          ],
          [
            -116.3362,
            48.26522,
            0
          ],
          [
            -116.33607,
            48.26505,
            0
          ],
          [
            -116.33587,
            48.26482,
            0
          ],
          [
            -116.3358,
            48.26475,
            0
          ],
          [
            -116.33546,
            48.26438,
            0
          ],
          [
            -116.33514,
            48.26408,
            0
          ],
          [
            -116.33461,
            48.26362,
            0
          ],
          [
            -116.33327,
            48.26252,
            0
          ],
          [
            -116.33266,
            48.26205,
            0
          ],
          [
            -116.33249,
            48.26191,
            0
          ],
          [
            -116.33158,
            48.26125,
            0
          ],
          [
            -116.33153,
            48.26122,
            0
          ],
          [
            -116.33137,
            48.2611,
            0
          ],
          [
            -116.33019,
            48.26028,
            0
          ],
          [
            -116.32935,
            48.25973,
            0
          ],
          [
            -116.32931,
            48.2597,
            0
          ],
          [
            -116.32917,
            48.25961,
            0
          ],
          [
            -116.329,
            48.25951,
            0
          ],
          [
            -116.32883,
            48.25939,
            0
          ],
          [
            -116.32793,
            48.25885,
            0
          ],
          [
            -116.327,
            48.25832,
            0
          ],
          [
            -116.32696,
            48.25829,
            0
          ],
          [
            -116.32682,
            48.25822,
            0
          ],
          [
            -116.32616,
            48.25785,
            0
          ],
          [
            -116.32612,
            48.25784,
            0
          ],
          [
            -116.32236,
            48.25577,
            0
          ],
          [
            -116.32061,
            48.25478,
            0
          ],
          [
            -116.32,
            48.25445,
            0
          ],
          [
            -116.31975,
            48.25456,
            0
          ],
          [
            -116.31965,
            48.25456,
            0
          ],
          [
            -116.3196,
            48.25455,
            0
          ],
          [
            -116.31954,
            48.25453,
            0
          ],
          [
            -116.31949,
            48.2545,
            0
          ],
          [
            -116.31936,
            48.2544,
            0
          ],
          [
            -116.31925,
            48.25422,
            0
          ],
          [
            -116.31914,
            48.25413,
            0
          ],
          [
            -116.31866,
            48.25378,
            0
          ],
          [
            -116.31835,
            48.25357,
            0
          ],
          [
            -116.3179,
            48.25329,
            0
          ],
          [
            -116.3173,
            48.25288,
            0
          ],
          [
            -116.31669,
            48.25241,
            0
          ],
          [
            -116.31668,
            48.2524,
            0
          ],
          [
            -116.3165,
            48.25227,
            0
          ],
          [
            -116.31632,
            48.25213,
            0
          ],
          [
            -116.31505,
            48.2512,
            0
          ],
          [
            -116.31366,
            48.2501,
            0
          ],
          [
            -116.3131,
            48.24971,
            0
          ],
          [
            -116.31276,
            48.24945,
            0
          ],
          [
            -116.31205,
            48.24908,
            0
          ],
          [
            -116.31111,
            48.24864,
            0
          ],
          [
            -116.31036,
            48.24835,
            0
          ],
          [
            -116.30878,
            48.24786,
            0
          ],
          [
            -116.30862,
            48.24783,
            0
          ],
          [
            -116.30856,
            48.24781,
            0
          ],
          [
            -116.30852,
            48.2478,
            0
          ],
          [
            -116.30836,
            48.24774,
            0
          ],
          [
            -116.30714,
            48.24739,
            0
          ],
          [
            -116.30715,
            48.24747,
            0
          ],
          [
            -116.30715,
            48.24748,
            0
          ],
          [
            -116.30714,
            48.24753,
            0
          ],
          [
            -116.30714,
            48.24766,
            0
          ],
          [
            -116.30715,
            48.24771,
            0
          ],
          [
            -116.30714,
            48.24766,
            0
          ],
          [
            -116.30714,
            48.24753,
            0
          ],
          [
            -116.30715,
            48.24748,
            0
          ],
          [
            -116.30715,
            48.24747,
            0
          ],
          [
            -116.30714,
            48.24739,
            0
          ],
          [
            -116.30836,
            48.24774,
            0
          ],
          [
            -116.30852,
            48.2478,
            0
          ],
          [
            -116.30856,
            48.24781,
            0
          ],
          [
            -116.30862,
            48.24783,
            0
          ],
          [
            -116.30878,
            48.24786,
            0
          ],
          [
            -116.30849,
            48.24771,
            0
          ],
          [
            -116.30796,
            48.24741,
            0
          ],
          [
            -116.3073,
            48.24708,
            0
          ],
          [
            -116.30514,
            48.24615,
            0
          ],
          [
            -116.30305,
            48.24511,
            0
          ],
          [
            -116.30293,
            48.24502,
            0
          ],
          [
            -116.30221,
            48.24454,
            0
          ],
          [
            -116.30119,
            48.24355,
            0
          ],
          [
            -116.29976,
            48.24222,
            0
          ],
          [
            -116.2996,
            48.2421,
            0
          ],
          [
            -116.29946,
            48.24204,
            0
          ],
          [
            -116.29929,
            48.24199,
            0
          ],
          [
            -116.29871,
            48.24191,
            0
          ],
          [
            -116.29854,
            48.2419,
            0
          ],
          [
            -116.29726,
            48.24193,
            0
          ],
          [
            -116.29693,
            48.24186,
            0
          ],
          [
            -116.29685,
            48.24181,
            0
          ],
          [
            -116.29515,
            48.24129,
            0
          ],
          [
            -116.29536,
            48.24075,
            0
          ],
          [
            -116.29546,
            48.24057,
            0
          ],
          [
            -116.29555,
            48.24035,
            0
          ],
          [
            -116.29563,
            48.24018,
            0
          ],
          [
            -116.29466,
            48.23998,
            0
          ],
          [
            -116.29415,
            48.23986,
            0
          ],
          [
            -116.2939,
            48.23979,
            0
          ],
          [
            -116.29311,
            48.23954,
            0
          ],
          [
            -116.29261,
            48.23935,
            0
          ],
          [
            -116.29213,
            48.23915,
            0
          ],
          [
            -116.29201,
            48.23909,
            0
          ],
          [
            -116.28811,
            48.23745,
            0
          ],
          [
            -116.28142,
            48.23458,
            0
          ],
          [
            -116.28121,
            48.23448,
            0
          ],
          [
            -116.2808,
            48.2343,
            0
          ],
          [
            -116.28048,
            48.23414,
            0
          ],
          [
            -116.28002,
            48.23388,
            0
          ],
          [
            -116.27969,
            48.23367,
            0
          ],
          [
            -116.2796,
            48.2336,
            0
          ],
          [
            -116.27944,
            48.2335,
            0
          ],
          [
            -116.27886,
            48.23304,
            0
          ],
          [
            -116.27843,
            48.23264,
            0
          ],
          [
            -116.2779,
            48.23206,
            0
          ],
          [
            -116.27789,
            48.23204,
            0
          ],
          [
            -116.27362,
            48.22711,
            0
          ],
          [
            -116.2736,
            48.22707,
            0
          ],
          [
            -116.2718,
            48.225,
            0
          ],
          [
            -116.27138,
            48.22455,
            0
          ],
          [
            -116.27099,
            48.22415,
            0
          ],
          [
            -116.2701,
            48.22332,
            0
          ],
          [
            -116.26992,
            48.22318,
            0
          ],
          [
            -116.26929,
            48.22266,
            0
          ],
          [
            -116.26819,
            48.22188,
            0
          ],
          [
            -116.26814,
            48.22184,
            0
          ],
          [
            -116.26796,
            48.22172,
            0
          ],
          [
            -116.26666,
            48.22093,
            0
          ],
          [
            -116.2661,
            48.22064,
            0
          ],
          [
            -116.26374,
            48.21955,
            0
          ],
          [
            -116.26356,
            48.21946,
            0
          ],
          [
            -116.26163,
            48.21858,
            0
          ],
          [
            -116.26159,
            48.21857,
            0
          ],
          [
            -116.26096,
            48.21827,
            0
          ],
          [
            -116.26011,
            48.21783,
            0
          ],
          [
            -116.25977,
            48.21763,
            0
          ],
          [
            -116.25919,
            48.21724,
            0
          ],
          [
            -116.25875,
            48.21691,
            0
          ],
          [
            -116.25847,
            48.21668,
            0
          ],
          [
            -116.25799,
            48.21624,
            0
          ],
          [
            -116.2578,
            48.21605,
            0
          ],
          [
            -116.2575,
            48.21572,
            0
          ],
          [
            -116.25741,
            48.21561,
            0
          ],
          [
            -116.25729,
            48.21545,
            0
          ],
          [
            -116.25719,
            48.21533,
            0
          ],
          [
            -116.25711,
            48.21522,
            0
          ],
          [
            -116.25704,
            48.21511,
            0
          ],
          [
            -116.25698,
            48.21503,
            0
          ],
          [
            -116.25686,
            48.21484,
            0
          ],
          [
            -116.2568,
            48.21473,
            0
          ],
          [
            -116.25675,
            48.21465,
            0
          ],
          [
            -116.25675,
            48.21463,
            0
          ],
          [
            -116.25667,
            48.21451,
            0
          ],
          [
            -116.25648,
            48.21415,
            0
          ],
          [
            -116.25627,
            48.2137,
            0
          ],
          [
            -116.25614,
            48.21346,
            0
          ],
          [
            -116.25211,
            48.20538,
            0
          ],
          [
            -116.25192,
            48.20498,
            0
          ],
          [
            -116.24787,
            48.19685,
            0
          ],
          [
            -116.24783,
            48.19676,
            0
          ],
          [
            -116.2475,
            48.19611,
            0
          ],
          [
            -116.24709,
            48.19517,
            0
          ],
          [
            -116.24665,
            48.194,
            0
          ],
          [
            -116.24655,
            48.19369,
            0
          ],
          [
            -116.24644,
            48.1934,
            0
          ],
          [
            -116.24621,
            48.19263,
            0
          ],
          [
            -116.24611,
            48.19223,
            0
          ],
          [
            -116.24578,
            48.19109,
            0
          ],
          [
            -116.2456,
            48.19061,
            0
          ],
          [
            -116.24546,
            48.19028,
            0
          ],
          [
            -116.24532,
            48.18998,
            0
          ],
          [
            -116.24495,
            48.18933,
            0
          ],
          [
            -116.24492,
            48.18929,
            0
          ],
          [
            -116.24476,
            48.18904,
            0
          ],
          [
            -116.24456,
            48.18875,
            0
          ],
          [
            -116.24443,
            48.18858,
            0
          ],
          [
            -116.24398,
            48.18804,
            0
          ],
          [
            -116.24377,
            48.18781,
            0
          ],
          [
            -116.2432,
            48.18724,
            0
          ],
          [
            -116.24295,
            48.18701,
            0
          ],
          [
            -116.24257,
            48.18669,
            0
          ],
          [
            -116.24218,
            48.18638,
            0
          ],
          [
            -116.2325,
            48.17952,
            0
          ],
          [
            -116.23216,
            48.1793,
            0
          ],
          [
            -116.23162,
            48.17899,
            0
          ],
          [
            -116.23111,
            48.17874,
            0
          ],
          [
            -116.23063,
            48.17854,
            0
          ],
          [
            -116.23028,
            48.17841,
            0
          ],
          [
            -116.22933,
            48.17814,
            0
          ],
          [
            -116.22679,
            48.1776,
            0
          ],
          [
            -116.22519,
            48.17728,
            0
          ],
          [
            -116.22431,
            48.17718,
            0
          ],
          [
            -116.22418,
            48.17716,
            0
          ],
          [
            -116.22122,
            48.1769,
            0
          ],
          [
            -116.22033,
            48.17676,
            0
          ],
          [
            -116.21918,
            48.17649,
            0
          ],
          [
            -116.21916,
            48.17648,
            0
          ],
          [
            -116.21888,
            48.17641,
            0
          ],
          [
            -116.21788,
            48.17612,
            0
          ],
          [
            -116.21763,
            48.17606,
            0
          ],
          [
            -116.21708,
            48.17596,
            0
          ],
          [
            -116.21591,
            48.17586,
            0
          ],
          [
            -116.21412,
            48.17575,
            0
          ],
          [
            -116.21363,
            48.17571,
            0
          ],
          [
            -116.2133,
            48.17567,
            0
          ],
          [
            -116.21266,
            48.17557,
            0
          ],
          [
            -116.21218,
            48.17547,
            0
          ],
          [
            -116.21171,
            48.17535,
            0
          ],
          [
            -116.21094,
            48.17512,
            0
          ],
          [
            -116.2107,
            48.17503,
            0
          ],
          [
            -116.21049,
            48.17496,
            0
          ],
          [
            -116.21006,
            48.17479,
            0
          ],
          [
            -116.20973,
            48.17464,
            0
          ],
          [
            -116.20931,
            48.17443,
            0
          ],
          [
            -116.20897,
            48.17424,
            0
          ],
          [
            -116.20866,
            48.17405,
            0
          ],
          [
            -116.20853,
            48.17396,
            0
          ],
          [
            -116.20834,
            48.17384,
            0
          ],
          [
            -116.20782,
            48.17348,
            0
          ],
          [
            -116.20752,
            48.17326,
            0
          ],
          [
            -116.20709,
            48.17291,
            0
          ],
          [
            -116.20662,
            48.17245,
            0
          ],
          [
            -116.20654,
            48.17236,
            0
          ],
          [
            -116.20634,
            48.1721,
            0
          ],
          [
            -116.20631,
            48.17205,
            0
          ],
          [
            -116.20626,
            48.17198,
            0
          ],
          [
            -116.20615,
            48.17179,
            0
          ],
          [
            -116.20608,
            48.17161,
            0
          ],
          [
            -116.20605,
            48.17155,
            0
          ],
          [
            -116.20593,
            48.17119,
            0
          ],
          [
            -116.20589,
            48.17103,
            0
          ],
          [
            -116.20586,
            48.17085,
            0
          ],
          [
            -116.20575,
            48.1703,
            0
          ],
          [
            -116.2057,
            48.17,
            0
          ],
          [
            -116.20565,
            48.16976,
            0
          ],
          [
            -116.20558,
            48.1695,
            0
          ],
          [
            -116.20551,
            48.16933,
            0
          ],
          [
            -116.20537,
            48.16908,
            0
          ],
          [
            -116.20531,
            48.169,
            0
          ],
          [
            -116.20525,
            48.16891,
            0
          ],
          [
            -116.20512,
            48.16876,
            0
          ],
          [
            -116.20509,
            48.16872,
            0
          ],
          [
            -116.20503,
            48.16867,
            0
          ],
          [
            -116.20488,
            48.16853,
            0
          ],
          [
            -116.20465,
            48.16833,
            0
          ],
          [
            -116.20458,
            48.16829,
            0
          ],
          [
            -116.20432,
            48.16812,
            0
          ],
          [
            -116.20385,
            48.16789,
            0
          ],
          [
            -116.20369,
            48.16782,
            0
          ],
          [
            -116.2029,
            48.16744,
            0
          ],
          [
            -116.20244,
            48.1672,
            0
          ],
          [
            -116.20182,
            48.16684,
            0
          ],
          [
            -116.2014,
            48.16658,
            0
          ],
          [
            -116.2008,
            48.16617,
            0
          ],
          [
            -116.20072,
            48.16612,
            0
          ],
          [
            -116.20041,
            48.1659,
            0
          ],
          [
            -116.20023,
            48.16575,
            0
          ],
          [
            -116.19961,
            48.16529,
            0
          ],
          [
            -116.19594,
            48.16242,
            0
          ],
          [
            -116.19581,
            48.16229,
            0
          ],
          [
            -116.19568,
            48.16217,
            0
          ],
          [
            -116.19513,
            48.16152,
            0
          ],
          [
            -116.1942,
            48.16035,
            0
          ],
          [
            -116.19262,
            48.15826,
            0
          ],
          [
            -116.19258,
            48.15822,
            0
          ],
          [
            -116.19221,
            48.15773,
            0
          ],
          [
            -116.19219,
            48.15772,
            0
          ],
          [
            -116.19171,
            48.1571,
            0
          ],
          [
            -116.1913,
            48.15662,
            0
          ],
          [
            -116.19101,
            48.1563,
            0
          ],
          [
            -116.19094,
            48.15623,
            0
          ],
          [
            -116.19047,
            48.1558,
            0
          ],
          [
            -116.19014,
            48.15552,
            0
          ],
          [
            -116.18964,
            48.15513,
            0
          ],
          [
            -116.18903,
            48.15469,
            0
          ],
          [
            -116.18861,
            48.15442,
            0
          ],
          [
            -116.18839,
            48.15429,
            0
          ],
          [
            -116.18755,
            48.15388,
            0
          ],
          [
            -116.18523,
            48.15286,
            0
          ],
          [
            -116.1848,
            48.15265,
            0
          ],
          [
            -116.18458,
            48.15251,
            0
          ],
          [
            -116.18441,
            48.15237,
            0
          ],
          [
            -116.18436,
            48.15231,
            0
          ],
          [
            -116.18425,
            48.1522,
            0
          ],
          [
            -116.18314,
            48.15086,
            0
          ],
          [
            -116.18308,
            48.15078,
            0
          ],
          [
            -116.18301,
            48.1507,
            0
          ],
          [
            -116.18281,
            48.15041,
            0
          ],
          [
            -116.18248,
            48.14988,
            0
          ],
          [
            -116.18245,
            48.14984,
            0
          ],
          [
            -116.18235,
            48.14968,
            0
          ],
          [
            -116.18231,
            48.14963,
            0
          ],
          [
            -116.18222,
            48.1495,
            0
          ],
          [
            -116.18198,
            48.14919,
            0
          ],
          [
            -116.18187,
            48.14906,
            0
          ],
          [
            -116.18167,
            48.1488,
            0
          ],
          [
            -116.18128,
            48.14835,
            0
          ],
          [
            -116.1812,
            48.14825,
            0
          ],
          [
            -116.18111,
            48.14815,
            0
          ],
          [
            -116.18104,
            48.14806,
            0
          ],
          [
            -116.18099,
            48.14802,
            0
          ],
          [
            -116.18093,
            48.14795,
            0
          ],
          [
            -116.18086,
            48.14788,
            0
          ],
          [
            -116.18076,
            48.1478,
            0
          ],
          [
            -116.18066,
            48.14774,
            0
          ],
          [
            -116.18059,
            48.14769,
            0
          ],
          [
            -116.18023,
            48.14752,
            0
          ],
          [
            -116.17972,
            48.1473,
            0
          ],
          [
            -116.17505,
            48.1454,
            0
          ],
          [
            -116.17536,
            48.14506,
            0
          ]
        ],
			},
			id: "86cd5e29-e6eb-4279-b976-7e5546c42965",
		},
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.5534675,
    //       48.276577,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Sandpoint, ID"
    //   },
    //   id: "ec9b3b0a-351d-4ad2-8058-ffee9d706e7e"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.3071465,
    //       48.2477052,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Hope, ID"
    //   },
    //   id: "84d5227f-6eec-4754-ac95-0caa2808b593"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.1753599,
    //       48.145055,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Clark Fork, ID"
    //   },
    //   id: "5a9fa36c-a541-4aad-a7dc-a383b8fd410d"
    // }
	],
};

export default PendOreille;
