import React, { useState } from "react";

const IconWildlife = (props) => {
	const [active, setActive] = useState(props.active);
	return (
		<>
			<svg x="0px" y="0px"
				viewBox="0 0 37 36"
				style={{enableBackground:"new 0 0 37 36"}}
				className={active ? "active" : ""}>
				<g>
					<path d="M27.4,24.2l-3.7-11.5c0-0.1-0.1-0.2-0.3-0.2s-0.2,0.1-0.3,0.2l-1.6,4.8l-2.8-8.4c0-0.1-0.1-0.2-0.3-0.2
						c-0.1,0-0.2,0.1-0.3,0.2l-2.7,8.3L14,12.7c0-0.1-0.1-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.3,0.2L9.6,24.2c0,0.1,0,0.2,0,0.3
						c0.1,0.1,0.1,0.1,0.2,0.1h2.7v2.3c0,0.2,0.1,0.3,0.3,0.3h1.6c0.2,0,0.3-0.1,0.3-0.3v-2.3h2.6v2.3c0,0.2,0.1,0.3,0.3,0.3h1.7
						c0.2,0,0.3-0.1,0.3-0.3v-2.3h2.5v2.3c0,0.2,0.1,0.3,0.3,0.3h1.6c0.2,0,0.3-0.1,0.3-0.3v-2.3h2.7c0.1,0,0.2-0.1,0.2-0.1
						C27.4,24.4,27.4,24.2,27.4,24.2z M10.3,24l3.4-10.3l1.5,4.6L13.3,24C13.3,24,10.3,24,10.3,24z M13.1,26.5v-2h1.1v2
						C14.2,26.5,13.1,26.5,13.1,26.5z M19.1,26.5H18v-2h1.1V26.5z M19.6,24h-5.7l4.6-13.8l2.7,8.3L23,24C23,24,19.6,24,19.6,24z
						 M22.8,26.5v-2h1.1v2H22.8z M23.6,24l-1.8-5.5l1.6-4.8L26.7,24H23.6z"/>
									<path
										d="M21.1,21.1H16c-0.2,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3h5.1c0.2,0,0.3-0.1,0.3-0.3S21.2,21.1,21.1,21.1z"/>
									<path
										d="M16.8,18.9h3.4c0.2,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3h-3.4c-0.2,0-0.3,0.1-0.3,0.3S16.7,18.9,16.8,18.9z"/>
									<path
										d="M17.7,16h1.7c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3h-1.7c-0.2,0-0.3,0.1-0.3,0.3C17.4,15.9,17.5,16,17.7,16z"/>
				</g>
</svg>
		</>
	);
}

export default IconWildlife
