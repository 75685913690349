//REACT
import React, { useEffect, useState, forwardRef } from "react";
import { useSelector } from "react-redux";
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  GeoJSON,
  Marker,
  Popup,
  ScaleControl,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import MarkerClusterGroup from "react-leaflet-cluster";

//JSONS REGIONS / BYWAYS
import IdahoJson from "../data/Idaho";
//import * as RegionsJSON from '../data/regions';
import * as ScenicBywaysJSON from "../data/scenic-byways";
//import * as CitiesSVG from '../data/cities';

//REDUX-RTK
import store from "../store/store";
import AdventureCard from "./Cards/AdventureCard";
import { setRecommendations } from "../store/actions/recommendedActions";
import { getMarkerIcon } from "./Markers/markers";
import { updatePopups } from "../store/actions/popupsActions";

// NOTE: iconCreateFunction is running by leaflet, which is not support ES6 arrow func syntax
// eslint-disable-next-line
const createClusterCustomIcon = function (cluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: L.point(33, 33, true),
  }); 
};

const Map = forwardRef((props, mapRef) => {
  const { showAccount, showDashboard, mapZoom, mapBounds } = props;
  const activities = useSelector(
    (state) => state.activities.selectedActivities
  );

  const byways = useSelector((state) => state.byways.selectedByways);
  const [markers, setMarkers] = useState([]);
  const cart = useSelector((state) => state.cart.cartItems);

  const loadMarkers = () => {
    const allMarkers = store.getState().markers.markers[0];
    const cartMarkers = store.getState().cart.cartItems;
    
    if (!allMarkers) return;
    
    allMarkers.forEach((item) => {
      const cartMatch = cartMarkers.some((e) => e.id === item.id);
      const categoryMatch = activities.some((e) => item.categories.includes(e));
      
      if (categoryMatch || cartMatch) {
        const selectedItem = cartMatch ? {...item, is_selected: true} : item;
        setMarkers(markers => [...markers, selectedItem]);
      }
    });
  };
  

  const recommendActivities = () => {
    const { markers, cart } = store.getState();
    const allMarkers = markers.markers[0];
    const cartMarkers = cart.cartItems;
    const arrayOfCats = Array.from(new Set(cartMarkers.flatMap(({ categories }) => categories)));
  
    if (allMarkers && allMarkers.length > 0) {
      const recommendations = allMarkers.filter(marker =>
        marker.categories.some(category => arrayOfCats.includes(category))
      );
      const uniqueRecommendations = Array.from(new Set(recommendations));
      const regionRecommendations = uniqueRecommendations.slice(0, 4);
      store.dispatch(setRecommendations(regionRecommendations));
    }
  };
  
  useEffect(() => {
    recommendActivities();
  }, [cart, activities]);

  useEffect(() => {
    setMarkers([]);
    loadMarkers();
  }, [showAccount, showDashboard, activities]);

  useEffect(() => {
    setMarkers([]);
    loadMarkers("cart");
  }, [cart]);

  const bywayStyles = (feature) => {
    let featureColor = "#D36841";
    switch (feature.properties.category) {
      case "somecategoryhere":
        featureColor = "red";
        break;
      default:
        featureColor = "#D36841";
    }
    return {
      color: featureColor,
      fillColor: "#fff",
      fillOpacity: 1,
      opacity: 1,
      weight: 6,
    };
  };

  const onEachFeature = (feature, layer) => {
    const { properties } = feature;
  
    if (properties && properties.name) {
      if (properties.shape === "Line") {
        // Add popup to byway route.
        const { name, description, route, link } = properties;
        const popupContent = `<article class="card-wrapper scenic-byway">
          <div class="card-content">
            <h5>${name}</h5>
            ${description ? `<p>${description}</p>` : ''}
            ${route ? `<p><strong>Stops along this byway:</strong>
              <ul>
                ${route.map(city => `<li>${city}</li>`).join('')}
              </ul>
            </p>` : ''}
            ${link ? `<a href="${link}" target="_blank">Explore this byway <IconExternalLink /></a>` : ''}
          </div>
        </article>`;
  
        layer.bindPopup(popupContent, {
          autoPan: true,
          maxHeight: 450,
        });
      } else {
        layer.bindTooltip(properties.name, {});
      }
    }
  };
  
  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        //console.log(cart.length, activities.length);
        if(cart.length === 0 && activities.length === 0 && byways.length === 0) {
          store.dispatch(updatePopups({ display: true, type: 'activities' }));
        }
      }
    });
    return null;
  }


  const pointToLayer = (feature, latlng) => {
    return L.circleMarker(latlng, {});
  };
  
  return (
    <MapContainer
      ref={mapRef}
      className="vita-map"
      center={[44.39682515811082, -114.25539640892148]}
      noWrap={true}
      zoom={mapZoom}
      maxZoom={18}
      minZoom={mapZoom}
      zoomControl={false}
      scrollWheelZoom={true}
      maxBounds={mapBounds}
      maxBoundsViscosity={0.5}
    >
      <TileLayer
        attribution="&copy; <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>"
        url="https://api.mapbox.com/styles/v1/visitidaho/clexsrswm000301orvslvh1q2/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidmlzaXRpZGFobyIsImEiOiJjbDlveHd3dDkwZWNyM3dsYjJtemF5b3d6In0.Mz0XqoMjxh3PlAeXFGvkfw"
      />
      <MapClickHandler />
      {/** IDAHO OUTLINE */}
      <GeoJSON
        className={"stateGeo"}
        data={IdahoJson}
        style={{ fillColor: "transparent" }}
      />

      {/** REGION OUTLINES */}
      {/* {regions.length && regions.map((region) => (
        <GeoJSON key={region} data={RegionsJSON[region]} style={{ weight: 1, opacity: '0.5', fillColor: '#336365', fillOpacity: '0.5' }} />
      ))} */}

      {/*
      <Marker
        key={`city-boise`}
        position={[
          CitiesSVG['Boise'].lat,
          CitiesSVG['Boise'].lng
        ]}
        zIndexOffset={9999}
        icon={getCityIcon(CitiesSVG['Boise'].svg)}
      />
      */}

      {/** SCENIC BYWAYS OUTLINES */}
      {byways.length &&
        byways.map((byway, index) => (
          <GeoJSON
            key={byway+'-'+index}
            className={"bywayGeo"}
            data={ScenicBywaysJSON[byway]}
            style={bywayStyles.bind(this)}
            onEachFeature={onEachFeature.bind(this)}
            pointToLayer={pointToLayer.bind(this)}
          />
        ))}

      <MarkerClusterGroup
        iconCreateFunction={createClusterCustomIcon}
        maxClusterRadius={5}
        spiderfyOnMaxZoom={true}
        spiderfyDistanceMultiplier={3}
        showCoverageOnHover={false}
        chunkedLoading
      >
        {markers.length > 0 &&
          markers.map((marker, index) => {
              return (
                <Marker
                  key={marker.id}
                  position={[marker.lat, marker.lng]}
                  className={index === 0 && "tour-marker"}
                  zIndexOffset={marker.is_selected ? 999 : 1}
                  data={marker}
                  icon={getMarkerIcon(marker)}
                >
                  <Popup>
                    <AdventureCard item={marker} />
                  </Popup>
                </Marker>
              );
          })}
      </MarkerClusterGroup>

      <ScaleControl position="bottomright" />
      <ZoomControl position="bottomright" />
    </MapContainer>
  );
});
export default Map;