import React from "react";

export default class IconArrowUp extends React.Component {
	render() {
		return (
			<>
				<svg xmlns="http://www.w3.org/2000/svg" width="16.615" height="9.499" viewBox="0 0 16.615 9.499">
					<path d="M14.493,14.114,8.211,20.4a1.187,1.187,0,0,1-1.677-1.682L13.652,11.6a1.185,1.185,0,0,1,1.637-.035l7.167,7.152A1.187,1.187,0,0,1,20.779,20.4Z" transform="translate(-6.188 -11.251)"/>
				</svg>
			</>
		);
	}
}
