import React, { useState } from "react";

const IconScenicByways = (props) => {
	const [active, setActive] = useState(props.active);
	return (
		<>
			<svg x="0px" y="0px"
				viewBox="0 0 37 36"
				style={{enableBackground:"new 0 0 37 36"}}
				className={active ? "active" : ""}>
				<g id="scenic-byway" transform="translate(9.234 7.315)">
					<path id="Path_1546" data-name="Path 1546" d="M14.29,16.336H12.781a.329.329,0,0,0-.333.333v3.205a.329.329,0,0,0,.333.333H14.29a.329.329,0,0,0,.333-.333V16.669A.342.342,0,0,0,14.29,16.336Zm-.333,3.205h-.843V17h.843Z" transform="translate(-4.3 -5.157)"/>
					<path id="Path_1547" data-name="Path 1547" d="M14.29,9.216H12.781a.329.329,0,0,0-.333.333v3.205a.329.329,0,0,0,.333.333H14.29a.329.329,0,0,0,.333-.333V9.549A.342.342,0,0,0,14.29,9.216Zm-.333,3.205h-.843V9.882h.843Z" transform="translate(-4.3 -2.668)"/>
					<path id="Path_1548" data-name="Path 1548" d="M12.479,1.886a.326.326,0,0,0-.3-.219H10.429a.336.336,0,0,0-.219-.083.319.319,0,0,0-.323.291.37.37,0,0,0-.021.1.3.3,0,0,0,.021.094V4.737H9.045V1.917a.329.329,0,0,0-.333-.333.316.316,0,0,0-.219.083H6.672a.312.312,0,0,0-.3.219L.792,19.369a.336.336,0,0,0,.042.281.3.3,0,0,0,.25.125H8.5a.132.132,0,0,0,.062-.01.264.264,0,0,0,.125.021.329.329,0,0,0,.333-.333V16.861h.843v2.5a.3.3,0,0,0-.021.094.324.324,0,0,0,.156.271.32.32,0,0,0,.2.062.308.308,0,0,0,.1-.021h7.5a.31.31,0,0,0,.25-.125.3.3,0,0,0,.042-.281L12.479,1.886ZM10.543,19.151v-2.6a.329.329,0,0,0-.333-.333H8.7a.329.329,0,0,0-.333.333v2.6H1.521L6.9,2.292H8.368V5.07A.329.329,0,0,0,8.7,5.4H10.21a.329.329,0,0,0,.333-.333V2.292h1.415l5.432,16.859Z" transform="translate(-0.221)"/>
					<path id="Path_1549" data-name="Path 1549" d="M.8,15.806,4.37,5.68a.333.333,0,1,0-.624-.229L.166,15.588a.336.336,0,0,0,.2.427.443.443,0,0,0,.114.021A.348.348,0,0,0,.8,15.806Z" transform="translate(0 -1.276)"/>
					<path id="Path_1550" data-name="Path 1550" d="M6.747,3.5a.443.443,0,0,0,.114.021A.335.335,0,0,0,7.173,3.3l.3-.853a.333.333,0,0,0-.624-.229l-.3.853A.328.328,0,0,0,6.747,3.5Z" transform="translate(-2.231 -0.146)"/>
					<path id="Path_1551" data-name="Path 1551" d="M25.895,15.592,22.325,5.467A.333.333,0,0,0,21.7,5.7l3.57,10.126a.335.335,0,0,0,.312.219.443.443,0,0,0,.114-.021.336.336,0,0,0,.2-.427Z" transform="translate(-7.528 -1.281)"/>
					<path id="Path_1552" data-name="Path 1552" d="M20.643,3.3a.335.335,0,0,0,.312.219.443.443,0,0,0,.114-.021.328.328,0,0,0,.2-.427l-.3-.853a.333.333,0,1,0-.624.229l.3.853Z" transform="translate(-7.052 -0.146)"/>
				</g>
			</svg>
		</>
	);
}

export default IconScenicByways
