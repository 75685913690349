import { Svg, G, Path } from "@react-pdf/renderer";
import React from "react";

const Logo = () => {
	return (
		<>
			<Svg
				width="80"
				height="43"
				preserveAspectRatio="true"
				viewBox="0 0 393.7 211.4"
			>
				<G>
					<G>
						<G>
							<Path
								fill="#fff"
								d="M284.4,83.6c-0.6,0.9-0.3,1.7,0.9,1.7l6.3,0.1c1.1,0,2.3-0.8,2.6-1.9l7.5-23.6c0.3-1.1,0.1-1.2-0.5-0.3     L284.4,83.6z"
							/>
						</G>
						<G>
							<Path
								fill="#fff"
								d="M194.1,85c-0.8,0.8-0.5,1.4,0.6,1.4l12.9-0.5c1.1,0,2.5-0.9,3.1-1.8l22.5-38.4c0.6-1,0.4-1.1-0.4-0.3     L194.1,85z"
							/>
						</G>
						<G>
							<Path
								fill="#fff"
								d="M229.1,23c0.8-0.8,2.3-0.9,3.2-0.3l7.6,4.9c0.9,0.6,2.4,0.5,3.2-0.3l21-19c0.8-0.7,1.1-0.6,0.5,0.4     l-41.1,74.9c-0.5,1-0.1,1.8,1.1,1.7c0,0,21.7-0.4,34.1-0.4c1.2,0,1.6,0,1.6,0c1.1,0,2.7-0.7,3.4-1.5l49.6-52.7     c0.8-0.8,1.2-0.6,1,0.5l-11.6,52.3c-0.2,1.1,0.5,2,1.6,2.1L392,92c1.1,0.1,1.4-0.4,0.6-1.2l-72.9-69.3c-0.8-0.8-2.2-0.8-3-0.1     l-6.9,5.8c-0.8,0.7-2.3,0.8-3.2,0.2L266.6,0c-0.9-0.6-2.4-0.6-3.3,0.1l-21.4,17c-0.9,0.7-2.4,0.8-3.3,0.2l-4.5-2.9     c-0.9-0.6-2.4-0.5-3.3,0.2l-89.7,74.1c-0.9,0.7-0.7,1.2,0.4,1.1l13.8-1.3c1.1-0.1,2.7-0.8,3.5-1.5L229.1,23z"
							/>
						</G>
					</G>
					<Path
						fill="#fff"
						d="M108.9,180c-8.6,6.1-20.6,9.6-33.6,9.6c-2.1,0-7.8-0.4-12.3-1.6c-0.4-5.9-0.7-25.2-0.7-30.3v-4.3   c0-4.5,0.4-25.8,0.7-31.3c2.2-0.3,5-0.5,7.5-0.5c19.3,0,32.7,2.8,41.2,8.6c9.8,6.7,11.1,15.9,11.1,24   C122.8,164.6,118,173.5,108.9,180 M53.9,114.5c-3.6,0-14-0.2-15.9-0.4l-0.1,3.2c2.9,0.2,5.2,1.5,8.5,2.1c0.4,4.8,0.6,21.6,0.6,28.4   v8.8c0,17.2-0.3,33-0.8,34.5l-0.8,3.3l-0.4,1.5l-0.4,1.6l1.6-0.1l1.6-0.1h0.7l21.1-0.1c50.8,0,68.8-26.6,68.8-49.4   c0-12.2-7.9-22.6-21.6-28.5c-7.6-3.2-21-5.2-36-5.2c-4.6,0-11.2,0.2-17.1,0.2C59.4,114.5,55.7,114.5,53.9,114.5"
					/>
					<Path
						fill="#fff"
						d="M377.7,158c0-9.4-3.7-18.3-10.5-25.2c-7.4-7.3-17.4-11.4-28.2-11.4c-16.2,0-35.1,8.1-35.1,31.1   c0,9,4,18.2,11.1,25.2c7.8,7.8,18.2,12.1,29.3,12.1c10.6,0,19-3,24.8-8.8C374.7,175.5,377.7,167.3,377.7,158 M301.6,185.9   c-8.6-8.2-12.3-16.8-12.3-28.6c0-10.6,4.5-20.9,12.5-29c10-10,24.2-15.6,40-15.6c11.2,0,23.4,1.1,35.6,9.4   c10.1,6.7,16.3,18,16.3,29.5c0,27.3-22.6,47.1-53.8,47.1C323.3,198.7,310.7,194.5,301.6,185.9"
					/>
					<Path
						fill="#fff"
						d="M26.5,118.7c4.4-0.4,6-1.2,8.5-1.4l0.1-3.2c-2.1,0.2-11.3,0.5-17.6,0.5c-5,0-15.1-0.2-17.5-0.5l0.7,3.1   c2.4,0.2,5.3,1,7.8,1.5c0.4,6,0.5,25.6,0.5,29.8V167c0,11-1.6,37.9-2.1,42.4l-0.1,1.4l19.8-5.9l0.4-0.4l-0.1-0.7l-0.1-0.7   c-0.3-1.2-0.6-11.1-0.6-35.3V149C26.2,131.2,26.4,121.3,26.5,118.7"
					/>
					<Path
						fill="#fff"
						d="M283.8,118.9c3.6-0.4,5.3-1.5,7.7-1.7l0.8-3c-2.2,0.2-9.7,0.4-15.9,0.4c-4.8,0-15.4-0.2-17.5-0.4l0.6,3   c3.6,0.2,5,1.1,7.2,1.6c0.4,4.6,0.6,20.2,0.6,27.2v4.5c-10.8-0.1-41.6,0-50.7,0V146c0-8.4,0.3-22.6,0.7-27.2c2-0.5,3.5-1.4,7-1.6   l0.6-3c-2,0.2-9.6,0.4-15.7,0.4c-4.7,0-13.5-0.2-15.6-0.4l0.6,3c4.2,0.4,5.7,1.1,7.9,1.6l0,0c0.3,5.9,0.5,25.4,0.5,29.7v10.4v7.9   c0.1,11.8,0.3,26.8-0.1,31.2v0.4l15.1-4.5v-0.4l-0.1-0.6c-0.5-4.1-0.9-20.2-0.9-31.3l-0.1-5.2c4,0,44.3-1.7,50.8-1.6v6.9   c0,11.7,0,26.2-0.7,29.7l-0.3,1.4l-0.3,1.4h1.4h1.4H282h1.5h1.5l-0.3-1.4l-0.3-1.4c-0.7-3.2-1-19.9-1-35v-7.8   C283.3,143.4,283.4,124.1,283.8,118.9"
					/>
					<Path
						fill="#fff"
						d="M150.4,150.6c2.9-8,7.2-19,9-22.6l8.4,22.6H150.4z M187.4,202.7l15.2-4.6l-0.9-1.5c0-0.1-2.9-5.5-6.3-13.6   c-1.4-3.5-4-10.3-6.5-16.9c-3-7.9-5.9-15.4-6.6-16.8c-0.9-1.9-17.9-47.4-18.1-47.9l-0.3-0.7l-0.3,0.7c-0.4,1-2.4,6.2-5.4,14   c-10.7,27.8-32.5,84.4-38.2,96l12.1-6c2.3-9.5,10.1-31.6,13.4-41.2c0.9-2.6,1.6-4.4,1.7-4.9c0.1-0.3,0.2-0.6,0.4-1   c0.1-0.4,0.7-2.3,0.9-2.8l20.7-1.1c0.9,2.1,9.4,23.6,7.5,18.4L187.4,202.7z"
					/>
					<G>
						<Path
							fill="#fff"
							d="M23.6,91.1h-5.4L7,63.1h6.8l7.3,19.6l7.3-19.6H35L23.6,91.1z"
						/>
						<Path fill="#fff" d="M42.5,90.9V63.1h6.1v27.8H42.5z" />
						<Path
							fill="#fff"
							d="M68.6,91.3c-4.2,0-8.5-1.5-11.8-4.4l3.6-4.3c2.5,2.1,5.1,3.4,8.3,3.4c2.5,0,4-1,4-2.6v-0.1    c0-1.5-1-2.3-5.6-3.5c-5.6-1.4-9.2-3-9.2-8.5v-0.1c0-5,4.1-8.4,9.7-8.4c4.1,0,7.5,1.3,10.3,3.5l-3.2,4.6c-2.5-1.7-4.9-2.7-7.2-2.7    c-2.3,0-3.6,1.1-3.6,2.4v0.1c0,1.8,1.2,2.4,6,3.7c5.6,1.5,8.8,3.5,8.8,8.3v0.1C78.8,88.2,74.6,91.3,68.6,91.3z"
						/>
						<Path fill="#fff" d="M87.5,90.9V63.1h6.1v27.8H87.5z" />
						<Path
							fill="#fff"
							d="M116.3,68.7v22.2h-6.1V68.7h-8.5v-5.6h23v5.6H116.3z"
						/>
					</G>
				</G>
			</Svg>
		</>
	);
};

export default Logo;