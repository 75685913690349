const HellsCanyon = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Hells Canyon Scenic Byway",
				description: "Get ready for an unforgettable journey on the Hells Canyon Scenic Byway. This route winds its way along the east side of Hells Canyon—North America's deepest river gorge—providing travelers with stunning views and ample opportunity to spot wildlife. Make the most of your trip with a guided float trip, whitewater rafting adventure or jet boat tour.",
				link: "https://visitidaho.org/things-to-do/road-trips/hells-canyon-scenic-byway/",
				route: [
					"Cambridge", 
					"Hells Canyon Dam", 
					"Brownlee Dam"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
					[-116.67672, 44.57175, 0],
					[-116.67721, 44.57206, 0],
					[-116.67739, 44.57219, 0],
					[-116.68098, 44.57447, 0],
					[-116.68135, 44.57468, 0],
					[-116.6815, 44.57474, 0],
					[-116.68158, 44.57476, 0],
					[-116.68166, 44.57479, 0],
					[-116.68202, 44.57486, 0],
					[-116.68241, 44.57489, 0],
					[-116.69462, 44.57473, 0],
					[-116.69493, 44.57474, 0],
					[-116.69511, 44.57476, 0],
					[-116.69539, 44.57482, 0],
					[-116.69563, 44.5749, 0],
					[-116.69586, 44.575, 0],
					[-116.69644, 44.57534, 0],
					[-116.70038, 44.57788, 0],
					[-116.7007, 44.57805, 0],
					[-116.70106, 44.5782, 0],
					[-116.7013, 44.57827, 0],
					[-116.70147, 44.5783, 0],
					[-116.70422, 44.57837, 0],
					[-116.70519, 44.57843, 0],
					[-116.70579, 44.57858, 0],
					[-116.70609, 44.57869, 0],
					[-116.70729, 44.57921, 0],
					[-116.70767, 44.57935, 0],
					[-116.70813, 44.57945, 0],
					[-116.7084, 44.57948, 0],
					[-116.70889, 44.5795, 0],
					[-116.7112, 44.57947, 0],
					[-116.71195, 44.57958, 0],
					[-116.71368, 44.58011, 0],
					[-116.71429, 44.58026, 0],
					[-116.71606, 44.5806, 0],
					[-116.71666, 44.58069, 0],
					[-116.71745, 44.5809, 0],
					[-116.71918, 44.5815, 0],
					[-116.71979, 44.58163, 0],
					[-116.72124, 44.58166, 0],
					[-116.72149, 44.58168, 0],
					[-116.72265, 44.58188, 0],
					[-116.72294, 44.58191, 0],
					[-116.72607, 44.58208, 0],
					[-116.72634, 44.58211, 0],
					[-116.72675, 44.58219, 0],
					[-116.72707, 44.58228, 0],
					[-116.73219, 44.58415, 0],
					[-116.73281, 44.58435, 0],
					[-116.7336, 44.58454, 0],
					[-116.73535, 44.58489, 0],
					[-116.7357, 44.58498, 0],
					[-116.73605, 44.58512, 0],
					[-116.73625, 44.58525, 0],
					[-116.73642, 44.58539, 0],
					[-116.73655, 44.58555, 0],
					[-116.73663, 44.58567, 0],
					[-116.73668, 44.58581, 0],
					[-116.73673, 44.58604, 0],
					[-116.73678, 44.58664, 0],
					[-116.73681, 44.589, 0],
					[-116.73686, 44.58922, 0],
					[-116.73692, 44.58939, 0],
					[-116.73705, 44.58965, 0],
					[-116.73748, 44.59032, 0],
					[-116.73765, 44.59055, 0],
					[-116.73789, 44.59082, 0],
					[-116.73807, 44.59098, 0],
					[-116.73844, 44.59123, 0],
					[-116.7422, 44.5935, 0],
					[-116.74272, 44.59375, 0],
					[-116.74273, 44.59375, 0],
					[-116.74332, 44.59405, 0],
					[-116.74482, 44.5951, 0],
					[-116.74571, 44.59568, 0],
					[-116.74662, 44.59618, 0],
					[-116.74689, 44.5963, 0],
					[-116.74693, 44.59631, 0],
					[-116.74712, 44.59639, 0],
					[-116.74715, 44.59639, 0],
					[-116.74724, 44.59642, 0],
					[-116.74753, 44.59648, 0],
					[-116.7479, 44.59653, 0],
					[-116.74813, 44.59658, 0],
					[-116.74843, 44.59669, 0],
					[-116.74872, 44.59683, 0],
					[-116.74908, 44.59706, 0],
					[-116.74919, 44.59715, 0],
					[-116.74937, 44.59734, 0],
					[-116.74949, 44.59752, 0],
					[-116.74959, 44.59774, 0],
					[-116.74974, 44.59824, 0],
					[-116.74974, 44.59825, 0],
					[-116.74978, 44.59835, 0],
					[-116.74988, 44.59853, 0],
					[-116.7501, 44.59881, 0],
					[-116.75036, 44.59908, 0],
					[-116.75045, 44.59915, 0],
					[-116.75068, 44.5993, 0],
					[-116.75156, 44.5997, 0],
					[-116.75227, 44.59998, 0],
					[-116.75262, 44.60014, 0],
					[-116.75354, 44.60066, 0],
					[-116.75436, 44.60118, 0],
					[-116.75615, 44.60206, 0],
					[-116.75628, 44.60214, 0],
					[-116.75632, 44.60215, 0],
					[-116.75684, 44.60261, 0],
					[-116.75882, 44.60451, 0],
					[-116.759, 44.60473, 0],
					[-116.75907, 44.60486, 0],
					[-116.75912, 44.605, 0],
					[-116.75916, 44.60516, 0],
					[-116.75917, 44.60565, 0],
					[-116.7591, 44.6061, 0],
					[-116.75903, 44.6063, 0],
					[-116.75903, 44.60646, 0],
					[-116.75902, 44.60653, 0],
					[-116.75907, 44.60678, 0],
					[-116.75914, 44.60701, 0],
					[-116.75925, 44.60749, 0],
					[-116.75928, 44.60771, 0],
					[-116.7593, 44.60848, 0],
					[-116.75921, 44.60901, 0],
					[-116.75904, 44.60957, 0],
					[-116.7589, 44.60994, 0],
					[-116.75795, 44.61176, 0],
					[-116.75777, 44.61218, 0],
					[-116.7577, 44.61247, 0],
					[-116.75769, 44.61249, 0],
					[-116.7577, 44.61285, 0],
					[-116.75775, 44.61319, 0],
					[-116.75791, 44.61347, 0],
					[-116.75822, 44.61391, 0],
					[-116.75872, 44.61445, 0],
					[-116.76065, 44.61635, 0],
					[-116.76106, 44.61679, 0],
					[-116.76121, 44.61693, 0],
					[-116.76171, 44.61747, 0],
					[-116.76216, 44.6179, 0],
					[-116.76254, 44.61817, 0],
					[-116.76294, 44.61839, 0],
					[-116.7637, 44.61864, 0],
					[-116.76433, 44.61875, 0],
					[-116.76506, 44.6188, 0],
					[-116.76952, 44.61851, 0],
					[-116.77042, 44.61852, 0],
					[-116.77052, 44.61855, 0],
					[-116.77054, 44.61855, 0],
					[-116.77075, 44.61863, 0],
					[-116.77209, 44.61956, 0],
					[-116.77348, 44.62068, 0],
					[-116.77404, 44.62141, 0],
					[-116.77465, 44.62251, 0],
					[-116.77491, 44.62303, 0],
					[-116.77542, 44.62396, 0],
					[-116.77593, 44.62498, 0],
					[-116.7771, 44.62711, 0],
					[-116.77745, 44.62754, 0],
					[-116.77792, 44.62799, 0],
					[-116.77824, 44.62825, 0],
					[-116.77853, 44.62845, 0],
					[-116.77889, 44.62867, 0],
					[-116.77961, 44.62902, 0],
					[-116.77962, 44.62902, 0],
					[-116.78108, 44.62961, 0],
					[-116.78267, 44.6302, 0],
					[-116.7834, 44.6305, 0],
					[-116.78361, 44.63061, 0],
					[-116.78382, 44.63074, 0],
					[-116.78398, 44.63088, 0],
					[-116.78421, 44.63112, 0],
					[-116.78482, 44.63185, 0],
					[-116.78496, 44.63197, 0],
					[-116.78518, 44.63213, 0],
					[-116.78804, 44.63388, 0],
					[-116.78849, 44.63423, 0],
					[-116.79288, 44.63816, 0],
					[-116.79345, 44.63856, 0],
					[-116.79438, 44.63903, 0],
					[-116.79497, 44.63937, 0],
					[-116.79548, 44.63972, 0],
					[-116.79618, 44.64029, 0],
					[-116.79639, 44.6405, 0],
					[-116.79681, 44.64098, 0],
					[-116.79713, 44.6413, 0],
					[-116.7973, 44.64144, 0],
					[-116.79754, 44.6416, 0],
					[-116.79928, 44.64252, 0],
					[-116.79954, 44.6427, 0],
					[-116.79989, 44.64309, 0],
					[-116.8009, 44.64438, 0],
					[-116.80111, 44.64459, 0],
					[-116.80123, 44.64468, 0],
					[-116.80132, 44.64473, 0],
					[-116.8023, 44.64518, 0],
					[-116.80265, 44.64538, 0],
					[-116.80284, 44.64554, 0],
					[-116.80306, 44.64576, 0],
					[-116.80408, 44.64726, 0],
					[-116.80445, 44.64773, 0],
					[-116.80461, 44.64787, 0],
					[-116.80475, 44.64796, 0],
					[-116.80526, 44.6482, 0],
					[-116.80875, 44.64951, 0],
					[-116.80911, 44.64967, 0],
					[-116.80961, 44.64996, 0],
					[-116.80999, 44.65024, 0],
					[-116.81055, 44.6506, 0],
					[-116.81108, 44.65084, 0],
					[-116.81152, 44.65098, 0],
					[-116.81303, 44.65137, 0],
					[-116.81304, 44.65137, 0],
					[-116.81437, 44.65173, 0],
					[-116.81483, 44.65188, 0],
					[-116.81564, 44.65225, 0],
					[-116.81808, 44.65352, 0],
					[-116.8183, 44.65365, 0],
					[-116.81876, 44.65387, 0],
					[-116.81922, 44.65405, 0],
					[-116.81947, 44.65413, 0],
					[-116.81998, 44.65423, 0],
					[-116.8229, 44.65447, 0],
					[-116.82328, 44.65456, 0],
					[-116.82365, 44.65467, 0],
					[-116.82394, 44.65479, 0],
					[-116.82436, 44.65506, 0],
					[-116.82602, 44.65626, 0],
					[-116.82645, 44.65662, 0],
					[-116.82658, 44.65676, 0],
					[-116.82665, 44.6569, 0],
					[-116.82674, 44.65833, 0],
					[-116.82676, 44.65918, 0],
					[-116.82682, 44.65965, 0],
					[-116.82688, 44.65985, 0],
					[-116.82697, 44.65999, 0],
					[-116.82709, 44.66013, 0],
					[-116.82731, 44.66027, 0],
					[-116.82757, 44.66037, 0],
					[-116.8279, 44.66046, 0],
					[-116.82855, 44.66058, 0],
					[-116.82889, 44.66067, 0],
					[-116.82919, 44.66079, 0],
					[-116.82951, 44.66098, 0],
					[-116.82979, 44.66122, 0],
					[-116.82997, 44.66145, 0],
					[-116.83011, 44.66166, 0],
					[-116.83022, 44.66203, 0],
					[-116.83023, 44.66239, 0],
					[-116.83007, 44.6635, 0],
					[-116.83007, 44.66392, 0],
					[-116.83009, 44.66412, 0],
					[-116.83012, 44.66428, 0],
					[-116.83017, 44.66443, 0],
					[-116.83031, 44.66476, 0],
					[-116.83047, 44.66503, 0],
					[-116.83088, 44.66552, 0],
					[-116.83116, 44.66579, 0],
					[-116.83382, 44.66812, 0],
					[-116.83486, 44.66909, 0],
					[-116.83619, 44.67041, 0],
					[-116.83652, 44.67081, 0],
					[-116.83667, 44.67104, 0],
					[-116.83687, 44.67139, 0],
					[-116.83701, 44.6718, 0],
					[-116.83727, 44.67293, 0],
					[-116.83737, 44.67319, 0],
					[-116.83746, 44.67338, 0],
					[-116.83784, 44.674, 0],
					[-116.83812, 44.6744, 0],
					[-116.83907, 44.67553, 0],
					[-116.83957, 44.67618, 0],
					[-116.83968, 44.67638, 0],
					[-116.83978, 44.6767, 0],
					[-116.8398, 44.67681, 0],
					[-116.83981, 44.67699, 0],
					[-116.8398, 44.67716, 0],
					[-116.83977, 44.67735, 0],
					[-116.8397, 44.67755, 0],
					[-116.83873, 44.67987, 0],
					[-116.83871, 44.68, 0],
					[-116.83871, 44.68012, 0],
					[-116.83874, 44.68028, 0],
					[-116.83877, 44.68036, 0],
					[-116.83892, 44.68065, 0],
					[-116.83931, 44.6813, 0],
					[-116.83968, 44.68202, 0],
					[-116.83998, 44.68284, 0],
					[-116.84019, 44.68325, 0],
					[-116.8403, 44.6834, 0],
					[-116.84102, 44.68419, 0],
					[-116.84151, 44.68478, 0],
					[-116.84174, 44.68502, 0],
					[-116.84195, 44.6853, 0],
					[-116.84208, 44.68552, 0],
					[-116.84214, 44.68568, 0],
					[-116.84222, 44.68601, 0],
					[-116.84226, 44.68626, 0],
					[-116.84233, 44.68651, 0],
					[-116.84284, 44.6878, 0],
					[-116.843, 44.68838, 0],
					[-116.84301, 44.68857, 0],
					[-116.84299, 44.68877, 0],
					[-116.84295, 44.68896, 0],
					[-116.84287, 44.68918, 0],
					[-116.84257, 44.68977, 0],
					[-116.84248, 44.69001, 0],
					[-116.84245, 44.69023, 0],
					[-116.84246, 44.69067, 0],
					[-116.8425, 44.69118, 0],
					[-116.84246, 44.69159, 0],
					[-116.84241, 44.69178, 0],
					[-116.84233, 44.69196, 0],
					[-116.84233, 44.69198, 0],
					[-116.84226, 44.69211, 0],
					[-116.84214, 44.69228, 0],
					[-116.84092, 44.69424, 0],
					[-116.84082, 44.69444, 0],
					[-116.84073, 44.69472, 0],
					[-116.84068, 44.69498, 0],
					[-116.84065, 44.6953, 0],
					[-116.84065, 44.69552, 0],
					[-116.8407, 44.69588, 0],
					[-116.84084, 44.69635, 0],
					[-116.84095, 44.69657, 0],
					[-116.84224, 44.69857, 0],
					[-116.84253, 44.69913, 0],
					[-116.84257, 44.69926, 0],
					[-116.84259, 44.69937, 0],
					[-116.84259, 44.69983, 0],
					[-116.84247, 44.70101, 0],
					[-116.84237, 44.70168, 0],
					[-116.84236, 44.70185, 0],
					[-116.84241, 44.70229, 0],
					[-116.84267, 44.70334, 0],
					[-116.84268, 44.70345, 0],
					[-116.84268, 44.70366, 0],
					[-116.84258, 44.70462, 0],
					[-116.8426, 44.7048, 0],
					[-116.84277, 44.70523, 0],
					[-116.84329, 44.70611, 0],
					[-116.8433, 44.70612, 0],
					[-116.84357, 44.7066, 0],
					[-116.84387, 44.70705, 0],
					[-116.84408, 44.70731, 0],
					[-116.84448, 44.70771, 0],
					[-116.84477, 44.70792, 0],
					[-116.84543, 44.70833, 0],
					[-116.84578, 44.70857, 0],
					[-116.84601, 44.7088, 0],
					[-116.84606, 44.70888, 0],
					[-116.84815, 44.7138, 0],
					[-116.84982, 44.71839, 0],
					[-116.84997, 44.71886, 0],
					[-116.85013, 44.71924, 0],
					[-116.85036, 44.71969, 0],
					[-116.85143, 44.72146, 0],
					[-116.85153, 44.72167, 0],
					[-116.85161, 44.72194, 0],
					[-116.85163, 44.7221, 0],
					[-116.85163, 44.72229, 0],
					[-116.85161, 44.72242, 0],
					[-116.85153, 44.7227, 0],
					[-116.85153, 44.72272, 0],
					[-116.85038, 44.72608, 0],
					[-116.84967, 44.72904, 0],
					[-116.84895, 44.73039, 0],
					[-116.84878, 44.73065, 0],
					[-116.84867, 44.73076, 0],
					[-116.84848, 44.73088, 0],
					[-116.84811, 44.73102, 0],
					[-116.84797, 44.73105, 0],
					[-116.84788, 44.73109, 0],
					[-116.84776, 44.73112, 0],
					[-116.84756, 44.73123, 0],
					[-116.8474, 44.73136, 0],
					[-116.84722, 44.73154, 0],
					[-116.84703, 44.73179, 0],
					[-116.84684, 44.73201, 0],
					[-116.8467, 44.73211, 0],
					[-116.84645, 44.73226, 0],
					[-116.84612, 44.73238, 0],
					[-116.84556, 44.73253, 0],
					[-116.84532, 44.73258, 0],
					[-116.84499, 44.73268, 0],
					[-116.84499, 44.73269, 0],
					[-116.8444, 44.73295, 0],
					[-116.84367, 44.73322, 0],
					[-116.84339, 44.73329, 0],
					[-116.84299, 44.73333, 0],
					[-116.84269, 44.73333, 0],
					[-116.8417, 44.7332, 0],
					[-116.84075, 44.73311, 0],
					[-116.84009, 44.7331, 0],
					[-116.8399, 44.73311, 0],
					[-116.83918, 44.73322, 0],
					[-116.83884, 44.73334, 0],
					[-116.83846, 44.73352, 0],
					[-116.83771, 44.73399, 0],
					[-116.83751, 44.73415, 0],
					[-116.83742, 44.73427, 0],
					[-116.8373, 44.73446, 0],
					[-116.83725, 44.73464, 0],
					[-116.83725, 44.73476, 0],
					[-116.83732, 44.73497, 0],
					[-116.83744, 44.73515, 0],
					[-116.83754, 44.73525, 0],
					[-116.83767, 44.73532, 0],
					[-116.83774, 44.73537, 0],
					[-116.83795, 44.73546, 0],
					[-116.83835, 44.73553, 0],
					[-116.83856, 44.73552, 0],
					[-116.83882, 44.73547, 0],
					[-116.83925, 44.73529, 0],
					[-116.83971, 44.73504, 0],
					[-116.83989, 44.73496, 0],
					[-116.84056, 44.73475, 0],
					[-116.84121, 44.73461, 0],
					[-116.84166, 44.73454, 0],
					[-116.84218, 44.73455, 0],
					[-116.84271, 44.73459, 0],
					[-116.8432, 44.73469, 0],
					[-116.84322, 44.73469, 0],
					[-116.84439, 44.735, 0],
					[-116.84565, 44.73528, 0],
					[-116.84632, 44.73531, 0],
					[-116.84784, 44.73529, 0],
					[-116.84941, 44.73536, 0],
					[-116.85053, 44.73549, 0],
					[-116.85076, 44.73547, 0],
					[-116.85097, 44.73542, 0],
					[-116.85141, 44.73526, 0],
					[-116.85191, 44.73511, 0],
					[-116.85224, 44.73507, 0],
					[-116.85276, 44.73508, 0],
					[-116.85451, 44.73522, 0],
					[-116.85581, 44.73515, 0],
					[-116.85607, 44.73516, 0],
					[-116.85619, 44.73518, 0],
					[-116.85626, 44.73518, 0],
					[-116.85668, 44.73525, 0],
					[-116.8567, 44.73526, 0],
					[-116.857, 44.73524, 0],
					[-116.85728, 44.73516, 0],
					[-116.85773, 44.7351, 0],
					[-116.85799, 44.73509, 0],
					[-116.86052, 44.73522, 0],
					[-116.86091, 44.7352, 0],
					[-116.86111, 44.73514, 0],
					[-116.86156, 44.73495, 0],
					[-116.86171, 44.73491, 0],
					[-116.86183, 44.7349, 0],
					[-116.86186, 44.73489, 0],
					[-116.86195, 44.73488, 0],
					[-116.86208, 44.73489, 0],
					[-116.86233, 44.73493, 0],
					[-116.86249, 44.73498, 0],
					[-116.86262, 44.73504, 0],
					[-116.86328, 44.73555, 0],
					[-116.86342, 44.73569, 0],
					[-116.86347, 44.73573, 0],
					[-116.86382, 44.73617, 0],
					[-116.86396, 44.73641, 0],
					[-116.86411, 44.73662, 0],
					[-116.86432, 44.73683, 0],
					[-116.86556, 44.73765, 0],
					[-116.86595, 44.73794, 0],
					[-116.86617, 44.73813, 0],
					[-116.86655, 44.73855, 0],
					[-116.86668, 44.73873, 0],
					[-116.86719, 44.73955, 0],
					[-116.8673, 44.7397, 0],
					[-116.86738, 44.73979, 0],
					[-116.86739, 44.73979, 0],
					[-116.86775, 44.74009, 0],
					[-116.86854, 44.74082, 0],
					[-116.86877, 44.74101, 0],
					[-116.86894, 44.74112, 0],
					[-116.86946, 44.74139, 0],
					[-116.8696, 44.74149, 0],
					[-116.8699, 44.74181, 0],
					[-116.87002, 44.74199, 0],
					[-116.87012, 44.7421, 0],
					[-116.87031, 44.74224, 0],
					[-116.8707, 44.74247, 0],
					[-116.87071, 44.74248, 0],
					[-116.87085, 44.74256, 0],
					[-116.87088, 44.74259, 0],
					[-116.87095, 44.74264, 0],
					[-116.87099, 44.7427, 0],
					[-116.87104, 44.7428, 0],
					[-116.87107, 44.74289, 0],
					[-116.87105, 44.74302, 0],
					[-116.87098, 44.74317, 0],
					[-116.87052, 44.74395, 0],
					[-116.87045, 44.74409, 0],
					[-116.87041, 44.74422, 0],
					[-116.87035, 44.74489, 0],
					[-116.8703, 44.74504, 0],
					[-116.87021, 44.74521, 0],
					[-116.87003, 44.74543, 0],
					[-116.86975, 44.74564, 0],
					[-116.86965, 44.74574, 0],
					[-116.86959, 44.74582, 0],
					[-116.86953, 44.74593, 0],
					[-116.8695, 44.74606, 0],
					[-116.8695, 44.74621, 0],
					[-116.86951, 44.7463, 0],
					[-116.86958, 44.74643, 0],
					[-116.86965, 44.74651, 0],
					[-116.86973, 44.74658, 0],
					[-116.87023, 44.74688, 0],
					[-116.8703, 44.74694, 0],
					[-116.87037, 44.74702, 0],
					[-116.8704, 44.74708, 0],
					[-116.87041, 44.74709, 0],
					[-116.87044, 44.74715, 0],
					[-116.87053, 44.74745, 0],
					[-116.87066, 44.74762, 0],
					[-116.87078, 44.74773, 0],
					[-116.87095, 44.74781, 0],
					[-116.87179, 44.74805, 0],
					[-116.87214, 44.7482, 0],
					[-116.87284, 44.74856, 0],
					[-116.87331, 44.74884, 0],
					[-116.87369, 44.74912, 0],
					[-116.87492, 44.75016, 0],
					[-116.87515, 44.75031, 0],
					[-116.87562, 44.75054, 0],
					[-116.87563, 44.75054, 0],
					[-116.87683, 44.75104, 0],
					[-116.87684, 44.75104, 0],
					[-116.87801, 44.75154, 0],
					[-116.87841, 44.75175, 0],
					[-116.87864, 44.75189, 0],
					[-116.87919, 44.75228, 0],
					[-116.88, 44.75304, 0],
					[-116.88093, 44.75397, 0],
					[-116.88164, 44.7546, 0],
					[-116.8818, 44.75479, 0],
					[-116.88193, 44.75503, 0],
					[-116.88197, 44.75525, 0],
					[-116.88197, 44.75539, 0],
					[-116.88183, 44.75581, 0],
					[-116.88181, 44.75597, 0],
					[-116.88184, 44.75614, 0],
					[-116.8819, 44.75623, 0],
					[-116.88205, 44.75639, 0],
					[-116.88217, 44.75646, 0],
					[-116.88224, 44.75648, 0],
					[-116.88235, 44.75653, 0],
					[-116.88258, 44.75661, 0],
					[-116.8829, 44.75675, 0],
					[-116.88307, 44.75685, 0],
					[-116.88357, 44.75725, 0],
					[-116.88372, 44.75734, 0],
					[-116.88412, 44.75751, 0],
					[-116.88543, 44.7579, 0],
					[-116.88575, 44.75796, 0],
					[-116.88645, 44.75801, 0],
					[-116.88674, 44.75801, 0],
					[-116.88737, 44.75795, 0],
					[-116.8888, 44.75765, 0],
					[-116.89121, 44.75703, 0],
					[-116.89153, 44.75697, 0],
					[-116.89198, 44.75693, 0],
					[-116.89248, 44.75692, 0],
					[-116.89495, 44.75696, 0],
					[-116.89537, 44.75699, 0],
					[-116.89566, 44.75705, 0],
					[-116.89726, 44.75746, 0],
					[-116.89743, 44.75748, 0],
					[-116.89752, 44.7575, 0],
					[-116.89806, 44.75752, 0],
					[-116.89826, 44.75751, 0],
					[-116.89834, 44.75752, 0],
					[-116.89854, 44.75752, 0],
					[-116.89873, 44.75754, 0],
					[-116.89901, 44.75759, 0],
					[-116.89924, 44.75765, 0],
					[-116.89948, 44.75775, 0],
					[-116.89949, 44.75775, 0],
					[-116.90048, 44.75826, 0],
					[-116.9026, 44.75943, 0],
					[-116.90293, 44.75964, 0],
					[-116.90312, 44.75978, 0],
					[-116.90345, 44.7601, 0],
					[-116.90363, 44.76034, 0],
					[-116.90372, 44.76049, 0],
					[-116.9038, 44.76072, 0],
					[-116.90383, 44.7609, 0],
					[-116.90389, 44.76187, 0],
					[-116.90393, 44.76203, 0],
					[-116.90396, 44.7621, 0],
					[-116.90409, 44.7623, 0],
					[-116.9044, 44.76266, 0],
					[-116.9045, 44.76276, 0],
					[-116.90468, 44.763, 0],
					[-116.9047, 44.76306, 0],
					[-116.90473, 44.76311, 0],
					[-116.90475, 44.76317, 0],
					[-116.90477, 44.76328, 0],
					[-116.90476, 44.76354, 0],
					[-116.90472, 44.76367, 0],
					[-116.90461, 44.7639, 0],
					[-116.90428, 44.76439, 0],
					[-116.90377, 44.76506, 0],
					[-116.90312, 44.7658, 0],
					[-116.90303, 44.76592, 0],
					[-116.90303, 44.76593, 0],
					[-116.90292, 44.76614, 0],
					[-116.90286, 44.76636, 0],
					[-116.90284, 44.76652, 0],
					[-116.90287, 44.7668, 0],
					[-116.9029, 44.76693, 0],
					[-116.9031, 44.7673, 0],
					[-116.90349, 44.76791, 0],
					[-116.9036, 44.76811, 0],
					[-116.90365, 44.76825, 0],
					[-116.90375, 44.76869, 0],
					[-116.9038, 44.76908, 0],
					[-116.9038, 44.76938, 0],
					[-116.90369, 44.76993, 0],
					[-116.90345, 44.77057, 0],
					[-116.90341, 44.77081, 0],
					[-116.90341, 44.77101, 0],
					[-116.90343, 44.77122, 0],
					[-116.90347, 44.77138, 0],
					[-116.90364, 44.77181, 0],
					[-116.90375, 44.77215, 0],
					[-116.9038, 44.77262, 0],
					[-116.90382, 44.77314, 0],
					[-116.90385, 44.7733, 0],
					[-116.90389, 44.77343, 0],
					[-116.90395, 44.77355, 0],
					[-116.90402, 44.77366, 0],
					[-116.90411, 44.77376, 0],
					[-116.90421, 44.77385, 0],
					[-116.90448, 44.77402, 0],
					[-116.90486, 44.77417, 0],
					[-116.9051, 44.77423, 0],
					[-116.9053, 44.77426, 0],
					[-116.90548, 44.77427, 0],
					[-116.90573, 44.77426, 0],
					[-116.90598, 44.77422, 0],
					[-116.90602, 44.77422, 0],
					[-116.90677, 44.77409, 0],
					[-116.90693, 44.77409, 0],
					[-116.9072, 44.77413, 0],
					[-116.90733, 44.77417, 0],
					[-116.90744, 44.77422, 0],
					[-116.90748, 44.77423, 0],
					[-116.9076, 44.77429, 0],
					[-116.90771, 44.77436, 0],
					[-116.90781, 44.77444, 0],
					[-116.90794, 44.77461, 0],
					[-116.90807, 44.77494, 0],
					[-116.90814, 44.77529, 0],
					[-116.90819, 44.77575, 0],
					[-116.90814, 44.77601, 0],
					[-116.90806, 44.77629, 0],
					[-116.9074, 44.77805, 0],
					[-116.90724, 44.77839, 0],
					[-116.90666, 44.77937, 0],
					[-116.90649, 44.77975, 0],
					[-116.90646, 44.77996, 0],
					[-116.90647, 44.78016, 0],
					[-116.90653, 44.78036, 0],
					[-116.90661, 44.78052, 0],
					[-116.90668, 44.78063, 0],
					[-116.90681, 44.7808, 0],
					[-116.90853, 44.7826, 0],
					[-116.90857, 44.78266, 0],
					[-116.90896, 44.78309, 0],
					[-116.90913, 44.78335, 0],
					[-116.90917, 44.78345, 0],
					[-116.90922, 44.78362, 0],
					[-116.90923, 44.78364, 0],
					[-116.90944, 44.78467, 0],
					[-116.90991, 44.78586, 0],
					[-116.91, 44.78637, 0],
					[-116.91004, 44.78647, 0],
					[-116.91011, 44.7866, 0],
					[-116.9104, 44.78701, 0],
					[-116.91049, 44.78716, 0],
					[-116.91057, 44.78735, 0],
					[-116.91082, 44.78807, 0],
					[-116.91089, 44.7882, 0],
					[-116.91089, 44.78821, 0],
					[-116.911, 44.78837, 0],
					[-116.91112, 44.78851, 0],
					[-116.91177, 44.78912, 0],
					[-116.91194, 44.7893, 0],
					[-116.91206, 44.78948, 0],
					[-116.9121, 44.78963, 0],
					[-116.91211, 44.78974, 0],
					[-116.91209, 44.78993, 0],
					[-116.91205, 44.79004, 0],
					[-116.91199, 44.79012, 0],
					[-116.91191, 44.7902, 0],
					[-116.91163, 44.79037, 0],
					[-116.91154, 44.79048, 0],
					[-116.91151, 44.79055, 0],
					[-116.9115, 44.79061, 0],
					[-116.91151, 44.79067, 0],
					[-116.91156, 44.79075, 0],
					[-116.91162, 44.79081, 0],
					[-116.91168, 44.79085, 0],
					[-116.91179, 44.7909, 0],
					[-116.91187, 44.79092, 0],
					[-116.91222, 44.79105, 0],
					[-116.91239, 44.79115, 0],
					[-116.91245, 44.7912, 0],
					[-116.91256, 44.79135, 0],
					[-116.91269, 44.79185, 0],
					[-116.91282, 44.79212, 0],
					[-116.91289, 44.7924, 0],
					[-116.91294, 44.79254, 0],
					[-116.91298, 44.79261, 0],
					[-116.91302, 44.79266, 0],
					[-116.91335, 44.79291, 0],
					[-116.9135, 44.79304, 0],
					[-116.91362, 44.7932, 0],
					[-116.91373, 44.79341, 0],
					[-116.9138, 44.79362, 0],
					[-116.91384, 44.79381, 0],
					[-116.91385, 44.79457, 0],
					[-116.91388, 44.79472, 0],
					[-116.91392, 44.79481, 0],
					[-116.91398, 44.79488, 0],
					[-116.91446, 44.79522, 0],
					[-116.91457, 44.79534, 0],
					[-116.91464, 44.79547, 0],
					[-116.91481, 44.79586, 0],
					[-116.91481, 44.79587, 0],
					[-116.91492, 44.79599, 0],
					[-116.91516, 44.79612, 0],
					[-116.91528, 44.79617, 0],
					[-116.91582, 44.7963, 0],
					[-116.91594, 44.79637, 0],
					[-116.91611, 44.79653, 0],
					[-116.91616, 44.79663, 0],
					[-116.91627, 44.79706, 0],
					[-116.91629, 44.7971, 0],
					[-116.91636, 44.79717, 0],
					[-116.91637, 44.79719, 0],
					[-116.91661, 44.79728, 0],
					[-116.91676, 44.79727, 0],
					[-116.91695, 44.79722, 0],
					[-116.9172, 44.7971, 0],
					[-116.91755, 44.797, 0],
					[-116.91772, 44.79697, 0],
					[-116.91799, 44.79698, 0],
					[-116.91822, 44.79701, 0],
					[-116.91846, 44.79707, 0],
					[-116.91866, 44.79715, 0],
					[-116.91885, 44.79725, 0],
					[-116.91919, 44.79751, 0],
					[-116.9193, 44.79762, 0],
					[-116.91936, 44.7977, 0],
					[-116.91942, 44.7978, 0],
					[-116.91944, 44.79789, 0],
					[-116.91943, 44.79797, 0],
					[-116.91934, 44.79822, 0],
					[-116.91932, 44.79831, 0],
					[-116.91934, 44.79842, 0],
					[-116.91939, 44.7985, 0],
					[-116.91946, 44.79857, 0],
					[-116.91962, 44.79864, 0],
					[-116.91975, 44.79868, 0],
					[-116.91993, 44.79877, 0],
					[-116.92003, 44.79885, 0],
					[-116.92009, 44.79893, 0],
					[-116.92012, 44.79902, 0],
					[-116.92012, 44.79912, 0],
					[-116.92004, 44.7999, 0],
					[-116.92007, 44.80008, 0],
					[-116.92015, 44.80026, 0],
					[-116.92046, 44.80076, 0],
					[-116.92052, 44.80091, 0],
					[-116.92055, 44.80105, 0],
					[-116.92056, 44.80127, 0],
					[-116.92053, 44.80161, 0],
					[-116.92051, 44.80172, 0],
					[-116.92048, 44.80181, 0],
					[-116.9203, 44.80215, 0],
					[-116.91891, 44.80432, 0],
					[-116.91876, 44.80465, 0],
					[-116.91829, 44.80672, 0],
					[-116.91816, 44.80719, 0],
					[-116.91816, 44.80737, 0],
					[-116.9182, 44.80751, 0],
					[-116.91825, 44.8076, 0],
					[-116.9186, 44.8079, 0],
					[-116.91871, 44.80802, 0],
					[-116.91871, 44.80803, 0],
					[-116.91878, 44.80813, 0],
					[-116.91878, 44.80814, 0],
					[-116.91883, 44.8083, 0],
					[-116.91877, 44.80852, 0],
					[-116.91875, 44.80855, 0],
					[-116.91857, 44.80874, 0],
					[-116.91834, 44.80891, 0],
					[-116.91833, 44.80891, 0],
					[-116.91726, 44.8095, 0],
					[-116.91711, 44.80955, 0],
					[-116.91698, 44.80961, 0],
					[-116.91643, 44.80976, 0],
					[-116.91614, 44.80982, 0],
					[-116.91552, 44.80991, 0],
					[-116.91499, 44.80996, 0],
					[-116.91453, 44.80998, 0],
					[-116.91279, 44.81014, 0],
					[-116.91265, 44.81017, 0],
					[-116.91229, 44.81031, 0],
					[-116.91214, 44.8104, 0],
					[-116.91201, 44.8105, 0],
					[-116.91184, 44.81069, 0],
					[-116.91175, 44.81084, 0],
					[-116.91164, 44.81115, 0],
					[-116.91158, 44.81139, 0],
					[-116.91134, 44.81206, 0],
					[-116.91128, 44.8123, 0],
					[-116.91128, 44.81251, 0],
					[-116.91133, 44.81288, 0],
					[-116.91131, 44.81301, 0],
					[-116.91126, 44.81315, 0],
					[-116.9112, 44.81326, 0],
					[-116.91104, 44.8135, 0],
					[-116.9109, 44.81378, 0],
					[-116.91078, 44.81429, 0],
					[-116.91077, 44.81439, 0],
					[-116.91072, 44.81445, 0],
					[-116.91058, 44.81453, 0],
					[-116.91049, 44.81456, 0],
					[-116.91024, 44.81455, 0],
					[-116.90994, 44.81449, 0],
					[-116.90974, 44.81448, 0],
					[-116.90939, 44.81453, 0],
					[-116.90929, 44.81456, 0],
					[-116.9091, 44.81466, 0],
					[-116.90904, 44.81472, 0],
					[-116.90891, 44.81489, 0],
					[-116.90881, 44.81511, 0],
					[-116.90882, 44.81539, 0],
					[-116.90897, 44.81561, 0],
					[-116.90918, 44.81584, 0],
					[-116.9094, 44.81612, 0],
					[-116.90953, 44.81634, 0],
					[-116.90959, 44.8165, 0],
					[-116.90962, 44.81666, 0],
					[-116.90966, 44.81707, 0],
					[-116.9097, 44.81728, 0],
					[-116.90978, 44.8174, 0],
					[-116.90988, 44.8175, 0],
					[-116.91002, 44.81759, 0],
					[-116.91047, 44.81782, 0],
					[-116.9106, 44.81795, 0],
					[-116.91068, 44.81808, 0],
					[-116.91072, 44.81823, 0],
					[-116.91067, 44.81851, 0],
					[-116.9106, 44.81864, 0],
					[-116.91057, 44.81872, 0],
					[-116.91049, 44.81883, 0],
					[-116.91036, 44.81892, 0],
					[-116.91014, 44.81898, 0],
					[-116.91003, 44.81899, 0],
					[-116.90973, 44.81894, 0],
					[-116.9096, 44.81889, 0],
					[-116.90938, 44.81884, 0],
					[-116.90921, 44.81882, 0],
					[-116.90908, 44.81884, 0],
					[-116.90897, 44.81887, 0],
					[-116.90879, 44.81897, 0],
					[-116.90875, 44.81903, 0],
					[-116.9087, 44.81917, 0],
					[-116.90873, 44.81934, 0],
					[-116.90885, 44.81964, 0],
					[-116.90897, 44.8198, 0],
					[-116.90926, 44.82008, 0],
					[-116.90935, 44.82022, 0],
					[-116.90938, 44.82033, 0],
					[-116.90936, 44.82044, 0],
					[-116.90917, 44.82093, 0],
					[-116.90906, 44.82107, 0],
					[-116.90884, 44.82122, 0],
					[-116.90859, 44.82129, 0],
					[-116.90853, 44.8213, 0],
					[-116.90832, 44.82131, 0],
					[-116.90808, 44.82124, 0],
					[-116.90717, 44.82085, 0],
					[-116.90704, 44.82082, 0],
					[-116.90693, 44.82081, 0],
					[-116.90678, 44.82082, 0],
					[-116.90671, 44.82084, 0],
					[-116.90656, 44.82092, 0],
					[-116.90649, 44.82102, 0],
					[-116.90646, 44.82112, 0],
					[-116.90648, 44.82132, 0],
					[-116.90665, 44.82165, 0],
					[-116.90671, 44.82186, 0],
					[-116.90674, 44.82253, 0],
					[-116.90684, 44.82273, 0],
					[-116.90695, 44.82291, 0],
					[-116.90697, 44.82304, 0],
					[-116.90687, 44.82327, 0],
					[-116.90673, 44.82349, 0],
					[-116.90658, 44.82367, 0],
					[-116.90658, 44.82368, 0],
					[-116.90624, 44.82407, 0],
					[-116.90605, 44.8242, 0],
					[-116.90574, 44.8243, 0],
					[-116.90501, 44.82438, 0],
					[-116.90499, 44.82439, 0],
					[-116.90477, 44.82445, 0],
					[-116.90452, 44.82454, 0],
					[-116.90436, 44.82457, 0],
					[-116.90374, 44.82459, 0],
					[-116.90328, 44.82456, 0],
					[-116.90289, 44.82457, 0],
					[-116.90268, 44.82461, 0],
					[-116.90252, 44.82468, 0],
					[-116.9024, 44.82477, 0],
					[-116.90237, 44.82488, 0],
					[-116.90237, 44.82497, 0],
					[-116.90238, 44.82499, 0],
					[-116.90252, 44.82511, 0],
					[-116.90267, 44.82518, 0],
					[-116.90308, 44.82532, 0],
					[-116.90341, 44.82546, 0],
					[-116.90351, 44.82553, 0],
					[-116.90364, 44.82572, 0],
					[-116.9037, 44.82603, 0],
					[-116.90378, 44.82621, 0],
					[-116.9039, 44.8264, 0],
					[-116.90412, 44.82668, 0],
					[-116.90425, 44.82691, 0],
					[-116.90429, 44.82708, 0],
					[-116.90426, 44.8272, 0],
					[-116.90413, 44.82755, 0],
					[-116.90391, 44.82787, 0],
					[-116.90376, 44.82802, 0],
					[-116.90373, 44.82804, 0],
					[-116.90352, 44.82832, 0],
					[-116.90332, 44.82862, 0],
					[-116.90321, 44.82873, 0],
					[-116.90312, 44.82879, 0],
					[-116.90269, 44.82901, 0],
					[-116.90256, 44.82906, 0],
					[-116.90238, 44.82916, 0],
					[-116.90227, 44.82925, 0],
					[-116.90221, 44.82934, 0],
					[-116.90218, 44.82948, 0],
					[-116.90219, 44.82956, 0],
					[-116.90222, 44.82965, 0],
					[-116.90231, 44.82978, 0],
					[-116.90236, 44.82992, 0],
					[-116.9024, 44.83013, 0],
					[-116.90233, 44.83034, 0],
					[-116.90223, 44.83051, 0],
					[-116.90216, 44.8306, 0],
					[-116.90206, 44.83066, 0],
					[-116.90192, 44.83071, 0],
					[-116.90173, 44.83073, 0],
					[-116.90124, 44.83067, 0],
					[-116.90086, 44.83067, 0],
					[-116.9006, 44.83072, 0],
					[-116.90048, 44.83079, 0],
					[-116.9004, 44.83085, 0],
					[-116.90033, 44.83095, 0],
					[-116.90031, 44.83107, 0],
					[-116.90032, 44.83122, 0],
					[-116.90038, 44.83149, 0],
					[-116.90045, 44.83165, 0],
					[-116.9006, 44.83189, 0],
					[-116.90096, 44.83229, 0],
					[-116.90103, 44.8324, 0],
					[-116.9011, 44.83254, 0],
					[-116.90114, 44.83268, 0],
					[-116.90115, 44.83281, 0],
					[-116.90107, 44.83315, 0],
					[-116.90094, 44.83337, 0],
					[-116.90094, 44.83338, 0],
					[-116.90061, 44.83381, 0],
					[-116.90046, 44.83395, 0],
					[-116.90034, 44.83404, 0],
					[-116.90021, 44.8341, 0],
					[-116.9002, 44.83411, 0],
					[-116.89993, 44.8342, 0],
					[-116.89928, 44.83432, 0],
					[-116.89814, 44.83447, 0],
					[-116.89795, 44.83452, 0],
					[-116.89779, 44.83463, 0],
					[-116.89774, 44.83473, 0],
					[-116.89774, 44.83484, 0],
					[-116.89781, 44.83493, 0],
					[-116.89796, 44.83503, 0],
					[-116.89834, 44.83519, 0],
					[-116.89843, 44.83527, 0],
					[-116.8985, 44.83538, 0],
					[-116.89852, 44.83549, 0],
					[-116.8985, 44.83559, 0],
					[-116.89842, 44.83567, 0],
					[-116.89819, 44.8358, 0],
					[-116.89794, 44.83585, 0],
					[-116.89754, 44.83586, 0],
					[-116.89711, 44.83583, 0],
					[-116.89668, 44.83572, 0],
					[-116.89647, 44.83568, 0],
					[-116.89627, 44.83568, 0],
					[-116.89608, 44.83574, 0],
					[-116.89596, 44.83585, 0],
					[-116.89592, 44.83594, 0],
					[-116.8959, 44.83604, 0],
					[-116.89592, 44.83621, 0],
					[-116.89601, 44.83641, 0],
					[-116.89611, 44.83655, 0],
					[-116.89614, 44.83661, 0],
					[-116.89623, 44.83671, 0],
					[-116.89625, 44.83674, 0],
					[-116.89628, 44.83691, 0],
					[-116.89624, 44.837, 0],
					[-116.89619, 44.83705, 0],
					[-116.89617, 44.83708, 0],
					[-116.89594, 44.83728, 0],
					[-116.89572, 44.83753, 0],
					[-116.89554, 44.83782, 0],
					[-116.89511, 44.83825, 0],
					[-116.89501, 44.83837, 0],
					[-116.89493, 44.83849, 0],
					[-116.89478, 44.83884, 0],
					[-116.8947, 44.83911, 0],
					[-116.89446, 44.83971, 0],
					[-116.89437, 44.84042, 0],
					[-116.89426, 44.84089, 0],
					[-116.89424, 44.84108, 0],
					[-116.89424, 44.84118, 0],
					[-116.89427, 44.84129, 0],
					[-116.89436, 44.84142, 0],
					[-116.89469, 44.84183, 0],
					[-116.89494, 44.84221, 0],
					[-116.8952, 44.84271, 0],
					[-116.89531, 44.84289, 0],
					[-116.89537, 44.84303, 0],
					[-116.89541, 44.84317, 0],
					[-116.89543, 44.84329, 0],
					[-116.89537, 44.84376, 0],
					[-116.89537, 44.84394, 0],
					[-116.89538, 44.84395, 0],
					[-116.89544, 44.84417, 0],
					[-116.89552, 44.84438, 0],
					[-116.89569, 44.84468, 0],
					[-116.89589, 44.84494, 0],
					[-116.89823, 44.84549, 0],
					[-116.89831, 44.84552, 0],
					[-116.89838, 44.84556, 0],
					[-116.89847, 44.84563, 0],
					[-116.89784, 44.84715, 0],
					[-116.8977, 44.84742, 0],
					[-116.89758, 44.84769, 0],
					[-116.89756, 44.84813, 0],
					[-116.89757, 44.84827, 0],
					[-116.89767, 44.8487, 0],
					[-116.89777, 44.84897, 0],
					[-116.89794, 44.84927, 0],
					[-116.89861, 44.85017, 0],
					[-116.89924, 44.85094, 0],
					[-116.89948, 44.85131, 0],
					[-116.89965, 44.85163, 0],
					[-116.8998, 44.85206, 0],
					[-116.89988, 44.85241, 0],
					[-116.89993, 44.85283, 0],
					[-116.89991, 44.85316, 0],
					[-116.89986, 44.85348, 0],
					[-116.8997, 44.85401, 0],
					[-116.89944, 44.85456, 0],
					[-116.89934, 44.85472, 0],
					[-116.89906, 44.85505, 0],
					[-116.89834, 44.85567, 0],
					[-116.89775, 44.85604, 0],
					[-116.8977, 44.85608, 0],
					[-116.89716, 44.85636, 0],
					[-116.8965, 44.85665, 0],
					[-116.89614, 44.85676, 0],
					[-116.89567, 44.85684, 0],
					[-116.89512, 44.8569, 0],
					[-116.89503, 44.8569, 0],
					[-116.89462, 44.85694, 0],
					[-116.89192, 44.85694, 0],
					[-116.88999, 44.857, 0],
					[-116.8892, 44.85705, 0],
					[-116.88868, 44.85715, 0],
					[-116.88752, 44.85748, 0],
					[-116.88626, 44.85793, 0],
					[-116.88605, 44.85802, 0],
					[-116.88596, 44.85807, 0],
					[-116.88588, 44.8581, 0],
					[-116.88559, 44.85826, 0],
					[-116.88513, 44.85856, 0],
					[-116.8828, 44.86034, 0],
					[-116.88102, 44.86163, 0],
					[-116.88069, 44.8619, 0],
					[-116.88008, 44.86251, 0],
					[-116.87998, 44.86265, 0],
					[-116.8799, 44.8628, 0],
					[-116.87986, 44.86291, 0],
					[-116.87974, 44.86348, 0],
					[-116.87959, 44.86405, 0],
					[-116.87907, 44.86551, 0],
					[-116.87894, 44.86578, 0],
					[-116.87866, 44.86617, 0],
					[-116.87855, 44.86629, 0],
					[-116.87833, 44.86648, 0],
					[-116.87822, 44.86655, 0],
					[-116.87809, 44.86665, 0],
					[-116.87798, 44.86672, 0],
					[-116.87774, 44.86684, 0],
					[-116.87511, 44.8679, 0],
					[-116.86865, 44.87017, 0],
					[-116.868, 44.87042, 0],
					[-116.86764, 44.8706, 0],
					[-116.86728, 44.87083, 0],
					[-116.86683, 44.87117, 0],
					[-116.86657, 44.87141, 0],
					[-116.86627, 44.87178, 0],
					[-116.86604, 44.87218, 0],
					[-116.86599, 44.87235, 0],
					[-116.8659, 44.87285, 0],
					[-116.8659, 44.87357, 0],
					[-116.86586, 44.87451, 0],
					[-116.86581, 44.87493, 0],
					[-116.86568, 44.87528, 0],
					[-116.86554, 44.87553, 0],
					[-116.86526, 44.87591, 0],
					[-116.86481, 44.87638, 0],
					[-116.86389, 44.87725, 0],
					[-116.86323, 44.87777, 0],
					[-116.8624, 44.87836, 0],
					[-116.86199, 44.87873, 0],
					[-116.86173, 44.87901, 0],
					[-116.8598, 44.88153, 0],
					[-116.85943, 44.88196, 0],
					[-116.85911, 44.88248, 0],
					[-116.85889, 44.88298, 0],
					[-116.85863, 44.8837, 0],
					[-116.85821, 44.88472, 0],
					[-116.85797, 44.88509, 0],
					[-116.8577, 44.88542, 0],
					[-116.85429, 44.88927, 0],
					[-116.85417, 44.88946, 0],
					[-116.8541, 44.88961, 0],
					[-116.85397, 44.89008, 0],
					[-116.85385, 44.89149, 0],
					[-116.85374, 44.8923, 0],
					[-116.85361, 44.89285, 0],
					[-116.85345, 44.89325, 0],
					[-116.85326, 44.89362, 0],
					[-116.85233, 44.89514, 0],
					[-116.85233, 44.89515, 0],
					[-116.85221, 44.89546, 0],
					[-116.85215, 44.89574, 0],
					[-116.85197, 44.8973, 0],
					[-116.85186, 44.89763, 0],
					[-116.85175, 44.89819, 0],
					[-116.85166, 44.89851, 0],
					[-116.85157, 44.89876, 0],
					[-116.85054, 44.9011, 0],
					[-116.85052, 44.90113, 0],
					[-116.85034, 44.90157, 0],
					[-116.84988, 44.90253, 0],
					[-116.84958, 44.90323, 0],
					[-116.84932, 44.90366, 0],
					[-116.84912, 44.90389, 0],
					[-116.84859, 44.9044, 0],
					[-116.84792, 44.90498, 0],
					[-116.8476, 44.90536, 0],
					[-116.8456, 44.90837, 0],
					[-116.84542, 44.90867, 0],
					[-116.84536, 44.90881, 0],
					[-116.84525, 44.9092, 0],
					[-116.84466, 44.91244, 0],
					[-116.84454, 44.91323, 0],
					[-116.84433, 44.91414, 0],
					[-116.84415, 44.91466, 0],
					[-116.84402, 44.91498, 0],
					[-116.84366, 44.9157, 0],
					[-116.84332, 44.91622, 0],
					[-116.84031, 44.92038, 0],
					[-116.84019, 44.92058, 0],
					[-116.84009, 44.92081, 0],
					[-116.84002, 44.92103, 0],
					[-116.83999, 44.92127, 0],
					[-116.83998, 44.92233, 0],
					[-116.84, 44.92274, 0],
					[-116.84013, 44.92352, 0],
					[-116.84014, 44.92372, 0],
					[-116.84013, 44.92397, 0],
					[-116.84009, 44.92417, 0],
					[-116.84009, 44.92422, 0],
					[-116.84007, 44.92428, 0],
					[-116.84006, 44.92429, 0],
					[-116.84003, 44.92439, 0],
					[-116.83995, 44.92458, 0],
					[-116.83926, 44.92571, 0],
					[-116.83882, 44.92657, 0],
					[-116.8387, 44.92672, 0],
					[-116.8386, 44.92682, 0],
					[-116.83728, 44.92781, 0],
					[-116.83547, 44.92929, 0],
					[-116.83504, 44.92974, 0],
					[-116.83471, 44.93022, 0],
					[-116.8345, 44.93062, 0],
					[-116.83433, 44.93105, 0],
					[-116.83431, 44.93114, 0],
					[-116.83426, 44.93128, 0],
					[-116.83426, 44.93131, 0],
					[-116.8342, 44.93158, 0],
					[-116.83416, 44.93224, 0],
					[-116.83425, 44.93289, 0],
					[-116.83436, 44.93328, 0],
					[-116.83492, 44.93498, 0],
					[-116.83542, 44.93634, 0],
					[-116.83553, 44.93658, 0],
					[-116.8358, 44.93703, 0],
					[-116.83602, 44.93732, 0],
					[-116.84018, 44.94151, 0],
					[-116.84018, 44.94152, 0],
					[-116.84151, 44.94283, 0],
					[-116.84232, 44.94368, 0],
					[-116.84253, 44.944, 0],
					[-116.84265, 44.94422, 0],
					[-116.84319, 44.94543, 0],
					[-116.84345, 44.94596, 0],
					[-116.84405, 44.94692, 0],
					[-116.84427, 44.94723, 0],
					[-116.84473, 44.94764, 0],
					[-116.84499, 44.9478, 0],
					[-116.84588, 44.94824, 0],
					[-116.84623, 44.94844, 0],
					[-116.84648, 44.94862, 0],
					[-116.84669, 44.94887, 0],
					[-116.84675, 44.94898, 0],
					[-116.84689, 44.94934, 0],
					[-116.84689, 44.94935, 0],
					[-116.8471, 44.94987, 0],
					[-116.84725, 44.95019, 0],
					[-116.84736, 44.95032, 0],
					[-116.84756, 44.95065, 0],
					[-116.84771, 44.95082, 0],
					[-116.84787, 44.95095, 0],
					[-116.84811, 44.95109, 0],
					[-116.84867, 44.95135, 0],
					[-116.84914, 44.95161, 0],
					[-116.84947, 44.95185, 0],
					[-116.84975, 44.95211, 0],
					[-116.84997, 44.95238, 0],
					[-116.85022, 44.95279, 0],
					[-116.85137, 44.95555, 0],
					[-116.85218, 44.95738, 0],
					[-116.85299, 44.95935, 0],
					[-116.85318, 44.95973, 0],
					[-116.85327, 44.95987, 0],
					[-116.85392, 44.96104, 0],
					[-116.85405, 44.96139, 0],
					[-116.85407, 44.96156, 0],
					[-116.85407, 44.9618, 0],
					[-116.85402, 44.96204, 0],
					[-116.85394, 44.96225, 0],
					[-116.8539, 44.96233, 0],
					[-116.85379, 44.96249, 0],
					[-116.85362, 44.96269, 0],
					[-116.85358, 44.96275, 0],
					[-116.85344, 44.9629, 0],
					[-116.85292, 44.96354, 0],
					[-116.85273, 44.96388, 0],
					[-116.8526, 44.9642, 0],
					[-116.85259, 44.96421, 0],
					[-116.85239, 44.96465, 0],
					[-116.85231, 44.96478, 0],
					[-116.85208, 44.96507, 0],
					[-116.8518, 44.96528, 0],
					[-116.8512, 44.96565, 0],
					[-116.85117, 44.96566, 0],
					[-116.85052, 44.96604, 0],
					[-116.84983, 44.9665, 0],
					[-116.84928, 44.96699, 0],
					[-116.84818, 44.96769, 0],
					[-116.84808, 44.96774, 0],
					[-116.84807, 44.96775, 0],
					[-116.84778, 44.96786, 0],
					[-116.84765, 44.96794, 0],
					[-116.84758, 44.96803, 0],
					[-116.84756, 44.9681, 0],
					[-116.84759, 44.96821, 0],
					[-116.84763, 44.96828, 0],
					[-116.8478, 44.96846, 0],
					[-116.84802, 44.96861, 0],
					[-116.84813, 44.96866, 0],
					[-116.84838, 44.96874, 0],
					[-116.84873, 44.96882, 0],
					[-116.84892, 44.96885, 0],
					[-116.84909, 44.96886, 0],
					[-116.84927, 44.96885, 0],
					[-116.84959, 44.96881, 0],
					[-116.85003, 44.96882, 0],
					[-116.85042, 44.96888, 0],
					[-116.85144, 44.96897, 0],
					[-116.85163, 44.969, 0],
					[-116.85232, 44.96923, 0],
					[-116.85233, 44.96924, 0],
					[-116.85273, 44.96942, 0],
					[-116.8531, 44.96964, 0],
					[-116.85411, 44.97031, 0],
					[-116.85432, 44.97047, 0],
					[-116.85493, 44.97109, 0],
					[-116.85543, 44.97148, 0],
					[-116.85544, 44.97148, 0],
					[-116.85569, 44.97163, 0],
					[-116.85579, 44.97166, 0],
					[-116.85606, 44.97171, 0],
					[-116.8583, 44.97177, 0],
					[-116.85829, 44.97211, 0],
					[-116.85828, 44.97219, 0],
					[-116.85827, 44.97252, 0],
					[-116.8583, 44.97272, 0],
					[-116.85848, 44.97349, 0],
					[-116.85843, 44.97383, 0],
					[-116.85837, 44.97395, 0],
					[-116.85817, 44.97426, 0],
					[-116.85705, 44.97471, 0],
					[-116.85487, 44.97568, 0],
					[-116.85469, 44.97593, 0],
					[-116.85535, 44.97677, 0],
					[-116.85563, 44.97717, 0],
					[-116.85584, 44.9776, 0],
					[-116.856, 44.97802, 0],
					[-116.85616, 44.97885, 0],
					[-116.85618, 44.97915, 0],
					[-116.85611, 44.97941, 0],
					[-116.85597, 44.9797, 0],
					[-116.85598, 44.98001, 0],
					[-116.8561, 44.98047, 0],
					[-116.85601, 44.98068, 0],
					[-116.85567, 44.98106, 0],
					[-116.85556, 44.98124, 0],
					[-116.85546, 44.98165, 0],
					[-116.85538, 44.98189, 0],
					[-116.85531, 44.98218, 0],
					[-116.85532, 44.98234, 0],
					[-116.85535, 44.9825, 0],
					[-116.85536, 44.9827, 0],
					[-116.85534, 44.98285, 0],
					[-116.8553, 44.983, 0],
					[-116.85514, 44.98336, 0],
					[-116.85502, 44.98357, 0],
					[-116.85491, 44.98382, 0],
					[-116.85484, 44.98413, 0],
					[-116.85461, 44.98478, 0],
					[-116.85448, 44.98534, 0],
					[-116.85433, 44.98567, 0],
					[-116.85342, 44.98698, 0],
					[-116.85272, 44.98788, 0],
					[-116.85259, 44.98815, 0],
					[-116.85239, 44.98847, 0],
					[-116.85225, 44.98863, 0],
					[-116.85198, 44.98883, 0],
					[-116.85169, 44.9891, 0],
					[-116.85159, 44.98926, 0],
					[-116.85156, 44.98936, 0],
					[-116.85154, 44.98957, 0],
					[-116.85145, 44.98986, 0],
					[-116.85139, 44.98996, 0],
					[-116.85054, 44.99109, 0],
					[-116.85054, 44.9911, 0],
					[-116.85025, 44.9914, 0],
					[-116.84887, 44.99267, 0],
					[-116.84857, 44.99297, 0],
					[-116.84829, 44.99329, 0],
					[-116.84802, 44.99364, 0],
					[-116.84785, 44.99389, 0],
					[-116.84784, 44.99392, 0],
					[-116.84708, 44.99512, 0],
					[-116.84704, 44.99521, 0],
					[-116.84702, 44.99562, 0],
					[-116.84697, 44.99586, 0],
					[-116.84672, 44.99661, 0],
					[-116.84661, 44.99688, 0],
					[-116.84645, 44.9971, 0],
					[-116.84589, 44.99755, 0],
					[-116.84571, 44.99772, 0],
					[-116.84511, 44.9984, 0],
					[-116.84494, 44.99865, 0],
					[-116.84486, 44.9988, 0],
					[-116.84461, 44.99944, 0],
					[-116.84458, 44.99947, 0],
					[-116.84443, 44.99973, 0],
					[-116.84441, 44.99978, 0],
					[-116.84422, 45.00002, 0],
					[-116.84401, 45.00033, 0],
					[-116.84392, 45.00055, 0],
					[-116.84391, 45.00065, 0],
					[-116.84394, 45.00088, 0],
					[-116.84394, 45.00104, 0],
					[-116.84391, 45.00122, 0],
					[-116.84388, 45.00133, 0],
					[-116.84368, 45.00179, 0],
					[-116.84362, 45.00197, 0],
					[-116.84327, 45.00347, 0],
					[-116.8432, 45.00394, 0],
					[-116.84323, 45.00433, 0],
					[-116.84323, 45.00562, 0],
					[-116.8433, 45.00671, 0],
					[-116.84335, 45.00714, 0],
					[-116.84352, 45.00792, 0],
					[-116.84363, 45.0092, 0],
					[-116.84363, 45.00973, 0],
					[-116.84365, 45.00985, 0],
					[-116.84374, 45.01, 0],
					[-116.84414, 45.01034, 0],
					[-116.84422, 45.01042, 0],
					[-116.8444, 45.01065, 0],
					[-116.84456, 45.01095, 0],
					[-116.84457, 45.01099, 0],
					[-116.84467, 45.01122, 0],
					[-116.84473, 45.01155, 0],
					[-116.8447, 45.01166, 0],
					[-116.84456, 45.01191, 0],
					[-116.84396, 45.01244, 0],
					[-116.84383, 45.0126, 0],
					[-116.84375, 45.01275, 0],
					[-116.84372, 45.01279, 0],
					[-116.84363, 45.01298, 0],
					[-116.84347, 45.01338, 0],
					[-116.84326, 45.0143, 0],
					[-116.84325, 45.01458, 0],
					[-116.84321, 45.01489, 0],
					[-116.84311, 45.01536, 0],
					[-116.84307, 45.01565, 0],
					[-116.84307, 45.01571, 0],
					[-116.84309, 45.01578, 0],
					[-116.84318, 45.01592, 0],
					[-116.84345, 45.01615, 0],
					[-116.84354, 45.01626, 0],
					[-116.84365, 45.01646, 0],
					[-116.84382, 45.01687, 0],
					[-116.84382, 45.01688, 0],
					[-116.84402, 45.01736, 0],
					[-116.84413, 45.0175, 0],
					[-116.84425, 45.01759, 0],
					[-116.84463, 45.01783, 0],
					[-116.84478, 45.01798, 0],
					[-116.84484, 45.0181, 0],
					[-116.84488, 45.01821, 0],
					[-116.84488, 45.0184, 0],
					[-116.84487, 45.01844, 0],
					[-116.8448, 45.01973, 0],
					[-116.84481, 45.01981, 0],
					[-116.84484, 45.01991, 0],
					[-116.84487, 45.01998, 0],
					[-116.84493, 45.02005, 0],
					[-116.8451, 45.0202, 0],
					[-116.84541, 45.02037, 0],
					[-116.84555, 45.02048, 0],
					[-116.84565, 45.02058, 0],
					[-116.84572, 45.02071, 0],
					[-116.84575, 45.02081, 0],
					[-116.84576, 45.0209, 0],
					[-116.84576, 45.021, 0],
					[-116.84574, 45.02111, 0],
					[-116.84569, 45.02127, 0],
					[-116.84568, 45.02154, 0],
					[-116.84575, 45.02194, 0],
					[-116.84579, 45.02231, 0],
					[-116.84584, 45.02254, 0],
					[-116.84584, 45.02261, 0],
					[-116.84582, 45.02272, 0],
					[-116.84575, 45.02286, 0],
					[-116.84564, 45.02297, 0],
					[-116.84505, 45.0233, 0],
					[-116.84484, 45.02346, 0],
					[-116.84451, 45.02384, 0],
					[-116.84442, 45.02396, 0],
					[-116.84401, 45.02428, 0],
					[-116.84381, 45.0245, 0],
					[-116.84372, 45.02463, 0],
					[-116.84354, 45.025, 0],
					[-116.84335, 45.02528, 0],
					[-116.84263, 45.02608, 0],
					[-116.84248, 45.02629, 0],
					[-116.84234, 45.02659, 0],
					[-116.84227, 45.02683, 0],
					[-116.84222, 45.02714, 0],
					[-116.84209, 45.02746, 0],
					[-116.84197, 45.0277, 0],
					[-116.84183, 45.02807, 0],
					[-116.84174, 45.02862, 0],
					[-116.8416, 45.02902, 0],
					[-116.84152, 45.02916, 0],
					[-116.84139, 45.02934, 0],
					[-116.84119, 45.02952, 0],
					[-116.84071, 45.0299, 0],
					[-116.84052, 45.03003, 0],
					[-116.83989, 45.03037, 0],
					[-116.83821, 45.03116, 0],
					[-116.83803, 45.03123, 0],
					[-116.83633, 45.03161, 0],
					[-116.83588, 45.03164, 0],
					[-116.83561, 45.03169, 0],
					[-116.83543, 45.03174, 0],
					[-116.83438, 45.0321, 0],
					[-116.83401, 45.03227, 0],
					[-116.83379, 45.03239, 0],
					[-116.83378, 45.0324, 0],
					[-116.83343, 45.03263, 0],
					[-116.83322, 45.03273, 0],
					[-116.8327, 45.03292, 0],
					[-116.83255, 45.03299, 0],
					[-116.83242, 45.03307, 0],
					[-116.83188, 45.03346, 0],
					[-116.83159, 45.0336, 0],
					[-116.83158, 45.0336, 0],
					[-116.83103, 45.03384, 0],
					[-116.83081, 45.03396, 0],
					[-116.83039, 45.03427, 0],
					[-116.82978, 45.0346, 0],
					[-116.82917, 45.03485, 0],
					[-116.82894, 45.03498, 0],
					[-116.8286, 45.03521, 0],
					[-116.82828, 45.03539, 0],
					[-116.8277, 45.03568, 0],
					[-116.8265, 45.03616, 0],
					[-116.8244, 45.03714, 0],
					[-116.8243, 45.0372, 0],
					[-116.82409, 45.0373, 0],
					[-116.82397, 45.03737, 0],
					[-116.8237, 45.03756, 0],
					[-116.82294, 45.03821, 0],
					[-116.82258, 45.03857, 0],
					[-116.82201, 45.03919, 0],
					[-116.82183, 45.03941, 0],
					[-116.82166, 45.03973, 0],
					[-116.82157, 45.03984, 0],
					[-116.82139, 45.04002, 0],
					[-116.82116, 45.04029, 0],
					[-116.82071, 45.04093, 0],
					[-116.82051, 45.04125, 0],
					[-116.82034, 45.04169, 0],
					[-116.82023, 45.04188, 0],
					[-116.82006, 45.04203, 0],
					[-116.81974, 45.04216, 0],
					[-116.81937, 45.04224, 0],
					[-116.81877, 45.04228, 0],
					[-116.81832, 45.04237, 0],
					[-116.81803, 45.04248, 0],
					[-116.81742, 45.04278, 0],
					[-116.81653, 45.04331, 0],
					[-116.81602, 45.04368, 0],
					[-116.81582, 45.04388, 0],
					[-116.81562, 45.04412, 0],
					[-116.81529, 45.04473, 0],
					[-116.81513, 45.04494, 0],
					[-116.8145, 45.0455, 0],
					[-116.81437, 45.04564, 0],
					[-116.81415, 45.04583, 0],
					[-116.81297, 45.04674, 0],
					[-116.81275, 45.04686, 0],
					[-116.81197, 45.04722, 0],
					[-116.81176, 45.04739, 0],
					[-116.81168, 45.0475, 0],
					[-116.81134, 45.04782, 0],
					[-116.81102, 45.04807, 0],
					[-116.81063, 45.04834, 0],
					[-116.8098, 45.04885, 0],
					[-116.80896, 45.0493, 0],
					[-116.8084, 45.04963, 0],
					[-116.80692, 45.0504, 0],
					[-116.80625, 45.05083, 0],
					[-116.80605, 45.051, 0],
					[-116.80596, 45.0511, 0],
					[-116.8056, 45.05158, 0],
					[-116.8055, 45.05166, 0],
					[-116.80533, 45.05175, 0],
					[-116.80514, 45.05183, 0],
					[-116.80505, 45.05188, 0],
					[-116.80476, 45.05209, 0],
					[-116.80426, 45.0525, 0],
					[-116.80354, 45.05291, 0],
					[-116.80338, 45.05304, 0],
					[-116.80316, 45.05319, 0],
					[-116.803, 45.05328, 0],
					[-116.80211, 45.05367, 0],
					[-116.80198, 45.05374, 0],
					[-116.80174, 45.05391, 0],
					[-116.80163, 45.05402, 0],
					[-116.80152, 45.05418, 0],
					[-116.80133, 45.05442, 0],
					[-116.80125, 45.05448, 0],
					[-116.80112, 45.05453, 0],
					[-116.80099, 45.05455, 0],
					[-116.80051, 45.05459, 0],
					[-116.80029, 45.05467, 0],
					[-116.80023, 45.05472, 0],
					[-116.80014, 45.05486, 0],
					[-116.80001, 45.0553, 0],
					[-116.79996, 45.05542, 0],
					[-116.79987, 45.05557, 0],
					[-116.79877, 45.05663, 0],
					[-116.79846, 45.05676, 0],
					[-116.79803, 45.05684, 0],
					[-116.79783, 45.05692, 0],
					[-116.79756, 45.05715, 0],
					[-116.79741, 45.05734, 0],
					[-116.79724, 45.05752, 0],
					[-116.79707, 45.05766, 0],
					[-116.79654, 45.05804, 0],
					[-116.79647, 45.05812, 0],
					[-116.7961, 45.05848, 0],
					[-116.79573, 45.05878, 0],
					[-116.79569, 45.05883, 0],
					[-116.79565, 45.05891, 0],
					[-116.79557, 45.05932, 0],
					[-116.79536, 45.06002, 0],
					[-116.79505, 45.06061, 0],
					[-116.79497, 45.06086, 0],
					[-116.79492, 45.06109, 0],
					[-116.79489, 45.06147, 0],
					[-116.79485, 45.06163, 0],
					[-116.79481, 45.06171, 0],
					[-116.79468, 45.06186, 0],
					[-116.79443, 45.06207, 0],
					[-116.79424, 45.06226, 0],
					[-116.79421, 45.06232, 0],
					[-116.79418, 45.06269, 0],
					[-116.79416, 45.0628, 0],
					[-116.79408, 45.0629, 0],
					[-116.79398, 45.06299, 0],
					[-116.79385, 45.06306, 0],
					[-116.79374, 45.06314, 0],
					[-116.79369, 45.06319, 0],
					[-116.79357, 45.06336, 0],
					[-116.79347, 45.06354, 0],
					[-116.79316, 45.06397, 0],
					[-116.79298, 45.06417, 0],
					[-116.79286, 45.06428, 0],
					[-116.79273, 45.06437, 0],
					[-116.7924, 45.06453, 0],
					[-116.79207, 45.06472, 0],
					[-116.79182, 45.06492, 0],
					[-116.79157, 45.06518, 0],
					[-116.78931, 45.0682, 0],
					[-116.78914, 45.06846, 0],
					[-116.78894, 45.06882, 0],
					[-116.78883, 45.06905, 0],
					[-116.78866, 45.06954, 0],
					[-116.78863, 45.06966, 0],
					[-116.78857, 45.06978, 0],
					[-116.78848, 45.06991, 0],
					[-116.78843, 45.07, 0],
					[-116.78778, 45.07074, 0],
					[-116.78771, 45.07084, 0],
					[-116.78769, 45.07086, 0],
					[-116.78759, 45.07104, 0],
					[-116.78742, 45.07125, 0],
					[-116.78732, 45.07135, 0],
					[-116.78717, 45.07146, 0],
					[-116.78702, 45.07159, 0],
					[-116.78698, 45.07161, 0],
					[-116.78687, 45.07175, 0],
					[-116.78683, 45.07183, 0],
					[-116.78675, 45.07204, 0],
					[-116.78668, 45.07217, 0],
					[-116.78655, 45.07234, 0],
					[-116.78577, 45.07306, 0],
					[-116.78507, 45.07366, 0],
					[-116.78488, 45.07379, 0],
					[-116.78447, 45.074, 0],
					[-116.78446, 45.07401, 0],
					[-116.78353, 45.07442, 0],
					[-116.78305, 45.07468, 0],
					[-116.78266, 45.07497, 0],
					[-116.78264, 45.07498, 0],
					[-116.78252, 45.07508, 0],
					[-116.7825, 45.07511, 0],
					[-116.78236, 45.07526, 0],
					[-116.78236, 45.07527, 0],
					[-116.78214, 45.07556, 0],
					[-116.78195, 45.07602, 0],
					[-116.78186, 45.07628, 0],
					[-116.7818, 45.07653, 0],
					[-116.78177, 45.0768, 0],
					[-116.78176, 45.07735, 0],
					[-116.78174, 45.07748, 0],
					[-116.78168, 45.07763, 0],
					[-116.78165, 45.07768, 0],
					[-116.78125, 45.07816, 0],
					[-116.78124, 45.07816, 0],
					[-116.78118, 45.07826, 0],
					[-116.78112, 45.07839, 0],
					[-116.78109, 45.0785, 0],
					[-116.78107, 45.07868, 0],
					[-116.78107, 45.07878, 0],
					[-116.78108, 45.07886, 0],
					[-116.78119, 45.07933, 0],
					[-116.78121, 45.07957, 0],
					[-116.7813, 45.08004, 0],
					[-116.78134, 45.08014, 0],
					[-116.78142, 45.08027, 0],
					[-116.78166, 45.0805, 0],
					[-116.7818, 45.08058, 0],
					[-116.78192, 45.08067, 0],
					[-116.78193, 45.08067, 0],
					[-116.78204, 45.08076, 0],
					[-116.78213, 45.08085, 0],
					[-116.78222, 45.08097, 0],
					[-116.78239, 45.08125, 0],
					[-116.78247, 45.08144, 0],
					[-116.7825, 45.08156, 0],
					[-116.78251, 45.08164, 0],
					[-116.78251, 45.08183, 0],
					[-116.78253, 45.08198, 0],
					[-116.78253, 45.0821, 0],
					[-116.78254, 45.08219, 0],
					[-116.78255, 45.0822, 0],
					[-116.78264, 45.08251, 0],
					[-116.78266, 45.08254, 0],
					[-116.78268, 45.0826, 0],
					[-116.78269, 45.08272, 0],
					[-116.78267, 45.0829, 0],
					[-116.78261, 45.083, 0],
					[-116.78259, 45.08307, 0],
					[-116.78253, 45.0832, 0],
					[-116.78252, 45.08324, 0],
					[-116.78249, 45.0833, 0],
					[-116.78247, 45.08338, 0],
					[-116.78248, 45.08368, 0],
					[-116.78251, 45.08377, 0],
					[-116.78256, 45.084, 0],
					[-116.78257, 45.08421, 0],
					[-116.78256, 45.08431, 0],
					[-116.78251, 45.08449, 0],
					[-116.78246, 45.08475, 0],
					[-116.78246, 45.08496, 0],
					[-116.78248, 45.08516, 0],
					[-116.78249, 45.0852, 0],
					[-116.78251, 45.08539, 0],
					[-116.78259, 45.08574, 0],
					[-116.78265, 45.0861, 0],
					[-116.78268, 45.08621, 0],
					[-116.78273, 45.0866, 0],
					[-116.78272, 45.08663, 0],
					[-116.78272, 45.08681, 0],
					[-116.78269, 45.087, 0],
					[-116.78268, 45.08719, 0],
					[-116.78272, 45.08755, 0],
					[-116.78269, 45.08779, 0],
					[-116.78259, 45.08806, 0],
					[-116.78243, 45.08841, 0],
					[-116.78237, 45.08871, 0],
					[-116.78237, 45.08894, 0],
					[-116.78243, 45.0895, 0],
					[-116.78259, 45.09041, 0],
					[-116.78261, 45.09073, 0],
					[-116.78256, 45.09145, 0],
					[-116.78248, 45.09207, 0],
					[-116.78241, 45.0929, 0],
					[-116.78244, 45.09326, 0],
					[-116.78243, 45.09361, 0],
					[-116.78235, 45.09397, 0],
					[-116.78217, 45.09429, 0],
					[-116.78216, 45.09429, 0],
					[-116.7818, 45.09456, 0],
					[-116.7815, 45.09481, 0],
					[-116.78081, 45.09549, 0],
					[-116.78057, 45.09576, 0],
					[-116.78034, 45.0962, 0],
					[-116.78033, 45.09621, 0],
					[-116.78017, 45.09646, 0],
					[-116.77997, 45.09672, 0],
					[-116.77952, 45.09741, 0],
					[-116.77932, 45.09759, 0],
					[-116.77908, 45.09784, 0],
					[-116.778, 45.0992, 0],
					[-116.77791, 45.09929, 0],
					[-116.77752, 45.09961, 0],
					[-116.77738, 45.0997, 0],
					[-116.77698, 45.1, 0],
					[-116.77687, 45.1001, 0],
					[-116.77677, 45.10024, 0],
					[-116.77669, 45.10039, 0],
					[-116.77663, 45.10065, 0],
					[-116.77662, 45.10066, 0],
					[-116.77656, 45.10081, 0],
					[-116.77649, 45.10093, 0],
					[-116.77639, 45.10106, 0],
					[-116.77598, 45.10149, 0],
					[-116.77593, 45.10156, 0],
					[-116.77587, 45.10174, 0],
					[-116.77574, 45.10237, 0],
					[-116.77567, 45.10252, 0],
					[-116.77558, 45.10265, 0],
					[-116.77558, 45.10266, 0],
					[-116.77547, 45.10278, 0],
					[-116.77507, 45.10316, 0],
					[-116.77484, 45.10343, 0],
					[-116.77438, 45.10415, 0],
					[-116.7742, 45.10434, 0],
					[-116.7741, 45.10442, 0],
					[-116.77352, 45.10477, 0],
					[-116.77318, 45.105, 0],
					[-116.77239, 45.10565, 0],
					[-116.7723, 45.1057, 0],
					[-116.77206, 45.1058, 0],
					[-116.7719, 45.10583, 0],
					[-116.77138, 45.10589, 0],
					[-116.77106, 45.10596, 0],
					[-116.77092, 45.10602, 0],
					[-116.77033, 45.10638, 0],
					[-116.77018, 45.10644, 0],
					[-116.77016, 45.10644, 0],
					[-116.76918, 45.10677, 0],
					[-116.7691, 45.10682, 0],
					[-116.76872, 45.10712, 0],
					[-116.76871, 45.10714, 0],
					[-116.7685, 45.10726, 0],
					[-116.76758, 45.10761, 0],
					[-116.76693, 45.10793, 0],
					[-116.76663, 45.10805, 0],
					[-116.76469, 45.10871, 0],
					[-116.7637, 45.10892, 0],
					[-116.76334, 45.10902, 0],
					[-116.76328, 45.10903, 0],
					[-116.76187, 45.10953, 0],
					[-116.76172, 45.10956, 0],
					[-116.7614, 45.10957, 0],
					[-116.76076, 45.10965, 0],
					[-116.76036, 45.10973, 0],
					[-116.75977, 45.10976, 0],
					[-116.75965, 45.10975, 0],
					[-116.75947, 45.10977, 0],
					[-116.75923, 45.10985, 0],
					[-116.75901, 45.11, 0],
					[-116.75873, 45.11023, 0],
					[-116.75858, 45.11033, 0],
					[-116.75842, 45.1105, 0],
					[-116.75824, 45.11075, 0],
					[-116.75812, 45.11087, 0],
					[-116.75778, 45.11107, 0],
					[-116.75751, 45.11118, 0],
					[-116.7575, 45.11119, 0],
					[-116.75711, 45.11132, 0],
					[-116.75695, 45.11139, 0],
					[-116.75645, 45.1117, 0],
					[-116.75623, 45.1118, 0],
					[-116.75606, 45.11183, 0],
					[-116.75582, 45.11183, 0],
					[-116.75557, 45.11179, 0],
					[-116.7554, 45.11178, 0],
					[-116.75529, 45.1118, 0],
					[-116.75504, 45.11188, 0],
					[-116.75392, 45.11246, 0],
					[-116.75317, 45.11311, 0],
					[-116.75292, 45.11337, 0],
					[-116.75275, 45.11364, 0],
					[-116.75262, 45.11379, 0],
					[-116.7526, 45.11385, 0],
					[-116.7526, 45.11393, 0],
					[-116.75265, 45.11418, 0],
					[-116.75271, 45.11438, 0],
					[-116.75271, 45.11446, 0],
					[-116.75269, 45.11457, 0],
					[-116.75261, 45.11469, 0],
					[-116.7525, 45.11481, 0],
					[-116.75224, 45.11505, 0],
					[-116.75191, 45.11531, 0],
					[-116.75168, 45.11553, 0],
					[-116.75158, 45.11561, 0],
					[-116.75145, 45.11576, 0],
					[-116.75137, 45.11591, 0],
					[-116.75137, 45.11593, 0],
					[-116.75134, 45.11601, 0],
					[-116.75126, 45.11647, 0],
					[-116.75119, 45.11663, 0],
					[-116.75118, 45.11664, 0],
					[-116.75112, 45.11698, 0],
					[-116.7509, 45.11752, 0],
					[-116.75089, 45.11753, 0],
					[-116.75085, 45.11769, 0],
					[-116.75085, 45.1177, 0],
					[-116.75078, 45.11785, 0],
					[-116.75077, 45.11786, 0],
					[-116.75074, 45.11794, 0],
					[-116.75046, 45.11827, 0],
					[-116.75019, 45.11865, 0],
					[-116.75005, 45.11881, 0],
					[-116.74998, 45.11886, 0],
					[-116.7499, 45.1189, 0],
					[-116.74979, 45.11894, 0],
					[-116.74967, 45.11896, 0],
					[-116.7496, 45.11896, 0],
					[-116.74875, 45.11904, 0],
					[-116.74847, 45.11911, 0],
					[-116.74837, 45.11915, 0],
					[-116.7482, 45.11924, 0],
					[-116.74809, 45.11933, 0],
					[-116.74797, 45.11945, 0],
					[-116.7479, 45.11957, 0],
					[-116.74789, 45.11958, 0],
					[-116.74783, 45.11974, 0],
					[-116.74779, 45.11993, 0],
					[-116.74771, 45.12009, 0],
					[-116.74759, 45.12024, 0],
					[-116.74746, 45.12034, 0],
					[-116.74735, 45.12041, 0],
					[-116.74509, 45.12125, 0],
					[-116.74483, 45.12137, 0],
					[-116.74466, 45.12147, 0],
					[-116.74458, 45.12156, 0],
					[-116.74451, 45.12162, 0],
					[-116.74437, 45.12181, 0],
					[-116.74423, 45.12196, 0],
					[-116.74414, 45.12202, 0],
					[-116.74397, 45.1221, 0],
					[-116.7436, 45.12223, 0],
					[-116.74344, 45.12227, 0],
					[-116.74317, 45.12239, 0],
					[-116.74307, 45.12246, 0],
					[-116.74296, 45.12257, 0],
					[-116.74275, 45.1229, 0],
					[-116.7426, 45.1231, 0],
					[-116.74241, 45.12332, 0],
					[-116.74225, 45.12345, 0],
					[-116.74057, 45.12458, 0],
					[-116.74054, 45.12459, 0],
					[-116.74009, 45.12492, 0],
					[-116.73938, 45.12551, 0],
					[-116.73919, 45.1257, 0],
					[-116.73905, 45.12582, 0],
					[-116.73887, 45.12601, 0],
					[-116.7381, 45.12696, 0],
					[-116.73779, 45.12744, 0],
					[-116.7372, 45.12869, 0],
					[-116.73672, 45.13035, 0],
					[-116.73649, 45.13089, 0],
					[-116.73636, 45.13111, 0],
					[-116.73633, 45.13118, 0],
					[-116.73598, 45.13167, 0],
					[-116.73543, 45.13228, 0],
					[-116.73514, 45.13269, 0],
					[-116.73498, 45.13296, 0],
					[-116.73487, 45.13319, 0],
					[-116.73483, 45.13325, 0],
					[-116.73477, 45.13341, 0],
					[-116.7347, 45.13353, 0],
					[-116.7347, 45.13354, 0],
					[-116.73458, 45.13369, 0],
					[-116.73443, 45.13383, 0],
					[-116.73441, 45.13384, 0],
					[-116.73407, 45.13414, 0],
					[-116.73391, 45.13437, 0],
					[-116.73374, 45.13473, 0],
					[-116.73371, 45.13477, 0],
					[-116.73357, 45.13503, 0],
					[-116.73336, 45.13525, 0],
					[-116.73303, 45.13552, 0],
					[-116.73302, 45.13554, 0],
					[-116.73287, 45.13566, 0],
					[-116.7328, 45.13574, 0],
					[-116.73274, 45.13584, 0],
					[-116.73271, 45.136, 0],
					[-116.73271, 45.13604, 0],
					[-116.73273, 45.13618, 0],
					[-116.73273, 45.13629, 0],
					[-116.73271, 45.13642, 0],
					[-116.73264, 45.13658, 0],
					[-116.73256, 45.13666, 0],
					[-116.73242, 45.13675, 0],
					[-116.73203, 45.13694, 0],
					[-116.73181, 45.13709, 0],
					[-116.73144, 45.13743, 0],
					[-116.73061, 45.13811, 0],
					[-116.7305, 45.13822, 0],
					[-116.73022, 45.13855, 0],
					[-116.7302, 45.13856, 0],
					[-116.73008, 45.1387, 0],
					[-116.72986, 45.13888, 0],
					[-116.72894, 45.13944, 0],
					[-116.72868, 45.13962, 0],
					[-116.72859, 45.1397, 0],
					[-116.72841, 45.13989, 0],
					[-116.72785, 45.14065, 0],
					[-116.72783, 45.14067, 0],
					[-116.72765, 45.14092, 0],
					[-116.72761, 45.14096, 0],
					[-116.7273, 45.14138, 0],
					[-116.72708, 45.14178, 0],
					[-116.72706, 45.14184, 0],
					[-116.72692, 45.14212, 0],
					[-116.72691, 45.14213, 0],
					[-116.72681, 45.14247, 0],
					[-116.72677, 45.14265, 0],
					[-116.7267, 45.14317, 0],
					[-116.72669, 45.14355, 0],
					[-116.72679, 45.14413, 0],
					[-116.72683, 45.14428, 0],
					[-116.72703, 45.14478, 0],
					[-116.72705, 45.14489, 0],
					[-116.72704, 45.14496, 0],
					[-116.727, 45.14506, 0],
					[-116.72668, 45.1454, 0],
					[-116.72667, 45.14543, 0],
					[-116.72658, 45.14556, 0],
					[-116.7265, 45.14585, 0],
					[-116.7265, 45.14599, 0],
					[-116.72652, 45.1461, 0],
					[-116.72657, 45.14623, 0],
					[-116.72684, 45.14658, 0],
					[-116.7269, 45.14668, 0],
					[-116.72693, 45.14678, 0],
					[-116.72694, 45.14684, 0],
					[-116.72692, 45.14703, 0],
					[-116.72679, 45.14768, 0],
					[-116.72674, 45.14783, 0],
					[-116.72659, 45.14817, 0],
					[-116.72657, 45.14819, 0],
					[-116.72644, 45.14846, 0],
					[-116.72637, 45.14856, 0],
					[-116.72625, 45.14869, 0],
					[-116.72593, 45.14895, 0],
					[-116.72593, 45.14896, 0],
					[-116.72585, 45.14905, 0],
					[-116.72582, 45.14912, 0],
					[-116.72579, 45.14916, 0],
					[-116.72574, 45.14927, 0],
					[-116.72574, 45.14929, 0],
					[-116.72555, 45.14975, 0],
					[-116.72543, 45.15025, 0],
					[-116.72538, 45.15065, 0],
					[-116.72539, 45.15083, 0],
					[-116.72545, 45.15102, 0],
					[-116.72553, 45.15122, 0],
					[-116.72553, 45.15123, 0],
					[-116.72561, 45.1515, 0],
					[-116.72561, 45.15172, 0],
					[-116.7256, 45.15176, 0],
					[-116.72537, 45.15231, 0],
					[-116.72531, 45.15258, 0],
					[-116.72534, 45.15353, 0],
					[-116.72524, 45.15459, 0],
					[-116.72519, 45.1547, 0],
					[-116.72502, 45.15493, 0],
					[-116.72502, 45.15494, 0],
					[-116.72498, 45.155, 0],
					[-116.72497, 45.1551, 0],
					[-116.72499, 45.15522, 0],
					[-116.72506, 45.15537, 0],
					[-116.7251, 45.15552, 0],
					[-116.72509, 45.15569, 0],
					[-116.72506, 45.15581, 0],
					[-116.72495, 45.156, 0],
					[-116.72474, 45.15618, 0],
					[-116.72449, 45.15633, 0],
					[-116.72417, 45.15657, 0],
					[-116.7238, 45.15701, 0],
					[-116.72371, 45.1571, 0],
					[-116.72352, 45.15734, 0],
					[-116.72326, 45.15775, 0],
					[-116.72303, 45.15806, 0],
					[-116.72303, 45.15807, 0],
					[-116.72271, 45.15835, 0],
					[-116.72217, 45.1589, 0],
					[-116.72204, 45.15918, 0],
					[-116.72184, 45.15944, 0],
					[-116.72182, 45.15945, 0],
					[-116.72158, 45.15974, 0],
					[-116.72146, 45.15991, 0],
					[-116.72134, 45.16016, 0],
					[-116.72121, 45.16034, 0],
					[-116.72104, 45.16051, 0],
					[-116.721, 45.16054, 0],
					[-116.72085, 45.1607, 0],
					[-116.72075, 45.16085, 0],
					[-116.72069, 45.16107, 0],
					[-116.72069, 45.16115, 0],
					[-116.72072, 45.16132, 0],
					[-116.72079, 45.16152, 0],
					[-116.72081, 45.16163, 0],
					[-116.72079, 45.16173, 0],
					[-116.72067, 45.1619, 0],
					[-116.7202, 45.16237, 0],
					[-116.72013, 45.16246, 0],
					[-116.72011, 45.16255, 0],
					[-116.72007, 45.16267, 0],
					[-116.72007, 45.16272, 0],
					[-116.72009, 45.16283, 0],
					[-116.72015, 45.16298, 0],
					[-116.72024, 45.16315, 0],
					[-116.72029, 45.16322, 0],
					[-116.72035, 45.16338, 0],
					[-116.72041, 45.1636, 0],
					[-116.72053, 45.16388, 0],
					[-116.72053, 45.16389, 0],
					[-116.72058, 45.16406, 0],
					[-116.7206, 45.16424, 0],
					[-116.72059, 45.16442, 0],
					[-116.72049, 45.16495, 0],
					[-116.72046, 45.16531, 0],
					[-116.72045, 45.16567, 0],
					[-116.72046, 45.16581, 0],
					[-116.72053, 45.16609, 0],
					[-116.72053, 45.16621, 0],
					[-116.7205, 45.16643, 0],
					[-116.72059, 45.16673, 0],
					[-116.72059, 45.16694, 0],
					[-116.72044, 45.16763, 0],
					[-116.72044, 45.16766, 0],
					[-116.72039, 45.16786, 0],
					[-116.72037, 45.16801, 0],
					[-116.72037, 45.16808, 0],
					[-116.72039, 45.16817, 0],
					[-116.72051, 45.16846, 0],
					[-116.72056, 45.16874, 0],
					[-116.72058, 45.16881, 0],
					[-116.72068, 45.16904, 0],
					[-116.72072, 45.1691, 0],
					[-116.72078, 45.16936, 0],
					[-116.72101, 45.16995, 0],
					[-116.72104, 45.17009, 0],
					[-116.72103, 45.17023, 0],
					[-116.72097, 45.17033, 0],
					[-116.7209, 45.17042, 0],
					[-116.72085, 45.17054, 0],
					[-116.72086, 45.1706, 0],
					[-116.72086, 45.17064, 0],
					[-116.72089, 45.17071, 0],
					[-116.72103, 45.17082, 0],
					[-116.72116, 45.17089, 0],
					[-116.72132, 45.17101, 0],
					[-116.72132, 45.17102, 0],
					[-116.72137, 45.17108, 0],
					[-116.72142, 45.17117, 0],
					[-116.72143, 45.1713, 0],
					[-116.7214, 45.17146, 0],
					[-116.72125, 45.17169, 0],
					[-116.72124, 45.17176, 0],
					[-116.72125, 45.17189, 0],
					[-116.72129, 45.17207, 0],
					[-116.72131, 45.17222, 0],
					[-116.72132, 45.17223, 0],
					[-116.72126, 45.1724, 0],
					[-116.72126, 45.17242, 0],
					[-116.72111, 45.17254, 0],
					[-116.7208, 45.17274, 0],
					[-116.72064, 45.17279, 0],
					[-116.72047, 45.17279, 0],
					[-116.72026, 45.17275, 0],
					[-116.72022, 45.17276, 0],
					[-116.72015, 45.17276, 0],
					[-116.72004, 45.17278, 0],
					[-116.71993, 45.17285, 0],
					[-116.71985, 45.17293, 0],
					[-116.71979, 45.17306, 0],
					[-116.71977, 45.17326, 0],
					[-116.71978, 45.17334, 0],
					[-116.71976, 45.17339, 0],
					[-116.71976, 45.17345, 0],
					[-116.71982, 45.17359, 0],
					[-116.71994, 45.17375, 0],
					[-116.72, 45.17381, 0],
					[-116.72014, 45.17398, 0],
					[-116.72015, 45.17406, 0],
					[-116.72014, 45.17414, 0],
					[-116.72011, 45.17423, 0],
					[-116.72005, 45.17432, 0],
					[-116.71976, 45.17456, 0],
					[-116.71972, 45.17458, 0],
					[-116.71954, 45.17471, 0],
					[-116.71933, 45.17481, 0],
					[-116.71932, 45.17482, 0],
					[-116.71922, 45.17487, 0],
					[-116.71914, 45.17493, 0],
					[-116.71903, 45.17507, 0],
					[-116.71894, 45.17522, 0],
					[-116.71879, 45.17555, 0],
					[-116.71847, 45.17647, 0],
					[-116.71841, 45.17701, 0],
					[-116.7184, 45.17702, 0],
					[-116.71837, 45.17719, 0],
					[-116.71837, 45.17721, 0],
					[-116.71832, 45.1773, 0],
					[-116.71822, 45.17744, 0],
					[-116.71801, 45.17766, 0],
					[-116.71787, 45.17777, 0],
					[-116.71733, 45.17829, 0],
					[-116.71687, 45.17886, 0],
					[-116.71674, 45.17899, 0],
					[-116.71663, 45.17907, 0],
					[-116.71649, 45.17913, 0],
					[-116.7163, 45.17918, 0],
					[-116.71597, 45.17924, 0],
					[-116.71589, 45.17929, 0],
					[-116.7158, 45.17933, 0],
					[-116.7157, 45.17942, 0],
					[-116.71563, 45.17953, 0],
					[-116.71557, 45.17967, 0],
					[-116.71549, 45.18004, 0],
					[-116.71546, 45.18024, 0],
					[-116.71546, 45.18039, 0],
					[-116.71549, 45.18062, 0],
					[-116.71549, 45.1808, 0],
					[-116.71546, 45.18095, 0],
					[-116.71537, 45.1812, 0],
					[-116.71537, 45.18122, 0],
					[-116.71523, 45.18149, 0],
					[-116.71474, 45.1822, 0],
					[-116.71468, 45.18234, 0],
					[-116.71459, 45.18268, 0],
					[-116.71458, 45.18366, 0],
					[-116.71455, 45.1838, 0],
					[-116.71446, 45.18407, 0],
					[-116.71444, 45.18419, 0],
					[-116.71446, 45.18426, 0],
					[-116.71449, 45.18433, 0],
					[-116.71449, 45.18434, 0],
					[-116.71466, 45.18454, 0],
					[-116.71476, 45.18464, 0],
					[-116.7148, 45.18467, 0],
					[-116.71486, 45.18476, 0],
					[-116.71488, 45.18486, 0],
					[-116.71488, 45.18498, 0],
					[-116.71485, 45.18516, 0],
					[-116.71475, 45.18551, 0],
					[-116.71465, 45.18574, 0],
					[-116.71456, 45.18584, 0],
					[-116.71455, 45.18584, 0],
					[-116.71443, 45.18597, 0],
					[-116.71442, 45.18599, 0],
					[-116.71434, 45.18608, 0],
					[-116.71427, 45.18621, 0],
					[-116.71416, 45.18661, 0],
					[-116.71412, 45.18683, 0],
					[-116.71416, 45.18739, 0],
					[-116.71414, 45.18779, 0],
					[-116.71411, 45.18803, 0],
					[-116.71403, 45.18829, 0],
					[-116.71392, 45.18848, 0],
					[-116.71386, 45.18861, 0],
					[-116.71383, 45.18875, 0],
					[-116.71383, 45.18885, 0],
					[-116.71385, 45.18898, 0],
					[-116.7139, 45.18909, 0],
					[-116.71391, 45.18928, 0],
					[-116.71386, 45.18943, 0],
					[-116.71369, 45.18961, 0],
					[-116.71355, 45.1898, 0],
					[-116.71355, 45.18981, 0],
					[-116.71352, 45.18987, 0],
					[-116.7135, 45.18996, 0],
					[-116.7135, 45.19008, 0],
					[-116.71353, 45.19028, 0],
					[-116.71352, 45.19035, 0],
					[-116.71352, 45.19041, 0],
					[-116.71349, 45.19057, 0],
					[-116.71326, 45.19114, 0],
					[-116.71318, 45.19139, 0],
					[-116.71318, 45.1914, 0],
					[-116.71284, 45.19274, 0],
					[-116.71275, 45.19299, 0],
					[-116.71253, 45.19339, 0],
					[-116.71239, 45.19355, 0],
					[-116.71228, 45.1937, 0],
					[-116.71218, 45.19388, 0],
					[-116.71213, 45.19404, 0],
					[-116.71205, 45.19422, 0],
					[-116.712, 45.1945, 0],
					[-116.71198, 45.19456, 0],
					[-116.71197, 45.1947, 0],
					[-116.71191, 45.19487, 0],
					[-116.71159, 45.19534, 0],
					[-116.71137, 45.1957, 0],
					[-116.71108, 45.19603, 0],
					[-116.71094, 45.19621, 0],
					[-116.71081, 45.19647, 0],
					[-116.71062, 45.19677, 0],
					[-116.71046, 45.19694, 0],
					[-116.71046, 45.19695, 0],
					[-116.71015, 45.19723, 0],
					[-116.71003, 45.1974, 0],
					[-116.70999, 45.19757, 0],
					[-116.70999, 45.19764, 0],
					[-116.70993, 45.1978, 0],
					[-116.70959, 45.19821, 0],
					[-116.7094, 45.19841, 0],
					[-116.70929, 45.19848, 0],
					[-116.70914, 45.19853, 0],
					[-116.70901, 45.19853, 0],
					[-116.70899, 45.19854, 0],
					[-116.70876, 45.19853, 0],
					[-116.70863, 45.19854, 0],
					[-116.70845, 45.19859, 0],
					[-116.70836, 45.19864, 0],
					[-116.70832, 45.19868, 0],
					[-116.70831, 45.19868, 0],
					[-116.70825, 45.19876, 0],
					[-116.70822, 45.19884, 0],
					[-116.70822, 45.19895, 0],
					[-116.70821, 45.19903, 0],
					[-116.70823, 45.19963, 0],
					[-116.70821, 45.19992, 0],
					[-116.70815, 45.2001, 0],
					[-116.70811, 45.20019, 0],
					[-116.70812, 45.2003, 0],
					[-116.70817, 45.20045, 0],
					[-116.70818, 45.20046, 0],
					[-116.7082, 45.20059, 0],
					[-116.70819, 45.20072, 0],
					[-116.70812, 45.20098, 0],
					[-116.70812, 45.20099, 0],
					[-116.70808, 45.20113, 0],
					[-116.70799, 45.20127, 0],
					[-116.70792, 45.20133, 0],
					[-116.70761, 45.2015, 0],
					[-116.70753, 45.20156, 0],
					[-116.70747, 45.20165, 0],
					[-116.70744, 45.20171, 0],
					[-116.70736, 45.20202, 0],
					[-116.70735, 45.20217, 0],
					[-116.70733, 45.20224, 0],
					[-116.70732, 45.20231, 0],
					[-116.70729, 45.20242, 0],
					[-116.70729, 45.20243, 0],
					[-116.70722, 45.20261, 0],
					[-116.70703, 45.20292, 0],
					[-116.70693, 45.20311, 0],
					[-116.70682, 45.20355, 0],
					[-116.70665, 45.20405, 0],
					[-116.7066, 45.20433, 0],
					[-116.70654, 45.20454, 0],
					[-116.70644, 45.20478, 0],
					[-116.70639, 45.20496, 0],
					[-116.70634, 45.20529, 0],
					[-116.70631, 45.20538, 0],
					[-116.7063, 45.20538, 0],
					[-116.70619, 45.20559, 0],
					[-116.70601, 45.20587, 0],
					[-116.70601, 45.20588, 0],
					[-116.70598, 45.20595, 0],
					[-116.70599, 45.20603, 0],
					[-116.70603, 45.20614, 0],
					[-116.70608, 45.20623, 0],
					[-116.70624, 45.2064, 0],
					[-116.70631, 45.2065, 0],
					[-116.70635, 45.20664, 0],
					[-116.70636, 45.20675, 0],
					[-116.70634, 45.20689, 0],
					[-116.70633, 45.2069, 0],
					[-116.7062, 45.20723, 0],
					[-116.70616, 45.20741, 0],
					[-116.70615, 45.2076, 0],
					[-116.70622, 45.20785, 0],
					[-116.7065, 45.20845, 0],
					[-116.70651, 45.20846, 0],
					[-116.70667, 45.20873, 0],
					[-116.70688, 45.20894, 0],
					[-116.70696, 45.20905, 0],
					[-116.70701, 45.20916, 0],
					[-116.70703, 45.20927, 0],
					[-116.707, 45.20945, 0],
					[-116.70698, 45.20952, 0],
					[-116.70697, 45.2096, 0],
					[-116.70699, 45.20989, 0],
					[-116.70698, 45.20999, 0],
					[-116.7069, 45.21034, 0],
					[-116.7069, 45.21037, 0],
					[-116.70687, 45.21052, 0],
					[-116.70687, 45.2107, 0],
					[-116.70688, 45.21072, 0],
					[-116.70696, 45.21101, 0],
					[-116.70698, 45.21105, 0],
					[-116.70711, 45.2115, 0],
					[-116.70722, 45.21212, 0],
					[-116.70723, 45.2125, 0],
					[-116.70722, 45.21272, 0],
					[-116.70724, 45.21284, 0],
					[-116.70732, 45.21301, 0],
					[-116.70741, 45.21331, 0],
					[-116.70748, 45.21384, 0],
					[-116.7075, 45.21431, 0],
					[-116.70768, 45.21476, 0],
					[-116.70785, 45.21512, 0],
					[-116.708, 45.21553, 0],
					[-116.70801, 45.21565, 0],
					[-116.70801, 45.21601, 0],
					[-116.70804, 45.21655, 0],
					[-116.70803, 45.21691, 0],
					[-116.70792, 45.21736, 0],
					[-116.7077, 45.218, 0],
					[-116.70749, 45.2184, 0],
					[-116.70748, 45.21841, 0],
					[-116.70739, 45.21857, 0],
					[-116.70696, 45.21923, 0],
					[-116.70666, 45.2195, 0],
					[-116.70652, 45.21961, 0],
					[-116.70638, 45.2197, 0],
					[-116.70626, 45.21984, 0],
					[-116.70615, 45.22001, 0],
					[-116.70601, 45.22046, 0],
					[-116.70593, 45.22065, 0],
					[-116.70575, 45.22121, 0],
					[-116.70533, 45.22235, 0],
					[-116.70523, 45.22255, 0],
					[-116.7052, 45.22259, 0],
					[-116.70506, 45.22288, 0],
					[-116.70417, 45.22416, 0],
					[-116.70403, 45.22442, 0],
					[-116.70387, 45.22482, 0],
					[-116.70378, 45.22512, 0],
					[-116.70365, 45.22541, 0],
					[-116.70346, 45.22572, 0],
					[-116.70329, 45.22594, 0],
					[-116.70304, 45.22633, 0],
					[-116.70292, 45.22658, 0],
					[-116.70287, 45.22679, 0],
					[-116.7028, 45.22732, 0],
					[-116.7027, 45.22761, 0],
					[-116.70253, 45.22788, 0],
					[-116.70248, 45.22807, 0],
					[-116.70248, 45.22836, 0],
					[-116.70254, 45.22903, 0],
					[-116.7025, 45.22936, 0],
					[-116.70242, 45.22961, 0],
					[-116.70217, 45.23001, 0],
					[-116.70209, 45.23018, 0],
					[-116.70204, 45.23035, 0],
					[-116.70202, 45.23039, 0],
					[-116.70199, 45.23049, 0],
					[-116.70197, 45.2306, 0],
					[-116.70197, 45.23074, 0],
					[-116.702, 45.23106, 0],
					[-116.70208, 45.23146, 0],
					[-116.70206, 45.23182, 0],
					[-116.7019, 45.23216, 0],
					[-116.70179, 45.23234, 0],
					[-116.70173, 45.2325, 0],
					[-116.70172, 45.23251, 0],
					[-116.70169, 45.23261, 0],
					[-116.70171, 45.23277, 0],
					[-116.70188, 45.23314, 0],
					[-116.702, 45.23345, 0],
					[-116.702, 45.23354, 0],
					[-116.70203, 45.23385, 0],
					[-116.70211, 45.23432, 0],
					[-116.70221, 45.2346, 0],
					[-116.70225, 45.23479, 0],
					[-116.70225, 45.23482, 0],
					[-116.70221, 45.235, 0],
					[-116.70199, 45.23531, 0],
					[-116.70191, 45.23546, 0],
					[-116.70184, 45.23563, 0],
					[-116.70165, 45.23619, 0],
					[-116.70151, 45.2368, 0],
					[-116.70151, 45.23703, 0],
					[-116.70152, 45.23704, 0],
					[-116.70157, 45.23722, 0],
					[-116.70158, 45.23724, 0],
					[-116.7016, 45.23742, 0],
					[-116.70156, 45.23753, 0],
					[-116.70119, 45.23774, 0],
					[-116.7011, 45.23784, 0],
					[-116.70108, 45.23798, 0],
					[-116.70115, 45.2382, 0],
					[-116.70132, 45.2384, 0],
					[-116.70138, 45.23854, 0],
					[-116.70137, 45.23872, 0],
					[-116.70131, 45.23882, 0],
					[-116.70124, 45.23891, 0],
					[-116.70044, 45.23961, 0],
					[-116.70029, 45.23976, 0],
					[-116.70014, 45.23996, 0],
					[-116.70013, 45.23998, 0],
					[-116.70009, 45.24016, 0],
					[-116.70008, 45.24018, 0],
					[-116.70005, 45.24044, 0],
					[-116.70008, 45.24088, 0],
					[-116.70002, 45.24124, 0],
					[-116.70001, 45.24126, 0],
					[-116.69998, 45.24179, 0],
					[-116.70004, 45.24186, 0],
					[-116.70008, 45.24193, 0],
					[-116.70009, 45.24204, 0],
					[-116.70008, 45.24211, 0],
					[-116.70011, 45.24218, 0],
					[-116.70013, 45.24221, 0],
				],
			},
			id: "86cd5e29-e6eb-4279-b976-7e5546c42965",
		},
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Marker",
		// 		name: "Cambridge, Idaho",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.676723, 44.57175, 0],
		// 	},
		// 	id: "de4855b6-5337-4227-82ff-d3b1e4217657",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Marker",
		// 		name: "Hells Canyon Dam",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.7001286, 45.2422077, 0],
		// 	},
		// 	id: "16754bf1-e63e-4b1e-bcb1-9935185dca10",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Brownlee Dam",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.900701, 44.837187],
		// 	},
		// 	id: "15390ea7-9947-4fec-885b-524f3ffb50e9",
		// },
	],
};

export default HellsCanyon;
