import { useDispatch } from "react-redux";
import { startTutorial } from "../../../store/actions/tutorialActions";
import InterstateIcon from "../../../images/icons/interstate";
import HighwayIcon from "../../../images/icons/highway";


const UserGuide = () => {
    const dispatch = useDispatch();
      
    const toggleTutorial = () => {
        //console.log('toggle tutorial');
        dispatch(startTutorial({ active: true}));
    }

    return (
        <div>
            <h2 style={{marginTop:"0.5rem"}}>Legend</h2>
            <div>

            <div className="guide-boxes">
                    <div className="guide-box box-2">
                        <h3>Interstates & Highways</h3>
                        <div>
                            <InterstateIcon />
                            <HighwayIcon />
                        </div>
                    </div>
                    <div className="guide-box box-1">
                        <p>
                        These icons indicate specific interstates and highways.
                        </p>
                    </div>
                </div>

                <div className="guide-boxes">
                    <div className="guide-box box-2">
                        <h3>Byways</h3>
                        <p className="line-color"></p>
                    </div>
                    <div className="guide-box box-1">
                        <p>
                        When selected, scenic byways are shown on the map as an orange line. Learn more about a specific byway by clicking on it on the map.
                        </p>
                    </div>
                </div>

                <div className="guide-boxes">
                <div className="guide-box box-2">
                    <h3>Marker Clusters</h3>
                    <p
                    className="leaflet-marker-icon custom-marker-cluster leaflet-zoom-animated leaflet-interactive marker-cluster"
                    tabIndex="0"
                    role="button"
                    >
                    <span>5</span>
                    </p>
                </div>
                <div className="guide-box box-1">
                    <p>
                    These numbers designate how many activity markers are in close proximity in an area. Zoom in on the map to expand the activities.
                    </p>
                </div>
                </div>


            </div>
            <br />
            <hr />
            <br />
            <div>
                <h2 style={{marginTop:".5rem"}}>Need More Help?</h2>
                <a className="btn btn-green" onClick={toggleTutorial.bind(this)}>View Tutorial</a>
            </div>
            <br />
            <hr />
            <br />
            <div>
            <h2 style={{marginTop:".5rem"}}>Resources</h2>
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:".25rem"}}>
                    <a target="_blank" href="https://visitidaho.org/">Visit Idaho</a>
                    <a target="_blank" href="https://511.idaho.gov/#:Alerts">Idaho 511</a>
                    <a target="_blank" href="https://visitidaho.org/regions/">Idaho's Regions</a>
                    <a target="_blank" href="https://visitidaho.org/things-to-do/scenic-byways/">Scenic Byways</a>
                    <a target="_blank" href="https://visitidaho.org/travel-with-care/">Travel With Care</a>
                    <a target="_blank" href="https://recreate.idaho.gov/">Recreate Responsibly</a>
                </div>
            </div>

            <br />
            
        </div>
    );
}
export default UserGuide;