import { createReducer } from "@reduxjs/toolkit";
import { updateByways } from "../actions/bywaysActions";

const initialState = {
    selectedByways: []
}

const bywaysReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(updateByways, (state, action) => {
            state.selectedByways = action.payload;
        })
});

export default bywaysReducer;