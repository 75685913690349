const LewisClark = {
    type: "FeatureCollection",
    features: [{
        type: "Feature",
        properties: {
            name: "Lewis and Clark Backcountry Byway",
            "description": "Take a drive on the breathtaking Lewis and Clark Backcountry Byway. This remote route recognizes the area where Meriwether Lewis and William Clark began their explorations of the Pacific Northwest. Visitors will find that many of the forests and meadows on this drive look much the same as when these explorers journeyed to the area in 1805.",
            "link": "https://visitidaho.org/things-to-do/road-trips/lewis-and-clark-backcountry-byway/",
            "route": [
                "Tendoy"
            ],
            "shape": "Line"
        },
        geometry: {
            type: "LineString",
            coordinates: [
                [-113.64441, 44.95982, 0],
                [-113.64074, 44.95992, 0],
                [-113.64082, 44.96056, 0],
                [-113.64083, 44.96127, 0],
                [-113.64077, 44.96221, 0],
                [-113.64023, 44.96525, 0],
                [-113.64006, 44.96598, 0],
                [-113.63975, 44.96685, 0],
                [-113.63957, 44.96767, 0],
                [-113.63942, 44.96887, 0],
                [-113.63866, 44.97217, 0],
                [-113.63853, 44.97301, 0],
                [-113.6385, 44.97389, 0],
                [-113.63875, 44.97618, 0],
                [-113.63938, 44.98008, 0],
                [-113.63948, 44.98034, 0],
                [-113.63959, 44.98097, 0],
                [-113.6396, 44.98098, 0],
                [-113.63968, 44.98146, 0],
                [-113.63992, 44.9819, 0],
                [-113.64021, 44.98231, 0],
                [-113.64035, 44.98259, 0],
                [-113.6405, 44.98315, 0],
                [-113.64048, 44.9834, 0],
                [-113.63938, 44.98979, 0],
                [-113.63929, 44.98998, 0],
                [-113.63929, 44.99009, 0],
                [-113.63917, 44.99085, 0],
                [-113.63899, 44.99136, 0],
                [-113.63892, 44.9915, 0],
                [-113.63886, 44.99181, 0],
                [-113.63886, 44.992, 0],
                [-113.6389, 44.99227, 0],
                [-113.63902, 44.99271, 0],
                [-113.63913, 44.99328, 0],
                [-113.63912, 44.99353, 0],
                [-113.63895, 44.99448, 0],
                [-113.63892, 44.99497, 0],
                [-113.63893, 44.99506, 0],
                [-113.63909, 44.99541, 0],
                [-113.63991, 44.99688, 0],
                [-113.64052, 44.99773, 0],
                [-113.64084, 44.99811, 0],
                [-113.641, 44.99834, 0],
                [-113.64154, 44.99954, 0],
                [-113.64129, 44.99965, 0],
                [-113.64122, 44.99976, 0],
                [-113.64124, 44.99994, 0],
                [-113.6417, 45.00107, 0],
                [-113.64168, 45.00136, 0],
                [-113.64152, 45.00156, 0],
                [-113.64122, 45.00168, 0],
                [-113.64085, 45.0017, 0],
                [-113.63823, 45.00159, 0],
                [-113.63786, 45.00164, 0],
                [-113.63707, 45.00166, 0],
                [-113.63529, 45.00142, 0],
                [-113.63488, 45.00143, 0],
                [-113.63444, 45.00148, 0],
                [-113.63422, 45.00163, 0],
                [-113.63401, 45.00188, 0],
                [-113.63391, 45.00216, 0],
                [-113.63379, 45.00236, 0],
                [-113.63347, 45.00262, 0],
                [-113.63235, 45.00333, 0],
                [-113.63181, 45.00356, 0],
                [-113.62979, 45.00405, 0],
                [-113.62925, 45.00415, 0],
                [-113.62885, 45.00431, 0],
                [-113.62837, 45.00455, 0],
                [-113.6279, 45.00472, 0],
                [-113.62671, 45.00499, 0],
                [-113.62565, 45.00512, 0],
                [-113.62354, 45.00494, 0],
                [-113.62333, 45.00502, 0],
                [-113.62316, 45.00523, 0],
                [-113.62312, 45.00552, 0],
                [-113.62304, 45.00583, 0],
                [-113.62287, 45.00606, 0],
                [-113.62265, 45.00625, 0],
                [-113.62225, 45.0065, 0],
                [-113.62207, 45.00667, 0],
                [-113.62203, 45.00684, 0],
                [-113.62204, 45.007, 0],
                [-113.62218, 45.00719, 0],
                [-113.62241, 45.00735, 0],
                [-113.62278, 45.00767, 0],
                [-113.62288, 45.0078, 0],
                [-113.62292, 45.00814, 0],
                [-113.62279, 45.00837, 0],
                [-113.62266, 45.00852, 0],
                [-113.62244, 45.00865, 0],
                [-113.62171, 45.00898, 0],
                [-113.62138, 45.00917, 0],
                [-113.62077, 45.00958, 0],
                [-113.62043, 45.00977, 0],
                [-113.62016, 45.00984, 0],
                [-113.61879, 45.00998, 0],
                [-113.61639, 45.01037, 0],
                [-113.61582, 45.0104, 0],
                [-113.61453, 45.01056, 0],
                [-113.6135, 45.01064, 0],
                [-113.6133, 45.01085, 0],
                [-113.61317, 45.0109, 0],
                [-113.6127, 45.01093, 0],
                [-113.61163, 45.01085, 0],
                [-113.61118, 45.01092, 0],
                [-113.6108, 45.01108, 0],
                [-113.61009, 45.0115, 0],
                [-113.60958, 45.01172, 0],
                [-113.60862, 45.01241, 0],
                [-113.60802, 45.01292, 0],
                [-113.60712, 45.01333, 0],
                [-113.6068, 45.01342, 0],
                [-113.60607, 45.01346, 0],
                [-113.60442, 45.01406, 0],
                [-113.60366, 45.01462, 0],
                [-113.60329, 45.01493, 0],
                [-113.60298, 45.01504, 0],
                [-113.60187, 45.01527, 0],
                [-113.6016, 45.0154, 0],
                [-113.60092, 45.01631, 0],
                [-113.60064, 45.01679, 0],
                [-113.60044, 45.01692, 0],
                [-113.60027, 45.01696, 0],
                [-113.59995, 45.01693, 0],
                [-113.59971, 45.01684, 0],
                [-113.5985, 45.01686, 0],
                [-113.59834, 45.01682, 0],
                [-113.59705, 45.01637, 0],
                [-113.59656, 45.01613, 0],
                [-113.59587, 45.01593, 0],
                [-113.59565, 45.01593, 0],
                [-113.59539, 45.01602, 0],
                [-113.59528, 45.01619, 0],
                [-113.59529, 45.01634, 0],
                [-113.59625, 45.01717, 0],
                [-113.59648, 45.01729, 0],
                [-113.59717, 45.01739, 0],
                [-113.59718, 45.0174, 0],
                [-113.59735, 45.01748, 0],
                [-113.59749, 45.01759, 0],
                [-113.59756, 45.01793, 0],
                [-113.59754, 45.01845, 0],
                [-113.59745, 45.01872, 0],
                [-113.59743, 45.01875, 0],
                [-113.59735, 45.01896, 0],
                [-113.5973, 45.01918, 0],
                [-113.59739, 45.01928, 0],
                [-113.59756, 45.01931, 0],
                [-113.59784, 45.01932, 0],
                [-113.59816, 45.01941, 0],
                [-113.59834, 45.01955, 0],
                [-113.59843, 45.01966, 0],
                [-113.59851, 45.01989, 0],
                [-113.59846, 45.02019, 0],
                [-113.59815, 45.02069, 0],
                [-113.59811, 45.02101, 0],
                [-113.59812, 45.0214, 0],
                [-113.59774, 45.0218, 0],
                [-113.5974, 45.02198, 0],
                [-113.59702, 45.0221, 0],
                [-113.59647, 45.02216, 0],
                [-113.59599, 45.02206, 0],
                [-113.59563, 45.02192, 0],
                [-113.59461, 45.02123, 0],
                [-113.59443, 45.02114, 0],
                [-113.59415, 45.02109, 0],
                [-113.59403, 45.02111, 0],
                [-113.59392, 45.02118, 0],
                [-113.59389, 45.02125, 0],
                [-113.59392, 45.0214, 0],
                [-113.59416, 45.02178, 0],
                [-113.59417, 45.0222, 0],
                [-113.59395, 45.02255, 0],
                [-113.59342, 45.02282, 0],
                [-113.59278, 45.02295, 0],
                [-113.59137, 45.02292, 0],
                [-113.59036, 45.02266, 0],
                [-113.59015, 45.02264, 0],
                [-113.58972, 45.02274, 0],
                [-113.5889, 45.0228, 0],
                [-113.58685, 45.0226, 0],
                [-113.58645, 45.0226, 0],
                [-113.58552, 45.02297, 0],
                [-113.58484, 45.02315, 0],
                [-113.58422, 45.02326, 0],
                [-113.58353, 45.02328, 0],
                [-113.58294, 45.02341, 0],
                [-113.58267, 45.02344, 0],
                [-113.58208, 45.02345, 0],
                [-113.58187, 45.02349, 0],
                [-113.58164, 45.02359, 0],
                [-113.58138, 45.02376, 0],
                [-113.58105, 45.0238, 0],
                [-113.58034, 45.02377, 0],
                [-113.58, 45.02373, 0],
                [-113.57977, 45.02373, 0],
                [-113.57963, 45.02381, 0],
                [-113.57942, 45.02403, 0],
                [-113.57927, 45.02422, 0],
                [-113.57915, 45.0245, 0],
                [-113.57908, 45.02497, 0],
                [-113.57916, 45.02526, 0],
                [-113.57949, 45.02571, 0],
                [-113.57963, 45.02619, 0],
                [-113.58005, 45.02704, 0],
                [-113.58037, 45.02726, 0],
                [-113.58057, 45.0273, 0],
                [-113.58084, 45.02731, 0],
                [-113.58105, 45.02744, 0],
                [-113.58107, 45.02762, 0],
                [-113.58093, 45.02773, 0],
                [-113.58072, 45.02779, 0],
                [-113.58028, 45.02773, 0],
                [-113.57967, 45.02747, 0],
                [-113.57923, 45.02723, 0],
                [-113.57811, 45.02688, 0],
                [-113.57736, 45.0266, 0],
                [-113.57679, 45.02629, 0],
                [-113.57632, 45.02597, 0],
                [-113.57522, 45.02542, 0],
                [-113.57399, 45.02467, 0],
                [-113.57353, 45.02417, 0],
                [-113.57338, 45.0241, 0],
                [-113.57318, 45.02407, 0],
                [-113.57296, 45.02412, 0],
                [-113.57283, 45.02424, 0],
                [-113.5728, 45.02448, 0],
                [-113.5728, 45.02472, 0],
                [-113.57306, 45.02572, 0],
                [-113.57349, 45.02655, 0],
                [-113.57401, 45.02733, 0],
                [-113.57407, 45.02767, 0],
                [-113.57398, 45.02799, 0],
                [-113.57373, 45.0283, 0],
                [-113.57306, 45.02865, 0],
                [-113.57219, 45.02888, 0],
                [-113.57038, 45.02921, 0],
                [-113.56989, 45.02943, 0],
                [-113.56969, 45.02975, 0],
                [-113.5696, 45.03001, 0],
                [-113.56963, 45.03033, 0],
                [-113.56997, 45.03072, 0],
                [-113.57008, 45.03128, 0],
                [-113.5702, 45.03143, 0],
                [-113.57056, 45.03175, 0],
                [-113.57065, 45.03197, 0],
                [-113.57064, 45.03215, 0],
                [-113.57046, 45.03243, 0],
                [-113.57042, 45.03265, 0],
                [-113.57051, 45.03324, 0],
                [-113.57043, 45.0335, 0],
                [-113.57008, 45.03367, 0],
                [-113.56964, 45.03372, 0],
                [-113.56867, 45.03365, 0],
                [-113.56831, 45.03367, 0],
                [-113.5674, 45.03388, 0],
                [-113.56705, 45.03399, 0],
                [-113.56642, 45.03441, 0],
                [-113.56594, 45.03452, 0],
                [-113.56513, 45.03449, 0],
                [-113.56403, 45.03437, 0],
                [-113.56308, 45.03413, 0],
                [-113.56278, 45.03411, 0],
                [-113.56245, 45.03414, 0],
                [-113.56063, 45.03481, 0],
                [-113.55963, 45.03523, 0],
                [-113.55891, 45.03547, 0],
                [-113.55815, 45.03561, 0],
                [-113.55744, 45.03564, 0],
                [-113.5556, 45.03549, 0],
                [-113.55464, 45.0355, 0],
                [-113.55432, 45.03568, 0],
                [-113.55387, 45.03622, 0],
                [-113.55299, 45.0375, 0],
                [-113.55272, 45.03869, 0],
                [-113.55271, 45.0387, 0],
                [-113.55276, 45.03922, 0],
                [-113.5527, 45.0395, 0],
                [-113.55244, 45.03982, 0],
                [-113.5519, 45.0402, 0],
                [-113.55151, 45.04055, 0],
                [-113.55116, 45.04103, 0],
                [-113.55084, 45.04181, 0],
                [-113.55037, 45.04235, 0],
                [-113.54981, 45.04279, 0],
                [-113.54935, 45.04293, 0],
                [-113.54908, 45.04326, 0],
                [-113.54903, 45.04378, 0],
                [-113.54877, 45.04418, 0],
                [-113.5479, 45.04473, 0],
                [-113.54705, 45.04511, 0],
                [-113.54641, 45.04558, 0],
                [-113.54595, 45.04622, 0],
                [-113.54558, 45.04664, 0],
                [-113.54462, 45.04705, 0],
                [-113.54408, 45.04739, 0],
                [-113.54237, 45.04833, 0],
                [-113.54038, 45.04923, 0],
                [-113.53875, 45.04961, 0],
                [-113.53717, 45.04967, 0],
                [-113.53653, 45.04987, 0],
                [-113.53577, 45.05034, 0],
                [-113.53498, 45.05057, 0],
                [-113.53395, 45.05077, 0],
                [-113.53288, 45.05137, 0],
                [-113.5301, 45.05221, 0],
                [-113.52858, 45.05253, 0],
                [-113.5257, 45.05382, 0],
                [-113.52434, 45.05461, 0],
                [-113.52385, 45.05481, 0],
                [-113.52134, 45.05539, 0],
                [-113.52077, 45.05549, 0],
                [-113.52032, 45.05551, 0],
                [-113.51993, 45.05548, 0],
                [-113.51844, 45.05495, 0],
                [-113.51815, 45.05491, 0],
                [-113.51789, 45.05494, 0],
                [-113.51568, 45.05568, 0],
                [-113.51514, 45.05594, 0],
                [-113.51483, 45.05622, 0],
                [-113.51448, 45.0563, 0],
                [-113.51421, 45.05627, 0],
                [-113.5135, 45.05587, 0],
                [-113.51349, 45.05587, 0],
                [-113.51312, 45.05566, 0],
                [-113.51257, 45.05568, 0],
                [-113.51214, 45.05554, 0],
                [-113.51177, 45.05535, 0],
                [-113.51152, 45.05531, 0],
                [-113.51132, 45.05535, 0],
                [-113.50965, 45.05596, 0],
                [-113.50885, 45.05608, 0],
                [-113.50732, 45.05618, 0],
                [-113.50671, 45.05628, 0],
                [-113.50555, 45.05689, 0],
                [-113.50438, 45.05731, 0],
                [-113.50226, 45.05744, 0],
                [-113.50088, 45.05747, 0],
                [-113.49867, 45.05734, 0],
                [-113.49809, 45.05739, 0],
                [-113.49775, 45.05737, 0],
                [-113.49742, 45.05731, 0],
                [-113.49638, 45.05699, 0],
                [-113.49527, 45.05674, 0],
                [-113.49433, 45.05673, 0],
                [-113.49395, 45.05678, 0],
                [-113.49348, 45.05689, 0],
                [-113.49294, 45.05719, 0],
                [-113.49252, 45.0576, 0],
                [-113.49236, 45.05772, 0],
                [-113.49209, 45.0578, 0],
                [-113.49185, 45.05781, 0],
                [-113.49149, 45.05773, 0],
                [-113.48959, 45.05764, 0],
                [-113.48836, 45.05731, 0],
                [-113.48746, 45.0572, 0],
                [-113.48679, 45.05708, 0],
                [-113.48539, 45.05675, 0],
                [-113.4847, 45.05666, 0],
                [-113.48364, 45.05669, 0],
                [-113.48294, 45.05678, 0],
                [-113.4805, 45.05683, 0],
                [-113.48025, 45.05678, 0],
                [-113.47998, 45.05667, 0],
                [-113.4795, 45.05639, 0],
                [-113.47834, 45.05556, 0],
                [-113.47761, 45.05533, 0],
                [-113.47666, 45.05514, 0],
                [-113.47589, 45.05511, 0],
                [-113.47525, 45.05513, 0],
                [-113.47423, 45.05522, 0],
                [-113.47329, 45.05525, 0],
                [-113.473, 45.05522, 0],
                [-113.47208, 45.05504, 0],
                [-113.47131, 45.05504, 0],
                [-113.46905, 45.05521, 0],
                [-113.46847, 45.05522, 0],
                [-113.46803, 45.05519, 0],
                [-113.46343, 45.05566, 0],
                [-113.46243, 45.05558, 0],
                [-113.46172, 45.05546, 0],
                [-113.46042, 45.05514, 0],
                [-113.45955, 45.055, 0],
                [-113.45904, 45.05482, 0],
                [-113.45889, 45.05471, 0],
                [-113.45882, 45.05462, 0],
                [-113.45886, 45.05446, 0],
                [-113.45914, 45.05412, 0],
                [-113.45924, 45.05391, 0],
                [-113.45923, 45.05368, 0],
                [-113.45915, 45.05343, 0],
                [-113.45914, 45.05342, 0],
                [-113.45879, 45.05272, 0],
                [-113.45783, 45.05156, 0],
                [-113.45774, 45.05139, 0],
                [-113.45767, 45.05108, 0],
                [-113.45769, 45.05069, 0],
                [-113.45767, 45.05043, 0],
                [-113.45758, 45.05022, 0],
                [-113.45759, 45.04999, 0],
                [-113.45784, 45.04967, 0],
                [-113.45805, 45.04952, 0],
                [-113.45819, 45.04946, 0],
                [-113.45838, 45.04944, 0],
                [-113.4596, 45.04949, 0],
                [-113.4602, 45.04939, 0],
                [-113.46069, 45.04948, 0],
                [-113.46099, 45.04951, 0],
                [-113.46199, 45.04946, 0],
                [-113.46411, 45.04906, 0],
                [-113.46643, 45.04802, 0],
                [-113.46652, 45.0479, 0],
                [-113.46654, 45.04777, 0],
                [-113.46647, 45.04765, 0],
                [-113.46636, 45.04759, 0],
                [-113.4662, 45.04754, 0],
                [-113.46589, 45.04751, 0],
                [-113.46566, 45.04754, 0],
                [-113.46537, 45.04753, 0],
                [-113.46511, 45.04745, 0],
                [-113.462, 45.04597, 0],
                [-113.46167, 45.04578, 0],
                [-113.45984, 45.04423, 0],
                [-113.45884, 45.04347, 0],
                [-113.45765, 45.04248, 0],
                [-113.45758, 45.04233, 0],
                [-113.45757, 45.04222, 0],
                [-113.45764, 45.04109, 0],
                [-113.45767, 45.03846, 0],
                [-113.45793, 45.03677, 0],
                [-113.45793, 45.03659, 0],
                [-113.4579, 45.03643, 0],
                [-113.45785, 45.03631, 0],
                [-113.45772, 45.03619, 0],
                [-113.45747, 45.03611, 0],
                [-113.45682, 45.03609, 0],
                [-113.45614, 45.03618, 0],
                [-113.45491, 45.03652, 0],
                [-113.4542, 45.03677, 0],
                [-113.45403, 45.03676, 0],
                [-113.45392, 45.03672, 0],
                [-113.4538, 45.03664, 0],
                [-113.45233, 45.03524, 0],
                [-113.45222, 45.03505, 0],
                [-113.45207, 45.03469, 0],
                [-113.45188, 45.03433, 0],
                [-113.45178, 45.03405, 0],
                [-113.45178, 45.0338, 0],
                [-113.45182, 45.03357, 0],
                [-113.45205, 45.03313, 0],
                [-113.45215, 45.03289, 0],
                [-113.45219, 45.03271, 0],
                [-113.45207, 45.03163, 0],
                [-113.45218, 45.03007, 0],
                [-113.45223, 45.02981, 0],
                [-113.45289, 45.02812, 0],
                [-113.45309, 45.02773, 0],
                [-113.45317, 45.02745, 0],
                [-113.45336, 45.02624, 0],
                [-113.45337, 45.02623, 0],
                [-113.45344, 45.02602, 0],
                [-113.45356, 45.02588, 0],
                [-113.45388, 45.02564, 0],
                [-113.45436, 45.02534, 0],
                [-113.45524, 45.02462, 0],
                [-113.45584, 45.02421, 0],
                [-113.45626, 45.02376, 0],
                [-113.45629, 45.02374, 0],
                [-113.45694, 45.02293, 0],
                [-113.4571, 45.02256, 0],
                [-113.45734, 45.0218, 0],
                [-113.45745, 45.0216, 0],
                [-113.45764, 45.02135, 0],
                [-113.45852, 45.02044, 0],
                [-113.45902, 45.01972, 0],
                [-113.45915, 45.01957, 0],
                [-113.46041, 45.01878, 0],
                [-113.46049, 45.01862, 0],
                [-113.46044, 45.01839, 0],
                [-113.46031, 45.01813, 0],
                [-113.46012, 45.0179, 0],
                [-113.45961, 45.01744, 0],
                [-113.45864, 45.01636, 0],
                [-113.45788, 45.01566, 0],
                [-113.45759, 45.01548, 0],
                [-113.45686, 45.01522, 0],
                [-113.45651, 45.01517, 0],
                [-113.45458, 45.01511, 0],
                [-113.45356, 45.01521, 0],
                [-113.4503, 45.01535, 0],
                [-113.45008, 45.01529, 0],
                [-113.44982, 45.01519, 0],
                [-113.44947, 45.01498, 0],
                [-113.44919, 45.01478, 0],
                [-113.44857, 45.01421, 0],
                [-113.44811, 45.01364, 0],
                [-113.44787, 45.01317, 0],
                [-113.44773, 45.01279, 0],
                [-113.44757, 45.01223, 0],
                [-113.44748, 45.01161, 0],
                [-113.44744, 45.01107, 0],
                [-113.44745, 45.01073, 0],
                [-113.4475, 45.01049, 0],
                [-113.44768, 45.00994, 0],
                [-113.44775, 45.00962, 0],
                [-113.44783, 45.00904, 0],
                [-113.44794, 45.00889, 0],
                [-113.44942, 45.00809, 0],
                [-113.4495, 45.00798, 0],
                [-113.44955, 45.00786, 0],
                [-113.44954, 45.00765, 0],
                [-113.44944, 45.00738, 0],
                [-113.44935, 45.00729, 0],
                [-113.44919, 45.00725, 0],
                [-113.44897, 45.00725, 0],
                [-113.44813, 45.00739, 0],
                [-113.44734, 45.00747, 0],
                [-113.44717, 45.00747, 0],
                [-113.44701, 45.00744, 0],
                [-113.44685, 45.00738, 0],
                [-113.44538, 45.00648, 0],
                [-113.44496, 45.00617, 0],
                [-113.44366, 45.00497, 0],
                [-113.44361, 45.00494, 0],
                [-113.44351, 45.00474, 0],
                [-113.44345, 45.00454, 0],
                [-113.44343, 45.00431, 0],
                [-113.44345, 45.00405, 0],
                [-113.44368, 45.0034, 0],
                [-113.44381, 45.00311, 0],
                [-113.44414, 45.00269, 0],
                [-113.4445, 45.0024, 0],
                [-113.44592, 45.00152, 0],
                [-113.44629, 45.00133, 0],
                [-113.44666, 45.00119, 0],
                [-113.44714, 45.00106, 0],
                [-113.44814, 45.00088, 0],
                [-113.4488, 45.00085, 0],
                [-113.45011, 45.00042, 0],
                [-113.45036, 45.00037, 0],
                [-113.45154, 45.00032, 0],
                [-113.45185, 45.00028, 0],
                [-113.45216, 45.00018, 0],
                [-113.45319, 44.99961, 0],
                [-113.45442, 44.99876, 0],
                [-113.45522, 44.99813, 0],
                [-113.45561, 44.99765, 0],
                [-113.45645, 44.99607, 0],
                [-113.45677, 44.9956, 0],
                [-113.45677, 44.99559, 0],
                [-113.45729, 44.99493, 0],
                [-113.4575, 44.9947, 0],
                [-113.45785, 44.99439, 0],
                [-113.45786, 44.99439, 0],
                [-113.45861, 44.99386, 0],
                [-113.45974, 44.99327, 0],
                [-113.45988, 44.99313, 0],
                [-113.45995, 44.993, 0],
                [-113.46003, 44.99272, 0],
                [-113.46002, 44.99246, 0],
                [-113.45992, 44.9922, 0],
                [-113.45952, 44.99164, 0],
                [-113.45917, 44.99132, 0],
                [-113.45852, 44.99087, 0],
                [-113.45836, 44.99082, 0],
                [-113.45818, 44.99079, 0],
                [-113.45797, 44.9908, 0],
                [-113.45773, 44.99085, 0],
                [-113.45568, 44.99164, 0],
                [-113.45467, 44.99172, 0],
                [-113.45432, 44.99172, 0],
                [-113.45412, 44.99168, 0],
                [-113.45392, 44.99153, 0],
                [-113.45354, 44.991, 0],
                [-113.45333, 44.99087, 0],
                [-113.45304, 44.99073, 0],
                [-113.45272, 44.99062, 0],
                [-113.45231, 44.99058, 0],
                [-113.45153, 44.99057, 0],
                [-113.45104, 44.9906, 0],
                [-113.44999, 44.99076, 0],
                [-113.44989, 44.99073, 0],
                [-113.44972, 44.99052, 0],
                [-113.44942, 44.98998, 0],
                [-113.44917, 44.98937, 0],
                [-113.44914, 44.98911, 0],
                [-113.44894, 44.98867, 0],
                [-113.44888, 44.98838, 0],
                [-113.4488, 44.98821, 0],
                [-113.44881, 44.98796, 0],
                [-113.44886, 44.98769, 0],
                [-113.44893, 44.98754, 0],
                [-113.44895, 44.98739, 0],
                [-113.44894, 44.98736, 0],
                [-113.44892, 44.98714, 0],
                [-113.44937, 44.98544, 0],
                [-113.44936, 44.98523, 0],
                [-113.44932, 44.9851, 0],
                [-113.44904, 44.98481, 0],
                [-113.44858, 44.98446, 0],
                [-113.44841, 44.98429, 0],
                [-113.44831, 44.98412, 0],
                [-113.44818, 44.98379, 0],
                [-113.448, 44.98358, 0],
                [-113.44774, 44.98344, 0],
                [-113.44731, 44.9833, 0],
                [-113.44703, 44.98324, 0],
                [-113.44687, 44.98316, 0],
                [-113.44667, 44.98301, 0],
                [-113.44667, 44.983, 0],
                [-113.44636, 44.98239, 0],
                [-113.44631, 44.98219, 0],
                [-113.44636, 44.98206, 0],
                [-113.44647, 44.98192, 0],
                [-113.44663, 44.98176, 0],
                [-113.44676, 44.98166, 0],
                [-113.4477, 44.9812, 0],
                [-113.44785, 44.98108, 0],
                [-113.44793, 44.98094, 0],
                [-113.44795, 44.98081, 0],
                [-113.44794, 44.98071, 0],
                [-113.44781, 44.98057, 0],
                [-113.44761, 44.98042, 0],
                [-113.4475, 44.98026, 0],
                [-113.44744, 44.98008, 0],
                [-113.44745, 44.97981, 0],
                [-113.4475, 44.97963, 0],
                [-113.44757, 44.9795, 0],
                [-113.44769, 44.97933, 0],
                [-113.44807, 44.97896, 0],
                [-113.4481, 44.97883, 0],
                [-113.44805, 44.97869, 0],
                [-113.44794, 44.97855, 0],
                [-113.44754, 44.97821, 0],
                [-113.44609, 44.97667, 0],
                [-113.44536, 44.97569, 0],
                [-113.44504, 44.97502, 0],
                [-113.44492, 44.97465, 0],
                [-113.44545, 44.97478, 0],
                [-113.44691, 44.97542, 0],
                [-113.44692, 44.97542, 0],
                [-113.44828, 44.97608, 0],
                [-113.45109, 44.97724, 0],
                [-113.45162, 44.97755, 0],
                [-113.45172, 44.97765, 0],
                [-113.4519, 44.97794, 0],
                [-113.45192, 44.97805, 0],
                [-113.45194, 44.97851, 0],
                [-113.45192, 44.97862, 0],
                [-113.45195, 44.97868, 0],
                [-113.45199, 44.97873, 0],
                [-113.45246, 44.97889, 0],
                [-113.45261, 44.97897, 0],
                [-113.45277, 44.97911, 0],
                [-113.45289, 44.97936, 0],
                [-113.45297, 44.97994, 0],
                [-113.45301, 44.98002, 0],
                [-113.45308, 44.98006, 0],
                [-113.45316, 44.98008, 0],
                [-113.45384, 44.97999, 0],
                [-113.45511, 44.97998, 0],
                [-113.4562, 44.98007, 0],
                [-113.4564, 44.98011, 0],
                [-113.45655, 44.98018, 0],
                [-113.4572, 44.98068, 0],
                [-113.45734, 44.98076, 0],
                [-113.45747, 44.98081, 0],
                [-113.45789, 44.9809, 0],
                [-113.45791, 44.98091, 0],
                [-113.45814, 44.98095, 0],
                [-113.45831, 44.98101, 0],
                [-113.45846, 44.98109, 0],
                [-113.45858, 44.98123, 0],
                [-113.45866, 44.98137, 0],
                [-113.45896, 44.98215, 0],
                [-113.45898, 44.98233, 0],
                [-113.45897, 44.98257, 0],
                [-113.45902, 44.98291, 0],
                [-113.45917, 44.98324, 0],
                [-113.45918, 44.98331, 0],
                [-113.45923, 44.98341, 0],
                [-113.45935, 44.98353, 0],
                [-113.45962, 44.98372, 0],
                [-113.45986, 44.9838, 0],
                [-113.45997, 44.98381, 0],
                [-113.46004, 44.9838, 0],
                [-113.46013, 44.98376, 0],
                [-113.46018, 44.98371, 0],
                [-113.46018, 44.98367, 0],
                [-113.46016, 44.98362, 0],
                [-113.45992, 44.98334, 0],
                [-113.45992, 44.98333, 0],
                [-113.45985, 44.98325, 0],
                [-113.45981, 44.98317, 0],
                [-113.45981, 44.98315, 0],
                [-113.45978, 44.98311, 0],
                [-113.4598, 44.98304, 0],
                [-113.45978, 44.98291, 0],
                [-113.45982, 44.98282, 0],
                [-113.45982, 44.98281, 0],
                [-113.45992, 44.98268, 0],
                [-113.46019, 44.98221, 0],
                [-113.46029, 44.98199, 0],
                [-113.4603, 44.98198, 0],
                [-113.46039, 44.9816, 0],
                [-113.46057, 44.98128, 0],
                [-113.46079, 44.98102, 0],
                [-113.4612, 44.98067, 0],
                [-113.46137, 44.9804, 0],
                [-113.46151, 44.98012, 0],
                [-113.4616, 44.97999, 0],
                [-113.46189, 44.97975, 0],
                [-113.46222, 44.97955, 0],
                [-113.46284, 44.97945, 0],
                [-113.46435, 44.97929, 0],
                [-113.46455, 44.97929, 0],
                [-113.46477, 44.97932, 0],
                [-113.46538, 44.9795, 0],
                [-113.4659, 44.97969, 0],
                [-113.46637, 44.97979, 0],
                [-113.46679, 44.97984, 0],
                [-113.4672, 44.97984, 0],
                [-113.46759, 44.97988, 0],
                [-113.46852, 44.97981, 0],
                [-113.46887, 44.97982, 0],
                [-113.46912, 44.97989, 0],
                [-113.46931, 44.9799, 0],
                [-113.46955, 44.97986, 0],
                [-113.47006, 44.97982, 0],
                [-113.47118, 44.97945, 0],
                [-113.47161, 44.97938, 0],
                [-113.4718, 44.97933, 0],
                [-113.47192, 44.97926, 0],
                [-113.47215, 44.97907, 0],
                [-113.47237, 44.97883, 0],
                [-113.4729, 44.97835, 0],
                [-113.47348, 44.97754, 0],
                [-113.47385, 44.97709, 0],
                [-113.47402, 44.97693, 0],
                [-113.47418, 44.97683, 0],
                [-113.47449, 44.97674, 0],
                [-113.4751, 44.97645, 0],
                [-113.47581, 44.97621, 0],
                [-113.47698, 44.9759, 0],
                [-113.47759, 44.97581, 0],
                [-113.47807, 44.97567, 0],
                [-113.47828, 44.97565, 0],
                [-113.47846, 44.9756, 0],
                [-113.47857, 44.97547, 0],
                [-113.47866, 44.97533, 0],
                [-113.47903, 44.97505, 0],
                [-113.47936, 44.97486, 0],
                [-113.47946, 44.97476, 0],
                [-113.47958, 44.97447, 0],
                [-113.4797, 44.97429, 0],
                [-113.48003, 44.97405, 0],
                [-113.48026, 44.97393, 0],
                [-113.48033, 44.97386, 0],
                [-113.4804, 44.97374, 0],
                [-113.48071, 44.97335, 0],
                [-113.48094, 44.97316, 0],
                [-113.48111, 44.97307, 0],
                [-113.4813, 44.97305, 0],
                [-113.48168, 44.97311, 0],
                [-113.48222, 44.97302, 0],
                [-113.48246, 44.97305, 0],
                [-113.48313, 44.9734, 0],
                [-113.48338, 44.9735, 0],
                [-113.48366, 44.97354, 0],
                [-113.48396, 44.97355, 0],
                [-113.48545, 44.97329, 0],
                [-113.48557, 44.97331, 0],
                [-113.48707, 44.97385, 0],
                [-113.48778, 44.97416, 0],
                [-113.48837, 44.97429, 0],
                [-113.48961, 44.9747, 0],
                [-113.48981, 44.97482, 0],
                [-113.49103, 44.97532, 0],
                [-113.49119, 44.97542, 0],
                [-113.49387, 44.97574, 0],
                [-113.49444, 44.97589, 0],
                [-113.4959, 44.9764, 0],
                [-113.49636, 44.97668, 0],
                [-113.49655, 44.97687, 0],
                [-113.49683, 44.97731, 0],
                [-113.49697, 44.9776, 0],
                [-113.49723, 44.97795, 0],
                [-113.49746, 44.97808, 0],
                [-113.49823, 44.97842, 0],
                [-113.49859, 44.97853, 0],
                [-113.49988, 44.97874, 0],
                [-113.50022, 44.97871, 0],
                [-113.50101, 44.97856, 0],
                [-113.50327, 44.97858, 0],
                [-113.50355, 44.97862, 0],
                [-113.50375, 44.9786, 0],
                [-113.50387, 44.97854, 0],
                [-113.50409, 44.97837, 0],
                [-113.5043, 44.97825, 0],
                [-113.50458, 44.97805, 0],
                [-113.50501, 44.97781, 0],
                [-113.50514, 44.97777, 0],
                [-113.5055, 44.97778, 0],
                [-113.50567, 44.97774, 0],
                [-113.50609, 44.97757, 0],
                [-113.50655, 44.97733, 0],
                [-113.50742, 44.97716, 0],
                [-113.50757, 44.97715, 0],
                [-113.50822, 44.97722, 0],
                [-113.50885, 44.97718, 0],
                [-113.50922, 44.97711, 0],
                [-113.50975, 44.97709, 0],
                [-113.51098, 44.97692, 0],
                [-113.5116, 44.97674, 0],
                [-113.51178, 44.97666, 0],
                [-113.51219, 44.97643, 0],
                [-113.51233, 44.97632, 0],
                [-113.51245, 44.97618, 0],
                [-113.51257, 44.97597, 0],
                [-113.51272, 44.97581, 0],
                [-113.51281, 44.97578, 0],
                [-113.5129, 44.97577, 0],
                [-113.51343, 44.97584, 0],
                [-113.51467, 44.97615, 0],
                [-113.51605, 44.97641, 0],
                [-113.5164, 44.97652, 0],
                [-113.51665, 44.97653, 0],
                [-113.51683, 44.97645, 0],
                [-113.51696, 44.97634, 0],
                [-113.51705, 44.97631, 0],
                [-113.5177, 44.9756, 0],
                [-113.51793, 44.9753, 0],
                [-113.51861, 44.97496, 0],
                [-113.5192, 44.97496, 0],
                [-113.52002, 44.9748, 0],
                [-113.52076, 44.97453, 0],
                [-113.52227, 44.97418, 0],
                [-113.52323, 44.97415, 0],
                [-113.52429, 44.97394, 0],
                [-113.52472, 44.97368, 0],
                [-113.52512, 44.97333, 0],
                [-113.52618, 44.97284, 0],
                [-113.52736, 44.97256, 0],
                [-113.52787, 44.97237, 0],
                [-113.52809, 44.9722, 0],
                [-113.52853, 44.97204, 0],
                [-113.52973, 44.97183, 0],
                [-113.53016, 44.97161, 0],
                [-113.53178, 44.97052, 0],
                [-113.53256, 44.96968, 0],
                [-113.53316, 44.96936, 0],
                [-113.53406, 44.96912, 0],
                [-113.53479, 44.96917, 0],
                [-113.53527, 44.96908, 0],
                [-113.53588, 44.96858, 0],
                [-113.53643, 44.9682, 0],
                [-113.53701, 44.96761, 0],
                [-113.53756, 44.96741, 0],
                [-113.53825, 44.96681, 0],
                [-113.53969, 44.96523, 0],
                [-113.54162, 44.96326, 0],
                [-113.54179, 44.96269, 0],
                [-113.54189, 44.9622, 0],
                [-113.54187, 44.96193, 0],
                [-113.54203, 44.96176, 0],
                [-113.54224, 44.96159, 0],
                [-113.5424, 44.96143, 0],
                [-113.54262, 44.96132, 0],
                [-113.54343, 44.96102, 0],
                [-113.54357, 44.96091, 0],
                [-113.54377, 44.96032, 0],
                [-113.54385, 44.9602, 0],
                [-113.54397, 44.96009, 0],
                [-113.54418, 44.95999, 0],
                [-113.54453, 44.95972, 0],
                [-113.54467, 44.95951, 0],
                [-113.54482, 44.95922, 0],
                [-113.54486, 44.95893, 0],
                [-113.5449, 44.95885, 0],
                [-113.54491, 44.95885, 0],
                [-113.54501, 44.95876, 0],
                [-113.54525, 44.95864, 0],
                [-113.54552, 44.9584, 0],
                [-113.54597, 44.95811, 0],
                [-113.54612, 44.95795, 0],
                [-113.54612, 44.95794, 0],
                [-113.54637, 44.95765, 0],
                [-113.54659, 44.95749, 0],
                [-113.54705, 44.95733, 0],
                [-113.54749, 44.95714, 0],
                [-113.54771, 44.9571, 0],
                [-113.54836, 44.95718, 0],
                [-113.54876, 44.95713, 0],
                [-113.54903, 44.95703, 0],
                [-113.54933, 44.95686, 0],
                [-113.54948, 44.95674, 0],
                [-113.54976, 44.95631, 0],
                [-113.55048, 44.95582, 0],
                [-113.55095, 44.95544, 0],
                [-113.55128, 44.95486, 0],
                [-113.55134, 44.95483, 0],
                [-113.55139, 44.95479, 0],
                [-113.55293, 44.95436, 0],
                [-113.55318, 44.95426, 0],
                [-113.5538, 44.95407, 0],
                [-113.55432, 44.9538, 0],
                [-113.55447, 44.95368, 0],
                [-113.55486, 44.95343, 0],
                [-113.55497, 44.95329, 0],
                [-113.55502, 44.95316, 0],
                [-113.55515, 44.95293, 0],
                [-113.55531, 44.95271, 0],
                [-113.55554, 44.95249, 0],
                [-113.55586, 44.95226, 0],
                [-113.55644, 44.95169, 0],
                [-113.55671, 44.95126, 0],
                [-113.55688, 44.95112, 0],
                [-113.55785, 44.95051, 0],
                [-113.5583, 44.95001, 0],
                [-113.55839, 44.94985, 0],
                [-113.55842, 44.94972, 0],
                [-113.55841, 44.94954, 0],
                [-113.55852, 44.94931, 0],
                [-113.55857, 44.94924, 0],
                [-113.5587, 44.94917, 0],
                [-113.55884, 44.94913, 0],
                [-113.55974, 44.94912, 0],
                [-113.56076, 44.94916, 0],
                [-113.56193, 44.94911, 0],
                [-113.56226, 44.94905, 0],
                [-113.56279, 44.94901, 0],
                [-113.56308, 44.94903, 0],
                [-113.56331, 44.94908, 0],
                [-113.56454, 44.94911, 0],
                [-113.56551, 44.94919, 0],
                [-113.56698, 44.94912, 0],
                [-113.56782, 44.94918, 0],
                [-113.56838, 44.94928, 0],
                [-113.56878, 44.94931, 0],
                [-113.56963, 44.94929, 0],
                [-113.57037, 44.94919, 0],
                [-113.57079, 44.94909, 0],
                [-113.57128, 44.94901, 0],
                [-113.57233, 44.94896, 0],
                [-113.57287, 44.94899, 0],
                [-113.57301, 44.94895, 0],
                [-113.57333, 44.94878, 0],
                [-113.57362, 44.94856, 0],
                [-113.57396, 44.94839, 0],
                [-113.57448, 44.94822, 0],
                [-113.57472, 44.94809, 0],
                [-113.57497, 44.9479, 0],
                [-113.57514, 44.94781, 0],
                [-113.57526, 44.94768, 0],
                [-113.57544, 44.94733, 0],
                [-113.57555, 44.94725, 0],
                [-113.57579, 44.94717, 0],
                [-113.57593, 44.94717, 0],
                [-113.57666, 44.94736, 0],
                [-113.57713, 44.94738, 0],
                [-113.57784, 44.94726, 0],
                [-113.57812, 44.94731, 0],
                [-113.57851, 44.94746, 0],
                [-113.57888, 44.94747, 0],
                [-113.58075, 44.94733, 0],
                [-113.58196, 44.94706, 0],
                [-113.58251, 44.94709, 0],
                [-113.58289, 44.94707, 0],
                [-113.58336, 44.94689, 0],
                [-113.5837, 44.94683, 0],
                [-113.58424, 44.94679, 0],
                [-113.58483, 44.94666, 0],
                [-113.58519, 44.94663, 0],
                [-113.58579, 44.94678, 0],
                [-113.58668, 44.94689, 0],
                [-113.5875, 44.94687, 0],
                [-113.58858, 44.94668, 0],
                [-113.58928, 44.9467, 0],
                [-113.58991, 44.94681, 0],
                [-113.59121, 44.94694, 0],
                [-113.59166, 44.94706, 0],
                [-113.59258, 44.94737, 0],
                [-113.59386, 44.94792, 0],
                [-113.59442, 44.94812, 0],
                [-113.595, 44.94825, 0],
                [-113.59585, 44.94828, 0],
                [-113.59701, 44.94861, 0],
                [-113.59809, 44.94904, 0],
                [-113.60038, 44.94934, 0],
                [-113.60118, 44.94953, 0],
                [-113.60163, 44.94972, 0],
                [-113.60256, 44.94994, 0],
                [-113.60387, 44.95011, 0],
                [-113.60476, 44.95032, 0],
                [-113.60564, 44.95048, 0],
                [-113.60599, 44.95051, 0],
                [-113.60774, 44.9505, 0],
                [-113.60954, 44.95031, 0],
                [-113.61005, 44.95033, 0],
                [-113.61112, 44.95055, 0],
                [-113.61399, 44.95155, 0],
                [-113.61509, 44.95218, 0],
                [-113.61628, 44.95273, 0],
                [-113.61713, 44.95301, 0],
                [-113.61779, 44.95327, 0],
                [-113.61967, 44.95385, 0],
                [-113.62162, 44.9548, 0],
                [-113.62316, 44.95536, 0],
                [-113.62439, 44.95594, 0],
                [-113.6247, 44.95603, 0],
                [-113.62506, 44.95609, 0],
                [-113.63221, 44.95668, 0],
                [-113.63384, 44.95665, 0],
                [-113.636, 44.95652, 0],
                [-113.63989, 44.95645, 0],
                [-113.63996, 44.95657, 0],
                [-113.64009, 44.95687, 0],
                [-113.64028, 44.95745, 0],
                [-113.64031, 44.95771, 0],
                [-113.64074, 44.95992, 0],
                [-113.64441, 44.95982, 0]
            ]
        },
        id: "7a8f7a9e-f74f-4516-9de4-fc5c7dcd636a"
    }, 
    // {
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-113.64441, 44.95982, 0]
    //     },
    //     properties: {
    //         name: "Tendoy, Idaho",
    //         "shape": "Marker",
    //         "category": "default"
    //     },
    //     id: "30256dea-8198-4e27-a5a9-22611bb06c27"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Lemhi, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-113.620113, 44.851823]
    //     },
    //     id: "5432a50f-3acd-4b1c-a039-8a1b85f738c4"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Salmon, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-113.88739, 45.173067]
    //     },
    //     id: "0df95634-0037-499a-9251-9c1e52c744da"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Leadore, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-113.358045, 44.680738]
    //     },
    //     id: "fc537426-bac7-4ed5-800e-181972e95fc5"
    // }
    ]
};

export default LewisClark;