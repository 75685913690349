import { configureStore } from "@reduxjs/toolkit";
import activitiesReducer from "./reducers/activitiesReducer";
import cartReducer from './reducers/cartReducer';
import markersReducer from "./reducers/markersReducers";
import regionsReducer from "./reducers/regionsReducer";
import bywaysReducer from "./reducers/bywaysReducers";
import userReducer from "./slices/userReducer";
import popupsReducer from "./reducers/popupsReducers";
import lastItemReducer from "./reducers/lastItemReducer";
import routesReducer from "./reducers/routesReducers";
import itineraryReducer from "./reducers/itineraryReducer";
import recommendedReducer from "./reducers/recommendedReducer";
import tutorialReducer from "./reducers/tutorialReducer";
import travelTipCartReducer from "./reducers/travelTipCartReducers";

const store = configureStore({
    reducer: {
        cart: cartReducer,
        ttcart: travelTipCartReducer,
        markers: markersReducer,
        activities: activitiesReducer,
        regions: regionsReducer,
        byways: bywaysReducer,
        user: userReducer,
        popup: popupsReducer,
        lastItem: lastItemReducer,
        routes: routesReducer,
        itinerary: itineraryReducer,
        recommendations: recommendedReducer,
        tutorial: tutorialReducer,
    },
});
export default store;