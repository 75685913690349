import React, { useState } from "react";

const IconArtsCulture = (props) => {
	const [active, setActive] = useState(props.active);
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 36 37"
				className={active ? "active" : ""}
			>
				<g>
					<path
						d="M27.4,15v-1.7c0-0.1-0.1-0.2-0.2-0.3l-9.1-4.1c-0.1,0-0.2,0-0.3,0l-9,4.1c-0.1,0-0.2,0.2-0.2,0.3V15c0,0.2,0.1,0.3,0.3,0.3
		h1.4c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.4,0.2,0.7,0.5,0.9v8.9H9.8c-0.6,0-1.2,0.5-1.2,1.2V27c0,0.6,0.5,1.2,1.2,1.2h16.4
		c0.6,0,1.2-0.5,1.2-1.2v-0.2c0-0.6-0.5-1.2-1.2-1.2h-0.9v-8.9c0.3-0.2,0.5-0.5,0.5-0.9c0-0.2-0.1-0.4-0.1-0.5H27
		C27.2,15.3,27.4,15.2,27.4,15z M26.2,26.3c0.3,0,0.6,0.3,0.6,0.6V27c0,0.3-0.3,0.6-0.6,0.6H9.8c-0.3,0-0.6-0.3-0.6-0.6v-0.2
		c0-0.3,0.3-0.6,0.6-0.6H26.2z M11.3,16.3c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5H14c0.3,0,0.5,0.2,0.5,0.5
		c0,0.3-0.2,0.5-0.5,0.5H11.3z M13.9,16.9v8.8h-2.6v-8.8L13.9,16.9z M14.9,15.3h0.8c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.4,0.2,0.7,0.5,0.9
		v8.9h-1.6v-8.9c0.3-0.2,0.5-0.5,0.5-0.9C15.1,15.6,15,15.4,14.9,15.3z M16.6,16.3c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5
		h2.8c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5H16.6z M19.3,16.9v8.8h-2.6v-8.8L19.3,16.9z M20.3,15.3h0.8
		c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.4,0.2,0.7,0.5,0.9v8.9h-1.6v-8.9c0.3-0.2,0.5-0.5,0.5-0.9C20.4,15.6,20.4,15.4,20.3,15.3z M22,16.3
		c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h2.8c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5H22z M24.7,16.9v8.8h-2.6v-8.8
		L24.7,16.9z M26.8,14.7H9.3v-1.2l8.7-4l8.8,4V14.7z"
					/>
					<path d="M17.7,10.5v3.7c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3v-3.7c0-0.2-0.1-0.3-0.3-0.3S17.7,10.4,17.7,10.5z" />
					<path d="M16,10.7c-0.2,0-0.3,0.1-0.3,0.3v3.2c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3V11C16.3,10.8,16.2,10.7,16,10.7z" />
					<path d="M14.1,11.6c-0.2,0-0.3,0.1-0.3,0.3v2.4c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3v-2.4C14.4,11.7,14.3,11.6,14.1,11.6z" />
					<path d="M12.2,12.4c-0.2,0-0.3,0.1-0.3,0.3v1.5c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3v-1.5C12.5,12.5,12.3,12.4,12.2,12.4z" />
					<path d="M10.2,13.2c-0.2,0-0.3,0.1-0.3,0.3v0.7c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3v-0.7C10.5,13.4,10.4,13.2,10.2,13.2z" />
					<path d="M20,14.5c0.2,0,0.3-0.1,0.3-0.3V11c0-0.2-0.1-0.3-0.3-0.3s-0.3,0.1-0.3,0.3v3.2C19.7,14.4,19.8,14.5,20,14.5z" />
					<path d="M21.9,14.5c0.2,0,0.3-0.1,0.3-0.3v-2.4c0-0.2-0.1-0.3-0.3-0.3s-0.3,0.1-0.3,0.3v2.4C21.6,14.4,21.7,14.5,21.9,14.5z" />
					<path d="M23.8,14.5c0.2,0,0.3-0.1,0.3-0.3v-1.5c0-0.2-0.1-0.3-0.3-0.3s-0.3,0.1-0.3,0.3v1.5C23.5,14.4,23.7,14.5,23.8,14.5z" />
					<path d="M25.8,14.5c0.2,0,0.3-0.1,0.3-0.3v-0.7c0-0.2-0.1-0.3-0.3-0.3s-0.3,0.1-0.3,0.3v0.7C25.5,14.4,25.6,14.5,25.8,14.5z" />
				</g>
			</svg>
		</>
	);
};

export default IconArtsCulture;
