const OregonTrailBearLake = {
    type: "FeatureCollection",
    features: [{
        type: "Feature",
        properties: {
            name: "Oregon Trail-Bear Lake Scenic Byway",
            "description": "Natural splendor and the promise of adventure lures travelers to southeastern Idaho and the Oregon Trail-Bear Lake Scenic Byway. Bear Lake boasts shimmering turquoise waters, sandy beaches and magnificent views. It's no wonder it's known as “The Caribbean of the Rockies”! Along this scenic route, check out the world's only captive geyser, Minnetonka Cave, historic sites, and a variety of experiences tied to the Oregon Trail.",
            "link": "https://visitidaho.org/things-to-do/road-trips/oregon-trail-bear-lake-scenic-byway/",
            "route": [
                "McCammon", 
                "Paris",
                "St. Charles",
                "Montpelier",
                "Soda Springs",
                "Bancroft",
                "Lava Hot Springs"
            ],
            "shape": "Line"
        },
        geometry: {
            type: "LineString",
            coordinates: [
                [-111.3959, 42.03719, 0],
                [-111.39587, 42.03745, 0],
                [-111.39582, 42.03779, 0],
                [-111.39573, 42.03854, 0],
                [-111.39569, 42.03881, 0],
                [-111.39565, 42.0392, 0],
                [-111.39549, 42.04045, 0],
                [-111.3953, 42.04209, 0],
                [-111.39526, 42.04253, 0],
                [-111.39523, 42.04275, 0],
                [-111.39508, 42.04416, 0],
                [-111.39507, 42.04432, 0],
                [-111.39493, 42.04559, 0],
                [-111.39487, 42.04626, 0],
                [-111.39475, 42.04715, 0],
                [-111.39459, 42.05086, 0],
                [-111.39457, 42.05114, 0],
                [-111.39452, 42.05162, 0],
                [-111.39442, 42.05237, 0],
                [-111.39438, 42.05262, 0],
                [-111.39428, 42.05311, 0],
                [-111.39421, 42.05336, 0],
                [-111.39415, 42.05363, 0],
                [-111.3941, 42.05379, 0],
                [-111.39405, 42.05397, 0],
                [-111.39394, 42.05432, 0],
                [-111.39373, 42.0549, 0],
                [-111.39366, 42.05506, 0],
                [-111.3936, 42.05522, 0],
                [-111.39347, 42.05551, 0],
                [-111.39334, 42.05578, 0],
                [-111.39306, 42.05631, 0],
                [-111.39303, 42.05636, 0],
                [-111.393, 42.05642, 0],
                [-111.39284, 42.0567, 0],
                [-111.39276, 42.05682, 0],
                [-111.39269, 42.05694, 0],
                [-111.39263, 42.05703, 0],
                [-111.39253, 42.0572, 0],
                [-111.39246, 42.05731, 0],
                [-111.39232, 42.05751, 0],
                [-111.39217, 42.05774, 0],
                [-111.39156, 42.05856, 0],
                [-111.39001, 42.06069, 0],
                [-111.38962, 42.06128, 0],
                [-111.38945, 42.06158, 0],
                [-111.38944, 42.06159, 0],
                [-111.3893, 42.06185, 0],
                [-111.38901, 42.06244, 0],
                [-111.3888, 42.06298, 0],
                [-111.38872, 42.0632, 0],
                [-111.38867, 42.06338, 0],
                [-111.38865, 42.06343, 0],
                [-111.38865, 42.06345, 0],
                [-111.38859, 42.06368, 0],
                [-111.38856, 42.06377, 0],
                [-111.3885, 42.06406, 0],
                [-111.38848, 42.06413, 0],
                [-111.38843, 42.06436, 0],
                [-111.38843, 42.06438, 0],
                [-111.38841, 42.06448, 0],
                [-111.38839, 42.06472, 0],
                [-111.38836, 42.06494, 0],
                [-111.38834, 42.06521, 0],
                [-111.38833, 42.06523, 0],
                [-111.38834, 42.06633, 0],
                [-111.38835, 42.06646, 0],
                [-111.38835, 42.06664, 0],
                [-111.38843, 42.06806, 0],
                [-111.38845, 42.06863, 0],
                [-111.38858, 42.07869, 0],
                [-111.38857, 42.08037, 0],
                [-111.38864, 42.09046, 0],
                [-111.38864, 42.09265, 0],
                [-111.38872, 42.09717, 0],
                [-111.3887, 42.09943, 0],
                [-111.38874, 42.09983, 0],
                [-111.38874, 42.10035, 0],
                [-111.38876, 42.10157, 0],
                [-111.38876, 42.10272, 0],
                [-111.38879, 42.10521, 0],
                [-111.38879, 42.10776, 0],
                [-111.3888, 42.10803, 0],
                [-111.38881, 42.11535, 0],
                [-111.38882, 42.11563, 0],
                [-111.38883, 42.11631, 0],
                [-111.3889, 42.11726, 0],
                [-111.38901, 42.11811, 0],
                [-111.3891, 42.11864, 0],
                [-111.38916, 42.11892, 0],
                [-111.38918, 42.11903, 0],
                [-111.38921, 42.11912, 0],
                [-111.38926, 42.11937, 0],
                [-111.38928, 42.11942, 0],
                [-111.3893, 42.1195, 0],
                [-111.38932, 42.1196, 0],
                [-111.38936, 42.11973, 0],
                [-111.38939, 42.11986, 0],
                [-111.38947, 42.12015, 0],
                [-111.38959, 42.12053, 0],
                [-111.3896, 42.12057, 0],
                [-111.38964, 42.12068, 0],
                [-111.38968, 42.12081, 0],
                [-111.38978, 42.12108, 0],
                [-111.38983, 42.12124, 0],
                [-111.38997, 42.12158, 0],
                [-111.38998, 42.12161, 0],
                [-111.39026, 42.12227, 0],
                [-111.39038, 42.12252, 0],
                [-111.39051, 42.12283, 0],
                [-111.39064, 42.1231, 0],
                [-111.39095, 42.12385, 0],
                [-111.39097, 42.12391, 0],
                [-111.39115, 42.12438, 0],
                [-111.39133, 42.12488, 0],
                [-111.39142, 42.12516, 0],
                [-111.39148, 42.12533, 0],
                [-111.3917, 42.12605, 0],
                [-111.39181, 42.12647, 0],
                [-111.3919, 42.12678, 0],
                [-111.39199, 42.12715, 0],
                [-111.39212, 42.12777, 0],
                [-111.39219, 42.12807, 0],
                [-111.39247, 42.12943, 0],
                [-111.39576, 42.14469, 0],
                [-111.39582, 42.14493, 0],
                [-111.39585, 42.14509, 0],
                [-111.39591, 42.14536, 0],
                [-111.39626, 42.14712, 0],
                [-111.39645, 42.14822, 0],
                [-111.39646, 42.14834, 0],
                [-111.39649, 42.14849, 0],
                [-111.39667, 42.14971, 0],
                [-111.39675, 42.15034, 0],
                [-111.397, 42.15206, 0],
                [-111.39703, 42.15232, 0],
                [-111.39733, 42.15441, 0],
                [-111.39738, 42.15483, 0],
                [-111.3975, 42.15563, 0],
                [-111.39763, 42.15662, 0],
                [-111.39765, 42.15673, 0],
                [-111.3978, 42.15784, 0],
                [-111.39781, 42.15787, 0],
                [-111.39788, 42.15837, 0],
                [-111.39789, 42.15841, 0],
                [-111.39853, 42.16305, 0],
                [-111.39853, 42.16306, 0],
                [-111.39878, 42.16496, 0],
                [-111.39887, 42.16557, 0],
                [-111.39899, 42.16648, 0],
                [-111.39911, 42.1673, 0],
                [-111.39911, 42.16735, 0],
                [-111.39962, 42.17106, 0],
                [-111.39964, 42.17126, 0],
                [-111.39972, 42.17181, 0],
                [-111.39977, 42.17222, 0],
                [-111.40033, 42.17627, 0],
                [-111.40035, 42.17645, 0],
                [-111.40053, 42.17771, 0],
                [-111.40062, 42.17841, 0],
                [-111.40068, 42.1788, 0],
                [-111.40079, 42.17965, 0],
                [-111.40106, 42.18155, 0],
                [-111.40128, 42.18324, 0],
                [-111.4013, 42.18346, 0],
                [-111.40131, 42.1835, 0],
                [-111.40136, 42.18418, 0],
                [-111.40137, 42.18468, 0],
                [-111.40137, 42.1858, 0],
                [-111.40136, 42.1861, 0],
                [-111.40131, 42.19672, 0],
                [-111.40129, 42.19781, 0],
                [-111.40128, 42.19796, 0],
                [-111.40124, 42.20006, 0],
                [-111.40123, 42.2002, 0],
                [-111.40121, 42.20085, 0],
                [-111.40121, 42.20114, 0],
                [-111.40112, 42.20555, 0],
                [-111.40112, 42.20659, 0],
                [-111.40111, 42.20695, 0],
                [-111.40111, 42.20713, 0],
                [-111.4011, 42.20744, 0],
                [-111.4011, 42.20921, 0],
                [-111.40107, 42.21163, 0],
                [-111.40107, 42.21258, 0],
                [-111.40106, 42.21296, 0],
                [-111.40106, 42.21494, 0],
                [-111.40105, 42.2154, 0],
                [-111.40105, 42.21705, 0],
                [-111.40104, 42.21767, 0],
                [-111.40103, 42.22125, 0],
                [-111.40102, 42.22192, 0],
                [-111.40103, 42.22255, 0],
                [-111.40102, 42.22312, 0],
                [-111.401, 42.22983, 0],
                [-111.40098, 42.23118, 0],
                [-111.40097, 42.2355, 0],
                [-111.40095, 42.23684, 0],
                [-111.40091, 42.24467, 0],
                [-111.4009, 42.24489, 0],
                [-111.40086, 42.25412, 0],
                [-111.40084, 42.25519, 0],
                [-111.40083, 42.25709, 0],
                [-111.40082, 42.25738, 0],
                [-111.40082, 42.2598, 0],
                [-111.40081, 42.26021, 0],
                [-111.40081, 42.26193, 0],
                [-111.4008, 42.26235, 0],
                [-111.4008, 42.26327, 0],
                [-111.40079, 42.26347, 0],
                [-111.40078, 42.26637, 0],
                [-111.40077, 42.2667, 0],
                [-111.40077, 42.26806, 0],
                [-111.40076, 42.26853, 0],
                [-111.40076, 42.26952, 0],
                [-111.40075, 42.27005, 0],
                [-111.40075, 42.27047, 0],
                [-111.40074, 42.27093, 0],
                [-111.40074, 42.27354, 0],
                [-111.40073, 42.27388, 0],
                [-111.40073, 42.27555, 0],
                [-111.40072, 42.27576, 0],
                [-111.40072, 42.27622, 0],
                [-111.40068, 42.27651, 0],
                [-111.40066, 42.27661, 0],
                [-111.40066, 42.27663, 0],
                [-111.40063, 42.27681, 0],
                [-111.40056, 42.27714, 0],
                [-111.40052, 42.27729, 0],
                [-111.40032, 42.2779, 0],
                [-111.39991, 42.27903, 0],
                [-111.39987, 42.27912, 0],
                [-111.39975, 42.27945, 0],
                [-111.39974, 42.27946, 0],
                [-111.3996, 42.27986, 0],
                [-111.39943, 42.28031, 0],
                [-111.39928, 42.28068, 0],
                [-111.39902, 42.28139, 0],
                [-111.399, 42.28143, 0],
                [-111.39884, 42.28187, 0],
                [-111.39883, 42.28191, 0],
                [-111.39865, 42.28243, 0],
                [-111.3986, 42.28262, 0],
                [-111.39858, 42.28272, 0],
                [-111.39854, 42.28287, 0],
                [-111.39851, 42.28301, 0],
                [-111.39849, 42.28313, 0],
                [-111.39846, 42.28336, 0],
                [-111.39844, 42.28345, 0],
                [-111.39843, 42.28354, 0],
                [-111.39841, 42.28382, 0],
                [-111.3984, 42.28407, 0],
                [-111.39839, 42.28631, 0],
                [-111.39841, 42.28724, 0],
                [-111.3984, 42.28752, 0],
                [-111.39839, 42.2876, 0],
                [-111.39823, 42.28788, 0],
                [-111.39816, 42.28797, 0],
                [-111.39811, 42.28802, 0],
                [-111.39808, 42.28806, 0],
                [-111.39805, 42.28808, 0],
                [-111.398, 42.28812, 0],
                [-111.39797, 42.28815, 0],
                [-111.3979, 42.2882, 0],
                [-111.39776, 42.28829, 0],
                [-111.39764, 42.28835, 0],
                [-111.3974, 42.28843, 0],
                [-111.39733, 42.28844, 0],
                [-111.39725, 42.28846, 0],
                [-111.39709, 42.28849, 0],
                [-111.39696, 42.2885, 0],
                [-111.39653, 42.28851, 0],
                [-111.39507, 42.28851, 0],
                [-111.39455, 42.2885, 0],
                [-111.39408, 42.2885, 0],
                [-111.39356, 42.28849, 0],
                [-111.3883, 42.28847, 0],
                [-111.38809, 42.28848, 0],
                [-111.3877, 42.28848, 0],
                [-111.38763, 42.28849, 0],
                [-111.38755, 42.28849, 0],
                [-111.3871, 42.28854, 0],
                [-111.38702, 42.28856, 0],
                [-111.38678, 42.2886, 0],
                [-111.38667, 42.28863, 0],
                [-111.3865, 42.28867, 0],
                [-111.38599, 42.28885, 0],
                [-111.38574, 42.28895, 0],
                [-111.3855, 42.28907, 0],
                [-111.38505, 42.28933, 0],
                [-111.38461, 42.28961, 0],
                [-111.37935, 42.29307, 0],
                [-111.37894, 42.29335, 0],
                [-111.3781, 42.29389, 0],
                [-111.37769, 42.29417, 0],
                [-111.37728, 42.29443, 0],
                [-111.3706, 42.29883, 0],
                [-111.37047, 42.29891, 0],
                [-111.36834, 42.3003, 0],
                [-111.36785, 42.30063, 0],
                [-111.36743, 42.3009, 0],
                [-111.36742, 42.30091, 0],
                [-111.3645, 42.30282, 0],
                [-111.36386, 42.30325, 0],
                [-111.36283, 42.30392, 0],
                [-111.36218, 42.30431, 0],
                [-111.36191, 42.30446, 0],
                [-111.36155, 42.30465, 0],
                [-111.36142, 42.3047, 0],
                [-111.36115, 42.30482, 0],
                [-111.36099, 42.30487, 0],
                [-111.36091, 42.3049, 0],
                [-111.36064, 42.30499, 0],
                [-111.35317, 42.30706, 0],
                [-111.35256, 42.30722, 0],
                [-111.3505, 42.3078, 0],
                [-111.35035, 42.30785, 0],
                [-111.34971, 42.30802, 0],
                [-111.34966, 42.30804, 0],
                [-111.34865, 42.30831, 0],
                [-111.34813, 42.30844, 0],
                [-111.34785, 42.3085, 0],
                [-111.34771, 42.30852, 0],
                [-111.34738, 42.30858, 0],
                [-111.34428, 42.30895, 0],
                [-111.342, 42.30925, 0],
                [-111.34149, 42.30933, 0],
                [-111.33968, 42.30956, 0],
                [-111.33924, 42.30963, 0],
                [-111.33905, 42.30967, 0],
                [-111.3389, 42.30971, 0],
                [-111.33875, 42.30974, 0],
                [-111.33845, 42.30983, 0],
                [-111.33831, 42.30988, 0],
                [-111.33821, 42.30992, 0],
                [-111.3382, 42.30993, 0],
                [-111.33806, 42.30998, 0],
                [-111.338, 42.31001, 0],
                [-111.33779, 42.3101, 0],
                [-111.33772, 42.31014, 0],
                [-111.3376, 42.3102, 0],
                [-111.33666, 42.31075, 0],
                [-111.3306, 42.31443, 0],
                [-111.32982, 42.31489, 0],
                [-111.32827, 42.31584, 0],
                [-111.32796, 42.31602, 0],
                [-111.32777, 42.31614, 0],
                [-111.3273, 42.31641, 0],
                [-111.32667, 42.31679, 0],
                [-111.32656, 42.31684, 0],
                [-111.32638, 42.31694, 0],
                [-111.326, 42.31712, 0],
                [-111.32558, 42.31727, 0],
                [-111.32514, 42.3174, 0],
                [-111.32464, 42.31751, 0],
                [-111.32451, 42.31753, 0],
                [-111.32441, 42.31755, 0],
                [-111.32431, 42.31756, 0],
                [-111.32418, 42.31758, 0],
                [-111.32409, 42.31759, 0],
                [-111.32393, 42.3176, 0],
                [-111.32377, 42.3176, 0],
                [-111.32344, 42.31762, 0],
                [-111.32163, 42.31762, 0],
                [-111.32118, 42.31761, 0],
                [-111.31126, 42.31762, 0],
                [-111.31116, 42.31759, 0],
                [-111.31044, 42.31759, 0],
                [-111.30899, 42.31757, 0],
                [-111.30591, 42.31758, 0],
                [-111.30572, 42.31759, 0],
                [-111.30568, 42.31759, 0],
                [-111.30564, 42.3176, 0],
                [-111.30555, 42.31763, 0],
                [-111.29781, 42.31767, 0],
                [-111.29781, 42.32264, 0],
                [-111.2978, 42.32299, 0],
                [-111.29781, 42.32996, 0],
                [-111.29782, 42.33015, 0],
                [-111.29781, 42.33202, 0],
                [-111.2977, 42.33579, 0],
                [-111.29769, 42.33588, 0],
                [-111.29764, 42.33895, 0],
                [-111.29762, 42.33961, 0],
                [-111.29761, 42.34076, 0],
                [-111.29759, 42.34127, 0],
                [-111.29756, 42.34257, 0],
                [-111.29756, 42.34319, 0],
                [-111.29757, 42.34362, 0],
                [-111.29759, 42.34385, 0],
                [-111.29759, 42.34393, 0],
                [-111.29761, 42.34406, 0],
                [-111.29761, 42.34411, 0],
                [-111.29767, 42.34462, 0],
                [-111.29768, 42.34474, 0],
                [-111.29774, 42.34509, 0],
                [-111.29784, 42.34557, 0],
                [-111.2979, 42.34582, 0],
                [-111.29791, 42.34589, 0],
                [-111.29796, 42.34609, 0],
                [-111.29818, 42.34679, 0],
                [-111.29829, 42.34711, 0],
                [-111.29873, 42.34818, 0],
                [-111.29892, 42.34857, 0],
                [-111.29902, 42.34874, 0],
                [-111.29915, 42.34898, 0],
                [-111.29943, 42.34947, 0],
                [-111.2997, 42.3499, 0],
                [-111.29971, 42.34991, 0],
                [-111.29979, 42.35005, 0],
                [-111.30014, 42.35058, 0],
                [-111.30044, 42.35101, 0],
                [-111.30051, 42.35113, 0],
                [-111.30173, 42.35296, 0],
                [-111.30224, 42.35376, 0],
                [-111.30492, 42.3578, 0],
                [-111.30508, 42.35803, 0],
                [-111.3075, 42.36173, 0],
                [-111.30873, 42.36354, 0],
                [-111.31073, 42.36656, 0],
                [-111.31252, 42.36931, 0],
                [-111.31307, 42.37026, 0],
                [-111.31308, 42.37029, 0],
                [-111.31344, 42.37095, 0],
                [-111.31392, 42.37195, 0],
                [-111.31409, 42.37233, 0],
                [-111.31428, 42.37273, 0],
                [-111.31493, 42.37416, 0],
                [-111.315, 42.37433, 0],
                [-111.31509, 42.37451, 0],
                [-111.31562, 42.3757, 0],
                [-111.31573, 42.37596, 0],
                [-111.31747, 42.3798, 0],
                [-111.31754, 42.37997, 0],
                [-111.31756, 42.38, 0],
                [-111.31869, 42.38253, 0],
                [-111.32038, 42.38624, 0],
                [-111.32045, 42.38638, 0],
                [-111.32389, 42.39398, 0],
                [-111.3241, 42.39446, 0],
                [-111.32423, 42.39473, 0],
                [-111.32455, 42.39546, 0],
                [-111.32953, 42.40643, 0],
                [-111.3299, 42.40727, 0],
                [-111.332, 42.41189, 0],
                [-111.33208, 42.41208, 0],
                [-111.33309, 42.41427, 0],
                [-111.33352, 42.41525, 0],
                [-111.34007, 42.42968, 0],
                [-111.34007, 42.42982, 0],
                [-111.34008, 42.42983, 0],
                [-111.34009, 42.42985, 0],
                [-111.34026, 42.43025, 0],
                [-111.34111, 42.43212, 0],
                [-111.34169, 42.43335, 0],
                [-111.34188, 42.43372, 0],
                [-111.34213, 42.43423, 0],
                [-111.34243, 42.4348, 0],
                [-111.34314, 42.43609, 0],
                [-111.34329, 42.43635, 0],
                [-111.3442, 42.43784, 0],
                [-111.34474, 42.43867, 0],
                [-111.34478, 42.43872, 0],
                [-111.34502, 42.43909, 0],
                [-111.34507, 42.43915, 0],
                [-111.34514, 42.43926, 0],
                [-111.34598, 42.44041, 0],
                [-111.346, 42.44045, 0],
                [-111.3463, 42.44084, 0],
                [-111.34647, 42.44108, 0],
                [-111.34692, 42.44167, 0],
                [-111.34693, 42.44168, 0],
                [-111.34693, 42.44169, 0],
                [-111.34694, 42.44169, 0],
                [-111.34703, 42.44174, 0],
                [-111.3483, 42.44331, 0],
                [-111.34848, 42.44351, 0],
                [-111.34865, 42.44371, 0],
                [-111.34963, 42.44482, 0],
                [-111.35566, 42.45153, 0],
                [-111.35599, 42.45191, 0],
                [-111.35814, 42.45429, 0],
                [-111.35847, 42.45467, 0],
                [-111.3601, 42.45646, 0],
                [-111.36048, 42.45689, 0],
                [-111.36054, 42.45699, 0],
                [-111.36084, 42.45733, 0],
                [-111.36102, 42.45755, 0],
                [-111.36138, 42.45796, 0],
                [-111.36154, 42.45816, 0],
                [-111.36157, 42.45821, 0],
                [-111.36161, 42.45825, 0],
                [-111.36173, 42.45842, 0],
                [-111.36183, 42.45862, 0],
                [-111.36199, 42.45891, 0],
                [-111.36208, 42.4591, 0],
                [-111.3621, 42.45912, 0],
                [-111.36217, 42.45926, 0],
                [-111.36224, 42.45943, 0],
                [-111.36239, 42.45975, 0],
                [-111.3624, 42.45978, 0],
                [-111.3624, 42.45979, 0],
                [-111.36241, 42.45979, 0],
                [-111.36241, 42.4598, 0],
                [-111.36245, 42.45983, 0],
                [-111.36263, 42.46027, 0],
                [-111.3628, 42.46071, 0],
                [-111.36287, 42.46086, 0],
                [-111.36323, 42.46177, 0],
                [-111.36524, 42.46664, 0],
                [-111.36527, 42.4667, 0],
                [-111.3654, 42.46703, 0],
                [-111.36554, 42.46735, 0],
                [-111.36688, 42.47067, 0],
                [-111.36699, 42.47091, 0],
                [-111.36705, 42.47103, 0],
                [-111.36731, 42.47144, 0],
                [-111.36766, 42.47197, 0],
                [-111.36767, 42.47198, 0],
                [-111.37427, 42.4818, 0],
                [-111.37454, 42.48223, 0],
                [-111.37498, 42.48287, 0],
                [-111.37501, 42.48291, 0],
                [-111.37546, 42.4836, 0],
                [-111.37562, 42.48382, 0],
                [-111.3765, 42.48512, 0],
                [-111.37656, 42.4852, 0],
                [-111.37661, 42.48528, 0],
                [-111.37694, 42.48576, 0],
                [-111.37701, 42.48587, 0],
                [-111.37705, 42.48592, 0],
                [-111.37782, 42.48706, 0],
                [-111.37792, 42.48722, 0],
                [-111.378, 42.48733, 0],
                [-111.37831, 42.48779, 0],
                [-111.37836, 42.48785, 0],
                [-111.37837, 42.48786, 0],
                [-111.37841, 42.48792, 0],
                [-111.37849, 42.48802, 0],
                [-111.3785, 42.48803, 0],
                [-111.37856, 42.48812, 0],
                [-111.37863, 42.48819, 0],
                [-111.37869, 42.48827, 0],
                [-111.37873, 42.4883, 0],
                [-111.37875, 42.48832, 0],
                [-111.37876, 42.48834, 0],
                [-111.37889, 42.48847, 0],
                [-111.37893, 42.4885, 0],
                [-111.37904, 42.48861, 0],
                [-111.37908, 42.48864, 0],
                [-111.37914, 42.4887, 0],
                [-111.37925, 42.48878, 0],
                [-111.37934, 42.48886, 0],
                [-111.37937, 42.48888, 0],
                [-111.37939, 42.48889, 0],
                [-111.37954, 42.48901, 0],
                [-111.37969, 42.48912, 0],
                [-111.37974, 42.48916, 0],
                [-111.37986, 42.48924, 0],
                [-111.37992, 42.48929, 0],
                [-111.38048, 42.48966, 0],
                [-111.38055, 42.48971, 0],
                [-111.38064, 42.48976, 0],
                [-111.38076, 42.48984, 0],
                [-111.38088, 42.4899, 0],
                [-111.38092, 42.48993, 0],
                [-111.38098, 42.48996, 0],
                [-111.38103, 42.48998, 0],
                [-111.38109, 42.49001, 0],
                [-111.38113, 42.49004, 0],
                [-111.38118, 42.49006, 0],
                [-111.38128, 42.49011, 0],
                [-111.38133, 42.49013, 0],
                [-111.38137, 42.49015, 0],
                [-111.38147, 42.49019, 0],
                [-111.38153, 42.49022, 0],
                [-111.38159, 42.49024, 0],
                [-111.38163, 42.49026, 0],
                [-111.38168, 42.49028, 0],
                [-111.38172, 42.49029, 0],
                [-111.38176, 42.49031, 0],
                [-111.3818, 42.49032, 0],
                [-111.38183, 42.49033, 0],
                [-111.38184, 42.49034, 0],
                [-111.38189, 42.49036, 0],
                [-111.38198, 42.49039, 0],
                [-111.38202, 42.4904, 0],
                [-111.3822, 42.49047, 0],
                [-111.3824, 42.49053, 0],
                [-111.38331, 42.49083, 0],
                [-111.38338, 42.49086, 0],
                [-111.38372, 42.49097, 0],
                [-111.38388, 42.49103, 0],
                [-111.38407, 42.49109, 0],
                [-111.38419, 42.49114, 0],
                [-111.38433, 42.49119, 0],
                [-111.38442, 42.49123, 0],
                [-111.38454, 42.49127, 0],
                [-111.38493, 42.49144, 0],
                [-111.38505, 42.49151, 0],
                [-111.38523, 42.4916, 0],
                [-111.38536, 42.49168, 0],
                [-111.38542, 42.49171, 0],
                [-111.38549, 42.49175, 0],
                [-111.3855, 42.49176, 0],
                [-111.38557, 42.4918, 0],
                [-111.38562, 42.49184, 0],
                [-111.38566, 42.49186, 0],
                [-111.38567, 42.49187, 0],
                [-111.38579, 42.49195, 0],
                [-111.38583, 42.49198, 0],
                [-111.38586, 42.49201, 0],
                [-111.38589, 42.49203, 0],
                [-111.38591, 42.49205, 0],
                [-111.38599, 42.49211, 0],
                [-111.38601, 42.49213, 0],
                [-111.38603, 42.49214, 0],
                [-111.38612, 42.49221, 0],
                [-111.38615, 42.49223, 0],
                [-111.38624, 42.49231, 0],
                [-111.38638, 42.49245, 0],
                [-111.38648, 42.49254, 0],
                [-111.38672, 42.49278, 0],
                [-111.38675, 42.49282, 0],
                [-111.3868, 42.49287, 0],
                [-111.38703, 42.49315, 0],
                [-111.38707, 42.49321, 0],
                [-111.38712, 42.49327, 0],
                [-111.38725, 42.49347, 0],
                [-111.3873, 42.49356, 0],
                [-111.38731, 42.49359, 0],
                [-111.38733, 42.49363, 0],
                [-111.38736, 42.49367, 0],
                [-111.38739, 42.49373, 0],
                [-111.38741, 42.49378, 0],
                [-111.38743, 42.49381, 0],
                [-111.38745, 42.49387, 0],
                [-111.38749, 42.49395, 0],
                [-111.38749, 42.49397, 0],
                [-111.38752, 42.49402, 0],
                [-111.38757, 42.49414, 0],
                [-111.38763, 42.49431, 0],
                [-111.38816, 42.4957, 0],
                [-111.38835, 42.49624, 0],
                [-111.38854, 42.49674, 0],
                [-111.38868, 42.49708, 0],
                [-111.39005, 42.50066, 0],
                [-111.39051, 42.50192, 0],
                [-111.39118, 42.50367, 0],
                [-111.39124, 42.50384, 0],
                [-111.39157, 42.50471, 0],
                [-111.39164, 42.50488, 0],
                [-111.39254, 42.50723, 0],
                [-111.39333, 42.5094, 0],
                [-111.39344, 42.50968, 0],
                [-111.39362, 42.51018, 0],
                [-111.39363, 42.51019, 0],
                [-111.39366, 42.51029, 0],
                [-111.39401, 42.5112, 0],
                [-111.3941, 42.51147, 0],
                [-111.3942, 42.51173, 0],
                [-111.3947, 42.51296, 0],
                [-111.39472, 42.51303, 0],
                [-111.39486, 42.51341, 0],
                [-111.39504, 42.51386, 0],
                [-111.39511, 42.51407, 0],
                [-111.39518, 42.51426, 0],
                [-111.39533, 42.5146, 0],
                [-111.39544, 42.51483, 0],
                [-111.39545, 42.51486, 0],
                [-111.39569, 42.51532, 0],
                [-111.39588, 42.51566, 0],
                [-111.39621, 42.51618, 0],
                [-111.39622, 42.51618, 0],
                [-111.39628, 42.51627, 0],
                [-111.39679, 42.51696, 0],
                [-111.39712, 42.51735, 0],
                [-111.39755, 42.51779, 0],
                [-111.39785, 42.51812, 0],
                [-111.39971, 42.52007, 0],
                [-111.39978, 42.52015, 0],
                [-111.40033, 42.52073, 0],
                [-111.40069, 42.52109, 0],
                [-111.40138, 42.52185, 0],
                [-111.40154, 42.52205, 0],
                [-111.40178, 42.52237, 0],
                [-111.40189, 42.52253, 0],
                [-111.40206, 42.5228, 0],
                [-111.40214, 42.52296, 0],
                [-111.40219, 42.52304, 0],
                [-111.40236, 42.52337, 0],
                [-111.4024, 42.52344, 0],
                [-111.40251, 42.5237, 0],
                [-111.40254, 42.52379, 0],
                [-111.40256, 42.52382, 0],
                [-111.40314, 42.52551, 0],
                [-111.40316, 42.52556, 0],
                [-111.40354, 42.52666, 0],
                [-111.40361, 42.52689, 0],
                [-111.40372, 42.52719, 0],
                [-111.40375, 42.52724, 0],
                [-111.40375, 42.52734, 0],
                [-111.40376, 42.52736, 0],
                [-111.40376, 42.52738, 0],
                [-111.40395, 42.52775, 0],
                [-111.40409, 42.52798, 0],
                [-111.40413, 42.52804, 0],
                [-111.40419, 42.52815, 0],
                [-111.40436, 42.52837, 0],
                [-111.40439, 42.52842, 0],
                [-111.40445, 42.5285, 0],
                [-111.4046, 42.52868, 0],
                [-111.40466, 42.52876, 0],
                [-111.40479, 42.52891, 0],
                [-111.40486, 42.52897, 0],
                [-111.40492, 42.52905, 0],
                [-111.40499, 42.52911, 0],
                [-111.40521, 42.52933, 0],
                [-111.4056, 42.52966, 0],
                [-111.40571, 42.52974, 0],
                [-111.40583, 42.52984, 0],
                [-111.40589, 42.52986, 0],
                [-111.40598, 42.52988, 0],
                [-111.40661, 42.53027, 0],
                [-111.4067, 42.53033, 0],
                [-111.40701, 42.5305, 0],
                [-111.40744, 42.53075, 0],
                [-111.41245, 42.53352, 0],
                [-111.41287, 42.53376, 0],
                [-111.41325, 42.53399, 0],
                [-111.4135, 42.53415, 0],
                [-111.41362, 42.53424, 0],
                [-111.41374, 42.53432, 0],
                [-111.41398, 42.53451, 0],
                [-111.41413, 42.53462, 0],
                [-111.41418, 42.53467, 0],
                [-111.41425, 42.53472, 0],
                [-111.41429, 42.53476, 0],
                [-111.41442, 42.53487, 0],
                [-111.4145, 42.53495, 0],
                [-111.41461, 42.53504, 0],
                [-111.41467, 42.53511, 0],
                [-111.41472, 42.53515, 0],
                [-111.41476, 42.5352, 0],
                [-111.41499, 42.53543, 0],
                [-111.41503, 42.53548, 0],
                [-111.41515, 42.5356, 0],
                [-111.4152, 42.53567, 0],
                [-111.4153, 42.53578, 0],
                [-111.41536, 42.53586, 0],
                [-111.41545, 42.53597, 0],
                [-111.41553, 42.53608, 0],
                [-111.41559, 42.53617, 0],
                [-111.4158, 42.53645, 0],
                [-111.4158, 42.53646, 0],
                [-111.41676, 42.53784, 0],
                [-111.41709, 42.53828, 0],
                [-111.41728, 42.5385, 0],
                [-111.41756, 42.53881, 0],
                [-111.41763, 42.53887, 0],
                [-111.41782, 42.53906, 0],
                [-111.41799, 42.53922, 0],
                [-111.41814, 42.53934, 0],
                [-111.41841, 42.53957, 0],
                [-111.41862, 42.53973, 0],
                [-111.41868, 42.53977, 0],
                [-111.41872, 42.5398, 0],
                [-111.41878, 42.53985, 0],
                [-111.41892, 42.53994, 0],
                [-111.41902, 42.54001, 0],
                [-111.41903, 42.54001, 0],
                [-111.41946, 42.5403, 0],
                [-111.41964, 42.54041, 0],
                [-111.41975, 42.54047, 0],
                [-111.42005, 42.54065, 0],
                [-111.4349, 42.54899, 0],
                [-111.43584, 42.54953, 0],
                [-111.44399, 42.55411, 0],
                [-111.44424, 42.55426, 0],
                [-111.46662, 42.56679, 0],
                [-111.46737, 42.56722, 0],
                [-111.46815, 42.56765, 0],
                [-111.47208, 42.56987, 0],
                [-111.47239, 42.57006, 0],
                [-111.4733, 42.57066, 0],
                [-111.47382, 42.57104, 0],
                [-111.47465, 42.5717, 0],
                [-111.47512, 42.57211, 0],
                [-111.47536, 42.57233, 0],
                [-111.47586, 42.57283, 0],
                [-111.47587, 42.57283, 0],
                [-111.4761, 42.57307, 0],
                [-111.47653, 42.57354, 0],
                [-111.47702, 42.57414, 0],
                [-111.47739, 42.57464, 0],
                [-111.4775, 42.57482, 0],
                [-111.47774, 42.57512, 0],
                [-111.47795, 42.57544, 0],
                [-111.47798, 42.57548, 0],
                [-111.47814, 42.57573, 0],
                [-111.47837, 42.57614, 0],
                [-111.47863, 42.57663, 0],
                [-111.47895, 42.57733, 0],
                [-111.47922, 42.57802, 0],
                [-111.47937, 42.57844, 0],
                [-111.47958, 42.57918, 0],
                [-111.48, 42.58095, 0],
                [-111.481, 42.58488, 0],
                [-111.48104, 42.58506, 0],
                [-111.48113, 42.5854, 0],
                [-111.48113, 42.58541, 0],
                [-111.48132, 42.58614, 0],
                [-111.48142, 42.58647, 0],
                [-111.48142, 42.58648, 0],
                [-111.48158, 42.58692, 0],
                [-111.48173, 42.58726, 0],
                [-111.48186, 42.5875, 0],
                [-111.48191, 42.5876, 0],
                [-111.48195, 42.58766, 0],
                [-111.48211, 42.58793, 0],
                [-111.48226, 42.58817, 0],
                [-111.48259, 42.58862, 0],
                [-111.48273, 42.5888, 0],
                [-111.48321, 42.58933, 0],
                [-111.48355, 42.58966, 0],
                [-111.4837, 42.58982, 0],
                [-111.48422, 42.59034, 0],
                [-111.48613, 42.5923, 0],
                [-111.48671, 42.59288, 0],
                [-111.48742, 42.59361, 0],
                [-111.488, 42.59419, 0],
                [-111.48814, 42.59434, 0],
                [-111.48893, 42.59513, 0],
                [-111.48943, 42.59565, 0],
                [-111.48958, 42.59579, 0],
                [-111.49041, 42.59664, 0],
                [-111.49116, 42.59739, 0],
                [-111.49131, 42.59753, 0],
                [-111.49203, 42.59826, 0],
                [-111.49205, 42.59827, 0],
                [-111.4929, 42.59913, 0],
                [-111.49304, 42.59928, 0],
                [-111.49433, 42.60059, 0],
                [-111.49444, 42.60071, 0],
                [-111.49447, 42.60073, 0],
                [-111.49692, 42.6032, 0],
                [-111.49807, 42.60438, 0],
                [-111.49925, 42.60555, 0],
                [-111.50283, 42.60919, 0],
                [-111.50315, 42.60953, 0],
                [-111.51739, 42.6239, 0],
                [-111.5181, 42.62463, 0],
                [-111.51893, 42.62546, 0],
                [-111.51917, 42.62569, 0],
                [-111.52578, 42.63231, 0],
                [-111.52592, 42.63246, 0],
                [-111.52678, 42.63333, 0],
                [-111.52682, 42.63338, 0],
                [-111.52693, 42.63348, 0],
                [-111.52697, 42.63351, 0],
                [-111.52708, 42.63362, 0],
                [-111.52724, 42.63375, 0],
                [-111.5274, 42.63389, 0],
                [-111.5279, 42.63427, 0],
                [-111.52802, 42.63435, 0],
                [-111.52815, 42.63443, 0],
                [-111.52832, 42.63455, 0],
                [-111.52887, 42.63488, 0],
                [-111.52936, 42.63514, 0],
                [-111.52963, 42.63526, 0],
                [-111.52999, 42.63543, 0],
                [-111.53018, 42.63551, 0],
                [-111.53053, 42.63564, 0],
                [-111.53082, 42.63574, 0],
                [-111.53126, 42.63588, 0],
                [-111.5321, 42.63612, 0],
                [-111.54013, 42.63819, 0],
                [-111.54036, 42.63824, 0],
                [-111.56204, 42.64384, 0],
                [-111.56213, 42.64388, 0],
                [-111.56237, 42.64395, 0],
                [-111.56858, 42.64555, 0],
                [-111.56871, 42.64558, 0],
                [-111.5688, 42.64559, 0],
                [-111.57088, 42.64614, 0],
                [-111.5719, 42.64646, 0],
                [-111.57374, 42.64701, 0],
                [-111.57382, 42.64704, 0],
                [-111.57738, 42.64813, 0],
                [-111.57749, 42.64816, 0],
                [-111.57804, 42.64837, 0],
                [-111.57816, 42.64842, 0],
                [-111.57845, 42.64856, 0],
                [-111.57859, 42.64864, 0],
                [-111.5788, 42.64875, 0],
                [-111.57892, 42.64883, 0],
                [-111.57904, 42.6489, 0],
                [-111.57932, 42.64909, 0],
                [-111.57965, 42.64933, 0],
                [-111.57985, 42.6495, 0],
                [-111.57997, 42.64961, 0],
                [-111.58043, 42.65011, 0],
                [-111.58055, 42.65027, 0],
                [-111.58214, 42.65223, 0],
                [-111.58232, 42.65243, 0],
                [-111.58249, 42.6526, 0],
                [-111.58257, 42.65267, 0],
                [-111.58273, 42.65282, 0],
                [-111.58287, 42.65294, 0],
                [-111.58291, 42.65299, 0],
                [-111.58292, 42.653, 0],
                [-111.58294, 42.65301, 0],
                [-111.58297, 42.65304, 0],
                [-111.58313, 42.65317, 0],
                [-111.58338, 42.65334, 0],
                [-111.58368, 42.65353, 0],
                [-111.58425, 42.65384, 0],
                [-111.58472, 42.65405, 0],
                [-111.58494, 42.65413, 0],
                [-111.58524, 42.65423, 0],
                [-111.5855, 42.6543, 0],
                [-111.58555, 42.65432, 0],
                [-111.5856, 42.65433, 0],
                [-111.58563, 42.65433, 0],
                [-111.58575, 42.65435, 0],
                [-111.58647, 42.65452, 0],
                [-111.58701, 42.6546, 0],
                [-111.58726, 42.65462, 0],
                [-111.58784, 42.65465, 0],
                [-111.59424, 42.65464, 0],
                [-111.59481, 42.65465, 0],
                [-111.59546, 42.65464, 0],
                [-111.59615, 42.65464, 0],
                [-111.59667, 42.65465, 0],
                [-111.60067, 42.65466, 0],
                [-111.60098, 42.65467, 0],
                [-111.60188, 42.65467, 0],
                [-111.60231, 42.65468, 0],
                [-111.61162, 42.65472, 0],
                [-111.6121, 42.65473, 0],
                [-111.61253, 42.65475, 0],
                [-111.61294, 42.65478, 0],
                [-111.61334, 42.65482, 0],
                [-111.61373, 42.65487, 0],
                [-111.6143, 42.65496, 0],
                [-111.61454, 42.65501, 0],
                [-111.61477, 42.65507, 0],
                [-111.61505, 42.65513, 0],
                [-111.61584, 42.65534, 0],
                [-111.61633, 42.65546, 0],
                [-111.61817, 42.65594, 0],
                [-111.61886, 42.65613, 0],
                [-111.63491, 42.66033, 0],
                [-111.63497, 42.66037, 0],
                [-111.63499, 42.66038, 0],
                [-111.63508, 42.66041, 0],
                [-111.63601, 42.66067, 0],
                [-111.63875, 42.66139, 0],
                [-111.63881, 42.6614, 0],
                [-111.63902, 42.66145, 0],
                [-111.63935, 42.66152, 0],
                [-111.64001, 42.66163, 0],
                [-111.64021, 42.66165, 0],
                [-111.64064, 42.66168, 0],
                [-111.64079, 42.66168, 0],
                [-111.64102, 42.66169, 0],
                [-111.64122, 42.66169, 0],
                [-111.64142, 42.66168, 0],
                [-111.64159, 42.66168, 0],
                [-111.64179, 42.66167, 0],
                [-111.64215, 42.66163, 0],
                [-111.64232, 42.66162, 0],
                [-111.64238, 42.66161, 0],
                [-111.64242, 42.6616, 0],
                [-111.64257, 42.66155, 0],
                [-111.64333, 42.66141, 0],
                [-111.64462, 42.66107, 0],
                [-111.64961, 42.65969, 0],
                [-111.64984, 42.65962, 0],
                [-111.65121, 42.65925, 0],
                [-111.65134, 42.65925, 0],
                [-111.65136, 42.65924, 0],
                [-111.65139, 42.65924, 0],
                [-111.65145, 42.65922, 0],
                [-111.65252, 42.65894, 0],
                [-111.6526, 42.65891, 0],
                [-111.65464, 42.65836, 0],
                [-111.65516, 42.65824, 0],
                [-111.65536, 42.6582, 0],
                [-111.65538, 42.65819, 0],
                [-111.65541, 42.65818, 0],
                [-111.65542, 42.65818, 0],
                [-111.65551, 42.65813, 0],
                [-111.65627, 42.658, 0],
                [-111.66199, 42.65722, 0],
                [-111.66227, 42.65719, 0],
                [-111.66871, 42.65631, 0],
                [-111.66925, 42.65622, 0],
                [-111.67229, 42.65565, 0],
                [-111.6729, 42.65555, 0],
                [-111.67464, 42.6553, 0],
                [-111.67476, 42.65531, 0],
                [-111.67488, 42.65531, 0],
                [-111.67628, 42.65513, 0],
                [-111.67889, 42.65476, 0],
                [-111.67898, 42.65474, 0],
                [-111.67912, 42.65469, 0],
                [-111.68005, 42.65456, 0],
                [-111.68077, 42.65447, 0],
                [-111.68784, 42.6535, 0],
                [-111.68816, 42.65345, 0],
                [-111.69291, 42.6528, 0],
                [-111.69358, 42.6527, 0],
                [-111.69699, 42.65224, 0],
                [-111.69804, 42.65207, 0],
                [-111.69846, 42.65198, 0],
                [-111.69858, 42.65199, 0],
                [-111.69863, 42.65199, 0],
                [-111.6997, 42.65178, 0],
                [-111.69999, 42.65173, 0],
                [-111.70007, 42.65171, 0],
                [-111.70275, 42.65115, 0],
                [-111.70284, 42.65112, 0],
                [-111.70297, 42.65107, 0],
                [-111.70322, 42.65101, 0],
                [-111.70739, 42.65016, 0],
                [-111.70924, 42.6498, 0],
                [-111.71139, 42.64946, 0],
                [-111.7134, 42.64923, 0],
                [-111.71424, 42.64915, 0],
                [-111.71525, 42.64907, 0],
                [-111.71758, 42.64896, 0],
                [-111.71778, 42.64896, 0],
                [-111.71816, 42.64895, 0],
                [-111.7208, 42.64895, 0],
                [-111.72095, 42.64898, 0],
                [-111.72097, 42.64899, 0],
                [-111.72742, 42.64908, 0],
                [-111.73024, 42.64909, 0],
                [-111.73028, 42.65063, 0],
                [-111.73028, 42.65128, 0],
                [-111.73029, 42.65155, 0],
                [-111.73032, 42.65179, 0],
                [-111.73045, 42.65212, 0],
                [-111.73065, 42.6524, 0],
                [-111.73085, 42.6526, 0],
                [-111.73178, 42.65335, 0],
                [-111.73195, 42.65348, 0],
                [-111.73493, 42.65587, 0],
                [-111.73602, 42.65671, 0],
                [-111.73612, 42.65677, 0],
                [-111.73625, 42.65686, 0],
                [-111.73654, 42.65703, 0],
                [-111.73673, 42.65713, 0],
                [-111.78222, 42.6767, 0],
                [-111.78223, 42.6767, 0],
                [-111.79483, 42.6821, 0],
                [-111.79504, 42.6822, 0],
                [-111.80244, 42.68536, 0],
                [-111.80445, 42.68624, 0],
                [-111.82163, 42.6936, 0],
                [-111.82226, 42.69388, 0],
                [-111.83093, 42.69759, 0],
                [-111.83135, 42.69778, 0],
                [-111.83368, 42.69877, 0],
                [-111.83452, 42.69914, 0],
                [-111.83685, 42.70013, 0],
                [-111.83739, 42.70037, 0],
                [-111.84129, 42.70203, 0],
                [-111.8415, 42.70213, 0],
                [-111.84552, 42.70384, 0],
                [-111.84573, 42.70394, 0],
                [-111.84657, 42.7043, 0],
                [-111.84665, 42.70433, 0],
                [-111.85126, 42.70631, 0],
                [-111.85144, 42.70638, 0],
                [-111.85186, 42.70657, 0],
                [-111.8673, 42.71317, 0],
                [-111.86761, 42.71331, 0],
                [-111.86819, 42.71355, 0],
                [-111.86845, 42.71367, 0],
                [-111.87407, 42.71603, 0],
                [-111.87452, 42.71621, 0],
                [-111.87733, 42.71738, 0],
                [-111.87772, 42.71753, 0],
                [-111.88016, 42.71854, 0],
                [-111.88064, 42.71873, 0],
                [-111.88108, 42.71892, 0],
                [-111.88238, 42.71945, 0],
                [-111.88419, 42.72022, 0],
                [-111.88485, 42.72049, 0],
                [-111.88548, 42.72076, 0],
                [-111.88673, 42.72127, 0],
                [-111.88795, 42.7218, 0],
                [-111.88847, 42.72201, 0],
                [-111.88851, 42.72203, 0],
                [-111.88858, 42.72208, 0],
                [-111.88922, 42.72247, 0],
                [-111.8899, 42.72285, 0],
                [-111.88991, 42.72285, 0],
                [-111.88998, 42.72288, 0],
                [-111.89011, 42.72295, 0],
                [-111.89012, 42.72295, 0],
                [-111.89026, 42.72302, 0],
                [-111.89042, 42.72308, 0],
                [-111.89093, 42.72331, 0],
                [-111.89337, 42.72434, 0],
                [-111.8935, 42.7244, 0],
                [-111.89435, 42.72476, 0],
                [-111.89439, 42.72477, 0],
                [-111.89617, 42.72551, 0],
                [-111.89674, 42.72576, 0],
                [-111.89719, 42.72594, 0],
                [-111.89776, 42.72618, 0],
                [-111.8979, 42.72623, 0],
                [-111.89797, 42.72627, 0],
                [-111.89841, 42.72643, 0],
                [-111.89863, 42.7265, 0],
                [-111.89902, 42.72664, 0],
                [-111.89998, 42.72693, 0],
                [-111.90302, 42.7278, 0],
                [-111.90408, 42.72812, 0],
                [-111.90423, 42.72816, 0],
                [-111.90506, 42.72844, 0],
                [-111.90558, 42.72863, 0],
                [-111.90563, 42.72866, 0],
                [-111.90647, 42.72903, 0],
                [-111.91063, 42.73093, 0],
                [-111.91081, 42.731, 0],
                [-111.91114, 42.73117, 0],
                [-111.91146, 42.73138, 0],
                [-111.9117, 42.73157, 0],
                [-111.91192, 42.73179, 0],
                [-111.91213, 42.73204, 0],
                [-111.91256, 42.73261, 0],
                [-111.91261, 42.73266, 0],
                [-111.91353, 42.73387, 0],
                [-111.91394, 42.73451, 0],
                [-111.91597, 42.73829, 0],
                [-111.91605, 42.73843, 0],
                [-111.91621, 42.73877, 0],
                [-111.91659, 42.73954, 0],
                [-111.91668, 42.73971, 0],
                [-111.9171, 42.74056, 0],
                [-111.91785, 42.74189, 0],
                [-111.91788, 42.74193, 0],
                [-111.91814, 42.74233, 0],
                [-111.91845, 42.74263, 0],
                [-111.91863, 42.74278, 0],
                [-111.91889, 42.74298, 0],
                [-111.91894, 42.74301, 0],
                [-111.91957, 42.7435, 0],
                [-111.91985, 42.7437, 0],
                [-111.92023, 42.74394, 0],
                [-111.92054, 42.74412, 0],
                [-111.9436, 42.7552, 0],
                [-111.94365, 42.75522, 0],
                [-111.94392, 42.75535, 0],
                [-111.9448, 42.75568, 0],
                [-111.94929, 42.75718, 0],
                [-111.94996, 42.75748, 0],
                [-111.95027, 42.75765, 0],
                [-111.95081, 42.75801, 0],
                [-111.95126, 42.75834, 0],
                [-111.95127, 42.75834, 0],
                [-111.95209, 42.75903, 0],
                [-111.9522, 42.75913, 0],
                [-111.95293, 42.75973, 0],
                [-111.95323, 42.75994, 0],
                [-111.95328, 42.75998, 0],
                [-111.95392, 42.76033, 0],
                [-111.95457, 42.76063, 0],
                [-111.95483, 42.76076, 0],
                [-111.95536, 42.76099, 0],
                [-111.95576, 42.76115, 0],
                [-111.95682, 42.76154, 0],
                [-111.95718, 42.76166, 0],
                [-111.95723, 42.76168, 0],
                [-111.95791, 42.76191, 0],
                [-111.95855, 42.76214, 0],
                [-111.95855, 42.76215, 0],
                [-111.95917, 42.76238, 0],
                [-111.9592, 42.7624, 0],
                [-111.95951, 42.76253, 0],
                [-111.96038, 42.76296, 0],
                [-111.96241, 42.76401, 0],
                [-111.96377, 42.76475, 0],
                [-111.96564, 42.76585, 0],
                [-111.96655, 42.76636, 0],
                [-111.96684, 42.7665, 0],
                [-111.96765, 42.76692, 0],
                [-111.96851, 42.76733, 0],
                [-111.96889, 42.7675, 0],
                [-111.96894, 42.76753, 0],
                [-111.96947, 42.76775, 0],
                [-111.96981, 42.76788, 0],
                [-111.96999, 42.76794, 0],
                [-111.97016, 42.76799, 0],
                [-111.9709, 42.76813, 0],
                [-111.97106, 42.76815, 0],
                [-111.97125, 42.76818, 0],
                [-111.97272, 42.76827, 0],
                [-111.97321, 42.76831, 0],
                [-111.97489, 42.76842, 0],
                [-111.97535, 42.76844, 0],
                [-111.97561, 42.76844, 0],
                [-111.97589, 42.76842, 0],
                [-111.97614, 42.76839, 0],
                [-111.97615, 42.76839, 0],
                [-111.97638, 42.76836, 0],
                [-111.97679, 42.76829, 0],
                [-111.97709, 42.76821, 0],
                [-111.9771, 42.76821, 0],
                [-111.97808, 42.76793, 0],
                [-111.97985, 42.76747, 0],
                [-111.98259, 42.76669, 0],
                [-111.98595, 42.76577, 0],
                [-111.98603, 42.76574, 0],
                [-111.99137, 42.76427, 0],
                [-111.99291, 42.76387, 0],
                [-111.99388, 42.76359, 0],
                [-111.99495, 42.76325, 0],
                [-111.99529, 42.76312, 0],
                [-111.99544, 42.76305, 0],
                [-111.99584, 42.76285, 0],
                [-111.99604, 42.76273, 0],
                [-111.99626, 42.76259, 0],
                [-111.99644, 42.76246, 0],
                [-111.99659, 42.76236, 0],
                [-111.99692, 42.76208, 0],
                [-111.99719, 42.76181, 0],
                [-111.99729, 42.76169, 0],
                [-111.99731, 42.76168, 0],
                [-111.99745, 42.7615, 0],
                [-111.99764, 42.76124, 0],
                [-111.99782, 42.76089, 0],
                [-111.9979, 42.76071, 0],
                [-111.99791, 42.7607, 0],
                [-111.99804, 42.76023, 0],
                [-111.99812, 42.75981, 0],
                [-111.9984, 42.75785, 0],
                [-111.99843, 42.75767, 0],
                [-111.99865, 42.75596, 0],
                [-111.99867, 42.75588, 0],
                [-111.9988, 42.75484, 0],
                [-111.99881, 42.7548, 0],
                [-111.99893, 42.75405, 0],
                [-111.99905, 42.75346, 0],
                [-111.99923, 42.7523, 0],
                [-112.00066, 42.74194, 0],
                [-112.00087, 42.74026, 0],
                [-112.00091, 42.73962, 0],
                [-112.00092, 42.7393, 0],
                [-112.00091, 42.73886, 0],
                [-112.00086, 42.73835, 0],
                [-112.00086, 42.73833, 0],
                [-112.0008, 42.73783, 0],
                [-112.00074, 42.73744, 0],
                [-112.00057, 42.73655, 0],
                [-112.00049, 42.73621, 0],
                [-112.00048, 42.73618, 0],
                [-112.0001, 42.73474, 0],
                [-111.99991, 42.73394, 0],
                [-111.99983, 42.73368, 0],
                [-111.9997, 42.73316, 0],
                [-111.99963, 42.73292, 0],
                [-111.99933, 42.73174, 0],
                [-111.99916, 42.73098, 0],
                [-111.99914, 42.73072, 0],
                [-111.99914, 42.73061, 0],
                [-111.99915, 42.73042, 0],
                [-111.99918, 42.73017, 0],
                [-111.9992, 42.73005, 0],
                [-111.99923, 42.72995, 0],
                [-111.99927, 42.72979, 0],
                [-111.99927, 42.72978, 0],
                [-111.99934, 42.72955, 0],
                [-111.99941, 42.72935, 0],
                [-111.99967, 42.72886, 0],
                [-111.99985, 42.72859, 0],
                [-111.99998, 42.72841, 0],
                [-112.00022, 42.72813, 0],
                [-112.00133, 42.72706, 0],
                [-112.0023, 42.72617, 0],
                [-112.00233, 42.72615, 0],
                [-112.0026, 42.7259, 0],
                [-112.00303, 42.72558, 0],
                [-112.00317, 42.7255, 0],
                [-112.0032, 42.72548, 0],
                [-112.00343, 42.72536, 0],
                [-112.00364, 42.72526, 0],
                [-112.00436, 42.72498, 0],
                [-112.00444, 42.72496, 0],
                [-112.00563, 42.72454, 0],
                [-112.00602, 42.72439, 0],
                [-112.0064, 42.72422, 0],
                [-112.00677, 42.72399, 0],
                [-112.00684, 42.72395, 0],
                [-112.00725, 42.72367, 0],
                [-112.00732, 42.72363, 0],
                [-112.00814, 42.72304, 0],
                [-112.00854, 42.72274, 0],
                [-112.00872, 42.72262, 0],
                [-112.00884, 42.72253, 0],
                [-112.00905, 42.72236, 0],
                [-112.00926, 42.7222, 0],
                [-112.00959, 42.72191, 0],
                [-112.0099, 42.72161, 0],
                [-112.01028, 42.72119, 0],
                [-112.0105, 42.72088, 0],
                [-112.01069, 42.72058, 0],
                [-112.0107, 42.72057, 0],
                [-112.01075, 42.72049, 0],
                [-112.01094, 42.72011, 0],
                [-112.01138, 42.71904, 0],
                [-112.01163, 42.71839, 0],
                [-112.01174, 42.718, 0],
                [-112.0118, 42.71772, 0],
                [-112.01204, 42.71625, 0],
                [-112.01205, 42.71616, 0],
                [-112.01297, 42.71027, 0],
                [-112.01303, 42.70996, 0],
                [-112.01303, 42.70991, 0],
                [-112.01311, 42.7095, 0],
                [-112.01312, 42.70942, 0],
                [-112.01315, 42.70928, 0],
                [-112.01334, 42.70868, 0],
                [-112.01337, 42.7086, 0],
                [-112.01342, 42.7085, 0],
                [-112.01345, 42.70841, 0],
                [-112.01367, 42.70794, 0],
                [-112.01382, 42.70766, 0],
                [-112.01383, 42.70765, 0],
                [-112.01389, 42.70754, 0],
                [-112.01408, 42.70724, 0],
                [-112.01427, 42.70697, 0],
                [-112.0146, 42.70657, 0],
                [-112.01476, 42.70639, 0],
                [-112.01483, 42.7063, 0],
                [-112.01731, 42.70375, 0],
                [-112.01767, 42.70336, 0],
                [-112.01772, 42.70329, 0],
                [-112.01811, 42.70283, 0],
                [-112.01821, 42.70268, 0],
                [-112.01836, 42.70249, 0],
                [-112.01863, 42.7021, 0],
                [-112.01909, 42.7014, 0],
                [-112.01933, 42.70097, 0],
                [-112.01938, 42.7009, 0],
                [-112.01938, 42.70089, 0],
                [-112.01955, 42.70055, 0],
                [-112.01975, 42.70017, 0],
                [-112.02016, 42.69916, 0],
                [-112.02023, 42.69896, 0],
                [-112.02031, 42.6987, 0],
                [-112.02044, 42.6981, 0],
                [-112.02047, 42.6979, 0],
                [-112.02051, 42.69768, 0],
                [-112.02051, 42.6976, 0],
                [-112.02052, 42.6975, 0],
                [-112.02051, 42.6973, 0],
                [-112.02051, 42.69726, 0],
                [-112.02049, 42.69712, 0],
                [-112.02045, 42.6969, 0],
                [-112.02042, 42.69676, 0],
                [-112.02034, 42.69648, 0],
                [-112.02032, 42.69644, 0],
                [-112.02022, 42.69612, 0],
                [-112.01943, 42.69373, 0],
                [-112.01936, 42.6935, 0],
                [-112.01931, 42.69327, 0],
                [-112.01928, 42.69316, 0],
                [-112.01927, 42.69311, 0],
                [-112.01924, 42.69285, 0],
                [-112.01923, 42.69269, 0],
                [-112.01923, 42.69257, 0],
                [-112.01925, 42.69223, 0],
                [-112.01928, 42.69203, 0],
                [-112.01929, 42.69191, 0],
                [-112.01953, 42.68984, 0],
                [-112.01955, 42.6897, 0],
                [-112.01957, 42.6896, 0],
                [-112.01964, 42.68908, 0],
                [-112.01985, 42.68793, 0],
                [-112.0199, 42.6877, 0],
                [-112.01994, 42.68746, 0],
                [-112.01995, 42.68744, 0],
                [-112.01998, 42.6873, 0],
                [-112.02002, 42.68706, 0],
                [-112.02006, 42.68688, 0],
                [-112.02008, 42.68676, 0],
                [-112.02011, 42.68646, 0],
                [-112.0201, 42.68637, 0],
                [-112.0201, 42.6863, 0],
                [-112.02009, 42.68623, 0],
                [-112.02006, 42.68608, 0],
                [-112.02006, 42.68603, 0],
                [-112.02005, 42.686, 0],
                [-112.02003, 42.68592, 0],
                [-112.02001, 42.68582, 0],
                [-112.01993, 42.68557, 0],
                [-112.0199, 42.68549, 0],
                [-112.01985, 42.6854, 0],
                [-112.0198, 42.6853, 0],
                [-112.01968, 42.68508, 0],
                [-112.01959, 42.68494, 0],
                [-112.01949, 42.6848, 0],
                [-112.01941, 42.68467, 0],
                [-112.01933, 42.68458, 0],
                [-112.01926, 42.68448, 0],
                [-112.01919, 42.68437, 0],
                [-112.01915, 42.68432, 0],
                [-112.01905, 42.68418, 0],
                [-112.01896, 42.68404, 0],
                [-112.0189, 42.68393, 0],
                [-112.01885, 42.68382, 0],
                [-112.0188, 42.68372, 0],
                [-112.01879, 42.68371, 0],
                [-112.01862, 42.68334, 0],
                [-112.01852, 42.6831, 0],
                [-112.01848, 42.68295, 0],
                [-112.01721, 42.67941, 0],
                [-112.01697, 42.67868, 0],
                [-112.01692, 42.67841, 0],
                [-112.0169, 42.6781, 0],
                [-112.01693, 42.67764, 0],
                [-112.0172, 42.67617, 0],
                [-112.01727, 42.67566, 0],
                [-112.01727, 42.67532, 0],
                [-112.0172, 42.6749, 0],
                [-112.01635, 42.67174, 0],
                [-112.01606, 42.67075, 0],
                [-112.01578, 42.66965, 0],
                [-112.0155, 42.66861, 0],
                [-112.01529, 42.66788, 0],
                [-112.01388, 42.66264, 0],
                [-112.01369, 42.66203, 0],
                [-112.01355, 42.66171, 0],
                [-112.01333, 42.66125, 0],
                [-112.01305, 42.66081, 0],
                [-112.01275, 42.66038, 0],
                [-112.01242, 42.65997, 0],
                [-112.01183, 42.65934, 0],
                [-112.00606, 42.65363, 0],
                [-112.00534, 42.65298, 0],
                [-112.00364, 42.65156, 0],
                [-112.00226, 42.65043, 0],
                [-112.00196, 42.65017, 0],
                [-112.00174, 42.64996, 0],
                [-112.00155, 42.64968, 0],
                [-112.00151, 42.64961, 0],
                [-112.00145, 42.64949, 0],
                [-112.0014, 42.6494, 0],
                [-112.00136, 42.64932, 0],
                [-112.00127, 42.64904, 0],
                [-112.00126, 42.64897, 0],
                [-112.00124, 42.64889, 0],
                [-112.00123, 42.64877, 0],
                [-112.00123, 42.64868, 0],
                [-112.00122, 42.64856, 0],
                [-112.00122, 42.64811, 0],
                [-112.00128, 42.64664, 0],
                [-112.00129, 42.64649, 0],
                [-112.00131, 42.64637, 0],
                [-112.00132, 42.64624, 0],
                [-112.00142, 42.64584, 0],
                [-112.00154, 42.64552, 0],
                [-112.00172, 42.6451, 0],
                [-112.00365, 42.64124, 0],
                [-112.00374, 42.64107, 0],
                [-112.00382, 42.64089, 0],
                [-112.0039, 42.64073, 0],
                [-112.00397, 42.64057, 0],
                [-112.00401, 42.64046, 0],
                [-112.00412, 42.64011, 0],
                [-112.00415, 42.63993, 0],
                [-112.00417, 42.63985, 0],
                [-112.00418, 42.63975, 0],
                [-112.00421, 42.63959, 0],
                [-112.00427, 42.63912, 0],
                [-112.0043, 42.63893, 0],
                [-112.0043, 42.63886, 0],
                [-112.00431, 42.63878, 0],
                [-112.00432, 42.63863, 0],
                [-112.00432, 42.63847, 0],
                [-112.00429, 42.63816, 0],
                [-112.00426, 42.63794, 0],
                [-112.00422, 42.63779, 0],
                [-112.00422, 42.63777, 0],
                [-112.00419, 42.63767, 0],
                [-112.00417, 42.63762, 0],
                [-112.00417, 42.63759, 0],
                [-112.00407, 42.6373, 0],
                [-112.00402, 42.63718, 0],
                [-112.00399, 42.63709, 0],
                [-112.00394, 42.63697, 0],
                [-112.00378, 42.63664, 0],
                [-112.00373, 42.63651, 0],
                [-112.00363, 42.63631, 0],
                [-112.0036, 42.63624, 0],
                [-112.00359, 42.63623, 0],
                [-112.00356, 42.63616, 0],
                [-112.00352, 42.63608, 0],
                [-112.00349, 42.63603, 0],
                [-112.00343, 42.63591, 0],
                [-112.00339, 42.63585, 0],
                [-112.00335, 42.63578, 0],
                [-112.00329, 42.63569, 0],
                [-112.00317, 42.63553, 0],
                [-112.00312, 42.63547, 0],
                [-112.00306, 42.63539, 0],
                [-112.00288, 42.63518, 0],
                [-112.00265, 42.63499, 0],
                [-112.00253, 42.63488, 0],
                [-112.00175, 42.63432, 0],
                [-112.00153, 42.63417, 0],
                [-112.0014, 42.63407, 0],
                [-112.00127, 42.63398, 0],
                [-112.00121, 42.63392, 0],
                [-112.00114, 42.63386, 0],
                [-112.00109, 42.63381, 0],
                [-112.00104, 42.63377, 0],
                [-112.00093, 42.63366, 0],
                [-112.0009, 42.63362, 0],
                [-112.00076, 42.63345, 0],
                [-112.00072, 42.63339, 0],
                [-112.00067, 42.63333, 0],
                [-112.00058, 42.63319, 0],
                [-112.00053, 42.63312, 0],
                [-112.00043, 42.63294, 0],
                [-112.00039, 42.63285, 0],
                [-112.00036, 42.63277, 0],
                [-112.00032, 42.63268, 0],
                [-112.00032, 42.63267, 0],
                [-112.00028, 42.63255, 0],
                [-112.00026, 42.63251, 0],
                [-112.00025, 42.63248, 0],
                [-112.00013, 42.63203, 0],
                [-112.00006, 42.6318, 0],
                [-112.00003, 42.63166, 0],
                [-111.99998, 42.63148, 0],
                [-111.99993, 42.63127, 0],
                [-111.99986, 42.63106, 0],
                [-111.99978, 42.63088, 0],
                [-111.99973, 42.63078, 0],
                [-111.99951, 42.63045, 0],
                [-111.9991, 42.6299, 0],
                [-111.99861, 42.62922, 0],
                [-111.99851, 42.62909, 0],
                [-111.99836, 42.62887, 0],
                [-111.99801, 42.62839, 0],
                [-111.9978, 42.62809, 0],
                [-111.99773, 42.62797, 0],
                [-111.9975, 42.6276, 0],
                [-111.99736, 42.6273, 0],
                [-111.99733, 42.62725, 0],
                [-111.99725, 42.62704, 0],
                [-111.99714, 42.6267, 0],
                [-111.99706, 42.62629, 0],
                [-111.99705, 42.62607, 0],
                [-111.99703, 42.62591, 0],
                [-111.99703, 42.62548, 0],
                [-111.99708, 42.62504, 0],
                [-111.99719, 42.62452, 0],
                [-111.99736, 42.62398, 0],
                [-111.99744, 42.62376, 0],
                [-111.99748, 42.6236, 0],
                [-111.99749, 42.62353, 0],
                [-111.99749, 42.62347, 0],
                [-111.99746, 42.62329, 0],
                [-111.99733, 42.6229, 0],
                [-111.99725, 42.62268, 0],
                [-111.99769, 42.62249, 0],
                [-111.99799, 42.62235, 0],
                [-111.99872, 42.62196, 0],
                [-111.99883, 42.62189, 0],
                [-111.999, 42.6218, 0],
                [-111.99995, 42.62123, 0],
                [-112.00005, 42.62116, 0],
                [-112.00021, 42.62107, 0],
                [-112.00024, 42.62105, 0],
                [-112.00079, 42.62074, 0],
                [-112.00123, 42.62052, 0],
                [-112.00168, 42.62033, 0],
                [-112.00202, 42.6202, 0],
                [-112.00215, 42.62016, 0],
                [-112.00226, 42.62012, 0],
                [-112.00269, 42.62002, 0],
                [-112.00284, 42.61999, 0],
                [-112.00285, 42.61998, 0],
                [-112.00328, 42.6199, 0],
                [-112.00347, 42.61987, 0],
                [-112.00361, 42.61986, 0],
                [-112.00377, 42.61984, 0],
                [-112.00418, 42.61981, 0],
                [-112.00432, 42.61981, 0],
                [-112.00444, 42.6198, 0],
                [-112.0048, 42.6198, 0],
                [-112.00511, 42.61981, 0],
                [-112.00541, 42.61983, 0],
                [-112.00544, 42.61983, 0],
                [-112.00575, 42.61987, 0],
                [-112.00587, 42.61988, 0],
                [-112.006, 42.6199, 0],
                [-112.00616, 42.61991, 0],
                [-112.00629, 42.61993, 0],
                [-112.0064, 42.61996, 0],
                [-112.00652, 42.61998, 0],
                [-112.00702, 42.62013, 0],
                [-112.00768, 42.62037, 0],
                [-112.00827, 42.6206, 0],
                [-112.00846, 42.62068, 0],
                [-112.0086, 42.62073, 0],
                [-112.00867, 42.62076, 0],
                [-112.00889, 42.62084, 0],
                [-112.00948, 42.62107, 0],
                [-112.0113, 42.62173, 0],
                [-112.01152, 42.6218, 0],
                [-112.01156, 42.62182, 0],
                [-112.01242, 42.62209, 0],
                [-112.01333, 42.62234, 0],
                [-112.0143, 42.62258, 0],
                [-112.01465, 42.62266, 0],
                [-112.01553, 42.62284, 0],
                [-112.01623, 42.62297, 0],
                [-112.01631, 42.62298, 0],
                [-112.0198, 42.62362, 0],
                [-112.02056, 42.62377, 0],
                [-112.02214, 42.62405, 0],
                [-112.02223, 42.62407, 0],
                [-112.02284, 42.62418, 0],
                [-112.02326, 42.62424, 0],
                [-112.0235, 42.62428, 0],
                [-112.02471, 42.62442, 0],
                [-112.02546, 42.62448, 0],
                [-112.02619, 42.62452, 0],
                [-112.02748, 42.62454, 0],
                [-112.02832, 42.62453, 0],
                [-112.02864, 42.62452, 0],
                [-112.02999, 42.62444, 0],
                [-112.03062, 42.62438, 0],
                [-112.03172, 42.62423, 0],
                [-112.03226, 42.62414, 0],
                [-112.03308, 42.62399, 0],
                [-112.03372, 42.62385, 0],
                [-112.03552, 42.62336, 0],
                [-112.03687, 42.62293, 0],
                [-112.03826, 42.62244, 0],
                [-112.03849, 42.62237, 0],
                [-112.04255, 42.62088, 0],
                [-112.04369, 42.62051, 0],
                [-112.04559, 42.61984, 0],
                [-112.04726, 42.61928, 0],
                [-112.0477, 42.61914, 0],
                [-112.04952, 42.61852, 0],
                [-112.0514, 42.61786, 0],
                [-112.05151, 42.61786, 0],
                [-112.0548, 42.61673, 0],
                [-112.0552, 42.6166, 0],
                [-112.05581, 42.61643, 0],
                [-112.0562, 42.61634, 0],
                [-112.05635, 42.61631, 0],
                [-112.05731, 42.61615, 0],
                [-112.05745, 42.61614, 0],
                [-112.05757, 42.61612, 0],
                [-112.05793, 42.61609, 0],
                [-112.05796, 42.61609, 0],
                [-112.05816, 42.61607, 0],
                [-112.05866, 42.61604, 0],
                [-112.05948, 42.61604, 0],
                [-112.06076, 42.61608, 0],
                [-112.06285, 42.61618, 0],
                [-112.0629, 42.61619, 0],
                [-112.07259, 42.61666, 0],
                [-112.07333, 42.61671, 0],
                [-112.07931, 42.61699, 0],
                [-112.08106, 42.61713, 0],
                [-112.08147, 42.61719, 0],
                [-112.08195, 42.61725, 0],
                [-112.0824, 42.61732, 0],
                [-112.08243, 42.61732, 0],
                [-112.08634, 42.6179, 0],
                [-112.08642, 42.6179, 0],
                [-112.08658, 42.61789, 0],
                [-112.08908, 42.61824, 0],
                [-112.08941, 42.61828, 0],
                [-112.09783, 42.61952, 0],
                [-112.09831, 42.6196, 0],
                [-112.10023, 42.61986, 0],
                [-112.10167, 42.62007, 0],
                [-112.10247, 42.62017, 0],
                [-112.10271, 42.62021, 0],
                [-112.10286, 42.62027, 0],
                [-112.10295, 42.6203, 0],
                [-112.10302, 42.62031, 0],
                [-112.1032, 42.62035, 0],
                [-112.10464, 42.62055, 0],
                [-112.10488, 42.62059, 0],
                [-112.10548, 42.62067, 0],
                [-112.10557, 42.62067, 0],
                [-112.10566, 42.62065, 0],
                [-112.1087, 42.62109, 0],
                [-112.10961, 42.62121, 0],
                [-112.11159, 42.62151, 0],
                [-112.11208, 42.6216, 0],
                [-112.1122, 42.62163, 0],
                [-112.11265, 42.62172, 0],
                [-112.11323, 42.62186, 0],
                [-112.11325, 42.62186, 0],
                [-112.11348, 42.62193, 0],
                [-112.11391, 42.62204, 0],
                [-112.11528, 42.62246, 0],
                [-112.11529, 42.62246, 0],
                [-112.11756, 42.62313, 0],
                [-112.11801, 42.62327, 0],
                [-112.1193, 42.62365, 0],
                [-112.11935, 42.62367, 0],
                [-112.11964, 42.62376, 0],
                [-112.12069, 42.62406, 0],
                [-112.12414, 42.6251, 0],
                [-112.12481, 42.62535, 0],
                [-112.1257, 42.62576, 0],
                [-112.12616, 42.626, 0],
                [-112.1265, 42.62622, 0],
                [-112.12665, 42.62631, 0],
                [-112.12758, 42.6269, 0],
                [-112.12789, 42.62711, 0],
                [-112.13068, 42.6289, 0],
                [-112.13094, 42.62905, 0],
                [-112.13113, 42.62918, 0],
                [-112.13193, 42.6296, 0],
                [-112.13273, 42.62994, 0],
                [-112.13369, 42.63028, 0],
                [-112.1342, 42.63041, 0],
                [-112.1346, 42.63048, 0],
                [-112.13495, 42.63055, 0],
                [-112.13562, 42.63066, 0],
                [-112.1359, 42.63069, 0],
                [-112.13679, 42.63076, 0],
                [-112.13709, 42.63077, 0],
                [-112.13771, 42.63077, 0],
                [-112.13804, 42.63076, 0],
                [-112.1384, 42.63074, 0],
                [-112.13858, 42.63072, 0],
                [-112.13872, 42.63071, 0],
                [-112.13918, 42.63066, 0],
                [-112.13978, 42.63055, 0],
                [-112.13983, 42.63055, 0],
                [-112.14002, 42.6305, 0],
                [-112.14018, 42.63047, 0],
                [-112.14972, 42.62799, 0],
                [-112.15036, 42.62784, 0],
                [-112.15053, 42.62781, 0],
                [-112.15087, 42.62774, 0],
                [-112.15106, 42.62771, 0],
                [-112.15144, 42.62766, 0],
                [-112.15206, 42.62759, 0],
                [-112.15227, 42.62758, 0],
                [-112.15328, 42.6275, 0],
                [-112.15376, 42.62748, 0],
                [-112.1542, 42.62745, 0],
                [-112.15522, 42.6274, 0],
                [-112.15547, 42.62738, 0],
                [-112.1562, 42.62735, 0],
                [-112.16058, 42.62712, 0],
                [-112.16255, 42.627, 0],
                [-112.16265, 42.62702, 0],
                [-112.16286, 42.62704, 0],
                [-112.16295, 42.62704, 0],
                [-112.16306, 42.62703, 0],
                [-112.16495, 42.62693, 0],
                [-112.16498, 42.62693, 0],
                [-112.16502, 42.62692, 0],
                [-112.16512, 42.62688, 0],
                [-112.1658, 42.62684, 0],
                [-112.16642, 42.62684, 0],
                [-112.16662, 42.62685, 0],
                [-112.16706, 42.62689, 0],
                [-112.16767, 42.62697, 0],
                [-112.16789, 42.62701, 0],
                [-112.16813, 42.62707, 0],
                [-112.16833, 42.62711, 0],
                [-112.16857, 42.62718, 0],
                [-112.16893, 42.6273, 0],
                [-112.16894, 42.62731, 0],
                [-112.16917, 42.6274, 0],
                [-112.16945, 42.62752, 0],
                [-112.16958, 42.62757, 0],
                [-112.16999, 42.62777, 0],
                [-112.17056, 42.62809, 0],
                [-112.17057, 42.6281, 0],
                [-112.17087, 42.6283, 0],
                [-112.17115, 42.6285, 0],
                [-112.17144, 42.62872, 0],
                [-112.17145, 42.62872, 0],
                [-112.17165, 42.62892, 0],
                [-112.17186, 42.62914, 0],
                [-112.17212, 42.62946, 0],
                [-112.17212, 42.62947, 0],
                [-112.1723, 42.62972, 0],
                [-112.17248, 42.63004, 0],
                [-112.17262, 42.63031, 0],
                [-112.17266, 42.6304, 0],
                [-112.17273, 42.63061, 0],
                [-112.17278, 42.63074, 0],
                [-112.17286, 42.6311, 0],
                [-112.17289, 42.63129, 0],
                [-112.17291, 42.63146, 0],
                [-112.17291, 42.63187, 0],
                [-112.173, 42.6331, 0],
                [-112.17304, 42.63349, 0],
                [-112.17312, 42.63392, 0],
                [-112.17323, 42.6344, 0],
                [-112.1733, 42.63481, 0],
                [-112.17334, 42.63509, 0],
                [-112.1734, 42.63545, 0],
                [-112.17343, 42.6356, 0],
                [-112.17343, 42.63563, 0],
                [-112.17344, 42.63581, 0],
                [-112.17346, 42.63599, 0],
                [-112.17354, 42.63697, 0],
                [-112.17359, 42.63739, 0],
                [-112.17363, 42.6376, 0],
                [-112.17366, 42.63773, 0],
                [-112.17368, 42.63786, 0],
                [-112.1737, 42.63796, 0],
                [-112.17371, 42.63805, 0],
                [-112.17374, 42.63813, 0],
                [-112.17377, 42.63827, 0],
                [-112.17379, 42.63831, 0],
                [-112.17388, 42.63857, 0],
                [-112.17411, 42.63917, 0],
                [-112.17415, 42.63926, 0],
                [-112.1742, 42.63936, 0],
                [-112.17433, 42.63959, 0],
                [-112.17446, 42.6398, 0],
                [-112.17478, 42.64021, 0],
                [-112.17479, 42.64022, 0],
                [-112.17487, 42.64032, 0],
                [-112.17506, 42.64052, 0],
                [-112.17514, 42.64061, 0],
                [-112.1752, 42.64067, 0],
                [-112.17533, 42.64082, 0],
                [-112.17534, 42.64082, 0],
                [-112.17547, 42.64097, 0],
                [-112.17557, 42.64107, 0],
                [-112.17561, 42.64112, 0],
                [-112.17947, 42.6453, 0],
                [-112.18028, 42.64637, 0],
                [-112.1804, 42.64656, 0],
                [-112.18065, 42.64693, 0],
                [-112.18076, 42.64711, 0],
                [-112.18081, 42.64721, 0],
                [-112.18087, 42.6473, 0],
                [-112.18094, 42.64742, 0],
                [-112.18097, 42.64746, 0],
                [-112.18125, 42.64794, 0],
                [-112.18148, 42.64829, 0],
                [-112.18164, 42.64855, 0],
                [-112.18187, 42.6489, 0],
                [-112.18204, 42.64913, 0],
                [-112.18266, 42.64991, 0],
                [-112.18307, 42.65041, 0],
                [-112.18327, 42.65064, 0],
                [-112.18339, 42.65079, 0],
                [-112.18344, 42.65086, 0],
                [-112.18386, 42.65135, 0],
                [-112.18492, 42.65264, 0],
                [-112.18493, 42.65266, 0],
                [-112.18553, 42.65338, 0],
                [-112.18605, 42.65403, 0],
                [-112.18621, 42.65424, 0],
                [-112.18727, 42.65557, 0],
                [-112.18802, 42.65655, 0],
                [-112.18805, 42.65658, 0],
                [-112.18814, 42.6567, 0],
                [-112.18838, 42.657, 0],
                [-112.18873, 42.65747, 0],
                [-112.18925, 42.65812, 0],
                [-112.18944, 42.65834, 0],
                [-112.18979, 42.6587, 0],
                [-112.18987, 42.65877, 0],
                [-112.19009, 42.65893, 0],
                [-112.19023, 42.65904, 0],
                [-112.1906, 42.65931, 0],
                [-112.19069, 42.65937, 0],
                [-112.19109, 42.65961, 0],
                [-112.19116, 42.65966, 0],
                [-112.1916, 42.65988, 0],
                [-112.19178, 42.65996, 0],
                [-112.19203, 42.66006, 0],
                [-112.19212, 42.6601, 0],
                [-112.19223, 42.66018, 0],
                [-112.19227, 42.6602, 0],
                [-112.19231, 42.66021, 0],
                [-112.19246, 42.66026, 0],
                [-112.19296, 42.66041, 0],
                [-112.19349, 42.66053, 0],
                [-112.19366, 42.66056, 0],
                [-112.19444, 42.66066, 0],
                [-112.19464, 42.66068, 0],
                [-112.195, 42.6607, 0],
                [-112.19507, 42.6607, 0],
                [-112.19523, 42.66064, 0],
                [-112.19596, 42.66067, 0],
                [-112.1967, 42.66068, 0],
                [-112.19745, 42.66072, 0],
                [-112.19877, 42.66077, 0],
                [-112.20256, 42.66087, 0],
                [-112.20482, 42.66091, 0],
                [-112.20571, 42.6609, 0]
            ]
        },
        id: "1d666f86-0a36-4a30-98e5-ae0e8595c4e8"
    }, 
    // {
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-111.3959, 42.03719, 0]
    //     },
    //     properties: {
    //         name: "Fish Haven, Idaho",
    //         "shape": "Marker",
    //         "category": "default"
    //     },
    //     id: "5bd1af04-8ada-4664-b0a1-7f9bcede34c7"
    // }, {
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-112.20571, 42.6609, 0]
    //     },
    //     properties: {
    //         name: "McCammon, Idaho",
    //         "shape": "Marker",
    //         "category": "default"
    //     },
    //     id: "18cf98ca-21b9-4e9c-9ada-e591c893da88"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Paris, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-111.401008, 42.226992]
    //     },
    //     id: "e6634f10-8778-4a1d-8a95-584f9d1758af"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "St. Charles, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-111.388807, 42.113505]
    //     },
    //     id: "5ac21b1e-2893-4133-860c-8bb3fb69c661"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Montpelier, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-111.29781, 42.31767, 0]
    //     },
    //     id: "e65d46c7-ddb9-4353-a7dc-153ffa92a638"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Soda Springs, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-111.60188, 42.65467, 0]
    //     },
    //     id: "55a68c75-72c5-4f21-b7e1-1aeaba8ca3e7"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Bancroft, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-111.88548, 42.72076, 0]
    //     },
    //     id: "29fea9c6-1b5d-49ef-90bc-fb3286bcf7f6"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Lava Hot Springs, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-112.00652, 42.61998, 0]
    //     },
    //     id: "72e02a98-db20-4e9a-8331-076a8e3df5af"
    // }
    ]
};

export default OregonTrailBearLake;