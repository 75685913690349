import { createReducer } from "@reduxjs/toolkit";
import { updateActivities } from "../actions/activitiesActions";
import { vitaDataLayer } from "../../helpers/dataLayer";

const initialState = {
    selectedActivities: []
}

const activitiesReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(updateActivities, (state, action) => {
            state.selectedActivities = action.payload;
            let activityCategory = null;
            switch (true) {
                case action.payload.includes("Native American Culture"):
                    activityCategory = "Arts & Culture";
                    break;
                case action.payload.includes("Rail to Trail"):
                    activityCategory = "Biking Recreation";
                    break;
                case action.payload.includes("Wineries"):
                    activityCategory = "Culinary";
                    break;
                case action.payload.includes("Oregon Trail"):
                    activityCategory = "Historical Sites";
                    break;
                case action.payload.includes("Ballooning"):
                    activityCategory = "Outdoor Recreation";
                    break;
                case action.payload.includes("Casinos"):
                     activityCategory = "Roadside Attractions";
                    break;
                case action.payload.includes("State Parks"):
                       activityCategory = "State Parks";
                    break;
                case action.payload.includes("Hot Springs"):
                    activityCategory = "Water Recreation";
                    break;
                case action.payload.includes("Wildlife Parks & Zoos"):
                    activityCategory = "Wildlife";
                    break;
                case action.payload.includes("Ice Skating"):
                    activityCategory = "Winter";
                    break;
                default:
                    break;
            }

            if(activityCategory !== null){
                vitaDataLayer('activities', 'select_activity', activityCategory);
            }
        })
});

export default activitiesReducer;