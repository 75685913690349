import { createReducer } from "@reduxjs/toolkit";
import { setRecommendations, setTravelTips } from "../actions/recommendedActions";

const initialState = {
    recommended: [],
    travelTips: []
}

const recommendedReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setRecommendations, (state, action) => {
            state.recommended = action.payload;
        })
        .addCase(setTravelTips, (state, action) => {
            state.travelTips = action.payload;
        })
});

export default recommendedReducer;