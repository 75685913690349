import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, removeFromCart } from "../../store/actions/cartActions";
import { setLastItem } from "../../store/actions/lastItemActions";
import { getIconHtmlByCategoryLabel, getLabelByValue } from "../Markers/markers";
import { IconPlus, IconMinus } from "../../images/icons";

const AdventureCard = (props) => {
  const [inCart, setInCart] = useState(false);
  const cart = useSelector((state) => state.cart.cartItems);
  const dispatch = useDispatch();

  const url = `https://visitidaho.org/?p=${props.item.id}`;

  const toggleItemInCart = (item) => {
    if (!inCart) {
      const cartCount = cart ? cart.length : 0;
      if (cartCount <= 9) {
        dispatch(addToCart(item));
        dispatch(
          setLastItem({
            dynamicText: item.title,
            message: "addToCart",
            icon: true,
            type: "success",
          })
        );
        setInCart(true);
      } else {
        dispatch(
          setLastItem({
            message: "cartIsFull",
            icon: false,
            timer: 8000,
            type: "error",
          })
        );
      }
    } else {
      dispatch(removeFromCart(item.id));
      dispatch(
        setLastItem({
          dynamicText: item.title,
          message: "removeFromCart",
          icon: false,
          type: "warning",
        })
      );
      setInCart(false);
    }
  };

  const showCategoryIcons = (item) => {
    const cats = [];
    item.categories.forEach((cat) => {
      const label = getLabelByValue(cat);
      if (label && !cats.includes(label)) {
        cats.push(label);
      }
    });
    return (
      <>
        {cats.map((cat, index) => {
          return (
            <div key={index} className="category-icon tooltip">
              {getIconHtmlByCategoryLabel(cat)}
              <span className="tooltiptext">{cat}</span>
            </div>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    setInCart(cart.some((storeItem) => props.item.id === storeItem.id));
  }, [cart, props.item.id]);

  return (
    <article className="ac-card-wrapper">
      <div className="card-image">
        <figure className="card-figure">
          <img src={props.item.image} alt={props.item.title} />
          <figcaption>{showCategoryIcons(props.item)}</figcaption>
        </figure>
        <a
          onClick={() => toggleItemInCart(props.item)}
          className={`add-to-cart ${inCart ? "active" : ""}`}
          title="Add to Itinerary"
        >
          {inCart ? <IconMinus /> : <IconPlus />}
        </a>
      </div>
      <div className="card-content">
        <h5>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {props.item.title}
          </a>
        </h5>
        {props.item.street_1 && props.item.city && (
          <address>
            {props.item.street_1} {props.item.street_2}
            <br />
            {props.item.city}, Idaho {props.item.zip}
          </address>
        )}
        {props.showExcerpt && <p>{props.item.excerpt}</p>}
      </div>
    </article>
  );
};

AdventureCard.defaultProps = {
    showExcerpt: true
}

export default AdventureCard;