import axios from "axios";
import store from "../../store/store";
import { setTravelTips } from "../../store/actions/recommendedActions";

// const getRecommendedTravelTips = async () => {
//   try {
//     const cartMarkers = store.getState().cart.cartItems;
//     const arrayOfCats = cartMarkers.flatMap(marker => marker.categories);
//     const arrayOfRegions = cartMarkers.map(marker => marker.region);

//     const uniqueCats = [...new Set(arrayOfCats)];
//     const uniqueRegions = [...new Set(arrayOfRegions)];

//     const response = await axios.get(`https://visitidaho.org/wp-json/vita_route/recommended_activities/?categories=${uniqueCats.join()}&regions=${uniqueRegions.join()}`);
//     store.dispatch(setRecommendedTravelTips(response.data));
//     return response;
//   } catch (error) {
//     return error;
//   }
// };

export const getRecommendedTravelTips = () => {
  return async dispatch => {
    try {
      let response = await axios.get("https://visitidaho.org/wp-json/vita_route/recommended_activities/");
      dispatch(setTravelTips(response.data));
      //dispatch(setMarkers(jsonResults.sort(() => Math.random() - 0.5)));
    }
    catch (e) {
      //console.log(e)
    }
  }
}
export default getRecommendedTravelTips;