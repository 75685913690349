import * as React from "react";
import store from "../../store/store";
import { addToCart, clearCart } from '../../store/actions/cartActions';
import { updatePopups } from "../../store/actions/popupsActions";
import { setLastItem } from "../../store/actions/lastItemActions";
import { updateItineraryName, updateItineraryId, updateItineraryStatus } from "../../store/actions/itineraryActions";
import { IconClose } from "../../images/icons";

function LoadItineraryPopup(props) {

	const toggleSaveItinerary = () => {
		store.dispatch(updatePopups({ display: true, type: 'save-itinerary' }));
	}

	const loadItinerary = async () => {
		store.dispatch(clearCart());

		const setMarkers = props.data.markers.map((marker) => {
			store.dispatch(addToCart(marker));
		});

		return Promise.all(setMarkers).then(() => {
			store.dispatch(updateItineraryId(props.data.docId));
			store.dispatch(updateItineraryName(props.data.name));
			store.dispatch(updateItineraryStatus('saved'));
            
            store.dispatch(setLastItem({
                dynamicText: props.data.name,
                message: 'loadItinerary',
                icon: true,
                type: 'success'
            }));

			props.toggleDashboard();
			props.closePopup();
		});
	}

	return (
		<>
			<div className="popup">
				<div className="popup-inner login-popup">
					<div className="popup-header no-bg">
						<a onClick={props.closePopup} className="popup-close">
							<IconClose />
						</a>
					</div>
					<div className="popup-content">
						<h2>You have unsaved items in your cart.</h2>
						<p>What would you like to do?</p>
						<br />
						<a onClick={toggleSaveItinerary} className="btn btn-block btn-white" aria-label="Save Itinerary" title="Save Itinerary">Save/Update Itinerary</a>
						<br />
						<a onClick={loadItinerary} className="btn btn-block btn-green" aria-label="Start New Itinerary" title="Start New Itinerary">Load Itinerary</a>
					</div>
				</div>
			</div>
		</>
	);
}

export default LoadItineraryPopup;
