import React, { useState } from "react";

const IconCulinary = (props) => {
	const [active, setActive] = useState(props.active);
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="36"
				height="36"
				viewBox="0 0 36 36"
				className={active ? 'active' : ''}
			>
				<g id="Culinary" transform="translate(25.045 31.982)">
					<g
						id="Group_589"
						data-name="Group 589"
						transform="translate(-14.045 -24.643)"
					>
						<path
							id="Path_1528"
							data-name="Path 1528"
							d="M21.372,23.322c-.838,0-1.4-.374-1.4-.838V13.1c0-1.67-.279-1.855-.648-2.134a2.611,2.611,0,0,1-1.022-1.027c-.838-1.42,0-7.992,2.229-9.464h0a2.144,2.144,0,0,1,1.86-.383.62.62,0,0,1,.369.648V22.5C22.763,22.948,22.114,23.322,21.372,23.322ZM21.741.646a1.485,1.485,0,0,0-.946.369h0C18.84,2.227,18.1,8.449,18.75,9.661a2.365,2.365,0,0,0,.838.833c.648.374,1.022.748,1.022,2.7v9.383a1.551,1.551,0,0,0,1.486,0V.831A.475.475,0,0,0,21.741.646Z"
							transform="translate(-9.475 0)"
						/>
						<path
							id="Path_1529"
							data-name="Path 1529"
							d="M4.006,5.884a.246.246,0,0,1-.279-.208.259.259,0,0,1,0-.066V1.052A.246.246,0,0,1,3.935.773a.258.258,0,0,1,.071,0,.246.246,0,0,1,.279.208.258.258,0,0,1,0,.071V5.609C4.38,5.789,4.191,5.884,4.006,5.884Z"
							transform="translate(-1.962 -0.406)"
						/>
						<path
							id="Path_1530"
							data-name="Path 1530"
							d="M7.146,5.884a.246.246,0,0,1-.279-.208.259.259,0,0,1,0-.066V1.052A.246.246,0,0,1,7.075.773a.258.258,0,0,1,.071,0,.246.246,0,0,1,.279.208.258.258,0,0,1,0,.071V5.609a.247.247,0,0,1-.213.275A.255.255,0,0,1,7.146,5.884Z"
							transform="translate(-3.616 -0.406)"
						/>
						<path
							id="Path_1531"
							data-name="Path 1531"
							d="M2.787,23.42c-.838,0-1.368-.374-1.368-.838V9.849c0-.185-.189-.369-.473-.554A2.4,2.4,0,0,1,0,7.435V.465A.3.3,0,0,1,.279.186.412.412,0,0,1,.558.465v6.97a1.42,1.42,0,0,0,.743,1.3A1.525,1.525,0,0,1,1.95,9.849V22.582a1.445,1.445,0,0,0,.748.185,1.413,1.413,0,0,0,.743-.185V9.849a1.525,1.525,0,0,1,.648-1.112,1.561,1.561,0,0,0,.743-1.3V.465A.3.3,0,0,1,5.111.186.412.412,0,0,1,5.39.465v6.97A2.4,2.4,0,0,1,4.443,9.3c-.279.274-.473.369-.473.554V22.582C4.183,23.046,3.625,23.42,2.787,23.42Z"
							transform="translate(0 -0.098)"
						/>
					</g>
				</g>
			</svg>
		</>
	);
}

export default IconCulinary