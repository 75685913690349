// Import your Redux store and actions
import store from '../store/store';
import { addToCart, removeFromCart, clearCart } from '../store/actions/cartActions';
import { updateItineraryName, updateItineraryId, updateItineraryStatus } from '../store/actions/itineraryActions';

// Store previous state
let prevState = store.getState();

// Subscribe to Redux store
store.subscribe(() => {
  const newState = store.getState();

  const { itineraryName, itineraryId, itineraryStatus } = newState.itinerary;
  const { cartItems } = newState.cart;

  if (prevState.itineraryName !== itineraryName) {
    window.parent.postMessage({
      type: 'UPDATE_ITINERARY_NAME',
      payload: itineraryName,
    }, 'http://visitidaho.org');
  }

  if (prevState.itineraryId !== itineraryId) {
    window.parent.postMessage({
      type: 'UPDATE_ITINERARY_ID',
      payload: itineraryId,
    }, 'http://visitidaho.org');
  }

  if (prevState.itineraryStatus !== itineraryStatus) {
    window.parent.postMessage({
      type: 'UPDATE_ITINERARY_STATUS',
      payload: itineraryStatus,
    }, 'http://visitidaho.org');
  }

  if (JSON.stringify(prevState.cart.cartItems) !== JSON.stringify(cartItems)) {
    window.parent.postMessage({
      type: 'UPDATE_CART_ITEMS',
      payload: cartItems,
    }, 'http://visitidaho.org');
  }

  prevState = newState;
});

// Event listener for postMessages from the main domain
window.addEventListener('message', function(event) {
  if (event.origin !== "http://visitidaho.org") {
    return;
  }

  if(!event.data || !event.data.type || !('payload' in event.data)) {
    return;
  }

  const { type, payload } = event.data;

  switch (type) {
    case 'ADD_TO_CART':
      store.dispatch(addToCart(payload));
      break;
      
    case 'REMOVE_FROM_CART':
      store.dispatch(removeFromCart(payload));
      break;

    case 'CLEAR_CART':
      store.dispatch(clearCart());
      break;

    case 'REQUEST_CART_STATUS':
      // Assume getCartItems is a selector that returns the current cart items from the state
      const isInCart = getCartItems().findIndex(item => item.id === payload.id) !== -1;
      window.parent.postMessage({
        type: 'CART_STATUS',
        payload: { inCart: isInCart }
      }, "http://visitidaho.org");
      break;

    case 'UPDATE_ITINERARY_NAME':
      store.dispatch(updateItineraryName(payload));
      break;

    case 'UPDATE_ITINERARY_ID':
      store.dispatch(updateItineraryId(payload));
      break;

    case 'UPDATE_ITINERARY_STATUS':
      store.dispatch(updateItineraryStatus(payload));
      break;

    default:
      // DO NOTHING?
      break;
  }
});
