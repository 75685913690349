const PeaksToCraters = {
    type: "FeatureCollection",
    features: [{
        type: "Feature",
        properties: {
            name: "Peaks to Craters Scenic Byway",
            "description": "This extraordinary byway is said to be the most diverse stretch of road in Idaho-boasting everything from sky-piercing peaks to captivating craters. Explore a vast ocean of lava flows at Craters of the Moon National Monument & Preserve, visit Mount Borah, Idaho's tallest peak, or explore the first city lit by atomic energy in Arco.",
            "link": "https://visitidaho.org/things-to-do/road-trips/peaks-to-craters-scenic-byway/",
            "route": [
                "Challis", 
                "Carey",
                "Picabo",
                "Arco",
                "Mackay"
            ],
            "shape": "Line"
        },
        geometry: {
            type: "LineString",
            coordinates: [
                [-114.27888, 43.33199, 0],
                [-114.27672, 43.33219, 0],
                [-114.27518, 43.33229, 0],
                [-114.27405, 43.33234, 0],
                [-114.25216, 43.3329, 0],
                [-114.25092, 43.3329, 0],
                [-114.2496, 43.33284, 0],
                [-114.24845, 43.33273, 0],
                [-114.24837, 43.33273, 0],
                [-114.24781, 43.33266, 0],
                [-114.2469, 43.33253, 0],
                [-114.2466, 43.33248, 0],
                [-114.24591, 43.33235, 0],
                [-114.2449, 43.33213, 0],
                [-114.24361, 43.33178, 0],
                [-114.24233, 43.3314, 0],
                [-114.24176, 43.33124, 0],
                [-114.24125, 43.33111, 0],
                [-114.2401, 43.33087, 0],
                [-114.2398, 43.33082, 0],
                [-114.23935, 43.33076, 0],
                [-114.23899, 43.33072, 0],
                [-114.23888, 43.3307, 0],
                [-114.23774, 43.33061, 0],
                [-114.23761, 43.33061, 0],
                [-114.23689, 43.33059, 0],
                [-114.19918, 43.33083, 0],
                [-114.19866, 43.33084, 0],
                [-114.18871, 43.33085, 0],
                [-114.18799, 43.33086, 0],
                [-114.16304, 43.3309, 0],
                [-114.16233, 43.33091, 0],
                [-114.14236, 43.33093, 0],
                [-114.14213, 43.33092, 0],
                [-114.14196, 43.3309, 0],
                [-114.14188, 43.3309, 0],
                [-114.14164, 43.33086, 0],
                [-114.14139, 43.33083, 0],
                [-114.14131, 43.33081, 0],
                [-114.14123, 43.3308, 0],
                [-114.14109, 43.33077, 0],
                [-114.14041, 43.3306, 0],
                [-114.14009, 43.3305, 0],
                [-114.13962, 43.33031, 0],
                [-114.13929, 43.33016, 0],
                [-114.13911, 43.33007, 0],
                [-114.13856, 43.32973, 0],
                [-114.13834, 43.32958, 0],
                [-114.13815, 43.32943, 0],
                [-114.1377, 43.32903, 0],
                [-114.13572, 43.3271, 0],
                [-114.13551, 43.32691, 0],
                [-114.13523, 43.32663, 0],
                [-114.13445, 43.32588, 0],
                [-114.13419, 43.32565, 0],
                [-114.13391, 43.32542, 0],
                [-114.13362, 43.3252, 0],
                [-114.13323, 43.32494, 0],
                [-114.13284, 43.32471, 0],
                [-114.13257, 43.32456, 0],
                [-114.13199, 43.32429, 0],
                [-114.1317, 43.32417, 0],
                [-114.13112, 43.32396, 0],
                [-114.13082, 43.32387, 0],
                [-114.1307, 43.32384, 0],
                [-114.13053, 43.32379, 0],
                [-114.12588, 43.32261, 0],
                [-114.12571, 43.32256, 0],
                [-114.12371, 43.32204, 0],
                [-114.12291, 43.32182, 0],
                [-114.12097, 43.32132, 0],
                [-114.12079, 43.32128, 0],
                [-114.10871, 43.3182, 0],
                [-114.10591, 43.31745, 0],
                [-114.1047, 43.31714, 0],
                [-114.10186, 43.31646, 0],
                [-114.09176, 43.31389, 0],
                [-114.08846, 43.31303, 0],
                [-114.08049, 43.31101, 0],
                [-114.07966, 43.31079, 0],
                [-114.07912, 43.31066, 0],
                [-114.07256, 43.30898, 0],
                [-114.07118, 43.30864, 0],
                [-114.07098, 43.3086, 0],
                [-114.07069, 43.30853, 0],
                [-114.06976, 43.30838, 0],
                [-114.0679, 43.30811, 0],
                [-114.06756, 43.30805, 0],
                [-114.0651, 43.30768, 0],
                [-114.065, 43.30767, 0],
                [-114.0539, 43.306, 0],
                [-114.05343, 43.30591, 0],
                [-114.05332, 43.30588, 0],
                [-114.05317, 43.30585, 0],
                [-114.05298, 43.3058, 0],
                [-114.0528, 43.30576, 0],
                [-114.05232, 43.30562, 0],
                [-114.0522, 43.30559, 0],
                [-114.05186, 43.30548, 0],
                [-114.05173, 43.30543, 0],
                [-114.05161, 43.30539, 0],
                [-114.0509, 43.30511, 0],
                [-114.05076, 43.30505, 0],
                [-114.05064, 43.30499, 0],
                [-114.05033, 43.30485, 0],
                [-114.04975, 43.30454, 0],
                [-114.04948, 43.30438, 0],
                [-114.04868, 43.30386, 0],
                [-114.04866, 43.30385, 0],
                [-114.04743, 43.30306, 0],
                [-114.04717, 43.30291, 0],
                [-114.04677, 43.30271, 0],
                [-114.04668, 43.30267, 0],
                [-114.04654, 43.3026, 0],
                [-114.04638, 43.30254, 0],
                [-114.04625, 43.30248, 0],
                [-114.04611, 43.30243, 0],
                [-114.04596, 43.30237, 0],
                [-114.04566, 43.30228, 0],
                [-114.04555, 43.30224, 0],
                [-114.04529, 43.30216, 0],
                [-114.04512, 43.30212, 0],
                [-114.04467, 43.302, 0],
                [-114.04463, 43.302, 0],
                [-114.043, 43.30158, 0],
                [-114.04299, 43.30158, 0],
                [-114.04277, 43.30152, 0],
                [-114.04263, 43.30149, 0],
                [-114.04253, 43.30146, 0],
                [-114.04162, 43.30123, 0],
                [-114.04127, 43.30113, 0],
                [-114.04114, 43.3011, 0],
                [-114.04093, 43.30103, 0],
                [-114.04091, 43.30102, 0],
                [-114.04085, 43.301, 0],
                [-114.04078, 43.30097, 0],
                [-114.04066, 43.30093, 0],
                [-114.04037, 43.30082, 0],
                [-114.04022, 43.30075, 0],
                [-114.04001, 43.30066, 0],
                [-114.0396, 43.30046, 0],
                [-114.03942, 43.30036, 0],
                [-114.03925, 43.30025, 0],
                [-114.03914, 43.30019, 0],
                [-114.03888, 43.30002, 0],
                [-114.03856, 43.29979, 0],
                [-114.03849, 43.29972, 0],
                [-114.03828, 43.29955, 0],
                [-114.0382, 43.29948, 0],
                [-114.03788, 43.29918, 0],
                [-114.03771, 43.29899, 0],
                [-114.03767, 43.29895, 0],
                [-114.03759, 43.29885, 0],
                [-114.03751, 43.29874, 0],
                [-114.0374, 43.29861, 0],
                [-114.03667, 43.29754, 0],
                [-114.03643, 43.29717, 0],
                [-114.03635, 43.29707, 0],
                [-114.03622, 43.29689, 0],
                [-114.03613, 43.29678, 0],
                [-114.03605, 43.29669, 0],
                [-114.03582, 43.29646, 0],
                [-114.03565, 43.29631, 0],
                [-114.03548, 43.29617, 0],
                [-114.03536, 43.29608, 0],
                [-114.03499, 43.29583, 0],
                [-114.03484, 43.29574, 0],
                [-114.0345, 43.29556, 0],
                [-114.03393, 43.29532, 0],
                [-114.03339, 43.29515, 0],
                [-114.03297, 43.29505, 0],
                [-114.03295, 43.29505, 0],
                [-114.03279, 43.29501, 0],
                [-114.03226, 43.29494, 0],
                [-114.03212, 43.29493, 0],
                [-114.03196, 43.29491, 0],
                [-114.03181, 43.2949, 0],
                [-114.03108, 43.29489, 0],
                [-114.02936, 43.29489, 0],
                [-114.02671, 43.29494, 0],
                [-114.02511, 43.29494, 0],
                [-114.02498, 43.29493, 0],
                [-114.02486, 43.29493, 0],
                [-114.02416, 43.29487, 0],
                [-114.02398, 43.29485, 0],
                [-114.02343, 43.29476, 0],
                [-114.02335, 43.29474, 0],
                [-114.02323, 43.29472, 0],
                [-114.02262, 43.29457, 0],
                [-114.02243, 43.29451, 0],
                [-114.02232, 43.29448, 0],
                [-114.02221, 43.29444, 0],
                [-114.02205, 43.29439, 0],
                [-114.02195, 43.29435, 0],
                [-114.02181, 43.2943, 0],
                [-114.02167, 43.29424, 0],
                [-114.02154, 43.29419, 0],
                [-114.01644, 43.29183, 0],
                [-114.01633, 43.29177, 0],
                [-114.01553, 43.29142, 0],
                [-114.0148, 43.29116, 0],
                [-114.01464, 43.29111, 0],
                [-114.01459, 43.2911, 0],
                [-114.01438, 43.29104, 0],
                [-114.01364, 43.29085, 0],
                [-114.01315, 43.29075, 0],
                [-114.01292, 43.29071, 0],
                [-114.01278, 43.29068, 0],
                [-114.01266, 43.29066, 0],
                [-114.01255, 43.29065, 0],
                [-114.01222, 43.2906, 0],
                [-114.01166, 43.29054, 0],
                [-114.01122, 43.29051, 0],
                [-114.01096, 43.29051, 0],
                [-114.01044, 43.29049, 0],
                [-114.00982, 43.29051, 0],
                [-114.00926, 43.29054, 0],
                [-114.00901, 43.29056, 0],
                [-114.00837, 43.29064, 0],
                [-114.00778, 43.29074, 0],
                [-114.00743, 43.29081, 0],
                [-114.00741, 43.29081, 0],
                [-114.00667, 43.29099, 0],
                [-114.00595, 43.29121, 0],
                [-114.00318, 43.29218, 0],
                [-114.0029, 43.29227, 0],
                [-113.99936, 43.29351, 0],
                [-113.99836, 43.29384, 0],
                [-113.99598, 43.29468, 0],
                [-113.99504, 43.295, 0],
                [-113.99481, 43.29507, 0],
                [-113.99473, 43.29509, 0],
                [-113.99432, 43.29521, 0],
                [-113.99418, 43.29524, 0],
                [-113.9939, 43.29531, 0],
                [-113.99381, 43.29532, 0],
                [-113.9936, 43.29537, 0],
                [-113.99334, 43.29541, 0],
                [-113.99295, 43.29546, 0],
                [-113.99276, 43.29549, 0],
                [-113.99261, 43.2955, 0],
                [-113.99241, 43.29552, 0],
                [-113.99196, 43.29555, 0],
                [-113.99111, 43.29555, 0],
                [-113.99073, 43.29553, 0],
                [-113.99007, 43.29547, 0],
                [-113.98994, 43.29545, 0],
                [-113.98981, 43.29544, 0],
                [-113.98963, 43.29541, 0],
                [-113.98281, 43.2941, 0],
                [-113.98224, 43.294, 0],
                [-113.98201, 43.29395, 0],
                [-113.98052, 43.29366, 0],
                [-113.98028, 43.29363, 0],
                [-113.97981, 43.2936, 0],
                [-113.97937, 43.2936, 0],
                [-113.97918, 43.29362, 0],
                [-113.97908, 43.29362, 0],
                [-113.97901, 43.29363, 0],
                [-113.9789, 43.29364, 0],
                [-113.9786, 43.29369, 0],
                [-113.97841, 43.29373, 0],
                [-113.97811, 43.29381, 0],
                [-113.97802, 43.29383, 0],
                [-113.97791, 43.29386, 0],
                [-113.97778, 43.29391, 0],
                [-113.97763, 43.29396, 0],
                [-113.97741, 43.29405, 0],
                [-113.97726, 43.29412, 0],
                [-113.97711, 43.29418, 0],
                [-113.97699, 43.29425, 0],
                [-113.9768, 43.29435, 0],
                [-113.9767, 43.29441, 0],
                [-113.97653, 43.29452, 0],
                [-113.97651, 43.29453, 0],
                [-113.97601, 43.29487, 0],
                [-113.97525, 43.29536, 0],
                [-113.97518, 43.29539, 0],
                [-113.97508, 43.29544, 0],
                [-113.97492, 43.29553, 0],
                [-113.97482, 43.29557, 0],
                [-113.97479, 43.29558, 0],
                [-113.97474, 43.29561, 0],
                [-113.97467, 43.29563, 0],
                [-113.97444, 43.29572, 0],
                [-113.9743, 43.29577, 0],
                [-113.97408, 43.29584, 0],
                [-113.97394, 43.29587, 0],
                [-113.97381, 43.29591, 0],
                [-113.97349, 43.29597, 0],
                [-113.97343, 43.29599, 0],
                [-113.97336, 43.296, 0],
                [-113.97295, 43.29604, 0],
                [-113.97287, 43.29604, 0],
                [-113.9728, 43.29605, 0],
                [-113.97246, 43.29605, 0],
                [-113.9719, 43.29602, 0],
                [-113.96999, 43.29586, 0],
                [-113.96981, 43.29585, 0],
                [-113.9695, 43.29582, 0],
                [-113.96939, 43.29582, 0],
                [-113.9692, 43.29581, 0],
                [-113.96879, 43.29581, 0],
                [-113.96848, 43.29583, 0],
                [-113.96841, 43.29583, 0],
                [-113.9683, 43.29584, 0],
                [-113.96772, 43.29592, 0],
                [-113.96768, 43.29593, 0],
                [-113.96741, 43.29598, 0],
                [-113.9672, 43.29601, 0],
                [-113.96708, 43.29604, 0],
                [-113.96677, 43.29613, 0],
                [-113.9665, 43.29622, 0],
                [-113.96634, 43.29628, 0],
                [-113.96615, 43.29636, 0],
                [-113.96607, 43.29639, 0],
                [-113.96598, 43.29643, 0],
                [-113.96591, 43.29647, 0],
                [-113.9659, 43.29647, 0],
                [-113.96578, 43.29653, 0],
                [-113.96556, 43.29665, 0],
                [-113.96547, 43.29671, 0],
                [-113.96533, 43.29679, 0],
                [-113.96525, 43.29684, 0],
                [-113.96515, 43.29691, 0],
                [-113.96493, 43.29708, 0],
                [-113.96484, 43.29714, 0],
                [-113.96474, 43.29723, 0],
                [-113.96464, 43.29733, 0],
                [-113.96452, 43.29744, 0],
                [-113.96274, 43.2992, 0],
                [-113.96271, 43.29924, 0],
                [-113.96201, 43.29993, 0],
                [-113.96162, 43.30026, 0],
                [-113.96117, 43.30059, 0],
                [-113.961, 43.30069, 0],
                [-113.96075, 43.30085, 0],
                [-113.96057, 43.30095, 0],
                [-113.95994, 43.30126, 0],
                [-113.95964, 43.30139, 0],
                [-113.95949, 43.30144, 0],
                [-113.95934, 43.3015, 0],
                [-113.95899, 43.30162, 0],
                [-113.95872, 43.3017, 0],
                [-113.95856, 43.30174, 0],
                [-113.9584, 43.30179, 0],
                [-113.95806, 43.30187, 0],
                [-113.95772, 43.30193, 0],
                [-113.95758, 43.30196, 0],
                [-113.95715, 43.30202, 0],
                [-113.95686, 43.30205, 0],
                [-113.95628, 43.30209, 0],
                [-113.94579, 43.30207, 0],
                [-113.94579, 43.30236, 0],
                [-113.9458, 43.30252, 0],
                [-113.94585, 43.3062, 0],
                [-113.94586, 43.30814, 0],
                [-113.94589, 43.30929, 0],
                [-113.94584, 43.31098, 0],
                [-113.94586, 43.31525, 0],
                [-113.94588, 43.31673, 0],
                [-113.94587, 43.31706, 0],
                [-113.94582, 43.31731, 0],
                [-113.94579, 43.31739, 0],
                [-113.94577, 43.31743, 0],
                [-113.94576, 43.31747, 0],
                [-113.94571, 43.31755, 0],
                [-113.94568, 43.31761, 0],
                [-113.94553, 43.31783, 0],
                [-113.94548, 43.31788, 0],
                [-113.94525, 43.31814, 0],
                [-113.94516, 43.31823, 0],
                [-113.94502, 43.31836, 0],
                [-113.9438, 43.31941, 0],
                [-113.94361, 43.31959, 0],
                [-113.94251, 43.32056, 0],
                [-113.94219, 43.32083, 0],
                [-113.93965, 43.32304, 0],
                [-113.93962, 43.32307, 0],
                [-113.93596, 43.32625, 0],
                [-113.93563, 43.32655, 0],
                [-113.93528, 43.32689, 0],
                [-113.93512, 43.32707, 0],
                [-113.93496, 43.32724, 0],
                [-113.93482, 43.3274, 0],
                [-113.93216, 43.33058, 0],
                [-113.93204, 43.33071, 0],
                [-113.93141, 43.33148, 0],
                [-113.93138, 43.33151, 0],
                [-113.93075, 43.33228, 0],
                [-113.93061, 43.33243, 0],
                [-113.93048, 43.33258, 0],
                [-113.93039, 43.33267, 0],
                [-113.93031, 43.33276, 0],
                [-113.93003, 43.33302, 0],
                [-113.92978, 43.33322, 0],
                [-113.9297, 43.33329, 0],
                [-113.92937, 43.33353, 0],
                [-113.92913, 43.33369, 0],
                [-113.92881, 43.33388, 0],
                [-113.92868, 43.33395, 0],
                [-113.92858, 43.33401, 0],
                [-113.9283, 43.33416, 0],
                [-113.92807, 43.33427, 0],
                [-113.92784, 43.33437, 0],
                [-113.9273, 43.33457, 0],
                [-113.92687, 43.33471, 0],
                [-113.92642, 43.33483, 0],
                [-113.92623, 43.33487, 0],
                [-113.92599, 43.33493, 0],
                [-113.92574, 43.33497, 0],
                [-113.9256, 43.335, 0],
                [-113.92522, 43.33506, 0],
                [-113.92492, 43.3351, 0],
                [-113.92453, 43.33513, 0],
                [-113.92444, 43.33513, 0],
                [-113.92433, 43.33514, 0],
                [-113.92267, 43.33514, 0],
                [-113.92103, 43.33512, 0],
                [-113.91848, 43.33512, 0],
                [-113.9183, 43.33511, 0],
                [-113.91703, 43.33511, 0],
                [-113.91646, 43.3351, 0],
                [-113.88561, 43.33494, 0],
                [-113.88521, 43.33496, 0],
                [-113.88509, 43.33497, 0],
                [-113.88444, 43.33505, 0],
                [-113.88377, 43.33518, 0],
                [-113.88342, 43.33526, 0],
                [-113.88296, 43.3354, 0],
                [-113.88265, 43.33551, 0],
                [-113.8819, 43.3358, 0],
                [-113.86997, 43.34071, 0],
                [-113.86986, 43.34076, 0],
                [-113.86116, 43.34433, 0],
                [-113.86114, 43.34434, 0],
                [-113.85736, 43.34589, 0],
                [-113.85656, 43.34624, 0],
                [-113.85598, 43.34651, 0],
                [-113.85587, 43.34658, 0],
                [-113.85574, 43.34664, 0],
                [-113.84542, 43.35276, 0],
                [-113.84541, 43.35276, 0],
                [-113.84256, 43.35445, 0],
                [-113.84209, 43.35472, 0],
                [-113.84197, 43.3548, 0],
                [-113.83298, 43.36014, 0],
                [-113.83282, 43.36024, 0],
                [-113.83183, 43.36082, 0],
                [-113.83163, 43.36093, 0],
                [-113.82987, 43.36197, 0],
                [-113.82958, 43.36213, 0],
                [-113.82915, 43.36234, 0],
                [-113.82909, 43.36236, 0],
                [-113.82908, 43.36237, 0],
                [-113.82868, 43.36253, 0],
                [-113.82848, 43.3626, 0],
                [-113.82844, 43.36262, 0],
                [-113.82829, 43.36267, 0],
                [-113.82817, 43.3627, 0],
                [-113.82805, 43.36274, 0],
                [-113.82764, 43.36285, 0],
                [-113.8275, 43.36288, 0],
                [-113.8273, 43.36293, 0],
                [-113.82703, 43.36297, 0],
                [-113.82689, 43.363, 0],
                [-113.81436, 43.3645, 0],
                [-113.81404, 43.36453, 0],
                [-113.81339, 43.36461, 0],
                [-113.81338, 43.36462, 0],
                [-113.80452, 43.36567, 0],
                [-113.80395, 43.36573, 0],
                [-113.8039, 43.36573, 0],
                [-113.8035, 43.36576, 0],
                [-113.80288, 43.36576, 0],
                [-113.80274, 43.36575, 0],
                [-113.80261, 43.36575, 0],
                [-113.80231, 43.36573, 0],
                [-113.80214, 43.36571, 0],
                [-113.802, 43.3657, 0],
                [-113.80164, 43.36566, 0],
                [-113.79088, 43.36409, 0],
                [-113.78999, 43.364, 0],
                [-113.78981, 43.364, 0],
                [-113.78956, 43.36398, 0],
                [-113.78924, 43.36398, 0],
                [-113.78909, 43.36397, 0],
                [-113.78861, 43.36397, 0],
                [-113.78845, 43.36398, 0],
                [-113.78317, 43.36409, 0],
                [-113.7821, 43.3641, 0],
                [-113.78119, 43.36413, 0],
                [-113.77974, 43.36416, 0],
                [-113.7795, 43.36416, 0],
                [-113.77924, 43.36417, 0],
                [-113.77895, 43.36419, 0],
                [-113.77887, 43.36419, 0],
                [-113.77872, 43.3642, 0],
                [-113.77853, 43.36422, 0],
                [-113.77825, 43.36424, 0],
                [-113.77797, 43.36428, 0],
                [-113.77784, 43.36429, 0],
                [-113.77741, 43.36435, 0],
                [-113.77716, 43.3644, 0],
                [-113.77677, 43.36446, 0],
                [-113.77667, 43.36448, 0],
                [-113.77635, 43.36453, 0],
                [-113.77245, 43.36523, 0],
                [-113.77229, 43.36525, 0],
                [-113.76985, 43.36569, 0],
                [-113.76884, 43.36585, 0],
                [-113.76806, 43.36592, 0],
                [-113.76788, 43.36592, 0],
                [-113.76771, 43.36593, 0],
                [-113.76355, 43.36608, 0],
                [-113.76324, 43.3661, 0],
                [-113.76268, 43.36617, 0],
                [-113.76266, 43.36617, 0],
                [-113.76231, 43.36624, 0],
                [-113.76202, 43.36631, 0],
                [-113.76175, 43.36639, 0],
                [-113.76159, 43.36645, 0],
                [-113.76144, 43.3665, 0],
                [-113.7612, 43.36659, 0],
                [-113.76106, 43.36666, 0],
                [-113.76092, 43.36672, 0],
                [-113.76082, 43.36677, 0],
                [-113.76044, 43.36698, 0],
                [-113.75972, 43.36742, 0],
                [-113.75968, 43.36744, 0],
                [-113.75896, 43.36788, 0],
                [-113.75848, 43.36819, 0],
                [-113.75542, 43.37006, 0],
                [-113.75504, 43.37031, 0],
                [-113.75433, 43.37075, 0],
                [-113.75401, 43.37093, 0],
                [-113.75371, 43.37109, 0],
                [-113.75363, 43.37114, 0],
                [-113.75353, 43.37118, 0],
                [-113.75341, 43.37124, 0],
                [-113.75318, 43.37134, 0],
                [-113.75298, 43.37141, 0],
                [-113.75288, 43.37144, 0],
                [-113.75276, 43.37149, 0],
                [-113.75228, 43.37161, 0],
                [-113.7521, 43.37165, 0],
                [-113.75207, 43.37166, 0],
                [-113.75194, 43.37168, 0],
                [-113.75181, 43.37171, 0],
                [-113.75137, 43.37177, 0],
                [-113.75118, 43.37178, 0],
                [-113.75075, 43.37182, 0],
                [-113.74353, 43.3721, 0],
                [-113.74333, 43.37212, 0],
                [-113.74297, 43.37214, 0],
                [-113.7428, 43.37216, 0],
                [-113.74267, 43.37217, 0],
                [-113.74255, 43.37219, 0],
                [-113.74243, 43.3722, 0],
                [-113.74199, 43.37227, 0],
                [-113.74187, 43.3723, 0],
                [-113.74174, 43.37232, 0],
                [-113.74159, 43.37236, 0],
                [-113.74143, 43.37239, 0],
                [-113.74131, 43.37243, 0],
                [-113.7412, 43.37245, 0],
                [-113.74111, 43.37248, 0],
                [-113.74102, 43.3725, 0],
                [-113.73328, 43.37497, 0],
                [-113.73274, 43.37515, 0],
                [-113.72911, 43.3763, 0],
                [-113.72832, 43.37656, 0],
                [-113.72747, 43.37682, 0],
                [-113.72502, 43.3776, 0],
                [-113.72455, 43.37777, 0],
                [-113.7239, 43.37804, 0],
                [-113.723, 43.37844, 0],
                [-113.72145, 43.37916, 0],
                [-113.72029, 43.37968, 0],
                [-113.71961, 43.37996, 0],
                [-113.7195, 43.38, 0],
                [-113.7194, 43.38003, 0],
                [-113.71918, 43.38011, 0],
                [-113.71863, 43.38028, 0],
                [-113.71786, 43.38048, 0],
                [-113.7177, 43.38051, 0],
                [-113.71738, 43.38058, 0],
                [-113.71729, 43.38059, 0],
                [-113.71705, 43.38063, 0],
                [-113.71607, 43.38076, 0],
                [-113.71347, 43.38104, 0],
                [-113.71332, 43.38105, 0],
                [-113.71274, 43.38112, 0],
                [-113.71127, 43.38127, 0],
                [-113.71067, 43.38134, 0],
                [-113.71053, 43.38135, 0],
                [-113.70975, 43.38144, 0],
                [-113.70949, 43.38148, 0],
                [-113.7093, 43.3815, 0],
                [-113.709, 43.38155, 0],
                [-113.70862, 43.38163, 0],
                [-113.70841, 43.38169, 0],
                [-113.70836, 43.3817, 0],
                [-113.70826, 43.38173, 0],
                [-113.70812, 43.38176, 0],
                [-113.70798, 43.38181, 0],
                [-113.70782, 43.38186, 0],
                [-113.70762, 43.38193, 0],
                [-113.70717, 43.3821, 0],
                [-113.70632, 43.3824, 0],
                [-113.70578, 43.38255, 0],
                [-113.70551, 43.38261, 0],
                [-113.70519, 43.38267, 0],
                [-113.70518, 43.38268, 0],
                [-113.70088, 43.38339, 0],
                [-113.70044, 43.38347, 0],
                [-113.69916, 43.38373, 0],
                [-113.69515, 43.38463, 0],
                [-113.69491, 43.38469, 0],
                [-113.69282, 43.38515, 0],
                [-113.69232, 43.38528, 0],
                [-113.69189, 43.38538, 0],
                [-113.69162, 43.38546, 0],
                [-113.69161, 43.38546, 0],
                [-113.69149, 43.3855, 0],
                [-113.69131, 43.38555, 0],
                [-113.69084, 43.38572, 0],
                [-113.68458, 43.38811, 0],
                [-113.68435, 43.38819, 0],
                [-113.68364, 43.38846, 0],
                [-113.68281, 43.38875, 0],
                [-113.68252, 43.38884, 0],
                [-113.68203, 43.38898, 0],
                [-113.6818, 43.38904, 0],
                [-113.68156, 43.38909, 0],
                [-113.68133, 43.38915, 0],
                [-113.68101, 43.38922, 0],
                [-113.68089, 43.38924, 0],
                [-113.68065, 43.38929, 0],
                [-113.6801, 43.38938, 0],
                [-113.6794, 43.38947, 0],
                [-113.67889, 43.38951, 0],
                [-113.67864, 43.38954, 0],
                [-113.67793, 43.38957, 0],
                [-113.6769, 43.38957, 0],
                [-113.67606, 43.38952, 0],
                [-113.67557, 43.38948, 0],
                [-113.67544, 43.38946, 0],
                [-113.67535, 43.38946, 0],
                [-113.67463, 43.38936, 0],
                [-113.67377, 43.38921, 0],
                [-113.6735, 43.38915, 0],
                [-113.67347, 43.38915, 0],
                [-113.67333, 43.38911, 0],
                [-113.67319, 43.38908, 0],
                [-113.67253, 43.38891, 0],
                [-113.672, 43.38875, 0],
                [-113.67189, 43.38871, 0],
                [-113.67179, 43.38868, 0],
                [-113.67137, 43.38853, 0],
                [-113.6712, 43.38846, 0],
                [-113.67103, 43.3884, 0],
                [-113.67089, 43.38834, 0],
                [-113.67076, 43.38829, 0],
                [-113.67016, 43.38804, 0],
                [-113.66695, 43.38665, 0],
                [-113.66689, 43.38662, 0],
                [-113.66674, 43.38656, 0],
                [-113.66665, 43.38652, 0],
                [-113.66657, 43.38648, 0],
                [-113.66646, 43.38644, 0],
                [-113.66633, 43.3864, 0],
                [-113.66619, 43.38635, 0],
                [-113.66603, 43.3863, 0],
                [-113.6657, 43.38622, 0],
                [-113.6655, 43.38618, 0],
                [-113.66537, 43.38616, 0],
                [-113.66529, 43.38614, 0],
                [-113.66482, 43.38608, 0],
                [-113.66435, 43.38605, 0],
                [-113.66395, 43.38605, 0],
                [-113.66368, 43.38607, 0],
                [-113.66365, 43.38607, 0],
                [-113.66316, 43.38612, 0],
                [-113.66314, 43.38612, 0],
                [-113.66304, 43.38614, 0],
                [-113.66296, 43.38615, 0],
                [-113.66288, 43.38617, 0],
                [-113.66282, 43.38618, 0],
                [-113.66232, 43.3863, 0],
                [-113.66214, 43.38635, 0],
                [-113.66199, 43.3864, 0],
                [-113.66195, 43.38641, 0],
                [-113.66155, 43.38657, 0],
                [-113.66152, 43.38658, 0],
                [-113.66131, 43.38667, 0],
                [-113.66122, 43.38672, 0],
                [-113.66112, 43.38677, 0],
                [-113.66083, 43.38693, 0],
                [-113.66071, 43.38699, 0],
                [-113.6601, 43.38734, 0],
                [-113.65975, 43.38753, 0],
                [-113.65818, 43.38842, 0],
                [-113.65799, 43.38852, 0],
                [-113.65563, 43.38986, 0],
                [-113.65522, 43.39008, 0],
                [-113.65517, 43.39012, 0],
                [-113.65513, 43.39014, 0],
                [-113.65508, 43.39017, 0],
                [-113.65499, 43.39021, 0],
                [-113.65493, 43.39025, 0],
                [-113.65482, 43.39031, 0],
                [-113.65471, 43.39036, 0],
                [-113.65422, 43.39063, 0],
                [-113.65402, 43.39073, 0],
                [-113.65395, 43.39076, 0],
                [-113.65373, 43.39084, 0],
                [-113.65354, 43.3909, 0],
                [-113.65346, 43.39093, 0],
                [-113.65323, 43.39099, 0],
                [-113.65319, 43.39099, 0],
                [-113.65305, 43.39102, 0],
                [-113.65296, 43.39103, 0],
                [-113.65292, 43.39103, 0],
                [-113.65287, 43.39104, 0],
                [-113.65278, 43.39105, 0],
                [-113.65269, 43.39105, 0],
                [-113.65254, 43.39106, 0],
                [-113.6524, 43.39106, 0],
                [-113.6522, 43.39105, 0],
                [-113.65204, 43.39105, 0],
                [-113.65185, 43.39103, 0],
                [-113.65177, 43.39101, 0],
                [-113.65169, 43.391, 0],
                [-113.65159, 43.39098, 0],
                [-113.65146, 43.39094, 0],
                [-113.65129, 43.3909, 0],
                [-113.65111, 43.39085, 0],
                [-113.65074, 43.39073, 0],
                [-113.65061, 43.39068, 0],
                [-113.65032, 43.39059, 0],
                [-113.64999, 43.3905, 0],
                [-113.64985, 43.39047, 0],
                [-113.64974, 43.39044, 0],
                [-113.64949, 43.3904, 0],
                [-113.64937, 43.39039, 0],
                [-113.64926, 43.39037, 0],
                [-113.64904, 43.39035, 0],
                [-113.64886, 43.39034, 0],
                [-113.64875, 43.39034, 0],
                [-113.64863, 43.39033, 0],
                [-113.64828, 43.39033, 0],
                [-113.64779, 43.39037, 0],
                [-113.64756, 43.3904, 0],
                [-113.64724, 43.39046, 0],
                [-113.64713, 43.39049, 0],
                [-113.64694, 43.39053, 0],
                [-113.6465, 43.39065, 0],
                [-113.64617, 43.39073, 0],
                [-113.63887, 43.39275, 0],
                [-113.63854, 43.39285, 0],
                [-113.63833, 43.39292, 0],
                [-113.63787, 43.39309, 0],
                [-113.63779, 43.39313, 0],
                [-113.63774, 43.39316, 0],
                [-113.63769, 43.39318, 0],
                [-113.63765, 43.3932, 0],
                [-113.6376, 43.39323, 0],
                [-113.63756, 43.39326, 0],
                [-113.63749, 43.3933, 0],
                [-113.63745, 43.39332, 0],
                [-113.63741, 43.39335, 0],
                [-113.63722, 43.39347, 0],
                [-113.63719, 43.3935, 0],
                [-113.6371, 43.39356, 0],
                [-113.63656, 43.39404, 0],
                [-113.63656, 43.39405, 0],
                [-113.63639, 43.39421, 0],
                [-113.63622, 43.39436, 0],
                [-113.63602, 43.39455, 0],
                [-113.63569, 43.39482, 0],
                [-113.63546, 43.39497, 0],
                [-113.63532, 43.39504, 0],
                [-113.63521, 43.39509, 0],
                [-113.63513, 43.39514, 0],
                [-113.63493, 43.39523, 0],
                [-113.63445, 43.39543, 0],
                [-113.63349, 43.39581, 0],
                [-113.63335, 43.39587, 0],
                [-113.63132, 43.39666, 0],
                [-113.63128, 43.39668, 0],
                [-113.63111, 43.39675, 0],
                [-113.63092, 43.39684, 0],
                [-113.63073, 43.39694, 0],
                [-113.63058, 43.39701, 0],
                [-113.63032, 43.39716, 0],
                [-113.63001, 43.39737, 0],
                [-113.62991, 43.39745, 0],
                [-113.62976, 43.39758, 0],
                [-113.62949, 43.39785, 0],
                [-113.62921, 43.39816, 0],
                [-113.6291, 43.3983, 0],
                [-113.629, 43.39845, 0],
                [-113.62874, 43.39891, 0],
                [-113.62859, 43.39926, 0],
                [-113.62852, 43.39946, 0],
                [-113.62847, 43.39962, 0],
                [-113.62811, 43.4011, 0],
                [-113.62798, 43.40167, 0],
                [-113.6278, 43.4024, 0],
                [-113.62768, 43.40281, 0],
                [-113.62738, 43.40372, 0],
                [-113.62729, 43.40393, 0],
                [-113.62717, 43.40418, 0],
                [-113.62709, 43.40432, 0],
                [-113.62699, 43.40448, 0],
                [-113.62689, 43.40462, 0],
                [-113.62672, 43.40489, 0],
                [-113.62646, 43.40525, 0],
                [-113.62613, 43.40575, 0],
                [-113.62608, 43.40584, 0],
                [-113.62605, 43.40591, 0],
                [-113.62603, 43.40595, 0],
                [-113.62602, 43.40598, 0],
                [-113.62599, 43.40605, 0],
                [-113.62592, 43.40627, 0],
                [-113.62589, 43.40642, 0],
                [-113.62587, 43.40649, 0],
                [-113.62586, 43.40655, 0],
                [-113.62584, 43.40664, 0],
                [-113.62584, 43.40668, 0],
                [-113.62583, 43.40685, 0],
                [-113.62582, 43.40694, 0],
                [-113.62582, 43.40709, 0],
                [-113.62583, 43.4072, 0],
                [-113.62584, 43.40726, 0],
                [-113.62585, 43.40738, 0],
                [-113.62588, 43.40751, 0],
                [-113.62591, 43.40768, 0],
                [-113.62595, 43.40784, 0],
                [-113.62606, 43.40815, 0],
                [-113.62624, 43.40848, 0],
                [-113.62636, 43.40865, 0],
                [-113.62644, 43.40875, 0],
                [-113.62658, 43.40894, 0],
                [-113.62675, 43.40914, 0],
                [-113.6273, 43.40971, 0],
                [-113.62742, 43.40985, 0],
                [-113.62754, 43.41, 0],
                [-113.62774, 43.41028, 0],
                [-113.62797, 43.41066, 0],
                [-113.62821, 43.41115, 0],
                [-113.62826, 43.41128, 0],
                [-113.6283, 43.41141, 0],
                [-113.62832, 43.41152, 0],
                [-113.62834, 43.4116, 0],
                [-113.62837, 43.41183, 0],
                [-113.62839, 43.41208, 0],
                [-113.62839, 43.41237, 0],
                [-113.62837, 43.41271, 0],
                [-113.62836, 43.41281, 0],
                [-113.62834, 43.41292, 0],
                [-113.62831, 43.41304, 0],
                [-113.62829, 43.41315, 0],
                [-113.62825, 43.41326, 0],
                [-113.62821, 43.41341, 0],
                [-113.62816, 43.41356, 0],
                [-113.62804, 43.41387, 0],
                [-113.62795, 43.41405, 0],
                [-113.62788, 43.41416, 0],
                [-113.62781, 43.41428, 0],
                [-113.62772, 43.41442, 0],
                [-113.62762, 43.41456, 0],
                [-113.62723, 43.41502, 0],
                [-113.62707, 43.41518, 0],
                [-113.62486, 43.41771, 0],
                [-113.62414, 43.41856, 0],
                [-113.62405, 43.41868, 0],
                [-113.62396, 43.41879, 0],
                [-113.6237, 43.41919, 0],
                [-113.6228, 43.42077, 0],
                [-113.62262, 43.4211, 0],
                [-113.62098, 43.42393, 0],
                [-113.61987, 43.4259, 0],
                [-113.61954, 43.42646, 0],
                [-113.61949, 43.42656, 0],
                [-113.61946, 43.42663, 0],
                [-113.61943, 43.42669, 0],
                [-113.61938, 43.42681, 0],
                [-113.61931, 43.427, 0],
                [-113.61928, 43.4271, 0],
                [-113.61921, 43.42737, 0],
                [-113.61915, 43.42766, 0],
                [-113.61914, 43.42776, 0],
                [-113.61912, 43.42787, 0],
                [-113.61912, 43.42794, 0],
                [-113.61911, 43.42801, 0],
                [-113.61911, 43.42846, 0],
                [-113.61912, 43.42853, 0],
                [-113.61912, 43.42861, 0],
                [-113.61913, 43.42872, 0],
                [-113.61916, 43.4289, 0],
                [-113.6192, 43.42908, 0],
                [-113.61929, 43.4294, 0],
                [-113.61937, 43.42961, 0],
                [-113.61938, 43.42965, 0],
                [-113.6194, 43.42969, 0],
                [-113.61943, 43.42976, 0],
                [-113.6195, 43.42989, 0],
                [-113.61969, 43.43027, 0],
                [-113.6201, 43.43103, 0],
                [-113.62029, 43.43152, 0],
                [-113.62034, 43.43167, 0],
                [-113.62042, 43.432, 0],
                [-113.62043, 43.43211, 0],
                [-113.62045, 43.43224, 0],
                [-113.62046, 43.43234, 0],
                [-113.62047, 43.43252, 0],
                [-113.62047, 43.43289, 0],
                [-113.62046, 43.43304, 0],
                [-113.62041, 43.43338, 0],
                [-113.62039, 43.43344, 0],
                [-113.62036, 43.43358, 0],
                [-113.62028, 43.43387, 0],
                [-113.62014, 43.43423, 0],
                [-113.61996, 43.43458, 0],
                [-113.61994, 43.4346, 0],
                [-113.61987, 43.43472, 0],
                [-113.61983, 43.4348, 0],
                [-113.61978, 43.43486, 0],
                [-113.61972, 43.43495, 0],
                [-113.61964, 43.43506, 0],
                [-113.6195, 43.43524, 0],
                [-113.61942, 43.43533, 0],
                [-113.61935, 43.4354, 0],
                [-113.61917, 43.4356, 0],
                [-113.61901, 43.43576, 0],
                [-113.61894, 43.43582, 0],
                [-113.61887, 43.43589, 0],
                [-113.6185, 43.43618, 0],
                [-113.61835, 43.43629, 0],
                [-113.61782, 43.43663, 0],
                [-113.61767, 43.43671, 0],
                [-113.61755, 43.43678, 0],
                [-113.61743, 43.43684, 0],
                [-113.61701, 43.43703, 0],
                [-113.61669, 43.43716, 0],
                [-113.61658, 43.4372, 0],
                [-113.61615, 43.43734, 0],
                [-113.61546, 43.43751, 0],
                [-113.61512, 43.43758, 0],
                [-113.61447, 43.43767, 0],
                [-113.61403, 43.43771, 0],
                [-113.60719, 43.43844, 0],
                [-113.60717, 43.43845, 0],
                [-113.60687, 43.43848, 0],
                [-113.60667, 43.43852, 0],
                [-113.60659, 43.43853, 0],
                [-113.60642, 43.43856, 0],
                [-113.60573, 43.43873, 0],
                [-113.60561, 43.43877, 0],
                [-113.60544, 43.43882, 0],
                [-113.60505, 43.43896, 0],
                [-113.60466, 43.43912, 0],
                [-113.60449, 43.43921, 0],
                [-113.60433, 43.43928, 0],
                [-113.60394, 43.43949, 0],
                [-113.60236, 43.44052, 0],
                [-113.60229, 43.44056, 0],
                [-113.59918, 43.44263, 0],
                [-113.5979, 43.44346, 0],
                [-113.59491, 43.44544, 0],
                [-113.59489, 43.44546, 0],
                [-113.59305, 43.44666, 0],
                [-113.59123, 43.44788, 0],
                [-113.59119, 43.4479, 0],
                [-113.59041, 43.44838, 0],
                [-113.58933, 43.44899, 0],
                [-113.58929, 43.449, 0],
                [-113.58909, 43.44911, 0],
                [-113.58847, 43.44941, 0],
                [-113.58805, 43.44959, 0],
                [-113.58799, 43.44962, 0],
                [-113.58793, 43.44964, 0],
                [-113.5877, 43.44974, 0],
                [-113.58728, 43.44991, 0],
                [-113.58696, 43.45002, 0],
                [-113.58692, 43.45004, 0],
                [-113.58672, 43.45011, 0],
                [-113.58644, 43.45022, 0],
                [-113.5849, 43.45072, 0],
                [-113.58473, 43.45078, 0],
                [-113.58447, 43.45086, 0],
                [-113.58386, 43.45107, 0],
                [-113.58349, 43.45118, 0],
                [-113.583, 43.45134, 0],
                [-113.58222, 43.45158, 0],
                [-113.58215, 43.45161, 0],
                [-113.58194, 43.45167, 0],
                [-113.58176, 43.45173, 0],
                [-113.58166, 43.45177, 0],
                [-113.58158, 43.45179, 0],
                [-113.58129, 43.45188, 0],
                [-113.58121, 43.45191, 0],
                [-113.58071, 43.45206, 0],
                [-113.58011, 43.45226, 0],
                [-113.57978, 43.45236, 0],
                [-113.57903, 43.45262, 0],
                [-113.57885, 43.45269, 0],
                [-113.57873, 43.45273, 0],
                [-113.5777, 43.45318, 0],
                [-113.57755, 43.45326, 0],
                [-113.57746, 43.4533, 0],
                [-113.57669, 43.45373, 0],
                [-113.57588, 43.45422, 0],
                [-113.57587, 43.45423, 0],
                [-113.57577, 43.4543, 0],
                [-113.57547, 43.45453, 0],
                [-113.57533, 43.45463, 0],
                [-113.57488, 43.45503, 0],
                [-113.57488, 43.45504, 0],
                [-113.57485, 43.45506, 0],
                [-113.57483, 43.45508, 0],
                [-113.57469, 43.45518, 0],
                [-113.57464, 43.45522, 0],
                [-113.57457, 43.45529, 0],
                [-113.57445, 43.4554, 0],
                [-113.5744, 43.45546, 0],
                [-113.57435, 43.45551, 0],
                [-113.57426, 43.45562, 0],
                [-113.57415, 43.45573, 0],
                [-113.57414, 43.45575, 0],
                [-113.57407, 43.45581, 0],
                [-113.574, 43.45588, 0],
                [-113.57388, 43.45601, 0],
                [-113.57351, 43.45646, 0],
                [-113.57347, 43.4565, 0],
                [-113.57342, 43.45656, 0],
                [-113.57335, 43.45662, 0],
                [-113.57326, 43.45672, 0],
                [-113.57311, 43.45687, 0],
                [-113.57306, 43.45691, 0],
                [-113.57248, 43.45747, 0],
                [-113.57242, 43.45752, 0],
                [-113.57224, 43.4577, 0],
                [-113.5722, 43.45773, 0],
                [-113.57214, 43.45779, 0],
                [-113.57208, 43.45783, 0],
                [-113.57195, 43.45795, 0],
                [-113.57171, 43.45813, 0],
                [-113.57161, 43.45821, 0],
                [-113.57145, 43.45832, 0],
                [-113.5714, 43.45837, 0],
                [-113.57131, 43.45844, 0],
                [-113.57129, 43.45846, 0],
                [-113.57121, 43.45852, 0],
                [-113.57111, 43.45858, 0],
                [-113.57085, 43.45876, 0],
                [-113.57067, 43.45886, 0],
                [-113.57034, 43.45908, 0],
                [-113.56994, 43.4593, 0],
                [-113.56986, 43.45933, 0],
                [-113.56949, 43.45952, 0],
                [-113.5689, 43.45978, 0],
                [-113.56828, 43.46002, 0],
                [-113.56826, 43.46003, 0],
                [-113.56817, 43.46006, 0],
                [-113.56807, 43.4601, 0],
                [-113.56728, 43.46038, 0],
                [-113.56671, 43.46056, 0],
                [-113.56668, 43.46056, 0],
                [-113.56535, 43.46099, 0],
                [-113.56481, 43.4612, 0],
                [-113.56411, 43.4615, 0],
                [-113.56398, 43.46157, 0],
                [-113.56322, 43.46194, 0],
                [-113.56299, 43.46206, 0],
                [-113.56282, 43.46214, 0],
                [-113.56241, 43.46236, 0],
                [-113.56195, 43.46262, 0],
                [-113.56155, 43.46286, 0],
                [-113.56023, 43.46368, 0],
                [-113.56021, 43.46372, 0],
                [-113.55907, 43.46441, 0],
                [-113.55893, 43.46449, 0],
                [-113.55827, 43.46489, 0],
                [-113.55784, 43.46514, 0],
                [-113.55681, 43.46571, 0],
                [-113.55658, 43.46583, 0],
                [-113.55584, 43.46619, 0],
                [-113.55519, 43.46649, 0],
                [-113.55508, 43.46653, 0],
                [-113.55497, 43.46658, 0],
                [-113.55428, 43.46686, 0],
                [-113.55421, 43.46688, 0],
                [-113.55356, 43.46714, 0],
                [-113.55202, 43.46773, 0],
                [-113.55178, 43.46783, 0],
                [-113.55086, 43.46825, 0],
                [-113.55063, 43.46837, 0],
                [-113.55009, 43.46867, 0],
                [-113.54997, 43.46873, 0],
                [-113.54955, 43.46898, 0],
                [-113.54928, 43.46916, 0],
                [-113.54905, 43.4693, 0],
                [-113.5485, 43.46969, 0],
                [-113.54849, 43.4697, 0],
                [-113.54833, 43.46981, 0],
                [-113.54798, 43.47008, 0],
                [-113.54756, 43.47043, 0],
                [-113.54718, 43.47077, 0],
                [-113.54682, 43.47111, 0],
                [-113.54663, 43.4713, 0],
                [-113.54631, 43.47164, 0],
                [-113.54628, 43.47168, 0],
                [-113.54596, 43.47206, 0],
                [-113.54592, 43.4721, 0],
                [-113.5458, 43.47226, 0],
                [-113.54578, 43.47228, 0],
                [-113.54558, 43.47254, 0],
                [-113.54539, 43.4728, 0],
                [-113.54497, 43.47347, 0],
                [-113.54485, 43.47368, 0],
                [-113.54456, 43.47423, 0],
                [-113.54435, 43.47469, 0],
                [-113.54421, 43.47504, 0],
                [-113.54404, 43.47552, 0],
                [-113.54383, 43.47615, 0],
                [-113.54368, 43.47656, 0],
                [-113.54331, 43.47765, 0],
                [-113.54329, 43.4777, 0],
                [-113.54224, 43.48077, 0],
                [-113.54207, 43.48124, 0],
                [-113.54117, 43.48385, 0],
                [-113.54106, 43.48411, 0],
                [-113.54096, 43.48437, 0],
                [-113.54072, 43.48489, 0],
                [-113.54045, 43.48544, 0],
                [-113.54018, 43.48591, 0],
                [-113.53968, 43.48669, 0],
                [-113.53942, 43.48705, 0],
                [-113.53899, 43.48762, 0],
                [-113.53781, 43.48905, 0],
                [-113.53771, 43.48918, 0],
                [-113.53105, 43.49724, 0],
                [-113.53077, 43.49759, 0],
                [-113.52998, 43.49853, 0],
                [-113.52946, 43.49909, 0],
                [-113.5294, 43.49916, 0],
                [-113.52716, 43.50152, 0],
                [-113.52716, 43.50153, 0],
                [-113.52687, 43.50182, 0],
                [-113.52456, 43.50428, 0],
                [-113.52413, 43.50475, 0],
                [-113.52393, 43.50495, 0],
                [-113.52372, 43.50518, 0],
                [-113.52243, 43.50654, 0],
                [-113.52199, 43.50702, 0],
                [-113.51469, 43.51478, 0],
                [-113.51042, 43.51926, 0],
                [-113.5063, 43.52365, 0],
                [-113.50442, 43.52568, 0],
                [-113.5042, 43.5259, 0],
                [-113.50368, 43.5265, 0],
                [-113.50353, 43.52669, 0],
                [-113.50325, 43.52702, 0],
                [-113.50202, 43.52855, 0],
                [-113.50197, 43.5286, 0],
                [-113.50159, 43.52908, 0],
                [-113.50123, 43.52951, 0],
                [-113.49941, 43.53182, 0],
                [-113.49931, 43.53193, 0],
                [-113.49803, 43.53352, 0],
                [-113.49797, 43.5336, 0],
                [-113.49779, 43.53382, 0],
                [-113.49757, 43.53411, 0],
                [-113.49728, 43.53446, 0],
                [-113.49679, 43.53503, 0],
                [-113.49661, 43.53525, 0],
                [-113.49649, 43.53538, 0],
                [-113.496, 43.53596, 0],
                [-113.49597, 43.53599, 0],
                [-113.49579, 43.53619, 0],
                [-113.49559, 43.53643, 0],
                [-113.49499, 43.53712, 0],
                [-113.49479, 43.53736, 0],
                [-113.49193, 43.54066, 0],
                [-113.49019, 43.5427, 0],
                [-113.48997, 43.54294, 0],
                [-113.48983, 43.54311, 0],
                [-113.48979, 43.54315, 0],
                [-113.48947, 43.54355, 0],
                [-113.4884, 43.54482, 0],
                [-113.48804, 43.54526, 0],
                [-113.48798, 43.54534, 0],
                [-113.48752, 43.54588, 0],
                [-113.4871, 43.54639, 0],
                [-113.48695, 43.54656, 0],
                [-113.48672, 43.54685, 0],
                [-113.47902, 43.55611, 0],
                [-113.47883, 43.55633, 0],
                [-113.47868, 43.55652, 0],
                [-113.47849, 43.55674, 0],
                [-113.478, 43.55726, 0],
                [-113.47775, 43.55751, 0],
                [-113.47756, 43.55769, 0],
                [-113.47734, 43.55791, 0],
                [-113.47671, 43.55846, 0],
                [-113.47648, 43.55865, 0],
                [-113.47568, 43.55927, 0],
                [-113.47548, 43.55941, 0],
                [-113.47538, 43.55949, 0],
                [-113.47522, 43.55959, 0],
                [-113.47505, 43.55971, 0],
                [-113.4747, 43.55994, 0],
                [-113.47427, 43.5602, 0],
                [-113.47411, 43.56031, 0],
                [-113.46967, 43.56302, 0],
                [-113.4692, 43.56332, 0],
                [-113.46891, 43.56349, 0],
                [-113.4655, 43.56558, 0],
                [-113.46525, 43.56574, 0],
                [-113.46504, 43.56586, 0],
                [-113.46479, 43.56602, 0],
                [-113.46443, 43.56623, 0],
                [-113.46321, 43.56698, 0],
                [-113.46317, 43.567, 0],
                [-113.4629, 43.56717, 0],
                [-113.46271, 43.56728, 0],
                [-113.45464, 43.57222, 0],
                [-113.45463, 43.57223, 0],
                [-113.45424, 43.57247, 0],
                [-113.45406, 43.57257, 0],
                [-113.45397, 43.57263, 0],
                [-113.45362, 43.57284, 0],
                [-113.45328, 43.57306, 0],
                [-113.45311, 43.57315, 0],
                [-113.4531, 43.57316, 0],
                [-113.45265, 43.57343, 0],
                [-113.45253, 43.57349, 0],
                [-113.45237, 43.57359, 0],
                [-113.45174, 43.57392, 0],
                [-113.45165, 43.57396, 0],
                [-113.45105, 43.57426, 0],
                [-113.45091, 43.57432, 0],
                [-113.4506, 43.57447, 0],
                [-113.45022, 43.57464, 0],
                [-113.44985, 43.57479, 0],
                [-113.44982, 43.5748, 0],
                [-113.44919, 43.57505, 0],
                [-113.44886, 43.57517, 0],
                [-113.44768, 43.57556, 0],
                [-113.44683, 43.5758, 0],
                [-113.43668, 43.57848, 0],
                [-113.43596, 43.57866, 0],
                [-113.43269, 43.57952, 0],
                [-113.43267, 43.57953, 0],
                [-113.42594, 43.5813, 0],
                [-113.42592, 43.5813, 0],
                [-113.42558, 43.58139, 0],
                [-113.42541, 43.58144, 0],
                [-113.42518, 43.5815, 0],
                [-113.42488, 43.58157, 0],
                [-113.42465, 43.58163, 0],
                [-113.42434, 43.58172, 0],
                [-113.42331, 43.58198, 0],
                [-113.42205, 43.58232, 0],
                [-113.42088, 43.58262, 0],
                [-113.42079, 43.58265, 0],
                [-113.42053, 43.58271, 0],
                [-113.41043, 43.58535, 0],
                [-113.40902, 43.58574, 0],
                [-113.40758, 43.5861, 0],
                [-113.40186, 43.58762, 0],
                [-113.40019, 43.58803, 0],
                [-113.39892, 43.58838, 0],
                [-113.39588, 43.58916, 0],
                [-113.39408, 43.58965, 0],
                [-113.3923, 43.59011, 0],
                [-113.39222, 43.59014, 0],
                [-113.37662, 43.59422, 0],
                [-113.37345, 43.59508, 0],
                [-113.37342, 43.59509, 0],
                [-113.37333, 43.59511, 0],
                [-113.34876, 43.60153, 0],
                [-113.3479, 43.60174, 0],
                [-113.34164, 43.60339, 0],
                [-113.34162, 43.60339, 0],
                [-113.32728, 43.60715, 0],
                [-113.32668, 43.60732, 0],
                [-113.32572, 43.60763, 0],
                [-113.3255, 43.60771, 0],
                [-113.32529, 43.60778, 0],
                [-113.32444, 43.60811, 0],
                [-113.3236, 43.60847, 0],
                [-113.32293, 43.60878, 0],
                [-113.32269, 43.6089, 0],
                [-113.32251, 43.609, 0],
                [-113.31976, 43.61041, 0],
                [-113.31959, 43.61049, 0],
                [-113.31956, 43.61051, 0],
                [-113.3194, 43.61059, 0],
                [-113.31936, 43.61062, 0],
                [-113.31678, 43.61196, 0],
                [-113.31569, 43.6125, 0],
                [-113.31251, 43.61413, 0],
                [-113.31104, 43.61491, 0],
                [-113.30928, 43.61581, 0],
                [-113.30861, 43.61617, 0],
                [-113.30826, 43.61637, 0],
                [-113.30797, 43.61655, 0],
                [-113.30773, 43.61671, 0],
                [-113.30749, 43.61689, 0],
                [-113.30731, 43.61706, 0],
                [-113.30705, 43.61734, 0],
                [-113.30692, 43.6175, 0],
                [-113.30679, 43.61767, 0],
                [-113.30667, 43.61784, 0],
                [-113.30658, 43.61798, 0],
                [-113.30645, 43.61823, 0],
                [-113.30639, 43.61837, 0],
                [-113.30635, 43.6185, 0],
                [-113.3063, 43.61862, 0],
                [-113.30627, 43.61876, 0],
                [-113.30621, 43.61914, 0],
                [-113.30619, 43.61941, 0],
                [-113.30614, 43.6256, 0],
                [-113.30614, 43.62942, 0],
                [-113.30615, 43.63024, 0],
                [-113.30613, 43.63168, 0],
                [-113.30612, 43.63185, 0],
                [-113.3061, 43.63204, 0],
                [-113.30608, 43.63214, 0],
                [-113.30599, 43.63246, 0],
                [-113.30596, 43.63253, 0],
                [-113.30593, 43.63259, 0],
                [-113.3059, 43.63264, 0],
                [-113.30587, 43.6327, 0],
                [-113.30584, 43.63274, 0],
                [-113.30581, 43.63279, 0],
                [-113.30574, 43.63288, 0],
                [-113.30561, 43.63301, 0],
                [-113.30543, 43.63315, 0],
                [-113.3018, 43.63547, 0],
                [-113.30143, 43.63572, 0],
                [-113.30108, 43.63594, 0],
                [-113.30057, 43.63628, 0],
                [-113.30046, 43.63634, 0],
                [-113.30006, 43.63661, 0],
                [-113.30059, 43.63703, 0],
                [-113.30107, 43.63746, 0],
                [-113.30176, 43.63803, 0],
                [-113.30258, 43.63868, 0],
                [-113.3028, 43.63887, 0],
                [-113.30634, 43.64179, 0],
                [-113.30638, 43.64182, 0],
                [-113.3065, 43.64193, 0],
                [-113.30667, 43.64206, 0],
                [-113.31032, 43.64507, 0],
                [-113.3106, 43.64531, 0],
                [-113.31132, 43.64589, 0],
                [-113.3121, 43.64654, 0],
                [-113.31319, 43.64742, 0],
                [-113.3142, 43.64828, 0],
                [-113.31434, 43.64841, 0],
                [-113.31447, 43.64852, 0],
                [-113.31493, 43.64895, 0],
                [-113.31548, 43.64952, 0],
                [-113.31552, 43.64957, 0],
                [-113.31571, 43.64976, 0],
                [-113.31589, 43.64996, 0],
                [-113.31607, 43.65018, 0],
                [-113.31617, 43.65029, 0],
                [-113.31651, 43.65069, 0],
                [-113.31658, 43.65079, 0],
                [-113.31668, 43.65092, 0],
                [-113.31678, 43.65106, 0],
                [-113.31695, 43.65128, 0],
                [-113.31729, 43.65179, 0],
                [-113.31782, 43.65268, 0],
                [-113.32527, 43.66548, 0],
                [-113.32533, 43.66559, 0],
                [-113.32629, 43.66723, 0],
                [-113.32648, 43.66757, 0],
                [-113.33892, 43.68894, 0],
                [-113.33935, 43.68965, 0],
                [-113.34363, 43.69697, 0],
                [-113.34483, 43.69906, 0],
                [-113.3449, 43.69919, 0],
                [-113.34509, 43.69949, 0],
                [-113.34727, 43.7032, 0],
                [-113.34801, 43.7045, 0],
                [-113.3483, 43.70498, 0],
                [-113.36235, 43.72907, 0],
                [-113.3633, 43.73065, 0],
                [-113.36452, 43.73277, 0],
                [-113.36455, 43.73283, 0],
                [-113.36458, 43.73287, 0],
                [-113.3646, 43.73291, 0],
                [-113.36518, 43.73389, 0],
                [-113.36519, 43.73391, 0],
                [-113.36561, 43.73461, 0],
                [-113.36563, 43.73466, 0],
                [-113.36863, 43.73979, 0],
                [-113.36962, 43.7416, 0],
                [-113.38836, 43.77367, 0],
                [-113.38852, 43.77393, 0],
                [-113.38882, 43.77447, 0],
                [-113.38888, 43.77455, 0],
                [-113.389, 43.77474, 0],
                [-113.38905, 43.77484, 0],
                [-113.38917, 43.77503, 0],
                [-113.3893, 43.77522, 0],
                [-113.38942, 43.77538, 0],
                [-113.39013, 43.77638, 0],
                [-113.39044, 43.77686, 0],
                [-113.39062, 43.77716, 0],
                [-113.39066, 43.77724, 0],
                [-113.39811, 43.78999, 0],
                [-113.39866, 43.79089, 0],
                [-113.39902, 43.79144, 0],
                [-113.3994, 43.792, 0],
                [-113.3995, 43.79213, 0],
                [-113.39976, 43.79249, 0],
                [-113.39992, 43.79273, 0],
                [-113.40088, 43.79407, 0],
                [-113.40122, 43.79457, 0],
                [-113.40388, 43.79835, 0],
                [-113.40429, 43.79896, 0],
                [-113.40452, 43.79935, 0],
                [-113.40472, 43.79972, 0],
                [-113.40493, 43.80007, 0],
                [-113.40526, 43.80059, 0],
                [-113.40596, 43.8016, 0],
                [-113.40722, 43.80337, 0],
                [-113.40869, 43.80548, 0],
                [-113.40916, 43.80613, 0],
                [-113.41109, 43.80888, 0],
                [-113.41156, 43.80953, 0],
                [-113.41216, 43.81039, 0],
                [-113.41242, 43.81074, 0],
                [-113.41293, 43.81146, 0],
                [-113.41337, 43.81211, 0],
                [-113.41382, 43.81273, 0],
                [-113.41577, 43.81552, 0],
                [-113.41591, 43.8157, 0],
                [-113.41729, 43.81766, 0],
                [-113.41749, 43.81796, 0],
                [-113.41828, 43.81906, 0],
                [-113.41865, 43.81954, 0],
                [-113.41883, 43.81976, 0],
                [-113.41893, 43.81989, 0],
                [-113.41903, 43.82, 0],
                [-113.4191, 43.82007, 0],
                [-113.41925, 43.82025, 0],
                [-113.41935, 43.82038, 0],
                [-113.41943, 43.82047, 0],
                [-113.41973, 43.82083, 0],
                [-113.42604, 43.82974, 0],
                [-113.42612, 43.82986, 0],
                [-113.4262, 43.82999, 0],
                [-113.42629, 43.8301, 0],
                [-113.42639, 43.83025, 0],
                [-113.42947, 43.83461, 0],
                [-113.42948, 43.83463, 0],
                [-113.42974, 43.835, 0],
                [-113.42991, 43.83523, 0],
                [-113.43076, 43.83645, 0],
                [-113.43094, 43.83669, 0],
                [-113.43811, 43.84686, 0],
                [-113.43961, 43.84895, 0],
                [-113.44307, 43.85386, 0],
                [-113.44359, 43.85452, 0],
                [-113.44397, 43.85496, 0],
                [-113.44448, 43.8555, 0],
                [-113.44489, 43.85591, 0],
                [-113.44569, 43.85666, 0],
                [-113.44585, 43.8568, 0],
                [-113.44644, 43.85735, 0],
                [-113.44664, 43.85751, 0],
                [-113.44685, 43.85769, 0],
                [-113.44696, 43.85777, 0],
                [-113.44704, 43.85784, 0],
                [-113.44724, 43.85799, 0],
                [-113.44776, 43.85836, 0],
                [-113.44816, 43.85862, 0],
                [-113.44898, 43.85909, 0],
                [-113.45006, 43.85964, 0],
                [-113.45049, 43.85984, 0],
                [-113.45068, 43.85992, 0],
                [-113.4508, 43.85998, 0],
                [-113.45096, 43.86005, 0],
                [-113.45112, 43.86011, 0],
                [-113.45133, 43.8602, 0],
                [-113.45174, 43.86036, 0],
                [-113.45193, 43.86044, 0],
                [-113.45205, 43.86048, 0],
                [-113.45221, 43.86054, 0],
                [-113.45239, 43.8606, 0],
                [-113.45258, 43.86067, 0],
                [-113.46197, 43.86382, 0],
                [-113.4626, 43.86404, 0],
                [-113.48943, 43.87306, 0],
                [-113.4896, 43.87311, 0],
                [-113.49028, 43.87335, 0],
                [-113.49115, 43.87364, 0],
                [-113.49132, 43.87369, 0],
                [-113.4915, 43.87376, 0],
                [-113.49752, 43.87577, 0],
                [-113.49785, 43.87589, 0],
                [-113.49889, 43.87623, 0],
                [-113.49923, 43.87635, 0],
                [-113.4994, 43.8764, 0],
                [-113.50233, 43.87738, 0],
                [-113.5025, 43.87743, 0],
                [-113.50336, 43.87773, 0],
                [-113.50344, 43.87775, 0],
                [-113.5049, 43.87825, 0],
                [-113.50594, 43.87859, 0],
                [-113.5061, 43.87865, 0],
                [-113.50645, 43.87877, 0],
                [-113.50662, 43.87882, 0],
                [-113.50679, 43.87888, 0],
                [-113.50698, 43.87894, 0],
                [-113.50728, 43.87905, 0],
                [-113.50731, 43.87905, 0],
                [-113.50749, 43.87912, 0],
                [-113.50766, 43.87917, 0],
                [-113.50886, 43.87958, 0],
                [-113.50922, 43.87969, 0],
                [-113.50955, 43.87981, 0],
                [-113.50973, 43.87986, 0],
                [-113.51042, 43.8801, 0],
                [-113.51059, 43.88015, 0],
                [-113.51179, 43.88056, 0],
                [-113.51181, 43.88056, 0],
                [-113.51729, 43.8824, 0],
                [-113.5178, 43.88258, 0],
                [-113.51833, 43.88275, 0],
                [-113.52925, 43.88642, 0],
                [-113.52947, 43.8865, 0],
                [-113.53019, 43.88674, 0],
                [-113.53036, 43.88679, 0],
                [-113.53055, 43.88686, 0],
                [-113.53072, 43.88691, 0],
                [-113.53105, 43.88702, 0],
                [-113.53106, 43.88703, 0],
                [-113.53426, 43.8881, 0],
                [-113.53449, 43.88817, 0],
                [-113.53775, 43.88927, 0],
                [-113.53792, 43.88932, 0],
                [-113.53914, 43.88974, 0],
                [-113.53931, 43.88979, 0],
                [-113.53982, 43.88997, 0],
                [-113.54, 43.89002, 0],
                [-113.54069, 43.89026, 0],
                [-113.54103, 43.89037, 0],
                [-113.54119, 43.89043, 0],
                [-113.54137, 43.89048, 0],
                [-113.54189, 43.89066, 0],
                [-113.54223, 43.89077, 0],
                [-113.54257, 43.89089, 0],
                [-113.54274, 43.89094, 0],
                [-113.54309, 43.89106, 0],
                [-113.54343, 43.89116, 0],
                [-113.54362, 43.89121, 0],
                [-113.54416, 43.89133, 0],
                [-113.54435, 43.89136, 0],
                [-113.54443, 43.89138, 0],
                [-113.54453, 43.89139, 0],
                [-113.5447, 43.89142, 0],
                [-113.54483, 43.89143, 0],
                [-113.54489, 43.89144, 0],
                [-113.54527, 43.89148, 0],
                [-113.54546, 43.89149, 0],
                [-113.54557, 43.8915, 0],
                [-113.54565, 43.8915, 0],
                [-113.54584, 43.89151, 0],
                [-113.54755, 43.89151, 0],
                [-113.54774, 43.8915, 0],
                [-113.55021, 43.8915, 0],
                [-113.55058, 43.89149, 0],
                [-113.55647, 43.89149, 0],
                [-113.55684, 43.8915, 0],
                [-113.5578, 43.8915, 0],
                [-113.5595, 43.89152, 0],
                [-113.55969, 43.89153, 0],
                [-113.56044, 43.89153, 0],
                [-113.56081, 43.89154, 0],
                [-113.5614, 43.89154, 0],
                [-113.56176, 43.89155, 0],
                [-113.56269, 43.89155, 0],
                [-113.56298, 43.89156, 0],
                [-113.56366, 43.89156, 0],
                [-113.56387, 43.89157, 0],
                [-113.56422, 43.89157, 0],
                [-113.56463, 43.89159, 0],
                [-113.5648, 43.89159, 0],
                [-113.56516, 43.89161, 0],
                [-113.56533, 43.89161, 0],
                [-113.56555, 43.89162, 0],
                [-113.56688, 43.89172, 0],
                [-113.56705, 43.89175, 0],
                [-113.56739, 43.89179, 0],
                [-113.56758, 43.89182, 0],
                [-113.56816, 43.89194, 0],
                [-113.56873, 43.89209, 0],
                [-113.56912, 43.89221, 0],
                [-113.5722, 43.89325, 0],
                [-113.57242, 43.89333, 0],
                [-113.57264, 43.8934, 0],
                [-113.57424, 43.89395, 0],
                [-113.5751, 43.89423, 0],
                [-113.57526, 43.89429, 0],
                [-113.5753, 43.8943, 0],
                [-113.57756, 43.89504, 0],
                [-113.57956, 43.89567, 0],
                [-113.57981, 43.89574, 0],
                [-113.58051, 43.89596, 0],
                [-113.58094, 43.89611, 0],
                [-113.58105, 43.89616, 0],
                [-113.58117, 43.8962, 0],
                [-113.58123, 43.89623, 0],
                [-113.58149, 43.89634, 0],
                [-113.58162, 43.8964, 0],
                [-113.58171, 43.89645, 0],
                [-113.58188, 43.89653, 0],
                [-113.58217, 43.89668, 0],
                [-113.58229, 43.89675, 0],
                [-113.58261, 43.89692, 0],
                [-113.58287, 43.89707, 0],
                [-113.58298, 43.89712, 0],
                [-113.5834, 43.89736, 0],
                [-113.58352, 43.89742, 0],
                [-113.58365, 43.8975, 0],
                [-113.58406, 43.89773, 0],
                [-113.58418, 43.89779, 0],
                [-113.58444, 43.89793, 0],
                [-113.58456, 43.898, 0],
                [-113.58518, 43.89832, 0],
                [-113.58544, 43.89843, 0],
                [-113.58575, 43.89855, 0],
                [-113.58596, 43.89864, 0],
                [-113.58666, 43.8989, 0],
                [-113.58706, 43.89906, 0],
                [-113.5872, 43.89911, 0],
                [-113.58721, 43.89911, 0],
                [-113.59104, 43.90056, 0],
                [-113.59124, 43.90063, 0],
                [-113.59126, 43.90064, 0],
                [-113.59166, 43.90079, 0],
                [-113.5917, 43.90081, 0],
                [-113.59204, 43.90093, 0],
                [-113.59298, 43.90129, 0],
                [-113.59329, 43.9014, 0],
                [-113.59349, 43.90149, 0],
                [-113.59364, 43.90154, 0],
                [-113.59388, 43.90164, 0],
                [-113.59408, 43.90173, 0],
                [-113.59429, 43.90184, 0],
                [-113.59432, 43.90185, 0],
                [-113.59455, 43.90197, 0],
                [-113.59504, 43.90227, 0],
                [-113.59515, 43.90235, 0],
                [-113.5953, 43.90245, 0],
                [-113.59642, 43.90332, 0],
                [-113.5966, 43.90347, 0],
                [-113.59676, 43.90359, 0],
                [-113.59744, 43.90414, 0],
                [-113.59845, 43.90492, 0],
                [-113.59899, 43.90532, 0],
                [-113.59926, 43.9055, 0],
                [-113.60179, 43.90727, 0],
                [-113.60216, 43.90754, 0],
                [-113.60381, 43.90868, 0],
                [-113.60416, 43.90893, 0],
                [-113.60444, 43.90912, 0],
                [-113.60567, 43.90999, 0],
                [-113.60581, 43.91008, 0],
                [-113.611, 43.91369, 0],
                [-113.61101, 43.91369, 0],
                [-113.6125, 43.91474, 0],
                [-113.61425, 43.91592, 0],
                [-113.61447, 43.91609, 0],
                [-113.61554, 43.91682, 0],
                [-113.61581, 43.91702, 0],
                [-113.61606, 43.91718, 0],
                [-113.61658, 43.91755, 0],
                [-113.61663, 43.91758, 0],
                [-113.61679, 43.91769, 0],
                [-113.61694, 43.9178, 0],
                [-113.61708, 43.91789, 0],
                [-113.6174, 43.91812, 0],
                [-113.61757, 43.91822, 0],
                [-113.61782, 43.91839, 0],
                [-113.61802, 43.91854, 0],
                [-113.61817, 43.91864, 0],
                [-113.61832, 43.91875, 0],
                [-113.61864, 43.91896, 0],
                [-113.61931, 43.91943, 0],
                [-113.61989, 43.91982, 0],
                [-113.62004, 43.91993, 0],
                [-113.62093, 43.92054, 0],
                [-113.62121, 43.92074, 0],
                [-113.62138, 43.92085, 0],
                [-113.62148, 43.92092, 0],
                [-113.62162, 43.92101, 0],
                [-113.62216, 43.92139, 0],
                [-113.62221, 43.92142, 0],
                [-113.6223, 43.92149, 0],
                [-113.62243, 43.92158, 0],
                [-113.62257, 43.92167, 0],
                [-113.62284, 43.92186, 0],
                [-113.62298, 43.92195, 0],
                [-113.62313, 43.92206, 0],
                [-113.62342, 43.92225, 0],
                [-113.62775, 43.92524, 0],
                [-113.62789, 43.92533, 0],
                [-113.62871, 43.9259, 0],
                [-113.62885, 43.92599, 0],
                [-113.62898, 43.92609, 0],
                [-113.62953, 43.92647, 0],
                [-113.62966, 43.92657, 0],
                [-113.62978, 43.92667, 0],
                [-113.62979, 43.92667, 0],
                [-113.62996, 43.9268, 0],
                [-113.63902, 43.93424, 0],
                [-113.63914, 43.93433, 0],
                [-113.64325, 43.93772, 0],
                [-113.64353, 43.93793, 0],
                [-113.64375, 43.93811, 0],
                [-113.64428, 43.9385, 0],
                [-113.64637, 43.93996, 0],
                [-113.65535, 43.94614, 0],
                [-113.65573, 43.94641, 0],
                [-113.65587, 43.9465, 0],
                [-113.656, 43.94659, 0],
                [-113.65611, 43.94668, 0],
                [-113.6564, 43.94689, 0],
                [-113.65942, 43.94921, 0],
                [-113.6598, 43.94949, 0],
                [-113.65986, 43.94954, 0],
                [-113.66158, 43.95083, 0],
                [-113.66235, 43.95143, 0],
                [-113.66264, 43.95164, 0],
                [-113.66303, 43.95194, 0],
                [-113.66309, 43.95199, 0],
                [-113.66388, 43.9526, 0],
                [-113.66389, 43.9526, 0],
                [-113.66545, 43.95378, 0],
                [-113.66569, 43.95397, 0],
                [-113.66597, 43.95418, 0],
                [-113.66599, 43.95419, 0],
                [-113.66611, 43.95429, 0],
                [-113.66616, 43.95432, 0],
                [-113.66689, 43.95488, 0],
                [-113.66702, 43.95497, 0],
                [-113.66728, 43.95518, 0],
                [-113.66741, 43.95527, 0],
                [-113.66775, 43.95554, 0],
                [-113.66781, 43.95558, 0],
                [-113.6684, 43.95603, 0],
                [-113.66856, 43.95616, 0],
                [-113.66937, 43.95678, 0],
                [-113.66989, 43.95716, 0],
                [-113.67005, 43.95727, 0],
                [-113.67018, 43.95735, 0],
                [-113.67057, 43.95756, 0],
                [-113.67085, 43.9577, 0],
                [-113.67094, 43.95774, 0],
                [-113.67103, 43.95779, 0],
                [-113.67118, 43.95786, 0],
                [-113.67145, 43.958, 0],
                [-113.6717, 43.95812, 0],
                [-113.67217, 43.95836, 0],
                [-113.67222, 43.95838, 0],
                [-113.67243, 43.95849, 0],
                [-113.67278, 43.95866, 0],
                [-113.67315, 43.95883, 0],
                [-113.67492, 43.95971, 0],
                [-113.67518, 43.95983, 0],
                [-113.67866, 43.96157, 0],
                [-113.67908, 43.96177, 0],
                [-113.67963, 43.96205, 0],
                [-113.67966, 43.96207, 0],
                [-113.67982, 43.96214, 0],
                [-113.6802, 43.96233, 0],
                [-113.6803, 43.96239, 0],
                [-113.68041, 43.96245, 0],
                [-113.68048, 43.96248, 0],
                [-113.68053, 43.96251, 0],
                [-113.6806, 43.96254, 0],
                [-113.68068, 43.96258, 0],
                [-113.68086, 43.96266, 0],
                [-113.6809, 43.96267, 0],
                [-113.68095, 43.9627, 0],
                [-113.68107, 43.96274, 0],
                [-113.6812, 43.9628, 0],
                [-113.68133, 43.96284, 0],
                [-113.68153, 43.96292, 0],
                [-113.68174, 43.96299, 0],
                [-113.68179, 43.963, 0],
                [-113.68196, 43.96306, 0],
                [-113.68216, 43.96312, 0],
                [-113.68245, 43.96319, 0],
                [-113.68288, 43.96328, 0],
                [-113.68377, 43.9634, 0],
                [-113.68444, 43.96343, 0],
                [-113.68483, 43.96344, 0],
                [-113.68522, 43.96344, 0],
                [-113.68745, 43.96348, 0],
                [-113.68784, 43.96348, 0],
                [-113.68819, 43.96349, 0],
                [-113.68859, 43.96349, 0],
                [-113.6902, 43.96352, 0],
                [-113.69107, 43.96355, 0],
                [-113.69131, 43.96357, 0],
                [-113.69212, 43.96366, 0],
                [-113.69283, 43.96376, 0],
                [-113.69303, 43.96378, 0],
                [-113.69359, 43.96386, 0],
                [-113.69393, 43.9639, 0],
                [-113.69429, 43.96396, 0],
                [-113.69449, 43.96398, 0],
                [-113.69476, 43.96402, 0],
                [-113.6956, 43.96412, 0],
                [-113.69642, 43.96425, 0],
                [-113.69667, 43.9643, 0],
                [-113.69704, 43.96439, 0],
                [-113.69731, 43.96447, 0],
                [-113.69736, 43.96448, 0],
                [-113.69812, 43.96474, 0],
                [-113.69826, 43.9648, 0],
                [-113.69829, 43.96481, 0],
                [-113.69878, 43.96504, 0],
                [-113.69908, 43.9652, 0],
                [-113.69909, 43.9652, 0],
                [-113.69945, 43.9654, 0],
                [-113.70021, 43.96585, 0],
                [-113.70026, 43.96587, 0],
                [-113.70029, 43.9659, 0],
                [-113.70085, 43.96621, 0],
                [-113.70107, 43.96634, 0],
                [-113.7018, 43.96674, 0],
                [-113.70184, 43.96675, 0],
                [-113.70203, 43.96687, 0],
                [-113.70208, 43.96689, 0],
                [-113.70322, 43.96752, 0],
                [-113.70335, 43.9676, 0],
                [-113.70391, 43.96792, 0],
                [-113.70481, 43.96841, 0],
                [-113.70482, 43.96842, 0],
                [-113.70544, 43.96872, 0],
                [-113.70589, 43.9689, 0],
                [-113.70609, 43.96899, 0],
                [-113.70689, 43.96929, 0],
                [-113.70759, 43.96958, 0],
                [-113.7085, 43.96994, 0],
                [-113.70851, 43.96995, 0],
                [-113.70979, 43.97047, 0],
                [-113.7098, 43.97048, 0],
                [-113.71113, 43.97098, 0],
                [-113.71573, 43.97281, 0],
                [-113.71594, 43.9729, 0],
                [-113.71687, 43.97326, 0],
                [-113.71706, 43.97334, 0],
                [-113.71748, 43.97349, 0],
                [-113.71789, 43.97365, 0],
                [-113.71805, 43.9737, 0],
                [-113.71816, 43.97373, 0],
                [-113.71859, 43.97383, 0],
                [-113.71907, 43.9739, 0],
                [-113.72069, 43.97403, 0],
                [-113.72093, 43.97404, 0],
                [-113.72097, 43.97405, 0],
                [-113.72146, 43.97408, 0],
                [-113.72406, 43.97428, 0],
                [-113.72426, 43.9743, 0],
                [-113.72441, 43.97431, 0],
                [-113.72463, 43.97434, 0],
                [-113.72472, 43.97436, 0],
                [-113.72486, 43.97438, 0],
                [-113.72536, 43.97448, 0],
                [-113.72916, 43.97533, 0],
                [-113.72943, 43.9754, 0],
                [-113.72952, 43.97542, 0],
                [-113.72967, 43.97547, 0],
                [-113.72969, 43.97547, 0],
                [-113.73018, 43.97565, 0],
                [-113.73035, 43.97572, 0],
                [-113.73068, 43.97588, 0],
                [-113.73114, 43.97614, 0],
                [-113.73124, 43.97619, 0],
                [-113.73228, 43.9768, 0],
                [-113.73241, 43.97687, 0],
                [-113.73285, 43.97714, 0],
                [-113.73368, 43.97761, 0],
                [-113.73386, 43.97772, 0],
                [-113.73408, 43.97784, 0],
                [-113.7346, 43.97815, 0],
                [-113.73484, 43.97828, 0],
                [-113.73497, 43.97836, 0],
                [-113.73562, 43.97873, 0],
                [-113.73583, 43.97886, 0],
                [-113.73612, 43.97902, 0],
                [-113.73624, 43.97908, 0],
                [-113.73687, 43.97944, 0],
                [-113.737, 43.97953, 0],
                [-113.73719, 43.97968, 0],
                [-113.73726, 43.97974, 0],
                [-113.73741, 43.97989, 0],
                [-113.73761, 43.98018, 0],
                [-113.73765, 43.98025, 0],
                [-113.73767, 43.98029, 0],
                [-113.73771, 43.98036, 0],
                [-113.7385, 43.98199, 0],
                [-113.73855, 43.98208, 0],
                [-113.73885, 43.9827, 0],
                [-113.739, 43.98297, 0],
                [-113.73904, 43.98305, 0],
                [-113.7391, 43.98314, 0],
                [-113.73921, 43.98334, 0],
                [-113.73931, 43.9835, 0],
                [-113.73932, 43.98351, 0],
                [-113.73936, 43.98359, 0],
                [-113.73942, 43.98367, 0],
                [-113.73947, 43.98375, 0],
                [-113.73955, 43.98385, 0],
                [-113.73968, 43.98404, 0],
                [-113.73989, 43.98429, 0],
                [-113.74017, 43.9846, 0],
                [-113.74045, 43.98488, 0],
                [-113.74056, 43.98498, 0],
                [-113.74067, 43.98507, 0],
                [-113.7409, 43.98528, 0],
                [-113.74123, 43.98555, 0],
                [-113.74131, 43.98562, 0],
                [-113.74157, 43.98579, 0],
                [-113.7418, 43.98595, 0],
                [-113.74216, 43.98617, 0],
                [-113.74246, 43.98634, 0],
                [-113.74308, 43.98671, 0],
                [-113.74409, 43.98729, 0],
                [-113.74453, 43.98753, 0],
                [-113.74556, 43.98813, 0],
                [-113.74627, 43.98853, 0],
                [-113.7465, 43.98867, 0],
                [-113.74693, 43.98891, 0],
                [-113.74812, 43.9896, 0],
                [-113.74832, 43.98971, 0],
                [-113.75024, 43.99083, 0],
                [-113.75321, 43.99253, 0],
                [-113.75344, 43.99267, 0],
                [-113.75484, 43.99347, 0],
                [-113.75492, 43.99352, 0],
                [-113.75664, 43.99452, 0],
                [-113.75688, 43.99465, 0],
                [-113.75733, 43.99491, 0],
                [-113.75751, 43.99502, 0],
                [-113.75762, 43.99508, 0],
                [-113.75776, 43.99517, 0],
                [-113.75825, 43.99555, 0],
                [-113.75841, 43.99569, 0],
                [-113.75874, 43.99601, 0],
                [-113.75954, 43.99687, 0],
                [-113.75959, 43.99693, 0],
                [-113.76236, 43.99992, 0],
                [-113.76243, 44, 0],
                [-113.76256, 44.00013, 0],
                [-113.76441, 44.0021, 0],
                [-113.7652, 44.00297, 0],
                [-113.76687, 44.00475, 0],
                [-113.76736, 44.00529, 0],
                [-113.76775, 44.0057, 0],
                [-113.76819, 44.00618, 0],
                [-113.76825, 44.00624, 0],
                [-113.77048, 44.00864, 0],
                [-113.7709, 44.00908, 0],
                [-113.77125, 44.00947, 0],
                [-113.77335, 44.01172, 0],
                [-113.77391, 44.01234, 0],
                [-113.77399, 44.01242, 0],
                [-113.77495, 44.01345, 0],
                [-113.77496, 44.01345, 0],
                [-113.77883, 44.01765, 0],
                [-113.77895, 44.01777, 0],
                [-113.77937, 44.01823, 0],
                [-113.77964, 44.01851, 0],
                [-113.78271, 44.02181, 0],
                [-113.78376, 44.02296, 0],
                [-113.78568, 44.02502, 0],
                [-113.78621, 44.02557, 0],
                [-113.78711, 44.02644, 0],
                [-113.78734, 44.02665, 0],
                [-113.78766, 44.02691, 0],
                [-113.78947, 44.02833, 0],
                [-113.79063, 44.0292, 0],
                [-113.79356, 44.03147, 0],
                [-113.79357, 44.03148, 0],
                [-113.79459, 44.03227, 0],
                [-113.79479, 44.03241, 0],
                [-113.80908, 44.04345, 0],
                [-113.8093, 44.04363, 0],
                [-113.81089, 44.04487, 0],
                [-113.81213, 44.0458, 0],
                [-113.81255, 44.04613, 0],
                [-113.81336, 44.0468, 0],
                [-113.81375, 44.04718, 0],
                [-113.8139, 44.04736, 0],
                [-113.81402, 44.04752, 0],
                [-113.81434, 44.04801, 0],
                [-113.81454, 44.04836, 0],
                [-113.81977, 44.05807, 0],
                [-113.81993, 44.05834, 0],
                [-113.81995, 44.05838, 0],
                [-113.81998, 44.05843, 0],
                [-113.82017, 44.05869, 0],
                [-113.82018, 44.0587, 0],
                [-113.82027, 44.05883, 0],
                [-113.82038, 44.05897, 0],
                [-113.82056, 44.05917, 0],
                [-113.8206, 44.05921, 0],
                [-113.82069, 44.05931, 0],
                [-113.8208, 44.05942, 0],
                [-113.82102, 44.05962, 0],
                [-113.82108, 44.05966, 0],
                [-113.82143, 44.05994, 0],
                [-113.82151, 44.05999, 0],
                [-113.82159, 44.06005, 0],
                [-113.82171, 44.06013, 0],
                [-113.82185, 44.06021, 0],
                [-113.82189, 44.06023, 0],
                [-113.82203, 44.06032, 0],
                [-113.8222, 44.06041, 0],
                [-113.8229, 44.06081, 0],
                [-113.82514, 44.06197, 0],
                [-113.82754, 44.06325, 0],
                [-113.83423, 44.06676, 0],
                [-113.83484, 44.06707, 0],
                [-113.8355, 44.06746, 0],
                [-113.83559, 44.06753, 0],
                [-113.83587, 44.06772, 0],
                [-113.83602, 44.06783, 0],
                [-113.83611, 44.06791, 0],
                [-113.83619, 44.06797, 0],
                [-113.83627, 44.06805, 0],
                [-113.83629, 44.06806, 0],
                [-113.83645, 44.06821, 0],
                [-113.83668, 44.06844, 0],
                [-113.83693, 44.06872, 0],
                [-113.83699, 44.06881, 0],
                [-113.83715, 44.06902, 0],
                [-113.83721, 44.06911, 0],
                [-113.83732, 44.06929, 0],
                [-113.83736, 44.06937, 0],
                [-113.83741, 44.06945, 0],
                [-113.83746, 44.06954, 0],
                [-113.83751, 44.06965, 0],
                [-113.83757, 44.06977, 0],
                [-113.83763, 44.06993, 0],
                [-113.83765, 44.06997, 0],
                [-113.83768, 44.07005, 0],
                [-113.83771, 44.07016, 0],
                [-113.83774, 44.07024, 0],
                [-113.83777, 44.07034, 0],
                [-113.83832, 44.07294, 0],
                [-113.83846, 44.07365, 0],
                [-113.83853, 44.07397, 0],
                [-113.83854, 44.074, 0],
                [-113.83861, 44.07436, 0],
                [-113.83873, 44.07489, 0],
                [-113.83876, 44.07507, 0],
                [-113.83902, 44.07627, 0],
                [-113.83967, 44.07945, 0],
                [-113.83969, 44.07952, 0],
                [-113.8398, 44.08005, 0],
                [-113.83983, 44.08023, 0],
                [-113.84018, 44.08181, 0],
                [-113.84031, 44.08219, 0],
                [-113.84035, 44.08232, 0],
                [-113.84048, 44.08261, 0],
                [-113.84051, 44.08269, 0],
                [-113.84064, 44.08296, 0],
                [-113.84072, 44.08314, 0],
                [-113.84084, 44.08333, 0],
                [-113.84086, 44.08337, 0],
                [-113.84115, 44.08382, 0],
                [-113.84166, 44.08452, 0],
                [-113.84175, 44.08466, 0],
                [-113.84184, 44.08477, 0],
                [-113.84284, 44.08619, 0],
                [-113.84412, 44.08797, 0],
                [-113.84416, 44.08802, 0],
                [-113.84485, 44.08898, 0],
                [-113.84487, 44.08902, 0],
                [-113.84516, 44.08942, 0],
                [-113.84591, 44.09049, 0],
                [-113.84598, 44.09058, 0],
                [-113.84668, 44.09157, 0],
                [-113.84706, 44.09207, 0],
                [-113.84747, 44.09252, 0],
                [-113.84764, 44.09269, 0],
                [-113.84776, 44.0928, 0],
                [-113.84797, 44.09297, 0],
                [-113.84825, 44.09318, 0],
                [-113.84839, 44.09327, 0],
                [-113.84852, 44.09336, 0],
                [-113.8487, 44.09347, 0],
                [-113.84879, 44.09352, 0],
                [-113.84901, 44.09366, 0],
                [-113.85264, 44.09557, 0],
                [-113.85373, 44.09613, 0],
                [-113.8582, 44.09849, 0],
                [-113.85832, 44.09856, 0],
                [-113.85954, 44.0992, 0],
                [-113.85975, 44.0993, 0],
                [-113.86454, 44.10181, 0],
                [-113.8646, 44.10185, 0],
                [-113.86717, 44.10318, 0],
                [-113.86966, 44.10451, 0],
                [-113.8742, 44.10687, 0],
                [-113.87572, 44.10768, 0],
                [-113.87694, 44.10831, 0],
                [-113.87727, 44.10849, 0],
                [-113.87846, 44.1091, 0],
                [-113.87983, 44.10984, 0],
                [-113.88069, 44.11028, 0],
                [-113.88191, 44.11095, 0],
                [-113.88213, 44.11109, 0],
                [-113.88254, 44.11137, 0],
                [-113.88286, 44.1116, 0],
                [-113.88354, 44.11217, 0],
                [-113.88375, 44.11237, 0],
                [-113.88382, 44.11243, 0],
                [-113.88648, 44.1151, 0],
                [-113.88658, 44.11521, 0],
                [-113.88878, 44.11739, 0],
                [-113.88933, 44.11797, 0],
                [-113.89136, 44.12, 0],
                [-113.89226, 44.12088, 0],
                [-113.89247, 44.1211, 0],
                [-113.89281, 44.12143, 0],
                [-113.89312, 44.12175, 0],
                [-113.89338, 44.122, 0],
                [-113.89448, 44.1231, 0],
                [-113.89461, 44.12324, 0],
                [-113.89699, 44.12561, 0],
                [-113.89739, 44.12599, 0],
                [-113.89787, 44.12649, 0],
                [-113.89878, 44.12741, 0],
                [-113.89978, 44.12838, 0],
                [-113.90007, 44.12865, 0],
                [-113.90042, 44.12896, 0],
                [-113.90067, 44.12917, 0],
                [-113.90116, 44.12956, 0],
                [-113.90172, 44.13003, 0],
                [-113.90219, 44.13041, 0],
                [-113.90275, 44.13088, 0],
                [-113.903, 44.13111, 0],
                [-113.90307, 44.13119, 0],
                [-113.90319, 44.1313, 0],
                [-113.90332, 44.13145, 0],
                [-113.9034, 44.13156, 0],
                [-113.9035, 44.13167, 0],
                [-113.90365, 44.13187, 0],
                [-113.90365, 44.13188, 0],
                [-113.90371, 44.13197, 0],
                [-113.90387, 44.13223, 0],
                [-113.90396, 44.13239, 0],
                [-113.90407, 44.13261, 0],
                [-113.90419, 44.13288, 0],
                [-113.90433, 44.13336, 0],
                [-113.90438, 44.13365, 0],
                [-113.90445, 44.13514, 0],
                [-113.90449, 44.13827, 0],
                [-113.9045, 44.13848, 0],
                [-113.90453, 44.1388, 0],
                [-113.90462, 44.1393, 0],
                [-113.90469, 44.13959, 0],
                [-113.90496, 44.14014, 0],
                [-113.90537, 44.14091, 0],
                [-113.90624, 44.14246, 0],
                [-113.90652, 44.14298, 0],
                [-113.91168, 44.15228, 0],
                [-113.91302, 44.15466, 0],
                [-113.91323, 44.15507, 0],
                [-113.9185, 44.16455, 0],
                [-113.91891, 44.16531, 0],
                [-113.91907, 44.16558, 0],
                [-113.91929, 44.16599, 0],
                [-113.91968, 44.16667, 0],
                [-113.92001, 44.16728, 0],
                [-113.92003, 44.16733, 0],
                [-113.92034, 44.16786, 0],
                [-113.92093, 44.16892, 0],
                [-113.92112, 44.16928, 0],
                [-113.92161, 44.17016, 0],
                [-113.92175, 44.1704, 0],
                [-113.92289, 44.17248, 0],
                [-113.92298, 44.17263, 0],
                [-113.92309, 44.17284, 0],
                [-113.92343, 44.17343, 0],
                [-113.92364, 44.17382, 0],
                [-113.92381, 44.17411, 0],
                [-113.92621, 44.17844, 0],
                [-113.92625, 44.17852, 0],
                [-113.92635, 44.17869, 0],
                [-113.92893, 44.18334, 0],
                [-113.92901, 44.18347, 0],
                [-113.92904, 44.18353, 0],
                [-113.93118, 44.18738, 0],
                [-113.93126, 44.18754, 0],
                [-113.93143, 44.18783, 0],
                [-113.93387, 44.19222, 0],
                [-113.93391, 44.19228, 0],
                [-113.93527, 44.19471, 0],
                [-113.9355, 44.19515, 0],
                [-113.93642, 44.1968, 0],
                [-113.9365, 44.19693, 0],
                [-113.93912, 44.20166, 0],
                [-113.93957, 44.20245, 0],
                [-113.94047, 44.20409, 0],
                [-113.94337, 44.20926, 0],
                [-113.9442, 44.21082, 0],
                [-113.94494, 44.2121, 0],
                [-113.94611, 44.2142, 0],
                [-113.94621, 44.21439, 0],
                [-113.94624, 44.21446, 0],
                [-113.94666, 44.21522, 0],
                [-113.94676, 44.21539, 0],
                [-113.9469, 44.21565, 0],
                [-113.94715, 44.21608, 0],
                [-113.94845, 44.21845, 0],
                [-113.94864, 44.21877, 0],
                [-113.94871, 44.21888, 0],
                [-113.94873, 44.2189, 0],
                [-113.94891, 44.21914, 0],
                [-113.94899, 44.21923, 0],
                [-113.94912, 44.21939, 0],
                [-113.9493, 44.21957, 0],
                [-113.9494, 44.21965, 0],
                [-113.94942, 44.21967, 0],
                [-113.94958, 44.2198, 0],
                [-113.94973, 44.21991, 0],
                [-113.95006, 44.22012, 0],
                [-113.95049, 44.22035, 0],
                [-113.95071, 44.22046, 0],
                [-113.95212, 44.22108, 0],
                [-113.95327, 44.22156, 0],
                [-113.95353, 44.22169, 0],
                [-113.95373, 44.22178, 0],
                [-113.95388, 44.22184, 0],
                [-113.95418, 44.22198, 0],
                [-113.95453, 44.22216, 0],
                [-113.95486, 44.22235, 0],
                [-113.95574, 44.22288, 0],
                [-113.95638, 44.22329, 0],
                [-113.95662, 44.22341, 0],
                [-113.95687, 44.22352, 0],
                [-113.95705, 44.22361, 0],
                [-113.96055, 44.22505, 0],
                [-113.96065, 44.22508, 0],
                [-113.96073, 44.22511, 0],
                [-113.96138, 44.2253, 0],
                [-113.96294, 44.2257, 0],
                [-113.96346, 44.22582, 0],
                [-113.96373, 44.22589, 0],
                [-113.96397, 44.22596, 0],
                [-113.96446, 44.22614, 0],
                [-113.96492, 44.22634, 0],
                [-113.96514, 44.22645, 0],
                [-113.96784, 44.22791, 0],
                [-113.9687, 44.22831, 0],
                [-113.96874, 44.22832, 0],
                [-113.96879, 44.22834, 0],
                [-113.96891, 44.22838, 0],
                [-113.96898, 44.22841, 0],
                [-113.96916, 44.22845, 0],
                [-113.96921, 44.22847, 0],
                [-113.96926, 44.22848, 0],
                [-113.96931, 44.22848, 0],
                [-113.96937, 44.2285, 0],
                [-113.96947, 44.22851, 0],
                [-113.96958, 44.22853, 0],
                [-113.97012, 44.22857, 0],
                [-113.97162, 44.22871, 0],
                [-113.97178, 44.22873, 0],
                [-113.97211, 44.22879, 0],
                [-113.97234, 44.22885, 0],
                [-113.97264, 44.22895, 0],
                [-113.97277, 44.229, 0],
                [-113.97305, 44.22913, 0],
                [-113.97323, 44.22922, 0],
                [-113.97333, 44.22928, 0],
                [-113.9734, 44.22933, 0],
                [-113.97345, 44.22936, 0],
                [-113.97357, 44.22944, 0],
                [-113.97391, 44.2297, 0],
                [-113.97481, 44.23058, 0],
                [-113.9751, 44.23088, 0],
                [-113.97513, 44.2309, 0],
                [-113.97551, 44.23128, 0],
                [-113.9756, 44.23138, 0],
                [-113.97569, 44.23147, 0],
                [-113.97577, 44.23157, 0],
                [-113.97586, 44.23166, 0],
                [-113.97593, 44.23175, 0],
                [-113.97594, 44.23177, 0],
                [-113.97597, 44.23181, 0],
                [-113.97609, 44.23199, 0],
                [-113.9762, 44.23222, 0],
                [-113.97623, 44.23231, 0],
                [-113.97629, 44.23252, 0],
                [-113.97639, 44.23297, 0],
                [-113.97651, 44.23339, 0],
                [-113.97657, 44.23357, 0],
                [-113.97662, 44.23368, 0],
                [-113.97671, 44.23386, 0],
                [-113.97674, 44.23391, 0],
                [-113.97678, 44.23396, 0],
                [-113.9768, 44.234, 0],
                [-113.97684, 44.23404, 0],
                [-113.97688, 44.23409, 0],
                [-113.97736, 44.23461, 0],
                [-113.97737, 44.23461, 0],
                [-113.97748, 44.23473, 0],
                [-113.9776, 44.23483, 0],
                [-113.97768, 44.23491, 0],
                [-113.97777, 44.23497, 0],
                [-113.97787, 44.23505, 0],
                [-113.978, 44.23512, 0],
                [-113.97811, 44.23519, 0],
                [-113.97822, 44.23525, 0],
                [-113.9784, 44.23534, 0],
                [-113.9794, 44.23575, 0],
                [-113.97974, 44.23593, 0],
                [-113.98007, 44.23612, 0],
                [-113.9816, 44.23711, 0],
                [-113.98161, 44.23712, 0],
                [-113.98192, 44.23736, 0],
                [-113.98228, 44.23769, 0],
                [-113.98241, 44.23782, 0],
                [-113.98257, 44.23802, 0],
                [-113.98264, 44.2381, 0],
                [-113.98265, 44.23812, 0],
                [-113.9827, 44.23817, 0],
                [-113.98284, 44.23837, 0],
                [-113.98304, 44.23862, 0],
                [-113.98326, 44.23888, 0],
                [-113.98365, 44.23937, 0],
                [-113.98377, 44.23951, 0],
                [-113.98414, 44.23992, 0],
                [-113.98443, 44.24026, 0],
                [-113.98453, 44.24039, 0],
                [-113.98456, 44.24042, 0],
                [-113.98459, 44.24046, 0],
                [-113.98464, 44.24054, 0],
                [-113.98468, 44.24059, 0],
                [-113.98471, 44.24065, 0],
                [-113.98481, 44.24078, 0],
                [-113.98491, 44.2409, 0],
                [-113.985, 44.241, 0],
                [-113.98507, 44.24109, 0],
                [-113.98544, 44.24151, 0],
                [-113.98578, 44.24187, 0],
                [-113.98624, 44.24233, 0],
                [-113.98633, 44.24243, 0],
                [-113.9864, 44.2425, 0],
                [-113.98646, 44.24257, 0],
                [-113.98658, 44.24269, 0],
                [-113.98665, 44.24277, 0],
                [-113.98672, 44.24284, 0],
                [-113.98682, 44.24293, 0],
                [-113.9869, 44.24299, 0],
                [-113.98696, 44.24304, 0],
                [-113.98698, 44.24305, 0],
                [-113.98712, 44.24317, 0],
                [-113.98752, 44.24345, 0],
                [-113.98781, 44.24363, 0],
                [-113.98804, 44.24379, 0],
                [-113.98836, 44.244, 0],
                [-113.98855, 44.24415, 0],
                [-113.98871, 44.24425, 0],
                [-113.98998, 44.24509, 0],
                [-113.99006, 44.24515, 0],
                [-113.99014, 44.24519, 0],
                [-113.99049, 44.2454, 0],
                [-113.99149, 44.24596, 0],
                [-113.99178, 44.24614, 0],
                [-113.99192, 44.24621, 0],
                [-113.99206, 44.24631, 0],
                [-113.99241, 44.24653, 0],
                [-113.99349, 44.24725, 0],
                [-113.99412, 44.2477, 0],
                [-113.99458, 44.24801, 0],
                [-113.9948, 44.24817, 0],
                [-113.99538, 44.24857, 0],
                [-113.99609, 44.24898, 0],
                [-113.99639, 44.24914, 0],
                [-113.99729, 44.24958, 0],
                [-113.99806, 44.24999, 0],
                [-113.99855, 44.25029, 0],
                [-113.99873, 44.25039, 0],
                [-113.99924, 44.25063, 0],
                [-114, 44.25095, 0],
                [-114.00018, 44.25101, 0],
                [-114.00047, 44.25112, 0],
                [-114.00082, 44.25123, 0],
                [-114.0014, 44.25138, 0],
                [-114.00301, 44.25173, 0],
                [-114.00314, 44.25175, 0],
                [-114.00345, 44.25182, 0],
                [-114.00582, 44.25229, 0],
                [-114.00692, 44.2526, 0],
                [-114.00705, 44.25265, 0],
                [-114.00729, 44.25273, 0],
                [-114.00745, 44.25279, 0],
                [-114.00759, 44.25283, 0],
                [-114.00783, 44.25293, 0],
                [-114.00794, 44.25297, 0],
                [-114.00832, 44.25313, 0],
                [-114.00844, 44.25319, 0],
                [-114.00868, 44.2533, 0],
                [-114.00939, 44.25367, 0],
                [-114.00952, 44.25375, 0],
                [-114.00968, 44.25384, 0],
                [-114.00974, 44.25387, 0],
                [-114.00982, 44.25392, 0],
                [-114.01002, 44.25406, 0],
                [-114.01017, 44.25415, 0],
                [-114.01028, 44.25424, 0],
                [-114.01053, 44.25442, 0],
                [-114.01067, 44.25453, 0],
                [-114.01078, 44.25461, 0],
                [-114.01104, 44.25483, 0],
                [-114.01119, 44.25497, 0],
                [-114.01133, 44.25509, 0],
                [-114.01143, 44.2552, 0],
                [-114.01161, 44.25538, 0],
                [-114.01177, 44.25555, 0],
                [-114.01193, 44.25573, 0],
                [-114.01276, 44.25671, 0],
                [-114.01302, 44.25703, 0],
                [-114.01322, 44.25726, 0],
                [-114.01348, 44.25758, 0],
                [-114.01354, 44.25764, 0],
                [-114.01379, 44.25793, 0],
                [-114.01509, 44.25951, 0],
                [-114.01537, 44.25983, 0],
                [-114.01699, 44.26175, 0],
                [-114.01715, 44.26193, 0],
                [-114.01811, 44.26307, 0],
                [-114.01831, 44.26333, 0],
                [-114.0184, 44.26343, 0],
                [-114.01857, 44.26364, 0],
                [-114.01919, 44.26437, 0],
                [-114.01938, 44.26458, 0],
                [-114.0198, 44.26507, 0],
                [-114.02, 44.26532, 0],
                [-114.0207, 44.26616, 0],
                [-114.02104, 44.26655, 0],
                [-114.02146, 44.26706, 0],
                [-114.02156, 44.26717, 0],
                [-114.0222, 44.26793, 0],
                [-114.02221, 44.26794, 0],
                [-114.02298, 44.26887, 0],
                [-114.02301, 44.26889, 0],
                [-114.02311, 44.26902, 0],
                [-114.0249, 44.27115, 0],
                [-114.02504, 44.27134, 0],
                [-114.02506, 44.27135, 0],
                [-114.02522, 44.27155, 0],
                [-114.0254, 44.27175, 0],
                [-114.02694, 44.27356, 0],
                [-114.02732, 44.27402, 0],
                [-114.02849, 44.27539, 0],
                [-114.0288, 44.27577, 0],
                [-114.03133, 44.27875, 0],
                [-114.03145, 44.2789, 0],
                [-114.03225, 44.27984, 0],
                [-114.03278, 44.28048, 0],
                [-114.03283, 44.28053, 0],
                [-114.03316, 44.28093, 0],
                [-114.03372, 44.28158, 0],
                [-114.03383, 44.28173, 0],
                [-114.03404, 44.28197, 0],
                [-114.03421, 44.28218, 0],
                [-114.03434, 44.28233, 0],
                [-114.03437, 44.28237, 0],
                [-114.03505, 44.28316, 0],
                [-114.03512, 44.28325, 0],
                [-114.03756, 44.28614, 0],
                [-114.03772, 44.28634, 0],
                [-114.03798, 44.28664, 0],
                [-114.03832, 44.28705, 0],
                [-114.03887, 44.28769, 0],
                [-114.03926, 44.28817, 0],
                [-114.04143, 44.29072, 0],
                [-114.04181, 44.2912, 0],
                [-114.04183, 44.29123, 0],
                [-114.042, 44.29144, 0],
                [-114.04252, 44.29219, 0],
                [-114.04276, 44.29256, 0],
                [-114.04309, 44.29316, 0],
                [-114.04349, 44.29394, 0],
                [-114.04362, 44.29424, 0],
                [-114.04369, 44.29444, 0],
                [-114.04404, 44.29535, 0],
                [-114.04412, 44.2956, 0],
                [-114.04413, 44.29564, 0],
                [-114.04416, 44.29573, 0],
                [-114.04427, 44.29611, 0],
                [-114.04441, 44.29674, 0],
                [-114.04467, 44.29809, 0],
                [-114.04469, 44.29817, 0],
                [-114.0447, 44.29819, 0],
                [-114.04472, 44.29832, 0],
                [-114.04476, 44.29847, 0],
                [-114.04482, 44.29874, 0],
                [-114.04488, 44.29895, 0],
                [-114.04491, 44.29911, 0],
                [-114.04495, 44.29928, 0],
                [-114.04498, 44.29939, 0],
                [-114.04501, 44.29953, 0],
                [-114.04508, 44.29991, 0],
                [-114.04532, 44.30103, 0],
                [-114.04535, 44.30119, 0],
                [-114.04542, 44.30148, 0],
                [-114.04581, 44.30332, 0],
                [-114.046, 44.30414, 0],
                [-114.04618, 44.30505, 0],
                [-114.0465, 44.30657, 0],
                [-114.04662, 44.30708, 0],
                [-114.0467, 44.30739, 0],
                [-114.04671, 44.30745, 0],
                [-114.04673, 44.3075, 0],
                [-114.04675, 44.30758, 0],
                [-114.04678, 44.30766, 0],
                [-114.04684, 44.30786, 0],
                [-114.04688, 44.30797, 0],
                [-114.04695, 44.30821, 0],
                [-114.04707, 44.30854, 0],
                [-114.04725, 44.30899, 0],
                [-114.04741, 44.30932, 0],
                [-114.04746, 44.3094, 0],
                [-114.04774, 44.30995, 0],
                [-114.04791, 44.31024, 0],
                [-114.04798, 44.31037, 0],
                [-114.04817, 44.31069, 0],
                [-114.04836, 44.31099, 0],
                [-114.04853, 44.31123, 0],
                [-114.04928, 44.31236, 0],
                [-114.0497, 44.31297, 0],
                [-114.05004, 44.3135, 0],
                [-114.05215, 44.31665, 0],
                [-114.05232, 44.31693, 0],
                [-114.05243, 44.31713, 0],
                [-114.05255, 44.31738, 0],
                [-114.05257, 44.31743, 0],
                [-114.05261, 44.31751, 0],
                [-114.05265, 44.31765, 0],
                [-114.05281, 44.31811, 0],
                [-114.05291, 44.31845, 0],
                [-114.05298, 44.31873, 0],
                [-114.05299, 44.31878, 0],
                [-114.05301, 44.31892, 0],
                [-114.05301, 44.31896, 0],
                [-114.05303, 44.31906, 0],
                [-114.05305, 44.31925, 0],
                [-114.05307, 44.31957, 0],
                [-114.05307, 44.31964, 0],
                [-114.05308, 44.31979, 0],
                [-114.05307, 44.31996, 0],
                [-114.05307, 44.32008, 0],
                [-114.05306, 44.3202, 0],
                [-114.05305, 44.32027, 0],
                [-114.05302, 44.32057, 0],
                [-114.053, 44.32066, 0],
                [-114.05297, 44.32086, 0],
                [-114.05295, 44.32095, 0],
                [-114.05294, 44.32103, 0],
                [-114.05291, 44.32113, 0],
                [-114.0529, 44.32121, 0],
                [-114.05286, 44.32134, 0],
                [-114.05278, 44.32166, 0],
                [-114.05259, 44.32227, 0],
                [-114.05183, 44.32496, 0],
                [-114.05158, 44.32591, 0],
                [-114.05139, 44.32655, 0],
                [-114.05129, 44.32694, 0],
                [-114.05128, 44.32699, 0],
                [-114.05108, 44.32773, 0],
                [-114.05101, 44.32795, 0],
                [-114.05097, 44.3281, 0],
                [-114.05088, 44.32839, 0],
                [-114.05087, 44.32846, 0],
                [-114.05083, 44.32862, 0],
                [-114.05066, 44.32922, 0],
                [-114.05063, 44.3293, 0],
                [-114.05055, 44.32957, 0],
                [-114.0505, 44.3297, 0],
                [-114.05044, 44.32992, 0],
                [-114.05041, 44.33001, 0],
                [-114.05032, 44.33037, 0],
                [-114.05028, 44.33061, 0],
                [-114.05025, 44.33075, 0],
                [-114.05023, 44.3309, 0],
                [-114.0502, 44.33138, 0],
                [-114.0502, 44.33166, 0],
                [-114.05023, 44.33214, 0],
                [-114.05038, 44.33326, 0],
                [-114.05039, 44.3333, 0],
                [-114.05057, 44.33466, 0],
                [-114.05061, 44.33492, 0],
                [-114.05064, 44.33519, 0],
                [-114.05066, 44.33527, 0],
                [-114.05069, 44.33547, 0],
                [-114.05076, 44.33581, 0],
                [-114.05081, 44.33622, 0],
                [-114.05088, 44.33669, 0],
                [-114.05094, 44.33716, 0],
                [-114.05104, 44.33782, 0],
                [-114.05113, 44.33861, 0],
                [-114.05118, 44.33891, 0],
                [-114.05159, 44.34184, 0],
                [-114.05159, 44.34185, 0],
                [-114.05172, 44.34283, 0],
                [-114.05179, 44.34326, 0],
                [-114.05196, 44.34402, 0],
                [-114.05201, 44.3442, 0],
                [-114.05204, 44.34433, 0],
                [-114.05208, 44.34444, 0],
                [-114.05214, 44.34463, 0],
                [-114.05218, 44.34478, 0],
                [-114.05223, 44.3449, 0],
                [-114.05229, 44.34503, 0],
                [-114.05235, 44.34518, 0],
                [-114.05265, 44.34584, 0],
                [-114.05276, 44.34604, 0],
                [-114.05283, 44.34619, 0],
                [-114.05307, 44.34661, 0],
                [-114.05326, 44.3469, 0],
                [-114.05362, 44.34741, 0],
                [-114.05377, 44.3476, 0],
                [-114.05391, 44.3478, 0],
                [-114.05395, 44.34784, 0],
                [-114.05398, 44.34788, 0],
                [-114.05432, 44.34826, 0],
                [-114.05449, 44.34844, 0],
                [-114.05594, 44.35013, 0],
                [-114.05661, 44.35089, 0],
                [-114.05663, 44.35091, 0],
                [-114.05719, 44.35157, 0],
                [-114.05833, 44.35288, 0],
                [-114.05837, 44.35291, 0],
                [-114.05915, 44.35382, 0],
                [-114.05964, 44.35437, 0],
                [-114.06, 44.3548, 0],
                [-114.06007, 44.35487, 0],
                [-114.06014, 44.35496, 0],
                [-114.06021, 44.35503, 0],
                [-114.06029, 44.3551, 0],
                [-114.06039, 44.35521, 0],
                [-114.06047, 44.35528, 0],
                [-114.06062, 44.3554, 0],
                [-114.06068, 44.35544, 0],
                [-114.06073, 44.35548, 0],
                [-114.06083, 44.35553, 0],
                [-114.06093, 44.35559, 0],
                [-114.06111, 44.35568, 0],
                [-114.06137, 44.35579, 0],
                [-114.06148, 44.35582, 0],
                [-114.06154, 44.35584, 0],
                [-114.06205, 44.35597, 0],
                [-114.06243, 44.35608, 0],
                [-114.06255, 44.35613, 0],
                [-114.06265, 44.35616, 0],
                [-114.06287, 44.35625, 0],
                [-114.06296, 44.3563, 0],
                [-114.06304, 44.35635, 0],
                [-114.06312, 44.35639, 0],
                [-114.06322, 44.35645, 0],
                [-114.06337, 44.35656, 0],
                [-114.06349, 44.35666, 0],
                [-114.06354, 44.35671, 0],
                [-114.06359, 44.35675, 0],
                [-114.06361, 44.35678, 0],
                [-114.06389, 44.35704, 0],
                [-114.06405, 44.3572, 0],
                [-114.06417, 44.35731, 0],
                [-114.06429, 44.35744, 0],
                [-114.06482, 44.35798, 0],
                [-114.06493, 44.35808, 0],
                [-114.06504, 44.3582, 0],
                [-114.06512, 44.35828, 0],
                [-114.06519, 44.35837, 0],
                [-114.06521, 44.35839, 0],
                [-114.06523, 44.35842, 0],
                [-114.06527, 44.35845, 0],
                [-114.06581, 44.35898, 0],
                [-114.06595, 44.3591, 0],
                [-114.06608, 44.35923, 0],
                [-114.06634, 44.35944, 0],
                [-114.06643, 44.35952, 0],
                [-114.06653, 44.3596, 0],
                [-114.06673, 44.35974, 0],
                [-114.06682, 44.35981, 0],
                [-114.0673, 44.36012, 0],
                [-114.0674, 44.36019, 0],
                [-114.06766, 44.36034, 0],
                [-114.06799, 44.36051, 0],
                [-114.06892, 44.36091, 0],
                [-114.06918, 44.36101, 0],
                [-114.06925, 44.36103, 0],
                [-114.0693, 44.36105, 0],
                [-114.06939, 44.36108, 0],
                [-114.06992, 44.36122, 0],
                [-114.07044, 44.36134, 0],
                [-114.07067, 44.3614, 0],
                [-114.07178, 44.36165, 0],
                [-114.07187, 44.36168, 0],
                [-114.07211, 44.36175, 0],
                [-114.07224, 44.3618, 0],
                [-114.07233, 44.36183, 0],
                [-114.07247, 44.36189, 0],
                [-114.07256, 44.36194, 0],
                [-114.07268, 44.362, 0],
                [-114.07288, 44.36211, 0],
                [-114.07316, 44.36224, 0],
                [-114.07328, 44.36231, 0],
                [-114.07335, 44.36234, 0],
                [-114.07339, 44.36236, 0],
                [-114.07344, 44.36238, 0],
                [-114.0735, 44.36241, 0],
                [-114.07362, 44.36244, 0],
                [-114.07383, 44.36251, 0],
                [-114.0739, 44.36252, 0],
                [-114.07408, 44.36257, 0],
                [-114.07431, 44.36262, 0],
                [-114.07445, 44.36266, 0],
                [-114.0746, 44.36271, 0],
                [-114.07484, 44.3628, 0],
                [-114.07498, 44.36286, 0],
                [-114.07517, 44.36296, 0],
                [-114.07536, 44.36309, 0],
                [-114.07546, 44.36318, 0],
                [-114.07558, 44.36328, 0],
                [-114.07568, 44.36339, 0],
                [-114.07584, 44.3636, 0],
                [-114.07597, 44.36382, 0],
                [-114.07601, 44.36393, 0],
                [-114.07605, 44.36406, 0],
                [-114.07609, 44.36421, 0],
                [-114.07611, 44.36436, 0],
                [-114.07612, 44.36452, 0],
                [-114.07612, 44.36468, 0],
                [-114.07611, 44.36481, 0],
                [-114.0761, 44.36483, 0],
                [-114.07608, 44.365, 0],
                [-114.07606, 44.36537, 0],
                [-114.07607, 44.3654, 0],
                [-114.07608, 44.36558, 0],
                [-114.0761, 44.36575, 0],
                [-114.07614, 44.366, 0],
                [-114.07618, 44.36613, 0],
                [-114.07627, 44.36639, 0],
                [-114.07638, 44.36667, 0],
                [-114.07639, 44.36668, 0],
                [-114.0765, 44.3669, 0],
                [-114.07656, 44.36699, 0],
                [-114.07659, 44.36705, 0],
                [-114.07669, 44.3672, 0],
                [-114.07675, 44.3673, 0],
                [-114.07686, 44.36747, 0],
                [-114.07703, 44.36771, 0],
                [-114.07712, 44.36786, 0],
                [-114.0772, 44.36797, 0],
                [-114.07777, 44.36888, 0],
                [-114.07797, 44.36922, 0],
                [-114.07939, 44.37144, 0],
                [-114.07967, 44.37186, 0],
                [-114.08026, 44.37282, 0],
                [-114.08073, 44.37361, 0],
                [-114.08079, 44.3737, 0],
                [-114.0816, 44.37501, 0],
                [-114.08168, 44.37513, 0],
                [-114.0822, 44.376, 0],
                [-114.08224, 44.37609, 0],
                [-114.08255, 44.37665, 0],
                [-114.08281, 44.37716, 0],
                [-114.08284, 44.37723, 0],
                [-114.08293, 44.3774, 0],
                [-114.08319, 44.37794, 0],
                [-114.08356, 44.37879, 0],
                [-114.08389, 44.37961, 0],
                [-114.08404, 44.38003, 0],
                [-114.08424, 44.38054, 0],
                [-114.08487, 44.38223, 0],
                [-114.08489, 44.3823, 0],
                [-114.08499, 44.38258, 0],
                [-114.08503, 44.38268, 0],
                [-114.08505, 44.38275, 0],
                [-114.08559, 44.38414, 0],
                [-114.08565, 44.38431, 0],
                [-114.08581, 44.38472, 0],
                [-114.08586, 44.38483, 0],
                [-114.08591, 44.38496, 0],
                [-114.08597, 44.3851, 0],
                [-114.086, 44.38518, 0],
                [-114.08604, 44.38526, 0],
                [-114.08613, 44.38546, 0],
                [-114.08618, 44.38555, 0],
                [-114.08623, 44.38566, 0],
                [-114.08648, 44.38613, 0],
                [-114.08656, 44.38629, 0],
                [-114.0866, 44.38636, 0],
                [-114.08662, 44.38639, 0],
                [-114.08673, 44.38658, 0],
                [-114.08681, 44.3867, 0],
                [-114.08709, 44.38715, 0],
                [-114.08721, 44.38733, 0],
                [-114.08756, 44.38782, 0],
                [-114.08762, 44.38791, 0],
                [-114.08771, 44.38801, 0],
                [-114.08778, 44.38811, 0],
                [-114.08793, 44.38829, 0],
                [-114.08904, 44.38971, 0],
                [-114.08942, 44.39017, 0],
                [-114.08991, 44.3908, 0],
                [-114.08993, 44.39082, 0],
                [-114.09325, 44.39505, 0],
                [-114.09393, 44.39589, 0],
                [-114.09405, 44.39605, 0],
                [-114.09484, 44.39705, 0],
                [-114.09491, 44.39715, 0],
                [-114.09524, 44.39756, 0],
                [-114.09528, 44.39762, 0],
                [-114.09729, 44.40019, 0],
                [-114.09853, 44.40175, 0],
                [-114.09917, 44.40259, 0],
                [-114.09975, 44.40333, 0],
                [-114.09983, 44.40342, 0],
                [-114.1, 44.40364, 0],
                [-114.10021, 44.40389, 0],
                [-114.10025, 44.40395, 0],
                [-114.101, 44.40489, 0],
                [-114.10113, 44.40504, 0],
                [-114.10126, 44.4052, 0],
                [-114.10154, 44.40552, 0],
                [-114.10163, 44.40563, 0],
                [-114.10167, 44.40567, 0],
                [-114.10172, 44.40573, 0],
                [-114.1018, 44.4058, 0],
                [-114.10214, 44.40615, 0],
                [-114.10223, 44.40625, 0],
                [-114.10227, 44.40629, 0],
                [-114.10246, 44.40646, 0],
                [-114.10254, 44.40654, 0],
                [-114.10264, 44.40663, 0],
                [-114.10269, 44.40668, 0],
                [-114.10282, 44.40679, 0],
                [-114.10285, 44.40682, 0],
                [-114.10325, 44.40717, 0],
                [-114.10365, 44.4075, 0],
                [-114.10449, 44.40817, 0],
                [-114.10516, 44.40869, 0],
                [-114.10568, 44.40911, 0],
                [-114.10585, 44.40924, 0],
                [-114.10618, 44.40951, 0],
                [-114.1066, 44.40984, 0],
                [-114.10671, 44.40992, 0],
                [-114.10786, 44.41083, 0],
                [-114.10798, 44.41092, 0],
                [-114.10808, 44.41101, 0],
                [-114.10846, 44.41132, 0],
                [-114.10855, 44.4114, 0],
                [-114.1087, 44.41155, 0],
                [-114.10875, 44.41161, 0],
                [-114.10883, 44.41169, 0],
                [-114.10892, 44.41179, 0],
                [-114.10898, 44.41188, 0],
                [-114.10906, 44.41197, 0],
                [-114.10922, 44.41218, 0],
                [-114.10932, 44.41233, 0],
                [-114.10936, 44.41238, 0],
                [-114.10939, 44.41244, 0],
                [-114.10945, 44.41254, 0],
                [-114.10961, 44.41283, 0],
                [-114.11139, 44.41626, 0],
                [-114.11172, 44.41686, 0],
                [-114.11179, 44.41697, 0],
                [-114.11196, 44.41727, 0],
                [-114.11236, 44.41786, 0],
                [-114.11263, 44.41823, 0],
                [-114.11278, 44.41845, 0],
                [-114.11282, 44.4185, 0],
                [-114.11293, 44.41866, 0],
                [-114.11294, 44.41866, 0],
                [-114.11343, 44.41931, 0],
                [-114.11395, 44.41994, 0],
                [-114.11436, 44.4204, 0],
                [-114.11494, 44.42101, 0],
                [-114.11503, 44.42109, 0],
                [-114.11557, 44.42163, 0],
                [-114.11896, 44.42485, 0],
                [-114.11911, 44.425, 0],
                [-114.11942, 44.42528, 0],
                [-114.12012, 44.42595, 0],
                [-114.12033, 44.42614, 0],
                [-114.12306, 44.42873, 0],
                [-114.12368, 44.4293, 0],
                [-114.13177, 44.43694, 0],
                [-114.13234, 44.4375, 0],
                [-114.13931, 44.44408, 0],
                [-114.13945, 44.4442, 0],
                [-114.14297, 44.44754, 0],
                [-114.14348, 44.44798, 0],
                [-114.14415, 44.44851, 0],
                [-114.14436, 44.44866, 0],
                [-114.14495, 44.44904, 0],
                [-114.14577, 44.44952, 0],
                [-114.14616, 44.44973, 0],
                [-114.17038, 44.46161, 0],
                [-114.17081, 44.46183, 0],
                [-114.17317, 44.46299, 0],
                [-114.17393, 44.46334, 0],
                [-114.1745, 44.46359, 0],
                [-114.17531, 44.46392, 0],
                [-114.17645, 44.46434, 0],
                [-114.17754, 44.46469, 0],
                [-114.17826, 44.4649, 0],
                [-114.17953, 44.46524, 0],
                [-114.17963, 44.46526, 0],
                [-114.17998, 44.46535, 0],
                [-114.18046, 44.46545, 0],
                [-114.18062, 44.46549, 0],
                [-114.18091, 44.46554, 0],
                [-114.18105, 44.46557, 0],
                [-114.18218, 44.46576, 0],
                [-114.1829, 44.46587, 0],
                [-114.18427, 44.46604, 0],
                [-114.18542, 44.46613, 0],
                [-114.18897, 44.46646, 0],
                [-114.19284, 44.46679, 0],
                [-114.19385, 44.4669, 0],
                [-114.19491, 44.467, 0],
                [-114.19553, 44.46709, 0],
                [-114.19581, 44.46715, 0],
                [-114.19644, 44.46727, 0],
                [-114.19741, 44.46752, 0],
                [-114.19753, 44.46756, 0],
                [-114.19769, 44.4676, 0],
                [-114.19785, 44.46765, 0],
                [-114.19789, 44.46766, 0],
                [-114.19805, 44.46772, 0],
                [-114.1985, 44.46787, 0],
                [-114.19934, 44.46819, 0],
                [-114.20002, 44.46851, 0],
                [-114.20067, 44.46883, 0],
                [-114.20082, 44.46891, 0],
                [-114.20124, 44.46916, 0],
                [-114.20183, 44.46956, 0],
                [-114.2024, 44.46999, 0],
                [-114.20469, 44.4719, 0],
                [-114.20497, 44.47215, 0],
                [-114.20557, 44.47261, 0],
                [-114.20717, 44.47394, 0],
                [-114.2083, 44.47492, 0],
                [-114.20871, 44.47529, 0]
            ]
        },
        id: "e23262b6-e1bf-4e82-b23b-64fde5b7edb6"
    }, 
    // {
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-114.27888, 43.33199, 0]
    //     },
    //     properties: {
    //         name: "Bellevue, Idaho",
    //         "shape": "Marker",
    //         "category": "default"
    //     },
    //     id: "310f6064-5e62-4964-be3d-d66384282210"
    // }, {
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-114.20871, 44.47529, 0]
    //     },
    //     properties: {
    //         name: "Challis, Idaho",
    //         "shape": "Marker",
    //         "category": "default"
    //     },
    //     id: "fdd71366-4568-4a4d-aed1-8e176041c22e"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Carey, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-113.94586, 43.30814, 0]
    //     },
    //     id: "091daa87-0733-4bf8-8c23-4f6805807743"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Picabo, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-114.065645, 43.306693]
    //     },
    //     id: "be631755-fff9-4eff-bfb2-5baed79ae7ac"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Arco, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-113.30006, 43.63661, 0]
    //     },
    //     id: "c5faa6a1-009d-46a1-bb7f-e5a14bb7a351"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Mackay, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-113.6125, 43.91474, 0]
    //     },
    //     id: "967dd3f8-0af8-4dc6-a874-513e1237ba96"
    // }
    ]
};

export default PeaksToCraters;