import AdventureCard from "../Cards/AdventureCard";
import { IconClose, IconSearchCircle } from "../../images/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RecommendedActivities from "../Recommended/RecommendedActivities";
import { findRelevance } from "../../helpers/relevancy";

function SearchPopup(props) {
	const [results, setResults] = useState([]);
	const markers = useSelector((state) => state.markers.markers);

	useEffect(() => {
		getResults(props);
	}, [markers])

	const singularize = (word) => {
		let endings = {
			ves: 'fe',
			ies: 'y',
			i: 'us',
			zes: 'ze',
			ses: 's',
			es: 'e',
			s: ''
		};
		return word.replace(
			new RegExp(`(${Object.keys(endings).join('|')})$`), 
			r => endings[r]
		);
	}


	const getResults = (props) => {
		let query = singularize(props.searchQuery.toLowerCase().trim());
		let resultsArray = markers[0].filter((marker) => {
			let title = marker['title'].toLowerCase();
			let excerpt = marker['excerpt'].toLowerCase();
			let categories = JSON.stringify(marker['categories']).toLowerCase();
			//console.log(categories);
			return (title.includes(query) || excerpt.includes(query) || categories.includes(query));
		});
		//console.log('query> ' + resultsArray, query);
		let relevantResults = findRelevance(resultsArray, query);
		setResults(relevantResults);
	}

	return (
		<div className="popup">
			<div className="popup-inner">
				<div className="search popup-header no-bg">
					<a onClick={props.closePopup} className="popup-close">
						<IconClose />
					</a>
				</div>
				<div className="search popup-content">
					<IconSearchCircle />
					{props.searchQuery && results && results.length > 0 ? (
						<h2>Searching for "{props.searchQuery}"</h2>
					) : (
						<>
							<h2>No Results</h2>
							<p>Idaho is a lot of ground to cover (83,643.12 square miles, to be exact)! The item you searched for isn't on the map yet. Try another search term, or check out the recommended activities below.</p>

						</>
					)}
					{results && results.length > 0 && (
						<div className="search-recommendations">
							{results.map((result, i) => {
								return (
									<AdventureCard item={result} key={`search-result-${i}`} />
								)
							}
							)}
						</div>
					)}
					{!results || results.length === 0 && (
						<RecommendedActivities maxLength={3} />
					)}
				</div>
			</div>
		</div>
	);
}

export default SearchPopup;
