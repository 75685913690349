
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../../auth/firebase";
import { doc, getDoc } from "firebase/firestore";
import { MapContainer, TileLayer, ZoomControl, Marker, Popup, ScaleControl, Polyline } from 'react-leaflet';
import LogoWhite from "../../images/logo-white";
import AdventureCard from "../Cards/AdventureCard";
import PageNotFound from "../404/PageNotFound";
import Loading from "../Loading/Loading";

import {
    IconLegend
} from "../../images/icons";
import { getMarkerIcon } from "../Markers/markers";
import { useRef } from "react";

export const Itinerary = ({ id }) => {
    //map ref
    const mapRef = useRef();

    const [itinerary, setItinerary] = useState(null);
    const [firstLoad, setFirstLoad] = useState(false);
    const [loading, setLoading] = useState(true);
    const [itinExists, setItinExists] = useState(false);
    const [showDirections, setShowDirections] = useState(false);
    
    const handleResize = () => {
        if (mapRef.current) {
            setTimeout(() => mapRef.current.invalidateSize(), 300);
        }
    };

    useEffect(() => {
        if (firstLoad === false) {
            const fetchData = async () => {
                const itinRef = doc(firestore, "itineraries", id);
                const itineraryDoc = await getDoc(itinRef);
                if (itineraryDoc.data()) {
                    setItinerary(itineraryDoc.data());
                    setLoading(false);
                    setItinExists(true);
                } else {
                    setLoading(false);

                }
            }
            fetchData().catch(console.error);
            setFirstLoad(true);
            
            // Add event listener for window resize
            window.addEventListener('resize', handleResize);

            // Cleanup function to remove the event listener on component unmount
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    const toggleDirections = () => {
        setShowDirections(!showDirections);
    }

    if (loading) return <Loading />
    if (!itinExists) return <PageNotFound />

    return (
        (itinerary && itinerary !== undefined) &&
        <div id="travel-assistant" className="display-itinerary">
            <div className="main-content">
                <div className="filterBar">
                    <div className="vi-logo">
                        <LogoWhite />
                    </div>
                    <h1>{itinerary.name}</h1>
                    <div className="navLinks">
                        {/* we can add other functions here - save itinerary, edit itinerary, and view directions/details? */}
                        {itinerary.directions &&
                            <div className="itinerary-directions">
                                <a onClick={toggleDirections} className="nav-link">
                                    <span>View Directions</span>
                                    <IconLegend />
                                </a>
                            </div>}
                    </div>
                </div>
                <div className="map-wrapper">
                    <MapContainer className="vita-map" center={[44.39682515811082, -114.25539640892148]} ref={mapRef} zoom={6} zoomControl={false} scrollWheelZoom={true}>
                    <TileLayer
          attribution="&copy; <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>"
          url="https://api.mapbox.com/styles/v1/visitidaho/clexsrswm000301orvslvh1q2/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidmlzaXRpZGFobyIsImEiOiJjbDlveHd3dDkwZWNyM3dsYjJtemF5b3d6In0.Mz0XqoMjxh3PlAeXFGvkfw"/>
                        {
                            JSON.parse(itinerary.markers).length > 0 &&
                            JSON.parse(itinerary.markers).map((marker, index) => (
                                <Marker
                                    key={index}
                                    position={[
                                        marker.lat,
                                        marker.lng
                                    ]}
                                    data={marker}
                                    icon={getMarkerIcon(marker)}
                                    zIndexOffset={marker.is_selected ? 999 : 1}
                                >
                                    <Popup>
                                        <AdventureCard item={marker} />
                                    </Popup>
                                </Marker>
                            ))
                        }
                        <Marker
                            icon={L.divIcon({
                                html: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 104 104" style="enable-background:new 0 0 104 104; background:white;" xml:space="preserve">
                                        <path id="Icon_material-location-on" style="fill:#B7562D" d="M52,21.3c-11.8,0-21.5,9.6-21.5,21.4c0,0,0,0,0,0C30.5,58.9,52,82.7,52,82.7
                                            s21.5-23.8,21.5-39.9C73.5,31,63.9,21.3,52,21.3C52,21.3,52,21.3,52,21.3z M52,50.5c-4.2,0-7.7-3.4-7.7-7.7s3.4-7.7,7.7-7.7
                                            c4.2,0,7.7,3.4,7.7,7.7l0,0C59.7,47,56.2,50.5,52,50.5z"/>
                                        </svg>`,
                                className: "",
                                iconSize: [40, 40],
                                iconAnchor: [20, 40],
                            })}
                            position={[
                                JSON.parse(itinerary.starting_point)[0],
                                JSON.parse(itinerary.starting_point)[1]
                            ]}
                        ></Marker>
                        <Polyline positions={JSON.parse(itinerary.route)} color={'#D36841'} weight={5} />
                        <ScaleControl position="bottomright" />
                        <ZoomControl position="bottomright" />
                    </MapContainer>
                    {/* <div className="map-buttons">
                        <div className="helper-btns">
                            <MapLegend showLegend={showLegend} toggleLegend={toggleLegend.bind(this)} />
                        </div>
                    </div> */}
                </div>
            </div>

            {itinerary.directions &&
                <aside className={`side-panel ${showDirections ? 'show' : ''}`}>
                    <a onClick={toggleDirections} className="side-panel-mobile">
                        <span className="toggle-bar">
                            <span></span>
                        </span>
                        View Directions
                    </a>
                    <div className="side-panel-header">
                        <h2 className="sidebar-title">Directions</h2>
                    </div>
                    <div className="side-panel-content">
                        {JSON.parse(itinerary.directions).map((item, index) => {
                            return (
                                <div key={index}>
                                    <p className="direction-item">{index + 1}. {item.text}
                                        {item.distance > 0 ? item.distance + ((item.distance == 1) ? ' mile.' : ' miles.') : 'Less than 1 mile.'}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </aside>}
        </div>
    );

}


export const DisplayItinerary = () => {
    const params = useParams();
    //check if all variables are set from db
    return (
        <>
            <Itinerary id={params.id} />
        </>
    )
}