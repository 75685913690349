const StJoeRiver = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "St. Joe River Scenic Byway",
				description: "Tracing the cottonwood-lined St. Joe River, the St. Joe River Scenic Byway is a picturesque haven for birdwatchers and anglers. Bald eagles and osprey call the cottonwoods home and feast on fish from the waters below. Continuing along the byway, the crystal-clear waters become shallower and are ideal all sorts of trout species. Along the river's edge, keep an eye out for deer, elk, moose and bear.",
				link: "https://visitidaho.org/things-to-do/road-trips/st-joe-river-scenic-byway/",
				route: [
					"St Maries", 
					"Avery"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
          [
            -116.56247,
            47.31435,
            0
          ],
          [
            -116.56247,
            47.3147,
            0
          ],
          [
            -116.56246,
            47.31488,
            0
          ],
          [
            -116.56246,
            47.31569,
            0
          ],
          [
            -116.56243,
            47.31585,
            0
          ],
          [
            -116.56234,
            47.3161,
            0
          ],
          [
            -116.5623,
            47.31618,
            0
          ],
          [
            -116.56226,
            47.31628,
            0
          ],
          [
            -116.56219,
            47.31639,
            0
          ],
          [
            -116.56103,
            47.31783,
            0
          ],
          [
            -116.5609,
            47.31801,
            0
          ],
          [
            -116.56083,
            47.31813,
            0
          ],
          [
            -116.56078,
            47.31825,
            0
          ],
          [
            -116.56076,
            47.31838,
            0
          ],
          [
            -116.56076,
            47.31848,
            0
          ],
          [
            -116.56077,
            47.31852,
            0
          ],
          [
            -116.56078,
            47.3186,
            0
          ],
          [
            -116.56081,
            47.31866,
            0
          ],
          [
            -116.56083,
            47.31872,
            0
          ],
          [
            -116.56085,
            47.31876,
            0
          ],
          [
            -116.56096,
            47.3189,
            0
          ],
          [
            -116.56101,
            47.31895,
            0
          ],
          [
            -116.56108,
            47.31901,
            0
          ],
          [
            -116.56118,
            47.31907,
            0
          ],
          [
            -116.56122,
            47.3191,
            0
          ],
          [
            -116.56123,
            47.3191,
            0
          ],
          [
            -116.5613,
            47.31914,
            0
          ],
          [
            -116.56153,
            47.31923,
            0
          ],
          [
            -116.56188,
            47.31932,
            0
          ],
          [
            -116.5628,
            47.31951,
            0
          ],
          [
            -116.5632,
            47.31962,
            0
          ],
          [
            -116.56353,
            47.31973,
            0
          ],
          [
            -116.56374,
            47.31983,
            0
          ],
          [
            -116.5638,
            47.31985,
            0
          ],
          [
            -116.56425,
            47.32012,
            0
          ],
          [
            -116.56444,
            47.32025,
            0
          ],
          [
            -116.56472,
            47.32042,
            0
          ],
          [
            -116.56525,
            47.32078,
            0
          ],
          [
            -116.56588,
            47.32118,
            0
          ],
          [
            -116.56588,
            47.32191,
            0
          ],
          [
            -116.566,
            47.32505,
            0
          ],
          [
            -116.56599,
            47.32663,
            0
          ],
          [
            -116.56592,
            47.32806,
            0
          ],
          [
            -116.56587,
            47.32837,
            0
          ],
          [
            -116.56583,
            47.32879,
            0
          ],
          [
            -116.5658,
            47.32926,
            0
          ],
          [
            -116.56574,
            47.32985,
            0
          ],
          [
            -116.56568,
            47.33017,
            0
          ],
          [
            -116.56561,
            47.33045,
            0
          ],
          [
            -116.56551,
            47.33069,
            0
          ],
          [
            -116.56542,
            47.33086,
            0
          ],
          [
            -116.56523,
            47.33115,
            0
          ],
          [
            -116.56507,
            47.33136,
            0
          ],
          [
            -116.5648,
            47.33166,
            0
          ],
          [
            -116.56449,
            47.33193,
            0
          ],
          [
            -116.5639,
            47.33234,
            0
          ],
          [
            -116.55829,
            47.33595,
            0
          ],
          [
            -116.55796,
            47.33614,
            0
          ],
          [
            -116.55755,
            47.33632,
            0
          ],
          [
            -116.55745,
            47.33635,
            0
          ],
          [
            -116.55741,
            47.33637,
            0
          ],
          [
            -116.55697,
            47.33648,
            0
          ],
          [
            -116.55661,
            47.33653,
            0
          ],
          [
            -116.55623,
            47.33655,
            0
          ],
          [
            -116.55591,
            47.33655,
            0
          ],
          [
            -116.55575,
            47.33654,
            0
          ],
          [
            -116.55557,
            47.33654,
            0
          ],
          [
            -116.55352,
            47.33645,
            0
          ],
          [
            -116.55146,
            47.33639,
            0
          ],
          [
            -116.55092,
            47.33639,
            0
          ],
          [
            -116.54936,
            47.3365,
            0
          ],
          [
            -116.54877,
            47.33658,
            0
          ],
          [
            -116.54774,
            47.33677,
            0
          ],
          [
            -116.54755,
            47.33682,
            0
          ],
          [
            -116.54749,
            47.33683,
            0
          ],
          [
            -116.54299,
            47.33808,
            0
          ],
          [
            -116.54297,
            47.33809,
            0
          ],
          [
            -116.54217,
            47.33831,
            0
          ],
          [
            -116.54202,
            47.33836,
            0
          ],
          [
            -116.542,
            47.33837,
            0
          ],
          [
            -116.5418,
            47.33843,
            0
          ],
          [
            -116.5415,
            47.33854,
            0
          ],
          [
            -116.54111,
            47.33872,
            0
          ],
          [
            -116.54108,
            47.33873,
            0
          ],
          [
            -116.54095,
            47.3388,
            0
          ],
          [
            -116.5408,
            47.33887,
            0
          ],
          [
            -116.54068,
            47.33895,
            0
          ],
          [
            -116.54066,
            47.33895,
            0
          ],
          [
            -116.54048,
            47.33906,
            0
          ],
          [
            -116.54021,
            47.33925,
            0
          ],
          [
            -116.54007,
            47.33936,
            0
          ],
          [
            -116.53997,
            47.33943,
            0
          ],
          [
            -116.53983,
            47.33956,
            0
          ],
          [
            -116.53954,
            47.33979,
            0
          ],
          [
            -116.53892,
            47.34022,
            0
          ],
          [
            -116.53839,
            47.34049,
            0
          ],
          [
            -116.53803,
            47.34063,
            0
          ],
          [
            -116.53771,
            47.34073,
            0
          ],
          [
            -116.53722,
            47.34085,
            0
          ],
          [
            -116.53669,
            47.34094,
            0
          ],
          [
            -116.53619,
            47.34098,
            0
          ],
          [
            -116.53557,
            47.34098,
            0
          ],
          [
            -116.53527,
            47.34096,
            0
          ],
          [
            -116.53489,
            47.34092,
            0
          ],
          [
            -116.5347,
            47.34088,
            0
          ],
          [
            -116.5346,
            47.34087,
            0
          ],
          [
            -116.53429,
            47.3408,
            0
          ],
          [
            -116.53261,
            47.34036,
            0
          ],
          [
            -116.53251,
            47.34034,
            0
          ],
          [
            -116.53202,
            47.34021,
            0
          ],
          [
            -116.53152,
            47.3401,
            0
          ],
          [
            -116.53109,
            47.34004,
            0
          ],
          [
            -116.53067,
            47.34001,
            0
          ],
          [
            -116.53042,
            47.34001,
            0
          ],
          [
            -116.5303,
            47.34002,
            0
          ],
          [
            -116.5302,
            47.34002,
            0
          ],
          [
            -116.52998,
            47.34004,
            0
          ],
          [
            -116.52944,
            47.34012,
            0
          ],
          [
            -116.52891,
            47.34026,
            0
          ],
          [
            -116.528,
            47.34059,
            0
          ],
          [
            -116.5272,
            47.34092,
            0
          ],
          [
            -116.52716,
            47.34093,
            0
          ],
          [
            -116.52648,
            47.34121,
            0
          ],
          [
            -116.52643,
            47.34122,
            0
          ],
          [
            -116.52527,
            47.34168,
            0
          ],
          [
            -116.5246,
            47.34199,
            0
          ],
          [
            -116.52448,
            47.34206,
            0
          ],
          [
            -116.52436,
            47.34212,
            0
          ],
          [
            -116.52409,
            47.3423,
            0
          ],
          [
            -116.52407,
            47.34231,
            0
          ],
          [
            -116.5239,
            47.34245,
            0
          ],
          [
            -116.52367,
            47.34262,
            0
          ],
          [
            -116.52338,
            47.34286,
            0
          ],
          [
            -116.52331,
            47.34293,
            0
          ],
          [
            -116.52322,
            47.343,
            0
          ],
          [
            -116.52316,
            47.34306,
            0
          ],
          [
            -116.52301,
            47.34318,
            0
          ],
          [
            -116.52261,
            47.34343,
            0
          ],
          [
            -116.52215,
            47.34363,
            0
          ],
          [
            -116.52205,
            47.34366,
            0
          ],
          [
            -116.52197,
            47.34369,
            0
          ],
          [
            -116.52181,
            47.34373,
            0
          ],
          [
            -116.52175,
            47.34374,
            0
          ],
          [
            -116.52175,
            47.34375,
            0
          ],
          [
            -116.52123,
            47.34385,
            0
          ],
          [
            -116.52111,
            47.34388,
            0
          ],
          [
            -116.51795,
            47.34444,
            0
          ],
          [
            -116.51764,
            47.34448,
            0
          ],
          [
            -116.51733,
            47.3445,
            0
          ],
          [
            -116.51692,
            47.3445,
            0
          ],
          [
            -116.51652,
            47.34446,
            0
          ],
          [
            -116.51644,
            47.34444,
            0
          ],
          [
            -116.51622,
            47.3444,
            0
          ],
          [
            -116.51613,
            47.34437,
            0
          ],
          [
            -116.51586,
            47.3443,
            0
          ],
          [
            -116.51577,
            47.34426,
            0
          ],
          [
            -116.51568,
            47.34423,
            0
          ],
          [
            -116.51535,
            47.34407,
            0
          ],
          [
            -116.51504,
            47.34388,
            0
          ],
          [
            -116.51177,
            47.34167,
            0
          ],
          [
            -116.51131,
            47.34134,
            0
          ],
          [
            -116.51035,
            47.3407,
            0
          ],
          [
            -116.51017,
            47.3406,
            0
          ],
          [
            -116.51003,
            47.34051,
            0
          ],
          [
            -116.50987,
            47.34042,
            0
          ],
          [
            -116.5095,
            47.34025,
            0
          ],
          [
            -116.50898,
            47.34005,
            0
          ],
          [
            -116.50884,
            47.34001,
            0
          ],
          [
            -116.5087,
            47.33996,
            0
          ],
          [
            -116.50827,
            47.33984,
            0
          ],
          [
            -116.5081,
            47.3398,
            0
          ],
          [
            -116.50745,
            47.33969,
            0
          ],
          [
            -116.50706,
            47.33965,
            0
          ],
          [
            -116.5064,
            47.33962,
            0
          ],
          [
            -116.50596,
            47.33963,
            0
          ],
          [
            -116.50568,
            47.33965,
            0
          ],
          [
            -116.50551,
            47.33967,
            0
          ],
          [
            -116.50533,
            47.33968,
            0
          ],
          [
            -116.50447,
            47.33982,
            0
          ],
          [
            -116.50382,
            47.33997,
            0
          ],
          [
            -116.50324,
            47.34013,
            0
          ],
          [
            -116.50308,
            47.34019,
            0
          ],
          [
            -116.50279,
            47.34032,
            0
          ],
          [
            -116.50264,
            47.34041,
            0
          ],
          [
            -116.50251,
            47.3405,
            0
          ],
          [
            -116.50232,
            47.34066,
            0
          ],
          [
            -116.50228,
            47.34071,
            0
          ],
          [
            -116.50224,
            47.34075,
            0
          ],
          [
            -116.50211,
            47.34091,
            0
          ],
          [
            -116.50202,
            47.34107,
            0
          ],
          [
            -116.502,
            47.34112,
            0
          ],
          [
            -116.50193,
            47.34136,
            0
          ],
          [
            -116.50191,
            47.3415,
            0
          ],
          [
            -116.50191,
            47.34157,
            0
          ],
          [
            -116.50192,
            47.34166,
            0
          ],
          [
            -116.50192,
            47.3417,
            0
          ],
          [
            -116.50195,
            47.34185,
            0
          ],
          [
            -116.50197,
            47.34192,
            0
          ],
          [
            -116.50203,
            47.34208,
            0
          ],
          [
            -116.50218,
            47.34242,
            0
          ],
          [
            -116.50224,
            47.34261,
            0
          ],
          [
            -116.50225,
            47.34269,
            0
          ],
          [
            -116.50225,
            47.34282,
            0
          ],
          [
            -116.50223,
            47.34295,
            0
          ],
          [
            -116.50219,
            47.34308,
            0
          ],
          [
            -116.50212,
            47.3432,
            0
          ],
          [
            -116.50204,
            47.34331,
            0
          ],
          [
            -116.50201,
            47.34334,
            0
          ],
          [
            -116.50198,
            47.34338,
            0
          ],
          [
            -116.50194,
            47.34341,
            0
          ],
          [
            -116.50191,
            47.34344,
            0
          ],
          [
            -116.50178,
            47.34355,
            0
          ],
          [
            -116.50176,
            47.34356,
            0
          ],
          [
            -116.50146,
            47.34376,
            0
          ],
          [
            -116.49889,
            47.34532,
            0
          ],
          [
            -116.4988,
            47.34536,
            0
          ],
          [
            -116.49853,
            47.3455,
            0
          ],
          [
            -116.49844,
            47.34554,
            0
          ],
          [
            -116.49817,
            47.34564,
            0
          ],
          [
            -116.49791,
            47.3457,
            0
          ],
          [
            -116.49771,
            47.34573,
            0
          ],
          [
            -116.49768,
            47.34573,
            0
          ],
          [
            -116.49749,
            47.34575,
            0
          ],
          [
            -116.49713,
            47.34575,
            0
          ],
          [
            -116.49699,
            47.34574,
            0
          ],
          [
            -116.49693,
            47.34574,
            0
          ],
          [
            -116.49662,
            47.3457,
            0
          ],
          [
            -116.4963,
            47.34564,
            0
          ],
          [
            -116.49616,
            47.3456,
            0
          ],
          [
            -116.4945,
            47.34521,
            0
          ],
          [
            -116.4938,
            47.34502,
            0
          ],
          [
            -116.49332,
            47.34486,
            0
          ],
          [
            -116.49311,
            47.34478,
            0
          ],
          [
            -116.49256,
            47.34454,
            0
          ],
          [
            -116.49209,
            47.3443,
            0
          ],
          [
            -116.49201,
            47.34425,
            0
          ],
          [
            -116.49186,
            47.34417,
            0
          ],
          [
            -116.49166,
            47.34405,
            0
          ],
          [
            -116.49146,
            47.34391,
            0
          ],
          [
            -116.49124,
            47.34377,
            0
          ],
          [
            -116.49063,
            47.34334,
            0
          ],
          [
            -116.49035,
            47.34313,
            0
          ],
          [
            -116.49011,
            47.3429,
            0
          ],
          [
            -116.49007,
            47.34285,
            0
          ],
          [
            -116.48998,
            47.34276,
            0
          ],
          [
            -116.48991,
            47.34267,
            0
          ],
          [
            -116.48983,
            47.34258,
            0
          ],
          [
            -116.48976,
            47.34248,
            0
          ],
          [
            -116.48962,
            47.34225,
            0
          ],
          [
            -116.48942,
            47.34188,
            0
          ],
          [
            -116.48923,
            47.34149,
            0
          ],
          [
            -116.4892,
            47.34144,
            0
          ],
          [
            -116.48913,
            47.34128,
            0
          ],
          [
            -116.48911,
            47.34119,
            0
          ],
          [
            -116.48908,
            47.34112,
            0
          ],
          [
            -116.48897,
            47.34076,
            0
          ],
          [
            -116.48895,
            47.34067,
            0
          ],
          [
            -116.48884,
            47.34043,
            0
          ],
          [
            -116.48874,
            47.34026,
            0
          ],
          [
            -116.48865,
            47.34014,
            0
          ],
          [
            -116.48864,
            47.34011,
            0
          ],
          [
            -116.48854,
            47.33998,
            0
          ],
          [
            -116.48841,
            47.33984,
            0
          ],
          [
            -116.48838,
            47.3398,
            0
          ],
          [
            -116.4883,
            47.33972,
            0
          ],
          [
            -116.48799,
            47.33944,
            0
          ],
          [
            -116.4879,
            47.33937,
            0
          ],
          [
            -116.48789,
            47.33935,
            0
          ],
          [
            -116.4875,
            47.33908,
            0
          ],
          [
            -116.48701,
            47.33881,
            0
          ],
          [
            -116.48672,
            47.33868,
            0
          ],
          [
            -116.48623,
            47.3385,
            0
          ],
          [
            -116.48607,
            47.33846,
            0
          ],
          [
            -116.48585,
            47.33839,
            0
          ],
          [
            -116.48564,
            47.33834,
            0
          ],
          [
            -116.48553,
            47.33832,
            0
          ],
          [
            -116.48537,
            47.33828,
            0
          ],
          [
            -116.48505,
            47.33823,
            0
          ],
          [
            -116.48479,
            47.3382,
            0
          ],
          [
            -116.48471,
            47.3382,
            0
          ],
          [
            -116.48452,
            47.33818,
            0
          ],
          [
            -116.48427,
            47.33817,
            0
          ],
          [
            -116.48385,
            47.33817,
            0
          ],
          [
            -116.4837,
            47.33818,
            0
          ],
          [
            -116.48362,
            47.33818,
            0
          ],
          [
            -116.48322,
            47.33821,
            0
          ],
          [
            -116.4826,
            47.3383,
            0
          ],
          [
            -116.47848,
            47.339,
            0
          ],
          [
            -116.47835,
            47.33903,
            0
          ],
          [
            -116.47105,
            47.34028,
            0
          ],
          [
            -116.47053,
            47.34038,
            0
          ],
          [
            -116.47013,
            47.34047,
            0
          ],
          [
            -116.46938,
            47.34069,
            0
          ],
          [
            -116.46873,
            47.34094,
            0
          ],
          [
            -116.46818,
            47.3412,
            0
          ],
          [
            -116.4675,
            47.34157,
            0
          ],
          [
            -116.46728,
            47.3417,
            0
          ],
          [
            -116.46705,
            47.34182,
            0
          ],
          [
            -116.4667,
            47.34198,
            0
          ],
          [
            -116.46633,
            47.34212,
            0
          ],
          [
            -116.46584,
            47.34226,
            0
          ],
          [
            -116.46507,
            47.34241,
            0
          ],
          [
            -116.46499,
            47.34243,
            0
          ],
          [
            -116.46304,
            47.34274,
            0
          ],
          [
            -116.46239,
            47.34281,
            0
          ],
          [
            -116.46222,
            47.34282,
            0
          ],
          [
            -116.46183,
            47.34282,
            0
          ],
          [
            -116.46169,
            47.34281,
            0
          ],
          [
            -116.46161,
            47.34281,
            0
          ],
          [
            -116.4613,
            47.34279,
            0
          ],
          [
            -116.46089,
            47.34274,
            0
          ],
          [
            -116.46051,
            47.34267,
            0
          ],
          [
            -116.46018,
            47.34259,
            0
          ],
          [
            -116.45971,
            47.34243,
            0
          ],
          [
            -116.45967,
            47.34242,
            0
          ],
          [
            -116.45856,
            47.34201,
            0
          ],
          [
            -116.45832,
            47.34191,
            0
          ],
          [
            -116.45791,
            47.34171,
            0
          ],
          [
            -116.45774,
            47.3416,
            0
          ],
          [
            -116.45733,
            47.34128,
            0
          ],
          [
            -116.45712,
            47.34109,
            0
          ],
          [
            -116.45691,
            47.34083,
            0
          ],
          [
            -116.45564,
            47.33901,
            0
          ],
          [
            -116.45534,
            47.33847,
            0
          ],
          [
            -116.45521,
            47.3382,
            0
          ],
          [
            -116.45516,
            47.33808,
            0
          ],
          [
            -116.45504,
            47.3376,
            0
          ],
          [
            -116.45502,
            47.33713,
            0
          ],
          [
            -116.45501,
            47.33705,
            0
          ],
          [
            -116.45505,
            47.33679,
            0
          ],
          [
            -116.45505,
            47.33677,
            0
          ],
          [
            -116.45522,
            47.33603,
            0
          ],
          [
            -116.45615,
            47.33417,
            0
          ],
          [
            -116.45625,
            47.33385,
            0
          ],
          [
            -116.45627,
            47.33373,
            0
          ],
          [
            -116.45627,
            47.33358,
            0
          ],
          [
            -116.45626,
            47.33346,
            0
          ],
          [
            -116.45621,
            47.3333,
            0
          ],
          [
            -116.45616,
            47.33319,
            0
          ],
          [
            -116.456,
            47.33295,
            0
          ],
          [
            -116.45579,
            47.33273,
            0
          ],
          [
            -116.45559,
            47.33255,
            0
          ],
          [
            -116.45545,
            47.33241,
            0
          ],
          [
            -116.45512,
            47.33215,
            0
          ],
          [
            -116.45505,
            47.33211,
            0
          ],
          [
            -116.45497,
            47.33205,
            0
          ],
          [
            -116.45485,
            47.33199,
            0
          ],
          [
            -116.45484,
            47.33199,
            0
          ],
          [
            -116.4547,
            47.33192,
            0
          ],
          [
            -116.45455,
            47.33186,
            0
          ],
          [
            -116.45428,
            47.33178,
            0
          ],
          [
            -116.45409,
            47.33175,
            0
          ],
          [
            -116.45388,
            47.33173,
            0
          ],
          [
            -116.45362,
            47.33172,
            0
          ],
          [
            -116.45323,
            47.33173,
            0
          ],
          [
            -116.45291,
            47.33175,
            0
          ],
          [
            -116.4525,
            47.33175,
            0
          ],
          [
            -116.4522,
            47.33172,
            0
          ],
          [
            -116.45202,
            47.33169,
            0
          ],
          [
            -116.45179,
            47.33164,
            0
          ],
          [
            -116.44729,
            47.33027,
            0
          ],
          [
            -116.44621,
            47.32989,
            0
          ],
          [
            -116.44615,
            47.32986,
            0
          ],
          [
            -116.44565,
            47.32968,
            0
          ],
          [
            -116.44523,
            47.32955,
            0
          ],
          [
            -116.44483,
            47.32945,
            0
          ],
          [
            -116.44445,
            47.32939,
            0
          ],
          [
            -116.44371,
            47.32933,
            0
          ],
          [
            -116.44346,
            47.32933,
            0
          ],
          [
            -116.44303,
            47.32936,
            0
          ],
          [
            -116.44252,
            47.32943,
            0
          ],
          [
            -116.44214,
            47.32951,
            0
          ],
          [
            -116.43983,
            47.33006,
            0
          ],
          [
            -116.4377,
            47.3307,
            0
          ],
          [
            -116.43704,
            47.33087,
            0
          ],
          [
            -116.43659,
            47.33094,
            0
          ],
          [
            -116.43599,
            47.33101,
            0
          ],
          [
            -116.43243,
            47.33124,
            0
          ],
          [
            -116.43216,
            47.33127,
            0
          ],
          [
            -116.43177,
            47.33135,
            0
          ],
          [
            -116.43077,
            47.33164,
            0
          ],
          [
            -116.43034,
            47.33174,
            0
          ],
          [
            -116.43014,
            47.33177,
            0
          ],
          [
            -116.42967,
            47.33182,
            0
          ],
          [
            -116.42943,
            47.33182,
            0
          ],
          [
            -116.42939,
            47.33181,
            0
          ],
          [
            -116.42893,
            47.33178,
            0
          ],
          [
            -116.42829,
            47.33165,
            0
          ],
          [
            -116.42765,
            47.3315,
            0
          ],
          [
            -116.42688,
            47.33135,
            0
          ],
          [
            -116.42665,
            47.33132,
            0
          ],
          [
            -116.42657,
            47.33132,
            0
          ],
          [
            -116.42644,
            47.3313,
            0
          ],
          [
            -116.4218,
            47.33116,
            0
          ],
          [
            -116.42024,
            47.33107,
            0
          ],
          [
            -116.4189,
            47.33097,
            0
          ],
          [
            -116.41533,
            47.33064,
            0
          ],
          [
            -116.41397,
            47.33048,
            0
          ],
          [
            -116.41172,
            47.33007,
            0
          ],
          [
            -116.41115,
            47.32999,
            0
          ],
          [
            -116.41034,
            47.32991,
            0
          ],
          [
            -116.40999,
            47.32989,
            0
          ],
          [
            -116.4091,
            47.32987,
            0
          ],
          [
            -116.40832,
            47.32988,
            0
          ],
          [
            -116.40446,
            47.33013,
            0
          ],
          [
            -116.40394,
            47.33014,
            0
          ],
          [
            -116.40355,
            47.33012,
            0
          ],
          [
            -116.40276,
            47.33004,
            0
          ],
          [
            -116.40206,
            47.3299,
            0
          ],
          [
            -116.39861,
            47.32914,
            0
          ],
          [
            -116.3983,
            47.32905,
            0
          ],
          [
            -116.39775,
            47.32886,
            0
          ],
          [
            -116.39761,
            47.3288,
            0
          ],
          [
            -116.39755,
            47.32878,
            0
          ],
          [
            -116.39705,
            47.32853,
            0
          ],
          [
            -116.39633,
            47.32808,
            0
          ],
          [
            -116.39444,
            47.3266,
            0
          ],
          [
            -116.39394,
            47.32629,
            0
          ],
          [
            -116.39391,
            47.32628,
            0
          ],
          [
            -116.39341,
            47.32603,
            0
          ],
          [
            -116.39316,
            47.32594,
            0
          ],
          [
            -116.39104,
            47.32507,
            0
          ],
          [
            -116.39098,
            47.32504,
            0
          ],
          [
            -116.38906,
            47.32428,
            0
          ],
          [
            -116.38874,
            47.32418,
            0
          ],
          [
            -116.3885,
            47.32412,
            0
          ],
          [
            -116.38816,
            47.32406,
            0
          ],
          [
            -116.38796,
            47.32404,
            0
          ],
          [
            -116.38775,
            47.32403,
            0
          ],
          [
            -116.38713,
            47.32403,
            0
          ],
          [
            -116.37669,
            47.32442,
            0
          ],
          [
            -116.37607,
            47.32442,
            0
          ],
          [
            -116.37557,
            47.3244,
            0
          ],
          [
            -116.37518,
            47.32437,
            0
          ],
          [
            -116.37474,
            47.32429,
            0
          ],
          [
            -116.37453,
            47.32426,
            0
          ],
          [
            -116.37414,
            47.32417,
            0
          ],
          [
            -116.37393,
            47.32411,
            0
          ],
          [
            -116.37344,
            47.32395,
            0
          ],
          [
            -116.37289,
            47.32373,
            0
          ],
          [
            -116.37243,
            47.32351,
            0
          ],
          [
            -116.37075,
            47.32264,
            0
          ],
          [
            -116.37067,
            47.32259,
            0
          ],
          [
            -116.36858,
            47.3215,
            0
          ],
          [
            -116.36812,
            47.32129,
            0
          ],
          [
            -116.36773,
            47.32119,
            0
          ],
          [
            -116.3675,
            47.32116,
            0
          ],
          [
            -116.3672,
            47.32115,
            0
          ],
          [
            -116.36694,
            47.32118,
            0
          ],
          [
            -116.36678,
            47.32121,
            0
          ],
          [
            -116.36661,
            47.32126,
            0
          ],
          [
            -116.36644,
            47.32132,
            0
          ],
          [
            -116.36623,
            47.32141,
            0
          ],
          [
            -116.36598,
            47.32156,
            0
          ],
          [
            -116.36573,
            47.32169,
            0
          ],
          [
            -116.36401,
            47.3227,
            0
          ],
          [
            -116.36361,
            47.32292,
            0
          ],
          [
            -116.36269,
            47.32346,
            0
          ],
          [
            -116.36248,
            47.32357,
            0
          ],
          [
            -116.36187,
            47.32392,
            0
          ],
          [
            -116.36144,
            47.32412,
            0
          ],
          [
            -116.36107,
            47.32425,
            0
          ],
          [
            -116.36057,
            47.32438,
            0
          ],
          [
            -116.36011,
            47.32446,
            0
          ],
          [
            -116.35944,
            47.32451,
            0
          ],
          [
            -116.35434,
            47.32454,
            0
          ],
          [
            -116.3537,
            47.32457,
            0
          ],
          [
            -116.35317,
            47.32462,
            0
          ],
          [
            -116.35293,
            47.32466,
            0
          ],
          [
            -116.35123,
            47.32509,
            0
          ],
          [
            -116.3512,
            47.32509,
            0
          ],
          [
            -116.34966,
            47.32548,
            0
          ],
          [
            -116.34916,
            47.32558,
            0
          ],
          [
            -116.3488,
            47.32564,
            0
          ],
          [
            -116.34773,
            47.32575,
            0
          ],
          [
            -116.34595,
            47.32584,
            0
          ],
          [
            -116.34496,
            47.32591,
            0
          ],
          [
            -116.34426,
            47.32599,
            0
          ],
          [
            -116.34368,
            47.32608,
            0
          ],
          [
            -116.34339,
            47.32614,
            0
          ],
          [
            -116.34306,
            47.32619,
            0
          ],
          [
            -116.34304,
            47.3262,
            0
          ],
          [
            -116.34201,
            47.32638,
            0
          ],
          [
            -116.34135,
            47.32648,
            0
          ],
          [
            -116.34123,
            47.32649,
            0
          ],
          [
            -116.3409,
            47.32654,
            0
          ],
          [
            -116.3404,
            47.32658,
            0
          ],
          [
            -116.33975,
            47.32658,
            0
          ],
          [
            -116.33925,
            47.32655,
            0
          ],
          [
            -116.33878,
            47.32649,
            0
          ],
          [
            -116.33828,
            47.3264,
            0
          ],
          [
            -116.33775,
            47.32628,
            0
          ],
          [
            -116.33537,
            47.32565,
            0
          ],
          [
            -116.33523,
            47.32562,
            0
          ],
          [
            -116.33417,
            47.32532,
            0
          ],
          [
            -116.33208,
            47.32468,
            0
          ],
          [
            -116.3313,
            47.3244,
            0
          ],
          [
            -116.3309,
            47.32427,
            0
          ],
          [
            -116.32945,
            47.32373,
            0
          ],
          [
            -116.32925,
            47.32364,
            0
          ],
          [
            -116.32919,
            47.32362,
            0
          ],
          [
            -116.3262,
            47.32241,
            0
          ],
          [
            -116.32526,
            47.3221,
            0
          ],
          [
            -116.32442,
            47.32188,
            0
          ],
          [
            -116.32297,
            47.32157,
            0
          ],
          [
            -116.31533,
            47.3203,
            0
          ],
          [
            -116.31465,
            47.32021,
            0
          ],
          [
            -116.31192,
            47.32001,
            0
          ],
          [
            -116.31032,
            47.31993,
            0
          ],
          [
            -116.30972,
            47.31992,
            0
          ],
          [
            -116.30926,
            47.31994,
            0
          ],
          [
            -116.30835,
            47.32003,
            0
          ],
          [
            -116.30804,
            47.32007,
            0
          ],
          [
            -116.30771,
            47.32009,
            0
          ],
          [
            -116.30759,
            47.32009,
            0
          ],
          [
            -116.30746,
            47.3201,
            0
          ],
          [
            -116.3073,
            47.3201,
            0
          ],
          [
            -116.3072,
            47.32009,
            0
          ],
          [
            -116.30709,
            47.32009,
            0
          ],
          [
            -116.30687,
            47.32007,
            0
          ],
          [
            -116.30677,
            47.32005,
            0
          ],
          [
            -116.30666,
            47.32004,
            0
          ],
          [
            -116.30513,
            47.31972,
            0
          ],
          [
            -116.30471,
            47.31966,
            0
          ],
          [
            -116.30433,
            47.31963,
            0
          ],
          [
            -116.30403,
            47.31963,
            0
          ],
          [
            -116.3036,
            47.31966,
            0
          ],
          [
            -116.30057,
            47.32,
            0
          ],
          [
            -116.30031,
            47.32002,
            0
          ],
          [
            -116.29952,
            47.32011,
            0
          ],
          [
            -116.29896,
            47.32019,
            0
          ],
          [
            -116.29868,
            47.32025,
            0
          ],
          [
            -116.29826,
            47.32037,
            0
          ],
          [
            -116.29826,
            47.32038,
            0
          ],
          [
            -116.29815,
            47.32041,
            0
          ],
          [
            -116.29783,
            47.32055,
            0
          ],
          [
            -116.29758,
            47.32069,
            0
          ],
          [
            -116.29742,
            47.32079,
            0
          ],
          [
            -116.29715,
            47.321,
            0
          ],
          [
            -116.29691,
            47.32124,
            0
          ],
          [
            -116.29678,
            47.32139,
            0
          ],
          [
            -116.29589,
            47.32259,
            0
          ],
          [
            -116.29574,
            47.32277,
            0
          ],
          [
            -116.29565,
            47.32289,
            0
          ],
          [
            -116.29552,
            47.32301,
            0
          ],
          [
            -116.29538,
            47.32312,
            0
          ],
          [
            -116.29521,
            47.32321,
            0
          ],
          [
            -116.29503,
            47.32328,
            0
          ],
          [
            -116.29502,
            47.32328,
            0
          ],
          [
            -116.29483,
            47.32334,
            0
          ],
          [
            -116.29462,
            47.32338,
            0
          ],
          [
            -116.29442,
            47.3234,
            0
          ],
          [
            -116.29392,
            47.3234,
            0
          ],
          [
            -116.29282,
            47.32335,
            0
          ],
          [
            -116.2923,
            47.32327,
            0
          ],
          [
            -116.29229,
            47.32327,
            0
          ],
          [
            -116.29204,
            47.32322,
            0
          ],
          [
            -116.2915,
            47.32307,
            0
          ],
          [
            -116.28912,
            47.32221,
            0
          ],
          [
            -116.28904,
            47.32219,
            0
          ],
          [
            -116.28879,
            47.32211,
            0
          ],
          [
            -116.2883,
            47.322,
            0
          ],
          [
            -116.28823,
            47.32199,
            0
          ],
          [
            -116.28317,
            47.32097,
            0
          ],
          [
            -116.28296,
            47.32092,
            0
          ],
          [
            -116.28235,
            47.3208,
            0
          ],
          [
            -116.28219,
            47.32076,
            0
          ],
          [
            -116.28167,
            47.3206,
            0
          ],
          [
            -116.28095,
            47.32028,
            0
          ],
          [
            -116.28061,
            47.32011,
            0
          ],
          [
            -116.28037,
            47.31997,
            0
          ],
          [
            -116.28014,
            47.31981,
            0
          ],
          [
            -116.28001,
            47.3197,
            0
          ],
          [
            -116.27983,
            47.31952,
            0
          ],
          [
            -116.27974,
            47.31939,
            0
          ],
          [
            -116.27966,
            47.31926,
            0
          ],
          [
            -116.2796,
            47.31912,
            0
          ],
          [
            -116.27946,
            47.31872,
            0
          ],
          [
            -116.27941,
            47.3186,
            0
          ],
          [
            -116.27932,
            47.31843,
            0
          ],
          [
            -116.27926,
            47.31834,
            0
          ],
          [
            -116.27918,
            47.31824,
            0
          ],
          [
            -116.27915,
            47.31822,
            0
          ],
          [
            -116.27905,
            47.31812,
            0
          ],
          [
            -116.27891,
            47.31802,
            0
          ],
          [
            -116.27876,
            47.31793,
            0
          ],
          [
            -116.27857,
            47.31783,
            0
          ],
          [
            -116.27836,
            47.31776,
            0
          ],
          [
            -116.27807,
            47.31768,
            0
          ],
          [
            -116.27237,
            47.31642,
            0
          ],
          [
            -116.27182,
            47.31625,
            0
          ],
          [
            -116.27156,
            47.31612,
            0
          ],
          [
            -116.27131,
            47.31596,
            0
          ],
          [
            -116.27123,
            47.3159,
            0
          ],
          [
            -116.27112,
            47.3158,
            0
          ],
          [
            -116.27099,
            47.31566,
            0
          ],
          [
            -116.27088,
            47.31552,
            0
          ],
          [
            -116.27077,
            47.31534,
            0
          ],
          [
            -116.27072,
            47.31527,
            0
          ],
          [
            -116.27048,
            47.31483,
            0
          ],
          [
            -116.27027,
            47.31448,
            0
          ],
          [
            -116.27008,
            47.31413,
            0
          ],
          [
            -116.27001,
            47.31398,
            0
          ],
          [
            -116.26992,
            47.31369,
            0
          ],
          [
            -116.2699,
            47.31355,
            0
          ],
          [
            -116.2699,
            47.31338,
            0
          ],
          [
            -116.26991,
            47.31324,
            0
          ],
          [
            -116.26995,
            47.31303,
            0
          ],
          [
            -116.27002,
            47.31285,
            0
          ],
          [
            -116.27012,
            47.31268,
            0
          ],
          [
            -116.27023,
            47.31253,
            0
          ],
          [
            -116.27023,
            47.31252,
            0
          ],
          [
            -116.27031,
            47.31243,
            0
          ],
          [
            -116.27052,
            47.31222,
            0
          ],
          [
            -116.27071,
            47.31206,
            0
          ],
          [
            -116.27118,
            47.31174,
            0
          ],
          [
            -116.2729,
            47.31051,
            0
          ],
          [
            -116.27362,
            47.31002,
            0
          ],
          [
            -116.27363,
            47.31001,
            0
          ],
          [
            -116.27418,
            47.3096,
            0
          ],
          [
            -116.27445,
            47.30935,
            0
          ],
          [
            -116.27465,
            47.30915,
            0
          ],
          [
            -116.27472,
            47.30906,
            0
          ],
          [
            -116.2748,
            47.30897,
            0
          ],
          [
            -116.275,
            47.30869,
            0
          ],
          [
            -116.2752,
            47.30836,
            0
          ],
          [
            -116.27521,
            47.30835,
            0
          ],
          [
            -116.2753,
            47.30815,
            0
          ],
          [
            -116.27539,
            47.30792,
            0
          ],
          [
            -116.27552,
            47.3075,
            0
          ],
          [
            -116.27571,
            47.30681,
            0
          ],
          [
            -116.27576,
            47.30654,
            0
          ],
          [
            -116.27577,
            47.30638,
            0
          ],
          [
            -116.27577,
            47.30619,
            0
          ],
          [
            -116.27575,
            47.3059,
            0
          ],
          [
            -116.27571,
            47.30571,
            0
          ],
          [
            -116.27569,
            47.30558,
            0
          ],
          [
            -116.27562,
            47.30535,
            0
          ],
          [
            -116.27528,
            47.30458,
            0
          ],
          [
            -116.27507,
            47.30415,
            0
          ],
          [
            -116.27496,
            47.3039,
            0
          ],
          [
            -116.27379,
            47.30152,
            0
          ],
          [
            -116.27363,
            47.30124,
            0
          ],
          [
            -116.27339,
            47.3009,
            0
          ],
          [
            -116.27316,
            47.30062,
            0
          ],
          [
            -116.27272,
            47.30017,
            0
          ],
          [
            -116.26944,
            47.29723,
            0
          ],
          [
            -116.26887,
            47.29679,
            0
          ],
          [
            -116.26852,
            47.29655,
            0
          ],
          [
            -116.26848,
            47.29653,
            0
          ],
          [
            -116.26833,
            47.29643,
            0
          ],
          [
            -116.26832,
            47.29642,
            0
          ],
          [
            -116.26715,
            47.29563,
            0
          ],
          [
            -116.26589,
            47.29471,
            0
          ],
          [
            -116.26462,
            47.29385,
            0
          ],
          [
            -116.26435,
            47.29365,
            0
          ],
          [
            -116.26417,
            47.29348,
            0
          ],
          [
            -116.26398,
            47.29323,
            0
          ],
          [
            -116.26388,
            47.29307,
            0
          ],
          [
            -116.26382,
            47.29295,
            0
          ],
          [
            -116.2638,
            47.29289,
            0
          ],
          [
            -116.26379,
            47.29288,
            0
          ],
          [
            -116.26376,
            47.2928,
            0
          ],
          [
            -116.26374,
            47.29273,
            0
          ],
          [
            -116.26374,
            47.29272,
            0
          ],
          [
            -116.26372,
            47.29265,
            0
          ],
          [
            -116.26371,
            47.29258,
            0
          ],
          [
            -116.26371,
            47.29226,
            0
          ],
          [
            -116.264,
            47.28961,
            0
          ],
          [
            -116.26404,
            47.28933,
            0
          ],
          [
            -116.26406,
            47.28927,
            0
          ],
          [
            -116.26408,
            47.28918,
            0
          ],
          [
            -116.26416,
            47.28893,
            0
          ],
          [
            -116.26471,
            47.28753,
            0
          ],
          [
            -116.26486,
            47.28707,
            0
          ],
          [
            -116.26498,
            47.28655,
            0
          ],
          [
            -116.26526,
            47.28551,
            0
          ],
          [
            -116.26528,
            47.28538,
            0
          ],
          [
            -116.26528,
            47.28537,
            0
          ],
          [
            -116.2653,
            47.28525,
            0
          ],
          [
            -116.26531,
            47.28515,
            0
          ],
          [
            -116.26531,
            47.28512,
            0
          ],
          [
            -116.26533,
            47.28496,
            0
          ],
          [
            -116.26533,
            47.28474,
            0
          ],
          [
            -116.26531,
            47.28452,
            0
          ],
          [
            -116.26523,
            47.28412,
            0
          ],
          [
            -116.26517,
            47.28392,
            0
          ],
          [
            -116.26511,
            47.28376,
            0
          ],
          [
            -116.26492,
            47.28337,
            0
          ],
          [
            -116.26483,
            47.28321,
            0
          ],
          [
            -116.2648,
            47.28318,
            0
          ],
          [
            -116.26465,
            47.28298,
            0
          ],
          [
            -116.26453,
            47.28286,
            0
          ],
          [
            -116.2645,
            47.28282,
            0
          ],
          [
            -116.26424,
            47.2826,
            0
          ],
          [
            -116.26387,
            47.28234,
            0
          ],
          [
            -116.26372,
            47.28225,
            0
          ],
          [
            -116.26351,
            47.28215,
            0
          ],
          [
            -116.2635,
            47.28215,
            0
          ],
          [
            -116.26331,
            47.28207,
            0
          ],
          [
            -116.26321,
            47.28204,
            0
          ],
          [
            -116.26312,
            47.282,
            0
          ],
          [
            -116.26304,
            47.28198,
            0
          ],
          [
            -116.26303,
            47.28198,
            0
          ],
          [
            -116.26289,
            47.28193,
            0
          ],
          [
            -116.26279,
            47.28191,
            0
          ],
          [
            -116.26269,
            47.28188,
            0
          ],
          [
            -116.26255,
            47.28185,
            0
          ],
          [
            -116.26254,
            47.28185,
            0
          ],
          [
            -116.26246,
            47.28183,
            0
          ],
          [
            -116.26236,
            47.28182,
            0
          ],
          [
            -116.26222,
            47.28179,
            0
          ],
          [
            -116.26207,
            47.28178,
            0
          ],
          [
            -116.26203,
            47.28177,
            0
          ],
          [
            -116.26196,
            47.28177,
            0
          ],
          [
            -116.26174,
            47.28175,
            0
          ],
          [
            -116.26139,
            47.28175,
            0
          ],
          [
            -116.25948,
            47.28192,
            0
          ],
          [
            -116.25926,
            47.28193,
            0
          ],
          [
            -116.25872,
            47.28192,
            0
          ],
          [
            -116.25851,
            47.2819,
            0
          ],
          [
            -116.25808,
            47.28183,
            0
          ],
          [
            -116.25752,
            47.28168,
            0
          ],
          [
            -116.25751,
            47.28167,
            0
          ],
          [
            -116.25734,
            47.28161,
            0
          ],
          [
            -116.25704,
            47.28147,
            0
          ],
          [
            -116.25629,
            47.28107,
            0
          ],
          [
            -116.25606,
            47.28096,
            0
          ],
          [
            -116.256,
            47.28094,
            0
          ],
          [
            -116.25588,
            47.28088,
            0
          ],
          [
            -116.25569,
            47.28082,
            0
          ],
          [
            -116.25556,
            47.28077,
            0
          ],
          [
            -116.25484,
            47.28058,
            0
          ],
          [
            -116.25271,
            47.28007,
            0
          ],
          [
            -116.25251,
            47.28001,
            0
          ],
          [
            -116.25232,
            47.27994,
            0
          ],
          [
            -116.25197,
            47.27978,
            0
          ],
          [
            -116.25165,
            47.27959,
            0
          ],
          [
            -116.25136,
            47.27937,
            0
          ],
          [
            -116.25123,
            47.27926,
            0
          ],
          [
            -116.25102,
            47.27905,
            0
          ],
          [
            -116.25082,
            47.27883,
            0
          ],
          [
            -116.25068,
            47.27869,
            0
          ],
          [
            -116.25058,
            47.27857,
            0
          ],
          [
            -116.25018,
            47.27816,
            0
          ],
          [
            -116.24993,
            47.27794,
            0
          ],
          [
            -116.24973,
            47.27779,
            0
          ],
          [
            -116.24948,
            47.27762,
            0
          ],
          [
            -116.24946,
            47.27761,
            0
          ],
          [
            -116.24913,
            47.2774,
            0
          ],
          [
            -116.24687,
            47.27607,
            0
          ],
          [
            -116.24679,
            47.27603,
            0
          ],
          [
            -116.24667,
            47.27595,
            0
          ],
          [
            -116.24626,
            47.27576,
            0
          ],
          [
            -116.24623,
            47.27574,
            0
          ],
          [
            -116.24612,
            47.27569,
            0
          ],
          [
            -116.24575,
            47.27555,
            0
          ],
          [
            -116.24083,
            47.27403,
            0
          ],
          [
            -116.24021,
            47.27381,
            0
          ],
          [
            -116.23997,
            47.27371,
            0
          ],
          [
            -116.23968,
            47.27357,
            0
          ],
          [
            -116.23924,
            47.27332,
            0
          ],
          [
            -116.23912,
            47.27324,
            0
          ],
          [
            -116.239,
            47.27315,
            0
          ],
          [
            -116.23899,
            47.27314,
            0
          ],
          [
            -116.23879,
            47.273,
            0
          ],
          [
            -116.23857,
            47.27282,
            0
          ],
          [
            -116.23804,
            47.27235,
            0
          ],
          [
            -116.238,
            47.27232,
            0
          ],
          [
            -116.23784,
            47.27218,
            0
          ],
          [
            -116.23741,
            47.27184,
            0
          ],
          [
            -116.23692,
            47.27154,
            0
          ],
          [
            -116.23652,
            47.27133,
            0
          ],
          [
            -116.23589,
            47.27107,
            0
          ],
          [
            -116.23557,
            47.27095,
            0
          ],
          [
            -116.2349,
            47.27076,
            0
          ],
          [
            -116.23428,
            47.27064,
            0
          ],
          [
            -116.23366,
            47.27056,
            0
          ],
          [
            -116.23314,
            47.27052,
            0
          ],
          [
            -116.23266,
            47.2705,
            0
          ],
          [
            -116.22799,
            47.27054,
            0
          ],
          [
            -116.2256,
            47.2706,
            0
          ],
          [
            -116.2249,
            47.27067,
            0
          ],
          [
            -116.22435,
            47.27074,
            0
          ],
          [
            -116.22357,
            47.27088,
            0
          ],
          [
            -116.22299,
            47.27101,
            0
          ],
          [
            -116.2223,
            47.27119,
            0
          ],
          [
            -116.22168,
            47.27137,
            0
          ],
          [
            -116.22109,
            47.27159,
            0
          ],
          [
            -116.2207,
            47.27175,
            0
          ],
          [
            -116.22063,
            47.27177,
            0
          ],
          [
            -116.21868,
            47.2726,
            0
          ],
          [
            -116.2186,
            47.27262,
            0
          ],
          [
            -116.21837,
            47.2727,
            0
          ],
          [
            -116.21835,
            47.27271,
            0
          ],
          [
            -116.21811,
            47.27278,
            0
          ],
          [
            -116.21786,
            47.27284,
            0
          ],
          [
            -116.21602,
            47.27318,
            0
          ],
          [
            -116.21554,
            47.27329,
            0
          ],
          [
            -116.21533,
            47.27335,
            0
          ],
          [
            -116.21528,
            47.27336,
            0
          ],
          [
            -116.21465,
            47.27359,
            0
          ],
          [
            -116.21463,
            47.2736,
            0
          ],
          [
            -116.21438,
            47.27369,
            0
          ],
          [
            -116.21408,
            47.27378,
            0
          ],
          [
            -116.21348,
            47.27391,
            0
          ],
          [
            -116.2134,
            47.27392,
            0
          ],
          [
            -116.212,
            47.27421,
            0
          ],
          [
            -116.21137,
            47.27431,
            0
          ],
          [
            -116.20763,
            47.27463,
            0
          ],
          [
            -116.20717,
            47.2747,
            0
          ],
          [
            -116.20711,
            47.27472,
            0
          ],
          [
            -116.20683,
            47.27479,
            0
          ],
          [
            -116.20664,
            47.27485,
            0
          ],
          [
            -116.20599,
            47.27509,
            0
          ],
          [
            -116.20579,
            47.27515,
            0
          ],
          [
            -116.20558,
            47.27519,
            0
          ],
          [
            -116.20527,
            47.27522,
            0
          ],
          [
            -116.20495,
            47.27522,
            0
          ],
          [
            -116.20442,
            47.27518,
            0
          ],
          [
            -116.20436,
            47.27517,
            0
          ],
          [
            -116.20338,
            47.27507,
            0
          ],
          [
            -116.20331,
            47.27507,
            0
          ],
          [
            -116.20256,
            47.27499,
            0
          ],
          [
            -116.20204,
            47.27492,
            0
          ],
          [
            -116.20184,
            47.27487,
            0
          ],
          [
            -116.20182,
            47.27486,
            0
          ],
          [
            -116.20156,
            47.27477,
            0
          ],
          [
            -116.20139,
            47.27468,
            0
          ],
          [
            -116.20133,
            47.27463,
            0
          ],
          [
            -116.20117,
            47.27452,
            0
          ],
          [
            -116.20116,
            47.2745,
            0
          ],
          [
            -116.201,
            47.27433,
            0
          ],
          [
            -116.20088,
            47.27413,
            0
          ],
          [
            -116.20083,
            47.27398,
            0
          ],
          [
            -116.2008,
            47.27383,
            0
          ],
          [
            -116.20077,
            47.2736,
            0
          ],
          [
            -116.20072,
            47.27335,
            0
          ],
          [
            -116.20063,
            47.27309,
            0
          ],
          [
            -116.20058,
            47.27299,
            0
          ],
          [
            -116.20052,
            47.27291,
            0
          ],
          [
            -116.20032,
            47.27259,
            0
          ],
          [
            -116.19911,
            47.27093,
            0
          ],
          [
            -116.19909,
            47.27091,
            0
          ],
          [
            -116.19894,
            47.27071,
            0
          ],
          [
            -116.19885,
            47.27062,
            0
          ],
          [
            -116.19877,
            47.27053,
            0
          ],
          [
            -116.19853,
            47.27033,
            0
          ],
          [
            -116.19825,
            47.27018,
            0
          ],
          [
            -116.19806,
            47.27011,
            0
          ],
          [
            -116.19787,
            47.27005,
            0
          ],
          [
            -116.19746,
            47.26997,
            0
          ],
          [
            -116.19682,
            47.26991,
            0
          ],
          [
            -116.19196,
            47.26968,
            0
          ],
          [
            -116.19111,
            47.26961,
            0
          ],
          [
            -116.19038,
            47.26952,
            0
          ],
          [
            -116.18997,
            47.26944,
            0
          ],
          [
            -116.18924,
            47.26925,
            0
          ],
          [
            -116.18822,
            47.26888,
            0
          ],
          [
            -116.18755,
            47.26855,
            0
          ],
          [
            -116.18713,
            47.26831,
            0
          ],
          [
            -116.1865,
            47.26792,
            0
          ],
          [
            -116.18574,
            47.26752,
            0
          ],
          [
            -116.18547,
            47.26741,
            0
          ],
          [
            -116.18519,
            47.26731,
            0
          ],
          [
            -116.1847,
            47.26717,
            0
          ],
          [
            -116.18428,
            47.26709,
            0
          ],
          [
            -116.18365,
            47.26702,
            0
          ],
          [
            -116.18313,
            47.267,
            0
          ],
          [
            -116.17996,
            47.26695,
            0
          ],
          [
            -116.17912,
            47.26699,
            0
          ],
          [
            -116.1786,
            47.26704,
            0
          ],
          [
            -116.17857,
            47.26705,
            0
          ],
          [
            -116.17799,
            47.26714,
            0
          ],
          [
            -116.17769,
            47.2672,
            0
          ],
          [
            -116.17591,
            47.26764,
            0
          ],
          [
            -116.17581,
            47.26767,
            0
          ],
          [
            -116.17441,
            47.26797,
            0
          ],
          [
            -116.17134,
            47.26848,
            0
          ],
          [
            -116.17031,
            47.26862,
            0
          ],
          [
            -116.1701,
            47.26864,
            0
          ],
          [
            -116.16926,
            47.26869,
            0
          ],
          [
            -116.16874,
            47.26869,
            0
          ],
          [
            -116.16831,
            47.26867,
            0
          ],
          [
            -116.1678,
            47.26862,
            0
          ],
          [
            -116.16556,
            47.26829,
            0
          ],
          [
            -116.16526,
            47.26826,
            0
          ],
          [
            -116.16474,
            47.26823,
            0
          ],
          [
            -116.16466,
            47.26824,
            0
          ],
          [
            -116.16439,
            47.26824,
            0
          ],
          [
            -116.16388,
            47.26829,
            0
          ],
          [
            -116.16241,
            47.26848,
            0
          ],
          [
            -116.16209,
            47.26851,
            0
          ],
          [
            -116.16159,
            47.26854,
            0
          ],
          [
            -116.16139,
            47.26854,
            0
          ],
          [
            -116.16122,
            47.26855,
            0
          ],
          [
            -116.1608,
            47.26854,
            0
          ],
          [
            -116.16046,
            47.26851,
            0
          ],
          [
            -116.16032,
            47.26849,
            0
          ],
          [
            -116.16006,
            47.26847,
            0
          ],
          [
            -116.15928,
            47.26834,
            0
          ],
          [
            -116.15607,
            47.26768,
            0
          ],
          [
            -116.15532,
            47.26749,
            0
          ],
          [
            -116.15519,
            47.26745,
            0
          ],
          [
            -116.15467,
            47.26726,
            0
          ],
          [
            -116.15464,
            47.26724,
            0
          ],
          [
            -116.15437,
            47.26713,
            0
          ],
          [
            -116.15388,
            47.26685,
            0
          ],
          [
            -116.15346,
            47.26656,
            0
          ],
          [
            -116.15345,
            47.26654,
            0
          ],
          [
            -116.15325,
            47.26638,
            0
          ],
          [
            -116.15262,
            47.26581,
            0
          ],
          [
            -116.15228,
            47.26554,
            0
          ],
          [
            -116.15206,
            47.26539,
            0
          ],
          [
            -116.15183,
            47.26525,
            0
          ],
          [
            -116.15151,
            47.2651,
            0
          ],
          [
            -116.15148,
            47.26509,
            0
          ],
          [
            -116.1513,
            47.26501,
            0
          ],
          [
            -116.14988,
            47.26451,
            0
          ],
          [
            -116.14849,
            47.26405,
            0
          ],
          [
            -116.14801,
            47.26391,
            0
          ],
          [
            -116.14751,
            47.2638,
            0
          ],
          [
            -116.14693,
            47.26369,
            0
          ],
          [
            -116.14632,
            47.26364,
            0
          ],
          [
            -116.14608,
            47.26364,
            0
          ],
          [
            -116.14575,
            47.26366,
            0
          ],
          [
            -116.14546,
            47.26369,
            0
          ],
          [
            -116.14511,
            47.26376,
            0
          ],
          [
            -116.14458,
            47.26392,
            0
          ],
          [
            -116.14397,
            47.26422,
            0
          ],
          [
            -116.14338,
            47.26459,
            0
          ],
          [
            -116.14189,
            47.26563,
            0
          ],
          [
            -116.14135,
            47.26595,
            0
          ],
          [
            -116.1411,
            47.26608,
            0
          ],
          [
            -116.14107,
            47.2661,
            0
          ],
          [
            -116.14078,
            47.26624,
            0
          ],
          [
            -116.14017,
            47.26649,
            0
          ],
          [
            -116.13985,
            47.2666,
            0
          ],
          [
            -116.13924,
            47.26677,
            0
          ],
          [
            -116.13852,
            47.26693,
            0
          ],
          [
            -116.13817,
            47.26699,
            0
          ],
          [
            -116.13747,
            47.26707,
            0
          ],
          [
            -116.13644,
            47.26713,
            0
          ],
          [
            -116.13542,
            47.26712,
            0
          ],
          [
            -116.13494,
            47.26709,
            0
          ],
          [
            -116.13458,
            47.26705,
            0
          ],
          [
            -116.13441,
            47.26704,
            0
          ],
          [
            -116.13389,
            47.26698,
            0
          ],
          [
            -116.13387,
            47.26698,
            0
          ],
          [
            -116.13234,
            47.26675,
            0
          ],
          [
            -116.13232,
            47.26674,
            0
          ],
          [
            -116.13208,
            47.26671,
            0
          ],
          [
            -116.13028,
            47.26641,
            0
          ],
          [
            -116.12861,
            47.26605,
            0
          ],
          [
            -116.1283,
            47.26601,
            0
          ],
          [
            -116.12817,
            47.26601,
            0
          ],
          [
            -116.12811,
            47.266,
            0
          ],
          [
            -116.1275,
            47.266,
            0
          ],
          [
            -116.12722,
            47.26602,
            0
          ],
          [
            -116.12711,
            47.26602,
            0
          ],
          [
            -116.12623,
            47.26609,
            0
          ],
          [
            -116.12552,
            47.26612,
            0
          ],
          [
            -116.12542,
            47.26613,
            0
          ],
          [
            -116.12499,
            47.26615,
            0
          ],
          [
            -116.12453,
            47.26619,
            0
          ],
          [
            -116.12416,
            47.26626,
            0
          ],
          [
            -116.1238,
            47.26635,
            0
          ],
          [
            -116.12244,
            47.26676,
            0
          ],
          [
            -116.12213,
            47.26683,
            0
          ],
          [
            -116.12176,
            47.2669,
            0
          ],
          [
            -116.12124,
            47.26698,
            0
          ],
          [
            -116.12074,
            47.26701,
            0
          ],
          [
            -116.12017,
            47.26702,
            0
          ],
          [
            -116.11972,
            47.267,
            0
          ],
          [
            -116.11724,
            47.26681,
            0
          ],
          [
            -116.11631,
            47.26671,
            0
          ],
          [
            -116.116,
            47.26666,
            0
          ],
          [
            -116.1153,
            47.26652,
            0
          ],
          [
            -116.1151,
            47.26647,
            0
          ],
          [
            -116.11505,
            47.26647,
            0
          ],
          [
            -116.11479,
            47.26642,
            0
          ],
          [
            -116.11453,
            47.26639,
            0
          ],
          [
            -116.11448,
            47.26638,
            0
          ],
          [
            -116.11385,
            47.26636,
            0
          ],
          [
            -116.11353,
            47.26639,
            0
          ],
          [
            -116.11322,
            47.26644,
            0
          ],
          [
            -116.11293,
            47.26651,
            0
          ],
          [
            -116.11249,
            47.26665,
            0
          ],
          [
            -116.11236,
            47.2667,
            0
          ],
          [
            -116.11211,
            47.26683,
            0
          ],
          [
            -116.11187,
            47.26697,
            0
          ],
          [
            -116.11165,
            47.26713,
            0
          ],
          [
            -116.11038,
            47.26825,
            0
          ],
          [
            -116.11008,
            47.26848,
            0
          ],
          [
            -116.1096,
            47.26876,
            0
          ],
          [
            -116.10904,
            47.26896,
            0
          ],
          [
            -116.10874,
            47.26903,
            0
          ],
          [
            -116.10843,
            47.26909,
            0
          ],
          [
            -116.10812,
            47.26912,
            0
          ],
          [
            -116.10748,
            47.26913,
            0
          ],
          [
            -116.10717,
            47.2691,
            0
          ],
          [
            -116.10686,
            47.26905,
            0
          ],
          [
            -116.10658,
            47.26899,
            0
          ],
          [
            -116.10657,
            47.26899,
            0
          ],
          [
            -116.10627,
            47.26892,
            0
          ],
          [
            -116.10154,
            47.26761,
            0
          ],
          [
            -116.10142,
            47.26757,
            0
          ],
          [
            -116.10141,
            47.26757,
            0
          ],
          [
            -116.10124,
            47.26752,
            0
          ],
          [
            -116.10117,
            47.26751,
            0
          ],
          [
            -116.10112,
            47.26749,
            0
          ],
          [
            -116.10024,
            47.26726,
            0
          ],
          [
            -116.09994,
            47.2672,
            0
          ],
          [
            -116.09932,
            47.26714,
            0
          ],
          [
            -116.09911,
            47.26714,
            0
          ],
          [
            -116.09859,
            47.26717,
            0
          ],
          [
            -116.09829,
            47.26721,
            0
          ],
          [
            -116.09747,
            47.26735,
            0
          ],
          [
            -116.09695,
            47.26741,
            0
          ],
          [
            -116.09679,
            47.26741,
            0
          ],
          [
            -116.09663,
            47.26742,
            0
          ],
          [
            -116.09631,
            47.26739,
            0
          ],
          [
            -116.09601,
            47.26734,
            0
          ],
          [
            -116.096,
            47.26734,
            0
          ],
          [
            -116.09578,
            47.26728,
            0
          ],
          [
            -116.09577,
            47.26727,
            0
          ],
          [
            -116.09571,
            47.26726,
            0
          ],
          [
            -116.09553,
            47.26718,
            0
          ],
          [
            -116.09544,
            47.26715,
            0
          ],
          [
            -116.09511,
            47.26697,
            0
          ],
          [
            -116.09476,
            47.2667,
            0
          ],
          [
            -116.09437,
            47.26636,
            0
          ],
          [
            -116.09427,
            47.26629,
            0
          ],
          [
            -116.09416,
            47.2662,
            0
          ],
          [
            -116.09393,
            47.26605,
            0
          ],
          [
            -116.09367,
            47.26592,
            0
          ],
          [
            -116.09339,
            47.26582,
            0
          ],
          [
            -116.0931,
            47.26574,
            0
          ],
          [
            -116.09294,
            47.26571,
            0
          ],
          [
            -116.09248,
            47.26565,
            0
          ],
          [
            -116.09216,
            47.26564,
            0
          ],
          [
            -116.09185,
            47.26565,
            0
          ],
          [
            -116.09165,
            47.26568,
            0
          ],
          [
            -116.09163,
            47.26569,
            0
          ],
          [
            -116.09155,
            47.2657,
            0
          ],
          [
            -116.09138,
            47.26575,
            0
          ],
          [
            -116.09125,
            47.26578,
            0
          ],
          [
            -116.09098,
            47.26589,
            0
          ],
          [
            -116.09092,
            47.26592,
            0
          ],
          [
            -116.09073,
            47.266,
            0
          ],
          [
            -116.08727,
            47.26794,
            0
          ],
          [
            -116.08693,
            47.2681,
            0
          ],
          [
            -116.08665,
            47.26821,
            0
          ],
          [
            -116.08605,
            47.26834,
            0
          ],
          [
            -116.08575,
            47.26836,
            0
          ],
          [
            -116.08542,
            47.26836,
            0
          ],
          [
            -116.0851,
            47.26833,
            0
          ],
          [
            -116.08448,
            47.26824,
            0
          ],
          [
            -116.08394,
            47.26813,
            0
          ],
          [
            -116.08326,
            47.26801,
            0
          ],
          [
            -116.08266,
            47.26788,
            0
          ],
          [
            -116.08204,
            47.26778,
            0
          ],
          [
            -116.08006,
            47.26756,
            0
          ],
          [
            -116.07986,
            47.26753,
            0
          ],
          [
            -116.0798,
            47.26753,
            0
          ],
          [
            -116.07925,
            47.26744,
            0
          ],
          [
            -116.07865,
            47.26729,
            0
          ],
          [
            -116.0783,
            47.26718,
            0
          ],
          [
            -116.07781,
            47.26699,
            0
          ],
          [
            -116.07737,
            47.26679,
            0
          ],
          [
            -116.07718,
            47.26669,
            0
          ],
          [
            -116.07658,
            47.26634,
            0
          ],
          [
            -116.07655,
            47.26633,
            0
          ],
          [
            -116.07638,
            47.26624,
            0
          ],
          [
            -116.07637,
            47.26624,
            0
          ],
          [
            -116.07615,
            47.26614,
            0
          ],
          [
            -116.07612,
            47.26612,
            0
          ],
          [
            -116.07593,
            47.26605,
            0
          ],
          [
            -116.07565,
            47.26596,
            0
          ],
          [
            -116.07519,
            47.26583,
            0
          ],
          [
            -116.07419,
            47.26559,
            0
          ],
          [
            -116.07394,
            47.26554,
            0
          ],
          [
            -116.07357,
            47.26545,
            0
          ],
          [
            -116.07188,
            47.26489,
            0
          ],
          [
            -116.07177,
            47.26486,
            0
          ],
          [
            -116.07075,
            47.26451,
            0
          ],
          [
            -116.0702,
            47.26434,
            0
          ],
          [
            -116.07017,
            47.26434,
            0
          ],
          [
            -116.06988,
            47.26426,
            0
          ],
          [
            -116.06945,
            47.26416,
            0
          ],
          [
            -116.06897,
            47.26408,
            0
          ],
          [
            -116.06894,
            47.26407,
            0
          ],
          [
            -116.06868,
            47.26404,
            0
          ],
          [
            -116.06866,
            47.26403,
            0
          ],
          [
            -116.06789,
            47.26397,
            0
          ],
          [
            -116.06772,
            47.26395,
            0
          ],
          [
            -116.0671,
            47.26392,
            0
          ],
          [
            -116.06577,
            47.26391,
            0
          ],
          [
            -116.06205,
            47.26396,
            0
          ],
          [
            -116.06079,
            47.26405,
            0
          ],
          [
            -116.05983,
            47.26417,
            0
          ],
          [
            -116.05959,
            47.26421,
            0
          ],
          [
            -116.05926,
            47.26424,
            0
          ],
          [
            -116.05873,
            47.26425,
            0
          ],
          [
            -116.05852,
            47.26424,
            0
          ],
          [
            -116.05831,
            47.26422,
            0
          ],
          [
            -116.05789,
            47.26416,
            0
          ],
          [
            -116.05749,
            47.26406,
            0
          ],
          [
            -116.0573,
            47.264,
            0
          ],
          [
            -116.05711,
            47.26393,
            0
          ],
          [
            -116.0568,
            47.26377,
            0
          ],
          [
            -116.05676,
            47.26376,
            0
          ],
          [
            -116.05661,
            47.26367,
            0
          ],
          [
            -116.05641,
            47.26353,
            0
          ],
          [
            -116.05618,
            47.26334,
            0
          ],
          [
            -116.05603,
            47.26319,
            0
          ],
          [
            -116.05595,
            47.26309,
            0
          ],
          [
            -116.05591,
            47.26305,
            0
          ],
          [
            -116.05568,
            47.26269,
            0
          ],
          [
            -116.05519,
            47.26185,
            0
          ],
          [
            -116.0548,
            47.26125,
            0
          ],
          [
            -116.05445,
            47.26079,
            0
          ],
          [
            -116.05435,
            47.26067,
            0
          ],
          [
            -116.05387,
            47.26014,
            0
          ],
          [
            -116.05232,
            47.25854,
            0
          ],
          [
            -116.05204,
            47.25821,
            0
          ],
          [
            -116.05198,
            47.25813,
            0
          ],
          [
            -116.0519,
            47.258,
            0
          ],
          [
            -116.05166,
            47.25755,
            0
          ],
          [
            -116.05154,
            47.25729,
            0
          ],
          [
            -116.0515,
            47.25722,
            0
          ],
          [
            -116.05145,
            47.2571,
            0
          ],
          [
            -116.05143,
            47.25704,
            0
          ],
          [
            -116.0511,
            47.25626,
            0
          ],
          [
            -116.05097,
            47.25592,
            0
          ],
          [
            -116.05093,
            47.25574,
            0
          ],
          [
            -116.05091,
            47.25552,
            0
          ],
          [
            -116.05092,
            47.2554,
            0
          ],
          [
            -116.05094,
            47.25529,
            0
          ],
          [
            -116.05095,
            47.25529,
            0
          ],
          [
            -116.05098,
            47.25518,
            0
          ],
          [
            -116.05108,
            47.25499,
            0
          ],
          [
            -116.05115,
            47.2549,
            0
          ],
          [
            -116.05133,
            47.25473,
            0
          ],
          [
            -116.05184,
            47.25431,
            0
          ],
          [
            -116.05205,
            47.25411,
            0
          ],
          [
            -116.05223,
            47.25387,
            0
          ],
          [
            -116.05229,
            47.25378,
            0
          ],
          [
            -116.05234,
            47.25368,
            0
          ],
          [
            -116.0524,
            47.25358,
            0
          ],
          [
            -116.05249,
            47.25335,
            0
          ],
          [
            -116.05257,
            47.25307,
            0
          ],
          [
            -116.05258,
            47.25302,
            0
          ],
          [
            -116.0526,
            47.25277,
            0
          ],
          [
            -116.0526,
            47.25263,
            0
          ],
          [
            -116.05258,
            47.25247,
            0
          ],
          [
            -116.05251,
            47.25216,
            0
          ],
          [
            -116.05246,
            47.25203,
            0
          ],
          [
            -116.0524,
            47.25191,
            0
          ],
          [
            -116.05226,
            47.2517,
            0
          ],
          [
            -116.05207,
            47.25147,
            0
          ],
          [
            -116.05181,
            47.25124,
            0
          ],
          [
            -116.05152,
            47.25104,
            0
          ],
          [
            -116.05086,
            47.25065,
            0
          ],
          [
            -116.05069,
            47.25054,
            0
          ],
          [
            -116.05058,
            47.25045,
            0
          ],
          [
            -116.05044,
            47.25032,
            0
          ],
          [
            -116.05041,
            47.25027,
            0
          ],
          [
            -116.05034,
            47.25018,
            0
          ],
          [
            -116.05028,
            47.25005,
            0
          ],
          [
            -116.05023,
            47.2499,
            0
          ],
          [
            -116.05022,
            47.24982,
            0
          ],
          [
            -116.05022,
            47.24966,
            0
          ],
          [
            -116.05023,
            47.2496,
            0
          ],
          [
            -116.05025,
            47.24953,
            0
          ],
          [
            -116.05028,
            47.24945,
            0
          ],
          [
            -116.0503,
            47.24941,
            0
          ],
          [
            -116.05032,
            47.24939,
            0
          ],
          [
            -116.05037,
            47.24929,
            0
          ],
          [
            -116.05046,
            47.24916,
            0
          ],
          [
            -116.05058,
            47.24904,
            0
          ],
          [
            -116.05071,
            47.24893,
            0
          ],
          [
            -116.05102,
            47.24873,
            0
          ],
          [
            -116.05118,
            47.24864,
            0
          ],
          [
            -116.05189,
            47.24833,
            0
          ],
          [
            -116.05206,
            47.24824,
            0
          ],
          [
            -116.05221,
            47.24814,
            0
          ],
          [
            -116.05246,
            47.24792,
            0
          ],
          [
            -116.05249,
            47.24787,
            0
          ],
          [
            -116.05255,
            47.24779,
            0
          ],
          [
            -116.05277,
            47.24738,
            0
          ],
          [
            -116.05278,
            47.24737,
            0
          ],
          [
            -116.05286,
            47.24719,
            0
          ],
          [
            -116.05287,
            47.24718,
            0
          ],
          [
            -116.05295,
            47.24702,
            0
          ],
          [
            -116.053,
            47.24686,
            0
          ],
          [
            -116.05301,
            47.24674,
            0
          ],
          [
            -116.053,
            47.24662,
            0
          ],
          [
            -116.05296,
            47.24641,
            0
          ],
          [
            -116.05286,
            47.2462,
            0
          ],
          [
            -116.05255,
            47.24566,
            0
          ],
          [
            -116.05239,
            47.24542,
            0
          ],
          [
            -116.05231,
            47.24532,
            0
          ],
          [
            -116.05227,
            47.24528,
            0
          ],
          [
            -116.05221,
            47.24521,
            0
          ],
          [
            -116.05202,
            47.24504,
            0
          ],
          [
            -116.05181,
            47.24489,
            0
          ],
          [
            -116.05088,
            47.2443,
            0
          ],
          [
            -116.05064,
            47.24417,
            0
          ],
          [
            -116.05,
            47.24385,
            0
          ],
          [
            -116.04978,
            47.24375,
            0
          ],
          [
            -116.04932,
            47.24357,
            0
          ],
          [
            -116.04883,
            47.24344,
            0
          ],
          [
            -116.0481,
            47.24331,
            0
          ],
          [
            -116.04754,
            47.24323,
            0
          ],
          [
            -116.04748,
            47.24323,
            0
          ],
          [
            -116.04686,
            47.24318,
            0
          ],
          [
            -116.04649,
            47.24318,
            0
          ],
          [
            -116.04622,
            47.2432,
            0
          ],
          [
            -116.0456,
            47.24329,
            0
          ],
          [
            -116.0452,
            47.24338,
            0
          ],
          [
            -116.045,
            47.24344,
            0
          ],
          [
            -116.04449,
            47.24362,
            0
          ],
          [
            -116.04371,
            47.24392,
            0
          ],
          [
            -116.04336,
            47.24407,
            0
          ],
          [
            -116.04303,
            47.24423,
            0
          ],
          [
            -116.04237,
            47.24462,
            0
          ],
          [
            -116.04004,
            47.24612,
            0
          ],
          [
            -116.04002,
            47.24614,
            0
          ],
          [
            -116.03958,
            47.24643,
            0
          ],
          [
            -116.0387,
            47.24708,
            0
          ],
          [
            -116.03843,
            47.2473,
            0
          ],
          [
            -116.0384,
            47.24732,
            0
          ],
          [
            -116.03783,
            47.24781,
            0
          ],
          [
            -116.03546,
            47.24971,
            0
          ],
          [
            -116.03537,
            47.24979,
            0
          ],
          [
            -116.03484,
            47.2502,
            0
          ],
          [
            -116.03462,
            47.25035,
            0
          ],
          [
            -116.03445,
            47.25045,
            0
          ],
          [
            -116.03428,
            47.25053,
            0
          ],
          [
            -116.03408,
            47.25059,
            0
          ],
          [
            -116.03404,
            47.25059,
            0
          ],
          [
            -116.03387,
            47.25062,
            0
          ],
          [
            -116.03365,
            47.25062,
            0
          ],
          [
            -116.03344,
            47.25061,
            0
          ],
          [
            -116.03303,
            47.25053,
            0
          ],
          [
            -116.033,
            47.25053,
            0
          ],
          [
            -116.03275,
            47.25046,
            0
          ],
          [
            -116.03274,
            47.25046,
            0
          ],
          [
            -116.03254,
            47.2504,
            0
          ],
          [
            -116.03181,
            47.25014,
            0
          ],
          [
            -116.03121,
            47.24996,
            0
          ],
          [
            -116.03082,
            47.24988,
            0
          ],
          [
            -116.0306,
            47.24985,
            0
          ],
          [
            -116.02997,
            47.24981,
            0
          ],
          [
            -116.02965,
            47.24981,
            0
          ],
          [
            -116.0287,
            47.24986,
            0
          ],
          [
            -116.0281,
            47.24992,
            0
          ],
          [
            -116.02727,
            47.25004,
            0
          ],
          [
            -116.02699,
            47.25009,
            0
          ],
          [
            -116.02614,
            47.25027,
            0
          ],
          [
            -116.02495,
            47.25056,
            0
          ],
          [
            -116.0248,
            47.25058,
            0
          ],
          [
            -116.02474,
            47.2506,
            0
          ],
          [
            -116.02453,
            47.25063,
            0
          ],
          [
            -116.02432,
            47.25064,
            0
          ],
          [
            -116.02411,
            47.25064,
            0
          ],
          [
            -116.0239,
            47.25062,
            0
          ],
          [
            -116.02369,
            47.25059,
            0
          ],
          [
            -116.02349,
            47.25055,
            0
          ],
          [
            -116.02242,
            47.25028,
            0
          ],
          [
            -116.02228,
            47.25026,
            0
          ],
          [
            -116.0222,
            47.25024,
            0
          ],
          [
            -116.02169,
            47.25017,
            0
          ],
          [
            -116.02139,
            47.25015,
            0
          ],
          [
            -116.02088,
            47.25015,
            0
          ],
          [
            -116.0205,
            47.25019,
            0
          ],
          [
            -116.02011,
            47.25026,
            0
          ],
          [
            -116.01988,
            47.25032,
            0
          ],
          [
            -116.01986,
            47.25032,
            0
          ],
          [
            -116.01967,
            47.25037,
            0
          ],
          [
            -116.01921,
            47.25051,
            0
          ],
          [
            -116.01913,
            47.25054,
            0
          ],
          [
            -116.0168,
            47.25126,
            0
          ],
          [
            -116.01644,
            47.25136,
            0
          ],
          [
            -116.0163,
            47.25139,
            0
          ],
          [
            -116.01614,
            47.25141,
            0
          ],
          [
            -116.01597,
            47.25142,
            0
          ],
          [
            -116.01576,
            47.25142,
            0
          ],
          [
            -116.01563,
            47.25141,
            0
          ],
          [
            -116.01535,
            47.25137,
            0
          ],
          [
            -116.01524,
            47.25134,
            0
          ],
          [
            -116.01518,
            47.25133,
            0
          ],
          [
            -116.01485,
            47.25122,
            0
          ],
          [
            -116.01474,
            47.25117,
            0
          ],
          [
            -116.01471,
            47.25116,
            0
          ],
          [
            -116.01452,
            47.25105,
            0
          ],
          [
            -116.01451,
            47.25104,
            0
          ],
          [
            -116.01443,
            47.25099,
            0
          ],
          [
            -116.01433,
            47.25091,
            0
          ],
          [
            -116.01425,
            47.25083,
            0
          ],
          [
            -116.01417,
            47.25076,
            0
          ],
          [
            -116.01411,
            47.25068,
            0
          ],
          [
            -116.01406,
            47.2506,
            0
          ],
          [
            -116.01387,
            47.25023,
            0
          ],
          [
            -116.01285,
            47.24791,
            0
          ],
          [
            -116.01285,
            47.2479,
            0
          ],
          [
            -116.01279,
            47.24774,
            0
          ],
          [
            -116.01276,
            47.24759,
            0
          ],
          [
            -116.01275,
            47.24749,
            0
          ],
          [
            -116.01278,
            47.24711,
            0
          ],
          [
            -116.01282,
            47.24699,
            0
          ],
          [
            -116.01297,
            47.24664,
            0
          ],
          [
            -116.0133,
            47.24602,
            0
          ],
          [
            -116.01336,
            47.24588,
            0
          ],
          [
            -116.01342,
            47.24571,
            0
          ],
          [
            -116.01344,
            47.24562,
            0
          ],
          [
            -116.01346,
            47.24545,
            0
          ],
          [
            -116.01345,
            47.24536,
            0
          ],
          [
            -116.01345,
            47.24528,
            0
          ],
          [
            -116.01342,
            47.24513,
            0
          ],
          [
            -116.01338,
            47.245,
            0
          ],
          [
            -116.01338,
            47.24499,
            0
          ],
          [
            -116.01336,
            47.24493,
            0
          ],
          [
            -116.01329,
            47.24479,
            0
          ],
          [
            -116.01317,
            47.24459,
            0
          ],
          [
            -116.01308,
            47.24447,
            0
          ],
          [
            -116.01284,
            47.24424,
            0
          ],
          [
            -116.01261,
            47.24407,
            0
          ],
          [
            -116.01254,
            47.24403,
            0
          ],
          [
            -116.012,
            47.24378,
            0
          ],
          [
            -116.0084,
            47.2422,
            0
          ],
          [
            -116.00784,
            47.24197,
            0
          ],
          [
            -116.0077,
            47.24192,
            0
          ],
          [
            -116.00728,
            47.24181,
            0
          ],
          [
            -116.00693,
            47.24175,
            0
          ],
          [
            -116.00667,
            47.24172,
            0
          ],
          [
            -116.00652,
            47.24171,
            0
          ],
          [
            -116.00603,
            47.24171,
            0
          ],
          [
            -116.00564,
            47.24174,
            0
          ],
          [
            -116.00561,
            47.24175,
            0
          ],
          [
            -116.00548,
            47.24176,
            0
          ],
          [
            -116.00482,
            47.24192,
            0
          ],
          [
            -116.00454,
            47.24202,
            0
          ],
          [
            -115.99905,
            47.24461,
            0
          ],
          [
            -115.99778,
            47.24526,
            0
          ],
          [
            -115.99705,
            47.24569,
            0
          ],
          [
            -115.99497,
            47.24703,
            0
          ],
          [
            -115.99473,
            47.24717,
            0
          ],
          [
            -115.99448,
            47.2473,
            0
          ],
          [
            -115.99396,
            47.24754,
            0
          ],
          [
            -115.99313,
            47.24786,
            0
          ],
          [
            -115.99089,
            47.24864,
            0
          ],
          [
            -115.99053,
            47.24878,
            0
          ],
          [
            -115.99024,
            47.24891,
            0
          ],
          [
            -115.99011,
            47.24898,
            0
          ],
          [
            -115.98976,
            47.24921,
            0
          ],
          [
            -115.98957,
            47.24937,
            0
          ],
          [
            -115.98948,
            47.24946,
            0
          ],
          [
            -115.98932,
            47.24964,
            0
          ],
          [
            -115.98903,
            47.25002,
            0
          ],
          [
            -115.98903,
            47.25003,
            0
          ],
          [
            -115.98874,
            47.25041,
            0
          ],
          [
            -115.98861,
            47.25056,
            0
          ],
          [
            -115.98851,
            47.25066,
            0
          ],
          [
            -115.98838,
            47.25076,
            0
          ],
          [
            -115.98608,
            47.2522,
            0
          ],
          [
            -115.98562,
            47.25246,
            0
          ],
          [
            -115.98561,
            47.25247,
            0
          ],
          [
            -115.98528,
            47.25263,
            0
          ],
          [
            -115.98503,
            47.25272,
            0
          ],
          [
            -115.98491,
            47.25275,
            0
          ],
          [
            -115.9848,
            47.25279,
            0
          ],
          [
            -115.98455,
            47.25285,
            0
          ],
          [
            -115.9842,
            47.25291,
            0
          ],
          [
            -115.98371,
            47.25296,
            0
          ],
          [
            -115.98292,
            47.25298,
            0
          ],
          [
            -115.98216,
            47.25295,
            0
          ],
          [
            -115.97867,
            47.25255,
            0
          ],
          [
            -115.97825,
            47.25248,
            0
          ],
          [
            -115.97808,
            47.25244,
            0
          ],
          [
            -115.97786,
            47.2524,
            0
          ],
          [
            -115.97718,
            47.25223,
            0
          ],
          [
            -115.97678,
            47.25211,
            0
          ],
          [
            -115.97554,
            47.25167,
            0
          ],
          [
            -115.97516,
            47.25151,
            0
          ],
          [
            -115.9744,
            47.25114,
            0
          ],
          [
            -115.97396,
            47.25089,
            0
          ],
          [
            -115.97373,
            47.25074,
            0
          ],
          [
            -115.97293,
            47.25012,
            0
          ],
          [
            -115.97289,
            47.2501,
            0
          ],
          [
            -115.97269,
            47.24994,
            0
          ],
          [
            -115.9724,
            47.24973,
            0
          ],
          [
            -115.97215,
            47.24959,
            0
          ],
          [
            -115.97164,
            47.24941,
            0
          ],
          [
            -115.97147,
            47.24937,
            0
          ],
          [
            -115.97118,
            47.24932,
            0
          ],
          [
            -115.97096,
            47.2493,
            0
          ],
          [
            -115.97063,
            47.2493,
            0
          ],
          [
            -115.97029,
            47.24932,
            0
          ],
          [
            -115.97014,
            47.24934,
            0
          ],
          [
            -115.96758,
            47.24978,
            0
          ],
          [
            -115.96665,
            47.24991,
            0
          ],
          [
            -115.96636,
            47.24994,
            0
          ],
          [
            -115.96623,
            47.24996,
            0
          ],
          [
            -115.9653,
            47.25003,
            0
          ],
          [
            -115.96435,
            47.25006,
            0
          ],
          [
            -115.96357,
            47.25006,
            0
          ],
          [
            -115.96277,
            47.24998,
            0
          ],
          [
            -115.96256,
            47.24994,
            0
          ],
          [
            -115.96235,
            47.24989,
            0
          ],
          [
            -115.96179,
            47.24972,
            0
          ],
          [
            -115.95904,
            47.24878,
            0
          ],
          [
            -115.95879,
            47.24871,
            0
          ],
          [
            -115.95849,
            47.24865,
            0
          ],
          [
            -115.95833,
            47.24863,
            0
          ],
          [
            -115.95798,
            47.24861,
            0
          ],
          [
            -115.95778,
            47.24861,
            0
          ],
          [
            -115.95775,
            47.24862,
            0
          ],
          [
            -115.95756,
            47.24863,
            0
          ],
          [
            -115.95739,
            47.24865,
            0
          ],
          [
            -115.95713,
            47.2487,
            0
          ],
          [
            -115.95682,
            47.24879,
            0
          ],
          [
            -115.95639,
            47.24897,
            0
          ],
          [
            -115.95621,
            47.24907,
            0
          ],
          [
            -115.9554,
            47.24958,
            0
          ],
          [
            -115.95533,
            47.24963,
            0
          ],
          [
            -115.95406,
            47.2504,
            0
          ],
          [
            -115.95384,
            47.25051,
            0
          ],
          [
            -115.95358,
            47.25062,
            0
          ],
          [
            -115.95339,
            47.25069,
            0
          ],
          [
            -115.95325,
            47.25073,
            0
          ],
          [
            -115.95323,
            47.25074,
            0
          ],
          [
            -115.95296,
            47.25082,
            0
          ],
          [
            -115.95276,
            47.25086,
            0
          ],
          [
            -115.95273,
            47.25087,
            0
          ],
          [
            -115.95255,
            47.2509,
            0
          ],
          [
            -115.95196,
            47.25096,
            0
          ],
          [
            -115.95163,
            47.25096,
            0
          ],
          [
            -115.95127,
            47.25093,
            0
          ],
          [
            -115.95107,
            47.2509,
            0
          ],
          [
            -115.95072,
            47.2508,
            0
          ],
          [
            -115.95058,
            47.25075,
            0
          ],
          [
            -115.95039,
            47.25067,
            0
          ],
          [
            -115.95017,
            47.25056,
            0
          ],
          [
            -115.94998,
            47.25045,
            0
          ],
          [
            -115.94976,
            47.2503,
            0
          ],
          [
            -115.94949,
            47.2501,
            0
          ],
          [
            -115.94789,
            47.24903,
            0
          ],
          [
            -115.94764,
            47.2489,
            0
          ],
          [
            -115.94748,
            47.24883,
            0
          ],
          [
            -115.94722,
            47.24876,
            0
          ],
          [
            -115.94708,
            47.24874,
            0
          ],
          [
            -115.94695,
            47.24871,
            0
          ],
          [
            -115.94681,
            47.24869,
            0
          ],
          [
            -115.94667,
            47.24868,
            0
          ],
          [
            -115.94636,
            47.24868,
            0
          ],
          [
            -115.94621,
            47.24869,
            0
          ],
          [
            -115.9459,
            47.24874,
            0
          ],
          [
            -115.94577,
            47.24877,
            0
          ],
          [
            -115.94558,
            47.24883,
            0
          ],
          [
            -115.9454,
            47.2489,
            0
          ],
          [
            -115.94499,
            47.2491,
            0
          ],
          [
            -115.94429,
            47.24959,
            0
          ],
          [
            -115.9437,
            47.25003,
            0
          ],
          [
            -115.94311,
            47.25052,
            0
          ],
          [
            -115.94255,
            47.25103,
            0
          ],
          [
            -115.94221,
            47.25138,
            0
          ],
          [
            -115.94153,
            47.25216,
            0
          ],
          [
            -115.94114,
            47.25267,
            0
          ],
          [
            -115.94025,
            47.254,
            0
          ],
          [
            -115.93901,
            47.25593,
            0
          ],
          [
            -115.93884,
            47.25617,
            0
          ],
          [
            -115.93875,
            47.25628,
            0
          ],
          [
            -115.93857,
            47.25646,
            0
          ],
          [
            -115.93837,
            47.25663,
            0
          ],
          [
            -115.93809,
            47.25682,
            0
          ],
          [
            -115.93777,
            47.25699,
            0
          ],
          [
            -115.9376,
            47.25706,
            0
          ],
          [
            -115.93743,
            47.25714,
            0
          ],
          [
            -115.93731,
            47.25717,
            0
          ],
          [
            -115.93657,
            47.2573,
            0
          ],
          [
            -115.93624,
            47.25734,
            0
          ],
          [
            -115.9362,
            47.25734,
            0
          ],
          [
            -115.93601,
            47.25737,
            0
          ],
          [
            -115.93562,
            47.25741,
            0
          ],
          [
            -115.93281,
            47.25778,
            0
          ],
          [
            -115.93276,
            47.25779,
            0
          ],
          [
            -115.93229,
            47.25785,
            0
          ],
          [
            -115.93191,
            47.25787,
            0
          ],
          [
            -115.93143,
            47.25786,
            0
          ],
          [
            -115.93124,
            47.25784,
            0
          ],
          [
            -115.93077,
            47.25776,
            0
          ],
          [
            -115.93054,
            47.2577,
            0
          ],
          [
            -115.93048,
            47.25769,
            0
          ],
          [
            -115.93,
            47.25752,
            0
          ],
          [
            -115.92947,
            47.25726,
            0
          ],
          [
            -115.92878,
            47.25686,
            0
          ],
          [
            -115.92856,
            47.25672,
            0
          ],
          [
            -115.92732,
            47.256,
            0
          ],
          [
            -115.92684,
            47.25576,
            0
          ],
          [
            -115.92645,
            47.25559,
            0
          ],
          [
            -115.92627,
            47.25552,
            0
          ],
          [
            -115.92626,
            47.25552,
            0
          ],
          [
            -115.92581,
            47.25539,
            0
          ],
          [
            -115.92577,
            47.25539,
            0
          ],
          [
            -115.92549,
            47.25534,
            0
          ],
          [
            -115.9251,
            47.25531,
            0
          ],
          [
            -115.9249,
            47.25531,
            0
          ],
          [
            -115.92384,
            47.25539,
            0
          ],
          [
            -115.92309,
            47.25543,
            0
          ],
          [
            -115.92291,
            47.25543,
            0
          ],
          [
            -115.92271,
            47.25542,
            0
          ],
          [
            -115.92235,
            47.25538,
            0
          ],
          [
            -115.92185,
            47.25527,
            0
          ],
          [
            -115.92156,
            47.25517,
            0
          ],
          [
            -115.92134,
            47.25508,
            0
          ],
          [
            -115.92104,
            47.25492,
            0
          ],
          [
            -115.92067,
            47.25466,
            0
          ],
          [
            -115.92044,
            47.25447,
            0
          ],
          [
            -115.91962,
            47.25374,
            0
          ],
          [
            -115.91931,
            47.25354,
            0
          ],
          [
            -115.91902,
            47.25339,
            0
          ],
          [
            -115.91852,
            47.25324,
            0
          ],
          [
            -115.91821,
            47.25318,
            0
          ],
          [
            -115.91796,
            47.25315,
            0
          ],
          [
            -115.91741,
            47.25316,
            0
          ],
          [
            -115.91719,
            47.25319,
            0
          ],
          [
            -115.91686,
            47.25326,
            0
          ],
          [
            -115.91666,
            47.25332,
            0
          ],
          [
            -115.91647,
            47.25339,
            0
          ],
          [
            -115.91595,
            47.25365,
            0
          ],
          [
            -115.91591,
            47.25368,
            0
          ],
          [
            -115.91549,
            47.25392,
            0
          ],
          [
            -115.91526,
            47.25404,
            0
          ],
          [
            -115.91507,
            47.25415,
            0
          ],
          [
            -115.9149,
            47.25423,
            0
          ],
          [
            -115.91418,
            47.25452,
            0
          ],
          [
            -115.91364,
            47.25467,
            0
          ],
          [
            -115.91294,
            47.25481,
            0
          ],
          [
            -115.91222,
            47.25488,
            0
          ],
          [
            -115.91176,
            47.2549,
            0
          ],
          [
            -115.90872,
            47.25483,
            0
          ],
          [
            -115.90771,
            47.25479,
            0
          ],
          [
            -115.90706,
            47.25479,
            0
          ],
          [
            -115.90641,
            47.25482,
            0
          ],
          [
            -115.90609,
            47.25485,
            0
          ],
          [
            -115.90542,
            47.25497,
            0
          ],
          [
            -115.90507,
            47.25505,
            0
          ],
          [
            -115.90446,
            47.25523,
            0
          ],
          [
            -115.90268,
            47.25585,
            0
          ],
          [
            -115.90239,
            47.25594,
            0
          ],
          [
            -115.90175,
            47.2561,
            0
          ],
          [
            -115.90138,
            47.25618,
            0
          ],
          [
            -115.90088,
            47.25626,
            0
          ],
          [
            -115.90023,
            47.25633,
            0
          ],
          [
            -115.8994,
            47.25639,
            0
          ],
          [
            -115.89742,
            47.25645,
            0
          ],
          [
            -115.89689,
            47.25645,
            0
          ],
          [
            -115.89664,
            47.25644,
            0
          ],
          [
            -115.89589,
            47.25638,
            0
          ],
          [
            -115.89571,
            47.25634,
            0
          ],
          [
            -115.89555,
            47.25632,
            0
          ],
          [
            -115.89424,
            47.25602,
            0
          ],
          [
            -115.89402,
            47.25598,
            0
          ],
          [
            -115.89371,
            47.25594,
            0
          ],
          [
            -115.89348,
            47.2559,
            0
          ],
          [
            -115.89315,
            47.25588,
            0
          ],
          [
            -115.89274,
            47.25588,
            0
          ],
          [
            -115.8925,
            47.25589,
            0
          ],
          [
            -115.89108,
            47.25602,
            0
          ],
          [
            -115.89089,
            47.25603,
            0
          ],
          [
            -115.89061,
            47.25603,
            0
          ],
          [
            -115.89,
            47.256,
            0
          ],
          [
            -115.88999,
            47.25599,
            0
          ],
          [
            -115.88973,
            47.25597,
            0
          ],
          [
            -115.8896,
            47.25595,
            0
          ],
          [
            -115.88937,
            47.2559,
            0
          ],
          [
            -115.88821,
            47.25559,
            0
          ],
          [
            -115.88792,
            47.25553,
            0
          ],
          [
            -115.88764,
            47.25549,
            0
          ],
          [
            -115.88724,
            47.25546,
            0
          ],
          [
            -115.88684,
            47.25548,
            0
          ],
          [
            -115.8864,
            47.25555,
            0
          ],
          [
            -115.88617,
            47.25561,
            0
          ],
          [
            -115.88607,
            47.25563,
            0
          ],
          [
            -115.8846,
            47.25601,
            0
          ],
          [
            -115.88393,
            47.25615,
            0
          ],
          [
            -115.8834,
            47.25624,
            0
          ],
          [
            -115.88239,
            47.25635,
            0
          ],
          [
            -115.88145,
            47.2564,
            0
          ],
          [
            -115.88083,
            47.2564,
            0
          ],
          [
            -115.87983,
            47.25636,
            0
          ],
          [
            -115.87928,
            47.25631,
            0
          ],
          [
            -115.87867,
            47.25623,
            0
          ],
          [
            -115.8772,
            47.25595,
            0
          ],
          [
            -115.87611,
            47.25577,
            0
          ],
          [
            -115.87607,
            47.25577,
            0
          ],
          [
            -115.8759,
            47.25575,
            0
          ],
          [
            -115.87551,
            47.25573,
            0
          ],
          [
            -115.87526,
            47.25575,
            0
          ],
          [
            -115.87487,
            47.2558,
            0
          ],
          [
            -115.8748,
            47.25582,
            0
          ],
          [
            -115.87467,
            47.25584,
            0
          ],
          [
            -115.87444,
            47.25591,
            0
          ],
          [
            -115.87407,
            47.25605,
            0
          ],
          [
            -115.87386,
            47.25616,
            0
          ],
          [
            -115.87368,
            47.25628,
            0
          ],
          [
            -115.87366,
            47.2563,
            0
          ],
          [
            -115.87347,
            47.25645,
            0
          ],
          [
            -115.87327,
            47.25664,
            0
          ],
          [
            -115.87279,
            47.25717,
            0
          ],
          [
            -115.87267,
            47.25732,
            0
          ],
          [
            -115.8725,
            47.25747,
            0
          ],
          [
            -115.87234,
            47.25759,
            0
          ],
          [
            -115.87215,
            47.25771,
            0
          ],
          [
            -115.87194,
            47.25782,
            0
          ],
          [
            -115.87159,
            47.25796,
            0
          ],
          [
            -115.8713,
            47.25804,
            0
          ],
          [
            -115.87059,
            47.25816,
            0
          ],
          [
            -115.8699,
            47.25822,
            0
          ],
          [
            -115.86883,
            47.25823,
            0
          ],
          [
            -115.86831,
            47.2582,
            0
          ],
          [
            -115.86762,
            47.25812,
            0
          ],
          [
            -115.86729,
            47.25807,
            0
          ],
          [
            -115.86727,
            47.25807,
            0
          ],
          [
            -115.86701,
            47.25801,
            0
          ],
          [
            -115.86698,
            47.25801,
            0
          ],
          [
            -115.86673,
            47.25795,
            0
          ],
          [
            -115.86644,
            47.25786,
            0
          ],
          [
            -115.8661,
            47.25773,
            0
          ],
          [
            -115.86584,
            47.2576,
            0
          ],
          [
            -115.86557,
            47.25744,
            0
          ],
          [
            -115.86514,
            47.25713,
            0
          ],
          [
            -115.86509,
            47.2571,
            0
          ],
          [
            -115.86475,
            47.25685,
            0
          ],
          [
            -115.86439,
            47.25652,
            0
          ],
          [
            -115.86427,
            47.25639,
            0
          ],
          [
            -115.86417,
            47.25627,
            0
          ],
          [
            -115.86415,
            47.25623,
            0
          ],
          [
            -115.86404,
            47.25607,
            0
          ],
          [
            -115.86398,
            47.25595,
            0
          ],
          [
            -115.86396,
            47.2559,
            0
          ],
          [
            -115.86392,
            47.25582,
            0
          ],
          [
            -115.86389,
            47.25574,
            0
          ],
          [
            -115.86388,
            47.25573,
            0
          ],
          [
            -115.86383,
            47.25553,
            0
          ],
          [
            -115.8638,
            47.25528,
            0
          ],
          [
            -115.86379,
            47.255,
            0
          ],
          [
            -115.8639,
            47.25411,
            0
          ],
          [
            -115.86396,
            47.25376,
            0
          ],
          [
            -115.86397,
            47.25365,
            0
          ],
          [
            -115.86404,
            47.25322,
            0
          ],
          [
            -115.86406,
            47.25304,
            0
          ],
          [
            -115.86407,
            47.25286,
            0
          ],
          [
            -115.86408,
            47.25281,
            0
          ],
          [
            -115.86407,
            47.25268,
            0
          ],
          [
            -115.86407,
            47.25257,
            0
          ],
          [
            -115.86406,
            47.2525,
            0
          ],
          [
            -115.86402,
            47.25236,
            0
          ],
          [
            -115.86393,
            47.25215,
            0
          ],
          [
            -115.86384,
            47.25198,
            0
          ],
          [
            -115.86368,
            47.25175,
            0
          ],
          [
            -115.8635,
            47.25157,
            0
          ],
          [
            -115.8633,
            47.25141,
            0
          ],
          [
            -115.86327,
            47.25138,
            0
          ],
          [
            -115.86307,
            47.25126,
            0
          ],
          [
            -115.86282,
            47.25113,
            0
          ],
          [
            -115.8622,
            47.25087,
            0
          ],
          [
            -115.86219,
            47.25087,
            0
          ],
          [
            -115.85847,
            47.24934,
            0
          ],
          [
            -115.85765,
            47.24903,
            0
          ],
          [
            -115.85736,
            47.24894,
            0
          ],
          [
            -115.85676,
            47.24881,
            0
          ],
          [
            -115.85641,
            47.24876,
            0
          ],
          [
            -115.85572,
            47.24871,
            0
          ],
          [
            -115.85143,
            47.24877,
            0
          ],
          [
            -115.85135,
            47.24878,
            0
          ],
          [
            -115.85089,
            47.2488,
            0
          ],
          [
            -115.85017,
            47.24887,
            0
          ],
          [
            -115.8494,
            47.24902,
            0
          ],
          [
            -115.84879,
            47.24918,
            0
          ],
          [
            -115.84842,
            47.2493,
            0
          ],
          [
            -115.84786,
            47.24952,
            0
          ],
          [
            -115.8476,
            47.24965,
            0
          ],
          [
            -115.84745,
            47.24974,
            0
          ],
          [
            -115.84744,
            47.24975,
            0
          ],
          [
            -115.84705,
            47.25004,
            0
          ],
          [
            -115.84688,
            47.2502,
            0
          ],
          [
            -115.84674,
            47.25035,
            0
          ],
          [
            -115.84643,
            47.25074,
            0
          ],
          [
            -115.84629,
            47.25093,
            0
          ],
          [
            -115.84628,
            47.25095,
            0
          ],
          [
            -115.84609,
            47.25118,
            0
          ],
          [
            -115.84595,
            47.25137,
            0
          ],
          [
            -115.84564,
            47.25169,
            0
          ],
          [
            -115.84535,
            47.25194,
            0
          ],
          [
            -115.84526,
            47.25199,
            0
          ],
          [
            -115.84494,
            47.25221,
            0
          ],
          [
            -115.84429,
            47.25252,
            0
          ],
          [
            -115.84353,
            47.2528,
            0
          ],
          [
            -115.84313,
            47.25292,
            0
          ],
          [
            -115.84244,
            47.25308,
            0
          ],
          [
            -115.84214,
            47.25313,
            0
          ],
          [
            -115.8414,
            47.25322,
            0
          ],
          [
            -115.84101,
            47.25325,
            0
          ],
          [
            -115.84018,
            47.25326,
            0
          ],
          [
            -115.83999,
            47.25325,
            0
          ],
          [
            -115.8398,
            47.25325,
            0
          ],
          [
            -115.83965,
            47.25323,
            0
          ],
          [
            -115.83956,
            47.25323,
            0
          ],
          [
            -115.83913,
            47.25318,
            0
          ],
          [
            -115.83887,
            47.25313,
            0
          ],
          [
            -115.83878,
            47.25312,
            0
          ],
          [
            -115.83837,
            47.25302,
            0
          ],
          [
            -115.83835,
            47.25301,
            0
          ],
          [
            -115.83802,
            47.25291,
            0
          ],
          [
            -115.83765,
            47.25277,
            0
          ],
          [
            -115.83754,
            47.25272,
            0
          ],
          [
            -115.83724,
            47.25256,
            0
          ],
          [
            -115.83699,
            47.25241,
            0
          ],
          [
            -115.83685,
            47.25231,
            0
          ],
          [
            -115.83676,
            47.25226,
            0
          ],
          [
            -115.83609,
            47.25183,
            0
          ],
          [
            -115.8354,
            47.25142,
            0
          ],
          [
            -115.8352,
            47.25132,
            0
          ],
          [
            -115.83482,
            47.25116,
            0
          ],
          [
            -115.83435,
            47.25099,
            0
          ],
          [
            -115.83372,
            47.25081,
            0
          ],
          [
            -115.8335,
            47.25076,
            0
          ],
          [
            -115.83336,
            47.25074,
            0
          ],
          [
            -115.83325,
            47.25071,
            0
          ],
          [
            -115.83256,
            47.25062,
            0
          ],
          [
            -115.8284,
            47.25017,
            0
          ],
          [
            -115.82836,
            47.25017,
            0
          ],
          [
            -115.82477,
            47.24978,
            0
          ],
          [
            -115.82315,
            47.2497,
            0
          ],
          [
            -115.82182,
            47.24971,
            0
          ],
          [
            -115.82089,
            47.24977,
            0
          ],
          [
            -115.82026,
            47.24983,
            0
          ],
          [
            -115.81908,
            47.24997,
            0
          ],
          [
            -115.81795,
            47.25017,
            0
          ],
          [
            -115.81742,
            47.25028,
            0
          ],
          [
            -115.81741,
            47.25028,
            0
          ],
          [
            -115.81437,
            47.2509,
            0
          ],
          [
            -115.81435,
            47.2509,
            0
          ],
          [
            -115.8132,
            47.25114,
            0
          ],
          [
            -115.81286,
            47.2512,
            0
          ],
          [
            -115.81199,
            47.2513,
            0
          ],
          [
            -115.81122,
            47.25136,
            0
          ],
          [
            -115.81072,
            47.25137,
            0
          ],
          [
            -115.81,
            47.25133,
            0
          ],
          [
            -115.80978,
            47.2513,
            0
          ],
          [
            -115.8092,
            47.25119,
            0
          ],
          [
            -115.80892,
            47.25112,
            0
          ],
          [
            -115.80671,
            47.25041,
            0
          ],
          [
            -115.80656,
            47.25037,
            0
          ],
          [
            -115.80623,
            47.25073,
            0
          ]
        ],
			},
			id: "86cd5e29-e6eb-4279-b976-7e5546c42965",
		},
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.5624701,
    //       47.3143529,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "St Maries, ID"
    //   },
    //   id: "e4da9677-230b-4df9-a1d2-4cd36342cc90"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -115.8062282,
    //       47.2507257,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Avery, ID"
    //   },
    //   id: "18e04101-543a-42c6-850e-83d491eaaf26"
    // }
	],
};

export default StJoeRiver;
