import { createSlice } from "@reduxjs/toolkit";
import { vitaDataLayer } from "../../helpers/dataLayer";

const initialState = {
    userName: null,
    userEmail: null,
    userID: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setActiveUser: (state, action) => {
            state.userName = action.payload.userName
            state.userEmail = action.payload.userEmail
            state.userID = action.payload.userID
            vitaDataLayer('user', 'login', action.payload.userID);
        },
        setUserLogOutState: (state) => {
            state.userName = null
            state.userEmail = null
            state.userID = null
        }
    }
});


export const { setActiveUser, setUserLogOutState } = userSlice.actions

export default userSlice.reducer