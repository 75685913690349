import React from "react";
import { useSelector } from "react-redux";
import TravelTipCard from "../Cards/TravelTipCard";

const TravelTipCart = (props) => {

    const ttcart = useSelector((state) => state.ttcart.cartItems);

    return(
        <>
            <div className="itinerary-cart">
                <h3>My Travel Tips</h3>
                <p>Your selected travel tips—Visit Idaho's blog posts—will be saved in your itinerary and exported with your PDF so you can reference them as you embark on your trip to Idaho.</p>
            </div>
            <ul className={`tt-cart-items ${ttcart && ttcart.length === 0 ? 'is-empty' : ''}`}>
                {ttcart && ttcart.length > 0 ? (
                    ttcart.map((item, i) => (
                        <TravelTipCard key={i} item={item} />
                    ))
                ) : (
                    <p>You currently have no saved travel tips. Browse the stories below to add to your trip.</p>
                )}
            </ul>
        </>
    );
}

export default TravelTipCart;