const WildHorseTrail = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Wild Horse Trail Scenic Byway",
				description: "Part of the International Selkirk Loop, the Wild Horse Trail Scenic Byway begins in Sandpoint's scenic downtown, located on the shores of Idaho's largest lake, Lake Pend Oreille. With the dramatic backdrop of the Selkirk Mountains, the route follows the “Wild Horse Trail,” which was crucial for the Kootenai Tribe and settlers alike as this area of northern Idaho grew through the gold rush era. Keep an eye out for wildlife along the byway as you make your way through the McArthur Lake Wildlife Corridor toward the historic gold-mining town of Bonners Ferry and the Kootenai National Wildlife Refuge before ending at Porthill, the international border stop to enter Canada.",
				link: "https://visitidaho.org/things-to-do/road-trips/wild-horse-trail-scenic-byway/",
				route: [
					"Sandpoint", 
					"Bonners Ferry",
          "Porthill"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
          [
            -116.55347,
            48.27658,
            0
          ],
          [
            -116.55348,
            48.27976,
            0
          ],
          [
            -116.55347,
            48.27984,
            0
          ],
          [
            -116.55347,
            48.27989,
            0
          ],
          [
            -116.55346,
            48.27993,
            0
          ],
          [
            -116.55345,
            48.27995,
            0
          ],
          [
            -116.55345,
            48.27997,
            0
          ],
          [
            -116.55344,
            48.28,
            0
          ],
          [
            -116.55344,
            48.28002,
            0
          ],
          [
            -116.55343,
            48.28005,
            0
          ],
          [
            -116.55342,
            48.28007,
            0
          ],
          [
            -116.5534,
            48.28014,
            0
          ],
          [
            -116.55332,
            48.28033,
            0
          ],
          [
            -116.55316,
            48.28053,
            0
          ],
          [
            -116.55307,
            48.28066,
            0
          ],
          [
            -116.55306,
            48.28069,
            0
          ],
          [
            -116.55303,
            48.28075,
            0
          ],
          [
            -116.55297,
            48.2809,
            0
          ],
          [
            -116.55293,
            48.28111,
            0
          ],
          [
            -116.55274,
            48.28272,
            0
          ],
          [
            -116.55269,
            48.28342,
            0
          ],
          [
            -116.55268,
            48.28374,
            0
          ],
          [
            -116.55269,
            48.28401,
            0
          ],
          [
            -116.55271,
            48.28428,
            0
          ],
          [
            -116.55275,
            48.2845,
            0
          ],
          [
            -116.55305,
            48.2867,
            0
          ],
          [
            -116.55308,
            48.28702,
            0
          ],
          [
            -116.5531,
            48.28716,
            0
          ],
          [
            -116.55315,
            48.2877,
            0
          ],
          [
            -116.55316,
            48.28797,
            0
          ],
          [
            -116.55316,
            48.28813,
            0
          ],
          [
            -116.55315,
            48.28829,
            0
          ],
          [
            -116.55312,
            48.28841,
            0
          ],
          [
            -116.55307,
            48.28854,
            0
          ],
          [
            -116.55301,
            48.28867,
            0
          ],
          [
            -116.55294,
            48.2888,
            0
          ],
          [
            -116.55276,
            48.28903,
            0
          ],
          [
            -116.55256,
            48.28921,
            0
          ],
          [
            -116.55243,
            48.28931,
            0
          ],
          [
            -116.55232,
            48.28938,
            0
          ],
          [
            -116.55211,
            48.28949,
            0
          ],
          [
            -116.55202,
            48.28953,
            0
          ],
          [
            -116.55151,
            48.2897,
            0
          ],
          [
            -116.55126,
            48.28976,
            0
          ],
          [
            -116.55125,
            48.28977,
            0
          ],
          [
            -116.5511,
            48.2898,
            0
          ],
          [
            -116.55057,
            48.28993,
            0
          ],
          [
            -116.54875,
            48.29034,
            0
          ],
          [
            -116.54839,
            48.29033,
            0
          ],
          [
            -116.54817,
            48.29035,
            0
          ],
          [
            -116.54807,
            48.29037,
            0
          ],
          [
            -116.54782,
            48.29037,
            0
          ],
          [
            -116.54762,
            48.29033,
            0
          ],
          [
            -116.54755,
            48.2903,
            0
          ],
          [
            -116.54749,
            48.29028,
            0
          ],
          [
            -116.54748,
            48.29028,
            0
          ],
          [
            -116.54742,
            48.29025,
            0
          ],
          [
            -116.54737,
            48.29022,
            0
          ],
          [
            -116.54731,
            48.29017,
            0
          ],
          [
            -116.54728,
            48.29015,
            0
          ],
          [
            -116.54723,
            48.2901,
            0
          ],
          [
            -116.54716,
            48.29001,
            0
          ],
          [
            -116.54711,
            48.2899,
            0
          ],
          [
            -116.54709,
            48.28984,
            0
          ],
          [
            -116.54708,
            48.2898,
            0
          ],
          [
            -116.54708,
            48.28972,
            0
          ],
          [
            -116.54709,
            48.28967,
            0
          ],
          [
            -116.54711,
            48.28961,
            0
          ],
          [
            -116.54713,
            48.28957,
            0
          ],
          [
            -116.54714,
            48.28954,
            0
          ],
          [
            -116.54716,
            48.28951,
            0
          ],
          [
            -116.54727,
            48.2894,
            0
          ],
          [
            -116.54729,
            48.28939,
            0
          ],
          [
            -116.54734,
            48.28935,
            0
          ],
          [
            -116.54743,
            48.2893,
            0
          ],
          [
            -116.5475,
            48.28927,
            0
          ],
          [
            -116.5476,
            48.28924,
            0
          ],
          [
            -116.5478,
            48.2892,
            0
          ],
          [
            -116.54804,
            48.2892,
            0
          ],
          [
            -116.5481,
            48.28921,
            0
          ],
          [
            -116.54815,
            48.28921,
            0
          ],
          [
            -116.54834,
            48.28925,
            0
          ],
          [
            -116.54848,
            48.2893,
            0
          ],
          [
            -116.54856,
            48.28934,
            0
          ],
          [
            -116.54864,
            48.28939,
            0
          ],
          [
            -116.54866,
            48.2894,
            0
          ],
          [
            -116.54871,
            48.28945,
            0
          ],
          [
            -116.54878,
            48.28954,
            0
          ],
          [
            -116.54882,
            48.2896,
            0
          ],
          [
            -116.54884,
            48.28968,
            0
          ],
          [
            -116.54886,
            48.28973,
            0
          ],
          [
            -116.54886,
            48.28979,
            0
          ],
          [
            -116.54887,
            48.28984,
            0
          ],
          [
            -116.54889,
            48.28988,
            0
          ],
          [
            -116.5489,
            48.28991,
            0
          ],
          [
            -116.54894,
            48.28997,
            0
          ],
          [
            -116.54894,
            48.29166,
            0
          ],
          [
            -116.54893,
            48.29188,
            0
          ],
          [
            -116.54893,
            48.29231,
            0
          ],
          [
            -116.54891,
            48.29286,
            0
          ],
          [
            -116.54888,
            48.29318,
            0
          ],
          [
            -116.54882,
            48.29366,
            0
          ],
          [
            -116.54855,
            48.29551,
            0
          ],
          [
            -116.54851,
            48.29571,
            0
          ],
          [
            -116.54842,
            48.29636,
            0
          ],
          [
            -116.5483,
            48.29693,
            0
          ],
          [
            -116.54826,
            48.29717,
            0
          ],
          [
            -116.54822,
            48.29729,
            0
          ],
          [
            -116.54822,
            48.29731,
            0
          ],
          [
            -116.54816,
            48.29753,
            0
          ],
          [
            -116.54779,
            48.29848,
            0
          ],
          [
            -116.54778,
            48.29854,
            0
          ],
          [
            -116.54777,
            48.29858,
            0
          ],
          [
            -116.54775,
            48.29863,
            0
          ],
          [
            -116.54764,
            48.29884,
            0
          ],
          [
            -116.54749,
            48.29917,
            0
          ],
          [
            -116.5474,
            48.29932,
            0
          ],
          [
            -116.54735,
            48.29943,
            0
          ],
          [
            -116.54682,
            48.30041,
            0
          ],
          [
            -116.54658,
            48.30093,
            0
          ],
          [
            -116.54634,
            48.30161,
            0
          ],
          [
            -116.54628,
            48.30187,
            0
          ],
          [
            -116.54621,
            48.30228,
            0
          ],
          [
            -116.54617,
            48.30244,
            0
          ],
          [
            -116.54617,
            48.30249,
            0
          ],
          [
            -116.54613,
            48.30269,
            0
          ],
          [
            -116.54612,
            48.30278,
            0
          ],
          [
            -116.54609,
            48.30322,
            0
          ],
          [
            -116.54611,
            48.30582,
            0
          ],
          [
            -116.54608,
            48.30659,
            0
          ],
          [
            -116.54608,
            48.30755,
            0
          ],
          [
            -116.5461,
            48.30827,
            0
          ],
          [
            -116.54613,
            48.31155,
            0
          ],
          [
            -116.54615,
            48.31218,
            0
          ],
          [
            -116.54614,
            48.31269,
            0
          ],
          [
            -116.54617,
            48.31512,
            0
          ],
          [
            -116.54623,
            48.31529,
            0
          ],
          [
            -116.54621,
            48.31579,
            0
          ],
          [
            -116.5462,
            48.31815,
            0
          ],
          [
            -116.54623,
            48.31967,
            0
          ],
          [
            -116.54622,
            48.32005,
            0
          ],
          [
            -116.54623,
            48.32011,
            0
          ],
          [
            -116.54624,
            48.32096,
            0
          ],
          [
            -116.54622,
            48.32164,
            0
          ],
          [
            -116.54624,
            48.32545,
            0
          ],
          [
            -116.54625,
            48.32575,
            0
          ],
          [
            -116.54627,
            48.32588,
            0
          ],
          [
            -116.54627,
            48.32595,
            0
          ],
          [
            -116.54628,
            48.326,
            0
          ],
          [
            -116.54628,
            48.32605,
            0
          ],
          [
            -116.54629,
            48.32606,
            0
          ],
          [
            -116.54627,
            48.32613,
            0
          ],
          [
            -116.54626,
            48.32624,
            0
          ],
          [
            -116.54639,
            48.3267,
            0
          ],
          [
            -116.54642,
            48.32679,
            0
          ],
          [
            -116.54647,
            48.32691,
            0
          ],
          [
            -116.54649,
            48.32697,
            0
          ],
          [
            -116.54657,
            48.32714,
            0
          ],
          [
            -116.54705,
            48.32802,
            0
          ],
          [
            -116.54712,
            48.32817,
            0
          ],
          [
            -116.54735,
            48.32858,
            0
          ],
          [
            -116.54748,
            48.32884,
            0
          ],
          [
            -116.54753,
            48.32893,
            0
          ],
          [
            -116.54768,
            48.32926,
            0
          ],
          [
            -116.54775,
            48.32944,
            0
          ],
          [
            -116.54778,
            48.32953,
            0
          ],
          [
            -116.5478,
            48.32962,
            0
          ],
          [
            -116.54784,
            48.32975,
            0
          ],
          [
            -116.5479,
            48.33006,
            0
          ],
          [
            -116.54791,
            48.33018,
            0
          ],
          [
            -116.54793,
            48.33033,
            0
          ],
          [
            -116.548,
            48.33039,
            0
          ],
          [
            -116.54801,
            48.33066,
            0
          ],
          [
            -116.54749,
            48.35525,
            0
          ],
          [
            -116.54749,
            48.35598,
            0
          ],
          [
            -116.54737,
            48.36072,
            0
          ],
          [
            -116.54739,
            48.36084,
            0
          ],
          [
            -116.54739,
            48.36131,
            0
          ],
          [
            -116.54729,
            48.36537,
            0
          ],
          [
            -116.54728,
            48.36544,
            0
          ],
          [
            -116.54717,
            48.37133,
            0
          ],
          [
            -116.54711,
            48.37343,
            0
          ],
          [
            -116.5471,
            48.37355,
            0
          ],
          [
            -116.54702,
            48.37405,
            0
          ],
          [
            -116.54699,
            48.37419,
            0
          ],
          [
            -116.54695,
            48.37434,
            0
          ],
          [
            -116.54679,
            48.37485,
            0
          ],
          [
            -116.5467,
            48.37509,
            0
          ],
          [
            -116.54665,
            48.3752,
            0
          ],
          [
            -116.54661,
            48.37531,
            0
          ],
          [
            -116.54631,
            48.3759,
            0
          ],
          [
            -116.5461,
            48.37628,
            0
          ],
          [
            -116.54569,
            48.37707,
            0
          ],
          [
            -116.54537,
            48.37772,
            0
          ],
          [
            -116.54521,
            48.37809,
            0
          ],
          [
            -116.54504,
            48.37853,
            0
          ],
          [
            -116.54486,
            48.37908,
            0
          ],
          [
            -116.54425,
            48.3813,
            0
          ],
          [
            -116.54414,
            48.38167,
            0
          ],
          [
            -116.54392,
            48.38232,
            0
          ],
          [
            -116.54389,
            48.38238,
            0
          ],
          [
            -116.54386,
            48.38246,
            0
          ],
          [
            -116.54354,
            48.38317,
            0
          ],
          [
            -116.54338,
            48.38348,
            0
          ],
          [
            -116.54332,
            48.38361,
            0
          ],
          [
            -116.54324,
            48.38374,
            0
          ],
          [
            -116.54273,
            48.38471,
            0
          ],
          [
            -116.5405,
            48.38867,
            0
          ],
          [
            -116.53866,
            48.39204,
            0
          ],
          [
            -116.53859,
            48.39218,
            0
          ],
          [
            -116.53845,
            48.39243,
            0
          ],
          [
            -116.53814,
            48.39294,
            0
          ],
          [
            -116.53772,
            48.39356,
            0
          ],
          [
            -116.53754,
            48.3938,
            0
          ],
          [
            -116.53744,
            48.39392,
            0
          ],
          [
            -116.53716,
            48.39429,
            0
          ],
          [
            -116.53503,
            48.39691,
            0
          ],
          [
            -116.53483,
            48.39714,
            0
          ],
          [
            -116.5345,
            48.39749,
            0
          ],
          [
            -116.53385,
            48.39814,
            0
          ],
          [
            -116.53318,
            48.39873,
            0
          ],
          [
            -116.53288,
            48.39898,
            0
          ],
          [
            -116.53061,
            48.40076,
            0
          ],
          [
            -116.53046,
            48.40087,
            0
          ],
          [
            -116.53042,
            48.40092,
            0
          ],
          [
            -116.53035,
            48.40098,
            0
          ],
          [
            -116.53025,
            48.40108,
            0
          ],
          [
            -116.53013,
            48.40119,
            0
          ],
          [
            -116.53004,
            48.40128,
            0
          ],
          [
            -116.52985,
            48.40149,
            0
          ],
          [
            -116.52972,
            48.40168,
            0
          ],
          [
            -116.52967,
            48.40174,
            0
          ],
          [
            -116.52958,
            48.40189,
            0
          ],
          [
            -116.52953,
            48.40199,
            0
          ],
          [
            -116.5295,
            48.40204,
            0
          ],
          [
            -116.52946,
            48.40212,
            0
          ],
          [
            -116.52937,
            48.40234,
            0
          ],
          [
            -116.52935,
            48.40238,
            0
          ],
          [
            -116.52933,
            48.40244,
            0
          ],
          [
            -116.52932,
            48.40246,
            0
          ],
          [
            -116.5293,
            48.40253,
            0
          ],
          [
            -116.52877,
            48.40402,
            0
          ],
          [
            -116.52874,
            48.40408,
            0
          ],
          [
            -116.52871,
            48.40418,
            0
          ],
          [
            -116.52855,
            48.40449,
            0
          ],
          [
            -116.5285,
            48.4046,
            0
          ],
          [
            -116.52845,
            48.40467,
            0
          ],
          [
            -116.52822,
            48.40504,
            0
          ],
          [
            -116.52814,
            48.40514,
            0
          ],
          [
            -116.52805,
            48.40527,
            0
          ],
          [
            -116.52584,
            48.40807,
            0
          ],
          [
            -116.52544,
            48.40853,
            0
          ],
          [
            -116.52524,
            48.40873,
            0
          ],
          [
            -116.52514,
            48.40881,
            0
          ],
          [
            -116.52511,
            48.40884,
            0
          ],
          [
            -116.52492,
            48.40899,
            0
          ],
          [
            -116.52467,
            48.40917,
            0
          ],
          [
            -116.5245,
            48.40928,
            0
          ],
          [
            -116.52422,
            48.40944,
            0
          ],
          [
            -116.5239,
            48.4096,
            0
          ],
          [
            -116.52376,
            48.40966,
            0
          ],
          [
            -116.52373,
            48.40968,
            0
          ],
          [
            -116.52364,
            48.40971,
            0
          ],
          [
            -116.52331,
            48.40985,
            0
          ],
          [
            -116.52259,
            48.41012,
            0
          ],
          [
            -116.52247,
            48.41017,
            0
          ],
          [
            -116.51978,
            48.41117,
            0
          ],
          [
            -116.51954,
            48.41128,
            0
          ],
          [
            -116.51934,
            48.41138,
            0
          ],
          [
            -116.51927,
            48.41141,
            0
          ],
          [
            -116.51899,
            48.41157,
            0
          ],
          [
            -116.51864,
            48.4118,
            0
          ],
          [
            -116.51851,
            48.4119,
            0
          ],
          [
            -116.51842,
            48.41198,
            0
          ],
          [
            -116.51832,
            48.41206,
            0
          ],
          [
            -116.51805,
            48.41233,
            0
          ],
          [
            -116.51705,
            48.41352,
            0
          ],
          [
            -116.51684,
            48.41373,
            0
          ],
          [
            -116.51676,
            48.4138,
            0
          ],
          [
            -116.51668,
            48.41388,
            0
          ],
          [
            -116.51656,
            48.41398,
            0
          ],
          [
            -116.51616,
            48.41426,
            0
          ],
          [
            -116.51603,
            48.41434,
            0
          ],
          [
            -116.51602,
            48.41435,
            0
          ],
          [
            -116.51589,
            48.41442,
            0
          ],
          [
            -116.51542,
            48.41465,
            0
          ],
          [
            -116.51523,
            48.41472,
            0
          ],
          [
            -116.51506,
            48.41479,
            0
          ],
          [
            -116.51492,
            48.41484,
            0
          ],
          [
            -116.51446,
            48.41498,
            0
          ],
          [
            -116.51287,
            48.41543,
            0
          ],
          [
            -116.51068,
            48.4161,
            0
          ],
          [
            -116.51011,
            48.41629,
            0
          ],
          [
            -116.50958,
            48.41648,
            0
          ],
          [
            -116.50947,
            48.41651,
            0
          ],
          [
            -116.5094,
            48.41654,
            0
          ],
          [
            -116.50779,
            48.41714,
            0
          ],
          [
            -116.50653,
            48.41764,
            0
          ],
          [
            -116.50545,
            48.4181,
            0
          ],
          [
            -116.5045,
            48.41855,
            0
          ],
          [
            -116.50433,
            48.41864,
            0
          ],
          [
            -116.504,
            48.4188,
            0
          ],
          [
            -116.50289,
            48.41941,
            0
          ],
          [
            -116.50197,
            48.41997,
            0
          ],
          [
            -116.50119,
            48.42049,
            0
          ],
          [
            -116.50077,
            48.42079,
            0
          ],
          [
            -116.50063,
            48.42088,
            0
          ],
          [
            -116.4997,
            48.42153,
            0
          ],
          [
            -116.49958,
            48.42162,
            0
          ],
          [
            -116.4993,
            48.42181,
            0
          ],
          [
            -116.49929,
            48.42182,
            0
          ],
          [
            -116.49906,
            48.42198,
            0
          ],
          [
            -116.499,
            48.42203,
            0
          ],
          [
            -116.49885,
            48.42212,
            0
          ],
          [
            -116.49879,
            48.42217,
            0
          ],
          [
            -116.49873,
            48.42221,
            0
          ],
          [
            -116.49868,
            48.42225,
            0
          ],
          [
            -116.49842,
            48.42243,
            0
          ],
          [
            -116.49831,
            48.4225,
            0
          ],
          [
            -116.49818,
            48.4226,
            0
          ],
          [
            -116.49809,
            48.42266,
            0
          ],
          [
            -116.49801,
            48.42273,
            0
          ],
          [
            -116.49788,
            48.42283,
            0
          ],
          [
            -116.4972,
            48.42344,
            0
          ],
          [
            -116.49681,
            48.42389,
            0
          ],
          [
            -116.49671,
            48.42402,
            0
          ],
          [
            -116.49669,
            48.42403,
            0
          ],
          [
            -116.49647,
            48.42436,
            0
          ],
          [
            -116.49645,
            48.4244,
            0
          ],
          [
            -116.49627,
            48.42469,
            0
          ],
          [
            -116.49564,
            48.42585,
            0
          ],
          [
            -116.49471,
            48.42762,
            0
          ],
          [
            -116.49461,
            48.42765,
            0
          ],
          [
            -116.49445,
            48.42799,
            0
          ],
          [
            -116.49356,
            48.42962,
            0
          ],
          [
            -116.4935,
            48.42976,
            0
          ],
          [
            -116.49203,
            48.43258,
            0
          ],
          [
            -116.49201,
            48.4327,
            0
          ],
          [
            -116.49186,
            48.43297,
            0
          ],
          [
            -116.4916,
            48.43337,
            0
          ],
          [
            -116.49132,
            48.43376,
            0
          ],
          [
            -116.49125,
            48.43385,
            0
          ],
          [
            -116.49124,
            48.43386,
            0
          ],
          [
            -116.49116,
            48.43396,
            0
          ],
          [
            -116.49109,
            48.43406,
            0
          ],
          [
            -116.49098,
            48.4342,
            0
          ],
          [
            -116.49086,
            48.43437,
            0
          ],
          [
            -116.49062,
            48.43468,
            0
          ],
          [
            -116.47908,
            48.45056,
            0
          ],
          [
            -116.47857,
            48.45124,
            0
          ],
          [
            -116.47842,
            48.45146,
            0
          ],
          [
            -116.47709,
            48.4533,
            0
          ],
          [
            -116.47706,
            48.45337,
            0
          ],
          [
            -116.47695,
            48.45354,
            0
          ],
          [
            -116.47688,
            48.45368,
            0
          ],
          [
            -116.47671,
            48.45397,
            0
          ],
          [
            -116.47578,
            48.45579,
            0
          ],
          [
            -116.46417,
            48.47788,
            0
          ],
          [
            -116.46415,
            48.47791,
            0
          ],
          [
            -116.46339,
            48.47937,
            0
          ],
          [
            -116.46333,
            48.47946,
            0
          ],
          [
            -116.45937,
            48.48707,
            0
          ],
          [
            -116.45902,
            48.48772,
            0
          ],
          [
            -116.45892,
            48.48788,
            0
          ],
          [
            -116.45887,
            48.48797,
            0
          ],
          [
            -116.45845,
            48.48862,
            0
          ],
          [
            -116.45837,
            48.48873,
            0
          ],
          [
            -116.45828,
            48.48884,
            0
          ],
          [
            -116.45818,
            48.48898,
            0
          ],
          [
            -116.45806,
            48.48912,
            0
          ],
          [
            -116.45771,
            48.48956,
            0
          ],
          [
            -116.45766,
            48.48963,
            0
          ],
          [
            -116.45129,
            48.49779,
            0
          ],
          [
            -116.4512,
            48.49792,
            0
          ],
          [
            -116.45111,
            48.49802,
            0
          ],
          [
            -116.45098,
            48.49819,
            0
          ],
          [
            -116.4508,
            48.49841,
            0
          ],
          [
            -116.45048,
            48.49883,
            0
          ],
          [
            -116.45037,
            48.49896,
            0
          ],
          [
            -116.45029,
            48.49907,
            0
          ],
          [
            -116.44974,
            48.49977,
            0
          ],
          [
            -116.44971,
            48.4998,
            0
          ],
          [
            -116.44963,
            48.49991,
            0
          ],
          [
            -116.44961,
            48.49993,
            0
          ],
          [
            -116.44907,
            48.50062,
            0
          ],
          [
            -116.4488,
            48.50095,
            0
          ],
          [
            -116.44861,
            48.5012,
            0
          ],
          [
            -116.44831,
            48.50163,
            0
          ],
          [
            -116.44816,
            48.50187,
            0
          ],
          [
            -116.44805,
            48.50206,
            0
          ],
          [
            -116.44801,
            48.50214,
            0
          ],
          [
            -116.44793,
            48.50227,
            0
          ],
          [
            -116.44698,
            48.50413,
            0
          ],
          [
            -116.44671,
            48.50463,
            0
          ],
          [
            -116.44662,
            48.50482,
            0
          ],
          [
            -116.44644,
            48.50516,
            0
          ],
          [
            -116.44621,
            48.50565,
            0
          ],
          [
            -116.44616,
            48.50578,
            0
          ],
          [
            -116.4461,
            48.50591,
            0
          ],
          [
            -116.446,
            48.50617,
            0
          ],
          [
            -116.44586,
            48.50658,
            0
          ],
          [
            -116.44569,
            48.50714,
            0
          ],
          [
            -116.44543,
            48.50792,
            0
          ],
          [
            -116.44527,
            48.50845,
            0
          ],
          [
            -116.44522,
            48.50859,
            0
          ],
          [
            -116.44514,
            48.50885,
            0
          ],
          [
            -116.44505,
            48.50911,
            0
          ],
          [
            -116.44501,
            48.50925,
            0
          ],
          [
            -116.44496,
            48.50938,
            0
          ],
          [
            -116.44484,
            48.50979,
            0
          ],
          [
            -116.44474,
            48.51007,
            0
          ],
          [
            -116.4444,
            48.51112,
            0
          ],
          [
            -116.4444,
            48.51113,
            0
          ],
          [
            -116.44418,
            48.5118,
            0
          ],
          [
            -116.44369,
            48.51338,
            0
          ],
          [
            -116.44363,
            48.51366,
            0
          ],
          [
            -116.44362,
            48.51381,
            0
          ],
          [
            -116.4436,
            48.51397,
            0
          ],
          [
            -116.44362,
            48.51711,
            0
          ],
          [
            -116.44361,
            48.5174,
            0
          ],
          [
            -116.44359,
            48.51767,
            0
          ],
          [
            -116.44357,
            48.51779,
            0
          ],
          [
            -116.44356,
            48.51782,
            0
          ],
          [
            -116.44355,
            48.51791,
            0
          ],
          [
            -116.44352,
            48.51801,
            0
          ],
          [
            -116.44347,
            48.51814,
            0
          ],
          [
            -116.44346,
            48.51818,
            0
          ],
          [
            -116.44338,
            48.51835,
            0
          ],
          [
            -116.44334,
            48.51842,
            0
          ],
          [
            -116.44329,
            48.51849,
            0
          ],
          [
            -116.44328,
            48.51852,
            0
          ],
          [
            -116.4432,
            48.51864,
            0
          ],
          [
            -116.44307,
            48.5188,
            0
          ],
          [
            -116.443,
            48.51887,
            0
          ],
          [
            -116.44291,
            48.51897,
            0
          ],
          [
            -116.44275,
            48.51911,
            0
          ],
          [
            -116.44259,
            48.51923,
            0
          ],
          [
            -116.44254,
            48.51926,
            0
          ],
          [
            -116.44245,
            48.51933,
            0
          ],
          [
            -116.44216,
            48.51952,
            0
          ],
          [
            -116.44212,
            48.51954,
            0
          ],
          [
            -116.44135,
            48.52,
            0
          ],
          [
            -116.44122,
            48.52009,
            0
          ],
          [
            -116.44091,
            48.52028,
            0
          ],
          [
            -116.44067,
            48.52045,
            0
          ],
          [
            -116.44066,
            48.52045,
            0
          ],
          [
            -116.44056,
            48.52052,
            0
          ],
          [
            -116.44048,
            48.52059,
            0
          ],
          [
            -116.44034,
            48.52069,
            0
          ],
          [
            -116.44011,
            48.5209,
            0
          ],
          [
            -116.43982,
            48.52119,
            0
          ],
          [
            -116.4371,
            48.52412,
            0
          ],
          [
            -116.43694,
            48.52428,
            0
          ],
          [
            -116.43662,
            48.52455,
            0
          ],
          [
            -116.43647,
            48.52466,
            0
          ],
          [
            -116.43643,
            48.5247,
            0
          ],
          [
            -116.43632,
            48.52479,
            0
          ],
          [
            -116.43618,
            48.52488,
            0
          ],
          [
            -116.4359,
            48.52509,
            0
          ],
          [
            -116.43588,
            48.5251,
            0
          ],
          [
            -116.43569,
            48.52523,
            0
          ],
          [
            -116.43507,
            48.5256,
            0
          ],
          [
            -116.43486,
            48.52571,
            0
          ],
          [
            -116.43247,
            48.52705,
            0
          ],
          [
            -116.43219,
            48.52723,
            0
          ],
          [
            -116.43206,
            48.52733,
            0
          ],
          [
            -116.43193,
            48.52742,
            0
          ],
          [
            -116.43126,
            48.52795,
            0
          ],
          [
            -116.42392,
            48.534,
            0
          ],
          [
            -116.42212,
            48.53552,
            0
          ],
          [
            -116.41923,
            48.53789,
            0
          ],
          [
            -116.41858,
            48.53844,
            0
          ],
          [
            -116.41619,
            48.5404,
            0
          ],
          [
            -116.41616,
            48.54043,
            0
          ],
          [
            -116.41483,
            48.54154,
            0
          ],
          [
            -116.41429,
            48.54197,
            0
          ],
          [
            -116.41379,
            48.54239,
            0
          ],
          [
            -116.41376,
            48.54241,
            0
          ],
          [
            -116.41291,
            48.54313,
            0
          ],
          [
            -116.41209,
            48.5438,
            0
          ],
          [
            -116.41072,
            48.54496,
            0
          ],
          [
            -116.40762,
            48.54748,
            0
          ],
          [
            -116.40724,
            48.5478,
            0
          ],
          [
            -116.40682,
            48.54813,
            0
          ],
          [
            -116.40667,
            48.54826,
            0
          ],
          [
            -116.40656,
            48.54834,
            0
          ],
          [
            -116.40652,
            48.54838,
            0
          ],
          [
            -116.40647,
            48.54842,
            0
          ],
          [
            -116.40644,
            48.54844,
            0
          ],
          [
            -116.40625,
            48.54861,
            0
          ],
          [
            -116.4062,
            48.54866,
            0
          ],
          [
            -116.40615,
            48.5487,
            0
          ],
          [
            -116.40606,
            48.54879,
            0
          ],
          [
            -116.40594,
            48.5489,
            0
          ],
          [
            -116.4057,
            48.54914,
            0
          ],
          [
            -116.40564,
            48.54921,
            0
          ],
          [
            -116.40555,
            48.5493,
            0
          ],
          [
            -116.40546,
            48.54941,
            0
          ],
          [
            -116.4054,
            48.54947,
            0
          ],
          [
            -116.40536,
            48.54953,
            0
          ],
          [
            -116.40513,
            48.54981,
            0
          ],
          [
            -116.40395,
            48.55144,
            0
          ],
          [
            -116.40387,
            48.55156,
            0
          ],
          [
            -116.40318,
            48.55251,
            0
          ],
          [
            -116.40313,
            48.55257,
            0
          ],
          [
            -116.40224,
            48.55382,
            0
          ],
          [
            -116.40208,
            48.55403,
            0
          ],
          [
            -116.39566,
            48.56293,
            0
          ],
          [
            -116.39533,
            48.56337,
            0
          ],
          [
            -116.39532,
            48.56339,
            0
          ],
          [
            -116.39402,
            48.56523,
            0
          ],
          [
            -116.3936,
            48.5658,
            0
          ],
          [
            -116.39354,
            48.56589,
            0
          ],
          [
            -116.39287,
            48.56679,
            0
          ],
          [
            -116.39255,
            48.56727,
            0
          ],
          [
            -116.39254,
            48.56729,
            0
          ],
          [
            -116.39212,
            48.56795,
            0
          ],
          [
            -116.39128,
            48.56942,
            0
          ],
          [
            -116.39117,
            48.56964,
            0
          ],
          [
            -116.39109,
            48.56978,
            0
          ],
          [
            -116.39098,
            48.57001,
            0
          ],
          [
            -116.39085,
            48.57026,
            0
          ],
          [
            -116.3905,
            48.57102,
            0
          ],
          [
            -116.39049,
            48.57105,
            0
          ],
          [
            -116.39006,
            48.57209,
            0
          ],
          [
            -116.39002,
            48.57221,
            0
          ],
          [
            -116.38985,
            48.57266,
            0
          ],
          [
            -116.38939,
            48.57406,
            0
          ],
          [
            -116.38924,
            48.57448,
            0
          ],
          [
            -116.38923,
            48.57452,
            0
          ],
          [
            -116.38921,
            48.57455,
            0
          ],
          [
            -116.38917,
            48.57468,
            0
          ],
          [
            -116.38915,
            48.57472,
            0
          ],
          [
            -116.38908,
            48.57492,
            0
          ],
          [
            -116.38903,
            48.57502,
            0
          ],
          [
            -116.38875,
            48.57566,
            0
          ],
          [
            -116.38747,
            48.57825,
            0
          ],
          [
            -116.38735,
            48.57851,
            0
          ],
          [
            -116.38724,
            48.57872,
            0
          ],
          [
            -116.3871,
            48.57901,
            0
          ],
          [
            -116.38695,
            48.57935,
            0
          ],
          [
            -116.3866,
            48.58003,
            0
          ],
          [
            -116.38657,
            48.58008,
            0
          ],
          [
            -116.38066,
            48.59204,
            0
          ],
          [
            -116.38064,
            48.59207,
            0
          ],
          [
            -116.37957,
            48.59425,
            0
          ],
          [
            -116.3795,
            48.59438,
            0
          ],
          [
            -116.37911,
            48.59519,
            0
          ],
          [
            -116.37892,
            48.59556,
            0
          ],
          [
            -116.37841,
            48.59661,
            0
          ],
          [
            -116.37824,
            48.59694,
            0
          ],
          [
            -116.37764,
            48.59818,
            0
          ],
          [
            -116.37613,
            48.6012,
            0
          ],
          [
            -116.37609,
            48.60129,
            0
          ],
          [
            -116.37595,
            48.60156,
            0
          ],
          [
            -116.37588,
            48.60167,
            0
          ],
          [
            -116.3758,
            48.60182,
            0
          ],
          [
            -116.37575,
            48.6019,
            0
          ],
          [
            -116.37568,
            48.60199,
            0
          ],
          [
            -116.37566,
            48.60204,
            0
          ],
          [
            -116.37559,
            48.60213,
            0
          ],
          [
            -116.37555,
            48.60219,
            0
          ],
          [
            -116.37551,
            48.60224,
            0
          ],
          [
            -116.37544,
            48.60231,
            0
          ],
          [
            -116.37529,
            48.60249,
            0
          ],
          [
            -116.37515,
            48.60263,
            0
          ],
          [
            -116.37507,
            48.60272,
            0
          ],
          [
            -116.37479,
            48.60297,
            0
          ],
          [
            -116.3745,
            48.6032,
            0
          ],
          [
            -116.3745,
            48.60321,
            0
          ],
          [
            -116.37446,
            48.60323,
            0
          ],
          [
            -116.37442,
            48.60327,
            0
          ],
          [
            -116.37416,
            48.60344,
            0
          ],
          [
            -116.3741,
            48.60347,
            0
          ],
          [
            -116.37408,
            48.60349,
            0
          ],
          [
            -116.37401,
            48.60352,
            0
          ],
          [
            -116.37389,
            48.6036,
            0
          ],
          [
            -116.37387,
            48.6036,
            0
          ],
          [
            -116.37381,
            48.60364,
            0
          ],
          [
            -116.37348,
            48.60382,
            0
          ],
          [
            -116.37254,
            48.6043,
            0
          ],
          [
            -116.37248,
            48.60434,
            0
          ],
          [
            -116.36711,
            48.60715,
            0
          ],
          [
            -116.36663,
            48.60739,
            0
          ],
          [
            -116.3662,
            48.60762,
            0
          ],
          [
            -116.36608,
            48.60769,
            0
          ],
          [
            -116.3658,
            48.60783,
            0
          ],
          [
            -116.36577,
            48.60785,
            0
          ],
          [
            -116.36407,
            48.60874,
            0
          ],
          [
            -116.36396,
            48.60879,
            0
          ],
          [
            -116.36388,
            48.60884,
            0
          ],
          [
            -116.36368,
            48.60894,
            0
          ],
          [
            -116.36343,
            48.60908,
            0
          ],
          [
            -116.36332,
            48.60913,
            0
          ],
          [
            -116.36174,
            48.60998,
            0
          ],
          [
            -116.3611,
            48.61036,
            0
          ],
          [
            -116.36039,
            48.61084,
            0
          ],
          [
            -116.36037,
            48.61086,
            0
          ],
          [
            -116.36028,
            48.61092,
            0
          ],
          [
            -116.36021,
            48.61098,
            0
          ],
          [
            -116.36019,
            48.61099,
            0
          ],
          [
            -116.35941,
            48.61174,
            0
          ],
          [
            -116.35799,
            48.61326,
            0
          ],
          [
            -116.35785,
            48.61342,
            0
          ],
          [
            -116.35733,
            48.61396,
            0
          ],
          [
            -116.35674,
            48.61462,
            0
          ],
          [
            -116.35669,
            48.61467,
            0
          ],
          [
            -116.3564,
            48.61499,
            0
          ],
          [
            -116.35213,
            48.61949,
            0
          ],
          [
            -116.35172,
            48.61995,
            0
          ],
          [
            -116.35161,
            48.62009,
            0
          ],
          [
            -116.35153,
            48.62018,
            0
          ],
          [
            -116.35146,
            48.62027,
            0
          ],
          [
            -116.35141,
            48.62035,
            0
          ],
          [
            -116.35137,
            48.6204,
            0
          ],
          [
            -116.35128,
            48.62054,
            0
          ],
          [
            -116.35126,
            48.62058,
            0
          ],
          [
            -116.35105,
            48.62091,
            0
          ],
          [
            -116.35099,
            48.62102,
            0
          ],
          [
            -116.35094,
            48.6211,
            0
          ],
          [
            -116.35089,
            48.6212,
            0
          ],
          [
            -116.35085,
            48.62127,
            0
          ],
          [
            -116.35082,
            48.62134,
            0
          ],
          [
            -116.35073,
            48.62152,
            0
          ],
          [
            -116.35056,
            48.62194,
            0
          ],
          [
            -116.35052,
            48.62208,
            0
          ],
          [
            -116.35048,
            48.62218,
            0
          ],
          [
            -116.35042,
            48.6224,
            0
          ],
          [
            -116.35039,
            48.62248,
            0
          ],
          [
            -116.35039,
            48.62249,
            0
          ],
          [
            -116.34951,
            48.62603,
            0
          ],
          [
            -116.34946,
            48.62621,
            0
          ],
          [
            -116.34945,
            48.62623,
            0
          ],
          [
            -116.3494,
            48.62639,
            0
          ],
          [
            -116.34937,
            48.62646,
            0
          ],
          [
            -116.3493,
            48.62666,
            0
          ],
          [
            -116.34917,
            48.62698,
            0
          ],
          [
            -116.34912,
            48.62708,
            0
          ],
          [
            -116.3491,
            48.62714,
            0
          ],
          [
            -116.34854,
            48.62828,
            0
          ],
          [
            -116.3485,
            48.62837,
            0
          ],
          [
            -116.34828,
            48.62897,
            0
          ],
          [
            -116.3482,
            48.62931,
            0
          ],
          [
            -116.34819,
            48.62933,
            0
          ],
          [
            -116.34815,
            48.62962,
            0
          ],
          [
            -116.34813,
            48.63002,
            0
          ],
          [
            -116.34813,
            48.63025,
            0
          ],
          [
            -116.34814,
            48.63043,
            0
          ],
          [
            -116.34814,
            48.63063,
            0
          ],
          [
            -116.34812,
            48.63077,
            0
          ],
          [
            -116.34811,
            48.6309,
            0
          ],
          [
            -116.34807,
            48.63113,
            0
          ],
          [
            -116.34785,
            48.63199,
            0
          ],
          [
            -116.34782,
            48.63215,
            0
          ],
          [
            -116.34776,
            48.63237,
            0
          ],
          [
            -116.34763,
            48.63297,
            0
          ],
          [
            -116.3476,
            48.63308,
            0
          ],
          [
            -116.34759,
            48.63317,
            0
          ],
          [
            -116.34757,
            48.63327,
            0
          ],
          [
            -116.34757,
            48.63355,
            0
          ],
          [
            -116.34765,
            48.63416,
            0
          ],
          [
            -116.34768,
            48.63431,
            0
          ],
          [
            -116.34776,
            48.63463,
            0
          ],
          [
            -116.34807,
            48.63561,
            0
          ],
          [
            -116.34925,
            48.63897,
            0
          ],
          [
            -116.34928,
            48.63908,
            0
          ],
          [
            -116.34969,
            48.64032,
            0
          ],
          [
            -116.34975,
            48.64048,
            0
          ],
          [
            -116.35054,
            48.64282,
            0
          ],
          [
            -116.3506,
            48.64303,
            0
          ],
          [
            -116.35066,
            48.64329,
            0
          ],
          [
            -116.35066,
            48.64333,
            0
          ],
          [
            -116.35068,
            48.64344,
            0
          ],
          [
            -116.35069,
            48.64347,
            0
          ],
          [
            -116.35069,
            48.64352,
            0
          ],
          [
            -116.35071,
            48.6436,
            0
          ],
          [
            -116.35071,
            48.64368,
            0
          ],
          [
            -116.35072,
            48.64376,
            0
          ],
          [
            -116.35072,
            48.64383,
            0
          ],
          [
            -116.35073,
            48.64385,
            0
          ],
          [
            -116.35073,
            48.64402,
            0
          ],
          [
            -116.35072,
            48.6441,
            0
          ],
          [
            -116.35072,
            48.64417,
            0
          ],
          [
            -116.35071,
            48.64428,
            0
          ],
          [
            -116.35071,
            48.64437,
            0
          ],
          [
            -116.3507,
            48.64439,
            0
          ],
          [
            -116.3507,
            48.64447,
            0
          ],
          [
            -116.35069,
            48.64455,
            0
          ],
          [
            -116.35064,
            48.64479,
            0
          ],
          [
            -116.35059,
            48.64497,
            0
          ],
          [
            -116.35043,
            48.64541,
            0
          ],
          [
            -116.35025,
            48.64577,
            0
          ],
          [
            -116.35021,
            48.64586,
            0
          ],
          [
            -116.35016,
            48.64594,
            0
          ],
          [
            -116.35015,
            48.64597,
            0
          ],
          [
            -116.35007,
            48.6461,
            0
          ],
          [
            -116.34994,
            48.64629,
            0
          ],
          [
            -116.34962,
            48.6467,
            0
          ],
          [
            -116.34941,
            48.64691,
            0
          ],
          [
            -116.3493,
            48.64703,
            0
          ],
          [
            -116.34918,
            48.64713,
            0
          ],
          [
            -116.34871,
            48.64755,
            0
          ],
          [
            -116.34867,
            48.64758,
            0
          ],
          [
            -116.34835,
            48.64785,
            0
          ],
          [
            -116.3483,
            48.6479,
            0
          ],
          [
            -116.34804,
            48.64811,
            0
          ],
          [
            -116.34798,
            48.64815,
            0
          ],
          [
            -116.34762,
            48.64846,
            0
          ],
          [
            -116.34727,
            48.64878,
            0
          ],
          [
            -116.34723,
            48.64883,
            0
          ],
          [
            -116.34718,
            48.64887,
            0
          ],
          [
            -116.34708,
            48.64898,
            0
          ],
          [
            -116.347,
            48.64906,
            0
          ],
          [
            -116.34697,
            48.6491,
            0
          ],
          [
            -116.34692,
            48.64915,
            0
          ],
          [
            -116.34687,
            48.64922,
            0
          ],
          [
            -116.3468,
            48.6493,
            0
          ],
          [
            -116.34672,
            48.64941,
            0
          ],
          [
            -116.34664,
            48.64951,
            0
          ],
          [
            -116.34658,
            48.64961,
            0
          ],
          [
            -116.34657,
            48.64961,
            0
          ],
          [
            -116.3464,
            48.64987,
            0
          ],
          [
            -116.34637,
            48.64993,
            0
          ],
          [
            -116.34634,
            48.64998,
            0
          ],
          [
            -116.3462,
            48.65027,
            0
          ],
          [
            -116.34616,
            48.65037,
            0
          ],
          [
            -116.3461,
            48.65055,
            0
          ],
          [
            -116.34607,
            48.65061,
            0
          ],
          [
            -116.34569,
            48.65157,
            0
          ],
          [
            -116.3456,
            48.65183,
            0
          ],
          [
            -116.34559,
            48.65187,
            0
          ],
          [
            -116.34554,
            48.65197,
            0
          ],
          [
            -116.34532,
            48.65237,
            0
          ],
          [
            -116.34531,
            48.65237,
            0
          ],
          [
            -116.34527,
            48.65244,
            0
          ],
          [
            -116.34522,
            48.65251,
            0
          ],
          [
            -116.34521,
            48.65253,
            0
          ],
          [
            -116.34518,
            48.65257,
            0
          ],
          [
            -116.34515,
            48.65262,
            0
          ],
          [
            -116.34499,
            48.65283,
            0
          ],
          [
            -116.34476,
            48.6531,
            0
          ],
          [
            -116.34451,
            48.65335,
            0
          ],
          [
            -116.34433,
            48.6535,
            0
          ],
          [
            -116.34422,
            48.6536,
            0
          ],
          [
            -116.34412,
            48.65368,
            0
          ],
          [
            -116.34409,
            48.6537,
            0
          ],
          [
            -116.34402,
            48.65376,
            0
          ],
          [
            -116.34392,
            48.65383,
            0
          ],
          [
            -116.3438,
            48.65393,
            0
          ],
          [
            -116.34202,
            48.65531,
            0
          ],
          [
            -116.34198,
            48.65535,
            0
          ],
          [
            -116.34183,
            48.65546,
            0
          ],
          [
            -116.34139,
            48.65584,
            0
          ],
          [
            -116.3412,
            48.65603,
            0
          ],
          [
            -116.34113,
            48.65609,
            0
          ],
          [
            -116.34095,
            48.65629,
            0
          ],
          [
            -116.34087,
            48.65639,
            0
          ],
          [
            -116.34082,
            48.65646,
            0
          ],
          [
            -116.3407,
            48.65661,
            0
          ],
          [
            -116.34053,
            48.65685,
            0
          ],
          [
            -116.34032,
            48.65718,
            0
          ],
          [
            -116.34012,
            48.65752,
            0
          ],
          [
            -116.34008,
            48.6576,
            0
          ],
          [
            -116.33858,
            48.66011,
            0
          ],
          [
            -116.33843,
            48.66033,
            0
          ],
          [
            -116.33821,
            48.6607,
            0
          ],
          [
            -116.3381,
            48.66087,
            0
          ],
          [
            -116.33784,
            48.66131,
            0
          ],
          [
            -116.33777,
            48.66144,
            0
          ],
          [
            -116.33776,
            48.66145,
            0
          ],
          [
            -116.3377,
            48.66157,
            0
          ],
          [
            -116.33767,
            48.66164,
            0
          ],
          [
            -116.33763,
            48.66172,
            0
          ],
          [
            -116.3376,
            48.66179,
            0
          ],
          [
            -116.33755,
            48.66189,
            0
          ],
          [
            -116.33752,
            48.66196,
            0
          ],
          [
            -116.3375,
            48.66203,
            0
          ],
          [
            -116.33747,
            48.66211,
            0
          ],
          [
            -116.33743,
            48.66224,
            0
          ],
          [
            -116.33741,
            48.66229,
            0
          ],
          [
            -116.3374,
            48.66234,
            0
          ],
          [
            -116.33738,
            48.66241,
            0
          ],
          [
            -116.33729,
            48.66282,
            0
          ],
          [
            -116.33727,
            48.66302,
            0
          ],
          [
            -116.33725,
            48.66336,
            0
          ],
          [
            -116.33726,
            48.66706,
            0
          ],
          [
            -116.33728,
            48.66787,
            0
          ],
          [
            -116.33729,
            48.67063,
            0
          ],
          [
            -116.33728,
            48.67129,
            0
          ],
          [
            -116.33729,
            48.67146,
            0
          ],
          [
            -116.33729,
            48.67215,
            0
          ],
          [
            -116.33728,
            48.67224,
            0
          ],
          [
            -116.33728,
            48.67231,
            0
          ],
          [
            -116.33727,
            48.67238,
            0
          ],
          [
            -116.33726,
            48.67242,
            0
          ],
          [
            -116.33726,
            48.67246,
            0
          ],
          [
            -116.33725,
            48.67248,
            0
          ],
          [
            -116.33725,
            48.67251,
            0
          ],
          [
            -116.33722,
            48.67262,
            0
          ],
          [
            -116.33721,
            48.67268,
            0
          ],
          [
            -116.33717,
            48.67279,
            0
          ],
          [
            -116.33714,
            48.6729,
            0
          ],
          [
            -116.33712,
            48.67293,
            0
          ],
          [
            -116.3371,
            48.67297,
            0
          ],
          [
            -116.33708,
            48.67302,
            0
          ],
          [
            -116.33702,
            48.67314,
            0
          ],
          [
            -116.33692,
            48.6733,
            0
          ],
          [
            -116.3369,
            48.67332,
            0
          ],
          [
            -116.33685,
            48.67339,
            0
          ],
          [
            -116.33682,
            48.67342,
            0
          ],
          [
            -116.33678,
            48.67348,
            0
          ],
          [
            -116.33674,
            48.67351,
            0
          ],
          [
            -116.33669,
            48.67358,
            0
          ],
          [
            -116.33666,
            48.6736,
            0
          ],
          [
            -116.3365,
            48.67376,
            0
          ],
          [
            -116.33631,
            48.67391,
            0
          ],
          [
            -116.33034,
            48.67792,
            0
          ],
          [
            -116.32999,
            48.67814,
            0
          ],
          [
            -116.32994,
            48.67818,
            0
          ],
          [
            -116.32196,
            48.68354,
            0
          ],
          [
            -116.31984,
            48.68499,
            0
          ],
          [
            -116.31969,
            48.68508,
            0
          ],
          [
            -116.3168,
            48.68701,
            0
          ],
          [
            -116.31674,
            48.68704,
            0
          ],
          [
            -116.31664,
            48.68712,
            0
          ],
          [
            -116.31662,
            48.68713,
            0
          ],
          [
            -116.31657,
            48.68717,
            0
          ],
          [
            -116.31653,
            48.68721,
            0
          ],
          [
            -116.31649,
            48.68724,
            0
          ],
          [
            -116.31643,
            48.6873,
            0
          ],
          [
            -116.31638,
            48.68737,
            0
          ],
          [
            -116.31634,
            48.68741,
            0
          ],
          [
            -116.31631,
            48.68747,
            0
          ],
          [
            -116.31629,
            48.68753,
            0
          ],
          [
            -116.31628,
            48.68755,
            0
          ],
          [
            -116.31626,
            48.68762,
            0
          ],
          [
            -116.31626,
            48.68766,
            0
          ],
          [
            -116.31625,
            48.68769,
            0
          ],
          [
            -116.31624,
            48.68774,
            0
          ],
          [
            -116.31624,
            48.68957,
            0
          ],
          [
            -116.31627,
            48.69087,
            0
          ],
          [
            -116.31629,
            48.69109,
            0
          ],
          [
            -116.31633,
            48.69138,
            0
          ],
          [
            -116.31638,
            48.69162,
            0
          ],
          [
            -116.31641,
            48.69181,
            0
          ],
          [
            -116.31639,
            48.69185,
            0
          ],
          [
            -116.31638,
            48.69186,
            0
          ],
          [
            -116.31638,
            48.69192,
            0
          ],
          [
            -116.31639,
            48.69194,
            0
          ],
          [
            -116.3164,
            48.69214,
            0
          ],
          [
            -116.31641,
            48.69217,
            0
          ],
          [
            -116.31641,
            48.69222,
            0
          ],
          [
            -116.3164,
            48.69229,
            0
          ],
          [
            -116.3164,
            48.69237,
            0
          ],
          [
            -116.31639,
            48.6924,
            0
          ],
          [
            -116.31639,
            48.69245,
            0
          ],
          [
            -116.31638,
            48.69249,
            0
          ],
          [
            -116.31636,
            48.69252,
            0
          ],
          [
            -116.31636,
            48.69254,
            0
          ],
          [
            -116.31634,
            48.69258,
            0
          ],
          [
            -116.31633,
            48.69261,
            0
          ],
          [
            -116.3163,
            48.69265,
            0
          ],
          [
            -116.31624,
            48.69275,
            0
          ],
          [
            -116.31621,
            48.69279,
            0
          ],
          [
            -116.31611,
            48.69289,
            0
          ],
          [
            -116.31606,
            48.69293,
            0
          ],
          [
            -116.31603,
            48.69296,
            0
          ],
          [
            -116.31599,
            48.69299,
            0
          ],
          [
            -116.3158,
            48.69311,
            0
          ],
          [
            -116.31538,
            48.69332,
            0
          ],
          [
            -116.31291,
            48.69445,
            0
          ],
          [
            -116.31153,
            48.69502,
            0
          ],
          [
            -116.31089,
            48.6953,
            0
          ],
          [
            -116.31033,
            48.69556,
            0
          ],
          [
            -116.3103,
            48.69558,
            0
          ],
          [
            -116.31015,
            48.69565,
            0
          ],
          [
            -116.31012,
            48.69566,
            0
          ],
          [
            -116.3101,
            48.69567,
            0
          ],
          [
            -116.31008,
            48.69569,
            0
          ],
          [
            -116.31005,
            48.6957,
            0
          ],
          [
            -116.30982,
            48.69584,
            0
          ],
          [
            -116.30975,
            48.69589,
            0
          ],
          [
            -116.30969,
            48.69594,
            0
          ],
          [
            -116.30953,
            48.6961,
            0
          ],
          [
            -116.3094,
            48.69633,
            0
          ],
          [
            -116.30939,
            48.69637,
            0
          ],
          [
            -116.30939,
            48.69638,
            0
          ],
          [
            -116.30938,
            48.69641,
            0
          ],
          [
            -116.30936,
            48.69649,
            0
          ],
          [
            -116.30936,
            48.69658,
            0
          ],
          [
            -116.30935,
            48.69668,
            0
          ],
          [
            -116.30936,
            48.69672,
            0
          ],
          [
            -116.30936,
            48.69675,
            0
          ],
          [
            -116.30937,
            48.69681,
            0
          ],
          [
            -116.30941,
            48.69693,
            0
          ],
          [
            -116.30943,
            48.69697,
            0
          ],
          [
            -116.30943,
            48.69698,
            0
          ],
          [
            -116.30947,
            48.69704,
            0
          ],
          [
            -116.30948,
            48.69708,
            0
          ],
          [
            -116.30952,
            48.69713,
            0
          ],
          [
            -116.30954,
            48.69717,
            0
          ],
          [
            -116.30957,
            48.6972,
            0
          ],
          [
            -116.30959,
            48.69724,
            0
          ],
          [
            -116.30961,
            48.69726,
            0
          ],
          [
            -116.30964,
            48.69728,
            0
          ],
          [
            -116.30965,
            48.6973,
            0
          ],
          [
            -116.30973,
            48.69738,
            0
          ],
          [
            -116.30982,
            48.69745,
            0
          ],
          [
            -116.30987,
            48.69748,
            0
          ],
          [
            -116.30993,
            48.69753,
            0
          ],
          [
            -116.30997,
            48.69755,
            0
          ],
          [
            -116.31001,
            48.69758,
            0
          ],
          [
            -116.3102,
            48.69767,
            0
          ],
          [
            -116.31023,
            48.69769,
            0
          ],
          [
            -116.31025,
            48.6977,
            0
          ],
          [
            -116.31118,
            48.69805,
            0
          ],
          [
            -116.31145,
            48.69818,
            0
          ],
          [
            -116.31152,
            48.69822,
            0
          ],
          [
            -116.31166,
            48.69829,
            0
          ],
          [
            -116.31182,
            48.6984,
            0
          ],
          [
            -116.31183,
            48.6984,
            0
          ],
          [
            -116.31185,
            48.69841,
            0
          ],
          [
            -116.31191,
            48.69843,
            0
          ],
          [
            -116.31193,
            48.69843,
            0
          ],
          [
            -116.312,
            48.69845,
            0
          ],
          [
            -116.31224,
            48.69871,
            0
          ],
          [
            -116.3125,
            48.69903,
            0
          ],
          [
            -116.3139,
            48.70087,
            0
          ],
          [
            -116.31393,
            48.7009,
            0
          ],
          [
            -116.31402,
            48.70104,
            0
          ],
          [
            -116.31406,
            48.70109,
            0
          ],
          [
            -116.31408,
            48.70112,
            0
          ],
          [
            -116.31412,
            48.7012,
            0
          ],
          [
            -116.31419,
            48.70138,
            0
          ],
          [
            -116.31422,
            48.70151,
            0
          ],
          [
            -116.31424,
            48.70156,
            0
          ],
          [
            -116.31427,
            48.70173,
            0
          ],
          [
            -116.31428,
            48.70185,
            0
          ],
          [
            -116.31428,
            48.702,
            0
          ],
          [
            -116.31427,
            48.70207,
            0
          ],
          [
            -116.31425,
            48.70216,
            0
          ],
          [
            -116.31424,
            48.70223,
            0
          ],
          [
            -116.31423,
            48.70227,
            0
          ],
          [
            -116.31421,
            48.70232,
            0
          ],
          [
            -116.31419,
            48.70239,
            0
          ],
          [
            -116.31408,
            48.70261,
            0
          ],
          [
            -116.31393,
            48.70284,
            0
          ],
          [
            -116.31384,
            48.70295,
            0
          ],
          [
            -116.31372,
            48.70307,
            0
          ],
          [
            -116.31352,
            48.70323,
            0
          ],
          [
            -116.31276,
            48.70378,
            0
          ],
          [
            -116.31267,
            48.70384,
            0
          ],
          [
            -116.31238,
            48.70407,
            0
          ],
          [
            -116.3123,
            48.70415,
            0
          ],
          [
            -116.31214,
            48.70429,
            0
          ],
          [
            -116.31154,
            48.70489,
            0
          ],
          [
            -116.31135,
            48.70513,
            0
          ],
          [
            -116.31134,
            48.70514,
            0
          ],
          [
            -116.31115,
            48.70545,
            0
          ],
          [
            -116.31061,
            48.70644,
            0
          ],
          [
            -116.31023,
            48.70718,
            0
          ],
          [
            -116.31017,
            48.70727,
            0
          ],
          [
            -116.31013,
            48.70734,
            0
          ],
          [
            -116.31009,
            48.7074,
            0
          ],
          [
            -116.31005,
            48.70745,
            0
          ],
          [
            -116.30996,
            48.70749,
            0
          ],
          [
            -116.30995,
            48.70749,
            0
          ],
          [
            -116.30992,
            48.70752,
            0
          ],
          [
            -116.30985,
            48.70761,
            0
          ],
          [
            -116.3096,
            48.70788,
            0
          ],
          [
            -116.30942,
            48.70804,
            0
          ],
          [
            -116.30942,
            48.70805,
            0
          ],
          [
            -116.30919,
            48.70823,
            0
          ],
          [
            -116.30915,
            48.70825,
            0
          ],
          [
            -116.30882,
            48.70847,
            0
          ],
          [
            -116.30875,
            48.7085,
            0
          ],
          [
            -116.30865,
            48.70856,
            0
          ],
          [
            -116.30825,
            48.70876,
            0
          ],
          [
            -116.30821,
            48.70877,
            0
          ],
          [
            -116.30775,
            48.70895,
            0
          ],
          [
            -116.30699,
            48.70922,
            0
          ],
          [
            -116.30662,
            48.70934,
            0
          ],
          [
            -116.30595,
            48.70959,
            0
          ],
          [
            -116.30558,
            48.70975,
            0
          ],
          [
            -116.30538,
            48.70985,
            0
          ],
          [
            -116.30533,
            48.70987,
            0
          ],
          [
            -116.30508,
            48.71002,
            0
          ],
          [
            -116.30501,
            48.71007,
            0
          ],
          [
            -116.30485,
            48.7102,
            0
          ],
          [
            -116.30481,
            48.71024,
            0
          ],
          [
            -116.30477,
            48.71027,
            0
          ],
          [
            -116.3045,
            48.71052,
            0
          ],
          [
            -116.30436,
            48.71068,
            0
          ],
          [
            -116.30414,
            48.71101,
            0
          ],
          [
            -116.30409,
            48.7111,
            0
          ],
          [
            -116.30401,
            48.71133,
            0
          ],
          [
            -116.30394,
            48.71159,
            0
          ],
          [
            -116.30391,
            48.7118,
            0
          ],
          [
            -116.30391,
            48.71199,
            0
          ],
          [
            -116.30392,
            48.71213,
            0
          ],
          [
            -116.30398,
            48.71242,
            0
          ],
          [
            -116.30408,
            48.71267,
            0
          ],
          [
            -116.30415,
            48.71281,
            0
          ],
          [
            -116.30431,
            48.71306,
            0
          ],
          [
            -116.30451,
            48.71331,
            0
          ],
          [
            -116.30463,
            48.71343,
            0
          ],
          [
            -116.30475,
            48.71353,
            0
          ],
          [
            -116.30516,
            48.71383,
            0
          ],
          [
            -116.30535,
            48.71394,
            0
          ],
          [
            -116.3056,
            48.71407,
            0
          ],
          [
            -116.30577,
            48.71415,
            0
          ],
          [
            -116.30695,
            48.71464,
            0
          ],
          [
            -116.30703,
            48.71468,
            0
          ],
          [
            -116.30717,
            48.71474,
            0
          ],
          [
            -116.30743,
            48.71487,
            0
          ],
          [
            -116.30752,
            48.71492,
            0
          ],
          [
            -116.30761,
            48.71496,
            0
          ],
          [
            -116.30786,
            48.7151,
            0
          ],
          [
            -116.30849,
            48.71551,
            0
          ],
          [
            -116.30897,
            48.71586,
            0
          ],
          [
            -116.30922,
            48.71606,
            0
          ],
          [
            -116.30949,
            48.71631,
            0
          ],
          [
            -116.30965,
            48.71647,
            0
          ],
          [
            -116.30973,
            48.71654,
            0
          ],
          [
            -116.30984,
            48.71666,
            0
          ],
          [
            -116.31004,
            48.71691,
            0
          ],
          [
            -116.31007,
            48.71694,
            0
          ],
          [
            -116.31011,
            48.71699,
            0
          ],
          [
            -116.31014,
            48.71704,
            0
          ],
          [
            -116.31018,
            48.71709,
            0
          ],
          [
            -116.31032,
            48.71732,
            0
          ],
          [
            -116.31038,
            48.71744,
            0
          ],
          [
            -116.31042,
            48.71751,
            0
          ],
          [
            -116.31045,
            48.71759,
            0
          ],
          [
            -116.31048,
            48.71765,
            0
          ],
          [
            -116.31059,
            48.71795,
            0
          ],
          [
            -116.31065,
            48.71824,
            0
          ],
          [
            -116.31066,
            48.71832,
            0
          ],
          [
            -116.31067,
            48.71837,
            0
          ],
          [
            -116.31067,
            48.71845,
            0
          ],
          [
            -116.31068,
            48.71851,
            0
          ],
          [
            -116.31068,
            48.71873,
            0
          ],
          [
            -116.31067,
            48.71882,
            0
          ],
          [
            -116.31067,
            48.71888,
            0
          ],
          [
            -116.31066,
            48.71893,
            0
          ],
          [
            -116.31064,
            48.71909,
            0
          ],
          [
            -116.31059,
            48.7193,
            0
          ],
          [
            -116.31054,
            48.71947,
            0
          ],
          [
            -116.31049,
            48.71961,
            0
          ],
          [
            -116.31045,
            48.7197,
            0
          ],
          [
            -116.31043,
            48.71976,
            0
          ],
          [
            -116.31039,
            48.71983,
            0
          ],
          [
            -116.31031,
            48.72,
            0
          ],
          [
            -116.31028,
            48.72004,
            0
          ],
          [
            -116.31025,
            48.7201,
            0
          ],
          [
            -116.31021,
            48.72016,
            0
          ],
          [
            -116.31017,
            48.72021,
            0
          ],
          [
            -116.31012,
            48.7203,
            0
          ],
          [
            -116.31006,
            48.72037,
            0
          ],
          [
            -116.30996,
            48.7205,
            0
          ],
          [
            -116.30988,
            48.72058,
            0
          ],
          [
            -116.30975,
            48.72073,
            0
          ],
          [
            -116.30963,
            48.72085,
            0
          ],
          [
            -116.30932,
            48.72112,
            0
          ],
          [
            -116.3089,
            48.72143,
            0
          ],
          [
            -116.30882,
            48.72148,
            0
          ],
          [
            -116.30879,
            48.72151,
            0
          ],
          [
            -116.30833,
            48.72179,
            0
          ],
          [
            -116.30812,
            48.72193,
            0
          ],
          [
            -116.30773,
            48.72217,
            0
          ],
          [
            -116.30737,
            48.72241,
            0
          ],
          [
            -116.30605,
            48.72324,
            0
          ],
          [
            -116.30595,
            48.72331,
            0
          ],
          [
            -116.30576,
            48.72343,
            0
          ],
          [
            -116.30538,
            48.7237,
            0
          ],
          [
            -116.3048,
            48.72415,
            0
          ],
          [
            -116.30468,
            48.72425,
            0
          ],
          [
            -116.30441,
            48.72452,
            0
          ],
          [
            -116.30433,
            48.72459,
            0
          ],
          [
            -116.30417,
            48.72477,
            0
          ],
          [
            -116.30417,
            48.72486,
            0
          ],
          [
            -116.30405,
            48.72497,
            0
          ],
          [
            -116.30379,
            48.72526,
            0
          ],
          [
            -116.30378,
            48.72528,
            0
          ],
          [
            -116.30356,
            48.72556,
            0
          ],
          [
            -116.30348,
            48.72568,
            0
          ],
          [
            -116.30343,
            48.72574,
            0
          ],
          [
            -116.30336,
            48.72585,
            0
          ],
          [
            -116.30333,
            48.72589,
            0
          ],
          [
            -116.30305,
            48.72632,
            0
          ],
          [
            -116.30112,
            48.72941,
            0
          ],
          [
            -116.30093,
            48.7297,
            0
          ],
          [
            -116.29699,
            48.736,
            0
          ],
          [
            -116.29519,
            48.73875,
            0
          ],
          [
            -116.29418,
            48.74018,
            0
          ],
          [
            -116.29417,
            48.7402,
            0
          ],
          [
            -116.29112,
            48.74502,
            0
          ],
          [
            -116.29072,
            48.74569,
            0
          ],
          [
            -116.29027,
            48.74637,
            0
          ],
          [
            -116.29022,
            48.74648,
            0
          ],
          [
            -116.29018,
            48.74653,
            0
          ],
          [
            -116.29008,
            48.74669,
            0
          ],
          [
            -116.29007,
            48.7467,
            0
          ],
          [
            -116.28936,
            48.74782,
            0
          ],
          [
            -116.28916,
            48.74817,
            0
          ],
          [
            -116.2891,
            48.74826,
            0
          ],
          [
            -116.28897,
            48.74854,
            0
          ],
          [
            -116.28895,
            48.74859,
            0
          ],
          [
            -116.28893,
            48.74866,
            0
          ],
          [
            -116.2889,
            48.74872,
            0
          ],
          [
            -116.28888,
            48.7488,
            0
          ],
          [
            -116.28885,
            48.74889,
            0
          ],
          [
            -116.28884,
            48.74891,
            0
          ],
          [
            -116.28877,
            48.7492,
            0
          ],
          [
            -116.28877,
            48.74925,
            0
          ],
          [
            -116.28874,
            48.7494,
            0
          ],
          [
            -116.28859,
            48.7506,
            0
          ],
          [
            -116.28829,
            48.75328,
            0
          ],
          [
            -116.2882,
            48.75398,
            0
          ],
          [
            -116.28818,
            48.75423,
            0
          ],
          [
            -116.28816,
            48.75439,
            0
          ],
          [
            -116.28811,
            48.75513,
            0
          ],
          [
            -116.28828,
            48.76064,
            0
          ],
          [
            -116.28832,
            48.76094,
            0
          ],
          [
            -116.28835,
            48.7611,
            0
          ],
          [
            -116.28839,
            48.76126,
            0
          ],
          [
            -116.28847,
            48.76152,
            0
          ],
          [
            -116.28849,
            48.76157,
            0
          ],
          [
            -116.28852,
            48.76167,
            0
          ],
          [
            -116.28858,
            48.7618,
            0
          ],
          [
            -116.2886,
            48.76186,
            0
          ],
          [
            -116.28862,
            48.76189,
            0
          ],
          [
            -116.28864,
            48.76194,
            0
          ],
          [
            -116.28888,
            48.76238,
            0
          ],
          [
            -116.28898,
            48.76252,
            0
          ],
          [
            -116.28976,
            48.76371,
            0
          ],
          [
            -116.28981,
            48.76377,
            0
          ],
          [
            -116.28987,
            48.76387,
            0
          ],
          [
            -116.29009,
            48.7642,
            0
          ],
          [
            -116.29028,
            48.76446,
            0
          ],
          [
            -116.29102,
            48.76555,
            0
          ],
          [
            -116.29121,
            48.76581,
            0
          ],
          [
            -116.29208,
            48.76714,
            0
          ],
          [
            -116.29233,
            48.76754,
            0
          ],
          [
            -116.29287,
            48.76854,
            0
          ],
          [
            -116.29414,
            48.77105,
            0
          ],
          [
            -116.29431,
            48.77135,
            0
          ],
          [
            -116.29434,
            48.77139,
            0
          ],
          [
            -116.2944,
            48.7715,
            0
          ],
          [
            -116.29445,
            48.77158,
            0
          ],
          [
            -116.29463,
            48.77183,
            0
          ],
          [
            -116.29478,
            48.77202,
            0
          ],
          [
            -116.29494,
            48.7722,
            0
          ],
          [
            -116.29505,
            48.77231,
            0
          ],
          [
            -116.29512,
            48.77239,
            0
          ],
          [
            -116.29534,
            48.77261,
            0
          ],
          [
            -116.2962,
            48.77336,
            0
          ],
          [
            -116.29668,
            48.77382,
            0
          ],
          [
            -116.29671,
            48.77384,
            0
          ],
          [
            -116.29685,
            48.77401,
            0
          ],
          [
            -116.2969,
            48.77406,
            0
          ],
          [
            -116.29698,
            48.77416,
            0
          ],
          [
            -116.29707,
            48.7743,
            0
          ],
          [
            -116.29716,
            48.77442,
            0
          ],
          [
            -116.29724,
            48.77455,
            0
          ],
          [
            -116.29728,
            48.77463,
            0
          ],
          [
            -116.29739,
            48.77482,
            0
          ],
          [
            -116.29758,
            48.77525,
            0
          ],
          [
            -116.29761,
            48.77533,
            0
          ],
          [
            -116.29772,
            48.77567,
            0
          ],
          [
            -116.29776,
            48.77586,
            0
          ],
          [
            -116.2978,
            48.7761,
            0
          ],
          [
            -116.29782,
            48.77619,
            0
          ],
          [
            -116.29784,
            48.77641,
            0
          ],
          [
            -116.29784,
            48.77652,
            0
          ],
          [
            -116.29785,
            48.77661,
            0
          ],
          [
            -116.29784,
            48.77853,
            0
          ],
          [
            -116.29783,
            48.77881,
            0
          ],
          [
            -116.29783,
            48.77907,
            0
          ],
          [
            -116.29778,
            48.78116,
            0
          ],
          [
            -116.29778,
            48.78322,
            0
          ],
          [
            -116.29777,
            48.78362,
            0
          ],
          [
            -116.29778,
            48.78376,
            0
          ],
          [
            -116.29784,
            48.78686,
            0
          ],
          [
            -116.29785,
            48.78699,
            0
          ],
          [
            -116.29785,
            48.78709,
            0
          ],
          [
            -116.29787,
            48.78739,
            0
          ],
          [
            -116.2979,
            48.78761,
            0
          ],
          [
            -116.298,
            48.78805,
            0
          ],
          [
            -116.29804,
            48.78818,
            0
          ],
          [
            -116.29812,
            48.78839,
            0
          ],
          [
            -116.2982,
            48.78857,
            0
          ],
          [
            -116.2984,
            48.78894,
            0
          ],
          [
            -116.29848,
            48.78907,
            0
          ],
          [
            -116.29851,
            48.78913,
            0
          ],
          [
            -116.29855,
            48.78918,
            0
          ],
          [
            -116.29879,
            48.78952,
            0
          ],
          [
            -116.29896,
            48.78972,
            0
          ],
          [
            -116.29909,
            48.78985,
            0
          ],
          [
            -116.29912,
            48.78989,
            0
          ],
          [
            -116.2992,
            48.78997,
            0
          ],
          [
            -116.29927,
            48.79003,
            0
          ],
          [
            -116.29935,
            48.79011,
            0
          ],
          [
            -116.29966,
            48.79038,
            0
          ],
          [
            -116.2997,
            48.79041,
            0
          ],
          [
            -116.29972,
            48.79043,
            0
          ],
          [
            -116.29975,
            48.79045,
            0
          ],
          [
            -116.29978,
            48.79048,
            0
          ],
          [
            -116.29983,
            48.79051,
            0
          ],
          [
            -116.29997,
            48.79062,
            0
          ],
          [
            -116.3001,
            48.79071,
            0
          ],
          [
            -116.30048,
            48.79095,
            0
          ],
          [
            -116.30083,
            48.79115,
            0
          ],
          [
            -116.30205,
            48.79177,
            0
          ],
          [
            -116.30221,
            48.79186,
            0
          ],
          [
            -116.30238,
            48.79194,
            0
          ],
          [
            -116.30625,
            48.7939,
            0
          ],
          [
            -116.30629,
            48.79393,
            0
          ],
          [
            -116.30874,
            48.79517,
            0
          ],
          [
            -116.30917,
            48.7954,
            0
          ],
          [
            -116.32606,
            48.80401,
            0
          ],
          [
            -116.32657,
            48.80426,
            0
          ],
          [
            -116.32668,
            48.80432,
            0
          ],
          [
            -116.32693,
            48.80444,
            0
          ],
          [
            -116.32719,
            48.80458,
            0
          ],
          [
            -116.32751,
            48.80474,
            0
          ],
          [
            -116.32786,
            48.80493,
            0
          ],
          [
            -116.3284,
            48.80526,
            0
          ],
          [
            -116.3287,
            48.80547,
            0
          ],
          [
            -116.32895,
            48.80567,
            0
          ],
          [
            -116.32918,
            48.80587,
            0
          ],
          [
            -116.32924,
            48.80593,
            0
          ],
          [
            -116.3293,
            48.80598,
            0
          ],
          [
            -116.3295,
            48.80618,
            0
          ],
          [
            -116.32975,
            48.80646,
            0
          ],
          [
            -116.32981,
            48.80655,
            0
          ],
          [
            -116.32987,
            48.80662,
            0
          ],
          [
            -116.32998,
            48.80677,
            0
          ],
          [
            -116.33022,
            48.80715,
            0
          ],
          [
            -116.33039,
            48.80751,
            0
          ],
          [
            -116.33048,
            48.80773,
            0
          ],
          [
            -116.33059,
            48.80807,
            0
          ],
          [
            -116.33062,
            48.80824,
            0
          ],
          [
            -116.33065,
            48.80835,
            0
          ],
          [
            -116.33068,
            48.80857,
            0
          ],
          [
            -116.3307,
            48.80894,
            0
          ],
          [
            -116.3307,
            48.81022,
            0
          ],
          [
            -116.33069,
            48.81032,
            0
          ],
          [
            -116.33072,
            48.8185,
            0
          ],
          [
            -116.33061,
            48.82171,
            0
          ],
          [
            -116.33049,
            48.82437,
            0
          ],
          [
            -116.33049,
            48.82451,
            0
          ],
          [
            -116.3305,
            48.82464,
            0
          ],
          [
            -116.3305,
            48.82472,
            0
          ],
          [
            -116.33051,
            48.82476,
            0
          ],
          [
            -116.33051,
            48.82483,
            0
          ],
          [
            -116.33052,
            48.82487,
            0
          ],
          [
            -116.33054,
            48.82502,
            0
          ],
          [
            -116.33058,
            48.82517,
            0
          ],
          [
            -116.33059,
            48.82522,
            0
          ],
          [
            -116.33061,
            48.82527,
            0
          ],
          [
            -116.33067,
            48.82546,
            0
          ],
          [
            -116.33073,
            48.82562,
            0
          ],
          [
            -116.33108,
            48.82645,
            0
          ],
          [
            -116.33114,
            48.82661,
            0
          ],
          [
            -116.33121,
            48.82677,
            0
          ],
          [
            -116.33125,
            48.82684,
            0
          ],
          [
            -116.3313,
            48.82697,
            0
          ],
          [
            -116.33137,
            48.82712,
            0
          ],
          [
            -116.33141,
            48.82719,
            0
          ],
          [
            -116.33146,
            48.82729,
            0
          ],
          [
            -116.33147,
            48.82732,
            0
          ],
          [
            -116.33149,
            48.82735,
            0
          ],
          [
            -116.33152,
            48.82741,
            0
          ],
          [
            -116.33172,
            48.82774,
            0
          ],
          [
            -116.33181,
            48.82787,
            0
          ],
          [
            -116.33202,
            48.8282,
            0
          ],
          [
            -116.33208,
            48.82828,
            0
          ],
          [
            -116.33212,
            48.82835,
            0
          ],
          [
            -116.33231,
            48.82864,
            0
          ],
          [
            -116.33251,
            48.82899,
            0
          ],
          [
            -116.33257,
            48.82911,
            0
          ],
          [
            -116.33261,
            48.82918,
            0
          ],
          [
            -116.33265,
            48.82928,
            0
          ],
          [
            -116.33271,
            48.82939,
            0
          ],
          [
            -116.33276,
            48.82952,
            0
          ],
          [
            -116.33282,
            48.82964,
            0
          ],
          [
            -116.33297,
            48.82997,
            0
          ],
          [
            -116.33297,
            48.82998,
            0
          ],
          [
            -116.33302,
            48.83009,
            0
          ],
          [
            -116.33305,
            48.83014,
            0
          ],
          [
            -116.33307,
            48.83018,
            0
          ],
          [
            -116.3331,
            48.83023,
            0
          ],
          [
            -116.33312,
            48.83027,
            0
          ],
          [
            -116.33314,
            48.8303,
            0
          ],
          [
            -116.33315,
            48.83034,
            0
          ],
          [
            -116.3332,
            48.83041,
            0
          ],
          [
            -116.33322,
            48.83046,
            0
          ],
          [
            -116.33324,
            48.83048,
            0
          ],
          [
            -116.33326,
            48.83051,
            0
          ],
          [
            -116.33329,
            48.83057,
            0
          ],
          [
            -116.33335,
            48.83066,
            0
          ],
          [
            -116.33357,
            48.83095,
            0
          ],
          [
            -116.33368,
            48.83106,
            0
          ],
          [
            -116.3337,
            48.83109,
            0
          ],
          [
            -116.33389,
            48.83128,
            0
          ],
          [
            -116.33397,
            48.83135,
            0
          ],
          [
            -116.33405,
            48.83143,
            0
          ],
          [
            -116.33422,
            48.83157,
            0
          ],
          [
            -116.33449,
            48.83181,
            0
          ],
          [
            -116.33478,
            48.83205,
            0
          ],
          [
            -116.33586,
            48.8329,
            0
          ],
          [
            -116.33607,
            48.83308,
            0
          ],
          [
            -116.33615,
            48.83314,
            0
          ],
          [
            -116.33627,
            48.83324,
            0
          ],
          [
            -116.33631,
            48.83328,
            0
          ],
          [
            -116.33636,
            48.83332,
            0
          ],
          [
            -116.33657,
            48.83353,
            0
          ],
          [
            -116.33667,
            48.83364,
            0
          ],
          [
            -116.33683,
            48.83383,
            0
          ],
          [
            -116.33685,
            48.83386,
            0
          ],
          [
            -116.33695,
            48.83399,
            0
          ],
          [
            -116.33708,
            48.83418,
            0
          ],
          [
            -116.33711,
            48.83424,
            0
          ],
          [
            -116.33713,
            48.83426,
            0
          ],
          [
            -116.33715,
            48.83429,
            0
          ],
          [
            -116.33727,
            48.83453,
            0
          ],
          [
            -116.33729,
            48.83456,
            0
          ],
          [
            -116.33731,
            48.83462,
            0
          ],
          [
            -116.33735,
            48.8347,
            0
          ],
          [
            -116.33746,
            48.835,
            0
          ],
          [
            -116.33756,
            48.83532,
            0
          ],
          [
            -116.33774,
            48.83611,
            0
          ],
          [
            -116.33777,
            48.83627,
            0
          ],
          [
            -116.33791,
            48.83686,
            0
          ],
          [
            -116.33798,
            48.83711,
            0
          ],
          [
            -116.33802,
            48.83722,
            0
          ],
          [
            -116.33804,
            48.83733,
            0
          ],
          [
            -116.33807,
            48.83742,
            0
          ],
          [
            -116.33809,
            48.8375,
            0
          ],
          [
            -116.33809,
            48.83753,
            0
          ],
          [
            -116.33813,
            48.83771,
            0
          ],
          [
            -116.33814,
            48.83781,
            0
          ],
          [
            -116.33816,
            48.83792,
            0
          ],
          [
            -116.33817,
            48.838,
            0
          ],
          [
            -116.33817,
            48.83808,
            0
          ],
          [
            -116.33818,
            48.83813,
            0
          ],
          [
            -116.33818,
            48.83826,
            0
          ],
          [
            -116.33819,
            48.83832,
            0
          ],
          [
            -116.33819,
            48.83853,
            0
          ],
          [
            -116.33821,
            48.8387,
            0
          ],
          [
            -116.33822,
            48.83892,
            0
          ],
          [
            -116.33824,
            48.83909,
            0
          ],
          [
            -116.33824,
            48.83964,
            0
          ],
          [
            -116.33822,
            48.8398,
            0
          ],
          [
            -116.33822,
            48.83985,
            0
          ],
          [
            -116.33821,
            48.83989,
            0
          ],
          [
            -116.33821,
            48.83994,
            0
          ],
          [
            -116.3382,
            48.83999,
            0
          ],
          [
            -116.33818,
            48.84006,
            0
          ],
          [
            -116.33816,
            48.84017,
            0
          ],
          [
            -116.33809,
            48.84034,
            0
          ],
          [
            -116.33803,
            48.84051,
            0
          ],
          [
            -116.33796,
            48.84068,
            0
          ],
          [
            -116.33789,
            48.84083,
            0
          ],
          [
            -116.33782,
            48.84096,
            0
          ],
          [
            -116.33775,
            48.84112,
            0
          ],
          [
            -116.33759,
            48.84143,
            0
          ],
          [
            -116.33542,
            48.84588,
            0
          ],
          [
            -116.33538,
            48.84595,
            0
          ],
          [
            -116.33305,
            48.85063,
            0
          ],
          [
            -116.33293,
            48.85091,
            0
          ],
          [
            -116.33285,
            48.85107,
            0
          ],
          [
            -116.33277,
            48.8513,
            0
          ],
          [
            -116.33276,
            48.85135,
            0
          ],
          [
            -116.33272,
            48.85146,
            0
          ],
          [
            -116.33266,
            48.85179,
            0
          ],
          [
            -116.33265,
            48.85183,
            0
          ],
          [
            -116.33265,
            48.85187,
            0
          ],
          [
            -116.33264,
            48.85194,
            0
          ],
          [
            -116.33264,
            48.85227,
            0
          ],
          [
            -116.33265,
            48.85233,
            0
          ],
          [
            -116.33265,
            48.85241,
            0
          ],
          [
            -116.33269,
            48.85261,
            0
          ],
          [
            -116.3327,
            48.85268,
            0
          ],
          [
            -116.33272,
            48.85276,
            0
          ],
          [
            -116.33275,
            48.85285,
            0
          ],
          [
            -116.33276,
            48.85291,
            0
          ],
          [
            -116.33279,
            48.85298,
            0
          ],
          [
            -116.33281,
            48.85307,
            0
          ],
          [
            -116.33443,
            48.85681,
            0
          ],
          [
            -116.33448,
            48.85695,
            0
          ],
          [
            -116.33468,
            48.85743,
            0
          ],
          [
            -116.33496,
            48.85804,
            0
          ],
          [
            -116.33513,
            48.85844,
            0
          ],
          [
            -116.33531,
            48.85891,
            0
          ],
          [
            -116.33545,
            48.8594,
            0
          ],
          [
            -116.33548,
            48.85949,
            0
          ],
          [
            -116.3368,
            48.86507,
            0
          ],
          [
            -116.33682,
            48.86513,
            0
          ],
          [
            -116.33708,
            48.86632,
            0
          ],
          [
            -116.33713,
            48.86659,
            0
          ],
          [
            -116.33714,
            48.86668,
            0
          ],
          [
            -116.33722,
            48.86712,
            0
          ],
          [
            -116.33723,
            48.86721,
            0
          ],
          [
            -116.33731,
            48.86769,
            0
          ],
          [
            -116.33739,
            48.86829,
            0
          ],
          [
            -116.33783,
            48.87122,
            0
          ],
          [
            -116.33785,
            48.8714,
            0
          ],
          [
            -116.33788,
            48.87157,
            0
          ],
          [
            -116.33793,
            48.87199,
            0
          ],
          [
            -116.33804,
            48.87264,
            0
          ],
          [
            -116.33808,
            48.87282,
            0
          ],
          [
            -116.33809,
            48.87285,
            0
          ],
          [
            -116.3381,
            48.87291,
            0
          ],
          [
            -116.33818,
            48.87313,
            0
          ],
          [
            -116.33821,
            48.8732,
            0
          ],
          [
            -116.33827,
            48.87337,
            0
          ],
          [
            -116.3383,
            48.87342,
            0
          ],
          [
            -116.33832,
            48.87347,
            0
          ],
          [
            -116.33839,
            48.87361,
            0
          ],
          [
            -116.33848,
            48.87377,
            0
          ],
          [
            -116.33852,
            48.87382,
            0
          ],
          [
            -116.33875,
            48.87415,
            0
          ],
          [
            -116.33883,
            48.87425,
            0
          ],
          [
            -116.33884,
            48.87427,
            0
          ],
          [
            -116.33922,
            48.87475,
            0
          ],
          [
            -116.33931,
            48.87488,
            0
          ],
          [
            -116.33941,
            48.875,
            0
          ],
          [
            -116.33954,
            48.87518,
            0
          ],
          [
            -116.33964,
            48.8753,
            0
          ],
          [
            -116.33972,
            48.87542,
            0
          ],
          [
            -116.3402,
            48.87607,
            0
          ],
          [
            -116.34026,
            48.87613,
            0
          ],
          [
            -116.34029,
            48.87618,
            0
          ],
          [
            -116.34051,
            48.87645,
            0
          ],
          [
            -116.34063,
            48.87661,
            0
          ],
          [
            -116.34084,
            48.87684,
            0
          ],
          [
            -116.3409,
            48.87689,
            0
          ],
          [
            -116.34096,
            48.87695,
            0
          ],
          [
            -116.341,
            48.87698,
            0
          ],
          [
            -116.34103,
            48.87701,
            0
          ],
          [
            -116.34111,
            48.87708,
            0
          ],
          [
            -116.3415,
            48.87739,
            0
          ],
          [
            -116.34174,
            48.8776,
            0
          ],
          [
            -116.34201,
            48.87781,
            0
          ],
          [
            -116.34206,
            48.87786,
            0
          ],
          [
            -116.34281,
            48.87847,
            0
          ],
          [
            -116.34285,
            48.87851,
            0
          ],
          [
            -116.34293,
            48.87858,
            0
          ],
          [
            -116.343,
            48.87865,
            0
          ],
          [
            -116.34303,
            48.87867,
            0
          ],
          [
            -116.34307,
            48.87872,
            0
          ],
          [
            -116.34312,
            48.87876,
            0
          ],
          [
            -116.34312,
            48.87877,
            0
          ],
          [
            -116.34321,
            48.87885,
            0
          ],
          [
            -116.34333,
            48.87901,
            0
          ],
          [
            -116.34338,
            48.87906,
            0
          ],
          [
            -116.34355,
            48.87929,
            0
          ],
          [
            -116.34359,
            48.87936,
            0
          ],
          [
            -116.34363,
            48.87941,
            0
          ],
          [
            -116.3437,
            48.87954,
            0
          ],
          [
            -116.34393,
            48.88001,
            0
          ],
          [
            -116.34418,
            48.87994,
            0
          ],
          [
            -116.34446,
            48.87989,
            0
          ],
          [
            -116.34469,
            48.87986,
            0
          ],
          [
            -116.34495,
            48.87988,
            0
          ],
          [
            -116.34512,
            48.87991,
            0
          ],
          [
            -116.34541,
            48.88002,
            0
          ],
          [
            -116.34547,
            48.88006,
            0
          ],
          [
            -116.34569,
            48.88025,
            0
          ],
          [
            -116.34582,
            48.88044,
            0
          ],
          [
            -116.3461,
            48.88079,
            0
          ],
          [
            -116.34623,
            48.88099,
            0
          ],
          [
            -116.34661,
            48.88147,
            0
          ],
          [
            -116.34683,
            48.88173,
            0
          ],
          [
            -116.34728,
            48.88239,
            0
          ],
          [
            -116.34734,
            48.88247,
            0
          ],
          [
            -116.3474,
            48.88257,
            0
          ],
          [
            -116.34783,
            48.88317,
            0
          ],
          [
            -116.34786,
            48.8832,
            0
          ],
          [
            -116.34788,
            48.88324,
            0
          ],
          [
            -116.34797,
            48.88338,
            0
          ],
          [
            -116.34805,
            48.88353,
            0
          ],
          [
            -116.34805,
            48.88354,
            0
          ],
          [
            -116.34808,
            48.88359,
            0
          ],
          [
            -116.3481,
            48.88364,
            0
          ],
          [
            -116.34814,
            48.88371,
            0
          ],
          [
            -116.34818,
            48.88382,
            0
          ],
          [
            -116.3482,
            48.88389,
            0
          ],
          [
            -116.34827,
            48.88408,
            0
          ],
          [
            -116.34829,
            48.88415,
            0
          ],
          [
            -116.34852,
            48.88476,
            0
          ],
          [
            -116.34854,
            48.88483,
            0
          ],
          [
            -116.34859,
            48.88493,
            0
          ],
          [
            -116.34873,
            48.88525,
            0
          ],
          [
            -116.34874,
            48.88528,
            0
          ],
          [
            -116.34881,
            48.88542,
            0
          ],
          [
            -116.34914,
            48.88601,
            0
          ],
          [
            -116.34919,
            48.88608,
            0
          ],
          [
            -116.34929,
            48.88625,
            0
          ],
          [
            -116.34931,
            48.88629,
            0
          ],
          [
            -116.34942,
            48.88646,
            0
          ],
          [
            -116.34963,
            48.88681,
            0
          ],
          [
            -116.34976,
            48.88701,
            0
          ],
          [
            -116.34977,
            48.88702,
            0
          ],
          [
            -116.34978,
            48.88705,
            0
          ],
          [
            -116.34983,
            48.88711,
            0
          ],
          [
            -116.34987,
            48.88718,
            0
          ],
          [
            -116.3499,
            48.88722,
            0
          ],
          [
            -116.34993,
            48.88727,
            0
          ],
          [
            -116.34996,
            48.8873,
            0
          ],
          [
            -116.34998,
            48.88734,
            0
          ],
          [
            -116.35001,
            48.88737,
            0
          ],
          [
            -116.35007,
            48.88746,
            0
          ],
          [
            -116.35014,
            48.88753,
            0
          ],
          [
            -116.35016,
            48.88757,
            0
          ],
          [
            -116.35019,
            48.88759,
            0
          ],
          [
            -116.3502,
            48.88761,
            0
          ],
          [
            -116.35028,
            48.88769,
            0
          ],
          [
            -116.35031,
            48.88773,
            0
          ],
          [
            -116.35035,
            48.88776,
            0
          ],
          [
            -116.35049,
            48.8879,
            0
          ],
          [
            -116.35054,
            48.88794,
            0
          ],
          [
            -116.35055,
            48.88795,
            0
          ],
          [
            -116.35063,
            48.88801,
            0
          ],
          [
            -116.3508,
            48.88815,
            0
          ],
          [
            -116.35083,
            48.88818,
            0
          ],
          [
            -116.35104,
            48.88833,
            0
          ],
          [
            -116.35111,
            48.88837,
            0
          ],
          [
            -116.35116,
            48.88841,
            0
          ],
          [
            -116.35122,
            48.88844,
            0
          ],
          [
            -116.35134,
            48.88852,
            0
          ],
          [
            -116.35147,
            48.88859,
            0
          ],
          [
            -116.35168,
            48.88872,
            0
          ],
          [
            -116.35566,
            48.89095,
            0
          ],
          [
            -116.35587,
            48.89106,
            0
          ],
          [
            -116.35814,
            48.89237,
            0
          ],
          [
            -116.35925,
            48.89308,
            0
          ],
          [
            -116.35976,
            48.89344,
            0
          ],
          [
            -116.3604,
            48.89392,
            0
          ],
          [
            -116.36228,
            48.89538,
            0
          ],
          [
            -116.36412,
            48.89677,
            0
          ],
          [
            -116.36455,
            48.89715,
            0
          ],
          [
            -116.36573,
            48.89803,
            0
          ],
          [
            -116.36601,
            48.89827,
            0
          ],
          [
            -116.36625,
            48.89854,
            0
          ],
          [
            -116.36634,
            48.89868,
            0
          ],
          [
            -116.36645,
            48.89888,
            0
          ],
          [
            -116.3665,
            48.899,
            0
          ],
          [
            -116.36657,
            48.89919,
            0
          ],
          [
            -116.36668,
            48.89959,
            0
          ],
          [
            -116.36678,
            48.8999,
            0
          ],
          [
            -116.36683,
            48.90002,
            0
          ],
          [
            -116.3669,
            48.90015,
            0
          ],
          [
            -116.36696,
            48.90024,
            0
          ],
          [
            -116.36711,
            48.9004,
            0
          ],
          [
            -116.36724,
            48.90051,
            0
          ],
          [
            -116.36739,
            48.90062,
            0
          ],
          [
            -116.36752,
            48.9007,
            0
          ],
          [
            -116.36758,
            48.90073,
            0
          ],
          [
            -116.36766,
            48.90078,
            0
          ],
          [
            -116.36779,
            48.90083,
            0
          ],
          [
            -116.36802,
            48.90093,
            0
          ],
          [
            -116.36821,
            48.90099,
            0
          ],
          [
            -116.36883,
            48.90122,
            0
          ],
          [
            -116.36884,
            48.90122,
            0
          ],
          [
            -116.36901,
            48.90129,
            0
          ],
          [
            -116.36935,
            48.90146,
            0
          ],
          [
            -116.36957,
            48.9016,
            0
          ],
          [
            -116.36966,
            48.90167,
            0
          ],
          [
            -116.36981,
            48.9018,
            0
          ],
          [
            -116.36986,
            48.90185,
            0
          ],
          [
            -116.37003,
            48.90204,
            0
          ],
          [
            -116.37009,
            48.90212,
            0
          ],
          [
            -116.3702,
            48.90229,
            0
          ],
          [
            -116.3703,
            48.90248,
            0
          ],
          [
            -116.37031,
            48.90249,
            0
          ],
          [
            -116.37075,
            48.90327,
            0
          ],
          [
            -116.37089,
            48.90354,
            0
          ],
          [
            -116.37307,
            48.90751,
            0
          ],
          [
            -116.37317,
            48.90768,
            0
          ],
          [
            -116.37617,
            48.91316,
            0
          ],
          [
            -116.37618,
            48.91319,
            0
          ],
          [
            -116.37977,
            48.91974,
            0
          ],
          [
            -116.38076,
            48.92175,
            0
          ],
          [
            -116.38163,
            48.92361,
            0
          ],
          [
            -116.38265,
            48.92563,
            0
          ],
          [
            -116.38429,
            48.92868,
            0
          ],
          [
            -116.38437,
            48.92881,
            0
          ],
          [
            -116.3876,
            48.9349,
            0
          ],
          [
            -116.38771,
            48.93509,
            0
          ],
          [
            -116.38832,
            48.93626,
            0
          ],
          [
            -116.38855,
            48.93668,
            0
          ],
          [
            -116.38892,
            48.9373,
            0
          ],
          [
            -116.38923,
            48.93774,
            0
          ],
          [
            -116.38944,
            48.93801,
            0
          ],
          [
            -116.38955,
            48.93814,
            0
          ],
          [
            -116.38966,
            48.93825,
            0
          ],
          [
            -116.38985,
            48.93846,
            0
          ],
          [
            -116.39004,
            48.93865,
            0
          ],
          [
            -116.39018,
            48.93878,
            0
          ],
          [
            -116.39043,
            48.93899,
            0
          ],
          [
            -116.39048,
            48.93904,
            0
          ],
          [
            -116.39142,
            48.93978,
            0
          ],
          [
            -116.3916,
            48.93993,
            0
          ],
          [
            -116.39707,
            48.94416,
            0
          ],
          [
            -116.39709,
            48.94418,
            0
          ],
          [
            -116.39722,
            48.94427,
            0
          ],
          [
            -116.39857,
            48.9453,
            0
          ],
          [
            -116.39866,
            48.94536,
            0
          ],
          [
            -116.39905,
            48.94567,
            0
          ],
          [
            -116.39947,
            48.94597,
            0
          ],
          [
            -116.3998,
            48.94618,
            0
          ],
          [
            -116.40031,
            48.94648,
            0
          ],
          [
            -116.40052,
            48.94659,
            0
          ],
          [
            -116.40138,
            48.94697,
            0
          ],
          [
            -116.40224,
            48.94728,
            0
          ],
          [
            -116.40226,
            48.94729,
            0
          ],
          [
            -116.40298,
            48.9475,
            0
          ],
          [
            -116.40306,
            48.94753,
            0
          ],
          [
            -116.40731,
            48.94876,
            0
          ],
          [
            -116.40983,
            48.94952,
            0
          ],
          [
            -116.41016,
            48.94964,
            0
          ],
          [
            -116.41058,
            48.94978,
            0
          ],
          [
            -116.41097,
            48.94994,
            0
          ],
          [
            -116.41139,
            48.95013,
            0
          ],
          [
            -116.41174,
            48.9503,
            0
          ],
          [
            -116.41221,
            48.95056,
            0
          ],
          [
            -116.41254,
            48.95076,
            0
          ],
          [
            -116.41311,
            48.95113,
            0
          ],
          [
            -116.41339,
            48.95134,
            0
          ],
          [
            -116.41373,
            48.95162,
            0
          ],
          [
            -116.41399,
            48.95187,
            0
          ],
          [
            -116.41405,
            48.95192,
            0
          ],
          [
            -116.41433,
            48.9522,
            0
          ],
          [
            -116.41446,
            48.95235,
            0
          ],
          [
            -116.41449,
            48.95237,
            0
          ],
          [
            -116.41456,
            48.95247,
            0
          ],
          [
            -116.41463,
            48.95255,
            0
          ],
          [
            -116.41486,
            48.95285,
            0
          ],
          [
            -116.41486,
            48.95286,
            0
          ],
          [
            -116.41504,
            48.95314,
            0
          ],
          [
            -116.41531,
            48.95359,
            0
          ],
          [
            -116.42225,
            48.96735,
            0
          ],
          [
            -116.42231,
            48.96748,
            0
          ],
          [
            -116.42252,
            48.96786,
            0
          ],
          [
            -116.42295,
            48.9685,
            0
          ],
          [
            -116.42333,
            48.96898,
            0
          ],
          [
            -116.42364,
            48.96935,
            0
          ],
          [
            -116.42475,
            48.97049,
            0
          ],
          [
            -116.42596,
            48.97169,
            0
          ],
          [
            -116.426,
            48.97174,
            0
          ],
          [
            -116.42852,
            48.97426,
            0
          ],
          [
            -116.42899,
            48.97471,
            0
          ],
          [
            -116.42914,
            48.97484,
            0
          ],
          [
            -116.42946,
            48.9751,
            0
          ],
          [
            -116.42966,
            48.97525,
            0
          ],
          [
            -116.43042,
            48.97578,
            0
          ],
          [
            -116.43266,
            48.97726,
            0
          ],
          [
            -116.43333,
            48.97772,
            0
          ],
          [
            -116.43852,
            48.98114,
            0
          ],
          [
            -116.43854,
            48.98116,
            0
          ],
          [
            -116.43914,
            48.98154,
            0
          ],
          [
            -116.44059,
            48.98252,
            0
          ],
          [
            -116.44097,
            48.98276,
            0
          ],
          [
            -116.44125,
            48.98297,
            0
          ],
          [
            -116.44196,
            48.98339,
            0
          ],
          [
            -116.44244,
            48.98364,
            0
          ],
          [
            -116.4431,
            48.98394,
            0
          ],
          [
            -116.44356,
            48.98411,
            0
          ],
          [
            -116.44412,
            48.9843,
            0
          ],
          [
            -116.44464,
            48.98445,
            0
          ],
          [
            -116.44569,
            48.98468,
            0
          ],
          [
            -116.44645,
            48.98479,
            0
          ],
          [
            -116.44742,
            48.98491,
            0
          ],
          [
            -116.44778,
            48.98494,
            0
          ],
          [
            -116.44967,
            48.98515,
            0
          ],
          [
            -116.44972,
            48.98515,
            0
          ],
          [
            -116.45992,
            48.98628,
            0
          ],
          [
            -116.46008,
            48.98629,
            0
          ],
          [
            -116.46278,
            48.9866,
            0
          ],
          [
            -116.46334,
            48.98664,
            0
          ],
          [
            -116.46352,
            48.98664,
            0
          ],
          [
            -116.46374,
            48.98663,
            0
          ],
          [
            -116.46397,
            48.98661,
            0
          ],
          [
            -116.46438,
            48.98653,
            0
          ],
          [
            -116.46483,
            48.98638,
            0
          ],
          [
            -116.46503,
            48.98629,
            0
          ],
          [
            -116.46519,
            48.9862,
            0
          ],
          [
            -116.46552,
            48.98599,
            0
          ],
          [
            -116.46567,
            48.98587,
            0
          ],
          [
            -116.46586,
            48.98566,
            0
          ],
          [
            -116.46594,
            48.98554,
            0
          ],
          [
            -116.466,
            48.98542,
            0
          ],
          [
            -116.46609,
            48.98505,
            0
          ],
          [
            -116.46611,
            48.98495,
            0
          ],
          [
            -116.46621,
            48.98459,
            0
          ],
          [
            -116.46622,
            48.98457,
            0
          ],
          [
            -116.46627,
            48.98444,
            0
          ],
          [
            -116.46629,
            48.98442,
            0
          ],
          [
            -116.46634,
            48.9843,
            0
          ],
          [
            -116.46648,
            48.98408,
            0
          ],
          [
            -116.46651,
            48.98404,
            0
          ],
          [
            -116.46666,
            48.98387,
            0
          ],
          [
            -116.46682,
            48.98371,
            0
          ],
          [
            -116.46692,
            48.98363,
            0
          ],
          [
            -116.46758,
            48.98314,
            0
          ],
          [
            -116.46869,
            48.98237,
            0
          ],
          [
            -116.46953,
            48.98176,
            0
          ],
          [
            -116.4699,
            48.98153,
            0
          ],
          [
            -116.47029,
            48.98131,
            0
          ],
          [
            -116.47055,
            48.98119,
            0
          ],
          [
            -116.4711,
            48.98098,
            0
          ],
          [
            -116.47178,
            48.98079,
            0
          ],
          [
            -116.47196,
            48.98075,
            0
          ],
          [
            -116.4723,
            48.9807,
            0
          ],
          [
            -116.47245,
            48.98067,
            0
          ],
          [
            -116.47275,
            48.98064,
            0
          ],
          [
            -116.47329,
            48.98061,
            0
          ],
          [
            -116.47381,
            48.98061,
            0
          ],
          [
            -116.47394,
            48.98062,
            0
          ],
          [
            -116.4741,
            48.98062,
            0
          ],
          [
            -116.47445,
            48.98065,
            0
          ],
          [
            -116.475,
            48.98074,
            0
          ],
          [
            -116.47501,
            48.98075,
            0
          ],
          [
            -116.47525,
            48.9808,
            0
          ],
          [
            -116.47527,
            48.9808,
            0
          ],
          [
            -116.47555,
            48.98087,
            0
          ],
          [
            -116.47605,
            48.98103,
            0
          ],
          [
            -116.47626,
            48.98111,
            0
          ],
          [
            -116.47627,
            48.98111,
            0
          ],
          [
            -116.47664,
            48.98128,
            0
          ],
          [
            -116.47797,
            48.98202,
            0
          ],
          [
            -116.48495,
            48.98606,
            0
          ],
          [
            -116.48496,
            48.98606,
            0
          ],
          [
            -116.48557,
            48.98642,
            0
          ],
          [
            -116.48558,
            48.98642,
            0
          ],
          [
            -116.48681,
            48.98713,
            0
          ],
          [
            -116.48702,
            48.98724,
            0
          ],
          [
            -116.48738,
            48.98745,
            0
          ],
          [
            -116.48783,
            48.98768,
            0
          ],
          [
            -116.48787,
            48.98769,
            0
          ],
          [
            -116.48809,
            48.9878,
            0
          ],
          [
            -116.48868,
            48.98805,
            0
          ],
          [
            -116.49235,
            48.98943,
            0
          ],
          [
            -116.49271,
            48.98961,
            0
          ],
          [
            -116.49278,
            48.98966,
            0
          ],
          [
            -116.49289,
            48.98973,
            0
          ],
          [
            -116.493,
            48.98981,
            0
          ],
          [
            -116.49312,
            48.98991,
            0
          ],
          [
            -116.49324,
            48.99002,
            0
          ],
          [
            -116.49333,
            48.99011,
            0
          ],
          [
            -116.49353,
            48.99037,
            0
          ],
          [
            -116.49355,
            48.99039,
            0
          ],
          [
            -116.49389,
            48.99088,
            0
          ],
          [
            -116.4939,
            48.9909,
            0
          ],
          [
            -116.4944,
            48.9916,
            0
          ],
          [
            -116.49484,
            48.99226,
            0
          ],
          [
            -116.49543,
            48.99307,
            0
          ],
          [
            -116.49571,
            48.99342,
            0
          ],
          [
            -116.49583,
            48.99355,
            0
          ],
          [
            -116.49598,
            48.9937,
            0
          ],
          [
            -116.49615,
            48.99385,
            0
          ],
          [
            -116.49666,
            48.99425,
            0
          ],
          [
            -116.49677,
            48.99432,
            0
          ],
          [
            -116.49689,
            48.99441,
            0
          ],
          [
            -116.4971,
            48.99458,
            0
          ],
          [
            -116.49743,
            48.9949,
            0
          ],
          [
            -116.49761,
            48.99513,
            0
          ],
          [
            -116.49769,
            48.99525,
            0
          ],
          [
            -116.49776,
            48.99538,
            0
          ],
          [
            -116.49782,
            48.99551,
            0
          ],
          [
            -116.49793,
            48.99588,
            0
          ],
          [
            -116.49795,
            48.99602,
            0
          ],
          [
            -116.49796,
            48.99614,
            0
          ],
          [
            -116.49796,
            48.99637,
            0
          ],
          [
            -116.49797,
            48.99647,
            0
          ],
          [
            -116.49795,
            48.99702,
            0
          ],
          [
            -116.49795,
            48.99751,
            0
          ]
        ],
			},
			id: "86cd5e29-e6eb-4279-b976-7e5546c42965",
		},
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.5534675,
    //       48.276577,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Sandpoint, ID"
    //   },
    //   id: "902dc188-023f-4644-bf9b-57dc144d1a69"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.3163216,
    //       48.6913249,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Bonners Ferry, ID"
    //   },
    //   id: "d5270108-b76e-452f-9ec6-6fcfa2ae4ccc"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.4979492,
    //       48.9975099,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Porthill, ID"
    //   },
    //   id: "fe6cb2fc-2a5b-4ee0-ab94-2a4033b58634"
    // }
	],
};

export default WildHorseTrail;
