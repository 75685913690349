const WhitePine = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "White Pine Scenic Byway",
				description: "Passing through the towering forests of Idaho's historic timber country, the White Pine Scenic Byway takes road trippers through marshland spotted with lakes fed by the lower Coeur d'Alene River before crossing the picturesque St. Maries and St. Joe rivers. Dive into the history of Coeur d'Alene's Old Mission State Park, where the 19th-century Cataldo Mission stands, and the town of Potlatch, once home to the world's largest white pine mill.",
				link: "https://visitidaho.org/things-to-do/road-trips/white-pine-scenic-byway/",
				route: [
					"Cataldo",
          "Harrison",
          "St Maries",
          "Potlatch"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
          [
            -116.32943,
            47.54876,
            0
          ],
          [
            -116.32957,
            47.54848,
            0
          ],
          [
            -116.32961,
            47.54842,
            0
          ],
          [
            -116.32935,
            47.54841,
            0
          ],
          [
            -116.32828,
            47.54841,
            0
          ],
          [
            -116.32801,
            47.5484,
            0
          ],
          [
            -116.32761,
            47.5484,
            0
          ],
          [
            -116.3275,
            47.54839,
            0
          ],
          [
            -116.32738,
            47.54837,
            0
          ],
          [
            -116.32725,
            47.54834,
            0
          ],
          [
            -116.32722,
            47.54832,
            0
          ],
          [
            -116.3272,
            47.54832,
            0
          ],
          [
            -116.32705,
            47.54823,
            0
          ],
          [
            -116.32703,
            47.5482,
            0
          ],
          [
            -116.32699,
            47.54816,
            0
          ],
          [
            -116.32693,
            47.54803,
            0
          ],
          [
            -116.32693,
            47.54791,
            0
          ],
          [
            -116.32696,
            47.54783,
            0
          ],
          [
            -116.32698,
            47.5478,
            0
          ],
          [
            -116.327,
            47.54776,
            0
          ],
          [
            -116.32708,
            47.54768,
            0
          ],
          [
            -116.32718,
            47.54762,
            0
          ],
          [
            -116.32726,
            47.54758,
            0
          ],
          [
            -116.32727,
            47.54758,
            0
          ],
          [
            -116.32751,
            47.54748,
            0
          ],
          [
            -116.32802,
            47.54734,
            0
          ],
          [
            -116.32841,
            47.54726,
            0
          ],
          [
            -116.329,
            47.54718,
            0
          ],
          [
            -116.32912,
            47.54717,
            0
          ],
          [
            -116.32931,
            47.54714,
            0
          ],
          [
            -116.32934,
            47.54714,
            0
          ],
          [
            -116.32951,
            47.54712,
            0
          ],
          [
            -116.32968,
            47.54709,
            0
          ],
          [
            -116.32981,
            47.54704,
            0
          ],
          [
            -116.33247,
            47.54684,
            0
          ],
          [
            -116.33253,
            47.54683,
            0
          ],
          [
            -116.33678,
            47.54649,
            0
          ],
          [
            -116.33701,
            47.54648,
            0
          ],
          [
            -116.33918,
            47.5463,
            0
          ],
          [
            -116.33941,
            47.54629,
            0
          ],
          [
            -116.33964,
            47.54627,
            0
          ],
          [
            -116.34046,
            47.54623,
            0
          ],
          [
            -116.3412,
            47.54623,
            0
          ],
          [
            -116.34163,
            47.54625,
            0
          ],
          [
            -116.34264,
            47.54635,
            0
          ],
          [
            -116.34365,
            47.54653,
            0
          ],
          [
            -116.34418,
            47.54667,
            0
          ],
          [
            -116.34436,
            47.54671,
            0
          ],
          [
            -116.34473,
            47.54683,
            0
          ],
          [
            -116.34488,
            47.54687,
            0
          ],
          [
            -116.34509,
            47.54694,
            0
          ],
          [
            -116.34526,
            47.54701,
            0
          ],
          [
            -116.34561,
            47.54713,
            0
          ],
          [
            -116.34574,
            47.54719,
            0
          ],
          [
            -116.34597,
            47.54727,
            0
          ],
          [
            -116.3498,
            47.54876,
            0
          ],
          [
            -116.34984,
            47.54878,
            0
          ],
          [
            -116.3552,
            47.55086,
            0
          ],
          [
            -116.357,
            47.55152,
            0
          ],
          [
            -116.35815,
            47.5519,
            0
          ],
          [
            -116.35865,
            47.55205,
            0
          ],
          [
            -116.35882,
            47.55211,
            0
          ],
          [
            -116.36007,
            47.55248,
            0
          ],
          [
            -116.36034,
            47.55255,
            0
          ],
          [
            -116.3605,
            47.5526,
            0
          ],
          [
            -116.36269,
            47.55317,
            0
          ],
          [
            -116.36285,
            47.5532,
            0
          ],
          [
            -116.36385,
            47.55343,
            0
          ],
          [
            -116.36386,
            47.55343,
            0
          ],
          [
            -116.36408,
            47.55348,
            0
          ],
          [
            -116.36411,
            47.55349,
            0
          ],
          [
            -116.36412,
            47.55349,
            0
          ],
          [
            -116.36579,
            47.55383,
            0
          ],
          [
            -116.36712,
            47.55406,
            0
          ],
          [
            -116.36715,
            47.55406,
            0
          ],
          [
            -116.36787,
            47.55418,
            0
          ],
          [
            -116.36875,
            47.5543,
            0
          ],
          [
            -116.36876,
            47.55431,
            0
          ],
          [
            -116.36897,
            47.55433,
            0
          ],
          [
            -116.37228,
            47.55478,
            0
          ],
          [
            -116.37478,
            47.55517,
            0
          ],
          [
            -116.37481,
            47.55517,
            0
          ],
          [
            -116.37653,
            47.55547,
            0
          ],
          [
            -116.37682,
            47.55553,
            0
          ],
          [
            -116.3769,
            47.55554,
            0
          ],
          [
            -116.37847,
            47.55584,
            0
          ],
          [
            -116.38186,
            47.55657,
            0
          ],
          [
            -116.38225,
            47.55667,
            0
          ],
          [
            -116.38249,
            47.55672,
            0
          ],
          [
            -116.38287,
            47.55682,
            0
          ],
          [
            -116.38306,
            47.55686,
            0
          ],
          [
            -116.38493,
            47.55734,
            0
          ],
          [
            -116.38495,
            47.55735,
            0
          ],
          [
            -116.38619,
            47.55768,
            0
          ],
          [
            -116.38644,
            47.55776,
            0
          ],
          [
            -116.38701,
            47.55791,
            0
          ],
          [
            -116.38864,
            47.5584,
            0
          ],
          [
            -116.38876,
            47.55843,
            0
          ],
          [
            -116.3896,
            47.55869,
            0
          ],
          [
            -116.38976,
            47.55875,
            0
          ],
          [
            -116.39013,
            47.55886,
            0
          ],
          [
            -116.39231,
            47.55958,
            0
          ],
          [
            -116.39325,
            47.55991,
            0
          ],
          [
            -116.39332,
            47.55994,
            0
          ],
          [
            -116.39362,
            47.56004,
            0
          ],
          [
            -116.39407,
            47.56021,
            0
          ],
          [
            -116.39448,
            47.56035,
            0
          ],
          [
            -116.39569,
            47.5608,
            0
          ],
          [
            -116.39593,
            47.56088,
            0
          ],
          [
            -116.39837,
            47.56178,
            0
          ],
          [
            -116.39839,
            47.56179,
            0
          ],
          [
            -116.39949,
            47.56219,
            0
          ],
          [
            -116.39956,
            47.56222,
            0
          ],
          [
            -116.3996,
            47.56223,
            0
          ],
          [
            -116.40001,
            47.56238,
            0
          ],
          [
            -116.40003,
            47.56239,
            0
          ],
          [
            -116.4021,
            47.56316,
            0
          ],
          [
            -116.40247,
            47.56331,
            0
          ],
          [
            -116.40262,
            47.56336,
            0
          ],
          [
            -116.40352,
            47.56372,
            0
          ],
          [
            -116.40466,
            47.56422,
            0
          ],
          [
            -116.40562,
            47.56469,
            0
          ],
          [
            -116.40565,
            47.56471,
            0
          ],
          [
            -116.40666,
            47.56523,
            0
          ],
          [
            -116.40809,
            47.56607,
            0
          ],
          [
            -116.40827,
            47.56619,
            0
          ],
          [
            -116.40841,
            47.56627,
            0
          ],
          [
            -116.40857,
            47.56638,
            0
          ],
          [
            -116.40872,
            47.56647,
            0
          ],
          [
            -116.40914,
            47.56675,
            0
          ],
          [
            -116.40929,
            47.56686,
            0
          ],
          [
            -116.40933,
            47.56688,
            0
          ],
          [
            -116.41003,
            47.56738,
            0
          ],
          [
            -116.41104,
            47.56817,
            0
          ],
          [
            -116.41117,
            47.56829,
            0
          ],
          [
            -116.41122,
            47.56832,
            0
          ],
          [
            -116.41165,
            47.56869,
            0
          ],
          [
            -116.41224,
            47.56923,
            0
          ],
          [
            -116.41238,
            47.56937,
            0
          ],
          [
            -116.41248,
            47.56946,
            0
          ],
          [
            -116.41309,
            47.57007,
            0
          ],
          [
            -116.41343,
            47.57043,
            0
          ],
          [
            -116.41433,
            47.57148,
            0
          ],
          [
            -116.41576,
            47.57326,
            0
          ],
          [
            -116.41577,
            47.57326,
            0
          ],
          [
            -116.4159,
            47.57342,
            0
          ],
          [
            -116.41597,
            47.57352,
            0
          ],
          [
            -116.416,
            47.57355,
            0
          ],
          [
            -116.4173,
            47.57515,
            0
          ],
          [
            -116.41735,
            47.57522,
            0
          ],
          [
            -116.41783,
            47.57581,
            0
          ],
          [
            -116.41822,
            47.57624,
            0
          ],
          [
            -116.41824,
            47.57627,
            0
          ],
          [
            -116.41842,
            47.57646,
            0
          ],
          [
            -116.41869,
            47.57672,
            0
          ],
          [
            -116.41916,
            47.57711,
            0
          ],
          [
            -116.41957,
            47.57742,
            0
          ],
          [
            -116.41975,
            47.57754,
            0
          ],
          [
            -116.4199,
            47.57763,
            0
          ],
          [
            -116.42003,
            47.57772,
            0
          ],
          [
            -116.42075,
            47.57813,
            0
          ],
          [
            -116.4213,
            47.57839,
            0
          ],
          [
            -116.42131,
            47.5784,
            0
          ],
          [
            -116.42194,
            47.57866,
            0
          ],
          [
            -116.42262,
            47.5789,
            0
          ],
          [
            -116.42319,
            47.57907,
            0
          ],
          [
            -116.42368,
            47.57919,
            0
          ],
          [
            -116.42415,
            47.57929,
            0
          ],
          [
            -116.42506,
            47.57944,
            0
          ],
          [
            -116.42572,
            47.57951,
            0
          ],
          [
            -116.4263,
            47.57955,
            0
          ],
          [
            -116.42655,
            47.57955,
            0
          ],
          [
            -116.42672,
            47.57956,
            0
          ],
          [
            -116.42732,
            47.57956,
            0
          ],
          [
            -116.42751,
            47.57955,
            0
          ],
          [
            -116.42767,
            47.57955,
            0
          ],
          [
            -116.42836,
            47.57951,
            0
          ],
          [
            -116.42839,
            47.5795,
            0
          ],
          [
            -116.4293,
            47.5794,
            0
          ],
          [
            -116.42973,
            47.57933,
            0
          ],
          [
            -116.43013,
            47.57925,
            0
          ],
          [
            -116.4306,
            47.57914,
            0
          ],
          [
            -116.43144,
            47.57891,
            0
          ],
          [
            -116.43227,
            47.57862,
            0
          ],
          [
            -116.43261,
            47.57848,
            0
          ],
          [
            -116.4331,
            47.57825,
            0
          ],
          [
            -116.43311,
            47.57825,
            0
          ],
          [
            -116.43346,
            47.57807,
            0
          ],
          [
            -116.43376,
            47.57793,
            0
          ],
          [
            -116.43445,
            47.57757,
            0
          ],
          [
            -116.43535,
            47.57713,
            0
          ],
          [
            -116.43539,
            47.5771,
            0
          ],
          [
            -116.43582,
            47.57689,
            0
          ],
          [
            -116.43589,
            47.57685,
            0
          ],
          [
            -116.4359,
            47.57684,
            0
          ],
          [
            -116.43591,
            47.57684,
            0
          ],
          [
            -116.43612,
            47.57673,
            0
          ],
          [
            -116.43616,
            47.57672,
            0
          ],
          [
            -116.43826,
            47.57566,
            0
          ],
          [
            -116.43847,
            47.57562,
            0
          ],
          [
            -116.43876,
            47.57553,
            0
          ],
          [
            -116.43879,
            47.57553,
            0
          ],
          [
            -116.43901,
            47.57546,
            0
          ],
          [
            -116.43906,
            47.57545,
            0
          ],
          [
            -116.43916,
            47.57542,
            0
          ],
          [
            -116.43939,
            47.57537,
            0
          ],
          [
            -116.43942,
            47.57536,
            0
          ],
          [
            -116.43956,
            47.57533,
            0
          ],
          [
            -116.43964,
            47.57532,
            0
          ],
          [
            -116.43967,
            47.57531,
            0
          ],
          [
            -116.44018,
            47.57524,
            0
          ],
          [
            -116.44024,
            47.57524,
            0
          ],
          [
            -116.44073,
            47.5752,
            0
          ],
          [
            -116.44179,
            47.5752,
            0
          ],
          [
            -116.44186,
            47.57521,
            0
          ],
          [
            -116.44233,
            47.57521,
            0
          ],
          [
            -116.44236,
            47.57489,
            0
          ],
          [
            -116.44235,
            47.57472,
            0
          ],
          [
            -116.44235,
            47.5741,
            0
          ],
          [
            -116.4423,
            47.57335,
            0
          ],
          [
            -116.4414,
            47.56643,
            0
          ],
          [
            -116.44111,
            47.5639,
            0
          ],
          [
            -116.44092,
            47.5626,
            0
          ],
          [
            -116.44074,
            47.56107,
            0
          ],
          [
            -116.44074,
            47.56089,
            0
          ],
          [
            -116.44073,
            47.56078,
            0
          ],
          [
            -116.44073,
            47.56054,
            0
          ],
          [
            -116.44078,
            47.56025,
            0
          ],
          [
            -116.44081,
            47.56016,
            0
          ],
          [
            -116.44083,
            47.56007,
            0
          ],
          [
            -116.44093,
            47.55982,
            0
          ],
          [
            -116.44098,
            47.55972,
            0
          ],
          [
            -116.44108,
            47.55955,
            0
          ],
          [
            -116.4412,
            47.55938,
            0
          ],
          [
            -116.44137,
            47.5592,
            0
          ],
          [
            -116.44164,
            47.55895,
            0
          ],
          [
            -116.44192,
            47.55874,
            0
          ],
          [
            -116.44246,
            47.55842,
            0
          ],
          [
            -116.44277,
            47.55825,
            0
          ],
          [
            -116.4428,
            47.55823,
            0
          ],
          [
            -116.45168,
            47.55349,
            0
          ],
          [
            -116.45171,
            47.55347,
            0
          ],
          [
            -116.45485,
            47.55178,
            0
          ],
          [
            -116.45616,
            47.55111,
            0
          ],
          [
            -116.45656,
            47.55093,
            0
          ],
          [
            -116.45735,
            47.55068,
            0
          ],
          [
            -116.45938,
            47.55019,
            0
          ],
          [
            -116.45944,
            47.55018,
            0
          ],
          [
            -116.46031,
            47.54991,
            0
          ],
          [
            -116.46074,
            47.54974,
            0
          ],
          [
            -116.46091,
            47.54966,
            0
          ],
          [
            -116.4613,
            47.54945,
            0
          ],
          [
            -116.46131,
            47.54945,
            0
          ],
          [
            -116.46172,
            47.54919,
            0
          ],
          [
            -116.46199,
            47.54899,
            0
          ],
          [
            -116.46234,
            47.54869,
            0
          ],
          [
            -116.4625,
            47.54852,
            0
          ],
          [
            -116.46282,
            47.54815,
            0
          ],
          [
            -116.46284,
            47.54812,
            0
          ],
          [
            -116.4635,
            47.54731,
            0
          ],
          [
            -116.46373,
            47.54705,
            0
          ],
          [
            -116.46404,
            47.54675,
            0
          ],
          [
            -116.46439,
            47.54645,
            0
          ],
          [
            -116.46471,
            47.54621,
            0
          ],
          [
            -116.46472,
            47.5462,
            0
          ],
          [
            -116.46482,
            47.54613,
            0
          ],
          [
            -116.46545,
            47.54577,
            0
          ],
          [
            -116.4662,
            47.54543,
            0
          ],
          [
            -116.46922,
            47.54422,
            0
          ],
          [
            -116.46961,
            47.54403,
            0
          ],
          [
            -116.47005,
            47.54378,
            0
          ],
          [
            -116.47033,
            47.54358,
            0
          ],
          [
            -116.47044,
            47.54349,
            0
          ],
          [
            -116.47087,
            47.54308,
            0
          ],
          [
            -116.47114,
            47.54272,
            0
          ],
          [
            -116.47119,
            47.54264,
            0
          ],
          [
            -116.47137,
            47.54231,
            0
          ],
          [
            -116.4714,
            47.54223,
            0
          ],
          [
            -116.47145,
            47.54212,
            0
          ],
          [
            -116.47153,
            47.54184,
            0
          ],
          [
            -116.47157,
            47.54163,
            0
          ],
          [
            -116.47158,
            47.54154,
            0
          ],
          [
            -116.47159,
            47.5415,
            0
          ],
          [
            -116.4716,
            47.54107,
            0
          ],
          [
            -116.47158,
            47.54092,
            0
          ],
          [
            -116.47147,
            47.54032,
            0
          ],
          [
            -116.47147,
            47.5403,
            0
          ],
          [
            -116.47143,
            47.54001,
            0
          ],
          [
            -116.47144,
            47.53972,
            0
          ],
          [
            -116.47146,
            47.53958,
            0
          ],
          [
            -116.4715,
            47.53942,
            0
          ],
          [
            -116.47155,
            47.5393,
            0
          ],
          [
            -116.47163,
            47.53914,
            0
          ],
          [
            -116.47173,
            47.53903,
            0
          ],
          [
            -116.4718,
            47.5389,
            0
          ],
          [
            -116.47203,
            47.53863,
            0
          ],
          [
            -116.47208,
            47.53858,
            0
          ],
          [
            -116.47243,
            47.5383,
            0
          ],
          [
            -116.47263,
            47.53816,
            0
          ],
          [
            -116.4731,
            47.5378,
            0
          ],
          [
            -116.47396,
            47.53719,
            0
          ],
          [
            -116.47422,
            47.53703,
            0
          ],
          [
            -116.47479,
            47.53674,
            0
          ],
          [
            -116.47544,
            47.53651,
            0
          ],
          [
            -116.47575,
            47.53642,
            0
          ],
          [
            -116.47576,
            47.53642,
            0
          ],
          [
            -116.476,
            47.53636,
            0
          ],
          [
            -116.47643,
            47.53628,
            0
          ],
          [
            -116.48021,
            47.53583,
            0
          ],
          [
            -116.48027,
            47.53583,
            0
          ],
          [
            -116.48125,
            47.53574,
            0
          ],
          [
            -116.48194,
            47.53571,
            0
          ],
          [
            -116.48281,
            47.53572,
            0
          ],
          [
            -116.48318,
            47.53574,
            0
          ],
          [
            -116.48331,
            47.53574,
            0
          ],
          [
            -116.48399,
            47.5358,
            0
          ],
          [
            -116.48482,
            47.53591,
            0
          ],
          [
            -116.49349,
            47.53756,
            0
          ],
          [
            -116.49436,
            47.5377,
            0
          ],
          [
            -116.49463,
            47.53773,
            0
          ],
          [
            -116.49487,
            47.53774,
            0
          ],
          [
            -116.49536,
            47.53772,
            0
          ],
          [
            -116.49561,
            47.53769,
            0
          ],
          [
            -116.49578,
            47.53766,
            0
          ],
          [
            -116.49598,
            47.53761,
            0
          ],
          [
            -116.49599,
            47.53761,
            0
          ],
          [
            -116.49646,
            47.53745,
            0
          ],
          [
            -116.49813,
            47.53666,
            0
          ],
          [
            -116.49816,
            47.53664,
            0
          ],
          [
            -116.49839,
            47.53653,
            0
          ],
          [
            -116.49903,
            47.53627,
            0
          ],
          [
            -116.49944,
            47.53615,
            0
          ],
          [
            -116.49973,
            47.53608,
            0
          ],
          [
            -116.50041,
            47.53595,
            0
          ],
          [
            -116.50138,
            47.53586,
            0
          ],
          [
            -116.50403,
            47.53569,
            0
          ],
          [
            -116.5041,
            47.53568,
            0
          ],
          [
            -116.51431,
            47.53502,
            0
          ],
          [
            -116.51522,
            47.53494,
            0
          ],
          [
            -116.51563,
            47.53487,
            0
          ],
          [
            -116.51586,
            47.53482,
            0
          ],
          [
            -116.51587,
            47.53481,
            0
          ],
          [
            -116.51621,
            47.53471,
            0
          ],
          [
            -116.51643,
            47.53462,
            0
          ],
          [
            -116.51673,
            47.53446,
            0
          ],
          [
            -116.51696,
            47.53431,
            0
          ],
          [
            -116.51718,
            47.53414,
            0
          ],
          [
            -116.5173,
            47.53402,
            0
          ],
          [
            -116.51746,
            47.53383,
            0
          ],
          [
            -116.51754,
            47.53371,
            0
          ],
          [
            -116.51766,
            47.5335,
            0
          ],
          [
            -116.51774,
            47.53328,
            0
          ],
          [
            -116.51779,
            47.53305,
            0
          ],
          [
            -116.51779,
            47.53283,
            0
          ],
          [
            -116.51776,
            47.5326,
            0
          ],
          [
            -116.5177,
            47.53238,
            0
          ],
          [
            -116.51761,
            47.53216,
            0
          ],
          [
            -116.51738,
            47.53174,
            0
          ],
          [
            -116.51671,
            47.53062,
            0
          ],
          [
            -116.51668,
            47.53058,
            0
          ],
          [
            -116.51621,
            47.52982,
            0
          ],
          [
            -116.51619,
            47.5298,
            0
          ],
          [
            -116.51545,
            47.52858,
            0
          ],
          [
            -116.51539,
            47.52846,
            0
          ],
          [
            -116.51507,
            47.52795,
            0
          ],
          [
            -116.51472,
            47.52735,
            0
          ],
          [
            -116.51467,
            47.52728,
            0
          ],
          [
            -116.51436,
            47.52677,
            0
          ],
          [
            -116.51426,
            47.52659,
            0
          ],
          [
            -116.51419,
            47.52644,
            0
          ],
          [
            -116.51418,
            47.52643,
            0
          ],
          [
            -116.51406,
            47.52609,
            0
          ],
          [
            -116.51406,
            47.52608,
            0
          ],
          [
            -116.51405,
            47.52605,
            0
          ],
          [
            -116.51399,
            47.52573,
            0
          ],
          [
            -116.51398,
            47.52544,
            0
          ],
          [
            -116.51402,
            47.52511,
            0
          ],
          [
            -116.5141,
            47.52483,
            0
          ],
          [
            -116.51429,
            47.52432,
            0
          ],
          [
            -116.51429,
            47.52431,
            0
          ],
          [
            -116.51469,
            47.52328,
            0
          ],
          [
            -116.51471,
            47.52324,
            0
          ],
          [
            -116.5149,
            47.52274,
            0
          ],
          [
            -116.51503,
            47.52236,
            0
          ],
          [
            -116.51527,
            47.52156,
            0
          ],
          [
            -116.51547,
            47.52103,
            0
          ],
          [
            -116.51575,
            47.52035,
            0
          ],
          [
            -116.51591,
            47.52007,
            0
          ],
          [
            -116.51607,
            47.51972,
            0
          ],
          [
            -116.51625,
            47.51936,
            0
          ],
          [
            -116.51635,
            47.51919,
            0
          ],
          [
            -116.51642,
            47.519,
            0
          ],
          [
            -116.5165,
            47.51886,
            0
          ],
          [
            -116.51657,
            47.51876,
            0
          ],
          [
            -116.51667,
            47.51859,
            0
          ],
          [
            -116.51674,
            47.51833,
            0
          ],
          [
            -116.51683,
            47.51812,
            0
          ],
          [
            -116.51692,
            47.51787,
            0
          ],
          [
            -116.51709,
            47.51751,
            0
          ],
          [
            -116.5172,
            47.51722,
            0
          ],
          [
            -116.51733,
            47.5168,
            0
          ],
          [
            -116.51762,
            47.51617,
            0
          ],
          [
            -116.51773,
            47.51598,
            0
          ],
          [
            -116.51775,
            47.51596,
            0
          ],
          [
            -116.51798,
            47.51558,
            0
          ],
          [
            -116.51849,
            47.51492,
            0
          ],
          [
            -116.51876,
            47.51463,
            0
          ],
          [
            -116.51956,
            47.51389,
            0
          ],
          [
            -116.52005,
            47.51352,
            0
          ],
          [
            -116.52029,
            47.51337,
            0
          ],
          [
            -116.52042,
            47.51328,
            0
          ],
          [
            -116.5216,
            47.51264,
            0
          ],
          [
            -116.52177,
            47.51252,
            0
          ],
          [
            -116.52238,
            47.51202,
            0
          ],
          [
            -116.52255,
            47.51191,
            0
          ],
          [
            -116.52269,
            47.51175,
            0
          ],
          [
            -116.52469,
            47.51048,
            0
          ],
          [
            -116.5249,
            47.51036,
            0
          ],
          [
            -116.52534,
            47.51008,
            0
          ],
          [
            -116.52598,
            47.50973,
            0
          ],
          [
            -116.52611,
            47.50967,
            0
          ],
          [
            -116.52623,
            47.5096,
            0
          ],
          [
            -116.52647,
            47.50948,
            0
          ],
          [
            -116.52676,
            47.50935,
            0
          ],
          [
            -116.52679,
            47.50934,
            0
          ],
          [
            -116.5273,
            47.50912,
            0
          ],
          [
            -116.52818,
            47.5088,
            0
          ],
          [
            -116.52893,
            47.50857,
            0
          ],
          [
            -116.52979,
            47.50836,
            0
          ],
          [
            -116.53035,
            47.50824,
            0
          ],
          [
            -116.53063,
            47.50819,
            0
          ],
          [
            -116.53066,
            47.50818,
            0
          ],
          [
            -116.53087,
            47.50814,
            0
          ],
          [
            -116.53089,
            47.50814,
            0
          ],
          [
            -116.53142,
            47.50806,
            0
          ],
          [
            -116.53223,
            47.50797,
            0
          ],
          [
            -116.53264,
            47.50794,
            0
          ],
          [
            -116.53353,
            47.50791,
            0
          ],
          [
            -116.53444,
            47.50792,
            0
          ],
          [
            -116.53679,
            47.50804,
            0
          ],
          [
            -116.53697,
            47.50804,
            0
          ],
          [
            -116.53715,
            47.50805,
            0
          ],
          [
            -116.53753,
            47.50805,
            0
          ],
          [
            -116.53825,
            47.50799,
            0
          ],
          [
            -116.53879,
            47.5079,
            0
          ],
          [
            -116.53929,
            47.50778,
            0
          ],
          [
            -116.5398,
            47.50763,
            0
          ],
          [
            -116.54019,
            47.50748,
            0
          ],
          [
            -116.54048,
            47.50735,
            0
          ],
          [
            -116.54078,
            47.50719,
            0
          ],
          [
            -116.54115,
            47.50697,
            0
          ],
          [
            -116.54145,
            47.50676,
            0
          ],
          [
            -116.54173,
            47.50653,
            0
          ],
          [
            -116.54479,
            47.50359,
            0
          ],
          [
            -116.54489,
            47.50348,
            0
          ],
          [
            -116.54497,
            47.50338,
            0
          ],
          [
            -116.54505,
            47.50326,
            0
          ],
          [
            -116.54515,
            47.50308,
            0
          ],
          [
            -116.54522,
            47.50289,
            0
          ],
          [
            -116.54528,
            47.50245,
            0
          ],
          [
            -116.54529,
            47.5022,
            0
          ],
          [
            -116.54533,
            47.5018,
            0
          ],
          [
            -116.54533,
            47.50177,
            0
          ],
          [
            -116.54537,
            47.50134,
            0
          ],
          [
            -116.54544,
            47.50098,
            0
          ],
          [
            -116.54549,
            47.5008,
            0
          ],
          [
            -116.54551,
            47.50075,
            0
          ],
          [
            -116.54554,
            47.50062,
            0
          ],
          [
            -116.54565,
            47.50032,
            0
          ],
          [
            -116.54583,
            47.49989,
            0
          ],
          [
            -116.54611,
            47.49938,
            0
          ],
          [
            -116.54642,
            47.49894,
            0
          ],
          [
            -116.54676,
            47.49849,
            0
          ],
          [
            -116.54679,
            47.49846,
            0
          ],
          [
            -116.54766,
            47.49732,
            0
          ],
          [
            -116.54777,
            47.49719,
            0
          ],
          [
            -116.54781,
            47.49713,
            0
          ],
          [
            -116.54839,
            47.49649,
            0
          ],
          [
            -116.54863,
            47.49625,
            0
          ],
          [
            -116.54896,
            47.49595,
            0
          ],
          [
            -116.54926,
            47.4957,
            0
          ],
          [
            -116.5496,
            47.49544,
            0
          ],
          [
            -116.55027,
            47.49497,
            0
          ],
          [
            -116.55093,
            47.49458,
            0
          ],
          [
            -116.55191,
            47.49408,
            0
          ],
          [
            -116.5525,
            47.49381,
            0
          ],
          [
            -116.55311,
            47.49357,
            0
          ],
          [
            -116.55336,
            47.49349,
            0
          ],
          [
            -116.55363,
            47.49339,
            0
          ],
          [
            -116.55431,
            47.49319,
            0
          ],
          [
            -116.55488,
            47.49304,
            0
          ],
          [
            -116.55753,
            47.49248,
            0
          ],
          [
            -116.5576,
            47.49247,
            0
          ],
          [
            -116.55817,
            47.49234,
            0
          ],
          [
            -116.56164,
            47.49163,
            0
          ],
          [
            -116.56209,
            47.49149,
            0
          ],
          [
            -116.56231,
            47.49141,
            0
          ],
          [
            -116.56269,
            47.49125,
            0
          ],
          [
            -116.56305,
            47.49105,
            0
          ],
          [
            -116.56329,
            47.49089,
            0
          ],
          [
            -116.56363,
            47.49063,
            0
          ],
          [
            -116.56384,
            47.49042,
            0
          ],
          [
            -116.56394,
            47.49031,
            0
          ],
          [
            -116.56414,
            47.49006,
            0
          ],
          [
            -116.56434,
            47.48975,
            0
          ],
          [
            -116.56445,
            47.4895,
            0
          ],
          [
            -116.56448,
            47.4894,
            0
          ],
          [
            -116.56454,
            47.48915,
            0
          ],
          [
            -116.56467,
            47.48844,
            0
          ],
          [
            -116.56522,
            47.48495,
            0
          ],
          [
            -116.56522,
            47.48493,
            0
          ],
          [
            -116.56532,
            47.48452,
            0
          ],
          [
            -116.56538,
            47.48436,
            0
          ],
          [
            -116.56551,
            47.48414,
            0
          ],
          [
            -116.56568,
            47.48393,
            0
          ],
          [
            -116.56578,
            47.48383,
            0
          ],
          [
            -116.56581,
            47.48381,
            0
          ],
          [
            -116.566,
            47.48364,
            0
          ],
          [
            -116.56614,
            47.48354,
            0
          ],
          [
            -116.56625,
            47.48347,
            0
          ],
          [
            -116.56631,
            47.48344,
            0
          ],
          [
            -116.56632,
            47.48343,
            0
          ],
          [
            -116.56639,
            47.4834,
            0
          ],
          [
            -116.5664,
            47.48339,
            0
          ],
          [
            -116.56647,
            47.48336,
            0
          ],
          [
            -116.56656,
            47.48331,
            0
          ],
          [
            -116.56691,
            47.48318,
            0
          ],
          [
            -116.56738,
            47.48305,
            0
          ],
          [
            -116.56834,
            47.48283,
            0
          ],
          [
            -116.5685,
            47.4828,
            0
          ],
          [
            -116.56853,
            47.48279,
            0
          ],
          [
            -116.56964,
            47.48254,
            0
          ],
          [
            -116.56976,
            47.48252,
            0
          ],
          [
            -116.57078,
            47.48229,
            0
          ],
          [
            -116.57086,
            47.48226,
            0
          ],
          [
            -116.57102,
            47.48222,
            0
          ],
          [
            -116.57103,
            47.48221,
            0
          ],
          [
            -116.57124,
            47.48214,
            0
          ],
          [
            -116.57133,
            47.4821,
            0
          ],
          [
            -116.57135,
            47.4821,
            0
          ],
          [
            -116.57143,
            47.48206,
            0
          ],
          [
            -116.57148,
            47.48204,
            0
          ],
          [
            -116.5715,
            47.48202,
            0
          ],
          [
            -116.5716,
            47.48197,
            0
          ],
          [
            -116.57171,
            47.4819,
            0
          ],
          [
            -116.57174,
            47.48187,
            0
          ],
          [
            -116.57177,
            47.48186,
            0
          ],
          [
            -116.57183,
            47.4818,
            0
          ],
          [
            -116.57191,
            47.48174,
            0
          ],
          [
            -116.57201,
            47.48164,
            0
          ],
          [
            -116.57201,
            47.48163,
            0
          ],
          [
            -116.57205,
            47.48159,
            0
          ],
          [
            -116.57208,
            47.48155,
            0
          ],
          [
            -116.57212,
            47.48151,
            0
          ],
          [
            -116.57215,
            47.48145,
            0
          ],
          [
            -116.5722,
            47.48138,
            0
          ],
          [
            -116.57224,
            47.48131,
            0
          ],
          [
            -116.57225,
            47.48128,
            0
          ],
          [
            -116.57228,
            47.48123,
            0
          ],
          [
            -116.57231,
            47.48116,
            0
          ],
          [
            -116.57232,
            47.48111,
            0
          ],
          [
            -116.57234,
            47.48107,
            0
          ],
          [
            -116.57236,
            47.48098,
            0
          ],
          [
            -116.57238,
            47.4807,
            0
          ],
          [
            -116.57238,
            47.48031,
            0
          ],
          [
            -116.57229,
            47.47856,
            0
          ],
          [
            -116.57229,
            47.47812,
            0
          ],
          [
            -116.57231,
            47.47791,
            0
          ],
          [
            -116.57237,
            47.47772,
            0
          ],
          [
            -116.57246,
            47.47751,
            0
          ],
          [
            -116.57255,
            47.47738,
            0
          ],
          [
            -116.57274,
            47.47716,
            0
          ],
          [
            -116.57281,
            47.47711,
            0
          ],
          [
            -116.57285,
            47.47707,
            0
          ],
          [
            -116.5729,
            47.47704,
            0
          ],
          [
            -116.57302,
            47.47695,
            0
          ],
          [
            -116.57315,
            47.47688,
            0
          ],
          [
            -116.57338,
            47.47677,
            0
          ],
          [
            -116.57355,
            47.4767,
            0
          ],
          [
            -116.57372,
            47.47662,
            0
          ],
          [
            -116.57415,
            47.47646,
            0
          ],
          [
            -116.57427,
            47.4764,
            0
          ],
          [
            -116.57439,
            47.47635,
            0
          ],
          [
            -116.5747,
            47.4762,
            0
          ],
          [
            -116.57498,
            47.47605,
            0
          ],
          [
            -116.57518,
            47.47591,
            0
          ],
          [
            -116.57544,
            47.47566,
            0
          ],
          [
            -116.57558,
            47.47549,
            0
          ],
          [
            -116.57569,
            47.47532,
            0
          ],
          [
            -116.57576,
            47.47519,
            0
          ],
          [
            -116.57579,
            47.47512,
            0
          ],
          [
            -116.57585,
            47.47495,
            0
          ],
          [
            -116.57585,
            47.47494,
            0
          ],
          [
            -116.57592,
            47.47472,
            0
          ],
          [
            -116.57594,
            47.4746,
            0
          ],
          [
            -116.57594,
            47.47458,
            0
          ],
          [
            -116.57625,
            47.47292,
            0
          ],
          [
            -116.57645,
            47.47173,
            0
          ],
          [
            -116.57647,
            47.47155,
            0
          ],
          [
            -116.57653,
            47.4712,
            0
          ],
          [
            -116.57656,
            47.47107,
            0
          ],
          [
            -116.57663,
            47.47068,
            0
          ],
          [
            -116.57663,
            47.47065,
            0
          ],
          [
            -116.57679,
            47.4699,
            0
          ],
          [
            -116.57685,
            47.4697,
            0
          ],
          [
            -116.57698,
            47.46939,
            0
          ],
          [
            -116.57708,
            47.46918,
            0
          ],
          [
            -116.57715,
            47.46901,
            0
          ],
          [
            -116.5773,
            47.46869,
            0
          ],
          [
            -116.57732,
            47.46867,
            0
          ],
          [
            -116.57739,
            47.46851,
            0
          ],
          [
            -116.5774,
            47.4685,
            0
          ],
          [
            -116.57756,
            47.46818,
            0
          ],
          [
            -116.57757,
            47.46815,
            0
          ],
          [
            -116.57837,
            47.46653,
            0
          ],
          [
            -116.57839,
            47.46644,
            0
          ],
          [
            -116.57846,
            47.46622,
            0
          ],
          [
            -116.57847,
            47.46611,
            0
          ],
          [
            -116.57848,
            47.46609,
            0
          ],
          [
            -116.5785,
            47.46573,
            0
          ],
          [
            -116.57852,
            47.46417,
            0
          ],
          [
            -116.57854,
            47.46378,
            0
          ],
          [
            -116.57856,
            47.46358,
            0
          ],
          [
            -116.57859,
            47.46342,
            0
          ],
          [
            -116.5787,
            47.46309,
            0
          ],
          [
            -116.57871,
            47.46304,
            0
          ],
          [
            -116.5788,
            47.46281,
            0
          ],
          [
            -116.57884,
            47.46269,
            0
          ],
          [
            -116.57889,
            47.46247,
            0
          ],
          [
            -116.5789,
            47.46236,
            0
          ],
          [
            -116.57891,
            47.46234,
            0
          ],
          [
            -116.57893,
            47.46215,
            0
          ],
          [
            -116.57904,
            47.46015,
            0
          ],
          [
            -116.57906,
            47.45999,
            0
          ],
          [
            -116.57911,
            47.45979,
            0
          ],
          [
            -116.57916,
            47.45969,
            0
          ],
          [
            -116.57916,
            47.45967,
            0
          ],
          [
            -116.57924,
            47.45952,
            0
          ],
          [
            -116.57931,
            47.45942,
            0
          ],
          [
            -116.57951,
            47.4592,
            0
          ],
          [
            -116.57982,
            47.45896,
            0
          ],
          [
            -116.58007,
            47.45883,
            0
          ],
          [
            -116.58034,
            47.45872,
            0
          ],
          [
            -116.58046,
            47.45869,
            0
          ],
          [
            -116.58054,
            47.45866,
            0
          ],
          [
            -116.58063,
            47.45864,
            0
          ],
          [
            -116.58106,
            47.45857,
            0
          ],
          [
            -116.58129,
            47.45856,
            0
          ],
          [
            -116.58167,
            47.45858,
            0
          ],
          [
            -116.58185,
            47.4586,
            0
          ],
          [
            -116.58208,
            47.45865,
            0
          ],
          [
            -116.58211,
            47.45865,
            0
          ],
          [
            -116.58223,
            47.45869,
            0
          ],
          [
            -116.58225,
            47.45869,
            0
          ],
          [
            -116.5824,
            47.45873,
            0
          ],
          [
            -116.58295,
            47.45895,
            0
          ],
          [
            -116.58365,
            47.45926,
            0
          ],
          [
            -116.58388,
            47.45935,
            0
          ],
          [
            -116.58425,
            47.45947,
            0
          ],
          [
            -116.58478,
            47.45961,
            0
          ],
          [
            -116.58533,
            47.45972,
            0
          ],
          [
            -116.58579,
            47.45979,
            0
          ],
          [
            -116.58607,
            47.45982,
            0
          ],
          [
            -116.58651,
            47.45984,
            0
          ],
          [
            -116.58679,
            47.45984,
            0
          ],
          [
            -116.58734,
            47.45982,
            0
          ],
          [
            -116.58787,
            47.45976,
            0
          ],
          [
            -116.58845,
            47.45967,
            0
          ],
          [
            -116.58889,
            47.45956,
            0
          ],
          [
            -116.589,
            47.45954,
            0
          ],
          [
            -116.59255,
            47.45866,
            0
          ],
          [
            -116.59274,
            47.45862,
            0
          ],
          [
            -116.60361,
            47.45594,
            0
          ],
          [
            -116.60376,
            47.45593,
            0
          ],
          [
            -116.60379,
            47.45592,
            0
          ],
          [
            -116.60396,
            47.45591,
            0
          ],
          [
            -116.60417,
            47.45591,
            0
          ],
          [
            -116.60442,
            47.45593,
            0
          ],
          [
            -116.60471,
            47.45597,
            0
          ],
          [
            -116.60498,
            47.45604,
            0
          ],
          [
            -116.60544,
            47.45622,
            0
          ],
          [
            -116.60588,
            47.45642,
            0
          ],
          [
            -116.60594,
            47.45646,
            0
          ],
          [
            -116.60613,
            47.45656,
            0
          ],
          [
            -116.60641,
            47.45673,
            0
          ],
          [
            -116.60679,
            47.45701,
            0
          ],
          [
            -116.6069,
            47.45711,
            0
          ],
          [
            -116.60819,
            47.45845,
            0
          ],
          [
            -116.60827,
            47.45852,
            0
          ],
          [
            -116.60884,
            47.4591,
            0
          ],
          [
            -116.60939,
            47.45959,
            0
          ],
          [
            -116.60991,
            47.45995,
            0
          ],
          [
            -116.6103,
            47.46016,
            0
          ],
          [
            -116.61082,
            47.46039,
            0
          ],
          [
            -116.6115,
            47.46062,
            0
          ],
          [
            -116.61233,
            47.46083,
            0
          ],
          [
            -116.61243,
            47.46085,
            0
          ],
          [
            -116.61246,
            47.46086,
            0
          ],
          [
            -116.61269,
            47.46091,
            0
          ],
          [
            -116.61318,
            47.46099,
            0
          ],
          [
            -116.61347,
            47.46101,
            0
          ],
          [
            -116.61376,
            47.46101,
            0
          ],
          [
            -116.61413,
            47.46098,
            0
          ],
          [
            -116.61439,
            47.46093,
            0
          ],
          [
            -116.61457,
            47.46088,
            0
          ],
          [
            -116.61481,
            47.46079,
            0
          ],
          [
            -116.61535,
            47.46054,
            0
          ],
          [
            -116.6157,
            47.46034,
            0
          ],
          [
            -116.61584,
            47.46025,
            0
          ],
          [
            -116.61592,
            47.46019,
            0
          ],
          [
            -116.61593,
            47.46019,
            0
          ],
          [
            -116.61609,
            47.46003,
            0
          ],
          [
            -116.61618,
            47.45993,
            0
          ],
          [
            -116.61624,
            47.45984,
            0
          ],
          [
            -116.61629,
            47.45978,
            0
          ],
          [
            -116.6164,
            47.45957,
            0
          ],
          [
            -116.61644,
            47.45944,
            0
          ],
          [
            -116.61654,
            47.45905,
            0
          ],
          [
            -116.61661,
            47.45887,
            0
          ],
          [
            -116.61669,
            47.45871,
            0
          ],
          [
            -116.61683,
            47.45852,
            0
          ],
          [
            -116.61701,
            47.45833,
            0
          ],
          [
            -116.61714,
            47.45822,
            0
          ],
          [
            -116.61736,
            47.45807,
            0
          ],
          [
            -116.61746,
            47.45802,
            0
          ],
          [
            -116.61786,
            47.45786,
            0
          ],
          [
            -116.61808,
            47.4578,
            0
          ],
          [
            -116.61838,
            47.45774,
            0
          ],
          [
            -116.619,
            47.45769,
            0
          ],
          [
            -116.61928,
            47.45768,
            0
          ],
          [
            -116.61996,
            47.45768,
            0
          ],
          [
            -116.62034,
            47.45772,
            0
          ],
          [
            -116.62082,
            47.45784,
            0
          ],
          [
            -116.62105,
            47.45793,
            0
          ],
          [
            -116.62118,
            47.45799,
            0
          ],
          [
            -116.62135,
            47.45809,
            0
          ],
          [
            -116.62136,
            47.4581,
            0
          ],
          [
            -116.62159,
            47.45825,
            0
          ],
          [
            -116.62165,
            47.45831,
            0
          ],
          [
            -116.62175,
            47.45839,
            0
          ],
          [
            -116.62257,
            47.45912,
            0
          ],
          [
            -116.62279,
            47.45929,
            0
          ],
          [
            -116.62288,
            47.45937,
            0
          ],
          [
            -116.62301,
            47.45947,
            0
          ],
          [
            -116.6232,
            47.4596,
            0
          ],
          [
            -116.62351,
            47.45978,
            0
          ],
          [
            -116.6237,
            47.45988,
            0
          ],
          [
            -116.62386,
            47.45995,
            0
          ],
          [
            -116.62413,
            47.46005,
            0
          ],
          [
            -116.62439,
            47.46012,
            0
          ],
          [
            -116.62455,
            47.46015,
            0
          ],
          [
            -116.62469,
            47.46019,
            0
          ],
          [
            -116.62489,
            47.46022,
            0
          ],
          [
            -116.62509,
            47.46026,
            0
          ],
          [
            -116.62532,
            47.46029,
            0
          ],
          [
            -116.62545,
            47.4603,
            0
          ],
          [
            -116.62574,
            47.46034,
            0
          ],
          [
            -116.62995,
            47.46074,
            0
          ],
          [
            -116.63029,
            47.46076,
            0
          ],
          [
            -116.63061,
            47.46076,
            0
          ],
          [
            -116.631,
            47.46074,
            0
          ],
          [
            -116.63138,
            47.46067,
            0
          ],
          [
            -116.63156,
            47.46062,
            0
          ],
          [
            -116.63174,
            47.46055,
            0
          ],
          [
            -116.63175,
            47.46055,
            0
          ],
          [
            -116.63221,
            47.46032,
            0
          ],
          [
            -116.63586,
            47.45768,
            0
          ],
          [
            -116.63667,
            47.45707,
            0
          ],
          [
            -116.63696,
            47.45682,
            0
          ],
          [
            -116.63733,
            47.45648,
            0
          ],
          [
            -116.63774,
            47.45606,
            0
          ],
          [
            -116.63821,
            47.45551,
            0
          ],
          [
            -116.63824,
            47.45548,
            0
          ],
          [
            -116.63855,
            47.45508,
            0
          ],
          [
            -116.6395,
            47.45394,
            0
          ],
          [
            -116.63998,
            47.45342,
            0
          ],
          [
            -116.64068,
            47.45275,
            0
          ],
          [
            -116.64081,
            47.4526,
            0
          ],
          [
            -116.64082,
            47.4526,
            0
          ],
          [
            -116.64124,
            47.45214,
            0
          ],
          [
            -116.64154,
            47.45172,
            0
          ],
          [
            -116.64165,
            47.45153,
            0
          ],
          [
            -116.64173,
            47.45137,
            0
          ],
          [
            -116.64175,
            47.45131,
            0
          ],
          [
            -116.64182,
            47.45115,
            0
          ],
          [
            -116.64182,
            47.45114,
            0
          ],
          [
            -116.64188,
            47.45098,
            0
          ],
          [
            -116.64193,
            47.4508,
            0
          ],
          [
            -116.64199,
            47.45037,
            0
          ],
          [
            -116.642,
            47.45021,
            0
          ],
          [
            -116.642,
            47.45005,
            0
          ],
          [
            -116.64199,
            47.44992,
            0
          ],
          [
            -116.64196,
            47.44975,
            0
          ],
          [
            -116.64187,
            47.44936,
            0
          ],
          [
            -116.64181,
            47.4492,
            0
          ],
          [
            -116.64169,
            47.44894,
            0
          ],
          [
            -116.64154,
            47.44865,
            0
          ],
          [
            -116.64141,
            47.44848,
            0
          ],
          [
            -116.64122,
            47.44826,
            0
          ],
          [
            -116.64071,
            47.44774,
            0
          ],
          [
            -116.64045,
            47.4475,
            0
          ],
          [
            -116.63993,
            47.44706,
            0
          ],
          [
            -116.63983,
            47.44696,
            0
          ],
          [
            -116.6395,
            47.44671,
            0
          ],
          [
            -116.63928,
            47.44656,
            0
          ],
          [
            -116.63895,
            47.4464,
            0
          ],
          [
            -116.63873,
            47.44631,
            0
          ],
          [
            -116.63853,
            47.44624,
            0
          ],
          [
            -116.63705,
            47.44584,
            0
          ],
          [
            -116.63529,
            47.44527,
            0
          ],
          [
            -116.63475,
            47.44512,
            0
          ],
          [
            -116.63453,
            47.44507,
            0
          ],
          [
            -116.6342,
            47.44497,
            0
          ],
          [
            -116.63388,
            47.44483,
            0
          ],
          [
            -116.63369,
            47.44473,
            0
          ],
          [
            -116.6334,
            47.44454,
            0
          ],
          [
            -116.63267,
            47.44374,
            0
          ],
          [
            -116.63247,
            47.44358,
            0
          ],
          [
            -116.63221,
            47.44342,
            0
          ],
          [
            -116.63159,
            47.44313,
            0
          ],
          [
            -116.63138,
            47.443,
            0
          ],
          [
            -116.63126,
            47.44291,
            0
          ],
          [
            -116.6311,
            47.44276,
            0
          ],
          [
            -116.63099,
            47.44264,
            0
          ],
          [
            -116.6309,
            47.44252,
            0
          ],
          [
            -116.63082,
            47.4424,
            0
          ],
          [
            -116.63067,
            47.44212,
            0
          ],
          [
            -116.63061,
            47.44199,
            0
          ],
          [
            -116.63046,
            47.44162,
            0
          ],
          [
            -116.63023,
            47.44112,
            0
          ],
          [
            -116.62997,
            47.44073,
            0
          ],
          [
            -116.62989,
            47.44063,
            0
          ],
          [
            -116.62939,
            47.44007,
            0
          ],
          [
            -116.62853,
            47.43903,
            0
          ],
          [
            -116.62826,
            47.43867,
            0
          ],
          [
            -116.62801,
            47.4383,
            0
          ],
          [
            -116.62789,
            47.43809,
            0
          ],
          [
            -116.62776,
            47.43776,
            0
          ],
          [
            -116.62773,
            47.43762,
            0
          ],
          [
            -116.62771,
            47.43749,
            0
          ],
          [
            -116.6277,
            47.43738,
            0
          ],
          [
            -116.62771,
            47.43724,
            0
          ],
          [
            -116.62775,
            47.43705,
            0
          ],
          [
            -116.62779,
            47.4369,
            0
          ],
          [
            -116.62788,
            47.43673,
            0
          ],
          [
            -116.628,
            47.43654,
            0
          ],
          [
            -116.62833,
            47.43611,
            0
          ],
          [
            -116.62837,
            47.43607,
            0
          ],
          [
            -116.62973,
            47.43438,
            0
          ],
          [
            -116.62985,
            47.43419,
            0
          ],
          [
            -116.62992,
            47.43404,
            0
          ],
          [
            -116.62995,
            47.43395,
            0
          ],
          [
            -116.62998,
            47.43378,
            0
          ],
          [
            -116.62999,
            47.43368,
            0
          ],
          [
            -116.62997,
            47.43343,
            0
          ],
          [
            -116.62992,
            47.43327,
            0
          ],
          [
            -116.62984,
            47.4331,
            0
          ],
          [
            -116.62973,
            47.43292,
            0
          ],
          [
            -116.62963,
            47.43279,
            0
          ],
          [
            -116.62932,
            47.43243,
            0
          ],
          [
            -116.62909,
            47.43214,
            0
          ],
          [
            -116.62897,
            47.43192,
            0
          ],
          [
            -116.62894,
            47.43183,
            0
          ],
          [
            -116.6289,
            47.43174,
            0
          ],
          [
            -116.62886,
            47.43154,
            0
          ],
          [
            -116.62882,
            47.43118,
            0
          ],
          [
            -116.62882,
            47.431,
            0
          ],
          [
            -116.62881,
            47.43081,
            0
          ],
          [
            -116.6288,
            47.43075,
            0
          ],
          [
            -116.6288,
            47.43071,
            0
          ],
          [
            -116.62878,
            47.4306,
            0
          ],
          [
            -116.62872,
            47.43042,
            0
          ],
          [
            -116.62863,
            47.43023,
            0
          ],
          [
            -116.62855,
            47.43009,
            0
          ],
          [
            -116.62842,
            47.42991,
            0
          ],
          [
            -116.6274,
            47.42862,
            0
          ],
          [
            -116.62712,
            47.42822,
            0
          ],
          [
            -116.62708,
            47.42814,
            0
          ],
          [
            -116.62674,
            47.42754,
            0
          ],
          [
            -116.62637,
            47.42679,
            0
          ],
          [
            -116.62637,
            47.42677,
            0
          ],
          [
            -116.62546,
            47.42491,
            0
          ],
          [
            -116.62522,
            47.42439,
            0
          ],
          [
            -116.62517,
            47.4243,
            0
          ],
          [
            -116.62401,
            47.42192,
            0
          ],
          [
            -116.62395,
            47.42181,
            0
          ],
          [
            -116.6238,
            47.42132,
            0
          ],
          [
            -116.62378,
            47.42117,
            0
          ],
          [
            -116.62377,
            47.42103,
            0
          ],
          [
            -116.62377,
            47.41969,
            0
          ],
          [
            -116.62378,
            47.41955,
            0
          ],
          [
            -116.62384,
            47.41368,
            0
          ],
          [
            -116.62386,
            47.41342,
            0
          ],
          [
            -116.62392,
            47.41317,
            0
          ],
          [
            -116.62399,
            47.41301,
            0
          ],
          [
            -116.62408,
            47.41287,
            0
          ],
          [
            -116.62422,
            47.4127,
            0
          ],
          [
            -116.62436,
            47.41256,
            0
          ],
          [
            -116.6245,
            47.41246,
            0
          ],
          [
            -116.62479,
            47.41229,
            0
          ],
          [
            -116.625,
            47.4122,
            0
          ],
          [
            -116.62539,
            47.41207,
            0
          ],
          [
            -116.6257,
            47.41201,
            0
          ],
          [
            -116.62573,
            47.41201,
            0
          ],
          [
            -116.62599,
            47.41198,
            0
          ],
          [
            -116.62632,
            47.41198,
            0
          ],
          [
            -116.62656,
            47.412,
            0
          ],
          [
            -116.6267,
            47.41202,
            0
          ],
          [
            -116.62689,
            47.41206,
            0
          ],
          [
            -116.62704,
            47.4121,
            0
          ],
          [
            -116.62738,
            47.41222,
            0
          ],
          [
            -116.62762,
            47.41233,
            0
          ],
          [
            -116.62773,
            47.41239,
            0
          ],
          [
            -116.62848,
            47.41289,
            0
          ],
          [
            -116.62871,
            47.41302,
            0
          ],
          [
            -116.6289,
            47.4131,
            0
          ],
          [
            -116.62899,
            47.41313,
            0
          ],
          [
            -116.62902,
            47.41315,
            0
          ],
          [
            -116.62948,
            47.41328,
            0
          ],
          [
            -116.62966,
            47.41331,
            0
          ],
          [
            -116.62973,
            47.41333,
            0
          ],
          [
            -116.62999,
            47.41336,
            0
          ],
          [
            -116.63002,
            47.41336,
            0
          ],
          [
            -116.6302,
            47.41338,
            0
          ],
          [
            -116.63412,
            47.41341,
            0
          ],
          [
            -116.63451,
            47.41344,
            0
          ],
          [
            -116.63466,
            47.41346,
            0
          ],
          [
            -116.63469,
            47.41346,
            0
          ],
          [
            -116.63479,
            47.41347,
            0
          ],
          [
            -116.63537,
            47.41358,
            0
          ],
          [
            -116.6358,
            47.41371,
            0
          ],
          [
            -116.63598,
            47.41378,
            0
          ],
          [
            -116.63615,
            47.41386,
            0
          ],
          [
            -116.63618,
            47.41387,
            0
          ],
          [
            -116.63665,
            47.41412,
            0
          ],
          [
            -116.63681,
            47.41423,
            0
          ],
          [
            -116.63712,
            47.41449,
            0
          ],
          [
            -116.63722,
            47.41459,
            0
          ],
          [
            -116.63742,
            47.41482,
            0
          ],
          [
            -116.63753,
            47.41496,
            0
          ],
          [
            -116.63754,
            47.41498,
            0
          ],
          [
            -116.63766,
            47.41514,
            0
          ],
          [
            -116.63767,
            47.41514,
            0
          ],
          [
            -116.63776,
            47.41528,
            0
          ],
          [
            -116.63777,
            47.41529,
            0
          ],
          [
            -116.63831,
            47.41606,
            0
          ],
          [
            -116.63846,
            47.41625,
            0
          ],
          [
            -116.63878,
            47.41658,
            0
          ],
          [
            -116.63891,
            47.4167,
            0
          ],
          [
            -116.63894,
            47.41671,
            0
          ],
          [
            -116.63905,
            47.41679,
            0
          ],
          [
            -116.63915,
            47.41685,
            0
          ],
          [
            -116.63919,
            47.41688,
            0
          ],
          [
            -116.6395,
            47.41702,
            0
          ],
          [
            -116.63969,
            47.41709,
            0
          ],
          [
            -116.63992,
            47.41716,
            0
          ],
          [
            -116.64028,
            47.41724,
            0
          ],
          [
            -116.64062,
            47.41729,
            0
          ],
          [
            -116.64106,
            47.41731,
            0
          ],
          [
            -116.64134,
            47.41731,
            0
          ],
          [
            -116.64153,
            47.4173,
            0
          ],
          [
            -116.64441,
            47.41702,
            0
          ],
          [
            -116.64455,
            47.417,
            0
          ],
          [
            -116.65042,
            47.41645,
            0
          ],
          [
            -116.65109,
            47.41637,
            0
          ],
          [
            -116.6517,
            47.41628,
            0
          ],
          [
            -116.65238,
            47.41616,
            0
          ],
          [
            -116.65241,
            47.41616,
            0
          ],
          [
            -116.653,
            47.41604,
            0
          ],
          [
            -116.65378,
            47.41584,
            0
          ],
          [
            -116.6546,
            47.41559,
            0
          ],
          [
            -116.65461,
            47.41558,
            0
          ],
          [
            -116.65516,
            47.41537,
            0
          ],
          [
            -116.65814,
            47.41412,
            0
          ],
          [
            -116.6584,
            47.414,
            0
          ],
          [
            -116.65841,
            47.414,
            0
          ],
          [
            -116.65863,
            47.41389,
            0
          ],
          [
            -116.65867,
            47.41388,
            0
          ],
          [
            -116.6591,
            47.41364,
            0
          ],
          [
            -116.65933,
            47.4135,
            0
          ],
          [
            -116.65953,
            47.41336,
            0
          ],
          [
            -116.65973,
            47.4132,
            0
          ],
          [
            -116.65978,
            47.41315,
            0
          ],
          [
            -116.65987,
            47.41308,
            0
          ],
          [
            -116.66008,
            47.41288,
            0
          ],
          [
            -116.66029,
            47.41264,
            0
          ],
          [
            -116.66053,
            47.41233,
            0
          ],
          [
            -116.66063,
            47.41218,
            0
          ],
          [
            -116.66069,
            47.41206,
            0
          ],
          [
            -116.66075,
            47.41196,
            0
          ],
          [
            -116.66089,
            47.41164,
            0
          ],
          [
            -116.66097,
            47.41135,
            0
          ],
          [
            -116.661,
            47.41117,
            0
          ],
          [
            -116.66105,
            47.41066,
            0
          ],
          [
            -116.66105,
            47.41059,
            0
          ],
          [
            -116.66106,
            47.41048,
            0
          ],
          [
            -116.66107,
            47.40915,
            0
          ],
          [
            -116.66106,
            47.40897,
            0
          ],
          [
            -116.66106,
            47.40879,
            0
          ],
          [
            -116.66105,
            47.40861,
            0
          ],
          [
            -116.66105,
            47.40825,
            0
          ],
          [
            -116.66104,
            47.40808,
            0
          ],
          [
            -116.66105,
            47.40753,
            0
          ],
          [
            -116.66103,
            47.40076,
            0
          ],
          [
            -116.66114,
            47.40055,
            0
          ],
          [
            -116.66123,
            47.40044,
            0
          ],
          [
            -116.66141,
            47.40029,
            0
          ],
          [
            -116.66153,
            47.40021,
            0
          ],
          [
            -116.66171,
            47.40011,
            0
          ],
          [
            -116.66186,
            47.40005,
            0
          ],
          [
            -116.66201,
            47.4,
            0
          ],
          [
            -116.66235,
            47.39995,
            0
          ],
          [
            -116.6625,
            47.39994,
            0
          ],
          [
            -116.66267,
            47.39994,
            0
          ],
          [
            -116.66304,
            47.39998,
            0
          ],
          [
            -116.66484,
            47.40067,
            0
          ],
          [
            -116.66542,
            47.40086,
            0
          ],
          [
            -116.6658,
            47.40095,
            0
          ],
          [
            -116.66633,
            47.40103,
            0
          ],
          [
            -116.6664,
            47.40103,
            0
          ],
          [
            -116.6666,
            47.40105,
            0
          ],
          [
            -116.66688,
            47.40106,
            0
          ],
          [
            -116.66962,
            47.401,
            0
          ],
          [
            -116.6702,
            47.40105,
            0
          ],
          [
            -116.67034,
            47.40107,
            0
          ],
          [
            -116.67035,
            47.40108,
            0
          ],
          [
            -116.67075,
            47.40116,
            0
          ],
          [
            -116.67127,
            47.40133,
            0
          ],
          [
            -116.67155,
            47.40145,
            0
          ],
          [
            -116.67198,
            47.40169,
            0
          ],
          [
            -116.6723,
            47.40193,
            0
          ],
          [
            -116.67248,
            47.4021,
            0
          ],
          [
            -116.67268,
            47.40233,
            0
          ],
          [
            -116.67303,
            47.40277,
            0
          ],
          [
            -116.67304,
            47.40279,
            0
          ],
          [
            -116.6733,
            47.40308,
            0
          ],
          [
            -116.67346,
            47.40322,
            0
          ],
          [
            -116.67351,
            47.40327,
            0
          ],
          [
            -116.67363,
            47.40336,
            0
          ],
          [
            -116.67399,
            47.4036,
            0
          ],
          [
            -116.67402,
            47.40361,
            0
          ],
          [
            -116.67412,
            47.40367,
            0
          ],
          [
            -116.67434,
            47.40378,
            0
          ],
          [
            -116.67465,
            47.4039,
            0
          ],
          [
            -116.6816,
            47.40619,
            0
          ],
          [
            -116.68161,
            47.40619,
            0
          ],
          [
            -116.682,
            47.40635,
            0
          ],
          [
            -116.68229,
            47.40649,
            0
          ],
          [
            -116.68272,
            47.40673,
            0
          ],
          [
            -116.68594,
            47.40865,
            0
          ],
          [
            -116.68602,
            47.40869,
            0
          ],
          [
            -116.68676,
            47.40915,
            0
          ],
          [
            -116.6871,
            47.40938,
            0
          ],
          [
            -116.6871,
            47.40939,
            0
          ],
          [
            -116.68725,
            47.40949,
            0
          ],
          [
            -116.68728,
            47.40952,
            0
          ],
          [
            -116.68778,
            47.40994,
            0
          ],
          [
            -116.68781,
            47.40997,
            0
          ],
          [
            -116.68811,
            47.41022,
            0
          ],
          [
            -116.6884,
            47.41048,
            0
          ],
          [
            -116.68863,
            47.41066,
            0
          ],
          [
            -116.68884,
            47.41079,
            0
          ],
          [
            -116.68922,
            47.41099,
            0
          ],
          [
            -116.68932,
            47.41104,
            0
          ],
          [
            -116.69011,
            47.41138,
            0
          ],
          [
            -116.69041,
            47.41152,
            0
          ],
          [
            -116.69053,
            47.41159,
            0
          ],
          [
            -116.6909,
            47.41186,
            0
          ],
          [
            -116.6911,
            47.41199,
            0
          ],
          [
            -116.69151,
            47.4125,
            0
          ],
          [
            -116.69196,
            47.41315,
            0
          ],
          [
            -116.69229,
            47.41357,
            0
          ],
          [
            -116.69237,
            47.41365,
            0
          ],
          [
            -116.6926,
            47.41385,
            0
          ],
          [
            -116.69282,
            47.41402,
            0
          ],
          [
            -116.6932,
            47.41425,
            0
          ],
          [
            -116.69394,
            47.41455,
            0
          ],
          [
            -116.69397,
            47.41455,
            0
          ],
          [
            -116.69445,
            47.41468,
            0
          ],
          [
            -116.69496,
            47.41479,
            0
          ],
          [
            -116.69497,
            47.4148,
            0
          ],
          [
            -116.69666,
            47.41517,
            0
          ],
          [
            -116.69699,
            47.41528,
            0
          ],
          [
            -116.69733,
            47.41542,
            0
          ],
          [
            -116.69751,
            47.41551,
            0
          ],
          [
            -116.69779,
            47.41568,
            0
          ],
          [
            -116.698,
            47.41582,
            0
          ],
          [
            -116.69832,
            47.41608,
            0
          ],
          [
            -116.69866,
            47.4164,
            0
          ],
          [
            -116.69945,
            47.4171,
            0
          ],
          [
            -116.69976,
            47.4174,
            0
          ],
          [
            -116.70024,
            47.41782,
            0
          ],
          [
            -116.70092,
            47.41834,
            0
          ],
          [
            -116.70131,
            47.41858,
            0
          ],
          [
            -116.70397,
            47.42001,
            0
          ],
          [
            -116.70398,
            47.42002,
            0
          ],
          [
            -116.70432,
            47.42023,
            0
          ],
          [
            -116.70455,
            47.42039,
            0
          ],
          [
            -116.70471,
            47.42052,
            0
          ],
          [
            -116.70503,
            47.42081,
            0
          ],
          [
            -116.7059,
            47.42173,
            0
          ],
          [
            -116.7059,
            47.42174,
            0
          ],
          [
            -116.70702,
            47.42295,
            0
          ],
          [
            -116.70703,
            47.42295,
            0
          ],
          [
            -116.70787,
            47.42387,
            0
          ],
          [
            -116.70842,
            47.42443,
            0
          ],
          [
            -116.7088,
            47.42473,
            0
          ],
          [
            -116.70937,
            47.42508,
            0
          ],
          [
            -116.70968,
            47.42523,
            0
          ],
          [
            -116.71095,
            47.4258,
            0
          ],
          [
            -116.711,
            47.42583,
            0
          ],
          [
            -116.71117,
            47.4259,
            0
          ],
          [
            -116.71154,
            47.42609,
            0
          ],
          [
            -116.71199,
            47.42636,
            0
          ],
          [
            -116.7122,
            47.42647,
            0
          ],
          [
            -116.71221,
            47.42648,
            0
          ],
          [
            -116.71281,
            47.42682,
            0
          ],
          [
            -116.71289,
            47.42686,
            0
          ],
          [
            -116.71301,
            47.42694,
            0
          ],
          [
            -116.71361,
            47.42729,
            0
          ],
          [
            -116.71448,
            47.42776,
            0
          ],
          [
            -116.71502,
            47.42803,
            0
          ],
          [
            -116.71538,
            47.42817,
            0
          ],
          [
            -116.71628,
            47.42847,
            0
          ],
          [
            -116.71634,
            47.42848,
            0
          ],
          [
            -116.71666,
            47.42857,
            0
          ],
          [
            -116.71718,
            47.42868,
            0
          ],
          [
            -116.71793,
            47.42879,
            0
          ],
          [
            -116.72038,
            47.42907,
            0
          ],
          [
            -116.72126,
            47.42919,
            0
          ],
          [
            -116.72167,
            47.42927,
            0
          ],
          [
            -116.72202,
            47.42936,
            0
          ],
          [
            -116.72224,
            47.42943,
            0
          ],
          [
            -116.72269,
            47.4296,
            0
          ],
          [
            -116.72304,
            47.42977,
            0
          ],
          [
            -116.72526,
            47.43113,
            0
          ],
          [
            -116.72532,
            47.43116,
            0
          ],
          [
            -116.72754,
            47.43253,
            0
          ],
          [
            -116.72776,
            47.43269,
            0
          ],
          [
            -116.72793,
            47.43283,
            0
          ],
          [
            -116.72824,
            47.43315,
            0
          ],
          [
            -116.72852,
            47.43353,
            0
          ],
          [
            -116.72897,
            47.43418,
            0
          ],
          [
            -116.7292,
            47.43449,
            0
          ],
          [
            -116.72955,
            47.43491,
            0
          ],
          [
            -116.72976,
            47.43512,
            0
          ],
          [
            -116.73002,
            47.43533,
            0
          ],
          [
            -116.73017,
            47.43544,
            0
          ],
          [
            -116.73024,
            47.43548,
            0
          ],
          [
            -116.73037,
            47.43557,
            0
          ],
          [
            -116.73056,
            47.43568,
            0
          ],
          [
            -116.73074,
            47.43577,
            0
          ],
          [
            -116.73084,
            47.43583,
            0
          ],
          [
            -116.73204,
            47.43636,
            0
          ],
          [
            -116.73222,
            47.43643,
            0
          ],
          [
            -116.73251,
            47.43656,
            0
          ],
          [
            -116.73279,
            47.43667,
            0
          ],
          [
            -116.73327,
            47.43683,
            0
          ],
          [
            -116.73352,
            47.4369,
            0
          ],
          [
            -116.73391,
            47.43698,
            0
          ],
          [
            -116.73393,
            47.43699,
            0
          ],
          [
            -116.73415,
            47.43702,
            0
          ],
          [
            -116.73419,
            47.43703,
            0
          ],
          [
            -116.73441,
            47.43705,
            0
          ],
          [
            -116.73445,
            47.43706,
            0
          ],
          [
            -116.73574,
            47.43718,
            0
          ],
          [
            -116.73577,
            47.43718,
            0
          ],
          [
            -116.73656,
            47.43725,
            0
          ],
          [
            -116.73657,
            47.43726,
            0
          ],
          [
            -116.73738,
            47.43734,
            0
          ],
          [
            -116.73797,
            47.43745,
            0
          ],
          [
            -116.73822,
            47.43752,
            0
          ],
          [
            -116.73859,
            47.43764,
            0
          ],
          [
            -116.7386,
            47.43764,
            0
          ],
          [
            -116.73883,
            47.43772,
            0
          ],
          [
            -116.73885,
            47.43773,
            0
          ],
          [
            -116.74323,
            47.4394,
            0
          ],
          [
            -116.74324,
            47.4394,
            0
          ],
          [
            -116.74346,
            47.43949,
            0
          ],
          [
            -116.7437,
            47.43957,
            0
          ],
          [
            -116.74452,
            47.4399,
            0
          ],
          [
            -116.74483,
            47.44001,
            0
          ],
          [
            -116.74575,
            47.44037,
            0
          ],
          [
            -116.74585,
            47.44042,
            0
          ],
          [
            -116.74599,
            47.44048,
            0
          ],
          [
            -116.74627,
            47.44065,
            0
          ],
          [
            -116.74638,
            47.44074,
            0
          ],
          [
            -116.74639,
            47.44074,
            0
          ],
          [
            -116.74686,
            47.44117,
            0
          ],
          [
            -116.747,
            47.44134,
            0
          ],
          [
            -116.7473,
            47.44167,
            0
          ],
          [
            -116.74748,
            47.44184,
            0
          ],
          [
            -116.74759,
            47.44192,
            0
          ],
          [
            -116.7479,
            47.44212,
            0
          ],
          [
            -116.74799,
            47.44217,
            0
          ],
          [
            -116.7483,
            47.44231,
            0
          ],
          [
            -116.74882,
            47.44247,
            0
          ],
          [
            -116.74925,
            47.44256,
            0
          ],
          [
            -116.74974,
            47.4426,
            0
          ],
          [
            -116.75017,
            47.4426,
            0
          ],
          [
            -116.75082,
            47.44256,
            0
          ],
          [
            -116.75176,
            47.44246,
            0
          ],
          [
            -116.75228,
            47.44244,
            0
          ],
          [
            -116.75244,
            47.44245,
            0
          ],
          [
            -116.75601,
            47.44242,
            0
          ],
          [
            -116.75676,
            47.44245,
            0
          ],
          [
            -116.75704,
            47.44248,
            0
          ],
          [
            -116.7576,
            47.44257,
            0
          ],
          [
            -116.75779,
            47.44261,
            0
          ],
          [
            -116.75816,
            47.44271,
            0
          ],
          [
            -116.75872,
            47.44291,
            0
          ],
          [
            -116.75889,
            47.44299,
            0
          ],
          [
            -116.75939,
            47.4432,
            0
          ],
          [
            -116.75964,
            47.44332,
            0
          ],
          [
            -116.75976,
            47.44339,
            0
          ],
          [
            -116.75983,
            47.44342,
            0
          ],
          [
            -116.75994,
            47.44348,
            0
          ],
          [
            -116.76019,
            47.44364,
            0
          ],
          [
            -116.76133,
            47.44454,
            0
          ],
          [
            -116.76149,
            47.44465,
            0
          ],
          [
            -116.76197,
            47.44492,
            0
          ],
          [
            -116.76276,
            47.44532,
            0
          ],
          [
            -116.76293,
            47.44539,
            0
          ],
          [
            -116.76301,
            47.44543,
            0
          ],
          [
            -116.76314,
            47.44547,
            0
          ],
          [
            -116.76321,
            47.4455,
            0
          ],
          [
            -116.76323,
            47.4455,
            0
          ],
          [
            -116.76335,
            47.44554,
            0
          ],
          [
            -116.76627,
            47.44599,
            0
          ],
          [
            -116.76709,
            47.4461,
            0
          ],
          [
            -116.7675,
            47.44612,
            0
          ],
          [
            -116.76765,
            47.44614,
            0
          ],
          [
            -116.76772,
            47.44614,
            0
          ],
          [
            -116.76803,
            47.44621,
            0
          ],
          [
            -116.76814,
            47.44626,
            0
          ],
          [
            -116.76825,
            47.44632,
            0
          ],
          [
            -116.76843,
            47.44646,
            0
          ],
          [
            -116.76854,
            47.44662,
            0
          ],
          [
            -116.76862,
            47.44687,
            0
          ],
          [
            -116.76865,
            47.44704,
            0
          ],
          [
            -116.76866,
            47.44715,
            0
          ],
          [
            -116.76869,
            47.44735,
            0
          ],
          [
            -116.76877,
            47.4475,
            0
          ],
          [
            -116.76883,
            47.44758,
            0
          ],
          [
            -116.7689,
            47.44764,
            0
          ],
          [
            -116.76912,
            47.44776,
            0
          ],
          [
            -116.76934,
            47.44783,
            0
          ],
          [
            -116.76952,
            47.44785,
            0
          ],
          [
            -116.76975,
            47.44785,
            0
          ],
          [
            -116.7699,
            47.44783,
            0
          ],
          [
            -116.77036,
            47.44773,
            0
          ],
          [
            -116.77163,
            47.4474,
            0
          ],
          [
            -116.77201,
            47.44736,
            0
          ],
          [
            -116.7723,
            47.44736,
            0
          ],
          [
            -116.77244,
            47.44737,
            0
          ],
          [
            -116.77251,
            47.44737,
            0
          ],
          [
            -116.77259,
            47.44739,
            0
          ],
          [
            -116.77271,
            47.44741,
            0
          ],
          [
            -116.77352,
            47.4476,
            0
          ],
          [
            -116.77437,
            47.44782,
            0
          ],
          [
            -116.77445,
            47.44783,
            0
          ],
          [
            -116.77519,
            47.44803,
            0
          ],
          [
            -116.77547,
            47.44809,
            0
          ],
          [
            -116.776,
            47.44826,
            0
          ],
          [
            -116.77687,
            47.44867,
            0
          ],
          [
            -116.77746,
            47.44889,
            0
          ],
          [
            -116.77778,
            47.44899,
            0
          ],
          [
            -116.77873,
            47.44932,
            0
          ],
          [
            -116.77984,
            47.44982,
            0
          ],
          [
            -116.78049,
            47.45008,
            0
          ],
          [
            -116.78072,
            47.45016,
            0
          ],
          [
            -116.78076,
            47.45018,
            0
          ],
          [
            -116.78217,
            47.45062,
            0
          ],
          [
            -116.78282,
            47.45084,
            0
          ],
          [
            -116.78344,
            47.45108,
            0
          ],
          [
            -116.78421,
            47.45147,
            0
          ],
          [
            -116.78506,
            47.452,
            0
          ],
          [
            -116.78542,
            47.45228,
            0
          ],
          [
            -116.78544,
            47.45231,
            0
          ],
          [
            -116.78572,
            47.45261,
            0
          ],
          [
            -116.78594,
            47.45298,
            0
          ],
          [
            -116.78597,
            47.45302,
            0
          ],
          [
            -116.78602,
            47.45318,
            0
          ],
          [
            -116.78605,
            47.45332,
            0
          ],
          [
            -116.78606,
            47.45333,
            0
          ],
          [
            -116.7861,
            47.45363,
            0
          ],
          [
            -116.7861,
            47.45377,
            0
          ],
          [
            -116.78605,
            47.45425,
            0
          ],
          [
            -116.78564,
            47.45422,
            0
          ],
          [
            -116.78545,
            47.45422,
            0
          ],
          [
            -116.78564,
            47.45422,
            0
          ],
          [
            -116.78605,
            47.45425,
            0
          ],
          [
            -116.7861,
            47.45377,
            0
          ],
          [
            -116.7861,
            47.45363,
            0
          ],
          [
            -116.78606,
            47.45333,
            0
          ],
          [
            -116.78605,
            47.45332,
            0
          ],
          [
            -116.78602,
            47.45318,
            0
          ],
          [
            -116.78597,
            47.45302,
            0
          ],
          [
            -116.78594,
            47.45298,
            0
          ],
          [
            -116.78572,
            47.45261,
            0
          ],
          [
            -116.78544,
            47.45231,
            0
          ],
          [
            -116.78542,
            47.45228,
            0
          ],
          [
            -116.78506,
            47.452,
            0
          ],
          [
            -116.78421,
            47.45147,
            0
          ],
          [
            -116.78344,
            47.45108,
            0
          ],
          [
            -116.78282,
            47.45084,
            0
          ],
          [
            -116.78217,
            47.45062,
            0
          ],
          [
            -116.78076,
            47.45018,
            0
          ],
          [
            -116.78072,
            47.45016,
            0
          ],
          [
            -116.78049,
            47.45008,
            0
          ],
          [
            -116.77984,
            47.44982,
            0
          ],
          [
            -116.77873,
            47.44932,
            0
          ],
          [
            -116.77778,
            47.44899,
            0
          ],
          [
            -116.77746,
            47.44889,
            0
          ],
          [
            -116.77687,
            47.44867,
            0
          ],
          [
            -116.776,
            47.44826,
            0
          ],
          [
            -116.77547,
            47.44809,
            0
          ],
          [
            -116.77519,
            47.44803,
            0
          ],
          [
            -116.77445,
            47.44783,
            0
          ],
          [
            -116.77437,
            47.44782,
            0
          ],
          [
            -116.77352,
            47.4476,
            0
          ],
          [
            -116.77271,
            47.44741,
            0
          ],
          [
            -116.77259,
            47.44739,
            0
          ],
          [
            -116.77251,
            47.44737,
            0
          ],
          [
            -116.77244,
            47.44737,
            0
          ],
          [
            -116.7723,
            47.44736,
            0
          ],
          [
            -116.77201,
            47.44736,
            0
          ],
          [
            -116.77163,
            47.4474,
            0
          ],
          [
            -116.77036,
            47.44773,
            0
          ],
          [
            -116.7699,
            47.44783,
            0
          ],
          [
            -116.76975,
            47.44785,
            0
          ],
          [
            -116.76952,
            47.44785,
            0
          ],
          [
            -116.76934,
            47.44783,
            0
          ],
          [
            -116.76912,
            47.44776,
            0
          ],
          [
            -116.7689,
            47.44764,
            0
          ],
          [
            -116.76883,
            47.44758,
            0
          ],
          [
            -116.76877,
            47.4475,
            0
          ],
          [
            -116.76869,
            47.44735,
            0
          ],
          [
            -116.76866,
            47.44715,
            0
          ],
          [
            -116.76865,
            47.44704,
            0
          ],
          [
            -116.76862,
            47.44687,
            0
          ],
          [
            -116.76854,
            47.44662,
            0
          ],
          [
            -116.76843,
            47.44646,
            0
          ],
          [
            -116.76825,
            47.44632,
            0
          ],
          [
            -116.76814,
            47.44626,
            0
          ],
          [
            -116.76803,
            47.44621,
            0
          ],
          [
            -116.76772,
            47.44614,
            0
          ],
          [
            -116.76765,
            47.44614,
            0
          ],
          [
            -116.7675,
            47.44612,
            0
          ],
          [
            -116.76709,
            47.4461,
            0
          ],
          [
            -116.76627,
            47.44599,
            0
          ],
          [
            -116.76335,
            47.44554,
            0
          ],
          [
            -116.76323,
            47.4455,
            0
          ],
          [
            -116.76321,
            47.4455,
            0
          ],
          [
            -116.76314,
            47.44547,
            0
          ],
          [
            -116.76301,
            47.44543,
            0
          ],
          [
            -116.76293,
            47.44539,
            0
          ],
          [
            -116.76276,
            47.44532,
            0
          ],
          [
            -116.76197,
            47.44492,
            0
          ],
          [
            -116.76149,
            47.44465,
            0
          ],
          [
            -116.76133,
            47.44454,
            0
          ],
          [
            -116.76019,
            47.44364,
            0
          ],
          [
            -116.75994,
            47.44348,
            0
          ],
          [
            -116.75983,
            47.44342,
            0
          ],
          [
            -116.75976,
            47.44339,
            0
          ],
          [
            -116.75964,
            47.44332,
            0
          ],
          [
            -116.75939,
            47.4432,
            0
          ],
          [
            -116.75889,
            47.44299,
            0
          ],
          [
            -116.75872,
            47.44291,
            0
          ],
          [
            -116.75816,
            47.44271,
            0
          ],
          [
            -116.75779,
            47.44261,
            0
          ],
          [
            -116.7576,
            47.44257,
            0
          ],
          [
            -116.75704,
            47.44248,
            0
          ],
          [
            -116.75676,
            47.44245,
            0
          ],
          [
            -116.75601,
            47.44242,
            0
          ],
          [
            -116.75244,
            47.44245,
            0
          ],
          [
            -116.75228,
            47.44244,
            0
          ],
          [
            -116.75176,
            47.44246,
            0
          ],
          [
            -116.75082,
            47.44256,
            0
          ],
          [
            -116.75017,
            47.4426,
            0
          ],
          [
            -116.74974,
            47.4426,
            0
          ],
          [
            -116.74925,
            47.44256,
            0
          ],
          [
            -116.74882,
            47.44247,
            0
          ],
          [
            -116.7483,
            47.44231,
            0
          ],
          [
            -116.74799,
            47.44217,
            0
          ],
          [
            -116.7479,
            47.44212,
            0
          ],
          [
            -116.74759,
            47.44192,
            0
          ],
          [
            -116.74748,
            47.44184,
            0
          ],
          [
            -116.7473,
            47.44167,
            0
          ],
          [
            -116.747,
            47.44134,
            0
          ],
          [
            -116.74686,
            47.44117,
            0
          ],
          [
            -116.74639,
            47.44074,
            0
          ],
          [
            -116.74638,
            47.44074,
            0
          ],
          [
            -116.74627,
            47.44065,
            0
          ],
          [
            -116.74599,
            47.44048,
            0
          ],
          [
            -116.74585,
            47.44042,
            0
          ],
          [
            -116.74575,
            47.44037,
            0
          ],
          [
            -116.74483,
            47.44001,
            0
          ],
          [
            -116.74452,
            47.4399,
            0
          ],
          [
            -116.7437,
            47.43957,
            0
          ],
          [
            -116.74346,
            47.43949,
            0
          ],
          [
            -116.74324,
            47.4394,
            0
          ],
          [
            -116.74323,
            47.4394,
            0
          ],
          [
            -116.73885,
            47.43773,
            0
          ],
          [
            -116.73883,
            47.43772,
            0
          ],
          [
            -116.7386,
            47.43764,
            0
          ],
          [
            -116.73859,
            47.43764,
            0
          ],
          [
            -116.73822,
            47.43752,
            0
          ],
          [
            -116.73797,
            47.43745,
            0
          ],
          [
            -116.73738,
            47.43734,
            0
          ],
          [
            -116.73657,
            47.43726,
            0
          ],
          [
            -116.73656,
            47.43725,
            0
          ],
          [
            -116.73577,
            47.43718,
            0
          ],
          [
            -116.73574,
            47.43718,
            0
          ],
          [
            -116.73445,
            47.43706,
            0
          ],
          [
            -116.73441,
            47.43705,
            0
          ],
          [
            -116.73419,
            47.43703,
            0
          ],
          [
            -116.73415,
            47.43702,
            0
          ],
          [
            -116.73393,
            47.43699,
            0
          ],
          [
            -116.73391,
            47.43698,
            0
          ],
          [
            -116.73352,
            47.4369,
            0
          ],
          [
            -116.73327,
            47.43683,
            0
          ],
          [
            -116.73279,
            47.43667,
            0
          ],
          [
            -116.73251,
            47.43656,
            0
          ],
          [
            -116.73222,
            47.43643,
            0
          ],
          [
            -116.73204,
            47.43636,
            0
          ],
          [
            -116.73084,
            47.43583,
            0
          ],
          [
            -116.73074,
            47.43577,
            0
          ],
          [
            -116.73056,
            47.43568,
            0
          ],
          [
            -116.73037,
            47.43557,
            0
          ],
          [
            -116.73024,
            47.43548,
            0
          ],
          [
            -116.73017,
            47.43544,
            0
          ],
          [
            -116.73002,
            47.43533,
            0
          ],
          [
            -116.72976,
            47.43512,
            0
          ],
          [
            -116.72955,
            47.43491,
            0
          ],
          [
            -116.7292,
            47.43449,
            0
          ],
          [
            -116.72897,
            47.43418,
            0
          ],
          [
            -116.72852,
            47.43353,
            0
          ],
          [
            -116.72824,
            47.43315,
            0
          ],
          [
            -116.72793,
            47.43283,
            0
          ],
          [
            -116.72776,
            47.43269,
            0
          ],
          [
            -116.72754,
            47.43253,
            0
          ],
          [
            -116.72532,
            47.43116,
            0
          ],
          [
            -116.72526,
            47.43113,
            0
          ],
          [
            -116.72304,
            47.42977,
            0
          ],
          [
            -116.72269,
            47.4296,
            0
          ],
          [
            -116.72224,
            47.42943,
            0
          ],
          [
            -116.72202,
            47.42936,
            0
          ],
          [
            -116.72167,
            47.42927,
            0
          ],
          [
            -116.72126,
            47.42919,
            0
          ],
          [
            -116.72038,
            47.42907,
            0
          ],
          [
            -116.71793,
            47.42879,
            0
          ],
          [
            -116.71718,
            47.42868,
            0
          ],
          [
            -116.71666,
            47.42857,
            0
          ],
          [
            -116.71634,
            47.42848,
            0
          ],
          [
            -116.71628,
            47.42847,
            0
          ],
          [
            -116.71538,
            47.42817,
            0
          ],
          [
            -116.71502,
            47.42803,
            0
          ],
          [
            -116.71448,
            47.42776,
            0
          ],
          [
            -116.71361,
            47.42729,
            0
          ],
          [
            -116.71301,
            47.42694,
            0
          ],
          [
            -116.71289,
            47.42686,
            0
          ],
          [
            -116.71281,
            47.42682,
            0
          ],
          [
            -116.71221,
            47.42648,
            0
          ],
          [
            -116.7122,
            47.42647,
            0
          ],
          [
            -116.71199,
            47.42636,
            0
          ],
          [
            -116.71154,
            47.42609,
            0
          ],
          [
            -116.71117,
            47.4259,
            0
          ],
          [
            -116.711,
            47.42583,
            0
          ],
          [
            -116.71095,
            47.4258,
            0
          ],
          [
            -116.70968,
            47.42523,
            0
          ],
          [
            -116.70937,
            47.42508,
            0
          ],
          [
            -116.7088,
            47.42473,
            0
          ],
          [
            -116.70842,
            47.42443,
            0
          ],
          [
            -116.70787,
            47.42387,
            0
          ],
          [
            -116.70703,
            47.42295,
            0
          ],
          [
            -116.70702,
            47.42295,
            0
          ],
          [
            -116.7059,
            47.42174,
            0
          ],
          [
            -116.7059,
            47.42173,
            0
          ],
          [
            -116.70503,
            47.42081,
            0
          ],
          [
            -116.70471,
            47.42052,
            0
          ],
          [
            -116.70455,
            47.42039,
            0
          ],
          [
            -116.70432,
            47.42023,
            0
          ],
          [
            -116.70398,
            47.42002,
            0
          ],
          [
            -116.70397,
            47.42001,
            0
          ],
          [
            -116.70131,
            47.41858,
            0
          ],
          [
            -116.70092,
            47.41834,
            0
          ],
          [
            -116.70024,
            47.41782,
            0
          ],
          [
            -116.69976,
            47.4174,
            0
          ],
          [
            -116.69945,
            47.4171,
            0
          ],
          [
            -116.69866,
            47.4164,
            0
          ],
          [
            -116.69832,
            47.41608,
            0
          ],
          [
            -116.698,
            47.41582,
            0
          ],
          [
            -116.69779,
            47.41568,
            0
          ],
          [
            -116.69751,
            47.41551,
            0
          ],
          [
            -116.69733,
            47.41542,
            0
          ],
          [
            -116.69699,
            47.41528,
            0
          ],
          [
            -116.69666,
            47.41517,
            0
          ],
          [
            -116.69497,
            47.4148,
            0
          ],
          [
            -116.69496,
            47.41479,
            0
          ],
          [
            -116.69445,
            47.41468,
            0
          ],
          [
            -116.69397,
            47.41455,
            0
          ],
          [
            -116.69394,
            47.41455,
            0
          ],
          [
            -116.6932,
            47.41425,
            0
          ],
          [
            -116.69282,
            47.41402,
            0
          ],
          [
            -116.6926,
            47.41385,
            0
          ],
          [
            -116.69237,
            47.41365,
            0
          ],
          [
            -116.69229,
            47.41357,
            0
          ],
          [
            -116.69196,
            47.41315,
            0
          ],
          [
            -116.69151,
            47.4125,
            0
          ],
          [
            -116.6911,
            47.41199,
            0
          ],
          [
            -116.6909,
            47.41186,
            0
          ],
          [
            -116.69053,
            47.41159,
            0
          ],
          [
            -116.69041,
            47.41152,
            0
          ],
          [
            -116.69011,
            47.41138,
            0
          ],
          [
            -116.68932,
            47.41104,
            0
          ],
          [
            -116.68922,
            47.41099,
            0
          ],
          [
            -116.68917,
            47.41096,
            0
          ],
          [
            -116.68884,
            47.41079,
            0
          ],
          [
            -116.68863,
            47.41066,
            0
          ],
          [
            -116.6884,
            47.41048,
            0
          ],
          [
            -116.68811,
            47.41022,
            0
          ],
          [
            -116.68781,
            47.40997,
            0
          ],
          [
            -116.68778,
            47.40994,
            0
          ],
          [
            -116.68728,
            47.40952,
            0
          ],
          [
            -116.68725,
            47.40949,
            0
          ],
          [
            -116.6871,
            47.40939,
            0
          ],
          [
            -116.6871,
            47.40938,
            0
          ],
          [
            -116.68676,
            47.40915,
            0
          ],
          [
            -116.68602,
            47.40869,
            0
          ],
          [
            -116.68594,
            47.40865,
            0
          ],
          [
            -116.68272,
            47.40673,
            0
          ],
          [
            -116.68229,
            47.40649,
            0
          ],
          [
            -116.682,
            47.40635,
            0
          ],
          [
            -116.68161,
            47.40619,
            0
          ],
          [
            -116.6816,
            47.40619,
            0
          ],
          [
            -116.67465,
            47.4039,
            0
          ],
          [
            -116.67434,
            47.40378,
            0
          ],
          [
            -116.67412,
            47.40367,
            0
          ],
          [
            -116.67402,
            47.40361,
            0
          ],
          [
            -116.67399,
            47.4036,
            0
          ],
          [
            -116.67363,
            47.40336,
            0
          ],
          [
            -116.67351,
            47.40327,
            0
          ],
          [
            -116.67346,
            47.40322,
            0
          ],
          [
            -116.6733,
            47.40308,
            0
          ],
          [
            -116.67304,
            47.40279,
            0
          ],
          [
            -116.67303,
            47.40277,
            0
          ],
          [
            -116.67268,
            47.40233,
            0
          ],
          [
            -116.67248,
            47.4021,
            0
          ],
          [
            -116.6723,
            47.40193,
            0
          ],
          [
            -116.67198,
            47.40169,
            0
          ],
          [
            -116.67155,
            47.40145,
            0
          ],
          [
            -116.67127,
            47.40133,
            0
          ],
          [
            -116.67075,
            47.40116,
            0
          ],
          [
            -116.67035,
            47.40108,
            0
          ],
          [
            -116.67034,
            47.40107,
            0
          ],
          [
            -116.6702,
            47.40105,
            0
          ],
          [
            -116.66962,
            47.401,
            0
          ],
          [
            -116.66688,
            47.40106,
            0
          ],
          [
            -116.6666,
            47.40105,
            0
          ],
          [
            -116.6664,
            47.40103,
            0
          ],
          [
            -116.66633,
            47.40103,
            0
          ],
          [
            -116.6658,
            47.40095,
            0
          ],
          [
            -116.66542,
            47.40086,
            0
          ],
          [
            -116.66484,
            47.40067,
            0
          ],
          [
            -116.66304,
            47.39998,
            0
          ],
          [
            -116.6626,
            47.39977,
            0
          ],
          [
            -116.66233,
            47.3996,
            0
          ],
          [
            -116.66199,
            47.39936,
            0
          ],
          [
            -116.66042,
            47.39819,
            0
          ],
          [
            -116.66009,
            47.39793,
            0
          ],
          [
            -116.65989,
            47.39776,
            0
          ],
          [
            -116.65965,
            47.39749,
            0
          ],
          [
            -116.65952,
            47.39733,
            0
          ],
          [
            -116.65936,
            47.39705,
            0
          ],
          [
            -116.65923,
            47.39678,
            0
          ],
          [
            -116.65914,
            47.39651,
            0
          ],
          [
            -116.65902,
            47.39595,
            0
          ],
          [
            -116.659,
            47.3959,
            0
          ],
          [
            -116.65893,
            47.39568,
            0
          ],
          [
            -116.65884,
            47.39547,
            0
          ],
          [
            -116.65875,
            47.3953,
            0
          ],
          [
            -116.65872,
            47.39523,
            0
          ],
          [
            -116.65858,
            47.39496,
            0
          ],
          [
            -116.65842,
            47.3947,
            0
          ],
          [
            -116.65839,
            47.39466,
            0
          ],
          [
            -116.65832,
            47.39454,
            0
          ],
          [
            -116.6583,
            47.39452,
            0
          ],
          [
            -116.65813,
            47.39429,
            0
          ],
          [
            -116.65785,
            47.39401,
            0
          ],
          [
            -116.65775,
            47.39392,
            0
          ],
          [
            -116.65738,
            47.39367,
            0
          ],
          [
            -116.65729,
            47.39362,
            0
          ],
          [
            -116.65713,
            47.39351,
            0
          ],
          [
            -116.65692,
            47.39334,
            0
          ],
          [
            -116.65673,
            47.39314,
            0
          ],
          [
            -116.65666,
            47.39305,
            0
          ],
          [
            -116.65657,
            47.39286,
            0
          ],
          [
            -116.65654,
            47.39272,
            0
          ],
          [
            -116.65653,
            47.3927,
            0
          ],
          [
            -116.65651,
            47.39256,
            0
          ],
          [
            -116.65645,
            47.39146,
            0
          ],
          [
            -116.65647,
            47.39126,
            0
          ],
          [
            -116.65653,
            47.39088,
            0
          ],
          [
            -116.65658,
            47.39068,
            0
          ],
          [
            -116.65672,
            47.39021,
            0
          ],
          [
            -116.65684,
            47.38985,
            0
          ],
          [
            -116.65706,
            47.38906,
            0
          ],
          [
            -116.65717,
            47.38873,
            0
          ],
          [
            -116.65736,
            47.38824,
            0
          ],
          [
            -116.65746,
            47.38808,
            0
          ],
          [
            -116.65754,
            47.38798,
            0
          ],
          [
            -116.65775,
            47.38779,
            0
          ],
          [
            -116.65787,
            47.3877,
            0
          ],
          [
            -116.65802,
            47.3876,
            0
          ],
          [
            -116.65818,
            47.38751,
            0
          ],
          [
            -116.65841,
            47.38741,
            0
          ],
          [
            -116.65855,
            47.38736,
            0
          ],
          [
            -116.65878,
            47.3873,
            0
          ],
          [
            -116.65893,
            47.38727,
            0
          ],
          [
            -116.65901,
            47.38726,
            0
          ],
          [
            -116.65908,
            47.38724,
            0
          ],
          [
            -116.65932,
            47.38721,
            0
          ],
          [
            -116.65965,
            47.38719,
            0
          ],
          [
            -116.66069,
            47.38717,
            0
          ],
          [
            -116.66122,
            47.38713,
            0
          ],
          [
            -116.66133,
            47.38711,
            0
          ],
          [
            -116.66145,
            47.38708,
            0
          ],
          [
            -116.66163,
            47.38702,
            0
          ],
          [
            -116.66175,
            47.38695,
            0
          ],
          [
            -116.66182,
            47.38692,
            0
          ],
          [
            -116.662,
            47.38678,
            0
          ],
          [
            -116.66208,
            47.3867,
            0
          ],
          [
            -116.66228,
            47.38654,
            0
          ],
          [
            -116.66241,
            47.38645,
            0
          ],
          [
            -116.66248,
            47.38641,
            0
          ],
          [
            -116.66257,
            47.38637,
            0
          ],
          [
            -116.66278,
            47.3863,
            0
          ],
          [
            -116.6629,
            47.38629,
            0
          ],
          [
            -116.66297,
            47.38627,
            0
          ],
          [
            -116.66325,
            47.38627,
            0
          ],
          [
            -116.66369,
            47.38635,
            0
          ],
          [
            -116.66446,
            47.3866,
            0
          ],
          [
            -116.6647,
            47.38666,
            0
          ],
          [
            -116.66499,
            47.38668,
            0
          ],
          [
            -116.66527,
            47.38666,
            0
          ],
          [
            -116.6655,
            47.38661,
            0
          ],
          [
            -116.6656,
            47.38657,
            0
          ],
          [
            -116.66574,
            47.3865,
            0
          ],
          [
            -116.66585,
            47.38643,
            0
          ],
          [
            -116.66596,
            47.38634,
            0
          ],
          [
            -116.66606,
            47.38622,
            0
          ],
          [
            -116.66638,
            47.38576,
            0
          ],
          [
            -116.66651,
            47.38562,
            0
          ],
          [
            -116.66662,
            47.38553,
            0
          ],
          [
            -116.66723,
            47.38509,
            0
          ],
          [
            -116.6673,
            47.38503,
            0
          ],
          [
            -116.66737,
            47.38495,
            0
          ],
          [
            -116.66742,
            47.38488,
            0
          ],
          [
            -116.66746,
            47.38481,
            0
          ],
          [
            -116.66751,
            47.38468,
            0
          ],
          [
            -116.66755,
            47.38444,
            0
          ],
          [
            -116.66763,
            47.3841,
            0
          ],
          [
            -116.66765,
            47.38396,
            0
          ],
          [
            -116.66765,
            47.38373,
            0
          ],
          [
            -116.66764,
            47.38369,
            0
          ],
          [
            -116.66764,
            47.38365,
            0
          ],
          [
            -116.66759,
            47.38347,
            0
          ],
          [
            -116.66755,
            47.38338,
            0
          ],
          [
            -116.6673,
            47.38304,
            0
          ],
          [
            -116.667,
            47.38275,
            0
          ],
          [
            -116.66667,
            47.38238,
            0
          ],
          [
            -116.66632,
            47.38202,
            0
          ],
          [
            -116.66607,
            47.38178,
            0
          ],
          [
            -116.66595,
            47.38168,
            0
          ],
          [
            -116.66585,
            47.38162,
            0
          ],
          [
            -116.6656,
            47.3815,
            0
          ],
          [
            -116.66519,
            47.38128,
            0
          ],
          [
            -116.66512,
            47.38122,
            0
          ],
          [
            -116.66509,
            47.3812,
            0
          ],
          [
            -116.66486,
            47.38097,
            0
          ],
          [
            -116.66478,
            47.3809,
            0
          ],
          [
            -116.66457,
            47.38068,
            0
          ],
          [
            -116.66434,
            47.3805,
            0
          ],
          [
            -116.66433,
            47.3805,
            0
          ],
          [
            -116.66408,
            47.38034,
            0
          ],
          [
            -116.66382,
            47.38019,
            0
          ],
          [
            -116.6635,
            47.37996,
            0
          ],
          [
            -116.66341,
            47.37988,
            0
          ],
          [
            -116.66318,
            47.37964,
            0
          ],
          [
            -116.66308,
            47.3795,
            0
          ],
          [
            -116.66297,
            47.37929,
            0
          ],
          [
            -116.66294,
            47.37922,
            0
          ],
          [
            -116.66292,
            47.37915,
            0
          ],
          [
            -116.66288,
            47.37905,
            0
          ],
          [
            -116.66285,
            47.37892,
            0
          ],
          [
            -116.66284,
            47.37884,
            0
          ],
          [
            -116.66284,
            47.37874,
            0
          ],
          [
            -116.66283,
            47.37863,
            0
          ],
          [
            -116.66283,
            47.37853,
            0
          ],
          [
            -116.66284,
            47.37844,
            0
          ],
          [
            -116.6629,
            47.37816,
            0
          ],
          [
            -116.66295,
            47.37797,
            0
          ],
          [
            -116.66297,
            47.37792,
            0
          ],
          [
            -116.66299,
            47.37782,
            0
          ],
          [
            -116.66304,
            47.37768,
            0
          ],
          [
            -116.66308,
            47.37753,
            0
          ],
          [
            -116.66324,
            47.37719,
            0
          ],
          [
            -116.66351,
            47.37678,
            0
          ],
          [
            -116.66365,
            47.3766,
            0
          ],
          [
            -116.66375,
            47.37644,
            0
          ],
          [
            -116.66387,
            47.3763,
            0
          ],
          [
            -116.66388,
            47.37628,
            0
          ],
          [
            -116.66461,
            47.37538,
            0
          ],
          [
            -116.66463,
            47.37535,
            0
          ],
          [
            -116.66492,
            47.37503,
            0
          ],
          [
            -116.66504,
            47.37491,
            0
          ],
          [
            -116.66542,
            47.37456,
            0
          ],
          [
            -116.66581,
            47.37424,
            0
          ],
          [
            -116.66612,
            47.37395,
            0
          ],
          [
            -116.66626,
            47.37384,
            0
          ],
          [
            -116.66647,
            47.37369,
            0
          ],
          [
            -116.66657,
            47.37363,
            0
          ],
          [
            -116.66687,
            47.37348,
            0
          ],
          [
            -116.66719,
            47.37335,
            0
          ],
          [
            -116.66798,
            47.37308,
            0
          ],
          [
            -116.66829,
            47.37296,
            0
          ],
          [
            -116.66857,
            47.37287,
            0
          ],
          [
            -116.66975,
            47.37245,
            0
          ],
          [
            -116.67068,
            47.37214,
            0
          ],
          [
            -116.67117,
            47.37195,
            0
          ],
          [
            -116.6715,
            47.37179,
            0
          ],
          [
            -116.6716,
            47.37173,
            0
          ],
          [
            -116.67187,
            47.37154,
            0
          ],
          [
            -116.67207,
            47.37134,
            0
          ],
          [
            -116.67218,
            47.37124,
            0
          ],
          [
            -116.67227,
            47.37112,
            0
          ],
          [
            -116.673,
            47.37023,
            0
          ],
          [
            -116.67305,
            47.37018,
            0
          ],
          [
            -116.67311,
            47.37011,
            0
          ],
          [
            -116.67321,
            47.37004,
            0
          ],
          [
            -116.67343,
            47.36992,
            0
          ],
          [
            -116.67357,
            47.36988,
            0
          ],
          [
            -116.67375,
            47.36984,
            0
          ],
          [
            -116.67386,
            47.36983,
            0
          ],
          [
            -116.67418,
            47.36983,
            0
          ],
          [
            -116.67442,
            47.36985,
            0
          ],
          [
            -116.67503,
            47.36995,
            0
          ],
          [
            -116.6755,
            47.36999,
            0
          ],
          [
            -116.67574,
            47.36999,
            0
          ],
          [
            -116.67593,
            47.36998,
            0
          ],
          [
            -116.67611,
            47.36996,
            0
          ],
          [
            -116.67738,
            47.36975,
            0
          ],
          [
            -116.6777,
            47.36968,
            0
          ],
          [
            -116.67815,
            47.36956,
            0
          ],
          [
            -116.67842,
            47.36946,
            0
          ],
          [
            -116.67875,
            47.3693,
            0
          ],
          [
            -116.679,
            47.36916,
            0
          ],
          [
            -116.67917,
            47.36905,
            0
          ],
          [
            -116.67919,
            47.36903,
            0
          ],
          [
            -116.67934,
            47.36893,
            0
          ],
          [
            -116.67961,
            47.36868,
            0
          ],
          [
            -116.67976,
            47.36852,
            0
          ],
          [
            -116.67982,
            47.36844,
            0
          ],
          [
            -116.68006,
            47.36804,
            0
          ],
          [
            -116.68012,
            47.3679,
            0
          ],
          [
            -116.68021,
            47.36758,
            0
          ],
          [
            -116.68024,
            47.36744,
            0
          ],
          [
            -116.68026,
            47.36711,
            0
          ],
          [
            -116.68025,
            47.36704,
            0
          ],
          [
            -116.68024,
            47.36688,
            0
          ],
          [
            -116.68005,
            47.36593,
            0
          ],
          [
            -116.68004,
            47.36579,
            0
          ],
          [
            -116.68004,
            47.36572,
            0
          ],
          [
            -116.68006,
            47.3656,
            0
          ],
          [
            -116.68009,
            47.3655,
            0
          ],
          [
            -116.68014,
            47.36539,
            0
          ],
          [
            -116.68023,
            47.36524,
            0
          ],
          [
            -116.68028,
            47.36517,
            0
          ],
          [
            -116.68071,
            47.36468,
            0
          ],
          [
            -116.68088,
            47.36443,
            0
          ],
          [
            -116.68093,
            47.36427,
            0
          ],
          [
            -116.68095,
            47.36413,
            0
          ],
          [
            -116.68095,
            47.36403,
            0
          ],
          [
            -116.68094,
            47.36392,
            0
          ],
          [
            -116.6809,
            47.36379,
            0
          ],
          [
            -116.68083,
            47.36364,
            0
          ],
          [
            -116.68058,
            47.36322,
            0
          ],
          [
            -116.68054,
            47.36314,
            0
          ],
          [
            -116.67967,
            47.36167,
            0
          ],
          [
            -116.67961,
            47.36154,
            0
          ],
          [
            -116.6796,
            47.36153,
            0
          ],
          [
            -116.67953,
            47.36138,
            0
          ],
          [
            -116.67917,
            47.3605,
            0
          ],
          [
            -116.67902,
            47.36019,
            0
          ],
          [
            -116.67864,
            47.35949,
            0
          ],
          [
            -116.67862,
            47.35946,
            0
          ],
          [
            -116.67793,
            47.35819,
            0
          ],
          [
            -116.67754,
            47.35753,
            0
          ],
          [
            -116.67713,
            47.35694,
            0
          ],
          [
            -116.67649,
            47.35613,
            0
          ],
          [
            -116.67623,
            47.35583,
            0
          ],
          [
            -116.67424,
            47.35335,
            0
          ],
          [
            -116.67415,
            47.35325,
            0
          ],
          [
            -116.67319,
            47.35206,
            0
          ],
          [
            -116.67278,
            47.35163,
            0
          ],
          [
            -116.6724,
            47.35128,
            0
          ],
          [
            -116.67175,
            47.35074,
            0
          ],
          [
            -116.67052,
            47.34978,
            0
          ],
          [
            -116.67021,
            47.34952,
            0
          ],
          [
            -116.66947,
            47.34894,
            0
          ],
          [
            -116.66922,
            47.34877,
            0
          ],
          [
            -116.66905,
            47.34867,
            0
          ],
          [
            -116.66866,
            47.34849,
            0
          ],
          [
            -116.6681,
            47.34833,
            0
          ],
          [
            -116.66787,
            47.34828,
            0
          ],
          [
            -116.66772,
            47.34826,
            0
          ],
          [
            -116.66755,
            47.34822,
            0
          ],
          [
            -116.66728,
            47.34818,
            0
          ],
          [
            -116.66639,
            47.34811,
            0
          ],
          [
            -116.65798,
            47.34772,
            0
          ],
          [
            -116.65655,
            47.3477,
            0
          ],
          [
            -116.6536,
            47.34776,
            0
          ],
          [
            -116.64577,
            47.34775,
            0
          ],
          [
            -116.64548,
            47.34776,
            0
          ],
          [
            -116.64494,
            47.34781,
            0
          ],
          [
            -116.64489,
            47.34781,
            0
          ],
          [
            -116.64429,
            47.34791,
            0
          ],
          [
            -116.64347,
            47.34808,
            0
          ],
          [
            -116.64265,
            47.34823,
            0
          ],
          [
            -116.64211,
            47.34829,
            0
          ],
          [
            -116.64174,
            47.34831,
            0
          ],
          [
            -116.64005,
            47.34828,
            0
          ],
          [
            -116.6398,
            47.34826,
            0
          ],
          [
            -116.6397,
            47.34826,
            0
          ],
          [
            -116.63945,
            47.34824,
            0
          ],
          [
            -116.63923,
            47.34821,
            0
          ],
          [
            -116.63867,
            47.34809,
            0
          ],
          [
            -116.63835,
            47.348,
            0
          ],
          [
            -116.63634,
            47.34733,
            0
          ],
          [
            -116.63602,
            47.34726,
            0
          ],
          [
            -116.63589,
            47.34724,
            0
          ],
          [
            -116.63569,
            47.34723,
            0
          ],
          [
            -116.63545,
            47.34723,
            0
          ],
          [
            -116.63357,
            47.34743,
            0
          ],
          [
            -116.633,
            47.34746,
            0
          ],
          [
            -116.63172,
            47.34745,
            0
          ],
          [
            -116.63005,
            47.34733,
            0
          ],
          [
            -116.62785,
            47.34702,
            0
          ],
          [
            -116.62765,
            47.34698,
            0
          ],
          [
            -116.62715,
            47.34683,
            0
          ],
          [
            -116.62683,
            47.34671,
            0
          ],
          [
            -116.62648,
            47.34662,
            0
          ],
          [
            -116.62619,
            47.34657,
            0
          ],
          [
            -116.62591,
            47.34656,
            0
          ],
          [
            -116.62576,
            47.34657,
            0
          ],
          [
            -116.62547,
            47.34662,
            0
          ],
          [
            -116.6248,
            47.34677,
            0
          ],
          [
            -116.62415,
            47.34687,
            0
          ],
          [
            -116.62363,
            47.34691,
            0
          ],
          [
            -116.62248,
            47.34689,
            0
          ],
          [
            -116.62208,
            47.34686,
            0
          ],
          [
            -116.62173,
            47.34682,
            0
          ],
          [
            -116.62151,
            47.34676,
            0
          ],
          [
            -116.62098,
            47.34659,
            0
          ],
          [
            -116.62065,
            47.34645,
            0
          ],
          [
            -116.62014,
            47.34614,
            0
          ],
          [
            -116.61989,
            47.34595,
            0
          ],
          [
            -116.61963,
            47.3457,
            0
          ],
          [
            -116.61944,
            47.34547,
            0
          ],
          [
            -116.61913,
            47.34503,
            0
          ],
          [
            -116.61878,
            47.34458,
            0
          ],
          [
            -116.61855,
            47.34437,
            0
          ],
          [
            -116.61832,
            47.34423,
            0
          ],
          [
            -116.61687,
            47.34349,
            0
          ],
          [
            -116.61665,
            47.34339,
            0
          ],
          [
            -116.61662,
            47.34337,
            0
          ],
          [
            -116.61458,
            47.34235,
            0
          ],
          [
            -116.61414,
            47.34217,
            0
          ],
          [
            -116.61356,
            47.34201,
            0
          ],
          [
            -116.61348,
            47.342,
            0
          ],
          [
            -116.61215,
            47.34171,
            0
          ],
          [
            -116.61204,
            47.34168,
            0
          ],
          [
            -116.61114,
            47.34149,
            0
          ],
          [
            -116.61092,
            47.34143,
            0
          ],
          [
            -116.61077,
            47.3414,
            0
          ],
          [
            -116.6106,
            47.34135,
            0
          ],
          [
            -116.61023,
            47.34119,
            0
          ],
          [
            -116.61006,
            47.34109,
            0
          ],
          [
            -116.60989,
            47.34097,
            0
          ],
          [
            -116.60938,
            47.34054,
            0
          ],
          [
            -116.60926,
            47.34046,
            0
          ],
          [
            -116.60896,
            47.34031,
            0
          ],
          [
            -116.60884,
            47.34026,
            0
          ],
          [
            -116.60858,
            47.34018,
            0
          ],
          [
            -116.60837,
            47.34013,
            0
          ],
          [
            -116.6081,
            47.34009,
            0
          ],
          [
            -116.60771,
            47.34007,
            0
          ],
          [
            -116.60753,
            47.34005,
            0
          ],
          [
            -116.6072,
            47.34003,
            0
          ],
          [
            -116.60682,
            47.33998,
            0
          ],
          [
            -116.60652,
            47.33991,
            0
          ],
          [
            -116.60627,
            47.33984,
            0
          ],
          [
            -116.60617,
            47.3398,
            0
          ],
          [
            -116.6061,
            47.33976,
            0
          ],
          [
            -116.60601,
            47.33973,
            0
          ],
          [
            -116.60588,
            47.33967,
            0
          ],
          [
            -116.60397,
            47.33863,
            0
          ],
          [
            -116.60374,
            47.33852,
            0
          ],
          [
            -116.60292,
            47.33821,
            0
          ],
          [
            -116.60274,
            47.33816,
            0
          ],
          [
            -116.60231,
            47.33802,
            0
          ],
          [
            -116.60226,
            47.33801,
            0
          ],
          [
            -116.60177,
            47.33786,
            0
          ],
          [
            -116.60089,
            47.33762,
            0
          ],
          [
            -116.60053,
            47.33754,
            0
          ],
          [
            -116.59962,
            47.33743,
            0
          ],
          [
            -116.59944,
            47.33738,
            0
          ],
          [
            -116.59933,
            47.33734,
            0
          ],
          [
            -116.59906,
            47.33718,
            0
          ],
          [
            -116.59891,
            47.33707,
            0
          ],
          [
            -116.5988,
            47.33698,
            0
          ],
          [
            -116.59865,
            47.33682,
            0
          ],
          [
            -116.59864,
            47.3368,
            0
          ],
          [
            -116.59854,
            47.33667,
            0
          ],
          [
            -116.59849,
            47.33657,
            0
          ],
          [
            -116.59845,
            47.33647,
            0
          ],
          [
            -116.59835,
            47.33614,
            0
          ],
          [
            -116.59831,
            47.33595,
            0
          ],
          [
            -116.59822,
            47.33566,
            0
          ],
          [
            -116.59811,
            47.33545,
            0
          ],
          [
            -116.59811,
            47.33544,
            0
          ],
          [
            -116.598,
            47.3353,
            0
          ],
          [
            -116.59781,
            47.33513,
            0
          ],
          [
            -116.59753,
            47.33492,
            0
          ],
          [
            -116.59716,
            47.33475,
            0
          ],
          [
            -116.59703,
            47.3347,
            0
          ],
          [
            -116.59698,
            47.33469,
            0
          ],
          [
            -116.59668,
            47.33459,
            0
          ],
          [
            -116.59639,
            47.33452,
            0
          ],
          [
            -116.59629,
            47.33449,
            0
          ],
          [
            -116.59619,
            47.33447,
            0
          ],
          [
            -116.5961,
            47.33444,
            0
          ],
          [
            -116.59567,
            47.33434,
            0
          ],
          [
            -116.59546,
            47.33428,
            0
          ],
          [
            -116.59535,
            47.33426,
            0
          ],
          [
            -116.59457,
            47.33402,
            0
          ],
          [
            -116.59453,
            47.334,
            0
          ],
          [
            -116.5943,
            47.33391,
            0
          ],
          [
            -116.59378,
            47.33367,
            0
          ],
          [
            -116.59357,
            47.33356,
            0
          ],
          [
            -116.58912,
            47.331,
            0
          ],
          [
            -116.58891,
            47.33089,
            0
          ],
          [
            -116.58812,
            47.33043,
            0
          ],
          [
            -116.5881,
            47.33043,
            0
          ],
          [
            -116.58752,
            47.33008,
            0
          ],
          [
            -116.58751,
            47.33007,
            0
          ],
          [
            -116.58723,
            47.32987,
            0
          ],
          [
            -116.58685,
            47.32956,
            0
          ],
          [
            -116.5868,
            47.32953,
            0
          ],
          [
            -116.58609,
            47.32889,
            0
          ],
          [
            -116.58554,
            47.32837,
            0
          ],
          [
            -116.58544,
            47.32826,
            0
          ],
          [
            -116.58518,
            47.32794,
            0
          ],
          [
            -116.58499,
            47.32768,
            0
          ],
          [
            -116.58457,
            47.32702,
            0
          ],
          [
            -116.58448,
            47.32685,
            0
          ],
          [
            -116.58434,
            47.32653,
            0
          ],
          [
            -116.58431,
            47.3263,
            0
          ],
          [
            -116.58429,
            47.32625,
            0
          ],
          [
            -116.58421,
            47.32551,
            0
          ],
          [
            -116.58414,
            47.32526,
            0
          ],
          [
            -116.58402,
            47.32494,
            0
          ],
          [
            -116.58388,
            47.32471,
            0
          ],
          [
            -116.58362,
            47.32437,
            0
          ],
          [
            -116.58327,
            47.32405,
            0
          ],
          [
            -116.58307,
            47.3239,
            0
          ],
          [
            -116.58284,
            47.32375,
            0
          ],
          [
            -116.58273,
            47.32369,
            0
          ],
          [
            -116.58238,
            47.32353,
            0
          ],
          [
            -116.58202,
            47.32338,
            0
          ],
          [
            -116.58187,
            47.32333,
            0
          ],
          [
            -116.58156,
            47.32325,
            0
          ],
          [
            -116.58145,
            47.32323,
            0
          ],
          [
            -116.58099,
            47.32317,
            0
          ],
          [
            -116.57801,
            47.32297,
            0
          ],
          [
            -116.57558,
            47.32277,
            0
          ],
          [
            -116.57097,
            47.32231,
            0
          ],
          [
            -116.57017,
            47.32221,
            0
          ],
          [
            -116.56745,
            47.32172,
            0
          ],
          [
            -116.56738,
            47.3217,
            0
          ],
          [
            -116.56713,
            47.32165,
            0
          ],
          [
            -116.56691,
            47.32159,
            0
          ],
          [
            -116.56642,
            47.32142,
            0
          ],
          [
            -116.56588,
            47.32118,
            0
          ],
          [
            -116.56525,
            47.32078,
            0
          ],
          [
            -116.56472,
            47.32042,
            0
          ],
          [
            -116.56444,
            47.32025,
            0
          ],
          [
            -116.56425,
            47.32012,
            0
          ],
          [
            -116.5638,
            47.31985,
            0
          ],
          [
            -116.56374,
            47.31983,
            0
          ],
          [
            -116.56353,
            47.31973,
            0
          ],
          [
            -116.5632,
            47.31962,
            0
          ],
          [
            -116.5628,
            47.31951,
            0
          ],
          [
            -116.56188,
            47.31932,
            0
          ],
          [
            -116.56153,
            47.31923,
            0
          ],
          [
            -116.5613,
            47.31914,
            0
          ],
          [
            -116.56123,
            47.3191,
            0
          ],
          [
            -116.56122,
            47.3191,
            0
          ],
          [
            -116.56118,
            47.31907,
            0
          ],
          [
            -116.56108,
            47.31901,
            0
          ],
          [
            -116.56101,
            47.31895,
            0
          ],
          [
            -116.56096,
            47.3189,
            0
          ],
          [
            -116.56085,
            47.31876,
            0
          ],
          [
            -116.56083,
            47.31872,
            0
          ],
          [
            -116.56081,
            47.31866,
            0
          ],
          [
            -116.56078,
            47.3186,
            0
          ],
          [
            -116.56077,
            47.31852,
            0
          ],
          [
            -116.56076,
            47.31848,
            0
          ],
          [
            -116.56076,
            47.31838,
            0
          ],
          [
            -116.56078,
            47.31825,
            0
          ],
          [
            -116.56083,
            47.31813,
            0
          ],
          [
            -116.5609,
            47.31801,
            0
          ],
          [
            -116.56103,
            47.31783,
            0
          ],
          [
            -116.56219,
            47.31639,
            0
          ],
          [
            -116.56226,
            47.31628,
            0
          ],
          [
            -116.5623,
            47.31618,
            0
          ],
          [
            -116.56234,
            47.3161,
            0
          ],
          [
            -116.56243,
            47.31585,
            0
          ],
          [
            -116.56246,
            47.31569,
            0
          ],
          [
            -116.56246,
            47.31488,
            0
          ],
          [
            -116.56247,
            47.3147,
            0
          ],
          [
            -116.56247,
            47.31435,
            0
          ],
          [
            -116.56247,
            47.3147,
            0
          ],
          [
            -116.56246,
            47.31488,
            0
          ],
          [
            -116.56246,
            47.31524,
            0
          ],
          [
            -116.55768,
            47.31524,
            0
          ],
          [
            -116.55745,
            47.31523,
            0
          ],
          [
            -116.55727,
            47.31521,
            0
          ],
          [
            -116.55709,
            47.3152,
            0
          ],
          [
            -116.55661,
            47.31515,
            0
          ],
          [
            -116.55636,
            47.3151,
            0
          ],
          [
            -116.55621,
            47.31508,
            0
          ],
          [
            -116.55619,
            47.31507,
            0
          ],
          [
            -116.55556,
            47.31493,
            0
          ],
          [
            -116.55523,
            47.31483,
            0
          ],
          [
            -116.55478,
            47.31467,
            0
          ],
          [
            -116.55417,
            47.3144,
            0
          ],
          [
            -116.55377,
            47.31417,
            0
          ],
          [
            -116.55348,
            47.31399,
            0
          ],
          [
            -116.55297,
            47.31358,
            0
          ],
          [
            -116.5518,
            47.31243,
            0
          ],
          [
            -116.55173,
            47.31237,
            0
          ],
          [
            -116.5517,
            47.31233,
            0
          ],
          [
            -116.55126,
            47.31199,
            0
          ],
          [
            -116.55099,
            47.31181,
            0
          ],
          [
            -116.55069,
            47.31164,
            0
          ],
          [
            -116.55009,
            47.31133,
            0
          ],
          [
            -116.54958,
            47.31111,
            0
          ],
          [
            -116.54381,
            47.30886,
            0
          ],
          [
            -116.54358,
            47.30878,
            0
          ],
          [
            -116.54222,
            47.30821,
            0
          ],
          [
            -116.54221,
            47.30821,
            0
          ],
          [
            -116.54122,
            47.30772,
            0
          ],
          [
            -116.54074,
            47.30743,
            0
          ],
          [
            -116.54053,
            47.30729,
            0
          ],
          [
            -116.53985,
            47.30678,
            0
          ],
          [
            -116.53924,
            47.30626,
            0
          ],
          [
            -116.53903,
            47.30605,
            0
          ],
          [
            -116.5386,
            47.30559,
            0
          ],
          [
            -116.53577,
            47.30225,
            0
          ],
          [
            -116.53572,
            47.30218,
            0
          ],
          [
            -116.53558,
            47.30203,
            0
          ],
          [
            -116.53518,
            47.30156,
            0
          ],
          [
            -116.53512,
            47.30148,
            0
          ],
          [
            -116.53505,
            47.30141,
            0
          ],
          [
            -116.53412,
            47.30029,
            0
          ],
          [
            -116.53379,
            47.29986,
            0
          ],
          [
            -116.53349,
            47.29955,
            0
          ],
          [
            -116.53311,
            47.29919,
            0
          ],
          [
            -116.53283,
            47.29897,
            0
          ],
          [
            -116.53258,
            47.29879,
            0
          ],
          [
            -116.5322,
            47.29858,
            0
          ],
          [
            -116.53179,
            47.29839,
            0
          ],
          [
            -116.53164,
            47.29833,
            0
          ],
          [
            -116.53163,
            47.29832,
            0
          ],
          [
            -116.53147,
            47.29826,
            0
          ],
          [
            -116.5314,
            47.29824,
            0
          ],
          [
            -116.53097,
            47.29808,
            0
          ],
          [
            -116.52941,
            47.29758,
            0
          ],
          [
            -116.52911,
            47.29747,
            0
          ],
          [
            -116.52869,
            47.29727,
            0
          ],
          [
            -116.52842,
            47.29712,
            0
          ],
          [
            -116.52782,
            47.29673,
            0
          ],
          [
            -116.52765,
            47.2966,
            0
          ],
          [
            -116.52735,
            47.29632,
            0
          ],
          [
            -116.52706,
            47.29599,
            0
          ],
          [
            -116.52691,
            47.29579,
            0
          ],
          [
            -116.52681,
            47.29564,
            0
          ],
          [
            -116.52654,
            47.29519,
            0
          ],
          [
            -116.52651,
            47.29513,
            0
          ],
          [
            -116.52629,
            47.29475,
            0
          ],
          [
            -116.52555,
            47.29337,
            0
          ],
          [
            -116.52276,
            47.28838,
            0
          ],
          [
            -116.52233,
            47.28752,
            0
          ],
          [
            -116.52146,
            47.28602,
            0
          ],
          [
            -116.52145,
            47.28601,
            0
          ],
          [
            -116.52128,
            47.28576,
            0
          ],
          [
            -116.52107,
            47.28541,
            0
          ],
          [
            -116.52063,
            47.28477,
            0
          ],
          [
            -116.52039,
            47.28445,
            0
          ],
          [
            -116.52008,
            47.28408,
            0
          ],
          [
            -116.51984,
            47.28384,
            0
          ],
          [
            -116.51968,
            47.28366,
            0
          ],
          [
            -116.51946,
            47.28345,
            0
          ],
          [
            -116.51897,
            47.28303,
            0
          ],
          [
            -116.51883,
            47.2829,
            0
          ],
          [
            -116.51875,
            47.28284,
            0
          ],
          [
            -116.51861,
            47.28272,
            0
          ],
          [
            -116.51779,
            47.28212,
            0
          ],
          [
            -116.51627,
            47.28113,
            0
          ],
          [
            -116.51622,
            47.28109,
            0
          ],
          [
            -116.51584,
            47.28085,
            0
          ],
          [
            -116.51583,
            47.28084,
            0
          ],
          [
            -116.51564,
            47.28072,
            0
          ],
          [
            -116.51548,
            47.2806,
            0
          ],
          [
            -116.51546,
            47.28058,
            0
          ],
          [
            -116.51529,
            47.28045,
            0
          ],
          [
            -116.51516,
            47.28034,
            0
          ],
          [
            -116.51513,
            47.2803,
            0
          ],
          [
            -116.51502,
            47.2802,
            0
          ],
          [
            -116.51499,
            47.28015,
            0
          ],
          [
            -116.51495,
            47.2801,
            0
          ],
          [
            -116.51481,
            47.27989,
            0
          ],
          [
            -116.51476,
            47.27978,
            0
          ],
          [
            -116.51468,
            47.27954,
            0
          ],
          [
            -116.51461,
            47.27926,
            0
          ],
          [
            -116.51453,
            47.27868,
            0
          ],
          [
            -116.51448,
            47.2785,
            0
          ],
          [
            -116.5144,
            47.2783,
            0
          ],
          [
            -116.51428,
            47.27807,
            0
          ],
          [
            -116.51418,
            47.27792,
            0
          ],
          [
            -116.51396,
            47.27765,
            0
          ],
          [
            -116.51392,
            47.27762,
            0
          ],
          [
            -116.51373,
            47.27744,
            0
          ],
          [
            -116.51345,
            47.27724,
            0
          ],
          [
            -116.51341,
            47.27722,
            0
          ],
          [
            -116.5132,
            47.27709,
            0
          ],
          [
            -116.51292,
            47.27695,
            0
          ],
          [
            -116.51264,
            47.27683,
            0
          ],
          [
            -116.51177,
            47.27652,
            0
          ],
          [
            -116.51123,
            47.27627,
            0
          ],
          [
            -116.51084,
            47.27602,
            0
          ],
          [
            -116.5108,
            47.27599,
            0
          ],
          [
            -116.51053,
            47.27575,
            0
          ],
          [
            -116.51032,
            47.27552,
            0
          ],
          [
            -116.5102,
            47.27535,
            0
          ],
          [
            -116.51015,
            47.27526,
            0
          ],
          [
            -116.50995,
            47.27475,
            0
          ],
          [
            -116.50995,
            47.27474,
            0
          ],
          [
            -116.50988,
            47.2746,
            0
          ],
          [
            -116.50986,
            47.27457,
            0
          ],
          [
            -116.5098,
            47.27446,
            0
          ],
          [
            -116.50968,
            47.27429,
            0
          ],
          [
            -116.50945,
            47.27408,
            0
          ],
          [
            -116.50891,
            47.27372,
            0
          ],
          [
            -116.50873,
            47.27358,
            0
          ],
          [
            -116.50864,
            47.2735,
            0
          ],
          [
            -116.50846,
            47.27331,
            0
          ],
          [
            -116.50831,
            47.27313,
            0
          ],
          [
            -116.50831,
            47.27312,
            0
          ],
          [
            -116.50811,
            47.27289,
            0
          ],
          [
            -116.50788,
            47.27267,
            0
          ],
          [
            -116.50787,
            47.27267,
            0
          ],
          [
            -116.50772,
            47.27255,
            0
          ],
          [
            -116.5077,
            47.27254,
            0
          ],
          [
            -116.50757,
            47.27245,
            0
          ],
          [
            -116.50738,
            47.27234,
            0
          ],
          [
            -116.50719,
            47.27225,
            0
          ],
          [
            -116.50708,
            47.27221,
            0
          ],
          [
            -116.50699,
            47.27217,
            0
          ],
          [
            -116.50657,
            47.27203,
            0
          ],
          [
            -116.50636,
            47.27197,
            0
          ],
          [
            -116.50608,
            47.27191,
            0
          ],
          [
            -116.50584,
            47.27187,
            0
          ],
          [
            -116.50561,
            47.27182,
            0
          ],
          [
            -116.5056,
            47.27182,
            0
          ],
          [
            -116.50538,
            47.27178,
            0
          ],
          [
            -116.5049,
            47.27166,
            0
          ],
          [
            -116.50486,
            47.27164,
            0
          ],
          [
            -116.50467,
            47.27157,
            0
          ],
          [
            -116.50454,
            47.27151,
            0
          ],
          [
            -116.50442,
            47.27144,
            0
          ],
          [
            -116.50441,
            47.27143,
            0
          ],
          [
            -116.50422,
            47.27132,
            0
          ],
          [
            -116.50399,
            47.27113,
            0
          ],
          [
            -116.50386,
            47.27098,
            0
          ],
          [
            -116.50373,
            47.27078,
            0
          ],
          [
            -116.50365,
            47.27058,
            0
          ],
          [
            -116.50361,
            47.27045,
            0
          ],
          [
            -116.50355,
            47.27017,
            0
          ],
          [
            -116.50349,
            47.27,
            0
          ],
          [
            -116.50336,
            47.26975,
            0
          ],
          [
            -116.5033,
            47.26966,
            0
          ],
          [
            -116.50314,
            47.26947,
            0
          ],
          [
            -116.50302,
            47.26936,
            0
          ],
          [
            -116.50287,
            47.26924,
            0
          ],
          [
            -116.50277,
            47.26917,
            0
          ],
          [
            -116.50242,
            47.26897,
            0
          ],
          [
            -116.50063,
            47.26823,
            0
          ],
          [
            -116.50036,
            47.2681,
            0
          ],
          [
            -116.50011,
            47.26795,
            0
          ],
          [
            -116.49997,
            47.26788,
            0
          ],
          [
            -116.49986,
            47.2678,
            0
          ],
          [
            -116.49959,
            47.26758,
            0
          ],
          [
            -116.49948,
            47.26747,
            0
          ],
          [
            -116.49921,
            47.26715,
            0
          ],
          [
            -116.49907,
            47.26693,
            0
          ],
          [
            -116.49901,
            47.26682,
            0
          ],
          [
            -116.49897,
            47.26673,
            0
          ],
          [
            -116.49864,
            47.26615,
            0
          ],
          [
            -116.49862,
            47.26613,
            0
          ],
          [
            -116.49841,
            47.26586,
            0
          ],
          [
            -116.4969,
            47.26418,
            0
          ],
          [
            -116.4966,
            47.26393,
            0
          ],
          [
            -116.4964,
            47.26381,
            0
          ],
          [
            -116.49552,
            47.26333,
            0
          ],
          [
            -116.49541,
            47.26326,
            0
          ],
          [
            -116.49515,
            47.26307,
            0
          ],
          [
            -116.49504,
            47.26298,
            0
          ],
          [
            -116.49478,
            47.2627,
            0
          ],
          [
            -116.49465,
            47.26252,
            0
          ],
          [
            -116.49458,
            47.26238,
            0
          ],
          [
            -116.49452,
            47.26224,
            0
          ],
          [
            -116.49446,
            47.26205,
            0
          ],
          [
            -116.49443,
            47.26193,
            0
          ],
          [
            -116.49442,
            47.26163,
            0
          ],
          [
            -116.49441,
            47.26157,
            0
          ],
          [
            -116.49441,
            47.26117,
            0
          ],
          [
            -116.49439,
            47.26103,
            0
          ],
          [
            -116.49439,
            47.26096,
            0
          ],
          [
            -116.49436,
            47.26077,
            0
          ],
          [
            -116.49434,
            47.26068,
            0
          ],
          [
            -116.49429,
            47.26051,
            0
          ],
          [
            -116.49429,
            47.2605,
            0
          ],
          [
            -116.49422,
            47.26033,
            0
          ],
          [
            -116.49422,
            47.26032,
            0
          ],
          [
            -116.49414,
            47.26016,
            0
          ],
          [
            -116.49406,
            47.26002,
            0
          ],
          [
            -116.49393,
            47.25982,
            0
          ],
          [
            -116.49372,
            47.25954,
            0
          ],
          [
            -116.49358,
            47.2594,
            0
          ],
          [
            -116.49353,
            47.25936,
            0
          ],
          [
            -116.49346,
            47.25928,
            0
          ],
          [
            -116.4933,
            47.25915,
            0
          ],
          [
            -116.4928,
            47.2588,
            0
          ],
          [
            -116.49258,
            47.25867,
            0
          ],
          [
            -116.49233,
            47.2585,
            0
          ],
          [
            -116.49222,
            47.25839,
            0
          ],
          [
            -116.49205,
            47.25816,
            0
          ],
          [
            -116.49201,
            47.25805,
            0
          ],
          [
            -116.49199,
            47.25794,
            0
          ],
          [
            -116.492,
            47.25776,
            0
          ],
          [
            -116.49205,
            47.25759,
            0
          ],
          [
            -116.49216,
            47.25742,
            0
          ],
          [
            -116.49217,
            47.2574,
            0
          ],
          [
            -116.49223,
            47.25734,
            0
          ],
          [
            -116.49236,
            47.25723,
            0
          ],
          [
            -116.4925,
            47.25713,
            0
          ],
          [
            -116.49251,
            47.25712,
            0
          ],
          [
            -116.49263,
            47.25705,
            0
          ],
          [
            -116.49272,
            47.25702,
            0
          ],
          [
            -116.49288,
            47.25695,
            0
          ],
          [
            -116.49528,
            47.25608,
            0
          ],
          [
            -116.49552,
            47.25601,
            0
          ],
          [
            -116.49554,
            47.256,
            0
          ],
          [
            -116.49575,
            47.25595,
            0
          ],
          [
            -116.496,
            47.25592,
            0
          ],
          [
            -116.49651,
            47.25588,
            0
          ],
          [
            -116.49921,
            47.2558,
            0
          ],
          [
            -116.49947,
            47.25578,
            0
          ],
          [
            -116.49979,
            47.25573,
            0
          ],
          [
            -116.50001,
            47.25567,
            0
          ],
          [
            -116.50015,
            47.25564,
            0
          ],
          [
            -116.5004,
            47.25554,
            0
          ],
          [
            -116.50064,
            47.25541,
            0
          ],
          [
            -116.50084,
            47.25527,
            0
          ],
          [
            -116.50107,
            47.25507,
            0
          ],
          [
            -116.50121,
            47.25485,
            0
          ],
          [
            -116.50129,
            47.25474,
            0
          ],
          [
            -116.50134,
            47.25463,
            0
          ],
          [
            -116.50138,
            47.25448,
            0
          ],
          [
            -116.50139,
            47.25447,
            0
          ],
          [
            -116.50143,
            47.25431,
            0
          ],
          [
            -116.50143,
            47.25429,
            0
          ],
          [
            -116.50192,
            47.25221,
            0
          ],
          [
            -116.50226,
            47.25095,
            0
          ],
          [
            -116.50235,
            47.25067,
            0
          ],
          [
            -116.50249,
            47.25031,
            0
          ],
          [
            -116.50265,
            47.25006,
            0
          ],
          [
            -116.50282,
            47.24984,
            0
          ],
          [
            -116.50312,
            47.24955,
            0
          ],
          [
            -116.50393,
            47.24892,
            0
          ],
          [
            -116.50405,
            47.24884,
            0
          ],
          [
            -116.50427,
            47.24865,
            0
          ],
          [
            -116.50433,
            47.24858,
            0
          ],
          [
            -116.50442,
            47.2485,
            0
          ],
          [
            -116.50451,
            47.2484,
            0
          ],
          [
            -116.5047,
            47.24816,
            0
          ],
          [
            -116.50497,
            47.2477,
            0
          ],
          [
            -116.50509,
            47.24746,
            0
          ],
          [
            -116.50522,
            47.24708,
            0
          ],
          [
            -116.50529,
            47.24669,
            0
          ],
          [
            -116.50531,
            47.24649,
            0
          ],
          [
            -116.50532,
            47.24629,
            0
          ],
          [
            -116.5053,
            47.24601,
            0
          ],
          [
            -116.50528,
            47.24585,
            0
          ],
          [
            -116.50523,
            47.24563,
            0
          ],
          [
            -116.50516,
            47.24541,
            0
          ],
          [
            -116.50508,
            47.24521,
            0
          ],
          [
            -116.50503,
            47.24511,
            0
          ],
          [
            -116.50501,
            47.24508,
            0
          ],
          [
            -116.50493,
            47.24492,
            0
          ],
          [
            -116.50484,
            47.24477,
            0
          ],
          [
            -116.50473,
            47.24461,
            0
          ],
          [
            -116.5046,
            47.24444,
            0
          ],
          [
            -116.50446,
            47.2443,
            0
          ],
          [
            -116.50444,
            47.24427,
            0
          ],
          [
            -116.50429,
            47.24411,
            0
          ],
          [
            -116.50405,
            47.2439,
            0
          ],
          [
            -116.50383,
            47.24373,
            0
          ],
          [
            -116.50382,
            47.24373,
            0
          ],
          [
            -116.50309,
            47.24326,
            0
          ],
          [
            -116.50019,
            47.24168,
            0
          ],
          [
            -116.49923,
            47.24118,
            0
          ],
          [
            -116.49859,
            47.24089,
            0
          ],
          [
            -116.49664,
            47.24008,
            0
          ],
          [
            -116.49654,
            47.24003,
            0
          ],
          [
            -116.49307,
            47.23864,
            0
          ],
          [
            -116.49277,
            47.23851,
            0
          ],
          [
            -116.49242,
            47.23834,
            0
          ],
          [
            -116.49235,
            47.2383,
            0
          ],
          [
            -116.49217,
            47.23818,
            0
          ],
          [
            -116.49192,
            47.23796,
            0
          ],
          [
            -116.49184,
            47.23787,
            0
          ],
          [
            -116.49173,
            47.2377,
            0
          ],
          [
            -116.49172,
            47.23767,
            0
          ],
          [
            -116.49167,
            47.23758,
            0
          ],
          [
            -116.49164,
            47.23751,
            0
          ],
          [
            -116.49159,
            47.23735,
            0
          ],
          [
            -116.49154,
            47.23709,
            0
          ],
          [
            -116.49145,
            47.23628,
            0
          ],
          [
            -116.49145,
            47.2362,
            0
          ],
          [
            -116.49133,
            47.23473,
            0
          ],
          [
            -116.49126,
            47.23412,
            0
          ],
          [
            -116.49102,
            47.23155,
            0
          ],
          [
            -116.491,
            47.2311,
            0
          ],
          [
            -116.49101,
            47.23069,
            0
          ],
          [
            -116.49102,
            47.23062,
            0
          ],
          [
            -116.49102,
            47.23052,
            0
          ],
          [
            -116.49105,
            47.23003,
            0
          ],
          [
            -116.49107,
            47.22985,
            0
          ],
          [
            -116.49109,
            47.22947,
            0
          ],
          [
            -116.49111,
            47.22931,
            0
          ],
          [
            -116.4912,
            47.22777,
            0
          ],
          [
            -116.49137,
            47.22582,
            0
          ],
          [
            -116.49147,
            47.22417,
            0
          ],
          [
            -116.49161,
            47.22255,
            0
          ],
          [
            -116.49163,
            47.22219,
            0
          ],
          [
            -116.49165,
            47.22201,
            0
          ],
          [
            -116.49166,
            47.22183,
            0
          ],
          [
            -116.49166,
            47.22112,
            0
          ],
          [
            -116.49164,
            47.22093,
            0
          ],
          [
            -116.4916,
            47.22075,
            0
          ],
          [
            -116.49159,
            47.22066,
            0
          ],
          [
            -116.49155,
            47.22049,
            0
          ],
          [
            -116.49147,
            47.22022,
            0
          ],
          [
            -116.49147,
            47.2202,
            0
          ],
          [
            -116.49139,
            47.21994,
            0
          ],
          [
            -116.49122,
            47.21952,
            0
          ],
          [
            -116.49079,
            47.21883,
            0
          ],
          [
            -116.49049,
            47.21843,
            0
          ],
          [
            -116.49037,
            47.21824,
            0
          ],
          [
            -116.49036,
            47.21821,
            0
          ],
          [
            -116.49032,
            47.21815,
            0
          ],
          [
            -116.49028,
            47.21805,
            0
          ],
          [
            -116.49028,
            47.21804,
            0
          ],
          [
            -116.49025,
            47.21791,
            0
          ],
          [
            -116.49025,
            47.21786,
            0
          ],
          [
            -116.49024,
            47.21777,
            0
          ],
          [
            -116.49026,
            47.21768,
            0
          ],
          [
            -116.49026,
            47.21765,
            0
          ],
          [
            -116.49031,
            47.21749,
            0
          ],
          [
            -116.49038,
            47.21733,
            0
          ],
          [
            -116.49057,
            47.21697,
            0
          ],
          [
            -116.49076,
            47.21658,
            0
          ],
          [
            -116.49082,
            47.21638,
            0
          ],
          [
            -116.49084,
            47.21618,
            0
          ],
          [
            -116.49084,
            47.21596,
            0
          ],
          [
            -116.49082,
            47.21584,
            0
          ],
          [
            -116.49079,
            47.21571,
            0
          ],
          [
            -116.49073,
            47.21553,
            0
          ],
          [
            -116.49062,
            47.21531,
            0
          ],
          [
            -116.4904,
            47.215,
            0
          ],
          [
            -116.48905,
            47.21341,
            0
          ],
          [
            -116.48871,
            47.21291,
            0
          ],
          [
            -116.4887,
            47.21288,
            0
          ],
          [
            -116.48858,
            47.21269,
            0
          ],
          [
            -116.48846,
            47.21248,
            0
          ],
          [
            -116.48816,
            47.21185,
            0
          ],
          [
            -116.48802,
            47.21147,
            0
          ],
          [
            -116.48794,
            47.21122,
            0
          ],
          [
            -116.48782,
            47.21079,
            0
          ],
          [
            -116.48776,
            47.21048,
            0
          ],
          [
            -116.48739,
            47.20774,
            0
          ],
          [
            -116.4873,
            47.20723,
            0
          ],
          [
            -116.48722,
            47.2069,
            0
          ],
          [
            -116.4872,
            47.20678,
            0
          ],
          [
            -116.48718,
            47.2067,
            0
          ],
          [
            -116.48706,
            47.20641,
            0
          ],
          [
            -116.48697,
            47.20623,
            0
          ],
          [
            -116.48693,
            47.20619,
            0
          ],
          [
            -116.48684,
            47.20605,
            0
          ],
          [
            -116.48668,
            47.20587,
            0
          ],
          [
            -116.4865,
            47.20565,
            0
          ],
          [
            -116.48641,
            47.20557,
            0
          ],
          [
            -116.48626,
            47.20542,
            0
          ],
          [
            -116.48604,
            47.20522,
            0
          ],
          [
            -116.48562,
            47.20488,
            0
          ],
          [
            -116.48559,
            47.20486,
            0
          ],
          [
            -116.48499,
            47.20437,
            0
          ],
          [
            -116.48496,
            47.20434,
            0
          ],
          [
            -116.48418,
            47.20377,
            0
          ],
          [
            -116.48392,
            47.2036,
            0
          ],
          [
            -116.48359,
            47.20341,
            0
          ],
          [
            -116.48317,
            47.2032,
            0
          ],
          [
            -116.48251,
            47.20294,
            0
          ],
          [
            -116.48186,
            47.20274,
            0
          ],
          [
            -116.48063,
            47.20244,
            0
          ],
          [
            -116.48061,
            47.20244,
            0
          ],
          [
            -116.48036,
            47.20238,
            0
          ],
          [
            -116.48003,
            47.20232,
            0
          ],
          [
            -116.47964,
            47.20221,
            0
          ],
          [
            -116.47928,
            47.20207,
            0
          ],
          [
            -116.47914,
            47.202,
            0
          ],
          [
            -116.47879,
            47.20177,
            0
          ],
          [
            -116.4786,
            47.20161,
            0
          ],
          [
            -116.47858,
            47.2016,
            0
          ],
          [
            -116.4785,
            47.2015,
            0
          ],
          [
            -116.47841,
            47.20135,
            0
          ],
          [
            -116.47837,
            47.20125,
            0
          ],
          [
            -116.47835,
            47.20117,
            0
          ],
          [
            -116.47834,
            47.20111,
            0
          ],
          [
            -116.47834,
            47.20098,
            0
          ],
          [
            -116.47835,
            47.20085,
            0
          ],
          [
            -116.47838,
            47.20068,
            0
          ],
          [
            -116.47848,
            47.20034,
            0
          ],
          [
            -116.4785,
            47.2003,
            0
          ],
          [
            -116.47853,
            47.20022,
            0
          ],
          [
            -116.4786,
            47.20009,
            0
          ],
          [
            -116.47868,
            47.19996,
            0
          ],
          [
            -116.47879,
            47.19983,
            0
          ],
          [
            -116.47882,
            47.1998,
            0
          ],
          [
            -116.47918,
            47.19938,
            0
          ],
          [
            -116.47924,
            47.19928,
            0
          ],
          [
            -116.47929,
            47.19918,
            0
          ],
          [
            -116.47934,
            47.19903,
            0
          ],
          [
            -116.47936,
            47.19888,
            0
          ],
          [
            -116.47938,
            47.19863,
            0
          ],
          [
            -116.47938,
            47.1985,
            0
          ],
          [
            -116.47939,
            47.19845,
            0
          ],
          [
            -116.47939,
            47.1984,
            0
          ],
          [
            -116.47942,
            47.19822,
            0
          ],
          [
            -116.47946,
            47.19808,
            0
          ],
          [
            -116.47949,
            47.198,
            0
          ],
          [
            -116.47957,
            47.19791,
            0
          ],
          [
            -116.47976,
            47.19766,
            0
          ],
          [
            -116.47979,
            47.19761,
            0
          ],
          [
            -116.4799,
            47.19746,
            0
          ],
          [
            -116.47996,
            47.19736,
            0
          ],
          [
            -116.47996,
            47.19733,
            0
          ],
          [
            -116.47998,
            47.19722,
            0
          ],
          [
            -116.48001,
            47.19711,
            0
          ],
          [
            -116.48001,
            47.19704,
            0
          ],
          [
            -116.48,
            47.19697,
            0
          ],
          [
            -116.47993,
            47.19678,
            0
          ],
          [
            -116.4799,
            47.19656,
            0
          ],
          [
            -116.47985,
            47.19636,
            0
          ],
          [
            -116.47985,
            47.19621,
            0
          ],
          [
            -116.47984,
            47.19616,
            0
          ],
          [
            -116.47985,
            47.19609,
            0
          ],
          [
            -116.47984,
            47.19605,
            0
          ],
          [
            -116.47985,
            47.19603,
            0
          ],
          [
            -116.47985,
            47.19596,
            0
          ],
          [
            -116.4799,
            47.19588,
            0
          ],
          [
            -116.47991,
            47.1958,
            0
          ],
          [
            -116.48003,
            47.19554,
            0
          ],
          [
            -116.48021,
            47.19534,
            0
          ],
          [
            -116.48025,
            47.1952,
            0
          ],
          [
            -116.48031,
            47.19515,
            0
          ],
          [
            -116.48049,
            47.19489,
            0
          ],
          [
            -116.48063,
            47.19467,
            0
          ],
          [
            -116.48071,
            47.19448,
            0
          ],
          [
            -116.4809,
            47.19418,
            0
          ],
          [
            -116.48098,
            47.19401,
            0
          ],
          [
            -116.48116,
            47.19371,
            0
          ],
          [
            -116.48141,
            47.19339,
            0
          ],
          [
            -116.48152,
            47.19327,
            0
          ],
          [
            -116.48165,
            47.19309,
            0
          ],
          [
            -116.48197,
            47.19276,
            0
          ],
          [
            -116.48218,
            47.19258,
            0
          ],
          [
            -116.48238,
            47.19229,
            0
          ],
          [
            -116.48249,
            47.19215,
            0
          ],
          [
            -116.48256,
            47.19203,
            0
          ],
          [
            -116.4826,
            47.19198,
            0
          ],
          [
            -116.48262,
            47.19196,
            0
          ],
          [
            -116.48268,
            47.19185,
            0
          ],
          [
            -116.48272,
            47.19172,
            0
          ],
          [
            -116.48279,
            47.19158,
            0
          ],
          [
            -116.48283,
            47.19144,
            0
          ],
          [
            -116.48289,
            47.19111,
            0
          ],
          [
            -116.4829,
            47.19108,
            0
          ],
          [
            -116.48292,
            47.19093,
            0
          ],
          [
            -116.48292,
            47.19087,
            0
          ],
          [
            -116.48294,
            47.19064,
            0
          ],
          [
            -116.48296,
            47.19053,
            0
          ],
          [
            -116.48303,
            47.18986,
            0
          ],
          [
            -116.48303,
            47.18979,
            0
          ],
          [
            -116.48312,
            47.18951,
            0
          ],
          [
            -116.48321,
            47.18932,
            0
          ],
          [
            -116.48326,
            47.18911,
            0
          ],
          [
            -116.4833,
            47.18902,
            0
          ],
          [
            -116.48338,
            47.18887,
            0
          ],
          [
            -116.4834,
            47.18881,
            0
          ],
          [
            -116.48349,
            47.18864,
            0
          ],
          [
            -116.4835,
            47.18861,
            0
          ],
          [
            -116.48379,
            47.18798,
            0
          ],
          [
            -116.48379,
            47.18797,
            0
          ],
          [
            -116.48386,
            47.18779,
            0
          ],
          [
            -116.48393,
            47.18766,
            0
          ],
          [
            -116.48398,
            47.18758,
            0
          ],
          [
            -116.48409,
            47.18736,
            0
          ],
          [
            -116.48416,
            47.18725,
            0
          ],
          [
            -116.48435,
            47.18705,
            0
          ],
          [
            -116.48447,
            47.18695,
            0
          ],
          [
            -116.48455,
            47.1869,
            0
          ],
          [
            -116.48456,
            47.18689,
            0
          ],
          [
            -116.48463,
            47.18684,
            0
          ],
          [
            -116.48475,
            47.18678,
            0
          ],
          [
            -116.48476,
            47.18678,
            0
          ],
          [
            -116.48498,
            47.18669,
            0
          ],
          [
            -116.48499,
            47.18668,
            0
          ],
          [
            -116.48528,
            47.18661,
            0
          ],
          [
            -116.48558,
            47.18657,
            0
          ],
          [
            -116.48578,
            47.18657,
            0
          ],
          [
            -116.48599,
            47.18658,
            0
          ],
          [
            -116.48652,
            47.18664,
            0
          ],
          [
            -116.48655,
            47.18664,
            0
          ],
          [
            -116.48707,
            47.18669,
            0
          ],
          [
            -116.48724,
            47.18669,
            0
          ],
          [
            -116.48754,
            47.18667,
            0
          ],
          [
            -116.4876,
            47.18666,
            0
          ],
          [
            -116.48764,
            47.18666,
            0
          ],
          [
            -116.48779,
            47.18664,
            0
          ],
          [
            -116.48786,
            47.18662,
            0
          ],
          [
            -116.48801,
            47.18659,
            0
          ],
          [
            -116.48827,
            47.18651,
            0
          ],
          [
            -116.48857,
            47.18639,
            0
          ],
          [
            -116.48866,
            47.18636,
            0
          ],
          [
            -116.48958,
            47.18599,
            0
          ],
          [
            -116.49018,
            47.18573,
            0
          ],
          [
            -116.49033,
            47.18564,
            0
          ],
          [
            -116.4905,
            47.18555,
            0
          ],
          [
            -116.49082,
            47.18531,
            0
          ],
          [
            -116.49111,
            47.18502,
            0
          ],
          [
            -116.4916,
            47.18447,
            0
          ],
          [
            -116.49161,
            47.18445,
            0
          ],
          [
            -116.49195,
            47.18407,
            0
          ],
          [
            -116.4925,
            47.18353,
            0
          ],
          [
            -116.49279,
            47.18328,
            0
          ],
          [
            -116.49289,
            47.18318,
            0
          ],
          [
            -116.49295,
            47.18314,
            0
          ],
          [
            -116.4931,
            47.18301,
            0
          ],
          [
            -116.49312,
            47.183,
            0
          ],
          [
            -116.49578,
            47.18102,
            0
          ],
          [
            -116.49603,
            47.18081,
            0
          ],
          [
            -116.49634,
            47.18052,
            0
          ],
          [
            -116.49648,
            47.18037,
            0
          ],
          [
            -116.49659,
            47.18024,
            0
          ],
          [
            -116.4967,
            47.18009,
            0
          ],
          [
            -116.4968,
            47.17993,
            0
          ],
          [
            -116.49689,
            47.17976,
            0
          ],
          [
            -116.49691,
            47.17971,
            0
          ],
          [
            -116.49699,
            47.17955,
            0
          ],
          [
            -116.49706,
            47.17937,
            0
          ],
          [
            -116.49706,
            47.17936,
            0
          ],
          [
            -116.49711,
            47.17921,
            0
          ],
          [
            -116.49714,
            47.17904,
            0
          ],
          [
            -116.49714,
            47.17901,
            0
          ],
          [
            -116.49717,
            47.17885,
            0
          ],
          [
            -116.49717,
            47.17852,
            0
          ],
          [
            -116.49716,
            47.17832,
            0
          ],
          [
            -116.49715,
            47.17829,
            0
          ],
          [
            -116.49713,
            47.17814,
            0
          ],
          [
            -116.49707,
            47.17794,
            0
          ],
          [
            -116.49705,
            47.17785,
            0
          ],
          [
            -116.49694,
            47.17758,
            0
          ],
          [
            -116.49686,
            47.17742,
            0
          ],
          [
            -116.49665,
            47.17708,
            0
          ],
          [
            -116.49646,
            47.17684,
            0
          ],
          [
            -116.49624,
            47.17661,
            0
          ],
          [
            -116.49587,
            47.17629,
            0
          ],
          [
            -116.49572,
            47.17619,
            0
          ],
          [
            -116.49553,
            47.17605,
            0
          ],
          [
            -116.49462,
            47.17545,
            0
          ],
          [
            -116.49203,
            47.17368,
            0
          ],
          [
            -116.49154,
            47.17332,
            0
          ],
          [
            -116.49131,
            47.17313,
            0
          ],
          [
            -116.49105,
            47.17288,
            0
          ],
          [
            -116.49086,
            47.17265,
            0
          ],
          [
            -116.49075,
            47.1725,
            0
          ],
          [
            -116.49074,
            47.1725,
            0
          ],
          [
            -116.49053,
            47.17218,
            0
          ],
          [
            -116.48987,
            47.17101,
            0
          ],
          [
            -116.48953,
            47.17055,
            0
          ],
          [
            -116.48924,
            47.17029,
            0
          ],
          [
            -116.48902,
            47.17012,
            0
          ],
          [
            -116.48794,
            47.16951,
            0
          ],
          [
            -116.4875,
            47.16922,
            0
          ],
          [
            -116.4871,
            47.1689,
            0
          ],
          [
            -116.48635,
            47.16816,
            0
          ],
          [
            -116.48632,
            47.16814,
            0
          ],
          [
            -116.48613,
            47.16797,
            0
          ],
          [
            -116.48563,
            47.16762,
            0
          ],
          [
            -116.48513,
            47.16738,
            0
          ],
          [
            -116.48481,
            47.16725,
            0
          ],
          [
            -116.48406,
            47.16702,
            0
          ],
          [
            -116.48363,
            47.16691,
            0
          ],
          [
            -116.48334,
            47.16682,
            0
          ],
          [
            -116.48315,
            47.16675,
            0
          ],
          [
            -116.48275,
            47.16656,
            0
          ],
          [
            -116.48257,
            47.16646,
            0
          ],
          [
            -116.48231,
            47.16625,
            0
          ],
          [
            -116.48212,
            47.16605,
            0
          ],
          [
            -116.48196,
            47.16581,
            0
          ],
          [
            -116.48184,
            47.16555,
            0
          ],
          [
            -116.48179,
            47.16539,
            0
          ],
          [
            -116.48175,
            47.16522,
            0
          ],
          [
            -116.48175,
            47.1652,
            0
          ],
          [
            -116.48159,
            47.16449,
            0
          ],
          [
            -116.48154,
            47.16431,
            0
          ],
          [
            -116.4813,
            47.16328,
            0
          ],
          [
            -116.48129,
            47.16325,
            0
          ],
          [
            -116.48117,
            47.16271,
            0
          ],
          [
            -116.48111,
            47.16249,
            0
          ],
          [
            -116.48101,
            47.162,
            0
          ],
          [
            -116.48095,
            47.16164,
            0
          ],
          [
            -116.48095,
            47.1616,
            0
          ],
          [
            -116.48093,
            47.16142,
            0
          ],
          [
            -116.48093,
            47.16126,
            0
          ],
          [
            -116.48094,
            47.16107,
            0
          ],
          [
            -116.48096,
            47.16092,
            0
          ],
          [
            -116.481,
            47.16077,
            0
          ],
          [
            -116.481,
            47.16075,
            0
          ],
          [
            -116.48105,
            47.16061,
            0
          ],
          [
            -116.48106,
            47.16057,
            0
          ],
          [
            -116.48129,
            47.16013,
            0
          ],
          [
            -116.48139,
            47.15997,
            0
          ],
          [
            -116.48439,
            47.15579,
            0
          ],
          [
            -116.48441,
            47.15577,
            0
          ],
          [
            -116.48655,
            47.15281,
            0
          ],
          [
            -116.48674,
            47.1526,
            0
          ],
          [
            -116.48705,
            47.15233,
            0
          ],
          [
            -116.48727,
            47.15216,
            0
          ],
          [
            -116.48781,
            47.15191,
            0
          ],
          [
            -116.48803,
            47.15182,
            0
          ],
          [
            -116.4888,
            47.15158,
            0
          ],
          [
            -116.48912,
            47.1515,
            0
          ],
          [
            -116.48913,
            47.1515,
            0
          ],
          [
            -116.48958,
            47.15138,
            0
          ],
          [
            -116.48988,
            47.15132,
            0
          ],
          [
            -116.48994,
            47.1513,
            0
          ],
          [
            -116.49039,
            47.15123,
            0
          ],
          [
            -116.4911,
            47.15118,
            0
          ],
          [
            -116.50065,
            47.15166,
            0
          ],
          [
            -116.50118,
            47.15173,
            0
          ],
          [
            -116.5014,
            47.15178,
            0
          ],
          [
            -116.50168,
            47.15187,
            0
          ],
          [
            -116.50187,
            47.15195,
            0
          ],
          [
            -116.50195,
            47.152,
            0
          ],
          [
            -116.50205,
            47.15205,
            0
          ],
          [
            -116.50242,
            47.15227,
            0
          ],
          [
            -116.50347,
            47.15295,
            0
          ],
          [
            -116.50407,
            47.15336,
            0
          ],
          [
            -116.50483,
            47.15383,
            0
          ],
          [
            -116.50513,
            47.15399,
            0
          ],
          [
            -116.50523,
            47.15403,
            0
          ],
          [
            -116.50581,
            47.15422,
            0
          ],
          [
            -116.5062,
            47.15431,
            0
          ],
          [
            -116.5068,
            47.15441,
            0
          ],
          [
            -116.51454,
            47.15534,
            0
          ],
          [
            -116.51498,
            47.15535,
            0
          ],
          [
            -116.51533,
            47.15533,
            0
          ],
          [
            -116.51569,
            47.15527,
            0
          ],
          [
            -116.51588,
            47.15523,
            0
          ],
          [
            -116.51595,
            47.15522,
            0
          ],
          [
            -116.51681,
            47.15502,
            0
          ],
          [
            -116.51731,
            47.15489,
            0
          ],
          [
            -116.51797,
            47.15468,
            0
          ],
          [
            -116.51847,
            47.15448,
            0
          ],
          [
            -116.51877,
            47.15434,
            0
          ],
          [
            -116.51881,
            47.15431,
            0
          ],
          [
            -116.5189,
            47.15426,
            0
          ],
          [
            -116.52008,
            47.15346,
            0
          ],
          [
            -116.52049,
            47.15322,
            0
          ],
          [
            -116.52086,
            47.15307,
            0
          ],
          [
            -116.52111,
            47.153,
            0
          ],
          [
            -116.52152,
            47.15292,
            0
          ],
          [
            -116.5227,
            47.15277,
            0
          ],
          [
            -116.52377,
            47.15266,
            0
          ],
          [
            -116.52447,
            47.15261,
            0
          ],
          [
            -116.5254,
            47.15259,
            0
          ],
          [
            -116.5266,
            47.15264,
            0
          ],
          [
            -116.5279,
            47.15272,
            0
          ],
          [
            -116.528,
            47.15272,
            0
          ],
          [
            -116.52983,
            47.15282,
            0
          ],
          [
            -116.53032,
            47.15281,
            0
          ],
          [
            -116.53054,
            47.15279,
            0
          ],
          [
            -116.5311,
            47.1527,
            0
          ],
          [
            -116.53156,
            47.15257,
            0
          ],
          [
            -116.53181,
            47.15248,
            0
          ],
          [
            -116.53266,
            47.15209,
            0
          ],
          [
            -116.53511,
            47.15091,
            0
          ],
          [
            -116.53635,
            47.15036,
            0
          ],
          [
            -116.53706,
            47.1501,
            0
          ],
          [
            -116.53754,
            47.14995,
            0
          ],
          [
            -116.53869,
            47.14968,
            0
          ],
          [
            -116.5393,
            47.14956,
            0
          ],
          [
            -116.53941,
            47.14953,
            0
          ],
          [
            -116.54269,
            47.1489,
            0
          ],
          [
            -116.54295,
            47.14886,
            0
          ],
          [
            -116.5432,
            47.14881,
            0
          ],
          [
            -116.54342,
            47.14878,
            0
          ],
          [
            -116.54365,
            47.14876,
            0
          ],
          [
            -116.54389,
            47.14875,
            0
          ],
          [
            -116.54416,
            47.14877,
            0
          ],
          [
            -116.54426,
            47.14877,
            0
          ],
          [
            -116.54459,
            47.14882,
            0
          ],
          [
            -116.54474,
            47.14885,
            0
          ],
          [
            -116.54525,
            47.14901,
            0
          ],
          [
            -116.54535,
            47.14905,
            0
          ],
          [
            -116.54585,
            47.14922,
            0
          ],
          [
            -116.54641,
            47.14937,
            0
          ],
          [
            -116.54677,
            47.14943,
            0
          ],
          [
            -116.54689,
            47.14944,
            0
          ],
          [
            -116.54716,
            47.14944,
            0
          ],
          [
            -116.54752,
            47.14941,
            0
          ],
          [
            -116.54769,
            47.14938,
            0
          ],
          [
            -116.54789,
            47.14933,
            0
          ],
          [
            -116.54807,
            47.14927,
            0
          ],
          [
            -116.5483,
            47.14918,
            0
          ],
          [
            -116.5485,
            47.14908,
            0
          ],
          [
            -116.54886,
            47.14882,
            0
          ],
          [
            -116.549,
            47.14869,
            0
          ],
          [
            -116.54913,
            47.14851,
            0
          ],
          [
            -116.54922,
            47.14834,
            0
          ],
          [
            -116.54926,
            47.14823,
            0
          ],
          [
            -116.54927,
            47.14821,
            0
          ],
          [
            -116.54932,
            47.14806,
            0
          ],
          [
            -116.54932,
            47.14803,
            0
          ],
          [
            -116.54944,
            47.14711,
            0
          ],
          [
            -116.54947,
            47.14696,
            0
          ],
          [
            -116.5495,
            47.14688,
            0
          ],
          [
            -116.54952,
            47.1468,
            0
          ],
          [
            -116.54961,
            47.14653,
            0
          ],
          [
            -116.54973,
            47.14628,
            0
          ],
          [
            -116.54982,
            47.14612,
            0
          ],
          [
            -116.55001,
            47.14587,
            0
          ],
          [
            -116.55027,
            47.14559,
            0
          ],
          [
            -116.55153,
            47.14441,
            0
          ],
          [
            -116.55196,
            47.14407,
            0
          ],
          [
            -116.55222,
            47.14391,
            0
          ],
          [
            -116.5528,
            47.14361,
            0
          ],
          [
            -116.55312,
            47.14348,
            0
          ],
          [
            -116.55338,
            47.14339,
            0
          ],
          [
            -116.55353,
            47.14333,
            0
          ],
          [
            -116.5541,
            47.14316,
            0
          ],
          [
            -116.55454,
            47.14306,
            0
          ],
          [
            -116.55485,
            47.14301,
            0
          ],
          [
            -116.55596,
            47.14289,
            0
          ],
          [
            -116.55667,
            47.14278,
            0
          ],
          [
            -116.55714,
            47.14269,
            0
          ],
          [
            -116.55919,
            47.14222,
            0
          ],
          [
            -116.55979,
            47.1421,
            0
          ],
          [
            -116.5612,
            47.14177,
            0
          ],
          [
            -116.56136,
            47.14174,
            0
          ],
          [
            -116.5628,
            47.14138,
            0
          ],
          [
            -116.56347,
            47.14119,
            0
          ],
          [
            -116.56526,
            47.14061,
            0
          ],
          [
            -116.56556,
            47.1405,
            0
          ],
          [
            -116.56621,
            47.14029,
            0
          ],
          [
            -116.56651,
            47.14018,
            0
          ],
          [
            -116.57011,
            47.13898,
            0
          ],
          [
            -116.57043,
            47.13886,
            0
          ],
          [
            -116.57047,
            47.13885,
            0
          ],
          [
            -116.57079,
            47.13873,
            0
          ],
          [
            -116.57161,
            47.13839,
            0
          ],
          [
            -116.57217,
            47.13814,
            0
          ],
          [
            -116.58718,
            47.13058,
            0
          ],
          [
            -116.58771,
            47.13028,
            0
          ],
          [
            -116.58832,
            47.12987,
            0
          ],
          [
            -116.58866,
            47.12961,
            0
          ],
          [
            -116.58919,
            47.12916,
            0
          ],
          [
            -116.59203,
            47.12641,
            0
          ],
          [
            -116.59222,
            47.12621,
            0
          ],
          [
            -116.59237,
            47.12602,
            0
          ],
          [
            -116.59253,
            47.12579,
            0
          ],
          [
            -116.59262,
            47.12564,
            0
          ],
          [
            -116.59274,
            47.12541,
            0
          ],
          [
            -116.59468,
            47.12117,
            0
          ],
          [
            -116.59491,
            47.12061,
            0
          ],
          [
            -116.59534,
            47.11938,
            0
          ],
          [
            -116.59551,
            47.11895,
            0
          ],
          [
            -116.59557,
            47.11882,
            0
          ],
          [
            -116.59569,
            47.1186,
            0
          ],
          [
            -116.59602,
            47.11813,
            0
          ],
          [
            -116.5986,
            47.11604,
            0
          ],
          [
            -116.59897,
            47.11571,
            0
          ],
          [
            -116.60079,
            47.11419,
            0
          ],
          [
            -116.60177,
            47.11334,
            0
          ],
          [
            -116.60236,
            47.11286,
            0
          ],
          [
            -116.60276,
            47.11257,
            0
          ],
          [
            -116.60347,
            47.11213,
            0
          ],
          [
            -116.604,
            47.11184,
            0
          ],
          [
            -116.60479,
            47.11147,
            0
          ],
          [
            -116.61515,
            47.10702,
            0
          ],
          [
            -116.61531,
            47.10696,
            0
          ],
          [
            -116.63573,
            47.09813,
            0
          ],
          [
            -116.63605,
            47.09796,
            0
          ],
          [
            -116.63645,
            47.0977,
            0
          ],
          [
            -116.63672,
            47.09746,
            0
          ],
          [
            -116.63676,
            47.09741,
            0
          ],
          [
            -116.63691,
            47.09726,
            0
          ],
          [
            -116.63702,
            47.0971,
            0
          ],
          [
            -116.63716,
            47.09681,
            0
          ],
          [
            -116.63729,
            47.09642,
            0
          ],
          [
            -116.6373,
            47.09641,
            0
          ],
          [
            -116.6375,
            47.0957,
            0
          ],
          [
            -116.63756,
            47.09552,
            0
          ],
          [
            -116.6378,
            47.09468,
            0
          ],
          [
            -116.63812,
            47.09375,
            0
          ],
          [
            -116.63816,
            47.09365,
            0
          ],
          [
            -116.63831,
            47.09339,
            0
          ],
          [
            -116.63858,
            47.09301,
            0
          ],
          [
            -116.63886,
            47.09273,
            0
          ],
          [
            -116.63931,
            47.09239,
            0
          ],
          [
            -116.6395,
            47.09227,
            0
          ],
          [
            -116.63992,
            47.09204,
            0
          ],
          [
            -116.64035,
            47.09186,
            0
          ],
          [
            -116.64046,
            47.09183,
            0
          ],
          [
            -116.64069,
            47.09175,
            0
          ],
          [
            -116.6407,
            47.09174,
            0
          ],
          [
            -116.64116,
            47.09163,
            0
          ],
          [
            -116.64225,
            47.09147,
            0
          ],
          [
            -116.64309,
            47.09137,
            0
          ],
          [
            -116.6438,
            47.09136,
            0
          ],
          [
            -116.64392,
            47.09135,
            0
          ],
          [
            -116.64458,
            47.09142,
            0
          ],
          [
            -116.64543,
            47.09158,
            0
          ],
          [
            -116.64594,
            47.09165,
            0
          ],
          [
            -116.64624,
            47.09165,
            0
          ],
          [
            -116.6465,
            47.09162,
            0
          ],
          [
            -116.64689,
            47.09153,
            0
          ],
          [
            -116.64711,
            47.09143,
            0
          ],
          [
            -116.64734,
            47.09134,
            0
          ],
          [
            -116.64901,
            47.09062,
            0
          ],
          [
            -116.6493,
            47.09045,
            0
          ],
          [
            -116.64931,
            47.09044,
            0
          ],
          [
            -116.6495,
            47.09032,
            0
          ],
          [
            -116.64966,
            47.0902,
            0
          ],
          [
            -116.64983,
            47.09004,
            0
          ],
          [
            -116.64995,
            47.0899,
            0
          ],
          [
            -116.65016,
            47.08955,
            0
          ],
          [
            -116.65024,
            47.08938,
            0
          ],
          [
            -116.6503,
            47.0892,
            0
          ],
          [
            -116.65032,
            47.08916,
            0
          ],
          [
            -116.65036,
            47.08896,
            0
          ],
          [
            -116.65036,
            47.08884,
            0
          ],
          [
            -116.65035,
            47.08875,
            0
          ],
          [
            -116.65024,
            47.08826,
            0
          ],
          [
            -116.65026,
            47.08795,
            0
          ],
          [
            -116.65027,
            47.0879,
            0
          ],
          [
            -116.65036,
            47.08779,
            0
          ],
          [
            -116.65038,
            47.08776,
            0
          ],
          [
            -116.65056,
            47.08768,
            0
          ],
          [
            -116.65152,
            47.08738,
            0
          ],
          [
            -116.65172,
            47.0872,
            0
          ],
          [
            -116.65182,
            47.08704,
            0
          ],
          [
            -116.65188,
            47.08687,
            0
          ],
          [
            -116.65189,
            47.08685,
            0
          ],
          [
            -116.65189,
            47.08669,
            0
          ],
          [
            -116.65187,
            47.08651,
            0
          ],
          [
            -116.65184,
            47.08633,
            0
          ],
          [
            -116.65184,
            47.08629,
            0
          ],
          [
            -116.65187,
            47.08618,
            0
          ],
          [
            -116.65196,
            47.08603,
            0
          ],
          [
            -116.652,
            47.086,
            0
          ],
          [
            -116.65214,
            47.08587,
            0
          ],
          [
            -116.65241,
            47.08579,
            0
          ],
          [
            -116.65243,
            47.08578,
            0
          ],
          [
            -116.65306,
            47.0856,
            0
          ],
          [
            -116.65313,
            47.08556,
            0
          ],
          [
            -116.65318,
            47.08554,
            0
          ],
          [
            -116.6533,
            47.08545,
            0
          ],
          [
            -116.65331,
            47.08544,
            0
          ],
          [
            -116.65333,
            47.08543,
            0
          ],
          [
            -116.65343,
            47.08528,
            0
          ],
          [
            -116.65349,
            47.08515,
            0
          ],
          [
            -116.65349,
            47.08492,
            0
          ],
          [
            -116.65342,
            47.08475,
            0
          ],
          [
            -116.65336,
            47.08457,
            0
          ],
          [
            -116.65328,
            47.0844,
            0
          ],
          [
            -116.65313,
            47.08413,
            0
          ],
          [
            -116.6528,
            47.08344,
            0
          ],
          [
            -116.65279,
            47.08338,
            0
          ],
          [
            -116.65276,
            47.08327,
            0
          ],
          [
            -116.65276,
            47.08319,
            0
          ],
          [
            -116.65278,
            47.08308,
            0
          ],
          [
            -116.65283,
            47.08297,
            0
          ],
          [
            -116.65291,
            47.08286,
            0
          ],
          [
            -116.65311,
            47.08274,
            0
          ],
          [
            -116.65315,
            47.08272,
            0
          ],
          [
            -116.65342,
            47.08265,
            0
          ],
          [
            -116.6536,
            47.08263,
            0
          ],
          [
            -116.65396,
            47.08257,
            0
          ],
          [
            -116.65403,
            47.08255,
            0
          ],
          [
            -116.6541,
            47.08252,
            0
          ],
          [
            -116.6543,
            47.08241,
            0
          ],
          [
            -116.65431,
            47.08241,
            0
          ],
          [
            -116.65447,
            47.08226,
            0
          ],
          [
            -116.65451,
            47.08223,
            0
          ],
          [
            -116.65455,
            47.08218,
            0
          ],
          [
            -116.65459,
            47.08211,
            0
          ],
          [
            -116.65459,
            47.0821,
            0
          ],
          [
            -116.65466,
            47.08193,
            0
          ],
          [
            -116.65469,
            47.08188,
            0
          ],
          [
            -116.65475,
            47.08163,
            0
          ],
          [
            -116.65484,
            47.0815,
            0
          ],
          [
            -116.65491,
            47.08143,
            0
          ],
          [
            -116.65504,
            47.08127,
            0
          ],
          [
            -116.65509,
            47.08122,
            0
          ],
          [
            -116.65546,
            47.08103,
            0
          ],
          [
            -116.65596,
            47.08089,
            0
          ],
          [
            -116.65627,
            47.08082,
            0
          ],
          [
            -116.6566,
            47.08071,
            0
          ],
          [
            -116.65665,
            47.08068,
            0
          ],
          [
            -116.65677,
            47.08062,
            0
          ],
          [
            -116.65685,
            47.08057,
            0
          ],
          [
            -116.65709,
            47.08035,
            0
          ],
          [
            -116.65723,
            47.08015,
            0
          ],
          [
            -116.65766,
            47.08,
            0
          ],
          [
            -116.65799,
            47.07995,
            0
          ],
          [
            -116.6584,
            47.07996,
            0
          ],
          [
            -116.6587,
            47.07998,
            0
          ],
          [
            -116.65904,
            47.07997,
            0
          ],
          [
            -116.65922,
            47.07993,
            0
          ],
          [
            -116.65932,
            47.0799,
            0
          ],
          [
            -116.65945,
            47.0798,
            0
          ],
          [
            -116.65951,
            47.07976,
            0
          ],
          [
            -116.65957,
            47.07973,
            0
          ],
          [
            -116.65968,
            47.07964,
            0
          ],
          [
            -116.65968,
            47.07963,
            0
          ],
          [
            -116.65973,
            47.07955,
            0
          ],
          [
            -116.65977,
            47.07947,
            0
          ],
          [
            -116.65978,
            47.07946,
            0
          ],
          [
            -116.65982,
            47.07933,
            0
          ],
          [
            -116.65982,
            47.07922,
            0
          ],
          [
            -116.65978,
            47.07911,
            0
          ],
          [
            -116.65978,
            47.07909,
            0
          ],
          [
            -116.6597,
            47.07894,
            0
          ],
          [
            -116.65969,
            47.07894,
            0
          ],
          [
            -116.65962,
            47.07886,
            0
          ],
          [
            -116.65942,
            47.07869,
            0
          ],
          [
            -116.65926,
            47.0786,
            0
          ],
          [
            -116.65898,
            47.07842,
            0
          ],
          [
            -116.65897,
            47.07841,
            0
          ],
          [
            -116.65885,
            47.07832,
            0
          ],
          [
            -116.65873,
            47.07816,
            0
          ],
          [
            -116.65867,
            47.07806,
            0
          ],
          [
            -116.65868,
            47.07794,
            0
          ],
          [
            -116.65868,
            47.07788,
            0
          ],
          [
            -116.65875,
            47.07772,
            0
          ],
          [
            -116.65885,
            47.07761,
            0
          ],
          [
            -116.65885,
            47.0776,
            0
          ],
          [
            -116.65899,
            47.07745,
            0
          ],
          [
            -116.65899,
            47.07744,
            0
          ],
          [
            -116.6591,
            47.07733,
            0
          ],
          [
            -116.6592,
            47.07714,
            0
          ],
          [
            -116.6592,
            47.07712,
            0
          ],
          [
            -116.65923,
            47.07699,
            0
          ],
          [
            -116.6592,
            47.07685,
            0
          ],
          [
            -116.65916,
            47.07675,
            0
          ],
          [
            -116.65894,
            47.07646,
            0
          ],
          [
            -116.65881,
            47.07631,
            0
          ],
          [
            -116.6588,
            47.07629,
            0
          ],
          [
            -116.65847,
            47.07589,
            0
          ],
          [
            -116.65842,
            47.07582,
            0
          ],
          [
            -116.6584,
            47.07578,
            0
          ],
          [
            -116.65838,
            47.07565,
            0
          ],
          [
            -116.65837,
            47.07553,
            0
          ],
          [
            -116.65843,
            47.07534,
            0
          ],
          [
            -116.65848,
            47.0753,
            0
          ],
          [
            -116.65851,
            47.07526,
            0
          ],
          [
            -116.65874,
            47.07504,
            0
          ],
          [
            -116.65879,
            47.075,
            0
          ],
          [
            -116.65884,
            47.07497,
            0
          ],
          [
            -116.65899,
            47.07479,
            0
          ],
          [
            -116.65902,
            47.07469,
            0
          ],
          [
            -116.65905,
            47.07462,
            0
          ],
          [
            -116.65906,
            47.07455,
            0
          ],
          [
            -116.65906,
            47.07451,
            0
          ],
          [
            -116.65904,
            47.0744,
            0
          ],
          [
            -116.65901,
            47.07434,
            0
          ],
          [
            -116.65901,
            47.07433,
            0
          ],
          [
            -116.65892,
            47.07416,
            0
          ],
          [
            -116.6588,
            47.07401,
            0
          ],
          [
            -116.65879,
            47.07401,
            0
          ],
          [
            -116.65862,
            47.07386,
            0
          ],
          [
            -116.65845,
            47.07374,
            0
          ],
          [
            -116.65823,
            47.07356,
            0
          ],
          [
            -116.65817,
            47.07349,
            0
          ],
          [
            -116.65813,
            47.07341,
            0
          ],
          [
            -116.6581,
            47.07333,
            0
          ],
          [
            -116.65809,
            47.07327,
            0
          ],
          [
            -116.65808,
            47.07326,
            0
          ],
          [
            -116.65809,
            47.07315,
            0
          ],
          [
            -116.6581,
            47.07309,
            0
          ],
          [
            -116.65811,
            47.07307,
            0
          ],
          [
            -116.65813,
            47.07301,
            0
          ],
          [
            -116.65817,
            47.07294,
            0
          ],
          [
            -116.65841,
            47.07268,
            0
          ],
          [
            -116.6585,
            47.07256,
            0
          ],
          [
            -116.65857,
            47.07245,
            0
          ],
          [
            -116.65859,
            47.07241,
            0
          ],
          [
            -116.65861,
            47.07231,
            0
          ],
          [
            -116.65861,
            47.0722,
            0
          ],
          [
            -116.6586,
            47.07212,
            0
          ],
          [
            -116.65858,
            47.0721,
            0
          ],
          [
            -116.65852,
            47.07199,
            0
          ],
          [
            -116.65848,
            47.07193,
            0
          ],
          [
            -116.65847,
            47.07193,
            0
          ],
          [
            -116.65838,
            47.07184,
            0
          ],
          [
            -116.65832,
            47.07179,
            0
          ],
          [
            -116.65829,
            47.07176,
            0
          ],
          [
            -116.6582,
            47.07169,
            0
          ],
          [
            -116.65793,
            47.07153,
            0
          ],
          [
            -116.65781,
            47.07144,
            0
          ],
          [
            -116.65775,
            47.07137,
            0
          ],
          [
            -116.65769,
            47.07132,
            0
          ],
          [
            -116.65764,
            47.07125,
            0
          ],
          [
            -116.65759,
            47.07115,
            0
          ],
          [
            -116.65757,
            47.07108,
            0
          ],
          [
            -116.65756,
            47.07102,
            0
          ],
          [
            -116.65756,
            47.07089,
            0
          ],
          [
            -116.65757,
            47.07077,
            0
          ],
          [
            -116.65759,
            47.0707,
            0
          ],
          [
            -116.65762,
            47.07062,
            0
          ],
          [
            -116.65765,
            47.07056,
            0
          ],
          [
            -116.65767,
            47.07055,
            0
          ],
          [
            -116.65775,
            47.07046,
            0
          ],
          [
            -116.65782,
            47.0704,
            0
          ],
          [
            -116.65789,
            47.07033,
            0
          ],
          [
            -116.65801,
            47.07024,
            0
          ],
          [
            -116.65859,
            47.06991,
            0
          ],
          [
            -116.65901,
            47.06969,
            0
          ],
          [
            -116.65904,
            47.06968,
            0
          ],
          [
            -116.6596,
            47.0694,
            0
          ],
          [
            -116.66009,
            47.06918,
            0
          ],
          [
            -116.66036,
            47.0691,
            0
          ],
          [
            -116.66058,
            47.06905,
            0
          ],
          [
            -116.6608,
            47.06902,
            0
          ],
          [
            -116.66084,
            47.06901,
            0
          ],
          [
            -116.66135,
            47.06897,
            0
          ],
          [
            -116.6616,
            47.06896,
            0
          ],
          [
            -116.66163,
            47.06897,
            0
          ],
          [
            -116.66189,
            47.069,
            0
          ],
          [
            -116.66226,
            47.06909,
            0
          ],
          [
            -116.66258,
            47.06915,
            0
          ],
          [
            -116.66275,
            47.06916,
            0
          ],
          [
            -116.66292,
            47.06915,
            0
          ],
          [
            -116.66305,
            47.06912,
            0
          ],
          [
            -116.66334,
            47.06903,
            0
          ],
          [
            -116.66339,
            47.06901,
            0
          ],
          [
            -116.66347,
            47.06896,
            0
          ],
          [
            -116.66376,
            47.06875,
            0
          ],
          [
            -116.6638,
            47.06873,
            0
          ],
          [
            -116.66393,
            47.06861,
            0
          ],
          [
            -116.66408,
            47.06849,
            0
          ],
          [
            -116.66547,
            47.06724,
            0
          ],
          [
            -116.66563,
            47.06712,
            0
          ],
          [
            -116.66574,
            47.06707,
            0
          ],
          [
            -116.66579,
            47.06704,
            0
          ],
          [
            -116.66599,
            47.06697,
            0
          ],
          [
            -116.66621,
            47.06691,
            0
          ],
          [
            -116.66624,
            47.06691,
            0
          ],
          [
            -116.66647,
            47.06688,
            0
          ],
          [
            -116.66672,
            47.06686,
            0
          ],
          [
            -116.66839,
            47.06693,
            0
          ],
          [
            -116.66849,
            47.06692,
            0
          ],
          [
            -116.66875,
            47.06691,
            0
          ],
          [
            -116.66916,
            47.06687,
            0
          ],
          [
            -116.66989,
            47.06672,
            0
          ],
          [
            -116.67003,
            47.0667,
            0
          ],
          [
            -116.67008,
            47.0667,
            0
          ],
          [
            -116.67029,
            47.06668,
            0
          ],
          [
            -116.67059,
            47.06668,
            0
          ],
          [
            -116.67083,
            47.0667,
            0
          ],
          [
            -116.67123,
            47.06675,
            0
          ],
          [
            -116.67134,
            47.06678,
            0
          ],
          [
            -116.67136,
            47.06678,
            0
          ],
          [
            -116.67159,
            47.06683,
            0
          ],
          [
            -116.67275,
            47.06722,
            0
          ],
          [
            -116.67277,
            47.06723,
            0
          ],
          [
            -116.67347,
            47.06749,
            0
          ],
          [
            -116.67394,
            47.06765,
            0
          ],
          [
            -116.67414,
            47.06773,
            0
          ],
          [
            -116.6744,
            47.06777,
            0
          ],
          [
            -116.67461,
            47.06777,
            0
          ],
          [
            -116.67469,
            47.06776,
            0
          ],
          [
            -116.67475,
            47.06776,
            0
          ],
          [
            -116.67483,
            47.06775,
            0
          ],
          [
            -116.67491,
            47.06773,
            0
          ],
          [
            -116.67508,
            47.06767,
            0
          ],
          [
            -116.67528,
            47.06755,
            0
          ],
          [
            -116.67541,
            47.06726,
            0
          ],
          [
            -116.6754,
            47.06713,
            0
          ],
          [
            -116.6754,
            47.06704,
            0
          ],
          [
            -116.67529,
            47.0668,
            0
          ],
          [
            -116.67512,
            47.06664,
            0
          ],
          [
            -116.67478,
            47.06625,
            0
          ],
          [
            -116.67473,
            47.06617,
            0
          ],
          [
            -116.67461,
            47.06601,
            0
          ],
          [
            -116.67443,
            47.06571,
            0
          ],
          [
            -116.67436,
            47.06557,
            0
          ],
          [
            -116.67434,
            47.06544,
            0
          ],
          [
            -116.67435,
            47.06532,
            0
          ],
          [
            -116.67436,
            47.06528,
            0
          ],
          [
            -116.6745,
            47.06509,
            0
          ],
          [
            -116.67471,
            47.06488,
            0
          ],
          [
            -116.67498,
            47.06475,
            0
          ],
          [
            -116.6752,
            47.06468,
            0
          ],
          [
            -116.67571,
            47.06459,
            0
          ],
          [
            -116.67574,
            47.06459,
            0
          ],
          [
            -116.67605,
            47.06445,
            0
          ],
          [
            -116.67617,
            47.06435,
            0
          ],
          [
            -116.67625,
            47.06422,
            0
          ],
          [
            -116.67627,
            47.0642,
            0
          ],
          [
            -116.6763,
            47.064,
            0
          ],
          [
            -116.67626,
            47.06387,
            0
          ],
          [
            -116.67611,
            47.06372,
            0
          ],
          [
            -116.6759,
            47.06361,
            0
          ],
          [
            -116.67586,
            47.06358,
            0
          ],
          [
            -116.67553,
            47.06348,
            0
          ],
          [
            -116.67522,
            47.06341,
            0
          ],
          [
            -116.67497,
            47.06332,
            0
          ],
          [
            -116.67468,
            47.06319,
            0
          ],
          [
            -116.67438,
            47.063,
            0
          ],
          [
            -116.67372,
            47.06245,
            0
          ],
          [
            -116.67352,
            47.06226,
            0
          ],
          [
            -116.67255,
            47.06141,
            0
          ],
          [
            -116.67246,
            47.06134,
            0
          ],
          [
            -116.6724,
            47.06126,
            0
          ],
          [
            -116.67219,
            47.06103,
            0
          ],
          [
            -116.67207,
            47.06078,
            0
          ],
          [
            -116.67206,
            47.06077,
            0
          ],
          [
            -116.67191,
            47.06033,
            0
          ],
          [
            -116.67184,
            47.05971,
            0
          ],
          [
            -116.67181,
            47.05953,
            0
          ],
          [
            -116.67162,
            47.05783,
            0
          ],
          [
            -116.67154,
            47.05757,
            0
          ],
          [
            -116.6715,
            47.05739,
            0
          ],
          [
            -116.67145,
            47.05723,
            0
          ],
          [
            -116.67099,
            47.05637,
            0
          ],
          [
            -116.67079,
            47.05575,
            0
          ],
          [
            -116.67077,
            47.05567,
            0
          ],
          [
            -116.67077,
            47.05566,
            0
          ],
          [
            -116.67072,
            47.0555,
            0
          ],
          [
            -116.67072,
            47.05549,
            0
          ],
          [
            -116.67064,
            47.05514,
            0
          ],
          [
            -116.67063,
            47.05513,
            0
          ],
          [
            -116.67062,
            47.05495,
            0
          ],
          [
            -116.67055,
            47.05435,
            0
          ],
          [
            -116.67055,
            47.05423,
            0
          ],
          [
            -116.67063,
            47.054,
            0
          ],
          [
            -116.67068,
            47.05383,
            0
          ],
          [
            -116.67073,
            47.05377,
            0
          ],
          [
            -116.67082,
            47.05368,
            0
          ],
          [
            -116.67093,
            47.05359,
            0
          ],
          [
            -116.67094,
            47.05359,
            0
          ],
          [
            -116.67111,
            47.05345,
            0
          ],
          [
            -116.67129,
            47.05333,
            0
          ],
          [
            -116.6713,
            47.05333,
            0
          ],
          [
            -116.67149,
            47.05322,
            0
          ],
          [
            -116.6715,
            47.05322,
            0
          ],
          [
            -116.67151,
            47.05321,
            0
          ],
          [
            -116.67169,
            47.05309,
            0
          ],
          [
            -116.6717,
            47.05309,
            0
          ],
          [
            -116.67199,
            47.0529,
            0
          ],
          [
            -116.67223,
            47.0527,
            0
          ],
          [
            -116.67223,
            47.05269,
            0
          ],
          [
            -116.67229,
            47.05264,
            0
          ],
          [
            -116.67246,
            47.05247,
            0
          ],
          [
            -116.67251,
            47.0524,
            0
          ],
          [
            -116.67252,
            47.05239,
            0
          ],
          [
            -116.67259,
            47.05229,
            0
          ],
          [
            -116.67272,
            47.05214,
            0
          ],
          [
            -116.6729,
            47.05175,
            0
          ],
          [
            -116.6729,
            47.05173,
            0
          ],
          [
            -116.67297,
            47.05156,
            0
          ],
          [
            -116.67297,
            47.05155,
            0
          ],
          [
            -116.673,
            47.05149,
            0
          ],
          [
            -116.67303,
            47.05138,
            0
          ],
          [
            -116.67326,
            47.05068,
            0
          ],
          [
            -116.67329,
            47.05061,
            0
          ],
          [
            -116.67332,
            47.05051,
            0
          ],
          [
            -116.67333,
            47.0505,
            0
          ],
          [
            -116.67339,
            47.05033,
            0
          ],
          [
            -116.67353,
            47.04999,
            0
          ],
          [
            -116.67353,
            47.04998,
            0
          ],
          [
            -116.67366,
            47.04964,
            0
          ],
          [
            -116.67367,
            47.04963,
            0
          ],
          [
            -116.67374,
            47.04947,
            0
          ],
          [
            -116.67374,
            47.04946,
            0
          ],
          [
            -116.67391,
            47.04905,
            0
          ],
          [
            -116.67397,
            47.04895,
            0
          ],
          [
            -116.67397,
            47.04894,
            0
          ],
          [
            -116.674,
            47.0489,
            0
          ],
          [
            -116.67438,
            47.04829,
            0
          ],
          [
            -116.67438,
            47.04828,
            0
          ],
          [
            -116.67449,
            47.04812,
            0
          ],
          [
            -116.67454,
            47.04803,
            0
          ],
          [
            -116.67528,
            47.0473,
            0
          ],
          [
            -116.67535,
            47.04722,
            0
          ],
          [
            -116.67565,
            47.04692,
            0
          ],
          [
            -116.67566,
            47.0469,
            0
          ],
          [
            -116.67586,
            47.04659,
            0
          ],
          [
            -116.67595,
            47.04643,
            0
          ],
          [
            -116.67596,
            47.04642,
            0
          ],
          [
            -116.67608,
            47.04608,
            0
          ],
          [
            -116.67608,
            47.04607,
            0
          ],
          [
            -116.67615,
            47.04587,
            0
          ],
          [
            -116.67616,
            47.04572,
            0
          ],
          [
            -116.67618,
            47.04557,
            0
          ],
          [
            -116.67617,
            47.04554,
            0
          ],
          [
            -116.67606,
            47.04488,
            0
          ],
          [
            -116.67597,
            47.04466,
            0
          ],
          [
            -116.67595,
            47.0446,
            0
          ],
          [
            -116.67593,
            47.04448,
            0
          ],
          [
            -116.6759,
            47.04435,
            0
          ],
          [
            -116.67574,
            47.04406,
            0
          ],
          [
            -116.67571,
            47.04396,
            0
          ],
          [
            -116.67551,
            47.04344,
            0
          ],
          [
            -116.67539,
            47.04309,
            0
          ],
          [
            -116.67532,
            47.04292,
            0
          ],
          [
            -116.67532,
            47.04291,
            0
          ],
          [
            -116.67526,
            47.04274,
            0
          ],
          [
            -116.67519,
            47.04257,
            0
          ],
          [
            -116.67513,
            47.04239,
            0
          ],
          [
            -116.67498,
            47.04202,
            0
          ],
          [
            -116.67493,
            47.04187,
            0
          ],
          [
            -116.67488,
            47.04169,
            0
          ],
          [
            -116.67452,
            47.04064,
            0
          ],
          [
            -116.67447,
            47.04047,
            0
          ],
          [
            -116.67411,
            47.03941,
            0
          ],
          [
            -116.67406,
            47.03924,
            0
          ],
          [
            -116.67347,
            47.03748,
            0
          ],
          [
            -116.67338,
            47.03731,
            0
          ],
          [
            -116.67301,
            47.03626,
            0
          ],
          [
            -116.673,
            47.03619,
            0
          ],
          [
            -116.67296,
            47.03601,
            0
          ],
          [
            -116.67296,
            47.03596,
            0
          ],
          [
            -116.67291,
            47.03565,
            0
          ],
          [
            -116.6729,
            47.03563,
            0
          ],
          [
            -116.67288,
            47.03547,
            0
          ],
          [
            -116.67287,
            47.03529,
            0
          ],
          [
            -116.67289,
            47.03492,
            0
          ],
          [
            -116.67291,
            47.03478,
            0
          ],
          [
            -116.67295,
            47.03465,
            0
          ],
          [
            -116.67305,
            47.03441,
            0
          ],
          [
            -116.67308,
            47.03435,
            0
          ],
          [
            -116.67317,
            47.03411,
            0
          ],
          [
            -116.67321,
            47.03407,
            0
          ],
          [
            -116.67328,
            47.03397,
            0
          ],
          [
            -116.67334,
            47.03391,
            0
          ],
          [
            -116.6734,
            47.03384,
            0
          ],
          [
            -116.67349,
            47.03376,
            0
          ],
          [
            -116.67351,
            47.03375,
            0
          ],
          [
            -116.67367,
            47.03363,
            0
          ],
          [
            -116.67375,
            47.03358,
            0
          ],
          [
            -116.67393,
            47.03345,
            0
          ],
          [
            -116.67405,
            47.03338,
            0
          ],
          [
            -116.6742,
            47.03328,
            0
          ],
          [
            -116.67423,
            47.03325,
            0
          ],
          [
            -116.6743,
            47.03321,
            0
          ],
          [
            -116.67438,
            47.03315,
            0
          ],
          [
            -116.67473,
            47.03284,
            0
          ],
          [
            -116.67493,
            47.03272,
            0
          ],
          [
            -116.67497,
            47.0327,
            0
          ],
          [
            -116.67518,
            47.03257,
            0
          ],
          [
            -116.67531,
            47.03247,
            0
          ],
          [
            -116.67539,
            47.03242,
            0
          ],
          [
            -116.67576,
            47.03213,
            0
          ],
          [
            -116.67607,
            47.03184,
            0
          ],
          [
            -116.6761,
            47.03182,
            0
          ],
          [
            -116.67643,
            47.03148,
            0
          ],
          [
            -116.67658,
            47.0313,
            0
          ],
          [
            -116.6767,
            47.03114,
            0
          ],
          [
            -116.67676,
            47.03108,
            0
          ],
          [
            -116.67678,
            47.03107,
            0
          ],
          [
            -116.67686,
            47.03096,
            0
          ],
          [
            -116.6772,
            47.03037,
            0
          ],
          [
            -116.67739,
            47.03009,
            0
          ],
          [
            -116.67767,
            47.02963,
            0
          ],
          [
            -116.6777,
            47.02959,
            0
          ],
          [
            -116.67816,
            47.02884,
            0
          ],
          [
            -116.67822,
            47.02877,
            0
          ],
          [
            -116.67824,
            47.02874,
            0
          ],
          [
            -116.67842,
            47.02853,
            0
          ],
          [
            -116.67852,
            47.02833,
            0
          ],
          [
            -116.67861,
            47.0281,
            0
          ],
          [
            -116.67868,
            47.02796,
            0
          ],
          [
            -116.67879,
            47.02771,
            0
          ],
          [
            -116.67885,
            47.02762,
            0
          ],
          [
            -116.67909,
            47.0273,
            0
          ],
          [
            -116.67912,
            47.02728,
            0
          ],
          [
            -116.67931,
            47.02693,
            0
          ],
          [
            -116.67935,
            47.02682,
            0
          ],
          [
            -116.6794,
            47.02657,
            0
          ],
          [
            -116.67944,
            47.02645,
            0
          ],
          [
            -116.67944,
            47.02644,
            0
          ],
          [
            -116.67947,
            47.02629,
            0
          ],
          [
            -116.67952,
            47.02618,
            0
          ],
          [
            -116.67957,
            47.02604,
            0
          ],
          [
            -116.67959,
            47.02594,
            0
          ],
          [
            -116.67959,
            47.02587,
            0
          ],
          [
            -116.67962,
            47.02574,
            0
          ],
          [
            -116.67962,
            47.02572,
            0
          ],
          [
            -116.67968,
            47.02552,
            0
          ],
          [
            -116.67972,
            47.02542,
            0
          ],
          [
            -116.67974,
            47.02532,
            0
          ],
          [
            -116.67976,
            47.02507,
            0
          ],
          [
            -116.67976,
            47.02461,
            0
          ],
          [
            -116.67978,
            47.02449,
            0
          ],
          [
            -116.67979,
            47.02436,
            0
          ],
          [
            -116.67979,
            47.02429,
            0
          ],
          [
            -116.67977,
            47.02414,
            0
          ],
          [
            -116.67976,
            47.02395,
            0
          ],
          [
            -116.67972,
            47.02359,
            0
          ],
          [
            -116.67969,
            47.02341,
            0
          ],
          [
            -116.67969,
            47.02334,
            0
          ],
          [
            -116.67965,
            47.02305,
            0
          ],
          [
            -116.67964,
            47.02302,
            0
          ],
          [
            -116.67962,
            47.02251,
            0
          ],
          [
            -116.67959,
            47.02222,
            0
          ],
          [
            -116.6796,
            47.02216,
            0
          ],
          [
            -116.67958,
            47.02198,
            0
          ],
          [
            -116.67957,
            47.02179,
            0
          ],
          [
            -116.67956,
            47.02175,
            0
          ],
          [
            -116.67956,
            47.02168,
            0
          ],
          [
            -116.67954,
            47.02143,
            0
          ],
          [
            -116.67953,
            47.02137,
            0
          ],
          [
            -116.67952,
            47.02126,
            0
          ],
          [
            -116.6795,
            47.02113,
            0
          ],
          [
            -116.67946,
            47.02094,
            0
          ],
          [
            -116.67945,
            47.02079,
            0
          ],
          [
            -116.67946,
            47.02065,
            0
          ],
          [
            -116.67945,
            47.02054,
            0
          ],
          [
            -116.67945,
            47.02029,
            0
          ],
          [
            -116.67943,
            47.02012,
            0
          ],
          [
            -116.67937,
            47.01928,
            0
          ],
          [
            -116.67935,
            47.0191,
            0
          ],
          [
            -116.67935,
            47.01906,
            0
          ],
          [
            -116.67929,
            47.01857,
            0
          ],
          [
            -116.67929,
            47.01849,
            0
          ],
          [
            -116.67924,
            47.01803,
            0
          ],
          [
            -116.67921,
            47.01749,
            0
          ],
          [
            -116.67912,
            47.0166,
            0
          ],
          [
            -116.6791,
            47.01623,
            0
          ],
          [
            -116.67909,
            47.01618,
            0
          ],
          [
            -116.67907,
            47.01587,
            0
          ],
          [
            -116.67905,
            47.01575,
            0
          ],
          [
            -116.67905,
            47.01569,
            0
          ],
          [
            -116.67903,
            47.01542,
            0
          ],
          [
            -116.67903,
            47.01533,
            0
          ],
          [
            -116.67902,
            47.0153,
            0
          ],
          [
            -116.679,
            47.01501,
            0
          ],
          [
            -116.67899,
            47.01497,
            0
          ],
          [
            -116.67894,
            47.01455,
            0
          ],
          [
            -116.67895,
            47.01439,
            0
          ],
          [
            -116.67893,
            47.01428,
            0
          ],
          [
            -116.67891,
            47.01368,
            0
          ],
          [
            -116.67889,
            47.01354,
            0
          ],
          [
            -116.67867,
            47.01095,
            0
          ],
          [
            -116.67862,
            47.01059,
            0
          ],
          [
            -116.6785,
            47.01,
            0
          ],
          [
            -116.67845,
            47.00982,
            0
          ],
          [
            -116.67845,
            47.00979,
            0
          ],
          [
            -116.67841,
            47.00966,
            0
          ],
          [
            -116.67841,
            47.00965,
            0
          ],
          [
            -116.67836,
            47.00947,
            0
          ],
          [
            -116.67818,
            47.00891,
            0
          ],
          [
            -116.67813,
            47.00878,
            0
          ],
          [
            -116.67813,
            47.00877,
            0
          ],
          [
            -116.67811,
            47.00874,
            0
          ],
          [
            -116.67794,
            47.00831,
            0
          ],
          [
            -116.67782,
            47.00805,
            0
          ],
          [
            -116.67775,
            47.00787,
            0
          ],
          [
            -116.67731,
            47.00687,
            0
          ],
          [
            -116.6767,
            47.00533,
            0
          ],
          [
            -116.67642,
            47.0047,
            0
          ],
          [
            -116.67638,
            47.00459,
            0
          ],
          [
            -116.67573,
            47.00303,
            0
          ],
          [
            -116.6757,
            47.00297,
            0
          ],
          [
            -116.67548,
            47.00242,
            0
          ],
          [
            -116.67545,
            47.00237,
            0
          ],
          [
            -116.67521,
            47.00185,
            0
          ],
          [
            -116.67519,
            47.00183,
            0
          ],
          [
            -116.67518,
            47.00179,
            0
          ],
          [
            -116.67505,
            47.00155,
            0
          ],
          [
            -116.67493,
            47.00136,
            0
          ],
          [
            -116.67481,
            47.00119,
            0
          ],
          [
            -116.6748,
            47.00117,
            0
          ],
          [
            -116.6748,
            47.00116,
            0
          ],
          [
            -116.67469,
            47.00103,
            0
          ],
          [
            -116.67435,
            47.00059,
            0
          ],
          [
            -116.67429,
            47.00053,
            0
          ],
          [
            -116.67418,
            47.0004,
            0
          ],
          [
            -116.67411,
            47.00034,
            0
          ],
          [
            -116.6739,
            47.00013,
            0
          ],
          [
            -116.67353,
            46.99982,
            0
          ],
          [
            -116.67315,
            46.99953,
            0
          ],
          [
            -116.67285,
            46.99934,
            0
          ],
          [
            -116.67283,
            46.99932,
            0
          ],
          [
            -116.67211,
            46.99887,
            0
          ],
          [
            -116.67206,
            46.99883,
            0
          ],
          [
            -116.67186,
            46.9987,
            0
          ],
          [
            -116.67151,
            46.99844,
            0
          ],
          [
            -116.67129,
            46.99826,
            0
          ],
          [
            -116.6711,
            46.99809,
            0
          ],
          [
            -116.67077,
            46.99774,
            0
          ],
          [
            -116.67075,
            46.99771,
            0
          ],
          [
            -116.67062,
            46.99755,
            0
          ],
          [
            -116.67059,
            46.99752,
            0
          ],
          [
            -116.67038,
            46.99723,
            0
          ],
          [
            -116.6702,
            46.99696,
            0
          ],
          [
            -116.67006,
            46.9967,
            0
          ],
          [
            -116.67,
            46.99656,
            0
          ],
          [
            -116.66997,
            46.99651,
            0
          ],
          [
            -116.66989,
            46.99631,
            0
          ],
          [
            -116.66986,
            46.99621,
            0
          ],
          [
            -116.66982,
            46.99604,
            0
          ],
          [
            -116.66982,
            46.99603,
            0
          ],
          [
            -116.66948,
            46.99462,
            0
          ],
          [
            -116.66934,
            46.9939,
            0
          ],
          [
            -116.66854,
            46.99037,
            0
          ],
          [
            -116.66853,
            46.99034,
            0
          ],
          [
            -116.66852,
            46.99027,
            0
          ],
          [
            -116.66812,
            46.9886,
            0
          ],
          [
            -116.66805,
            46.98817,
            0
          ],
          [
            -116.66804,
            46.98801,
            0
          ],
          [
            -116.66805,
            46.98786,
            0
          ],
          [
            -116.66805,
            46.98766,
            0
          ],
          [
            -116.66808,
            46.98749,
            0
          ],
          [
            -116.6682,
            46.98704,
            0
          ],
          [
            -116.66923,
            46.98458,
            0
          ],
          [
            -116.66924,
            46.98454,
            0
          ],
          [
            -116.66932,
            46.98437,
            0
          ],
          [
            -116.66987,
            46.98302,
            0
          ],
          [
            -116.66997,
            46.9827,
            0
          ],
          [
            -116.67,
            46.9825,
            0
          ],
          [
            -116.67001,
            46.98221,
            0
          ],
          [
            -116.66997,
            46.9819,
            0
          ],
          [
            -116.66991,
            46.98167,
            0
          ],
          [
            -116.66979,
            46.98137,
            0
          ],
          [
            -116.66962,
            46.98109,
            0
          ],
          [
            -116.66939,
            46.98078,
            0
          ],
          [
            -116.66768,
            46.97873,
            0
          ],
          [
            -116.66738,
            46.97831,
            0
          ],
          [
            -116.66733,
            46.97822,
            0
          ],
          [
            -116.66725,
            46.97804,
            0
          ],
          [
            -116.66718,
            46.97786,
            0
          ],
          [
            -116.66714,
            46.97769,
            0
          ],
          [
            -116.6671,
            46.97742,
            0
          ],
          [
            -116.6671,
            46.97728,
            0
          ],
          [
            -116.66712,
            46.97707,
            0
          ],
          [
            -116.66712,
            46.97696,
            0
          ],
          [
            -116.66826,
            46.96881,
            0
          ],
          [
            -116.6683,
            46.96833,
            0
          ],
          [
            -116.66836,
            46.96699,
            0
          ],
          [
            -116.66864,
            46.95507,
            0
          ],
          [
            -116.66865,
            46.95495,
            0
          ],
          [
            -116.66868,
            46.95475,
            0
          ],
          [
            -116.66869,
            46.95462,
            0
          ],
          [
            -116.66876,
            46.95435,
            0
          ],
          [
            -116.66881,
            46.95419,
            0
          ],
          [
            -116.66894,
            46.95388,
            0
          ],
          [
            -116.6691,
            46.95359,
            0
          ],
          [
            -116.66921,
            46.95343,
            0
          ],
          [
            -116.66943,
            46.95314,
            0
          ],
          [
            -116.6707,
            46.95174,
            0
          ],
          [
            -116.67079,
            46.95165,
            0
          ],
          [
            -116.67187,
            46.95047,
            0
          ],
          [
            -116.67266,
            46.94956,
            0
          ],
          [
            -116.67302,
            46.94919,
            0
          ],
          [
            -116.67435,
            46.94769,
            0
          ],
          [
            -116.6751,
            46.9469,
            0
          ],
          [
            -116.67524,
            46.94677,
            0
          ],
          [
            -116.67524,
            46.94676,
            0
          ],
          [
            -116.67525,
            46.94676,
            0
          ],
          [
            -116.6754,
            46.94662,
            0
          ],
          [
            -116.6756,
            46.94646,
            0
          ],
          [
            -116.67564,
            46.94642,
            0
          ],
          [
            -116.67594,
            46.9462,
            0
          ],
          [
            -116.67623,
            46.94601,
            0
          ],
          [
            -116.67631,
            46.94597,
            0
          ],
          [
            -116.67634,
            46.94595,
            0
          ],
          [
            -116.67713,
            46.94552,
            0
          ],
          [
            -116.67714,
            46.94552,
            0
          ],
          [
            -116.67716,
            46.94551,
            0
          ],
          [
            -116.67717,
            46.9455,
            0
          ],
          [
            -116.67822,
            46.94501,
            0
          ],
          [
            -116.67825,
            46.94499,
            0
          ],
          [
            -116.6808,
            46.9438,
            0
          ],
          [
            -116.68139,
            46.94354,
            0
          ],
          [
            -116.68312,
            46.94271,
            0
          ],
          [
            -116.68329,
            46.94264,
            0
          ],
          [
            -116.6855,
            46.94159,
            0
          ],
          [
            -116.68551,
            46.94158,
            0
          ],
          [
            -116.68571,
            46.94149,
            0
          ],
          [
            -116.68572,
            46.94148,
            0
          ],
          [
            -116.68615,
            46.94129,
            0
          ],
          [
            -116.68616,
            46.94128,
            0
          ],
          [
            -116.68663,
            46.94107,
            0
          ],
          [
            -116.68717,
            46.94077,
            0
          ],
          [
            -116.68742,
            46.94059,
            0
          ],
          [
            -116.68747,
            46.94056,
            0
          ],
          [
            -116.6876,
            46.94046,
            0
          ],
          [
            -116.68777,
            46.94035,
            0
          ],
          [
            -116.68778,
            46.94033,
            0
          ],
          [
            -116.69177,
            46.93746,
            0
          ],
          [
            -116.69232,
            46.9371,
            0
          ],
          [
            -116.69527,
            46.93497,
            0
          ],
          [
            -116.69842,
            46.93286,
            0
          ],
          [
            -116.70515,
            46.92847,
            0
          ],
          [
            -116.70613,
            46.92786,
            0
          ],
          [
            -116.70734,
            46.92704,
            0
          ],
          [
            -116.70999,
            46.92532,
            0
          ],
          [
            -116.71011,
            46.92525,
            0
          ],
          [
            -116.71247,
            46.92371,
            0
          ],
          [
            -116.71259,
            46.92364,
            0
          ],
          [
            -116.71297,
            46.92339,
            0
          ],
          [
            -116.71321,
            46.92325,
            0
          ],
          [
            -116.71355,
            46.92307,
            0
          ],
          [
            -116.71436,
            46.92268,
            0
          ],
          [
            -116.71527,
            46.92233,
            0
          ],
          [
            -116.71572,
            46.92218,
            0
          ],
          [
            -116.71656,
            46.92194,
            0
          ],
          [
            -116.71789,
            46.92166,
            0
          ],
          [
            -116.72036,
            46.92119,
            0
          ],
          [
            -116.72043,
            46.92117,
            0
          ],
          [
            -116.7208,
            46.92109,
            0
          ],
          [
            -116.72119,
            46.92099,
            0
          ],
          [
            -116.7217,
            46.92084,
            0
          ],
          [
            -116.72225,
            46.92065,
            0
          ],
          [
            -116.72367,
            46.92008,
            0
          ],
          [
            -116.72368,
            46.92008,
            0
          ],
          [
            -116.72602,
            46.91911,
            0
          ],
          [
            -116.72616,
            46.91906,
            0
          ],
          [
            -116.72684,
            46.91878,
            0
          ],
          [
            -116.72699,
            46.91871,
            0
          ],
          [
            -116.73011,
            46.91744,
            0
          ],
          [
            -116.73071,
            46.91722,
            0
          ],
          [
            -116.73125,
            46.91712,
            0
          ],
          [
            -116.73152,
            46.9171,
            0
          ],
          [
            -116.73201,
            46.9171,
            0
          ],
          [
            -116.73226,
            46.91712,
            0
          ],
          [
            -116.73821,
            46.91808,
            0
          ],
          [
            -116.74334,
            46.91902,
            0
          ],
          [
            -116.74347,
            46.91905,
            0
          ],
          [
            -116.74567,
            46.91946,
            0
          ],
          [
            -116.74576,
            46.91947,
            0
          ],
          [
            -116.74856,
            46.91998,
            0
          ],
          [
            -116.74864,
            46.91999,
            0
          ],
          [
            -116.75859,
            46.92183,
            0
          ],
          [
            -116.75976,
            46.92197,
            0
          ],
          [
            -116.76047,
            46.92201,
            0
          ],
          [
            -116.76056,
            46.92202,
            0
          ],
          [
            -116.76096,
            46.92203,
            0
          ],
          [
            -116.76189,
            46.92202,
            0
          ],
          [
            -116.76292,
            46.92196,
            0
          ],
          [
            -116.77168,
            46.92125,
            0
          ],
          [
            -116.77243,
            46.92122,
            0
          ],
          [
            -116.78637,
            46.92119,
            0
          ],
          [
            -116.7866,
            46.9212,
            0
          ],
          [
            -116.78678,
            46.92119,
            0
          ],
          [
            -116.79244,
            46.92118,
            0
          ],
          [
            -116.79279,
            46.92116,
            0
          ],
          [
            -116.79288,
            46.92116,
            0
          ],
          [
            -116.79335,
            46.92112,
            0
          ],
          [
            -116.79365,
            46.92108,
            0
          ],
          [
            -116.79378,
            46.92105,
            0
          ],
          [
            -116.79396,
            46.92102,
            0
          ],
          [
            -116.79433,
            46.92094,
            0
          ],
          [
            -116.79445,
            46.9209,
            0
          ],
          [
            -116.79462,
            46.92086,
            0
          ],
          [
            -116.79472,
            46.92082,
            0
          ],
          [
            -116.79486,
            46.92078,
            0
          ],
          [
            -116.79536,
            46.92059,
            0
          ],
          [
            -116.79572,
            46.92042,
            0
          ],
          [
            -116.79578,
            46.9204,
            0
          ],
          [
            -116.79598,
            46.92029,
            0
          ],
          [
            -116.79631,
            46.92009,
            0
          ],
          [
            -116.79845,
            46.91855,
            0
          ],
          [
            -116.7986,
            46.91845,
            0
          ],
          [
            -116.7989,
            46.91823,
            0
          ],
          [
            -116.79927,
            46.918,
            0
          ],
          [
            -116.79971,
            46.91776,
            0
          ],
          [
            -116.80019,
            46.91756,
            0
          ],
          [
            -116.8004,
            46.91749,
            0
          ],
          [
            -116.80047,
            46.91746,
            0
          ],
          [
            -116.80093,
            46.91731,
            0
          ],
          [
            -116.80139,
            46.91719,
            0
          ],
          [
            -116.80159,
            46.91715,
            0
          ],
          [
            -116.80161,
            46.91715,
            0
          ],
          [
            -116.80208,
            46.91706,
            0
          ],
          [
            -116.8026,
            46.917,
            0
          ],
          [
            -116.80334,
            46.91696,
            0
          ],
          [
            -116.80417,
            46.91699,
            0
          ],
          [
            -116.80468,
            46.91704,
            0
          ],
          [
            -116.80517,
            46.91712,
            0
          ],
          [
            -116.80572,
            46.91724,
            0
          ],
          [
            -116.80889,
            46.91811,
            0
          ],
          [
            -116.80914,
            46.91816,
            0
          ],
          [
            -116.80964,
            46.91822,
            0
          ],
          [
            -116.80975,
            46.91824,
            0
          ],
          [
            -116.81001,
            46.91825,
            0
          ],
          [
            -116.81062,
            46.91825,
            0
          ],
          [
            -116.81076,
            46.91824,
            0
          ],
          [
            -116.81148,
            46.91814,
            0
          ],
          [
            -116.81166,
            46.9181,
            0
          ],
          [
            -116.81219,
            46.91794,
            0
          ],
          [
            -116.81278,
            46.91769,
            0
          ],
          [
            -116.81349,
            46.91734,
            0
          ],
          [
            -116.81354,
            46.91732,
            0
          ],
          [
            -116.81396,
            46.91711,
            0
          ],
          [
            -116.81403,
            46.91707,
            0
          ],
          [
            -116.8149,
            46.91666,
            0
          ],
          [
            -116.81512,
            46.91657,
            0
          ],
          [
            -116.81564,
            46.91638,
            0
          ],
          [
            -116.81594,
            46.91629,
            0
          ],
          [
            -116.81599,
            46.91628,
            0
          ],
          [
            -116.81659,
            46.9161,
            0
          ],
          [
            -116.81671,
            46.91605,
            0
          ],
          [
            -116.81681,
            46.91602,
            0
          ],
          [
            -116.817,
            46.91595,
            0
          ],
          [
            -116.81743,
            46.91576,
            0
          ],
          [
            -116.81778,
            46.91559,
            0
          ],
          [
            -116.81855,
            46.91517,
            0
          ],
          [
            -116.81865,
            46.9151,
            0
          ],
          [
            -116.81879,
            46.91502,
            0
          ],
          [
            -116.81912,
            46.91486,
            0
          ],
          [
            -116.81936,
            46.91476,
            0
          ],
          [
            -116.81985,
            46.91458,
            0
          ],
          [
            -116.82029,
            46.91444,
            0
          ],
          [
            -116.82051,
            46.91438,
            0
          ],
          [
            -116.82091,
            46.9143,
            0
          ],
          [
            -116.82125,
            46.91425,
            0
          ],
          [
            -116.82162,
            46.91422,
            0
          ],
          [
            -116.82562,
            46.91413,
            0
          ],
          [
            -116.82843,
            46.91402,
            0
          ],
          [
            -116.83056,
            46.9139,
            0
          ],
          [
            -116.83075,
            46.91388,
            0
          ],
          [
            -116.83253,
            46.91377,
            0
          ],
          [
            -116.83352,
            46.91373,
            0
          ],
          [
            -116.83659,
            46.91372,
            0
          ],
          [
            -116.84113,
            46.91376,
            0
          ],
          [
            -116.84183,
            46.91379,
            0
          ],
          [
            -116.84276,
            46.9139,
            0
          ],
          [
            -116.84527,
            46.91425,
            0
          ],
          [
            -116.84635,
            46.91427,
            0
          ],
          [
            -116.85014,
            46.91426,
            0
          ],
          [
            -116.85053,
            46.91428,
            0
          ],
          [
            -116.85088,
            46.91433,
            0
          ],
          [
            -116.85131,
            46.91443,
            0
          ],
          [
            -116.86133,
            46.91767,
            0
          ],
          [
            -116.86141,
            46.91769,
            0
          ],
          [
            -116.86514,
            46.9189,
            0
          ],
          [
            -116.86545,
            46.91901,
            0
          ],
          [
            -116.87127,
            46.92089,
            0
          ],
          [
            -116.87132,
            46.9209,
            0
          ],
          [
            -116.87212,
            46.92117,
            0
          ],
          [
            -116.87227,
            46.92121,
            0
          ],
          [
            -116.8723,
            46.92123,
            0
          ],
          [
            -116.87253,
            46.92129,
            0
          ],
          [
            -116.87311,
            46.92141,
            0
          ],
          [
            -116.87802,
            46.92205,
            0
          ],
          [
            -116.87821,
            46.92206,
            0
          ],
          [
            -116.87851,
            46.92209,
            0
          ],
          [
            -116.87881,
            46.9221,
            0
          ],
          [
            -116.87935,
            46.9221,
            0
          ],
          [
            -116.87983,
            46.92205,
            0
          ],
          [
            -116.87986,
            46.92205,
            0
          ],
          [
            -116.88012,
            46.92202,
            0
          ],
          [
            -116.88021,
            46.922,
            0
          ],
          [
            -116.88051,
            46.92196,
            0
          ],
          [
            -116.88088,
            46.92188,
            0
          ],
          [
            -116.88136,
            46.92174,
            0
          ],
          [
            -116.88226,
            46.9214,
            0
          ],
          [
            -116.88275,
            46.92124,
            0
          ],
          [
            -116.88338,
            46.92106,
            0
          ],
          [
            -116.8837,
            46.92098,
            0
          ],
          [
            -116.88419,
            46.92089,
            0
          ],
          [
            -116.88484,
            46.92083,
            0
          ],
          [
            -116.88563,
            46.92083,
            0
          ],
          [
            -116.88585,
            46.92084,
            0
          ],
          [
            -116.88663,
            46.92093,
            0
          ],
          [
            -116.88688,
            46.92098,
            0
          ],
          [
            -116.88787,
            46.92127,
            0
          ],
          [
            -116.88826,
            46.92136,
            0
          ],
          [
            -116.88864,
            46.92142,
            0
          ],
          [
            -116.88884,
            46.92144,
            0
          ],
          [
            -116.88894,
            46.92146,
            0
          ],
          [
            -116.88993,
            46.92152,
            0
          ],
          [
            -116.89055,
            46.92153,
            0
          ],
          [
            -116.89064,
            46.92154,
            0
          ],
          [
            -116.89822,
            46.9216,
            0
          ]
        ],
			},
			id: "86cd5e29-e6eb-4279-b976-7e5546c42965",
		},
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.3294268,
    //       47.5487614,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Cataldo, ID"
    //   },
    //   id: "56800a96-3c4d-4b08-9ddc-49ae6ef643bb"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.7854481,
    //       47.4542231,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Harrison, ID"
    //   },
    //   id: "ffd72ec8-9fa4-4863-bddf-783c46f4aa7b"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.5624701,
    //       47.3143529,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "St Maries, ID"
    //   },
    //   id: "4a0d68f6-f978-4b40-a92a-cfae6e0c7408"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.8982187,
    //       46.9216039,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Potlatch, ID"
    //   },
    //   id: "312bbf9e-9710-48cc-9e9a-4afc982ccb20"
    // }
	],
};

export default WhitePine;
