import { createReducer } from "@reduxjs/toolkit";
import { updateDirections, updateRoutes, updateStartingPoint, updateSummary, updateFinishingPoint, updateStartAddress, updateFinishAddress, updateCustomEndPoint } from "../actions/routesActions";

const initialState = {
    route: [],
    directions: [],
    summary: [],
    startingPoint: ['43.6150', '-116.2023'],
    finishingPoint: [],
    startAddress: 'Boise, ID, USA',
    finishAddress: '',
    customEndPoint: false
}

const routesReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(updateDirections, (state, action) => {
            state.directions = action.payload;
        })
        .addCase(updateRoutes, (state, action) => {
            state.route = action.payload;
        })
        .addCase(updateStartingPoint, (state, action) => {
            state.startingPoint = action.payload;
        })
        .addCase(updateFinishingPoint, (state, action) => {
            state.finishingPoint = action.payload;
        })
        .addCase(updateStartAddress, (state, action) => {
            state.startAddress = action.payload;
        })
        .addCase(updateFinishAddress, (state, action) => {
            state.finishAddress = action.payload;
        })
        .addCase(updateCustomEndPoint, (state, action) => {
            state.customEndPoint = action.payload;
        })
        .addCase(updateSummary, (state, action) => {
            state.summary = action.payload
        })
});

export default routesReducer;