const GoldRushHistoric = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Gold Rush Historic Byway",
				description: "Steeped in mining history with an abundance of scenic views, the Gold Rush Historic Byway showcases the beauty of north central Idaho. On this route, travelers will find panoramic views of the Clearwater Valley, resplendent rolling fields of grain and flourishing forests full of wildlife. Spend time in Pierce, where gold was first found in Idaho, or visit historical sites of the Nez Perce and Lewis and Clark. There's no shortage of history on this byway.",
				link: "https://visitidaho.org/things-to-do/road-trips/gold-rush-historic-byway/",
				route: [
					"Greer", 
					"Weippe", 
					"Pierce",
          "Headquarters"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
          [
            -116.17519,
            46.38988,
            0
          ],
          [
            -116.17516,
            46.38982,
            0
          ],
          [
            -116.17461,
            46.38901,
            0
          ],
          [
            -116.17461,
            46.389,
            0
          ],
          [
            -116.17451,
            46.38884,
            0
          ],
          [
            -116.17448,
            46.38878,
            0
          ],
          [
            -116.17443,
            46.3887,
            0
          ],
          [
            -116.17435,
            46.38846,
            0
          ],
          [
            -116.17434,
            46.38835,
            0
          ],
          [
            -116.17434,
            46.38824,
            0
          ],
          [
            -116.1744,
            46.38803,
            0
          ],
          [
            -116.17454,
            46.38771,
            0
          ],
          [
            -116.17457,
            46.38762,
            0
          ],
          [
            -116.17463,
            46.38748,
            0
          ],
          [
            -116.17463,
            46.38744,
            0
          ],
          [
            -116.17465,
            46.38733,
            0
          ],
          [
            -116.17464,
            46.38711,
            0
          ],
          [
            -116.17463,
            46.38709,
            0
          ],
          [
            -116.17459,
            46.38691,
            0
          ],
          [
            -116.17457,
            46.38675,
            0
          ],
          [
            -116.17457,
            46.38666,
            0
          ],
          [
            -116.17453,
            46.38639,
            0
          ],
          [
            -116.17454,
            46.38612,
            0
          ],
          [
            -116.17458,
            46.38571,
            0
          ],
          [
            -116.17463,
            46.38536,
            0
          ],
          [
            -116.17464,
            46.38496,
            0
          ],
          [
            -116.17461,
            46.38465,
            0
          ],
          [
            -116.1746,
            46.38437,
            0
          ],
          [
            -116.17463,
            46.38416,
            0
          ],
          [
            -116.17468,
            46.38404,
            0
          ],
          [
            -116.17481,
            46.38381,
            0
          ],
          [
            -116.17512,
            46.38343,
            0
          ],
          [
            -116.17524,
            46.38326,
            0
          ],
          [
            -116.17529,
            46.3832,
            0
          ],
          [
            -116.17535,
            46.3831,
            0
          ],
          [
            -116.17535,
            46.38309,
            0
          ],
          [
            -116.1754,
            46.38299,
            0
          ],
          [
            -116.17546,
            46.38275,
            0
          ],
          [
            -116.17554,
            46.38184,
            0
          ],
          [
            -116.17565,
            46.38106,
            0
          ],
          [
            -116.17564,
            46.3809,
            0
          ],
          [
            -116.17559,
            46.38078,
            0
          ],
          [
            -116.17553,
            46.3807,
            0
          ],
          [
            -116.17545,
            46.38062,
            0
          ],
          [
            -116.17529,
            46.38049,
            0
          ],
          [
            -116.17516,
            46.3804,
            0
          ],
          [
            -116.17503,
            46.38029,
            0
          ],
          [
            -116.17495,
            46.3802,
            0
          ],
          [
            -116.17488,
            46.38014,
            0
          ],
          [
            -116.17481,
            46.37995,
            0
          ],
          [
            -116.1748,
            46.37987,
            0
          ],
          [
            -116.17475,
            46.37963,
            0
          ],
          [
            -116.17468,
            46.37949,
            0
          ],
          [
            -116.17466,
            46.37946,
            0
          ],
          [
            -116.17453,
            46.37933,
            0
          ],
          [
            -116.17435,
            46.3792,
            0
          ],
          [
            -116.17369,
            46.37893,
            0
          ],
          [
            -116.17349,
            46.37886,
            0
          ],
          [
            -116.17317,
            46.37871,
            0
          ],
          [
            -116.17283,
            46.37848,
            0
          ],
          [
            -116.17244,
            46.37816,
            0
          ],
          [
            -116.17176,
            46.37766,
            0
          ],
          [
            -116.17156,
            46.37744,
            0
          ],
          [
            -116.17148,
            46.37733,
            0
          ],
          [
            -116.17146,
            46.37725,
            0
          ],
          [
            -116.17142,
            46.37715,
            0
          ],
          [
            -116.17141,
            46.37707,
            0
          ],
          [
            -116.17145,
            46.3767,
            0
          ],
          [
            -116.1714,
            46.37653,
            0
          ],
          [
            -116.17137,
            46.37646,
            0
          ],
          [
            -116.17132,
            46.37639,
            0
          ],
          [
            -116.17124,
            46.37634,
            0
          ],
          [
            -116.17117,
            46.37631,
            0
          ],
          [
            -116.17098,
            46.37627,
            0
          ],
          [
            -116.17088,
            46.37626,
            0
          ],
          [
            -116.17076,
            46.37626,
            0
          ],
          [
            -116.17066,
            46.37628,
            0
          ],
          [
            -116.17058,
            46.37631,
            0
          ],
          [
            -116.1705,
            46.37635,
            0
          ],
          [
            -116.17046,
            46.37638,
            0
          ],
          [
            -116.17039,
            46.37645,
            0
          ],
          [
            -116.17036,
            46.37656,
            0
          ],
          [
            -116.17036,
            46.37666,
            0
          ],
          [
            -116.17037,
            46.37673,
            0
          ],
          [
            -116.17098,
            46.37807,
            0
          ],
          [
            -116.17113,
            46.37827,
            0
          ],
          [
            -116.1712,
            46.37834,
            0
          ],
          [
            -116.17154,
            46.3786,
            0
          ],
          [
            -116.17173,
            46.37882,
            0
          ],
          [
            -116.17182,
            46.37897,
            0
          ],
          [
            -116.17189,
            46.37916,
            0
          ],
          [
            -116.17207,
            46.37947,
            0
          ],
          [
            -116.17227,
            46.37964,
            0
          ],
          [
            -116.17239,
            46.37973,
            0
          ],
          [
            -116.17249,
            46.37979,
            0
          ],
          [
            -116.17263,
            46.37991,
            0
          ],
          [
            -116.17268,
            46.37997,
            0
          ],
          [
            -116.17276,
            46.38009,
            0
          ],
          [
            -116.17281,
            46.38019,
            0
          ],
          [
            -116.17285,
            46.38033,
            0
          ],
          [
            -116.17286,
            46.38042,
            0
          ],
          [
            -116.17287,
            46.38072,
            0
          ],
          [
            -116.17286,
            46.38102,
            0
          ],
          [
            -116.17295,
            46.38127,
            0
          ],
          [
            -116.17308,
            46.38147,
            0
          ],
          [
            -116.17322,
            46.38165,
            0
          ],
          [
            -116.17334,
            46.38185,
            0
          ],
          [
            -116.17339,
            46.38201,
            0
          ],
          [
            -116.17343,
            46.38218,
            0
          ],
          [
            -116.17342,
            46.38232,
            0
          ],
          [
            -116.17339,
            46.38246,
            0
          ],
          [
            -116.17332,
            46.38261,
            0
          ],
          [
            -116.1732,
            46.38281,
            0
          ],
          [
            -116.17297,
            46.38301,
            0
          ],
          [
            -116.17257,
            46.38329,
            0
          ],
          [
            -116.17238,
            46.38346,
            0
          ],
          [
            -116.17231,
            46.3836,
            0
          ],
          [
            -116.17228,
            46.38369,
            0
          ],
          [
            -116.17214,
            46.38396,
            0
          ],
          [
            -116.17203,
            46.3841,
            0
          ],
          [
            -116.17187,
            46.3842,
            0
          ],
          [
            -116.17178,
            46.38424,
            0
          ],
          [
            -116.17163,
            46.38429,
            0
          ],
          [
            -116.17148,
            46.38432,
            0
          ],
          [
            -116.17119,
            46.38432,
            0
          ],
          [
            -116.17093,
            46.38429,
            0
          ],
          [
            -116.17031,
            46.38419,
            0
          ],
          [
            -116.17,
            46.38418,
            0
          ],
          [
            -116.16986,
            46.3842,
            0
          ],
          [
            -116.16966,
            46.38425,
            0
          ],
          [
            -116.16939,
            46.38436,
            0
          ],
          [
            -116.16926,
            46.38445,
            0
          ],
          [
            -116.16915,
            46.38456,
            0
          ],
          [
            -116.16909,
            46.38464,
            0
          ],
          [
            -116.16904,
            46.38474,
            0
          ],
          [
            -116.16901,
            46.38483,
            0
          ],
          [
            -116.16899,
            46.38492,
            0
          ],
          [
            -116.16899,
            46.38499,
            0
          ],
          [
            -116.169,
            46.38504,
            0
          ],
          [
            -116.16913,
            46.3854,
            0
          ],
          [
            -116.16914,
            46.38553,
            0
          ],
          [
            -116.16914,
            46.3857,
            0
          ],
          [
            -116.1691,
            46.38583,
            0
          ],
          [
            -116.16888,
            46.38621,
            0
          ],
          [
            -116.16888,
            46.38622,
            0
          ],
          [
            -116.16875,
            46.3864,
            0
          ],
          [
            -116.16852,
            46.38668,
            0
          ],
          [
            -116.16831,
            46.38686,
            0
          ],
          [
            -116.16817,
            46.38695,
            0
          ],
          [
            -116.16788,
            46.38711,
            0
          ],
          [
            -116.16777,
            46.38719,
            0
          ],
          [
            -116.16765,
            46.3873,
            0
          ],
          [
            -116.16758,
            46.3874,
            0
          ],
          [
            -116.16756,
            46.38751,
            0
          ],
          [
            -116.16755,
            46.38754,
            0
          ],
          [
            -116.16755,
            46.38767,
            0
          ],
          [
            -116.16759,
            46.38777,
            0
          ],
          [
            -116.16764,
            46.38787,
            0
          ],
          [
            -116.16784,
            46.38802,
            0
          ],
          [
            -116.16806,
            46.38814,
            0
          ],
          [
            -116.1682,
            46.38823,
            0
          ],
          [
            -116.16843,
            46.38835,
            0
          ],
          [
            -116.16852,
            46.38843,
            0
          ],
          [
            -116.16872,
            46.38868,
            0
          ],
          [
            -116.16875,
            46.38875,
            0
          ],
          [
            -116.16881,
            46.38899,
            0
          ],
          [
            -116.16883,
            46.38917,
            0
          ],
          [
            -116.16883,
            46.38942,
            0
          ],
          [
            -116.16882,
            46.38946,
            0
          ],
          [
            -116.16878,
            46.38952,
            0
          ],
          [
            -116.16877,
            46.38952,
            0
          ],
          [
            -116.16871,
            46.38956,
            0
          ],
          [
            -116.1686,
            46.38958,
            0
          ],
          [
            -116.16856,
            46.38958,
            0
          ],
          [
            -116.16852,
            46.38957,
            0
          ],
          [
            -116.16847,
            46.38955,
            0
          ],
          [
            -116.16844,
            46.38952,
            0
          ],
          [
            -116.16838,
            46.38935,
            0
          ],
          [
            -116.16831,
            46.38908,
            0
          ],
          [
            -116.16831,
            46.38907,
            0
          ],
          [
            -116.16821,
            46.3889,
            0
          ],
          [
            -116.16806,
            46.38875,
            0
          ],
          [
            -116.1674,
            46.38839,
            0
          ],
          [
            -116.16696,
            46.3882,
            0
          ],
          [
            -116.16671,
            46.38799,
            0
          ],
          [
            -116.16661,
            46.38778,
            0
          ],
          [
            -116.1666,
            46.38767,
            0
          ],
          [
            -116.16665,
            46.38755,
            0
          ],
          [
            -116.16709,
            46.38696,
            0
          ],
          [
            -116.16723,
            46.38675,
            0
          ],
          [
            -116.1673,
            46.38653,
            0
          ],
          [
            -116.16733,
            46.38631,
            0
          ],
          [
            -116.1673,
            46.38609,
            0
          ],
          [
            -116.16722,
            46.38588,
            0
          ],
          [
            -116.16684,
            46.38543,
            0
          ],
          [
            -116.16674,
            46.38529,
            0
          ],
          [
            -116.16668,
            46.38509,
            0
          ],
          [
            -116.1667,
            46.38493,
            0
          ],
          [
            -116.16693,
            46.3843,
            0
          ],
          [
            -116.16709,
            46.384,
            0
          ],
          [
            -116.16726,
            46.38381,
            0
          ],
          [
            -116.16736,
            46.38365,
            0
          ],
          [
            -116.16743,
            46.38342,
            0
          ],
          [
            -116.16745,
            46.3833,
            0
          ],
          [
            -116.1675,
            46.38261,
            0
          ],
          [
            -116.16762,
            46.38233,
            0
          ],
          [
            -116.16771,
            46.38224,
            0
          ],
          [
            -116.16786,
            46.38212,
            0
          ],
          [
            -116.16804,
            46.38205,
            0
          ],
          [
            -116.16825,
            46.382,
            0
          ],
          [
            -116.16858,
            46.38199,
            0
          ],
          [
            -116.16862,
            46.382,
            0
          ],
          [
            -116.16871,
            46.38201,
            0
          ],
          [
            -116.16881,
            46.38203,
            0
          ],
          [
            -116.16912,
            46.38214,
            0
          ],
          [
            -116.16938,
            46.3822,
            0
          ],
          [
            -116.16957,
            46.3822,
            0
          ],
          [
            -116.1697,
            46.38217,
            0
          ],
          [
            -116.16985,
            46.3821,
            0
          ],
          [
            -116.16992,
            46.38205,
            0
          ],
          [
            -116.16999,
            46.38198,
            0
          ],
          [
            -116.17004,
            46.38185,
            0
          ],
          [
            -116.17004,
            46.38171,
            0
          ],
          [
            -116.16995,
            46.38149,
            0
          ],
          [
            -116.16988,
            46.38137,
            0
          ],
          [
            -116.16969,
            46.38113,
            0
          ],
          [
            -116.16905,
            46.3804,
            0
          ],
          [
            -116.1689,
            46.38026,
            0
          ],
          [
            -116.16867,
            46.38009,
            0
          ],
          [
            -116.16839,
            46.37995,
            0
          ],
          [
            -116.16821,
            46.37993,
            0
          ],
          [
            -116.16819,
            46.37992,
            0
          ],
          [
            -116.16793,
            46.37993,
            0
          ],
          [
            -116.16776,
            46.37998,
            0
          ],
          [
            -116.16757,
            46.38009,
            0
          ],
          [
            -116.16737,
            46.38028,
            0
          ],
          [
            -116.16731,
            46.38035,
            0
          ],
          [
            -116.16716,
            46.3806,
            0
          ],
          [
            -116.16714,
            46.38061,
            0
          ],
          [
            -116.16702,
            46.38094,
            0
          ],
          [
            -116.16697,
            46.38113,
            0
          ],
          [
            -116.16688,
            46.38164,
            0
          ],
          [
            -116.16681,
            46.38179,
            0
          ],
          [
            -116.16675,
            46.38189,
            0
          ],
          [
            -116.16609,
            46.38281,
            0
          ],
          [
            -116.16587,
            46.38321,
            0
          ],
          [
            -116.16506,
            46.38448,
            0
          ],
          [
            -116.16499,
            46.38464,
            0
          ],
          [
            -116.16488,
            46.38506,
            0
          ],
          [
            -116.1647,
            46.3856,
            0
          ],
          [
            -116.16469,
            46.38565,
            0
          ],
          [
            -116.16471,
            46.38577,
            0
          ],
          [
            -116.16475,
            46.3859,
            0
          ],
          [
            -116.16477,
            46.38594,
            0
          ],
          [
            -116.16497,
            46.38623,
            0
          ],
          [
            -116.16506,
            46.38643,
            0
          ],
          [
            -116.16507,
            46.38644,
            0
          ],
          [
            -116.16509,
            46.38654,
            0
          ],
          [
            -116.16509,
            46.38662,
            0
          ],
          [
            -116.1651,
            46.3867,
            0
          ],
          [
            -116.16508,
            46.3868,
            0
          ],
          [
            -116.16503,
            46.38695,
            0
          ],
          [
            -116.16489,
            46.38724,
            0
          ],
          [
            -116.16476,
            46.38741,
            0
          ],
          [
            -116.16462,
            46.38752,
            0
          ],
          [
            -116.16425,
            46.38766,
            0
          ],
          [
            -116.16402,
            46.38771,
            0
          ],
          [
            -116.16401,
            46.38772,
            0
          ],
          [
            -116.16386,
            46.38776,
            0
          ],
          [
            -116.16358,
            46.3879,
            0
          ],
          [
            -116.16342,
            46.38802,
            0
          ],
          [
            -116.16341,
            46.38804,
            0
          ],
          [
            -116.16333,
            46.38813,
            0
          ],
          [
            -116.16328,
            46.38828,
            0
          ],
          [
            -116.16326,
            46.38838,
            0
          ],
          [
            -116.16326,
            46.38842,
            0
          ],
          [
            -116.16331,
            46.38861,
            0
          ],
          [
            -116.16339,
            46.38874,
            0
          ],
          [
            -116.16348,
            46.38883,
            0
          ],
          [
            -116.16375,
            46.38901,
            0
          ],
          [
            -116.1643,
            46.38931,
            0
          ],
          [
            -116.16451,
            46.38946,
            0
          ],
          [
            -116.16461,
            46.38957,
            0
          ],
          [
            -116.16474,
            46.3898,
            0
          ],
          [
            -116.16489,
            46.39013,
            0
          ],
          [
            -116.16491,
            46.39016,
            0
          ],
          [
            -116.16516,
            46.39065,
            0
          ],
          [
            -116.16517,
            46.39081,
            0
          ],
          [
            -116.16519,
            46.39092,
            0
          ],
          [
            -116.16518,
            46.39099,
            0
          ],
          [
            -116.16517,
            46.39116,
            0
          ],
          [
            -116.1652,
            46.39141,
            0
          ],
          [
            -116.16528,
            46.39156,
            0
          ],
          [
            -116.16537,
            46.39168,
            0
          ],
          [
            -116.16552,
            46.39183,
            0
          ],
          [
            -116.16559,
            46.39189,
            0
          ],
          [
            -116.1657,
            46.39205,
            0
          ],
          [
            -116.16575,
            46.39215,
            0
          ],
          [
            -116.16582,
            46.39238,
            0
          ],
          [
            -116.16583,
            46.39251,
            0
          ],
          [
            -116.16583,
            46.39273,
            0
          ],
          [
            -116.16585,
            46.39302,
            0
          ],
          [
            -116.16594,
            46.39328,
            0
          ],
          [
            -116.16604,
            46.39364,
            0
          ],
          [
            -116.16611,
            46.39399,
            0
          ],
          [
            -116.16611,
            46.39401,
            0
          ],
          [
            -116.16615,
            46.39417,
            0
          ],
          [
            -116.16618,
            46.39437,
            0
          ],
          [
            -116.16632,
            46.39501,
            0
          ],
          [
            -116.16654,
            46.39566,
            0
          ],
          [
            -116.16655,
            46.39582,
            0
          ],
          [
            -116.16654,
            46.39588,
            0
          ],
          [
            -116.16652,
            46.39595,
            0
          ],
          [
            -116.16652,
            46.39596,
            0
          ],
          [
            -116.16648,
            46.39601,
            0
          ],
          [
            -116.16641,
            46.39607,
            0
          ],
          [
            -116.16633,
            46.39609,
            0
          ],
          [
            -116.16624,
            46.39609,
            0
          ],
          [
            -116.16616,
            46.39607,
            0
          ],
          [
            -116.16614,
            46.39606,
            0
          ],
          [
            -116.16611,
            46.39602,
            0
          ],
          [
            -116.16609,
            46.39596,
            0
          ],
          [
            -116.16609,
            46.39561,
            0
          ],
          [
            -116.16608,
            46.39553,
            0
          ],
          [
            -116.16604,
            46.3954,
            0
          ],
          [
            -116.166,
            46.39531,
            0
          ],
          [
            -116.16561,
            46.39475,
            0
          ],
          [
            -116.16534,
            46.3943,
            0
          ],
          [
            -116.16505,
            46.39355,
            0
          ],
          [
            -116.16505,
            46.39354,
            0
          ],
          [
            -116.16486,
            46.39304,
            0
          ],
          [
            -116.16481,
            46.39296,
            0
          ],
          [
            -116.16478,
            46.39288,
            0
          ],
          [
            -116.16476,
            46.39286,
            0
          ],
          [
            -116.16451,
            46.39239,
            0
          ],
          [
            -116.16435,
            46.39214,
            0
          ],
          [
            -116.16425,
            46.39201,
            0
          ],
          [
            -116.1641,
            46.39185,
            0
          ],
          [
            -116.16407,
            46.39181,
            0
          ],
          [
            -116.16399,
            46.39173,
            0
          ],
          [
            -116.16394,
            46.39167,
            0
          ],
          [
            -116.16392,
            46.39166,
            0
          ],
          [
            -116.16367,
            46.39141,
            0
          ],
          [
            -116.16356,
            46.39128,
            0
          ],
          [
            -116.16355,
            46.39126,
            0
          ],
          [
            -116.16343,
            46.39112,
            0
          ],
          [
            -116.16343,
            46.39111,
            0
          ],
          [
            -116.16338,
            46.39105,
            0
          ],
          [
            -116.16329,
            46.39088,
            0
          ],
          [
            -116.16328,
            46.39085,
            0
          ],
          [
            -116.16324,
            46.39078,
            0
          ],
          [
            -116.16323,
            46.39077,
            0
          ],
          [
            -116.16319,
            46.39071,
            0
          ],
          [
            -116.16306,
            46.39059,
            0
          ],
          [
            -116.16303,
            46.39058,
            0
          ],
          [
            -116.16295,
            46.39054,
            0
          ],
          [
            -116.16289,
            46.39052,
            0
          ],
          [
            -116.16284,
            46.39053,
            0
          ],
          [
            -116.16277,
            46.39053,
            0
          ],
          [
            -116.16268,
            46.39056,
            0
          ],
          [
            -116.16259,
            46.3906,
            0
          ],
          [
            -116.16257,
            46.39062,
            0
          ],
          [
            -116.1625,
            46.39071,
            0
          ],
          [
            -116.16249,
            46.39074,
            0
          ],
          [
            -116.1625,
            46.39079,
            0
          ],
          [
            -116.16256,
            46.3909,
            0
          ],
          [
            -116.16261,
            46.39094,
            0
          ],
          [
            -116.16275,
            46.3911,
            0
          ],
          [
            -116.16276,
            46.3911,
            0
          ],
          [
            -116.16289,
            46.39126,
            0
          ],
          [
            -116.16307,
            46.39151,
            0
          ],
          [
            -116.1631,
            46.39172,
            0
          ],
          [
            -116.1631,
            46.39193,
            0
          ],
          [
            -116.16307,
            46.39225,
            0
          ],
          [
            -116.1631,
            46.39247,
            0
          ],
          [
            -116.16313,
            46.39259,
            0
          ],
          [
            -116.16317,
            46.39269,
            0
          ],
          [
            -116.16323,
            46.39279,
            0
          ],
          [
            -116.16353,
            46.39315,
            0
          ],
          [
            -116.16357,
            46.39319,
            0
          ],
          [
            -116.16367,
            46.39333,
            0
          ],
          [
            -116.16369,
            46.39335,
            0
          ],
          [
            -116.16371,
            46.39338,
            0
          ],
          [
            -116.16379,
            46.39357,
            0
          ],
          [
            -116.1638,
            46.39363,
            0
          ],
          [
            -116.16376,
            46.39373,
            0
          ],
          [
            -116.16368,
            46.39386,
            0
          ],
          [
            -116.16368,
            46.39387,
            0
          ],
          [
            -116.16351,
            46.39401,
            0
          ],
          [
            -116.16334,
            46.39417,
            0
          ],
          [
            -116.1633,
            46.39422,
            0
          ],
          [
            -116.16322,
            46.39436,
            0
          ],
          [
            -116.1632,
            46.39449,
            0
          ],
          [
            -116.1632,
            46.39463,
            0
          ],
          [
            -116.16323,
            46.39477,
            0
          ],
          [
            -116.16333,
            46.39505,
            0
          ],
          [
            -116.16338,
            46.39533,
            0
          ],
          [
            -116.16338,
            46.39541,
            0
          ],
          [
            -116.16335,
            46.39559,
            0
          ],
          [
            -116.16326,
            46.39584,
            0
          ],
          [
            -116.1631,
            46.39617,
            0
          ],
          [
            -116.16299,
            46.39629,
            0
          ],
          [
            -116.16292,
            46.39633,
            0
          ],
          [
            -116.1628,
            46.39635,
            0
          ],
          [
            -116.16253,
            46.39629,
            0
          ],
          [
            -116.16239,
            46.3962,
            0
          ],
          [
            -116.16228,
            46.39608,
            0
          ],
          [
            -116.16178,
            46.39543,
            0
          ],
          [
            -116.16166,
            46.39524,
            0
          ],
          [
            -116.16158,
            46.39503,
            0
          ],
          [
            -116.16148,
            46.39484,
            0
          ],
          [
            -116.16134,
            46.39468,
            0
          ],
          [
            -116.16112,
            46.39448,
            0
          ],
          [
            -116.16099,
            46.39438,
            0
          ],
          [
            -116.16082,
            46.39427,
            0
          ],
          [
            -116.1605,
            46.39399,
            0
          ],
          [
            -116.15975,
            46.39298,
            0
          ],
          [
            -116.15975,
            46.39297,
            0
          ],
          [
            -116.159,
            46.39176,
            0
          ],
          [
            -116.15878,
            46.39151,
            0
          ],
          [
            -116.15868,
            46.39137,
            0
          ],
          [
            -116.15863,
            46.39128,
            0
          ],
          [
            -116.15856,
            46.39121,
            0
          ],
          [
            -116.15829,
            46.39088,
            0
          ],
          [
            -116.15802,
            46.39059,
            0
          ],
          [
            -116.15765,
            46.39023,
            0
          ],
          [
            -116.15747,
            46.39008,
            0
          ],
          [
            -116.15724,
            46.38995,
            0
          ],
          [
            -116.15664,
            46.38974,
            0
          ],
          [
            -116.15651,
            46.38968,
            0
          ],
          [
            -116.15641,
            46.3896,
            0
          ],
          [
            -116.15634,
            46.38951,
            0
          ],
          [
            -116.1563,
            46.38939,
            0
          ],
          [
            -116.15629,
            46.38933,
            0
          ],
          [
            -116.15629,
            46.38927,
            0
          ],
          [
            -116.15632,
            46.38914,
            0
          ],
          [
            -116.15639,
            46.38894,
            0
          ],
          [
            -116.15651,
            46.38871,
            0
          ],
          [
            -116.15652,
            46.38866,
            0
          ],
          [
            -116.15657,
            46.38856,
            0
          ],
          [
            -116.15658,
            46.38849,
            0
          ],
          [
            -116.1566,
            46.38843,
            0
          ],
          [
            -116.1566,
            46.38825,
            0
          ],
          [
            -116.15656,
            46.38811,
            0
          ],
          [
            -116.15644,
            46.38792,
            0
          ],
          [
            -116.15634,
            46.38782,
            0
          ],
          [
            -116.1563,
            46.38779,
            0
          ],
          [
            -116.15598,
            46.38763,
            0
          ],
          [
            -116.15584,
            46.38759,
            0
          ],
          [
            -116.15555,
            46.38753,
            0
          ],
          [
            -116.15522,
            46.38749,
            0
          ],
          [
            -116.15486,
            46.38747,
            0
          ],
          [
            -116.15384,
            46.38748,
            0
          ],
          [
            -116.15007,
            46.387,
            0
          ],
          [
            -116.14999,
            46.387,
            0
          ],
          [
            -116.14955,
            46.38694,
            0
          ],
          [
            -116.14931,
            46.38689,
            0
          ],
          [
            -116.14914,
            46.38683,
            0
          ],
          [
            -116.14902,
            46.38677,
            0
          ],
          [
            -116.14828,
            46.38631,
            0
          ],
          [
            -116.14811,
            46.38623,
            0
          ],
          [
            -116.14776,
            46.38609,
            0
          ],
          [
            -116.14753,
            46.38602,
            0
          ],
          [
            -116.147,
            46.38597,
            0
          ],
          [
            -116.14627,
            46.38598,
            0
          ],
          [
            -116.14586,
            46.38601,
            0
          ],
          [
            -116.14521,
            46.38614,
            0
          ],
          [
            -116.14482,
            46.38628,
            0
          ],
          [
            -116.14469,
            46.38636,
            0
          ],
          [
            -116.14461,
            46.38643,
            0
          ],
          [
            -116.14445,
            46.38661,
            0
          ],
          [
            -116.14408,
            46.3871,
            0
          ],
          [
            -116.144,
            46.38723,
            0
          ],
          [
            -116.14392,
            46.38733,
            0
          ],
          [
            -116.14341,
            46.38823,
            0
          ],
          [
            -116.14338,
            46.38827,
            0
          ],
          [
            -116.14326,
            46.38847,
            0
          ],
          [
            -116.14312,
            46.38861,
            0
          ],
          [
            -116.14296,
            46.38868,
            0
          ],
          [
            -116.14287,
            46.38869,
            0
          ],
          [
            -116.14274,
            46.38869,
            0
          ],
          [
            -116.14264,
            46.38868,
            0
          ],
          [
            -116.14254,
            46.38864,
            0
          ],
          [
            -116.14239,
            46.38853,
            0
          ],
          [
            -116.14228,
            46.38842,
            0
          ],
          [
            -116.1421,
            46.38821,
            0
          ],
          [
            -116.14203,
            46.38811,
            0
          ],
          [
            -116.14193,
            46.38794,
            0
          ],
          [
            -116.14188,
            46.38783,
            0
          ],
          [
            -116.14184,
            46.38765,
            0
          ],
          [
            -116.14184,
            46.38757,
            0
          ],
          [
            -116.14186,
            46.38745,
            0
          ],
          [
            -116.14193,
            46.38727,
            0
          ],
          [
            -116.14202,
            46.38713,
            0
          ],
          [
            -116.14213,
            46.38699,
            0
          ],
          [
            -116.14242,
            46.38667,
            0
          ],
          [
            -116.14249,
            46.38658,
            0
          ],
          [
            -116.14255,
            46.38648,
            0
          ],
          [
            -116.14259,
            46.38638,
            0
          ],
          [
            -116.1426,
            46.38633,
            0
          ],
          [
            -116.14262,
            46.38627,
            0
          ],
          [
            -116.14262,
            46.38615,
            0
          ],
          [
            -116.14257,
            46.386,
            0
          ],
          [
            -116.14254,
            46.38594,
            0
          ],
          [
            -116.14245,
            46.38581,
            0
          ],
          [
            -116.14237,
            46.38574,
            0
          ],
          [
            -116.14217,
            46.38553,
            0
          ],
          [
            -116.14181,
            46.38507,
            0
          ],
          [
            -116.14139,
            46.38446,
            0
          ],
          [
            -116.14124,
            46.38422,
            0
          ],
          [
            -116.14047,
            46.38332,
            0
          ],
          [
            -116.14045,
            46.38329,
            0
          ],
          [
            -116.14042,
            46.38326,
            0
          ],
          [
            -116.1404,
            46.38325,
            0
          ],
          [
            -116.14029,
            46.38315,
            0
          ],
          [
            -116.14028,
            46.38313,
            0
          ],
          [
            -116.14006,
            46.38297,
            0
          ],
          [
            -116.13986,
            46.38286,
            0
          ],
          [
            -116.13954,
            46.38272,
            0
          ],
          [
            -116.13939,
            46.38267,
            0
          ],
          [
            -116.13918,
            46.38262,
            0
          ],
          [
            -116.13895,
            46.38258,
            0
          ],
          [
            -116.13859,
            46.38255,
            0
          ],
          [
            -116.13843,
            46.38255,
            0
          ],
          [
            -116.13834,
            46.38256,
            0
          ],
          [
            -116.13825,
            46.38256,
            0
          ],
          [
            -116.13791,
            46.38261,
            0
          ],
          [
            -116.1371,
            46.38278,
            0
          ],
          [
            -116.13708,
            46.38279,
            0
          ],
          [
            -116.13559,
            46.38311,
            0
          ],
          [
            -116.13541,
            46.38314,
            0
          ],
          [
            -116.13534,
            46.38316,
            0
          ],
          [
            -116.13487,
            46.38324,
            0
          ],
          [
            -116.13483,
            46.38324,
            0
          ],
          [
            -116.13412,
            46.38331,
            0
          ],
          [
            -116.13037,
            46.3833,
            0
          ],
          [
            -116.12994,
            46.38327,
            0
          ],
          [
            -116.12973,
            46.38324,
            0
          ],
          [
            -116.12941,
            46.38317,
            0
          ],
          [
            -116.12919,
            46.38311,
            0
          ],
          [
            -116.1287,
            46.38291,
            0
          ],
          [
            -116.12853,
            46.38282,
            0
          ],
          [
            -116.12805,
            46.38249,
            0
          ],
          [
            -116.12801,
            46.38245,
            0
          ],
          [
            -116.12777,
            46.38228,
            0
          ],
          [
            -116.12773,
            46.38224,
            0
          ],
          [
            -116.12512,
            46.38028,
            0
          ],
          [
            -116.1248,
            46.38007,
            0
          ],
          [
            -116.12446,
            46.37987,
            0
          ],
          [
            -116.12416,
            46.37972,
            0
          ],
          [
            -116.12413,
            46.37971,
            0
          ],
          [
            -116.12398,
            46.37964,
            0
          ],
          [
            -116.12391,
            46.37962,
            0
          ],
          [
            -116.12373,
            46.37954,
            0
          ],
          [
            -116.12229,
            46.37904,
            0
          ],
          [
            -116.12212,
            46.37899,
            0
          ],
          [
            -116.12205,
            46.37896,
            0
          ],
          [
            -116.12074,
            46.37852,
            0
          ],
          [
            -116.12072,
            46.37851,
            0
          ],
          [
            -116.12055,
            46.37846,
            0
          ],
          [
            -116.12039,
            46.3784,
            0
          ],
          [
            -116.12024,
            46.37839,
            0
          ],
          [
            -116.12003,
            46.37835,
            0
          ],
          [
            -116.11975,
            46.37833,
            0
          ],
          [
            -116.1192,
            46.37834,
            0
          ],
          [
            -116.11917,
            46.37835,
            0
          ],
          [
            -116.11861,
            46.37843,
            0
          ],
          [
            -116.1171,
            46.37873,
            0
          ],
          [
            -116.11652,
            46.37877,
            0
          ],
          [
            -116.11591,
            46.37874,
            0
          ],
          [
            -116.11328,
            46.37824,
            0
          ],
          [
            -116.11303,
            46.37818,
            0
          ],
          [
            -116.11269,
            46.37812,
            0
          ],
          [
            -116.11225,
            46.37808,
            0
          ],
          [
            -116.1122,
            46.37808,
            0
          ],
          [
            -116.11206,
            46.37806,
            0
          ],
          [
            -116.11023,
            46.37807,
            0
          ],
          [
            -116.11011,
            46.37808,
            0
          ],
          [
            -116.10992,
            46.37808,
            0
          ],
          [
            -116.10965,
            46.37812,
            0
          ],
          [
            -116.1096,
            46.37812,
            0
          ],
          [
            -116.10922,
            46.3782,
            0
          ],
          [
            -116.10887,
            46.37831,
            0
          ],
          [
            -116.10807,
            46.37864,
            0
          ],
          [
            -116.10778,
            46.37874,
            0
          ],
          [
            -116.10748,
            46.37879,
            0
          ],
          [
            -116.10716,
            46.3788,
            0
          ],
          [
            -116.10685,
            46.37879,
            0
          ],
          [
            -116.10655,
            46.37873,
            0
          ],
          [
            -116.10457,
            46.37812,
            0
          ],
          [
            -116.10404,
            46.37799,
            0
          ],
          [
            -116.10379,
            46.37796,
            0
          ],
          [
            -116.10354,
            46.37795,
            0
          ],
          [
            -116.10288,
            46.37796,
            0
          ],
          [
            -116.10272,
            46.37795,
            0
          ],
          [
            -116.10087,
            46.3776,
            0
          ],
          [
            -116.10052,
            46.37756,
            0
          ],
          [
            -116.1001,
            46.37756,
            0
          ],
          [
            -116.09969,
            46.37759,
            0
          ],
          [
            -116.09619,
            46.37816,
            0
          ],
          [
            -116.09551,
            46.37819,
            0
          ],
          [
            -116.09489,
            46.37817,
            0
          ],
          [
            -116.09021,
            46.37786,
            0
          ],
          [
            -116.08958,
            46.37785,
            0
          ],
          [
            -116.08934,
            46.37786,
            0
          ],
          [
            -116.08867,
            46.37796,
            0
          ],
          [
            -116.08838,
            46.37803,
            0
          ],
          [
            -116.08809,
            46.37812,
            0
          ],
          [
            -116.08755,
            46.37834,
            0
          ],
          [
            -116.08548,
            46.37977,
            0
          ],
          [
            -116.08039,
            46.3834,
            0
          ],
          [
            -116.07988,
            46.3837,
            0
          ],
          [
            -116.07925,
            46.38396,
            0
          ],
          [
            -116.07858,
            46.38414,
            0
          ],
          [
            -116.06682,
            46.38678,
            0
          ],
          [
            -116.06604,
            46.3869,
            0
          ],
          [
            -116.06533,
            46.38694,
            0
          ],
          [
            -116.06345,
            46.38694,
            0
          ],
          [
            -116.0634,
            46.38693,
            0
          ],
          [
            -116.05933,
            46.38691,
            0
          ],
          [
            -116.05913,
            46.3869,
            0
          ],
          [
            -116.04265,
            46.38687,
            0
          ],
          [
            -116.04227,
            46.38684,
            0
          ],
          [
            -116.0418,
            46.38676,
            0
          ],
          [
            -116.04167,
            46.38672,
            0
          ],
          [
            -116.04154,
            46.38669,
            0
          ],
          [
            -116.04128,
            46.38659,
            0
          ],
          [
            -116.04111,
            46.38651,
            0
          ],
          [
            -116.0408,
            46.38634,
            0
          ],
          [
            -116.04073,
            46.38628,
            0
          ],
          [
            -116.04059,
            46.38618,
            0
          ],
          [
            -116.04032,
            46.38594,
            0
          ],
          [
            -116.03845,
            46.38399,
            0
          ],
          [
            -116.03833,
            46.38388,
            0
          ],
          [
            -116.03791,
            46.38342,
            0
          ],
          [
            -116.0366,
            46.38208,
            0
          ],
          [
            -116.03438,
            46.38009,
            0
          ],
          [
            -116.03437,
            46.38009,
            0
          ],
          [
            -116.03088,
            46.37697,
            0
          ],
          [
            -116.0306,
            46.37675,
            0
          ],
          [
            -116.03017,
            46.37651,
            0
          ],
          [
            -116.02965,
            46.37629,
            0
          ],
          [
            -116.02905,
            46.37612,
            0
          ],
          [
            -116.02849,
            46.37604,
            0
          ],
          [
            -116.02767,
            46.37601,
            0
          ],
          [
            -116.01588,
            46.37597,
            0
          ],
          [
            -116.00877,
            46.37602,
            0
          ],
          [
            -116.00857,
            46.37603,
            0
          ],
          [
            -115.98822,
            46.37601,
            0
          ],
          [
            -115.98798,
            46.376,
            0
          ],
          [
            -115.97456,
            46.376,
            0
          ],
          [
            -115.97444,
            46.37599,
            0
          ],
          [
            -115.93867,
            46.37582,
            0
          ],
          [
            -115.93859,
            46.37583,
            0
          ],
          [
            -115.93851,
            46.37583,
            0
          ],
          [
            -115.93845,
            46.37584,
            0
          ],
          [
            -115.9384,
            46.37586,
            0
          ],
          [
            -115.93836,
            46.37587,
            0
          ],
          [
            -115.93831,
            46.3759,
            0
          ],
          [
            -115.93826,
            46.37594,
            0
          ],
          [
            -115.93821,
            46.37602,
            0
          ],
          [
            -115.93819,
            46.37607,
            0
          ],
          [
            -115.93818,
            46.37612,
            0
          ],
          [
            -115.93821,
            46.38051,
            0
          ],
          [
            -115.93826,
            46.38215,
            0
          ],
          [
            -115.93833,
            46.38303,
            0
          ],
          [
            -115.93833,
            46.3836,
            0
          ],
          [
            -115.93826,
            46.38401,
            0
          ],
          [
            -115.93821,
            46.38418,
            0
          ],
          [
            -115.93814,
            46.38434,
            0
          ],
          [
            -115.93804,
            46.38453,
            0
          ],
          [
            -115.93793,
            46.38469,
            0
          ],
          [
            -115.93789,
            46.38476,
            0
          ],
          [
            -115.93702,
            46.38606,
            0
          ],
          [
            -115.93687,
            46.38625,
            0
          ],
          [
            -115.93647,
            46.3867,
            0
          ],
          [
            -115.93542,
            46.38778,
            0
          ],
          [
            -115.93532,
            46.3879,
            0
          ],
          [
            -115.9351,
            46.38813,
            0
          ],
          [
            -115.935,
            46.38822,
            0
          ],
          [
            -115.93411,
            46.38914,
            0
          ],
          [
            -115.93405,
            46.38922,
            0
          ],
          [
            -115.93376,
            46.38951,
            0
          ],
          [
            -115.93338,
            46.38984,
            0
          ],
          [
            -115.9333,
            46.3899,
            0
          ],
          [
            -115.93299,
            46.39009,
            0
          ],
          [
            -115.93277,
            46.3902,
            0
          ],
          [
            -115.93044,
            46.39116,
            0
          ],
          [
            -115.93034,
            46.39121,
            0
          ],
          [
            -115.9303,
            46.39122,
            0
          ],
          [
            -115.93,
            46.39139,
            0
          ],
          [
            -115.92996,
            46.39142,
            0
          ],
          [
            -115.92989,
            46.39146,
            0
          ],
          [
            -115.92985,
            46.3915,
            0
          ],
          [
            -115.92978,
            46.39155,
            0
          ],
          [
            -115.92959,
            46.39173,
            0
          ],
          [
            -115.92956,
            46.39178,
            0
          ],
          [
            -115.92949,
            46.39186,
            0
          ],
          [
            -115.92938,
            46.39205,
            0
          ],
          [
            -115.92938,
            46.39206,
            0
          ],
          [
            -115.92931,
            46.39223,
            0
          ],
          [
            -115.92929,
            46.39231,
            0
          ],
          [
            -115.92927,
            46.39248,
            0
          ],
          [
            -115.92927,
            46.39262,
            0
          ],
          [
            -115.92935,
            46.39381,
            0
          ],
          [
            -115.92936,
            46.39385,
            0
          ],
          [
            -115.92942,
            46.39471,
            0
          ],
          [
            -115.92941,
            46.39507,
            0
          ],
          [
            -115.92935,
            46.39547,
            0
          ],
          [
            -115.92931,
            46.39562,
            0
          ],
          [
            -115.92918,
            46.39595,
            0
          ],
          [
            -115.92908,
            46.39616,
            0
          ],
          [
            -115.92885,
            46.39652,
            0
          ],
          [
            -115.9263,
            46.39966,
            0
          ],
          [
            -115.92595,
            46.40002,
            0
          ],
          [
            -115.92575,
            46.40019,
            0
          ],
          [
            -115.92547,
            46.4004,
            0
          ],
          [
            -115.92501,
            46.40068,
            0
          ],
          [
            -115.92056,
            46.403,
            0
          ],
          [
            -115.92018,
            46.40322,
            0
          ],
          [
            -115.91999,
            46.40337,
            0
          ],
          [
            -115.91994,
            46.40343,
            0
          ],
          [
            -115.91978,
            46.40359,
            0
          ],
          [
            -115.91965,
            46.40378,
            0
          ],
          [
            -115.9196,
            46.40389,
            0
          ],
          [
            -115.91959,
            46.4039,
            0
          ],
          [
            -115.91937,
            46.40442,
            0
          ],
          [
            -115.91933,
            46.40454,
            0
          ],
          [
            -115.91919,
            46.40482,
            0
          ],
          [
            -115.91896,
            46.40521,
            0
          ],
          [
            -115.91888,
            46.40532,
            0
          ],
          [
            -115.91876,
            46.40544,
            0
          ],
          [
            -115.91866,
            46.40556,
            0
          ],
          [
            -115.91857,
            46.40565,
            0
          ],
          [
            -115.91834,
            46.40585,
            0
          ],
          [
            -115.91783,
            46.4062,
            0
          ],
          [
            -115.91738,
            46.40643,
            0
          ],
          [
            -115.91429,
            46.40775,
            0
          ],
          [
            -115.9131,
            46.40823,
            0
          ],
          [
            -115.90959,
            46.40946,
            0
          ],
          [
            -115.90953,
            46.40949,
            0
          ],
          [
            -115.90936,
            46.40955,
            0
          ],
          [
            -115.90896,
            46.40973,
            0
          ],
          [
            -115.90856,
            46.40995,
            0
          ],
          [
            -115.90688,
            46.41102,
            0
          ],
          [
            -115.90644,
            46.41128,
            0
          ],
          [
            -115.90608,
            46.41145,
            0
          ],
          [
            -115.90554,
            46.41167,
            0
          ],
          [
            -115.90507,
            46.41183,
            0
          ],
          [
            -115.90461,
            46.41203,
            0
          ],
          [
            -115.90426,
            46.4122,
            0
          ],
          [
            -115.90242,
            46.41318,
            0
          ],
          [
            -115.90189,
            46.41344,
            0
          ],
          [
            -115.90078,
            46.41393,
            0
          ],
          [
            -115.89945,
            46.41463,
            0
          ],
          [
            -115.89939,
            46.41465,
            0
          ],
          [
            -115.89862,
            46.41506,
            0
          ],
          [
            -115.8983,
            46.41525,
            0
          ],
          [
            -115.89808,
            46.4154,
            0
          ],
          [
            -115.89801,
            46.41544,
            0
          ],
          [
            -115.89779,
            46.4156,
            0
          ],
          [
            -115.89726,
            46.41603,
            0
          ],
          [
            -115.89688,
            46.41639,
            0
          ],
          [
            -115.89657,
            46.41673,
            0
          ],
          [
            -115.89654,
            46.41678,
            0
          ],
          [
            -115.89642,
            46.41693,
            0
          ],
          [
            -115.89641,
            46.41695,
            0
          ],
          [
            -115.89631,
            46.41708,
            0
          ],
          [
            -115.89598,
            46.41767,
            0
          ],
          [
            -115.89448,
            46.42065,
            0
          ],
          [
            -115.89415,
            46.42113,
            0
          ],
          [
            -115.89164,
            46.42413,
            0
          ],
          [
            -115.88941,
            46.42641,
            0
          ],
          [
            -115.88904,
            46.42672,
            0
          ],
          [
            -115.88884,
            46.42686,
            0
          ],
          [
            -115.88859,
            46.42701,
            0
          ],
          [
            -115.88804,
            46.4273,
            0
          ],
          [
            -115.88782,
            46.42745,
            0
          ],
          [
            -115.88749,
            46.42773,
            0
          ],
          [
            -115.88737,
            46.42786,
            0
          ],
          [
            -115.88734,
            46.4279,
            0
          ],
          [
            -115.88722,
            46.42803,
            0
          ],
          [
            -115.88702,
            46.42834,
            0
          ],
          [
            -115.88693,
            46.42853,
            0
          ],
          [
            -115.88684,
            46.42878,
            0
          ],
          [
            -115.88681,
            46.42901,
            0
          ],
          [
            -115.8868,
            46.42943,
            0
          ],
          [
            -115.88682,
            46.42959,
            0
          ],
          [
            -115.88686,
            46.42976,
            0
          ],
          [
            -115.88691,
            46.42991,
            0
          ],
          [
            -115.88707,
            46.43023,
            0
          ],
          [
            -115.88761,
            46.43105,
            0
          ],
          [
            -115.88777,
            46.43132,
            0
          ],
          [
            -115.88785,
            46.43152,
            0
          ],
          [
            -115.88793,
            46.43176,
            0
          ],
          [
            -115.88797,
            46.4321,
            0
          ],
          [
            -115.88797,
            46.43226,
            0
          ],
          [
            -115.88795,
            46.43246,
            0
          ],
          [
            -115.8879,
            46.43268,
            0
          ],
          [
            -115.88782,
            46.43289,
            0
          ],
          [
            -115.88769,
            46.43317,
            0
          ],
          [
            -115.88545,
            46.43605,
            0
          ],
          [
            -115.88513,
            46.43639,
            0
          ],
          [
            -115.88476,
            46.43669,
            0
          ],
          [
            -115.88447,
            46.43688,
            0
          ],
          [
            -115.88437,
            46.43693,
            0
          ],
          [
            -115.88429,
            46.43698,
            0
          ],
          [
            -115.88374,
            46.43724,
            0
          ],
          [
            -115.88309,
            46.43745,
            0
          ],
          [
            -115.8829,
            46.43749,
            0
          ],
          [
            -115.88272,
            46.43754,
            0
          ],
          [
            -115.87704,
            46.4387,
            0
          ],
          [
            -115.87702,
            46.4387,
            0
          ],
          [
            -115.8722,
            46.43969,
            0
          ],
          [
            -115.8718,
            46.43979,
            0
          ],
          [
            -115.87155,
            46.43988,
            0
          ],
          [
            -115.87129,
            46.44,
            0
          ],
          [
            -115.87111,
            46.4401,
            0
          ],
          [
            -115.87015,
            46.4407,
            0
          ],
          [
            -115.87014,
            46.44071,
            0
          ],
          [
            -115.8694,
            46.44108,
            0
          ],
          [
            -115.86304,
            46.444,
            0
          ],
          [
            -115.86236,
            46.44434,
            0
          ],
          [
            -115.86208,
            46.44451,
            0
          ],
          [
            -115.86176,
            46.44473,
            0
          ],
          [
            -115.85951,
            46.4465,
            0
          ],
          [
            -115.85932,
            46.44667,
            0
          ],
          [
            -115.85382,
            46.45228,
            0
          ],
          [
            -115.85377,
            46.45234,
            0
          ],
          [
            -115.85365,
            46.45244,
            0
          ],
          [
            -115.85364,
            46.45246,
            0
          ],
          [
            -115.85318,
            46.45292,
            0
          ],
          [
            -115.85303,
            46.45305,
            0
          ],
          [
            -115.8501,
            46.45537,
            0
          ],
          [
            -115.84826,
            46.45677,
            0
          ],
          [
            -115.84776,
            46.45709,
            0
          ],
          [
            -115.84668,
            46.45772,
            0
          ],
          [
            -115.84633,
            46.45803,
            0
          ],
          [
            -115.8463,
            46.45805,
            0
          ],
          [
            -115.84588,
            46.45851,
            0
          ],
          [
            -115.84555,
            46.45884,
            0
          ],
          [
            -115.84519,
            46.45913,
            0
          ],
          [
            -115.84364,
            46.45998,
            0
          ],
          [
            -115.84318,
            46.46032,
            0
          ],
          [
            -115.84232,
            46.4611,
            0
          ],
          [
            -115.84231,
            46.4611,
            0
          ],
          [
            -115.84185,
            46.46153,
            0
          ],
          [
            -115.84185,
            46.46154,
            0
          ],
          [
            -115.84151,
            46.46186,
            0
          ],
          [
            -115.84129,
            46.46211,
            0
          ],
          [
            -115.84112,
            46.46234,
            0
          ],
          [
            -115.84098,
            46.46263,
            0
          ],
          [
            -115.84089,
            46.4629,
            0
          ],
          [
            -115.84076,
            46.46352,
            0
          ],
          [
            -115.84066,
            46.46387,
            0
          ],
          [
            -115.84046,
            46.46438,
            0
          ],
          [
            -115.84027,
            46.46473,
            0
          ],
          [
            -115.84001,
            46.4651,
            0
          ],
          [
            -115.83992,
            46.4652,
            0
          ],
          [
            -115.83987,
            46.46527,
            0
          ],
          [
            -115.83946,
            46.46559,
            0
          ],
          [
            -115.8393,
            46.46569,
            0
          ],
          [
            -115.83851,
            46.46612,
            0
          ],
          [
            -115.83797,
            46.46628,
            0
          ],
          [
            -115.83628,
            46.46666,
            0
          ],
          [
            -115.836,
            46.46674,
            0
          ],
          [
            -115.83543,
            46.46693,
            0
          ],
          [
            -115.83415,
            46.46742,
            0
          ],
          [
            -115.83395,
            46.46748,
            0
          ],
          [
            -115.83364,
            46.46754,
            0
          ],
          [
            -115.83346,
            46.46755,
            0
          ],
          [
            -115.83312,
            46.46754,
            0
          ],
          [
            -115.83253,
            46.46741,
            0
          ],
          [
            -115.8316,
            46.4671,
            0
          ],
          [
            -115.83109,
            46.46697,
            0
          ],
          [
            -115.83088,
            46.46694,
            0
          ],
          [
            -115.83076,
            46.46695,
            0
          ],
          [
            -115.83058,
            46.46698,
            0
          ],
          [
            -115.83039,
            46.46705,
            0
          ],
          [
            -115.83016,
            46.4672,
            0
          ],
          [
            -115.82982,
            46.46747,
            0
          ],
          [
            -115.82977,
            46.4675,
            0
          ],
          [
            -115.82798,
            46.46883,
            0
          ],
          [
            -115.82767,
            46.46903,
            0
          ],
          [
            -115.8273,
            46.46919,
            0
          ],
          [
            -115.82711,
            46.46926,
            0
          ],
          [
            -115.82591,
            46.46963,
            0
          ],
          [
            -115.82586,
            46.46964,
            0
          ],
          [
            -115.82552,
            46.46981,
            0
          ],
          [
            -115.82537,
            46.46991,
            0
          ],
          [
            -115.82397,
            46.47106,
            0
          ],
          [
            -115.82369,
            46.47127,
            0
          ],
          [
            -115.82352,
            46.47136,
            0
          ],
          [
            -115.82343,
            46.47139,
            0
          ],
          [
            -115.82337,
            46.47142,
            0
          ],
          [
            -115.82334,
            46.47143,
            0
          ],
          [
            -115.82326,
            46.47147,
            0
          ],
          [
            -115.82316,
            46.4715,
            0
          ],
          [
            -115.82305,
            46.47151,
            0
          ],
          [
            -115.82295,
            46.47154,
            0
          ],
          [
            -115.82254,
            46.47159,
            0
          ],
          [
            -115.82203,
            46.47158,
            0
          ],
          [
            -115.82174,
            46.47155,
            0
          ],
          [
            -115.82163,
            46.47153,
            0
          ],
          [
            -115.8215,
            46.47153,
            0
          ],
          [
            -115.82136,
            46.47151,
            0
          ],
          [
            -115.82107,
            46.4715,
            0
          ],
          [
            -115.8196,
            46.47137,
            0
          ],
          [
            -115.8192,
            46.47136,
            0
          ],
          [
            -115.81821,
            46.47137,
            0
          ],
          [
            -115.81782,
            46.47139,
            0
          ],
          [
            -115.8174,
            46.47139,
            0
          ],
          [
            -115.81678,
            46.47144,
            0
          ],
          [
            -115.81659,
            46.47147,
            0
          ],
          [
            -115.81639,
            46.47152,
            0
          ],
          [
            -115.81598,
            46.47165,
            0
          ],
          [
            -115.81581,
            46.47173,
            0
          ],
          [
            -115.81557,
            46.47188,
            0
          ],
          [
            -115.81543,
            46.47198,
            0
          ],
          [
            -115.81522,
            46.4722,
            0
          ],
          [
            -115.81494,
            46.47264,
            0
          ],
          [
            -115.8141,
            46.47411,
            0
          ],
          [
            -115.81397,
            46.47431,
            0
          ],
          [
            -115.81383,
            46.4745,
            0
          ],
          [
            -115.81352,
            46.47488,
            0
          ],
          [
            -115.81306,
            46.47527,
            0
          ],
          [
            -115.81194,
            46.47606,
            0
          ],
          [
            -115.81163,
            46.47626,
            0
          ],
          [
            -115.81137,
            46.47638,
            0
          ],
          [
            -115.81118,
            46.47644,
            0
          ],
          [
            -115.81108,
            46.47646,
            0
          ],
          [
            -115.81098,
            46.47647,
            0
          ],
          [
            -115.81067,
            46.47648,
            0
          ],
          [
            -115.80996,
            46.47644,
            0
          ],
          [
            -115.80975,
            46.47644,
            0
          ],
          [
            -115.80955,
            46.47647,
            0
          ],
          [
            -115.80937,
            46.47651,
            0
          ],
          [
            -115.80919,
            46.47659,
            0
          ],
          [
            -115.80912,
            46.47663,
            0
          ],
          [
            -115.80905,
            46.47668,
            0
          ],
          [
            -115.8088,
            46.47689,
            0
          ],
          [
            -115.80834,
            46.47733,
            0
          ],
          [
            -115.80812,
            46.4775,
            0
          ],
          [
            -115.80798,
            46.47758,
            0
          ],
          [
            -115.80794,
            46.47759,
            0
          ],
          [
            -115.80785,
            46.47763,
            0
          ],
          [
            -115.80775,
            46.47766,
            0
          ],
          [
            -115.80755,
            46.4777,
            0
          ],
          [
            -115.80734,
            46.4777,
            0
          ],
          [
            -115.80714,
            46.47768,
            0
          ],
          [
            -115.80664,
            46.47759,
            0
          ],
          [
            -115.80644,
            46.47757,
            0
          ],
          [
            -115.80602,
            46.47756,
            0
          ],
          [
            -115.80596,
            46.47757,
            0
          ],
          [
            -115.80582,
            46.47758,
            0
          ],
          [
            -115.80511,
            46.47768,
            0
          ],
          [
            -115.805,
            46.47768,
            0
          ],
          [
            -115.8049,
            46.47767,
            0
          ],
          [
            -115.8048,
            46.47765,
            0
          ],
          [
            -115.8047,
            46.47762,
            0
          ],
          [
            -115.80453,
            46.47754,
            0
          ],
          [
            -115.80445,
            46.47749,
            0
          ],
          [
            -115.80433,
            46.47738,
            0
          ],
          [
            -115.80393,
            46.47694,
            0
          ],
          [
            -115.80373,
            46.47679,
            0
          ],
          [
            -115.80372,
            46.47679,
            0
          ],
          [
            -115.80365,
            46.47675,
            0
          ],
          [
            -115.80356,
            46.47672,
            0
          ],
          [
            -115.80346,
            46.4767,
            0
          ],
          [
            -115.80326,
            46.47668,
            0
          ],
          [
            -115.80306,
            46.47669,
            0
          ],
          [
            -115.80246,
            46.47677,
            0
          ],
          [
            -115.80227,
            46.47681,
            0
          ],
          [
            -115.80008,
            46.47745,
            0
          ],
          [
            -115.79991,
            46.47752,
            0
          ],
          [
            -115.79958,
            46.4777,
            0
          ],
          [
            -115.79951,
            46.47775,
            0
          ],
          [
            -115.79911,
            46.47808,
            0
          ],
          [
            -115.79895,
            46.47825,
            0
          ],
          [
            -115.79878,
            46.4785,
            0
          ],
          [
            -115.79869,
            46.47866,
            0
          ],
          [
            -115.79863,
            46.47882,
            0
          ],
          [
            -115.79859,
            46.47899,
            0
          ],
          [
            -115.79858,
            46.47913,
            0
          ],
          [
            -115.79859,
            46.47927,
            0
          ],
          [
            -115.79862,
            46.47944,
            0
          ],
          [
            -115.79863,
            46.47955,
            0
          ],
          [
            -115.79876,
            46.48033,
            0
          ],
          [
            -115.79879,
            46.48046,
            0
          ],
          [
            -115.7989,
            46.4813,
            0
          ],
          [
            -115.79898,
            46.48243,
            0
          ],
          [
            -115.79901,
            46.484,
            0
          ],
          [
            -115.79899,
            46.4881,
            0
          ],
          [
            -115.79896,
            46.48882,
            0
          ],
          [
            -115.79896,
            46.4893,
            0
          ],
          [
            -115.79902,
            46.49091,
            0
          ],
          [
            -115.79901,
            46.49102,
            0
          ],
          [
            -115.79901,
            46.49125,
            0
          ],
          [
            -115.799,
            46.4914,
            0
          ],
          [
            -115.79906,
            46.49175,
            0
          ],
          [
            -115.79917,
            46.49186,
            0
          ],
          [
            -115.7992,
            46.4919,
            0
          ],
          [
            -115.7993,
            46.492,
            0
          ],
          [
            -115.79985,
            46.4923,
            0
          ],
          [
            -115.80063,
            46.49265,
            0
          ],
          [
            -115.80072,
            46.4927,
            0
          ],
          [
            -115.80109,
            46.49282,
            0
          ],
          [
            -115.80158,
            46.49293,
            0
          ],
          [
            -115.80178,
            46.49295,
            0
          ],
          [
            -115.80219,
            46.49294,
            0
          ],
          [
            -115.80279,
            46.49288,
            0
          ],
          [
            -115.8029,
            46.49288,
            0
          ],
          [
            -115.803,
            46.49289,
            0
          ],
          [
            -115.80319,
            46.49293,
            0
          ],
          [
            -115.80336,
            46.49301,
            0
          ],
          [
            -115.80361,
            46.49319,
            0
          ],
          [
            -115.80384,
            46.49339,
            0
          ],
          [
            -115.80391,
            46.49344,
            0
          ],
          [
            -115.80417,
            46.49356,
            0
          ],
          [
            -115.80447,
            46.49362,
            0
          ],
          [
            -115.80468,
            46.49362,
            0
          ],
          [
            -115.80478,
            46.49361,
            0
          ],
          [
            -115.80497,
            46.49357,
            0
          ],
          [
            -115.80506,
            46.49354,
            0
          ],
          [
            -115.80515,
            46.4935,
            0
          ],
          [
            -115.80538,
            46.49335,
            0
          ],
          [
            -115.80558,
            46.49319,
            0
          ],
          [
            -115.80566,
            46.49314,
            0
          ],
          [
            -115.80591,
            46.49302,
            0
          ],
          [
            -115.80601,
            46.49299,
            0
          ],
          [
            -115.8064,
            46.49294,
            0
          ],
          [
            -115.80651,
            46.49294,
            0
          ],
          [
            -115.80679,
            46.49297,
            0
          ],
          [
            -115.80702,
            46.49304,
            0
          ],
          [
            -115.80706,
            46.49306,
            0
          ],
          [
            -115.80717,
            46.4931,
            0
          ],
          [
            -115.80739,
            46.49325,
            0
          ],
          [
            -115.80749,
            46.49335,
            0
          ],
          [
            -115.80785,
            46.4939,
            0
          ],
          [
            -115.80803,
            46.49422,
            0
          ],
          [
            -115.80806,
            46.49429,
            0
          ],
          [
            -115.80834,
            46.49514,
            0
          ],
          [
            -115.80842,
            46.49532,
            0
          ],
          [
            -115.80851,
            46.49546,
            0
          ],
          [
            -115.80863,
            46.49561,
            0
          ],
          [
            -115.80876,
            46.49572,
            0
          ],
          [
            -115.80891,
            46.49582,
            0
          ],
          [
            -115.80933,
            46.49604,
            0
          ],
          [
            -115.80955,
            46.49619,
            0
          ],
          [
            -115.80968,
            46.4963,
            0
          ],
          [
            -115.80979,
            46.49641,
            0
          ],
          [
            -115.80989,
            46.49654,
            0
          ],
          [
            -115.80996,
            46.49668,
            0
          ],
          [
            -115.81002,
            46.4969,
            0
          ],
          [
            -115.81003,
            46.49703,
            0
          ],
          [
            -115.81002,
            46.49717,
            0
          ],
          [
            -115.80999,
            46.49731,
            0
          ],
          [
            -115.80994,
            46.49745,
            0
          ],
          [
            -115.80986,
            46.49759,
            0
          ],
          [
            -115.80976,
            46.49785,
            0
          ],
          [
            -115.80975,
            46.49793,
            0
          ],
          [
            -115.80976,
            46.49803,
            0
          ],
          [
            -115.80978,
            46.49813,
            0
          ],
          [
            -115.80983,
            46.49823,
            0
          ],
          [
            -115.80997,
            46.49836,
            0
          ],
          [
            -115.81012,
            46.49845,
            0
          ],
          [
            -115.81041,
            46.49855,
            0
          ],
          [
            -115.81054,
            46.49858,
            0
          ],
          [
            -115.81184,
            46.49873,
            0
          ],
          [
            -115.81223,
            46.4988,
            0
          ],
          [
            -115.81243,
            46.49885,
            0
          ],
          [
            -115.81256,
            46.49892,
            0
          ],
          [
            -115.81266,
            46.49899,
            0
          ],
          [
            -115.81276,
            46.49912,
            0
          ],
          [
            -115.81281,
            46.49924,
            0
          ],
          [
            -115.81279,
            46.49941,
            0
          ],
          [
            -115.81271,
            46.49954,
            0
          ],
          [
            -115.81251,
            46.49966,
            0
          ],
          [
            -115.81243,
            46.49968,
            0
          ],
          [
            -115.81233,
            46.49972,
            0
          ],
          [
            -115.81214,
            46.49978,
            0
          ],
          [
            -115.81199,
            46.49984,
            0
          ],
          [
            -115.81189,
            46.49989,
            0
          ],
          [
            -115.81182,
            46.49995,
            0
          ],
          [
            -115.8117,
            46.50009,
            0
          ],
          [
            -115.81166,
            46.50018,
            0
          ],
          [
            -115.81162,
            46.50031,
            0
          ],
          [
            -115.81163,
            46.50053,
            0
          ],
          [
            -115.8116,
            46.50091,
            0
          ],
          [
            -115.8116,
            46.50128,
            0
          ],
          [
            -115.81164,
            46.50164,
            0
          ],
          [
            -115.8117,
            46.50199,
            0
          ],
          [
            -115.8117,
            46.50232,
            0
          ],
          [
            -115.81174,
            46.50277,
            0
          ],
          [
            -115.8118,
            46.50304,
            0
          ],
          [
            -115.81192,
            46.50324,
            0
          ],
          [
            -115.81199,
            46.5033,
            0
          ],
          [
            -115.81227,
            46.5036,
            0
          ],
          [
            -115.81235,
            46.50372,
            0
          ],
          [
            -115.81246,
            46.50397,
            0
          ],
          [
            -115.8125,
            46.50414,
            0
          ],
          [
            -115.8125,
            46.50425,
            0
          ],
          [
            -115.81249,
            46.50433,
            0
          ],
          [
            -115.8125,
            46.50464,
            0
          ],
          [
            -115.81256,
            46.50485,
            0
          ],
          [
            -115.81263,
            46.50499,
            0
          ],
          [
            -115.81276,
            46.50518,
            0
          ],
          [
            -115.81288,
            46.5053,
            0
          ],
          [
            -115.81301,
            46.5054,
            0
          ],
          [
            -115.81355,
            46.50574,
            0
          ],
          [
            -115.81382,
            46.50595,
            0
          ],
          [
            -115.81408,
            46.50622,
            0
          ],
          [
            -115.81418,
            46.50638,
            0
          ],
          [
            -115.81446,
            46.50701,
            0
          ],
          [
            -115.81471,
            46.50746,
            0
          ],
          [
            -115.81487,
            46.50768,
            0
          ],
          [
            -115.81534,
            46.50823,
            0
          ],
          [
            -115.8154,
            46.50831,
            0
          ],
          [
            -115.81545,
            46.50839,
            0
          ],
          [
            -115.81553,
            46.50867,
            0
          ],
          [
            -115.81552,
            46.50896,
            0
          ],
          [
            -115.81548,
            46.50909,
            0
          ],
          [
            -115.81543,
            46.50917,
            0
          ],
          [
            -115.81537,
            46.50929,
            0
          ],
          [
            -115.81537,
            46.5093,
            0
          ],
          [
            -115.81525,
            46.50958,
            0
          ],
          [
            -115.81522,
            46.50978,
            0
          ],
          [
            -115.81522,
            46.50993,
            0
          ],
          [
            -115.81527,
            46.51015,
            0
          ],
          [
            -115.81536,
            46.51042,
            0
          ],
          [
            -115.81545,
            46.51084,
            0
          ],
          [
            -115.8155,
            46.51122,
            0
          ],
          [
            -115.81563,
            46.51186,
            0
          ],
          [
            -115.81571,
            46.51214,
            0
          ],
          [
            -115.81581,
            46.51237,
            0
          ],
          [
            -115.81589,
            46.5125,
            0
          ],
          [
            -115.81604,
            46.51267,
            0
          ],
          [
            -115.81608,
            46.51273,
            0
          ],
          [
            -115.81632,
            46.51299,
            0
          ],
          [
            -115.81686,
            46.5135,
            0
          ],
          [
            -115.81697,
            46.51359,
            0
          ],
          [
            -115.81724,
            46.51375,
            0
          ],
          [
            -115.81735,
            46.5138,
            0
          ],
          [
            -115.81761,
            46.51389,
            0
          ],
          [
            -115.81768,
            46.5139,
            0
          ],
          [
            -115.81789,
            46.51395,
            0
          ],
          [
            -115.81799,
            46.51396,
            0
          ],
          [
            -115.81801,
            46.51397,
            0
          ],
          [
            -115.81839,
            46.51399,
            0
          ],
          [
            -115.81865,
            46.51396,
            0
          ],
          [
            -115.81963,
            46.51379,
            0
          ],
          [
            -115.81978,
            46.51378,
            0
          ],
          [
            -115.81983,
            46.51377,
            0
          ],
          [
            -115.82058,
            46.51376,
            0
          ],
          [
            -115.82092,
            46.51378,
            0
          ],
          [
            -115.82159,
            46.51388,
            0
          ],
          [
            -115.82237,
            46.51392,
            0
          ],
          [
            -115.8225,
            46.51394,
            0
          ],
          [
            -115.82271,
            46.51403,
            0
          ],
          [
            -115.8228,
            46.51408,
            0
          ],
          [
            -115.82285,
            46.51412,
            0
          ],
          [
            -115.82295,
            46.51424,
            0
          ],
          [
            -115.82295,
            46.51425,
            0
          ],
          [
            -115.82298,
            46.51431,
            0
          ],
          [
            -115.82297,
            46.51475,
            0
          ],
          [
            -115.82299,
            46.51489,
            0
          ],
          [
            -115.82305,
            46.51502,
            0
          ],
          [
            -115.82315,
            46.51515,
            0
          ],
          [
            -115.82322,
            46.51521,
            0
          ],
          [
            -115.82329,
            46.51526,
            0
          ],
          [
            -115.82355,
            46.51539,
            0
          ],
          [
            -115.82387,
            46.51551,
            0
          ],
          [
            -115.82397,
            46.51554,
            0
          ],
          [
            -115.82463,
            46.5158,
            0
          ],
          [
            -115.82495,
            46.51595,
            0
          ],
          [
            -115.82514,
            46.51606,
            0
          ],
          [
            -115.82534,
            46.51612,
            0
          ],
          [
            -115.82555,
            46.51615,
            0
          ],
          [
            -115.82575,
            46.51615,
            0
          ],
          [
            -115.82595,
            46.51613,
            0
          ],
          [
            -115.82616,
            46.51609,
            0
          ],
          [
            -115.82635,
            46.51604,
            0
          ],
          [
            -115.82655,
            46.516,
            0
          ],
          [
            -115.82676,
            46.51598,
            0
          ],
          [
            -115.82697,
            46.516,
            0
          ],
          [
            -115.82716,
            46.51607,
            0
          ],
          [
            -115.82731,
            46.51615,
            0
          ],
          [
            -115.82738,
            46.5162,
            0
          ],
          [
            -115.8275,
            46.51632,
            0
          ],
          [
            -115.82758,
            46.51644,
            0
          ],
          [
            -115.82759,
            46.51645,
            0
          ],
          [
            -115.82814,
            46.51738,
            0
          ],
          [
            -115.82833,
            46.51776,
            0
          ],
          [
            -115.8284,
            46.518,
            0
          ],
          [
            -115.82844,
            46.51822,
            0
          ],
          [
            -115.82845,
            46.51844,
            0
          ],
          [
            -115.82844,
            46.51887,
            0
          ],
          [
            -115.82846,
            46.51917,
            0
          ],
          [
            -115.82853,
            46.5196,
            0
          ],
          [
            -115.8286,
            46.52032,
            0
          ],
          [
            -115.82864,
            46.52107,
            0
          ],
          [
            -115.82867,
            46.52135,
            0
          ],
          [
            -115.82873,
            46.52158,
            0
          ],
          [
            -115.82887,
            46.52186,
            0
          ],
          [
            -115.82938,
            46.52271,
            0
          ],
          [
            -115.82964,
            46.5231,
            0
          ],
          [
            -115.82967,
            46.52317,
            0
          ],
          [
            -115.83001,
            46.52369,
            0
          ],
          [
            -115.83007,
            46.52377,
            0
          ],
          [
            -115.83055,
            46.52457,
            0
          ],
          [
            -115.83079,
            46.52494,
            0
          ],
          [
            -115.83088,
            46.5251,
            0
          ],
          [
            -115.83094,
            46.52524,
            0
          ],
          [
            -115.83098,
            46.52547,
            0
          ],
          [
            -115.83095,
            46.52574,
            0
          ],
          [
            -115.8308,
            46.52642,
            0
          ],
          [
            -115.83079,
            46.52649,
            0
          ],
          [
            -115.83066,
            46.52708,
            0
          ],
          [
            -115.83056,
            46.52774,
            0
          ],
          [
            -115.8305,
            46.52838,
            0
          ],
          [
            -115.83047,
            46.52961,
            0
          ],
          [
            -115.8305,
            46.52982,
            0
          ],
          [
            -115.83055,
            46.53,
            0
          ],
          [
            -115.83062,
            46.53018,
            0
          ],
          [
            -115.83071,
            46.53036,
            0
          ],
          [
            -115.83085,
            46.5306,
            0
          ],
          [
            -115.83109,
            46.53089,
            0
          ],
          [
            -115.83157,
            46.53152,
            0
          ],
          [
            -115.8318,
            46.53191,
            0
          ],
          [
            -115.83192,
            46.5322,
            0
          ],
          [
            -115.83196,
            46.53239,
            0
          ],
          [
            -115.83198,
            46.53269,
            0
          ],
          [
            -115.832,
            46.53363,
            0
          ],
          [
            -115.83202,
            46.53379,
            0
          ],
          [
            -115.83205,
            46.5339,
            0
          ],
          [
            -115.83207,
            46.53394,
            0
          ],
          [
            -115.83209,
            46.53401,
            0
          ],
          [
            -115.83214,
            46.53412,
            0
          ],
          [
            -115.83215,
            46.53413,
            0
          ],
          [
            -115.83224,
            46.53427,
            0
          ],
          [
            -115.83234,
            46.53438,
            0
          ],
          [
            -115.83253,
            46.53455,
            0
          ],
          [
            -115.83273,
            46.53469,
            0
          ],
          [
            -115.83274,
            46.5347,
            0
          ],
          [
            -115.83294,
            46.53484,
            0
          ],
          [
            -115.83307,
            46.53495,
            0
          ],
          [
            -115.83318,
            46.53506,
            0
          ],
          [
            -115.8333,
            46.5352,
            0
          ],
          [
            -115.8335,
            46.53547,
            0
          ],
          [
            -115.8336,
            46.53559,
            0
          ],
          [
            -115.83394,
            46.53608,
            0
          ],
          [
            -115.83549,
            46.53819,
            0
          ],
          [
            -115.83589,
            46.53861,
            0
          ],
          [
            -115.83701,
            46.53951,
            0
          ],
          [
            -115.83731,
            46.53978,
            0
          ],
          [
            -115.83742,
            46.5399,
            0
          ],
          [
            -115.83751,
            46.54003,
            0
          ],
          [
            -115.83755,
            46.54012,
            0
          ],
          [
            -115.83762,
            46.54033,
            0
          ],
          [
            -115.83764,
            46.54049,
            0
          ],
          [
            -115.83763,
            46.54065,
            0
          ],
          [
            -115.83757,
            46.54084,
            0
          ],
          [
            -115.83748,
            46.54102,
            0
          ],
          [
            -115.83743,
            46.5411,
            0
          ],
          [
            -115.83719,
            46.54136,
            0
          ],
          [
            -115.83678,
            46.54171,
            0
          ],
          [
            -115.83662,
            46.54183,
            0
          ],
          [
            -115.83649,
            46.54195,
            0
          ],
          [
            -115.8363,
            46.54218,
            0
          ],
          [
            -115.83618,
            46.54236,
            0
          ],
          [
            -115.83591,
            46.54285,
            0
          ],
          [
            -115.83577,
            46.54315,
            0
          ],
          [
            -115.83562,
            46.54354,
            0
          ],
          [
            -115.83559,
            46.54366,
            0
          ],
          [
            -115.83553,
            46.54384,
            0
          ],
          [
            -115.83553,
            46.5439,
            0
          ],
          [
            -115.83548,
            46.54431,
            0
          ],
          [
            -115.83537,
            46.54603,
            0
          ],
          [
            -115.83537,
            46.54638,
            0
          ],
          [
            -115.83541,
            46.54667,
            0
          ],
          [
            -115.83549,
            46.54695,
            0
          ],
          [
            -115.8357,
            46.54748,
            0
          ],
          [
            -115.83573,
            46.54754,
            0
          ],
          [
            -115.83587,
            46.54789,
            0
          ],
          [
            -115.83597,
            46.54821,
            0
          ],
          [
            -115.83599,
            46.54837,
            0
          ],
          [
            -115.836,
            46.54855,
            0
          ],
          [
            -115.83598,
            46.54881,
            0
          ],
          [
            -115.83594,
            46.54901,
            0
          ],
          [
            -115.83588,
            46.54949,
            0
          ],
          [
            -115.83587,
            46.54968,
            0
          ],
          [
            -115.8358,
            46.55026,
            0
          ],
          [
            -115.8358,
            46.55042,
            0
          ],
          [
            -115.83582,
            46.55058,
            0
          ],
          [
            -115.83586,
            46.55067,
            0
          ],
          [
            -115.8359,
            46.55074,
            0
          ],
          [
            -115.83592,
            46.5508,
            0
          ],
          [
            -115.836,
            46.55094,
            0
          ],
          [
            -115.83615,
            46.55114,
            0
          ],
          [
            -115.83646,
            46.55143,
            0
          ],
          [
            -115.83691,
            46.55189,
            0
          ],
          [
            -115.837,
            46.55203,
            0
          ],
          [
            -115.83713,
            46.55229,
            0
          ],
          [
            -115.83716,
            46.55245,
            0
          ],
          [
            -115.83718,
            46.55285,
            0
          ],
          [
            -115.83714,
            46.55419,
            0
          ],
          [
            -115.83712,
            46.55447,
            0
          ],
          [
            -115.83712,
            46.55459,
            0
          ],
          [
            -115.83708,
            46.55482,
            0
          ],
          [
            -115.83696,
            46.55512,
            0
          ],
          [
            -115.83678,
            46.55537,
            0
          ],
          [
            -115.83552,
            46.55684,
            0
          ],
          [
            -115.83536,
            46.55705,
            0
          ],
          [
            -115.83525,
            46.55716,
            0
          ],
          [
            -115.83502,
            46.55731,
            0
          ],
          [
            -115.83491,
            46.55736,
            0
          ],
          [
            -115.83486,
            46.55737,
            0
          ],
          [
            -115.83481,
            46.55739,
            0
          ],
          [
            -115.83361,
            46.55764,
            0
          ],
          [
            -115.83353,
            46.55765,
            0
          ],
          [
            -115.83336,
            46.5577,
            0
          ],
          [
            -115.83314,
            46.55775,
            0
          ],
          [
            -115.83276,
            46.55786,
            0
          ],
          [
            -115.83185,
            46.55819,
            0
          ],
          [
            -115.83172,
            46.55825,
            0
          ],
          [
            -115.83158,
            46.5583,
            0
          ],
          [
            -115.83061,
            46.55872,
            0
          ],
          [
            -115.8304,
            46.55883,
            0
          ],
          [
            -115.83022,
            46.55894,
            0
          ],
          [
            -115.83009,
            46.55905,
            0
          ],
          [
            -115.82997,
            46.55917,
            0
          ],
          [
            -115.82918,
            46.56017,
            0
          ],
          [
            -115.82848,
            46.56117,
            0
          ],
          [
            -115.8276,
            46.56234,
            0
          ],
          [
            -115.82745,
            46.56252,
            0
          ],
          [
            -115.82735,
            46.56266,
            0
          ],
          [
            -115.82733,
            46.56268,
            0
          ],
          [
            -115.8271,
            46.56297,
            0
          ],
          [
            -115.82683,
            46.56327,
            0
          ],
          [
            -115.82601,
            46.56409,
            0
          ],
          [
            -115.82587,
            46.5642,
            0
          ],
          [
            -115.82554,
            46.56438,
            0
          ],
          [
            -115.82553,
            46.56438,
            0
          ],
          [
            -115.82545,
            46.56442,
            0
          ],
          [
            -115.82436,
            46.56478,
            0
          ],
          [
            -115.82404,
            46.56492,
            0
          ],
          [
            -115.82361,
            46.56515,
            0
          ],
          [
            -115.82353,
            46.56522,
            0
          ],
          [
            -115.82337,
            46.56533,
            0
          ],
          [
            -115.82324,
            46.56545,
            0
          ],
          [
            -115.82314,
            46.56559,
            0
          ],
          [
            -115.82305,
            46.56576,
            0
          ],
          [
            -115.82302,
            46.56583,
            0
          ],
          [
            -115.82297,
            46.56606,
            0
          ],
          [
            -115.82294,
            46.56649,
            0
          ],
          [
            -115.82289,
            46.56677,
            0
          ],
          [
            -115.82278,
            46.56704,
            0
          ],
          [
            -115.82271,
            46.56718,
            0
          ],
          [
            -115.82252,
            46.56743,
            0
          ],
          [
            -115.82211,
            46.56782,
            0
          ],
          [
            -115.82096,
            46.56883,
            0
          ],
          [
            -115.82072,
            46.56906,
            0
          ],
          [
            -115.8206,
            46.5692,
            0
          ],
          [
            -115.82055,
            46.56929,
            0
          ],
          [
            -115.82048,
            46.56951,
            0
          ],
          [
            -115.82042,
            46.56977,
            0
          ],
          [
            -115.82039,
            46.56984,
            0
          ],
          [
            -115.82034,
            46.56991,
            0
          ],
          [
            -115.8198,
            46.57051,
            0
          ],
          [
            -115.81954,
            46.57076,
            0
          ],
          [
            -115.8194,
            46.57087,
            0
          ],
          [
            -115.81925,
            46.57096,
            0
          ],
          [
            -115.81911,
            46.57102,
            0
          ],
          [
            -115.81781,
            46.5715,
            0
          ],
          [
            -115.81765,
            46.57155,
            0
          ],
          [
            -115.8171,
            46.57176,
            0
          ],
          [
            -115.81681,
            46.57189,
            0
          ],
          [
            -115.81664,
            46.57199,
            0
          ],
          [
            -115.81645,
            46.57214,
            0
          ],
          [
            -115.81634,
            46.57227,
            0
          ],
          [
            -115.81629,
            46.57234,
            0
          ],
          [
            -115.81622,
            46.57247,
            0
          ],
          [
            -115.81609,
            46.57288,
            0
          ],
          [
            -115.81604,
            46.57301,
            0
          ],
          [
            -115.81596,
            46.57318,
            0
          ],
          [
            -115.81585,
            46.57332,
            0
          ],
          [
            -115.81574,
            46.57344,
            0
          ],
          [
            -115.81566,
            46.57351,
            0
          ],
          [
            -115.81553,
            46.57359,
            0
          ],
          [
            -115.81538,
            46.57366,
            0
          ],
          [
            -115.81516,
            46.57372,
            0
          ],
          [
            -115.81514,
            46.57372,
            0
          ],
          [
            -115.81502,
            46.57375,
            0
          ],
          [
            -115.8148,
            46.57378,
            0
          ],
          [
            -115.81444,
            46.57386,
            0
          ],
          [
            -115.81417,
            46.57396,
            0
          ],
          [
            -115.8141,
            46.574,
            0
          ],
          [
            -115.81394,
            46.57412,
            0
          ],
          [
            -115.81328,
            46.57475,
            0
          ],
          [
            -115.81322,
            46.57482,
            0
          ],
          [
            -115.81298,
            46.57506,
            0
          ],
          [
            -115.81292,
            46.57511,
            0
          ],
          [
            -115.81277,
            46.57526,
            0
          ],
          [
            -115.81271,
            46.57531,
            0
          ],
          [
            -115.81262,
            46.57541,
            0
          ],
          [
            -115.81172,
            46.57628,
            0
          ],
          [
            -115.81152,
            46.57644,
            0
          ],
          [
            -115.81136,
            46.57653,
            0
          ],
          [
            -115.81009,
            46.57714,
            0
          ],
          [
            -115.80964,
            46.57738,
            0
          ],
          [
            -115.80963,
            46.57739,
            0
          ],
          [
            -115.80922,
            46.57762,
            0
          ],
          [
            -115.80852,
            46.57813,
            0
          ],
          [
            -115.80836,
            46.57827,
            0
          ],
          [
            -115.80822,
            46.57844,
            0
          ],
          [
            -115.80784,
            46.57903,
            0
          ],
          [
            -115.80766,
            46.57925,
            0
          ],
          [
            -115.80747,
            46.57943,
            0
          ],
          [
            -115.80717,
            46.57964,
            0
          ],
          [
            -115.80536,
            46.58077,
            0
          ],
          [
            -115.80522,
            46.58087,
            0
          ],
          [
            -115.80509,
            46.58098,
            0
          ],
          [
            -115.80498,
            46.5811,
            0
          ],
          [
            -115.80479,
            46.58143,
            0
          ],
          [
            -115.8046,
            46.58169,
            0
          ],
          [
            -115.80448,
            46.58181,
            0
          ],
          [
            -115.80409,
            46.58227,
            0
          ],
          [
            -115.80407,
            46.5823,
            0
          ],
          [
            -115.80396,
            46.58242,
            0
          ],
          [
            -115.80396,
            46.58243,
            0
          ],
          [
            -115.80389,
            46.5825,
            0
          ],
          [
            -115.8037,
            46.58262,
            0
          ],
          [
            -115.80354,
            46.58269,
            0
          ],
          [
            -115.80336,
            46.58275,
            0
          ],
          [
            -115.80312,
            46.58281,
            0
          ],
          [
            -115.8031,
            46.58281,
            0
          ],
          [
            -115.80286,
            46.58289,
            0
          ],
          [
            -115.80285,
            46.5829,
            0
          ],
          [
            -115.80279,
            46.58293,
            0
          ],
          [
            -115.80269,
            46.583,
            0
          ],
          [
            -115.80268,
            46.58302,
            0
          ],
          [
            -115.80258,
            46.58309,
            0
          ],
          [
            -115.80247,
            46.5832,
            0
          ],
          [
            -115.80227,
            46.58348,
            0
          ],
          [
            -115.80198,
            46.58378,
            0
          ],
          [
            -115.80151,
            46.58421,
            0
          ],
          [
            -115.80137,
            46.58436,
            0
          ],
          [
            -115.80079,
            46.58507,
            0
          ],
          [
            -115.80051,
            46.58538,
            0
          ],
          [
            -115.80033,
            46.58555,
            0
          ],
          [
            -115.80002,
            46.58581,
            0
          ],
          [
            -115.79877,
            46.58668,
            0
          ],
          [
            -115.79852,
            46.58689,
            0
          ],
          [
            -115.79828,
            46.58714,
            0
          ],
          [
            -115.79819,
            46.58726,
            0
          ],
          [
            -115.79807,
            46.5874,
            0
          ],
          [
            -115.79782,
            46.58776,
            0
          ],
          [
            -115.7975,
            46.58816,
            0
          ],
          [
            -115.7975,
            46.58817,
            0
          ],
          [
            -115.79715,
            46.58859,
            0
          ],
          [
            -115.79711,
            46.58863,
            0
          ],
          [
            -115.7948,
            46.59141,
            0
          ],
          [
            -115.79452,
            46.59177,
            0
          ],
          [
            -115.79416,
            46.59219,
            0
          ],
          [
            -115.79414,
            46.59222,
            0
          ],
          [
            -115.794,
            46.59237,
            0
          ],
          [
            -115.79382,
            46.59252,
            0
          ],
          [
            -115.79361,
            46.59264,
            0
          ],
          [
            -115.79332,
            46.59277,
            0
          ],
          [
            -115.7931,
            46.59283,
            0
          ],
          [
            -115.79309,
            46.59283,
            0
          ],
          [
            -115.79297,
            46.59286,
            0
          ],
          [
            -115.79253,
            46.59291,
            0
          ],
          [
            -115.7858,
            46.59316,
            0
          ],
          [
            -115.78428,
            46.5933,
            0
          ],
          [
            -115.78398,
            46.59334,
            0
          ],
          [
            -115.78384,
            46.59335,
            0
          ],
          [
            -115.7837,
            46.59337,
            0
          ],
          [
            -115.78353,
            46.59341,
            0
          ],
          [
            -115.78348,
            46.59343,
            0
          ],
          [
            -115.78339,
            46.59345,
            0
          ],
          [
            -115.78324,
            46.59351,
            0
          ],
          [
            -115.78322,
            46.59351,
            0
          ],
          [
            -115.78313,
            46.59355,
            0
          ],
          [
            -115.78287,
            46.59371,
            0
          ],
          [
            -115.78238,
            46.59406,
            0
          ],
          [
            -115.7821,
            46.59424,
            0
          ],
          [
            -115.78179,
            46.5945,
            0
          ],
          [
            -115.78175,
            46.59455,
            0
          ],
          [
            -115.7817,
            46.59464,
            0
          ],
          [
            -115.78169,
            46.59468,
            0
          ],
          [
            -115.78168,
            46.59476,
            0
          ],
          [
            -115.78172,
            46.59498,
            0
          ],
          [
            -115.78174,
            46.59505,
            0
          ],
          [
            -115.78174,
            46.59521,
            0
          ],
          [
            -115.78171,
            46.59541,
            0
          ],
          [
            -115.78171,
            46.5955,
            0
          ],
          [
            -115.78172,
            46.59557,
            0
          ],
          [
            -115.78173,
            46.59558,
            0
          ],
          [
            -115.78176,
            46.59568,
            0
          ],
          [
            -115.7818,
            46.59577,
            0
          ],
          [
            -115.78189,
            46.59593,
            0
          ],
          [
            -115.78194,
            46.59604,
            0
          ],
          [
            -115.78195,
            46.5961,
            0
          ],
          [
            -115.78197,
            46.59615,
            0
          ],
          [
            -115.78197,
            46.59624,
            0
          ],
          [
            -115.78196,
            46.59628,
            0
          ],
          [
            -115.78194,
            46.59649,
            0
          ],
          [
            -115.78191,
            46.59667,
            0
          ],
          [
            -115.78192,
            46.59679,
            0
          ],
          [
            -115.78193,
            46.59682,
            0
          ],
          [
            -115.78193,
            46.59684,
            0
          ],
          [
            -115.78198,
            46.59699,
            0
          ],
          [
            -115.78204,
            46.5971,
            0
          ],
          [
            -115.78218,
            46.59728,
            0
          ],
          [
            -115.78222,
            46.59731,
            0
          ],
          [
            -115.78229,
            46.59738,
            0
          ],
          [
            -115.78258,
            46.59761,
            0
          ],
          [
            -115.7827,
            46.59773,
            0
          ],
          [
            -115.7827,
            46.59774,
            0
          ],
          [
            -115.78273,
            46.59778,
            0
          ],
          [
            -115.78277,
            46.59787,
            0
          ],
          [
            -115.78279,
            46.59801,
            0
          ],
          [
            -115.78276,
            46.59826,
            0
          ],
          [
            -115.7827,
            46.59843,
            0
          ],
          [
            -115.7827,
            46.59844,
            0
          ],
          [
            -115.78253,
            46.59904,
            0
          ],
          [
            -115.78253,
            46.59924,
            0
          ],
          [
            -115.78254,
            46.59933,
            0
          ],
          [
            -115.78256,
            46.59981,
            0
          ],
          [
            -115.78254,
            46.60001,
            0
          ],
          [
            -115.78252,
            46.6001,
            0
          ],
          [
            -115.78242,
            46.60037,
            0
          ],
          [
            -115.78238,
            46.60051,
            0
          ],
          [
            -115.78236,
            46.60066,
            0
          ],
          [
            -115.78236,
            46.60076,
            0
          ],
          [
            -115.78239,
            46.6009,
            0
          ],
          [
            -115.78246,
            46.60111,
            0
          ],
          [
            -115.78292,
            46.60192,
            0
          ],
          [
            -115.78294,
            46.60194,
            0
          ],
          [
            -115.78312,
            46.60217,
            0
          ],
          [
            -115.78325,
            46.6023,
            0
          ],
          [
            -115.7834,
            46.6024,
            0
          ],
          [
            -115.78351,
            46.60246,
            0
          ],
          [
            -115.78395,
            46.60264,
            0
          ],
          [
            -115.78403,
            46.60269,
            0
          ],
          [
            -115.78415,
            46.60279,
            0
          ],
          [
            -115.7842,
            46.60292,
            0
          ],
          [
            -115.78421,
            46.60301,
            0
          ],
          [
            -115.78416,
            46.60318,
            0
          ],
          [
            -115.78415,
            46.60319,
            0
          ],
          [
            -115.78392,
            46.60366,
            0
          ],
          [
            -115.78384,
            46.60391,
            0
          ],
          [
            -115.78381,
            46.60405,
            0
          ],
          [
            -115.78384,
            46.60429,
            0
          ],
          [
            -115.78389,
            46.60439,
            0
          ],
          [
            -115.78401,
            46.60453,
            0
          ],
          [
            -115.78405,
            46.60455,
            0
          ],
          [
            -115.78409,
            46.60459,
            0
          ],
          [
            -115.78435,
            46.60473,
            0
          ],
          [
            -115.78495,
            46.60491,
            0
          ],
          [
            -115.78541,
            46.60507,
            0
          ],
          [
            -115.78599,
            46.60531,
            0
          ],
          [
            -115.78632,
            46.60543,
            0
          ],
          [
            -115.78651,
            46.60552,
            0
          ],
          [
            -115.78654,
            46.60553,
            0
          ],
          [
            -115.78673,
            46.60565,
            0
          ],
          [
            -115.78753,
            46.60632,
            0
          ],
          [
            -115.78757,
            46.60634,
            0
          ],
          [
            -115.78806,
            46.60676,
            0
          ],
          [
            -115.78818,
            46.60688,
            0
          ],
          [
            -115.78851,
            46.60729,
            0
          ],
          [
            -115.78858,
            46.60739,
            0
          ],
          [
            -115.78867,
            46.6075,
            0
          ],
          [
            -115.78871,
            46.60754,
            0
          ],
          [
            -115.78886,
            46.60775,
            0
          ],
          [
            -115.78894,
            46.60784,
            0
          ],
          [
            -115.78901,
            46.6079,
            0
          ],
          [
            -115.78907,
            46.60797,
            0
          ],
          [
            -115.78934,
            46.60816,
            0
          ],
          [
            -115.7914,
            46.60898,
            0
          ],
          [
            -115.79155,
            46.60903,
            0
          ],
          [
            -115.79167,
            46.60906,
            0
          ],
          [
            -115.79201,
            46.6091,
            0
          ],
          [
            -115.79232,
            46.60909,
            0
          ],
          [
            -115.79258,
            46.60906,
            0
          ],
          [
            -115.79281,
            46.60905,
            0
          ],
          [
            -115.79312,
            46.60908,
            0
          ],
          [
            -115.79322,
            46.6091,
            0
          ],
          [
            -115.79325,
            46.60911,
            0
          ],
          [
            -115.79345,
            46.60914,
            0
          ],
          [
            -115.79348,
            46.60914,
            0
          ],
          [
            -115.79382,
            46.60922,
            0
          ],
          [
            -115.79396,
            46.60927,
            0
          ],
          [
            -115.79407,
            46.60932,
            0
          ],
          [
            -115.79428,
            46.60945,
            0
          ],
          [
            -115.79459,
            46.60973,
            0
          ],
          [
            -115.79494,
            46.61012,
            0
          ],
          [
            -115.79539,
            46.61047,
            0
          ],
          [
            -115.79578,
            46.61074,
            0
          ],
          [
            -115.79614,
            46.61102,
            0
          ],
          [
            -115.79628,
            46.61111,
            0
          ],
          [
            -115.79649,
            46.61127,
            0
          ],
          [
            -115.79847,
            46.61239,
            0
          ],
          [
            -115.7985,
            46.6124,
            0
          ],
          [
            -115.79915,
            46.61282,
            0
          ],
          [
            -115.79948,
            46.61306,
            0
          ],
          [
            -115.80164,
            46.61475,
            0
          ],
          [
            -115.80182,
            46.61487,
            0
          ],
          [
            -115.8035,
            46.6158,
            0
          ],
          [
            -115.80364,
            46.61585,
            0
          ],
          [
            -115.80388,
            46.61591,
            0
          ],
          [
            -115.80397,
            46.61592,
            0
          ],
          [
            -115.80401,
            46.61593,
            0
          ],
          [
            -115.8047,
            46.616,
            0
          ],
          [
            -115.80487,
            46.61603,
            0
          ],
          [
            -115.80507,
            46.61608,
            0
          ],
          [
            -115.80533,
            46.61617,
            0
          ],
          [
            -115.80548,
            46.61624,
            0
          ],
          [
            -115.80569,
            46.61636,
            0
          ],
          [
            -115.80589,
            46.61652,
            0
          ],
          [
            -115.8061,
            46.61675,
            0
          ],
          [
            -115.8062,
            46.6169,
            0
          ],
          [
            -115.80816,
            46.62025,
            0
          ],
          [
            -115.80817,
            46.62028,
            0
          ],
          [
            -115.80822,
            46.62035,
            0
          ],
          [
            -115.80838,
            46.62067,
            0
          ],
          [
            -115.80843,
            46.62083,
            0
          ],
          [
            -115.80844,
            46.62091,
            0
          ],
          [
            -115.80844,
            46.62097,
            0
          ],
          [
            -115.80843,
            46.62106,
            0
          ],
          [
            -115.80837,
            46.62125,
            0
          ],
          [
            -115.80816,
            46.62149,
            0
          ],
          [
            -115.80786,
            46.62169,
            0
          ],
          [
            -115.80775,
            46.6218,
            0
          ],
          [
            -115.80762,
            46.62198,
            0
          ],
          [
            -115.80761,
            46.62204,
            0
          ],
          [
            -115.80761,
            46.62215,
            0
          ],
          [
            -115.80765,
            46.62232,
            0
          ],
          [
            -115.80768,
            46.62238,
            0
          ],
          [
            -115.80775,
            46.62246,
            0
          ],
          [
            -115.80802,
            46.62269,
            0
          ],
          [
            -115.80809,
            46.62276,
            0
          ],
          [
            -115.80814,
            46.62284,
            0
          ],
          [
            -115.80817,
            46.6229,
            0
          ],
          [
            -115.80821,
            46.62305,
            0
          ],
          [
            -115.80822,
            46.62313,
            0
          ],
          [
            -115.8082,
            46.6232,
            0
          ],
          [
            -115.80819,
            46.62328,
            0
          ],
          [
            -115.80814,
            46.62337,
            0
          ],
          [
            -115.80808,
            46.62346,
            0
          ],
          [
            -115.80765,
            46.62401,
            0
          ],
          [
            -115.8075,
            46.62417,
            0
          ],
          [
            -115.80742,
            46.6243,
            0
          ],
          [
            -115.8074,
            46.62432,
            0
          ],
          [
            -115.80736,
            46.62439,
            0
          ],
          [
            -115.80732,
            46.62448,
            0
          ],
          [
            -115.80732,
            46.62449,
            0
          ],
          [
            -115.8073,
            46.62457,
            0
          ],
          [
            -115.8073,
            46.62467,
            0
          ],
          [
            -115.80731,
            46.62475,
            0
          ],
          [
            -115.80733,
            46.62482,
            0
          ],
          [
            -115.80735,
            46.62485,
            0
          ],
          [
            -115.80737,
            46.6249,
            0
          ],
          [
            -115.80743,
            46.62498,
            0
          ],
          [
            -115.8075,
            46.62505,
            0
          ],
          [
            -115.80759,
            46.62512,
            0
          ],
          [
            -115.80773,
            46.62521,
            0
          ],
          [
            -115.80804,
            46.62537,
            0
          ],
          [
            -115.8082,
            46.6255,
            0
          ],
          [
            -115.80832,
            46.62563,
            0
          ],
          [
            -115.80836,
            46.6257,
            0
          ],
          [
            -115.80848,
            46.62601,
            0
          ],
          [
            -115.80848,
            46.62603,
            0
          ],
          [
            -115.80853,
            46.62614,
            0
          ],
          [
            -115.80856,
            46.62618,
            0
          ],
          [
            -115.80869,
            46.62643,
            0
          ],
          [
            -115.80877,
            46.62665,
            0
          ],
          [
            -115.8088,
            46.62679,
            0
          ],
          [
            -115.80892,
            46.62712,
            0
          ],
          [
            -115.80901,
            46.62728,
            0
          ],
          [
            -115.80912,
            46.62745,
            0
          ],
          [
            -115.80926,
            46.6276,
            0
          ],
          [
            -115.80949,
            46.62776,
            0
          ],
          [
            -115.80972,
            46.62788,
            0
          ],
          [
            -115.81026,
            46.62821,
            0
          ],
          [
            -115.81058,
            46.62835,
            0
          ],
          [
            -115.81088,
            46.62841,
            0
          ],
          [
            -115.81124,
            46.62846,
            0
          ],
          [
            -115.81137,
            46.62849,
            0
          ],
          [
            -115.81143,
            46.62851,
            0
          ],
          [
            -115.81158,
            46.62858,
            0
          ],
          [
            -115.81163,
            46.62861,
            0
          ],
          [
            -115.81169,
            46.62866,
            0
          ],
          [
            -115.81174,
            46.62869,
            0
          ],
          [
            -115.81179,
            46.62875,
            0
          ],
          [
            -115.81184,
            46.62884,
            0
          ],
          [
            -115.81186,
            46.6289,
            0
          ],
          [
            -115.81186,
            46.62899,
            0
          ],
          [
            -115.81184,
            46.62909,
            0
          ],
          [
            -115.8118,
            46.62918,
            0
          ],
          [
            -115.81174,
            46.62928,
            0
          ],
          [
            -115.81142,
            46.62966,
            0
          ],
          [
            -115.81132,
            46.6298,
            0
          ],
          [
            -115.81115,
            46.63,
            0
          ],
          [
            -115.81095,
            46.63027,
            0
          ],
          [
            -115.81048,
            46.63012,
            0
          ],
          [
            -115.80946,
            46.62972,
            0
          ]
        ],
			},
			id: "86cd5e29-e6eb-4279-b976-7e5546c42965",
		},
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.1751946,
    //       46.3898826,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Greer, ID"
    //   },
    //   id: "e32856a2-4c06-400a-9052-2f414995a477"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -115.9382079,
    //       46.3760174,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Weippe, ID"
    //   },
    //   id: "ad6ac78b-b6f9-4fe9-8b1e-45c296a60262"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -115.7990115,
    //       46.4910204,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Pierce, ID"
    //   },
    //   id: "167ae9da-b579-4b73-b2ab-4146d8ab6749"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -115.8094595,
    //       46.6297177,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Headquarters, ID"
    //   },
    //   id: "f64eb60a-0f44-4187-825a-75018d0d0865"
    // }
	],
};

export default GoldRushHistoric;
