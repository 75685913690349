export const findRelevance = (array, query) => {
  const settings = {
    query: [query],
    data: array,
    rankings: {
      title: 5,
      excerpt: 1,
    },
  };

  const getIndicesOf = (searchStr, str, caseSensitive) => {
    let startIndex = 0;
    const searchStrLen = searchStr.length;
    let index;
    const indices = [];

    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  };

  return settings.data.sort((first, next) => {
    let firstScore = 0;
    let nextScore = 0;
    settings.query.forEach((element, index, array) => {
      if (element && typeof element == "string") {
        element = element.trim().toLowerCase();
        for (let prop in settings.rankings) {
          const value = settings.rankings[prop];
          const firstSearch = getIndicesOf(element, first[prop], false);
          const nextSearch = getIndicesOf(element, next[prop], false);

          if (firstSearch.length) {
            firstScore += value * firstSearch.length;
          }
          if (nextSearch.length) {
            nextScore += value * nextSearch.length;
          }
        }
      }
    });

    if (firstScore > nextScore) {
      return -1;
    } else if (firstScore < nextScore) {
      return 1;
    } else {
      return 0;
    }
  });
  
};
