import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../../store/store";
import axios from "axios";
import TravelTipCard from "../Cards/TravelTipCard";

const RecommendedTravelTips = ({maxLength}) => {
    const [recommendations, setRecommendations] = useState([]);
    const cart = useSelector((state) => state.cart.cartItems);
    const ttcart = useSelector((state) => state.ttcart.cartItems);
    const maxLengthProp = maxLength ? maxLength : 4;

    useEffect(() => {
        const fetchRecommendations = async () => {
            let cartMarkers = store.getState().cart.cartItems;
            let arrayOfCats = [];
            let arrayOfRegions = [];
            cartMarkers.map((marker, i) => {
                marker['categories'].map((cat, j) => {
                    arrayOfCats.push(cat);
                });
                arrayOfRegions.push(marker['region']);
            });
            arrayOfCats = [...new Set(arrayOfCats)];
            arrayOfRegions = [...new Set(arrayOfRegions)];
            let response = await axios
            .get("https://visitidaho.org/wp-json/vita_route/recommended_activities/?categories=" + encodeURIComponent(arrayOfCats));
            //.get("https://visitidaho.org/wp-json/vita_route/recommended_activities/?categories=" + encodeURIComponent(arrayOfCats) + "&regions=" + encodeURIComponent(arrayOfRegions));
            setRecommendations(response.data);
        }
        fetchRecommendations();
    }, [cart])


    return (
        <>
            {(recommendations && recommendations.length > 0) &&
                <div className="recommended tt">
                    <h3>Suggested Travel Tips</h3>
                    <p>Read related stories for more Idaho vacation inspiration.</p>
                    <div className="cards-list">
                        {recommendations.map((result, i) => {
                            // return (
                            //     // <>
                            //     //     {i < maxLengthProp ?
                            //     //         <TravelTipCard item={result} /> : null}
                            //     // </>
                            //     <TravelTipCard item={result} />
                            // )

                            if (!ttcart.some(item => item.id === result.id)) {
                                return (
                                    <TravelTipCard item={result} />
                                )
                            }
                            return null;
                        }
                        )}
                    </div>
                </div>
            }
        </>
    );
}

export default RecommendedTravelTips;