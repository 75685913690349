// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyAZQFzrxtcgTuBIyPqG84-LYY_qqbPFidI",
    authDomain: "visitidaho-itinerary-tool.firebaseapp.com",
    projectId: "visitidaho-itinerary-tool",
    storageBucket: "visitidaho-itinerary-tool.appspot.com",
    messagingSenderId: "859004936124",
    appId: "1:859004936124:web:101aabfbf16e32f1df55b9",
    //measurementId: "G-0J0PM0TF7G"
};

const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const firestore = getFirestore(app);

export { firestore }

export default app