import React, { useEffect, useState } from 'react';
import SidePanelCard from '../Cards/SidePanelCard';
import { useSelector } from "react-redux";

function SidePanel(props) {
    const [features, setFeatures] = useState([]);
    const map = props.map;
    const activities = useSelector(state => state.activities.selectedActivities);

    const mapHandler = () => {
        let tempArr = [];
        setFeatures(tempArr);
        if (map) {
            map.eachLayer(function (layer) {
                if (layer instanceof L.Marker && layer.options.data) {
                    if (map.getBounds().contains(layer.getLatLng())) {
                        if (!layer.feature) {
                            let item = {
                                id: layer._leaflet_id,
                                data: layer.options.data
                            }
                            tempArr.push(item);
                        }
                    }
                }
            });
            map.invalidateSize();
        }
        //forces the list to display as A-Z instead of Z-A
        setFeatures(tempArr.reverse());
    }

    useEffect(() => {
        setTimeout(() => {
            mapHandler();
        }, 100);
    }, [activities]);

    useEffect(() => {
        if (map) {
            map.on('move zoom resize', mapHandler);
        }
    }, [map]);

    useEffect(() => {
        if (props.className === 'show' && map) {
            mapHandler();
        }
    }, [props.className]);

    const openMarker = (id) => {
        map._layers[id].fire('click');
    }

    return (
        <>
            <aside className={`side-panel ${props.className}`}>
                <a onClick={props.closeSidePanel} className="side-panel-mobile">
                    <span className="toggle-bar">
                        <span></span>
                    </span>
                    List View
                </a>
                <div className="side-panel-header">
                    {props.header}
                </div>
                <div className="side-panel-content">
                    {features.length > 0 &&
                        features.map((item, index) => (
                            <SidePanelCard
                            key={index} 
                            toggleMarker={openMarker.bind(this)}
                            markerId={item.id}
                            item={item.data}  />
                        ))
                    }
                </div>
            </aside>
        </>
    );
}

export default SidePanel;