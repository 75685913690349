import { createReducer } from "@reduxjs/toolkit";
import { addToCart, removeFromCart, clearCart, sortCart } from "../actions/cartActions";
import { vitaDataLayer } from "../../helpers/dataLayer";

const initialState = {
    cartItems: []
}

const cartReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(addToCart, (state, action) => {
            state.cartItems.findIndex(item => item.id == action.payload.id) === -1 && state.cartItems.push(action.payload);
            vitaDataLayer('cart', 'add_ac_to_cart', action.payload.title);
        })
        .addCase(removeFromCart, (state, action) => {
            state.cartItems = state.cartItems.filter((item) => item.id !== action.payload);
        })
        .addCase(sortCart, (state, action) => {
            state.cartItems = action.payload;
        })
        .addCase(clearCart, (state, action) => {
            state.cartItems = [];
        })
});

export default cartReducer;