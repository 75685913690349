import { createReducer } from "@reduxjs/toolkit";
import { setLastItem } from "../actions/lastItemActions";

const initialState = {
    lastItem: []
}

const lastItemReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setLastItem, (state, action) => {
            state.lastItem = action.payload;
        })
});

export default lastItemReducer;