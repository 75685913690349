const SacajaweaHistoric = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Fort Henry Historic Byway",
				description: "Tales of the past surround you on every side as you drive the Sacajawea Historic Byway. Parallel to the Continental Divide, this byway makes its way through the Lemhi River Valley, summits the Lemhi Pass and then drops onto the Snake River Plain. Discover rich Native American history and learn more about Sacajawea's story and her impact on the Corps of Discovery and Idaho.",
				link: "https://visitidaho.org/things-to-do/road-trips/sacajawea-historic-byway/",
				route: [
					"Terreton", 
					"Leadore", 
					"Tendoy",
          "Salmon"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
          [
            -112.43577,
            43.84136,
            0
          ],
          [
            -112.4392,
            43.84137,
            0
          ],
          [
            -112.44003,
            43.84136,
            0
          ],
          [
            -112.45222,
            43.84135,
            0
          ],
          [
            -112.4535,
            43.84136,
            0
          ],
          [
            -112.47362,
            43.84131,
            0
          ],
          [
            -112.47406,
            43.8413,
            0
          ],
          [
            -112.47867,
            43.8413,
            0
          ],
          [
            -112.47952,
            43.84129,
            0
          ],
          [
            -112.48373,
            43.84129,
            0
          ],
          [
            -112.48441,
            43.84128,
            0
          ],
          [
            -112.48508,
            43.84129,
            0
          ],
          [
            -112.48567,
            43.84128,
            0
          ],
          [
            -112.4934,
            43.84127,
            0
          ],
          [
            -112.49529,
            43.84128,
            0
          ],
          [
            -112.49566,
            43.84134,
            0
          ],
          [
            -112.49572,
            43.84134,
            0
          ],
          [
            -112.49577,
            43.84135,
            0
          ],
          [
            -112.49593,
            43.84136,
            0
          ],
          [
            -112.49611,
            43.84138,
            0
          ],
          [
            -112.49625,
            43.8414,
            0
          ],
          [
            -112.49643,
            43.84144,
            0
          ],
          [
            -112.49646,
            43.84145,
            0
          ],
          [
            -112.49662,
            43.84149,
            0
          ],
          [
            -112.49689,
            43.84155,
            0
          ],
          [
            -112.49698,
            43.84158,
            0
          ],
          [
            -112.49706,
            43.8416,
            0
          ],
          [
            -112.49734,
            43.8417,
            0
          ],
          [
            -112.49747,
            43.84174,
            0
          ],
          [
            -112.4976,
            43.84179,
            0
          ],
          [
            -112.49772,
            43.84185,
            0
          ],
          [
            -112.49785,
            43.8419,
            0
          ],
          [
            -112.49853,
            43.84224,
            0
          ],
          [
            -112.51483,
            43.85249,
            0
          ],
          [
            -112.51502,
            43.8526,
            0
          ],
          [
            -112.52602,
            43.8595,
            0
          ],
          [
            -112.52658,
            43.85986,
            0
          ],
          [
            -112.53593,
            43.86572,
            0
          ],
          [
            -112.53674,
            43.86624,
            0
          ],
          [
            -112.5463,
            43.87223,
            0
          ],
          [
            -112.54631,
            43.87223,
            0
          ],
          [
            -112.56252,
            43.8824,
            0
          ],
          [
            -112.56327,
            43.88286,
            0
          ],
          [
            -112.56624,
            43.88472,
            0
          ],
          [
            -112.56637,
            43.88481,
            0
          ],
          [
            -112.57423,
            43.88973,
            0
          ],
          [
            -112.57503,
            43.89024,
            0
          ],
          [
            -112.5859,
            43.89704,
            0
          ],
          [
            -112.58603,
            43.89713,
            0
          ],
          [
            -112.58607,
            43.89715,
            0
          ],
          [
            -112.5894,
            43.89924,
            0
          ],
          [
            -112.5896,
            43.89936,
            0
          ],
          [
            -112.59072,
            43.90006,
            0
          ],
          [
            -112.59114,
            43.90033,
            0
          ],
          [
            -112.59339,
            43.90173,
            0
          ],
          [
            -112.59349,
            43.9018,
            0
          ],
          [
            -112.59706,
            43.90401,
            0
          ],
          [
            -112.59746,
            43.90427,
            0
          ],
          [
            -112.59921,
            43.90536,
            0
          ],
          [
            -112.59931,
            43.90543,
            0
          ],
          [
            -112.60477,
            43.90884,
            0
          ],
          [
            -112.60714,
            43.91034,
            0
          ],
          [
            -112.61636,
            43.91611,
            0
          ],
          [
            -112.61666,
            43.91629,
            0
          ],
          [
            -112.62867,
            43.9238,
            0
          ],
          [
            -112.62917,
            43.92412,
            0
          ],
          [
            -112.62947,
            43.9243,
            0
          ],
          [
            -112.63145,
            43.92554,
            0
          ],
          [
            -112.63176,
            43.92574,
            0
          ],
          [
            -112.63229,
            43.92606,
            0
          ],
          [
            -112.64223,
            43.93228,
            0
          ],
          [
            -112.64272,
            43.93258,
            0
          ],
          [
            -112.64487,
            43.93394,
            0
          ],
          [
            -112.6458,
            43.93451,
            0
          ],
          [
            -112.64761,
            43.93565,
            0
          ],
          [
            -112.64902,
            43.93652,
            0
          ],
          [
            -112.65242,
            43.93866,
            0
          ],
          [
            -112.65324,
            43.93916,
            0
          ],
          [
            -112.66356,
            43.9456,
            0
          ],
          [
            -112.66449,
            43.94619,
            0
          ],
          [
            -112.67393,
            43.95207,
            0
          ],
          [
            -112.67413,
            43.9522,
            0
          ],
          [
            -112.68752,
            43.96055,
            0
          ],
          [
            -112.68771,
            43.96066,
            0
          ],
          [
            -112.688,
            43.96085,
            0
          ],
          [
            -112.70471,
            43.97129,
            0
          ],
          [
            -112.7048,
            43.97134,
            0
          ],
          [
            -112.70584,
            43.97198,
            0
          ],
          [
            -112.70682,
            43.9726,
            0
          ],
          [
            -112.70725,
            43.97286,
            0
          ],
          [
            -112.70809,
            43.97339,
            0
          ],
          [
            -112.70838,
            43.97356,
            0
          ],
          [
            -112.70866,
            43.97374,
            0
          ],
          [
            -112.71456,
            43.97742,
            0
          ],
          [
            -112.71512,
            43.97778,
            0
          ],
          [
            -112.71626,
            43.97848,
            0
          ],
          [
            -112.71851,
            43.97989,
            0
          ],
          [
            -112.71892,
            43.98014,
            0
          ],
          [
            -112.71934,
            43.98041,
            0
          ],
          [
            -112.73145,
            43.98795,
            0
          ],
          [
            -112.73191,
            43.98822,
            0
          ],
          [
            -112.73205,
            43.98832,
            0
          ],
          [
            -112.73234,
            43.9885,
            0
          ],
          [
            -112.73235,
            43.9885,
            0
          ],
          [
            -112.73445,
            43.98982,
            0
          ],
          [
            -112.73658,
            43.99113,
            0
          ],
          [
            -112.73846,
            43.99232,
            0
          ],
          [
            -112.73865,
            43.99243,
            0
          ],
          [
            -112.74111,
            43.99396,
            0
          ],
          [
            -112.74186,
            43.99444,
            0
          ],
          [
            -112.74332,
            43.99534,
            0
          ],
          [
            -112.74358,
            43.99549,
            0
          ],
          [
            -112.74566,
            43.9968,
            0
          ],
          [
            -112.75137,
            44.00033,
            0
          ],
          [
            -112.75196,
            44.00071,
            0
          ],
          [
            -112.75464,
            44.0024,
            0
          ],
          [
            -112.76191,
            44.0069,
            0
          ],
          [
            -112.76679,
            44.00995,
            0
          ],
          [
            -112.76943,
            44.01158,
            0
          ],
          [
            -112.77095,
            44.01254,
            0
          ],
          [
            -112.77398,
            44.01441,
            0
          ],
          [
            -112.77513,
            44.01514,
            0
          ],
          [
            -112.77616,
            44.01577,
            0
          ],
          [
            -112.78496,
            44.02124,
            0
          ],
          [
            -112.78597,
            44.0219,
            0
          ],
          [
            -112.78653,
            44.02229,
            0
          ],
          [
            -112.78696,
            44.02258,
            0
          ],
          [
            -112.78749,
            44.02298,
            0
          ],
          [
            -112.7876,
            44.02307,
            0
          ],
          [
            -112.78803,
            44.02339,
            0
          ],
          [
            -112.78864,
            44.02387,
            0
          ],
          [
            -112.78965,
            44.0247,
            0
          ],
          [
            -112.79128,
            44.02619,
            0
          ],
          [
            -112.79394,
            44.02869,
            0
          ],
          [
            -112.79532,
            44.02997,
            0
          ],
          [
            -112.79571,
            44.03032,
            0
          ],
          [
            -112.79698,
            44.03153,
            0
          ],
          [
            -112.80826,
            44.04206,
            0
          ],
          [
            -112.80904,
            44.0428,
            0
          ],
          [
            -112.81001,
            44.04369,
            0
          ],
          [
            -112.81926,
            44.05236,
            0
          ],
          [
            -112.82512,
            44.05781,
            0
          ],
          [
            -112.82962,
            44.06203,
            0
          ],
          [
            -112.83019,
            44.06255,
            0
          ],
          [
            -112.83201,
            44.06425,
            0
          ],
          [
            -112.83234,
            44.06455,
            0
          ],
          [
            -112.83563,
            44.06764,
            0
          ],
          [
            -112.83614,
            44.0681,
            0
          ],
          [
            -112.84099,
            44.07264,
            0
          ],
          [
            -112.84124,
            44.07286,
            0
          ],
          [
            -112.84279,
            44.07431,
            0
          ],
          [
            -112.84391,
            44.07534,
            0
          ],
          [
            -112.85335,
            44.08415,
            0
          ],
          [
            -112.85442,
            44.08517,
            0
          ],
          [
            -112.8682,
            44.09802,
            0
          ],
          [
            -112.86828,
            44.0981,
            0
          ],
          [
            -112.87125,
            44.10087,
            0
          ],
          [
            -112.87198,
            44.10154,
            0
          ],
          [
            -112.87233,
            44.10184,
            0
          ],
          [
            -112.87307,
            44.10257,
            0
          ],
          [
            -112.87502,
            44.10438,
            0
          ],
          [
            -112.87564,
            44.10497,
            0
          ],
          [
            -112.87628,
            44.1056,
            0
          ],
          [
            -112.87691,
            44.10626,
            0
          ],
          [
            -112.87781,
            44.10728,
            0
          ],
          [
            -112.87829,
            44.10785,
            0
          ],
          [
            -112.87894,
            44.10869,
            0
          ],
          [
            -112.8793,
            44.10918,
            0
          ],
          [
            -112.87969,
            44.10973,
            0
          ],
          [
            -112.87978,
            44.10985,
            0
          ],
          [
            -112.88013,
            44.11038,
            0
          ],
          [
            -112.88066,
            44.11123,
            0
          ],
          [
            -112.88127,
            44.11224,
            0
          ],
          [
            -112.88278,
            44.1148,
            0
          ],
          [
            -112.88401,
            44.11684,
            0
          ],
          [
            -112.88735,
            44.12245,
            0
          ],
          [
            -112.88764,
            44.12295,
            0
          ],
          [
            -112.8901,
            44.12706,
            0
          ],
          [
            -112.89121,
            44.12894,
            0
          ],
          [
            -112.8918,
            44.12991,
            0
          ],
          [
            -112.89224,
            44.13066,
            0
          ],
          [
            -112.89225,
            44.13068,
            0
          ],
          [
            -112.89234,
            44.13082,
            0
          ],
          [
            -112.89709,
            44.13875,
            0
          ],
          [
            -112.89748,
            44.13943,
            0
          ],
          [
            -112.8977,
            44.13975,
            0
          ],
          [
            -112.89791,
            44.1401,
            0
          ],
          [
            -112.89876,
            44.14129,
            0
          ],
          [
            -112.89918,
            44.14183,
            0
          ],
          [
            -112.89935,
            44.14207,
            0
          ],
          [
            -112.89994,
            44.1428,
            0
          ],
          [
            -112.9006,
            44.14356,
            0
          ],
          [
            -112.90265,
            44.14578,
            0
          ],
          [
            -112.90271,
            44.14584,
            0
          ],
          [
            -112.90356,
            44.14678,
            0
          ],
          [
            -112.9092,
            44.1529,
            0
          ],
          [
            -112.90981,
            44.15359,
            0
          ],
          [
            -112.90999,
            44.15378,
            0
          ],
          [
            -112.91061,
            44.15448,
            0
          ],
          [
            -112.91075,
            44.15465,
            0
          ],
          [
            -112.91084,
            44.15475,
            0
          ],
          [
            -112.91151,
            44.15558,
            0
          ],
          [
            -112.91202,
            44.15629,
            0
          ],
          [
            -112.91249,
            44.15696,
            0
          ],
          [
            -112.9155,
            44.16153,
            0
          ],
          [
            -112.91572,
            44.16188,
            0
          ],
          [
            -112.9167,
            44.16336,
            0
          ],
          [
            -112.91688,
            44.16362,
            0
          ],
          [
            -112.91721,
            44.16406,
            0
          ],
          [
            -112.91753,
            44.16445,
            0
          ],
          [
            -112.91783,
            44.1648,
            0
          ],
          [
            -112.91827,
            44.16528,
            0
          ],
          [
            -112.92173,
            44.16898,
            0
          ],
          [
            -112.92257,
            44.16986,
            0
          ],
          [
            -112.92517,
            44.17264,
            0
          ],
          [
            -112.92521,
            44.17268,
            0
          ],
          [
            -112.9259,
            44.17342,
            0
          ],
          [
            -112.92605,
            44.17357,
            0
          ],
          [
            -112.93053,
            44.17835,
            0
          ],
          [
            -112.93059,
            44.17842,
            0
          ],
          [
            -112.93284,
            44.18081,
            0
          ],
          [
            -112.93319,
            44.18119,
            0
          ],
          [
            -112.9333,
            44.18132,
            0
          ],
          [
            -112.93343,
            44.18146,
            0
          ],
          [
            -112.93354,
            44.1816,
            0
          ],
          [
            -112.93364,
            44.18171,
            0
          ],
          [
            -112.93382,
            44.18196,
            0
          ],
          [
            -112.93397,
            44.18221,
            0
          ],
          [
            -112.93408,
            44.18241,
            0
          ],
          [
            -112.93419,
            44.18263,
            0
          ],
          [
            -112.93424,
            44.18274,
            0
          ],
          [
            -112.93429,
            44.18286,
            0
          ],
          [
            -112.93443,
            44.18329,
            0
          ],
          [
            -112.93445,
            44.18341,
            0
          ],
          [
            -112.93452,
            44.18374,
            0
          ],
          [
            -112.93456,
            44.18418,
            0
          ],
          [
            -112.93457,
            44.18434,
            0
          ],
          [
            -112.93459,
            44.18452,
            0
          ],
          [
            -112.9346,
            44.1847,
            0
          ],
          [
            -112.9346,
            44.18472,
            0
          ],
          [
            -112.93462,
            44.18488,
            0
          ],
          [
            -112.93462,
            44.18498,
            0
          ],
          [
            -112.93463,
            44.18514,
            0
          ],
          [
            -112.93465,
            44.18526,
            0
          ],
          [
            -112.93466,
            44.18543,
            0
          ],
          [
            -112.93471,
            44.18571,
            0
          ],
          [
            -112.93475,
            44.18587,
            0
          ],
          [
            -112.93478,
            44.18595,
            0
          ],
          [
            -112.93482,
            44.18608,
            0
          ],
          [
            -112.93484,
            44.18613,
            0
          ],
          [
            -112.93486,
            44.18617,
            0
          ],
          [
            -112.9349,
            44.18628,
            0
          ],
          [
            -112.93504,
            44.18658,
            0
          ],
          [
            -112.93511,
            44.18668,
            0
          ],
          [
            -112.93522,
            44.18686,
            0
          ],
          [
            -112.93529,
            44.18695,
            0
          ],
          [
            -112.93534,
            44.18702,
            0
          ],
          [
            -112.9354,
            44.18709,
            0
          ],
          [
            -112.93549,
            44.18721,
            0
          ],
          [
            -112.93572,
            44.18744,
            0
          ],
          [
            -112.93609,
            44.18777,
            0
          ],
          [
            -112.93611,
            44.18777,
            0
          ],
          [
            -112.93624,
            44.18788,
            0
          ],
          [
            -112.93678,
            44.18829,
            0
          ],
          [
            -112.93715,
            44.18855,
            0
          ],
          [
            -112.93729,
            44.18866,
            0
          ],
          [
            -112.9375,
            44.18881,
            0
          ],
          [
            -112.93809,
            44.18925,
            0
          ],
          [
            -112.93819,
            44.18933,
            0
          ],
          [
            -112.93837,
            44.18946,
            0
          ],
          [
            -112.93854,
            44.18959,
            0
          ],
          [
            -112.93874,
            44.18973,
            0
          ],
          [
            -112.94006,
            44.19072,
            0
          ],
          [
            -112.94011,
            44.19076,
            0
          ],
          [
            -112.94046,
            44.19101,
            0
          ],
          [
            -112.94071,
            44.19121,
            0
          ],
          [
            -112.9408,
            44.19127,
            0
          ],
          [
            -112.94437,
            44.19393,
            0
          ],
          [
            -112.94446,
            44.19399,
            0
          ],
          [
            -112.94463,
            44.19412,
            0
          ],
          [
            -112.94464,
            44.19413,
            0
          ],
          [
            -112.94551,
            44.19477,
            0
          ],
          [
            -112.94556,
            44.19481,
            0
          ],
          [
            -112.94609,
            44.1952,
            0
          ],
          [
            -112.94672,
            44.19568,
            0
          ],
          [
            -112.94676,
            44.19572,
            0
          ],
          [
            -112.94688,
            44.19581,
            0
          ],
          [
            -112.94712,
            44.196,
            0
          ],
          [
            -112.94737,
            44.19621,
            0
          ],
          [
            -112.9476,
            44.19643,
            0
          ],
          [
            -112.94795,
            44.19675,
            0
          ],
          [
            -112.94828,
            44.19708,
            0
          ],
          [
            -112.94885,
            44.19768,
            0
          ],
          [
            -112.94891,
            44.19774,
            0
          ],
          [
            -112.94981,
            44.19877,
            0
          ],
          [
            -112.9501,
            44.19912,
            0
          ],
          [
            -112.95822,
            44.20851,
            0
          ],
          [
            -112.9588,
            44.20917,
            0
          ],
          [
            -112.96229,
            44.21321,
            0
          ],
          [
            -112.96317,
            44.21419,
            0
          ],
          [
            -112.96364,
            44.21467,
            0
          ],
          [
            -112.96381,
            44.21485,
            0
          ],
          [
            -112.96449,
            44.21551,
            0
          ],
          [
            -112.96949,
            44.22026,
            0
          ],
          [
            -112.97007,
            44.2208,
            0
          ],
          [
            -112.97108,
            44.22177,
            0
          ],
          [
            -112.9727,
            44.22329,
            0
          ],
          [
            -112.97325,
            44.22383,
            0
          ],
          [
            -112.9735,
            44.22409,
            0
          ],
          [
            -112.97377,
            44.22439,
            0
          ],
          [
            -112.97419,
            44.22491,
            0
          ],
          [
            -112.97434,
            44.22512,
            0
          ],
          [
            -112.97459,
            44.22549,
            0
          ],
          [
            -112.97473,
            44.22572,
            0
          ],
          [
            -112.97495,
            44.2261,
            0
          ],
          [
            -112.97509,
            44.22636,
            0
          ],
          [
            -112.97527,
            44.22675,
            0
          ],
          [
            -112.97531,
            44.22689,
            0
          ],
          [
            -112.97544,
            44.2272,
            0
          ],
          [
            -112.97584,
            44.22824,
            0
          ],
          [
            -112.97614,
            44.22905,
            0
          ],
          [
            -112.97944,
            44.23758,
            0
          ],
          [
            -112.97947,
            44.23768,
            0
          ],
          [
            -112.97969,
            44.23824,
            0
          ],
          [
            -112.98013,
            44.23941,
            0
          ],
          [
            -112.98104,
            44.24176,
            0
          ],
          [
            -112.98159,
            44.24323,
            0
          ],
          [
            -112.98233,
            44.24514,
            0
          ],
          [
            -112.98261,
            44.24589,
            0
          ],
          [
            -112.98427,
            44.25018,
            0
          ],
          [
            -112.98438,
            44.25045,
            0
          ],
          [
            -112.98475,
            44.25144,
            0
          ],
          [
            -112.98506,
            44.25222,
            0
          ],
          [
            -112.98519,
            44.25253,
            0
          ],
          [
            -112.98523,
            44.2526,
            0
          ],
          [
            -112.98556,
            44.25332,
            0
          ],
          [
            -112.98571,
            44.25361,
            0
          ],
          [
            -112.98584,
            44.25382,
            0
          ],
          [
            -112.98604,
            44.2542,
            0
          ],
          [
            -112.9862,
            44.25452,
            0
          ],
          [
            -112.9863,
            44.25471,
            0
          ],
          [
            -112.98688,
            44.25569,
            0
          ],
          [
            -112.98916,
            44.25941,
            0
          ],
          [
            -112.98925,
            44.25955,
            0
          ],
          [
            -112.98985,
            44.26053,
            0
          ],
          [
            -112.98994,
            44.2607,
            0
          ],
          [
            -112.99129,
            44.2629,
            0
          ],
          [
            -112.99189,
            44.26386,
            0
          ],
          [
            -112.99262,
            44.26507,
            0
          ],
          [
            -112.99315,
            44.26593,
            0
          ],
          [
            -112.99571,
            44.27016,
            0
          ],
          [
            -112.99629,
            44.27109,
            0
          ],
          [
            -112.99755,
            44.27317,
            0
          ],
          [
            -112.99863,
            44.27492,
            0
          ],
          [
            -112.99976,
            44.2768,
            0
          ],
          [
            -113.00058,
            44.27812,
            0
          ],
          [
            -113.00099,
            44.27875,
            0
          ],
          [
            -113.00187,
            44.28025,
            0
          ],
          [
            -113.00269,
            44.28155,
            0
          ],
          [
            -113.00306,
            44.28218,
            0
          ],
          [
            -113.00446,
            44.28444,
            0
          ],
          [
            -113.00466,
            44.28478,
            0
          ],
          [
            -113.005,
            44.28532,
            0
          ],
          [
            -113.00572,
            44.2865,
            0
          ],
          [
            -113.00656,
            44.28793,
            0
          ],
          [
            -113.00786,
            44.29002,
            0
          ],
          [
            -113.00991,
            44.29344,
            0
          ],
          [
            -113.01065,
            44.29459,
            0
          ],
          [
            -113.01076,
            44.29475,
            0
          ],
          [
            -113.01085,
            44.29489,
            0
          ],
          [
            -113.01504,
            44.30177,
            0
          ],
          [
            -113.01509,
            44.30184,
            0
          ],
          [
            -113.01569,
            44.30283,
            0
          ],
          [
            -113.01643,
            44.30402,
            0
          ],
          [
            -113.0166,
            44.30431,
            0
          ],
          [
            -113.02034,
            44.31042,
            0
          ],
          [
            -113.02055,
            44.31075,
            0
          ],
          [
            -113.02066,
            44.31089,
            0
          ],
          [
            -113.02105,
            44.31142,
            0
          ],
          [
            -113.02141,
            44.31189,
            0
          ],
          [
            -113.02177,
            44.31228,
            0
          ],
          [
            -113.0225,
            44.31305,
            0
          ],
          [
            -113.02292,
            44.31346,
            0
          ],
          [
            -113.02326,
            44.31376,
            0
          ],
          [
            -113.0237,
            44.31413,
            0
          ],
          [
            -113.02446,
            44.31474,
            0
          ],
          [
            -113.02482,
            44.315,
            0
          ],
          [
            -113.02559,
            44.3155,
            0
          ],
          [
            -113.02651,
            44.31606,
            0
          ],
          [
            -113.02729,
            44.31649,
            0
          ],
          [
            -113.02789,
            44.31679,
            0
          ],
          [
            -113.02903,
            44.31732,
            0
          ],
          [
            -113.02948,
            44.3175,
            0
          ],
          [
            -113.03092,
            44.31803,
            0
          ],
          [
            -113.03141,
            44.31819,
            0
          ],
          [
            -113.0317,
            44.31829,
            0
          ],
          [
            -113.03425,
            44.31901,
            0
          ],
          [
            -113.03469,
            44.31914,
            0
          ],
          [
            -113.03506,
            44.31924,
            0
          ],
          [
            -113.03544,
            44.31935,
            0
          ],
          [
            -113.03596,
            44.31949,
            0
          ],
          [
            -113.03802,
            44.32008,
            0
          ],
          [
            -113.03843,
            44.32019,
            0
          ],
          [
            -113.04033,
            44.32075,
            0
          ],
          [
            -113.04127,
            44.321,
            0
          ],
          [
            -113.04252,
            44.32137,
            0
          ],
          [
            -113.04425,
            44.32186,
            0
          ],
          [
            -113.0444,
            44.32191,
            0
          ],
          [
            -113.04484,
            44.32203,
            0
          ],
          [
            -113.04541,
            44.3222,
            0
          ],
          [
            -113.04635,
            44.32246,
            0
          ],
          [
            -113.04682,
            44.3226,
            0
          ],
          [
            -113.05149,
            44.32393,
            0
          ],
          [
            -113.05158,
            44.32395,
            0
          ],
          [
            -113.05596,
            44.3252,
            0
          ],
          [
            -113.05648,
            44.32536,
            0
          ],
          [
            -113.06059,
            44.32654,
            0
          ],
          [
            -113.06157,
            44.32681,
            0
          ],
          [
            -113.06951,
            44.32909,
            0
          ],
          [
            -113.06974,
            44.32915,
            0
          ],
          [
            -113.09096,
            44.3352,
            0
          ],
          [
            -113.09145,
            44.33535,
            0
          ],
          [
            -113.09316,
            44.33583,
            0
          ],
          [
            -113.09477,
            44.3363,
            0
          ],
          [
            -113.12527,
            44.34499,
            0
          ],
          [
            -113.12532,
            44.34501,
            0
          ],
          [
            -113.12563,
            44.34509,
            0
          ],
          [
            -113.12669,
            44.3454,
            0
          ],
          [
            -113.12808,
            44.34582,
            0
          ],
          [
            -113.12847,
            44.34595,
            0
          ],
          [
            -113.12929,
            44.34621,
            0
          ],
          [
            -113.13099,
            44.34683,
            0
          ],
          [
            -113.13148,
            44.34702,
            0
          ],
          [
            -113.13149,
            44.34703,
            0
          ],
          [
            -113.13197,
            44.34722,
            0
          ],
          [
            -113.1323,
            44.34736,
            0
          ],
          [
            -113.13236,
            44.34738,
            0
          ],
          [
            -113.13258,
            44.34748,
            0
          ],
          [
            -113.13296,
            44.34763,
            0
          ],
          [
            -113.13358,
            44.34791,
            0
          ],
          [
            -113.13407,
            44.34814,
            0
          ],
          [
            -113.13422,
            44.3482,
            0
          ],
          [
            -113.13515,
            44.34865,
            0
          ],
          [
            -113.13595,
            44.34905,
            0
          ],
          [
            -113.13685,
            44.34953,
            0
          ],
          [
            -113.13817,
            44.35028,
            0
          ],
          [
            -113.13947,
            44.35107,
            0
          ],
          [
            -113.14003,
            44.35145,
            0
          ],
          [
            -113.14064,
            44.35185,
            0
          ],
          [
            -113.14154,
            44.35249,
            0
          ],
          [
            -113.14246,
            44.35319,
            0
          ],
          [
            -113.15491,
            44.36287,
            0
          ],
          [
            -113.15677,
            44.3643,
            0
          ],
          [
            -113.15731,
            44.36473,
            0
          ],
          [
            -113.16263,
            44.36886,
            0
          ],
          [
            -113.16294,
            44.36911,
            0
          ],
          [
            -113.16575,
            44.37129,
            0
          ],
          [
            -113.16628,
            44.37169,
            0
          ],
          [
            -113.17011,
            44.37468,
            0
          ],
          [
            -113.17048,
            44.37496,
            0
          ],
          [
            -113.18087,
            44.38303,
            0
          ],
          [
            -113.18145,
            44.38347,
            0
          ],
          [
            -113.20978,
            44.40545,
            0
          ],
          [
            -113.21074,
            44.40628,
            0
          ],
          [
            -113.21179,
            44.40731,
            0
          ],
          [
            -113.21238,
            44.40794,
            0
          ],
          [
            -113.2127,
            44.40831,
            0
          ],
          [
            -113.21545,
            44.41157,
            0
          ],
          [
            -113.21601,
            44.41222,
            0
          ],
          [
            -113.21612,
            44.41236,
            0
          ],
          [
            -113.22029,
            44.41731,
            0
          ],
          [
            -113.22039,
            44.41742,
            0
          ],
          [
            -113.22091,
            44.41805,
            0
          ],
          [
            -113.2213,
            44.4185,
            0
          ],
          [
            -113.2234,
            44.421,
            0
          ],
          [
            -113.22353,
            44.42116,
            0
          ],
          [
            -113.22413,
            44.42186,
            0
          ],
          [
            -113.22589,
            44.42395,
            0
          ],
          [
            -113.2259,
            44.42397,
            0
          ],
          [
            -113.22629,
            44.42444,
            0
          ],
          [
            -113.2268,
            44.42503,
            0
          ],
          [
            -113.22787,
            44.42632,
            0
          ],
          [
            -113.23058,
            44.4295,
            0
          ],
          [
            -113.23105,
            44.43007,
            0
          ],
          [
            -113.23113,
            44.43016,
            0
          ],
          [
            -113.23143,
            44.43054,
            0
          ],
          [
            -113.23152,
            44.43067,
            0
          ],
          [
            -113.23173,
            44.43095,
            0
          ],
          [
            -113.23187,
            44.43115,
            0
          ],
          [
            -113.23221,
            44.43166,
            0
          ],
          [
            -113.23235,
            44.43191,
            0
          ],
          [
            -113.23255,
            44.43225,
            0
          ],
          [
            -113.23275,
            44.43262,
            0
          ],
          [
            -113.23289,
            44.43291,
            0
          ],
          [
            -113.23306,
            44.43329,
            0
          ],
          [
            -113.23322,
            44.43368,
            0
          ],
          [
            -113.23334,
            44.43395,
            0
          ],
          [
            -113.23349,
            44.43435,
            0
          ],
          [
            -113.23372,
            44.43499,
            0
          ],
          [
            -113.2345,
            44.43723,
            0
          ],
          [
            -113.23452,
            44.43727,
            0
          ],
          [
            -113.23593,
            44.44131,
            0
          ],
          [
            -113.23609,
            44.44173,
            0
          ],
          [
            -113.23843,
            44.44841,
            0
          ],
          [
            -113.23933,
            44.45091,
            0
          ],
          [
            -113.24202,
            44.45857,
            0
          ],
          [
            -113.2424,
            44.45961,
            0
          ],
          [
            -113.24337,
            44.46238,
            0
          ],
          [
            -113.24346,
            44.46262,
            0
          ],
          [
            -113.2454,
            44.46814,
            0
          ],
          [
            -113.24566,
            44.4688,
            0
          ],
          [
            -113.2459,
            44.46931,
            0
          ],
          [
            -113.2461,
            44.46969,
            0
          ],
          [
            -113.24623,
            44.46995,
            0
          ],
          [
            -113.24637,
            44.4702,
            0
          ],
          [
            -113.2466,
            44.47057,
            0
          ],
          [
            -113.24668,
            44.47071,
            0
          ],
          [
            -113.24683,
            44.47094,
            0
          ],
          [
            -113.24694,
            44.47109,
            0
          ],
          [
            -113.2471,
            44.47132,
            0
          ],
          [
            -113.24727,
            44.47155,
            0
          ],
          [
            -113.24739,
            44.47172,
            0
          ],
          [
            -113.24779,
            44.47222,
            0
          ],
          [
            -113.24783,
            44.47226,
            0
          ],
          [
            -113.24812,
            44.4726,
            0
          ],
          [
            -113.2486,
            44.47313,
            0
          ],
          [
            -113.24863,
            44.47318,
            0
          ],
          [
            -113.24904,
            44.47363,
            0
          ],
          [
            -113.24974,
            44.47444,
            0
          ],
          [
            -113.25097,
            44.47582,
            0
          ],
          [
            -113.25195,
            44.47694,
            0
          ],
          [
            -113.25238,
            44.47742,
            0
          ],
          [
            -113.25243,
            44.47748,
            0
          ],
          [
            -113.25778,
            44.48355,
            0
          ],
          [
            -113.25792,
            44.4837,
            0
          ],
          [
            -113.25832,
            44.48416,
            0
          ],
          [
            -113.25836,
            44.48421,
            0
          ],
          [
            -113.25926,
            44.48523,
            0
          ],
          [
            -113.25932,
            44.48529,
            0
          ],
          [
            -113.25982,
            44.48585,
            0
          ],
          [
            -113.25994,
            44.48599,
            0
          ],
          [
            -113.25995,
            44.486,
            0
          ],
          [
            -113.261,
            44.48721,
            0
          ],
          [
            -113.2614,
            44.4877,
            0
          ],
          [
            -113.26174,
            44.48815,
            0
          ],
          [
            -113.26175,
            44.48817,
            0
          ],
          [
            -113.2619,
            44.48837,
            0
          ],
          [
            -113.2621,
            44.48866,
            0
          ],
          [
            -113.26234,
            44.48905,
            0
          ],
          [
            -113.26254,
            44.48939,
            0
          ],
          [
            -113.26284,
            44.48993,
            0
          ],
          [
            -113.26305,
            44.49036,
            0
          ],
          [
            -113.26311,
            44.49049,
            0
          ],
          [
            -113.26335,
            44.49104,
            0
          ],
          [
            -113.26404,
            44.49269,
            0
          ],
          [
            -113.26411,
            44.49287,
            0
          ],
          [
            -113.26468,
            44.49425,
            0
          ],
          [
            -113.26476,
            44.49442,
            0
          ],
          [
            -113.26548,
            44.49614,
            0
          ],
          [
            -113.26562,
            44.49649,
            0
          ],
          [
            -113.2657,
            44.49666,
            0
          ],
          [
            -113.26606,
            44.49752,
            0
          ],
          [
            -113.2662,
            44.49787,
            0
          ],
          [
            -113.26679,
            44.49929,
            0
          ],
          [
            -113.26738,
            44.50067,
            0
          ],
          [
            -113.26751,
            44.50093,
            0
          ],
          [
            -113.26758,
            44.50106,
            0
          ],
          [
            -113.26765,
            44.50117,
            0
          ],
          [
            -113.26773,
            44.50131,
            0
          ],
          [
            -113.26781,
            44.50142,
            0
          ],
          [
            -113.26802,
            44.50173,
            0
          ],
          [
            -113.26921,
            44.50338,
            0
          ],
          [
            -113.26933,
            44.50354,
            0
          ],
          [
            -113.27035,
            44.50495,
            0
          ],
          [
            -113.27037,
            44.50497,
            0
          ],
          [
            -113.27048,
            44.50513,
            0
          ],
          [
            -113.27198,
            44.50721,
            0
          ],
          [
            -113.2721,
            44.50737,
            0
          ],
          [
            -113.27233,
            44.50769,
            0
          ],
          [
            -113.27244,
            44.50785,
            0
          ],
          [
            -113.27289,
            44.50847,
            0
          ],
          [
            -113.2729,
            44.50849,
            0
          ],
          [
            -113.27363,
            44.50949,
            0
          ],
          [
            -113.27381,
            44.50975,
            0
          ],
          [
            -113.27463,
            44.51089,
            0
          ],
          [
            -113.27467,
            44.51094,
            0
          ],
          [
            -113.27647,
            44.51342,
            0
          ],
          [
            -113.27674,
            44.51377,
            0
          ],
          [
            -113.27698,
            44.51405,
            0
          ],
          [
            -113.27725,
            44.51435,
            0
          ],
          [
            -113.27736,
            44.51446,
            0
          ],
          [
            -113.27771,
            44.51479,
            0
          ],
          [
            -113.27806,
            44.51509,
            0
          ],
          [
            -113.27952,
            44.51615,
            0
          ],
          [
            -113.28125,
            44.51737,
            0
          ],
          [
            -113.28142,
            44.5175,
            0
          ],
          [
            -113.28235,
            44.51815,
            0
          ],
          [
            -113.2839,
            44.51928,
            0
          ],
          [
            -113.28421,
            44.51952,
            0
          ],
          [
            -113.28442,
            44.51969,
            0
          ],
          [
            -113.2845,
            44.51975,
            0
          ],
          [
            -113.28529,
            44.52043,
            0
          ],
          [
            -113.28572,
            44.52084,
            0
          ],
          [
            -113.28625,
            44.5214,
            0
          ],
          [
            -113.28626,
            44.5214,
            0
          ],
          [
            -113.2868,
            44.52201,
            0
          ],
          [
            -113.28693,
            44.52217,
            0
          ],
          [
            -113.28746,
            44.52278,
            0
          ],
          [
            -113.28779,
            44.52318,
            0
          ],
          [
            -113.28871,
            44.52422,
            0
          ],
          [
            -113.28895,
            44.52448,
            0
          ],
          [
            -113.28923,
            44.5248,
            0
          ],
          [
            -113.28932,
            44.52492,
            0
          ],
          [
            -113.29163,
            44.52761,
            0
          ],
          [
            -113.29212,
            44.52816,
            0
          ],
          [
            -113.29231,
            44.52839,
            0
          ],
          [
            -113.29239,
            44.5285,
            0
          ],
          [
            -113.29274,
            44.52891,
            0
          ],
          [
            -113.29279,
            44.52896,
            0
          ],
          [
            -113.29288,
            44.52906,
            0
          ],
          [
            -113.29349,
            44.52978,
            0
          ],
          [
            -113.29409,
            44.53046,
            0
          ],
          [
            -113.29539,
            44.53197,
            0
          ],
          [
            -113.29606,
            44.53273,
            0
          ],
          [
            -113.2963,
            44.53302,
            0
          ],
          [
            -113.2966,
            44.53336,
            0
          ],
          [
            -113.29875,
            44.53589,
            0
          ],
          [
            -113.29895,
            44.53611,
            0
          ],
          [
            -113.29947,
            44.5367,
            0
          ],
          [
            -113.29971,
            44.53694,
            0
          ],
          [
            -113.29992,
            44.53716,
            0
          ],
          [
            -113.30044,
            44.53766,
            0
          ],
          [
            -113.3008,
            44.53798,
            0
          ],
          [
            -113.30084,
            44.53802,
            0
          ],
          [
            -113.30134,
            44.53843,
            0
          ],
          [
            -113.30138,
            44.53847,
            0
          ],
          [
            -113.30151,
            44.53856,
            0
          ],
          [
            -113.3022,
            44.53909,
            0
          ],
          [
            -113.30226,
            44.53914,
            0
          ],
          [
            -113.30386,
            44.54031,
            0
          ],
          [
            -113.30556,
            44.54158,
            0
          ],
          [
            -113.30597,
            44.5419,
            0
          ],
          [
            -113.30603,
            44.54195,
            0
          ],
          [
            -113.30611,
            44.54201,
            0
          ],
          [
            -113.30625,
            44.54213,
            0
          ],
          [
            -113.30638,
            44.54225,
            0
          ],
          [
            -113.30662,
            44.54249,
            0
          ],
          [
            -113.30664,
            44.54252,
            0
          ],
          [
            -113.30672,
            44.5426,
            0
          ],
          [
            -113.30695,
            44.54289,
            0
          ],
          [
            -113.30707,
            44.54306,
            0
          ],
          [
            -113.30713,
            44.54315,
            0
          ],
          [
            -113.30726,
            44.54336,
            0
          ],
          [
            -113.30737,
            44.54355,
            0
          ],
          [
            -113.30751,
            44.54384,
            0
          ],
          [
            -113.30757,
            44.54402,
            0
          ],
          [
            -113.30763,
            44.54416,
            0
          ],
          [
            -113.30764,
            44.54419,
            0
          ],
          [
            -113.30767,
            44.54431,
            0
          ],
          [
            -113.30771,
            44.54445,
            0
          ],
          [
            -113.30779,
            44.54477,
            0
          ],
          [
            -113.30781,
            44.54501,
            0
          ],
          [
            -113.30782,
            44.54521,
            0
          ],
          [
            -113.30782,
            44.5456,
            0
          ],
          [
            -113.30783,
            44.54613,
            0
          ],
          [
            -113.30784,
            44.54757,
            0
          ],
          [
            -113.30785,
            44.54805,
            0
          ],
          [
            -113.30785,
            44.54919,
            0
          ],
          [
            -113.30786,
            44.54959,
            0
          ],
          [
            -113.30791,
            44.55369,
            0
          ],
          [
            -113.30791,
            44.5549,
            0
          ],
          [
            -113.30792,
            44.5553,
            0
          ],
          [
            -113.30792,
            44.55548,
            0
          ],
          [
            -113.30793,
            44.55571,
            0
          ],
          [
            -113.30793,
            44.55586,
            0
          ],
          [
            -113.30796,
            44.55626,
            0
          ],
          [
            -113.30799,
            44.55652,
            0
          ],
          [
            -113.30803,
            44.5568,
            0
          ],
          [
            -113.30804,
            44.55692,
            0
          ],
          [
            -113.30807,
            44.5571,
            0
          ],
          [
            -113.30811,
            44.55742,
            0
          ],
          [
            -113.3084,
            44.55932,
            0
          ],
          [
            -113.3088,
            44.56211,
            0
          ],
          [
            -113.30888,
            44.56261,
            0
          ],
          [
            -113.3101,
            44.57085,
            0
          ],
          [
            -113.31025,
            44.5718,
            0
          ],
          [
            -113.31046,
            44.57325,
            0
          ],
          [
            -113.31064,
            44.57414,
            0
          ],
          [
            -113.31071,
            44.5744,
            0
          ],
          [
            -113.31081,
            44.57472,
            0
          ],
          [
            -113.31099,
            44.57533,
            0
          ],
          [
            -113.31297,
            44.58138,
            0
          ],
          [
            -113.3132,
            44.58206,
            0
          ],
          [
            -113.31379,
            44.58387,
            0
          ],
          [
            -113.314,
            44.58449,
            0
          ],
          [
            -113.31428,
            44.58537,
            0
          ],
          [
            -113.31844,
            44.59805,
            0
          ],
          [
            -113.3186,
            44.59863,
            0
          ],
          [
            -113.31867,
            44.59886,
            0
          ],
          [
            -113.31873,
            44.59911,
            0
          ],
          [
            -113.31875,
            44.59926,
            0
          ],
          [
            -113.31875,
            44.59933,
            0
          ],
          [
            -113.31877,
            44.59954,
            0
          ],
          [
            -113.31878,
            44.5999,
            0
          ],
          [
            -113.31878,
            44.6003,
            0
          ],
          [
            -113.31873,
            44.60081,
            0
          ],
          [
            -113.3186,
            44.60154,
            0
          ],
          [
            -113.31854,
            44.60195,
            0
          ],
          [
            -113.31774,
            44.60693,
            0
          ],
          [
            -113.3176,
            44.60788,
            0
          ],
          [
            -113.31719,
            44.61017,
            0
          ],
          [
            -113.31706,
            44.61084,
            0
          ],
          [
            -113.31698,
            44.61137,
            0
          ],
          [
            -113.31696,
            44.6116,
            0
          ],
          [
            -113.31696,
            44.61185,
            0
          ],
          [
            -113.31698,
            44.6121,
            0
          ],
          [
            -113.31701,
            44.61239,
            0
          ],
          [
            -113.31708,
            44.61275,
            0
          ],
          [
            -113.31715,
            44.61302,
            0
          ],
          [
            -113.31726,
            44.61335,
            0
          ],
          [
            -113.31727,
            44.61337,
            0
          ],
          [
            -113.31742,
            44.61374,
            0
          ],
          [
            -113.31871,
            44.61671,
            0
          ],
          [
            -113.32217,
            44.62457,
            0
          ],
          [
            -113.32296,
            44.6264,
            0
          ],
          [
            -113.32407,
            44.62904,
            0
          ],
          [
            -113.32467,
            44.63041,
            0
          ],
          [
            -113.32547,
            44.63246,
            0
          ],
          [
            -113.32634,
            44.63474,
            0
          ],
          [
            -113.32647,
            44.63512,
            0
          ],
          [
            -113.3274,
            44.63811,
            0
          ],
          [
            -113.32743,
            44.63819,
            0
          ],
          [
            -113.32839,
            44.64127,
            0
          ],
          [
            -113.3284,
            44.64129,
            0
          ],
          [
            -113.32988,
            44.64601,
            0
          ],
          [
            -113.32988,
            44.64603,
            0
          ],
          [
            -113.33,
            44.64638,
            0
          ],
          [
            -113.33005,
            44.64655,
            0
          ],
          [
            -113.33163,
            44.65159,
            0
          ],
          [
            -113.33211,
            44.65308,
            0
          ],
          [
            -113.33237,
            44.65394,
            0
          ],
          [
            -113.33249,
            44.65427,
            0
          ],
          [
            -113.33252,
            44.65436,
            0
          ],
          [
            -113.33268,
            44.65474,
            0
          ],
          [
            -113.33285,
            44.65509,
            0
          ],
          [
            -113.33336,
            44.65596,
            0
          ],
          [
            -113.33358,
            44.65626,
            0
          ],
          [
            -113.3336,
            44.6563,
            0
          ],
          [
            -113.33382,
            44.65662,
            0
          ],
          [
            -113.33427,
            44.65715,
            0
          ],
          [
            -113.33566,
            44.65869,
            0
          ],
          [
            -113.33568,
            44.65872,
            0
          ],
          [
            -113.33584,
            44.65889,
            0
          ],
          [
            -113.33811,
            44.66142,
            0
          ],
          [
            -113.33921,
            44.66267,
            0
          ],
          [
            -113.33922,
            44.66268,
            0
          ],
          [
            -113.33988,
            44.66343,
            0
          ],
          [
            -113.33989,
            44.66345,
            0
          ],
          [
            -113.34233,
            44.66616,
            0
          ],
          [
            -113.34235,
            44.66617,
            0
          ],
          [
            -113.34249,
            44.66632,
            0
          ],
          [
            -113.3441,
            44.66813,
            0
          ],
          [
            -113.34412,
            44.66814,
            0
          ],
          [
            -113.34464,
            44.66873,
            0
          ],
          [
            -113.34466,
            44.66875,
            0
          ],
          [
            -113.3504,
            44.67516,
            0
          ],
          [
            -113.35093,
            44.6757,
            0
          ],
          [
            -113.3514,
            44.67615,
            0
          ],
          [
            -113.3516,
            44.67632,
            0
          ],
          [
            -113.35252,
            44.67701,
            0
          ],
          [
            -113.35346,
            44.67766,
            0
          ],
          [
            -113.35354,
            44.67771,
            0
          ],
          [
            -113.35372,
            44.67784,
            0
          ],
          [
            -113.35554,
            44.67904,
            0
          ],
          [
            -113.35598,
            44.67935,
            0
          ],
          [
            -113.35653,
            44.67972,
            0
          ],
          [
            -113.35699,
            44.68001,
            0
          ],
          [
            -113.35717,
            44.68013,
            0
          ],
          [
            -113.35755,
            44.68037,
            0
          ],
          [
            -113.35775,
            44.68021,
            0
          ],
          [
            -113.35791,
            44.68009,
            0
          ],
          [
            -113.35775,
            44.68021,
            0
          ],
          [
            -113.35755,
            44.68037,
            0
          ],
          [
            -113.35815,
            44.68075,
            0
          ],
          [
            -113.35824,
            44.68082,
            0
          ],
          [
            -113.35981,
            44.68185,
            0
          ],
          [
            -113.36153,
            44.68294,
            0
          ],
          [
            -113.3678,
            44.68704,
            0
          ],
          [
            -113.36991,
            44.68838,
            0
          ],
          [
            -113.3718,
            44.68947,
            0
          ],
          [
            -113.37284,
            44.69005,
            0
          ],
          [
            -113.37576,
            44.69163,
            0
          ],
          [
            -113.37586,
            44.69169,
            0
          ],
          [
            -113.37847,
            44.69309,
            0
          ],
          [
            -113.37848,
            44.6931,
            0
          ],
          [
            -113.3849,
            44.69655,
            0
          ],
          [
            -113.38519,
            44.6967,
            0
          ],
          [
            -113.39952,
            44.70441,
            0
          ],
          [
            -113.39972,
            44.70451,
            0
          ],
          [
            -113.41426,
            44.71233,
            0
          ],
          [
            -113.41446,
            44.71243,
            0
          ],
          [
            -113.41472,
            44.71258,
            0
          ],
          [
            -113.41754,
            44.71408,
            0
          ],
          [
            -113.43913,
            44.7257,
            0
          ],
          [
            -113.43942,
            44.72585,
            0
          ],
          [
            -113.442,
            44.72724,
            0
          ],
          [
            -113.4455,
            44.72909,
            0
          ],
          [
            -113.44873,
            44.73084,
            0
          ],
          [
            -113.45161,
            44.73237,
            0
          ],
          [
            -113.4527,
            44.73297,
            0
          ],
          [
            -113.45782,
            44.73574,
            0
          ],
          [
            -113.45863,
            44.73621,
            0
          ],
          [
            -113.45939,
            44.73668,
            0
          ],
          [
            -113.4622,
            44.73848,
            0
          ],
          [
            -113.46237,
            44.73858,
            0
          ],
          [
            -113.46341,
            44.73924,
            0
          ],
          [
            -113.46408,
            44.73965,
            0
          ],
          [
            -113.46495,
            44.74016,
            0
          ],
          [
            -113.46551,
            44.74046,
            0
          ],
          [
            -113.48623,
            44.75125,
            0
          ],
          [
            -113.48643,
            44.75136,
            0
          ],
          [
            -113.4895,
            44.75295,
            0
          ],
          [
            -113.4897,
            44.75306,
            0
          ],
          [
            -113.49175,
            44.75412,
            0
          ],
          [
            -113.49195,
            44.75423,
            0
          ],
          [
            -113.51582,
            44.76663,
            0
          ],
          [
            -113.51632,
            44.76688,
            0
          ],
          [
            -113.52223,
            44.76996,
            0
          ],
          [
            -113.52243,
            44.77007,
            0
          ],
          [
            -113.52803,
            44.77297,
            0
          ],
          [
            -113.52942,
            44.77364,
            0
          ],
          [
            -113.53108,
            44.77436,
            0
          ],
          [
            -113.532,
            44.77473,
            0
          ],
          [
            -113.53402,
            44.77552,
            0
          ],
          [
            -113.53552,
            44.77613,
            0
          ],
          [
            -113.53889,
            44.77745,
            0
          ],
          [
            -113.53973,
            44.7778,
            0
          ],
          [
            -113.53977,
            44.77782,
            0
          ],
          [
            -113.54044,
            44.7781,
            0
          ],
          [
            -113.54075,
            44.77824,
            0
          ],
          [
            -113.54115,
            44.77844,
            0
          ],
          [
            -113.54182,
            44.77879,
            0
          ],
          [
            -113.5424,
            44.77911,
            0
          ],
          [
            -113.54288,
            44.7794,
            0
          ],
          [
            -113.54385,
            44.78001,
            0
          ],
          [
            -113.54464,
            44.78057,
            0
          ],
          [
            -113.54493,
            44.7808,
            0
          ],
          [
            -113.54512,
            44.78096,
            0
          ],
          [
            -113.54555,
            44.7813,
            0
          ],
          [
            -113.54592,
            44.78162,
            0
          ],
          [
            -113.54661,
            44.7823,
            0
          ],
          [
            -113.54703,
            44.78276,
            0
          ],
          [
            -113.54752,
            44.78332,
            0
          ],
          [
            -113.55028,
            44.78678,
            0
          ],
          [
            -113.55037,
            44.78691,
            0
          ],
          [
            -113.55302,
            44.79026,
            0
          ],
          [
            -113.55379,
            44.79125,
            0
          ],
          [
            -113.55416,
            44.7917,
            0
          ],
          [
            -113.5544,
            44.79201,
            0
          ],
          [
            -113.55453,
            44.79216,
            0
          ],
          [
            -113.55486,
            44.79257,
            0
          ],
          [
            -113.55519,
            44.79296,
            0
          ],
          [
            -113.55596,
            44.79377,
            0
          ],
          [
            -113.55615,
            44.79396,
            0
          ],
          [
            -113.5562,
            44.794,
            0
          ],
          [
            -113.55677,
            44.79452,
            0
          ],
          [
            -113.55725,
            44.79492,
            0
          ],
          [
            -113.55789,
            44.79541,
            0
          ],
          [
            -113.55839,
            44.79578,
            0
          ],
          [
            -113.56035,
            44.79706,
            0
          ],
          [
            -113.5604,
            44.7971,
            0
          ],
          [
            -113.56258,
            44.79854,
            0
          ],
          [
            -113.56456,
            44.79987,
            0
          ],
          [
            -113.56522,
            44.8003,
            0
          ],
          [
            -113.56537,
            44.80041,
            0
          ],
          [
            -113.56685,
            44.80137,
            0
          ],
          [
            -113.59458,
            44.81968,
            0
          ],
          [
            -113.59578,
            44.82045,
            0
          ],
          [
            -113.60208,
            44.82466,
            0
          ],
          [
            -113.60774,
            44.82838,
            0
          ],
          [
            -113.60847,
            44.82885,
            0
          ],
          [
            -113.61052,
            44.83022,
            0
          ],
          [
            -113.61183,
            44.83108,
            0
          ],
          [
            -113.61235,
            44.83141,
            0
          ],
          [
            -113.61386,
            44.83242,
            0
          ],
          [
            -113.61394,
            44.83249,
            0
          ],
          [
            -113.61436,
            44.8328,
            0
          ],
          [
            -113.61476,
            44.83312,
            0
          ],
          [
            -113.6154,
            44.83368,
            0
          ],
          [
            -113.6158,
            44.83404,
            0
          ],
          [
            -113.61622,
            44.83445,
            0
          ],
          [
            -113.61656,
            44.83481,
            0
          ],
          [
            -113.61715,
            44.83547,
            0
          ],
          [
            -113.61742,
            44.83582,
            0
          ],
          [
            -113.61777,
            44.83626,
            0
          ],
          [
            -113.61841,
            44.83725,
            0
          ],
          [
            -113.61867,
            44.83769,
            0
          ],
          [
            -113.61905,
            44.83842,
            0
          ],
          [
            -113.61934,
            44.83909,
            0
          ],
          [
            -113.61966,
            44.83996,
            0
          ],
          [
            -113.61981,
            44.84046,
            0
          ],
          [
            -113.61996,
            44.84111,
            0
          ],
          [
            -113.62008,
            44.84176,
            0
          ],
          [
            -113.6201,
            44.84184,
            0
          ],
          [
            -113.62014,
            44.84216,
            0
          ],
          [
            -113.62017,
            44.84245,
            0
          ],
          [
            -113.62021,
            44.84303,
            0
          ],
          [
            -113.62019,
            44.8464,
            0
          ],
          [
            -113.62011,
            44.85126,
            0
          ],
          [
            -113.6201,
            44.85155,
            0
          ],
          [
            -113.62008,
            44.85183,
            0
          ],
          [
            -113.62008,
            44.85303,
            0
          ],
          [
            -113.62011,
            44.85371,
            0
          ],
          [
            -113.62013,
            44.85401,
            0
          ],
          [
            -113.62016,
            44.85433,
            0
          ],
          [
            -113.6202,
            44.85462,
            0
          ],
          [
            -113.62031,
            44.85528,
            0
          ],
          [
            -113.62048,
            44.85594,
            0
          ],
          [
            -113.62068,
            44.85659,
            0
          ],
          [
            -113.62088,
            44.85715,
            0
          ],
          [
            -113.62125,
            44.85802,
            0
          ],
          [
            -113.62152,
            44.85853,
            0
          ],
          [
            -113.62178,
            44.85909,
            0
          ],
          [
            -113.62203,
            44.85961,
            0
          ],
          [
            -113.62292,
            44.86141,
            0
          ],
          [
            -113.62353,
            44.86267,
            0
          ],
          [
            -113.62358,
            44.86276,
            0
          ],
          [
            -113.62456,
            44.86481,
            0
          ],
          [
            -113.62472,
            44.86518,
            0
          ],
          [
            -113.62514,
            44.86625,
            0
          ],
          [
            -113.62533,
            44.86681,
            0
          ],
          [
            -113.62555,
            44.86781,
            0
          ],
          [
            -113.62568,
            44.86869,
            0
          ],
          [
            -113.62572,
            44.86922,
            0
          ],
          [
            -113.62574,
            44.86971,
            0
          ],
          [
            -113.62576,
            44.87068,
            0
          ],
          [
            -113.62574,
            44.87178,
            0
          ],
          [
            -113.62578,
            44.87366,
            0
          ],
          [
            -113.62579,
            44.87676,
            0
          ],
          [
            -113.62578,
            44.87798,
            0
          ],
          [
            -113.62581,
            44.87938,
            0
          ],
          [
            -113.62581,
            44.88039,
            0
          ],
          [
            -113.62583,
            44.88151,
            0
          ],
          [
            -113.62583,
            44.8821,
            0
          ],
          [
            -113.62585,
            44.88383,
            0
          ],
          [
            -113.62585,
            44.88519,
            0
          ],
          [
            -113.62588,
            44.8865,
            0
          ],
          [
            -113.62589,
            44.88728,
            0
          ],
          [
            -113.62588,
            44.88782,
            0
          ],
          [
            -113.62589,
            44.88848,
            0
          ],
          [
            -113.62593,
            44.88895,
            0
          ],
          [
            -113.62601,
            44.88938,
            0
          ],
          [
            -113.62608,
            44.88981,
            0
          ],
          [
            -113.62638,
            44.89106,
            0
          ],
          [
            -113.62714,
            44.89396,
            0
          ],
          [
            -113.62753,
            44.89539,
            0
          ],
          [
            -113.62819,
            44.8979,
            0
          ],
          [
            -113.62831,
            44.89838,
            0
          ],
          [
            -113.62872,
            44.89991,
            0
          ],
          [
            -113.62929,
            44.90212,
            0
          ],
          [
            -113.62982,
            44.90411,
            0
          ],
          [
            -113.63002,
            44.90492,
            0
          ],
          [
            -113.63014,
            44.9053,
            0
          ],
          [
            -113.63217,
            44.91306,
            0
          ],
          [
            -113.63217,
            44.91308,
            0
          ],
          [
            -113.63303,
            44.91635,
            0
          ],
          [
            -113.63304,
            44.9164,
            0
          ],
          [
            -113.63425,
            44.92084,
            0
          ],
          [
            -113.63617,
            44.92805,
            0
          ],
          [
            -113.63672,
            44.9302,
            0
          ],
          [
            -113.63692,
            44.93092,
            0
          ],
          [
            -113.63761,
            44.93353,
            0
          ],
          [
            -113.63833,
            44.93638,
            0
          ],
          [
            -113.63844,
            44.93679,
            0
          ],
          [
            -113.63897,
            44.93887,
            0
          ],
          [
            -113.6393,
            44.94023,
            0
          ],
          [
            -113.63958,
            44.94122,
            0
          ],
          [
            -113.63992,
            44.94258,
            0
          ],
          [
            -113.64018,
            44.94356,
            0
          ],
          [
            -113.6403,
            44.94399,
            0
          ],
          [
            -113.6405,
            44.94477,
            0
          ],
          [
            -113.6407,
            44.94559,
            0
          ],
          [
            -113.64109,
            44.94708,
            0
          ],
          [
            -113.64147,
            44.94859,
            0
          ],
          [
            -113.64161,
            44.94912,
            0
          ],
          [
            -113.64163,
            44.94923,
            0
          ],
          [
            -113.64168,
            44.9494,
            0
          ],
          [
            -113.64236,
            44.95206,
            0
          ],
          [
            -113.64353,
            44.95648,
            0
          ],
          [
            -113.64357,
            44.95665,
            0
          ],
          [
            -113.64403,
            44.95842,
            0
          ],
          [
            -113.64416,
            44.95888,
            0
          ],
          [
            -113.6443,
            44.95941,
            0
          ],
          [
            -113.6443,
            44.95943,
            0
          ],
          [
            -113.64441,
            44.95982,
            0
          ],
          [
            -113.64525,
            44.96295,
            0
          ],
          [
            -113.64542,
            44.96362,
            0
          ],
          [
            -113.64557,
            44.96416,
            0
          ],
          [
            -113.64622,
            44.9666,
            0
          ],
          [
            -113.64637,
            44.96714,
            0
          ],
          [
            -113.64695,
            44.96938,
            0
          ],
          [
            -113.64898,
            44.97697,
            0
          ],
          [
            -113.64927,
            44.97802,
            0
          ],
          [
            -113.64965,
            44.97949,
            0
          ],
          [
            -113.65034,
            44.98205,
            0
          ],
          [
            -113.65058,
            44.98298,
            0
          ],
          [
            -113.65077,
            44.98367,
            0
          ],
          [
            -113.65173,
            44.98729,
            0
          ],
          [
            -113.65188,
            44.98783,
            0
          ],
          [
            -113.65198,
            44.98824,
            0
          ],
          [
            -113.65463,
            44.99815,
            0
          ],
          [
            -113.65474,
            44.99858,
            0
          ],
          [
            -113.65506,
            44.99975,
            0
          ],
          [
            -113.65573,
            45.00231,
            0
          ],
          [
            -113.65584,
            45.0027,
            0
          ],
          [
            -113.65726,
            45.00808,
            0
          ],
          [
            -113.6576,
            45.00941,
            0
          ],
          [
            -113.6591,
            45.01501,
            0
          ],
          [
            -113.66012,
            45.01891,
            0
          ],
          [
            -113.66027,
            45.01946,
            0
          ],
          [
            -113.66088,
            45.02183,
            0
          ],
          [
            -113.66102,
            45.02231,
            0
          ],
          [
            -113.66128,
            45.02309,
            0
          ],
          [
            -113.6616,
            45.02386,
            0
          ],
          [
            -113.66185,
            45.02437,
            0
          ],
          [
            -113.66218,
            45.02497,
            0
          ],
          [
            -113.66236,
            45.02526,
            0
          ],
          [
            -113.66243,
            45.02539,
            0
          ],
          [
            -113.6626,
            45.02565,
            0
          ],
          [
            -113.66311,
            45.02638,
            0
          ],
          [
            -113.66342,
            45.02678,
            0
          ],
          [
            -113.66366,
            45.02708,
            0
          ],
          [
            -113.66501,
            45.02858,
            0
          ],
          [
            -113.66576,
            45.02939,
            0
          ],
          [
            -113.66866,
            45.0326,
            0
          ],
          [
            -113.67085,
            45.03499,
            0
          ],
          [
            -113.68916,
            45.05523,
            0
          ],
          [
            -113.69013,
            45.05628,
            0
          ],
          [
            -113.69031,
            45.05649,
            0
          ],
          [
            -113.71889,
            45.08801,
            0
          ],
          [
            -113.72003,
            45.08922,
            0
          ],
          [
            -113.72031,
            45.0895,
            0
          ],
          [
            -113.72072,
            45.08988,
            0
          ],
          [
            -113.72139,
            45.09043,
            0
          ],
          [
            -113.72215,
            45.09104,
            0
          ],
          [
            -113.72325,
            45.09178,
            0
          ],
          [
            -113.72401,
            45.09225,
            0
          ],
          [
            -113.72506,
            45.09284,
            0
          ],
          [
            -113.73153,
            45.09612,
            0
          ],
          [
            -113.73249,
            45.09663,
            0
          ],
          [
            -113.73722,
            45.09904,
            0
          ],
          [
            -113.73845,
            45.09968,
            0
          ],
          [
            -113.76001,
            45.11067,
            0
          ],
          [
            -113.76014,
            45.11074,
            0
          ],
          [
            -113.76261,
            45.112,
            0
          ],
          [
            -113.76262,
            45.11201,
            0
          ],
          [
            -113.76785,
            45.1147,
            0
          ],
          [
            -113.76793,
            45.11475,
            0
          ],
          [
            -113.79439,
            45.12823,
            0
          ],
          [
            -113.79693,
            45.12957,
            0
          ],
          [
            -113.79803,
            45.13012,
            0
          ],
          [
            -113.8401,
            45.15158,
            0
          ],
          [
            -113.84367,
            45.15342,
            0
          ],
          [
            -113.8443,
            45.15373,
            0
          ],
          [
            -113.84672,
            45.15495,
            0
          ],
          [
            -113.84937,
            45.15631,
            0
          ],
          [
            -113.85032,
            45.15678,
            0
          ],
          [
            -113.86307,
            45.1633,
            0
          ],
          [
            -113.86533,
            45.16441,
            0
          ],
          [
            -113.86646,
            45.16488,
            0
          ],
          [
            -113.86883,
            45.16582,
            0
          ],
          [
            -113.87028,
            45.16637,
            0
          ],
          [
            -113.87216,
            45.16711,
            0
          ],
          [
            -113.87531,
            45.16838,
            0
          ],
          [
            -113.87613,
            45.1687,
            0
          ],
          [
            -113.87694,
            45.169,
            0
          ],
          [
            -113.87735,
            45.16918,
            0
          ],
          [
            -113.87924,
            45.16991,
            0
          ],
          [
            -113.87939,
            45.16996,
            0
          ],
          [
            -113.8804,
            45.17036,
            0
          ],
          [
            -113.88165,
            45.17087,
            0
          ],
          [
            -113.88284,
            45.17131,
            0
          ],
          [
            -113.88408,
            45.17179,
            0
          ],
          [
            -113.8853,
            45.17228,
            0
          ],
          [
            -113.88688,
            45.17289,
            0
          ],
          [
            -113.88698,
            45.17292,
            0
          ],
          [
            -113.88793,
            45.1733,
            0
          ],
          [
            -113.88932,
            45.17384,
            0
          ],
          [
            -113.89025,
            45.17419,
            0
          ],
          [
            -113.89115,
            45.17455,
            0
          ],
          [
            -113.89209,
            45.1749,
            0
          ],
          [
            -113.89554,
            45.17625,
            0
          ],
          [
            -113.8959,
            45.17575,
            0
          ]
        ],
			},
			id: "86cd5e29-e6eb-4279-b976-7e5546c42965",
		},
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -112.435772,
    //       43.8413612,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Terreton, ID"
    //   },
    //   id: "262af50d-0ebf-4a9f-8b9b-ea2632ad2238"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -113.3579126,
    //       44.6800856,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Leadore, ID"
    //   },
    //   id: "ac5f74c7-0bc2-448f-be4f-67ed2cd328b4"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -113.6443046,
    //       44.9594333,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Tendoy, ID"
    //   },
    //   id: "0923042b-df54-4be6-8411-475adb798287"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -113.8959001,
    //       45.1757544,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Salmon, ID"
    //   },
    //   id: "115157fe-6a39-467d-a023-0b9ac905f7bc"
    // }
	],
};

export default SacajaweaHistoric;
