import { useEffect, useRef, useState } from "react";
import { IconClose } from "../../images/icons";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { firestore } from "../../auth/firebase";
import { doc, addDoc, updateDoc, collection } from "firebase/firestore";
import store from "../../store/store";
import { useSelector } from "react-redux";
import { updatePopups } from "../../store/actions/popupsActions";
import { updateItineraryName, updateItineraryId, updateItineraryStatus } from "../../store/actions/itineraryActions";
import { setLastItem } from "../../store/actions/lastItemActions";

function SaveItineraryPopup(props) {
	const inputRef = useRef();
	const [message, setMessage] = useState('');
	const userName = useSelector((state) => state.user.userName);
	const cart = useSelector((state) => state.cart.cartItems);
	const userID = useSelector((state) => state.user.userID);
	const route = useSelector((state) => state.routes.route);
	const directions = useSelector((state) => state.routes.directions);
	const startingPoint = useSelector((state) => state.routes.startingPoint);
	const itinerary = useSelector((state) => state.itinerary);

	const saveNewItin = () => {
		//this function is to clear the stored itin & let the user save it as a new itin
		store.dispatch(updateItineraryId(''));
		store.dispatch(updateItineraryName(''));
		store.dispatch(updateItineraryStatus(''));
	}

	const saveItin = async () => {
		if (!inputRef.current.value) {
			inputRef.current.classList.add('error');
			return; //force user to input an itinerary name...
		}

		let newItinName = inputRef.current.value;
		let userNameTrimmed = userName.trim().toLowerCase().replace(/\s+/g, '-');
		let tmpCart = [];
		cart.map((itm, i) => {
			let newItem = Object.assign({}, itm);
			tmpCart.push(newItem);
		});
		try {
			await addDoc(collection(firestore, "itineraries"), {
				name: inputRef.current.value,
				user_name: userNameTrimmed,
				user_id: userID,
				markers: JSON.stringify(tmpCart),
				route: JSON.stringify(route),
				starting_point: JSON.stringify(startingPoint),
				directions: JSON.stringify(directions),
				createdAt: Date.now(),
				lastModified: Date.now()
			}).then((docRef) => {
				store.dispatch(updateItineraryId(docRef.id));
				store.dispatch(updateItineraryStatus('saved'));
				setMessage("Successfully added, click here to view your itinerary");
				store.dispatch(updateItineraryName(inputRef.current.value));
			}).then(() => {
				store.dispatch(updatePopups({ display: false, type: '' }));		
				store.dispatch(setLastItem({
					dynamicText: newItinName,
					message: 'saveItinerary',
					icon: true,
					type: 'success'
				}));
			});
		} catch (e) {
			setMessage(e);
			//console.log(e);
		}
	}

	const reSaveItin = async () => {
		let userNameTrimmed = userName.trim().toLowerCase().replace(/\s+/g, '-');
		let tmpCart = [];
		cart.map((itm, i) => {
			let newItem = Object.assign({}, itm);
			tmpCart.push(newItem);
		});
		try {
			await updateDoc(doc(firestore, "itineraries", itinerary.itineraryId), {
				user_name: userNameTrimmed,
				user_id: userID,
				markers: JSON.stringify(tmpCart),
				route: JSON.stringify(route),
				starting_point: JSON.stringify(startingPoint),
				directions: JSON.stringify(directions),
				lastModified: Date.now()
			}).then(() => {
				store.dispatch(updateItineraryStatus('saved'));
				setMessage("Successfully updated, click here to view your itinerary");
			}).then(() => {
				store.dispatch(updatePopups({ display: false, type: '' }));
				store.dispatch(setLastItem({
					dynamicText: itinerary.itineraryName,
					message: 'updateItinerary',
					icon: true,
					type: 'success'
				}));
			})
		} catch (e) {
			setMessage(e);
			//console.log(e);
		}
	}

	return (
		<>
			<div className="popup">
				<div className="popup-inner login-popup">
					<div className="popup-header">
						<h2>Save Your Itinerary</h2>
						<a onClick={props.closePopup} className="popup-close">
							<IconClose />
						</a>
					</div>
					<div className="popup-content">

						{(itinerary.itineraryId === "") ?
							<h2>Enter a name for your itinerary.</h2>
							: <h2>{itinerary.itineraryName} already exists. Would you like to update it?</h2>
						}
						
						{/* this is breaking things? I fixed the alert so this might not be needed (message && message !== '') && <p>{message}</p>*/}

						{(itinerary.itineraryId === "") &&
							<div className="field-group">
								<input placeholder="My awesome northern Idaho adventure" className="input-block" ref={inputRef} />
								<span className="validation">This field is required.</span>
							</div>
						}

						{(itinerary.itineraryId === "") ?
							<button
								className="btn btn-block btn-green"
								onClick={() => saveItin()}
							>
								Save
							</button>
							:
							<>
							<button
								className="btn btn-block btn-green"
								onClick={() => reSaveItin()}
							>
								Update
							</button>
							<br/>
							<button
								className="btn btn-block btn-white"
								onClick={() => saveNewItin()}
							>
								Save New Itinerary
							</button>
							</>
						}

					</div>
				</div>
			</div>
		</>
	);
}

export default SaveItineraryPopup;
