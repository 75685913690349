const idahoJson = {
    "_id": "idaho",
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "name": "Idaho",
                "abbreviation": "ID",
                "stroke": "#336365",
                "stroke-width": 2,
                "stroke-opacity": 1,
                "fill": "transparent",
                "fill-opacity": 0
            },
            "geometry": {
                "coordinates": [
                    [
                      [
                        -111.046689,
                        42.001567
                      ],
                      [
                        -111.415873,
                        42.000748
                      ],
                      [
                        -111.420898,
                        42.000793
                      ],
                      [
                        -111.425535,
                        42.00084
                      ],
                      [
                        -111.471381,
                        41.999739
                      ],
                      [
                        -111.507264,
                        41.999518
                      ],
                      [
                        -111.750778,
                        41.99933
                      ],
                      [
                        -111.876491,
                        41.998528
                      ],
                      [
                        -111.915622,
                        41.998496
                      ],
                      [
                        -111.915837,
                        41.998519
                      ],
                      [
                        -112.01218,
                        41.99835
                      ],
                      [
                        -112.109532,
                        41.997598
                      ],
                      [
                        -112.163956,
                        41.996708
                      ],
                      [
                        -112.173352,
                        41.996568
                      ],
                      [
                        -112.192976,
                        42.001167
                      ],
                      [
                        -112.239107,
                        42.001217
                      ],
                      [
                        -112.264936,
                        42.000991
                      ],
                      [
                        -112.38617,
                        42.001126
                      ],
                      [
                        -112.450567,
                        42.001092
                      ],
                      [
                        -112.450814,
                        42.000953
                      ],
                      [
                        -112.648019,
                        42.000307
                      ],
                      [
                        -112.709375,
                        42.000309
                      ],
                      [
                        -112.788542,
                        41.999681
                      ],
                      [
                        -112.833084,
                        41.999305
                      ],
                      [
                        -112.833125,
                        41.999345
                      ],
                      [
                        -112.880619,
                        41.998921
                      ],
                      [
                        -112.882367,
                        41.998922
                      ],
                      [
                        -112.909587,
                        41.998791
                      ],
                      [
                        -112.979218,
                        41.998263
                      ],
                      [
                        -113.000821,
                        41.998223
                      ],
                      [
                        -113.249159,
                        41.996203
                      ],
                      [
                        -113.250829,
                        41.99561
                      ],
                      [
                        -113.340072,
                        41.994747
                      ],
                      [
                        -113.357611,
                        41.993859
                      ],
                      [
                        -113.396497,
                        41.99425
                      ],
                      [
                        -113.40223,
                        41.994161
                      ],
                      [
                        -113.431563,
                        41.993799
                      ],
                      [
                        -113.496548,
                        41.993305
                      ],
                      [
                        -113.500837,
                        41.992799
                      ],
                      [
                        -113.76453,
                        41.989459
                      ],
                      [
                        -113.796082,
                        41.989104
                      ],
                      [
                        -113.822163,
                        41.988479
                      ],
                      [
                        -113.893261,
                        41.988057
                      ],
                      [
                        -113.993903,
                        41.992698
                      ],
                      [
                        -114.041723,
                        41.99372
                      ],
                      [
                        -114.048246,
                        41.993721
                      ],
                      [
                        -114.048257,
                        41.993814
                      ],
                      [
                        -114.061774,
                        41.993797
                      ],
                      [
                        -114.061763,
                        41.993939
                      ],
                      [
                        -114.107259,
                        41.993831
                      ],
                      [
                        -114.107428,
                        41.993965
                      ],
                      [
                        -114.281855,
                        41.994214
                      ],
                      [
                        -114.467581,
                        41.995492
                      ],
                      [
                        -114.498243,
                        41.994636
                      ],
                      [
                        -114.498259,
                        41.994599
                      ],
                      [
                        -114.598267,
                        41.994511
                      ],
                      [
                        -114.720715,
                        41.998231
                      ],
                      [
                        -114.806384,
                        42.001822
                      ],
                      [
                        -114.831077,
                        42.002207
                      ],
                      [
                        -114.875877,
                        42.001319
                      ],
                      [
                        -114.89921,
                        41.999909
                      ],
                      [
                        -114.914187,
                        41.999909
                      ],
                      [
                        -115.031783,
                        41.996008
                      ],
                      [
                        -115.250795,
                        41.996156
                      ],
                      [
                        -115.254333,
                        41.996721
                      ],
                      [
                        -115.313877,
                        41.996103
                      ],
                      [
                        -115.586849,
                        41.996884
                      ],
                      [
                        -115.625914,
                        41.997415
                      ],
                      [
                        -115.870181,
                        41.996766
                      ],
                      [
                        -115.879596,
                        41.997891
                      ],
                      [
                        -115.887612,
                        41.998048
                      ],
                      [
                        -115.98688,
                        41.998534
                      ],
                      [
                        -116.012212,
                        41.998035
                      ],
                      [
                        -116.012219,
                        41.998048
                      ],
                      [
                        -116.018945,
                        41.997722
                      ],
                      [
                        -116.01896,
                        41.997762
                      ],
                      [
                        -116.030758,
                        41.997383
                      ],
                      [
                        -116.030754,
                        41.997399
                      ],
                      [
                        -116.03857,
                        41.997413
                      ],
                      [
                        -116.038602,
                        41.99746
                      ],
                      [
                        -116.160833,
                        41.997508
                      ],
                      [
                        -116.163931,
                        41.997555
                      ],
                      [
                        -116.332763,
                        41.997283
                      ],
                      [
                        -116.368478,
                        41.996281
                      ],
                      [
                        -116.463528,
                        41.996547
                      ],
                      [
                        -116.483094,
                        41.996885
                      ],
                      [
                        -116.485823,
                        41.996861
                      ],
                      [
                        -116.499777,
                        41.99674
                      ],
                      [
                        -116.501741,
                        41.997334
                      ],
                      [
                        -116.510452,
                        41.997096
                      ],
                      [
                        -116.525319,
                        41.997558
                      ],
                      [
                        -116.582217,
                        41.997834
                      ],
                      [
                        -116.586937,
                        41.99737
                      ],
                      [
                        -116.625947,
                        41.997379
                      ],
                      [
                        -116.62677,
                        41.99775
                      ],
                      [
                        -116.969156,
                        41.998991
                      ],
                      [
                        -117.009255,
                        41.998127
                      ],
                      [
                        -117.018294,
                        41.999358
                      ],
                      [
                        -117.026222,
                        42.000252
                      ],
                      [
                        -117.026098,
                        42.117647
                      ],
                      [
                        -117.02659,
                        42.133258
                      ],
                      [
                        -117.026195,
                        42.166404
                      ],
                      [
                        -117.026129,
                        42.357193
                      ],
                      [
                        -117.026551,
                        42.378557
                      ],
                      [
                        -117.026665,
                        42.624878
                      ],
                      [
                        -117.026331,
                        42.807015
                      ],
                      [
                        -117.026303,
                        42.80717
                      ],
                      [
                        -117.026253,
                        42.807447
                      ],
                      [
                        -117.026683,
                        43.024876
                      ],
                      [
                        -117.026652,
                        43.025128
                      ],
                      [
                        -117.026746,
                        43.577526
                      ],
                      [
                        -117.026774,
                        43.578674
                      ],
                      [
                        -117.026922,
                        43.593632
                      ],
                      [
                        -117.026889,
                        43.596033
                      ],
                      [
                        -117.026824,
                        43.600357
                      ],
                      [
                        -117.02676,
                        43.601912
                      ],
                      [
                        -117.026789,
                        43.610669
                      ],
                      [
                        -117.026937,
                        43.617614
                      ],
                      [
                        -117.027001,
                        43.621032
                      ],
                      [
                        -117.026905,
                        43.62488
                      ],
                      [
                        -117.026705,
                        43.631659
                      ],
                      [
                        -117.026661,
                        43.664385
                      ],
                      [
                        -117.026717,
                        43.675523
                      ],
                      [
                        -117.026586,
                        43.683001
                      ],
                      [
                        -117.026825,
                        43.706193
                      ],
                      [
                        -117.026725,
                        43.714815
                      ],
                      [
                        -117.026841,
                        43.732905
                      ],
                      [
                        -117.026651,
                        43.733935
                      ],
                      [
                        -117.026634,
                        43.808104
                      ],
                      [
                        -117.02678,
                        43.829841
                      ],
                      [
                        -117.026871,
                        43.832479
                      ],
                      [
                        -117.026143,
                        43.83448
                      ],
                      [
                        -117.013954,
                        43.859358
                      ],
                      [
                        -117.01077,
                        43.862269
                      ],
                      [
                        -116.999061,
                        43.864637
                      ],
                      [
                        -116.997391,
                        43.864874
                      ],
                      [
                        -116.991415,
                        43.863864
                      ],
                      [
                        -116.989598,
                        43.864301
                      ],
                      [
                        -116.98294,
                        43.86771
                      ],
                      [
                        -116.982347,
                        43.86884
                      ],
                      [
                        -116.976024,
                        43.895548
                      ],
                      [
                        -116.976429,
                        43.901293
                      ],
                      [
                        -116.977332,
                        43.905812
                      ],
                      [
                        -116.966256,
                        43.918573
                      ],
                      [
                        -116.963666,
                        43.921363
                      ],
                      [
                        -116.96247,
                        43.928336
                      ],
                      [
                        -116.963666,
                        43.952644
                      ],
                      [
                        -116.966256,
                        43.955832
                      ],
                      [
                        -116.969245,
                        43.957426
                      ],
                      [
                        -116.970241,
                        43.958622
                      ],
                      [
                        -116.971237,
                        43.960216
                      ],
                      [
                        -116.971835,
                        43.962806
                      ],
                      [
                        -116.971436,
                        43.964998
                      ],
                      [
                        -116.969842,
                        43.967588
                      ],
                      [
                        -116.966314,
                        43.968884
                      ],
                      [
                        -116.957527,
                        43.972443
                      ],
                      [
                        -116.942944,
                        43.987512
                      ],
                      [
                        -116.942346,
                        43.989106
                      ],
                      [
                        -116.936765,
                        44.010608
                      ],
                      [
                        -116.934485,
                        44.021249
                      ],
                      [
                        -116.934727,
                        44.023806
                      ],
                      [
                        -116.937342,
                        44.029376
                      ],
                      [
                        -116.943361,
                        44.035645
                      ],
                      [
                        -116.956246,
                        44.042888
                      ],
                      [
                        -116.972504,
                        44.048771
                      ],
                      [
                        -116.973185,
                        44.049425
                      ],
                      [
                        -116.974016,
                        44.053663
                      ],
                      [
                        -116.977351,
                        44.085364
                      ],
                      [
                        -116.974253,
                        44.088295
                      ],
                      [
                        -116.967203,
                        44.090936
                      ],
                      [
                        -116.957009,
                        44.091743
                      ],
                      [
                        -116.943132,
                        44.09406
                      ],
                      [
                        -116.937835,
                        44.096943
                      ],
                      [
                        -116.933704,
                        44.100039
                      ],
                      [
                        -116.928306,
                        44.107326
                      ],
                      [
                        -116.927688,
                        44.109438
                      ],
                      [
                        -116.895931,
                        44.154295
                      ],
                      [
                        -116.894309,
                        44.158114
                      ],
                      [
                        -116.894083,
                        44.160191
                      ],
                      [
                        -116.895757,
                        44.171267
                      ],
                      [
                        -116.900103,
                        44.176851
                      ],
                      [
                        -116.902752,
                        44.179467
                      ],
                      [
                        -116.925392,
                        44.191544
                      ],
                      [
                        -116.935443,
                        44.193962
                      ],
                      [
                        -116.940534,
                        44.19371
                      ],
                      [
                        -116.945256,
                        44.191677
                      ],
                      [
                        -116.947591,
                        44.191264
                      ],
                      [
                        -116.965498,
                        44.194126
                      ],
                      [
                        -116.967259,
                        44.194581
                      ],
                      [
                        -116.971675,
                        44.197256
                      ],
                      [
                        -116.973701,
                        44.208017
                      ],
                      [
                        -116.973945,
                        44.225932
                      ],
                      [
                        -116.971958,
                        44.235677
                      ],
                      [
                        -116.973542,
                        44.23998
                      ],
                      [
                        -116.975905,
                        44.242844
                      ],
                      [
                        -116.98687,
                        44.245477
                      ],
                      [
                        -117.001,
                        44.245386
                      ],
                      [
                        -117.016921,
                        44.245391
                      ],
                      [
                        -117.020231,
                        44.246063
                      ],
                      [
                        -117.025277,
                        44.248505
                      ],
                      [
                        -117.027558,
                        44.248881
                      ],
                      [
                        -117.031862,
                        44.248635
                      ],
                      [
                        -117.03585,
                        44.246805
                      ],
                      [
                        -117.042283,
                        44.242775
                      ],
                      [
                        -117.045513,
                        44.232005
                      ],
                      [
                        -117.047062,
                        44.229742
                      ],
                      [
                        -117.050057,
                        44.22883
                      ],
                      [
                        -117.05303,
                        44.229076
                      ],
                      [
                        -117.05651,
                        44.230874
                      ],
                      [
                        -117.059352,
                        44.237244
                      ],
                      [
                        -117.067284,
                        44.24401
                      ],
                      [
                        -117.07835,
                        44.249885
                      ],
                      [
                        -117.089503,
                        44.258234
                      ],
                      [
                        -117.090933,
                        44.260311
                      ],
                      [
                        -117.093578,
                        44.269383
                      ],
                      [
                        -117.09457,
                        44.270978
                      ],
                      [
                        -117.098531,
                        44.275533
                      ],
                      [
                        -117.102242,
                        44.278799
                      ],
                      [
                        -117.104208,
                        44.27994
                      ],
                      [
                        -117.107673,
                        44.280763
                      ],
                      [
                        -117.111617,
                        44.280667
                      ],
                      [
                        -117.118018,
                        44.278945
                      ],
                      [
                        -117.121037,
                        44.277585
                      ],
                      [
                        -117.130904,
                        44.269453
                      ],
                      [
                        -117.13253,
                        44.267045
                      ],
                      [
                        -117.133104,
                        44.264236
                      ],
                      [
                        -117.133984,
                        44.262972
                      ],
                      [
                        -117.138523,
                        44.25937
                      ],
                      [
                        -117.143394,
                        44.258262
                      ],
                      [
                        -117.15706,
                        44.25749
                      ],
                      [
                        -117.170342,
                        44.25889
                      ],
                      [
                        -117.193129,
                        44.270963
                      ],
                      [
                        -117.198147,
                        44.273828
                      ],
                      [
                        -117.216974,
                        44.288357
                      ],
                      [
                        -117.222647,
                        44.297578
                      ],
                      [
                        -117.222451,
                        44.298963
                      ],
                      [
                        -117.220069,
                        44.301382
                      ],
                      [
                        -117.217843,
                        44.30718
                      ],
                      [
                        -117.216795,
                        44.308236
                      ],
                      [
                        -117.21521,
                        44.309116
                      ],
                      [
                        -117.2055,
                        44.311789
                      ],
                      [
                        -117.203323,
                        44.313024
                      ],
                      [
                        -117.192203,
                        44.32863
                      ],
                      [
                        -117.191546,
                        44.329621
                      ],
                      [
                        -117.189842,
                        44.335007
                      ],
                      [
                        -117.189769,
                        44.336585
                      ],
                      [
                        -117.196149,
                        44.346362
                      ],
                      [
                        -117.197339,
                        44.347406
                      ],
                      [
                        -117.206962,
                        44.355206
                      ],
                      [
                        -117.210587,
                        44.357703
                      ],
                      [
                        -117.216911,
                        44.360163
                      ],
                      [
                        -117.227938,
                        44.367975
                      ],
                      [
                        -117.235117,
                        44.373853
                      ],
                      [
                        -117.243027,
                        44.390974
                      ],
                      [
                        -117.242675,
                        44.396548
                      ],
                      [
                        -117.234835,
                        44.399669
                      ],
                      [
                        -117.22698,
                        44.405583
                      ],
                      [
                        -117.225461,
                        44.407729
                      ],
                      [
                        -117.218285,
                        44.420664
                      ],
                      [
                        -117.215072,
                        44.427162
                      ],
                      [
                        -117.214637,
                        44.44803
                      ],
                      [
                        -117.215573,
                        44.453746
                      ],
                      [
                        -117.217015,
                        44.459042
                      ],
                      [
                        -117.221548,
                        44.470146
                      ],
                      [
                        -117.224445,
                        44.473884
                      ],
                      [
                        -117.225758,
                        44.477223
                      ],
                      [
                        -117.225932,
                        44.479389
                      ],
                      [
                        -117.225076,
                        44.482346
                      ],
                      [
                        -117.224104,
                        44.483734
                      ],
                      [
                        -117.216372,
                        44.48616
                      ],
                      [
                        -117.211148,
                        44.485359
                      ],
                      [
                        -117.208936,
                        44.485661
                      ],
                      [
                        -117.200237,
                        44.492027
                      ],
                      [
                        -117.194317,
                        44.499884
                      ],
                      [
                        -117.192494,
                        44.503272
                      ],
                      [
                        -117.191329,
                        44.506784
                      ],
                      [
                        -117.19163,
                        44.509886
                      ],
                      [
                        -117.189759,
                        44.513385
                      ],
                      [
                        -117.185386,
                        44.519261
                      ],
                      [
                        -117.181583,
                        44.52296
                      ],
                      [
                        -117.167187,
                        44.523431
                      ],
                      [
                        -117.161033,
                        44.525166
                      ],
                      [
                        -117.152406,
                        44.531802
                      ],
                      [
                        -117.149242,
                        44.536151
                      ],
                      [
                        -117.144161,
                        44.545647
                      ],
                      [
                        -117.14293,
                        44.557236
                      ],
                      [
                        -117.147934,
                        44.562143
                      ],
                      [
                        -117.148255,
                        44.564371
                      ],
                      [
                        -117.146032,
                        44.568603
                      ],
                      [
                        -117.14248,
                        44.57143
                      ],
                      [
                        -117.138066,
                        44.572996
                      ],
                      [
                        -117.133963,
                        44.57524
                      ],
                      [
                        -117.126009,
                        44.581553
                      ],
                      [
                        -117.124754,
                        44.583834
                      ],
                      [
                        -117.125267,
                        44.593818
                      ],
                      [
                        -117.120522,
                        44.614658
                      ],
                      [
                        -117.117809,
                        44.620139
                      ],
                      [
                        -117.114754,
                        44.624883
                      ],
                      [
                        -117.108231,
                        44.62711
                      ],
                      [
                        -117.098221,
                        44.640689
                      ],
                      [
                        -117.094968,
                        44.652011
                      ],
                      [
                        -117.096791,
                        44.657385
                      ],
                      [
                        -117.095868,
                        44.664737
                      ],
                      [
                        -117.091223,
                        44.668807
                      ],
                      [
                        -117.080772,
                        44.684161
                      ],
                      [
                        -117.080555,
                        44.686714
                      ],
                      [
                        -117.07912,
                        44.692175
                      ],
                      [
                        -117.072221,
                        44.700517
                      ],
                      [
                        -117.063824,
                        44.703623
                      ],
                      [
                        -117.061799,
                        44.706654
                      ],
                      [
                        -117.060454,
                        44.721668
                      ],
                      [
                        -117.062273,
                        44.727143
                      ],
                      [
                        -117.044217,
                        44.74514
                      ],
                      [
                        -117.03827,
                        44.748179
                      ],
                      [
                        -117.013802,
                        44.756841
                      ],
                      [
                        -117.006045,
                        44.756024
                      ],
                      [
                        -116.998903,
                        44.756382
                      ],
                      [
                        -116.992003,
                        44.759182
                      ],
                      [
                        -116.986502,
                        44.762381
                      ],
                      [
                        -116.977802,
                        44.767981
                      ],
                      [
                        -116.972902,
                        44.772581
                      ],
                      [
                        -116.970902,
                        44.773881
                      ],
                      [
                        -116.966801,
                        44.775181
                      ],
                      [
                        -116.949001,
                        44.777981
                      ],
                      [
                        -116.9368,
                        44.782881
                      ],
                      [
                        -116.9347,
                        44.783881
                      ],
                      [
                        -116.9318,
                        44.787181
                      ],
                      [
                        -116.9307,
                        44.789881
                      ],
                      [
                        -116.9308,
                        44.790981
                      ],
                      [
                        -116.933099,
                        44.794481
                      ],
                      [
                        -116.933799,
                        44.796781
                      ],
                      [
                        -116.933699,
                        44.798781
                      ],
                      [
                        -116.931099,
                        44.804781
                      ],
                      [
                        -116.928099,
                        44.808381
                      ],
                      [
                        -116.920498,
                        44.81438
                      ],
                      [
                        -116.905771,
                        44.834794
                      ],
                      [
                        -116.896249,
                        44.84833
                      ],
                      [
                        -116.883598,
                        44.858268
                      ],
                      [
                        -116.865338,
                        44.870599
                      ],
                      [
                        -116.857038,
                        44.880769
                      ],
                      [
                        -116.852427,
                        44.887577
                      ],
                      [
                        -116.850512,
                        44.893523
                      ],
                      [
                        -116.846061,
                        44.905249
                      ],
                      [
                        -116.842108,
                        44.914922
                      ],
                      [
                        -116.838467,
                        44.923601
                      ],
                      [
                        -116.833632,
                        44.928976
                      ],
                      [
                        -116.832176,
                        44.931373
                      ],
                      [
                        -116.83199,
                        44.933007
                      ],
                      [
                        -116.835702,
                        44.940633
                      ],
                      [
                        -116.846461,
                        44.951521
                      ],
                      [
                        -116.850737,
                        44.958113
                      ],
                      [
                        -116.851406,
                        44.959841
                      ],
                      [
                        -116.858313,
                        44.978761
                      ],
                      [
                        -116.856754,
                        44.984298
                      ],
                      [
                        -116.846103,
                        44.999878
                      ],
                      [
                        -116.844625,
                        45.001435
                      ],
                      [
                        -116.844796,
                        45.015312
                      ],
                      [
                        -116.845847,
                        45.01847
                      ],
                      [
                        -116.848037,
                        45.021728
                      ],
                      [
                        -116.847944,
                        45.022602
                      ],
                      [
                        -116.841314,
                        45.030907
                      ],
                      [
                        -116.830115,
                        45.035317
                      ],
                      [
                        -116.825133,
                        45.03784
                      ],
                      [
                        -116.808576,
                        45.050652
                      ],
                      [
                        -116.797329,
                        45.060267
                      ],
                      [
                        -116.78371,
                        45.076972
                      ],
                      [
                        -116.784244,
                        45.088128
                      ],
                      [
                        -116.783537,
                        45.093605
                      ],
                      [
                        -116.782492,
                        45.09579
                      ],
                      [
                        -116.774847,
                        45.105536
                      ],
                      [
                        -116.754643,
                        45.113972
                      ],
                      [
                        -116.731216,
                        45.139934
                      ],
                      [
                        -116.729607,
                        45.142091
                      ],
                      [
                        -116.728757,
                        45.144381
                      ],
                      [
                        -116.724188,
                        45.162924
                      ],
                      [
                        -116.724205,
                        45.171501
                      ],
                      [
                        -116.709536,
                        45.203015
                      ],
                      [
                        -116.708546,
                        45.207356
                      ],
                      [
                        -116.70975,
                        45.217243
                      ],
                      [
                        -116.709373,
                        45.219463
                      ],
                      [
                        -116.703607,
                        45.239757
                      ],
                      [
                        -116.696047,
                        45.254679
                      ],
                      [
                        -116.691388,
                        45.263739
                      ],
                      [
                        -116.687027,
                        45.267857
                      ],
                      [
                        -116.681013,
                        45.27072
                      ],
                      [
                        -116.675587,
                        45.274867
                      ],
                      [
                        -116.674493,
                        45.276349
                      ],
                      [
                        -116.672733,
                        45.283183
                      ],
                      [
                        -116.672163,
                        45.288938
                      ],
                      [
                        -116.672594,
                        45.298023
                      ],
                      [
                        -116.674648,
                        45.314342
                      ],
                      [
                        -116.673793,
                        45.321511
                      ],
                      [
                        -116.653252,
                        45.351084
                      ],
                      [
                        -116.626633,
                        45.388037
                      ],
                      [
                        -116.619057,
                        45.39821
                      ],
                      [
                        -116.597447,
                        45.41277
                      ],
                      [
                        -116.592416,
                        45.427356
                      ],
                      [
                        -116.588195,
                        45.44292
                      ],
                      [
                        -116.581382,
                        45.448984
                      ],
                      [
                        -116.575949,
                        45.452522
                      ],
                      [
                        -116.563985,
                        45.460169
                      ],
                      [
                        -116.561744,
                        45.461213
                      ],
                      [
                        -116.554829,
                        45.46293
                      ],
                      [
                        -116.55498,
                        45.472801
                      ],
                      [
                        -116.558803,
                        45.480076
                      ],
                      [
                        -116.558804,
                        45.481188
                      ],
                      [
                        -116.553473,
                        45.499107
                      ],
                      [
                        -116.548676,
                        45.510385
                      ],
                      [
                        -116.543837,
                        45.514193
                      ],
                      [
                        -116.535482,
                        45.525079
                      ],
                      [
                        -116.523638,
                        45.54661
                      ],
                      [
                        -116.502756,
                        45.566608
                      ],
                      [
                        -116.490279,
                        45.574499
                      ],
                      [
                        -116.48297,
                        45.577008
                      ],
                      [
                        -116.481943,
                        45.577898
                      ],
                      [
                        -116.463635,
                        45.602785
                      ],
                      [
                        -116.463504,
                        45.615785
                      ],
                      [
                        -116.46517,
                        45.617986
                      ],
                      [
                        -116.469813,
                        45.620604
                      ],
                      [
                        -116.472882,
                        45.624884
                      ],
                      [
                        -116.477452,
                        45.631267
                      ],
                      [
                        -116.482495,
                        45.639916
                      ],
                      [
                        -116.487894,
                        45.649769
                      ],
                      [
                        -116.49451,
                        45.655679
                      ],
                      [
                        -116.512326,
                        45.670224
                      ],
                      [
                        -116.523961,
                        45.677639
                      ],
                      [
                        -116.528272,
                        45.681473
                      ],
                      [
                        -116.535396,
                        45.691734
                      ],
                      [
                        -116.536395,
                        45.69665
                      ],
                      [
                        -116.538014,
                        45.714929
                      ],
                      [
                        -116.535698,
                        45.734231
                      ],
                      [
                        -116.537173,
                        45.737288
                      ],
                      [
                        -116.546643,
                        45.750972
                      ],
                      [
                        -116.549085,
                        45.752735
                      ],
                      [
                        -116.553548,
                        45.753388
                      ],
                      [
                        -116.559444,
                        45.755189
                      ],
                      [
                        -116.577422,
                        45.76753
                      ],
                      [
                        -116.593004,
                        45.778541
                      ],
                      [
                        -116.60504,
                        45.781018
                      ],
                      [
                        -116.632032,
                        45.784979
                      ],
                      [
                        -116.635814,
                        45.783642
                      ],
                      [
                        -116.639641,
                        45.781274
                      ],
                      [
                        -116.646342,
                        45.779815
                      ],
                      [
                        -116.659629,
                        45.780016
                      ],
                      [
                        -116.665344,
                        45.781998
                      ],
                      [
                        -116.680139,
                        45.79359
                      ],
                      [
                        -116.687007,
                        45.806319
                      ],
                      [
                        -116.697192,
                        45.820135
                      ],
                      [
                        -116.698079,
                        45.820852
                      ],
                      [
                        -116.70845,
                        45.825117
                      ],
                      [
                        -116.711822,
                        45.826267
                      ],
                      [
                        -116.715527,
                        45.826773
                      ],
                      [
                        -116.736268,
                        45.826179
                      ],
                      [
                        -116.740486,
                        45.82446
                      ],
                      [
                        -116.745219,
                        45.821394
                      ],
                      [
                        -116.750978,
                        45.818537
                      ],
                      [
                        -116.755288,
                        45.817061
                      ],
                      [
                        -116.759787,
                        45.816167
                      ],
                      [
                        -116.7634,
                        45.81658
                      ],
                      [
                        -116.782676,
                        45.825376
                      ],
                      [
                        -116.788329,
                        45.831928
                      ],
                      [
                        -116.789066,
                        45.833471
                      ],
                      [
                        -116.788923,
                        45.836741
                      ],
                      [
                        -116.78752,
                        45.840204
                      ],
                      [
                        -116.787792,
                        45.844267
                      ],
                      [
                        -116.790151,
                        45.849851
                      ],
                      [
                        -116.796051,
                        45.858473
                      ],
                      [
                        -116.814142,
                        45.877551
                      ],
                      [
                        -116.819182,
                        45.880938
                      ],
                      [
                        -116.830003,
                        45.886405
                      ],
                      [
                        -116.84355,
                        45.892273
                      ],
                      [
                        -116.857254,
                        45.904159
                      ],
                      [
                        -116.859795,
                        45.907264
                      ],
                      [
                        -116.866544,
                        45.916958
                      ],
                      [
                        -116.869655,
                        45.923799
                      ],
                      [
                        -116.875706,
                        45.945008
                      ],
                      [
                        -116.886843,
                        45.958617
                      ],
                      [
                        -116.892935,
                        45.974396
                      ],
                      [
                        -116.911409,
                        45.988912
                      ],
                      [
                        -116.915989,
                        45.995413
                      ],
                      [
                        -116.91718,
                        45.996575
                      ],
                      [
                        -116.91868,
                        45.999875
                      ],
                      [
                        -116.923005,
                        46.018293
                      ],
                      [
                        -116.931706,
                        46.039651
                      ],
                      [
                        -116.942656,
                        46.061
                      ],
                      [
                        -116.948564,
                        46.067933
                      ],
                      [
                        -116.957372,
                        46.075449
                      ],
                      [
                        -116.960416,
                        46.076346
                      ],
                      [
                        -116.96319,
                        46.075905
                      ],
                      [
                        -116.970009,
                        46.076769
                      ],
                      [
                        -116.978938,
                        46.080007
                      ],
                      [
                        -116.981962,
                        46.084915
                      ],
                      [
                        -116.982479,
                        46.089389
                      ],
                      [
                        -116.982498,
                        46.091347
                      ],
                      [
                        -116.981747,
                        46.092881
                      ],
                      [
                        -116.978823,
                        46.095731
                      ],
                      [
                        -116.976957,
                        46.09667
                      ],
                      [
                        -116.974058,
                        46.097707
                      ],
                      [
                        -116.959548,
                        46.099058
                      ],
                      [
                        -116.955263,
                        46.102237
                      ],
                      [
                        -116.951265,
                        46.111161
                      ],
                      [
                        -116.95098,
                        46.118853
                      ],
                      [
                        -116.950276,
                        46.123464
                      ],
                      [
                        -116.948336,
                        46.125885
                      ],
                      [
                        -116.935473,
                        46.142448
                      ],
                      [
                        -116.922648,
                        46.160744
                      ],
                      [
                        -116.921258,
                        46.164795
                      ],
                      [
                        -116.92187,
                        46.167808
                      ],
                      [
                        -116.923958,
                        46.17092
                      ],
                      [
                        -116.941724,
                        46.185034
                      ],
                      [
                        -116.952416,
                        46.193514
                      ],
                      [
                        -116.962966,
                        46.19968
                      ],
                      [
                        -116.965841,
                        46.203417
                      ],
                      [
                        -116.966569,
                        46.207501
                      ],
                      [
                        -116.96613,
                        46.209453
                      ],
                      [
                        -116.959428,
                        46.219812
                      ],
                      [
                        -116.956031,
                        46.225976
                      ],
                      [
                        -116.955264,
                        46.23088
                      ],
                      [
                        -116.958801,
                        46.24232
                      ],
                      [
                        -116.964379,
                        46.253282
                      ],
                      [
                        -116.966742,
                        46.256923
                      ],
                      [
                        -116.970298,
                        46.261233
                      ],
                      [
                        -116.972591,
                        46.263271
                      ],
                      [
                        -116.976054,
                        46.26601
                      ],
                      [
                        -116.987391,
                        46.272865
                      ],
                      [
                        -116.991134,
                        46.276342
                      ],
                      [
                        -116.991422,
                        46.278467
                      ],
                      [
                        -116.990894,
                        46.280372
                      ],
                      [
                        -116.98491,
                        46.289738
                      ],
                      [
                        -116.98463,
                        46.292705
                      ],
                      [
                        -116.986688,
                        46.296662
                      ],
                      [
                        -116.989794,
                        46.299395
                      ],
                      [
                        -116.99726,
                        46.303151
                      ],
                      [
                        -117.007486,
                        46.305302
                      ],
                      [
                        -117.016413,
                        46.311236
                      ],
                      [
                        -117.020663,
                        46.314793
                      ],
                      [
                        -117.022293,
                        46.31747
                      ],
                      [
                        -117.022939,
                        46.320175
                      ],
                      [
                        -117.023424,
                        46.326427
                      ],
                      [
                        -117.022706,
                        46.32899
                      ],
                      [
                        -117.023149,
                        46.334759
                      ],
                      [
                        -117.023844,
                        46.335976
                      ],
                      [
                        -117.027744,
                        46.338751
                      ],
                      [
                        -117.030672,
                        46.340315
                      ],
                      [
                        -117.03445,
                        46.34132
                      ],
                      [
                        -117.045469,
                        46.34249
                      ],
                      [
                        -117.051735,
                        46.343833
                      ],
                      [
                        -117.055983,
                        46.345531
                      ],
                      [
                        -117.060703,
                        46.349015
                      ],
                      [
                        -117.06263,
                        46.352522
                      ],
                      [
                        -117.062748,
                        46.353624
                      ],
                      [
                        -117.062785,
                        46.365287
                      ],
                      [
                        -117.061045,
                        46.367747
                      ],
                      [
                        -117.057516,
                        46.371396
                      ],
                      [
                        -117.051775,
                        46.375641
                      ],
                      [
                        -117.049474,
                        46.37682
                      ],
                      [
                        -117.046915,
                        46.379577
                      ],
                      [
                        -117.04195,
                        46.39216
                      ],
                      [
                        -117.041737,
                        46.395195
                      ],
                      [
                        -117.038282,
                        46.40604
                      ],
                      [
                        -117.036455,
                        46.407792
                      ],
                      [
                        -117.035545,
                        46.410012
                      ],
                      [
                        -117.034696,
                        46.418318
                      ],
                      [
                        -117.036562,
                        46.422596
                      ],
                      [
                        -117.039813,
                        46.425425
                      ],
                      [
                        -117.039741,
                        46.462704
                      ],
                      [
                        -117.039763,
                        46.46957
                      ],
                      [
                        -117.039771,
                        46.471779
                      ],
                      [
                        -117.039398,
                        46.700186
                      ],
                      [
                        -117.039828,
                        46.815443
                      ],
                      [
                        -117.039657,
                        46.825798
                      ],
                      [
                        -117.039836,
                        47.154734
                      ],
                      [
                        -117.039871,
                        47.181858
                      ],
                      [
                        -117.039888,
                        47.203282
                      ],
                      [
                        -117.039899,
                        47.225515
                      ],
                      [
                        -117.040019,
                        47.259272
                      ],
                      [
                        -117.039843,
                        47.347201
                      ],
                      [
                        -117.040176,
                        47.3749
                      ],
                      [
                        -117.039882,
                        47.399085
                      ],
                      [
                        -117.03995,
                        47.412412
                      ],
                      [
                        -117.039948,
                        47.434885
                      ],
                      [
                        -117.039971,
                        47.463309
                      ],
                      [
                        -117.039945,
                        47.477823
                      ],
                      [
                        -117.040514,
                        47.522351
                      ],
                      [
                        -117.040545,
                        47.527562
                      ],
                      [
                        -117.040745,
                        47.532909
                      ],
                      [
                        -117.041276,
                        47.55821
                      ],
                      [
                        -117.041174,
                        47.55853
                      ],
                      [
                        -117.04085,
                        47.574124
                      ],
                      [
                        -117.041431,
                        47.67814
                      ],
                      [
                        -117.041431,
                        47.678185
                      ],
                      [
                        -117.041431,
                        47.68
                      ],
                      [
                        -117.041532,
                        47.683194
                      ],
                      [
                        -117.041633,
                        47.7064
                      ],
                      [
                        -117.041678,
                        47.72271
                      ],
                      [
                        -117.041634,
                        47.7353
                      ],
                      [
                        -117.042135,
                        47.7441
                      ],
                      [
                        -117.042059,
                        47.7451
                      ],
                      [
                        -117.042657,
                        47.760857
                      ],
                      [
                        -117.042623,
                        47.761223
                      ],
                      [
                        -117.042521,
                        47.764896
                      ],
                      [
                        -117.042485,
                        47.766525
                      ],
                      [
                        -117.042064,
                        47.77863
                      ],
                      [
                        -117.041999,
                        47.822399
                      ],
                      [
                        -117.04247,
                        47.839009
                      ],
                      [
                        -117.04236,
                        47.966343
                      ],
                      [
                        -117.041676,
                        48.04556
                      ],
                      [
                        -117.041401,
                        48.0855
                      ],
                      [
                        -117.041107,
                        48.124904
                      ],
                      [
                        -117.039552,
                        48.17396
                      ],
                      [
                        -117.039413,
                        48.17725
                      ],
                      [
                        -117.039618,
                        48.178142
                      ],
                      [
                        -117.039583,
                        48.180313
                      ],
                      [
                        -117.039582,
                        48.180853
                      ],
                      [
                        -117.039582,
                        48.181124
                      ],
                      [
                        -117.039615,
                        48.184015
                      ],
                      [
                        -117.039599,
                        48.184387
                      ],
                      [
                        -117.038602,
                        48.207939
                      ],
                      [
                        -117.035178,
                        48.370878
                      ],
                      [
                        -117.035178,
                        48.371221
                      ],
                      [
                        -117.035289,
                        48.422732
                      ],
                      [
                        -117.035254,
                        48.423144
                      ],
                      [
                        -117.035285,
                        48.429816
                      ],
                      [
                        -117.035285,
                        48.430113
                      ],
                      [
                        -117.035425,
                        48.499914
                      ],
                      [
                        -117.034499,
                        48.620769
                      ],
                      [
                        -117.034358,
                        48.628523
                      ],
                      [
                        -117.033671,
                        48.656902
                      ],
                      [
                        -117.033335,
                        48.749921
                      ],
                      [
                        -117.032107,
                        48.874926
                      ],
                      [
                        -117.032351,
                        48.999188
                      ],
                      [
                        -116.757185,
                        48.999791
                      ],
                      [
                        -116.757234,
                        48.999943
                      ],
                      [
                        -116.417503,
                        49.000099
                      ],
                      [
                        -116.376039,
                        49.000518
                      ],
                      [
                        -116.369128,
                        49.001146
                      ],
                      [
                        -116.049193,
                        49.000912
                      ],
                      [
                        -116.049155,
                        48.481247
                      ],
                      [
                        -116.050003,
                        48.413492
                      ],
                      [
                        -116.048948,
                        48.309847
                      ],
                      [
                        -116.049735,
                        48.274668
                      ],
                      [
                        -116.049353,
                        48.249936
                      ],
                      [
                        -116.049977,
                        48.237604
                      ],
                      [
                        -116.048911,
                        48.12493
                      ],
                      [
                        -116.049415,
                        48.07722
                      ],
                      [
                        -116.04932,
                        48.066644
                      ],
                      [
                        -116.048739,
                        48.060093
                      ],
                      [
                        -116.049153,
                        47.999923
                      ],
                      [
                        -116.04885,
                        47.977186
                      ],
                      [
                        -116.048421,
                        47.97682
                      ],
                      [
                        -116.03834,
                        47.971318
                      ],
                      [
                        -116.030751,
                        47.973349
                      ],
                      [
                        -116.007246,
                        47.950087
                      ],
                      [
                        -115.998236,
                        47.938779
                      ],
                      [
                        -115.995121,
                        47.933827
                      ],
                      [
                        -115.993678,
                        47.926183
                      ],
                      [
                        -115.982791,
                        47.915994
                      ],
                      [
                        -115.969076,
                        47.914256
                      ],
                      [
                        -115.965153,
                        47.910131
                      ],
                      [
                        -115.959946,
                        47.898142
                      ],
                      [
                        -115.939993,
                        47.883153
                      ],
                      [
                        -115.919291,
                        47.857406
                      ],
                      [
                        -115.906409,
                        47.846261
                      ],
                      [
                        -115.900934,
                        47.843064
                      ],
                      [
                        -115.881522,
                        47.849672
                      ],
                      [
                        -115.875262,
                        47.843272
                      ],
                      [
                        -115.870861,
                        47.834939
                      ],
                      [
                        -115.852291,
                        47.827991
                      ],
                      [
                        -115.845474,
                        47.814967
                      ],
                      [
                        -115.848509,
                        47.809331
                      ],
                      [
                        -115.847487,
                        47.785227
                      ],
                      [
                        -115.84044,
                        47.780172
                      ],
                      [
                        -115.837438,
                        47.774846
                      ],
                      [
                        -115.835069,
                        47.77006
                      ],
                      [
                        -115.835365,
                        47.760957
                      ],
                      [
                        -115.831755,
                        47.755785
                      ],
                      [
                        -115.824597,
                        47.752154
                      ],
                      [
                        -115.803917,
                        47.75848
                      ],
                      [
                        -115.797299,
                        47.75752
                      ],
                      [
                        -115.780441,
                        47.743447
                      ],
                      [
                        -115.783504,
                        47.729305
                      ],
                      [
                        -115.776219,
                        47.719818
                      ],
                      [
                        -115.77177,
                        47.717412
                      ],
                      [
                        -115.763424,
                        47.717313
                      ],
                      [
                        -115.758623,
                        47.719041
                      ],
                      [
                        -115.752349,
                        47.716743
                      ],
                      [
                        -115.730764,
                        47.704426
                      ],
                      [
                        -115.72377,
                        47.696671
                      ],
                      [
                        -115.726613,
                        47.672093
                      ],
                      [
                        -115.73627,
                        47.654762
                      ],
                      [
                        -115.72993,
                        47.642442
                      ],
                      [
                        -115.715193,
                        47.63634
                      ],
                      [
                        -115.708537,
                        47.635356
                      ],
                      [
                        -115.694284,
                        47.62346
                      ],
                      [
                        -115.689404,
                        47.595402
                      ],
                      [
                        -115.706473,
                        47.577299
                      ],
                      [
                        -115.721207,
                        47.576323
                      ],
                      [
                        -115.734674,
                        47.567401
                      ],
                      [
                        -115.746945,
                        47.555293
                      ],
                      [
                        -115.748536,
                        47.549245
                      ],
                      [
                        -115.747263,
                        47.543197
                      ],
                      [
                        -115.741371,
                        47.538645
                      ],
                      [
                        -115.717024,
                        47.532693
                      ],
                      [
                        -115.71034,
                        47.52951
                      ],
                      [
                        -115.708748,
                        47.51264
                      ],
                      [
                        -115.694106,
                        47.498634
                      ],
                      [
                        -115.686704,
                        47.485596
                      ],
                      [
                        -115.655272,
                        47.477944
                      ],
                      [
                        -115.653044,
                        47.476035
                      ],
                      [
                        -115.654318,
                        47.468077
                      ],
                      [
                        -115.663867,
                        47.456936
                      ],
                      [
                        -115.671188,
                        47.45439
                      ],
                      [
                        -115.69293,
                        47.457237
                      ],
                      [
                        -115.718247,
                        47.45316
                      ],
                      [
                        -115.728801,
                        47.445159
                      ],
                      [
                        -115.731348,
                        47.433381
                      ],
                      [
                        -115.728801,
                        47.428925
                      ],
                      [
                        -115.72148,
                        47.424469
                      ],
                      [
                        -115.718934,
                        47.420967
                      ],
                      [
                        -115.71034,
                        47.417784
                      ],
                      [
                        -115.69057,
                        47.415059
                      ],
                      [
                        -115.657681,
                        47.400651
                      ],
                      [
                        -115.648479,
                        47.390293
                      ],
                      [
                        -115.644341,
                        47.381826
                      ],
                      [
                        -115.639186,
                        47.378605
                      ],
                      [
                        -115.617247,
                        47.382521
                      ],
                      [
                        -115.609492,
                        47.380799
                      ],
                      [
                        -115.578619,
                        47.367007
                      ],
                      [
                        -115.570887,
                        47.356375
                      ],
                      [
                        -115.564766,
                        47.353476
                      ],
                      [
                        -115.556318,
                        47.353076
                      ],
                      [
                        -115.551079,
                        47.349856
                      ],
                      [
                        -115.548658,
                        47.332213
                      ],
                      [
                        -115.531971,
                        47.314121
                      ],
                      [
                        -115.526751,
                        47.303219
                      ],
                      [
                        -115.51186,
                        47.295219
                      ],
                      [
                        -115.487314,
                        47.286518
                      ],
                      [
                        -115.470959,
                        47.284873
                      ],
                      [
                        -115.457077,
                        47.277794
                      ],
                      [
                        -115.443566,
                        47.277309
                      ],
                      [
                        -115.428359,
                        47.278722
                      ],
                      [
                        -115.423173,
                        47.276222
                      ],
                      [
                        -115.421645,
                        47.271736
                      ],
                      [
                        -115.410685,
                        47.264228
                      ],
                      [
                        -115.371825,
                        47.265213
                      ],
                      [
                        -115.36628,
                        47.261485
                      ],
                      [
                        -115.3593,
                        47.259461
                      ],
                      [
                        -115.339201,
                        47.261623
                      ],
                      [
                        -115.326903,
                        47.255912
                      ],
                      [
                        -115.324832,
                        47.244841
                      ],
                      [
                        -115.317124,
                        47.233305
                      ],
                      [
                        -115.311875,
                        47.229673
                      ],
                      [
                        -115.307239,
                        47.229892
                      ],
                      [
                        -115.298794,
                        47.225245
                      ],
                      [
                        -115.294785,
                        47.220914
                      ],
                      [
                        -115.29211,
                        47.209861
                      ],
                      [
                        -115.295986,
                        47.205658
                      ],
                      [
                        -115.300805,
                        47.19393
                      ],
                      [
                        -115.300504,
                        47.188139
                      ],
                      [
                        -115.286353,
                        47.18327
                      ],
                      [
                        -115.261885,
                        47.181742
                      ],
                      [
                        -115.255786,
                        47.174725
                      ],
                      [
                        -115.255146,
                        47.162876
                      ],
                      [
                        -115.243707,
                        47.150347
                      ],
                      [
                        -115.223246,
                        47.148974
                      ],
                      [
                        -115.200547,
                        47.139154
                      ],
                      [
                        -115.189451,
                        47.131032
                      ],
                      [
                        -115.172938,
                        47.112881
                      ],
                      [
                        -115.170436,
                        47.106265
                      ],
                      [
                        -115.140375,
                        47.093013
                      ],
                      [
                        -115.139515,
                        47.08456
                      ],
                      [
                        -115.136671,
                        47.078276
                      ],
                      [
                        -115.120917,
                        47.061237
                      ],
                      [
                        -115.107132,
                        47.049041
                      ],
                      [
                        -115.102681,
                        47.047239
                      ],
                      [
                        -115.098136,
                        47.048897
                      ],
                      [
                        -115.087806,
                        47.045519
                      ],
                      [
                        -115.071254,
                        47.022083
                      ],
                      [
                        -115.066223,
                        46.996375
                      ],
                      [
                        -115.057098,
                        46.986758
                      ],
                      [
                        -115.049538,
                        46.970774
                      ],
                      [
                        -115.047857,
                        46.969533
                      ],
                      [
                        -115.031651,
                        46.971548
                      ],
                      [
                        -115.028994,
                        46.973159
                      ],
                      [
                        -115.028386,
                        46.975659
                      ],
                      [
                        -115.001274,
                        46.971901
                      ],
                      [
                        -115.00091,
                        46.967703
                      ],
                      [
                        -114.986539,
                        46.952099
                      ],
                      [
                        -114.960597,
                        46.93001
                      ],
                      [
                        -114.929997,
                        46.919625
                      ],
                      [
                        -114.927432,
                        46.914185
                      ],
                      [
                        -114.927948,
                        46.909948
                      ],
                      [
                        -114.935035,
                        46.901749
                      ],
                      [
                        -114.936805,
                        46.897378
                      ],
                      [
                        -114.931058,
                        46.882108
                      ],
                      [
                        -114.931608,
                        46.876799
                      ],
                      [
                        -114.938713,
                        46.869021
                      ],
                      [
                        -114.943281,
                        46.867971
                      ],
                      [
                        -114.947413,
                        46.859324
                      ],
                      [
                        -114.940398,
                        46.85605
                      ],
                      [
                        -114.928615,
                        46.854815
                      ],
                      [
                        -114.92349,
                        46.847594
                      ],
                      [
                        -114.92845,
                        46.843242
                      ],
                      [
                        -114.927837,
                        46.83599
                      ],
                      [
                        -114.920459,
                        46.827697
                      ],
                      [
                        -114.904505,
                        46.822851
                      ],
                      [
                        -114.897857,
                        46.813184
                      ],
                      [
                        -114.888146,
                        46.808573
                      ],
                      [
                        -114.880588,
                        46.811791
                      ],
                      [
                        -114.864342,
                        46.813858
                      ],
                      [
                        -114.861376,
                        46.81196
                      ],
                      [
                        -114.860067,
                        46.804988
                      ],
                      [
                        -114.856874,
                        46.801633
                      ],
                      [
                        -114.844794,
                        46.794305
                      ],
                      [
                        -114.835917,
                        46.791111
                      ],
                      [
                        -114.829117,
                        46.782503
                      ],
                      [
                        -114.818161,
                        46.781139
                      ],
                      [
                        -114.808587,
                        46.78235
                      ],
                      [
                        -114.79004,
                        46.778729
                      ],
                      [
                        -114.765106,
                        46.758153
                      ],
                      [
                        -114.765127,
                        46.745383
                      ],
                      [
                        -114.76718,
                        46.738828
                      ],
                      [
                        -114.773765,
                        46.731805
                      ],
                      [
                        -114.779668,
                        46.730411
                      ],
                      [
                        -114.788656,
                        46.714033
                      ],
                      [
                        -114.787065,
                        46.711255
                      ],
                      [
                        -114.76689,
                        46.696901
                      ],
                      [
                        -114.751921,
                        46.697207
                      ],
                      [
                        -114.749257,
                        46.699688
                      ],
                      [
                        -114.747758,
                        46.702649
                      ],
                      [
                        -114.740115,
                        46.711771
                      ],
                      [
                        -114.727445,
                        46.714604
                      ],
                      [
                        -114.713516,
                        46.715138
                      ],
                      [
                        -114.710425,
                        46.717704
                      ],
                      [
                        -114.699008,
                        46.740223
                      ],
                      [
                        -114.696656,
                        46.740572
                      ],
                      [
                        -114.649388,
                        46.73289
                      ],
                      [
                        -114.632954,
                        46.715495
                      ],
                      [
                        -114.626695,
                        46.712889
                      ],
                      [
                        -114.620859,
                        46.707415
                      ],
                      [
                        -114.623198,
                        46.691511
                      ],
                      [
                        -114.631898,
                        46.68397
                      ],
                      [
                        -114.641745,
                        46.679286
                      ],
                      [
                        -114.642713,
                        46.673145
                      ],
                      [
                        -114.635713,
                        46.659375
                      ],
                      [
                        -114.621483,
                        46.658143
                      ],
                      [
                        -114.614716,
                        46.655256
                      ],
                      [
                        -114.611676,
                        46.647704
                      ],
                      [
                        -114.616354,
                        46.643646
                      ],
                      [
                        -114.615036,
                        46.639733
                      ],
                      [
                        -114.593292,
                        46.632848
                      ],
                      [
                        -114.583385,
                        46.633227
                      ],
                      [
                        -114.561582,
                        46.642043
                      ],
                      [
                        -114.547321,
                        46.644485
                      ],
                      [
                        -114.498007,
                        46.637655
                      ],
                      [
                        -114.486218,
                        46.632829
                      ],
                      [
                        -114.466902,
                        46.631695
                      ],
                      [
                        -114.45425,
                        46.640974
                      ],
                      [
                        -114.453239,
                        46.649266
                      ],
                      [
                        -114.424424,
                        46.660648
                      ],
                      [
                        -114.410907,
                        46.657466
                      ],
                      [
                        -114.403383,
                        46.659633
                      ],
                      [
                        -114.394514,
                        46.664846
                      ],
                      [
                        -114.360709,
                        46.669059
                      ],
                      [
                        -114.332887,
                        46.660756
                      ],
                      [
                        -114.32456,
                        46.653579
                      ],
                      [
                        -114.320665,
                        46.646963
                      ],
                      [
                        -114.322912,
                        46.642938
                      ],
                      [
                        -114.322519,
                        46.611066
                      ],
                      [
                        -114.333931,
                        46.592162
                      ],
                      [
                        -114.334992,
                        46.588154
                      ],
                      [
                        -114.333931,
                        46.582732
                      ],
                      [
                        -114.331338,
                        46.577781
                      ],
                      [
                        -114.33175,
                        46.571914
                      ],
                      [
                        -114.339533,
                        46.564039
                      ],
                      [
                        -114.34534,
                        46.548444
                      ],
                      [
                        -114.348733,
                        46.533792
                      ],
                      [
                        -114.349208,
                        46.529514
                      ],
                      [
                        -114.342072,
                        46.519679
                      ],
                      [
                        -114.351655,
                        46.508119
                      ],
                      [
                        -114.35874,
                        46.505306
                      ],
                      [
                        -114.375348,
                        46.501855
                      ],
                      [
                        -114.385871,
                        46.50437
                      ],
                      [
                        -114.395204,
                        46.503148
                      ],
                      [
                        -114.400257,
                        46.502143
                      ],
                      [
                        -114.403019,
                        46.498675
                      ],
                      [
                        -114.400068,
                        46.47718
                      ],
                      [
                        -114.394447,
                        46.469549
                      ],
                      [
                        -114.383051,
                        46.466402
                      ],
                      [
                        -114.379338,
                        46.460166
                      ],
                      [
                        -114.376413,
                        46.442983
                      ],
                      [
                        -114.384756,
                        46.411784
                      ],
                      [
                        -114.408974,
                        46.400438
                      ],
                      [
                        -114.422458,
                        46.387097
                      ],
                      [
                        -114.411592,
                        46.366688
                      ],
                      [
                        -114.410682,
                        46.360673
                      ],
                      [
                        -114.413758,
                        46.335945
                      ],
                      [
                        -114.431708,
                        46.310744
                      ],
                      [
                        -114.433478,
                        46.305502
                      ],
                      [
                        -114.425587,
                        46.287899
                      ],
                      [
                        -114.427309,
                        46.283624
                      ],
                      [
                        -114.43544,
                        46.27661
                      ],
                      [
                        -114.441326,
                        46.2738
                      ],
                      [
                        -114.453257,
                        46.270939
                      ],
                      [
                        -114.465024,
                        46.273127
                      ],
                      [
                        -114.470479,
                        46.26732
                      ],
                      [
                        -114.468254,
                        46.248796
                      ],
                      [
                        -114.451912,
                        46.241253
                      ],
                      [
                        -114.449819,
                        46.237119
                      ],
                      [
                        -114.445497,
                        46.220227
                      ],
                      [
                        -114.443215,
                        46.202943
                      ],
                      [
                        -114.445928,
                        46.173933
                      ],
                      [
                        -114.457549,
                        46.170231
                      ],
                      [
                        -114.472643,
                        46.162202
                      ],
                      [
                        -114.478333,
                        46.160876
                      ],
                      [
                        -114.489254,
                        46.167684
                      ],
                      [
                        -114.514706,
                        46.167726
                      ],
                      [
                        -114.527096,
                        46.146218
                      ],
                      [
                        -114.5213,
                        46.125287
                      ],
                      [
                        -114.488303,
                        46.113106
                      ],
                      [
                        -114.474415,
                        46.112515
                      ],
                      [
                        -114.460049,
                        46.097104
                      ],
                      [
                        -114.461864,
                        46.078571
                      ],
                      [
                        -114.468529,
                        46.062484
                      ],
                      [
                        -114.492153,
                        46.04729
                      ],
                      [
                        -114.494683,
                        46.042546
                      ],
                      [
                        -114.493418,
                        46.03717
                      ],
                      [
                        -114.490572,
                        46.032427
                      ],
                      [
                        -114.485793,
                        46.030022
                      ],
                      [
                        -114.480241,
                        46.030325
                      ],
                      [
                        -114.473811,
                        46.016614
                      ],
                      [
                        -114.477922,
                        46.009025
                      ],
                      [
                        -114.47729,
                        46.000802
                      ],
                      [
                        -114.470965,
                        45.995742
                      ],
                      [
                        -114.441185,
                        45.988453
                      ],
                      [
                        -114.425843,
                        45.984984
                      ],
                      [
                        -114.419899,
                        45.981106
                      ],
                      [
                        -114.411892,
                        45.977883
                      ],
                      [
                        -114.409353,
                        45.97141
                      ],
                      [
                        -114.403712,
                        45.967049
                      ],
                      [
                        -114.402261,
                        45.961489
                      ],
                      [
                        -114.404708,
                        45.9559
                      ],
                      [
                        -114.411933,
                        45.952358
                      ],
                      [
                        -114.415977,
                        45.947891
                      ],
                      [
                        -114.423681,
                        45.9441
                      ],
                      [
                        -114.427717,
                        45.939625
                      ],
                      [
                        -114.431328,
                        45.938023
                      ],
                      [
                        -114.431159,
                        45.935737
                      ],
                      [
                        -114.413168,
                        45.911479
                      ],
                      [
                        -114.404314,
                        45.903497
                      ],
                      [
                        -114.395059,
                        45.901458
                      ],
                      [
                        -114.387166,
                        45.889164
                      ],
                      [
                        -114.388243,
                        45.88234
                      ],
                      [
                        -114.409477,
                        45.85164
                      ],
                      [
                        -114.422963,
                        45.855381
                      ],
                      [
                        -114.44868,
                        45.858891
                      ],
                      [
                        -114.455532,
                        45.855012
                      ],
                      [
                        -114.470296,
                        45.851343
                      ],
                      [
                        -114.498809,
                        45.850676
                      ],
                      [
                        -114.509303,
                        45.845531
                      ],
                      [
                        -114.514596,
                        45.840785
                      ],
                      [
                        -114.517143,
                        45.835993
                      ],
                      [
                        -114.51704,
                        45.833148
                      ],
                      [
                        -114.512973,
                        45.828825
                      ],
                      [
                        -114.544692,
                        45.791447
                      ],
                      [
                        -114.555487,
                        45.786249
                      ],
                      [
                        -114.562509,
                        45.779927
                      ],
                      [
                        -114.566172,
                        45.773864
                      ],
                      [
                        -114.535634,
                        45.739095
                      ],
                      [
                        -114.504869,
                        45.722176
                      ],
                      [
                        -114.497553,
                        45.710677
                      ],
                      [
                        -114.495421,
                        45.703321
                      ],
                      [
                        -114.499637,
                        45.669035
                      ],
                      [
                        -114.507645,
                        45.658949
                      ],
                      [
                        -114.522142,
                        45.64934
                      ],
                      [
                        -114.529678,
                        45.65232
                      ],
                      [
                        -114.53577,
                        45.650613
                      ],
                      [
                        -114.561046,
                        45.639906
                      ],
                      [
                        -114.563652,
                        45.637412
                      ],
                      [
                        -114.563305,
                        45.631612
                      ],
                      [
                        -114.553937,
                        45.619299
                      ],
                      [
                        -114.544905,
                        45.616673
                      ],
                      [
                        -114.538132,
                        45.606834
                      ],
                      [
                        -114.553999,
                        45.591279
                      ],
                      [
                        -114.558253,
                        45.585104
                      ],
                      [
                        -114.559038,
                        45.565706
                      ],
                      [
                        -114.549508,
                        45.56059
                      ],
                      [
                        -114.526075,
                        45.570771
                      ],
                      [
                        -114.517761,
                        45.568129
                      ],
                      [
                        -114.506341,
                        45.559216
                      ],
                      [
                        -114.498176,
                        45.555473
                      ],
                      [
                        -114.473759,
                        45.563278
                      ],
                      [
                        -114.460542,
                        45.561283
                      ],
                      [
                        -114.456764,
                        45.543983
                      ],
                      [
                        -114.450863,
                        45.54253
                      ],
                      [
                        -114.438991,
                        45.536076
                      ],
                      [
                        -114.415804,
                        45.509753
                      ],
                      [
                        -114.388618,
                        45.502903
                      ],
                      [
                        -114.36852,
                        45.492716
                      ],
                      [
                        -114.36562,
                        45.490416
                      ],
                      [
                        -114.360719,
                        45.474116
                      ],
                      [
                        -114.345019,
                        45.459916
                      ],
                      [
                        -114.333218,
                        45.459316
                      ],
                      [
                        -114.279217,
                        45.480616
                      ],
                      [
                        -114.270717,
                        45.486116
                      ],
                      [
                        -114.261616,
                        45.495816
                      ],
                      [
                        -114.247824,
                        45.524283
                      ],
                      [
                        -114.248183,
                        45.533226
                      ],
                      [
                        -114.251836,
                        45.537812
                      ],
                      [
                        -114.248121,
                        45.545877
                      ],
                      [
                        -114.227942,
                        45.546423
                      ],
                      [
                        -114.203665,
                        45.53557
                      ],
                      [
                        -114.192802,
                        45.536596
                      ],
                      [
                        -114.18647,
                        45.545539
                      ],
                      [
                        -114.180043,
                        45.551432
                      ],
                      [
                        -114.154837,
                        45.552916
                      ],
                      [
                        -114.135249,
                        45.557465
                      ],
                      [
                        -114.129099,
                        45.565491
                      ],
                      [
                        -114.128601,
                        45.568996
                      ],
                      [
                        -114.132359,
                        45.572531
                      ],
                      [
                        -114.131469,
                        45.574444
                      ],
                      [
                        -114.122322,
                        45.58426
                      ],
                      [
                        -114.100308,
                        45.586354
                      ],
                      [
                        -114.086584,
                        45.59118
                      ],
                      [
                        -114.0821,
                        45.596958
                      ],
                      [
                        -114.083149,
                        45.603996
                      ],
                      [
                        -114.08179,
                        45.611329
                      ],
                      [
                        -114.067619,
                        45.627706
                      ],
                      [
                        -114.033456,
                        45.648629
                      ],
                      [
                        -114.018731,
                        45.648616
                      ],
                      [
                        -114.014973,
                        45.654008
                      ],
                      [
                        -114.013786,
                        45.658238
                      ],
                      [
                        -114.02007,
                        45.670332
                      ],
                      [
                        -114.020533,
                        45.681223
                      ],
                      [
                        -114.019315,
                        45.692937
                      ],
                      [
                        -114.015633,
                        45.696127
                      ],
                      [
                        -113.986656,
                        45.704564
                      ],
                      [
                        -113.971565,
                        45.700636
                      ],
                      [
                        -113.93422,
                        45.682232
                      ],
                      [
                        -113.930403,
                        45.671878
                      ],
                      [
                        -113.919752,
                        45.658536
                      ],
                      [
                        -113.903582,
                        45.651165
                      ],
                      [
                        -113.900588,
                        45.648259
                      ],
                      [
                        -113.898883,
                        45.644167
                      ],
                      [
                        -113.902539,
                        45.636945
                      ],
                      [
                        -113.904691,
                        45.622007
                      ],
                      [
                        -113.886006,
                        45.61702
                      ],
                      [
                        -113.861404,
                        45.62366
                      ],
                      [
                        -113.823068,
                        45.612486
                      ],
                      [
                        -113.806729,
                        45.602146
                      ],
                      [
                        -113.802955,
                        45.592631
                      ],
                      [
                        -113.803261,
                        45.584193
                      ],
                      [
                        -113.804796,
                        45.580358
                      ],
                      [
                        -113.819868,
                        45.566326
                      ],
                      [
                        -113.834555,
                        45.520729
                      ],
                      [
                        -113.809144,
                        45.519908
                      ],
                      [
                        -113.802849,
                        45.523159
                      ],
                      [
                        -113.796579,
                        45.523462
                      ],
                      [
                        -113.778361,
                        45.523415
                      ],
                      [
                        -113.766022,
                        45.520621
                      ],
                      [
                        -113.759986,
                        45.480735
                      ],
                      [
                        -113.78416,
                        45.454946
                      ],
                      [
                        -113.783272,
                        45.451839
                      ],
                      [
                        -113.764591,
                        45.431403
                      ],
                      [
                        -113.763368,
                        45.427732
                      ],
                      [
                        -113.768058,
                        45.418147
                      ],
                      [
                        -113.765203,
                        45.410601
                      ],
                      [
                        -113.760924,
                        45.406501
                      ],
                      [
                        -113.750546,
                        45.40272
                      ],
                      [
                        -113.734402,
                        45.392353
                      ],
                      [
                        -113.733092,
                        45.390173
                      ],
                      [
                        -113.73239,
                        45.385058
                      ],
                      [
                        -113.73553,
                        45.364738
                      ],
                      [
                        -113.7402,
                        45.34559
                      ],
                      [
                        -113.738729,
                        45.329741
                      ],
                      [
                        -113.735601,
                        45.325265
                      ],
                      [
                        -113.689359,
                        45.28355
                      ],
                      [
                        -113.688077,
                        45.276407
                      ],
                      [
                        -113.691557,
                        45.270912
                      ],
                      [
                        -113.692039,
                        45.265191
                      ],
                      [
                        -113.684946,
                        45.253706
                      ],
                      [
                        -113.678749,
                        45.24927
                      ],
                      [
                        -113.674409,
                        45.249411
                      ],
                      [
                        -113.665633,
                        45.246265
                      ],
                      [
                        -113.657027,
                        45.241436
                      ],
                      [
                        -113.650064,
                        45.23471
                      ],
                      [
                        -113.647399,
                        45.228282
                      ],
                      [
                        -113.636889,
                        45.212983
                      ],
                      [
                        -113.599506,
                        45.191114
                      ],
                      [
                        -113.589891,
                        45.176986
                      ],
                      [
                        -113.594632,
                        45.166034
                      ],
                      [
                        -113.57467,
                        45.128411
                      ],
                      [
                        -113.554744,
                        45.112901
                      ],
                      [
                        -113.546488,
                        45.112285
                      ],
                      [
                        -113.538037,
                        45.11503
                      ],
                      [
                        -113.513342,
                        45.115225
                      ],
                      [
                        -113.506638,
                        45.107288
                      ],
                      [
                        -113.510819,
                        45.099902
                      ],
                      [
                        -113.520134,
                        45.093033
                      ],
                      [
                        -113.485278,
                        45.063519
                      ],
                      [
                        -113.47377,
                        45.0617
                      ],
                      [
                        -113.465073,
                        45.062755
                      ],
                      [
                        -113.460578,
                        45.064879
                      ],
                      [
                        -113.45197,
                        45.059247
                      ],
                      [
                        -113.44912,
                        45.046098
                      ],
                      [
                        -113.449909,
                        45.035167
                      ],
                      [
                        -113.437726,
                        45.006967
                      ],
                      [
                        -113.446884,
                        44.998545
                      ],
                      [
                        -113.447013,
                        44.984637
                      ],
                      [
                        -113.444862,
                        44.976141
                      ],
                      [
                        -113.443782,
                        44.95989
                      ],
                      [
                        -113.448958,
                        44.953544
                      ],
                      [
                        -113.467467,
                        44.948061
                      ],
                      [
                        -113.474781,
                        44.948795
                      ],
                      [
                        -113.480836,
                        44.95031
                      ],
                      [
                        -113.494446,
                        44.948597
                      ],
                      [
                        -113.498745,
                        44.942314
                      ],
                      [
                        -113.491121,
                        44.927548
                      ],
                      [
                        -113.474573,
                        44.910846
                      ],
                      [
                        -113.455071,
                        44.865424
                      ],
                      [
                        -113.422376,
                        44.842595
                      ],
                      [
                        -113.383984,
                        44.8374
                      ],
                      [
                        -113.377153,
                        44.834858
                      ],
                      [
                        -113.356062,
                        44.819798
                      ],
                      [
                        -113.3461,
                        44.800611
                      ],
                      [
                        -113.346692,
                        44.798898
                      ],
                      [
                        -113.354763,
                        44.795468
                      ],
                      [
                        -113.354034,
                        44.791745
                      ],
                      [
                        -113.341704,
                        44.784853
                      ],
                      [
                        -113.301508,
                        44.798985
                      ],
                      [
                        -113.29683,
                        44.803358
                      ],
                      [
                        -113.278382,
                        44.812706
                      ],
                      [
                        -113.247166,
                        44.82295
                      ],
                      [
                        -113.238729,
                        44.814144
                      ],
                      [
                        -113.209624,
                        44.80907
                      ],
                      [
                        -113.19436,
                        44.802151
                      ],
                      [
                        -113.183395,
                        44.793565
                      ],
                      [
                        -113.179366,
                        44.787142
                      ],
                      [
                        -113.163806,
                        44.778921
                      ],
                      [
                        -113.158206,
                        44.780847
                      ],
                      [
                        -113.140618,
                        44.776698
                      ],
                      [
                        -113.131453,
                        44.772837
                      ],
                      [
                        -113.131387,
                        44.764738
                      ],
                      [
                        -113.137704,
                        44.760109
                      ],
                      [
                        -113.134824,
                        44.752763
                      ],
                      [
                        -113.116874,
                        44.738104
                      ],
                      [
                        -113.102138,
                        44.729027
                      ],
                      [
                        -113.101154,
                        44.708578
                      ],
                      [
                        -113.098064,
                        44.697477
                      ],
                      [
                        -113.081906,
                        44.691392
                      ],
                      [
                        -113.06776,
                        44.679474
                      ],
                      [
                        -113.067756,
                        44.672807
                      ],
                      [
                        -113.07042,
                        44.667844
                      ],
                      [
                        -113.068306,
                        44.656374
                      ],
                      [
                        -113.065589,
                        44.649371
                      ],
                      [
                        -113.051504,
                        44.63695
                      ],
                      [
                        -113.049349,
                        44.62938
                      ],
                      [
                        -113.053529,
                        44.621187
                      ],
                      [
                        -113.05677,
                        44.618657
                      ],
                      [
                        -113.065593,
                        44.617391
                      ],
                      [
                        -113.07376,
                        44.613928
                      ],
                      [
                        -113.083819,
                        44.60222
                      ],
                      [
                        -113.061071,
                        44.577329
                      ],
                      [
                        -113.042363,
                        44.565237
                      ],
                      [
                        -113.04282,
                        44.546757
                      ],
                      [
                        -113.032722,
                        44.537137
                      ],
                      [
                        -113.019777,
                        44.528505
                      ],
                      [
                        -113.018636,
                        44.520064
                      ],
                      [
                        -113.020917,
                        44.493827
                      ],
                      [
                        -113.003544,
                        44.450814
                      ],
                      [
                        -112.981682,
                        44.434279
                      ],
                      [
                        -112.951146,
                        44.416699
                      ],
                      [
                        -112.915602,
                        44.402699
                      ],
                      [
                        -112.886041,
                        44.395874
                      ],
                      [
                        -112.881769,
                        44.380315
                      ],
                      [
                        -112.855395,
                        44.359975
                      ],
                      [
                        -112.844859,
                        44.358221
                      ],
                      [
                        -112.820489,
                        44.370946
                      ],
                      [
                        -112.81324,
                        44.378103
                      ],
                      [
                        -112.812608,
                        44.392275
                      ],
                      [
                        -112.821896,
                        44.407436
                      ],
                      [
                        -112.836034,
                        44.422653
                      ],
                      [
                        -112.828191,
                        44.442472
                      ],
                      [
                        -112.797863,
                        44.466112
                      ],
                      [
                        -112.781294,
                        44.484888
                      ],
                      [
                        -112.749011,
                        44.491233
                      ],
                      [
                        -112.735084,
                        44.499159
                      ],
                      [
                        -112.71911,
                        44.504344
                      ],
                      [
                        -112.707815,
                        44.503023
                      ],
                      [
                        -112.671169,
                        44.491265
                      ],
                      [
                        -112.664485,
                        44.48645
                      ],
                      [
                        -112.660696,
                        44.485756
                      ],
                      [
                        -112.601863,
                        44.491015
                      ],
                      [
                        -112.584197,
                        44.481368
                      ],
                      [
                        -112.573513,
                        44.480983
                      ],
                      [
                        -112.550557,
                        44.484928
                      ],
                      [
                        -112.541989,
                        44.483971
                      ],
                      [
                        -112.518871,
                        44.475784
                      ],
                      [
                        -112.512036,
                        44.47042
                      ],
                      [
                        -112.511713,
                        44.466445
                      ],
                      [
                        -112.50031,
                        44.463051
                      ],
                      [
                        -112.473207,
                        44.480027
                      ],
                      [
                        -112.460347,
                        44.47571
                      ],
                      [
                        -112.435342,
                        44.462216
                      ],
                      [
                        -112.387389,
                        44.448058
                      ],
                      [
                        -112.368764,
                        44.467153
                      ],
                      [
                        -112.358926,
                        44.48628
                      ],
                      [
                        -112.3566,
                        44.493127
                      ],
                      [
                        -112.358917,
                        44.528847
                      ],
                      [
                        -112.35421,
                        44.535638
                      ],
                      [
                        -112.348794,
                        44.538691
                      ],
                      [
                        -112.319198,
                        44.53911
                      ],
                      [
                        -112.315008,
                        44.5419
                      ],
                      [
                        -112.315047,
                        44.550049
                      ],
                      [
                        -112.312899,
                        44.553536
                      ],
                      [
                        -112.307642,
                        44.557651
                      ],
                      [
                        -112.286187,
                        44.568472
                      ],
                      [
                        -112.258665,
                        44.569516
                      ],
                      [
                        -112.242785,
                        44.568091
                      ],
                      [
                        -112.230117,
                        44.562759
                      ],
                      [
                        -112.226841,
                        44.555239
                      ],
                      [
                        -112.229477,
                        44.549494
                      ],
                      [
                        -112.221698,
                        44.543519
                      ],
                      [
                        -112.183937,
                        44.533067
                      ],
                      [
                        -112.179703,
                        44.533021
                      ],
                      [
                        -112.164597,
                        44.541666
                      ],
                      [
                        -112.136454,
                        44.539911
                      ],
                      [
                        -112.129078,
                        44.5363
                      ],
                      [
                        -112.125101,
                        44.528527
                      ],
                      [
                        -112.106755,
                        44.520829
                      ],
                      [
                        -112.101564,
                        44.520847
                      ],
                      [
                        -112.096299,
                        44.523212
                      ],
                      [
                        -112.093304,
                        44.530002
                      ],
                      [
                        -112.069011,
                        44.537104
                      ],
                      [
                        -112.053434,
                        44.535089
                      ],
                      [
                        -112.036943,
                        44.530323
                      ],
                      [
                        -112.034133,
                        44.537716
                      ],
                      [
                        -112.035025,
                        44.542691
                      ],
                      [
                        -112.032707,
                        44.546642
                      ],
                      [
                        -112.01385,
                        44.542348
                      ],
                      [
                        -111.995231,
                        44.535444
                      ],
                      [
                        -111.980833,
                        44.536682
                      ],
                      [
                        -111.951522,
                        44.550062
                      ],
                      [
                        -111.947941,
                        44.556776
                      ],
                      [
                        -111.903566,
                        44.55723
                      ],
                      [
                        -111.887852,
                        44.563413
                      ],
                      [
                        -111.870504,
                        44.564033
                      ],
                      [
                        -111.849293,
                        44.539837
                      ],
                      [
                        -111.842542,
                        44.526069
                      ],
                      [
                        -111.821488,
                        44.509286
                      ],
                      [
                        -111.807914,
                        44.511716
                      ],
                      [
                        -111.806512,
                        44.516264
                      ],
                      [
                        -111.761904,
                        44.529841
                      ],
                      [
                        -111.758966,
                        44.533766
                      ],
                      [
                        -111.746401,
                        44.540766
                      ],
                      [
                        -111.737191,
                        44.54306
                      ],
                      [
                        -111.715474,
                        44.543543
                      ],
                      [
                        -111.709553,
                        44.550206
                      ],
                      [
                        -111.704218,
                        44.560205
                      ],
                      [
                        -111.681571,
                        44.559864
                      ],
                      [
                        -111.614405,
                        44.548991
                      ],
                      [
                        -111.601249,
                        44.55421
                      ],
                      [
                        -111.591768,
                        44.561502
                      ],
                      [
                        -111.585763,
                        44.562843
                      ],
                      [
                        -111.562814,
                        44.555209
                      ],
                      [
                        -111.556577,
                        44.554495
                      ],
                      [
                        -111.546637,
                        44.557099
                      ],
                      [
                        -111.524006,
                        44.548385
                      ],
                      [
                        -111.518095,
                        44.544177
                      ],
                      [
                        -111.500792,
                        44.540062
                      ],
                      [
                        -111.471682,
                        44.540824
                      ],
                      [
                        -111.467736,
                        44.544521
                      ],
                      [
                        -111.469185,
                        44.552044
                      ],
                      [
                        -111.492024,
                        44.56081
                      ],
                      [
                        -111.519126,
                        44.582916
                      ],
                      [
                        -111.524213,
                        44.595585
                      ],
                      [
                        -111.525764,
                        44.604883
                      ],
                      [
                        -111.521688,
                        44.613371
                      ],
                      [
                        -111.50494,
                        44.635746
                      ],
                      [
                        -111.473178,
                        44.665479
                      ],
                      [
                        -111.468833,
                        44.679335
                      ],
                      [
                        -111.47798,
                        44.682393
                      ],
                      [
                        -111.484898,
                        44.687578
                      ],
                      [
                        -111.490228,
                        44.700221
                      ],
                      [
                        -111.489339,
                        44.704946
                      ],
                      [
                        -111.486019,
                        44.707654
                      ],
                      [
                        -111.438793,
                        44.720546
                      ],
                      [
                        -111.429604,
                        44.720149
                      ],
                      [
                        -111.424214,
                        44.714024
                      ],
                      [
                        -111.414271,
                        44.710741
                      ],
                      [
                        -111.398575,
                        44.723343
                      ],
                      [
                        -111.394459,
                        44.744578
                      ],
                      [
                        -111.397805,
                        44.746738
                      ],
                      [
                        -111.393854,
                        44.752549
                      ],
                      [
                        -111.385005,
                        44.755128
                      ],
                      [
                        -111.37476,
                        44.750295
                      ],
                      [
                        -111.36627,
                        44.742234
                      ],
                      [
                        -111.366723,
                        44.738361
                      ],
                      [
                        -111.355768,
                        44.727602
                      ],
                      [
                        -111.348184,
                        44.725459
                      ],
                      [
                        -111.341351,
                        44.7293
                      ],
                      [
                        -111.323669,
                        44.724474
                      ],
                      [
                        -111.29626,
                        44.702271
                      ],
                      [
                        -111.26875,
                        44.668279
                      ],
                      [
                        -111.276956,
                        44.655626
                      ],
                      [
                        -111.262839,
                        44.649658
                      ],
                      [
                        -111.25268,
                        44.651092
                      ],
                      [
                        -111.224161,
                        44.623402
                      ],
                      [
                        -111.231227,
                        44.606915
                      ],
                      [
                        -111.23018,
                        44.587025
                      ],
                      [
                        -111.225208,
                        44.581006
                      ],
                      [
                        -111.201459,
                        44.575696
                      ],
                      [
                        -111.189617,
                        44.571062
                      ],
                      [
                        -111.182551,
                        44.566874
                      ],
                      [
                        -111.175747,
                        44.552219
                      ],
                      [
                        -111.166892,
                        44.54722
                      ],
                      [
                        -111.15959,
                        44.546376
                      ],
                      [
                        -111.143557,
                        44.535732
                      ],
                      [
                        -111.139455,
                        44.517112
                      ],
                      [
                        -111.131379,
                        44.499925
                      ],
                      [
                        -111.122654,
                        44.493659
                      ],
                      [
                        -111.062729,
                        44.476073
                      ],
                      [
                        -111.048974,
                        44.474072
                      ],
                      [
                        -111.049194,
                        44.438058
                      ],
                      [
                        -111.049216,
                        44.435811
                      ],
                      [
                        -111.049148,
                        44.374925
                      ],
                      [
                        -111.049695,
                        44.353626
                      ],
                      [
                        -111.049119,
                        44.124923
                      ],
                      [
                        -111.048452,
                        44.114831
                      ],
                      [
                        -111.048633,
                        44.062903
                      ],
                      [
                        -111.048751,
                        44.060838
                      ],
                      [
                        -111.048751,
                        44.060403
                      ],
                      [
                        -111.049077,
                        44.020072
                      ],
                      [
                        -111.047349,
                        43.999921
                      ],
                      [
                        -111.046917,
                        43.974978
                      ],
                      [
                        -111.046515,
                        43.908376
                      ],
                      [
                        -111.046715,
                        43.815832
                      ],
                      [
                        -111.04634,
                        43.726957
                      ],
                      [
                        -111.046435,
                        43.726545
                      ],
                      [
                        -111.046421,
                        43.722059
                      ],
                      [
                        -111.04611,
                        43.687848
                      ],
                      [
                        -111.046051,
                        43.685812
                      ],
                      [
                        -111.046118,
                        43.684902
                      ],
                      [
                        -111.04588,
                        43.681033
                      ],
                      [
                        -111.045706,
                        43.659112
                      ],
                      [
                        -111.044617,
                        43.31572
                      ],
                      [
                        -111.044229,
                        43.195579
                      ],
                      [
                        -111.044168,
                        43.189244
                      ],
                      [
                        -111.044232,
                        43.18444
                      ],
                      [
                        -111.044266,
                        43.177236
                      ],
                      [
                        -111.044235,
                        43.177121
                      ],
                      [
                        -111.044143,
                        43.072364
                      ],
                      [
                        -111.044162,
                        43.068222
                      ],
                      [
                        -111.04415,
                        43.066172
                      ],
                      [
                        -111.044117,
                        43.060309
                      ],
                      [
                        -111.044086,
                        43.054819
                      ],
                      [
                        -111.044063,
                        43.046302
                      ],
                      [
                        -111.044058,
                        43.04464
                      ],
                      [
                        -111.043997,
                        43.041415
                      ],
                      [
                        -111.044094,
                        43.02927
                      ],
                      [
                        -111.044033,
                        43.026411
                      ],
                      [
                        -111.044034,
                        43.024844
                      ],
                      [
                        -111.044034,
                        43.024581
                      ],
                      [
                        -111.044206,
                        43.022614
                      ],
                      [
                        -111.044129,
                        43.018702
                      ],
                      [
                        -111.043924,
                        42.975063
                      ],
                      [
                        -111.043957,
                        42.969482
                      ],
                      [
                        -111.043959,
                        42.96445
                      ],
                      [
                        -111.044135,
                        42.874924
                      ],
                      [
                        -111.043564,
                        42.722624
                      ],
                      [
                        -111.046017,
                        42.582723
                      ],
                      [
                        -111.046801,
                        42.504946
                      ],
                      [
                        -111.04708,
                        42.34942
                      ],
                      [
                        -111.047074,
                        42.280787
                      ],
                      [
                        -111.047097,
                        42.194773
                      ],
                      [
                        -111.047058,
                        42.182672
                      ],
                      [
                        -111.047107,
                        42.148971
                      ],
                      [
                        -111.047109,
                        42.142497
                      ],
                      [
                        -111.046689,
                        42.001567
                      ]
                    ]
                  ],
                "type": "Polygon"
            }
        }
    ]
}

export default idahoJson;

