import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userReducer, { setUserLogOutState } from "../../store/slices/userReducer";
import { getAuth, signOut } from "firebase/auth";
import { firestore } from '../../auth/firebase';
import app from '../../auth/firebase';
import faq from "../../data/faq";
import { getDocs, collection, query, where } from "firebase/firestore";
import ItineraryCards from "./ItineraryCards";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";



function Account(props) {
    const auth = getAuth(app);
    const [view, setView] = useState("itineraries");
    const [itins, setItins] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const dispatch = useDispatch();
    const userID = useSelector((state) => state.user.userID);
    const userEmail = useSelector((state) => state.user.userEmail);

    const jsonObjToArray = (arrayOfObjects) => {
        let tmpArray = [];
        arrayOfObjects.forEach((obj) => {
            let k = Object.keys(obj);
            let v = Object.values(obj);
            let tmp = [];
            k.map((itm, i) => {
                tmp[itm] = v[i];
            })
            tmpArray.push(tmp);
        })
        return tmpArray;
    }

    useEffect(() => {
        if (firstLoad === true) {
            const fetchData = async () => {
                const itinsRef = collection(firestore, "itineraries");
                const q = query(itinsRef, where("user_id", "==", userID));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    let data = doc.data();
                    let markers = jsonObjToArray(JSON.parse(data.markers));
                    let tmpArray = {
                        docId: doc.id,
                        name: data.name,
                        directions: JSON.parse(data.directions),
                        markers: markers,
                        route: JSON.parse(data.route),
                        startingPoint: JSON.parse(data.starting_point),
                        userId: data.user_id,
                        userName: data.user_name
                    };

                    setItins(itins => [...itins, tmpArray]);
                });
            }
            fetchData().catch(console.error);
            setFirstLoad(false);
        }
    }, []);

    const toggleDashboard = () => {
        props.toggleDashboard();
    }

    const clearFilters = () => {
        props.clearFilters();
    }

    const toggleView = (viewFrag) => {
        setView(viewFrag);
    };

    const handleSignOut = () => {
        signOut(auth)
            .then(() => {
                dispatch(setUserLogOutState());
            })
            .catch((error) => alert(error.message));
    };

    const ViewFragments = (props) => {

        useEffect(() => {
            if (props.view == "help") {

                //identify user
                if (userEmail && userEmail.length > 0) {
                    document.tidioIdentify = {
                        email: userEmail, // visitor email
                    };
                }

                // Create script tag
                const script = document.createElement("script");
                script.setAttribute("id", "tidioChat");
                script.src = "//code.tidio.co/cykxpa6kz1fph82jqonyuyup29ah8czq.js";
                document.body.appendChild(script);

                // clean ups
                return () => {
                    document.body.removeChild(script);
                };
            } else {
                if (document.getElementById('tidio-chat')) {
                    document.getElementById('tidio-chat-code').remove();
                    document.getElementById('tidio-chat').remove();
                }
            }
        }, [props.view]);

        /*
        if (props.view == "info") {
            return (
                <div className="account-info">
                    <h3>Account Information</h3>
                    <a onClick={handleSignOut}>Sign Out</a>
                </div>
            );
        }
        */

        if (props.view == "help") {
            return (
                <div className="account-help" id="account-help">
                    <h3>Frequently Asked Questions</h3>
                    {faq && faq.length > 0 && (
                        <Accordion>
                            {faq.map((qa, i) => (
                                <AccordionItem key={i}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>{qa.question}</AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        {qa.answer && qa.answer.map((answer, x) => (
                                            <p key={x} dangerouslySetInnerHTML={{ __html: answer }} />
                                        ))}
                                    </AccordionItemPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    )}
                    <div className="more-info">
                        <h3>Have more questions about traveling to Idaho?</h3>
                        <p>Continue reading <a href="https://visitidaho.org/travel-tips/planning-an-idaho-vacation-check-out-the-most-commonly-asked-questions/" target="_blank">more frequently asked questions</a> to help you plan your adventure.</p>
                    </div>
                </div>
            );
        }

        return (
            <ItineraryCards itins={itins} clearFilters={clearFilters} toggleDashboard={toggleDashboard} />
        );
    };
    return (
        <>
            <div className="account-links">
                {/*
                <a
                    onClick={() => toggleView("info")}
                    className={
                        view === "info"
                            ? "btn btn-small btn-green"
                            : "btn btn-small btn-white-shadow"
                    }
                    title="Account information"
                    aria-label="Account information"
                >
                    Account Info
                </a>
                */}
                <a
                    onClick={() => toggleView("itineraries")}
                    className={
                        view === "itineraries"
                            ? "btn btn-small btn-green"
                            : "btn btn-small btn-white-shadow"
                    }
                    title="Itineraries"
                    aria-label="Itineraries"
                >
                    Itineraries
                </a>
                <a
                    onClick={() => toggleView("help")}
                    className={
                        view === "help"
                            ? "btn btn-small btn-green"
                            : "btn btn-small btn-white-shadow"
                    }
                    title="Help"
                    aria-label="Help"
                >
                    Help
                </a>
                <a
                    onClick={() => handleSignOut()}
                    className="btn btn-small btn-white-shadow"
                    title="Sign Out"
                    aria-label="Sign Out"
                >
                    Sign Out
                </a>
            </div>
            <div className="account-content">
                <ViewFragments view={view} />
            </div>
        </>
    );
}

export default Account;
