import React from "react";
import Main from "./components/Main";
import {
  Routes,
  Route
} from "react-router-dom";
import { DisplayItinerary } from "./components/Itinerary/DisplayItinerary";
import './listeners/postMessage';

import './styles/main.scss';

import { QueryClient, QueryClientProvider } from 'react-query'
import PageNotFound from "./components/404/PageNotFound";
const queryClient = new QueryClient()

const App = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/itinerary/:id" element={<DisplayItinerary />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </QueryClientProvider>
  );
}
export default App;