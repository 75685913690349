const LowerPayetteRiver = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Lower Payette River Heritage Byway",
				description: "Winding through the charming towns of Payette, Emmett and Horseshoe Bend, the Lower Payette River Heritage Byway is 52 miles of rural views on Highway 52. While you're exploring, take some time to soak up the hometown hospitality this area has to offer. As you meander along the Lower Payette River, stop off for local cuisine, ziplining, historical museums, picturesque parks and more.",
				link: "https://visitidaho.org/things-to-do/road-trips/lower-payette-river-heritage-byway/",
				route: [
					"Payette", 
					"Horseshoe Bend", 
					"Emmet",
					"Sweet"
				],
				shape: "Line"
			},
			geometry: {
				type: "LineString",
				coordinates: [
					[-116.92328, 44.07266, 0],
					[-116.92269, 44.07268, 0],
					[-116.92188, 44.07269, 0],
					[-116.92176, 44.07268, 0],
					[-116.92172, 44.07268, 0],
					[-116.92164, 44.07266, 0],
					[-116.92153, 44.07262, 0],
					[-116.92146, 44.07259, 0],
					[-116.92135, 44.07253, 0],
					[-116.92126, 44.07247, 0],
					[-116.92121, 44.07243, 0],
					[-116.92116, 44.07238, 0],
					[-116.92108, 44.07227, 0],
					[-116.92103, 44.07215, 0],
					[-116.92099, 44.07197, 0],
					[-116.921, 44.07039, 0],
					[-116.92101, 44.07014, 0],
					[-116.92101, 44.07002, 0],
					[-116.92099, 44.06979, 0],
					[-116.92099, 44.06974, 0],
					[-116.92098, 44.06966, 0],
					[-116.92094, 44.06945, 0],
					[-116.9209, 44.06928, 0],
					[-116.92085, 44.06911, 0],
					[-116.92078, 44.06894, 0],
					[-116.92066, 44.06869, 0],
					[-116.91963, 44.06705, 0],
					[-116.91951, 44.06688, 0],
					[-116.91853, 44.06534, 0],
					[-116.91832, 44.06503, 0],
					[-116.91799, 44.06451, 0],
					[-116.91765, 44.06401, 0],
					[-116.91754, 44.06389, 0],
					[-116.91746, 44.06379, 0],
					[-116.91724, 44.06354, 0],
					[-116.91694, 44.06325, 0],
					[-116.9165, 44.06289, 0],
					[-116.91606, 44.06259, 0],
					[-116.91583, 44.06245, 0],
					[-116.91556, 44.0623, 0],
					[-116.91538, 44.06221, 0],
					[-116.91502, 44.06205, 0],
					[-116.91456, 44.06187, 0],
					[-116.91426, 44.06177, 0],
					[-116.91416, 44.06173, 0],
					[-116.91406, 44.0617, 0],
					[-116.90415, 44.05835, 0],
					[-116.90414, 44.05835, 0],
					[-116.9011, 44.05733, 0],
					[-116.90046, 44.05709, 0],
					[-116.89973, 44.05679, 0],
					[-116.89896, 44.05645, 0],
					[-116.89128, 44.05287, 0],
					[-116.89105, 44.05277, 0],
					[-116.89014, 44.05234, 0],
					[-116.88969, 44.05214, 0],
					[-116.88937, 44.05198, 0],
					[-116.88885, 44.05175, 0],
					[-116.88697, 44.05087, 0],
					[-116.88673, 44.05074, 0],
					[-116.88671, 44.05072, 0],
					[-116.88633, 44.05052, 0],
					[-116.88579, 44.05018, 0],
					[-116.88507, 44.04965, 0],
					[-116.88495, 44.04955, 0],
					[-116.88449, 44.04913, 0],
					[-116.88396, 44.0486, 0],
					[-116.88386, 44.04851, 0],
					[-116.88372, 44.04837, 0],
					[-116.8832, 44.04793, 0],
					[-116.8827, 44.04756, 0],
					[-116.87856, 44.04467, 0],
					[-116.87812, 44.04438, 0],
					[-116.87798, 44.04428, 0],
					[-116.87751, 44.04398, 0],
					[-116.87719, 44.04379, 0],
					[-116.87684, 44.0436, 0],
					[-116.87636, 44.04337, 0],
					[-116.87627, 44.04332, 0],
					[-116.87567, 44.04306, 0],
					[-116.87529, 44.04291, 0],
					[-116.87457, 44.04266, 0],
					[-116.86763, 44.04055, 0],
					[-116.86755, 44.04052, 0],
					[-116.86745, 44.04047, 0],
					[-116.86679, 44.04026, 0],
					[-116.86628, 44.04012, 0],
					[-116.86598, 44.04005, 0],
					[-116.86572, 44.04, 0],
					[-116.86479, 44.03985, 0],
					[-116.86449, 44.03982, 0],
					[-116.86389, 44.03978, 0],
					[-116.86371, 44.0398, 0],
					[-116.86334, 44.03978, 0],
					[-116.86, 44.03972, 0],
					[-116.8599, 44.03971, 0],
					[-116.85966, 44.0397, 0],
					[-116.85898, 44.03963, 0],
					[-116.85883, 44.0396, 0],
					[-116.85858, 44.03957, 0],
					[-116.85823, 44.03951, 0],
					[-116.85777, 44.03941, 0],
					[-116.85722, 44.03926, 0],
					[-116.85682, 44.03913, 0],
					[-116.8566, 44.03905, 0],
					[-116.85592, 44.03878, 0],
					[-116.85548, 44.03856, 0],
					[-116.85383, 44.03769, 0],
					[-116.85367, 44.0376, 0],
					[-116.85356, 44.03753, 0],
					[-116.85342, 44.03745, 0],
					[-116.85317, 44.03729, 0],
					[-116.85267, 44.03693, 0],
					[-116.85247, 44.03676, 0],
					[-116.85146, 44.03576, 0],
					[-116.85137, 44.03566, 0],
					[-116.8495, 44.03371, 0],
					[-116.84935, 44.03357, 0],
					[-116.84656, 44.03071, 0],
					[-116.8462, 44.03037, 0],
					[-116.84593, 44.03015, 0],
					[-116.84535, 44.02965, 0],
					[-116.84498, 44.0293, 0],
					[-116.8416, 44.02582, 0],
					[-116.83873, 44.02291, 0],
					[-116.83825, 44.02247, 0],
					[-116.83811, 44.02236, 0],
					[-116.83801, 44.02227, 0],
					[-116.8372, 44.02166, 0],
					[-116.83651, 44.02121, 0],
					[-116.83607, 44.02094, 0],
					[-116.83561, 44.02068, 0],
					[-116.83548, 44.0206, 0],
					[-116.83473, 44.02019, 0],
					[-116.83409, 44.01988, 0],
					[-116.83321, 44.01949, 0],
					[-116.83277, 44.01932, 0],
					[-116.82681, 44.0169, 0],
					[-116.82646, 44.01677, 0],
					[-116.81256, 44.01115, 0],
					[-116.81236, 44.01106, 0],
					[-116.80526, 44.00819, 0],
					[-116.80474, 44.00799, 0],
					[-116.80443, 44.00786, 0],
					[-116.80377, 44.00756, 0],
					[-116.80329, 44.00732, 0],
					[-116.80299, 44.00715, 0],
					[-116.80227, 44.00669, 0],
					[-116.80191, 44.00642, 0],
					[-116.80137, 44.00597, 0],
					[-116.80113, 44.00575, 0],
					[-116.80102, 44.00564, 0],
					[-116.80092, 44.00553, 0],
					[-116.80081, 44.00542, 0],
					[-116.80061, 44.0052, 0],
					[-116.80042, 44.00497, 0],
					[-116.80015, 44.00461, 0],
					[-116.80006, 44.00448, 0],
					[-116.79999, 44.00435, 0],
					[-116.79989, 44.0042, 0],
					[-116.7995, 44.00347, 0],
					[-116.79939, 44.00323, 0],
					[-116.79783, 44.00003, 0],
					[-116.79776, 43.99987, 0],
					[-116.79716, 43.99864, 0],
					[-116.79689, 43.99811, 0],
					[-116.79461, 43.99339, 0],
					[-116.79424, 43.99265, 0],
					[-116.79391, 43.99213, 0],
					[-116.79356, 43.99171, 0],
					[-116.79308, 43.99125, 0],
					[-116.79282, 43.99104, 0],
					[-116.79226, 43.99065, 0],
					[-116.78924, 43.98862, 0],
					[-116.78921, 43.98859, 0],
					[-116.78218, 43.98383, 0],
					[-116.7814, 43.98326, 0],
					[-116.78084, 43.98283, 0],
					[-116.77999, 43.98214, 0],
					[-116.77903, 43.98127, 0],
					[-116.77846, 43.98072, 0],
					[-116.77839, 43.98064, 0],
					[-116.77799, 43.98023, 0],
					[-116.77769, 43.9799, 0],
					[-116.77731, 43.97944, 0],
					[-116.77685, 43.97884, 0],
					[-116.77683, 43.9788, 0],
					[-116.7767, 43.97862, 0],
					[-116.77465, 43.97557, 0],
					[-116.77438, 43.97513, 0],
					[-116.77421, 43.97482, 0],
					[-116.77388, 43.97417, 0],
					[-116.77367, 43.9737, 0],
					[-116.77347, 43.97322, 0],
					[-116.77343, 43.97314, 0],
					[-116.77329, 43.97272, 0],
					[-116.77328, 43.9727, 0],
					[-116.77305, 43.97199, 0],
					[-116.77293, 43.97157, 0],
					[-116.77278, 43.97099, 0],
					[-116.77265, 43.9704, 0],
					[-116.77255, 43.96987, 0],
					[-116.77246, 43.96926, 0],
					[-116.77244, 43.96906, 0],
					[-116.77237, 43.96853, 0],
					[-116.77236, 43.9685, 0],
					[-116.77231, 43.96778, 0],
					[-116.77229, 43.96721, 0],
					[-116.77235, 43.96126, 0],
					[-116.77236, 43.96094, 0],
					[-116.77245, 43.95265, 0],
					[-116.75982, 43.9527, 0],
					[-116.75932, 43.95271, 0],
					[-116.75255, 43.95272, 0],
					[-116.75249, 43.95273, 0],
					[-116.75151, 43.95273, 0],
					[-116.75125, 43.95272, 0],
					[-116.75078, 43.95268, 0],
					[-116.75037, 43.95262, 0],
					[-116.75016, 43.95258, 0],
					[-116.74981, 43.9525, 0],
					[-116.74931, 43.95235, 0],
					[-116.74878, 43.95214, 0],
					[-116.74832, 43.9519, 0],
					[-116.74805, 43.95174, 0],
					[-116.74775, 43.95154, 0],
					[-116.74756, 43.95143, 0],
					[-116.74395, 43.94911, 0],
					[-116.74343, 43.94879, 0],
					[-116.70631, 43.92504, 0],
					[-116.70612, 43.92493, 0],
					[-116.6807, 43.90864, 0],
					[-116.68016, 43.90824, 0],
					[-116.67953, 43.90775, 0],
					[-116.67892, 43.90723, 0],
					[-116.67422, 43.90278, 0],
					[-116.67399, 43.90258, 0],
					[-116.67348, 43.90218, 0],
					[-116.67319, 43.90198, 0],
					[-116.67262, 43.90162, 0],
					[-116.67232, 43.90144, 0],
					[-116.67158, 43.90109, 0],
					[-116.65906, 43.89537, 0],
					[-116.65863, 43.89516, 0],
					[-116.65786, 43.89475, 0],
					[-116.65706, 43.89428, 0],
					[-116.65622, 43.89374, 0],
					[-116.65591, 43.89353, 0],
					[-116.65548, 43.89322, 0],
					[-116.6454, 43.88561, 0],
					[-116.64429, 43.88475, 0],
					[-116.63218, 43.87559, 0],
					[-116.63165, 43.87521, 0],
					[-116.63117, 43.87488, 0],
					[-116.63087, 43.87469, 0],
					[-116.63068, 43.87458, 0],
					[-116.62987, 43.87414, 0],
					[-116.6294, 43.87391, 0],
					[-116.6286, 43.87357, 0],
					[-116.62809, 43.87338, 0],
					[-116.62797, 43.87333, 0],
					[-116.62741, 43.87314, 0],
					[-116.62657, 43.87289, 0],
					[-116.62619, 43.87279, 0],
					[-116.62571, 43.87268, 0],
					[-116.62489, 43.87253, 0],
					[-116.624, 43.8724, 0],
					[-116.62319, 43.87231, 0],
					[-116.6224, 43.87227, 0],
					[-116.61317, 43.87207, 0],
					[-116.60113, 43.87199, 0],
					[-116.60083, 43.87198, 0],
					[-116.595, 43.87195, 0],
					[-116.59462, 43.87194, 0],
					[-116.59408, 43.87188, 0],
					[-116.59392, 43.87185, 0],
					[-116.59356, 43.87175, 0],
					[-116.59337, 43.87169, 0],
					[-116.59296, 43.87152, 0],
					[-116.59261, 43.87133, 0],
					[-116.59245, 43.87123, 0],
					[-116.59179, 43.87074, 0],
					[-116.59125, 43.87039, 0],
					[-116.59122, 43.87036, 0],
					[-116.57875, 43.86288, 0],
					[-116.57791, 43.86246, 0],
					[-116.57769, 43.86236, 0],
					[-116.5771, 43.86212, 0],
					[-116.57676, 43.862, 0],
					[-116.57649, 43.86192, 0],
					[-116.57622, 43.86183, 0],
					[-116.57601, 43.86178, 0],
					[-116.57573, 43.8617, 0],
					[-116.575, 43.86154, 0],
					[-116.57448, 43.86144, 0],
					[-116.57405, 43.86138, 0],
					[-116.57359, 43.86133, 0],
					[-116.57311, 43.86129, 0],
					[-116.5729, 43.86128, 0],
					[-116.57279, 43.86127, 0],
					[-116.57257, 43.86127, 0],
					[-116.57232, 43.86126, 0],
					[-116.55934, 43.86125, 0],
					[-116.55894, 43.86122, 0],
					[-116.55813, 43.8612, 0],
					[-116.55357, 43.86121, 0],
					[-116.55228, 43.86124, 0],
					[-116.55216, 43.86125, 0],
					[-116.55177, 43.86126, 0],
					[-116.50644, 43.86124, 0],
					[-116.50555, 43.86126, 0],
					[-116.49999, 43.86123, 0],
					[-116.49998, 43.86179, 0],
					[-116.49999, 43.86195, 0],
					[-116.49999, 43.86335, 0],
					[-116.5, 43.86352, 0],
					[-116.50002, 43.86985, 0],
					[-116.50005, 43.87075, 0],
					[-116.50006, 43.87229, 0],
					[-116.50007, 43.8726, 0],
					[-116.50007, 43.87361, 0],
					[-116.50005, 43.87438, 0],
					[-116.50005, 43.87525, 0],
					[-116.50006, 43.87562, 0],
					[-116.50005, 43.87592, 0],
					[-116.50005, 43.87659, 0],
					[-116.50006, 43.87684, 0],
					[-116.50006, 43.87702, 0],
					[-116.50007, 43.8772, 0],
					[-116.50008, 43.87914, 0],
					[-116.5001, 43.87936, 0],
					[-116.50012, 43.88025, 0],
					[-116.50011, 43.8806, 0],
					[-116.50012, 43.88072, 0],
					[-116.50013, 43.8811, 0],
					[-116.50014, 43.88119, 0],
					[-116.50016, 43.88172, 0],
					[-116.50016, 43.8821, 0],
					[-116.50015, 43.88246, 0],
					[-116.50012, 43.88686, 0],
					[-116.50014, 43.88751, 0],
					[-116.50016, 43.88768, 0],
					[-116.50018, 43.88798, 0],
					[-116.50024, 43.8883, 0],
					[-116.50027, 43.88843, 0],
					[-116.50031, 43.88853, 0],
					[-116.50035, 43.88868, 0],
					[-116.5005, 43.88899, 0],
					[-116.50054, 43.88905, 0],
					[-116.50079, 43.88939, 0],
					[-116.502, 43.89075, 0],
					[-116.50205, 43.8908, 0],
					[-116.50219, 43.89098, 0],
					[-116.50229, 43.89113, 0],
					[-116.50237, 43.89128, 0],
					[-116.50243, 43.89145, 0],
					[-116.50245, 43.89149, 0],
					[-116.50247, 43.89159, 0],
					[-116.50248, 43.89168, 0],
					[-116.5025, 43.89178, 0],
					[-116.50247, 43.89529, 0],
					[-116.50246, 43.90736, 0],
					[-116.50245, 43.90753, 0],
					[-116.50222, 43.90775, 0],
					[-116.50209, 43.90784, 0],
					[-116.50202, 43.90788, 0],
					[-116.50194, 43.90791, 0],
					[-116.5018, 43.90795, 0],
					[-116.50171, 43.90797, 0],
					[-116.50138, 43.908, 0],
					[-116.49249, 43.90798, 0],
					[-116.48974, 43.90798, 0],
					[-116.48949, 43.90797, 0],
					[-116.46389, 43.90794, 0],
					[-116.46355, 43.90797, 0],
					[-116.46336, 43.908, 0],
					[-116.46327, 43.90803, 0],
					[-116.46318, 43.90805, 0],
					[-116.46296, 43.90813, 0],
					[-116.4627, 43.90827, 0],
					[-116.4625, 43.90842, 0],
					[-116.46238, 43.90854, 0],
					[-116.46228, 43.90866, 0],
					[-116.46206, 43.90896, 0],
					[-116.46204, 43.909, 0],
					[-116.46156, 43.9098, 0],
					[-116.46146, 43.90993, 0],
					[-116.46131, 43.91011, 0],
					[-116.46106, 43.91037, 0],
					[-116.45675, 43.91446, 0],
					[-116.45674, 43.91446, 0],
					[-116.45636, 43.91484, 0],
					[-116.45568, 43.91547, 0],
					[-116.45538, 43.91573, 0],
					[-116.45492, 43.9161, 0],
					[-116.4549, 43.91611, 0],
					[-116.45449, 43.9164, 0],
					[-116.45406, 43.91667, 0],
					[-116.45331, 43.91708, 0],
					[-116.4494, 43.91909, 0],
					[-116.44912, 43.91926, 0],
					[-116.44891, 43.91941, 0],
					[-116.44868, 43.91962, 0],
					[-116.44859, 43.91971, 0],
					[-116.44856, 43.91975, 0],
					[-116.44852, 43.91979, 0],
					[-116.44839, 43.91995, 0],
					[-116.44829, 43.92009, 0],
					[-116.44825, 43.92017, 0],
					[-116.44819, 43.92026, 0],
					[-116.44808, 43.92046, 0],
					[-116.4477, 43.92128, 0],
					[-116.4477, 43.92129, 0],
					[-116.44651, 43.92392, 0],
					[-116.4463, 43.9245, 0],
					[-116.44622, 43.92474, 0],
					[-116.44588, 43.92568, 0],
					[-116.44571, 43.9262, 0],
					[-116.4456, 43.92651, 0],
					[-116.44552, 43.9268, 0],
					[-116.44549, 43.92697, 0],
					[-116.44547, 43.92713, 0],
					[-116.44547, 43.92739, 0],
					[-116.44548, 43.92754, 0],
					[-116.44552, 43.92771, 0],
					[-116.44562, 43.92802, 0],
					[-116.44571, 43.92822, 0],
					[-116.44578, 43.92834, 0],
					[-116.44592, 43.92854, 0],
					[-116.44605, 43.92871, 0],
					[-116.44619, 43.92894, 0],
					[-116.44623, 43.92903, 0],
					[-116.44623, 43.92904, 0],
					[-116.44627, 43.92917, 0],
					[-116.44629, 43.92941, 0],
					[-116.44627, 43.92955, 0],
					[-116.44624, 43.92968, 0],
					[-116.44619, 43.92981, 0],
					[-116.44605, 43.93004, 0],
					[-116.44601, 43.93008, 0],
					[-116.44594, 43.93017, 0],
					[-116.44584, 43.93028, 0],
					[-116.44578, 43.93034, 0],
					[-116.44561, 43.93048, 0],
					[-116.44556, 43.9305, 0],
					[-116.4455, 43.93055, 0],
					[-116.44537, 43.93063, 0],
					[-116.44517, 43.93072, 0],
					[-116.44501, 43.9308, 0],
					[-116.44496, 43.93082, 0],
					[-116.44464, 43.93092, 0],
					[-116.44404, 43.93106, 0],
					[-116.44372, 43.93112, 0],
					[-116.44125, 43.93168, 0],
					[-116.44095, 43.93174, 0],
					[-116.44014, 43.93186, 0],
					[-116.43565, 43.93237, 0],
					[-116.43512, 43.93241, 0],
					[-116.43504, 43.9324, 0],
					[-116.43485, 43.9324, 0],
					[-116.43434, 43.93232, 0],
					[-116.43421, 43.93228, 0],
					[-116.43413, 43.93226, 0],
					[-116.43394, 43.93218, 0],
					[-116.43379, 43.93211, 0],
					[-116.43359, 43.93198, 0],
					[-116.4333, 43.93175, 0],
					[-116.43292, 43.93142, 0],
					[-116.43264, 43.93121, 0],
					[-116.43243, 43.93109, 0],
					[-116.43218, 43.93097, 0],
					[-116.43195, 43.93089, 0],
					[-116.43153, 43.93077, 0],
					[-116.42801, 43.92998, 0],
					[-116.42744, 43.92983, 0],
					[-116.4272, 43.92976, 0],
					[-116.42687, 43.92963, 0],
					[-116.4265, 43.92946, 0],
					[-116.4257, 43.92907, 0],
					[-116.42547, 43.92895, 0],
					[-116.4243, 43.92839, 0],
					[-116.42378, 43.92818, 0],
					[-116.42349, 43.92808, 0],
					[-116.42313, 43.92797, 0],
					[-116.42266, 43.92781, 0],
					[-116.41869, 43.92653, 0],
					[-116.41792, 43.92627, 0],
					[-116.41755, 43.92613, 0],
					[-116.41723, 43.92599, 0],
					[-116.41694, 43.92583, 0],
					[-116.41686, 43.92579, 0],
					[-116.41632, 43.92541, 0],
					[-116.41632, 43.9254, 0],
					[-116.41473, 43.92418, 0],
					[-116.41429, 43.92387, 0],
					[-116.41395, 43.92365, 0],
					[-116.41367, 43.9235, 0],
					[-116.41333, 43.92334, 0],
					[-116.41269, 43.92309, 0],
					[-116.41216, 43.9229, 0],
					[-116.4108, 43.92238, 0],
					[-116.40998, 43.92208, 0],
					[-116.4087, 43.92159, 0],
					[-116.40781, 43.92127, 0],
					[-116.40771, 43.92124, 0],
					[-116.40745, 43.92118, 0],
					[-116.40722, 43.92115, 0],
					[-116.40707, 43.92114, 0],
					[-116.40688, 43.92114, 0],
					[-116.40654, 43.92117, 0],
					[-116.40615, 43.92125, 0],
					[-116.40582, 43.92138, 0],
					[-116.40565, 43.92146, 0],
					[-116.40552, 43.92153, 0],
					[-116.40525, 43.92174, 0],
					[-116.40505, 43.92196, 0],
					[-116.40496, 43.92211, 0],
					[-116.40489, 43.92224, 0],
					[-116.40484, 43.92237, 0],
					[-116.40477, 43.92267, 0],
					[-116.40475, 43.92278, 0],
					[-116.4047, 43.92297, 0],
					[-116.40469, 43.92304, 0],
					[-116.40457, 43.92344, 0],
					[-116.40416, 43.92468, 0],
					[-116.40395, 43.92541, 0],
					[-116.4039, 43.92563, 0],
					[-116.40389, 43.92572, 0],
					[-116.40387, 43.92643, 0],
					[-116.40384, 43.92663, 0],
					[-116.40376, 43.92692, 0],
					[-116.4033, 43.92831, 0],
					[-116.40315, 43.92872, 0],
					[-116.40307, 43.92892, 0],
					[-116.40292, 43.92922, 0],
					[-116.40276, 43.92946, 0],
					[-116.40259, 43.92967, 0],
					[-116.40164, 43.93078, 0],
					[-116.40149, 43.93094, 0],
					[-116.40129, 43.93114, 0],
					[-116.40097, 43.93138, 0],
					[-116.40072, 43.93154, 0],
					[-116.4005, 43.93166, 0],
					[-116.40037, 43.93171, 0],
					[-116.40021, 43.93178, 0],
					[-116.39995, 43.93188, 0],
					[-116.39946, 43.93201, 0],
					[-116.39924, 43.93205, 0],
					[-116.39889, 43.9321, 0],
					[-116.39869, 43.93212, 0],
					[-116.39849, 43.93213, 0],
					[-116.39786, 43.93213, 0],
					[-116.39744, 43.93211, 0],
					[-116.39714, 43.93208, 0],
					[-116.39651, 43.932, 0],
					[-116.39616, 43.93194, 0],
					[-116.39587, 43.93188, 0],
					[-116.39572, 43.93184, 0],
					[-116.39554, 43.9318, 0],
					[-116.39491, 43.93159, 0],
					[-116.39423, 43.93131, 0],
					[-116.39371, 43.93104, 0],
					[-116.3934, 43.93086, 0],
					[-116.39281, 43.93046, 0],
					[-116.39258, 43.93027, 0],
					[-116.39237, 43.93008, 0],
					[-116.39214, 43.92986, 0],
					[-116.39194, 43.92963, 0],
					[-116.39177, 43.92942, 0],
					[-116.39148, 43.92903, 0],
					[-116.39136, 43.92888, 0],
					[-116.39127, 43.92879, 0],
					[-116.39107, 43.92861, 0],
					[-116.39086, 43.92849, 0],
					[-116.39068, 43.92842, 0],
					[-116.39047, 43.92837, 0],
					[-116.39035, 43.92835, 0],
					[-116.3902, 43.92834, 0],
					[-116.39007, 43.92834, 0],
					[-116.38992, 43.92835, 0],
					[-116.38974, 43.92837, 0],
					[-116.3896, 43.92841, 0],
					[-116.38948, 43.92845, 0],
					[-116.38935, 43.92851, 0],
					[-116.38915, 43.92863, 0],
					[-116.38904, 43.92873, 0],
					[-116.38896, 43.92883, 0],
					[-116.38894, 43.92885, 0],
					[-116.38891, 43.92889, 0],
					[-116.38884, 43.92902, 0],
					[-116.38878, 43.92918, 0],
					[-116.38869, 43.92948, 0],
					[-116.38864, 43.92968, 0],
					[-116.38852, 43.93002, 0],
					[-116.38846, 43.93016, 0],
					[-116.3884, 43.93028, 0],
					[-116.38829, 43.93047, 0],
					[-116.3881, 43.93075, 0],
					[-116.38797, 43.93091, 0],
					[-116.38782, 43.93107, 0],
					[-116.38756, 43.93133, 0],
					[-116.38739, 43.93148, 0],
					[-116.3866, 43.93213, 0],
					[-116.3863, 43.9324, 0],
					[-116.38597, 43.93275, 0],
					[-116.38584, 43.93292, 0],
					[-116.38576, 43.93304, 0],
					[-116.3857, 43.93314, 0],
					[-116.38559, 43.93336, 0],
					[-116.38548, 43.9336, 0],
					[-116.38541, 43.93382, 0],
					[-116.38536, 43.93402, 0],
					[-116.38533, 43.93422, 0],
					[-116.3853, 43.93499, 0],
					[-116.38528, 43.93529, 0],
					[-116.38524, 43.93552, 0],
					[-116.3852, 43.93581, 0],
					[-116.38518, 43.93587, 0],
					[-116.38509, 43.93619, 0],
					[-116.38498, 43.93644, 0],
					[-116.38483, 43.93675, 0],
					[-116.38476, 43.93688, 0],
					[-116.38454, 43.93721, 0],
					[-116.38443, 43.93736, 0],
					[-116.38415, 43.93768, 0],
					[-116.38406, 43.93777, 0],
					[-116.38395, 43.93789, 0],
					[-116.38386, 43.93796, 0],
					[-116.38369, 43.93813, 0],
					[-116.38347, 43.93829, 0],
					[-116.38279, 43.93874, 0],
					[-116.38216, 43.93906, 0],
					[-116.38144, 43.93934, 0],
					[-116.38097, 43.93948, 0],
					[-116.38069, 43.93954, 0],
					[-116.38005, 43.93966, 0],
					[-116.37979, 43.93969, 0],
					[-116.37934, 43.93973, 0],
					[-116.37912, 43.93974, 0],
					[-116.37814, 43.93973, 0],
					[-116.37661, 43.93964, 0],
					[-116.37602, 43.93959, 0],
					[-116.37531, 43.93955, 0],
					[-116.37479, 43.93957, 0],
					[-116.37469, 43.93958, 0],
					[-116.37426, 43.93967, 0],
					[-116.37406, 43.93974, 0],
					[-116.37387, 43.93983, 0],
					[-116.37386, 43.93984, 0],
					[-116.37377, 43.93988, 0],
					[-116.37364, 43.93996, 0],
					[-116.37344, 43.94012, 0],
					[-116.37333, 43.94022, 0],
					[-116.37328, 43.94029, 0],
					[-116.37319, 43.9404, 0],
					[-116.37314, 43.94048, 0],
					[-116.37311, 43.94054, 0],
					[-116.37309, 43.94057, 0],
					[-116.37302, 43.94071, 0],
					[-116.37298, 43.94082, 0],
					[-116.37297, 43.94089, 0],
					[-116.37295, 43.94097, 0],
					[-116.37294, 43.94106, 0],
					[-116.37295, 43.94125, 0],
					[-116.37297, 43.94138, 0],
					[-116.37298, 43.94142, 0],
					[-116.37304, 43.94161, 0],
					[-116.37312, 43.94178, 0],
					[-116.37321, 43.94193, 0],
					[-116.37335, 43.94214, 0],
					[-116.37352, 43.94244, 0],
					[-116.37358, 43.94256, 0],
					[-116.37364, 43.9427, 0],
					[-116.37375, 43.94304, 0],
					[-116.37382, 43.94331, 0],
					[-116.37385, 43.94346, 0],
					[-116.37387, 43.94359, 0],
					[-116.37389, 43.94386, 0],
					[-116.37389, 43.94401, 0],
					[-116.37388, 43.94429, 0],
					[-116.37385, 43.94456, 0],
					[-116.37379, 43.94483, 0],
					[-116.37371, 43.94508, 0],
					[-116.37369, 43.94512, 0],
					[-116.37363, 43.94528, 0],
					[-116.37348, 43.9456, 0],
					[-116.37323, 43.94607, 0],
					[-116.37292, 43.94659, 0],
					[-116.37273, 43.94681, 0],
					[-116.37249, 43.94701, 0],
					[-116.37239, 43.94708, 0],
					[-116.3721, 43.94724, 0],
					[-116.37168, 43.94739, 0],
					[-116.37143, 43.94745, 0],
					[-116.37125, 43.94748, 0],
					[-116.37097, 43.94751, 0],
					[-116.37064, 43.94751, 0],
					[-116.36928, 43.94756, 0],
					[-116.36877, 43.94759, 0],
					[-116.36848, 43.94764, 0],
					[-116.36813, 43.94772, 0],
					[-116.36783, 43.94783, 0],
					[-116.36766, 43.9479, 0],
					[-116.36752, 43.94797, 0],
					[-116.3663, 43.94869, 0],
					[-116.36613, 43.9488, 0],
					[-116.36611, 43.94881, 0],
					[-116.36577, 43.94902, 0],
					[-116.36556, 43.94916, 0],
					[-116.36527, 43.94938, 0],
					[-116.36523, 43.94942, 0],
					[-116.36514, 43.94949, 0],
					[-116.36495, 43.94967, 0],
					[-116.36473, 43.94986, 0],
					[-116.36432, 43.95019, 0],
					[-116.36413, 43.95031, 0],
					[-116.36378, 43.95048, 0],
					[-116.36332, 43.95066, 0],
					[-116.36319, 43.9507, 0],
					[-116.36313, 43.95071, 0],
					[-116.36291, 43.95077, 0],
					[-116.35736, 43.95207, 0],
					[-116.35672, 43.95219, 0],
					[-116.35587, 43.95233, 0],
					[-116.35584, 43.95234, 0],
					[-116.35527, 43.95243, 0],
					[-116.35445, 43.95251, 0],
					[-116.35361, 43.95253, 0],
					[-116.35322, 43.95253, 0],
					[-116.35274, 43.95251, 0],
					[-116.35186, 43.95241, 0],
					[-116.35141, 43.95234, 0],
					[-116.35117, 43.95229, 0],
					[-116.35045, 43.95211, 0],
					[-116.35001, 43.95198, 0],
					[-116.34962, 43.95184, 0],
					[-116.34842, 43.95132, 0],
					[-116.34814, 43.95119, 0],
					[-116.34695, 43.95067, 0],
					[-116.34587, 43.95017, 0],
					[-116.34216, 43.94852, 0],
					[-116.34179, 43.94833, 0],
					[-116.34143, 43.94812, 0],
					[-116.34137, 43.94809, 0],
					[-116.34108, 43.9479, 0],
					[-116.34058, 43.94752, 0],
					[-116.34041, 43.94738, 0],
					[-116.33916, 43.94627, 0],
					[-116.3389, 43.94605, 0],
					[-116.33409, 43.94174, 0],
					[-116.3339, 43.94158, 0],
					[-116.33365, 43.9414, 0],
					[-116.33317, 43.9411, 0],
					[-116.333, 43.94101, 0],
					[-116.33249, 43.94078, 0],
					[-116.33207, 43.94063, 0],
					[-116.33204, 43.94063, 0],
					[-116.33185, 43.94057, 0],
					[-116.33157, 43.9405, 0],
					[-116.33147, 43.94047, 0],
					[-116.33133, 43.94044, 0],
					[-116.33118, 43.94042, 0],
					[-116.33103, 43.94039, 0],
					[-116.3306, 43.94034, 0],
					[-116.33043, 43.94033, 0],
					[-116.33036, 43.94033, 0],
					[-116.33009, 43.94031, 0],
					[-116.32971, 43.9403, 0],
					[-116.32961, 43.94031, 0],
					[-116.31076, 43.94037, 0],
					[-116.31011, 43.94035, 0],
					[-116.30954, 43.94031, 0],
					[-116.30934, 43.94028, 0],
					[-116.30905, 43.94025, 0],
					[-116.30877, 43.94021, 0],
					[-116.30829, 43.94012, 0],
					[-116.3077, 43.93999, 0],
					[-116.30724, 43.93987, 0],
					[-116.30709, 43.93984, 0],
					[-116.30629, 43.93958, 0],
					[-116.30587, 43.93942, 0],
					[-116.30572, 43.93937, 0],
					[-116.30187, 43.9377, 0],
					[-116.30021, 43.93694, 0],
					[-116.29984, 43.93675, 0],
					[-116.29902, 43.93628, 0],
					[-116.29876, 43.93614, 0],
					[-116.2986, 43.93607, 0],
					[-116.29829, 43.93597, 0],
					[-116.2979, 43.93589, 0],
					[-116.29771, 43.93587, 0],
					[-116.2974, 43.93587, 0],
					[-116.2972, 43.93588, 0],
					[-116.29698, 43.93591, 0],
					[-116.29675, 43.93595, 0],
					[-116.296, 43.93612, 0],
					[-116.29598, 43.93612, 0],
					[-116.29543, 43.93625, 0],
					[-116.29504, 43.93633, 0],
					[-116.29469, 43.93638, 0],
					[-116.2944, 43.93643, 0],
					[-116.29397, 43.93647, 0],
					[-116.2932, 43.93651, 0],
					[-116.29246, 43.93653, 0],
					[-116.29168, 43.9365, 0],
					[-116.29119, 43.93646, 0],
					[-116.29027, 43.93635, 0],
					[-116.28973, 43.93627, 0],
					[-116.28929, 43.93618, 0],
					[-116.28888, 43.93608, 0],
					[-116.28887, 43.93607, 0],
					[-116.28847, 43.93596, 0],
					[-116.28801, 43.93582, 0],
					[-116.28743, 43.93562, 0],
					[-116.28728, 43.93555, 0],
					[-116.28685, 43.93537, 0],
					[-116.28656, 43.93524, 0],
					[-116.28616, 43.93504, 0],
					[-116.28583, 43.93486, 0],
					[-116.28555, 43.93469, 0],
					[-116.28507, 43.93438, 0],
					[-116.28471, 43.93413, 0],
					[-116.28442, 43.93391, 0],
					[-116.28423, 43.93375, 0],
					[-116.28421, 43.93373, 0],
					[-116.2841, 43.93364, 0],
					[-116.28361, 43.93316, 0],
					[-116.28339, 43.93292, 0],
					[-116.28186, 43.93111, 0],
					[-116.28176, 43.93098, 0],
					[-116.28143, 43.93058, 0],
					[-116.28137, 43.93052, 0],
					[-116.28078, 43.9298, 0],
					[-116.28067, 43.92968, 0],
					[-116.28027, 43.92918, 0],
					[-116.28004, 43.92886, 0],
					[-116.27995, 43.92872, 0],
					[-116.27978, 43.92843, 0],
					[-116.27973, 43.92833, 0],
					[-116.27956, 43.92792, 0],
					[-116.27953, 43.92783, 0],
					[-116.27945, 43.92755, 0],
					[-116.27937, 43.92712, 0],
					[-116.27935, 43.92695, 0],
					[-116.27935, 43.9268, 0],
					[-116.27934, 43.92656, 0],
					[-116.27934, 43.92641, 0],
					[-116.27935, 43.92629, 0],
					[-116.27935, 43.92625, 0],
					[-116.27977, 43.92098, 0],
					[-116.27993, 43.91918, 0],
					[-116.27994, 43.91878, 0],
					[-116.27992, 43.91855, 0],
					[-116.27989, 43.91835, 0],
					[-116.27981, 43.91807, 0],
					[-116.27977, 43.91797, 0],
					[-116.27972, 43.91786, 0],
					[-116.27956, 43.91756, 0],
					[-116.27955, 43.91755, 0],
					[-116.27949, 43.91745, 0],
					[-116.2794, 43.91732, 0],
					[-116.27927, 43.91716, 0],
					[-116.27917, 43.91705, 0],
					[-116.27898, 43.91686, 0],
					[-116.276, 43.91411, 0],
					[-116.27558, 43.91376, 0],
					[-116.27499, 43.91334, 0],
					[-116.2748, 43.91322, 0],
					[-116.27461, 43.91309, 0],
					[-116.27287, 43.91198, 0],
					[-116.27251, 43.91179, 0],
					[-116.27242, 43.91176, 0],
					[-116.27194, 43.91157, 0],
					[-116.27181, 43.91151, 0],
					[-116.27166, 43.91145, 0],
					[-116.27153, 43.91139, 0],
					[-116.27112, 43.91116, 0],
					[-116.26774, 43.90903, 0],
					[-116.26753, 43.90892, 0],
					[-116.26739, 43.90884, 0],
					[-116.26736, 43.90882, 0],
					[-116.26591, 43.90808, 0],
					[-116.26537, 43.90778, 0],
					[-116.26518, 43.90766, 0],
					[-116.26445, 43.90715, 0],
					[-116.26352, 43.90639, 0],
					[-116.26231, 43.90536, 0],
					[-116.26211, 43.90523, 0],
					[-116.26206, 43.90519, 0],
					[-116.26199, 43.90515, 0],
					[-116.26191, 43.90511, 0],
					[-116.26184, 43.90508, 0],
					[-116.26166, 43.90499, 0],
					[-116.26143, 43.90491, 0],
					[-116.26136, 43.90488, 0],
					[-116.26115, 43.90482, 0],
					[-116.26079, 43.90475, 0],
					[-116.26029, 43.9047, 0],
					[-116.25825, 43.90462, 0],
					[-116.25799, 43.90462, 0],
					[-116.25782, 43.90461, 0],
					[-116.2572, 43.90461, 0],
					[-116.25678, 43.90463, 0],
					[-116.25618, 43.90469, 0],
					[-116.25581, 43.90474, 0],
					[-116.25236, 43.90544, 0],
					[-116.25146, 43.90565, 0],
					[-116.25069, 43.90586, 0],
					[-116.24877, 43.90642, 0],
					[-116.24849, 43.90649, 0],
					[-116.24836, 43.90653, 0],
					[-116.24811, 43.90657, 0],
					[-116.24793, 43.90661, 0],
					[-116.24767, 43.90664, 0],
					[-116.24751, 43.90665, 0],
					[-116.24716, 43.90669, 0],
					[-116.24704, 43.90669, 0],
					[-116.24694, 43.9067, 0],
					[-116.2467, 43.9067, 0],
					[-116.24654, 43.90669, 0],
					[-116.24615, 43.90668, 0],
					[-116.24557, 43.90661, 0],
					[-116.24538, 43.90658, 0],
					[-116.24499, 43.90649, 0],
					[-116.24312, 43.90597, 0],
					[-116.24307, 43.90595, 0],
					[-116.24113, 43.90541, 0],
					[-116.24108, 43.9054, 0],
					[-116.24102, 43.90538, 0],
					[-116.24069, 43.9053, 0],
					[-116.24027, 43.90522, 0],
					[-116.23958, 43.90511, 0],
					[-116.23846, 43.90502, 0],
					[-116.23779, 43.90502, 0],
					[-116.23714, 43.90505, 0],
					[-116.23684, 43.90508, 0],
					[-116.2363, 43.90515, 0],
					[-116.23571, 43.90526, 0],
					[-116.23566, 43.90526, 0],
					[-116.23511, 43.90536, 0],
					[-116.2346, 43.90547, 0],
					[-116.23435, 43.90555, 0],
					[-116.2342, 43.90561, 0],
					[-116.23383, 43.90579, 0],
					[-116.23369, 43.90588, 0],
					[-116.23361, 43.90594, 0],
					[-116.23353, 43.90601, 0],
					[-116.23348, 43.90606, 0],
					[-116.23344, 43.90611, 0],
					[-116.23338, 43.90617, 0],
					[-116.23335, 43.90621, 0],
					[-116.23334, 43.90623, 0],
					[-116.23324, 43.90639, 0],
					[-116.23315, 43.90663, 0],
					[-116.23313, 43.90677, 0],
					[-116.23311, 43.90703, 0],
					[-116.23311, 43.90752, 0],
					[-116.23309, 43.90776, 0],
					[-116.23306, 43.90798, 0],
					[-116.23304, 43.90805, 0],
					[-116.233, 43.90825, 0],
					[-116.23287, 43.90862, 0],
					[-116.23267, 43.90906, 0],
					[-116.23251, 43.90932, 0],
					[-116.23224, 43.90965, 0],
					[-116.23193, 43.90996, 0],
					[-116.23175, 43.91011, 0],
					[-116.23119, 43.91053, 0],
					[-116.23074, 43.91085, 0],
					[-116.23073, 43.91086, 0],
					[-116.22842, 43.9125, 0],
					[-116.22773, 43.91297, 0],
					[-116.22731, 43.91324, 0],
					[-116.22674, 43.91357, 0],
					[-116.22639, 43.91376, 0],
					[-116.2258, 43.91406, 0],
					[-116.22522, 43.91433, 0],
					[-116.22449, 43.91464, 0],
					[-116.22346, 43.91502, 0],
					[-116.22259, 43.91529, 0],
					[-116.22197, 43.91547, 0],
					[-116.22089, 43.91573, 0],
					[-116.21451, 43.91699, 0],
					[-116.21372, 43.91713, 0],
					[-116.21334, 43.91717, 0],
					[-116.21293, 43.9172, 0],
					[-116.21242, 43.91722, 0],
					[-116.21202, 43.91721, 0],
					[-116.21157, 43.91718, 0],
					[-116.20747, 43.91675, 0],
					[-116.20726, 43.91672, 0],
					[-116.20687, 43.91665, 0],
					[-116.20666, 43.91659, 0],
					[-116.20645, 43.91652, 0],
					[-116.2062, 43.91642, 0],
					[-116.206, 43.91633, 0],
					[-116.20597, 43.91631, 0],
					[-116.20578, 43.91622, 0],
					[-116.20547, 43.91609, 0],
					[-116.20534, 43.91605, 0],
					[-116.20532, 43.91605, 0],
					[-116.20523, 43.91602, 0],
					[-116.205, 43.91598, 0],
					[-116.20477, 43.91595, 0],
					[-116.20452, 43.91595, 0],
					[-116.2043, 43.91596, 0],
					[-116.20407, 43.91599, 0],
					[-116.20385, 43.91603, 0],
					[-116.20305, 43.91626, 0],
					[-116.20277, 43.91635, 0],
					[-116.20077, 43.91694, 0],
					[-116.19893, 43.91753, 0],
					[-116.19832, 43.917673],
					[-116.197842, 43.917692],
					[-116.197488, 43.917673],
					[-116.197268, 43.917649],
				],
			},
			id: "8f1cb08e-1298-4c23-9cd5-4be634a7287f",
		},
		// {
		// 	type: "Feature",
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.92328, 44.07266, 0],
		// 	},
		// 	properties: {
		// 		name: "Payette, ID, USA",
		// 		shape: "Marker",
		// 		category: "default",
		// 	},
		// 	id: "eea19272-776a-4b13-af2c-6d246145c563",
		// },
		// {
		// 	type: "Feature",
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.197268, 43.917649],
		// 	},
		// 	properties: {
		// 		name: "Horseshoe Bend, Idaho",
		// 		shape: "Marker",
		// 		category: "default",
		// 	},
		// 	id: "5bf19d2a-1213-40d0-842a-e09785334c00",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Emmet, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.50006, 43.87562, 0],
		// 	},
		// 	id: "d6587834-b3b4-435b-910a-b542077b1039",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Sweet, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.324271, 43.972696],
		// 	},
		// 	id: "a7cbc8e7-a013-47b6-a9d8-ede35adc91f9",
		// },
	],
};

export default LowerPayetteRiver;
