const PayetteRiver = {
	type: "FeatureCollection",
	name: "Payette River Scenic Byway",
	crs: { type: name, properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
	features: [
		{
			type: "Feature",
			properties: {
				name: "Payette River Scenic Byway",
				description: "Find whitewater adventures and outdoor thrills along the Payette River Scenic Byway. Begin this route west of Boise (in Eagle) and follow the Payette River through the Boise and Payette National Forests. This drive is packed with stunning scenery year-round and plenty of opportunities to hop out for some fun in the fresh air.",
				link: "https://visitidaho.org/things-to-do/road-trips/payette-river-scenic-byway/",
				route: [
					"Eagle", 
					"Horseshoe Bend",
					"Banks",
					"Smiths Ferry",
					"Cascade",
					"Donnelly",
					"McCall",
					"New Meadows"
				],
				tessellate: 1,
				shape: 'Line'
			},
			geometry: {
				type: "LineString",
				coordinates: [
					[-116.35401, 43.69544],
					[-116.354, 43.69566],
					[-116.35397, 43.69816],
					[-116.35396, 43.6983],
					[-116.35396, 43.69876],
					[-116.35395, 43.69884],
					[-116.35394, 43.70006],
					[-116.35393, 43.7002],
					[-116.35393, 43.70055],
					[-116.35392, 43.70074],
					[-116.35392, 43.70171],
					[-116.3539, 43.70222],
					[-116.35384, 43.70642],
					[-116.32395, 43.70651],
					[-116.32378, 43.7065],
					[-116.32373, 43.70651],
					[-116.32337, 43.70651],
					[-116.32313, 43.7065],
					[-116.31888, 43.70652],
					[-116.31885, 43.7082],
					[-116.31886, 43.70829],
					[-116.31886, 43.70888],
					[-116.31883, 43.70996],
					[-116.31883, 43.71074],
					[-116.31882, 43.71092],
					[-116.31881, 43.71197],
					[-116.31888, 43.71344],
					[-116.31891, 43.71376],
					[-116.31892, 43.7138],
					[-116.31895, 43.71416],
					[-116.31899, 43.71446],
					[-116.31899, 43.71451],
					[-116.31914, 43.71552],
					[-116.31917, 43.71566],
					[-116.31918, 43.71577],
					[-116.31926, 43.71617],
					[-116.31971, 43.71876],
					[-116.31974, 43.71898],
					[-116.31975, 43.71902],
					[-116.31975, 43.71908],
					[-116.31976, 43.7191],
					[-116.31976, 43.71916],
					[-116.31977, 43.71922],
					[-116.31977, 43.7196],
					[-116.31976, 43.71965],
					[-116.31976, 43.71971],
					[-116.31975, 43.71978],
					[-116.31975, 43.71985],
					[-116.31972, 43.72],
					[-116.31972, 43.72001],
					[-116.31971, 43.72004],
					[-116.3197, 43.72009],
					[-116.31968, 43.72014],
					[-116.31967, 43.72019],
					[-116.31956, 43.72049],
					[-116.31946, 43.72071],
					[-116.31932, 43.72096],
					[-116.31928, 43.72101],
					[-116.31918, 43.72117],
					[-116.31903, 43.72136],
					[-116.31896, 43.72143],
					[-116.3189, 43.7215],
					[-116.31882, 43.72158],
					[-116.31857, 43.7218],
					[-116.3183, 43.72201],
					[-116.31819, 43.72208],
					[-116.31811, 43.72215],
					[-116.31798, 43.72222],
					[-116.31793, 43.72226],
					[-116.31783, 43.72231],
					[-116.31761, 43.72244],
					[-116.31735, 43.72258],
					[-116.31722, 43.72264],
					[-116.31719, 43.72266],
					[-116.31373, 43.72452],
					[-116.31363, 43.72456],
					[-116.31346, 43.72465],
					[-116.3134, 43.72471],
					[-116.30663, 43.72826],
					[-116.30632, 43.72844],
					[-116.30626, 43.72847],
					[-116.30586, 43.72875],
					[-116.30553, 43.72902],
					[-116.30529, 43.72925],
					[-116.30508, 43.72949],
					[-116.30483, 43.72981],
					[-116.30467, 43.73004],
					[-116.30446, 43.73043],
					[-116.30441, 43.73056],
					[-116.30436, 43.73067],
					[-116.3043, 43.73083],
					[-116.30427, 43.73093],
					[-116.30419, 43.73126],
					[-116.30415, 43.73157],
					[-116.30413, 43.73183],
					[-116.3041, 43.73592],
					[-116.30405, 43.73654],
					[-116.30402, 43.73674],
					[-116.304, 43.73681],
					[-116.304, 43.73684],
					[-116.30398, 43.7369],
					[-116.30396, 43.73701],
					[-116.30378, 43.73757],
					[-116.30363, 43.73792],
					[-116.30352, 43.73815],
					[-116.3033, 43.73855],
					[-116.30308, 43.73891],
					[-116.30302, 43.73898],
					[-116.3029, 43.73916],
					[-116.30273, 43.73938],
					[-116.30202, 43.7402],
					[-116.30196, 43.74028],
					[-116.29944, 43.74319],
					[-116.29925, 43.74343],
					[-116.29885, 43.74388],
					[-116.29874, 43.74399],
					[-116.29866, 43.74408],
					[-116.29853, 43.74421],
					[-116.29793, 43.74474],
					[-116.29786, 43.74479],
					[-116.29514, 43.74699],
					[-116.29486, 43.7472],
					[-116.29371, 43.74814],
					[-116.2935, 43.7483],
					[-116.29223, 43.74933],
					[-116.29221, 43.74935],
					[-116.29206, 43.74946],
					[-116.29204, 43.74948],
					[-116.28952, 43.7515],
					[-116.28949, 43.75152],
					[-116.28945, 43.75156],
					[-116.28938, 43.7516],
					[-116.28927, 43.75169],
					[-116.28921, 43.75173],
					[-116.28916, 43.75177],
					[-116.28911, 43.7518],
					[-116.28906, 43.75184],
					[-116.289, 43.75187],
					[-116.28895, 43.75191],
					[-116.28891, 43.75193],
					[-116.28875, 43.75203],
					[-116.2887, 43.75205],
					[-116.28863, 43.7521],
					[-116.28846, 43.75219],
					[-116.28837, 43.75223],
					[-116.28827, 43.75229],
					[-116.28819, 43.75232],
					[-116.28811, 43.75236],
					[-116.28798, 43.75241],
					[-116.28792, 43.75244],
					[-116.28334, 43.75439],
					[-116.28301, 43.75452],
					[-116.28277, 43.75463],
					[-116.28259, 43.7547],
					[-116.28238, 43.7548],
					[-116.28216, 43.75489],
					[-116.28215, 43.7549],
					[-116.28199, 43.75498],
					[-116.28196, 43.755],
					[-116.28176, 43.7551],
					[-116.28138, 43.75533],
					[-116.28117, 43.75549],
					[-116.28029, 43.75623],
					[-116.28012, 43.75639],
					[-116.28005, 43.75644],
					[-116.27956, 43.75686],
					[-116.27944, 43.75694],
					[-116.27942, 43.75696],
					[-116.27938, 43.75699],
					[-116.2792, 43.7571],
					[-116.27916, 43.75713],
					[-116.27894, 43.75726],
					[-116.27873, 43.75736],
					[-116.27856, 43.75743],
					[-116.27848, 43.75747],
					[-116.27831, 43.75754],
					[-116.27805, 43.75763],
					[-116.27789, 43.7577],
					[-116.27773, 43.75775],
					[-116.27604, 43.75835],
					[-116.27595, 43.75839],
					[-116.27575, 43.75846],
					[-116.27568, 43.75849],
					[-116.27561, 43.75851],
					[-116.27551, 43.75855],
					[-116.27541, 43.7586],
					[-116.2752, 43.75869],
					[-116.27516, 43.7587],
					[-116.27512, 43.75873],
					[-116.27506, 43.75875],
					[-116.27501, 43.75878],
					[-116.27496, 43.7588],
					[-116.2749, 43.75884],
					[-116.27485, 43.75886],
					[-116.27451, 43.75906],
					[-116.2739, 43.7595],
					[-116.27356, 43.75973],
					[-116.27346, 43.75979],
					[-116.27342, 43.75982],
					[-116.2731, 43.75999],
					[-116.27287, 43.7601],
					[-116.27271, 43.76016],
					[-116.27262, 43.7602],
					[-116.2726, 43.7602],
					[-116.27256, 43.76021],
					[-116.27249, 43.76024],
					[-116.2724, 43.76027],
					[-116.27225, 43.76031],
					[-116.2721, 43.76036],
					[-116.27202, 43.76037],
					[-116.27197, 43.76039],
					[-116.27191, 43.7604],
					[-116.27188, 43.76041],
					[-116.27184, 43.76041],
					[-116.27179, 43.76042],
					[-116.27173, 43.76044],
					[-116.27168, 43.76044],
					[-116.27161, 43.76046],
					[-116.27121, 43.76051],
					[-116.27115, 43.76051],
					[-116.27106, 43.76052],
					[-116.27089, 43.76052],
					[-116.27077, 43.76053],
					[-116.26945, 43.76053],
					[-116.26941, 43.76054],
					[-116.2693, 43.76054],
					[-116.26924, 43.76055],
					[-116.26916, 43.76055],
					[-116.26884, 43.76058],
					[-116.26874, 43.7606],
					[-116.26846, 43.76064],
					[-116.26838, 43.76066],
					[-116.26832, 43.76067],
					[-116.26818, 43.76071],
					[-116.26813, 43.76072],
					[-116.26768, 43.76085],
					[-116.26762, 43.76088],
					[-116.26755, 43.7609],
					[-116.26751, 43.76092],
					[-116.26747, 43.76093],
					[-116.26713, 43.76108],
					[-116.26707, 43.76112],
					[-116.26694, 43.76119],
					[-116.26689, 43.76121],
					[-116.26687, 43.76123],
					[-116.26684, 43.76124],
					[-116.26681, 43.76126],
					[-116.26679, 43.76128],
					[-116.26675, 43.76131],
					[-116.26671, 43.76133],
					[-116.26649, 43.76149],
					[-116.26642, 43.76155],
					[-116.26636, 43.76159],
					[-116.26623, 43.7617],
					[-116.26612, 43.76181],
					[-116.26606, 43.76186],
					[-116.26606, 43.76187],
					[-116.26591, 43.76202],
					[-116.26586, 43.76208],
					[-116.26581, 43.76215],
					[-116.26579, 43.76217],
					[-116.26576, 43.76221],
					[-116.26574, 43.76225],
					[-116.26571, 43.76228],
					[-116.26569, 43.76232],
					[-116.26566, 43.76236],
					[-116.26559, 43.76247],
					[-116.26552, 43.7626],
					[-116.2655, 43.76266],
					[-116.26541, 43.76285],
					[-116.26532, 43.76317],
					[-116.26528, 43.76337],
					[-116.26525, 43.76369],
					[-116.26525, 43.76387],
					[-116.26527, 43.76393],
					[-116.26527, 43.76397],
					[-116.26528, 43.764],
					[-116.26531, 43.76419],
					[-116.26538, 43.7645],
					[-116.26538, 43.76452],
					[-116.26577, 43.76618],
					[-116.26579, 43.76629],
					[-116.26596, 43.767],
					[-116.26599, 43.7671],
					[-116.266, 43.76718],
					[-116.26613, 43.7677],
					[-116.26615, 43.76785],
					[-116.26618, 43.76798],
					[-116.26618, 43.76802],
					[-116.26619, 43.76807],
					[-116.26619, 43.76812],
					[-116.2662, 43.76815],
					[-116.2662, 43.76819],
					[-116.26621, 43.76825],
					[-116.26621, 43.76836],
					[-116.26622, 43.76843],
					[-116.26622, 43.76857],
					[-116.26621, 43.76868],
					[-116.26621, 43.76876],
					[-116.2662, 43.76881],
					[-116.2662, 43.76885],
					[-116.26618, 43.76903],
					[-116.26599, 43.76997],
					[-116.26595, 43.77022],
					[-116.26567, 43.77164],
					[-116.26567, 43.77168],
					[-116.26558, 43.77208],
					[-116.26558, 43.7721],
					[-116.26536, 43.77326],
					[-116.26533, 43.77336],
					[-116.26425, 43.77865],
					[-116.26425, 43.77867],
					[-116.26411, 43.77938],
					[-116.26409, 43.77945],
					[-116.26327, 43.78352],
					[-116.26322, 43.78391],
					[-116.2632, 43.78417],
					[-116.2632, 43.78676],
					[-116.26321, 43.78707],
					[-116.26323, 43.79231],
					[-116.26326, 43.79287],
					[-116.26334, 43.79338],
					[-116.26339, 43.79361],
					[-116.26352, 43.79403],
					[-116.26376, 43.79471],
					[-116.2642, 43.79577],
					[-116.26424, 43.79589],
					[-116.26501, 43.79778],
					[-116.26508, 43.79798],
					[-116.26513, 43.79816],
					[-116.26513, 43.79818],
					[-116.26517, 43.79834],
					[-116.26523, 43.79873],
					[-116.26524, 43.79884],
					[-116.26525, 43.79888],
					[-116.26525, 43.79896],
					[-116.26526, 43.7991],
					[-116.26526, 43.79926],
					[-116.26525, 43.79942],
					[-116.26522, 43.79965],
					[-116.26516, 43.79995],
					[-116.2651, 43.80019],
					[-116.26506, 43.80029],
					[-116.26502, 43.80044],
					[-116.26451, 43.80177],
					[-116.26444, 43.802],
					[-116.26434, 43.80244],
					[-116.26431, 43.80263],
					[-116.26429, 43.80294],
					[-116.26429, 43.8032],
					[-116.26434, 43.80367],
					[-116.26444, 43.80415],
					[-116.26452, 43.80443],
					[-116.26467, 43.80484],
					[-116.26507, 43.80607],
					[-116.26547, 43.80714],
					[-116.2658, 43.80781],
					[-116.26622, 43.80849],
					[-116.26639, 43.80879],
					[-116.26657, 43.80908],
					[-116.26704, 43.8099],
					[-116.26739, 43.81047],
					[-116.2675, 43.81068],
					[-116.26758, 43.81087],
					[-116.26764, 43.81108],
					[-116.26766, 43.81118],
					[-116.26769, 43.81145],
					[-116.2677, 43.81162],
					[-116.2677, 43.81176],
					[-116.26769, 43.8119],
					[-116.26766, 43.8121],
					[-116.2676, 43.81235],
					[-116.26749, 43.81262],
					[-116.26746, 43.81268],
					[-116.26744, 43.81274],
					[-116.26741, 43.81278],
					[-116.26739, 43.81282],
					[-116.26736, 43.81286],
					[-116.26721, 43.81311],
					[-116.26712, 43.81323],
					[-116.267, 43.81337],
					[-116.26695, 43.81341],
					[-116.26688, 43.81349],
					[-116.26661, 43.81373],
					[-116.26651, 43.81381],
					[-116.26638, 43.8139],
					[-116.26636, 43.81392],
					[-116.2663, 43.81395],
					[-116.26622, 43.81401],
					[-116.26601, 43.81414],
					[-116.26594, 43.81417],
					[-116.26562, 43.81433],
					[-116.26548, 43.81439],
					[-116.26187, 43.81573],
					[-116.26166, 43.81582],
					[-116.26133, 43.81598],
					[-116.26107, 43.81613],
					[-116.26081, 43.81631],
					[-116.26044, 43.81664],
					[-116.26024, 43.81686],
					[-116.26023, 43.81688],
					[-116.26015, 43.81696],
					[-116.25988, 43.81733],
					[-116.25986, 43.81735],
					[-116.25949, 43.81782],
					[-116.25947, 43.81785],
					[-116.25917, 43.81816],
					[-116.25891, 43.81837],
					[-116.25888, 43.81839],
					[-116.25873, 43.81851],
					[-116.25863, 43.81857],
					[-116.25839, 43.81875],
					[-116.25795, 43.81904],
					[-116.25766, 43.81925],
					[-116.25764, 43.81927],
					[-116.25758, 43.81931],
					[-116.25748, 43.81939],
					[-116.25733, 43.81953],
					[-116.25702, 43.81988],
					[-116.25687, 43.8201],
					[-116.25676, 43.82029],
					[-116.25666, 43.82051],
					[-116.25666, 43.82052],
					[-116.25657, 43.82076],
					[-116.25646, 43.82121],
					[-116.25644, 43.82127],
					[-116.25642, 43.82139],
					[-116.25632, 43.82184],
					[-116.25618, 43.82231],
					[-116.25607, 43.8226],
					[-116.25578, 43.82312],
					[-116.25578, 43.82313],
					[-116.25572, 43.82326],
					[-116.25564, 43.82339],
					[-116.25553, 43.82355],
					[-116.25549, 43.82362],
					[-116.25522, 43.824],
					[-116.25497, 43.82431],
					[-116.25416, 43.82507],
					[-116.25393, 43.82525],
					[-116.25387, 43.82531],
					[-116.25381, 43.82535],
					[-116.25375, 43.8254],
					[-116.25348, 43.82559],
					[-116.25235, 43.82629],
					[-116.25218, 43.82641],
					[-116.25197, 43.82659],
					[-116.25177, 43.82679],
					[-116.2515, 43.8271],
					[-116.25108, 43.82763],
					[-116.25096, 43.82776],
					[-116.25092, 43.82781],
					[-116.25067, 43.82804],
					[-116.25056, 43.82813],
					[-116.25031, 43.82831],
					[-116.24997, 43.82852],
					[-116.24988, 43.82856],
					[-116.2498, 43.82861],
					[-116.24969, 43.82866],
					[-116.24958, 43.82872],
					[-116.24921, 43.8289],
					[-116.24869, 43.82918],
					[-116.2484, 43.82935],
					[-116.24807, 43.82952],
					[-116.24729, 43.82986],
					[-116.24692, 43.83004],
					[-116.24633, 43.83039],
					[-116.24629, 43.83041],
					[-116.24616, 43.83049],
					[-116.24595, 43.83065],
					[-116.24588, 43.83071],
					[-116.24582, 43.83077],
					[-116.24552, 43.83114],
					[-116.24506, 43.83191],
					[-116.24493, 43.83221],
					[-116.24486, 43.83256],
					[-116.24484, 43.83274],
					[-116.24485, 43.83293],
					[-116.24488, 43.83315],
					[-116.245, 43.83369],
					[-116.24518, 43.83433],
					[-116.24531, 43.83474],
					[-116.24535, 43.83492],
					[-116.24537, 43.8351],
					[-116.24537, 43.83532],
					[-116.24535, 43.83548],
					[-116.24533, 43.83556],
					[-116.24531, 43.83568],
					[-116.24528, 43.83575],
					[-116.24525, 43.83585],
					[-116.24508, 43.83621],
					[-116.245, 43.83633],
					[-116.24493, 43.83646],
					[-116.24481, 43.83661],
					[-116.24477, 43.83665],
					[-116.24457, 43.83688],
					[-116.24429, 43.83716],
					[-116.24419, 43.83724],
					[-116.244, 43.83737],
					[-116.24377, 43.8375],
					[-116.24355, 43.83761],
					[-116.24117, 43.83864],
					[-116.23947, 43.83942],
					[-116.23908, 43.83963],
					[-116.23891, 43.83975],
					[-116.23874, 43.83985],
					[-116.23807, 43.84035],
					[-116.23762, 43.84062],
					[-116.23724, 43.84089],
					[-116.23668, 43.84132],
					[-116.23629, 43.84159],
					[-116.23608, 43.84175],
					[-116.23569, 43.842],
					[-116.23545, 43.84214],
					[-116.23387, 43.84322],
					[-116.23348, 43.84346],
					[-116.23183, 43.84441],
					[-116.23111, 43.84478],
					[-116.23002, 43.8453],
					[-116.22972, 43.84547],
					[-116.22961, 43.84554],
					[-116.22956, 43.84558],
					[-116.22949, 43.84562],
					[-116.22937, 43.84572],
					[-116.22931, 43.84576],
					[-116.22919, 43.84587],
					[-116.22878, 43.84621],
					[-116.22875, 43.84625],
					[-116.2287, 43.84629],
					[-116.22867, 43.84632],
					[-116.22835, 43.84671],
					[-116.22823, 43.84689],
					[-116.22816, 43.84704],
					[-116.22814, 43.84707],
					[-116.22813, 43.84713],
					[-116.22809, 43.84724],
					[-116.22801, 43.84766],
					[-116.22799, 43.84785],
					[-116.228, 43.84829],
					[-116.22804, 43.84859],
					[-116.22807, 43.84875],
					[-116.2281, 43.84886],
					[-116.2282, 43.84934],
					[-116.22822, 43.84953],
					[-116.22826, 43.84972],
					[-116.22831, 43.84989],
					[-116.22835, 43.84999],
					[-116.22839, 43.85013],
					[-116.2284, 43.85024],
					[-116.2284, 43.85029],
					[-116.22841, 43.85035],
					[-116.22841, 43.85042],
					[-116.2284, 43.85048],
					[-116.2284, 43.85055],
					[-116.22839, 43.85061],
					[-116.22832, 43.85088],
					[-116.22824, 43.85111],
					[-116.22819, 43.85122],
					[-116.22794, 43.85165],
					[-116.2279, 43.85169],
					[-116.22787, 43.85173],
					[-116.22772, 43.85188],
					[-116.22735, 43.85215],
					[-116.22719, 43.85225],
					[-116.22696, 43.85238],
					[-116.2268, 43.85246],
					[-116.22668, 43.8525],
					[-116.22657, 43.85255],
					[-116.2264, 43.85261],
					[-116.22629, 43.85264],
					[-116.22386, 43.85319],
					[-116.2237, 43.85324],
					[-116.2236, 43.85326],
					[-116.22321, 43.8534],
					[-116.2232, 43.8534],
					[-116.22312, 43.85343],
					[-116.22306, 43.85346],
					[-116.22297, 43.85349],
					[-116.22275, 43.85358],
					[-116.22195, 43.85396],
					[-116.22152, 43.85421],
					[-116.22128, 43.85439],
					[-116.22119, 43.85445],
					[-116.2208, 43.85479],
					[-116.22072, 43.85487],
					[-116.22071, 43.85487],
					[-116.22058, 43.855],
					[-116.22036, 43.85526],
					[-116.22032, 43.85533],
					[-116.2203, 43.85535],
					[-116.22023, 43.85544],
					[-116.2202, 43.85549],
					[-116.22014, 43.85557],
					[-116.22008, 43.85568],
					[-116.2198, 43.85614],
					[-116.21969, 43.85638],
					[-116.21959, 43.85666],
					[-116.21954, 43.85684],
					[-116.21953, 43.8569],
					[-116.21951, 43.85697],
					[-116.21949, 43.85707],
					[-116.21945, 43.85738],
					[-116.21945, 43.85744],
					[-116.2194, 43.85788],
					[-116.2194, 43.85791],
					[-116.21936, 43.85833],
					[-116.2193, 43.85862],
					[-116.2193, 43.85863],
					[-116.21926, 43.85881],
					[-116.21925, 43.85888],
					[-116.21908, 43.85937],
					[-116.21902, 43.8595],
					[-116.21902, 43.85951],
					[-116.21889, 43.85979],
					[-116.21882, 43.85991],
					[-116.21873, 43.86004],
					[-116.21867, 43.86014],
					[-116.21858, 43.86027],
					[-116.21834, 43.86057],
					[-116.21801, 43.86091],
					[-116.21752, 43.86132],
					[-116.21695, 43.86171],
					[-116.2162, 43.86218],
					[-116.21612, 43.86224],
					[-116.21596, 43.86234],
					[-116.21574, 43.86252],
					[-116.21568, 43.86258],
					[-116.21561, 43.86264],
					[-116.21557, 43.86269],
					[-116.21553, 43.86273],
					[-116.21548, 43.86279],
					[-116.21543, 43.86284],
					[-116.21529, 43.86303],
					[-116.21526, 43.86309],
					[-116.21516, 43.86325],
					[-116.21508, 43.86342],
					[-116.21504, 43.86353],
					[-116.21498, 43.86374],
					[-116.21497, 43.86379],
					[-116.21493, 43.86412],
					[-116.21494, 43.86422],
					[-116.21495, 43.86452],
					[-116.21508, 43.86593],
					[-116.21508, 43.86623],
					[-116.21506, 43.86631],
					[-116.21503, 43.86652],
					[-116.21494, 43.86679],
					[-116.21482, 43.86705],
					[-116.21478, 43.86711],
					[-116.21474, 43.86719],
					[-116.21469, 43.86725],
					[-116.21466, 43.8673],
					[-116.21443, 43.86757],
					[-116.21433, 43.86767],
					[-116.21428, 43.86771],
					[-116.21427, 43.86773],
					[-116.214, 43.86794],
					[-116.21354, 43.86826],
					[-116.21343, 43.86835],
					[-116.21328, 43.86845],
					[-116.21315, 43.86856],
					[-116.21305, 43.86863],
					[-116.21296, 43.86872],
					[-116.21284, 43.86882],
					[-116.21278, 43.86888],
					[-116.21273, 43.86894],
					[-116.2126, 43.86907],
					[-116.21231, 43.86944],
					[-116.21226, 43.86952],
					[-116.21224, 43.86954],
					[-116.21219, 43.86963],
					[-116.21215, 43.86968],
					[-116.21204, 43.86989],
					[-116.21201, 43.86996],
					[-116.21199, 43.87002],
					[-116.21192, 43.87019],
					[-116.21189, 43.87029],
					[-116.21186, 43.87037],
					[-116.21121, 43.87252],
					[-116.21113, 43.87272],
					[-116.21104, 43.8729],
					[-116.2108, 43.8732],
					[-116.21068, 43.87332],
					[-116.21048, 43.87349],
					[-116.21035, 43.87359],
					[-116.21012, 43.87374],
					[-116.20993, 43.87384],
					[-116.20978, 43.87391],
					[-116.20956, 43.874],
					[-116.20925, 43.8741],
					[-116.2067, 43.87472],
					[-116.20649, 43.87478],
					[-116.2063, 43.87482],
					[-116.20583, 43.8749],
					[-116.20551, 43.87492],
					[-116.20509, 43.87492],
					[-116.20463, 43.87489],
					[-116.20395, 43.87482],
					[-116.20312, 43.87476],
					[-116.20287, 43.87476],
					[-116.20278, 43.87477],
					[-116.20266, 43.87477],
					[-116.20223, 43.87482],
					[-116.20202, 43.87486],
					[-116.20192, 43.87487],
					[-116.20178, 43.8749],
					[-116.20148, 43.87499],
					[-116.20136, 43.87504],
					[-116.20129, 43.87506],
					[-116.20106, 43.87517],
					[-116.20102, 43.87518],
					[-116.20082, 43.87528],
					[-116.19956, 43.87599],
					[-116.19946, 43.87606],
					[-116.19936, 43.87612],
					[-116.19905, 43.87634],
					[-116.19881, 43.87653],
					[-116.19861, 43.87673],
					[-116.19848, 43.87692],
					[-116.1984, 43.87707],
					[-116.19838, 43.87712],
					[-116.19835, 43.87717],
					[-116.19827, 43.87742],
					[-116.19825, 43.87751],
					[-116.19822, 43.8776],
					[-116.1982, 43.8777],
					[-116.19818, 43.87787],
					[-116.19818, 43.87811],
					[-116.19819, 43.87822],
					[-116.19823, 43.8784],
					[-116.19824, 43.87847],
					[-116.19826, 43.87854],
					[-116.19837, 43.87883],
					[-116.19842, 43.87892],
					[-116.1986, 43.87917],
					[-116.19864, 43.87921],
					[-116.19877, 43.87937],
					[-116.19897, 43.87956],
					[-116.1996, 43.88007],
					[-116.19982, 43.88023],
					[-116.20021, 43.88054],
					[-116.20038, 43.88069],
					[-116.20124, 43.88159],
					[-116.2031, 43.88363],
					[-116.20328, 43.88384],
					[-116.20395, 43.88456],
					[-116.204, 43.88462],
					[-116.20406, 43.88468],
					[-116.20408, 43.88471],
					[-116.20411, 43.88473],
					[-116.20422, 43.88484],
					[-116.20426, 43.88487],
					[-116.20429, 43.8849],
					[-116.20454, 43.8851],
					[-116.20459, 43.88513],
					[-116.2048, 43.88528],
					[-116.20498, 43.88539],
					[-116.20513, 43.88547],
					[-116.20528, 43.88556],
					[-116.20609, 43.88598],
					[-116.20627, 43.88605],
					[-116.20671, 43.88626],
					[-116.20685, 43.88636],
					[-116.20696, 43.88643],
					[-116.20717, 43.8866],
					[-116.20721, 43.88664],
					[-116.20726, 43.88668],
					[-116.2074, 43.88683],
					[-116.20772, 43.88724],
					[-116.20799, 43.88772],
					[-116.20968, 43.89056],
					[-116.20971, 43.89063],
					[-116.20977, 43.89073],
					[-116.20983, 43.89085],
					[-116.20997, 43.89122],
					[-116.21002, 43.89143],
					[-116.21005, 43.89165],
					[-116.21005, 43.89173],
					[-116.21006, 43.89183],
					[-116.21005, 43.89191],
					[-116.21005, 43.89204],
					[-116.21002, 43.89225],
					[-116.21, 43.89235],
					[-116.20994, 43.89256],
					[-116.20993, 43.89261],
					[-116.20987, 43.89278],
					[-116.20984, 43.89285],
					[-116.20971, 43.8931],
					[-116.20947, 43.89343],
					[-116.20916, 43.89377],
					[-116.20908, 43.89384],
					[-116.20904, 43.89389],
					[-116.20775, 43.89512],
					[-116.20689, 43.89584],
					[-116.20556, 43.89682],
					[-116.20533, 43.89697],
					[-116.2051, 43.89714],
					[-116.2049, 43.89731],
					[-116.20477, 43.89741],
					[-116.20452, 43.89767],
					[-116.20448, 43.89773],
					[-116.20435, 43.89789],
					[-116.20424, 43.89805],
					[-116.20408, 43.89833],
					[-116.20403, 43.89844],
					[-116.204, 43.89852],
					[-116.20396, 43.8986],
					[-116.20395, 43.89865],
					[-116.20393, 43.8987],
					[-116.20371, 43.89942],
					[-116.2034, 43.90057],
					[-116.20337, 43.90071],
					[-116.20322, 43.90119],
					[-116.20311, 43.90147],
					[-116.20284, 43.90204],
					[-116.20162, 43.90404],
					[-116.20113, 43.90488],
					[-116.20093, 43.9052],
					[-116.20075, 43.90553],
					[-116.20059, 43.90586],
					[-116.20046, 43.90619],
					[-116.20034, 43.90655],
					[-116.19848, 43.91326],
					[-116.19847, 43.91328],
					[-116.19836, 43.91368],
					[-116.19834, 43.91379],
					[-116.19791, 43.91539],
					[-116.19725, 43.91765],
					[-116.19721, 43.91781],
					[-116.1972, 43.91783],
					[-116.19718, 43.91791],
					[-116.19714, 43.91801],
					[-116.19713, 43.91806],
					[-116.1971, 43.91812],
					[-116.19708, 43.91819],
					[-116.19687, 43.91866],
					[-116.19682, 43.91875],
					[-116.19676, 43.91888],
					[-116.19665, 43.91906],
					[-116.19662, 43.91912],
					[-116.19625, 43.91963],
					[-116.19107, 43.9264],
					[-116.19096, 43.92653],
					[-116.18854, 43.92977],
					[-116.18842, 43.92995],
					[-116.18837, 43.93001],
					[-116.18834, 43.93006],
					[-116.18831, 43.9301],
					[-116.18829, 43.93015],
					[-116.18825, 43.9302],
					[-116.18815, 43.93038],
					[-116.18814, 43.93041],
					[-116.18809, 43.9305],
					[-116.18789, 43.93097],
					[-116.1878, 43.93124],
					[-116.18769, 43.93172],
					[-116.18766, 43.9319],
					[-116.18762, 43.93226],
					[-116.18762, 43.93255],
					[-116.18766, 43.9329],
					[-116.18776, 43.93333],
					[-116.18787, 43.93366],
					[-116.18811, 43.93419],
					[-116.18831, 43.93453],
					[-116.18849, 43.93479],
					[-116.1885, 43.93481],
					[-116.18882, 43.9352],
					[-116.18888, 43.93525],
					[-116.18914, 43.93551],
					[-116.18945, 43.93577],
					[-116.1896, 43.93587],
					[-116.18974, 43.93598],
					[-116.18986, 43.93606],
					[-116.1899, 43.93608],
					[-116.19059, 43.93655],
					[-116.19088, 43.93676],
					[-116.19113, 43.93692],
					[-116.19116, 43.93695],
					[-116.19158, 43.93723],
					[-116.19168, 43.93729],
					[-116.19319, 43.93833],
					[-116.19359, 43.93865],
					[-116.19381, 43.93885],
					[-116.19402, 43.93906],
					[-116.19409, 43.93914],
					[-116.19414, 43.93921],
					[-116.19425, 43.93934],
					[-116.19429, 43.9394],
					[-116.19432, 43.93943],
					[-116.1945, 43.93969],
					[-116.19501, 43.94051],
					[-116.19505, 43.94056],
					[-116.19568, 43.94158],
					[-116.19577, 43.94171],
					[-116.19581, 43.94178],
					[-116.19586, 43.94185],
					[-116.19598, 43.94208],
					[-116.19603, 43.94216],
					[-116.19606, 43.94222],
					[-116.19607, 43.94225],
					[-116.19611, 43.94233],
					[-116.19628, 43.94276],
					[-116.19631, 43.94286],
					[-116.19632, 43.94291],
					[-116.19633, 43.94293],
					[-116.19637, 43.94308],
					[-116.19642, 43.94334],
					[-116.19642, 43.94339],
					[-116.19643, 43.94344],
					[-116.19643, 43.94349],
					[-116.19644, 43.94356],
					[-116.19644, 43.94363],
					[-116.19645, 43.94371],
					[-116.19645, 43.94403],
					[-116.19644, 43.94417],
					[-116.19642, 43.94431],
					[-116.19642, 43.94435],
					[-116.19641, 43.94439],
					[-116.19637, 43.94465],
					[-116.19635, 43.94474],
					[-116.19634, 43.94481],
					[-116.19632, 43.94488],
					[-116.19629, 43.94496],
					[-116.19628, 43.94503],
					[-116.19619, 43.94528],
					[-116.19607, 43.94557],
					[-116.19607, 43.94558],
					[-116.19594, 43.94582],
					[-116.19584, 43.94598],
					[-116.19578, 43.94609],
					[-116.19562, 43.9463],
					[-116.19556, 43.9464],
					[-116.19534, 43.94666],
					[-116.19516, 43.94685],
					[-116.19496, 43.94704],
					[-116.19486, 43.94712],
					[-116.19477, 43.94721],
					[-116.19386, 43.94795],
					[-116.19379, 43.94802],
					[-116.19375, 43.94805],
					[-116.19339, 43.94837],
					[-116.19298, 43.94876],
					[-116.19298, 43.94877],
					[-116.19287, 43.94888],
					[-116.19276, 43.94903],
					[-116.19269, 43.94914],
					[-116.19268, 43.94917],
					[-116.19262, 43.94928],
					[-116.19256, 43.94945],
					[-116.1925, 43.94967],
					[-116.19245, 43.94999],
					[-116.19245, 43.95009],
					[-116.19244, 43.95015],
					[-116.19244, 43.95031],
					[-116.19245, 43.95037],
					[-116.19245, 43.95044],
					[-116.19246, 43.95047],
					[-116.19246, 43.95049],
					[-116.19248, 43.95059],
					[-116.19248, 43.95065],
					[-116.1925, 43.9507],
					[-116.19251, 43.95078],
					[-116.19258, 43.95103],
					[-116.19262, 43.95114],
					[-116.19264, 43.95121],
					[-116.1927, 43.95137],
					[-116.19284, 43.95166],
					[-116.19287, 43.95171],
					[-116.19288, 43.95174],
					[-116.19297, 43.95187],
					[-116.19297, 43.95188],
					[-116.19342, 43.95252],
					[-116.1935, 43.95262],
					[-116.19365, 43.95283],
					[-116.19376, 43.953],
					[-116.19401, 43.95333],
					[-116.1941, 43.95348],
					[-116.19419, 43.95361],
					[-116.19439, 43.95394],
					[-116.19444, 43.95404],
					[-116.19448, 43.95414],
					[-116.19456, 43.95431],
					[-116.19459, 43.95441],
					[-116.19462, 43.95449],
					[-116.19464, 43.95453],
					[-116.19468, 43.95466],
					[-116.19468, 43.95467],
					[-116.19478, 43.95504],
					[-116.1948, 43.95516],
					[-116.19483, 43.95528],
					[-116.19487, 43.95565],
					[-116.19487, 43.95572],
					[-116.19488, 43.95582],
					[-116.19488, 43.9559],
					[-116.19487, 43.95595],
					[-116.19487, 43.95602],
					[-116.19485, 43.95627],
					[-116.19484, 43.9563],
					[-116.19483, 43.95643],
					[-116.19482, 43.95645],
					[-116.19481, 43.95652],
					[-116.19479, 43.9566],
					[-116.19478, 43.95669],
					[-116.19476, 43.95674],
					[-116.19475, 43.95681],
					[-116.19472, 43.95689],
					[-116.1947, 43.95697],
					[-116.19461, 43.95722],
					[-116.19445, 43.95759],
					[-116.19427, 43.95792],
					[-116.19422, 43.958],
					[-116.1942, 43.95805],
					[-116.19406, 43.95828],
					[-116.19404, 43.95834],
					[-116.19401, 43.95838],
					[-116.19373, 43.95889],
					[-116.19371, 43.95894],
					[-116.19368, 43.959],
					[-116.19366, 43.95905],
					[-116.19362, 43.95913],
					[-116.19357, 43.95929],
					[-116.19355, 43.95938],
					[-116.19354, 43.9594],
					[-116.19354, 43.95944],
					[-116.19353, 43.95947],
					[-116.19353, 43.95954],
					[-116.19352, 43.95957],
					[-116.19353, 43.96028],
					[-116.19352, 43.96033],
					[-116.19352, 43.96038],
					[-116.19347, 43.9607],
					[-116.19345, 43.96079],
					[-116.19343, 43.96083],
					[-116.19342, 43.96089],
					[-116.19339, 43.96093],
					[-116.19336, 43.961],
					[-116.19334, 43.96102],
					[-116.19331, 43.96107],
					[-116.19329, 43.96112],
					[-116.19323, 43.96119],
					[-116.19311, 43.96131],
					[-116.19307, 43.96134],
					[-116.19303, 43.96138],
					[-116.19292, 43.96145],
					[-116.19288, 43.96148],
					[-116.1927, 43.96158],
					[-116.19263, 43.96161],
					[-116.19221, 43.96176],
					[-116.19193, 43.96184],
					[-116.19109, 43.96212],
					[-116.19095, 43.96216],
					[-116.18971, 43.9626],
					[-116.18964, 43.96262],
					[-116.18936, 43.96274],
					[-116.18922, 43.96281],
					[-116.1892, 43.96283],
					[-116.18915, 43.96285],
					[-116.18903, 43.96294],
					[-116.18898, 43.96297],
					[-116.1889, 43.96303],
					[-116.18887, 43.96306],
					[-116.18884, 43.96308],
					[-116.18881, 43.96312],
					[-116.18874, 43.96319],
					[-116.18866, 43.9633],
					[-116.18862, 43.96334],
					[-116.18858, 43.96342],
					[-116.18856, 43.96345],
					[-116.18851, 43.96355],
					[-116.18847, 43.96367],
					[-116.18844, 43.96374],
					[-116.1884, 43.96389],
					[-116.18838, 43.96394],
					[-116.18834, 43.96419],
					[-116.18834, 43.96427],
					[-116.18833, 43.96433],
					[-116.18834, 43.96443],
					[-116.18835, 43.96481],
					[-116.18842, 43.96575],
					[-116.18849, 43.9673],
					[-116.1885, 43.96737],
					[-116.18869, 43.97135],
					[-116.18871, 43.97151],
					[-116.18871, 43.97158],
					[-116.18872, 43.97164],
					[-116.18872, 43.97165],
					[-116.18874, 43.97178],
					[-116.18875, 43.97189],
					[-116.18884, 43.97226],
					[-116.18892, 43.97248],
					[-116.18897, 43.97259],
					[-116.18902, 43.97272],
					[-116.18917, 43.97303],
					[-116.18931, 43.97329],
					[-116.18948, 43.97353],
					[-116.19101, 43.97538],
					[-116.19102, 43.97538],
					[-116.19265, 43.97729],
					[-116.19382, 43.97882],
					[-116.19389, 43.97893],
					[-116.19397, 43.97904],
					[-116.194, 43.9791],
					[-116.19405, 43.97918],
					[-116.19414, 43.97936],
					[-116.1942, 43.97953],
					[-116.19423, 43.97965],
					[-116.19424, 43.97967],
					[-116.19426, 43.97977],
					[-116.19426, 43.97982],
					[-116.19427, 43.97982],
					[-116.19427, 43.97992],
					[-116.19428, 43.98],
					[-116.19428, 43.98026],
					[-116.19424, 43.98058],
					[-116.19422, 43.98067],
					[-116.19419, 43.98075],
					[-116.19418, 43.9808],
					[-116.19416, 43.98086],
					[-116.19414, 43.98089],
					[-116.19413, 43.98094],
					[-116.19405, 43.9811],
					[-116.194, 43.98118],
					[-116.19397, 43.98124],
					[-116.19386, 43.98139],
					[-116.19381, 43.98145],
					[-116.19373, 43.98156],
					[-116.19357, 43.98173],
					[-116.19349, 43.9818],
					[-116.19343, 43.98186],
					[-116.19287, 43.9823],
					[-116.19269, 43.98242],
					[-116.19036, 43.98412],
					[-116.18854, 43.98525],
					[-116.18822, 43.98543],
					[-116.18748, 43.98589],
					[-116.18745, 43.98592],
					[-116.1872, 43.98612],
					[-116.18716, 43.98617],
					[-116.18709, 43.98623],
					[-116.18697, 43.98637],
					[-116.18685, 43.98654],
					[-116.18675, 43.98673],
					[-116.18667, 43.98691],
					[-116.18657, 43.98727],
					[-116.18652, 43.98755],
					[-116.18652, 43.98761],
					[-116.18651, 43.98768],
					[-116.18651, 43.98793],
					[-116.18655, 43.98816],
					[-116.18659, 43.98831],
					[-116.18665, 43.98859],
					[-116.18699, 43.9898],
					[-116.18701, 43.98989],
					[-116.18755, 43.99184],
					[-116.18755, 43.99185],
					[-116.18762, 43.99216],
					[-116.18762, 43.99219],
					[-116.18764, 43.99225],
					[-116.18768, 43.9927],
					[-116.18768, 43.99282],
					[-116.18764, 43.99316],
					[-116.18757, 43.99348],
					[-116.18752, 43.99363],
					[-116.1874, 43.99392],
					[-116.18729, 43.99415],
					[-116.18723, 43.99425],
					[-116.18692, 43.9947],
					[-116.1868, 43.99483],
					[-116.18677, 43.99487],
					[-116.18669, 43.99495],
					[-116.18658, 43.99508],
					[-116.18461, 43.99703],
					[-116.18457, 43.99706],
					[-116.18232, 43.99929],
					[-116.18217, 43.99948],
					[-116.18209, 43.99957],
					[-116.18205, 43.99964],
					[-116.182, 43.99971],
					[-116.18161, 44.0004],
					[-116.18144, 44.00065],
					[-116.18139, 44.00074],
					[-116.1811, 44.00112],
					[-116.18106, 44.00116],
					[-116.18101, 44.0012],
					[-116.18098, 44.00123],
					[-116.18077, 44.0014],
					[-116.18072, 44.00143],
					[-116.18069, 44.00146],
					[-116.18065, 44.00149],
					[-116.18061, 44.00153],
					[-116.1805, 44.00167],
					[-116.18041, 44.00182],
					[-116.18036, 44.00189],
					[-116.18021, 44.00226],
					[-116.1802, 44.00233],
					[-116.18018, 44.00239],
					[-116.18018, 44.00273],
					[-116.18027, 44.00343],
					[-116.1803, 44.00357],
					[-116.18032, 44.00371],
					[-116.18032, 44.00375],
					[-116.18035, 44.004],
					[-116.18035, 44.00414],
					[-116.18034, 44.0042],
					[-116.18034, 44.00424],
					[-116.18033, 44.0043],
					[-116.18031, 44.00435],
					[-116.18021, 44.00467],
					[-116.18017, 44.00477],
					[-116.18013, 44.00485],
					[-116.18012, 44.00489],
					[-116.18009, 44.00494],
					[-116.18005, 44.00502],
					[-116.18002, 44.00506],
					[-116.18, 44.00511],
					[-116.17995, 44.00517],
					[-116.17992, 44.00522],
					[-116.17977, 44.0054],
					[-116.17934, 44.00581],
					[-116.17919, 44.00593],
					[-116.17907, 44.00601],
					[-116.17885, 44.00613],
					[-116.17885, 44.00614],
					[-116.17822, 44.00642],
					[-116.17704, 44.00689],
					[-116.17691, 44.00693],
					[-116.17679, 44.00698],
					[-116.17624, 44.00715],
					[-116.17589, 44.00723],
					[-116.17532, 44.00732],
					[-116.17451, 44.00742],
					[-116.17445, 44.00742],
					[-116.17376, 44.00749],
					[-116.17364, 44.00751],
					[-116.16826, 44.00807],
					[-116.16787, 44.00813],
					[-116.16762, 44.00818],
					[-116.16753, 44.00821],
					[-116.16724, 44.00828],
					[-116.16692, 44.00838],
					[-116.16686, 44.00841],
					[-116.16678, 44.00843],
					[-116.16657, 44.00851],
					[-116.16651, 44.00854],
					[-116.16511, 44.00911],
					[-116.16476, 44.00924],
					[-116.16356, 44.00973],
					[-116.16352, 44.00975],
					[-116.16315, 44.00989],
					[-116.16293, 44.00999],
					[-116.16288, 44.01002],
					[-116.16278, 44.01006],
					[-116.16275, 44.01008],
					[-116.16258, 44.01017],
					[-116.16243, 44.01026],
					[-116.16233, 44.01033],
					[-116.16224, 44.01038],
					[-116.16216, 44.01044],
					[-116.16215, 44.01046],
					[-116.16213, 44.01046],
					[-116.1621, 44.01049],
					[-116.16207, 44.01051],
					[-116.162, 44.01058],
					[-116.16198, 44.01059],
					[-116.16196, 44.01061],
					[-116.16192, 44.01064],
					[-116.16185, 44.01071],
					[-116.16182, 44.01073],
					[-116.16175, 44.0108],
					[-116.1608, 44.01163],
					[-116.16072, 44.01171],
					[-116.16065, 44.01176],
					[-116.16016, 44.01218],
					[-116.15964, 44.01259],
					[-116.15899, 44.01307],
					[-116.15872, 44.01325],
					[-116.15863, 44.0133],
					[-116.15849, 44.01339],
					[-116.15836, 44.01346],
					[-116.15825, 44.01353],
					[-116.15819, 44.01356],
					[-116.15814, 44.01358],
					[-116.15803, 44.01364],
					[-116.15796, 44.01367],
					[-116.1579, 44.01369],
					[-116.15785, 44.01372],
					[-116.15778, 44.01374],
					[-116.15757, 44.01382],
					[-116.15632, 44.01415],
					[-116.15602, 44.01424],
					[-116.15596, 44.01425],
					[-116.15558, 44.01437],
					[-116.15522, 44.0145],
					[-116.15497, 44.01461],
					[-116.15478, 44.01471],
					[-116.15473, 44.01475],
					[-116.15462, 44.01482],
					[-116.15451, 44.0149],
					[-116.15408, 44.01527],
					[-116.15332, 44.016],
					[-116.15326, 44.01605],
					[-116.15303, 44.01628],
					[-116.15281, 44.01648],
					[-116.15268, 44.01661],
					[-116.15258, 44.0167],
					[-116.15244, 44.01684],
					[-116.1523, 44.017],
					[-116.15219, 44.01714],
					[-116.15215, 44.01722],
					[-116.15211, 44.01728],
					[-116.15205, 44.01739],
					[-116.15194, 44.01767],
					[-116.15182, 44.01834],
					[-116.15174, 44.01896],
					[-116.15159, 44.01983],
					[-116.15156, 44.01996],
					[-116.15145, 44.02031],
					[-116.15141, 44.0204],
					[-116.15126, 44.02065],
					[-116.15112, 44.02083],
					[-116.15103, 44.02093],
					[-116.15091, 44.02105],
					[-116.15079, 44.02115],
					[-116.15074, 44.02118],
					[-116.15057, 44.02131],
					[-116.15054, 44.02134],
					[-116.15009, 44.0216],
					[-116.14957, 44.02185],
					[-116.14947, 44.02191],
					[-116.14921, 44.02204],
					[-116.14916, 44.02206],
					[-116.14795, 44.02269],
					[-116.14794, 44.02269],
					[-116.14752, 44.02289],
					[-116.14731, 44.02298],
					[-116.146, 44.02361],
					[-116.14546, 44.02383],
					[-116.14531, 44.0239],
					[-116.14414, 44.02434],
					[-116.14409, 44.02435],
					[-116.14264, 44.02487],
					[-116.14257, 44.0249],
					[-116.14214, 44.02505],
					[-116.14212, 44.02505],
					[-116.14189, 44.02512],
					[-116.14187, 44.02513],
					[-116.14031, 44.02558],
					[-116.14027, 44.0256],
					[-116.1387, 44.02603],
					[-116.13817, 44.02619],
					[-116.13816, 44.02619],
					[-116.13778, 44.02632],
					[-116.13772, 44.02635],
					[-116.13751, 44.02642],
					[-116.13718, 44.02656],
					[-116.13686, 44.02673],
					[-116.13678, 44.02678],
					[-116.13666, 44.02684],
					[-116.13652, 44.02693],
					[-116.13622, 44.02715],
					[-116.1357, 44.02759],
					[-116.13533, 44.028],
					[-116.13485, 44.02856],
					[-116.13475, 44.0287],
					[-116.13468, 44.02878],
					[-116.1346, 44.02891],
					[-116.13448, 44.02915],
					[-116.13435, 44.02945],
					[-116.13416, 44.02998],
					[-116.13392, 44.03102],
					[-116.13392, 44.03105],
					[-116.13388, 44.03131],
					[-116.13388, 44.03157],
					[-116.13389, 44.03168],
					[-116.13441, 44.0342],
					[-116.13444, 44.0344],
					[-116.13445, 44.03456],
					[-116.13446, 44.03458],
					[-116.13446, 44.03484],
					[-116.13443, 44.03526],
					[-116.13438, 44.03565],
					[-116.13432, 44.03601],
					[-116.13421, 44.03651],
					[-116.13415, 44.03668],
					[-116.13399, 44.03703],
					[-116.13323, 44.03837],
					[-116.1319, 44.04083],
					[-116.13188, 44.04088],
					[-116.13092, 44.04267],
					[-116.13078, 44.04295],
					[-116.13071, 44.04311],
					[-116.13064, 44.04331],
					[-116.1305, 44.04388],
					[-116.13044, 44.04427],
					[-116.13043, 44.04446],
					[-116.13043, 44.04464],
					[-116.13042, 44.04485],
					[-116.13042, 44.04507],
					[-116.13043, 44.04521],
					[-116.13049, 44.04557],
					[-116.13055, 44.04577],
					[-116.13067, 44.04606],
					[-116.13094, 44.04655],
					[-116.13134, 44.04719],
					[-116.13177, 44.04795],
					[-116.13178, 44.04798],
					[-116.1327, 44.05001],
					[-116.1327, 44.05002],
					[-116.13274, 44.05012],
					[-116.13276, 44.0502],
					[-116.13278, 44.05024],
					[-116.13282, 44.05037],
					[-116.13286, 44.05056],
					[-116.13288, 44.05073],
					[-116.13288, 44.05078],
					[-116.13289, 44.05083],
					[-116.13288, 44.05092],
					[-116.13288, 44.051],
					[-116.13287, 44.05105],
					[-116.13287, 44.05111],
					[-116.13285, 44.05119],
					[-116.13283, 44.05124],
					[-116.13282, 44.0513],
					[-116.13271, 44.05161],
					[-116.13271, 44.05162],
					[-116.13241, 44.05228],
					[-116.13231, 44.05247],
					[-116.13229, 44.05252],
					[-116.13225, 44.05258],
					[-116.13217, 44.05274],
					[-116.13199, 44.05305],
					[-116.1319, 44.05317],
					[-116.13182, 44.05329],
					[-116.13181, 44.0533],
					[-116.13166, 44.05351],
					[-116.13159, 44.05359],
					[-116.13158, 44.05361],
					[-116.13129, 44.05392],
					[-116.13125, 44.05395],
					[-116.13101, 44.05417],
					[-116.13082, 44.05432],
					[-116.13067, 44.05445],
					[-116.13065, 44.05446],
					[-116.12908, 44.0556],
					[-116.12904, 44.05562],
					[-116.12888, 44.05573],
					[-116.12886, 44.05575],
					[-116.12864, 44.05589],
					[-116.12784, 44.05645],
					[-116.12742, 44.05679],
					[-116.12724, 44.05696],
					[-116.12693, 44.05733],
					[-116.12692, 44.05735],
					[-116.1266, 44.05776],
					[-116.12646, 44.05797],
					[-116.12644, 44.05801],
					[-116.12638, 44.05809],
					[-116.12571, 44.0589],
					[-116.12569, 44.05893],
					[-116.12566, 44.05896],
					[-116.12527, 44.05943],
					[-116.12504, 44.05967],
					[-116.125, 44.05972],
					[-116.12497, 44.05975],
					[-116.12475, 44.06001],
					[-116.12464, 44.06012],
					[-116.12399, 44.0607],
					[-116.12385, 44.06081],
					[-116.12327, 44.06131],
					[-116.12319, 44.0614],
					[-116.12307, 44.06152],
					[-116.12295, 44.06167],
					[-116.12295, 44.06168],
					[-116.12285, 44.06181],
					[-116.12263, 44.06214],
					[-116.12261, 44.06216],
					[-116.12252, 44.0623],
					[-116.12225, 44.06278],
					[-116.12159, 44.06379],
					[-116.12159, 44.0638],
					[-116.1214, 44.06408],
					[-116.12105, 44.06469],
					[-116.12092, 44.06496],
					[-116.12088, 44.06508],
					[-116.12085, 44.06513],
					[-116.12078, 44.06536],
					[-116.12075, 44.06544],
					[-116.12075, 44.06549],
					[-116.12074, 44.06552],
					[-116.1207, 44.06581],
					[-116.12064, 44.06655],
					[-116.12061, 44.06676],
					[-116.12057, 44.06692],
					[-116.12057, 44.06693],
					[-116.12052, 44.06713],
					[-116.12047, 44.06724],
					[-116.12044, 44.06732],
					[-116.12033, 44.06756],
					[-116.12029, 44.06762],
					[-116.12021, 44.06776],
					[-116.12017, 44.06782],
					[-116.11998, 44.06807],
					[-116.11908, 44.06916],
					[-116.11895, 44.06934],
					[-116.11881, 44.06963],
					[-116.11878, 44.06973],
					[-116.11875, 44.06981],
					[-116.11871, 44.06996],
					[-116.1187, 44.0701],
					[-116.11852, 44.07164],
					[-116.11852, 44.0718],
					[-116.11851, 44.07194],
					[-116.11852, 44.07198],
					[-116.11852, 44.07214],
					[-116.11853, 44.07234],
					[-116.11855, 44.07244],
					[-116.11855, 44.07248],
					[-116.11857, 44.07254],
					[-116.11859, 44.07267],
					[-116.11867, 44.07286],
					[-116.11885, 44.0732],
					[-116.11899, 44.07341],
					[-116.11994, 44.07461],
					[-116.12014, 44.07493],
					[-116.12014, 44.07494],
					[-116.12023, 44.07511],
					[-116.12066, 44.07621],
					[-116.12071, 44.07636],
					[-116.12074, 44.0764],
					[-116.12075, 44.07644],
					[-116.12077, 44.07649],
					[-116.12079, 44.07652],
					[-116.12085, 44.07664],
					[-116.12099, 44.07687],
					[-116.12113, 44.07707],
					[-116.12119, 44.07718],
					[-116.12134, 44.07741],
					[-116.12138, 44.07751],
					[-116.12144, 44.07762],
					[-116.12154, 44.07787],
					[-116.12159, 44.0781],
					[-116.12161, 44.07824],
					[-116.12162, 44.07826],
					[-116.12163, 44.07833],
					[-116.12163, 44.07886],
					[-116.1216, 44.07927],
					[-116.12153, 44.07986],
					[-116.12142, 44.08055],
					[-116.12135, 44.08077],
					[-116.1213, 44.08088],
					[-116.12126, 44.08099],
					[-116.1211, 44.08128],
					[-116.12106, 44.08134],
					[-116.12105, 44.08136],
					[-116.1208, 44.08166],
					[-116.12074, 44.08172],
					[-116.12062, 44.08186],
					[-116.12034, 44.08213],
					[-116.1202, 44.08225],
					[-116.1202, 44.08226],
					[-116.12005, 44.08237],
					[-116.12004, 44.08238],
					[-116.11992, 44.08247],
					[-116.11935, 44.08283],
					[-116.11908, 44.08297],
					[-116.11905, 44.08298],
					[-116.11874, 44.08312],
					[-116.11847, 44.08322],
					[-116.11818, 44.08331],
					[-116.11759, 44.08342],
					[-116.11745, 44.08343],
					[-116.11726, 44.08347],
					[-116.11679, 44.08354],
					[-116.11639, 44.08362],
					[-116.11612, 44.0837],
					[-116.11598, 44.08375],
					[-116.11549, 44.08397],
					[-116.11525, 44.08411],
					[-116.11513, 44.08419],
					[-116.11507, 44.08424],
					[-116.11496, 44.08432],
					[-116.11485, 44.08441],
					[-116.11482, 44.08443],
					[-116.11476, 44.08449],
					[-116.11465, 44.08465],
					[-116.11443, 44.08493],
					[-116.11443, 44.08494],
					[-116.11421, 44.08519],
					[-116.11405, 44.0854],
					[-116.11392, 44.08555],
					[-116.11382, 44.08569],
					[-116.11374, 44.08578],
					[-116.1136, 44.08599],
					[-116.11351, 44.08611],
					[-116.1134, 44.08629],
					[-116.11329, 44.08643],
					[-116.11329, 44.08644],
					[-116.11296, 44.08688],
					[-116.11277, 44.08711],
					[-116.11268, 44.08723],
					[-116.11261, 44.08731],
					[-116.11251, 44.08741],
					[-116.11247, 44.08744],
					[-116.11245, 44.08746],
					[-116.11232, 44.08755],
					[-116.11229, 44.08756],
					[-116.11211, 44.08768],
					[-116.1121, 44.08769],
					[-116.11204, 44.08773],
					[-116.11201, 44.08776],
					[-116.11199, 44.08777],
					[-116.11192, 44.08786],
					[-116.11188, 44.08792],
					[-116.11184, 44.08801],
					[-116.11181, 44.0881],
					[-116.11173, 44.08829],
					[-116.1117, 44.08841],
					[-116.11167, 44.08849],
					[-116.11167, 44.0885],
					[-116.11165, 44.08858],
					[-116.11163, 44.08872],
					[-116.1116, 44.08885],
					[-116.1116, 44.08887],
					[-116.11155, 44.08903],
					[-116.11155, 44.08904],
					[-116.11154, 44.08908],
					[-116.11152, 44.08912],
					[-116.11148, 44.08918],
					[-116.11148, 44.08919],
					[-116.11147, 44.0892],
					[-116.11145, 44.08924],
					[-116.11143, 44.08926],
					[-116.1114, 44.0893],
					[-116.11135, 44.08935],
					[-116.11127, 44.08941],
					[-116.11123, 44.08943],
					[-116.1112, 44.08945],
					[-116.11105, 44.08951],
					[-116.1108, 44.08958],
					[-116.11067, 44.0896],
					[-116.11066, 44.08961],
					[-116.11059, 44.08961],
					[-116.11042, 44.08963],
					[-116.11028, 44.08963],
					[-116.11012, 44.08964],
					[-116.10999, 44.08964],
					[-116.10991, 44.08963],
					[-116.10983, 44.08963],
					[-116.10869, 44.08948],
					[-116.10854, 44.08947],
					[-116.10841, 44.08945],
					[-116.10828, 44.08944],
					[-116.10781, 44.08944],
					[-116.10731, 44.08949],
					[-116.10725, 44.0895],
					[-116.10718, 44.08952],
					[-116.10686, 44.08959],
					[-116.10653, 44.0897],
					[-116.10647, 44.08973],
					[-116.1062, 44.08984],
					[-116.10606, 44.08991],
					[-116.10603, 44.08992],
					[-116.10592, 44.08997],
					[-116.10585, 44.09001],
					[-116.10584, 44.09002],
					[-116.10497, 44.09055],
					[-116.10482, 44.09065],
					[-116.10469, 44.09072],
					[-116.10463, 44.09077],
					[-116.1045, 44.09085],
					[-116.10409, 44.09114],
					[-116.10388, 44.09126],
					[-116.10378, 44.09131],
					[-116.10368, 44.09135],
					[-116.10338, 44.09151],
					[-116.10322, 44.09161],
					[-116.103, 44.09177],
					[-116.10292, 44.09184],
					[-116.10281, 44.09196],
					[-116.10274, 44.09206],
					[-116.10274, 44.09207],
					[-116.10271, 44.09211],
					[-116.10267, 44.09218],
					[-116.1026, 44.09234],
					[-116.10224, 44.09375],
					[-116.10224, 44.09378],
					[-116.10223, 44.09382],
					[-116.10221, 44.09387],
					[-116.10219, 44.09395],
					[-116.10217, 44.094],
					[-116.10204, 44.09425],
					[-116.10199, 44.09432],
					[-116.10175, 44.09461],
					[-116.10146, 44.09489],
					[-116.10135, 44.09497],
					[-116.10133, 44.09498],
					[-116.10074, 44.09547],
					[-116.10055, 44.09564],
					[-116.10021, 44.09597],
					[-116.10014, 44.09607],
					[-116.10006, 44.09617],
					[-116.09999, 44.0963],
					[-116.09993, 44.09643],
					[-116.09984, 44.09672],
					[-116.09981, 44.0968],
					[-116.09979, 44.09689],
					[-116.09977, 44.09705],
					[-116.09977, 44.09726],
					[-116.0998, 44.09769],
					[-116.09981, 44.09772],
					[-116.09984, 44.09799],
					[-116.09984, 44.09805],
					[-116.09986, 44.09823],
					[-116.09985, 44.09831],
					[-116.09985, 44.09839],
					[-116.09984, 44.09847],
					[-116.0998, 44.0986],
					[-116.0998, 44.09861],
					[-116.09978, 44.09867],
					[-116.0997, 44.0988],
					[-116.09964, 44.09888],
					[-116.09952, 44.09898],
					[-116.09944, 44.09903],
					[-116.09937, 44.09906],
					[-116.0993, 44.0991],
					[-116.09923, 44.09912],
					[-116.09921, 44.09913],
					[-116.09911, 44.09916],
					[-116.09906, 44.09917],
					[-116.0984, 44.09915],
					[-116.09779, 44.09916],
					[-116.09647, 44.09926],
					[-116.09643, 44.09927],
					[-116.09623, 44.0993],
					[-116.09594, 44.09936],
					[-116.09578, 44.09942],
					[-116.09576, 44.09943],
					[-116.09573, 44.09944],
					[-116.09563, 44.09949],
					[-116.09561, 44.09952],
					[-116.09555, 44.09958],
					[-116.09551, 44.09963],
					[-116.09543, 44.09976],
					[-116.09535, 44.09998],
					[-116.09527, 44.10032],
					[-116.09517, 44.10089],
					[-116.09517, 44.1009],
					[-116.0951, 44.10136],
					[-116.09508, 44.10144],
					[-116.09506, 44.10149],
					[-116.095, 44.10171],
					[-116.09488, 44.10195],
					[-116.09459, 44.10245],
					[-116.09458, 44.10245],
					[-116.09457, 44.10248],
					[-116.0945, 44.10262],
					[-116.09441, 44.10284],
					[-116.0944, 44.10291],
					[-116.09438, 44.10297],
					[-116.09437, 44.10302],
					[-116.09437, 44.10317],
					[-116.09438, 44.10326],
					[-116.09438, 44.10335],
					[-116.09442, 44.10354],
					[-116.09443, 44.10361],
					[-116.09445, 44.10367],
					[-116.09447, 44.1037],
					[-116.09448, 44.10374],
					[-116.0947, 44.10411],
					[-116.09477, 44.10425],
					[-116.09483, 44.1044],
					[-116.0949, 44.10455],
					[-116.095, 44.1049],
					[-116.09507, 44.10527],
					[-116.09508, 44.10547],
					[-116.09507, 44.10607],
					[-116.0951, 44.1063],
					[-116.09517, 44.10655],
					[-116.09523, 44.10672],
					[-116.09524, 44.10672],
					[-116.09528, 44.10681],
					[-116.09535, 44.10693],
					[-116.09545, 44.10708],
					[-116.09569, 44.10738],
					[-116.09573, 44.10742],
					[-116.09583, 44.10754],
					[-116.096, 44.10772],
					[-116.09605, 44.10778],
					[-116.09615, 44.10788],
					[-116.09632, 44.10802],
					[-116.09654, 44.10817],
					[-116.09667, 44.10825],
					[-116.09688, 44.10835],
					[-116.0971, 44.10843],
					[-116.09711, 44.10844],
					[-116.09721, 44.10847],
					[-116.09741, 44.10855],
					[-116.0976, 44.10864],
					[-116.09786, 44.10875],
					[-116.09814, 44.10889],
					[-116.09839, 44.10905],
					[-116.09869, 44.10931],
					[-116.09905, 44.10969],
					[-116.09911, 44.10974],
					[-116.09941, 44.11008],
					[-116.09963, 44.1103],
					[-116.09994, 44.11057],
					[-116.09999, 44.1106],
					[-116.10007, 44.11066],
					[-116.10034, 44.11083],
					[-116.10423, 44.11299],
					[-116.10424, 44.11299],
					[-116.10452, 44.11315],
					[-116.10463, 44.11322],
					[-116.10505, 44.11357],
					[-116.1052, 44.11372],
					[-116.10552, 44.11407],
					[-116.10565, 44.1142],
					[-116.10567, 44.11423],
					[-116.10582, 44.11439],
					[-116.10628, 44.11496],
					[-116.1063, 44.11499],
					[-116.10695, 44.11573],
					[-116.10729, 44.11607],
					[-116.1075, 44.11624],
					[-116.10793, 44.11653],
					[-116.10808, 44.11662],
					[-116.10827, 44.11672],
					[-116.1086, 44.11687],
					[-116.10885, 44.11697],
					[-116.10906, 44.11704],
					[-116.10906, 44.11705],
					[-116.1102, 44.11738],
					[-116.11022, 44.11739],
					[-116.11039, 44.11744],
					[-116.11068, 44.11754],
					[-116.11091, 44.11763],
					[-116.11127, 44.11779],
					[-116.11132, 44.11782],
					[-116.11153, 44.11792],
					[-116.11169, 44.11802],
					[-116.11179, 44.1181],
					[-116.11181, 44.11812],
					[-116.1119, 44.11819],
					[-116.11198, 44.11827],
					[-116.11209, 44.11841],
					[-116.11223, 44.11864],
					[-116.11232, 44.11891],
					[-116.11232, 44.11892],
					[-116.11235, 44.11905],
					[-116.11235, 44.1191],
					[-116.11236, 44.11916],
					[-116.11236, 44.11928],
					[-116.11235, 44.11946],
					[-116.11234, 44.11954],
					[-116.11231, 44.11963],
					[-116.11231, 44.11964],
					[-116.11226, 44.1198],
					[-116.11225, 44.11981],
					[-116.11219, 44.11996],
					[-116.11208, 44.12016],
					[-116.11188, 44.12048],
					[-116.11176, 44.12064],
					[-116.11171, 44.12072],
					[-116.11126, 44.12132],
					[-116.11123, 44.12138],
					[-116.11119, 44.12143],
					[-116.11115, 44.12151],
					[-116.11112, 44.12155],
					[-116.11107, 44.12167],
					[-116.11106, 44.12168],
					[-116.11101, 44.12185],
					[-116.11099, 44.12196],
					[-116.11097, 44.12202],
					[-116.11091, 44.12236],
					[-116.11091, 44.12239],
					[-116.1109, 44.1224],
					[-116.11086, 44.12288],
					[-116.11086, 44.12318],
					[-116.11085, 44.12327],
					[-116.11086, 44.12348],
					[-116.11088, 44.12364],
					[-116.11089, 44.12368],
					[-116.11091, 44.12382],
					[-116.11095, 44.12397],
					[-116.11096, 44.12405],
					[-116.11102, 44.12424],
					[-116.11104, 44.12432],
					[-116.11118, 44.12467],
					[-116.11118, 44.12468],
					[-116.11158, 44.12555],
					[-116.1116, 44.12561],
					[-116.11167, 44.12578],
					[-116.11167, 44.12579],
					[-116.11174, 44.12603],
					[-116.11177, 44.12624],
					[-116.11178, 44.1264],
					[-116.11178, 44.12671],
					[-116.11177, 44.12685],
					[-116.11172, 44.12722],
					[-116.11167, 44.12739],
					[-116.11156, 44.12767],
					[-116.11136, 44.12812],
					[-116.1113, 44.12829],
					[-116.11121, 44.12851],
					[-116.11118, 44.12861],
					[-116.11116, 44.12864],
					[-116.11113, 44.12877],
					[-116.11112, 44.12889],
					[-116.11112, 44.12913],
					[-116.11113, 44.12918],
					[-116.11114, 44.12927],
					[-116.11116, 44.12931],
					[-116.11123, 44.1295],
					[-116.11133, 44.12966],
					[-116.11156, 44.12993],
					[-116.1117, 44.13004],
					[-116.11176, 44.13008],
					[-116.11178, 44.1301],
					[-116.11187, 44.13016],
					[-116.11206, 44.13026],
					[-116.11251, 44.13045],
					[-116.11369, 44.1309],
					[-116.11402, 44.13106],
					[-116.11435, 44.13127],
					[-116.11453, 44.13142],
					[-116.11461, 44.1315],
					[-116.11475, 44.13166],
					[-116.11476, 44.13166],
					[-116.1148, 44.13173],
					[-116.11491, 44.13188],
					[-116.115, 44.13206],
					[-116.11502, 44.13212],
					[-116.11505, 44.13225],
					[-116.11507, 44.13237],
					[-116.11509, 44.13245],
					[-116.11509, 44.13251],
					[-116.11514, 44.13284],
					[-116.11514, 44.13287],
					[-116.11521, 44.13362],
					[-116.11524, 44.13385],
					[-116.11524, 44.13389],
					[-116.11533, 44.13439],
					[-116.11537, 44.13454],
					[-116.11541, 44.13465],
					[-116.11542, 44.13471],
					[-116.1155, 44.13491],
					[-116.11574, 44.13539],
					[-116.11584, 44.13556],
					[-116.11591, 44.13566],
					[-116.11616, 44.13607],
					[-116.11618, 44.13611],
					[-116.11623, 44.13619],
					[-116.11623, 44.13621],
					[-116.11627, 44.13629],
					[-116.11631, 44.1364],
					[-116.11638, 44.13668],
					[-116.11641, 44.1369],
					[-116.11641, 44.13692],
					[-116.11642, 44.13698],
					[-116.11643, 44.1371],
					[-116.11643, 44.13746],
					[-116.11641, 44.13778],
					[-116.11639, 44.13794],
					[-116.11636, 44.13811],
					[-116.11626, 44.13852],
					[-116.11606, 44.13907],
					[-116.11605, 44.13908],
					[-116.11569, 44.14001],
					[-116.11569, 44.14002],
					[-116.11542, 44.1407],
					[-116.11526, 44.14116],
					[-116.11484, 44.14226],
					[-116.11456, 44.1431],
					[-116.11449, 44.14324],
					[-116.11449, 44.14325],
					[-116.11443, 44.1434],
					[-116.11437, 44.14351],
					[-116.11421, 44.14374],
					[-116.114, 44.14395],
					[-116.11384, 44.14409],
					[-116.11374, 44.14416],
					[-116.11342, 44.14444],
					[-116.11333, 44.14453],
					[-116.11316, 44.14476],
					[-116.11309, 44.14492],
					[-116.11309, 44.14494],
					[-116.11306, 44.14504],
					[-116.11305, 44.14509],
					[-116.11305, 44.14531],
					[-116.11308, 44.1455],
					[-116.11313, 44.1457],
					[-116.11324, 44.14601],
					[-116.1133, 44.14621],
					[-116.11335, 44.14633],
					[-116.11335, 44.14634],
					[-116.11336, 44.14637],
					[-116.11337, 44.14646],
					[-116.11337, 44.14651],
					[-116.11338, 44.14658],
					[-116.11338, 44.14672],
					[-116.11337, 44.14686],
					[-116.11329, 44.14718],
					[-116.11326, 44.14726],
					[-116.11318, 44.14743],
					[-116.11304, 44.14761],
					[-116.1123, 44.14843],
					[-116.11221, 44.14855],
					[-116.11216, 44.14865],
					[-116.11208, 44.14885],
					[-116.11206, 44.14897],
					[-116.11206, 44.14898],
					[-116.11205, 44.14904],
					[-116.11206, 44.14913],
					[-116.11206, 44.14918],
					[-116.11207, 44.14929],
					[-116.11207, 44.14934],
					[-116.11208, 44.14935],
					[-116.11209, 44.14942],
					[-116.11211, 44.1495],
					[-116.11211, 44.14951],
					[-116.11213, 44.14958],
					[-116.11215, 44.14963],
					[-116.11223, 44.14978],
					[-116.11239, 44.14999],
					[-116.11259, 44.15019],
					[-116.11272, 44.15028],
					[-116.11272, 44.15029],
					[-116.11281, 44.15034],
					[-116.11288, 44.15039],
					[-116.11308, 44.15049],
					[-116.11403, 44.15089],
					[-116.11414, 44.15095],
					[-116.11419, 44.15097],
					[-116.11432, 44.15104],
					[-116.11444, 44.15112],
					[-116.11454, 44.1512],
					[-116.11456, 44.15121],
					[-116.11471, 44.15136],
					[-116.11471, 44.15137],
					[-116.11485, 44.15155],
					[-116.11491, 44.15166],
					[-116.11493, 44.15168],
					[-116.11502, 44.15185],
					[-116.11502, 44.15186],
					[-116.11508, 44.15197],
					[-116.11509, 44.15202],
					[-116.11523, 44.15233],
					[-116.11531, 44.15253],
					[-116.11531, 44.15254],
					[-116.11537, 44.15267],
					[-116.11539, 44.15273],
					[-116.11548, 44.15287],
					[-116.11562, 44.15304],
					[-116.11564, 44.15307],
					[-116.11573, 44.15315],
					[-116.11576, 44.15317],
					[-116.11584, 44.15325],
					[-116.11598, 44.15336],
					[-116.11629, 44.15355],
					[-116.11632, 44.15356],
					[-116.11702, 44.15399],
					[-116.1172, 44.15413],
					[-116.11722, 44.15415],
					[-116.11732, 44.15423],
					[-116.11746, 44.15437],
					[-116.11751, 44.15444],
					[-116.11752, 44.15446],
					[-116.11761, 44.15458],
					[-116.11762, 44.15461],
					[-116.11763, 44.15463],
					[-116.11767, 44.15469],
					[-116.11776, 44.15493],
					[-116.11777, 44.15495],
					[-116.11781, 44.15512],
					[-116.11781, 44.15513],
					[-116.11783, 44.15518],
					[-116.11785, 44.15536],
					[-116.11787, 44.15566],
					[-116.11787, 44.15584],
					[-116.11788, 44.15602],
					[-116.11797, 44.16043],
					[-116.11798, 44.16052],
					[-116.118, 44.16142],
					[-116.11802, 44.16178],
					[-116.11806, 44.16347],
					[-116.11809, 44.16388],
					[-116.11812, 44.16407],
					[-116.11814, 44.16412],
					[-116.11819, 44.16428],
					[-116.11825, 44.16444],
					[-116.1183, 44.16453],
					[-116.11834, 44.16462],
					[-116.11835, 44.16463],
					[-116.11844, 44.16479],
					[-116.1186, 44.16503],
					[-116.11876, 44.16522],
					[-116.11881, 44.16526],
					[-116.11881, 44.16527],
					[-116.11922, 44.16567],
					[-116.1195, 44.16592],
					[-116.11964, 44.16606],
					[-116.11972, 44.16613],
					[-116.12012, 44.16658],
					[-116.12019, 44.16668],
					[-116.12024, 44.16674],
					[-116.12024, 44.16675],
					[-116.1203, 44.16683],
					[-116.1205, 44.16716],
					[-116.12062, 44.16738],
					[-116.12067, 44.1675],
					[-116.12072, 44.16759],
					[-116.12076, 44.1677],
					[-116.12078, 44.16774],
					[-116.12081, 44.16784],
					[-116.12084, 44.16792],
					[-116.12085, 44.16792],
					[-116.12087, 44.16798],
					[-116.12089, 44.16806],
					[-116.1209, 44.16812],
					[-116.12092, 44.1682],
					[-116.12094, 44.16835],
					[-116.12094, 44.16844],
					[-116.12095, 44.16852],
					[-116.12095, 44.16864],
					[-116.12096, 44.16871],
					[-116.12095, 44.16881],
					[-116.12095, 44.16917],
					[-116.12093, 44.16935],
					[-116.12091, 44.16945],
					[-116.1209, 44.16953],
					[-116.1209, 44.16957],
					[-116.12088, 44.1697],
					[-116.12088, 44.16971],
					[-116.12057, 44.17112],
					[-116.12054, 44.17131],
					[-116.12048, 44.17158],
					[-116.12027, 44.17273],
					[-116.12027, 44.17275],
					[-116.12008, 44.17373],
					[-116.12, 44.17401],
					[-116.11998, 44.17412],
					[-116.11988, 44.17447],
					[-116.11987, 44.17447],
					[-116.11981, 44.17464],
					[-116.11973, 44.17481],
					[-116.11967, 44.17496],
					[-116.11965, 44.17498],
					[-116.11958, 44.17514],
					[-116.11941, 44.17543],
					[-116.11938, 44.17549],
					[-116.11928, 44.17565],
					[-116.11905, 44.17592],
					[-116.11896, 44.176],
					[-116.11896, 44.17601],
					[-116.11873, 44.1762],
					[-116.11845, 44.17641],
					[-116.11843, 44.17643],
					[-116.11807, 44.17667],
					[-116.11773, 44.17687],
					[-116.1175, 44.17699],
					[-116.11749, 44.17699],
					[-116.11741, 44.17703],
					[-116.11729, 44.17708],
					[-116.11728, 44.17709],
					[-116.11689, 44.17724],
					[-116.11662, 44.17733],
					[-116.11659, 44.17733],
					[-116.11641, 44.17739],
					[-116.11639, 44.1774],
					[-116.11615, 44.17746],
					[-116.11581, 44.17756],
					[-116.11541, 44.1777],
					[-116.11516, 44.17783],
					[-116.11507, 44.1779],
					[-116.11506, 44.1779],
					[-116.11501, 44.17794],
					[-116.11491, 44.17804],
					[-116.11486, 44.1781],
					[-116.11482, 44.17816],
					[-116.11476, 44.1783],
					[-116.11472, 44.17844],
					[-116.11471, 44.17851],
					[-116.11471, 44.17856],
					[-116.1147, 44.17864],
					[-116.11471, 44.1787],
					[-116.11471, 44.17877],
					[-116.11472, 44.17883],
					[-116.11475, 44.17892],
					[-116.11476, 44.17897],
					[-116.11483, 44.17912],
					[-116.11488, 44.17919],
					[-116.11492, 44.17927],
					[-116.11503, 44.1794],
					[-116.11594, 44.18036],
					[-116.11615, 44.1806],
					[-116.11625, 44.1807],
					[-116.11645, 44.18093],
					[-116.1165, 44.18101],
					[-116.11654, 44.18106],
					[-116.11661, 44.18117],
					[-116.11674, 44.18141],
					[-116.1168, 44.18162],
					[-116.11682, 44.18167],
					[-116.11683, 44.18173],
					[-116.11683, 44.18174],
					[-116.11686, 44.1819],
					[-116.11686, 44.18228],
					[-116.11684, 44.18236],
					[-116.11683, 44.18244],
					[-116.11682, 44.18246],
					[-116.11678, 44.18258],
					[-116.11675, 44.18263],
					[-116.11673, 44.18269],
					[-116.11668, 44.1828],
					[-116.11658, 44.18297],
					[-116.11657, 44.183],
					[-116.11649, 44.18311],
					[-116.11605, 44.18358],
					[-116.11565, 44.18404],
					[-116.11555, 44.18414],
					[-116.11537, 44.18441],
					[-116.11529, 44.18458],
					[-116.11525, 44.1847],
					[-116.1152, 44.18482],
					[-116.11514, 44.18507],
					[-116.11506, 44.18548],
					[-116.11505, 44.18558],
					[-116.11472, 44.18761],
					[-116.11471, 44.18772],
					[-116.11471, 44.18783],
					[-116.11472, 44.1879],
					[-116.11473, 44.18808],
					[-116.11477, 44.18826],
					[-116.11488, 44.18865],
					[-116.11493, 44.18879],
					[-116.11503, 44.18917],
					[-116.11504, 44.18929],
					[-116.11504, 44.18936],
					[-116.11506, 44.18965],
					[-116.11506, 44.18984],
					[-116.11504, 44.19003],
					[-116.11502, 44.1901],
					[-116.11502, 44.19012],
					[-116.11498, 44.19023],
					[-116.11497, 44.19028],
					[-116.1149, 44.19044],
					[-116.1149, 44.19045],
					[-116.11479, 44.19068],
					[-116.1147, 44.19081],
					[-116.11408, 44.19156],
					[-116.11394, 44.19171],
					[-116.11355, 44.19217],
					[-116.11353, 44.19221],
					[-116.11342, 44.19233],
					[-116.11329, 44.19249],
					[-116.11289, 44.19294],
					[-116.11266, 44.19323],
					[-116.11264, 44.19325],
					[-116.11247, 44.1935],
					[-116.11237, 44.19368],
					[-116.11225, 44.19397],
					[-116.11223, 44.19406],
					[-116.11222, 44.19414],
					[-116.11222, 44.19424],
					[-116.11224, 44.19449],
					[-116.11225, 44.19456],
					[-116.11225, 44.1946],
					[-116.11227, 44.19466],
					[-116.11228, 44.19472],
					[-116.11235, 44.19491],
					[-116.11236, 44.19492],
					[-116.11239, 44.195],
					[-116.11243, 44.19508],
					[-116.11263, 44.19537],
					[-116.11266, 44.1954],
					[-116.1129, 44.19571],
					[-116.11291, 44.19571],
					[-116.11294, 44.19575],
					[-116.1131, 44.19593],
					[-116.11325, 44.19612],
					[-116.11334, 44.19625],
					[-116.11346, 44.19648],
					[-116.11348, 44.19651],
					[-116.11352, 44.19661],
					[-116.11356, 44.19668],
					[-116.11358, 44.19675],
					[-116.1136, 44.1968],
					[-116.11361, 44.19685],
					[-116.11363, 44.19702],
					[-116.11363, 44.19709],
					[-116.11364, 44.19719],
					[-116.11364, 44.19727],
					[-116.11363, 44.19736],
					[-116.11363, 44.19741],
					[-116.1136, 44.19754],
					[-116.11353, 44.19775],
					[-116.11351, 44.19778],
					[-116.1135, 44.19781],
					[-116.11346, 44.19789],
					[-116.11341, 44.19797],
					[-116.11331, 44.19811],
					[-116.11326, 44.19817],
					[-116.11319, 44.19827],
					[-116.1131, 44.19838],
					[-116.11306, 44.19842],
					[-116.11302, 44.19847],
					[-116.11289, 44.19861],
					[-116.11282, 44.1987],
					[-116.11279, 44.19873],
					[-116.11264, 44.19891],
					[-116.11245, 44.19917],
					[-116.1124, 44.19923],
					[-116.11234, 44.19933],
					[-116.11229, 44.1994],
					[-116.11228, 44.19943],
					[-116.11224, 44.19949],
					[-116.11205, 44.19982],
					[-116.11204, 44.19985],
					[-116.11187, 44.20016],
					[-116.11184, 44.20023],
					[-116.11175, 44.2004],
					[-116.11169, 44.2005],
					[-116.11159, 44.20069],
					[-116.1115, 44.20083],
					[-116.11135, 44.20111],
					[-116.11131, 44.20116],
					[-116.11121, 44.20133],
					[-116.11055, 44.20225],
					[-116.11053, 44.20229],
					[-116.11047, 44.20238],
					[-116.11041, 44.20245],
					[-116.10995, 44.20309],
					[-116.10971, 44.2034],
					[-116.10958, 44.2036],
					[-116.10956, 44.20364],
					[-116.10948, 44.20376],
					[-116.10942, 44.20387],
					[-116.10938, 44.20398],
					[-116.10936, 44.20406],
					[-116.10934, 44.20425],
					[-116.10935, 44.20439],
					[-116.10936, 44.20443],
					[-116.10937, 44.20451],
					[-116.10942, 44.20469],
					[-116.10942, 44.2047],
					[-116.10948, 44.20487],
					[-116.10954, 44.205],
					[-116.10963, 44.20523],
					[-116.10968, 44.20545],
					[-116.10968, 44.20556],
					[-116.10967, 44.20566],
					[-116.10965, 44.20577],
					[-116.10961, 44.20587],
					[-116.10953, 44.206],
					[-116.10953, 44.20601],
					[-116.10933, 44.20633],
					[-116.10924, 44.20646],
					[-116.10922, 44.2065],
					[-116.10899, 44.20682],
					[-116.10877, 44.20706],
					[-116.10867, 44.20719],
					[-116.10843, 44.20754],
					[-116.10839, 44.20761],
					[-116.10831, 44.20772],
					[-116.10823, 44.20785],
					[-116.10788, 44.20831],
					[-116.10782, 44.20841],
					[-116.10743, 44.20896],
					[-116.10729, 44.20922],
					[-116.10728, 44.20923],
					[-116.10697, 44.21],
					[-116.10695, 44.21009],
					[-116.10693, 44.21013],
					[-116.10683, 44.21048],
					[-116.10675, 44.21104],
					[-116.10675, 44.21151],
					[-116.10676, 44.21156],
					[-116.10676, 44.21175],
					[-116.10677, 44.21181],
					[-116.10677, 44.21188],
					[-116.1068, 44.21205],
					[-116.10686, 44.21229],
					[-116.10687, 44.21229],
					[-116.10689, 44.21239],
					[-116.10708, 44.213],
					[-116.10714, 44.21314],
					[-116.10725, 44.21349],
					[-116.1073, 44.21361],
					[-116.10752, 44.21423],
					[-116.10759, 44.21439],
					[-116.10761, 44.21446],
					[-116.10765, 44.21456],
					[-116.10768, 44.21467],
					[-116.1077, 44.21471],
					[-116.10784, 44.21514],
					[-116.10786, 44.21527],
					[-116.10786, 44.21528],
					[-116.10788, 44.21536],
					[-116.1079, 44.21556],
					[-116.1079, 44.21563],
					[-116.10791, 44.21567],
					[-116.10791, 44.21616],
					[-116.1079, 44.21622],
					[-116.10789, 44.21634],
					[-116.10784, 44.2167],
					[-116.10783, 44.21672],
					[-116.10778, 44.21703],
					[-116.10777, 44.21706],
					[-116.10776, 44.21717],
					[-116.10774, 44.21724],
					[-116.10768, 44.21756],
					[-116.10768, 44.21757],
					[-116.10756, 44.2181],
					[-116.10754, 44.21816],
					[-116.10752, 44.2183],
					[-116.10741, 44.21881],
					[-116.10741, 44.21884],
					[-116.10733, 44.21919],
					[-116.10733, 44.2192],
					[-116.10724, 44.2197],
					[-116.10723, 44.21973],
					[-116.10715, 44.22024],
					[-116.10711, 44.22044],
					[-116.1071, 44.22052],
					[-116.10699, 44.22113],
					[-116.10699, 44.22116],
					[-116.10692, 44.22149],
					[-116.10692, 44.22151],
					[-116.10685, 44.22187],
					[-116.10683, 44.22202],
					[-116.10682, 44.22205],
					[-116.10673, 44.22256],
					[-116.10672, 44.22258],
					[-116.10668, 44.2228],
					[-116.10659, 44.2231],
					[-116.10653, 44.22326],
					[-116.10651, 44.22329],
					[-116.10646, 44.22341],
					[-116.10637, 44.22359],
					[-116.10636, 44.22363],
					[-116.10627, 44.22379],
					[-116.10611, 44.22402],
					[-116.10605, 44.22412],
					[-116.10597, 44.22422],
					[-116.10588, 44.22432],
					[-116.10582, 44.2244],
					[-116.10571, 44.22453],
					[-116.10566, 44.22458],
					[-116.10562, 44.22463],
					[-116.10551, 44.22475],
					[-116.10539, 44.22486],
					[-116.10537, 44.22487],
					[-116.10513, 44.22507],
					[-116.10486, 44.22527],
					[-116.10455, 44.22552],
					[-116.10421, 44.22577],
					[-116.10408, 44.2259],
					[-116.10403, 44.22596],
					[-116.10393, 44.22611],
					[-116.10392, 44.22612],
					[-116.10388, 44.22622],
					[-116.10387, 44.22626],
					[-116.10385, 44.2263],
					[-116.1038, 44.22643],
					[-116.10378, 44.22654],
					[-116.10375, 44.22663],
					[-116.10375, 44.22667],
					[-116.10373, 44.22678],
					[-116.10372, 44.22681],
					[-116.10367, 44.22749],
					[-116.10358, 44.22923],
					[-116.10359, 44.22933],
					[-116.10361, 44.23014],
					[-116.10363, 44.23041],
					[-116.10363, 44.23059],
					[-116.10372, 44.23239],
					[-116.10375, 44.23272],
					[-116.10382, 44.23399],
					[-116.10382, 44.23418],
					[-116.10383, 44.2343],
					[-116.10382, 44.23433],
					[-116.10382, 44.2349],
					[-116.10381, 44.23508],
					[-116.10381, 44.23541],
					[-116.1038, 44.23544],
					[-116.1038, 44.23555],
					[-116.10378, 44.23577],
					[-116.10373, 44.23613],
					[-116.10372, 44.23616],
					[-116.10369, 44.23634],
					[-116.10361, 44.2367],
					[-116.10348, 44.23715],
					[-116.10341, 44.23734],
					[-116.10341, 44.23737],
					[-116.1034, 44.23739],
					[-116.10325, 44.23782],
					[-116.10302, 44.23839],
					[-116.10291, 44.2387],
					[-116.10285, 44.23896],
					[-116.10283, 44.23908],
					[-116.1028, 44.23948],
					[-116.1028, 44.23964],
					[-116.10282, 44.23977],
					[-116.10294, 44.24103],
					[-116.10296, 44.24135],
					[-116.10297, 44.24139],
					[-116.103, 44.24199],
					[-116.10308, 44.24301],
					[-116.10316, 44.24381],
					[-116.10316, 44.24395],
					[-116.10315, 44.24397],
					[-116.10315, 44.24399],
					[-116.10311, 44.24408],
					[-116.103, 44.24423],
					[-116.10293, 44.2443],
					[-116.10286, 44.24438],
					[-116.10259, 44.2446],
					[-116.1025, 44.24465],
					[-116.10241, 44.24469],
					[-116.10235, 44.24471],
					[-116.10234, 44.24472],
					[-116.10201, 44.24481],
					[-116.10167, 44.24486],
					[-116.10145, 44.24486],
					[-116.10114, 44.2448],
					[-116.10066, 44.24466],
					[-116.09999, 44.24444],
					[-116.09997, 44.24443],
					[-116.09972, 44.24437],
					[-116.09934, 44.24433],
					[-116.0992, 44.24433],
					[-116.09901, 44.24435],
					[-116.09893, 44.24435],
					[-116.09884, 44.24436],
					[-116.09873, 44.24438],
					[-116.09863, 44.24441],
					[-116.09852, 44.24443],
					[-116.09822, 44.24454],
					[-116.09811, 44.24459],
					[-116.09808, 44.2446],
					[-116.09666, 44.2454],
					[-116.09653, 44.24548],
					[-116.0964, 44.24555],
					[-116.09623, 44.24566],
					[-116.09614, 44.24574],
					[-116.09612, 44.24575],
					[-116.09603, 44.24584],
					[-116.0959, 44.24601],
					[-116.09579, 44.24623],
					[-116.09563, 44.24661],
					[-116.09559, 44.24673],
					[-116.09546, 44.24698],
					[-116.0954, 44.24707],
					[-116.0953, 44.24719],
					[-116.09528, 44.24722],
					[-116.0952, 44.24731],
					[-116.09513, 44.24737],
					[-116.09507, 44.24743],
					[-116.09497, 44.24751],
					[-116.09458, 44.24776],
					[-116.09442, 44.24785],
					[-116.09427, 44.24792],
					[-116.09414, 44.24799],
					[-116.09406, 44.24804],
					[-116.09398, 44.2481],
					[-116.09393, 44.24815],
					[-116.09387, 44.2482],
					[-116.0938, 44.2483],
					[-116.09369, 44.24851],
					[-116.09366, 44.24858],
					[-116.09362, 44.24871],
					[-116.0936, 44.24881],
					[-116.09354, 44.24927],
					[-116.09352, 44.24934],
					[-116.09351, 44.2494],
					[-116.09348, 44.2495],
					[-116.0934, 44.24971],
					[-116.09336, 44.24979],
					[-116.09335, 44.24982],
					[-116.09321, 44.25003],
					[-116.09304, 44.25025],
					[-116.09287, 44.25041],
					[-116.09286, 44.25041],
					[-116.09269, 44.25055],
					[-116.0924, 44.25074],
					[-116.09215, 44.25086],
					[-116.0918, 44.25099],
					[-116.09152, 44.25106],
					[-116.09141, 44.25108],
					[-116.09137, 44.25108],
					[-116.0912, 44.2511],
					[-116.09116, 44.2511],
					[-116.09108, 44.25111],
					[-116.09095, 44.2511],
					[-116.09082, 44.2511],
					[-116.09068, 44.25109],
					[-116.09066, 44.25108],
					[-116.09056, 44.25107],
					[-116.09046, 44.25105],
					[-116.09037, 44.25104],
					[-116.09018, 44.25098],
					[-116.09016, 44.25098],
					[-116.08974, 44.25081],
					[-116.0897, 44.2508],
					[-116.08948, 44.25072],
					[-116.08938, 44.25069],
					[-116.0893, 44.25066],
					[-116.08921, 44.25065],
					[-116.08911, 44.25063],
					[-116.08901, 44.25062],
					[-116.08878, 44.25062],
					[-116.08855, 44.25066],
					[-116.08854, 44.25066],
					[-116.08845, 44.25068],
					[-116.08833, 44.25072],
					[-116.08831, 44.25073],
					[-116.08821, 44.25076],
					[-116.08812, 44.25081],
					[-116.08809, 44.25082],
					[-116.08802, 44.25086],
					[-116.08794, 44.25092],
					[-116.08779, 44.25108],
					[-116.0877, 44.25119],
					[-116.08718, 44.25189],
					[-116.08621, 44.25331],
					[-116.08613, 44.25344],
					[-116.08608, 44.2535],
					[-116.08581, 44.2539],
					[-116.08567, 44.25408],
					[-116.08566, 44.2541],
					[-116.08562, 44.25414],
					[-116.08559, 44.25418],
					[-116.08547, 44.25429],
					[-116.08545, 44.25432],
					[-116.08543, 44.25433],
					[-116.0853, 44.25442],
					[-116.08529, 44.25442],
					[-116.08523, 44.25446],
					[-116.08511, 44.2545],
					[-116.085, 44.25455],
					[-116.08464, 44.25468],
					[-116.08454, 44.25471],
					[-116.08422, 44.25484],
					[-116.08396, 44.25496],
					[-116.08383, 44.25503],
					[-116.08354, 44.25521],
					[-116.08331, 44.25539],
					[-116.0831, 44.2556],
					[-116.083, 44.25569],
					[-116.08286, 44.25587],
					[-116.08285, 44.25589],
					[-116.08282, 44.25593],
					[-116.08279, 44.25598],
					[-116.08275, 44.25606],
					[-116.08274, 44.25607],
					[-116.08258, 44.25647],
					[-116.08247, 44.25681],
					[-116.08242, 44.25692],
					[-116.08242, 44.25694],
					[-116.08235, 44.25709],
					[-116.08235, 44.2571],
					[-116.08215, 44.2575],
					[-116.08202, 44.25772],
					[-116.08197, 44.25782],
					[-116.08189, 44.25793],
					[-116.08182, 44.25805],
					[-116.08174, 44.25817],
					[-116.08164, 44.2583],
					[-116.081, 44.25902],
					[-116.0809, 44.25912],
					[-116.08087, 44.25917],
					[-116.08082, 44.25923],
					[-116.08078, 44.25929],
					[-116.08067, 44.25949],
					[-116.08046, 44.25994],
					[-116.08026, 44.26043],
					[-116.08022, 44.26055],
					[-116.08016, 44.2607],
					[-116.08007, 44.26087],
					[-116.08007, 44.26088],
					[-116.08004, 44.26094],
					[-116.08, 44.26099],
					[-116.07997, 44.26104],
					[-116.07988, 44.26116],
					[-116.07985, 44.26119],
					[-116.07984, 44.26121],
					[-116.07982, 44.26124],
					[-116.07975, 44.26131],
					[-116.07972, 44.26135],
					[-116.07964, 44.26143],
					[-116.07938, 44.26164],
					[-116.07882, 44.262],
					[-116.07845, 44.26221],
					[-116.07834, 44.26228],
					[-116.07769, 44.26261],
					[-116.07763, 44.26263],
					[-116.07688, 44.26296],
					[-116.07666, 44.26304],
					[-116.07642, 44.2631],
					[-116.0763, 44.26312],
					[-116.07628, 44.26312],
					[-116.07614, 44.26315],
					[-116.07601, 44.26317],
					[-116.07588, 44.26318],
					[-116.07573, 44.2632],
					[-116.0754, 44.2632],
					[-116.07478, 44.26317],
					[-116.07442, 44.26313],
					[-116.07416, 44.26309],
					[-116.07385, 44.26302],
					[-116.07383, 44.26302],
					[-116.07361, 44.26296],
					[-116.07359, 44.26295],
					[-116.07299, 44.26276],
					[-116.07251, 44.26256],
					[-116.07247, 44.26255],
					[-116.07227, 44.26244],
					[-116.07216, 44.26239],
					[-116.07159, 44.26203],
					[-116.0715, 44.26198],
					[-116.07114, 44.26175],
					[-116.07078, 44.26154],
					[-116.07064, 44.26147],
					[-116.07047, 44.2614],
					[-116.07023, 44.26135],
					[-116.0701, 44.26134],
					[-116.06976, 44.26136],
					[-116.06963, 44.26138],
					[-116.06938, 44.26144],
					[-116.06905, 44.26157],
					[-116.06864, 44.26179],
					[-116.06815, 44.26215],
					[-116.06811, 44.26219],
					[-116.06795, 44.26232],
					[-116.06783, 44.26243],
					[-116.06758, 44.2627],
					[-116.0674, 44.26294],
					[-116.06734, 44.26304],
					[-116.06721, 44.26329],
					[-116.06717, 44.26339],
					[-116.06712, 44.26357],
					[-116.0671, 44.26361],
					[-116.06704, 44.26385],
					[-116.06703, 44.26392],
					[-116.06703, 44.26394],
					[-116.06701, 44.26402],
					[-116.06701, 44.26427],
					[-116.06702, 44.26435],
					[-116.06704, 44.26441],
					[-116.06705, 44.26446],
					[-116.06707, 44.26452],
					[-116.06713, 44.26463],
					[-116.06725, 44.26477],
					[-116.06736, 44.26488],
					[-116.06742, 44.26492],
					[-116.06743, 44.26493],
					[-116.0675, 44.26497],
					[-116.06757, 44.265],
					[-116.06761, 44.26503],
					[-116.06776, 44.26509],
					[-116.06828, 44.26523],
					[-116.06874, 44.26531],
					[-116.06885, 44.26532],
					[-116.06893, 44.26534],
					[-116.06907, 44.26536],
					[-116.06925, 44.26541],
					[-116.06943, 44.26545],
					[-116.06959, 44.2655],
					[-116.06975, 44.26556],
					[-116.07003, 44.26568],
					[-116.07015, 44.26574],
					[-116.07047, 44.26593],
					[-116.07069, 44.2661],
					[-116.07077, 44.26617],
					[-116.07095, 44.26637],
					[-116.07103, 44.26647],
					[-116.07108, 44.26655],
					[-116.07117, 44.26667],
					[-116.07121, 44.26675],
					[-116.07253, 44.26902],
					[-116.07268, 44.26926],
					[-116.07343, 44.27064],
					[-116.0737, 44.2711],
					[-116.07379, 44.27123],
					[-116.07386, 44.27129],
					[-116.07391, 44.27135],
					[-116.07413, 44.27153],
					[-116.07439, 44.27172],
					[-116.07545, 44.27232],
					[-116.07553, 44.27238],
					[-116.07568, 44.27247],
					[-116.07576, 44.27254],
					[-116.07581, 44.27257],
					[-116.07586, 44.27261],
					[-116.07591, 44.27266],
					[-116.07597, 44.27271],
					[-116.07602, 44.27276],
					[-116.07608, 44.2728],
					[-116.07614, 44.27286],
					[-116.07625, 44.273],
					[-116.07631, 44.27306],
					[-116.07646, 44.27328],
					[-116.07652, 44.27339],
					[-116.07659, 44.2735],
					[-116.07711, 44.27457],
					[-116.07717, 44.27471],
					[-116.07736, 44.27527],
					[-116.0775, 44.27581],
					[-116.07751, 44.2759],
					[-116.07754, 44.27604],
					[-116.07778, 44.27747],
					[-116.07784, 44.27771],
					[-116.07792, 44.27797],
					[-116.07797, 44.27809],
					[-116.07801, 44.27817],
					[-116.07805, 44.27827],
					[-116.07828, 44.2787],
					[-116.07897, 44.27988],
					[-116.07897, 44.27989],
					[-116.07972, 44.28125],
					[-116.08, 44.28171],
					[-116.08019, 44.28206],
					[-116.08025, 44.28215],
					[-116.0803, 44.28225],
					[-116.08035, 44.28233],
					[-116.0804, 44.28244],
					[-116.08045, 44.28253],
					[-116.08055, 44.28279],
					[-116.08076, 44.28356],
					[-116.08081, 44.28384],
					[-116.08081, 44.28389],
					[-116.08082, 44.28396],
					[-116.08082, 44.28403],
					[-116.08085, 44.28438],
					[-116.08085, 44.28458],
					[-116.08084, 44.2847],
					[-116.08084, 44.28479],
					[-116.08083, 44.28484],
					[-116.08083, 44.28487],
					[-116.08082, 44.28497],
					[-116.08079, 44.28515],
					[-116.08078, 44.28526],
					[-116.08064, 44.28583],
					[-116.08064, 44.28585],
					[-116.08053, 44.28632],
					[-116.08053, 44.28635],
					[-116.08051, 44.28647],
					[-116.0805, 44.2865],
					[-116.08042, 44.28698],
					[-116.0804, 44.28726],
					[-116.08044, 44.28762],
					[-116.0805, 44.28793],
					[-116.08068, 44.28856],
					[-116.0808, 44.28891],
					[-116.08119, 44.2899],
					[-116.08132, 44.29019],
					[-116.08167, 44.29085],
					[-116.08167, 44.29086],
					[-116.08254, 44.29253],
					[-116.08334, 44.294],
					[-116.08373, 44.29464],
					[-116.0839, 44.29487],
					[-116.08395, 44.29495],
					[-116.0845, 44.29569],
					[-116.08481, 44.29605],
					[-116.08519, 44.29641],
					[-116.0855, 44.29662],
					[-116.08561, 44.29668],
					[-116.0857, 44.29674],
					[-116.08595, 44.29686],
					[-116.08651, 44.29709],
					[-116.08752, 44.29742],
					[-116.08761, 44.29746],
					[-116.0877, 44.29749],
					[-116.08781, 44.29754],
					[-116.08794, 44.29761],
					[-116.08805, 44.29768],
					[-116.08832, 44.29788],
					[-116.08847, 44.29802],
					[-116.08861, 44.29817],
					[-116.08876, 44.29837],
					[-116.08884, 44.29851],
					[-116.08896, 44.29881],
					[-116.08901, 44.299],
					[-116.08908, 44.29952],
					[-116.08913, 44.30021],
					[-116.08913, 44.30045],
					[-116.08911, 44.30079],
					[-116.08906, 44.30115],
					[-116.08896, 44.30161],
					[-116.08877, 44.30215],
					[-116.08861, 44.30251],
					[-116.08803, 44.30354],
					[-116.08803, 44.30355],
					[-116.08786, 44.30388],
					[-116.08778, 44.30406],
					[-116.08767, 44.30435],
					[-116.08754, 44.30485],
					[-116.0875, 44.30509],
					[-116.08748, 44.3053],
					[-116.08747, 44.30553],
					[-116.08748, 44.30599],
					[-116.0875, 44.30626],
					[-116.08753, 44.30649],
					[-116.08754, 44.30675],
					[-116.08758, 44.30723],
					[-116.08758, 44.30733],
					[-116.08756, 44.30753],
					[-116.08751, 44.30779],
					[-116.0874, 44.30814],
					[-116.08731, 44.30835],
					[-116.08709, 44.30873],
					[-116.08585, 44.31071],
					[-116.08569, 44.31093],
					[-116.08539, 44.31126],
					[-116.08524, 44.31141],
					[-116.08511, 44.31152],
					[-116.08495, 44.31164],
					[-116.0846, 44.31188],
					[-116.08436, 44.31203],
					[-116.08396, 44.31224],
					[-116.08357, 44.3124],
					[-116.08315, 44.31254],
					[-116.08266, 44.31267],
					[-116.08246, 44.31271],
					[-116.08207, 44.31277],
					[-116.082, 44.31277],
					[-116.08152, 44.31281],
					[-116.0814, 44.31281],
					[-116.08125, 44.31282],
					[-116.08111, 44.31282],
					[-116.08094, 44.31281],
					[-116.08079, 44.31281],
					[-116.0805, 44.31279],
					[-116.08035, 44.31277],
					[-116.08011, 44.31275],
					[-116.08003, 44.31273],
					[-116.07987, 44.31271],
					[-116.07939, 44.31258],
					[-116.07938, 44.31258],
					[-116.07895, 44.31245],
					[-116.07828, 44.31222],
					[-116.07815, 44.31219],
					[-116.07794, 44.31213],
					[-116.07788, 44.31212],
					[-116.07785, 44.31211],
					[-116.07773, 44.31209],
					[-116.07766, 44.31209],
					[-116.0776, 44.31208],
					[-116.07751, 44.31208],
					[-116.07746, 44.31207],
					[-116.07742, 44.31208],
					[-116.0773, 44.31208],
					[-116.07721, 44.3121],
					[-116.07718, 44.31211],
					[-116.07713, 44.31212],
					[-116.07711, 44.31213],
					[-116.07697, 44.31218],
					[-116.0768, 44.31227],
					[-116.07675, 44.31229],
					[-116.0767, 44.31232],
					[-116.0766, 44.31236],
					[-116.07657, 44.31238],
					[-116.0765, 44.31241],
					[-116.07616, 44.31253],
					[-116.07605, 44.31256],
					[-116.07597, 44.31259],
					[-116.07579, 44.31264],
					[-116.07555, 44.31268],
					[-116.07517, 44.31272],
					[-116.07471, 44.31275],
					[-116.07457, 44.31275],
					[-116.07443, 44.31276],
					[-116.0743, 44.31278],
					[-116.07417, 44.31279],
					[-116.07322, 44.31298],
					[-116.07259, 44.31309],
					[-116.07233, 44.31315],
					[-116.07205, 44.31328],
					[-116.07165, 44.31356],
					[-116.07154, 44.31365],
					[-116.07147, 44.3137],
					[-116.0712, 44.31387],
					[-116.07107, 44.31393],
					[-116.07076, 44.31405],
					[-116.07068, 44.31407],
					[-116.07032, 44.31421],
					[-116.07025, 44.31426],
					[-116.07024, 44.31426],
					[-116.07012, 44.31434],
					[-116.07006, 44.31439],
					[-116.06996, 44.31449],
					[-116.06981, 44.31468],
					[-116.06974, 44.3148],
					[-116.06968, 44.31494],
					[-116.06946, 44.31558],
					[-116.06943, 44.31563],
					[-116.0694, 44.3157],
					[-116.06936, 44.31576],
					[-116.06933, 44.31582],
					[-116.06917, 44.31602],
					[-116.06911, 44.31608],
					[-116.06901, 44.31616],
					[-116.06893, 44.31621],
					[-116.06885, 44.31627],
					[-116.06842, 44.31651],
					[-116.06814, 44.31665],
					[-116.06806, 44.3167],
					[-116.06801, 44.31674],
					[-116.06796, 44.31679],
					[-116.06782, 44.31696],
					[-116.06759, 44.31732],
					[-116.06756, 44.31735],
					[-116.06746, 44.31743],
					[-116.0674, 44.31747],
					[-116.06732, 44.31751],
					[-116.06729, 44.31753],
					[-116.0671, 44.3176],
					[-116.06672, 44.31767],
					[-116.06654, 44.31767],
					[-116.06638, 44.31766],
					[-116.06584, 44.3176],
					[-116.06544, 44.31757],
					[-116.06526, 44.31757],
					[-116.06492, 44.3176],
					[-116.06482, 44.31762],
					[-116.06455, 44.31765],
					[-116.06429, 44.31771],
					[-116.06327, 44.31808],
					[-116.0631, 44.31816],
					[-116.06302, 44.31819],
					[-116.0629, 44.31825],
					[-116.06282, 44.31831],
					[-116.0628, 44.31832],
					[-116.06196, 44.31893],
					[-116.06194, 44.31895],
					[-116.06162, 44.3192],
					[-116.0615, 44.31935],
					[-116.06142, 44.31953],
					[-116.0614, 44.3196],
					[-116.06135, 44.31991],
					[-116.06132, 44.3207],
					[-116.06131, 44.32079],
					[-116.06131, 44.32085],
					[-116.0613, 44.32092],
					[-116.06128, 44.32098],
					[-116.06128, 44.321],
					[-116.06121, 44.32116],
					[-116.0611, 44.32135],
					[-116.06095, 44.32155],
					[-116.06071, 44.32182],
					[-116.06055, 44.32204],
					[-116.06052, 44.32211],
					[-116.06046, 44.32222],
					[-116.06038, 44.32243],
					[-116.06038, 44.32246],
					[-116.06031, 44.32271],
					[-116.06025, 44.32303],
					[-116.06023, 44.32323],
					[-116.06023, 44.32356],
					[-116.06027, 44.32384],
					[-116.06027, 44.32389],
					[-116.0603, 44.32406],
					[-116.0603, 44.32411],
					[-116.06033, 44.32431],
					[-116.06033, 44.32449],
					[-116.06032, 44.32451],
					[-116.06032, 44.32453],
					[-116.06031, 44.32455],
					[-116.06031, 44.32456],
					[-116.0603, 44.32458],
					[-116.06029, 44.32459],
					[-116.06029, 44.32461],
					[-116.06027, 44.32463],
					[-116.06026, 44.32465],
					[-116.06024, 44.32467],
					[-116.06022, 44.3247],
					[-116.06013, 44.32479],
					[-116.06009, 44.32482],
					[-116.06005, 44.32486],
					[-116.05992, 44.32495],
					[-116.05972, 44.32507],
					[-116.05945, 44.32521],
					[-116.05908, 44.32543],
					[-116.05887, 44.32557],
					[-116.05882, 44.32561],
					[-116.05875, 44.3257],
					[-116.0587, 44.32578],
					[-116.05853, 44.32616],
					[-116.05851, 44.32622],
					[-116.05846, 44.32633],
					[-116.05827, 44.32684],
					[-116.05821, 44.3272],
					[-116.05821, 44.32745],
					[-116.05824, 44.32764],
					[-116.05827, 44.32775],
					[-116.05838, 44.32826],
					[-116.05838, 44.32834],
					[-116.05836, 44.32855],
					[-116.05834, 44.32863],
					[-116.05833, 44.32864],
					[-116.05824, 44.32883],
					[-116.05816, 44.32894],
					[-116.05805, 44.32907],
					[-116.05712, 44.33001],
					[-116.05694, 44.33022],
					[-116.05689, 44.33029],
					[-116.05677, 44.3305],
					[-116.05673, 44.33061],
					[-116.05671, 44.33065],
					[-116.05668, 44.33075],
					[-116.05668, 44.33079],
					[-116.05667, 44.33084],
					[-116.05667, 44.33091],
					[-116.05668, 44.33097],
					[-116.05668, 44.33099],
					[-116.05669, 44.33106],
					[-116.05669, 44.33111],
					[-116.05671, 44.33119],
					[-116.05675, 44.33131],
					[-116.05705, 44.33179],
					[-116.05708, 44.33188],
					[-116.05709, 44.33194],
					[-116.05711, 44.33215],
					[-116.05708, 44.33234],
					[-116.05703, 44.33246],
					[-116.05698, 44.33256],
					[-116.05694, 44.33262],
					[-116.05692, 44.33266],
					[-116.05689, 44.33269],
					[-116.05686, 44.33273],
					[-116.05673, 44.33285],
					[-116.056, 44.3334],
					[-116.05583, 44.33351],
					[-116.0557, 44.33358],
					[-116.05559, 44.33365],
					[-116.05542, 44.33373],
					[-116.05503, 44.33389],
					[-116.05482, 44.334],
					[-116.05407, 44.33434],
					[-116.05405, 44.33434],
					[-116.05402, 44.33436],
					[-116.05396, 44.33438],
					[-116.05382, 44.33441],
					[-116.0538, 44.33441],
					[-116.05375, 44.33442],
					[-116.05369, 44.33442],
					[-116.05362, 44.33443],
					[-116.05355, 44.33443],
					[-116.05349, 44.33442],
					[-116.05344, 44.33442],
					[-116.05324, 44.3344],
					[-116.05308, 44.33437],
					[-116.05147, 44.33416],
					[-116.05134, 44.33415],
					[-116.05121, 44.33413],
					[-116.05092, 44.33411],
					[-116.05064, 44.33411],
					[-116.05049, 44.33412],
					[-116.0504, 44.33412],
					[-116.05023, 44.33414],
					[-116.05015, 44.33416],
					[-116.04993, 44.3342],
					[-116.04992, 44.33421],
					[-116.04963, 44.33428],
					[-116.04871, 44.33456],
					[-116.04848, 44.33465],
					[-116.0484, 44.33469],
					[-116.04788, 44.33501],
					[-116.0478, 44.33505],
					[-116.04761, 44.33513],
					[-116.04724, 44.33522],
					[-116.04706, 44.33524],
					[-116.04687, 44.33525],
					[-116.04653, 44.33525],
					[-116.04601, 44.33523],
					[-116.04542, 44.33527],
					[-116.04489, 44.33533],
					[-116.04353, 44.33555],
					[-116.04336, 44.33557],
					[-116.04308, 44.33559],
					[-116.04295, 44.33559],
					[-116.0427, 44.33557],
					[-116.04239, 44.33552],
					[-116.04174, 44.33538],
					[-116.04152, 44.33536],
					[-116.0411, 44.33536],
					[-116.04089, 44.3354],
					[-116.04087, 44.33541],
					[-116.0408, 44.33542],
					[-116.04, 44.3357],
					[-116.03989, 44.33572],
					[-116.03978, 44.33575],
					[-116.03968, 44.33577],
					[-116.03948, 44.33579],
					[-116.03936, 44.33578],
					[-116.03925, 44.33578],
					[-116.03896, 44.33575],
					[-116.03868, 44.33568],
					[-116.03813, 44.33549],
					[-116.03782, 44.3354],
					[-116.03781, 44.33539],
					[-116.03767, 44.33536],
					[-116.03758, 44.33535],
					[-116.03748, 44.33533],
					[-116.03736, 44.33532],
					[-116.03724, 44.33532],
					[-116.03711, 44.33531],
					[-116.03678, 44.33531],
					[-116.03645, 44.33534],
					[-116.03608, 44.33541],
					[-116.03582, 44.33548],
					[-116.03343, 44.33628],
					[-116.03276, 44.33655],
					[-116.03246, 44.33669],
					[-116.03228, 44.33679],
					[-116.03166, 44.33719],
					[-116.03137, 44.3374],
					[-116.03125, 44.3375],
					[-116.03072, 44.33799],
					[-116.03063, 44.33809],
					[-116.03044, 44.33834],
					[-116.03017, 44.33874],
					[-116.02995, 44.33911],
					[-116.02994, 44.33915],
					[-116.02947, 44.34019],
					[-116.02859, 44.3423],
					[-116.02845, 44.34261],
					[-116.02027, 44.36214],
					[-116.02025, 44.36217],
					[-116.01891, 44.36534],
					[-116.0186, 44.36613],
					[-116.01799, 44.36757],
					[-116.0179, 44.36781],
					[-116.01784, 44.36794],
					[-116.01767, 44.36841],
					[-116.01765, 44.36845],
					[-116.0176, 44.36861],
					[-116.01753, 44.36879],
					[-116.01693, 44.37068],
					[-116.01676, 44.37114],
					[-116.01629, 44.37253],
					[-116.01609, 44.37317],
					[-116.01607, 44.37325],
					[-116.01561, 44.3747],
					[-116.01477, 44.37714],
					[-116.01475, 44.37722],
					[-116.01271, 44.38316],
					[-116.01271, 44.38318],
					[-116.01226, 44.38446],
					[-116.01181, 44.38582],
					[-116.01172, 44.38615],
					[-116.01121, 44.38741],
					[-116.01086, 44.38818],
					[-116.01044, 44.38899],
					[-116.00996, 44.38986],
					[-116.00951, 44.39062],
					[-116.00934, 44.39088],
					[-116.00911, 44.39126],
					[-116.00756, 44.39338],
					[-116.00686, 44.39424],
					[-116.0019, 44.40068],
					[-116.00148, 44.40132],
					[-116.00115, 44.40189],
					[-116.001, 44.40218],
					[-116.00069, 44.40285],
					[-116.00024, 44.40413],
					[-116.00018, 44.4044],
					[-115.9999, 44.40537],
					[-115.99977, 44.4063],
					[-115.99973, 44.40699],
					[-115.99972, 44.40898],
					[-115.99978, 44.41159],
					[-115.99977, 44.41344],
					[-115.99965, 44.41781],
					[-115.99948, 44.42747],
					[-115.99937, 44.44428],
					[-115.99938, 44.44461],
					[-115.99924, 44.46335],
					[-115.99916, 44.46841],
					[-115.99929, 44.47944],
					[-115.99933, 44.48016],
					[-115.99939, 44.48069],
					[-115.99944, 44.48099],
					[-115.9995, 44.48126],
					[-115.99967, 44.4819],
					[-115.99974, 44.48212],
					[-115.9998, 44.48228],
					[-115.99989, 44.48249],
					[-116.00031, 44.48338],
					[-116.00045, 44.48363],
					[-116.00076, 44.48413],
					[-116.00133, 44.48489],
					[-116.0016, 44.48521],
					[-116.00235, 44.48598],
					[-116.00264, 44.48623],
					[-116.00326, 44.48673],
					[-116.00579, 44.48847],
					[-116.01687, 44.49594],
					[-116.01694, 44.49598],
					[-116.0198, 44.49791],
					[-116.02129, 44.49899],
					[-116.03131, 44.50646],
					[-116.03151, 44.50662],
					[-116.03199, 44.50696],
					[-116.0343, 44.50871],
					[-116.03508, 44.50935],
					[-116.03723, 44.51126],
					[-116.03762, 44.51163],
					[-116.03853, 44.51245],
					[-116.04187, 44.51595],
					[-116.04463, 44.5189],
					[-116.04475, 44.51904],
					[-116.0455, 44.51983],
					[-116.04565, 44.52],
					[-116.04584, 44.52024],
					[-116.04597, 44.52044],
					[-116.04604, 44.52062],
					[-116.04608, 44.52075],
					[-116.04611, 44.52082],
					[-116.04613, 44.5209],
					[-116.04613, 44.52091],
					[-116.04615, 44.52098],
					[-116.04617, 44.52108],
					[-116.0462, 44.52135],
					[-116.0462, 44.52143],
					[-116.04618, 44.5216],
					[-116.04618, 44.52168],
					[-116.04617, 44.52169],
					[-116.04616, 44.52179],
					[-116.04611, 44.52197],
					[-116.04607, 44.52208],
					[-116.04604, 44.52214],
					[-116.046, 44.52224],
					[-116.04595, 44.52234],
					[-116.04589, 44.52244],
					[-116.04584, 44.52254],
					[-116.0458, 44.5226],
					[-116.04563, 44.52282],
					[-116.04438, 44.52427],
					[-116.04433, 44.52434],
					[-116.04419, 44.5245],
					[-116.04414, 44.52455],
					[-116.04128, 44.52784],
					[-116.04107, 44.5281],
					[-116.03962, 44.52978],
					[-116.03957, 44.52985],
					[-116.03879, 44.53074],
					[-116.0384, 44.53123],
					[-116.03815, 44.53162],
					[-116.0379, 44.53207],
					[-116.03547, 44.53674],
					[-116.03525, 44.5371],
					[-116.03507, 44.53734],
					[-116.03487, 44.53758],
					[-116.03469, 44.53777],
					[-116.03429, 44.53815],
					[-116.03386, 44.5385],
					[-116.03357, 44.5387],
					[-116.03341, 44.53879],
					[-116.0334, 44.5388],
					[-116.02851, 44.5416],
					[-116.02791, 44.54199],
					[-116.02772, 44.54213],
					[-116.02739, 44.54241],
					[-116.02706, 44.54274],
					[-116.02679, 44.54311],
					[-116.02669, 44.54327],
					[-116.02652, 44.54361],
					[-116.02651, 44.54365],
					[-116.02647, 44.54374],
					[-116.02645, 44.54382],
					[-116.02637, 44.54405],
					[-116.02629, 44.54443],
					[-116.02627, 44.54458],
					[-116.02627, 44.54466],
					[-116.02626, 44.54479],
					[-116.02626, 44.54493],
					[-116.02627, 44.54502],
					[-116.02627, 44.54512],
					[-116.0263, 44.54535],
					[-116.02647, 44.5463],
					[-116.02696, 44.54877],
					[-116.02714, 44.54979],
					[-116.03011, 44.56515],
					[-116.03015, 44.56553],
					[-116.03015, 44.56587],
					[-116.03014, 44.56595],
					[-116.03014, 44.56603],
					[-116.03012, 44.56612],
					[-116.03009, 44.56634],
					[-116.02995, 44.56683],
					[-116.02969, 44.56794],
					[-116.02964, 44.56826],
					[-116.02964, 44.56833],
					[-116.02963, 44.56844],
					[-116.02963, 44.56875],
					[-116.02965, 44.5689],
					[-116.02965, 44.56896],
					[-116.02967, 44.56908],
					[-116.02976, 44.56941],
					[-116.02985, 44.56966],
					[-116.03005, 44.57005],
					[-116.03033, 44.57052],
					[-116.031, 44.57152],
					[-116.03127, 44.57195],
					[-116.03499, 44.57756],
					[-116.03526, 44.57793],
					[-116.03535, 44.57807],
					[-116.03548, 44.57823],
					[-116.03558, 44.57834],
					[-116.03585, 44.57861],
					[-116.03588, 44.57863],
					[-116.03598, 44.57872],
					[-116.03638, 44.57899],
					[-116.0366, 44.57911],
					[-116.03667, 44.57914],
					[-116.03674, 44.57918],
					[-116.03881, 44.57998],
					[-116.03928, 44.58021],
					[-116.03934, 44.58025],
					[-116.03939, 44.58027],
					[-116.03944, 44.58031],
					[-116.03955, 44.58038],
					[-116.03963, 44.58046],
					[-116.0397, 44.58052],
					[-116.0399, 44.58073],
					[-116.04005, 44.58094],
					[-116.04015, 44.58116],
					[-116.04018, 44.58124],
					[-116.04019, 44.5813],
					[-116.04021, 44.58136],
					[-116.04023, 44.58145],
					[-116.04025, 44.58161],
					[-116.04025, 44.58169],
					[-116.04024, 44.58176],
					[-116.04024, 44.58182],
					[-116.04021, 44.58194],
					[-116.04012, 44.58216],
					[-116.03991, 44.58248],
					[-116.03987, 44.58253],
					[-116.03963, 44.58279],
					[-116.03905, 44.58338],
					[-116.0389, 44.58352],
					[-116.03879, 44.58364],
					[-116.03858, 44.58392],
					[-116.03851, 44.58403],
					[-116.03842, 44.5842],
					[-116.03836, 44.58435],
					[-116.03834, 44.58443],
					[-116.03833, 44.58454],
					[-116.0383, 44.58474],
					[-116.03829, 44.58489],
					[-116.03829, 44.58506],
					[-116.03832, 44.58543],
					[-116.03836, 44.58569],
					[-116.03891, 44.58818],
					[-116.03992, 44.59246],
					[-116.0401, 44.59308],
					[-116.04016, 44.59325],
					[-116.04031, 44.59356],
					[-116.04046, 44.59383],
					[-116.04064, 44.59408],
					[-116.04071, 44.59416],
					[-116.04077, 44.59424],
					[-116.04092, 44.59441],
					[-116.0411, 44.59459],
					[-116.04133, 44.59479],
					[-116.04175, 44.59509],
					[-116.04198, 44.59523],
					[-116.0426, 44.59555],
					[-116.04359, 44.59595],
					[-116.04562, 44.59673],
					[-116.04628, 44.59704],
					[-116.04638, 44.5971],
					[-116.04641, 44.59711],
					[-116.04672, 44.59729],
					[-116.04712, 44.59759],
					[-116.04735, 44.59779],
					[-116.04754, 44.59798],
					[-116.04779, 44.59827],
					[-116.04794, 44.59847],
					[-116.04802, 44.5986],
					[-116.04809, 44.5987],
					[-116.04819, 44.59891],
					[-116.04831, 44.5992],
					[-116.0484, 44.59952],
					[-116.04844, 44.59971],
					[-116.0485, 44.60011],
					[-116.04835, 44.63181],
					[-116.04833, 44.63272],
					[-116.04832, 44.63665],
					[-116.04834, 44.63725],
					[-116.04834, 44.63752],
					[-116.04839, 44.6386],
					[-116.04855, 44.64038],
					[-116.04859, 44.64064],
					[-116.04867, 44.64134],
					[-116.04881, 44.64213],
					[-116.04883, 44.6423],
					[-116.04888, 44.64252],
					[-116.0489, 44.64267],
					[-116.04926, 44.64436],
					[-116.04948, 44.64522],
					[-116.04982, 44.64642],
					[-116.05013, 44.64738],
					[-116.0504, 44.64815],
					[-116.0527, 44.65431],
					[-116.05296, 44.65511],
					[-116.05331, 44.65638],
					[-116.05341, 44.65683],
					[-116.05369, 44.65835],
					[-116.05377, 44.65892],
					[-116.05385, 44.65971],
					[-116.05393, 44.6608],
					[-116.05394, 44.66248],
					[-116.05392, 44.66284],
					[-116.05359, 44.66637],
					[-116.05357, 44.66651],
					[-116.05315, 44.67068],
					[-116.05315, 44.67072],
					[-116.05142, 44.68741],
					[-116.05119, 44.68987],
					[-116.05111, 44.69117],
					[-116.0511, 44.69327],
					[-116.05111, 44.6933],
					[-116.05112, 44.69369],
					[-116.05119, 44.69485],
					[-116.05131, 44.69592],
					[-116.05152, 44.69733],
					[-116.05167, 44.69802],
					[-116.05175, 44.69845],
					[-116.05194, 44.69927],
					[-116.05229, 44.70057],
					[-116.0526, 44.70155],
					[-116.05314, 44.70302],
					[-116.0536, 44.70414],
					[-116.05408, 44.70518],
					[-116.05454, 44.70608],
					[-116.05518, 44.70726],
					[-116.05557, 44.70792],
					[-116.05575, 44.7082],
					[-116.05583, 44.70834],
					[-116.05598, 44.70857],
					[-116.05605, 44.70869],
					[-116.05676, 44.70976],
					[-116.0568, 44.7098],
					[-116.05696, 44.71004],
					[-116.05795, 44.71137],
					[-116.05852, 44.71209],
					[-116.0599, 44.7137],
					[-116.06054, 44.71439],
					[-116.06164, 44.7155],
					[-116.06265, 44.71646],
					[-116.06453, 44.71815],
					[-116.06465, 44.71825],
					[-116.06472, 44.71832],
					[-116.07205, 44.72489],
					[-116.07324, 44.72599],
					[-116.07384, 44.72651],
					[-116.0739, 44.72657],
					[-116.07437, 44.72697],
					[-116.07771, 44.72999],
					[-116.07786, 44.73014],
					[-116.0779, 44.73017],
					[-116.07813, 44.73046],
					[-116.07824, 44.73065],
					[-116.07828, 44.73075],
					[-116.07831, 44.73088],
					[-116.07832, 44.73099],
					[-116.07833, 44.73148],
					[-116.07831, 44.73295],
					[-116.07833, 44.73426],
					[-116.07834, 44.73432],
					[-116.07834, 44.73468],
					[-116.0783, 44.73561],
					[-116.07833, 44.74454],
					[-116.0783, 44.7475],
					[-116.07831, 44.74774],
					[-116.0783, 44.74785],
					[-116.07821, 44.76016],
					[-116.07822, 44.76073],
					[-116.07821, 44.76122],
					[-116.07823, 44.77119],
					[-116.07821, 44.77134],
					[-116.07821, 44.77141],
					[-116.07818, 44.77164],
					[-116.07814, 44.77183],
					[-116.07812, 44.77188],
					[-116.07808, 44.77201],
					[-116.07807, 44.77208],
					[-116.07786, 44.77261],
					[-116.07768, 44.77302],
					[-116.07763, 44.77316],
					[-116.07761, 44.77324],
					[-116.07758, 44.77332],
					[-116.07756, 44.7734],
					[-116.07753, 44.77348],
					[-116.07738, 44.77422],
					[-116.07679, 44.78145],
					[-116.07674, 44.78193],
					[-116.07647, 44.78538],
					[-116.07643, 44.7857],
					[-116.07638, 44.78643],
					[-116.07638, 44.78654],
					[-116.07639, 44.78657],
					[-116.0764, 44.78663],
					[-116.0764, 44.78667],
					[-116.07647, 44.78709],
					[-116.07649, 44.78715],
					[-116.07649, 44.78717],
					[-116.0765, 44.78723],
					[-116.07665, 44.7877],
					[-116.07665, 44.78771],
					[-116.07685, 44.78815],
					[-116.07782, 44.78996],
					[-116.07788, 44.79009],
					[-116.078, 44.79042],
					[-116.07801, 44.79043],
					[-116.07809, 44.7907],
					[-116.07814, 44.79096],
					[-116.07815, 44.79109],
					[-116.07817, 44.7912],
					[-116.0782, 44.79167],
					[-116.07816, 44.80363],
					[-116.07815, 44.80388],
					[-116.07816, 44.80442],
					[-116.07811, 44.82158],
					[-116.07812, 44.82181],
					[-116.07812, 44.82208],
					[-116.07814, 44.82222],
					[-116.07816, 44.82256],
					[-116.07818, 44.82274],
					[-116.07832, 44.82334],
					[-116.07833, 44.8234],
					[-116.0784, 44.82361],
					[-116.07842, 44.82369],
					[-116.07855, 44.82404],
					[-116.07868, 44.82435],
					[-116.07913, 44.82516],
					[-116.07923, 44.82531],
					[-116.08218, 44.82924],
					[-116.08242, 44.82954],
					[-116.08255, 44.82972],
					[-116.0827, 44.82991],
					[-116.08396, 44.8317],
					[-116.08415, 44.83211],
					[-116.08419, 44.83221],
					[-116.08442, 44.83271],
					[-116.08483, 44.83389],
					[-116.08485, 44.83393],
					[-116.08654, 44.83872],
					[-116.08665, 44.83906],
					[-116.08711, 44.8403],
					[-116.08717, 44.84051],
					[-116.08762, 44.8418],
					[-116.08763, 44.84181],
					[-116.08801, 44.84285],
					[-116.08802, 44.8429],
					[-116.08826, 44.84355],
					[-116.08946, 44.84702],
					[-116.08987, 44.84815],
					[-116.09061, 44.85032],
					[-116.09067, 44.85053],
					[-116.09075, 44.85095],
					[-116.0908, 44.8514],
					[-116.09082, 44.85357],
					[-116.09083, 44.85373],
					[-116.09081, 44.85467],
					[-116.09082, 44.86222],
					[-116.09079, 44.86447],
					[-116.0908, 44.86634],
					[-116.09079, 44.86666],
					[-116.09079, 44.86746],
					[-116.09078, 44.86768],
					[-116.09075, 44.87213],
					[-116.09076, 44.8726],
					[-116.09067, 44.88142],
					[-116.09069, 44.88234],
					[-116.09072, 44.88282],
					[-116.09076, 44.88325],
					[-116.09083, 44.88369],
					[-116.09103, 44.8846],
					[-116.09121, 44.88525],
					[-116.0914, 44.8858],
					[-116.09343, 44.89062],
					[-116.09396, 44.89182],
					[-116.09494, 44.89414],
					[-116.09529, 44.89488],
					[-116.09612, 44.8968],
					[-116.09651, 44.89776],
					[-116.09798, 44.89772],
					[-116.09816, 44.89773],
					[-116.09906, 44.8977],
					[-116.09915, 44.89771],
					[-116.0997, 44.89773],
					[-116.10021, 44.89783],
					[-116.10048, 44.89798],
					[-116.10068, 44.89826],
					[-116.10084, 44.8986],
					[-116.10096, 44.89875],
					[-116.10099, 44.8988],
					[-116.10132, 44.89893],
					[-116.10169, 44.89899],
					[-116.10264, 44.89898],
					[-116.1046, 44.8989],
					[-116.10537, 44.89883],
					[-116.10714, 44.89876],
					[-116.10721, 44.89875],
					[-116.10888, 44.89865],
					[-116.10943, 44.89843],
					[-116.11042, 44.89797],
					[-116.11064, 44.89788],
					[-116.11135, 44.89763],
					[-116.11197, 44.89758],
					[-116.11278, 44.89771],
					[-116.11432, 44.89805],
					[-116.1149, 44.89811],
					[-116.11536, 44.8981],
					[-116.11549, 44.89806],
					[-116.11665, 44.89776],
					[-116.11736, 44.89756],
					[-116.11739, 44.89756],
					[-116.11785, 44.89752],
					[-116.11945, 44.89751],
					[-116.11957, 44.89752],
					[-116.11999, 44.89752],
					[-116.12029, 44.89754],
					[-116.12042, 44.89754],
					[-116.12109, 44.8976],
					[-116.12175, 44.89769],
					[-116.12264, 44.89789],
					[-116.12508, 44.89855],
					[-116.12514, 44.89856],
					[-116.12582, 44.89875],
					[-116.12624, 44.899],
					[-116.12646, 44.89919],
					[-116.12662, 44.89941],
					[-116.12672, 44.89978],
					[-116.12674, 44.90016],
					[-116.12678, 44.90045],
					[-116.12677, 44.90101],
					[-116.12679, 44.90135],
					[-116.12677, 44.90213],
					[-116.1267, 44.90336],
					[-116.12664, 44.90377],
					[-116.12658, 44.90409],
					[-116.1265, 44.90496],
					[-116.12655, 44.90621],
					[-116.12656, 44.90628],
					[-116.12656, 44.90639],
					[-116.12657, 44.90657],
					[-116.12659, 44.90675],
					[-116.1266, 44.90693],
					[-116.12668, 44.90764],
					[-116.1267, 44.91037],
					[-116.12669, 44.91073],
					[-116.12669, 44.91357],
					[-116.12774, 44.91379],
					[-116.12827, 44.91392],
					[-116.12887, 44.9141],
					[-116.12951, 44.91433],
					[-116.13027, 44.91463],
					[-116.1312, 44.91507],
					[-116.13155, 44.91526],
					[-116.13182, 44.91543],
					[-116.13267, 44.91606],
					[-116.13303, 44.91636],
					[-116.13466, 44.91784],
					[-116.13599, 44.91913],
					[-116.13894, 44.92188],
					[-116.13913, 44.92207],
					[-116.14075, 44.92359],
					[-116.14106, 44.92391],
					[-116.14167, 44.92447],
					[-116.14193, 44.92469],
					[-116.14258, 44.92518],
					[-116.14289, 44.92538],
					[-116.14322, 44.92556],
					[-116.1437, 44.9258],
					[-116.14819, 44.92761],
					[-116.14822, 44.92763],
					[-116.14844, 44.92772],
					[-116.1488, 44.92785],
					[-116.15106, 44.92876],
					[-116.15114, 44.9288],
					[-116.15139, 44.9289],
					[-116.15162, 44.92898],
					[-116.15235, 44.9292],
					[-116.15299, 44.92935],
					[-116.15351, 44.92943],
					[-116.15452, 44.92952],
					[-116.15456, 44.92953],
					[-116.15673, 44.92968],
					[-116.15733, 44.92974],
					[-116.15767, 44.92979],
					[-116.15922, 44.93011],
					[-116.15988, 44.93023],
					[-116.16003, 44.93025],
					[-116.16019, 44.93028],
					[-116.16044, 44.9303],
					[-116.16156, 44.9303],
					[-116.16209, 44.93033],
					[-116.16251, 44.93039],
					[-116.16298, 44.93051],
					[-116.16318, 44.93058],
					[-116.16339, 44.93067],
					[-116.16368, 44.93081],
					[-116.1639, 44.93095],
					[-116.16411, 44.93111],
					[-116.1643, 44.93128],
					[-116.16439, 44.93137],
					[-116.16455, 44.93156],
					[-116.16467, 44.93175],
					[-116.16482, 44.93214],
					[-116.1648, 44.93219],
					[-116.16504, 44.93328],
					[-116.16509, 44.93345],
					[-116.16519, 44.93394],
					[-116.16522, 44.93405],
					[-116.16536, 44.93472],
					[-116.16539, 44.93475],
					[-116.16564, 44.93604],
					[-116.16633, 44.93917],
					[-116.16682, 44.94114],
					[-116.16699, 44.94202],
					[-116.16699, 44.94206],
					[-116.16794, 44.94643],
					[-116.16816, 44.94732],
					[-116.16853, 44.94897],
					[-116.16862, 44.94946],
					[-116.16938, 44.95299],
					[-116.16939, 44.95306],
					[-116.16951, 44.95359],
					[-116.16951, 44.95361],
					[-116.16992, 44.95534],
					[-116.17003, 44.95589],
					[-116.17016, 44.95645],
					[-116.17024, 44.95675],
					[-116.17037, 44.9571],
					[-116.17045, 44.95727],
					[-116.17054, 44.9575],
					[-116.1706, 44.95763],
					[-116.17073, 44.95786],
					[-116.17106, 44.9584],
					[-116.17136, 44.95895],
					[-116.17136, 44.95896],
					[-116.17161, 44.95941],
					[-116.17204, 44.96013],
					[-116.17225, 44.96036],
					[-116.17239, 44.96048],
					[-116.17266, 44.96062],
					[-116.17287, 44.96071],
					[-116.17334, 44.96087],
					[-116.17351, 44.96095],
					[-116.17368, 44.96106],
					[-116.1738, 44.96118],
					[-116.17381, 44.96121],
					[-116.17384, 44.96126],
					[-116.17388, 44.96135],
					[-116.17393, 44.96153],
					[-116.17393, 44.96161],
					[-116.17381, 44.96263],
					[-116.1738, 44.96285],
					[-116.17383, 44.96298],
					[-116.17386, 44.96308],
					[-116.17391, 44.96318],
					[-116.17399, 44.96329],
					[-116.17411, 44.96341],
					[-116.17472, 44.96382],
					[-116.17544, 44.96426],
					[-116.1757, 44.96436],
					[-116.17583, 44.9644],
					[-116.17599, 44.96442],
					[-116.17613, 44.96443],
					[-116.17625, 44.96442],
					[-116.1764, 44.96439],
					[-116.17674, 44.96428],
					[-116.17685, 44.96426],
					[-116.17713, 44.96424],
					[-116.17724, 44.96425],
					[-116.17735, 44.96427],
					[-116.17748, 44.96432],
					[-116.17767, 44.96442],
					[-116.17777, 44.9645],
					[-116.17787, 44.96459],
					[-116.17794, 44.9647],
					[-116.17803, 44.96489],
					[-116.17815, 44.96505],
					[-116.17831, 44.96521],
					[-116.17843, 44.96528],
					[-116.17847, 44.96531],
					[-116.1786, 44.96539],
					[-116.17937, 44.96576],
					[-116.17964, 44.96587],
					[-116.17988, 44.96601],
					[-116.17997, 44.96608],
					[-116.18006, 44.96617],
					[-116.18013, 44.96628],
					[-116.18015, 44.96635],
					[-116.1802, 44.96648],
					[-116.18023, 44.96652],
					[-116.18031, 44.96669],
					[-116.1804, 44.96683],
					[-116.18047, 44.96691],
					[-116.18057, 44.96699],
					[-116.18062, 44.96702],
					[-116.18077, 44.96707],
					[-116.18085, 44.96709],
					[-116.18097, 44.96709],
					[-116.18102, 44.96708],
					[-116.18107, 44.96708],
					[-116.18119, 44.96704],
					[-116.18126, 44.96701],
					[-116.18172, 44.96687],
					[-116.18185, 44.96686],
					[-116.18232, 44.96679],
					[-116.18264, 44.96668],
					[-116.18281, 44.96659],
					[-116.18293, 44.96654],
					[-116.18307, 44.96647],
					[-116.18352, 44.96632],
					[-116.1837, 44.96627],
					[-116.18402, 44.96622],
					[-116.18439, 44.96622],
					[-116.18458, 44.96624],
					[-116.18464, 44.96624],
					[-116.18682, 44.96649],
					[-116.18714, 44.96649],
					[-116.18853, 44.96644],
					[-116.18892, 44.9664],
					[-116.18917, 44.96633],
					[-116.18926, 44.96629],
					[-116.18937, 44.96623],
					[-116.1894, 44.96621],
					[-116.18943, 44.96618],
					[-116.1895, 44.96613],
					[-116.18954, 44.96609],
					[-116.18961, 44.96603],
					[-116.18961, 44.96602],
					[-116.18969, 44.96595],
					[-116.18975, 44.96592],
					[-116.18979, 44.96589],
					[-116.18991, 44.96585],
					[-116.19002, 44.96583],
					[-116.19012, 44.96582],
					[-116.19024, 44.96582],
					[-116.19033, 44.96583],
					[-116.19043, 44.96585],
					[-116.19052, 44.96586],
					[-116.19061, 44.96589],
					[-116.19126, 44.96618],
					[-116.19144, 44.96622],
					[-116.19165, 44.96624],
					[-116.19176, 44.96624],
					[-116.19187, 44.96623],
					[-116.192, 44.96621],
					[-116.19214, 44.96618],
					[-116.19231, 44.96613],
					[-116.19252, 44.96604],
					[-116.19272, 44.96594],
					[-116.19314, 44.96571],
					[-116.19345, 44.96552],
					[-116.19376, 44.96531],
					[-116.19418, 44.96495],
					[-116.19556, 44.96366],
					[-116.19571, 44.96354],
					[-116.19572, 44.96352],
					[-116.19588, 44.96338],
					[-116.19626, 44.96301],
					[-116.19648, 44.96277],
					[-116.19663, 44.96253],
					[-116.19679, 44.96231],
					[-116.19721, 44.96166],
					[-116.19722, 44.96165],
					[-116.1973, 44.96154],
					[-116.19734, 44.9615],
					[-116.19754, 44.96125],
					[-116.19776, 44.96101],
					[-116.19793, 44.96087],
					[-116.19813, 44.96074],
					[-116.19842, 44.96057],
					[-116.19848, 44.96055],
					[-116.19865, 44.96046],
					[-116.19873, 44.96043],
					[-116.19915, 44.9603],
					[-116.20004, 44.96008],
					[-116.20011, 44.96007],
					[-116.20035, 44.96002],
					[-116.20049, 44.96],
					[-116.20072, 44.95998],
					[-116.20113, 44.95996],
					[-116.20141, 44.95996],
					[-116.20156, 44.95994],
					[-116.20162, 44.95994],
					[-116.20211, 44.95986],
					[-116.20236, 44.9598],
					[-116.20277, 44.95968],
					[-116.20283, 44.95967],
					[-116.20318, 44.95957],
					[-116.20333, 44.95955],
					[-116.20349, 44.95952],
					[-116.20367, 44.95951],
					[-116.20388, 44.95951],
					[-116.20407, 44.95952],
					[-116.20422, 44.95954],
					[-116.20432, 44.95956],
					[-116.20434, 44.95956],
					[-116.20454, 44.95962],
					[-116.20455, 44.95962],
					[-116.20479, 44.95969],
					[-116.20508, 44.95981],
					[-116.20569, 44.96001],
					[-116.20587, 44.96004],
					[-116.20626, 44.96005],
					[-116.20656, 44.96003],
					[-116.20672, 44.96001],
					[-116.20702, 44.95995],
					[-116.20735, 44.95984],
					[-116.20758, 44.95974],
					[-116.20792, 44.95957],
					[-116.20821, 44.9594],
					[-116.20822, 44.95939],
					[-116.20834, 44.95932],
					[-116.20842, 44.95928],
					[-116.20862, 44.9592],
					[-116.20884, 44.95914],
					[-116.20898, 44.95912],
					[-116.2099, 44.95912],
					[-116.21012, 44.95911],
					[-116.2103, 44.95909],
					[-116.21054, 44.95905],
					[-116.21064, 44.95902],
					[-116.21069, 44.95901],
					[-116.21085, 44.95896],
					[-116.21099, 44.9589],
					[-116.21116, 44.95881],
					[-116.2114, 44.95866],
					[-116.21183, 44.95836],
					[-116.21184, 44.95836],
					[-116.21218, 44.95811],
					[-116.21219, 44.95811],
					[-116.21259, 44.95788],
					[-116.21303, 44.95771],
					[-116.21307, 44.95769],
					[-116.2144, 44.95732],
					[-116.21548, 44.95705],
					[-116.21807, 44.95634],
					[-116.21995, 44.95586],
					[-116.22025, 44.95581],
					[-116.22027, 44.9558],
					[-116.22068, 44.95574],
					[-116.22121, 44.95569],
					[-116.22184, 44.95566],
					[-116.23148, 44.95573],
					[-116.23218, 44.95576],
					[-116.23264, 44.9558],
					[-116.23316, 44.95587],
					[-116.23359, 44.95595],
					[-116.23411, 44.95608],
					[-116.23443, 44.95618],
					[-116.23474, 44.95629],
					[-116.23485, 44.95632],
					[-116.23499, 44.95637],
					[-116.23528, 44.95649],
					[-116.23574, 44.95671],
					[-116.23603, 44.95687],
					[-116.23662, 44.95724],
					[-116.2391, 44.95898],
					[-116.23952, 44.95929],
					[-116.24042, 44.95991],
					[-116.24091, 44.9602],
					[-116.2412, 44.96035],
					[-116.24182, 44.9606],
					[-116.24201, 44.96065],
					[-116.24223, 44.96072],
					[-116.2425, 44.96079],
					[-116.24284, 44.96086],
					[-116.24309, 44.9609],
					[-116.2432, 44.96091],
					[-116.24333, 44.96093],
					[-116.24458, 44.961],
					[-116.24488, 44.961],
					[-116.24897, 44.96114],
					[-116.24902, 44.96115],
					[-116.24936, 44.96115],
					[-116.25, 44.9612],
					[-116.25017, 44.96123],
					[-116.25034, 44.96127],
					[-116.25074, 44.96139],
					[-116.26996, 44.96784],
					[-116.27002, 44.96787],
					[-116.27897, 44.97085],
					[-116.27915, 44.9709],
					[-116.27929, 44.97095],
					[-116.27968, 44.97105],
					[-116.27995, 44.97109],
					[-116.28027, 44.97112],
					[-116.28097, 44.97113],
					[-116.28402, 44.97111],
				],
			},
		},
		// {
		// 	type: "Feature",
		// 	properties: { 
		// 		shape: "Marker",
		// 		name: "Eagle, Idaho",
		// 		category: "default"
		// 	},
		// 	geometry: { type: "Point", coordinates: [-116.3540086, 43.6954424] },
		// },
		// {
		// 	type: "Feature",
		// 	properties: { 
		// 		shape: "Marker",
		// 		name: "New Meadows, Idaho",
		// 		category: "default"
		// 	},
		// 	geometry: { type: "Point", coordinates: [-116.2840164, 44.9711052] },
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		name: "Horseshoe Bend, Idaho",
		// 		radius: 2000,
		// 		category: "default",
		// 	},
		// 	geometry: { type: "Point", coordinates: [-116.1979025, 43.9146123] },
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		name: "Banks, Idaho",
		// 		radius: 2000,
		// 		category: "default",
		// 	},
		// 	geometry: { type: "Point", coordinates: [-116.1240151, 44.0804473] },
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		name: "Smiths Ferry, Idaho",
		// 		radius: 2000,
		// 		category: "default",
		// 	},
		// 	geometry: { type: "Point", coordinates: [-116.0884825, 44.3027038] },
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		name: "Cascade, Idaho",
		// 		radius: 2000,
		// 		category: "default",
		// 	},
		// 	geometry: { type: "Point", coordinates: [-116.0417983, 44.5162821] },
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		name: "Donnelly, Idaho",
		// 		radius: 2000,
		// 		category: "default",
		// 	},
		// 	geometry: { type: "Point", coordinates: [-116.0772125, 44.7308461] },
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		name: "McCall, Idaho",
		// 		radius: 2000,
		// 		category: "default",
		// 	},
		// 	geometry: { type: "Point", coordinates: [-116.1171311, 44.906183] },
		// },
	],
};

export default PayetteRiver;
