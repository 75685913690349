export const activities = [
    { id: 'winter', label: "Winter", value: "Cat & Heli Skiing,Nordic Skiing & Snowshoeing,Skiing & Snowboarding,Snow Tubing,Snowmobiling,Sleigh Rides,Ice Skating" },
    { id: 'culinary', label: "Culinary", value: "Breweries,Cideries,Culinary,Distilleries,Farmers Markets,Wineries" },
    { id: 'historical', label: "Historical Sites", value: "Natural Attractions,National Monuments & Historical Sites,Ghost Towns & Mining History,Oregon Trail" },
    { id: 'water', label: "Water Recreation", value: "Fishing,Jet Boating,Lake & River Cruises,Rafting & Kayaking,Hot Springs" },
    { id: 'parks', label: "State Parks", value: "State Parks" },
    // { label: "Scenic Byways", value: "Road Trips" },
    { id: 'biking', label: "Biking Recreation", value: "Mountain Biking,Rail to Trail" },
    { id: 'roadside', label: "Roadside Attractions", value: "Roadside Attractions,Amusement Parks & Waterparks,Casinos" },
    { id: 'culture', label: "Arts & Culture", value: "Native American Culture,Museums & Galleries,Festivals & Events" },
    { id: 'outdoor', label: "Outdoor Recreation", value: "ATV Recreation & Motorsports,Ballooning,Horseback Riding & Guest Ranches,Rock Climbing,Ziplining,Camping,Hiking & Backpacking,Golfing,Guest Ranches" },
    { id: 'wildlife', label: "Wildlife", value: "Wildlife Viewing & Birding,Wildlife Parks & Zoos" },
];

export const northByways = [
    { label: "International Selkirk Loop", value: "SelkirkLoop" },
    { label: "Wild Horse Trail Scenic Byway", value: "WildHorseTrail" },
    { label: "Panhandle Historic Rivers Passage", value: "PanhandleHistoric" },
    { label: "Pend Oreille Scenic Byway", value: "PendOreille" },
    { label: "Lake Coeur d'Alene Scenic Byway", value: "LakeCoeurDAlene" },
    { label: "St. Joe River Scenic Byway", value: "StJoeRiver" },
    { label: "White Pine Scenic Byway", value: "WhitePine" },
];

export const northCentralByways = [
    { label: "Elk River Backcountry Byway", value: "ElkRiver" },
    { label: "Northwest Passage Scenic Byway", value: "NorthwestPassage" },
    { label: "Gold Rush Historic Byway", value: "GoldRushHistoric" },
    { label: "White Pine Scenic Byway", value: "WhitePine" },
];

export const southWestByways = [
    { label: "Payette River Scenic Byway", value: "PayetteRiver" },
    { label: "Ponderosa Pine Scenic Byway", value: "PonderosaPines" },
    { label: "Hells Canyon Scenic Byway", value: "HellsCanyon" },
    { label: "Wildlife Canyon Scenic Byway", value: "WildlifeCanyon" },
    { label: "Main Oregon Trail Backcountry Byway", value: "MainOregonTrail" },
    { label: "Snake River Canyon Scenic Byway", value: "SnakeRiverCanyon" },
    { label: "Lower Payette River Heritage Byway", value: "LowerPayetteRiver" },
    { label: "Owyhee Uplands Backcountry Byway", value: "OwyheeUplands" },
    { label: "Western Heritage Historic Byway", value: "WesternHeritage" },
];

export const southCentralByways = [
    { label: "Sawtooth Scenic Byway", value: "Sawtooth" },
    { label: "Thousand Springs Scenic Byway", value: "ThousandSprings" },
    { label: "City of Rocks Backcountry Byway", value: "CityOfRocks" },
];

export const southEastByways = [
    { label: "Pioneer Historic Byway", value: "PioneerHistoric" },
    { label: "Oregon Trail–Bear Lake Scenic Byway", value: "OregonTrailBearLake" },
];

export const easternByways = [
    { label: "Fort Henry Historic Byway", value: "FortHenryHistoric" },
    { label: "Mesa Falls Scenic Byway", value: "MesaFalls" },
    { label: "Sacajawea Historic Byway", value: "SacajaweaHistoric" },
    { label: "Teton Scenic Byway", value: "Teton" },
    { label: "Lost Gold Trails Loop", value: "LostGoldTrailsLoop" },
];

export const centralByways = [
    { label: "Peaks to Craters Scenic Byway", value: "PeaksToCraters" },
    { label: "Salmon River Scenic Byway", value: "SalmonRiver" },
    { label: "Sawtooth Scenic Byway", value: "Sawtooth" },
    { label: "Lewis and Clark Backcountry Byway", value: "LewisClark" },
    { label: "Ponderosa Pine Scenic Byway", value: "PonderosaPines" },
];

export const groupedByways = [
    { label: "Northern", options: northByways, cities: "Coeur d'Alene, Sandpoint & Wallace", image:"https://visitidaho.org/content/uploads/2020/01/DJI_0322-1.jpg", description: "Known for abundant forests and magnificent lakes, northern Idaho offers both adventure and relaxation. Experience the region's three ski resorts, meandering trout-filled rivers and hundreds of miles of biking and hiking trails. Northern Idaho is also home to the International Selkirk Loop. A unique, 280-mile-long scenic route winding through Idaho, Washington and Canada, the Selkirk Loop is designated as one of only 32 All-American Road National Scenic Byways in the U.S. and is one of the Top 10 scenic drives in the Northern Rockies." },
    { label: "North Central", options: northCentralByways, cities: "Grangeville, Moscow, Lewiston & Riggins", image:"https://visitidaho.org/content/uploads/2020/01/180612-1930-1-scaled-1920x1080.jpg", description: "Packed with breathtaking wilderness, rolling hills, farmland and flower-filled meadows, north central Idaho is rich in cultural importance and outdoor activities. Tour the many sites that make up the Nez Perce National Historical Park, which celebrates the history of the Nez Perce (Nimiipuu). The region is also known for its rafting and fishing opportunities, while towns like Orofino, Kooskia and Kamiah offer small-town hospitality and are perfect base camps for outdoor adventures." },
    { label: "Southwest", options: southWestByways, cities: "Boise, McCall & Mountain Home", image:"https://visitidaho.org/content/uploads/2021/06/170605cc-1784-1920x1080.png", description: "Home to the state's capital, Boise, southwest Idaho offers the best of both worlds for visitors. From awe-inspiring hiking and biking trails, rushing rivers and sand dunes to historical sites, top-notch wineries, local craft breweries and entertainment, this region showcases a variety of easily accessible adventures." },
    { label: "South Central", options: southCentralByways, cities: "Twin Falls, Hagerman & Burley", image:"https://visitidaho.org/content/uploads/2018/02/DSC7923-1-1920x1080.jpg", description: "Hello, deep canyons, high bluffs, unusual rock formations and lava flows! South central Idaho is a geologist's dream, but its terrain also delivers big adventure opportunities. At the heart of the region's recreation is the mighty Snake River—make a stop at the picturesque Perrine Bridge that spans the Snake River Canyon and watch as BASE jumpers float to the ground below, speed along a zipline inside the canyon or visit the area's numerous waterfalls." },
    { label: "Southeast", options: southEastByways, cities: "Pocatello, Lava Hot Springs & Montpelier", image:"https://visitidaho.org/content/uploads/2019/03/180914-0008-1600x1200-1600x1080.jpg", description: "Southeastern Idaho is a destination for outdoor lovers, and it's no secret why. Hike to a hidden mountain lake, explore miles of hiking and biking trails, visit the Idaho Potato Museum and end the day with a hot spring soak. History seekers will find sites and museums connected to the Oregon Trail throughout this area as well as the Fort Hall Replica." },
    { label: "Eastern", options: easternByways, cities: "Idaho Falls, Rexburg & Island Park", image:"https://visitidaho.org/content/uploads/2019/03/180910-0059-1600x1200.jpg", description: "Eastern Idaho is no stranger to year-round outdoor adventure. An ideal gateway to Yellowstone National Park, the unique ecosystem of this region provides many opportunities for recreation amidst breathtaking scenery. Fish blue-ribbon trout streams and experience some of the lesser-known pleasures, like a lively ride on Rexburg's antique carousel, a huckleberry milkshake in Victor and a night of old-fashioned family fun at the Spud Drive-In in Driggs." },
    { label: "Central", options: centralByways, cities: "Sun Valley, Stanley & Salmon", image:"https://visitidaho.org/content/uploads/2021/09/10-SawtoothSB-Drone-Lake-2.00_00_22_05.Still001-1920x1080.jpg", description: "Central Idaho is home to some of Idaho's most dramatic scenery. From Redfish Lake to the Salmon River, central Idaho has no shortage of thrilling whitewater, alpine lakes, jagged mountain peaks, wildflower-filled meadows and historic mountain towns. With the first and only International Dark Sky Reserve, the stargazing here is out of this world." },
];

export const byways = [
    { label: "City of Rocks Backcountry Byway", value: "CityOfRocks" },
    { label: "Elk River Backcountry Byway", value: "ElkRiver" },
    { label: "Fort Henry Historic Byway", value: "FortHenryHistoric" },
    { label: "Gold Rush Historic Byway", value: "GoldRushHistoric" },
    { label: "Hells Canyon Scenic Byway", value: "HellsCanyon" },
    { label: "Lake Coeur d'Alene Scenic Byway", value: "LakeCoeurDAlene" },
    { label: "Lewis and Clark Backcountry Byway", value: "LewisClark" },
    { label: "Lost Gold Trails Loop", value: "LostGoldTrailsLoop" },
    { label: "Lower Payette River Heritage Byway", value: "LowerPayetteRiver" },
    { label: "Main Oregon Trail Backcountry Byway", value: "MainOregonTrail" },
    { label: "Mesa Falls Scenic Byway", value: "MesaFalls" },
    { label: "Northwest Passage Scenic Byway", value: "NorthwestPassage" },
    { label: "Oregon Trail–Bear Lake Scenic Byway", value: "OregonTrailBearLake" },
    { label: "Owyhee Uplands Backcountry Byway", value: "OwyheeUplands" },
    { label: "Panhandle Historic Rivers Passage", value: "PanhandleHistoric" },
    { label: "Payette River Scenic Byway", value: "PayetteRiver" },
    { label: "Pend Oreille Scenic Byway", value: "PendOreille" },
    { label: "Peaks to Craters Scenic Byway", value: "PeaksToCraters" },
    { label: "Pioneer Historic Byway", value: "PioneerHistoric" },
    { label: "Ponderosa Pine Scenic Byway", value: "PonderosaPines" },
    { label: "Sacajawea Historic Byway", value: "SacajaweaHistoric" },
    { label: "Salmon River Scenic Byway", value: "SalmonRiver" },
    { label: "Sawtooth Scenic Byway", value: "Sawtooth" },
    { label: "International Selkirk Loop", value: "SelkirkLoop" },
    { label: "Snake River Canyon Scenic Byway", value: "SnakeRiverCanyon" },
    { label: "St. Joe River Scenic Byway", value: "StJoeRiver" },
    { label: "Teton Scenic Byway", value: "Teton" },
    { label: "Thousand Springs Scenic Byway", value: "ThousandSprings" },
    { label: "Western Heritage Historic Byway", value: "WesternHeritage" },
    { label: "White Pine Scenic Byway", value: "WhitePine" },
    { label: "Wild Horse Trail Scenic Byway", value: "WildHorseTrail" },
    { label: "Wildlife Canyon Scenic Byway", value: "WildlifeCanyon" },
];

export const regions = [
    { label: "Northern", value: "north", latLng: [47.678521280814756, -116.5370209748492] },
    { label: "North Central", value: "north-central", latLng: [46.409734640295156, -116.10642197029661] },
    { label: "Southwest", value: "southwest", latLng: [43.57931912148301, -116.19171857744311] },
    { label: "South Central", value: "south-central", latLng: [42.55780573349964, -114.47116164541976] },
    { label: "Southeast", value: "southeast", latLng: [42.878576458351894, -112.44520229379106] },
    { label: "Eastern", value: "east", latLng: [43.499407024008676, -112.04332145695925] },
    { label: "Central", value: "central", latLng: [43.697828682835976, -114.28679422777564] },
];

