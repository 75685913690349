import React from "react";
import axios from "axios";
import { setMarkers } from "../../store/actions/markersActions";
import { renderToString } from 'react-dom/server';
import { activities, activities as ActivityCategories } from "../../data/filters-data";
import L from 'leaflet';
import {
	IconWinter,
	IconCulinary,
  IconNationalMonumentsHistoricSites, 
	IconWaterRecreation,
  IconStateParks,
	IconScenicByways,
	IconBikingRecreation,
	IconRoadSideAttractions,
	IconArtsCulture, 
  IconOutdoorRecreation,
	IconWildlife,
	IconMapMarker
} from "../../images/icons";

//import jsonResults from "./markers.json";

export const getMarkers = () => {
  return async dispatch => {
    try {
      let response = await axios.get("https://visitidaho.org/wp-json/vita_route/adventures/");
      dispatch(setMarkers(response.data.sort(() => Math.random() - 0.5)));
      //dispatch(setMarkers(jsonResults.sort(() => Math.random() - 0.5)));
    }
    catch (e) {
      //console.log(e)
    }
  }
}

export const getMarkerIcon = (marker, returnHtml, allMarkersActive) => {

  let category = marker.primary_category;
  let categoryLabel = null;
  let isSelected = allMarkersActive ? allMarkersActive : marker.is_selected;

  for (let i = 0; i < ActivityCategories.length; i++) {
    let ids = ActivityCategories[i].value.split(',');
    let match = ids.filter(e => category && category.includes(e));
    if (match.length) {
      categoryLabel = ActivityCategories[i].label;
      break;
    }
  }
  let iconHtml = getIconHtmlByCategoryLabel(categoryLabel, isSelected);

  if(returnHtml) return iconHtml;

  return new L.divIcon({
    html: renderToString(iconHtml),
    iconSize: [40, 40]
  })
}

export const getIconHtmlByCategoryLabel = (categoryLabel, active) => {
  switch (categoryLabel) {
    case "Winter":
      return <IconWinter active={active} />;
    case "Culinary":
      return <IconCulinary active={active} />;
    case "Historical Sites":
      return <IconNationalMonumentsHistoricSites active={active} />;
    case "Water Recreation":
      return <IconWaterRecreation active={active} />;
    case "State Parks":
      return <IconStateParks active={active} />;
    case "Scenic Byways":
      return <IconScenicByways active={active} />;
    case "Biking Recreation":
      return <IconBikingRecreation active={active} />;
    case "Roadside Attractions":
      return <IconRoadSideAttractions active={active} />;
    case "Arts & Culture":
      return <IconArtsCulture active={active} />;
    case "Outdoor Recreation":
      return <IconOutdoorRecreation active={active} />;
    case "Wildlife":
      return <IconWildlife active={active} />;
    default:
      return <IconMapMarker />;
  }
};

export const getLabelByValue = (value) => {
  const activity = activities.find((activity) => activity.value.includes(value));
  return activity ? activity.label : null;
};
