const PioneerHistoric = {
    type: "FeatureCollection",
    features: [{
        type: "Feature",
        properties: {
            name: "Pioneer Historic Byway",
            "description": "The Pioneer Historic Byway takes visitors on an adventure through southeastern Idaho. Marvel in the rugged beauty of Red Rock Pass. Discover the world's only captive geyser in Soda Springs. Or journey to Historic Chesterfield, a ghost town dating back to the 1880s with 27 original structures now on the National Historic Register.",
            "link": "https://visitidaho.org/things-to-do/road-trips/pioneer-historic-byway/",
            "route": [
                "Franklin", 
                "Preston",
                "Soda Springs"
            ],
            "shape": "Line"
        },
        geometry: {
            type: "LineString",
            coordinates: [
                [-111.81273, 41.99892, 0],
                [-111.81272, 42.00074, 0],
                [-111.81273, 42.00127, 0],
                [-111.81271, 42.0017, 0],
                [-111.81271, 42.00198, 0],
                [-111.8127, 42.00204, 0],
                [-111.8127, 42.0021, 0],
                [-111.81269, 42.00214, 0],
                [-111.81269, 42.00223, 0],
                [-111.81266, 42.0024, 0],
                [-111.81266, 42.00245, 0],
                [-111.81261, 42.00271, 0],
                [-111.81259, 42.00277, 0],
                [-111.81258, 42.00283, 0],
                [-111.81256, 42.00289, 0],
                [-111.81255, 42.00294, 0],
                [-111.81253, 42.003, 0],
                [-111.81252, 42.00306, 0],
                [-111.8125, 42.00312, 0],
                [-111.81247, 42.00319, 0],
                [-111.81245, 42.00327, 0],
                [-111.81243, 42.00332, 0],
                [-111.8124, 42.00338, 0],
                [-111.81235, 42.00352, 0],
                [-111.8123, 42.00362, 0],
                [-111.81228, 42.00369, 0],
                [-111.8122, 42.00384, 0],
                [-111.81217, 42.00391, 0],
                [-111.81211, 42.00403, 0],
                [-111.81205, 42.00412, 0],
                [-111.812, 42.00421, 0],
                [-111.81197, 42.00425, 0],
                [-111.81191, 42.00436, 0],
                [-111.81184, 42.00447, 0],
                [-111.81135, 42.00515, 0],
                [-111.81012, 42.00677, 0],
                [-111.80952, 42.00759, 0],
                [-111.80916, 42.00806, 0],
                [-111.809, 42.00824, 0],
                [-111.80889, 42.00838, 0],
                [-111.8081, 42.00927, 0],
                [-111.80719, 42.01025, 0],
                [-111.8071, 42.01034, 0],
                [-111.8066, 42.01088, 0],
                [-111.80626, 42.01123, 0],
                [-111.80576, 42.01178, 0],
                [-111.80549, 42.0121, 0],
                [-111.80526, 42.01235, 0],
                [-111.80509, 42.01255, 0],
                [-111.80502, 42.01264, 0],
                [-111.8049, 42.01281, 0],
                [-111.80475, 42.01305, 0],
                [-111.80461, 42.01331, 0],
                [-111.8045, 42.01359, 0],
                [-111.80447, 42.0137, 0],
                [-111.80444, 42.01379, 0],
                [-111.8044, 42.01394, 0],
                [-111.80439, 42.014, 0],
                [-111.80436, 42.01413, 0],
                [-111.80435, 42.01419, 0],
                [-111.80435, 42.01424, 0],
                [-111.80434, 42.01429, 0],
                [-111.80433, 42.01443, 0],
                [-111.80426, 42.01659, 0],
                [-111.80426, 42.0168, 0],
                [-111.80424, 42.01718, 0],
                [-111.80421, 42.0182, 0],
                [-111.8042, 42.0183, 0],
                [-111.8042, 42.01836, 0],
                [-111.80421, 42.01843, 0],
                [-111.80421, 42.01856, 0],
                [-111.80426, 42.019, 0],
                [-111.80428, 42.01909, 0],
                [-111.8043, 42.01916, 0],
                [-111.80431, 42.01921, 0],
                [-111.80438, 42.01947, 0],
                [-111.80442, 42.01958, 0],
                [-111.80447, 42.01975, 0],
                [-111.80458, 42.02003, 0],
                [-111.80459, 42.02004, 0],
                [-111.80466, 42.02018, 0],
                [-111.80467, 42.02021, 0],
                [-111.80476, 42.02037, 0],
                [-111.80476, 42.02038, 0],
                [-111.80515, 42.02103, 0],
                [-111.80516, 42.02103, 0],
                [-111.80557, 42.02173, 0],
                [-111.80575, 42.02202, 0],
                [-111.806, 42.02246, 0],
                [-111.80627, 42.0229, 0],
                [-111.80632, 42.023, 0],
                [-111.80643, 42.02317, 0],
                [-111.80681, 42.02382, 0],
                [-111.80709, 42.02427, 0],
                [-111.8072, 42.02443, 0],
                [-111.80766, 42.02521, 0],
                [-111.8078, 42.02543, 0],
                [-111.80805, 42.02585, 0],
                [-111.80806, 42.02586, 0],
                [-111.80845, 42.02652, 0],
                [-111.8092, 42.02787, 0],
                [-111.80943, 42.02831, 0],
                [-111.81064, 42.03042, 0],
                [-111.81127, 42.03144, 0],
                [-111.81164, 42.03207, 0],
                [-111.81197, 42.03269, 0],
                [-111.81215, 42.03314, 0],
                [-111.81226, 42.0335, 0],
                [-111.81242, 42.03423, 0],
                [-111.81249, 42.03463, 0],
                [-111.8131, 42.03761, 0],
                [-111.81341, 42.03925, 0],
                [-111.81391, 42.04171, 0],
                [-111.81411, 42.0428, 0],
                [-111.81426, 42.04348, 0],
                [-111.81472, 42.04587, 0],
                [-111.8149, 42.04707, 0],
                [-111.815, 42.04794, 0],
                [-111.81512, 42.0492, 0],
                [-111.81512, 42.04973, 0],
                [-111.81517, 42.05018, 0],
                [-111.81526, 42.05065, 0],
                [-111.81531, 42.05083, 0],
                [-111.81548, 42.05131, 0],
                [-111.81565, 42.05167, 0],
                [-111.81573, 42.05182, 0],
                [-111.8159, 42.05211, 0],
                [-111.81601, 42.05226, 0],
                [-111.8161, 42.0524, 0],
                [-111.81621, 42.05253, 0],
                [-111.81623, 42.05256, 0],
                [-111.81635, 42.05271, 0],
                [-111.81653, 42.05291, 0],
                [-111.81661, 42.05299, 0],
                [-111.81667, 42.05306, 0],
                [-111.81676, 42.05315, 0],
                [-111.81707, 42.05343, 0],
                [-111.81709, 42.05344, 0],
                [-111.81737, 42.05366, 0],
                [-111.81751, 42.05376, 0],
                [-111.81802, 42.0541, 0],
                [-111.82132, 42.05613, 0],
                [-111.8215, 42.05625, 0],
                [-111.82158, 42.05629, 0],
                [-111.82305, 42.05718, 0],
                [-111.82418, 42.05789, 0],
                [-111.83126, 42.06221, 0],
                [-111.83144, 42.06233, 0],
                [-111.83747, 42.06601, 0],
                [-111.8392, 42.06712, 0],
                [-111.8397, 42.06742, 0],
                [-111.84166, 42.06865, 0],
                [-111.84346, 42.0697, 0],
                [-111.84454, 42.0703, 0],
                [-111.84545, 42.07078, 0],
                [-111.84569, 42.07092, 0],
                [-111.84726, 42.07176, 0],
                [-111.85268, 42.07478, 0],
                [-111.8561, 42.07676, 0],
                [-111.85703, 42.07725, 0],
                [-111.85729, 42.0774, 0],
                [-111.85749, 42.0775, 0],
                [-111.85894, 42.07828, 0],
                [-111.85902, 42.07833, 0],
                [-111.86043, 42.07909, 0],
                [-111.86069, 42.07924, 0],
                [-111.86274, 42.08035, 0],
                [-111.8628, 42.08037, 0],
                [-111.86282, 42.08037, 0],
                [-111.86285, 42.08036, 0],
                [-111.86476, 42.08142, 0],
                [-111.86484, 42.08148, 0],
                [-111.8649, 42.08151, 0],
                [-111.86509, 42.08163, 0],
                [-111.86602, 42.08231, 0],
                [-111.86628, 42.08253, 0],
                [-111.86636, 42.08259, 0],
                [-111.86656, 42.08276, 0],
                [-111.86708, 42.08315, 0],
                [-111.86925, 42.085, 0],
                [-111.8693, 42.08505, 0],
                [-111.87108, 42.08653, 0],
                [-111.87148, 42.08685, 0],
                [-111.87206, 42.08734, 0],
                [-111.87401, 42.08891, 0],
                [-111.87417, 42.08903, 0],
                [-111.87588, 42.09044, 0],
                [-111.87594, 42.0905, 0],
                [-111.87599, 42.09053, 0],
                [-111.87624, 42.09073, 0],
                [-111.87632, 42.09081, 0],
                [-111.87638, 42.09086, 0],
                [-111.87645, 42.09093, 0],
                [-111.87651, 42.091, 0],
                [-111.87652, 42.09103, 0],
                [-111.87656, 42.09109, 0],
                [-111.87657, 42.09113, 0],
                [-111.87659, 42.09116, 0],
                [-111.87661, 42.09123, 0],
                [-111.87662, 42.09125, 0],
                [-111.87662, 42.09127, 0],
                [-111.87663, 42.0913, 0],
                [-111.87663, 42.09134, 0],
                [-111.87664, 42.09142, 0],
                [-111.87664, 42.09235, 0],
                [-111.87665, 42.09258, 0],
                [-111.87667, 42.09628, 0],
                [-111.87665, 42.09837, 0],
                [-111.87669, 42.10826, 0],
                [-111.8767, 42.10832, 0],
                [-111.8767, 42.10844, 0],
                [-111.87671, 42.10848, 0],
                [-111.87672, 42.10856, 0],
                [-111.87672, 42.10858, 0],
                [-111.87667, 42.10863, 0],
                [-111.87666, 42.10865, 0],
                [-111.87666, 42.10866, 0],
                [-111.87665, 42.10866, 0],
                [-111.87665, 42.10898, 0],
                [-111.87663, 42.10914, 0],
                [-111.8766, 42.1093, 0],
                [-111.87658, 42.10938, 0],
                [-111.87656, 42.10942, 0],
                [-111.87656, 42.10944, 0],
                [-111.87655, 42.10946, 0],
                [-111.87653, 42.10952, 0],
                [-111.87651, 42.10955, 0],
                [-111.87649, 42.10959, 0],
                [-111.87648, 42.10962, 0],
                [-111.87646, 42.10965, 0],
                [-111.87644, 42.10969, 0],
                [-111.87638, 42.10977, 0],
                [-111.87637, 42.1098, 0],
                [-111.87635, 42.10982, 0],
                [-111.87626, 42.10994, 0],
                [-111.87623, 42.10996, 0],
                [-111.87622, 42.10998, 0],
                [-111.87622, 42.10999, 0],
                [-111.87621, 42.11, 0],
                [-111.87621, 42.11001, 0],
                [-111.87619, 42.11006, 0],
                [-111.87607, 42.11018, 0],
                [-111.87604, 42.1102, 0],
                [-111.87601, 42.11023, 0],
                [-111.87598, 42.11025, 0],
                [-111.87595, 42.11028, 0],
                [-111.87592, 42.1103, 0],
                [-111.8759, 42.11032, 0],
                [-111.87571, 42.11044, 0],
                [-111.87567, 42.11047, 0],
                [-111.87563, 42.11049, 0],
                [-111.87558, 42.11052, 0],
                [-111.87552, 42.11054, 0],
                [-111.87547, 42.11057, 0],
                [-111.87541, 42.1106, 0],
                [-111.87508, 42.11072, 0],
                [-111.87473, 42.11081, 0],
                [-111.87446, 42.11085, 0],
                [-111.87409, 42.11087, 0],
                [-111.8673, 42.11084, 0],
                [-111.867, 42.11083, 0],
                [-111.86577, 42.11082, 0],
                [-111.86505, 42.11084, 0],
                [-111.86449, 42.11089, 0],
                [-111.86411, 42.11095, 0],
                [-111.86336, 42.11115, 0],
                [-111.86281, 42.11134, 0],
                [-111.86256, 42.11145, 0],
                [-111.86205, 42.11172, 0],
                [-111.86182, 42.11187, 0],
                [-111.86153, 42.11209, 0],
                [-111.8612, 42.11236, 0],
                [-111.86064, 42.11288, 0],
                [-111.85029, 42.1228, 0],
                [-111.84932, 42.12361, 0],
                [-111.84841, 42.12428, 0],
                [-111.84786, 42.12465, 0],
                [-111.84717, 42.12507, 0],
                [-111.837, 42.13096, 0],
                [-111.83623, 42.13139, 0],
                [-111.83601, 42.13154, 0],
                [-111.83517, 42.13219, 0],
                [-111.83477, 42.13254, 0],
                [-111.8344, 42.1329, 0],
                [-111.83411, 42.13322, 0],
                [-111.83385, 42.13357, 0],
                [-111.83361, 42.13392, 0],
                [-111.83334, 42.13434, 0],
                [-111.82942, 42.13995, 0],
                [-111.82899, 42.14054, 0],
                [-111.82875, 42.14091, 0],
                [-111.82858, 42.14112, 0],
                [-111.82839, 42.14137, 0],
                [-111.8275, 42.1427, 0],
                [-111.82741, 42.14282, 0],
                [-111.82649, 42.14414, 0],
                [-111.82601, 42.14486, 0],
                [-111.82571, 42.14537, 0],
                [-111.82548, 42.14567, 0],
                [-111.825, 42.14635, 0],
                [-111.82487, 42.14659, 0],
                [-111.82475, 42.14688, 0],
                [-111.82454, 42.14745, 0],
                [-111.82448, 42.14764, 0],
                [-111.82441, 42.1479, 0],
                [-111.8244, 42.14798, 0],
                [-111.82438, 42.14807, 0],
                [-111.82434, 42.14868, 0],
                [-111.82434, 42.14894, 0],
                [-111.82437, 42.14925, 0],
                [-111.82441, 42.14949, 0],
                [-111.82447, 42.14972, 0],
                [-111.82455, 42.15013, 0],
                [-111.8246, 42.15035, 0],
                [-111.82473, 42.15076, 0],
                [-111.82481, 42.15094, 0],
                [-111.82507, 42.15134, 0],
                [-111.82544, 42.15211, 0],
                [-111.82612, 42.15328, 0],
                [-111.82771, 42.15621, 0],
                [-111.82787, 42.15646, 0],
                [-111.8289, 42.15834, 0],
                [-111.82906, 42.15859, 0],
                [-111.82973, 42.15982, 0],
                [-111.82983, 42.15998, 0],
                [-111.83045, 42.16115, 0],
                [-111.83053, 42.16132, 0],
                [-111.83153, 42.16316, 0],
                [-111.83201, 42.16401, 0],
                [-111.83206, 42.16412, 0],
                [-111.83225, 42.16445, 0],
                [-111.8327, 42.16529, 0],
                [-111.83296, 42.16569, 0],
                [-111.83316, 42.16608, 0],
                [-111.83424, 42.16806, 0],
                [-111.83432, 42.16822, 0],
                [-111.83433, 42.16823, 0],
                [-111.83495, 42.1694, 0],
                [-111.83495, 42.16941, 0],
                [-111.83504, 42.16957, 0],
                [-111.83583, 42.17108, 0],
                [-111.83621, 42.17174, 0],
                [-111.83676, 42.17275, 0],
                [-111.83691, 42.17299, 0],
                [-111.83707, 42.1733, 0],
                [-111.83717, 42.17356, 0],
                [-111.83736, 42.17401, 0],
                [-111.83756, 42.17473, 0],
                [-111.83768, 42.17531, 0],
                [-111.8377, 42.17568, 0],
                [-111.8377, 42.17589, 0],
                [-111.83768, 42.17621, 0],
                [-111.83768, 42.1763, 0],
                [-111.83762, 42.17692, 0],
                [-111.83675, 42.18188, 0],
                [-111.83673, 42.18206, 0],
                [-111.83668, 42.18234, 0],
                [-111.83668, 42.18238, 0],
                [-111.8366, 42.18279, 0],
                [-111.83658, 42.18287, 0],
                [-111.83624, 42.18477, 0],
                [-111.83603, 42.18609, 0],
                [-111.83599, 42.18623, 0],
                [-111.83593, 42.1865, 0],
                [-111.8359, 42.18659, 0],
                [-111.83585, 42.18677, 0],
                [-111.8358, 42.1869, 0],
                [-111.83576, 42.18704, 0],
                [-111.83566, 42.1873, 0],
                [-111.8356, 42.18743, 0],
                [-111.83559, 42.18746, 0],
                [-111.83554, 42.18756, 0],
                [-111.83548, 42.1877, 0],
                [-111.83528, 42.18808, 0],
                [-111.83515, 42.18831, 0],
                [-111.83484, 42.18879, 0],
                [-111.8344, 42.18941, 0],
                [-111.83427, 42.18957, 0],
                [-111.83415, 42.18974, 0],
                [-111.83406, 42.18985, 0],
                [-111.83389, 42.19008, 0],
                [-111.83378, 42.19024, 0],
                [-111.83358, 42.19056, 0],
                [-111.83344, 42.19082, 0],
                [-111.8333, 42.19112, 0],
                [-111.8332, 42.1914, 0],
                [-111.83312, 42.19166, 0],
                [-111.83304, 42.19203, 0],
                [-111.83303, 42.19216, 0],
                [-111.83302, 42.19222, 0],
                [-111.83302, 42.19224, 0],
                [-111.833, 42.19247, 0],
                [-111.833, 42.19283, 0],
                [-111.83302, 42.19304, 0],
                [-111.8331, 42.19353, 0],
                [-111.83321, 42.19391, 0],
                [-111.83341, 42.19438, 0],
                [-111.83354, 42.19464, 0],
                [-111.83367, 42.19485, 0],
                [-111.83378, 42.19501, 0],
                [-111.83396, 42.19525, 0],
                [-111.83417, 42.1956, 0],
                [-111.83489, 42.19661, 0],
                [-111.83493, 42.19665, 0],
                [-111.83635, 42.19861, 0],
                [-111.83664, 42.19904, 0],
                [-111.83715, 42.1999, 0],
                [-111.83716, 42.19991, 0],
                [-111.83735, 42.20027, 0],
                [-111.83892, 42.2035, 0],
                [-111.83892, 42.20351, 0],
                [-111.84083, 42.20742, 0],
                [-111.84115, 42.20805, 0],
                [-111.84342, 42.21269, 0],
                [-111.84387, 42.21375, 0],
                [-111.84408, 42.21435, 0],
                [-111.84415, 42.21459, 0],
                [-111.84432, 42.21527, 0],
                [-111.84447, 42.21596, 0],
                [-111.84455, 42.21647, 0],
                [-111.84478, 42.21828, 0],
                [-111.84481, 42.21844, 0],
                [-111.84515, 42.22107, 0],
                [-111.84516, 42.2211, 0],
                [-111.84539, 42.22297, 0],
                [-111.84561, 42.22451, 0],
                [-111.84561, 42.22457, 0],
                [-111.84589, 42.22672, 0],
                [-111.84598, 42.2272, 0],
                [-111.84609, 42.22817, 0],
                [-111.84612, 42.22835, 0],
                [-111.84621, 42.22906, 0],
                [-111.84629, 42.22987, 0],
                [-111.84629, 42.23011, 0],
                [-111.84632, 42.23091, 0],
                [-111.84639, 42.23118, 0],
                [-111.84639, 42.23146, 0],
                [-111.84636, 42.23199, 0],
                [-111.84636, 42.23213, 0],
                [-111.84634, 42.23269, 0],
                [-111.84632, 42.23298, 0],
                [-111.84631, 42.2334, 0],
                [-111.84627, 42.23408, 0],
                [-111.84612, 42.23587, 0],
                [-111.84611, 42.23607, 0],
                [-111.84603, 42.23706, 0],
                [-111.8459, 42.23911, 0],
                [-111.8458, 42.24019, 0],
                [-111.84565, 42.24099, 0],
                [-111.84552, 42.24155, 0],
                [-111.84539, 42.24202, 0],
                [-111.84506, 42.24307, 0],
                [-111.84466, 42.24396, 0],
                [-111.84429, 42.2447, 0],
                [-111.844, 42.2452, 0],
                [-111.84357, 42.24587, 0],
                [-111.84237, 42.24764, 0],
                [-111.84151, 42.24886, 0],
                [-111.84127, 42.24922, 0],
                [-111.84057, 42.25022, 0],
                [-111.84051, 42.25033, 0],
                [-111.84034, 42.25056, 0],
                [-111.83968, 42.25153, 0],
                [-111.83817, 42.25389, 0],
                [-111.8375, 42.25501, 0],
                [-111.83729, 42.25539, 0],
                [-111.83706, 42.25577, 0],
                [-111.83689, 42.25602, 0],
                [-111.83616, 42.25727, 0],
                [-111.8356, 42.25811, 0],
                [-111.83529, 42.25847, 0],
                [-111.83508, 42.2587, 0],
                [-111.83475, 42.25902, 0],
                [-111.83452, 42.25922, 0],
                [-111.83437, 42.25936, 0],
                [-111.83426, 42.25945, 0],
                [-111.83338, 42.26024, 0],
                [-111.83016, 42.26329, 0],
                [-111.82988, 42.26352, 0],
                [-111.82918, 42.26416, 0],
                [-111.82857, 42.26477, 0],
                [-111.8283, 42.26502, 0],
                [-111.82779, 42.26544, 0],
                [-111.82766, 42.26553, 0],
                [-111.82742, 42.26574, 0],
                [-111.82682, 42.26623, 0],
                [-111.82657, 42.26642, 0],
                [-111.82616, 42.26671, 0],
                [-111.82589, 42.26689, 0],
                [-111.82538, 42.26728, 0],
                [-111.82489, 42.26763, 0],
                [-111.82452, 42.26788, 0],
                [-111.82294, 42.26902, 0],
                [-111.8228, 42.26911, 0],
                [-111.81909, 42.27177, 0],
                [-111.81735, 42.27299, 0],
                [-111.81706, 42.27318, 0],
                [-111.81567, 42.27418, 0],
                [-111.81542, 42.27437, 0],
                [-111.81515, 42.27456, 0],
                [-111.81498, 42.2747, 0],
                [-111.81491, 42.27475, 0],
                [-111.81407, 42.27552, 0],
                [-111.81346, 42.27619, 0],
                [-111.81328, 42.27643, 0],
                [-111.81312, 42.27668, 0],
                [-111.81261, 42.27758, 0],
                [-111.8104, 42.282, 0],
                [-111.81009, 42.28253, 0],
                [-111.80989, 42.28285, 0],
                [-111.80967, 42.28317, 0],
                [-111.8093, 42.28359, 0],
                [-111.80905, 42.28386, 0],
                [-111.80843, 42.28446, 0],
                [-111.80797, 42.28488, 0],
                [-111.80764, 42.28522, 0],
                [-111.80732, 42.28553, 0],
                [-111.80651, 42.28627, 0],
                [-111.80278, 42.28982, 0],
                [-111.8022, 42.29033, 0],
                [-111.80153, 42.291, 0],
                [-111.80093, 42.29169, 0],
                [-111.80061, 42.29209, 0],
                [-111.79719, 42.29671, 0],
                [-111.79698, 42.29697, 0],
                [-111.79619, 42.29809, 0],
                [-111.79437, 42.30055, 0],
                [-111.79412, 42.30086, 0],
                [-111.79411, 42.30088, 0],
                [-111.79383, 42.30122, 0],
                [-111.79313, 42.30201, 0],
                [-111.7923, 42.30284, 0],
                [-111.79184, 42.30327, 0],
                [-111.79123, 42.30379, 0],
                [-111.79044, 42.30443, 0],
                [-111.78852, 42.30585, 0],
                [-111.78838, 42.30596, 0],
                [-111.78786, 42.30634, 0],
                [-111.78601, 42.30774, 0],
                [-111.78566, 42.30799, 0],
                [-111.77666, 42.31473, 0],
                [-111.77601, 42.3152, 0],
                [-111.77545, 42.31556, 0],
                [-111.77516, 42.31573, 0],
                [-111.7749, 42.31586, 0],
                [-111.77487, 42.31588, 0],
                [-111.77423, 42.31619, 0],
                [-111.77392, 42.31632, 0],
                [-111.77338, 42.31653, 0],
                [-111.77278, 42.31673, 0],
                [-111.77252, 42.3168, 0],
                [-111.7721, 42.31693, 0],
                [-111.76819, 42.318, 0],
                [-111.76767, 42.31818, 0],
                [-111.76719, 42.3184, 0],
                [-111.76698, 42.31852, 0],
                [-111.76677, 42.31865, 0],
                [-111.76641, 42.31893, 0],
                [-111.76624, 42.31909, 0],
                [-111.76604, 42.3193, 0],
                [-111.76591, 42.31946, 0],
                [-111.76573, 42.31973, 0],
                [-111.76525, 42.32051, 0],
                [-111.76456, 42.32174, 0],
                [-111.76427, 42.32223, 0],
                [-111.76427, 42.32224, 0],
                [-111.76377, 42.32303, 0],
                [-111.7635, 42.3235, 0],
                [-111.76323, 42.32425, 0],
                [-111.76319, 42.32449, 0],
                [-111.76306, 42.32567, 0],
                [-111.76302, 42.32591, 0],
                [-111.76295, 42.32621, 0],
                [-111.76283, 42.32663, 0],
                [-111.76282, 42.32669, 0],
                [-111.7626, 42.32732, 0],
                [-111.76246, 42.32766, 0],
                [-111.76225, 42.32813, 0],
                [-111.76201, 42.32856, 0],
                [-111.7619, 42.32871, 0],
                [-111.76161, 42.32904, 0],
                [-111.76146, 42.32919, 0],
                [-111.76119, 42.32943, 0],
                [-111.76093, 42.32962, 0],
                [-111.76071, 42.32977, 0],
                [-111.76054, 42.32987, 0],
                [-111.7603, 42.33, 0],
                [-111.76004, 42.33013, 0],
                [-111.75975, 42.33025, 0],
                [-111.75937, 42.33039, 0],
                [-111.75898, 42.3305, 0],
                [-111.75868, 42.33057, 0],
                [-111.75838, 42.33063, 0],
                [-111.75809, 42.33067, 0],
                [-111.75744, 42.33072, 0],
                [-111.75703, 42.33072, 0],
                [-111.75388, 42.33054, 0],
                [-111.75315, 42.33051, 0],
                [-111.75259, 42.33051, 0],
                [-111.75242, 42.33052, 0],
                [-111.75223, 42.33052, 0],
                [-111.75187, 42.33054, 0],
                [-111.75118, 42.3306, 0],
                [-111.75094, 42.33063, 0],
                [-111.75022, 42.33075, 0],
                [-111.74971, 42.33086, 0],
                [-111.73512, 42.33439, 0],
                [-111.73476, 42.33449, 0],
                [-111.73409, 42.33471, 0],
                [-111.73358, 42.3349, 0],
                [-111.73281, 42.33523, 0],
                [-111.73231, 42.33549, 0],
                [-111.73204, 42.33564, 0],
                [-111.73192, 42.33572, 0],
                [-111.73148, 42.33599, 0],
                [-111.73103, 42.33632, 0],
                [-111.73003, 42.33712, 0],
                [-111.72996, 42.33717, 0],
                [-111.72935, 42.33766, 0],
                [-111.72884, 42.33805, 0],
                [-111.72826, 42.33843, 0],
                [-111.72783, 42.33866, 0],
                [-111.7276, 42.33877, 0],
                [-111.72736, 42.33887, 0],
                [-111.72671, 42.33912, 0],
                [-111.72601, 42.33931, 0],
                [-111.72544, 42.33943, 0],
                [-111.72509, 42.33948, 0],
                [-111.72426, 42.33955, 0],
                [-111.72402, 42.33956, 0],
                [-111.72363, 42.33956, 0],
                [-111.72345, 42.33955, 0],
                [-111.72116, 42.3395, 0],
                [-111.72072, 42.3395, 0],
                [-111.7205, 42.33951, 0],
                [-111.72027, 42.33953, 0],
                [-111.71987, 42.33958, 0],
                [-111.71962, 42.33963, 0],
                [-111.71925, 42.33972, 0],
                [-111.71878, 42.33988, 0],
                [-111.7185, 42.34001, 0],
                [-111.71819, 42.34017, 0],
                [-111.718, 42.34029, 0],
                [-111.71798, 42.34031, 0],
                [-111.71787, 42.34038, 0],
                [-111.71783, 42.34042, 0],
                [-111.71778, 42.34045, 0],
                [-111.71768, 42.34053, 0],
                [-111.71752, 42.34067, 0],
                [-111.71733, 42.34085, 0],
                [-111.7171, 42.34112, 0],
                [-111.7171, 42.34113, 0],
                [-111.71705, 42.34121, 0],
                [-111.71699, 42.34129, 0],
                [-111.7169, 42.34144, 0],
                [-111.71679, 42.34164, 0],
                [-111.71648, 42.3423, 0],
                [-111.71544, 42.34463, 0],
                [-111.71525, 42.34502, 0],
                [-111.71511, 42.34526, 0],
                [-111.71494, 42.34552, 0],
                [-111.71483, 42.34565, 0],
                [-111.71473, 42.34575, 0],
                [-111.71463, 42.34586, 0],
                [-111.71453, 42.34596, 0],
                [-111.71428, 42.34618, 0],
                [-111.713, 42.34718, 0],
                [-111.71277, 42.34734, 0],
                [-111.71221, 42.34779, 0],
                [-111.71199, 42.34803, 0],
                [-111.71178, 42.34828, 0],
                [-111.71169, 42.34843, 0],
                [-111.71153, 42.34872, 0],
                [-111.71148, 42.34884, 0],
                [-111.71138, 42.34914, 0],
                [-111.71134, 42.3493, 0],
                [-111.71134, 42.34931, 0],
                [-111.71131, 42.34948, 0],
                [-111.7113, 42.34962, 0],
                [-111.71128, 42.35302, 0],
                [-111.71127, 42.35331, 0],
                [-111.71131, 42.35401, 0],
                [-111.71134, 42.35423, 0],
                [-111.7114, 42.35453, 0],
                [-111.71145, 42.35473, 0],
                [-111.71157, 42.35507, 0],
                [-111.71173, 42.35544, 0],
                [-111.71289, 42.35774, 0],
                [-111.71324, 42.3584, 0],
                [-111.71371, 42.35917, 0],
                [-111.71398, 42.35954, 0],
                [-111.71433, 42.35999, 0],
                [-111.71464, 42.36034, 0],
                [-111.71507, 42.36078, 0],
                [-111.71716, 42.36269, 0],
                [-111.71728, 42.36281, 0],
                [-111.71731, 42.36283, 0],
                [-111.71808, 42.36353, 0],
                [-111.71832, 42.36373, 0],
                [-111.71878, 42.36417, 0],
                [-111.71947, 42.36479, 0],
                [-111.71992, 42.36523, 0],
                [-111.72005, 42.36537, 0],
                [-111.72017, 42.36552, 0],
                [-111.72021, 42.36558, 0],
                [-111.7203, 42.36569, 0],
                [-111.72051, 42.36599, 0],
                [-111.72059, 42.36613, 0],
                [-111.72068, 42.36627, 0],
                [-111.72091, 42.36673, 0],
                [-111.72107, 42.36713, 0],
                [-111.72118, 42.36749, 0],
                [-111.72125, 42.36782, 0],
                [-111.72129, 42.36814, 0],
                [-111.7213, 42.36831, 0],
                [-111.7213, 42.3684, 0],
                [-111.72131, 42.3685, 0],
                [-111.7213, 42.36887, 0],
                [-111.72124, 42.36935, 0],
                [-111.7212, 42.36958, 0],
                [-111.72116, 42.36973, 0],
                [-111.7211, 42.36989, 0],
                [-111.72104, 42.37009, 0],
                [-111.72096, 42.37031, 0],
                [-111.72086, 42.37054, 0],
                [-111.72076, 42.37075, 0],
                [-111.72051, 42.37117, 0],
                [-111.72024, 42.37156, 0],
                [-111.72021, 42.37159, 0],
                [-111.72007, 42.37177, 0],
                [-111.71992, 42.37194, 0],
                [-111.71973, 42.37214, 0],
                [-111.71938, 42.37247, 0],
                [-111.71896, 42.37282, 0],
                [-111.71709, 42.37426, 0],
                [-111.71687, 42.37442, 0],
                [-111.71549, 42.3755, 0],
                [-111.71527, 42.37569, 0],
                [-111.71512, 42.37583, 0],
                [-111.71494, 42.37601, 0],
                [-111.71471, 42.37626, 0],
                [-111.71449, 42.37653, 0],
                [-111.71439, 42.37667, 0],
                [-111.71421, 42.37695, 0],
                [-111.71392, 42.37751, 0],
                [-111.71378, 42.37793, 0],
                [-111.71377, 42.37798, 0],
                [-111.71366, 42.37837, 0],
                [-111.71361, 42.37878, 0],
                [-111.7136, 42.37917, 0],
                [-111.71366, 42.37966, 0],
                [-111.71368, 42.37977, 0],
                [-111.7137, 42.37994, 0],
                [-111.71372, 42.38006, 0],
                [-111.71378, 42.38023, 0],
                [-111.71413, 42.3815, 0],
                [-111.71616, 42.38854, 0],
                [-111.71629, 42.38896, 0],
                [-111.71641, 42.3896, 0],
                [-111.71646, 42.39019, 0],
                [-111.71646, 42.39055, 0],
                [-111.71645, 42.39087, 0],
                [-111.71638, 42.39143, 0],
                [-111.71627, 42.39194, 0],
                [-111.71591, 42.3932, 0],
                [-111.71581, 42.39361, 0],
                [-111.71578, 42.39379, 0],
                [-111.71576, 42.39402, 0],
                [-111.71576, 42.39409, 0],
                [-111.71575, 42.39426, 0],
                [-111.71576, 42.39447, 0],
                [-111.71578, 42.39469, 0],
                [-111.71581, 42.39491, 0],
                [-111.71591, 42.39527, 0],
                [-111.71609, 42.39573, 0],
                [-111.71627, 42.39605, 0],
                [-111.7164, 42.39625, 0],
                [-111.71678, 42.39676, 0],
                [-111.71693, 42.39691, 0],
                [-111.7181, 42.39801, 0],
                [-111.71879, 42.39869, 0],
                [-111.7191, 42.39901, 0],
                [-111.71936, 42.3993, 0],
                [-111.71943, 42.39937, 0],
                [-111.71999, 42.40004, 0],
                [-111.72, 42.40006, 0],
                [-111.72083, 42.40124, 0],
                [-111.72543, 42.40808, 0],
                [-111.7255, 42.40817, 0],
                [-111.72593, 42.40881, 0],
                [-111.72636, 42.40951, 0],
                [-111.72648, 42.40973, 0],
                [-111.7266, 42.41001, 0],
                [-111.72666, 42.41017, 0],
                [-111.72672, 42.41038, 0],
                [-111.72676, 42.41057, 0],
                [-111.72684, 42.41106, 0],
                [-111.72684, 42.41127, 0],
                [-111.72683, 42.41148, 0],
                [-111.72679, 42.41185, 0],
                [-111.72671, 42.4122, 0],
                [-111.72665, 42.41238, 0],
                [-111.72655, 42.4126, 0],
                [-111.72644, 42.41288, 0],
                [-111.72627, 42.41326, 0],
                [-111.72532, 42.41521, 0],
                [-111.72506, 42.41577, 0],
                [-111.72499, 42.41594, 0],
                [-111.72482, 42.41628, 0],
                [-111.72404, 42.418, 0],
                [-111.72283, 42.42058, 0],
                [-111.72279, 42.42068, 0],
                [-111.7222, 42.42193, 0],
                [-111.72173, 42.42307, 0],
                [-111.72149, 42.42385, 0],
                [-111.72135, 42.42439, 0],
                [-111.72128, 42.42471, 0],
                [-111.72113, 42.4256, 0],
                [-111.72109, 42.426, 0],
                [-111.72105, 42.42684, 0],
                [-111.72083, 42.43552, 0],
                [-111.72084, 42.43581, 0],
                [-111.72084, 42.43609, 0],
                [-111.72085, 42.43634, 0],
                [-111.72093, 42.43716, 0],
                [-111.72098, 42.43752, 0],
                [-111.7211, 42.43819, 0],
                [-111.72141, 42.43944, 0],
                [-111.72143, 42.4395, 0],
                [-111.72206, 42.44198, 0],
                [-111.7221, 42.44222, 0],
                [-111.72215, 42.44259, 0],
                [-111.72219, 42.44311, 0],
                [-111.72219, 42.44345, 0],
                [-111.72218, 42.44369, 0],
                [-111.72216, 42.44391, 0],
                [-111.72214, 42.44401, 0],
                [-111.72212, 42.44419, 0],
                [-111.72212, 42.44421, 0],
                [-111.72207, 42.44443, 0],
                [-111.72203, 42.44464, 0],
                [-111.72192, 42.44507, 0],
                [-111.72191, 42.44508, 0],
                [-111.72187, 42.44525, 0],
                [-111.72123, 42.44761, 0],
                [-111.72112, 42.4481, 0],
                [-111.72105, 42.44847, 0],
                [-111.72095, 42.44915, 0],
                [-111.72091, 42.44956, 0],
                [-111.72088, 42.44998, 0],
                [-111.72083, 42.4541, 0],
                [-111.72085, 42.45494, 0],
                [-111.72088, 42.4555, 0],
                [-111.72093, 42.45606, 0],
                [-111.72093, 42.45608, 0],
                [-111.72095, 42.45626, 0],
                [-111.72098, 42.45644, 0],
                [-111.72098, 42.45646, 0],
                [-111.72104, 42.45679, 0],
                [-111.72362, 42.46813, 0],
                [-111.72377, 42.46886, 0],
                [-111.72398, 42.46975, 0],
                [-111.72407, 42.47007, 0],
                [-111.72423, 42.47058, 0],
                [-111.72449, 42.47129, 0],
                [-111.72464, 42.47167, 0],
                [-111.72481, 42.47203, 0],
                [-111.72492, 42.47229, 0],
                [-111.72499, 42.47242, 0],
                [-111.72524, 42.47293, 0],
                [-111.72553, 42.47347, 0],
                [-111.72602, 42.47424, 0],
                [-111.72649, 42.47491, 0],
                [-111.72685, 42.47538, 0],
                [-111.72689, 42.47544, 0],
                [-111.72761, 42.47627, 0],
                [-111.72914, 42.4779, 0],
                [-111.72922, 42.47797, 0],
                [-111.72974, 42.47853, 0],
                [-111.72996, 42.47883, 0],
                [-111.73007, 42.47895, 0],
                [-111.73016, 42.4791, 0],
                [-111.73018, 42.47912, 0],
                [-111.73025, 42.47923, 0],
                [-111.73042, 42.47955, 0],
                [-111.7305, 42.47973, 0],
                [-111.73063, 42.48007, 0],
                [-111.73069, 42.48029, 0],
                [-111.73074, 42.48052, 0],
                [-111.73078, 42.48078, 0],
                [-111.7308, 42.48103, 0],
                [-111.7308, 42.48195, 0],
                [-111.73078, 42.48265, 0],
                [-111.73077, 42.48457, 0],
                [-111.73075, 42.48512, 0],
                [-111.73074, 42.48902, 0],
                [-111.73072, 42.49043, 0],
                [-111.73073, 42.49076, 0],
                [-111.73073, 42.49169, 0],
                [-111.73072, 42.49186, 0],
                [-111.73062, 42.49793, 0],
                [-111.73061, 42.49811, 0],
                [-111.73055, 42.50292, 0],
                [-111.73056, 42.50435, 0],
                [-111.73055, 42.50519, 0],
                [-111.73056, 42.51011, 0],
                [-111.73055, 42.51053, 0],
                [-111.73056, 42.51093, 0],
                [-111.73055, 42.51352, 0],
                [-111.73056, 42.51409, 0],
                [-111.73053, 42.52352, 0],
                [-111.73054, 42.52369, 0],
                [-111.73044, 42.54964, 0],
                [-111.73045, 42.54999, 0],
                [-111.73044, 42.5504, 0],
                [-111.73044, 42.55193, 0],
                [-111.73045, 42.55206, 0],
                [-111.73047, 42.55444, 0],
                [-111.73043, 42.55476, 0],
                [-111.73039, 42.58421, 0],
                [-111.73033, 42.59166, 0],
                [-111.73034, 42.59166, 0],
                [-111.73034, 42.59167, 0],
                [-111.73035, 42.59168, 0],
                [-111.73035, 42.59248, 0],
                [-111.73033, 42.59319, 0],
                [-111.73032, 42.59492, 0],
                [-111.7303, 42.59564, 0],
                [-111.7302, 42.60331, 0],
                [-111.73014, 42.61245, 0],
                [-111.73013, 42.61272, 0],
                [-111.73013, 42.61342, 0],
                [-111.73012, 42.6136, 0],
                [-111.73009, 42.61996, 0],
                [-111.7301, 42.62016, 0],
                [-111.73008, 42.62175, 0],
                [-111.73009, 42.62682, 0],
                [-111.73008, 42.62686, 0],
                [-111.73007, 42.62956, 0],
                [-111.73005, 42.63059, 0],
                [-111.73008, 42.63502, 0],
                [-111.73007, 42.6357, 0],
                [-111.73008, 42.63666, 0],
                [-111.73007, 42.63886, 0],
                [-111.73008, 42.639, 0],
                [-111.73014, 42.64255, 0],
                [-111.73015, 42.64259, 0],
                [-111.73024, 42.64898, 0],
                [-111.72613, 42.64897, 0],
                [-111.72577, 42.64898, 0],
                [-111.72503, 42.64898, 0],
                [-111.72154, 42.64891, 0],
                [-111.72093, 42.64892, 0],
                [-111.7208, 42.64895, 0],
                [-111.71816, 42.64895, 0],
                [-111.71758, 42.64896, 0],
                [-111.71525, 42.64907, 0],
                [-111.7134, 42.64923, 0],
                [-111.71139, 42.64946, 0],
                [-111.70924, 42.6498, 0],
                [-111.70322, 42.65101, 0],
                [-111.70297, 42.65107, 0],
                [-111.70278, 42.65107, 0],
                [-111.70274, 42.65108, 0],
                [-111.7027, 42.65108, 0],
                [-111.70067, 42.65148, 0],
                [-111.69857, 42.65193, 0],
                [-111.69855, 42.65194, 0],
                [-111.69852, 42.65195, 0],
                [-111.69846, 42.65198, 0],
                [-111.69804, 42.65207, 0],
                [-111.69699, 42.65224, 0],
                [-111.67912, 42.65469, 0],
                [-111.67885, 42.65469, 0],
                [-111.67811, 42.65478, 0],
                [-111.67764, 42.65485, 0],
                [-111.67729, 42.65489, 0],
                [-111.6748, 42.65525, 0],
                [-111.67476, 42.65526, 0],
                [-111.67474, 42.65526, 0],
                [-111.67464, 42.6553, 0],
                [-111.6729, 42.65555, 0],
                [-111.67229, 42.65565, 0],
                [-111.66925, 42.65622, 0],
                [-111.66871, 42.65631, 0],
                [-111.66227, 42.65719, 0],
                [-111.66199, 42.65722, 0],
                [-111.65627, 42.658, 0],
                [-111.65551, 42.65813, 0],
                [-111.6554, 42.65812, 0],
                [-111.65536, 42.65812, 0],
                [-111.65533, 42.65813, 0],
                [-111.65427, 42.65835, 0],
                [-111.65256, 42.65882, 0],
                [-111.65131, 42.65919, 0],
                [-111.65129, 42.65919, 0],
                [-111.65128, 42.6592, 0],
                [-111.65121, 42.65925, 0],
                [-111.64984, 42.65962, 0],
                [-111.64961, 42.65969, 0],
                [-111.64462, 42.66107, 0],
                [-111.64333, 42.66141, 0],
                [-111.64257, 42.66155, 0],
                [-111.64246, 42.66154, 0],
                [-111.6424, 42.66154, 0],
                [-111.64161, 42.66159, 0],
                [-111.64083, 42.66159, 0],
                [-111.64067, 42.66158, 0],
                [-111.64053, 42.66158, 0],
                [-111.64008, 42.66154, 0],
                [-111.63973, 42.66149, 0],
                [-111.63938, 42.66143, 0],
                [-111.63848, 42.66122, 0],
                [-111.63768, 42.661, 0],
                [-111.6351, 42.66034, 0],
                [-111.63506, 42.66034, 0],
                [-111.63504, 42.66033, 0],
                [-111.63491, 42.66033, 0],
                [-111.61505, 42.65513, 0],
                [-111.61477, 42.65507, 0],
                [-111.61454, 42.65501, 0],
                [-111.6143, 42.65496, 0],
                [-111.61373, 42.65487, 0],
                [-111.61334, 42.65482, 0],
                [-111.61294, 42.65478, 0],
                [-111.61253, 42.65475, 0],
                [-111.6121, 42.65473, 0],
                [-111.60231, 42.65468, 0],
                [-111.60188, 42.65467, 0],
                [-111.60098, 42.65467, 0],
                [-111.60067, 42.65466, 0],
                [-111.59615, 42.65464, 0],
                [-111.59481, 42.65465, 0],
                [-111.59481, 42.65476, 0],
                [-111.59482, 42.65494, 0],
                [-111.59482, 42.65512, 0],
                [-111.59483, 42.65534, 0],
                [-111.59484, 42.65614, 0],
                [-111.59485, 42.65632, 0],
                [-111.59485, 42.6568, 0],
                [-111.59481, 42.65787, 0],
                [-111.5948, 42.65793, 0],
                [-111.5948, 42.65823, 0],
                [-111.59479, 42.65841, 0],
                [-111.59479, 42.65898, 0],
                [-111.59303, 42.65898, 0],
                [-111.59182, 42.65896, 0],
                [-111.59045, 42.65896, 0],
                [-111.59014, 42.65895, 0],
                [-111.58779, 42.65895, 0],
                [-111.58753, 42.65896, 0],
                [-111.58723, 42.65899, 0],
                [-111.58686, 42.65906, 0],
                [-111.58652, 42.65915, 0],
                [-111.58624, 42.65925, 0],
                [-111.58603, 42.65934, 0],
                [-111.58587, 42.65942, 0],
                [-111.58547, 42.65966, 0],
                [-111.58514, 42.65993, 0],
                [-111.58501, 42.66006, 0],
                [-111.58488, 42.66021, 0],
                [-111.58476, 42.66036, 0],
                [-111.58455, 42.66072, 0],
                [-111.58448, 42.66089, 0],
                [-111.58443, 42.66105, 0],
                [-111.58438, 42.66127, 0],
                [-111.58436, 42.66147, 0],
                [-111.58439, 42.66525, 0],
                [-111.5844, 42.6655, 0],
                [-111.58441, 42.66821, 0],
                [-111.58442, 42.66838, 0],
                [-111.58442, 42.67011, 0],
                [-111.58441, 42.67049, 0],
                [-111.58443, 42.67634, 0],
                [-111.58445, 42.67667, 0],
                [-111.58445, 42.67928, 0],
                [-111.58442, 42.68007, 0],
                [-111.58441, 42.68018, 0],
                [-111.58437, 42.68042, 0],
                [-111.5843, 42.68073, 0],
                [-111.58425, 42.6809, 0],
                [-111.5841, 42.68126, 0],
                [-111.58397, 42.68153, 0],
                [-111.58386, 42.68172, 0],
                [-111.58375, 42.68189, 0],
                [-111.58312, 42.68274, 0],
                [-111.58222, 42.6839, 0],
                [-111.58206, 42.68408, 0],
                [-111.5819, 42.68429, 0],
                [-111.58172, 42.68455, 0],
                [-111.58161, 42.68469, 0],
                [-111.58151, 42.68485, 0],
                [-111.58139, 42.68502, 0],
                [-111.58129, 42.68519, 0],
                [-111.58108, 42.6856, 0],
                [-111.58096, 42.68586, 0],
                [-111.5807, 42.6865, 0],
                [-111.57862, 42.69188, 0],
                [-111.57842, 42.69237, 0],
                [-111.57817, 42.69292, 0],
                [-111.57784, 42.69356, 0],
                [-111.57751, 42.69415, 0],
                [-111.57729, 42.6945, 0],
                [-111.57695, 42.69499, 0],
                [-111.57622, 42.69593, 0],
                [-111.57583, 42.69637, 0],
                [-111.57581, 42.6964, 0],
                [-111.5741, 42.69833, 0],
                [-111.57401, 42.69844, 0],
                [-111.57314, 42.69938, 0],
                [-111.57265, 42.69997, 0],
                [-111.57038, 42.70248, 0],
                [-111.56983, 42.70314, 0],
                [-111.56943, 42.70368, 0],
                [-111.56918, 42.70406, 0],
                [-111.56872, 42.7048, 0],
                [-111.56865, 42.70493, 0],
                [-111.56509, 42.71103, 0],
                [-111.56485, 42.71152, 0],
                [-111.56472, 42.71181, 0],
                [-111.56467, 42.71199, 0],
                [-111.56461, 42.71225, 0],
                [-111.56457, 42.71251, 0],
                [-111.56454, 42.71291, 0],
                [-111.5645, 42.74129, 0],
                [-111.56449, 42.74164, 0],
                [-111.5644, 42.76487, 0],
                [-111.56439, 42.76503, 0],
                [-111.56423, 42.78101, 0],
                [-111.56422, 42.7811, 0],
                [-111.56383, 42.81402, 0],
                [-111.56381, 42.81434, 0],
                [-111.56379, 42.81446, 0],
                [-111.56378, 42.81459, 0],
                [-111.56375, 42.81473, 0],
                [-111.56367, 42.815, 0],
                [-111.56348, 42.81546, 0],
                [-111.56328, 42.81577, 0],
                [-111.56321, 42.81589, 0],
                [-111.56301, 42.81613, 0],
                [-111.5629, 42.81624, 0],
                [-111.56279, 42.81636, 0],
                [-111.56234, 42.81681, 0],
                [-111.55588, 42.82302, 0],
                [-111.55552, 42.82343, 0],
                [-111.55529, 42.82374, 0],
                [-111.55523, 42.82384, 0],
                [-111.5551, 42.82403, 0],
                [-111.55486, 42.82447, 0],
                [-111.55475, 42.82471, 0],
                [-111.55461, 42.82505, 0],
                [-111.55445, 42.82558, 0],
                [-111.55437, 42.82581, 0],
                [-111.55431, 42.82604, 0],
                [-111.55406, 42.8268, 0],
                [-111.55396, 42.82722, 0],
                [-111.55392, 42.82757, 0],
                [-111.55391, 42.82781, 0],
                [-111.55392, 42.82802, 0],
                [-111.55393, 42.82879, 0],
                [-111.55392, 42.82912, 0],
                [-111.5539, 42.82935, 0],
                [-111.5539, 42.82939, 0],
                [-111.55387, 42.82952, 0],
                [-111.55385, 42.82964, 0],
                [-111.5538, 42.82982, 0],
                [-111.55372, 42.83005, 0],
                [-111.55365, 42.8302, 0],
                [-111.55359, 42.83031, 0],
                [-111.55345, 42.83054, 0],
                [-111.55328, 42.83074, 0],
                [-111.55302, 42.831, 0],
                [-111.55173, 42.83213, 0],
                [-111.55151, 42.83231, 0],
                [-111.54972, 42.83385, 0],
                [-111.54942, 42.83418, 0],
                [-111.5493, 42.83434, 0],
                [-111.54927, 42.83437, 0],
                [-111.54919, 42.83448, 0],
                [-111.54908, 42.83469, 0],
                [-111.54894, 42.83502, 0],
                [-111.54885, 42.83539, 0],
                [-111.54881, 42.83575, 0],
                [-111.54881, 42.83586, 0],
                [-111.5488, 42.83593, 0],
                [-111.5488, 42.83611, 0],
                [-111.54879, 42.83623, 0],
                [-111.54878, 42.83683, 0],
                [-111.54877, 42.83703, 0],
                [-111.54877, 42.83753, 0],
                [-111.54873, 42.8387, 0],
                [-111.5487, 42.83916, 0],
                [-111.54867, 42.8393, 0],
                [-111.54865, 42.83942, 0],
                [-111.54862, 42.83952, 0],
                [-111.54861, 42.83957, 0],
                [-111.54857, 42.83966, 0],
                [-111.54853, 42.83977, 0],
                [-111.54824, 42.84038, 0],
                [-111.54823, 42.84041, 0],
                [-111.54775, 42.84138, 0],
                [-111.54774, 42.84139, 0],
                [-111.54661, 42.84367, 0],
                [-111.54651, 42.84389, 0],
                [-111.5465, 42.84393, 0],
                [-111.54633, 42.84438, 0],
                [-111.54598, 42.84563, 0],
                [-111.54551, 42.84744, 0],
                [-111.54549, 42.8475, 0],
                [-111.54498, 42.84945, 0],
                [-111.5447, 42.85046, 0],
                [-111.54466, 42.85072, 0],
                [-111.54466, 42.85085, 0],
                [-111.54467, 42.85098, 0],
                [-111.54474, 42.85131, 0],
                [-111.54487, 42.85168, 0],
                [-111.54498, 42.85196, 0],
                [-111.54527, 42.85278, 0],
                [-111.54536, 42.85307, 0],
                [-111.54543, 42.85333, 0],
                [-111.54549, 42.85368, 0],
                [-111.54553, 42.85414, 0],
                [-111.54557, 42.85448, 0],
                [-111.54568, 42.85578, 0],
                [-111.54577, 42.85665, 0],
                [-111.5459, 42.85827, 0],
                [-111.54591, 42.85834, 0],
                [-111.54595, 42.85891, 0],
                [-111.54595, 42.86062, 0],
                [-111.54587, 42.86284, 0],
                [-111.54587, 42.86342, 0],
                [-111.54588, 42.86358, 0],
                [-111.5459, 42.86371, 0],
                [-111.54593, 42.86383, 0],
                [-111.54597, 42.86396, 0],
                [-111.54607, 42.86418, 0],
                [-111.5461, 42.86423, 0],
                [-111.54614, 42.86428, 0],
                [-111.54621, 42.86439, 0],
                [-111.54687, 42.86527, 0],
                [-111.54717, 42.86564, 0],
                [-111.54769, 42.86631, 0],
                [-111.54828, 42.86704, 0],
                [-111.54868, 42.86756, 0],
                [-111.54882, 42.8678, 0],
                [-111.54888, 42.86794, 0],
                [-111.5489, 42.86804, 0],
                [-111.54891, 42.86806, 0],
                [-111.54893, 42.86819, 0],
                [-111.54894, 42.8684, 0],
                [-111.54892, 42.86854, 0],
                [-111.54889, 42.86868, 0],
                [-111.54886, 42.86875, 0],
                [-111.54885, 42.86881, 0],
                [-111.54869, 42.86917, 0],
                [-111.54816, 42.87029, 0],
                [-111.54809, 42.87046, 0],
                [-111.5477, 42.87131, 0],
                [-111.54761, 42.87149, 0],
                [-111.54747, 42.87181, 0],
                [-111.54746, 42.87182, 0],
                [-111.54726, 42.87225, 0],
                [-111.54715, 42.87251, 0],
                [-111.54701, 42.8728, 0],
                [-111.54699, 42.87285, 0],
                [-111.54676, 42.87334, 0],
                [-111.54647, 42.87401, 0],
                [-111.54639, 42.87423, 0],
                [-111.54632, 42.87447, 0],
                [-111.54627, 42.87473, 0],
                [-111.54627, 42.87475, 0],
                [-111.54625, 42.8749, 0],
                [-111.54624, 42.87509, 0],
                [-111.54625, 42.87534, 0],
                [-111.54628, 42.87565, 0],
                [-111.5463, 42.87573, 0],
                [-111.54633, 42.87591, 0],
                [-111.54642, 42.87627, 0],
                [-111.54643, 42.87636, 0],
                [-111.54703, 42.87899, 0],
                [-111.54711, 42.87928, 0],
                [-111.54718, 42.87949, 0],
                [-111.5472, 42.87953, 0],
                [-111.54724, 42.87964, 0],
                [-111.54738, 42.87991, 0],
                [-111.54753, 42.88016, 0],
                [-111.54769, 42.8804, 0],
                [-111.54832, 42.88127, 0],
                [-111.54844, 42.88145, 0],
                [-111.5488, 42.88194, 0],
                [-111.54893, 42.88214, 0],
                [-111.54899, 42.88225, 0],
                [-111.54905, 42.88239, 0],
                [-111.5491, 42.88249, 0],
                [-111.54913, 42.88261, 0],
                [-111.54915, 42.88272, 0],
                [-111.54917, 42.88289, 0],
                [-111.54917, 42.88314, 0],
                [-111.54915, 42.88324, 0],
                [-111.54909, 42.88347, 0],
                [-111.54905, 42.88355, 0],
                [-111.54904, 42.8836, 0],
                [-111.54887, 42.88393, 0],
                [-111.54875, 42.8842, 0],
                [-111.54743, 42.88687, 0],
                [-111.54738, 42.88695, 0],
                [-111.5472, 42.88727, 0],
                [-111.54712, 42.88737, 0],
                [-111.54703, 42.8875, 0],
                [-111.54691, 42.88765, 0],
                [-111.54677, 42.88781, 0],
                [-111.54659, 42.88799, 0],
                [-111.54636, 42.8882, 0],
                [-111.54598, 42.88852, 0],
                [-111.54596, 42.88854, 0],
                [-111.54514, 42.88921, 0],
                [-111.54511, 42.88923, 0],
                [-111.5448, 42.88947, 0],
                [-111.54478, 42.88948, 0],
                [-111.54449, 42.88969, 0],
                [-111.54364, 42.89035, 0],
                [-111.54361, 42.89037, 0],
                [-111.5431, 42.89077, 0],
                [-111.54307, 42.89079, 0],
                [-111.54271, 42.89107, 0],
                [-111.54246, 42.89125, 0],
                [-111.54232, 42.89134, 0],
                [-111.54196, 42.89155, 0],
                [-111.54183, 42.89161, 0],
                [-111.54174, 42.89166, 0],
                [-111.54162, 42.8917, 0],
                [-111.54156, 42.89173, 0],
                [-111.54133, 42.89181, 0],
                [-111.54107, 42.89189, 0],
                [-111.54048, 42.89202, 0],
                [-111.54047, 42.89202, 0],
                [-111.5398, 42.89214, 0],
                [-111.53976, 42.89214, 0],
                [-111.53957, 42.89216, 0],
                [-111.53952, 42.89217, 0],
                [-111.53927, 42.89218, 0],
                [-111.53917, 42.89219, 0],
                [-111.53903, 42.89218, 0],
                [-111.53897, 42.89218, 0],
                [-111.53878, 42.89216, 0],
                [-111.53874, 42.89216, 0],
                [-111.53834, 42.89208, 0],
                [-111.53811, 42.892, 0],
                [-111.53779, 42.8919, 0],
                [-111.53763, 42.89186, 0],
                [-111.5374, 42.89182, 0],
                [-111.53737, 42.89181, 0],
                [-111.53721, 42.8918, 0],
                [-111.53707, 42.8918, 0],
                [-111.53688, 42.89181, 0],
                [-111.53676, 42.89183, 0],
                [-111.53651, 42.89189, 0],
                [-111.53641, 42.89192, 0],
                [-111.53614, 42.89205, 0],
                [-111.53603, 42.89212, 0],
                [-111.53599, 42.89216, 0],
                [-111.53592, 42.89221, 0],
                [-111.53583, 42.89229, 0],
                [-111.53578, 42.89234, 0],
                [-111.53544, 42.89273, 0],
                [-111.53542, 42.89274, 0],
                [-111.53477, 42.89348, 0],
                [-111.53476, 42.8935, 0],
                [-111.5317, 42.89689, 0],
                [-111.53097, 42.89773, 0],
                [-111.53088, 42.89785, 0],
                [-111.53081, 42.89797, 0],
                [-111.5307, 42.89823, 0],
                [-111.53066, 42.89841, 0],
                [-111.53063, 42.89859, 0],
                [-111.53061, 42.89913, 0],
                [-111.53061, 42.89931, 0],
                [-111.53059, 42.89985, 0],
                [-111.53055, 42.90514, 0],
                [-111.53054, 42.90545, 0],
                [-111.53052, 42.90564, 0],
                [-111.53052, 42.9057, 0],
                [-111.53046, 42.90613, 0],
                [-111.53037, 42.90648, 0],
                [-111.53029, 42.90667, 0],
                [-111.5301, 42.90704, 0],
                [-111.52997, 42.90722, 0],
                [-111.52972, 42.9075, 0],
                [-111.52942, 42.90778, 0],
                [-111.5293, 42.90788, 0],
                [-111.52906, 42.90806, 0],
                [-111.52888, 42.90818, 0],
                [-111.52867, 42.9083, 0],
                [-111.52847, 42.9084, 0],
                [-111.52831, 42.90849, 0],
                [-111.52815, 42.90856, 0],
                [-111.52782, 42.90872, 0],
                [-111.52349, 42.91068, 0],
                [-111.52335, 42.91075, 0],
                [-111.51898, 42.91275, 0],
                [-111.51891, 42.91279, 0],
                [-111.51866, 42.91291, 0],
                [-111.51849, 42.91301, 0],
                [-111.5183, 42.91314, 0],
                [-111.51816, 42.91325, 0],
                [-111.51799, 42.91342, 0],
                [-111.51789, 42.91354, 0],
                [-111.51779, 42.91369, 0],
                [-111.5177, 42.91384, 0],
                [-111.51755, 42.91422, 0],
                [-111.51747, 42.91439, 0],
                [-111.51744, 42.91447, 0],
                [-111.51735, 42.91466, 0],
                [-111.51727, 42.91479, 0],
                [-111.51712, 42.91499, 0],
                [-111.51682, 42.91528, 0],
                [-111.51668, 42.9154, 0],
                [-111.51646, 42.91557, 0],
                [-111.51622, 42.91573, 0],
                [-111.51496, 42.9165, 0],
                [-111.51475, 42.91664, 0],
                [-111.51419, 42.91699, 0],
                [-111.51418, 42.917, 0],
                [-111.51251, 42.91804, 0],
                [-111.51249, 42.91805, 0],
                [-111.51233, 42.91816, 0],
                [-111.51209, 42.91831, 0],
                [-111.51165, 42.91864, 0],
                [-111.5115, 42.91877, 0],
                [-111.51133, 42.91893, 0],
                [-111.51126, 42.91901, 0],
                [-111.51105, 42.91922, 0],
                [-111.51091, 42.91939, 0],
                [-111.51079, 42.91952, 0],
                [-111.51056, 42.91984, 0],
                [-111.51008, 42.92047, 0],
                [-111.50974, 42.92096, 0],
                [-111.50856, 42.92254, 0],
                [-111.50845, 42.9227, 0],
                [-111.50844, 42.92271, 0],
                [-111.50811, 42.92318, 0],
                [-111.50803, 42.92328, 0],
                [-111.50764, 42.92381, 0],
                [-111.50753, 42.92397, 0],
                [-111.50421, 42.92851, 0],
                [-111.50418, 42.92856, 0],
                [-111.50409, 42.92867, 0],
                [-111.50371, 42.92919, 0],
                [-111.50363, 42.92931, 0],
                [-111.50339, 42.92962, 0],
                [-111.50304, 42.9301, 0],
                [-111.50289, 42.93032, 0],
                [-111.50282, 42.93041, 0],
                [-111.50259, 42.93082, 0],
                [-111.50243, 42.93114, 0],
                [-111.50223, 42.93167, 0],
                [-111.50217, 42.93194, 0],
                [-111.50214, 42.93213, 0],
                [-111.50206, 42.93307, 0],
                [-111.50205, 42.93328, 0],
                [-111.502, 42.93391, 0],
                [-111.50163, 42.94028, 0],
                [-111.50161, 42.94047, 0],
                [-111.50154, 42.94146, 0],
                [-111.50154, 42.94154, 0],
                [-111.50148, 42.94262, 0],
                [-111.50147, 42.94295, 0],
                [-111.50147, 42.94333, 0],
                [-111.50148, 42.94355, 0],
                [-111.50152, 42.94378, 0],
                [-111.50154, 42.94394, 0],
                [-111.50157, 42.94404, 0],
                [-111.50158, 42.94405, 0],
                [-111.5016, 42.94416, 0],
                [-111.50176, 42.94461, 0],
                [-111.50181, 42.94473, 0],
                [-111.50196, 42.945, 0],
                [-111.50298, 42.94665, 0],
                [-111.50329, 42.9472, 0],
                [-111.50346, 42.94759, 0],
                [-111.50352, 42.94775, 0],
                [-111.5036, 42.948, 0],
                [-111.5037, 42.94839, 0],
                [-111.50373, 42.94849, 0],
                [-111.50397, 42.9495, 0],
                [-111.50401, 42.94963, 0],
                [-111.50545, 42.95547, 0],
                [-111.50546, 42.95549, 0],
                [-111.50549, 42.95564, 0],
                [-111.50571, 42.95653, 0],
                [-111.5058, 42.95694, 0],
                [-111.50593, 42.95738, 0],
                [-111.50608, 42.95776, 0],
                [-111.50746, 42.96045, 0],
                [-111.50751, 42.96056, 0],
                [-111.50792, 42.96134, 0],
                [-111.50837, 42.96229, 0],
                [-111.50844, 42.96246, 0],
                [-111.50848, 42.96258, 0],
                [-111.50855, 42.96285, 0],
                [-111.5086, 42.96314, 0],
                [-111.5086, 42.96352, 0],
                [-111.50853, 42.96403, 0],
                [-111.50848, 42.96427, 0],
                [-111.50831, 42.96481, 0],
                [-111.50805, 42.96548, 0],
                [-111.50092, 42.98475, 0],
                [-111.50086, 42.98489, 0],
                [-111.5, 42.9873, 0],
                [-111.49997, 42.9874, 0],
                [-111.49992, 42.98753, 0],
                [-111.49986, 42.98781, 0],
                [-111.49985, 42.98783, 0],
                [-111.49982, 42.98801, 0],
                [-111.49982, 42.98813, 0],
                [-111.49981, 42.98832, 0],
                [-111.49982, 42.98837, 0],
                [-111.49983, 42.98855, 0],
                [-111.49986, 42.98873, 0],
                [-111.49987, 42.98874, 0],
                [-111.4999, 42.98888, 0],
                [-111.49995, 42.98901, 0],
                [-111.50012, 42.98936, 0],
                [-111.50031, 42.98968, 0],
                [-111.50104, 42.99076, 0],
                [-111.50148, 42.99138, 0],
                [-111.50158, 42.99151, 0],
                [-111.50169, 42.99167, 0],
                [-111.50195, 42.99197, 0],
                [-111.50203, 42.99208, 0],
                [-111.50222, 42.99227, 0],
                [-111.50225, 42.99231, 0],
                [-111.50236, 42.99242, 0],
                [-111.50266, 42.99268, 0],
                [-111.503, 42.99295, 0],
                [-111.50301, 42.99296, 0],
                [-111.50333, 42.99322, 0],
                [-111.50334, 42.99323, 0],
                [-111.50351, 42.99336, 0],
                [-111.50399, 42.99377, 0],
                [-111.50415, 42.99392, 0],
                [-111.50444, 42.99424, 0],
                [-111.5045, 42.99433, 0],
                [-111.50461, 42.99448, 0],
                [-111.50469, 42.99465, 0],
                [-111.50476, 42.99483, 0],
                [-111.50482, 42.99504, 0],
                [-111.50484, 42.99519, 0],
                [-111.50485, 42.99523, 0],
                [-111.50487, 42.99541, 0],
                [-111.50487, 42.99572, 0],
                [-111.50486, 42.99577, 0],
                [-111.50486, 42.99582, 0],
                [-111.50484, 42.99593, 0],
                [-111.50482, 42.996, 0],
                [-111.5048, 42.99609, 0],
                [-111.50474, 42.99627, 0],
                [-111.50469, 42.9964, 0],
                [-111.50465, 42.99648, 0],
                [-111.50461, 42.99654, 0],
                [-111.50456, 42.99663, 0],
                [-111.50456, 42.99664, 0],
                [-111.50434, 42.99696, 0],
                [-111.5042, 42.99722, 0],
                [-111.50416, 42.99731, 0],
                [-111.50413, 42.99739, 0],
                [-111.50411, 42.99747, 0],
                [-111.50409, 42.99758, 0],
                [-111.50409, 42.99772, 0],
                [-111.5041, 42.99782, 0],
                [-111.50412, 42.99791, 0],
                [-111.50413, 42.99793, 0],
                [-111.50415, 42.99803, 0],
                [-111.50419, 42.99818, 0],
                [-111.50501, 43.0008, 0],
                [-111.50519, 43.00129, 0],
                [-111.50534, 43.0016, 0],
                [-111.50535, 43.00161, 0],
                [-111.50545, 43.0018, 0],
                [-111.50574, 43.00227, 0],
                [-111.50681, 43.00387, 0],
                [-111.50687, 43.00397, 0],
                [-111.50974, 43.00823, 0],
                [-111.51023, 43.00893, 0],
                [-111.51041, 43.00921, 0],
                [-111.51043, 43.00925, 0],
                [-111.51055, 43.00945, 0],
                [-111.51065, 43.00964, 0],
                [-111.51071, 43.00982, 0],
                [-111.51075, 43.00997, 0],
                [-111.51075, 43.01002, 0],
                [-111.51076, 43.01009, 0],
                [-111.51077, 43.01022, 0],
                [-111.51076, 43.01033, 0],
                [-111.51076, 43.01041, 0],
                [-111.51069, 43.01065, 0],
                [-111.51064, 43.01078, 0],
                [-111.51056, 43.01093, 0],
                [-111.51048, 43.01106, 0],
                [-111.51039, 43.01118, 0],
                [-111.51025, 43.01133, 0],
                [-111.51024, 43.01133, 0],
                [-111.51014, 43.01142, 0],
                [-111.50992, 43.01159, 0],
                [-111.5098, 43.01167, 0],
                [-111.50957, 43.01178, 0],
                [-111.50944, 43.01183, 0],
                [-111.50901, 43.01196, 0],
                [-111.50854, 43.01203, 0],
                [-111.50824, 43.01205, 0],
                [-111.50816, 43.01204, 0],
                [-111.50805, 43.01204, 0],
                [-111.50787, 43.01203, 0],
                [-111.5076, 43.01199, 0],
                [-111.50743, 43.01195, 0],
                [-111.50716, 43.01186, 0],
                [-111.50678, 43.01171, 0],
                [-111.50637, 43.0115, 0],
                [-111.49991, 43.00835, 0],
                [-111.49979, 43.0083, 0],
                [-111.49332, 43.00514, 0],
                [-111.49316, 43.00507, 0],
                [-111.49316, 43.00506, 0],
                [-111.49254, 43.00476, 0],
                [-111.49253, 43.00476, 0],
                [-111.49234, 43.00466, 0],
                [-111.49144, 43.00423, 0],
                [-111.49137, 43.00419, 0],
                [-111.49006, 43.00355, 0],
                [-111.49, 43.00353, 0],
                [-111.48775, 43.00243, 0],
                [-111.48774, 43.00242, 0],
                [-111.48718, 43.00214, 0],
                [-111.48708, 43.00208, 0],
                [-111.48695, 43.00201, 0],
                [-111.48695, 43.002, 0],
                [-111.48678, 43.0019, 0],
                [-111.48634, 43.00159, 0],
                [-111.48523, 43.00072, 0],
                [-111.4848, 43.0004, 0],
                [-111.48455, 43.00019, 0],
                [-111.48356, 42.99941, 0],
                [-111.48236, 42.99851, 0],
                [-111.48034, 42.99693, 0],
                [-111.48026, 42.99686, 0],
                [-111.4794, 42.99621, 0],
                [-111.47917, 42.99605, 0],
                [-111.47877, 42.99582, 0],
                [-111.47847, 42.99567, 0],
                [-111.47783, 42.99539, 0],
                [-111.47744, 42.99524, 0],
                [-111.47714, 42.99514, 0],
                [-111.47617, 42.99487, 0],
                [-111.47575, 42.99477, 0],
                [-111.47429, 42.99438, 0],
                [-111.47376, 42.99425, 0],
                [-111.46433, 42.99172, 0],
                [-111.46416, 42.99168, 0],
                [-111.4635, 42.9915, 0],
                [-111.46323, 42.99144, 0],
                [-111.46317, 42.99142, 0],
                [-111.46154, 42.99098, 0],
                [-111.46132, 42.99093, 0],
                [-111.46083, 42.9908, 0],
                [-111.46063, 42.99074, 0],
                [-111.46035, 42.99067, 0],
                [-111.45994, 42.99055, 0],
                [-111.45762, 42.98994, 0],
                [-111.4576, 42.98993, 0],
                [-111.45512, 42.98927, 0],
                [-111.4546, 42.98915, 0],
                [-111.45441, 42.98912, 0],
                [-111.45438, 42.98911, 0],
                [-111.45425, 42.98909, 0],
                [-111.45406, 42.98907, 0],
                [-111.45369, 42.98905, 0],
                [-111.45352, 42.98906, 0],
                [-111.45332, 42.98906, 0],
                [-111.45315, 42.98907, 0],
                [-111.4526, 42.98913, 0],
                [-111.45243, 42.98917, 0],
                [-111.45242, 42.98917, 0],
                [-111.45225, 42.9892, 0],
                [-111.45206, 42.98925, 0],
                [-111.45154, 42.98942, 0],
                [-111.45094, 42.98971, 0],
                [-111.4508, 42.98979, 0],
                [-111.4505, 42.98998, 0],
                [-111.45022, 42.99017, 0],
                [-111.44994, 42.99034, 0],
                [-111.44966, 42.99053, 0],
                [-111.4491, 42.99089, 0],
                [-111.44855, 42.99126, 0],
                [-111.44851, 42.99128, 0],
                [-111.44715, 42.99216, 0],
                [-111.44688, 42.99235, 0],
                [-111.44686, 42.99236, 0],
                [-111.44661, 42.99254, 0],
                [-111.44626, 42.99284, 0],
                [-111.44614, 42.99296, 0],
                [-111.44606, 42.99306, 0],
                [-111.44594, 42.99319, 0],
                [-111.44578, 42.99342, 0],
                [-111.44563, 42.99368, 0],
                [-111.44544, 42.99414, 0],
                [-111.44499, 42.99574, 0],
                [-111.44485, 42.99616, 0],
                [-111.44478, 42.99634, 0],
                [-111.44474, 42.99641, 0],
                [-111.44468, 42.99653, 0],
                [-111.44459, 42.99664, 0],
                [-111.44448, 42.99675, 0],
                [-111.44442, 42.9968, 0],
                [-111.44427, 42.9969, 0],
                [-111.44409, 42.99699, 0],
                [-111.44401, 42.99701, 0],
                [-111.44395, 42.99703, 0],
                [-111.44375, 42.99708, 0],
                [-111.44333, 42.99711, 0],
                [-111.44325, 42.99711, 0],
                [-111.44312, 42.99709, 0],
                [-111.44304, 42.99709, 0],
                [-111.44293, 42.99707, 0],
                [-111.44285, 42.99704, 0],
                [-111.44276, 42.99702, 0],
                [-111.4426, 42.99696, 0],
                [-111.44255, 42.99693, 0],
                [-111.44249, 42.9969, 0],
                [-111.44229, 42.99676, 0],
                [-111.4422, 42.99668, 0],
                [-111.44204, 42.9965, 0],
                [-111.44191, 42.99634, 0],
                [-111.44164, 42.99596, 0],
                [-111.44153, 42.99579, 0],
                [-111.44007, 42.99372, 0],
                [-111.43934, 42.99272, 0],
                [-111.43883, 42.99198, 0],
                [-111.43865, 42.99175, 0],
                [-111.43848, 42.99151, 0],
                [-111.43828, 42.99127, 0],
                [-111.43808, 42.99105, 0],
                [-111.43784, 42.99084, 0],
                [-111.43771, 42.99074, 0],
                [-111.43758, 42.99065, 0],
                [-111.43729, 42.99047, 0],
                [-111.43714, 42.99039, 0],
                [-111.43677, 42.99023, 0],
                [-111.4365, 42.99013, 0],
                [-111.43615, 42.99002, 0],
                [-111.436, 42.98998, 0],
                [-111.43563, 42.98991, 0],
                [-111.43527, 42.98986, 0],
                [-111.43507, 42.98984, 0],
                [-111.43451, 42.98983, 0],
                [-111.41704, 42.99066, 0],
                [-111.41537, 42.99067, 0],
                [-111.41424, 42.99066, 0],
                [-111.41349, 42.99067, 0],
                [-111.40119, 42.99066, 0],
                [-111.40063, 42.9907, 0],
                [-111.40048, 42.99072, 0],
                [-111.4001, 42.99079, 0],
                [-111.39994, 42.99083, 0],
                [-111.39992, 42.99083, 0],
                [-111.3997, 42.99088, 0],
                [-111.39947, 42.99095, 0],
                [-111.39939, 42.99097, 0],
                [-111.39873, 42.99122, 0],
                [-111.39353, 42.99361, 0],
                [-111.39276, 42.99395, 0],
                [-111.39256, 42.99403, 0],
                [-111.39222, 42.99414, 0],
                [-111.39187, 42.99422, 0],
                [-111.39168, 42.99424, 0],
                [-111.39148, 42.99427, 0],
                [-111.39094, 42.99428, 0],
                [-111.38143, 42.99427, 0],
                [-111.38131, 42.99426, 0],
                [-111.38124, 42.99425, 0],
                [-111.38104, 42.99421, 0],
                [-111.38088, 42.99414, 0],
                [-111.38081, 42.9941, 0],
                [-111.38073, 42.99404, 0],
                [-111.38068, 42.99399, 0],
                [-111.38061, 42.99389, 0],
                [-111.38057, 42.99384, 0],
                [-111.38049, 42.99366, 0],
                [-111.37836, 42.98785, 0],
                [-111.37829, 42.98764, 0],
                [-111.37725, 42.98482, 0],
                [-111.3772, 42.9847, 0],
                [-111.37715, 42.98453, 0],
                [-111.37711, 42.98443, 0],
                [-111.37707, 42.9843, 0],
                [-111.37701, 42.98403, 0],
                [-111.37699, 42.98389, 0],
                [-111.37698, 42.98376, 0],
                [-111.37698, 42.98362, 0],
                [-111.37697, 42.98338, 0],
                [-111.37698, 42.98322, 0],
                [-111.37702, 42.98194, 0],
                [-111.37702, 42.98176, 0],
                [-111.37707, 42.9805, 0],
                [-111.37708, 42.98047, 0],
                [-111.37717, 42.97882, 0],
                [-111.37729, 42.97706, 0],
                [-111.37731, 42.97664, 0],
                [-111.37731, 42.97651, 0],
                [-111.3773, 42.97636, 0],
                [-111.37724, 42.97597, 0],
                [-111.37721, 42.97583, 0],
                [-111.37713, 42.97556, 0],
                [-111.37703, 42.9753, 0],
                [-111.37682, 42.97492, 0],
                [-111.37657, 42.97455, 0],
                [-111.37637, 42.97431, 0],
                [-111.37614, 42.97408, 0],
                [-111.3759, 42.97387, 0],
                [-111.37568, 42.97369, 0],
                [-111.37525, 42.9734, 0],
                [-111.37509, 42.97331, 0],
                [-111.3745, 42.97301, 0],
                [-111.37355, 42.97256, 0],
                [-111.37297, 42.97227, 0],
                [-111.37246, 42.97204, 0],
                [-111.36954, 42.97065, 0],
                [-111.36938, 42.97058, 0],
                [-111.36889, 42.97034, 0],
                [-111.36819, 42.97003, 0],
                [-111.36795, 42.96991, 0],
                [-111.36733, 42.96963, 0],
                [-111.36701, 42.96951, 0],
                [-111.36683, 42.96945, 0],
                [-111.36674, 42.96943, 0],
                [-111.36661, 42.96939, 0],
                [-111.36636, 42.96934, 0],
                [-111.36626, 42.96931, 0],
                [-111.36606, 42.96928, 0],
                [-111.36598, 42.96926, 0],
                [-111.36592, 42.96926, 0],
                [-111.36583, 42.96924, 0],
                [-111.36526, 42.96917, 0],
                [-111.36476, 42.96913, 0],
                [-111.36454, 42.96913, 0],
                [-111.36426, 42.96914, 0],
                [-111.36367, 42.96918, 0],
                [-111.36359, 42.96919, 0],
                [-111.36319, 42.96926, 0],
                [-111.36304, 42.96928, 0],
                [-111.36285, 42.96932, 0],
                [-111.36228, 42.96946, 0],
                [-111.36193, 42.96957, 0],
                [-111.36165, 42.96968, 0],
                [-111.36133, 42.96982, 0],
                [-111.36078, 42.97011, 0],
                [-111.36067, 42.97018, 0],
                [-111.36052, 42.97026, 0],
                [-111.36019, 42.97042, 0],
                [-111.35966, 42.97073, 0],
                [-111.35917, 42.971, 0],
                [-111.35879, 42.97117, 0],
                [-111.35819, 42.9714, 0],
                [-111.35769, 42.97157, 0],
                [-111.3529, 42.97309, 0],
                [-111.35272, 42.97314, 0],
                [-111.35235, 42.97322, 0],
                [-111.35217, 42.97325, 0],
                [-111.3518, 42.97329, 0],
                [-111.35144, 42.97331, 0],
                [-111.35107, 42.97329, 0],
                [-111.35094, 42.97327, 0],
                [-111.35075, 42.97325, 0],
                [-111.35059, 42.97322, 0],
                [-111.35018, 42.97312, 0],
                [-111.35004, 42.97308, 0],
                [-111.34987, 42.97302, 0],
                [-111.34954, 42.97288, 0],
                [-111.34937, 42.9728, 0],
                [-111.34908, 42.97264, 0],
                [-111.34907, 42.97263, 0],
                [-111.34893, 42.97255, 0],
                [-111.34871, 42.97241, 0],
                [-111.34853, 42.97228, 0],
                [-111.34838, 42.97218, 0],
                [-111.34816, 42.97205, 0],
                [-111.34816, 42.97204, 0],
                [-111.34765, 42.97173, 0],
                [-111.34613, 42.97076, 0],
                [-111.34568, 42.97049, 0],
                [-111.34516, 42.97016, 0],
                [-111.34427, 42.96956, 0],
                [-111.34344, 42.96904, 0],
                [-111.34306, 42.96882, 0],
                [-111.34248, 42.96844, 0],
                [-111.34222, 42.96829, 0],
                [-111.34189, 42.96814, 0],
                [-111.34133, 42.96796, 0],
                [-111.34131, 42.96796, 0],
                [-111.34066, 42.96785, 0],
                [-111.3405, 42.96784, 0],
                [-111.34042, 42.96783, 0],
                [-111.34002, 42.96783, 0],
                [-111.33981, 42.96784, 0],
                [-111.33939, 42.96789, 0],
                [-111.339, 42.96797, 0],
                [-111.33745, 42.96842, 0],
                [-111.33728, 42.96846, 0],
                [-111.3371, 42.96851, 0],
                [-111.3366, 42.96858, 0],
                [-111.33637, 42.96859, 0],
                [-111.33618, 42.96859, 0],
                [-111.33582, 42.96855, 0],
                [-111.33565, 42.96852, 0],
                [-111.33563, 42.96851, 0],
                [-111.3355, 42.96849, 0],
                [-111.33489, 42.96831, 0],
                [-111.33427, 42.9681, 0],
                [-111.33419, 42.96808, 0],
                [-111.33363, 42.96789, 0],
                [-111.3331, 42.96773, 0],
                [-111.33291, 42.96768, 0],
                [-111.33288, 42.96768, 0],
                [-111.33268, 42.96764, 0],
                [-111.33247, 42.96762, 0],
                [-111.33224, 42.96761, 0],
                [-111.33191, 42.96761, 0],
                [-111.32483, 42.9684, 0],
                [-111.32372, 42.96854, 0],
                [-111.32339, 42.96859, 0],
                [-111.32269, 42.96874, 0],
                [-111.32254, 42.96879, 0],
                [-111.3221, 42.96892, 0],
                [-111.31814, 42.97034, 0],
                [-111.31748, 42.97055, 0],
                [-111.31728, 42.9706, 0],
                [-111.31689, 42.97068, 0],
                [-111.31679, 42.97069, 0],
                [-111.3166, 42.97072, 0],
                [-111.31636, 42.97074, 0],
                [-111.31608, 42.97075, 0],
                [-111.31569, 42.97075, 0],
                [-111.31537, 42.97073, 0],
                [-111.31499, 42.97068, 0],
                [-111.31478, 42.97064, 0],
                [-111.31457, 42.97059, 0],
                [-111.3142, 42.97048, 0],
                [-111.31391, 42.97037, 0],
                [-111.31364, 42.97025, 0],
                [-111.31324, 42.97004, 0],
                [-111.3131, 42.96995, 0],
                [-111.31281, 42.96974, 0],
                [-111.31256, 42.96953, 0],
                [-111.31241, 42.96938, 0],
                [-111.31111, 42.96793, 0],
                [-111.31047, 42.96725, 0],
                [-111.31018, 42.96698, 0],
                [-111.31005, 42.96687, 0],
                [-111.30987, 42.96673, 0],
                [-111.30976, 42.96666, 0],
                [-111.30962, 42.96658, 0],
                [-111.30937, 42.96645, 0],
                [-111.30912, 42.96634, 0],
                [-111.30896, 42.96629, 0],
                [-111.30881, 42.96623, 0],
                [-111.30862, 42.96618, 0],
                [-111.30851, 42.96616, 0],
                [-111.30839, 42.96613, 0],
                [-111.30802, 42.96607, 0],
                [-111.30742, 42.96604, 0],
                [-111.30695, 42.96606, 0],
                [-111.30174, 42.96653, 0],
                [-111.30123, 42.96655, 0],
                [-111.30101, 42.96655, 0],
                [-111.30062, 42.96653, 0],
                [-111.30043, 42.96651, 0],
                [-111.29995, 42.96643, 0],
                [-111.29976, 42.96638, 0],
                [-111.29937, 42.96625, 0],
                [-111.29907, 42.96613, 0],
                [-111.2976, 42.96546, 0],
                [-111.29699, 42.96521, 0],
                [-111.29646, 42.96503, 0],
                [-111.29603, 42.96491, 0],
                [-111.29532, 42.96477, 0],
                [-111.29463, 42.96468, 0],
                [-111.29386, 42.96464, 0],
                [-111.29358, 42.96464, 0],
                [-111.29327, 42.96465, 0],
                [-111.29275, 42.96469, 0],
                [-111.29219, 42.96477, 0],
                [-111.29173, 42.96487, 0],
                [-111.29154, 42.96492, 0],
                [-111.29121, 42.96503, 0],
                [-111.29109, 42.96508, 0],
                [-111.29083, 42.96523, 0],
                [-111.29082, 42.96524, 0],
                [-111.29067, 42.96535, 0],
                [-111.29052, 42.96548, 0],
                [-111.29039, 42.96561, 0],
                [-111.29032, 42.96569, 0],
                [-111.29022, 42.96586, 0],
                [-111.29016, 42.96595, 0],
                [-111.29013, 42.96603, 0],
                [-111.29009, 42.96612, 0],
                [-111.29009, 42.96614, 0],
                [-111.29005, 42.96628, 0],
                [-111.29002, 42.96652, 0],
                [-111.29004, 42.96668, 0],
                [-111.29004, 42.96675, 0],
                [-111.29008, 42.96692, 0],
                [-111.2901, 42.96698, 0],
                [-111.29021, 42.96721, 0],
                [-111.29031, 42.96737, 0],
                [-111.29033, 42.96739, 0],
                [-111.2904, 42.96748, 0],
                [-111.29055, 42.96763, 0],
                [-111.2907, 42.96775, 0],
                [-111.29083, 42.96787, 0],
                [-111.29092, 42.96794, 0],
                [-111.29102, 42.96803, 0],
                [-111.29112, 42.96811, 0],
                [-111.29133, 42.96832, 0],
                [-111.29147, 42.96849, 0],
                [-111.29158, 42.96868, 0],
                [-111.29162, 42.96876, 0],
                [-111.29166, 42.96887, 0],
                [-111.29172, 42.9691, 0],
                [-111.29174, 42.96933, 0],
                [-111.29172, 42.96953, 0],
                [-111.29163, 42.96982, 0],
                [-111.2916, 42.96989, 0],
                [-111.29142, 42.97017, 0],
                [-111.29134, 42.97026, 0],
                [-111.29122, 42.97038, 0],
                [-111.29111, 42.97047, 0],
                [-111.29091, 42.97061, 0],
                [-111.2908, 42.97068, 0],
                [-111.29067, 42.97075, 0],
                [-111.29047, 42.97084, 0],
                [-111.29032, 42.97089, 0],
                [-111.28999, 42.97098, 0],
                [-111.28973, 42.97102, 0],
                [-111.28949, 42.97104, 0],
                [-111.28915, 42.97104, 0],
                [-111.28883, 42.971, 0],
                [-111.28852, 42.97093, 0],
                [-111.28824, 42.97083, 0],
                [-111.288, 42.97072, 0],
                [-111.28791, 42.97067, 0],
                [-111.28763, 42.97049, 0],
                [-111.28697, 42.96997, 0],
                [-111.28665, 42.96975, 0],
                [-111.28614, 42.96946, 0],
                [-111.28573, 42.96929, 0],
                [-111.2852, 42.9691, 0],
                [-111.28477, 42.96898, 0],
                [-111.28453, 42.96893, 0],
                [-111.28451, 42.96893, 0],
                [-111.28424, 42.96889, 0],
                [-111.28363, 42.96882, 0],
                [-111.28356, 42.96882, 0],
                [-111.27229, 42.96807, 0],
                [-111.27012, 42.96795, 0],
                [-111.26817, 42.96792, 0],
                [-111.26685, 42.96793, 0],
                [-111.26611, 42.96798, 0],
                [-111.26555, 42.96804, 0],
                [-111.26484, 42.96815, 0],
                [-111.26061, 42.96911, 0],
                [-111.26017, 42.9692, 0],
                [-111.25949, 42.96938, 0],
                [-111.25853, 42.96966, 0],
                [-111.25743, 42.97001, 0],
                [-111.25691, 42.97021, 0],
                [-111.25418, 42.97133, 0],
                [-111.25397, 42.97143, 0],
                [-111.25313, 42.97178, 0],
                [-111.25312, 42.97179, 0],
                [-111.25192, 42.97229, 0],
                [-111.25146, 42.9725, 0],
                [-111.25126, 42.97261, 0],
                [-111.25123, 42.97263, 0],
                [-111.25103, 42.97273, 0],
                [-111.25077, 42.9729, 0],
                [-111.2506, 42.97304, 0],
                [-111.25041, 42.97322, 0],
                [-111.25025, 42.9734, 0],
                [-111.25009, 42.97362, 0],
                [-111.24986, 42.97398, 0],
                [-111.24977, 42.97411, 0],
                [-111.24967, 42.97424, 0],
                [-111.24944, 42.97449, 0],
                [-111.2493, 42.9746, 0],
                [-111.24917, 42.97468, 0],
                [-111.24896, 42.97479, 0],
                [-111.24864, 42.97494, 0],
                [-111.24837, 42.97504, 0],
                [-111.24815, 42.97514, 0],
                [-111.2479, 42.97527, 0],
                [-111.24772, 42.97538, 0],
                [-111.24743, 42.97562, 0],
                [-111.24731, 42.97576, 0],
                [-111.24712, 42.97601, 0],
                [-111.24709, 42.97606, 0],
                [-111.2468, 42.97648, 0],
                [-111.24662, 42.97667, 0],
                [-111.2464, 42.97684, 0],
                [-111.24624, 42.97693, 0],
                [-111.24617, 42.97698, 0],
                [-111.24602, 42.97704, 0],
                [-111.24571, 42.97715, 0],
                [-111.24553, 42.9772, 0],
                [-111.24527, 42.97726, 0],
                [-111.24495, 42.97729, 0],
                [-111.24467, 42.97729, 0],
                [-111.24439, 42.97728, 0],
                [-111.24419, 42.97728, 0],
                [-111.23901, 42.97706, 0],
                [-111.23654, 42.97717, 0],
                [-111.23644, 42.97717, 0],
                [-111.23641, 42.97716, 0],
                [-111.23585, 42.97714, 0],
                [-111.23532, 42.97707, 0],
                [-111.2353, 42.97706, 0],
                [-111.23472, 42.97695, 0],
                [-111.23432, 42.97686, 0],
                [-111.23398, 42.9768, 0],
                [-111.23367, 42.97677, 0],
                [-111.23347, 42.97676, 0],
                [-111.23328, 42.97676, 0],
                [-111.23279, 42.97682, 0],
                [-111.23237, 42.97691, 0],
                [-111.23204, 42.97699, 0],
                [-111.23126, 42.97714, 0],
                [-111.2307, 42.9772, 0],
                [-111.23015, 42.97722, 0],
                [-111.22919, 42.97715, 0],
                [-111.2288, 42.9771, 0],
                [-111.22857, 42.97708, 0],
                [-111.22846, 42.97706, 0],
                [-111.22833, 42.97705, 0],
                [-111.22772, 42.97698, 0],
                [-111.22661, 42.97682, 0],
                [-111.22625, 42.97675, 0],
                [-111.22588, 42.97666, 0],
                [-111.2254, 42.97652, 0],
                [-111.22496, 42.97637, 0],
                [-111.2243, 42.97609, 0],
                [-111.22376, 42.97583, 0],
                [-111.22327, 42.97561, 0],
                [-111.22313, 42.97556, 0],
                [-111.22274, 42.97545, 0],
                [-111.22232, 42.97539, 0],
                [-111.22202, 42.97536, 0],
                [-111.22145, 42.97533, 0],
                [-111.2209, 42.97528, 0],
                [-111.22058, 42.97521, 0],
                [-111.22019, 42.9751, 0],
                [-111.21993, 42.97501, 0],
                [-111.21961, 42.97487, 0],
                [-111.21867, 42.97441, 0],
                [-111.21849, 42.97435, 0],
                [-111.21838, 42.9743, 0],
                [-111.21818, 42.97423, 0],
                [-111.21781, 42.97413, 0],
                [-111.21762, 42.97407, 0],
                [-111.21697, 42.97395, 0],
                [-111.21672, 42.97392, 0],
                [-111.21668, 42.97392, 0],
                [-111.21611, 42.97388, 0],
                [-111.21551, 42.97387, 0],
                [-111.215, 42.97385, 0],
                [-111.21447, 42.97381, 0],
                [-111.2142, 42.97377, 0],
                [-111.21385, 42.97368, 0],
                [-111.21351, 42.97357, 0],
                [-111.21337, 42.97351, 0],
                [-111.21262, 42.97324, 0],
                [-111.21223, 42.97312, 0],
                [-111.21174, 42.97302, 0],
                [-111.21154, 42.97299, 0],
                [-111.21139, 42.97298, 0],
                [-111.21076, 42.97291, 0],
                [-111.20915, 42.97276, 0],
                [-111.20889, 42.97275, 0],
                [-111.20855, 42.97275, 0],
                [-111.20827, 42.97276, 0],
                [-111.20776, 42.97281, 0],
                [-111.2073, 42.97288, 0],
                [-111.2071, 42.97292, 0],
                [-111.20657, 42.97306, 0],
                [-111.20505, 42.97361, 0],
                [-111.20489, 42.97368, 0],
                [-111.20464, 42.97377, 0],
                [-111.20172, 42.97491, 0],
                [-111.20139, 42.97505, 0],
                [-111.20089, 42.97529, 0],
                [-111.2002, 42.97565, 0],
                [-111.19936, 42.97612, 0],
                [-111.19889, 42.97635, 0],
                [-111.19858, 42.97645, 0],
                [-111.19831, 42.97651, 0],
                [-111.19803, 42.97656, 0],
                [-111.19758, 42.97658, 0],
                [-111.19733, 42.97656, 0],
                [-111.19704, 42.97652, 0],
                [-111.19678, 42.97647, 0],
                [-111.19653, 42.9764, 0],
                [-111.19622, 42.97629, 0],
                [-111.19569, 42.97605, 0],
                [-111.19528, 42.9759, 0],
                [-111.19506, 42.97583, 0],
                [-111.19467, 42.97575, 0],
                [-111.19427, 42.9757, 0],
                [-111.19371, 42.97568, 0],
                [-111.19316, 42.97568, 0],
                [-111.19279, 42.97569, 0],
                [-111.19245, 42.97568, 0],
                [-111.1922, 42.97566, 0],
                [-111.19183, 42.97561, 0],
                [-111.19149, 42.97555, 0],
                [-111.1913, 42.9755, 0],
                [-111.19081, 42.97535, 0],
                [-111.19052, 42.97524, 0],
                [-111.19048, 42.97522, 0],
                [-111.18948, 42.97485, 0],
                [-111.18895, 42.9747, 0],
                [-111.18806, 42.9745, 0],
                [-111.18617, 42.97413, 0],
                [-111.1859, 42.97409, 0],
                [-111.18513, 42.97405, 0],
                [-111.18459, 42.97407, 0],
                [-111.18421, 42.97411, 0],
                [-111.18372, 42.97419, 0],
                [-111.18367, 42.97419, 0],
                [-111.18184, 42.97455, 0],
                [-111.18031, 42.9749, 0],
                [-111.17964, 42.97509, 0],
                [-111.17517, 42.97643, 0],
                [-111.17494, 42.97649, 0],
                [-111.17361, 42.9769, 0],
                [-111.17328, 42.97699, 0],
                [-111.16898, 42.97827, 0],
                [-111.16862, 42.97839, 0],
                [-111.16831, 42.97852, 0],
                [-111.168, 42.97867, 0],
                [-111.1678, 42.97878, 0],
                [-111.16767, 42.97886, 0],
                [-111.1674, 42.97905, 0],
                [-111.16718, 42.97925, 0],
                [-111.16707, 42.97936, 0],
                [-111.16698, 42.97944, 0],
                [-111.16684, 42.9796, 0],
                [-111.16672, 42.97977, 0],
                [-111.1665, 42.98013, 0],
                [-111.16641, 42.98032, 0],
                [-111.16582, 42.98259, 0],
                [-111.16569, 42.98291, 0],
                [-111.16559, 42.98311, 0],
                [-111.16545, 42.98335, 0],
                [-111.16545, 42.98336, 0],
                [-111.16521, 42.98372, 0],
                [-111.165, 42.984, 0],
                [-111.16446, 42.98478, 0],
                [-111.16402, 42.98537, 0],
                [-111.16392, 42.98549, 0],
                [-111.16366, 42.98572, 0],
                [-111.1635, 42.98583, 0],
                [-111.16312, 42.98603, 0],
                [-111.16272, 42.98622, 0],
                [-111.16234, 42.98643, 0],
                [-111.16223, 42.98651, 0],
                [-111.16205, 42.98666, 0],
                [-111.16199, 42.98672, 0],
                [-111.16187, 42.98686, 0],
                [-111.1618, 42.98696, 0],
                [-111.1617, 42.98715, 0],
                [-111.16166, 42.98725, 0],
                [-111.16163, 42.98739, 0],
                [-111.16161, 42.98755, 0],
                [-111.16161, 42.98787, 0],
                [-111.16166, 42.98836, 0],
                [-111.16167, 42.98859, 0],
                [-111.16164, 42.98891, 0],
                [-111.16159, 42.98914, 0],
                [-111.16151, 42.98942, 0],
                [-111.16134, 42.98979, 0],
                [-111.16122, 42.98998, 0],
                [-111.16109, 42.99016, 0],
                [-111.161, 42.99026, 0],
                [-111.16086, 42.99043, 0],
                [-111.16061, 42.99066, 0],
                [-111.16034, 42.99087, 0],
                [-111.1601, 42.99103, 0],
                [-111.15988, 42.99115, 0],
                [-111.15964, 42.99127, 0],
                [-111.15911, 42.99151, 0],
                [-111.15844, 42.99179, 0],
                [-111.15726, 42.99232, 0],
                [-111.15578, 42.9931, 0],
                [-111.15527, 42.99339, 0],
                [-111.15484, 42.99361, 0],
                [-111.15466, 42.99368, 0],
                [-111.15445, 42.99375, 0],
                [-111.15428, 42.99379, 0],
                [-111.15418, 42.9938, 0],
                [-111.15403, 42.99383, 0],
                [-111.1537, 42.99387, 0],
                [-111.1533, 42.9939, 0],
                [-111.15281, 42.99396, 0],
                [-111.1527, 42.99399, 0],
                [-111.1526, 42.99401, 0],
                [-111.15249, 42.99404, 0],
                [-111.15238, 42.99408, 0],
                [-111.15219, 42.99416, 0],
                [-111.15203, 42.99424, 0],
                [-111.15178, 42.9944, 0],
                [-111.15167, 42.99451, 0],
                [-111.15157, 42.9946, 0],
                [-111.15146, 42.99473, 0],
                [-111.15135, 42.99488, 0],
                [-111.15092, 42.99566, 0],
                [-111.15083, 42.99577, 0],
                [-111.15075, 42.99588, 0],
                [-111.15049, 42.99615, 0],
                [-111.15023, 42.99635, 0],
                [-111.15007, 42.99645, 0],
                [-111.14981, 42.9966, 0],
                [-111.14953, 42.99672, 0],
                [-111.14932, 42.9968, 0],
                [-111.14907, 42.99687, 0],
                [-111.14898, 42.9969, 0],
                [-111.1487, 42.99696, 0],
                [-111.1484, 42.99699, 0],
                [-111.14805, 42.99701, 0],
                [-111.14769, 42.99701, 0],
                [-111.14606, 42.99683, 0],
                [-111.1457, 42.99681, 0],
                [-111.14535, 42.99681, 0],
                [-111.14488, 42.99683, 0],
                [-111.14467, 42.99685, 0],
                [-111.14393, 42.99697, 0],
                [-111.14326, 42.99717, 0],
                [-111.14293, 42.99729, 0],
                [-111.14291, 42.9973, 0],
                [-111.1425, 42.99746, 0],
                [-111.14184, 42.99775, 0],
                [-111.13456, 43.00112, 0],
                [-111.13349, 43.00159, 0],
                [-111.12935, 43.00352, 0],
                [-111.1281, 43.00407, 0],
                [-111.12746, 43.00432, 0],
                [-111.12706, 43.00444, 0],
                [-111.12658, 43.00457, 0],
                [-111.126, 43.00469, 0],
                [-111.1254, 43.00478, 0],
                [-111.12513, 43.00481, 0],
                [-111.12475, 43.00484, 0],
                [-111.12398, 43.00487, 0],
                [-111.1233, 43.00485, 0],
                [-111.12278, 43.00481, 0],
                [-111.11787, 43.00422, 0],
                [-111.11724, 43.00416, 0],
                [-111.11623, 43.00411, 0],
                [-111.1154, 43.00411, 0],
                [-111.11481, 43.00414, 0],
                [-111.11427, 43.00418, 0],
                [-111.11311, 43.00431, 0],
                [-111.10622, 43.00536, 0],
                [-111.10555, 43.00544, 0],
                [-111.10483, 43.00547, 0],
                [-111.10404, 43.00542, 0],
                [-111.10363, 43.00535, 0],
                [-111.10315, 43.00525, 0],
                [-111.10315, 43.00524, 0],
                [-111.10299, 43.0052, 0],
                [-111.10251, 43.00504, 0],
                [-111.10219, 43.00492, 0],
                [-111.10188, 43.00479, 0],
                [-111.10183, 43.00476, 0],
                [-111.10149, 43.00459, 0],
                [-111.09752, 43.00238, 0],
                [-111.09725, 43.00224, 0],
                [-111.0967, 43.00198, 0],
                [-111.09616, 43.00176, 0],
                [-111.0958, 43.00164, 0],
                [-111.09536, 43.00151, 0],
                [-111.09481, 43.00139, 0],
                [-111.09449, 43.00133, 0],
                [-111.09413, 43.00128, 0],
                [-111.09412, 43.00127, 0],
                [-111.09361, 43.00122, 0],
                [-111.09303, 43.00119, 0],
                [-111.09265, 43.00118, 0],
                [-111.09228, 43.00119, 0],
                [-111.09172, 43.00119, 0],
                [-111.09137, 43.0012, 0],
                [-111.09116, 43.00119, 0],
                [-111.09083, 43.00119, 0],
                [-111.09042, 43.00117, 0],
                [-111.08987, 43.00111, 0],
                [-111.08971, 43.00108, 0],
                [-111.08949, 43.00105, 0],
                [-111.08913, 43.00098, 0],
                [-111.08878, 43.0009, 0],
                [-111.08812, 43.00071, 0],
                [-111.08795, 43.00065, 0],
                [-111.08778, 43.0006, 0],
                [-111.08761, 43.00053, 0],
                [-111.08744, 43.00047, 0],
                [-111.08729, 43.0004, 0],
                [-111.08712, 43.00033, 0],
                [-111.08697, 43.00025, 0],
                [-111.08662, 43.00008, 0],
                [-111.08604, 42.99974, 0],
                [-111.08234, 42.99742, 0],
                [-111.08207, 42.99727, 0],
                [-111.08189, 42.99718, 0],
                [-111.08157, 42.99704, 0],
                [-111.08124, 42.99692, 0],
                [-111.08107, 42.99687, 0],
                [-111.0809, 42.99681, 0],
                [-111.08073, 42.99676, 0],
                [-111.08039, 42.99668, 0],
                [-111.08037, 42.99668, 0],
                [-111.08019, 42.99664, 0],
                [-111.07986, 42.99658, 0],
                [-111.07972, 42.99656, 0],
                [-111.07946, 42.99654, 0],
                [-111.07929, 42.99652, 0],
                [-111.0791, 42.99652, 0],
                [-111.07895, 42.99651, 0],
                [-111.07882, 42.99651, 0],
                [-111.07831, 42.99653, 0],
                [-111.07772, 42.99659, 0],
                [-111.07761, 42.99661, 0],
                [-111.07604, 42.99681, 0],
                [-111.07602, 42.99681, 0],
                [-111.07552, 42.99686, 0],
                [-111.07449, 42.99691, 0],
                [-111.07397, 42.99691, 0],
                [-111.07329, 42.99688, 0],
                [-111.07286, 42.99684, 0],
                [-111.07188, 42.99672, 0],
                [-111.07178, 42.9967, 0],
                [-111.07125, 42.99662, 0],
                [-111.06987, 42.99644, 0],
                [-111.0692, 42.99639, 0],
                [-111.06901, 42.99639, 0],
                [-111.06846, 42.99637, 0],
                [-111.0668, 42.99636, 0],
                [-111.06671, 42.99637, 0],
                [-111.04407, 42.99631, 0]
            ]
        },
        id: "e3b6647a-0a75-4161-8e0d-5863fc2d9548"
    }, 
    // {
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-111.81273, 41.99892, 0]
    //     },
    //     properties: {
    //         name: "Franklin, Idaho",
    //         "shape": "Marker",
    //         "category": "default"
    //     },
    //     id: "566ff897-b099-4b8a-8233-522452e448f1"
    // }, {
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-111.04407, 42.99631, 0]
    //     },
    //     properties: {
    //         name: "Freedom, Wyoming",
    //         "shape": "Marker",
    //         "category": "default"
    //     },
    //     id: "31faf98e-84b7-48e1-a44a-fc4515287cbf"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Preston, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-111.87667, 42.09628, 0]
    //     },
    //     id: "76a6816c-bc29-40e3-95dc-9ad3ef3541e8"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Soda Springs, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-111.604338, 42.65469]
    //     },
    //     id: "fe11ae33-6faf-4282-ab73-e355bed80baa"
    // }
    ]
};

export default PioneerHistoric;