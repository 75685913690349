const SnakeRiverCanyon = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Snake River Canyon Byway",
				description: "This 53-mile route meanders through some of southwestern Idaho's rich agricultural lands, showcasing orchards, vineyards and a variety of rural experiences. Sample Idaho's award-winning wineries as you sip your way through the Sunnyslope Wine Trail within this unique wine-growing region or explore historical sites, like Map Rock, while enjoying the scenic vistas found on this drive.",
				link: "https://visitidaho.org/things-to-do/road-trips/snake-river-canyon-scenic-byway/",
				route: [
					"Walters Ferry", 
					"Nyssa, Oregon", 
					"Marsing",
					"Homedale",
					"Wilder",
					"Parma"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
					[-116.600406, 43.348108],
					[-116.600255, 43.348487],
					[-116.60018, 43.348662],
					[-116.600084, 43.348822],
					[-116.596611, 43.354657],
					[-116.596795, 43.354757],
					[-116.598355, 43.355756],
					[-116.598918, 43.356085],
					[-116.600209, 43.356922],
					[-116.600583, 43.357203],
					[-116.600708, 43.357367],
					[-116.600882, 43.357714],
					[-116.60092, 43.357938],
					[-116.600894, 43.358436],
					[-116.601092, 43.360178],
					[-116.601107, 43.36055],
					[-116.601208, 43.360756],
					[-116.601658, 43.361222],
					[-116.602232, 43.361599],
					[-116.60265, 43.361821],
					[-116.602983, 43.362106],
					[-116.603486, 43.362703],
					[-116.603601, 43.36288],
					[-116.603848, 43.363104],
					[-116.604044, 43.363206],
					[-116.604346, 43.363324],
					[-116.604732, 43.363441],
					[-116.605623, 43.363772],
					[-116.605895, 43.363936],
					[-116.606121, 43.364134],
					[-116.606303, 43.364358],
					[-116.60641, 43.364534],
					[-116.606669, 43.365381],
					[-116.606876, 43.365931],
					[-116.607146, 43.36635],
					[-116.608166, 43.367454],
					[-116.609709, 43.369223],
					[-116.609949, 43.369835],
					[-116.610237, 43.370254],
					[-116.610941, 43.370814],
					[-116.61123, 43.370995],
					[-116.611722, 43.371215],
					[-116.612277, 43.371375],
					[-116.612893, 43.371495],
					[-116.613577, 43.371582],
					[-116.614482, 43.371731],
					[-116.615988, 43.371939],
					[-116.616368, 43.371937],
					[-116.616979, 43.371853],
					[-116.617862, 43.371591],
					[-116.618318, 43.37144],
					[-116.618832, 43.37132],
					[-116.619419, 43.371306],
					[-116.61967, 43.371318],
					[-116.620205, 43.371408],
					[-116.620548, 43.371531],
					[-116.62096, 43.371627],
					[-116.62224, 43.371981],
					[-116.625131, 43.372729],
					[-116.628357, 43.373508],
					[-116.630504, 43.374075],
					[-116.630983, 43.374285],
					[-116.631581, 43.374579],
					[-116.632161, 43.374891],
					[-116.632984, 43.375197],
					[-116.634065, 43.375553],
					[-116.63563, 43.376153],
					[-116.636198, 43.376437],
					[-116.637435, 43.377163],
					[-116.639162, 43.378321],
					[-116.639638, 43.378673],
					[-116.639903, 43.378935],
					[-116.640185, 43.379309],
					[-116.640959, 43.380459],
					[-116.641738, 43.381648],
					[-116.641834, 43.38182],
					[-116.642076, 43.381785],
					[-116.642203, 43.381737],
					[-116.642283, 43.381667],
					[-116.642385, 43.381537],
					[-116.642471, 43.38135],
					[-116.642521, 43.38103],
					[-116.642653, 43.380812],
					[-116.642782, 43.380723],
					[-116.642942, 43.380657],
					[-116.64316, 43.38066],
					[-116.643925, 43.3809],
					[-116.644438, 43.381104],
					[-116.644609, 43.381155],
					[-116.645229, 43.381433],
					[-116.646044, 43.381825],
					[-116.648604, 43.382883],
					[-116.649811, 43.383423],
					[-116.650414, 43.383668],
					[-116.650755, 43.38376],
					[-116.651371, 43.383831],
					[-116.651669, 43.383899],
					[-116.651941, 43.383996],
					[-116.652061, 43.384075],
					[-116.652249, 43.384164],
					[-116.652408, 43.384217],
					[-116.653282, 43.384402],
					[-116.654358, 43.384706],
					[-116.655144, 43.384967],
					[-116.655976, 43.385219],
					[-116.657432, 43.38571],
					[-116.658563, 43.386132],
					[-116.659724, 43.386612],
					[-116.660102, 43.386714],
					[-116.661459, 43.38696],
					[-116.662489, 43.387109],
					[-116.662916, 43.387193],
					[-116.663007, 43.387231],
					[-116.663064, 43.387321],
					[-116.663093, 43.387457],
					[-116.662993, 43.387683],
					[-116.662883, 43.387812],
					[-116.662775, 43.38804],
					[-116.661359, 43.391426],
					[-116.660901, 43.392639],
					[-116.660742, 43.392792],
					[-116.660585, 43.392909],
					[-116.661146, 43.393365],
					[-116.661513, 43.393608],
					[-116.662616, 43.394121],
					[-116.662926, 43.394323],
					[-116.66485, 43.395807],
					[-116.665629, 43.396368],
					[-116.666486, 43.396854],
					[-116.667522, 43.397215],
					[-116.668602, 43.397658],
					[-116.668883, 43.397802],
					[-116.669089, 43.397936],
					[-116.669287, 43.398122],
					[-116.67015, 43.399076],
					[-116.670516, 43.399443],
					[-116.670883, 43.399762],
					[-116.672895, 43.401099],
					[-116.674066, 43.401806],
					[-116.675145, 43.402394],
					[-116.676678, 43.403323],
					[-116.681481, 43.406608],
					[-116.683214, 43.407764],
					[-116.683906, 43.408199],
					[-116.687381, 43.409833],
					[-116.688015, 43.410228],
					[-116.688486, 43.410567],
					[-116.689565, 43.411116],
					[-116.690321, 43.411394],
					[-116.690533, 43.411491],
					[-116.691527, 43.412081],
					[-116.692331, 43.412637],
					[-116.693066, 43.413184],
					[-116.69335, 43.41337],
					[-116.693517, 43.413505],
					[-116.693929, 43.413758],
					[-116.694123, 43.413844],
					[-116.694363, 43.413924],
					[-116.694616, 43.413985],
					[-116.695096, 43.414073],
					[-116.695324, 43.414137],
					[-116.696422, 43.414544],
					[-116.698334, 43.415371],
					[-116.699617, 43.415991],
					[-116.700058, 43.416279],
					[-116.700372, 43.416569],
					[-116.701001, 43.417366],
					[-116.701382, 43.417672],
					[-116.701597, 43.417808],
					[-116.701908, 43.418044],
					[-116.702064, 43.418192],
					[-116.702189, 43.418331],
					[-116.702427, 43.418654],
					[-116.702651, 43.419053],
					[-116.702861, 43.419338],
					[-116.703127, 43.419656],
					[-116.703636, 43.420183],
					[-116.704103, 43.420593],
					[-116.704342, 43.420758],
					[-116.704973, 43.421125],
					[-116.70583, 43.421554],
					[-116.706021, 43.421664],
					[-116.706645, 43.422125],
					[-116.706977, 43.422407],
					[-116.707867, 43.423226],
					[-116.708817, 43.424327],
					[-116.709124, 43.424647],
					[-116.709639, 43.425243],
					[-116.709961, 43.425776],
					[-116.710417, 43.426755],
					[-116.711003, 43.427889],
					[-116.711589, 43.429224],
					[-116.712715, 43.432027],
					[-116.712848, 43.432593],
					[-116.712982, 43.433459],
					[-116.713075, 43.433887],
					[-116.713446, 43.435127],
					[-116.713585, 43.435493],
					[-116.713663, 43.435775],
					[-116.713798, 43.436152],
					[-116.714033, 43.436658],
					[-116.714132, 43.436923],
					[-116.714299, 43.437231],
					[-116.714612, 43.437619],
					[-116.715986, 43.438814],
					[-116.716951, 43.439463],
					[-116.71762, 43.439885],
					[-116.717927, 43.440048],
					[-116.718951, 43.440443],
					[-116.721156, 43.441354],
					[-116.721862, 43.441595],
					[-116.722206, 43.441751],
					[-116.723012, 43.442187],
					[-116.724884, 43.443314],
					[-116.725983, 43.444088],
					[-116.726874, 43.444676],
					[-116.730266, 43.446822],
					[-116.733027, 43.448369],
					[-116.734363, 43.449176],
					[-116.734778, 43.449483],
					[-116.735635, 43.450208],
					[-116.736682, 43.451033],
					[-116.738461, 43.45255],
					[-116.739884, 43.45379],
					[-116.741499, 43.455068],
					[-116.741842, 43.455278],
					[-116.74219, 43.455441],
					[-116.74319, 43.45586],
					[-116.745076, 43.457151],
					[-116.746053, 43.457864],
					[-116.746651, 43.458349],
					[-116.74713, 43.458675],
					[-116.747562, 43.458905],
					[-116.748394, 43.459261],
					[-116.74995, 43.460047],
					[-116.750459, 43.460276],
					[-116.752171, 43.460779],
					[-116.753477, 43.461258],
					[-116.755705, 43.462243],
					[-116.75717, 43.463048],
					[-116.759288, 43.464363],
					[-116.761548, 43.465652],
					[-116.763607, 43.466892],
					[-116.765213, 43.467673],
					[-116.766334, 43.468419],
					[-116.766888, 43.468859],
					[-116.767042, 43.469079],
					[-116.767069, 43.469517],
					[-116.767124, 43.474863],
					[-116.767073, 43.476288],
					[-116.767141, 43.477803],
					[-116.767125, 43.478661],
					[-116.767171, 43.486749],
					[-116.7672, 43.487442],
					[-116.767165, 43.488217],
					[-116.767162, 43.493809],
					[-116.767226, 43.49694],
					[-116.767219, 43.501928],
					[-116.757253, 43.501952],
					[-116.757316, 43.50379],
					[-116.75729, 43.504198],
					[-116.757288, 43.508569],
					[-116.75727, 43.509195],
					[-116.757311, 43.512836],
					[-116.757343, 43.524696],
					[-116.757368, 43.530163],
					[-116.75739, 43.530989],
					[-116.757374, 43.532111],
					[-116.757391, 43.532754],
					[-116.757401, 43.535593],
					[-116.757407, 43.54519],
					[-116.772375, 43.54522],
					[-116.772347, 43.555164],
					[-116.772326, 43.556908],
					[-116.77233, 43.564733],
					[-116.772339, 43.566261],
					[-116.772366, 43.567121],
					[-116.772342, 43.573338],
					[-116.772557, 43.573276],
					[-116.772721, 43.573264],
					[-116.772972, 43.573277],
					[-116.773463, 43.573345],
					[-116.774443, 43.573522],
					[-116.774988, 43.573642],
					[-116.775471, 43.573779],
					[-116.776175, 43.574015],
					[-116.776403, 43.574073],
					[-116.776756, 43.574122],
					[-116.777128, 43.574138],
					[-116.791481, 43.574135],
					[-116.794584, 43.574122],
					[-116.795952, 43.574061],
					[-116.796989, 43.574095],
					[-116.804124, 43.574043],
					[-116.80808, 43.574037],
					[-116.809306, 43.574053],
					[-116.80944, 43.574075],
					[-116.809569, 43.574126],
					[-116.809952, 43.574396],
					[-116.811491, 43.575587],
					[-116.812378, 43.576137],
					[-116.812487, 43.576255],
					[-116.812541, 43.576407],
					[-116.81249, 43.577493],
					[-116.812445, 43.57951],
					[-116.812453, 43.581411],
					[-116.812411, 43.584483],
					[-116.812384, 43.588612],
					[-116.812402, 43.596193],
					[-116.812445, 43.604367],
					[-116.812493, 43.608694],
					[-116.812486, 43.609546],
					[-116.81254, 43.612892],
					[-116.812577, 43.617008],
					[-116.812605, 43.617169],
					[-116.812655, 43.617296],
					[-116.812803, 43.617512],
					[-116.812904, 43.617614],
					[-116.826028, 43.617596],
					[-116.834263, 43.61756],
					[-116.834744, 43.61754],
					[-116.835188, 43.617498],
					[-116.83762, 43.617188],
					[-116.838073, 43.617144],
					[-116.838833, 43.61712],
					[-116.839207, 43.617128],
					[-116.839776, 43.617175],
					[-116.841963, 43.617462],
					[-116.842654, 43.617511],
					[-116.852574, 43.617654],
					[-116.868398, 43.617851],
					[-116.871598, 43.61791],
					[-116.871601, 43.618727],
					[-116.871626, 43.619378],
					[-116.871606, 43.620654],
					[-116.87167, 43.626952],
					[-116.871674, 43.631106],
					[-116.871707, 43.632507],
					[-116.889864, 43.632334],
					[-116.896654, 43.63229],
					[-116.901657, 43.632289],
					[-116.911761, 43.632254],
					[-116.92162, 43.632165],
					[-116.931617, 43.632113],
					[-116.93167, 43.640477],
					[-116.931695, 43.641237],
					[-116.931705, 43.642862],
					[-116.931725, 43.642958],
					[-116.931726, 43.645581],
					[-116.931753, 43.646599],
					[-116.931739, 43.653089],
					[-116.931754, 43.653913],
					[-116.931739, 43.655327],
					[-116.931764, 43.657483],
					[-116.931777, 43.662254],
					[-116.931924, 43.673451],
					[-116.93193, 43.675659],
					[-116.931969, 43.677248],
					[-116.931974, 43.679591],
					[-116.932077, 43.690595],
					[-116.932115, 43.697934],
					[-116.932104, 43.705262],
					[-116.931947, 43.712634],
					[-116.93176, 43.719852],
					[-116.95186, 43.719874],
					[-116.951989, 43.734918],
					[-116.951979, 43.737347],
					[-116.952002, 43.738829],
					[-116.952194, 43.739475],
					[-116.952297, 43.740067],
					[-116.952226, 43.740657],
					[-116.952023, 43.741202],
					[-116.952009, 43.741362],
					[-116.951979, 43.744559],
					[-116.952011, 43.748618],
					[-116.951992, 43.74985],
					[-116.952028, 43.756336],
					[-116.952029, 43.761712],
					[-116.951929, 43.772109],
					[-116.951977, 43.777737],
					[-116.952015, 43.780078],
					[-116.951984, 43.780985],
					[-116.951907, 43.781371],
					[-116.951725, 43.78176],
					[-116.951524, 43.782041],
					[-116.949298, 43.785044],
					[-116.94868, 43.785904],
					[-116.948403, 43.786227],
					[-116.948184, 43.786438],
					[-116.951643, 43.788305],
					[-116.95253, 43.788743],
					[-116.953599, 43.789329],
					[-116.954638, 43.789928],
					[-116.955718, 43.790474],
					[-116.959413, 43.792477],
					[-116.960331, 43.793003],
					[-116.967992, 43.797113],
					[-116.969795, 43.798113],
					[-116.971263, 43.798865],
					[-116.971952, 43.799281],
					[-116.972215, 43.799395],
					[-116.97231, 43.799532],
					[-116.972354, 43.799905],
					[-116.97238, 43.800386],
					[-116.972389, 43.807222],
					[-116.973463, 43.807214],
					[-116.973586, 43.80724],
					[-116.973852, 43.807367],
					[-116.974183, 43.807631],
					[-116.974259, 43.807766],
					[-116.974333, 43.808006],
					[-116.974357, 43.808573],
					[-116.97433, 43.8103],
					[-116.974309, 43.816148],
					[-116.974335, 43.821913],
					[-116.97432, 43.831706],
					[-116.974335, 43.840261],
					[-116.974889, 43.840256],
					[-116.975165, 43.840317],
					[-116.975252, 43.840405],
					[-116.975302, 43.840517],
					[-116.975268, 43.840644],
					[-116.975067, 43.840789],
					[-116.974854, 43.840845],
					[-116.974331, 43.840866],
					[-116.974342, 43.837165],
					[-116.97432, 43.831706],
					[-116.974335, 43.821913],
					[-116.974309, 43.816148],
					[-116.97433, 43.8103],
					[-116.97436, 43.808943],
					[-116.974333, 43.808006],
					[-116.974259, 43.807766],
					[-116.974183, 43.807631],
					[-116.973852, 43.807367],
					[-116.973586, 43.80724],
					[-116.973463, 43.807214],
					[-116.972389, 43.807222],
					[-116.97238, 43.800386],
					[-116.972354, 43.799905],
					[-116.97231, 43.799532],
					[-116.972215, 43.799395],
					[-116.971952, 43.799281],
					[-116.971263, 43.798865],
					[-116.969795, 43.798113],
					[-116.967992, 43.797113],
					[-116.960331, 43.793003],
					[-116.959228, 43.792371],
					[-116.955718, 43.790474],
					[-116.954638, 43.789928],
					[-116.953599, 43.789329],
					[-116.95253, 43.788743],
					[-116.952127, 43.788544],
					[-116.951643, 43.788305],
					[-116.948184, 43.786438],
					[-116.947355, 43.787219],
					[-116.947164, 43.787366],
					[-116.946669, 43.786981],
					[-116.946151, 43.786678],
					[-116.947059, 43.78588],
					[-116.947368, 43.78602],
					[-116.948184, 43.786438],
					[-116.951643, 43.788305],
					[-116.95253, 43.788743],
					[-116.953599, 43.789329],
					[-116.954638, 43.789928],
					[-116.955718, 43.790474],
					[-116.959413, 43.792477],
					[-116.960331, 43.793003],
					[-116.967992, 43.797113],
					[-116.969795, 43.798113],
					[-116.971263, 43.798865],
					[-116.971952, 43.799281],
					[-116.972215, 43.799395],
					[-116.97231, 43.799532],
					[-116.972354, 43.799905],
					[-116.97238, 43.800386],
					[-116.972389, 43.807222],
					[-116.973463, 43.807214],
					[-116.973586, 43.80724],
					[-116.973852, 43.807367],
					[-116.974183, 43.807631],
					[-116.974259, 43.807766],
					[-116.974333, 43.808006],
					[-116.974357, 43.808573],
					[-116.97433, 43.8103],
					[-116.974309, 43.816148],
					[-116.974335, 43.821913],
					[-116.97432, 43.831706],
					[-116.974342, 43.837165],
					[-116.974297, 43.850238],
					[-116.97432, 43.851961],
					[-116.974292, 43.853925],
					[-116.974308, 43.855675],
					[-116.974293, 43.856255],
					[-116.974349, 43.859344],
					[-116.974337, 43.860313],
					[-116.974375, 43.864041],
					[-116.974358, 43.864397],
					[-116.974387, 43.865636],
					[-116.974426, 43.873868],
					[-116.974988, 43.874233],
					[-116.975648, 43.874691],
					[-116.975719, 43.874719],
					[-116.977184, 43.875838],
					[-116.977512, 43.876065],
					[-116.977787, 43.876233],
					[-116.978136, 43.876412],
					[-116.97874, 43.87666],
					[-116.979239, 43.87682],
					[-116.979815, 43.876956],
					[-116.980371, 43.877043],
					[-116.980834, 43.877086],
					[-116.98162, 43.87709],
					[-116.983265, 43.876986],
				],
			},
			id: "a6f1cf9e-66eb-4ba2-9bc1-95e3134a199b",
		},
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Marker",
		// 		name: "Walters Ferry, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.600406, 43.348108],
		// 	},
		// 	id: "91d0473c-092d-4176-b267-866b76580b48",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Marker",
		// 		name: "Nyssa, Oregon",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.983265, 43.876986],
		// 	},
		// 	id: "f0dfd26d-10df-43e9-ae19-55f22e32453a",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Marsing, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.812233, 43.545375],
		// 	},
		// 	id: "9ceed05a-f33d-4a7d-84c8-842da2aa2e23",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Homedale, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.932678, 43.617188],
		// 	},
		// 	id: "19dc641f-a193-4076-ae3a-88b66e176502",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Wilder, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.912045, 43.676004],
		// 	},
		// 	id: "8bf12e66-4347-4e29-83ea-3fa58096eaf7",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Parma, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.943297, 43.784976],
		// 	},
		// 	id: "e082136d-0c7a-4051-ba5d-0c36ed6f4130",
		// }
	],
};

export default SnakeRiverCanyon;
