import { createReducer } from "@reduxjs/toolkit";
import { addToTTCart, removeFromTTCart, clearTTCart, sortTTCart } from "../actions/travelTipCartActions";
import { vitaDataLayer } from "../../helpers/dataLayer";

const initialState = {
    cartItems: []
}

const cartReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(addToTTCart, (state, action) => {
            state.cartItems.findIndex(item => item.id == action.payload.id) === -1 && state.cartItems.push(action.payload);
            vitaDataLayer('cart', 'add_tt_to_cart', action.payload.title);
        })
        .addCase(removeFromTTCart, (state, action) => {
            state.cartItems = state.cartItems.filter((item) => item.id !== action.payload);
        })
        .addCase(sortTTCart, (state, action) => {
            state.cartItems = action.payload;
        })
        .addCase(clearTTCart, (state, action) => {
            state.cartItems = [];
        })
});

export default cartReducer;