import React, { useState } from "react";

const IconBikingRecreation = (props) => {
	const [active, setActive] = useState(props.active);
	return (
		<>
			<svg x="0px" y="0px"
				 viewBox="0 0 37 36"
				 style={{enableBackground:"new 0 0 37 36"}}
				 className={active ? "active" : ""}>
				<g>
					<path d="M27.7594,26.95H9.2472c-0.1625,0-0.2963,0.1338-0.2963,0.2963c0,0.1625,0.1338,0.2963,0.2963,0.2963h18.5122
						c0.1625,0,0.2963-0.1338,0.2963-0.2963C28.0556,27.0838,27.9218,26.95,27.7594,26.95z"/>
									<path d="M12.8102,20.5879c2.1492,0,3.8975-1.7484,3.8975-3.8975c0-1.4712-0.8213-2.7513-2.0249-3.4101l0.6875-1.3278l2.8659,4.9004
						c0.0573,0.0956,0.1529,0.1434,0.258,0.1434h0.0096c0.1051,0,0.2103-0.0669,0.258-0.1625l2.5601-5.0247l0.8118,1.5763
						c-1.108,0.678-1.8433,1.9007-1.8433,3.305c0,2.1492,1.7484,3.8975,3.8975,3.8975s3.8975-1.7484,3.8975-3.8975
						s-1.7484-3.8975-3.8975-3.8975c-0.5352,0-1.0507,0.1051-1.519,0.3058l-1.0125-1.958l1.0602-2.0823h0.9647
						c0.0956,0.0478,0.2676,0.1529,0.2963,0.2867c0.0287,0.1338-0.0956,0.3441-0.3441,0.5639
						c-0.1242,0.1147-0.1338,0.2963-0.0191,0.4205s0.2963,0.1338,0.4205,0.0191c0.4205-0.3823,0.6021-0.7544,0.5256-1.1176
						c-0.1051-0.5161-0.6588-0.7257-0.7162-0.7544c-0.0287-0.0096-0.0669-0.0191-0.1051-0.0191h-1.2132
						c-0.1147,0-0.2198,0.0669-0.2676,0.1625l-1.0316,2.0345l0,0l0,0l-5.7026,0.0382c-0.0573,0-0.1147,0.0191-0.1625,0.0478
						l-0.7735-1.3183l1.2514,0.0287c0.1625,0,0.3058-0.1242,0.3058-0.2867c0-0.1625-0.1242-0.3058-0.2867-0.3058l-1.7955-0.0478
						c-0.1051,0-0.2103,0.0573-0.2676,0.1434c-0.0573,0.086-0.0573,0.2103,0,0.3058l1.2132,2.0631l-0.8691,1.691
						c-0.4205-0.1529-0.8595-0.2389-1.3278-0.2389c-2.1492,0-3.8975,1.7484-3.8975,3.8975s1.7484,3.9166,3.8975,3.9166L12.8102,20.5879z
						 M24.1868,13.3854c1.8147,0,3.2954,1.4808,3.2954,3.2954c0,1.8147-1.4808,3.2954-3.2954,3.2954s-3.2954-1.4808-3.2954-3.2954
						c0-1.1654,0.6111-2.1969,1.5285-2.7799l1.4999,2.9233c0.0765,0.1434,0.258,0.2007,0.4014,0.1242
						c0.1434-0.0765,0.2007-0.258,0.1242-0.4014l-1.5094-2.9233c0.3918-0.1529,0.8118-0.2389,1.2514-0.2389L24.1868,13.3854z
						 M15.7042,11.284l5.2158-0.0287l-2.4454,4.8144l-2.7799-4.7571L15.7042,11.284z M12.8102,13.3854
						c0.3727,0,0.7257,0.0573,1.0507,0.172l-1.5285,2.9896c-0.0765,0.1434-0.0191,0.3249,0.1242,0.4014
						c0.0478,0.0191,0.086,0.0287,0.1338,0.0287c0.1051,0,0.2103-0.0573,0.2676-0.1625l1.5572-3.0087
						c1.0125,0.5639,1.7006,1.6432,1.7006,2.8851c0,1.8147-1.4808,3.2954-3.2954,3.2954S9.525,18.5056,9.525,16.691
						s1.4712-3.305,3.2859-3.305L12.8102,13.3854z"/>
									<path d="M27.7594,21.4283H9.2472c-0.1625,0-0.2963,0.1338-0.2963,0.2963c0,0.1625,0.1338,0.2963,0.2963,0.2963h18.5122
						c0.1625,0,0.2963-0.1338,0.2963-0.2963C28.0556,21.5621,27.9218,21.4283,27.7594,21.4283z"/>
									<path d="M27.6829,24.7906c0.1625,0,0.2963-0.1338,0.2963-0.2963c0-0.1625-0.1338-0.2963-0.2963-0.2963h-2.1014
						c-0.1625,0-0.2963,0.1338-0.2963,0.2963c0,0.1625,0.1338,0.2963,0.2963,0.2963H27.6829z"/>
									<path d="M22.2383,24.7906c0.1625,0,0.2963-0.1338,0.2963-0.2963c0-0.1625-0.1338-0.2963-0.2963-0.2963H20.137
						c-0.1625,0-0.2963,0.1338-0.2963,0.2963c0,0.1625,0.1338,0.2963,0.2963,0.2963H22.2383z"/>
									<path d="M9.2472,24.7906h2.1014c0.1625,0,0.2963-0.1338,0.2963-0.2963c0-0.1625-0.1338-0.2963-0.2963-0.2963H9.2472
						c-0.1625,0-0.2963,0.1338-0.2963,0.2963C8.9509,24.6568,9.0847,24.7906,9.2472,24.7906z"/>
									<path d="M16.7932,24.7906c0.1625,0,0.2963-0.1338,0.2963-0.2963c0-0.1625-0.1338-0.2963-0.2963-0.2963h-2.1014
						c-0.1625,0-0.2963,0.1338-0.2963,0.2963c0,0.1625,0.1338,0.2963,0.2963,0.2963H16.7932z"/>
				</g>
			</svg>
		</>
	);
}

export default IconBikingRecreation
