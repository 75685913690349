import { Svg, Path } from "@react-pdf/renderer";
import React from "react";

const IconWinter = () => {
	return (
		<>
			<Svg width="28" viewBox="0 0 36 37">
				<Path fill="#000" d="M27.2,17.7h-4.6l3.2-3.2c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3-0.1-0.4,0l-3.6,3.6h-3v-3l3.6-3.6c0.1-0.1,0.1-0.3,0-0.4
					s-0.3-0.1-0.4,0l-3.2,3.2V9.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3v4.6l-3.2-3.2c-0.1-0.1-0.3-0.1-0.4,0s-0.1,0.3,0,0.4
					l3.6,3.6v3h-3l-3.6-3.6c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4l3.2,3.2H9.8c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3
					h4.7l-3.2,3.2c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1l3.6-3.6h3v3l-3.6,3.6c-0.1,0.1-0.1,0.3,0,0.4
					c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1l3.2-3.2v4.7c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3v-4.7l3.2,3.2
					c0.1,0.1,0.3,0.1,0.4,0c0.1-0.1,0.1-0.3,0-0.4l-3.6-3.6v-3h3l3.6,3.6c0.1,0.1,0.1,0.1,0.2,0.1s0.1,0,0.2-0.1c0.1-0.1,0.1-0.3,0-0.4
					l-3.2-3.2h4.6c0.2,0,0.3-0.1,0.3-0.3C27.5,17.9,27.4,17.7,27.2,17.7z"/>
			</Svg>
		</>
	);
}

export default IconWinter;