import AdventureCard from "../Cards/AdventureCard";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const RecommendedActivities = (props) => {
  const recommendedActivities = useSelector((state) => state.recommendations.recommended);
  const [recommendations, setRecommendations] = useState(recommendedActivities);
  const maxLength = props.maxLength;

  useEffect(() => {
    setRecommendations(recommendedActivities);
  }, [recommendedActivities]);

  return (
    <div className="recommended">
      <h3>Suggested Activities</h3>
      <div className="cards-list">
        {props.showTWC && (
          <article className="twc-card card-wrapper">
            <div className="card-image">
              <img
                src="https://visitidaho.org/content/uploads/2023/07/twc-card.png"
                alt="Travel With Care"
                title="Travel With Care"
              />
            </div>
            <div className="card-content">
              <h5>Travel With Care</h5>
              <p>
                Get the scoop on safe travel practices, responsible recreation and how you can do your part to keep Idaho beautiful.
              </p>
              <a href="https://visitidaho.org/travel-with-care/" target="_blank" className="btn btn-green btn-small">
                Learn More
              </a>
            </div>
          </article>
        )}
        {recommendations.slice(0, maxLength).map((result, i) => (
          <AdventureCard item={result} key={i} />
        ))}
      </div>
    </div>
  );
};

RecommendedActivities.defaultProps = {
  maxLength: 4,
  showTWC: false,
};

export default RecommendedActivities;