import React from "react";

function IconSearchCircle() {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="99"
				height="99"
				viewBox="0 0 99 99"
			>
				<g
					id="Group_651"
					data-name="Group 651"
					transform="translate(-912 -272)"
				>
					<circle
						id="Ellipse_19"
						data-name="Ellipse 19"
						cx="49.5"
						cy="49.5"
						r="49.5"
						transform="translate(912 272)"
						fill="#f0f3f4"
					/>
					<g id="Search" transform="translate(933 301.523)">
						<path
							id="Path_1591"
							data-name="Path 1591"
							d="M18.3,5.95a.874.874,0,0,1-.87.87A10.577,10.577,0,0,0,6.876,17.373a.87.87,0,1,1-1.74,0A12.283,12.283,0,0,1,17.4,5.08.881.881,0,0,1,18.3,5.95ZM46.45,46.393a4.269,4.269,0,0,1-6.062,0l-9.234-9.262a8.455,8.455,0,0,1-2.47-4.855l-.9-.9a17.478,17.478,0,1,1,3.621-3.649l.926.926a8.38,8.38,0,0,1,4.855,2.47l9.234,9.234A4.26,4.26,0,0,1,46.45,46.393ZM17.4,33.034A15.661,15.661,0,1,0,1.74,17.373,15.667,15.667,0,0,0,17.4,33.034Zm27.814,8.532-9.234-9.234a6.625,6.625,0,0,0-4.042-1.993.764.764,0,0,1-.589-.253l-1.01-1.01c-.365.393-.758.786-1.151,1.151l1.01,1.01a.871.871,0,0,1,.253.617A6.442,6.442,0,0,0,32.445,35.9l9.234,9.234a2.46,2.46,0,0,0,1.768.73,2.414,2.414,0,0,0,1.768-.73A2.524,2.524,0,0,0,45.215,41.566Z"
							transform="translate(5 -3.523)"
							fill="#b7562d"
						/>
					</g>
				</g>
			</svg>
		</>
	);
}

export default IconSearchCircle;
