const ThousandSprings = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Thousand Springs Scenic Byway",
				description: "Thousand Springs Scenic Byway gives road trippers a glimpse of some of Idaho's remarkable geological wonders. Starting near Bliss, this route features canyon views, quaint small towns and Thousand Springs State Park—an expansive park made up of six separate park units with stunning waterfalls, hiking trails, wildlife and more.",
				link: "https://visitidaho.org/things-to-do/road-trips/thousand-springs-scenic-byway/",
				route: [
					"Bliss", 
					"Hagerman", 
					"Buhl",
					"Filer",
					"Twin Falls",
					"Kimberly",
					"Hansen"
				],
				shape: 'Line'
			},
			geometry: {
				type: "LineString",
				coordinates: [
					[-114.94958, 42.92422, 0],
					[-114.94914, 42.9241, 0],
					[-114.94884, 42.92403, 0],
					[-114.94866, 42.92398, 0],
					[-114.94849, 42.92394, 0],
					[-114.94838, 42.92392, 0],
					[-114.94825, 42.92389, 0],
					[-114.9478, 42.92381, 0],
					[-114.94752, 42.92377, 0],
					[-114.94742, 42.92376, 0],
					[-114.94722, 42.92373, 0],
					[-114.94716, 42.92373, 0],
					[-114.94706, 42.92372, 0],
					[-114.94693, 42.92372, 0],
					[-114.94672, 42.9237, 0],
					[-114.94657, 42.9237, 0],
					[-114.9464, 42.92369, 0],
					[-114.94465, 42.92369, 0],
					[-114.94417, 42.92368, 0],
					[-114.94324, 42.92368, 0],
					[-114.94278, 42.92367, 0],
					[-114.93983, 42.92366, 0],
					[-114.93981, 42.92349, 0],
					[-114.93981, 42.92343, 0],
					[-114.9398, 42.92337, 0],
					[-114.93978, 42.9233, 0],
					[-114.93977, 42.92324, 0],
					[-114.9397, 42.92308, 0],
					[-114.93965, 42.92298, 0],
					[-114.93956, 42.92284, 0],
					[-114.9395, 42.92277, 0],
					[-114.93945, 42.92272, 0],
					[-114.93942, 42.92268, 0],
					[-114.93938, 42.92265, 0],
					[-114.93935, 42.92262, 0],
					[-114.93929, 42.92257, 0],
					[-114.93917, 42.92248, 0],
					[-114.93912, 42.92246, 0],
					[-114.93905, 42.92242, 0],
					[-114.93899, 42.92238, 0],
					[-114.93894, 42.92236, 0],
					[-114.9389, 42.92234, 0],
					[-114.93885, 42.92231, 0],
					[-114.93878, 42.92228, 0],
					[-114.93873, 42.92227, 0],
					[-114.93869, 42.92225, 0],
					[-114.9385, 42.92219, 0],
					[-114.93821, 42.92214, 0],
					[-114.93817, 42.92213, 0],
					[-114.93812, 42.92213, 0],
					[-114.93788, 42.92211, 0],
					[-114.93664, 42.92211, 0],
					[-114.93615, 42.9221, 0],
					[-114.93583, 42.9221, 0],
					[-114.93566, 42.92209, 0],
					[-114.93512, 42.92204, 0],
					[-114.93471, 42.92198, 0],
					[-114.93454, 42.92195, 0],
					[-114.93397, 42.92182, 0],
					[-114.93383, 42.92178, 0],
					[-114.93366, 42.92172, 0],
					[-114.93349, 42.92167, 0],
					[-114.93317, 42.92155, 0],
					[-114.9327, 42.92134, 0],
					[-114.93263, 42.9213, 0],
					[-114.93253, 42.92125, 0],
					[-114.93226, 42.9211, 0],
					[-114.93184, 42.92082, 0],
					[-114.9318, 42.9208, 0],
					[-114.93158, 42.92062, 0],
					[-114.93149, 42.92056, 0],
					[-114.93143, 42.9205, 0],
					[-114.93122, 42.92031, 0],
					[-114.93091, 42.91997, 0],
					[-114.93081, 42.91985, 0],
					[-114.93073, 42.91973, 0],
					[-114.93064, 42.91961, 0],
					[-114.93056, 42.91949, 0],
					[-114.93041, 42.91924, 0],
					[-114.93029, 42.91899, 0],
					[-114.93018, 42.91873, 0],
					[-114.93011, 42.91848, 0],
					[-114.9301, 42.91846, 0],
					[-114.93004, 42.9182, 0],
					[-114.93, 42.91791, 0],
					[-114.92999, 42.91778, 0],
					[-114.92999, 42.91765, 0],
					[-114.92998, 42.91759, 0],
					[-114.92999, 42.91625, 0],
					[-114.92998, 42.91602, 0],
					[-114.92998, 42.91508, 0],
					[-114.92999, 42.91453, 0],
					[-114.92998, 42.91398, 0],
					[-114.92998, 42.9129, 0],
					[-114.92999, 42.91263, 0],
					[-114.92999, 42.91018, 0],
					[-114.92996, 42.90923, 0],
					[-114.92992, 42.90869, 0],
					[-114.92989, 42.90838, 0],
					[-114.92986, 42.90815, 0],
					[-114.92983, 42.908, 0],
					[-114.92979, 42.90775, 0],
					[-114.92969, 42.9072, 0],
					[-114.92964, 42.90699, 0],
					[-114.92963, 42.90693, 0],
					[-114.9296, 42.90681, 0],
					[-114.9296, 42.9068, 0],
					[-114.92957, 42.90667, 0],
					[-114.92942, 42.90613, 0],
					[-114.9293, 42.90573, 0],
					[-114.92921, 42.90548, 0],
					[-114.92917, 42.90534, 0],
					[-114.92907, 42.90508, 0],
					[-114.92898, 42.90482, 0],
					[-114.92876, 42.9043, 0],
					[-114.92871, 42.9042, 0],
					[-114.92859, 42.90392, 0],
					[-114.92834, 42.90341, 0],
					[-114.9282, 42.90315, 0],
					[-114.92811, 42.903, 0],
					[-114.92785, 42.90253, 0],
					[-114.92762, 42.90215, 0],
					[-114.92733, 42.9017, 0],
					[-114.92687, 42.90106, 0],
					[-114.92633, 42.90035, 0],
					[-114.92514, 42.89889, 0],
					[-114.92465, 42.89827, 0],
					[-114.92464, 42.89827, 0],
					[-114.9236, 42.89696, 0],
					[-114.92285, 42.89604, 0],
					[-114.92269, 42.89583, 0],
					[-114.92254, 42.89566, 0],
					[-114.92214, 42.89516, 0],
					[-114.92198, 42.89498, 0],
					[-114.92193, 42.89491, 0],
					[-114.92176, 42.89472, 0],
					[-114.9215, 42.89441, 0],
					[-114.92114, 42.89394, 0],
					[-114.92107, 42.89386, 0],
					[-114.92065, 42.89331, 0],
					[-114.92046, 42.89309, 0],
					[-114.91987, 42.89235, 0],
					[-114.91972, 42.89217, 0],
					[-114.91967, 42.8921, 0],
					[-114.91948, 42.89188, 0],
					[-114.91947, 42.89186, 0],
					[-114.91934, 42.8917, 0],
					[-114.91923, 42.89155, 0],
					[-114.91896, 42.89124, 0],
					[-114.91884, 42.89109, 0],
					[-114.91846, 42.89064, 0],
					[-114.91845, 42.89063, 0],
					[-114.91833, 42.89047, 0],
					[-114.9182, 42.89032, 0],
					[-114.91808, 42.89016, 0],
					[-114.91804, 42.89012, 0],
					[-114.91783, 42.88985, 0],
					[-114.91745, 42.88939, 0],
					[-114.91733, 42.88923, 0],
					[-114.91721, 42.88908, 0],
					[-114.91697, 42.88876, 0],
					[-114.91695, 42.88874, 0],
					[-114.91684, 42.88861, 0],
					[-114.91665, 42.88836, 0],
					[-114.91596, 42.8875, 0],
					[-114.91585, 42.88737, 0],
					[-114.91435, 42.88551, 0],
					[-114.9126, 42.88355, 0],
					[-114.91246, 42.88341, 0],
					[-114.91237, 42.88331, 0],
					[-114.91187, 42.88281, 0],
					[-114.9117, 42.88265, 0],
					[-114.91152, 42.88247, 0],
					[-114.9091, 42.88022, 0],
					[-114.90822, 42.87936, 0],
					[-114.90744, 42.87863, 0],
					[-114.90729, 42.87848, 0],
					[-114.90667, 42.87792, 0],
					[-114.90502, 42.87627, 0],
					[-114.90227, 42.87323, 0],
					[-114.90211, 42.87302, 0],
					[-114.90198, 42.87287, 0],
					[-114.90172, 42.87254, 0],
					[-114.89952, 42.86996, 0],
					[-114.89949, 42.86993, 0],
					[-114.89868, 42.86897, 0],
					[-114.89862, 42.86889, 0],
					[-114.89808, 42.86829, 0],
					[-114.89773, 42.86787, 0],
					[-114.8976, 42.86769, 0],
					[-114.89758, 42.86767, 0],
					[-114.89751, 42.86757, 0],
					[-114.89716, 42.86702, 0],
					[-114.89715, 42.86699, 0],
					[-114.89686, 42.86648, 0],
					[-114.89685, 42.86647, 0],
					[-114.89658, 42.86598, 0],
					[-114.89646, 42.86578, 0],
					[-114.8964, 42.86561, 0],
					[-114.89633, 42.86544, 0],
					[-114.89627, 42.86526, 0],
					[-114.89624, 42.86518, 0],
					[-114.8958, 42.86378, 0],
					[-114.89577, 42.8637, 0],
					[-114.89568, 42.86327, 0],
					[-114.89557, 42.86264, 0],
					[-114.89557, 42.86251, 0],
					[-114.89555, 42.86215, 0],
					[-114.89552, 42.86129, 0],
					[-114.89553, 42.85487, 0],
					[-114.89556, 42.85347, 0],
					[-114.89553, 42.85044, 0],
					[-114.89551, 42.85011, 0],
					[-114.89548, 42.84977, 0],
					[-114.89544, 42.84944, 0],
					[-114.89541, 42.84926, 0],
					[-114.89537, 42.84909, 0],
					[-114.89529, 42.84879, 0],
					[-114.89509, 42.84814, 0],
					[-114.89487, 42.84758, 0],
					[-114.8941, 42.8459, 0],
					[-114.89397, 42.84559, 0],
					[-114.89383, 42.84519, 0],
					[-114.89366, 42.84466, 0],
					[-114.89359, 42.84439, 0],
					[-114.89349, 42.84384, 0],
					[-114.89345, 42.84346, 0],
					[-114.89341, 42.84298, 0],
					[-114.89339, 42.84177, 0],
					[-114.89342, 42.83997, 0],
					[-114.89344, 42.83951, 0],
					[-114.89344, 42.83934, 0],
					[-114.89356, 42.83709, 0],
					[-114.8936, 42.8353, 0],
					[-114.89355, 42.83313, 0],
					[-114.89353, 42.83267, 0],
					[-114.89353, 42.82526, 0],
					[-114.89354, 42.8251, 0],
					[-114.89358, 42.82486, 0],
					[-114.89362, 42.82471, 0],
					[-114.89366, 42.82459, 0],
					[-114.8937, 42.8245, 0],
					[-114.89373, 42.82442, 0],
					[-114.89383, 42.82423, 0],
					[-114.89389, 42.82413, 0],
					[-114.89404, 42.82393, 0],
					[-114.89425, 42.82369, 0],
					[-114.89446, 42.82352, 0],
					[-114.89472, 42.82333, 0],
					[-114.89485, 42.82325, 0],
					[-114.89516, 42.82308, 0],
					[-114.89551, 42.82292, 0],
					[-114.89571, 42.82285, 0],
					[-114.89582, 42.82282, 0],
					[-114.89625, 42.82268, 0],
					[-114.89637, 42.82265, 0],
					[-114.89668, 42.82256, 0],
					[-114.89682, 42.82251, 0],
					[-114.89694, 42.82248, 0],
					[-114.89702, 42.82245, 0],
					[-114.89706, 42.82244, 0],
					[-114.89712, 42.82242, 0],
					[-114.8972, 42.8224, 0],
					[-114.89722, 42.82239, 0],
					[-114.89724, 42.82239, 0],
					[-114.89728, 42.82238, 0],
					[-114.89731, 42.82237, 0],
					[-114.89737, 42.82234, 0],
					[-114.89741, 42.82233, 0],
					[-114.89747, 42.82231, 0],
					[-114.8975, 42.82229, 0],
					[-114.89754, 42.82228, 0],
					[-114.89758, 42.82226, 0],
					[-114.89761, 42.82225, 0],
					[-114.89764, 42.82223, 0],
					[-114.8977, 42.82221, 0],
					[-114.89773, 42.82219, 0],
					[-114.89781, 42.82215, 0],
					[-114.89786, 42.82212, 0],
					[-114.89792, 42.82209, 0],
					[-114.89793, 42.82208, 0],
					[-114.89804, 42.822, 0],
					[-114.89812, 42.82193, 0],
					[-114.89815, 42.8219, 0],
					[-114.89818, 42.82188, 0],
					[-114.89819, 42.82186, 0],
					[-114.89824, 42.82181, 0],
					[-114.89825, 42.82179, 0],
					[-114.89829, 42.82175, 0],
					[-114.89831, 42.82171, 0],
					[-114.89836, 42.82164, 0],
					[-114.89842, 42.82152, 0],
					[-114.89844, 42.82146, 0],
					[-114.89846, 42.82142, 0],
					[-114.89846, 42.82139, 0],
					[-114.89848, 42.82134, 0],
					[-114.89849, 42.82116, 0],
					[-114.89851, 42.82103, 0],
					[-114.89851, 42.82081, 0],
					[-114.8985, 42.8206, 0],
					[-114.89852, 42.81833, 0],
					[-114.89853, 42.81806, 0],
					[-114.89853, 42.8177, 0],
					[-114.89854, 42.81744, 0],
					[-114.89854, 42.81528, 0],
					[-114.89855, 42.81466, 0],
					[-114.89855, 42.81374, 0],
					[-114.89856, 42.8135, 0],
					[-114.89856, 42.81212, 0],
					[-114.89857, 42.81188, 0],
					[-114.89857, 42.81167, 0],
					[-114.89856, 42.81151, 0],
					[-114.89856, 42.81116, 0],
					[-114.89855, 42.81097, 0],
					[-114.89855, 42.81088, 0],
					[-114.89854, 42.81084, 0],
					[-114.89853, 42.81077, 0],
					[-114.89852, 42.81072, 0],
					[-114.89848, 42.8106, 0],
					[-114.89848, 42.81058, 0],
					[-114.89846, 42.81054, 0],
					[-114.89844, 42.81048, 0],
					[-114.89842, 42.81045, 0],
					[-114.8984, 42.81039, 0],
					[-114.89839, 42.81037, 0],
					[-114.89828, 42.81019, 0],
					[-114.89822, 42.81011, 0],
					[-114.8982, 42.81009, 0],
					[-114.89813, 42.81, 0],
					[-114.89809, 42.80997, 0],
					[-114.89793, 42.80981, 0],
					[-114.89777, 42.8097, 0],
					[-114.89773, 42.80966, 0],
					[-114.89749, 42.8095, 0],
					[-114.89738, 42.80942, 0],
					[-114.89626, 42.80866, 0],
					[-114.89487, 42.80774, 0],
					[-114.89468, 42.80763, 0],
					[-114.89437, 42.80744, 0],
					[-114.89423, 42.80736, 0],
					[-114.89409, 42.80727, 0],
					[-114.89394, 42.80719, 0],
					[-114.89365, 42.80702, 0],
					[-114.89319, 42.80677, 0],
					[-114.89304, 42.8067, 0],
					[-114.89253, 42.80643, 0],
					[-114.89227, 42.8063, 0],
					[-114.89189, 42.80613, 0],
					[-114.89128, 42.80584, 0],
					[-114.8906, 42.80553, 0],
					[-114.89048, 42.80548, 0],
					[-114.8901, 42.80529, 0],
					[-114.88999, 42.80523, 0],
					[-114.88957, 42.80497, 0],
					[-114.88937, 42.80481, 0],
					[-114.88917, 42.80463, 0],
					[-114.88912, 42.80457, 0],
					[-114.88906, 42.80451, 0],
					[-114.88901, 42.80444, 0],
					[-114.88895, 42.80437, 0],
					[-114.88891, 42.80431, 0],
					[-114.88887, 42.80426, 0],
					[-114.88883, 42.8042, 0],
					[-114.88879, 42.80412, 0],
					[-114.8887, 42.80397, 0],
					[-114.88868, 42.80392, 0],
					[-114.88867, 42.80388, 0],
					[-114.88858, 42.80366, 0],
					[-114.88854, 42.8035, 0],
					[-114.88849, 42.80314, 0],
					[-114.88849, 42.80298, 0],
					[-114.88848, 42.80289, 0],
					[-114.88849, 42.79704, 0],
					[-114.88852, 42.7948, 0],
					[-114.88853, 42.78993, 0],
					[-114.88852, 42.78859, 0],
					[-114.88853, 42.78843, 0],
					[-114.88855, 42.7884, 0],
					[-114.88855, 42.78838, 0],
					[-114.88856, 42.78837, 0],
					[-114.88856, 42.78832, 0],
					[-114.88857, 42.78825, 0],
					[-114.88857, 42.78812, 0],
					[-114.88858, 42.788, 0],
					[-114.88858, 42.78762, 0],
					[-114.88859, 42.78729, 0],
					[-114.88859, 42.78667, 0],
					[-114.8886, 42.78626, 0],
					[-114.8886, 42.78542, 0],
					[-114.88858, 42.78536, 0],
					[-114.88859, 42.7849, 0],
					[-114.8886, 42.78469, 0],
					[-114.8886, 42.78434, 0],
					[-114.88861, 42.78396, 0],
					[-114.88862, 42.78271, 0],
					[-114.88864, 42.78199, 0],
					[-114.88867, 42.77997, 0],
					[-114.88867, 42.77919, 0],
					[-114.8887, 42.77769, 0],
					[-114.8887, 42.77742, 0],
					[-114.88869, 42.77701, 0],
					[-114.88869, 42.77674, 0],
					[-114.88868, 42.77659, 0],
					[-114.88867, 42.77631, 0],
					[-114.88866, 42.77625, 0],
					[-114.88866, 42.77617, 0],
					[-114.88863, 42.77587, 0],
					[-114.88862, 42.77572, 0],
					[-114.8886, 42.77561, 0],
					[-114.88859, 42.77548, 0],
					[-114.88855, 42.77522, 0],
					[-114.88843, 42.7746, 0],
					[-114.88837, 42.77434, 0],
					[-114.8883, 42.77407, 0],
					[-114.88822, 42.77381, 0],
					[-114.88816, 42.77358, 0],
					[-114.88807, 42.77332, 0],
					[-114.88803, 42.77318, 0],
					[-114.88775, 42.77243, 0],
					[-114.88764, 42.77217, 0],
					[-114.88752, 42.77191, 0],
					[-114.88726, 42.77139, 0],
					[-114.88705, 42.77101, 0],
					[-114.88671, 42.77044, 0],
					[-114.88669, 42.7704, 0],
					[-114.88658, 42.77024, 0],
					[-114.88652, 42.77014, 0],
					[-114.8863, 42.76982, 0],
					[-114.88613, 42.7696, 0],
					[-114.88609, 42.76953, 0],
					[-114.88585, 42.76922, 0],
					[-114.88526, 42.76849, 0],
					[-114.88486, 42.76798, 0],
					[-114.88249, 42.76504, 0],
					[-114.88245, 42.76498, 0],
					[-114.88232, 42.76483, 0],
					[-114.88212, 42.76457, 0],
					[-114.87909, 42.76083, 0],
					[-114.87846, 42.76003, 0],
					[-114.87569, 42.7566, 0],
					[-114.87569, 42.75659, 0],
					[-114.87424, 42.7548, 0],
					[-114.87406, 42.75456, 0],
					[-114.87367, 42.75413, 0],
					[-114.87354, 42.75401, 0],
					[-114.87341, 42.75388, 0],
					[-114.87287, 42.75344, 0],
					[-114.8728, 42.75339, 0],
					[-114.87273, 42.75333, 0],
					[-114.8725, 42.75318, 0],
					[-114.87242, 42.75312, 0],
					[-114.87199, 42.75287, 0],
					[-114.8717, 42.75271, 0],
					[-114.87161, 42.75267, 0],
					[-114.87143, 42.75258, 0],
					[-114.87132, 42.75252, 0],
					[-114.87122, 42.75248, 0],
					[-114.87097, 42.75237, 0],
					[-114.87072, 42.75227, 0],
					[-114.87053, 42.7522, 0],
					[-114.87041, 42.75215, 0],
					[-114.87022, 42.75209, 0],
					[-114.87011, 42.75205, 0],
					[-114.86999, 42.75202, 0],
					[-114.86949, 42.75188, 0],
					[-114.86943, 42.75187, 0],
					[-114.86905, 42.75178, 0],
					[-114.86896, 42.75177, 0],
					[-114.86872, 42.75172, 0],
					[-114.86864, 42.7517, 0],
					[-114.86821, 42.75162, 0],
					[-114.86783, 42.75154, 0],
					[-114.86754, 42.75149, 0],
					[-114.867, 42.75138, 0],
					[-114.85718, 42.7495, 0],
					[-114.85643, 42.74931, 0],
					[-114.85617, 42.74923, 0],
					[-114.85578, 42.74909, 0],
					[-114.85551, 42.749, 0],
					[-114.85532, 42.74892, 0],
					[-114.85504, 42.74882, 0],
					[-114.85462, 42.74863, 0],
					[-114.85437, 42.74851, 0],
					[-114.85428, 42.74846, 0],
					[-114.85419, 42.74842, 0],
					[-114.85396, 42.7483, 0],
					[-114.85362, 42.74811, 0],
					[-114.85361, 42.7481, 0],
					[-114.85338, 42.74796, 0],
					[-114.85329, 42.74791, 0],
					[-114.85311, 42.74779, 0],
					[-114.85277, 42.74755, 0],
					[-114.85256, 42.74739, 0],
					[-114.85218, 42.74708, 0],
					[-114.85195, 42.74688, 0],
					[-114.85175, 42.74668, 0],
					[-114.85167, 42.74659, 0],
					[-114.85159, 42.74652, 0],
					[-114.85145, 42.74637, 0],
					[-114.85137, 42.74627, 0],
					[-114.85131, 42.74621, 0],
					[-114.85124, 42.74613, 0],
					[-114.85092, 42.74572, 0],
					[-114.85085, 42.74561, 0],
					[-114.85082, 42.74558, 0],
					[-114.85075, 42.74547, 0],
					[-114.85065, 42.74533, 0],
					[-114.85048, 42.74506, 0],
					[-114.85034, 42.7448, 0],
					[-114.85016, 42.74443, 0],
					[-114.85001, 42.7441, 0],
					[-114.84965, 42.74319, 0],
					[-114.84929, 42.74232, 0],
					[-114.84927, 42.74228, 0],
					[-114.84916, 42.742, 0],
					[-114.849, 42.74163, 0],
					[-114.84857, 42.74057, 0],
					[-114.84847, 42.74034, 0],
					[-114.8484, 42.74016, 0],
					[-114.8483, 42.73994, 0],
					[-114.84814, 42.73953, 0],
					[-114.84813, 42.73952, 0],
					[-114.84809, 42.73941, 0],
					[-114.84807, 42.73932, 0],
					[-114.84804, 42.73923, 0],
					[-114.84801, 42.73916, 0],
					[-114.84799, 42.73907, 0],
					[-114.84796, 42.73898, 0],
					[-114.84791, 42.73877, 0],
					[-114.84778, 42.73811, 0],
					[-114.84773, 42.73775, 0],
					[-114.84769, 42.73738, 0],
					[-114.84767, 42.73688, 0],
					[-114.84767, 42.73671, 0],
					[-114.8477, 42.73611, 0],
					[-114.84772, 42.73595, 0],
					[-114.84774, 42.73584, 0],
					[-114.84775, 42.73572, 0],
					[-114.84789, 42.73499, 0],
					[-114.84801, 42.7345, 0],
					[-114.84811, 42.73422, 0],
					[-114.84824, 42.7339, 0],
					[-114.84824, 42.73389, 0],
					[-114.84836, 42.73359, 0],
					[-114.84881, 42.73258, 0],
					[-114.85102, 42.72749, 0],
					[-114.85126, 42.72697, 0],
					[-114.8516, 42.72619, 0],
					[-114.85176, 42.7258, 0],
					[-114.85188, 42.72554, 0],
					[-114.85254, 42.72397, 0],
					[-114.85328, 42.72228, 0],
					[-114.85335, 42.72214, 0],
					[-114.85351, 42.72177, 0],
					[-114.85378, 42.72109, 0],
					[-114.85403, 42.72038, 0],
					[-114.8541, 42.72015, 0],
					[-114.8542, 42.71978, 0],
					[-114.85433, 42.71922, 0],
					[-114.85443, 42.71871, 0],
					[-114.8545, 42.71827, 0],
					[-114.85451, 42.71815, 0],
					[-114.85453, 42.718, 0],
					[-114.85456, 42.71762, 0],
					[-114.8546, 42.7158, 0],
					[-114.85461, 42.71556, 0],
					[-114.85461, 42.71501, 0],
					[-114.85462, 42.71494, 0],
					[-114.85465, 42.7146, 0],
					[-114.85469, 42.71438, 0],
					[-114.85471, 42.71429, 0],
					[-114.85473, 42.71417, 0],
					[-114.85477, 42.71404, 0],
					[-114.85478, 42.71399, 0],
					[-114.85481, 42.71391, 0],
					[-114.85483, 42.71383, 0],
					[-114.85486, 42.71377, 0],
					[-114.85489, 42.71369, 0],
					[-114.85495, 42.71356, 0],
					[-114.85498, 42.71347, 0],
					[-114.8552, 42.71305, 0],
					[-114.85531, 42.71287, 0],
					[-114.85536, 42.71278, 0],
					[-114.85542, 42.7127, 0],
					[-114.85547, 42.71262, 0],
					[-114.85567, 42.71236, 0],
					[-114.85572, 42.7123, 0],
					[-114.85578, 42.71224, 0],
					[-114.85599, 42.71201, 0],
					[-114.85618, 42.71183, 0],
					[-114.8568, 42.71128, 0],
					[-114.85689, 42.71121, 0],
					[-114.8571, 42.71102, 0],
					[-114.85717, 42.71097, 0],
					[-114.85786, 42.71037, 0],
					[-114.85884, 42.70954, 0],
					[-114.85887, 42.70951, 0],
					[-114.85906, 42.70934, 0],
					[-114.85917, 42.70923, 0],
					[-114.85929, 42.70912, 0],
					[-114.85941, 42.709, 0],
					[-114.85952, 42.70888, 0],
					[-114.8596, 42.70878, 0],
					[-114.85972, 42.70865, 0],
					[-114.8599, 42.70841, 0],
					[-114.85996, 42.70832, 0],
					[-114.85999, 42.70828, 0],
					[-114.86021, 42.70792, 0],
					[-114.86034, 42.70766, 0],
					[-114.86039, 42.70753, 0],
					[-114.86045, 42.70739, 0],
					[-114.8605, 42.70726, 0],
					[-114.86058, 42.70699, 0],
					[-114.86061, 42.70686, 0],
					[-114.86065, 42.70672, 0],
					[-114.86067, 42.70661, 0],
					[-114.86069, 42.70644, 0],
					[-114.86072, 42.70625, 0],
					[-114.86072, 42.70576, 0],
					[-114.86071, 42.70563, 0],
					[-114.86067, 42.70535, 0],
					[-114.86066, 42.70522, 0],
					[-114.86063, 42.70507, 0],
					[-114.86049, 42.70463, 0],
					[-114.86035, 42.70428, 0],
					[-114.86028, 42.70415, 0],
					[-114.86022, 42.70402, 0],
					[-114.86006, 42.70376, 0],
					[-114.85975, 42.70328, 0],
					[-114.85906, 42.70229, 0],
					[-114.85831, 42.70118, 0],
					[-114.85805, 42.70082, 0],
					[-114.85727, 42.69966, 0],
					[-114.85714, 42.69948, 0],
					[-114.85664, 42.69875, 0],
					[-114.85655, 42.69863, 0],
					[-114.85623, 42.69815, 0],
					[-114.85613, 42.69801, 0],
					[-114.85598, 42.69777, 0],
					[-114.85524, 42.69673, 0],
					[-114.85512, 42.69658, 0],
					[-114.8548, 42.69609, 0],
					[-114.85479, 42.69606, 0],
					[-114.8543, 42.69536, 0],
					[-114.85423, 42.69524, 0],
					[-114.85391, 42.69479, 0],
					[-114.85379, 42.69461, 0],
					[-114.85371, 42.69451, 0],
					[-114.85319, 42.69373, 0],
					[-114.85285, 42.69326, 0],
					[-114.85281, 42.69319, 0],
					[-114.85274, 42.69311, 0],
					[-114.85261, 42.69294, 0],
					[-114.85253, 42.69286, 0],
					[-114.85233, 42.69263, 0],
					[-114.85224, 42.69252, 0],
					[-114.85214, 42.69241, 0],
					[-114.85191, 42.69218, 0],
					[-114.85177, 42.69205, 0],
					[-114.85169, 42.69197, 0],
					[-114.85144, 42.69174, 0],
					[-114.85129, 42.69162, 0],
					[-114.85109, 42.69145, 0],
					[-114.85095, 42.69134, 0],
					[-114.85081, 42.69124, 0],
					[-114.85058, 42.69105, 0],
					[-114.84997, 42.69062, 0],
					[-114.84968, 42.69044, 0],
					[-114.84961, 42.69039, 0],
					[-114.84945, 42.6903, 0],
					[-114.84919, 42.69014, 0],
					[-114.8487, 42.68988, 0],
					[-114.84863, 42.68985, 0],
					[-114.84857, 42.68981, 0],
					[-114.8485, 42.68977, 0],
					[-114.84825, 42.68965, 0],
					[-114.84816, 42.6896, 0],
					[-114.84804, 42.68955, 0],
					[-114.84794, 42.6895, 0],
					[-114.8472, 42.68918, 0],
					[-114.84689, 42.68903, 0],
					[-114.84673, 42.68896, 0],
					[-114.84661, 42.6889, 0],
					[-114.84656, 42.68888, 0],
					[-114.8465, 42.68885, 0],
					[-114.84643, 42.68881, 0],
					[-114.84633, 42.68876, 0],
					[-114.84626, 42.68873, 0],
					[-114.84595, 42.68854, 0],
					[-114.84587, 42.68848, 0],
					[-114.84575, 42.6884, 0],
					[-114.84554, 42.68825, 0],
					[-114.84546, 42.68818, 0],
					[-114.84537, 42.68811, 0],
					[-114.84529, 42.68804, 0],
					[-114.84521, 42.68796, 0],
					[-114.84515, 42.68789, 0],
					[-114.84508, 42.68782, 0],
					[-114.84501, 42.68774, 0],
					[-114.84494, 42.68767, 0],
					[-114.84481, 42.68749, 0],
					[-114.84471, 42.68736, 0],
					[-114.84441, 42.68688, 0],
					[-114.84438, 42.68681, 0],
					[-114.84436, 42.68674, 0],
					[-114.84431, 42.68663, 0],
					[-114.8443, 42.68658, 0],
					[-114.84427, 42.68651, 0],
					[-114.84423, 42.68634, 0],
					[-114.84421, 42.68623, 0],
					[-114.84419, 42.68615, 0],
					[-114.84417, 42.68603, 0],
					[-114.84417, 42.68595, 0],
					[-114.84415, 42.68576, 0],
					[-114.84415, 42.68549, 0],
					[-114.84416, 42.68543, 0],
					[-114.84416, 42.68535, 0],
					[-114.84418, 42.68521, 0],
					[-114.84418, 42.68518, 0],
					[-114.84419, 42.68511, 0],
					[-114.84422, 42.68496, 0],
					[-114.84424, 42.68489, 0],
					[-114.84425, 42.68484, 0],
					[-114.84427, 42.68477, 0],
					[-114.84451, 42.68419, 0],
					[-114.84495, 42.68329, 0],
					[-114.84504, 42.68312, 0],
					[-114.84509, 42.68301, 0],
					[-114.84583, 42.68152, 0],
					[-114.84588, 42.68143, 0],
					[-114.84596, 42.68125, 0],
					[-114.8464, 42.68042, 0],
					[-114.84721, 42.67881, 0],
					[-114.84733, 42.67856, 0],
					[-114.84843, 42.67643, 0],
					[-114.84855, 42.67621, 0],
					[-114.84876, 42.67579, 0],
					[-114.8488, 42.67569, 0],
					[-114.84882, 42.67566, 0],
					[-114.84884, 42.6756, 0],
					[-114.8492, 42.67478, 0],
					[-114.84927, 42.67464, 0],
					[-114.84941, 42.67429, 0],
					[-114.84945, 42.67421, 0],
					[-114.84949, 42.67409, 0],
					[-114.8496, 42.67383, 0],
					[-114.84966, 42.6737, 0],
					[-114.84971, 42.67357, 0],
					[-114.84977, 42.67343, 0],
					[-114.85015, 42.67233, 0],
					[-114.85022, 42.67211, 0],
					[-114.85048, 42.67118, 0],
					[-114.85055, 42.6709, 0],
					[-114.8507, 42.67017, 0],
					[-114.85097, 42.66872, 0],
					[-114.85104, 42.6683, 0],
					[-114.85112, 42.66761, 0],
					[-114.85115, 42.66723, 0],
					[-114.85119, 42.66689, 0],
					[-114.85122, 42.6665, 0],
					[-114.85125, 42.66597, 0],
					[-114.85126, 42.66542, 0],
					[-114.85124, 42.66432, 0],
					[-114.85038, 42.65183, 0],
					[-114.85038, 42.65119, 0],
					[-114.85039, 42.65093, 0],
					[-114.85039, 42.64956, 0],
					[-114.85041, 42.64858, 0],
					[-114.85043, 42.64449, 0],
					[-114.85044, 42.64415, 0],
					[-114.85044, 42.64261, 0],
					[-114.85045, 42.64238, 0],
					[-114.85046, 42.64084, 0],
					[-114.85045, 42.64072, 0],
					[-114.85045, 42.64058, 0],
					[-114.85046, 42.64031, 0],
					[-114.85046, 42.6392, 0],
					[-114.85047, 42.63878, 0],
					[-114.85048, 42.6361, 0],
					[-114.85049, 42.63569, 0],
					[-114.85049, 42.6351, 0],
					[-114.8505, 42.63457, 0],
					[-114.85051, 42.63435, 0],
					[-114.85051, 42.63347, 0],
					[-114.85052, 42.63311, 0],
					[-114.85052, 42.63181, 0],
					[-114.85053, 42.63174, 0],
					[-114.85053, 42.63094, 0],
					[-114.85054, 42.63032, 0],
					[-114.85054, 42.62931, 0],
					[-114.85055, 42.62922, 0],
					[-114.85055, 42.6285, 0],
					[-114.85056, 42.62814, 0],
					[-114.85056, 42.62688, 0],
					[-114.85057, 42.62648, 0],
					[-114.85057, 42.62616, 0],
					[-114.85056, 42.62573, 0],
					[-114.85057, 42.62544, 0],
					[-114.85056, 42.62519, 0],
					[-114.85057, 42.62364, 0],
					[-114.85056, 42.62341, 0],
					[-114.85053, 42.62328, 0],
					[-114.85051, 42.62321, 0],
					[-114.85049, 42.62316, 0],
					[-114.85038, 42.62301, 0],
					[-114.85036, 42.62299, 0],
					[-114.85035, 42.62297, 0],
					[-114.85026, 42.6229, 0],
					[-114.8502, 42.62286, 0],
					[-114.85016, 42.62283, 0],
					[-114.85012, 42.62281, 0],
					[-114.85007, 42.62279, 0],
					[-114.85001, 42.62276, 0],
					[-114.84997, 42.62275, 0],
					[-114.84988, 42.62272, 0],
					[-114.84977, 42.62269, 0],
					[-114.84947, 42.62268, 0],
					[-114.84925, 42.62268, 0],
					[-114.84908, 42.62267, 0],
					[-114.84764, 42.62267, 0],
					[-114.83838, 42.62258, 0],
					[-114.83328, 42.62256, 0],
					[-114.83277, 42.62255, 0],
					[-114.83257, 42.62253, 0],
					[-114.83225, 42.62248, 0],
					[-114.83207, 42.62244, 0],
					[-114.83165, 42.62227, 0],
					[-114.83163, 42.62226, 0],
					[-114.83157, 42.62222, 0],
					[-114.83144, 42.62215, 0],
					[-114.83143, 42.62214, 0],
					[-114.83138, 42.62211, 0],
					[-114.83109, 42.62185, 0],
					[-114.83103, 42.62177, 0],
					[-114.83092, 42.62164, 0],
					[-114.8309, 42.62159, 0],
					[-114.83083, 42.62146, 0],
					[-114.83075, 42.62124, 0],
					[-114.83071, 42.62104, 0],
					[-114.83071, 42.62056, 0],
					[-114.83072, 42.62022, 0],
					[-114.83073, 42.62004, 0],
					[-114.83074, 42.61932, 0],
					[-114.83077, 42.61851, 0],
					[-114.83079, 42.61741, 0],
					[-114.8308, 42.61726, 0],
					[-114.83084, 42.61565, 0],
					[-114.83085, 42.61551, 0],
					[-114.83085, 42.61531, 0],
					[-114.83087, 42.61467, 0],
					[-114.83089, 42.61341, 0],
					[-114.8309, 42.61323, 0],
					[-114.83091, 42.6127, 0],
					[-114.83091, 42.61232, 0],
					[-114.83092, 42.61196, 0],
					[-114.83093, 42.6118, 0],
					[-114.83093, 42.61162, 0],
					[-114.83096, 42.61066, 0],
					[-114.83096, 42.61054, 0],
					[-114.83097, 42.61036, 0],
					[-114.83098, 42.60991, 0],
					[-114.83098, 42.60966, 0],
					[-114.83099, 42.60946, 0],
					[-114.831, 42.60906, 0],
					[-114.831, 42.60887, 0],
					[-114.83101, 42.60866, 0],
					[-114.83101, 42.60858, 0],
					[-114.831, 42.60851, 0],
					[-114.83099, 42.60838, 0],
					[-114.83097, 42.60831, 0],
					[-114.83095, 42.60826, 0],
					[-114.83093, 42.60822, 0],
					[-114.83091, 42.60819, 0],
					[-114.83089, 42.60815, 0],
					[-114.83087, 42.60812, 0],
					[-114.83083, 42.60809, 0],
					[-114.83081, 42.60806, 0],
					[-114.83078, 42.60803, 0],
					[-114.83075, 42.60799, 0],
					[-114.83071, 42.60797, 0],
					[-114.83068, 42.60794, 0],
					[-114.8306, 42.60789, 0],
					[-114.83057, 42.60788, 0],
					[-114.83052, 42.60785, 0],
					[-114.83046, 42.60782, 0],
					[-114.83036, 42.60778, 0],
					[-114.83025, 42.60775, 0],
					[-114.83012, 42.60772, 0],
					[-114.8289, 42.60772, 0],
					[-114.82864, 42.60773, 0],
					[-114.82801, 42.60773, 0],
					[-114.82776, 42.60774, 0],
					[-114.82705, 42.60774, 0],
					[-114.82661, 42.60775, 0],
					[-114.82609, 42.60775, 0],
					[-114.82596, 42.60776, 0],
					[-114.82486, 42.60777, 0],
					[-114.82454, 42.60778, 0],
					[-114.82378, 42.60778, 0],
					[-114.82358, 42.60779, 0],
					[-114.81844, 42.60783, 0],
					[-114.81827, 42.60784, 0],
					[-114.81717, 42.60784, 0],
					[-114.81585, 42.60786, 0],
					[-114.81518, 42.60786, 0],
					[-114.81473, 42.60787, 0],
					[-114.81416, 42.60787, 0],
					[-114.81371, 42.60788, 0],
					[-114.80998, 42.60791, 0],
					[-114.80541, 42.60791, 0],
					[-114.80462, 42.60792, 0],
					[-114.80207, 42.60792, 0],
					[-114.8014, 42.60793, 0],
					[-114.79745, 42.60793, 0],
					[-114.79697, 42.60794, 0],
					[-114.79541, 42.60794, 0],
					[-114.7946, 42.60795, 0],
					[-114.78626, 42.60793, 0],
					[-114.78583, 42.60792, 0],
					[-114.77255, 42.60787, 0],
					[-114.77242, 42.60785, 0],
					[-114.77223, 42.60783, 0],
					[-114.77197, 42.60778, 0],
					[-114.77165, 42.60762, 0],
					[-114.76223, 42.60081, 0],
					[-114.76222, 42.60074, 0],
					[-114.76179, 42.60044, 0],
					[-114.76097, 42.59984, 0],
					[-114.76085, 42.59976, 0],
					[-114.76001, 42.59915, 0],
					[-114.75988, 42.59905, 0],
					[-114.75974, 42.59895, 0],
					[-114.75958, 42.59908, 0],
					[-114.75974, 42.59895, 0],
					[-114.75958, 42.59884, 0],
					[-114.75853, 42.59807, 0],
					[-114.75844, 42.59806, 0],
					[-114.75766, 42.5975, 0],
					[-114.75744, 42.59733, 0],
					[-114.75705, 42.59705, 0],
					[-114.75688, 42.59692, 0],
					[-114.75679, 42.59686, 0],
					[-114.75541, 42.59586, 0],
					[-114.75524, 42.59573, 0],
					[-114.75506, 42.59561, 0],
					[-114.75268, 42.59388, 0],
					[-114.75263, 42.59386, 0],
					[-114.75249, 42.59377, 0],
					[-114.75231, 42.59368, 0],
					[-114.75206, 42.59359, 0],
					[-114.75192, 42.59355, 0],
					[-114.75178, 42.59352, 0],
					[-114.7517, 42.59351, 0],
					[-114.75136, 42.59349, 0],
					[-114.75089, 42.59348, 0],
					[-114.7391, 42.59347, 0],
					[-114.73877, 42.59348, 0],
					[-114.7375, 42.59347, 0],
					[-114.73416, 42.59348, 0],
					[-114.73388, 42.59347, 0],
					[-114.71723, 42.59347, 0],
					[-114.71625, 42.59346, 0],
					[-114.71015, 42.59346, 0],
					[-114.71009, 42.59345, 0],
					[-114.71006, 42.59344, 0],
					[-114.70984, 42.59344, 0],
					[-114.70943, 42.59343, 0],
					[-114.70918, 42.59343, 0],
					[-114.70873, 42.59342, 0],
					[-114.70712, 42.59342, 0],
					[-114.70704, 42.59341, 0],
					[-114.70673, 42.59341, 0],
					[-114.70603, 42.59338, 0],
					[-114.70413, 42.59326, 0],
					[-114.70409, 42.59327, 0],
					[-114.70403, 42.59327, 0],
					[-114.70382, 42.59326, 0],
					[-114.69445, 42.59254, 0],
					[-114.69396, 42.59251, 0],
					[-114.68672, 42.59195, 0],
					[-114.68609, 42.59189, 0],
					[-114.68569, 42.59184, 0],
					[-114.68567, 42.59184, 0],
					[-114.68542, 42.59181, 0],
					[-114.68429, 42.59164, 0],
					[-114.68337, 42.59147, 0],
					[-114.68331, 42.59145, 0],
					[-114.68284, 42.59135, 0],
					[-114.68156, 42.59103, 0],
					[-114.6807, 42.59079, 0],
					[-114.66291, 42.58603, 0],
					[-114.65668, 42.58422, 0],
					[-114.65381, 42.58345, 0],
					[-114.64811, 42.58203, 0],
					[-114.64628, 42.58153, 0],
					[-114.64474, 42.58106, 0],
					[-114.64336, 42.58058, 0],
					[-114.63649, 42.57806, 0],
					[-114.636, 42.57785, 0],
					[-114.63572, 42.57771, 0],
					[-114.6353, 42.57747, 0],
					[-114.63504, 42.57731, 0],
					[-114.63476, 42.57711, 0],
					[-114.63452, 42.57693, 0],
					[-114.63431, 42.57675, 0],
					[-114.6342, 42.57665, 0],
					[-114.63388, 42.57633, 0],
					[-114.63365, 42.57606, 0],
					[-114.63355, 42.57593, 0],
					[-114.63342, 42.57575, 0],
					[-114.63328, 42.57554, 0],
					[-114.63319, 42.57542, 0],
					[-114.63287, 42.57489, 0],
					[-114.63273, 42.57468, 0],
					[-114.62976, 42.56981, 0],
					[-114.62963, 42.56962, 0],
					[-114.62959, 42.56955, 0],
					[-114.62942, 42.5693, 0],
					[-114.62936, 42.56922, 0],
					[-114.62927, 42.56909, 0],
					[-114.62879, 42.56851, 0],
					[-114.62843, 42.56811, 0],
					[-114.62826, 42.56794, 0],
					[-114.62817, 42.56786, 0],
					[-114.62811, 42.5678, 0],
					[-114.62778, 42.5675, 0],
					[-114.62757, 42.56732, 0],
					[-114.62714, 42.56698, 0],
					[-114.62697, 42.56686, 0],
					[-114.6266, 42.56658, 0],
					[-114.62605, 42.56623, 0],
					[-114.62567, 42.566, 0],
					[-114.62557, 42.56595, 0],
					[-114.62526, 42.56577, 0],
					[-114.62479, 42.56554, 0],
					[-114.6246, 42.56546, 0],
					[-114.62441, 42.56537, 0],
					[-114.62392, 42.56516, 0],
					[-114.62374, 42.56509, 0],
					[-114.62321, 42.5649, 0],
					[-114.62251, 42.56467, 0],
					[-114.62149, 42.56439, 0],
					[-114.62112, 42.5643, 0],
					[-114.62064, 42.5642, 0],
					[-114.62048, 42.56418, 0],
					[-114.62033, 42.56415, 0],
					[-114.62012, 42.56412, 0],
					[-114.62003, 42.5641, 0],
					[-114.61996, 42.56409, 0],
					[-114.61992, 42.56409, 0],
					[-114.61971, 42.56406, 0],
					[-114.61939, 42.56403, 0],
					[-114.61911, 42.56399, 0],
					[-114.6189, 42.56398, 0],
					[-114.61841, 42.56394, 0],
					[-114.61807, 42.56393, 0],
					[-114.61778, 42.56391, 0],
					[-114.61729, 42.56389, 0],
					[-114.61713, 42.56389, 0],
					[-114.61682, 42.56388, 0],
					[-114.60762, 42.56388, 0],
					[-114.60683, 42.56389, 0],
					[-114.60509, 42.56389, 0],
					[-114.60443, 42.56388, 0],
					[-114.60263, 42.56388, 0],
					[-114.6014, 42.56386, 0],
					[-114.59915, 42.56376, 0],
					[-114.59884, 42.56376, 0],
					[-114.59786, 42.56374, 0],
					[-114.58769, 42.56375, 0],
					[-114.58672, 42.56373, 0],
					[-114.58628, 42.56373, 0],
					[-114.58464, 42.5637, 0],
					[-114.58119, 42.5637, 0],
					[-114.58096, 42.56365, 0],
					[-114.58062, 42.56366, 0],
					[-114.57571, 42.56366, 0],
					[-114.57515, 42.56365, 0],
					[-114.57468, 42.56365, 0],
					[-114.57465, 42.56366, 0],
					[-114.57415, 42.56365, 0],
					[-114.57368, 42.56366, 0],
					[-114.5677, 42.56366, 0],
					[-114.5674, 42.56371, 0],
					[-114.56604, 42.56371, 0],
					[-114.56579, 42.5637, 0],
					[-114.5596, 42.56371, 0],
					[-114.55779, 42.56377, 0],
					[-114.55723, 42.5638, 0],
					[-114.55611, 42.56388, 0],
					[-114.55438, 42.56396, 0],
					[-114.55427, 42.56397, 0],
					[-114.55362, 42.56399, 0],
					[-114.55302, 42.56399, 0],
					[-114.55267, 42.564, 0],
					[-114.53547, 42.56393, 0],
					[-114.53434, 42.5639, 0],
					[-114.53314, 42.56385, 0],
					[-114.53308, 42.56384, 0],
					[-114.53216, 42.56379, 0],
					[-114.53035, 42.56374, 0],
					[-114.52023, 42.56373, 0],
					[-114.51968, 42.56375, 0],
					[-114.51702, 42.56381, 0],
					[-114.5159, 42.56382, 0],
					[-114.50748, 42.56382, 0],
					[-114.50714, 42.56383, 0],
					[-114.50478, 42.56383, 0],
					[-114.50459, 42.56384, 0],
					[-114.50427, 42.56384, 0],
					[-114.50042, 42.56409, 0],
					[-114.49842, 42.56417, 0],
					[-114.49677, 42.56419, 0],
					[-114.49602, 42.56417, 0],
					[-114.49552, 42.56417, 0],
					[-114.4945, 42.56413, 0],
					[-114.49436, 42.56412, 0],
					[-114.49411, 42.56412, 0],
					[-114.49335, 42.56407, 0],
					[-114.4931, 42.56406, 0],
					[-114.49276, 42.56403, 0],
					[-114.49238, 42.56401, 0],
					[-114.49206, 42.56398, 0],
					[-114.49034, 42.56385, 0],
					[-114.48978, 42.56382, 0],
					[-114.48311, 42.56334, 0],
					[-114.48243, 42.5633, 0],
					[-114.48227, 42.56328, 0],
					[-114.48189, 42.56326, 0],
					[-114.48182, 42.56321, 0],
					[-114.48182, 42.5632, 0],
					[-114.48178, 42.5632, 0],
					[-114.48176, 42.56319, 0],
					[-114.48167, 42.56319, 0],
					[-114.48136, 42.56316, 0],
					[-114.4801, 42.56308, 0],
					[-114.48009, 42.56228, 0],
					[-114.48003, 42.56172, 0],
					[-114.47989, 42.56165, 0],
					[-114.47951, 42.5614, 0],
					[-114.47829, 42.56051, 0],
					[-114.47708, 42.5596, 0],
					[-114.47613, 42.55892, 0],
					[-114.47584, 42.55872, 0],
					[-114.47346, 42.55696, 0],
					[-114.47275, 42.55646, 0],
					[-114.47224, 42.55607, 0],
					[-114.47096, 42.55516, 0],
					[-114.46608, 42.55157, 0],
					[-114.46334, 42.54959, 0],
					[-114.4632, 42.5495, 0],
					[-114.46313, 42.54945, 0],
					[-114.46299, 42.54937, 0],
					[-114.46283, 42.54929, 0],
					[-114.46276, 42.54925, 0],
					[-114.46268, 42.54922, 0],
					[-114.46249, 42.54916, 0],
					[-114.46054, 42.54868, 0],
					[-114.4604, 42.54865, 0],
					[-114.46036, 42.54865, 0],
					[-114.46032, 42.54864, 0],
					[-114.46028, 42.54864, 0],
					[-114.46019, 42.54863, 0],
					[-114.45945, 42.54863, 0],
					[-114.45898, 42.54865, 0],
					[-114.45849, 42.54864, 0],
					[-114.45752, 42.54864, 0],
					[-114.45654, 42.54862, 0],
					[-114.45462, 42.54862, 0],
					[-114.45319, 42.5486, 0],
					[-114.4517, 42.5486, 0],
					[-114.45138, 42.54859, 0],
					[-114.45039, 42.54859, 0],
					[-114.44999, 42.54858, 0],
					[-114.44834, 42.54857, 0],
					[-114.44812, 42.54858, 0],
					[-114.44754, 42.54856, 0],
					[-114.44627, 42.54856, 0],
					[-114.44502, 42.54854, 0],
					[-114.44207, 42.54853, 0],
					[-114.44171, 42.54852, 0],
					[-114.44154, 42.54847, 0],
					[-114.44102, 42.54846, 0],
					[-114.44016, 42.54846, 0],
					[-114.43825, 42.54843, 0],
					[-114.43775, 42.54843, 0],
					[-114.43752, 42.54842, 0],
					[-114.43599, 42.5484, 0],
					[-114.43574, 42.54839, 0],
					[-114.43518, 42.54839, 0],
					[-114.43501, 42.54844, 0],
					[-114.43389, 42.54844, 0],
					[-114.42176, 42.5483, 0],
					[-114.40483, 42.54832, 0],
					[-114.40479, 42.5483, 0],
					[-114.40474, 42.54828, 0],
					[-114.40468, 42.54827, 0],
					[-114.39537, 42.54825, 0],
					[-114.39443, 42.54823, 0],
					[-114.36585, 42.5481, 0],
					[-114.36555, 42.54805, 0],
					[-114.36527, 42.54802, 0],
					[-114.36521, 42.548, 0],
					[-114.36517, 42.54798, 0],
					[-114.36512, 42.54796, 0],
					[-114.36508, 42.54793, 0],
					[-114.36505, 42.5479, 0],
					[-114.36501, 42.54785, 0],
					[-114.36497, 42.54781, 0],
					[-114.36483, 42.54761, 0],
					[-114.36475, 42.54531, 0],
					[-114.3648, 42.54523, 0],
					[-114.3648, 42.54522, 0],
					[-114.36481, 42.54521, 0],
					[-114.36481, 42.54518, 0],
					[-114.36482, 42.5451, 0],
					[-114.36483, 42.54449, 0],
					[-114.36486, 42.544, 0],
					[-114.36488, 42.54346, 0],
					[-114.36487, 42.54274, 0],
					[-114.36486, 42.54265, 0],
					[-114.36486, 42.54259, 0],
					[-114.36483, 42.54252, 0],
					[-114.36482, 42.542, 0],
					[-114.36481, 42.53862, 0],
					[-114.3648, 42.5386, 0],
					[-114.3648, 42.53856, 0],
					[-114.36479, 42.5385, 0],
					[-114.36472, 42.5384, 0],
					[-114.3647, 42.53838, 0],
					[-114.36468, 42.53835, 0],
					[-114.36461, 42.53828, 0],
					[-114.36459, 42.53827, 0],
					[-114.36451, 42.53822, 0],
					[-114.36441, 42.53817, 0],
					[-114.35965, 42.53609, 0],
					[-114.3595, 42.53602, 0],
					[-114.35735, 42.53508, 0],
					[-114.35731, 42.53507, 0],
					[-114.35695, 42.53493, 0],
					[-114.35354, 42.53375, 0],
					[-114.35328, 42.53368, 0],
					[-114.35326, 42.53368, 0],
					[-114.35322, 42.53367, 0],
					[-114.35316, 42.53365, 0],
					[-114.35313, 42.53365, 0],
					[-114.35299, 42.53363, 0],
					[-114.3528, 42.53362, 0],
					[-114.35274, 42.53362, 0],
					[-114.35269, 42.53361, 0],
					[-114.32069, 42.53342, 0],
					[-114.32022, 42.53341, 0],
					[-114.30103, 42.5333, 0],
				],
			},
			id: "6e8b2692-d501-4083-ac53-34b18a454d03",
		},
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Marker",
		// 		name: "Bliss, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.94958, 42.92422, 0],
		// 	},
		// 	id: "aa2998c9-0f2b-4073-92f5-72154e387996",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Hagerman, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.8985647, 42.8121235, 0],
		// 	},
		// 	id: "ddc94f82-0426-49d0-a437-59f8501a5c41",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Buhl, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.7595804, 42.5990761, 0],
		// 	},
		// 	id: "8eafe9c0-1fba-47a4-8e74-88790fa3fcd1",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Marker",
		// 		name: "Hansen, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.30103, 42.5333, 0],
		// 	},
		// 	id: "228b1f65-7f59-4e3f-a164-a91cb3425e67",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Twin Falls, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.460191, 42.570529],
		// 	},
		// 	id: "baabd005-5956-48f8-a0c5-b3c0173f14cf",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Kimberly, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.364798, 42.533666],
		// 	},
		// 	id: "16b71755-0eb2-49a2-9e54-146bfe3274bb",
		// },
	],
};

export default ThousandSprings;
