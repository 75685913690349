import { useSelector } from 'react-redux';
import React from "react";
import store from '../../store/store';
import { updatePopups } from '../../store/actions/popupsActions';
import { IconAccountCircle } from "../../images/icons";

const AccountMenu = (props) => {
  const userName = useSelector(state => state.user.userName);

  const toggleLogin = () => {
    store.dispatch(updatePopups({ display: true, type: 'login' }));
  }

  return (
    <div className="accountMenu">
      {userName ? (
        <a onClick={props.toggleAccount} className="nav-link">
          <span>My Account</span>
          <IconAccountCircle />
        </a>
      ) : (
        <a onClick={toggleLogin} className="nav-link">
          <span>Sign In</span>
          <IconAccountCircle />
        </a>
      )}
    </div>
  );
}

export default AccountMenu;