//REDUX-RTK
import { useSelector } from "react-redux";

//REACT
import React from "react";
import { MapContainer, TileLayer, ZoomControl, Marker, Popup, ScaleControl, Polyline, GeoJSON } from 'react-leaflet';
import ReactDragListView from 'react-drag-listview';
import ExportPDF from "./ExportPDF";

import * as htmlToImage from 'html-to-image';
//LOCAL DATA
import {
	IconEdit,
	IconLocationMarker,
	IconBookmark,
	IconMinus,
	IconCheck,
	IconShare,
	IconExport,
	IconRoute,
    IconPencil
} from "../../images/icons";
import RecommendedActivities from "../Recommended/RecommendedActivities";
import AdventureCard from "../Cards/AdventureCard";
import { removeFromCart, sortCart } from "../../store/actions/cartActions";
import { useEffect, useRef, useState } from "react";
import store from "../../store/store";
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { updateDirections, updateRoutes, updateStartingPoint, updateSummary, updateFinishingPoint, updateStartAddress, updateFinishAddress, updateCustomEndPoint } from "../../store/actions/routesActions";
import { updatePopups } from "../../store/actions/popupsActions";
import Alert from "../Alert/Alert";
import RecommendedTravelTips from "../Recommended/RecommendedTravelTips";
import axios from "axios";
var polyline = require('@mapbox/polyline');
import idahoJson from "../../data/Idaho";
import { setLastItem } from "../../store/actions/lastItemActions";
import { getMarkerIcon } from "../Markers/markers";
import {
    sToHMTimeConvert,
    metersToMiles,
    getMapBoxUrl
} from "./utils";
import TravelTipCart from "./TravelTipCart";

const SaveItinerary = ({currentView}) => {
    const northEastBounds = L.latLng(54.100971058261564, -122.50336811441568);
    const southWestBounds = L.latLng(38.3800071147504, -106.61713826558358);
    const [mapBounds, setMapBounds] = useState(L.latLngBounds(southWestBounds, northEastBounds));
    const [mapZoom, setMapZoom] = useState(5.5);
    const [mapCenter, setMapCenter] = useState([45.494576, -114.142430]);
    const cart = useSelector((state) => state.cart.cartItems);
    const routes = useSelector((state) => state.routes.route);
    const summary = useSelector((state) => state.routes.summary);
    const directions = useSelector((state) => state.routes.directions);
    const userName = useSelector((state) => state.user.userName);
    const startingPoint = useSelector((state) => state.routes.startingPoint);
    const finishingPoint = useSelector((state) => state.routes.finishingPoint);
    const startAddress = useSelector((state) => state.routes.startAddress);
    const finishAddress = useSelector((state) => state.routes.finishAddress);
    const customEndPoint = useSelector((state) => state.routes.customEndPoint);
    const [allPoints, setAllPoints] = useState([]);
    
    const itinerary = useSelector((state) => state.itinerary);
    const [editItems, setEditItems] = useState(false);

    const [reorderingRoute, setReorderingRoute] = useState(false);
    const [optimizedRoute, setOptimizedRoute] = useState(false);
    const [editStarting, setEditStarting] = useState(false);
    const [editEnding, setEditEnding] = useState(false);

    const [loading, setLoading] = useState(true);
    const [mapImage, setMapImage] = useState(null);
    const map = useRef();

    useEffect(() => {
        if (document.getElementById('tidio-chat')) {
            document.getElementById('tidio-chat-code').remove();
            document.getElementById('tidio-chat').remove();
        }
    }, []);

    useEffect(() => {
        // set the map image
        if(cart.length > 0){
            setTimeout(() => {
                htmlToImage.toJpeg(document.getElementById('itin-map'), { 
                    quality: 1, 
                    pixelRatio: 2 
                }).then(function (dataUrl) {
                    setMapImage(dataUrl);
                });
            }, 500);
        }
    }, [loading, startingPoint, finishingPoint]);
            
    useEffect(() => {
        if (editItems === false && !reorderingRoute) {
            reorderWaypoints();
        }
    }, [startingPoint, finishingPoint, editItems]);

    useEffect(()=>{
        setLoading(true);
        if (cart.length > 0 && !customEndPoint) {
            setNewEndingPoint(false);
        }
        //when we run "reorder by most efficient" we want to block this from running multiple times until the first call is complete
        if (cart.length > 0 && !reorderingRoute) {
            reorderWaypoints();
        }
    }, [cart]);

    const setNewStartingPoint = (place) => {
        store.dispatch(updateStartAddress(place.formatted_address));
        store.dispatch(updateStartingPoint([
            place.geometry.location.lat().toString(), 
            place.geometry.location.lng().toString()
        ]));
    }

    const setNewEndingPoint = (custom, place) => {
        store.dispatch(updateCustomEndPoint(custom));
        if (custom) {
            store.dispatch(updateFinishAddress(place.formatted_address));
            store.dispatch(updateFinishingPoint([
                place.geometry.location.lat().toString(), 
                place.geometry.location.lng().toString()
            ]));
        } else {
            store.dispatch(updateFinishAddress(cart.at(-1).title));
            store.dispatch(updateFinishingPoint([
                cart.at(-1).lat.toString(), 
                cart.at(-1).lng.toString()
            ]));
        }
    }

    const toggleEditor = () => {
        setEditItems(!editItems);
    }

    const toggleStartingPoint = () => {
        setEditStarting(!editStarting);
    }

    const toggleEndingPoint = () => {
        setEditEnding(!editEnding);
    }

    const toggleCustomEndPoint = () => {
        setNewEndingPoint(false);
        setEditEnding(false);
    }

    const removeItemFromCart = (item) => {
        store.dispatch(removeFromCart(item));
    }

    const toggleLoginPopup = () => {
        store.dispatch(updatePopups({ display: true, type: 'login-save' }));
    }

    const toggleSavePopup = () => {
        store.dispatch(updatePopups({ display: true, type: 'save-itinerary' }));
    }

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            //setEditItems(true);
            const data = [...cart];
            const item = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, item);
            store.dispatch(sortCart(data));
        },
        ignoreSelector: '.non-draggable',
        nodeSelector: 'li'
    };

    const copyToClipboard = (textToCopy) => {

        store.dispatch(setLastItem({
            message: 'urlCopied',
            icon: true,
            timer: 8000,
            type: 'success'
        }));

        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }

    }

    const reorderWaypoints = async (optimizedCheck = false) => {

        setReorderingRoute(true);
        setOptimizedRoute(optimizedCheck);
        
        let startPoint = Number(startingPoint[1].toString()) + "," + Number(startingPoint[0].toString()) + ";";
        
        let cartItems = cart;
        let allPointsArray = [];
        cartItems.map((item, i) => {
            let latLng = Number(item.lng) + "," + Number(item.lat);
            allPointsArray.push(latLng);
        });

        if (customEndPoint) {
            allPointsArray.push(Number(finishingPoint[1]) + "," + Number(finishingPoint[0]));
        }

        setAllPoints(allPointsArray);
    
        let mapBoxURL = getMapBoxUrl(optimizedCheck, startPoint, allPointsArray.join(';'));

        await axios.get(mapBoxURL)
            .then(response => {
                let route = optimizedCheck === true ? response.data.trips[0] : response.data.routes[0];
                let summary = {
                    "totalDistance": route.distance,
                    "totalTime": route.duration,
                    "totalAscend": 0,
                    "totalDescend": 0
                }
                //console.log(summary);
                let waypointCoords = [];
                if (optimizedCheck === true) {
                    let waypoints = response.data.waypoints;
                    let newRouteOrder = [];
                    waypoints.map((waypoint, i) => {
                        newRouteOrder.push(cartItems[waypoint.waypoint_index - 1]);
                        waypointCoords.push(L.marker(waypoint.location.reverse()));
                    });
                    newRouteOrder = newRouteOrder.filter(function (element) {
                        return element !== undefined;
                    });
                    store.dispatch(sortCart(newRouteOrder));
                }
                let instructions = [];
                route.legs.map((leg, i) => {
                    leg.steps.map((step, j) => {
                        let instruction = {
                            type: step.maneuver.type,
                            modifier: step.maneuver.modifier,
                            text: step.maneuver.instruction,
                            distance: metersToMiles(step.distance),
                            time: sToHMTimeConvert(step.duration),
                            geometry: step.geometry,
                        }
                        instructions.push(instruction);
                    })
                });
                let latLngs = polyline.decode(route.geometry);
                store.dispatch(updateRoutes(latLngs));
                store.dispatch(updateSummary(summary));
                store.dispatch(updateDirections(instructions));
                map.current.setView(mapCenter, mapZoom);

                // let newBounds = L.featureGroup(waypointCoords).getBounds();
                // if(newBounds.isValid()){
                //     map.current.fitBounds(newBounds);
                // }
            });

            // set the loading state to false
            setLoading(false);
            setReorderingRoute(false);
    }

    return (
        <>
            <Alert />
            {currentView === 'itinerary' && (
                <>
                    <div className="itinerary-content">
                        <div className="my-itinerary">
                            <div className="itinerary-cart">
                                <h3>My Itinerary</h3>
                                <div className="cart-wrapper">
                                    <div className="cart-header">
                                        <div className="location">
                                            <p className="location-title"><strong>Starting at:</strong></p>
                                        {editStarting ? 
                                            <div className="starting-point">
                                                <IconLocationMarker width="18" />
                                                <ReactGoogleAutocomplete
                                                    apiKey={'AIzaSyDXoytSn9yB9XEf37ekOEhtjggVE5PaRws'}
                                                    onPlaceSelected={(place) => {
                                                        setNewStartingPoint(place);
                                                    }}
                                                    options={{
                                                        types: ["(cities)"],
                                                        componentRestrictions: { country: "us" },
                                                    }}
                                                    placeholder="Enter new starting city"
                                                />
                                                <a onClick={toggleStartingPoint} title="Save starting point"><IconCheck /></a>
                                            </div> 
                                            :
                                            <p className="location-name">{startAddress} <a onClick={toggleStartingPoint} title="Edit starting point"><IconPencil /></a></p>
                                        }
                                        </div>
                                        <div className="location">
                                            <p className="location-title">
                                                <strong>Ending at:</strong>
                                                <label className="custom-endpoint">                   
                                                    <input type="checkbox" onChange={toggleCustomEndPoint} checked={customEndPoint ? false : true} disabled={customEndPoint ? false : true} />
                                                    Use last stop
                                                </label>
                                            </p>
                                            {editEnding ?
                                            <div className="finishing-point">
                                                <IconLocationMarker width="18" />
                                                <ReactGoogleAutocomplete
                                                    apiKey={'AIzaSyDXoytSn9yB9XEf37ekOEhtjggVE5PaRws'}
                                                    onPlaceSelected={(place) => {
                                                        setNewEndingPoint(true, place);
                                                    }}
                                                    options={{
                                                        types: ["(cities)"],
                                                        componentRestrictions: { country: "us" },
                                                    }}
                                                    placeholder="Enter new ending city"
                                                />
                                                <a onClick={toggleEndingPoint} title="Save ending point"><IconCheck /></a>
                                            </div> 
                                            :
                                            <p className="location-name">{finishAddress} <a onClick={toggleEndingPoint} title="Edit ending point"><IconPencil /></a></p>
                                            }
                                        </div>
                                    </div>
                                    <ReactDragListView {...dragProps}>
                                        <div className="edit-cart">
                                            <p>My Stops</p>
                                            {editItems ?
                                                <a onClick={toggleEditor} title="Edit itinerary"><IconCheck /> <span>Save</span></a>
                                                : <a onClick={toggleEditor} title="Edit itinerary"><IconEdit /> <span>Edit</span></a>
                                            }
                                        </div>
                                        <p className="non-draggable">Drag and drop items to reorder</p>
                                        <ul className="cart-items">
                                            {/* <li className="non-draggable">Starting Point: Boise, Idaho</li> */}
                                            {cart && cart.length > 0 ?
                                                cart.map((item, i) => (
                                                    <li key={`cart-items-${i}`}>
                                                        <div className="cart-item">
                                                            {editItems &&
                                                                <a onClick={() => { removeItemFromCart(item.id) }} className="cart-item-remove">
                                                                    <IconMinus />
                                                                </a>}
                                                            {getMarkerIcon(item, true)}
                                                            {item.title}
                                                        </div>
                                                    </li>
                                                ))
                                                : <p>Your itinerary is empty. Explore the map and add points of interest to start building your itinerary.</p>
                                            }
                                        </ul>
                                    </ReactDragListView>
                                    <a onClick={() => { !optimizedRoute && reorderWaypoints(true) }} className={`sort-waypoints ${optimizedRoute ? 'disabled' : ''}`}>
                                        {optimizedRoute ? 
                                        <>
                                            <IconCheck /><span>Using Most Efficient Route</span>
                                        </>
                                        :
                                        <>
                                            <IconRoute /><span>Reorder by Most Efficient Route</span>
                                        </>
                                        }
                                    </a>
                                </div>
                            </div>
                            <div className="itinerary-map">
                                <h3>{itinerary.itineraryName && itinerary.itineraryName !== '' ? itinerary.itineraryName : 'Itinerary Route'}</h3>

                                {(summary && summary.totalDistance) &&
                                    <div className="details-content">
                                        <p className="distance"><strong>Distance:</strong> {metersToMiles(summary.totalDistance) + ' miles'}</p>
                                        <p className="time"><strong>Est. Travel Time:</strong> {sToHMTimeConvert(summary.totalTime)}</p>
                                        {/* <a className="show-directions"><span>Show Directions</span></a> */}
                                    </div>}
                                <MapContainer ref={map} id="itin-map" className="itin-map" center={mapCenter} maxBoundsViscosity={1.0} zoom={mapZoom} zoomControl={false}>
                                <TileLayer
                attribution="&copy; <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>"
                url="https://api.mapbox.com/styles/v1/visitidaho/clexsrswm000301orvslvh1q2/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidmlzaXRpZGFobyIsImEiOiJjbDlveHd3dDkwZWNyM3dsYjJtemF5b3d6In0.Mz0XqoMjxh3PlAeXFGvkfw"/>
                                    {startingPoint &&
                                        <Marker
                                            position={[
                                                startingPoint[0],
                                                startingPoint[1]
                                            ]}
                                            zIndexOffset={999}
                                            icon={L.divIcon({
                                                html: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                viewBox="0 0 104 104" style="enable-background:new 0 0 104 104; background:white;" xml:space="preserve">
                                                        <path id="Icon_material-location-on" style="fill:#B7562D" d="M52,21.3c-11.8,0-21.5,9.6-21.5,21.4c0,0,0,0,0,0C30.5,58.9,52,82.7,52,82.7
                                                            s21.5-23.8,21.5-39.9C73.5,31,63.9,21.3,52,21.3C52,21.3,52,21.3,52,21.3z M52,50.5c-4.2,0-7.7-3.4-7.7-7.7s3.4-7.7,7.7-7.7
                                                            c4.2,0,7.7,3.4,7.7,7.7l0,0C59.7,47,56.2,50.5,52,50.5z"/>
                                                        </svg>`,
                                                className: "",
                                                iconSize: [40, 40],
                                                iconAnchor: [20, 40],
                                            })}
                                        />}
                                    {customEndPoint && finishingPoint &&
                                        <Marker
                                            position={[
                                                finishingPoint[0],
                                                finishingPoint[1]
                                            ]}
                                            zIndexOffset={999}
                                            icon={L.divIcon({
                                                html: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                viewBox="0 0 104 104" style="enable-background:new 0 0 104 104; background:white;" xml:space="preserve">
                                                        <path id="Icon_material-location-on" style="fill:#B7562D" d="M52,21.3c-11.8,0-21.5,9.6-21.5,21.4c0,0,0,0,0,0C30.5,58.9,52,82.7,52,82.7
                                                            s21.5-23.8,21.5-39.9C73.5,31,63.9,21.3,52,21.3C52,21.3,52,21.3,52,21.3z M52,50.5c-4.2,0-7.7-3.4-7.7-7.7s3.4-7.7,7.7-7.7
                                                            c4.2,0,7.7,3.4,7.7,7.7l0,0C59.7,47,56.2,50.5,52,50.5z"/>
                                                        </svg>`,
                                                className: "",
                                                iconSize: [40, 40],
                                                iconAnchor: [20, 40],
                                            })}
                                        />}
                                    {cart && cart.length > 0 &&
                                        cart.map((item, index) => (
                                            <Marker
                                                key={`preview-marker-${index}`}
                                                position={[
                                                    item.lat,
                                                    item.lng
                                                ]}
                                                data={item}
                                                icon={getMarkerIcon(item, false, true)}
                                                zIndexOffset={item.is_selected ? 999 : 1}
                                            >
                                                <Popup>
                                                    <AdventureCard item={item} />
                                                </Popup>
                                            </Marker>
                                        ))
                                    }
                                    {/* {(!editItems && cart.length > 0) && <RoutingMachine map={map} ref={rMachine} points={cart} />} */}
                                    {(routes && routes.length > 0 && cart.length > 0) && <Polyline color="#D36841" positions={routes} />}

                                    {/** IDAHO OUTLINE */}
                                    <GeoJSON className={"stateGeo"} data={idahoJson} style={{ fillColor: 'transparent' }} />
                                    <ScaleControl position="bottomright" />
                                    <ZoomControl position="bottomright" />
                                </MapContainer>
                            </div>
                            <div className="itinerary-save mobile-only">
                                {(routes && routes.length > 0 && cart.length > 0 && loading === false) ? <ExportPDF startingPoint={startingPoint} allPoints={allPoints} mapImage={mapImage} cart={cart} routes={routes} startAddress={startAddress} finishAddress={finishAddress} /> : <a style={{ cursor: 'not-allowed' }}><IconExport width="18" /> <span>Loading...</span></a>}
                                <a onClick={userName ? toggleSavePopup.bind(this) : toggleLoginPopup.bind(this)} title="Save for later"><IconBookmark width="18" /> <span>Save for Later</span></a>
                                {itinerary.itineraryId && itinerary.itineraryId.length > 0 && <a onClick={() => { copyToClipboard(window.location.origin + '/itinerary/' + itinerary.itineraryId) }} title="Share URL"><IconShare width="18" /> <span>Share URL</span></a>}
                            </div>
                        </div>
                        <RecommendedActivities maxLength="3" showTWC="true" />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="itinerary-save desktop-only">
                        {(routes && routes.length > 0 && cart.length > 0 && loading === false) ? <ExportPDF startingPoint={startingPoint} allPoints={allPoints} mapImage={mapImage} cart={cart} routes={routes} startAddress={startAddress} finishAddress={finishAddress} /> : <a style={{ cursor: 'not-allowed' }}><IconExport width="18" /> <span>Loading...</span></a>}
                        <a onClick={userName ? toggleSavePopup.bind(this) : toggleLoginPopup.bind(this)} title="Save for later"><IconBookmark width="18" /> <span>Save for Later</span></a>
                        {itinerary.itineraryId && itinerary.itineraryId.length > 0 && <a onClick={() => { copyToClipboard(window.location.origin + '/itinerary/' + itinerary.itineraryId) }} title="Share URL"><IconShare width="18" /> <span>Share URL</span></a>}
                    </div>
                </>
            )}
            {currentView === 'traveltips' && (
                <div className="itinerary-content">
                    <TravelTipCart />
                    <RecommendedTravelTips />
                </div>
            )}
        </>
    );
}

export default SaveItinerary;
