const WildlifeCanyon = {
    type: "FeatureCollection",
    features: [{
        type: "Feature",
        properties: {
            name: "Wildlife Canyon Scenic Byway",
            "description": "Following the South Fork of the Payette River, the Wildlife Canyon Scenic Byway offers plenty to do for outdoor adventurers-from whitewater rafting and wildlife viewing, to fishing and hiking. If you're not ready for the road trip to end, you're in luck! This route joins both the Ponderosa Pine Scenic Byway and the Payette River Scenic Byway with an abundance of ways to explore.",
            "link": "https://visitidaho.org/things-to-do/road-trips/wildlife-canyon-scenic-byway/",
            "route": [
                "Banks", 
                "Lowman", 
                "Crouch",
                "Garden Valley"
            ],
            "shape": "Line"
        },
        geometry: {
            type: "LineString",
            coordinates: [
                [-116.11451, 44.08456, 0],
                [-116.11388, 44.08419, 0],
                [-116.11358, 44.08405, 0],
                [-116.11337, 44.08397, 0],
                [-116.11307, 44.08388, 0],
                [-116.11299, 44.08385, 0],
                [-116.11281, 44.08381, 0],
                [-116.11252, 44.08376, 0],
                [-116.11213, 44.08371, 0],
                [-116.11178, 44.08368, 0],
                [-116.11002, 44.08359, 0],
                [-116.10952, 44.08355, 0],
                [-116.10829, 44.08349, 0],
                [-116.10773, 44.08349, 0],
                [-116.10678, 44.08354, 0],
                [-116.10672, 44.08355, 0],
                [-116.10594, 44.0836, 0],
                [-116.10528, 44.08367, 0],
                [-116.10473, 44.08378, 0],
                [-116.10428, 44.08392, 0],
                [-116.10376, 44.08417, 0],
                [-116.10249, 44.08483, 0],
                [-116.10146, 44.08545, 0],
                [-116.09952, 44.08667, 0],
                [-116.09929, 44.08684, 0],
                [-116.0991, 44.08702, 0],
                [-116.09886, 44.08733, 0],
                [-116.09874, 44.08751, 0],
                [-116.09854, 44.08778, 0],
                [-116.09771, 44.08901, 0],
                [-116.09724, 44.08966, 0],
                [-116.09712, 44.08981, 0],
                [-116.09697, 44.09002, 0],
                [-116.09673, 44.09032, 0],
                [-116.09592, 44.09118, 0],
                [-116.09592, 44.09119, 0],
                [-116.0955, 44.09163, 0],
                [-116.0951, 44.09213, 0],
                [-116.09457, 44.09289, 0],
                [-116.09456, 44.0929, 0],
                [-116.09445, 44.09309, 0],
                [-116.09438, 44.09324, 0],
                [-116.09435, 44.09329, 0],
                [-116.09431, 44.09338, 0],
                [-116.09415, 44.09386, 0],
                [-116.0941, 44.09398, 0],
                [-116.09395, 44.09427, 0],
                [-116.09388, 44.09439, 0],
                [-116.09368, 44.09466, 0],
                [-116.09355, 44.09486, 0],
                [-116.09307, 44.09553, 0],
                [-116.09292, 44.0957, 0],
                [-116.09279, 44.09583, 0],
                [-116.09258, 44.09596, 0],
                [-116.09245, 44.09603, 0],
                [-116.09218, 44.09615, 0],
                [-116.09177, 44.0963, 0],
                [-116.09158, 44.09636, 0],
                [-116.09141, 44.09643, 0],
                [-116.0912, 44.09653, 0],
                [-116.09087, 44.09672, 0],
                [-116.09056, 44.09694, 0],
                [-116.08956, 44.09774, 0],
                [-116.08912, 44.09803, 0],
                [-116.08523, 44.10026, 0],
                [-116.0852, 44.10027, 0],
                [-116.08455, 44.10066, 0],
                [-116.08414, 44.10095, 0],
                [-116.08391, 44.10114, 0],
                [-116.0836, 44.10137, 0],
                [-116.08343, 44.10148, 0],
                [-116.0833, 44.10154, 0],
                [-116.08321, 44.10159, 0],
                [-116.08304, 44.10164, 0],
                [-116.08294, 44.10168, 0],
                [-116.08286, 44.10169, 0],
                [-116.08262, 44.10174, 0],
                [-116.08255, 44.10174, 0],
                [-116.08246, 44.10175, 0],
                [-116.08216, 44.10175, 0],
                [-116.08182, 44.10171, 0],
                [-116.08168, 44.10168, 0],
                [-116.07847, 44.10078, 0],
                [-116.07809, 44.10065, 0],
                [-116.07786, 44.10056, 0],
                [-116.07771, 44.10049, 0],
                [-116.0777, 44.10049, 0],
                [-116.07756, 44.10042, 0],
                [-116.07733, 44.10029, 0],
                [-116.07698, 44.10005, 0],
                [-116.07645, 44.09962, 0],
                [-116.07436, 44.09783, 0],
                [-116.07386, 44.09736, 0],
                [-116.07296, 44.09656, 0],
                [-116.07289, 44.09649, 0],
                [-116.0725, 44.09621, 0],
                [-116.07164, 44.09564, 0],
                [-116.07143, 44.09552, 0],
                [-116.07113, 44.09537, 0],
                [-116.07092, 44.09528, 0],
                [-116.0703, 44.09505, 0],
                [-116.07022, 44.09503, 0],
                [-116.06989, 44.09491, 0],
                [-116.0695, 44.09475, 0],
                [-116.0694, 44.0947, 0],
                [-116.06927, 44.09465, 0],
                [-116.06903, 44.09453, 0],
                [-116.06726, 44.09349, 0],
                [-116.06659, 44.09312, 0],
                [-116.06638, 44.09302, 0],
                [-116.06634, 44.09301, 0],
                [-116.0653, 44.09253, 0],
                [-116.0649, 44.09231, 0],
                [-116.06458, 44.09211, 0],
                [-116.06455, 44.09208, 0],
                [-116.06445, 44.09202, 0],
                [-116.0642, 44.09183, 0],
                [-116.06361, 44.0913, 0],
                [-116.06357, 44.09127, 0],
                [-116.06354, 44.09124, 0],
                [-116.06321, 44.09103, 0],
                [-116.06235, 44.09064, 0],
                [-116.0623, 44.09061, 0],
                [-116.06104, 44.09005, 0],
                [-116.06079, 44.08995, 0],
                [-116.06041, 44.08982, 0],
                [-116.05911, 44.08947, 0],
                [-116.05872, 44.08939, 0],
                [-116.05839, 44.08935, 0],
                [-116.0583, 44.08935, 0],
                [-116.058, 44.08937, 0],
                [-116.05705, 44.08956, 0],
                [-116.05696, 44.08957, 0],
                [-116.05681, 44.08961, 0],
                [-116.05554, 44.08989, 0],
                [-116.05493, 44.09009, 0],
                [-116.0535, 44.09064, 0],
                [-116.05276, 44.09086, 0],
                [-116.05244, 44.09094, 0],
                [-116.05242, 44.09094, 0],
                [-116.05218, 44.091, 0],
                [-116.05196, 44.09104, 0],
                [-116.05195, 44.09104, 0],
                [-116.05176, 44.09108, 0],
                [-116.04931, 44.09145, 0],
                [-116.04838, 44.09162, 0],
                [-116.04703, 44.09196, 0],
                [-116.04602, 44.09217, 0],
                [-116.04488, 44.09229, 0],
                [-116.04437, 44.09239, 0],
                [-116.04392, 44.09252, 0],
                [-116.04352, 44.09266, 0],
                [-116.04324, 44.09273, 0],
                [-116.04304, 44.09277, 0],
                [-116.04303, 44.09277, 0],
                [-116.0429, 44.0928, 0],
                [-116.04269, 44.09283, 0],
                [-116.04239, 44.09285, 0],
                [-116.04216, 44.09285, 0],
                [-116.04191, 44.09283, 0],
                [-116.04082, 44.09268, 0],
                [-116.04032, 44.09266, 0],
                [-116.03806, 44.09266, 0],
                [-116.03789, 44.09265, 0],
                [-116.03687, 44.09265, 0],
                [-116.03656, 44.09266, 0],
                [-116.03637, 44.09268, 0],
                [-116.03631, 44.09268, 0],
                [-116.03626, 44.09269, 0],
                [-116.03616, 44.0927, 0],
                [-116.03577, 44.09278, 0],
                [-116.03537, 44.09284, 0],
                [-116.03526, 44.09284, 0],
                [-116.03514, 44.09285, 0],
                [-116.03504, 44.09285, 0],
                [-116.03492, 44.09284, 0],
                [-116.03484, 44.09284, 0],
                [-116.0348, 44.09283, 0],
                [-116.03466, 44.09282, 0],
                [-116.03421, 44.09275, 0],
                [-116.03386, 44.09268, 0],
                [-116.03313, 44.09256, 0],
                [-116.03306, 44.09256, 0],
                [-116.03284, 44.09253, 0],
                [-116.03273, 44.09253, 0],
                [-116.03263, 44.09252, 0],
                [-116.03232, 44.09252, 0],
                [-116.03215, 44.09253, 0],
                [-116.03198, 44.09253, 0],
                [-116.03152, 44.09256, 0],
                [-116.03139, 44.09256, 0],
                [-116.03127, 44.09257, 0],
                [-116.03094, 44.09257, 0],
                [-116.03088, 44.09256, 0],
                [-116.03074, 44.09256, 0],
                [-116.02895, 44.0924, 0],
                [-116.02846, 44.09231, 0],
                [-116.02831, 44.09227, 0],
                [-116.02813, 44.09221, 0],
                [-116.02785, 44.09209, 0],
                [-116.02753, 44.09192, 0],
                [-116.02728, 44.09172, 0],
                [-116.0271, 44.09154, 0],
                [-116.02687, 44.09125, 0],
                [-116.02682, 44.09116, 0],
                [-116.02674, 44.09105, 0],
                [-116.02653, 44.09067, 0],
                [-116.02641, 44.0905, 0],
                [-116.02621, 44.09028, 0],
                [-116.02609, 44.09017, 0],
                [-116.02592, 44.09005, 0],
                [-116.02558, 44.08985, 0],
                [-116.02524, 44.08972, 0],
                [-116.02484, 44.08962, 0],
                [-116.02457, 44.08958, 0],
                [-116.02429, 44.08956, 0],
                [-116.02414, 44.08956, 0],
                [-116.02402, 44.08957, 0],
                [-116.02393, 44.08957, 0],
                [-116.02353, 44.08963, 0],
                [-116.02311, 44.08974, 0],
                [-116.0228, 44.08987, 0],
                [-116.02257, 44.08999, 0],
                [-116.02064, 44.09118, 0],
                [-116.02048, 44.09127, 0],
                [-116.02023, 44.09139, 0],
                [-116.02008, 44.09145, 0],
                [-116.01966, 44.09158, 0],
                [-116.01786, 44.09188, 0],
                [-116.01782, 44.09188, 0],
                [-116.01772, 44.0919, 0],
                [-116.01752, 44.09192, 0],
                [-116.01707, 44.09192, 0],
                [-116.01689, 44.09189, 0],
                [-116.01685, 44.09189, 0],
                [-116.01683, 44.09188, 0],
                [-116.01647, 44.09181, 0],
                [-116.01635, 44.09178, 0],
                [-116.01634, 44.09177, 0],
                [-116.01582, 44.09167, 0],
                [-116.01562, 44.09164, 0],
                [-116.01556, 44.09164, 0],
                [-116.01546, 44.09163, 0],
                [-116.01528, 44.09163, 0],
                [-116.01507, 44.09165, 0],
                [-116.01486, 44.09169, 0],
                [-116.01461, 44.09176, 0],
                [-116.01432, 44.09189, 0],
                [-116.01388, 44.09213, 0],
                [-116.01062, 44.09405, 0],
                [-116.01054, 44.09409, 0],
                [-116.00878, 44.09513, 0],
                [-116.00829, 44.09547, 0],
                [-116.00809, 44.09563, 0],
                [-116.00788, 44.09578, 0],
                [-116.00735, 44.0962, 0],
                [-116.00708, 44.09643, 0],
                [-116.00695, 44.09653, 0],
                [-116.0067, 44.09675, 0],
                [-116.00655, 44.09691, 0],
                [-116.0063, 44.09723, 0],
                [-116.0062, 44.09741, 0],
                [-116.00607, 44.09769, 0],
                [-116.00604, 44.09779, 0],
                [-116.00603, 44.09784, 0],
                [-116.006, 44.09792, 0],
                [-116.00595, 44.09819, 0],
                [-116.00593, 44.09852, 0],
                [-116.00595, 44.09873, 0],
                [-116.00602, 44.09908, 0],
                [-116.00603, 44.09909, 0],
                [-116.0061, 44.09932, 0],
                [-116.00622, 44.09957, 0],
                [-116.00654, 44.10036, 0],
                [-116.00665, 44.10076, 0],
                [-116.00667, 44.10105, 0],
                [-116.00666, 44.10127, 0],
                [-116.00664, 44.10142, 0],
                [-116.00661, 44.10153, 0],
                [-116.0066, 44.10161, 0],
                [-116.00658, 44.10168, 0],
                [-116.00645, 44.10199, 0],
                [-116.00627, 44.10229, 0],
                [-116.00616, 44.10243, 0],
                [-116.00596, 44.10264, 0],
                [-116.00585, 44.10274, 0],
                [-116.00565, 44.1029, 0],
                [-116.00536, 44.1031, 0],
                [-116.00523, 44.10317, 0],
                [-116.00522, 44.10318, 0],
                [-116.00499, 44.10329, 0],
                [-116.00488, 44.10333, 0],
                [-116.00477, 44.10338, 0],
                [-116.00457, 44.10345, 0],
                [-116.0045, 44.10348, 0],
                [-116.00434, 44.10352, 0],
                [-116.00434, 44.10353, 0],
                [-116.00412, 44.10359, 0],
                [-116.00411, 44.10359, 0],
                [-116.00002, 44.10446, 0],
                [-115.99999, 44.10446, 0],
                [-115.99707, 44.10509, 0],
                [-115.99704, 44.10509, 0],
                [-115.99695, 44.10511, 0],
                [-115.99666, 44.10521, 0],
                [-115.99658, 44.10523, 0],
                [-115.99636, 44.10531, 0],
                [-115.99623, 44.10537, 0],
                [-115.99612, 44.10541, 0],
                [-115.99599, 44.10547, 0],
                [-115.99593, 44.10551, 0],
                [-115.99576, 44.10559, 0],
                [-115.99564, 44.10566, 0],
                [-115.99551, 44.10575, 0],
                [-115.9954, 44.10581, 0],
                [-115.99431, 44.10667, 0],
                [-115.99418, 44.10675, 0],
                [-115.99413, 44.10679, 0],
                [-115.99386, 44.10696, 0],
                [-115.99358, 44.1071, 0],
                [-115.99321, 44.10726, 0],
                [-115.99207, 44.10768, 0],
                [-115.99196, 44.10771, 0],
                [-115.98728, 44.10938, 0],
                [-115.98683, 44.1095, 0],
                [-115.98625, 44.10959, 0],
                [-115.98587, 44.10963, 0],
                [-115.98573, 44.10963, 0],
                [-115.98558, 44.10964, 0],
                [-115.98547, 44.10964, 0],
                [-115.98495, 44.10961, 0],
                [-115.98457, 44.10955, 0],
                [-115.98447, 44.10954, 0],
                [-115.98428, 44.1095, 0],
                [-115.98402, 44.10943, 0],
                [-115.98396, 44.10942, 0],
                [-115.98376, 44.10936, 0],
                [-115.98325, 44.10918, 0],
                [-115.9801, 44.10789, 0],
                [-115.97947, 44.1076, 0],
                [-115.97918, 44.10744, 0],
                [-115.97915, 44.10743, 0],
                [-115.97838, 44.10699, 0],
                [-115.97784, 44.10663, 0],
                [-115.97661, 44.10568, 0],
                [-115.97519, 44.10466, 0],
                [-115.97435, 44.10409, 0],
                [-115.97283, 44.10314, 0],
                [-115.97163, 44.10245, 0],
                [-115.97027, 44.10173, 0],
                [-115.96853, 44.10086, 0],
                [-115.96798, 44.10056, 0],
                [-115.9669, 44.09989, 0],
                [-115.95775, 44.09396, 0],
                [-115.95443, 44.09195, 0],
                [-115.95398, 44.09165, 0],
                [-115.95372, 44.09143, 0],
                [-115.95339, 44.09109, 0],
                [-115.95314, 44.09074, 0],
                [-115.94925, 44.08455, 0],
                [-115.94922, 44.08451, 0],
                [-115.94871, 44.08371, 0],
                [-115.94837, 44.08312, 0],
                [-115.94816, 44.08263, 0],
                [-115.94805, 44.08234, 0],
                [-115.94796, 44.08205, 0],
                [-115.94782, 44.08149, 0],
                [-115.94775, 44.08108, 0],
                [-115.94771, 44.08048, 0],
                [-115.94776, 44.07885, 0],
                [-115.94775, 44.07848, 0],
                [-115.94772, 44.07815, 0],
                [-115.94764, 44.07778, 0],
                [-115.94752, 44.07736, 0],
                [-115.94726, 44.0768, 0],
                [-115.94713, 44.07657, 0],
                [-115.94695, 44.0763, 0],
                [-115.94676, 44.07605, 0],
                [-115.94622, 44.07548, 0],
                [-115.946, 44.07528, 0],
                [-115.94587, 44.07515, 0],
                [-115.94213, 44.07168, 0],
                [-115.94184, 44.07143, 0],
                [-115.94147, 44.07117, 0],
                [-115.94102, 44.0709, 0],
                [-115.94058, 44.0707, 0],
                [-115.94055, 44.07068, 0],
                [-115.93988, 44.07043, 0],
                [-115.92504, 44.06523, 0],
                [-115.9244, 44.06496, 0],
                [-115.92439, 44.06495, 0],
                [-115.92389, 44.06471, 0],
                [-115.92358, 44.06454, 0],
                [-115.92357, 44.06454, 0],
                [-115.92319, 44.06433, 0],
                [-115.91936, 44.06201, 0],
                [-115.91897, 44.0618, 0],
                [-115.9185, 44.06162, 0],
                [-115.91848, 44.06162, 0],
                [-115.91813, 44.06151, 0],
                [-115.91779, 44.06143, 0],
                [-115.91611, 44.06114, 0],
                [-115.91607, 44.06114, 0],
                [-115.91561, 44.06106, 0],
                [-115.91558, 44.06106, 0],
                [-115.91499, 44.06094, 0],
                [-115.91444, 44.06078, 0],
                [-115.91417, 44.06066, 0],
                [-115.9139, 44.06052, 0],
                [-115.9137, 44.06037, 0],
                [-115.9135, 44.06017, 0],
                [-115.91339, 44.06002, 0],
                [-115.91329, 44.05981, 0],
                [-115.91325, 44.05964, 0],
                [-115.9132, 44.05919, 0],
                [-115.91319, 44.05864, 0],
                [-115.91315, 44.05788, 0],
                [-115.91314, 44.05688, 0],
                [-115.91312, 44.05659, 0],
                [-115.91309, 44.05637, 0],
                [-115.91304, 44.05612, 0],
                [-115.91299, 44.05598, 0],
                [-115.91292, 44.05585, 0],
                [-115.91283, 44.05574, 0],
                [-115.91279, 44.05568, 0],
                [-115.91263, 44.05552, 0],
                [-115.91247, 44.05539, 0],
                [-115.91211, 44.05518, 0],
                [-115.91178, 44.05505, 0],
                [-115.91127, 44.05493, 0],
                [-115.91086, 44.05486, 0],
                [-115.91073, 44.05481, 0],
                [-115.90954, 44.05458, 0],
                [-115.90867, 44.05444, 0],
                [-115.90837, 44.05442, 0],
                [-115.90808, 44.05442, 0],
                [-115.90752, 44.05447, 0],
                [-115.90746, 44.05448, 0],
                [-115.90739, 44.05451, 0],
                [-115.90736, 44.05451, 0],
                [-115.9061, 44.0547, 0],
                [-115.90554, 44.05475, 0],
                [-115.905, 44.05476, 0],
                [-115.90408, 44.05471, 0],
                [-115.90377, 44.05466, 0],
                [-115.90358, 44.05464, 0],
                [-115.90285, 44.05446, 0],
                [-115.90107, 44.05389, 0],
                [-115.90064, 44.0537, 0],
                [-115.89837, 44.05255, 0],
                [-115.89835, 44.05253, 0],
                [-115.89553, 44.05108, 0],
                [-115.89501, 44.05083, 0],
                [-115.89437, 44.05057, 0],
                [-115.89416, 44.0505, 0],
                [-115.89391, 44.05043, 0],
                [-115.89329, 44.0503, 0],
                [-115.89301, 44.05026, 0],
                [-115.89258, 44.05022, 0],
                [-115.89205, 44.05021, 0],
                [-115.89155, 44.05024, 0],
                [-115.89131, 44.05027, 0],
                [-115.8909, 44.05034, 0],
                [-115.8904, 44.05046, 0],
                [-115.89035, 44.05048, 0],
                [-115.88999, 44.05059, 0],
                [-115.88927, 44.05091, 0],
                [-115.88926, 44.05092, 0],
                [-115.88895, 44.05112, 0],
                [-115.88814, 44.0517, 0],
                [-115.88794, 44.05182, 0],
                [-115.88765, 44.05196, 0],
                [-115.88744, 44.05205, 0],
                [-115.8871, 44.05215, 0],
                [-115.88681, 44.05222, 0],
                [-115.88647, 44.05228, 0],
                [-115.88587, 44.05231, 0],
                [-115.88565, 44.05231, 0],
                [-115.8856, 44.0523, 0],
                [-115.8854, 44.05229, 0],
                [-115.88492, 44.05221, 0],
                [-115.88479, 44.05218, 0],
                [-115.88452, 44.0521, 0],
                [-115.88416, 44.05196, 0],
                [-115.88395, 44.05186, 0],
                [-115.88354, 44.05162, 0],
                [-115.88316, 44.05129, 0],
                [-115.88314, 44.05126, 0],
                [-115.88296, 44.05106, 0],
                [-115.88266, 44.05059, 0],
                [-115.88138, 44.04829, 0],
                [-115.88105, 44.04773, 0],
                [-115.88084, 44.04748, 0],
                [-115.88069, 44.04733, 0],
                [-115.88048, 44.04715, 0],
                [-115.88021, 44.04697, 0],
                [-115.87994, 44.04682, 0],
                [-115.87862, 44.04624, 0],
                [-115.87818, 44.04607, 0],
                [-115.87771, 44.04592, 0],
                [-115.87681, 44.04572, 0],
                [-115.87188, 44.04477, 0],
                [-115.87146, 44.04473, 0],
                [-115.87133, 44.04473, 0],
                [-115.87123, 44.04474, 0],
                [-115.87114, 44.04474, 0],
                [-115.87079, 44.0448, 0],
                [-115.87062, 44.04485, 0],
                [-115.87038, 44.04494, 0],
                [-115.8703, 44.04498, 0],
                [-115.87004, 44.04514, 0],
                [-115.8694, 44.04567, 0],
                [-115.86918, 44.04583, 0],
                [-115.86898, 44.04596, 0],
                [-115.86876, 44.04608, 0],
                [-115.86846, 44.0462, 0],
                [-115.86833, 44.04624, 0],
                [-115.86797, 44.04632, 0],
                [-115.86786, 44.04633, 0],
                [-115.86775, 44.04635, 0],
                [-115.86761, 44.04636, 0],
                [-115.86721, 44.04636, 0],
                [-115.86678, 44.04631, 0],
                [-115.86622, 44.04621, 0],
                [-115.86486, 44.0459, 0],
                [-115.86379, 44.04571, 0],
                [-115.8627, 44.04563, 0],
                [-115.86267, 44.04562, 0],
                [-115.85916, 44.04538, 0],
                [-115.8551, 44.04515, 0],
                [-115.85443, 44.04509, 0],
                [-115.85382, 44.04499, 0],
                [-115.85381, 44.04499, 0],
                [-115.85231, 44.04469, 0],
                [-115.85204, 44.04466, 0],
                [-115.85192, 44.04464, 0],
                [-115.85157, 44.04464, 0],
                [-115.8508, 44.04473, 0],
                [-115.85054, 44.04475, 0],
                [-115.85029, 44.04475, 0],
                [-115.84979, 44.04469, 0],
                [-115.84894, 44.04448, 0],
                [-115.84818, 44.04434, 0],
                [-115.8478, 44.04432, 0],
                [-115.84741, 44.04432, 0],
                [-115.84686, 44.04437, 0],
                [-115.84667, 44.0444, 0],
                [-115.84615, 44.04452, 0],
                [-115.84586, 44.04461, 0],
                [-115.84481, 44.04504, 0],
                [-115.84413, 44.04536, 0],
                [-115.84387, 44.04551, 0],
                [-115.84369, 44.04563, 0],
                [-115.84322, 44.04605, 0],
                [-115.84292, 44.04638, 0],
                [-115.84289, 44.04642, 0],
                [-115.84263, 44.04667, 0],
                [-115.84261, 44.04668, 0],
                [-115.84239, 44.04685, 0],
                [-115.84227, 44.04692, 0],
                [-115.84215, 44.047, 0],
                [-115.84208, 44.04703, 0],
                [-115.84179, 44.04718, 0],
                [-115.84144, 44.04732, 0],
                [-115.84101, 44.04747, 0],
                [-115.84078, 44.04754, 0],
                [-115.84069, 44.04758, 0],
                [-115.83966, 44.04792, 0],
                [-115.83965, 44.04793, 0],
                [-115.83942, 44.048, 0],
                [-115.8392, 44.04808, 0],
                [-115.83873, 44.04822, 0],
                [-115.83826, 44.04834, 0],
                [-115.83802, 44.04839, 0],
                [-115.83779, 44.04845, 0],
                [-115.83773, 44.04846, 0],
                [-115.8366, 44.04875, 0],
                [-115.83612, 44.04891, 0],
                [-115.83591, 44.04899, 0],
                [-115.8355, 44.04917, 0],
                [-115.83482, 44.04951, 0],
                [-115.83469, 44.04959, 0],
                [-115.83458, 44.04964, 0],
                [-115.83385, 44.05012, 0],
                [-115.83326, 44.05059, 0],
                [-115.83303, 44.05081, 0],
                [-115.83191, 44.05201, 0],
                [-115.83152, 44.05235, 0],
                [-115.83115, 44.05263, 0],
                [-115.83093, 44.05282, 0],
                [-115.83086, 44.05289, 0],
                [-115.8307, 44.05302, 0],
                [-115.83054, 44.05319, 0],
                [-115.82993, 44.0539, 0],
                [-115.82979, 44.05405, 0],
                [-115.82964, 44.05419, 0],
                [-115.82942, 44.05437, 0],
                [-115.82903, 44.05466, 0],
                [-115.82845, 44.05497, 0],
                [-115.82443, 44.05657, 0],
                [-115.82431, 44.05663, 0],
                [-115.82421, 44.05667, 0],
                [-115.8239, 44.05685, 0],
                [-115.82388, 44.05687, 0],
                [-115.82376, 44.05694, 0],
                [-115.82356, 44.05709, 0],
                [-115.82245, 44.05805, 0],
                [-115.82185, 44.05853, 0],
                [-115.82108, 44.05906, 0],
                [-115.82012, 44.05965, 0],
                [-115.81989, 44.05978, 0],
                [-115.81933, 44.06006, 0],
                [-115.81916, 44.06012, 0],
                [-115.81885, 44.06025, 0],
                [-115.81812, 44.06049, 0],
                [-115.81748, 44.06065, 0],
                [-115.81593, 44.06094, 0],
                [-115.81534, 44.06107, 0],
                [-115.815, 44.06119, 0],
                [-115.81484, 44.06126, 0],
                [-115.8145, 44.06145, 0],
                [-115.81433, 44.06158, 0],
                [-115.81407, 44.06184, 0],
                [-115.81389, 44.06209, 0],
                [-115.8138, 44.06224, 0],
                [-115.81374, 44.06238, 0],
                [-115.81364, 44.0627, 0],
                [-115.81326, 44.06445, 0],
                [-115.81319, 44.06463, 0],
                [-115.81318, 44.06464, 0],
                [-115.81312, 44.06476, 0],
                [-115.81309, 44.0648, 0],
                [-115.81303, 44.0649, 0],
                [-115.81293, 44.06503, 0],
                [-115.81284, 44.06512, 0],
                [-115.81269, 44.06523, 0],
                [-115.81262, 44.06529, 0],
                [-115.8125, 44.06537, 0],
                [-115.81249, 44.06537, 0],
                [-115.81235, 44.06545, 0],
                [-115.81202, 44.06559, 0],
                [-115.81193, 44.06562, 0],
                [-115.81185, 44.06564, 0],
                [-115.81183, 44.06565, 0],
                [-115.81145, 44.06573, 0],
                [-115.811, 44.06576, 0],
                [-115.81061, 44.06576, 0],
                [-115.81021, 44.06574, 0],
                [-115.80978, 44.06574, 0],
                [-115.80938, 44.06576, 0],
                [-115.80917, 44.06578, 0],
                [-115.80882, 44.06584, 0],
                [-115.80854, 44.06591, 0],
                [-115.80819, 44.06604, 0],
                [-115.80782, 44.06622, 0],
                [-115.80744, 44.06647, 0],
                [-115.80711, 44.06678, 0],
                [-115.80689, 44.06708, 0],
                [-115.80679, 44.06727, 0],
                [-115.80678, 44.06728, 0],
                [-115.80668, 44.06757, 0],
                [-115.80666, 44.06767, 0],
                [-115.80663, 44.06791, 0],
                [-115.80662, 44.06857, 0],
                [-115.80661, 44.06868, 0],
                [-115.80661, 44.06874, 0],
                [-115.8066, 44.06882, 0],
                [-115.80656, 44.06896, 0],
                [-115.80646, 44.06919, 0],
                [-115.80637, 44.06932, 0],
                [-115.80624, 44.06947, 0],
                [-115.80613, 44.06957, 0],
                [-115.80591, 44.06973, 0],
                [-115.8057, 44.06984, 0],
                [-115.80564, 44.06986, 0],
                [-115.80555, 44.0699, 0],
                [-115.80508, 44.07007, 0],
                [-115.80081, 44.07141, 0],
                [-115.80076, 44.07142, 0],
                [-115.79848, 44.07222, 0],
                [-115.79769, 44.07256, 0],
                [-115.79691, 44.07296, 0],
                [-115.79539, 44.07382, 0],
                [-115.79499, 44.07401, 0],
                [-115.79476, 44.0741, 0],
                [-115.79451, 44.07418, 0],
                [-115.7938, 44.07431, 0],
                [-115.79379, 44.07431, 0],
                [-115.7935, 44.07437, 0],
                [-115.79338, 44.07441, 0],
                [-115.79326, 44.07444, 0],
                [-115.79302, 44.07453, 0],
                [-115.79282, 44.07462, 0],
                [-115.79251, 44.0748, 0],
                [-115.79249, 44.07482, 0],
                [-115.7916, 44.07534, 0],
                [-115.79136, 44.07546, 0],
                [-115.79088, 44.07566, 0],
                [-115.79087, 44.07567, 0],
                [-115.79025, 44.07588, 0],
                [-115.79007, 44.07595, 0],
                [-115.78861, 44.07643, 0],
                [-115.78794, 44.07667, 0],
                [-115.78786, 44.07669, 0],
                [-115.78748, 44.07681, 0],
                [-115.78675, 44.07698, 0],
                [-115.7858, 44.07712, 0],
                [-115.78511, 44.07717, 0],
                [-115.78438, 44.07717, 0],
                [-115.78372, 44.07712, 0],
                [-115.78333, 44.07707, 0],
                [-115.7833, 44.07706, 0],
                [-115.78295, 44.07701, 0],
                [-115.7797, 44.07626, 0],
                [-115.77917, 44.07618, 0],
                [-115.77881, 44.07615, 0],
                [-115.7783, 44.07616, 0],
                [-115.77816, 44.07618, 0],
                [-115.77803, 44.07619, 0],
                [-115.7779, 44.07621, 0],
                [-115.77778, 44.07624, 0],
                [-115.77773, 44.07624, 0],
                [-115.77763, 44.07627, 0],
                [-115.7774, 44.07632, 0],
                [-115.777, 44.07645, 0],
                [-115.77523, 44.0772, 0],
                [-115.77509, 44.07725, 0],
                [-115.77506, 44.07727, 0],
                [-115.77478, 44.07737, 0],
                [-115.7747, 44.07739, 0],
                [-115.77461, 44.07742, 0],
                [-115.77442, 44.07745, 0],
                [-115.7744, 44.07746, 0],
                [-115.77432, 44.07747, 0],
                [-115.774, 44.07747, 0],
                [-115.77391, 44.07746, 0],
                [-115.77384, 44.07746, 0],
                [-115.77377, 44.07744, 0],
                [-115.77367, 44.07743, 0],
                [-115.77357, 44.0774, 0],
                [-115.77348, 44.07738, 0],
                [-115.77329, 44.07731, 0],
                [-115.77301, 44.07716, 0],
                [-115.77284, 44.07702, 0],
                [-115.77271, 44.07687, 0],
                [-115.77266, 44.0768, 0],
                [-115.77256, 44.07662, 0],
                [-115.77249, 44.07631, 0],
                [-115.77245, 44.076, 0],
                [-115.77233, 44.07529, 0],
                [-115.77232, 44.07527, 0],
                [-115.77229, 44.07509, 0],
                [-115.77209, 44.07439, 0],
                [-115.77154, 44.07275, 0],
                [-115.77141, 44.07248, 0],
                [-115.77123, 44.07219, 0],
                [-115.77107, 44.07199, 0],
                [-115.77092, 44.07183, 0],
                [-115.77065, 44.07164, 0],
                [-115.77063, 44.07163, 0],
                [-115.77046, 44.07152, 0],
                [-115.77028, 44.07144, 0],
                [-115.76997, 44.07132, 0],
                [-115.76987, 44.07129, 0],
                [-115.76954, 44.07122, 0],
                [-115.76914, 44.07118, 0],
                [-115.76892, 44.07117, 0],
                [-115.76854, 44.07119, 0],
                [-115.76852, 44.0712, 0],
                [-115.76816, 44.07126, 0],
                [-115.76769, 44.0714, 0],
                [-115.76733, 44.07159, 0],
                [-115.76689, 44.07185, 0],
                [-115.76658, 44.07205, 0],
                [-115.76622, 44.07224, 0],
                [-115.7659, 44.07237, 0],
                [-115.76557, 44.07246, 0],
                [-115.76356, 44.07294, 0],
                [-115.76335, 44.07298, 0],
                [-115.76287, 44.07298, 0],
                [-115.76268, 44.07295, 0],
                [-115.76253, 44.07291, 0],
                [-115.76215, 44.07278, 0],
                [-115.76195, 44.07269, 0],
                [-115.76116, 44.07221, 0],
                [-115.76096, 44.0721, 0],
                [-115.76085, 44.07202, 0],
                [-115.7606, 44.07186, 0],
                [-115.76043, 44.07171, 0],
                [-115.76036, 44.07163, 0],
                [-115.76034, 44.07162, 0],
                [-115.76028, 44.07156, 0],
                [-115.75997, 44.07116, 0],
                [-115.75992, 44.07108, 0],
                [-115.7597, 44.07086, 0],
                [-115.75962, 44.07079, 0],
                [-115.75954, 44.07074, 0],
                [-115.75922, 44.07059, 0],
                [-115.75892, 44.0705, 0],
                [-115.75882, 44.07048, 0],
                [-115.75852, 44.07045, 0],
                [-115.75838, 44.07045, 0],
                [-115.75823, 44.07046, 0],
                [-115.75661, 44.07048, 0],
                [-115.75623, 44.07047, 0],
                [-115.75519, 44.07037, 0],
                [-115.75511, 44.07037, 0],
                [-115.75505, 44.07038, 0],
                [-115.75496, 44.07038, 0],
                [-115.75472, 44.07042, 0],
                [-115.75455, 44.07046, 0],
                [-115.75338, 44.07089, 0],
                [-115.75289, 44.0711, 0],
                [-115.75287, 44.0711, 0],
                [-115.75206, 44.0715, 0],
                [-115.75152, 44.07173, 0],
                [-115.75097, 44.0719, 0],
                [-115.75063, 44.07198, 0],
                [-115.75022, 44.07206, 0],
                [-115.74962, 44.07213, 0],
                [-115.74872, 44.07216, 0],
                [-115.74807, 44.07213, 0],
                [-115.74767, 44.07209, 0],
                [-115.74651, 44.0719, 0],
                [-115.74648, 44.0719, 0],
                [-115.746, 44.07182, 0],
                [-115.74524, 44.07175, 0],
                [-115.74465, 44.07174, 0],
                [-115.74403, 44.07176, 0],
                [-115.74375, 44.07178, 0],
                [-115.74213, 44.07195, 0],
                [-115.74171, 44.07195, 0],
                [-115.74156, 44.07193, 0],
                [-115.74136, 44.07189, 0],
                [-115.74131, 44.07187, 0],
                [-115.74108, 44.0718, 0],
                [-115.74083, 44.07166, 0],
                [-115.74073, 44.07158, 0],
                [-115.74056, 44.0714, 0],
                [-115.74048, 44.07128, 0],
                [-115.74046, 44.07122, 0],
                [-115.74044, 44.07118, 0],
                [-115.74043, 44.07112, 0],
                [-115.74039, 44.07097, 0],
                [-115.74037, 44.07082, 0],
                [-115.74032, 44.07057, 0],
                [-115.74028, 44.07045, 0],
                [-115.7402, 44.07028, 0],
                [-115.74016, 44.07022, 0],
                [-115.74002, 44.07005, 0],
                [-115.73994, 44.06997, 0],
                [-115.73974, 44.06982, 0],
                [-115.73947, 44.06968, 0],
                [-115.73938, 44.06965, 0],
                [-115.73929, 44.06961, 0],
                [-115.73901, 44.06954, 0],
                [-115.73871, 44.0695, 0],
                [-115.73774, 44.06941, 0],
                [-115.73721, 44.0693, 0],
                [-115.73676, 44.06918, 0],
                [-115.73628, 44.069, 0],
                [-115.73599, 44.06886, 0],
                [-115.73596, 44.06884, 0],
                [-115.7359, 44.06881, 0],
                [-115.73563, 44.06863, 0],
                [-115.73542, 44.06846, 0],
                [-115.73522, 44.06827, 0],
                [-115.73511, 44.06818, 0],
                [-115.73506, 44.06813, 0],
                [-115.735, 44.06809, 0],
                [-115.73494, 44.06804, 0],
                [-115.73481, 44.06797, 0],
                [-115.73467, 44.06791, 0],
                [-115.73457, 44.06788, 0],
                [-115.73445, 44.06786, 0],
                [-115.73437, 44.06784, 0],
                [-115.7342, 44.06783, 0],
                [-115.73402, 44.06783, 0],
                [-115.73381, 44.06786, 0],
                [-115.73359, 44.06792, 0],
                [-115.73335, 44.06805, 0],
                [-115.733, 44.06831, 0],
                [-115.73269, 44.06856, 0],
                [-115.73216, 44.06895, 0],
                [-115.73162, 44.06925, 0],
                [-115.73133, 44.06939, 0],
                [-115.7308, 44.06961, 0],
                [-115.73067, 44.06965, 0],
                [-115.73061, 44.06968, 0],
                [-115.73044, 44.06972, 0],
                [-115.73027, 44.06974, 0],
                [-115.7301, 44.06975, 0],
                [-115.72982, 44.06975, 0],
                [-115.72972, 44.06974, 0],
                [-115.7297, 44.06973, 0],
                [-115.72958, 44.06972, 0],
                [-115.72933, 44.06965, 0],
                [-115.72925, 44.06962, 0],
                [-115.72903, 44.06952, 0],
                [-115.72902, 44.06951, 0],
                [-115.7288, 44.06935, 0],
                [-115.72832, 44.06892, 0],
                [-115.72818, 44.06882, 0],
                [-115.72807, 44.06876, 0],
                [-115.72799, 44.06874, 0],
                [-115.72792, 44.06871, 0],
                [-115.72775, 44.06868, 0],
                [-115.72761, 44.06867, 0],
                [-115.72752, 44.06867, 0],
                [-115.7274, 44.06868, 0],
                [-115.72719, 44.06872, 0],
                [-115.72707, 44.06875, 0],
                [-115.72677, 44.06887, 0],
                [-115.72661, 44.06895, 0],
                [-115.72635, 44.06905, 0],
                [-115.726, 44.06912, 0],
                [-115.7259, 44.06912, 0],
                [-115.72582, 44.06913, 0],
                [-115.72572, 44.06913, 0],
                [-115.72552, 44.06911, 0],
                [-115.72528, 44.06906, 0],
                [-115.72484, 44.06894, 0],
                [-115.7242, 44.06868, 0],
                [-115.72391, 44.06854, 0],
                [-115.72374, 44.06844, 0],
                [-115.72372, 44.06842, 0],
                [-115.72363, 44.06837, 0],
                [-115.72322, 44.06802, 0],
                [-115.72322, 44.06801, 0],
                [-115.72303, 44.06785, 0],
                [-115.72289, 44.06772, 0],
                [-115.72266, 44.06754, 0],
                [-115.72238, 44.06735, 0],
                [-115.72223, 44.06727, 0],
                [-115.72219, 44.06724, 0],
                [-115.72176, 44.06702, 0],
                [-115.72137, 44.06686, 0],
                [-115.72114, 44.0668, 0],
                [-115.72093, 44.06677, 0],
                [-115.72071, 44.06677, 0],
                [-115.72064, 44.06678, 0],
                [-115.72061, 44.06678, 0],
                [-115.72036, 44.06683, 0],
                [-115.71995, 44.06698, 0],
                [-115.71994, 44.06699, 0],
                [-115.71984, 44.06703, 0],
                [-115.7194, 44.06727, 0],
                [-115.71916, 44.06743, 0],
                [-115.71867, 44.06788, 0],
                [-115.71832, 44.06813, 0],
                [-115.71821, 44.0682, 0],
                [-115.71816, 44.06824, 0],
                [-115.71801, 44.06832, 0],
                [-115.71769, 44.06846, 0],
                [-115.71594, 44.0689, 0],
                [-115.71581, 44.06894, 0],
                [-115.71458, 44.06925, 0],
                [-115.71432, 44.0693, 0],
                [-115.7142, 44.06931, 0],
                [-115.71406, 44.06931, 0],
                [-115.71393, 44.0693, 0],
                [-115.71379, 44.06928, 0],
                [-115.71365, 44.06925, 0],
                [-115.71362, 44.06925, 0],
                [-115.71276, 44.06899, 0],
                [-115.71185, 44.06862, 0],
                [-115.71167, 44.06853, 0],
                [-115.71145, 44.0684, 0],
                [-115.71089, 44.06812, 0],
                [-115.71067, 44.06803, 0],
                [-115.7105, 44.06797, 0],
                [-115.71039, 44.06794, 0],
                [-115.71031, 44.06791, 0],
                [-115.71011, 44.06786, 0],
                [-115.70994, 44.06779, 0],
                [-115.70993, 44.06779, 0],
                [-115.7097, 44.06766, 0],
                [-115.70962, 44.0676, 0],
                [-115.70946, 44.06744, 0],
                [-115.70929, 44.06724, 0],
                [-115.70905, 44.06693, 0],
                [-115.70897, 44.0668, 0],
                [-115.70896, 44.06676, 0],
                [-115.7089, 44.06664, 0],
                [-115.70884, 44.06648, 0],
                [-115.70881, 44.06637, 0],
                [-115.70879, 44.06627, 0],
                [-115.70879, 44.06624, 0],
                [-115.70877, 44.06606, 0],
                [-115.70875, 44.06552, 0],
                [-115.70874, 44.06543, 0],
                [-115.70873, 44.06516, 0],
                [-115.70872, 44.0651, 0],
                [-115.70874, 44.06501, 0],
                [-115.70874, 44.06498, 0],
                [-115.70876, 44.06491, 0],
                [-115.70881, 44.06481, 0],
                [-115.70882, 44.0648, 0],
                [-115.70889, 44.06466, 0],
                [-115.70902, 44.06448, 0],
                [-115.70902, 44.06447, 0],
                [-115.70914, 44.06432, 0],
                [-115.70917, 44.06427, 0],
                [-115.7093, 44.0641, 0],
                [-115.70936, 44.064, 0],
                [-115.70937, 44.06399, 0],
                [-115.70942, 44.06383, 0],
                [-115.70943, 44.06378, 0],
                [-115.70943, 44.06365, 0],
                [-115.70941, 44.06356, 0],
                [-115.70936, 44.06345, 0],
                [-115.70914, 44.0632, 0],
                [-115.709, 44.06309, 0],
                [-115.70865, 44.06275, 0],
                [-115.70817, 44.06235, 0],
                [-115.70816, 44.06233, 0],
                [-115.70803, 44.06218, 0],
                [-115.70771, 44.06177, 0],
                [-115.70755, 44.06159, 0],
                [-115.70743, 44.06147, 0],
                [-115.70728, 44.06137, 0],
                [-115.70715, 44.0613, 0],
                [-115.70682, 44.06123, 0],
                [-115.70661, 44.0612, 0],
                [-115.70644, 44.06119, 0],
                [-115.70619, 44.06115, 0],
                [-115.70609, 44.06114, 0],
                [-115.70565, 44.06105, 0],
                [-115.70525, 44.06092, 0],
                [-115.70506, 44.06087, 0],
                [-115.70502, 44.06085, 0],
                [-115.70479, 44.06078, 0],
                [-115.70478, 44.06078, 0],
                [-115.70456, 44.06071, 0],
                [-115.70449, 44.0607, 0],
                [-115.70434, 44.06065, 0],
                [-115.70432, 44.06065, 0],
                [-115.70425, 44.06063, 0],
                [-115.7041, 44.0606, 0],
                [-115.70391, 44.06059, 0],
                [-115.70374, 44.06061, 0],
                [-115.70359, 44.06064, 0],
                [-115.70341, 44.0607, 0],
                [-115.70332, 44.06075, 0],
                [-115.70325, 44.0608, 0],
                [-115.70315, 44.0609, 0],
                [-115.70309, 44.06098, 0],
                [-115.70305, 44.06106, 0],
                [-115.70301, 44.06117, 0],
                [-115.703, 44.06118, 0],
                [-115.70293, 44.06143, 0],
                [-115.70287, 44.06158, 0],
                [-115.7028, 44.0617, 0],
                [-115.70271, 44.06179, 0],
                [-115.7026, 44.06186, 0],
                [-115.70247, 44.06192, 0],
                [-115.70221, 44.06201, 0],
                [-115.7022, 44.06201, 0],
                [-115.70197, 44.06208, 0],
                [-115.70169, 44.06215, 0],
                [-115.70104, 44.06234, 0],
                [-115.70103, 44.06234, 0],
                [-115.70081, 44.0624, 0],
                [-115.70052, 44.06246, 0],
                [-115.70032, 44.06248, 0],
                [-115.70011, 44.06249, 0],
                [-115.69987, 44.06249, 0],
                [-115.69971, 44.06248, 0],
                [-115.69958, 44.06246, 0],
                [-115.69935, 44.0624, 0],
                [-115.69928, 44.06239, 0],
                [-115.69913, 44.06239, 0],
                [-115.69889, 44.06242, 0],
                [-115.69873, 44.06247, 0],
                [-115.6985, 44.06258, 0],
                [-115.69839, 44.06265, 0],
                [-115.69773, 44.06314, 0],
                [-115.69749, 44.06328, 0],
                [-115.69732, 44.06335, 0],
                [-115.69716, 44.06338, 0],
                [-115.69698, 44.0634, 0],
                [-115.69679, 44.0634, 0],
                [-115.69659, 44.06337, 0],
                [-115.6964, 44.06331, 0],
                [-115.6962, 44.06321, 0],
                [-115.69584, 44.06305, 0],
                [-115.69538, 44.06288, 0],
                [-115.69508, 44.06286, 0],
                [-115.69495, 44.06286, 0],
                [-115.69372, 44.06299, 0],
                [-115.69346, 44.06299, 0],
                [-115.69331, 44.06296, 0],
                [-115.69325, 44.06296, 0],
                [-115.69313, 44.06292, 0],
                [-115.69302, 44.0629, 0],
                [-115.69258, 44.06277, 0],
                [-115.6923, 44.0627, 0],
                [-115.69171, 44.06259, 0],
                [-115.69102, 44.0625, 0],
                [-115.69075, 44.06245, 0],
                [-115.69049, 44.06238, 0],
                [-115.68921, 44.06185, 0],
                [-115.68898, 44.06177, 0],
                [-115.68853, 44.06172, 0],
                [-115.68838, 44.06172, 0],
                [-115.68808, 44.06175, 0],
                [-115.68791, 44.06179, 0],
                [-115.68789, 44.06179, 0],
                [-115.68763, 44.06189, 0],
                [-115.68745, 44.06197, 0],
                [-115.68605, 44.06272, 0],
                [-115.68595, 44.06278, 0],
                [-115.68566, 44.06299, 0],
                [-115.68538, 44.06326, 0],
                [-115.68524, 44.06342, 0],
                [-115.68501, 44.06372, 0],
                [-115.68498, 44.06375, 0],
                [-115.68477, 44.06405, 0],
                [-115.68442, 44.06444, 0],
                [-115.68431, 44.06452, 0],
                [-115.68395, 44.06482, 0],
                [-115.68335, 44.06529, 0],
                [-115.68299, 44.06562, 0],
                [-115.68259, 44.06609, 0],
                [-115.68115, 44.06797, 0],
                [-115.68108, 44.06805, 0],
                [-115.68088, 44.06825, 0],
                [-115.68072, 44.06837, 0],
                [-115.68065, 44.06843, 0],
                [-115.68055, 44.0685, 0],
                [-115.68037, 44.06861, 0],
                [-115.68023, 44.06868, 0],
                [-115.6801, 44.06876, 0],
                [-115.67983, 44.06887, 0],
                [-115.67934, 44.06904, 0],
                [-115.67897, 44.06912, 0],
                [-115.67848, 44.06919, 0],
                [-115.67832, 44.0692, 0],
                [-115.67791, 44.0692, 0],
                [-115.67754, 44.06918, 0],
                [-115.67722, 44.06914, 0],
                [-115.67677, 44.06903, 0],
                [-115.67613, 44.06882, 0],
                [-115.67612, 44.06881, 0],
                [-115.67458, 44.06824, 0],
                [-115.67454, 44.06822, 0],
                [-115.67428, 44.06812, 0],
                [-115.67406, 44.06805, 0],
                [-115.67392, 44.06802, 0],
                [-115.67373, 44.068, 0],
                [-115.67362, 44.06801, 0],
                [-115.67354, 44.06801, 0],
                [-115.6735, 44.06802, 0],
                [-115.67343, 44.06803, 0],
                [-115.6734, 44.06804, 0],
                [-115.67331, 44.06806, 0],
                [-115.67315, 44.06811, 0],
                [-115.67305, 44.06816, 0],
                [-115.67295, 44.06822, 0],
                [-115.67288, 44.06827, 0],
                [-115.67281, 44.06834, 0],
                [-115.67263, 44.06858, 0],
                [-115.6725, 44.06879, 0],
                [-115.67247, 44.06883, 0],
                [-115.67239, 44.06898, 0],
                [-115.6723, 44.06911, 0],
                [-115.67226, 44.06918, 0],
                [-115.6722, 44.06933, 0],
                [-115.67201, 44.06988, 0],
                [-115.67194, 44.07003, 0],
                [-115.67181, 44.07026, 0],
                [-115.67174, 44.07036, 0],
                [-115.6717, 44.07043, 0],
                [-115.67163, 44.07052, 0],
                [-115.67132, 44.071, 0],
                [-115.67131, 44.07101, 0],
                [-115.67095, 44.07159, 0],
                [-115.67075, 44.07196, 0],
                [-115.67049, 44.07238, 0],
                [-115.6703, 44.07259, 0],
                [-115.67013, 44.07276, 0],
                [-115.6699, 44.07293, 0],
                [-115.66963, 44.0731, 0],
                [-115.66947, 44.07318, 0],
                [-115.66937, 44.07324, 0],
                [-115.66913, 44.07333, 0],
                [-115.6685, 44.07352, 0],
                [-115.66843, 44.07353, 0],
                [-115.66771, 44.07368, 0],
                [-115.6674, 44.07377, 0],
                [-115.66726, 44.07383, 0],
                [-115.66716, 44.07389, 0],
                [-115.66707, 44.07396, 0],
                [-115.66698, 44.07404, 0],
                [-115.66693, 44.0741, 0],
                [-115.66681, 44.0743, 0],
                [-115.66679, 44.0744, 0],
                [-115.66679, 44.07451, 0],
                [-115.6668, 44.07463, 0],
                [-115.66696, 44.07515, 0],
                [-115.66697, 44.07516, 0],
                [-115.667, 44.07533, 0],
                [-115.66702, 44.0755, 0],
                [-115.66703, 44.07567, 0],
                [-115.667, 44.07599, 0],
                [-115.66696, 44.07622, 0],
                [-115.6668, 44.07748, 0],
                [-115.66679, 44.0775, 0],
                [-115.66676, 44.07762, 0],
                [-115.66671, 44.07776, 0],
                [-115.66666, 44.07787, 0],
                [-115.66659, 44.07799, 0],
                [-115.66644, 44.07816, 0],
                [-115.66628, 44.07837, 0],
                [-115.66592, 44.07875, 0],
                [-115.66578, 44.07886, 0],
                [-115.66558, 44.07898, 0],
                [-115.66528, 44.07911, 0],
                [-115.66449, 44.07941, 0],
                [-115.66445, 44.07942, 0],
                [-115.66416, 44.07952, 0],
                [-115.66398, 44.07956, 0],
                [-115.66384, 44.07958, 0],
                [-115.6636, 44.0796, 0],
                [-115.66314, 44.07956, 0],
                [-115.66305, 44.07954, 0],
                [-115.66303, 44.07954, 0],
                [-115.6627, 44.07944, 0],
                [-115.66091, 44.07868, 0],
                [-115.66061, 44.07861, 0],
                [-115.66044, 44.07858, 0],
                [-115.66011, 44.07856, 0],
                [-115.65997, 44.07856, 0],
                [-115.65975, 44.07858, 0],
                [-115.65952, 44.07862, 0],
                [-115.65922, 44.0787, 0],
                [-115.65895, 44.0788, 0],
                [-115.6586, 44.079, 0],
                [-115.65852, 44.07906, 0],
                [-115.65837, 44.07921, 0],
                [-115.65825, 44.07936, 0],
                [-115.65817, 44.0795, 0],
                [-115.6581, 44.07968, 0],
                [-115.65805, 44.0799, 0],
                [-115.65804, 44.08004, 0],
                [-115.65807, 44.08027, 0],
                [-115.65815, 44.08065, 0],
                [-115.65843, 44.08171, 0],
                [-115.65845, 44.08182, 0],
                [-115.65848, 44.08194, 0],
                [-115.65849, 44.08204, 0],
                [-115.65849, 44.08214, 0],
                [-115.65847, 44.08225, 0],
                [-115.65844, 44.08234, 0],
                [-115.65837, 44.0825, 0],
                [-115.65812, 44.08288, 0],
                [-115.6581, 44.08292, 0],
                [-115.65792, 44.0832, 0],
                [-115.65775, 44.08342, 0],
                [-115.65763, 44.08354, 0],
                [-115.65746, 44.08368, 0],
                [-115.65732, 44.08377, 0],
                [-115.65702, 44.08393, 0],
                [-115.65685, 44.084, 0],
                [-115.65665, 44.08407, 0],
                [-115.65631, 44.08415, 0],
                [-115.65608, 44.08419, 0],
                [-115.65585, 44.08421, 0],
                [-115.6554, 44.08421, 0],
                [-115.65454, 44.08414, 0],
                [-115.65415, 44.08413, 0],
                [-115.65403, 44.08412, 0],
                [-115.65388, 44.08412, 0],
                [-115.65303, 44.08418, 0],
                [-115.6509, 44.08442, 0],
                [-115.65074, 44.08443, 0],
                [-115.65056, 44.08443, 0],
                [-115.65006, 44.08438, 0],
                [-115.64871, 44.08417, 0],
                [-115.64811, 44.08406, 0],
                [-115.64757, 44.0839, 0],
                [-115.64724, 44.08376, 0],
                [-115.64697, 44.08362, 0],
                [-115.64665, 44.08341, 0],
                [-115.64612, 44.08297, 0],
                [-115.64592, 44.08276, 0],
                [-115.64583, 44.08264, 0],
                [-115.6455, 44.08212, 0],
                [-115.64549, 44.08209, 0],
                [-115.64537, 44.08192, 0],
                [-115.6452, 44.08174, 0],
                [-115.64507, 44.08163, 0],
                [-115.64493, 44.08153, 0],
                [-115.64455, 44.08134, 0],
                [-115.64425, 44.08122, 0],
                [-115.6438, 44.08101, 0],
                [-115.64354, 44.08085, 0],
                [-115.64344, 44.08078, 0],
                [-115.64322, 44.08059, 0],
                [-115.64306, 44.0804, 0],
                [-115.64294, 44.08022, 0],
                [-115.64289, 44.08016, 0],
                [-115.64258, 44.07972, 0],
                [-115.6425, 44.07963, 0],
                [-115.64231, 44.07945, 0],
                [-115.64226, 44.07941, 0],
                [-115.64217, 44.07936, 0],
                [-115.64198, 44.07927, 0],
                [-115.6419, 44.07924, 0],
                [-115.64185, 44.07923, 0],
                [-115.64174, 44.07919, 0],
                [-115.64162, 44.07916, 0],
                [-115.64128, 44.07913, 0],
                [-115.64111, 44.07913, 0],
                [-115.64097, 44.07914, 0],
                [-115.64082, 44.07916, 0],
                [-115.64069, 44.07919, 0],
                [-115.64047, 44.07926, 0],
                [-115.64046, 44.07926, 0],
                [-115.64026, 44.07935, 0],
                [-115.64015, 44.07941, 0],
                [-115.64006, 44.07948, 0],
                [-115.63995, 44.07958, 0],
                [-115.63981, 44.07975, 0],
                [-115.63973, 44.07989, 0],
                [-115.63969, 44.08, 0],
                [-115.63966, 44.08013, 0],
                [-115.63965, 44.08024, 0],
                [-115.63968, 44.08048, 0],
                [-115.63972, 44.08063, 0],
                [-115.63989, 44.08141, 0],
                [-115.63995, 44.0816, 0],
                [-115.64001, 44.08174, 0],
                [-115.64014, 44.08196, 0],
                [-115.64082, 44.08277, 0],
                [-115.64088, 44.08286, 0],
                [-115.64093, 44.08295, 0],
                [-115.64103, 44.08318, 0],
                [-115.64106, 44.0833, 0],
                [-115.64109, 44.08348, 0],
                [-115.6411, 44.08361, 0],
                [-115.6411, 44.08376, 0],
                [-115.64109, 44.08389, 0],
                [-115.64106, 44.08404, 0],
                [-115.64102, 44.08417, 0],
                [-115.64096, 44.08432, 0],
                [-115.64072, 44.0848, 0],
                [-115.64062, 44.08497, 0],
                [-115.64051, 44.08513, 0],
                [-115.64037, 44.08526, 0],
                [-115.64025, 44.08535, 0],
                [-115.64008, 44.08545, 0],
                [-115.64, 44.08549, 0],
                [-115.6399, 44.08553, 0],
                [-115.63964, 44.08561, 0],
                [-115.63941, 44.08565, 0],
                [-115.63926, 44.08566, 0],
                [-115.63911, 44.08566, 0],
                [-115.63885, 44.08564, 0],
                [-115.63861, 44.08559, 0],
                [-115.63847, 44.08554, 0],
                [-115.63822, 44.08543, 0],
                [-115.63811, 44.08537, 0],
                [-115.6381, 44.08537, 0],
                [-115.63646, 44.08455, 0],
                [-115.63632, 44.08447, 0],
                [-115.63609, 44.08431, 0],
                [-115.63608, 44.08431, 0],
                [-115.63592, 44.08415, 0],
                [-115.63585, 44.08407, 0],
                [-115.63534, 44.08326, 0],
                [-115.63521, 44.08309, 0],
                [-115.63515, 44.08304, 0],
                [-115.63508, 44.08297, 0],
                [-115.6348, 44.08279, 0],
                [-115.63461, 44.08271, 0],
                [-115.63424, 44.0826, 0],
                [-115.63406, 44.08257, 0],
                [-115.63392, 44.08256, 0],
                [-115.63364, 44.08257, 0],
                [-115.63337, 44.08261, 0],
                [-115.63321, 44.08265, 0],
                [-115.63291, 44.08277, 0],
                [-115.63279, 44.08283, 0],
                [-115.63237, 44.08314, 0],
                [-115.63139, 44.08393, 0],
                [-115.63137, 44.08394, 0],
                [-115.6307, 44.08447, 0],
                [-115.6303, 44.08472, 0],
                [-115.62996, 44.08491, 0],
                [-115.6295, 44.08512, 0],
                [-115.6292, 44.08527, 0],
                [-115.62891, 44.0854, 0],
                [-115.62887, 44.08541, 0],
                [-115.62867, 44.0855, 0],
                [-115.62828, 44.08562, 0],
                [-115.62808, 44.08566, 0],
                [-115.62793, 44.0857, 0],
                [-115.62594, 44.08606, 0],
                [-115.62567, 44.08608, 0],
                [-115.62539, 44.08608, 0],
                [-115.62354, 44.086, 0],
                [-115.62282, 44.08592, 0],
                [-115.62264, 44.08589, 0],
                [-115.62074, 44.08545, 0],
                [-115.61993, 44.08532, 0],
                [-115.6199, 44.08532, 0],
                [-115.61898, 44.08521, 0],
                [-115.61894, 44.08521, 0],
                [-115.61865, 44.08517, 0],
                [-115.61825, 44.08509, 0],
                [-115.61773, 44.08495, 0],
                [-115.61731, 44.08481, 0],
                [-115.6171, 44.08473, 0],
                [-115.61687, 44.08463, 0],
                [-115.61686, 44.08462, 0],
                [-115.61664, 44.08452, 0],
                [-115.61632, 44.08434, 0],
                [-115.616, 44.08412, 0],
                [-115.61574, 44.08391, 0],
                [-115.61539, 44.08358, 0],
                [-115.61506, 44.08319, 0],
                [-115.61462, 44.08254, 0]
            ]
        },
        id: "de942d8a-7ac9-4a26-8bce-6766b3f3e238"
    }, 
    // {
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-116.11451, 44.08456, 0]
    //     },
    //     properties: {
    //         name: "Banks, Idaho",
    //         "shape": "Marker",
    //         "category": "default"
    //     },
    //     id: "b58e32ee-30da-4ea1-a270-d694ae29970e"
    // }, {
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-115.61462, 44.08254, 0]
    //     },
    //     properties: {
    //         name: "Lowman, Idaho",
    //         "shape": "Marker",
    //         "category": "default"
    //     },
    //     id: "bb2c6815-c13e-470f-9543-0cfc5035a283"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Crouch, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-115.971036, 44.114489]
    //     },
    //     id: "76e46506-debd-4df4-897d-ec4b3e6f3712"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Garden Valley, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-115.951918, 44.088795]
    //     },
    //     id: "09c4ab5b-a57e-4b7e-8d92-276749067adb"
    // }
    ]
};

export default WildlifeCanyon;