const MesaFalls = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Mesa Falls Scenic Byway",
				description: "Discover the lush landscape of the Caribou-Targhee National Forest and two of the most spectacular waterfalls in the West—Upper and Lower Mesa Falls. As tall as a 10-story building, the upper falls pour over remnants of an ancient volcanic super-eruption while the lower falls continue to chisel away at the solidified ash and lava. Get an up-close-and-personal experience by exploring the on viewing platforms, or stop at the historic Mesa Falls Visitor Center to learn about the area's geology and rich past.",
				link: "https://visitidaho.org/things-to-do/road-trips/mesa-falls-scenic-byway/",
				route: [
					"Ashton", 
					"Island Park"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
          [
            -111.44829,
            44.07135,
            0
          ],
          [
            -111.45013,
            44.07135,
            0
          ],
          [
            -111.45041,
            44.07136,
            0
          ],
          [
            -111.4531,
            44.07135,
            0
          ],
          [
            -111.45505,
            44.07137,
            0
          ],
          [
            -111.45518,
            44.07141,
            0
          ],
          [
            -111.45523,
            44.07142,
            0
          ],
          [
            -111.45531,
            44.07143,
            0
          ],
          [
            -111.45552,
            44.07148,
            0
          ],
          [
            -111.45556,
            44.0715,
            0
          ],
          [
            -111.45561,
            44.07153,
            0
          ],
          [
            -111.45568,
            44.07156,
            0
          ],
          [
            -111.45573,
            44.07161,
            0
          ],
          [
            -111.45577,
            44.07166,
            0
          ],
          [
            -111.4558,
            44.07171,
            0
          ],
          [
            -111.45582,
            44.07176,
            0
          ],
          [
            -111.4559,
            44.07189,
            0
          ],
          [
            -111.45588,
            44.07253,
            0
          ],
          [
            -111.45594,
            44.07265,
            0
          ],
          [
            -111.45595,
            44.07347,
            0
          ],
          [
            -111.45594,
            44.07399,
            0
          ],
          [
            -111.45594,
            44.07489,
            0
          ],
          [
            -111.45593,
            44.07501,
            0
          ],
          [
            -111.45593,
            44.08083,
            0
          ],
          [
            -111.45594,
            44.08113,
            0
          ],
          [
            -111.45594,
            44.09395,
            0
          ],
          [
            -111.45592,
            44.0946,
            0
          ],
          [
            -111.45591,
            44.0947,
            0
          ],
          [
            -111.45591,
            44.09479,
            0
          ],
          [
            -111.45589,
            44.095,
            0
          ],
          [
            -111.4558,
            44.09561,
            0
          ],
          [
            -111.45568,
            44.09623,
            0
          ],
          [
            -111.45566,
            44.09636,
            0
          ],
          [
            -111.45563,
            44.09645,
            0
          ],
          [
            -111.45561,
            44.09656,
            0
          ],
          [
            -111.45556,
            44.09671,
            0
          ],
          [
            -111.45551,
            44.09691,
            0
          ],
          [
            -111.45539,
            44.09732,
            0
          ],
          [
            -111.45526,
            44.0977,
            0
          ],
          [
            -111.45499,
            44.09839,
            0
          ],
          [
            -111.45487,
            44.09863,
            0
          ],
          [
            -111.45482,
            44.09876,
            0
          ],
          [
            -111.45476,
            44.09888,
            0
          ],
          [
            -111.45471,
            44.09897,
            0
          ],
          [
            -111.45224,
            44.10386,
            0
          ],
          [
            -111.45216,
            44.10403,
            0
          ],
          [
            -111.45112,
            44.10606,
            0
          ],
          [
            -111.45087,
            44.10657,
            0
          ],
          [
            -111.4507,
            44.10688,
            0
          ],
          [
            -111.44994,
            44.10841,
            0
          ],
          [
            -111.44976,
            44.1088,
            0
          ],
          [
            -111.44897,
            44.11042,
            0
          ],
          [
            -111.44869,
            44.11094,
            0
          ],
          [
            -111.44828,
            44.11177,
            0
          ],
          [
            -111.44805,
            44.1122,
            0
          ],
          [
            -111.44769,
            44.11293,
            0
          ],
          [
            -111.44763,
            44.11303,
            0
          ],
          [
            -111.44656,
            44.11502,
            0
          ],
          [
            -111.44603,
            44.11595,
            0
          ],
          [
            -111.44601,
            44.11599,
            0
          ],
          [
            -111.44573,
            44.11647,
            0
          ],
          [
            -111.44561,
            44.11666,
            0
          ],
          [
            -111.44525,
            44.11727,
            0
          ],
          [
            -111.44474,
            44.11802,
            0
          ],
          [
            -111.44369,
            44.11942,
            0
          ],
          [
            -111.4432,
            44.12002,
            0
          ],
          [
            -111.44234,
            44.12101,
            0
          ],
          [
            -111.44221,
            44.12117,
            0
          ],
          [
            -111.44206,
            44.12134,
            0
          ],
          [
            -111.44174,
            44.12174,
            0
          ],
          [
            -111.44167,
            44.12184,
            0
          ],
          [
            -111.44164,
            44.12187,
            0
          ],
          [
            -111.4415,
            44.12207,
            0
          ],
          [
            -111.44143,
            44.12218,
            0
          ],
          [
            -111.44135,
            44.12229,
            0
          ],
          [
            -111.4408,
            44.12313,
            0
          ],
          [
            -111.4406,
            44.12346,
            0
          ],
          [
            -111.44056,
            44.12352,
            0
          ],
          [
            -111.44035,
            44.1239,
            0
          ],
          [
            -111.44028,
            44.12405,
            0
          ],
          [
            -111.43999,
            44.12461,
            0
          ],
          [
            -111.43997,
            44.12464,
            0
          ],
          [
            -111.43989,
            44.1248,
            0
          ],
          [
            -111.43978,
            44.125,
            0
          ],
          [
            -111.4395,
            44.12546,
            0
          ],
          [
            -111.43949,
            44.12547,
            0
          ],
          [
            -111.43937,
            44.12569,
            0
          ],
          [
            -111.43925,
            44.12587,
            0
          ],
          [
            -111.43878,
            44.12662,
            0
          ],
          [
            -111.43851,
            44.12702,
            0
          ],
          [
            -111.43765,
            44.12823,
            0
          ],
          [
            -111.43703,
            44.129,
            0
          ],
          [
            -111.43703,
            44.12901,
            0
          ],
          [
            -111.43677,
            44.12936,
            0
          ],
          [
            -111.4357,
            44.13074,
            0
          ],
          [
            -111.43559,
            44.1309,
            0
          ],
          [
            -111.43554,
            44.13099,
            0
          ],
          [
            -111.43543,
            44.13115,
            0
          ],
          [
            -111.43534,
            44.13131,
            0
          ],
          [
            -111.43531,
            44.13138,
            0
          ],
          [
            -111.43524,
            44.13152,
            0
          ],
          [
            -111.43511,
            44.13181,
            0
          ],
          [
            -111.43499,
            44.1321,
            0
          ],
          [
            -111.4349,
            44.13234,
            0
          ],
          [
            -111.43482,
            44.13253,
            0
          ],
          [
            -111.4341,
            44.13441,
            0
          ],
          [
            -111.43366,
            44.13562,
            0
          ],
          [
            -111.43353,
            44.13594,
            0
          ],
          [
            -111.43346,
            44.13609,
            0
          ],
          [
            -111.43341,
            44.13621,
            0
          ],
          [
            -111.43336,
            44.13631,
            0
          ],
          [
            -111.43332,
            44.13641,
            0
          ],
          [
            -111.43327,
            44.1365,
            0
          ],
          [
            -111.43323,
            44.13659,
            0
          ],
          [
            -111.43313,
            44.13678,
            0
          ],
          [
            -111.43306,
            44.13689,
            0
          ],
          [
            -111.43302,
            44.13697,
            0
          ],
          [
            -111.43297,
            44.13704,
            0
          ],
          [
            -111.4329,
            44.13715,
            0
          ],
          [
            -111.43289,
            44.13717,
            0
          ],
          [
            -111.43279,
            44.13732,
            0
          ],
          [
            -111.43278,
            44.13733,
            0
          ],
          [
            -111.43271,
            44.13744,
            0
          ],
          [
            -111.43262,
            44.13756,
            0
          ],
          [
            -111.43251,
            44.13772,
            0
          ],
          [
            -111.43213,
            44.13824,
            0
          ],
          [
            -111.43016,
            44.14073,
            0
          ],
          [
            -111.43008,
            44.14084,
            0
          ],
          [
            -111.42999,
            44.14095,
            0
          ],
          [
            -111.42993,
            44.14105,
            0
          ],
          [
            -111.42986,
            44.14113,
            0
          ],
          [
            -111.42983,
            44.14119,
            0
          ],
          [
            -111.42975,
            44.1413,
            0
          ],
          [
            -111.42975,
            44.14131,
            0
          ],
          [
            -111.42923,
            44.14211,
            0
          ],
          [
            -111.42804,
            44.14404,
            0
          ],
          [
            -111.42779,
            44.14442,
            0
          ],
          [
            -111.42753,
            44.14484,
            0
          ],
          [
            -111.4273,
            44.14524,
            0
          ],
          [
            -111.42566,
            44.14782,
            0
          ],
          [
            -111.42564,
            44.14786,
            0
          ],
          [
            -111.42536,
            44.14827,
            0
          ],
          [
            -111.42532,
            44.14835,
            0
          ],
          [
            -111.42515,
            44.14861,
            0
          ],
          [
            -111.42482,
            44.14918,
            0
          ],
          [
            -111.42477,
            44.14928,
            0
          ],
          [
            -111.42466,
            44.14948,
            0
          ],
          [
            -111.42453,
            44.14976,
            0
          ],
          [
            -111.42447,
            44.14992,
            0
          ],
          [
            -111.4244,
            44.15007,
            0
          ],
          [
            -111.42433,
            44.15025,
            0
          ],
          [
            -111.42423,
            44.15054,
            0
          ],
          [
            -111.42421,
            44.15058,
            0
          ],
          [
            -111.42419,
            44.15066,
            0
          ],
          [
            -111.42417,
            44.15072,
            0
          ],
          [
            -111.42417,
            44.15073,
            0
          ],
          [
            -111.42414,
            44.15085,
            0
          ],
          [
            -111.42409,
            44.15102,
            0
          ],
          [
            -111.424,
            44.15148,
            0
          ],
          [
            -111.42399,
            44.1516,
            0
          ],
          [
            -111.42398,
            44.15161,
            0
          ],
          [
            -111.42393,
            44.15212,
            0
          ],
          [
            -111.42393,
            44.15268,
            0
          ],
          [
            -111.42394,
            44.15287,
            0
          ],
          [
            -111.42394,
            44.15303,
            0
          ],
          [
            -111.42395,
            44.15317,
            0
          ],
          [
            -111.42395,
            44.15322,
            0
          ],
          [
            -111.42396,
            44.15326,
            0
          ],
          [
            -111.42398,
            44.15349,
            0
          ],
          [
            -111.424,
            44.15356,
            0
          ],
          [
            -111.42401,
            44.15363,
            0
          ],
          [
            -111.42405,
            44.15384,
            0
          ],
          [
            -111.42424,
            44.15454,
            0
          ],
          [
            -111.42427,
            44.15463,
            0
          ],
          [
            -111.42431,
            44.15473,
            0
          ],
          [
            -111.42439,
            44.15496,
            0
          ],
          [
            -111.42474,
            44.15575,
            0
          ],
          [
            -111.42496,
            44.15628,
            0
          ],
          [
            -111.42506,
            44.1565,
            0
          ],
          [
            -111.42513,
            44.15669,
            0
          ],
          [
            -111.42524,
            44.15694,
            0
          ],
          [
            -111.42536,
            44.15729,
            0
          ],
          [
            -111.42538,
            44.15737,
            0
          ],
          [
            -111.4254,
            44.15743,
            0
          ],
          [
            -111.42544,
            44.1576,
            0
          ],
          [
            -111.42546,
            44.15767,
            0
          ],
          [
            -111.4255,
            44.1579,
            0
          ],
          [
            -111.4255,
            44.15803,
            0
          ],
          [
            -111.42551,
            44.15812,
            0
          ],
          [
            -111.42551,
            44.15823,
            0
          ],
          [
            -111.42552,
            44.15834,
            0
          ],
          [
            -111.4255,
            44.1586,
            0
          ],
          [
            -111.4255,
            44.15872,
            0
          ],
          [
            -111.42544,
            44.15923,
            0
          ],
          [
            -111.42538,
            44.1596,
            0
          ],
          [
            -111.42533,
            44.15998,
            0
          ],
          [
            -111.42525,
            44.16045,
            0
          ],
          [
            -111.42521,
            44.16063,
            0
          ],
          [
            -111.42508,
            44.16105,
            0
          ],
          [
            -111.42502,
            44.16122,
            0
          ],
          [
            -111.42494,
            44.16139,
            0
          ],
          [
            -111.42485,
            44.16161,
            0
          ],
          [
            -111.42477,
            44.16175,
            0
          ],
          [
            -111.42469,
            44.16192,
            0
          ],
          [
            -111.42456,
            44.16214,
            0
          ],
          [
            -111.42445,
            44.16236,
            0
          ],
          [
            -111.42411,
            44.16296,
            0
          ],
          [
            -111.4239,
            44.16336,
            0
          ],
          [
            -111.42386,
            44.16347,
            0
          ],
          [
            -111.42379,
            44.16364,
            0
          ],
          [
            -111.42374,
            44.16378,
            0
          ],
          [
            -111.42363,
            44.16414,
            0
          ],
          [
            -111.42358,
            44.16437,
            0
          ],
          [
            -111.42354,
            44.16461,
            0
          ],
          [
            -111.42351,
            44.16475,
            0
          ],
          [
            -111.42351,
            44.1648,
            0
          ],
          [
            -111.42348,
            44.16506,
            0
          ],
          [
            -111.42346,
            44.16519,
            0
          ],
          [
            -111.42344,
            44.16558,
            0
          ],
          [
            -111.42345,
            44.1657,
            0
          ],
          [
            -111.42345,
            44.16595,
            0
          ],
          [
            -111.42347,
            44.1661,
            0
          ],
          [
            -111.42348,
            44.16632,
            0
          ],
          [
            -111.42349,
            44.16641,
            0
          ],
          [
            -111.42361,
            44.1671,
            0
          ],
          [
            -111.42365,
            44.16726,
            0
          ],
          [
            -111.4237,
            44.16743,
            0
          ],
          [
            -111.42376,
            44.1676,
            0
          ],
          [
            -111.4238,
            44.1677,
            0
          ],
          [
            -111.42386,
            44.16783,
            0
          ],
          [
            -111.42392,
            44.16799,
            0
          ],
          [
            -111.42399,
            44.16814,
            0
          ],
          [
            -111.424,
            44.16817,
            0
          ],
          [
            -111.42413,
            44.16844,
            0
          ],
          [
            -111.42418,
            44.16853,
            0
          ],
          [
            -111.42421,
            44.1686,
            0
          ],
          [
            -111.42428,
            44.16873,
            0
          ],
          [
            -111.42499,
            44.16991,
            0
          ],
          [
            -111.42506,
            44.17004,
            0
          ],
          [
            -111.4258,
            44.17124,
            0
          ],
          [
            -111.42608,
            44.17173,
            0
          ],
          [
            -111.42609,
            44.17174,
            0
          ],
          [
            -111.42618,
            44.17189,
            0
          ],
          [
            -111.42623,
            44.17199,
            0
          ],
          [
            -111.42678,
            44.17288,
            0
          ],
          [
            -111.42681,
            44.17295,
            0
          ],
          [
            -111.42701,
            44.17326,
            0
          ],
          [
            -111.42727,
            44.17371,
            0
          ],
          [
            -111.42755,
            44.17423,
            0
          ],
          [
            -111.42764,
            44.17441,
            0
          ],
          [
            -111.4279,
            44.17504,
            0
          ],
          [
            -111.428,
            44.1753,
            0
          ],
          [
            -111.42824,
            44.17608,
            0
          ],
          [
            -111.42857,
            44.17738,
            0
          ],
          [
            -111.42869,
            44.17779,
            0
          ],
          [
            -111.42876,
            44.178,
            0
          ],
          [
            -111.42882,
            44.17816,
            0
          ],
          [
            -111.42897,
            44.17848,
            0
          ],
          [
            -111.42923,
            44.17894,
            0
          ],
          [
            -111.42957,
            44.17948,
            0
          ],
          [
            -111.4298,
            44.17977,
            0
          ],
          [
            -111.42991,
            44.17992,
            0
          ],
          [
            -111.43006,
            44.18008,
            0
          ],
          [
            -111.4307,
            44.18072,
            0
          ],
          [
            -111.43088,
            44.18087,
            0
          ],
          [
            -111.4309,
            44.18089,
            0
          ],
          [
            -111.43104,
            44.181,
            0
          ],
          [
            -111.43113,
            44.18108,
            0
          ],
          [
            -111.43127,
            44.18118,
            0
          ],
          [
            -111.43137,
            44.18127,
            0
          ],
          [
            -111.43149,
            44.18135,
            0
          ],
          [
            -111.43173,
            44.18154,
            0
          ],
          [
            -111.432,
            44.18172,
            0
          ],
          [
            -111.43237,
            44.18195,
            0
          ],
          [
            -111.43258,
            44.18207,
            0
          ],
          [
            -111.43329,
            44.18243,
            0
          ],
          [
            -111.4333,
            44.18243,
            0
          ],
          [
            -111.4337,
            44.18262,
            0
          ],
          [
            -111.43476,
            44.1831,
            0
          ],
          [
            -111.43552,
            44.18343,
            0
          ],
          [
            -111.43622,
            44.18372,
            0
          ],
          [
            -111.43892,
            44.1849,
            0
          ],
          [
            -111.4394,
            44.18513,
            0
          ],
          [
            -111.44029,
            44.1856,
            0
          ],
          [
            -111.44032,
            44.18561,
            0
          ],
          [
            -111.44058,
            44.18576,
            0
          ],
          [
            -111.44079,
            44.18589,
            0
          ],
          [
            -111.44141,
            44.1863,
            0
          ],
          [
            -111.44182,
            44.18661,
            0
          ],
          [
            -111.4424,
            44.18707,
            0
          ],
          [
            -111.44267,
            44.18731,
            0
          ],
          [
            -111.44287,
            44.18751,
            0
          ],
          [
            -111.44305,
            44.18768,
            0
          ],
          [
            -111.44342,
            44.18805,
            0
          ],
          [
            -111.44407,
            44.18884,
            0
          ],
          [
            -111.44428,
            44.18912,
            0
          ],
          [
            -111.44441,
            44.18932,
            0
          ],
          [
            -111.44453,
            44.18949,
            0
          ],
          [
            -111.4447,
            44.18977,
            0
          ],
          [
            -111.44487,
            44.19008,
            0
          ],
          [
            -111.44488,
            44.19008,
            0
          ],
          [
            -111.44494,
            44.19022,
            0
          ],
          [
            -111.44502,
            44.19038,
            0
          ],
          [
            -111.44517,
            44.19071,
            0
          ],
          [
            -111.44547,
            44.19151,
            0
          ],
          [
            -111.44627,
            44.194,
            0
          ],
          [
            -111.44652,
            44.19473,
            0
          ],
          [
            -111.44667,
            44.19527,
            0
          ],
          [
            -111.44673,
            44.19544,
            0
          ],
          [
            -111.44757,
            44.19808,
            0
          ],
          [
            -111.44766,
            44.19834,
            0
          ],
          [
            -111.44902,
            44.20265,
            0
          ],
          [
            -111.44924,
            44.2033,
            0
          ],
          [
            -111.44965,
            44.20462,
            0
          ],
          [
            -111.44974,
            44.20487,
            0
          ],
          [
            -111.45,
            44.20568,
            0
          ],
          [
            -111.45003,
            44.20579,
            0
          ],
          [
            -111.4508,
            44.20821,
            0
          ],
          [
            -111.45092,
            44.20855,
            0
          ],
          [
            -111.45175,
            44.21121,
            0
          ],
          [
            -111.45204,
            44.21207,
            0
          ],
          [
            -111.45233,
            44.213,
            0
          ],
          [
            -111.45279,
            44.2144,
            0
          ],
          [
            -111.45284,
            44.21458,
            0
          ],
          [
            -111.45291,
            44.21477,
            0
          ],
          [
            -111.45415,
            44.21871,
            0
          ],
          [
            -111.45428,
            44.2191,
            0
          ],
          [
            -111.45434,
            44.21931,
            0
          ],
          [
            -111.45471,
            44.22044,
            0
          ],
          [
            -111.45555,
            44.22311,
            0
          ],
          [
            -111.4557,
            44.22354,
            0
          ],
          [
            -111.45686,
            44.22718,
            0
          ],
          [
            -111.45713,
            44.22797,
            0
          ],
          [
            -111.4574,
            44.22885,
            0
          ],
          [
            -111.45963,
            44.23582,
            0
          ],
          [
            -111.4597,
            44.23602,
            0
          ],
          [
            -111.46176,
            44.24251,
            0
          ],
          [
            -111.4618,
            44.24262,
            0
          ],
          [
            -111.46562,
            44.25457,
            0
          ],
          [
            -111.46569,
            44.25481,
            0
          ],
          [
            -111.46783,
            44.26146,
            0
          ],
          [
            -111.46893,
            44.26505,
            0
          ],
          [
            -111.4692,
            44.26581,
            0
          ],
          [
            -111.46929,
            44.26609,
            0
          ],
          [
            -111.46934,
            44.26627,
            0
          ],
          [
            -111.46943,
            44.26666,
            0
          ],
          [
            -111.46949,
            44.26698,
            0
          ],
          [
            -111.46954,
            44.26718,
            0
          ],
          [
            -111.46961,
            44.26769,
            0
          ],
          [
            -111.46962,
            44.26785,
            0
          ],
          [
            -111.46964,
            44.26804,
            0
          ],
          [
            -111.46964,
            44.26808,
            0
          ],
          [
            -111.46966,
            44.26827,
            0
          ],
          [
            -111.46968,
            44.26857,
            0
          ],
          [
            -111.46968,
            44.26886,
            0
          ],
          [
            -111.46969,
            44.2691,
            0
          ],
          [
            -111.46969,
            44.26936,
            0
          ],
          [
            -111.46967,
            44.26982,
            0
          ],
          [
            -111.46957,
            44.27077,
            0
          ],
          [
            -111.46953,
            44.27102,
            0
          ],
          [
            -111.46944,
            44.2715,
            0
          ],
          [
            -111.46925,
            44.27229,
            0
          ],
          [
            -111.4692,
            44.27247,
            0
          ],
          [
            -111.46909,
            44.27282,
            0
          ],
          [
            -111.46904,
            44.27295,
            0
          ],
          [
            -111.46899,
            44.2731,
            0
          ],
          [
            -111.46877,
            44.27366,
            0
          ],
          [
            -111.46865,
            44.27391,
            0
          ],
          [
            -111.46858,
            44.27398,
            0
          ],
          [
            -111.46841,
            44.27433,
            0
          ],
          [
            -111.46819,
            44.27472,
            0
          ],
          [
            -111.46784,
            44.27542,
            0
          ],
          [
            -111.46731,
            44.27642,
            0
          ],
          [
            -111.46714,
            44.27672,
            0
          ],
          [
            -111.46712,
            44.27677,
            0
          ],
          [
            -111.46709,
            44.27681,
            0
          ],
          [
            -111.46708,
            44.2769,
            0
          ],
          [
            -111.4669,
            44.27726,
            0
          ],
          [
            -111.46678,
            44.27747,
            0
          ],
          [
            -111.46563,
            44.27961,
            0
          ],
          [
            -111.46541,
            44.27998,
            0
          ],
          [
            -111.46536,
            44.28008,
            0
          ],
          [
            -111.46269,
            44.285,
            0
          ],
          [
            -111.46233,
            44.2857,
            0
          ],
          [
            -111.46179,
            44.28666,
            0
          ],
          [
            -111.46091,
            44.28833,
            0
          ],
          [
            -111.45865,
            44.29251,
            0
          ],
          [
            -111.45857,
            44.29267,
            0
          ],
          [
            -111.45816,
            44.29355,
            0
          ],
          [
            -111.45808,
            44.29378,
            0
          ],
          [
            -111.45794,
            44.29414,
            0
          ],
          [
            -111.45787,
            44.29421,
            0
          ],
          [
            -111.45785,
            44.29428,
            0
          ],
          [
            -111.45775,
            44.29451,
            0
          ],
          [
            -111.45762,
            44.29489,
            0
          ],
          [
            -111.45743,
            44.29558,
            0
          ],
          [
            -111.45737,
            44.29599,
            0
          ],
          [
            -111.45727,
            44.29654,
            0
          ],
          [
            -111.45711,
            44.2978,
            0
          ],
          [
            -111.45714,
            44.29791,
            0
          ],
          [
            -111.45709,
            44.2982,
            0
          ],
          [
            -111.45684,
            44.29995,
            0
          ],
          [
            -111.45606,
            44.30507,
            0
          ],
          [
            -111.45585,
            44.30635,
            0
          ],
          [
            -111.45579,
            44.30681,
            0
          ],
          [
            -111.45491,
            44.31255,
            0
          ],
          [
            -111.45466,
            44.31432,
            0
          ],
          [
            -111.45462,
            44.31438,
            0
          ],
          [
            -111.45461,
            44.3144,
            0
          ],
          [
            -111.4546,
            44.31444,
            0
          ],
          [
            -111.45457,
            44.31468,
            0
          ],
          [
            -111.45442,
            44.31563,
            0
          ],
          [
            -111.45427,
            44.3163,
            0
          ],
          [
            -111.45423,
            44.31651,
            0
          ],
          [
            -111.45417,
            44.31671,
            0
          ],
          [
            -111.45402,
            44.31731,
            0
          ],
          [
            -111.45399,
            44.3174,
            0
          ],
          [
            -111.45395,
            44.31749,
            0
          ],
          [
            -111.45388,
            44.31769,
            0
          ],
          [
            -111.45367,
            44.31824,
            0
          ],
          [
            -111.45358,
            44.31844,
            0
          ],
          [
            -111.45349,
            44.31866,
            0
          ],
          [
            -111.45341,
            44.31882,
            0
          ],
          [
            -111.45332,
            44.31903,
            0
          ],
          [
            -111.45302,
            44.3196,
            0
          ],
          [
            -111.45279,
            44.32001,
            0
          ],
          [
            -111.45277,
            44.3201,
            0
          ],
          [
            -111.45248,
            44.32058,
            0
          ],
          [
            -111.45217,
            44.32106,
            0
          ],
          [
            -111.45175,
            44.32163,
            0
          ],
          [
            -111.45144,
            44.32202,
            0
          ],
          [
            -111.45115,
            44.32236,
            0
          ],
          [
            -111.45058,
            44.32297,
            0
          ],
          [
            -111.4501,
            44.32343,
            0
          ],
          [
            -111.4493,
            44.32412,
            0
          ],
          [
            -111.44883,
            44.32451,
            0
          ],
          [
            -111.4486,
            44.32471,
            0
          ],
          [
            -111.44836,
            44.32493,
            0
          ],
          [
            -111.4482,
            44.32506,
            0
          ],
          [
            -111.44535,
            44.32755,
            0
          ],
          [
            -111.44512,
            44.32776,
            0
          ],
          [
            -111.44319,
            44.32938,
            0
          ],
          [
            -111.44309,
            44.32944,
            0
          ],
          [
            -111.44305,
            44.32947,
            0
          ],
          [
            -111.443,
            44.3295,
            0
          ],
          [
            -111.44177,
            44.33054,
            0
          ],
          [
            -111.44141,
            44.33089,
            0
          ],
          [
            -111.44054,
            44.33166,
            0
          ],
          [
            -111.43874,
            44.33331,
            0
          ],
          [
            -111.43873,
            44.33333,
            0
          ],
          [
            -111.43872,
            44.33334,
            0
          ],
          [
            -111.43872,
            44.33336,
            0
          ],
          [
            -111.43871,
            44.3334,
            0
          ],
          [
            -111.43859,
            44.3335,
            0
          ],
          [
            -111.43847,
            44.33362,
            0
          ],
          [
            -111.4379,
            44.33412,
            0
          ],
          [
            -111.43685,
            44.33508,
            0
          ],
          [
            -111.43122,
            44.34012,
            0
          ],
          [
            -111.43097,
            44.34033,
            0
          ],
          [
            -111.42864,
            44.3421,
            0
          ],
          [
            -111.42797,
            44.34263,
            0
          ],
          [
            -111.42772,
            44.34281,
            0
          ],
          [
            -111.42381,
            44.34576,
            0
          ],
          [
            -111.42348,
            44.34602,
            0
          ],
          [
            -111.42237,
            44.34684,
            0
          ],
          [
            -111.42204,
            44.3471,
            0
          ],
          [
            -111.42069,
            44.34812,
            0
          ],
          [
            -111.42026,
            44.34843,
            0
          ],
          [
            -111.41848,
            44.34979,
            0
          ],
          [
            -111.41733,
            44.35064,
            0
          ],
          [
            -111.41683,
            44.35103,
            0
          ],
          [
            -111.40954,
            44.35651,
            0
          ],
          [
            -111.40879,
            44.35709,
            0
          ],
          [
            -111.4071,
            44.35835,
            0
          ],
          [
            -111.40706,
            44.35839,
            0
          ],
          [
            -111.4065,
            44.35882,
            0
          ],
          [
            -111.40565,
            44.35955,
            0
          ],
          [
            -111.4049,
            44.36028,
            0
          ],
          [
            -111.40473,
            44.36046,
            0
          ],
          [
            -111.40413,
            44.36114,
            0
          ],
          [
            -111.40398,
            44.36132,
            0
          ],
          [
            -111.40368,
            44.36171,
            0
          ],
          [
            -111.40362,
            44.36178,
            0
          ],
          [
            -111.40308,
            44.36255,
            0
          ],
          [
            -111.40274,
            44.36308,
            0
          ],
          [
            -111.40234,
            44.36375,
            0
          ],
          [
            -111.40053,
            44.36665,
            0
          ],
          [
            -111.39971,
            44.368,
            0
          ],
          [
            -111.39535,
            44.37501,
            0
          ],
          [
            -111.39524,
            44.37521,
            0
          ],
          [
            -111.39392,
            44.37737,
            0
          ],
          [
            -111.39369,
            44.37773,
            0
          ],
          [
            -111.39364,
            44.3778,
            0
          ],
          [
            -111.38418,
            44.39308,
            0
          ],
          [
            -111.38393,
            44.39347,
            0
          ],
          [
            -111.37796,
            44.40313,
            0
          ],
          [
            -111.37784,
            44.40331,
            0
          ],
          [
            -111.37766,
            44.4036,
            0
          ],
          [
            -111.37756,
            44.4037,
            0
          ],
          [
            -111.37752,
            44.40378,
            0
          ],
          [
            -111.37745,
            44.4039,
            0
          ],
          [
            -111.3773,
            44.40412,
            0
          ],
          [
            -111.37679,
            44.40495,
            0
          ],
          [
            -111.37625,
            44.40578,
            0
          ],
          [
            -111.37612,
            44.40601,
            0
          ],
          [
            -111.37598,
            44.40624,
            0
          ],
          [
            -111.37587,
            44.40645,
            0
          ],
          [
            -111.37566,
            44.40681,
            0
          ],
          [
            -111.37554,
            44.40699,
            0
          ],
          [
            -111.37542,
            44.4072,
            0
          ],
          [
            -111.37522,
            44.40759,
            0
          ],
          [
            -111.37507,
            44.40791,
            0
          ],
          [
            -111.37499,
            44.4081,
            0
          ],
          [
            -111.37487,
            44.40841,
            0
          ],
          [
            -111.37476,
            44.40873,
            0
          ],
          [
            -111.37476,
            44.40876,
            0
          ],
          [
            -111.37475,
            44.40884,
            0
          ],
          [
            -111.37464,
            44.40914,
            0
          ],
          [
            -111.37447,
            44.40973,
            0
          ],
          [
            -111.37127,
            44.423,
            0
          ],
          [
            -111.37093,
            44.42435,
            0
          ]
        ],
			},
			id: "86cd5e29-e6eb-4279-b976-7e5546c42965",
		},
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -111.448288,
    //       44.0713503,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Ashton, ID"
    //   },
    //   id: "d24521db-6365-4692-8dcc-b4636bc0070c"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -111.3709332,
    //       44.4243477,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Island Park, ID"
    //   },
    //   id: "04a37f8a-a30d-41f1-8194-3cd284d24aa3"
    // }
	],
};

export default MesaFalls;
