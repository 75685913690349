import React from "react";

export default class IconMapMarkerCircle extends React.Component {
	render() {
		return (
			<>
			    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49">
                    <defs>
                        <filter id="Ellipse_13" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse">
                            <feOffset></feOffset>
                            <feGaussianBlur stdDeviation="3" result="blur"></feGaussianBlur>
                            <feFlood floodOpacity="0.345"></feFlood>
                            <feComposite operator="in" in2="blur"></feComposite>
                            <feComposite in="SourceGraphic"></feComposite>
                        </filter>
                    </defs>
                    <g id="Component_2_48" data-name="Component 2 – 48" transform="translate(9 9)">
                        <g transform="matrix(1, 0, 0, 1, -9, -9)" filter="url(#Ellipse_13)">
                            <circle id="Ellipse_13-2" data-name="Ellipse 13" cx="15.5" cy="15.5" r="15.5" transform="translate(9 9)" fill="#fff"></circle>
                        </g>
                        <g id="State_Park" data-name="State Parks" transform="translate(8.135 7.984)">
                            <g id="Group_490" data-name="Group 490" transform="translate(0 0)">
                                <path id="Path_1504" data-name="Path 1504" d="M14.724,12.618,11.648,3.106a.233.233,0,0,0-.211-.165.277.277,0,0,0-.233.165L9.889,7.121,7.6.143a.237.237,0,0,0-.444.023L4.88,7.023,3.632,3.106a.233.233,0,0,0-.211-.165.224.224,0,0,0-.211.165l-3.2,9.512a.235.235,0,0,0,.023.211.218.218,0,0,0,.188.09H2.437V14.8a.217.217,0,0,0,.233.233H4.031a.217.217,0,0,0,.233-.233v-1.88H6.429V14.8a.234.234,0,0,0,.233.233H8.076a.234.234,0,0,0,.233-.233v-1.88h2.113V14.8a.217.217,0,0,0,.233.233h1.361a.217.217,0,0,0,.233-.233v-1.88h2.233a.3.3,0,0,0,.188-.09A.229.229,0,0,0,14.724,12.618ZM.557,12.475,3.4,3.925l1.218,3.85-1.549,4.7Zm2.346,2.1V12.926H3.8v1.647Zm4.963,0h-.94V12.926h.94Zm.444-2.121H3.565L7.369.985,9.625,7.873l1.5,4.579ZM10.9,14.573V12.926h.895v1.647Zm.729-2.121-1.5-4.6L11.415,3.9l2.775,8.55Z" transform="translate(0)"></path>
                                <path id="Path_1505" data-name="Path 1505" d="M14.763,21.5H10.9c-.131,0-.213.105-.213.271a.247.247,0,0,0,.213.271h3.862c.131,0,.213-.105.213-.271S14.893,21.5,14.763,21.5Z" transform="translate(-5.466 -11.076)"></path>
                                <path id="Path_1514" data-name="Path 1514" d="M14.246,21.5H10.874c-.114,0-.186.105-.186.271s.1.271.186.271h3.372c.114,0,.186-.105.186-.271S14.36,21.5,14.246,21.5Z" transform="translate(-5.195 -13.076)"></path>
                                <path id="Path_1515" data-name="Path 1515" d="M13.153,21.5H10.817c-.079,0-.129.105-.129.271s.067.271.129.271h2.336c.079,0,.129-.105.129-.271S13.232,21.5,13.153,21.5Z" transform="translate(-4.62 -15.076)"></path>
                            </g>
                        </g>
                    </g>
                </svg>
			</>
		);
	}
}
