import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconArrowDown, IconArrowUp, IconCheck } from "../../images/icons";
import { updateByways } from "../../store/actions/bywaysActions";

const FilterDropdown = (props) => {
  const groupedOptions = props.groupedOptions;

  const dispatch = useDispatch();
  const byways = useSelector((state) => state.byways.selectedByways);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleDropdown = () => {
    props.toggleFilterDropdown();
  };

  const selectAll = () => {
    let tempArray = [];
    groupedOptions.flatMap((group) => group.options).map((option) => { 
        tempArray.push(option.value);
    });
    dispatch(updateByways(tempArray));
    //console.log(tempArray);
  };

  const clearAll = () => {
    dispatch(updateByways([]));
  };
 
  const setBywayActive = (selectedByway) => {
    const selectedValues = selectedByway.split(',');
    const updatedByways = selectedValues.reduce((acc, byway) => {
      if (byways.includes(byway)) {
        acc = acc.filter((item) => item !== byway);
      } else {
        acc.push(byway);
      }
      return acc;
    }, [...byways]);
  
    dispatch(updateByways(updatedByways));
  }

  const filteredGroups = groupedOptions.filter((group) =>
    group.options.some((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const renderGroup = (group) => {
    const filteredByways = group.options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    if (filteredByways.length === 0) {
      return null; // hide the region if there are no matching byways
    }
  
    return (
      <div key={group.label}>
        <h3>{group.label}</h3>
        {filteredByways.map((byway, index) => (
          <a
            key={byway.value+'-'+index}
            onClick={() => setBywayActive(byway.value)}
            className={`byway-item ${
              byway.value.split(",").every((v) => byways.includes(v))
                ? "active"
                : ""
            }`}
          >
            <p style={{display:'flex',gap:'.25rem'}}>
              {byway.value.split(",").every((v) => byways.includes(v)) && <IconCheck width={12} height={12}/>}
              {byway.label}
            </p>
          </a>
        ))}
      </div>
    );
  };
  

  return (
    <div className="dropdown">
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        Scenic Byways {props.showFilterDropdown ? <IconArrowDown /> : <IconArrowUp/>} 
      </button>
      {props.showFilterDropdown && (
        <div className="dropdown-menu">
          <div className="dropdown-menu-header">
            <input
              type="text"
              className="dropdown-search"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="dropdown-actions">
              <button onClick={selectAll}>Select All</button>
              <button onClick={clearAll}>Clear All</button>
            </div>
          </div>
          <div className="dropdown-options">{filteredGroups.map(renderGroup)}</div>
        </div>
      )}
    </div>
  );
};


export default FilterDropdown;
