const Sawtooth = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Sawtooth Scenic Byway",
				description: "Rugged mountain peaks are the star of the show on the Sawtooth Scenic Byway. This route carves its way through the Boulder Mountains, delivering adventurers to the base of the iconic Sawtooth Mountain Range. Keep an eye out for local wildlife like deer, elk, and pronghorn antelope and plan stops along the way to take advantage of the abundant recreation opportunities. When the sun sets, the night skies will take your breath away.",
				link: "https://visitidaho.org/things-to-do/road-trips/sawtooth-scenic-byway/",
				route: [
					"Stanley", 
					"Shoshone", 
					"Ketchum",
					"Sun Valley",
					"Hailey",
					"Bellevue"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
					[-114.93005, 44.21626, 0],
					[-114.93016, 44.21575, 0],
					[-114.93019, 44.21566, 0],
					[-114.93041, 44.21474, 0],
					[-114.93041, 44.21473, 0],
					[-114.93115, 44.21192, 0],
					[-114.93122, 44.21168, 0],
					[-114.93278, 44.20573, 0],
					[-114.93292, 44.2051, 0],
					[-114.93305, 44.20431, 0],
					[-114.93309, 44.20397, 0],
					[-114.9331, 44.20377, 0],
					[-114.93312, 44.20356, 0],
					[-114.93314, 44.20313, 0],
					[-114.93314, 44.20242, 0],
					[-114.93308, 44.20171, 0],
					[-114.933, 44.20115, 0],
					[-114.933, 44.20114, 0],
					[-114.93272, 44.19949, 0],
					[-114.93266, 44.19884, 0],
					[-114.93265, 44.19848, 0],
					[-114.93265, 44.1979, 0],
					[-114.93267, 44.19767, 0],
					[-114.93269, 44.19728, 0],
					[-114.93272, 44.19691, 0],
					[-114.93276, 44.19613, 0],
					[-114.93276, 44.19559, 0],
					[-114.93275, 44.19547, 0],
					[-114.93275, 44.19541, 0],
					[-114.93274, 44.19537, 0],
					[-114.93274, 44.19528, 0],
					[-114.93268, 44.1948, 0],
					[-114.93263, 44.19448, 0],
					[-114.93256, 44.19411, 0],
					[-114.93253, 44.19398, 0],
					[-114.93233, 44.19335, 0],
					[-114.93227, 44.1932, 0],
					[-114.9322, 44.193, 0],
					[-114.93212, 44.19281, 0],
					[-114.92511, 44.17794, 0],
					[-114.92504, 44.17781, 0],
					[-114.92432, 44.17631, 0],
					[-114.92424, 44.17616, 0],
					[-114.92416, 44.17603, 0],
					[-114.9241, 44.17592, 0],
					[-114.92409, 44.17591, 0],
					[-114.92403, 44.17581, 0],
					[-114.92376, 44.17544, 0],
					[-114.92361, 44.17527, 0],
					[-114.92345, 44.17511, 0],
					[-114.92313, 44.17481, 0],
					[-114.92284, 44.17457, 0],
					[-114.92259, 44.1744, 0],
					[-114.92222, 44.17417, 0],
					[-114.92203, 44.17407, 0],
					[-114.92188, 44.17398, 0],
					[-114.92161, 44.17385, 0],
					[-114.92108, 44.17363, 0],
					[-114.92058, 44.17347, 0],
					[-114.9203, 44.17339, 0],
					[-114.91986, 44.17328, 0],
					[-114.91912, 44.17316, 0],
					[-114.91892, 44.17314, 0],
					[-114.91888, 44.17313, 0],
					[-114.9181, 44.17309, 0],
					[-114.91717, 44.1731, 0],
					[-114.9167, 44.17313, 0],
					[-114.9141, 44.17323, 0],
					[-114.91338, 44.17322, 0],
					[-114.91229, 44.17316, 0],
					[-114.91163, 44.17307, 0],
					[-114.9106, 44.17289, 0],
					[-114.91021, 44.1728, 0],
					[-114.9095, 44.1726, 0],
					[-114.90928, 44.17252, 0],
					[-114.90912, 44.17247, 0],
					[-114.90881, 44.17236, 0],
					[-114.90822, 44.17212, 0],
					[-114.90805, 44.17204, 0],
					[-114.90798, 44.172, 0],
					[-114.90753, 44.17179, 0],
					[-114.9073, 44.17167, 0],
					[-114.90635, 44.17114, 0],
					[-114.90492, 44.1703, 0],
					[-114.90433, 44.16998, 0],
					[-114.90387, 44.16976, 0],
					[-114.90295, 44.16938, 0],
					[-114.90216, 44.16912, 0],
					[-114.90186, 44.16904, 0],
					[-114.9016, 44.16896, 0],
					[-114.89957, 44.16841, 0],
					[-114.89897, 44.16823, 0],
					[-114.89692, 44.16767, 0],
					[-114.89691, 44.16766, 0],
					[-114.89623, 44.16748, 0],
					[-114.89622, 44.16747, 0],
					[-114.89599, 44.16741, 0],
					[-114.89594, 44.16739, 0],
					[-114.89544, 44.16725, 0],
					[-114.89506, 44.16713, 0],
					[-114.89499, 44.1671, 0],
					[-114.89485, 44.16706, 0],
					[-114.89434, 44.16688, 0],
					[-114.89247, 44.16614, 0],
					[-114.89233, 44.16607, 0],
					[-114.89189, 44.16587, 0],
					[-114.88932, 44.16461, 0],
					[-114.88587, 44.16305, 0],
					[-114.88575, 44.16299, 0],
					[-114.88563, 44.16294, 0],
					[-114.88523, 44.1627, 0],
					[-114.88508, 44.1626, 0],
					[-114.88478, 44.16238, 0],
					[-114.88465, 44.16226, 0],
					[-114.88444, 44.16205, 0],
					[-114.88438, 44.16197, 0],
					[-114.88431, 44.16189, 0],
					[-114.88428, 44.16184, 0],
					[-114.88418, 44.1617, 0],
					[-114.88416, 44.16168, 0],
					[-114.88411, 44.16158, 0],
					[-114.88405, 44.16149, 0],
					[-114.884, 44.16139, 0],
					[-114.88382, 44.16098, 0],
					[-114.87864, 44.14708, 0],
					[-114.87858, 44.1469, 0],
					[-114.87709, 44.1429, 0],
					[-114.87666, 44.14182, 0],
					[-114.87648, 44.14131, 0],
					[-114.87483, 44.13692, 0],
					[-114.87463, 44.13634, 0],
					[-114.87432, 44.13554, 0],
					[-114.87432, 44.13552, 0],
					[-114.87354, 44.13345, 0],
					[-114.87322, 44.13268, 0],
					[-114.87298, 44.13215, 0],
					[-114.87252, 44.13123, 0],
					[-114.87249, 44.13118, 0],
					[-114.871, 44.12818, 0],
					[-114.87064, 44.12749, 0],
					[-114.86853, 44.12324, 0],
					[-114.86813, 44.1225, 0],
					[-114.86664, 44.11955, 0],
					[-114.86636, 44.11902, 0],
					[-114.86594, 44.11827, 0],
					[-114.86539, 44.11734, 0],
					[-114.86434, 44.1157, 0],
					[-114.86418, 44.11543, 0],
					[-114.864, 44.11515, 0],
					[-114.86386, 44.11491, 0],
					[-114.86345, 44.11426, 0],
					[-114.86326, 44.11394, 0],
					[-114.8623, 44.11245, 0],
					[-114.8618, 44.11161, 0],
					[-114.86154, 44.11113, 0],
					[-114.86117, 44.11036, 0],
					[-114.86002, 44.10753, 0],
					[-114.85981, 44.10706, 0],
					[-114.85659, 44.09923, 0],
					[-114.85654, 44.09913, 0],
					[-114.8565, 44.09903, 0],
					[-114.85634, 44.09869, 0],
					[-114.85626, 44.09847, 0],
					[-114.85607, 44.09803, 0],
					[-114.85584, 44.09742, 0],
					[-114.85422, 44.09354, 0],
					[-114.85419, 44.09348, 0],
					[-114.85384, 44.09261, 0],
					[-114.85382, 44.09258, 0],
					[-114.85063, 44.08486, 0],
					[-114.85003, 44.08335, 0],
					[-114.84983, 44.08289, 0],
					[-114.84967, 44.08256, 0],
					[-114.84703, 44.07757, 0],
					[-114.84675, 44.07707, 0],
					[-114.84401, 44.07192, 0],
					[-114.84395, 44.07179, 0],
					[-114.84249, 44.06904, 0],
					[-114.84228, 44.06862, 0],
					[-114.83578, 44.05638, 0],
					[-114.83484, 44.05465, 0],
					[-114.83427, 44.05356, 0],
					[-114.83415, 44.0533, 0],
					[-114.83182, 44.04895, 0],
					[-114.83156, 44.04843, 0],
					[-114.83144, 44.04821, 0],
					[-114.83141, 44.04813, 0],
					[-114.83136, 44.04804, 0],
					[-114.83118, 44.04762, 0],
					[-114.83116, 44.04754, 0],
					[-114.83112, 44.04743, 0],
					[-114.83108, 44.04729, 0],
					[-114.83106, 44.0472, 0],
					[-114.83104, 44.04716, 0],
					[-114.83095, 44.0468, 0],
					[-114.83091, 44.04659, 0],
					[-114.83087, 44.04632, 0],
					[-114.83086, 44.04619, 0],
					[-114.83084, 44.04605, 0],
					[-114.83082, 44.04571, 0],
					[-114.83082, 44.04552, 0],
					[-114.83083, 44.04541, 0],
					[-114.83083, 44.04525, 0],
					[-114.83084, 44.04517, 0],
					[-114.83085, 44.04501, 0],
					[-114.83089, 44.04473, 0],
					[-114.8309, 44.0447, 0],
					[-114.83113, 44.04348, 0],
					[-114.83144, 44.04203, 0],
					[-114.83172, 44.04061, 0],
					[-114.83179, 44.0403, 0],
					[-114.83197, 44.03936, 0],
					[-114.8329, 44.03486, 0],
					[-114.83293, 44.03468, 0],
					[-114.83323, 44.03329, 0],
					[-114.83324, 44.03318, 0],
					[-114.83326, 44.03308, 0],
					[-114.83331, 44.0327, 0],
					[-114.83332, 44.03268, 0],
					[-114.83334, 44.03253, 0],
					[-114.83335, 44.03236, 0],
					[-114.83337, 44.03226, 0],
					[-114.83337, 44.03216, 0],
					[-114.83339, 44.03199, 0],
					[-114.83341, 44.03148, 0],
					[-114.83341, 44.03118, 0],
					[-114.83338, 44.03021, 0],
					[-114.83336, 44.02725, 0],
					[-114.83337, 44.02521, 0],
					[-114.83331, 44.02286, 0],
					[-114.83332, 44.0209, 0],
					[-114.8333, 44.01946, 0],
					[-114.83331, 44.01892, 0],
					[-114.83307, 43.99056, 0],
					[-114.83306, 43.99032, 0],
					[-114.83307, 43.99016, 0],
					[-114.83306, 43.98983, 0],
					[-114.83305, 43.98804, 0],
					[-114.83302, 43.98672, 0],
					[-114.833, 43.98644, 0],
					[-114.833, 43.98633, 0],
					[-114.83296, 43.98592, 0],
					[-114.83296, 43.98589, 0],
					[-114.83294, 43.98579, 0],
					[-114.83286, 43.98522, 0],
					[-114.83274, 43.98465, 0],
					[-114.83264, 43.98423, 0],
					[-114.8326, 43.98411, 0],
					[-114.83258, 43.98401, 0],
					[-114.83252, 43.98381, 0],
					[-114.83226, 43.98306, 0],
					[-114.8322, 43.98292, 0],
					[-114.83216, 43.98281, 0],
					[-114.83209, 43.98266, 0],
					[-114.83204, 43.98253, 0],
					[-114.83179, 43.98199, 0],
					[-114.81822, 43.95594, 0],
					[-114.81814, 43.9558, 0],
					[-114.81328, 43.94647, 0],
					[-114.81327, 43.94644, 0],
					[-114.81312, 43.94613, 0],
					[-114.81308, 43.94606, 0],
					[-114.81299, 43.94588, 0],
					[-114.81282, 43.9455, 0],
					[-114.81268, 43.94514, 0],
					[-114.81252, 43.94465, 0],
					[-114.8124, 43.94417, 0],
					[-114.81235, 43.94391, 0],
					[-114.81234, 43.94383, 0],
					[-114.81226, 43.94338, 0],
					[-114.81223, 43.94307, 0],
					[-114.81223, 43.94297, 0],
					[-114.81222, 43.94281, 0],
					[-114.81222, 43.94228, 0],
					[-114.81227, 43.94159, 0],
					[-114.81229, 43.94148, 0],
					[-114.81234, 43.94111, 0],
					[-114.81237, 43.94098, 0],
					[-114.8124, 43.9408, 0],
					[-114.81251, 43.94035, 0],
					[-114.81254, 43.94025, 0],
					[-114.81281, 43.93947, 0],
					[-114.81353, 43.93751, 0],
					[-114.81378, 43.93687, 0],
					[-114.81384, 43.93669, 0],
					[-114.81389, 43.93657, 0],
					[-114.81391, 43.9365, 0],
					[-114.81394, 43.93644, 0],
					[-114.81407, 43.93613, 0],
					[-114.81424, 43.93578, 0],
					[-114.81454, 43.93523, 0],
					[-114.8147, 43.93498, 0],
					[-114.81534, 43.9341, 0],
					[-114.81603, 43.93321, 0],
					[-114.81689, 43.93214, 0],
					[-114.81702, 43.93194, 0],
					[-114.81705, 43.9319, 0],
					[-114.81707, 43.93186, 0],
					[-114.81711, 43.9318, 0],
					[-114.81719, 43.93164, 0],
					[-114.81721, 43.93159, 0],
					[-114.81725, 43.93151, 0],
					[-114.81733, 43.93129, 0],
					[-114.81736, 43.93118, 0],
					[-114.81737, 43.93116, 0],
					[-114.8179, 43.92959, 0],
					[-114.81799, 43.9293, 0],
					[-114.81831, 43.92836, 0],
					[-114.81833, 43.92829, 0],
					[-114.81852, 43.92775, 0],
					[-114.81864, 43.92734, 0],
					[-114.81866, 43.92729, 0],
					[-114.81867, 43.92723, 0],
					[-114.81869, 43.92718, 0],
					[-114.8187, 43.92713, 0],
					[-114.81871, 43.92705, 0],
					[-114.81873, 43.92695, 0],
					[-114.81873, 43.92689, 0],
					[-114.81874, 43.92679, 0],
					[-114.81874, 43.92672, 0],
					[-114.81875, 43.92666, 0],
					[-114.81874, 43.92658, 0],
					[-114.81874, 43.92651, 0],
					[-114.81873, 43.92644, 0],
					[-114.81873, 43.92637, 0],
					[-114.81872, 43.92631, 0],
					[-114.81871, 43.92627, 0],
					[-114.81871, 43.92623, 0],
					[-114.81868, 43.9261, 0],
					[-114.81862, 43.9259, 0],
					[-114.81859, 43.92585, 0],
					[-114.81858, 43.9258, 0],
					[-114.81856, 43.92576, 0],
					[-114.81854, 43.92571, 0],
					[-114.81852, 43.92567, 0],
					[-114.8185, 43.92564, 0],
					[-114.81849, 43.92561, 0],
					[-114.81847, 43.92557, 0],
					[-114.81845, 43.92554, 0],
					[-114.8184, 43.92545, 0],
					[-114.81824, 43.9252, 0],
					[-114.81011, 43.91341, 0],
					[-114.81002, 43.91329, 0],
					[-114.81, 43.91327, 0],
					[-114.80989, 43.91313, 0],
					[-114.80987, 43.91312, 0],
					[-114.80982, 43.91306, 0],
					[-114.80969, 43.91293, 0],
					[-114.80966, 43.91291, 0],
					[-114.8096, 43.91285, 0],
					[-114.80953, 43.9128, 0],
					[-114.8095, 43.91277, 0],
					[-114.80943, 43.91273, 0],
					[-114.80929, 43.91262, 0],
					[-114.8092, 43.91257, 0],
					[-114.80916, 43.91254, 0],
					[-114.80904, 43.91248, 0],
					[-114.80899, 43.91245, 0],
					[-114.80893, 43.91242, 0],
					[-114.80871, 43.91233, 0],
					[-114.80834, 43.91216, 0],
					[-114.80366, 43.91016, 0],
					[-114.80338, 43.91005, 0],
					[-114.79678, 43.90726, 0],
					[-114.79677, 43.90726, 0],
					[-114.79626, 43.90704, 0],
					[-114.79592, 43.90688, 0],
					[-114.79582, 43.90684, 0],
					[-114.79574, 43.9068, 0],
					[-114.79548, 43.90665, 0],
					[-114.79534, 43.90656, 0],
					[-114.79527, 43.90653, 0],
					[-114.79522, 43.90649, 0],
					[-114.79518, 43.90647, 0],
					[-114.79477, 43.90617, 0],
					[-114.79446, 43.90588, 0],
					[-114.7944, 43.90581, 0],
					[-114.79434, 43.90575, 0],
					[-114.79428, 43.90567, 0],
					[-114.79413, 43.90549, 0],
					[-114.79395, 43.90525, 0],
					[-114.7906, 43.90038, 0],
					[-114.79049, 43.90023, 0],
					[-114.78851, 43.89734, 0],
					[-114.7883, 43.89705, 0],
					[-114.78566, 43.89322, 0],
					[-114.78543, 43.89287, 0],
					[-114.78541, 43.89283, 0],
					[-114.7853, 43.89266, 0],
					[-114.78498, 43.89209, 0],
					[-114.78278, 43.88784, 0],
					[-114.7826, 43.88751, 0],
					[-114.78227, 43.887, 0],
					[-114.78201, 43.88668, 0],
					[-114.7819, 43.88657, 0],
					[-114.78184, 43.8865, 0],
					[-114.78171, 43.88637, 0],
					[-114.78165, 43.88632, 0],
					[-114.7816, 43.88626, 0],
					[-114.78147, 43.88615, 0],
					[-114.78141, 43.88609, 0],
					[-114.78134, 43.88604, 0],
					[-114.78125, 43.88596, 0],
					[-114.7812, 43.88593, 0],
					[-114.78118, 43.88591, 0],
					[-114.78084, 43.88566, 0],
					[-114.78042, 43.8854, 0],
					[-114.78013, 43.88524, 0],
					[-114.77953, 43.88496, 0],
					[-114.77941, 43.88492, 0],
					[-114.77927, 43.88486, 0],
					[-114.77898, 43.88476, 0],
					[-114.77891, 43.88473, 0],
					[-114.77858, 43.88463, 0],
					[-114.77857, 43.88463, 0],
					[-114.77849, 43.88461, 0],
					[-114.77844, 43.88459, 0],
					[-114.77805, 43.88449, 0],
					[-114.77741, 43.88437, 0],
					[-114.77731, 43.88436, 0],
					[-114.77715, 43.88433, 0],
					[-114.77659, 43.88428, 0],
					[-114.77653, 43.88428, 0],
					[-114.77631, 43.88426, 0],
					[-114.77619, 43.88426, 0],
					[-114.77602, 43.88425, 0],
					[-114.77565, 43.88425, 0],
					[-114.7754, 43.88424, 0],
					[-114.77124, 43.88421, 0],
					[-114.77041, 43.88419, 0],
					[-114.76498, 43.88414, 0],
					[-114.76479, 43.88413, 0],
					[-114.76176, 43.88409, 0],
					[-114.75984, 43.8841, 0],
					[-114.75973, 43.88411, 0],
					[-114.75949, 43.88411, 0],
					[-114.75941, 43.88412, 0],
					[-114.75933, 43.88412, 0],
					[-114.75922, 43.88413, 0],
					[-114.75913, 43.88413, 0],
					[-114.75896, 43.88415, 0],
					[-114.75884, 43.88417, 0],
					[-114.75874, 43.88418, 0],
					[-114.75853, 43.88422, 0],
					[-114.75844, 43.88423, 0],
					[-114.75801, 43.88432, 0],
					[-114.75779, 43.88438, 0],
					[-114.75769, 43.8844, 0],
					[-114.75722, 43.88454, 0],
					[-114.75689, 43.88465, 0],
					[-114.75675, 43.88471, 0],
					[-114.75671, 43.88472, 0],
					[-114.75665, 43.88475, 0],
					[-114.75661, 43.88476, 0],
					[-114.75657, 43.88478, 0],
					[-114.75647, 43.88482, 0],
					[-114.75641, 43.88485, 0],
					[-114.75635, 43.88487, 0],
					[-114.75621, 43.88494, 0],
					[-114.75611, 43.88498, 0],
					[-114.75594, 43.88507, 0],
					[-114.75579, 43.88514, 0],
					[-114.7557, 43.8852, 0],
					[-114.75541, 43.88537, 0],
					[-114.7553, 43.88545, 0],
					[-114.75522, 43.8855, 0],
					[-114.75513, 43.88557, 0],
					[-114.75505, 43.88562, 0],
					[-114.75453, 43.88604, 0],
					[-114.75236, 43.88795, 0],
					[-114.75223, 43.88805, 0],
					[-114.7521, 43.88817, 0],
					[-114.75185, 43.88838, 0],
					[-114.75178, 43.88845, 0],
					[-114.75171, 43.8885, 0],
					[-114.75167, 43.88855, 0],
					[-114.75151, 43.88871, 0],
					[-114.75117, 43.88901, 0],
					[-114.75067, 43.88943, 0],
					[-114.75016, 43.88983, 0],
					[-114.75011, 43.88988, 0],
					[-114.75006, 43.88992, 0],
					[-114.74998, 43.89, 0],
					[-114.74994, 43.89005, 0],
					[-114.74992, 43.89008, 0],
					[-114.74989, 43.8901, 0],
					[-114.74982, 43.89019, 0],
					[-114.74977, 43.89024, 0],
					[-114.74972, 43.8903, 0],
					[-114.74948, 43.89054, 0],
					[-114.74916, 43.89089, 0],
					[-114.74908, 43.89099, 0],
					[-114.74903, 43.89104, 0],
					[-114.74888, 43.89123, 0],
					[-114.74853, 43.89171, 0],
					[-114.74845, 43.89183, 0],
					[-114.74821, 43.89214, 0],
					[-114.74817, 43.8922, 0],
					[-114.74811, 43.89227, 0],
					[-114.74806, 43.89232, 0],
					[-114.74784, 43.89257, 0],
					[-114.74776, 43.89265, 0],
					[-114.74776, 43.89266, 0],
					[-114.74742, 43.89298, 0],
					[-114.74728, 43.89309, 0],
					[-114.7472, 43.89316, 0],
					[-114.74714, 43.89319, 0],
					[-114.74708, 43.89324, 0],
					[-114.74685, 43.89339, 0],
					[-114.74658, 43.89355, 0],
					[-114.74645, 43.89362, 0],
					[-114.74638, 43.89367, 0],
					[-114.74632, 43.8937, 0],
					[-114.74628, 43.89373, 0],
					[-114.74624, 43.89375, 0],
					[-114.74622, 43.89377, 0],
					[-114.7461, 43.89386, 0],
					[-114.74606, 43.89388, 0],
					[-114.74596, 43.89395, 0],
					[-114.74588, 43.89399, 0],
					[-114.74531, 43.89433, 0],
					[-114.74525, 43.89436, 0],
					[-114.74519, 43.8944, 0],
					[-114.74507, 43.89446, 0],
					[-114.74484, 43.89455, 0],
					[-114.7448, 43.89456, 0],
					[-114.74479, 43.89457, 0],
					[-114.74474, 43.89458, 0],
					[-114.74472, 43.89459, 0],
					[-114.7445, 43.89465, 0],
					[-114.74443, 43.89466, 0],
					[-114.74439, 43.89467, 0],
					[-114.74434, 43.89467, 0],
					[-114.74423, 43.89469, 0],
					[-114.74417, 43.89469, 0],
					[-114.74411, 43.8947, 0],
					[-114.74397, 43.8947, 0],
					[-114.74392, 43.89471, 0],
					[-114.74378, 43.89471, 0],
					[-114.74374, 43.8947, 0],
					[-114.74367, 43.8947, 0],
					[-114.74364, 43.89469, 0],
					[-114.7436, 43.89469, 0],
					[-114.74358, 43.89468, 0],
					[-114.74355, 43.89468, 0],
					[-114.74349, 43.89466, 0],
					[-114.74335, 43.89463, 0],
					[-114.7432, 43.89459, 0],
					[-114.74309, 43.89454, 0],
					[-114.74305, 43.89453, 0],
					[-114.74295, 43.89449, 0],
					[-114.74291, 43.89446, 0],
					[-114.74284, 43.89443, 0],
					[-114.74278, 43.89439, 0],
					[-114.74276, 43.89438, 0],
					[-114.74259, 43.89426, 0],
					[-114.74253, 43.89421, 0],
					[-114.7424, 43.89408, 0],
					[-114.74236, 43.89402, 0],
					[-114.74232, 43.89397, 0],
					[-114.74228, 43.89391, 0],
					[-114.74226, 43.89385, 0],
					[-114.74222, 43.89379, 0],
					[-114.74221, 43.89374, 0],
					[-114.74218, 43.89367, 0],
					[-114.74217, 43.89364, 0],
					[-114.74217, 43.89361, 0],
					[-114.74215, 43.89355, 0],
					[-114.74215, 43.89351, 0],
					[-114.74214, 43.89348, 0],
					[-114.74214, 43.89327, 0],
					[-114.74218, 43.89305, 0],
					[-114.74223, 43.89291, 0],
					[-114.7423, 43.89277, 0],
					[-114.74231, 43.89274, 0],
					[-114.74235, 43.89268, 0],
					[-114.74236, 43.89266, 0],
					[-114.74239, 43.89263, 0],
					[-114.74241, 43.89259, 0],
					[-114.74244, 43.89255, 0],
					[-114.74247, 43.89252, 0],
					[-114.74251, 43.89247, 0],
					[-114.7426, 43.89238, 0],
					[-114.7427, 43.8923, 0],
					[-114.74275, 43.89225, 0],
					[-114.74278, 43.89224, 0],
					[-114.7428, 43.89222, 0],
					[-114.74288, 43.89218, 0],
					[-114.74291, 43.89216, 0],
					[-114.743, 43.89211, 0],
					[-114.74303, 43.89208, 0],
					[-114.74305, 43.89208, 0],
					[-114.74307, 43.89206, 0],
					[-114.7431, 43.89205, 0],
					[-114.74314, 43.89202, 0],
					[-114.74328, 43.89195, 0],
					[-114.74331, 43.89193, 0],
					[-114.74332, 43.89193, 0],
					[-114.74336, 43.89189, 0],
					[-114.74338, 43.89188, 0],
					[-114.74341, 43.89186, 0],
					[-114.74347, 43.89181, 0],
					[-114.74353, 43.89178, 0],
					[-114.74372, 43.89164, 0],
					[-114.74376, 43.8916, 0],
					[-114.74381, 43.89157, 0],
					[-114.74404, 43.89134, 0],
					[-114.74406, 43.89131, 0],
					[-114.74409, 43.89128, 0],
					[-114.74415, 43.89119, 0],
					[-114.74416, 43.89118, 0],
					[-114.74421, 43.89111, 0],
					[-114.74424, 43.89105, 0],
					[-114.74427, 43.89101, 0],
					[-114.74429, 43.89097, 0],
					[-114.7443, 43.89094, 0],
					[-114.74432, 43.89091, 0],
					[-114.74435, 43.89085, 0],
					[-114.74435, 43.89084, 0],
					[-114.74437, 43.89077, 0],
					[-114.74439, 43.89073, 0],
					[-114.7444, 43.89069, 0],
					[-114.7444, 43.89067, 0],
					[-114.74443, 43.89053, 0],
					[-114.74443, 43.89047, 0],
					[-114.74444, 43.89041, 0],
					[-114.74444, 43.89015, 0],
					[-114.74443, 43.89011, 0],
					[-114.74443, 43.89007, 0],
					[-114.74441, 43.88996, 0],
					[-114.74434, 43.88973, 0],
					[-114.74432, 43.88968, 0],
					[-114.74428, 43.8896, 0],
					[-114.74426, 43.88954, 0],
					[-114.74402, 43.8891, 0],
					[-114.7417, 43.8851, 0],
					[-114.74164, 43.88498, 0],
					[-114.74162, 43.88492, 0],
					[-114.7416, 43.88488, 0],
					[-114.74158, 43.88481, 0],
					[-114.74156, 43.88476, 0],
					[-114.74151, 43.8846, 0],
					[-114.74151, 43.88459, 0],
					[-114.7415, 43.88455, 0],
					[-114.74149, 43.88449, 0],
					[-114.74147, 43.88443, 0],
					[-114.74147, 43.8844, 0],
					[-114.74146, 43.88436, 0],
					[-114.74146, 43.88431, 0],
					[-114.74145, 43.88426, 0],
					[-114.74143, 43.88352, 0],
					[-114.74142, 43.88347, 0],
					[-114.74142, 43.88344, 0],
					[-114.74141, 43.88341, 0],
					[-114.74141, 43.88333, 0],
					[-114.74139, 43.88326, 0],
					[-114.74139, 43.88324, 0],
					[-114.74138, 43.88322, 0],
					[-114.74138, 43.88319, 0],
					[-114.74136, 43.88315, 0],
					[-114.74135, 43.88312, 0],
					[-114.74133, 43.88308, 0],
					[-114.74132, 43.88305, 0],
					[-114.7413, 43.88301, 0],
					[-114.74129, 43.88298, 0],
					[-114.74127, 43.88294, 0],
					[-114.74124, 43.8829, 0],
					[-114.74121, 43.88285, 0],
					[-114.74117, 43.88281, 0],
					[-114.74115, 43.88277, 0],
					[-114.74107, 43.88269, 0],
					[-114.74105, 43.88266, 0],
					[-114.74101, 43.88263, 0],
					[-114.74098, 43.88259, 0],
					[-114.7409, 43.88253, 0],
					[-114.74088, 43.88251, 0],
					[-114.74085, 43.8825, 0],
					[-114.74081, 43.88247, 0],
					[-114.7407, 43.8824, 0],
					[-114.7404, 43.88226, 0],
					[-114.74033, 43.88224, 0],
					[-114.74029, 43.88222, 0],
					[-114.74025, 43.88221, 0],
					[-114.74014, 43.88217, 0],
					[-114.73961, 43.88202, 0],
					[-114.73954, 43.88199, 0],
					[-114.73932, 43.88193, 0],
					[-114.7383, 43.88162, 0],
					[-114.73796, 43.88153, 0],
					[-114.73564, 43.88082, 0],
					[-114.73475, 43.88053, 0],
					[-114.73456, 43.88048, 0],
					[-114.73434, 43.88041, 0],
					[-114.73429, 43.88039, 0],
					[-114.73424, 43.88038, 0],
					[-114.73397, 43.8803, 0],
					[-114.73354, 43.8802, 0],
					[-114.73319, 43.88014, 0],
					[-114.73302, 43.88012, 0],
					[-114.73295, 43.88012, 0],
					[-114.7329, 43.88011, 0],
					[-114.73266, 43.88009, 0],
					[-114.73256, 43.88009, 0],
					[-114.7325, 43.88008, 0],
					[-114.7324, 43.88008, 0],
					[-114.73231, 43.88007, 0],
					[-114.73189, 43.88007, 0],
					[-114.73175, 43.88008, 0],
					[-114.73154, 43.88008, 0],
					[-114.73149, 43.88009, 0],
					[-114.73139, 43.88009, 0],
					[-114.73127, 43.88011, 0],
					[-114.73118, 43.88012, 0],
					[-114.73111, 43.88012, 0],
					[-114.73072, 43.88019, 0],
					[-114.73065, 43.88021, 0],
					[-114.73024, 43.88028, 0],
					[-114.73016, 43.8803, 0],
					[-114.73009, 43.88031, 0],
					[-114.73004, 43.88031, 0],
					[-114.72993, 43.88033, 0],
					[-114.72987, 43.88033, 0],
					[-114.72982, 43.88034, 0],
					[-114.72977, 43.88034, 0],
					[-114.7297, 43.88035, 0],
					[-114.72932, 43.88035, 0],
					[-114.72927, 43.88034, 0],
					[-114.72923, 43.88034, 0],
					[-114.72917, 43.88033, 0],
					[-114.72913, 43.88033, 0],
					[-114.72901, 43.88031, 0],
					[-114.72879, 43.88025, 0],
					[-114.72874, 43.88024, 0],
					[-114.72862, 43.8802, 0],
					[-114.72844, 43.88013, 0],
					[-114.72812, 43.87999, 0],
					[-114.72804, 43.87996, 0],
					[-114.72779, 43.87984, 0],
					[-114.72764, 43.87979, 0],
					[-114.7276, 43.87977, 0],
					[-114.72739, 43.87971, 0],
					[-114.72735, 43.87971, 0],
					[-114.72732, 43.8797, 0],
					[-114.72729, 43.8797, 0],
					[-114.72725, 43.87969, 0],
					[-114.72721, 43.87969, 0],
					[-114.72718, 43.87968, 0],
					[-114.72697, 43.87968, 0],
					[-114.72691, 43.87969, 0],
					[-114.72686, 43.87969, 0],
					[-114.72683, 43.8797, 0],
					[-114.72679, 43.8797, 0],
					[-114.72676, 43.87971, 0],
					[-114.72671, 43.87972, 0],
					[-114.72669, 43.87973, 0],
					[-114.72664, 43.87974, 0],
					[-114.72645, 43.87981, 0],
					[-114.72639, 43.87984, 0],
					[-114.72636, 43.87985, 0],
					[-114.72595, 43.8801, 0],
					[-114.72577, 43.88022, 0],
					[-114.72572, 43.88024, 0],
					[-114.72569, 43.88026, 0],
					[-114.72565, 43.88028, 0],
					[-114.72562, 43.8803, 0],
					[-114.72559, 43.88031, 0],
					[-114.72553, 43.88034, 0],
					[-114.72546, 43.88036, 0],
					[-114.72543, 43.88038, 0],
					[-114.7254, 43.88038, 0],
					[-114.72538, 43.88039, 0],
					[-114.72535, 43.8804, 0],
					[-114.72531, 43.88042, 0],
					[-114.72528, 43.88042, 0],
					[-114.72525, 43.88043, 0],
					[-114.72523, 43.88043, 0],
					[-114.72521, 43.88044, 0],
					[-114.72518, 43.88044, 0],
					[-114.72515, 43.88045, 0],
					[-114.72512, 43.88045, 0],
					[-114.72508, 43.88046, 0],
					[-114.72482, 43.88046, 0],
					[-114.72477, 43.88045, 0],
					[-114.72473, 43.88045, 0],
					[-114.72469, 43.88044, 0],
					[-114.72466, 43.88044, 0],
					[-114.7246, 43.88042, 0],
					[-114.72456, 43.88042, 0],
					[-114.72451, 43.8804, 0],
					[-114.7245, 43.8804, 0],
					[-114.72276, 43.87993, 0],
					[-114.72274, 43.87992, 0],
					[-114.72271, 43.87991, 0],
					[-114.72269, 43.8799, 0],
					[-114.72266, 43.87989, 0],
					[-114.72265, 43.87988, 0],
					[-114.72264, 43.87988, 0],
					[-114.72258, 43.87986, 0],
					[-114.72255, 43.87984, 0],
					[-114.7225, 43.87982, 0],
					[-114.72246, 43.87981, 0],
					[-114.7224, 43.87977, 0],
					[-114.72236, 43.87975, 0],
					[-114.72229, 43.8797, 0],
					[-114.72223, 43.87967, 0],
					[-114.72219, 43.87963, 0],
					[-114.72212, 43.87958, 0],
					[-114.72209, 43.87955, 0],
					[-114.72207, 43.87952, 0],
					[-114.72203, 43.87948, 0],
					[-114.72201, 43.87945, 0],
					[-114.72199, 43.87943, 0],
					[-114.72197, 43.87939, 0],
					[-114.72195, 43.87936, 0],
					[-114.7219, 43.87926, 0],
					[-114.7219, 43.87924, 0],
					[-114.72189, 43.87923, 0],
					[-114.72189, 43.8792, 0],
					[-114.72188, 43.87918, 0],
					[-114.72188, 43.87915, 0],
					[-114.72187, 43.87913, 0],
					[-114.72187, 43.87895, 0],
					[-114.72188, 43.87893, 0],
					[-114.72188, 43.87889, 0],
					[-114.72189, 43.87888, 0],
					[-114.7219, 43.87886, 0],
					[-114.7219, 43.87884, 0],
					[-114.72191, 43.87882, 0],
					[-114.72192, 43.87879, 0],
					[-114.72193, 43.87878, 0],
					[-114.72194, 43.87875, 0],
					[-114.72196, 43.87873, 0],
					[-114.72198, 43.8787, 0],
					[-114.72199, 43.87867, 0],
					[-114.72201, 43.87865, 0],
					[-114.72204, 43.87861, 0],
					[-114.72209, 43.87856, 0],
					[-114.72211, 43.87853, 0],
					[-114.72213, 43.87851, 0],
					[-114.72216, 43.8785, 0],
					[-114.7222, 43.87846, 0],
					[-114.72227, 43.87841, 0],
					[-114.72229, 43.8784, 0],
					[-114.72229, 43.87839, 0],
					[-114.72233, 43.87838, 0],
					[-114.72239, 43.87834, 0],
					[-114.72241, 43.87833, 0],
					[-114.72249, 43.8783, 0],
					[-114.72257, 43.87826, 0],
					[-114.72261, 43.87825, 0],
					[-114.72272, 43.87821, 0],
					[-114.7232, 43.87808, 0],
					[-114.7234, 43.87804, 0],
					[-114.72347, 43.87802, 0],
					[-114.7238, 43.87796, 0],
					[-114.72387, 43.87794, 0],
					[-114.72389, 43.87794, 0],
					[-114.72398, 43.87791, 0],
					[-114.72402, 43.8779, 0],
					[-114.72413, 43.87786, 0],
					[-114.72421, 43.87782, 0],
					[-114.72424, 43.87781, 0],
					[-114.72426, 43.87779, 0],
					[-114.72432, 43.87776, 0],
					[-114.72434, 43.87774, 0],
					[-114.72437, 43.87772, 0],
					[-114.7244, 43.87771, 0],
					[-114.72445, 43.87766, 0],
					[-114.72448, 43.87764, 0],
					[-114.72449, 43.87762, 0],
					[-114.72451, 43.87761, 0],
					[-114.72458, 43.87754, 0],
					[-114.72466, 43.87744, 0],
					[-114.72472, 43.87734, 0],
					[-114.72473, 43.87731, 0],
					[-114.72475, 43.87728, 0],
					[-114.72477, 43.87722, 0],
					[-114.72479, 43.87718, 0],
					[-114.72481, 43.87713, 0],
					[-114.72489, 43.87686, 0],
					[-114.72492, 43.87679, 0],
					[-114.72492, 43.87678, 0],
					[-114.72497, 43.87661, 0],
					[-114.72499, 43.87658, 0],
					[-114.72511, 43.87612, 0],
					[-114.72513, 43.87602, 0],
					[-114.72521, 43.87573, 0],
					[-114.72521, 43.87571, 0],
					[-114.72522, 43.87567, 0],
					[-114.72524, 43.87563, 0],
					[-114.72524, 43.8756, 0],
					[-114.72525, 43.87557, 0],
					[-114.72526, 43.87555, 0],
					[-114.72526, 43.87554, 0],
					[-114.72527, 43.87552, 0],
					[-114.72529, 43.87546, 0],
					[-114.72531, 43.87543, 0],
					[-114.72532, 43.8754, 0],
					[-114.72535, 43.87534, 0],
					[-114.72536, 43.87531, 0],
					[-114.72537, 43.87529, 0],
					[-114.72539, 43.87528, 0],
					[-114.72541, 43.87525, 0],
					[-114.72543, 43.8752, 0],
					[-114.72547, 43.87516, 0],
					[-114.72548, 43.87514, 0],
					[-114.72551, 43.87512, 0],
					[-114.7256, 43.87503, 0],
					[-114.72572, 43.87494, 0],
					[-114.72574, 43.87492, 0],
					[-114.72577, 43.87491, 0],
					[-114.72583, 43.87487, 0],
					[-114.72587, 43.87485, 0],
					[-114.72591, 43.87482, 0],
					[-114.72607, 43.87476, 0],
					[-114.7261, 43.87474, 0],
					[-114.72613, 43.87473, 0],
					[-114.72615, 43.87473, 0],
					[-114.72619, 43.87471, 0],
					[-114.72622, 43.8747, 0],
					[-114.72631, 43.87468, 0],
					[-114.72637, 43.87466, 0],
					[-114.72642, 43.87465, 0],
					[-114.72648, 43.87463, 0],
					[-114.72665, 43.87459, 0],
					[-114.72683, 43.87456, 0],
					[-114.72712, 43.87449, 0],
					[-114.72732, 43.87445, 0],
					[-114.72752, 43.87442, 0],
					[-114.72758, 43.8744, 0],
					[-114.72785, 43.87436, 0],
					[-114.72802, 43.87432, 0],
					[-114.72806, 43.87432, 0],
					[-114.72817, 43.87429, 0],
					[-114.72828, 43.87425, 0],
					[-114.72833, 43.87424, 0],
					[-114.72839, 43.87422, 0],
					[-114.72843, 43.8742, 0],
					[-114.72846, 43.87419, 0],
					[-114.7285, 43.87417, 0],
					[-114.72858, 43.87414, 0],
					[-114.7287, 43.87408, 0],
					[-114.72874, 43.87405, 0],
					[-114.72878, 43.87403, 0],
					[-114.72893, 43.87392, 0],
					[-114.72895, 43.8739, 0],
					[-114.72898, 43.87388, 0],
					[-114.72901, 43.87385, 0],
					[-114.72905, 43.87382, 0],
					[-114.72908, 43.87379, 0],
					[-114.72915, 43.87373, 0],
					[-114.72919, 43.87368, 0],
					[-114.7292, 43.87366, 0],
					[-114.72922, 43.87364, 0],
					[-114.72927, 43.87357, 0],
					[-114.72934, 43.87344, 0],
					[-114.72936, 43.87339, 0],
					[-114.72938, 43.87335, 0],
					[-114.72939, 43.87332, 0],
					[-114.7294, 43.8733, 0],
					[-114.72941, 43.87327, 0],
					[-114.72942, 43.87325, 0],
					[-114.72942, 43.87323, 0],
					[-114.72943, 43.8732, 0],
					[-114.72943, 43.87316, 0],
					[-114.72944, 43.87314, 0],
					[-114.72944, 43.8731, 0],
					[-114.72945, 43.87306, 0],
					[-114.72945, 43.87298, 0],
					[-114.72944, 43.87293, 0],
					[-114.72944, 43.87289, 0],
					[-114.72942, 43.87279, 0],
					[-114.72942, 43.87275, 0],
					[-114.72938, 43.87263, 0],
					[-114.72938, 43.87262, 0],
					[-114.72937, 43.87259, 0],
					[-114.72932, 43.87249, 0],
					[-114.7293, 43.87247, 0],
					[-114.72926, 43.8724, 0],
					[-114.72921, 43.87235, 0],
					[-114.72919, 43.87232, 0],
					[-114.72904, 43.87217, 0],
					[-114.72897, 43.87212, 0],
					[-114.72894, 43.87209, 0],
					[-114.7289, 43.87207, 0],
					[-114.72884, 43.87202, 0],
					[-114.72877, 43.87197, 0],
					[-114.72843, 43.87176, 0],
					[-114.72825, 43.87164, 0],
					[-114.7279, 43.87143, 0],
					[-114.72769, 43.87132, 0],
					[-114.72762, 43.8713, 0],
					[-114.7276, 43.87129, 0],
					[-114.72754, 43.87127, 0],
					[-114.7275, 43.87125, 0],
					[-114.72747, 43.87124, 0],
					[-114.72744, 43.87124, 0],
					[-114.72742, 43.87123, 0],
					[-114.72732, 43.8712, 0],
					[-114.72727, 43.8712, 0],
					[-114.72725, 43.87119, 0],
					[-114.72723, 43.87119, 0],
					[-114.7272, 43.87118, 0],
					[-114.72717, 43.87118, 0],
					[-114.72713, 43.87117, 0],
					[-114.72707, 43.87117, 0],
					[-114.72704, 43.87116, 0],
					[-114.72695, 43.87116, 0],
					[-114.72691, 43.87115, 0],
					[-114.7267, 43.87115, 0],
					[-114.72664, 43.87116, 0],
					[-114.72645, 43.87116, 0],
					[-114.72604, 43.87119, 0],
					[-114.726, 43.87119, 0],
					[-114.72507, 43.87125, 0],
					[-114.72499, 43.87125, 0],
					[-114.72473, 43.87128, 0],
					[-114.72469, 43.87128, 0],
					[-114.72464, 43.87129, 0],
					[-114.72457, 43.87129, 0],
					[-114.72436, 43.87131, 0],
					[-114.72419, 43.87131, 0],
					[-114.72402, 43.87133, 0],
					[-114.7239, 43.87133, 0],
					[-114.7234, 43.87137, 0],
					[-114.72282, 43.8714, 0],
					[-114.72261, 43.87142, 0],
					[-114.72227, 43.87144, 0],
					[-114.72219, 43.87144, 0],
					[-114.72216, 43.87145, 0],
					[-114.72208, 43.87145, 0],
					[-114.72204, 43.87146, 0],
					[-114.72197, 43.87147, 0],
					[-114.72192, 43.87147, 0],
					[-114.72186, 43.87148, 0],
					[-114.72172, 43.87148, 0],
					[-114.72168, 43.87149, 0],
					[-114.72122, 43.87149, 0],
					[-114.72115, 43.87148, 0],
					[-114.72105, 43.87148, 0],
					[-114.7204, 43.87141, 0],
					[-114.72032, 43.87141, 0],
					[-114.72016, 43.87139, 0],
					[-114.7201, 43.87139, 0],
					[-114.72004, 43.87138, 0],
					[-114.71999, 43.87138, 0],
					[-114.71993, 43.87137, 0],
					[-114.71941, 43.87137, 0],
					[-114.71935, 43.87138, 0],
					[-114.7193, 43.87138, 0],
					[-114.71925, 43.87139, 0],
					[-114.71918, 43.87139, 0],
					[-114.71916, 43.8714, 0],
					[-114.71912, 43.8714, 0],
					[-114.71909, 43.87141, 0],
					[-114.71881, 43.87145, 0],
					[-114.71858, 43.8715, 0],
					[-114.71853, 43.87152, 0],
					[-114.71848, 43.87153, 0],
					[-114.71837, 43.87157, 0],
					[-114.71833, 43.87158, 0],
					[-114.71809, 43.87167, 0],
					[-114.71737, 43.87199, 0],
					[-114.71731, 43.87201, 0],
					[-114.71721, 43.87206, 0],
					[-114.71715, 43.87208, 0],
					[-114.71713, 43.87209, 0],
					[-114.71704, 43.87211, 0],
					[-114.71702, 43.87212, 0],
					[-114.71699, 43.87213, 0],
					[-114.71697, 43.87214, 0],
					[-114.71694, 43.87214, 0],
					[-114.71691, 43.87215, 0],
					[-114.71689, 43.87215, 0],
					[-114.71686, 43.87216, 0],
					[-114.71683, 43.87216, 0],
					[-114.71681, 43.87217, 0],
					[-114.71678, 43.87217, 0],
					[-114.71675, 43.87218, 0],
					[-114.71668, 43.87218, 0],
					[-114.71665, 43.87219, 0],
					[-114.71644, 43.87219, 0],
					[-114.71639, 43.87218, 0],
					[-114.71632, 43.87218, 0],
					[-114.71623, 43.87216, 0],
					[-114.7162, 43.87215, 0],
					[-114.71616, 43.87215, 0],
					[-114.71612, 43.87214, 0],
					[-114.71598, 43.87209, 0],
					[-114.71594, 43.87208, 0],
					[-114.7159, 43.87206, 0],
					[-114.71584, 43.87204, 0],
					[-114.71582, 43.87203, 0],
					[-114.71576, 43.87199, 0],
					[-114.71571, 43.87197, 0],
					[-114.71568, 43.87195, 0],
					[-114.71566, 43.87194, 0],
					[-114.71564, 43.87192, 0],
					[-114.71562, 43.87191, 0],
					[-114.7156, 43.87189, 0],
					[-114.71557, 43.87188, 0],
					[-114.71556, 43.87186, 0],
					[-114.71554, 43.87185, 0],
					[-114.7155, 43.87181, 0],
					[-114.71522, 43.87158, 0],
					[-114.715, 43.87138, 0],
					[-114.71492, 43.87132, 0],
					[-114.71488, 43.87128, 0],
					[-114.71484, 43.87126, 0],
					[-114.71481, 43.87123, 0],
					[-114.71468, 43.87113, 0],
					[-114.71464, 43.87111, 0],
					[-114.71451, 43.87102, 0],
					[-114.71433, 43.87093, 0],
					[-114.7143, 43.87092, 0],
					[-114.71426, 43.8709, 0],
					[-114.7139, 43.87079, 0],
					[-114.71387, 43.87079, 0],
					[-114.71379, 43.87077, 0],
					[-114.7137, 43.87074, 0],
					[-114.71366, 43.87074, 0],
					[-114.71352, 43.8707, 0],
					[-114.71334, 43.87066, 0],
					[-114.71316, 43.87059, 0],
					[-114.71312, 43.87058, 0],
					[-114.71309, 43.87056, 0],
					[-114.71305, 43.87055, 0],
					[-114.71303, 43.87054, 0],
					[-114.71301, 43.87052, 0],
					[-114.71293, 43.87048, 0],
					[-114.71292, 43.87047, 0],
					[-114.71289, 43.87045, 0],
					[-114.71286, 43.87042, 0],
					[-114.71284, 43.87041, 0],
					[-114.71273, 43.8703, 0],
					[-114.71271, 43.87027, 0],
					[-114.71269, 43.87025, 0],
					[-114.71267, 43.87021, 0],
					[-114.71265, 43.87018, 0],
					[-114.71264, 43.87017, 0],
					[-114.71263, 43.87015, 0],
					[-114.71263, 43.87013, 0],
					[-114.7126, 43.87007, 0],
					[-114.7126, 43.87006, 0],
					[-114.71259, 43.87004, 0],
					[-114.71259, 43.87002, 0],
					[-114.71258, 43.86999, 0],
					[-114.71258, 43.86997, 0],
					[-114.71257, 43.86994, 0],
					[-114.71257, 43.86979, 0],
					[-114.71258, 43.86976, 0],
					[-114.71258, 43.86973, 0],
					[-114.71259, 43.8697, 0],
					[-114.71259, 43.86969, 0],
					[-114.71261, 43.86966, 0],
					[-114.71265, 43.86955, 0],
					[-114.71266, 43.86951, 0],
					[-114.71288, 43.86909, 0],
					[-114.71301, 43.86887, 0],
					[-114.71321, 43.86843, 0],
					[-114.71324, 43.86838, 0],
					[-114.71327, 43.86831, 0],
					[-114.7133, 43.86826, 0],
					[-114.71337, 43.86811, 0],
					[-114.71341, 43.86799, 0],
					[-114.71341, 43.86797, 0],
					[-114.71342, 43.86795, 0],
					[-114.71342, 43.86791, 0],
					[-114.71343, 43.8679, 0],
					[-114.71343, 43.86784, 0],
					[-114.71342, 43.86781, 0],
					[-114.71342, 43.86776, 0],
					[-114.71341, 43.86771, 0],
					[-114.7134, 43.86768, 0],
					[-114.7134, 43.86765, 0],
					[-114.71336, 43.86757, 0],
					[-114.71334, 43.86754, 0],
					[-114.71333, 43.86751, 0],
					[-114.71328, 43.86744, 0],
					[-114.71326, 43.86742, 0],
					[-114.71324, 43.86741, 0],
					[-114.71322, 43.86738, 0],
					[-114.71318, 43.86735, 0],
					[-114.71316, 43.86733, 0],
					[-114.71312, 43.8673, 0],
					[-114.71308, 43.86728, 0],
					[-114.71305, 43.86725, 0],
					[-114.71302, 43.86723, 0],
					[-114.71297, 43.86721, 0],
					[-114.71294, 43.86719, 0],
					[-114.71288, 43.86716, 0],
					[-114.71281, 43.86714, 0],
					[-114.71279, 43.86713, 0],
					[-114.71268, 43.8671, 0],
					[-114.71265, 43.8671, 0],
					[-114.71252, 43.86707, 0],
					[-114.71239, 43.86707, 0],
					[-114.71235, 43.86706, 0],
					[-114.71231, 43.86707, 0],
					[-114.7122, 43.86707, 0],
					[-114.71216, 43.86708, 0],
					[-114.71213, 43.86708, 0],
					[-114.71182, 43.86717, 0],
					[-114.71168, 43.86724, 0],
					[-114.71163, 43.86727, 0],
					[-114.71159, 43.8673, 0],
					[-114.71154, 43.86733, 0],
					[-114.71145, 43.8674, 0],
					[-114.71144, 43.86742, 0],
					[-114.71142, 43.86744, 0],
					[-114.71138, 43.8675, 0],
					[-114.71136, 43.86755, 0],
					[-114.71132, 43.86763, 0],
					[-114.71129, 43.86776, 0],
					[-114.71129, 43.86793, 0],
					[-114.71133, 43.86811, 0],
					[-114.71139, 43.86828, 0],
					[-114.71147, 43.86858, 0],
					[-114.7115, 43.86866, 0],
					[-114.71151, 43.86872, 0],
					[-114.71151, 43.86876, 0],
					[-114.71152, 43.86881, 0],
					[-114.71152, 43.86887, 0],
					[-114.7115, 43.86897, 0],
					[-114.71149, 43.869, 0],
					[-114.71149, 43.86903, 0],
					[-114.71147, 43.8691, 0],
					[-114.71145, 43.86913, 0],
					[-114.7114, 43.86923, 0],
					[-114.71137, 43.86927, 0],
					[-114.71134, 43.86933, 0],
					[-114.7113, 43.86937, 0],
					[-114.71126, 43.86942, 0],
					[-114.71123, 43.86945, 0],
					[-114.71119, 43.86948, 0],
					[-114.71114, 43.86953, 0],
					[-114.7111, 43.86955, 0],
					[-114.71107, 43.86958, 0],
					[-114.71103, 43.8696, 0],
					[-114.711, 43.86962, 0],
					[-114.71092, 43.86966, 0],
					[-114.71089, 43.86968, 0],
					[-114.71086, 43.86969, 0],
					[-114.71064, 43.86979, 0],
					[-114.71053, 43.86983, 0],
					[-114.71043, 43.86986, 0],
					[-114.71038, 43.86988, 0],
					[-114.71034, 43.8699, 0],
					[-114.71028, 43.86992, 0],
					[-114.71022, 43.86995, 0],
					[-114.71018, 43.86996, 0],
					[-114.71015, 43.86998, 0],
					[-114.71013, 43.86998, 0],
					[-114.71011, 43.87, 0],
					[-114.71005, 43.87002, 0],
					[-114.71003, 43.87004, 0],
					[-114.71, 43.87005, 0],
					[-114.70997, 43.87007, 0],
					[-114.70989, 43.87011, 0],
					[-114.70987, 43.87013, 0],
					[-114.70984, 43.87015, 0],
					[-114.70982, 43.87016, 0],
					[-114.7098, 43.87018, 0],
					[-114.70978, 43.87019, 0],
					[-114.70975, 43.87021, 0],
					[-114.70973, 43.87022, 0],
					[-114.70972, 43.87024, 0],
					[-114.7097, 43.87025, 0],
					[-114.70968, 43.87027, 0],
					[-114.70966, 43.87028, 0],
					[-114.7096, 43.87034, 0],
					[-114.70957, 43.87036, 0],
					[-114.70955, 43.87039, 0],
					[-114.70952, 43.87042, 0],
					[-114.7095, 43.87045, 0],
					[-114.70949, 43.87048, 0],
					[-114.70945, 43.87053, 0],
					[-114.70943, 43.87057, 0],
					[-114.70941, 43.87062, 0],
					[-114.70939, 43.87066, 0],
					[-114.70938, 43.87067, 0],
					[-114.70937, 43.87072, 0],
					[-114.70933, 43.87083, 0],
					[-114.70933, 43.87084, 0],
					[-114.70928, 43.871, 0],
					[-114.70924, 43.87117, 0],
					[-114.70923, 43.87119, 0],
					[-114.70919, 43.87137, 0],
					[-114.70918, 43.8714, 0],
					[-114.70915, 43.87153, 0],
					[-114.70915, 43.87155, 0],
					[-114.70908, 43.87183, 0],
					[-114.70904, 43.87196, 0],
					[-114.709, 43.87204, 0],
					[-114.70889, 43.87222, 0],
					[-114.70882, 43.87232, 0],
					[-114.70879, 43.87237, 0],
					[-114.70865, 43.87251, 0],
					[-114.70839, 43.8727, 0],
					[-114.70834, 43.87273, 0],
					[-114.70818, 43.87281, 0],
					[-114.708, 43.87289, 0],
					[-114.70767, 43.87301, 0],
					[-114.70742, 43.87309, 0],
					[-114.70733, 43.87311, 0],
					[-114.70697, 43.87322, 0],
					[-114.70688, 43.87324, 0],
					[-114.70681, 43.87327, 0],
					[-114.70674, 43.87329, 0],
					[-114.70654, 43.87337, 0],
					[-114.70636, 43.87347, 0],
					[-114.70627, 43.87351, 0],
					[-114.70614, 43.87359, 0],
					[-114.70608, 43.87362, 0],
					[-114.70601, 43.87367, 0],
					[-114.70597, 43.87369, 0],
					[-114.70587, 43.87376, 0],
					[-114.70583, 43.8738, 0],
					[-114.70575, 43.87386, 0],
					[-114.70571, 43.8739, 0],
					[-114.70569, 43.87393, 0],
					[-114.70563, 43.87399, 0],
					[-114.70554, 43.87412, 0],
					[-114.70548, 43.87419, 0],
					[-114.70548, 43.87421, 0],
					[-114.70538, 43.87437, 0],
					[-114.70538, 43.87438, 0],
					[-114.70532, 43.87453, 0],
					[-114.70528, 43.87461, 0],
					[-114.70524, 43.87475, 0],
					[-114.70518, 43.87503, 0],
					[-114.70515, 43.87525, 0],
					[-114.70514, 43.8754, 0],
					[-114.7051, 43.8757, 0],
					[-114.70507, 43.87585, 0],
					[-114.70505, 43.87591, 0],
					[-114.70503, 43.87599, 0],
					[-114.70501, 43.87604, 0],
					[-114.705, 43.87608, 0],
					[-114.70494, 43.87621, 0],
					[-114.70492, 43.87624, 0],
					[-114.7049, 43.87628, 0],
					[-114.70479, 43.87645, 0],
					[-114.70473, 43.87653, 0],
					[-114.7044, 43.87686, 0],
					[-114.70408, 43.87712, 0],
					[-114.70403, 43.87715, 0],
					[-114.70397, 43.8772, 0],
					[-114.7039, 43.87724, 0],
					[-114.70384, 43.87728, 0],
					[-114.70377, 43.87732, 0],
					[-114.70368, 43.87738, 0],
					[-114.70328, 43.87761, 0],
					[-114.70294, 43.87782, 0],
					[-114.70283, 43.87788, 0],
					[-114.70271, 43.87796, 0],
					[-114.70259, 43.87803, 0],
					[-114.70232, 43.87821, 0],
					[-114.7023, 43.87823, 0],
					[-114.702, 43.87845, 0],
					[-114.70182, 43.87857, 0],
					[-114.70178, 43.87861, 0],
					[-114.70177, 43.87861, 0],
					[-114.70175, 43.87862, 0],
					[-114.70173, 43.87864, 0],
					[-114.70169, 43.87866, 0],
					[-114.70165, 43.87869, 0],
					[-114.70156, 43.87874, 0],
					[-114.70153, 43.87875, 0],
					[-114.7015, 43.87877, 0],
					[-114.70138, 43.87883, 0],
					[-114.70133, 43.87885, 0],
					[-114.70127, 43.87888, 0],
					[-114.70123, 43.87889, 0],
					[-114.7012, 43.87891, 0],
					[-114.7011, 43.87894, 0],
					[-114.70102, 43.87898, 0],
					[-114.70095, 43.879, 0],
					[-114.70089, 43.87903, 0],
					[-114.70084, 43.87905, 0],
					[-114.7008, 43.87907, 0],
					[-114.70076, 43.87908, 0],
					[-114.70073, 43.8791, 0],
					[-114.70071, 43.87911, 0],
					[-114.70068, 43.87913, 0],
					[-114.70064, 43.87914, 0],
					[-114.70062, 43.87916, 0],
					[-114.70059, 43.87917, 0],
					[-114.70047, 43.87926, 0],
					[-114.70045, 43.87927, 0],
					[-114.70034, 43.87938, 0],
					[-114.70033, 43.8794, 0],
					[-114.7003, 43.87943, 0],
					[-114.70026, 43.87949, 0],
					[-114.70021, 43.87959, 0],
					[-114.70017, 43.87971, 0],
					[-114.70016, 43.87978, 0],
					[-114.70016, 43.87984, 0],
					[-114.70017, 43.87986, 0],
					[-114.70017, 43.87992, 0],
					[-114.70018, 43.87995, 0],
					[-114.70019, 43.87997, 0],
					[-114.70019, 43.87999, 0],
					[-114.7002, 43.88002, 0],
					[-114.70021, 43.88004, 0],
					[-114.70023, 43.88007, 0],
					[-114.70024, 43.8801, 0],
					[-114.70026, 43.88013, 0],
					[-114.70029, 43.88016, 0],
					[-114.7003, 43.88019, 0],
					[-114.70032, 43.88021, 0],
					[-114.70034, 43.88024, 0],
					[-114.70037, 43.88026, 0],
					[-114.70041, 43.8803, 0],
					[-114.70043, 43.88031, 0],
					[-114.70045, 43.88033, 0],
					[-114.70046, 43.88035, 0],
					[-114.70049, 43.88036, 0],
					[-114.70053, 43.8804, 0],
					[-114.7006, 43.88045, 0],
					[-114.70102, 43.8807, 0],
					[-114.70103, 43.8807, 0],
					[-114.70113, 43.88076, 0],
					[-114.70117, 43.88078, 0],
					[-114.7012, 43.8808, 0],
					[-114.70126, 43.88083, 0],
					[-114.70131, 43.88086, 0],
					[-114.70134, 43.88089, 0],
					[-114.70141, 43.88094, 0],
					[-114.70148, 43.88101, 0],
					[-114.70153, 43.88108, 0],
					[-114.70155, 43.88109, 0],
					[-114.70159, 43.88115, 0],
					[-114.70164, 43.88125, 0],
					[-114.70165, 43.88128, 0],
					[-114.70167, 43.88132, 0],
					[-114.70168, 43.88136, 0],
					[-114.7017, 43.8814, 0],
					[-114.7017, 43.88144, 0],
					[-114.70171, 43.88146, 0],
					[-114.70171, 43.88149, 0],
					[-114.70172, 43.88151, 0],
					[-114.70172, 43.88155, 0],
					[-114.70173, 43.88158, 0],
					[-114.70173, 43.88171, 0],
					[-114.70172, 43.88173, 0],
					[-114.70172, 43.88178, 0],
					[-114.70171, 43.88179, 0],
					[-114.7017, 43.88182, 0],
					[-114.7017, 43.88184, 0],
					[-114.70168, 43.88187, 0],
					[-114.70167, 43.88189, 0],
					[-114.70166, 43.8819, 0],
					[-114.70164, 43.88193, 0],
					[-114.70163, 43.88196, 0],
					[-114.70157, 43.88202, 0],
					[-114.70155, 43.88205, 0],
					[-114.7014, 43.8822, 0],
					[-114.70135, 43.88224, 0],
					[-114.70109, 43.88242, 0],
					[-114.70093, 43.8825, 0],
					[-114.70085, 43.88252, 0],
					[-114.70079, 43.88254, 0],
					[-114.70078, 43.88254, 0],
					[-114.70076, 43.88255, 0],
					[-114.70067, 43.88257, 0],
					[-114.70063, 43.88257, 0],
					[-114.70046, 43.8826, 0],
					[-114.70041, 43.8826, 0],
					[-114.70033, 43.88261, 0],
					[-114.70014, 43.88261, 0],
					[-114.70003, 43.88259, 0],
					[-114.69986, 43.88257, 0],
					[-114.69972, 43.88254, 0],
					[-114.69966, 43.88252, 0],
					[-114.69957, 43.8825, 0],
					[-114.69934, 43.88243, 0],
					[-114.69915, 43.88236, 0],
					[-114.69857, 43.88217, 0],
					[-114.69797, 43.882, 0],
					[-114.69794, 43.882, 0],
					[-114.69791, 43.88199, 0],
					[-114.69789, 43.88199, 0],
					[-114.69786, 43.88198, 0],
					[-114.69778, 43.88198, 0],
					[-114.69775, 43.88197, 0],
					[-114.69738, 43.88197, 0],
					[-114.69732, 43.88198, 0],
					[-114.69727, 43.88198, 0],
					[-114.69701, 43.88203, 0],
					[-114.69694, 43.88205, 0],
					[-114.69684, 43.88207, 0],
					[-114.6968, 43.88209, 0],
					[-114.69671, 43.88211, 0],
					[-114.69668, 43.88211, 0],
					[-114.69664, 43.88213, 0],
					[-114.69663, 43.88213, 0],
					[-114.6966, 43.88214, 0],
					[-114.69658, 43.88216, 0],
					[-114.69655, 43.88217, 0],
					[-114.69649, 43.8822, 0],
					[-114.69647, 43.88222, 0],
					[-114.69644, 43.88223, 0],
					[-114.69642, 43.88225, 0],
					[-114.69638, 43.88227, 0],
					[-114.69633, 43.88232, 0],
					[-114.69629, 43.88234, 0],
					[-114.69628, 43.88236, 0],
					[-114.69624, 43.88238, 0],
					[-114.69623, 43.8824, 0],
					[-114.69609, 43.88254, 0],
					[-114.69605, 43.88261, 0],
					[-114.69603, 43.88263, 0],
					[-114.69602, 43.88265, 0],
					[-114.69599, 43.88269, 0],
					[-114.69595, 43.88276, 0],
					[-114.69594, 43.88279, 0],
					[-114.69593, 43.88281, 0],
					[-114.69592, 43.88284, 0],
					[-114.6959, 43.88287, 0],
					[-114.69588, 43.88293, 0],
					[-114.69586, 43.88297, 0],
					[-114.69581, 43.88319, 0],
					[-114.69581, 43.88349, 0],
					[-114.69582, 43.88352, 0],
					[-114.69584, 43.88361, 0],
					[-114.69584, 43.88364, 0],
					[-114.69585, 43.88366, 0],
					[-114.69589, 43.88377, 0],
					[-114.69594, 43.88387, 0],
					[-114.69596, 43.8839, 0],
					[-114.69598, 43.88394, 0],
					[-114.69602, 43.884, 0],
					[-114.69605, 43.88403, 0],
					[-114.69608, 43.88407, 0],
					[-114.69646, 43.88447, 0],
					[-114.69676, 43.88477, 0],
					[-114.69693, 43.88497, 0],
					[-114.69714, 43.88524, 0],
					[-114.69716, 43.88528, 0],
					[-114.69718, 43.88531, 0],
					[-114.69724, 43.88543, 0],
					[-114.69728, 43.88554, 0],
					[-114.69728, 43.88556, 0],
					[-114.69729, 43.88558, 0],
					[-114.6973, 43.88562, 0],
					[-114.6973, 43.88565, 0],
					[-114.69731, 43.88568, 0],
					[-114.69731, 43.8859, 0],
					[-114.69726, 43.88617, 0],
					[-114.69724, 43.88622, 0],
					[-114.69722, 43.88626, 0],
					[-114.6972, 43.88632, 0],
					[-114.69718, 43.88636, 0],
					[-114.69709, 43.8865, 0],
					[-114.69706, 43.88654, 0],
					[-114.69702, 43.88658, 0],
					[-114.697, 43.88661, 0],
					[-114.69698, 43.88662, 0],
					[-114.69694, 43.88666, 0],
					[-114.69687, 43.88671, 0],
					[-114.69681, 43.88677, 0],
					[-114.69677, 43.88679, 0],
					[-114.69675, 43.88681, 0],
					[-114.69665, 43.88688, 0],
					[-114.69649, 43.88696, 0],
					[-114.69644, 43.88699, 0],
					[-114.69632, 43.88703, 0],
					[-114.69622, 43.88707, 0],
					[-114.69592, 43.88715, 0],
					[-114.69589, 43.88715, 0],
					[-114.69586, 43.88716, 0],
					[-114.69558, 43.88716, 0],
					[-114.69552, 43.88715, 0],
					[-114.69547, 43.88715, 0],
					[-114.69542, 43.88714, 0],
					[-114.69538, 43.88714, 0],
					[-114.6953, 43.88712, 0],
					[-114.69527, 43.88712, 0],
					[-114.69521, 43.8871, 0],
					[-114.69514, 43.88709, 0],
					[-114.69506, 43.88707, 0],
					[-114.69501, 43.88705, 0],
					[-114.69493, 43.88703, 0],
					[-114.69487, 43.88701, 0],
					[-114.6948, 43.88698, 0],
					[-114.69473, 43.88696, 0],
					[-114.69459, 43.8869, 0],
					[-114.69453, 43.88687, 0],
					[-114.69448, 43.88685, 0],
					[-114.69445, 43.88683, 0],
					[-114.69441, 43.88681, 0],
					[-114.69423, 43.88668, 0],
					[-114.69419, 43.88666, 0],
					[-114.69416, 43.88663, 0],
					[-114.69402, 43.88652, 0],
					[-114.69399, 43.88649, 0],
					[-114.69394, 43.88645, 0],
					[-114.69348, 43.88598, 0],
					[-114.69329, 43.88581, 0],
					[-114.69313, 43.8857, 0],
					[-114.69311, 43.88568, 0],
					[-114.69305, 43.88564, 0],
					[-114.69304, 43.88563, 0],
					[-114.69302, 43.88562, 0],
					[-114.69299, 43.88561, 0],
					[-114.69297, 43.88559, 0],
					[-114.69291, 43.88557, 0],
					[-114.69287, 43.88554, 0],
					[-114.69277, 43.8855, 0],
					[-114.69264, 43.88544, 0],
					[-114.69254, 43.88541, 0],
					[-114.69251, 43.88539, 0],
					[-114.69247, 43.88538, 0],
					[-114.69238, 43.88535, 0],
					[-114.69235, 43.88535, 0],
					[-114.69232, 43.88534, 0],
					[-114.6923, 43.88534, 0],
					[-114.69224, 43.88532, 0],
					[-114.69206, 43.88529, 0],
					[-114.69202, 43.88529, 0],
					[-114.69197, 43.88528, 0],
					[-114.69192, 43.88528, 0],
					[-114.69188, 43.88527, 0],
					[-114.69182, 43.88527, 0],
					[-114.69178, 43.88526, 0],
					[-114.6916, 43.88526, 0],
					[-114.69153, 43.88525, 0],
					[-114.69145, 43.88525, 0],
					[-114.69136, 43.88526, 0],
					[-114.69124, 43.88526, 0],
					[-114.69111, 43.88528, 0],
					[-114.69106, 43.88528, 0],
					[-114.69098, 43.8853, 0],
					[-114.69092, 43.88531, 0],
					[-114.6909, 43.88532, 0],
					[-114.69074, 43.88535, 0],
					[-114.69068, 43.88537, 0],
					[-114.69044, 43.88543, 0],
					[-114.69042, 43.88544, 0],
					[-114.6904, 43.88544, 0],
					[-114.69039, 43.88545, 0],
					[-114.69037, 43.88545, 0],
					[-114.69034, 43.88546, 0],
					[-114.69032, 43.88546, 0],
					[-114.69028, 43.88548, 0],
					[-114.69026, 43.88548, 0],
					[-114.69013, 43.88554, 0],
					[-114.6901, 43.88556, 0],
					[-114.69002, 43.88559, 0],
					[-114.69, 43.88561, 0],
					[-114.68997, 43.88562, 0],
					[-114.6899, 43.88567, 0],
					[-114.68986, 43.88569, 0],
					[-114.68984, 43.88571, 0],
					[-114.68981, 43.88572, 0],
					[-114.68977, 43.88575, 0],
					[-114.68974, 43.88578, 0],
					[-114.68971, 43.8858, 0],
					[-114.68969, 43.88582, 0],
					[-114.68964, 43.88586, 0],
					[-114.68953, 43.88597, 0],
					[-114.68939, 43.88615, 0],
					[-114.68934, 43.88623, 0],
					[-114.68929, 43.8863, 0],
					[-114.68928, 43.88633, 0],
					[-114.68923, 43.88642, 0],
					[-114.68921, 43.88648, 0],
					[-114.68919, 43.88651, 0],
					[-114.68918, 43.88655, 0],
					[-114.68917, 43.88657, 0],
					[-114.68917, 43.88659, 0],
					[-114.68916, 43.88661, 0],
					[-114.68916, 43.88663, 0],
					[-114.68915, 43.88666, 0],
					[-114.68909, 43.88694, 0],
					[-114.68907, 43.88707, 0],
					[-114.68907, 43.88713, 0],
					[-114.68906, 43.8872, 0],
					[-114.68906, 43.88738, 0],
					[-114.68905, 43.88743, 0],
					[-114.68905, 43.88747, 0],
					[-114.68904, 43.88753, 0],
					[-114.68903, 43.88755, 0],
					[-114.68903, 43.88757, 0],
					[-114.68901, 43.88761, 0],
					[-114.689, 43.88764, 0],
					[-114.68899, 43.88766, 0],
					[-114.68897, 43.88769, 0],
					[-114.68896, 43.88772, 0],
					[-114.68895, 43.88772, 0],
					[-114.68894, 43.88775, 0],
					[-114.68891, 43.88778, 0],
					[-114.68889, 43.88781, 0],
					[-114.68887, 43.88783, 0],
					[-114.68886, 43.88785, 0],
					[-114.68873, 43.88798, 0],
					[-114.68863, 43.88805, 0],
					[-114.68861, 43.88807, 0],
					[-114.68859, 43.88808, 0],
					[-114.68857, 43.8881, 0],
					[-114.68853, 43.88812, 0],
					[-114.68852, 43.88813, 0],
					[-114.6885, 43.88814, 0],
					[-114.68847, 43.88815, 0],
					[-114.68844, 43.88817, 0],
					[-114.68839, 43.88819, 0],
					[-114.68831, 43.88821, 0],
					[-114.68823, 43.88824, 0],
					[-114.68817, 43.88825, 0],
					[-114.6881, 43.88827, 0],
					[-114.68793, 43.88829, 0],
					[-114.68775, 43.88829, 0],
					[-114.68769, 43.88828, 0],
					[-114.68754, 43.88828, 0],
					[-114.68748, 43.88827, 0],
					[-114.68743, 43.88827, 0],
					[-114.68729, 43.88825, 0],
					[-114.68714, 43.88824, 0],
					[-114.68703, 43.88822, 0],
					[-114.68685, 43.8882, 0],
					[-114.6868, 43.8882, 0],
					[-114.68676, 43.88819, 0],
					[-114.68664, 43.88819, 0],
					[-114.68661, 43.88818, 0],
					[-114.68658, 43.88819, 0],
					[-114.68655, 43.88819, 0],
					[-114.68641, 43.88821, 0],
					[-114.68638, 43.88821, 0],
					[-114.68634, 43.88822, 0],
					[-114.6863, 43.88824, 0],
					[-114.68626, 43.88825, 0],
					[-114.68618, 43.88829, 0],
					[-114.68613, 43.88831, 0],
					[-114.68599, 43.8884, 0],
					[-114.68596, 43.88841, 0],
					[-114.68594, 43.88843, 0],
					[-114.68587, 43.88848, 0],
					[-114.68582, 43.88853, 0],
					[-114.6858, 43.88856, 0],
					[-114.68576, 43.8886, 0],
					[-114.68571, 43.88866, 0],
					[-114.68564, 43.8888, 0],
					[-114.68562, 43.88887, 0],
					[-114.68562, 43.8889, 0],
					[-114.68561, 43.88893, 0],
					[-114.68561, 43.88895, 0],
					[-114.6856, 43.88897, 0],
					[-114.6856, 43.88911, 0],
					[-114.68561, 43.88915, 0],
					[-114.68561, 43.88917, 0],
					[-114.68562, 43.8892, 0],
					[-114.68563, 43.88922, 0],
					[-114.68563, 43.88924, 0],
					[-114.68583, 43.8897, 0],
					[-114.68587, 43.88981, 0],
					[-114.68593, 43.88993, 0],
					[-114.68594, 43.88997, 0],
					[-114.68596, 43.89001, 0],
					[-114.68596, 43.89004, 0],
					[-114.68598, 43.8901, 0],
					[-114.68598, 43.89013, 0],
					[-114.68599, 43.89017, 0],
					[-114.68599, 43.89027, 0],
					[-114.68598, 43.89029, 0],
					[-114.68598, 43.89035, 0],
					[-114.68597, 43.89036, 0],
					[-114.68597, 43.89038, 0],
					[-114.68595, 43.89041, 0],
					[-114.68594, 43.89044, 0],
					[-114.68593, 43.89046, 0],
					[-114.68591, 43.89052, 0],
					[-114.68584, 43.89062, 0],
					[-114.68581, 43.89065, 0],
					[-114.6858, 43.89067, 0],
					[-114.68578, 43.89068, 0],
					[-114.68576, 43.89071, 0],
					[-114.68572, 43.89074, 0],
					[-114.68569, 43.89077, 0],
					[-114.6856, 43.89084, 0],
					[-114.68552, 43.89089, 0],
					[-114.68544, 43.89093, 0],
					[-114.68525, 43.891, 0],
					[-114.68522, 43.891, 0],
					[-114.68505, 43.89105, 0],
					[-114.685, 43.89105, 0],
					[-114.68494, 43.89107, 0],
					[-114.68489, 43.89107, 0],
					[-114.68482, 43.89108, 0],
					[-114.68474, 43.8911, 0],
					[-114.6847, 43.8911, 0],
					[-114.68466, 43.89111, 0],
					[-114.68459, 43.89111, 0],
					[-114.68456, 43.89112, 0],
					[-114.68446, 43.89112, 0],
					[-114.6844, 43.89113, 0],
					[-114.68429, 43.89113, 0],
					[-114.68427, 43.89114, 0],
					[-114.68422, 43.89114, 0],
					[-114.68419, 43.89115, 0],
					[-114.68406, 43.89118, 0],
					[-114.68389, 43.89123, 0],
					[-114.68367, 43.89131, 0],
					[-114.68351, 43.89139, 0],
					[-114.68337, 43.89148, 0],
					[-114.68332, 43.8915, 0],
					[-114.68327, 43.89154, 0],
					[-114.68322, 43.89159, 0],
					[-114.68317, 43.89163, 0],
					[-114.68313, 43.89168, 0],
					[-114.6831, 43.89171, 0],
					[-114.68306, 43.89176, 0],
					[-114.68295, 43.89197, 0],
					[-114.68292, 43.89207, 0],
					[-114.68292, 43.8921, 0],
					[-114.68291, 43.89215, 0],
					[-114.68291, 43.89218, 0],
					[-114.6829, 43.89221, 0],
					[-114.6829, 43.8923, 0],
					[-114.68291, 43.89236, 0],
					[-114.68291, 43.89239, 0],
					[-114.68294, 43.89252, 0],
					[-114.68297, 43.8926, 0],
					[-114.68303, 43.89272, 0],
					[-114.68308, 43.89278, 0],
					[-114.68308, 43.89279, 0],
					[-114.68311, 43.89284, 0],
					[-114.68384, 43.89375, 0],
					[-114.68402, 43.89399, 0],
					[-114.68411, 43.8941, 0],
					[-114.68415, 43.89417, 0],
					[-114.68421, 43.89425, 0],
					[-114.68424, 43.89431, 0],
					[-114.68427, 43.89436, 0],
					[-114.68431, 43.89448, 0],
					[-114.68433, 43.89452, 0],
					[-114.68436, 43.89467, 0],
					[-114.68436, 43.89494, 0],
					[-114.68435, 43.89497, 0],
					[-114.68433, 43.89506, 0],
					[-114.68427, 43.89521, 0],
					[-114.68419, 43.89536, 0],
					[-114.68415, 43.89541, 0],
					[-114.68411, 43.89545, 0],
					[-114.68407, 43.89551, 0],
					[-114.68405, 43.89552, 0],
					[-114.68402, 43.89556, 0],
					[-114.68398, 43.8956, 0],
					[-114.68392, 43.89565, 0],
					[-114.68378, 43.89575, 0],
					[-114.68374, 43.89577, 0],
					[-114.68365, 43.89583, 0],
					[-114.68361, 43.89584, 0],
					[-114.68359, 43.89586, 0],
					[-114.68353, 43.89588, 0],
					[-114.6835, 43.8959, 0],
					[-114.68346, 43.89591, 0],
					[-114.68343, 43.89593, 0],
					[-114.68339, 43.89594, 0],
					[-114.68336, 43.89596, 0],
					[-114.68327, 43.89598, 0],
					[-114.68324, 43.89599, 0],
					[-114.68319, 43.896, 0],
					[-114.68316, 43.89601, 0],
					[-114.68314, 43.89602, 0],
					[-114.68309, 43.89602, 0],
					[-114.68307, 43.89603, 0],
					[-114.68304, 43.89603, 0],
					[-114.68301, 43.89604, 0],
					[-114.68296, 43.89604, 0],
					[-114.68292, 43.89605, 0],
					[-114.68252, 43.89605, 0],
					[-114.68249, 43.89604, 0],
					[-114.68241, 43.89604, 0],
					[-114.68217, 43.896, 0],
					[-114.68195, 43.89593, 0],
					[-114.68191, 43.89591, 0],
					[-114.68181, 43.89588, 0],
					[-114.68177, 43.89586, 0],
					[-114.68174, 43.89585, 0],
					[-114.68162, 43.89579, 0],
					[-114.68159, 43.89578, 0],
					[-114.68157, 43.89576, 0],
					[-114.68154, 43.89575, 0],
					[-114.68145, 43.89569, 0],
					[-114.68143, 43.89568, 0],
					[-114.68139, 43.89564, 0],
					[-114.68136, 43.89562, 0],
					[-114.68134, 43.89561, 0],
					[-114.68124, 43.89551, 0],
					[-114.68122, 43.8955, 0],
					[-114.6812, 43.89548, 0],
					[-114.68119, 43.89546, 0],
					[-114.68117, 43.89544, 0],
					[-114.68115, 43.8954, 0],
					[-114.68112, 43.89537, 0],
					[-114.68103, 43.89524, 0],
					[-114.68102, 43.89522, 0],
					[-114.68101, 43.89519, 0],
					[-114.68096, 43.8951, 0],
					[-114.68093, 43.89501, 0],
					[-114.68092, 43.89499, 0],
					[-114.6809, 43.89493, 0],
					[-114.68089, 43.89486, 0],
					[-114.68089, 43.89484, 0],
					[-114.68088, 43.89482, 0],
					[-114.68088, 43.89479, 0],
					[-114.68087, 43.89472, 0],
					[-114.68083, 43.89358, 0],
					[-114.68082, 43.89351, 0],
					[-114.68082, 43.89347, 0],
					[-114.68081, 43.89344, 0],
					[-114.68081, 43.8934, 0],
					[-114.68078, 43.89329, 0],
					[-114.68078, 43.89326, 0],
					[-114.68076, 43.89322, 0],
					[-114.68076, 43.8932, 0],
					[-114.68075, 43.89317, 0],
					[-114.68062, 43.89289, 0],
					[-114.6806, 43.89288, 0],
					[-114.68059, 43.89285, 0],
					[-114.68038, 43.89258, 0],
					[-114.68029, 43.89249, 0],
					[-114.68026, 43.89247, 0],
					[-114.68023, 43.89244, 0],
					[-114.6802, 43.89242, 0],
					[-114.68015, 43.89238, 0],
					[-114.68, 43.89228, 0],
					[-114.67985, 43.8922, 0],
					[-114.67979, 43.89216, 0],
					[-114.67974, 43.89214, 0],
					[-114.67967, 43.8921, 0],
					[-114.67901, 43.8918, 0],
					[-114.67842, 43.89155, 0],
					[-114.67826, 43.89147, 0],
					[-114.6781, 43.89138, 0],
					[-114.67784, 43.89122, 0],
					[-114.6778, 43.89119, 0],
					[-114.67775, 43.89116, 0],
					[-114.67761, 43.89105, 0],
					[-114.6776, 43.89104, 0],
					[-114.67753, 43.89099, 0],
					[-114.6775, 43.89096, 0],
					[-114.67747, 43.89094, 0],
					[-114.67746, 43.89093, 0],
					[-114.67743, 43.89091, 0],
					[-114.67734, 43.89082, 0],
					[-114.67731, 43.8908, 0],
					[-114.67729, 43.89077, 0],
					[-114.67728, 43.89077, 0],
					[-114.67728, 43.89076, 0],
					[-114.67725, 43.89073, 0],
					[-114.67721, 43.89068, 0],
					[-114.67714, 43.89061, 0],
					[-114.67704, 43.89047, 0],
					[-114.67702, 43.89045, 0],
					[-114.67699, 43.89039, 0],
					[-114.67694, 43.89032, 0],
					[-114.67693, 43.89029, 0],
					[-114.6769, 43.89026, 0],
					[-114.67688, 43.8902, 0],
					[-114.67668, 43.88976, 0],
					[-114.67608, 43.88826, 0],
					[-114.67587, 43.88778, 0],
					[-114.67565, 43.88723, 0],
					[-114.67557, 43.88705, 0],
					[-114.67547, 43.88685, 0],
					[-114.67547, 43.88684, 0],
					[-114.6754, 43.88669, 0],
					[-114.67539, 43.88668, 0],
					[-114.67533, 43.88655, 0],
					[-114.67528, 43.88647, 0],
					[-114.67514, 43.88621, 0],
					[-114.67492, 43.88585, 0],
					[-114.67492, 43.88584, 0],
					[-114.6749, 43.88582, 0],
					[-114.67441, 43.88504, 0],
					[-114.6744, 43.88502, 0],
					[-114.67432, 43.8849, 0],
					[-114.67429, 43.88484, 0],
					[-114.67418, 43.88468, 0],
					[-114.67396, 43.88433, 0],
					[-114.67391, 43.88424, 0],
					[-114.67384, 43.88414, 0],
					[-114.67381, 43.88408, 0],
					[-114.67371, 43.88391, 0],
					[-114.67368, 43.88385, 0],
					[-114.67359, 43.8837, 0],
					[-114.67353, 43.88358, 0],
					[-114.67347, 43.88343, 0],
					[-114.67345, 43.8834, 0],
					[-114.67338, 43.88323, 0],
					[-114.67336, 43.88321, 0],
					[-114.67324, 43.88288, 0],
					[-114.6732, 43.88274, 0],
					[-114.67313, 43.88254, 0],
					[-114.67304, 43.88221, 0],
					[-114.67303, 43.88214, 0],
					[-114.67301, 43.88208, 0],
					[-114.67297, 43.88187, 0],
					[-114.67293, 43.88151, 0],
					[-114.67288, 43.88121, 0],
					[-114.67278, 43.88043, 0],
					[-114.67276, 43.88035, 0],
					[-114.67273, 43.88017, 0],
					[-114.67265, 43.87993, 0],
					[-114.67256, 43.87971, 0],
					[-114.67253, 43.87965, 0],
					[-114.67251, 43.8796, 0],
					[-114.67249, 43.87956, 0],
					[-114.67241, 43.87943, 0],
					[-114.67237, 43.87938, 0],
					[-114.67235, 43.87934, 0],
					[-114.67228, 43.87924, 0],
					[-114.67224, 43.8792, 0],
					[-114.67221, 43.87916, 0],
					[-114.67217, 43.87912, 0],
					[-114.67214, 43.87908, 0],
					[-114.67208, 43.87902, 0],
					[-114.67202, 43.87895, 0],
					[-114.67199, 43.87892, 0],
					[-114.67194, 43.87888, 0],
					[-114.6719, 43.87883, 0],
					[-114.67175, 43.87871, 0],
					[-114.67169, 43.87867, 0],
					[-114.67164, 43.87863, 0],
					[-114.67145, 43.87851, 0],
					[-114.67138, 43.87848, 0],
					[-114.67132, 43.87844, 0],
					[-114.6712, 43.87839, 0],
					[-114.67115, 43.87836, 0],
					[-114.67097, 43.87829, 0],
					[-114.67093, 43.87827, 0],
					[-114.67071, 43.87818, 0],
					[-114.67053, 43.87812, 0],
					[-114.67041, 43.87807, 0],
					[-114.66981, 43.87787, 0],
					[-114.66949, 43.87775, 0],
					[-114.66893, 43.87756, 0],
					[-114.6688, 43.87751, 0],
					[-114.66867, 43.87747, 0],
					[-114.66809, 43.87726, 0],
					[-114.66722, 43.8769, 0],
					[-114.66715, 43.87686, 0],
					[-114.66694, 43.87677, 0],
					[-114.66659, 43.87659, 0],
					[-114.66642, 43.87651, 0],
					[-114.66633, 43.87645, 0],
					[-114.66605, 43.87629, 0],
					[-114.6659, 43.87619, 0],
					[-114.66567, 43.87605, 0],
					[-114.66508, 43.87563, 0],
					[-114.66484, 43.87544, 0],
					[-114.66452, 43.87517, 0],
					[-114.66423, 43.87488, 0],
					[-114.66396, 43.87459, 0],
					[-114.66307, 43.87352, 0],
					[-114.66269, 43.87304, 0],
					[-114.66253, 43.87285, 0],
					[-114.66249, 43.87281, 0],
					[-114.66243, 43.87274, 0],
					[-114.66239, 43.8727, 0],
					[-114.66236, 43.87268, 0],
					[-114.6623, 43.87262, 0],
					[-114.66226, 43.87259, 0],
					[-114.66221, 43.87256, 0],
					[-114.66219, 43.87254, 0],
					[-114.662, 43.8724, 0],
					[-114.66178, 43.87227, 0],
					[-114.66158, 43.87217, 0],
					[-114.6615, 43.87214, 0],
					[-114.66145, 43.87213, 0],
					[-114.66129, 43.87207, 0],
					[-114.66122, 43.87205, 0],
					[-114.66117, 43.87204, 0],
					[-114.66107, 43.87201, 0],
					[-114.661, 43.872, 0],
					[-114.66094, 43.87198, 0],
					[-114.66081, 43.87196, 0],
					[-114.66061, 43.87194, 0],
					[-114.6605, 43.87192, 0],
					[-114.66032, 43.87191, 0],
					[-114.6602, 43.87191, 0],
					[-114.65982, 43.87193, 0],
					[-114.65955, 43.87197, 0],
					[-114.65946, 43.87199, 0],
					[-114.65887, 43.87209, 0],
					[-114.65852, 43.87216, 0],
					[-114.65851, 43.87217, 0],
					[-114.65846, 43.87217, 0],
					[-114.65843, 43.87218, 0],
					[-114.65837, 43.87219, 0],
					[-114.65827, 43.8722, 0],
					[-114.65818, 43.87222, 0],
					[-114.65803, 43.87224, 0],
					[-114.65796, 43.87224, 0],
					[-114.65778, 43.87226, 0],
					[-114.65771, 43.87226, 0],
					[-114.65762, 43.87227, 0],
					[-114.65726, 43.87227, 0],
					[-114.65722, 43.87226, 0],
					[-114.65713, 43.87226, 0],
					[-114.65705, 43.87225, 0],
					[-114.657, 43.87225, 0],
					[-114.65695, 43.87224, 0],
					[-114.65691, 43.87224, 0],
					[-114.65638, 43.87215, 0],
					[-114.65587, 43.87201, 0],
					[-114.65557, 43.87189, 0],
					[-114.65554, 43.87187, 0],
					[-114.65529, 43.87175, 0],
					[-114.65496, 43.87154, 0],
					[-114.65491, 43.8715, 0],
					[-114.65483, 43.87145, 0],
					[-114.6548, 43.87142, 0],
					[-114.65476, 43.87139, 0],
					[-114.65472, 43.87135, 0],
					[-114.6547, 43.87134, 0],
					[-114.65468, 43.87132, 0],
					[-114.65466, 43.87131, 0],
					[-114.65447, 43.87112, 0],
					[-114.65444, 43.87108, 0],
					[-114.65439, 43.87103, 0],
					[-114.65424, 43.87082, 0],
					[-114.65414, 43.87066, 0],
					[-114.65407, 43.87052, 0],
					[-114.65403, 43.87045, 0],
					[-114.65401, 43.87039, 0],
					[-114.65399, 43.87035, 0],
					[-114.65397, 43.8703, 0],
					[-114.65395, 43.87027, 0],
					[-114.65393, 43.8702, 0],
					[-114.65387, 43.87003, 0],
					[-114.65385, 43.86995, 0],
					[-114.65384, 43.86987, 0],
					[-114.65378, 43.86957, 0],
					[-114.65362, 43.86853, 0],
					[-114.65362, 43.86852, 0],
					[-114.65361, 43.86849, 0],
					[-114.65349, 43.86771, 0],
					[-114.65347, 43.86763, 0],
					[-114.65346, 43.86752, 0],
					[-114.65344, 43.86744, 0],
					[-114.65344, 43.86742, 0],
					[-114.65339, 43.86718, 0],
					[-114.65332, 43.86673, 0],
					[-114.65329, 43.86662, 0],
					[-114.65315, 43.86571, 0],
					[-114.65314, 43.86567, 0],
					[-114.65313, 43.86556, 0],
					[-114.65309, 43.86535, 0],
					[-114.65308, 43.86533, 0],
					[-114.65303, 43.86509, 0],
					[-114.65303, 43.86505, 0],
					[-114.65302, 43.865, 0],
					[-114.653, 43.86494, 0],
					[-114.65299, 43.86487, 0],
					[-114.65296, 43.86477, 0],
					[-114.65292, 43.8646, 0],
					[-114.65284, 43.86434, 0],
					[-114.65283, 43.86429, 0],
					[-114.65264, 43.86373, 0],
					[-114.65258, 43.86359, 0],
					[-114.65254, 43.86348, 0],
					[-114.65232, 43.86298, 0],
					[-114.65228, 43.86291, 0],
					[-114.65221, 43.86277, 0],
					[-114.65218, 43.86272, 0],
					[-114.65215, 43.86266, 0],
					[-114.6519, 43.86225, 0],
					[-114.65168, 43.86193, 0],
					[-114.65122, 43.8613, 0],
					[-114.65075, 43.86075, 0],
					[-114.6507, 43.8607, 0],
					[-114.65066, 43.86064, 0],
					[-114.65021, 43.86019, 0],
					[-114.6476, 43.85778, 0],
					[-114.64729, 43.85748, 0],
					[-114.64714, 43.85735, 0],
					[-114.64263, 43.85318, 0],
					[-114.64218, 43.85272, 0],
					[-114.64205, 43.85258, 0],
					[-114.64193, 43.85244, 0],
					[-114.64189, 43.8524, 0],
					[-114.64174, 43.85221, 0],
					[-114.6417, 43.85215, 0],
					[-114.64164, 43.85208, 0],
					[-114.6416, 43.85202, 0],
					[-114.64152, 43.85192, 0],
					[-114.6412, 43.85144, 0],
					[-114.64105, 43.85117, 0],
					[-114.64087, 43.85077, 0],
					[-114.64081, 43.85062, 0],
					[-114.64076, 43.85052, 0],
					[-114.64071, 43.85036, 0],
					[-114.64069, 43.85032, 0],
					[-114.64064, 43.85017, 0],
					[-114.64063, 43.85015, 0],
					[-114.64058, 43.84996, 0],
					[-114.64054, 43.84985, 0],
					[-114.64048, 43.84965, 0],
					[-114.64044, 43.84948, 0],
					[-114.64042, 43.84943, 0],
					[-114.64041, 43.84937, 0],
					[-114.64039, 43.84931, 0],
					[-114.64038, 43.84923, 0],
					[-114.64009, 43.84788, 0],
					[-114.64008, 43.84781, 0],
					[-114.64006, 43.84775, 0],
					[-114.64004, 43.84767, 0],
					[-114.64002, 43.84761, 0],
					[-114.64, 43.84752, 0],
					[-114.63997, 43.84745, 0],
					[-114.63995, 43.84738, 0],
					[-114.63988, 43.84723, 0],
					[-114.63985, 43.84715, 0],
					[-114.63981, 43.84707, 0],
					[-114.63977, 43.84701, 0],
					[-114.63974, 43.84694, 0],
					[-114.63973, 43.84693, 0],
					[-114.63971, 43.84688, 0],
					[-114.63962, 43.84675, 0],
					[-114.6396, 43.84671, 0],
					[-114.63953, 43.84662, 0],
					[-114.6395, 43.84657, 0],
					[-114.63934, 43.8464, 0],
					[-114.63929, 43.84634, 0],
					[-114.63922, 43.84628, 0],
					[-114.63919, 43.84624, 0],
					[-114.63875, 43.84588, 0],
					[-114.63869, 43.84584, 0],
					[-114.63864, 43.8458, 0],
					[-114.63831, 43.84557, 0],
					[-114.63825, 43.84552, 0],
					[-114.63759, 43.84507, 0],
					[-114.6371, 43.84472, 0],
					[-114.63699, 43.84465, 0],
					[-114.63367, 43.84237, 0],
					[-114.63344, 43.84223, 0],
					[-114.63291, 43.84194, 0],
					[-114.6326, 43.84179, 0],
					[-114.63219, 43.84161, 0],
					[-114.63202, 43.84155, 0],
					[-114.63149, 43.84134, 0],
					[-114.62894, 43.84044, 0],
					[-114.62879, 43.84038, 0],
					[-114.62867, 43.84034, 0],
					[-114.62816, 43.84014, 0],
					[-114.62791, 43.84003, 0],
					[-114.6279, 43.84003, 0],
					[-114.62773, 43.83995, 0],
					[-114.62765, 43.83992, 0],
					[-114.62716, 43.83967, 0],
					[-114.62674, 43.83943, 0],
					[-114.6264, 43.83919, 0],
					[-114.62622, 43.83904, 0],
					[-114.62617, 43.83901, 0],
					[-114.62612, 43.83896, 0],
					[-114.62603, 43.83889, 0],
					[-114.62593, 43.83879, 0],
					[-114.62588, 43.83875, 0],
					[-114.62583, 43.8387, 0],
					[-114.62577, 43.83863, 0],
					[-114.62571, 43.83857, 0],
					[-114.6256, 43.83844, 0],
					[-114.62556, 43.83838, 0],
					[-114.62551, 43.83832, 0],
					[-114.6254, 43.83817, 0],
					[-114.62521, 43.83787, 0],
					[-114.62519, 43.83783, 0],
					[-114.62517, 43.83778, 0],
					[-114.62515, 43.83774, 0],
					[-114.62513, 43.83769, 0],
					[-114.62508, 43.8376, 0],
					[-114.62507, 43.83756, 0],
					[-114.62503, 43.83748, 0],
					[-114.62502, 43.83742, 0],
					[-114.625, 43.83738, 0],
					[-114.62494, 43.83718, 0],
					[-114.62487, 43.83685, 0],
					[-114.62487, 43.83681, 0],
					[-114.62485, 43.8367, 0],
					[-114.62485, 43.83627, 0],
					[-114.62486, 43.83622, 0],
					[-114.62486, 43.8361, 0],
					[-114.62488, 43.83589, 0],
					[-114.62491, 43.8357, 0],
					[-114.62494, 43.8354, 0],
					[-114.6251, 43.83426, 0],
					[-114.62511, 43.83412, 0],
					[-114.62515, 43.83377, 0],
					[-114.62515, 43.83355, 0],
					[-114.62516, 43.8335, 0],
					[-114.62516, 43.83346, 0],
					[-114.62517, 43.83343, 0],
					[-114.62517, 43.83339, 0],
					[-114.62518, 43.83336, 0],
					[-114.62521, 43.83319, 0],
					[-114.62521, 43.83317, 0],
					[-114.62536, 43.83186, 0],
					[-114.62536, 43.83181, 0],
					[-114.62537, 43.83175, 0],
					[-114.62537, 43.83158, 0],
					[-114.62538, 43.83151, 0],
					[-114.62537, 43.83145, 0],
					[-114.62537, 43.83135, 0],
					[-114.62536, 43.83125, 0],
					[-114.62536, 43.83119, 0],
					[-114.62535, 43.83109, 0],
					[-114.62534, 43.83105, 0],
					[-114.62531, 43.83087, 0],
					[-114.62531, 43.83086, 0],
					[-114.62529, 43.83082, 0],
					[-114.62527, 43.83072, 0],
					[-114.62521, 43.83052, 0],
					[-114.62514, 43.83032, 0],
					[-114.6251, 43.83024, 0],
					[-114.62509, 43.8302, 0],
					[-114.62501, 43.83004, 0],
					[-114.62498, 43.82999, 0],
					[-114.62496, 43.82994, 0],
					[-114.6249, 43.82985, 0],
					[-114.62481, 43.82969, 0],
					[-114.62454, 43.8293, 0],
					[-114.62431, 43.82901, 0],
					[-114.62426, 43.82896, 0],
					[-114.62422, 43.8289, 0],
					[-114.62398, 43.82866, 0],
					[-114.62361, 43.82832, 0],
					[-114.62289, 43.82776, 0],
					[-114.62278, 43.8277, 0],
					[-114.62273, 43.82766, 0],
					[-114.62219, 43.82732, 0],
					[-114.62182, 43.8271, 0],
					[-114.62178, 43.82707, 0],
					[-114.62127, 43.82678, 0],
					[-114.62046, 43.82636, 0],
					[-114.62045, 43.82635, 0],
					[-114.62012, 43.82619, 0],
					[-114.61996, 43.82612, 0],
					[-114.61986, 43.82607, 0],
					[-114.61978, 43.82604, 0],
					[-114.61975, 43.82602, 0],
					[-114.61956, 43.82593, 0],
					[-114.61842, 43.82546, 0],
					[-114.61828, 43.82541, 0],
					[-114.61814, 43.82535, 0],
					[-114.61756, 43.82514, 0],
					[-114.6172, 43.82502, 0],
					[-114.61667, 43.82486, 0],
					[-114.61656, 43.82482, 0],
					[-114.61617, 43.82471, 0],
					[-114.61608, 43.82468, 0],
					[-114.61545, 43.82451, 0],
					[-114.61492, 43.82438, 0],
					[-114.61394, 43.82418, 0],
					[-114.61378, 43.82414, 0],
					[-114.61312, 43.82402, 0],
					[-114.6129, 43.82397, 0],
					[-114.61272, 43.82394, 0],
					[-114.61233, 43.82385, 0],
					[-114.6122, 43.82383, 0],
					[-114.61211, 43.82381, 0],
					[-114.61201, 43.82378, 0],
					[-114.61164, 43.82369, 0],
					[-114.61155, 43.82366, 0],
					[-114.61139, 43.82362, 0],
					[-114.61057, 43.82335, 0],
					[-114.61047, 43.82331, 0],
					[-114.61041, 43.82328, 0],
					[-114.61031, 43.82325, 0],
					[-114.61026, 43.82323, 0],
					[-114.6102, 43.8232, 0],
					[-114.61004, 43.82314, 0],
					[-114.60995, 43.8231, 0],
					[-114.60987, 43.82307, 0],
					[-114.60979, 43.82303, 0],
					[-114.60969, 43.82299, 0],
					[-114.60968, 43.82298, 0],
					[-114.60958, 43.82294, 0],
					[-114.60951, 43.8229, 0],
					[-114.60941, 43.82286, 0],
					[-114.60921, 43.82276, 0],
					[-114.60904, 43.82269, 0],
					[-114.60466, 43.82063, 0],
					[-114.60441, 43.82052, 0],
					[-114.60079, 43.81882, 0],
					[-114.60069, 43.81878, 0],
					[-114.59765, 43.81735, 0],
					[-114.59705, 43.81705, 0],
					[-114.59698, 43.81702, 0],
					[-114.59681, 43.81692, 0],
					[-114.59672, 43.81688, 0],
					[-114.59607, 43.81651, 0],
					[-114.59507, 43.81587, 0],
					[-114.59496, 43.81579, 0],
					[-114.59495, 43.81578, 0],
					[-114.59465, 43.81557, 0],
					[-114.5943, 43.8153, 0],
					[-114.59427, 43.81528, 0],
					[-114.59412, 43.81516, 0],
					[-114.5941, 43.81514, 0],
					[-114.59367, 43.81478, 0],
					[-114.5932, 43.81436, 0],
					[-114.59265, 43.81381, 0],
					[-114.59016, 43.81103, 0],
					[-114.59014, 43.811, 0],
					[-114.58955, 43.81033, 0],
					[-114.58908, 43.80982, 0],
					[-114.58905, 43.80978, 0],
					[-114.58884, 43.80955, 0],
					[-114.58875, 43.80944, 0],
					[-114.5884, 43.80906, 0],
					[-114.58802, 43.80862, 0],
					[-114.58794, 43.80854, 0],
					[-114.58295, 43.80292, 0],
					[-114.58292, 43.80288, 0],
					[-114.58275, 43.8027, 0],
					[-114.58273, 43.80267, 0],
					[-114.58268, 43.80262, 0],
					[-114.58266, 43.80259, 0],
					[-114.58242, 43.8023, 0],
					[-114.58149, 43.80109, 0],
					[-114.58142, 43.80099, 0],
					[-114.58137, 43.80093, 0],
					[-114.58133, 43.80087, 0],
					[-114.58115, 43.80064, 0],
					[-114.5809, 43.80029, 0],
					[-114.58088, 43.80027, 0],
					[-114.58079, 43.80013, 0],
					[-114.58043, 43.79966, 0],
					[-114.5803, 43.79947, 0],
					[-114.57787, 43.79622, 0],
					[-114.5766, 43.79458, 0],
					[-114.57648, 43.79446, 0],
					[-114.57626, 43.79421, 0],
					[-114.57509, 43.793, 0],
					[-114.57399, 43.79198, 0],
					[-114.57369, 43.79173, 0],
					[-114.57367, 43.79171, 0],
					[-114.5733, 43.7914, 0],
					[-114.57235, 43.79065, 0],
					[-114.57084, 43.78957, 0],
					[-114.56954, 43.78876, 0],
					[-114.56833, 43.78805, 0],
					[-114.56777, 43.78775, 0],
					[-114.56676, 43.78727, 0],
					[-114.56592, 43.7869, 0],
					[-114.56574, 43.78681, 0],
					[-114.56455, 43.78633, 0],
					[-114.56295, 43.78573, 0],
					[-114.56241, 43.78554, 0],
					[-114.56131, 43.78519, 0],
					[-114.55849, 43.78446, 0],
					[-114.55621, 43.78402, 0],
					[-114.5545, 43.78378, 0],
					[-114.55296, 43.78359, 0],
					[-114.54938, 43.7832, 0],
					[-114.54909, 43.78318, 0],
					[-114.54746, 43.78298, 0],
					[-114.54702, 43.78294, 0],
					[-114.53511, 43.78162, 0],
					[-114.53505, 43.78161, 0],
					[-114.51827, 43.77975, 0],
					[-114.51726, 43.77968, 0],
					[-114.51655, 43.77965, 0],
					[-114.5156, 43.77964, 0],
					[-114.51505, 43.77965, 0],
					[-114.51356, 43.77972, 0],
					[-114.51305, 43.77976, 0],
					[-114.51216, 43.77985, 0],
					[-114.51157, 43.77993, 0],
					[-114.51068, 43.78007, 0],
					[-114.50962, 43.78028, 0],
					[-114.509, 43.78043, 0],
					[-114.50885, 43.78046, 0],
					[-114.50737, 43.78086, 0],
					[-114.48272, 43.7889, 0],
					[-114.48238, 43.78902, 0],
					[-114.48204, 43.78912, 0],
					[-114.48087, 43.78951, 0],
					[-114.48054, 43.78961, 0],
					[-114.47252, 43.79225, 0],
					[-114.47231, 43.7923, 0],
					[-114.47198, 43.7924, 0],
					[-114.47157, 43.79254, 0],
					[-114.47097, 43.79272, 0],
					[-114.47063, 43.79281, 0],
					[-114.47049, 43.79284, 0],
					[-114.47017, 43.79293, 0],
					[-114.47001, 43.79296, 0],
					[-114.46957, 43.79307, 0],
					[-114.46919, 43.79314, 0],
					[-114.46902, 43.79318, 0],
					[-114.46884, 43.79321, 0],
					[-114.46866, 43.79325, 0],
					[-114.46813, 43.79334, 0],
					[-114.46796, 43.79336, 0],
					[-114.46761, 43.79342, 0],
					[-114.46743, 43.79344, 0],
					[-114.46712, 43.79349, 0],
					[-114.46586, 43.79364, 0],
					[-114.46559, 43.79366, 0],
					[-114.46473, 43.79376, 0],
					[-114.4613, 43.7941, 0],
					[-114.46113, 43.79411, 0],
					[-114.46051, 43.79417, 0],
					[-114.46032, 43.7942, 0],
					[-114.45956, 43.79427, 0],
					[-114.45935, 43.7943, 0],
					[-114.45904, 43.79432, 0],
					[-114.44832, 43.79538, 0],
					[-114.44808, 43.79541, 0],
					[-114.44712, 43.7955, 0],
					[-114.44709, 43.79551, 0],
					[-114.4456, 43.79564, 0],
					[-114.44471, 43.79569, 0],
					[-114.44293, 43.7957, 0],
					[-114.44191, 43.79566, 0],
					[-114.44067, 43.79556, 0],
					[-114.44064, 43.79556, 0],
					[-114.43945, 43.79541, 0],
					[-114.43943, 43.79541, 0],
					[-114.43921, 43.79538, 0],
					[-114.43918, 43.79537, 0],
					[-114.43869, 43.7953, 0],
					[-114.43848, 43.79526, 0],
					[-114.43844, 43.79526, 0],
					[-114.43719, 43.795, 0],
					[-114.43643, 43.79482, 0],
					[-114.43557, 43.79459, 0],
					[-114.43441, 43.79422, 0],
					[-114.43315, 43.79376, 0],
					[-114.43267, 43.79356, 0],
					[-114.43188, 43.7932, 0],
					[-114.4313, 43.79292, 0],
					[-114.43021, 43.79234, 0],
					[-114.42946, 43.79189, 0],
					[-114.42314, 43.78762, 0],
					[-114.42301, 43.78754, 0],
					[-114.42299, 43.78752, 0],
					[-114.42116, 43.78609, 0],
					[-114.42076, 43.78574, 0],
					[-114.41994, 43.78496, 0],
					[-114.41952, 43.78454, 0],
					[-114.41436, 43.77886, 0],
					[-114.41434, 43.77883, 0],
					[-114.4143, 43.7788, 0],
					[-114.41425, 43.77874, 0],
					[-114.41418, 43.77867, 0],
					[-114.41286, 43.77721, 0],
					[-114.41273, 43.77705, 0],
					[-114.41129, 43.77549, 0],
					[-114.41087, 43.77497, 0],
					[-114.41054, 43.77466, 0],
					[-114.41013, 43.77418, 0],
					[-114.40628, 43.76995, 0],
					[-114.40546, 43.76898, 0],
					[-114.39851, 43.76026, 0],
					[-114.39759, 43.75903, 0],
					[-114.39738, 43.75873, 0],
					[-114.39699, 43.75813, 0],
					[-114.39565, 43.75596, 0],
					[-114.3939, 43.75305, 0],
					[-114.39389, 43.75305, 0],
					[-114.39385, 43.75298, 0],
					[-114.39377, 43.75282, 0],
					[-114.39119, 43.74847, 0],
					[-114.39038, 43.74715, 0],
					[-114.39026, 43.74697, 0],
					[-114.38914, 43.74516, 0],
					[-114.38635, 43.74052, 0],
					[-114.38627, 43.74037, 0],
					[-114.38614, 43.7401, 0],
					[-114.38598, 43.7397, 0],
					[-114.38587, 43.7394, 0],
					[-114.38572, 43.73884, 0],
					[-114.38567, 43.73858, 0],
					[-114.38563, 43.73827, 0],
					[-114.3856, 43.73768, 0],
					[-114.3856, 43.73744, 0],
					[-114.38563, 43.73704, 0],
					[-114.38569, 43.73661, 0],
					[-114.38578, 43.73622, 0],
					[-114.38583, 43.73604, 0],
					[-114.38595, 43.73567, 0],
					[-114.38616, 43.73513, 0],
					[-114.38625, 43.73492, 0],
					[-114.38636, 43.73463, 0],
					[-114.38648, 43.73427, 0],
					[-114.38654, 43.73402, 0],
					[-114.38658, 43.7338, 0],
					[-114.38658, 43.7337, 0],
					[-114.38659, 43.73361, 0],
					[-114.38659, 43.73352, 0],
					[-114.38658, 43.73339, 0],
					[-114.38658, 43.73325, 0],
					[-114.3865, 43.73277, 0],
					[-114.38646, 43.73266, 0],
					[-114.3864, 43.73245, 0],
					[-114.38628, 43.7322, 0],
					[-114.38619, 43.73206, 0],
					[-114.38595, 43.73172, 0],
					[-114.38593, 43.7317, 0],
					[-114.38582, 43.73157, 0],
					[-114.38569, 43.7314, 0],
					[-114.38544, 43.73112, 0],
					[-114.38523, 43.73092, 0],
					[-114.38475, 43.73055, 0],
					[-114.38371, 43.72968, 0],
					[-114.38294, 43.72896, 0],
					[-114.38215, 43.72815, 0],
					[-114.38182, 43.72778, 0],
					[-114.38103, 43.72673, 0],
					[-114.38057, 43.72599, 0],
					[-114.38039, 43.72568, 0],
					[-114.37998, 43.72493, 0],
					[-114.37969, 43.72432, 0],
					[-114.37962, 43.72415, 0],
					[-114.37957, 43.72405, 0],
					[-114.37945, 43.72375, 0],
					[-114.37926, 43.72332, 0],
					[-114.37896, 43.72259, 0],
					[-114.37877, 43.72207, 0],
					[-114.37847, 43.72117, 0],
					[-114.37811, 43.71988, 0],
					[-114.3781, 43.71983, 0],
					[-114.3781, 43.71964, 0],
					[-114.37809, 43.71961, 0],
					[-114.37809, 43.71955, 0],
					[-114.37794, 43.71889, 0],
					[-114.37786, 43.71861, 0],
					[-114.37763, 43.71762, 0],
					[-114.37761, 43.71755, 0],
					[-114.37742, 43.71706, 0],
					[-114.37725, 43.71674, 0],
					[-114.37718, 43.71664, 0],
					[-114.3771, 43.71656, 0],
					[-114.37706, 43.71649, 0],
					[-114.37554, 43.71423, 0],
					[-114.37482, 43.7131, 0],
					[-114.37446, 43.71249, 0],
					[-114.37441, 43.71239, 0],
					[-114.37404, 43.71172, 0],
					[-114.37299, 43.70966, 0],
					[-114.37185, 43.70756, 0],
					[-114.37177, 43.70739, 0],
					[-114.37174, 43.70735, 0],
					[-114.37168, 43.70722, 0],
					[-114.37167, 43.70721, 0],
					[-114.37146, 43.70675, 0],
					[-114.37145, 43.70671, 0],
					[-114.37142, 43.70664, 0],
					[-114.37121, 43.706, 0],
					[-114.3707, 43.70382, 0],
					[-114.37068, 43.70379, 0],
					[-114.37029, 43.70204, 0],
					[-114.37028, 43.70202, 0],
					[-114.37003, 43.70104, 0],
					[-114.36858, 43.69498, 0],
					[-114.36858, 43.69481, 0],
					[-114.36845, 43.69429, 0],
					[-114.36834, 43.69375, 0],
					[-114.36829, 43.69356, 0],
					[-114.36816, 43.69279, 0],
					[-114.36802, 43.69132, 0],
					[-114.36797, 43.69066, 0],
					[-114.36796, 43.69064, 0],
					[-114.36796, 43.69063, 0],
					[-114.36795, 43.69062, 0],
					[-114.36792, 43.69056, 0],
					[-114.36739, 43.6843, 0],
					[-114.36736, 43.68407, 0],
					[-114.36736, 43.68403, 0],
					[-114.36734, 43.68394, 0],
					[-114.36732, 43.68388, 0],
					[-114.36728, 43.68373, 0],
					[-114.36725, 43.68364, 0],
					[-114.36708, 43.68324, 0],
					[-114.36702, 43.68312, 0],
					[-114.36689, 43.6829, 0],
					[-114.36675, 43.68271, 0],
					[-114.36671, 43.68264, 0],
					[-114.36665, 43.68257, 0],
					[-114.36622, 43.68213, 0],
					[-114.36617, 43.68209, 0],
					[-114.36606, 43.68199, 0],
					[-114.36433, 43.6807, 0],
					[-114.36349, 43.6801, 0],
					[-114.36271, 43.67951, 0],
					[-114.36192, 43.67894, 0],
					[-114.36171, 43.67876, 0],
					[-114.36169, 43.67875, 0],
					[-114.36159, 43.67865, 0],
					[-114.36156, 43.67861, 0],
					[-114.36152, 43.67857, 0],
					[-114.3615, 43.67853, 0],
					[-114.36146, 43.67848, 0],
					[-114.36141, 43.6784, 0],
					[-114.36136, 43.6783, 0],
					[-114.36135, 43.67829, 0],
					[-114.36134, 43.67826, 0],
					[-114.36132, 43.67822, 0],
					[-114.3613, 43.67816, 0],
					[-114.36126, 43.67796, 0],
					[-114.36126, 43.67791, 0],
					[-114.36125, 43.6778, 0],
					[-114.36125, 43.67776, 0],
					[-114.36124, 43.67769, 0],
					[-114.36123, 43.6774, 0],
					[-114.36123, 43.67665, 0],
					[-114.36118, 43.67394, 0],
					[-114.36115, 43.67324, 0],
					[-114.36115, 43.67309, 0],
					[-114.36111, 43.67285, 0],
					[-114.36109, 43.67278, 0],
					[-114.36109, 43.67275, 0],
					[-114.36107, 43.67271, 0],
					[-114.36104, 43.67261, 0],
					[-114.36101, 43.67254, 0],
					[-114.36096, 43.67244, 0],
					[-114.36088, 43.67231, 0],
					[-114.36083, 43.67224, 0],
					[-114.36078, 43.67218, 0],
					[-114.36073, 43.67213, 0],
					[-114.3607, 43.67209, 0],
					[-114.36057, 43.67196, 0],
					[-114.36049, 43.67189, 0],
					[-114.36037, 43.6718, 0],
					[-114.36029, 43.67173, 0],
					[-114.35927, 43.671, 0],
					[-114.35867, 43.67059, 0],
					[-114.35719, 43.66954, 0],
					[-114.35714, 43.66949, 0],
					[-114.35709, 43.66946, 0],
					[-114.35695, 43.66932, 0],
					[-114.35685, 43.66921, 0],
					[-114.3568, 43.66914, 0],
					[-114.35675, 43.66909, 0],
					[-114.35671, 43.66902, 0],
					[-114.35653, 43.66875, 0],
					[-114.35601, 43.66777, 0],
					[-114.35351, 43.66331, 0],
					[-114.35314, 43.66261, 0],
					[-114.35292, 43.66215, 0],
					[-114.35262, 43.66146, 0],
					[-114.3506, 43.65723, 0],
					[-114.35041, 43.65686, 0],
					[-114.34945, 43.65516, 0],
					[-114.34821, 43.6525, 0],
					[-114.34749, 43.65074, 0],
					[-114.34735, 43.65037, 0],
					[-114.34733, 43.65029, 0],
					[-114.34732, 43.65023, 0],
					[-114.3473, 43.65017, 0],
					[-114.34728, 43.65008, 0],
					[-114.34726, 43.64994, 0],
					[-114.34726, 43.64986, 0],
					[-114.34725, 43.64977, 0],
					[-114.34725, 43.64946, 0],
					[-114.34726, 43.64939, 0],
					[-114.34726, 43.64936, 0],
					[-114.34729, 43.6492, 0],
					[-114.34732, 43.64909, 0],
					[-114.34736, 43.64891, 0],
					[-114.34739, 43.64882, 0],
					[-114.34742, 43.64876, 0],
					[-114.34744, 43.6487, 0],
					[-114.3475, 43.64858, 0],
					[-114.34765, 43.64832, 0],
					[-114.34771, 43.64823, 0],
					[-114.34775, 43.64818, 0],
					[-114.3478, 43.6481, 0],
					[-114.34792, 43.64796, 0],
					[-114.34807, 43.64781, 0],
					[-114.34817, 43.6477, 0],
					[-114.34827, 43.64762, 0],
					[-114.34837, 43.64753, 0],
					[-114.34894, 43.64712, 0],
					[-114.34951, 43.64673, 0],
					[-114.34973, 43.64657, 0],
					[-114.35003, 43.64631, 0],
					[-114.35012, 43.64624, 0],
					[-114.35019, 43.64616, 0],
					[-114.35028, 43.64607, 0],
					[-114.35043, 43.6459, 0],
					[-114.35045, 43.64589, 0],
					[-114.35066, 43.64558, 0],
					[-114.35067, 43.64557, 0],
					[-114.35068, 43.64555, 0],
					[-114.35075, 43.64544, 0],
					[-114.35095, 43.64507, 0],
					[-114.35103, 43.6449, 0],
					[-114.35108, 43.64481, 0],
					[-114.35442, 43.63794, 0],
					[-114.35461, 43.6376, 0],
					[-114.35521, 43.63635, 0],
					[-114.3554, 43.63593, 0],
					[-114.35566, 43.6352, 0],
					[-114.35595, 43.63408, 0],
					[-114.35602, 43.63365, 0],
					[-114.35603, 43.63355, 0],
					[-114.35604, 43.63349, 0],
					[-114.35608, 43.63301, 0],
					[-114.35609, 43.63244, 0],
					[-114.35603, 43.63135, 0],
					[-114.35536, 43.62454, 0],
					[-114.35516, 43.62303, 0],
					[-114.3549, 43.62172, 0],
					[-114.35471, 43.62091, 0],
					[-114.35447, 43.6196, 0],
					[-114.35429, 43.61878, 0],
					[-114.35427, 43.61863, 0],
					[-114.35361, 43.61542, 0],
					[-114.35338, 43.61444, 0],
					[-114.35339, 43.61428, 0],
					[-114.35319, 43.61328, 0],
					[-114.35308, 43.61266, 0],
					[-114.35304, 43.61248, 0],
					[-114.35287, 43.61155, 0],
					[-114.35263, 43.60998, 0],
					[-114.35254, 43.6097, 0],
					[-114.35253, 43.60966, 0],
					[-114.35249, 43.60938, 0],
					[-114.3523, 43.60828, 0],
					[-114.35214, 43.60716, 0],
					[-114.352, 43.60645, 0],
					[-114.35189, 43.60605, 0],
					[-114.35183, 43.60586, 0],
					[-114.35183, 43.60583, 0],
					[-114.35185, 43.60571, 0],
					[-114.35178, 43.60547, 0],
					[-114.35155, 43.60485, 0],
					[-114.35109, 43.60385, 0],
					[-114.35098, 43.60368, 0],
					[-114.35042, 43.60274, 0],
					[-114.35028, 43.60253, 0],
					[-114.35023, 43.60247, 0],
					[-114.35012, 43.6023, 0],
					[-114.35002, 43.60216, 0],
					[-114.34998, 43.60213, 0],
					[-114.3498, 43.60194, 0],
					[-114.34867, 43.60029, 0],
					[-114.34799, 43.59937, 0],
					[-114.34779, 43.59908, 0],
					[-114.34735, 43.59849, 0],
					[-114.34652, 43.5973, 0],
					[-114.34488, 43.59486, 0],
					[-114.34435, 43.59392, 0],
					[-114.34417, 43.59355, 0],
					[-114.34415, 43.5935, 0],
					[-114.34363, 43.59241, 0],
					[-114.34314, 43.59133, 0],
					[-114.34289, 43.59081, 0],
					[-114.34251, 43.58997, 0],
					[-114.342, 43.5889, 0],
					[-114.3418, 43.58843, 0],
					[-114.34118, 43.58711, 0],
					[-114.34086, 43.58646, 0],
					[-114.33908, 43.58253, 0],
					[-114.33853, 43.58127, 0],
					[-114.33716, 43.57834, 0],
					[-114.337, 43.57802, 0],
					[-114.33393, 43.57128, 0],
					[-114.33392, 43.57119, 0],
					[-114.3339, 43.57108, 0],
					[-114.3336, 43.57041, 0],
					[-114.33193, 43.56684, 0],
					[-114.33151, 43.5659, 0],
					[-114.33145, 43.56578, 0],
					[-114.33133, 43.56558, 0],
					[-114.33124, 43.56525, 0],
					[-114.33123, 43.56497, 0],
					[-114.33124, 43.56466, 0],
					[-114.33124, 43.5643, 0],
					[-114.33146, 43.55767, 0],
					[-114.33146, 43.55729, 0],
					[-114.33165, 43.55042, 0],
					[-114.33164, 43.54999, 0],
					[-114.33162, 43.5497, 0],
					[-114.33159, 43.54941, 0],
					[-114.33154, 43.54913, 0],
					[-114.3315, 43.54896, 0],
					[-114.33148, 43.54885, 0],
					[-114.33145, 43.54873, 0],
					[-114.33141, 43.54861, 0],
					[-114.33138, 43.54849, 0],
					[-114.33132, 43.54832, 0],
					[-114.3313, 43.54828, 0],
					[-114.33116, 43.54793, 0],
					[-114.33098, 43.54752, 0],
					[-114.32715, 43.53987, 0],
					[-114.32711, 43.53977, 0],
					[-114.32691, 43.53934, 0],
					[-114.32677, 43.539, 0],
					[-114.32664, 43.53864, 0],
					[-114.32509, 43.53361, 0],
					[-114.3248, 43.53284, 0],
					[-114.32469, 43.53258, 0],
					[-114.32444, 43.53206, 0],
					[-114.32443, 43.53203, 0],
					[-114.32403, 43.53128, 0],
					[-114.3237, 43.53074, 0],
					[-114.32306, 43.52981, 0],
					[-114.32275, 43.52938, 0],
					[-114.32246, 43.52901, 0],
					[-114.32006, 43.5258, 0],
					[-114.31979, 43.52542, 0],
					[-114.31538, 43.51966, 0],
					[-114.31465, 43.51867, 0],
					[-114.31317, 43.51674, 0],
					[-114.31174, 43.51483, 0],
					[-114.31067, 43.51345, 0],
					[-114.31062, 43.5134, 0],
					[-114.31057, 43.51334, 0],
					[-114.31051, 43.51329, 0],
					[-114.31047, 43.51325, 0],
					[-114.31038, 43.51318, 0],
					[-114.3102, 43.51306, 0],
					[-114.30991, 43.51291, 0],
					[-114.3098, 43.51286, 0],
					[-114.30935, 43.51273, 0],
					[-114.30928, 43.51272, 0],
					[-114.30922, 43.5127, 0],
					[-114.30899, 43.51267, 0],
					[-114.3089, 43.51267, 0],
					[-114.30865, 43.51265, 0],
					[-114.30852, 43.51266, 0],
					[-114.30842, 43.51266, 0],
					[-114.30831, 43.51267, 0],
					[-114.30819, 43.51269, 0],
					[-114.30814, 43.51269, 0],
					[-114.30805, 43.51271, 0],
					[-114.30797, 43.51272, 0],
					[-114.30784, 43.51275, 0],
					[-114.30748, 43.51286, 0],
					[-114.30698, 43.51304, 0],
					[-114.30677, 43.5131, 0],
					[-114.30654, 43.51318, 0],
					[-114.30633, 43.51323, 0],
					[-114.30619, 43.51327, 0],
					[-114.30611, 43.51328, 0],
					[-114.30602, 43.5133, 0],
					[-114.30584, 43.51332, 0],
					[-114.30573, 43.51334, 0],
					[-114.30568, 43.51334, 0],
					[-114.30563, 43.51335, 0],
					[-114.30555, 43.51335, 0],
					[-114.30547, 43.51336, 0],
					[-114.30492, 43.51336, 0],
					[-114.30467, 43.51334, 0],
					[-114.30441, 43.51331, 0],
					[-114.30402, 43.51325, 0],
					[-114.30396, 43.51323, 0],
					[-114.30387, 43.51321, 0],
					[-114.30362, 43.51314, 0],
					[-114.3035, 43.5131, 0],
					[-114.30332, 43.51303, 0],
					[-114.30322, 43.513, 0],
					[-114.30308, 43.51294, 0],
					[-114.30267, 43.51272, 0],
					[-114.30257, 43.51266, 0],
					[-114.30253, 43.51264, 0],
					[-114.3025, 43.51261, 0],
					[-114.30245, 43.51258, 0],
					[-114.30231, 43.51248, 0],
					[-114.30226, 43.51243, 0],
					[-114.30205, 43.51225, 0],
					[-114.30084, 43.51103, 0],
					[-114.2839, 43.49353, 0],
					[-114.28375, 43.49339, 0],
					[-114.27934, 43.48882, 0],
					[-114.27926, 43.48875, 0],
					[-114.27055, 43.47977, 0],
					[-114.27038, 43.47958, 0],
					[-114.26849, 43.47763, 0],
					[-114.26834, 43.47749, 0],
					[-114.26654, 43.47564, 0],
					[-114.26612, 43.47518, 0],
					[-114.2658, 43.47479, 0],
					[-114.26553, 43.47441, 0],
					[-114.26528, 43.474, 0],
					[-114.26508, 43.47362, 0],
					[-114.26436, 43.47205, 0],
					[-114.26431, 43.47192, 0],
					[-114.26283, 43.46877, 0],
					[-114.2626, 43.46824, 0],
					[-114.26254, 43.46807, 0],
					[-114.25643, 43.45465, 0],
					[-114.25503, 43.45165, 0],
					[-114.25413, 43.44957, 0],
					[-114.25261, 43.44626, 0],
					[-114.25237, 43.44566, 0],
					[-114.25229, 43.44539, 0],
					[-114.25223, 43.44512, 0],
					[-114.25221, 43.44499, 0],
					[-114.25219, 43.44458, 0],
					[-114.25223, 43.42304, 0],
					[-114.25228, 43.41689, 0],
					[-114.25233, 43.41525, 0],
					[-114.25225, 43.40567, 0],
					[-114.25226, 43.40187, 0],
					[-114.25229, 43.40138, 0],
					[-114.25231, 43.40117, 0],
					[-114.25236, 43.40092, 0],
					[-114.25246, 43.4006, 0],
					[-114.25251, 43.40046, 0],
					[-114.25265, 43.40012, 0],
					[-114.2529, 43.39968, 0],
					[-114.25302, 43.3995, 0],
					[-114.25331, 43.39912, 0],
					[-114.25339, 43.39904, 0],
					[-114.25348, 43.39893, 0],
					[-114.25362, 43.39878, 0],
					[-114.2539, 43.39852, 0],
					[-114.25414, 43.39832, 0],
					[-114.25841, 43.39504, 0],
					[-114.25891, 43.39464, 0],
					[-114.25918, 43.39444, 0],
					[-114.25937, 43.39428, 0],
					[-114.25968, 43.39404, 0],
					[-114.25991, 43.39382, 0],
					[-114.26017, 43.39354, 0],
					[-114.26046, 43.39318, 0],
					[-114.26062, 43.39296, 0],
					[-114.26075, 43.39275, 0],
					[-114.26102, 43.39222, 0],
					[-114.26112, 43.39196, 0],
					[-114.26119, 43.3918, 0],
					[-114.26196, 43.38971, 0],
					[-114.262, 43.38956, 0],
					[-114.26205, 43.38941, 0],
					[-114.26213, 43.38913, 0],
					[-114.26232, 43.38823, 0],
					[-114.2625, 43.38708, 0],
					[-114.26251, 43.38704, 0],
					[-114.26287, 43.38475, 0],
					[-114.26297, 43.3842, 0],
					[-114.26308, 43.38386, 0],
					[-114.26311, 43.38375, 0],
					[-114.26326, 43.38339, 0],
					[-114.26363, 43.38268, 0],
					[-114.2665, 43.37773, 0],
					[-114.2665, 43.37771, 0],
					[-114.26666, 43.3774, 0],
					[-114.26682, 43.37703, 0],
					[-114.26683, 43.37702, 0],
					[-114.26692, 43.37671, 0],
					[-114.267, 43.37632, 0],
					[-114.267, 43.3763, 0],
					[-114.26702, 43.37618, 0],
					[-114.26703, 43.37609, 0],
					[-114.26704, 43.37584, 0],
					[-114.26705, 43.3744, 0],
					[-114.26703, 43.37335, 0],
					[-114.26701, 43.36923, 0],
					[-114.26702, 43.36907, 0],
					[-114.26706, 43.36875, 0],
					[-114.26718, 43.36803, 0],
					[-114.26723, 43.36779, 0],
					[-114.26728, 43.36761, 0],
					[-114.26729, 43.36759, 0],
					[-114.2674, 43.36723, 0],
					[-114.26746, 43.36706, 0],
					[-114.26761, 43.36669, 0],
					[-114.26782, 43.36623, 0],
					[-114.26864, 43.36465, 0],
					[-114.26906, 43.36391, 0],
					[-114.26943, 43.36319, 0],
					[-114.27157, 43.35923, 0],
					[-114.27175, 43.35885, 0],
					[-114.27193, 43.3584, 0],
					[-114.27202, 43.35806, 0],
					[-114.27206, 43.35784, 0],
					[-114.27207, 43.35772, 0],
					[-114.27213, 43.35737, 0],
					[-114.27249, 43.354, 0],
					[-114.27255, 43.35359, 0],
					[-114.27258, 43.35344, 0],
					[-114.27267, 43.35315, 0],
					[-114.27285, 43.3527, 0],
					[-114.27538, 43.34673, 0],
					[-114.2756, 43.3461, 0],
					[-114.27565, 43.34591, 0],
					[-114.27572, 43.34568, 0],
					[-114.27587, 43.34508, 0],
					[-114.27869, 43.33293, 0],
					[-114.27875, 43.3327, 0],
					[-114.27882, 43.33236, 0],
					[-114.28034, 43.32602, 0],
					[-114.28058, 43.32494, 0],
					[-114.28063, 43.32476, 0],
					[-114.28068, 43.32451, 0],
					[-114.28071, 43.3244, 0],
					[-114.28087, 43.32369, 0],
					[-114.28087, 43.32368, 0],
					[-114.28103, 43.32298, 0],
					[-114.28113, 43.3226, 0],
					[-114.2812, 43.32228, 0],
					[-114.2812, 43.32227, 0],
					[-114.28142, 43.32126, 0],
					[-114.28151, 43.32094, 0],
					[-114.28152, 43.32081, 0],
					[-114.28185, 43.31945, 0],
					[-114.28185, 43.31943, 0],
					[-114.28192, 43.31916, 0],
					[-114.28209, 43.31859, 0],
					[-114.28217, 43.31838, 0],
					[-114.28234, 43.31804, 0],
					[-114.28247, 43.31782, 0],
					[-114.28275, 43.31739, 0],
					[-114.28299, 43.31708, 0],
					[-114.28299, 43.31707, 0],
					[-114.2831, 43.31693, 0],
					[-114.28311, 43.31691, 0],
					[-114.28334, 43.3166, 0],
					[-114.28335, 43.3166, 0],
					[-114.28346, 43.31644, 0],
					[-114.28367, 43.31616, 0],
					[-114.2837, 43.31613, 0],
					[-114.2837, 43.31612, 0],
					[-114.28382, 43.31597, 0],
					[-114.28385, 43.31592, 0],
					[-114.2843, 43.31535, 0],
					[-114.28431, 43.31534, 0],
					[-114.28442, 43.31519, 0],
					[-114.28443, 43.31518, 0],
					[-114.28455, 43.31503, 0],
					[-114.28466, 43.31487, 0],
					[-114.28467, 43.31487, 0],
					[-114.28502, 43.3144, 0],
					[-114.28503, 43.3144, 0],
					[-114.28514, 43.31424, 0],
					[-114.28579, 43.31342, 0],
					[-114.28583, 43.31336, 0],
					[-114.28607, 43.31305, 0],
					[-114.28609, 43.31302, 0],
					[-114.28655, 43.31244, 0],
					[-114.28662, 43.31234, 0],
					[-114.28726, 43.31154, 0],
					[-114.28729, 43.31151, 0],
					[-114.28798, 43.3106, 0],
					[-114.288, 43.31058, 0],
					[-114.2888, 43.30949, 0],
					[-114.28916, 43.30902, 0],
					[-114.28927, 43.30886, 0],
					[-114.28975, 43.30824, 0],
					[-114.29058, 43.3071, 0],
					[-114.29089, 43.30665, 0],
					[-114.2909, 43.30664, 0],
					[-114.29135, 43.30596, 0],
					[-114.29301, 43.3036, 0],
					[-114.29301, 43.30359, 0],
					[-114.29368, 43.30263, 0],
					[-114.29369, 43.30263, 0],
					[-114.29379, 43.30247, 0],
					[-114.29505, 43.30072, 0],
					[-114.29506, 43.30071, 0],
					[-114.2965, 43.29863, 0],
					[-114.29662, 43.29847, 0],
					[-114.29718, 43.29767, 0],
					[-114.2973, 43.29752, 0],
					[-114.29731, 43.29749, 0],
					[-114.29781, 43.29679, 0],
					[-114.29822, 43.29606, 0],
					[-114.2986, 43.29533, 0],
					[-114.29889, 43.29469, 0],
					[-114.29909, 43.29417, 0],
					[-114.29909, 43.29416, 0],
					[-114.29922, 43.29384, 0],
					[-114.29922, 43.29382, 0],
					[-114.29937, 43.2933, 0],
					[-114.29949, 43.29284, 0],
					[-114.2997, 43.29188, 0],
					[-114.2997, 43.29184, 0],
					[-114.29977, 43.29152, 0],
					[-114.29978, 43.29151, 0],
					[-114.30004, 43.29033, 0],
					[-114.30012, 43.28992, 0],
					[-114.30024, 43.28938, 0],
					[-114.30027, 43.28921, 0],
					[-114.30051, 43.28814, 0],
					[-114.30054, 43.28797, 0],
					[-114.30054, 43.28796, 0],
					[-114.30079, 43.28685, 0],
					[-114.30081, 43.28672, 0],
					[-114.30088, 43.28637, 0],
					[-114.30088, 43.28636, 0],
					[-114.30092, 43.28618, 0],
					[-114.30095, 43.28601, 0],
					[-114.30099, 43.28583, 0],
					[-114.30102, 43.28566, 0],
					[-114.30102, 43.28565, 0],
					[-114.30127, 43.2844, 0],
					[-114.30128, 43.28431, 0],
					[-114.3013, 43.28423, 0],
					[-114.30144, 43.28352, 0],
					[-114.30145, 43.28351, 0],
					[-114.30148, 43.28334, 0],
					[-114.30148, 43.28333, 0],
					[-114.30163, 43.28263, 0],
					[-114.30166, 43.28245, 0],
					[-114.3017, 43.28227, 0],
					[-114.30173, 43.2821, 0],
					[-114.30182, 43.28175, 0],
					[-114.30197, 43.28125, 0],
					[-114.3026, 43.27939, 0],
					[-114.30264, 43.27929, 0],
					[-114.30283, 43.27869, 0],
					[-114.3045, 43.27422, 0],
					[-114.30452, 43.27418, 0],
					[-114.30499, 43.27291, 0],
					[-114.30526, 43.27213, 0],
					[-114.30527, 43.27209, 0],
					[-114.30609, 43.26983, 0],
					[-114.30616, 43.26966, 0],
					[-114.30628, 43.26931, 0],
					[-114.30635, 43.26914, 0],
					[-114.30647, 43.26879, 0],
					[-114.30654, 43.26862, 0],
					[-114.30654, 43.2686, 0],
					[-114.30671, 43.26812, 0],
					[-114.30671, 43.26809, 0],
					[-114.30679, 43.2678, 0],
					[-114.3068, 43.26774, 0],
					[-114.30704, 43.26673, 0],
					[-114.30705, 43.26667, 0],
					[-114.30709, 43.2665, 0],
					[-114.30719, 43.26596, 0],
					[-114.30722, 43.26584, 0],
					[-114.30747, 43.26456, 0],
					[-114.30748, 43.26454, 0],
					[-114.30754, 43.26418, 0],
					[-114.30758, 43.264, 0],
					[-114.30759, 43.26393, 0],
					[-114.30762, 43.26383, 0],
					[-114.30766, 43.26365, 0],
					[-114.30769, 43.26347, 0],
					[-114.30773, 43.26329, 0],
					[-114.30776, 43.26311, 0],
					[-114.30784, 43.26276, 0],
					[-114.30794, 43.26222, 0],
					[-114.30798, 43.26205, 0],
					[-114.30844, 43.25973, 0],
					[-114.30882, 43.25795, 0],
					[-114.30892, 43.25739, 0],
					[-114.30899, 43.25706, 0],
					[-114.30902, 43.25688, 0],
					[-114.30916, 43.25619, 0],
					[-114.30934, 43.2551, 0],
					[-114.30958, 43.25313, 0],
					[-114.30961, 43.25295, 0],
					[-114.30968, 43.25241, 0],
					[-114.30968, 43.25239, 0],
					[-114.30971, 43.25223, 0],
					[-114.30973, 43.25205, 0],
					[-114.30979, 43.2517, 0],
					[-114.31, 43.25011, 0],
					[-114.31, 43.25009, 0],
					[-114.31028, 43.24812, 0],
					[-114.3103, 43.24794, 0],
					[-114.31038, 43.2474, 0],
					[-114.3104, 43.24722, 0],
					[-114.31042, 43.24711, 0],
					[-114.31068, 43.24509, 0],
					[-114.31081, 43.24454, 0],
					[-114.31082, 43.24451, 0],
					[-114.31088, 43.24401, 0],
					[-114.31098, 43.24299, 0],
					[-114.31112, 43.24182, 0],
					[-114.31129, 43.24017, 0],
					[-114.31179, 43.23627, 0],
					[-114.3118, 43.23623, 0],
					[-114.31501, 43.21256, 0],
					[-114.3152, 43.21129, 0],
					[-114.31544, 43.20938, 0],
					[-114.31566, 43.20787, 0],
					[-114.3158, 43.20706, 0],
					[-114.31596, 43.20623, 0],
					[-114.3163, 43.20472, 0],
					[-114.31658, 43.20365, 0],
					[-114.31733, 43.20128, 0],
					[-114.31739, 43.20114, 0],
					[-114.31745, 43.20097, 0],
					[-114.31755, 43.20063, 0],
					[-114.31809, 43.19924, 0],
					[-114.31895, 43.19724, 0],
					[-114.31953, 43.19608, 0],
					[-114.31957, 43.19599, 0],
					[-114.32824, 43.17926, 0],
					[-114.32833, 43.1791, 0],
					[-114.3291, 43.17762, 0],
					[-114.3291, 43.17761, 0],
					[-114.32932, 43.17719, 0],
					[-114.32937, 43.17708, 0],
					[-114.33019, 43.17552, 0],
					[-114.33023, 43.17543, 0],
					[-114.33044, 43.17501, 0],
					[-114.33049, 43.17493, 0],
					[-114.3313, 43.17338, 0],
					[-114.33136, 43.17325, 0],
					[-114.33232, 43.17139, 0],
					[-114.33234, 43.17136, 0],
					[-114.33318, 43.16975, 0],
					[-114.33328, 43.16954, 0],
					[-114.33383, 43.1685, 0],
					[-114.33404, 43.16807, 0],
					[-114.33461, 43.16698, 0],
					[-114.33464, 43.16691, 0],
					[-114.33569, 43.1649, 0],
					[-114.33602, 43.16423, 0],
					[-114.34059, 43.15544, 0],
					[-114.34068, 43.15528, 0],
					[-114.34138, 43.15394, 0],
					[-114.3414, 43.15388, 0],
					[-114.34233, 43.15208, 0],
					[-114.34236, 43.15203, 0],
					[-114.34304, 43.15074, 0],
					[-114.34312, 43.15057, 0],
					[-114.34337, 43.15011, 0],
					[-114.34495, 43.14704, 0],
					[-114.34503, 43.14687, 0],
					[-114.34647, 43.14411, 0],
					[-114.3466, 43.14384, 0],
					[-114.34994, 43.13741, 0],
					[-114.35, 43.13727, 0],
					[-114.35002, 43.13724, 0],
					[-114.35673, 43.12428, 0],
					[-114.35679, 43.12418, 0],
					[-114.36196, 43.11418, 0],
					[-114.36204, 43.11401, 0],
					[-114.37654, 43.08599, 0],
					[-114.37662, 43.08582, 0],
					[-114.37843, 43.08234, 0],
					[-114.37845, 43.08229, 0],
					[-114.38063, 43.07808, 0],
					[-114.38068, 43.07797, 0],
					[-114.38092, 43.07752, 0],
					[-114.38112, 43.0771, 0],
					[-114.39164, 43.05676, 0],
					[-114.39241, 43.05514, 0],
					[-114.39264, 43.05458, 0],
					[-114.39272, 43.05441, 0],
					[-114.39329, 43.053, 0],
					[-114.39372, 43.05178, 0],
					[-114.39397, 43.05101, 0],
					[-114.39448, 43.04924, 0],
					[-114.39493, 43.04729, 0],
					[-114.39508, 43.04652, 0],
					[-114.39543, 43.04421, 0],
					[-114.39558, 43.04241, 0],
					[-114.39557, 43.0421, 0],
					[-114.39561, 43.041, 0],
					[-114.39542, 43.03652, 0],
					[-114.39547, 43.0246, 0],
					[-114.39546, 43.02424, 0],
					[-114.3954, 43.00954, 0],
					[-114.39535, 43.00746, 0],
					[-114.39526, 42.99959, 0],
					[-114.39526, 42.99807, 0],
					[-114.39525, 42.99795, 0],
					[-114.39517, 42.99152, 0],
					[-114.39516, 42.99134, 0],
					[-114.39516, 42.9904, 0],
					[-114.39514, 42.98993, 0],
					[-114.39507, 42.98288, 0],
					[-114.39508, 42.98276, 0],
					[-114.39507, 42.98246, 0],
					[-114.39509, 42.98208, 0],
					[-114.39515, 42.98155, 0],
					[-114.39529, 42.98075, 0],
					[-114.39547, 42.98005, 0],
					[-114.39563, 42.97955, 0],
					[-114.39875, 42.97194, 0],
					[-114.39876, 42.97189, 0],
					[-114.39938, 42.97033, 0],
					[-114.3996, 42.96964, 0],
					[-114.39964, 42.96953, 0],
					[-114.39984, 42.9689, 0],
					[-114.40034, 42.96744, 0],
					[-114.40134, 42.96464, 0],
					[-114.40172, 42.96363, 0],
					[-114.40217, 42.96235, 0],
					[-114.40258, 42.96111, 0],
					[-114.40274, 42.96049, 0],
					[-114.40279, 42.96032, 0],
					[-114.40285, 42.96008, 0],
					[-114.40314, 42.95872, 0],
					[-114.40314, 42.9587, 0],
					[-114.40323, 42.95816, 0],
					[-114.4034, 42.95684, 0],
					[-114.40344, 42.95621, 0],
					[-114.40348, 42.95583, 0],
					[-114.40349, 42.95565, 0],
					[-114.4035, 42.95562, 0],
					[-114.4035, 42.95557, 0],
					[-114.40351, 42.95544, 0],
					[-114.40351, 42.95539, 0],
					[-114.4036, 42.95437, 0],
					[-114.40361, 42.95431, 0],
					[-114.40379, 42.95205, 0],
					[-114.4038, 42.95199, 0],
					[-114.40409, 42.94907, 0],
					[-114.40411, 42.94898, 0],
					[-114.40419, 42.94824, 0],
					[-114.40431, 42.94742, 0],
					[-114.40431, 42.94735, 0],
					[-114.40448, 42.94603, 0],
					[-114.40479, 42.94295, 0],
					[-114.40481, 42.94268, 0],
					[-114.40493, 42.94151, 0],
					[-114.40505, 42.94005, 0],
					[-114.40506, 42.93999, 0],
					[-114.40509, 42.9396, 0],
					[-114.40511, 42.93834, 0],
					[-114.40513, 42.93823, 0],
					[-114.40514, 42.93812, 0],
					[-114.40516, 42.93802, 0],
					[-114.40523, 42.93781, 0],
					[-114.40532, 42.93759, 0],
					[-114.4056, 42.93714, 0],
					[-114.40641, 42.93594, 0],
				],
			},
			id: "d86783f0-8032-4f77-832a-385141bc79ef",
		},
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Marker",
		// 		name: "Stanley, ID, USA",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.93005, 44.21626, 0],
		// 	},
		// 	id: "f3489d2f-a589-4531-819a-6cb5d35fa585",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Marker",
		// 		name: "Shoshone, ID, USA",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.40641, 42.93594, 0],
		// 	},
		// 	id: "41c9e3d4-5ecb-4d5f-b7e4-72e6978e27b6",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Ketchum, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.3636619, 43.6807402, 0],
		// 	},
		// 	id: "7d58050f-43aa-4160-bd74-83b7df8463ff",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Sun Valley, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.3517172, 43.6971294, 0],
		// 	},
		// 	id: "644a07b2-7fe6-4c1f-93a7-9d40889c5e59",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Hailey, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.31538, 43.51966, 0],
		// 	},
		// 	id: "a8845ab4-284e-4f52-80ca-2ebaa66694d8",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Bellevue, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.260435, 43.463446],
		// 	},
		// 	id: "5f8a335b-9d15-45af-9cbf-4522d35e18c6",
		// },
	],
};

export default Sawtooth;
