const MainOregonTrail = {
    type: "FeatureCollection",
    features: [{
        type: "Feature",
        properties: {
            name: "Main Oregon Trail Backcountry Byway",
            "description": "The Main Oregon Trail Backcountry Byway follows the route of the pioneers who journeyed through the Idaho desert. Spend the afternoon exploring historic Oregon Trail sites or visit Three Island Crossing State Park to see Conestoga replicas and learn about the emigrants who dared to take on one of the most challenging river crossings along the Oregon Trail and the Native Americans who mastered this difficult Idaho terrain.",
            "link": "https://visitidaho.org/things-to-do/road-trips/main-oregon-trail-backcountry-byway/",
            "route": [
                "Glenns Ferry", 
                "Mountain Home",
                "Mayfield"
            ],
            "shape": "Line"
        },
        geometry: {
            type: "LineString",
            coordinates: [
                [-115.30852, 42.96338, 0],
                [-115.30853, 42.96339, 0],
                [-115.30869, 42.96342, 0],
                [-115.30899, 42.96342, 0],
                [-115.30953, 42.9634, 0],
                [-115.30974, 42.96337, 0],
                [-115.31008, 42.9633, 0],
                [-115.31065, 42.96312, 0],
                [-115.3107, 42.96311, 0],
                [-115.31172, 42.96278, 0],
                [-115.31204, 42.96269, 0],
                [-115.31221, 42.96266, 0],
                [-115.31268, 42.9626, 0],
                [-115.31331, 42.9626, 0],
                [-115.31925, 42.96362, 0],
                [-115.32693, 42.96481, 0],
                [-115.32696, 42.96481, 0],
                [-115.33446, 42.96598, 0],
                [-115.33568, 42.9661, 0],
                [-115.33675, 42.96613, 0],
                [-115.33782, 42.96609, 0],
                [-115.33968, 42.96592, 0],
                [-115.34058, 42.96591, 0],
                [-115.34163, 42.96598, 0],
                [-115.34208, 42.96604, 0],
                [-115.34249, 42.96612, 0],
                [-115.34257, 42.96613, 0],
                [-115.3454, 42.96682, 0],
                [-115.34675, 42.96722, 0],
                [-115.34716, 42.96737, 0],
                [-115.34751, 42.96753, 0],
                [-115.3478, 42.96768, 0],
                [-115.3479, 42.96774, 0],
                [-115.3481, 42.96784, 0],
                [-115.34887, 42.96833, 0],
                [-115.34934, 42.96867, 0],
                [-115.34974, 42.96903, 0],
                [-115.35007, 42.96935, 0],
                [-115.35038, 42.96968, 0],
                [-115.35058, 42.96991, 0],
                [-115.35088, 42.97029, 0],
                [-115.35089, 42.97029, 0],
                [-115.35137, 42.97093, 0],
                [-115.35201, 42.9717, 0],
                [-115.35249, 42.97222, 0],
                [-115.3529, 42.97278, 0],
                [-115.35297, 42.9729, 0],
                [-115.35319, 42.97335, 0],
                [-115.35331, 42.97364, 0],
                [-115.35346, 42.97406, 0],
                [-115.35359, 42.97463, 0],
                [-115.3536, 42.97472, 0],
                [-115.3536, 42.97512, 0],
                [-115.35358, 42.97522, 0],
                [-115.35356, 42.97541, 0],
                [-115.35352, 42.97561, 0],
                [-115.35345, 42.97588, 0],
                [-115.35325, 42.97634, 0],
                [-115.35311, 42.97661, 0],
                [-115.3531, 42.97662, 0],
                [-115.35293, 42.97691, 0],
                [-115.3526, 42.97741, 0],
                [-115.34999, 42.98046, 0],
                [-115.34986, 42.98063, 0],
                [-115.34979, 42.9807, 0],
                [-115.34962, 42.981, 0],
                [-115.34956, 42.98115, 0],
                [-115.34953, 42.98125, 0],
                [-115.34949, 42.98153, 0],
                [-115.34949, 42.98168, 0],
                [-115.34954, 42.98197, 0],
                [-115.34964, 42.98229, 0],
                [-115.34973, 42.98252, 0],
                [-115.34979, 42.98263, 0],
                [-115.34981, 42.98269, 0],
                [-115.34989, 42.98283, 0],
                [-115.35006, 42.98305, 0],
                [-115.35022, 42.98317, 0],
                [-115.35034, 42.98323, 0],
                [-115.35079, 42.98341, 0],
                [-115.35309, 42.98423, 0],
                [-115.35311, 42.98423, 0],
                [-115.35619, 42.98531, 0],
                [-115.3562, 42.98532, 0],
                [-115.35656, 42.98546, 0],
                [-115.35713, 42.98575, 0],
                [-115.3574, 42.98592, 0],
                [-115.35782, 42.98623, 0],
                [-115.36031, 42.98839, 0],
                [-115.36058, 42.9886, 0],
                [-115.3608, 42.98867, 0],
                [-115.36094, 42.9887, 0],
                [-115.36104, 42.98873, 0],
                [-115.36106, 42.98873, 0],
                [-115.36127, 42.98877, 0],
                [-115.36941, 42.99002, 0],
                [-115.36983, 42.99011, 0],
                [-115.37008, 42.9902, 0],
                [-115.37342, 42.99154, 0],
                [-115.37398, 42.99175, 0],
                [-115.37484, 42.9921, 0],
                [-115.37506, 42.99218, 0],
                [-115.37783, 42.9933, 0],
                [-115.37865, 42.99367, 0],
                [-115.37889, 42.9938, 0],
                [-115.3789, 42.9938, 0],
                [-115.38446, 42.99723, 0],
                [-115.38464, 42.99735, 0],
                [-115.3851, 42.99769, 0],
                [-115.39246, 43.00394, 0],
                [-115.39265, 43.00412, 0],
                [-115.39847, 43.00909, 0],
                [-115.39864, 43.00922, 0],
                [-115.40104, 43.01127, 0],
                [-115.40105, 43.01127, 0],
                [-115.4027, 43.01268, 0],
                [-115.40334, 43.01325, 0],
                [-115.40336, 43.01326, 0],
                [-115.40375, 43.01355, 0],
                [-115.40399, 43.01368, 0],
                [-115.40411, 43.01373, 0],
                [-115.40445, 43.01384, 0],
                [-115.40741, 43.01435, 0],
                [-115.40757, 43.01437, 0],
                [-115.40801, 43.0144, 0],
                [-115.41633, 43.0145, 0],
                [-115.41668, 43.01452, 0],
                [-115.41682, 43.01454, 0],
                [-115.41703, 43.01459, 0],
                [-115.41721, 43.01466, 0],
                [-115.41743, 43.01477, 0],
                [-115.41745, 43.01479, 0],
                [-115.41762, 43.01488, 0],
                [-115.41768, 43.01493, 0],
                [-115.4178, 43.015, 0],
                [-115.41814, 43.01525, 0],
                [-115.41851, 43.0155, 0],
                [-115.41878, 43.0157, 0],
                [-115.4221, 43.01794, 0],
                [-115.4223, 43.01806, 0],
                [-115.42492, 43.01979, 0],
                [-115.42531, 43.02003, 0],
                [-115.43391, 43.02485, 0],
                [-115.43502, 43.02539, 0],
                [-115.43694, 43.02626, 0],
                [-115.43702, 43.02629, 0],
                [-115.43723, 43.02639, 0],
                [-115.43725, 43.02639, 0],
                [-115.44925, 43.03176, 0],
                [-115.44928, 43.03177, 0],
                [-115.47488, 43.04319, 0],
                [-115.47505, 43.04328, 0],
                [-115.47522, 43.04339, 0],
                [-115.47528, 43.04344, 0],
                [-115.47538, 43.04354, 0],
                [-115.47545, 43.04364, 0],
                [-115.47551, 43.04381, 0],
                [-115.47554, 43.04397, 0],
                [-115.47554, 43.04451, 0],
                [-115.47556, 43.04494, 0],
                [-115.4756, 43.05746, 0],
                [-115.47568, 43.06115, 0],
                [-115.47569, 43.06126, 0],
                [-115.47577, 43.06143, 0],
                [-115.47612, 43.06189, 0],
                [-115.47785, 43.06396, 0],
                [-115.47785, 43.06397, 0],
                [-115.48243, 43.06947, 0],
                [-115.48274, 43.06995, 0],
                [-115.48283, 43.07013, 0],
                [-115.48283, 43.07014, 0],
                [-115.48581, 43.07672, 0],
                [-115.48603, 43.07713, 0],
                [-115.48619, 43.07731, 0],
                [-115.48647, 43.07758, 0],
                [-115.48661, 43.07768, 0],
                [-115.48677, 43.07777, 0],
                [-115.48691, 43.07783, 0],
                [-115.48706, 43.07788, 0],
                [-115.48721, 43.07791, 0],
                [-115.48743, 43.07794, 0],
                [-115.4878, 43.07796, 0],
                [-115.48733, 43.07807, 0],
                [-115.48633, 43.07825, 0],
                [-115.48593, 43.07835, 0],
                [-115.47145, 43.08123, 0],
                [-115.47143, 43.08124, 0],
                [-115.46584, 43.08236, 0],
                [-115.4653, 43.08245, 0],
                [-115.46527, 43.08245, 0],
                [-115.46585, 43.08324, 0],
                [-115.46749, 43.08566, 0],
                [-115.46883, 43.08755, 0],
                [-115.46965, 43.08878, 0],
                [-115.47298, 43.09351, 0],
                [-115.47397, 43.09496, 0],
                [-115.47452, 43.09573, 0],
                [-115.47453, 43.09576, 0],
                [-115.47513, 43.09663, 0],
                [-115.47541, 43.09699, 0],
                [-115.47602, 43.0979, 0],
                [-115.47669, 43.0988, 0],
                [-115.47892, 43.102, 0],
                [-115.47957, 43.10298, 0],
                [-115.48773, 43.11463, 0],
                [-115.48827, 43.11544, 0],
                [-115.48828, 43.11545, 0],
                [-115.49156, 43.12018, 0],
                [-115.49359, 43.12302, 0],
                [-115.49405, 43.12362, 0],
                [-115.49775, 43.12463, 0],
                [-115.49799, 43.12471, 0],
                [-115.49808, 43.12476, 0],
                [-115.49833, 43.12494, 0],
                [-115.49837, 43.12498, 0],
                [-115.49904, 43.12598, 0],
                [-115.49948, 43.12671, 0],
                [-115.49962, 43.12698, 0],
                [-115.4998, 43.12721, 0],
                [-115.49981, 43.12721, 0],
                [-115.5, 43.12733, 0],
                [-115.5002, 43.12744, 0],
                [-115.50066, 43.12765, 0],
                [-115.50106, 43.12779, 0],
                [-115.50255, 43.12826, 0],
                [-115.50326, 43.12851, 0],
                [-115.50367, 43.12863, 0],
                [-115.50467, 43.12932, 0],
                [-115.50582, 43.13021, 0],
                [-115.50625, 43.13059, 0],
                [-115.50628, 43.13061, 0],
                [-115.50686, 43.13114, 0],
                [-115.50726, 43.13153, 0],
                [-115.50776, 43.1321, 0],
                [-115.50805, 43.1325, 0],
                [-115.50863, 43.13354, 0],
                [-115.50876, 43.13372, 0],
                [-115.50996, 43.1362, 0],
                [-115.51178, 43.14011, 0],
                [-115.51214, 43.14079, 0],
                [-115.51231, 43.14106, 0],
                [-115.51251, 43.14142, 0],
                [-115.51271, 43.14171, 0],
                [-115.5142, 43.14419, 0],
                [-115.51475, 43.14504, 0],
                [-115.51514, 43.14555, 0],
                [-115.51549, 43.14593, 0],
                [-115.51574, 43.14618, 0],
                [-115.51578, 43.14621, 0],
                [-115.51657, 43.14692, 0],
                [-115.51697, 43.14731, 0],
                [-115.51764, 43.14789, 0],
                [-115.51933, 43.14944, 0],
                [-115.52128, 43.15131, 0],
                [-115.52154, 43.15162, 0],
                [-115.52172, 43.15188, 0],
                [-115.52201, 43.15244, 0],
                [-115.52203, 43.15247, 0],
                [-115.52276, 43.15396, 0],
                [-115.52279, 43.15401, 0],
                [-115.52346, 43.15541, 0],
                [-115.5243, 43.15703, 0],
                [-115.52484, 43.15831, 0],
                [-115.52503, 43.15888, 0],
                [-115.52503, 43.1589, 0],
                [-115.52558, 43.16028, 0],
                [-115.52583, 43.16097, 0],
                [-115.52654, 43.1627, 0],
                [-115.52683, 43.16346, 0],
                [-115.52696, 43.16374, 0],
                [-115.52727, 43.16461, 0],
                [-115.52744, 43.16488, 0],
                [-115.52819, 43.16557, 0],
                [-115.52849, 43.16579, 0],
                [-115.53009, 43.16732, 0],
                [-115.53038, 43.16764, 0],
                [-115.53267, 43.16998, 0],
                [-115.54001, 43.17673, 0],
                [-115.54013, 43.17683, 0],
                [-115.54293, 43.1794, 0],
                [-115.54304, 43.17951, 0],
                [-115.54355, 43.17995, 0],
                [-115.54463, 43.18094, 0],
                [-115.54638, 43.18261, 0],
                [-115.54642, 43.18264, 0],
                [-115.54658, 43.1828, 0],
                [-115.55095, 43.18683, 0],
                [-115.55162, 43.18748, 0],
                [-115.55168, 43.18756, 0],
                [-115.55227, 43.18866, 0],
                [-115.55596, 43.19647, 0],
                [-115.55616, 43.19675, 0],
                [-115.55645, 43.19658, 0],
                [-115.55679, 43.19641, 0],
                [-115.55713, 43.19626, 0],
                [-115.55758, 43.1961, 0],
                [-115.55828, 43.19591, 0],
                [-115.55978, 43.19559, 0],
                [-115.56072, 43.19536, 0],
                [-115.56645, 43.19417, 0],
                [-115.56653, 43.19477, 0],
                [-115.56657, 43.19562, 0],
                [-115.56661, 43.19596, 0],
                [-115.56668, 43.19641, 0],
                [-115.56689, 43.19662, 0],
                [-115.56725, 43.1968, 0],
                [-115.57139, 43.19835, 0],
                [-115.57211, 43.1983, 0],
                [-115.57218, 43.19829, 0],
                [-115.57248, 43.19837, 0],
                [-115.57276, 43.19852, 0],
                [-115.57337, 43.19878, 0],
                [-115.57636, 43.20068, 0],
                [-115.57707, 43.20118, 0],
                [-115.57708, 43.20119, 0],
                [-115.57895, 43.20254, 0],
                [-115.579, 43.20259, 0],
                [-115.57973, 43.20317, 0],
                [-115.58154, 43.20433, 0],
                [-115.58176, 43.20453, 0],
                [-115.58332, 43.20576, 0],
                [-115.5841, 43.20648, 0],
                [-115.58469, 43.2072, 0],
                [-115.58502, 43.20742, 0],
                [-115.58514, 43.20746, 0],
                [-115.58535, 43.20751, 0],
                [-115.58577, 43.20754, 0],
                [-115.58631, 43.20775, 0],
                [-115.5869, 43.20803, 0],
                [-115.58828, 43.20877, 0],
                [-115.58927, 43.20943, 0],
                [-115.59079, 43.21034, 0],
                [-115.5919, 43.21093, 0],
                [-115.59413, 43.2122, 0],
                [-115.59472, 43.21249, 0],
                [-115.59505, 43.2127, 0],
                [-115.59562, 43.21309, 0],
                [-115.59657, 43.21347, 0],
                [-115.59712, 43.21366, 0],
                [-115.5985, 43.21418, 0],
                [-115.59892, 43.21448, 0],
                [-115.5993, 43.2147, 0],
                [-115.59994, 43.21492, 0],
                [-115.6011, 43.21525, 0],
                [-115.60117, 43.21531, 0],
                [-115.60139, 43.21553, 0],
                [-115.60237, 43.21602, 0],
                [-115.60349, 43.21664, 0],
                [-115.60389, 43.21698, 0],
                [-115.60454, 43.21726, 0],
                [-115.60609, 43.21754, 0],
                [-115.60728, 43.21768, 0],
                [-115.60772, 43.21783, 0],
                [-115.60774, 43.21784, 0],
                [-115.60837, 43.21806, 0],
                [-115.60919, 43.21846, 0],
                [-115.61023, 43.2188, 0],
                [-115.61046, 43.21908, 0],
                [-115.61095, 43.22056, 0],
                [-115.61122, 43.22086, 0],
                [-115.61138, 43.22106, 0],
                [-115.61141, 43.22126, 0],
                [-115.61133, 43.2217, 0],
                [-115.61134, 43.22228, 0],
                [-115.61141, 43.22306, 0],
                [-115.61142, 43.22349, 0],
                [-115.61174, 43.22426, 0],
                [-115.6119, 43.22454, 0],
                [-115.61215, 43.2248, 0],
                [-115.61223, 43.22519, 0],
                [-115.61241, 43.22563, 0],
                [-115.61267, 43.22671, 0],
                [-115.61267, 43.22711, 0],
                [-115.61291, 43.22733, 0],
                [-115.61301, 43.2278, 0],
                [-115.61311, 43.22816, 0],
                [-115.61319, 43.2283, 0],
                [-115.61327, 43.2285, 0],
                [-115.61326, 43.22877, 0],
                [-115.61327, 43.22889, 0],
                [-115.61344, 43.22908, 0],
                [-115.61359, 43.22937, 0],
                [-115.61369, 43.22969, 0],
                [-115.61373, 43.23007, 0],
                [-115.61363, 43.23043, 0],
                [-115.61362, 43.23065, 0],
                [-115.61368, 43.23073, 0],
                [-115.61378, 43.23081, 0],
                [-115.61406, 43.23095, 0],
                [-115.61415, 43.23101, 0],
                [-115.61421, 43.23106, 0],
                [-115.6149, 43.23194, 0],
                [-115.6154, 43.23253, 0],
                [-115.61564, 43.23287, 0],
                [-115.61611, 43.23363, 0],
                [-115.61643, 43.23401, 0],
                [-115.61675, 43.23447, 0],
                [-115.61699, 43.23479, 0],
                [-115.6176, 43.23535, 0],
                [-115.61761, 43.23535, 0],
                [-115.61803, 43.23584, 0],
                [-115.61838, 43.23633, 0],
                [-115.61867, 43.23668, 0],
                [-115.61904, 43.23705, 0],
                [-115.61915, 43.23719, 0],
                [-115.61932, 43.23738, 0],
                [-115.61993, 43.23788, 0],
                [-115.62051, 43.23844, 0],
                [-115.62143, 43.23967, 0],
                [-115.62182, 43.23998, 0],
                [-115.62199, 43.24005, 0],
                [-115.62224, 43.24012, 0],
                [-115.62243, 43.24027, 0],
                [-115.62304, 43.24102, 0],
                [-115.62332, 43.24128, 0],
                [-115.62359, 43.24157, 0],
                [-115.62374, 43.2417, 0],
                [-115.62407, 43.24185, 0],
                [-115.62444, 43.24211, 0],
                [-115.6251, 43.24266, 0],
                [-115.62594, 43.24325, 0],
                [-115.62692, 43.24379, 0],
                [-115.62746, 43.24411, 0],
                [-115.62872, 43.24518, 0],
                [-115.62925, 43.24573, 0],
                [-115.62995, 43.24633, 0],
                [-115.63017, 43.24649, 0],
                [-115.63066, 43.2468, 0],
                [-115.632, 43.24859, 0],
                [-115.63201, 43.24862, 0],
                [-115.63226, 43.24885, 0],
                [-115.63251, 43.249, 0],
                [-115.63285, 43.24916, 0],
                [-115.63321, 43.24925, 0],
                [-115.63341, 43.24934, 0],
                [-115.63357, 43.24944, 0],
                [-115.63424, 43.24995, 0],
                [-115.63446, 43.25009, 0],
                [-115.63466, 43.25025, 0],
                [-115.63489, 43.25048, 0],
                [-115.63491, 43.25051, 0],
                [-115.63529, 43.25094, 0],
                [-115.6416, 43.25174, 0],
                [-115.64885, 43.25257, 0],
                [-115.64891, 43.25257, 0],
                [-115.64902, 43.25259, 0],
                [-115.64908, 43.25261, 0],
                [-115.64912, 43.25261, 0],
                [-115.65302, 43.2531, 0],
                [-115.65512, 43.25332, 0],
                [-115.65524, 43.25331, 0],
                [-115.67676, 43.25588, 0],
                [-115.67782, 43.25594, 0],
                [-115.68037, 43.256, 0],
                [-115.68129, 43.25595, 0],
                [-115.68201, 43.25608, 0],
                [-115.69925, 43.25692, 0],
                [-115.70021, 43.25662, 0],
                [-115.70084, 43.25633, 0],
                [-115.70092, 43.25627, 0],
                [-115.70104, 43.25624, 0],
                [-115.70106, 43.25623, 0],
                [-115.70113, 43.25623, 0],
                [-115.70115, 43.25624, 0],
                [-115.70119, 43.25624, 0],
                [-115.70125, 43.25627, 0],
                [-115.70132, 43.25634, 0],
                [-115.70139, 43.25681, 0],
                [-115.7016, 43.25771, 0],
                [-115.7017, 43.25829, 0],
                [-115.70173, 43.25865, 0],
                [-115.70173, 43.2587, 0],
                [-115.70171, 43.25882, 0],
                [-115.70163, 43.25898, 0],
                [-115.70161, 43.25899, 0],
                [-115.70143, 43.25915, 0],
                [-115.70128, 43.25935, 0],
                [-115.70118, 43.25954, 0],
                [-115.70114, 43.25968, 0],
                [-115.70117, 43.2598, 0],
                [-115.70117, 43.25986, 0],
                [-115.70124, 43.25999, 0],
                [-115.70135, 43.26013, 0],
                [-115.70169, 43.26039, 0],
                [-115.7024, 43.261, 0],
                [-115.70271, 43.26131, 0],
                [-115.70296, 43.26149, 0],
                [-115.70301, 43.26152, 0],
                [-115.70508, 43.26251, 0],
                [-115.70522, 43.26254, 0],
                [-115.70545, 43.26253, 0],
                [-115.70584, 43.26242, 0],
                [-115.7063, 43.26234, 0],
                [-115.70688, 43.26247, 0],
                [-115.70908, 43.26305, 0],
                [-115.71038, 43.26343, 0],
                [-115.72702, 43.26902, 0],
                [-115.72824, 43.26945, 0],
                [-115.73345, 43.27117, 0],
                [-115.73405, 43.27139, 0],
                [-115.73451, 43.27161, 0],
                [-115.73498, 43.27186, 0],
                [-115.73537, 43.27209, 0],
                [-115.7358, 43.27245, 0],
                [-115.73615, 43.27283, 0],
                [-115.73667, 43.27355, 0],
                [-115.73695, 43.274, 0],
                [-115.73751, 43.27484, 0],
                [-115.73877, 43.27682, 0],
                [-115.73878, 43.27683, 0],
                [-115.7398, 43.27839, 0],
                [-115.73983, 43.27845, 0],
                [-115.74048, 43.27944, 0],
                [-115.74082, 43.27999, 0],
                [-115.74104, 43.28022, 0],
                [-115.74126, 43.28043, 0],
                [-115.74159, 43.28071, 0],
                [-115.74182, 43.28096, 0],
                [-115.74196, 43.28124, 0],
                [-115.74196, 43.28127, 0],
                [-115.742, 43.28159, 0],
                [-115.742, 43.2818, 0],
                [-115.74201, 43.28186, 0],
                [-115.74202, 43.28288, 0],
                [-115.74203, 43.28298, 0],
                [-115.74204, 43.28882, 0],
                [-115.74205, 43.28906, 0],
                [-115.74208, 43.29924, 0],
                [-115.74209, 43.29937, 0],
                [-115.74208, 43.2996, 0],
                [-115.74207, 43.30392, 0],
                [-115.74213, 43.31155, 0],
                [-115.74217, 43.31251, 0],
                [-115.74218, 43.31669, 0],
                [-115.74227, 43.3237, 0],
                [-115.74229, 43.32426, 0],
                [-115.74274, 43.3249, 0],
                [-115.74292, 43.3251, 0],
                [-115.74311, 43.32527, 0],
                [-115.7434, 43.32546, 0],
                [-115.74356, 43.32545, 0],
                [-115.74385, 43.32546, 0],
                [-115.74572, 43.32541, 0],
                [-115.74604, 43.32544, 0],
                [-115.74634, 43.32551, 0],
                [-115.74666, 43.32564, 0],
                [-115.7492, 43.32694, 0],
                [-115.74987, 43.32726, 0],
                [-115.75017, 43.32744, 0],
                [-115.75037, 43.3276, 0],
                [-115.75058, 43.32784, 0],
                [-115.75087, 43.32822, 0],
                [-115.75103, 43.32841, 0],
                [-115.75118, 43.32853, 0],
                [-115.75137, 43.32862, 0],
                [-115.75151, 43.3287, 0],
                [-115.75393, 43.32984, 0],
                [-115.75399, 43.32989, 0],
                [-115.7541, 43.32996, 0],
                [-115.75444, 43.33025, 0],
                [-115.75472, 43.33051, 0],
                [-115.75643, 43.33192, 0],
                [-115.7582, 43.3333, 0],
                [-115.76029, 43.33488, 0],
                [-115.76035, 43.33491, 0],
                [-115.76051, 43.33502, 0],
                [-115.76112, 43.33536, 0],
                [-115.76123, 43.33543, 0],
                [-115.76133, 43.33551, 0],
                [-115.76159, 43.3358, 0],
                [-115.76166, 43.33591, 0],
                [-115.76173, 43.33613, 0],
                [-115.76185, 43.33683, 0],
                [-115.76193, 43.33702, 0],
                [-115.76201, 43.33712, 0],
                [-115.76211, 43.33719, 0],
                [-115.76275, 43.33752, 0],
                [-115.76312, 43.33784, 0],
                [-115.76345, 43.33817, 0],
                [-115.76345, 43.33818, 0],
                [-115.76391, 43.3386, 0],
                [-115.76418, 43.33882, 0],
                [-115.76436, 43.33895, 0],
                [-115.76457, 43.33907, 0],
                [-115.76472, 43.33917, 0],
                [-115.7649, 43.33927, 0],
                [-115.765, 43.33931, 0],
                [-115.76564, 43.33961, 0],
                [-115.76601, 43.33981, 0],
                [-115.7666, 43.34029, 0],
                [-115.7668, 43.34042, 0],
                [-115.76706, 43.34056, 0],
                [-115.76729, 43.34066, 0],
                [-115.76834, 43.34106, 0],
                [-115.76852, 43.34112, 0],
                [-115.76872, 43.34122, 0],
                [-115.76893, 43.34129, 0],
                [-115.76937, 43.34138, 0],
                [-115.76979, 43.34141, 0],
                [-115.7701, 43.34145, 0],
                [-115.77026, 43.34149, 0],
                [-115.77028, 43.34149, 0],
                [-115.77048, 43.34156, 0],
                [-115.77082, 43.34165, 0],
                [-115.77112, 43.34171, 0],
                [-115.77139, 43.34173, 0],
                [-115.77165, 43.34179, 0],
                [-115.77181, 43.34184, 0],
                [-115.77197, 43.3419, 0],
                [-115.77211, 43.34199, 0],
                [-115.7722, 43.34207, 0],
                [-115.77257, 43.34247, 0],
                [-115.77292, 43.34281, 0],
                [-115.77312, 43.34295, 0],
                [-115.77323, 43.34306, 0],
                [-115.77338, 43.34318, 0],
                [-115.77374, 43.34337, 0],
                [-115.77382, 43.3434, 0],
                [-115.77401, 43.34342, 0],
                [-115.77418, 43.34346, 0],
                [-115.77444, 43.34349, 0],
                [-115.77456, 43.34347, 0],
                [-115.77462, 43.34344, 0],
                [-115.77468, 43.34342, 0],
                [-115.77495, 43.34327, 0],
                [-115.7751, 43.3432, 0],
                [-115.77558, 43.34307, 0],
                [-115.77588, 43.34302, 0],
                [-115.77623, 43.34299, 0],
                [-115.77769, 43.34301, 0],
                [-115.77784, 43.34299, 0],
                [-115.77942, 43.3427, 0],
                [-115.77984, 43.3426, 0],
                [-115.77997, 43.34256, 0],
                [-115.78014, 43.34249, 0],
                [-115.78033, 43.34239, 0],
                [-115.7807, 43.34217, 0],
                [-115.78112, 43.34199, 0],
                [-115.78176, 43.34166, 0],
                [-115.78209, 43.34151, 0],
                [-115.78227, 43.34144, 0],
                [-115.78251, 43.34137, 0],
                [-115.7827, 43.34134, 0],
                [-115.78293, 43.34134, 0],
                [-115.78358, 43.34139, 0],
                [-115.78392, 43.34144, 0],
                [-115.78566, 43.34154, 0],
                [-115.78814, 43.3415, 0],
                [-115.78865, 43.34146, 0],
                [-115.78958, 43.3413, 0],
                [-115.79004, 43.34124, 0],
                [-115.79045, 43.34123, 0],
                [-115.79088, 43.34125, 0],
                [-115.79193, 43.34136, 0],
                [-115.79204, 43.34139, 0],
                [-115.79213, 43.34143, 0],
                [-115.7922, 43.34154, 0],
                [-115.79218, 43.34175, 0],
                [-115.7921, 43.34211, 0],
                [-115.792, 43.34249, 0],
                [-115.79196, 43.34276, 0],
                [-115.792, 43.34293, 0],
                [-115.79212, 43.34307, 0],
                [-115.79214, 43.34308, 0],
                [-115.79237, 43.34328, 0],
                [-115.79317, 43.34391, 0],
                [-115.79338, 43.34411, 0],
                [-115.79351, 43.34426, 0],
                [-115.79373, 43.34461, 0],
                [-115.7938, 43.34477, 0],
                [-115.79386, 43.34486, 0],
                [-115.79399, 43.34503, 0],
                [-115.79422, 43.34529, 0],
                [-115.79453, 43.34556, 0],
                [-115.79523, 43.34607, 0],
                [-115.79567, 43.34647, 0],
                [-115.79593, 43.34663, 0],
                [-115.79921, 43.34817, 0],
                [-115.79922, 43.34818, 0],
                [-115.80112, 43.34912, 0],
                [-115.8021, 43.34964, 0],
                [-115.80232, 43.34973, 0],
                [-115.80621, 43.35197, 0],
                [-115.80785, 43.35264, 0],
                [-115.80786, 43.35264, 0],
                [-115.80856, 43.35289, 0],
                [-115.80901, 43.35297, 0],
                [-115.80988, 43.35308, 0],
                [-115.81092, 43.35325, 0],
                [-115.81095, 43.35325, 0],
                [-115.81298, 43.3536, 0],
                [-115.81557, 43.35395, 0],
                [-115.81673, 43.35416, 0],
                [-115.81924, 43.35485, 0],
                [-115.81985, 43.35515, 0],
                [-115.82144, 43.35606, 0],
                [-115.82207, 43.3565, 0],
                [-115.82233, 43.35675, 0],
                [-115.82258, 43.35705, 0],
                [-115.8232, 43.35771, 0],
                [-115.82339, 43.35799, 0],
                [-115.82387, 43.35858, 0],
                [-115.82429, 43.35903, 0],
                [-115.82436, 43.35923, 0],
                [-115.82437, 43.35957, 0],
                [-115.82434, 43.35996, 0],
                [-115.82432, 43.36005, 0],
                [-115.82426, 43.36015, 0],
                [-115.82418, 43.36025, 0],
                [-115.82412, 43.36034, 0],
                [-115.82385, 43.36056, 0],
                [-115.82369, 43.36079, 0],
                [-115.82362, 43.36103, 0],
                [-115.82361, 43.3616, 0],
                [-115.82377, 43.36318, 0],
                [-115.82399, 43.36444, 0],
                [-115.82452, 43.36599, 0],
                [-115.82472, 43.36631, 0],
                [-115.82491, 43.36653, 0],
                [-115.82555, 43.36701, 0],
                [-115.82556, 43.36701, 0],
                [-115.82606, 43.36737, 0],
                [-115.82607, 43.36739, 0],
                [-115.82619, 43.36752, 0],
                [-115.82632, 43.36776, 0],
                [-115.82639, 43.36806, 0],
                [-115.82642, 43.36842, 0],
                [-115.82639, 43.36878, 0],
                [-115.8264, 43.36902, 0],
                [-115.82676, 43.37361, 0],
                [-115.8268, 43.37367, 0],
                [-115.82692, 43.37391, 0],
                [-115.82711, 43.37408, 0],
                [-115.82866, 43.37491, 0],
                [-115.82891, 43.37506, 0],
                [-115.82914, 43.37518, 0],
                [-115.82923, 43.37524, 0],
                [-115.8297, 43.37549, 0],
                [-115.82984, 43.3756, 0],
                [-115.82986, 43.37563, 0],
                [-115.82993, 43.37577, 0],
                [-115.82997, 43.37593, 0],
                [-115.82994, 43.37624, 0],
                [-115.82983, 43.37687, 0],
                [-115.82952, 43.37812, 0],
                [-115.8295, 43.37832, 0],
                [-115.82958, 43.38017, 0],
                [-115.82959, 43.38021, 0],
                [-115.82964, 43.38061, 0],
                [-115.82964, 43.38075, 0],
                [-115.82962, 43.38089, 0],
                [-115.82937, 43.38144, 0],
                [-115.8291, 43.3819, 0],
                [-115.8288, 43.3822, 0],
                [-115.82873, 43.38234, 0],
                [-115.82871, 43.38247, 0],
                [-115.82879, 43.38281, 0],
                [-115.8288, 43.38295, 0],
                [-115.82878, 43.38308, 0],
                [-115.82868, 43.38329, 0],
                [-115.82853, 43.38393, 0],
                [-115.82833, 43.3842, 0],
                [-115.82778, 43.38484, 0],
                [-115.82774, 43.38491, 0],
                [-115.82758, 43.38514, 0],
                [-115.82751, 43.38533, 0],
                [-115.82744, 43.38574, 0],
                [-115.82744, 43.38607, 0],
                [-115.82752, 43.38724, 0],
                [-115.82762, 43.38754, 0],
                [-115.8278, 43.3877, 0],
                [-115.8279, 43.38776, 0],
                [-115.82801, 43.3878, 0],
                [-115.82805, 43.38782, 0],
                [-115.82857, 43.38796, 0],
                [-115.82881, 43.38801, 0],
                [-115.82908, 43.38812, 0],
                [-115.82926, 43.38825, 0],
                [-115.82967, 43.38861, 0],
                [-115.83065, 43.3894, 0],
                [-115.83093, 43.38967, 0],
                [-115.83098, 43.38973, 0],
                [-115.83107, 43.38982, 0],
                [-115.83107, 43.38983, 0],
                [-115.83117, 43.3899, 0],
                [-115.83136, 43.38996, 0],
                [-115.83144, 43.38995, 0],
                [-115.83158, 43.38989, 0],
                [-115.8318, 43.38983, 0],
                [-115.83194, 43.38978, 0],
                [-115.83205, 43.38975, 0],
                [-115.83222, 43.38975, 0],
                [-115.83237, 43.38982, 0],
                [-115.83239, 43.38984, 0],
                [-115.83248, 43.38991, 0],
                [-115.83258, 43.39005, 0],
                [-115.83266, 43.39022, 0],
                [-115.8328, 43.39061, 0],
                [-115.83291, 43.39078, 0],
                [-115.83299, 43.39093, 0],
                [-115.83306, 43.39113, 0],
                [-115.83318, 43.39129, 0],
                [-115.83332, 43.39144, 0],
                [-115.83514, 43.39218, 0],
                [-115.83583, 43.39243, 0],
                [-115.83605, 43.39249, 0],
                [-115.83616, 43.39251, 0],
                [-115.83632, 43.3925, 0],
                [-115.83657, 43.39242, 0],
                [-115.83669, 43.39231, 0],
                [-115.83677, 43.39226, 0],
                [-115.83694, 43.39222, 0],
                [-115.83702, 43.39223, 0],
                [-115.83707, 43.39227, 0],
                [-115.83709, 43.39247, 0],
                [-115.83715, 43.39252, 0],
                [-115.83723, 43.39257, 0],
                [-115.83811, 43.39266, 0],
                [-115.83901, 43.39294, 0],
                [-115.83975, 43.39315, 0],
                [-115.84082, 43.39353, 0],
                [-115.84102, 43.39364, 0],
                [-115.84105, 43.39365, 0],
                [-115.84125, 43.39387, 0],
                [-115.84141, 43.39401, 0],
                [-115.84163, 43.39413, 0],
                [-115.84199, 43.39427, 0],
                [-115.84382, 43.39486, 0],
                [-115.84393, 43.39491, 0],
                [-115.84483, 43.39525, 0],
                [-115.84523, 43.39543, 0],
                [-115.84526, 43.39544, 0],
                [-115.84583, 43.39575, 0],
                [-115.84616, 43.3959, 0],
                [-115.8466, 43.39598, 0],
                [-115.84695, 43.39607, 0],
                [-115.84769, 43.39633, 0],
                [-115.84831, 43.39666, 0],
                [-115.84832, 43.39667, 0],
                [-115.84978, 43.39729, 0],
                [-115.84997, 43.39738, 0],
                [-115.85002, 43.39739, 0],
                [-115.85014, 43.3974, 0],
                [-115.8502, 43.39738, 0],
                [-115.85029, 43.39736, 0],
                [-115.85061, 43.39724, 0],
                [-115.85068, 43.39718, 0],
                [-115.85158, 43.39654, 0],
                [-115.85234, 43.39608, 0],
                [-115.85235, 43.39608, 0],
                [-115.85265, 43.396, 0],
                [-115.85282, 43.39599, 0],
                [-115.85303, 43.39596, 0],
                [-115.85341, 43.39594, 0],
                [-115.85379, 43.39589, 0],
                [-115.85432, 43.39584, 0],
                [-115.85472, 43.39582, 0],
                [-115.85501, 43.39583, 0],
                [-115.8553, 43.39582, 0],
                [-115.85555, 43.3958, 0],
                [-115.85728, 43.39537, 0],
                [-115.85761, 43.39524, 0],
                [-115.85788, 43.3951, 0],
                [-115.85813, 43.39491, 0],
                [-115.85845, 43.39469, 0],
                [-115.85854, 43.39465, 0],
                [-115.85868, 43.3946, 0],
                [-115.85914, 43.39455, 0],
                [-115.8592, 43.39455, 0],
                [-115.85924, 43.39458, 0],
                [-115.86003, 43.39488, 0],
                [-115.8602, 43.39501, 0],
                [-115.86033, 43.39519, 0],
                [-115.86042, 43.39536, 0],
                [-115.86052, 43.39561, 0],
                [-115.86047, 43.39589, 0],
                [-115.86042, 43.39659, 0],
                [-115.86045, 43.39688, 0],
                [-115.86072, 43.39822, 0],
                [-115.86107, 43.40048, 0],
                [-115.86107, 43.4008, 0],
                [-115.86106, 43.40094, 0],
                [-115.86113, 43.40119, 0],
                [-115.86125, 43.40153, 0],
                [-115.86143, 43.40193, 0],
                [-115.86167, 43.4024, 0],
                [-115.86183, 43.40268, 0],
                [-115.86187, 43.40271, 0],
                [-115.86264, 43.40355, 0],
                [-115.86289, 43.40376, 0],
                [-115.86382, 43.40439, 0],
                [-115.86464, 43.40499, 0],
                [-115.86464, 43.405, 0],
                [-115.86518, 43.40536, 0],
                [-115.86588, 43.40587, 0],
                [-115.8662, 43.40615, 0],
                [-115.86644, 43.40645, 0],
                [-115.8667, 43.40681, 0],
                [-115.86704, 43.40725, 0],
                [-115.86721, 43.40745, 0],
                [-115.86731, 43.40755, 0],
                [-115.86757, 43.40778, 0],
                [-115.868, 43.40808, 0],
                [-115.86853, 43.4084, 0],
                [-115.86864, 43.4085, 0],
                [-115.86872, 43.40856, 0],
                [-115.8689, 43.40874, 0],
                [-115.86906, 43.40888, 0],
                [-115.86922, 43.40895, 0],
                [-115.86997, 43.40936, 0],
                [-115.87025, 43.4095, 0],
                [-115.87043, 43.40957, 0],
                [-115.87064, 43.40962, 0],
                [-115.87091, 43.40965, 0],
                [-115.87095, 43.40965, 0],
                [-115.87158, 43.40972, 0],
                [-115.87187, 43.40981, 0],
                [-115.87228, 43.40996, 0],
                [-115.8723, 43.40998, 0],
                [-115.87269, 43.41016, 0],
                [-115.87271, 43.41018, 0],
                [-115.8728, 43.41025, 0],
                [-115.87297, 43.41042, 0],
                [-115.87318, 43.4106, 0],
                [-115.87336, 43.41072, 0],
                [-115.87353, 43.41085, 0],
                [-115.87365, 43.41093, 0],
                [-115.87388, 43.41101, 0],
                [-115.87409, 43.41105, 0],
                [-115.87423, 43.41105, 0],
                [-115.87442, 43.41102, 0],
                [-115.87444, 43.41101, 0],
                [-115.87458, 43.41098, 0],
                [-115.87514, 43.41079, 0],
                [-115.87534, 43.41074, 0],
                [-115.87569, 43.41071, 0],
                [-115.87673, 43.41068, 0],
                [-115.87702, 43.41072, 0],
                [-115.87726, 43.41077, 0],
                [-115.87747, 43.41083, 0],
                [-115.87767, 43.41096, 0],
                [-115.87801, 43.41128, 0],
                [-115.87878, 43.41206, 0],
                [-115.87918, 43.41244, 0],
                [-115.87951, 43.4127, 0],
                [-115.87972, 43.41281, 0],
                [-115.8804, 43.41331, 0],
                [-115.881, 43.41348, 0],
                [-115.8815, 43.41353, 0],
                [-115.88206, 43.41361, 0],
                [-115.88254, 43.41365, 0],
                [-115.88331, 43.41381, 0],
                [-115.88404, 43.41392, 0],
                [-115.88471, 43.41399, 0],
                [-115.88604, 43.41406, 0],
                [-115.88694, 43.4142, 0],
                [-115.88882, 43.4147, 0],
                [-115.89181, 43.41541, 0],
                [-115.89224, 43.4155, 0],
                [-115.89255, 43.41554, 0],
                [-115.89336, 43.41559, 0],
                [-115.89403, 43.41565, 0],
                [-115.89485, 43.41607, 0],
                [-115.89512, 43.41625, 0],
                [-115.89535, 43.41644, 0],
                [-115.89556, 43.41659, 0],
                [-115.89582, 43.41673, 0],
                [-115.89609, 43.41683, 0],
                [-115.89677, 43.417, 0],
                [-115.90099, 43.41782, 0],
                [-115.90233, 43.41804, 0],
                [-115.90303, 43.4181, 0],
                [-115.90671, 43.41822, 0],
                [-115.90697, 43.41828, 0],
                [-115.90719, 43.4184, 0],
                [-115.9075, 43.41861, 0],
                [-115.9086, 43.41926, 0],
                [-115.90979, 43.41993, 0],
                [-115.91042, 43.42026, 0],
                [-115.91071, 43.42043, 0],
                [-115.91081, 43.42046, 0],
                [-115.91161, 43.42132, 0],
                [-115.91286, 43.42243, 0],
                [-115.91358, 43.42304, 0],
                [-115.9142, 43.42348, 0],
                [-115.9143, 43.42356, 0],
                [-115.915, 43.42398, 0],
                [-115.91529, 43.42423, 0],
                [-115.91556, 43.42452, 0],
                [-115.91584, 43.42498, 0],
                [-115.91594, 43.4252, 0],
                [-115.91615, 43.42623, 0],
                [-115.91622, 43.42641, 0],
                [-115.91631, 43.42659, 0],
                [-115.91643, 43.42677, 0],
                [-115.91674, 43.427, 0],
                [-115.91971, 43.42878, 0],
                [-115.91989, 43.42895, 0],
                [-115.91992, 43.42906, 0],
                [-115.9199, 43.42918, 0],
                [-115.91989, 43.42919, 0],
                [-115.91982, 43.42934, 0],
                [-115.9196, 43.42962, 0],
                [-115.91934, 43.43004, 0],
                [-115.9193, 43.43018, 0],
                [-115.91928, 43.43022, 0],
                [-115.91928, 43.43051, 0],
                [-115.9193, 43.4307, 0],
                [-115.91933, 43.43087, 0],
                [-115.91942, 43.43106, 0],
                [-115.91953, 43.43123, 0],
                [-115.92012, 43.4319, 0],
                [-115.92028, 43.43202, 0],
                [-115.92043, 43.43206, 0],
                [-115.92078, 43.43206, 0],
                [-115.921, 43.43204, 0],
                [-115.92157, 43.43195, 0],
                [-115.92173, 43.43195, 0],
                [-115.92195, 43.43198, 0],
                [-115.92301, 43.43222, 0],
                [-115.92323, 43.43229, 0],
                [-115.92397, 43.43243, 0],
                [-115.92459, 43.43258, 0],
                [-115.92505, 43.43265, 0],
                [-115.92529, 43.43266, 0],
                [-115.92554, 43.43264, 0],
                [-115.92579, 43.43264, 0],
                [-115.92597, 43.43267, 0],
                [-115.92641, 43.43287, 0],
                [-115.92659, 43.433, 0],
                [-115.92695, 43.4332, 0],
                [-115.92709, 43.43329, 0],
                [-115.92726, 43.43345, 0],
                [-115.92747, 43.43371, 0],
                [-115.92762, 43.43401, 0],
                [-115.92774, 43.43419, 0],
                [-115.92793, 43.43454, 0],
                [-115.92806, 43.43472, 0],
                [-115.92815, 43.43523, 0],
                [-115.9283, 43.43556, 0],
                [-115.92875, 43.43611, 0],
                [-115.92896, 43.43632, 0],
                [-115.92902, 43.43639, 0],
                [-115.92948, 43.43673, 0],
                [-115.92977, 43.43688, 0],
                [-115.93024, 43.43706, 0],
                [-115.93039, 43.43715, 0],
                [-115.9305, 43.43727, 0],
                [-115.93064, 43.4375, 0],
                [-115.93089, 43.43817, 0],
                [-115.9309, 43.43823, 0],
                [-115.93091, 43.43825, 0],
                [-115.93098, 43.43863, 0],
                [-115.93098, 43.43883, 0],
                [-115.93093, 43.43914, 0],
                [-115.93089, 43.43996, 0],
                [-115.9309, 43.44004, 0],
                [-115.93091, 43.44035, 0],
                [-115.93087, 43.44049, 0],
                [-115.93074, 43.44067, 0],
                [-115.9305, 43.44095, 0],
                [-115.93031, 43.44114, 0],
                [-115.93014, 43.44136, 0],
                [-115.93, 43.44164, 0],
                [-115.92969, 43.44211, 0],
                [-115.92964, 43.44222, 0],
                [-115.92962, 43.44234, 0],
                [-115.92966, 43.44248, 0],
                [-115.92992, 43.44295, 0],
                [-115.93001, 43.44314, 0],
                [-115.93012, 43.4435, 0],
                [-115.93019, 43.44423, 0],
                [-115.93025, 43.44439, 0],
                [-115.93039, 43.44456, 0],
                [-115.9305, 43.44471, 0],
                [-115.93063, 43.44493, 0],
                [-115.9309, 43.44528, 0],
                [-115.93118, 43.44535, 0],
                [-115.93161, 43.44543, 0],
                [-115.93203, 43.44555, 0],
                [-115.93207, 43.44555, 0],
                [-115.93251, 43.44565, 0],
                [-115.93255, 43.44565, 0],
                [-115.93314, 43.44579, 0],
                [-115.93324, 43.44583, 0],
                [-115.93325, 43.44583, 0],
                [-115.93382, 43.4461, 0],
                [-115.93388, 43.44614, 0],
                [-115.93403, 43.44626, 0],
                [-115.93419, 43.44641, 0],
                [-115.93461, 43.44699, 0],
                [-115.9347, 43.44709, 0],
                [-115.93493, 43.44727, 0],
                [-115.93546, 43.44755, 0],
                [-115.93559, 43.44766, 0],
                [-115.93588, 43.44794, 0],
                [-115.93635, 43.4485, 0],
                [-115.93657, 43.4488, 0],
                [-115.93671, 43.44896, 0],
                [-115.93688, 43.44908, 0],
                [-115.93704, 43.44917, 0],
                [-115.93752, 43.44938, 0],
                [-115.93768, 43.44947, 0],
                [-115.93783, 43.44959, 0],
                [-115.93801, 43.44976, 0],
                [-115.93805, 43.44979, 0],
                [-115.93854, 43.45035, 0],
                [-115.93886, 43.45068, 0],
                [-115.93895, 43.45075, 0],
                [-115.93915, 43.45084, 0],
                [-115.93943, 43.45091, 0],
                [-115.94115, 43.45123, 0],
                [-115.94487, 43.45207, 0],
                [-115.94492, 43.4521, 0],
                [-115.94505, 43.45215, 0],
                [-115.94581, 43.45258, 0],
                [-115.94596, 43.4527, 0],
                [-115.94656, 43.45326, 0],
                [-115.94769, 43.45421, 0],
                [-115.9481, 43.45459, 0],
                [-115.94823, 43.45475, 0],
                [-115.94901, 43.45582, 0],
                [-115.94918, 43.45607, 0],
                [-115.94959, 43.45673, 0],
                [-115.95014, 43.45779, 0],
                [-115.95019, 43.45785, 0],
                [-115.95064, 43.45849, 0],
                [-115.95065, 43.45851, 0],
                [-115.95085, 43.45873, 0],
                [-115.95092, 43.45879, 0],
                [-115.95111, 43.45892, 0],
                [-115.95129, 43.459, 0],
                [-115.95149, 43.45907, 0],
                [-115.95263, 43.45934, 0],
                [-115.95273, 43.45938, 0],
                [-115.95286, 43.45945, 0],
                [-115.95329, 43.45974, 0],
                [-115.95331, 43.45976, 0],
                [-115.95417, 43.46046, 0],
                [-115.95456, 43.46073, 0],
                [-115.95486, 43.46092, 0],
                [-115.95529, 43.46113, 0],
                [-115.95595, 43.46133, 0],
                [-115.95596, 43.46133, 0],
                [-115.95619, 43.46139, 0],
                [-115.95643, 43.46143, 0],
                [-115.95664, 43.46149, 0],
                [-115.95703, 43.46165, 0],
                [-115.95771, 43.46197, 0],
                [-115.95774, 43.46199, 0],
                [-115.95891, 43.46253, 0],
                [-115.95911, 43.46265, 0],
                [-115.9597, 43.46304, 0],
                [-115.96036, 43.46345, 0],
                [-115.96156, 43.46426, 0],
                [-115.9617, 43.46437, 0],
                [-115.9617, 43.46438, 0],
                [-115.96183, 43.46453, 0],
                [-115.96184, 43.46455, 0],
                [-115.96207, 43.46477, 0],
                [-115.96223, 43.46489, 0],
                [-115.96235, 43.46496, 0],
                [-115.96314, 43.46526, 0],
                [-115.96318, 43.46528, 0],
                [-115.96369, 43.46545, 0],
                [-115.96416, 43.46555, 0],
                [-115.96493, 43.46574, 0],
                [-115.9651, 43.46577, 0],
                [-115.96529, 43.46578, 0],
                [-115.96533, 43.46579, 0],
                [-115.96675, 43.46593, 0],
                [-115.96704, 43.46594, 0],
                [-115.96924, 43.46593, 0],
                [-115.9696, 43.46596, 0],
                [-115.96974, 43.46598, 0],
                [-115.96982, 43.46601, 0],
                [-115.97124, 43.46683, 0],
                [-115.97147, 43.46693, 0],
                [-115.97211, 43.46718, 0],
                [-115.97228, 43.46722, 0],
                [-115.97249, 43.46725, 0],
                [-115.97266, 43.46724, 0],
                [-115.97284, 43.46724, 0],
                [-115.97358, 43.46719, 0],
                [-115.97434, 43.46717, 0],
                [-115.97512, 43.46717, 0],
                [-115.97521, 43.46718, 0],
                [-115.97591, 43.46735, 0],
                [-115.97749, 43.46756, 0],
                [-115.97799, 43.46768, 0],
                [-115.97871, 43.46788, 0],
                [-115.97921, 43.46814, 0],
                [-115.97968, 43.4686, 0],
                [-115.97978, 43.46867, 0],
                [-115.97998, 43.46912, 0],
                [-115.98001, 43.46925, 0],
                [-115.98021, 43.46961, 0],
                [-115.98077, 43.47025, 0],
                [-115.98133, 43.47059, 0],
                [-115.98141, 43.47068, 0],
                [-115.98181, 43.47152, 0],
                [-115.98224, 43.47198, 0],
                [-115.98251, 43.47235, 0],
                [-115.98288, 43.473, 0],
                [-115.98338, 43.47348, 0],
                [-115.98515, 43.47422, 0],
                [-115.98583, 43.47425, 0],
                [-115.9862, 43.47431, 0],
                [-115.98696, 43.47459, 0],
                [-115.98771, 43.476, 0],
                [-115.98783, 43.47607, 0],
                [-115.98816, 43.47629, 0],
                [-115.98948, 43.47692, 0],
                [-115.99005, 43.47707, 0],
                [-115.99095, 43.47749, 0],
                [-115.99162, 43.47776, 0],
                [-115.99554, 43.47922, 0],
                [-115.99558, 43.47924, 0],
                [-115.99585, 43.47942, 0],
                [-115.99596, 43.47961, 0],
                [-115.99706, 43.48037, 0],
                [-115.99749, 43.48085, 0],
                [-115.99772, 43.48107, 0],
                [-115.99821, 43.48159, 0],
                [-115.99868, 43.482, 0],
                [-115.99878, 43.48207, 0],
                [-115.99904, 43.4823, 0],
                [-115.99965, 43.48278, 0],
                [-116, 43.48301, 0],
                [-116.00052, 43.48327, 0],
                [-116.00145, 43.48358, 0],
                [-116.00236, 43.48409, 0],
                [-116.00345, 43.48467, 0],
                [-116.00415, 43.48547, 0],
                [-116.00457, 43.4858, 0],
                [-116.00482, 43.48611, 0],
                [-116.00501, 43.48659, 0],
                [-116.00519, 43.48679, 0],
                [-116.00547, 43.48697, 0],
                [-116.00612, 43.48718, 0],
                [-116.00684, 43.48744, 0],
                [-116.00779, 43.48794, 0],
                [-116.00835, 43.48841, 0],
                [-116.00851, 43.48849, 0],
                [-116.00852, 43.4885, 0],
                [-116.0089, 43.48863, 0],
                [-116.01059, 43.48902, 0],
                [-116.0109, 43.48907, 0],
                [-116.01131, 43.48909, 0],
                [-116.01196, 43.48904, 0],
                [-116.01463, 43.48855, 0],
                [-116.01512, 43.48824, 0],
                [-116.01621, 43.48726, 0],
                [-116.01717, 43.48599, 0],
                [-116.01804, 43.4851, 0],
                [-116.01937, 43.48323, 0],
                [-116.02054, 43.48086, 0],
                [-116.02089, 43.48035, 0],
                [-116.02106, 43.48017, 0],
                [-116.02124, 43.48003, 0],
                [-116.02149, 43.47991, 0],
                [-116.02214, 43.47971, 0],
                [-116.02239, 43.47956, 0],
                [-116.02256, 43.47941, 0],
                [-116.02259, 43.47937, 0],
                [-116.02311, 43.47881, 0],
                [-116.02353, 43.47857, 0],
                [-116.02455, 43.47818, 0],
                [-116.02507, 43.47804, 0],
                [-116.02543, 43.47803, 0],
                [-116.02684, 43.47818, 0],
                [-116.02772, 43.47824, 0],
                [-116.02842, 43.47825, 0],
                [-116.02902, 43.47823, 0],
                [-116.02966, 43.47819, 0],
                [-116.03037, 43.47809, 0],
                [-116.03039, 43.47809, 0],
                [-116.03145, 43.47792, 0],
                [-116.03174, 43.47789, 0],
                [-116.03209, 43.47787, 0],
                [-116.03582, 43.47802, 0],
                [-116.03785, 43.47818, 0],
                [-116.03938, 43.47822, 0],
                [-116.03997, 43.47821, 0],
                [-116.04051, 43.47814, 0],
                [-116.04106, 43.47814, 0],
                [-116.04144, 43.47816, 0],
                [-116.04163, 43.47818, 0],
                [-116.04276, 43.47825, 0],
                [-116.04311, 43.47824, 0],
                [-116.04406, 43.47812, 0],
                [-116.0446, 43.47798, 0],
                [-116.04539, 43.47772, 0],
                [-116.04594, 43.47761, 0],
                [-116.04653, 43.47755, 0],
                [-116.04709, 43.47747, 0],
                [-116.04735, 43.4774, 0],
                [-116.04764, 43.4773, 0],
                [-116.04813, 43.47708, 0],
                [-116.04866, 43.4768, 0],
                [-116.04907, 43.4766, 0],
                [-116.04946, 43.47646, 0],
                [-116.05, 43.4763, 0],
                [-116.05046, 43.4762, 0],
                [-116.05049, 43.47619, 0],
                [-116.05092, 43.47613, 0],
                [-116.05153, 43.4761, 0],
                [-116.05329, 43.47583, 0],
                [-116.05443, 43.47561, 0],
                [-116.05493, 43.47548, 0],
                [-116.05545, 43.47517, 0],
                [-116.05645, 43.47466, 0],
                [-116.05738, 43.47412, 0],
                [-116.05785, 43.47383, 0],
                [-116.05894, 43.47296, 0],
                [-116.06031, 43.47178, 0],
                [-116.06055, 43.47154, 0],
                [-116.061, 43.47116, 0],
                [-116.06137, 43.47093, 0],
                [-116.06156, 43.4708, 0],
                [-116.06227, 43.47027, 0],
                [-116.06276, 43.46993, 0],
                [-116.06317, 43.46971, 0],
                [-116.06326, 43.46967, 0],
                [-116.06425, 43.46931, 0],
                [-116.06489, 43.46919, 0],
                [-116.06558, 43.46914, 0],
                [-116.06634, 43.46913, 0],
                [-116.06652, 43.46912, 0],
                [-116.06704, 43.46905, 0],
                [-116.06865, 43.4686, 0],
                [-116.0708, 43.46851, 0],
                [-116.07089, 43.4685, 0],
                [-116.07276, 43.46856, 0],
                [-116.07334, 43.46861, 0],
                [-116.07425, 43.46875, 0],
                [-116.07506, 43.46903, 0],
                [-116.07528, 43.46912, 0],
                [-116.07598, 43.46916, 0],
                [-116.07748, 43.46881, 0],
                [-116.07894, 43.46834, 0],
                [-116.08098, 43.46807, 0],
                [-116.08165, 43.468, 0],
                [-116.08245, 43.4681, 0],
                [-116.0849, 43.46816, 0],
                [-116.08598, 43.46816, 0],
                [-116.08652, 43.46819, 0],
                [-116.08785, 43.46821, 0],
                [-116.08848, 43.46815, 0],
                [-116.08883, 43.4681, 0],
                [-116.08914, 43.468, 0],
                [-116.08979, 43.46771, 0],
                [-116.09032, 43.4674, 0],
                [-116.09066, 43.46706, 0],
                [-116.09084, 43.46684, 0],
                [-116.09099, 43.4666, 0]
            ]
        },
        id: "173b15a3-8a22-48b6-81b1-17abd02b1898"
    }, 
    // {
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-115.30853, 42.96339, 0]
    //     },
    //     properties: {
    //         name: "Glenns Ferry, Idaho",
    //         "shape": "Marker",
    //         "category": "default"
    //     },
    //     id: "c851c32e-1eae-4bec-939f-c805a0f53689"
    // }, {
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-116.09099, 43.4666, 0]
    //     },
    //     properties: {
    //         name: "Boise, Idaho",
    //         "shape": "Marker",
    //         "category": "default"
    //     },
    //     id: "6c8a6fcf-02f6-4b23-b339-d991f8af09e3"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Mountain Home, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-115.687378, 43.133688]
    //     },
    //     id: "60429bf5-1898-4f2f-909f-de807fd245f5"
    // }, {
    //     type: "Feature",
    //     properties: {
    //         "shape": "Circle",
    //         "radius": 2000,
    //         name: "Mayfield, Idaho",
    //         "category": "default"
    //     },
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-115.90140322998137, 43.4179550886686]
    //     },
    //     id: "7a3368d7-d5b6-4cac-89ab-c9c487794f5c"
    // }
    ]
};

export default MainOregonTrail;