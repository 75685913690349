import { createReducer } from "@reduxjs/toolkit";
import { setMarkers } from "../actions/markersActions";

const initialState = {
    markers: []
}

const markersReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setMarkers, (state, action) => {
            let tempArray = [];
            action.payload.forEach((item, index, arr) => {
                let itemObject = [];
                itemObject.title = item['title'];
                itemObject.id = item['id'];
                itemObject.excerpt = item['excerpt'];
                itemObject.url = item['url'];
                itemObject.image = item['image'];
                itemObject.street_1 = item['street_1'];
                itemObject.street_2 = item['street_2'];
                itemObject.city = item['city'];
                itemObject.state = item['state'];
                itemObject.zip = item['zip'];
                itemObject.lat = item['lat'];
                itemObject.lng = item['lng'];
                itemObject.categories = item['categories'];
                itemObject.primary_category = item['primary_category'];
                itemObject.region = item['region'];
                itemObject.is_selected = false;
                if ((itemObject.lat < 54.100971058261564 && itemObject.lat > 38.3800071147504) && (itemObject.lng < -106.61713826558358 && itemObject.lat > -122.50336811441568) && itemObject.primary_category !== 'Road Trips') {
                    tempArray.push(itemObject);
                }
            });
            action.payload = tempArray;
            state.markers.push(action.payload);
        })
});

export default markersReducer;