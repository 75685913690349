const SalmonRiver = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Salmon River Scenic Byway",
				description: "Explore Idaho's wild and scenic side along the Salmon River Scenic Byway. This 161-mile route follows the Salmon River providing access to jaw-dropping outdoor opportunities as well as a chance to visit ghost towns and other historical sites. Add in wonderful views of the rugged Sawtooth Mountains, and it's easy to understand why this is a must-see scenic byway.",
				link: "https://visitidaho.org/things-to-do/road-trips/salmon-river-scenic-byway/",
				route: [
					"Salmon", 
					"Stanley", 
					"Challis"
				],
				tessellate: 1,
				shape: 'Line'
			},
			geometry: {
				type: "LineString",
				coordinates: [
					[-113.89063, 45.16857],
					[-113.89132, 45.16762],
					[-113.89156, 45.16726],
					[-113.89228, 45.16627],
					[-113.89242, 45.16606],
					[-113.89262, 45.16572],
					[-113.89275, 45.16547],
					[-113.89286, 45.16521],
					[-113.8929, 45.16509],
					[-113.89296, 45.16483],
					[-113.893, 45.16457],
					[-113.89302, 45.16403],
					[-113.89306, 45.16068],
					[-113.89302, 45.15686],
					[-113.89307, 45.15039],
					[-113.89305, 45.13614],
					[-113.89304, 45.13596],
					[-113.89301, 45.12661],
					[-113.89299, 45.12601],
					[-113.89297, 45.11853],
					[-113.89294, 45.11689],
					[-113.89287, 45.10123],
					[-113.89289, 45.10005],
					[-113.89286, 45.09847],
					[-113.89283, 45.09801],
					[-113.89282, 45.09798],
					[-113.89279, 45.09773],
					[-113.89278, 45.09768],
					[-113.89276, 45.09746],
					[-113.89269, 45.09703],
					[-113.89269, 45.097],
					[-113.8924, 45.0955],
					[-113.89239, 45.09541],
					[-113.89237, 45.09532],
					[-113.89233, 45.09462],
					[-113.8924, 45.09419],
					[-113.8924, 45.09417],
					[-113.8925, 45.0938],
					[-113.89251, 45.09374],
					[-113.89257, 45.09358],
					[-113.8926, 45.09348],
					[-113.89267, 45.0933],
					[-113.8927, 45.09325],
					[-113.89273, 45.09318],
					[-113.89307, 45.09258],
					[-113.89397, 45.0913],
					[-113.89407, 45.09114],
					[-113.89426, 45.0909],
					[-113.89458, 45.09044],
					[-113.89671, 45.08771],
					[-113.89682, 45.08758],
					[-113.89702, 45.08731],
					[-113.8973, 45.08689],
					[-113.89738, 45.08674],
					[-113.8974, 45.08672],
					[-113.89744, 45.08665],
					[-113.89752, 45.08647],
					[-113.89763, 45.08617],
					[-113.89799, 45.08491],
					[-113.89832, 45.08367],
					[-113.89836, 45.08349],
					[-113.89837, 45.08349],
					[-113.89846, 45.08315],
					[-113.89849, 45.08308],
					[-113.89851, 45.08302],
					[-113.89865, 45.08271],
					[-113.89871, 45.08256],
					[-113.89886, 45.08224],
					[-113.89904, 45.08189],
					[-113.8999, 45.08006],
					[-113.89997, 45.07993],
					[-113.9, 45.07985],
					[-113.90021, 45.07949],
					[-113.90024, 45.07945],
					[-113.90028, 45.07941],
					[-113.90032, 45.07935],
					[-113.90059, 45.07903],
					[-113.90139, 45.07813],
					[-113.90194, 45.07754],
					[-113.90201, 45.07748],
					[-113.90208, 45.07741],
					[-113.90222, 45.07729],
					[-113.90234, 45.0772],
					[-113.90248, 45.07708],
					[-113.90283, 45.07684],
					[-113.90322, 45.07655],
					[-113.90346, 45.07631],
					[-113.90352, 45.07624],
					[-113.90366, 45.07604],
					[-113.90413, 45.07491],
					[-113.90417, 45.07483],
					[-113.90427, 45.07459],
					[-113.90441, 45.07432],
					[-113.90455, 45.07412],
					[-113.90469, 45.07395],
					[-113.90483, 45.07383],
					[-113.90488, 45.07378],
					[-113.90575, 45.07312],
					[-113.90582, 45.07306],
					[-113.90583, 45.07306],
					[-113.90612, 45.07281],
					[-113.90616, 45.07277],
					[-113.90622, 45.0727],
					[-113.90629, 45.07263],
					[-113.90644, 45.07246],
					[-113.90664, 45.07217],
					[-113.90685, 45.07178],
					[-113.90719, 45.07102],
					[-113.90731, 45.07081],
					[-113.90745, 45.0706],
					[-113.90769, 45.0703],
					[-113.90775, 45.07025],
					[-113.9078, 45.07019],
					[-113.90782, 45.07018],
					[-113.90792, 45.07009],
					[-113.90823, 45.06984],
					[-113.90863, 45.06958],
					[-113.9093, 45.0692],
					[-113.91073, 45.06844],
					[-113.91155, 45.06792],
					[-113.91301, 45.0667],
					[-113.91334, 45.0664],
					[-113.91359, 45.06614],
					[-113.91367, 45.06604],
					[-113.91384, 45.06579],
					[-113.91434, 45.065],
					[-113.91439, 45.06493],
					[-113.91445, 45.06486],
					[-113.91453, 45.06475],
					[-113.91473, 45.06454],
					[-113.91494, 45.06435],
					[-113.91519, 45.06416],
					[-113.91533, 45.06407],
					[-113.91562, 45.06392],
					[-113.91563, 45.06391],
					[-113.91625, 45.0636],
					[-113.91645, 45.06348],
					[-113.91666, 45.06332],
					[-113.91681, 45.06319],
					[-113.91692, 45.06308],
					[-113.91698, 45.063],
					[-113.91706, 45.06291],
					[-113.91718, 45.06274],
					[-113.91725, 45.06261],
					[-113.91732, 45.06242],
					[-113.91734, 45.06238],
					[-113.91736, 45.06228],
					[-113.91738, 45.06221],
					[-113.91738, 45.06216],
					[-113.91739, 45.0621],
					[-113.91739, 45.06192],
					[-113.91738, 45.06188],
					[-113.91737, 45.0618],
					[-113.91735, 45.06171],
					[-113.91734, 45.06162],
					[-113.91731, 45.06153],
					[-113.91667, 45.05901],
					[-113.9165, 45.05841],
					[-113.91641, 45.05819],
					[-113.91628, 45.05791],
					[-113.91608, 45.05755],
					[-113.91603, 45.05748],
					[-113.91594, 45.05733],
					[-113.9156, 45.0569],
					[-113.91555, 45.05685],
					[-113.91543, 45.05671],
					[-113.91537, 45.05666],
					[-113.91532, 45.0566],
					[-113.91411, 45.05548],
					[-113.91403, 45.05539],
					[-113.914, 45.05537],
					[-113.91397, 45.05533],
					[-113.91383, 45.05519],
					[-113.91374, 45.05509],
					[-113.91368, 45.055],
					[-113.91351, 45.05479],
					[-113.91343, 45.05466],
					[-113.91335, 45.05455],
					[-113.91329, 45.05445],
					[-113.91324, 45.05435],
					[-113.91318, 45.05425],
					[-113.91309, 45.05406],
					[-113.91306, 45.05397],
					[-113.91298, 45.05378],
					[-113.91291, 45.05357],
					[-113.91289, 45.05349],
					[-113.91287, 45.05344],
					[-113.91284, 45.05333],
					[-113.9128, 45.05309],
					[-113.91277, 45.05284],
					[-113.91275, 45.05232],
					[-113.91278, 45.05198],
					[-113.91282, 45.05175],
					[-113.91286, 45.0516],
					[-113.91289, 45.05145],
					[-113.91292, 45.05136],
					[-113.91292, 45.05134],
					[-113.91294, 45.05129],
					[-113.91296, 45.05122],
					[-113.91328, 45.05034],
					[-113.9133, 45.0503],
					[-113.9134, 45.05001],
					[-113.91342, 45.04992],
					[-113.91345, 45.04984],
					[-113.91353, 45.04942],
					[-113.91353, 45.04939],
					[-113.91355, 45.0493],
					[-113.91356, 45.04923],
					[-113.91359, 45.04852],
					[-113.91359, 45.04812],
					[-113.9136, 45.04788],
					[-113.9136, 45.04745],
					[-113.91362, 45.04691],
					[-113.91362, 45.04633],
					[-113.91364, 45.04586],
					[-113.91366, 45.0442],
					[-113.9137, 45.04355],
					[-113.91372, 45.04344],
					[-113.91373, 45.04334],
					[-113.91379, 45.04299],
					[-113.91429, 45.04063],
					[-113.9144, 45.03988],
					[-113.91442, 45.03969],
					[-113.91442, 45.03871],
					[-113.91443, 45.03848],
					[-113.91446, 45.03821],
					[-113.91446, 45.03817],
					[-113.91455, 45.0376],
					[-113.91532, 45.03347],
					[-113.91535, 45.03327],
					[-113.91538, 45.03298],
					[-113.91538, 45.03265],
					[-113.91537, 45.03245],
					[-113.91531, 45.03204],
					[-113.91525, 45.03178],
					[-113.91489, 45.03051],
					[-113.91487, 45.0304],
					[-113.91485, 45.03035],
					[-113.91481, 45.03007],
					[-113.91481, 45.02968],
					[-113.91483, 45.02949],
					[-113.91485, 45.02938],
					[-113.91491, 45.02913],
					[-113.91493, 45.02907],
					[-113.91494, 45.02902],
					[-113.91496, 45.02899],
					[-113.91502, 45.02885],
					[-113.9151, 45.0287],
					[-113.9151, 45.02869],
					[-113.91516, 45.02861],
					[-113.91523, 45.02849],
					[-113.91546, 45.02818],
					[-113.91554, 45.0281],
					[-113.91676, 45.0267],
					[-113.91811, 45.02521],
					[-113.9182, 45.0251],
					[-113.91986, 45.02323],
					[-113.91986, 45.02322],
					[-113.9199, 45.02316],
					[-113.91998, 45.02306],
					[-113.9201, 45.02286],
					[-113.9202, 45.02271],
					[-113.92037, 45.02239],
					[-113.92041, 45.02229],
					[-113.9211, 45.02086],
					[-113.92111, 45.02085],
					[-113.92121, 45.02066],
					[-113.92126, 45.02058],
					[-113.92141, 45.02037],
					[-113.9216, 45.02014],
					[-113.92169, 45.02005],
					[-113.92192, 45.01979],
					[-113.92265, 45.01909],
					[-113.92271, 45.01904],
					[-113.92275, 45.019],
					[-113.92291, 45.01888],
					[-113.92319, 45.0187],
					[-113.92336, 45.01862],
					[-113.92353, 45.01855],
					[-113.92365, 45.01851],
					[-113.92379, 45.01845],
					[-113.92472, 45.01815],
					[-113.9248, 45.01811],
					[-113.92504, 45.01801],
					[-113.9251, 45.01797],
					[-113.92517, 45.01794],
					[-113.92529, 45.01787],
					[-113.92539, 45.0178],
					[-113.92546, 45.01776],
					[-113.92554, 45.0177],
					[-113.92567, 45.01759],
					[-113.92573, 45.01753],
					[-113.92576, 45.01749],
					[-113.92579, 45.01747],
					[-113.92584, 45.01741],
					[-113.9259, 45.01735],
					[-113.92594, 45.01729],
					[-113.92598, 45.01724],
					[-113.926, 45.01719],
					[-113.92605, 45.01713],
					[-113.92609, 45.01705],
					[-113.92609, 45.01704],
					[-113.92612, 45.01699],
					[-113.9262, 45.01676],
					[-113.92621, 45.01668],
					[-113.92623, 45.01663],
					[-113.92624, 45.01656],
					[-113.92624, 45.01649],
					[-113.9264, 45.01475],
					[-113.92641, 45.01473],
					[-113.92654, 45.01316],
					[-113.92657, 45.013],
					[-113.92659, 45.01285],
					[-113.92663, 45.01264],
					[-113.92669, 45.01239],
					[-113.92669, 45.01238],
					[-113.92679, 45.01208],
					[-113.92696, 45.01169],
					[-113.92706, 45.01149],
					[-113.92711, 45.01141],
					[-113.92719, 45.01126],
					[-113.92725, 45.01117],
					[-113.92726, 45.01116],
					[-113.9273, 45.01109],
					[-113.92743, 45.01091],
					[-113.92774, 45.01053],
					[-113.92976, 45.00822],
					[-113.93004, 45.00788],
					[-113.9304, 45.00747],
					[-113.93047, 45.0074],
					[-113.93079, 45.00701],
					[-113.93122, 45.00654],
					[-113.93146, 45.00623],
					[-113.93158, 45.00603],
					[-113.93165, 45.00589],
					[-113.93174, 45.00565],
					[-113.9318, 45.00541],
					[-113.93181, 45.00532],
					[-113.93182, 45.00527],
					[-113.93182, 45.00522],
					[-113.93183, 45.00514],
					[-113.93183, 45.00497],
					[-113.93178, 45.00463],
					[-113.93176, 45.00456],
					[-113.93173, 45.00448],
					[-113.93171, 45.00441],
					[-113.9316, 45.00417],
					[-113.93155, 45.00409],
					[-113.93085, 45.00282],
					[-113.93076, 45.00262],
					[-113.93074, 45.00259],
					[-113.93073, 45.00254],
					[-113.93066, 45.00233],
					[-113.93063, 45.0021],
					[-113.93063, 45.00193],
					[-113.93065, 45.00175],
					[-113.93067, 45.00166],
					[-113.93073, 45.00146],
					[-113.93077, 45.00135],
					[-113.93083, 45.00123],
					[-113.93095, 45.00102],
					[-113.93113, 45.00079],
					[-113.93134, 45.00058],
					[-113.93151, 45.00045],
					[-113.9317, 45.00032],
					[-113.93206, 45.00012],
					[-113.93308, 44.99968],
					[-113.93361, 44.99943],
					[-113.93554, 44.99858],
					[-113.93562, 44.99855],
					[-113.94232, 44.99562],
					[-113.94254, 44.99551],
					[-113.94294, 44.99528],
					[-113.94322, 44.9951],
					[-113.94352, 44.99487],
					[-113.94381, 44.99462],
					[-113.94399, 44.99444],
					[-113.94404, 44.99438],
					[-113.9441, 44.99432],
					[-113.94419, 44.99421],
					[-113.94433, 44.99401],
					[-113.94437, 44.99393],
					[-113.94441, 44.99388],
					[-113.94444, 44.99382],
					[-113.94448, 44.99376],
					[-113.94451, 44.99369],
					[-113.94458, 44.99357],
					[-113.94471, 44.99324],
					[-113.94473, 44.99317],
					[-113.94476, 44.99309],
					[-113.94477, 44.99302],
					[-113.94481, 44.99289],
					[-113.94483, 44.99276],
					[-113.94485, 44.99269],
					[-113.94508, 44.99126],
					[-113.94509, 44.99116],
					[-113.94515, 44.99082],
					[-113.94526, 44.98991],
					[-113.94534, 44.98947],
					[-113.94537, 44.98938],
					[-113.94538, 44.98932],
					[-113.94541, 44.98922],
					[-113.94543, 44.98918],
					[-113.94546, 44.9891],
					[-113.94548, 44.98903],
					[-113.9455, 44.98898],
					[-113.94564, 44.9887],
					[-113.94567, 44.98866],
					[-113.94579, 44.98843],
					[-113.9475, 44.98553],
					[-113.94763, 44.98529],
					[-113.94767, 44.98523],
					[-113.94778, 44.985],
					[-113.94789, 44.98474],
					[-113.94792, 44.98464],
					[-113.94796, 44.98455],
					[-113.948, 44.98444],
					[-113.94808, 44.98413],
					[-113.94808, 44.98412],
					[-113.94811, 44.98403],
					[-113.94812, 44.98397],
					[-113.94814, 44.9839],
					[-113.94816, 44.98374],
					[-113.94818, 44.98367],
					[-113.94827, 44.98309],
					[-113.94828, 44.98305],
					[-113.94828, 44.98303],
					[-113.9483, 44.98295],
					[-113.94832, 44.98291],
					[-113.9484, 44.98268],
					[-113.94846, 44.98257],
					[-113.9486, 44.98235],
					[-113.94863, 44.98231],
					[-113.94866, 44.98226],
					[-113.94869, 44.98223],
					[-113.94878, 44.98212],
					[-113.94884, 44.98206],
					[-113.9489, 44.98201],
					[-113.94895, 44.98196],
					[-113.94902, 44.9819],
					[-113.94909, 44.98185],
					[-113.94915, 44.98179],
					[-113.94988, 44.9813],
					[-113.94995, 44.98124],
					[-113.95002, 44.98119],
					[-113.95013, 44.9811],
					[-113.95017, 44.98106],
					[-113.95024, 44.981],
					[-113.95026, 44.98097],
					[-113.9503, 44.98093],
					[-113.95035, 44.98087],
					[-113.95039, 44.98083],
					[-113.95049, 44.9807],
					[-113.95057, 44.98057],
					[-113.95063, 44.98049],
					[-113.95083, 44.98008],
					[-113.95092, 44.97984],
					[-113.95096, 44.97968],
					[-113.95098, 44.97954],
					[-113.95098, 44.97938],
					[-113.95097, 44.97931],
					[-113.95097, 44.97925],
					[-113.95094, 44.9791],
					[-113.95093, 44.97902],
					[-113.95089, 44.97889],
					[-113.95081, 44.97868],
					[-113.95074, 44.97852],
					[-113.95061, 44.97832],
					[-113.9506, 44.9783],
					[-113.95043, 44.97807],
					[-113.9504, 44.97804],
					[-113.95039, 44.97802],
					[-113.95037, 44.978],
					[-113.95035, 44.97797],
					[-113.94916, 44.97682],
					[-113.94889, 44.97658],
					[-113.94858, 44.97635],
					[-113.94853, 44.97633],
					[-113.94848, 44.97629],
					[-113.94842, 44.97626],
					[-113.94832, 44.9762],
					[-113.94818, 44.97613],
					[-113.94806, 44.97609],
					[-113.94802, 44.97607],
					[-113.94787, 44.97601],
					[-113.94782, 44.976],
					[-113.94774, 44.97597],
					[-113.94765, 44.97595],
					[-113.94761, 44.97593],
					[-113.94755, 44.97592],
					[-113.94747, 44.9759],
					[-113.94721, 44.97586],
					[-113.94717, 44.97585],
					[-113.94712, 44.97585],
					[-113.94704, 44.97584],
					[-113.94696, 44.97584],
					[-113.94691, 44.97583],
					[-113.94652, 44.97583],
					[-113.94643, 44.97584],
					[-113.94634, 44.97584],
					[-113.9452, 44.97595],
					[-113.94488, 44.97595],
					[-113.94481, 44.97594],
					[-113.94475, 44.97594],
					[-113.94443, 44.97589],
					[-113.94424, 44.97584],
					[-113.9442, 44.97582],
					[-113.94404, 44.97576],
					[-113.94387, 44.97567],
					[-113.9437, 44.97554],
					[-113.94366, 44.97549],
					[-113.94363, 44.97547],
					[-113.94356, 44.97538],
					[-113.94353, 44.97535],
					[-113.94351, 44.97532],
					[-113.94348, 44.97525],
					[-113.94346, 44.97522],
					[-113.94341, 44.97511],
					[-113.9434, 44.97507],
					[-113.94337, 44.975],
					[-113.94335, 44.97491],
					[-113.94335, 44.97489],
					[-113.94334, 44.97481],
					[-113.94334, 44.97462],
					[-113.94366, 44.97232],
					[-113.94369, 44.97222],
					[-113.94371, 44.97213],
					[-113.94375, 44.972],
					[-113.94378, 44.97194],
					[-113.94383, 44.97182],
					[-113.94394, 44.97162],
					[-113.94404, 44.97146],
					[-113.94421, 44.97124],
					[-113.94428, 44.97116],
					[-113.94456, 44.97088],
					[-113.94471, 44.97075],
					[-113.94507, 44.97049],
					[-113.94527, 44.97037],
					[-113.94565, 44.97017],
					[-113.94592, 44.97006],
					[-113.94609, 44.97001],
					[-113.94628, 44.96994],
					[-113.9473, 44.96965],
					[-113.95064, 44.96878],
					[-113.95152, 44.96853],
					[-113.95204, 44.96835],
					[-113.95248, 44.96815],
					[-113.95292, 44.96788],
					[-113.95328, 44.9676],
					[-113.95352, 44.96736],
					[-113.95368, 44.96716],
					[-113.95374, 44.96707],
					[-113.95378, 44.967],
					[-113.95379, 44.96699],
					[-113.9539, 44.96679],
					[-113.95392, 44.96674],
					[-113.95394, 44.96671],
					[-113.95395, 44.96667],
					[-113.95397, 44.96663],
					[-113.95407, 44.96631],
					[-113.95408, 44.96623],
					[-113.9541, 44.96614],
					[-113.95411, 44.96607],
					[-113.95411, 44.966],
					[-113.95412, 44.96593],
					[-113.95412, 44.96586],
					[-113.95411, 44.96579],
					[-113.95411, 44.96569],
					[-113.95409, 44.9656],
					[-113.95408, 44.96552],
					[-113.95399, 44.96521],
					[-113.95393, 44.96506],
					[-113.95384, 44.96489],
					[-113.95381, 44.96482],
					[-113.95376, 44.96475],
					[-113.95372, 44.96468],
					[-113.95356, 44.96447],
					[-113.95338, 44.96427],
					[-113.95316, 44.96406],
					[-113.95302, 44.96394],
					[-113.95275, 44.96375],
					[-113.9523, 44.9635],
					[-113.95192, 44.96334],
					[-113.94864, 44.9621],
					[-113.94853, 44.96204],
					[-113.94822, 44.96189],
					[-113.94785, 44.96168],
					[-113.94754, 44.96146],
					[-113.9473, 44.96127],
					[-113.94717, 44.96115],
					[-113.94698, 44.96094],
					[-113.94671, 44.9606],
					[-113.94665, 44.9605],
					[-113.9466, 44.96043],
					[-113.94654, 44.96033],
					[-113.94645, 44.96016],
					[-113.94638, 44.96001],
					[-113.94631, 44.95983],
					[-113.94627, 44.95969],
					[-113.94622, 44.95941],
					[-113.94619, 44.95907],
					[-113.94619, 44.95895],
					[-113.94623, 44.95855],
					[-113.94629, 44.95826],
					[-113.94634, 44.9581],
					[-113.94644, 44.95784],
					[-113.9465, 44.95771],
					[-113.94664, 44.95747],
					[-113.947, 44.95703],
					[-113.94717, 44.95686],
					[-113.9475, 44.95658],
					[-113.94768, 44.95644],
					[-113.94792, 44.95628],
					[-113.94832, 44.95605],
					[-113.9487, 44.95588],
					[-113.94911, 44.95573],
					[-113.94926, 44.95569],
					[-113.94977, 44.95559],
					[-113.95033, 44.95556],
					[-113.95075, 44.95557],
					[-113.9512, 44.95561],
					[-113.9515, 44.95566],
					[-113.95187, 44.95575],
					[-113.95199, 44.95579],
					[-113.95218, 44.95584],
					[-113.95282, 44.95605],
					[-113.95298, 44.95609],
					[-113.95317, 44.95615],
					[-113.9533, 44.95617],
					[-113.95343, 44.9562],
					[-113.95359, 44.95622],
					[-113.95368, 44.95622],
					[-113.95378, 44.95623],
					[-113.95401, 44.95623],
					[-113.95426, 44.95621],
					[-113.9546, 44.95615],
					[-113.95488, 44.95605],
					[-113.95512, 44.95594],
					[-113.95524, 44.95587],
					[-113.95533, 44.9558],
					[-113.95541, 44.95575],
					[-113.9556, 44.95556],
					[-113.9557, 44.95542],
					[-113.95577, 44.95529],
					[-113.95584, 44.95512],
					[-113.95588, 44.95493],
					[-113.95588, 44.95489],
					[-113.95589, 44.95484],
					[-113.95589, 44.95476],
					[-113.95587, 44.95456],
					[-113.95566, 44.95356],
					[-113.9554, 44.95246],
					[-113.95539, 44.95245],
					[-113.95536, 44.95228],
					[-113.95534, 44.95211],
					[-113.95533, 44.95195],
					[-113.95533, 44.95178],
					[-113.95536, 44.95136],
					[-113.95541, 44.951],
					[-113.95549, 44.95065],
					[-113.95554, 44.9505],
					[-113.95554, 44.95048],
					[-113.95561, 44.95031],
					[-113.95562, 44.95031],
					[-113.95571, 44.95014],
					[-113.95574, 44.9501],
					[-113.95579, 44.95002],
					[-113.95582, 44.94999],
					[-113.95587, 44.94992],
					[-113.95612, 44.94966],
					[-113.95623, 44.94957],
					[-113.95655, 44.94935],
					[-113.95666, 44.94929],
					[-113.95674, 44.94923],
					[-113.95681, 44.9492],
					[-113.95975, 44.94775],
					[-113.95978, 44.94773],
					[-113.95993, 44.94765],
					[-113.9602, 44.94748],
					[-113.96049, 44.94725],
					[-113.96066, 44.94708],
					[-113.96076, 44.94696],
					[-113.96076, 44.94695],
					[-113.96086, 44.94681],
					[-113.96094, 44.94665],
					[-113.96097, 44.94651],
					[-113.96098, 44.94643],
					[-113.96098, 44.94626],
					[-113.96097, 44.94622],
					[-113.96097, 44.94617],
					[-113.9609, 44.9459],
					[-113.96037, 44.94452],
					[-113.96037, 44.94451],
					[-113.96004, 44.94365],
					[-113.96002, 44.94361],
					[-113.95993, 44.94332],
					[-113.95993, 44.9433],
					[-113.9599, 44.94316],
					[-113.95988, 44.94302],
					[-113.95988, 44.94297],
					[-113.95987, 44.94292],
					[-113.95987, 44.94245],
					[-113.95988, 44.94237],
					[-113.95988, 44.94228],
					[-113.9599, 44.9422],
					[-113.95991, 44.94213],
					[-113.95993, 44.94205],
					[-113.96013, 44.94146],
					[-113.96042, 44.941],
					[-113.96077, 44.94052],
					[-113.96077, 44.94051],
					[-113.96091, 44.94034],
					[-113.96186, 44.93909],
					[-113.96216, 44.93865],
					[-113.96223, 44.93851],
					[-113.9623, 44.93839],
					[-113.96235, 44.93829],
					[-113.96261, 44.9376],
					[-113.96261, 44.93757],
					[-113.96264, 44.93747],
					[-113.96266, 44.93738],
					[-113.96273, 44.93689],
					[-113.96273, 44.93658],
					[-113.96272, 44.93648],
					[-113.96272, 44.93639],
					[-113.96269, 44.93618],
					[-113.96262, 44.93581],
					[-113.96262, 44.9358],
					[-113.96259, 44.93567],
					[-113.96252, 44.93544],
					[-113.96209, 44.93419],
					[-113.96133, 44.93217],
					[-113.96131, 44.93213],
					[-113.96104, 44.9313],
					[-113.96102, 44.93117],
					[-113.96102, 44.93086],
					[-113.96103, 44.93078],
					[-113.96103, 44.93076],
					[-113.96105, 44.93061],
					[-113.96106, 44.93058],
					[-113.9611, 44.93041],
					[-113.96113, 44.93033],
					[-113.96115, 44.93024],
					[-113.9612, 44.93015],
					[-113.96123, 44.93007],
					[-113.96128, 44.92998],
					[-113.96131, 44.92994],
					[-113.96133, 44.9299],
					[-113.96139, 44.92981],
					[-113.96145, 44.92974],
					[-113.96157, 44.92957],
					[-113.96234, 44.92867],
					[-113.96234, 44.92866],
					[-113.96244, 44.92855],
					[-113.96246, 44.92851],
					[-113.96259, 44.92834],
					[-113.96264, 44.92825],
					[-113.9627, 44.92817],
					[-113.96274, 44.92809],
					[-113.96279, 44.92802],
					[-113.96308, 44.92755],
					[-113.96328, 44.9272],
					[-113.9633, 44.92715],
					[-113.96426, 44.92549],
					[-113.96439, 44.92524],
					[-113.96444, 44.92512],
					[-113.96445, 44.92508],
					[-113.96447, 44.92503],
					[-113.96451, 44.92482],
					[-113.96452, 44.92472],
					[-113.96452, 44.92465],
					[-113.96453, 44.92459],
					[-113.96452, 44.92453],
					[-113.96452, 44.92446],
					[-113.96451, 44.9244],
					[-113.9645, 44.92437],
					[-113.9645, 44.92434],
					[-113.96448, 44.92424],
					[-113.96446, 44.92417],
					[-113.96435, 44.92392],
					[-113.96416, 44.92364],
					[-113.96411, 44.92359],
					[-113.96406, 44.92353],
					[-113.96394, 44.92341],
					[-113.96378, 44.92328],
					[-113.96368, 44.92321],
					[-113.96359, 44.92316],
					[-113.96357, 44.92314],
					[-113.96339, 44.92304],
					[-113.96338, 44.92303],
					[-113.96308, 44.92289],
					[-113.96273, 44.92279],
					[-113.96209, 44.92264],
					[-113.96204, 44.92262],
					[-113.96182, 44.92255],
					[-113.96179, 44.92253],
					[-113.96159, 44.92245],
					[-113.96139, 44.92234],
					[-113.96134, 44.9223],
					[-113.96128, 44.92226],
					[-113.96123, 44.92222],
					[-113.9611, 44.9221],
					[-113.96098, 44.92196],
					[-113.96082, 44.92172],
					[-113.96078, 44.92164],
					[-113.96072, 44.92143],
					[-113.96069, 44.92118],
					[-113.96069, 44.9211],
					[-113.9607, 44.92103],
					[-113.96072, 44.92097],
					[-113.96074, 44.92085],
					[-113.9608, 44.92071],
					[-113.96089, 44.92054],
					[-113.96103, 44.92034],
					[-113.96109, 44.92028],
					[-113.96403, 44.91675],
					[-113.96421, 44.91647],
					[-113.9643, 44.91631],
					[-113.96433, 44.91624],
					[-113.96445, 44.9159],
					[-113.96449, 44.91575],
					[-113.96453, 44.91554],
					[-113.96453, 44.91528],
					[-113.96451, 44.91506],
					[-113.9643, 44.91367],
					[-113.96426, 44.91326],
					[-113.96411, 44.91231],
					[-113.96405, 44.91205],
					[-113.96399, 44.91188],
					[-113.96396, 44.91181],
					[-113.96381, 44.91153],
					[-113.96367, 44.91134],
					[-113.9635, 44.91114],
					[-113.96233, 44.90986],
					[-113.96229, 44.90981],
					[-113.96209, 44.90961],
					[-113.96186, 44.90936],
					[-113.96184, 44.90935],
					[-113.96099, 44.90842],
					[-113.96098, 44.9084],
					[-113.95958, 44.9069],
					[-113.95918, 44.90644],
					[-113.959, 44.90617],
					[-113.95887, 44.90586],
					[-113.95881, 44.90569],
					[-113.95839, 44.90414],
					[-113.95817, 44.90348],
					[-113.95809, 44.90317],
					[-113.95808, 44.90309],
					[-113.95808, 44.90295],
					[-113.95809, 44.90285],
					[-113.95815, 44.9026],
					[-113.95815, 44.90259],
					[-113.95822, 44.90239],
					[-113.95828, 44.90228],
					[-113.95836, 44.90218],
					[-113.95842, 44.90209],
					[-113.95856, 44.90195],
					[-113.95873, 44.90181],
					[-113.95895, 44.90168],
					[-113.95907, 44.90162],
					[-113.95919, 44.90157],
					[-113.95929, 44.90154],
					[-113.95939, 44.9015],
					[-113.95951, 44.90147],
					[-113.95996, 44.90139],
					[-113.96024, 44.90137],
					[-113.96034, 44.90137],
					[-113.96048, 44.90138],
					[-113.96062, 44.90138],
					[-113.96086, 44.90141],
					[-113.96088, 44.90142],
					[-113.96098, 44.90143],
					[-113.96108, 44.90145],
					[-113.96394, 44.90216],
					[-113.9655, 44.90252],
					[-113.9661, 44.90268],
					[-113.96641, 44.90279],
					[-113.96692, 44.90301],
					[-113.96704, 44.90307],
					[-113.96715, 44.90311],
					[-113.96747, 44.90327],
					[-113.96797, 44.90347],
					[-113.96819, 44.90354],
					[-113.96843, 44.9036],
					[-113.96849, 44.90362],
					[-113.96854, 44.90362],
					[-113.96859, 44.90363],
					[-113.9687, 44.90364],
					[-113.96883, 44.90366],
					[-113.96948, 44.90361],
					[-113.96963, 44.90359],
					[-113.96985, 44.90353],
					[-113.96991, 44.90352],
					[-113.97027, 44.90339],
					[-113.97051, 44.90327],
					[-113.97069, 44.90316],
					[-113.97086, 44.90303],
					[-113.97108, 44.90284],
					[-113.97116, 44.90276],
					[-113.97116, 44.90275],
					[-113.97119, 44.90272],
					[-113.97132, 44.90254],
					[-113.97134, 44.90249],
					[-113.97138, 44.90241],
					[-113.97139, 44.90237],
					[-113.97141, 44.90232],
					[-113.97144, 44.90219],
					[-113.97147, 44.90193],
					[-113.97147, 44.90177],
					[-113.97146, 44.9017],
					[-113.9714, 44.90143],
					[-113.97137, 44.90138],
					[-113.97135, 44.90132],
					[-113.97126, 44.90114],
					[-113.97122, 44.90108],
					[-113.97108, 44.90092],
					[-113.97101, 44.90085],
					[-113.97094, 44.90079],
					[-113.97085, 44.9007],
					[-113.97059, 44.9005],
					[-113.97036, 44.90037],
					[-113.96863, 44.89932],
					[-113.96765, 44.8987],
					[-113.96737, 44.89854],
					[-113.96545, 44.89735],
					[-113.96525, 44.89724],
					[-113.96512, 44.89716],
					[-113.96492, 44.89706],
					[-113.96479, 44.89698],
					[-113.96426, 44.8967],
					[-113.9633, 44.89616],
					[-113.96295, 44.89593],
					[-113.96271, 44.89575],
					[-113.96228, 44.8954],
					[-113.96173, 44.89491],
					[-113.96166, 44.89484],
					[-113.9615, 44.89466],
					[-113.96126, 44.89436],
					[-113.9612, 44.89427],
					[-113.96116, 44.89419],
					[-113.96112, 44.89414],
					[-113.96058, 44.89297],
					[-113.96055, 44.89292],
					[-113.96055, 44.89291],
					[-113.96053, 44.89288],
					[-113.96051, 44.89279],
					[-113.96049, 44.89274],
					[-113.96047, 44.89259],
					[-113.96046, 44.89256],
					[-113.96046, 44.8923],
					[-113.96048, 44.89217],
					[-113.9605, 44.8921],
					[-113.9606, 44.89185],
					[-113.96064, 44.89179],
					[-113.96066, 44.89174],
					[-113.96069, 44.89168],
					[-113.96071, 44.89163],
					[-113.96076, 44.89155],
					[-113.9608, 44.89147],
					[-113.96083, 44.89143],
					[-113.96102, 44.89113],
					[-113.96112, 44.89101],
					[-113.96115, 44.89096],
					[-113.9612, 44.8909],
					[-113.9612, 44.89089],
					[-113.96126, 44.89083],
					[-113.96133, 44.89075],
					[-113.9614, 44.89069],
					[-113.96149, 44.8906],
					[-113.96165, 44.89048],
					[-113.96169, 44.89044],
					[-113.96173, 44.89041],
					[-113.9618, 44.89037],
					[-113.96187, 44.89032],
					[-113.96194, 44.89028],
					[-113.96203, 44.89022],
					[-113.96214, 44.89016],
					[-113.96218, 44.89013],
					[-113.96231, 44.89005],
					[-113.96285, 44.88975],
					[-113.96291, 44.88971],
					[-113.96655, 44.88778],
					[-113.96665, 44.88772],
					[-113.96727, 44.8874],
					[-113.96744, 44.8873],
					[-113.96786, 44.88708],
					[-113.96789, 44.88706],
					[-113.969, 44.8865],
					[-113.96903, 44.88648],
					[-113.96929, 44.88634],
					[-113.9693, 44.88634],
					[-113.96997, 44.88595],
					[-113.97065, 44.8855],
					[-113.97114, 44.88515],
					[-113.97136, 44.88497],
					[-113.97146, 44.8849],
					[-113.97221, 44.88425],
					[-113.97236, 44.88411],
					[-113.97281, 44.88364],
					[-113.97308, 44.88329],
					[-113.97322, 44.88308],
					[-113.97327, 44.88298],
					[-113.97328, 44.88297],
					[-113.97333, 44.88289],
					[-113.97345, 44.88266],
					[-113.97347, 44.88261],
					[-113.97352, 44.88251],
					[-113.9736, 44.88231],
					[-113.97363, 44.88225],
					[-113.9737, 44.88201],
					[-113.97371, 44.88194],
					[-113.97373, 44.88189],
					[-113.97374, 44.88183],
					[-113.97376, 44.88175],
					[-113.97376, 44.88172],
					[-113.97377, 44.8817],
					[-113.97378, 44.88163],
					[-113.97378, 44.88157],
					[-113.97379, 44.88152],
					[-113.97379, 44.88147],
					[-113.9738, 44.8814],
					[-113.9738, 44.88126],
					[-113.97381, 44.88118],
					[-113.97381, 44.881],
					[-113.9738, 44.88092],
					[-113.9738, 44.88084],
					[-113.97377, 44.88059],
					[-113.97375, 44.88048],
					[-113.97375, 44.88046],
					[-113.97374, 44.88039],
					[-113.97372, 44.88032],
					[-113.97371, 44.88026],
					[-113.97368, 44.88016],
					[-113.97366, 44.88007],
					[-113.97363, 44.87999],
					[-113.97361, 44.8799],
					[-113.97358, 44.87982],
					[-113.97358, 44.87981],
					[-113.97355, 44.87975],
					[-113.97352, 44.87967],
					[-113.97349, 44.87961],
					[-113.97349, 44.8796],
					[-113.97346, 44.87953],
					[-113.97343, 44.87948],
					[-113.9734, 44.87944],
					[-113.97334, 44.87931],
					[-113.97331, 44.87927],
					[-113.97326, 44.87918],
					[-113.97318, 44.87906],
					[-113.97311, 44.87894],
					[-113.97305, 44.87885],
					[-113.97298, 44.87876],
					[-113.97292, 44.87867],
					[-113.97272, 44.87843],
					[-113.97262, 44.87833],
					[-113.97257, 44.87827],
					[-113.97252, 44.87822],
					[-113.97247, 44.87816],
					[-113.97242, 44.87812],
					[-113.97154, 44.87725],
					[-113.97133, 44.877],
					[-113.97129, 44.87694],
					[-113.97116, 44.87677],
					[-113.97108, 44.87662],
					[-113.97102, 44.87647],
					[-113.971, 44.8764],
					[-113.97099, 44.87634],
					[-113.97097, 44.87628],
					[-113.97096, 44.87623],
					[-113.97095, 44.87614],
					[-113.97095, 44.8761],
					[-113.97094, 44.876],
					[-113.97094, 44.87581],
					[-113.97096, 44.87572],
					[-113.97098, 44.87556],
					[-113.97102, 44.87542],
					[-113.97107, 44.87528],
					[-113.97114, 44.87515],
					[-113.97116, 44.8751],
					[-113.97122, 44.87502],
					[-113.97128, 44.87492],
					[-113.97134, 44.87484],
					[-113.97136, 44.8748],
					[-113.97147, 44.87469],
					[-113.97151, 44.87464],
					[-113.97171, 44.87446],
					[-113.97188, 44.87433],
					[-113.97213, 44.87411],
					[-113.97215, 44.87411],
					[-113.97255, 44.87376],
					[-113.97321, 44.87322],
					[-113.97378, 44.87267],
					[-113.97389, 44.87254],
					[-113.97393, 44.8725],
					[-113.97402, 44.87238],
					[-113.97405, 44.87233],
					[-113.97415, 44.87219],
					[-113.97427, 44.87197],
					[-113.97445, 44.87155],
					[-113.97448, 44.8715],
					[-113.97449, 44.87146],
					[-113.9746, 44.87123],
					[-113.97472, 44.87093],
					[-113.975, 44.8703],
					[-113.97507, 44.87012],
					[-113.97508, 44.87011],
					[-113.97522, 44.86979],
					[-113.9753, 44.86964],
					[-113.97546, 44.86927],
					[-113.97548, 44.86921],
					[-113.97553, 44.86909],
					[-113.97553, 44.86908],
					[-113.97559, 44.86891],
					[-113.97561, 44.86887],
					[-113.97565, 44.86875],
					[-113.97566, 44.86869],
					[-113.97567, 44.86867],
					[-113.97567, 44.86865],
					[-113.97568, 44.86863],
					[-113.97569, 44.86858],
					[-113.97569, 44.86856],
					[-113.9757, 44.86853],
					[-113.97571, 44.86847],
					[-113.97571, 44.86841],
					[-113.97572, 44.86836],
					[-113.97572, 44.86821],
					[-113.97571, 44.86818],
					[-113.97571, 44.86813],
					[-113.9757, 44.8681],
					[-113.9757, 44.86805],
					[-113.97569, 44.86804],
					[-113.97569, 44.86803],
					[-113.97568, 44.86799],
					[-113.97568, 44.86795],
					[-113.97567, 44.86794],
					[-113.97567, 44.86792],
					[-113.97566, 44.86789],
					[-113.97565, 44.86782],
					[-113.97562, 44.8677],
					[-113.97561, 44.86764],
					[-113.97556, 44.86745],
					[-113.97556, 44.86743],
					[-113.97555, 44.86739],
					[-113.97553, 44.86734],
					[-113.97552, 44.86729],
					[-113.97551, 44.86726],
					[-113.97551, 44.86724],
					[-113.97549, 44.86717],
					[-113.97548, 44.86711],
					[-113.97547, 44.86708],
					[-113.97547, 44.86705],
					[-113.97546, 44.86702],
					[-113.97546, 44.86697],
					[-113.97545, 44.86693],
					[-113.97545, 44.86686],
					[-113.97544, 44.86683],
					[-113.97544, 44.86657],
					[-113.97545, 44.86652],
					[-113.97545, 44.86649],
					[-113.97546, 44.86644],
					[-113.97546, 44.86642],
					[-113.97547, 44.86639],
					[-113.97547, 44.86635],
					[-113.97549, 44.86629],
					[-113.97551, 44.86619],
					[-113.97555, 44.86608],
					[-113.97556, 44.86603],
					[-113.97558, 44.86598],
					[-113.97566, 44.86582],
					[-113.97569, 44.86578],
					[-113.97572, 44.86571],
					[-113.97582, 44.86556],
					[-113.97594, 44.86541],
					[-113.97607, 44.86528],
					[-113.97615, 44.86519],
					[-113.97621, 44.86514],
					[-113.9763, 44.86504],
					[-113.97644, 44.8649],
					[-113.97649, 44.86486],
					[-113.97658, 44.86477],
					[-113.97661, 44.86473],
					[-113.97668, 44.86467],
					[-113.97691, 44.86444],
					[-113.97697, 44.86439],
					[-113.97738, 44.86398],
					[-113.97749, 44.86388],
					[-113.9779, 44.86347],
					[-113.97796, 44.86342],
					[-113.97846, 44.86291],
					[-113.97851, 44.86285],
					[-113.97862, 44.86274],
					[-113.97867, 44.86268],
					[-113.9787, 44.86265],
					[-113.97872, 44.86262],
					[-113.97878, 44.86256],
					[-113.9788, 44.86253],
					[-113.97884, 44.86249],
					[-113.97887, 44.86245],
					[-113.97891, 44.86241],
					[-113.97899, 44.8623],
					[-113.97901, 44.86226],
					[-113.97904, 44.86223],
					[-113.97907, 44.86219],
					[-113.97909, 44.86217],
					[-113.9791, 44.86214],
					[-113.97913, 44.86211],
					[-113.97915, 44.86207],
					[-113.97921, 44.86199],
					[-113.97924, 44.86193],
					[-113.97926, 44.8619],
					[-113.97928, 44.86188],
					[-113.97932, 44.86182],
					[-113.97938, 44.8617],
					[-113.97941, 44.86166],
					[-113.97946, 44.86156],
					[-113.97948, 44.86151],
					[-113.97952, 44.86145],
					[-113.97954, 44.86139],
					[-113.97961, 44.86124],
					[-113.97969, 44.86104],
					[-113.97974, 44.86088],
					[-113.97977, 44.86081],
					[-113.97983, 44.86062],
					[-113.97985, 44.86053],
					[-113.97989, 44.8604],
					[-113.97992, 44.86023],
					[-113.97994, 44.86017],
					[-113.98, 44.85968],
					[-113.98, 44.85958],
					[-113.98001, 44.85946],
					[-113.98, 44.85935],
					[-113.98, 44.85923],
					[-113.97999, 44.85913],
					[-113.97999, 44.85901],
					[-113.97997, 44.85887],
					[-113.97996, 44.85875],
					[-113.97994, 44.85862],
					[-113.97988, 44.85837],
					[-113.97986, 44.85825],
					[-113.97975, 44.85789],
					[-113.97969, 44.85773],
					[-113.97964, 44.85762],
					[-113.9796, 44.85751],
					[-113.97951, 44.85731],
					[-113.97945, 44.8572],
					[-113.9794, 44.85709],
					[-113.97886, 44.85605],
					[-113.97878, 44.85591],
					[-113.97868, 44.85569],
					[-113.97851, 44.85538],
					[-113.97846, 44.85527],
					[-113.97828, 44.85493],
					[-113.97827, 44.85492],
					[-113.97795, 44.8543],
					[-113.97794, 44.85427],
					[-113.97788, 44.85416],
					[-113.97776, 44.85387],
					[-113.97767, 44.85361],
					[-113.97761, 44.8534],
					[-113.97759, 44.85331],
					[-113.97755, 44.8532],
					[-113.97753, 44.85311],
					[-113.97752, 44.85303],
					[-113.9775, 44.85297],
					[-113.97748, 44.85288],
					[-113.97743, 44.85253],
					[-113.97742, 44.8524],
					[-113.97742, 44.85205],
					[-113.97744, 44.85178],
					[-113.97752, 44.85125],
					[-113.97756, 44.85108],
					[-113.97758, 44.85096],
					[-113.97776, 44.85032],
					[-113.9781, 44.84929],
					[-113.97815, 44.84916],
					[-113.97823, 44.84889],
					[-113.97828, 44.84876],
					[-113.97832, 44.84862],
					[-113.97878, 44.84723],
					[-113.97886, 44.84702],
					[-113.97894, 44.84676],
					[-113.97897, 44.84669],
					[-113.97913, 44.84621],
					[-113.97915, 44.84613],
					[-113.97917, 44.84607],
					[-113.97919, 44.84599],
					[-113.97921, 44.84594],
					[-113.9793, 44.84556],
					[-113.97934, 44.84542],
					[-113.97938, 44.84522],
					[-113.97938, 44.84518],
					[-113.97939, 44.84514],
					[-113.97939, 44.8451],
					[-113.9794, 44.84506],
					[-113.9794, 44.84502],
					[-113.97943, 44.84484],
					[-113.97943, 44.84479],
					[-113.97944, 44.84474],
					[-113.97944, 44.84469],
					[-113.97945, 44.84463],
					[-113.97945, 44.84456],
					[-113.97948, 44.84425],
					[-113.97971, 44.84067],
					[-113.97971, 44.84021],
					[-113.9797, 44.84013],
					[-113.9797, 44.84005],
					[-113.97965, 44.83972],
					[-113.97963, 44.83965],
					[-113.97958, 44.8394],
					[-113.97954, 44.83928],
					[-113.97953, 44.83922],
					[-113.97951, 44.83918],
					[-113.97949, 44.8391],
					[-113.97946, 44.83902],
					[-113.97934, 44.83861],
					[-113.97876, 44.83682],
					[-113.97863, 44.83634],
					[-113.97859, 44.83616],
					[-113.97856, 44.83598],
					[-113.97852, 44.8358],
					[-113.97846, 44.83534],
					[-113.97846, 44.83523],
					[-113.97844, 44.83493],
					[-113.97845, 44.83419],
					[-113.97849, 44.83381],
					[-113.9785, 44.83376],
					[-113.9785, 44.83372],
					[-113.97852, 44.83359],
					[-113.97854, 44.83352],
					[-113.97855, 44.83346],
					[-113.97859, 44.83333],
					[-113.9786, 44.83327],
					[-113.97862, 44.83321],
					[-113.97865, 44.83314],
					[-113.97867, 44.83306],
					[-113.9787, 44.833],
					[-113.97876, 44.83285],
					[-113.97884, 44.83271],
					[-113.97891, 44.83256],
					[-113.97899, 44.83245],
					[-113.97905, 44.83235],
					[-113.97908, 44.83232],
					[-113.97921, 44.83214],
					[-113.97933, 44.83199],
					[-113.97949, 44.83181],
					[-113.97975, 44.83155],
					[-113.97982, 44.8315],
					[-113.97987, 44.83145],
					[-113.98002, 44.83134],
					[-113.98008, 44.83129],
					[-113.98015, 44.83124],
					[-113.98025, 44.83118],
					[-113.98028, 44.83115],
					[-113.98032, 44.83113],
					[-113.9804, 44.83107],
					[-113.98061, 44.83095],
					[-113.98065, 44.83092],
					[-113.9807, 44.8309],
					[-113.98074, 44.83087],
					[-113.98084, 44.83082],
					[-113.98087, 44.8308],
					[-113.98091, 44.83078],
					[-113.98094, 44.83077],
					[-113.98097, 44.83075],
					[-113.98105, 44.83071],
					[-113.9811, 44.83068],
					[-113.98127, 44.8306],
					[-113.98136, 44.83055],
					[-113.98144, 44.83052],
					[-113.98154, 44.83046],
					[-113.9818, 44.83034],
					[-113.98209, 44.83019],
					[-113.98227, 44.83011],
					[-113.98234, 44.83007],
					[-113.98248, 44.83001],
					[-113.98249, 44.83],
					[-113.9827, 44.82989],
					[-113.98345, 44.82953],
					[-113.98359, 44.82945],
					[-113.98574, 44.82838],
					[-113.9863, 44.82812],
					[-113.98637, 44.82808],
					[-113.98678, 44.82788],
					[-113.98679, 44.82787],
					[-113.9869, 44.82781],
					[-113.98701, 44.82776],
					[-113.98722, 44.82764],
					[-113.98731, 44.8276],
					[-113.98735, 44.82757],
					[-113.98743, 44.82753],
					[-113.98747, 44.8275],
					[-113.98751, 44.82748],
					[-113.98761, 44.82741],
					[-113.98765, 44.82739],
					[-113.98777, 44.8273],
					[-113.98782, 44.82727],
					[-113.98792, 44.82719],
					[-113.98796, 44.82715],
					[-113.9881, 44.82703],
					[-113.98817, 44.82695],
					[-113.98824, 44.82688],
					[-113.98829, 44.82681],
					[-113.98834, 44.82676],
					[-113.98839, 44.82669],
					[-113.98846, 44.82657],
					[-113.98847, 44.82656],
					[-113.98856, 44.82639],
					[-113.98863, 44.82622],
					[-113.98864, 44.82621],
					[-113.98868, 44.82611],
					[-113.98873, 44.82596],
					[-113.98875, 44.82587],
					[-113.98876, 44.82586],
					[-113.98881, 44.82566],
					[-113.98902, 44.82467],
					[-113.98926, 44.82368],
					[-113.98947, 44.82296],
					[-113.98956, 44.82271],
					[-113.98956, 44.82269],
					[-113.98961, 44.82259],
					[-113.98967, 44.82242],
					[-113.98971, 44.82234],
					[-113.9898, 44.82213],
					[-113.99006, 44.82164],
					[-113.99048, 44.82099],
					[-113.99069, 44.82071],
					[-113.9911, 44.82023],
					[-113.99146, 44.81986],
					[-113.99189, 44.81945],
					[-113.99221, 44.81917],
					[-113.99276, 44.81874],
					[-113.99406, 44.81778],
					[-113.99431, 44.81758],
					[-113.99444, 44.81749],
					[-113.99446, 44.81747],
					[-113.99459, 44.81738],
					[-113.99618, 44.81616],
					[-113.99632, 44.81607],
					[-113.99792, 44.81486],
					[-113.99794, 44.81484],
					[-113.9993, 44.81381],
					[-113.99938, 44.81374],
					[-113.99979, 44.81344],
					[-113.9999, 44.81337],
					[-114.00001, 44.81329],
					[-114.00023, 44.81316],
					[-114.00065, 44.81294],
					[-114.00104, 44.81277],
					[-114.00126, 44.81269],
					[-114.00188, 44.8125],
					[-114.00445, 44.81189],
					[-114.00448, 44.81188],
					[-114.00674, 44.81136],
					[-114.00696, 44.8113],
					[-114.0074, 44.8112],
					[-114.00782, 44.81107],
					[-114.00828, 44.81091],
					[-114.00845, 44.81083],
					[-114.00862, 44.81074],
					[-114.00867, 44.8107],
					[-114.00873, 44.81067],
					[-114.00879, 44.81062],
					[-114.00893, 44.81052],
					[-114.00911, 44.81037],
					[-114.00918, 44.8103],
					[-114.00927, 44.81019],
					[-114.00931, 44.81015],
					[-114.00942, 44.80995],
					[-114.00952, 44.80974],
					[-114.01007, 44.8083],
					[-114.01042, 44.80745],
					[-114.01055, 44.80718],
					[-114.01073, 44.80672],
					[-114.01078, 44.80661],
					[-114.01084, 44.80645],
					[-114.01085, 44.80643],
					[-114.01087, 44.80636],
					[-114.0109, 44.80629],
					[-114.01092, 44.80621],
					[-114.01095, 44.80613],
					[-114.01096, 44.80608],
					[-114.01098, 44.80602],
					[-114.011, 44.80588],
					[-114.01102, 44.80581],
					[-114.01102, 44.80573],
					[-114.01103, 44.80567],
					[-114.01103, 44.80543],
					[-114.01102, 44.80537],
					[-114.01102, 44.80534],
					[-114.011, 44.80526],
					[-114.01099, 44.80519],
					[-114.01096, 44.80508],
					[-114.0109, 44.80492],
					[-114.01081, 44.80474],
					[-114.01076, 44.80467],
					[-114.01073, 44.80461],
					[-114.01044, 44.80427],
					[-114.01025, 44.8041],
					[-114.00784, 44.8022],
					[-114.00783, 44.8022],
					[-114.00557, 44.80042],
					[-114.0055, 44.80035],
					[-114.00537, 44.80024],
					[-114.00516, 44.80001],
					[-114.00497, 44.79976],
					[-114.00478, 44.79947],
					[-114.00392, 44.79806],
					[-114.00388, 44.79798],
					[-114.00385, 44.79794],
					[-114.00369, 44.79768],
					[-114.00361, 44.79753],
					[-114.00353, 44.7974],
					[-114.00308, 44.79679],
					[-114.00298, 44.79667],
					[-114.00286, 44.79654],
					[-114.00277, 44.79643],
					[-114.002, 44.79562],
					[-114.00128, 44.79496],
					[-114.00124, 44.79493],
					[-114.00118, 44.79487],
					[-114.00103, 44.79474],
					[-114.001, 44.79472],
					[-114.00096, 44.79468],
					[-114.00083, 44.79458],
					[-114.00068, 44.79448],
					[-114.00061, 44.79442],
					[-114.00051, 44.79436],
					[-114.00046, 44.79431],
					[-114.00036, 44.79423],
					[-114.00023, 44.7941],
					[-114.0002, 44.79406],
					[-114.00017, 44.79403],
					[-114.00015, 44.79399],
					[-114.00007, 44.79386],
					[-114.00001, 44.79374],
					[-114, 44.79371],
					[-113.99994, 44.79358],
					[-113.99991, 44.79347],
					[-113.9999, 44.79346],
					[-113.99987, 44.79334],
					[-113.99985, 44.7933],
					[-113.9997, 44.79271],
					[-113.99968, 44.79259],
					[-113.99966, 44.79252],
					[-113.99945, 44.79159],
					[-113.99941, 44.79146],
					[-113.99927, 44.79092],
					[-113.99919, 44.79067],
					[-113.99904, 44.79011],
					[-113.99903, 44.7901],
					[-113.99895, 44.78984],
					[-113.99884, 44.78943],
					[-113.99862, 44.7887],
					[-113.99861, 44.78865],
					[-113.99768, 44.78546],
					[-113.99767, 44.7854],
					[-113.99766, 44.78537],
					[-113.99765, 44.78535],
					[-113.99759, 44.78496],
					[-113.99757, 44.78473],
					[-113.99755, 44.78461],
					[-113.99755, 44.78453],
					[-113.99754, 44.78448],
					[-113.99754, 44.78422],
					[-113.99753, 44.784],
					[-113.99754, 44.78386],
					[-113.99756, 44.78373],
					[-113.99758, 44.78367],
					[-113.99759, 44.78362],
					[-113.99761, 44.78356],
					[-113.99771, 44.78337],
					[-113.99778, 44.78327],
					[-113.99803, 44.78297],
					[-113.99804, 44.78294],
					[-113.99806, 44.78291],
					[-113.99808, 44.78287],
					[-113.9981, 44.78285],
					[-113.99811, 44.78281],
					[-113.99812, 44.78279],
					[-113.99818, 44.7826],
					[-113.99824, 44.78232],
					[-113.99826, 44.78218],
					[-113.99829, 44.78203],
					[-113.99832, 44.78177],
					[-113.99834, 44.78168],
					[-113.99837, 44.78143],
					[-113.99837, 44.78136],
					[-113.99838, 44.78127],
					[-113.99838, 44.78121],
					[-113.99836, 44.78103],
					[-113.99832, 44.78081],
					[-113.99827, 44.78059],
					[-113.99822, 44.78043],
					[-113.9982, 44.78038],
					[-113.99819, 44.78033],
					[-113.99813, 44.78016],
					[-113.99809, 44.78008],
					[-113.99807, 44.78002],
					[-113.99799, 44.77988],
					[-113.99799, 44.77986],
					[-113.99798, 44.77985],
					[-113.99789, 44.77972],
					[-113.99777, 44.7796],
					[-113.99765, 44.7795],
					[-113.99757, 44.77945],
					[-113.99752, 44.77941],
					[-113.9975, 44.77939],
					[-113.99738, 44.77933],
					[-113.99727, 44.77926],
					[-113.99716, 44.7792],
					[-113.99715, 44.7792],
					[-113.99671, 44.77899],
					[-113.99662, 44.77896],
					[-113.99653, 44.77892],
					[-113.99595, 44.77873],
					[-113.99533, 44.77859],
					[-113.99406, 44.77834],
					[-113.994, 44.77832],
					[-113.99395, 44.77831],
					[-113.9939, 44.77829],
					[-113.99378, 44.77826],
					[-113.99341, 44.77812],
					[-113.9932, 44.77803],
					[-113.99319, 44.77802],
					[-113.99289, 44.77788],
					[-113.99202, 44.7774],
					[-113.99188, 44.77731],
					[-113.99179, 44.77726],
					[-113.99178, 44.77725],
					[-113.9917, 44.77721],
					[-113.99165, 44.77717],
					[-113.99159, 44.77713],
					[-113.99153, 44.77708],
					[-113.99148, 44.77705],
					[-113.99117, 44.77676],
					[-113.99112, 44.77673],
					[-113.99104, 44.77667],
					[-113.99099, 44.77664],
					[-113.99095, 44.77661],
					[-113.9909, 44.77658],
					[-113.99048, 44.77641],
					[-113.99015, 44.77631],
					[-113.98995, 44.77624],
					[-113.98977, 44.77616],
					[-113.98973, 44.77613],
					[-113.98969, 44.77611],
					[-113.98967, 44.77609],
					[-113.98965, 44.77608],
					[-113.98964, 44.77606],
					[-113.98959, 44.77601],
					[-113.98956, 44.77597],
					[-113.98955, 44.77595],
					[-113.98953, 44.77592],
					[-113.98953, 44.77591],
					[-113.98952, 44.77589],
					[-113.98951, 44.77588],
					[-113.98951, 44.77586],
					[-113.9895, 44.77585],
					[-113.9895, 44.7758],
					[-113.98949, 44.77577],
					[-113.98949, 44.77563],
					[-113.9895, 44.77559],
					[-113.9895, 44.77554],
					[-113.98952, 44.77547],
					[-113.98953, 44.77541],
					[-113.98957, 44.77529],
					[-113.98958, 44.77524],
					[-113.9896, 44.77519],
					[-113.98971, 44.77499],
					[-113.98977, 44.7749],
					[-113.98981, 44.77486],
					[-113.98983, 44.77483],
					[-113.9899, 44.77476],
					[-113.98994, 44.77473],
					[-113.98996, 44.77471],
					[-113.99012, 44.77459],
					[-113.99016, 44.77457],
					[-113.99019, 44.77455],
					[-113.99023, 44.77453],
					[-113.99025, 44.77451],
					[-113.99028, 44.7745],
					[-113.99032, 44.77448],
					[-113.99033, 44.77448],
					[-113.99043, 44.77443],
					[-113.99062, 44.77435],
					[-113.99076, 44.7743],
					[-113.9909, 44.77424],
					[-113.99097, 44.77422],
					[-113.99104, 44.77419],
					[-113.99113, 44.77416],
					[-113.99119, 44.77413],
					[-113.99127, 44.7741],
					[-113.99137, 44.77407],
					[-113.99153, 44.77401],
					[-113.99162, 44.77397],
					[-113.9917, 44.77395],
					[-113.99186, 44.77389],
					[-113.99189, 44.77389],
					[-113.99206, 44.77384],
					[-113.99216, 44.77382],
					[-113.99227, 44.77379],
					[-113.99335, 44.7736],
					[-113.99347, 44.77357],
					[-113.99374, 44.77352],
					[-113.99379, 44.7735],
					[-113.99402, 44.77344],
					[-113.99408, 44.77341],
					[-113.9942, 44.77337],
					[-113.99426, 44.77334],
					[-113.99441, 44.77328],
					[-113.99519, 44.77285],
					[-113.99551, 44.77263],
					[-113.99575, 44.77243],
					[-113.99604, 44.77214],
					[-113.99611, 44.77205],
					[-113.99616, 44.772],
					[-113.99633, 44.77179],
					[-113.99633, 44.77178],
					[-113.99638, 44.77172],
					[-113.99643, 44.77165],
					[-113.99647, 44.77158],
					[-113.99652, 44.77151],
					[-113.99655, 44.77146],
					[-113.99658, 44.77139],
					[-113.99678, 44.77099],
					[-113.99694, 44.77064],
					[-113.99707, 44.77031],
					[-113.99711, 44.77018],
					[-113.99712, 44.77016],
					[-113.99714, 44.77004],
					[-113.99715, 44.77001],
					[-113.99715, 44.76993],
					[-113.99716, 44.76986],
					[-113.99716, 44.76972],
					[-113.99712, 44.76948],
					[-113.99708, 44.76932],
					[-113.99707, 44.7693],
					[-113.99703, 44.76919],
					[-113.99689, 44.76873],
					[-113.99686, 44.76866],
					[-113.99684, 44.76857],
					[-113.99663, 44.76808],
					[-113.99662, 44.76807],
					[-113.99642, 44.76769],
					[-113.99626, 44.76743],
					[-113.99623, 44.76737],
					[-113.99607, 44.76713],
					[-113.99599, 44.76703],
					[-113.99569, 44.76661],
					[-113.99525, 44.76603],
					[-113.9951, 44.76581],
					[-113.99509, 44.76578],
					[-113.99492, 44.76551],
					[-113.99483, 44.76532],
					[-113.99478, 44.76523],
					[-113.99474, 44.76513],
					[-113.99466, 44.76488],
					[-113.99459, 44.76459],
					[-113.99452, 44.76417],
					[-113.99449, 44.76388],
					[-113.99447, 44.76375],
					[-113.99446, 44.76364],
					[-113.99444, 44.76356],
					[-113.9944, 44.76334],
					[-113.9944, 44.76333],
					[-113.99438, 44.76324],
					[-113.99436, 44.76318],
					[-113.99435, 44.76311],
					[-113.9943, 44.76297],
					[-113.9943, 44.76295],
					[-113.99429, 44.76293],
					[-113.99423, 44.76275],
					[-113.9942, 44.76268],
					[-113.99417, 44.76259],
					[-113.9941, 44.76244],
					[-113.99391, 44.76208],
					[-113.99386, 44.762],
					[-113.99386, 44.76199],
					[-113.99383, 44.76193],
					[-113.99381, 44.7619],
					[-113.9938, 44.76186],
					[-113.99378, 44.76182],
					[-113.99377, 44.76178],
					[-113.99375, 44.76173],
					[-113.99372, 44.76158],
					[-113.99372, 44.76154],
					[-113.99371, 44.76151],
					[-113.99371, 44.76132],
					[-113.99372, 44.76129],
					[-113.99372, 44.76126],
					[-113.99375, 44.76113],
					[-113.9938, 44.76101],
					[-113.99385, 44.7609],
					[-113.99397, 44.76069],
					[-113.99408, 44.76052],
					[-113.99417, 44.76043],
					[-113.99426, 44.76032],
					[-113.99431, 44.76028],
					[-113.99437, 44.76022],
					[-113.99447, 44.76014],
					[-113.99496, 44.7598],
					[-113.9953, 44.75959],
					[-113.9958, 44.75926],
					[-113.99601, 44.75911],
					[-113.99606, 44.75908],
					[-113.99611, 44.75904],
					[-113.99617, 44.75898],
					[-113.99622, 44.75895],
					[-113.99626, 44.75892],
					[-113.99631, 44.75887],
					[-113.99644, 44.75876],
					[-113.9965, 44.75869],
					[-113.99664, 44.75855],
					[-113.99757, 44.75752],
					[-113.99767, 44.75739],
					[-113.9977, 44.75734],
					[-113.99777, 44.75725],
					[-113.9978, 44.75718],
					[-113.99783, 44.75714],
					[-113.99786, 44.75709],
					[-113.99787, 44.75705],
					[-113.9979, 44.75699],
					[-113.99798, 44.75676],
					[-113.99799, 44.75669],
					[-113.99801, 44.75661],
					[-113.99803, 44.75643],
					[-113.99803, 44.75619],
					[-113.99802, 44.75611],
					[-113.99802, 44.75603],
					[-113.998, 44.75583],
					[-113.99796, 44.7556],
					[-113.99795, 44.7555],
					[-113.99793, 44.75544],
					[-113.99791, 44.75536],
					[-113.9979, 44.7553],
					[-113.99788, 44.75526],
					[-113.99787, 44.75522],
					[-113.99783, 44.75512],
					[-113.99778, 44.75503],
					[-113.99777, 44.755],
					[-113.99775, 44.75496],
					[-113.99774, 44.75495],
					[-113.99763, 44.7548],
					[-113.99748, 44.75465],
					[-113.99743, 44.75459],
					[-113.99736, 44.75454],
					[-113.99719, 44.75439],
					[-113.99641, 44.7538],
					[-113.99636, 44.75378],
					[-113.9962, 44.75368],
					[-113.99598, 44.75357],
					[-113.99584, 44.75351],
					[-113.99578, 44.75349],
					[-113.9957, 44.75347],
					[-113.99562, 44.75344],
					[-113.9955, 44.75341],
					[-113.99538, 44.75339],
					[-113.9953, 44.75337],
					[-113.99489, 44.75333],
					[-113.99429, 44.75334],
					[-113.99421, 44.75335],
					[-113.99402, 44.75335],
					[-113.99362, 44.75338],
					[-113.99311, 44.75339],
					[-113.99296, 44.7534],
					[-113.99289, 44.75341],
					[-113.99282, 44.75341],
					[-113.99278, 44.75342],
					[-113.99274, 44.75342],
					[-113.99267, 44.75343],
					[-113.99221, 44.75353],
					[-113.99213, 44.75354],
					[-113.99201, 44.75357],
					[-113.99197, 44.75357],
					[-113.99193, 44.75358],
					[-113.99176, 44.75358],
					[-113.99173, 44.75357],
					[-113.9917, 44.75357],
					[-113.99167, 44.75356],
					[-113.99158, 44.75354],
					[-113.99152, 44.75352],
					[-113.99149, 44.7535],
					[-113.99143, 44.75348],
					[-113.99137, 44.75344],
					[-113.99133, 44.75342],
					[-113.9913, 44.7534],
					[-113.99128, 44.75338],
					[-113.99125, 44.75337],
					[-113.99116, 44.75328],
					[-113.99112, 44.75325],
					[-113.9909, 44.75303],
					[-113.99086, 44.75298],
					[-113.99082, 44.75294],
					[-113.99078, 44.75288],
					[-113.99076, 44.75286],
					[-113.99057, 44.7526],
					[-113.99056, 44.75257],
					[-113.99053, 44.75253],
					[-113.99045, 44.75238],
					[-113.99042, 44.75229],
					[-113.99039, 44.75223],
					[-113.99038, 44.7522],
					[-113.99037, 44.75215],
					[-113.99036, 44.75212],
					[-113.99035, 44.7521],
					[-113.99032, 44.75201],
					[-113.99032, 44.75197],
					[-113.99029, 44.75183],
					[-113.99028, 44.75175],
					[-113.99028, 44.75147],
					[-113.99029, 44.75129],
					[-113.99038, 44.75069],
					[-113.99043, 44.75045],
					[-113.99047, 44.75034],
					[-113.99049, 44.75026],
					[-113.99054, 44.75016],
					[-113.99058, 44.75006],
					[-113.99064, 44.74994],
					[-113.99095, 44.74945],
					[-113.9911, 44.74923],
					[-113.99116, 44.74917],
					[-113.99118, 44.74914],
					[-113.99126, 44.74904],
					[-113.99137, 44.74893],
					[-113.99158, 44.74875],
					[-113.99176, 44.74862],
					[-113.99183, 44.74856],
					[-113.99211, 44.74839],
					[-113.9922, 44.74835],
					[-113.99228, 44.7483],
					[-113.99264, 44.74813],
					[-113.99467, 44.74728],
					[-113.99477, 44.74725],
					[-113.99487, 44.74721],
					[-113.99497, 44.74718],
					[-113.99522, 44.74712],
					[-113.99546, 44.74709],
					[-113.99575, 44.74707],
					[-113.99731, 44.74703],
					[-113.99755, 44.74701],
					[-113.9978, 44.74696],
					[-113.99781, 44.74696],
					[-113.99792, 44.74694],
					[-113.99939, 44.74642],
					[-113.99941, 44.74641],
					[-114.00054, 44.74599],
					[-114.00062, 44.74595],
					[-114.0007, 44.74592],
					[-114.00091, 44.74581],
					[-114.00132, 44.74554],
					[-114.00203, 44.74491],
					[-114.00312, 44.74387],
					[-114.00334, 44.74361],
					[-114.00358, 44.74328],
					[-114.00385, 44.74282],
					[-114.0039, 44.7427],
					[-114.00393, 44.74265],
					[-114.00398, 44.74253],
					[-114.00412, 44.74224],
					[-114.00438, 44.74165],
					[-114.0046, 44.74123],
					[-114.00472, 44.74098],
					[-114.00475, 44.74089],
					[-114.00478, 44.74082],
					[-114.00483, 44.74058],
					[-114.00483, 44.74055],
					[-114.00484, 44.74049],
					[-114.00484, 44.74026],
					[-114.0048, 44.73999],
					[-114.00472, 44.73968],
					[-114.00468, 44.73958],
					[-114.00461, 44.73937],
					[-114.00436, 44.73852],
					[-114.00421, 44.73808],
					[-114.00393, 44.73752],
					[-114.00373, 44.73719],
					[-114.0028, 44.73588],
					[-114.00278, 44.73584],
					[-114.00274, 44.73578],
					[-114.00268, 44.73566],
					[-114.00265, 44.73561],
					[-114.00259, 44.73545],
					[-114.00258, 44.73541],
					[-114.00258, 44.73539],
					[-114.00257, 44.73536],
					[-114.00257, 44.73535],
					[-114.00255, 44.73524],
					[-114.00255, 44.73518],
					[-114.00254, 44.73514],
					[-114.00254, 44.7351],
					[-114.00255, 44.73501],
					[-114.00255, 44.73494],
					[-114.00256, 44.73485],
					[-114.00263, 44.73449],
					[-114.00264, 44.73446],
					[-114.00267, 44.73432],
					[-114.00273, 44.73411],
					[-114.003, 44.73301],
					[-114.003, 44.733],
					[-114.00302, 44.7329],
					[-114.00303, 44.73287],
					[-114.00314, 44.73237],
					[-114.00314, 44.73233],
					[-114.00341, 44.73133],
					[-114.00341, 44.73131],
					[-114.0035, 44.73079],
					[-114.0035, 44.73074],
					[-114.00352, 44.73058],
					[-114.00355, 44.72981],
					[-114.00358, 44.7294],
					[-114.00359, 44.7291],
					[-114.00362, 44.72876],
					[-114.00362, 44.72871],
					[-114.00365, 44.72845],
					[-114.00369, 44.72826],
					[-114.00373, 44.72815],
					[-114.00388, 44.72786],
					[-114.00394, 44.72776],
					[-114.004, 44.72768],
					[-114.00403, 44.72765],
					[-114.00406, 44.72761],
					[-114.00411, 44.72756],
					[-114.00425, 44.72736],
					[-114.00425, 44.72735],
					[-114.00428, 44.72727],
					[-114.00429, 44.72721],
					[-114.00429, 44.72715],
					[-114.00434, 44.72682],
					[-114.00434, 44.72676],
					[-114.00439, 44.72644],
					[-114.00439, 44.72636],
					[-114.0044, 44.72629],
					[-114.00444, 44.72612],
					[-114.00447, 44.72604],
					[-114.0045, 44.72598],
					[-114.00455, 44.72586],
					[-114.00458, 44.72577],
					[-114.00477, 44.72534],
					[-114.00484, 44.7252],
					[-114.00484, 44.72519],
					[-114.0049, 44.72504],
					[-114.005, 44.72474],
					[-114.00501, 44.72472],
					[-114.00502, 44.72465],
					[-114.00504, 44.7246],
					[-114.00504, 44.72454],
					[-114.00505, 44.72446],
					[-114.00505, 44.72436],
					[-114.00503, 44.72419],
					[-114.00497, 44.72385],
					[-114.00496, 44.72382],
					[-114.00496, 44.72368],
					[-114.00497, 44.72363],
					[-114.00497, 44.72357],
					[-114.00499, 44.72347],
					[-114.00499, 44.72346],
					[-114.005, 44.72343],
					[-114.00502, 44.72339],
					[-114.00504, 44.72333],
					[-114.00512, 44.72314],
					[-114.00514, 44.72312],
					[-114.00517, 44.72307],
					[-114.00521, 44.72298],
					[-114.00536, 44.72276],
					[-114.00539, 44.72273],
					[-114.00543, 44.72268],
					[-114.00547, 44.72264],
					[-114.00572, 44.72244],
					[-114.00595, 44.72231],
					[-114.00618, 44.7222],
					[-114.00647, 44.72211],
					[-114.00665, 44.72208],
					[-114.00669, 44.72208],
					[-114.00696, 44.72205],
					[-114.00705, 44.72205],
					[-114.00717, 44.72203],
					[-114.00764, 44.72198],
					[-114.00773, 44.72196],
					[-114.0078, 44.72195],
					[-114.0088, 44.72171],
					[-114.009, 44.72165],
					[-114.0092, 44.7216],
					[-114.0096, 44.72146],
					[-114.0102, 44.72123],
					[-114.01048, 44.72115],
					[-114.01062, 44.7211],
					[-114.01087, 44.72099],
					[-114.01132, 44.72076],
					[-114.0118, 44.72055],
					[-114.012, 44.72044],
					[-114.01211, 44.72037],
					[-114.01222, 44.72031],
					[-114.01243, 44.72018],
					[-114.01262, 44.72003],
					[-114.01277, 44.71993],
					[-114.01296, 44.71982],
					[-114.01322, 44.71969],
					[-114.01339, 44.71963],
					[-114.01346, 44.7196],
					[-114.01389, 44.71946],
					[-114.01456, 44.71928],
					[-114.01495, 44.71919],
					[-114.0156, 44.71909],
					[-114.01646, 44.71899],
					[-114.01661, 44.71898],
					[-114.01677, 44.71896],
					[-114.01721, 44.71896],
					[-114.01802, 44.71899],
					[-114.0184, 44.71899],
					[-114.01957, 44.71889],
					[-114.01976, 44.71885],
					[-114.02023, 44.71869],
					[-114.0204, 44.71862],
					[-114.02063, 44.71849],
					[-114.02074, 44.71842],
					[-114.02093, 44.71825],
					[-114.02094, 44.71822],
					[-114.02097, 44.71818],
					[-114.02116, 44.71782],
					[-114.02122, 44.71773],
					[-114.02124, 44.71772],
					[-114.02131, 44.71763],
					[-114.02138, 44.71756],
					[-114.02148, 44.71749],
					[-114.02148, 44.71748],
					[-114.02158, 44.71741],
					[-114.02165, 44.71735],
					[-114.02172, 44.7173],
					[-114.02179, 44.71723],
					[-114.02184, 44.71719],
					[-114.02189, 44.71714],
					[-114.02193, 44.71711],
					[-114.0221, 44.71689],
					[-114.02215, 44.71681],
					[-114.02227, 44.71658],
					[-114.02236, 44.71638],
					[-114.02243, 44.71617],
					[-114.02244, 44.71612],
					[-114.02245, 44.71609],
					[-114.02245, 44.71606],
					[-114.02247, 44.71586],
					[-114.02247, 44.71568],
					[-114.02246, 44.71559],
					[-114.02242, 44.7154],
					[-114.02233, 44.71513],
					[-114.02224, 44.71496],
					[-114.022, 44.71465],
					[-114.02135, 44.71401],
					[-114.02133, 44.71398],
					[-114.0213, 44.71395],
					[-114.02124, 44.71385],
					[-114.02117, 44.71369],
					[-114.02116, 44.71365],
					[-114.02114, 44.71361],
					[-114.02112, 44.71347],
					[-114.02112, 44.71333],
					[-114.02115, 44.71318],
					[-114.02118, 44.71311],
					[-114.02119, 44.71307],
					[-114.02121, 44.71303],
					[-114.02125, 44.71297],
					[-114.02127, 44.71295],
					[-114.02129, 44.71292],
					[-114.02134, 44.71288],
					[-114.02143, 44.71279],
					[-114.0215, 44.71275],
					[-114.02156, 44.7127],
					[-114.02163, 44.71266],
					[-114.02168, 44.71262],
					[-114.02202, 44.71245],
					[-114.02204, 44.71243],
					[-114.02207, 44.71242],
					[-114.02209, 44.7124],
					[-114.02211, 44.71239],
					[-114.02212, 44.71239],
					[-114.02216, 44.71235],
					[-114.02219, 44.71233],
					[-114.02222, 44.71229],
					[-114.02226, 44.71225],
					[-114.02239, 44.71208],
					[-114.02255, 44.71194],
					[-114.02601, 44.70917],
					[-114.02608, 44.70912],
					[-114.02626, 44.70901],
					[-114.02645, 44.70892],
					[-114.02648, 44.7089],
					[-114.02669, 44.70882],
					[-114.02696, 44.70873],
					[-114.02728, 44.70864],
					[-114.02836, 44.70838],
					[-114.0364, 44.70626],
					[-114.03658, 44.70622],
					[-114.0371, 44.70606],
					[-114.03735, 44.70596],
					[-114.03736, 44.70596],
					[-114.03747, 44.70592],
					[-114.03756, 44.70587],
					[-114.03765, 44.70583],
					[-114.03778, 44.70576],
					[-114.03815, 44.70553],
					[-114.03826, 44.70545],
					[-114.04024, 44.70385],
					[-114.04097, 44.70318],
					[-114.04154, 44.70269],
					[-114.04161, 44.70264],
					[-114.04168, 44.70258],
					[-114.04193, 44.7024],
					[-114.04203, 44.70234],
					[-114.04215, 44.70225],
					[-114.04224, 44.7022],
					[-114.04234, 44.70212],
					[-114.04262, 44.70187],
					[-114.04303, 44.70147],
					[-114.04309, 44.7014],
					[-114.04313, 44.70134],
					[-114.04315, 44.7013],
					[-114.04317, 44.70124],
					[-114.04325, 44.70083],
					[-114.04328, 44.70059],
					[-114.04328, 44.70051],
					[-114.04329, 44.70039],
					[-114.04329, 44.70031],
					[-114.0433, 44.70026],
					[-114.04329, 44.70017],
					[-114.04328, 44.69991],
					[-114.04327, 44.69982],
					[-114.04325, 44.69971],
					[-114.04325, 44.69958],
					[-114.04329, 44.69939],
					[-114.04331, 44.69934],
					[-114.04332, 44.69933],
					[-114.04338, 44.69921],
					[-114.04341, 44.69916],
					[-114.04345, 44.69912],
					[-114.0435, 44.69906],
					[-114.04362, 44.69894],
					[-114.04369, 44.69888],
					[-114.04452, 44.69828],
					[-114.04492, 44.69803],
					[-114.04503, 44.69797],
					[-114.0451, 44.69794],
					[-114.04523, 44.69787],
					[-114.0454, 44.6978],
					[-114.04643, 44.69747],
					[-114.04722, 44.69725],
					[-114.0478, 44.69706],
					[-114.04834, 44.69682],
					[-114.04862, 44.69666],
					[-114.0487, 44.6966],
					[-114.04885, 44.69647],
					[-114.04923, 44.6961],
					[-114.04957, 44.69574],
					[-114.04961, 44.69568],
					[-114.04964, 44.69565],
					[-114.04969, 44.69559],
					[-114.04971, 44.69555],
					[-114.04971, 44.69554],
					[-114.04973, 44.69551],
					[-114.04976, 44.69541],
					[-114.04976, 44.69527],
					[-114.04974, 44.69521],
					[-114.04974, 44.69519],
					[-114.04971, 44.6951],
					[-114.04967, 44.69503],
					[-114.04964, 44.69495],
					[-114.04914, 44.69398],
					[-114.04891, 44.69349],
					[-114.04867, 44.69292],
					[-114.04864, 44.69286],
					[-114.04863, 44.69283],
					[-114.04863, 44.6928],
					[-114.04861, 44.69272],
					[-114.0486, 44.69233],
					[-114.04862, 44.69197],
					[-114.04866, 44.69187],
					[-114.04868, 44.69179],
					[-114.04873, 44.69168],
					[-114.04877, 44.69162],
					[-114.04879, 44.69158],
					[-114.04881, 44.69155],
					[-114.04884, 44.69152],
					[-114.04889, 44.69146],
					[-114.04915, 44.69122],
					[-114.04929, 44.69111],
					[-114.04958, 44.69091],
					[-114.04962, 44.69089],
					[-114.04973, 44.69082],
					[-114.0499, 44.69073],
					[-114.051, 44.6903],
					[-114.0514, 44.69011],
					[-114.05157, 44.69001],
					[-114.05162, 44.68997],
					[-114.05167, 44.68994],
					[-114.05188, 44.68977],
					[-114.05281, 44.68885],
					[-114.05348, 44.68823],
					[-114.05373, 44.68802],
					[-114.05403, 44.68779],
					[-114.05428, 44.68762],
					[-114.05589, 44.68661],
					[-114.05591, 44.68659],
					[-114.05781, 44.6854],
					[-114.05791, 44.68535],
					[-114.06087, 44.68347],
					[-114.06095, 44.68343],
					[-114.06139, 44.68317],
					[-114.06334, 44.68193],
					[-114.0654, 44.68066],
					[-114.06614, 44.68016],
					[-114.06629, 44.68004],
					[-114.06634, 44.68001],
					[-114.06645, 44.67993],
					[-114.06647, 44.67991],
					[-114.0666, 44.67982],
					[-114.0668, 44.67966],
					[-114.06684, 44.67962],
					[-114.06691, 44.67957],
					[-114.06701, 44.67948],
					[-114.0673, 44.67919],
					[-114.06737, 44.67913],
					[-114.06746, 44.67902],
					[-114.06793, 44.67851],
					[-114.07282, 44.67296],
					[-114.07282, 44.67295],
					[-114.07302, 44.67272],
					[-114.07397, 44.67169],
					[-114.07584, 44.66954],
					[-114.07608, 44.66923],
					[-114.07688, 44.66846],
					[-114.07703, 44.66833],
					[-114.07859, 44.66724],
					[-114.07904, 44.66688],
					[-114.07922, 44.6667],
					[-114.07931, 44.6666],
					[-114.07943, 44.66644],
					[-114.0795, 44.66633],
					[-114.0796, 44.66614],
					[-114.07968, 44.66593],
					[-114.07976, 44.66562],
					[-114.07977, 44.66553],
					[-114.07979, 44.66542],
					[-114.07979, 44.66512],
					[-114.07978, 44.66495],
					[-114.07976, 44.6648],
					[-114.07971, 44.66464],
					[-114.07961, 44.66439],
					[-114.07946, 44.66414],
					[-114.07945, 44.66413],
					[-114.07934, 44.66398],
					[-114.07922, 44.66384],
					[-114.07907, 44.6637],
					[-114.07867, 44.66326],
					[-114.07866, 44.66324],
					[-114.07839, 44.66295],
					[-114.07834, 44.66288],
					[-114.07816, 44.66258],
					[-114.07804, 44.66227],
					[-114.07801, 44.66215],
					[-114.07797, 44.66204],
					[-114.07793, 44.66183],
					[-114.07792, 44.66175],
					[-114.07792, 44.66161],
					[-114.07791, 44.66156],
					[-114.07791, 44.66149],
					[-114.07792, 44.66134],
					[-114.07792, 44.66121],
					[-114.07793, 44.66113],
					[-114.07793, 44.66105],
					[-114.07802, 44.66021],
					[-114.07806, 44.65971],
					[-114.07811, 44.65931],
					[-114.07828, 44.65754],
					[-114.07835, 44.65699],
					[-114.07837, 44.65688],
					[-114.07838, 44.65679],
					[-114.07846, 44.65645],
					[-114.07851, 44.65631],
					[-114.07856, 44.6562],
					[-114.07861, 44.65612],
					[-114.07866, 44.65602],
					[-114.0788, 44.65583],
					[-114.07893, 44.65567],
					[-114.07903, 44.65557],
					[-114.07943, 44.65524],
					[-114.07959, 44.65513],
					[-114.07979, 44.65501],
					[-114.07987, 44.65498],
					[-114.08004, 44.65489],
					[-114.08297, 44.65375],
					[-114.08307, 44.65372],
					[-114.08453, 44.65316],
					[-114.08454, 44.65316],
					[-114.08554, 44.65276],
					[-114.08562, 44.65272],
					[-114.08567, 44.6527],
					[-114.08579, 44.65264],
					[-114.08593, 44.65256],
					[-114.08602, 44.65252],
					[-114.08618, 44.65242],
					[-114.08624, 44.65239],
					[-114.0863, 44.65234],
					[-114.08632, 44.65233],
					[-114.08638, 44.65229],
					[-114.08672, 44.65203],
					[-114.08677, 44.65198],
					[-114.08696, 44.65183],
					[-114.08704, 44.65174],
					[-114.08713, 44.65166],
					[-114.08725, 44.65153],
					[-114.08726, 44.65151],
					[-114.08735, 44.65142],
					[-114.08738, 44.65137],
					[-114.08743, 44.65132],
					[-114.08752, 44.65119],
					[-114.08758, 44.65109],
					[-114.08761, 44.65105],
					[-114.08763, 44.65101],
					[-114.08766, 44.65097],
					[-114.08837, 44.64974],
					[-114.08838, 44.64974],
					[-114.08853, 44.64946],
					[-114.08869, 44.64919],
					[-114.08882, 44.64895],
					[-114.0889, 44.64882],
					[-114.08908, 44.64849],
					[-114.08911, 44.64841],
					[-114.08912, 44.6484],
					[-114.08916, 44.64831],
					[-114.08919, 44.64826],
					[-114.08921, 44.64819],
					[-114.08924, 44.64812],
					[-114.08931, 44.64792],
					[-114.08937, 44.6477],
					[-114.08945, 44.64746],
					[-114.08949, 44.64731],
					[-114.08955, 44.64716],
					[-114.08955, 44.64714],
					[-114.08957, 44.64708],
					[-114.0896, 44.64701],
					[-114.08962, 44.64694],
					[-114.08964, 44.64689],
					[-114.08967, 44.64683],
					[-114.08967, 44.64682],
					[-114.08986, 44.64647],
					[-114.09007, 44.64616],
					[-114.09032, 44.64585],
					[-114.09036, 44.64581],
					[-114.09039, 44.64577],
					[-114.09049, 44.64567],
					[-114.09054, 44.64561],
					[-114.09071, 44.64544],
					[-114.09078, 44.64538],
					[-114.09079, 44.64538],
					[-114.09086, 44.64531],
					[-114.09093, 44.64525],
					[-114.09134, 44.64496],
					[-114.09147, 44.64489],
					[-114.09181, 44.64468],
					[-114.09208, 44.64454],
					[-114.09212, 44.64451],
					[-114.09244, 44.64437],
					[-114.09273, 44.64426],
					[-114.09277, 44.64425],
					[-114.09316, 44.64411],
					[-114.09368, 44.64397],
					[-114.09424, 44.64386],
					[-114.09435, 44.64385],
					[-114.09443, 44.64383],
					[-114.09462, 44.64381],
					[-114.09464, 44.6438],
					[-114.09472, 44.64379],
					[-114.0948, 44.64379],
					[-114.09489, 44.64378],
					[-114.09498, 44.64378],
					[-114.09509, 44.64377],
					[-114.0952, 44.64377],
					[-114.09528, 44.64376],
					[-114.0957, 44.64376],
					[-114.0982, 44.6439],
					[-114.09896, 44.64392],
					[-114.10179, 44.64391],
					[-114.10182, 44.6439],
					[-114.10189, 44.64389],
					[-114.10195, 44.64389],
					[-114.10196, 44.64388],
					[-114.10203, 44.64387],
					[-114.1043, 44.64341],
					[-114.1048, 44.64333],
					[-114.10546, 44.64325],
					[-114.10639, 44.64319],
					[-114.10663, 44.64319],
					[-114.10779, 44.64312],
					[-114.10869, 44.64301],
					[-114.11111, 44.64258],
					[-114.11134, 44.64255],
					[-114.11428, 44.64203],
					[-114.11432, 44.64203],
					[-114.11494, 44.6419],
					[-114.11504, 44.64187],
					[-114.11525, 44.64182],
					[-114.11593, 44.64162],
					[-114.11641, 44.64146],
					[-114.11695, 44.64126],
					[-114.11777, 44.64089],
					[-114.11778, 44.64089],
					[-114.12124, 44.63903],
					[-114.12199, 44.63859],
					[-114.12265, 44.63825],
					[-114.12273, 44.63822],
					[-114.1228, 44.63818],
					[-114.12307, 44.63808],
					[-114.12315, 44.63804],
					[-114.12323, 44.63802],
					[-114.1233, 44.63799],
					[-114.12353, 44.63792],
					[-114.12368, 44.63789],
					[-114.12436, 44.63772],
					[-114.12459, 44.63768],
					[-114.12576, 44.63742],
					[-114.12637, 44.63724],
					[-114.12641, 44.63722],
					[-114.12647, 44.63721],
					[-114.12652, 44.63719],
					[-114.12657, 44.63718],
					[-114.12673, 44.63713],
					[-114.12682, 44.63709],
					[-114.12826, 44.63656],
					[-114.1393, 44.63279],
					[-114.14014, 44.63254],
					[-114.1412, 44.63228],
					[-114.14199, 44.63212],
					[-114.14292, 44.63198],
					[-114.14345, 44.63193],
					[-114.14698, 44.6315],
					[-114.14748, 44.63142],
					[-114.14752, 44.63141],
					[-114.15041, 44.63093],
					[-114.15133, 44.63075],
					[-114.15216, 44.63056],
					[-114.15261, 44.63044],
					[-114.15352, 44.63014],
					[-114.15409, 44.62993],
					[-114.15488, 44.62961],
					[-114.15573, 44.6292],
					[-114.15626, 44.62892],
					[-114.15672, 44.62865],
					[-114.15744, 44.62818],
					[-114.16103, 44.6253],
					[-114.16343, 44.62342],
					[-114.16358, 44.62329],
					[-114.16374, 44.62313],
					[-114.16428, 44.62253],
					[-114.16437, 44.62241],
					[-114.16461, 44.62205],
					[-114.16529, 44.62083],
					[-114.16535, 44.6207],
					[-114.16562, 44.6202],
					[-114.16567, 44.62012],
					[-114.16689, 44.61789],
					[-114.16721, 44.61736],
					[-114.16744, 44.61705],
					[-114.16773, 44.61672],
					[-114.16802, 44.61645],
					[-114.16824, 44.61629],
					[-114.16863, 44.61605],
					[-114.16886, 44.61592],
					[-114.16959, 44.61557],
					[-114.1696, 44.61556],
					[-114.16982, 44.61547],
					[-114.17022, 44.61528],
					[-114.17105, 44.61485],
					[-114.17152, 44.61458],
					[-114.17198, 44.61428],
					[-114.17212, 44.61418],
					[-114.17232, 44.61402],
					[-114.17598, 44.61145],
					[-114.1764, 44.61112],
					[-114.17681, 44.61074],
					[-114.17692, 44.61065],
					[-114.17704, 44.61054],
					[-114.17745, 44.6101],
					[-114.17748, 44.61005],
					[-114.17754, 44.60998],
					[-114.17899, 44.60804],
					[-114.17949, 44.60741],
					[-114.18075, 44.60573],
					[-114.18084, 44.60559],
					[-114.18266, 44.60315],
					[-114.18292, 44.60282],
					[-114.18321, 44.6025],
					[-114.18348, 44.60223],
					[-114.18388, 44.60191],
					[-114.18415, 44.60172],
					[-114.18439, 44.60158],
					[-114.18445, 44.60155],
					[-114.18571, 44.60106],
					[-114.18866, 44.60002],
					[-114.18927, 44.59976],
					[-114.18979, 44.59949],
					[-114.19054, 44.599],
					[-114.1906, 44.59895],
					[-114.19063, 44.59892],
					[-114.19078, 44.5988],
					[-114.19104, 44.59855],
					[-114.1914, 44.59813],
					[-114.19143, 44.59807],
					[-114.19167, 44.5977],
					[-114.19176, 44.59752],
					[-114.19177, 44.59751],
					[-114.19188, 44.59729],
					[-114.19198, 44.59703],
					[-114.19209, 44.59652],
					[-114.19216, 44.59613],
					[-114.19222, 44.59564],
					[-114.19232, 44.59502],
					[-114.19241, 44.59427],
					[-114.19256, 44.59324],
					[-114.1926, 44.59287],
					[-114.19281, 44.59132],
					[-114.1929, 44.59084],
					[-114.1929, 44.59082],
					[-114.19294, 44.59071],
					[-114.19296, 44.59064],
					[-114.1934, 44.58951],
					[-114.19384, 44.58847],
					[-114.19389, 44.58837],
					[-114.19392, 44.58829],
					[-114.19394, 44.58822],
					[-114.19397, 44.58814],
					[-114.194, 44.58804],
					[-114.19405, 44.58782],
					[-114.19405, 44.58778],
					[-114.19406, 44.58778],
					[-114.19406, 44.58772],
					[-114.19408, 44.5875],
					[-114.19408, 44.58731],
					[-114.19406, 44.58703],
					[-114.19401, 44.58675],
					[-114.19398, 44.58665],
					[-114.19386, 44.58634],
					[-114.19382, 44.58627],
					[-114.19315, 44.58484],
					[-114.19291, 44.58427],
					[-114.19278, 44.58388],
					[-114.19274, 44.58371],
					[-114.19274, 44.58369],
					[-114.19268, 44.58322],
					[-114.19268, 44.58313],
					[-114.19267, 44.583],
					[-114.19268, 44.58289],
					[-114.19268, 44.58274],
					[-114.19274, 44.58221],
					[-114.19281, 44.58175],
					[-114.19306, 44.58039],
					[-114.19306, 44.58038],
					[-114.19318, 44.57983],
					[-114.19336, 44.57917],
					[-114.19338, 44.57912],
					[-114.19345, 44.57884],
					[-114.19356, 44.57846],
					[-114.19385, 44.57762],
					[-114.19418, 44.5764],
					[-114.1943, 44.57583],
					[-114.1943, 44.5758],
					[-114.19433, 44.57562],
					[-114.19436, 44.57529],
					[-114.19436, 44.57517],
					[-114.19437, 44.57507],
					[-114.19437, 44.57494],
					[-114.19429, 44.57414],
					[-114.19423, 44.57388],
					[-114.19421, 44.57375],
					[-114.1941, 44.57334],
					[-114.19363, 44.57209],
					[-114.19346, 44.57159],
					[-114.19339, 44.57132],
					[-114.19337, 44.57122],
					[-114.19335, 44.57106],
					[-114.19335, 44.57074],
					[-114.19339, 44.5703],
					[-114.19364, 44.56945],
					[-114.19424, 44.56755],
					[-114.19445, 44.56681],
					[-114.19568, 44.56281],
					[-114.19571, 44.56274],
					[-114.19584, 44.56231],
					[-114.19588, 44.5622],
					[-114.19598, 44.56181],
					[-114.19627, 44.56083],
					[-114.19653, 44.56002],
					[-114.19674, 44.5593],
					[-114.19677, 44.55922],
					[-114.19683, 44.55899],
					[-114.19691, 44.55875],
					[-114.19691, 44.55873],
					[-114.19749, 44.55687],
					[-114.19772, 44.55624],
					[-114.19819, 44.55516],
					[-114.19843, 44.55465],
					[-114.1985, 44.55448],
					[-114.19878, 44.55389],
					[-114.19927, 44.55265],
					[-114.19934, 44.55244],
					[-114.19952, 44.55196],
					[-114.19962, 44.55164],
					[-114.19967, 44.55145],
					[-114.1997, 44.55136],
					[-114.19972, 44.55127],
					[-114.19987, 44.55075],
					[-114.19998, 44.5503],
					[-114.20005, 44.55006],
					[-114.20007, 44.54994],
					[-114.20018, 44.54949],
					[-114.2006, 44.54757],
					[-114.20067, 44.54719],
					[-114.20072, 44.54685],
					[-114.20075, 44.54656],
					[-114.20075, 44.54578],
					[-114.20073, 44.5456],
					[-114.20073, 44.5455],
					[-114.2007, 44.54527],
					[-114.20069, 44.54509],
					[-114.20067, 44.545],
					[-114.20066, 44.5449],
					[-114.2006, 44.54463],
					[-114.2006, 44.54461],
					[-114.20058, 44.54454],
					[-114.20057, 44.54446],
					[-114.20054, 44.54434],
					[-114.20042, 44.54396],
					[-114.20025, 44.5435],
					[-114.19997, 44.54292],
					[-114.19994, 44.54287],
					[-114.19985, 44.54269],
					[-114.19957, 44.54218],
					[-114.19921, 44.54146],
					[-114.19877, 44.54068],
					[-114.19843, 44.5399],
					[-114.19842, 44.53985],
					[-114.19836, 44.53966],
					[-114.19835, 44.53964],
					[-114.19835, 44.53962],
					[-114.19832, 44.53953],
					[-114.19825, 44.53917],
					[-114.19821, 44.53887],
					[-114.19819, 44.53861],
					[-114.19819, 44.53848],
					[-114.1982, 44.53834],
					[-114.1982, 44.53816],
					[-114.19823, 44.53784],
					[-114.19823, 44.53779],
					[-114.19825, 44.53771],
					[-114.19826, 44.53761],
					[-114.1983, 44.5374],
					[-114.19832, 44.53735],
					[-114.19835, 44.53722],
					[-114.19845, 44.53688],
					[-114.19861, 44.53648],
					[-114.19867, 44.53637],
					[-114.19872, 44.53626],
					[-114.19883, 44.53605],
					[-114.19898, 44.53581],
					[-114.19917, 44.53553],
					[-114.1993, 44.53536],
					[-114.1994, 44.53525],
					[-114.19952, 44.53509],
					[-114.20043, 44.53409],
					[-114.201, 44.53349],
					[-114.20822, 44.52549],
					[-114.21009, 44.5235],
					[-114.21863, 44.51408],
					[-114.22056, 44.51189],
					[-114.22171, 44.51067],
					[-114.22261, 44.50963],
					[-114.22307, 44.50892],
					[-114.22316, 44.50875],
					[-114.22327, 44.50848],
					[-114.22338, 44.50807],
					[-114.22343, 44.50782],
					[-114.22344, 44.50769],
					[-114.22345, 44.50765],
					[-114.2235, 44.50577],
					[-114.2235, 44.50524],
					[-114.22348, 44.50455],
					[-114.22348, 44.4992],
					[-114.22343, 44.49799],
					[-114.22342, 44.49791],
					[-114.22342, 44.4978],
					[-114.22341, 44.49779],
					[-114.22341, 44.4977],
					[-114.22337, 44.49741],
					[-114.22335, 44.49734],
					[-114.22325, 44.49687],
					[-114.22322, 44.49676],
					[-114.22318, 44.49665],
					[-114.2231, 44.49635],
					[-114.22294, 44.49587],
					[-114.22282, 44.49559],
					[-114.22278, 44.49548],
					[-114.22268, 44.49527],
					[-114.22267, 44.49524],
					[-114.2226, 44.49511],
					[-114.22256, 44.49502],
					[-114.22246, 44.49484],
					[-114.22233, 44.49463],
					[-114.22232, 44.4946],
					[-114.22102, 44.49246],
					[-114.21688, 44.48584],
					[-114.21668, 44.4855],
					[-114.21636, 44.485],
					[-114.21635, 44.48498],
					[-114.21596, 44.48437],
					[-114.21566, 44.48394],
					[-114.2146, 44.48256],
					[-114.21452, 44.48244],
					[-114.21155, 44.4786],
					[-114.21146, 44.47847],
					[-114.21022, 44.47689],
					[-114.20947, 44.47605],
					[-114.20873, 44.4753],
					[-114.21221, 44.47352],
					[-114.21315, 44.47297],
					[-114.21394, 44.47247],
					[-114.21434, 44.4722],
					[-114.21439, 44.47216],
					[-114.21451, 44.47208],
					[-114.21457, 44.47203],
					[-114.21472, 44.47193],
					[-114.21537, 44.47144],
					[-114.21603, 44.47089],
					[-114.21621, 44.47071],
					[-114.21648, 44.47047],
					[-114.21675, 44.47021],
					[-114.2169, 44.47005],
					[-114.21721, 44.46975],
					[-114.21775, 44.46915],
					[-114.21784, 44.46904],
					[-114.21792, 44.46896],
					[-114.21799, 44.46887],
					[-114.21804, 44.46879],
					[-114.2182, 44.46859],
					[-114.2183, 44.46845],
					[-114.21836, 44.46838],
					[-114.21842, 44.46829],
					[-114.21852, 44.46816],
					[-114.22166, 44.46344],
					[-114.22337, 44.46079],
					[-114.22342, 44.4607],
					[-114.22362, 44.46038],
					[-114.22406, 44.45947],
					[-114.22424, 44.45906],
					[-114.2243, 44.45894],
					[-114.2244, 44.45867],
					[-114.22445, 44.45856],
					[-114.22453, 44.45834],
					[-114.22474, 44.45763],
					[-114.22501, 44.45651],
					[-114.22688, 44.44752],
					[-114.22689, 44.44741],
					[-114.22691, 44.44729],
					[-114.22695, 44.44685],
					[-114.22695, 44.4462],
					[-114.22693, 44.44603],
					[-114.22692, 44.44583],
					[-114.22691, 44.44578],
					[-114.2269, 44.44567],
					[-114.22684, 44.44529],
					[-114.22683, 44.44519],
					[-114.2268, 44.44509],
					[-114.22638, 44.44338],
					[-114.22622, 44.44262],
					[-114.22614, 44.44212],
					[-114.22608, 44.44131],
					[-114.22609, 44.44061],
					[-114.2261, 44.44053],
					[-114.2261, 44.44046],
					[-114.22614, 44.44005],
					[-114.22616, 44.43991],
					[-114.22617, 44.43978],
					[-114.22687, 44.43559],
					[-114.22692, 44.43523],
					[-114.22694, 44.43513],
					[-114.22694, 44.43503],
					[-114.22696, 44.43483],
					[-114.22696, 44.43472],
					[-114.22695, 44.43466],
					[-114.22695, 44.43461],
					[-114.22692, 44.43443],
					[-114.2269, 44.43437],
					[-114.22689, 44.43432],
					[-114.22687, 44.43429],
					[-114.22684, 44.43421],
					[-114.2268, 44.43413],
					[-114.22676, 44.43407],
					[-114.22673, 44.43401],
					[-114.2267, 44.43398],
					[-114.22669, 44.43396],
					[-114.22668, 44.43395],
					[-114.22659, 44.43383],
					[-114.22603, 44.43329],
					[-114.22569, 44.43293],
					[-114.22568, 44.43291],
					[-114.22566, 44.43288],
					[-114.22564, 44.43286],
					[-114.22553, 44.4327],
					[-114.22533, 44.43234],
					[-114.22525, 44.43214],
					[-114.22518, 44.43193],
					[-114.22512, 44.43164],
					[-114.22511, 44.43153],
					[-114.22511, 44.4311],
					[-114.22512, 44.43105],
					[-114.22512, 44.43102],
					[-114.22513, 44.43098],
					[-114.22513, 44.43094],
					[-114.22514, 44.43091],
					[-114.22518, 44.43073],
					[-114.22521, 44.43064],
					[-114.22523, 44.43056],
					[-114.22526, 44.4305],
					[-114.2253, 44.43038],
					[-114.22551, 44.43001],
					[-114.22572, 44.42974],
					[-114.22576, 44.4297],
					[-114.22583, 44.42961],
					[-114.22638, 44.4291],
					[-114.22653, 44.42898],
					[-114.22698, 44.42865],
					[-114.22753, 44.42829],
					[-114.22781, 44.42812],
					[-114.22829, 44.42787],
					[-114.22884, 44.42761],
					[-114.22892, 44.42758],
					[-114.22905, 44.42752],
					[-114.22917, 44.42748],
					[-114.22965, 44.42729],
					[-114.23777, 44.42458],
					[-114.2386, 44.42427],
					[-114.23915, 44.42403],
					[-114.23963, 44.42379],
					[-114.24003, 44.42357],
					[-114.24012, 44.42351],
					[-114.24031, 44.42341],
					[-114.24038, 44.42336],
					[-114.24048, 44.4233],
					[-114.24056, 44.42324],
					[-114.24066, 44.42318],
					[-114.24098, 44.42295],
					[-114.24142, 44.42261],
					[-114.24168, 44.42239],
					[-114.24176, 44.42233],
					[-114.24177, 44.42231],
					[-114.24183, 44.42226],
					[-114.24189, 44.4222],
					[-114.24197, 44.42213],
					[-114.24216, 44.42194],
					[-114.24224, 44.42184],
					[-114.2423, 44.42178],
					[-114.24257, 44.42146],
					[-114.24307, 44.42082],
					[-114.24316, 44.42069],
					[-114.24365, 44.42006],
					[-114.2437, 44.41998],
					[-114.24381, 44.41983],
					[-114.24386, 44.41975],
					[-114.2439, 44.41967],
					[-114.24399, 44.41952],
					[-114.24402, 44.41944],
					[-114.24409, 44.4193],
					[-114.24411, 44.41924],
					[-114.24414, 44.41918],
					[-114.24414, 44.41917],
					[-114.24416, 44.4191],
					[-114.24419, 44.41903],
					[-114.24425, 44.41877],
					[-114.24428, 44.41857],
					[-114.24428, 44.41851],
					[-114.2443, 44.41837],
					[-114.24431, 44.41684],
					[-114.24432, 44.41668],
					[-114.24432, 44.41641],
					[-114.24435, 44.41602],
					[-114.24437, 44.41588],
					[-114.24438, 44.41576],
					[-114.24442, 44.41554],
					[-114.24445, 44.41545],
					[-114.24446, 44.4154],
					[-114.2445, 44.41526],
					[-114.24452, 44.41522],
					[-114.24453, 44.41518],
					[-114.24561, 44.41264],
					[-114.24568, 44.4125],
					[-114.24591, 44.41209],
					[-114.24594, 44.41205],
					[-114.24597, 44.412],
					[-114.246, 44.41196],
					[-114.24603, 44.41193],
					[-114.24604, 44.41191],
					[-114.24607, 44.41188],
					[-114.24609, 44.41185],
					[-114.24616, 44.41178],
					[-114.24621, 44.41174],
					[-114.24625, 44.4117],
					[-114.24633, 44.41164],
					[-114.24636, 44.41161],
					[-114.2468, 44.41131],
					[-114.24692, 44.41125],
					[-114.24704, 44.41118],
					[-114.24737, 44.41105],
					[-114.24841, 44.41068],
					[-114.24854, 44.41064],
					[-114.24864, 44.4106],
					[-114.2487, 44.41057],
					[-114.24876, 44.41055],
					[-114.24883, 44.41051],
					[-114.249, 44.41043],
					[-114.24904, 44.4104],
					[-114.24907, 44.41039],
					[-114.24911, 44.41037],
					[-114.24914, 44.41035],
					[-114.2492, 44.41032],
					[-114.24921, 44.41031],
					[-114.24931, 44.41024],
					[-114.24935, 44.41022],
					[-114.24939, 44.41019],
					[-114.24941, 44.41017],
					[-114.24945, 44.41015],
					[-114.24955, 44.41007],
					[-114.24961, 44.41003],
					[-114.24975, 44.40991],
					[-114.24982, 44.40984],
					[-114.24985, 44.40982],
					[-114.24988, 44.40979],
					[-114.24991, 44.40974],
					[-114.24995, 44.4097],
					[-114.24999, 44.40967],
					[-114.2502, 44.4094],
					[-114.25057, 44.40886],
					[-114.25082, 44.40845],
					[-114.25119, 44.40793],
					[-114.25178, 44.40705],
					[-114.25199, 44.40677],
					[-114.2521, 44.40661],
					[-114.25214, 44.40654],
					[-114.25218, 44.40649],
					[-114.25224, 44.4064],
					[-114.25231, 44.40631],
					[-114.25235, 44.40624],
					[-114.25241, 44.40615],
					[-114.25246, 44.40606],
					[-114.25256, 44.40592],
					[-114.25256, 44.4059],
					[-114.25265, 44.40578],
					[-114.25271, 44.40566],
					[-114.25287, 44.40541],
					[-114.25295, 44.40526],
					[-114.25302, 44.40515],
					[-114.25325, 44.40473],
					[-114.25328, 44.40469],
					[-114.2533, 44.40464],
					[-114.25333, 44.40459],
					[-114.25335, 44.40454],
					[-114.25411, 44.40299],
					[-114.25414, 44.40291],
					[-114.25483, 44.40155],
					[-114.25489, 44.40141],
					[-114.25491, 44.40138],
					[-114.25497, 44.40127],
					[-114.25516, 44.40098],
					[-114.25526, 44.40085],
					[-114.2553, 44.40079],
					[-114.25542, 44.40064],
					[-114.25546, 44.40058],
					[-114.25551, 44.40052],
					[-114.25556, 44.40045],
					[-114.25564, 44.40037],
					[-114.25605, 44.39989],
					[-114.25728, 44.39854],
					[-114.25735, 44.39845],
					[-114.25741, 44.39839],
					[-114.25753, 44.39824],
					[-114.25762, 44.39814],
					[-114.25797, 44.3977],
					[-114.25845, 44.39702],
					[-114.25853, 44.39692],
					[-114.2587, 44.39668],
					[-114.25875, 44.39662],
					[-114.25959, 44.39541],
					[-114.26141, 44.39287],
					[-114.26144, 44.39282],
					[-114.26373, 44.38966],
					[-114.26391, 44.38938],
					[-114.26395, 44.3893],
					[-114.26396, 44.38927],
					[-114.26398, 44.38924],
					[-114.26399, 44.38921],
					[-114.26403, 44.38912],
					[-114.26404, 44.38908],
					[-114.26406, 44.38905],
					[-114.26407, 44.38901],
					[-114.26408, 44.38899],
					[-114.26408, 44.38896],
					[-114.26409, 44.38893],
					[-114.2641, 44.38891],
					[-114.26411, 44.38887],
					[-114.26411, 44.38884],
					[-114.26412, 44.3888],
					[-114.26412, 44.38873],
					[-114.26413, 44.38867],
					[-114.26413, 44.38852],
					[-114.26412, 44.38846],
					[-114.26412, 44.38839],
					[-114.26411, 44.38836],
					[-114.26411, 44.3883],
					[-114.2641, 44.38828],
					[-114.26409, 44.3882],
					[-114.26405, 44.38808],
					[-114.26401, 44.38799],
					[-114.2639, 44.38778],
					[-114.26386, 44.38773],
					[-114.26376, 44.38757],
					[-114.26366, 44.38745],
					[-114.26361, 44.38741],
					[-114.26349, 44.38729],
					[-114.26342, 44.38724],
					[-114.26335, 44.38718],
					[-114.26328, 44.38714],
					[-114.26321, 44.38709],
					[-114.26314, 44.38705],
					[-114.26313, 44.38704],
					[-114.26296, 44.38694],
					[-114.26275, 44.38685],
					[-114.26262, 44.38678],
					[-114.25572, 44.38361],
					[-114.25525, 44.38337],
					[-114.25493, 44.38318],
					[-114.25485, 44.38314],
					[-114.25485, 44.38313],
					[-114.25481, 44.38311],
					[-114.25477, 44.38308],
					[-114.25473, 44.38306],
					[-114.25445, 44.38285],
					[-114.25442, 44.38282],
					[-114.25428, 44.38271],
					[-114.25423, 44.38266],
					[-114.25416, 44.3826],
					[-114.25411, 44.38254],
					[-114.25398, 44.38241],
					[-114.25393, 44.38235],
					[-114.25388, 44.3823],
					[-114.25383, 44.38224],
					[-114.25378, 44.38219],
					[-114.25375, 44.38214],
					[-114.25372, 44.38211],
					[-114.25368, 44.38206],
					[-114.25361, 44.38195],
					[-114.25358, 44.38192],
					[-114.25356, 44.38187],
					[-114.25353, 44.38184],
					[-114.25344, 44.38167],
					[-114.25343, 44.38164],
					[-114.25341, 44.38161],
					[-114.25339, 44.38157],
					[-114.25337, 44.38154],
					[-114.25336, 44.38151],
					[-114.25334, 44.38147],
					[-114.25333, 44.38143],
					[-114.25332, 44.38141],
					[-114.25325, 44.38121],
					[-114.2532, 44.3809],
					[-114.2532, 44.38084],
					[-114.25319, 44.38079],
					[-114.25319, 44.38074],
					[-114.25318, 44.38067],
					[-114.25318, 44.38031],
					[-114.25319, 44.38017],
					[-114.25324, 44.37991],
					[-114.25326, 44.37985],
					[-114.25328, 44.37977],
					[-114.2533, 44.37973],
					[-114.25331, 44.37968],
					[-114.25335, 44.3796],
					[-114.25338, 44.37955],
					[-114.25342, 44.37947],
					[-114.25345, 44.37943],
					[-114.25358, 44.37921],
					[-114.2538, 44.37892],
					[-114.25384, 44.37886],
					[-114.2539, 44.37879],
					[-114.25405, 44.37864],
					[-114.25412, 44.37856],
					[-114.25439, 44.37834],
					[-114.25444, 44.37831],
					[-114.25448, 44.37827],
					[-114.25459, 44.37821],
					[-114.2546, 44.3782],
					[-114.25464, 44.37817],
					[-114.25472, 44.37813],
					[-114.25491, 44.37801],
					[-114.255, 44.37797],
					[-114.25508, 44.37792],
					[-114.25741, 44.37678],
					[-114.2575, 44.37673],
					[-114.25757, 44.3767],
					[-114.25804, 44.37642],
					[-114.25811, 44.37637],
					[-114.2582, 44.37632],
					[-114.25853, 44.37609],
					[-114.25938, 44.37542],
					[-114.26383, 44.3717],
					[-114.26391, 44.37164],
					[-114.26397, 44.37158],
					[-114.26401, 44.37155],
					[-114.26407, 44.37149],
					[-114.2641, 44.37147],
					[-114.26413, 44.37143],
					[-114.26421, 44.37135],
					[-114.26428, 44.37126],
					[-114.26434, 44.3712],
					[-114.26448, 44.371],
					[-114.2646, 44.3708],
					[-114.26463, 44.37076],
					[-114.26464, 44.37073],
					[-114.26466, 44.37069],
					[-114.26468, 44.37066],
					[-114.26469, 44.37063],
					[-114.26471, 44.3706],
					[-114.26488, 44.37013],
					[-114.26488, 44.3701],
					[-114.26489, 44.37007],
					[-114.26489, 44.37005],
					[-114.2649, 44.37003],
					[-114.2649, 44.37],
					[-114.26491, 44.36997],
					[-114.26491, 44.36992],
					[-114.26492, 44.36987],
					[-114.26492, 44.36982],
					[-114.26493, 44.36977],
					[-114.26493, 44.36951],
					[-114.26492, 44.36946],
					[-114.26492, 44.36934],
					[-114.26489, 44.36919],
					[-114.26489, 44.36915],
					[-114.26485, 44.36899],
					[-114.26481, 44.36888],
					[-114.2648, 44.36883],
					[-114.26476, 44.36874],
					[-114.26474, 44.36867],
					[-114.26472, 44.36863],
					[-114.2647, 44.3686],
					[-114.26466, 44.36851],
					[-114.26457, 44.36836],
					[-114.26457, 44.36835],
					[-114.26385, 44.36717],
					[-114.26381, 44.36708],
					[-114.26375, 44.36697],
					[-114.26368, 44.36682],
					[-114.26365, 44.36674],
					[-114.2636, 44.36663],
					[-114.26351, 44.36634],
					[-114.26345, 44.36609],
					[-114.26345, 44.36606],
					[-114.26344, 44.36603],
					[-114.26341, 44.36588],
					[-114.26341, 44.36585],
					[-114.2634, 44.36582],
					[-114.2634, 44.36578],
					[-114.26339, 44.36575],
					[-114.26339, 44.36571],
					[-114.26338, 44.36567],
					[-114.26279, 44.36056],
					[-114.2627, 44.35938],
					[-114.26269, 44.35912],
					[-114.26274, 44.3582],
					[-114.26284, 44.35736],
					[-114.26296, 44.35673],
					[-114.26309, 44.35619],
					[-114.26329, 44.35551],
					[-114.26346, 44.35505],
					[-114.26352, 44.35486],
					[-114.26408, 44.35359],
					[-114.26509, 44.35168],
					[-114.26518, 44.35146],
					[-114.2652, 44.35142],
					[-114.26522, 44.35139],
					[-114.26531, 44.35113],
					[-114.26535, 44.35094],
					[-114.26535, 44.35086],
					[-114.26536, 44.35081],
					[-114.26536, 44.35071],
					[-114.26535, 44.35066],
					[-114.26535, 44.35062],
					[-114.26534, 44.35058],
					[-114.26534, 44.35052],
					[-114.26533, 44.35049],
					[-114.26533, 44.35044],
					[-114.26527, 44.35015],
					[-114.26525, 44.35009],
					[-114.26523, 44.35001],
					[-114.2652, 44.34993],
					[-114.26512, 44.34967],
					[-114.26502, 44.34941],
					[-114.26494, 44.34923],
					[-114.26492, 44.34917],
					[-114.26487, 44.34905],
					[-114.26481, 44.34887],
					[-114.2648, 44.34882],
					[-114.26476, 44.34868],
					[-114.26476, 44.34865],
					[-114.26475, 44.34862],
					[-114.26475, 44.34858],
					[-114.26474, 44.34855],
					[-114.26474, 44.34853],
					[-114.26473, 44.3485],
					[-114.26473, 44.34842],
					[-114.26472, 44.34831],
					[-114.26473, 44.34824],
					[-114.26473, 44.34808],
					[-114.26474, 44.34801],
					[-114.26474, 44.34796],
					[-114.26476, 44.34786],
					[-114.26476, 44.34782],
					[-114.26477, 44.3478],
					[-114.26479, 44.34771],
					[-114.26481, 44.34766],
					[-114.26482, 44.34765],
					[-114.26486, 44.34754],
					[-114.26497, 44.34731],
					[-114.26505, 44.34717],
					[-114.26514, 44.34703],
					[-114.26518, 44.34695],
					[-114.26523, 44.3469],
					[-114.26527, 44.34685],
					[-114.2653, 44.3468],
					[-114.26535, 44.34675],
					[-114.2654, 44.34669],
					[-114.26544, 44.34665],
					[-114.26548, 44.3466],
					[-114.26554, 44.34654],
					[-114.26567, 44.34643],
					[-114.26573, 44.34639],
					[-114.26585, 44.34629],
					[-114.26591, 44.34625],
					[-114.26596, 44.34621],
					[-114.26608, 44.34613],
					[-114.26626, 44.34603],
					[-114.26628, 44.34601],
					[-114.27051, 44.34351],
					[-114.27078, 44.34337],
					[-114.27083, 44.34335],
					[-114.27098, 44.34327],
					[-114.27156, 44.34306],
					[-114.27205, 44.34293],
					[-114.27217, 44.34291],
					[-114.27224, 44.34289],
					[-114.27245, 44.34286],
					[-114.27251, 44.34286],
					[-114.27259, 44.34285],
					[-114.27314, 44.34282],
					[-114.27365, 44.34282],
					[-114.27458, 44.34286],
					[-114.27572, 44.34288],
					[-114.27582, 44.34287],
					[-114.27596, 44.34287],
					[-114.27664, 44.34281],
					[-114.2772, 44.34272],
					[-114.27811, 44.3425],
					[-114.27869, 44.3423],
					[-114.27912, 44.34212],
					[-114.27924, 44.34205],
					[-114.27937, 44.34199],
					[-114.28007, 44.34159],
					[-114.28034, 44.34141],
					[-114.28077, 44.34109],
					[-114.2812, 44.3407],
					[-114.28135, 44.34053],
					[-114.28154, 44.34028],
					[-114.28172, 44.34001],
					[-114.28192, 44.33968],
					[-114.28206, 44.33939],
					[-114.28224, 44.33893],
					[-114.28244, 44.33837],
					[-114.28364, 44.33475],
					[-114.28368, 44.33467],
					[-114.28372, 44.33454],
					[-114.28385, 44.33427],
					[-114.28392, 44.33415],
					[-114.28416, 44.3338],
					[-114.28431, 44.33362],
					[-114.28437, 44.33356],
					[-114.28444, 44.33347],
					[-114.28453, 44.33339],
					[-114.2846, 44.33331],
					[-114.2849, 44.33305],
					[-114.28509, 44.3329],
					[-114.28552, 44.33262],
					[-114.2856, 44.33258],
					[-114.28571, 44.33251],
					[-114.28577, 44.33248],
					[-114.28588, 44.33241],
					[-114.28615, 44.33227],
					[-114.28626, 44.33222],
					[-114.2871, 44.33174],
					[-114.28754, 44.33144],
					[-114.28764, 44.33136],
					[-114.28812, 44.33093],
					[-114.28818, 44.33086],
					[-114.28822, 44.33082],
					[-114.28828, 44.33075],
					[-114.28833, 44.3307],
					[-114.28847, 44.33053],
					[-114.28863, 44.3303],
					[-114.28866, 44.33027],
					[-114.28886, 44.32997],
					[-114.28898, 44.32976],
					[-114.28907, 44.32958],
					[-114.28918, 44.32933],
					[-114.28925, 44.32914],
					[-114.28936, 44.32878],
					[-114.28939, 44.32863],
					[-114.28944, 44.32821],
					[-114.28945, 44.32804],
					[-114.28945, 44.32767],
					[-114.28943, 44.32743],
					[-114.28935, 44.32691],
					[-114.28876, 44.32406],
					[-114.28869, 44.32345],
					[-114.28868, 44.32326],
					[-114.28868, 44.32268],
					[-114.28869, 44.3225],
					[-114.28888, 44.32052],
					[-114.28894, 44.32006],
					[-114.28905, 44.3195],
					[-114.2892, 44.319],
					[-114.28941, 44.31838],
					[-114.28953, 44.31797],
					[-114.28959, 44.3178],
					[-114.2897, 44.31731],
					[-114.2897, 44.31727],
					[-114.28971, 44.31722],
					[-114.28973, 44.31695],
					[-114.28973, 44.31684],
					[-114.28968, 44.31635],
					[-114.28963, 44.31603],
					[-114.28957, 44.31579],
					[-114.28952, 44.31564],
					[-114.28943, 44.31543],
					[-114.28887, 44.31424],
					[-114.28885, 44.31418],
					[-114.2888, 44.31407],
					[-114.28878, 44.31401],
					[-114.28871, 44.31384],
					[-114.28866, 44.31361],
					[-114.28866, 44.31352],
					[-114.28865, 44.31345],
					[-114.28865, 44.31338],
					[-114.28866, 44.31329],
					[-114.28866, 44.31321],
					[-114.28869, 44.31299],
					[-114.28874, 44.3128],
					[-114.28884, 44.31251],
					[-114.29031, 44.30778],
					[-114.29032, 44.30776],
					[-114.29037, 44.3076],
					[-114.2904, 44.30753],
					[-114.2905, 44.30725],
					[-114.29052, 44.30722],
					[-114.29065, 44.30691],
					[-114.29146, 44.30549],
					[-114.29191, 44.30475],
					[-114.29953, 44.29301],
					[-114.29974, 44.29271],
					[-114.30079, 44.29112],
					[-114.30184, 44.28947],
					[-114.30231, 44.28859],
					[-114.30239, 44.2884],
					[-114.30248, 44.28822],
					[-114.30254, 44.28806],
					[-114.30262, 44.28788],
					[-114.30281, 44.2874],
					[-114.30284, 44.28728],
					[-114.3029, 44.28712],
					[-114.30333, 44.28562],
					[-114.30336, 44.28554],
					[-114.30337, 44.28549],
					[-114.30342, 44.28537],
					[-114.30346, 44.28525],
					[-114.30349, 44.28519],
					[-114.3036, 44.28491],
					[-114.30366, 44.2848],
					[-114.30389, 44.28447],
					[-114.30401, 44.28432],
					[-114.30434, 44.28397],
					[-114.30469, 44.28368],
					[-114.30487, 44.28355],
					[-114.30519, 44.28335],
					[-114.30577, 44.28308],
					[-114.3059, 44.28304],
					[-114.30595, 44.28302],
					[-114.30601, 44.28299],
					[-114.30638, 44.28288],
					[-114.30641, 44.28288],
					[-114.30692, 44.28276],
					[-114.30718, 44.28271],
					[-114.30964, 44.28242],
					[-114.31016, 44.28234],
					[-114.31036, 44.2823],
					[-114.31077, 44.2822],
					[-114.31108, 44.28211],
					[-114.31127, 44.28204],
					[-114.31128, 44.28204],
					[-114.31166, 44.28191],
					[-114.31202, 44.28177],
					[-114.31239, 44.2816],
					[-114.31281, 44.28137],
					[-114.31294, 44.28128],
					[-114.31325, 44.28109],
					[-114.31344, 44.28095],
					[-114.31375, 44.2807],
					[-114.31414, 44.28034],
					[-114.31428, 44.2802],
					[-114.31464, 44.27979],
					[-114.3148, 44.27957],
					[-114.31499, 44.27927],
					[-114.31523, 44.27878],
					[-114.31536, 44.27843],
					[-114.3155, 44.27792],
					[-114.31555, 44.2775],
					[-114.31556, 44.27732],
					[-114.31551, 44.27598],
					[-114.31537, 44.2744],
					[-114.31537, 44.27408],
					[-114.31539, 44.27385],
					[-114.31545, 44.27356],
					[-114.31546, 44.27355],
					[-114.31547, 44.2735],
					[-114.31551, 44.27341],
					[-114.31553, 44.27338],
					[-114.31556, 44.27331],
					[-114.31565, 44.27316],
					[-114.31584, 44.27288],
					[-114.31613, 44.27259],
					[-114.31614, 44.27257],
					[-114.31655, 44.27226],
					[-114.31682, 44.27209],
					[-114.31715, 44.27193],
					[-114.31727, 44.27188],
					[-114.31785, 44.2717],
					[-114.31825, 44.27162],
					[-114.32005, 44.27137],
					[-114.32082, 44.27122],
					[-114.32116, 44.27113],
					[-114.32159, 44.271],
					[-114.32165, 44.27099],
					[-114.3221, 44.2708],
					[-114.32263, 44.27055],
					[-114.32292, 44.27039],
					[-114.32305, 44.27031],
					[-114.32339, 44.27007],
					[-114.3237, 44.26983],
					[-114.32412, 44.26943],
					[-114.32543, 44.26779],
					[-114.32744, 44.26543],
					[-114.32763, 44.26523],
					[-114.32764, 44.26521],
					[-114.32845, 44.26432],
					[-114.32975, 44.26294],
					[-114.33015, 44.26254],
					[-114.33046, 44.26226],
					[-114.3308, 44.26199],
					[-114.33083, 44.26196],
					[-114.33117, 44.26172],
					[-114.33208, 44.26113],
					[-114.33209, 44.26112],
					[-114.33309, 44.26049],
					[-114.33338, 44.26029],
					[-114.33339, 44.26029],
					[-114.33391, 44.2599],
					[-114.3343, 44.25958],
					[-114.33792, 44.25609],
					[-114.33841, 44.25567],
					[-114.33848, 44.25562],
					[-114.33853, 44.25559],
					[-114.33854, 44.25558],
					[-114.33881, 44.25543],
					[-114.33913, 44.25528],
					[-114.33937, 44.25518],
					[-114.33948, 44.25515],
					[-114.33962, 44.2551],
					[-114.34003, 44.25498],
					[-114.34005, 44.25498],
					[-114.34044, 44.2549],
					[-114.34053, 44.2549],
					[-114.34059, 44.25489],
					[-114.34089, 44.25487],
					[-114.34234, 44.2549],
					[-114.34253, 44.25489],
					[-114.34286, 44.25489],
					[-114.34318, 44.25486],
					[-114.34907, 44.2541],
					[-114.34947, 44.25406],
					[-114.34967, 44.25403],
					[-114.34972, 44.25403],
					[-114.3504, 44.25398],
					[-114.35047, 44.25398],
					[-114.35055, 44.25397],
					[-114.35083, 44.25396],
					[-114.35122, 44.25396],
					[-114.35172, 44.25398],
					[-114.3518, 44.25399],
					[-114.35203, 44.254],
					[-114.35281, 44.25411],
					[-114.3536, 44.25425],
					[-114.35376, 44.25427],
					[-114.35392, 44.2543],
					[-114.3541, 44.25432],
					[-114.35457, 44.25435],
					[-114.35495, 44.25435],
					[-114.35624, 44.25427],
					[-114.35651, 44.25427],
					[-114.35687, 44.2543],
					[-114.35702, 44.25432],
					[-114.35715, 44.25435],
					[-114.35718, 44.25435],
					[-114.35781, 44.25451],
					[-114.35979, 44.25522],
					[-114.36034, 44.25539],
					[-114.36036, 44.2554],
					[-114.36093, 44.25557],
					[-114.36158, 44.25574],
					[-114.36193, 44.25582],
					[-114.36215, 44.25586],
					[-114.36246, 44.25593],
					[-114.36249, 44.25593],
					[-114.36314, 44.25606],
					[-114.3642, 44.25624],
					[-114.36444, 44.25627],
					[-114.36449, 44.25628],
					[-114.36538, 44.25638],
					[-114.36543, 44.25639],
					[-114.36658, 44.25653],
					[-114.36715, 44.25658],
					[-114.36774, 44.25665],
					[-114.36866, 44.25673],
					[-114.3689, 44.25676],
					[-114.37103, 44.25692],
					[-114.37143, 44.25692],
					[-114.37162, 44.25693],
					[-114.37174, 44.25692],
					[-114.37276, 44.25689],
					[-114.37292, 44.25687],
					[-114.3731, 44.25686],
					[-114.37559, 44.25662],
					[-114.3759, 44.25658],
					[-114.37599, 44.25658],
					[-114.37666, 44.25651],
					[-114.37731, 44.25647],
					[-114.37954, 44.25648],
					[-114.37983, 44.25647],
					[-114.37989, 44.25646],
					[-114.37999, 44.25646],
					[-114.38002, 44.25645],
					[-114.38006, 44.25645],
					[-114.38013, 44.25643],
					[-114.38017, 44.25643],
					[-114.38053, 44.25636],
					[-114.38111, 44.25619],
					[-114.38154, 44.25602],
					[-114.38166, 44.25598],
					[-114.3821, 44.25581],
					[-114.38211, 44.25581],
					[-114.38228, 44.25575],
					[-114.38234, 44.25574],
					[-114.38245, 44.2557],
					[-114.3828, 44.25563],
					[-114.38314, 44.25559],
					[-114.38374, 44.25555],
					[-114.38389, 44.25555],
					[-114.38404, 44.25554],
					[-114.38415, 44.25554],
					[-114.38456, 44.25557],
					[-114.3846, 44.25558],
					[-114.38504, 44.25564],
					[-114.38518, 44.25568],
					[-114.38529, 44.2557],
					[-114.3853, 44.25571],
					[-114.38553, 44.25577],
					[-114.38554, 44.25577],
					[-114.38583, 44.25588],
					[-114.38668, 44.25628],
					[-114.39119, 44.25854],
					[-114.39162, 44.25874],
					[-114.39165, 44.25876],
					[-114.39205, 44.25894],
					[-114.39243, 44.25909],
					[-114.39287, 44.25924],
					[-114.3937, 44.25948],
					[-114.39386, 44.25951],
					[-114.39401, 44.25955],
					[-114.3942, 44.25958],
					[-114.39423, 44.25959],
					[-114.39493, 44.2597],
					[-114.39496, 44.2597],
					[-114.395, 44.25971],
					[-114.39525, 44.25974],
					[-114.39577, 44.25978],
					[-114.39615, 44.25978],
					[-114.39635, 44.25976],
					[-114.39646, 44.25976],
					[-114.39867, 44.25941],
					[-114.39898, 44.25935],
					[-114.39938, 44.25929],
					[-114.40036, 44.25911],
					[-114.40401, 44.25853],
					[-114.40446, 44.25849],
					[-114.40477, 44.25849],
					[-114.40496, 44.2585],
					[-114.40515, 44.2585],
					[-114.40528, 44.25852],
					[-114.40545, 44.25853],
					[-114.40583, 44.25858],
					[-114.40594, 44.2586],
					[-114.40595, 44.2586],
					[-114.40616, 44.25865],
					[-114.40625, 44.25866],
					[-114.40649, 44.25872],
					[-114.40661, 44.25876],
					[-114.4068, 44.25881],
					[-114.40753, 44.25909],
					[-114.40822, 44.2594],
					[-114.41192, 44.26128],
					[-114.41309, 44.26192],
					[-114.4135, 44.2621],
					[-114.41403, 44.26226],
					[-114.41443, 44.26233],
					[-114.41463, 44.26235],
					[-114.41504, 44.26237],
					[-114.41556, 44.26237],
					[-114.41563, 44.26236],
					[-114.4157, 44.26236],
					[-114.41572, 44.26235],
					[-114.4159, 44.26233],
					[-114.41602, 44.26231],
					[-114.41739, 44.26202],
					[-114.41793, 44.26193],
					[-114.41823, 44.26189],
					[-114.41838, 44.26188],
					[-114.4185, 44.26186],
					[-114.41917, 44.26179],
					[-114.41956, 44.26173],
					[-114.42026, 44.26159],
					[-114.42055, 44.26151],
					[-114.42057, 44.26151],
					[-114.42196, 44.26109],
					[-114.42243, 44.26097],
					[-114.42245, 44.26096],
					[-114.42305, 44.26082],
					[-114.42412, 44.26062],
					[-114.42486, 44.2605],
					[-114.42559, 44.26041],
					[-114.42602, 44.26039],
					[-114.42625, 44.26039],
					[-114.42647, 44.26038],
					[-114.42702, 44.26039],
					[-114.42736, 44.26041],
					[-114.42797, 44.26048],
					[-114.4281, 44.26051],
					[-114.42858, 44.26059],
					[-114.43271, 44.26138],
					[-114.43275, 44.26138],
					[-114.43343, 44.26151],
					[-114.43381, 44.26155],
					[-114.43419, 44.26157],
					[-114.43469, 44.26157],
					[-114.43494, 44.26155],
					[-114.43505, 44.26155],
					[-114.43561, 44.26148],
					[-114.43568, 44.26146],
					[-114.43593, 44.26142],
					[-114.43629, 44.26133],
					[-114.43685, 44.26113],
					[-114.43692, 44.2611],
					[-114.43707, 44.26105],
					[-114.43773, 44.26078],
					[-114.43812, 44.2606],
					[-114.43815, 44.26059],
					[-114.43835, 44.2605],
					[-114.43836, 44.26049],
					[-114.43878, 44.26029],
					[-114.4388, 44.26029],
					[-114.43898, 44.26021],
					[-114.43899, 44.2602],
					[-114.43917, 44.26013],
					[-114.43921, 44.26011],
					[-114.44041, 44.25964],
					[-114.4405, 44.25961],
					[-114.4428, 44.25869],
					[-114.44322, 44.25851],
					[-114.44384, 44.25819],
					[-114.44398, 44.25809],
					[-114.4441, 44.25802],
					[-114.44427, 44.2579],
					[-114.44428, 44.25789],
					[-114.44444, 44.25778],
					[-114.44474, 44.25752],
					[-114.44506, 44.25721],
					[-114.44507, 44.25719],
					[-114.44528, 44.25696],
					[-114.44544, 44.25675],
					[-114.4455, 44.25665],
					[-114.44556, 44.25657],
					[-114.44582, 44.25611],
					[-114.44784, 44.25104],
					[-114.44789, 44.25094],
					[-114.44796, 44.25083],
					[-114.44811, 44.25063],
					[-114.44812, 44.25062],
					[-114.44821, 44.2505],
					[-114.44824, 44.25048],
					[-114.44831, 44.2504],
					[-114.44858, 44.25016],
					[-114.44884, 44.24998],
					[-114.44894, 44.24992],
					[-114.44915, 44.24981],
					[-114.44949, 44.24966],
					[-114.44992, 44.2495],
					[-114.45, 44.24948],
					[-114.45007, 44.24945],
					[-114.45022, 44.24941],
					[-114.45138, 44.24918],
					[-114.45215, 44.24905],
					[-114.4531, 44.24886],
					[-114.45369, 44.2487],
					[-114.45397, 44.24861],
					[-114.45437, 44.24846],
					[-114.45444, 44.24844],
					[-114.45458, 44.24838],
					[-114.45459, 44.24838],
					[-114.45544, 44.248],
					[-114.4558, 44.24781],
					[-114.45585, 44.24779],
					[-114.45634, 44.24751],
					[-114.4566, 44.24734],
					[-114.45662, 44.24733],
					[-114.4576, 44.24662],
					[-114.45783, 44.24647],
					[-114.45788, 44.24645],
					[-114.45791, 44.24643],
					[-114.45808, 44.24634],
					[-114.45845, 44.24618],
					[-114.4588, 44.24608],
					[-114.45891, 44.24604],
					[-114.45898, 44.24603],
					[-114.45906, 44.24601],
					[-114.45914, 44.246],
					[-114.45922, 44.24598],
					[-114.45925, 44.24598],
					[-114.45943, 44.24596],
					[-114.4595, 44.24596],
					[-114.45958, 44.24595],
					[-114.45997, 44.24595],
					[-114.46053, 44.246],
					[-114.46101, 44.24607],
					[-114.46116, 44.2461],
					[-114.46139, 44.24613],
					[-114.46141, 44.24613],
					[-114.46163, 44.24617],
					[-114.46186, 44.2462],
					[-114.46235, 44.2463],
					[-114.46253, 44.24635],
					[-114.46264, 44.24639],
					[-114.46275, 44.24642],
					[-114.46284, 44.24646],
					[-114.46298, 44.24651],
					[-114.46311, 44.24658],
					[-114.46318, 44.24661],
					[-114.46336, 44.24671],
					[-114.46344, 44.24678],
					[-114.46352, 44.24683],
					[-114.46361, 44.24691],
					[-114.46363, 44.24692],
					[-114.46374, 44.24701],
					[-114.4638, 44.24707],
					[-114.46387, 44.24713],
					[-114.46406, 44.24736],
					[-114.46416, 44.24751],
					[-114.46417, 44.24752],
					[-114.46423, 44.24761],
					[-114.46432, 44.24782],
					[-114.46437, 44.24792],
					[-114.46465, 44.24858],
					[-114.46471, 44.2487],
					[-114.46473, 44.24876],
					[-114.465, 44.2493],
					[-114.46514, 44.24955],
					[-114.46546, 44.25003],
					[-114.46583, 44.25051],
					[-114.46621, 44.25094],
					[-114.46656, 44.25129],
					[-114.4666, 44.25132],
					[-114.46664, 44.25136],
					[-114.46675, 44.25145],
					[-114.46688, 44.25157],
					[-114.46724, 44.25185],
					[-114.46729, 44.25188],
					[-114.46766, 44.25215],
					[-114.46836, 44.2526],
					[-114.46859, 44.25273],
					[-114.46891, 44.25288],
					[-114.4691, 44.25298],
					[-114.46954, 44.25318],
					[-114.4704, 44.25352],
					[-114.47073, 44.25363],
					[-114.48622, 44.25788],
					[-114.48671, 44.258],
					[-114.48696, 44.25805],
					[-114.4871, 44.25807],
					[-114.48719, 44.25809],
					[-114.48748, 44.25812],
					[-114.48776, 44.25812],
					[-114.4881, 44.25809],
					[-114.48818, 44.25807],
					[-114.48825, 44.25806],
					[-114.48844, 44.25802],
					[-114.48863, 44.25797],
					[-114.48885, 44.2579],
					[-114.48901, 44.25784],
					[-114.48926, 44.25772],
					[-114.48933, 44.25768],
					[-114.48957, 44.25749],
					[-114.48962, 44.25744],
					[-114.48969, 44.25738],
					[-114.48992, 44.25711],
					[-114.48995, 44.25706],
					[-114.48997, 44.25701],
					[-114.49001, 44.25696],
					[-114.49089, 44.25522],
					[-114.49161, 44.25389],
					[-114.49188, 44.25343],
					[-114.49209, 44.25311],
					[-114.49236, 44.25275],
					[-114.49285, 44.25215],
					[-114.49318, 44.25177],
					[-114.4933, 44.25165],
					[-114.49332, 44.25162],
					[-114.49359, 44.25135],
					[-114.49393, 44.25105],
					[-114.49434, 44.25072],
					[-114.49492, 44.25029],
					[-114.49496, 44.25027],
					[-114.49531, 44.25002],
					[-114.49569, 44.24978],
					[-114.49573, 44.24976],
					[-114.49584, 44.24969],
					[-114.49589, 44.24967],
					[-114.49591, 44.24965],
					[-114.4961, 44.24955],
					[-114.49627, 44.24948],
					[-114.49644, 44.2494],
					[-114.49672, 44.24931],
					[-114.49695, 44.24925],
					[-114.4973, 44.24918],
					[-114.49771, 44.24913],
					[-114.49794, 44.24912],
					[-114.49815, 44.2491],
					[-114.50136, 44.24899],
					[-114.50551, 44.24879],
					[-114.50624, 44.24872],
					[-114.50695, 44.24862],
					[-114.50724, 44.24859],
					[-114.50773, 44.24851],
					[-114.51285, 44.24742],
					[-114.51343, 44.24736],
					[-114.51378, 44.24736],
					[-114.51405, 44.24737],
					[-114.5141, 44.24738],
					[-114.51434, 44.24739],
					[-114.51484, 44.24745],
					[-114.51501, 44.24748],
					[-114.51517, 44.2475],
					[-114.51532, 44.24753],
					[-114.51533, 44.24753],
					[-114.51546, 44.24755],
					[-114.51644, 44.24778],
					[-114.51678, 44.24787],
					[-114.51814, 44.24832],
					[-114.52228, 44.24977],
					[-114.5229, 44.24997],
					[-114.52309, 44.25004],
					[-114.52352, 44.25017],
					[-114.52372, 44.25022],
					[-114.52406, 44.25029],
					[-114.52456, 44.25036],
					[-114.52478, 44.25038],
					[-114.52492, 44.2504],
					[-114.52539, 44.25043],
					[-114.52603, 44.25043],
					[-114.52651, 44.2504],
					[-114.52674, 44.25037],
					[-114.52721, 44.25029],
					[-114.52771, 44.25018],
					[-114.52813, 44.25006],
					[-114.52836, 44.24998],
					[-114.52951, 44.24963],
					[-114.52952, 44.24963],
					[-114.52961, 44.2496],
					[-114.52969, 44.24958],
					[-114.52992, 44.24954],
					[-114.53008, 44.24952],
					[-114.53042, 44.2495],
					[-114.53057, 44.2495],
					[-114.53098, 44.24953],
					[-114.53133, 44.24958],
					[-114.53178, 44.24969],
					[-114.532, 44.24977],
					[-114.53226, 44.24989],
					[-114.53239, 44.24997],
					[-114.53255, 44.25005],
					[-114.53307, 44.25044],
					[-114.53323, 44.25058],
					[-114.53329, 44.25062],
					[-114.5336, 44.25091],
					[-114.53391, 44.25125],
					[-114.53418, 44.25161],
					[-114.53438, 44.25193],
					[-114.53465, 44.25246],
					[-114.53465, 44.25247],
					[-114.53514, 44.2536],
					[-114.53519, 44.25369],
					[-114.53531, 44.25394],
					[-114.53539, 44.25405],
					[-114.53548, 44.25416],
					[-114.53556, 44.25424],
					[-114.53564, 44.2543],
					[-114.53582, 44.25442],
					[-114.53591, 44.25445],
					[-114.53597, 44.25448],
					[-114.53604, 44.2545],
					[-114.5361, 44.25451],
					[-114.53616, 44.25453],
					[-114.53622, 44.25454],
					[-114.53629, 44.25454],
					[-114.53635, 44.25455],
					[-114.53654, 44.25455],
					[-114.53658, 44.25454],
					[-114.53667, 44.25454],
					[-114.53671, 44.25453],
					[-114.53679, 44.25452],
					[-114.53685, 44.2545],
					[-114.53691, 44.25449],
					[-114.53697, 44.25447],
					[-114.53702, 44.25444],
					[-114.53707, 44.25442],
					[-114.53722, 44.25434],
					[-114.53724, 44.25432],
					[-114.53738, 44.25422],
					[-114.53739, 44.2542],
					[-114.53744, 44.25416],
					[-114.53756, 44.25403],
					[-114.53814, 44.25328],
					[-114.53815, 44.25327],
					[-114.53856, 44.25274],
					[-114.53865, 44.25265],
					[-114.53873, 44.25255],
					[-114.53878, 44.25251],
					[-114.53882, 44.25247],
					[-114.53905, 44.25229],
					[-114.53948, 44.25204],
					[-114.53953, 44.25202],
					[-114.53975, 44.2519],
					[-114.53987, 44.25185],
					[-114.53997, 44.2518],
					[-114.54042, 44.25168],
					[-114.54063, 44.25164],
					[-114.54104, 44.25158],
					[-114.54142, 44.25156],
					[-114.54155, 44.25157],
					[-114.54166, 44.25157],
					[-114.5419, 44.25159],
					[-114.54218, 44.25163],
					[-114.54235, 44.25167],
					[-114.54253, 44.2517],
					[-114.54719, 44.25279],
					[-114.55239, 44.25386],
					[-114.55241, 44.25387],
					[-114.55381, 44.25418],
					[-114.55598, 44.2547],
					[-114.55607, 44.25473],
					[-114.55617, 44.25478],
					[-114.55626, 44.25481],
					[-114.55627, 44.25482],
					[-114.55639, 44.25487],
					[-114.55647, 44.25492],
					[-114.55654, 44.25495],
					[-114.55684, 44.25513],
					[-114.55696, 44.25521],
					[-114.55716, 44.25538],
					[-114.55723, 44.25545],
					[-114.55749, 44.25575],
					[-114.55793, 44.25633],
					[-114.55794, 44.25635],
					[-114.55815, 44.25658],
					[-114.55832, 44.25675],
					[-114.55849, 44.25687],
					[-114.55866, 44.25697],
					[-114.55894, 44.25709],
					[-114.55899, 44.2571],
					[-114.55915, 44.25715],
					[-114.55923, 44.25716],
					[-114.55931, 44.25718],
					[-114.55938, 44.25719],
					[-114.55943, 44.25719],
					[-114.55948, 44.2572],
					[-114.55964, 44.2572],
					[-114.55972, 44.25719],
					[-114.55979, 44.25719],
					[-114.56011, 44.25715],
					[-114.56023, 44.25712],
					[-114.56035, 44.2571],
					[-114.56335, 44.25632],
					[-114.56355, 44.25626],
					[-114.56415, 44.25613],
					[-114.56418, 44.25613],
					[-114.56473, 44.25604],
					[-114.56516, 44.256],
					[-114.56567, 44.25598],
					[-114.56595, 44.25598],
					[-114.56617, 44.25599],
					[-114.56673, 44.25604],
					[-114.56949, 44.25643],
					[-114.56963, 44.25644],
					[-114.57013, 44.2565],
					[-114.5702, 44.2565],
					[-114.57033, 44.25651],
					[-114.57045, 44.2565],
					[-114.57055, 44.2565],
					[-114.57065, 44.25649],
					[-114.57104, 44.25641],
					[-114.57127, 44.25635],
					[-114.57157, 44.25619],
					[-114.57176, 44.25606],
					[-114.57186, 44.25597],
					[-114.57199, 44.25581],
					[-114.57215, 44.25553],
					[-114.57217, 44.25548],
					[-114.57221, 44.2554],
					[-114.57224, 44.25527],
					[-114.57227, 44.25494],
					[-114.57233, 44.25314],
					[-114.57241, 44.25247],
					[-114.57255, 44.25202],
					[-114.5726, 44.2519],
					[-114.57264, 44.25178],
					[-114.57267, 44.25173],
					[-114.57289, 44.25126],
					[-114.57298, 44.25112],
					[-114.57301, 44.25106],
					[-114.57327, 44.25069],
					[-114.5735, 44.25041],
					[-114.57372, 44.25018],
					[-114.57396, 44.24998],
					[-114.57406, 44.24992],
					[-114.57413, 44.24987],
					[-114.57435, 44.24975],
					[-114.57464, 44.24964],
					[-114.57473, 44.24962],
					[-114.57501, 44.24958],
					[-114.57513, 44.24957],
					[-114.57538, 44.24957],
					[-114.57566, 44.24961],
					[-114.57577, 44.24964],
					[-114.57579, 44.24964],
					[-114.576, 44.24972],
					[-114.57612, 44.24978],
					[-114.57641, 44.24996],
					[-114.57646, 44.25001],
					[-114.57651, 44.25005],
					[-114.57655, 44.2501],
					[-114.57659, 44.25014],
					[-114.57661, 44.25019],
					[-114.57664, 44.25023],
					[-114.5767, 44.25034],
					[-114.57672, 44.2504],
					[-114.57672, 44.25042],
					[-114.57674, 44.25047],
					[-114.57677, 44.25059],
					[-114.57681, 44.25081],
					[-114.57685, 44.25113],
					[-114.57687, 44.25124],
					[-114.57696, 44.25199],
					[-114.57699, 44.25213],
					[-114.57707, 44.25241],
					[-114.5772, 44.25268],
					[-114.57729, 44.25284],
					[-114.57742, 44.25301],
					[-114.57758, 44.25319],
					[-114.57793, 44.25347],
					[-114.57804, 44.25355],
					[-114.57837, 44.25374],
					[-114.579, 44.25402],
					[-114.57985, 44.25434],
					[-114.58065, 44.25462],
					[-114.58072, 44.25464],
					[-114.58145, 44.25493],
					[-114.58159, 44.255],
					[-114.5816, 44.255],
					[-114.58244, 44.25544],
					[-114.58268, 44.25555],
					[-114.58273, 44.25558],
					[-114.58292, 44.25565],
					[-114.58302, 44.25568],
					[-114.58321, 44.25572],
					[-114.58346, 44.25575],
					[-114.58361, 44.25576],
					[-114.58398, 44.25576],
					[-114.58419, 44.25573],
					[-114.58422, 44.25573],
					[-114.58463, 44.25565],
					[-114.58566, 44.2554],
					[-114.5859, 44.25535],
					[-114.58607, 44.2553],
					[-114.58638, 44.25524],
					[-114.58686, 44.25513],
					[-114.58748, 44.25504],
					[-114.5876, 44.25504],
					[-114.58766, 44.25503],
					[-114.58783, 44.25503],
					[-114.58785, 44.25502],
					[-114.58844, 44.25503],
					[-114.5887, 44.25505],
					[-114.58907, 44.25511],
					[-114.58957, 44.25522],
					[-114.59002, 44.25536],
					[-114.59003, 44.25536],
					[-114.59047, 44.2555],
					[-114.59082, 44.25563],
					[-114.59117, 44.25574],
					[-114.59122, 44.25576],
					[-114.59163, 44.25589],
					[-114.59184, 44.25594],
					[-114.59187, 44.25595],
					[-114.59208, 44.256],
					[-114.59258, 44.25607],
					[-114.59279, 44.25608],
					[-114.59285, 44.25609],
					[-114.5932, 44.25609],
					[-114.59356, 44.25607],
					[-114.5936, 44.25606],
					[-114.59378, 44.25605],
					[-114.59536, 44.25587],
					[-114.59539, 44.25586],
					[-114.59658, 44.25572],
					[-114.59705, 44.25565],
					[-114.5972, 44.25562],
					[-114.59731, 44.25559],
					[-114.59734, 44.25559],
					[-114.59755, 44.25554],
					[-114.59756, 44.25553],
					[-114.59783, 44.25547],
					[-114.5983, 44.25534],
					[-114.59852, 44.25527],
					[-114.59893, 44.25511],
					[-114.60409, 44.25269],
					[-114.60431, 44.25261],
					[-114.60461, 44.25253],
					[-114.60473, 44.25251],
					[-114.60485, 44.25248],
					[-114.60525, 44.25244],
					[-114.60572, 44.25243],
					[-114.60588, 44.25245],
					[-114.60604, 44.25246],
					[-114.60657, 44.25257],
					[-114.60667, 44.2526],
					[-114.60671, 44.25262],
					[-114.60687, 44.25267],
					[-114.60695, 44.25271],
					[-114.60717, 44.2528],
					[-114.60756, 44.25302],
					[-114.60922, 44.2541],
					[-114.60937, 44.25419],
					[-114.60964, 44.25438],
					[-114.60986, 44.25451],
					[-114.60994, 44.25455],
					[-114.60999, 44.25458],
					[-114.61035, 44.25475],
					[-114.61042, 44.25477],
					[-114.61048, 44.2548],
					[-114.61059, 44.25484],
					[-114.61073, 44.25488],
					[-114.61084, 44.2549],
					[-114.61091, 44.25492],
					[-114.61135, 44.255],
					[-114.6115, 44.25502],
					[-114.6119, 44.25505],
					[-114.61216, 44.25505],
					[-114.61227, 44.25504],
					[-114.61238, 44.25504],
					[-114.61251, 44.25503],
					[-114.61263, 44.25503],
					[-114.61288, 44.25501],
					[-114.6131, 44.25498],
					[-114.61405, 44.25489],
					[-114.61409, 44.25488],
					[-114.61451, 44.25485],
					[-114.61514, 44.25476],
					[-114.61528, 44.25473],
					[-114.61577, 44.25465],
					[-114.61583, 44.25463],
					[-114.61589, 44.25462],
					[-114.61594, 44.2546],
					[-114.61606, 44.25457],
					[-114.61613, 44.25454],
					[-114.61695, 44.25426],
					[-114.61702, 44.25423],
					[-114.61739, 44.25411],
					[-114.61755, 44.25405],
					[-114.61809, 44.25388],
					[-114.61908, 44.25354],
					[-114.61923, 44.2535],
					[-114.62045, 44.25308],
					[-114.62132, 44.25276],
					[-114.62156, 44.25269],
					[-114.62192, 44.25263],
					[-114.6224, 44.25258],
					[-114.62284, 44.25258],
					[-114.62331, 44.25261],
					[-114.62365, 44.25265],
					[-114.62441, 44.25277],
					[-114.62445, 44.25277],
					[-114.62543, 44.25291],
					[-114.62569, 44.25293],
					[-114.62572, 44.25294],
					[-114.6259, 44.25295],
					[-114.62609, 44.25297],
					[-114.6265, 44.25297],
					[-114.62669, 44.25298],
					[-114.62748, 44.25297],
					[-114.62851, 44.25291],
					[-114.63171, 44.25267],
					[-114.6322, 44.25267],
					[-114.63244, 44.25269],
					[-114.63248, 44.25269],
					[-114.63267, 44.25271],
					[-114.63292, 44.25277],
					[-114.63293, 44.25277],
					[-114.63315, 44.25284],
					[-114.63342, 44.25295],
					[-114.63364, 44.25306],
					[-114.63378, 44.25315],
					[-114.63387, 44.2532],
					[-114.63409, 44.25336],
					[-114.63417, 44.25344],
					[-114.63424, 44.2535],
					[-114.63437, 44.25365],
					[-114.63444, 44.25375],
					[-114.63454, 44.25392],
					[-114.6346, 44.25406],
					[-114.63476, 44.25449],
					[-114.63486, 44.25473],
					[-114.63506, 44.25516],
					[-114.63514, 44.25528],
					[-114.63518, 44.25533],
					[-114.63528, 44.25543],
					[-114.63535, 44.25549],
					[-114.63547, 44.25557],
					[-114.63572, 44.25567],
					[-114.63593, 44.25572],
					[-114.63604, 44.25574],
					[-114.63622, 44.25576],
					[-114.63636, 44.25576],
					[-114.63645, 44.25575],
					[-114.63652, 44.25575],
					[-114.63665, 44.25573],
					[-114.63679, 44.2557],
					[-114.63685, 44.25568],
					[-114.6369, 44.25567],
					[-114.63696, 44.25564],
					[-114.63703, 44.25562],
					[-114.64447, 44.25237],
					[-114.6455, 44.25201],
					[-114.64584, 44.25191],
					[-114.64605, 44.25186],
					[-114.64609, 44.25186],
					[-114.64627, 44.25182],
					[-114.64654, 44.25178],
					[-114.64669, 44.25177],
					[-114.64684, 44.25175],
					[-114.64701, 44.25174],
					[-114.64817, 44.25161],
					[-114.64857, 44.25153],
					[-114.64869, 44.25149],
					[-114.64874, 44.25148],
					[-114.64879, 44.25146],
					[-114.64904, 44.25138],
					[-114.64938, 44.25125],
					[-114.64957, 44.25116],
					[-114.64962, 44.25113],
					[-114.64972, 44.25108],
					[-114.65019, 44.25079],
					[-114.6503, 44.25073],
					[-114.65041, 44.25066],
					[-114.65052, 44.2506],
					[-114.65077, 44.25049],
					[-114.65086, 44.25046],
					[-114.65095, 44.25042],
					[-114.65106, 44.25038],
					[-114.65108, 44.25038],
					[-114.65151, 44.25025],
					[-114.65165, 44.25023],
					[-114.65179, 44.2502],
					[-114.65182, 44.2502],
					[-114.65188, 44.25019],
					[-114.65194, 44.25019],
					[-114.65203, 44.25018],
					[-114.6521, 44.25018],
					[-114.65219, 44.25017],
					[-114.65228, 44.25017],
					[-114.65235, 44.25016],
					[-114.65254, 44.25016],
					[-114.6526, 44.25017],
					[-114.65291, 44.25017],
					[-114.65462, 44.25032],
					[-114.65483, 44.25035],
					[-114.65505, 44.25037],
					[-114.65595, 44.25049],
					[-114.65793, 44.25086],
					[-114.65814, 44.25091],
					[-114.65824, 44.25094],
					[-114.65856, 44.25101],
					[-114.65876, 44.25107],
					[-114.65909, 44.25115],
					[-114.66692, 44.2535],
					[-114.66729, 44.25364],
					[-114.66758, 44.25377],
					[-114.66764, 44.25381],
					[-114.66775, 44.25386],
					[-114.66815, 44.25412],
					[-114.66891, 44.25468],
					[-114.66924, 44.25489],
					[-114.66928, 44.25491],
					[-114.66935, 44.25496],
					[-114.66944, 44.25501],
					[-114.66963, 44.2551],
					[-114.67001, 44.25525],
					[-114.67083, 44.2555],
					[-114.67138, 44.25565],
					[-114.67182, 44.25574],
					[-114.67225, 44.25581],
					[-114.67301, 44.2559],
					[-114.67351, 44.25593],
					[-114.67368, 44.25592],
					[-114.67419, 44.25584],
					[-114.67443, 44.25577],
					[-114.67474, 44.25565],
					[-114.67514, 44.25545],
					[-114.6754, 44.25529],
					[-114.67551, 44.25521],
					[-114.67586, 44.25493],
					[-114.67648, 44.25438],
					[-114.67732, 44.25355],
					[-114.67799, 44.25298],
					[-114.67825, 44.25271],
					[-114.67825, 44.2527],
					[-114.67864, 44.25223],
					[-114.67893, 44.25192],
					[-114.67936, 44.25153],
					[-114.67963, 44.2513],
					[-114.67998, 44.25103],
					[-114.68005, 44.25099],
					[-114.68024, 44.25086],
					[-114.68047, 44.25073],
					[-114.68143, 44.25029],
					[-114.68161, 44.25022],
					[-114.68171, 44.25019],
					[-114.68191, 44.25017],
					[-114.68196, 44.25016],
					[-114.68206, 44.25016],
					[-114.68226, 44.25019],
					[-114.68243, 44.25023],
					[-114.68248, 44.25026],
					[-114.68251, 44.25027],
					[-114.68265, 44.25034],
					[-114.6828, 44.25044],
					[-114.6829, 44.25053],
					[-114.68319, 44.25082],
					[-114.68362, 44.2514],
					[-114.68376, 44.25168],
					[-114.68387, 44.25195],
					[-114.68397, 44.25225],
					[-114.68416, 44.25289],
					[-114.68425, 44.25314],
					[-114.6844, 44.25342],
					[-114.68442, 44.25347],
					[-114.68451, 44.25361],
					[-114.68456, 44.25366],
					[-114.6846, 44.25372],
					[-114.68484, 44.25398],
					[-114.685, 44.25411],
					[-114.68522, 44.25425],
					[-114.68622, 44.25472],
					[-114.68797, 44.25545],
					[-114.68813, 44.25551],
					[-114.68836, 44.25561],
					[-114.6888, 44.25585],
					[-114.68961, 44.25644],
					[-114.68984, 44.25663],
					[-114.69026, 44.25704],
					[-114.69077, 44.2576],
					[-114.69091, 44.25774],
					[-114.69127, 44.25805],
					[-114.69164, 44.25832],
					[-114.69196, 44.2585],
					[-114.69219, 44.25861],
					[-114.69223, 44.25862],
					[-114.69243, 44.25869],
					[-114.69246, 44.25869],
					[-114.69249, 44.2587],
					[-114.69284, 44.25876],
					[-114.69527, 44.25901],
					[-114.69543, 44.25904],
					[-114.69567, 44.2591],
					[-114.696, 44.25922],
					[-114.69626, 44.25933],
					[-114.69708, 44.25977],
					[-114.69753, 44.25997],
					[-114.69796, 44.26013],
					[-114.69813, 44.26018],
					[-114.69826, 44.26021],
					[-114.7005, 44.26049],
					[-114.70079, 44.26056],
					[-114.70104, 44.26064],
					[-114.70127, 44.26073],
					[-114.70187, 44.26111],
					[-114.70346, 44.26218],
					[-114.70347, 44.26219],
					[-114.70371, 44.26236],
					[-114.70411, 44.26258],
					[-114.70427, 44.26265],
					[-114.70448, 44.26273],
					[-114.70482, 44.26282],
					[-114.70505, 44.26285],
					[-114.7052, 44.26286],
					[-114.70555, 44.26286],
					[-114.70591, 44.26283],
					[-114.70602, 44.26281],
					[-114.70641, 44.2627],
					[-114.7069, 44.26254],
					[-114.70817, 44.26203],
					[-114.70842, 44.26196],
					[-114.70874, 44.2619],
					[-114.7089, 44.26189],
					[-114.7092, 44.26189],
					[-114.70945, 44.26192],
					[-114.70948, 44.26192],
					[-114.70966, 44.26195],
					[-114.71043, 44.26211],
					[-114.71144, 44.26236],
					[-114.71159, 44.26241],
					[-114.71192, 44.26254],
					[-114.71203, 44.26259],
					[-114.71214, 44.26265],
					[-114.71223, 44.26271],
					[-114.7123, 44.26274],
					[-114.71237, 44.26279],
					[-114.71249, 44.26289],
					[-114.71263, 44.26302],
					[-114.7131, 44.2634],
					[-114.71359, 44.26376],
					[-114.71415, 44.26407],
					[-114.71501, 44.26442],
					[-114.71654, 44.26497],
					[-114.71664, 44.265],
					[-114.71686, 44.26508],
					[-114.7169, 44.2651],
					[-114.71789, 44.2654],
					[-114.71806, 44.26544],
					[-114.71829, 44.26548],
					[-114.71854, 44.26551],
					[-114.72072, 44.26559],
					[-114.72076, 44.2656],
					[-114.7209, 44.26561],
					[-114.72133, 44.26568],
					[-114.72196, 44.26587],
					[-114.72197, 44.26587],
					[-114.72243, 44.26604],
					[-114.72283, 44.26616],
					[-114.72323, 44.26625],
					[-114.72358, 44.26631],
					[-114.72381, 44.26633],
					[-114.7239, 44.26633],
					[-114.72423, 44.2663],
					[-114.72431, 44.26628],
					[-114.72437, 44.26626],
					[-114.72446, 44.26624],
					[-114.72461, 44.26619],
					[-114.72521, 44.26594],
					[-114.72583, 44.26573],
					[-114.72602, 44.26568],
					[-114.7263, 44.26562],
					[-114.72684, 44.26554],
					[-114.72688, 44.26554],
					[-114.72696, 44.26553],
					[-114.72727, 44.26553],
					[-114.72776, 44.26558],
					[-114.7279, 44.26562],
					[-114.72822, 44.26569],
					[-114.72889, 44.2659],
					[-114.72966, 44.26623],
					[-114.73018, 44.26648],
					[-114.73055, 44.2667],
					[-114.73138, 44.26738],
					[-114.73148, 44.26747],
					[-114.73264, 44.26841],
					[-114.73268, 44.26845],
					[-114.73306, 44.26876],
					[-114.73323, 44.26891],
					[-114.73338, 44.26907],
					[-114.73354, 44.26928],
					[-114.73365, 44.26944],
					[-114.73367, 44.26946],
					[-114.73397, 44.27],
					[-114.73413, 44.27033],
					[-114.73428, 44.27058],
					[-114.73435, 44.27068],
					[-114.73449, 44.27084],
					[-114.73463, 44.27094],
					[-114.73469, 44.27096],
					[-114.73477, 44.27101],
					[-114.73503, 44.27111],
					[-114.73508, 44.27112],
					[-114.73518, 44.27115],
					[-114.73553, 44.2712],
					[-114.73608, 44.27121],
					[-114.73611, 44.2712],
					[-114.73622, 44.2712],
					[-114.73642, 44.27118],
					[-114.73664, 44.27114],
					[-114.7368, 44.27109],
					[-114.73683, 44.27107],
					[-114.73693, 44.27103],
					[-114.73726, 44.27085],
					[-114.73765, 44.27057],
					[-114.73814, 44.27024],
					[-114.73816, 44.27023],
					[-114.73848, 44.27],
					[-114.7385, 44.26998],
					[-114.73855, 44.26994],
					[-114.73867, 44.26982],
					[-114.73883, 44.26962],
					[-114.7389, 44.26946],
					[-114.73894, 44.26927],
					[-114.73896, 44.26907],
					[-114.73897, 44.26863],
					[-114.739, 44.26809],
					[-114.739, 44.26792],
					[-114.73904, 44.26765],
					[-114.73906, 44.26756],
					[-114.73909, 44.26746],
					[-114.73912, 44.26738],
					[-114.73913, 44.26737],
					[-114.73916, 44.26731],
					[-114.73922, 44.26724],
					[-114.73928, 44.26718],
					[-114.73947, 44.26703],
					[-114.73967, 44.26695],
					[-114.73993, 44.26691],
					[-114.74008, 44.2669],
					[-114.74019, 44.2669],
					[-114.74039, 44.26694],
					[-114.74059, 44.26699],
					[-114.74073, 44.26705],
					[-114.74078, 44.26708],
					[-114.74079, 44.26708],
					[-114.74083, 44.26711],
					[-114.74091, 44.26716],
					[-114.74131, 44.26747],
					[-114.74133, 44.26748],
					[-114.74162, 44.26768],
					[-114.74176, 44.26775],
					[-114.7419, 44.26781],
					[-114.74216, 44.26789],
					[-114.74259, 44.26794],
					[-114.74291, 44.26794],
					[-114.74355, 44.2679],
					[-114.74362, 44.2679],
					[-114.74476, 44.26783],
					[-114.74619, 44.26777],
					[-114.74679, 44.26772],
					[-114.74687, 44.26772],
					[-114.74762, 44.26764],
					[-114.74814, 44.26761],
					[-114.74862, 44.26762],
					[-114.74888, 44.26766],
					[-114.75078, 44.26806],
					[-114.7513, 44.26814],
					[-114.75253, 44.26838],
					[-114.75272, 44.26841],
					[-114.75289, 44.26842],
					[-114.75325, 44.26842],
					[-114.7534, 44.26841],
					[-114.75354, 44.26839],
					[-114.7541, 44.26826],
					[-114.75601, 44.26762],
					[-114.75619, 44.26757],
					[-114.7562, 44.26757],
					[-114.75647, 44.26747],
					[-114.75709, 44.26721],
					[-114.75727, 44.26712],
					[-114.75737, 44.26708],
					[-114.75784, 44.26682],
					[-114.75821, 44.26659],
					[-114.75825, 44.26656],
					[-114.75869, 44.26628],
					[-114.7592, 44.26598],
					[-114.75921, 44.26598],
					[-114.75942, 44.26588],
					[-114.75992, 44.26571],
					[-114.76035, 44.26551],
					[-114.7607, 44.26532],
					[-114.76091, 44.26519],
					[-114.76105, 44.26508],
					[-114.76141, 44.26487],
					[-114.76186, 44.26469],
					[-114.76214, 44.26462],
					[-114.76215, 44.26461],
					[-114.76228, 44.26458],
					[-114.76272, 44.26451],
					[-114.76285, 44.26447],
					[-114.76286, 44.26447],
					[-114.76297, 44.26444],
					[-114.76357, 44.26418],
					[-114.76379, 44.26407],
					[-114.76412, 44.26395],
					[-114.76417, 44.26394],
					[-114.7642, 44.26393],
					[-114.76433, 44.2639],
					[-114.76461, 44.26387],
					[-114.76477, 44.26387],
					[-114.76489, 44.26386],
					[-114.76516, 44.26388],
					[-114.76529, 44.26388],
					[-114.76558, 44.26392],
					[-114.76609, 44.26397],
					[-114.76658, 44.26405],
					[-114.76768, 44.2642],
					[-114.76831, 44.26426],
					[-114.76888, 44.26429],
					[-114.76907, 44.26429],
					[-114.76947, 44.26426],
					[-114.77009, 44.26417],
					[-114.77012, 44.26416],
					[-114.77048, 44.26412],
					[-114.77087, 44.26412],
					[-114.77096, 44.26411],
					[-114.77112, 44.26412],
					[-114.77137, 44.26415],
					[-114.77149, 44.26418],
					[-114.77161, 44.2642],
					[-114.77249, 44.26439],
					[-114.7727, 44.26442],
					[-114.77284, 44.26443],
					[-114.77305, 44.26443],
					[-114.77339, 44.26441],
					[-114.77357, 44.26439],
					[-114.77359, 44.26439],
					[-114.77388, 44.26434],
					[-114.77427, 44.26423],
					[-114.77438, 44.26417],
					[-114.77451, 44.26411],
					[-114.77464, 44.26403],
					[-114.77486, 44.26387],
					[-114.77487, 44.26387],
					[-114.77531, 44.26354],
					[-114.77538, 44.26348],
					[-114.77556, 44.26335],
					[-114.77573, 44.26324],
					[-114.77603, 44.26308],
					[-114.77634, 44.26297],
					[-114.77651, 44.26293],
					[-114.77672, 44.26287],
					[-114.77719, 44.26281],
					[-114.77734, 44.2628],
					[-114.77755, 44.2628],
					[-114.77784, 44.26283],
					[-114.77809, 44.26291],
					[-114.77828, 44.263],
					[-114.77855, 44.26318],
					[-114.77867, 44.26328],
					[-114.77911, 44.26369],
					[-114.77916, 44.26375],
					[-114.77956, 44.26415],
					[-114.77979, 44.26436],
					[-114.77993, 44.26447],
					[-114.78059, 44.26491],
					[-114.78116, 44.26524],
					[-114.78117, 44.26525],
					[-114.78137, 44.26536],
					[-114.78145, 44.26541],
					[-114.78191, 44.26564],
					[-114.78222, 44.26578],
					[-114.78268, 44.26595],
					[-114.78384, 44.26633],
					[-114.78402, 44.26637],
					[-114.78416, 44.26641],
					[-114.78443, 44.26646],
					[-114.7845, 44.26646],
					[-114.78475, 44.26648],
					[-114.78492, 44.26648],
					[-114.78522, 44.26645],
					[-114.7854, 44.2664],
					[-114.78595, 44.26614],
					[-114.78733, 44.26544],
					[-114.78764, 44.26527],
					[-114.78788, 44.2651],
					[-114.78799, 44.26501],
					[-114.78822, 44.26477],
					[-114.78836, 44.2646],
					[-114.78871, 44.26403],
					[-114.78874, 44.26399],
					[-114.78883, 44.26384],
					[-114.78892, 44.26372],
					[-114.78914, 44.26349],
					[-114.78922, 44.26343],
					[-114.78931, 44.26338],
					[-114.78945, 44.26332],
					[-114.78958, 44.26328],
					[-114.78967, 44.26327],
					[-114.78975, 44.26325],
					[-114.79004, 44.26323],
					[-114.79045, 44.26327],
					[-114.79146, 44.26348],
					[-114.79183, 44.26352],
					[-114.79191, 44.26352],
					[-114.792, 44.26353],
					[-114.79263, 44.26352],
					[-114.79297, 44.2635],
					[-114.79402, 44.26337],
					[-114.79431, 44.26332],
					[-114.79465, 44.26324],
					[-114.79482, 44.26319],
					[-114.795, 44.26312],
					[-114.79506, 44.26309],
					[-114.79514, 44.26304],
					[-114.79518, 44.26301],
					[-114.79528, 44.26291],
					[-114.79592, 44.26217],
					[-114.79609, 44.26196],
					[-114.79661, 44.26138],
					[-114.79688, 44.26114],
					[-114.7969, 44.26113],
					[-114.79703, 44.26103],
					[-114.79717, 44.26094],
					[-114.79742, 44.26083],
					[-114.79773, 44.26076],
					[-114.79818, 44.26073],
					[-114.79824, 44.26073],
					[-114.79851, 44.26075],
					[-114.79867, 44.26077],
					[-114.79907, 44.26086],
					[-114.79947, 44.26099],
					[-114.79962, 44.26106],
					[-114.79965, 44.26107],
					[-114.79978, 44.26115],
					[-114.80015, 44.26135],
					[-114.8003, 44.26144],
					[-114.80042, 44.26154],
					[-114.80053, 44.26162],
					[-114.80077, 44.26183],
					[-114.80127, 44.26242],
					[-114.80158, 44.26271],
					[-114.80167, 44.26277],
					[-114.80178, 44.26286],
					[-114.8021, 44.26304],
					[-114.80241, 44.26316],
					[-114.80308, 44.26335],
					[-114.80826, 44.26468],
					[-114.80827, 44.26468],
					[-114.80874, 44.26481],
					[-114.80875, 44.26482],
					[-114.80894, 44.26487],
					[-114.80934, 44.26495],
					[-114.80966, 44.26499],
					[-114.81015, 44.265],
					[-114.81056, 44.26497],
					[-114.81098, 44.26488],
					[-114.81141, 44.26476],
					[-114.81175, 44.26463],
					[-114.81184, 44.26459],
					[-114.81185, 44.26459],
					[-114.81205, 44.26448],
					[-114.81213, 44.26443],
					[-114.81223, 44.26435],
					[-114.81228, 44.26432],
					[-114.81248, 44.26415],
					[-114.81282, 44.26389],
					[-114.81291, 44.26383],
					[-114.81303, 44.26376],
					[-114.81326, 44.26365],
					[-114.8135, 44.26357],
					[-114.81359, 44.26355],
					[-114.81381, 44.26352],
					[-114.81398, 44.26351],
					[-114.81414, 44.26351],
					[-114.81431, 44.26352],
					[-114.81478, 44.26361],
					[-114.81555, 44.2638],
					[-114.81676, 44.26404],
					[-114.81709, 44.26406],
					[-114.81721, 44.26406],
					[-114.81726, 44.26407],
					[-114.8174, 44.26406],
					[-114.81746, 44.26406],
					[-114.81771, 44.26404],
					[-114.81811, 44.26397],
					[-114.81833, 44.2639],
					[-114.81863, 44.26378],
					[-114.81881, 44.26369],
					[-114.81887, 44.26365],
					[-114.81896, 44.2636],
					[-114.81912, 44.26347],
					[-114.81929, 44.26329],
					[-114.81942, 44.26313],
					[-114.82005, 44.26221],
					[-114.82025, 44.262],
					[-114.82045, 44.26183],
					[-114.82052, 44.26179],
					[-114.82073, 44.26169],
					[-114.82085, 44.26166],
					[-114.82086, 44.26166],
					[-114.82101, 44.26162],
					[-114.8212, 44.26159],
					[-114.8218, 44.26152],
					[-114.82208, 44.2615],
					[-114.8222, 44.26148],
					[-114.82265, 44.26144],
					[-114.82284, 44.2614],
					[-114.82304, 44.26137],
					[-114.82321, 44.26133],
					[-114.82365, 44.2612],
					[-114.82416, 44.26103],
					[-114.82442, 44.26093],
					[-114.8246, 44.26088],
					[-114.82527, 44.26073],
					[-114.82544, 44.26071],
					[-114.82586, 44.26068],
					[-114.82662, 44.26068],
					[-114.82706, 44.26072],
					[-114.82728, 44.26076],
					[-114.82739, 44.26079],
					[-114.82754, 44.26082],
					[-114.82777, 44.26088],
					[-114.82799, 44.2609],
					[-114.82823, 44.2609],
					[-114.82862, 44.26088],
					[-114.82896, 44.26084],
					[-114.82917, 44.2608],
					[-114.82933, 44.26075],
					[-114.82959, 44.26065],
					[-114.83002, 44.26041],
					[-114.83017, 44.2603],
					[-114.83083, 44.25971],
					[-114.83098, 44.25959],
					[-114.83104, 44.25955],
					[-114.83123, 44.25945],
					[-114.83143, 44.25939],
					[-114.83161, 44.25935],
					[-114.83187, 44.25931],
					[-114.83283, 44.25921],
					[-114.83371, 44.25916],
					[-114.8342, 44.25919],
					[-114.83433, 44.25921],
					[-114.8344, 44.25923],
					[-114.83462, 44.25931],
					[-114.83505, 44.25956],
					[-114.83536, 44.25979],
					[-114.83559, 44.26002],
					[-114.83613, 44.26069],
					[-114.8363, 44.26087],
					[-114.83643, 44.26098],
					[-114.83669, 44.26112],
					[-114.83693, 44.26119],
					[-114.83714, 44.26124],
					[-114.83735, 44.26128],
					[-114.83785, 44.26134],
					[-114.83803, 44.26135],
					[-114.8385, 44.2614],
					[-114.83853, 44.2614],
					[-114.83894, 44.26146],
					[-114.83984, 44.26166],
					[-114.84027, 44.26178],
					[-114.84054, 44.26187],
					[-114.84103, 44.26206],
					[-114.84166, 44.26237],
					[-114.84209, 44.26261],
					[-114.84227, 44.26272],
					[-114.8425, 44.26288],
					[-114.84274, 44.26307],
					[-114.84312, 44.26342],
					[-114.84333, 44.26364],
					[-114.84377, 44.26416],
					[-114.84387, 44.26429],
					[-114.84402, 44.26453],
					[-114.84432, 44.26511],
					[-114.84463, 44.26577],
					[-114.84468, 44.26589],
					[-114.84481, 44.26612],
					[-114.84504, 44.26647],
					[-114.84513, 44.26658],
					[-114.84537, 44.26683],
					[-114.84563, 44.26707],
					[-114.84587, 44.26723],
					[-114.84598, 44.26729],
					[-114.84611, 44.26735],
					[-114.84632, 44.26743],
					[-114.8467, 44.26753],
					[-114.84696, 44.26757],
					[-114.84732, 44.26757],
					[-114.84754, 44.26756],
					[-114.84776, 44.26753],
					[-114.84785, 44.26751],
					[-114.84821, 44.26739],
					[-114.84829, 44.26735],
					[-114.84864, 44.26715],
					[-114.85036, 44.26609],
					[-114.85071, 44.26586],
					[-114.85227, 44.26491],
					[-114.85294, 44.26453],
					[-114.85356, 44.26423],
					[-114.85431, 44.2639],
					[-114.85473, 44.2637],
					[-114.85507, 44.26348],
					[-114.85527, 44.26332],
					[-114.85538, 44.26322],
					[-114.85557, 44.26302],
					[-114.85571, 44.26285],
					[-114.85589, 44.2626],
					[-114.85614, 44.26218],
					[-114.85621, 44.26205],
					[-114.85645, 44.2615],
					[-114.8566, 44.26106],
					[-114.85669, 44.26059],
					[-114.85674, 44.2601],
					[-114.85674, 44.25986],
					[-114.85675, 44.25969],
					[-114.85675, 44.25924],
					[-114.8569, 44.25648],
					[-114.8569, 44.2563],
					[-114.85692, 44.2559],
					[-114.85696, 44.25562],
					[-114.85702, 44.25539],
					[-114.85709, 44.2552],
					[-114.85721, 44.25497],
					[-114.85728, 44.25489],
					[-114.85734, 44.25481],
					[-114.85744, 44.25471],
					[-114.85753, 44.25463],
					[-114.85756, 44.25461],
					[-114.85771, 44.25449],
					[-114.85796, 44.25432],
					[-114.85847, 44.25404],
					[-114.85914, 44.25371],
					[-114.85941, 44.25359],
					[-114.8598, 44.25344],
					[-114.86031, 44.2533],
					[-114.86065, 44.25323],
					[-114.86081, 44.25321],
					[-114.86098, 44.25318],
					[-114.86467, 44.25291],
					[-114.86508, 44.25283],
					[-114.8654, 44.25275],
					[-114.86567, 44.25267],
					[-114.86599, 44.25256],
					[-114.86619, 44.25248],
					[-114.86679, 44.2522],
					[-114.86692, 44.25213],
					[-114.86713, 44.252],
					[-114.86732, 44.25186],
					[-114.86764, 44.25156],
					[-114.86844, 44.25074],
					[-114.87144, 44.24796],
					[-114.87162, 44.24782],
					[-114.87183, 44.24768],
					[-114.87201, 44.24759],
					[-114.87207, 44.24757],
					[-114.87221, 44.24751],
					[-114.87241, 44.24744],
					[-114.87258, 44.24739],
					[-114.87282, 44.24735],
					[-114.87294, 44.24734],
					[-114.87357, 44.24735],
					[-114.87391, 44.24737],
					[-114.87667, 44.24742],
					[-114.87686, 44.24744],
					[-114.877, 44.24744],
					[-114.87715, 44.24745],
					[-114.8773, 44.24747],
					[-114.87733, 44.24747],
					[-114.8774, 44.24748],
					[-114.87764, 44.24753],
					[-114.87772, 44.24754],
					[-114.87863, 44.24784],
					[-114.88013, 44.24837],
					[-114.88031, 44.24842],
					[-114.88059, 44.24852],
					[-114.88077, 44.24857],
					[-114.881, 44.24861],
					[-114.88136, 44.24864],
					[-114.88234, 44.24862],
					[-114.88273, 44.24859],
					[-114.88314, 44.24854],
					[-114.88331, 44.24851],
					[-114.8834, 44.24848],
					[-114.88348, 44.24846],
					[-114.8836, 44.24842],
					[-114.88385, 44.2483],
					[-114.8841, 44.24816],
					[-114.88416, 44.24811],
					[-114.88422, 44.24807],
					[-114.88426, 44.24803],
					[-114.8843, 44.248],
					[-114.88443, 44.24787],
					[-114.88458, 44.24768],
					[-114.88471, 44.2475],
					[-114.88509, 44.24691],
					[-114.88514, 44.2468],
					[-114.88543, 44.2463],
					[-114.88562, 44.24593],
					[-114.88564, 44.24587],
					[-114.88568, 44.24579],
					[-114.88571, 44.24569],
					[-114.88587, 44.24525],
					[-114.88591, 44.24517],
					[-114.88593, 44.24512],
					[-114.88603, 44.24497],
					[-114.88621, 44.24479],
					[-114.88627, 44.24475],
					[-114.88629, 44.24473],
					[-114.88632, 44.24472],
					[-114.8864, 44.24467],
					[-114.8866, 44.24458],
					[-114.88709, 44.24443],
					[-114.88763, 44.2443],
					[-114.88863, 44.24402],
					[-114.88872, 44.24398],
					[-114.88886, 44.24393],
					[-114.88921, 44.24376],
					[-114.88933, 44.24371],
					[-114.88942, 44.24366],
					[-114.88969, 44.24355],
					[-114.89037, 44.24333],
					[-114.89093, 44.24322],
					[-114.89133, 44.24317],
					[-114.8917, 44.24315],
					[-114.89214, 44.24315],
					[-114.8928, 44.2432],
					[-114.89369, 44.24336],
					[-114.89501, 44.24357],
					[-114.89514, 44.24358],
					[-114.8953, 44.24358],
					[-114.89575, 44.24355],
					[-114.89599, 44.2435],
					[-114.896, 44.2435],
					[-114.89616, 44.24346],
					[-114.90025, 44.24218],
					[-114.90037, 44.24215],
					[-114.90368, 44.24111],
					[-114.90411, 44.241],
					[-114.90426, 44.24097],
					[-114.90478, 44.24091],
					[-114.90506, 44.24089],
					[-114.90537, 44.24085],
					[-114.90569, 44.24083],
					[-114.90602, 44.24077],
					[-114.90636, 44.24069],
					[-114.90666, 44.24058],
					[-114.90691, 44.24046],
					[-114.90748, 44.24012],
					[-114.90762, 44.24002],
					[-114.91142, 44.23755],
					[-114.91178, 44.23733],
					[-114.9118, 44.23731],
					[-114.91317, 44.23643],
					[-114.91329, 44.23636],
					[-114.91336, 44.23631],
					[-114.91466, 44.23548],
					[-114.91718, 44.23414],
					[-114.91721, 44.23413],
					[-114.91744, 44.23401],
					[-114.91768, 44.23386],
					[-114.91793, 44.23368],
					[-114.918, 44.23362],
					[-114.91803, 44.2336],
					[-114.91854, 44.23319],
					[-114.91952, 44.2325],
					[-114.92159, 44.23137],
					[-114.9216, 44.23136],
					[-114.92206, 44.2311],
					[-114.92243, 44.23092],
					[-114.92254, 44.23086],
					[-114.92278, 44.23076],
					[-114.92283, 44.23075],
					[-114.92302, 44.23068],
					[-114.92309, 44.23066],
					[-114.92359, 44.23057],
					[-114.92439, 44.23052],
					[-114.92462, 44.23049],
					[-114.9247, 44.23047],
					[-114.92477, 44.23046],
					[-114.92516, 44.23034],
					[-114.92533, 44.23026],
					[-114.92543, 44.23023],
					[-114.9265, 44.22968],
					[-114.92677, 44.22952],
					[-114.92682, 44.22948],
					[-114.92689, 44.22944],
					[-114.92706, 44.2293],
					[-114.9272, 44.22916],
					[-114.92756, 44.22875],
					[-114.92761, 44.22871],
					[-114.92851, 44.22775],
					[-114.9286, 44.22767],
					[-114.9289, 44.22736],
					[-114.92899, 44.22724],
					[-114.92912, 44.22703],
					[-114.92917, 44.22691],
					[-114.92921, 44.22675],
					[-114.92923, 44.22663],
					[-114.92923, 44.22658],
					[-114.92924, 44.22651],
					[-114.92924, 44.22632],
					[-114.92922, 44.22616],
					[-114.9292, 44.22607],
					[-114.92918, 44.22592],
					[-114.92911, 44.22556],
					[-114.92881, 44.22428],
					[-114.92881, 44.22426],
					[-114.92872, 44.22392],
					[-114.92866, 44.22356],
					[-114.92864, 44.22349],
					[-114.92864, 44.22343],
					[-114.92863, 44.22335],
					[-114.92861, 44.22303],
					[-114.92866, 44.22244],
					[-114.9287, 44.2222],
					[-114.9287, 44.22217],
					[-114.92895, 44.22093],
					[-114.92897, 44.22086],
					[-114.92911, 44.22021],
					[-114.92912, 44.22018],
					[-114.92941, 44.2189],
					[-114.9295, 44.21844],
					[-114.92952, 44.21837],
					[-114.92954, 44.21823],
					[-114.92959, 44.218],
					[-114.92962, 44.21782],
					[-114.92994, 44.2167],
				],
			},
			id: "835d55b4-31e6-40d9-ab52-d63687412d80",
		},
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		tessellate: -1,
		// 		shape: "Marker",
		// 		name: "Salmon, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-113.89063, 45.16857],
		// 	},
		// 	id: "74a30483-e3e3-4528-8bac-eb2e23af866c",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		tessellate: -1,
		// 		shape: "Marker",
		// 		name: "Stanley, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.92994, 44.2167],
		// 	},
		// 	id: "ff83e570-572f-4ed4-88ce-02d995c3935c",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Challis, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-114.2317308, 44.5046445],
		// 	},
		// 	id: "4ee169b2-4956-42cf-a978-3debadb7350c",
		// },
	],
};

export default SalmonRiver;
