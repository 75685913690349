import React, { useState } from "react";
import { IconArrowLeft } from "../../images/icons";
import Account from "./Account";
import SaveItinerary from "./SaveItinerary";

function Dashboard(props) {
    const [currentView, setCurrentView] = useState('itinerary');
    return (
        <>
            <div className="itinerary-tabs">
                <a onClick={() => { setCurrentView('itinerary') }} className={currentView === 'itinerary' ? 'btn active' : 'btn'}>My Itinerary</a>
                <a onClick={() => { setCurrentView('traveltips') }} className={currentView === 'traveltips' ? 'btn active' : 'btn'}>Saved Travel Tips</a>
            </div>
            <div className="dashboard-wrapper">
                <div className={`dashboard-container ${props.showAccount ? ' my-account' : ' save-itinerary'}`}>
                    <div className="return-to-map">
                        <a onClick={props.passedFunctions.toggleMap} className="mapview-btn return-to-map-btn btn btn-small btn-white-shadow" title="Return to map" aria-label="Return to map">
                            <IconArrowLeft /> Return to Map
                        </a>
                    </div>
                    {props.showAccount ? <Account clearFilters={props.passedFunctions.clearFilters} toggleDashboard={props.passedFunctions.toggleMap} /> : <SaveItinerary currentView={currentView}/>}
                </div>
            </div>
        </>
    );
}

export default Dashboard;