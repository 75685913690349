import React from "react";

function IconSearch() {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="16.207"
				height="16.207"
				viewBox="0 0 16.207 16.207"
			>
				<g id="search" transform="translate(-54.756 -54.756)">
					<circle
						id="Ellipse_2"
						data-name="Ellipse 2"
						cx="6.667"
						cy="6.667"
						r="6.667"
						transform="translate(55.256 55.256)"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
					<line
						id="Line_4"
						data-name="Line 4"
						x1="3.625"
						y1="3.625"
						transform="translate(66.631 66.631)"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
				</g>
			</svg>
		</>
	);
}

export default IconSearch;
