const ElkRiver = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Elk River Backcountry Byway",
				description: "Elk River Backcountry Byway is an off-the-beaten-path adventure. The route crosses Idaho's tallest bridge–the Dent Bridge, spanning the Dworshak Reservoir–and winds its way through the vast evergreen forests of the Clearwater Mountains. Along the way, stop at Dworshak State Park for fishing, hiking, biking and camping. Keep an eye out for elk, bears and small woodland creatures as you continue on to the quaint community of Elk River.",
				link: "https://visitidaho.org/things-to-do/road-trips/elk-river-backcountry-scenic-byway/",
				route: [
					"Orofino", 
					"Elk River", 
					"Bovill"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
                    [
                      -116.25496,
                      46.47939
                    ],
                    [
                      -116.25507,
                      46.4796
                    ],
                    [
                      -116.25594,
                      46.4796
                    ],
                    [
                      -116.25604,
                      46.47958
                    ],
                    [
                      -116.25641,
                      46.47943
                    ],
                    [
                      -116.25761,
                      46.48059
                    ],
                    [
                      -116.25746,
                      46.4807
                    ],
                    [
                      -116.25762,
                      46.48086
                    ],
                    [
                      -116.25773,
                      46.48095
                    ],
                    [
                      -116.25774,
                      46.48097
                    ],
                    [
                      -116.25787,
                      46.48108
                    ],
                    [
                      -116.25806,
                      46.4813
                    ],
                    [
                      -116.25817,
                      46.48153
                    ],
                    [
                      -116.25819,
                      46.48167
                    ],
                    [
                      -116.25821,
                      46.4828
                    ],
                    [
                      -116.25808,
                      46.48394
                    ],
                    [
                      -116.25807,
                      46.48395
                    ],
                    [
                      -116.25801,
                      46.48429
                    ],
                    [
                      -116.258,
                      46.4845
                    ],
                    [
                      -116.25802,
                      46.48464
                    ],
                    [
                      -116.25818,
                      46.4851
                    ],
                    [
                      -116.25827,
                      46.48553
                    ],
                    [
                      -116.25828,
                      46.48598
                    ],
                    [
                      -116.25827,
                      46.48599
                    ],
                    [
                      -116.25813,
                      46.48637
                    ],
                    [
                      -116.25792,
                      46.48661
                    ],
                    [
                      -116.25789,
                      46.48666
                    ],
                    [
                      -116.25788,
                      46.48669
                    ],
                    [
                      -116.25788,
                      46.48675
                    ],
                    [
                      -116.25792,
                      46.48694
                    ],
                    [
                      -116.25793,
                      46.48707
                    ],
                    [
                      -116.25792,
                      46.48719
                    ],
                    [
                      -116.25786,
                      46.48733
                    ],
                    [
                      -116.25774,
                      46.48748
                    ],
                    [
                      -116.25773,
                      46.4875
                    ],
                    [
                      -116.25755,
                      46.48767
                    ],
                    [
                      -116.25738,
                      46.48778
                    ],
                    [
                      -116.2572,
                      46.48786
                    ],
                    [
                      -116.25714,
                      46.48787
                    ],
                    [
                      -116.25709,
                      46.48789
                    ],
                    [
                      -116.25693,
                      46.48791
                    ],
                    [
                      -116.2561,
                      46.48792
                    ],
                    [
                      -116.25564,
                      46.48799
                    ],
                    [
                      -116.25555,
                      46.48804
                    ],
                    [
                      -116.25533,
                      46.48813
                    ],
                    [
                      -116.25522,
                      46.4882
                    ],
                    [
                      -116.255,
                      46.48841
                    ],
                    [
                      -116.25477,
                      46.48867
                    ],
                    [
                      -116.25428,
                      46.48904
                    ],
                    [
                      -116.25413,
                      46.48927
                    ],
                    [
                      -116.2541,
                      46.48938
                    ],
                    [
                      -116.2541,
                      46.48941
                    ],
                    [
                      -116.25409,
                      46.48945
                    ],
                    [
                      -116.25409,
                      46.48952
                    ],
                    [
                      -116.25411,
                      46.48956
                    ],
                    [
                      -116.25415,
                      46.4896
                    ],
                    [
                      -116.25416,
                      46.48962
                    ],
                    [
                      -116.25424,
                      46.48969
                    ],
                    [
                      -116.25457,
                      46.48988
                    ],
                    [
                      -116.25472,
                      46.49001
                    ],
                    [
                      -116.25476,
                      46.49008
                    ],
                    [
                      -116.2548,
                      46.49012
                    ],
                    [
                      -116.25484,
                      46.49021
                    ],
                    [
                      -116.25484,
                      46.4903
                    ],
                    [
                      -116.25486,
                      46.49033
                    ],
                    [
                      -116.25486,
                      46.49038
                    ],
                    [
                      -116.25482,
                      46.49044
                    ],
                    [
                      -116.25474,
                      46.49053
                    ],
                    [
                      -116.25461,
                      46.49061
                    ],
                    [
                      -116.2546,
                      46.49061
                    ],
                    [
                      -116.25448,
                      46.49065
                    ],
                    [
                      -116.25415,
                      46.4907
                    ],
                    [
                      -116.25332,
                      46.49072
                    ],
                    [
                      -116.25272,
                      46.49067
                    ],
                    [
                      -116.25169,
                      46.49068
                    ],
                    [
                      -116.25154,
                      46.49071
                    ],
                    [
                      -116.25137,
                      46.49078
                    ],
                    [
                      -116.25111,
                      46.49096
                    ],
                    [
                      -116.25096,
                      46.49109
                    ],
                    [
                      -116.25079,
                      46.49127
                    ],
                    [
                      -116.25082,
                      46.49137
                    ],
                    [
                      -116.25091,
                      46.49146
                    ],
                    [
                      -116.25093,
                      46.49147
                    ],
                    [
                      -116.25101,
                      46.49155
                    ],
                    [
                      -116.25153,
                      46.49198
                    ],
                    [
                      -116.25163,
                      46.49209
                    ],
                    [
                      -116.25173,
                      46.49225
                    ],
                    [
                      -116.25182,
                      46.49246
                    ],
                    [
                      -116.25184,
                      46.49254
                    ],
                    [
                      -116.25169,
                      46.49253
                    ],
                    [
                      -116.25162,
                      46.49251
                    ],
                    [
                      -116.25156,
                      46.49248
                    ],
                    [
                      -116.25152,
                      46.49245
                    ],
                    [
                      -116.25147,
                      46.49236
                    ],
                    [
                      -116.25147,
                      46.49235
                    ],
                    [
                      -116.25141,
                      46.49222
                    ],
                    [
                      -116.25138,
                      46.49218
                    ],
                    [
                      -116.25118,
                      46.492
                    ],
                    [
                      -116.25102,
                      46.49192
                    ],
                    [
                      -116.25084,
                      46.49186
                    ],
                    [
                      -116.24967,
                      46.49163
                    ],
                    [
                      -116.2493,
                      46.49152
                    ],
                    [
                      -116.24929,
                      46.49152
                    ],
                    [
                      -116.2487,
                      46.49127
                    ],
                    [
                      -116.24864,
                      46.49126
                    ],
                    [
                      -116.2484,
                      46.49127
                    ],
                    [
                      -116.2483,
                      46.4913
                    ],
                    [
                      -116.24796,
                      46.49148
                    ],
                    [
                      -116.24793,
                      46.49149
                    ],
                    [
                      -116.2479,
                      46.49151
                    ],
                    [
                      -116.24761,
                      46.49159
                    ],
                    [
                      -116.24741,
                      46.49162
                    ],
                    [
                      -116.24597,
                      46.49172
                    ],
                    [
                      -116.24566,
                      46.49171
                    ],
                    [
                      -116.24546,
                      46.49166
                    ],
                    [
                      -116.24528,
                      46.49158
                    ],
                    [
                      -116.24503,
                      46.49144
                    ],
                    [
                      -116.24497,
                      46.49142
                    ],
                    [
                      -116.24497,
                      46.49141
                    ],
                    [
                      -116.24479,
                      46.49134
                    ],
                    [
                      -116.24424,
                      46.49124
                    ],
                    [
                      -116.24419,
                      46.49124
                    ],
                    [
                      -116.24343,
                      46.491
                    ],
                    [
                      -116.24323,
                      46.49097
                    ],
                    [
                      -116.24301,
                      46.49096
                    ],
                    [
                      -116.24273,
                      46.491
                    ],
                    [
                      -116.24264,
                      46.49103
                    ],
                    [
                      -116.24239,
                      46.49115
                    ],
                    [
                      -116.24224,
                      46.49125
                    ],
                    [
                      -116.24204,
                      46.4913
                    ],
                    [
                      -116.24185,
                      46.49133
                    ],
                    [
                      -116.24153,
                      46.49133
                    ],
                    [
                      -116.24052,
                      46.49124
                    ],
                    [
                      -116.24033,
                      46.49117
                    ],
                    [
                      -116.24032,
                      46.49116
                    ],
                    [
                      -116.24019,
                      46.49111
                    ],
                    [
                      -116.23947,
                      46.49076
                    ],
                    [
                      -116.23928,
                      46.4907
                    ],
                    [
                      -116.23886,
                      46.49067
                    ],
                    [
                      -116.23882,
                      46.49066
                    ],
                    [
                      -116.23875,
                      46.49066
                    ],
                    [
                      -116.23844,
                      46.49059
                    ],
                    [
                      -116.23801,
                      46.49046
                    ],
                    [
                      -116.23787,
                      46.49045
                    ],
                    [
                      -116.23759,
                      46.49049
                    ],
                    [
                      -116.23744,
                      46.49054
                    ],
                    [
                      -116.237,
                      46.49075
                    ],
                    [
                      -116.23642,
                      46.49097
                    ],
                    [
                      -116.23624,
                      46.491
                    ],
                    [
                      -116.23612,
                      46.491
                    ],
                    [
                      -116.23594,
                      46.49097
                    ],
                    [
                      -116.23562,
                      46.49084
                    ],
                    [
                      -116.23559,
                      46.49082
                    ],
                    [
                      -116.23539,
                      46.49076
                    ],
                    [
                      -116.23535,
                      46.49074
                    ],
                    [
                      -116.23504,
                      46.49068
                    ],
                    [
                      -116.23465,
                      46.49066
                    ],
                    [
                      -116.23419,
                      46.4907
                    ],
                    [
                      -116.23393,
                      46.49078
                    ],
                    [
                      -116.2339,
                      46.49078
                    ],
                    [
                      -116.23384,
                      46.4908
                    ],
                    [
                      -116.23378,
                      46.49081
                    ],
                    [
                      -116.23373,
                      46.49083
                    ],
                    [
                      -116.23327,
                      46.49088
                    ],
                    [
                      -116.23299,
                      46.49086
                    ],
                    [
                      -116.23294,
                      46.49084
                    ],
                    [
                      -116.23287,
                      46.49083
                    ],
                    [
                      -116.23286,
                      46.49082
                    ],
                    [
                      -116.23239,
                      46.4907
                    ],
                    [
                      -116.23237,
                      46.49069
                    ],
                    [
                      -116.23232,
                      46.49068
                    ],
                    [
                      -116.23193,
                      46.49069
                    ],
                    [
                      -116.23157,
                      46.49074
                    ],
                    [
                      -116.2311,
                      46.49085
                    ],
                    [
                      -116.23046,
                      46.49106
                    ],
                    [
                      -116.22965,
                      46.49126
                    ],
                    [
                      -116.22914,
                      46.49135
                    ],
                    [
                      -116.22851,
                      46.49142
                    ],
                    [
                      -116.22667,
                      46.49193
                    ],
                    [
                      -116.22598,
                      46.4922
                    ],
                    [
                      -116.22595,
                      46.4922
                    ],
                    [
                      -116.2259,
                      46.49222
                    ],
                    [
                      -116.22483,
                      46.49244
                    ],
                    [
                      -116.2237,
                      46.4926
                    ],
                    [
                      -116.22266,
                      46.49283
                    ],
                    [
                      -116.22242,
                      46.49294
                    ],
                    [
                      -116.22215,
                      46.4931
                    ],
                    [
                      -116.22168,
                      46.49342
                    ],
                    [
                      -116.22153,
                      46.4936
                    ],
                    [
                      -116.22147,
                      46.4937
                    ],
                    [
                      -116.2214,
                      46.49391
                    ],
                    [
                      -116.22135,
                      46.49414
                    ],
                    [
                      -116.2213,
                      46.49423
                    ],
                    [
                      -116.22122,
                      46.49433
                    ],
                    [
                      -116.22099,
                      46.49453
                    ],
                    [
                      -116.2206,
                      46.49479
                    ],
                    [
                      -116.22036,
                      46.49498
                    ],
                    [
                      -116.22004,
                      46.49527
                    ],
                    [
                      -116.21995,
                      46.49542
                    ],
                    [
                      -116.21978,
                      46.49581
                    ],
                    [
                      -116.21973,
                      46.49587
                    ],
                    [
                      -116.21973,
                      46.49588
                    ],
                    [
                      -116.21917,
                      46.49646
                    ],
                    [
                      -116.21906,
                      46.49654
                    ],
                    [
                      -116.2189,
                      46.49662
                    ],
                    [
                      -116.21859,
                      46.49674
                    ],
                    [
                      -116.21837,
                      46.49679
                    ],
                    [
                      -116.21747,
                      46.49711
                    ],
                    [
                      -116.21703,
                      46.49722
                    ],
                    [
                      -116.21623,
                      46.49737
                    ],
                    [
                      -116.21606,
                      46.49742
                    ],
                    [
                      -116.21594,
                      46.49748
                    ],
                    [
                      -116.21582,
                      46.49756
                    ],
                    [
                      -116.2157,
                      46.49769
                    ],
                    [
                      -116.21549,
                      46.49805
                    ],
                    [
                      -116.21547,
                      46.49812
                    ],
                    [
                      -116.21541,
                      46.49824
                    ],
                    [
                      -116.21539,
                      46.49837
                    ],
                    [
                      -116.21538,
                      46.49862
                    ],
                    [
                      -116.21547,
                      46.4989
                    ],
                    [
                      -116.21568,
                      46.49935
                    ],
                    [
                      -116.2157,
                      46.49936
                    ],
                    [
                      -116.21582,
                      46.49953
                    ],
                    [
                      -116.21656,
                      46.50021
                    ],
                    [
                      -116.21678,
                      46.50046
                    ],
                    [
                      -116.2169,
                      46.50065
                    ],
                    [
                      -116.21693,
                      46.5008
                    ],
                    [
                      -116.2169,
                      46.50094
                    ],
                    [
                      -116.21681,
                      46.50108
                    ],
                    [
                      -116.21674,
                      46.50113
                    ],
                    [
                      -116.21603,
                      46.50143
                    ],
                    [
                      -116.21407,
                      46.50206
                    ],
                    [
                      -116.21368,
                      46.50213
                    ],
                    [
                      -116.2132,
                      46.50227
                    ],
                    [
                      -116.21268,
                      46.50249
                    ],
                    [
                      -116.21253,
                      46.50259
                    ],
                    [
                      -116.21241,
                      46.5027
                    ],
                    [
                      -116.21231,
                      46.50282
                    ],
                    [
                      -116.21223,
                      46.50295
                    ],
                    [
                      -116.21217,
                      46.50308
                    ],
                    [
                      -116.21211,
                      46.50315
                    ],
                    [
                      -116.21201,
                      46.50349
                    ],
                    [
                      -116.21194,
                      46.50359
                    ],
                    [
                      -116.21175,
                      46.50375
                    ],
                    [
                      -116.21157,
                      46.50383
                    ],
                    [
                      -116.21131,
                      46.50392
                    ],
                    [
                      -116.21118,
                      46.50398
                    ],
                    [
                      -116.21088,
                      46.50419
                    ],
                    [
                      -116.21061,
                      46.50445
                    ],
                    [
                      -116.21057,
                      46.50452
                    ],
                    [
                      -116.21009,
                      46.50489
                    ],
                    [
                      -116.21003,
                      46.50495
                    ],
                    [
                      -116.20998,
                      46.50502
                    ],
                    [
                      -116.20993,
                      46.50523
                    ],
                    [
                      -116.20991,
                      46.50552
                    ],
                    [
                      -116.20961,
                      46.50672
                    ],
                    [
                      -116.20959,
                      46.50686
                    ],
                    [
                      -116.20959,
                      46.50708
                    ],
                    [
                      -116.20963,
                      46.50734
                    ],
                    [
                      -116.20967,
                      46.5075
                    ],
                    [
                      -116.20972,
                      46.50787
                    ],
                    [
                      -116.20975,
                      46.508
                    ],
                    [
                      -116.20976,
                      46.50822
                    ],
                    [
                      -116.2098,
                      46.50843
                    ],
                    [
                      -116.20987,
                      46.50864
                    ],
                    [
                      -116.2099,
                      46.50878
                    ],
                    [
                      -116.2099,
                      46.50906
                    ],
                    [
                      -116.20992,
                      46.50921
                    ],
                    [
                      -116.21004,
                      46.50955
                    ],
                    [
                      -116.21018,
                      46.50981
                    ],
                    [
                      -116.21019,
                      46.50982
                    ],
                    [
                      -116.21028,
                      46.50998
                    ],
                    [
                      -116.21044,
                      46.51021
                    ],
                    [
                      -116.2113,
                      46.51123
                    ],
                    [
                      -116.21201,
                      46.51219
                    ],
                    [
                      -116.2127,
                      46.51347
                    ],
                    [
                      -116.21282,
                      46.51421
                    ],
                    [
                      -116.21287,
                      46.51586
                    ],
                    [
                      -116.21291,
                      46.51625
                    ],
                    [
                      -116.21301,
                      46.51653
                    ],
                    [
                      -116.21305,
                      46.51678
                    ],
                    [
                      -116.21301,
                      46.51753
                    ],
                    [
                      -116.21299,
                      46.5176
                    ],
                    [
                      -116.21276,
                      46.51807
                    ],
                    [
                      -116.21271,
                      46.51828
                    ],
                    [
                      -116.21269,
                      46.51856
                    ],
                    [
                      -116.21264,
                      46.51885
                    ],
                    [
                      -116.21248,
                      46.51933
                    ],
                    [
                      -116.21229,
                      46.5197
                    ],
                    [
                      -116.21222,
                      46.51996
                    ],
                    [
                      -116.21219,
                      46.52021
                    ],
                    [
                      -116.21208,
                      46.52045
                    ],
                    [
                      -116.21203,
                      46.52052
                    ],
                    [
                      -116.21184,
                      46.52069
                    ],
                    [
                      -116.21154,
                      46.52088
                    ],
                    [
                      -116.21059,
                      46.52131
                    ],
                    [
                      -116.21036,
                      46.52145
                    ],
                    [
                      -116.21017,
                      46.52161
                    ],
                    [
                      -116.20996,
                      46.52186
                    ],
                    [
                      -116.20984,
                      46.52198
                    ],
                    [
                      -116.20945,
                      46.5223
                    ],
                    [
                      -116.20944,
                      46.52232
                    ],
                    [
                      -116.20932,
                      46.52244
                    ],
                    [
                      -116.20913,
                      46.52269
                    ],
                    [
                      -116.20889,
                      46.52293
                    ],
                    [
                      -116.2086,
                      46.52313
                    ],
                    [
                      -116.20847,
                      46.52324
                    ],
                    [
                      -116.2084,
                      46.52333
                    ],
                    [
                      -116.20836,
                      46.52336
                    ],
                    [
                      -116.20796,
                      46.52387
                    ],
                    [
                      -116.20757,
                      46.52429
                    ],
                    [
                      -116.20744,
                      46.5244
                    ],
                    [
                      -116.20744,
                      46.52441
                    ],
                    [
                      -116.20729,
                      46.52451
                    ],
                    [
                      -116.20713,
                      46.52459
                    ],
                    [
                      -116.20648,
                      46.52483
                    ],
                    [
                      -116.20631,
                      46.52491
                    ],
                    [
                      -116.20624,
                      46.52496
                    ],
                    [
                      -116.20602,
                      46.52516
                    ],
                    [
                      -116.20574,
                      46.5255
                    ],
                    [
                      -116.20566,
                      46.52563
                    ],
                    [
                      -116.20524,
                      46.52674
                    ],
                    [
                      -116.20518,
                      46.52703
                    ],
                    [
                      -116.20514,
                      46.52776
                    ],
                    [
                      -116.2051,
                      46.52804
                    ],
                    [
                      -116.20506,
                      46.52818
                    ],
                    [
                      -116.2049,
                      46.52852
                    ],
                    [
                      -116.20488,
                      46.52866
                    ],
                    [
                      -116.20488,
                      46.52874
                    ],
                    [
                      -116.20491,
                      46.52888
                    ],
                    [
                      -116.205,
                      46.52909
                    ],
                    [
                      -116.20502,
                      46.52923
                    ],
                    [
                      -116.20501,
                      46.52938
                    ],
                    [
                      -116.20499,
                      46.52945
                    ],
                    [
                      -116.20484,
                      46.5298
                    ],
                    [
                      -116.20477,
                      46.53037
                    ],
                    [
                      -116.20461,
                      46.53063
                    ],
                    [
                      -116.20414,
                      46.53111
                    ],
                    [
                      -116.20389,
                      46.53151
                    ],
                    [
                      -116.2038,
                      46.53178
                    ],
                    [
                      -116.20379,
                      46.53185
                    ],
                    [
                      -116.2038,
                      46.532
                    ],
                    [
                      -116.20384,
                      46.53214
                    ],
                    [
                      -116.20385,
                      46.53228
                    ],
                    [
                      -116.20384,
                      46.53242
                    ],
                    [
                      -116.20371,
                      46.53269
                    ],
                    [
                      -116.20353,
                      46.53288
                    ],
                    [
                      -116.20332,
                      46.53303
                    ],
                    [
                      -116.20316,
                      46.53312
                    ],
                    [
                      -116.20295,
                      46.53328
                    ],
                    [
                      -116.20286,
                      46.53341
                    ],
                    [
                      -116.20283,
                      46.53348
                    ],
                    [
                      -116.2028,
                      46.53363
                    ],
                    [
                      -116.20278,
                      46.53386
                    ],
                    [
                      -116.20266,
                      46.53407
                    ],
                    [
                      -116.20256,
                      46.53419
                    ],
                    [
                      -116.20201,
                      46.53469
                    ],
                    [
                      -116.20191,
                      46.53482
                    ],
                    [
                      -116.20157,
                      46.53539
                    ],
                    [
                      -116.20117,
                      46.53598
                    ],
                    [
                      -116.20111,
                      46.53603
                    ],
                    [
                      -116.20104,
                      46.53607
                    ],
                    [
                      -116.20085,
                      46.53611
                    ],
                    [
                      -116.18501,
                      46.53605
                    ],
                    [
                      -116.18397,
                      46.536
                    ],
                    [
                      -116.18012,
                      46.53602
                    ],
                    [
                      -116.17971,
                      46.5361
                    ],
                    [
                      -116.17942,
                      46.53619
                    ],
                    [
                      -116.17866,
                      46.53654
                    ],
                    [
                      -116.17807,
                      46.53687
                    ],
                    [
                      -116.17789,
                      46.53695
                    ],
                    [
                      -116.17656,
                      46.53735
                    ],
                    [
                      -116.17534,
                      46.53777
                    ],
                    [
                      -116.17479,
                      46.53801
                    ],
                    [
                      -116.17447,
                      46.53821
                    ],
                    [
                      -116.17355,
                      46.53896
                    ],
                    [
                      -116.17321,
                      46.53914
                    ],
                    [
                      -116.17179,
                      46.53967
                    ],
                    [
                      -116.17177,
                      46.53967
                    ],
                    [
                      -116.17111,
                      46.5399
                    ],
                    [
                      -116.17089,
                      46.53996
                    ],
                    [
                      -116.17082,
                      46.53997
                    ],
                    [
                      -116.17051,
                      46.54006
                    ],
                    [
                      -116.1702,
                      46.54011
                    ],
                    [
                      -116.16989,
                      46.54012
                    ],
                    [
                      -116.16867,
                      46.54008
                    ],
                    [
                      -116.16784,
                      46.54015
                    ],
                    [
                      -116.16725,
                      46.54026
                    ],
                    [
                      -116.16676,
                      46.54041
                    ],
                    [
                      -116.16675,
                      46.54041
                    ],
                    [
                      -116.16552,
                      46.54077
                    ],
                    [
                      -116.16482,
                      46.54089
                    ],
                    [
                      -116.16464,
                      46.54095
                    ],
                    [
                      -116.16456,
                      46.54099
                    ],
                    [
                      -116.16441,
                      46.54109
                    ],
                    [
                      -116.16409,
                      46.54138
                    ],
                    [
                      -116.16366,
                      46.54169
                    ],
                    [
                      -116.16324,
                      46.54191
                    ],
                    [
                      -116.16261,
                      46.54216
                    ],
                    [
                      -116.16245,
                      46.54225
                    ],
                    [
                      -116.16233,
                      46.54236
                    ],
                    [
                      -116.16222,
                      46.54249
                    ],
                    [
                      -116.16215,
                      46.54262
                    ],
                    [
                      -116.1621,
                      46.54276
                    ],
                    [
                      -116.16208,
                      46.5429
                    ],
                    [
                      -116.16207,
                      46.54315
                    ],
                    [
                      -116.1621,
                      46.54366
                    ],
                    [
                      -116.16209,
                      46.54402
                    ],
                    [
                      -116.16206,
                      46.5442
                    ],
                    [
                      -116.16205,
                      46.54443
                    ],
                    [
                      -116.16199,
                      46.5446
                    ],
                    [
                      -116.1619,
                      46.54475
                    ],
                    [
                      -116.16175,
                      46.54491
                    ],
                    [
                      -116.16155,
                      46.54506
                    ],
                    [
                      -116.16099,
                      46.54529
                    ],
                    [
                      -116.15985,
                      46.54559
                    ],
                    [
                      -116.1594,
                      46.54577
                    ],
                    [
                      -116.159,
                      46.54599
                    ],
                    [
                      -116.15872,
                      46.54618
                    ],
                    [
                      -116.15848,
                      46.54637
                    ],
                    [
                      -116.15826,
                      46.54667
                    ],
                    [
                      -116.15808,
                      46.54696
                    ],
                    [
                      -116.15778,
                      46.54735
                    ],
                    [
                      -116.15727,
                      46.54792
                    ],
                    [
                      -116.1571,
                      46.54806
                    ],
                    [
                      -116.15672,
                      46.54828
                    ],
                    [
                      -116.156,
                      46.54863
                    ],
                    [
                      -116.15573,
                      46.54874
                    ],
                    [
                      -116.15572,
                      46.54875
                    ],
                    [
                      -116.15509,
                      46.54897
                    ],
                    [
                      -116.15493,
                      46.54905
                    ],
                    [
                      -116.15471,
                      46.54919
                    ],
                    [
                      -116.1544,
                      46.54943
                    ],
                    [
                      -116.1525,
                      46.55142
                    ],
                    [
                      -116.15219,
                      46.55186
                    ],
                    [
                      -116.15207,
                      46.55206
                    ],
                    [
                      -116.152,
                      46.5523
                    ],
                    [
                      -116.15205,
                      46.55286
                    ],
                    [
                      -116.15201,
                      46.55429
                    ],
                    [
                      -116.15199,
                      46.55449
                    ],
                    [
                      -116.15207,
                      46.55641
                    ],
                    [
                      -116.15207,
                      46.55741
                    ],
                    [
                      -116.15199,
                      46.55893
                    ],
                    [
                      -116.15197,
                      46.55902
                    ],
                    [
                      -116.15197,
                      46.55907
                    ],
                    [
                      -116.15191,
                      46.55927
                    ],
                    [
                      -116.15185,
                      46.5594
                    ],
                    [
                      -116.15152,
                      46.5599
                    ],
                    [
                      -116.15064,
                      46.56108
                    ],
                    [
                      -116.15056,
                      46.56121
                    ],
                    [
                      -116.1505,
                      46.56135
                    ],
                    [
                      -116.15046,
                      46.5615
                    ],
                    [
                      -116.15044,
                      46.56173
                    ],
                    [
                      -116.15045,
                      46.56185
                    ],
                    [
                      -116.15049,
                      46.562
                    ],
                    [
                      -116.15061,
                      46.56228
                    ],
                    [
                      -116.15074,
                      46.56265
                    ],
                    [
                      -116.15076,
                      46.56301
                    ],
                    [
                      -116.15062,
                      46.56352
                    ],
                    [
                      -116.15062,
                      46.56367
                    ],
                    [
                      -116.15067,
                      46.56384
                    ],
                    [
                      -116.15072,
                      46.56394
                    ],
                    [
                      -116.15077,
                      46.56401
                    ],
                    [
                      -116.15089,
                      46.56412
                    ],
                    [
                      -116.15104,
                      46.56422
                    ],
                    [
                      -116.15178,
                      46.56457
                    ],
                    [
                      -116.15179,
                      46.56457
                    ],
                    [
                      -116.15219,
                      46.56474
                    ],
                    [
                      -116.15513,
                      46.56619
                    ],
                    [
                      -116.15602,
                      46.56673
                    ],
                    [
                      -116.15662,
                      46.56713
                    ],
                    [
                      -116.15667,
                      46.56718
                    ],
                    [
                      -116.15669,
                      46.56719
                    ],
                    [
                      -116.15693,
                      46.56743
                    ],
                    [
                      -116.15702,
                      46.56756
                    ],
                    [
                      -116.15712,
                      46.56776
                    ],
                    [
                      -116.15713,
                      46.56782
                    ],
                    [
                      -116.15714,
                      46.56784
                    ],
                    [
                      -116.15717,
                      46.56803
                    ],
                    [
                      -116.15717,
                      46.56815
                    ],
                    [
                      -116.15706,
                      46.56872
                    ],
                    [
                      -116.15706,
                      46.56885
                    ],
                    [
                      -116.1572,
                      46.56939
                    ],
                    [
                      -116.15726,
                      46.56971
                    ],
                    [
                      -116.15728,
                      46.5699
                    ],
                    [
                      -116.15725,
                      46.57184
                    ],
                    [
                      -116.15714,
                      46.5738
                    ],
                    [
                      -116.15717,
                      46.57526
                    ],
                    [
                      -116.1572,
                      46.57541
                    ],
                    [
                      -116.15739,
                      46.57581
                    ],
                    [
                      -116.15749,
                      46.57594
                    ],
                    [
                      -116.15763,
                      46.57608
                    ],
                    [
                      -116.15788,
                      46.57626
                    ],
                    [
                      -116.15938,
                      46.5772
                    ],
                    [
                      -116.15959,
                      46.57735
                    ],
                    [
                      -116.15971,
                      46.57746
                    ],
                    [
                      -116.15981,
                      46.57758
                    ],
                    [
                      -116.15982,
                      46.57761
                    ],
                    [
                      -116.1599,
                      46.57773
                    ],
                    [
                      -116.15998,
                      46.57796
                    ],
                    [
                      -116.16001,
                      46.57811
                    ],
                    [
                      -116.15999,
                      46.57846
                    ],
                    [
                      -116.15984,
                      46.57879
                    ],
                    [
                      -116.15969,
                      46.57901
                    ],
                    [
                      -116.15947,
                      46.57926
                    ],
                    [
                      -116.15925,
                      46.57959
                    ],
                    [
                      -116.15919,
                      46.57971
                    ],
                    [
                      -116.15915,
                      46.57991
                    ],
                    [
                      -116.15914,
                      46.58004
                    ],
                    [
                      -116.15917,
                      46.58019
                    ],
                    [
                      -116.15949,
                      46.58096
                    ],
                    [
                      -116.15957,
                      46.58111
                    ],
                    [
                      -116.15963,
                      46.58127
                    ],
                    [
                      -116.15966,
                      46.58142
                    ],
                    [
                      -116.15967,
                      46.58156
                    ],
                    [
                      -116.15947,
                      46.58311
                    ],
                    [
                      -116.15949,
                      46.58325
                    ],
                    [
                      -116.15958,
                      46.58346
                    ],
                    [
                      -116.15977,
                      46.58371
                    ],
                    [
                      -116.15983,
                      46.58385
                    ],
                    [
                      -116.15985,
                      46.58406
                    ],
                    [
                      -116.15982,
                      46.58463
                    ],
                    [
                      -116.15987,
                      46.58505
                    ],
                    [
                      -116.16005,
                      46.58596
                    ],
                    [
                      -116.16002,
                      46.58638
                    ],
                    [
                      -116.16003,
                      46.58645
                    ],
                    [
                      -116.16004,
                      46.58646
                    ],
                    [
                      -116.16006,
                      46.58652
                    ],
                    [
                      -116.16015,
                      46.58664
                    ],
                    [
                      -116.16036,
                      46.5868
                    ],
                    [
                      -116.1607,
                      46.58697
                    ],
                    [
                      -116.16089,
                      46.58703
                    ],
                    [
                      -116.16129,
                      46.58712
                    ],
                    [
                      -116.1617,
                      46.58715
                    ],
                    [
                      -116.1619,
                      46.58714
                    ],
                    [
                      -116.16209,
                      46.58708
                    ],
                    [
                      -116.16225,
                      46.58698
                    ],
                    [
                      -116.16235,
                      46.58688
                    ],
                    [
                      -116.16288,
                      46.58624
                    ],
                    [
                      -116.16294,
                      46.58619
                    ],
                    [
                      -116.16302,
                      46.58614
                    ],
                    [
                      -116.16322,
                      46.58607
                    ],
                    [
                      -116.16332,
                      46.58605
                    ],
                    [
                      -116.16356,
                      46.58605
                    ],
                    [
                      -116.16373,
                      46.58609
                    ],
                    [
                      -116.16391,
                      46.58617
                    ],
                    [
                      -116.16398,
                      46.58622
                    ],
                    [
                      -116.16404,
                      46.58628
                    ],
                    [
                      -116.16409,
                      46.58635
                    ],
                    [
                      -116.16415,
                      46.58649
                    ],
                    [
                      -116.16414,
                      46.58671
                    ],
                    [
                      -116.16396,
                      46.58735
                    ],
                    [
                      -116.16394,
                      46.58749
                    ],
                    [
                      -116.16394,
                      46.58763
                    ],
                    [
                      -116.16411,
                      46.58812
                    ],
                    [
                      -116.16412,
                      46.58819
                    ],
                    [
                      -116.16412,
                      46.58833
                    ],
                    [
                      -116.16406,
                      46.58854
                    ],
                    [
                      -116.16402,
                      46.58861
                    ],
                    [
                      -116.164,
                      46.58875
                    ],
                    [
                      -116.16405,
                      46.58889
                    ],
                    [
                      -116.16409,
                      46.58896
                    ],
                    [
                      -116.16421,
                      46.58908
                    ],
                    [
                      -116.16435,
                      46.58919
                    ],
                    [
                      -116.16542,
                      46.58987
                    ],
                    [
                      -116.16555,
                      46.58999
                    ],
                    [
                      -116.1656,
                      46.59005
                    ],
                    [
                      -116.16565,
                      46.59019
                    ],
                    [
                      -116.16566,
                      46.59027
                    ],
                    [
                      -116.16565,
                      46.59034
                    ],
                    [
                      -116.16546,
                      46.59089
                    ],
                    [
                      -116.16547,
                      46.59103
                    ],
                    [
                      -116.1655,
                      46.5911
                    ],
                    [
                      -116.16561,
                      46.59122
                    ],
                    [
                      -116.16568,
                      46.59127
                    ],
                    [
                      -116.1659,
                      46.59134
                    ],
                    [
                      -116.16597,
                      46.59135
                    ],
                    [
                      -116.16617,
                      46.59135
                    ],
                    [
                      -116.16638,
                      46.59132
                    ],
                    [
                      -116.16657,
                      46.59127
                    ],
                    [
                      -116.16685,
                      46.59117
                    ],
                    [
                      -116.16688,
                      46.59115
                    ],
                    [
                      -116.16712,
                      46.59106
                    ],
                    [
                      -116.16733,
                      46.59102
                    ],
                    [
                      -116.16754,
                      46.59105
                    ],
                    [
                      -116.16764,
                      46.59108
                    ],
                    [
                      -116.16781,
                      46.59116
                    ],
                    [
                      -116.16828,
                      46.59144
                    ],
                    [
                      -116.16846,
                      46.59151
                    ],
                    [
                      -116.16866,
                      46.59154
                    ],
                    [
                      -116.16876,
                      46.59154
                    ],
                    [
                      -116.16897,
                      46.5915
                    ],
                    [
                      -116.16906,
                      46.59147
                    ],
                    [
                      -116.16916,
                      46.59142
                    ],
                    [
                      -116.16939,
                      46.59125
                    ],
                    [
                      -116.16962,
                      46.59099
                    ],
                    [
                      -116.16966,
                      46.59088
                    ],
                    [
                      -116.16977,
                      46.59073
                    ],
                    [
                      -116.17026,
                      46.59044
                    ],
                    [
                      -116.17034,
                      46.59038
                    ],
                    [
                      -116.17039,
                      46.59024
                    ],
                    [
                      -116.17036,
                      46.5901
                    ],
                    [
                      -116.17016,
                      46.58977
                    ],
                    [
                      -116.17012,
                      46.58963
                    ],
                    [
                      -116.17012,
                      46.58956
                    ],
                    [
                      -116.17014,
                      46.58948
                    ],
                    [
                      -116.17024,
                      46.58936
                    ],
                    [
                      -116.17032,
                      46.5893
                    ],
                    [
                      -116.17049,
                      46.58922
                    ],
                    [
                      -116.17059,
                      46.5892
                    ],
                    [
                      -116.1709,
                      46.58921
                    ],
                    [
                      -116.17159,
                      46.5893
                    ],
                    [
                      -116.17162,
                      46.58931
                    ],
                    [
                      -116.17227,
                      46.58941
                    ],
                    [
                      -116.17245,
                      46.58947
                    ],
                    [
                      -116.17259,
                      46.58955
                    ],
                    [
                      -116.17262,
                      46.58956
                    ],
                    [
                      -116.17269,
                      46.58961
                    ],
                    [
                      -116.17282,
                      46.58968
                    ],
                    [
                      -116.17298,
                      46.58979
                    ],
                    [
                      -116.17306,
                      46.58987
                    ],
                    [
                      -116.17322,
                      46.58996
                    ],
                    [
                      -116.17357,
                      46.59012
                    ],
                    [
                      -116.17368,
                      46.59019
                    ],
                    [
                      -116.17374,
                      46.59026
                    ],
                    [
                      -116.17376,
                      46.59033
                    ],
                    [
                      -116.17374,
                      46.59043
                    ],
                    [
                      -116.17369,
                      46.5905
                    ],
                    [
                      -116.1736,
                      46.59057
                    ],
                    [
                      -116.17347,
                      46.59061
                    ],
                    [
                      -116.17318,
                      46.5906
                    ],
                    [
                      -116.17281,
                      46.59052
                    ],
                    [
                      -116.17268,
                      46.59051
                    ],
                    [
                      -116.1725,
                      46.59052
                    ],
                    [
                      -116.1723,
                      46.59056
                    ],
                    [
                      -116.17221,
                      46.5906
                    ],
                    [
                      -116.1721,
                      46.59068
                    ],
                    [
                      -116.17206,
                      46.5907
                    ],
                    [
                      -116.17196,
                      46.59083
                    ],
                    [
                      -116.17192,
                      46.59092
                    ],
                    [
                      -116.1719,
                      46.59112
                    ],
                    [
                      -116.17213,
                      46.59176
                    ],
                    [
                      -116.17219,
                      46.592
                    ],
                    [
                      -116.17229,
                      46.59278
                    ],
                    [
                      -116.17244,
                      46.59336
                    ],
                    [
                      -116.17289,
                      46.59407
                    ],
                    [
                      -116.17327,
                      46.59447
                    ],
                    [
                      -116.17349,
                      46.59465
                    ],
                    [
                      -116.17404,
                      46.59498
                    ],
                    [
                      -116.17415,
                      46.59511
                    ],
                    [
                      -116.17425,
                      46.59526
                    ],
                    [
                      -116.17437,
                      46.59553
                    ],
                    [
                      -116.17447,
                      46.59582
                    ],
                    [
                      -116.17448,
                      46.59597
                    ],
                    [
                      -116.17445,
                      46.59608
                    ],
                    [
                      -116.17437,
                      46.5962
                    ],
                    [
                      -116.1743,
                      46.59625
                    ],
                    [
                      -116.17401,
                      46.5964
                    ],
                    [
                      -116.1739,
                      46.59643
                    ],
                    [
                      -116.17377,
                      46.59644
                    ],
                    [
                      -116.17354,
                      46.59641
                    ],
                    [
                      -116.17318,
                      46.59641
                    ],
                    [
                      -116.17301,
                      46.59645
                    ],
                    [
                      -116.17284,
                      46.59654
                    ],
                    [
                      -116.17242,
                      46.5967
                    ],
                    [
                      -116.17217,
                      46.5967
                    ],
                    [
                      -116.17206,
                      46.59668
                    ],
                    [
                      -116.17197,
                      46.59665
                    ],
                    [
                      -116.17185,
                      46.59659
                    ],
                    [
                      -116.17177,
                      46.59651
                    ],
                    [
                      -116.17169,
                      46.59645
                    ],
                    [
                      -116.17159,
                      46.59632
                    ],
                    [
                      -116.17126,
                      46.59598
                    ],
                    [
                      -116.17117,
                      46.59591
                    ],
                    [
                      -116.17103,
                      46.59583
                    ],
                    [
                      -116.17079,
                      46.59574
                    ],
                    [
                      -116.17045,
                      46.59572
                    ],
                    [
                      -116.17035,
                      46.59574
                    ],
                    [
                      -116.1702,
                      46.5958
                    ],
                    [
                      -116.17,
                      46.59594
                    ],
                    [
                      -116.16981,
                      46.59614
                    ],
                    [
                      -116.1697,
                      46.59623
                    ],
                    [
                      -116.16951,
                      46.59632
                    ],
                    [
                      -116.16932,
                      46.59636
                    ],
                    [
                      -116.1691,
                      46.59635
                    ],
                    [
                      -116.16887,
                      46.59628
                    ],
                    [
                      -116.16849,
                      46.5962
                    ],
                    [
                      -116.16817,
                      46.5962
                    ],
                    [
                      -116.16807,
                      46.59622
                    ],
                    [
                      -116.16717,
                      46.5963
                    ],
                    [
                      -116.1669,
                      46.59637
                    ],
                    [
                      -116.1668,
                      46.59647
                    ],
                    [
                      -116.16672,
                      46.59662
                    ],
                    [
                      -116.1667,
                      46.59674
                    ],
                    [
                      -116.16686,
                      46.59799
                    ],
                    [
                      -116.1669,
                      46.59849
                    ],
                    [
                      -116.16692,
                      46.59855
                    ],
                    [
                      -116.16694,
                      46.59866
                    ],
                    [
                      -116.16697,
                      46.59872
                    ],
                    [
                      -116.16707,
                      46.59886
                    ],
                    [
                      -116.16732,
                      46.59907
                    ],
                    [
                      -116.16758,
                      46.59933
                    ],
                    [
                      -116.16763,
                      46.59948
                    ],
                    [
                      -116.16765,
                      46.59958
                    ],
                    [
                      -116.16765,
                      46.59973
                    ],
                    [
                      -116.16763,
                      46.59986
                    ],
                    [
                      -116.16753,
                      46.60013
                    ],
                    [
                      -116.16751,
                      46.6003
                    ],
                    [
                      -116.16749,
                      46.60037
                    ],
                    [
                      -116.16754,
                      46.60049
                    ],
                    [
                      -116.16766,
                      46.60057
                    ],
                    [
                      -116.16784,
                      46.60065
                    ],
                    [
                      -116.16815,
                      46.60066
                    ],
                    [
                      -116.16834,
                      46.60063
                    ],
                    [
                      -116.16845,
                      46.60057
                    ],
                    [
                      -116.16848,
                      46.60056
                    ],
                    [
                      -116.16861,
                      46.60043
                    ],
                    [
                      -116.16863,
                      46.60042
                    ],
                    [
                      -116.16866,
                      46.60032
                    ],
                    [
                      -116.16862,
                      46.6001
                    ],
                    [
                      -116.16844,
                      46.59971
                    ],
                    [
                      -116.16832,
                      46.59957
                    ],
                    [
                      -116.16829,
                      46.59945
                    ],
                    [
                      -116.16848,
                      46.5987
                    ],
                    [
                      -116.16857,
                      46.59858
                    ],
                    [
                      -116.16874,
                      46.59846
                    ],
                    [
                      -116.16891,
                      46.5984
                    ],
                    [
                      -116.16912,
                      46.5984
                    ],
                    [
                      -116.16934,
                      46.59845
                    ],
                    [
                      -116.16954,
                      46.59856
                    ],
                    [
                      -116.16984,
                      46.59877
                    ],
                    [
                      -116.16993,
                      46.59881
                    ],
                    [
                      -116.17003,
                      46.59884
                    ],
                    [
                      -116.17042,
                      46.59883
                    ],
                    [
                      -116.17076,
                      46.59888
                    ],
                    [
                      -116.17097,
                      46.59901
                    ],
                    [
                      -116.17123,
                      46.5991
                    ],
                    [
                      -116.17191,
                      46.59927
                    ],
                    [
                      -116.17219,
                      46.59937
                    ],
                    [
                      -116.17306,
                      46.59954
                    ],
                    [
                      -116.17324,
                      46.59959
                    ],
                    [
                      -116.17357,
                      46.59965
                    ],
                    [
                      -116.17362,
                      46.59967
                    ],
                    [
                      -116.17391,
                      46.5997
                    ],
                    [
                      -116.17463,
                      46.59965
                    ],
                    [
                      -116.17486,
                      46.5996
                    ],
                    [
                      -116.17502,
                      46.59955
                    ],
                    [
                      -116.1756,
                      46.59952
                    ],
                    [
                      -116.17655,
                      46.59956
                    ],
                    [
                      -116.17675,
                      46.59954
                    ],
                    [
                      -116.17764,
                      46.59929
                    ],
                    [
                      -116.17796,
                      46.59928
                    ],
                    [
                      -116.17816,
                      46.59934
                    ],
                    [
                      -116.17841,
                      46.59947
                    ],
                    [
                      -116.17856,
                      46.59958
                    ],
                    [
                      -116.17889,
                      46.59976
                    ],
                    [
                      -116.17912,
                      46.59991
                    ],
                    [
                      -116.17918,
                      46.59997
                    ],
                    [
                      -116.17925,
                      46.60011
                    ],
                    [
                      -116.17926,
                      46.60018
                    ],
                    [
                      -116.17921,
                      46.60046
                    ],
                    [
                      -116.17859,
                      46.60255
                    ],
                    [
                      -116.17794,
                      46.60498
                    ],
                    [
                      -116.17795,
                      46.60505
                    ],
                    [
                      -116.178,
                      46.60522
                    ],
                    [
                      -116.17811,
                      46.60539
                    ],
                    [
                      -116.17823,
                      46.60551
                    ],
                    [
                      -116.17853,
                      46.60569
                    ],
                    [
                      -116.18021,
                      46.60653
                    ],
                    [
                      -116.18034,
                      46.60665
                    ],
                    [
                      -116.18038,
                      46.60672
                    ],
                    [
                      -116.1804,
                      46.60686
                    ],
                    [
                      -116.18038,
                      46.60694
                    ],
                    [
                      -116.18031,
                      46.60707
                    ],
                    [
                      -116.18026,
                      46.60713
                    ],
                    [
                      -116.18019,
                      46.60726
                    ],
                    [
                      -116.18017,
                      46.6074
                    ],
                    [
                      -116.18023,
                      46.60754
                    ],
                    [
                      -116.18028,
                      46.6076
                    ],
                    [
                      -116.18035,
                      46.60766
                    ],
                    [
                      -116.18051,
                      46.60774
                    ],
                    [
                      -116.1808,
                      46.60782
                    ],
                    [
                      -116.181,
                      46.60785
                    ],
                    [
                      -116.18137,
                      46.60798
                    ],
                    [
                      -116.1815,
                      46.6081
                    ],
                    [
                      -116.18154,
                      46.60817
                    ],
                    [
                      -116.18156,
                      46.60823
                    ],
                    [
                      -116.18151,
                      46.60852
                    ],
                    [
                      -116.18148,
                      46.60859
                    ],
                    [
                      -116.18148,
                      46.60862
                    ],
                    [
                      -116.18151,
                      46.60873
                    ],
                    [
                      -116.1816,
                      46.60885
                    ],
                    [
                      -116.18174,
                      46.60896
                    ],
                    [
                      -116.18209,
                      46.6091
                    ],
                    [
                      -116.18216,
                      46.60915
                    ],
                    [
                      -116.18222,
                      46.60921
                    ],
                    [
                      -116.1823,
                      46.60934
                    ],
                    [
                      -116.18235,
                      46.6094
                    ],
                    [
                      -116.18238,
                      46.60946
                    ],
                    [
                      -116.18243,
                      46.60952
                    ],
                    [
                      -116.18249,
                      46.60957
                    ],
                    [
                      -116.18253,
                      46.60959
                    ],
                    [
                      -116.18256,
                      46.60962
                    ],
                    [
                      -116.18264,
                      46.60966
                    ],
                    [
                      -116.18284,
                      46.6097
                    ],
                    [
                      -116.18344,
                      46.60969
                    ],
                    [
                      -116.18374,
                      46.60976
                    ],
                    [
                      -116.18389,
                      46.60986
                    ],
                    [
                      -116.18394,
                      46.60993
                    ],
                    [
                      -116.18397,
                      46.61
                    ],
                    [
                      -116.18398,
                      46.61015
                    ],
                    [
                      -116.18378,
                      46.61048
                    ],
                    [
                      -116.18375,
                      46.61062
                    ],
                    [
                      -116.18378,
                      46.61075
                    ],
                    [
                      -116.18388,
                      46.61088
                    ],
                    [
                      -116.18491,
                      46.61167
                    ],
                    [
                      -116.18503,
                      46.61179
                    ],
                    [
                      -116.18512,
                      46.61192
                    ],
                    [
                      -116.18515,
                      46.61199
                    ],
                    [
                      -116.18517,
                      46.61207
                    ],
                    [
                      -116.18522,
                      46.61256
                    ],
                    [
                      -116.18524,
                      46.61263
                    ],
                    [
                      -116.18531,
                      46.61276
                    ],
                    [
                      -116.18536,
                      46.61282
                    ],
                    [
                      -116.18543,
                      46.61288
                    ],
                    [
                      -116.18559,
                      46.61297
                    ],
                    [
                      -116.18578,
                      46.61301
                    ],
                    [
                      -116.18597,
                      46.61301
                    ],
                    [
                      -116.18598,
                      46.613
                    ],
                    [
                      -116.18649,
                      46.61296
                    ],
                    [
                      -116.1867,
                      46.61297
                    ],
                    [
                      -116.18675,
                      46.61299
                    ],
                    [
                      -116.1868,
                      46.613
                    ],
                    [
                      -116.18697,
                      46.61308
                    ],
                    [
                      -116.1871,
                      46.6132
                    ],
                    [
                      -116.18739,
                      46.61373
                    ],
                    [
                      -116.1874,
                      46.61374
                    ],
                    [
                      -116.1879,
                      46.61468
                    ],
                    [
                      -116.18795,
                      46.61482
                    ],
                    [
                      -116.18795,
                      46.61484
                    ],
                    [
                      -116.18797,
                      46.61489
                    ],
                    [
                      -116.18796,
                      46.6151
                    ],
                    [
                      -116.18794,
                      46.61518
                    ],
                    [
                      -116.18781,
                      46.61539
                    ],
                    [
                      -116.18659,
                      46.61703
                    ],
                    [
                      -116.1854,
                      46.61826
                    ],
                    [
                      -116.1853,
                      46.61838
                    ],
                    [
                      -116.18524,
                      46.61851
                    ],
                    [
                      -116.18523,
                      46.61865
                    ],
                    [
                      -116.18527,
                      46.61879
                    ],
                    [
                      -116.18536,
                      46.61893
                    ],
                    [
                      -116.18556,
                      46.61908
                    ],
                    [
                      -116.18574,
                      46.61915
                    ],
                    [
                      -116.1869,
                      46.61943
                    ],
                    [
                      -116.18801,
                      46.61955
                    ],
                    [
                      -116.18944,
                      46.61962
                    ],
                    [
                      -116.19005,
                      46.61969
                    ],
                    [
                      -116.19032,
                      46.61979
                    ],
                    [
                      -116.19055,
                      46.61994
                    ],
                    [
                      -116.19062,
                      46.62
                    ],
                    [
                      -116.19079,
                      46.62026
                    ],
                    [
                      -116.19119,
                      46.62105
                    ],
                    [
                      -116.19133,
                      46.62124
                    ],
                    [
                      -116.19145,
                      46.62135
                    ],
                    [
                      -116.19165,
                      46.6215
                    ],
                    [
                      -116.19186,
                      46.62163
                    ],
                    [
                      -116.19202,
                      46.6217
                    ],
                    [
                      -116.19237,
                      46.62181
                    ],
                    [
                      -116.1931,
                      46.62192
                    ],
                    [
                      -116.19319,
                      46.62195
                    ],
                    [
                      -116.19344,
                      46.622
                    ],
                    [
                      -116.19345,
                      46.62201
                    ],
                    [
                      -116.19372,
                      46.62216
                    ],
                    [
                      -116.19379,
                      46.62221
                    ],
                    [
                      -116.19383,
                      46.62231
                    ],
                    [
                      -116.19382,
                      46.62259
                    ],
                    [
                      -116.19367,
                      46.62295
                    ],
                    [
                      -116.19351,
                      46.62316
                    ],
                    [
                      -116.19321,
                      46.62344
                    ],
                    [
                      -116.19272,
                      46.62371
                    ],
                    [
                      -116.19253,
                      46.62377
                    ],
                    [
                      -116.19232,
                      46.6238
                    ],
                    [
                      -116.19212,
                      46.62378
                    ],
                    [
                      -116.19183,
                      46.6237
                    ],
                    [
                      -116.19171,
                      46.62365
                    ],
                    [
                      -116.19153,
                      46.62369
                    ],
                    [
                      -116.19146,
                      46.62374
                    ],
                    [
                      -116.19144,
                      46.62381
                    ],
                    [
                      -116.19148,
                      46.62387
                    ],
                    [
                      -116.19156,
                      46.62392
                    ],
                    [
                      -116.19165,
                      46.62396
                    ],
                    [
                      -116.19195,
                      46.62404
                    ],
                    [
                      -116.19223,
                      46.62407
                    ],
                    [
                      -116.19247,
                      46.62406
                    ],
                    [
                      -116.19268,
                      46.62402
                    ],
                    [
                      -116.19315,
                      46.62388
                    ],
                    [
                      -116.19357,
                      46.62387
                    ],
                    [
                      -116.19376,
                      46.62393
                    ],
                    [
                      -116.1939,
                      46.62403
                    ],
                    [
                      -116.19391,
                      46.62405
                    ],
                    [
                      -116.19392,
                      46.62406
                    ],
                    [
                      -116.19396,
                      46.62417
                    ],
                    [
                      -116.19392,
                      46.62432
                    ],
                    [
                      -116.1937,
                      46.62465
                    ],
                    [
                      -116.19363,
                      46.62479
                    ],
                    [
                      -116.19362,
                      46.62493
                    ],
                    [
                      -116.19367,
                      46.62507
                    ],
                    [
                      -116.19378,
                      46.62519
                    ],
                    [
                      -116.19394,
                      46.62528
                    ],
                    [
                      -116.19522,
                      46.62574
                    ],
                    [
                      -116.19548,
                      46.62588
                    ],
                    [
                      -116.1956,
                      46.626
                    ],
                    [
                      -116.19568,
                      46.62621
                    ],
                    [
                      -116.19572,
                      46.62642
                    ],
                    [
                      -116.19574,
                      46.62663
                    ],
                    [
                      -116.19573,
                      46.62678
                    ],
                    [
                      -116.19568,
                      46.62692
                    ],
                    [
                      -116.19557,
                      46.62704
                    ],
                    [
                      -116.19469,
                      46.62764
                    ],
                    [
                      -116.19451,
                      46.62771
                    ],
                    [
                      -116.19431,
                      46.62773
                    ],
                    [
                      -116.194,
                      46.62772
                    ],
                    [
                      -116.1937,
                      46.62775
                    ],
                    [
                      -116.19353,
                      46.62783
                    ],
                    [
                      -116.19343,
                      46.62794
                    ],
                    [
                      -116.1934,
                      46.62809
                    ],
                    [
                      -116.19354,
                      46.62843
                    ],
                    [
                      -116.19367,
                      46.62863
                    ],
                    [
                      -116.19398,
                      46.62891
                    ],
                    [
                      -116.19415,
                      46.6291
                    ],
                    [
                      -116.19448,
                      46.6297
                    ],
                    [
                      -116.19448,
                      46.62971
                    ],
                    [
                      -116.19462,
                      46.63011
                    ],
                    [
                      -116.19483,
                      46.6305
                    ],
                    [
                      -116.19485,
                      46.63094
                    ],
                    [
                      -116.19489,
                      46.631
                    ],
                    [
                      -116.19492,
                      46.63107
                    ],
                    [
                      -116.19503,
                      46.63119
                    ],
                    [
                      -116.19523,
                      46.63135
                    ],
                    [
                      -116.1959,
                      46.63179
                    ],
                    [
                      -116.19611,
                      46.63195
                    ],
                    [
                      -116.19672,
                      46.63268
                    ],
                    [
                      -116.1969,
                      46.63285
                    ],
                    [
                      -116.19714,
                      46.63298
                    ],
                    [
                      -116.19768,
                      46.63319
                    ],
                    [
                      -116.19826,
                      46.63334
                    ],
                    [
                      -116.19854,
                      46.63344
                    ],
                    [
                      -116.19873,
                      46.63354
                    ],
                    [
                      -116.19879,
                      46.63356
                    ],
                    [
                      -116.19899,
                      46.63372
                    ],
                    [
                      -116.19932,
                      46.63408
                    ],
                    [
                      -116.19944,
                      46.63428
                    ],
                    [
                      -116.19959,
                      46.63446
                    ],
                    [
                      -116.19973,
                      46.63456
                    ],
                    [
                      -116.19997,
                      46.6347
                    ],
                    [
                      -116.20024,
                      46.6348
                    ],
                    [
                      -116.20082,
                      46.63495
                    ],
                    [
                      -116.20112,
                      46.635
                    ],
                    [
                      -116.20167,
                      46.63502
                    ],
                    [
                      -116.20235,
                      46.63497
                    ],
                    [
                      -116.20255,
                      46.635
                    ],
                    [
                      -116.20275,
                      46.63505
                    ],
                    [
                      -116.20353,
                      46.63539
                    ],
                    [
                      -116.20378,
                      46.63552
                    ],
                    [
                      -116.20419,
                      46.63579
                    ],
                    [
                      -116.20443,
                      46.63598
                    ],
                    [
                      -116.20461,
                      46.63615
                    ],
                    [
                      -116.20482,
                      46.63631
                    ],
                    [
                      -116.20507,
                      46.63646
                    ],
                    [
                      -116.20525,
                      46.63661
                    ],
                    [
                      -116.20558,
                      46.63697
                    ],
                    [
                      -116.20571,
                      46.63708
                    ],
                    [
                      -116.20593,
                      46.63723
                    ],
                    [
                      -116.20671,
                      46.63759
                    ],
                    [
                      -116.20699,
                      46.63767
                    ],
                    [
                      -116.20729,
                      46.63773
                    ],
                    [
                      -116.2076,
                      46.63774
                    ],
                    [
                      -116.2088,
                      46.63755
                    ],
                    [
                      -116.20911,
                      46.63756
                    ],
                    [
                      -116.20925,
                      46.63761
                    ],
                    [
                      -116.2093,
                      46.63762
                    ],
                    [
                      -116.20937,
                      46.63767
                    ],
                    [
                      -116.20943,
                      46.63776
                    ],
                    [
                      -116.20944,
                      46.63781
                    ],
                    [
                      -116.20943,
                      46.63788
                    ],
                    [
                      -116.20941,
                      46.63794
                    ],
                    [
                      -116.20928,
                      46.63809
                    ],
                    [
                      -116.20891,
                      46.63833
                    ],
                    [
                      -116.20796,
                      46.63888
                    ],
                    [
                      -116.20778,
                      46.63905
                    ],
                    [
                      -116.2077,
                      46.63918
                    ],
                    [
                      -116.20768,
                      46.63927
                    ],
                    [
                      -116.20766,
                      46.63932
                    ],
                    [
                      -116.20765,
                      46.63975
                    ],
                    [
                      -116.20767,
                      46.63996
                    ],
                    [
                      -116.20768,
                      46.63998
                    ],
                    [
                      -116.20777,
                      46.64038
                    ],
                    [
                      -116.20776,
                      46.64052
                    ],
                    [
                      -116.20777,
                      46.64068
                    ],
                    [
                      -116.20783,
                      46.64082
                    ],
                    [
                      -116.20804,
                      46.6412
                    ],
                    [
                      -116.20805,
                      46.64121
                    ],
                    [
                      -116.20815,
                      46.64142
                    ],
                    [
                      -116.20822,
                      46.64164
                    ],
                    [
                      -116.2083,
                      46.64198
                    ],
                    [
                      -116.20831,
                      46.64229
                    ],
                    [
                      -116.20829,
                      46.64244
                    ],
                    [
                      -116.20819,
                      46.64266
                    ],
                    [
                      -116.20792,
                      46.64297
                    ],
                    [
                      -116.2078,
                      46.64317
                    ],
                    [
                      -116.2077,
                      46.64359
                    ],
                    [
                      -116.2076,
                      46.6438
                    ],
                    [
                      -116.20747,
                      46.64399
                    ],
                    [
                      -116.20716,
                      46.64437
                    ],
                    [
                      -116.20698,
                      46.64454
                    ],
                    [
                      -116.20669,
                      46.64492
                    ],
                    [
                      -116.20634,
                      46.64528
                    ],
                    [
                      -116.20586,
                      46.64583
                    ],
                    [
                      -116.20585,
                      46.64585
                    ],
                    [
                      -116.20536,
                      46.64649
                    ],
                    [
                      -116.2052,
                      46.64663
                    ],
                    [
                      -116.20505,
                      46.64673
                    ],
                    [
                      -116.20438,
                      46.64706
                    ],
                    [
                      -116.20411,
                      46.64717
                    ],
                    [
                      -116.20362,
                      46.64728
                    ],
                    [
                      -116.20287,
                      46.64754
                    ],
                    [
                      -116.20267,
                      46.64758
                    ],
                    [
                      -116.20184,
                      46.64766
                    ],
                    [
                      -116.20166,
                      46.64772
                    ],
                    [
                      -116.2009,
                      46.64809
                    ],
                    [
                      -116.20062,
                      46.64819
                    ],
                    [
                      -116.20002,
                      46.64831
                    ],
                    [
                      -116.19796,
                      46.64895
                    ],
                    [
                      -116.19782,
                      46.64901
                    ],
                    [
                      -116.19778,
                      46.64908
                    ],
                    [
                      -116.19777,
                      46.64913
                    ],
                    [
                      -116.19777,
                      46.64917
                    ],
                    [
                      -116.19781,
                      46.64922
                    ],
                    [
                      -116.19787,
                      46.64925
                    ],
                    [
                      -116.19805,
                      46.64929
                    ],
                    [
                      -116.19821,
                      46.64931
                    ],
                    [
                      -116.19864,
                      46.64926
                    ],
                    [
                      -116.19899,
                      46.64927
                    ],
                    [
                      -116.19916,
                      46.64929
                    ],
                    [
                      -116.19933,
                      46.64933
                    ],
                    [
                      -116.20066,
                      46.64979
                    ],
                    [
                      -116.20108,
                      46.64999
                    ],
                    [
                      -116.20138,
                      46.65005
                    ],
                    [
                      -116.20166,
                      46.65007
                    ],
                    [
                      -116.20206,
                      46.65014
                    ],
                    [
                      -116.20221,
                      46.65014
                    ],
                    [
                      -116.20228,
                      46.65012
                    ],
                    [
                      -116.2023,
                      46.65012
                    ],
                    [
                      -116.20253,
                      46.64999
                    ],
                    [
                      -116.20268,
                      46.64986
                    ],
                    [
                      -116.20291,
                      46.64976
                    ],
                    [
                      -116.20306,
                      46.64972
                    ],
                    [
                      -116.20322,
                      46.64971
                    ],
                    [
                      -116.20349,
                      46.64975
                    ],
                    [
                      -116.20364,
                      46.64981
                    ],
                    [
                      -116.2038,
                      46.64985
                    ],
                    [
                      -116.20444,
                      46.64992
                    ],
                    [
                      -116.20486,
                      46.65009
                    ],
                    [
                      -116.20542,
                      46.65027
                    ],
                    [
                      -116.2061,
                      46.65054
                    ],
                    [
                      -116.20621,
                      46.65061
                    ],
                    [
                      -116.20628,
                      46.65067
                    ],
                    [
                      -116.20637,
                      46.65081
                    ],
                    [
                      -116.20646,
                      46.65108
                    ],
                    [
                      -116.20645,
                      46.65129
                    ],
                    [
                      -116.20637,
                      46.65147
                    ],
                    [
                      -116.20631,
                      46.65153
                    ],
                    [
                      -116.20616,
                      46.65164
                    ],
                    [
                      -116.20603,
                      46.65169
                    ],
                    [
                      -116.20572,
                      46.65176
                    ],
                    [
                      -116.2055,
                      46.65179
                    ],
                    [
                      -116.2054,
                      46.65182
                    ],
                    [
                      -116.20538,
                      46.65182
                    ],
                    [
                      -116.2053,
                      46.65185
                    ],
                    [
                      -116.2051,
                      46.65198
                    ],
                    [
                      -116.205,
                      46.65207
                    ],
                    [
                      -116.20491,
                      46.65213
                    ],
                    [
                      -116.20486,
                      46.65219
                    ],
                    [
                      -116.20482,
                      46.65226
                    ],
                    [
                      -116.20482,
                      46.65235
                    ],
                    [
                      -116.20485,
                      46.65246
                    ],
                    [
                      -116.20495,
                      46.65267
                    ],
                    [
                      -116.205,
                      46.65289
                    ],
                    [
                      -116.205,
                      46.6531
                    ],
                    [
                      -116.20494,
                      46.65327
                    ],
                    [
                      -116.20489,
                      46.65334
                    ],
                    [
                      -116.20479,
                      46.6534
                    ],
                    [
                      -116.20449,
                      46.65347
                    ],
                    [
                      -116.20433,
                      46.65353
                    ],
                    [
                      -116.20371,
                      46.65389
                    ],
                    [
                      -116.20344,
                      46.65402
                    ],
                    [
                      -116.20331,
                      46.65413
                    ],
                    [
                      -116.2033,
                      46.65415
                    ],
                    [
                      -116.2033,
                      46.65417
                    ],
                    [
                      -116.20331,
                      46.65418
                    ],
                    [
                      -116.20332,
                      46.65422
                    ],
                    [
                      -116.2037,
                      46.65465
                    ],
                    [
                      -116.20382,
                      46.65485
                    ],
                    [
                      -116.20389,
                      46.65506
                    ],
                    [
                      -116.20398,
                      46.65527
                    ],
                    [
                      -116.20413,
                      46.65554
                    ],
                    [
                      -116.20414,
                      46.65568
                    ],
                    [
                      -116.20412,
                      46.65575
                    ],
                    [
                      -116.20403,
                      46.65596
                    ],
                    [
                      -116.204,
                      46.6561
                    ],
                    [
                      -116.20401,
                      46.65624
                    ],
                    [
                      -116.20404,
                      46.65638
                    ],
                    [
                      -116.20411,
                      46.65652
                    ],
                    [
                      -116.2042,
                      46.65665
                    ],
                    [
                      -116.20424,
                      46.65669
                    ],
                    [
                      -116.20434,
                      46.65684
                    ],
                    [
                      -116.20439,
                      46.65699
                    ],
                    [
                      -116.20438,
                      46.65707
                    ],
                    [
                      -116.20436,
                      46.65714
                    ],
                    [
                      -116.20431,
                      46.65723
                    ],
                    [
                      -116.20416,
                      46.65735
                    ],
                    [
                      -116.20364,
                      46.65766
                    ],
                    [
                      -116.20322,
                      46.65803
                    ],
                    [
                      -116.20259,
                      46.65845
                    ],
                    [
                      -116.2018,
                      46.65912
                    ],
                    [
                      -116.20145,
                      46.65948
                    ],
                    [
                      -116.20117,
                      46.65986
                    ],
                    [
                      -116.20107,
                      46.66008
                    ],
                    [
                      -116.20099,
                      46.66037
                    ],
                    [
                      -116.201,
                      46.66059
                    ],
                    [
                      -116.20097,
                      46.66081
                    ],
                    [
                      -116.20083,
                      46.66104
                    ],
                    [
                      -116.20067,
                      46.66122
                    ],
                    [
                      -116.19966,
                      46.66197
                    ],
                    [
                      -116.19879,
                      46.66276
                    ],
                    [
                      -116.19679,
                      46.66426
                    ],
                    [
                      -116.1966,
                      46.66443
                    ],
                    [
                      -116.19646,
                      46.66461
                    ],
                    [
                      -116.196,
                      46.6654
                    ],
                    [
                      -116.19594,
                      46.66554
                    ],
                    [
                      -116.19591,
                      46.66568
                    ],
                    [
                      -116.19591,
                      46.66579
                    ],
                    [
                      -116.19597,
                      46.66594
                    ],
                    [
                      -116.1965,
                      46.6668
                    ],
                    [
                      -116.19665,
                      46.66716
                    ],
                    [
                      -116.19685,
                      46.66752
                    ],
                    [
                      -116.19687,
                      46.66758
                    ],
                    [
                      -116.19712,
                      46.66809
                    ],
                    [
                      -116.19713,
                      46.6681
                    ],
                    [
                      -116.19714,
                      46.66812
                    ],
                    [
                      -116.19715,
                      46.66868
                    ],
                    [
                      -116.19704,
                      46.6689
                    ],
                    [
                      -116.19696,
                      46.66915
                    ],
                    [
                      -116.19693,
                      46.66945
                    ],
                    [
                      -116.19693,
                      46.66975
                    ],
                    [
                      -116.19676,
                      46.67007
                    ],
                    [
                      -116.19671,
                      46.67019
                    ],
                    [
                      -116.19672,
                      46.67029
                    ],
                    [
                      -116.19677,
                      46.67038
                    ],
                    [
                      -116.19686,
                      46.67045
                    ],
                    [
                      -116.19708,
                      46.67057
                    ],
                    [
                      -116.19755,
                      46.67069
                    ],
                    [
                      -116.19774,
                      46.67076
                    ],
                    [
                      -116.19792,
                      46.67085
                    ],
                    [
                      -116.19817,
                      46.67103
                    ],
                    [
                      -116.19838,
                      46.67125
                    ],
                    [
                      -116.19875,
                      46.67159
                    ],
                    [
                      -116.19908,
                      46.67195
                    ],
                    [
                      -116.19944,
                      46.67249
                    ],
                    [
                      -116.19947,
                      46.67252
                    ],
                    [
                      -116.19983,
                      46.67346
                    ],
                    [
                      -116.19993,
                      46.67364
                    ],
                    [
                      -116.20011,
                      46.67378
                    ],
                    [
                      -116.20125,
                      46.67451
                    ],
                    [
                      -116.20195,
                      46.67485
                    ],
                    [
                      -116.2021,
                      46.67499
                    ],
                    [
                      -116.20218,
                      46.67514
                    ],
                    [
                      -116.20218,
                      46.67522
                    ],
                    [
                      -116.20214,
                      46.67528
                    ],
                    [
                      -116.20201,
                      46.67536
                    ],
                    [
                      -116.20185,
                      46.67539
                    ],
                    [
                      -116.20137,
                      46.67529
                    ],
                    [
                      -116.20115,
                      46.67527
                    ],
                    [
                      -116.20083,
                      46.67528
                    ],
                    [
                      -116.20078,
                      46.67529
                    ],
                    [
                      -116.20054,
                      46.67538
                    ],
                    [
                      -116.20029,
                      46.67551
                    ],
                    [
                      -116.20008,
                      46.67567
                    ],
                    [
                      -116.19988,
                      46.67585
                    ],
                    [
                      -116.19966,
                      46.676
                    ],
                    [
                      -116.19941,
                      46.67614
                    ],
                    [
                      -116.19888,
                      46.67638
                    ],
                    [
                      -116.19829,
                      46.67654
                    ],
                    [
                      -116.1969,
                      46.67707
                    ],
                    [
                      -116.19639,
                      46.67733
                    ],
                    [
                      -116.19592,
                      46.67763
                    ],
                    [
                      -116.19552,
                      46.67797
                    ],
                    [
                      -116.1952,
                      46.67816
                    ],
                    [
                      -116.19477,
                      46.67836
                    ],
                    [
                      -116.19449,
                      46.6786
                    ],
                    [
                      -116.19447,
                      46.67866
                    ],
                    [
                      -116.19448,
                      46.67876
                    ],
                    [
                      -116.19451,
                      46.67883
                    ],
                    [
                      -116.19469,
                      46.679
                    ],
                    [
                      -116.1947,
                      46.679
                    ],
                    [
                      -116.19485,
                      46.67908
                    ],
                    [
                      -116.19514,
                      46.67917
                    ],
                    [
                      -116.1954,
                      46.67928
                    ],
                    [
                      -116.19555,
                      46.67938
                    ],
                    [
                      -116.19566,
                      46.6795
                    ],
                    [
                      -116.19569,
                      46.67964
                    ],
                    [
                      -116.19565,
                      46.67978
                    ],
                    [
                      -116.19556,
                      46.6799
                    ],
                    [
                      -116.19542,
                      46.68002
                    ],
                    [
                      -116.19525,
                      46.6801
                    ],
                    [
                      -116.19504,
                      46.68014
                    ],
                    [
                      -116.19452,
                      46.68017
                    ],
                    [
                      -116.19421,
                      46.68021
                    ],
                    [
                      -116.19387,
                      46.68028
                    ],
                    [
                      -116.19364,
                      46.68038
                    ],
                    [
                      -116.19343,
                      46.68055
                    ],
                    [
                      -116.19314,
                      46.68093
                    ],
                    [
                      -116.19301,
                      46.68104
                    ],
                    [
                      -116.19284,
                      46.68111
                    ],
                    [
                      -116.19263,
                      46.68115
                    ],
                    [
                      -116.19232,
                      46.68114
                    ],
                    [
                      -116.1918,
                      46.68119
                    ],
                    [
                      -116.19152,
                      46.68127
                    ],
                    [
                      -116.1907,
                      46.6816
                    ],
                    [
                      -116.18981,
                      46.68181
                    ],
                    [
                      -116.18925,
                      46.68198
                    ],
                    [
                      -116.18757,
                      46.68273
                    ],
                    [
                      -116.18732,
                      46.68277
                    ],
                    [
                      -116.187,
                      46.68274
                    ],
                    [
                      -116.18622,
                      46.68249
                    ],
                    [
                      -116.18603,
                      46.68245
                    ],
                    [
                      -116.18588,
                      46.68246
                    ],
                    [
                      -116.18581,
                      46.68249
                    ],
                    [
                      -116.18578,
                      46.68251
                    ],
                    [
                      -116.18572,
                      46.68257
                    ],
                    [
                      -116.18558,
                      46.68284
                    ],
                    [
                      -116.1855,
                      46.68304
                    ],
                    [
                      -116.18545,
                      46.68325
                    ],
                    [
                      -116.18538,
                      46.68345
                    ],
                    [
                      -116.18507,
                      46.68405
                    ],
                    [
                      -116.18497,
                      46.68429
                    ],
                    [
                      -116.18466,
                      46.6848
                    ],
                    [
                      -116.18453,
                      46.68497
                    ],
                    [
                      -116.18449,
                      46.68509
                    ],
                    [
                      -116.18448,
                      46.68517
                    ],
                    [
                      -116.18452,
                      46.68526
                    ],
                    [
                      -116.18485,
                      46.68549
                    ],
                    [
                      -116.18491,
                      46.68561
                    ],
                    [
                      -116.18492,
                      46.6858
                    ],
                    [
                      -116.18488,
                      46.68626
                    ],
                    [
                      -116.18482,
                      46.68634
                    ],
                    [
                      -116.18474,
                      46.6864
                    ],
                    [
                      -116.18463,
                      46.68644
                    ],
                    [
                      -116.18437,
                      46.68645
                    ],
                    [
                      -116.18435,
                      46.68644
                    ],
                    [
                      -116.18383,
                      46.68637
                    ],
                    [
                      -116.18352,
                      46.68635
                    ],
                    [
                      -116.1826,
                      46.68642
                    ],
                    [
                      -116.18229,
                      46.68647
                    ],
                    [
                      -116.18167,
                      46.6865
                    ],
                    [
                      -116.18147,
                      46.68653
                    ],
                    [
                      -116.18128,
                      46.68659
                    ],
                    [
                      -116.18112,
                      46.68668
                    ],
                    [
                      -116.18099,
                      46.68679
                    ],
                    [
                      -116.18082,
                      46.687
                    ],
                    [
                      -116.18081,
                      46.68703
                    ],
                    [
                      -116.18079,
                      46.68704
                    ],
                    [
                      -116.18054,
                      46.68744
                    ],
                    [
                      -116.18007,
                      46.68801
                    ],
                    [
                      -116.17993,
                      46.68813
                    ],
                    [
                      -116.1798,
                      46.68821
                    ],
                    [
                      -116.17959,
                      46.68829
                    ],
                    [
                      -116.17894,
                      46.6884
                    ],
                    [
                      -116.17864,
                      46.6885
                    ],
                    [
                      -116.17839,
                      46.68863
                    ],
                    [
                      -116.17828,
                      46.68875
                    ],
                    [
                      -116.17816,
                      46.68901
                    ],
                    [
                      -116.17805,
                      46.68912
                    ],
                    [
                      -116.17789,
                      46.6892
                    ],
                    [
                      -116.17736,
                      46.68936
                    ],
                    [
                      -116.1773,
                      46.68941
                    ],
                    [
                      -116.17725,
                      46.68947
                    ],
                    [
                      -116.17722,
                      46.68953
                    ],
                    [
                      -116.17722,
                      46.6896
                    ],
                    [
                      -116.17723,
                      46.68966
                    ],
                    [
                      -116.17727,
                      46.68973
                    ],
                    [
                      -116.17742,
                      46.68991
                    ],
                    [
                      -116.17749,
                      46.69004
                    ],
                    [
                      -116.1775,
                      46.69019
                    ],
                    [
                      -116.17744,
                      46.69031
                    ],
                    [
                      -116.17731,
                      46.69041
                    ],
                    [
                      -116.17714,
                      46.69048
                    ],
                    [
                      -116.1766,
                      46.69063
                    ],
                    [
                      -116.17589,
                      46.69087
                    ],
                    [
                      -116.1756,
                      46.69102
                    ],
                    [
                      -116.1755,
                      46.6911
                    ],
                    [
                      -116.17505,
                      46.69158
                    ],
                    [
                      -116.17498,
                      46.69168
                    ],
                    [
                      -116.17486,
                      46.69179
                    ],
                    [
                      -116.17456,
                      46.69237
                    ],
                    [
                      -116.17452,
                      46.69243
                    ],
                    [
                      -116.1744,
                      46.69277
                    ],
                    [
                      -116.17418,
                      46.69299
                    ],
                    [
                      -116.17383,
                      46.69321
                    ],
                    [
                      -116.17368,
                      46.69334
                    ],
                    [
                      -116.17357,
                      46.69348
                    ],
                    [
                      -116.17345,
                      46.6937
                    ],
                    [
                      -116.17338,
                      46.69402
                    ],
                    [
                      -116.17329,
                      46.69421
                    ],
                    [
                      -116.17308,
                      46.69441
                    ],
                    [
                      -116.17294,
                      46.6945
                    ],
                    [
                      -116.17289,
                      46.69452
                    ],
                    [
                      -116.17271,
                      46.69455
                    ],
                    [
                      -116.17257,
                      46.69451
                    ],
                    [
                      -116.17245,
                      46.69444
                    ],
                    [
                      -116.17192,
                      46.69376
                    ],
                    [
                      -116.1718,
                      46.69368
                    ],
                    [
                      -116.17091,
                      46.69357
                    ],
                    [
                      -116.17076,
                      46.69358
                    ],
                    [
                      -116.17065,
                      46.69363
                    ],
                    [
                      -116.17057,
                      46.69369
                    ],
                    [
                      -116.17056,
                      46.69371
                    ],
                    [
                      -116.17056,
                      46.69372
                    ],
                    [
                      -116.17052,
                      46.69375
                    ],
                    [
                      -116.1705,
                      46.69381
                    ],
                    [
                      -116.17055,
                      46.69394
                    ],
                    [
                      -116.17073,
                      46.6941
                    ],
                    [
                      -116.17105,
                      46.69448
                    ],
                    [
                      -116.17119,
                      46.69468
                    ],
                    [
                      -116.1714,
                      46.69509
                    ],
                    [
                      -116.1714,
                      46.69526
                    ],
                    [
                      -116.17135,
                      46.69541
                    ],
                    [
                      -116.17126,
                      46.69558
                    ],
                    [
                      -116.17115,
                      46.69566
                    ],
                    [
                      -116.17096,
                      46.69571
                    ],
                    [
                      -116.17008,
                      46.69561
                    ],
                    [
                      -116.1698,
                      46.69554
                    ],
                    [
                      -116.16947,
                      46.6955
                    ],
                    [
                      -116.1689,
                      46.69537
                    ],
                    [
                      -116.16839,
                      46.69517
                    ],
                    [
                      -116.16666,
                      46.69468
                    ],
                    [
                      -116.16645,
                      46.69465
                    ],
                    [
                      -116.16625,
                      46.69466
                    ],
                    [
                      -116.16595,
                      46.6947
                    ],
                    [
                      -116.16535,
                      46.69483
                    ],
                    [
                      -116.1649,
                      46.69496
                    ],
                    [
                      -116.1647,
                      46.69504
                    ],
                    [
                      -116.16436,
                      46.69531
                    ],
                    [
                      -116.16431,
                      46.69538
                    ],
                    [
                      -116.16429,
                      46.69545
                    ],
                    [
                      -116.16428,
                      46.69553
                    ],
                    [
                      -116.16434,
                      46.69562
                    ],
                    [
                      -116.16516,
                      46.6965
                    ],
                    [
                      -116.16518,
                      46.69653
                    ],
                    [
                      -116.16529,
                      46.69664
                    ],
                    [
                      -116.16534,
                      46.69667
                    ],
                    [
                      -116.16536,
                      46.69669
                    ],
                    [
                      -116.16545,
                      46.69674
                    ],
                    [
                      -116.16565,
                      46.69682
                    ],
                    [
                      -116.16595,
                      46.69687
                    ],
                    [
                      -116.16625,
                      46.69685
                    ],
                    [
                      -116.16646,
                      46.6968
                    ],
                    [
                      -116.16667,
                      46.69677
                    ],
                    [
                      -116.16687,
                      46.69678
                    ],
                    [
                      -116.16707,
                      46.69682
                    ],
                    [
                      -116.16725,
                      46.6969
                    ],
                    [
                      -116.16729,
                      46.69691
                    ],
                    [
                      -116.16741,
                      46.69697
                    ],
                    [
                      -116.16779,
                      46.69723
                    ],
                    [
                      -116.1679,
                      46.69734
                    ],
                    [
                      -116.16799,
                      46.69746
                    ],
                    [
                      -116.16815,
                      46.69758
                    ],
                    [
                      -116.16836,
                      46.69768
                    ],
                    [
                      -116.16851,
                      46.69773
                    ],
                    [
                      -116.16868,
                      46.69781
                    ],
                    [
                      -116.16884,
                      46.69791
                    ],
                    [
                      -116.1689,
                      46.69796
                    ],
                    [
                      -116.16897,
                      46.69804
                    ],
                    [
                      -116.16901,
                      46.69816
                    ],
                    [
                      -116.16904,
                      46.69822
                    ],
                    [
                      -116.16906,
                      46.69857
                    ],
                    [
                      -116.169,
                      46.69872
                    ],
                    [
                      -116.169,
                      46.69873
                    ],
                    [
                      -116.16889,
                      46.69887
                    ],
                    [
                      -116.16883,
                      46.69897
                    ],
                    [
                      -116.1688,
                      46.69904
                    ],
                    [
                      -116.1688,
                      46.69906
                    ],
                    [
                      -116.16878,
                      46.69911
                    ],
                    [
                      -116.16879,
                      46.69919
                    ],
                    [
                      -116.16883,
                      46.69923
                    ],
                    [
                      -116.16894,
                      46.69945
                    ],
                    [
                      -116.16897,
                      46.69959
                    ],
                    [
                      -116.16894,
                      46.69964
                    ],
                    [
                      -116.16894,
                      46.69966
                    ],
                    [
                      -116.16887,
                      46.69974
                    ],
                    [
                      -116.16834,
                      46.70011
                    ],
                    [
                      -116.16784,
                      46.70037
                    ],
                    [
                      -116.16767,
                      46.70048
                    ],
                    [
                      -116.16754,
                      46.70062
                    ],
                    [
                      -116.16751,
                      46.70069
                    ],
                    [
                      -116.16755,
                      46.70084
                    ],
                    [
                      -116.16763,
                      46.70104
                    ],
                    [
                      -116.16768,
                      46.70125
                    ],
                    [
                      -116.16768,
                      46.70132
                    ],
                    [
                      -116.16766,
                      46.70139
                    ],
                    [
                      -116.16761,
                      46.70146
                    ],
                    [
                      -116.16755,
                      46.70152
                    ],
                    [
                      -116.16704,
                      46.70167
                    ],
                    [
                      -116.16672,
                      46.7018
                    ],
                    [
                      -116.16648,
                      46.70194
                    ],
                    [
                      -116.16632,
                      46.70206
                    ],
                    [
                      -116.16599,
                      46.70265
                    ],
                    [
                      -116.16571,
                      46.70304
                    ],
                    [
                      -116.1656,
                      46.70324
                    ],
                    [
                      -116.16543,
                      46.70348
                    ],
                    [
                      -116.1654,
                      46.70355
                    ],
                    [
                      -116.16538,
                      46.70364
                    ],
                    [
                      -116.16539,
                      46.70371
                    ],
                    [
                      -116.16542,
                      46.7038
                    ],
                    [
                      -116.16548,
                      46.70387
                    ],
                    [
                      -116.16551,
                      46.70397
                    ],
                    [
                      -116.16568,
                      46.70432
                    ],
                    [
                      -116.16574,
                      46.70439
                    ],
                    [
                      -116.16575,
                      46.70441
                    ],
                    [
                      -116.16616,
                      46.70471
                    ],
                    [
                      -116.16623,
                      46.70485
                    ],
                    [
                      -116.16624,
                      46.70529
                    ],
                    [
                      -116.16629,
                      46.70547
                    ],
                    [
                      -116.16636,
                      46.70557
                    ],
                    [
                      -116.16645,
                      46.70567
                    ],
                    [
                      -116.1665,
                      46.70581
                    ],
                    [
                      -116.1665,
                      46.70588
                    ],
                    [
                      -116.16635,
                      46.70599
                    ],
                    [
                      -116.16613,
                      46.70622
                    ],
                    [
                      -116.16605,
                      46.70646
                    ],
                    [
                      -116.16594,
                      46.70701
                    ],
                    [
                      -116.16604,
                      46.7073
                    ],
                    [
                      -116.16623,
                      46.70767
                    ],
                    [
                      -116.16644,
                      46.70785
                    ],
                    [
                      -116.16671,
                      46.70812
                    ],
                    [
                      -116.16675,
                      46.70829
                    ],
                    [
                      -116.16669,
                      46.70844
                    ],
                    [
                      -116.16665,
                      46.7085
                    ],
                    [
                      -116.16648,
                      46.70866
                    ],
                    [
                      -116.16575,
                      46.70907
                    ],
                    [
                      -116.16558,
                      46.70912
                    ],
                    [
                      -116.16539,
                      46.70914
                    ],
                    [
                      -116.16532,
                      46.70913
                    ],
                    [
                      -116.16514,
                      46.70907
                    ],
                    [
                      -116.16477,
                      46.70883
                    ],
                    [
                      -116.1647,
                      46.70877
                    ],
                    [
                      -116.16448,
                      46.70866
                    ],
                    [
                      -116.16426,
                      46.7086
                    ],
                    [
                      -116.16352,
                      46.70855
                    ],
                    [
                      -116.16308,
                      46.7086
                    ],
                    [
                      -116.16216,
                      46.70886
                    ],
                    [
                      -116.1619,
                      46.70888
                    ],
                    [
                      -116.16086,
                      46.70904
                    ],
                    [
                      -116.16036,
                      46.70916
                    ],
                    [
                      -116.15955,
                      46.70929
                    ],
                    [
                      -116.1593,
                      46.70929
                    ],
                    [
                      -116.15856,
                      46.70915
                    ],
                    [
                      -116.15836,
                      46.70918
                    ],
                    [
                      -116.15824,
                      46.70923
                    ],
                    [
                      -116.15816,
                      46.70929
                    ],
                    [
                      -116.1581,
                      46.70932
                    ],
                    [
                      -116.15809,
                      46.70934
                    ],
                    [
                      -116.15802,
                      46.70939
                    ],
                    [
                      -116.15787,
                      46.70958
                    ],
                    [
                      -116.15785,
                      46.70965
                    ],
                    [
                      -116.15785,
                      46.70972
                    ],
                    [
                      -116.15789,
                      46.70981
                    ],
                    [
                      -116.1581,
                      46.71011
                    ],
                    [
                      -116.1582,
                      46.71031
                    ],
                    [
                      -116.15821,
                      46.71038
                    ],
                    [
                      -116.1582,
                      46.71052
                    ],
                    [
                      -116.15814,
                      46.71066
                    ],
                    [
                      -116.15794,
                      46.7109
                    ],
                    [
                      -116.15782,
                      46.71102
                    ],
                    [
                      -116.15775,
                      46.71107
                    ],
                    [
                      -116.15758,
                      46.71115
                    ],
                    [
                      -116.15746,
                      46.71119
                    ],
                    [
                      -116.15739,
                      46.7112
                    ],
                    [
                      -116.15728,
                      46.7112
                    ],
                    [
                      -116.15686,
                      46.71116
                    ],
                    [
                      -116.15642,
                      46.71115
                    ],
                    [
                      -116.15632,
                      46.71116
                    ],
                    [
                      -116.15621,
                      46.71119
                    ],
                    [
                      -116.15615,
                      46.71122
                    ],
                    [
                      -116.15608,
                      46.71128
                    ],
                    [
                      -116.15604,
                      46.71135
                    ],
                    [
                      -116.15598,
                      46.71141
                    ],
                    [
                      -116.15585,
                      46.7116
                    ],
                    [
                      -116.15575,
                      46.7118
                    ],
                    [
                      -116.15573,
                      46.71187
                    ],
                    [
                      -116.15568,
                      46.71223
                    ],
                    [
                      -116.15561,
                      46.71236
                    ],
                    [
                      -116.15559,
                      46.71237
                    ],
                    [
                      -116.15555,
                      46.71242
                    ],
                    [
                      -116.15539,
                      46.71251
                    ],
                    [
                      -116.1553,
                      46.71254
                    ],
                    [
                      -116.154,
                      46.71281
                    ],
                    [
                      -116.15389,
                      46.71281
                    ],
                    [
                      -116.15369,
                      46.71277
                    ],
                    [
                      -116.15342,
                      46.71266
                    ],
                    [
                      -116.15327,
                      46.71256
                    ],
                    [
                      -116.15314,
                      46.71244
                    ],
                    [
                      -116.15295,
                      46.71218
                    ],
                    [
                      -116.15284,
                      46.71206
                    ],
                    [
                      -116.15277,
                      46.71201
                    ],
                    [
                      -116.15268,
                      46.71198
                    ],
                    [
                      -116.15257,
                      46.71197
                    ],
                    [
                      -116.15226,
                      46.71198
                    ],
                    [
                      -116.15205,
                      46.71195
                    ],
                    [
                      -116.15186,
                      46.71189
                    ],
                    [
                      -116.15166,
                      46.7118
                    ],
                    [
                      -116.15138,
                      46.71161
                    ],
                    [
                      -116.15122,
                      46.71152
                    ],
                    [
                      -116.15089,
                      46.71138
                    ],
                    [
                      -116.15083,
                      46.71137
                    ],
                    [
                      -116.15068,
                      46.71138
                    ],
                    [
                      -116.15055,
                      46.71143
                    ],
                    [
                      -116.1505,
                      46.71148
                    ],
                    [
                      -116.15049,
                      46.71153
                    ],
                    [
                      -116.1505,
                      46.71157
                    ],
                    [
                      -116.15058,
                      46.71167
                    ],
                    [
                      -116.15095,
                      46.71192
                    ],
                    [
                      -116.15119,
                      46.71204
                    ],
                    [
                      -116.15143,
                      46.71237
                    ],
                    [
                      -116.15153,
                      46.71254
                    ],
                    [
                      -116.15171,
                      46.71275
                    ],
                    [
                      -116.15219,
                      46.71324
                    ],
                    [
                      -116.15244,
                      46.71346
                    ],
                    [
                      -116.15266,
                      46.71358
                    ],
                    [
                      -116.15292,
                      46.71369
                    ],
                    [
                      -116.15346,
                      46.71379
                    ],
                    [
                      -116.15365,
                      46.71381
                    ],
                    [
                      -116.15383,
                      46.71381
                    ],
                    [
                      -116.15399,
                      46.71384
                    ],
                    [
                      -116.15411,
                      46.7139
                    ],
                    [
                      -116.15427,
                      46.71401
                    ],
                    [
                      -116.15439,
                      46.71412
                    ],
                    [
                      -116.15495,
                      46.71477
                    ],
                    [
                      -116.15527,
                      46.7152
                    ],
                    [
                      -116.15582,
                      46.71558
                    ],
                    [
                      -116.15586,
                      46.71567
                    ],
                    [
                      -116.15589,
                      46.71571
                    ],
                    [
                      -116.1559,
                      46.71585
                    ],
                    [
                      -116.15589,
                      46.71591
                    ],
                    [
                      -116.1558,
                      46.71601
                    ],
                    [
                      -116.15567,
                      46.71609
                    ],
                    [
                      -116.1544,
                      46.71649
                    ],
                    [
                      -116.15416,
                      46.71663
                    ],
                    [
                      -116.15406,
                      46.71679
                    ],
                    [
                      -116.15403,
                      46.71692
                    ],
                    [
                      -116.1541,
                      46.71706
                    ],
                    [
                      -116.1542,
                      46.71715
                    ],
                    [
                      -116.15434,
                      46.71721
                    ],
                    [
                      -116.15451,
                      46.71723
                    ],
                    [
                      -116.15466,
                      46.71722
                    ],
                    [
                      -116.15476,
                      46.71718
                    ],
                    [
                      -116.15489,
                      46.71711
                    ],
                    [
                      -116.15494,
                      46.71706
                    ],
                    [
                      -116.155,
                      46.71696
                    ],
                    [
                      -116.15511,
                      46.7169
                    ],
                    [
                      -116.15538,
                      46.71685
                    ],
                    [
                      -116.15566,
                      46.71688
                    ],
                    [
                      -116.15569,
                      46.71689
                    ],
                    [
                      -116.1561,
                      46.71692
                    ],
                    [
                      -116.15626,
                      46.71696
                    ],
                    [
                      -116.15635,
                      46.717
                    ],
                    [
                      -116.15642,
                      46.71705
                    ],
                    [
                      -116.15656,
                      46.71723
                    ],
                    [
                      -116.15662,
                      46.71736
                    ],
                    [
                      -116.15673,
                      46.7179
                    ],
                    [
                      -116.15686,
                      46.71808
                    ],
                    [
                      -116.15732,
                      46.71841
                    ],
                    [
                      -116.15743,
                      46.71852
                    ],
                    [
                      -116.15754,
                      46.71859
                    ],
                    [
                      -116.15769,
                      46.71874
                    ],
                    [
                      -116.15787,
                      46.71902
                    ],
                    [
                      -116.15796,
                      46.71919
                    ],
                    [
                      -116.15795,
                      46.71927
                    ],
                    [
                      -116.15786,
                      46.71938
                    ],
                    [
                      -116.15771,
                      46.71953
                    ],
                    [
                      -116.15767,
                      46.71959
                    ],
                    [
                      -116.15764,
                      46.71967
                    ],
                    [
                      -116.15765,
                      46.71974
                    ],
                    [
                      -116.15767,
                      46.7198
                    ],
                    [
                      -116.15771,
                      46.71985
                    ],
                    [
                      -116.15783,
                      46.71992
                    ],
                    [
                      -116.15784,
                      46.71992
                    ],
                    [
                      -116.1582,
                      46.72004
                    ],
                    [
                      -116.15829,
                      46.7201
                    ],
                    [
                      -116.15837,
                      46.72019
                    ],
                    [
                      -116.15841,
                      46.72026
                    ],
                    [
                      -116.15851,
                      46.72051
                    ],
                    [
                      -116.15852,
                      46.72051
                    ],
                    [
                      -116.15863,
                      46.72078
                    ],
                    [
                      -116.1587,
                      46.72085
                    ],
                    [
                      -116.15882,
                      46.72091
                    ],
                    [
                      -116.15888,
                      46.72098
                    ],
                    [
                      -116.15891,
                      46.72106
                    ],
                    [
                      -116.15892,
                      46.72124
                    ],
                    [
                      -116.15856,
                      46.72207
                    ],
                    [
                      -116.15854,
                      46.72214
                    ],
                    [
                      -116.15854,
                      46.72222
                    ],
                    [
                      -116.15857,
                      46.72229
                    ],
                    [
                      -116.15861,
                      46.72235
                    ],
                    [
                      -116.15868,
                      46.72241
                    ],
                    [
                      -116.15901,
                      46.72256
                    ],
                    [
                      -116.15908,
                      46.72263
                    ],
                    [
                      -116.15913,
                      46.72271
                    ],
                    [
                      -116.15928,
                      46.72307
                    ],
                    [
                      -116.15929,
                      46.72308
                    ],
                    [
                      -116.15931,
                      46.72318
                    ],
                    [
                      -116.15932,
                      46.7232
                    ],
                    [
                      -116.15937,
                      46.72324
                    ],
                    [
                      -116.15938,
                      46.72326
                    ],
                    [
                      -116.15948,
                      46.7233
                    ],
                    [
                      -116.15949,
                      46.72331
                    ],
                    [
                      -116.1599,
                      46.72336
                    ],
                    [
                      -116.16021,
                      46.72349
                    ],
                    [
                      -116.16029,
                      46.72354
                    ],
                    [
                      -116.16038,
                      46.72363
                    ],
                    [
                      -116.16049,
                      46.72379
                    ],
                    [
                      -116.16051,
                      46.72387
                    ],
                    [
                      -116.16052,
                      46.72388
                    ],
                    [
                      -116.16051,
                      46.72397
                    ],
                    [
                      -116.16048,
                      46.72407
                    ],
                    [
                      -116.16047,
                      46.72415
                    ],
                    [
                      -116.16042,
                      46.72429
                    ],
                    [
                      -116.16035,
                      46.72441
                    ],
                    [
                      -116.16028,
                      46.72448
                    ],
                    [
                      -116.16028,
                      46.72449
                    ],
                    [
                      -116.1602,
                      46.72453
                    ],
                    [
                      -116.15994,
                      46.7246
                    ],
                    [
                      -116.1593,
                      46.7247
                    ],
                    [
                      -116.15831,
                      46.72471
                    ],
                    [
                      -116.15812,
                      46.72475
                    ],
                    [
                      -116.15803,
                      46.72479
                    ],
                    [
                      -116.15795,
                      46.72484
                    ],
                    [
                      -116.1579,
                      46.7249
                    ],
                    [
                      -116.15789,
                      46.72495
                    ],
                    [
                      -116.15798,
                      46.72509
                    ],
                    [
                      -116.15811,
                      46.7252
                    ],
                    [
                      -116.15827,
                      46.7253
                    ],
                    [
                      -116.15846,
                      46.72536
                    ],
                    [
                      -116.1589,
                      46.72538
                    ],
                    [
                      -116.15912,
                      46.72543
                    ],
                    [
                      -116.16002,
                      46.72571
                    ],
                    [
                      -116.16047,
                      46.72581
                    ],
                    [
                      -116.16118,
                      46.72581
                    ],
                    [
                      -116.16132,
                      46.72587
                    ],
                    [
                      -116.16147,
                      46.72598
                    ],
                    [
                      -116.16162,
                      46.72622
                    ],
                    [
                      -116.16164,
                      46.72628
                    ],
                    [
                      -116.16164,
                      46.72631
                    ],
                    [
                      -116.16171,
                      46.72637
                    ],
                    [
                      -116.16221,
                      46.72665
                    ],
                    [
                      -116.16237,
                      46.72679
                    ],
                    [
                      -116.1626,
                      46.72686
                    ],
                    [
                      -116.16314,
                      46.72689
                    ],
                    [
                      -116.16336,
                      46.72696
                    ],
                    [
                      -116.16367,
                      46.72712
                    ],
                    [
                      -116.16408,
                      46.72728
                    ],
                    [
                      -116.1642,
                      46.72735
                    ],
                    [
                      -116.16421,
                      46.72738
                    ],
                    [
                      -116.16425,
                      46.72745
                    ],
                    [
                      -116.16425,
                      46.72756
                    ],
                    [
                      -116.16419,
                      46.72773
                    ],
                    [
                      -116.16407,
                      46.72799
                    ],
                    [
                      -116.16386,
                      46.72826
                    ],
                    [
                      -116.16373,
                      46.72836
                    ],
                    [
                      -116.1633,
                      46.72862
                    ],
                    [
                      -116.1632,
                      46.7287
                    ],
                    [
                      -116.16315,
                      46.72876
                    ],
                    [
                      -116.16312,
                      46.72889
                    ],
                    [
                      -116.16311,
                      46.72904
                    ],
                    [
                      -116.16309,
                      46.72909
                    ],
                    [
                      -116.16291,
                      46.72922
                    ],
                    [
                      -116.16222,
                      46.72952
                    ],
                    [
                      -116.16202,
                      46.72957
                    ],
                    [
                      -116.16188,
                      46.72964
                    ],
                    [
                      -116.16168,
                      46.72986
                    ],
                    [
                      -116.16159,
                      46.73004
                    ],
                    [
                      -116.16155,
                      46.73037
                    ],
                    [
                      -116.16161,
                      46.73074
                    ],
                    [
                      -116.16165,
                      46.73086
                    ],
                    [
                      -116.16165,
                      46.73097
                    ],
                    [
                      -116.16158,
                      46.73106
                    ],
                    [
                      -116.16145,
                      46.73112
                    ],
                    [
                      -116.16131,
                      46.73113
                    ],
                    [
                      -116.16129,
                      46.73114
                    ],
                    [
                      -116.16072,
                      46.73107
                    ],
                    [
                      -116.1604,
                      46.73112
                    ],
                    [
                      -116.16011,
                      46.73112
                    ],
                    [
                      -116.1599,
                      46.73106
                    ],
                    [
                      -116.15979,
                      46.73097
                    ],
                    [
                      -116.15948,
                      46.73056
                    ],
                    [
                      -116.15944,
                      46.73038
                    ],
                    [
                      -116.15944,
                      46.73034
                    ],
                    [
                      -116.15947,
                      46.73018
                    ],
                    [
                      -116.15936,
                      46.72994
                    ],
                    [
                      -116.15924,
                      46.72981
                    ],
                    [
                      -116.15912,
                      46.72972
                    ],
                    [
                      -116.15899,
                      46.72969
                    ],
                    [
                      -116.15881,
                      46.72967
                    ],
                    [
                      -116.15863,
                      46.7297
                    ],
                    [
                      -116.15845,
                      46.72975
                    ],
                    [
                      -116.158,
                      46.73001
                    ],
                    [
                      -116.15765,
                      46.73018
                    ],
                    [
                      -116.15757,
                      46.73025
                    ],
                    [
                      -116.15756,
                      46.73028
                    ],
                    [
                      -116.15753,
                      46.73033
                    ],
                    [
                      -116.15755,
                      46.73047
                    ],
                    [
                      -116.15763,
                      46.73057
                    ],
                    [
                      -116.15779,
                      46.73068
                    ],
                    [
                      -116.15797,
                      46.7309
                    ],
                    [
                      -116.15802,
                      46.731
                    ],
                    [
                      -116.15803,
                      46.73117
                    ],
                    [
                      -116.15802,
                      46.73132
                    ],
                    [
                      -116.15798,
                      46.73145
                    ],
                    [
                      -116.15776,
                      46.73167
                    ],
                    [
                      -116.15761,
                      46.73178
                    ],
                    [
                      -116.15749,
                      46.73191
                    ],
                    [
                      -116.15743,
                      46.73204
                    ],
                    [
                      -116.15738,
                      46.73212
                    ],
                    [
                      -116.1572,
                      46.73288
                    ],
                    [
                      -116.15717,
                      46.7331
                    ],
                    [
                      -116.1571,
                      46.73319
                    ],
                    [
                      -116.15701,
                      46.73323
                    ],
                    [
                      -116.15698,
                      46.73325
                    ],
                    [
                      -116.1568,
                      46.73325
                    ],
                    [
                      -116.15643,
                      46.73318
                    ],
                    [
                      -116.15625,
                      46.73319
                    ],
                    [
                      -116.15613,
                      46.73326
                    ],
                    [
                      -116.15609,
                      46.73337
                    ],
                    [
                      -116.15608,
                      46.73352
                    ],
                    [
                      -116.15623,
                      46.73389
                    ],
                    [
                      -116.1562,
                      46.73398
                    ],
                    [
                      -116.15601,
                      46.73418
                    ],
                    [
                      -116.15595,
                      46.73427
                    ],
                    [
                      -116.15592,
                      46.73435
                    ],
                    [
                      -116.15591,
                      46.73435
                    ],
                    [
                      -116.15586,
                      46.73439
                    ],
                    [
                      -116.15578,
                      46.73443
                    ],
                    [
                      -116.1552,
                      46.73457
                    ],
                    [
                      -116.1549,
                      46.73478
                    ],
                    [
                      -116.15462,
                      46.73503
                    ],
                    [
                      -116.15442,
                      46.73528
                    ],
                    [
                      -116.15401,
                      46.73598
                    ],
                    [
                      -116.15388,
                      46.73606
                    ],
                    [
                      -116.15375,
                      46.73608
                    ],
                    [
                      -116.15354,
                      46.73606
                    ],
                    [
                      -116.15297,
                      46.73576
                    ],
                    [
                      -116.15257,
                      46.7357
                    ],
                    [
                      -116.15225,
                      46.7357
                    ],
                    [
                      -116.15169,
                      46.73586
                    ],
                    [
                      -116.15117,
                      46.73607
                    ],
                    [
                      -116.15093,
                      46.73619
                    ],
                    [
                      -116.15048,
                      46.73652
                    ],
                    [
                      -116.15019,
                      46.7368
                    ],
                    [
                      -116.15018,
                      46.73683
                    ],
                    [
                      -116.15009,
                      46.73699
                    ],
                    [
                      -116.15,
                      46.73723
                    ],
                    [
                      -116.14992,
                      46.73735
                    ],
                    [
                      -116.14951,
                      46.73772
                    ],
                    [
                      -116.14941,
                      46.73786
                    ],
                    [
                      -116.14935,
                      46.73798
                    ],
                    [
                      -116.14923,
                      46.73832
                    ],
                    [
                      -116.14922,
                      46.73841
                    ],
                    [
                      -116.14933,
                      46.73882
                    ],
                    [
                      -116.14957,
                      46.73953
                    ],
                    [
                      -116.14972,
                      46.74012
                    ],
                    [
                      -116.14983,
                      46.74027
                    ],
                    [
                      -116.14988,
                      46.74037
                    ],
                    [
                      -116.14989,
                      46.74059
                    ],
                    [
                      -116.14986,
                      46.7407
                    ],
                    [
                      -116.14969,
                      46.74098
                    ],
                    [
                      -116.14955,
                      46.74126
                    ],
                    [
                      -116.14942,
                      46.74136
                    ],
                    [
                      -116.14901,
                      46.74154
                    ],
                    [
                      -116.14895,
                      46.74158
                    ],
                    [
                      -116.14866,
                      46.74193
                    ],
                    [
                      -116.14851,
                      46.74207
                    ],
                    [
                      -116.14835,
                      46.74218
                    ],
                    [
                      -116.14817,
                      46.74228
                    ],
                    [
                      -116.14816,
                      46.74229
                    ],
                    [
                      -116.14778,
                      46.74248
                    ],
                    [
                      -116.14755,
                      46.74263
                    ],
                    [
                      -116.14744,
                      46.74267
                    ],
                    [
                      -116.14702,
                      46.74278
                    ],
                    [
                      -116.14688,
                      46.74285
                    ],
                    [
                      -116.14672,
                      46.74299
                    ],
                    [
                      -116.14651,
                      46.74312
                    ],
                    [
                      -116.14596,
                      46.74337
                    ],
                    [
                      -116.14572,
                      46.74354
                    ],
                    [
                      -116.14552,
                      46.74365
                    ],
                    [
                      -116.14537,
                      46.74371
                    ],
                    [
                      -116.14503,
                      46.74375
                    ],
                    [
                      -116.14475,
                      46.74388
                    ],
                    [
                      -116.14465,
                      46.74395
                    ],
                    [
                      -116.14456,
                      46.74407
                    ],
                    [
                      -116.14454,
                      46.74413
                    ],
                    [
                      -116.14452,
                      46.74426
                    ],
                    [
                      -116.14452,
                      46.7444
                    ],
                    [
                      -116.14453,
                      46.74447
                    ],
                    [
                      -116.14455,
                      46.74453
                    ],
                    [
                      -116.14457,
                      46.74455
                    ],
                    [
                      -116.14459,
                      46.74459
                    ],
                    [
                      -116.14464,
                      46.74465
                    ],
                    [
                      -116.14515,
                      46.74506
                    ],
                    [
                      -116.14524,
                      46.74516
                    ],
                    [
                      -116.1453,
                      46.74526
                    ],
                    [
                      -116.14537,
                      46.74542
                    ],
                    [
                      -116.1454,
                      46.74555
                    ],
                    [
                      -116.14543,
                      46.74563
                    ],
                    [
                      -116.14555,
                      46.74574
                    ],
                    [
                      -116.14563,
                      46.74579
                    ],
                    [
                      -116.14603,
                      46.74595
                    ],
                    [
                      -116.14604,
                      46.74596
                    ],
                    [
                      -116.14648,
                      46.74615
                    ],
                    [
                      -116.14692,
                      46.74622
                    ],
                    [
                      -116.14716,
                      46.74628
                    ],
                    [
                      -116.14743,
                      46.7464
                    ],
                    [
                      -116.14758,
                      46.7465
                    ],
                    [
                      -116.14759,
                      46.7465
                    ],
                    [
                      -116.14779,
                      46.74668
                    ],
                    [
                      -116.14789,
                      46.7468
                    ],
                    [
                      -116.14798,
                      46.74696
                    ],
                    [
                      -116.14802,
                      46.74714
                    ],
                    [
                      -116.14802,
                      46.74732
                    ],
                    [
                      -116.14757,
                      46.74797
                    ],
                    [
                      -116.14752,
                      46.74808
                    ],
                    [
                      -116.14749,
                      46.74818
                    ],
                    [
                      -116.14747,
                      46.74832
                    ],
                    [
                      -116.14748,
                      46.74857
                    ],
                    [
                      -116.14751,
                      46.74872
                    ],
                    [
                      -116.1475,
                      46.74891
                    ],
                    [
                      -116.14755,
                      46.74934
                    ],
                    [
                      -116.14753,
                      46.74952
                    ],
                    [
                      -116.1475,
                      46.74961
                    ],
                    [
                      -116.14743,
                      46.74974
                    ],
                    [
                      -116.14734,
                      46.74987
                    ],
                    [
                      -116.14718,
                      46.75021
                    ],
                    [
                      -116.14716,
                      46.75035
                    ],
                    [
                      -116.14716,
                      46.75049
                    ],
                    [
                      -116.14719,
                      46.75064
                    ],
                    [
                      -116.14724,
                      46.75077
                    ],
                    [
                      -116.14736,
                      46.75091
                    ],
                    [
                      -116.14756,
                      46.7511
                    ],
                    [
                      -116.14779,
                      46.75117
                    ],
                    [
                      -116.1479,
                      46.75119
                    ],
                    [
                      -116.14806,
                      46.7512
                    ],
                    [
                      -116.14833,
                      46.75118
                    ],
                    [
                      -116.14844,
                      46.75116
                    ],
                    [
                      -116.14855,
                      46.75112
                    ],
                    [
                      -116.14863,
                      46.75107
                    ],
                    [
                      -116.14872,
                      46.75098
                    ],
                    [
                      -116.1488,
                      46.75088
                    ],
                    [
                      -116.14906,
                      46.75047
                    ],
                    [
                      -116.14927,
                      46.75024
                    ],
                    [
                      -116.14944,
                      46.75012
                    ],
                    [
                      -116.14976,
                      46.74996
                    ],
                    [
                      -116.15078,
                      46.74962
                    ],
                    [
                      -116.15142,
                      46.74937
                    ],
                    [
                      -116.15188,
                      46.74909
                    ],
                    [
                      -116.15215,
                      46.74895
                    ],
                    [
                      -116.15263,
                      46.74876
                    ],
                    [
                      -116.15288,
                      46.7487
                    ],
                    [
                      -116.15325,
                      46.7487
                    ],
                    [
                      -116.15399,
                      46.74876
                    ],
                    [
                      -116.1544,
                      46.74868
                    ],
                    [
                      -116.15459,
                      46.74861
                    ],
                    [
                      -116.15493,
                      46.74852
                    ],
                    [
                      -116.15521,
                      46.74841
                    ],
                    [
                      -116.15539,
                      46.74836
                    ],
                    [
                      -116.15586,
                      46.74827
                    ],
                    [
                      -116.1582,
                      46.74798
                    ],
                    [
                      -116.15868,
                      46.74788
                    ],
                    [
                      -116.15882,
                      46.74782
                    ],
                    [
                      -116.15903,
                      46.74769
                    ],
                    [
                      -116.15927,
                      46.7474
                    ],
                    [
                      -116.15941,
                      46.7472
                    ],
                    [
                      -116.15968,
                      46.74693
                    ],
                    [
                      -116.15988,
                      46.7468
                    ],
                    [
                      -116.16007,
                      46.74673
                    ],
                    [
                      -116.16048,
                      46.74673
                    ],
                    [
                      -116.16062,
                      46.74675
                    ],
                    [
                      -116.16079,
                      46.74681
                    ],
                    [
                      -116.16092,
                      46.74689
                    ],
                    [
                      -116.16102,
                      46.74699
                    ],
                    [
                      -116.16116,
                      46.74719
                    ],
                    [
                      -116.16116,
                      46.7472
                    ],
                    [
                      -116.16125,
                      46.74737
                    ],
                    [
                      -116.16135,
                      46.74766
                    ],
                    [
                      -116.16139,
                      46.74774
                    ],
                    [
                      -116.16142,
                      46.74789
                    ],
                    [
                      -116.16142,
                      46.74806
                    ],
                    [
                      -116.16138,
                      46.74822
                    ],
                    [
                      -116.16138,
                      46.74825
                    ],
                    [
                      -116.1613,
                      46.74862
                    ],
                    [
                      -116.16123,
                      46.74884
                    ],
                    [
                      -116.16115,
                      46.749
                    ],
                    [
                      -116.1611,
                      46.74923
                    ],
                    [
                      -116.16116,
                      46.74957
                    ],
                    [
                      -116.16148,
                      46.75014
                    ],
                    [
                      -116.16153,
                      46.75032
                    ],
                    [
                      -116.1615,
                      46.75043
                    ],
                    [
                      -116.16139,
                      46.75069
                    ],
                    [
                      -116.1613,
                      46.75086
                    ],
                    [
                      -116.16129,
                      46.75093
                    ],
                    [
                      -116.16131,
                      46.75108
                    ],
                    [
                      -116.16133,
                      46.75114
                    ],
                    [
                      -116.16137,
                      46.7512
                    ],
                    [
                      -116.16154,
                      46.75138
                    ],
                    [
                      -116.16194,
                      46.7517
                    ],
                    [
                      -116.16212,
                      46.7518
                    ],
                    [
                      -116.16287,
                      46.75233
                    ],
                    [
                      -116.16292,
                      46.75241
                    ],
                    [
                      -116.16296,
                      46.7525
                    ],
                    [
                      -116.16297,
                      46.75264
                    ],
                    [
                      -116.16295,
                      46.7527
                    ],
                    [
                      -116.16295,
                      46.75274
                    ],
                    [
                      -116.16285,
                      46.75288
                    ],
                    [
                      -116.16269,
                      46.75315
                    ],
                    [
                      -116.16268,
                      46.75335
                    ],
                    [
                      -116.16278,
                      46.75358
                    ],
                    [
                      -116.16288,
                      46.75369
                    ],
                    [
                      -116.16296,
                      46.75375
                    ],
                    [
                      -116.16313,
                      46.75385
                    ],
                    [
                      -116.16338,
                      46.75396
                    ],
                    [
                      -116.16356,
                      46.75401
                    ],
                    [
                      -116.16369,
                      46.75403
                    ],
                    [
                      -116.1638,
                      46.75407
                    ],
                    [
                      -116.16411,
                      46.75411
                    ],
                    [
                      -116.16619,
                      46.75406
                    ],
                    [
                      -116.167,
                      46.75399
                    ],
                    [
                      -116.16742,
                      46.75402
                    ],
                    [
                      -116.16785,
                      46.7541
                    ],
                    [
                      -116.16826,
                      46.75422
                    ],
                    [
                      -116.16853,
                      46.75436
                    ],
                    [
                      -116.16865,
                      46.75444
                    ],
                    [
                      -116.16877,
                      46.75454
                    ],
                    [
                      -116.16884,
                      46.75462
                    ],
                    [
                      -116.16892,
                      46.75477
                    ],
                    [
                      -116.16895,
                      46.75485
                    ],
                    [
                      -116.16897,
                      46.75496
                    ],
                    [
                      -116.16897,
                      46.75506
                    ],
                    [
                      -116.16894,
                      46.75527
                    ],
                    [
                      -116.16892,
                      46.75561
                    ],
                    [
                      -116.16893,
                      46.75565
                    ],
                    [
                      -116.16904,
                      46.75585
                    ],
                    [
                      -116.16911,
                      46.75594
                    ],
                    [
                      -116.16938,
                      46.75616
                    ],
                    [
                      -116.17036,
                      46.75672
                    ],
                    [
                      -116.17049,
                      46.75682
                    ],
                    [
                      -116.17097,
                      46.75733
                    ],
                    [
                      -116.17113,
                      46.75745
                    ],
                    [
                      -116.1714,
                      46.7576
                    ],
                    [
                      -116.17147,
                      46.75766
                    ],
                    [
                      -116.17154,
                      46.75776
                    ],
                    [
                      -116.17165,
                      46.75803
                    ],
                    [
                      -116.17166,
                      46.75818
                    ],
                    [
                      -116.17179,
                      46.75861
                    ],
                    [
                      -116.17183,
                      46.75889
                    ],
                    [
                      -116.17181,
                      46.75915
                    ],
                    [
                      -116.17163,
                      46.75933
                    ],
                    [
                      -116.17137,
                      46.7595
                    ],
                    [
                      -116.17127,
                      46.75955
                    ],
                    [
                      -116.17054,
                      46.76007
                    ],
                    [
                      -116.17039,
                      46.76024
                    ],
                    [
                      -116.17033,
                      46.76039
                    ],
                    [
                      -116.17036,
                      46.76056
                    ],
                    [
                      -116.17048,
                      46.76083
                    ],
                    [
                      -116.17075,
                      46.76126
                    ],
                    [
                      -116.17095,
                      46.76147
                    ],
                    [
                      -116.17127,
                      46.76171
                    ],
                    [
                      -116.17129,
                      46.76178
                    ],
                    [
                      -116.17127,
                      46.76187
                    ],
                    [
                      -116.17122,
                      46.76197
                    ],
                    [
                      -116.17097,
                      46.76223
                    ],
                    [
                      -116.17076,
                      46.76242
                    ],
                    [
                      -116.17056,
                      46.76267
                    ],
                    [
                      -116.17039,
                      46.76298
                    ],
                    [
                      -116.17012,
                      46.7636
                    ],
                    [
                      -116.17005,
                      46.76452
                    ],
                    [
                      -116.1701,
                      46.76475
                    ],
                    [
                      -116.17013,
                      46.76512
                    ],
                    [
                      -116.17008,
                      46.76532
                    ],
                    [
                      -116.16979,
                      46.76583
                    ],
                    [
                      -116.16964,
                      46.76605
                    ],
                    [
                      -116.16952,
                      46.76615
                    ],
                    [
                      -116.16933,
                      46.76625
                    ],
                    [
                      -116.16899,
                      46.76649
                    ],
                    [
                      -116.16799,
                      46.76771
                    ],
                    [
                      -116.16787,
                      46.7679
                    ],
                    [
                      -116.16783,
                      46.76806
                    ],
                    [
                      -116.16777,
                      46.76821
                    ],
                    [
                      -116.16773,
                      46.7684
                    ],
                    [
                      -116.16774,
                      46.76846
                    ],
                    [
                      -116.16775,
                      46.76847
                    ],
                    [
                      -116.16782,
                      46.76862
                    ],
                    [
                      -116.1679,
                      46.76872
                    ],
                    [
                      -116.16818,
                      46.76884
                    ],
                    [
                      -116.16867,
                      46.76885
                    ],
                    [
                      -116.16939,
                      46.76879
                    ],
                    [
                      -116.16999,
                      46.76866
                    ],
                    [
                      -116.1703,
                      46.76851
                    ],
                    [
                      -116.17057,
                      46.76843
                    ],
                    [
                      -116.1708,
                      46.76842
                    ],
                    [
                      -116.17129,
                      46.76848
                    ],
                    [
                      -116.17151,
                      46.76854
                    ],
                    [
                      -116.17173,
                      46.7687
                    ],
                    [
                      -116.17225,
                      46.76922
                    ],
                    [
                      -116.17308,
                      46.76985
                    ],
                    [
                      -116.17328,
                      46.77006
                    ],
                    [
                      -116.17335,
                      46.77024
                    ],
                    [
                      -116.17335,
                      46.77041
                    ],
                    [
                      -116.17332,
                      46.77059
                    ],
                    [
                      -116.17338,
                      46.77074
                    ],
                    [
                      -116.17342,
                      46.77079
                    ],
                    [
                      -116.1735,
                      46.77085
                    ],
                    [
                      -116.17363,
                      46.77089
                    ],
                    [
                      -116.17379,
                      46.77092
                    ],
                    [
                      -116.17417,
                      46.77093
                    ],
                    [
                      -116.17435,
                      46.77098
                    ],
                    [
                      -116.17449,
                      46.77105
                    ],
                    [
                      -116.17472,
                      46.77124
                    ],
                    [
                      -116.17495,
                      46.77152
                    ],
                    [
                      -116.17515,
                      46.77164
                    ],
                    [
                      -116.17544,
                      46.77176
                    ],
                    [
                      -116.17565,
                      46.77191
                    ],
                    [
                      -116.17575,
                      46.77205
                    ],
                    [
                      -116.17577,
                      46.77219
                    ],
                    [
                      -116.17574,
                      46.77236
                    ],
                    [
                      -116.17568,
                      46.77255
                    ],
                    [
                      -116.17563,
                      46.77285
                    ],
                    [
                      -116.17558,
                      46.77302
                    ],
                    [
                      -116.17546,
                      46.77373
                    ],
                    [
                      -116.17547,
                      46.77415
                    ],
                    [
                      -116.17546,
                      46.77426
                    ],
                    [
                      -116.17542,
                      46.77444
                    ],
                    [
                      -116.17524,
                      46.77467
                    ],
                    [
                      -116.17477,
                      46.77506
                    ],
                    [
                      -116.17449,
                      46.7752
                    ],
                    [
                      -116.17415,
                      46.77532
                    ],
                    [
                      -116.17374,
                      46.77537
                    ],
                    [
                      -116.17334,
                      46.77536
                    ],
                    [
                      -116.17219,
                      46.77527
                    ],
                    [
                      -116.17199,
                      46.77527
                    ],
                    [
                      -116.17161,
                      46.77532
                    ],
                    [
                      -116.17029,
                      46.77593
                    ],
                    [
                      -116.16993,
                      46.77619
                    ],
                    [
                      -116.16949,
                      46.77645
                    ],
                    [
                      -116.16923,
                      46.77658
                    ],
                    [
                      -116.16906,
                      46.77669
                    ],
                    [
                      -116.16895,
                      46.77682
                    ],
                    [
                      -116.1682,
                      46.7779
                    ],
                    [
                      -116.16813,
                      46.77815
                    ],
                    [
                      -116.16813,
                      46.77825
                    ],
                    [
                      -116.16817,
                      46.77842
                    ],
                    [
                      -116.16827,
                      46.77863
                    ],
                    [
                      -116.16917,
                      46.77975
                    ],
                    [
                      -116.1693,
                      46.77997
                    ],
                    [
                      -116.16941,
                      46.78023
                    ],
                    [
                      -116.16948,
                      46.78045
                    ],
                    [
                      -116.16957,
                      46.78092
                    ],
                    [
                      -116.16956,
                      46.7812
                    ],
                    [
                      -116.16965,
                      46.78148
                    ],
                    [
                      -116.16977,
                      46.78168
                    ],
                    [
                      -116.16989,
                      46.7818
                    ],
                    [
                      -116.1699,
                      46.7818
                    ],
                    [
                      -116.17006,
                      46.7819
                    ],
                    [
                      -116.17039,
                      46.78202
                    ],
                    [
                      -116.17136,
                      46.7822
                    ],
                    [
                      -116.17231,
                      46.78224
                    ],
                    [
                      -116.17654,
                      46.78208
                    ],
                    [
                      -116.17678,
                      46.78215
                    ],
                    [
                      -116.17686,
                      46.78221
                    ],
                    [
                      -116.17762,
                      46.78295
                    ],
                    [
                      -116.17771,
                      46.78311
                    ],
                    [
                      -116.17851,
                      46.78418
                    ],
                    [
                      -116.17914,
                      46.78397
                    ],
                    [
                      -116.1797,
                      46.7837
                    ],
                    [
                      -116.18102,
                      46.78326
                    ],
                    [
                      -116.18185,
                      46.78307
                    ],
                    [
                      -116.18252,
                      46.78282
                    ],
                    [
                      -116.18369,
                      46.78406
                    ],
                    [
                      -116.18408,
                      46.78434
                    ],
                    [
                      -116.18448,
                      46.78457
                    ],
                    [
                      -116.18486,
                      46.78474
                    ],
                    [
                      -116.18597,
                      46.78517
                    ],
                    [
                      -116.18655,
                      46.78547
                    ],
                    [
                      -116.18721,
                      46.78594
                    ],
                    [
                      -116.18746,
                      46.7862
                    ],
                    [
                      -116.18783,
                      46.78653
                    ],
                    [
                      -116.18783,
                      46.78654
                    ],
                    [
                      -116.18813,
                      46.78699
                    ],
                    [
                      -116.18841,
                      46.78768
                    ],
                    [
                      -116.18851,
                      46.78788
                    ],
                    [
                      -116.1887,
                      46.78806
                    ],
                    [
                      -116.18894,
                      46.7882
                    ],
                    [
                      -116.18921,
                      46.7883
                    ],
                    [
                      -116.18952,
                      46.78834
                    ],
                    [
                      -116.18983,
                      46.78833
                    ],
                    [
                      -116.19013,
                      46.78827
                    ],
                    [
                      -116.1904,
                      46.78816
                    ],
                    [
                      -116.19055,
                      46.78807
                    ],
                    [
                      -116.19064,
                      46.788
                    ],
                    [
                      -116.19081,
                      46.78782
                    ],
                    [
                      -116.19092,
                      46.78761
                    ],
                    [
                      -116.19093,
                      46.78739
                    ],
                    [
                      -116.19091,
                      46.78724
                    ],
                    [
                      -116.19077,
                      46.78676
                    ],
                    [
                      -116.19076,
                      46.78661
                    ],
                    [
                      -116.1908,
                      46.7864
                    ],
                    [
                      -116.19092,
                      46.78621
                    ],
                    [
                      -116.19109,
                      46.78605
                    ],
                    [
                      -116.1911,
                      46.78603
                    ],
                    [
                      -116.19133,
                      46.78589
                    ],
                    [
                      -116.19198,
                      46.78568
                    ],
                    [
                      -116.19199,
                      46.78568
                    ],
                    [
                      -116.19225,
                      46.78557
                    ],
                    [
                      -116.19247,
                      46.78542
                    ],
                    [
                      -116.19263,
                      46.78523
                    ],
                    [
                      -116.19299,
                      46.78416
                    ],
                    [
                      -116.19299,
                      46.78392
                    ],
                    [
                      -116.19295,
                      46.78363
                    ],
                    [
                      -116.19258,
                      46.78262
                    ],
                    [
                      -116.19247,
                      46.78213
                    ],
                    [
                      -116.19241,
                      46.78145
                    ],
                    [
                      -116.19238,
                      46.77636
                    ],
                    [
                      -116.19245,
                      46.77559
                    ],
                    [
                      -116.19251,
                      46.77538
                    ],
                    [
                      -116.19283,
                      46.77484
                    ],
                    [
                      -116.1974,
                      46.76826
                    ],
                    [
                      -116.19757,
                      46.76809
                    ],
                    [
                      -116.19777,
                      46.76794
                    ],
                    [
                      -116.19802,
                      46.76782
                    ],
                    [
                      -116.1983,
                      46.76773
                    ],
                    [
                      -116.19855,
                      46.76769
                    ],
                    [
                      -116.19878,
                      46.76768
                    ],
                    [
                      -116.19911,
                      46.7677
                    ],
                    [
                      -116.20115,
                      46.76808
                    ],
                    [
                      -116.20152,
                      46.76818
                    ],
                    [
                      -116.2018,
                      46.76831
                    ],
                    [
                      -116.20266,
                      46.7688
                    ],
                    [
                      -116.20293,
                      46.76893
                    ],
                    [
                      -116.20328,
                      46.76905
                    ],
                    [
                      -116.20413,
                      46.76929
                    ],
                    [
                      -116.20442,
                      46.7694
                    ],
                    [
                      -116.20463,
                      46.76953
                    ],
                    [
                      -116.20483,
                      46.76972
                    ],
                    [
                      -116.20505,
                      46.77
                    ],
                    [
                      -116.20555,
                      46.77082
                    ],
                    [
                      -116.20565,
                      46.77102
                    ],
                    [
                      -116.20572,
                      46.77123
                    ],
                    [
                      -116.20574,
                      46.77144
                    ],
                    [
                      -116.20566,
                      46.77216
                    ],
                    [
                      -116.20566,
                      46.77237
                    ],
                    [
                      -116.20569,
                      46.77258
                    ],
                    [
                      -116.20576,
                      46.77279
                    ],
                    [
                      -116.20605,
                      46.77327
                    ],
                    [
                      -116.20675,
                      46.77424
                    ],
                    [
                      -116.20691,
                      46.77454
                    ],
                    [
                      -116.20693,
                      46.77476
                    ],
                    [
                      -116.20691,
                      46.77494
                    ],
                    [
                      -116.20674,
                      46.7754
                    ],
                    [
                      -116.20675,
                      46.77561
                    ],
                    [
                      -116.20682,
                      46.77582
                    ],
                    [
                      -116.20695,
                      46.77601
                    ],
                    [
                      -116.20775,
                      46.77684
                    ],
                    [
                      -116.20797,
                      46.77699
                    ],
                    [
                      -116.20823,
                      46.7771
                    ],
                    [
                      -116.20853,
                      46.77717
                    ],
                    [
                      -116.20924,
                      46.77727
                    ],
                    [
                      -116.20951,
                      46.77738
                    ],
                    [
                      -116.20972,
                      46.77754
                    ],
                    [
                      -116.21006,
                      46.778
                    ],
                    [
                      -116.21024,
                      46.77817
                    ],
                    [
                      -116.21047,
                      46.77831
                    ],
                    [
                      -116.21074,
                      46.77841
                    ],
                    [
                      -116.21144,
                      46.77855
                    ],
                    [
                      -116.21265,
                      46.77872
                    ],
                    [
                      -116.21296,
                      46.77871
                    ],
                    [
                      -116.21326,
                      46.77865
                    ],
                    [
                      -116.21352,
                      46.77854
                    ],
                    [
                      -116.21407,
                      46.77821
                    ],
                    [
                      -116.21465,
                      46.77791
                    ],
                    [
                      -116.21589,
                      46.77739
                    ],
                    [
                      -116.21708,
                      46.77682
                    ],
                    [
                      -116.21822,
                      46.7762
                    ],
                    [
                      -116.21872,
                      46.77584
                    ],
                    [
                      -116.21908,
                      46.77541
                    ],
                    [
                      -116.21957,
                      46.77447
                    ],
                    [
                      -116.21992,
                      46.77403
                    ],
                    [
                      -116.22038,
                      46.77365
                    ],
                    [
                      -116.22418,
                      46.77131
                    ],
                    [
                      -116.2243,
                      46.77125
                    ],
                    [
                      -116.22469,
                      46.77101
                    ],
                    [
                      -116.22532,
                      46.7707
                    ],
                    [
                      -116.22534,
                      46.7707
                    ],
                    [
                      -116.22601,
                      46.77053
                    ],
                    [
                      -116.22673,
                      46.7705
                    ],
                    [
                      -116.22745,
                      46.77057
                    ],
                    [
                      -116.2281,
                      46.77078
                    ],
                    [
                      -116.22811,
                      46.77078
                    ],
                    [
                      -116.22869,
                      46.77109
                    ],
                    [
                      -116.22872,
                      46.77112
                    ],
                    [
                      -116.23023,
                      46.77212
                    ],
                    [
                      -116.23087,
                      46.77238
                    ],
                    [
                      -116.23157,
                      46.77249
                    ],
                    [
                      -116.23229,
                      46.77247
                    ],
                    [
                      -116.23298,
                      46.77231
                    ],
                    [
                      -116.23356,
                      46.77202
                    ],
                    [
                      -116.23645,
                      46.7698
                    ],
                    [
                      -116.23669,
                      46.76966
                    ],
                    [
                      -116.23697,
                      46.76955
                    ],
                    [
                      -116.23726,
                      46.76949
                    ],
                    [
                      -116.23757,
                      46.76949
                    ],
                    [
                      -116.23788,
                      46.76954
                    ],
                    [
                      -116.23818,
                      46.76963
                    ],
                    [
                      -116.23848,
                      46.76968
                    ],
                    [
                      -116.23878,
                      46.76968
                    ],
                    [
                      -116.23909,
                      46.76962
                    ],
                    [
                      -116.23935,
                      46.76952
                    ],
                    [
                      -116.23957,
                      46.76938
                    ],
                    [
                      -116.23974,
                      46.7692
                    ],
                    [
                      -116.23995,
                      46.76872
                    ],
                    [
                      -116.24125,
                      46.76535
                    ],
                    [
                      -116.24129,
                      46.76529
                    ],
                    [
                      -116.24136,
                      46.76515
                    ],
                    [
                      -116.24152,
                      46.76496
                    ],
                    [
                      -116.24172,
                      46.7648
                    ],
                    [
                      -116.24195,
                      46.76465
                    ],
                    [
                      -116.24258,
                      46.76439
                    ],
                    [
                      -116.24329,
                      46.76428
                    ],
                    [
                      -116.2462,
                      46.7643
                    ],
                    [
                      -116.2465,
                      46.76434
                    ],
                    [
                      -116.24686,
                      46.76444
                    ],
                    [
                      -116.24718,
                      46.76456
                    ],
                    [
                      -116.25051,
                      46.76649
                    ],
                    [
                      -116.25104,
                      46.76683
                    ],
                    [
                      -116.25126,
                      46.767
                    ],
                    [
                      -116.2515,
                      46.76722
                    ],
                    [
                      -116.25277,
                      46.76909
                    ],
                    [
                      -116.25279,
                      46.76913
                    ],
                    [
                      -116.25342,
                      46.77003
                    ],
                    [
                      -116.25344,
                      46.77007
                    ],
                    [
                      -116.25411,
                      46.77091
                    ],
                    [
                      -116.25699,
                      46.77427
                    ],
                    [
                      -116.25741,
                      46.77467
                    ],
                    [
                      -116.25794,
                      46.77501
                    ],
                    [
                      -116.26017,
                      46.77626
                    ],
                    [
                      -116.2614,
                      46.77682
                    ],
                    [
                      -116.26163,
                      46.77697
                    ],
                    [
                      -116.26183,
                      46.77714
                    ],
                    [
                      -116.26236,
                      46.778
                    ],
                    [
                      -116.26252,
                      46.77818
                    ],
                    [
                      -116.26271,
                      46.77834
                    ],
                    [
                      -116.26376,
                      46.779
                    ],
                    [
                      -116.26378,
                      46.77902
                    ],
                    [
                      -116.26431,
                      46.77936
                    ],
                    [
                      -116.26432,
                      46.77938
                    ],
                    [
                      -116.26478,
                      46.77974
                    ],
                    [
                      -116.26603,
                      46.78115
                    ],
                    [
                      -116.26632,
                      46.78143
                    ],
                    [
                      -116.26686,
                      46.78176
                    ],
                    [
                      -116.26734,
                      46.78214
                    ],
                    [
                      -116.26766,
                      46.78259
                    ],
                    [
                      -116.26802,
                      46.78302
                    ],
                    [
                      -116.26892,
                      46.7838
                    ],
                    [
                      -116.26941,
                      46.78416
                    ],
                    [
                      -116.27055,
                      46.78478
                    ],
                    [
                      -116.27328,
                      46.78641
                    ],
                    [
                      -116.27387,
                      46.7867
                    ],
                    [
                      -116.27417,
                      46.78681
                    ],
                    [
                      -116.27477,
                      46.78697
                    ],
                    [
                      -116.27908,
                      46.78762
                    ],
                    [
                      -116.2797,
                      46.78788
                    ],
                    [
                      -116.28064,
                      46.78864
                    ],
                    [
                      -116.28121,
                      46.78898
                    ],
                    [
                      -116.28372,
                      46.79076
                    ],
                    [
                      -116.28385,
                      46.79087
                    ],
                    [
                      -116.28405,
                      46.79109
                    ],
                    [
                      -116.28415,
                      46.79125
                    ],
                    [
                      -116.28422,
                      46.79146
                    ],
                    [
                      -116.28446,
                      46.79246
                    ],
                    [
                      -116.28468,
                      46.79295
                    ],
                    [
                      -116.28501,
                      46.7934
                    ],
                    [
                      -116.28527,
                      46.79388
                    ],
                    [
                      -116.28548,
                      46.79435
                    ],
                    [
                      -116.28576,
                      46.79484
                    ],
                    [
                      -116.28613,
                      46.79526
                    ],
                    [
                      -116.28613,
                      46.79527
                    ],
                    [
                      -116.28657,
                      46.79564
                    ],
                    [
                      -116.2871,
                      46.79595
                    ],
                    [
                      -116.2877,
                      46.79621
                    ],
                    [
                      -116.28835,
                      46.79639
                    ],
                    [
                      -116.29305,
                      46.79741
                    ],
                    [
                      -116.29448,
                      46.79777
                    ],
                    [
                      -116.29659,
                      46.79821
                    ],
                    [
                      -116.3009,
                      46.79879
                    ],
                    [
                      -116.30158,
                      46.79894
                    ],
                    [
                      -116.30356,
                      46.79952
                    ],
                    [
                      -116.30358,
                      46.79952
                    ],
                    [
                      -116.30423,
                      46.79966
                    ],
                    [
                      -116.30459,
                      46.79971
                    ],
                    [
                      -116.30491,
                      46.79973
                    ],
                    [
                      -116.30868,
                      46.79954
                    ],
                    [
                      -116.30948,
                      46.79955
                    ],
                    [
                      -116.31018,
                      46.79966
                    ],
                    [
                      -116.31079,
                      46.79984
                    ],
                    [
                      -116.31145,
                      46.80008
                    ],
                    [
                      -116.31192,
                      46.80032
                    ],
                    [
                      -116.3124,
                      46.80067
                    ],
                    [
                      -116.31301,
                      46.80124
                    ],
                    [
                      -116.31344,
                      46.80169
                    ],
                    [
                      -116.31369,
                      46.80201
                    ],
                    [
                      -116.31444,
                      46.80281
                    ],
                    [
                      -116.31488,
                      46.8032
                    ],
                    [
                      -116.31519,
                      46.80343
                    ],
                    [
                      -116.31583,
                      46.8038
                    ],
                    [
                      -116.31607,
                      46.80391
                    ],
                    [
                      -116.32165,
                      46.80602
                    ],
                    [
                      -116.32166,
                      46.80602
                    ],
                    [
                      -116.32185,
                      46.80611
                    ],
                    [
                      -116.32187,
                      46.80611
                    ],
                    [
                      -116.32257,
                      46.80631
                    ],
                    [
                      -116.32329,
                      46.80646
                    ],
                    [
                      -116.32383,
                      46.80654
                    ],
                    [
                      -116.33214,
                      46.80727
                    ],
                    [
                      -116.33295,
                      46.80727
                    ],
                    [
                      -116.33341,
                      46.80723
                    ],
                    [
                      -116.33818,
                      46.8066
                    ],
                    [
                      -116.34005,
                      46.80641
                    ],
                    [
                      -116.34082,
                      46.80641
                    ],
                    [
                      -116.34119,
                      46.80645
                    ],
                    [
                      -116.34174,
                      46.80656
                    ],
                    [
                      -116.34205,
                      46.80666
                    ],
                    [
                      -116.34276,
                      46.80699
                    ],
                    [
                      -116.34333,
                      46.80738
                    ],
                    [
                      -116.34646,
                      46.80992
                    ],
                    [
                      -116.34684,
                      46.81019
                    ],
                    [
                      -116.3479,
                      46.81104
                    ],
                    [
                      -116.34825,
                      46.81129
                    ],
                    [
                      -116.34883,
                      46.81161
                    ],
                    [
                      -116.35418,
                      46.81365
                    ],
                    [
                      -116.35447,
                      46.81373
                    ],
                    [
                      -116.35477,
                      46.81379
                    ],
                    [
                      -116.35533,
                      46.81384
                    ],
                    [
                      -116.3556,
                      46.81384
                    ],
                    [
                      -116.35629,
                      46.81377
                    ],
                    [
                      -116.35678,
                      46.81366
                    ],
                    [
                      -116.35717,
                      46.81352
                    ],
                    [
                      -116.35737,
                      46.81343
                    ],
                    [
                      -116.35782,
                      46.81317
                    ],
                    [
                      -116.35815,
                      46.8129
                    ],
                    [
                      -116.36036,
                      46.81056
                    ],
                    [
                      -116.36073,
                      46.8103
                    ],
                    [
                      -116.36101,
                      46.81016
                    ],
                    [
                      -116.36149,
                      46.80999
                    ],
                    [
                      -116.36182,
                      46.80991
                    ],
                    [
                      -116.36256,
                      46.80984
                    ],
                    [
                      -116.36305,
                      46.80987
                    ],
                    [
                      -116.37257,
                      46.81153
                    ],
                    [
                      -116.37406,
                      46.81189
                    ],
                    [
                      -116.37513,
                      46.81226
                    ],
                    [
                      -116.37587,
                      46.81257
                    ],
                    [
                      -116.37664,
                      46.81295
                    ],
                    [
                      -116.37705,
                      46.8132
                    ],
                    [
                      -116.38042,
                      46.81546
                    ],
                    [
                      -116.38042,
                      46.81547
                    ],
                    [
                      -116.38667,
                      46.81973
                    ],
                    [
                      -116.3871,
                      46.8201
                    ],
                    [
                      -116.38739,
                      46.82045
                    ],
                    [
                      -116.38747,
                      46.82058
                    ],
                    [
                      -116.3876,
                      46.82085
                    ],
                    [
                      -116.38768,
                      46.82107
                    ],
                    [
                      -116.38773,
                      46.82128
                    ],
                    [
                      -116.38774,
                      46.8215
                    ],
                    [
                      -116.38772,
                      46.82178
                    ],
                    [
                      -116.38767,
                      46.82204
                    ],
                    [
                      -116.38764,
                      46.82214
                    ],
                    [
                      -116.38762,
                      46.82218
                    ],
                    [
                      -116.38711,
                      46.82372
                    ],
                    [
                      -116.38709,
                      46.82387
                    ],
                    [
                      -116.38708,
                      46.82389
                    ],
                    [
                      -116.38706,
                      46.82427
                    ],
                    [
                      -116.38709,
                      46.82451
                    ],
                    [
                      -116.38723,
                      46.82495
                    ],
                    [
                      -116.38738,
                      46.82524
                    ],
                    [
                      -116.38749,
                      46.8254
                    ],
                    [
                      -116.38898,
                      46.82703
                    ],
                    [
                      -116.38908,
                      46.82718
                    ],
                    [
                      -116.38923,
                      46.82749
                    ],
                    [
                      -116.38932,
                      46.82778
                    ],
                    [
                      -116.38937,
                      46.82812
                    ],
                    [
                      -116.38935,
                      46.82838
                    ],
                    [
                      -116.38857,
                      46.83197
                    ],
                    [
                      -116.38856,
                      46.83214
                    ],
                    [
                      -116.38857,
                      46.83248
                    ],
                    [
                      -116.38861,
                      46.83274
                    ],
                    [
                      -116.38876,
                      46.83312
                    ],
                    [
                      -116.38886,
                      46.8333
                    ],
                    [
                      -116.38901,
                      46.83352
                    ],
                    [
                      -116.38919,
                      46.83373
                    ],
                    [
                      -116.38957,
                      46.83407
                    ],
                    [
                      -116.38993,
                      46.83431
                    ],
                    [
                      -116.39022,
                      46.83447
                    ],
                    [
                      -116.39063,
                      46.83464
                    ],
                    [
                      -116.3911,
                      46.83479
                    ],
                    [
                      -116.39127,
                      46.83483
                    ],
                    [
                      -116.39289,
                      46.83509
                    ],
                    [
                      -116.39289,
                      46.8351
                    ],
                    [
                      -116.39304,
                      46.83513
                    ],
                    [
                      -116.39338,
                      46.83525
                    ],
                    [
                      -116.39378,
                      46.83546
                    ],
                    [
                      -116.39415,
                      46.83574
                    ],
                    [
                      -116.39436,
                      46.83598
                    ],
                    [
                      -116.39455,
                      46.83632
                    ],
                    [
                      -116.39466,
                      46.83664
                    ],
                    [
                      -116.39522,
                      46.83875
                    ],
                    [
                      -116.39526,
                      46.83885
                    ],
                    [
                      -116.39528,
                      46.83893
                    ],
                    [
                      -116.39548,
                      46.83931
                    ],
                    [
                      -116.39575,
                      46.8397
                    ],
                    [
                      -116.39776,
                      46.84186
                    ],
                    [
                      -116.39794,
                      46.84208
                    ],
                    [
                      -116.39804,
                      46.84224
                    ],
                    [
                      -116.39811,
                      46.84241
                    ],
                    [
                      -116.39813,
                      46.84244
                    ],
                    [
                      -116.39816,
                      46.84253
                    ],
                    [
                      -116.3982,
                      46.84277
                    ],
                    [
                      -116.3982,
                      46.84295
                    ],
                    [
                      -116.39818,
                      46.8431
                    ],
                    [
                      -116.39813,
                      46.84326
                    ],
                    [
                      -116.39801,
                      46.84351
                    ],
                    [
                      -116.39787,
                      46.84375
                    ],
                    [
                      -116.39782,
                      46.8438
                    ],
                    [
                      -116.39776,
                      46.84389
                    ],
                    [
                      -116.39754,
                      46.84411
                    ],
                    [
                      -116.39736,
                      46.84424
                    ],
                    [
                      -116.397,
                      46.84444
                    ],
                    [
                      -116.39645,
                      46.8447
                    ],
                    [
                      -116.39528,
                      46.84533
                    ],
                    [
                      -116.39501,
                      46.84553
                    ],
                    [
                      -116.39484,
                      46.84568
                    ],
                    [
                      -116.39463,
                      46.84596
                    ],
                    [
                      -116.3945,
                      46.84619
                    ],
                    [
                      -116.39438,
                      46.8466
                    ],
                    [
                      -116.39438,
                      46.84693
                    ],
                    [
                      -116.39474,
                      46.84839
                    ],
                    [
                      -116.39482,
                      46.84883
                    ],
                    [
                      -116.39485,
                      46.84928
                    ],
                    [
                      -116.39483,
                      46.84966
                    ],
                    [
                      -116.39474,
                      46.85039
                    ],
                    [
                      -116.3947,
                      46.85053
                    ],
                    [
                      -116.3947,
                      46.85056
                    ],
                    [
                      -116.39446,
                      46.85122
                    ],
                    [
                      -116.39357,
                      46.85273
                    ],
                    [
                      -116.39327,
                      46.85331
                    ],
                    [
                      -116.39327,
                      46.85332
                    ],
                    [
                      -116.39318,
                      46.85355
                    ],
                    [
                      -116.39313,
                      46.85411
                    ],
                    [
                      -116.39316,
                      46.85439
                    ],
                    [
                      -116.39322,
                      46.8546
                    ],
                    [
                      -116.39338,
                      46.85483
                    ],
                    [
                      -116.39361,
                      46.85509
                    ],
                    [
                      -116.39384,
                      46.85529
                    ],
                    [
                      -116.39417,
                      46.85553
                    ],
                    [
                      -116.39438,
                      46.85566
                    ],
                    [
                      -116.39525,
                      46.85605
                    ],
                    [
                      -116.39557,
                      46.85622
                    ],
                    [
                      -116.39576,
                      46.85635
                    ],
                    [
                      -116.39605,
                      46.85663
                    ],
                    [
                      -116.39613,
                      46.85677
                    ],
                    [
                      -116.39624,
                      46.85701
                    ],
                    [
                      -116.39632,
                      46.85733
                    ],
                    [
                      -116.39633,
                      46.85753
                    ],
                    [
                      -116.3965,
                      46.8585
                    ],
                    [
                      -116.39348,
                      46.85873
                    ]
                  ],
			},
			id: "86cd5e29-e6eb-4279-b976-7e5546c42965",
		},
        // {
        //   type: "Feature",
        //   properties: {
        //     name: "Orofino, ID",
        //     description: null,
        //     tessellate: -1
        //   },
        //   geometry: {
        //     type: "Point",
        //     coordinates: [
        //       -116.2549605,
        //       46.479391
        //     ]
        //   },
        //   id: "c8c25951-4d57-4776-af6f-0554edbbf530"
        // },
        // {
        //   type: "Feature",
        //   properties: {
        //     name: "Elk River, ID",
        //     description: null,
        //     tessellate: -1
        //   },
        //   geometry: {
        //     type: "Point",
        //     coordinates: [
        //       -116.1799451,
        //       46.7836171
        //     ]
        //   },
        //   id: "8ae351a9-b347-43b9-bc9e-d67aa718cecb"
        // },
        // {
        //   type: "Feature",
        //   properties: {
        //     name: "Bovill, ID",
        //     description: null,
        //     tessellate: -1
        //   },
        //   geometry: {
        //     type: "Point",
        //     coordinates: [
        //       -116.3934779,
        //       46.8587283
        //     ]
        //   },
        //   id: "c888a434-c19a-4bcc-89b8-684b573c4924"
        // }
	],
};

export default ElkRiver;
