import React from "react";

export default class IconTutorial extends React.Component {
	render() {
		return (
			<>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.743 36">
					<path d="M14.2,0A13.623,13.623,0,0,0,2.1,6.4a1.689,1.689,0,0,0,.364,2.311l3.033,2.3a1.687,1.687,0,0,0,2.338-.292C9.6,8.513,10.906,7.243,13.658,7.243c2.163,0,4.839,1.392,4.839,3.49,0,1.586-1.309,2.4-3.445,3.6-2.491,1.4-5.787,3.134-5.787,7.482V22.5a1.687,1.687,0,0,0,1.687,1.688h5.1A1.687,1.687,0,0,0,17.735,22.5v-.406c0-3.014,8.808-3.139,8.808-11.294C26.543,4.659,20.173,0,14.2,0Zm-.7,26.259a4.871,4.871,0,1,0,4.871,4.871A4.876,4.876,0,0,0,13.5,26.259Z" transform="translate(-1.8)"/>
		  		</svg>
			</>
		);
	}
}
