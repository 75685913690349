import React from "react";

export default class IconClose extends React.Component {
	render() {
		return (
			<>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="30.152"
					height="30.152"
					viewBox="0 0 30.152 30.152"
				>
					<path
						id="Path_1511"
						data-name="Path 1511"
						d="M23.632,21.994l-3.82-3.82,3.82-3.82a1.158,1.158,0,0,0-1.638-1.638l-3.82,3.82-3.82-3.82a1.158,1.158,0,0,0-1.638,1.638l3.82,3.82-3.82,3.82a1.12,1.12,0,0,0,0,1.638,1.151,1.151,0,0,0,1.638,0l3.82-3.82,3.82,3.82a1.164,1.164,0,0,0,1.638,0A1.151,1.151,0,0,0,23.632,21.994Z"
						transform="translate(-3.098 -3.098)"
					/>
					<path
						id="Path_1512"
						data-name="Path 1512"
						d="M18.451,5.4a13.041,13.041,0,1,1-9.227,3.82A12.96,12.96,0,0,1,18.451,5.4m0-2.029A15.076,15.076,0,1,0,33.527,18.451,15.073,15.073,0,0,0,18.451,3.375Z"
						transform="translate(-3.375 -3.375)"
					/>
				</svg>
			</>
		);
	}
}
