import React, {useState} from "react";

const IconRoadSideAttractions = (props) => {
	const [active, setActive] = useState(props.active);
	return (
	<>
		<svg x="0px" y="0px"
			viewBox="0 0 37 36"
			style={{enableBackground:"new 0 0 37 36"}}
			className={active ? "active" : ""}>
			<g>
				<path d="M27.1,20.3c0-0.2,0-0.3-0.1-0.5c0-0.2-0.1-0.4-0.1-0.5c-0.1-0.2-0.1-0.3-0.2-0.5l0,0c-0.8-1.2-2-1.6-3.2-2.1
					c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.4-0.3
					c-0.1-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.3-0.1-0.6-0.1-0.8,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.4-0.3
					s-0.3-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3-0.1-0.7-0.1-1,0c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0.1-0.3,0.1-0.4,0.2
					c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0-0.6,0-0.8,0c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0.1-0.3,0.1-0.4,0.2
					s-0.3,0.2-0.4,0.3s-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.1-0.1,0.1-0.1,0.2c-0.9,0.3-1.6,0.6-2.3,1.1
					c-0.2,0.1-0.3,0.3-0.4,0.4c0,0,0,0.1-0.1,0.1c-0.7,1.2-1.1,2.7-1,4.5c0,0,0,0,0,0.1c0,0.2,0.1,0.3,0.1,0.5c0.3,1.2,0.8,2,1.6,2.6
					c0.1,0.1,0.3,0.2,0.4,0.3c0.4,0.3,0.8,0.5,1.2,0.7c0.2,0.1,0.4,0.2,0.6,0.2c0,0,0,0,0,0c0.7,0.2,1.4,0.2,2.1,0.3l0.2,0
					c0.1,0,0.3,0,0.4,0c0,0,0.1,0,0.1,0c0.2,0,0.5,0,0.7,0.1c0.2,0.1,0.5,0.1,0.8,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0
					c0,0,0.1,0,0.1,0c0.4-0.1,0.8-0.2,1.2-0.2c0.4,0,0.8-0.1,1.1-0.1c0.1,0,0.3-0.1,0.4-0.1c1.4-0.4,2.5-1,3.4-1.6l0,0
					c0.1-0.1,0.3-0.3,0.4-0.4c0.9-1,1.4-2.1,1.3-3.2c0-0.2,0-0.4-0.1-0.5C27.2,20.6,27.1,20.4,27.1,20.3z M15,16.1
					c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1
					c0.2,0,0.5,0,0.7,0c0.1,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4-0.2c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.2s0.2-0.1,0.3-0.1
					c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.2
					c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0,0c0.1,0,0.1,0,0.1,0c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3
					c0,0.1,0,0.2,0,0.3v0l0,0c0,0.1,0,0.1,0,0.2v0c-0.4,0.2-1,0.3-1.1,0.3c-0.1,0-0.2,0-0.3-0.1c-0.6-0.1-1.5-0.3-2.4-0.3
					c-0.7,0-1.1,0.1-1.6,0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.7,0.1-1.3,0-1.5-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3
					C14.9,16.3,14.9,16.2,15,16.1z M25.3,23.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.8,0.6-1.8,1.1-3.1,1.5c-0.1,0-0.3,0.1-0.4,0.1
					c-0.3,0.1-0.6,0.1-1,0.1c-0.5,0-1,0.1-1.4,0.2c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.7-0.1C17.7,26,17.4,26,17,26
					c-0.1,0-0.3,0-0.4,0l-0.2,0c-0.7-0.1-1.3-0.1-2-0.3c-0.2-0.1-0.3-0.1-0.5-0.2c-0.4-0.2-0.7-0.4-1-0.6c-0.1-0.1-0.2-0.1-0.3-0.3l0,0
					c-0.6-0.5-1-1.1-1.3-2.2c0-0.1-0.1-0.3-0.1-0.4c0-1.6,0.3-2.9,0.9-4c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.4,1-0.6,1.6-0.9
					c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.9,0.2,1.9,0.1
					c0.3,0,0.6-0.1,0.9-0.1c0.5-0.1,0.9-0.1,1.5-0.1c0.8,0,1.7,0.2,2.2,0.3c0.1,0,0.2,0,0.3,0.1c0.1,0,0.1,0,0.2,0
					c0.6,0,1.4-0.4,1.6-0.4c0.1-0.1,0.2-0.2,0.2-0.3c1,0.4,2,0.8,2.6,1.7c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.3,0.1,0.4v0
					c0,0.1,0.1,0.3,0.1,0.4c0,0,0,0.1,0,0.1c0,0.2,0.1,0.3,0.1,0.4c0,0.1,0,0.3,0,0.4C26.4,22.2,26,23.1,25.3,23.9z"/>
							<path d="M24.7,21.8c-0.1,0.1-0.1,0.2-0.1,0.3s0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3
					s0-0.2-0.1-0.3C25.1,21.6,24.9,21.6,24.7,21.8z"/>
							<path d="M14.8,23.5c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1
					c0.1-0.1,0.1-0.1,0.1-0.2s0-0.1-0.1-0.2C15.1,23.4,14.9,23.4,14.8,23.5z"/>
							<path d="M13.3,19c-0.1,0.1-0.1,0.1-0.1,0.2s0,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1s0.1,0,0.2-0.1c0.1-0.1,0.1-0.1,0.1-0.2
					s0-0.1-0.1-0.2C13.6,18.9,13.4,18.9,13.3,19z"/>
							<path d="M20.1,19c-0.1,0.1-0.1,0.2-0.1,0.3s0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3
					s0-0.2-0.1-0.3C20.5,18.9,20.2,18.9,20.1,19z"/>
							<path d="M18.5,22.3c-0.1,0.1-0.1,0.2-0.1,0.3s0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3
					s0-0.2-0.1-0.3C18.9,22.2,18.6,22.2,18.5,22.3z"/>
				<path d="M29.5,13.3c-0.2-0.2-0.4-0.2-0.6,0l-1.6,1.6c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l1.6-1.6
		C29.6,13.7,29.6,13.5,29.5,13.3z"/>
				<path d="M23.2,12.8c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.4-0.3l0.8-2.2c0.1-0.2,0-0.5-0.3-0.6s-0.5,0-0.6,0.3L23,12.2
		C22.9,12.4,23,12.7,23.2,12.8z"/>
				<path d="M18.7,12.1L18.7,12.1c0.3,0,0.4-0.2,0.4-0.4L19,9.3c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4l0.1,2.3
		C18.2,11.9,18.4,12.1,18.7,12.1z"/>
				<path d="M12.8,12.5c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.1,0,0.2,0c0.2-0.1,0.3-0.4,0.2-0.6l-1-2.1c-0.1-0.2-0.4-0.3-0.6-0.2
		c-0.2,0.1-0.3,0.4-0.2,0.6C11.8,10.5,12.8,12.5,12.8,12.5z"/>
				<path d="M10.2,15.6L8,14.8c-0.2-0.1-0.5,0-0.6,0.3c-0.1,0.2,0,0.5,0.3,0.6l2.2,0.8c0.1,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.4-0.3
C10.5,15.9,10.4,15.7,10.2,15.6z"/>
			</g>
		</svg>
	</>);
}

export default IconRoadSideAttractions
