import React from "react";

function IconEdit() {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="19.491"
				height="17.322"
				viewBox="0 0 19.491 17.322"
			>
				<path
					d="M13.624,2.819l3.052,3.052a.331.331,0,0,1,0,.467L9.286,13.729l-3.14.349a.658.658,0,0,1-.728-.728l.349-3.14,7.391-7.391A.331.331,0,0,1,13.624,2.819Zm5.482-.775L17.454.393a1.323,1.323,0,0,0-1.868,0l-1.2,1.2a.331.331,0,0,0,0,.467L17.441,5.11a.331.331,0,0,0,.467,0l1.2-1.2a1.323,1.323,0,0,0,0-1.868Zm-6.111,9.675v3.445H2.166V4.335H9.942a.416.416,0,0,0,.288-.118l1.354-1.354a.406.406,0,0,0-.288-.694H1.624A1.625,1.625,0,0,0,0,3.794V15.705a1.625,1.625,0,0,0,1.624,1.624H13.536a1.625,1.625,0,0,0,1.624-1.624v-5.34a.407.407,0,0,0-.694-.288l-1.354,1.354A.416.416,0,0,0,12.994,11.719Z"
					transform="translate(0 -0.007)"
				/>
			</svg>
		</>
	);
}

export default IconEdit;
