const LakeCoeurDAlene = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Lake Coeur d'Alene Scenic Byway",
				description: "With rugged mountains, wildlife and year-round access, the Lake Coeur d'Alene Scenic Byway showcases panoramic views and all types of outdoor adventure in northern Idaho.",
				link: "https://visitidaho.org/things-to-do/road-trips/lake-coeur-dalene-scenic-byway/",
				route: [
					"Coeur d'Alene", 
					"Harrison"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
          [
            -116.78122,
            47.67346,
            0
          ],
          [
            -116.77382,
            47.67334,
            0
          ],
          [
            -116.75209,
            47.67331,
            0
          ],
          [
            -116.75193,
            47.67275,
            0
          ],
          [
            -116.75172,
            47.67223,
            0
          ],
          [
            -116.75164,
            47.67206,
            0
          ],
          [
            -116.75164,
            47.67205,
            0
          ],
          [
            -116.75125,
            47.67114,
            0
          ],
          [
            -116.75116,
            47.67088,
            0
          ],
          [
            -116.7511,
            47.67062,
            0
          ],
          [
            -116.75105,
            47.67033,
            0
          ],
          [
            -116.75096,
            47.66931,
            0
          ],
          [
            -116.75096,
            47.6692,
            0
          ],
          [
            -116.75095,
            47.66914,
            0
          ],
          [
            -116.75095,
            47.66909,
            0
          ],
          [
            -116.75087,
            47.66902,
            0
          ],
          [
            -116.75084,
            47.66843,
            0
          ],
          [
            -116.75083,
            47.66836,
            0
          ],
          [
            -116.75083,
            47.66826,
            0
          ],
          [
            -116.75082,
            47.66815,
            0
          ],
          [
            -116.75082,
            47.668,
            0
          ],
          [
            -116.75081,
            47.66793,
            0
          ],
          [
            -116.75076,
            47.66698,
            0
          ],
          [
            -116.75075,
            47.66694,
            0
          ],
          [
            -116.75072,
            47.66642,
            0
          ],
          [
            -116.75068,
            47.66604,
            0
          ],
          [
            -116.75057,
            47.66557,
            0
          ],
          [
            -116.75041,
            47.66518,
            0
          ],
          [
            -116.75032,
            47.665,
            0
          ],
          [
            -116.7502,
            47.66479,
            0
          ],
          [
            -116.75014,
            47.66471,
            0
          ],
          [
            -116.75009,
            47.66463,
            0
          ],
          [
            -116.74994,
            47.66445,
            0
          ],
          [
            -116.74991,
            47.6644,
            0
          ],
          [
            -116.74989,
            47.66439,
            0
          ],
          [
            -116.74981,
            47.66429,
            0
          ],
          [
            -116.74967,
            47.66414,
            0
          ],
          [
            -116.74927,
            47.66379,
            0
          ],
          [
            -116.74911,
            47.66367,
            0
          ],
          [
            -116.74909,
            47.66365,
            0
          ],
          [
            -116.74897,
            47.66358,
            0
          ],
          [
            -116.74883,
            47.66348,
            0
          ],
          [
            -116.7487,
            47.6634,
            0
          ],
          [
            -116.74833,
            47.6632,
            0
          ],
          [
            -116.748,
            47.66304,
            0
          ],
          [
            -116.74765,
            47.6629,
            0
          ],
          [
            -116.74733,
            47.66279,
            0
          ],
          [
            -116.74731,
            47.66279,
            0
          ],
          [
            -116.74677,
            47.66262,
            0
          ],
          [
            -116.7463,
            47.66251,
            0
          ],
          [
            -116.74568,
            47.66239,
            0
          ],
          [
            -116.74504,
            47.66231,
            0
          ],
          [
            -116.74478,
            47.66229,
            0
          ],
          [
            -116.74472,
            47.66228,
            0
          ],
          [
            -116.74457,
            47.66227,
            0
          ],
          [
            -116.74443,
            47.66227,
            0
          ],
          [
            -116.74434,
            47.66226,
            0
          ],
          [
            -116.74412,
            47.66226,
            0
          ],
          [
            -116.74403,
            47.66225,
            0
          ],
          [
            -116.74375,
            47.66225,
            0
          ],
          [
            -116.74363,
            47.66226,
            0
          ],
          [
            -116.74346,
            47.66226,
            0
          ],
          [
            -116.74264,
            47.66232,
            0
          ],
          [
            -116.74257,
            47.66232,
            0
          ],
          [
            -116.7412,
            47.66248,
            0
          ],
          [
            -116.74044,
            47.66254,
            0
          ],
          [
            -116.74041,
            47.66255,
            0
          ],
          [
            -116.73981,
            47.66257,
            0
          ],
          [
            -116.73916,
            47.66255,
            0
          ],
          [
            -116.73717,
            47.66242,
            0
          ],
          [
            -116.73663,
            47.66242,
            0
          ],
          [
            -116.73654,
            47.66243,
            0
          ],
          [
            -116.73635,
            47.66243,
            0
          ],
          [
            -116.73556,
            47.66252,
            0
          ],
          [
            -116.73436,
            47.66273,
            0
          ],
          [
            -116.734,
            47.66278,
            0
          ],
          [
            -116.73393,
            47.66278,
            0
          ],
          [
            -116.73377,
            47.6628,
            0
          ],
          [
            -116.73368,
            47.6628,
            0
          ],
          [
            -116.73358,
            47.66281,
            0
          ],
          [
            -116.73348,
            47.66281,
            0
          ],
          [
            -116.73338,
            47.66282,
            0
          ],
          [
            -116.73306,
            47.66282,
            0
          ],
          [
            -116.73271,
            47.6628,
            0
          ],
          [
            -116.73234,
            47.66276,
            0
          ],
          [
            -116.73212,
            47.66272,
            0
          ],
          [
            -116.7321,
            47.66272,
            0
          ],
          [
            -116.73185,
            47.66267,
            0
          ],
          [
            -116.73153,
            47.66259,
            0
          ],
          [
            -116.73104,
            47.66243,
            0
          ],
          [
            -116.73092,
            47.66237,
            0
          ],
          [
            -116.73084,
            47.66234,
            0
          ],
          [
            -116.73065,
            47.66225,
            0
          ],
          [
            -116.73053,
            47.66218,
            0
          ],
          [
            -116.73046,
            47.66215,
            0
          ],
          [
            -116.73037,
            47.6621,
            0
          ],
          [
            -116.7301,
            47.66192,
            0
          ],
          [
            -116.72997,
            47.66182,
            0
          ],
          [
            -116.7299,
            47.66178,
            0
          ],
          [
            -116.72949,
            47.66144,
            0
          ],
          [
            -116.72915,
            47.66108,
            0
          ],
          [
            -116.72914,
            47.66105,
            0
          ],
          [
            -116.72906,
            47.66096,
            0
          ],
          [
            -116.72887,
            47.66067,
            0
          ],
          [
            -116.72885,
            47.66063,
            0
          ],
          [
            -116.72881,
            47.66057,
            0
          ],
          [
            -116.72865,
            47.66025,
            0
          ],
          [
            -116.72858,
            47.66007,
            0
          ],
          [
            -116.72852,
            47.65988,
            0
          ],
          [
            -116.72845,
            47.65957,
            0
          ],
          [
            -116.72843,
            47.65951,
            0
          ],
          [
            -116.72833,
            47.65903,
            0
          ],
          [
            -116.72832,
            47.65902,
            0
          ],
          [
            -116.72831,
            47.65893,
            0
          ],
          [
            -116.72824,
            47.65861,
            0
          ],
          [
            -116.72822,
            47.65855,
            0
          ],
          [
            -116.7282,
            47.65846,
            0
          ],
          [
            -116.7282,
            47.65845,
            0
          ],
          [
            -116.72817,
            47.65829,
            0
          ],
          [
            -116.72814,
            47.6582,
            0
          ],
          [
            -116.72812,
            47.65807,
            0
          ],
          [
            -116.72806,
            47.65784,
            0
          ],
          [
            -116.72791,
            47.6571,
            0
          ],
          [
            -116.7279,
            47.65709,
            0
          ],
          [
            -116.72773,
            47.65631,
            0
          ],
          [
            -116.7277,
            47.65622,
            0
          ],
          [
            -116.72768,
            47.65611,
            0
          ],
          [
            -116.72756,
            47.65569,
            0
          ],
          [
            -116.72741,
            47.65533,
            0
          ],
          [
            -116.72731,
            47.65516,
            0
          ],
          [
            -116.72712,
            47.65489,
            0
          ],
          [
            -116.72705,
            47.65481,
            0
          ],
          [
            -116.72705,
            47.6548,
            0
          ],
          [
            -116.72682,
            47.65456,
            0
          ],
          [
            -116.72672,
            47.65447,
            0
          ],
          [
            -116.72664,
            47.65441,
            0
          ],
          [
            -116.72646,
            47.65425,
            0
          ],
          [
            -116.72605,
            47.65398,
            0
          ],
          [
            -116.726,
            47.65396,
            0
          ],
          [
            -116.72585,
            47.65387,
            0
          ],
          [
            -116.72536,
            47.65365,
            0
          ],
          [
            -116.72483,
            47.65346,
            0
          ],
          [
            -116.72467,
            47.65342,
            0
          ],
          [
            -116.72462,
            47.6534,
            0
          ],
          [
            -116.72433,
            47.65333,
            0
          ],
          [
            -116.72424,
            47.65332,
            0
          ],
          [
            -116.72405,
            47.65328,
            0
          ],
          [
            -116.72379,
            47.65324,
            0
          ],
          [
            -116.72366,
            47.65323,
            0
          ],
          [
            -116.72361,
            47.65322,
            0
          ],
          [
            -116.72357,
            47.65322,
            0
          ],
          [
            -116.72326,
            47.65319,
            0
          ],
          [
            -116.7231,
            47.65319,
            0
          ],
          [
            -116.72301,
            47.65318,
            0
          ],
          [
            -116.72212,
            47.65317,
            0
          ],
          [
            -116.72201,
            47.65316,
            0
          ],
          [
            -116.7214,
            47.65316,
            0
          ],
          [
            -116.72121,
            47.65315,
            0
          ],
          [
            -116.72069,
            47.65315,
            0
          ],
          [
            -116.71988,
            47.65312,
            0
          ],
          [
            -116.71906,
            47.65303,
            0
          ],
          [
            -116.71899,
            47.65301,
            0
          ],
          [
            -116.71885,
            47.65299,
            0
          ],
          [
            -116.71856,
            47.65293,
            0
          ],
          [
            -116.71851,
            47.65291,
            0
          ],
          [
            -116.7181,
            47.6528,
            0
          ],
          [
            -116.71749,
            47.65256,
            0
          ],
          [
            -116.71748,
            47.65255,
            0
          ],
          [
            -116.71726,
            47.65245,
            0
          ],
          [
            -116.71694,
            47.65227,
            0
          ],
          [
            -116.7169,
            47.65224,
            0
          ],
          [
            -116.7168,
            47.65219,
            0
          ],
          [
            -116.71674,
            47.65214,
            0
          ],
          [
            -116.71666,
            47.65209,
            0
          ],
          [
            -116.71656,
            47.65201,
            0
          ],
          [
            -116.7164,
            47.6519,
            0
          ],
          [
            -116.71628,
            47.65178,
            0
          ],
          [
            -116.71625,
            47.65176,
            0
          ],
          [
            -116.71612,
            47.65163,
            0
          ],
          [
            -116.71607,
            47.65159,
            0
          ],
          [
            -116.71602,
            47.65153,
            0
          ],
          [
            -116.71592,
            47.65143,
            0
          ],
          [
            -116.71588,
            47.65137,
            0
          ],
          [
            -116.71581,
            47.6513,
            0
          ],
          [
            -116.71566,
            47.65107,
            0
          ],
          [
            -116.71562,
            47.65102,
            0
          ],
          [
            -116.71552,
            47.65085,
            0
          ],
          [
            -116.71549,
            47.65078,
            0
          ],
          [
            -116.71545,
            47.65071,
            0
          ],
          [
            -116.7154,
            47.65057,
            0
          ],
          [
            -116.71537,
            47.65051,
            0
          ],
          [
            -116.71535,
            47.65044,
            0
          ],
          [
            -116.71532,
            47.65037,
            0
          ],
          [
            -116.7153,
            47.65029,
            0
          ],
          [
            -116.71528,
            47.65024,
            0
          ],
          [
            -116.71526,
            47.65015,
            0
          ],
          [
            -116.71524,
            47.6501,
            0
          ],
          [
            -116.71523,
            47.65003,
            0
          ],
          [
            -116.71521,
            47.64998,
            0
          ],
          [
            -116.71496,
            47.649,
            0
          ],
          [
            -116.71495,
            47.64898,
            0
          ],
          [
            -116.71475,
            47.64824,
            0
          ],
          [
            -116.71474,
            47.64818,
            0
          ],
          [
            -116.7147,
            47.64805,
            0
          ],
          [
            -116.7147,
            47.64803,
            0
          ],
          [
            -116.71467,
            47.64792,
            0
          ],
          [
            -116.71466,
            47.64785,
            0
          ],
          [
            -116.7146,
            47.64766,
            0
          ],
          [
            -116.71457,
            47.64752,
            0
          ],
          [
            -116.71456,
            47.6475,
            0
          ],
          [
            -116.71453,
            47.64736,
            0
          ],
          [
            -116.71436,
            47.64674,
            0
          ],
          [
            -116.71436,
            47.64673,
            0
          ],
          [
            -116.71428,
            47.64644,
            0
          ],
          [
            -116.71428,
            47.64642,
            0
          ],
          [
            -116.71421,
            47.64617,
            0
          ],
          [
            -116.7142,
            47.64611,
            0
          ],
          [
            -116.71412,
            47.64582,
            0
          ],
          [
            -116.71411,
            47.64576,
            0
          ],
          [
            -116.71405,
            47.64555,
            0
          ],
          [
            -116.71404,
            47.64549,
            0
          ],
          [
            -116.714,
            47.64537,
            0
          ],
          [
            -116.71396,
            47.64519,
            0
          ],
          [
            -116.71394,
            47.64514,
            0
          ],
          [
            -116.71389,
            47.64495,
            0
          ],
          [
            -116.71388,
            47.64489,
            0
          ],
          [
            -116.71386,
            47.64483,
            0
          ],
          [
            -116.71383,
            47.64469,
            0
          ],
          [
            -116.71377,
            47.64448,
            0
          ],
          [
            -116.71377,
            47.64446,
            0
          ],
          [
            -116.71365,
            47.64402,
            0
          ],
          [
            -116.71365,
            47.644,
            0
          ],
          [
            -116.71356,
            47.64367,
            0
          ],
          [
            -116.71356,
            47.64365,
            0
          ],
          [
            -116.71355,
            47.6436,
            0
          ],
          [
            -116.71351,
            47.64348,
            0
          ],
          [
            -116.71334,
            47.64283,
            0
          ],
          [
            -116.71325,
            47.64255,
            0
          ],
          [
            -116.71308,
            47.64215,
            0
          ],
          [
            -116.71293,
            47.6419,
            0
          ],
          [
            -116.71273,
            47.64164,
            0
          ],
          [
            -116.71255,
            47.64145,
            0
          ],
          [
            -116.71227,
            47.64119,
            0
          ],
          [
            -116.712,
            47.64099,
            0
          ],
          [
            -116.71172,
            47.64081,
            0
          ],
          [
            -116.71139,
            47.64063,
            0
          ],
          [
            -116.70968,
            47.63989,
            0
          ],
          [
            -116.70946,
            47.63978,
            0
          ],
          [
            -116.70943,
            47.63976,
            0
          ],
          [
            -116.70939,
            47.63975,
            0
          ],
          [
            -116.70897,
            47.63949,
            0
          ],
          [
            -116.70869,
            47.63929,
            0
          ],
          [
            -116.70867,
            47.63926,
            0
          ],
          [
            -116.70864,
            47.63924,
            0
          ],
          [
            -116.70851,
            47.63913,
            0
          ],
          [
            -116.70846,
            47.63908,
            0
          ],
          [
            -116.70842,
            47.63903,
            0
          ],
          [
            -116.70829,
            47.6389,
            0
          ],
          [
            -116.70824,
            47.63884,
            0
          ],
          [
            -116.70823,
            47.63882,
            0
          ],
          [
            -116.7082,
            47.63879,
            0
          ],
          [
            -116.70806,
            47.6386,
            0
          ],
          [
            -116.70796,
            47.63843,
            0
          ],
          [
            -116.70795,
            47.63842,
            0
          ],
          [
            -116.70786,
            47.63825,
            0
          ],
          [
            -116.70773,
            47.6379,
            0
          ],
          [
            -116.70767,
            47.63764,
            0
          ],
          [
            -116.70766,
            47.63754,
            0
          ],
          [
            -116.70765,
            47.6375,
            0
          ],
          [
            -116.70765,
            47.63736,
            0
          ],
          [
            -116.70764,
            47.63724,
            0
          ],
          [
            -116.70765,
            47.63718,
            0
          ],
          [
            -116.70767,
            47.63679,
            0
          ],
          [
            -116.70768,
            47.63674,
            0
          ],
          [
            -116.70768,
            47.63664,
            0
          ],
          [
            -116.70771,
            47.63635,
            0
          ],
          [
            -116.70771,
            47.63627,
            0
          ],
          [
            -116.70773,
            47.63613,
            0
          ],
          [
            -116.70773,
            47.63604,
            0
          ],
          [
            -116.70776,
            47.63575,
            0
          ],
          [
            -116.70776,
            47.63565,
            0
          ],
          [
            -116.70779,
            47.63529,
            0
          ],
          [
            -116.70779,
            47.63483,
            0
          ],
          [
            -116.70778,
            47.63475,
            0
          ],
          [
            -116.70777,
            47.63471,
            0
          ],
          [
            -116.70777,
            47.63466,
            0
          ],
          [
            -116.70771,
            47.63436,
            0
          ],
          [
            -116.70769,
            47.63432,
            0
          ],
          [
            -116.70769,
            47.63429,
            0
          ],
          [
            -116.70766,
            47.63421,
            0
          ],
          [
            -116.70764,
            47.63417,
            0
          ],
          [
            -116.70763,
            47.63413,
            0
          ],
          [
            -116.7076,
            47.63407,
            0
          ],
          [
            -116.70759,
            47.63404,
            0
          ],
          [
            -116.70737,
            47.63364,
            0
          ],
          [
            -116.70724,
            47.63346,
            0
          ],
          [
            -116.70721,
            47.63344,
            0
          ],
          [
            -116.70718,
            47.63339,
            0
          ],
          [
            -116.70711,
            47.63332,
            0
          ],
          [
            -116.70701,
            47.6332,
            0
          ],
          [
            -116.70697,
            47.63317,
            0
          ],
          [
            -116.70674,
            47.63295,
            0
          ],
          [
            -116.70655,
            47.63281,
            0
          ],
          [
            -116.7065,
            47.63278,
            0
          ],
          [
            -116.70647,
            47.63275,
            0
          ],
          [
            -116.70645,
            47.63274,
            0
          ],
          [
            -116.70626,
            47.63261,
            0
          ],
          [
            -116.70589,
            47.6324,
            0
          ],
          [
            -116.70572,
            47.63232,
            0
          ],
          [
            -116.70564,
            47.63229,
            0
          ],
          [
            -116.70547,
            47.63221,
            0
          ],
          [
            -116.70535,
            47.63217,
            0
          ],
          [
            -116.70518,
            47.6321,
            0
          ],
          [
            -116.70469,
            47.63197,
            0
          ],
          [
            -116.70468,
            47.63197,
            0
          ],
          [
            -116.70468,
            47.63196,
            0
          ],
          [
            -116.70467,
            47.63196,
            0
          ],
          [
            -116.70446,
            47.63192,
            0
          ],
          [
            -116.70443,
            47.63191,
            0
          ],
          [
            -116.70442,
            47.63191,
            0
          ],
          [
            -116.70164,
            47.63132,
            0
          ],
          [
            -116.70163,
            47.63132,
            0
          ],
          [
            -116.70133,
            47.63126,
            0
          ],
          [
            -116.70126,
            47.63124,
            0
          ],
          [
            -116.70001,
            47.63099,
            0
          ],
          [
            -116.69953,
            47.63092,
            0
          ],
          [
            -116.69903,
            47.63087,
            0
          ],
          [
            -116.69826,
            47.63086,
            0
          ],
          [
            -116.69769,
            47.63089,
            0
          ],
          [
            -116.6972,
            47.63095,
            0
          ],
          [
            -116.69665,
            47.63104,
            0
          ],
          [
            -116.69645,
            47.63109,
            0
          ],
          [
            -116.69627,
            47.63112,
            0
          ],
          [
            -116.69586,
            47.63121,
            0
          ],
          [
            -116.69582,
            47.63121,
            0
          ],
          [
            -116.69571,
            47.63123,
            0
          ],
          [
            -116.69564,
            47.63125,
            0
          ],
          [
            -116.69486,
            47.63138,
            0
          ],
          [
            -116.69455,
            47.63141,
            0
          ],
          [
            -116.69389,
            47.63144,
            0
          ],
          [
            -116.69368,
            47.63144,
            0
          ],
          [
            -116.69365,
            47.63143,
            0
          ],
          [
            -116.69345,
            47.63143,
            0
          ],
          [
            -116.69334,
            47.63142,
            0
          ],
          [
            -116.69327,
            47.63142,
            0
          ],
          [
            -116.69311,
            47.6314,
            0
          ],
          [
            -116.69305,
            47.6314,
            0
          ],
          [
            -116.69292,
            47.63138,
            0
          ],
          [
            -116.69286,
            47.63138,
            0
          ],
          [
            -116.69249,
            47.63133,
            0
          ],
          [
            -116.69192,
            47.63121,
            0
          ],
          [
            -116.69177,
            47.63117,
            0
          ],
          [
            -116.69176,
            47.63117,
            0
          ],
          [
            -116.69059,
            47.63089,
            0
          ],
          [
            -116.69046,
            47.63085,
            0
          ],
          [
            -116.6901,
            47.63077,
            0
          ],
          [
            -116.69008,
            47.63076,
            0
          ],
          [
            -116.68932,
            47.63061,
            0
          ],
          [
            -116.6893,
            47.63061,
            0
          ],
          [
            -116.68919,
            47.63059,
            0
          ],
          [
            -116.68909,
            47.63058,
            0
          ],
          [
            -116.68887,
            47.63054,
            0
          ],
          [
            -116.6887,
            47.63052,
            0
          ],
          [
            -116.68866,
            47.63051,
            0
          ],
          [
            -116.68852,
            47.63049,
            0
          ],
          [
            -116.68847,
            47.63049,
            0
          ],
          [
            -116.68813,
            47.63044,
            0
          ],
          [
            -116.6881,
            47.63043,
            0
          ],
          [
            -116.68804,
            47.63043,
            0
          ],
          [
            -116.68794,
            47.63041,
            0
          ],
          [
            -116.6879,
            47.63041,
            0
          ],
          [
            -116.68777,
            47.63039,
            0
          ],
          [
            -116.68775,
            47.63039,
            0
          ],
          [
            -116.68766,
            47.63037,
            0
          ],
          [
            -116.68763,
            47.63037,
            0
          ],
          [
            -116.68688,
            47.63026,
            0
          ],
          [
            -116.68685,
            47.63025,
            0
          ],
          [
            -116.68648,
            47.63018,
            0
          ],
          [
            -116.68646,
            47.63017,
            0
          ],
          [
            -116.68624,
            47.63013,
            0
          ],
          [
            -116.68622,
            47.63012,
            0
          ],
          [
            -116.68603,
            47.63008,
            0
          ],
          [
            -116.68594,
            47.63005,
            0
          ],
          [
            -116.68589,
            47.63004,
            0
          ],
          [
            -116.68587,
            47.63004,
            0
          ],
          [
            -116.6857,
            47.62999,
            0
          ],
          [
            -116.68564,
            47.62998,
            0
          ],
          [
            -116.68555,
            47.62995,
            0
          ],
          [
            -116.68542,
            47.62992,
            0
          ],
          [
            -116.68524,
            47.62986,
            0
          ],
          [
            -116.68517,
            47.62985,
            0
          ],
          [
            -116.68502,
            47.6298,
            0
          ],
          [
            -116.68501,
            47.62979,
            0
          ],
          [
            -116.68499,
            47.62979,
            0
          ],
          [
            -116.68481,
            47.62973,
            0
          ],
          [
            -116.68479,
            47.62972,
            0
          ],
          [
            -116.68476,
            47.62972,
            0
          ],
          [
            -116.68472,
            47.6297,
            0
          ],
          [
            -116.68455,
            47.62965,
            0
          ],
          [
            -116.68451,
            47.62963,
            0
          ],
          [
            -116.68449,
            47.62963,
            0
          ],
          [
            -116.68405,
            47.62948,
            0
          ],
          [
            -116.68393,
            47.62945,
            0
          ],
          [
            -116.68389,
            47.62943,
            0
          ],
          [
            -116.68381,
            47.62941,
            0
          ],
          [
            -116.6824,
            47.62896,
            0
          ],
          [
            -116.68233,
            47.62893,
            0
          ],
          [
            -116.68212,
            47.62887,
            0
          ],
          [
            -116.68208,
            47.62885,
            0
          ],
          [
            -116.682,
            47.62883,
            0
          ],
          [
            -116.68139,
            47.62863,
            0
          ],
          [
            -116.6813,
            47.62861,
            0
          ],
          [
            -116.681,
            47.62851,
            0
          ],
          [
            -116.68095,
            47.6285,
            0
          ],
          [
            -116.68082,
            47.62846,
            0
          ],
          [
            -116.68075,
            47.62843,
            0
          ],
          [
            -116.68065,
            47.62841,
            0
          ],
          [
            -116.68063,
            47.6284,
            0
          ],
          [
            -116.68048,
            47.62835,
            0
          ],
          [
            -116.68043,
            47.62834,
            0
          ],
          [
            -116.68023,
            47.62827,
            0
          ],
          [
            -116.6802,
            47.62827,
            0
          ],
          [
            -116.68006,
            47.62822,
            0
          ],
          [
            -116.67932,
            47.628,
            0
          ],
          [
            -116.6793,
            47.628,
            0
          ],
          [
            -116.67838,
            47.62771,
            0
          ],
          [
            -116.67826,
            47.62768,
            0
          ],
          [
            -116.67821,
            47.62766,
            0
          ],
          [
            -116.67743,
            47.62743,
            0
          ],
          [
            -116.67724,
            47.62736,
            0
          ],
          [
            -116.67718,
            47.62735,
            0
          ],
          [
            -116.6769,
            47.62726,
            0
          ],
          [
            -116.67688,
            47.62726,
            0
          ],
          [
            -116.6767,
            47.6272,
            0
          ],
          [
            -116.67655,
            47.62716,
            0
          ],
          [
            -116.67654,
            47.62715,
            0
          ],
          [
            -116.67622,
            47.62706,
            0
          ],
          [
            -116.67605,
            47.627,
            0
          ],
          [
            -116.6748,
            47.62663,
            0
          ],
          [
            -116.67469,
            47.62659,
            0
          ],
          [
            -116.67454,
            47.62655,
            0
          ],
          [
            -116.67424,
            47.62645,
            0
          ],
          [
            -116.67385,
            47.62634,
            0
          ],
          [
            -116.6738,
            47.62632,
            0
          ],
          [
            -116.67325,
            47.62616,
            0
          ],
          [
            -116.6732,
            47.62614,
            0
          ],
          [
            -116.67193,
            47.62575,
            0
          ],
          [
            -116.67181,
            47.62572,
            0
          ],
          [
            -116.67076,
            47.62539,
            0
          ],
          [
            -116.6707,
            47.62538,
            0
          ],
          [
            -116.67033,
            47.62526,
            0
          ],
          [
            -116.6702,
            47.62523,
            0
          ],
          [
            -116.66997,
            47.62516,
            0
          ],
          [
            -116.6699,
            47.62513,
            0
          ],
          [
            -116.66979,
            47.6251,
            0
          ],
          [
            -116.66977,
            47.6251,
            0
          ],
          [
            -116.66929,
            47.62495,
            0
          ],
          [
            -116.66928,
            47.62495,
            0
          ],
          [
            -116.66773,
            47.62448,
            0
          ],
          [
            -116.66762,
            47.62444,
            0
          ],
          [
            -116.66725,
            47.62434,
            0
          ],
          [
            -116.66708,
            47.62428,
            0
          ],
          [
            -116.6665,
            47.62412,
            0
          ],
          [
            -116.66644,
            47.62411,
            0
          ],
          [
            -116.66572,
            47.62393,
            0
          ],
          [
            -116.66511,
            47.62381,
            0
          ],
          [
            -116.66394,
            47.62364,
            0
          ],
          [
            -116.66287,
            47.62354,
            0
          ],
          [
            -116.66233,
            47.62351,
            0
          ],
          [
            -116.66213,
            47.62351,
            0
          ],
          [
            -116.66209,
            47.6235,
            0
          ],
          [
            -116.66128,
            47.6235,
            0
          ],
          [
            -116.66062,
            47.62352,
            0
          ],
          [
            -116.66053,
            47.62353,
            0
          ],
          [
            -116.66004,
            47.62355,
            0
          ],
          [
            -116.65983,
            47.62357,
            0
          ],
          [
            -116.65977,
            47.62357,
            0
          ],
          [
            -116.65967,
            47.62358,
            0
          ],
          [
            -116.65957,
            47.62358,
            0
          ],
          [
            -116.65835,
            47.62368,
            0
          ],
          [
            -116.65832,
            47.62369,
            0
          ],
          [
            -116.65736,
            47.62373,
            0
          ],
          [
            -116.65646,
            47.62371,
            0
          ],
          [
            -116.65398,
            47.62354,
            0
          ],
          [
            -116.653,
            47.62351,
            0
          ],
          [
            -116.65199,
            47.62351,
            0
          ],
          [
            -116.6519,
            47.62348,
            0
          ],
          [
            -116.6518,
            47.62347,
            0
          ],
          [
            -116.65173,
            47.62347,
            0
          ],
          [
            -116.65126,
            47.62343,
            0
          ],
          [
            -116.65077,
            47.62335,
            0
          ],
          [
            -116.6507,
            47.62333,
            0
          ],
          [
            -116.65069,
            47.62333,
            0
          ],
          [
            -116.6498,
            47.6231,
            0
          ],
          [
            -116.64916,
            47.62297,
            0
          ],
          [
            -116.6491,
            47.62297,
            0
          ],
          [
            -116.64897,
            47.62288,
            0
          ],
          [
            -116.64888,
            47.62289,
            0
          ],
          [
            -116.64889,
            47.62269,
            0
          ],
          [
            -116.64892,
            47.62256,
            0
          ],
          [
            -116.64898,
            47.62237,
            0
          ],
          [
            -116.64898,
            47.62236,
            0
          ],
          [
            -116.6494,
            47.62126,
            0
          ],
          [
            -116.64964,
            47.62071,
            0
          ],
          [
            -116.64995,
            47.62015,
            0
          ],
          [
            -116.65032,
            47.61957,
            0
          ],
          [
            -116.6508,
            47.61895,
            0
          ],
          [
            -116.65136,
            47.61833,
            0
          ],
          [
            -116.65155,
            47.61816,
            0
          ],
          [
            -116.65173,
            47.61802,
            0
          ],
          [
            -116.65181,
            47.61797,
            0
          ],
          [
            -116.65193,
            47.61788,
            0
          ],
          [
            -116.652,
            47.61785,
            0
          ],
          [
            -116.65217,
            47.61776,
            0
          ],
          [
            -116.65272,
            47.61758,
            0
          ],
          [
            -116.65308,
            47.61752,
            0
          ],
          [
            -116.65349,
            47.61751,
            0
          ],
          [
            -116.65375,
            47.61754,
            0
          ],
          [
            -116.65376,
            47.61755,
            0
          ],
          [
            -116.65399,
            47.61761,
            0
          ],
          [
            -116.65475,
            47.61793,
            0
          ],
          [
            -116.65529,
            47.61825,
            0
          ],
          [
            -116.65602,
            47.61865,
            0
          ],
          [
            -116.65617,
            47.61877,
            0
          ],
          [
            -116.65646,
            47.61916,
            0
          ],
          [
            -116.65666,
            47.6193,
            0
          ],
          [
            -116.65678,
            47.61937,
            0
          ],
          [
            -116.6569,
            47.61941,
            0
          ],
          [
            -116.65694,
            47.61943,
            0
          ],
          [
            -116.65712,
            47.61947,
            0
          ],
          [
            -116.65715,
            47.61947,
            0
          ],
          [
            -116.65737,
            47.6195,
            0
          ],
          [
            -116.65771,
            47.61952,
            0
          ],
          [
            -116.65816,
            47.61946,
            0
          ],
          [
            -116.65828,
            47.61942,
            0
          ],
          [
            -116.6584,
            47.61937,
            0
          ],
          [
            -116.65862,
            47.61924,
            0
          ],
          [
            -116.65897,
            47.61893,
            0
          ],
          [
            -116.65915,
            47.61879,
            0
          ],
          [
            -116.65935,
            47.61868,
            0
          ],
          [
            -116.6595,
            47.61861,
            0
          ],
          [
            -116.65968,
            47.61856,
            0
          ],
          [
            -116.65993,
            47.61851,
            0
          ],
          [
            -116.66044,
            47.61847,
            0
          ],
          [
            -116.66287,
            47.61848,
            0
          ],
          [
            -116.66634,
            47.61882,
            0
          ],
          [
            -116.66646,
            47.61885,
            0
          ],
          [
            -116.66719,
            47.6191,
            0
          ],
          [
            -116.66737,
            47.61915,
            0
          ],
          [
            -116.66793,
            47.61919,
            0
          ],
          [
            -116.66806,
            47.61921,
            0
          ],
          [
            -116.66809,
            47.61922,
            0
          ],
          [
            -116.66828,
            47.61925,
            0
          ],
          [
            -116.66936,
            47.6196,
            0
          ],
          [
            -116.67053,
            47.61988,
            0
          ],
          [
            -116.67101,
            47.61997,
            0
          ],
          [
            -116.67193,
            47.6201,
            0
          ],
          [
            -116.6724,
            47.62019,
            0
          ],
          [
            -116.67274,
            47.62027,
            0
          ],
          [
            -116.67293,
            47.6203,
            0
          ],
          [
            -116.675,
            47.62043,
            0
          ],
          [
            -116.67505,
            47.62044,
            0
          ],
          [
            -116.67594,
            47.62052,
            0
          ],
          [
            -116.67877,
            47.62059,
            0
          ],
          [
            -116.67938,
            47.62065,
            0
          ],
          [
            -116.67957,
            47.62063,
            0
          ],
          [
            -116.67966,
            47.62061,
            0
          ],
          [
            -116.67975,
            47.62057,
            0
          ],
          [
            -116.67987,
            47.62049,
            0
          ],
          [
            -116.67996,
            47.6204,
            0
          ],
          [
            -116.68032,
            47.61964,
            0
          ],
          [
            -116.68039,
            47.61935,
            0
          ],
          [
            -116.68038,
            47.61925,
            0
          ],
          [
            -116.68034,
            47.61911,
            0
          ],
          [
            -116.68023,
            47.61889,
            0
          ],
          [
            -116.68011,
            47.61869,
            0
          ],
          [
            -116.67999,
            47.61844,
            0
          ],
          [
            -116.67992,
            47.61827,
            0
          ],
          [
            -116.67984,
            47.61797,
            0
          ],
          [
            -116.67983,
            47.61772,
            0
          ],
          [
            -116.67984,
            47.61764,
            0
          ],
          [
            -116.67999,
            47.61698,
            0
          ],
          [
            -116.67999,
            47.6169,
            0
          ],
          [
            -116.68,
            47.61678,
            0
          ],
          [
            -116.67996,
            47.61652,
            0
          ],
          [
            -116.67986,
            47.61624,
            0
          ],
          [
            -116.67977,
            47.61605,
            0
          ],
          [
            -116.67966,
            47.61588,
            0
          ],
          [
            -116.67954,
            47.61574,
            0
          ],
          [
            -116.67952,
            47.61573,
            0
          ],
          [
            -116.67921,
            47.61547,
            0
          ],
          [
            -116.67916,
            47.61542,
            0
          ],
          [
            -116.67907,
            47.61529,
            0
          ],
          [
            -116.67904,
            47.61523,
            0
          ],
          [
            -116.67902,
            47.61515,
            0
          ],
          [
            -116.67902,
            47.61495,
            0
          ],
          [
            -116.67908,
            47.61476,
            0
          ],
          [
            -116.67909,
            47.61476,
            0
          ],
          [
            -116.67915,
            47.61463,
            0
          ],
          [
            -116.67937,
            47.61432,
            0
          ],
          [
            -116.67951,
            47.6141,
            0
          ],
          [
            -116.67952,
            47.61404,
            0
          ],
          [
            -116.67954,
            47.61399,
            0
          ],
          [
            -116.67955,
            47.61395,
            0
          ],
          [
            -116.67955,
            47.61385,
            0
          ],
          [
            -116.67952,
            47.61375,
            0
          ],
          [
            -116.67944,
            47.6136,
            0
          ],
          [
            -116.67935,
            47.6135,
            0
          ],
          [
            -116.67924,
            47.61341,
            0
          ],
          [
            -116.67913,
            47.61334,
            0
          ],
          [
            -116.67912,
            47.61332,
            0
          ],
          [
            -116.67847,
            47.61298,
            0
          ],
          [
            -116.6784,
            47.61293,
            0
          ],
          [
            -116.67833,
            47.61286,
            0
          ],
          [
            -116.67829,
            47.61279,
            0
          ],
          [
            -116.67829,
            47.61273,
            0
          ],
          [
            -116.6783,
            47.61266,
            0
          ],
          [
            -116.67832,
            47.61261,
            0
          ],
          [
            -116.6785,
            47.61228,
            0
          ],
          [
            -116.67877,
            47.61184,
            0
          ],
          [
            -116.67887,
            47.61165,
            0
          ],
          [
            -116.67926,
            47.61101,
            0
          ],
          [
            -116.67936,
            47.61092,
            0
          ],
          [
            -116.6795,
            47.61084,
            0
          ],
          [
            -116.67964,
            47.6108,
            0
          ],
          [
            -116.67976,
            47.61078,
            0
          ],
          [
            -116.68009,
            47.6108,
            0
          ],
          [
            -116.68036,
            47.61086,
            0
          ],
          [
            -116.68073,
            47.61097,
            0
          ],
          [
            -116.68085,
            47.61103,
            0
          ],
          [
            -116.68086,
            47.61103,
            0
          ],
          [
            -116.68105,
            47.61116,
            0
          ],
          [
            -116.68123,
            47.61132,
            0
          ],
          [
            -116.68165,
            47.61161,
            0
          ],
          [
            -116.68177,
            47.61167,
            0
          ],
          [
            -116.68202,
            47.61176,
            0
          ],
          [
            -116.68204,
            47.61176,
            0
          ],
          [
            -116.68217,
            47.61179,
            0
          ],
          [
            -116.68237,
            47.61187,
            0
          ],
          [
            -116.68248,
            47.61194,
            0
          ],
          [
            -116.68255,
            47.612,
            0
          ],
          [
            -116.68263,
            47.61214,
            0
          ],
          [
            -116.6827,
            47.6123,
            0
          ],
          [
            -116.68285,
            47.6125,
            0
          ],
          [
            -116.68293,
            47.61258,
            0
          ],
          [
            -116.68299,
            47.61262,
            0
          ],
          [
            -116.68314,
            47.6127,
            0
          ],
          [
            -116.68322,
            47.61273,
            0
          ],
          [
            -116.68375,
            47.61289,
            0
          ],
          [
            -116.68399,
            47.61299,
            0
          ],
          [
            -116.68411,
            47.61303,
            0
          ],
          [
            -116.68427,
            47.61312,
            0
          ],
          [
            -116.68444,
            47.61324,
            0
          ],
          [
            -116.68458,
            47.61337,
            0
          ],
          [
            -116.68463,
            47.6134,
            0
          ],
          [
            -116.68464,
            47.61341,
            0
          ],
          [
            -116.68475,
            47.61346,
            0
          ],
          [
            -116.68483,
            47.61348,
            0
          ],
          [
            -116.68489,
            47.61348,
            0
          ],
          [
            -116.68508,
            47.61345,
            0
          ],
          [
            -116.68513,
            47.61343,
            0
          ],
          [
            -116.6853,
            47.61334,
            0
          ],
          [
            -116.68567,
            47.61308,
            0
          ],
          [
            -116.68595,
            47.61294,
            0
          ],
          [
            -116.68613,
            47.61287,
            0
          ],
          [
            -116.68628,
            47.6128,
            0
          ],
          [
            -116.68637,
            47.61275,
            0
          ],
          [
            -116.68639,
            47.61275,
            0
          ],
          [
            -116.68654,
            47.6126,
            0
          ],
          [
            -116.68663,
            47.61245,
            0
          ],
          [
            -116.68664,
            47.61245,
            0
          ],
          [
            -116.6867,
            47.61233,
            0
          ],
          [
            -116.68677,
            47.61212,
            0
          ],
          [
            -116.68677,
            47.6121,
            0
          ],
          [
            -116.68679,
            47.612,
            0
          ],
          [
            -116.6868,
            47.61182,
            0
          ],
          [
            -116.6868,
            47.61136,
            0
          ],
          [
            -116.68682,
            47.61102,
            0
          ],
          [
            -116.68679,
            47.61077,
            0
          ],
          [
            -116.68676,
            47.61066,
            0
          ],
          [
            -116.68675,
            47.6106,
            0
          ],
          [
            -116.68666,
            47.61037,
            0
          ],
          [
            -116.68663,
            47.61031,
            0
          ],
          [
            -116.68663,
            47.6103,
            0
          ],
          [
            -116.68659,
            47.61016,
            0
          ],
          [
            -116.68658,
            47.61014,
            0
          ],
          [
            -116.68655,
            47.61001,
            0
          ],
          [
            -116.68655,
            47.60976,
            0
          ],
          [
            -116.68652,
            47.6096,
            0
          ],
          [
            -116.6865,
            47.60954,
            0
          ],
          [
            -116.68641,
            47.60935,
            0
          ],
          [
            -116.68634,
            47.60924,
            0
          ],
          [
            -116.68629,
            47.60919,
            0
          ],
          [
            -116.68615,
            47.60902,
            0
          ],
          [
            -116.68579,
            47.60854,
            0
          ],
          [
            -116.68543,
            47.6081,
            0
          ],
          [
            -116.68531,
            47.60789,
            0
          ],
          [
            -116.6853,
            47.60785,
            0
          ],
          [
            -116.68527,
            47.60777,
            0
          ],
          [
            -116.68523,
            47.60761,
            0
          ],
          [
            -116.68521,
            47.60746,
            0
          ],
          [
            -116.6852,
            47.60718,
            0
          ],
          [
            -116.68532,
            47.60613,
            0
          ],
          [
            -116.68551,
            47.60485,
            0
          ],
          [
            -116.68553,
            47.6048,
            0
          ],
          [
            -116.68556,
            47.60469,
            0
          ],
          [
            -116.68556,
            47.60468,
            0
          ],
          [
            -116.68559,
            47.60457,
            0
          ],
          [
            -116.68571,
            47.60428,
            0
          ],
          [
            -116.68586,
            47.60401,
            0
          ],
          [
            -116.68588,
            47.60399,
            0
          ],
          [
            -116.68594,
            47.60385,
            0
          ],
          [
            -116.68597,
            47.60382,
            0
          ],
          [
            -116.68606,
            47.60365,
            0
          ],
          [
            -116.68608,
            47.60363,
            0
          ],
          [
            -116.68612,
            47.60354,
            0
          ],
          [
            -116.68617,
            47.60346,
            0
          ],
          [
            -116.68623,
            47.60339,
            0
          ],
          [
            -116.68638,
            47.60331,
            0
          ],
          [
            -116.68649,
            47.60329,
            0
          ],
          [
            -116.68662,
            47.60329,
            0
          ],
          [
            -116.68675,
            47.60334,
            0
          ],
          [
            -116.68681,
            47.60337,
            0
          ],
          [
            -116.68688,
            47.60344,
            0
          ],
          [
            -116.68693,
            47.60355,
            0
          ],
          [
            -116.68695,
            47.60362,
            0
          ],
          [
            -116.68695,
            47.60365,
            0
          ],
          [
            -116.68696,
            47.60372,
            0
          ],
          [
            -116.68697,
            47.60456,
            0
          ],
          [
            -116.68702,
            47.6048,
            0
          ],
          [
            -116.68705,
            47.605,
            0
          ],
          [
            -116.68721,
            47.60551,
            0
          ],
          [
            -116.68721,
            47.60552,
            0
          ],
          [
            -116.6874,
            47.60603,
            0
          ],
          [
            -116.68749,
            47.60632,
            0
          ],
          [
            -116.68755,
            47.60665,
            0
          ],
          [
            -116.68759,
            47.60678,
            0
          ],
          [
            -116.68762,
            47.60684,
            0
          ],
          [
            -116.68769,
            47.60691,
            0
          ],
          [
            -116.68772,
            47.60693,
            0
          ],
          [
            -116.68782,
            47.60697,
            0
          ],
          [
            -116.68791,
            47.60699,
            0
          ],
          [
            -116.68798,
            47.60699,
            0
          ],
          [
            -116.6881,
            47.60697,
            0
          ],
          [
            -116.68816,
            47.60695,
            0
          ],
          [
            -116.68817,
            47.60695,
            0
          ],
          [
            -116.68822,
            47.60693,
            0
          ],
          [
            -116.68829,
            47.60689,
            0
          ],
          [
            -116.68832,
            47.60686,
            0
          ],
          [
            -116.68836,
            47.60683,
            0
          ],
          [
            -116.68865,
            47.60653,
            0
          ],
          [
            -116.68866,
            47.60653,
            0
          ],
          [
            -116.68882,
            47.60639,
            0
          ],
          [
            -116.68916,
            47.60616,
            0
          ],
          [
            -116.68921,
            47.60614,
            0
          ],
          [
            -116.68938,
            47.60604,
            0
          ],
          [
            -116.68948,
            47.60601,
            0
          ],
          [
            -116.68958,
            47.606,
            0
          ],
          [
            -116.68963,
            47.60599,
            0
          ],
          [
            -116.6897,
            47.60599,
            0
          ],
          [
            -116.68986,
            47.60604,
            0
          ],
          [
            -116.68994,
            47.60608,
            0
          ],
          [
            -116.68997,
            47.60612,
            0
          ],
          [
            -116.69003,
            47.60618,
            0
          ],
          [
            -116.69005,
            47.60622,
            0
          ],
          [
            -116.69007,
            47.60628,
            0
          ],
          [
            -116.69013,
            47.60665,
            0
          ],
          [
            -116.69023,
            47.607,
            0
          ],
          [
            -116.69036,
            47.60735,
            0
          ],
          [
            -116.69039,
            47.60746,
            0
          ],
          [
            -116.69039,
            47.60748,
            0
          ],
          [
            -116.69048,
            47.60766,
            0
          ],
          [
            -116.69055,
            47.60771,
            0
          ],
          [
            -116.69063,
            47.60775,
            0
          ],
          [
            -116.69065,
            47.60775,
            0
          ],
          [
            -116.69072,
            47.60777,
            0
          ],
          [
            -116.69081,
            47.60778,
            0
          ],
          [
            -116.69112,
            47.60778,
            0
          ],
          [
            -116.69131,
            47.60781,
            0
          ],
          [
            -116.69136,
            47.60783,
            0
          ],
          [
            -116.69141,
            47.60786,
            0
          ],
          [
            -116.69144,
            47.60789,
            0
          ],
          [
            -116.69147,
            47.60791,
            0
          ],
          [
            -116.69153,
            47.60797,
            0
          ],
          [
            -116.69157,
            47.60805,
            0
          ],
          [
            -116.6916,
            47.60818,
            0
          ],
          [
            -116.6916,
            47.60819,
            0
          ],
          [
            -116.69174,
            47.60858,
            0
          ],
          [
            -116.69194,
            47.60885,
            0
          ],
          [
            -116.69223,
            47.60906,
            0
          ],
          [
            -116.69245,
            47.60919,
            0
          ],
          [
            -116.69252,
            47.60924,
            0
          ],
          [
            -116.69261,
            47.60932,
            0
          ],
          [
            -116.69287,
            47.6096,
            0
          ],
          [
            -116.69303,
            47.60972,
            0
          ],
          [
            -116.69315,
            47.60978,
            0
          ],
          [
            -116.69342,
            47.60985,
            0
          ],
          [
            -116.69356,
            47.60986,
            0
          ],
          [
            -116.69382,
            47.60986,
            0
          ],
          [
            -116.69468,
            47.60982,
            0
          ],
          [
            -116.69477,
            47.60981,
            0
          ],
          [
            -116.70875,
            47.60909,
            0
          ],
          [
            -116.70913,
            47.60904,
            0
          ],
          [
            -116.70947,
            47.60897,
            0
          ],
          [
            -116.70981,
            47.60887,
            0
          ],
          [
            -116.70982,
            47.60887,
            0
          ],
          [
            -116.71005,
            47.60878,
            0
          ],
          [
            -116.71006,
            47.60878,
            0
          ],
          [
            -116.71418,
            47.6069,
            0
          ],
          [
            -116.7142,
            47.6069,
            0
          ],
          [
            -116.71487,
            47.6066,
            0
          ],
          [
            -116.71504,
            47.60651,
            0
          ],
          [
            -116.71598,
            47.6061,
            0
          ],
          [
            -116.71609,
            47.60604,
            0
          ],
          [
            -116.71643,
            47.60591,
            0
          ],
          [
            -116.71661,
            47.60583,
            0
          ],
          [
            -116.71666,
            47.60582,
            0
          ],
          [
            -116.71689,
            47.60574,
            0
          ],
          [
            -116.71691,
            47.60573,
            0
          ],
          [
            -116.71705,
            47.60569,
            0
          ],
          [
            -116.71716,
            47.60567,
            0
          ],
          [
            -116.7173,
            47.60563,
            0
          ],
          [
            -116.71783,
            47.60554,
            0
          ],
          [
            -116.71841,
            47.60551,
            0
          ],
          [
            -116.72095,
            47.60556,
            0
          ],
          [
            -116.72161,
            47.60559,
            0
          ],
          [
            -116.72188,
            47.60562,
            0
          ],
          [
            -116.72219,
            47.60567,
            0
          ],
          [
            -116.72259,
            47.60576,
            0
          ],
          [
            -116.72317,
            47.60597,
            0
          ],
          [
            -116.72373,
            47.60622,
            0
          ],
          [
            -116.72377,
            47.60625,
            0
          ],
          [
            -116.72423,
            47.60648,
            0
          ],
          [
            -116.72424,
            47.60649,
            0
          ],
          [
            -116.72452,
            47.60664,
            0
          ],
          [
            -116.72466,
            47.60673,
            0
          ],
          [
            -116.7247,
            47.60675,
            0
          ],
          [
            -116.7249,
            47.60688,
            0
          ],
          [
            -116.72503,
            47.60678,
            0
          ],
          [
            -116.72506,
            47.60677,
            0
          ],
          [
            -116.72518,
            47.60668,
            0
          ],
          [
            -116.72524,
            47.60659,
            0
          ],
          [
            -116.72538,
            47.60615,
            0
          ],
          [
            -116.72538,
            47.6061,
            0
          ],
          [
            -116.72539,
            47.60609,
            0
          ],
          [
            -116.7256,
            47.60415,
            0
          ],
          [
            -116.72571,
            47.60376,
            0
          ],
          [
            -116.72571,
            47.60375,
            0
          ],
          [
            -116.72588,
            47.60342,
            0
          ],
          [
            -116.72614,
            47.60305,
            0
          ],
          [
            -116.72694,
            47.60161,
            0
          ],
          [
            -116.7274,
            47.60084,
            0
          ],
          [
            -116.72749,
            47.60076,
            0
          ],
          [
            -116.72763,
            47.60061,
            0
          ],
          [
            -116.72833,
            47.60008,
            0
          ],
          [
            -116.72972,
            47.59861,
            0
          ],
          [
            -116.72998,
            47.59844,
            0
          ],
          [
            -116.73013,
            47.59833,
            0
          ],
          [
            -116.73073,
            47.59773,
            0
          ],
          [
            -116.73147,
            47.59708,
            0
          ],
          [
            -116.73158,
            47.597,
            0
          ],
          [
            -116.73182,
            47.59679,
            0
          ],
          [
            -116.73213,
            47.59662,
            0
          ],
          [
            -116.73213,
            47.59661,
            0
          ],
          [
            -116.73259,
            47.59639,
            0
          ],
          [
            -116.73349,
            47.59605,
            0
          ],
          [
            -116.73437,
            47.59575,
            0
          ],
          [
            -116.73475,
            47.59569,
            0
          ],
          [
            -116.73606,
            47.59568,
            0
          ],
          [
            -116.73669,
            47.5957,
            0
          ],
          [
            -116.73716,
            47.59582,
            0
          ],
          [
            -116.7375,
            47.59599,
            0
          ],
          [
            -116.7379,
            47.59625,
            0
          ],
          [
            -116.73819,
            47.5966,
            0
          ],
          [
            -116.73836,
            47.59719,
            0
          ],
          [
            -116.73853,
            47.59758,
            0
          ],
          [
            -116.73872,
            47.59794,
            0
          ],
          [
            -116.73921,
            47.59832,
            0
          ],
          [
            -116.73963,
            47.5985,
            0
          ],
          [
            -116.74009,
            47.59855,
            0
          ],
          [
            -116.74395,
            47.5985,
            0
          ],
          [
            -116.74397,
            47.59849,
            0
          ],
          [
            -116.74422,
            47.59842,
            0
          ],
          [
            -116.74421,
            47.5984,
            0
          ],
          [
            -116.74448,
            47.59788,
            0
          ],
          [
            -116.74468,
            47.59724,
            0
          ],
          [
            -116.74474,
            47.59674,
            0
          ],
          [
            -116.74466,
            47.59597,
            0
          ],
          [
            -116.74453,
            47.59518,
            0
          ],
          [
            -116.74449,
            47.59472,
            0
          ],
          [
            -116.74448,
            47.59468,
            0
          ],
          [
            -116.74444,
            47.58994,
            0
          ],
          [
            -116.74434,
            47.58785,
            0
          ],
          [
            -116.74438,
            47.58704,
            0
          ],
          [
            -116.74445,
            47.58663,
            0
          ],
          [
            -116.74457,
            47.58638,
            0
          ],
          [
            -116.74466,
            47.58604,
            0
          ],
          [
            -116.74466,
            47.58602,
            0
          ],
          [
            -116.74472,
            47.58584,
            0
          ],
          [
            -116.7448,
            47.58565,
            0
          ],
          [
            -116.74488,
            47.58554,
            0
          ],
          [
            -116.74497,
            47.58544,
            0
          ],
          [
            -116.74506,
            47.58537,
            0
          ],
          [
            -116.74518,
            47.5853,
            0
          ],
          [
            -116.74529,
            47.58525,
            0
          ],
          [
            -116.74556,
            47.58516,
            0
          ],
          [
            -116.74627,
            47.585,
            0
          ],
          [
            -116.7464,
            47.58496,
            0
          ],
          [
            -116.74703,
            47.58481,
            0
          ],
          [
            -116.74727,
            47.58473,
            0
          ],
          [
            -116.74748,
            47.58461,
            0
          ],
          [
            -116.74759,
            47.58452,
            0
          ],
          [
            -116.74774,
            47.58434,
            0
          ],
          [
            -116.7478,
            47.58418,
            0
          ],
          [
            -116.74782,
            47.58411,
            0
          ],
          [
            -116.74782,
            47.58403,
            0
          ],
          [
            -116.7478,
            47.58389,
            0
          ],
          [
            -116.74775,
            47.58378,
            0
          ],
          [
            -116.74769,
            47.58369,
            0
          ],
          [
            -116.74761,
            47.5836,
            0
          ],
          [
            -116.74747,
            47.58348,
            0
          ],
          [
            -116.74722,
            47.58335,
            0
          ],
          [
            -116.74706,
            47.5833,
            0
          ],
          [
            -116.74633,
            47.58319,
            0
          ],
          [
            -116.74597,
            47.58311,
            0
          ],
          [
            -116.74569,
            47.58301,
            0
          ],
          [
            -116.74546,
            47.58291,
            0
          ],
          [
            -116.74503,
            47.58262,
            0
          ],
          [
            -116.74407,
            47.58165,
            0
          ],
          [
            -116.74394,
            47.58147,
            0
          ],
          [
            -116.74381,
            47.58118,
            0
          ],
          [
            -116.74378,
            47.58099,
            0
          ],
          [
            -116.74378,
            47.58079,
            0
          ],
          [
            -116.74379,
            47.58071,
            0
          ],
          [
            -116.74381,
            47.58064,
            0
          ],
          [
            -116.74389,
            47.58045,
            0
          ],
          [
            -116.74396,
            47.58031,
            0
          ],
          [
            -116.74398,
            47.58028,
            0
          ],
          [
            -116.74405,
            47.58013,
            0
          ],
          [
            -116.74421,
            47.57987,
            0
          ],
          [
            -116.74426,
            47.57973,
            0
          ],
          [
            -116.74421,
            47.57955,
            0
          ],
          [
            -116.74431,
            47.57926,
            0
          ],
          [
            -116.74436,
            47.57915,
            0
          ],
          [
            -116.74438,
            47.57909,
            0
          ],
          [
            -116.74438,
            47.57906,
            0
          ],
          [
            -116.74457,
            47.57844,
            0
          ],
          [
            -116.74459,
            47.57827,
            0
          ],
          [
            -116.7448,
            47.57786,
            0
          ],
          [
            -116.7449,
            47.5777,
            0
          ],
          [
            -116.74541,
            47.57697,
            0
          ],
          [
            -116.74572,
            47.57664,
            0
          ],
          [
            -116.74637,
            47.57603,
            0
          ],
          [
            -116.74641,
            47.57598,
            0
          ],
          [
            -116.74649,
            47.57575,
            0
          ],
          [
            -116.74662,
            47.57557,
            0
          ],
          [
            -116.74676,
            47.5754,
            0
          ],
          [
            -116.74698,
            47.57518,
            0
          ],
          [
            -116.74714,
            47.57506,
            0
          ],
          [
            -116.74735,
            47.57496,
            0
          ],
          [
            -116.74735,
            47.57494,
            0
          ],
          [
            -116.74773,
            47.57473,
            0
          ],
          [
            -116.74806,
            47.5746,
            0
          ],
          [
            -116.74906,
            47.5743,
            0
          ],
          [
            -116.74947,
            47.57424,
            0
          ],
          [
            -116.74983,
            47.57422,
            0
          ],
          [
            -116.75123,
            47.5742,
            0
          ],
          [
            -116.75178,
            47.57411,
            0
          ],
          [
            -116.75242,
            47.57393,
            0
          ],
          [
            -116.75288,
            47.57388,
            0
          ],
          [
            -116.75415,
            47.57392,
            0
          ],
          [
            -116.75466,
            47.57398,
            0
          ],
          [
            -116.75517,
            47.5741,
            0
          ],
          [
            -116.75582,
            47.57421,
            0
          ],
          [
            -116.75757,
            47.57461,
            0
          ],
          [
            -116.75809,
            47.5747,
            0
          ],
          [
            -116.75911,
            47.57472,
            0
          ],
          [
            -116.76,
            47.57491,
            0
          ],
          [
            -116.76151,
            47.57489,
            0
          ],
          [
            -116.76342,
            47.57509,
            0
          ],
          [
            -116.76404,
            47.57521,
            0
          ],
          [
            -116.76457,
            47.57527,
            0
          ],
          [
            -116.76536,
            47.57533,
            0
          ],
          [
            -116.76602,
            47.57531,
            0
          ],
          [
            -116.76647,
            47.57522,
            0
          ],
          [
            -116.76683,
            47.57521,
            0
          ],
          [
            -116.76718,
            47.57513,
            0
          ],
          [
            -116.76767,
            47.57497,
            0
          ],
          [
            -116.76868,
            47.5746,
            0
          ],
          [
            -116.76907,
            47.57444,
            0
          ],
          [
            -116.76937,
            47.57437,
            0
          ],
          [
            -116.76963,
            47.57434,
            0
          ],
          [
            -116.76992,
            47.57434,
            0
          ],
          [
            -116.77087,
            47.57438,
            0
          ],
          [
            -116.77201,
            47.57426,
            0
          ],
          [
            -116.77274,
            47.57423,
            0
          ],
          [
            -116.77318,
            47.57427,
            0
          ],
          [
            -116.77353,
            47.57439,
            0
          ],
          [
            -116.77371,
            47.57444,
            0
          ],
          [
            -116.77375,
            47.57444,
            0
          ],
          [
            -116.77394,
            47.57446,
            0
          ],
          [
            -116.77427,
            47.57446,
            0
          ],
          [
            -116.77504,
            47.57456,
            0
          ],
          [
            -116.77526,
            47.57461,
            0
          ],
          [
            -116.77529,
            47.57461,
            0
          ],
          [
            -116.77573,
            47.57475,
            0
          ],
          [
            -116.77575,
            47.57475,
            0
          ],
          [
            -116.77657,
            47.57493,
            0
          ],
          [
            -116.77749,
            47.57516,
            0
          ],
          [
            -116.77803,
            47.57526,
            0
          ],
          [
            -116.77817,
            47.5753,
            0
          ],
          [
            -116.77889,
            47.57544,
            0
          ],
          [
            -116.77991,
            47.57548,
            0
          ],
          [
            -116.78023,
            47.57546,
            0
          ],
          [
            -116.78121,
            47.5753,
            0
          ],
          [
            -116.78156,
            47.57526,
            0
          ],
          [
            -116.78222,
            47.57523,
            0
          ],
          [
            -116.78227,
            47.57522,
            0
          ],
          [
            -116.78277,
            47.5752,
            0
          ],
          [
            -116.78282,
            47.57517,
            0
          ],
          [
            -116.78266,
            47.57511,
            0
          ],
          [
            -116.78248,
            47.57503,
            0
          ],
          [
            -116.78237,
            47.57497,
            0
          ],
          [
            -116.78209,
            47.57479,
            0
          ],
          [
            -116.78185,
            47.57453,
            0
          ],
          [
            -116.78177,
            47.57442,
            0
          ],
          [
            -116.78162,
            47.57417,
            0
          ],
          [
            -116.78153,
            47.57392,
            0
          ],
          [
            -116.78149,
            47.57372,
            0
          ],
          [
            -116.78148,
            47.57353,
            0
          ],
          [
            -116.7815,
            47.57342,
            0
          ],
          [
            -116.7815,
            47.57339,
            0
          ],
          [
            -116.78151,
            47.57334,
            0
          ],
          [
            -116.78155,
            47.57321,
            0
          ],
          [
            -116.78155,
            47.5732,
            0
          ],
          [
            -116.78162,
            47.57306,
            0
          ],
          [
            -116.78174,
            47.57287,
            0
          ],
          [
            -116.78192,
            47.57266,
            0
          ],
          [
            -116.78202,
            47.57257,
            0
          ],
          [
            -116.78205,
            47.57255,
            0
          ],
          [
            -116.78285,
            47.57189,
            0
          ],
          [
            -116.78287,
            47.57188,
            0
          ],
          [
            -116.78338,
            47.57146,
            0
          ],
          [
            -116.78446,
            47.57066,
            0
          ],
          [
            -116.78531,
            47.56997,
            0
          ],
          [
            -116.78537,
            47.56993,
            0
          ],
          [
            -116.78579,
            47.56957,
            0
          ],
          [
            -116.78585,
            47.5695,
            0
          ],
          [
            -116.78592,
            47.56939,
            0
          ],
          [
            -116.78594,
            47.56934,
            0
          ],
          [
            -116.78597,
            47.56916,
            0
          ],
          [
            -116.78594,
            47.56904,
            0
          ],
          [
            -116.78591,
            47.56896,
            0
          ],
          [
            -116.78588,
            47.5689,
            0
          ],
          [
            -116.78579,
            47.56878,
            0
          ],
          [
            -116.78558,
            47.56855,
            0
          ],
          [
            -116.78556,
            47.56854,
            0
          ],
          [
            -116.78541,
            47.56836,
            0
          ],
          [
            -116.78537,
            47.56828,
            0
          ],
          [
            -116.78536,
            47.56824,
            0
          ],
          [
            -116.78535,
            47.56823,
            0
          ],
          [
            -116.78535,
            47.56817,
            0
          ],
          [
            -116.78538,
            47.56807,
            0
          ],
          [
            -116.78548,
            47.56797,
            0
          ],
          [
            -116.78564,
            47.56791,
            0
          ],
          [
            -116.78597,
            47.56783,
            0
          ],
          [
            -116.78676,
            47.56769,
            0
          ],
          [
            -116.78681,
            47.56769,
            0
          ],
          [
            -116.78731,
            47.56759,
            0
          ],
          [
            -116.78748,
            47.56752,
            0
          ],
          [
            -116.78753,
            47.56749,
            0
          ],
          [
            -116.78758,
            47.56745,
            0
          ],
          [
            -116.78766,
            47.56737,
            0
          ],
          [
            -116.78768,
            47.56733,
            0
          ],
          [
            -116.78769,
            47.56732,
            0
          ],
          [
            -116.78772,
            47.56727,
            0
          ],
          [
            -116.78773,
            47.56721,
            0
          ],
          [
            -116.78774,
            47.56718,
            0
          ],
          [
            -116.78774,
            47.56709,
            0
          ],
          [
            -116.78773,
            47.56703,
            0
          ],
          [
            -116.78767,
            47.5669,
            0
          ],
          [
            -116.7876,
            47.56681,
            0
          ],
          [
            -116.78745,
            47.56667,
            0
          ],
          [
            -116.78703,
            47.56639,
            0
          ],
          [
            -116.78687,
            47.5663,
            0
          ],
          [
            -116.78681,
            47.56625,
            0
          ],
          [
            -116.78643,
            47.56602,
            0
          ],
          [
            -116.78636,
            47.56599,
            0
          ],
          [
            -116.78628,
            47.56594,
            0
          ],
          [
            -116.78618,
            47.5659,
            0
          ],
          [
            -116.78606,
            47.56584,
            0
          ],
          [
            -116.78582,
            47.56574,
            0
          ],
          [
            -116.7849,
            47.56544,
            0
          ],
          [
            -116.78467,
            47.56531,
            0
          ],
          [
            -116.78459,
            47.56525,
            0
          ],
          [
            -116.78445,
            47.56511,
            0
          ],
          [
            -116.7844,
            47.56501,
            0
          ],
          [
            -116.78436,
            47.56495,
            0
          ],
          [
            -116.78433,
            47.56486,
            0
          ],
          [
            -116.78431,
            47.56469,
            0
          ],
          [
            -116.7843,
            47.5643,
            0
          ],
          [
            -116.78424,
            47.56414,
            0
          ],
          [
            -116.78416,
            47.56401,
            0
          ],
          [
            -116.78405,
            47.5639,
            0
          ],
          [
            -116.78387,
            47.56378,
            0
          ],
          [
            -116.78352,
            47.56357,
            0
          ],
          [
            -116.78333,
            47.56342,
            0
          ],
          [
            -116.78325,
            47.56333,
            0
          ],
          [
            -116.78311,
            47.56306,
            0
          ],
          [
            -116.78308,
            47.56299,
            0
          ],
          [
            -116.783,
            47.56285,
            0
          ],
          [
            -116.78299,
            47.56282,
            0
          ],
          [
            -116.7829,
            47.56263,
            0
          ],
          [
            -116.78276,
            47.56222,
            0
          ],
          [
            -116.78268,
            47.56207,
            0
          ],
          [
            -116.78255,
            47.56187,
            0
          ],
          [
            -116.78249,
            47.5618,
            0
          ],
          [
            -116.78249,
            47.56179,
            0
          ],
          [
            -116.78223,
            47.56154,
            0
          ],
          [
            -116.78152,
            47.56113,
            0
          ],
          [
            -116.78145,
            47.56107,
            0
          ],
          [
            -116.78142,
            47.56103,
            0
          ],
          [
            -116.78142,
            47.56102,
            0
          ],
          [
            -116.78141,
            47.561,
            0
          ],
          [
            -116.78141,
            47.56092,
            0
          ],
          [
            -116.78143,
            47.56087,
            0
          ],
          [
            -116.78145,
            47.56084,
            0
          ],
          [
            -116.78148,
            47.56081,
            0
          ],
          [
            -116.78154,
            47.56077,
            0
          ],
          [
            -116.78165,
            47.56073,
            0
          ],
          [
            -116.78193,
            47.56068,
            0
          ],
          [
            -116.78205,
            47.56067,
            0
          ],
          [
            -116.78222,
            47.56064,
            0
          ],
          [
            -116.78242,
            47.56057,
            0
          ],
          [
            -116.78264,
            47.56045,
            0
          ],
          [
            -116.78275,
            47.56037,
            0
          ],
          [
            -116.78277,
            47.56034,
            0
          ],
          [
            -116.7831,
            47.56006,
            0
          ],
          [
            -116.78397,
            47.55938,
            0
          ],
          [
            -116.78415,
            47.55922,
            0
          ],
          [
            -116.78443,
            47.55881,
            0
          ],
          [
            -116.78452,
            47.55862,
            0
          ],
          [
            -116.78457,
            47.55847,
            0
          ],
          [
            -116.78459,
            47.55829,
            0
          ],
          [
            -116.78454,
            47.55779,
            0
          ],
          [
            -116.78415,
            47.55522,
            0
          ],
          [
            -116.78414,
            47.55519,
            0
          ],
          [
            -116.784,
            47.55439,
            0
          ],
          [
            -116.78397,
            47.5543,
            0
          ],
          [
            -116.78396,
            47.55429,
            0
          ],
          [
            -116.78393,
            47.5542,
            0
          ],
          [
            -116.78385,
            47.55406,
            0
          ],
          [
            -116.78344,
            47.55348,
            0
          ],
          [
            -116.7834,
            47.55341,
            0
          ],
          [
            -116.78312,
            47.55304,
            0
          ],
          [
            -116.78287,
            47.55267,
            0
          ],
          [
            -116.78287,
            47.55266,
            0
          ],
          [
            -116.78254,
            47.55219,
            0
          ],
          [
            -116.78244,
            47.55208,
            0
          ],
          [
            -116.78233,
            47.55198,
            0
          ],
          [
            -116.78222,
            47.5519,
            0
          ],
          [
            -116.7821,
            47.55185,
            0
          ],
          [
            -116.78198,
            47.55181,
            0
          ],
          [
            -116.78185,
            47.55178,
            0
          ],
          [
            -116.78175,
            47.55177,
            0
          ],
          [
            -116.78167,
            47.55177,
            0
          ],
          [
            -116.78137,
            47.55173,
            0
          ],
          [
            -116.78127,
            47.55171,
            0
          ],
          [
            -116.78097,
            47.55162,
            0
          ],
          [
            -116.78003,
            47.55121,
            0
          ],
          [
            -116.77989,
            47.55113,
            0
          ],
          [
            -116.77888,
            47.55045,
            0
          ],
          [
            -116.77876,
            47.55035,
            0
          ],
          [
            -116.77862,
            47.5502,
            0
          ],
          [
            -116.77852,
            47.55007,
            0
          ],
          [
            -116.7785,
            47.54998,
            0
          ],
          [
            -116.77849,
            47.5499,
            0
          ],
          [
            -116.7785,
            47.54981,
            0
          ],
          [
            -116.77874,
            47.54912,
            0
          ],
          [
            -116.77875,
            47.54911,
            0
          ],
          [
            -116.77875,
            47.54894,
            0
          ],
          [
            -116.77872,
            47.54885,
            0
          ],
          [
            -116.77864,
            47.54872,
            0
          ],
          [
            -116.77856,
            47.54864,
            0
          ],
          [
            -116.77843,
            47.54854,
            0
          ],
          [
            -116.77823,
            47.54842,
            0
          ],
          [
            -116.77813,
            47.54838,
            0
          ],
          [
            -116.77812,
            47.54837,
            0
          ],
          [
            -116.77786,
            47.5482,
            0
          ],
          [
            -116.77607,
            47.54733,
            0
          ],
          [
            -116.776,
            47.54731,
            0
          ],
          [
            -116.77582,
            47.54724,
            0
          ],
          [
            -116.77576,
            47.54723,
            0
          ],
          [
            -116.77558,
            47.54718,
            0
          ],
          [
            -116.77538,
            47.54715,
            0
          ],
          [
            -116.77507,
            47.54714,
            0
          ],
          [
            -116.77474,
            47.54709,
            0
          ],
          [
            -116.77467,
            47.54706,
            0
          ],
          [
            -116.77459,
            47.547,
            0
          ],
          [
            -116.77455,
            47.54695,
            0
          ],
          [
            -116.77451,
            47.54686,
            0
          ],
          [
            -116.7745,
            47.54681,
            0
          ],
          [
            -116.7745,
            47.54675,
            0
          ],
          [
            -116.77453,
            47.54667,
            0
          ],
          [
            -116.77457,
            47.5466,
            0
          ],
          [
            -116.77459,
            47.54655,
            0
          ],
          [
            -116.77465,
            47.54645,
            0
          ],
          [
            -116.77469,
            47.54634,
            0
          ],
          [
            -116.77471,
            47.54625,
            0
          ],
          [
            -116.7747,
            47.5461,
            0
          ],
          [
            -116.77465,
            47.54598,
            0
          ],
          [
            -116.77461,
            47.5458,
            0
          ],
          [
            -116.77457,
            47.54555,
            0
          ],
          [
            -116.77447,
            47.5452,
            0
          ],
          [
            -116.7744,
            47.54502,
            0
          ],
          [
            -116.77419,
            47.54471,
            0
          ],
          [
            -116.77396,
            47.5445,
            0
          ],
          [
            -116.77384,
            47.54435,
            0
          ],
          [
            -116.77374,
            47.54425,
            0
          ],
          [
            -116.7734,
            47.5441,
            0
          ],
          [
            -116.77266,
            47.5439,
            0
          ],
          [
            -116.77234,
            47.5438,
            0
          ],
          [
            -116.77215,
            47.54372,
            0
          ],
          [
            -116.77205,
            47.54363,
            0
          ],
          [
            -116.77175,
            47.54344,
            0
          ],
          [
            -116.77147,
            47.5432,
            0
          ],
          [
            -116.77137,
            47.5431,
            0
          ],
          [
            -116.77126,
            47.54297,
            0
          ],
          [
            -116.7712,
            47.54288,
            0
          ],
          [
            -116.77118,
            47.54286,
            0
          ],
          [
            -116.7711,
            47.54272,
            0
          ],
          [
            -116.7711,
            47.54271,
            0
          ],
          [
            -116.77098,
            47.54241,
            0
          ],
          [
            -116.77094,
            47.54211,
            0
          ],
          [
            -116.77094,
            47.54195,
            0
          ],
          [
            -116.77093,
            47.54182,
            0
          ],
          [
            -116.77092,
            47.5418,
            0
          ],
          [
            -116.7709,
            47.5417,
            0
          ],
          [
            -116.77086,
            47.54159,
            0
          ],
          [
            -116.77074,
            47.54139,
            0
          ],
          [
            -116.77067,
            47.5413,
            0
          ],
          [
            -116.77045,
            47.54109,
            0
          ],
          [
            -116.7703,
            47.54097,
            0
          ],
          [
            -116.76965,
            47.54058,
            0
          ],
          [
            -116.76823,
            47.53978,
            0
          ],
          [
            -116.76804,
            47.53964,
            0
          ],
          [
            -116.76791,
            47.53953,
            0
          ],
          [
            -116.76777,
            47.53938,
            0
          ],
          [
            -116.76767,
            47.53925,
            0
          ],
          [
            -116.7676,
            47.53912,
            0
          ],
          [
            -116.76755,
            47.53898,
            0
          ],
          [
            -116.76752,
            47.53886,
            0
          ],
          [
            -116.76751,
            47.53878,
            0
          ],
          [
            -116.76751,
            47.53866,
            0
          ],
          [
            -116.76752,
            47.53856,
            0
          ],
          [
            -116.76756,
            47.53841,
            0
          ],
          [
            -116.76762,
            47.53826,
            0
          ],
          [
            -116.76769,
            47.53813,
            0
          ],
          [
            -116.76777,
            47.53802,
            0
          ],
          [
            -116.76786,
            47.53792,
            0
          ],
          [
            -116.76799,
            47.5378,
            0
          ],
          [
            -116.76815,
            47.53769,
            0
          ],
          [
            -116.76821,
            47.53766,
            0
          ],
          [
            -116.76834,
            47.53758,
            0
          ],
          [
            -116.76851,
            47.5375,
            0
          ],
          [
            -116.76864,
            47.53745,
            0
          ],
          [
            -116.76908,
            47.53733,
            0
          ],
          [
            -116.77024,
            47.53712,
            0
          ],
          [
            -116.77058,
            47.53704,
            0
          ],
          [
            -116.77086,
            47.53696,
            0
          ],
          [
            -116.77117,
            47.53685,
            0
          ],
          [
            -116.77178,
            47.53659,
            0
          ],
          [
            -116.77193,
            47.53654,
            0
          ],
          [
            -116.77237,
            47.53642,
            0
          ],
          [
            -116.77285,
            47.53636,
            0
          ],
          [
            -116.77308,
            47.53635,
            0
          ],
          [
            -116.77419,
            47.53635,
            0
          ],
          [
            -116.77466,
            47.53631,
            0
          ],
          [
            -116.77537,
            47.53619,
            0
          ],
          [
            -116.77619,
            47.53602,
            0
          ],
          [
            -116.77648,
            47.53598,
            0
          ],
          [
            -116.77653,
            47.53598,
            0
          ],
          [
            -116.77681,
            47.53595,
            0
          ],
          [
            -116.77749,
            47.53593,
            0
          ],
          [
            -116.77772,
            47.53594,
            0
          ],
          [
            -116.77833,
            47.536,
            0
          ],
          [
            -116.77866,
            47.53606,
            0
          ],
          [
            -116.77999,
            47.53641,
            0
          ],
          [
            -116.78038,
            47.53654,
            0
          ],
          [
            -116.78088,
            47.53674,
            0
          ],
          [
            -116.78154,
            47.53711,
            0
          ],
          [
            -116.78167,
            47.53716,
            0
          ],
          [
            -116.78185,
            47.53721,
            0
          ],
          [
            -116.78192,
            47.53722,
            0
          ],
          [
            -116.78204,
            47.53722,
            0
          ],
          [
            -116.78216,
            47.53721,
            0
          ],
          [
            -116.78233,
            47.53717,
            0
          ],
          [
            -116.78234,
            47.53717,
            0
          ],
          [
            -116.78255,
            47.53707,
            0
          ],
          [
            -116.78261,
            47.53703,
            0
          ],
          [
            -116.78271,
            47.53693,
            0
          ],
          [
            -116.78303,
            47.53639,
            0
          ],
          [
            -116.78315,
            47.53626,
            0
          ],
          [
            -116.78346,
            47.53598,
            0
          ],
          [
            -116.78367,
            47.53584,
            0
          ],
          [
            -116.7839,
            47.53571,
            0
          ],
          [
            -116.78424,
            47.53554,
            0
          ],
          [
            -116.78502,
            47.53521,
            0
          ],
          [
            -116.78535,
            47.53509,
            0
          ],
          [
            -116.78591,
            47.53493,
            0
          ],
          [
            -116.7861,
            47.53486,
            0
          ],
          [
            -116.78621,
            47.53481,
            0
          ],
          [
            -116.7865,
            47.53462,
            0
          ],
          [
            -116.7866,
            47.53454,
            0
          ],
          [
            -116.78671,
            47.53448,
            0
          ],
          [
            -116.78672,
            47.53447,
            0
          ],
          [
            -116.78693,
            47.5344,
            0
          ],
          [
            -116.78704,
            47.53439,
            0
          ],
          [
            -116.78821,
            47.53439,
            0
          ],
          [
            -116.78862,
            47.53431,
            0
          ],
          [
            -116.78889,
            47.53421,
            0
          ],
          [
            -116.78914,
            47.53405,
            0
          ],
          [
            -116.78925,
            47.53395,
            0
          ],
          [
            -116.79023,
            47.53287,
            0
          ],
          [
            -116.79035,
            47.53276,
            0
          ],
          [
            -116.7905,
            47.53264,
            0
          ],
          [
            -116.79071,
            47.53249,
            0
          ],
          [
            -116.79106,
            47.5323,
            0
          ],
          [
            -116.79182,
            47.53198,
            0
          ],
          [
            -116.79218,
            47.53179,
            0
          ],
          [
            -116.79233,
            47.5317,
            0
          ],
          [
            -116.79253,
            47.53156,
            0
          ],
          [
            -116.79271,
            47.5314,
            0
          ],
          [
            -116.79284,
            47.53127,
            0
          ],
          [
            -116.79296,
            47.53113,
            0
          ],
          [
            -116.79305,
            47.53099,
            0
          ],
          [
            -116.79314,
            47.53082,
            0
          ],
          [
            -116.79329,
            47.53034,
            0
          ],
          [
            -116.79348,
            47.52953,
            0
          ],
          [
            -116.79348,
            47.52945,
            0
          ],
          [
            -116.79349,
            47.52936,
            0
          ],
          [
            -116.79347,
            47.52921,
            0
          ],
          [
            -116.79343,
            47.52903,
            0
          ],
          [
            -116.79339,
            47.52891,
            0
          ],
          [
            -116.79336,
            47.52879,
            0
          ],
          [
            -116.79334,
            47.52874,
            0
          ],
          [
            -116.79323,
            47.52831,
            0
          ],
          [
            -116.79323,
            47.52811,
            0
          ],
          [
            -116.79325,
            47.52804,
            0
          ],
          [
            -116.79333,
            47.52789,
            0
          ],
          [
            -116.79339,
            47.52783,
            0
          ],
          [
            -116.79345,
            47.52778,
            0
          ],
          [
            -116.79362,
            47.5277,
            0
          ],
          [
            -116.79377,
            47.52767,
            0
          ],
          [
            -116.79388,
            47.52766,
            0
          ],
          [
            -116.7943,
            47.52767,
            0
          ],
          [
            -116.79437,
            47.52768,
            0
          ],
          [
            -116.79474,
            47.5277,
            0
          ],
          [
            -116.79563,
            47.52764,
            0
          ],
          [
            -116.79597,
            47.52764,
            0
          ],
          [
            -116.79812,
            47.52787,
            0
          ],
          [
            -116.79827,
            47.52787,
            0
          ],
          [
            -116.7984,
            47.52788,
            0
          ],
          [
            -116.79862,
            47.52787,
            0
          ],
          [
            -116.79894,
            47.52782,
            0
          ],
          [
            -116.79908,
            47.52778,
            0
          ],
          [
            -116.79923,
            47.52772,
            0
          ],
          [
            -116.79944,
            47.5276,
            0
          ],
          [
            -116.7996,
            47.52747,
            0
          ],
          [
            -116.79963,
            47.52744,
            0
          ],
          [
            -116.79975,
            47.52726,
            0
          ],
          [
            -116.7998,
            47.52714,
            0
          ],
          [
            -116.79982,
            47.52696,
            0
          ],
          [
            -116.79981,
            47.52678,
            0
          ],
          [
            -116.79978,
            47.5266,
            0
          ],
          [
            -116.79977,
            47.5266,
            0
          ],
          [
            -116.79977,
            47.52657,
            0
          ],
          [
            -116.7997,
            47.52618,
            0
          ],
          [
            -116.7997,
            47.52604,
            0
          ],
          [
            -116.79979,
            47.52552,
            0
          ],
          [
            -116.7998,
            47.52549,
            0
          ],
          [
            -116.79981,
            47.52536,
            0
          ],
          [
            -116.79986,
            47.52518,
            0
          ],
          [
            -116.79986,
            47.52517,
            0
          ],
          [
            -116.79994,
            47.52499,
            0
          ],
          [
            -116.79995,
            47.52499,
            0
          ],
          [
            -116.80006,
            47.52483,
            0
          ],
          [
            -116.80009,
            47.5248,
            0
          ],
          [
            -116.80017,
            47.52469,
            0
          ],
          [
            -116.80041,
            47.5244,
            0
          ],
          [
            -116.80043,
            47.52435,
            0
          ],
          [
            -116.80045,
            47.52433,
            0
          ],
          [
            -116.80049,
            47.52425,
            0
          ],
          [
            -116.80051,
            47.52418,
            0
          ],
          [
            -116.80052,
            47.52417,
            0
          ],
          [
            -116.80054,
            47.5241,
            0
          ],
          [
            -116.80055,
            47.52401,
            0
          ],
          [
            -116.80055,
            47.52381,
            0
          ],
          [
            -116.80053,
            47.52344,
            0
          ],
          [
            -116.80054,
            47.52319,
            0
          ],
          [
            -116.80055,
            47.52313,
            0
          ],
          [
            -116.80058,
            47.52308,
            0
          ],
          [
            -116.80063,
            47.52302,
            0
          ],
          [
            -116.80069,
            47.52297,
            0
          ],
          [
            -116.80074,
            47.52294,
            0
          ],
          [
            -116.80075,
            47.52294,
            0
          ],
          [
            -116.80091,
            47.52289,
            0
          ],
          [
            -116.80107,
            47.52287,
            0
          ],
          [
            -116.80223,
            47.52286,
            0
          ],
          [
            -116.80236,
            47.52285,
            0
          ],
          [
            -116.8025,
            47.52285,
            0
          ],
          [
            -116.80272,
            47.52281,
            0
          ],
          [
            -116.80274,
            47.52281,
            0
          ],
          [
            -116.80294,
            47.52273,
            0
          ],
          [
            -116.80296,
            47.52271,
            0
          ],
          [
            -116.80298,
            47.5227,
            0
          ],
          [
            -116.80308,
            47.52262,
            0
          ],
          [
            -116.80314,
            47.52254,
            0
          ],
          [
            -116.80321,
            47.5224,
            0
          ],
          [
            -116.80325,
            47.52229,
            0
          ],
          [
            -116.80328,
            47.52223,
            0
          ],
          [
            -116.8033,
            47.52221,
            0
          ],
          [
            -116.80333,
            47.52216,
            0
          ],
          [
            -116.80337,
            47.52212,
            0
          ],
          [
            -116.80342,
            47.52208,
            0
          ],
          [
            -116.8035,
            47.52203,
            0
          ],
          [
            -116.80351,
            47.52203,
            0
          ],
          [
            -116.80358,
            47.522,
            0
          ],
          [
            -116.80368,
            47.52197,
            0
          ],
          [
            -116.80392,
            47.52194,
            0
          ],
          [
            -116.8047,
            47.52194,
            0
          ],
          [
            -116.80514,
            47.52192,
            0
          ],
          [
            -116.80546,
            47.52187,
            0
          ],
          [
            -116.80549,
            47.52187,
            0
          ],
          [
            -116.80555,
            47.52185,
            0
          ],
          [
            -116.80564,
            47.52183,
            0
          ],
          [
            -116.80573,
            47.5218,
            0
          ],
          [
            -116.80576,
            47.5218,
            0
          ],
          [
            -116.80619,
            47.52166,
            0
          ],
          [
            -116.80654,
            47.5216,
            0
          ],
          [
            -116.80698,
            47.52157,
            0
          ],
          [
            -116.8072,
            47.52158,
            0
          ],
          [
            -116.80758,
            47.52156,
            0
          ],
          [
            -116.80801,
            47.52151,
            0
          ],
          [
            -116.80811,
            47.52148,
            0
          ],
          [
            -116.80824,
            47.52143,
            0
          ],
          [
            -116.80841,
            47.52134,
            0
          ],
          [
            -116.80847,
            47.5213,
            0
          ],
          [
            -116.80854,
            47.52124,
            0
          ],
          [
            -116.80864,
            47.52114,
            0
          ],
          [
            -116.80886,
            47.52089,
            0
          ],
          [
            -116.80916,
            47.52059,
            0
          ],
          [
            -116.80919,
            47.52057,
            0
          ],
          [
            -116.80928,
            47.52047,
            0
          ],
          [
            -116.8093,
            47.52043,
            0
          ],
          [
            -116.80934,
            47.52039,
            0
          ],
          [
            -116.80946,
            47.52019,
            0
          ],
          [
            -116.80985,
            47.51902,
            0
          ],
          [
            -116.8099,
            47.51884,
            0
          ],
          [
            -116.80996,
            47.51867,
            0
          ],
          [
            -116.80996,
            47.51865,
            0
          ],
          [
            -116.81,
            47.51851,
            0
          ],
          [
            -116.81002,
            47.5184,
            0
          ],
          [
            -116.81002,
            47.51831,
            0
          ],
          [
            -116.81003,
            47.51824,
            0
          ],
          [
            -116.81001,
            47.51813,
            0
          ],
          [
            -116.81001,
            47.51811,
            0
          ],
          [
            -116.80997,
            47.51796,
            0
          ],
          [
            -116.80996,
            47.51795,
            0
          ],
          [
            -116.80989,
            47.51763,
            0
          ],
          [
            -116.80989,
            47.51752,
            0
          ],
          [
            -116.8099,
            47.51742,
            0
          ],
          [
            -116.80991,
            47.5174,
            0
          ],
          [
            -116.80996,
            47.51725,
            0
          ],
          [
            -116.80997,
            47.51724,
            0
          ],
          [
            -116.81002,
            47.51714,
            0
          ],
          [
            -116.81034,
            47.51667,
            0
          ],
          [
            -116.81038,
            47.51659,
            0
          ],
          [
            -116.81039,
            47.51656,
            0
          ],
          [
            -116.81043,
            47.51648,
            0
          ],
          [
            -116.81045,
            47.51642,
            0
          ],
          [
            -116.81046,
            47.51635,
            0
          ],
          [
            -116.81045,
            47.51626,
            0
          ],
          [
            -116.81042,
            47.51618,
            0
          ],
          [
            -116.81038,
            47.51612,
            0
          ],
          [
            -116.8103,
            47.51604,
            0
          ],
          [
            -116.81008,
            47.51587,
            0
          ],
          [
            -116.80979,
            47.51569,
            0
          ],
          [
            -116.80978,
            47.51568,
            0
          ],
          [
            -116.8097,
            47.51563,
            0
          ],
          [
            -116.80944,
            47.51541,
            0
          ],
          [
            -116.80942,
            47.51538,
            0
          ],
          [
            -116.80928,
            47.51527,
            0
          ],
          [
            -116.80928,
            47.51526,
            0
          ],
          [
            -116.80911,
            47.51512,
            0
          ],
          [
            -116.80906,
            47.51509,
            0
          ],
          [
            -116.80886,
            47.51489,
            0
          ],
          [
            -116.80883,
            47.51484,
            0
          ],
          [
            -116.80879,
            47.51479,
            0
          ],
          [
            -116.80877,
            47.51471,
            0
          ],
          [
            -116.80875,
            47.51466,
            0
          ],
          [
            -116.80875,
            47.51459,
            0
          ],
          [
            -116.80876,
            47.51453,
            0
          ],
          [
            -116.80876,
            47.5145,
            0
          ],
          [
            -116.80878,
            47.51444,
            0
          ],
          [
            -116.80887,
            47.5143,
            0
          ],
          [
            -116.8089,
            47.51428,
            0
          ],
          [
            -116.80894,
            47.51424,
            0
          ],
          [
            -116.80902,
            47.51418,
            0
          ],
          [
            -116.80913,
            47.51412,
            0
          ],
          [
            -116.80923,
            47.51408,
            0
          ],
          [
            -116.80945,
            47.51402,
            0
          ],
          [
            -116.80962,
            47.514,
            0
          ],
          [
            -116.80981,
            47.514,
            0
          ],
          [
            -116.81067,
            47.51409,
            0
          ],
          [
            -116.81088,
            47.51409,
            0
          ],
          [
            -116.81095,
            47.51408,
            0
          ],
          [
            -116.81112,
            47.51407,
            0
          ],
          [
            -116.81122,
            47.51405,
            0
          ],
          [
            -116.81144,
            47.51399,
            0
          ],
          [
            -116.81162,
            47.51391,
            0
          ],
          [
            -116.81181,
            47.51378,
            0
          ],
          [
            -116.81198,
            47.51364,
            0
          ],
          [
            -116.81276,
            47.51292,
            0
          ],
          [
            -116.81277,
            47.51292,
            0
          ],
          [
            -116.81454,
            47.51129,
            0
          ],
          [
            -116.81476,
            47.51111,
            0
          ],
          [
            -116.81498,
            47.51097,
            0
          ],
          [
            -116.81506,
            47.51093,
            0
          ],
          [
            -116.8151,
            47.51092,
            0
          ],
          [
            -116.81533,
            47.51082,
            0
          ],
          [
            -116.81563,
            47.51073,
            0
          ],
          [
            -116.81604,
            47.51066,
            0
          ],
          [
            -116.81618,
            47.51065,
            0
          ],
          [
            -116.81657,
            47.51065,
            0
          ],
          [
            -116.81672,
            47.51066,
            0
          ],
          [
            -116.817,
            47.5107,
            0
          ],
          [
            -116.81709,
            47.51072,
            0
          ],
          [
            -116.81712,
            47.51072,
            0
          ],
          [
            -116.81732,
            47.51077,
            0
          ],
          [
            -116.81751,
            47.51083,
            0
          ],
          [
            -116.81783,
            47.5109,
            0
          ],
          [
            -116.81817,
            47.51095,
            0
          ],
          [
            -116.81825,
            47.51094,
            0
          ],
          [
            -116.81835,
            47.51094,
            0
          ],
          [
            -116.81876,
            47.51085,
            0
          ],
          [
            -116.81886,
            47.51081,
            0
          ],
          [
            -116.81906,
            47.51075,
            0
          ],
          [
            -116.81924,
            47.51068,
            0
          ],
          [
            -116.81958,
            47.51058,
            0
          ],
          [
            -116.81971,
            47.51055,
            0
          ],
          [
            -116.81973,
            47.51055,
            0
          ],
          [
            -116.81985,
            47.51052,
            0
          ],
          [
            -116.82021,
            47.51049,
            0
          ],
          [
            -116.82072,
            47.5105,
            0
          ],
          [
            -116.82078,
            47.51051,
            0
          ],
          [
            -116.82138,
            47.51051,
            0
          ],
          [
            -116.82168,
            47.51049,
            0
          ],
          [
            -116.822,
            47.51044,
            0
          ],
          [
            -116.82226,
            47.51038,
            0
          ],
          [
            -116.82249,
            47.51029,
            0
          ],
          [
            -116.82259,
            47.51024,
            0
          ],
          [
            -116.82279,
            47.51009,
            0
          ],
          [
            -116.82285,
            47.51002,
            0
          ],
          [
            -116.82291,
            47.50993,
            0
          ],
          [
            -116.82296,
            47.5098,
            0
          ],
          [
            -116.82297,
            47.50973,
            0
          ],
          [
            -116.82297,
            47.50962,
            0
          ],
          [
            -116.82295,
            47.50944,
            0
          ],
          [
            -116.8229,
            47.50926,
            0
          ],
          [
            -116.82267,
            47.50856,
            0
          ],
          [
            -116.82267,
            47.50855,
            0
          ],
          [
            -116.82255,
            47.50818,
            0
          ],
          [
            -116.82248,
            47.5079,
            0
          ],
          [
            -116.82244,
            47.50779,
            0
          ],
          [
            -116.82236,
            47.50764,
            0
          ],
          [
            -116.82231,
            47.50757,
            0
          ],
          [
            -116.82225,
            47.5075,
            0
          ],
          [
            -116.82205,
            47.50733,
            0
          ],
          [
            -116.82192,
            47.50724,
            0
          ],
          [
            -116.82172,
            47.50713,
            0
          ],
          [
            -116.82164,
            47.5071,
            0
          ],
          [
            -116.82069,
            47.50658,
            0
          ],
          [
            -116.82068,
            47.50657,
            0
          ],
          [
            -116.82051,
            47.50645,
            0
          ],
          [
            -116.82035,
            47.50629,
            0
          ],
          [
            -116.82026,
            47.50618,
            0
          ],
          [
            -116.82016,
            47.50604,
            0
          ],
          [
            -116.81996,
            47.5057,
            0
          ],
          [
            -116.81988,
            47.50554,
            0
          ],
          [
            -116.81898,
            47.50393,
            0
          ],
          [
            -116.81888,
            47.50377,
            0
          ],
          [
            -116.81866,
            47.50337,
            0
          ],
          [
            -116.81859,
            47.50326,
            0
          ],
          [
            -116.81858,
            47.50325,
            0
          ],
          [
            -116.81852,
            47.50315,
            0
          ],
          [
            -116.81846,
            47.50308,
            0
          ],
          [
            -116.81842,
            47.50302,
            0
          ],
          [
            -116.81825,
            47.50284,
            0
          ],
          [
            -116.81819,
            47.5028,
            0
          ],
          [
            -116.81814,
            47.50275,
            0
          ],
          [
            -116.81804,
            47.50268,
            0
          ],
          [
            -116.81802,
            47.50266,
            0
          ],
          [
            -116.81781,
            47.50253,
            0
          ],
          [
            -116.81748,
            47.50235,
            0
          ],
          [
            -116.81731,
            47.50227,
            0
          ],
          [
            -116.81721,
            47.5022,
            0
          ],
          [
            -116.8172,
            47.5022,
            0
          ],
          [
            -116.8171,
            47.50213,
            0
          ],
          [
            -116.81703,
            47.50207,
            0
          ],
          [
            -116.81703,
            47.50206,
            0
          ],
          [
            -116.81698,
            47.50202,
            0
          ],
          [
            -116.81689,
            47.5019,
            0
          ],
          [
            -116.81686,
            47.50184,
            0
          ],
          [
            -116.81682,
            47.5017,
            0
          ],
          [
            -116.81681,
            47.5016,
            0
          ],
          [
            -116.81682,
            47.50158,
            0
          ],
          [
            -116.81684,
            47.50144,
            0
          ],
          [
            -116.81687,
            47.50134,
            0
          ],
          [
            -116.81699,
            47.50107,
            0
          ],
          [
            -116.81707,
            47.50092,
            0
          ],
          [
            -116.81708,
            47.50088,
            0
          ],
          [
            -116.81712,
            47.50079,
            0
          ],
          [
            -116.81714,
            47.50071,
            0
          ],
          [
            -116.81715,
            47.50063,
            0
          ],
          [
            -116.81715,
            47.50049,
            0
          ],
          [
            -116.81713,
            47.50042,
            0
          ],
          [
            -116.81707,
            47.50029,
            0
          ],
          [
            -116.81692,
            47.50011,
            0
          ],
          [
            -116.81677,
            47.49998,
            0
          ],
          [
            -116.81654,
            47.49974,
            0
          ],
          [
            -116.81643,
            47.49958,
            0
          ],
          [
            -116.81633,
            47.49931,
            0
          ],
          [
            -116.81626,
            47.49882,
            0
          ],
          [
            -116.81625,
            47.49865,
            0
          ],
          [
            -116.8162,
            47.4983,
            0
          ],
          [
            -116.81619,
            47.49813,
            0
          ],
          [
            -116.81616,
            47.49799,
            0
          ],
          [
            -116.81616,
            47.49798,
            0
          ],
          [
            -116.81614,
            47.49788,
            0
          ],
          [
            -116.81606,
            47.49766,
            0
          ],
          [
            -116.81591,
            47.49743,
            0
          ],
          [
            -116.8157,
            47.4972,
            0
          ],
          [
            -116.815,
            47.49652,
            0
          ],
          [
            -116.81476,
            47.49638,
            0
          ],
          [
            -116.81465,
            47.49633,
            0
          ],
          [
            -116.81451,
            47.49629,
            0
          ],
          [
            -116.81434,
            47.49626,
            0
          ],
          [
            -116.81366,
            47.4962,
            0
          ],
          [
            -116.81341,
            47.49612,
            0
          ],
          [
            -116.81321,
            47.49598,
            0
          ],
          [
            -116.81311,
            47.49585,
            0
          ],
          [
            -116.81309,
            47.49576,
            0
          ],
          [
            -116.81309,
            47.49568,
            0
          ],
          [
            -116.8131,
            47.49563,
            0
          ],
          [
            -116.81316,
            47.49545,
            0
          ],
          [
            -116.81316,
            47.49543,
            0
          ],
          [
            -116.81384,
            47.49458,
            0
          ],
          [
            -116.81392,
            47.4945,
            0
          ],
          [
            -116.81408,
            47.49436,
            0
          ],
          [
            -116.81427,
            47.49423,
            0
          ],
          [
            -116.81473,
            47.49396,
            0
          ],
          [
            -116.81488,
            47.49389,
            0
          ],
          [
            -116.81493,
            47.49386,
            0
          ],
          [
            -116.81568,
            47.49358,
            0
          ],
          [
            -116.81585,
            47.49347,
            0
          ],
          [
            -116.81595,
            47.49337,
            0
          ],
          [
            -116.81602,
            47.49331,
            0
          ],
          [
            -116.81616,
            47.49311,
            0
          ],
          [
            -116.81629,
            47.49286,
            0
          ],
          [
            -116.81675,
            47.4921,
            0
          ],
          [
            -116.81679,
            47.49205,
            0
          ],
          [
            -116.81712,
            47.49156,
            0
          ],
          [
            -116.81717,
            47.4915,
            0
          ],
          [
            -116.81723,
            47.4914,
            0
          ],
          [
            -116.81779,
            47.49058,
            0
          ],
          [
            -116.81783,
            47.49053,
            0
          ],
          [
            -116.81797,
            47.49031,
            0
          ],
          [
            -116.81804,
            47.49016,
            0
          ],
          [
            -116.81807,
            47.49008,
            0
          ],
          [
            -116.81808,
            47.49003,
            0
          ],
          [
            -116.81812,
            47.48991,
            0
          ],
          [
            -116.81814,
            47.48976,
            0
          ],
          [
            -116.81814,
            47.48918,
            0
          ],
          [
            -116.81811,
            47.48861,
            0
          ],
          [
            -116.81805,
            47.4884,
            0
          ],
          [
            -116.81798,
            47.4883,
            0
          ],
          [
            -116.81796,
            47.48826,
            0
          ],
          [
            -116.81785,
            47.48814,
            0
          ],
          [
            -116.81777,
            47.48808,
            0
          ],
          [
            -116.81766,
            47.48802,
            0
          ],
          [
            -116.81752,
            47.48793,
            0
          ],
          [
            -116.81671,
            47.48761,
            0
          ],
          [
            -116.81623,
            47.48736,
            0
          ],
          [
            -116.81432,
            47.48615,
            0
          ],
          [
            -116.81404,
            47.48595,
            0
          ],
          [
            -116.81399,
            47.48592,
            0
          ],
          [
            -116.81328,
            47.48535,
            0
          ],
          [
            -116.81266,
            47.48495,
            0
          ],
          [
            -116.81217,
            47.48468,
            0
          ],
          [
            -116.8119,
            47.48455,
            0
          ],
          [
            -116.80978,
            47.48369,
            0
          ],
          [
            -116.80869,
            47.48316,
            0
          ],
          [
            -116.80831,
            47.48296,
            0
          ],
          [
            -116.80807,
            47.48282,
            0
          ],
          [
            -116.80777,
            47.4826,
            0
          ],
          [
            -116.80755,
            47.48242,
            0
          ],
          [
            -116.80709,
            47.48193,
            0
          ],
          [
            -116.80683,
            47.48173,
            0
          ],
          [
            -116.80627,
            47.48135,
            0
          ],
          [
            -116.80621,
            47.4813,
            0
          ],
          [
            -116.80608,
            47.48122,
            0
          ],
          [
            -116.80497,
            47.48045,
            0
          ],
          [
            -116.8047,
            47.48021,
            0
          ],
          [
            -116.80436,
            47.47986,
            0
          ],
          [
            -116.80433,
            47.47982,
            0
          ],
          [
            -116.8042,
            47.47968,
            0
          ],
          [
            -116.8039,
            47.47943,
            0
          ],
          [
            -116.80352,
            47.47916,
            0
          ],
          [
            -116.80324,
            47.47894,
            0
          ],
          [
            -116.80302,
            47.47871,
            0
          ],
          [
            -116.80296,
            47.4786,
            0
          ],
          [
            -116.80288,
            47.47841,
            0
          ],
          [
            -116.80279,
            47.47815,
            0
          ],
          [
            -116.80269,
            47.47802,
            0
          ],
          [
            -116.80257,
            47.4779,
            0
          ],
          [
            -116.80219,
            47.4777,
            0
          ],
          [
            -116.80197,
            47.47763,
            0
          ],
          [
            -116.80161,
            47.47755,
            0
          ],
          [
            -116.80091,
            47.47744,
            0
          ],
          [
            -116.80087,
            47.47744,
            0
          ],
          [
            -116.80022,
            47.47735,
            0
          ],
          [
            -116.79988,
            47.47734,
            0
          ],
          [
            -116.79953,
            47.47735,
            0
          ],
          [
            -116.79929,
            47.47737,
            0
          ],
          [
            -116.79918,
            47.47737,
            0
          ],
          [
            -116.79744,
            47.47752,
            0
          ],
          [
            -116.7972,
            47.47757,
            0
          ],
          [
            -116.79719,
            47.47758,
            0
          ],
          [
            -116.79704,
            47.47762,
            0
          ],
          [
            -116.79675,
            47.47776,
            0
          ],
          [
            -116.79648,
            47.47793,
            0
          ],
          [
            -116.7958,
            47.47841,
            0
          ],
          [
            -116.79578,
            47.47842,
            0
          ],
          [
            -116.79519,
            47.47885,
            0
          ],
          [
            -116.79487,
            47.47918,
            0
          ],
          [
            -116.79482,
            47.47924,
            0
          ],
          [
            -116.79475,
            47.47929,
            0
          ],
          [
            -116.79464,
            47.47935,
            0
          ],
          [
            -116.79449,
            47.47939,
            0
          ],
          [
            -116.79427,
            47.47939,
            0
          ],
          [
            -116.79407,
            47.47935,
            0
          ],
          [
            -116.794,
            47.47932,
            0
          ],
          [
            -116.79396,
            47.47929,
            0
          ],
          [
            -116.79392,
            47.47927,
            0
          ],
          [
            -116.79387,
            47.47921,
            0
          ],
          [
            -116.79385,
            47.47917,
            0
          ],
          [
            -116.79384,
            47.47913,
            0
          ],
          [
            -116.79384,
            47.47902,
            0
          ],
          [
            -116.79391,
            47.47887,
            0
          ],
          [
            -116.79398,
            47.4788,
            0
          ],
          [
            -116.79402,
            47.47878,
            0
          ],
          [
            -116.7941,
            47.47872,
            0
          ],
          [
            -116.79449,
            47.4785,
            0
          ],
          [
            -116.79492,
            47.4782,
            0
          ],
          [
            -116.79542,
            47.47775,
            0
          ],
          [
            -116.7956,
            47.47757,
            0
          ],
          [
            -116.79573,
            47.47741,
            0
          ],
          [
            -116.79573,
            47.4774,
            0
          ],
          [
            -116.79577,
            47.47733,
            0
          ],
          [
            -116.79582,
            47.47722,
            0
          ],
          [
            -116.79585,
            47.47705,
            0
          ],
          [
            -116.79584,
            47.47688,
            0
          ],
          [
            -116.79581,
            47.47675,
            0
          ],
          [
            -116.79579,
            47.47671,
            0
          ],
          [
            -116.79576,
            47.47662,
            0
          ],
          [
            -116.79571,
            47.47654,
            0
          ],
          [
            -116.79571,
            47.47653,
            0
          ],
          [
            -116.7956,
            47.47639,
            0
          ],
          [
            -116.79551,
            47.4763,
            0
          ],
          [
            -116.79526,
            47.47609,
            0
          ],
          [
            -116.79508,
            47.47596,
            0
          ],
          [
            -116.79504,
            47.47594,
            0
          ],
          [
            -116.79468,
            47.47569,
            0
          ],
          [
            -116.79409,
            47.47533,
            0
          ],
          [
            -116.79332,
            47.47498,
            0
          ],
          [
            -116.79266,
            47.47474,
            0
          ],
          [
            -116.79238,
            47.47466,
            0
          ],
          [
            -116.79237,
            47.47466,
            0
          ],
          [
            -116.79211,
            47.47459,
            0
          ],
          [
            -116.7916,
            47.47448,
            0
          ],
          [
            -116.78947,
            47.47422,
            0
          ],
          [
            -116.78914,
            47.47421,
            0
          ],
          [
            -116.78856,
            47.47428,
            0
          ],
          [
            -116.78777,
            47.47444,
            0
          ],
          [
            -116.78736,
            47.47454,
            0
          ],
          [
            -116.78631,
            47.47476,
            0
          ],
          [
            -116.78586,
            47.47487,
            0
          ],
          [
            -116.78519,
            47.475,
            0
          ],
          [
            -116.78483,
            47.47505,
            0
          ],
          [
            -116.78451,
            47.47507,
            0
          ],
          [
            -116.78409,
            47.47507,
            0
          ],
          [
            -116.78062,
            47.4749,
            0
          ],
          [
            -116.77983,
            47.47489,
            0
          ],
          [
            -116.77956,
            47.47491,
            0
          ],
          [
            -116.77924,
            47.47495,
            0
          ],
          [
            -116.77857,
            47.4751,
            0
          ],
          [
            -116.77804,
            47.47525,
            0
          ],
          [
            -116.77763,
            47.47532,
            0
          ],
          [
            -116.77728,
            47.47535,
            0
          ],
          [
            -116.77705,
            47.47535,
            0
          ],
          [
            -116.77641,
            47.4753,
            0
          ],
          [
            -116.77605,
            47.4753,
            0
          ],
          [
            -116.77577,
            47.47534,
            0
          ],
          [
            -116.77536,
            47.47546,
            0
          ],
          [
            -116.77523,
            47.47553,
            0
          ],
          [
            -116.77508,
            47.47559,
            0
          ],
          [
            -116.77406,
            47.47611,
            0
          ],
          [
            -116.77384,
            47.47618,
            0
          ],
          [
            -116.77361,
            47.47622,
            0
          ],
          [
            -116.77343,
            47.47623,
            0
          ],
          [
            -116.77322,
            47.47621,
            0
          ],
          [
            -116.77301,
            47.47616,
            0
          ],
          [
            -116.77274,
            47.47606,
            0
          ],
          [
            -116.77247,
            47.47592,
            0
          ],
          [
            -116.76965,
            47.47428,
            0
          ],
          [
            -116.76951,
            47.47419,
            0
          ],
          [
            -116.76927,
            47.47406,
            0
          ],
          [
            -116.76925,
            47.47404,
            0
          ],
          [
            -116.76899,
            47.47387,
            0
          ],
          [
            -116.76859,
            47.47352,
            0
          ],
          [
            -116.76857,
            47.47349,
            0
          ],
          [
            -116.76843,
            47.47334,
            0
          ],
          [
            -116.76843,
            47.47333,
            0
          ],
          [
            -116.76834,
            47.47319,
            0
          ],
          [
            -116.76832,
            47.47317,
            0
          ],
          [
            -116.76821,
            47.47298,
            0
          ],
          [
            -116.76806,
            47.47266,
            0
          ],
          [
            -116.76806,
            47.47265,
            0
          ],
          [
            -116.76788,
            47.47214,
            0
          ],
          [
            -116.76772,
            47.47161,
            0
          ],
          [
            -116.76771,
            47.47159,
            0
          ],
          [
            -116.76675,
            47.46855,
            0
          ],
          [
            -116.76597,
            47.46642,
            0
          ],
          [
            -116.76574,
            47.46561,
            0
          ],
          [
            -116.76548,
            47.4648,
            0
          ],
          [
            -116.76538,
            47.46454,
            0
          ],
          [
            -116.7653,
            47.46429,
            0
          ],
          [
            -116.76528,
            47.46419,
            0
          ],
          [
            -116.76518,
            47.46384,
            0
          ],
          [
            -116.76516,
            47.46369,
            0
          ],
          [
            -116.76515,
            47.46353,
            0
          ],
          [
            -116.76516,
            47.46348,
            0
          ],
          [
            -116.76516,
            47.46342,
            0
          ],
          [
            -116.76518,
            47.46329,
            0
          ],
          [
            -116.76522,
            47.46318,
            0
          ],
          [
            -116.76523,
            47.46312,
            0
          ],
          [
            -116.76526,
            47.46306,
            0
          ],
          [
            -116.76532,
            47.46291,
            0
          ],
          [
            -116.7654,
            47.46278,
            0
          ],
          [
            -116.76541,
            47.46277,
            0
          ],
          [
            -116.76547,
            47.46267,
            0
          ],
          [
            -116.76569,
            47.46242,
            0
          ],
          [
            -116.76587,
            47.46226,
            0
          ],
          [
            -116.76597,
            47.46219,
            0
          ],
          [
            -116.76598,
            47.46218,
            0
          ],
          [
            -116.76621,
            47.46203,
            0
          ],
          [
            -116.76668,
            47.46182,
            0
          ],
          [
            -116.76719,
            47.46167,
            0
          ],
          [
            -116.76741,
            47.46163,
            0
          ],
          [
            -116.76855,
            47.46152,
            0
          ],
          [
            -116.77299,
            47.46126,
            0
          ],
          [
            -116.77337,
            47.46122,
            0
          ],
          [
            -116.77346,
            47.4612,
            0
          ],
          [
            -116.77363,
            47.46118,
            0
          ],
          [
            -116.77365,
            47.46117,
            0
          ],
          [
            -116.77382,
            47.46114,
            0
          ],
          [
            -116.77423,
            47.46104,
            0
          ],
          [
            -116.77444,
            47.46097,
            0
          ],
          [
            -116.77481,
            47.46082,
            0
          ],
          [
            -116.77505,
            47.4607,
            0
          ],
          [
            -116.7756,
            47.46037,
            0
          ],
          [
            -116.77581,
            47.46023,
            0
          ],
          [
            -116.77603,
            47.4601,
            0
          ],
          [
            -116.77657,
            47.45982,
            0
          ],
          [
            -116.77953,
            47.45855,
            0
          ],
          [
            -116.77994,
            47.4584,
            0
          ],
          [
            -116.77995,
            47.45839,
            0
          ],
          [
            -116.78128,
            47.45791,
            0
          ],
          [
            -116.78383,
            47.45693,
            0
          ],
          [
            -116.7841,
            47.45679,
            0
          ],
          [
            -116.7841,
            47.45678,
            0
          ],
          [
            -116.78415,
            47.45675,
            0
          ],
          [
            -116.78426,
            47.45664,
            0
          ],
          [
            -116.78427,
            47.45662,
            0
          ],
          [
            -116.78433,
            47.45654,
            0
          ],
          [
            -116.78438,
            47.45645,
            0
          ],
          [
            -116.78458,
            47.45614,
            0
          ],
          [
            -116.78482,
            47.45585,
            0
          ],
          [
            -116.78494,
            47.45574,
            0
          ],
          [
            -116.78499,
            47.45568,
            0
          ],
          [
            -116.78505,
            47.45563,
            0
          ],
          [
            -116.78535,
            47.45533,
            0
          ],
          [
            -116.78543,
            47.45523,
            0
          ],
          [
            -116.78546,
            47.45521,
            0
          ],
          [
            -116.78564,
            47.45498,
            0
          ],
          [
            -116.78581,
            47.45471,
            0
          ],
          [
            -116.78598,
            47.45441,
            0
          ],
          [
            -116.78605,
            47.45425,
            0
          ],
          [
            -116.78564,
            47.45422,
            0
          ],
          [
            -116.78545,
            47.45422,
            0
          ]
        ],
			},
			id: "86cd5e29-e6eb-4279-b976-7e5546c42965",
		},
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.7812225,
    //       47.6734632,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Coeur d'Alene, ID"
    //   },
    //   id: "a27b8a97-6ef9-4e6b-9b9e-9a1182240062"
    // },
    // {
    //   type: "Feature",
    //   geometry: {
    //     type: "Point",
    //     coordinates: [
    //       -116.7854481,
    //       47.4542231,
    //       0
    //     ]
    //   },
    //   properties: {
    //     name: "Harrison, ID"
    //   },
    //   id: "5bfb29da-7a86-4702-a748-f303cc6d57d9"
    // }
	],
};

export default LakeCoeurDAlene;
