import { createReducer } from "@reduxjs/toolkit";
import { updatePopups } from "../actions/popupsActions";

const initialState = {
    display: true,
    type: 'login',
    data: '',
    tab: ''
}

const popupsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(updatePopups, (state, action) => {
            state.display = action.payload.display;
            state.type = action.payload.type;
            state.data = action.payload.data;
            state.tab = action.payload.tab;
        })
});

export default popupsReducer;