const WesternHeritage = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Western Heritage Historic Byway",
				description: "Once a route for miners and homesteaders, the Western Heritage Historic Byway passes through farmland and rocky terrain, giving visitors the opportunity to create their own unique frontier adventure. Dig into history at Celebration Park, Idaho's only archaeological park. Catch fish at Swan Falls Reservoir, or check out Morley Nelson Snake River Birds of Prey National Conservation Area - home to the greatest concentration of nesting raptors in North America.",
				link: "https://visitidaho.org/things-to-do/road-trips/western-heritage-historic-byway/",
				route: [
					"Meridian", 
					"Swan Falls Dam", 
					"Kuna"
				],
				shape: 'Line'
			},
			geometry: {
				type: "LineString",
				coordinates: [
					[-116.39372, 43.59754, 0],
					[-116.3938, 43.59649, 0],
					[-116.39382, 43.59615, 0],
					[-116.39384, 43.59565, 0],
					[-116.39384, 43.59456, 0],
					[-116.39383, 43.59407, 0],
					[-116.39383, 43.59382, 0],
					[-116.39384, 43.59358, 0],
					[-116.39384, 43.59345, 0],
					[-116.39385, 43.59335, 0],
					[-116.39385, 43.59302, 0],
					[-116.39386, 43.59263, 0],
					[-116.39387, 43.59245, 0],
					[-116.39385, 43.59209, 0],
					[-116.39386, 43.59196, 0],
					[-116.39387, 43.59176, 0],
					[-116.39387, 43.59149, 0],
					[-116.39388, 43.5913, 0],
					[-116.39388, 43.59086, 0],
					[-116.39389, 43.59041, 0],
					[-116.39389, 43.5901, 0],
					[-116.39377, 43.58997, 0],
					[-116.39377, 43.58968, 0],
					[-116.39376, 43.58928, 0],
					[-116.39376, 43.58764, 0],
					[-116.39377, 43.58729, 0],
					[-116.39377, 43.58672, 0],
					[-116.39376, 43.58645, 0],
					[-116.39377, 43.58602, 0],
					[-116.39378, 43.58227, 0],
					[-116.39379, 43.582, 0],
					[-116.39379, 43.57963, 0],
					[-116.3938, 43.57915, 0],
					[-116.3938, 43.57619, 0],
					[-116.39382, 43.57598, 0],
					[-116.39382, 43.57554, 0],
					[-116.39384, 43.57494, 0],
					[-116.39383, 43.5745, 0],
					[-116.39382, 43.57178, 0],
					[-116.39383, 43.57002, 0],
					[-116.39382, 43.5698, 0],
					[-116.39382, 43.5689, 0],
					[-116.39381, 43.56825, 0],
					[-116.39382, 43.56794, 0],
					[-116.39382, 43.56681, 0],
					[-116.39383, 43.56592, 0],
					[-116.39383, 43.56218, 0],
					[-116.39384, 43.56188, 0],
					[-116.39384, 43.55882, 0],
					[-116.39385, 43.55833, 0],
					[-116.39387, 43.55365, 0],
					[-116.39385, 43.55254, 0],
					[-116.39379, 43.54714, 0],
					[-116.39377, 43.54645, 0],
					[-116.39377, 43.54518, 0],
					[-116.39383, 43.53964, 0],
					[-116.39383, 43.53889, 0],
					[-116.3939, 43.53289, 0],
					[-116.39392, 43.53254, 0],
					[-116.39391, 43.52562, 0],
					[-116.3939, 43.5252, 0],
					[-116.39391, 43.52457, 0],
					[-116.3939, 43.52432, 0],
					[-116.3939, 43.52386, 0],
					[-116.39389, 43.52336, 0],
					[-116.39391, 43.52295, 0],
					[-116.39389, 43.5188, 0],
					[-116.3939, 43.51855, 0],
					[-116.3939, 43.51766, 0],
					[-116.39391, 43.51732, 0],
					[-116.3939, 43.50698, 0],
					[-116.39387, 43.50346, 0],
					[-116.39387, 43.50219, 0],
					[-116.3939, 43.50118, 0],
					[-116.39391, 43.49971, 0],
					[-116.39393, 43.49847, 0],
					[-116.39392, 43.49711, 0],
					[-116.39393, 43.49639, 0],
					[-116.39393, 43.4955, 0],
					[-116.39392, 43.4952, 0],
					[-116.39392, 43.495, 0],
					[-116.39391, 43.49482, 0],
					[-116.39391, 43.4933, 0],
					[-116.39388, 43.49088, 0],
					[-116.39388, 43.49048, 0],
					[-116.3939, 43.49035, 0],
					[-116.39391, 43.49026, 0],
					[-116.39392, 43.49022, 0],
					[-116.39393, 43.49015, 0],
					[-116.39397, 43.49, 0],
					[-116.39406, 43.48979, 0],
					[-116.3942, 43.48956, 0],
					[-116.39424, 43.4895, 0],
					[-116.39435, 43.48936, 0],
					[-116.39451, 43.4892, 0],
					[-116.39464, 43.48908, 0],
					[-116.39477, 43.48898, 0],
					[-116.39483, 43.48893, 0],
					[-116.39504, 43.4888, 0],
					[-116.39515, 43.48874, 0],
					[-116.39532, 43.48866, 0],
					[-116.39544, 43.48861, 0],
					[-116.3955, 43.48858, 0],
					[-116.39562, 43.48853, 0],
					[-116.39573, 43.4885, 0],
					[-116.39586, 43.48846, 0],
					[-116.39627, 43.48837, 0],
					[-116.39657, 43.48833, 0],
					[-116.39692, 43.48831, 0],
					[-116.39728, 43.48831, 0],
					[-116.39752, 43.48832, 0],
					[-116.39954, 43.48834, 0],
					[-116.40007, 43.48834, 0],
					[-116.4003, 43.48835, 0],
					[-116.40104, 43.48835, 0],
					[-116.40157, 43.48836, 0],
					[-116.40186, 43.48836, 0],
					[-116.40296, 43.48838, 0],
					[-116.40311, 43.48838, 0],
					[-116.40392, 43.4884, 0],
					[-116.40493, 43.4884, 0],
					[-116.406, 43.48841, 0],
					[-116.40627, 43.48842, 0],
					[-116.40888, 43.48845, 0],
					[-116.40996, 43.48845, 0],
					[-116.41246, 43.48848, 0],
					[-116.41262, 43.48849, 0],
					[-116.41271, 43.48849, 0],
					[-116.41279, 43.4885, 0],
					[-116.41295, 43.48853, 0],
					[-116.41311, 43.48858, 0],
					[-116.41319, 43.48862, 0],
					[-116.41328, 43.48866, 0],
					[-116.41338, 43.48872, 0],
					[-116.41347, 43.48878, 0],
					[-116.41361, 43.48865, 0],
					[-116.41367, 43.48859, 0],
					[-116.41373, 43.4885, 0],
					[-116.41375, 43.48846, 0],
					[-116.41377, 43.48826, 0],
					[-116.41378, 43.48803, 0],
					[-116.41378, 43.487, 0],
					[-116.41377, 43.48683, 0],
					[-116.41377, 43.48674, 0],
					[-116.41376, 43.48638, 0],
					[-116.41374, 43.48607, 0],
					[-116.41374, 43.48489, 0],
					[-116.41373, 43.48461, 0],
					[-116.41372, 43.48322, 0],
					[-116.41368, 43.48113, 0],
					[-116.41368, 43.48087, 0],
					[-116.41367, 43.48029, 0],
					[-116.41367, 43.47993, 0],
					[-116.41366, 43.47946, 0],
					[-116.41367, 43.479, 0],
					[-116.41367, 43.47711, 0],
					[-116.41368, 43.47658, 0],
					[-116.41368, 43.47585, 0],
					[-116.41369, 43.47556, 0],
					[-116.41369, 43.47541, 0],
					[-116.41368, 43.47531, 0],
					[-116.41368, 43.47466, 0],
					[-116.41366, 43.47386, 0],
					[-116.41365, 43.47304, 0],
					[-116.41365, 43.47025, 0],
					[-116.41367, 43.46937, 0],
					[-116.4137, 43.46712, 0],
					[-116.4137, 43.46651, 0],
					[-116.41373, 43.46359, 0],
					[-116.41378, 43.46177, 0],
					[-116.41379, 43.46159, 0],
					[-116.41383, 43.46051, 0],
					[-116.41384, 43.45997, 0],
					[-116.41387, 43.45913, 0],
					[-116.41387, 43.45895, 0],
					[-116.41388, 43.45864, 0],
					[-116.41388, 43.45643, 0],
					[-116.41387, 43.45608, 0],
					[-116.41388, 43.45499, 0],
					[-116.41388, 43.45039, 0],
					[-116.41387, 43.44888, 0],
					[-116.41388, 43.44767, 0],
					[-116.41387, 43.44739, 0],
					[-116.41387, 43.43709, 0],
					[-116.41388, 43.43629, 0],
					[-116.41388, 43.42981, 0],
					[-116.41389, 43.42921, 0],
					[-116.41388, 43.42814, 0],
					[-116.41388, 43.42371, 0],
					[-116.41389, 43.42357, 0],
					[-116.41388, 43.42347, 0],
					[-116.41388, 43.42188, 0],
					[-116.41389, 43.42152, 0],
					[-116.41389, 43.41351, 0],
					[-116.41388, 43.4133, 0],
					[-116.41388, 43.41326, 0],
					[-116.41389, 43.41307, 0],
					[-116.41388, 43.41194, 0],
					[-116.41388, 43.40765, 0],
					[-116.41389, 43.40725, 0],
					[-116.41389, 43.40697, 0],
					[-116.41388, 43.40651, 0],
					[-116.41388, 43.40567, 0],
					[-116.41389, 43.40541, 0],
					[-116.41389, 43.40056, 0],
					[-116.41388, 43.4004, 0],
					[-116.41388, 43.39985, 0],
					[-116.41387, 43.39954, 0],
					[-116.41388, 43.39904, 0],
					[-116.41387, 43.39866, 0],
					[-116.41387, 43.398, 0],
					[-116.41386, 43.39789, 0],
					[-116.41386, 43.39718, 0],
					[-116.41387, 43.39703, 0],
					[-116.41387, 43.39603, 0],
					[-116.41388, 43.39595, 0],
					[-116.41388, 43.39361, 0],
					[-116.41389, 43.39343, 0],
					[-116.41389, 43.39223, 0],
					[-116.41388, 43.39199, 0],
					[-116.41388, 43.38802, 0],
					[-116.41389, 43.38772, 0],
					[-116.41388, 43.38731, 0],
					[-116.41387, 43.38629, 0],
					[-116.41387, 43.38498, 0],
					[-116.41386, 43.38462, 0],
					[-116.41386, 43.38294, 0],
					[-116.41385, 43.38215, 0],
					[-116.41387, 43.3794, 0],
					[-116.41388, 43.37918, 0],
					[-116.41388, 43.37847, 0],
					[-116.41389, 43.37769, 0],
					[-116.41389, 43.37636, 0],
					[-116.4139, 43.37607, 0],
					[-116.4139, 43.37598, 0],
					[-116.41389, 43.37581, 0],
					[-116.4139, 43.37512, 0],
					[-116.4139, 43.36878, 0],
					[-116.41389, 43.36716, 0],
					[-116.41389, 43.36392, 0],
					[-116.41388, 43.36271, 0],
					[-116.41389, 43.36212, 0],
					[-116.41389, 43.36098, 0],
					[-116.4139, 43.36052, 0],
					[-116.4139, 43.35934, 0],
					[-116.41389, 43.35898, 0],
					[-116.41389, 43.35532, 0],
					[-116.41388, 43.35514, 0],
					[-116.41388, 43.35487, 0],
					[-116.41389, 43.35442, 0],
					[-116.41388, 43.35417, 0],
					[-116.41389, 43.35352, 0],
					[-116.41389, 43.35136, 0],
					[-116.4139, 43.35118, 0],
					[-116.4139, 43.3466, 0],
					[-116.41389, 43.34601, 0],
					[-116.41389, 43.34461, 0],
					[-116.41388, 43.34453, 0],
					[-116.41388, 43.34418, 0],
					[-116.41387, 43.34369, 0],
					[-116.41387, 43.34273, 0],
					[-116.41388, 43.34255, 0],
					[-116.41389, 43.34076, 0],
					[-116.41391, 43.33944, 0],
					[-116.41391, 43.33711, 0],
					[-116.4139, 43.33657, 0],
					[-116.4139, 43.33585, 0],
					[-116.41389, 43.33551, 0],
					[-116.4139, 43.33531, 0],
					[-116.4139, 43.33499, 0],
					[-116.41389, 43.3348, 0],
					[-116.4139, 43.33421, 0],
					[-116.4139, 43.33369, 0],
					[-116.41389, 43.33317, 0],
					[-116.41389, 43.33225, 0],
					[-116.4139, 43.33153, 0],
					[-116.41389, 43.32667, 0],
					[-116.41391, 43.32447, 0],
					[-116.41391, 43.32164, 0],
					[-116.41392, 43.3211, 0],
					[-116.41392, 43.32021, 0],
					[-116.41391, 43.31998, 0],
					[-116.41392, 43.31687, 0],
					[-116.4139, 43.31352, 0],
					[-116.41389, 43.31316, 0],
					[-116.41389, 43.30956, 0],
					[-116.41388, 43.30943, 0],
					[-116.41389, 43.30938, 0],
					[-116.41391, 43.30579, 0],
					[-116.41391, 43.30363, 0],
					[-116.41392, 43.30324, 0],
					[-116.41392, 43.30237, 0],
					[-116.41393, 43.30183, 0],
					[-116.41394, 43.30158, 0],
					[-116.41394, 43.30073, 0],
					[-116.41393, 43.30052, 0],
					[-116.41392, 43.30045, 0],
					[-116.4139, 43.3004, 0],
					[-116.4139, 43.30036, 0],
					[-116.41385, 43.30026, 0],
					[-116.41379, 43.30016, 0],
					[-116.41366, 43.29999, 0],
					[-116.4135, 43.2998, 0],
					[-116.41343, 43.29974, 0],
					[-116.41334, 43.29967, 0],
					[-116.41328, 43.29963, 0],
					[-116.41321, 43.29961, 0],
					[-116.41314, 43.29958, 0],
					[-116.41306, 43.29956, 0],
					[-116.41292, 43.29954, 0],
					[-116.41275, 43.29953, 0],
					[-116.40803, 43.2995, 0],
					[-116.40778, 43.29949, 0],
					[-116.4018, 43.29943, 0],
					[-116.40052, 43.29943, 0],
					[-116.40012, 43.29942, 0],
					[-116.39888, 43.29942, 0],
					[-116.39864, 43.29941, 0],
					[-116.39719, 43.29941, 0],
					[-116.39691, 43.2994, 0],
					[-116.39493, 43.29939, 0],
					[-116.39476, 43.29938, 0],
					[-116.39464, 43.29938, 0],
					[-116.3946, 43.29937, 0],
					[-116.39458, 43.29937, 0],
					[-116.39452, 43.29936, 0],
					[-116.39447, 43.29934, 0],
					[-116.39441, 43.29932, 0],
					[-116.3943, 43.29925, 0],
					[-116.39425, 43.2992, 0],
					[-116.3942, 43.29914, 0],
					[-116.39418, 43.2991, 0],
					[-116.39416, 43.29904, 0],
					[-116.39415, 43.29902, 0],
					[-116.39413, 43.29894, 0],
					[-116.39412, 43.29889, 0],
					[-116.39411, 43.29873, 0],
					[-116.39412, 43.29787, 0],
					[-116.39412, 43.29463, 0],
					[-116.39413, 43.29423, 0],
					[-116.39413, 43.29368, 0],
					[-116.39414, 43.29336, 0],
					[-116.39414, 43.29254, 0],
					[-116.39413, 43.29219, 0],
					[-116.39414, 43.292, 0],
					[-116.39415, 43.28678, 0],
					[-116.39416, 43.28624, 0],
					[-116.39416, 43.2848, 0],
					[-116.39417, 43.28444, 0],
					[-116.39417, 43.28373, 0],
					[-116.39419, 43.28211, 0],
					[-116.39419, 43.28103, 0],
					[-116.3942, 43.28085, 0],
					[-116.3942, 43.27977, 0],
					[-116.39421, 43.27941, 0],
					[-116.39422, 43.27833, 0],
					[-116.39422, 43.27708, 0],
					[-116.3942, 43.27697, 0],
					[-116.39417, 43.27686, 0],
					[-116.39414, 43.27678, 0],
					[-116.39403, 43.2766, 0],
					[-116.3938, 43.27632, 0],
					[-116.39272, 43.27505, 0],
					[-116.39267, 43.275, 0],
					[-116.39136, 43.27348, 0],
					[-116.39123, 43.27332, 0],
					[-116.38915, 43.27088, 0],
					[-116.38786, 43.26934, 0],
					[-116.38638, 43.2676, 0],
					[-116.38611, 43.26729, 0],
					[-116.38521, 43.26622, 0],
					[-116.38461, 43.26552, 0],
					[-116.38426, 43.26509, 0],
					[-116.38407, 43.26488, 0],
					[-116.38211, 43.26254, 0],
					[-116.38098, 43.26121, 0],
					[-116.38094, 43.26117, 0],
					[-116.38081, 43.26101, 0],
					[-116.37638, 43.25582, 0],
					[-116.37405, 43.25306, 0],
					[-116.37339, 43.2523, 0],
					[-116.37278, 43.25158, 0],
					[-116.37274, 43.25153, 0],
					[-116.37217, 43.25086, 0],
					[-116.37208, 43.25071, 0],
					[-116.37201, 43.2506, 0],
					[-116.37198, 43.25054, 0],
					[-116.3719, 43.25034, 0],
					[-116.37181, 43.25013, 0],
					[-116.37174, 43.24994, 0],
					[-116.37174, 43.24993, 0],
					[-116.37168, 43.24977, 0],
					[-116.37167, 43.24973, 0],
					[-116.37006, 43.24576, 0],
					[-116.36949, 43.24439, 0],
					[-116.36948, 43.24437, 0],
					[-116.36898, 43.24319, 0],
					[-116.36888, 43.24297, 0],
					[-116.36863, 43.24235, 0],
					[-116.36826, 43.24149, 0],
					[-116.36813, 43.2412, 0],
					[-116.36746, 43.23963, 0],
					[-116.367, 43.23851, 0],
					[-116.36695, 43.23837, 0],
					[-116.36695, 43.23828, 0],
					[-116.36697, 43.23818, 0],
					[-116.36702, 43.23808, 0],
					[-116.3671, 43.23799, 0],
					[-116.36722, 43.23792, 0],
					[-116.36738, 43.23786, 0],
					[-116.3675, 43.23785, 0],
					[-116.36765, 43.23785, 0],
					[-116.36783, 43.23787, 0],
					[-116.368, 43.238, 0],
					[-116.36806, 43.23806, 0],
					[-116.36809, 43.23813, 0],
					[-116.36814, 43.23821, 0],
					[-116.36816, 43.23829, 0],
					[-116.36822, 43.23856, 0],
					[-116.36823, 43.23865, 0],
					[-116.36827, 43.23884, 0],
					[-116.36848, 43.2393, 0],
					[-116.36911, 43.24021, 0],
					[-116.36948, 43.2407, 0],
					[-116.36953, 43.24077, 0],
					[-116.36959, 43.24086, 0],
					[-116.36977, 43.24106, 0],
					[-116.37022, 43.24151, 0],
					[-116.3703, 43.2416, 0],
					[-116.37037, 43.24172, 0],
					[-116.3704, 43.24176, 0],
					[-116.37082, 43.24239, 0],
					[-116.37136, 43.2431, 0],
					[-116.37138, 43.24314, 0],
					[-116.37148, 43.2433, 0],
					[-116.37179, 43.24377, 0],
					[-116.37202, 43.24413, 0],
					[-116.37213, 43.24427, 0],
					[-116.3722, 43.24435, 0],
					[-116.3725, 43.24465, 0],
					[-116.37324, 43.24525, 0],
					[-116.37354, 43.24552, 0],
					[-116.3737, 43.24565, 0],
					[-116.374, 43.24592, 0],
					[-116.37416, 43.24621, 0],
					[-116.37417, 43.24627, 0],
					[-116.37418, 43.24629, 0],
					[-116.37423, 43.24649, 0],
					[-116.37423, 43.24663, 0],
					[-116.37424, 43.24673, 0],
					[-116.37428, 43.24699, 0],
					[-116.37435, 43.2473, 0],
					[-116.37443, 43.2475, 0],
					[-116.37453, 43.24767, 0],
					[-116.37521, 43.24843, 0],
					[-116.37527, 43.24848, 0],
					[-116.37535, 43.24858, 0],
					[-116.37563, 43.24887, 0],
					[-116.37591, 43.24917, 0],
					[-116.37661, 43.24989, 0],
					[-116.37671, 43.24994, 0],
					[-116.37683, 43.24999, 0],
					[-116.37695, 43.25, 0],
					[-116.37703, 43.25, 0],
					[-116.37718, 43.24998, 0],
					[-116.37727, 43.24996, 0],
					[-116.37732, 43.24993, 0],
					[-116.37733, 43.24992, 0],
					[-116.37741, 43.24987, 0],
					[-116.37745, 43.24982, 0],
					[-116.37749, 43.24976, 0],
					[-116.37752, 43.2497, 0],
					[-116.37751, 43.2496, 0],
					[-116.37747, 43.24946, 0],
					[-116.37746, 43.24945, 0],
					[-116.37681, 43.24868, 0],
					[-116.37666, 43.24851, 0],
					[-116.37651, 43.2483, 0],
					[-116.37641, 43.24815, 0],
					[-116.37634, 43.248, 0],
					[-116.37625, 43.24768, 0],
					[-116.37612, 43.24734, 0],
					[-116.37605, 43.24714, 0],
					[-116.37598, 43.247, 0],
					[-116.37598, 43.24699, 0],
					[-116.37595, 43.24688, 0],
					[-116.37594, 43.24682, 0],
					[-116.37593, 43.2468, 0],
					[-116.37592, 43.24662, 0],
					[-116.37596, 43.24647, 0],
					[-116.37597, 43.24642, 0],
					[-116.37603, 43.24629, 0],
					[-116.37609, 43.24618, 0],
					[-116.37639, 43.24583, 0],
					[-116.37641, 43.24581, 0],
					[-116.3766, 43.24552, 0],
					[-116.37661, 43.2455, 0],
					[-116.37671, 43.24533, 0],
					[-116.37688, 43.24501, 0],
					[-116.37704, 43.24466, 0],
					[-116.37719, 43.24402, 0],
					[-116.37728, 43.24402, 0],
					[-116.37732, 43.24403, 0],
					[-116.37743, 43.24402, 0],
					[-116.37754, 43.24402, 0],
					[-116.37776, 43.24397, 0],
				],
			},
			id: "cefcde91-b802-4bc5-9bb8-6b03f7a5be1b",
		},
		// {
		// 	type: "Feature",
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.39372, 43.59754, 0],
		// 	},
		// 	properties: {
		// 		name: "Meridian, Idaho",
		// 		shape: "Marker",
		// 		category: "default",
		// 	},
		// 	id: "eaf5918a-52bd-49d4-9b71-5356a5e6d2d3",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Marker",
		// 		name: "Swan Falls Dam, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.3777629, 43.2439749, 0],
		// 	},
		// 	id: "d1d6c2d5-130f-44c9-96e6-1edc7d6879e7",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Kuna, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.41338, 43.48872, 0],
		// 	},
		// 	id: "7a8f16b2-7ce7-4023-aac8-7dfa0a22b444",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Melba, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-116.529486, 43.371989],
		// 	},
		// 	id: "a0e34b1f-d087-4ef5-945f-6bb08598a6f9",
		// },
	],
};

export default WesternHeritage;
