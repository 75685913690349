import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { clearCart, removeFromCart } from "../../store/actions/cartActions";
import { removeFromTTCart } from "../../store/actions/travelTipCartActions";
import { updateItineraryStatus } from "../../store/actions/itineraryActions";
import { IconMinus, IconOpenBook } from "../../images/icons";

const Cart = (props) => {
  const cart = useSelector((state) => state.cart);
  const ttcart = useSelector((state) => state.ttcart);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState('cart');

  const cartItemsCount = cart.cartItems.length;
  const ttCartItemsCount = ttcart.cartItems.length;

  const handleRemoveItem = (itemId) => {
    dispatch(removeFromCart(itemId));
  };
  const handleRemoveTTItem = (itemId) => {
    dispatch(removeFromTTCart(itemId));
  };

  const handleResetCart = () => {
    dispatch(clearCart());
  };

  useEffect(() => {
    if(cartItemsCount > 0 || ttCartItemsCount > 0) {
      
      let cartItemIds = [];
      let ttCartItemIds = [];

      if(cartItemsCount > 0) {
        cartItemIds = cart.cartItems.map((item) => item.id);
      }
      if(ttCartItemsCount > 0) {
        ttCartItemIds = ttcart.cartItems.map((item) => item.id);
      }
      setSearchParams({ i: cartItemIds, t: ttCartItemIds });
    }
    dispatch(updateItineraryStatus(""));
  }, [cart.cartItems, ttcart.cartItems]);


  return (
    <div className="cart-wrapper">
      <a onClick={props.toggleCart} className="nav-link">
        <span>My Itinerary</span>
        <IconOpenBook />
        {cartItemsCount > 0 && (
          <span className="cart-count">{cartItemsCount}</span>
        )}
      </a>
      {props.showCart && (
        <div className="cart-dropdown" style={{ position: "absolute" }}>
          <div className="cart-tab-headers">
            <a 
              onClick={() => setActiveTab('cart')} 
              className={`btn btn-block-50 btn-white-shadow ${activeTab === 'cart' ? 'active' : ''}`}
            >
              My Trip
            </a>
            <a 
              onClick={() => setActiveTab('ttcart')} 
              className={`btn btn-block-50 btn-white-shadow ${activeTab === 'ttcart' ? 'active' : ''}`}
            >
              My Travel Tips
            </a>
         </div>
          <hr />
          {
            activeTab === 'cart' && (
              <ul className="cart-items">
                {cartItemsCount > 0 ? (
                  cart.cartItems.map((item) => (
                    <li key={item.id}>
                      <a
                        onClick={() => {
                          handleRemoveItem(item.id);
                        }}
                        className="cart-item-remove"
                      >
                        <IconMinus />
                      </a>
                      {item.title}
                    </li>
                  ))
                ) : (
                  <p>Your itinerary is empty. Explore the map and add points of interest to start building your itinerary.</p>
                )}
              </ul>
            )
          }
          {
            activeTab === 'ttcart' && (
              <ul className="cart-items">
                {ttCartItemsCount > 0 ? (
                  ttcart.cartItems.map((item) => (
                    <li key={item.id}>
                      <a
                        onClick={() => {
                          handleRemoveTTItem(item.id);
                        }}
                        className="cart-item-remove"
                      >
                        <IconMinus />
                      </a>
                      {item.title}
                    </li>
                  ))
                ) : (
                  <p>You currently have no saved travel tips.</p>
                )}
              </ul>
            )
          }
          <hr />
          <div className="cart-actions">
            <a onClick={handleResetCart} className="btn-clear">
              Clear All
            </a>
            <button
              onClick={props.showDashboard}
              className="btn btn-orange btn-review"
            >
              Review and Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;