import React from "react";

export default class IconOpenBook extends React.Component {
	render() {
		return (
			<>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="27.191"
					height="31.075"
					viewBox="0 0 27.191 31.075"
				>
					<path
						d="M3.884,0a2.262,2.262,0,0,0-.738.117,3.913,3.913,0,0,0-3.03,3.03A1.558,1.558,0,0,0,0,3.884V25.249a5.819,5.819,0,0,0,5.827,5.827H27.191V27.191H5.827a1.942,1.942,0,0,1,0-3.884H27.191V1.942A1.923,1.923,0,0,0,25.249,0H23.306V11.653L19.422,7.769l-3.884,3.884V0Z"
					/>
				</svg>
			</>
		);
	}
}
