import { createReducer } from "@reduxjs/toolkit";
import { updateRegions } from "../actions/regionsActions";

const initialState = {
    selectedRegions: []
}

const regionsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(updateRegions, (state, action) => {
            state.selectedRegions = action.payload;
        })
});

export default regionsReducer;