import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IconCheck, IconMinus } from "../../images/icons";

/*
To use:
store.dispatch(setLastItem({
    dynamicText: this is added to the static text
    message: message type (so we can manage static alert text in ONE location)
    icon: true/false
    timer: use a timer other than the default (optional)
    type: success/warning/error
})
*/

function Alert() {
    const lastItem = useSelector((state) => state.lastItem.lastItem);
    const [showAlert, setShowAlert] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const timerRef = useRef(null);

    const timer = lastItem.timer ? lastItem.timer : 3000;

    const alertMessages = {
        'addToCart': lastItem.dynamicText + ' has been added to My Itinerary',
        'removeFromCart': lastItem.dynamicText + ' has been removed from My Itinerary',
        'cartIsFull': 'Your itinerary is full (10 items). To add additional items, please remove an item from your itinerary.',
        'saveItinerary': 'Your itinerary ' + lastItem.dynamicText + ' has been saved',
        'updateItinerary': 'Your itinerary ' + lastItem.dynamicText + ' has been updated',
        'loadItinerary': 'Your itinerary ' + lastItem.dynamicText + ' has been loaded',
        'urlCopied': 'Your itinerary URL has been copied.'
    }

    useEffect(() => {
        if (firstLoad === true) {
            setFirstLoad(false);
            return;
        }
        clearTimeout(timerRef.current);
        setShowAlert(true);
        timerRef.current = setTimeout(() => {
            setShowAlert(false);
        }, timer);
    }, [lastItem]);

    return (
        <>
            {(showAlert && lastItem.message && alertMessages[lastItem.message]) &&
                <div className={(lastItem && lastItem.type) && lastItem.type + " alert-popup"}>
                    <span>
                        {lastItem && lastItem.icon ?
                            <IconCheck /> : <IconMinus />
                        }
                    </span>
                    <p>
                        {alertMessages[lastItem.message]}
                    </p>
                </div>
            }
        </>
    )
}

export default Alert;