const NorthwestPassage = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Northwest Passage Scenic Byway",
				description: "Known as Idaho's longest byway, Northwest Passage Scenic Byway follows the route Meriwether Lewis and William Clark took through north central Idaho in 1803. Traverse the winding Clearwater River Canyon and see the beautiful blue blooms of the Camas Prairie. Visit the numerous Nez Perce historical sites along this route to understand the importance of Indigenous people in this region.",
				link: "https://visitidaho.org/things-to-do/road-trips/northwest-passage-scenic-byway/",
				route: [
					"Lewiston", 
					"Spalding", 
					"Orofino",
          "Kamiah",
          "Kooskia",
          "Grangeville",
          "Syringa",
          "Lowell"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
        coordinates: [
          [
              -117.00192,
              46.4141
          ],
          [
              -117.00194,
              46.41438
          ],
          [
              -117.00194,
              46.41445
          ],
          [
              -117.00187,
              46.41455
          ],
          [
              -117.0018,
              46.41471
          ],
          [
              -117.00172,
              46.41485
          ],
          [
              -117.00168,
              46.4149
          ],
          [
              -117.00157,
              46.41499
          ],
          [
              -117.00141,
              46.41514
          ],
          [
              -117.0013,
              46.41523
          ],
          [
              -117.00122,
              46.41527
          ],
          [
              -117.00114,
              46.41533
          ],
          [
              -117.00085,
              46.4155
          ],
          [
              -117.00082,
              46.41551
          ],
          [
              -117.00073,
              46.41555
          ],
          [
              -117.00049,
              46.41568
          ],
          [
              -117.00045,
              46.41571
          ],
          [
              -117.00043,
              46.41572
          ],
          [
              -117.00038,
              46.41576
          ],
          [
              -117.00034,
              46.41581
          ],
          [
              -117.00032,
              46.41583
          ],
          [
              -117.00029,
              46.41587
          ],
          [
              -117.00021,
              46.41601
          ],
          [
              -117.0002,
              46.41604
          ],
          [
              -117.00017,
              46.4161
          ],
          [
              -117.00016,
              46.41614
          ],
          [
              -117.00016,
              46.41617
          ],
          [
              -117.00015,
              46.4162
          ],
          [
              -117.00013,
              46.41633
          ],
          [
              -117.00012,
              46.41637
          ],
          [
              -117.00011,
              46.41651
          ],
          [
              -117.00011,
              46.41705
          ],
          [
              -117.00013,
              46.41727
          ],
          [
              -117.00013,
              46.41741
          ],
          [
              -117.00014,
              46.41749
          ],
          [
              -117.00023,
              46.42156
          ],
          [
              -117.00022,
              46.42257
          ],
          [
              -117.00031,
              46.4246
          ],
          [
              -117.00031,
              46.42499
          ],
          [
              -117.00032,
              46.42519
          ],
          [
              -117.00032,
              46.42548
          ],
          [
              -117.0003,
              46.4256
          ],
          [
              -117.00026,
              46.42575
          ],
          [
              -117.00024,
              46.4258
          ],
          [
              -117.00023,
              46.42584
          ],
          [
              -117.00015,
              46.42602
          ],
          [
              -117.00011,
              46.42608
          ],
          [
              -117.00007,
              46.42613
          ],
          [
              -117.00005,
              46.42618
          ],
          [
              -116.99993,
              46.42633
          ],
          [
              -116.99982,
              46.42644
          ],
          [
              -116.99962,
              46.42662
          ],
          [
              -116.99951,
              46.4267
          ],
          [
              -116.99943,
              46.42675
          ],
          [
              -116.99916,
              46.42689
          ],
          [
              -116.99892,
              46.42704
          ],
          [
              -116.99797,
              46.42754
          ],
          [
              -116.99758,
              46.42776
          ],
          [
              -116.99717,
              46.42793
          ],
          [
              -116.99586,
              46.42862
          ],
          [
              -116.99576,
              46.42868
          ],
          [
              -116.99483,
              46.42916
          ],
          [
              -116.99482,
              46.42916
          ],
          [
              -116.99461,
              46.42927
          ],
          [
              -116.99418,
              46.42946
          ],
          [
              -116.99372,
              46.42964
          ],
          [
              -116.99366,
              46.42965
          ],
          [
              -116.99346,
              46.42972
          ],
          [
              -116.99292,
              46.42987
          ],
          [
              -116.99259,
              46.42995
          ],
          [
              -116.9922,
              46.43003
          ],
          [
              -116.99183,
              46.43009
          ],
          [
              -116.98431,
              46.43111
          ],
          [
              -116.98373,
              46.4312
          ],
          [
              -116.98355,
              46.43124
          ],
          [
              -116.98336,
              46.43127
          ],
          [
              -116.98298,
              46.43136
          ],
          [
              -116.98208,
              46.4316
          ],
          [
              -116.9812,
              46.4319
          ],
          [
              -116.98084,
              46.43204
          ],
          [
              -116.98068,
              46.43211
          ],
          [
              -116.97947,
              46.43258
          ],
          [
              -116.97873,
              46.43283
          ],
          [
              -116.97771,
              46.4331
          ],
          [
              -116.97694,
              46.43325
          ],
          [
              -116.96256,
              46.43537
          ],
          [
              -116.96197,
              46.43544
          ],
          [
              -116.96178,
              46.43547
          ],
          [
              -116.9616,
              46.43548
          ],
          [
              -116.96122,
              46.43552
          ],
          [
              -116.95962,
              46.43559
          ],
          [
              -116.95877,
              46.43559
          ],
          [
              -116.95749,
              46.43553
          ],
          [
              -116.95699,
              46.43549
          ],
          [
              -116.9559,
              46.43537
          ],
          [
              -116.95513,
              46.43525
          ],
          [
              -116.954,
              46.43503
          ],
          [
              -116.9526,
              46.43469
          ],
          [
              -116.95163,
              46.43438
          ],
          [
              -116.95111,
              46.4342
          ],
          [
              -116.95033,
              46.43389
          ],
          [
              -116.9491,
              46.43333
          ],
          [
              -116.94444,
              46.43092
          ],
          [
              -116.94327,
              46.43035
          ],
          [
              -116.94227,
              46.42992
          ],
          [
              -116.94127,
              46.42954
          ],
          [
              -116.93736,
              46.42815
          ],
          [
              -116.93664,
              46.42791
          ],
          [
              -116.93598,
              46.42772
          ],
          [
              -116.9358,
              46.42766
          ],
          [
              -116.93527,
              46.42752
          ],
          [
              -116.93398,
              46.42721
          ],
          [
              -116.9332,
              46.42705
          ],
          [
              -116.93187,
              46.42683
          ],
          [
              -116.93117,
              46.42674
          ],
          [
              -116.92638,
              46.42628
          ],
          [
              -116.92527,
              46.42621
          ],
          [
              -116.92451,
              46.42619
          ],
          [
              -116.92354,
              46.42623
          ],
          [
              -116.92256,
              46.42633
          ],
          [
              -116.92202,
              46.42641
          ],
          [
              -116.92123,
              46.42656
          ],
          [
              -116.92068,
              46.42669
          ],
          [
              -116.91977,
              46.42695
          ],
          [
              -116.91942,
              46.42707
          ],
          [
              -116.91872,
              46.42734
          ],
          [
              -116.91798,
              46.42768
          ],
          [
              -116.91747,
              46.42795
          ],
          [
              -116.91681,
              46.42835
          ],
          [
              -116.91629,
              46.42871
          ],
          [
              -116.91579,
              46.4291
          ],
          [
              -116.91559,
              46.42928
          ],
          [
              -116.91555,
              46.42933
          ],
          [
              -116.9154,
              46.42946
          ],
          [
              -116.91514,
              46.42972
          ],
          [
              -116.91507,
              46.42978
          ],
          [
              -116.91455,
              46.43032
          ],
          [
              -116.91253,
              46.43234
          ],
          [
              -116.91217,
              46.43268
          ],
          [
              -116.91184,
              46.43297
          ],
          [
              -116.91144,
              46.43329
          ],
          [
              -116.91091,
              46.43367
          ],
          [
              -116.91055,
              46.4339
          ],
          [
              -116.91031,
              46.43403
          ],
          [
              -116.91004,
              46.43419
          ],
          [
              -116.90937,
              46.43453
          ],
          [
              -116.90771,
              46.43526
          ],
          [
              -116.90737,
              46.43542
          ],
          [
              -116.90639,
              46.43584
          ],
          [
              -116.90625,
              46.43591
          ],
          [
              -116.89533,
              46.4407
          ],
          [
              -116.89435,
              46.44116
          ],
          [
              -116.89372,
              46.44149
          ],
          [
              -116.89326,
              46.44175
          ],
          [
              -116.88704,
              46.44548
          ],
          [
              -116.88643,
              46.44583
          ],
          [
              -116.88596,
              46.44607
          ],
          [
              -116.88504,
              46.44649
          ],
          [
              -116.88407,
              46.44686
          ],
          [
              -116.88319,
              46.44716
          ],
          [
              -116.88318,
              46.44716
          ],
          [
              -116.8828,
              46.44728
          ],
          [
              -116.88244,
              46.44738
          ],
          [
              -116.87801,
              46.44842
          ],
          [
              -116.87729,
              46.44856
          ],
          [
              -116.87636,
              46.44872
          ],
          [
              -116.87498,
              46.4489
          ],
          [
              -116.8738,
              46.44901
          ],
          [
              -116.87264,
              46.44907
          ],
          [
              -116.87243,
              46.44907
          ],
          [
              -116.87203,
              46.44909
          ],
          [
              -116.87086,
              46.44909
          ],
          [
              -116.87008,
              46.44907
          ],
          [
              -116.86967,
              46.44904
          ],
          [
              -116.85809,
              46.4485
          ],
          [
              -116.85796,
              46.4485
          ],
          [
              -116.85789,
              46.44849
          ],
          [
              -116.85753,
              46.44847
          ],
          [
              -116.85659,
              46.44838
          ],
          [
              -116.85636,
              46.44835
          ],
          [
              -116.85547,
              46.4482
          ],
          [
              -116.85443,
              46.44797
          ],
          [
              -116.85365,
              46.44775
          ],
          [
              -116.85274,
              46.44743
          ],
          [
              -116.85234,
              46.44726
          ],
          [
              -116.8518,
              46.44705
          ],
          [
              -116.85127,
              46.44682
          ],
          [
              -116.85028,
              46.44636
          ],
          [
              -116.85011,
              46.44631
          ],
          [
              -116.84992,
              46.4462
          ],
          [
              -116.84246,
              46.44238
          ],
          [
              -116.84221,
              46.44227
          ],
          [
              -116.84166,
              46.44206
          ],
          [
              -116.84104,
              46.44186
          ],
          [
              -116.84086,
              46.44181
          ],
          [
              -116.84009,
              46.44164
          ],
          [
              -116.83992,
              46.44162
          ],
          [
              -116.83975,
              46.44159
          ],
          [
              -116.8391,
              46.44151
          ],
          [
              -116.83872,
              46.44149
          ],
          [
              -116.83863,
              46.44148
          ],
          [
              -116.83776,
              46.44148
          ],
          [
              -116.83727,
              46.44152
          ],
          [
              -116.83718,
              46.44152
          ],
          [
              -116.83699,
              46.44154
          ],
          [
              -116.83622,
              46.44166
          ],
          [
              -116.83583,
              46.44174
          ],
          [
              -116.83536,
              46.44186
          ],
          [
              -116.83531,
              46.44188
          ],
          [
              -116.83461,
              46.4421
          ],
          [
              -116.83457,
              46.44212
          ],
          [
              -116.83422,
              46.44225
          ],
          [
              -116.83275,
              46.44294
          ],
          [
              -116.83162,
              46.4435
          ],
          [
              -116.83158,
              46.44351
          ],
          [
              -116.82783,
              46.44535
          ],
          [
              -116.82772,
              46.44538
          ],
          [
              -116.8277,
              46.44538
          ],
          [
              -116.82753,
              46.44546
          ],
          [
              -116.82722,
              46.44559
          ],
          [
              -116.82607,
              46.44598
          ],
          [
              -116.82528,
              46.44619
          ],
          [
              -116.82507,
              46.44623
          ],
          [
              -116.82484,
              46.44629
          ],
          [
              -116.82449,
              46.44636
          ],
          [
              -116.82441,
              46.44637
          ],
          [
              -116.82438,
              46.44638
          ],
          [
              -116.82372,
              46.44648
          ],
          [
              -116.8231,
              46.44655
          ],
          [
              -116.82289,
              46.44656
          ],
          [
              -116.82267,
              46.44658
          ],
          [
              -116.82204,
              46.44661
          ],
          [
              -116.82106,
              46.44661
          ],
          [
              -116.8207,
              46.44659
          ],
          [
              -116.82055,
              46.4466
          ],
          [
              -116.82033,
              46.44659
          ],
          [
              -116.81936,
              46.44649
          ],
          [
              -116.81915,
              46.44646
          ],
          [
              -116.8184,
              46.44631
          ],
          [
              -116.81821,
              46.44626
          ],
          [
              -116.81805,
              46.44623
          ],
          [
              -116.81786,
              46.44618
          ],
          [
              -116.81771,
              46.44615
          ],
          [
              -116.81681,
              46.44589
          ],
          [
              -116.81662,
              46.44582
          ],
          [
              -116.81643,
              46.44576
          ],
          [
              -116.81628,
              46.4457
          ],
          [
              -116.81613,
              46.44565
          ],
          [
              -116.81555,
              46.4454
          ],
          [
              -116.81544,
              46.44536
          ],
          [
              -116.81516,
              46.44522
          ],
          [
              -116.815,
              46.44515
          ],
          [
              -116.81413,
              46.44467
          ],
          [
              -116.81407,
              46.44474
          ],
          [
              -116.81407,
              46.4448
          ],
          [
              -116.81413,
              46.44488
          ],
          [
              -116.8145,
              46.4451
          ],
          [
              -116.81517,
              46.44544
          ],
          [
              -116.81591,
              46.44584
          ],
          [
              -116.81592,
              46.44584
          ],
          [
              -116.81614,
              46.44596
          ],
          [
              -116.81637,
              46.44613
          ],
          [
              -116.81646,
              46.44617
          ],
          [
              -116.81674,
              46.44637
          ],
          [
              -116.81696,
              46.44656
          ],
          [
              -116.81702,
              46.44662
          ],
          [
              -116.81722,
              46.44687
          ],
          [
              -116.81737,
              46.44715
          ],
          [
              -116.81764,
              46.44751
          ],
          [
              -116.81787,
              46.44775
          ],
          [
              -116.81802,
              46.44797
          ],
          [
              -116.81813,
              46.44817
          ],
          [
              -116.81814,
              46.44821
          ],
          [
              -116.81815,
              46.44821
          ],
          [
              -116.81819,
              46.44833
          ],
          [
              -116.81819,
              46.44837
          ],
          [
              -116.81817,
              46.44841
          ],
          [
              -116.81812,
              46.44845
          ],
          [
              -116.81807,
              46.44847
          ],
          [
              -116.81797,
              46.4485
          ],
          [
              -116.81783,
              46.44852
          ],
          [
              -116.81775,
              46.44854
          ],
          [
              -116.81768,
              46.44855
          ],
          [
              -116.8176,
              46.44858
          ],
          [
              -116.81752,
              46.4486
          ],
          [
              -116.81731,
              46.44871
          ],
          [
              -116.81695,
              46.44894
          ],
          [
              -116.81683,
              46.449
          ],
          [
              -116.81672,
              46.44907
          ],
          [
              -116.81613,
              46.44939
          ],
          [
              -116.81546,
              46.44964
          ],
          [
              -116.81487,
              46.44993
          ],
          [
              -116.81456,
              46.45061
          ],
          [
              -116.8141,
              46.45037
          ],
          [
              -116.81364,
              46.45018
          ],
          [
              -116.813,
              46.44996
          ],
          [
              -116.81275,
              46.4499
          ],
          [
              -116.81227,
              46.44976
          ],
          [
              -116.81225,
              46.44976
          ],
          [
              -116.81153,
              46.44958
          ],
          [
              -116.81129,
              46.44951
          ],
          [
              -116.81101,
              46.44944
          ],
          [
              -116.81075,
              46.44939
          ],
          [
              -116.81053,
              46.44937
          ],
          [
              -116.81019,
              46.44932
          ],
          [
              -116.80997,
              46.44931
          ],
          [
              -116.80943,
              46.44932
          ],
          [
              -116.80898,
              46.44937
          ],
          [
              -116.80847,
              46.44945
          ],
          [
              -116.80565,
              46.44983
          ],
          [
              -116.80361,
              46.45014
          ],
          [
              -116.79952,
              46.45065
          ],
          [
              -116.79821,
              46.45079
          ],
          [
              -116.79694,
              46.451
          ],
          [
              -116.79649,
              46.45106
          ],
          [
              -116.79593,
              46.45116
          ],
          [
              -116.79543,
              46.45128
          ],
          [
              -116.79495,
              46.45142
          ],
          [
              -116.7946,
              46.45154
          ],
          [
              -116.79411,
              46.45173
          ],
          [
              -116.79374,
              46.45189
          ],
          [
              -116.79189,
              46.45263
          ],
          [
              -116.79162,
              46.45275
          ],
          [
              -116.7909,
              46.45312
          ],
          [
              -116.78865,
              46.45442
          ],
          [
              -116.78666,
              46.45564
          ],
          [
              -116.7866,
              46.45569
          ],
          [
              -116.78451,
              46.45703
          ],
          [
              -116.78226,
              46.45866
          ],
          [
              -116.78177,
              46.45897
          ],
          [
              -116.78138,
              46.45924
          ],
          [
              -116.78101,
              46.45946
          ],
          [
              -116.78082,
              46.45959
          ],
          [
              -116.7807,
              46.45966
          ],
          [
              -116.78061,
              46.45974
          ],
          [
              -116.78048,
              46.45981
          ],
          [
              -116.78037,
              46.45988
          ],
          [
              -116.78028,
              46.45995
          ],
          [
              -116.78027,
              46.45997
          ],
          [
              -116.77982,
              46.46035
          ],
          [
              -116.77955,
              46.46062
          ],
          [
              -116.77934,
              46.4608
          ],
          [
              -116.77912,
              46.46101
          ],
          [
              -116.779,
              46.46111
          ],
          [
              -116.77895,
              46.46116
          ],
          [
              -116.77884,
              46.46125
          ],
          [
              -116.77868,
              46.4614
          ],
          [
              -116.77658,
              46.46319
          ],
          [
              -116.77628,
              46.46346
          ],
          [
              -116.77554,
              46.46408
          ],
          [
              -116.77539,
              46.46422
          ],
          [
              -116.77516,
              46.46446
          ],
          [
              -116.77483,
              46.4649
          ],
          [
              -116.77469,
              46.46513
          ],
          [
              -116.77457,
              46.46535
          ],
          [
              -116.77449,
              46.46553
          ],
          [
              -116.77428,
              46.4666
          ],
          [
              -116.77401,
              46.4678
          ],
          [
              -116.77391,
              46.46802
          ],
          [
              -116.77371,
              46.46831
          ],
          [
              -116.77356,
              46.46847
          ],
          [
              -116.77336,
              46.46863
          ],
          [
              -116.773,
              46.46888
          ],
          [
              -116.77299,
              46.46888
          ],
          [
              -116.77126,
              46.46997
          ],
          [
              -116.77125,
              46.46997
          ],
          [
              -116.7701,
              46.47069
          ],
          [
              -116.77009,
              46.47069
          ],
          [
              -116.76957,
              46.47102
          ],
          [
              -116.76942,
              46.47109
          ],
          [
              -116.76928,
              46.47113
          ],
          [
              -116.76926,
              46.47114
          ],
          [
              -116.76901,
              46.47116
          ],
          [
              -116.76824,
              46.47118
          ],
          [
              -116.76813,
              46.4712
          ],
          [
              -116.76786,
              46.47131
          ],
          [
              -116.76757,
              46.4715
          ],
          [
              -116.76747,
              46.47159
          ],
          [
              -116.76744,
              46.47161
          ],
          [
              -116.76743,
              46.47163
          ],
          [
              -116.76743,
              46.47164
          ],
          [
              -116.76742,
              46.47168
          ],
          [
              -116.76743,
              46.4718
          ],
          [
              -116.76713,
              46.47188
          ],
          [
              -116.76697,
              46.47193
          ],
          [
              -116.76694,
              46.47193
          ],
          [
              -116.76663,
              46.47204
          ],
          [
              -116.76653,
              46.47209
          ],
          [
              -116.76619,
              46.47223
          ],
          [
              -116.76466,
              46.47297
          ],
          [
              -116.76463,
              46.47298
          ],
          [
              -116.76442,
              46.47308
          ],
          [
              -116.76441,
              46.47308
          ],
          [
              -116.76415,
              46.47319
          ],
          [
              -116.76398,
              46.47325
          ],
          [
              -116.76396,
              46.47326
          ],
          [
              -116.76375,
              46.47334
          ],
          [
              -116.76363,
              46.47337
          ],
          [
              -116.76285,
              46.47361
          ],
          [
              -116.76253,
              46.47369
          ],
          [
              -116.76252,
              46.4737
          ],
          [
              -116.76034,
              46.47421
          ],
          [
              -116.76007,
              46.47426
          ],
          [
              -116.76005,
              46.47426
          ],
          [
              -116.75991,
              46.47429
          ],
          [
              -116.7598,
              46.4743
          ],
          [
              -116.75975,
              46.4743
          ],
          [
              -116.75971,
              46.47431
          ],
          [
              -116.75964,
              46.47432
          ],
          [
              -116.75956,
              46.47432
          ],
          [
              -116.75889,
              46.47436
          ],
          [
              -116.75881,
              46.47437
          ],
          [
              -116.75874,
              46.47437
          ],
          [
              -116.75863,
              46.47439
          ],
          [
              -116.75831,
              46.47443
          ],
          [
              -116.75821,
              46.47445
          ],
          [
              -116.75814,
              46.47447
          ],
          [
              -116.75807,
              46.47448
          ],
          [
              -116.75784,
              46.47454
          ],
          [
              -116.75768,
              46.47459
          ],
          [
              -116.75763,
              46.4746
          ],
          [
              -116.75743,
              46.47468
          ],
          [
              -116.75699,
              46.47489
          ],
          [
              -116.75604,
              46.47539
          ],
          [
              -116.75603,
              46.4754
          ],
          [
              -116.75562,
              46.47565
          ],
          [
              -116.75464,
              46.47633
          ],
          [
              -116.75335,
              46.47728
          ],
          [
              -116.75331,
              46.47732
          ],
          [
              -116.75329,
              46.47733
          ],
          [
              -116.75315,
              46.47747
          ],
          [
              -116.75311,
              46.47753
          ],
          [
              -116.75308,
              46.47756
          ],
          [
              -116.75304,
              46.47763
          ],
          [
              -116.753,
              46.47768
          ],
          [
              -116.75291,
              46.47781
          ],
          [
              -116.75283,
              46.47794
          ],
          [
              -116.75283,
              46.47796
          ],
          [
              -116.75255,
              46.47843
          ],
          [
              -116.75247,
              46.47861
          ],
          [
              -116.7524,
              46.4788
          ],
          [
              -116.75239,
              46.47882
          ],
          [
              -116.75233,
              46.47901
          ],
          [
              -116.75233,
              46.47907
          ],
          [
              -116.75232,
              46.47911
          ],
          [
              -116.75232,
              46.47914
          ],
          [
              -116.75231,
              46.47918
          ],
          [
              -116.75231,
              46.47923
          ],
          [
              -116.75232,
              46.4793
          ],
          [
              -116.75232,
              46.47937
          ],
          [
              -116.75236,
              46.47961
          ],
          [
              -116.75241,
              46.47979
          ],
          [
              -116.75244,
              46.47987
          ],
          [
              -116.75248,
              46.47995
          ],
          [
              -116.75259,
              46.48023
          ],
          [
              -116.75262,
              46.48029
          ],
          [
              -116.75271,
              46.48054
          ],
          [
              -116.75283,
              46.48081
          ],
          [
              -116.75286,
              46.4809
          ],
          [
              -116.75311,
              46.48147
          ],
          [
              -116.75323,
              46.48171
          ],
          [
              -116.75391,
              46.48296
          ],
          [
              -116.75408,
              46.4833
          ],
          [
              -116.75411,
              46.48338
          ],
          [
              -116.75417,
              46.4835
          ],
          [
              -116.75422,
              46.4837
          ],
          [
              -116.75424,
              46.48376
          ],
          [
              -116.75425,
              46.48383
          ],
          [
              -116.75425,
              46.48389
          ],
          [
              -116.75426,
              46.48395
          ],
          [
              -116.75426,
              46.48416
          ],
          [
              -116.75425,
              46.48421
          ],
          [
              -116.75425,
              46.48425
          ],
          [
              -116.7542,
              46.48447
          ],
          [
              -116.75418,
              46.48451
          ],
          [
              -116.75417,
              46.48457
          ],
          [
              -116.75413,
              46.48465
          ],
          [
              -116.7541,
              46.48473
          ],
          [
              -116.75399,
              46.48494
          ],
          [
              -116.75392,
              46.48504
          ],
          [
              -116.75373,
              46.48527
          ],
          [
              -116.75363,
              46.48537
          ],
          [
              -116.75358,
              46.48541
          ],
          [
              -116.75351,
              46.48548
          ],
          [
              -116.75332,
              46.48563
          ],
          [
              -116.75282,
              46.48592
          ],
          [
              -116.75271,
              46.48597
          ],
          [
              -116.75253,
              46.48607
          ],
          [
              -116.75242,
              46.48612
          ],
          [
              -116.75158,
              46.48658
          ],
          [
              -116.75091,
              46.48692
          ],
          [
              -116.75075,
              46.48699
          ],
          [
              -116.75064,
              46.48703
          ],
          [
              -116.75041,
              46.48713
          ],
          [
              -116.74998,
              46.48727
          ],
          [
              -116.74937,
              46.48741
          ],
          [
              -116.74874,
              46.48751
          ],
          [
              -116.74734,
              46.48767
          ],
          [
              -116.7472,
              46.48768
          ],
          [
              -116.7461,
              46.48781
          ],
          [
              -116.74554,
              46.48786
          ],
          [
              -116.7452,
              46.48788
          ],
          [
              -116.74511,
              46.48788
          ],
          [
              -116.74496,
              46.48789
          ],
          [
              -116.74484,
              46.48789
          ],
          [
              -116.74469,
              46.48788
          ],
          [
              -116.74451,
              46.48788
          ],
          [
              -116.74177,
              46.48769
          ],
          [
              -116.7417,
              46.48768
          ],
          [
              -116.73978,
              46.48757
          ],
          [
              -116.73909,
              46.48757
          ],
          [
              -116.73838,
              46.48762
          ],
          [
              -116.73766,
              46.48773
          ],
          [
              -116.73762,
              46.48773
          ],
          [
              -116.73737,
              46.48778
          ],
          [
              -116.73735,
              46.48779
          ],
          [
              -116.73721,
              46.48781
          ],
          [
              -116.73682,
              46.4879
          ],
          [
              -116.73351,
              46.48888
          ],
          [
              -116.72933,
              46.49036
          ],
          [
              -116.72931,
              46.49036
          ],
          [
              -116.72865,
              46.49063
          ],
          [
              -116.72849,
              46.49071
          ],
          [
              -116.72844,
              46.49074
          ],
          [
              -116.728,
              46.49096
          ],
          [
              -116.72638,
              46.49187
          ],
          [
              -116.72589,
              46.49218
          ],
          [
              -116.72582,
              46.49224
          ],
          [
              -116.72565,
              46.49236
          ],
          [
              -116.72558,
              46.49243
          ],
          [
              -116.72549,
              46.4925
          ],
          [
              -116.72528,
              46.49271
          ],
          [
              -116.72523,
              46.49277
          ],
          [
              -116.72517,
              46.49283
          ],
          [
              -116.7251,
              46.49292
          ],
          [
              -116.72501,
              46.49305
          ],
          [
              -116.72497,
              46.49312
          ],
          [
              -116.72492,
              46.49319
          ],
          [
              -116.72478,
              46.49347
          ],
          [
              -116.72473,
              46.4936
          ],
          [
              -116.72468,
              46.49377
          ],
          [
              -116.72465,
              46.4939
          ],
          [
              -116.72464,
              46.49397
          ],
          [
              -116.72463,
              46.494
          ],
          [
              -116.72463,
              46.49405
          ],
          [
              -116.72462,
              46.49413
          ],
          [
              -116.72462,
              46.49445
          ],
          [
              -116.72464,
              46.49461
          ],
          [
              -116.72466,
              46.4947
          ],
          [
              -116.72466,
              46.49471
          ],
          [
              -116.72471,
              46.49489
          ],
          [
              -116.72484,
              46.49522
          ],
          [
              -116.72485,
              46.49523
          ],
          [
              -116.72491,
              46.49536
          ],
          [
              -116.72526,
              46.49595
          ],
          [
              -116.72541,
              46.49623
          ],
          [
              -116.72541,
              46.49624
          ],
          [
              -116.72542,
              46.49626
          ],
          [
              -116.72546,
              46.49639
          ],
          [
              -116.72547,
              46.49641
          ],
          [
              -116.72549,
              46.49651
          ],
          [
              -116.72549,
              46.49655
          ],
          [
              -116.7255,
              46.49658
          ],
          [
              -116.72551,
              46.49673
          ],
          [
              -116.72551,
              46.49687
          ],
          [
              -116.7255,
              46.49694
          ],
          [
              -116.7255,
              46.49697
          ],
          [
              -116.72547,
              46.4971
          ],
          [
              -116.72547,
              46.49713
          ],
          [
              -116.72546,
              46.49715
          ],
          [
              -116.72545,
              46.49719
          ],
          [
              -116.72543,
              46.49724
          ],
          [
              -116.72542,
              46.49728
          ],
          [
              -116.7254,
              46.49731
          ],
          [
              -116.72539,
              46.49735
          ],
          [
              -116.72536,
              46.4974
          ],
          [
              -116.72533,
              46.49744
          ],
          [
              -116.72531,
              46.49748
          ],
          [
              -116.72523,
              46.49758
          ],
          [
              -116.72516,
              46.49765
          ],
          [
              -116.72494,
              46.49784
          ],
          [
              -116.7248,
              46.49794
          ],
          [
              -116.72205,
              46.49976
          ],
          [
              -116.72198,
              46.4998
          ],
          [
              -116.72187,
              46.49988
          ],
          [
              -116.72099,
              46.50046
          ],
          [
              -116.72098,
              46.50047
          ],
          [
              -116.72092,
              46.5005
          ],
          [
              -116.72082,
              46.50057
          ],
          [
              -116.72053,
              46.50074
          ],
          [
              -116.72026,
              46.50088
          ],
          [
              -116.72019,
              46.50091
          ],
          [
              -116.72007,
              46.50095
          ],
          [
              -116.72004,
              46.50097
          ],
          [
              -116.71982,
              46.50103
          ],
          [
              -116.71977,
              46.50105
          ],
          [
              -116.71974,
              46.50105
          ],
          [
              -116.71965,
              46.50107
          ],
          [
              -116.71958,
              46.50108
          ],
          [
              -116.71954,
              46.50109
          ],
          [
              -116.7195,
              46.50109
          ],
          [
              -116.71946,
              46.5011
          ],
          [
              -116.71938,
              46.5011
          ],
          [
              -116.71935,
              46.50111
          ],
          [
              -116.71911,
              46.50111
          ],
          [
              -116.71875,
              46.50107
          ],
          [
              -116.7187,
              46.50106
          ],
          [
              -116.71864,
              46.50104
          ],
          [
              -116.71858,
              46.50103
          ],
          [
              -116.71843,
              46.50099
          ],
          [
              -116.71808,
              46.50086
          ],
          [
              -116.71808,
              46.50085
          ],
          [
              -116.71804,
              46.50084
          ],
          [
              -116.71787,
              46.50074
          ],
          [
              -116.71786,
              46.50073
          ],
          [
              -116.71775,
              46.50066
          ],
          [
              -116.71768,
              46.50059
          ],
          [
              -116.71764,
              46.50056
          ],
          [
              -116.71759,
              46.5005
          ],
          [
              -116.71742,
              46.50033
          ],
          [
              -116.71741,
              46.50031
          ],
          [
              -116.71722,
              46.50016
          ],
          [
              -116.71712,
              46.50009
          ],
          [
              -116.71708,
              46.50007
          ],
          [
              -116.717,
              46.50002
          ],
          [
              -116.71695,
              46.5
          ],
          [
              -116.71686,
              46.49995
          ],
          [
              -116.71681,
              46.49993
          ],
          [
              -116.71674,
              46.49991
          ],
          [
              -116.7167,
              46.49989
          ],
          [
              -116.71666,
              46.49988
          ],
          [
              -116.71659,
              46.49985
          ],
          [
              -116.71628,
              46.49977
          ],
          [
              -116.71611,
              46.49974
          ],
          [
              -116.71609,
              46.49974
          ],
          [
              -116.716,
              46.49972
          ],
          [
              -116.71554,
              46.49967
          ],
          [
              -116.71536,
              46.49967
          ],
          [
              -116.71532,
              46.49966
          ],
          [
              -116.71502,
              46.49966
          ],
          [
              -116.71492,
              46.49967
          ],
          [
              -116.71482,
              46.49967
          ],
          [
              -116.71477,
              46.49968
          ],
          [
              -116.71467,
              46.49968
          ],
          [
              -116.71435,
              46.49972
          ],
          [
              -116.71396,
              46.4998
          ],
          [
              -116.71365,
              46.49988
          ],
          [
              -116.71311,
              46.50006
          ],
          [
              -116.71229,
              46.50031
          ],
          [
              -116.71195,
              46.50038
          ],
          [
              -116.71159,
              46.50043
          ],
          [
              -116.71156,
              46.50043
          ],
          [
              -116.71151,
              46.50044
          ],
          [
              -116.71141,
              46.50044
          ],
          [
              -116.71111,
              46.50046
          ],
          [
              -116.70733,
              46.50045
          ],
          [
              -116.7065,
              46.50047
          ],
          [
              -116.70595,
              46.5005
          ],
          [
              -116.70548,
              46.50055
          ],
          [
              -116.70537,
              46.50057
          ],
          [
              -116.70518,
              46.50059
          ],
          [
              -116.70499,
              46.50062
          ],
          [
              -116.7049,
              46.50064
          ],
          [
              -116.70437,
              46.50073
          ],
          [
              -116.70357,
              46.50091
          ],
          [
              -116.70354,
              46.50092
          ],
          [
              -116.70249,
              46.50116
          ],
          [
              -116.70019,
              46.50161
          ],
          [
              -116.69963,
              46.50174
          ],
          [
              -116.69942,
              46.5018
          ],
          [
              -116.69919,
              46.50188
          ],
          [
              -116.69904,
              46.50192
          ],
          [
              -116.69895,
              46.50196
          ],
          [
              -116.69886,
              46.50199
          ],
          [
              -116.69857,
              46.50211
          ],
          [
              -116.69815,
              46.50231
          ],
          [
              -116.69741,
              46.50274
          ],
          [
              -116.6974,
              46.50275
          ],
          [
              -116.69674,
              46.50314
          ],
          [
              -116.69641,
              46.50335
          ],
          [
              -116.696,
              46.50363
          ],
          [
              -116.69322,
              46.50564
          ],
          [
              -116.69237,
              46.5062
          ],
          [
              -116.69082,
              46.50717
          ],
          [
              -116.69054,
              46.50733
          ],
          [
              -116.68777,
              46.50903
          ],
          [
              -116.68719,
              46.50941
          ],
          [
              -116.68453,
              46.51123
          ],
          [
              -116.68405,
              46.51154
          ],
          [
              -116.68387,
              46.51167
          ],
          [
              -116.68329,
              46.51203
          ],
          [
              -116.68319,
              46.5121
          ],
          [
              -116.68285,
              46.51231
          ],
          [
              -116.68277,
              46.51235
          ],
          [
              -116.6825,
              46.51251
          ],
          [
              -116.68158,
              46.51296
          ],
          [
              -116.67844,
              46.51433
          ],
          [
              -116.67787,
              46.51456
          ],
          [
              -116.67749,
              46.5147
          ],
          [
              -116.6774,
              46.51472
          ],
          [
              -116.67724,
              46.51478
          ],
          [
              -116.67714,
              46.5148
          ],
          [
              -116.67704,
              46.51484
          ],
          [
              -116.67694,
              46.51486
          ],
          [
              -116.67638,
              46.51502
          ],
          [
              -116.67584,
              46.51514
          ],
          [
              -116.67574,
              46.51515
          ],
          [
              -116.67494,
              46.51528
          ],
          [
              -116.67441,
              46.51533
          ],
          [
              -116.67375,
              46.51537
          ],
          [
              -116.6663,
              46.51535
          ],
          [
              -116.66606,
              46.51536
          ],
          [
              -116.66527,
              46.51536
          ],
          [
              -116.66516,
              46.51537
          ],
          [
              -116.66475,
              46.51538
          ],
          [
              -116.66453,
              46.5154
          ],
          [
              -116.66427,
              46.51541
          ],
          [
              -116.66416,
              46.51542
          ],
          [
              -116.66353,
              46.51542
          ],
          [
              -116.66256,
              46.51535
          ],
          [
              -116.6614,
              46.51521
          ],
          [
              -116.6581,
              46.51476
          ],
          [
              -116.65732,
              46.51467
          ],
          [
              -116.65727,
              46.51466
          ],
          [
              -116.65259,
              46.51422
          ],
          [
              -116.65223,
              46.5142
          ],
          [
              -116.65214,
              46.51419
          ],
          [
              -116.65165,
              46.51419
          ],
          [
              -116.65155,
              46.5142
          ],
          [
              -116.65145,
              46.5142
          ],
          [
              -116.65126,
              46.51422
          ],
          [
              -116.65121,
              46.51423
          ],
          [
              -116.65105,
              46.51425
          ],
          [
              -116.65102,
              46.51425
          ],
          [
              -116.64988,
              46.5144
          ],
          [
              -116.64939,
              46.51448
          ],
          [
              -116.64893,
              46.51457
          ],
          [
              -116.64875,
              46.51462
          ],
          [
              -116.64857,
              46.51466
          ],
          [
              -116.6482,
              46.51477
          ],
          [
              -116.64809,
              46.51481
          ],
          [
              -116.64802,
              46.51483
          ],
          [
              -116.64747,
              46.51504
          ],
          [
              -116.64699,
              46.51526
          ],
          [
              -116.64601,
              46.51576
          ],
          [
              -116.64574,
              46.51592
          ],
          [
              -116.64503,
              46.5164
          ],
          [
              -116.64489,
              46.51651
          ],
          [
              -116.64485,
              46.51656
          ],
          [
              -116.64479,
              46.51661
          ],
          [
              -116.64476,
              46.51663
          ],
          [
              -116.64449,
              46.5169
          ],
          [
              -116.64443,
              46.51695
          ],
          [
              -116.64338,
              46.51795
          ],
          [
              -116.64307,
              46.51828
          ],
          [
              -116.64296,
              46.51842
          ],
          [
              -116.64289,
              46.51853
          ],
          [
              -116.64282,
              46.51862
          ],
          [
              -116.64275,
              46.51873
          ],
          [
              -116.6427,
              46.51882
          ],
          [
              -116.64254,
              46.51921
          ],
          [
              -116.64251,
              46.51933
          ],
          [
              -116.64245,
              46.51947
          ],
          [
              -116.64242,
              46.51957
          ],
          [
              -116.64235,
              46.51974
          ],
          [
              -116.64215,
              46.52011
          ],
          [
              -116.64196,
              46.52041
          ],
          [
              -116.64191,
              46.5205
          ],
          [
              -116.64169,
              46.52079
          ],
          [
              -116.64119,
              46.52132
          ],
          [
              -116.64106,
              46.52147
          ],
          [
              -116.64088,
              46.52163
          ],
          [
              -116.6408,
              46.52171
          ],
          [
              -116.64069,
              46.52179
          ],
          [
              -116.64066,
              46.52182
          ],
          [
              -116.64059,
              46.52187
          ],
          [
              -116.64055,
              46.52189
          ],
          [
              -116.64054,
              46.5219
          ],
          [
              -116.64044,
              46.52197
          ],
          [
              -116.64037,
              46.52201
          ],
          [
              -116.64034,
              46.52202
          ],
          [
              -116.6403,
              46.52204
          ],
          [
              -116.64017,
              46.52212
          ],
          [
              -116.63921,
              46.52257
          ],
          [
              -116.63777,
              46.5233
          ],
          [
              -116.63653,
              46.52397
          ],
          [
              -116.6365,
              46.52399
          ],
          [
              -116.63464,
              46.52501
          ],
          [
              -116.63427,
              46.5252
          ],
          [
              -116.6339,
              46.52536
          ],
          [
              -116.63379,
              46.5254
          ],
          [
              -116.6336,
              46.52545
          ],
          [
              -116.63359,
              46.52546
          ],
          [
              -116.63353,
              46.52547
          ],
          [
              -116.63347,
              46.52549
          ],
          [
              -116.63342,
              46.5255
          ],
          [
              -116.63337,
              46.52552
          ],
          [
              -116.63315,
              46.52556
          ],
          [
              -116.63309,
              46.52558
          ],
          [
              -116.63283,
              46.52562
          ],
          [
              -116.63275,
              46.52562
          ],
          [
              -116.63261,
              46.52564
          ],
          [
              -116.63235,
              46.52566
          ],
          [
              -116.63188,
              46.52566
          ],
          [
              -116.63178,
              46.52565
          ],
          [
              -116.6317,
              46.52565
          ],
          [
              -116.63155,
              46.52563
          ],
          [
              -116.63148,
              46.52563
          ],
          [
              -116.63139,
              46.52561
          ],
          [
              -116.62925,
              46.52529
          ],
          [
              -116.62909,
              46.52526
          ],
          [
              -116.62773,
              46.52506
          ],
          [
              -116.62757,
              46.52503
          ],
          [
              -116.62642,
              46.52487
          ],
          [
              -116.6262,
              46.52483
          ],
          [
              -116.62613,
              46.52481
          ],
          [
              -116.62604,
              46.5248
          ],
          [
              -116.62598,
              46.52478
          ],
          [
              -116.62595,
              46.52478
          ],
          [
              -116.6259,
              46.52476
          ],
          [
              -116.62584,
              46.52475
          ],
          [
              -116.62576,
              46.52473
          ],
          [
              -116.62564,
              46.52469
          ],
          [
              -116.62559,
              46.52468
          ],
          [
              -116.62532,
              46.52458
          ],
          [
              -116.62528,
              46.52457
          ],
          [
              -116.62525,
              46.52455
          ],
          [
              -116.62516,
              46.52451
          ],
          [
              -116.62513,
              46.52449
          ],
          [
              -116.62497,
              46.52442
          ],
          [
              -116.62488,
              46.52436
          ],
          [
              -116.62477,
              46.5243
          ],
          [
              -116.62413,
              46.5239
          ],
          [
              -116.62399,
              46.5238
          ],
          [
              -116.62387,
              46.52373
          ],
          [
              -116.62386,
              46.52372
          ],
          [
              -116.62373,
              46.52365
          ],
          [
              -116.6236,
              46.52357
          ],
          [
              -116.62312,
              46.52335
          ],
          [
              -116.62292,
              46.52327
          ],
          [
              -116.62287,
              46.52326
          ],
          [
              -116.62278,
              46.52323
          ],
          [
              -116.62266,
              46.5232
          ],
          [
              -116.62237,
              46.52311
          ],
          [
              -116.62214,
              46.52306
          ],
          [
              -116.62206,
              46.52305
          ],
          [
              -116.62197,
              46.52303
          ],
          [
              -116.62154,
              46.52296
          ],
          [
              -116.62153,
              46.52296
          ],
          [
              -116.61888,
              46.52256
          ],
          [
              -116.61272,
              46.52188
          ],
          [
              -116.61254,
              46.52185
          ],
          [
              -116.61242,
              46.52184
          ],
          [
              -116.61172,
              46.52172
          ],
          [
              -116.61065,
              46.52149
          ],
          [
              -116.60984,
              46.52127
          ],
          [
              -116.60973,
              46.52123
          ],
          [
              -116.60957,
              46.52119
          ],
          [
              -116.6095,
              46.52116
          ],
          [
              -116.60929,
              46.5211
          ],
          [
              -116.60536,
              46.51965
          ],
          [
              -116.60489,
              46.51951
          ],
          [
              -116.60354,
              46.51919
          ],
          [
              -116.6023,
              46.51887
          ],
          [
              -116.60222,
              46.51884
          ],
          [
              -116.60152,
              46.51862
          ],
          [
              -116.6005,
              46.51827
          ],
          [
              -116.60044,
              46.51824
          ],
          [
              -116.60028,
              46.51818
          ],
          [
              -116.60004,
              46.51806
          ],
          [
              -116.59996,
              46.51803
          ],
          [
              -116.59992,
              46.518
          ],
          [
              -116.59974,
              46.51791
          ],
          [
              -116.59803,
              46.51692
          ],
          [
              -116.59802,
              46.51692
          ],
          [
              -116.59791,
              46.51685
          ],
          [
              -116.59758,
              46.51669
          ],
          [
              -116.59753,
              46.51666
          ],
          [
              -116.59743,
              46.51662
          ],
          [
              -116.5972,
              46.51651
          ],
          [
              -116.59633,
              46.51613
          ],
          [
              -116.59559,
              46.51577
          ],
          [
              -116.59552,
              46.51573
          ],
          [
              -116.59468,
              46.51541
          ],
          [
              -116.59402,
              46.51521
          ],
          [
              -116.59392,
              46.51519
          ],
          [
              -116.59382,
              46.51516
          ],
          [
              -116.59327,
              46.51506
          ],
          [
              -116.59308,
              46.51504
          ],
          [
              -116.59292,
              46.51501
          ],
          [
              -116.59277,
              46.515
          ],
          [
              -116.59266,
              46.51498
          ],
          [
              -116.59199,
              46.51492
          ],
          [
              -116.5914,
              46.51489
          ],
          [
              -116.5913,
              46.51489
          ],
          [
              -116.59121,
              46.51488
          ],
          [
              -116.5911,
              46.51488
          ],
          [
              -116.5909,
              46.51486
          ],
          [
              -116.59055,
              46.51481
          ],
          [
              -116.59004,
              46.51471
          ],
          [
              -116.58994,
              46.51468
          ],
          [
              -116.58926,
              46.51451
          ],
          [
              -116.58917,
              46.51448
          ],
          [
              -116.58657,
              46.51381
          ],
          [
              -116.58645,
              46.51377
          ],
          [
              -116.58617,
              46.5137
          ],
          [
              -116.58611,
              46.51369
          ],
          [
              -116.58512,
              46.51338
          ],
          [
              -116.58389,
              46.51293
          ],
          [
              -116.58314,
              46.51263
          ],
          [
              -116.58223,
              46.51218
          ],
          [
              -116.58172,
              46.51188
          ],
          [
              -116.58095,
              46.51134
          ],
          [
              -116.58057,
              46.51105
          ],
          [
              -116.58033,
              46.51084
          ],
          [
              -116.58029,
              46.5108
          ],
          [
              -116.58025,
              46.51077
          ],
          [
              -116.58016,
              46.51068
          ],
          [
              -116.58012,
              46.51063
          ],
          [
              -116.58007,
              46.51058
          ],
          [
              -116.58001,
              46.5105
          ],
          [
              -116.57998,
              46.51047
          ],
          [
              -116.57996,
              46.51044
          ],
          [
              -116.57993,
              46.51041
          ],
          [
              -116.57991,
              46.51037
          ],
          [
              -116.57984,
              46.51027
          ],
          [
              -116.57983,
              46.51024
          ],
          [
              -116.57981,
              46.51022
          ],
          [
              -116.5798,
              46.51019
          ],
          [
              -116.57978,
              46.51017
          ],
          [
              -116.57969,
              46.50998
          ],
          [
              -116.57967,
              46.50995
          ],
          [
              -116.57966,
              46.50991
          ],
          [
              -116.57961,
              46.50977
          ],
          [
              -116.57959,
              46.50969
          ],
          [
              -116.57955,
              46.50958
          ],
          [
              -116.57953,
              46.50948
          ],
          [
              -116.57951,
              46.50944
          ],
          [
              -116.5795,
              46.5094
          ],
          [
              -116.57946,
              46.50932
          ],
          [
              -116.57945,
              46.50928
          ],
          [
              -116.57942,
              46.50924
          ],
          [
              -116.57939,
              46.50918
          ],
          [
              -116.57937,
              46.50913
          ],
          [
              -116.57915,
              46.5088
          ],
          [
              -116.57913,
              46.50878
          ],
          [
              -116.57912,
              46.50876
          ],
          [
              -116.57906,
              46.5087
          ],
          [
              -116.57902,
              46.50865
          ],
          [
              -116.57806,
              46.50767
          ],
          [
              -116.57798,
              46.50761
          ],
          [
              -116.57791,
              46.50755
          ],
          [
              -116.57766,
              46.50736
          ],
          [
              -116.57744,
              46.50722
          ],
          [
              -116.57704,
              46.50702
          ],
          [
              -116.57669,
              46.50688
          ],
          [
              -116.57631,
              46.50677
          ],
          [
              -116.57478,
              46.50642
          ],
          [
              -116.57422,
              46.50631
          ],
          [
              -116.57334,
              46.50616
          ],
          [
              -116.57231,
              46.50601
          ],
          [
              -116.57135,
              46.5059
          ],
          [
              -116.56985,
              46.50579
          ],
          [
              -116.56919,
              46.50576
          ],
          [
              -116.56893,
              46.50576
          ],
          [
              -116.56833,
              46.50574
          ],
          [
              -116.56748,
              46.50574
          ],
          [
              -116.56737,
              46.50575
          ],
          [
              -116.56721,
              46.50575
          ],
          [
              -116.56676,
              46.5058
          ],
          [
              -116.56633,
              46.50588
          ],
          [
              -116.56594,
              46.50598
          ],
          [
              -116.56569,
              46.50607
          ],
          [
              -116.56535,
              46.50621
          ],
          [
              -116.56527,
              46.50626
          ],
          [
              -116.56519,
              46.50629
          ],
          [
              -116.5649,
              46.50645
          ],
          [
              -116.56438,
              46.50685
          ],
          [
              -116.56418,
              46.50707
          ],
          [
              -116.56401,
              46.50728
          ],
          [
              -116.56351,
              46.508
          ],
          [
              -116.56348,
              46.50806
          ],
          [
              -116.56343,
              46.50813
          ],
          [
              -116.56338,
              46.50819
          ],
          [
              -116.56319,
              46.50846
          ],
          [
              -116.56314,
              46.50852
          ],
          [
              -116.56267,
              46.50915
          ],
          [
              -116.56254,
              46.5093
          ],
          [
              -116.56248,
              46.50936
          ],
          [
              -116.56243,
              46.50942
          ],
          [
              -116.56238,
              46.50946
          ],
          [
              -116.56232,
              46.50952
          ],
          [
              -116.56207,
              46.50972
          ],
          [
              -116.56187,
              46.50985
          ],
          [
              -116.56137,
              46.5101
          ],
          [
              -116.56087,
              46.51027
          ],
          [
              -116.56039,
              46.51039
          ],
          [
              -116.55993,
              46.51046
          ],
          [
              -116.55963,
              46.51048
          ],
          [
              -116.55921,
              46.51048
          ],
          [
              -116.55902,
              46.51046
          ],
          [
              -116.55889,
              46.51046
          ],
          [
              -116.55865,
              46.51044
          ],
          [
              -116.55835,
              46.51039
          ],
          [
              -116.55818,
              46.51037
          ],
          [
              -116.55813,
              46.51036
          ],
          [
              -116.55811,
              46.51035
          ],
          [
              -116.55797,
              46.51032
          ],
          [
              -116.55783,
              46.51028
          ],
          [
              -116.5576,
              46.5102
          ],
          [
              -116.55719,
              46.51003
          ],
          [
              -116.55696,
              46.50991
          ],
          [
              -116.55692,
              46.50988
          ],
          [
              -116.55687,
              46.50986
          ],
          [
              -116.5568,
              46.50981
          ],
          [
              -116.55647,
              46.50963
          ],
          [
              -116.55591,
              46.50929
          ],
          [
              -116.55531,
              46.50889
          ],
          [
              -116.55473,
              46.50847
          ],
          [
              -116.55463,
              46.50841
          ],
          [
              -116.55446,
              46.50828
          ],
          [
              -116.55426,
              46.50811
          ],
          [
              -116.55394,
              46.50778
          ],
          [
              -116.55377,
              46.50759
          ],
          [
              -116.5533,
              46.50702
          ],
          [
              -116.55313,
              46.50685
          ],
          [
              -116.55303,
              46.50676
          ],
          [
              -116.55278,
              46.50656
          ],
          [
              -116.55266,
              46.50648
          ],
          [
              -116.55257,
              46.50641
          ],
          [
              -116.55234,
              46.50627
          ],
          [
              -116.5522,
              46.5062
          ],
          [
              -116.5521,
              46.50614
          ],
          [
              -116.55174,
              46.50597
          ],
          [
              -116.55129,
              46.50579
          ],
          [
              -116.55057,
              46.50553
          ],
          [
              -116.5504,
              46.50546
          ],
          [
              -116.5498,
              46.50525
          ],
          [
              -116.54925,
              46.50508
          ],
          [
              -116.54916,
              46.50506
          ],
          [
              -116.54899,
              46.50501
          ],
          [
              -116.54881,
              46.50497
          ],
          [
              -116.54853,
              46.50492
          ],
          [
              -116.5481,
              46.50487
          ],
          [
              -116.54806,
              46.50487
          ],
          [
              -116.548,
              46.50486
          ],
          [
              -116.54765,
              46.50486
          ],
          [
              -116.54726,
              46.50488
          ],
          [
              -116.5471,
              46.5049
          ],
          [
              -116.54706,
              46.50491
          ],
          [
              -116.54689,
              46.50493
          ],
          [
              -116.54652,
              46.50499
          ],
          [
              -116.54616,
              46.50507
          ],
          [
              -116.54597,
              46.5051
          ],
          [
              -116.54579,
              46.50514
          ],
          [
              -116.5456,
              46.50517
          ],
          [
              -116.54505,
              46.50529
          ],
          [
              -116.54486,
              46.50532
          ],
          [
              -116.54479,
              46.50534
          ],
          [
              -116.54429,
              46.50543
          ],
          [
              -116.54392,
              46.50551
          ],
          [
              -116.54373,
              46.50554
          ],
          [
              -116.54355,
              46.50558
          ],
          [
              -116.54354,
              46.50558
          ],
          [
              -116.54336,
              46.50562
          ],
          [
              -116.54317,
              46.50565
          ],
          [
              -116.5428,
              46.50573
          ],
          [
              -116.54261,
              46.50576
          ],
          [
              -116.54224,
              46.50584
          ],
          [
              -116.54205,
              46.50587
          ],
          [
              -116.54167,
              46.50595
          ],
          [
              -116.54152,
              46.50597
          ],
          [
              -116.53886,
              46.50647
          ],
          [
              -116.53865,
              46.50652
          ],
          [
              -116.53861,
              46.50652
          ],
          [
              -116.53818,
              46.50662
          ],
          [
              -116.53812,
              46.50664
          ],
          [
              -116.538,
              46.50666
          ],
          [
              -116.53721,
              46.5069
          ],
          [
              -116.53679,
              46.50701
          ],
          [
              -116.53648,
              46.50707
          ],
          [
              -116.5364,
              46.50708
          ],
          [
              -116.53611,
              46.50714
          ],
          [
              -116.53592,
              46.50716
          ],
          [
              -116.53513,
              46.50729
          ],
          [
              -116.53476,
              46.50734
          ],
          [
              -116.53437,
              46.50741
          ],
          [
              -116.53421,
              46.50743
          ],
          [
              -116.53261,
              46.50771
          ],
          [
              -116.53186,
              46.50786
          ],
          [
              -116.53184,
              46.50786
          ],
          [
              -116.53176,
              46.50787
          ],
          [
              -116.53113,
              46.508
          ],
          [
              -116.53102,
              46.50801
          ],
          [
              -116.53089,
              46.50804
          ],
          [
              -116.52959,
              46.50825
          ],
          [
              -116.52952,
              46.50827
          ],
          [
              -116.52947,
              46.50827
          ],
          [
              -116.52938,
              46.50829
          ],
          [
              -116.52934,
              46.50829
          ],
          [
              -116.52931,
              46.5083
          ],
          [
              -116.52926,
              46.5083
          ],
          [
              -116.52921,
              46.50831
          ],
          [
              -116.52915,
              46.50831
          ],
          [
              -116.52909,
              46.50832
          ],
          [
              -116.52908,
              46.50832
          ],
          [
              -116.52905,
              46.50833
          ],
          [
              -116.5289,
              46.50833
          ],
          [
              -116.52886,
              46.50834
          ],
          [
              -116.52857,
              46.50834
          ],
          [
              -116.52849,
              46.50833
          ],
          [
              -116.52838,
              46.50833
          ],
          [
              -116.52834,
              46.50832
          ],
          [
              -116.52828,
              46.50832
          ],
          [
              -116.52784,
              46.50825
          ],
          [
              -116.52778,
              46.50823
          ],
          [
              -116.52758,
              46.50819
          ],
          [
              -116.52742,
              46.50814
          ],
          [
              -116.52724,
              46.5081
          ],
          [
              -116.52581,
              46.50773
          ],
          [
              -116.5258,
              46.50773
          ],
          [
              -116.52498,
              46.50754
          ],
          [
              -116.52445,
              46.50745
          ],
          [
              -116.52392,
              46.50738
          ],
          [
              -116.52248,
              46.50723
          ],
          [
              -116.52201,
              46.5072
          ],
          [
              -116.52194,
              46.50719
          ],
          [
              -116.52178,
              46.50718
          ],
          [
              -116.52154,
              46.50718
          ],
          [
              -116.52142,
              46.50717
          ],
          [
              -116.52068,
              46.50717
          ],
          [
              -116.52008,
              46.5072
          ],
          [
              -116.51988,
              46.5072
          ],
          [
              -116.51884,
              46.50727
          ],
          [
              -116.51873,
              46.50727
          ],
          [
              -116.51863,
              46.50728
          ],
          [
              -116.51726,
              46.50735
          ],
          [
              -116.51707,
              46.50735
          ],
          [
              -116.5169,
              46.50736
          ],
          [
              -116.51623,
              46.50736
          ],
          [
              -116.51609,
              46.50735
          ],
          [
              -116.51596,
              46.50735
          ],
          [
              -116.51496,
              46.50726
          ],
          [
              -116.51484,
              46.50724
          ],
          [
              -116.5131,
              46.50707
          ],
          [
              -116.51296,
              46.50705
          ],
          [
              -116.5129,
              46.50705
          ],
          [
              -116.51286,
              46.50704
          ],
          [
              -116.51284,
              46.50704
          ],
          [
              -116.5128,
              46.50703
          ],
          [
              -116.51272,
              46.50702
          ],
          [
              -116.51268,
              46.50702
          ],
          [
              -116.51238,
              46.50696
          ],
          [
              -116.51233,
              46.50694
          ],
          [
              -116.51225,
              46.50692
          ],
          [
              -116.5122,
              46.5069
          ],
          [
              -116.51217,
              46.5069
          ],
          [
              -116.51213,
              46.50688
          ],
          [
              -116.51203,
              46.50685
          ],
          [
              -116.51199,
              46.50683
          ],
          [
              -116.51191,
              46.5068
          ],
          [
              -116.51185,
              46.50677
          ],
          [
              -116.51182,
              46.50676
          ],
          [
              -116.51179,
              46.50674
          ],
          [
              -116.5117,
              46.5067
          ],
          [
              -116.51167,
              46.50668
          ],
          [
              -116.51164,
              46.50667
          ],
          [
              -116.51161,
              46.50665
          ],
          [
              -116.51157,
              46.50663
          ],
          [
              -116.51153,
              46.5066
          ],
          [
              -116.51149,
              46.50658
          ],
          [
              -116.51127,
              46.50642
          ],
          [
              -116.51124,
              46.50639
          ],
          [
              -116.50997,
              46.50533
          ],
          [
              -116.50971,
              46.50513
          ],
          [
              -116.50926,
              46.50476
          ],
          [
              -116.50925,
              46.50476
          ],
          [
              -116.50907,
              46.5046
          ],
          [
              -116.50854,
              46.50421
          ],
          [
              -116.50841,
              46.50413
          ],
          [
              -116.50824,
              46.50401
          ],
          [
              -116.50804,
              46.50389
          ],
          [
              -116.50796,
              46.50385
          ],
          [
              -116.50784,
              46.50378
          ],
          [
              -116.50782,
              46.50378
          ],
          [
              -116.50766,
              46.50369
          ],
          [
              -116.5075,
              46.50361
          ],
          [
              -116.50734,
              46.50354
          ],
          [
              -116.50718,
              46.50346
          ],
          [
              -116.50702,
              46.5034
          ],
          [
              -116.50685,
              46.50332
          ],
          [
              -116.50668,
              46.50326
          ],
          [
              -116.50636,
              46.50313
          ],
          [
              -116.50629,
              46.50311
          ],
          [
              -116.50618,
              46.50306
          ],
          [
              -116.50599,
              46.50299
          ],
          [
              -116.50597,
              46.50298
          ],
          [
              -116.50566,
              46.50287
          ],
          [
              -116.50548,
              46.50279
          ],
          [
              -116.50541,
              46.50277
          ],
          [
              -116.50526,
              46.50271
          ],
          [
              -116.50516,
              46.50268
          ],
          [
              -116.5045,
              46.50243
          ],
          [
              -116.50442,
              46.50239
          ],
          [
              -116.50433,
              46.50236
          ],
          [
              -116.50427,
              46.50233
          ],
          [
              -116.50415,
              46.50229
          ],
          [
              -116.50414,
              46.50229
          ],
          [
              -116.50382,
              46.50216
          ],
          [
              -116.50376,
              46.50214
          ],
          [
              -116.50359,
              46.50206
          ],
          [
              -116.50354,
              46.50205
          ],
          [
              -116.50324,
              46.50192
          ],
          [
              -116.50315,
              46.50189
          ],
          [
              -116.50302,
              46.50183
          ],
          [
              -116.50292,
              46.5018
          ],
          [
              -116.50285,
              46.50176
          ],
          [
              -116.50252,
              46.50162
          ],
          [
              -116.50249,
              46.5016
          ],
          [
              -116.50244,
              46.50158
          ],
          [
              -116.50236,
              46.50154
          ],
          [
              -116.50233,
              46.50152
          ],
          [
              -116.50229,
              46.5015
          ],
          [
              -116.50226,
              46.50148
          ],
          [
              -116.50225,
              46.50148
          ],
          [
              -116.50206,
              46.50137
          ],
          [
              -116.50202,
              46.50134
          ],
          [
              -116.50198,
              46.50132
          ],
          [
              -116.50172,
              46.50113
          ],
          [
              -116.50169,
              46.5011
          ],
          [
              -116.50165,
              46.50108
          ],
          [
              -116.50162,
              46.50105
          ],
          [
              -116.50157,
              46.50102
          ],
          [
              -116.50149,
              46.50096
          ],
          [
              -116.50138,
              46.50089
          ],
          [
              -116.50134,
              46.50086
          ],
          [
              -116.50124,
              46.5008
          ],
          [
              -116.50112,
              46.50074
          ],
          [
              -116.50112,
              46.50073
          ],
          [
              -116.50107,
              46.50071
          ],
          [
              -116.50091,
              46.50063
          ],
          [
              -116.50088,
              46.50062
          ],
          [
              -116.5008,
              46.50058
          ],
          [
              -116.50074,
              46.50056
          ],
          [
              -116.50067,
              46.50053
          ],
          [
              -116.50061,
              46.50051
          ],
          [
              -116.50054,
              46.50048
          ],
          [
              -116.50015,
              46.50037
          ],
          [
              -116.50004,
              46.50035
          ],
          [
              -116.49998,
              46.50033
          ],
          [
              -116.49991,
              46.50032
          ],
          [
              -116.49985,
              46.5003
          ],
          [
              -116.4992,
              46.5002
          ],
          [
              -116.49818,
              46.50009
          ],
          [
              -116.49791,
              46.50007
          ],
          [
              -116.49705,
              46.49997
          ],
          [
              -116.49634,
              46.49986
          ],
          [
              -116.49605,
              46.49979
          ],
          [
              -116.49593,
              46.49977
          ],
          [
              -116.49512,
              46.49958
          ],
          [
              -116.4951,
              46.49957
          ],
          [
              -116.49497,
              46.49954
          ],
          [
              -116.49485,
              46.49952
          ],
          [
              -116.49396,
              46.49931
          ],
          [
              -116.49179,
              46.49885
          ],
          [
              -116.49171,
              46.49884
          ],
          [
              -116.49161,
              46.49882
          ],
          [
              -116.49144,
              46.4988
          ],
          [
              -116.49135,
              46.49878
          ],
          [
              -116.49086,
              46.49873
          ],
          [
              -116.49083,
              46.49873
          ],
          [
              -116.49074,
              46.49872
          ],
          [
              -116.49065,
              46.49872
          ],
          [
              -116.49043,
              46.4987
          ],
          [
              -116.48968,
              46.49869
          ],
          [
              -116.48953,
              46.49868
          ],
          [
              -116.48929,
              46.49868
          ],
          [
              -116.48909,
              46.49867
          ],
          [
              -116.48832,
              46.49867
          ],
          [
              -116.48811,
              46.49868
          ],
          [
              -116.48793,
              46.49868
          ],
          [
              -116.48598,
              46.49877
          ],
          [
              -116.48587,
              46.49877
          ],
          [
              -116.48578,
              46.49878
          ],
          [
              -116.48562,
              46.49878
          ],
          [
              -116.4855,
              46.49879
          ],
          [
              -116.48541,
              46.49879
          ],
          [
              -116.48531,
              46.4988
          ],
          [
              -116.48522,
              46.4988
          ],
          [
              -116.48505,
              46.49881
          ],
          [
              -116.48502,
              46.49882
          ],
          [
              -116.48497,
              46.49882
          ],
          [
              -116.48474,
              46.49884
          ],
          [
              -116.48465,
              46.49886
          ],
          [
              -116.4845,
              46.49888
          ],
          [
              -116.48418,
              46.49894
          ],
          [
              -116.48396,
              46.49899
          ],
          [
              -116.48274,
              46.49931
          ],
          [
              -116.48265,
              46.49934
          ],
          [
              -116.48229,
              46.49943
          ],
          [
              -116.48208,
              46.49947
          ],
          [
              -116.48199,
              46.49948
          ],
          [
              -116.48192,
              46.4995
          ],
          [
              -116.48183,
              46.49951
          ],
          [
              -116.48173,
              46.49953
          ],
          [
              -116.48163,
              46.49954
          ],
          [
              -116.48153,
              46.49956
          ],
          [
              -116.48143,
              46.49957
          ],
          [
              -116.48104,
              46.49963
          ],
          [
              -116.48102,
              46.49963
          ],
          [
              -116.48096,
              46.49964
          ],
          [
              -116.48094,
              46.49964
          ],
          [
              -116.48086,
              46.49966
          ],
          [
              -116.48076,
              46.49967
          ],
          [
              -116.48066,
              46.49969
          ],
          [
              -116.48056,
              46.4997
          ],
          [
              -116.48046,
              46.49972
          ],
          [
              -116.48045,
              46.49972
          ],
          [
              -116.48035,
              46.49974
          ],
          [
              -116.48025,
              46.49975
          ],
          [
              -116.48017,
              46.49977
          ],
          [
              -116.48005,
              46.49978
          ],
          [
              -116.47996,
              46.4998
          ],
          [
              -116.47979,
              46.49982
          ],
          [
              -116.47971,
              46.49984
          ],
          [
              -116.47952,
              46.49987
          ],
          [
              -116.4794,
              46.49988
          ],
          [
              -116.47919,
              46.49992
          ],
          [
              -116.47909,
              46.49993
          ],
          [
              -116.47899,
              46.49995
          ],
          [
              -116.47889,
              46.49996
          ],
          [
              -116.47879,
              46.49998
          ],
          [
              -116.4782,
              46.50006
          ],
          [
              -116.47817,
              46.50006
          ],
          [
              -116.47723,
              46.50016
          ],
          [
              -116.47706,
              46.50016
          ],
          [
              -116.47696,
              46.50017
          ],
          [
              -116.47686,
              46.50017
          ],
          [
              -116.47674,
              46.50018
          ],
          [
              -116.47591,
              46.50018
          ],
          [
              -116.47581,
              46.50017
          ],
          [
              -116.47567,
              46.50017
          ],
          [
              -116.47561,
              46.50016
          ],
          [
              -116.47553,
              46.50016
          ],
          [
              -116.47545,
              46.50015
          ],
          [
              -116.47535,
              46.50015
          ],
          [
              -116.47531,
              46.50014
          ],
          [
              -116.47522,
              46.50014
          ],
          [
              -116.47517,
              46.50013
          ],
          [
              -116.47512,
              46.50013
          ],
          [
              -116.47491,
              46.5001
          ],
          [
              -116.47485,
              46.5001
          ],
          [
              -116.47469,
              46.50008
          ],
          [
              -116.4746,
              46.50006
          ],
          [
              -116.47449,
              46.50005
          ],
          [
              -116.47419,
              46.5
          ],
          [
              -116.4741,
              46.49998
          ],
          [
              -116.47385,
              46.49994
          ],
          [
              -116.47377,
              46.49992
          ],
          [
              -116.47371,
              46.49991
          ],
          [
              -116.47354,
              46.49987
          ],
          [
              -116.47348,
              46.49986
          ],
          [
              -116.4734,
              46.49984
          ],
          [
              -116.47334,
              46.49983
          ],
          [
              -116.47329,
              46.49981
          ],
          [
              -116.47305,
              46.49975
          ],
          [
              -116.47267,
              46.49964
          ],
          [
              -116.47144,
              46.49924
          ],
          [
              -116.47118,
              46.49914
          ],
          [
              -116.47111,
              46.49912
          ],
          [
              -116.47056,
              46.49891
          ],
          [
              -116.46954,
              46.49847
          ],
          [
              -116.46948,
              46.49845
          ],
          [
              -116.4691,
              46.49828
          ],
          [
              -116.46816,
              46.49789
          ],
          [
              -116.46789,
              46.49776
          ],
          [
              -116.46746,
              46.49752
          ],
          [
              -116.46631,
              46.49682
          ],
          [
              -116.46551,
              46.4964
          ],
          [
              -116.46371,
              46.49552
          ],
          [
              -116.46334,
              46.49531
          ],
          [
              -116.46324,
              46.49524
          ],
          [
              -116.46314,
              46.49518
          ],
          [
              -116.46253,
              46.49474
          ],
          [
              -116.4621,
              46.49445
          ],
          [
              -116.46143,
              46.49406
          ],
          [
              -116.46129,
              46.49397
          ],
          [
              -116.46123,
              46.49394
          ],
          [
              -116.46117,
              46.49389
          ],
          [
              -116.46106,
              46.49383
          ],
          [
              -116.46098,
              46.49377
          ],
          [
              -116.46094,
              46.49373
          ],
          [
              -116.46089,
              46.4937
          ],
          [
              -116.46076,
              46.4936
          ],
          [
              -116.4605,
              46.49338
          ],
          [
              -116.46019,
              46.49308
          ],
          [
              -116.46011,
              46.49299
          ],
          [
              -116.46,
              46.49288
          ],
          [
              -116.45985,
              46.49271
          ],
          [
              -116.45976,
              46.49262
          ],
          [
              -116.45969,
              46.49253
          ],
          [
              -116.45962,
              46.49246
          ],
          [
              -116.45955,
              46.49238
          ],
          [
              -116.4594,
              46.49223
          ],
          [
              -116.45935,
              46.49217
          ],
          [
              -116.45897,
              46.49183
          ],
          [
              -116.45861,
              46.49155
          ],
          [
              -116.45826,
              46.49132
          ],
          [
              -116.45818,
              46.49126
          ],
          [
              -116.45808,
              46.4912
          ],
          [
              -116.45775,
              46.49097
          ],
          [
              -116.4574,
              46.49076
          ],
          [
              -116.45671,
              46.49031
          ],
          [
              -116.4564,
              46.49014
          ],
          [
              -116.45629,
              46.49007
          ],
          [
              -116.45598,
              46.48994
          ],
          [
              -116.45587,
              46.4899
          ],
          [
              -116.45586,
              46.4899
          ],
          [
              -116.45562,
              46.48982
          ],
          [
              -116.45536,
              46.48977
          ],
          [
              -116.45521,
              46.48973
          ],
          [
              -116.45513,
              46.48972
          ],
          [
              -116.45503,
              46.48969
          ],
          [
              -116.4549,
              46.48967
          ],
          [
              -116.45488,
              46.48966
          ],
          [
              -116.45442,
              46.48958
          ],
          [
              -116.45426,
              46.48956
          ],
          [
              -116.45398,
              46.4895
          ],
          [
              -116.45373,
              46.48946
          ],
          [
              -116.45313,
              46.4894
          ],
          [
              -116.45291,
              46.4894
          ],
          [
              -116.45269,
              46.48942
          ],
          [
              -116.4526,
              46.48942
          ],
          [
              -116.45258,
              46.48943
          ],
          [
              -116.45255,
              46.48943
          ],
          [
              -116.45241,
              46.48945
          ],
          [
              -116.45232,
              46.48947
          ],
          [
              -116.45225,
              46.48948
          ],
          [
              -116.45133,
              46.48974
          ],
          [
              -116.45112,
              46.48982
          ],
          [
              -116.45104,
              46.48984
          ],
          [
              -116.45098,
              46.48987
          ],
          [
              -116.45087,
              46.48991
          ],
          [
              -116.45083,
              46.48993
          ],
          [
              -116.45079,
              46.48994
          ],
          [
              -116.45075,
              46.48996
          ],
          [
              -116.45072,
              46.48997
          ],
          [
              -116.45068,
              46.49
          ],
          [
              -116.45062,
              46.49002
          ],
          [
              -116.45045,
              46.49011
          ],
          [
              -116.4503,
              46.49021
          ],
          [
              -116.45012,
              46.49035
          ],
          [
              -116.45004,
              46.49043
          ],
          [
              -116.45,
              46.49046
          ],
          [
              -116.4499,
              46.49058
          ],
          [
              -116.44983,
              46.49068
          ],
          [
              -116.44977,
              46.4908
          ],
          [
              -116.44975,
              46.49083
          ],
          [
              -116.44972,
              46.49089
          ],
          [
              -116.44964,
              46.49116
          ],
          [
              -116.44945,
              46.49201
          ],
          [
              -116.44943,
              46.49207
          ],
          [
              -116.44915,
              46.49331
          ],
          [
              -116.44915,
              46.49332
          ],
          [
              -116.44911,
              46.49348
          ],
          [
              -116.44908,
              46.49364
          ],
          [
              -116.44904,
              46.4938
          ],
          [
              -116.44892,
              46.49419
          ],
          [
              -116.44891,
              46.4942
          ],
          [
              -116.44891,
              46.49422
          ],
          [
              -116.4489,
              46.49426
          ],
          [
              -116.44887,
              46.49434
          ],
          [
              -116.44886,
              46.49438
          ],
          [
              -116.44882,
              46.49447
          ],
          [
              -116.44877,
              46.49461
          ],
          [
              -116.44868,
              46.49481
          ],
          [
              -116.44866,
              46.49487
          ],
          [
              -116.44864,
              46.4949
          ],
          [
              -116.44854,
              46.49511
          ],
          [
              -116.44847,
              46.49524
          ],
          [
              -116.44845,
              46.49527
          ],
          [
              -116.44836,
              46.49544
          ],
          [
              -116.44834,
              46.49547
          ],
          [
              -116.44832,
              46.49551
          ],
          [
              -116.44829,
              46.49555
          ],
          [
              -116.44822,
              46.49568
          ],
          [
              -116.44818,
              46.49574
          ],
          [
              -116.44813,
              46.49583
          ],
          [
              -116.44806,
              46.49593
          ],
          [
              -116.44803,
              46.49596
          ],
          [
              -116.44797,
              46.49605
          ],
          [
              -116.44796,
              46.49606
          ],
          [
              -116.44787,
              46.49619
          ],
          [
              -116.44782,
              46.49624
          ],
          [
              -116.44777,
              46.49631
          ],
          [
              -116.44772,
              46.49637
          ],
          [
              -116.4476,
              46.49649
          ],
          [
              -116.44757,
              46.49653
          ],
          [
              -116.44755,
              46.49655
          ],
          [
              -116.44752,
              46.49659
          ],
          [
              -116.44748,
              46.49662
          ],
          [
              -116.44738,
              46.49672
          ],
          [
              -116.44727,
              46.49681
          ],
          [
              -116.44724,
              46.49684
          ],
          [
              -116.44708,
              46.49697
          ],
          [
              -116.44677,
              46.49719
          ],
          [
              -116.44666,
              46.49726
          ],
          [
              -116.44662,
              46.49728
          ],
          [
              -116.44651,
              46.49735
          ],
          [
              -116.44647,
              46.49737
          ],
          [
              -116.44641,
              46.49741
          ],
          [
              -116.44637,
              46.49743
          ],
          [
              -116.44634,
              46.49744
          ],
          [
              -116.44633,
              46.49745
          ],
          [
              -116.44627,
              46.49748
          ],
          [
              -116.44624,
              46.4975
          ],
          [
              -116.44618,
              46.49753
          ],
          [
              -116.44614,
              46.49754
          ],
          [
              -116.44609,
              46.49757
          ],
          [
              -116.44599,
              46.49762
          ],
          [
              -116.44594,
              46.49764
          ],
          [
              -116.44584,
              46.49769
          ],
          [
              -116.44578,
              46.49771
          ],
          [
              -116.4456,
              46.49779
          ],
          [
              -116.4451,
              46.49798
          ],
          [
              -116.44493,
              46.49803
          ],
          [
              -116.44445,
              46.4982
          ],
          [
              -116.44416,
              46.49829
          ],
          [
              -116.44389,
              46.49836
          ],
          [
              -116.44381,
              46.49839
          ],
          [
              -116.44375,
              46.4984
          ],
          [
              -116.44329,
              46.49851
          ],
          [
              -116.44307,
              46.49855
          ],
          [
              -116.44295,
              46.49858
          ],
          [
              -116.44266,
              46.49863
          ],
          [
              -116.44256,
              46.49864
          ],
          [
              -116.44233,
              46.49868
          ],
          [
              -116.44185,
              46.49874
          ],
          [
              -116.44176,
              46.49874
          ],
          [
              -116.44161,
              46.49876
          ],
          [
              -116.44149,
              46.49876
          ],
          [
              -116.44139,
              46.49877
          ],
          [
              -116.44097,
              46.49877
          ],
          [
              -116.44087,
              46.49876
          ],
          [
              -116.44075,
              46.49876
          ],
          [
              -116.4407,
              46.49875
          ],
          [
              -116.44064,
              46.49875
          ],
          [
              -116.44026,
              46.4987
          ],
          [
              -116.44019,
              46.49868
          ],
          [
              -116.44015,
              46.49868
          ],
          [
              -116.44006,
              46.49866
          ],
          [
              -116.44001,
              46.49864
          ],
          [
              -116.43963,
              46.49854
          ],
          [
              -116.43921,
              46.49839
          ],
          [
              -116.43888,
              46.49824
          ],
          [
              -116.43884,
              46.49821
          ],
          [
              -116.43879,
              46.49819
          ],
          [
              -116.43868,
              46.49812
          ],
          [
              -116.43864,
              46.4981
          ],
          [
              -116.4373,
              46.49728
          ],
          [
              -116.43698,
              46.49711
          ],
          [
              -116.43695,
              46.49709
          ],
          [
              -116.43621,
              46.49678
          ],
          [
              -116.43594,
              46.49669
          ],
          [
              -116.43558,
              46.49661
          ],
          [
              -116.43531,
              46.49654
          ],
          [
              -116.43524,
              46.49653
          ],
          [
              -116.43515,
              46.49651
          ],
          [
              -116.43512,
              46.4965
          ],
          [
              -116.43503,
              46.49649
          ],
          [
              -116.43481,
              46.49645
          ],
          [
              -116.43476,
              46.49645
          ],
          [
              -116.43461,
              46.49643
          ],
          [
              -116.43397,
              46.49638
          ],
          [
              -116.43336,
              46.49638
          ],
          [
              -116.43329,
              46.49639
          ],
          [
              -116.43323,
              46.49639
          ],
          [
              -116.43303,
              46.49641
          ],
          [
              -116.43299,
              46.49641
          ],
          [
              -116.43146,
              46.49663
          ],
          [
              -116.43124,
              46.49667
          ],
          [
              -116.43119,
              46.49667
          ],
          [
              -116.43108,
              46.49669
          ],
          [
              -116.43052,
              46.49674
          ],
          [
              -116.43006,
              46.49676
          ],
          [
              -116.42985,
              46.49678
          ],
          [
              -116.42863,
              46.49685
          ],
          [
              -116.42813,
              46.49686
          ],
          [
              -116.42803,
              46.49687
          ],
          [
              -116.42775,
              46.49687
          ],
          [
              -116.42753,
              46.49686
          ],
          [
              -116.4272,
              46.49686
          ],
          [
              -116.4247,
              46.49677
          ],
          [
              -116.42415,
              46.49677
          ],
          [
              -116.42407,
              46.49678
          ],
          [
              -116.42392,
              46.49678
          ],
          [
              -116.42374,
              46.4968
          ],
          [
              -116.42368,
              46.4968
          ],
          [
              -116.42196,
              46.49703
          ],
          [
              -116.42184,
              46.49704
          ],
          [
              -116.42177,
              46.49704
          ],
          [
              -116.42065,
              46.49713
          ],
          [
              -116.42048,
              46.49715
          ],
          [
              -116.41793,
              46.49732
          ],
          [
              -116.4173,
              46.49734
          ],
          [
              -116.417,
              46.49734
          ],
          [
              -116.41683,
              46.49735
          ],
          [
              -116.41666,
              46.49734
          ],
          [
              -116.41651,
              46.49734
          ],
          [
              -116.41625,
              46.49732
          ],
          [
              -116.41614,
              46.49732
          ],
          [
              -116.41503,
              46.4972
          ],
          [
              -116.41474,
              46.49718
          ],
          [
              -116.41457,
              46.49716
          ],
          [
              -116.41454,
              46.49716
          ],
          [
              -116.41429,
              46.49712
          ],
          [
              -116.41396,
              46.49708
          ],
          [
              -116.41263,
              46.49686
          ],
          [
              -116.41214,
              46.4968
          ],
          [
              -116.41197,
              46.49679
          ],
          [
              -116.41173,
              46.49676
          ],
          [
              -116.40996,
              46.49661
          ],
          [
              -116.40922,
              46.49658
          ],
          [
              -116.409,
              46.49658
          ],
          [
              -116.40887,
              46.49659
          ],
          [
              -116.40876,
              46.49659
          ],
          [
              -116.40724,
              46.49672
          ],
          [
              -116.40713,
              46.49672
          ],
          [
              -116.40698,
              46.49673
          ],
          [
              -116.40527,
              46.49673
          ],
          [
              -116.40516,
              46.49674
          ],
          [
              -116.40507,
              46.49674
          ],
          [
              -116.40469,
              46.49678
          ],
          [
              -116.40434,
              46.49683
          ],
          [
              -116.40321,
              46.49709
          ],
          [
              -116.40316,
              46.49711
          ],
          [
              -116.40277,
              46.4972
          ],
          [
              -116.40272,
              46.49722
          ],
          [
              -116.40249,
              46.49727
          ],
          [
              -116.40085,
              46.4977
          ],
          [
              -116.40019,
              46.49785
          ],
          [
              -116.39959,
              46.49797
          ],
          [
              -116.3993,
              46.49801
          ],
          [
              -116.39921,
              46.49803
          ],
          [
              -116.39875,
              46.4981
          ],
          [
              -116.39849,
              46.49813
          ],
          [
              -116.39845,
              46.49814
          ],
          [
              -116.3983,
              46.49815
          ],
          [
              -116.39815,
              46.49817
          ],
          [
              -116.39742,
              46.49822
          ],
          [
              -116.39718,
              46.49825
          ],
          [
              -116.39714,
              46.49825
          ],
          [
              -116.39711,
              46.49826
          ],
          [
              -116.39695,
              46.49828
          ],
          [
              -116.39651,
              46.49838
          ],
          [
              -116.39637,
              46.49842
          ],
          [
              -116.39607,
              46.49853
          ],
          [
              -116.3957,
              46.49869
          ],
          [
              -116.39383,
              46.49969
          ],
          [
              -116.39339,
              46.49991
          ],
          [
              -116.39323,
              46.49998
          ],
          [
              -116.39291,
              46.5001
          ],
          [
              -116.39261,
              46.50019
          ],
          [
              -116.39255,
              46.5002
          ],
          [
              -116.39249,
              46.50022
          ],
          [
              -116.39231,
              46.50025
          ],
          [
              -116.3923,
              46.50025
          ],
          [
              -116.39211,
              46.50028
          ],
          [
              -116.39205,
              46.50028
          ],
          [
              -116.39193,
              46.5003
          ],
          [
              -116.39182,
              46.5003
          ],
          [
              -116.39171,
              46.50031
          ],
          [
              -116.39157,
              46.50031
          ],
          [
              -116.3915,
              46.50032
          ],
          [
              -116.3914,
              46.50031
          ],
          [
              -116.39123,
              46.50031
          ],
          [
              -116.39104,
              46.5003
          ],
          [
              -116.39042,
              46.50023
          ],
          [
              -116.3902,
              46.50019
          ],
          [
              -116.39004,
              46.50017
          ],
          [
              -116.38989,
              46.50014
          ],
          [
              -116.38979,
              46.50013
          ],
          [
              -116.38969,
              46.50011
          ],
          [
              -116.38963,
              46.50011
          ],
          [
              -116.3893,
              46.50007
          ],
          [
              -116.38895,
              46.50005
          ],
          [
              -116.38886,
              46.50005
          ],
          [
              -116.38873,
              46.50004
          ],
          [
              -116.38857,
              46.50004
          ],
          [
              -116.38843,
              46.50005
          ],
          [
              -116.38814,
              46.50005
          ],
          [
              -116.38793,
              46.50006
          ],
          [
              -116.3875,
              46.50006
          ],
          [
              -116.38744,
              46.50005
          ],
          [
              -116.38737,
              46.50005
          ],
          [
              -116.38725,
              46.50003
          ],
          [
              -116.38719,
              46.50003
          ],
          [
              -116.38711,
              46.50002
          ],
          [
              -116.38702,
              46.5
          ],
          [
              -116.38693,
              46.49999
          ],
          [
              -116.38662,
              46.49992
          ],
          [
              -116.3866,
              46.49992
          ],
          [
              -116.38278,
              46.49914
          ],
          [
              -116.38249,
              46.49909
          ],
          [
              -116.38193,
              46.49903
          ],
          [
              -116.38148,
              46.49901
          ],
          [
              -116.38053,
              46.49901
          ],
          [
              -116.38048,
              46.499
          ],
          [
              -116.38024,
              46.49899
          ],
          [
              -116.38001,
              46.49896
          ],
          [
              -116.37992,
              46.49894
          ],
          [
              -116.37977,
              46.49892
          ],
          [
              -116.37976,
              46.49891
          ],
          [
              -116.37967,
              46.4989
          ],
          [
              -116.37938,
              46.49883
          ],
          [
              -116.37926,
              46.49881
          ],
          [
              -116.37893,
              46.49873
          ],
          [
              -116.37881,
              46.49871
          ],
          [
              -116.37864,
              46.49867
          ],
          [
              -116.37851,
              46.49865
          ],
          [
              -116.37848,
              46.49865
          ],
          [
              -116.37776,
              46.49855
          ],
          [
              -116.3763,
              46.49842
          ],
          [
              -116.37615,
              46.49842
          ],
          [
              -116.376,
              46.49841
          ],
          [
              -116.37551,
              46.49841
          ],
          [
              -116.37523,
              46.49842
          ],
          [
              -116.37467,
              46.49842
          ],
          [
              -116.37462,
              46.49843
          ],
          [
              -116.37444,
              46.49843
          ],
          [
              -116.37436,
              46.49844
          ],
          [
              -116.37428,
              46.49844
          ],
          [
              -116.37419,
              46.49846
          ],
          [
              -116.37414,
              46.49846
          ],
          [
              -116.37395,
              46.4985
          ],
          [
              -116.37368,
              46.49857
          ],
          [
              -116.37156,
              46.4993
          ],
          [
              -116.37084,
              46.49951
          ],
          [
              -116.36804,
              46.50027
          ],
          [
              -116.36742,
              46.50041
          ],
          [
              -116.36659,
              46.50055
          ],
          [
              -116.36657,
              46.50055
          ],
          [
              -116.3662,
              46.50062
          ],
          [
              -116.36588,
              46.5007
          ],
          [
              -116.36577,
              46.50072
          ],
          [
              -116.36568,
              46.50075
          ],
          [
              -116.36556,
              46.50077
          ],
          [
              -116.36438,
              46.50109
          ],
          [
              -116.36412,
              46.50115
          ],
          [
              -116.36317,
              46.50141
          ],
          [
              -116.36316,
              46.50141
          ],
          [
              -116.3629,
              46.50147
          ],
          [
              -116.36276,
              46.50151
          ],
          [
              -116.36207,
              46.50167
          ],
          [
              -116.36164,
              46.50175
          ],
          [
              -116.36156,
              46.50176
          ],
          [
              -116.36147,
              46.50178
          ],
          [
              -116.3612,
              46.50182
          ],
          [
              -116.36116,
              46.50183
          ],
          [
              -116.36062,
              46.50189
          ],
          [
              -116.36012,
              46.50192
          ],
          [
              -116.35923,
              46.50192
          ],
          [
              -116.35903,
              46.50191
          ],
          [
              -116.3587,
              46.50188
          ],
          [
              -116.35856,
              46.50186
          ],
          [
              -116.3583,
              46.50184
          ],
          [
              -116.35815,
              46.50182
          ],
          [
              -116.35698,
              46.50172
          ],
          [
              -116.35668,
              46.50171
          ],
          [
              -116.35587,
              46.50171
          ],
          [
              -116.35531,
              46.50175
          ],
          [
              -116.35513,
              46.50177
          ],
          [
              -116.35502,
              46.50179
          ],
          [
              -116.3549,
              46.5018
          ],
          [
              -116.35444,
              46.50187
          ],
          [
              -116.35386,
              46.50198
          ],
          [
              -116.35351,
              46.50207
          ],
          [
              -116.35272,
              46.50221
          ],
          [
              -116.35228,
              46.50226
          ],
          [
              -116.35185,
              46.50229
          ],
          [
              -116.35126,
              46.50231
          ],
          [
              -116.34932,
              46.50226
          ],
          [
              -116.34815,
              46.5022
          ],
          [
              -116.34324,
              46.50205
          ],
          [
              -116.34237,
              46.50199
          ],
          [
              -116.34198,
              46.50193
          ],
          [
              -116.34151,
              46.50184
          ],
          [
              -116.34104,
              46.50171
          ],
          [
              -116.33891,
              46.50094
          ],
          [
              -116.33862,
              46.50085
          ],
          [
              -116.33821,
              46.50074
          ],
          [
              -116.33794,
              46.50068
          ],
          [
              -116.33745,
              46.50061
          ],
          [
              -116.33714,
              46.50058
          ],
          [
              -116.33705,
              46.50058
          ],
          [
              -116.33698,
              46.50057
          ],
          [
              -116.3368,
              46.50057
          ],
          [
              -116.33643,
              46.50055
          ],
          [
              -116.33205,
              46.50059
          ],
          [
              -116.33141,
              46.50057
          ],
          [
              -116.32885,
              46.50057
          ],
          [
              -116.32828,
              46.50053
          ],
          [
              -116.32773,
              46.50047
          ],
          [
              -116.32097,
              46.49954
          ],
          [
              -116.31595,
              46.49893
          ],
          [
              -116.31284,
              46.49851
          ],
          [
              -116.31119,
              46.49825
          ],
          [
              -116.31112,
              46.49823
          ],
          [
              -116.31039,
              46.49811
          ],
          [
              -116.31036,
              46.49811
          ],
          [
              -116.30374,
              46.49695
          ],
          [
              -116.30371,
              46.49695
          ],
          [
              -116.30346,
              46.4969
          ],
          [
              -116.30344,
              46.4969
          ],
          [
              -116.30034,
              46.49627
          ],
          [
              -116.30016,
              46.49624
          ],
          [
              -116.29933,
              46.49607
          ],
          [
              -116.2987,
              46.49596
          ],
          [
              -116.29808,
              46.49587
          ],
          [
              -116.29579,
              46.49548
          ],
          [
              -116.29018,
              46.49429
          ],
          [
              -116.28953,
              46.49417
          ],
          [
              -116.28886,
              46.49407
          ],
          [
              -116.28639,
              46.49383
          ],
          [
              -116.28633,
              46.49382
          ],
          [
              -116.2836,
              46.49357
          ],
          [
              -116.28318,
              46.49351
          ],
          [
              -116.28311,
              46.49349
          ],
          [
              -116.28297,
              46.49347
          ],
          [
              -116.28277,
              46.49346
          ],
          [
              -116.28234,
              46.49338
          ],
          [
              -116.28153,
              46.49314
          ],
          [
              -116.27891,
              46.49248
          ],
          [
              -116.27708,
              46.49197
          ],
          [
              -116.2755,
              46.49148
          ],
          [
              -116.27344,
              46.49077
          ],
          [
              -116.27337,
              46.49074
          ],
          [
              -116.27321,
              46.49069
          ],
          [
              -116.27298,
              46.4906
          ],
          [
              -116.26995,
              46.48952
          ],
          [
              -116.2695,
              46.48933
          ],
          [
              -116.26924,
              46.48921
          ],
          [
              -116.26911,
              46.48914
          ],
          [
              -116.26898,
              46.48908
          ],
          [
              -116.26851,
              46.4888
          ],
          [
              -116.26806,
              46.48848
          ],
          [
              -116.26767,
              46.48815
          ],
          [
              -116.26761,
              46.48809
          ],
          [
              -116.26755,
              46.48804
          ],
          [
              -116.26721,
              46.48767
          ],
          [
              -116.26692,
              46.48728
          ],
          [
              -116.26688,
              46.48721
          ],
          [
              -116.26682,
              46.48712
          ],
          [
              -116.26669,
              46.48688
          ],
          [
              -116.26603,
              46.48548
          ],
          [
              -116.26589,
              46.48522
          ],
          [
              -116.26573,
              46.48495
          ],
          [
              -116.26546,
              46.48457
          ],
          [
              -116.26526,
              46.48431
          ],
          [
              -116.26483,
              46.48383
          ],
          [
              -116.26188,
              46.48085
          ],
          [
              -116.26188,
              46.48084
          ],
          [
              -116.26173,
              46.48069
          ],
          [
              -116.25981,
              46.47858
          ],
          [
              -116.25932,
              46.47808
          ],
          [
              -116.25911,
              46.47789
          ],
          [
              -116.25756,
              46.47886
          ],
          [
              -116.25709,
              46.47906
          ],
          [
              -116.25676,
              46.47924
          ],
          [
              -116.25663,
              46.47932
          ],
          [
              -116.25641,
              46.47943
          ],
          [
              -116.2562,
              46.47952
          ],
          [
              -116.25604,
              46.47958
          ],
          [
              -116.25594,
              46.4796
          ],
          [
              -116.25576,
              46.47961
          ],
          [
              -116.25507,
              46.4796
          ],
          [
              -116.25496,
              46.47939
          ],
          [
              -116.25507,
              46.4796
          ],
          [
              -116.25576,
              46.47961
          ],
          [
              -116.25594,
              46.4796
          ],
          [
              -116.25604,
              46.47958
          ],
          [
              -116.2562,
              46.47952
          ],
          [
              -116.25641,
              46.47943
          ],
          [
              -116.25663,
              46.47932
          ],
          [
              -116.25676,
              46.47924
          ],
          [
              -116.25709,
              46.47906
          ],
          [
              -116.25756,
              46.47886
          ],
          [
              -116.25911,
              46.47789
          ],
          [
              -116.25818,
              46.47708
          ],
          [
              -116.25768,
              46.4767
          ],
          [
              -116.25749,
              46.47657
          ],
          [
              -116.25714,
              46.47631
          ],
          [
              -116.25705,
              46.47625
          ],
          [
              -116.25634,
              46.47572
          ],
          [
              -116.25633,
              46.47571
          ],
          [
              -116.25624,
              46.47564
          ],
          [
              -116.25618,
              46.47558
          ],
          [
              -116.25611,
              46.47553
          ],
          [
              -116.25608,
              46.4755
          ],
          [
              -116.25577,
              46.47524
          ],
          [
              -116.25527,
              46.47479
          ],
          [
              -116.25514,
              46.47466
          ],
          [
              -116.25508,
              46.47461
          ],
          [
              -116.255,
              46.47452
          ],
          [
              -116.25492,
              46.47444
          ],
          [
              -116.25492,
              46.47443
          ],
          [
              -116.25486,
              46.47437
          ],
          [
              -116.25481,
              46.4743
          ],
          [
              -116.25475,
              46.47423
          ],
          [
              -116.25463,
              46.47406
          ],
          [
              -116.25425,
              46.47337
          ],
          [
              -116.25361,
              46.47239
          ],
          [
              -116.25346,
              46.47219
          ],
          [
              -116.25258,
              46.47092
          ],
          [
              -116.25236,
              46.47069
          ],
          [
              -116.2522,
              46.47055
          ],
          [
              -116.25214,
              46.47051
          ],
          [
              -116.252,
              46.4704
          ],
          [
              -116.25169,
              46.47022
          ],
          [
              -116.25065,
              46.46968
          ],
          [
              -116.24845,
              46.46833
          ],
          [
              -116.24803,
              46.4681
          ],
          [
              -116.24767,
              46.46788
          ],
          [
              -116.24763,
              46.46786
          ],
          [
              -116.24754,
              46.4678
          ],
          [
              -116.24695,
              46.46745
          ],
          [
              -116.24644,
              46.46719
          ],
          [
              -116.24621,
              46.46709
          ],
          [
              -116.24619,
              46.46709
          ],
          [
              -116.24605,
              46.46703
          ],
          [
              -116.24598,
              46.46701
          ],
          [
              -116.24576,
              46.46692
          ],
          [
              -116.24572,
              46.4669
          ],
          [
              -116.24539,
              46.4668
          ],
          [
              -116.24506,
              46.46668
          ],
          [
              -116.24473,
              46.4666
          ],
          [
              -116.24411,
              46.4665
          ],
          [
              -116.24407,
              46.46649
          ],
          [
              -116.24355,
              46.46645
          ],
          [
              -116.243,
              46.46645
          ],
          [
              -116.24224,
              46.46648
          ],
          [
              -116.24184,
              46.46647
          ],
          [
              -116.2413,
              46.46641
          ],
          [
              -116.24064,
              46.46627
          ],
          [
              -116.24046,
              46.46622
          ],
          [
              -116.23983,
              46.46607
          ],
          [
              -116.23947,
              46.466
          ],
          [
              -116.2391,
              46.46591
          ],
          [
              -116.23878,
              46.46582
          ],
          [
              -116.23862,
              46.46576
          ],
          [
              -116.23843,
              46.4657
          ],
          [
              -116.23811,
              46.46556
          ],
          [
              -116.23759,
              46.46528
          ],
          [
              -116.23746,
              46.46519
          ],
          [
              -116.23505,
              46.46373
          ],
          [
              -116.23374,
              46.46289
          ],
          [
              -116.23362,
              46.46279
          ],
          [
              -116.23344,
              46.46267
          ],
          [
              -116.23326,
              46.46253
          ],
          [
              -116.23322,
              46.46251
          ],
          [
              -116.23125,
              46.46102
          ],
          [
              -116.23072,
              46.46059
          ],
          [
              -116.23017,
              46.46006
          ],
          [
              -116.22996,
              46.45987
          ],
          [
              -116.22984,
              46.45975
          ],
          [
              -116.22974,
              46.45966
          ],
          [
              -116.22954,
              46.4595
          ],
          [
              -116.22936,
              46.45938
          ],
          [
              -116.22915,
              46.45927
          ],
          [
              -116.22901,
              46.45921
          ],
          [
              -116.22889,
              46.45917
          ],
          [
              -116.22877,
              46.45912
          ],
          [
              -116.22847,
              46.45904
          ],
          [
              -116.22832,
              46.45899
          ],
          [
              -116.22814,
              46.45895
          ],
          [
              -116.22777,
              46.45885
          ],
          [
              -116.22759,
              46.45881
          ],
          [
              -116.22426,
              46.45793
          ],
          [
              -116.22319,
              46.45762
          ],
          [
              -116.22226,
              46.45729
          ],
          [
              -116.22213,
              46.45723
          ],
          [
              -116.22199,
              46.45718
          ],
          [
              -116.22134,
              46.4569
          ],
          [
              -116.22028,
              46.45632
          ],
          [
              -116.21943,
              46.4558
          ],
          [
              -116.21921,
              46.45565
          ],
          [
              -116.21886,
              46.45539
          ],
          [
              -116.21866,
              46.45522
          ],
          [
              -116.21858,
              46.45514
          ],
          [
              -116.21839,
              46.45493
          ],
          [
              -116.21823,
              46.45472
          ],
          [
              -116.21815,
              46.45459
          ],
          [
              -116.21765,
              46.45367
          ],
          [
              -116.21749,
              46.45342
          ],
          [
              -116.21683,
              46.45229
          ],
          [
              -116.21668,
              46.45198
          ],
          [
              -116.21662,
              46.45184
          ],
          [
              -116.21658,
              46.45173
          ],
          [
              -116.21655,
              46.45162
          ],
          [
              -116.21654,
              46.4516
          ],
          [
              -116.21647,
              46.45134
          ],
          [
              -116.2164,
              46.45098
          ],
          [
              -116.21606,
              46.44886
          ],
          [
              -116.21604,
              46.44877
          ],
          [
              -116.21598,
              46.44858
          ],
          [
              -116.21589,
              46.44836
          ],
          [
              -116.21581,
              46.44821
          ],
          [
              -116.21574,
              46.4481
          ],
          [
              -116.21555,
              46.44776
          ],
          [
              -116.21533,
              46.44728
          ],
          [
              -116.21528,
              46.44714
          ],
          [
              -116.21518,
              46.44676
          ],
          [
              -116.21515,
              46.44658
          ],
          [
              -116.21514,
              46.44647
          ],
          [
              -116.21511,
              46.44629
          ],
          [
              -116.21502,
              46.44539
          ],
          [
              -116.21501,
              46.44535
          ],
          [
              -116.21496,
              46.44485
          ],
          [
              -116.21495,
              46.44481
          ],
          [
              -116.21487,
              46.44413
          ],
          [
              -116.21487,
              46.4441
          ],
          [
              -116.21484,
              46.44383
          ],
          [
              -116.21482,
              46.44374
          ],
          [
              -116.21478,
              46.4434
          ],
          [
              -116.21478,
              46.44338
          ],
          [
              -116.21457,
              46.44159
          ],
          [
              -116.21456,
              46.44159
          ],
          [
              -116.21449,
              46.44094
          ],
          [
              -116.2144,
              46.44058
          ],
          [
              -116.21426,
              46.44022
          ],
          [
              -116.21388,
              46.43943
          ],
          [
              -116.2138,
              46.43923
          ],
          [
              -116.21379,
              46.43922
          ],
          [
              -116.21376,
              46.43913
          ],
          [
              -116.21372,
              46.43904
          ],
          [
              -116.21372,
              46.43902
          ],
          [
              -116.21368,
              46.43892
          ],
          [
              -116.21362,
              46.43871
          ],
          [
              -116.21355,
              46.43833
          ],
          [
              -116.21353,
              46.43818
          ],
          [
              -116.21353,
              46.43775
          ],
          [
              -116.21356,
              46.43743
          ],
          [
              -116.21355,
              46.43736
          ],
          [
              -116.21357,
              46.43719
          ],
          [
              -116.21404,
              46.43536
          ],
          [
              -116.21409,
              46.43519
          ],
          [
              -116.21413,
              46.43502
          ],
          [
              -116.21413,
              46.43501
          ],
          [
              -116.2142,
              46.43469
          ],
          [
              -116.21427,
              46.43446
          ],
          [
              -116.21434,
              46.43417
          ],
          [
              -116.21434,
              46.43412
          ],
          [
              -116.21435,
              46.43409
          ],
          [
              -116.21436,
              46.43402
          ],
          [
              -116.21437,
              46.43398
          ],
          [
              -116.21437,
              46.43393
          ],
          [
              -116.21438,
              46.43389
          ],
          [
              -116.21438,
              46.43358
          ],
          [
              -116.21437,
              46.43354
          ],
          [
              -116.21436,
              46.43345
          ],
          [
              -116.21429,
              46.43311
          ],
          [
              -116.21423,
              46.43292
          ],
          [
              -116.21416,
              46.43277
          ],
          [
              -116.21412,
              46.43267
          ],
          [
              -116.21404,
              46.43253
          ],
          [
              -116.21396,
              46.43241
          ],
          [
              -116.21391,
              46.43236
          ],
          [
              -116.21383,
              46.43226
          ],
          [
              -116.21374,
              46.43217
          ],
          [
              -116.21337,
              46.43175
          ],
          [
              -116.21334,
              46.43173
          ],
          [
              -116.21309,
              46.43149
          ],
          [
              -116.21228,
              46.43076
          ],
          [
              -116.2122,
              46.43068
          ],
          [
              -116.21211,
              46.43062
          ],
          [
              -116.21205,
              46.43056
          ],
          [
              -116.21199,
              46.43052
          ],
          [
              -116.21192,
              46.43046
          ],
          [
              -116.2118,
              46.43034
          ],
          [
              -116.21174,
              46.43029
          ],
          [
              -116.21159,
              46.43014
          ],
          [
              -116.21155,
              46.43009
          ],
          [
              -116.2115,
              46.43004
          ],
          [
              -116.21147,
              46.42999
          ],
          [
              -116.21142,
              46.42993
          ],
          [
              -116.21128,
              46.42973
          ],
          [
              -116.21119,
              46.42957
          ],
          [
              -116.21104,
              46.42924
          ],
          [
              -116.2108,
              46.42866
          ],
          [
              -116.21046,
              46.42793
          ],
          [
              -116.21041,
              46.4278
          ],
          [
              -116.21035,
              46.42761
          ],
          [
              -116.21034,
              46.42755
          ],
          [
              -116.21033,
              46.42752
          ],
          [
              -116.21033,
              46.42749
          ],
          [
              -116.21032,
              46.4274
          ],
          [
              -116.21032,
              46.42715
          ],
          [
              -116.21034,
              46.42695
          ],
          [
              -116.21034,
              46.42687
          ],
          [
              -116.21036,
              46.42674
          ],
          [
              -116.21036,
              46.42667
          ],
          [
              -116.21037,
              46.42657
          ],
          [
              -116.21037,
              46.42632
          ],
          [
              -116.21034,
              46.42609
          ],
          [
              -116.21034,
              46.42606
          ],
          [
              -116.21033,
              46.42604
          ],
          [
              -116.2103,
              46.42589
          ],
          [
              -116.21029,
              46.42587
          ],
          [
              -116.21027,
              46.42577
          ],
          [
              -116.2102,
              46.42554
          ],
          [
              -116.21017,
              46.42547
          ],
          [
              -116.21013,
              46.42533
          ],
          [
              -116.21012,
              46.42528
          ],
          [
              -116.21008,
              46.42514
          ],
          [
              -116.21005,
              46.42498
          ],
          [
              -116.21005,
              46.42473
          ],
          [
              -116.21006,
              46.42472
          ],
          [
              -116.21011,
              46.42444
          ],
          [
              -116.21012,
              46.42444
          ],
          [
              -116.21018,
              46.42412
          ],
          [
              -116.21018,
              46.42402
          ],
          [
              -116.21019,
              46.42396
          ],
          [
              -116.21018,
              46.42391
          ],
          [
              -116.21018,
              46.42383
          ],
          [
              -116.21013,
              46.42359
          ],
          [
              -116.2101,
              46.42338
          ],
          [
              -116.21006,
              46.4232
          ],
          [
              -116.2099,
              46.42268
          ],
          [
              -116.20986,
              46.42259
          ],
          [
              -116.20985,
              46.42255
          ],
          [
              -116.20971,
              46.42221
          ],
          [
              -116.20968,
              46.42215
          ],
          [
              -116.20967,
              46.42212
          ],
          [
              -116.20958,
              46.42197
          ],
          [
              -116.20929,
              46.42157
          ],
          [
              -116.20915,
              46.4214
          ],
          [
              -116.20909,
              46.42134
          ],
          [
              -116.20901,
              46.42124
          ],
          [
              -116.2089,
              46.42112
          ],
          [
              -116.20861,
              46.42084
          ],
          [
              -116.20856,
              46.42078
          ],
          [
              -116.20837,
              46.42059
          ],
          [
              -116.20831,
              46.42052
          ],
          [
              -116.20822,
              46.42043
          ],
          [
              -116.20813,
              46.42031
          ],
          [
              -116.20803,
              46.42019
          ],
          [
              -116.20683,
              46.419
          ],
          [
              -116.20653,
              46.41874
          ],
          [
              -116.20639,
              46.41863
          ],
          [
              -116.20634,
              46.41858
          ],
          [
              -116.20632,
              46.41857
          ],
          [
              -116.20497,
              46.41734
          ],
          [
              -116.20482,
              46.41722
          ],
          [
              -116.20441,
              46.41693
          ],
          [
              -116.20145,
              46.41514
          ],
          [
              -116.20137,
              46.4151
          ],
          [
              -116.20125,
              46.41503
          ],
          [
              -116.20082,
              46.41482
          ],
          [
              -116.20075,
              46.41478
          ],
          [
              -116.19999,
              46.41446
          ],
          [
              -116.19922,
              46.41409
          ],
          [
              -116.19863,
              46.41377
          ],
          [
              -116.19841,
              46.41366
          ],
          [
              -116.19835,
              46.41362
          ],
          [
              -116.19829,
              46.41359
          ],
          [
              -116.19793,
              46.41337
          ],
          [
              -116.19763,
              46.41314
          ],
          [
              -116.19757,
              46.41307
          ],
          [
              -116.19738,
              46.41289
          ],
          [
              -116.19729,
              46.41279
          ],
          [
              -116.19708,
              46.41258
          ],
          [
              -116.19703,
              46.41254
          ],
          [
              -116.19695,
              46.41246
          ],
          [
              -116.1969,
              46.41242
          ],
          [
              -116.19687,
              46.41239
          ],
          [
              -116.19665,
              46.41222
          ],
          [
              -116.19659,
              46.41218
          ],
          [
              -116.1965,
              46.41211
          ],
          [
              -116.19384,
              46.41033
          ],
          [
              -116.19374,
              46.41027
          ],
          [
              -116.19367,
              46.41022
          ],
          [
              -116.19359,
              46.41018
          ],
          [
              -116.19336,
              46.41002
          ],
          [
              -116.19327,
              46.40995
          ],
          [
              -116.19325,
              46.40994
          ],
          [
              -116.19296,
              46.40974
          ],
          [
              -116.19274,
              46.40957
          ],
          [
              -116.19267,
              46.4095
          ],
          [
              -116.19248,
              46.40933
          ],
          [
              -116.19221,
              46.40905
          ],
          [
              -116.19182,
              46.40861
          ],
          [
              -116.19177,
              46.40856
          ],
          [
              -116.19172,
              46.4085
          ],
          [
              -116.19012,
              46.40687
          ],
          [
              -116.19005,
              46.40681
          ],
          [
              -116.19001,
              46.40676
          ],
          [
              -116.18956,
              46.4063
          ],
          [
              -116.18902,
              46.40579
          ],
          [
              -116.18898,
              46.40574
          ],
          [
              -116.18891,
              46.40567
          ],
          [
              -116.18875,
              46.40553
          ],
          [
              -116.18848,
              46.40532
          ],
          [
              -116.18839,
              46.40527
          ],
          [
              -116.1883,
              46.4052
          ],
          [
              -116.18683,
              46.40437
          ],
          [
              -116.18607,
              46.40398
          ],
          [
              -116.18539,
              46.40359
          ],
          [
              -116.18507,
              46.40335
          ],
          [
              -116.18481,
              46.40309
          ],
          [
              -116.18444,
              46.40265
          ],
          [
              -116.18444,
              46.40264
          ],
          [
              -116.18429,
              46.40249
          ],
          [
              -116.18417,
              46.40234
          ],
          [
              -116.18355,
              46.40168
          ],
          [
              -116.1835,
              46.40164
          ],
          [
              -116.18347,
              46.40159
          ],
          [
              -116.18344,
              46.40156
          ],
          [
              -116.18332,
              46.4014
          ],
          [
              -116.18326,
              46.4013
          ],
          [
              -116.18325,
              46.40127
          ],
          [
              -116.18322,
              46.40121
          ],
          [
              -116.18321,
              46.4012
          ],
          [
              -116.18318,
              46.40113
          ],
          [
              -116.18317,
              46.40109
          ],
          [
              -116.18317,
              46.40108
          ],
          [
              -116.18315,
              46.40104
          ],
          [
              -116.18313,
              46.40095
          ],
          [
              -116.18313,
              46.40091
          ],
          [
              -116.18312,
              46.40088
          ],
          [
              -116.18312,
              46.4006
          ],
          [
              -116.18313,
              46.40055
          ],
          [
              -116.18313,
              46.40053
          ],
          [
              -116.18314,
              46.40045
          ],
          [
              -116.1832,
              46.40016
          ],
          [
              -116.18321,
              46.40006
          ],
          [
              -116.18323,
              46.39996
          ],
          [
              -116.18326,
              46.39969
          ],
          [
              -116.18326,
              46.39943
          ],
          [
              -116.18324,
              46.39917
          ],
          [
              -116.18317,
              46.39881
          ],
          [
              -116.18312,
              46.39861
          ],
          [
              -116.18296,
              46.39814
          ],
          [
              -116.18273,
              46.39754
          ],
          [
              -116.18262,
              46.39717
          ],
          [
              -116.18254,
              46.39684
          ],
          [
              -116.18252,
              46.39673
          ],
          [
              -116.18239,
              46.39627
          ],
          [
              -116.18235,
              46.39616
          ],
          [
              -116.18233,
              46.39612
          ],
          [
              -116.18232,
              46.39608
          ],
          [
              -116.18224,
              46.39591
          ],
          [
              -116.18222,
              46.39585
          ],
          [
              -116.18212,
              46.39565
          ],
          [
              -116.18208,
              46.39555
          ],
          [
              -116.18196,
              46.39533
          ],
          [
              -116.18147,
              46.39451
          ],
          [
              -116.18119,
              46.39409
          ],
          [
              -116.18109,
              46.39397
          ],
          [
              -116.18105,
              46.39391
          ],
          [
              -116.18084,
              46.39366
          ],
          [
              -116.1808,
              46.39358
          ],
          [
              -116.1808,
              46.39357
          ],
          [
              -116.18079,
              46.39357
          ],
          [
              -116.18076,
              46.39354
          ],
          [
              -116.18073,
              46.3935
          ],
          [
              -116.18056,
              46.39332
          ],
          [
              -116.17967,
              46.39244
          ],
          [
              -116.17843,
              46.39113
          ],
          [
              -116.17841,
              46.39109
          ],
          [
              -116.17825,
              46.39089
          ],
          [
              -116.17817,
              46.3908
          ],
          [
              -116.17809,
              46.39069
          ],
          [
              -116.178,
              46.39058
          ],
          [
              -116.17782,
              46.39032
          ],
          [
              -116.17771,
              46.39014
          ],
          [
              -116.17761,
              46.39
          ],
          [
              -116.17761,
              46.38999
          ],
          [
              -116.17739,
              46.38962
          ],
          [
              -116.17717,
              46.38917
          ],
          [
              -116.17703,
              46.38891
          ],
          [
              -116.17701,
              46.38886
          ],
          [
              -116.17699,
              46.38884
          ],
          [
              -116.17696,
              46.38876
          ],
          [
              -116.17686,
              46.38854
          ],
          [
              -116.17681,
              46.3884
          ],
          [
              -116.1768,
              46.38834
          ],
          [
              -116.17672,
              46.38804
          ],
          [
              -116.17668,
              46.38784
          ],
          [
              -116.17667,
              46.38782
          ],
          [
              -116.17665,
              46.38766
          ],
          [
              -116.17664,
              46.38763
          ],
          [
              -116.17663,
              46.38753
          ],
          [
              -116.17662,
              46.38748
          ],
          [
              -116.1766,
              46.38731
          ],
          [
              -116.1766,
              46.38688
          ],
          [
              -116.17661,
              46.38679
          ],
          [
              -116.17664,
              46.38668
          ],
          [
              -116.17665,
              46.38662
          ],
          [
              -116.17669,
              46.38649
          ],
          [
              -116.17678,
              46.38628
          ],
          [
              -116.17681,
              46.38623
          ],
          [
              -116.17683,
              46.38618
          ],
          [
              -116.17686,
              46.38613
          ],
          [
              -116.17689,
              46.38609
          ],
          [
              -116.17691,
              46.38605
          ],
          [
              -116.17693,
              46.38602
          ],
          [
              -116.17695,
              46.38598
          ],
          [
              -116.17717,
              46.38568
          ],
          [
              -116.17743,
              46.38535
          ],
          [
              -116.17747,
              46.38529
          ],
          [
              -116.17749,
              46.38527
          ],
          [
              -116.17752,
              46.38523
          ],
          [
              -116.17763,
              46.38505
          ],
          [
              -116.17772,
              46.38487
          ],
          [
              -116.17776,
              46.38475
          ],
          [
              -116.17779,
              46.38468
          ],
          [
              -116.17785,
              46.38446
          ],
          [
              -116.17786,
              46.38438
          ],
          [
              -116.17786,
              46.38429
          ],
          [
              -116.17787,
              46.38419
          ],
          [
              -116.17787,
              46.38412
          ],
          [
              -116.17786,
              46.38405
          ],
          [
              -116.17784,
              46.38378
          ],
          [
              -116.17784,
              46.38368
          ],
          [
              -116.17783,
              46.38358
          ],
          [
              -116.17783,
              46.38348
          ],
          [
              -116.17784,
              46.38342
          ],
          [
              -116.17784,
              46.38335
          ],
          [
              -116.17785,
              46.38329
          ],
          [
              -116.17785,
              46.38325
          ],
          [
              -116.17787,
              46.38316
          ],
          [
              -116.17788,
              46.38309
          ],
          [
              -116.17789,
              46.38306
          ],
          [
              -116.1779,
              46.38304
          ],
          [
              -116.17804,
              46.38259
          ],
          [
              -116.17808,
              46.38249
          ],
          [
              -116.17811,
              46.38239
          ],
          [
              -116.17813,
              46.38234
          ],
          [
              -116.17815,
              46.38225
          ],
          [
              -116.17817,
              46.38219
          ],
          [
              -116.1782,
              46.38204
          ],
          [
              -116.17824,
              46.38156
          ],
          [
              -116.17824,
              46.38092
          ],
          [
              -116.1782,
              46.38061
          ],
          [
              -116.17765,
              46.37854
          ],
          [
              -116.1776,
              46.37829
          ],
          [
              -116.17735,
              46.37725
          ],
          [
              -116.17732,
              46.37717
          ],
          [
              -116.17728,
              46.37702
          ],
          [
              -116.17727,
              46.37695
          ],
          [
              -116.17725,
              46.37689
          ],
          [
              -116.17725,
              46.37686
          ],
          [
              -116.17724,
              46.37684
          ],
          [
              -116.17724,
              46.37662
          ],
          [
              -116.17729,
              46.37613
          ],
          [
              -116.17735,
              46.37582
          ],
          [
              -116.1774,
              46.37564
          ],
          [
              -116.1774,
              46.37563
          ],
          [
              -116.17748,
              46.37541
          ],
          [
              -116.17753,
              46.37523
          ],
          [
              -116.17754,
              46.37515
          ],
          [
              -116.17756,
              46.37507
          ],
          [
              -116.17756,
              46.37498
          ],
          [
              -116.17757,
              46.37489
          ],
          [
              -116.17756,
              46.3748
          ],
          [
              -116.17756,
              46.37467
          ],
          [
              -116.1775,
              46.37443
          ],
          [
              -116.17742,
              46.37421
          ],
          [
              -116.1774,
              46.37414
          ],
          [
              -116.17737,
              46.37409
          ],
          [
              -116.17735,
              46.37403
          ],
          [
              -116.17728,
              46.37391
          ],
          [
              -116.17726,
              46.37386
          ],
          [
              -116.17722,
              46.37381
          ],
          [
              -116.1772,
              46.37377
          ],
          [
              -116.17715,
              46.3737
          ],
          [
              -116.17712,
              46.37365
          ],
          [
              -116.17708,
              46.37361
          ],
          [
              -116.17702,
              46.37352
          ],
          [
              -116.17698,
              46.37347
          ],
          [
              -116.1769,
              46.3734
          ],
          [
              -116.17685,
              46.37334
          ],
          [
              -116.17672,
              46.37322
          ],
          [
              -116.17655,
              46.37309
          ],
          [
              -116.17623,
              46.37288
          ],
          [
              -116.17567,
              46.37261
          ],
          [
              -116.17562,
              46.37259
          ],
          [
              -116.17474,
              46.37231
          ],
          [
              -116.17188,
              46.37151
          ],
          [
              -116.17146,
              46.37136
          ],
          [
              -116.17134,
              46.37131
          ],
          [
              -116.17081,
              46.37106
          ],
          [
              -116.17061,
              46.37095
          ],
          [
              -116.17042,
              46.37083
          ],
          [
              -116.17013,
              46.37063
          ],
          [
              -116.16996,
              46.37048
          ],
          [
              -116.16988,
              46.3704
          ],
          [
              -116.16979,
              46.37032
          ],
          [
              -116.16972,
              46.37024
          ],
          [
              -116.16954,
              46.37006
          ],
          [
              -116.16941,
              46.36989
          ],
          [
              -116.1693,
              46.36976
          ],
          [
              -116.1692,
              46.3696
          ],
          [
              -116.16904,
              46.36937
          ],
          [
              -116.16881,
              46.369
          ],
          [
              -116.16877,
              46.36892
          ],
          [
              -116.1687,
              46.3688
          ],
          [
              -116.16866,
              46.36871
          ],
          [
              -116.16862,
              46.36865
          ],
          [
              -116.16857,
              46.36855
          ],
          [
              -116.16849,
              46.36835
          ],
          [
              -116.16844,
              46.36825
          ],
          [
              -116.16836,
              46.36804
          ],
          [
              -116.16829,
              46.36782
          ],
          [
              -116.16824,
              46.36769
          ],
          [
              -116.16814,
              46.36734
          ],
          [
              -116.16812,
              46.36725
          ],
          [
              -116.1681,
              46.3671
          ],
          [
              -116.16807,
              46.36694
          ],
          [
              -116.16801,
              46.36637
          ],
          [
              -116.16801,
              46.36631
          ],
          [
              -116.16793,
              46.36518
          ],
          [
              -116.16791,
              46.36503
          ],
          [
              -116.16786,
              46.36481
          ],
          [
              -116.16784,
              46.36469
          ],
          [
              -116.16692,
              46.36104
          ],
          [
              -116.16692,
              46.36103
          ],
          [
              -116.16688,
              46.3609
          ],
          [
              -116.16679,
              46.36068
          ],
          [
              -116.16679,
              46.36066
          ],
          [
              -116.16672,
              46.3605
          ],
          [
              -116.16671,
              46.36049
          ],
          [
              -116.16665,
              46.36037
          ],
          [
              -116.16664,
              46.36033
          ],
          [
              -116.16637,
              46.35986
          ],
          [
              -116.16605,
              46.35939
          ],
          [
              -116.16594,
              46.35919
          ],
          [
              -116.16594,
              46.35917
          ],
          [
              -116.16589,
              46.35904
          ],
          [
              -116.16585,
              46.35884
          ],
          [
              -116.16585,
              46.35876
          ],
          [
              -116.16588,
              46.35832
          ],
          [
              -116.16588,
              46.35793
          ],
          [
              -116.16586,
              46.35777
          ],
          [
              -116.16582,
              46.35755
          ],
          [
              -116.16579,
              46.35747
          ],
          [
              -116.16577,
              46.3574
          ],
          [
              -116.1657,
              46.35721
          ],
          [
              -116.16568,
              46.35718
          ],
          [
              -116.16566,
              46.35712
          ],
          [
              -116.16552,
              46.35682
          ],
          [
              -116.16547,
              46.35676
          ],
          [
              -116.16543,
              46.35669
          ],
          [
              -116.16529,
              46.35649
          ],
          [
              -116.16527,
              46.35645
          ],
          [
              -116.16511,
              46.35626
          ],
          [
              -116.16472,
              46.35589
          ],
          [
              -116.16465,
              46.35581
          ],
          [
              -116.16457,
              46.35573
          ],
          [
              -116.16449,
              46.35566
          ],
          [
              -116.16403,
              46.35517
          ],
          [
              -116.16312,
              46.35425
          ],
          [
              -116.16302,
              46.35414
          ],
          [
              -116.16288,
              46.35401
          ],
          [
              -116.1627,
              46.35382
          ],
          [
              -116.16245,
              46.35358
          ],
          [
              -116.16219,
              46.35336
          ],
          [
              -116.16185,
              46.35311
          ],
          [
              -116.16169,
              46.35302
          ],
          [
              -116.16152,
              46.3529
          ],
          [
              -116.16146,
              46.35287
          ],
          [
              -116.16106,
              46.35263
          ],
          [
              -116.16098,
              46.35259
          ],
          [
              -116.16066,
              46.3524
          ],
          [
              -116.16047,
              46.3523
          ],
          [
              -116.1603,
              46.3522
          ],
          [
              -116.16005,
              46.35207
          ],
          [
              -116.15957,
              46.35179
          ],
          [
              -116.15874,
              46.35134
          ],
          [
              -116.1585,
              46.3512
          ],
          [
              -116.15836,
              46.35113
          ],
          [
              -116.15782,
              46.35082
          ],
          [
              -116.15781,
              46.35082
          ],
          [
              -116.1575,
              46.35063
          ],
          [
              -116.15726,
              46.35047
          ],
          [
              -116.15724,
              46.35045
          ],
          [
              -116.15715,
              46.35039
          ],
          [
              -116.1568,
              46.35013
          ],
          [
              -116.15671,
              46.35005
          ],
          [
              -116.15619,
              46.34964
          ],
          [
              -116.15545,
              46.34895
          ],
          [
              -116.15525,
              46.34873
          ],
          [
              -116.15514,
              46.34859
          ],
          [
              -116.15493,
              46.34835
          ],
          [
              -116.15478,
              46.34816
          ],
          [
              -116.15474,
              46.34809
          ],
          [
              -116.15439,
              46.34761
          ],
          [
              -116.15407,
              46.34707
          ],
          [
              -116.15399,
              46.3469
          ],
          [
              -116.15392,
              46.34678
          ],
          [
              -116.15383,
              46.34659
          ],
          [
              -116.15368,
              46.34622
          ],
          [
              -116.15367,
              46.34618
          ],
          [
              -116.15362,
              46.34604
          ],
          [
              -116.15358,
              46.34589
          ],
          [
              -116.15358,
              46.34587
          ],
          [
              -116.15356,
              46.3458
          ],
          [
              -116.15355,
              46.34572
          ],
          [
              -116.15351,
              46.34552
          ],
          [
              -116.15348,
              46.34516
          ],
          [
              -116.15347,
              46.34483
          ],
          [
              -116.15347,
              46.3442
          ],
          [
              -116.15346,
              46.34412
          ],
          [
              -116.15344,
              46.34403
          ],
          [
              -116.15343,
              46.34395
          ],
          [
              -116.15341,
              46.34389
          ],
          [
              -116.15341,
              46.34387
          ],
          [
              -116.15333,
              46.34362
          ],
          [
              -116.15324,
              46.34338
          ],
          [
              -116.1532,
              46.34329
          ],
          [
              -116.15317,
              46.34319
          ],
          [
              -116.15316,
              46.34317
          ],
          [
              -116.15315,
              46.3431
          ],
          [
              -116.15313,
              46.34305
          ],
          [
              -116.1531,
              46.34294
          ],
          [
              -116.1531,
              46.34291
          ],
          [
              -116.15309,
              46.34289
          ],
          [
              -116.15309,
              46.34287
          ],
          [
              -116.15308,
              46.34281
          ],
          [
              -116.15308,
              46.34278
          ],
          [
              -116.15307,
              46.34275
          ],
          [
              -116.1531,
              46.34217
          ],
          [
              -116.1531,
              46.3418
          ],
          [
              -116.15309,
              46.34171
          ],
          [
              -116.15309,
              46.34162
          ],
          [
              -116.15308,
              46.3416
          ],
          [
              -116.15307,
              46.34155
          ],
          [
              -116.15307,
              46.34153
          ],
          [
              -116.15303,
              46.3414
          ],
          [
              -116.153,
              46.34133
          ],
          [
              -116.15298,
              46.34127
          ],
          [
              -116.15284,
              46.34097
          ],
          [
              -116.15281,
              46.34093
          ],
          [
              -116.15262,
              46.34059
          ],
          [
              -116.15223,
              46.34
          ],
          [
              -116.15211,
              46.33985
          ],
          [
              -116.15206,
              46.3398
          ],
          [
              -116.152,
              46.33975
          ],
          [
              -116.15197,
              46.33972
          ],
          [
              -116.15168,
              46.33953
          ],
          [
              -116.15157,
              46.33947
          ],
          [
              -116.15147,
              46.33943
          ],
          [
              -116.1513,
              46.33935
          ],
          [
              -116.15048,
              46.33905
          ],
          [
              -116.15013,
              46.33889
          ],
          [
              -116.15012,
              46.33888
          ],
          [
              -116.15006,
              46.33885
          ],
          [
              -116.14989,
              46.33875
          ],
          [
              -116.1498,
              46.33868
          ],
          [
              -116.14975,
              46.33863
          ],
          [
              -116.14971,
              46.3386
          ],
          [
              -116.14961,
              46.33851
          ],
          [
              -116.1496,
              46.33849
          ],
          [
              -116.14945,
              46.33834
          ],
          [
              -116.14926,
              46.3381
          ],
          [
              -116.14921,
              46.33802
          ],
          [
              -116.1492,
              46.33802
          ],
          [
              -116.1491,
              46.33786
          ],
          [
              -116.14909,
              46.33785
          ],
          [
              -116.14905,
              46.33777
          ],
          [
              -116.14901,
              46.33771
          ],
          [
              -116.14899,
              46.33767
          ],
          [
              -116.14898,
              46.33764
          ],
          [
              -116.14897,
              46.33762
          ],
          [
              -116.14897,
              46.3376
          ],
          [
              -116.14896,
              46.33757
          ],
          [
              -116.14896,
              46.33756
          ],
          [
              -116.14895,
              46.33753
          ],
          [
              -116.14895,
              46.33748
          ],
          [
              -116.14894,
              46.33743
          ],
          [
              -116.14894,
              46.33726
          ],
          [
              -116.14898,
              46.33698
          ],
          [
              -116.14899,
              46.33696
          ],
          [
              -116.149,
              46.33691
          ],
          [
              -116.149,
              46.33684
          ],
          [
              -116.14901,
              46.33682
          ],
          [
              -116.14901,
              46.33658
          ],
          [
              -116.149,
              46.33656
          ],
          [
              -116.149,
              46.33651
          ],
          [
              -116.14899,
              46.33649
          ],
          [
              -116.14899,
              46.33646
          ],
          [
              -116.14898,
              46.33644
          ],
          [
              -116.14898,
              46.33643
          ],
          [
              -116.14893,
              46.33626
          ],
          [
              -116.14867,
              46.33551
          ],
          [
              -116.14862,
              46.33539
          ],
          [
              -116.14856,
              46.33521
          ],
          [
              -116.14847,
              46.33498
          ],
          [
              -116.14839,
              46.3348
          ],
          [
              -116.14835,
              46.33469
          ],
          [
              -116.14833,
              46.33466
          ],
          [
              -116.14826,
              46.33452
          ],
          [
              -116.14826,
              46.33451
          ],
          [
              -116.14818,
              46.33438
          ],
          [
              -116.14816,
              46.33436
          ],
          [
              -116.14808,
              46.33423
          ],
          [
              -116.14805,
              46.3342
          ],
          [
              -116.14794,
              46.33406
          ],
          [
              -116.14767,
              46.33377
          ],
          [
              -116.14748,
              46.33358
          ],
          [
              -116.14731,
              46.33344
          ],
          [
              -116.14696,
              46.33319
          ],
          [
              -116.14681,
              46.3331
          ],
          [
              -116.14676,
              46.33308
          ],
          [
              -116.14665,
              46.33301
          ],
          [
              -116.14655,
              46.33297
          ],
          [
              -116.1463,
              46.33283
          ],
          [
              -116.1461,
              46.33273
          ],
          [
              -116.14575,
              46.33251
          ],
          [
              -116.14573,
              46.3325
          ],
          [
              -116.14557,
              46.33238
          ],
          [
              -116.14553,
              46.33236
          ],
          [
              -116.14457,
              46.3316
          ],
          [
              -116.14454,
              46.33157
          ],
          [
              -116.14443,
              46.33148
          ],
          [
              -116.14423,
              46.33128
          ],
          [
              -116.14414,
              46.3312
          ],
          [
              -116.14408,
              46.33113
          ],
          [
              -116.14404,
              46.33109
          ],
          [
              -116.1439,
              46.33092
          ],
          [
              -116.14387,
              46.33086
          ],
          [
              -116.14384,
              46.33082
          ],
          [
              -116.14383,
              46.33081
          ],
          [
              -116.14364,
              46.33049
          ],
          [
              -116.14324,
              46.32987
          ],
          [
              -116.14253,
              46.32894
          ],
          [
              -116.1425,
              46.32888
          ],
          [
              -116.14249,
              46.32887
          ],
          [
              -116.14213,
              46.3282
          ],
          [
              -116.14205,
              46.32803
          ],
          [
              -116.14196,
              46.32786
          ],
          [
              -116.14196,
              46.32785
          ],
          [
              -116.14189,
              46.32768
          ],
          [
              -116.14188,
              46.32764
          ],
          [
              -116.14188,
              46.32761
          ],
          [
              -116.14186,
              46.32755
          ],
          [
              -116.14186,
              46.3275
          ],
          [
              -116.14185,
              46.32746
          ],
          [
              -116.14185,
              46.32741
          ],
          [
              -116.14184,
              46.32737
          ],
          [
              -116.14186,
              46.32638
          ],
          [
              -116.14185,
              46.32625
          ],
          [
              -116.14183,
              46.32612
          ],
          [
              -116.14178,
              46.32589
          ],
          [
              -116.14174,
              46.32576
          ],
          [
              -116.14173,
              46.32571
          ],
          [
              -116.14169,
              46.32561
          ],
          [
              -116.14164,
              46.32546
          ],
          [
              -116.14161,
              46.3254
          ],
          [
              -116.14159,
              46.32537
          ],
          [
              -116.14157,
              46.32532
          ],
          [
              -116.14154,
              46.32528
          ],
          [
              -116.14151,
              46.32523
          ],
          [
              -116.14145,
              46.32511
          ],
          [
              -116.14129,
              46.32487
          ],
          [
              -116.14129,
              46.32486
          ],
          [
              -116.14119,
              46.32471
          ],
          [
              -116.14118,
              46.3247
          ],
          [
              -116.14086,
              46.32421
          ],
          [
              -116.1408,
              46.32413
          ],
          [
              -116.14031,
              46.32337
          ],
          [
              -116.13904,
              46.32118
          ],
          [
              -116.13849,
              46.32028
          ],
          [
              -116.13843,
              46.32016
          ],
          [
              -116.13823,
              46.31969
          ],
          [
              -116.1382,
              46.3196
          ],
          [
              -116.13812,
              46.3194
          ],
          [
              -116.13803,
              46.31908
          ],
          [
              -116.13797,
              46.31892
          ],
          [
              -116.13794,
              46.31887
          ],
          [
              -116.13791,
              46.3188
          ],
          [
              -116.13785,
              46.31869
          ],
          [
              -116.13774,
              46.31845
          ],
          [
              -116.13764,
              46.3183
          ],
          [
              -116.13756,
              46.31816
          ],
          [
              -116.13675,
              46.31704
          ],
          [
              -116.13629,
              46.31632
          ],
          [
              -116.13596,
              46.31571
          ],
          [
              -116.13595,
              46.3157
          ],
          [
              -116.13583,
              46.31547
          ],
          [
              -116.1357,
              46.31518
          ],
          [
              -116.13559,
              46.31484
          ],
          [
              -116.13559,
              46.31482
          ],
          [
              -116.13556,
              46.31471
          ],
          [
              -116.13554,
              46.3146
          ],
          [
              -116.13551,
              46.31431
          ],
          [
              -116.13551,
              46.31425
          ],
          [
              -116.13549,
              46.31401
          ],
          [
              -116.13542,
              46.31363
          ],
          [
              -116.13532,
              46.31322
          ],
          [
              -116.13523,
              46.31294
          ],
          [
              -116.13521,
              46.31289
          ],
          [
              -116.1352,
              46.31284
          ],
          [
              -116.1351,
              46.31256
          ],
          [
              -116.13505,
              46.31246
          ],
          [
              -116.13499,
              46.31232
          ],
          [
              -116.13466,
              46.31169
          ],
          [
              -116.13466,
              46.31168
          ],
          [
              -116.13415,
              46.31076
          ],
          [
              -116.13378,
              46.31015
          ],
          [
              -116.13369,
              46.31002
          ],
          [
              -116.13306,
              46.30903
          ],
          [
              -116.13304,
              46.30901
          ],
          [
              -116.13292,
              46.30883
          ],
          [
              -116.13278,
              46.30866
          ],
          [
              -116.1324,
              46.30824
          ],
          [
              -116.1322,
              46.30806
          ],
          [
              -116.13109,
              46.307
          ],
          [
              -116.13018,
              46.30617
          ],
          [
              -116.13009,
              46.30608
          ],
          [
              -116.13003,
              46.30601
          ],
          [
              -116.12997,
              46.30595
          ],
          [
              -116.12971,
              46.30563
          ],
          [
              -116.12947,
              46.3053
          ],
          [
              -116.12934,
              46.30509
          ],
          [
              -116.1292,
              46.30482
          ],
          [
              -116.1291,
              46.30455
          ],
          [
              -116.12907,
              46.30445
          ],
          [
              -116.12867,
              46.30276
          ],
          [
              -116.12856,
              46.30211
          ],
          [
              -116.12838,
              46.30131
          ],
          [
              -116.12829,
              46.30098
          ],
          [
              -116.12815,
              46.30038
          ],
          [
              -116.12811,
              46.30025
          ],
          [
              -116.12808,
              46.3001
          ],
          [
              -116.12808,
              46.30004
          ],
          [
              -116.12807,
              46.29996
          ],
          [
              -116.12807,
              46.29989
          ],
          [
              -116.1281,
              46.29974
          ],
          [
              -116.1281,
              46.29973
          ],
          [
              -116.12824,
              46.29929
          ],
          [
              -116.1283,
              46.29899
          ],
          [
              -116.1283,
              46.29895
          ],
          [
              -116.12831,
              46.29891
          ],
          [
              -116.12831,
              46.29888
          ],
          [
              -116.12832,
              46.29884
          ],
          [
              -116.12832,
              46.29858
          ],
          [
              -116.12827,
              46.29834
          ],
          [
              -116.12818,
              46.29811
          ],
          [
              -116.12815,
              46.29807
          ],
          [
              -116.12811,
              46.29799
          ],
          [
              -116.12787,
              46.29769
          ],
          [
              -116.1278,
              46.29763
          ],
          [
              -116.12776,
              46.29758
          ],
          [
              -116.1277,
              46.29752
          ],
          [
              -116.12766,
              46.2975
          ],
          [
              -116.12761,
              46.29746
          ],
          [
              -116.12757,
              46.29742
          ],
          [
              -116.12752,
              46.29739
          ],
          [
              -116.12745,
              46.29733
          ],
          [
              -116.12725,
              46.29721
          ],
          [
              -116.1271,
              46.29711
          ],
          [
              -116.12618,
              46.29658
          ],
          [
              -116.1253,
              46.29604
          ],
          [
              -116.12521,
              46.29597
          ],
          [
              -116.12515,
              46.29593
          ],
          [
              -116.12501,
              46.29582
          ],
          [
              -116.12372,
              46.29447
          ],
          [
              -116.1236,
              46.29436
          ],
          [
              -116.12334,
              46.29416
          ],
          [
              -116.12229,
              46.29343
          ],
          [
              -116.1221,
              46.29331
          ],
          [
              -116.12207,
              46.29328
          ],
          [
              -116.1213,
              46.29272
          ],
          [
              -116.12092,
              46.29241
          ],
          [
              -116.12063,
              46.29213
          ],
          [
              -116.12049,
              46.29195
          ],
          [
              -116.12033,
              46.29177
          ],
          [
              -116.12032,
              46.29177
          ],
          [
              -116.12008,
              46.29153
          ],
          [
              -116.11996,
              46.29142
          ],
          [
              -116.1194,
              46.29102
          ],
          [
              -116.1192,
              46.29086
          ],
          [
              -116.11906,
              46.29072
          ],
          [
              -116.11879,
              46.29041
          ],
          [
              -116.11846,
              46.28996
          ],
          [
              -116.11827,
              46.28972
          ],
          [
              -116.1179,
              46.28931
          ],
          [
              -116.11784,
              46.28926
          ],
          [
              -116.11772,
              46.28914
          ],
          [
              -116.11717,
              46.28868
          ],
          [
              -116.11651,
              46.28819
          ],
          [
              -116.1163,
              46.28802
          ],
          [
              -116.116,
              46.28774
          ],
          [
              -116.1159,
              46.2876
          ],
          [
              -116.11578,
              46.28745
          ],
          [
              -116.11567,
              46.28725
          ],
          [
              -116.11555,
              46.28699
          ],
          [
              -116.11532,
              46.28639
          ],
          [
              -116.11531,
              46.28638
          ],
          [
              -116.11522,
              46.28614
          ],
          [
              -116.11517,
              46.28604
          ],
          [
              -116.11516,
              46.28601
          ],
          [
              -116.11509,
              46.28588
          ],
          [
              -116.11495,
              46.28568
          ],
          [
              -116.11479,
              46.28551
          ],
          [
              -116.11469,
              46.28542
          ],
          [
              -116.11451,
              46.28529
          ],
          [
              -116.11426,
              46.28516
          ],
          [
              -116.11354,
              46.28485
          ],
          [
              -116.11312,
              46.28469
          ],
          [
              -116.11274,
              46.28457
          ],
          [
              -116.11197,
              46.28438
          ],
          [
              -116.11162,
              46.28428
          ],
          [
              -116.11085,
              46.284
          ],
          [
              -116.1108,
              46.28399
          ],
          [
              -116.11006,
              46.28366
          ],
          [
              -116.10932,
              46.28326
          ],
          [
              -116.10826,
              46.28261
          ],
          [
              -116.1079,
              46.28241
          ],
          [
              -116.1075,
              46.28222
          ],
          [
              -116.10709,
              46.28205
          ],
          [
              -116.10666,
              46.2819
          ],
          [
              -116.1066,
              46.28189
          ],
          [
              -116.10627,
              46.2818
          ],
          [
              -116.10594,
              46.28168
          ],
          [
              -116.10558,
              46.28153
          ],
          [
              -116.10522,
              46.28136
          ],
          [
              -116.10492,
              46.2812
          ],
          [
              -116.10458,
              46.28099
          ],
          [
              -116.10428,
              46.28077
          ],
          [
              -116.10406,
              46.28058
          ],
          [
              -116.10387,
              46.28039
          ],
          [
              -116.10333,
              46.2798
          ],
          [
              -116.10291,
              46.27938
          ],
          [
              -116.10273,
              46.27922
          ],
          [
              -116.10227,
              46.27889
          ],
          [
              -116.10093,
              46.27802
          ],
          [
              -116.10028,
              46.27756
          ],
          [
              -116.09874,
              46.27658
          ],
          [
              -116.09832,
              46.27633
          ],
          [
              -116.09796,
              46.2761
          ],
          [
              -116.09745,
              46.27575
          ],
          [
              -116.09699,
              46.2754
          ],
          [
              -116.09676,
              46.27521
          ],
          [
              -116.09664,
              46.27509
          ],
          [
              -116.09654,
              46.27497
          ],
          [
              -116.09641,
              46.27479
          ],
          [
              -116.09584,
              46.27353
          ],
          [
              -116.09478,
              46.27083
          ],
          [
              -116.09472,
              46.27064
          ],
          [
              -116.0946,
              46.27015
          ],
          [
              -116.09458,
              46.27009
          ],
          [
              -116.09443,
              46.2691
          ],
          [
              -116.09435,
              46.2688
          ],
          [
              -116.09432,
              46.26871
          ],
          [
              -116.09427,
              46.2686
          ],
          [
              -116.09419,
              46.26848
          ],
          [
              -116.09405,
              46.26832
          ],
          [
              -116.09397,
              46.26824
          ],
          [
              -116.09393,
              46.26819
          ],
          [
              -116.09361,
              46.26786
          ],
          [
              -116.09349,
              46.26772
          ],
          [
              -116.09321,
              46.2673
          ],
          [
              -116.09277,
              46.2664
          ],
          [
              -116.09262,
              46.26616
          ],
          [
              -116.09251,
              46.26603
          ],
          [
              -116.09214,
              46.26567
          ],
          [
              -116.09135,
              46.26496
          ],
          [
              -116.09035,
              46.26413
          ],
          [
              -116.08981,
              46.26378
          ],
          [
              -116.08959,
              46.26365
          ],
          [
              -116.08936,
              46.26353
          ],
          [
              -116.08814,
              46.26278
          ],
          [
              -116.08772,
              46.26249
          ],
          [
              -116.08713,
              46.26211
          ],
          [
              -116.08638,
              46.26173
          ],
          [
              -116.08573,
              46.26147
          ],
          [
              -116.08475,
              46.26115
          ],
          [
              -116.08407,
              46.26098
          ],
          [
              -116.08374,
              46.26091
          ],
          [
              -116.08293,
              46.26078
          ],
          [
              -116.08278,
              46.26077
          ],
          [
              -116.08218,
              46.26068
          ],
          [
              -116.08165,
              46.26058
          ],
          [
              -116.08121,
              46.26046
          ],
          [
              -116.08005,
              46.26003
          ],
          [
              -116.07919,
              46.25965
          ],
          [
              -116.07845,
              46.25926
          ],
          [
              -116.07806,
              46.25902
          ],
          [
              -116.07749,
              46.25863
          ],
          [
              -116.07693,
              46.25819
          ],
          [
              -116.07624,
              46.2576
          ],
          [
              -116.07606,
              46.25741
          ],
          [
              -116.07575,
              46.25703
          ],
          [
              -116.07554,
              46.25672
          ],
          [
              -116.07545,
              46.25661
          ],
          [
              -116.07524,
              46.25643
          ],
          [
              -116.07495,
              46.25625
          ],
          [
              -116.07476,
              46.25616
          ],
          [
              -116.07457,
              46.25609
          ],
          [
              -116.07418,
              46.25599
          ],
          [
              -116.07206,
              46.25563
          ],
          [
              -116.07167,
              46.25558
          ],
          [
              -116.07143,
              46.25557
          ],
          [
              -116.07098,
              46.25557
          ],
          [
              -116.07097,
              46.25558
          ],
          [
              -116.07049,
              46.25559
          ],
          [
              -116.06875,
              46.25571
          ],
          [
              -116.06792,
              46.25575
          ],
          [
              -116.06768,
              46.25575
          ],
          [
              -116.0671,
              46.25572
          ],
          [
              -116.06665,
              46.25567
          ],
          [
              -116.06602,
              46.25558
          ],
          [
              -116.06553,
              46.25549
          ],
          [
              -116.06524,
              46.25542
          ],
          [
              -116.06485,
              46.25534
          ],
          [
              -116.06415,
              46.25517
          ],
          [
              -116.0641,
              46.25515
          ],
          [
              -116.06252,
              46.25474
          ],
          [
              -116.06155,
              46.25445
          ],
          [
              -116.06116,
              46.25431
          ],
          [
              -116.06109,
              46.25427
          ],
          [
              -116.06101,
              46.25424
          ],
          [
              -116.06076,
              46.2541
          ],
          [
              -116.06021,
              46.25383
          ],
          [
              -116.06,
              46.25375
          ],
          [
              -116.05964,
              46.25363
          ],
          [
              -116.05879,
              46.25341
          ],
          [
              -116.0575,
              46.25312
          ],
          [
              -116.05697,
              46.25302
          ],
          [
              -116.05566,
              46.25282
          ],
          [
              -116.05492,
              46.25273
          ],
          [
              -116.05445,
              46.2527
          ],
          [
              -116.05419,
              46.2527
          ],
          [
              -116.05401,
              46.25271
          ],
          [
              -116.05234,
              46.25273
          ],
          [
              -116.05132,
              46.25271
          ],
          [
              -116.0508,
              46.25268
          ],
          [
              -116.05053,
              46.25265
          ],
          [
              -116.05044,
              46.25265
          ],
          [
              -116.04941,
              46.25252
          ],
          [
              -116.04886,
              46.25242
          ],
          [
              -116.04842,
              46.25232
          ],
          [
              -116.04821,
              46.25226
          ],
          [
              -116.04796,
              46.2522
          ],
          [
              -116.04736,
              46.25202
          ],
          [
              -116.0464,
              46.25168
          ],
          [
              -116.04602,
              46.25151
          ],
          [
              -116.0456,
              46.2513
          ],
          [
              -116.04538,
              46.25117
          ],
          [
              -116.04497,
              46.25095
          ],
          [
              -116.04483,
              46.25086
          ],
          [
              -116.04379,
              46.25026
          ],
          [
              -116.0436,
              46.25012
          ],
          [
              -116.04358,
              46.2501
          ],
          [
              -116.04347,
              46.25002
          ],
          [
              -116.04344,
              46.24998
          ],
          [
              -116.04322,
              46.24976
          ],
          [
              -116.0425,
              46.24886
          ],
          [
              -116.04234,
              46.24863
          ],
          [
              -116.04226,
              46.24848
          ],
          [
              -116.04223,
              46.24838
          ],
          [
              -116.0422,
              46.2483
          ],
          [
              -116.04217,
              46.24812
          ],
          [
              -116.04215,
              46.24784
          ],
          [
              -116.04215,
              46.24773
          ],
          [
              -116.04216,
              46.24761
          ],
          [
              -116.04217,
              46.2471
          ],
          [
              -116.04214,
              46.24682
          ],
          [
              -116.04205,
              46.24636
          ],
          [
              -116.04195,
              46.2461
          ],
          [
              -116.04187,
              46.24593
          ],
          [
              -116.04186,
              46.24592
          ],
          [
              -116.04172,
              46.24569
          ],
          [
              -116.04166,
              46.24561
          ],
          [
              -116.04146,
              46.24528
          ],
          [
              -116.04137,
              46.2451
          ],
          [
              -116.04136,
              46.24509
          ],
          [
              -116.04134,
              46.24504
          ],
          [
              -116.04131,
              46.24498
          ],
          [
              -116.04128,
              46.24473
          ],
          [
              -116.04128,
              46.24458
          ],
          [
              -116.04138,
              46.24309
          ],
          [
              -116.0414,
              46.24295
          ],
          [
              -116.04141,
              46.2428
          ],
          [
              -116.04141,
              46.24212
          ],
          [
              -116.04132,
              46.24097
          ],
          [
              -116.0413,
              46.24085
          ],
          [
              -116.04116,
              46.2402
          ],
          [
              -116.04107,
              46.23995
          ],
          [
              -116.04095,
              46.23972
          ],
          [
              -116.04073,
              46.23937
          ],
          [
              -116.04028,
              46.23873
          ],
          [
              -116.04001,
              46.23832
          ],
          [
              -116.03994,
              46.23819
          ],
          [
              -116.03984,
              46.23793
          ],
          [
              -116.03981,
              46.23781
          ],
          [
              -116.03978,
              46.23773
          ],
          [
              -116.03973,
              46.23746
          ],
          [
              -116.03972,
              46.23731
          ],
          [
              -116.03973,
              46.23461
          ],
          [
              -116.03972,
              46.23444
          ],
          [
              -116.03969,
              46.23419
          ],
          [
              -116.03965,
              46.23399
          ],
          [
              -116.03956,
              46.23373
          ],
          [
              -116.03951,
              46.23361
          ],
          [
              -116.03936,
              46.23333
          ],
          [
              -116.03926,
              46.23319
          ],
          [
              -116.03922,
              46.23312
          ],
          [
              -116.03914,
              46.23301
          ],
          [
              -116.03891,
              46.23274
          ],
          [
              -116.03862,
              46.23246
          ],
          [
              -116.03802,
              46.23203
          ],
          [
              -116.03791,
              46.23197
          ],
          [
              -116.03782,
              46.23191
          ],
          [
              -116.03696,
              46.23149
          ],
          [
              -116.03587,
              46.231
          ],
          [
              -116.03565,
              46.23089
          ],
          [
              -116.03304,
              46.2297
          ],
          [
              -116.03265,
              46.22954
          ],
          [
              -116.03255,
              46.22951
          ],
          [
              -116.03245,
              46.22947
          ],
          [
              -116.03175,
              46.22927
          ],
          [
              -116.03144,
              46.2292
          ],
          [
              -116.03116,
              46.22915
          ],
          [
              -116.03085,
              46.22911
          ],
          [
              -116.03014,
              46.22906
          ],
          [
              -116.02925,
              46.22905
          ],
          [
              -116.02926,
              46.22887
          ],
          [
              -116.02927,
              46.22712
          ],
          [
              -116.02926,
              46.22887
          ],
          [
              -116.02925,
              46.22905
          ],
          [
              -116.02261,
              46.22902
          ],
          [
              -116.02253,
              46.22903
          ],
          [
              -116.02245,
              46.22903
          ],
          [
              -116.02238,
              46.22904
          ],
          [
              -116.02232,
              46.22904
          ],
          [
              -116.02214,
              46.22906
          ],
          [
              -116.02195,
              46.22909
          ],
          [
              -116.02191,
              46.2291
          ],
          [
              -116.02182,
              46.22911
          ],
          [
              -116.02156,
              46.22916
          ],
          [
              -116.02138,
              46.22921
          ],
          [
              -116.02133,
              46.22922
          ],
          [
              -116.02113,
              46.22929
          ],
          [
              -116.02079,
              46.22943
          ],
          [
              -116.02075,
              46.22945
          ],
          [
              -116.02047,
              46.22956
          ],
          [
              -116.02025,
              46.22966
          ],
          [
              -116.02012,
              46.2297
          ],
          [
              -116.01764,
              46.23074
          ],
          [
              -116.01744,
              46.23081
          ],
          [
              -116.01718,
              46.23087
          ],
          [
              -116.01696,
              46.2309
          ],
          [
              -116.01694,
              46.2309
          ],
          [
              -116.01687,
              46.23091
          ],
          [
              -116.01642,
              46.23091
          ],
          [
              -116.01626,
              46.23089
          ],
          [
              -116.01599,
              46.23083
          ],
          [
              -116.01569,
              46.23072
          ],
          [
              -116.01551,
              46.23064
          ],
          [
              -116.01539,
              46.23057
          ],
          [
              -116.01519,
              46.23043
          ],
          [
              -116.01439,
              46.22959
          ],
          [
              -116.01413,
              46.22934
          ],
          [
              -116.01309,
              46.22818
          ],
          [
              -116.01285,
              46.22789
          ],
          [
              -116.01215,
              46.22697
          ],
          [
              -116.01152,
              46.2261
          ],
          [
              -116.01093,
              46.22517
          ],
          [
              -116.00888,
              46.22165
          ],
          [
              -116.00884,
              46.22159
          ],
          [
              -116.00629,
              46.21708
          ],
          [
              -116.00566,
              46.21601
          ],
          [
              -116.00529,
              46.21525
          ],
          [
              -116.00523,
              46.21508
          ],
          [
              -116.00522,
              46.21501
          ],
          [
              -116.0052,
              46.21495
          ],
          [
              -116.00512,
              46.21439
          ],
          [
              -116.0051,
              46.21405
          ],
          [
              -116.00509,
              46.2112
          ],
          [
              -116.00506,
              46.20976
          ],
          [
              -116.00506,
              46.20887
          ],
          [
              -116.00507,
              46.20874
          ],
          [
              -116.00507,
              46.20862
          ],
          [
              -116.00508,
              46.20855
          ],
          [
              -116.00508,
              46.20848
          ],
          [
              -116.00509,
              46.20842
          ],
          [
              -116.00523,
              46.20784
          ],
          [
              -116.00543,
              46.20733
          ],
          [
              -116.00556,
              46.2071
          ],
          [
              -116.00606,
              46.20607
          ],
          [
              -116.00686,
              46.20453
          ],
          [
              -116.00725,
              46.20371
          ],
          [
              -116.00843,
              46.20139
          ],
          [
              -116.00855,
              46.20118
          ],
          [
              -116.01016,
              46.19795
          ],
          [
              -116.01106,
              46.19622
          ],
          [
              -116.01137,
              46.19559
          ],
          [
              -116.01153,
              46.19523
          ],
          [
              -116.01165,
              46.19483
          ],
          [
              -116.0117,
              46.19463
          ],
          [
              -116.01173,
              46.19427
          ],
          [
              -116.01173,
              46.194
          ],
          [
              -116.0117,
              46.19369
          ],
          [
              -116.01169,
              46.19366
          ],
          [
              -116.01167,
              46.19355
          ],
          [
              -116.01155,
              46.19316
          ],
          [
              -116.01149,
              46.19301
          ],
          [
              -116.01133,
              46.19269
          ],
          [
              -116.01125,
              46.19255
          ],
          [
              -116.0111,
              46.19232
          ],
          [
              -116.01085,
              46.19199
          ],
          [
              -116.01036,
              46.19151
          ],
          [
              -116.01026,
              46.19143
          ],
          [
              -116.00988,
              46.19117
          ],
          [
              -116.00984,
              46.19115
          ],
          [
              -116.00821,
              46.19006
          ],
          [
              -116.00763,
              46.18965
          ],
          [
              -116.00743,
              46.18949
          ],
          [
              -116.00662,
              46.18879
          ],
          [
              -116.00521,
              46.18748
          ],
          [
              -116.00454,
              46.18688
          ],
          [
              -116.00441,
              46.18675
          ],
          [
              -116.00191,
              46.18444
          ],
          [
              -116.00169,
              46.18425
          ],
          [
              -116.00144,
              46.18401
          ],
          [
              -116.00117,
              46.18373
          ],
          [
              -116.00096,
              46.18341
          ],
          [
              -116.00088,
              46.18326
          ],
          [
              -116.0008,
              46.18309
          ],
          [
              -116.00065,
              46.18263
          ],
          [
              -116.00045,
              46.18182
          ],
          [
              -116.00045,
              46.1818
          ],
          [
              -116.00019,
              46.18037
          ],
          [
              -116.00008,
              46.17984
          ],
          [
              -116.00006,
              46.1797
          ],
          [
              -115.99994,
              46.17913
          ],
          [
              -115.99989,
              46.17879
          ],
          [
              -115.99988,
              46.17859
          ],
          [
              -115.99989,
              46.17842
          ],
          [
              -115.99989,
              46.17826
          ],
          [
              -115.99993,
              46.17794
          ],
          [
              -115.99995,
              46.17784
          ],
          [
              -115.99999,
              46.17772
          ],
          [
              -116.00005,
              46.17758
          ],
          [
              -116.00008,
              46.17753
          ],
          [
              -116.00011,
              46.17746
          ],
          [
              -116.00018,
              46.17734
          ],
          [
              -116.00043,
              46.177
          ],
          [
              -116.00073,
              46.17664
          ],
          [
              -116.001,
              46.17629
          ],
          [
              -116.00106,
              46.17622
          ],
          [
              -116.00151,
              46.1756
          ],
          [
              -116.00165,
              46.17533
          ],
          [
              -116.00171,
              46.1752
          ],
          [
              -116.00178,
              46.17498
          ],
          [
              -116.00182,
              46.17479
          ],
          [
              -116.00187,
              46.17446
          ],
          [
              -116.00188,
              46.17427
          ],
          [
              -116.00187,
              46.17415
          ],
          [
              -116.00183,
              46.17388
          ],
          [
              -116.00179,
              46.17377
          ],
          [
              -116.0017,
              46.17356
          ],
          [
              -116.00159,
              46.17334
          ],
          [
              -116.00148,
              46.17315
          ],
          [
              -116.00132,
              46.17293
          ],
          [
              -116.00121,
              46.17281
          ],
          [
              -116.00086,
              46.17247
          ],
          [
              -115.99979,
              46.17165
          ],
          [
              -115.99947,
              46.17142
          ],
          [
              -115.99912,
              46.17112
          ],
          [
              -115.99818,
              46.1702
          ],
          [
              -115.99778,
              46.16978
          ],
          [
              -115.9971,
              46.16899
          ],
          [
              -115.99684,
              46.16866
          ],
          [
              -115.99634,
              46.16797
          ],
          [
              -115.99588,
              46.16729
          ],
          [
              -115.99533,
              46.16654
          ],
          [
              -115.99495,
              46.16606
          ],
          [
              -115.9948,
              46.16589
          ],
          [
              -115.99465,
              46.16575
          ],
          [
              -115.99437,
              46.16552
          ],
          [
              -115.99417,
              46.16537
          ],
          [
              -115.99399,
              46.16525
          ],
          [
              -115.99398,
              46.16524
          ],
          [
              -115.99362,
              46.16502
          ],
          [
              -115.99361,
              46.16501
          ],
          [
              -115.99288,
              46.1646
          ],
          [
              -115.99252,
              46.16438
          ],
          [
              -115.99227,
              46.16424
          ],
          [
              -115.99174,
              46.1639
          ],
          [
              -115.99108,
              46.16342
          ],
          [
              -115.99068,
              46.16309
          ],
          [
              -115.99052,
              46.16294
          ],
          [
              -115.99039,
              46.16283
          ],
          [
              -115.98995,
              46.1624
          ],
          [
              -115.98965,
              46.16206
          ],
          [
              -115.98936,
              46.16168
          ],
          [
              -115.98933,
              46.16163
          ],
          [
              -115.98929,
              46.16158
          ],
          [
              -115.98921,
              46.16145
          ],
          [
              -115.98918,
              46.16137
          ],
          [
              -115.9891,
              46.16119
          ],
          [
              -115.98903,
              46.16095
          ],
          [
              -115.989,
              46.16079
          ],
          [
              -115.98897,
              46.1605
          ],
          [
              -115.98896,
              46.16044
          ],
          [
              -115.98896,
              46.16037
          ],
          [
              -115.989,
              46.16008
          ],
          [
              -115.989,
              46.16006
          ],
          [
              -115.98907,
              46.15982
          ],
          [
              -115.98911,
              46.15971
          ],
          [
              -115.98913,
              46.15964
          ],
          [
              -115.9892,
              46.15946
          ],
          [
              -115.98937,
              46.15909
          ],
          [
              -115.9895,
              46.15885
          ],
          [
              -115.9895,
              46.15884
          ],
          [
              -115.98965,
              46.15858
          ],
          [
              -115.98982,
              46.15834
          ],
          [
              -115.99025,
              46.15781
          ],
          [
              -115.99056,
              46.1575
          ],
          [
              -115.9911,
              46.15701
          ],
          [
              -115.99119,
              46.15692
          ],
          [
              -115.99272,
              46.1556
          ],
          [
              -115.99305,
              46.15533
          ],
          [
              -115.99435,
              46.15421
          ],
          [
              -115.9945,
              46.15409
          ],
          [
              -115.99486,
              46.15372
          ],
          [
              -115.99506,
              46.15346
          ],
          [
              -115.99507,
              46.15344
          ],
          [
              -115.99517,
              46.15329
          ],
          [
              -115.99539,
              46.15283
          ],
          [
              -115.99545,
              46.15267
          ],
          [
              -115.99553,
              46.15234
          ],
          [
              -115.99556,
              46.15205
          ],
          [
              -115.99556,
              46.152
          ],
          [
              -115.99557,
              46.15194
          ],
          [
              -115.99555,
              46.15178
          ],
          [
              -115.9955,
              46.15153
          ],
          [
              -115.99545,
              46.15136
          ],
          [
              -115.99541,
              46.15119
          ],
          [
              -115.9954,
              46.15118
          ],
          [
              -115.99533,
              46.15097
          ],
          [
              -115.99515,
              46.15062
          ],
          [
              -115.99498,
              46.15038
          ],
          [
              -115.99494,
              46.15034
          ],
          [
              -115.99482,
              46.15019
          ],
          [
              -115.99476,
              46.15013
          ],
          [
              -115.9947,
              46.15006
          ],
          [
              -115.99457,
              46.14995
          ],
          [
              -115.99418,
              46.14965
          ],
          [
              -115.99397,
              46.14951
          ],
          [
              -115.99396,
              46.1495
          ],
          [
              -115.99378,
              46.14938
          ],
          [
              -115.99186,
              46.14821
          ],
          [
              -115.99171,
              46.14813
          ],
          [
              -115.99162,
              46.14809
          ],
          [
              -115.99155,
              46.14805
          ],
          [
              -115.99141,
              46.14799
          ],
          [
              -115.99134,
              46.14795
          ],
          [
              -115.99125,
              46.14792
          ],
          [
              -115.99109,
              46.14785
          ],
          [
              -115.9907,
              46.14771
          ],
          [
              -115.9905,
              46.14765
          ],
          [
              -115.99006,
              46.14755
          ],
          [
              -115.98994,
              46.14753
          ],
          [
              -115.98982,
              46.1475
          ],
          [
              -115.98941,
              46.14744
          ],
          [
              -115.98898,
              46.1474
          ],
          [
              -115.98895,
              46.14739
          ],
          [
              -115.98854,
              46.14737
          ],
          [
              -115.98813,
              46.14737
          ],
          [
              -115.98766,
              46.1474
          ],
          [
              -115.9864,
              46.14753
          ],
          [
              -115.98472,
              46.14774
          ],
          [
              -115.98403,
              46.14788
          ],
          [
              -115.98317,
              46.14811
          ],
          [
              -115.98295,
              46.14818
          ],
          [
              -115.98293,
              46.14818
          ],
          [
              -115.98255,
              46.1483
          ],
          [
              -115.98226,
              46.1483
          ],
          [
              -115.98216,
              46.14829
          ],
          [
              -115.98213,
              46.14829
          ],
          [
              -115.98176,
              46.1482
          ],
          [
              -115.9811,
              46.14768
          ],
          [
              -115.98096,
              46.14758
          ],
          [
              -115.98062,
              46.14731
          ],
          [
              -115.98046,
              46.1472
          ],
          [
              -115.97885,
              46.14593
          ],
          [
              -115.9787,
              46.14579
          ],
          [
              -115.9786,
              46.14564
          ],
          [
              -115.97855,
              46.14553
          ],
          [
              -115.97841,
              46.14507
          ],
          [
              -115.97839,
              46.14489
          ],
          [
              -115.97811,
              46.14493
          ],
          [
              -115.97807,
              46.14493
          ],
          [
              -115.97793,
              46.14495
          ],
          [
              -115.97807,
              46.14493
          ],
          [
              -115.97811,
              46.14493
          ],
          [
              -115.97839,
              46.14489
          ],
          [
              -115.97839,
              46.14462
          ],
          [
              -115.97843,
              46.14446
          ],
          [
              -115.97848,
              46.14436
          ],
          [
              -115.97877,
              46.14391
          ],
          [
              -115.97887,
              46.14374
          ],
          [
              -115.97894,
              46.14356
          ],
          [
              -115.979,
              46.14332
          ],
          [
              -115.97904,
              46.14309
          ],
          [
              -115.97904,
              46.14277
          ],
          [
              -115.97905,
              46.14269
          ],
          [
              -115.97865,
              46.13671
          ],
          [
              -115.97834,
              46.13536
          ],
          [
              -115.97808,
              46.13397
          ],
          [
              -115.97759,
              46.13178
          ],
          [
              -115.97753,
              46.13157
          ],
          [
              -115.97739,
              46.13098
          ],
          [
              -115.97728,
              46.13034
          ],
          [
              -115.97718,
              46.13
          ],
          [
              -115.97712,
              46.12968
          ],
          [
              -115.97708,
              46.12926
          ],
          [
              -115.97706,
              46.12888
          ],
          [
              -115.97705,
              46.12809
          ],
          [
              -115.97708,
              46.12647
          ],
          [
              -115.97711,
              46.12572
          ],
          [
              -115.97711,
              46.1249
          ],
          [
              -115.97716,
              46.12327
          ],
          [
              -115.97716,
              46.12261
          ],
          [
              -115.97723,
              46.11888
          ],
          [
              -115.97721,
              46.11854
          ],
          [
              -115.97714,
              46.11818
          ],
          [
              -115.97691,
              46.11729
          ],
          [
              -115.97691,
              46.11728
          ],
          [
              -115.97687,
              46.11706
          ],
          [
              -115.97686,
              46.1169
          ],
          [
              -115.97687,
              46.11657
          ],
          [
              -115.97689,
              46.1164
          ],
          [
              -115.97698,
              46.11599
          ],
          [
              -115.97716,
              46.11555
          ],
          [
              -115.97816,
              46.11357
          ],
          [
              -115.97851,
              46.1128
          ],
          [
              -115.97932,
              46.11071
          ],
          [
              -115.97944,
              46.11044
          ],
          [
              -115.97954,
              46.11013
          ],
          [
              -115.97957,
              46.10999
          ],
          [
              -115.97959,
              46.10984
          ],
          [
              -115.97959,
              46.1097
          ],
          [
              -115.97953,
              46.10945
          ],
          [
              -115.97949,
              46.10933
          ],
          [
              -115.97938,
              46.10909
          ],
          [
              -115.97923,
              46.1087
          ],
          [
              -115.97921,
              46.10863
          ],
          [
              -115.97914,
              46.10845
          ],
          [
              -115.97902,
              46.108
          ],
          [
              -115.97887,
              46.10725
          ],
          [
              -115.97877,
              46.10662
          ],
          [
              -115.97869,
              46.10623
          ],
          [
              -115.97864,
              46.10591
          ],
          [
              -115.97791,
              46.10198
          ],
          [
              -115.97791,
              46.10197
          ],
          [
              -115.97787,
              46.10182
          ],
          [
              -115.97786,
              46.10181
          ],
          [
              -115.97783,
              46.10172
          ],
          [
              -115.97773,
              46.10151
          ],
          [
              -115.97733,
              46.10093
          ],
          [
              -115.97729,
              46.10089
          ],
          [
              -115.97715,
              46.10066
          ],
          [
              -115.97706,
              46.10049
          ],
          [
              -115.97695,
              46.1
          ],
          [
              -115.97692,
              46.0998
          ],
          [
              -115.9769,
              46.09959
          ],
          [
              -115.9769,
              46.09956
          ],
          [
              -115.97687,
              46.09936
          ],
          [
              -115.97665,
              46.09729
          ],
          [
              -115.97664,
              46.09711
          ],
          [
              -115.97663,
              46.09708
          ],
          [
              -115.97658,
              46.09632
          ],
          [
              -115.9766,
              46.09613
          ],
          [
              -115.9766,
              46.09596
          ],
          [
              -115.97661,
              46.09593
          ],
          [
              -115.97663,
              46.09559
          ],
          [
              -115.97663,
              46.09549
          ],
          [
              -115.97664,
              46.09539
          ],
          [
              -115.97664,
              46.09522
          ],
          [
              -115.97663,
              46.09517
          ],
          [
              -115.97663,
              46.09516
          ],
          [
              -115.97661,
              46.09506
          ],
          [
              -115.97658,
              46.09495
          ],
          [
              -115.97651,
              46.09474
          ],
          [
              -115.97647,
              46.09466
          ],
          [
              -115.97643,
              46.09451
          ],
          [
              -115.9764,
              46.09442
          ],
          [
              -115.97636,
              46.09422
          ],
          [
              -115.97633,
              46.09402
          ],
          [
              -115.97633,
              46.09393
          ],
          [
              -115.97632,
              46.09384
          ],
          [
              -115.97631,
              46.09365
          ],
          [
              -115.97631,
              46.09344
          ],
          [
              -115.97625,
              46.0916
          ],
          [
              -115.97679,
              46.09157
          ],
          [
              -115.97703,
              46.09157
          ],
          [
              -115.97725,
              46.09152
          ],
          [
              -115.9773,
              46.0915
          ],
          [
              -115.97753,
              46.09144
          ],
          [
              -115.97754,
              46.09144
          ],
          [
              -115.97785,
              46.09135
          ],
          [
              -115.97818,
              46.09123
          ],
          [
              -115.97826,
              46.09118
          ],
          [
              -115.9781,
              46.09087
          ],
          [
              -115.9776,
              46.09018
          ],
          [
              -115.97759,
              46.09016
          ],
          [
              -115.97754,
              46.0901
          ],
          [
              -115.97726,
              46.08942
          ],
          [
              -115.9771,
              46.0885
          ],
          [
              -115.97699,
              46.08805
          ],
          [
              -115.97696,
              46.08795
          ],
          [
              -115.97692,
              46.08775
          ],
          [
              -115.97682,
              46.08698
          ],
          [
              -115.97681,
              46.08686
          ],
          [
              -115.97681,
              46.08679
          ],
          [
              -115.97686,
              46.08595
          ],
          [
              -115.97689,
              46.08564
          ],
          [
              -115.97693,
              46.08555
          ],
          [
              -115.97693,
              46.08554
          ],
          [
              -115.97698,
              46.0854
          ],
          [
              -115.97731,
              46.08432
          ],
          [
              -115.9777,
              46.0835
          ],
          [
              -115.97807,
              46.08282
          ],
          [
              -115.97809,
              46.08279
          ],
          [
              -115.97842,
              46.08217
          ],
          [
              -115.9786,
              46.08193
          ],
          [
              -115.97919,
              46.08153
          ],
          [
              -115.9793,
              46.08148
          ],
          [
              -115.97894,
              46.08085
          ],
          [
              -115.97884,
              46.08069
          ],
          [
              -115.9786,
              46.08017
          ],
          [
              -115.97859,
              46.08014
          ],
          [
              -115.9784,
              46.07925
          ],
          [
              -115.9783,
              46.0786
          ],
          [
              -115.97804,
              46.07811
          ],
          [
              -115.97766,
              46.0775
          ],
          [
              -115.97698,
              46.07684
          ],
          [
              -115.97685,
              46.0767
          ],
          [
              -115.97488,
              46.07441
          ],
          [
              -115.97437,
              46.07359
          ],
          [
              -115.97424,
              46.07312
          ],
          [
              -115.97423,
              46.0729
          ],
          [
              -115.97425,
              46.07267
          ],
          [
              -115.97428,
              46.07245
          ],
          [
              -115.97437,
              46.07199
          ],
          [
              -115.97442,
              46.0718
          ],
          [
              -115.97464,
              46.07136
          ],
          [
              -115.97542,
              46.07065
          ],
          [
              -115.97553,
              46.07057
          ],
          [
              -115.97654,
              46.06976
          ],
          [
              -115.97656,
              46.06975
          ],
          [
              -115.97695,
              46.0696
          ],
          [
              -115.97757,
              46.06927
          ],
          [
              -115.97875,
              46.06871
          ],
          [
              -115.97959,
              46.06818
          ],
          [
              -115.98016,
              46.06778
          ],
          [
              -115.98035,
              46.06766
          ],
          [
              -115.98052,
              46.06754
          ],
          [
              -115.9813,
              46.06685
          ],
          [
              -115.98221,
              46.06596
          ],
          [
              -115.98227,
              46.06588
          ],
          [
              -115.98302,
              46.06505
          ],
          [
              -115.98334,
              46.06473
          ],
          [
              -115.98347,
              46.06457
          ],
          [
              -115.98356,
              46.06444
          ],
          [
              -115.98365,
              46.06428
          ],
          [
              -115.98366,
              46.06411
          ],
          [
              -115.98362,
              46.06389
          ],
          [
              -115.98349,
              46.06344
          ],
          [
              -115.98313,
              46.06263
          ],
          [
              -115.98255,
              46.06159
          ],
          [
              -115.9824,
              46.06142
          ],
          [
              -115.98231,
              46.06136
          ],
          [
              -115.98216,
              46.06121
          ],
          [
              -115.98205,
              46.06111
          ],
          [
              -115.98202,
              46.06106
          ],
          [
              -115.98194,
              46.06096
          ],
          [
              -115.98188,
              46.06083
          ],
          [
              -115.98183,
              46.0606
          ],
          [
              -115.98182,
              46.06037
          ],
          [
              -115.98182,
              46.05983
          ],
          [
              -115.98179,
              46.05929
          ],
          [
              -115.98174,
              46.0587
          ],
          [
              -115.98173,
              46.05868
          ],
          [
              -115.9817,
              46.05859
          ],
          [
              -115.9817,
              46.05858
          ],
          [
              -115.98165,
              46.05846
          ],
          [
              -115.98161,
              46.05841
          ],
          [
              -115.98161,
              46.0584
          ],
          [
              -115.9815,
              46.05824
          ],
          [
              -115.98113,
              46.05777
          ],
          [
              -115.9811,
              46.05774
          ],
          [
              -115.98097,
              46.05749
          ],
          [
              -115.98092,
              46.05726
          ],
          [
              -115.98092,
              46.05694
          ],
          [
              -115.98084,
              46.05644
          ],
          [
              -115.98078,
              46.05618
          ],
          [
              -115.98066,
              46.05575
          ],
          [
              -115.98062,
              46.05547
          ],
          [
              -115.98061,
              46.05547
          ],
          [
              -115.98052,
              46.05494
          ],
          [
              -115.98056,
              46.05457
          ],
          [
              -115.9806,
              46.05448
          ],
          [
              -115.9806,
              46.05447
          ],
          [
              -115.98069,
              46.05422
          ],
          [
              -115.98073,
              46.05413
          ],
          [
              -115.98073,
              46.05412
          ],
          [
              -115.98128,
              46.05294
          ],
          [
              -115.9814,
              46.05277
          ],
          [
              -115.98141,
              46.05276
          ],
          [
              -115.98176,
              46.05225
          ],
          [
              -115.98204,
              46.05199
          ],
          [
              -115.98205,
              46.05199
          ],
          [
              -115.98229,
              46.05177
          ],
          [
              -115.98301,
              46.05141
          ],
          [
              -115.98302,
              46.0514
          ],
          [
              -115.98313,
              46.05133
          ],
          [
              -115.98319,
              46.05127
          ],
          [
              -115.98323,
              46.05121
          ],
          [
              -115.98324,
              46.05119
          ],
          [
              -115.98326,
              46.0511
          ],
          [
              -115.98327,
              46.05107
          ],
          [
              -115.98325,
              46.05096
          ],
          [
              -115.98317,
              46.05083
          ],
          [
              -115.98305,
              46.05074
          ],
          [
              -115.98289,
              46.05066
          ],
          [
              -115.98264,
              46.05059
          ],
          [
              -115.98219,
              46.05049
          ],
          [
              -115.98217,
              46.05048
          ],
          [
              -115.98216,
              46.05048
          ],
          [
              -115.98201,
              46.0504
          ],
          [
              -115.98184,
              46.05027
          ],
          [
              -115.98172,
              46.05008
          ],
          [
              -115.98171,
              46.04987
          ],
          [
              -115.9817,
              46.04986
          ],
          [
              -115.9817,
              46.04981
          ],
          [
              -115.98162,
              46.04916
          ],
          [
              -115.9816,
              46.0491
          ],
          [
              -115.98158,
              46.04898
          ],
          [
              -115.98157,
              46.04898
          ],
          [
              -115.98146,
              46.0485
          ],
          [
              -115.98133,
              46.04822
          ],
          [
              -115.98129,
              46.04812
          ],
          [
              -115.98117,
              46.04795
          ],
          [
              -115.98116,
              46.04794
          ],
          [
              -115.98113,
              46.04789
          ],
          [
              -115.98094,
              46.04762
          ],
          [
              -115.98058,
              46.04727
          ],
          [
              -115.98024,
              46.04687
          ],
          [
              -115.98023,
              46.04687
          ],
          [
              -115.98019,
              46.04681
          ],
          [
              -115.9798,
              46.04632
          ],
          [
              -115.97945,
              46.04591
          ],
          [
              -115.97934,
              46.04576
          ],
          [
              -115.97931,
              46.0457
          ],
          [
              -115.97929,
              46.04563
          ],
          [
              -115.97928,
              46.04478
          ],
          [
              -115.97933,
              46.04414
          ],
          [
              -115.9794,
              46.04371
          ],
          [
              -115.97941,
              46.04358
          ],
          [
              -115.97942,
              46.04351
          ],
          [
              -115.97949,
              46.0432
          ],
          [
              -115.97956,
              46.04298
          ],
          [
              -115.97968,
              46.04253
          ],
          [
              -115.97987,
              46.04211
          ],
          [
              -115.97989,
              46.04208
          ],
          [
              -115.97993,
              46.04199
          ],
          [
              -115.97997,
              46.04193
          ],
          [
              -115.98011,
              46.04175
          ],
          [
              -115.98054,
              46.04134
          ],
          [
              -115.98073,
              46.04118
          ],
          [
              -115.9809,
              46.04101
          ],
          [
              -115.98105,
              46.04083
          ],
          [
              -115.98113,
              46.04075
          ],
          [
              -115.98191,
              46.03985
          ],
          [
              -115.98207,
              46.03963
          ],
          [
              -115.98214,
              46.03955
          ],
          [
              -115.98247,
              46.03923
          ],
          [
              -115.98257,
              46.03912
          ],
          [
              -115.98266,
              46.03901
          ],
          [
              -115.98315,
              46.03825
          ],
          [
              -115.98315,
              46.03824
          ],
          [
              -115.98323,
              46.03814
          ],
          [
              -115.98328,
              46.03806
          ],
          [
              -115.98334,
              46.03799
          ],
          [
              -115.98364,
              46.03774
          ],
          [
              -115.98378,
              46.03765
          ],
          [
              -115.98412,
              46.03747
          ],
          [
              -115.98455,
              46.03729
          ],
          [
              -115.98484,
              46.03719
          ],
          [
              -115.98507,
              46.0371
          ],
          [
              -115.98508,
              46.0371
          ],
          [
              -115.98575,
              46.03689
          ],
          [
              -115.98637,
              46.03674
          ],
          [
              -115.98666,
              46.03669
          ],
          [
              -115.98678,
              46.03669
          ],
          [
              -115.98687,
              46.03668
          ],
          [
              -115.98732,
              46.03666
          ],
          [
              -115.98768,
              46.03663
          ],
          [
              -115.98781,
              46.03661
          ],
          [
              -115.98795,
              46.03658
          ],
          [
              -115.98829,
              46.03654
          ],
          [
              -115.98861,
              46.03652
          ],
          [
              -115.98892,
              46.03652
          ],
          [
              -115.9891,
              46.03653
          ],
          [
              -115.98954,
              46.03653
          ],
          [
              -115.98978,
              46.03649
          ],
          [
              -115.98986,
              46.03647
          ],
          [
              -115.99003,
              46.03644
          ],
          [
              -115.99022,
              46.03639
          ],
          [
              -115.99043,
              46.03635
          ],
          [
              -115.99074,
              46.03632
          ],
          [
              -115.99131,
              46.03632
          ],
          [
              -115.99145,
              46.03631
          ],
          [
              -115.99184,
              46.03625
          ],
          [
              -115.99236,
              46.03612
          ],
          [
              -115.99237,
              46.03611
          ],
          [
              -115.99257,
              46.03606
          ],
          [
              -115.99272,
              46.03601
          ],
          [
              -115.99281,
              46.03599
          ],
          [
              -115.99299,
              46.03592
          ],
          [
              -115.9932,
              46.03581
          ],
          [
              -115.99364,
              46.03552
          ],
          [
              -115.99401,
              46.03526
          ],
          [
              -115.99417,
              46.03517
          ],
          [
              -115.99435,
              46.03508
          ],
          [
              -115.99534,
              46.03471
          ],
          [
              -115.9955,
              46.03464
          ],
          [
              -115.99567,
              46.03453
          ],
          [
              -115.99578,
              46.03445
          ],
          [
              -115.99623,
              46.03406
          ],
          [
              -115.99627,
              46.03403
          ],
          [
              -115.99653,
              46.03388
          ],
          [
              -115.9967,
              46.0338
          ],
          [
              -115.99696,
              46.03371
          ],
          [
              -115.99718,
              46.03361
          ],
          [
              -115.99787,
              46.03321
          ],
          [
              -115.99797,
              46.03316
          ],
          [
              -115.99809,
              46.03311
          ],
          [
              -115.99843,
              46.03295
          ],
          [
              -115.99928,
              46.03258
          ],
          [
              -115.99939,
              46.03254
          ],
          [
              -115.9994,
              46.03253
          ],
          [
              -115.99973,
              46.03237
          ],
          [
              -115.99998,
              46.03218
          ],
          [
              -116.00021,
              46.03195
          ],
          [
              -116.00039,
              46.03166
          ],
          [
              -116.00052,
              46.03128
          ],
          [
              -116.00054,
              46.03124
          ],
          [
              -116.0007,
              46.03103
          ],
          [
              -116.00091,
              46.03087
          ],
          [
              -116.00147,
              46.0306
          ],
          [
              -116.0015,
              46.03058
          ],
          [
              -116.00171,
              46.03049
          ],
          [
              -116.00205,
              46.03022
          ],
          [
              -116.00255,
              46.0298
          ],
          [
              -116.00255,
              46.02979
          ],
          [
              -116.00276,
              46.02958
          ],
          [
              -116.0033,
              46.02895
          ],
          [
              -116.00342,
              46.02877
          ],
          [
              -116.00343,
              46.0287
          ],
          [
              -116.00344,
              46.02867
          ],
          [
              -116.00341,
              46.02855
          ],
          [
              -116.00336,
              46.02847
          ],
          [
              -116.00326,
              46.02843
          ],
          [
              -116.00285,
              46.02833
          ],
          [
              -116.00274,
              46.02826
          ],
          [
              -116.0027,
              46.02818
          ],
          [
              -116.00269,
              46.02809
          ],
          [
              -116.00269,
              46.02806
          ],
          [
              -116.00279,
              46.02792
          ],
          [
              -116.00295,
              46.02776
          ],
          [
              -116.00332,
              46.02748
          ],
          [
              -116.00336,
              46.02746
          ],
          [
              -116.00351,
              46.0274
          ],
          [
              -116.00372,
              46.02734
          ],
          [
              -116.00377,
              46.02732
          ],
          [
              -116.00445,
              46.02713
          ],
          [
              -116.00463,
              46.02704
          ],
          [
              -116.00465,
              46.02702
          ],
          [
              -116.00472,
              46.02697
          ],
          [
              -116.00479,
              46.02691
          ],
          [
              -116.00482,
              46.02689
          ],
          [
              -116.00491,
              46.0268
          ],
          [
              -116.00501,
              46.02661
          ],
          [
              -116.00506,
              46.02631
          ],
          [
              -116.00506,
              46.02611
          ],
          [
              -116.00507,
              46.02595
          ],
          [
              -116.00507,
              46.02587
          ],
          [
              -116.00505,
              46.02559
          ],
          [
              -116.00504,
              46.02557
          ],
          [
              -116.00504,
              46.02547
          ],
          [
              -116.00498,
              46.02523
          ],
          [
              -116.00498,
              46.02521
          ],
          [
              -116.00494,
              46.02506
          ],
          [
              -116.00494,
              46.02503
          ],
          [
              -116.00493,
              46.02501
          ],
          [
              -116.00479,
              46.02416
          ],
          [
              -116.00465,
              46.02352
          ],
          [
              -116.00462,
              46.02343
          ],
          [
              -116.00455,
              46.02326
          ],
          [
              -116.00455,
              46.02325
          ],
          [
              -116.00447,
              46.02312
          ],
          [
              -116.0042,
              46.02279
          ],
          [
              -116.00371,
              46.02229
          ],
          [
              -116.00345,
              46.02199
          ],
          [
              -116.00325,
              46.02162
          ],
          [
              -116.00314,
              46.02117
          ],
          [
              -116.00314,
              46.02115
          ],
          [
              -116.00306,
              46.02075
          ],
          [
              -116.00308,
              46.02057
          ],
          [
              -116.00317,
              46.0203
          ],
          [
              -116.0033,
              46.02012
          ],
          [
              -116.00331,
              46.02011
          ],
          [
              -116.00335,
              46.02005
          ],
          [
              -116.00362,
              46.01992
          ],
          [
              -116.00412,
              46.01977
          ],
          [
              -116.00439,
              46.01964
          ],
          [
              -116.00453,
              46.01958
          ],
          [
              -116.00472,
              46.01937
          ],
          [
              -116.00477,
              46.01916
          ],
          [
              -116.00472,
              46.01903
          ],
          [
              -116.00471,
              46.01902
          ],
          [
              -116.0047,
              46.019
          ],
          [
              -116.00459,
              46.01869
          ],
          [
              -116.00458,
              46.01867
          ],
          [
              -116.00456,
              46.0186
          ],
          [
              -116.00454,
              46.01849
          ],
          [
              -116.00452,
              46.01833
          ],
          [
              -116.00458,
              46.0178
          ],
          [
              -116.00466,
              46.01745
          ],
          [
              -116.00466,
              46.01743
          ],
          [
              -116.0047,
              46.01707
          ],
          [
              -116.00467,
              46.0084
          ],
          [
              -116.00466,
              46.0083
          ],
          [
              -116.00465,
              46.0064
          ],
          [
              -116.00469,
              46.00632
          ],
          [
              -116.00477,
              46.00623
          ],
          [
              -116.00495,
              46.00618
          ],
          [
              -116.0052,
              46.0062
          ],
          [
              -116.00635,
              46.00647
          ],
          [
              -116.0068,
              46.00663
          ],
          [
              -116.00681,
              46.00663
          ],
          [
              -116.00702,
              46.00678
          ],
          [
              -116.00751,
              46.0072
          ],
          [
              -116.00778,
              46.00737
          ],
          [
              -116.00806,
              46.00749
          ],
          [
              -116.00836,
              46.00755
          ],
          [
              -116.00898,
              46.00765
          ],
          [
              -116.00944,
              46.0077
          ],
          [
              -116.00975,
              46.0077
          ],
          [
              -116.01004,
              46.00766
          ],
          [
              -116.01029,
              46.0076
          ],
          [
              -116.01085,
              46.00743
          ],
          [
              -116.01123,
              46.0073
          ],
          [
              -116.01132,
              46.00728
          ],
          [
              -116.01159,
              46.00718
          ],
          [
              -116.0118,
              46.00715
          ],
          [
              -116.0119,
              46.00713
          ],
          [
              -116.01203,
              46.00714
          ],
          [
              -116.01213,
              46.00719
          ],
          [
              -116.01221,
              46.00725
          ],
          [
              -116.01224,
              46.00729
          ],
          [
              -116.01239,
              46.00753
          ],
          [
              -116.01251,
              46.00768
          ],
          [
              -116.01261,
              46.00773
          ],
          [
              -116.01269,
              46.00778
          ],
          [
              -116.01277,
              46.0078
          ],
          [
              -116.01293,
              46.00779
          ],
          [
              -116.01309,
              46.00775
          ],
          [
              -116.01375,
              46.00747
          ],
          [
              -116.01385,
              46.00742
          ],
          [
              -116.01417,
              46.00729
          ],
          [
              -116.01439,
              46.00722
          ],
          [
              -116.0146,
              46.0072
          ],
          [
              -116.01473,
              46.00721
          ],
          [
              -116.01484,
              46.00724
          ],
          [
              -116.01492,
              46.00728
          ],
          [
              -116.01529,
              46.00742
          ],
          [
              -116.01555,
              46.00755
          ],
          [
              -116.01573,
              46.00761
          ],
          [
              -116.01583,
              46.00762
          ],
          [
              -116.01595,
              46.00764
          ],
          [
              -116.01615,
              46.00763
          ],
          [
              -116.01633,
              46.00759
          ],
          [
              -116.0164,
              46.00756
          ],
          [
              -116.01695,
              46.00716
          ],
          [
              -116.01718,
              46.00701
          ],
          [
              -116.01731,
              46.00696
          ],
          [
              -116.01775,
              46.00686
          ],
          [
              -116.01776,
              46.00685
          ],
          [
              -116.0195,
              46.00666
          ],
          [
              -116.01986,
              46.00659
          ],
          [
              -116.0201,
              46.0065
          ],
          [
              -116.02016,
              46.00644
          ],
          [
              -116.02028,
              46.00628
          ],
          [
              -116.02062,
              46.00563
          ],
          [
              -116.02097,
              46.00507
          ],
          [
              -116.02112,
              46.0049
          ],
          [
              -116.02132,
              46.00477
          ],
          [
              -116.0219,
              46.00444
          ],
          [
              -116.02231,
              46.00425
          ],
          [
              -116.02232,
              46.00425
          ],
          [
              -116.02232,
              46.00424
          ],
          [
              -116.02255,
              46.00416
          ],
          [
              -116.02287,
              46.00412
          ],
          [
              -116.02461,
              46.00416
          ],
          [
              -116.0247,
              46.00417
          ],
          [
              -116.02533,
              46.00416
          ],
          [
              -116.02545,
              46.00414
          ],
          [
              -116.02553,
              46.00408
          ],
          [
              -116.02557,
              46.00403
          ],
          [
              -116.02559,
              46.00401
          ],
          [
              -116.02565,
              46.00389
          ],
          [
              -116.02567,
              46.00206
          ],
          [
              -116.02566,
              46.0017
          ],
          [
              -116.02566,
              46.00044
          ],
          [
              -116.02561,
              45.99868
          ],
          [
              -116.02563,
              45.99737
          ],
          [
              -116.02565,
              45.99703
          ],
          [
              -116.02567,
              45.99698
          ],
          [
              -116.02569,
              45.99695
          ],
          [
              -116.02573,
              45.99693
          ],
          [
              -116.02577,
              45.99692
          ],
          [
              -116.02585,
              45.99691
          ],
          [
              -116.02955,
              45.99697
          ],
          [
              -116.03491,
              45.997
          ],
          [
              -116.03511,
              45.99695
          ],
          [
              -116.03528,
              45.99686
          ],
          [
              -116.03534,
              45.99675
          ],
          [
              -116.03538,
              45.99626
          ],
          [
              -116.03573,
              45.99448
          ],
          [
              -116.03585,
              45.99397
          ],
          [
              -116.0359,
              45.99379
          ],
          [
              -116.0359,
              45.99377
          ],
          [
              -116.03594,
              45.99362
          ],
          [
              -116.03599,
              45.99336
          ],
          [
              -116.03604,
              45.99159
          ],
          [
              -116.03606,
              45.98747
          ],
          [
              -116.03605,
              45.98719
          ],
          [
              -116.03604,
              45.98571
          ],
          [
              -116.03603,
              45.98553
          ],
          [
              -116.03603,
              45.98462
          ],
          [
              -116.0362,
              45.98457
          ],
          [
              -116.03631,
              45.98455
          ],
          [
              -116.04256,
              45.98291
          ],
          [
              -116.04359,
              45.98262
          ],
          [
              -116.04369,
              45.9826
          ],
          [
              -116.04384,
              45.98258
          ],
          [
              -116.05612,
              45.98281
          ],
          [
              -116.05711,
              45.98279
          ],
          [
              -116.06037,
              45.9828
          ],
          [
              -116.06229,
              45.98277
          ],
          [
              -116.06247,
              45.98276
          ],
          [
              -116.06402,
              45.98276
          ],
          [
              -116.0641,
              45.98275
          ],
          [
              -116.06694,
              45.98272
          ],
          [
              -116.06695,
              45.98243
          ],
          [
              -116.06695,
              45.98015
          ],
          [
              -116.06693,
              45.97941
          ],
          [
              -116.06694,
              45.97889
          ],
          [
              -116.06691,
              45.97548
          ],
          [
              -116.07288,
              45.9755
          ],
          [
              -116.07312,
              45.97551
          ],
          [
              -116.07726,
              45.97552
          ],
          [
              -116.07726,
              45.96436
          ],
          [
              -116.0773,
              45.96142
          ],
          [
              -116.07729,
              45.96136
          ],
          [
              -116.07728,
              45.95956
          ],
          [
              -116.07732,
              45.95773
          ],
          [
              -116.07732,
              45.95385
          ],
          [
              -116.07734,
              45.95367
          ],
          [
              -116.07735,
              45.9518
          ],
          [
              -116.07736,
              45.95152
          ],
          [
              -116.07737,
              45.94939
          ],
          [
              -116.07738,
              45.94936
          ],
          [
              -116.07739,
              45.94846
          ],
          [
              -116.0774,
              45.94828
          ],
          [
              -116.0774,
              45.94774
          ],
          [
              -116.07741,
              45.94756
          ],
          [
              -116.07743,
              45.94612
          ],
          [
              -116.07744,
              45.94594
          ],
          [
              -116.07748,
              45.94387
          ],
          [
              -116.07748,
              45.94283
          ],
          [
              -116.07751,
              45.94225
          ],
          [
              -116.07751,
              45.94179
          ],
          [
              -116.07752,
              45.94176
          ],
          [
              -116.07753,
              45.94122
          ],
          [
              -116.07754,
              45.94107
          ],
          [
              -116.07755,
              45.93981
          ],
          [
              -116.07754,
              45.93971
          ],
          [
              -116.07755,
              45.93802
          ],
          [
              -116.07756,
              45.93779
          ],
          [
              -116.07756,
              45.93749
          ],
          [
              -116.07754,
              45.93708
          ],
          [
              -116.07754,
              45.93683
          ],
          [
              -116.07751,
              45.93657
          ],
          [
              -116.07751,
              45.93574
          ],
          [
              -116.07752,
              45.93549
          ],
          [
              -116.07747,
              45.93218
          ],
          [
              -116.07745,
              45.93211
          ],
          [
              -116.07747,
              45.93164
          ],
          [
              -116.07748,
              45.93075
          ],
          [
              -116.07763,
              45.93056
          ],
          [
              -116.07774,
              45.9304
          ],
          [
              -116.07785,
              45.93022
          ],
          [
              -116.07791,
              45.93009
          ],
          [
              -116.07791,
              45.93007
          ],
          [
              -116.07808,
              45.92958
          ],
          [
              -116.07815,
              45.92942
          ],
          [
              -116.07822,
              45.92929
          ],
          [
              -116.07835,
              45.92913
          ],
          [
              -116.0785,
              45.92899
          ],
          [
              -116.07867,
              45.92887
          ],
          [
              -116.07891,
              45.92873
          ],
          [
              -116.07918,
              45.92862
          ],
          [
              -116.07957,
              45.92852
          ],
          [
              -116.07999,
              45.92847
          ],
          [
              -116.10113,
              45.92851
          ],
          [
              -116.10119,
              45.9285
          ],
          [
              -116.10168,
              45.92846
          ],
          [
              -116.10171,
              45.92845
          ],
          [
              -116.10192,
              45.92842
          ],
          [
              -116.10215,
              45.92837
          ],
          [
              -116.10261,
              45.92825
          ],
          [
              -116.10301,
              45.92812
          ],
          [
              -116.10345,
              45.92793
          ],
          [
              -116.10366,
              45.92783
          ],
          [
              -116.1037,
              45.9278
          ],
          [
              -116.10534,
              45.92699
          ],
          [
              -116.10535,
              45.92698
          ],
          [
              -116.10639,
              45.92647
          ],
          [
              -116.10644,
              45.92644
          ],
          [
              -116.11004,
              45.92468
          ],
          [
              -116.11039,
              45.92454
          ],
          [
              -116.11066,
              45.92445
          ],
          [
              -116.11068,
              45.92444
          ],
          [
              -116.11115,
              45.92435
          ],
          [
              -116.11137,
              45.92433
          ],
          [
              -116.11159,
              45.92432
          ],
          [
              -116.11361,
              45.92434
          ],
          [
              -116.11374,
              45.92435
          ],
          [
              -116.11467,
              45.92457
          ],
          [
              -116.11516,
              45.9247
          ],
          [
              -116.12226,
              45.92629
          ],
          [
              -116.12216,
              45.92651
          ],
          [
              -116.12226,
              45.92629
          ],
          [
              -116.11516,
              45.9247
          ],
          [
              -116.11467,
              45.92457
          ],
          [
              -116.11374,
              45.92435
          ],
          [
              -116.11361,
              45.92434
          ],
          [
              -116.11159,
              45.92432
          ],
          [
              -116.11137,
              45.92433
          ],
          [
              -116.11115,
              45.92435
          ],
          [
              -116.11068,
              45.92444
          ],
          [
              -116.11066,
              45.92445
          ],
          [
              -116.11039,
              45.92454
          ],
          [
              -116.11004,
              45.92468
          ],
          [
              -116.10644,
              45.92644
          ],
          [
              -116.10639,
              45.92647
          ],
          [
              -116.10535,
              45.92698
          ],
          [
              -116.10534,
              45.92699
          ],
          [
              -116.1037,
              45.9278
          ],
          [
              -116.10366,
              45.92783
          ],
          [
              -116.10345,
              45.92793
          ],
          [
              -116.10301,
              45.92812
          ],
          [
              -116.10261,
              45.92825
          ],
          [
              -116.10215,
              45.92837
          ],
          [
              -116.10192,
              45.92842
          ],
          [
              -116.10171,
              45.92845
          ],
          [
              -116.10168,
              45.92846
          ],
          [
              -116.10119,
              45.9285
          ],
          [
              -116.10113,
              45.92851
          ],
          [
              -116.07999,
              45.92847
          ],
          [
              -116.07957,
              45.92852
          ],
          [
              -116.07918,
              45.92862
          ],
          [
              -116.07891,
              45.92873
          ],
          [
              -116.07867,
              45.92887
          ],
          [
              -116.0785,
              45.92899
          ],
          [
              -116.07835,
              45.92913
          ],
          [
              -116.07822,
              45.92929
          ],
          [
              -116.07815,
              45.92942
          ],
          [
              -116.07808,
              45.92958
          ],
          [
              -116.07791,
              45.93007
          ],
          [
              -116.07791,
              45.93009
          ],
          [
              -116.07785,
              45.93022
          ],
          [
              -116.07774,
              45.9304
          ],
          [
              -116.07763,
              45.93056
          ],
          [
              -116.07748,
              45.93075
          ],
          [
              -116.07747,
              45.93164
          ],
          [
              -116.07745,
              45.93211
          ],
          [
              -116.07747,
              45.93218
          ],
          [
              -116.07752,
              45.93549
          ],
          [
              -116.07751,
              45.93574
          ],
          [
              -116.07751,
              45.93657
          ],
          [
              -116.07754,
              45.93683
          ],
          [
              -116.07754,
              45.93708
          ],
          [
              -116.07756,
              45.93749
          ],
          [
              -116.07756,
              45.93779
          ],
          [
              -116.07755,
              45.93802
          ],
          [
              -116.07754,
              45.93971
          ],
          [
              -116.07755,
              45.93981
          ],
          [
              -116.07754,
              45.94107
          ],
          [
              -116.07753,
              45.94122
          ],
          [
              -116.07752,
              45.94176
          ],
          [
              -116.07751,
              45.94179
          ],
          [
              -116.07751,
              45.94225
          ],
          [
              -116.07748,
              45.94283
          ],
          [
              -116.07748,
              45.94387
          ],
          [
              -116.07744,
              45.94594
          ],
          [
              -116.07743,
              45.94612
          ],
          [
              -116.07741,
              45.94756
          ],
          [
              -116.0774,
              45.94774
          ],
          [
              -116.0774,
              45.94828
          ],
          [
              -116.07739,
              45.94846
          ],
          [
              -116.07738,
              45.94936
          ],
          [
              -116.07737,
              45.94939
          ],
          [
              -116.07736,
              45.95152
          ],
          [
              -116.07735,
              45.9518
          ],
          [
              -116.07734,
              45.95367
          ],
          [
              -116.07732,
              45.95385
          ],
          [
              -116.07732,
              45.95773
          ],
          [
              -116.07728,
              45.95956
          ],
          [
              -116.07729,
              45.96136
          ],
          [
              -116.0773,
              45.96142
          ],
          [
              -116.07726,
              45.96436
          ],
          [
              -116.07726,
              45.97552
          ],
          [
              -116.07312,
              45.97551
          ],
          [
              -116.07288,
              45.9755
          ],
          [
              -116.06691,
              45.97548
          ],
          [
              -116.06694,
              45.97889
          ],
          [
              -116.06693,
              45.97941
          ],
          [
              -116.06695,
              45.98015
          ],
          [
              -116.06695,
              45.98243
          ],
          [
              -116.06694,
              45.98272
          ],
          [
              -116.0641,
              45.98275
          ],
          [
              -116.06402,
              45.98276
          ],
          [
              -116.06247,
              45.98276
          ],
          [
              -116.06229,
              45.98277
          ],
          [
              -116.06037,
              45.9828
          ],
          [
              -116.05711,
              45.98279
          ],
          [
              -116.05612,
              45.98281
          ],
          [
              -116.04384,
              45.98258
          ],
          [
              -116.04369,
              45.9826
          ],
          [
              -116.04359,
              45.98262
          ],
          [
              -116.04256,
              45.98291
          ],
          [
              -116.03631,
              45.98455
          ],
          [
              -116.0362,
              45.98457
          ],
          [
              -116.03603,
              45.98462
          ],
          [
              -116.03603,
              45.98553
          ],
          [
              -116.03604,
              45.98571
          ],
          [
              -116.03605,
              45.98719
          ],
          [
              -116.03606,
              45.98747
          ],
          [
              -116.03604,
              45.99159
          ],
          [
              -116.03599,
              45.99336
          ],
          [
              -116.03594,
              45.99362
          ],
          [
              -116.0359,
              45.99377
          ],
          [
              -116.0359,
              45.99379
          ],
          [
              -116.03585,
              45.99397
          ],
          [
              -116.03573,
              45.99448
          ],
          [
              -116.03538,
              45.99626
          ],
          [
              -116.03534,
              45.99675
          ],
          [
              -116.03528,
              45.99686
          ],
          [
              -116.03511,
              45.99695
          ],
          [
              -116.03491,
              45.997
          ],
          [
              -116.02955,
              45.99697
          ],
          [
              -116.02585,
              45.99691
          ],
          [
              -116.02577,
              45.99692
          ],
          [
              -116.02573,
              45.99693
          ],
          [
              -116.02569,
              45.99695
          ],
          [
              -116.02567,
              45.99698
          ],
          [
              -116.02565,
              45.99703
          ],
          [
              -116.02563,
              45.99737
          ],
          [
              -116.02561,
              45.99868
          ],
          [
              -116.02566,
              46.00044
          ],
          [
              -116.02566,
              46.0017
          ],
          [
              -116.02567,
              46.00206
          ],
          [
              -116.02565,
              46.00389
          ],
          [
              -116.02559,
              46.00401
          ],
          [
              -116.02557,
              46.00403
          ],
          [
              -116.02553,
              46.00408
          ],
          [
              -116.02545,
              46.00414
          ],
          [
              -116.02533,
              46.00416
          ],
          [
              -116.0247,
              46.00417
          ],
          [
              -116.02461,
              46.00416
          ],
          [
              -116.02287,
              46.00412
          ],
          [
              -116.02255,
              46.00416
          ],
          [
              -116.02232,
              46.00424
          ],
          [
              -116.02232,
              46.00425
          ],
          [
              -116.02231,
              46.00425
          ],
          [
              -116.0219,
              46.00444
          ],
          [
              -116.02132,
              46.00477
          ],
          [
              -116.02112,
              46.0049
          ],
          [
              -116.02097,
              46.00507
          ],
          [
              -116.02062,
              46.00563
          ],
          [
              -116.02028,
              46.00628
          ],
          [
              -116.02016,
              46.00644
          ],
          [
              -116.0201,
              46.0065
          ],
          [
              -116.01986,
              46.00659
          ],
          [
              -116.0195,
              46.00666
          ],
          [
              -116.01776,
              46.00685
          ],
          [
              -116.01775,
              46.00686
          ],
          [
              -116.01731,
              46.00696
          ],
          [
              -116.01718,
              46.00701
          ],
          [
              -116.01695,
              46.00716
          ],
          [
              -116.0164,
              46.00756
          ],
          [
              -116.01633,
              46.00759
          ],
          [
              -116.01615,
              46.00763
          ],
          [
              -116.01595,
              46.00764
          ],
          [
              -116.01583,
              46.00762
          ],
          [
              -116.01573,
              46.00761
          ],
          [
              -116.01555,
              46.00755
          ],
          [
              -116.01529,
              46.00742
          ],
          [
              -116.01492,
              46.00728
          ],
          [
              -116.01484,
              46.00724
          ],
          [
              -116.01473,
              46.00721
          ],
          [
              -116.0146,
              46.0072
          ],
          [
              -116.01439,
              46.00722
          ],
          [
              -116.01417,
              46.00729
          ],
          [
              -116.01385,
              46.00742
          ],
          [
              -116.01375,
              46.00747
          ],
          [
              -116.01309,
              46.00775
          ],
          [
              -116.01293,
              46.00779
          ],
          [
              -116.01277,
              46.0078
          ],
          [
              -116.01269,
              46.00778
          ],
          [
              -116.01261,
              46.00773
          ],
          [
              -116.01251,
              46.00768
          ],
          [
              -116.01239,
              46.00753
          ],
          [
              -116.01224,
              46.00729
          ],
          [
              -116.01221,
              46.00725
          ],
          [
              -116.01213,
              46.00719
          ],
          [
              -116.01203,
              46.00714
          ],
          [
              -116.0119,
              46.00713
          ],
          [
              -116.0118,
              46.00715
          ],
          [
              -116.01159,
              46.00718
          ],
          [
              -116.01132,
              46.00728
          ],
          [
              -116.01123,
              46.0073
          ],
          [
              -116.01085,
              46.00743
          ],
          [
              -116.01029,
              46.0076
          ],
          [
              -116.01004,
              46.00766
          ],
          [
              -116.00975,
              46.0077
          ],
          [
              -116.00944,
              46.0077
          ],
          [
              -116.00898,
              46.00765
          ],
          [
              -116.00836,
              46.00755
          ],
          [
              -116.00806,
              46.00749
          ],
          [
              -116.00778,
              46.00737
          ],
          [
              -116.00751,
              46.0072
          ],
          [
              -116.00702,
              46.00678
          ],
          [
              -116.00681,
              46.00663
          ],
          [
              -116.0068,
              46.00663
          ],
          [
              -116.00635,
              46.00647
          ],
          [
              -116.0052,
              46.0062
          ],
          [
              -116.00495,
              46.00618
          ],
          [
              -116.00477,
              46.00623
          ],
          [
              -116.00469,
              46.00632
          ],
          [
              -116.00465,
              46.0064
          ],
          [
              -116.00466,
              46.0083
          ],
          [
              -116.00467,
              46.0084
          ],
          [
              -116.0047,
              46.01707
          ],
          [
              -116.00466,
              46.01743
          ],
          [
              -116.00466,
              46.01745
          ],
          [
              -116.00458,
              46.0178
          ],
          [
              -116.00452,
              46.01833
          ],
          [
              -116.00454,
              46.01849
          ],
          [
              -116.00456,
              46.0186
          ],
          [
              -116.00458,
              46.01867
          ],
          [
              -116.00459,
              46.01869
          ],
          [
              -116.0047,
              46.019
          ],
          [
              -116.00471,
              46.01902
          ],
          [
              -116.00472,
              46.01903
          ],
          [
              -116.00477,
              46.01916
          ],
          [
              -116.00472,
              46.01937
          ],
          [
              -116.00453,
              46.01958
          ],
          [
              -116.00439,
              46.01964
          ],
          [
              -116.00412,
              46.01977
          ],
          [
              -116.00362,
              46.01992
          ],
          [
              -116.00335,
              46.02005
          ],
          [
              -116.00331,
              46.02011
          ],
          [
              -116.0033,
              46.02012
          ],
          [
              -116.00317,
              46.0203
          ],
          [
              -116.00308,
              46.02057
          ],
          [
              -116.00306,
              46.02075
          ],
          [
              -116.00314,
              46.02115
          ],
          [
              -116.00314,
              46.02117
          ],
          [
              -116.00325,
              46.02162
          ],
          [
              -116.00345,
              46.02199
          ],
          [
              -116.00371,
              46.02229
          ],
          [
              -116.0042,
              46.02279
          ],
          [
              -116.00447,
              46.02312
          ],
          [
              -116.00455,
              46.02325
          ],
          [
              -116.00455,
              46.02326
          ],
          [
              -116.00462,
              46.02343
          ],
          [
              -116.00465,
              46.02352
          ],
          [
              -116.00479,
              46.02416
          ],
          [
              -116.00493,
              46.02501
          ],
          [
              -116.00494,
              46.02503
          ],
          [
              -116.00494,
              46.02506
          ],
          [
              -116.00498,
              46.02521
          ],
          [
              -116.00498,
              46.02523
          ],
          [
              -116.00504,
              46.02547
          ],
          [
              -116.00504,
              46.02557
          ],
          [
              -116.00505,
              46.02559
          ],
          [
              -116.00507,
              46.02587
          ],
          [
              -116.00507,
              46.02595
          ],
          [
              -116.00506,
              46.02611
          ],
          [
              -116.00506,
              46.02631
          ],
          [
              -116.00501,
              46.02661
          ],
          [
              -116.00491,
              46.0268
          ],
          [
              -116.00482,
              46.02689
          ],
          [
              -116.00479,
              46.02691
          ],
          [
              -116.00472,
              46.02697
          ],
          [
              -116.00465,
              46.02702
          ],
          [
              -116.00463,
              46.02704
          ],
          [
              -116.00445,
              46.02713
          ],
          [
              -116.00377,
              46.02732
          ],
          [
              -116.00372,
              46.02734
          ],
          [
              -116.00351,
              46.0274
          ],
          [
              -116.00336,
              46.02746
          ],
          [
              -116.00332,
              46.02748
          ],
          [
              -116.00295,
              46.02776
          ],
          [
              -116.00279,
              46.02792
          ],
          [
              -116.00269,
              46.02806
          ],
          [
              -116.00269,
              46.02809
          ],
          [
              -116.0027,
              46.02818
          ],
          [
              -116.00274,
              46.02826
          ],
          [
              -116.00285,
              46.02833
          ],
          [
              -116.00326,
              46.02843
          ],
          [
              -116.00336,
              46.02847
          ],
          [
              -116.00341,
              46.02855
          ],
          [
              -116.00344,
              46.02867
          ],
          [
              -116.00343,
              46.0287
          ],
          [
              -116.00342,
              46.02877
          ],
          [
              -116.0033,
              46.02895
          ],
          [
              -116.00276,
              46.02958
          ],
          [
              -116.00255,
              46.02979
          ],
          [
              -116.00255,
              46.0298
          ],
          [
              -116.00205,
              46.03022
          ],
          [
              -116.00171,
              46.03049
          ],
          [
              -116.0015,
              46.03058
          ],
          [
              -116.00147,
              46.0306
          ],
          [
              -116.00091,
              46.03087
          ],
          [
              -116.0007,
              46.03103
          ],
          [
              -116.00054,
              46.03124
          ],
          [
              -116.00052,
              46.03128
          ],
          [
              -116.00039,
              46.03166
          ],
          [
              -116.00021,
              46.03195
          ],
          [
              -115.99998,
              46.03218
          ],
          [
              -115.99973,
              46.03237
          ],
          [
              -115.9994,
              46.03253
          ],
          [
              -115.99939,
              46.03254
          ],
          [
              -115.99928,
              46.03258
          ],
          [
              -115.99843,
              46.03295
          ],
          [
              -115.99809,
              46.03311
          ],
          [
              -115.99797,
              46.03316
          ],
          [
              -115.99787,
              46.03321
          ],
          [
              -115.99718,
              46.03361
          ],
          [
              -115.99696,
              46.03371
          ],
          [
              -115.9967,
              46.0338
          ],
          [
              -115.99653,
              46.03388
          ],
          [
              -115.99627,
              46.03403
          ],
          [
              -115.99623,
              46.03406
          ],
          [
              -115.99578,
              46.03445
          ],
          [
              -115.99567,
              46.03453
          ],
          [
              -115.9955,
              46.03464
          ],
          [
              -115.99534,
              46.03471
          ],
          [
              -115.99435,
              46.03508
          ],
          [
              -115.99417,
              46.03517
          ],
          [
              -115.99401,
              46.03526
          ],
          [
              -115.99364,
              46.03552
          ],
          [
              -115.9932,
              46.03581
          ],
          [
              -115.99299,
              46.03592
          ],
          [
              -115.99281,
              46.03599
          ],
          [
              -115.99272,
              46.03601
          ],
          [
              -115.99257,
              46.03606
          ],
          [
              -115.99237,
              46.03611
          ],
          [
              -115.99236,
              46.03612
          ],
          [
              -115.99184,
              46.03625
          ],
          [
              -115.99145,
              46.03631
          ],
          [
              -115.99131,
              46.03632
          ],
          [
              -115.99074,
              46.03632
          ],
          [
              -115.99043,
              46.03635
          ],
          [
              -115.99022,
              46.03639
          ],
          [
              -115.99003,
              46.03644
          ],
          [
              -115.98986,
              46.03647
          ],
          [
              -115.98978,
              46.03649
          ],
          [
              -115.98954,
              46.03653
          ],
          [
              -115.9891,
              46.03653
          ],
          [
              -115.98892,
              46.03652
          ],
          [
              -115.98861,
              46.03652
          ],
          [
              -115.98829,
              46.03654
          ],
          [
              -115.98795,
              46.03658
          ],
          [
              -115.98781,
              46.03661
          ],
          [
              -115.98768,
              46.03663
          ],
          [
              -115.98732,
              46.03666
          ],
          [
              -115.98687,
              46.03668
          ],
          [
              -115.98678,
              46.03669
          ],
          [
              -115.98666,
              46.03669
          ],
          [
              -115.98637,
              46.03674
          ],
          [
              -115.98575,
              46.03689
          ],
          [
              -115.98508,
              46.0371
          ],
          [
              -115.98507,
              46.0371
          ],
          [
              -115.98484,
              46.03719
          ],
          [
              -115.98455,
              46.03729
          ],
          [
              -115.98412,
              46.03747
          ],
          [
              -115.98378,
              46.03765
          ],
          [
              -115.98364,
              46.03774
          ],
          [
              -115.98334,
              46.03799
          ],
          [
              -115.98328,
              46.03806
          ],
          [
              -115.98323,
              46.03814
          ],
          [
              -115.98315,
              46.03824
          ],
          [
              -115.98315,
              46.03825
          ],
          [
              -115.98266,
              46.03901
          ],
          [
              -115.98257,
              46.03912
          ],
          [
              -115.98247,
              46.03923
          ],
          [
              -115.98214,
              46.03955
          ],
          [
              -115.98207,
              46.03963
          ],
          [
              -115.98191,
              46.03985
          ],
          [
              -115.98113,
              46.04075
          ],
          [
              -115.98105,
              46.04083
          ],
          [
              -115.9809,
              46.04101
          ],
          [
              -115.98073,
              46.04118
          ],
          [
              -115.98054,
              46.04134
          ],
          [
              -115.98011,
              46.04175
          ],
          [
              -115.97997,
              46.04193
          ],
          [
              -115.97993,
              46.04199
          ],
          [
              -115.97989,
              46.04208
          ],
          [
              -115.97987,
              46.04211
          ],
          [
              -115.97968,
              46.04253
          ],
          [
              -115.97956,
              46.04298
          ],
          [
              -115.97949,
              46.0432
          ],
          [
              -115.97942,
              46.04351
          ],
          [
              -115.97941,
              46.04358
          ],
          [
              -115.9794,
              46.04371
          ],
          [
              -115.97933,
              46.04414
          ],
          [
              -115.97928,
              46.04478
          ],
          [
              -115.97929,
              46.04563
          ],
          [
              -115.97931,
              46.0457
          ],
          [
              -115.97934,
              46.04576
          ],
          [
              -115.97945,
              46.04591
          ],
          [
              -115.9798,
              46.04632
          ],
          [
              -115.98019,
              46.04681
          ],
          [
              -115.98023,
              46.04687
          ],
          [
              -115.98024,
              46.04687
          ],
          [
              -115.98058,
              46.04727
          ],
          [
              -115.98094,
              46.04762
          ],
          [
              -115.98113,
              46.04789
          ],
          [
              -115.98116,
              46.04794
          ],
          [
              -115.98117,
              46.04795
          ],
          [
              -115.98129,
              46.04812
          ],
          [
              -115.98133,
              46.04822
          ],
          [
              -115.98146,
              46.0485
          ],
          [
              -115.98157,
              46.04898
          ],
          [
              -115.98158,
              46.04898
          ],
          [
              -115.9816,
              46.0491
          ],
          [
              -115.98162,
              46.04916
          ],
          [
              -115.9817,
              46.04981
          ],
          [
              -115.9817,
              46.04986
          ],
          [
              -115.98171,
              46.04987
          ],
          [
              -115.98172,
              46.05008
          ],
          [
              -115.98184,
              46.05027
          ],
          [
              -115.98201,
              46.0504
          ],
          [
              -115.98216,
              46.05048
          ],
          [
              -115.98217,
              46.05048
          ],
          [
              -115.98219,
              46.05049
          ],
          [
              -115.98264,
              46.05059
          ],
          [
              -115.98289,
              46.05066
          ],
          [
              -115.98305,
              46.05074
          ],
          [
              -115.98317,
              46.05083
          ],
          [
              -115.98325,
              46.05096
          ],
          [
              -115.98327,
              46.05107
          ],
          [
              -115.98326,
              46.0511
          ],
          [
              -115.98324,
              46.05119
          ],
          [
              -115.98323,
              46.05121
          ],
          [
              -115.98319,
              46.05127
          ],
          [
              -115.98313,
              46.05133
          ],
          [
              -115.98302,
              46.0514
          ],
          [
              -115.98301,
              46.05141
          ],
          [
              -115.98229,
              46.05177
          ],
          [
              -115.98205,
              46.05199
          ],
          [
              -115.98204,
              46.05199
          ],
          [
              -115.98176,
              46.05225
          ],
          [
              -115.98141,
              46.05276
          ],
          [
              -115.9814,
              46.05277
          ],
          [
              -115.98128,
              46.05294
          ],
          [
              -115.98073,
              46.05412
          ],
          [
              -115.98073,
              46.05413
          ],
          [
              -115.98069,
              46.05422
          ],
          [
              -115.9806,
              46.05447
          ],
          [
              -115.9806,
              46.05448
          ],
          [
              -115.98056,
              46.05457
          ],
          [
              -115.98052,
              46.05494
          ],
          [
              -115.98061,
              46.05547
          ],
          [
              -115.98062,
              46.05547
          ],
          [
              -115.98066,
              46.05575
          ],
          [
              -115.98078,
              46.05618
          ],
          [
              -115.98084,
              46.05644
          ],
          [
              -115.98092,
              46.05694
          ],
          [
              -115.98092,
              46.05726
          ],
          [
              -115.98097,
              46.05749
          ],
          [
              -115.9811,
              46.05774
          ],
          [
              -115.98113,
              46.05777
          ],
          [
              -115.9815,
              46.05824
          ],
          [
              -115.98161,
              46.0584
          ],
          [
              -115.98161,
              46.05841
          ],
          [
              -115.98165,
              46.05846
          ],
          [
              -115.9817,
              46.05858
          ],
          [
              -115.9817,
              46.05859
          ],
          [
              -115.98173,
              46.05868
          ],
          [
              -115.98174,
              46.0587
          ],
          [
              -115.98179,
              46.05929
          ],
          [
              -115.98182,
              46.05983
          ],
          [
              -115.98182,
              46.06037
          ],
          [
              -115.98183,
              46.0606
          ],
          [
              -115.98188,
              46.06083
          ],
          [
              -115.98194,
              46.06096
          ],
          [
              -115.98202,
              46.06106
          ],
          [
              -115.98205,
              46.06111
          ],
          [
              -115.98216,
              46.06121
          ],
          [
              -115.98231,
              46.06136
          ],
          [
              -115.9824,
              46.06142
          ],
          [
              -115.98255,
              46.06159
          ],
          [
              -115.98313,
              46.06263
          ],
          [
              -115.98349,
              46.06344
          ],
          [
              -115.98362,
              46.06389
          ],
          [
              -115.98366,
              46.06411
          ],
          [
              -115.98365,
              46.06428
          ],
          [
              -115.98356,
              46.06444
          ],
          [
              -115.98347,
              46.06457
          ],
          [
              -115.98334,
              46.06473
          ],
          [
              -115.98302,
              46.06505
          ],
          [
              -115.98227,
              46.06588
          ],
          [
              -115.98221,
              46.06596
          ],
          [
              -115.9813,
              46.06685
          ],
          [
              -115.98052,
              46.06754
          ],
          [
              -115.98035,
              46.06766
          ],
          [
              -115.98016,
              46.06778
          ],
          [
              -115.97959,
              46.06818
          ],
          [
              -115.97875,
              46.06871
          ],
          [
              -115.97757,
              46.06927
          ],
          [
              -115.97695,
              46.0696
          ],
          [
              -115.97656,
              46.06975
          ],
          [
              -115.97654,
              46.06976
          ],
          [
              -115.97553,
              46.07057
          ],
          [
              -115.97542,
              46.07065
          ],
          [
              -115.97464,
              46.07136
          ],
          [
              -115.97442,
              46.0718
          ],
          [
              -115.97437,
              46.07199
          ],
          [
              -115.97428,
              46.07245
          ],
          [
              -115.97425,
              46.07267
          ],
          [
              -115.97423,
              46.0729
          ],
          [
              -115.97424,
              46.07312
          ],
          [
              -115.97437,
              46.07359
          ],
          [
              -115.97488,
              46.07441
          ],
          [
              -115.97685,
              46.0767
          ],
          [
              -115.97698,
              46.07684
          ],
          [
              -115.97766,
              46.0775
          ],
          [
              -115.97804,
              46.07811
          ],
          [
              -115.9783,
              46.0786
          ],
          [
              -115.9784,
              46.07925
          ],
          [
              -115.97859,
              46.08014
          ],
          [
              -115.9786,
              46.08017
          ],
          [
              -115.97884,
              46.08069
          ],
          [
              -115.97894,
              46.08085
          ],
          [
              -115.9793,
              46.08148
          ],
          [
              -115.97919,
              46.08153
          ],
          [
              -115.9786,
              46.08193
          ],
          [
              -115.97842,
              46.08217
          ],
          [
              -115.97809,
              46.08279
          ],
          [
              -115.97807,
              46.08282
          ],
          [
              -115.9777,
              46.0835
          ],
          [
              -115.97731,
              46.08432
          ],
          [
              -115.97698,
              46.0854
          ],
          [
              -115.97693,
              46.08554
          ],
          [
              -115.97693,
              46.08555
          ],
          [
              -115.97689,
              46.08564
          ],
          [
              -115.97686,
              46.08595
          ],
          [
              -115.97681,
              46.08679
          ],
          [
              -115.97681,
              46.08686
          ],
          [
              -115.97682,
              46.08698
          ],
          [
              -115.97692,
              46.08775
          ],
          [
              -115.97696,
              46.08795
          ],
          [
              -115.97699,
              46.08805
          ],
          [
              -115.9771,
              46.0885
          ],
          [
              -115.97726,
              46.08942
          ],
          [
              -115.97754,
              46.0901
          ],
          [
              -115.97759,
              46.09016
          ],
          [
              -115.9776,
              46.09018
          ],
          [
              -115.9781,
              46.09087
          ],
          [
              -115.97826,
              46.09118
          ],
          [
              -115.97818,
              46.09123
          ],
          [
              -115.97785,
              46.09135
          ],
          [
              -115.97754,
              46.09144
          ],
          [
              -115.97753,
              46.09144
          ],
          [
              -115.9773,
              46.0915
          ],
          [
              -115.97725,
              46.09152
          ],
          [
              -115.97703,
              46.09157
          ],
          [
              -115.97679,
              46.09157
          ],
          [
              -115.97625,
              46.0916
          ],
          [
              -115.97631,
              46.09344
          ],
          [
              -115.97631,
              46.09365
          ],
          [
              -115.97632,
              46.09384
          ],
          [
              -115.97633,
              46.09393
          ],
          [
              -115.97633,
              46.09402
          ],
          [
              -115.97636,
              46.09422
          ],
          [
              -115.9764,
              46.09442
          ],
          [
              -115.97643,
              46.09451
          ],
          [
              -115.97647,
              46.09466
          ],
          [
              -115.97651,
              46.09474
          ],
          [
              -115.97658,
              46.09495
          ],
          [
              -115.97661,
              46.09506
          ],
          [
              -115.97663,
              46.09516
          ],
          [
              -115.97663,
              46.09517
          ],
          [
              -115.97664,
              46.09522
          ],
          [
              -115.97664,
              46.09539
          ],
          [
              -115.97663,
              46.09549
          ],
          [
              -115.97663,
              46.09559
          ],
          [
              -115.97661,
              46.09593
          ],
          [
              -115.9766,
              46.09596
          ],
          [
              -115.9766,
              46.09613
          ],
          [
              -115.97658,
              46.09632
          ],
          [
              -115.97663,
              46.09708
          ],
          [
              -115.97664,
              46.09711
          ],
          [
              -115.97665,
              46.09729
          ],
          [
              -115.97687,
              46.09936
          ],
          [
              -115.9769,
              46.09956
          ],
          [
              -115.9769,
              46.09959
          ],
          [
              -115.97692,
              46.0998
          ],
          [
              -115.97695,
              46.1
          ],
          [
              -115.97706,
              46.10049
          ],
          [
              -115.97715,
              46.10066
          ],
          [
              -115.97729,
              46.10089
          ],
          [
              -115.97733,
              46.10093
          ],
          [
              -115.97773,
              46.10151
          ],
          [
              -115.97783,
              46.10172
          ],
          [
              -115.97786,
              46.10181
          ],
          [
              -115.97787,
              46.10182
          ],
          [
              -115.97791,
              46.10197
          ],
          [
              -115.97791,
              46.10198
          ],
          [
              -115.97864,
              46.10591
          ],
          [
              -115.97869,
              46.10623
          ],
          [
              -115.97877,
              46.10662
          ],
          [
              -115.97887,
              46.10725
          ],
          [
              -115.97902,
              46.108
          ],
          [
              -115.97914,
              46.10845
          ],
          [
              -115.97921,
              46.10863
          ],
          [
              -115.97923,
              46.1087
          ],
          [
              -115.97938,
              46.10909
          ],
          [
              -115.97949,
              46.10933
          ],
          [
              -115.97953,
              46.10945
          ],
          [
              -115.97959,
              46.1097
          ],
          [
              -115.97959,
              46.10984
          ],
          [
              -115.97957,
              46.10999
          ],
          [
              -115.97954,
              46.11013
          ],
          [
              -115.97944,
              46.11044
          ],
          [
              -115.97932,
              46.11071
          ],
          [
              -115.97851,
              46.1128
          ],
          [
              -115.97816,
              46.11357
          ],
          [
              -115.97716,
              46.11555
          ],
          [
              -115.97698,
              46.11599
          ],
          [
              -115.97689,
              46.1164
          ],
          [
              -115.97687,
              46.11657
          ],
          [
              -115.97686,
              46.1169
          ],
          [
              -115.97687,
              46.11706
          ],
          [
              -115.97691,
              46.11728
          ],
          [
              -115.97691,
              46.11729
          ],
          [
              -115.97714,
              46.11818
          ],
          [
              -115.97721,
              46.11854
          ],
          [
              -115.97723,
              46.11888
          ],
          [
              -115.97716,
              46.12261
          ],
          [
              -115.97716,
              46.12327
          ],
          [
              -115.97711,
              46.1249
          ],
          [
              -115.97711,
              46.12572
          ],
          [
              -115.97708,
              46.12647
          ],
          [
              -115.97705,
              46.12809
          ],
          [
              -115.97706,
              46.12888
          ],
          [
              -115.97708,
              46.12926
          ],
          [
              -115.97712,
              46.12968
          ],
          [
              -115.97718,
              46.13
          ],
          [
              -115.97728,
              46.13034
          ],
          [
              -115.97739,
              46.13098
          ],
          [
              -115.97753,
              46.13157
          ],
          [
              -115.97759,
              46.13178
          ],
          [
              -115.97808,
              46.13397
          ],
          [
              -115.97834,
              46.13536
          ],
          [
              -115.97865,
              46.13671
          ],
          [
              -115.97905,
              46.14269
          ],
          [
              -115.97904,
              46.14277
          ],
          [
              -115.97904,
              46.14309
          ],
          [
              -115.979,
              46.14332
          ],
          [
              -115.97894,
              46.14356
          ],
          [
              -115.97887,
              46.14374
          ],
          [
              -115.97877,
              46.14391
          ],
          [
              -115.97848,
              46.14436
          ],
          [
              -115.97843,
              46.14446
          ],
          [
              -115.97839,
              46.14462
          ],
          [
              -115.97839,
              46.14489
          ],
          [
              -115.97811,
              46.14493
          ],
          [
              -115.97807,
              46.14493
          ],
          [
              -115.97738,
              46.14503
          ],
          [
              -115.97732,
              46.14503
          ],
          [
              -115.97676,
              46.14508
          ],
          [
              -115.97167,
              46.14515
          ],
          [
              -115.97137,
              46.14508
          ],
          [
              -115.971,
              46.14501
          ],
          [
              -115.97094,
              46.14498
          ],
          [
              -115.97075,
              46.14491
          ],
          [
              -115.97051,
              46.14479
          ],
          [
              -115.97024,
              46.14456
          ],
          [
              -115.97004,
              46.14437
          ],
          [
              -115.96892,
              46.14353
          ],
          [
              -115.96836,
              46.14305
          ],
          [
              -115.96801,
              46.14278
          ],
          [
              -115.96799,
              46.14277
          ],
          [
              -115.96751,
              46.14237
          ],
          [
              -115.96748,
              46.14235
          ],
          [
              -115.96717,
              46.14208
          ],
          [
              -115.96708,
              46.14193
          ],
          [
              -115.96704,
              46.14185
          ],
          [
              -115.96696,
              46.14153
          ],
          [
              -115.9668,
              46.14139
          ],
          [
              -115.96671,
              46.1413
          ],
          [
              -115.96632,
              46.14118
          ],
          [
              -115.96513,
              46.14105
          ],
          [
              -115.9651,
              46.14104
          ],
          [
              -115.96508,
              46.14104
          ],
          [
              -115.9643,
              46.14095
          ],
          [
              -115.96431,
              46.14057
          ],
          [
              -115.9643,
              46.14038
          ],
          [
              -115.96428,
              46.14028
          ],
          [
              -115.96427,
              46.14018
          ],
          [
              -115.96425,
              46.14007
          ],
          [
              -115.96419,
              46.13985
          ],
          [
              -115.96413,
              46.13969
          ],
          [
              -115.96399,
              46.13938
          ],
          [
              -115.96382,
              46.13907
          ],
          [
              -115.96381,
              46.13906
          ],
          [
              -115.96375,
              46.13896
          ],
          [
              -115.96358,
              46.13875
          ],
          [
              -115.96335,
              46.13849
          ],
          [
              -115.96278,
              46.13795
          ],
          [
              -115.96238,
              46.13762
          ],
          [
              -115.96235,
              46.13759
          ],
          [
              -115.96191,
              46.13728
          ],
          [
              -115.96153,
              46.13704
          ],
          [
              -115.96142,
              46.13696
          ],
          [
              -115.96092,
              46.13667
          ],
          [
              -115.96069,
              46.13655
          ],
          [
              -115.96011,
              46.1363
          ],
          [
              -115.95975,
              46.13619
          ],
          [
              -115.95927,
              46.13609
          ],
          [
              -115.95916,
              46.13606
          ],
          [
              -115.95896,
              46.13604
          ],
          [
              -115.95875,
              46.136
          ],
          [
              -115.95855,
              46.13599
          ],
          [
              -115.95841,
              46.13597
          ],
          [
              -115.95531,
              46.13584
          ],
          [
              -115.9552,
              46.13583
          ],
          [
              -115.95375,
              46.13577
          ],
          [
              -115.95334,
              46.13574
          ],
          [
              -115.95305,
              46.13573
          ],
          [
              -115.95026,
              46.13551
          ],
          [
              -115.94972,
              46.13548
          ],
          [
              -115.94952,
              46.13548
          ],
          [
              -115.94899,
              46.13554
          ],
          [
              -115.94893,
              46.13556
          ],
          [
              -115.94887,
              46.13557
          ],
          [
              -115.94856,
              46.13567
          ],
          [
              -115.94839,
              46.13574
          ],
          [
              -115.94833,
              46.13576
          ],
          [
              -115.94731,
              46.13623
          ],
          [
              -115.94704,
              46.13634
          ],
          [
              -115.94603,
              46.1367
          ],
          [
              -115.94575,
              46.13678
          ],
          [
              -115.94499,
              46.13704
          ],
          [
              -115.9445,
              46.13719
          ],
          [
              -115.94444,
              46.13722
          ],
          [
              -115.94437,
              46.13724
          ],
          [
              -115.94433,
              46.13726
          ],
          [
              -115.94407,
              46.13736
          ],
          [
              -115.94397,
              46.13741
          ],
          [
              -115.94373,
              46.13751
          ],
          [
              -115.94363,
              46.13756
          ],
          [
              -115.94357,
              46.1376
          ],
          [
              -115.9435,
              46.13763
          ],
          [
              -115.94288,
              46.13803
          ],
          [
              -115.94286,
              46.13804
          ],
          [
              -115.94253,
              46.13831
          ],
          [
              -115.94253,
              46.13832
          ],
          [
              -115.94235,
              46.13848
          ],
          [
              -115.94229,
              46.13854
          ],
          [
              -115.94212,
              46.13873
          ],
          [
              -115.94184,
              46.13912
          ],
          [
              -115.94174,
              46.13928
          ],
          [
              -115.94167,
              46.13941
          ],
          [
              -115.94166,
              46.13942
          ],
          [
              -115.94161,
              46.13954
          ],
          [
              -115.94156,
              46.13964
          ],
          [
              -115.94153,
              46.13974
          ],
          [
              -115.94152,
              46.13976
          ],
          [
              -115.94146,
              46.13994
          ],
          [
              -115.94146,
              46.13995
          ],
          [
              -115.94142,
              46.14011
          ],
          [
              -115.9414,
              46.14031
          ],
          [
              -115.9414,
              46.14065
          ],
          [
              -115.94144,
              46.141
          ],
          [
              -115.94144,
              46.14102
          ],
          [
              -115.94146,
              46.14109
          ],
          [
              -115.94148,
              46.14119
          ],
          [
              -115.94148,
              46.1412
          ],
          [
              -115.94166,
              46.14186
          ],
          [
              -115.94168,
              46.14202
          ],
          [
              -115.94168,
              46.14248
          ],
          [
              -115.94158,
              46.14359
          ],
          [
              -115.94154,
              46.14462
          ],
          [
              -115.94153,
              46.14473
          ],
          [
              -115.94148,
              46.14503
          ],
          [
              -115.94147,
              46.14513
          ],
          [
              -115.94143,
              46.14533
          ],
          [
              -115.94132,
              46.14569
          ],
          [
              -115.94125,
              46.14583
          ],
          [
              -115.94087,
              46.14647
          ],
          [
              -115.94061,
              46.14682
          ],
          [
              -115.94059,
              46.14684
          ],
          [
              -115.9405,
              46.14695
          ],
          [
              -115.94048,
              46.14697
          ],
          [
              -115.9404,
              46.14707
          ],
          [
              -115.94027,
              46.14719
          ],
          [
              -115.94021,
              46.14726
          ],
          [
              -115.94016,
              46.1473
          ],
          [
              -115.9401,
              46.14736
          ],
          [
              -115.93937,
              46.14794
          ],
          [
              -115.93872,
              46.1485
          ],
          [
              -115.93857,
              46.14865
          ],
          [
              -115.93842,
              46.14882
          ],
          [
              -115.93837,
              46.14889
          ],
          [
              -115.93813,
              46.14919
          ],
          [
              -115.938,
              46.14939
          ],
          [
              -115.93787,
              46.1497
          ],
          [
              -115.93778,
              46.14994
          ],
          [
              -115.9376,
              46.15054
          ],
          [
              -115.93751,
              46.15075
          ],
          [
              -115.93743,
              46.15091
          ],
          [
              -115.9372,
              46.15126
          ],
          [
              -115.93703,
              46.15148
          ],
          [
              -115.93693,
              46.15159
          ],
          [
              -115.93676,
              46.15174
          ],
          [
              -115.93661,
              46.15184
          ],
          [
              -115.9363,
              46.152
          ],
          [
              -115.93584,
              46.15219
          ],
          [
              -115.93557,
              46.15228
          ],
          [
              -115.93512,
              46.15239
          ],
          [
              -115.9351,
              46.15239
          ],
          [
              -115.93491,
              46.15242
          ],
          [
              -115.93445,
              46.15245
          ],
          [
              -115.93385,
              46.15244
          ],
          [
              -115.93333,
              46.15238
          ],
          [
              -115.93257,
              46.15222
          ],
          [
              -115.93255,
              46.15222
          ],
          [
              -115.93231,
              46.15215
          ],
          [
              -115.93199,
              46.15207
          ],
          [
              -115.93164,
              46.15195
          ],
          [
              -115.93114,
              46.15173
          ],
          [
              -115.93054,
              46.15142
          ],
          [
              -115.93051,
              46.15141
          ],
          [
              -115.93,
              46.15117
          ],
          [
              -115.92879,
              46.15065
          ],
          [
              -115.92761,
              46.15017
          ],
          [
              -115.92686,
              46.14991
          ],
          [
              -115.92634,
              46.14977
          ],
          [
              -115.92591,
              46.14968
          ],
          [
              -115.92521,
              46.14958
          ],
          [
              -115.92429,
              46.14955
          ],
          [
              -115.92377,
              46.14955
          ],
          [
              -115.92297,
              46.14959
          ],
          [
              -115.92234,
              46.14969
          ],
          [
              -115.92031,
              46.15014
          ],
          [
              -115.91948,
              46.15037
          ],
          [
              -115.91752,
              46.15102
          ],
          [
              -115.91436,
              46.15183
          ],
          [
              -115.91436,
              46.15184
          ],
          [
              -115.91059,
              46.1528
          ],
          [
              -115.90989,
              46.15296
          ],
          [
              -115.90947,
              46.15304
          ],
          [
              -115.90921,
              46.15308
          ],
          [
              -115.90851,
              46.15314
          ],
          [
              -115.90777,
              46.15315
          ],
          [
              -115.90663,
              46.15311
          ],
          [
              -115.9062,
              46.15308
          ],
          [
              -115.90583,
              46.15304
          ],
          [
              -115.90564,
              46.15301
          ],
          [
              -115.90513,
              46.1529
          ],
          [
              -115.90511,
              46.15289
          ],
          [
              -115.90489,
              46.15283
          ],
          [
              -115.90466,
              46.15275
          ],
          [
              -115.90432,
              46.1526
          ],
          [
              -115.90408,
              46.15248
          ],
          [
              -115.90393,
              46.15239
          ],
          [
              -115.90379,
              46.15229
          ],
          [
              -115.9036,
              46.15217
          ],
          [
              -115.90349,
              46.15208
          ],
          [
              -115.90335,
              46.15195
          ],
          [
              -115.90233,
              46.15107
          ],
          [
              -115.9017,
              46.15049
          ],
          [
              -115.90106,
              46.14983
          ],
          [
              -115.9007,
              46.14944
          ],
          [
              -115.90068,
              46.14941
          ],
          [
              -115.89914,
              46.14781
          ],
          [
              -115.89897,
              46.14762
          ],
          [
              -115.89843,
              46.14706
          ],
          [
              -115.89807,
              46.14673
          ],
          [
              -115.89782,
              46.14652
          ],
          [
              -115.89707,
              46.14596
          ],
          [
              -115.89536,
              46.14475
          ],
          [
              -115.89524,
              46.14468
          ],
          [
              -115.89516,
              46.14464
          ],
          [
              -115.8951,
              46.1446
          ],
          [
              -115.89489,
              46.14449
          ],
          [
              -115.89477,
              46.14444
          ],
          [
              -115.89348,
              46.14402
          ],
          [
              -115.89341,
              46.14399
          ],
          [
              -115.89201,
              46.14355
          ],
          [
              -115.89199,
              46.14355
          ],
          [
              -115.89117,
              46.14338
          ],
          [
              -115.88823,
              46.14288
          ],
          [
              -115.88581,
              46.14254
          ],
          [
              -115.88509,
              46.14247
          ],
          [
              -115.88484,
              46.14246
          ],
          [
              -115.88395,
              46.14248
          ],
          [
              -115.88315,
              46.14252
          ],
          [
              -115.88212,
              46.14252
          ],
          [
              -115.88198,
              46.14251
          ],
          [
              -115.88148,
              46.1425
          ],
          [
              -115.88064,
              46.14244
          ],
          [
              -115.88041,
              46.14241
          ],
          [
              -115.87987,
              46.14232
          ],
          [
              -115.87831,
              46.14202
          ],
          [
              -115.87766,
              46.14188
          ],
          [
              -115.87765,
              46.14188
          ],
          [
              -115.87676,
              46.1417
          ],
          [
              -115.87588,
              46.1415
          ],
          [
              -115.87192,
              46.14067
          ],
          [
              -115.87112,
              46.14054
          ],
          [
              -115.87043,
              46.14047
          ],
          [
              -115.86997,
              46.14045
          ],
          [
              -115.86945,
              46.14045
          ],
          [
              -115.86886,
              46.14048
          ],
          [
              -115.8677,
              46.1406
          ],
          [
              -115.86764,
              46.1406
          ],
          [
              -115.86675,
              46.14068
          ],
          [
              -115.86604,
              46.14076
          ],
          [
              -115.86451,
              46.1409
          ],
          [
              -115.86383,
              46.14102
          ],
          [
              -115.86306,
              46.14123
          ],
          [
              -115.86244,
              46.14142
          ],
          [
              -115.86056,
              46.14194
          ],
          [
              -115.85958,
              46.14213
          ],
          [
              -115.85921,
              46.14218
          ],
          [
              -115.8584,
              46.14226
          ],
          [
              -115.85762,
              46.14228
          ],
          [
              -115.85522,
              46.14219
          ],
          [
              -115.85437,
              46.14211
          ],
          [
              -115.85288,
              46.14192
          ],
          [
              -115.85212,
              46.1418
          ],
          [
              -115.85187,
              46.14177
          ],
          [
              -115.85138,
              46.14169
          ],
          [
              -115.85136,
              46.14169
          ],
          [
              -115.85105,
              46.14165
          ],
          [
              -115.85027,
              46.1416
          ],
          [
              -115.84978,
              46.14163
          ],
          [
              -115.84956,
              46.14166
          ],
          [
              -115.84953,
              46.14166
          ],
          [
              -115.8493,
              46.1417
          ],
          [
              -115.84891,
              46.14179
          ],
          [
              -115.84835,
              46.14195
          ],
          [
              -115.84794,
              46.14209
          ],
          [
              -115.84681,
              46.14256
          ],
          [
              -115.8465,
              46.1427
          ],
          [
              -115.84647,
              46.14272
          ],
          [
              -115.84507,
              46.14338
          ],
          [
              -115.84439,
              46.14374
          ],
          [
              -115.84398,
              46.144
          ],
          [
              -115.84333,
              46.14445
          ],
          [
              -115.84324,
              46.14452
          ],
          [
              -115.84066,
              46.14629
          ],
          [
              -115.8403,
              46.14656
          ],
          [
              -115.84012,
              46.14672
          ],
          [
              -115.83986,
              46.14693
          ],
          [
              -115.83973,
              46.14705
          ],
          [
              -115.83934,
              46.14747
          ],
          [
              -115.83913,
              46.14767
          ],
          [
              -115.83896,
              46.14779
          ],
          [
              -115.8386,
              46.14801
          ],
          [
              -115.83822,
              46.14816
          ],
          [
              -115.83821,
              46.14816
          ],
          [
              -115.83796,
              46.14823
          ],
          [
              -115.83735,
              46.14835
          ],
          [
              -115.83651,
              46.14849
          ],
          [
              -115.83544,
              46.1487
          ],
          [
              -115.83452,
              46.14896
          ],
          [
              -115.83414,
              46.14903
          ],
          [
              -115.83372,
              46.14907
          ],
          [
              -115.83346,
              46.14907
          ],
          [
              -115.83323,
              46.14906
          ],
          [
              -115.83258,
              46.14899
          ],
          [
              -115.83209,
              46.14891
          ],
          [
              -115.83186,
              46.14886
          ],
          [
              -115.83185,
              46.14885
          ],
          [
              -115.8315,
              46.14877
          ],
          [
              -115.82766,
              46.14764
          ],
          [
              -115.82746,
              46.14759
          ],
          [
              -115.82742,
              46.14757
          ],
          [
              -115.82553,
              46.147
          ],
          [
              -115.82437,
              46.14672
          ],
          [
              -115.8232,
              46.14649
          ],
          [
              -115.82231,
              46.14634
          ],
          [
              -115.82162,
              46.14624
          ],
          [
              -115.82094,
              46.14616
          ],
          [
              -115.81621,
              46.14571
          ],
          [
              -115.81597,
              46.14567
          ],
          [
              -115.8157,
              46.14561
          ],
          [
              -115.8156,
              46.14558
          ],
          [
              -115.81535,
              46.14552
          ],
          [
              -115.81493,
              46.14539
          ],
          [
              -115.81444,
              46.14521
          ],
          [
              -115.81427,
              46.14514
          ],
          [
              -115.8133,
              46.14465
          ],
          [
              -115.8131,
              46.14453
          ],
          [
              -115.81251,
              46.14414
          ],
          [
              -115.81185,
              46.14361
          ],
          [
              -115.81145,
              46.14324
          ],
          [
              -115.81104,
              46.14284
          ],
          [
              -115.81094,
              46.14276
          ],
          [
              -115.81081,
              46.14264
          ],
          [
              -115.81044,
              46.14235
          ],
          [
              -115.80979,
              46.14196
          ],
          [
              -115.80942,
              46.14176
          ],
          [
              -115.80895,
              46.14154
          ],
          [
              -115.80343,
              46.13922
          ],
          [
              -115.80112,
              46.13828
          ],
          [
              -115.79894,
              46.13736
          ],
          [
              -115.79827,
              46.1371
          ],
          [
              -115.79786,
              46.13696
          ],
          [
              -115.79775,
              46.13693
          ],
          [
              -115.79706,
              46.1367
          ],
          [
              -115.79667,
              46.1366
          ],
          [
              -115.79651,
              46.13655
          ],
          [
              -115.79529,
              46.13623
          ],
          [
              -115.79481,
              46.13609
          ],
          [
              -115.79436,
              46.13591
          ],
          [
              -115.79426,
              46.13586
          ],
          [
              -115.79411,
              46.1358
          ],
          [
              -115.79396,
              46.13573
          ],
          [
              -115.79318,
              46.1353
          ],
          [
              -115.79311,
              46.13527
          ],
          [
              -115.79246,
              46.13488
          ],
          [
              -115.79131,
              46.13412
          ],
          [
              -115.79129,
              46.1341
          ],
          [
              -115.79083,
              46.13374
          ],
          [
              -115.79002,
              46.13305
          ],
          [
              -115.79002,
              46.13304
          ],
          [
              -115.78986,
              46.1329
          ],
          [
              -115.78983,
              46.13288
          ],
          [
              -115.78971,
              46.13277
          ],
          [
              -115.78911,
              46.13214
          ],
          [
              -115.78872,
              46.13167
          ],
          [
              -115.78865,
              46.13157
          ],
          [
              -115.78858,
              46.13149
          ],
          [
              -115.78853,
              46.13141
          ],
          [
              -115.78846,
              46.13133
          ],
          [
              -115.78841,
              46.13125
          ],
          [
              -115.7884,
              46.13124
          ],
          [
              -115.78831,
              46.13111
          ],
          [
              -115.78783,
              46.13032
          ],
          [
              -115.78772,
              46.13009
          ],
          [
              -115.7877,
              46.13006
          ],
          [
              -115.78749,
              46.1296
          ],
          [
              -115.78723,
              46.12896
          ],
          [
              -115.78693,
              46.12817
          ],
          [
              -115.78671,
              46.12753
          ],
          [
              -115.78661,
              46.12727
          ],
          [
              -115.78644,
              46.12675
          ],
          [
              -115.78627,
              46.12634
          ],
          [
              -115.78593,
              46.12559
          ],
          [
              -115.78548,
              46.12484
          ],
          [
              -115.78494,
              46.12408
          ],
          [
              -115.78447,
              46.12347
          ],
          [
              -115.7828,
              46.12173
          ],
          [
              -115.78241,
              46.12137
          ],
          [
              -115.78207,
              46.1211
          ],
          [
              -115.78197,
              46.12103
          ],
          [
              -115.7816,
              46.12082
          ],
          [
              -115.78113,
              46.12062
          ],
          [
              -115.78054,
              46.12044
          ],
          [
              -115.77986,
              46.12034
          ],
          [
              -115.77899,
              46.12028
          ],
          [
              -115.777,
              46.12018
          ],
          [
              -115.7766,
              46.12017
          ],
          [
              -115.77606,
              46.12018
          ],
          [
              -115.77556,
              46.12023
          ],
          [
              -115.77487,
              46.12035
          ],
          [
              -115.77397,
              46.12056
          ],
          [
              -115.77323,
              46.12078
          ],
          [
              -115.77254,
              46.12102
          ],
          [
              -115.7709,
              46.12165
          ],
          [
              -115.76927,
              46.12233
          ],
          [
              -115.769,
              46.12247
          ],
          [
              -115.76867,
              46.12267
          ],
          [
              -115.76829,
              46.12294
          ],
          [
              -115.76818,
              46.12303
          ],
          [
              -115.76307,
              46.12795
          ],
          [
              -115.76066,
              46.13022
          ],
          [
              -115.75951,
              46.13119
          ],
          [
              -115.75906,
              46.13152
          ],
          [
              -115.75706,
              46.13286
          ],
          [
              -115.75627,
              46.13343
          ],
          [
              -115.7554,
              46.13399
          ],
          [
              -115.75537,
              46.13402
          ],
          [
              -115.7551,
              46.13419
          ],
          [
              -115.75479,
              46.13441
          ],
          [
              -115.75435,
              46.13478
          ],
          [
              -115.75334,
              46.13569
          ],
          [
              -115.75296,
              46.13598
          ],
          [
              -115.75278,
              46.13608
          ],
          [
              -115.75244,
              46.13624
          ],
          [
              -115.75214,
              46.13635
          ],
          [
              -115.7515,
              46.13654
          ],
          [
              -115.75127,
              46.13659
          ],
          [
              -115.75071,
              46.13668
          ],
          [
              -115.74997,
              46.13674
          ],
          [
              -115.74971,
              46.13675
          ],
          [
              -115.74963,
              46.13676
          ],
          [
              -115.74928,
              46.13676
          ],
          [
              -115.74881,
              46.13678
          ],
          [
              -115.74837,
              46.13683
          ],
          [
              -115.74793,
              46.13691
          ],
          [
              -115.74745,
              46.13704
          ],
          [
              -115.74625,
              46.13747
          ],
          [
              -115.74603,
              46.13754
          ],
          [
              -115.7451,
              46.13778
          ],
          [
              -115.74441,
              46.13798
          ],
          [
              -115.74436,
              46.13799
          ],
          [
              -115.74418,
              46.13806
          ],
          [
              -115.74385,
              46.13817
          ],
          [
              -115.74354,
              46.13835
          ],
          [
              -115.74316,
              46.13865
          ],
          [
              -115.74248,
              46.13929
          ],
          [
              -115.74241,
              46.13934
          ],
          [
              -115.7422,
              46.13951
          ],
          [
              -115.74168,
              46.13985
          ],
          [
              -115.7415,
              46.13998
          ],
          [
              -115.74149,
              46.13998
          ],
          [
              -115.74125,
              46.14016
          ],
          [
              -115.74098,
              46.14039
          ],
          [
              -115.74068,
              46.14068
          ],
          [
              -115.74065,
              46.1407
          ],
          [
              -115.74022,
              46.14111
          ],
          [
              -115.74019,
              46.14113
          ],
          [
              -115.74006,
              46.14125
          ],
          [
              -115.73973,
              46.1415
          ],
          [
              -115.73956,
              46.14161
          ],
          [
              -115.73951,
              46.14163
          ],
          [
              -115.7393,
              46.14175
          ],
          [
              -115.73894,
              46.14193
          ],
          [
              -115.73849,
              46.14219
          ],
          [
              -115.73831,
              46.14232
          ],
          [
              -115.73817,
              46.14241
          ],
          [
              -115.73813,
              46.14245
          ],
          [
              -115.73788,
              46.14264
          ],
          [
              -115.73779,
              46.14272
          ],
          [
              -115.73768,
              46.14284
          ],
          [
              -115.73755,
              46.14296
          ],
          [
              -115.73742,
              46.14311
          ],
          [
              -115.73701,
              46.14365
          ],
          [
              -115.73684,
              46.14394
          ],
          [
              -115.73607,
              46.14514
          ],
          [
              -115.73604,
              46.14518
          ],
          [
              -115.73568,
              46.1458
          ],
          [
              -115.73563,
              46.14587
          ],
          [
              -115.73508,
              46.14679
          ],
          [
              -115.73508,
              46.1468
          ],
          [
              -115.73485,
              46.14714
          ],
          [
              -115.73473,
              46.14728
          ],
          [
              -115.73465,
              46.14736
          ],
          [
              -115.73462,
              46.14738
          ],
          [
              -115.7345,
              46.14748
          ],
          [
              -115.73439,
              46.14755
          ],
          [
              -115.73421,
              46.14768
          ],
          [
              -115.73326,
              46.14828
          ],
          [
              -115.73252,
              46.14872
          ],
          [
              -115.73232,
              46.14883
          ],
          [
              -115.73196,
              46.149
          ],
          [
              -115.73172,
              46.14909
          ],
          [
              -115.73127,
              46.14924
          ],
          [
              -115.73011,
              46.14953
          ],
          [
              -115.73004,
              46.14954
          ],
          [
              -115.72746,
              46.15015
          ],
          [
              -115.72739,
              46.15027
          ],
          [
              -115.72726,
              46.15038
          ],
          [
              -115.72697,
              46.15052
          ],
          [
              -115.72694,
              46.15053
          ],
          [
              -115.72697,
              46.15052
          ],
          [
              -115.72726,
              46.15038
          ],
          [
              -115.72739,
              46.15027
          ],
          [
              -115.72746,
              46.15015
          ],
          [
              -115.72572,
              46.15056
          ],
          [
              -115.72546,
              46.15061
          ],
          [
              -115.725,
              46.15066
          ],
          [
              -115.7243,
              46.15065
          ],
          [
              -115.72366,
              46.1506
          ],
          [
              -115.72345,
              46.15056
          ],
          [
              -115.72282,
              46.15039
          ],
          [
              -115.72238,
              46.15021
          ],
          [
              -115.72212,
              46.15009
          ],
          [
              -115.72014,
              46.14898
          ],
          [
              -115.71965,
              46.14873
          ],
          [
              -115.71949,
              46.14866
          ],
          [
              -115.71912,
              46.14848
          ],
          [
              -115.71849,
              46.1482
          ],
          [
              -115.7168,
              46.14739
          ],
          [
              -115.714,
              46.14595
          ],
          [
              -115.7138,
              46.14583
          ],
          [
              -115.71274,
              46.1453
          ],
          [
              -115.71242,
              46.14516
          ],
          [
              -115.71186,
              46.14494
          ],
          [
              -115.71134,
              46.14476
          ],
          [
              -115.71118,
              46.14472
          ],
          [
              -115.71046,
              46.1445
          ],
          [
              -115.70957,
              46.14429
          ],
          [
              -115.70705,
              46.14378
          ],
          [
              -115.70704,
              46.14378
          ],
          [
              -115.70527,
              46.14338
          ],
          [
              -115.70418,
              46.14307
          ],
          [
              -115.70383,
              46.14296
          ],
          [
              -115.70279,
              46.14259
          ],
          [
              -115.70082,
              46.14177
          ],
          [
              -115.69986,
              46.14139
          ],
          [
              -115.69912,
              46.14114
          ],
          [
              -115.69799,
              46.14082
          ],
          [
              -115.69798,
              46.14082
          ],
          [
              -115.69779,
              46.14077
          ],
          [
              -115.69736,
              46.14068
          ],
          [
              -115.69682,
              46.14059
          ],
          [
              -115.69617,
              46.14051
          ],
          [
              -115.69072,
              46.14008
          ],
          [
              -115.69034,
              46.14003
          ],
          [
              -115.6903,
              46.14002
          ],
          [
              -115.69025,
              46.14002
          ],
          [
              -115.69016,
              46.14
          ],
          [
              -115.69008,
              46.13999
          ],
          [
              -115.68961,
              46.13989
          ],
          [
              -115.68916,
              46.13977
          ],
          [
              -115.68899,
              46.13971
          ],
          [
              -115.68881,
              46.13966
          ],
          [
              -115.68864,
              46.13959
          ],
          [
              -115.6886,
              46.13958
          ],
          [
              -115.68852,
              46.13954
          ],
          [
              -115.68815,
              46.1394
          ],
          [
              -115.68814,
              46.13939
          ],
          [
              -115.68794,
              46.1393
          ],
          [
              -115.68793,
              46.13929
          ],
          [
              -115.68782,
              46.13924
          ],
          [
              -115.6867,
              46.13864
          ],
          [
              -115.68667,
              46.13862
          ],
          [
              -115.6864,
              46.13849
          ],
          [
              -115.68628,
              46.13844
          ],
          [
              -115.68627,
              46.13844
          ],
          [
              -115.68598,
              46.13833
          ],
          [
              -115.68576,
              46.13826
          ],
          [
              -115.68568,
              46.13824
          ],
          [
              -115.6856,
              46.13821
          ],
          [
              -115.68487,
              46.13802
          ],
          [
              -115.68484,
              46.13802
          ],
          [
              -115.68344,
              46.13767
          ],
          [
              -115.68338,
              46.13765
          ],
          [
              -115.68243,
              46.13744
          ],
          [
              -115.68192,
              46.13738
          ],
          [
              -115.68183,
              46.13738
          ],
          [
              -115.68175,
              46.13737
          ],
          [
              -115.68165,
              46.13737
          ],
          [
              -115.68136,
              46.13735
          ],
          [
              -115.6786,
              46.13725
          ],
          [
              -115.67832,
              46.13725
          ],
          [
              -115.67817,
              46.13726
          ],
          [
              -115.67799,
              46.13726
          ],
          [
              -115.67786,
              46.13727
          ],
          [
              -115.67773,
              46.13727
          ],
          [
              -115.6777,
              46.13728
          ],
          [
              -115.67759,
              46.13728
          ],
          [
              -115.67734,
              46.13731
          ],
          [
              -115.67725,
              46.13731
          ],
          [
              -115.67132,
              46.13798
          ],
          [
              -115.67128,
              46.13799
          ],
          [
              -115.6711,
              46.13801
          ],
          [
              -115.6708,
              46.13807
          ],
          [
              -115.67053,
              46.13814
          ],
          [
              -115.67037,
              46.13819
          ],
          [
              -115.67027,
              46.13823
          ],
          [
              -115.67022,
              46.13824
          ],
          [
              -115.67016,
              46.13827
          ],
          [
              -115.67009,
              46.13829
          ],
          [
              -115.66961,
              46.13851
          ],
          [
              -115.66904,
              46.1388
          ],
          [
              -115.66894,
              46.13884
          ],
          [
              -115.66882,
              46.1389
          ],
          [
              -115.66881,
              46.1389
          ],
          [
              -115.66877,
              46.13891
          ],
          [
              -115.66859,
              46.13898
          ],
          [
              -115.66855,
              46.13899
          ],
          [
              -115.6685,
              46.13901
          ],
          [
              -115.66845,
              46.13902
          ],
          [
              -115.66835,
              46.13905
          ],
          [
              -115.66833,
              46.13906
          ],
          [
              -115.66825,
              46.13908
          ],
          [
              -115.66818,
              46.13909
          ],
          [
              -115.66811,
              46.13911
          ],
          [
              -115.6665,
              46.13937
          ],
          [
              -115.66507,
              46.13964
          ],
          [
              -115.66486,
              46.13966
          ],
          [
              -115.66481,
              46.13967
          ],
          [
              -115.66479,
              46.13967
          ],
          [
              -115.6646,
              46.13969
          ],
          [
              -115.66379,
              46.1397
          ],
          [
              -115.66352,
              46.13969
          ],
          [
              -115.66345,
              46.13968
          ],
          [
              -115.66338,
              46.13968
          ],
          [
              -115.6633,
              46.13967
          ],
          [
              -115.66322,
              46.13967
          ],
          [
              -115.66294,
              46.13964
          ],
          [
              -115.66284,
              46.13962
          ],
          [
              -115.66264,
              46.1396
          ],
          [
              -115.66244,
              46.13957
          ],
          [
              -115.66236,
              46.13955
          ],
          [
              -115.66224,
              46.13953
          ],
          [
              -115.66207,
              46.13948
          ],
          [
              -115.66197,
              46.13946
          ],
          [
              -115.66187,
              46.13943
          ],
          [
              -115.66179,
              46.1394
          ],
          [
              -115.66168,
              46.13937
          ],
          [
              -115.66139,
              46.13927
          ],
          [
              -115.6611,
              46.13914
          ],
          [
              -115.66108,
              46.13914
          ],
          [
              -115.6606,
              46.13891
          ],
          [
              -115.66037,
              46.13878
          ],
          [
              -115.66027,
              46.13871
          ],
          [
              -115.66025,
              46.1387
          ],
          [
              -115.66021,
              46.13867
          ],
          [
              -115.66006,
              46.13858
          ],
          [
              -115.66004,
              46.13856
          ],
          [
              -115.65988,
              46.13845
          ],
          [
              -115.65987,
              46.13844
          ],
          [
              -115.65974,
              46.13834
          ],
          [
              -115.65966,
              46.13829
          ],
          [
              -115.65957,
              46.13821
          ],
          [
              -115.65948,
              46.13815
          ],
          [
              -115.6593,
              46.13801
          ],
          [
              -115.65916,
              46.13793
          ],
          [
              -115.65912,
              46.1379
          ],
          [
              -115.65903,
              46.13785
          ],
          [
              -115.65897,
              46.13781
          ],
          [
              -115.65889,
              46.13777
          ],
          [
              -115.65884,
              46.13774
          ],
          [
              -115.65855,
              46.13761
          ],
          [
              -115.65854,
              46.13761
          ],
          [
              -115.65832,
              46.13753
          ],
          [
              -115.65831,
              46.13753
          ],
          [
              -115.65826,
              46.13752
          ],
          [
              -115.65822,
              46.1375
          ],
          [
              -115.65817,
              46.13749
          ],
          [
              -115.65811,
              46.13747
          ],
          [
              -115.65804,
              46.13746
          ],
          [
              -115.65781,
              46.13741
          ],
          [
              -115.65744,
              46.13736
          ],
          [
              -115.65739,
              46.13736
          ],
          [
              -115.65661,
              46.13728
          ],
          [
              -115.65651,
              46.13726
          ],
          [
              -115.65612,
              46.13721
          ],
          [
              -115.65591,
              46.13717
          ],
          [
              -115.65588,
              46.13716
          ],
          [
              -115.65586,
              46.13716
          ],
          [
              -115.65583,
              46.13715
          ],
          [
              -115.65581,
              46.13714
          ],
          [
              -115.65578,
              46.13714
          ],
          [
              -115.65564,
              46.13709
          ],
          [
              -115.65552,
              46.13706
          ],
          [
              -115.6544,
              46.13668
          ],
          [
              -115.65392,
              46.1365
          ],
          [
              -115.65388,
              46.13649
          ],
          [
              -115.6538,
              46.13646
          ],
          [
              -115.65375,
              46.13645
          ],
          [
              -115.65367,
              46.13642
          ],
          [
              -115.65364,
              46.13642
          ],
          [
              -115.65361,
              46.13641
          ],
          [
              -115.65359,
              46.1364
          ],
          [
              -115.65352,
              46.13638
          ],
          [
              -115.6531,
              46.1363
          ],
          [
              -115.65299,
              46.13629
          ],
          [
              -115.64912,
              46.1356
          ],
          [
              -115.64909,
              46.13559
          ],
          [
              -115.64884,
              46.13556
          ],
          [
              -115.64878,
              46.13556
          ],
          [
              -115.64866,
              46.13554
          ],
          [
              -115.64858,
              46.13554
          ],
          [
              -115.6485,
              46.13553
          ],
          [
              -115.64825,
              46.13553
          ],
          [
              -115.64818,
              46.13554
          ],
          [
              -115.64808,
              46.13554
          ],
          [
              -115.64799,
              46.13555
          ],
          [
              -115.64792,
              46.13555
          ],
          [
              -115.64761,
              46.13558
          ],
          [
              -115.64758,
              46.13559
          ],
          [
              -115.64742,
              46.13561
          ],
          [
              -115.64608,
              46.13586
          ],
          [
              -115.64511,
              46.13602
          ],
          [
              -115.64453,
              46.13614
          ],
          [
              -115.64176,
              46.13663
          ],
          [
              -115.64149,
              46.13667
          ],
          [
              -115.64138,
              46.13668
          ],
          [
              -115.64134,
              46.13669
          ],
          [
              -115.64118,
              46.13671
          ],
          [
              -115.63936,
              46.13685
          ],
          [
              -115.6388,
              46.13692
          ],
          [
              -115.63873,
              46.13694
          ],
          [
              -115.63856,
              46.13697
          ],
          [
              -115.6385,
              46.13699
          ],
          [
              -115.63843,
              46.137
          ],
          [
              -115.63838,
              46.13702
          ],
          [
              -115.63822,
              46.13706
          ],
          [
              -115.63814,
              46.13709
          ],
          [
              -115.63806,
              46.13711
          ],
          [
              -115.638,
              46.13713
          ],
          [
              -115.63796,
              46.13715
          ],
          [
              -115.63792,
              46.13716
          ],
          [
              -115.63615,
              46.1379
          ],
          [
              -115.63584,
              46.13801
          ],
          [
              -115.63583,
              46.13801
          ],
          [
              -115.63573,
              46.13805
          ],
          [
              -115.63564,
              46.13807
          ],
          [
              -115.63544,
              46.13813
          ],
          [
              -115.63483,
              46.13822
          ],
          [
              -115.63475,
              46.13822
          ],
          [
              -115.63466,
              46.13823
          ],
          [
              -115.63447,
              46.13823
          ],
          [
              -115.6344,
              46.13824
          ],
          [
              -115.63433,
              46.13823
          ],
          [
              -115.63412,
              46.13823
          ],
          [
              -115.63405,
              46.13822
          ],
          [
              -115.63394,
              46.13822
          ],
          [
              -115.63369,
              46.13819
          ],
          [
              -115.63251,
              46.13798
          ],
          [
              -115.63243,
              46.13796
          ],
          [
              -115.6323,
              46.13794
          ],
          [
              -115.63175,
              46.13783
          ],
          [
              -115.63125,
              46.13771
          ],
          [
              -115.6308,
              46.13758
          ],
          [
              -115.63071,
              46.13756
          ],
          [
              -115.63063,
              46.13753
          ],
          [
              -115.63046,
              46.13748
          ],
          [
              -115.63037,
              46.13744
          ],
          [
              -115.63024,
              46.13741
          ],
          [
              -115.63007,
              46.13734
          ],
          [
              -115.62816,
              46.13669
          ],
          [
              -115.62815,
              46.13669
          ],
          [
              -115.62796,
              46.13662
          ],
          [
              -115.62761,
              46.13651
          ],
          [
              -115.62755,
              46.1365
          ],
          [
              -115.6269,
              46.13632
          ],
          [
              -115.62629,
              46.13618
          ],
          [
              -115.62609,
              46.13615
          ],
          [
              -115.62597,
              46.13612
          ],
          [
              -115.62502,
              46.13596
          ],
          [
              -115.625,
              46.13596
          ],
          [
              -115.62353,
              46.13571
          ],
          [
              -115.62257,
              46.13552
          ],
          [
              -115.61844,
              46.13489
          ],
          [
              -115.61803,
              46.13484
          ],
          [
              -115.61365,
              46.13418
          ],
          [
              -115.61328,
              46.13415
          ],
          [
              -115.61269,
              46.13415
          ],
          [
              -115.61265,
              46.13416
          ],
          [
              -115.6126,
              46.13416
          ],
          [
              -115.61255,
              46.13417
          ],
          [
              -115.61248,
              46.13417
          ],
          [
              -115.61221,
              46.13422
          ],
          [
              -115.61215,
              46.13424
          ],
          [
              -115.61206,
              46.13426
          ],
          [
              -115.6118,
              46.13435
          ],
          [
              -115.61125,
              46.13457
          ],
          [
              -115.60547,
              46.13732
          ],
          [
              -115.60467,
              46.13774
          ],
          [
              -115.60458,
              46.1378
          ],
          [
              -115.60374,
              46.13828
          ],
          [
              -115.60358,
              46.13838
          ],
          [
              -115.60335,
              46.13851
          ],
          [
              -115.60323,
              46.13857
          ],
          [
              -115.60311,
              46.13864
          ],
          [
              -115.60304,
              46.13869
          ],
          [
              -115.60285,
              46.1388
          ],
          [
              -115.60275,
              46.13887
          ],
          [
              -115.60262,
              46.13895
          ],
          [
              -115.60214,
              46.13928
          ],
          [
              -115.60208,
              46.13933
          ],
          [
              -115.602,
              46.13938
          ],
          [
              -115.6019,
              46.13946
          ],
          [
              -115.60188,
              46.13948
          ],
          [
              -115.60179,
              46.13954
          ],
          [
              -115.60113,
              46.1401
          ],
          [
              -115.60112,
              46.1401
          ],
          [
              -115.60101,
              46.14021
          ],
          [
              -115.60018,
              46.14096
          ],
          [
              -115.59988,
              46.14125
          ],
          [
              -115.59967,
              46.14143
          ],
          [
              -115.59955,
              46.14155
          ],
          [
              -115.59918,
              46.14188
          ],
          [
              -115.59911,
              46.14196
          ],
          [
              -115.59903,
              46.14204
          ],
          [
              -115.59899,
              46.14209
          ],
          [
              -115.59895,
              46.14213
          ],
          [
              -115.5989,
              46.1422
          ],
          [
              -115.59889,
              46.1422
          ],
          [
              -115.59887,
              46.14224
          ],
          [
              -115.59884,
              46.14228
          ],
          [
              -115.59884,
              46.14229
          ],
          [
              -115.59881,
              46.14232
          ],
          [
              -115.59877,
              46.14239
          ],
          [
              -115.59867,
              46.1426
          ],
          [
              -115.59861,
              46.14276
          ],
          [
              -115.59837,
              46.1428
          ],
          [
              -115.59725,
              46.14303
          ],
          [
              -115.59722,
              46.14304
          ],
          [
              -115.5969,
              46.14305
          ],
          [
              -115.5968,
              46.14304
          ],
          [
              -115.59663,
              46.143
          ],
          [
              -115.59653,
              46.14297
          ],
          [
              -115.59646,
              46.14293
          ],
          [
              -115.59632,
              46.14282
          ],
          [
              -115.59624,
              46.14293
          ],
          [
              -115.59621,
              46.14296
          ],
          [
              -115.59618,
              46.14304
          ],
          [
              -115.59596,
              46.14398
          ],
          [
              -115.59618,
              46.14304
          ],
          [
              -115.59621,
              46.14296
          ],
          [
              -115.59624,
              46.14293
          ],
          [
              -115.59632,
              46.14282
          ],
          [
              -115.59646,
              46.14293
          ],
          [
              -115.59653,
              46.14297
          ],
          [
              -115.59663,
              46.143
          ],
          [
              -115.5968,
              46.14304
          ],
          [
              -115.5969,
              46.14305
          ],
          [
              -115.59722,
              46.14304
          ],
          [
              -115.59725,
              46.14303
          ],
          [
              -115.59837,
              46.1428
          ],
          [
              -115.59861,
              46.14276
          ],
          [
              -115.59854,
              46.14298
          ],
          [
              -115.59831,
              46.14419
          ],
          [
              -115.59827,
              46.14433
          ],
          [
              -115.59825,
              46.14444
          ],
          [
              -115.59817,
              46.14469
          ],
          [
              -115.59809,
              46.14489
          ],
          [
              -115.59806,
              46.14494
          ],
          [
              -115.59805,
              46.14497
          ],
          [
              -115.59802,
              46.14501
          ],
          [
              -115.59799,
              46.14507
          ],
          [
              -115.59782,
              46.14535
          ],
          [
              -115.5977,
              46.14552
          ],
          [
              -115.59765,
              46.14557
          ],
          [
              -115.59751,
              46.14574
          ],
          [
              -115.59729,
              46.14597
          ],
          [
              -115.59722,
              46.14603
          ],
          [
              -115.59715,
              46.14608
          ],
          [
              -115.59702,
              46.14619
          ],
          [
              -115.597,
              46.1462
          ],
          [
              -115.59695,
              46.14624
          ],
          [
              -115.59689,
              46.14628
          ],
          [
              -115.59684,
              46.14632
          ],
          [
              -115.59662,
              46.14645
          ],
          [
              -115.59646,
              46.14656
          ],
          [
              -115.59637,
              46.1466
          ],
          [
              -115.59623,
              46.14668
          ],
          [
              -115.59582,
              46.14688
          ],
          [
              -115.59571,
              46.14694
          ],
          [
              -115.5956,
              46.14699
          ],
          [
              -115.59549,
              46.14705
          ],
          [
              -115.5954,
              46.14709
          ],
          [
              -115.59501,
              46.14729
          ],
          [
              -115.59493,
              46.14732
          ],
          [
              -115.59466,
              46.14746
          ],
          [
              -115.59457,
              46.1475
          ],
          [
              -115.5945,
              46.14754
          ],
          [
              -115.59442,
              46.14757
          ],
          [
              -115.59432,
              46.14762
          ],
          [
              -115.59413,
              46.14769
          ],
          [
              -115.59404,
              46.14773
          ],
          [
              -115.59382,
              46.1478
          ],
          [
              -115.59346,
              46.14788
          ],
          [
              -115.59338,
              46.14789
          ],
          [
              -115.59329,
              46.14791
          ],
          [
              -115.59194,
              46.14809
          ],
          [
              -115.59158,
              46.14817
          ],
          [
              -115.5915,
              46.1482
          ],
          [
              -115.59141,
              46.14822
          ],
          [
              -115.59131,
              46.14825
          ],
          [
              -115.59093,
              46.14839
          ],
          [
              -115.5909,
              46.14841
          ],
          [
              -115.59082,
              46.14844
          ],
          [
              -115.59055,
              46.14857
          ],
          [
              -115.59051,
              46.1486
          ],
          [
              -115.59047,
              46.14862
          ],
          [
              -115.59025,
              46.14876
          ],
          [
              -115.59024,
              46.14877
          ],
          [
              -115.58961,
              46.14921
          ],
          [
              -115.58947,
              46.14932
          ],
          [
              -115.58915,
              46.14955
          ],
          [
              -115.58913,
              46.14956
          ],
          [
              -115.58902,
              46.14964
          ],
          [
              -115.58898,
              46.14968
          ],
          [
              -115.58809,
              46.15032
          ],
          [
              -115.58743,
              46.15084
          ],
          [
              -115.58715,
              46.15109
          ],
          [
              -115.58703,
              46.15121
          ],
          [
              -115.58678,
              46.15152
          ],
          [
              -115.58669,
              46.15162
          ],
          [
              -115.58668,
              46.15164
          ],
          [
              -115.58657,
              46.15176
          ],
          [
              -115.58656,
              46.15178
          ],
          [
              -115.58643,
              46.15193
          ],
          [
              -115.58622,
              46.15215
          ],
          [
              -115.58601,
              46.15239
          ],
          [
              -115.58575,
              46.15265
          ],
          [
              -115.58511,
              46.15336
          ],
          [
              -115.58455,
              46.15392
          ],
          [
              -115.58447,
              46.15401
          ],
          [
              -115.58427,
              46.15427
          ],
          [
              -115.58408,
              46.15455
          ],
          [
              -115.58387,
              46.15491
          ],
          [
              -115.58376,
              46.15519
          ],
          [
              -115.58374,
              46.15526
          ],
          [
              -115.58372,
              46.15538
          ],
          [
              -115.58372,
              46.15544
          ],
          [
              -115.58371,
              46.1555
          ],
          [
              -115.58372,
              46.15558
          ],
          [
              -115.58372,
              46.15565
          ],
          [
              -115.58375,
              46.15586
          ],
          [
              -115.58377,
              46.15593
          ],
          [
              -115.58379,
              46.15597
          ],
          [
              -115.58381,
              46.15603
          ],
          [
              -115.58382,
              46.15608
          ],
          [
              -115.58393,
              46.15628
          ],
          [
              -115.58406,
              46.15645
          ],
          [
              -115.5841,
              46.15651
          ],
          [
              -115.58416,
              46.15657
          ],
          [
              -115.58431,
              46.15669
          ],
          [
              -115.5844,
              46.15675
          ],
          [
              -115.58449,
              46.15682
          ],
          [
              -115.58456,
              46.15686
          ],
          [
              -115.58463,
              46.15691
          ],
          [
              -115.58489,
              46.15707
          ],
          [
              -115.5849,
              46.15707
          ],
          [
              -115.58494,
              46.1571
          ],
          [
              -115.58514,
              46.1572
          ],
          [
              -115.58524,
              46.15724
          ],
          [
              -115.58547,
              46.15735
          ],
          [
              -115.58578,
              46.15747
          ],
          [
              -115.58858,
              46.15839
          ],
          [
              -115.58859,
              46.15839
          ],
          [
              -115.59004,
              46.15888
          ],
          [
              -115.59016,
              46.15893
          ],
          [
              -115.59049,
              46.1591
          ],
          [
              -115.59071,
              46.15924
          ],
          [
              -115.59082,
              46.15932
          ],
          [
              -115.59088,
              46.15937
          ],
          [
              -115.5911,
              46.15959
          ],
          [
              -115.59113,
              46.15964
          ],
          [
              -115.59118,
              46.1597
          ],
          [
              -115.59125,
              46.15981
          ],
          [
              -115.59129,
              46.15989
          ],
          [
              -115.59135,
              46.15999
          ],
          [
              -115.59142,
              46.16017
          ],
          [
              -115.59147,
              46.16034
          ],
          [
              -115.59151,
              46.16054
          ],
          [
              -115.59151,
              46.16055
          ],
          [
              -115.59153,
              46.16068
          ],
          [
              -115.59155,
              46.16092
          ],
          [
              -115.59155,
              46.16103
          ],
          [
              -115.59156,
              46.16112
          ],
          [
              -115.59156,
              46.16125
          ],
          [
              -115.59153,
              46.1616
          ],
          [
              -115.59153,
              46.16169
          ],
          [
              -115.5915,
              46.1619
          ],
          [
              -115.59148,
              46.16197
          ],
          [
              -115.59146,
              46.16207
          ],
          [
              -115.59135,
              46.16248
          ],
          [
              -115.59119,
              46.16291
          ],
          [
              -115.59113,
              46.16304
          ],
          [
              -115.59104,
              46.1632
          ],
          [
              -115.59088,
              46.16344
          ],
          [
              -115.59046,
              46.16397
          ],
          [
              -115.59019,
              46.16427
          ],
          [
              -115.59016,
              46.16431
          ],
          [
              -115.59006,
              46.1644
          ],
          [
              -115.58985,
              46.16462
          ],
          [
              -115.58977,
              46.16469
          ],
          [
              -115.58955,
              46.16491
          ],
          [
              -115.58923,
              46.16518
          ],
          [
              -115.58856,
              46.16568
          ],
          [
              -115.58848,
              46.16573
          ],
          [
              -115.58842,
              46.16576
          ],
          [
              -115.58799,
              46.16604
          ],
          [
              -115.58736,
              46.1664
          ],
          [
              -115.58725,
              46.16645
          ],
          [
              -115.58716,
              46.1665
          ],
          [
              -115.58707,
              46.16654
          ],
          [
              -115.58686,
              46.16665
          ],
          [
              -115.58639,
              46.16685
          ],
          [
              -115.58593,
              46.16703
          ],
          [
              -115.58574,
              46.16709
          ],
          [
              -115.58559,
              46.16715
          ],
          [
              -115.58526,
              46.16726
          ],
          [
              -115.58484,
              46.16738
          ],
          [
              -115.58467,
              46.16742
          ],
          [
              -115.58446,
              46.16749
          ],
          [
              -115.58435,
              46.16752
          ],
          [
              -115.58425,
              46.16754
          ],
          [
              -115.58413,
              46.16759
          ],
          [
              -115.58412,
              46.16759
          ],
          [
              -115.58364,
              46.16776
          ],
          [
              -115.5836,
              46.16778
          ],
          [
              -115.58329,
              46.16789
          ],
          [
              -115.58247,
              46.16823
          ],
          [
              -115.58219,
              46.16833
          ],
          [
              -115.5821,
              46.16835
          ],
          [
              -115.582,
              46.16838
          ],
          [
              -115.58143,
              46.16849
          ],
          [
              -115.5813,
              46.1685
          ],
          [
              -115.58117,
              46.16852
          ],
          [
              -115.58078,
              46.16855
          ],
          [
              -115.58017,
              46.16855
          ],
          [
              -115.57957,
              46.16852
          ],
          [
              -115.57827,
              46.16837
          ],
          [
              -115.57823,
              46.16836
          ],
          [
              -115.57808,
              46.16834
          ],
          [
              -115.57799,
              46.16832
          ],
          [
              -115.57789,
              46.16831
          ],
          [
              -115.5775,
              46.16825
          ],
          [
              -115.57673,
              46.1681
          ],
          [
              -115.57573,
              46.16786
          ],
          [
              -115.57557,
              46.16783
          ],
          [
              -115.57542,
              46.16781
          ],
          [
              -115.57541,
              46.1678
          ],
          [
              -115.57494,
              46.16774
          ],
          [
              -115.57487,
              46.16774
          ],
          [
              -115.57482,
              46.16773
          ],
          [
              -115.57473,
              46.16773
          ],
          [
              -115.57462,
              46.16772
          ],
          [
              -115.57392,
              46.16772
          ],
          [
              -115.57387,
              46.16773
          ],
          [
              -115.57384,
              46.16773
          ],
          [
              -115.57378,
              46.16774
          ],
          [
              -115.5737,
              46.16774
          ],
          [
              -115.57362,
              46.16775
          ],
          [
              -115.57352,
              46.16777
          ],
          [
              -115.57342,
              46.16778
          ],
          [
              -115.57297,
              46.16787
          ],
          [
              -115.57289,
              46.16788
          ],
          [
              -115.5725,
              46.16799
          ],
          [
              -115.57211,
              46.16812
          ],
          [
              -115.57198,
              46.16818
          ],
          [
              -115.57186,
              46.16822
          ],
          [
              -115.57165,
              46.16833
          ],
          [
              -115.57156,
              46.16837
          ],
          [
              -115.57143,
              46.16844
          ],
          [
              -115.57123,
              46.16857
          ],
          [
              -115.57118,
              46.16861
          ],
          [
              -115.5711,
              46.16866
          ],
          [
              -115.57093,
              46.16878
          ],
          [
              -115.57082,
              46.16887
          ],
          [
              -115.57077,
              46.16892
          ],
          [
              -115.5707,
              46.16898
          ],
          [
              -115.57063,
              46.16905
          ],
          [
              -115.57061,
              46.16906
          ],
          [
              -115.57043,
              46.16924
          ],
          [
              -115.57038,
              46.1693
          ],
          [
              -115.57034,
              46.16936
          ],
          [
              -115.57029,
              46.16942
          ],
          [
              -115.57015,
              46.16963
          ],
          [
              -115.56998,
              46.16996
          ],
          [
              -115.56996,
              46.17003
          ],
          [
              -115.56988,
              46.17023
          ],
          [
              -115.56977,
              46.17058
          ],
          [
              -115.56973,
              46.17081
          ],
          [
              -115.56973,
              46.17089
          ],
          [
              -115.56972,
              46.17095
          ],
          [
              -115.56972,
              46.17116
          ],
          [
              -115.56975,
              46.1714
          ],
          [
              -115.56987,
              46.1719
          ],
          [
              -115.56997,
              46.17218
          ],
          [
              -115.57003,
              46.17229
          ],
          [
              -115.57011,
              46.17247
          ],
          [
              -115.57013,
              46.1725
          ],
          [
              -115.57019,
              46.17262
          ],
          [
              -115.57056,
              46.17349
          ],
          [
              -115.57057,
              46.17353
          ],
          [
              -115.57061,
              46.17364
          ],
          [
              -115.57065,
              46.17373
          ],
          [
              -115.57067,
              46.17381
          ],
          [
              -115.57072,
              46.17393
          ],
          [
              -115.57078,
              46.1742
          ],
          [
              -115.57082,
              46.17433
          ],
          [
              -115.57095,
              46.17509
          ],
          [
              -115.57099,
              46.17539
          ],
          [
              -115.571,
              46.17557
          ],
          [
              -115.57102,
              46.17569
          ],
          [
              -115.57104,
              46.17596
          ],
          [
              -115.57104,
              46.1761
          ],
          [
              -115.57105,
              46.17619
          ],
          [
              -115.57104,
              46.1763
          ],
          [
              -115.57104,
              46.17647
          ],
          [
              -115.57102,
              46.17683
          ],
          [
              -115.57102,
              46.17747
          ],
          [
              -115.57105,
              46.17774
          ],
          [
              -115.57109,
              46.17796
          ],
          [
              -115.57116,
              46.17823
          ],
          [
              -115.57124,
              46.17847
          ],
          [
              -115.57138,
              46.17878
          ],
          [
              -115.57153,
              46.17907
          ],
          [
              -115.57171,
              46.17937
          ],
          [
              -115.57178,
              46.1795
          ],
          [
              -115.57188,
              46.17965
          ],
          [
              -115.57194,
              46.17976
          ],
          [
              -115.57201,
              46.17987
          ],
          [
              -115.5721,
              46.18004
          ],
          [
              -115.57306,
              46.18162
          ],
          [
              -115.57311,
              46.18169
          ],
          [
              -115.57318,
              46.18181
          ],
          [
              -115.57325,
              46.18195
          ],
          [
              -115.57327,
              46.182
          ],
          [
              -115.57328,
              46.18204
          ],
          [
              -115.57329,
              46.18206
          ],
          [
              -115.57333,
              46.18219
          ],
          [
              -115.57335,
              46.18224
          ],
          [
              -115.57336,
              46.18228
          ],
          [
              -115.57336,
              46.18233
          ],
          [
              -115.57337,
              46.18238
          ],
          [
              -115.57337,
              46.18242
          ],
          [
              -115.57338,
              46.18246
          ],
          [
              -115.57338,
              46.18274
          ],
          [
              -115.57337,
              46.18275
          ],
          [
              -115.57337,
              46.18281
          ],
          [
              -115.57335,
              46.18288
          ],
          [
              -115.57334,
              46.18293
          ],
          [
              -115.57334,
              46.18296
          ],
          [
              -115.57333,
              46.18299
          ],
          [
              -115.5733,
              46.18305
          ],
          [
              -115.57329,
              46.18309
          ],
          [
              -115.57327,
              46.18313
          ],
          [
              -115.57326,
              46.18316
          ],
          [
              -115.57323,
              46.1832
          ],
          [
              -115.57321,
              46.18325
          ],
          [
              -115.57317,
              46.18331
          ],
          [
              -115.57313,
              46.18335
          ],
          [
              -115.57309,
              46.18341
          ],
          [
              -115.57284,
              46.18371
          ],
          [
              -115.5727,
              46.18385
          ],
          [
              -115.57256,
              46.18396
          ],
          [
              -115.57243,
              46.18405
          ],
          [
              -115.57234,
              46.18412
          ],
          [
              -115.57224,
              46.18418
          ],
          [
              -115.57216,
              46.18424
          ],
          [
              -115.57183,
              46.18443
          ],
          [
              -115.57133,
              46.18467
          ],
          [
              -115.57071,
              46.1849
          ],
          [
              -115.57052,
              46.18496
          ],
          [
              -115.57026,
              46.18503
          ],
          [
              -115.56915,
              46.18524
          ],
          [
              -115.56886,
              46.18528
          ],
          [
              -115.56871,
              46.18531
          ],
          [
              -115.56783,
              46.18545
          ],
          [
              -115.56765,
              46.18549
          ],
          [
              -115.56757,
              46.1855
          ],
          [
              -115.56621,
              46.18576
          ],
          [
              -115.56607,
              46.1858
          ],
          [
              -115.56594,
              46.18582
          ],
          [
              -115.56239,
              46.18668
          ],
          [
              -115.56214,
              46.18673
          ],
          [
              -115.56116,
              46.18698
          ],
          [
              -115.56091,
              46.18706
          ],
          [
              -115.56081,
              46.1871
          ],
          [
              -115.56071,
              46.18713
          ],
          [
              -115.56058,
              46.18718
          ],
          [
              -115.56047,
              46.18723
          ],
          [
              -115.56034,
              46.18728
          ],
          [
              -115.56023,
              46.18734
          ],
          [
              -115.56004,
              46.18743
          ],
          [
              -115.55984,
              46.18754
          ],
          [
              -115.55983,
              46.18754
          ],
          [
              -115.55968,
              46.18763
          ],
          [
              -115.55952,
              46.18774
          ],
          [
              -115.55921,
              46.18799
          ],
          [
              -115.5582,
              46.18897
          ],
          [
              -115.55675,
              46.19046
          ],
          [
              -115.55662,
              46.19058
          ],
          [
              -115.55653,
              46.19068
          ],
          [
              -115.55603,
              46.19117
          ],
          [
              -115.55596,
              46.19125
          ],
          [
              -115.55588,
              46.19133
          ],
          [
              -115.5558,
              46.1914
          ],
          [
              -115.5557,
              46.1915
          ],
          [
              -115.55537,
              46.19178
          ],
          [
              -115.55507,
              46.19201
          ],
          [
              -115.5548,
              46.19219
          ],
          [
              -115.55468,
              46.19226
          ],
          [
              -115.5543,
              46.19244
          ],
          [
              -115.55384,
              46.1926
          ],
          [
              -115.55379,
              46.19261
          ],
          [
              -115.55367,
              46.19265
          ],
          [
              -115.55326,
              46.19273
          ],
          [
              -115.55292,
              46.19278
          ],
          [
              -115.55286,
              46.19278
          ],
          [
              -115.5528,
              46.19279
          ],
          [
              -115.55273,
              46.19279
          ],
          [
              -115.55266,
              46.1928
          ],
          [
              -115.55219,
              46.1928
          ],
          [
              -115.55212,
              46.19279
          ],
          [
              -115.55205,
              46.19279
          ],
          [
              -115.55161,
              46.19273
          ],
          [
              -115.55152,
              46.19271
          ],
          [
              -115.55149,
              46.1927
          ],
          [
              -115.55139,
              46.19268
          ],
          [
              -115.55133,
              46.19266
          ],
          [
              -115.55124,
              46.19264
          ],
          [
              -115.55115,
              46.19261
          ],
          [
              -115.55107,
              46.19259
          ],
          [
              -115.55061,
              46.19242
          ],
          [
              -115.55045,
              46.19237
          ],
          [
              -115.55006,
              46.19223
          ],
          [
              -115.54992,
              46.19219
          ],
          [
              -115.54981,
              46.19215
          ],
          [
              -115.54975,
              46.19214
          ],
          [
              -115.54967,
              46.19212
          ],
          [
              -115.54961,
              46.1921
          ],
          [
              -115.54956,
              46.19209
          ],
          [
              -115.54955,
              46.19209
          ],
          [
              -115.54945,
              46.19207
          ],
          [
              -115.54939,
              46.19205
          ],
          [
              -115.54909,
              46.19201
          ],
          [
              -115.54899,
              46.19199
          ],
          [
              -115.5489,
              46.19199
          ],
          [
              -115.54873,
              46.19197
          ],
          [
              -115.54831,
              46.19197
          ],
          [
              -115.54818,
              46.19199
          ],
          [
              -115.54812,
              46.19199
          ],
          [
              -115.54803,
              46.19201
          ],
          [
              -115.54792,
              46.19202
          ],
          [
              -115.54782,
              46.19204
          ],
          [
              -115.54774,
              46.19205
          ],
          [
              -115.54764,
              46.19207
          ],
          [
              -115.54723,
              46.19218
          ],
          [
              -115.54717,
              46.19221
          ],
          [
              -115.54709,
              46.19223
          ],
          [
              -115.54691,
              46.1923
          ],
          [
              -115.54679,
              46.19236
          ],
          [
              -115.54674,
              46.19239
          ],
          [
              -115.54667,
              46.19242
          ],
          [
              -115.54653,
              46.1925
          ],
          [
              -115.54636,
              46.19261
          ],
          [
              -115.54631,
              46.19265
          ],
          [
              -115.54626,
              46.19268
          ],
          [
              -115.54617,
              46.19275
          ],
          [
              -115.54611,
              46.19279
          ],
          [
              -115.54597,
              46.1929
          ],
          [
              -115.54588,
              46.19299
          ],
          [
              -115.54582,
              46.19304
          ],
          [
              -115.54576,
              46.19311
          ],
          [
              -115.54565,
              46.19322
          ],
          [
              -115.54545,
              46.19345
          ],
          [
              -115.5454,
              46.19352
          ],
          [
              -115.54531,
              46.19363
          ],
          [
              -115.54526,
              46.19372
          ],
          [
              -115.54514,
              46.19391
          ],
          [
              -115.54505,
              46.19409
          ],
          [
              -115.54499,
              46.19425
          ],
          [
              -115.54495,
              46.19438
          ],
          [
              -115.54494,
              46.19443
          ],
          [
              -115.54492,
              46.19447
          ],
          [
              -115.54489,
              46.19463
          ],
          [
              -115.54489,
              46.19468
          ],
          [
              -115.54488,
              46.19473
          ],
          [
              -115.54488,
              46.19484
          ],
          [
              -115.54487,
              46.1949
          ],
          [
              -115.54487,
              46.19518
          ],
          [
              -115.54489,
              46.19529
          ],
          [
              -115.54493,
              46.19545
          ],
          [
              -115.54495,
              46.19549
          ],
          [
              -115.54496,
              46.19554
          ],
          [
              -115.54498,
              46.19558
          ],
          [
              -115.54499,
              46.19562
          ],
          [
              -115.54502,
              46.19567
          ],
          [
              -115.54504,
              46.19572
          ],
          [
              -115.54507,
              46.19578
          ],
          [
              -115.54511,
              46.19583
          ],
          [
              -115.54518,
              46.19595
          ],
          [
              -115.54537,
              46.1962
          ],
          [
              -115.54543,
              46.19626
          ],
          [
              -115.54551,
              46.19636
          ],
          [
              -115.54557,
              46.19642
          ],
          [
              -115.54563,
              46.19647
          ],
          [
              -115.54566,
              46.1965
          ],
          [
              -115.5457,
              46.19653
          ],
          [
              -115.54575,
              46.19658
          ],
          [
              -115.54595,
              46.19675
          ],
          [
              -115.54603,
              46.1968
          ],
          [
              -115.54614,
              46.19689
          ],
          [
              -115.54644,
              46.19711
          ],
          [
              -115.54651,
              46.19717
          ],
          [
              -115.54665,
              46.19727
          ],
          [
              -115.54671,
              46.19733
          ],
          [
              -115.54677,
              46.19737
          ],
          [
              -115.54681,
              46.19741
          ],
          [
              -115.54686,
              46.19745
          ],
          [
              -115.5469,
              46.1975
          ],
          [
              -115.54699,
              46.19759
          ],
          [
              -115.54703,
              46.19764
          ],
          [
              -115.54707,
              46.19768
          ],
          [
              -115.54712,
              46.19774
          ],
          [
              -115.54728,
              46.19797
          ],
          [
              -115.54732,
              46.19804
          ],
          [
              -115.54742,
              46.19819
          ],
          [
              -115.54753,
              46.19841
          ],
          [
              -115.5476,
              46.19853
          ],
          [
              -115.54764,
              46.19863
          ],
          [
              -115.54768,
              46.19871
          ],
          [
              -115.54781,
              46.19911
          ],
          [
              -115.54785,
              46.19931
          ],
          [
              -115.5479,
              46.19963
          ],
          [
              -115.5479,
              46.19971
          ],
          [
              -115.54791,
              46.1998
          ],
          [
              -115.54791,
              46.20002
          ],
          [
              -115.5479,
              46.20011
          ],
          [
              -115.5479,
              46.20018
          ],
          [
              -115.54788,
              46.20033
          ],
          [
              -115.54786,
              46.20041
          ],
          [
              -115.54785,
              46.20048
          ],
          [
              -115.54781,
              46.20064
          ],
          [
              -115.54781,
              46.20067
          ],
          [
              -115.54779,
              46.20073
          ],
          [
              -115.54778,
              46.20082
          ],
          [
              -115.54776,
              46.20088
          ],
          [
              -115.5477,
              46.20112
          ],
          [
              -115.54759,
              46.20145
          ],
          [
              -115.54756,
              46.2015
          ],
          [
              -115.5474,
              46.20184
          ],
          [
              -115.54719,
              46.20219
          ],
          [
              -115.54714,
              46.20229
          ],
          [
              -115.547,
              46.20253
          ],
          [
              -115.5469,
              46.20273
          ],
          [
              -115.5469,
              46.20274
          ],
          [
              -115.54676,
              46.20302
          ],
          [
              -115.54655,
              46.20352
          ],
          [
              -115.54642,
              46.20393
          ],
          [
              -115.54635,
              46.20419
          ],
          [
              -115.5463,
              46.20433
          ],
          [
              -115.54623,
              46.20462
          ],
          [
              -115.54616,
              46.20484
          ],
          [
              -115.54612,
              46.20499
          ],
          [
              -115.54609,
              46.20505
          ],
          [
              -115.54592,
              46.2055
          ],
          [
              -115.54579,
              46.2058
          ],
          [
              -115.54567,
              46.20603
          ],
          [
              -115.5456,
              46.20614
          ],
          [
              -115.54557,
              46.20621
          ],
          [
              -115.54547,
              46.20637
          ],
          [
              -115.54546,
              46.2064
          ],
          [
              -115.54538,
              46.20652
          ],
          [
              -115.54531,
              46.20665
          ],
          [
              -115.54526,
              46.20671
          ],
          [
              -115.54522,
              46.20678
          ],
          [
              -115.54507,
              46.20701
          ],
          [
              -115.54502,
              46.20707
          ],
          [
              -115.54498,
              46.20713
          ],
          [
              -115.54483,
              46.20731
          ],
          [
              -115.54477,
              46.20737
          ],
          [
              -115.54473,
              46.20742
          ],
          [
              -115.54467,
              46.20747
          ],
          [
              -115.5445,
              46.20764
          ],
          [
              -115.54447,
              46.20768
          ],
          [
              -115.5444,
              46.20773
          ],
          [
              -115.54437,
              46.20776
          ],
          [
              -115.54418,
              46.20791
          ],
          [
              -115.54391,
              46.2081
          ],
          [
              -115.54378,
              46.20818
          ],
          [
              -115.54346,
              46.2084
          ],
          [
              -115.54336,
              46.20848
          ],
          [
              -115.54323,
              46.20857
          ],
          [
              -115.54317,
              46.20863
          ],
          [
              -115.5431,
              46.20868
          ],
          [
              -115.54289,
              46.20888
          ],
          [
              -115.5427,
              46.2091
          ],
          [
              -115.54265,
              46.20917
          ],
          [
              -115.5426,
              46.20927
          ],
          [
              -115.54255,
              46.20935
          ],
          [
              -115.54253,
              46.20941
          ],
          [
              -115.5425,
              46.20947
          ],
          [
              -115.54248,
              46.2095
          ],
          [
              -115.54247,
              46.20953
          ],
          [
              -115.54241,
              46.20979
          ],
          [
              -115.54238,
              46.21005
          ],
          [
              -115.54238,
              46.21033
          ],
          [
              -115.54242,
              46.21079
          ],
          [
              -115.54242,
              46.2111
          ],
          [
              -115.54241,
              46.21115
          ],
          [
              -115.54241,
              46.21118
          ],
          [
              -115.54235,
              46.21138
          ],
          [
              -115.54229,
              46.21153
          ],
          [
              -115.54216,
              46.21181
          ],
          [
              -115.54211,
              46.21189
          ],
          [
              -115.54208,
              46.21195
          ],
          [
              -115.54207,
              46.21195
          ],
          [
              -115.54204,
              46.21201
          ],
          [
              -115.54184,
              46.21228
          ],
          [
              -115.5418,
              46.21231
          ],
          [
              -115.54177,
              46.21235
          ],
          [
              -115.54167,
              46.21245
          ],
          [
              -115.5416,
              46.21251
          ],
          [
              -115.54154,
              46.21257
          ],
          [
              -115.54148,
              46.21261
          ],
          [
              -115.54134,
              46.21272
          ],
          [
              -115.54103,
              46.2129
          ],
          [
              -115.54097,
              46.21293
          ],
          [
              -115.54091,
              46.21297
          ],
          [
              -115.54084,
              46.21299
          ],
          [
              -115.54075,
              46.21304
          ],
          [
              -115.54047,
              46.21315
          ],
          [
              -115.54038,
              46.21318
          ],
          [
              -115.54029,
              46.2132
          ],
          [
              -115.54007,
              46.21327
          ],
          [
              -115.5398,
              46.21332
          ],
          [
              -115.53968,
              46.21335
          ],
          [
              -115.53955,
              46.21337
          ],
          [
              -115.53942,
              46.2134
          ],
          [
              -115.53935,
              46.21341
          ],
          [
              -115.53931,
              46.21343
          ],
          [
              -115.53929,
              46.21343
          ],
          [
              -115.53923,
              46.21344
          ],
          [
              -115.5391,
              46.21348
          ],
          [
              -115.53905,
              46.21349
          ],
          [
              -115.53896,
              46.21352
          ],
          [
              -115.53884,
              46.21355
          ],
          [
              -115.53853,
              46.21368
          ],
          [
              -115.53778,
              46.21407
          ],
          [
              -115.53777,
              46.21408
          ],
          [
              -115.5376,
              46.21419
          ],
          [
              -115.53758,
              46.2142
          ],
          [
              -115.53736,
              46.21437
          ],
          [
              -115.53728,
              46.21442
          ],
          [
              -115.53724,
              46.21446
          ],
          [
              -115.53721,
              46.21448
          ],
          [
              -115.53717,
              46.21452
          ],
          [
              -115.53713,
              46.21457
          ],
          [
              -115.53709,
              46.21463
          ],
          [
              -115.53705,
              46.21468
          ],
          [
              -115.53703,
              46.21473
          ],
          [
              -115.53701,
              46.21476
          ],
          [
              -115.53699,
              46.2148
          ],
          [
              -115.53679,
              46.21552
          ],
          [
              -115.53675,
              46.21573
          ],
          [
              -115.53672,
              46.21582
          ],
          [
              -115.53671,
              46.21588
          ],
          [
              -115.53663,
              46.21611
          ],
          [
              -115.5366,
              46.21617
          ],
          [
              -115.53652,
              46.2164
          ],
          [
              -115.53648,
              46.21648
          ],
          [
              -115.53642,
              46.21662
          ],
          [
              -115.53637,
              46.21672
          ],
          [
              -115.53625,
              46.21689
          ],
          [
              -115.5362,
              46.21697
          ],
          [
              -115.53614,
              46.21704
          ],
          [
              -115.53607,
              46.21714
          ],
          [
              -115.53598,
              46.21725
          ],
          [
              -115.53571,
              46.21752
          ],
          [
              -115.53548,
              46.21771
          ],
          [
              -115.53536,
              46.2178
          ],
          [
              -115.53528,
              46.21784
          ],
          [
              -115.53522,
              46.21788
          ],
          [
              -115.53494,
              46.21804
          ],
          [
              -115.53463,
              46.2182
          ],
          [
              -115.53415,
              46.21839
          ],
          [
              -115.53395,
              46.21848
          ],
          [
              -115.5331,
              46.2188
          ],
          [
              -115.53133,
              46.21951
          ],
          [
              -115.53127,
              46.21954
          ],
          [
              -115.53111,
              46.2196
          ],
          [
              -115.53109,
              46.21961
          ],
          [
              -115.53089,
              46.21968
          ],
          [
              -115.53083,
              46.21969
          ],
          [
              -115.53076,
              46.21972
          ],
          [
              -115.53067,
              46.21974
          ],
          [
              -115.53054,
              46.21976
          ],
          [
              -115.53039,
              46.2198
          ],
          [
              -115.53013,
              46.21985
          ],
          [
              -115.5299,
              46.21988
          ],
          [
              -115.52988,
              46.21989
          ],
          [
              -115.52948,
              46.21993
          ],
          [
              -115.5294,
              46.21993
          ],
          [
              -115.52932,
              46.21994
          ],
          [
              -115.52902,
              46.21995
          ],
          [
              -115.52838,
              46.22
          ],
          [
              -115.5281,
              46.22004
          ],
          [
              -115.52808,
              46.22005
          ],
          [
              -115.52799,
              46.22006
          ],
          [
              -115.52766,
              46.22016
          ],
          [
              -115.52754,
              46.22021
          ],
          [
              -115.52723,
              46.22037
          ],
          [
              -115.52651,
              46.22082
          ],
          [
              -115.52646,
              46.22086
          ],
          [
              -115.52642,
              46.22088
          ],
          [
              -115.52636,
              46.22093
          ],
          [
              -115.52632,
              46.22097
          ],
          [
              -115.52568,
              46.22151
          ],
          [
              -115.52564,
              46.22155
          ],
          [
              -115.52536,
              46.22177
          ],
          [
              -115.52526,
              46.22186
          ],
          [
              -115.52509,
              46.22199
          ],
          [
              -115.52487,
              46.22218
          ],
          [
              -115.52469,
              46.22236
          ],
          [
              -115.52453,
              46.22254
          ],
          [
              -115.52442,
              46.22269
          ],
          [
              -115.52427,
              46.22294
          ],
          [
              -115.52413,
              46.22326
          ],
          [
              -115.52404,
              46.22357
          ],
          [
              -115.52402,
              46.22367
          ],
          [
              -115.52402,
              46.22398
          ],
          [
              -115.52404,
              46.22424
          ],
          [
              -115.5241,
              46.22465
          ],
          [
              -115.52411,
              46.22468
          ],
          [
              -115.52411,
              46.22472
          ],
          [
              -115.52412,
              46.22479
          ],
          [
              -115.52412,
              46.22506
          ],
          [
              -115.5241,
              46.22518
          ],
          [
              -115.52403,
              46.22546
          ],
          [
              -115.52398,
              46.22558
          ],
          [
              -115.52394,
              46.22566
          ],
          [
              -115.52393,
              46.22569
          ],
          [
              -115.52386,
              46.22584
          ],
          [
              -115.5238,
              46.22594
          ],
          [
              -115.52361,
              46.2262
          ],
          [
              -115.52326,
              46.22664
          ],
          [
              -115.52279,
              46.2272
          ],
          [
              -115.52272,
              46.22727
          ],
          [
              -115.52268,
              46.22732
          ],
          [
              -115.52262,
              46.22738
          ],
          [
              -115.52255,
              46.22744
          ],
          [
              -115.52247,
              46.22752
          ],
          [
              -115.52208,
              46.22785
          ],
          [
              -115.52193,
              46.22796
          ],
          [
              -115.52166,
              46.22814
          ],
          [
              -115.52165,
              46.22814
          ],
          [
              -115.52156,
              46.2282
          ],
          [
              -115.52146,
              46.22825
          ],
          [
              -115.52132,
              46.22833
          ],
          [
              -115.52122,
              46.22838
          ],
          [
              -115.52088,
              46.22853
          ],
          [
              -115.52077,
              46.22857
          ],
          [
              -115.52056,
              46.22866
          ],
          [
              -115.52032,
              46.22874
          ],
          [
              -115.52024,
              46.22876
          ],
          [
              -115.52016,
              46.22879
          ],
          [
              -115.52008,
              46.22881
          ],
          [
              -115.51999,
              46.22884
          ],
          [
              -115.5195,
              46.22896
          ],
          [
              -115.51832,
              46.22919
          ],
          [
              -115.51733,
              46.22936
          ],
          [
              -115.51692,
              46.22941
          ],
          [
              -115.51677,
              46.22942
          ],
          [
              -115.51661,
              46.22944
          ],
          [
              -115.51625,
              46.22946
          ],
          [
              -115.51429,
              46.22963
          ],
          [
              -115.51379,
              46.22966
          ],
          [
              -115.51364,
              46.22968
          ],
          [
              -115.51354,
              46.22968
          ],
          [
              -115.5131,
              46.22971
          ],
          [
              -115.51256,
              46.22971
          ],
          [
              -115.51223,
              46.22968
          ],
          [
              -115.51195,
              46.22964
          ],
          [
              -115.51194,
              46.22964
          ],
          [
              -115.51149,
              46.22956
          ],
          [
              -115.5109,
              46.22938
          ],
          [
              -115.5107,
              46.22931
          ],
          [
              -115.51034,
              46.22916
          ],
          [
              -115.50991,
              46.22894
          ],
          [
              -115.50987,
              46.22893
          ],
          [
              -115.50902,
              46.22852
          ],
          [
              -115.50875,
              46.22842
          ],
          [
              -115.50865,
              46.22839
          ],
          [
              -115.50832,
              46.22827
          ],
          [
              -115.50827,
              46.22826
          ],
          [
              -115.50778,
              46.22811
          ],
          [
              -115.50703,
              46.22792
          ],
          [
              -115.50667,
              46.22784
          ],
          [
              -115.50612,
              46.22774
          ],
          [
              -115.50606,
              46.22772
          ],
          [
              -115.50414,
              46.22733
          ],
          [
              -115.50401,
              46.22731
          ],
          [
              -115.50245,
              46.227
          ],
          [
              -115.50236,
              46.22699
          ],
          [
              -115.50223,
              46.22695
          ],
          [
              -115.50215,
              46.22694
          ],
          [
              -115.50153,
              46.22676
          ],
          [
              -115.50106,
              46.2266
          ],
          [
              -115.50087,
              46.22652
          ],
          [
              -115.50081,
              46.2265
          ],
          [
              -115.5008,
              46.22649
          ],
          [
              -115.50071,
              46.22646
          ],
          [
              -115.50066,
              46.22643
          ],
          [
              -115.50038,
              46.2263
          ],
          [
              -115.50037,
              46.22629
          ],
          [
              -115.49987,
              46.22604
          ],
          [
              -115.49945,
              46.22586
          ],
          [
              -115.49919,
              46.22577
          ],
          [
              -115.49912,
              46.22574
          ],
          [
              -115.49889,
              46.22568
          ],
          [
              -115.4987,
              46.22564
          ],
          [
              -115.49863,
              46.22563
          ],
          [
              -115.49854,
              46.22561
          ],
          [
              -115.49847,
              46.22561
          ],
          [
              -115.49845,
              46.2256
          ],
          [
              -115.49841,
              46.2256
          ],
          [
              -115.49836,
              46.22559
          ],
          [
              -115.49825,
              46.22559
          ],
          [
              -115.4982,
              46.22558
          ],
          [
              -115.49776,
              46.22558
          ],
          [
              -115.4977,
              46.22559
          ],
          [
              -115.49758,
              46.22559
          ],
          [
              -115.49755,
              46.2256
          ],
          [
              -115.49751,
              46.2256
          ],
          [
              -115.49747,
              46.22561
          ],
          [
              -115.49732,
              46.22563
          ],
          [
              -115.49716,
              46.22567
          ],
          [
              -115.49712,
              46.22567
          ],
          [
              -115.49694,
              46.22572
          ],
          [
              -115.49682,
              46.22576
          ],
          [
              -115.49673,
              46.22578
          ],
          [
              -115.49668,
              46.2258
          ],
          [
              -115.49664,
              46.22581
          ],
          [
              -115.4966,
              46.22583
          ],
          [
              -115.49656,
              46.22584
          ],
          [
              -115.49653,
              46.22586
          ],
          [
              -115.4965,
              46.22587
          ],
          [
              -115.49647,
              46.22589
          ],
          [
              -115.49644,
              46.2259
          ],
          [
              -115.4964,
              46.22592
          ],
          [
              -115.49637,
              46.22594
          ],
          [
              -115.49633,
              46.22596
          ],
          [
              -115.4963,
              46.22597
          ],
          [
              -115.49626,
              46.226
          ],
          [
              -115.49622,
              46.22602
          ],
          [
              -115.4962,
              46.22604
          ],
          [
              -115.49588,
              46.22625
          ],
          [
              -115.49583,
              46.22629
          ],
          [
              -115.49578,
              46.22632
          ],
          [
              -115.49578,
              46.22633
          ],
          [
              -115.49573,
              46.22636
          ],
          [
              -115.49565,
              46.22642
          ],
          [
              -115.4956,
              46.22647
          ],
          [
              -115.4955,
              46.22655
          ],
          [
              -115.49533,
              46.22672
          ],
          [
              -115.49527,
              46.22679
          ],
          [
              -115.49521,
              46.22685
          ],
          [
              -115.49498,
              46.22715
          ],
          [
              -115.49487,
              46.22734
          ],
          [
              -115.49482,
              46.22741
          ],
          [
              -115.49476,
              46.22751
          ],
          [
              -115.49472,
              46.22759
          ],
          [
              -115.49455,
              46.22786
          ],
          [
              -115.4943,
              46.22821
          ],
          [
              -115.49396,
              46.22859
          ],
          [
              -115.49332,
              46.22924
          ],
          [
              -115.49279,
              46.22974
          ],
          [
              -115.49264,
              46.2299
          ],
          [
              -115.49194,
              46.23057
          ],
          [
              -115.49171,
              46.23081
          ],
          [
              -115.49141,
              46.23107
          ],
          [
              -115.49134,
              46.23114
          ],
          [
              -115.49126,
              46.23121
          ],
          [
              -115.49071,
              46.23162
          ],
          [
              -115.49046,
              46.23178
          ],
          [
              -115.49032,
              46.23188
          ],
          [
              -115.49024,
              46.23193
          ],
          [
              -115.49016,
              46.23197
          ],
          [
              -115.49004,
              46.23204
          ],
          [
              -115.49001,
              46.23205
          ],
          [
              -115.48985,
              46.23213
          ],
          [
              -115.48981,
              46.23214
          ],
          [
              -115.48977,
              46.23216
          ],
          [
              -115.48974,
              46.23217
          ],
          [
              -115.48964,
              46.23219
          ],
          [
              -115.48958,
              46.23221
          ],
          [
              -115.48947,
              46.23223
          ],
          [
              -115.4892,
              46.23226
          ],
          [
              -115.4891,
              46.23226
          ],
          [
              -115.48901,
              46.23227
          ],
          [
              -115.48864,
              46.23227
          ],
          [
              -115.48851,
              46.23226
          ],
          [
              -115.4884,
              46.23226
          ],
          [
              -115.48829,
              46.23225
          ],
          [
              -115.48816,
              46.23225
          ],
          [
              -115.488,
              46.23224
          ],
          [
              -115.48783,
              46.23222
          ],
          [
              -115.48773,
              46.23222
          ],
          [
              -115.48762,
              46.23221
          ],
          [
              -115.48752,
              46.23219
          ],
          [
              -115.48737,
              46.23217
          ],
          [
              -115.48731,
              46.23217
          ],
          [
              -115.48716,
              46.23214
          ],
          [
              -115.4871,
              46.23212
          ],
          [
              -115.48703,
              46.23211
          ],
          [
              -115.48673,
              46.23203
          ],
          [
              -115.48664,
              46.232
          ],
          [
              -115.48662,
              46.232
          ],
          [
              -115.48658,
              46.23198
          ],
          [
              -115.48648,
              46.23195
          ],
          [
              -115.48639,
              46.23191
          ],
          [
              -115.4863,
              46.23188
          ],
          [
              -115.4862,
              46.23183
          ],
          [
              -115.48619,
              46.23183
          ],
          [
              -115.48579,
              46.23163
          ],
          [
              -115.48574,
              46.23161
          ],
          [
              -115.48568,
              46.23158
          ],
          [
              -115.48557,
              46.23151
          ],
          [
              -115.48535,
              46.23139
          ],
          [
              -115.48506,
              46.23118
          ],
          [
              -115.48487,
              46.23102
          ],
          [
              -115.48482,
              46.23097
          ],
          [
              -115.48475,
              46.23091
          ],
          [
              -115.48454,
              46.2307
          ],
          [
              -115.48452,
              46.23069
          ],
          [
              -115.4844,
              46.23055
          ],
          [
              -115.48435,
              46.2305
          ],
          [
              -115.48427,
              46.2304
          ],
          [
              -115.48425,
              46.23038
          ],
          [
              -115.48414,
              46.23024
          ],
          [
              -115.48412,
              46.23022
          ],
          [
              -115.48381,
              46.22977
          ],
          [
              -115.4838,
              46.22975
          ],
          [
              -115.4834,
              46.22918
          ],
          [
              -115.48334,
              46.22911
          ],
          [
              -115.48333,
              46.22911
          ],
          [
              -115.48322,
              46.22897
          ],
          [
              -115.4832,
              46.22895
          ],
          [
              -115.48317,
              46.22891
          ],
          [
              -115.48296,
              46.2287
          ],
          [
              -115.48291,
              46.22866
          ],
          [
              -115.48266,
              46.2285
          ],
          [
              -115.48232,
              46.22834
          ],
          [
              -115.48229,
              46.22833
          ],
          [
              -115.48226,
              46.22831
          ],
          [
              -115.48219,
              46.22829
          ],
          [
              -115.4821,
              46.22825
          ],
          [
              -115.48202,
              46.22823
          ],
          [
              -115.48169,
              46.22812
          ],
          [
              -115.48151,
              46.22807
          ],
          [
              -115.48041,
              46.22772
          ],
          [
              -115.48033,
              46.22769
          ],
          [
              -115.48018,
              46.22765
          ],
          [
              -115.4801,
              46.22762
          ],
          [
              -115.47993,
              46.22758
          ],
          [
              -115.47987,
              46.22756
          ],
          [
              -115.4798,
              46.22755
          ],
          [
              -115.47974,
              46.22753
          ],
          [
              -115.47943,
              46.22749
          ],
          [
              -115.47933,
              46.22747
          ],
          [
              -115.47926,
              46.22747
          ],
          [
              -115.4792,
              46.22746
          ],
          [
              -115.47901,
              46.22746
          ],
          [
              -115.47893,
              46.22747
          ],
          [
              -115.47886,
              46.22747
          ],
          [
              -115.4784,
              46.22751
          ],
          [
              -115.47831,
              46.22753
          ],
          [
              -115.47798,
              46.22758
          ],
          [
              -115.47786,
              46.22761
          ],
          [
              -115.47775,
              46.22762
          ],
          [
              -115.47768,
              46.22764
          ],
          [
              -115.47764,
              46.22764
          ],
          [
              -115.4776,
              46.22765
          ],
          [
              -115.47755,
              46.22765
          ],
          [
              -115.47751,
              46.22766
          ],
          [
              -115.47729,
              46.22766
          ],
          [
              -115.47718,
              46.22767
          ],
          [
              -115.47686,
              46.22767
          ],
          [
              -115.47679,
              46.22766
          ],
          [
              -115.47659,
              46.22766
          ],
          [
              -115.47647,
              46.22765
          ],
          [
              -115.47441,
              46.22759
          ],
          [
              -115.47426,
              46.22758
          ],
          [
              -115.47361,
              46.22758
          ],
          [
              -115.47339,
              46.22761
          ],
          [
              -115.47331,
              46.22761
          ],
          [
              -115.47286,
              46.22768
          ],
          [
              -115.47208,
              46.22785
          ],
          [
              -115.47203,
              46.22785
          ],
          [
              -115.47187,
              46.22787
          ],
          [
              -115.47176,
              46.22789
          ],
          [
              -115.47169,
              46.22789
          ],
          [
              -115.47163,
              46.2279
          ],
          [
              -115.47142,
              46.2279
          ],
          [
              -115.47134,
              46.22791
          ],
          [
              -115.47118,
              46.22791
          ],
          [
              -115.47109,
              46.2279
          ],
          [
              -115.47101,
              46.2279
          ],
          [
              -115.47074,
              46.22787
          ],
          [
              -115.47049,
              46.22783
          ],
          [
              -115.47031,
              46.22779
          ],
          [
              -115.46986,
              46.22771
          ],
          [
              -115.46895,
              46.22752
          ],
          [
              -115.46858,
              46.22743
          ],
          [
              -115.46811,
              46.2273
          ],
          [
              -115.46799,
              46.22726
          ],
          [
              -115.46787,
              46.22723
          ],
          [
              -115.4677,
              46.22717
          ],
          [
              -115.46756,
              46.22713
          ],
          [
              -115.46741,
              46.22707
          ],
          [
              -115.46686,
              46.22689
          ],
          [
              -115.46663,
              46.2268
          ],
          [
              -115.46507,
              46.22626
          ],
          [
              -115.46489,
              46.22619
          ],
          [
              -115.46482,
              46.22617
          ],
          [
              -115.46451,
              46.22606
          ],
          [
              -115.46443,
              46.22604
          ],
          [
              -115.46435,
              46.22601
          ],
          [
              -115.46428,
              46.22599
          ],
          [
              -115.46423,
              46.22597
          ],
          [
              -115.46401,
              46.22592
          ],
          [
              -115.46396,
              46.2259
          ],
          [
              -115.46387,
              46.22588
          ],
          [
              -115.46386,
              46.22588
          ],
          [
              -115.46382,
              46.22587
          ],
          [
              -115.46373,
              46.22586
          ],
          [
              -115.46362,
              46.22583
          ],
          [
              -115.46358,
              46.22583
          ],
          [
              -115.46347,
              46.22581
          ],
          [
              -115.46342,
              46.22581
          ],
          [
              -115.46337,
              46.2258
          ],
          [
              -115.46326,
              46.2258
          ],
          [
              -115.46318,
              46.22579
          ],
          [
              -115.46242,
              46.22575
          ],
          [
              -115.46222,
              46.22575
          ],
          [
              -115.46207,
              46.22574
          ],
          [
              -115.46191,
              46.22574
          ],
          [
              -115.46187,
              46.22575
          ],
          [
              -115.46175,
              46.22575
          ],
          [
              -115.4615,
              46.22579
          ],
          [
              -115.46127,
              46.22585
          ],
          [
              -115.46119,
              46.22588
          ],
          [
              -115.46114,
              46.22589
          ],
          [
              -115.46095,
              46.22597
          ],
          [
              -115.46052,
              46.22619
          ],
          [
              -115.46012,
              46.22645
          ],
          [
              -115.45986,
              46.22665
          ],
          [
              -115.4598,
              46.22669
          ],
          [
              -115.45973,
              46.22675
          ],
          [
              -115.45967,
              46.22681
          ],
          [
              -115.45958,
              46.22688
          ],
          [
              -115.4595,
              46.22696
          ],
          [
              -115.45944,
              46.22701
          ],
          [
              -115.45933,
              46.22713
          ],
          [
              -115.45928,
              46.2272
          ],
          [
              -115.45922,
              46.22726
          ],
          [
              -115.45919,
              46.22731
          ],
          [
              -115.45915,
              46.22736
          ],
          [
              -115.45906,
              46.22752
          ],
          [
              -115.45882,
              46.2279
          ],
          [
              -115.45873,
              46.22803
          ],
          [
              -115.4586,
              46.22825
          ],
          [
              -115.45844,
              46.22849
          ],
          [
              -115.45827,
              46.22869
          ],
          [
              -115.45823,
              46.22872
          ],
          [
              -115.4582,
              46.22876
          ],
          [
              -115.45816,
              46.2288
          ],
          [
              -115.45796,
              46.22894
          ],
          [
              -115.45795,
              46.22895
          ],
          [
              -115.4579,
              46.22897
          ],
          [
              -115.45787,
              46.22899
          ],
          [
              -115.45776,
              46.22905
          ],
          [
              -115.45772,
              46.22908
          ],
          [
              -115.45755,
              46.22916
          ],
          [
              -115.4575,
              46.22919
          ],
          [
              -115.45737,
              46.22924
          ],
          [
              -115.45731,
              46.22927
          ],
          [
              -115.4569,
              46.22942
          ],
          [
              -115.45683,
              46.22944
          ],
          [
              -115.45678,
              46.22946
          ],
          [
              -115.45674,
              46.22947
          ],
          [
              -115.45657,
              46.22953
          ],
          [
              -115.45632,
              46.2296
          ],
          [
              -115.45599,
              46.22971
          ],
          [
              -115.45583,
              46.22975
          ],
          [
              -115.45543,
              46.22982
          ],
          [
              -115.45537,
              46.22982
          ],
          [
              -115.45531,
              46.22983
          ],
          [
              -115.45526,
              46.22983
          ],
          [
              -115.45521,
              46.22984
          ],
          [
              -115.45507,
              46.22984
          ],
          [
              -115.45501,
              46.22985
          ],
          [
              -115.45447,
              46.22985
          ],
          [
              -115.45441,
              46.22984
          ],
          [
              -115.45432,
              46.22984
          ],
          [
              -115.45418,
              46.22982
          ],
          [
              -115.45413,
              46.22982
          ],
          [
              -115.45411,
              46.22981
          ],
          [
              -115.45406,
              46.22981
          ],
          [
              -115.45386,
              46.22978
          ],
          [
              -115.45377,
              46.22976
          ],
          [
              -115.45356,
              46.22973
          ],
          [
              -115.45343,
              46.2297
          ],
          [
              -115.45267,
              46.22956
          ],
          [
              -115.45237,
              46.22949
          ],
          [
              -115.45236,
              46.22949
          ],
          [
              -115.45215,
              46.22945
          ],
          [
              -115.45212,
              46.22944
          ],
          [
              -115.45188,
              46.22939
          ],
          [
              -115.45175,
              46.22937
          ],
          [
              -115.45127,
              46.22926
          ],
          [
              -115.45121,
              46.22924
          ],
          [
              -115.45113,
              46.22922
          ],
          [
              -115.45083,
              46.2291
          ],
          [
              -115.4507,
              46.22904
          ],
          [
              -115.45064,
              46.229
          ],
          [
              -115.45053,
              46.22894
          ],
          [
              -115.45049,
              46.22891
          ],
          [
              -115.45044,
              46.22888
          ],
          [
              -115.45032,
              46.22878
          ],
          [
              -115.45027,
              46.22875
          ],
          [
              -115.45022,
              46.2287
          ],
          [
              -115.45011,
              46.22861
          ],
          [
              -115.45005,
              46.22854
          ],
          [
              -115.44998,
              46.22848
          ],
          [
              -115.44986,
              46.22836
          ],
          [
              -115.44962,
              46.22817
          ],
          [
              -115.44957,
              46.22814
          ],
          [
              -115.44952,
              46.2281
          ],
          [
              -115.44941,
              46.22803
          ],
          [
              -115.44931,
              46.22798
          ],
          [
              -115.4493,
              46.22797
          ],
          [
              -115.44927,
              46.22796
          ],
          [
              -115.4491,
              46.22788
          ],
          [
              -115.44905,
              46.22787
          ],
          [
              -115.44882,
              46.22779
          ],
          [
              -115.44876,
              46.22778
          ],
          [
              -115.44869,
              46.22776
          ],
          [
              -115.44865,
              46.22776
          ],
          [
              -115.44853,
              46.22773
          ],
          [
              -115.44842,
              46.22771
          ],
          [
              -115.44835,
              46.22771
          ],
          [
              -115.44822,
              46.22769
          ],
          [
              -115.44772,
              46.22769
          ],
          [
              -115.44767,
              46.2277
          ],
          [
              -115.44758,
              46.2277
          ],
          [
              -115.44755,
              46.22771
          ],
          [
              -115.44749,
              46.22771
          ],
          [
              -115.44742,
              46.22773
          ],
          [
              -115.44721,
              46.22777
          ],
          [
              -115.44715,
              46.22779
          ],
          [
              -115.44703,
              46.22782
          ],
          [
              -115.44695,
              46.22785
          ],
          [
              -115.4469,
              46.22786
          ],
          [
              -115.44684,
              46.22788
          ],
          [
              -115.44683,
              46.22789
          ],
          [
              -115.44611,
              46.22814
          ],
          [
              -115.44591,
              46.22822
          ],
          [
              -115.44568,
              46.2283
          ],
          [
              -115.44564,
              46.22831
          ],
          [
              -115.445,
              46.22853
          ],
          [
              -115.4448,
              46.22861
          ],
          [
              -115.44383,
              46.22894
          ],
          [
              -115.44378,
              46.22895
          ],
          [
              -115.44372,
              46.22897
          ],
          [
              -115.44361,
              46.22899
          ],
          [
              -115.44353,
              46.22901
          ],
          [
              -115.44342,
              46.22903
          ],
          [
              -115.44337,
              46.22903
          ],
          [
              -115.44332,
              46.22904
          ],
          [
              -115.44318,
              46.22904
          ],
          [
              -115.44314,
              46.22905
          ],
          [
              -115.44304,
              46.22905
          ],
          [
              -115.443,
              46.22904
          ],
          [
              -115.44285,
              46.22904
          ],
          [
              -115.4428,
              46.22903
          ],
          [
              -115.44279,
              46.22903
          ],
          [
              -115.44273,
              46.22902
          ],
          [
              -115.44266,
              46.22902
          ],
          [
              -115.44244,
              46.22898
          ],
          [
              -115.44151,
              46.22877
          ],
          [
              -115.44079,
              46.22858
          ],
          [
              -115.4401,
              46.22837
          ],
          [
              -115.4399,
              46.22829
          ],
          [
              -115.43981,
              46.22826
          ],
          [
              -115.43966,
              46.22819
          ],
          [
              -115.4396,
              46.22817
          ],
          [
              -115.43947,
              46.22811
          ],
          [
              -115.43944,
              46.2281
          ],
          [
              -115.43941,
              46.22808
          ],
          [
              -115.43904,
              46.22791
          ],
          [
              -115.43887,
              46.22782
          ],
          [
              -115.43864,
              46.22772
          ],
          [
              -115.43852,
              46.22766
          ],
          [
              -115.43838,
              46.2276
          ],
          [
              -115.43826,
              46.22756
          ],
          [
              -115.4382,
              46.22753
          ],
          [
              -115.43812,
              46.2275
          ],
          [
              -115.43804,
              46.22748
          ],
          [
              -115.43797,
              46.22745
          ],
          [
              -115.43778,
              46.22739
          ],
          [
              -115.43766,
              46.22736
          ],
          [
              -115.43764,
              46.22736
          ],
          [
              -115.43659,
              46.22712
          ],
          [
              -115.43646,
              46.2271
          ],
          [
              -115.43601,
              46.22701
          ],
          [
              -115.43566,
              46.22697
          ],
          [
              -115.43561,
              46.22696
          ],
          [
              -115.43554,
              46.22696
          ],
          [
              -115.43547,
              46.22695
          ],
          [
              -115.4353,
              46.22695
          ],
          [
              -115.4352,
              46.22694
          ],
          [
              -115.43462,
              46.22694
          ],
          [
              -115.43443,
              46.22695
          ],
          [
              -115.4343,
              46.22695
          ],
          [
              -115.43419,
              46.22696
          ],
          [
              -115.43403,
              46.22696
          ],
          [
              -115.43384,
              46.22697
          ],
          [
              -115.43345,
              46.22697
          ],
          [
              -115.43318,
              46.22695
          ],
          [
              -115.43302,
              46.22695
          ],
          [
              -115.43285,
              46.22693
          ],
          [
              -115.43248,
              46.22691
          ],
          [
              -115.43217,
              46.22688
          ],
          [
              -115.43199,
              46.22688
          ],
          [
              -115.4319,
              46.22687
          ],
          [
              -115.43142,
              46.22687
          ],
          [
              -115.43094,
              46.22689
          ],
          [
              -115.43006,
              46.22689
          ],
          [
              -115.42995,
              46.22688
          ],
          [
              -115.42976,
              46.22688
          ],
          [
              -115.42931,
              46.22685
          ],
          [
              -115.4292,
              46.22685
          ],
          [
              -115.42903,
              46.22684
          ],
          [
              -115.42884,
              46.22684
          ],
          [
              -115.42853,
              46.22682
          ],
          [
              -115.42844,
              46.22682
          ],
          [
              -115.42836,
              46.22683
          ],
          [
              -115.42826,
              46.22683
          ],
          [
              -115.42813,
              46.22685
          ],
          [
              -115.4279,
              46.22687
          ],
          [
              -115.42778,
              46.22689
          ],
          [
              -115.42759,
              46.22691
          ],
          [
              -115.42751,
              46.22693
          ],
          [
              -115.42744,
              46.22694
          ],
          [
              -115.42678,
              46.2271
          ],
          [
              -115.42639,
              46.22723
          ],
          [
              -115.42631,
              46.22727
          ],
          [
              -115.42592,
              46.22744
          ],
          [
              -115.42535,
              46.22774
          ],
          [
              -115.42505,
              46.22793
          ],
          [
              -115.42473,
              46.22815
          ],
          [
              -115.42439,
              46.22836
          ],
          [
              -115.42414,
              46.22848
          ],
          [
              -115.42408,
              46.22852
          ],
          [
              -115.42402,
              46.22855
          ],
          [
              -115.42393,
              46.22858
          ],
          [
              -115.42385,
              46.22862
          ],
          [
              -115.42345,
              46.22876
          ],
          [
              -115.4233,
              46.22882
          ],
          [
              -115.42329,
              46.22882
          ],
          [
              -115.42259,
              46.22908
          ],
          [
              -115.42257,
              46.22909
          ],
          [
              -115.42213,
              46.22924
          ],
          [
              -115.42202,
              46.22927
          ],
          [
              -115.42191,
              46.22931
          ],
          [
              -115.42181,
              46.22933
          ],
          [
              -115.42174,
              46.22936
          ],
          [
              -115.42149,
              46.22941
          ],
          [
              -115.4213,
              46.22946
          ],
          [
              -115.42111,
              46.2295
          ],
          [
              -115.42069,
              46.22957
          ],
          [
              -115.4198,
              46.22975
          ],
          [
              -115.41964,
              46.22979
          ],
          [
              -115.41962,
              46.22979
          ],
          [
              -115.41925,
              46.22987
          ],
          [
              -115.41889,
              46.22997
          ],
          [
              -115.41879,
              46.23001
          ],
          [
              -115.41859,
              46.23007
          ],
          [
              -115.41849,
              46.23011
          ],
          [
              -115.41276,
              46.23202
          ],
          [
              -115.41274,
              46.23203
          ],
          [
              -115.41258,
              46.23209
          ],
          [
              -115.41205,
              46.23226
          ],
          [
              -115.41189,
              46.23232
          ],
          [
              -115.41181,
              46.23234
          ],
          [
              -115.41171,
              46.23238
          ],
          [
              -115.41157,
              46.23242
          ],
          [
              -115.41153,
              46.23244
          ],
          [
              -115.41082,
              46.23267
          ],
          [
              -115.41066,
              46.23273
          ],
          [
              -115.40933,
              46.23316
          ],
          [
              -115.40925,
              46.23318
          ],
          [
              -115.40914,
              46.23322
          ],
          [
              -115.40905,
              46.23324
          ],
          [
              -115.40896,
              46.23327
          ],
          [
              -115.40889,
              46.23328
          ],
          [
              -115.40883,
              46.2333
          ],
          [
              -115.40876,
              46.23331
          ],
          [
              -115.40874,
              46.23332
          ],
          [
              -115.40859,
              46.23335
          ],
          [
              -115.40839,
              46.2334
          ],
          [
              -115.40774,
              46.23353
          ],
          [
              -115.40767,
              46.23355
          ],
          [
              -115.40749,
              46.23358
          ],
          [
              -115.40724,
              46.23364
          ],
          [
              -115.40716,
              46.23365
          ],
          [
              -115.40702,
              46.23368
          ],
          [
              -115.407,
              46.23369
          ],
          [
              -115.40685,
              46.23372
          ],
          [
              -115.40675,
              46.23375
          ],
          [
              -115.40672,
              46.23375
          ],
          [
              -115.40651,
              46.23382
          ],
          [
              -115.40649,
              46.23382
          ],
          [
              -115.40631,
              46.23388
          ],
          [
              -115.40628,
              46.2339
          ],
          [
              -115.40612,
              46.23396
          ],
          [
              -115.40605,
              46.23398
          ],
          [
              -115.40597,
              46.23402
          ],
          [
              -115.40589,
              46.23405
          ],
          [
              -115.40584,
              46.23408
          ],
          [
              -115.40583,
              46.23408
          ],
          [
              -115.40562,
              46.23419
          ],
          [
              -115.40561,
              46.2342
          ],
          [
              -115.4055,
              46.23427
          ],
          [
              -115.40541,
              46.23432
          ],
          [
              -115.40537,
              46.23436
          ],
          [
              -115.40532,
              46.23439
          ],
          [
              -115.40521,
              46.23448
          ],
          [
              -115.40514,
              46.23455
          ],
          [
              -115.40506,
              46.23465
          ],
          [
              -115.40501,
              46.2347
          ],
          [
              -115.40497,
              46.23477
          ],
          [
              -115.40492,
              46.23483
          ],
          [
              -115.40488,
              46.2349
          ],
          [
              -115.40487,
              46.23491
          ],
          [
              -115.40483,
              46.23497
          ],
          [
              -115.4048,
              46.23504
          ],
          [
              -115.40477,
              46.23508
          ],
          [
              -115.40476,
              46.2351
          ],
          [
              -115.40475,
              46.23514
          ],
          [
              -115.40473,
              46.23517
          ],
          [
              -115.40471,
              46.23523
          ],
          [
              -115.4047,
              46.23525
          ],
          [
              -115.40465,
              46.23542
          ],
          [
              -115.40465,
              46.23543
          ],
          [
              -115.40458,
              46.23568
          ],
          [
              -115.40454,
              46.23588
          ],
          [
              -115.40451,
              46.23596
          ],
          [
              -115.40449,
              46.23608
          ],
          [
              -115.40442,
              46.2363
          ],
          [
              -115.40438,
              46.23649
          ],
          [
              -115.40428,
              46.23684
          ],
          [
              -115.4042,
              46.2372
          ],
          [
              -115.40415,
              46.23736
          ],
          [
              -115.40415,
              46.23737
          ],
          [
              -115.40412,
              46.23744
          ],
          [
              -115.40405,
              46.23777
          ],
          [
              -115.40403,
              46.2379
          ],
          [
              -115.40402,
              46.23803
          ],
          [
              -115.404,
              46.23818
          ],
          [
              -115.40399,
              46.23844
          ],
          [
              -115.40395,
              46.23893
          ],
          [
              -115.40392,
              46.23916
          ],
          [
              -115.4039,
              46.23952
          ],
          [
              -115.40388,
              46.2397
          ],
          [
              -115.40388,
              46.23975
          ],
          [
              -115.40385,
              46.24016
          ],
          [
              -115.40385,
              46.24024
          ],
          [
              -115.40384,
              46.24029
          ],
          [
              -115.40384,
              46.24038
          ],
          [
              -115.40383,
              46.24042
          ],
          [
              -115.40383,
              46.24048
          ],
          [
              -115.40382,
              46.24057
          ],
          [
              -115.40375,
              46.24094
          ],
          [
              -115.4037,
              46.24112
          ],
          [
              -115.40356,
              46.24151
          ],
          [
              -115.40343,
              46.24179
          ],
          [
              -115.40343,
              46.2418
          ],
          [
              -115.4033,
              46.24205
          ],
          [
              -115.40322,
              46.24218
          ],
          [
              -115.40307,
              46.2424
          ],
          [
              -115.40306,
              46.24242
          ],
          [
              -115.40303,
              46.24245
          ],
          [
              -115.40298,
              46.24252
          ],
          [
              -115.40296,
              46.24254
          ],
          [
              -115.40292,
              46.24259
          ],
          [
              -115.40291,
              46.24261
          ],
          [
              -115.40289,
              46.24263
          ],
          [
              -115.40276,
              46.24279
          ],
          [
              -115.40272,
              46.24283
          ],
          [
              -115.40269,
              46.24287
          ],
          [
              -115.40238,
              46.24318
          ],
          [
              -115.40234,
              46.24321
          ],
          [
              -115.40225,
              46.2433
          ],
          [
              -115.40203,
              46.24349
          ],
          [
              -115.40185,
              46.24363
          ],
          [
              -115.40176,
              46.24369
          ],
          [
              -115.40168,
              46.24376
          ],
          [
              -115.40143,
              46.24395
          ],
          [
              -115.40125,
              46.2441
          ],
          [
              -115.4012,
              46.24416
          ],
          [
              -115.40108,
              46.24428
          ],
          [
              -115.40104,
              46.24434
          ],
          [
              -115.401,
              46.24439
          ],
          [
              -115.40096,
              46.24443
          ],
          [
              -115.4009,
              46.24452
          ],
          [
              -115.40085,
              46.24458
          ],
          [
              -115.40081,
              46.24465
          ],
          [
              -115.4008,
              46.24465
          ],
          [
              -115.40077,
              46.24471
          ],
          [
              -115.40073,
              46.24477
          ],
          [
              -115.40064,
              46.24493
          ],
          [
              -115.40056,
              46.24516
          ],
          [
              -115.40052,
              46.24531
          ],
          [
              -115.40051,
              46.24537
          ],
          [
              -115.40051,
              46.2454
          ],
          [
              -115.4005,
              46.24543
          ],
          [
              -115.4005,
              46.24546
          ],
          [
              -115.40049,
              46.2455
          ],
          [
              -115.40049,
              46.2457
          ],
          [
              -115.4005,
              46.24574
          ],
          [
              -115.4005,
              46.24579
          ],
          [
              -115.40051,
              46.24585
          ],
          [
              -115.40056,
              46.24605
          ],
          [
              -115.40057,
              46.24607
          ],
          [
              -115.40061,
              46.24619
          ],
          [
              -115.40068,
              46.24635
          ],
          [
              -115.40078,
              46.24651
          ],
          [
              -115.40105,
              46.24686
          ],
          [
              -115.40121,
              46.24702
          ],
          [
              -115.40121,
              46.24703
          ],
          [
              -115.40128,
              46.24709
          ],
          [
              -115.40144,
              46.24725
          ],
          [
              -115.4015,
              46.2473
          ],
          [
              -115.40151,
              46.24732
          ],
          [
              -115.40154,
              46.24734
          ],
          [
              -115.4016,
              46.24742
          ],
          [
              -115.40167,
              46.24749
          ],
          [
              -115.40183,
              46.24771
          ],
          [
              -115.40187,
              46.24779
          ],
          [
              -115.40187,
              46.2478
          ],
          [
              -115.40189,
              46.24784
          ],
          [
              -115.40191,
              46.24787
          ],
          [
              -115.40195,
              46.24798
          ],
          [
              -115.40196,
              46.24802
          ],
          [
              -115.40198,
              46.24805
          ],
          [
              -115.402,
              46.24813
          ],
          [
              -115.402,
              46.24815
          ],
          [
              -115.40203,
              46.2483
          ],
          [
              -115.40203,
              46.24855
          ],
          [
              -115.40202,
              46.24859
          ],
          [
              -115.40202,
              46.24867
          ],
          [
              -115.402,
              46.24876
          ],
          [
              -115.402,
              46.24879
          ],
          [
              -115.40198,
              46.24883
          ],
          [
              -115.40197,
              46.24888
          ],
          [
              -115.40194,
              46.24894
          ],
          [
              -115.40189,
              46.24908
          ],
          [
              -115.40186,
              46.24914
          ],
          [
              -115.40181,
              46.24921
          ],
          [
              -115.40177,
              46.24929
          ],
          [
              -115.40176,
              46.2493
          ],
          [
              -115.40157,
              46.2496
          ],
          [
              -115.40152,
              46.2497
          ],
          [
              -115.40108,
              46.25045
          ],
          [
              -115.40104,
              46.25051
          ],
          [
              -115.401,
              46.25059
          ],
          [
              -115.40099,
              46.2506
          ],
          [
              -115.40097,
              46.25063
          ],
          [
              -115.40093,
              46.25072
          ],
          [
              -115.40091,
              46.25075
          ],
          [
              -115.40089,
              46.25079
          ],
          [
              -115.40083,
              46.25095
          ],
          [
              -115.40082,
              46.25096
          ],
          [
              -115.4008,
              46.25104
          ],
          [
              -115.40077,
              46.25112
          ],
          [
              -115.40068,
              46.25149
          ],
          [
              -115.40067,
              46.25156
          ],
          [
              -115.40061,
              46.25183
          ],
          [
              -115.40061,
              46.25185
          ],
          [
              -115.40058,
              46.25201
          ],
          [
              -115.40057,
              46.25203
          ],
          [
              -115.40054,
              46.25219
          ],
          [
              -115.40054,
              46.25221
          ],
          [
              -115.4005,
              46.25237
          ],
          [
              -115.4005,
              46.25238
          ],
          [
              -115.40044,
              46.25269
          ],
          [
              -115.40043,
              46.25272
          ],
          [
              -115.40043,
              46.25278
          ],
          [
              -115.40042,
              46.25283
          ],
          [
              -115.40042,
              46.25313
          ],
          [
              -115.40044,
              46.25324
          ],
          [
              -115.40044,
              46.25326
          ],
          [
              -115.40045,
              46.25331
          ],
          [
              -115.40045,
              46.25334
          ],
          [
              -115.40046,
              46.25339
          ],
          [
              -115.40048,
              46.25343
          ],
          [
              -115.40048,
              46.25346
          ],
          [
              -115.40049,
              46.25348
          ],
          [
              -115.40053,
              46.2536
          ],
          [
              -115.40055,
              46.25371
          ],
          [
              -115.40056,
              46.25374
          ],
          [
              -115.40056,
              46.25377
          ],
          [
              -115.40057,
              46.25381
          ],
          [
              -115.40057,
              46.25388
          ],
          [
              -115.40056,
              46.25397
          ],
          [
              -115.40056,
              46.25403
          ],
          [
              -115.40055,
              46.2541
          ],
          [
              -115.40053,
              46.25419
          ],
          [
              -115.40048,
              46.25435
          ],
          [
              -115.40036,
              46.25458
          ],
          [
              -115.40009,
              46.255
          ],
          [
              -115.40008,
              46.25501
          ],
          [
              -115.39995,
              46.25524
          ],
          [
              -115.39993,
              46.25529
          ],
          [
              -115.3999,
              46.25535
          ],
          [
              -115.39988,
              46.25541
          ],
          [
              -115.39986,
              46.25545
          ],
          [
              -115.39985,
              46.25549
          ],
          [
              -115.39984,
              46.2555
          ],
          [
              -115.39984,
              46.25552
          ],
          [
              -115.39981,
              46.25566
          ],
          [
              -115.39981,
              46.25569
          ],
          [
              -115.3998,
              46.25573
          ],
          [
              -115.3998,
              46.25577
          ],
          [
              -115.39979,
              46.25582
          ],
          [
              -115.39979,
              46.256
          ],
          [
              -115.39983,
              46.25632
          ],
          [
              -115.39987,
              46.25656
          ],
          [
              -115.39987,
              46.25666
          ],
          [
              -115.39988,
              46.2567
          ],
          [
              -115.39988,
              46.25679
          ],
          [
              -115.39987,
              46.25682
          ],
          [
              -115.39987,
              46.25691
          ],
          [
              -115.39986,
              46.25694
          ],
          [
              -115.39986,
              46.25696
          ],
          [
              -115.39982,
              46.25712
          ],
          [
              -115.39982,
              46.25713
          ],
          [
              -115.39976,
              46.25729
          ],
          [
              -115.39973,
              46.25735
          ],
          [
              -115.39972,
              46.25738
          ],
          [
              -115.3997,
              46.25741
          ],
          [
              -115.39967,
              46.25747
          ],
          [
              -115.39955,
              46.25764
          ],
          [
              -115.39954,
              46.25764
          ],
          [
              -115.3995,
              46.25768
          ],
          [
              -115.39945,
              46.25774
          ],
          [
              -115.39941,
              46.25777
          ],
          [
              -115.3994,
              46.25779
          ],
          [
              -115.39935,
              46.25783
          ],
          [
              -115.39925,
              46.25789
          ],
          [
              -115.3992,
              46.25794
          ],
          [
              -115.39906,
              46.25802
          ],
          [
              -115.39905,
              46.25803
          ],
          [
              -115.39902,
              46.25805
          ],
          [
              -115.39878,
              46.25818
          ],
          [
              -115.39865,
              46.25824
          ],
          [
              -115.39864,
              46.25824
          ],
          [
              -115.39859,
              46.25827
          ],
          [
              -115.39853,
              46.25829
          ],
          [
              -115.3984,
              46.25835
          ],
          [
              -115.39839,
              46.25836
          ],
          [
              -115.39823,
              46.25842
          ],
          [
              -115.3982,
              46.25844
          ],
          [
              -115.39817,
              46.25845
          ],
          [
              -115.39797,
              46.25854
          ],
          [
              -115.39796,
              46.25854
          ],
          [
              -115.3976,
              46.25871
          ],
          [
              -115.39755,
              46.25874
          ],
          [
              -115.39754,
              46.25874
          ],
          [
              -115.39735,
              46.25884
          ],
          [
              -115.39734,
              46.25885
          ],
          [
              -115.39715,
              46.25895
          ],
          [
              -115.39696,
              46.25908
          ],
          [
              -115.39695,
              46.25908
          ],
          [
              -115.3967,
              46.25927
          ],
          [
              -115.39663,
              46.25934
          ],
          [
              -115.39661,
              46.25935
          ],
          [
              -115.39646,
              46.2595
          ],
          [
              -115.39641,
              46.25954
          ],
          [
              -115.39637,
              46.25959
          ],
          [
              -115.39627,
              46.25969
          ],
          [
              -115.39618,
              46.2598
          ],
          [
              -115.39617,
              46.25982
          ],
          [
              -115.39605,
              46.25997
          ],
          [
              -115.39603,
              46.26001
          ],
          [
              -115.39599,
              46.26006
          ],
          [
              -115.39596,
              46.26011
          ],
          [
              -115.39594,
              46.26016
          ],
          [
              -115.39591,
              46.2602
          ],
          [
              -115.39578,
              46.26048
          ],
          [
              -115.39574,
              46.26059
          ],
          [
              -115.39568,
              46.26084
          ],
          [
              -115.39567,
              46.26087
          ],
          [
              -115.39565,
              46.26097
          ],
          [
              -115.39562,
              46.26107
          ],
          [
              -115.39561,
              46.26115
          ],
          [
              -115.39557,
              46.2613
          ],
          [
              -115.39554,
              46.26138
          ],
          [
              -115.39551,
              46.26149
          ],
          [
              -115.39549,
              46.26154
          ],
          [
              -115.39546,
              46.26167
          ],
          [
              -115.39541,
              46.26181
          ],
          [
              -115.3954,
              46.26188
          ],
          [
              -115.39539,
              46.2619
          ],
          [
              -115.39534,
              46.26207
          ],
          [
              -115.39529,
              46.26228
          ],
          [
              -115.3952,
              46.26259
          ],
          [
              -115.39519,
              46.2626
          ],
          [
              -115.39504,
              46.26309
          ],
          [
              -115.39503,
              46.26311
          ],
          [
              -115.39503,
              46.26313
          ],
          [
              -115.39495,
              46.2634
          ],
          [
              -115.39487,
              46.26363
          ],
          [
              -115.39486,
              46.26364
          ],
          [
              -115.39482,
              46.26379
          ],
          [
              -115.3948,
              46.26382
          ],
          [
              -115.3948,
              46.26383
          ],
          [
              -115.39461,
              46.26434
          ],
          [
              -115.3946,
              46.26439
          ],
          [
              -115.39456,
              46.26451
          ],
          [
              -115.39454,
              46.26464
          ],
          [
              -115.39451,
              46.26476
          ],
          [
              -115.3945,
              46.26483
          ],
          [
              -115.3945,
              46.26492
          ],
          [
              -115.39448,
              46.26503
          ],
          [
              -115.39448,
              46.26511
          ],
          [
              -115.39447,
              46.26516
          ],
          [
              -115.39447,
              46.26577
          ],
          [
              -115.39448,
              46.26579
          ],
          [
              -115.39449,
              46.2662
          ],
          [
              -115.3945,
              46.2663
          ],
          [
              -115.3945,
              46.26669
          ],
          [
              -115.39446,
              46.26693
          ],
          [
              -115.39445,
              46.26702
          ],
          [
              -115.39445,
              46.26706
          ],
          [
              -115.39444,
              46.26709
          ],
          [
              -115.39444,
              46.26711
          ],
          [
              -115.39443,
              46.26713
          ],
          [
              -115.39443,
              46.26715
          ],
          [
              -115.39442,
              46.26717
          ],
          [
              -115.39442,
              46.2672
          ],
          [
              -115.39437,
              46.26734
          ],
          [
              -115.39436,
              46.26738
          ],
          [
              -115.39428,
              46.26761
          ],
          [
              -115.39426,
              46.26765
          ],
          [
              -115.39426,
              46.26767
          ],
          [
              -115.39422,
              46.26775
          ],
          [
              -115.3942,
              46.26778
          ],
          [
              -115.39412,
              46.26796
          ],
          [
              -115.39409,
              46.26801
          ],
          [
              -115.39407,
              46.26803
          ],
          [
              -115.39403,
              46.26809
          ],
          [
              -115.394,
              46.26816
          ],
          [
              -115.39399,
              46.26817
          ],
          [
              -115.39396,
              46.26821
          ],
          [
              -115.39393,
              46.26826
          ],
          [
              -115.39388,
              46.26832
          ],
          [
              -115.39387,
              46.26834
          ],
          [
              -115.39383,
              46.26839
          ],
          [
              -115.39379,
              46.26845
          ],
          [
              -115.39375,
              46.26849
          ],
          [
              -115.39374,
              46.26851
          ],
          [
              -115.39371,
              46.26854
          ],
          [
              -115.39364,
              46.26863
          ],
          [
              -115.39348,
              46.2688
          ],
          [
              -115.39335,
              46.26896
          ],
          [
              -115.39321,
              46.26911
          ],
          [
              -115.39298,
              46.26938
          ],
          [
              -115.39294,
              46.26942
          ],
          [
              -115.39292,
              46.26945
          ],
          [
              -115.39285,
              46.26953
          ],
          [
              -115.39278,
              46.2696
          ],
          [
              -115.39268,
              46.26973
          ],
          [
              -115.39257,
              46.26989
          ],
          [
              -115.39251,
              46.26999
          ],
          [
              -115.39248,
              46.27005
          ],
          [
              -115.39241,
              46.27016
          ],
          [
              -115.39238,
              46.27023
          ],
          [
              -115.39235,
              46.27027
          ],
          [
              -115.39224,
              46.2705
          ],
          [
              -115.39222,
              46.27057
          ],
          [
              -115.39216,
              46.27073
          ],
          [
              -115.39216,
              46.27074
          ],
          [
              -115.3921,
              46.27096
          ],
          [
              -115.3921,
              46.27099
          ],
          [
              -115.39209,
              46.27102
          ],
          [
              -115.39209,
              46.27108
          ],
          [
              -115.39207,
              46.27117
          ],
          [
              -115.39207,
              46.27128
          ],
          [
              -115.39205,
              46.27154
          ],
          [
              -115.39205,
              46.272
          ],
          [
              -115.39207,
              46.27215
          ],
          [
              -115.39207,
              46.27218
          ],
          [
              -115.39208,
              46.2722
          ],
          [
              -115.39208,
              46.27224
          ],
          [
              -115.39209,
              46.27229
          ],
          [
              -115.3921,
              46.27232
          ],
          [
              -115.3921,
              46.27235
          ],
          [
              -115.39211,
              46.27236
          ],
          [
              -115.39211,
              46.27239
          ],
          [
              -115.39212,
              46.27241
          ],
          [
              -115.39212,
              46.27244
          ],
          [
              -115.39214,
              46.27248
          ],
          [
              -115.39215,
              46.27252
          ],
          [
              -115.39215,
              46.27253
          ],
          [
              -115.39216,
              46.27255
          ],
          [
              -115.39218,
              46.27263
          ],
          [
              -115.3922,
              46.27266
          ],
          [
              -115.39222,
              46.27275
          ],
          [
              -115.39224,
              46.27278
          ],
          [
              -115.39225,
              46.27282
          ],
          [
              -115.39229,
              46.2729
          ],
          [
              -115.3923,
              46.27294
          ],
          [
              -115.39234,
              46.27302
          ],
          [
              -115.39236,
              46.27305
          ],
          [
              -115.39236,
              46.27306
          ],
          [
              -115.39241,
              46.27315
          ],
          [
              -115.39246,
              46.27322
          ],
          [
              -115.39248,
              46.27324
          ],
          [
              -115.39251,
              46.2733
          ],
          [
              -115.39257,
              46.27338
          ],
          [
              -115.3926,
              46.27343
          ],
          [
              -115.39268,
              46.27354
          ],
          [
              -115.39271,
              46.27357
          ],
          [
              -115.39279,
              46.27369
          ],
          [
              -115.3928,
              46.2737
          ],
          [
              -115.39285,
              46.27377
          ],
          [
              -115.3929,
              46.27383
          ],
          [
              -115.39302,
              46.27402
          ],
          [
              -115.39303,
              46.27403
          ],
          [
              -115.39312,
              46.27419
          ],
          [
              -115.39317,
              46.2743
          ],
          [
              -115.39319,
              46.27433
          ],
          [
              -115.3932,
              46.27436
          ],
          [
              -115.3932,
              46.27437
          ],
          [
              -115.39323,
              46.27443
          ],
          [
              -115.39327,
              46.27454
          ],
          [
              -115.39328,
              46.27455
          ],
          [
              -115.39329,
              46.27462
          ],
          [
              -115.39331,
              46.27467
          ],
          [
              -115.39332,
              46.27471
          ],
          [
              -115.39332,
              46.27473
          ],
          [
              -115.39335,
              46.27487
          ],
          [
              -115.39341,
              46.27525
          ],
          [
              -115.39342,
              46.27536
          ],
          [
              -115.39345,
              46.27552
          ],
          [
              -115.39347,
              46.2757
          ],
          [
              -115.39347,
              46.27576
          ],
          [
              -115.39348,
              46.27579
          ],
          [
              -115.39348,
              46.27605
          ],
          [
              -115.39347,
              46.27613
          ],
          [
              -115.39347,
              46.27615
          ],
          [
              -115.39345,
              46.27627
          ],
          [
              -115.39345,
              46.27633
          ],
          [
              -115.39344,
              46.27634
          ],
          [
              -115.39343,
              46.27641
          ],
          [
              -115.39343,
              46.27647
          ],
          [
              -115.39342,
              46.27651
          ],
          [
              -115.39341,
              46.27658
          ],
          [
              -115.39339,
              46.27667
          ],
          [
              -115.39339,
              46.27668
          ],
          [
              -115.39337,
              46.2768
          ],
          [
              -115.39327,
              46.27723
          ],
          [
              -115.39321,
              46.27739
          ],
          [
              -115.39321,
              46.2774
          ],
          [
              -115.39319,
              46.27745
          ],
          [
              -115.39318,
              46.27749
          ],
          [
              -115.39316,
              46.27753
          ],
          [
              -115.39315,
              46.27756
          ],
          [
              -115.39315,
              46.27757
          ],
          [
              -115.39314,
              46.27758
          ],
          [
              -115.39313,
              46.27761
          ],
          [
              -115.3931,
              46.27767
          ],
          [
              -115.39309,
              46.27768
          ],
          [
              -115.39307,
              46.27772
          ],
          [
              -115.39306,
              46.27773
          ],
          [
              -115.39305,
              46.27775
          ],
          [
              -115.39304,
              46.27776
          ],
          [
              -115.39304,
              46.27777
          ],
          [
              -115.39302,
              46.27779
          ],
          [
              -115.39299,
              46.27783
          ],
          [
              -115.39296,
              46.27786
          ],
          [
              -115.39294,
              46.27789
          ],
          [
              -115.39294,
              46.2779
          ],
          [
              -115.39291,
              46.27792
          ],
          [
              -115.39281,
              46.27804
          ],
          [
              -115.39275,
              46.2781
          ],
          [
              -115.39268,
              46.27816
          ],
          [
              -115.39264,
              46.2782
          ],
          [
              -115.3926,
              46.27823
          ],
          [
              -115.39257,
              46.27826
          ],
          [
              -115.39251,
              46.27831
          ],
          [
              -115.39243,
              46.27836
          ],
          [
              -115.39231,
              46.27845
          ],
          [
              -115.3923,
              46.27846
          ],
          [
              -115.39225,
              46.27849
          ],
          [
              -115.3922,
              46.27851
          ],
          [
              -115.39216,
              46.27854
          ],
          [
              -115.39211,
              46.27857
          ],
          [
              -115.3921,
              46.27858
          ],
          [
              -115.39206,
              46.2786
          ],
          [
              -115.39193,
              46.27869
          ],
          [
              -115.39191,
              46.2787
          ],
          [
              -115.39175,
              46.27881
          ],
          [
              -115.39173,
              46.27883
          ],
          [
              -115.39165,
              46.27889
          ],
          [
              -115.39162,
              46.27892
          ],
          [
              -115.39158,
              46.27894
          ],
          [
              -115.39152,
              46.279
          ],
          [
              -115.39148,
              46.27903
          ],
          [
              -115.39145,
              46.27907
          ],
          [
              -115.3914,
              46.27911
          ],
          [
              -115.39128,
              46.27923
          ],
          [
              -115.39114,
              46.27942
          ],
          [
              -115.39113,
              46.27943
          ],
          [
              -115.39111,
              46.27947
          ],
          [
              -115.39109,
              46.27949
          ],
          [
              -115.39108,
              46.27951
          ],
          [
              -115.39107,
              46.27954
          ],
          [
              -115.39105,
              46.27957
          ],
          [
              -115.39104,
              46.27959
          ],
          [
              -115.39104,
              46.2796
          ],
          [
              -115.39097,
              46.27976
          ],
          [
              -115.39094,
              46.27987
          ],
          [
              -115.39093,
              46.27993
          ],
          [
              -115.39091,
              46.28
          ],
          [
              -115.39079,
              46.28089
          ],
          [
              -115.39079,
              46.28094
          ],
          [
              -115.39077,
              46.28101
          ],
          [
              -115.39075,
              46.28115
          ],
          [
              -115.39073,
              46.28121
          ],
          [
              -115.39071,
              46.28132
          ],
          [
              -115.39068,
              46.28141
          ],
          [
              -115.39065,
              46.28156
          ],
          [
              -115.39055,
              46.28189
          ],
          [
              -115.39051,
              46.28206
          ],
          [
              -115.39048,
              46.28215
          ],
          [
              -115.39047,
              46.28217
          ],
          [
              -115.39043,
              46.2823
          ],
          [
              -115.3904,
              46.28236
          ],
          [
              -115.39038,
              46.28243
          ],
          [
              -115.39035,
              46.28248
          ],
          [
              -115.39033,
              46.28253
          ],
          [
              -115.39028,
              46.28261
          ],
          [
              -115.39024,
              46.28266
          ],
          [
              -115.39019,
              46.28274
          ],
          [
              -115.39017,
              46.28278
          ],
          [
              -115.39014,
              46.28281
          ],
          [
              -115.39013,
              46.28283
          ],
          [
              -115.39011,
              46.28286
          ],
          [
              -115.39007,
              46.2829
          ],
          [
              -115.39005,
              46.28293
          ],
          [
              -115.39002,
              46.28296
          ],
          [
              -115.39,
              46.28299
          ],
          [
              -115.38998,
              46.28301
          ],
          [
              -115.38995,
              46.28303
          ],
          [
              -115.38991,
              46.28307
          ],
          [
              -115.38988,
              46.28311
          ],
          [
              -115.38983,
              46.28315
          ],
          [
              -115.3898,
              46.28318
          ],
          [
              -115.38979,
              46.28318
          ],
          [
              -115.38975,
              46.28322
          ],
          [
              -115.38964,
              46.28331
          ],
          [
              -115.38947,
              46.28343
          ],
          [
              -115.38946,
              46.28345
          ],
          [
              -115.3894,
              46.28348
          ],
          [
              -115.38929,
              46.28356
          ],
          [
              -115.38886,
              46.28379
          ],
          [
              -115.38876,
              46.28385
          ],
          [
              -115.38834,
              46.28404
          ],
          [
              -115.38798,
              46.28418
          ],
          [
              -115.38713,
              46.28454
          ],
          [
              -115.3871,
              46.28456
          ],
          [
              -115.38666,
              46.28475
          ],
          [
              -115.38665,
              46.28475
          ],
          [
              -115.38658,
              46.28479
          ],
          [
              -115.3865,
              46.28482
          ],
          [
              -115.38636,
              46.2849
          ],
          [
              -115.38627,
              46.28494
          ],
          [
              -115.38624,
              46.28496
          ],
          [
              -115.38614,
              46.28501
          ],
          [
              -115.3861,
              46.28504
          ],
          [
              -115.38606,
              46.28506
          ],
          [
              -115.38604,
              46.28508
          ],
          [
              -115.38601,
              46.2851
          ],
          [
              -115.38595,
              46.28513
          ],
          [
              -115.3856,
              46.28538
          ],
          [
              -115.38553,
              46.28545
          ],
          [
              -115.38546,
              46.2855
          ],
          [
              -115.38539,
              46.28556
          ],
          [
              -115.38535,
              46.28561
          ],
          [
              -115.38525,
              46.28571
          ],
          [
              -115.38521,
              46.28576
          ],
          [
              -115.3852,
              46.28576
          ],
          [
              -115.38508,
              46.28591
          ],
          [
              -115.38505,
              46.28594
          ],
          [
              -115.385,
              46.286
          ],
          [
              -115.38496,
              46.28606
          ],
          [
              -115.38492,
              46.28611
          ],
          [
              -115.38478,
              46.28634
          ],
          [
              -115.38476,
              46.28638
          ],
          [
              -115.38475,
              46.28641
          ],
          [
              -115.38469,
              46.28653
          ],
          [
              -115.38468,
              46.28656
          ],
          [
              -115.38466,
              46.2866
          ],
          [
              -115.38463,
              46.28671
          ],
          [
              -115.38459,
              46.28682
          ],
          [
              -115.38455,
              46.28697
          ],
          [
              -115.38452,
              46.28706
          ],
          [
              -115.38447,
              46.28727
          ],
          [
              -115.38446,
              46.28728
          ],
          [
              -115.38446,
              46.28731
          ],
          [
              -115.38444,
              46.28735
          ],
          [
              -115.38444,
              46.28737
          ],
          [
              -115.38443,
              46.28739
          ],
          [
              -115.38442,
              46.28742
          ],
          [
              -115.38441,
              46.28744
          ],
          [
              -115.38441,
              46.28746
          ],
          [
              -115.3844,
              46.28746
          ],
          [
              -115.38439,
              46.28748
          ],
          [
              -115.38438,
              46.28751
          ],
          [
              -115.38435,
              46.28757
          ],
          [
              -115.38434,
              46.28761
          ],
          [
              -115.38429,
              46.28768
          ],
          [
              -115.38427,
              46.28772
          ],
          [
              -115.38424,
              46.28776
          ],
          [
              -115.38421,
              46.28779
          ],
          [
              -115.3842,
              46.28781
          ],
          [
              -115.38407,
              46.288
          ],
          [
              -115.38402,
              46.28806
          ],
          [
              -115.38391,
              46.28817
          ],
          [
              -115.38385,
              46.28822
          ],
          [
              -115.38382,
              46.28826
          ],
          [
              -115.38374,
              46.28833
          ],
          [
              -115.38368,
              46.28839
          ],
          [
              -115.38366,
              46.2884
          ],
          [
              -115.38353,
              46.28851
          ],
          [
              -115.38312,
              46.28879
          ],
          [
              -115.38311,
              46.28879
          ],
          [
              -115.38237,
              46.28928
          ],
          [
              -115.38236,
              46.28928
          ],
          [
              -115.38224,
              46.28936
          ],
          [
              -115.38218,
              46.28941
          ],
          [
              -115.38214,
              46.28943
          ],
          [
              -115.38207,
              46.28949
          ],
          [
              -115.38198,
              46.28955
          ],
          [
              -115.38172,
              46.28975
          ],
          [
              -115.38169,
              46.28978
          ],
          [
              -115.38165,
              46.28981
          ],
          [
              -115.38164,
              46.28981
          ],
          [
              -115.3816,
              46.28985
          ],
          [
              -115.38154,
              46.28989
          ],
          [
              -115.38151,
              46.28992
          ],
          [
              -115.38148,
              46.28994
          ],
          [
              -115.38147,
              46.28996
          ],
          [
              -115.38144,
              46.28998
          ],
          [
              -115.38141,
              46.29001
          ],
          [
              -115.38136,
              46.29005
          ],
          [
              -115.38132,
              46.29009
          ],
          [
              -115.38131,
              46.29009
          ],
          [
              -115.38096,
              46.29043
          ],
          [
              -115.38085,
              46.29055
          ],
          [
              -115.38072,
              46.29067
          ],
          [
              -115.38069,
              46.29071
          ],
          [
              -115.38043,
              46.29098
          ],
          [
              -115.38021,
              46.29119
          ],
          [
              -115.38009,
              46.29128
          ],
          [
              -115.38005,
              46.29132
          ],
          [
              -115.38001,
              46.29134
          ],
          [
              -115.37998,
              46.29137
          ],
          [
              -115.37996,
              46.29138
          ],
          [
              -115.37988,
              46.29144
          ],
          [
              -115.37979,
              46.29149
          ],
          [
              -115.37975,
              46.29152
          ],
          [
              -115.3797,
              46.29155
          ],
          [
              -115.37963,
              46.2916
          ],
          [
              -115.37836,
              46.29233
          ],
          [
              -115.37781,
              46.29263
          ],
          [
              -115.37775,
              46.29267
          ],
          [
              -115.37753,
              46.29279
          ],
          [
              -115.37737,
              46.29289
          ],
          [
              -115.37735,
              46.29289
          ],
          [
              -115.377,
              46.29309
          ],
          [
              -115.37695,
              46.29311
          ],
          [
              -115.37686,
              46.29316
          ],
          [
              -115.37683,
              46.29319
          ],
          [
              -115.37679,
              46.29321
          ],
          [
              -115.37665,
              46.29331
          ],
          [
              -115.37662,
              46.29334
          ],
          [
              -115.37659,
              46.29336
          ],
          [
              -115.37658,
              46.29337
          ],
          [
              -115.37655,
              46.29339
          ],
          [
              -115.37652,
              46.29342
          ],
          [
              -115.37648,
              46.29347
          ],
          [
              -115.37643,
              46.29352
          ],
          [
              -115.37639,
              46.29355
          ],
          [
              -115.37634,
              46.29362
          ],
          [
              -115.37631,
              46.29365
          ],
          [
              -115.3763,
              46.29368
          ],
          [
              -115.37629,
              46.29368
          ],
          [
              -115.37619,
              46.29382
          ],
          [
              -115.37612,
              46.29395
          ],
          [
              -115.37607,
              46.29406
          ],
          [
              -115.37602,
              46.29419
          ],
          [
              -115.376,
              46.29423
          ],
          [
              -115.37599,
              46.29429
          ],
          [
              -115.37595,
              46.29442
          ],
          [
              -115.37594,
              46.29448
          ],
          [
              -115.37587,
              46.29471
          ],
          [
              -115.37586,
              46.29473
          ],
          [
              -115.37581,
              46.29492
          ],
          [
              -115.37579,
              46.29498
          ],
          [
              -115.37574,
              46.2952
          ],
          [
              -115.37572,
              46.29524
          ],
          [
              -115.37572,
              46.29526
          ],
          [
              -115.37558,
              46.29562
          ],
          [
              -115.3755,
              46.29578
          ],
          [
              -115.37547,
              46.29582
          ],
          [
              -115.37544,
              46.29587
          ],
          [
              -115.3754,
              46.29592
          ],
          [
              -115.37538,
              46.29594
          ],
          [
              -115.37526,
              46.29611
          ],
          [
              -115.37505,
              46.29636
          ],
          [
              -115.37502,
              46.29639
          ],
          [
              -115.37497,
              46.29645
          ],
          [
              -115.37488,
              46.29654
          ],
          [
              -115.37483,
              46.29658
          ],
          [
              -115.37477,
              46.29664
          ],
          [
              -115.37472,
              46.29668
          ],
          [
              -115.37465,
              46.29675
          ],
          [
              -115.37458,
              46.2968
          ],
          [
              -115.37456,
              46.29682
          ],
          [
              -115.37406,
              46.29723
          ],
          [
              -115.37373,
              46.29752
          ],
          [
              -115.37371,
              46.29753
          ],
          [
              -115.37363,
              46.2976
          ],
          [
              -115.37357,
              46.29766
          ],
          [
              -115.37355,
              46.29767
          ],
          [
              -115.37349,
              46.29773
          ],
          [
              -115.37346,
              46.29777
          ],
          [
              -115.37342,
              46.29781
          ],
          [
              -115.37325,
              46.29802
          ],
          [
              -115.37321,
              46.29808
          ],
          [
              -115.37314,
              46.29816
          ],
          [
              -115.37305,
              46.29828
          ],
          [
              -115.37304,
              46.2983
          ],
          [
              -115.37293,
              46.29844
          ],
          [
              -115.37293,
              46.29845
          ],
          [
              -115.3728,
              46.29864
          ],
          [
              -115.37272,
              46.29877
          ],
          [
              -115.37261,
              46.29893
          ],
          [
              -115.37256,
              46.29902
          ],
          [
              -115.37247,
              46.29916
          ],
          [
              -115.37241,
              46.29927
          ],
          [
              -115.37239,
              46.29932
          ],
          [
              -115.37224,
              46.29958
          ],
          [
              -115.37221,
              46.29965
          ],
          [
              -115.37218,
              46.2997
          ],
          [
              -115.3721,
              46.29987
          ],
          [
              -115.37186,
              46.30047
          ],
          [
              -115.3718,
              46.30064
          ],
          [
              -115.37173,
              46.30081
          ],
          [
              -115.37173,
              46.30082
          ],
          [
              -115.37171,
              46.30086
          ],
          [
              -115.37169,
              46.30089
          ],
          [
              -115.37165,
              46.30097
          ],
          [
              -115.37164,
              46.30098
          ],
          [
              -115.37162,
              46.30102
          ],
          [
              -115.3715,
              46.30121
          ],
          [
              -115.37148,
              46.30125
          ],
          [
              -115.37144,
              46.3013
          ],
          [
              -115.37142,
              46.30134
          ],
          [
              -115.37137,
              46.3014
          ],
          [
              -115.37133,
              46.30146
          ],
          [
              -115.37077,
              46.30215
          ],
          [
              -115.3707,
              46.30225
          ],
          [
              -115.37066,
              46.30229
          ],
          [
              -115.37063,
              46.30235
          ],
          [
              -115.3706,
              46.30238
          ],
          [
              -115.37055,
              46.30248
          ],
          [
              -115.37047,
              46.30275
          ],
          [
              -115.37045,
              46.30286
          ],
          [
              -115.37045,
              46.30293
          ],
          [
              -115.37044,
              46.30299
          ],
          [
              -115.37044,
              46.30321
          ],
          [
              -115.37045,
              46.30326
          ],
          [
              -115.37045,
              46.30335
          ],
          [
              -115.37046,
              46.30338
          ],
          [
              -115.37046,
              46.30342
          ],
          [
              -115.3705,
              46.30357
          ],
          [
              -115.3705,
              46.30358
          ],
          [
              -115.37053,
              46.30364
          ],
          [
              -115.37054,
              46.30367
          ],
          [
              -115.37056,
              46.30371
          ],
          [
              -115.37057,
              46.30374
          ],
          [
              -115.37059,
              46.30376
          ],
          [
              -115.37061,
              46.3038
          ],
          [
              -115.37063,
              46.30383
          ],
          [
              -115.37064,
              46.30385
          ],
          [
              -115.37069,
              46.30391
          ],
          [
              -115.37085,
              46.30416
          ],
          [
              -115.37088,
              46.30419
          ],
          [
              -115.37109,
              46.3045
          ],
          [
              -115.37178,
              46.30543
          ],
          [
              -115.37178,
              46.30545
          ],
          [
              -115.37184,
              46.30553
          ],
          [
              -115.37207,
              46.30592
          ],
          [
              -115.37208,
              46.30595
          ],
          [
              -115.37214,
              46.30607
          ],
          [
              -115.37217,
              46.30612
          ],
          [
              -115.37219,
              46.30617
          ],
          [
              -115.37222,
              46.30623
          ],
          [
              -115.37224,
              46.30629
          ],
          [
              -115.37228,
              46.30638
          ],
          [
              -115.37231,
              46.3065
          ],
          [
              -115.37246,
              46.30694
          ],
          [
              -115.37247,
              46.30699
          ],
          [
              -115.37248,
              46.30702
          ],
          [
              -115.37249,
              46.30708
          ],
          [
              -115.37251,
              46.30715
          ],
          [
              -115.37251,
              46.30725
          ],
          [
              -115.37252,
              46.30734
          ],
          [
              -115.37252,
              46.30748
          ],
          [
              -115.37248,
              46.30771
          ],
          [
              -115.37243,
              46.30788
          ],
          [
              -115.37236,
              46.30805
          ],
          [
              -115.37233,
              46.3081
          ],
          [
              -115.37229,
              46.30815
          ],
          [
              -115.37226,
              46.3082
          ],
          [
              -115.37206,
              46.30844
          ],
          [
              -115.37197,
              46.30853
          ],
          [
              -115.37193,
              46.30856
          ],
          [
              -115.37189,
              46.3086
          ],
          [
              -115.37185,
              46.30863
          ],
          [
              -115.37181,
              46.30867
          ],
          [
              -115.37173,
              46.30873
          ],
          [
              -115.37163,
              46.30879
          ],
          [
              -115.37147,
              46.30887
          ],
          [
              -115.37142,
              46.3089
          ],
          [
              -115.37136,
              46.30892
          ],
          [
              -115.37133,
              46.30894
          ],
          [
              -115.37131,
              46.30894
          ],
          [
              -115.37125,
              46.30897
          ],
          [
              -115.3711,
              46.30903
          ],
          [
              -115.37085,
              46.30909
          ],
          [
              -115.37072,
              46.30913
          ],
          [
              -115.37049,
              46.30917
          ],
          [
              -115.37044,
              46.30917
          ],
          [
              -115.37029,
              46.30919
          ],
          [
              -115.37005,
              46.30919
          ],
          [
              -115.36996,
              46.3092
          ],
          [
              -115.36985,
              46.3092
          ],
          [
              -115.36977,
              46.30919
          ],
          [
              -115.36958,
              46.30919
          ],
          [
              -115.36932,
              46.30916
          ],
          [
              -115.36925,
              46.30916
          ],
          [
              -115.36819,
              46.30906
          ],
          [
              -115.3681,
              46.30906
          ],
          [
              -115.36803,
              46.30905
          ],
          [
              -115.36766,
              46.30905
          ],
          [
              -115.36753,
              46.30906
          ],
          [
              -115.36731,
              46.30906
          ],
          [
              -115.36725,
              46.30907
          ],
          [
              -115.36719,
              46.30907
          ],
          [
              -115.3671,
              46.30908
          ],
          [
              -115.36699,
              46.3091
          ],
          [
              -115.36678,
              46.30912
          ],
          [
              -115.36658,
              46.30916
          ],
          [
              -115.36649,
              46.30917
          ],
          [
              -115.36642,
              46.30919
          ],
          [
              -115.36632,
              46.30921
          ],
          [
              -115.36624,
              46.30922
          ],
          [
              -115.36617,
              46.30924
          ],
          [
              -115.36607,
              46.30926
          ],
          [
              -115.366,
              46.30928
          ],
          [
              -115.36595,
              46.3093
          ],
          [
              -115.36563,
              46.3094
          ],
          [
              -115.36555,
              46.30942
          ],
          [
              -115.36551,
              46.30944
          ],
          [
              -115.36547,
              46.30945
          ],
          [
              -115.36541,
              46.30948
          ],
          [
              -115.36533,
              46.30951
          ],
          [
              -115.36529,
              46.30953
          ],
          [
              -115.36519,
              46.30956
          ],
          [
              -115.36493,
              46.30968
          ],
          [
              -115.36486,
              46.30972
          ],
          [
              -115.36485,
              46.30972
          ],
          [
              -115.36438,
              46.30998
          ],
          [
              -115.36425,
              46.31007
          ],
          [
              -115.36424,
              46.31007
          ],
          [
              -115.36413,
              46.31015
          ],
          [
              -115.36406,
              46.31019
          ],
          [
              -115.36288,
              46.31104
          ],
          [
              -115.36268,
              46.3112
          ],
          [
              -115.36265,
              46.31123
          ],
          [
              -115.3626,
              46.31127
          ],
          [
              -115.36255,
              46.31132
          ],
          [
              -115.3625,
              46.31136
          ],
          [
              -115.36235,
              46.31151
          ],
          [
              -115.36232,
              46.31156
          ],
          [
              -115.36224,
              46.31166
          ],
          [
              -115.36222,
              46.31169
          ],
          [
              -115.36221,
              46.3117
          ],
          [
              -115.36217,
              46.31176
          ],
          [
              -115.36214,
              46.31182
          ],
          [
              -115.36211,
              46.31186
          ],
          [
              -115.36207,
              46.31194
          ],
          [
              -115.36203,
              46.312
          ],
          [
              -115.36202,
              46.31203
          ],
          [
              -115.36194,
              46.3122
          ],
          [
              -115.36189,
              46.31237
          ],
          [
              -115.36188,
              46.31239
          ],
          [
              -115.36186,
              46.31245
          ],
          [
              -115.36184,
              46.31255
          ],
          [
              -115.36183,
              46.31258
          ],
          [
              -115.3618,
              46.31273
          ],
          [
              -115.3618,
              46.31277
          ],
          [
              -115.36178,
              46.31285
          ],
          [
              -115.36176,
              46.31301
          ],
          [
              -115.36176,
              46.31342
          ],
          [
              -115.36177,
              46.31345
          ],
          [
              -115.36177,
              46.31346
          ],
          [
              -115.36178,
              46.3135
          ],
          [
              -115.36179,
              46.31358
          ],
          [
              -115.3618,
              46.31362
          ],
          [
              -115.36181,
              46.31363
          ],
          [
              -115.36182,
              46.31367
          ],
          [
              -115.36184,
              46.31372
          ],
          [
              -115.36185,
              46.31377
          ],
          [
              -115.36187,
              46.3138
          ],
          [
              -115.36189,
              46.31385
          ],
          [
              -115.3619,
              46.31389
          ],
          [
              -115.36192,
              46.31393
          ],
          [
              -115.36193,
              46.31396
          ],
          [
              -115.36194,
              46.31397
          ],
          [
              -115.362,
              46.31409
          ],
          [
              -115.36203,
              46.31413
          ],
          [
              -115.36246,
              46.3148
          ],
          [
              -115.36257,
              46.31501
          ],
          [
              -115.36258,
              46.31504
          ],
          [
              -115.3626,
              46.31508
          ],
          [
              -115.3626,
              46.3151
          ],
          [
              -115.36261,
              46.31514
          ],
          [
              -115.36262,
              46.31516
          ],
          [
              -115.36262,
              46.3152
          ],
          [
              -115.36264,
              46.31527
          ],
          [
              -115.36264,
              46.31532
          ],
          [
              -115.36265,
              46.31532
          ],
          [
              -115.36265,
              46.31541
          ],
          [
              -115.36264,
              46.31543
          ],
          [
              -115.36264,
              46.31555
          ],
          [
              -115.36263,
              46.31556
          ],
          [
              -115.36263,
              46.31559
          ],
          [
              -115.36262,
              46.31561
          ],
          [
              -115.36261,
              46.31565
          ],
          [
              -115.36259,
              46.31569
          ],
          [
              -115.36257,
              46.31576
          ],
          [
              -115.36253,
              46.31584
          ],
          [
              -115.36246,
              46.31595
          ],
          [
              -115.36245,
              46.31598
          ],
          [
              -115.36243,
              46.316
          ],
          [
              -115.36241,
              46.31603
          ],
          [
              -115.36239,
              46.31605
          ],
          [
              -115.36237,
              46.31608
          ],
          [
              -115.36232,
              46.31613
          ],
          [
              -115.36229,
              46.31617
          ],
          [
              -115.36226,
              46.3162
          ],
          [
              -115.36223,
              46.31624
          ],
          [
              -115.3622,
              46.31626
          ],
          [
              -115.36216,
              46.3163
          ],
          [
              -115.3621,
              46.31635
          ],
          [
              -115.36205,
              46.3164
          ],
          [
              -115.36166,
              46.31672
          ],
          [
              -115.36164,
              46.31673
          ],
          [
              -115.36147,
              46.31686
          ],
          [
              -115.36146,
              46.31686
          ],
          [
              -115.36141,
              46.31689
          ],
          [
              -115.36137,
              46.31692
          ],
          [
              -115.36132,
              46.31695
          ],
          [
              -115.36125,
              46.317
          ],
          [
              -115.36053,
              46.31745
          ],
          [
              -115.36051,
              46.31747
          ],
          [
              -115.35885,
              46.3185
          ],
          [
              -115.35877,
              46.31854
          ],
          [
              -115.35876,
              46.31855
          ],
          [
              -115.3587,
              46.31858
          ],
          [
              -115.35865,
              46.3186
          ],
          [
              -115.3586,
              46.31863
          ],
          [
              -115.35855,
              46.31865
          ],
          [
              -115.3584,
              46.31873
          ],
          [
              -115.35835,
              46.31875
          ],
          [
              -115.35833,
              46.31875
          ],
          [
              -115.35829,
              46.31877
          ],
          [
              -115.35818,
              46.31881
          ],
          [
              -115.35812,
              46.31884
          ],
          [
              -115.35784,
              46.31894
          ],
          [
              -115.35706,
              46.31919
          ],
          [
              -115.35698,
              46.31921
          ],
          [
              -115.35684,
              46.31927
          ],
          [
              -115.35676,
              46.31929
          ],
          [
              -115.3567,
              46.31932
          ],
          [
              -115.35665,
              46.31934
          ],
          [
              -115.3566,
              46.31937
          ],
          [
              -115.35654,
              46.31939
          ],
          [
              -115.35638,
              46.31948
          ],
          [
              -115.35634,
              46.31951
          ],
          [
              -115.35631,
              46.31952
          ],
          [
              -115.35628,
              46.31954
          ],
          [
              -115.35623,
              46.31959
          ],
          [
              -115.35619,
              46.31961
          ],
          [
              -115.35616,
              46.31965
          ],
          [
              -115.35613,
              46.31967
          ],
          [
              -115.35598,
              46.31982
          ],
          [
              -115.35585,
              46.31999
          ],
          [
              -115.3558,
              46.32007
          ],
          [
              -115.35578,
              46.32011
          ],
          [
              -115.35576,
              46.32013
          ],
          [
              -115.35575,
              46.32016
          ],
          [
              -115.35566,
              46.32033
          ],
          [
              -115.35561,
              46.32045
          ],
          [
              -115.35555,
              46.32056
          ],
          [
              -115.35546,
              46.32078
          ],
          [
              -115.35542,
              46.32091
          ],
          [
              -115.35532,
              46.32117
          ],
          [
              -115.35529,
              46.32123
          ],
          [
              -115.35519,
              46.32138
          ],
          [
              -115.35481,
              46.322
          ],
          [
              -115.35477,
              46.32208
          ],
          [
              -115.3547,
              46.32219
          ],
          [
              -115.35444,
              46.32265
          ],
          [
              -115.3542,
              46.32299
          ],
          [
              -115.35417,
              46.32302
          ],
          [
              -115.35408,
              46.32313
          ],
          [
              -115.35406,
              46.32314
          ],
          [
              -115.35399,
              46.32322
          ],
          [
              -115.35377,
              46.32344
          ],
          [
              -115.35356,
              46.32363
          ],
          [
              -115.35346,
              46.32373
          ],
          [
              -115.35325,
              46.32392
          ],
          [
              -115.35289,
              46.32427
          ],
          [
              -115.35268,
              46.32445
          ],
          [
              -115.35261,
              46.32452
          ],
          [
              -115.35126,
              46.32571
          ],
          [
              -115.35124,
              46.32572
          ],
          [
              -115.35108,
              46.32587
          ],
          [
              -115.3491,
              46.32758
          ],
          [
              -115.3479,
              46.32865
          ],
          [
              -115.34724,
              46.32932
          ],
          [
              -115.34703,
              46.32957
          ],
          [
              -115.347,
              46.32962
          ],
          [
              -115.34699,
              46.32963
          ],
          [
              -115.34695,
              46.32969
          ],
          [
              -115.34683,
              46.3299
          ],
          [
              -115.34676,
              46.33005
          ],
          [
              -115.34673,
              46.33014
          ],
          [
              -115.34672,
              46.33016
          ],
          [
              -115.34667,
              46.33036
          ],
          [
              -115.34666,
              46.33044
          ],
          [
              -115.34664,
              46.33054
          ],
          [
              -115.34663,
              46.33067
          ],
          [
              -115.34662,
              46.33073
          ],
          [
              -115.34659,
              46.3311
          ],
          [
              -115.34656,
              46.3318
          ],
          [
              -115.34653,
              46.33206
          ],
          [
              -115.34653,
              46.33211
          ],
          [
              -115.34651,
              46.33232
          ],
          [
              -115.34651,
              46.33247
          ],
          [
              -115.34649,
              46.33264
          ],
          [
              -115.34648,
              46.33332
          ],
          [
              -115.34647,
              46.33335
          ],
          [
              -115.34643,
              46.33344
          ],
          [
              -115.34642,
              46.33348
          ],
          [
              -115.34629,
              46.33374
          ],
          [
              -115.34622,
              46.33385
          ],
          [
              -115.34618,
              46.3339
          ],
          [
              -115.34615,
              46.33395
          ],
          [
              -115.34602,
              46.33411
          ],
          [
              -115.34599,
              46.33414
          ],
          [
              -115.34595,
              46.33419
          ],
          [
              -115.34588,
              46.33425
          ],
          [
              -115.34581,
              46.33432
          ],
          [
              -115.3458,
              46.33434
          ],
          [
              -115.34576,
              46.33436
          ],
          [
              -115.34558,
              46.33451
          ],
          [
              -115.34545,
              46.3346
          ],
          [
              -115.34425,
              46.33529
          ],
          [
              -115.34419,
              46.33533
          ],
          [
              -115.34299,
              46.33603
          ],
          [
              -115.34282,
              46.33611
          ],
          [
              -115.34271,
              46.33615
          ],
          [
              -115.34262,
              46.33619
          ],
          [
              -115.34219,
              46.33632
          ],
          [
              -115.34175,
              46.33641
          ],
          [
              -115.34168,
              46.33642
          ],
          [
              -115.34164,
              46.33642
          ],
          [
              -115.3416,
              46.33643
          ],
          [
              -115.34153,
              46.33643
          ],
          [
              -115.34145,
              46.33644
          ],
          [
              -115.34134,
              46.33644
          ],
          [
              -115.34121,
              46.33645
          ],
          [
              -115.34097,
              46.33645
          ],
          [
              -115.34061,
              46.33641
          ],
          [
              -115.34036,
              46.33636
          ],
          [
              -115.34013,
              46.3363
          ],
          [
              -115.34011,
              46.3363
          ],
          [
              -115.33998,
              46.33626
          ],
          [
              -115.33908,
              46.33594
          ],
          [
              -115.33782,
              46.33544
          ],
          [
              -115.33723,
              46.33519
          ],
          [
              -115.33709,
              46.33511
          ],
          [
              -115.33689,
              46.33498
          ],
          [
              -115.33682,
              46.33492
          ],
          [
              -115.33674,
              46.33486
          ],
          [
              -115.33647,
              46.33461
          ],
          [
              -115.33604,
              46.33413
          ],
          [
              -115.33553,
              46.33353
          ],
          [
              -115.33545,
              46.33345
          ],
          [
              -115.33532,
              46.33334
          ],
          [
              -115.33521,
              46.33326
          ],
          [
              -115.33514,
              46.3332
          ],
          [
              -115.33496,
              46.33308
          ],
          [
              -115.33458,
              46.33287
          ],
          [
              -115.33423,
              46.33274
          ],
          [
              -115.33414,
              46.33272
          ],
          [
              -115.33401,
              46.33268
          ],
          [
              -115.33384,
              46.33264
          ],
          [
              -115.33361,
              46.3326
          ],
          [
              -115.33311,
              46.33255
          ],
          [
              -115.33273,
              46.33255
          ],
          [
              -115.3326,
              46.33256
          ],
          [
              -115.33248,
              46.33258
          ],
          [
              -115.33238,
              46.33259
          ],
          [
              -115.33219,
              46.33263
          ],
          [
              -115.33166,
              46.33279
          ],
          [
              -115.3315,
              46.33286
          ],
          [
              -115.33138,
              46.33292
          ],
          [
              -115.33124,
              46.333
          ],
          [
              -115.33111,
              46.33309
          ],
          [
              -115.33106,
              46.33313
          ],
          [
              -115.33105,
              46.33315
          ],
          [
              -115.33095,
              46.33323
          ],
          [
              -115.33091,
              46.33328
          ],
          [
              -115.33085,
              46.33334
          ],
          [
              -115.33079,
              46.33342
          ],
          [
              -115.33078,
              46.33343
          ],
          [
              -115.33067,
              46.33358
          ],
          [
              -115.33058,
              46.33376
          ],
          [
              -115.33055,
              46.33385
          ],
          [
              -115.3305,
              46.33397
          ],
          [
              -115.33047,
              46.33413
          ],
          [
              -115.33047,
              46.33418
          ],
          [
              -115.33046,
              46.33426
          ],
          [
              -115.33046,
              46.33432
          ],
          [
              -115.33048,
              46.33453
          ],
          [
              -115.33049,
              46.33459
          ],
          [
              -115.33053,
              46.33476
          ],
          [
              -115.33056,
              46.33483
          ],
          [
              -115.33057,
              46.33488
          ],
          [
              -115.33062,
              46.33501
          ],
          [
              -115.33066,
              46.33509
          ],
          [
              -115.33077,
              46.33535
          ],
          [
              -115.33087,
              46.33556
          ],
          [
              -115.33092,
              46.3357
          ],
          [
              -115.33093,
              46.33571
          ],
          [
              -115.33098,
              46.33587
          ],
          [
              -115.331,
              46.33591
          ],
          [
              -115.33102,
              46.33598
          ],
          [
              -115.33103,
              46.33605
          ],
          [
              -115.33103,
              46.33614
          ],
          [
              -115.33104,
              46.33621
          ],
          [
              -115.33104,
              46.33634
          ],
          [
              -115.33103,
              46.3364
          ],
          [
              -115.33099,
              46.33652
          ],
          [
              -115.33098,
              46.33657
          ],
          [
              -115.33097,
              46.33658
          ],
          [
              -115.33087,
              46.33677
          ],
          [
              -115.33077,
              46.3369
          ],
          [
              -115.33075,
              46.33691
          ],
          [
              -115.33074,
              46.33693
          ],
          [
              -115.33069,
              46.33698
          ],
          [
              -115.3306,
              46.33705
          ],
          [
              -115.33059,
              46.33705
          ],
          [
              -115.33053,
              46.3371
          ],
          [
              -115.33039,
              46.33719
          ],
          [
              -115.33032,
              46.33722
          ],
          [
              -115.33021,
              46.33728
          ],
          [
              -115.3302,
              46.33728
          ],
          [
              -115.33014,
              46.33731
          ],
          [
              -115.32999,
              46.33737
          ],
          [
              -115.32997,
              46.33737
          ],
          [
              -115.3299,
              46.3374
          ],
          [
              -115.32981,
              46.33742
          ],
          [
              -115.32925,
              46.33758
          ],
          [
              -115.3245,
              46.33882
          ],
          [
              -115.32432,
              46.33885
          ],
          [
              -115.32419,
              46.33888
          ],
          [
              -115.32366,
              46.33896
          ],
          [
              -115.32351,
              46.33899
          ],
          [
              -115.32308,
              46.33904
          ],
          [
              -115.32306,
              46.33904
          ],
          [
              -115.3212,
              46.33924
          ],
          [
              -115.3211,
              46.33924
          ],
          [
              -115.31827,
              46.33952
          ],
          [
              -115.31769,
              46.33956
          ],
          [
              -115.31761,
              46.33957
          ],
          [
              -115.31746,
              46.33957
          ],
          [
              -115.31739,
              46.33958
          ],
          [
              -115.31731,
              46.33958
          ],
          [
              -115.3172,
              46.33957
          ],
          [
              -115.31703,
              46.33957
          ],
          [
              -115.31657,
              46.33954
          ],
          [
              -115.3164,
              46.33952
          ],
          [
              -115.31583,
              46.33943
          ],
          [
              -115.31512,
              46.33934
          ],
          [
              -115.31504,
              46.33934
          ],
          [
              -115.31497,
              46.33933
          ],
          [
              -115.31489,
              46.33933
          ],
          [
              -115.31478,
              46.33932
          ],
          [
              -115.31459,
              46.33932
          ],
          [
              -115.31451,
              46.33933
          ],
          [
              -115.31439,
              46.33933
          ],
          [
              -115.31431,
              46.33934
          ],
          [
              -115.31424,
              46.33934
          ],
          [
              -115.3141,
              46.33936
          ],
          [
              -115.31379,
              46.33943
          ],
          [
              -115.31367,
              46.33947
          ],
          [
              -115.31365,
              46.33948
          ],
          [
              -115.31359,
              46.3395
          ],
          [
              -115.31358,
              46.33951
          ],
          [
              -115.31346,
              46.33956
          ],
          [
              -115.31339,
              46.3396
          ],
          [
              -115.31323,
              46.33971
          ],
          [
              -115.3132,
              46.33974
          ],
          [
              -115.31313,
              46.33979
          ],
          [
              -115.31306,
              46.33986
          ],
          [
              -115.31296,
              46.33999
          ],
          [
              -115.31295,
              46.34
          ],
          [
              -115.31292,
              46.34005
          ],
          [
              -115.31291,
              46.34006
          ],
          [
              -115.31269,
              46.34047
          ],
          [
              -115.31258,
              46.3407
          ],
          [
              -115.31247,
              46.3409
          ],
          [
              -115.31233,
              46.34111
          ],
          [
              -115.31226,
              46.3412
          ],
          [
              -115.31218,
              46.34129
          ],
          [
              -115.31209,
              46.34138
          ],
          [
              -115.31184,
              46.3416
          ],
          [
              -115.31145,
              46.34198
          ],
          [
              -115.31139,
              46.34205
          ],
          [
              -115.31109,
              46.34247
          ],
          [
              -115.31064,
              46.34323
          ],
          [
              -115.31056,
              46.34335
          ],
          [
              -115.31045,
              46.34349
          ],
          [
              -115.3104,
              46.34354
          ],
          [
              -115.31033,
              46.3436
          ],
          [
              -115.31026,
              46.34367
          ],
          [
              -115.31018,
              46.34374
          ],
          [
              -115.31,
              46.34387
          ],
          [
              -115.3098,
              46.344
          ],
          [
              -115.30941,
              46.34423
          ],
          [
              -115.30932,
              46.34429
          ],
          [
              -115.30918,
              46.34437
          ],
          [
              -115.30913,
              46.34441
          ],
          [
              -115.30907,
              46.34445
          ],
          [
              -115.30896,
              46.34454
          ],
          [
              -115.30876,
              46.34472
          ],
          [
              -115.30867,
              46.34481
          ],
          [
              -115.30858,
              46.34493
          ],
          [
              -115.30857,
              46.34496
          ],
          [
              -115.30854,
              46.345
          ],
          [
              -115.30851,
              46.34506
          ],
          [
              -115.30848,
              46.34514
          ],
          [
              -115.30843,
              46.34532
          ],
          [
              -115.30824,
              46.34625
          ],
          [
              -115.30822,
              46.3463
          ],
          [
              -115.30819,
              46.3464
          ],
          [
              -115.30813,
              46.34655
          ],
          [
              -115.30807,
              46.34667
          ],
          [
              -115.30801,
              46.34675
          ],
          [
              -115.30797,
              46.34682
          ],
          [
              -115.30791,
              46.34688
          ],
          [
              -115.30791,
              46.34689
          ],
          [
              -115.30777,
              46.34703
          ],
          [
              -115.30774,
              46.34705
          ],
          [
              -115.30763,
              46.34714
          ],
          [
              -115.30759,
              46.34716
          ],
          [
              -115.30757,
              46.34718
          ],
          [
              -115.30751,
              46.34721
          ],
          [
              -115.30745,
              46.34725
          ],
          [
              -115.30735,
              46.3473
          ],
          [
              -115.30719,
              46.34736
          ],
          [
              -115.30713,
              46.34739
          ],
          [
              -115.30697,
              46.34744
          ],
          [
              -115.30669,
              46.34751
          ],
          [
              -115.30643,
              46.34755
          ],
          [
              -115.3064,
              46.34756
          ],
          [
              -115.30518,
              46.34771
          ],
          [
              -115.30477,
              46.34778
          ],
          [
              -115.30466,
              46.34781
          ],
          [
              -115.30455,
              46.34783
          ],
          [
              -115.30422,
              46.34794
          ],
          [
              -115.30418,
              46.34796
          ],
          [
              -115.30417,
              46.34796
          ],
          [
              -115.30412,
              46.34798
          ],
          [
              -115.3039,
              46.3481
          ],
          [
              -115.30377,
              46.34818
          ],
          [
              -115.30352,
              46.3484
          ],
          [
              -115.30345,
              46.34849
          ],
          [
              -115.30339,
              46.34858
          ],
          [
              -115.30337,
              46.34863
          ],
          [
              -115.30336,
              46.34864
          ],
          [
              -115.30335,
              46.34868
          ],
          [
              -115.30331,
              46.34877
          ],
          [
              -115.30328,
              46.3489
          ],
          [
              -115.30328,
              46.34894
          ],
          [
              -115.30327,
              46.34898
          ],
          [
              -115.30327,
              46.34915
          ],
          [
              -115.30328,
              46.34917
          ],
          [
              -115.3033,
              46.34929
          ],
          [
              -115.30334,
              46.3494
          ],
          [
              -115.3034,
              46.34952
          ],
          [
              -115.30354,
              46.34972
          ],
          [
              -115.30379,
              46.34996
          ],
          [
              -115.30395,
              46.35009
          ],
          [
              -115.30416,
              46.35024
          ],
          [
              -115.30417,
              46.35025
          ],
          [
              -115.30506,
              46.35089
          ],
          [
              -115.30522,
              46.35103
          ],
          [
              -115.30522,
              46.35104
          ],
          [
              -115.30528,
              46.3511
          ],
          [
              -115.30533,
              46.35116
          ],
          [
              -115.30538,
              46.35123
          ],
          [
              -115.30544,
              46.3513
          ],
          [
              -115.30554,
              46.35147
          ],
          [
              -115.30558,
              46.35155
          ],
          [
              -115.30567,
              46.35178
          ],
          [
              -115.30573,
              46.35206
          ],
          [
              -115.30573,
              46.35216
          ],
          [
              -115.30574,
              46.35221
          ],
          [
              -115.30573,
              46.35226
          ],
          [
              -115.30573,
              46.35231
          ],
          [
              -115.30572,
              46.35234
          ],
          [
              -115.30572,
              46.35237
          ],
          [
              -115.30571,
              46.35242
          ],
          [
              -115.30571,
              46.35245
          ],
          [
              -115.3057,
              46.35248
          ],
          [
              -115.30565,
              46.35258
          ],
          [
              -115.30563,
              46.35263
          ],
          [
              -115.3056,
              46.35267
          ],
          [
              -115.30558,
              46.35271
          ],
          [
              -115.30555,
              46.35274
          ],
          [
              -115.30555,
              46.35275
          ],
          [
              -115.30542,
              46.35291
          ],
          [
              -115.30535,
              46.35298
          ],
          [
              -115.30528,
              46.35304
          ],
          [
              -115.30505,
              46.35321
          ],
          [
              -115.30497,
              46.35326
          ],
          [
              -115.3049,
              46.35329
          ],
          [
              -115.3049,
              46.3533
          ],
          [
              -115.30457,
              46.35345
          ],
          [
              -115.30395,
              46.35367
          ],
          [
              -115.3029,
              46.35399
          ],
          [
              -115.30225,
              46.35416
          ],
          [
              -115.30214,
              46.35418
          ],
          [
              -115.30203,
              46.35421
          ],
          [
              -115.30154,
              46.3543
          ],
          [
              -115.30131,
              46.35433
          ],
          [
              -115.30129,
              46.35433
          ],
          [
              -115.30084,
              46.35438
          ],
          [
              -115.30054,
              46.3544
          ],
          [
              -115.30046,
              46.3544
          ],
          [
              -115.30034,
              46.35441
          ],
          [
              -115.30017,
              46.35441
          ],
          [
              -115.30002,
              46.35442
          ],
          [
              -115.29952,
              46.35441
          ],
          [
              -115.29889,
              46.35435
          ],
          [
              -115.29873,
              46.35432
          ],
          [
              -115.29868,
              46.35432
          ],
          [
              -115.29793,
              46.35418
          ],
          [
              -115.29713,
              46.35396
          ],
          [
              -115.29662,
              46.35379
          ],
          [
              -115.29609,
              46.35358
          ],
          [
              -115.29606,
              46.35356
          ],
          [
              -115.29534,
              46.35325
          ],
          [
              -115.2952,
              46.3532
          ],
          [
              -115.2952,
              46.35319
          ],
          [
              -115.29505,
              46.35314
          ],
          [
              -115.29494,
              46.35309
          ],
          [
              -115.29479,
              46.35304
          ],
          [
              -115.29456,
              46.35298
          ],
          [
              -115.2945,
              46.35297
          ],
          [
              -115.29447,
              46.35296
          ],
          [
              -115.29438,
              46.35294
          ],
          [
              -115.29413,
              46.3529
          ],
          [
              -115.29401,
              46.35289
          ],
          [
              -115.29379,
              46.35289
          ],
          [
              -115.29347,
              46.35292
          ],
          [
              -115.29344,
              46.35292
          ],
          [
              -115.29312,
              46.35298
          ],
          [
              -115.29306,
              46.353
          ],
          [
              -115.29302,
              46.35302
          ],
          [
              -115.29295,
              46.35304
          ],
          [
              -115.29276,
              46.35312
          ],
          [
              -115.29276,
              46.35313
          ],
          [
              -115.29267,
              46.35317
          ],
          [
              -115.29258,
              46.35323
          ],
          [
              -115.29242,
              46.35336
          ],
          [
              -115.29239,
              46.35338
          ],
          [
              -115.29224,
              46.35353
          ],
          [
              -115.29215,
              46.35364
          ],
          [
              -115.29212,
              46.35369
          ],
          [
              -115.29209,
              46.35372
          ],
          [
              -115.29202,
              46.35383
          ],
          [
              -115.292,
              46.35385
          ],
          [
              -115.29194,
              46.35395
          ],
          [
              -115.29191,
              46.35402
          ],
          [
              -115.29188,
              46.35406
          ],
          [
              -115.29183,
              46.35418
          ],
          [
              -115.29182,
              46.35419
          ],
          [
              -115.29161,
              46.35471
          ],
          [
              -115.29151,
              46.35499
          ],
          [
              -115.29111,
              46.356
          ],
          [
              -115.29105,
              46.35617
          ],
          [
              -115.29097,
              46.35634
          ],
          [
              -115.29091,
              46.35645
          ],
          [
              -115.29073,
              46.35673
          ],
          [
              -115.29046,
              46.35707
          ],
          [
              -115.29036,
              46.35717
          ],
          [
              -115.29013,
              46.35737
          ],
          [
              -115.29013,
              46.35738
          ],
          [
              -115.28995,
              46.35752
          ],
          [
              -115.28967,
              46.35771
          ],
          [
              -115.28958,
              46.35776
          ],
          [
              -115.28953,
              46.3578
          ],
          [
              -115.28921,
              46.35798
          ],
          [
              -115.28919,
              46.358
          ],
          [
              -115.28907,
              46.35805
          ],
          [
              -115.28886,
              46.35816
          ],
          [
              -115.28862,
              46.35826
          ],
          [
              -115.28852,
              46.35829
          ],
          [
              -115.2883,
              46.35837
          ],
          [
              -115.28815,
              46.35841
          ],
          [
              -115.28806,
              46.35844
          ],
          [
              -115.288,
              46.35845
          ],
          [
              -115.28783,
              46.3585
          ],
          [
              -115.28757,
              46.35856
          ],
          [
              -115.28684,
              46.35866
          ],
          [
              -115.2868,
              46.35866
          ],
          [
              -115.28648,
              46.35869
          ],
          [
              -115.28629,
              46.35869
          ],
          [
              -115.28576,
              46.35871
          ],
          [
              -115.28498,
              46.35871
          ],
          [
              -115.28493,
              46.35872
          ],
          [
              -115.28442,
              46.35872
          ],
          [
              -115.28426,
              46.35873
          ],
          [
              -115.28414,
              46.35873
          ],
          [
              -115.28398,
              46.35875
          ],
          [
              -115.28394,
              46.35875
          ],
          [
              -115.28358,
              46.35879
          ],
          [
              -115.28306,
              46.35883
          ],
          [
              -115.28262,
              46.35884
          ],
          [
              -115.28191,
              46.35888
          ],
          [
              -115.28173,
              46.3589
          ],
          [
              -115.28156,
              46.35893
          ],
          [
              -115.28139,
              46.35895
          ],
          [
              -115.28102,
              46.35902
          ],
          [
              -115.281,
              46.35903
          ],
          [
              -115.28082,
              46.35907
          ],
          [
              -115.28076,
              46.35909
          ],
          [
              -115.28042,
              46.35918
          ],
          [
              -115.27939,
              46.35955
          ],
          [
              -115.27869,
              46.35984
          ],
          [
              -115.27615,
              46.36097
          ],
          [
              -115.27512,
              46.36136
          ],
          [
              -115.27395,
              46.36176
          ],
          [
              -115.27359,
              46.36187
          ],
          [
              -115.27295,
              46.36209
          ],
          [
              -115.27256,
              46.36224
          ],
          [
              -115.27246,
              46.36227
          ],
          [
              -115.27228,
              46.36234
          ],
          [
              -115.27192,
              46.3625
          ],
          [
              -115.27176,
              46.36258
          ],
          [
              -115.27168,
              46.36263
          ],
          [
              -115.27163,
              46.36265
          ],
          [
              -115.27148,
              46.36273
          ],
          [
              -115.27131,
              46.36284
          ],
          [
              -115.27116,
              46.36295
          ],
          [
              -115.2706,
              46.36333
          ],
          [
              -115.26997,
              46.36372
          ],
          [
              -115.2699,
              46.36375
          ],
          [
              -115.26985,
              46.36378
          ],
          [
              -115.2698,
              46.3638
          ],
          [
              -115.26975,
              46.36383
          ],
          [
              -115.26914,
              46.36407
          ],
          [
              -115.26906,
              46.36409
          ],
          [
              -115.26861,
              46.36427
          ],
          [
              -115.26857,
              46.36428
          ],
          [
              -115.26808,
              46.36447
          ],
          [
              -115.2677,
              46.36465
          ],
          [
              -115.26765,
              46.36469
          ],
          [
              -115.2676,
              46.36471
          ],
          [
              -115.26755,
              46.36475
          ],
          [
              -115.26737,
              46.36486
          ],
          [
              -115.26705,
              46.36511
          ],
          [
              -115.26616,
              46.36594
          ],
          [
              -115.26596,
              46.36611
          ],
          [
              -115.2659,
              46.36617
          ],
          [
              -115.26577,
              46.36628
          ],
          [
              -115.26574,
              46.36631
          ],
          [
              -115.26564,
              46.36638
          ],
          [
              -115.26522,
              46.36671
          ],
          [
              -115.2645,
              46.36722
          ],
          [
              -115.26393,
              46.36757
          ],
          [
              -115.26295,
              46.36813
          ],
          [
              -115.26236,
              46.36844
          ],
          [
              -115.26146,
              46.36884
          ],
          [
              -115.261,
              46.36906
          ],
          [
              -115.26097,
              46.36907
          ],
          [
              -115.26059,
              46.36927
          ],
          [
              -115.26051,
              46.36933
          ],
          [
              -115.26045,
              46.36936
          ],
          [
              -115.26026,
              46.36951
          ],
          [
              -115.26016,
              46.36961
          ],
          [
              -115.26008,
              46.36971
          ],
          [
              -115.26008,
              46.36972
          ],
          [
              -115.26005,
              46.36975
          ],
          [
              -115.25997,
              46.36987
          ],
          [
              -115.25995,
              46.36994
          ],
          [
              -115.25993,
              46.36999
          ],
          [
              -115.25992,
              46.37003
          ],
          [
              -115.2599,
              46.37008
          ],
          [
              -115.25989,
              46.37014
          ],
          [
              -115.25989,
              46.37034
          ],
          [
              -115.25991,
              46.37048
          ],
          [
              -115.25993,
              46.37056
          ],
          [
              -115.25995,
              46.37062
          ],
          [
              -115.26003,
              46.37076
          ],
          [
              -115.26004,
              46.37077
          ],
          [
              -115.26008,
              46.37084
          ],
          [
              -115.26014,
              46.3709
          ],
          [
              -115.26015,
              46.37092
          ],
          [
              -115.26018,
              46.37094
          ],
          [
              -115.26021,
              46.37098
          ],
          [
              -115.26027,
              46.37103
          ],
          [
              -115.2603,
              46.37107
          ],
          [
              -115.262,
              46.37266
          ],
          [
              -115.26222,
              46.37288
          ],
          [
              -115.26229,
              46.37296
          ],
          [
              -115.26229,
              46.37297
          ],
          [
              -115.26236,
              46.37305
          ],
          [
              -115.26245,
              46.37319
          ],
          [
              -115.26254,
              46.3734
          ],
          [
              -115.26257,
              46.3736
          ],
          [
              -115.26257,
              46.37366
          ],
          [
              -115.26256,
              46.37372
          ],
          [
              -115.26256,
              46.37377
          ],
          [
              -115.26255,
              46.37384
          ],
          [
              -115.26253,
              46.3739
          ],
          [
              -115.26252,
              46.37396
          ],
          [
              -115.26249,
              46.37402
          ],
          [
              -115.26244,
              46.37416
          ],
          [
              -115.26238,
              46.37425
          ],
          [
              -115.26233,
              46.37434
          ],
          [
              -115.26222,
              46.37449
          ],
          [
              -115.26213,
              46.3746
          ],
          [
              -115.26208,
              46.37465
          ],
          [
              -115.26204,
              46.3747
          ],
          [
              -115.26185,
              46.37489
          ],
          [
              -115.2615,
              46.37521
          ],
          [
              -115.26147,
              46.37523
          ],
          [
              -115.26142,
              46.37528
          ],
          [
              -115.2614,
              46.37529
          ],
          [
              -115.26108,
              46.37553
          ],
          [
              -115.26099,
              46.37559
          ],
          [
              -115.26091,
              46.37563
          ],
          [
              -115.26068,
              46.37577
          ],
          [
              -115.26067,
              46.37578
          ],
          [
              -115.26052,
              46.37585
          ],
          [
              -115.26029,
              46.37594
          ],
          [
              -115.26028,
              46.37595
          ],
          [
              -115.25995,
              46.37604
          ],
          [
              -115.25985,
              46.37605
          ],
          [
              -115.25973,
              46.37607
          ],
          [
              -115.25961,
              46.37608
          ],
          [
              -115.25955,
              46.37608
          ],
          [
              -115.25947,
              46.37609
          ],
          [
              -115.25924,
              46.37609
          ],
          [
              -115.25887,
              46.37605
          ],
          [
              -115.25881,
              46.37603
          ],
          [
              -115.25873,
              46.37602
          ],
          [
              -115.25858,
              46.37598
          ],
          [
              -115.25853,
              46.37596
          ],
          [
              -115.25852,
              46.37596
          ],
          [
              -115.25819,
              46.37585
          ],
          [
              -115.25807,
              46.3758
          ],
          [
              -115.25807,
              46.37579
          ],
          [
              -115.25798,
              46.37576
          ],
          [
              -115.2579,
              46.37572
          ],
          [
              -115.25786,
              46.37569
          ],
          [
              -115.25779,
              46.37566
          ],
          [
              -115.25778,
              46.37566
          ],
          [
              -115.25731,
              46.37539
          ],
          [
              -115.25714,
              46.37527
          ],
          [
              -115.2569,
              46.37508
          ],
          [
              -115.25544,
              46.3738
          ],
          [
              -115.25536,
              46.37374
          ],
          [
              -115.25513,
              46.3736
          ],
          [
              -115.25507,
              46.37357
          ],
          [
              -115.25505,
              46.37355
          ],
          [
              -115.2547,
              46.3734
          ],
          [
              -115.25461,
              46.37337
          ],
          [
              -115.25452,
              46.37335
          ],
          [
              -115.25442,
              46.37332
          ],
          [
              -115.25422,
              46.37328
          ],
          [
              -115.25387,
              46.37324
          ],
          [
              -115.25376,
              46.37324
          ],
          [
              -115.25367,
              46.37323
          ],
          [
              -115.25348,
              46.37323
          ],
          [
              -115.25308,
              46.37327
          ],
          [
              -115.25301,
              46.37329
          ],
          [
              -115.25294,
              46.3733
          ],
          [
              -115.2527,
              46.37337
          ],
          [
              -115.25248,
              46.37345
          ],
          [
              -115.25239,
              46.37349
          ],
          [
              -115.25237,
              46.37351
          ],
          [
              -115.25233,
              46.37353
          ],
          [
              -115.25225,
              46.37356
          ],
          [
              -115.25215,
              46.37362
          ],
          [
              -115.25213,
              46.37364
          ],
          [
              -115.25195,
              46.37376
          ],
          [
              -115.25185,
              46.37384
          ],
          [
              -115.25173,
              46.37396
          ],
          [
              -115.25162,
              46.37409
          ],
          [
              -115.25152,
              46.37424
          ],
          [
              -115.25148,
              46.37432
          ],
          [
              -115.25145,
              46.3744
          ],
          [
              -115.2514,
              46.37461
          ],
          [
              -115.25136,
              46.37491
          ],
          [
              -115.25121,
              46.37637
          ],
          [
              -115.25121,
              46.37639
          ],
          [
              -115.25118,
              46.37654
          ],
          [
              -115.25118,
              46.37658
          ],
          [
              -115.25112,
              46.37687
          ],
          [
              -115.25104,
              46.37716
          ],
          [
              -115.25089,
              46.3776
          ],
          [
              -115.25076,
              46.37794
          ],
          [
              -115.25068,
              46.37812
          ],
          [
              -115.25052,
              46.37842
          ],
          [
              -115.2504,
              46.37861
          ],
          [
              -115.25027,
              46.37878
          ],
          [
              -115.24978,
              46.3795
          ],
          [
              -115.24964,
              46.37973
          ],
          [
              -115.24963,
              46.37977
          ],
          [
              -115.24959,
              46.37984
          ],
          [
              -115.2495,
              46.38012
          ],
          [
              -115.24945,
              46.38032
          ],
          [
              -115.24944,
              46.38039
          ],
          [
              -115.24944,
              46.38045
          ],
          [
              -115.24934,
              46.38115
          ],
          [
              -115.24931,
              46.38127
          ],
          [
              -115.24931,
              46.38128
          ],
          [
              -115.24928,
              46.38139
          ],
          [
              -115.24913,
              46.38171
          ],
          [
              -115.249,
              46.38189
          ],
          [
              -115.24895,
              46.38195
          ],
          [
              -115.24877,
              46.38213
          ],
          [
              -115.24837,
              46.38241
          ],
          [
              -115.24827,
              46.38247
          ],
          [
              -115.24792,
              46.38262
          ],
          [
              -115.24778,
              46.38266
          ],
          [
              -115.24761,
              46.38272
          ],
          [
              -115.24731,
              46.38279
          ],
          [
              -115.24725,
              46.3828
          ],
          [
              -115.24717,
              46.38282
          ],
          [
              -115.24681,
              46.38286
          ],
          [
              -115.24654,
              46.38286
          ],
          [
              -115.24647,
              46.38287
          ],
          [
              -115.24633,
              46.38286
          ],
          [
              -115.24613,
              46.38286
          ],
          [
              -115.24577,
              46.38283
          ],
          [
              -115.24545,
              46.38279
          ],
          [
              -115.24481,
              46.38274
          ],
          [
              -115.24421,
              46.38271
          ],
          [
              -115.24342,
              46.38271
          ],
          [
              -115.24277,
              46.38273
          ],
          [
              -115.24169,
              46.38283
          ],
          [
              -115.2388,
              46.38316
          ],
          [
              -115.23829,
              46.3832
          ],
          [
              -115.23765,
              46.3832
          ],
          [
              -115.23711,
              46.38317
          ],
          [
              -115.23698,
              46.38315
          ],
          [
              -115.23694,
              46.38315
          ],
          [
              -115.23575,
              46.38295
          ],
          [
              -115.23546,
              46.38292
          ],
          [
              -115.2354,
              46.38292
          ],
          [
              -115.23532,
              46.38291
          ],
          [
              -115.23514,
              46.38291
          ],
          [
              -115.23505,
              46.38292
          ],
          [
              -115.23495,
              46.38292
          ],
          [
              -115.23485,
              46.38294
          ],
          [
              -115.23476,
              46.38295
          ],
          [
              -115.23468,
              46.38297
          ],
          [
              -115.23466,
              46.38297
          ],
          [
              -115.23457,
              46.38299
          ],
          [
              -115.23445,
              46.38303
          ],
          [
              -115.23443,
              46.38303
          ],
          [
              -115.23403,
              46.38319
          ],
          [
              -115.23399,
              46.38322
          ],
          [
              -115.23398,
              46.38322
          ],
          [
              -115.23387,
              46.38329
          ],
          [
              -115.23376,
              46.38338
          ],
          [
              -115.23364,
              46.3835
          ],
          [
              -115.23352,
              46.38365
          ],
          [
              -115.23343,
              46.3838
          ],
          [
              -115.23334,
              46.38406
          ],
          [
              -115.23318,
              46.38533
          ],
          [
              -115.23315,
              46.38545
          ],
          [
              -115.23312,
              46.38561
          ],
          [
              -115.23312,
              46.38564
          ],
          [
              -115.23308,
              46.38581
          ],
          [
              -115.23308,
              46.38585
          ],
          [
              -115.23303,
              46.38602
          ],
          [
              -115.23298,
              46.38614
          ],
          [
              -115.23292,
              46.38632
          ],
          [
              -115.23269,
              46.38688
          ],
          [
              -115.23262,
              46.38702
          ],
          [
              -115.23247,
              46.38729
          ],
          [
              -115.23242,
              46.38736
          ],
          [
              -115.23242,
              46.38737
          ],
          [
              -115.23239,
              46.38743
          ],
          [
              -115.2322,
              46.38768
          ],
          [
              -115.2319,
              46.38803
          ],
          [
              -115.23173,
              46.3882
          ],
          [
              -115.23156,
              46.38834
          ],
          [
              -115.2315,
              46.38838
          ],
          [
              -115.23144,
              46.38841
          ],
          [
              -115.23142,
              46.38843
          ],
          [
              -115.23124,
              46.38853
          ],
          [
              -115.23119,
              46.38855
          ],
          [
              -115.23112,
              46.38859
          ],
          [
              -115.23096,
              46.38866
          ],
          [
              -115.23076,
              46.38873
          ],
          [
              -115.2306,
              46.38877
          ],
          [
              -115.23055,
              46.38879
          ],
          [
              -115.23051,
              46.38879
          ],
          [
              -115.23034,
              46.38883
          ],
          [
              -115.23005,
              46.38886
          ],
          [
              -115.23,
              46.38886
          ],
          [
              -115.22995,
              46.38887
          ],
          [
              -115.22953,
              46.38887
          ],
          [
              -115.22947,
              46.38886
          ],
          [
              -115.22913,
              46.38883
          ],
          [
              -115.22905,
              46.38881
          ],
          [
              -115.22892,
              46.38879
          ],
          [
              -115.22881,
              46.38878
          ],
          [
              -115.22699,
              46.38835
          ],
          [
              -115.22635,
              46.38822
          ],
          [
              -115.22611,
              46.38818
          ],
          [
              -115.22602,
              46.38818
          ],
          [
              -115.22595,
              46.38817
          ],
          [
              -115.22549,
              46.38817
          ],
          [
              -115.22538,
              46.38818
          ],
          [
              -115.22531,
              46.38818
          ],
          [
              -115.22498,
              46.38823
          ],
          [
              -115.22467,
              46.38829
          ],
          [
              -115.22431,
              46.3884
          ],
          [
              -115.22403,
              46.38851
          ],
          [
              -115.22365,
              46.38869
          ],
          [
              -115.22319,
              46.38894
          ],
          [
              -115.22308,
              46.38899
          ],
          [
              -115.2199,
              46.3907
          ],
          [
              -115.2198,
              46.39074
          ],
          [
              -115.21969,
              46.3908
          ],
          [
              -115.21904,
              46.39109
          ],
          [
              -115.21851,
              46.39129
          ],
          [
              -115.21765,
              46.39164
          ],
          [
              -115.21738,
              46.39176
          ],
          [
              -115.21722,
              46.39184
          ],
          [
              -115.21694,
              46.392
          ],
          [
              -115.21684,
              46.39207
          ],
          [
              -115.2168,
              46.39209
          ],
          [
              -115.21665,
              46.39219
          ],
          [
              -115.21616,
              46.39261
          ],
          [
              -115.21581,
              46.39296
          ],
          [
              -115.21486,
              46.39396
          ],
          [
              -115.21454,
              46.39425
          ],
          [
              -115.21413,
              46.39458
          ],
          [
              -115.21411,
              46.39459
          ],
          [
              -115.21349,
              46.39505
          ],
          [
              -115.2131,
              46.39531
          ],
          [
              -115.21248,
              46.39567
          ],
          [
              -115.2107,
              46.39653
          ],
          [
              -115.21055,
              46.39661
          ],
          [
              -115.21041,
              46.3967
          ],
          [
              -115.21022,
              46.39684
          ],
          [
              -115.21013,
              46.39692
          ],
          [
              -115.20994,
              46.39713
          ],
          [
              -115.20986,
              46.39725
          ],
          [
              -115.20978,
              46.3974
          ],
          [
              -115.20964,
              46.39773
          ],
          [
              -115.20943,
              46.39836
          ],
          [
              -115.20938,
              46.39849
          ],
          [
              -115.20928,
              46.39869
          ],
          [
              -115.20917,
              46.39886
          ],
          [
              -115.20908,
              46.39898
          ],
          [
              -115.20885,
              46.39925
          ],
          [
              -115.20848,
              46.39959
          ],
          [
              -115.20778,
              46.40015
          ],
          [
              -115.2076,
              46.40031
          ],
          [
              -115.2067,
              46.40106
          ],
          [
              -115.20665,
              46.40109
          ],
          [
              -115.2066,
              46.40114
          ],
          [
              -115.20627,
              46.40134
          ],
          [
              -115.20611,
              46.40142
          ],
          [
              -115.20581,
              46.40155
          ],
          [
              -115.2056,
              46.40163
          ],
          [
              -115.20526,
              46.40174
          ],
          [
              -115.20482,
              46.40184
          ],
          [
              -115.2048,
              46.40184
          ],
          [
              -115.20444,
              46.4019
          ],
          [
              -115.20412,
              46.40193
          ],
          [
              -115.20404,
              46.40193
          ],
          [
              -115.20394,
              46.40194
          ],
          [
              -115.20351,
              46.40194
          ],
          [
              -115.20305,
              46.4019
          ],
          [
              -115.20293,
              46.40188
          ],
          [
              -115.20275,
              46.40186
          ],
          [
              -115.20227,
              46.40176
          ],
          [
              -115.19996,
              46.4012
          ],
          [
              -115.19976,
              46.40117
          ],
          [
              -115.1997,
              46.40115
          ],
          [
              -115.19944,
              46.40112
          ],
          [
              -115.19932,
              46.4011
          ],
          [
              -115.1988,
              46.40106
          ],
          [
              -115.1987,
              46.40106
          ],
          [
              -115.19858,
              46.40105
          ],
          [
              -115.19825,
              46.40105
          ],
          [
              -115.19818,
              46.40106
          ],
          [
              -115.19785,
              46.40108
          ],
          [
              -115.19768,
              46.4011
          ],
          [
              -115.19753,
              46.40113
          ],
          [
              -115.19747,
              46.40113
          ],
          [
              -115.19725,
              46.40117
          ],
          [
              -115.19673,
              46.40131
          ],
          [
              -115.1963,
              46.40146
          ],
          [
              -115.19583,
              46.40166
          ],
          [
              -115.19533,
              46.40192
          ],
          [
              -115.19475,
              46.40225
          ],
          [
              -115.19445,
              46.40239
          ],
          [
              -115.19405,
              46.40252
          ],
          [
              -115.19372,
              46.4026
          ],
          [
              -115.19356,
              46.40263
          ],
          [
              -115.19324,
              46.40271
          ],
          [
              -115.1928,
              46.40285
          ],
          [
              -115.19276,
              46.40287
          ],
          [
              -115.19273,
              46.40289
          ],
          [
              -115.19265,
              46.40293
          ],
          [
              -115.19256,
              46.40299
          ],
          [
              -115.1925,
              46.40302
          ],
          [
              -115.19245,
              46.40306
          ],
          [
              -115.19241,
              46.4031
          ],
          [
              -115.19238,
              46.40312
          ],
          [
              -115.19235,
              46.40315
          ],
          [
              -115.1923,
              46.40319
          ],
          [
              -115.19214,
              46.40335
          ],
          [
              -115.1921,
              46.4034
          ],
          [
              -115.19161,
              46.40412
          ],
          [
              -115.19155,
              46.40418
          ],
          [
              -115.19152,
              46.40422
          ],
          [
              -115.19143,
              46.40432
          ],
          [
              -115.19127,
              46.40446
          ],
          [
              -115.19123,
              46.40451
          ],
          [
              -115.19113,
              46.40458
          ],
          [
              -115.1911,
              46.40461
          ],
          [
              -115.19105,
              46.40464
          ],
          [
              -115.19101,
              46.40468
          ],
          [
              -115.19072,
              46.40486
          ],
          [
              -115.19044,
              46.40501
          ],
          [
              -115.19031,
              46.40507
          ],
          [
              -115.18999,
              46.40519
          ],
          [
              -115.18991,
              46.40521
          ],
          [
              -115.18983,
              46.40524
          ],
          [
              -115.18946,
              46.40533
          ],
          [
              -115.18928,
              46.40536
          ],
          [
              -115.18927,
              46.40537
          ],
          [
              -115.18852,
              46.40546
          ],
          [
              -115.18664,
              46.40557
          ],
          [
              -115.18642,
              46.4056
          ],
          [
              -115.18639,
              46.4056
          ],
          [
              -115.18615,
              46.40564
          ],
          [
              -115.18607,
              46.40566
          ],
          [
              -115.18598,
              46.40569
          ],
          [
              -115.18592,
              46.4057
          ],
          [
              -115.18569,
              46.40577
          ],
          [
              -115.18553,
              46.40583
          ],
          [
              -115.18546,
              46.40587
          ],
          [
              -115.18538,
              46.4059
          ],
          [
              -115.1853,
              46.40594
          ],
          [
              -115.18515,
              46.40603
          ],
          [
              -115.18507,
              46.40609
          ],
          [
              -115.18506,
              46.4061
          ],
          [
              -115.18501,
              46.40613
          ],
          [
              -115.18495,
              46.40619
          ],
          [
              -115.1849,
              46.40623
          ],
          [
              -115.18485,
              46.40628
          ],
          [
              -115.18474,
              46.40641
          ],
          [
              -115.18466,
              46.40653
          ],
          [
              -115.18459,
              46.40667
          ],
          [
              -115.18457,
              46.40673
          ],
          [
              -115.18455,
              46.40683
          ],
          [
              -115.18453,
              46.4069
          ],
          [
              -115.18453,
              46.40692
          ],
          [
              -115.18451,
              46.40704
          ],
          [
              -115.18451,
              46.40722
          ],
          [
              -115.18452,
              46.40725
          ],
          [
              -115.18452,
              46.40729
          ],
          [
              -115.18458,
              46.40748
          ],
          [
              -115.18459,
              46.40753
          ],
          [
              -115.18462,
              46.4076
          ],
          [
              -115.18467,
              46.40769
          ],
          [
              -115.18475,
              46.40787
          ],
          [
              -115.18496,
              46.40827
          ],
          [
              -115.18506,
              46.4085
          ],
          [
              -115.18514,
              46.40871
          ],
          [
              -115.18518,
              46.40884
          ],
          [
              -115.18522,
              46.40908
          ],
          [
              -115.18523,
              46.40919
          ],
          [
              -115.18522,
              46.40925
          ],
          [
              -115.18522,
              46.4093
          ],
          [
              -115.1852,
              46.40942
          ],
          [
              -115.18513,
              46.40962
          ],
          [
              -115.1851,
              46.40969
          ],
          [
              -115.18506,
              46.40974
          ],
          [
              -115.18503,
              46.4098
          ],
          [
              -115.18493,
              46.40994
          ],
          [
              -115.18487,
              46.41001
          ],
          [
              -115.18478,
              46.4101
          ],
          [
              -115.18463,
              46.41023
          ],
          [
              -115.18456,
              46.41028
          ],
          [
              -115.18439,
              46.41038
          ],
          [
              -115.18412,
              46.41051
          ],
          [
              -115.18387,
              46.4106
          ],
          [
              -115.18373,
              46.41064
          ],
          [
              -115.18336,
              46.41072
          ],
          [
              -115.1831,
              46.41076
          ],
          [
              -115.18261,
              46.4108
          ],
          [
              -115.18183,
              46.41081
          ],
          [
              -115.18025,
              46.41074
          ],
          [
              -115.17994,
              46.41074
          ],
          [
              -115.17981,
              46.41075
          ],
          [
              -115.1797,
              46.41075
          ],
          [
              -115.17937,
              46.41078
          ],
          [
              -115.17923,
              46.4108
          ],
          [
              -115.17899,
              46.41086
          ],
          [
              -115.17893,
              46.41088
          ],
          [
              -115.17871,
              46.41097
          ],
          [
              -115.17863,
              46.41101
          ],
          [
              -115.17849,
              46.4111
          ],
          [
              -115.17845,
              46.41112
          ],
          [
              -115.17842,
              46.41115
          ],
          [
              -115.17838,
              46.41117
          ],
          [
              -115.17835,
              46.4112
          ],
          [
              -115.17827,
              46.41126
          ],
          [
              -115.17823,
              46.4113
          ],
          [
              -115.17818,
              46.41136
          ],
          [
              -115.17813,
              46.41141
          ],
          [
              -115.17811,
              46.41145
          ],
          [
              -115.17803,
              46.41156
          ],
          [
              -115.17797,
              46.41167
          ],
          [
              -115.17788,
              46.41193
          ],
          [
              -115.17785,
              46.41205
          ],
          [
              -115.17783,
              46.41218
          ],
          [
              -115.17776,
              46.41317
          ],
          [
              -115.17773,
              46.41347
          ],
          [
              -115.17769,
              46.41365
          ],
          [
              -115.17768,
              46.41372
          ],
          [
              -115.17766,
              46.41376
          ],
          [
              -115.17765,
              46.41379
          ],
          [
              -115.17765,
              46.41381
          ],
          [
              -115.17763,
              46.41386
          ],
          [
              -115.17761,
              46.41389
          ],
          [
              -115.17759,
              46.41394
          ],
          [
              -115.17752,
              46.41404
          ],
          [
              -115.17748,
              46.41411
          ],
          [
              -115.17742,
              46.41419
          ],
          [
              -115.17736,
              46.41425
          ],
          [
              -115.1773,
              46.41432
          ],
          [
              -115.17715,
              46.41444
          ],
          [
              -115.17707,
              46.41449
          ],
          [
              -115.17699,
              46.41455
          ],
          [
              -115.1768,
              46.41465
          ],
          [
              -115.17665,
              46.41472
          ],
          [
              -115.17656,
              46.41475
          ],
          [
              -115.17648,
              46.41479
          ],
          [
              -115.17641,
              46.41481
          ],
          [
              -115.17636,
              46.41482
          ],
          [
              -115.17627,
              46.41485
          ],
          [
              -115.1762,
              46.41486
          ],
          [
              -115.176,
              46.41491
          ],
          [
              -115.17595,
              46.41491
          ],
          [
              -115.1759,
              46.41492
          ],
          [
              -115.17585,
              46.41492
          ],
          [
              -115.1758,
              46.41493
          ],
          [
              -115.17572,
              46.41493
          ],
          [
              -115.1756,
              46.41494
          ],
          [
              -115.17541,
              46.41494
          ],
          [
              -115.17531,
              46.41493
          ],
          [
              -115.17522,
              46.41493
          ],
          [
              -115.17514,
              46.41492
          ],
          [
              -115.17509,
              46.41492
          ],
          [
              -115.17477,
              46.41486
          ],
          [
              -115.17441,
              46.41477
          ],
          [
              -115.1742,
              46.4147
          ],
          [
              -115.17378,
              46.41458
          ],
          [
              -115.17084,
              46.41362
          ],
          [
              -115.17072,
              46.41359
          ],
          [
              -115.17064,
              46.41356
          ],
          [
              -115.17046,
              46.41352
          ],
          [
              -115.17023,
              46.41348
          ],
          [
              -115.17018,
              46.41348
          ],
          [
              -115.17013,
              46.41347
          ],
          [
              -115.17008,
              46.41347
          ],
          [
              -115.17,
              46.41346
          ],
          [
              -115.16961,
              46.41346
          ],
          [
              -115.16955,
              46.41347
          ],
          [
              -115.16949,
              46.41347
          ],
          [
              -115.16932,
              46.4135
          ],
          [
              -115.16921,
              46.41353
          ],
          [
              -115.1691,
              46.41357
          ],
          [
              -115.16905,
              46.41358
          ],
          [
              -115.16897,
              46.41362
          ],
          [
              -115.16889,
              46.41365
          ],
          [
              -115.16879,
              46.4137
          ],
          [
              -115.16862,
              46.4138
          ],
          [
              -115.16857,
              46.41384
          ],
          [
              -115.16855,
              46.41385
          ],
          [
              -115.16849,
              46.4139
          ],
          [
              -115.16832,
              46.41407
          ],
          [
              -115.16824,
              46.41417
          ],
          [
              -115.1682,
              46.41423
          ],
          [
              -115.16814,
              46.41438
          ],
          [
              -115.16813,
              46.41442
          ],
          [
              -115.16811,
              46.41454
          ],
          [
              -115.16808,
              46.41467
          ],
          [
              -115.16802,
              46.41529
          ],
          [
              -115.16802,
              46.41536
          ],
          [
              -115.16799,
              46.41551
          ],
          [
              -115.16796,
              46.41561
          ],
          [
              -115.16784,
              46.41586
          ],
          [
              -115.16766,
              46.41617
          ],
          [
              -115.16748,
              46.41644
          ],
          [
              -115.16708,
              46.41698
          ],
          [
              -115.16689,
              46.41727
          ],
          [
              -115.16683,
              46.41738
          ],
          [
              -115.16669,
              46.41767
          ],
          [
              -115.16661,
              46.41788
          ],
          [
              -115.16656,
              46.41804
          ],
          [
              -115.16649,
              46.41832
          ],
          [
              -115.16647,
              46.41843
          ],
          [
              -115.16647,
              46.41853
          ],
          [
              -115.16646,
              46.41867
          ],
          [
              -115.16646,
              46.41897
          ],
          [
              -115.16645,
              46.41903
          ],
          [
              -115.16643,
              46.4193
          ],
          [
              -115.16639,
              46.41955
          ],
          [
              -115.16633,
              46.41971
          ],
          [
              -115.16629,
              46.41978
          ],
          [
              -115.16626,
              46.41986
          ],
          [
              -115.16621,
              46.41993
          ],
          [
              -115.1661,
              46.42006
          ],
          [
              -115.16605,
              46.42011
          ],
          [
              -115.16601,
              46.42014
          ],
          [
              -115.16586,
              46.42027
          ],
          [
              -115.1658,
              46.42031
          ],
          [
              -115.16572,
              46.42035
          ],
          [
              -115.16564,
              46.4204
          ],
          [
              -115.16554,
              46.42044
          ],
          [
              -115.16544,
              46.42049
          ],
          [
              -115.16374,
              46.4211
          ],
          [
              -115.16337,
              46.42126
          ],
          [
              -115.16318,
              46.42136
          ],
          [
              -115.1629,
              46.42153
          ],
          [
              -115.16248,
              46.42182
          ],
          [
              -115.16238,
              46.4219
          ],
          [
              -115.16218,
              46.42208
          ],
          [
              -115.16205,
              46.42221
          ],
          [
              -115.16191,
              46.42238
          ],
          [
              -115.16186,
              46.42243
          ],
          [
              -115.16174,
              46.42261
          ],
          [
              -115.16167,
              46.4227
          ],
          [
              -115.16106,
              46.42375
          ],
          [
              -115.1609,
              46.424
          ],
          [
              -115.16086,
              46.42405
          ],
          [
              -115.16083,
              46.4241
          ],
          [
              -115.16055,
              46.42439
          ],
          [
              -115.16049,
              46.42443
          ],
          [
              -115.16035,
              46.42454
          ],
          [
              -115.16016,
              46.42465
          ],
          [
              -115.15993,
              46.42477
          ],
          [
              -115.15979,
              46.42483
          ],
          [
              -115.1596,
              46.4249
          ],
          [
              -115.15933,
              46.42497
          ],
          [
              -115.15924,
              46.425
          ],
          [
              -115.15918,
              46.42501
          ],
          [
              -115.15916,
              46.42501
          ],
          [
              -115.15903,
              46.42504
          ],
          [
              -115.15891,
              46.42506
          ],
          [
              -115.15861,
              46.42509
          ],
          [
              -115.15852,
              46.42509
          ],
          [
              -115.15844,
              46.4251
          ],
          [
              -115.15834,
              46.4251
          ],
          [
              -115.15824,
              46.42509
          ],
          [
              -115.15799,
              46.42509
          ],
          [
              -115.15782,
              46.42508
          ],
          [
              -115.15758,
              46.42505
          ],
          [
              -115.15737,
              46.42504
          ],
          [
              -115.15573,
              46.42488
          ],
          [
              -115.15562,
              46.42486
          ],
          [
              -115.15546,
              46.42484
          ],
          [
              -115.15519,
              46.42479
          ],
          [
              -115.15509,
              46.42478
          ],
          [
              -115.15463,
              46.42469
          ],
          [
              -115.15442,
              46.42464
          ],
          [
              -115.1543,
              46.42462
          ],
          [
              -115.15386,
              46.4245
          ],
          [
              -115.1534,
              46.42436
          ],
          [
              -115.15294,
              46.42419
          ],
          [
              -115.15243,
              46.42398
          ],
          [
              -115.15181,
              46.42369
          ],
          [
              -115.15171,
              46.42363
          ],
          [
              -115.15143,
              46.42348
          ],
          [
              -115.15055,
              46.42292
          ],
          [
              -115.14999,
              46.42261
          ],
          [
              -115.14981,
              46.42253
          ],
          [
              -115.1498,
              46.42253
          ],
          [
              -115.14967,
              46.42247
          ],
          [
              -115.14954,
              46.42243
          ],
          [
              -115.14942,
              46.42238
          ],
          [
              -115.14931,
              46.42235
          ],
          [
              -115.14907,
              46.42227
          ],
          [
              -115.14897,
              46.42225
          ],
          [
              -115.14887,
              46.42222
          ],
          [
              -115.14886,
              46.42222
          ],
          [
              -115.14855,
              46.42216
          ],
          [
              -115.14844,
              46.42215
          ],
          [
              -115.14834,
              46.42213
          ],
          [
              -115.14784,
              46.42209
          ],
          [
              -115.14771,
              46.42209
          ],
          [
              -115.14701,
              46.42206
          ],
          [
              -115.14642,
              46.42206
          ],
          [
              -115.14618,
              46.42208
          ],
          [
              -115.14582,
              46.42214
          ],
          [
              -115.14562,
              46.42221
          ],
          [
              -115.14555,
              46.42224
          ],
          [
              -115.14554,
              46.42224
          ],
          [
              -115.14532,
              46.42234
          ],
          [
              -115.14514,
              46.42245
          ],
          [
              -115.14504,
              46.42252
          ],
          [
              -115.14488,
              46.42265
          ],
          [
              -115.14478,
              46.42274
          ],
          [
              -115.14467,
              46.42285
          ],
          [
              -115.14457,
              46.42297
          ],
          [
              -115.14455,
              46.42301
          ],
          [
              -115.14452,
              46.42305
          ],
          [
              -115.1445,
              46.4231
          ],
          [
              -115.14445,
              46.42319
          ],
          [
              -115.14443,
              46.42324
          ],
          [
              -115.14442,
              46.42328
          ],
          [
              -115.14439,
              46.42335
          ],
          [
              -115.14436,
              46.42345
          ],
          [
              -115.14431,
              46.42372
          ],
          [
              -115.14431,
              46.42381
          ],
          [
              -115.1443,
              46.42394
          ],
          [
              -115.14431,
              46.42466
          ],
          [
              -115.14429,
              46.42491
          ],
          [
              -115.14427,
              46.42505
          ],
          [
              -115.14417,
              46.42536
          ],
          [
              -115.14412,
              46.42548
          ],
          [
              -115.14409,
              46.42554
          ],
          [
              -115.14404,
              46.42562
          ],
          [
              -115.14395,
              46.42579
          ],
          [
              -115.14389,
              46.42587
          ],
          [
              -115.14386,
              46.42592
          ],
          [
              -115.14363,
              46.42616
          ],
          [
              -115.14332,
              46.42644
          ],
          [
              -115.14297,
              46.42673
          ],
          [
              -115.14265,
              46.42696
          ],
          [
              -115.14261,
              46.42698
          ],
          [
              -115.1421,
              46.42732
          ],
          [
              -115.14198,
              46.42738
          ],
          [
              -115.14145,
              46.42768
          ],
          [
              -115.14125,
              46.42778
          ],
          [
              -115.141,
              46.42789
          ],
          [
              -115.14096,
              46.42792
          ],
          [
              -115.14031,
              46.4282
          ],
          [
              -115.13941,
              46.42853
          ],
          [
              -115.13856,
              46.42882
          ],
          [
              -115.13493,
              46.42998
          ],
          [
              -115.13377,
              46.43037
          ],
          [
              -115.13376,
              46.43037
          ],
          [
              -115.13277,
              46.43068
          ],
          [
              -115.13235,
              46.43079
          ],
          [
              -115.13185,
              46.4309
          ],
          [
              -115.13133,
              46.43097
          ],
          [
              -115.13131,
              46.43097
          ],
          [
              -115.13123,
              46.43098
          ],
          [
              -115.13112,
              46.43098
          ],
          [
              -115.13099,
              46.43099
          ],
          [
              -115.13064,
              46.43099
          ],
          [
              -115.13047,
              46.43098
          ],
          [
              -115.12997,
              46.43093
          ],
          [
              -115.12939,
              46.4308
          ],
          [
              -115.12915,
              46.43073
          ],
          [
              -115.12866,
              46.43056
          ],
          [
              -115.12856,
              46.43051
          ],
          [
              -115.12822,
              46.43037
          ],
          [
              -115.12725,
              46.42987
          ],
          [
              -115.12699,
              46.42975
          ],
          [
              -115.12672,
              46.42964
          ],
          [
              -115.1264,
              46.42953
          ],
          [
              -115.12615,
              46.42946
          ],
          [
              -115.12606,
              46.42944
          ],
          [
              -115.12596,
              46.42941
          ],
          [
              -115.12585,
              46.42939
          ],
          [
              -115.12577,
              46.42937
          ],
          [
              -115.12568,
              46.42936
          ],
          [
              -115.12545,
              46.42932
          ],
          [
              -115.12489,
              46.42926
          ],
          [
              -115.12388,
              46.42918
          ],
          [
              -115.12345,
              46.42912
          ],
          [
              -115.12335,
              46.4291
          ],
          [
              -115.12327,
              46.42909
          ],
          [
              -115.1231,
              46.42905
          ],
          [
              -115.12297,
              46.42901
          ],
          [
              -115.12281,
              46.42897
          ],
          [
              -115.12261,
              46.42891
          ],
          [
              -115.12245,
              46.42885
          ],
          [
              -115.12217,
              46.42876
          ],
          [
              -115.12155,
              46.4285
          ],
          [
              -115.12111,
              46.42834
          ],
          [
              -115.12064,
              46.42821
          ],
          [
              -115.12002,
              46.42809
          ],
          [
              -115.11973,
              46.42806
          ],
          [
              -115.11919,
              46.42804
          ],
          [
              -115.1191,
              46.42805
          ],
          [
              -115.11898,
              46.42805
          ],
          [
              -115.11823,
              46.42812
          ],
          [
              -115.11725,
              46.42825
          ],
          [
              -115.11708,
              46.42828
          ],
          [
              -115.11698,
              46.42831
          ],
          [
              -115.11682,
              46.42834
          ],
          [
              -115.11644,
              46.42847
          ],
          [
              -115.11635,
              46.42851
          ],
          [
              -115.11607,
              46.42866
          ],
          [
              -115.11594,
              46.42875
          ],
          [
              -115.11583,
              46.42884
          ],
          [
              -115.11573,
              46.42894
          ],
          [
              -115.11567,
              46.42901
          ],
          [
              -115.11554,
              46.42919
          ],
          [
              -115.11551,
              46.42924
          ],
          [
              -115.11546,
              46.42935
          ],
          [
              -115.11543,
              46.4294
          ],
          [
              -115.11537,
              46.42955
          ],
          [
              -115.11532,
              46.42971
          ],
          [
              -115.11529,
              46.42977
          ],
          [
              -115.11523,
              46.42999
          ],
          [
              -115.11515,
              46.43019
          ],
          [
              -115.11509,
              46.43032
          ],
          [
              -115.11502,
              46.43045
          ],
          [
              -115.11497,
              46.43051
          ],
          [
              -115.11493,
              46.43057
          ],
          [
              -115.11484,
              46.43068
          ],
          [
              -115.11477,
              46.43073
          ],
          [
              -115.11471,
              46.43079
          ],
          [
              -115.11464,
              46.43085
          ],
          [
              -115.11457,
              46.4309
          ],
          [
              -115.11456,
              46.43091
          ],
          [
              -115.11438,
              46.43103
          ],
          [
              -115.11283,
              46.43186
          ],
          [
              -115.11255,
              46.43202
          ],
          [
              -115.11246,
              46.43208
          ],
          [
              -115.11232,
              46.43219
          ],
          [
              -115.11226,
              46.43223
          ],
          [
              -115.11221,
              46.43227
          ],
          [
              -115.11218,
              46.4323
          ],
          [
              -115.11216,
              46.43231
          ],
          [
              -115.11213,
              46.43235
          ],
          [
              -115.11203,
              46.43245
          ],
          [
              -115.11191,
              46.43261
          ],
          [
              -115.1118,
              46.43281
          ],
          [
              -115.11174,
              46.43299
          ],
          [
              -115.11173,
              46.43307
          ],
          [
              -115.11171,
              46.43315
          ],
          [
              -115.1117,
              46.43324
          ],
          [
              -115.1117,
              46.43338
          ],
          [
              -115.11172,
              46.43354
          ],
          [
              -115.11172,
              46.43359
          ],
          [
              -115.11173,
              46.43367
          ],
          [
              -115.11175,
              46.43372
          ],
          [
              -115.11182,
              46.43399
          ],
          [
              -115.11191,
              46.43422
          ],
          [
              -115.11197,
              46.43441
          ],
          [
              -115.11204,
              46.43476
          ],
          [
              -115.11204,
              46.43498
          ],
          [
              -115.11202,
              46.43513
          ],
          [
              -115.11199,
              46.43524
          ],
          [
              -115.11192,
              46.43544
          ],
          [
              -115.11189,
              46.43551
          ],
          [
              -115.11175,
              46.43576
          ],
          [
              -115.11174,
              46.43577
          ],
          [
              -115.11161,
              46.43595
          ],
          [
              -115.11149,
              46.43609
          ],
          [
              -115.1114,
              46.43618
          ],
          [
              -115.11134,
              46.43623
          ],
          [
              -115.1113,
              46.43627
          ],
          [
              -115.11108,
              46.43644
          ],
          [
              -115.11082,
              46.4366
          ],
          [
              -115.11072,
              46.43665
          ],
          [
              -115.11031,
              46.43682
          ],
          [
              -115.11012,
              46.43689
          ],
          [
              -115.10941,
              46.43709
          ],
          [
              -115.10899,
              46.43719
          ],
          [
              -115.10866,
              46.43725
          ],
          [
              -115.10864,
              46.43725
          ],
          [
              -115.10836,
              46.43729
          ],
          [
              -115.10786,
              46.43732
          ],
          [
              -115.10755,
              46.43732
          ],
          [
              -115.10746,
              46.43731
          ],
          [
              -115.10739,
              46.43731
          ],
          [
              -115.10732,
              46.4373
          ],
          [
              -115.10725,
              46.4373
          ],
          [
              -115.10698,
              46.43726
          ],
          [
              -115.10686,
              46.43725
          ],
          [
              -115.10668,
              46.43721
          ],
          [
              -115.10655,
              46.43719
          ],
          [
              -115.10587,
              46.43702
          ],
          [
              -115.10522,
              46.43683
          ],
          [
              -115.10512,
              46.43681
          ],
          [
              -115.10482,
              46.43672
          ],
          [
              -115.10425,
              46.43658
          ],
          [
              -115.10389,
              46.43651
          ],
          [
              -115.10388,
              46.43651
          ],
          [
              -115.10374,
              46.43648
          ],
          [
              -115.10363,
              46.43647
          ],
          [
              -115.10354,
              46.43645
          ],
          [
              -115.10289,
              46.43639
          ],
          [
              -115.10242,
              46.43639
          ],
          [
              -115.10184,
              46.43643
          ],
          [
              -115.10162,
              46.43646
          ],
          [
              -115.10147,
              46.43649
          ],
          [
              -115.10129,
              46.43651
          ],
          [
              -115.10089,
              46.4366
          ],
          [
              -115.10056,
              46.43669
          ],
          [
              -115.10006,
              46.43686
          ],
          [
              -115.09971,
              46.43701
          ],
          [
              -115.09953,
              46.4371
          ],
          [
              -115.09918,
              46.4373
          ],
          [
              -115.09883,
              46.43755
          ],
          [
              -115.09865,
              46.4377
          ],
          [
              -115.09833,
              46.43801
          ],
          [
              -115.09804,
              46.43836
          ],
          [
              -115.09795,
              46.4385
          ],
          [
              -115.09781,
              46.43876
          ],
          [
              -115.09773,
              46.43894
          ],
          [
              -115.09593,
              46.44359
          ],
          [
              -115.09541,
              46.44487
          ],
          [
              -115.09535,
              46.44499
          ],
          [
              -115.09528,
              46.44515
          ],
          [
              -115.0952,
              46.44529
          ],
          [
              -115.09504,
              46.44552
          ],
          [
              -115.09489,
              46.4457
          ],
          [
              -115.09477,
              46.44582
          ],
          [
              -115.09455,
              46.446
          ],
          [
              -115.09426,
              46.44619
          ],
          [
              -115.09405,
              46.4463
          ],
          [
              -115.09366,
              46.44647
          ],
          [
              -115.09348,
              46.44653
          ],
          [
              -115.09324,
              46.44659
          ],
          [
              -115.09289,
              46.44665
          ],
          [
              -115.09261,
              46.44668
          ],
          [
              -115.09219,
              46.44669
          ],
          [
              -115.09199,
              46.44668
          ],
          [
              -115.09184,
              46.44668
          ],
          [
              -115.09138,
              46.44662
          ],
          [
              -115.09111,
              46.44657
          ],
          [
              -115.09081,
              46.4465
          ],
          [
              -115.08569,
              46.44502
          ],
          [
              -115.08554,
              46.44497
          ],
          [
              -115.08471,
              46.44476
          ],
          [
              -115.08452,
              46.44473
          ],
          [
              -115.08415,
              46.4447
          ],
          [
              -115.08383,
              46.4447
          ],
          [
              -115.08357,
              46.44472
          ],
          [
              -115.0835,
              46.44472
          ],
          [
              -115.08316,
              46.44478
          ],
          [
              -115.08292,
              46.44484
          ],
          [
              -115.08275,
              46.4449
          ],
          [
              -115.08258,
              46.44495
          ],
          [
              -115.08245,
              46.445
          ],
          [
              -115.08237,
              46.44504
          ],
          [
              -115.08227,
              46.44508
          ],
          [
              -115.08219,
              46.44513
          ],
          [
              -115.08209,
              46.44518
          ],
          [
              -115.08191,
              46.44529
          ],
          [
              -115.08186,
              46.44533
          ],
          [
              -115.08184,
              46.44534
          ],
          [
              -115.08173,
              46.44543
          ],
          [
              -115.0816,
              46.44556
          ],
          [
              -115.08154,
              46.44563
          ],
          [
              -115.0815,
              46.44569
          ],
          [
              -115.08145,
              46.44574
          ],
          [
              -115.08127,
              46.44603
          ],
          [
              -115.0812,
              46.44619
          ],
          [
              -115.08116,
              46.44634
          ],
          [
              -115.08115,
              46.44642
          ],
          [
              -115.08114,
              46.44658
          ],
          [
              -115.08114,
              46.4468
          ],
          [
              -115.08116,
              46.44687
          ],
          [
              -115.08119,
              46.44702
          ],
          [
              -115.08119,
              46.44704
          ],
          [
              -115.08122,
              46.44713
          ],
          [
              -115.08133,
              46.44736
          ],
          [
              -115.08139,
              46.44746
          ],
          [
              -115.08142,
              46.44752
          ],
          [
              -115.08143,
              46.44753
          ],
          [
              -115.08145,
              46.44757
          ],
          [
              -115.08153,
              46.44767
          ],
          [
              -115.08159,
              46.44773
          ],
          [
              -115.08173,
              46.44789
          ],
          [
              -115.0818,
              46.44794
          ],
          [
              -115.08265,
              46.44869
          ],
          [
              -115.08267,
              46.44872
          ],
          [
              -115.08289,
              46.44893
          ],
          [
              -115.08313,
              46.44921
          ],
          [
              -115.08331,
              46.44948
          ],
          [
              -115.0834,
              46.44963
          ],
          [
              -115.0834,
              46.44964
          ],
          [
              -115.08345,
              46.44972
          ],
          [
              -115.08348,
              46.4498
          ],
          [
              -115.08348,
              46.44981
          ],
          [
              -115.08351,
              46.44986
          ],
          [
              -115.08353,
              46.44995
          ],
          [
              -115.08355,
              46.45001
          ],
          [
              -115.08356,
              46.45006
          ],
          [
              -115.08358,
              46.45012
          ],
          [
              -115.08358,
              46.45019
          ],
          [
              -115.08361,
              46.45044
          ],
          [
              -115.08361,
              46.45053
          ],
          [
              -115.08359,
              46.45071
          ],
          [
              -115.08356,
              46.45084
          ],
          [
              -115.08344,
              46.45118
          ],
          [
              -115.08333,
              46.45141
          ],
          [
              -115.08328,
              46.4515
          ],
          [
              -115.08316,
              46.45167
          ],
          [
              -115.08301,
              46.45184
          ],
          [
              -115.08266,
              46.45217
          ],
          [
              -115.08261,
              46.45221
          ],
          [
              -115.08248,
              46.4523
          ],
          [
              -115.08215,
              46.45249
          ],
          [
              -115.08178,
              46.45267
          ],
          [
              -115.08172,
              46.45269
          ],
          [
              -115.08168,
              46.45271
          ],
          [
              -115.08127,
              46.45285
          ],
          [
              -115.0808,
              46.45298
          ],
          [
              -115.08019,
              46.4531
          ],
          [
              -115.07973,
              46.45316
          ],
          [
              -115.07924,
              46.4532
          ],
          [
              -115.079,
              46.4532
          ],
          [
              -115.07891,
              46.45321
          ],
          [
              -115.07874,
              46.4532
          ],
          [
              -115.07858,
              46.4532
          ],
          [
              -115.07812,
              46.45316
          ],
          [
              -115.07727,
              46.45304
          ],
          [
              -115.07711,
              46.453
          ],
          [
              -115.077,
              46.45298
          ],
          [
              -115.07657,
              46.45286
          ],
          [
              -115.07598,
              46.45265
          ],
          [
              -115.07552,
              46.45244
          ],
          [
              -115.07512,
              46.45223
          ],
          [
              -115.07322,
              46.4513
          ],
          [
              -115.073,
              46.45122
          ],
          [
              -115.07291,
              46.45118
          ],
          [
              -115.07281,
              46.45115
          ],
          [
              -115.07277,
              46.45113
          ],
          [
              -115.07263,
              46.45109
          ],
          [
              -115.07256,
              46.45106
          ],
          [
              -115.07241,
              46.45102
          ],
          [
              -115.07195,
              46.45092
          ],
          [
              -115.0715,
              46.45088
          ],
          [
              -115.07138,
              46.45088
          ],
          [
              -115.07127,
              46.45087
          ],
          [
              -115.0708,
              46.45087
          ],
          [
              -115.07031,
              46.45091
          ],
          [
              -115.07003,
              46.45097
          ],
          [
              -115.0697,
              46.45106
          ],
          [
              -115.06917,
              46.45124
          ],
          [
              -115.06877,
              46.45141
          ],
          [
              -115.06754,
              46.45201
          ],
          [
              -115.06748,
              46.45205
          ],
          [
              -115.0652,
              46.45317
          ],
          [
              -115.06509,
              46.45323
          ],
          [
              -115.06457,
              46.45348
          ],
          [
              -115.06331,
              46.45412
          ],
          [
              -115.0633,
              46.45413
          ],
          [
              -115.06263,
              46.45448
          ],
          [
              -115.06247,
              46.45458
          ],
          [
              -115.06212,
              46.45482
          ],
          [
              -115.06211,
              46.45483
          ],
          [
              -115.06186,
              46.45502
          ],
          [
              -115.06175,
              46.45512
          ],
          [
              -115.06128,
              46.4556
          ],
          [
              -115.05965,
              46.4575
          ],
          [
              -115.0596,
              46.45755
          ],
          [
              -115.0596,
              46.45756
          ],
          [
              -115.05952,
              46.45764
          ],
          [
              -115.05945,
              46.4577
          ],
          [
              -115.05935,
              46.4578
          ],
          [
              -115.05919,
              46.45794
          ],
          [
              -115.05912,
              46.45799
          ],
          [
              -115.059,
              46.45809
          ],
          [
              -115.05886,
              46.45817
          ],
          [
              -115.05877,
              46.45823
          ],
          [
              -115.0586,
              46.45833
          ],
          [
              -115.05844,
              46.4584
          ],
          [
              -115.05828,
              46.45848
          ],
          [
              -115.05795,
              46.4586
          ],
          [
              -115.05773,
              46.45867
          ],
          [
              -115.05752,
              46.45872
          ],
          [
              -115.05721,
              46.45878
          ],
          [
              -115.05684,
              46.45883
          ],
          [
              -115.05674,
              46.45883
          ],
          [
              -115.05666,
              46.45884
          ],
          [
              -115.05656,
              46.45884
          ],
          [
              -115.05646,
              46.45885
          ],
          [
              -115.05624,
              46.45885
          ],
          [
              -115.05597,
              46.45883
          ],
          [
              -115.05587,
              46.45883
          ],
          [
              -115.05535,
              46.45876
          ],
          [
              -115.05513,
              46.45872
          ],
          [
              -115.05507,
              46.45872
          ],
          [
              -115.05465,
              46.45865
          ],
          [
              -115.05444,
              46.45863
          ],
          [
              -115.05436,
              46.45863
          ],
          [
              -115.05426,
              46.45862
          ],
          [
              -115.05384,
              46.45862
          ],
          [
              -115.05355,
              46.45866
          ],
          [
              -115.05352,
              46.45866
          ],
          [
              -115.05329,
              46.45871
          ],
          [
              -115.05327,
              46.45871
          ],
          [
              -115.05317,
              46.45873
          ],
          [
              -115.05287,
              46.45882
          ],
          [
              -115.05261,
              46.45892
          ],
          [
              -115.05244,
              46.459
          ],
          [
              -115.05234,
              46.45906
          ],
          [
              -115.05227,
              46.45909
          ],
          [
              -115.05222,
              46.45913
          ],
          [
              -115.05211,
              46.45919
          ],
          [
              -115.05203,
              46.45925
          ],
          [
              -115.05199,
              46.45929
          ],
          [
              -115.05198,
              46.45929
          ],
          [
              -115.05191,
              46.45935
          ],
          [
              -115.05188,
              46.45937
          ],
          [
              -115.05178,
              46.45947
          ],
          [
              -115.05173,
              46.45951
          ],
          [
              -115.05167,
              46.45958
          ],
          [
              -115.05162,
              46.45963
          ],
          [
              -115.05128,
              46.46005
          ],
          [
              -115.05123,
              46.4601
          ],
          [
              -115.0509,
              46.46047
          ],
          [
              -115.05076,
              46.46061
          ],
          [
              -115.05065,
              46.4607
          ],
          [
              -115.05061,
              46.46074
          ],
          [
              -115.05056,
              46.46077
          ],
          [
              -115.05053,
              46.4608
          ],
          [
              -115.05048,
              46.46084
          ],
          [
              -115.05041,
              46.46088
          ],
          [
              -115.05029,
              46.46097
          ],
          [
              -115.05023,
              46.461
          ],
          [
              -115.05015,
              46.46106
          ],
          [
              -115.0501,
              46.46108
          ],
          [
              -115.05,
              46.46114
          ],
          [
              -115.04968,
              46.4613
          ],
          [
              -115.04944,
              46.4614
          ],
          [
              -115.04902,
              46.46154
          ],
          [
              -115.04896,
              46.46155
          ],
          [
              -115.04888,
              46.46158
          ],
          [
              -115.04844,
              46.46168
          ],
          [
              -115.04798,
              46.46174
          ],
          [
              -115.04792,
              46.46174
          ],
          [
              -115.04784,
              46.46175
          ],
          [
              -115.04777,
              46.46175
          ],
          [
              -115.04772,
              46.46176
          ],
          [
              -115.04712,
              46.46176
          ],
          [
              -115.04704,
              46.46175
          ],
          [
              -115.04698,
              46.46175
          ],
          [
              -115.04692,
              46.46174
          ],
          [
              -115.04685,
              46.46174
          ],
          [
              -115.04634,
              46.46166
          ],
          [
              -115.04628,
              46.46164
          ],
          [
              -115.04597,
              46.46157
          ],
          [
              -115.04563,
              46.46147
          ],
          [
              -115.04544,
              46.4614
          ],
          [
              -115.04533,
              46.46135
          ],
          [
              -115.04517,
              46.46129
          ],
          [
              -115.04512,
              46.46126
          ],
          [
              -115.04506,
              46.46124
          ],
          [
              -115.045,
              46.4612
          ],
          [
              -115.04492,
              46.46117
          ],
          [
              -115.0445,
              46.46091
          ],
          [
              -115.04423,
              46.46072
          ],
          [
              -115.04341,
              46.46006
          ],
          [
              -115.0431,
              46.45985
          ],
          [
              -115.04276,
              46.45964
          ],
          [
              -115.04239,
              46.45948
          ],
          [
              -115.04231,
              46.45944
          ],
          [
              -115.04224,
              46.45942
          ],
          [
              -115.04219,
              46.4594
          ],
          [
              -115.04206,
              46.45936
          ],
          [
              -115.04205,
              46.45935
          ],
          [
              -115.04199,
              46.45934
          ],
          [
              -115.0417,
              46.45926
          ],
          [
              -115.04147,
              46.45922
          ],
          [
              -115.0414,
              46.4592
          ],
          [
              -115.03998,
              46.45902
          ],
          [
              -115.03904,
              46.45887
          ],
          [
              -115.03818,
              46.45869
          ],
          [
              -115.03743,
              46.4585
          ],
          [
              -115.03614,
              46.45812
          ],
          [
              -115.03587,
              46.45806
          ],
          [
              -115.03501,
              46.45793
          ],
          [
              -115.03448,
              46.4579
          ],
          [
              -115.03402,
              46.4579
          ],
          [
              -115.03389,
              46.45791
          ],
          [
              -115.03378,
              46.45791
          ],
          [
              -115.02919,
              46.45826
          ],
          [
              -115.02904,
              46.45828
          ],
          [
              -115.02459,
              46.45864
          ],
          [
              -115.02445,
              46.45867
          ],
          [
              -115.02435,
              46.45868
          ],
          [
              -115.02426,
              46.4587
          ],
          [
              -115.0242,
              46.45872
          ],
          [
              -115.02413,
              46.45873
          ],
          [
              -115.02386,
              46.45881
          ],
          [
              -115.02384,
              46.45882
          ],
          [
              -115.02378,
              46.45884
          ],
          [
              -115.02363,
              46.45891
          ],
          [
              -115.02353,
              46.45895
          ],
          [
              -115.02334,
              46.45904
          ],
          [
              -115.02308,
              46.4592
          ],
          [
              -115.02285,
              46.45939
          ],
          [
              -115.0228,
              46.45945
          ],
          [
              -115.02275,
              46.45949
          ],
          [
              -115.0227,
              46.45954
          ],
          [
              -115.02193,
              46.46042
          ],
          [
              -115.02182,
              46.46057
          ],
          [
              -115.02173,
              46.46067
          ],
          [
              -115.02171,
              46.46071
          ],
          [
              -115.02163,
              46.46078
          ],
          [
              -115.02158,
              46.46084
          ],
          [
              -115.02152,
              46.4609
          ],
          [
              -115.0213,
              46.46109
          ],
          [
              -115.02118,
              46.46117
          ],
          [
              -115.02111,
              46.46121
          ],
          [
              -115.02104,
              46.46126
          ],
          [
              -115.02096,
              46.4613
          ],
          [
              -115.0209,
              46.46134
          ],
          [
              -115.02061,
              46.46147
          ],
          [
              -115.02056,
              46.46148
          ],
          [
              -115.02041,
              46.46154
          ],
          [
              -115.02033,
              46.46156
          ],
          [
              -115.02022,
              46.4616
          ],
          [
              -115.0198,
              46.4617
          ],
          [
              -115.01929,
              46.46177
          ],
          [
              -115.01913,
              46.46177
          ],
          [
              -115.0191,
              46.46178
          ],
          [
              -115.01871,
              46.46178
          ],
          [
              -115.01846,
              46.46176
          ],
          [
              -115.01716,
              46.4616
          ],
          [
              -115.01657,
              46.46157
          ],
          [
              -115.0164,
              46.46157
          ],
          [
              -115.01629,
              46.46158
          ],
          [
              -115.01619,
              46.46158
          ],
          [
              -115.01608,
              46.46159
          ],
          [
              -115.01599,
              46.46159
          ],
          [
              -115.01556,
              46.46165
          ],
          [
              -115.0155,
              46.46167
          ],
          [
              -115.0153,
              46.46172
          ],
          [
              -115.01503,
              46.46181
          ],
          [
              -115.01449,
              46.46202
          ],
          [
              -115.01411,
              46.4622
          ],
          [
              -115.01346,
              46.46253
          ],
          [
              -115.01342,
              46.46256
          ],
          [
              -115.01303,
              46.46276
          ],
          [
              -115.01285,
              46.46287
          ],
          [
              -115.01264,
              46.46296
          ],
          [
              -115.01222,
              46.4631
          ],
          [
              -115.0116,
              46.46327
          ],
          [
              -115.01147,
              46.46329
          ],
          [
              -115.01134,
              46.46332
          ],
          [
              -115.01105,
              46.46337
          ],
          [
              -115.0107,
              46.46341
          ],
          [
              -115.0102,
              46.46344
          ],
          [
              -115.00972,
              46.46344
          ],
          [
              -115.00955,
              46.46343
          ],
          [
              -115.00933,
              46.46343
          ],
          [
              -115.00929,
              46.46342
          ],
          [
              -115.00878,
              46.46338
          ],
          [
              -115.0082,
              46.46329
          ],
          [
              -115.00426,
              46.46248
          ],
          [
              -115.00388,
              46.46242
          ],
          [
              -115.00296,
              46.46233
          ],
          [
              -115.00227,
              46.46231
          ],
          [
              -115.00144,
              46.46232
          ],
          [
              -115.00097,
              46.46236
          ],
          [
              -115.0004,
              46.46245
          ],
          [
              -115.00015,
              46.4625
          ],
          [
              -115.00012,
              46.46251
          ],
          [
              -115.00001,
              46.46253
          ],
          [
              -114.99945,
              46.46267
          ],
          [
              -114.99938,
              46.4627
          ],
          [
              -114.99913,
              46.46277
          ],
          [
              -114.99874,
              46.4629
          ],
          [
              -114.99847,
              46.46301
          ],
          [
              -114.99822,
              46.4631
          ],
          [
              -114.99793,
              46.46324
          ],
          [
              -114.99783,
              46.46328
          ],
          [
              -114.99732,
              46.46355
          ],
          [
              -114.99731,
              46.46356
          ],
          [
              -114.99497,
              46.46488
          ],
          [
              -114.99458,
              46.46515
          ],
          [
              -114.99442,
              46.46528
          ],
          [
              -114.99423,
              46.46545
          ],
          [
              -114.99413,
              46.46555
          ],
          [
              -114.99407,
              46.46562
          ],
          [
              -114.99355,
              46.46615
          ],
          [
              -114.99349,
              46.4662
          ],
          [
              -114.99321,
              46.46647
          ],
          [
              -114.99299,
              46.46664
          ],
          [
              -114.99298,
              46.46665
          ],
          [
              -114.99289,
              46.46671
          ],
          [
              -114.99284,
              46.46676
          ],
          [
              -114.99281,
              46.46677
          ],
          [
              -114.99271,
              46.46684
          ],
          [
              -114.9926,
              46.4669
          ],
          [
              -114.99254,
              46.46694
          ],
          [
              -114.99246,
              46.46697
          ],
          [
              -114.99239,
              46.46701
          ],
          [
              -114.99231,
              46.46704
          ],
          [
              -114.99224,
              46.46708
          ],
          [
              -114.99217,
              46.4671
          ],
          [
              -114.99202,
              46.46716
          ],
          [
              -114.99165,
              46.46729
          ],
          [
              -114.99146,
              46.46733
          ],
          [
              -114.99141,
              46.46735
          ],
          [
              -114.99134,
              46.46736
          ],
          [
              -114.99126,
              46.46738
          ],
          [
              -114.99115,
              46.4674
          ],
          [
              -114.99083,
              46.46744
          ],
          [
              -114.99074,
              46.46744
          ],
          [
              -114.99049,
              46.46746
          ],
          [
              -114.99013,
              46.46746
          ],
          [
              -114.99003,
              46.46745
          ],
          [
              -114.9899,
              46.46745
          ],
          [
              -114.98982,
              46.46744
          ],
          [
              -114.98973,
              46.46744
          ],
          [
              -114.98952,
              46.46742
          ],
          [
              -114.9894,
              46.4674
          ],
          [
              -114.98928,
              46.46739
          ],
          [
              -114.98914,
              46.46737
          ],
          [
              -114.98886,
              46.46731
          ],
          [
              -114.98871,
              46.46729
          ],
          [
              -114.98846,
              46.46723
          ],
          [
              -114.98835,
              46.46721
          ],
          [
              -114.98812,
              46.46715
          ],
          [
              -114.98803,
              46.46712
          ],
          [
              -114.98066,
              46.46515
          ],
          [
              -114.98058,
              46.46514
          ],
          [
              -114.9805,
              46.46512
          ],
          [
              -114.98042,
              46.46511
          ],
          [
              -114.98032,
              46.46509
          ],
          [
              -114.98001,
              46.46506
          ],
          [
              -114.97971,
              46.46506
          ],
          [
              -114.97946,
              46.46508
          ],
          [
              -114.9794,
              46.46508
          ],
          [
              -114.97931,
              46.4651
          ],
          [
              -114.97927,
              46.4651
          ],
          [
              -114.97915,
              46.46513
          ],
          [
              -114.9791,
              46.46513
          ],
          [
              -114.97902,
              46.46515
          ],
          [
              -114.97897,
              46.46517
          ],
          [
              -114.97892,
              46.46518
          ],
          [
              -114.97881,
              46.46522
          ],
          [
              -114.97874,
              46.46524
          ],
          [
              -114.97839,
              46.4654
          ],
          [
              -114.97834,
              46.46543
          ],
          [
              -114.97827,
              46.46548
          ],
          [
              -114.97822,
              46.4655
          ],
          [
              -114.97816,
              46.46554
          ],
          [
              -114.97812,
              46.46558
          ],
          [
              -114.97803,
              46.46564
          ],
          [
              -114.97801,
              46.46567
          ],
          [
              -114.97792,
              46.46574
          ],
          [
              -114.97788,
              46.46579
          ],
          [
              -114.97787,
              46.46579
          ],
          [
              -114.97781,
              46.46586
          ],
          [
              -114.97769,
              46.46597
          ],
          [
              -114.97759,
              46.46608
          ],
          [
              -114.97745,
              46.46621
          ],
          [
              -114.9772,
              46.4664
          ],
          [
              -114.9771,
              46.46646
          ],
          [
              -114.977,
              46.46651
          ],
          [
              -114.9769,
              46.46657
          ],
          [
              -114.97646,
              46.46675
          ],
          [
              -114.97637,
              46.46677
          ],
          [
              -114.97629,
              46.4668
          ],
          [
              -114.97567,
              46.46698
          ],
          [
              -114.97425,
              46.46733
          ],
          [
              -114.97404,
              46.46739
          ],
          [
              -114.97395,
              46.46743
          ],
          [
              -114.97374,
              46.46751
          ],
          [
              -114.97351,
              46.46761
          ],
          [
              -114.97337,
              46.46769
          ],
          [
              -114.97325,
              46.46777
          ],
          [
              -114.97316,
              46.46782
          ],
          [
              -114.97296,
              46.46797
          ],
          [
              -114.97278,
              46.46813
          ],
          [
              -114.97271,
              46.4682
          ],
          [
              -114.97267,
              46.46826
          ],
          [
              -114.97263,
              46.46831
          ],
          [
              -114.97254,
              46.46844
          ],
          [
              -114.97245,
              46.46859
          ],
          [
              -114.97237,
              46.46878
          ],
          [
              -114.97232,
              46.46893
          ],
          [
              -114.97228,
              46.46908
          ],
          [
              -114.97223,
              46.46939
          ],
          [
              -114.97209,
              46.47057
          ],
          [
              -114.97202,
              46.471
          ],
          [
              -114.97202,
              46.47102
          ],
          [
              -114.97188,
              46.47143
          ],
          [
              -114.9718,
              46.4716
          ],
          [
              -114.97161,
              46.47194
          ],
          [
              -114.97154,
              46.47204
          ],
          [
              -114.9715,
              46.47211
          ],
          [
              -114.97141,
              46.47224
          ],
          [
              -114.97129,
              46.47238
          ],
          [
              -114.97128,
              46.47238
          ],
          [
              -114.97123,
              46.47245
          ],
          [
              -114.97108,
              46.47262
          ],
          [
              -114.97101,
              46.47269
          ],
          [
              -114.97096,
              46.47275
          ],
          [
              -114.97042,
              46.47333
          ],
          [
              -114.97033,
              46.47344
          ],
          [
              -114.96851,
              46.47541
          ],
          [
              -114.96844,
              46.47547
          ],
          [
              -114.96838,
              46.47554
          ],
          [
              -114.9683,
              46.47561
          ],
          [
              -114.9682,
              46.47571
          ],
          [
              -114.96811,
              46.47578
          ],
          [
              -114.96784,
              46.47596
          ],
          [
              -114.96783,
              46.47597
          ],
          [
              -114.96756,
              46.47614
          ],
          [
              -114.96707,
              46.47639
          ],
          [
              -114.96683,
              46.4765
          ],
          [
              -114.9668,
              46.47651
          ],
          [
              -114.96671,
              46.47655
          ],
          [
              -114.96656,
              46.4766
          ],
          [
              -114.96644,
              46.47665
          ],
          [
              -114.96637,
              46.47667
          ],
          [
              -114.96623,
              46.47672
          ],
          [
              -114.96601,
              46.47678
          ],
          [
              -114.96546,
              46.4769
          ],
          [
              -114.96485,
              46.47698
          ],
          [
              -114.96481,
              46.47698
          ],
          [
              -114.96429,
              46.47702
          ],
          [
              -114.96404,
              46.47702
          ],
          [
              -114.96384,
              46.47701
          ],
          [
              -114.96366,
              46.47701
          ],
          [
              -114.9634,
              46.47699
          ],
          [
              -114.9627,
              46.47689
          ],
          [
              -114.96237,
              46.47682
          ],
          [
              -114.96163,
              46.4766
          ],
          [
              -114.96133,
              46.47648
          ],
          [
              -114.96121,
              46.47642
          ],
          [
              -114.9612,
              46.47642
          ],
          [
              -114.96062,
              46.47612
          ],
          [
              -114.96044,
              46.47601
          ],
          [
              -114.96021,
              46.47585
          ],
          [
              -114.9602,
              46.47584
          ],
          [
              -114.96005,
              46.47573
          ],
          [
              -114.95977,
              46.47549
          ],
          [
              -114.95953,
              46.47525
          ],
          [
              -114.95945,
              46.47515
          ],
          [
              -114.95941,
              46.47509
          ],
          [
              -114.95844,
              46.47391
          ],
          [
              -114.95825,
              46.4737
          ],
          [
              -114.9579,
              46.47337
          ],
          [
              -114.95766,
              46.47319
          ],
          [
              -114.95758,
              46.47314
          ],
          [
              -114.95753,
              46.4731
          ],
          [
              -114.95725,
              46.47295
          ],
          [
              -114.95714,
              46.4729
          ],
          [
              -114.95703,
              46.47284
          ],
          [
              -114.95693,
              46.4728
          ],
          [
              -114.95646,
              46.47266
          ],
          [
              -114.95635,
              46.47264
          ],
          [
              -114.95628,
              46.47262
          ],
          [
              -114.95617,
              46.4726
          ],
          [
              -114.95606,
              46.47259
          ],
          [
              -114.95595,
              46.47257
          ],
          [
              -114.95584,
              46.47256
          ],
          [
              -114.95577,
              46.47256
          ],
          [
              -114.95555,
              46.47253
          ],
          [
              -114.95506,
              46.47253
          ],
          [
              -114.95414,
              46.47263
          ],
          [
              -114.95314,
              46.47278
          ],
          [
              -114.95241,
              46.47287
          ],
          [
              -114.95216,
              46.47289
          ],
          [
              -114.95209,
              46.47289
          ],
          [
              -114.95157,
              46.47293
          ],
          [
              -114.95112,
              46.47295
          ],
          [
              -114.94973,
              46.47296
          ],
          [
              -114.94962,
              46.47295
          ],
          [
              -114.94947,
              46.47295
          ],
          [
              -114.94892,
              46.4729
          ],
          [
              -114.94822,
              46.4728
          ],
          [
              -114.94718,
              46.47259
          ],
          [
              -114.94674,
              46.47248
          ],
          [
              -114.94498,
              46.47193
          ],
          [
              -114.94487,
              46.47189
          ],
          [
              -114.94463,
              46.47182
          ],
          [
              -114.94424,
              46.47169
          ],
          [
              -114.94413,
              46.47164
          ],
          [
              -114.94395,
              46.47157
          ],
          [
              -114.9437,
              46.47145
          ],
          [
              -114.94362,
              46.4714
          ],
          [
              -114.9435,
              46.47131
          ],
          [
              -114.94336,
              46.47119
          ],
          [
              -114.94329,
              46.47112
          ],
          [
              -114.94316,
              46.47096
          ],
          [
              -114.94309,
              46.47084
          ],
          [
              -114.94306,
              46.47076
          ],
          [
              -114.94304,
              46.47072
          ],
          [
              -114.94304,
              46.47069
          ],
          [
              -114.94302,
              46.47063
          ],
          [
              -114.943,
              46.47054
          ],
          [
              -114.94299,
              46.47042
          ],
          [
              -114.94299,
              46.47028
          ],
          [
              -114.94334,
              46.46649
          ],
          [
              -114.94336,
              46.46607
          ],
          [
              -114.94336,
              46.46595
          ],
          [
              -114.94334,
              46.46588
          ],
          [
              -114.94333,
              46.46581
          ],
          [
              -114.94327,
              46.46565
          ],
          [
              -114.94319,
              46.46548
          ],
          [
              -114.94299,
              46.46524
          ],
          [
              -114.94292,
              46.46517
          ],
          [
              -114.94263,
              46.46495
          ],
          [
              -114.94237,
              46.46481
          ],
          [
              -114.94188,
              46.46462
          ],
          [
              -114.94119,
              46.46441
          ],
          [
              -114.941,
              46.46436
          ],
          [
              -114.93884,
              46.46371
          ],
          [
              -114.93872,
              46.46369
          ],
          [
              -114.9386,
              46.46366
          ],
          [
              -114.93829,
              46.46361
          ],
          [
              -114.93795,
              46.46358
          ],
          [
              -114.93739,
              46.46358
          ],
          [
              -114.93649,
              46.46365
          ],
          [
              -114.93602,
              46.46365
          ],
          [
              -114.93572,
              46.46363
          ],
          [
              -114.93539,
              46.46359
          ],
          [
              -114.93489,
              46.4635
          ],
          [
              -114.93479,
              46.46349
          ],
          [
              -114.93457,
              46.46345
          ],
          [
              -114.93429,
              46.46342
          ],
          [
              -114.93362,
              46.46342
          ],
          [
              -114.93339,
              46.46346
          ],
          [
              -114.93316,
              46.46352
          ],
          [
              -114.933,
              46.46357
          ],
          [
              -114.93274,
              46.46367
          ],
          [
              -114.93154,
              46.4642
          ],
          [
              -114.93048,
              46.46472
          ],
          [
              -114.92965,
              46.46519
          ],
          [
              -114.92943,
              46.46536
          ],
          [
              -114.92925,
              46.46552
          ],
          [
              -114.92918,
              46.4656
          ],
          [
              -114.92905,
              46.46579
          ],
          [
              -114.92899,
              46.46589
          ],
          [
              -114.92889,
              46.46612
          ],
          [
              -114.92866,
              46.46703
          ],
          [
              -114.92864,
              46.46713
          ],
          [
              -114.92861,
              46.46721
          ],
          [
              -114.92858,
              46.46726
          ],
          [
              -114.92856,
              46.46731
          ],
          [
              -114.92849,
              46.46742
          ],
          [
              -114.92844,
              46.46748
          ],
          [
              -114.92837,
              46.46755
          ],
          [
              -114.92823,
              46.46767
          ],
          [
              -114.92815,
              46.46772
          ],
          [
              -114.9281,
              46.46776
          ],
          [
              -114.92795,
              46.46784
          ],
          [
              -114.92778,
              46.4679
          ],
          [
              -114.92769,
              46.46794
          ],
          [
              -114.92751,
              46.46799
          ],
          [
              -114.92729,
              46.46803
          ],
          [
              -114.92714,
              46.46805
          ],
          [
              -114.92679,
              46.46808
          ],
          [
              -114.92616,
              46.46808
          ],
          [
              -114.92601,
              46.46807
          ],
          [
              -114.92586,
              46.46807
          ],
          [
              -114.92575,
              46.46808
          ],
          [
              -114.92563,
              46.46808
          ],
          [
              -114.92552,
              46.46809
          ],
          [
              -114.92531,
              46.46813
          ],
          [
              -114.92513,
              46.46818
          ],
          [
              -114.92505,
              46.46821
          ],
          [
              -114.92501,
              46.46824
          ],
          [
              -114.92493,
              46.46827
          ],
          [
              -114.92482,
              46.46833
          ],
          [
              -114.92458,
              46.46849
          ],
          [
              -114.92444,
              46.4686
          ],
          [
              -114.92436,
              46.46868
          ],
          [
              -114.92427,
              46.46879
          ],
          [
              -114.92421,
              46.4689
          ],
          [
              -114.92419,
              46.46895
          ],
          [
              -114.92417,
              46.46903
          ],
          [
              -114.92415,
              46.46909
          ],
          [
              -114.92412,
              46.46931
          ],
          [
              -114.92412,
              46.46941
          ],
          [
              -114.92413,
              46.46953
          ],
          [
              -114.92413,
              46.46962
          ],
          [
              -114.92414,
              46.4697
          ],
          [
              -114.92418,
              46.46985
          ],
          [
              -114.9242,
              46.46995
          ],
          [
              -114.92426,
              46.47011
          ],
          [
              -114.92432,
              46.4703
          ],
          [
              -114.92437,
              46.47043
          ],
          [
              -114.92441,
              46.47061
          ],
          [
              -114.92443,
              46.47074
          ],
          [
              -114.92443,
              46.4709
          ],
          [
              -114.92441,
              46.47107
          ],
          [
              -114.92441,
              46.47115
          ],
          [
              -114.92433,
              46.47148
          ],
          [
              -114.9243,
              46.47156
          ],
          [
              -114.92429,
              46.47161
          ],
          [
              -114.92421,
              46.47183
          ],
          [
              -114.92415,
              46.47195
          ],
          [
              -114.92413,
              46.472
          ],
          [
              -114.92402,
              46.47219
          ],
          [
              -114.924,
              46.47221
          ],
          [
              -114.92392,
              46.47233
          ],
          [
              -114.92376,
              46.47253
          ],
          [
              -114.92363,
              46.47265
          ],
          [
              -114.92363,
              46.47266
          ],
          [
              -114.92348,
              46.4728
          ],
          [
              -114.92343,
              46.47284
          ],
          [
              -114.92338,
              46.47289
          ],
          [
              -114.92331,
              46.47294
          ],
          [
              -114.9233,
              46.47295
          ],
          [
              -114.92314,
              46.47307
          ],
          [
              -114.92309,
              46.4731
          ],
          [
              -114.92239,
              46.47358
          ],
          [
              -114.92238,
              46.47358
          ],
          [
              -114.91969,
              46.47527
          ],
          [
              -114.9195,
              46.4754
          ],
          [
              -114.91893,
              46.47576
          ],
          [
              -114.91874,
              46.47589
          ],
          [
              -114.91865,
              46.47594
          ],
          [
              -114.9152,
              46.47815
          ],
          [
              -114.91509,
              46.47821
          ],
          [
              -114.91508,
              46.47822
          ],
          [
              -114.91488,
              46.47831
          ],
          [
              -114.91465,
              46.4784
          ],
          [
              -114.9146,
              46.47841
          ],
          [
              -114.91449,
              46.47845
          ],
          [
              -114.91411,
              46.47854
          ],
          [
              -114.91401,
              46.47855
          ],
          [
              -114.91389,
              46.47857
          ],
          [
              -114.91365,
              46.47859
          ],
          [
              -114.91361,
              46.4786
          ],
          [
              -114.91322,
              46.4786
          ],
          [
              -114.91295,
              46.47858
          ],
          [
              -114.91269,
              46.47854
          ],
          [
              -114.91241,
              46.47848
          ],
          [
              -114.9121,
              46.47839
          ],
          [
              -114.91204,
              46.47836
          ],
          [
              -114.91192,
              46.47832
          ],
          [
              -114.91061,
              46.47776
          ],
          [
              -114.90957,
              46.47726
          ],
          [
              -114.90954,
              46.47725
          ],
          [
              -114.90653,
              46.47587
          ],
          [
              -114.90652,
              46.47586
          ],
          [
              -114.90565,
              46.47548
          ],
          [
              -114.90462,
              46.47508
          ],
          [
              -114.90428,
              46.47497
          ],
          [
              -114.90422,
              46.47496
          ],
          [
              -114.90417,
              46.47494
          ],
          [
              -114.90387,
              46.47486
          ],
          [
              -114.90351,
              46.47479
          ],
          [
              -114.90343,
              46.47478
          ],
          [
              -114.90334,
              46.47476
          ],
          [
              -114.90286,
              46.47472
          ],
          [
              -114.90203,
              46.47469
          ],
          [
              -114.90136,
              46.47469
          ],
          [
              -114.90098,
              46.47473
          ],
          [
              -114.9008,
              46.47476
          ],
          [
              -114.90063,
              46.47478
          ],
          [
              -114.90011,
              46.47488
          ],
          [
              -114.8994,
              46.47505
          ],
          [
              -114.89889,
              46.47515
          ],
          [
              -114.8985,
              46.47519
          ],
          [
              -114.89802,
              46.47519
          ],
          [
              -114.89791,
              46.47518
          ],
          [
              -114.89764,
              46.47513
          ],
          [
              -114.89723,
              46.47502
          ],
          [
              -114.89569,
              46.47453
          ],
          [
              -114.89532,
              46.4744
          ],
          [
              -114.895,
              46.47427
          ],
          [
              -114.8948,
              46.47416
          ],
          [
              -114.89461,
              46.47402
          ],
          [
              -114.89452,
              46.47394
          ],
          [
              -114.89438,
              46.4738
          ],
          [
              -114.89431,
              46.47369
          ],
          [
              -114.89399,
              46.47305
          ],
          [
              -114.89397,
              46.47299
          ],
          [
              -114.89381,
              46.47274
          ],
          [
              -114.89376,
              46.47268
          ],
          [
              -114.89371,
              46.47263
          ],
          [
              -114.89356,
              46.4725
          ],
          [
              -114.89333,
              46.47235
          ],
          [
              -114.89327,
              46.47232
          ],
          [
              -114.89326,
              46.47232
          ],
          [
              -114.8932,
              46.47228
          ],
          [
              -114.89303,
              46.47221
          ],
          [
              -114.89282,
              46.47214
          ],
          [
              -114.89271,
              46.47212
          ],
          [
              -114.8926,
              46.47209
          ],
          [
              -114.8925,
              46.47207
          ],
          [
              -114.89235,
              46.47205
          ],
          [
              -114.89228,
              46.47205
          ],
          [
              -114.89221,
              46.47204
          ],
          [
              -114.89192,
              46.47204
          ],
          [
              -114.8915,
              46.47212
          ],
          [
              -114.89124,
              46.4722
          ],
          [
              -114.89088,
              46.47239
          ],
          [
              -114.89077,
              46.47247
          ],
          [
              -114.89031,
              46.47285
          ],
          [
              -114.88999,
              46.47313
          ],
          [
              -114.88984,
              46.47325
          ],
          [
              -114.88956,
              46.47344
          ],
          [
              -114.88923,
              46.47361
          ],
          [
              -114.88899,
              46.4737
          ],
          [
              -114.88844,
              46.47383
          ],
          [
              -114.88833,
              46.47384
          ],
          [
              -114.88829,
              46.47385
          ],
          [
              -114.88767,
              46.4739
          ],
          [
              -114.88742,
              46.47391
          ],
          [
              -114.88709,
              46.47394
          ],
          [
              -114.88695,
              46.47396
          ],
          [
              -114.88654,
              46.47406
          ],
          [
              -114.88639,
              46.47412
          ],
          [
              -114.88627,
              46.47416
          ],
          [
              -114.88607,
              46.47424
          ],
          [
              -114.88579,
              46.47442
          ],
          [
              -114.88569,
              46.4745
          ],
          [
              -114.8855,
              46.47469
          ],
          [
              -114.88535,
              46.47488
          ],
          [
              -114.88488,
              46.47561
          ],
          [
              -114.88474,
              46.47581
          ],
          [
              -114.88422,
              46.47662
          ],
          [
              -114.88403,
              46.47684
          ],
          [
              -114.88397,
              46.4769
          ],
          [
              -114.88393,
              46.47693
          ],
          [
              -114.88387,
              46.47699
          ],
          [
              -114.88355,
              46.47722
          ],
          [
              -114.88346,
              46.47727
          ],
          [
              -114.88329,
              46.47735
          ],
          [
              -114.88293,
              46.4775
          ],
          [
              -114.88282,
              46.47753
          ],
          [
              -114.88271,
              46.47757
          ],
          [
              -114.8826,
              46.47759
          ],
          [
              -114.88249,
              46.47762
          ],
          [
              -114.88227,
              46.47766
          ],
          [
              -114.88194,
              46.4777
          ],
          [
              -114.88184,
              46.4777
          ],
          [
              -114.88167,
              46.47771
          ],
          [
              -114.88127,
              46.4777
          ],
          [
              -114.88104,
              46.47768
          ],
          [
              -114.88059,
              46.4776
          ],
          [
              -114.88044,
              46.47755
          ],
          [
              -114.87674,
              46.47651
          ],
          [
              -114.87665,
              46.4765
          ],
          [
              -114.87633,
              46.47644
          ],
          [
              -114.87622,
              46.47643
          ],
          [
              -114.8761,
              46.47643
          ],
          [
              -114.87597,
              46.47642
          ],
          [
              -114.87585,
              46.47642
          ],
          [
              -114.87552,
              46.47645
          ],
          [
              -114.87528,
              46.4765
          ],
          [
              -114.87497,
              46.4766
          ],
          [
              -114.8748,
              46.47667
          ],
          [
              -114.8747,
              46.47672
          ],
          [
              -114.8746,
              46.47678
          ],
          [
              -114.8745,
              46.47686
          ],
          [
              -114.87444,
              46.4769
          ],
          [
              -114.87422,
              46.47715
          ],
          [
              -114.87416,
              46.47724
          ],
          [
              -114.87412,
              46.47731
          ],
          [
              -114.87405,
              46.47754
          ],
          [
              -114.87404,
              46.4776
          ],
          [
              -114.87404,
              46.47777
          ],
          [
              -114.87407,
              46.47793
          ],
          [
              -114.87411,
              46.47807
          ],
          [
              -114.87415,
              46.47818
          ],
          [
              -114.8742,
              46.47826
          ],
          [
              -114.87437,
              46.47848
          ],
          [
              -114.87482,
              46.47891
          ],
          [
              -114.87573,
              46.47967
          ],
          [
              -114.87664,
              46.48038
          ],
          [
              -114.87699,
              46.48069
          ],
          [
              -114.87722,
              46.48094
          ],
          [
              -114.87732,
              46.48106
          ],
          [
              -114.87752,
              46.48139
          ],
          [
              -114.87761,
              46.48156
          ],
          [
              -114.8777,
              46.48179
          ],
          [
              -114.87777,
              46.48209
          ],
          [
              -114.8778,
              46.48225
          ],
          [
              -114.87783,
              46.48237
          ],
          [
              -114.87784,
              46.48245
          ],
          [
              -114.87786,
              46.48255
          ],
          [
              -114.8779,
              46.48291
          ],
          [
              -114.8779,
              46.48338
          ],
          [
              -114.87788,
              46.48351
          ],
          [
              -114.87785,
              46.48362
          ],
          [
              -114.87778,
              46.48383
          ],
          [
              -114.87771,
              46.48399
          ],
          [
              -114.87761,
              46.48414
          ],
          [
              -114.87758,
              46.48417
          ],
          [
              -114.87749,
              46.48429
          ],
          [
              -114.87748,
              46.48429
          ],
          [
              -114.87738,
              46.4844
          ],
          [
              -114.87714,
              46.4846
          ],
          [
              -114.87689,
              46.48475
          ],
          [
              -114.87678,
              46.4848
          ],
          [
              -114.87665,
              46.48487
          ],
          [
              -114.87648,
              46.48494
          ],
          [
              -114.87618,
              46.48504
          ],
          [
              -114.87601,
              46.48508
          ],
          [
              -114.87564,
              46.48515
          ],
          [
              -114.86854,
              46.48605
          ],
          [
              -114.86837,
              46.48608
          ],
          [
              -114.86824,
              46.48611
          ],
          [
              -114.86812,
              46.48615
          ],
          [
              -114.86797,
              46.48619
          ],
          [
              -114.86779,
              46.48626
          ],
          [
              -114.86761,
              46.48634
          ],
          [
              -114.86729,
              46.4865
          ],
          [
              -114.86726,
              46.48653
          ],
          [
              -114.86721,
              46.48656
          ],
          [
              -114.8671,
              46.48664
          ],
          [
              -114.86708,
              46.48666
          ],
          [
              -114.86692,
              46.48679
          ],
          [
              -114.86676,
              46.48695
          ],
          [
              -114.86669,
              46.48703
          ],
          [
              -114.86659,
              46.48718
          ],
          [
              -114.86648,
              46.4874
          ],
          [
              -114.86642,
              46.48756
          ],
          [
              -114.86638,
              46.48769
          ],
          [
              -114.86626,
              46.48819
          ],
          [
              -114.86624,
              46.48825
          ],
          [
              -114.86617,
              46.48842
          ],
          [
              -114.86612,
              46.48849
          ],
          [
              -114.86608,
              46.48856
          ],
          [
              -114.86596,
              46.48872
          ],
          [
              -114.86589,
              46.48879
          ],
          [
              -114.86582,
              46.48887
          ],
          [
              -114.86563,
              46.48903
          ],
          [
              -114.86555,
              46.48909
          ],
          [
              -114.86548,
              46.48913
          ],
          [
              -114.86541,
              46.48918
          ],
          [
              -114.86518,
              46.4893
          ],
          [
              -114.86515,
              46.48931
          ],
          [
              -114.86491,
              46.48942
          ],
          [
              -114.8645,
              46.48957
          ],
          [
              -114.86421,
              46.48966
          ],
          [
              -114.8613,
              46.49071
          ],
          [
              -114.86123,
              46.49073
          ],
          [
              -114.86122,
              46.49074
          ],
          [
              -114.86114,
              46.49076
          ],
          [
              -114.861,
              46.49083
          ],
          [
              -114.86099,
              46.49083
          ],
          [
              -114.86048,
              46.49108
          ],
          [
              -114.8595,
              46.49165
          ],
          [
              -114.85941,
              46.49171
          ],
          [
              -114.85937,
              46.49173
          ],
          [
              -114.85768,
              46.49297
          ],
          [
              -114.85756,
              46.49304
          ],
          [
              -114.85747,
              46.4931
          ],
          [
              -114.85705,
              46.4933
          ],
          [
              -114.85636,
              46.49356
          ],
          [
              -114.85554,
              46.4938
          ],
          [
              -114.85466,
              46.49402
          ],
          [
              -114.85329,
              46.4944
          ],
          [
              -114.85253,
              46.49465
          ],
          [
              -114.85201,
              46.49486
          ],
          [
              -114.85197,
              46.49487
          ],
          [
              -114.85186,
              46.49491
          ],
          [
              -114.8514,
              46.49511
          ],
          [
              -114.84946,
              46.49601
          ],
          [
              -114.84921,
              46.49611
          ],
          [
              -114.84901,
              46.49617
          ],
          [
              -114.84891,
              46.49621
          ],
          [
              -114.84888,
              46.49621
          ],
          [
              -114.84879,
              46.49624
          ],
          [
              -114.84847,
              46.49632
          ],
          [
              -114.84832,
              46.49635
          ],
          [
              -114.84815,
              46.49637
          ],
          [
              -114.84805,
              46.49639
          ],
          [
              -114.84783,
              46.49641
          ],
          [
              -114.84721,
              46.49641
          ],
          [
              -114.8471,
              46.4964
          ],
          [
              -114.84707,
              46.4964
          ],
          [
              -114.84659,
              46.49633
          ],
          [
              -114.84556,
              46.4961
          ],
          [
              -114.84545,
              46.49607
          ],
          [
              -114.8451,
              46.496
          ],
          [
              -114.84461,
              46.49594
          ],
          [
              -114.84447,
              46.49593
          ],
          [
              -114.84384,
              46.49593
          ],
          [
              -114.8434,
              46.49596
          ],
          [
              -114.84309,
              46.49601
          ],
          [
              -114.84295,
              46.49604
          ],
          [
              -114.84272,
              46.49611
          ],
          [
              -114.84255,
              46.49617
          ],
          [
              -114.8425,
              46.49618
          ],
          [
              -114.84213,
              46.49633
          ],
          [
              -114.84186,
              46.49647
          ],
          [
              -114.8417,
              46.49657
          ],
          [
              -114.84169,
              46.49657
          ],
          [
              -114.84124,
              46.49687
          ],
          [
              -114.84044,
              46.49757
          ],
          [
              -114.84014,
              46.49779
          ],
          [
              -114.83987,
              46.49794
          ],
          [
              -114.83962,
              46.49806
          ],
          [
              -114.83911,
              46.49826
          ],
          [
              -114.83718,
              46.49882
          ],
          [
              -114.83663,
              46.49896
          ],
          [
              -114.83547,
              46.49921
          ],
          [
              -114.83315,
              46.49965
          ],
          [
              -114.83312,
              46.49966
          ],
          [
              -114.83296,
              46.49969
          ],
          [
              -114.83279,
              46.49974
          ],
          [
              -114.83265,
              46.49977
          ],
          [
              -114.83227,
              46.49989
          ],
          [
              -114.832,
              46.5
          ],
          [
              -114.83181,
              46.50009
          ],
          [
              -114.83152,
              46.50028
          ],
          [
              -114.8309,
              46.50084
          ],
          [
              -114.83081,
              46.50093
          ],
          [
              -114.8306,
              46.50112
          ],
          [
              -114.83047,
              46.50121
          ],
          [
              -114.83034,
              46.50131
          ],
          [
              -114.83022,
              46.50139
          ],
          [
              -114.83008,
              46.50147
          ],
          [
              -114.82981,
              46.5016
          ],
          [
              -114.82979,
              46.5016
          ],
          [
              -114.8297,
              46.50165
          ],
          [
              -114.82921,
              46.50181
          ],
          [
              -114.8285,
              46.50199
          ],
          [
              -114.82814,
              46.5021
          ],
          [
              -114.82811,
              46.50212
          ],
          [
              -114.82805,
              46.50214
          ],
          [
              -114.82781,
              46.50225
          ],
          [
              -114.82776,
              46.50228
          ],
          [
              -114.82766,
              46.50233
          ],
          [
              -114.82755,
              46.5024
          ],
          [
              -114.82749,
              46.50243
          ],
          [
              -114.82745,
              46.50246
          ],
          [
              -114.8274,
              46.50249
          ],
          [
              -114.82732,
              46.50255
          ],
          [
              -114.82718,
              46.50267
          ],
          [
              -114.8269,
              46.50297
          ],
          [
              -114.82552,
              46.50437
          ],
          [
              -114.82515,
              46.50471
          ],
          [
              -114.82508,
              46.50476
          ],
          [
              -114.82505,
              46.50479
          ],
          [
              -114.82488,
              46.50489
          ],
          [
              -114.82449,
              46.50507
          ],
          [
              -114.82438,
              46.5051
          ],
          [
              -114.82428,
              46.50514
          ],
          [
              -114.8241,
              46.50518
          ],
          [
              -114.82403,
              46.50519
          ],
          [
              -114.82392,
              46.50522
          ],
          [
              -114.82386,
              46.50522
          ],
          [
              -114.82373,
              46.50524
          ],
          [
              -114.82357,
              46.50524
          ],
          [
              -114.82348,
              46.50525
          ],
          [
              -114.82329,
              46.50525
          ],
          [
              -114.82299,
              46.50522
          ],
          [
              -114.82275,
              46.50518
          ],
          [
              -114.82264,
              46.50514
          ],
          [
              -114.82253,
              46.50511
          ],
          [
              -114.8223,
              46.50502
          ],
          [
              -114.82189,
              46.50483
          ],
          [
              -114.82028,
              46.50403
          ],
          [
              -114.81986,
              46.5038
          ],
          [
              -114.81975,
              46.50373
          ],
          [
              -114.81951,
              46.50355
          ],
          [
              -114.81872,
              46.5029
          ],
          [
              -114.81844,
              46.50269
          ],
          [
              -114.81811,
              46.50248
          ],
          [
              -114.81803,
              46.50244
          ],
          [
              -114.81797,
              46.5024
          ],
          [
              -114.81787,
              46.50236
          ],
          [
              -114.81775,
              46.5023
          ],
          [
              -114.81741,
              46.50218
          ],
          [
              -114.81727,
              46.50214
          ],
          [
              -114.81686,
              46.50205
          ],
          [
              -114.81683,
              46.50205
          ],
          [
              -114.81678,
              46.50204
          ],
          [
              -114.81659,
              46.50202
          ],
          [
              -114.81617,
              46.50202
          ],
          [
              -114.81475,
              46.50214
          ],
          [
              -114.81405,
              46.50222
          ],
          [
              -114.81394,
              46.50224
          ],
          [
              -114.81388,
              46.50224
          ],
          [
              -114.81382,
              46.50226
          ],
          [
              -114.81375,
              46.50227
          ],
          [
              -114.81352,
              46.50233
          ],
          [
              -114.81318,
              46.50245
          ],
          [
              -114.81298,
              46.50254
          ],
          [
              -114.81276,
              46.50267
          ],
          [
              -114.81249,
              46.5029
          ],
          [
              -114.81236,
              46.50306
          ],
          [
              -114.81226,
              46.50321
          ],
          [
              -114.8122,
              46.50329
          ],
          [
              -114.81216,
              46.50337
          ],
          [
              -114.81213,
              46.50345
          ],
          [
              -114.8121,
              46.50355
          ],
          [
              -114.81206,
              46.50372
          ],
          [
              -114.81194,
              46.50446
          ],
          [
              -114.81193,
              46.50448
          ],
          [
              -114.81191,
              46.50463
          ],
          [
              -114.81185,
              46.50481
          ],
          [
              -114.81173,
              46.50504
          ],
          [
              -114.81163,
              46.50518
          ],
          [
              -114.81158,
              46.50524
          ],
          [
              -114.81143,
              46.50537
          ],
          [
              -114.81124,
              46.50549
          ],
          [
              -114.81098,
              46.5056
          ],
          [
              -114.81082,
              46.50565
          ],
          [
              -114.81075,
              46.50566
          ],
          [
              -114.81067,
              46.50568
          ],
          [
              -114.8105,
              46.5057
          ],
          [
              -114.81041,
              46.5057
          ],
          [
              -114.81031,
              46.50571
          ],
          [
              -114.81021,
              46.50571
          ],
          [
              -114.8101,
              46.5057
          ],
          [
              -114.81002,
              46.5057
          ],
          [
              -114.80993,
              46.50569
          ],
          [
              -114.80983,
              46.50567
          ],
          [
              -114.80973,
              46.50566
          ],
          [
              -114.80963,
              46.50563
          ],
          [
              -114.80948,
              46.5056
          ],
          [
              -114.80866,
              46.50537
          ],
          [
              -114.80734,
              46.50496
          ],
          [
              -114.80718,
              46.5049
          ],
          [
              -114.80711,
              46.50488
          ],
          [
              -114.80691,
              46.50479
          ],
          [
              -114.80683,
              46.50476
          ],
          [
              -114.8067,
              46.5047
          ],
          [
              -114.80664,
              46.50466
          ],
          [
              -114.80556,
              46.50403
          ],
          [
              -114.80536,
              46.50394
          ],
          [
              -114.80535,
              46.50394
          ],
          [
              -114.80529,
              46.50392
          ],
          [
              -114.80522,
              46.50389
          ],
          [
              -114.80512,
              46.50387
          ],
          [
              -114.8051,
              46.50386
          ],
          [
              -114.80494,
              46.50383
          ],
          [
              -114.80489,
              46.50383
          ],
          [
              -114.80482,
              46.50382
          ],
          [
              -114.80467,
              46.50382
          ],
          [
              -114.80459,
              46.50383
          ],
          [
              -114.80452,
              46.50383
          ],
          [
              -114.80406,
              46.5039
          ],
          [
              -114.80342,
              46.50407
          ],
          [
              -114.80326,
              46.50412
          ],
          [
              -114.80273,
              46.50424
          ],
          [
              -114.79914,
              46.50478
          ],
          [
              -114.799,
              46.50481
          ],
          [
              -114.7987,
              46.5049
          ],
          [
              -114.79864,
              46.50493
          ],
          [
              -114.79849,
              46.50499
          ],
          [
              -114.79829,
              46.50511
          ],
          [
              -114.79815,
              46.50522
          ],
          [
              -114.79809,
              46.50528
          ],
          [
              -114.7979,
              46.50555
          ],
          [
              -114.7977,
              46.50599
          ],
          [
              -114.79767,
              46.50604
          ],
          [
              -114.79766,
              46.50607
          ],
          [
              -114.79763,
              46.50613
          ],
          [
              -114.79759,
              46.50618
          ],
          [
              -114.79759,
              46.50619
          ],
          [
              -114.79755,
              46.50624
          ],
          [
              -114.79751,
              46.50628
          ],
          [
              -114.79746,
              46.50634
          ],
          [
              -114.79745,
              46.50636
          ],
          [
              -114.79722,
              46.50656
          ],
          [
              -114.79713,
              46.50663
          ],
          [
              -114.79704,
              46.50669
          ],
          [
              -114.79604,
              46.50725
          ],
          [
              -114.79576,
              46.50744
          ],
          [
              -114.79573,
              46.50747
          ],
          [
              -114.79566,
              46.50752
          ],
          [
              -114.79557,
              46.50761
          ],
          [
              -114.79553,
              46.50767
          ],
          [
              -114.7954,
              46.5079
          ],
          [
              -114.79538,
              46.50796
          ],
          [
              -114.79535,
              46.50803
          ],
          [
              -114.79512,
              46.509
          ],
          [
              -114.79501,
              46.50933
          ],
          [
              -114.7949,
              46.5095
          ],
          [
              -114.79484,
              46.50958
          ],
          [
              -114.79478,
              46.50965
          ],
          [
              -114.79473,
              46.5097
          ],
          [
              -114.79467,
              46.50977
          ],
          [
              -114.79451,
              46.50991
          ],
          [
              -114.79442,
              46.50998
          ],
          [
              -114.79423,
              46.5101
          ],
          [
              -114.79406,
              46.51019
          ],
          [
              -114.79397,
              46.51022
          ],
          [
              -114.79376,
              46.51031
          ],
          [
              -114.79345,
              46.51039
          ],
          [
              -114.79289,
              46.51048
          ],
          [
              -114.79204,
              46.51055
          ],
          [
              -114.78492,
              46.51093
          ],
          [
              -114.78439,
              46.51093
          ],
          [
              -114.78357,
              46.51086
          ],
          [
              -114.78327,
              46.51082
          ],
          [
              -114.78325,
              46.51082
          ],
          [
              -114.7825,
              46.51069
          ],
          [
              -114.78173,
              46.51049
          ],
          [
              -114.78127,
              46.51034
          ],
          [
              -114.781,
              46.51024
          ],
          [
              -114.78021,
              46.50987
          ],
          [
              -114.77962,
              46.50952
          ],
          [
              -114.77903,
              46.50913
          ],
          [
              -114.77811,
              46.50845
          ],
          [
              -114.77739,
              46.50797
          ],
          [
              -114.77721,
              46.50787
          ],
          [
              -114.77708,
              46.50781
          ],
          [
              -114.77695,
              46.50774
          ],
          [
              -114.77675,
              46.50765
          ],
          [
              -114.77659,
              46.50759
          ],
          [
              -114.7765,
              46.50755
          ],
          [
              -114.77607,
              46.50742
          ],
          [
              -114.77551,
              46.5073
          ],
          [
              -114.77541,
              46.50729
          ],
          [
              -114.77531,
              46.50727
          ],
          [
              -114.77511,
              46.50725
          ],
          [
              -114.77502,
              46.50725
          ],
          [
              -114.77494,
              46.50724
          ],
          [
              -114.77483,
              46.50724
          ],
          [
              -114.77474,
              46.50723
          ],
          [
              -114.7741,
              46.50723
          ],
          [
              -114.77385,
              46.50725
          ],
          [
              -114.7737,
              46.50727
          ],
          [
              -114.77268,
              46.5075
          ],
          [
              -114.77245,
              46.50757
          ],
          [
              -114.77137,
              46.50795
          ],
          [
              -114.7706,
              46.50824
          ],
          [
              -114.76987,
              46.50849
          ],
          [
              -114.76943,
              46.50861
          ],
          [
              -114.76924,
              46.50864
          ],
          [
              -114.76902,
              46.50869
          ],
          [
              -114.76817,
              46.50883
          ],
          [
              -114.76816,
              46.50883
          ],
          [
              -114.76559,
              46.50922
          ],
          [
              -114.76531,
              46.50928
          ],
          [
              -114.76486,
              46.50941
          ],
          [
              -114.76464,
              46.50949
          ],
          [
              -114.76407,
              46.50973
          ],
          [
              -114.76358,
              46.50997
          ],
          [
              -114.76356,
              46.50999
          ],
          [
              -114.7634,
              46.51008
          ],
          [
              -114.7631,
              46.51028
          ],
          [
              -114.76271,
              46.51057
          ],
          [
              -114.76265,
              46.51063
          ],
          [
              -114.76247,
              46.51079
          ],
          [
              -114.76222,
              46.51106
          ],
          [
              -114.76192,
              46.51151
          ],
          [
              -114.76183,
              46.51167
          ],
          [
              -114.76133,
              46.51245
          ],
          [
              -114.76035,
              46.51406
          ],
          [
              -114.76013,
              46.51436
          ],
          [
              -114.76002,
              46.51449
          ],
          [
              -114.75984,
              46.51468
          ],
          [
              -114.7595,
              46.51501
          ],
          [
              -114.75927,
              46.5152
          ],
          [
              -114.75898,
              46.5154
          ],
          [
              -114.75876,
              46.51554
          ],
          [
              -114.7586,
              46.51563
          ],
          [
              -114.75856,
              46.51566
          ],
          [
              -114.75807,
              46.51592
          ],
          [
              -114.75745,
              46.51618
          ],
          [
              -114.75724,
              46.51625
          ],
          [
              -114.75675,
              46.51639
          ],
          [
              -114.75654,
              46.51644
          ],
          [
              -114.75587,
              46.51656
          ],
          [
              -114.7555,
              46.5166
          ],
          [
              -114.75533,
              46.51663
          ],
          [
              -114.75513,
              46.51664
          ],
          [
              -114.75498,
              46.51664
          ],
          [
              -114.75491,
              46.51665
          ],
          [
              -114.75472,
              46.51665
          ],
          [
              -114.7545,
              46.51666
          ],
          [
              -114.75413,
              46.51666
          ],
          [
              -114.74631,
              46.51647
          ],
          [
              -114.74528,
              46.51646
          ],
          [
              -114.74443,
              46.51651
          ],
          [
              -114.74334,
              46.51665
          ],
          [
              -114.74285,
              46.51674
          ],
          [
              -114.74204,
              46.51693
          ],
          [
              -114.74097,
              46.51722
          ],
          [
              -114.7402,
              46.51738
          ],
          [
              -114.73919,
              46.5175
          ],
          [
              -114.7387,
              46.51753
          ],
          [
              -114.73816,
              46.51753
          ],
          [
              -114.73796,
              46.51752
          ],
          [
              -114.73776,
              46.51752
          ],
          [
              -114.73757,
              46.51751
          ],
          [
              -114.73742,
              46.51749
          ],
          [
              -114.73723,
              46.51748
          ],
          [
              -114.73703,
              46.51746
          ],
          [
              -114.73693,
              46.51744
          ],
          [
              -114.73685,
              46.51743
          ],
          [
              -114.73644,
              46.51734
          ],
          [
              -114.7361,
              46.51722
          ],
          [
              -114.73585,
              46.5171
          ],
          [
              -114.73573,
              46.51703
          ],
          [
              -114.73558,
              46.51693
          ],
          [
              -114.73548,
              46.51684
          ],
          [
              -114.73542,
              46.51677
          ],
          [
              -114.73535,
              46.51667
          ],
          [
              -114.73532,
              46.51662
          ],
          [
              -114.73531,
              46.51659
          ],
          [
              -114.73524,
              46.51645
          ],
          [
              -114.73517,
              46.51623
          ],
          [
              -114.73507,
              46.51581
          ],
          [
              -114.73504,
              46.51565
          ],
          [
              -114.73496,
              46.51536
          ],
          [
              -114.73488,
              46.51515
          ],
          [
              -114.73484,
              46.51507
          ],
          [
              -114.73484,
              46.51506
          ],
          [
              -114.73482,
              46.51504
          ],
          [
              -114.73478,
              46.51497
          ],
          [
              -114.73472,
              46.51491
          ],
          [
              -114.73458,
              46.51475
          ],
          [
              -114.73452,
              46.51469
          ],
          [
              -114.73443,
              46.51463
          ],
          [
              -114.73434,
              46.51456
          ],
          [
              -114.73419,
              46.51448
          ],
          [
              -114.73416,
              46.51446
          ],
          [
              -114.73401,
              46.51439
          ],
          [
              -114.73397,
              46.51438
          ],
          [
              -114.73397,
              46.51437
          ],
          [
              -114.73376,
              46.5143
          ],
          [
              -114.73374,
              46.5143
          ],
          [
              -114.73362,
              46.51426
          ],
          [
              -114.73331,
              46.51419
          ],
          [
              -114.73033,
              46.51361
          ],
          [
              -114.73022,
              46.5136
          ],
          [
              -114.7302,
              46.51359
          ],
          [
              -114.73008,
              46.51358
          ],
          [
              -114.72996,
              46.51356
          ],
          [
              -114.7299,
              46.51356
          ],
          [
              -114.72966,
              46.51354
          ],
          [
              -114.72917,
              46.51354
          ],
          [
              -114.72904,
              46.51355
          ],
          [
              -114.7289,
              46.51357
          ],
          [
              -114.72848,
              46.51366
          ],
          [
              -114.72821,
              46.51375
          ],
          [
              -114.72819,
              46.51376
          ],
          [
              -114.72785,
              46.51389
          ],
          [
              -114.72658,
              46.51448
          ],
          [
              -114.72619,
              46.51462
          ],
          [
              -114.72606,
              46.51466
          ],
          [
              -114.72564,
              46.51476
          ],
          [
              -114.7255,
              46.51478
          ],
          [
              -114.72546,
              46.51479
          ],
          [
              -114.72503,
              46.51484
          ],
          [
              -114.72489,
              46.51484
          ],
          [
              -114.72475,
              46.51485
          ],
          [
              -114.72439,
              46.51485
          ],
          [
              -114.72426,
              46.51484
          ],
          [
              -114.72416,
              46.51484
          ],
          [
              -114.72391,
              46.51481
          ],
          [
              -114.72389,
              46.51481
          ],
          [
              -114.72362,
              46.51477
          ],
          [
              -114.72342,
              46.51473
          ],
          [
              -114.72084,
              46.51408
          ],
          [
              -114.72069,
              46.51405
          ],
          [
              -114.72067,
              46.51405
          ],
          [
              -114.72056,
              46.51402
          ],
          [
              -114.72019,
              46.51396
          ],
          [
              -114.71965,
              46.51392
          ],
          [
              -114.71904,
              46.51397
          ],
          [
              -114.71851,
              46.51407
          ],
          [
              -114.71844,
              46.51409
          ],
          [
              -114.71828,
              46.51412
          ],
          [
              -114.71817,
              46.51415
          ],
          [
              -114.71787,
              46.51425
          ],
          [
              -114.71772,
              46.51429
          ],
          [
              -114.71763,
              46.51432
          ],
          [
              -114.71524,
              46.51529
          ],
          [
              -114.71465,
              46.51547
          ],
          [
              -114.714,
              46.51562
          ],
          [
              -114.71378,
              46.51566
          ],
          [
              -114.71377,
              46.51567
          ],
          [
              -114.71325,
              46.51575
          ],
          [
              -114.71051,
              46.51603
          ],
          [
              -114.71033,
              46.51606
          ],
          [
              -114.71028,
              46.51606
          ],
          [
              -114.70936,
              46.51624
          ],
          [
              -114.7092,
              46.51628
          ],
          [
              -114.70846,
              46.51651
          ],
          [
              -114.70836,
              46.51655
          ],
          [
              -114.70829,
              46.51657
          ],
          [
              -114.70791,
              46.51672
          ],
          [
              -114.70745,
              46.51692
          ],
          [
              -114.70151,
              46.51984
          ],
          [
              -114.701,
              46.52007
          ],
          [
              -114.70061,
              46.52022
          ],
          [
              -114.70027,
              46.52033
          ],
          [
              -114.70022,
              46.52035
          ],
          [
              -114.70016,
              46.52036
          ],
          [
              -114.70001,
              46.5204
          ],
          [
              -114.69929,
              46.52051
          ],
          [
              -114.69909,
              46.52053
          ],
          [
              -114.69887,
              46.52054
          ],
          [
              -114.69837,
              46.52054
          ],
          [
              -114.69795,
              46.52052
          ],
          [
              -114.69755,
              46.52047
          ],
          [
              -114.69234,
              46.51955
          ],
          [
              -114.69143,
              46.51941
          ],
          [
              -114.69107,
              46.51938
          ],
          [
              -114.69069,
              46.51938
          ],
          [
              -114.69011,
              46.51941
          ],
          [
              -114.68976,
              46.51946
          ],
          [
              -114.68964,
              46.51949
          ],
          [
              -114.68924,
              46.51957
          ],
          [
              -114.68724,
              46.52009
          ],
          [
              -114.687,
              46.52018
          ],
          [
              -114.68598,
              46.52044
          ],
          [
              -114.68541,
              46.52056
          ],
          [
              -114.6847,
              46.52076
          ],
          [
              -114.68279,
              46.52125
          ],
          [
              -114.68267,
              46.52129
          ],
          [
              -114.68255,
              46.52132
          ],
          [
              -114.68222,
              46.52143
          ],
          [
              -114.68213,
              46.52147
          ],
          [
              -114.68191,
              46.52155
          ],
          [
              -114.68166,
              46.52167
          ],
          [
              -114.68148,
              46.52177
          ],
          [
              -114.68109,
              46.52203
          ],
          [
              -114.68099,
              46.52211
          ],
          [
              -114.68094,
              46.52216
          ],
          [
              -114.68087,
              46.52222
          ],
          [
              -114.68072,
              46.52237
          ],
          [
              -114.68057,
              46.52256
          ],
          [
              -114.68043,
              46.52276
          ],
          [
              -114.68038,
              46.52287
          ],
          [
              -114.68033,
              46.52295
          ],
          [
              -114.68013,
              46.52337
          ],
          [
              -114.67981,
              46.52415
          ],
          [
              -114.67981,
              46.52416
          ],
          [
              -114.67971,
              46.52441
          ],
          [
              -114.67958,
              46.52465
          ],
          [
              -114.67936,
              46.5249
          ],
          [
              -114.67926,
              46.52498
          ],
          [
              -114.67917,
              46.52507
          ],
          [
              -114.67896,
              46.52524
          ],
          [
              -114.67877,
              46.52537
          ],
          [
              -114.67877,
              46.52538
          ],
          [
              -114.6786,
              46.52551
          ],
          [
              -114.67859,
              46.52552
          ],
          [
              -114.67827,
              46.52577
          ],
          [
              -114.67802,
              46.52603
          ],
          [
              -114.67796,
              46.52614
          ],
          [
              -114.67791,
              46.52626
          ],
          [
              -114.67787,
              46.52638
          ],
          [
              -114.67786,
              46.52644
          ],
          [
              -114.67786,
              46.52662
          ],
          [
              -114.67787,
              46.52673
          ],
          [
              -114.67792,
              46.52699
          ],
          [
              -114.67802,
              46.52728
          ],
          [
              -114.67808,
              46.5275
          ],
          [
              -114.67808,
              46.52751
          ],
          [
              -114.67812,
              46.52768
          ],
          [
              -114.67813,
              46.52771
          ],
          [
              -114.67818,
              46.52798
          ],
          [
              -114.67821,
              46.52821
          ],
          [
              -114.6782,
              46.52834
          ],
          [
              -114.6782,
              46.52845
          ],
          [
              -114.67818,
              46.52854
          ],
          [
              -114.67818,
              46.52858
          ],
          [
              -114.67815,
              46.52872
          ],
          [
              -114.67812,
              46.52882
          ],
          [
              -114.67807,
              46.52892
          ],
          [
              -114.67796,
              46.52911
          ],
          [
              -114.67729,
              46.52995
          ],
          [
              -114.67715,
              46.53021
          ],
          [
              -114.67715,
              46.53022
          ],
          [
              -114.67714,
              46.53024
          ],
          [
              -114.6771,
              46.53035
          ],
          [
              -114.67708,
              46.53046
          ],
          [
              -114.67708,
              46.53055
          ],
          [
              -114.67707,
              46.53067
          ],
          [
              -114.67708,
              46.53074
          ],
          [
              -114.67708,
              46.53088
          ],
          [
              -114.67711,
              46.53107
          ],
          [
              -114.67712,
              46.5311
          ],
          [
              -114.67712,
              46.53111
          ],
          [
              -114.67715,
              46.53118
          ],
          [
              -114.67718,
              46.53128
          ],
          [
              -114.6772,
              46.53133
          ],
          [
              -114.67731,
              46.53168
          ],
          [
              -114.67739,
              46.53198
          ],
          [
              -114.67741,
              46.5321
          ],
          [
              -114.67742,
              46.53222
          ],
          [
              -114.67743,
              46.53277
          ],
          [
              -114.67741,
              46.53302
          ],
          [
              -114.67729,
              46.53362
          ],
          [
              -114.67667,
              46.53563
          ],
          [
              -114.67662,
              46.53588
          ],
          [
              -114.6766,
              46.53595
          ],
          [
              -114.67654,
              46.53626
          ],
          [
              -114.67643,
              46.53739
          ],
          [
              -114.6764,
              46.5376
          ],
          [
              -114.67636,
              46.53773
          ],
          [
              -114.67636,
              46.53774
          ],
          [
              -114.67605,
              46.5386
          ],
          [
              -114.676,
              46.53871
          ],
          [
              -114.67599,
              46.53874
          ],
          [
              -114.67597,
              46.53877
          ],
          [
              -114.6754,
              46.53943
          ],
          [
              -114.67518,
              46.53974
          ],
          [
              -114.67511,
              46.53988
          ],
          [
              -114.67504,
              46.54004
          ],
          [
              -114.67497,
              46.54027
          ],
          [
              -114.67493,
              46.54058
          ],
          [
              -114.67492,
              46.54082
          ],
          [
              -114.67494,
              46.54109
          ],
          [
              -114.67501,
              46.54138
          ],
          [
              -114.67529,
              46.54219
          ],
          [
              -114.67529,
              46.54221
          ],
          [
              -114.67537,
              46.54243
          ],
          [
              -114.67544,
              46.54269
          ],
          [
              -114.67562,
              46.54361
          ],
          [
              -114.67562,
              46.54364
          ],
          [
              -114.67563,
              46.54375
          ],
          [
              -114.67563,
              46.54394
          ],
          [
              -114.67559,
              46.54417
          ],
          [
              -114.67554,
              46.54433
          ],
          [
              -114.67547,
              46.54452
          ],
          [
              -114.6753,
              46.54488
          ],
          [
              -114.67429,
              46.54631
          ],
          [
              -114.67419,
              46.54647
          ],
          [
              -114.67417,
              46.54651
          ],
          [
              -114.67414,
              46.54655
          ],
          [
              -114.67411,
              46.54664
          ],
          [
              -114.67406,
              46.54675
          ],
          [
              -114.67404,
              46.54681
          ],
          [
              -114.674,
              46.54703
          ],
          [
              -114.67397,
              46.54755
          ],
          [
              -114.67394,
              46.54858
          ],
          [
              -114.67393,
              46.54872
          ],
          [
              -114.67389,
              46.54897
          ],
          [
              -114.67357,
              46.54984
          ],
          [
              -114.67348,
              46.55006
          ],
          [
              -114.6733,
              46.55045
          ],
          [
              -114.6732,
              46.55069
          ],
          [
              -114.67315,
              46.55077
          ],
          [
              -114.67309,
              46.5509
          ],
          [
              -114.67293,
              46.55109
          ],
          [
              -114.6728,
              46.55122
          ],
          [
              -114.67272,
              46.55128
          ],
          [
              -114.67265,
              46.55134
          ],
          [
              -114.67244,
              46.55148
          ],
          [
              -114.67235,
              46.55153
          ],
          [
              -114.67206,
              46.55167
          ],
          [
              -114.67173,
              46.55179
          ],
          [
              -114.67065,
              46.5521
          ],
          [
              -114.67041,
              46.55218
          ],
          [
              -114.67038,
              46.5522
          ],
          [
              -114.67011,
              46.55235
          ],
          [
              -114.67005,
              46.55239
          ],
          [
              -114.67001,
              46.55241
          ],
          [
              -114.66983,
              46.55254
          ],
          [
              -114.66961,
              46.55276
          ],
          [
              -114.66955,
              46.55284
          ],
          [
              -114.66954,
              46.55284
          ],
          [
              -114.66952,
              46.55288
          ],
          [
              -114.66948,
              46.55293
          ],
          [
              -114.66939,
              46.55309
          ],
          [
              -114.66935,
              46.55318
          ],
          [
              -114.66905,
              46.55376
          ],
          [
              -114.66903,
              46.55379
          ],
          [
              -114.66902,
              46.55381
          ],
          [
              -114.669,
              46.55383
          ],
          [
              -114.66895,
              46.5539
          ],
          [
              -114.6689,
              46.55395
          ],
          [
              -114.66887,
              46.55399
          ],
          [
              -114.66876,
              46.5541
          ],
          [
              -114.66871,
              46.55414
          ],
          [
              -114.66869,
              46.55415
          ],
          [
              -114.66854,
              46.55425
          ],
          [
              -114.66841,
              46.55432
          ],
          [
              -114.66811,
              46.55444
          ],
          [
              -114.66782,
              46.55452
          ],
          [
              -114.66773,
              46.55453
          ],
          [
              -114.66765,
              46.55455
          ],
          [
              -114.66756,
              46.55456
          ],
          [
              -114.66749,
              46.55456
          ],
          [
              -114.66743,
              46.55457
          ],
          [
              -114.66725,
              46.55457
          ],
          [
              -114.66611,
              46.55449
          ],
          [
              -114.66589,
              46.55449
          ],
          [
              -114.66581,
              46.55448
          ],
          [
              -114.6657,
              46.55449
          ],
          [
              -114.66546,
              46.55449
          ],
          [
              -114.66536,
              46.55451
          ],
          [
              -114.66527,
              46.55452
          ],
          [
              -114.66516,
              46.55454
          ],
          [
              -114.66488,
              46.55462
          ],
          [
              -114.66467,
              46.55471
          ],
          [
              -114.66458,
              46.55476
          ],
          [
              -114.66447,
              46.55483
          ],
          [
              -114.66438,
              46.5549
          ],
          [
              -114.66422,
              46.55504
          ],
          [
              -114.66385,
              46.55545
          ],
          [
              -114.6633,
              46.55616
          ],
          [
              -114.66266,
              46.55694
          ],
          [
              -114.66265,
              46.55696
          ],
          [
              -114.6626,
              46.55703
          ],
          [
              -114.66239,
              46.55724
          ],
          [
              -114.6622,
              46.55738
          ],
          [
              -114.66189,
              46.55754
          ],
          [
              -114.66182,
              46.55757
          ],
          [
              -114.6616,
              46.55764
          ],
          [
              -114.66139,
              46.55769
          ],
          [
              -114.66128,
              46.5577
          ],
          [
              -114.66119,
              46.55772
          ],
          [
              -114.66109,
              46.55773
          ],
          [
              -114.66102,
              46.55773
          ],
          [
              -114.66082,
              46.55775
          ],
          [
              -114.66067,
              46.55775
          ],
          [
              -114.66028,
              46.55778
          ],
          [
              -114.65992,
              46.55784
          ],
          [
              -114.65981,
              46.55787
          ],
          [
              -114.65974,
              46.55788
          ],
          [
              -114.65967,
              46.5579
          ],
          [
              -114.65956,
              46.55794
          ],
          [
              -114.65954,
              46.55795
          ],
          [
              -114.65942,
              46.55799
          ],
          [
              -114.6593,
              46.55804
          ],
          [
              -114.65908,
              46.55815
          ],
          [
              -114.65895,
              46.55823
          ],
          [
              -114.65883,
              46.55832
          ],
          [
              -114.65878,
              46.55835
          ],
          [
              -114.65872,
              46.5584
          ],
          [
              -114.65866,
              46.55846
          ],
          [
              -114.65848,
              46.55862
          ],
          [
              -114.65841,
              46.5587
          ],
          [
              -114.65819,
              46.55891
          ],
          [
              -114.65813,
              46.55896
          ],
          [
              -114.65793,
              46.5591
          ],
          [
              -114.65776,
              46.55919
          ],
          [
              -114.6575,
              46.5593
          ],
          [
              -114.65711,
              46.55942
          ],
          [
              -114.65674,
              46.55948
          ],
          [
              -114.65672,
              46.55948
          ],
          [
              -114.65661,
              46.5595
          ],
          [
              -114.65609,
              46.55955
          ],
          [
              -114.65348,
              46.55973
          ],
          [
              -114.65304,
              46.5598
          ],
          [
              -114.65291,
              46.55983
          ],
          [
              -114.6529,
              46.55983
          ],
          [
              -114.65268,
              46.55988
          ],
          [
              -114.6526,
              46.55991
          ],
          [
              -114.6525,
              46.55994
          ],
          [
              -114.65228,
              46.56002
          ],
          [
              -114.65215,
              46.56006
          ],
          [
              -114.6519,
              46.56017
          ],
          [
              -114.65187,
              46.56018
          ],
          [
              -114.65143,
              46.56043
          ],
          [
              -114.65129,
              46.56053
          ],
          [
              -114.65112,
              46.56064
          ],
          [
              -114.65067,
              46.56098
          ],
          [
              -114.64935,
              46.56206
          ],
          [
              -114.64764,
              46.5634
          ],
          [
              -114.64736,
              46.56357
          ],
          [
              -114.64726,
              46.56364
          ],
          [
              -114.6469,
              46.56383
          ],
          [
              -114.64546,
              46.56453
          ],
          [
              -114.64543,
              46.56455
          ],
          [
              -114.64269,
              46.56586
          ],
          [
              -114.6414,
              46.5665
          ],
          [
              -114.63849,
              46.568
          ],
          [
              -114.636,
              46.56934
          ],
          [
              -114.63572,
              46.56948
          ],
          [
              -114.63526,
              46.56968
          ],
          [
              -114.63495,
              46.56979
          ],
          [
              -114.63486,
              46.56981
          ],
          [
              -114.63457,
              46.5699
          ],
          [
              -114.6345,
              46.56991
          ],
          [
              -114.63419,
              46.56998
          ],
          [
              -114.63393,
              46.57002
          ],
          [
              -114.63344,
              46.57006
          ],
          [
              -114.63332,
              46.57006
          ],
          [
              -114.63321,
              46.57007
          ],
          [
              -114.63307,
              46.57007
          ],
          [
              -114.63298,
              46.57006
          ],
          [
              -114.6328,
              46.57006
          ],
          [
              -114.63245,
              46.57004
          ],
          [
              -114.6317,
              46.56996
          ],
          [
              -114.63111,
              46.56992
          ],
          [
              -114.63065,
              46.56992
          ],
          [
              -114.63048,
              46.56994
          ],
          [
              -114.63041,
              46.56994
          ],
          [
              -114.63001,
              46.57003
          ],
          [
              -114.62991,
              46.57007
          ],
          [
              -114.62979,
              46.57011
          ],
          [
              -114.62962,
              46.57019
          ],
          [
              -114.62956,
              46.57023
          ],
          [
              -114.62955,
              46.57023
          ],
          [
              -114.62939,
              46.57033
          ],
          [
              -114.62933,
              46.57038
          ],
          [
              -114.62929,
              46.57042
          ],
          [
              -114.62922,
              46.57048
          ],
          [
              -114.62912,
              46.5706
          ],
          [
              -114.62908,
              46.57066
          ],
          [
              -114.62903,
              46.57072
          ],
          [
              -114.629,
              46.57079
          ],
          [
              -114.62897,
              46.57083
          ],
          [
              -114.62891,
              46.57097
          ],
          [
              -114.62874,
              46.57143
          ],
          [
              -114.6287,
              46.57151
          ],
          [
              -114.62823,
              46.57272
          ],
          [
              -114.62818,
              46.5728
          ],
          [
              -114.6281,
              46.57295
          ],
          [
              -114.62795,
              46.57317
          ],
          [
              -114.62791,
              46.57322
          ],
          [
              -114.62791,
              46.57323
          ],
          [
              -114.62785,
              46.57331
          ],
          [
              -114.62777,
              46.5734
          ],
          [
              -114.62772,
              46.57347
          ],
          [
              -114.62766,
              46.57353
          ],
          [
              -114.62759,
              46.57361
          ],
          [
              -114.62737,
              46.57383
          ],
          [
              -114.62706,
              46.57409
          ],
          [
              -114.62695,
              46.57417
          ],
          [
              -114.62666,
              46.57436
          ],
          [
              -114.6236,
              46.576
          ],
          [
              -114.62345,
              46.57607
          ],
          [
              -114.62298,
              46.57632
          ],
          [
              -114.62143,
              46.5771
          ],
          [
              -114.62092,
              46.57734
          ],
          [
              -114.6208,
              46.57739
          ],
          [
              -114.62067,
              46.57743
          ],
          [
              -114.62046,
              46.57751
          ],
          [
              -114.62039,
              46.57753
          ],
          [
              -114.62028,
              46.57757
          ],
          [
              -114.6199,
              46.57767
          ],
          [
              -114.61971,
              46.57771
          ],
          [
              -114.61954,
              46.57773
          ],
          [
              -114.61938,
              46.57776
          ],
          [
              -114.61922,
              46.57778
          ],
          [
              -114.61888,
              46.5778
          ],
          [
              -114.61875,
              46.5778
          ],
          [
              -114.61861,
              46.57781
          ],
          [
              -114.61837,
              46.5778
          ],
          [
              -114.61816,
              46.5778
          ],
          [
              -114.61713,
              46.57773
          ],
          [
              -114.61696,
              46.57773
          ],
          [
              -114.61687,
              46.57772
          ],
          [
              -114.61663,
              46.57772
          ],
          [
              -114.61608,
              46.57776
          ],
          [
              -114.61575,
              46.57782
          ],
          [
              -114.61553,
              46.57787
          ],
          [
              -114.61335,
              46.57861
          ],
          [
              -114.61285,
              46.5788
          ],
          [
              -114.61257,
              46.57895
          ],
          [
              -114.61249,
              46.579
          ],
          [
              -114.61228,
              46.57916
          ],
          [
              -114.61211,
              46.57932
          ],
          [
              -114.61204,
              46.5794
          ],
          [
              -114.61191,
              46.57959
          ],
          [
              -114.61185,
              46.5797
          ],
          [
              -114.61181,
              46.5798
          ],
          [
              -114.61174,
              46.58003
          ],
          [
              -114.61173,
              46.58004
          ],
          [
              -114.61167,
              46.58029
          ],
          [
              -114.61163,
              46.58042
          ],
          [
              -114.61155,
              46.58058
          ],
          [
              -114.61139,
              46.58077
          ],
          [
              -114.61108,
              46.58102
          ],
          [
              -114.61088,
              46.58114
          ],
          [
              -114.6108,
              46.58117
          ],
          [
              -114.61076,
              46.58119
          ],
          [
              -114.61064,
              46.58123
          ],
          [
              -114.61051,
              46.58126
          ],
          [
              -114.61043,
              46.58129
          ],
          [
              -114.61034,
              46.5813
          ],
          [
              -114.6102,
              46.58133
          ],
          [
              -114.60989,
              46.58136
          ],
          [
              -114.60964,
              46.58136
          ],
          [
              -114.60598,
              46.58113
          ],
          [
              -114.60562,
              46.58113
          ],
          [
              -114.60546,
              46.58115
          ],
          [
              -114.60543,
              46.58115
          ],
          [
              -114.6052,
              46.5812
          ],
          [
              -114.60518,
              46.5812
          ],
          [
              -114.60501,
              46.58126
          ],
          [
              -114.60492,
              46.58128
          ],
          [
              -114.60478,
              46.58134
          ],
          [
              -114.60455,
              46.58148
          ],
          [
              -114.60437,
              46.58161
          ],
          [
              -114.60423,
              46.58175
          ],
          [
              -114.60414,
              46.58189
          ],
          [
              -114.60356,
              46.58302
          ],
          [
              -114.6035,
              46.58315
          ],
          [
              -114.60349,
              46.58321
          ],
          [
              -114.60347,
              46.58327
          ],
          [
              -114.60346,
              46.58333
          ],
          [
              -114.60346,
              46.5835
          ],
          [
              -114.60348,
              46.58361
          ],
          [
              -114.60352,
              46.58376
          ],
          [
              -114.60355,
              46.58385
          ],
          [
              -114.60359,
              46.58391
          ],
          [
              -114.60383,
              46.58435
          ],
          [
              -114.60409,
              46.58477
          ],
          [
              -114.60419,
              46.58502
          ],
          [
              -114.60422,
              46.58508
          ],
          [
              -114.60423,
              46.58513
          ],
          [
              -114.60423,
              46.58522
          ],
          [
              -114.60424,
              46.58532
          ],
          [
              -114.60424,
              46.58542
          ],
          [
              -114.60421,
              46.58571
          ],
          [
              -114.60416,
              46.58585
          ],
          [
              -114.60405,
              46.58608
          ],
          [
              -114.60399,
              46.58615
          ],
          [
              -114.60392,
              46.58625
          ],
          [
              -114.60376,
              46.58641
          ],
          [
              -114.60149,
              46.58849
          ],
          [
              -114.60146,
              46.58851
          ],
          [
              -114.6014,
              46.58857
          ],
          [
              -114.60134,
              46.58864
          ],
          [
              -114.60133,
              46.58864
          ],
          [
              -114.60121,
              46.58879
          ],
          [
              -114.60121,
              46.5888
          ],
          [
              -114.60111,
              46.58896
          ],
          [
              -114.60108,
              46.58904
          ],
          [
              -114.60106,
              46.58912
          ],
          [
              -114.60105,
              46.58918
          ],
          [
              -114.60105,
              46.58937
          ],
          [
              -114.60106,
              46.58944
          ],
          [
              -114.60113,
              46.58968
          ],
          [
              -114.60123,
              46.58987
          ],
          [
              -114.60129,
              46.58993
          ],
          [
              -114.60135,
              46.59
          ],
          [
              -114.60142,
              46.59005
          ],
          [
              -114.60148,
              46.5901
          ],
          [
              -114.60172,
              46.59024
          ],
          [
              -114.60277,
              46.59066
          ],
          [
              -114.60307,
              46.59083
          ],
          [
              -114.60318,
              46.59091
          ],
          [
              -114.60325,
              46.59098
          ],
          [
              -114.60331,
              46.59103
          ],
          [
              -114.60346,
              46.59119
          ],
          [
              -114.6035,
              46.59126
          ],
          [
              -114.60352,
              46.59128
          ],
          [
              -114.60358,
              46.59137
          ],
          [
              -114.6036,
              46.59142
          ],
          [
              -114.60363,
              46.59147
          ],
          [
              -114.60365,
              46.59152
          ],
          [
              -114.60368,
              46.59171
          ],
          [
              -114.60368,
              46.59177
          ],
          [
              -114.60366,
              46.59193
          ],
          [
              -114.60366,
              46.59196
          ],
          [
              -114.60362,
              46.59211
          ],
          [
              -114.6036,
              46.59216
          ],
          [
              -114.60356,
              46.59224
          ],
          [
              -114.60353,
              46.59229
          ],
          [
              -114.60348,
              46.59236
          ],
          [
              -114.60333,
              46.59253
          ],
          [
              -114.60311,
              46.59271
          ],
          [
              -114.6028,
              46.59289
          ],
          [
              -114.60262,
              46.59298
          ],
          [
              -114.60188,
              46.59331
          ],
          [
              -114.60161,
              46.59345
          ],
          [
              -114.60131,
              46.59364
          ],
          [
              -114.60113,
              46.5938
          ],
          [
              -114.60102,
              46.59392
          ],
          [
              -114.60093,
              46.59403
          ],
          [
              -114.6009,
              46.59408
          ],
          [
              -114.60081,
              46.59419
          ],
          [
              -114.60024,
              46.59497
          ],
          [
              -114.60015,
              46.59511
          ],
          [
              -114.59988,
              46.59547
          ],
          [
              -114.59987,
              46.59548
          ],
          [
              -114.59977,
              46.59562
          ],
          [
              -114.59968,
              46.59573
          ],
          [
              -114.59964,
              46.5958
          ],
          [
              -114.59952,
              46.59596
          ],
          [
              -114.59949,
              46.59601
          ],
          [
              -114.59942,
              46.59611
          ],
          [
              -114.59937,
              46.59622
          ],
          [
              -114.59933,
              46.59629
          ],
          [
              -114.59932,
              46.59632
          ],
          [
              -114.59931,
              46.59638
          ],
          [
              -114.59929,
              46.59646
          ],
          [
              -114.59929,
              46.59662
          ],
          [
              -114.5993,
              46.59673
          ],
          [
              -114.59932,
              46.5968
          ],
          [
              -114.59942,
              46.59701
          ],
          [
              -114.59959,
              46.59722
          ],
          [
              -114.59998,
              46.59767
          ],
          [
              -114.60005,
              46.59777
          ],
          [
              -114.60006,
              46.59778
          ],
          [
              -114.60011,
              46.59786
          ],
          [
              -114.60015,
              46.59794
          ],
          [
              -114.60015,
              46.59795
          ],
          [
              -114.60024,
              46.59817
          ],
          [
              -114.60026,
              46.5983
          ],
          [
              -114.60026,
              46.59841
          ],
          [
              -114.60024,
              46.59849
          ],
          [
              -114.60023,
              46.59857
          ],
          [
              -114.60019,
              46.59866
          ],
          [
              -114.60011,
              46.59881
          ],
          [
              -114.60003,
              46.59893
          ],
          [
              -114.59993,
              46.59904
          ],
          [
              -114.59983,
              46.59913
          ],
          [
              -114.59971,
              46.59922
          ],
          [
              -114.59959,
              46.5993
          ],
          [
              -114.59934,
              46.59942
          ],
          [
              -114.599,
              46.59953
          ],
          [
              -114.59869,
              46.59961
          ],
          [
              -114.59836,
              46.59971
          ],
          [
              -114.59814,
              46.59979
          ],
          [
              -114.59808,
              46.59982
          ],
          [
              -114.598,
              46.59987
          ],
          [
              -114.59799,
              46.59988
          ],
          [
              -114.59794,
              46.5999
          ],
          [
              -114.59787,
              46.59995
          ],
          [
              -114.59772,
              46.60008
          ],
          [
              -114.59585,
              46.60213
          ],
          [
              -114.59559,
              46.60249
          ],
          [
              -114.59558,
              46.60252
          ],
          [
              -114.59555,
              46.60257
          ],
          [
              -114.59553,
              46.60262
          ],
          [
              -114.59551,
              46.60275
          ],
          [
              -114.59549,
              46.60283
          ],
          [
              -114.59545,
              46.6031
          ],
          [
              -114.59539,
              46.60334
          ],
          [
              -114.59539,
              46.60336
          ],
          [
              -114.59534,
              46.60356
          ],
          [
              -114.59531,
              46.60362
          ],
          [
              -114.59529,
              46.60369
          ],
          [
              -114.59526,
              46.60375
          ],
          [
              -114.59524,
              46.60381
          ],
          [
              -114.59517,
              46.60394
          ],
          [
              -114.59513,
              46.60399
          ],
          [
              -114.59509,
              46.60405
          ],
          [
              -114.59504,
              46.60411
          ],
          [
              -114.59488,
              46.60427
          ],
          [
              -114.59482,
              46.60432
          ],
          [
              -114.59466,
              46.60443
          ],
          [
              -114.59452,
              46.6045
          ],
          [
              -114.59444,
              46.60453
          ],
          [
              -114.59436,
              46.60457
          ],
          [
              -114.59435,
              46.60457
          ],
          [
              -114.59426,
              46.6046
          ],
          [
              -114.59417,
              46.60462
          ],
          [
              -114.59408,
              46.60465
          ],
          [
              -114.59399,
              46.60467
          ],
          [
              -114.59373,
              46.60476
          ],
          [
              -114.59354,
              46.60485
          ],
          [
              -114.59328,
              46.60501
          ],
          [
              -114.59312,
              46.60515
          ],
          [
              -114.59292,
              46.60538
          ],
          [
              -114.59265,
              46.60578
          ],
          [
              -114.5924,
              46.60618
          ],
          [
              -114.59224,
              46.60649
          ],
          [
              -114.59223,
              46.6065
          ],
          [
              -114.59218,
              46.60666
          ],
          [
              -114.59216,
              46.60675
          ],
          [
              -114.59215,
              46.60684
          ],
          [
              -114.59217,
              46.60704
          ],
          [
              -114.59219,
              46.60711
          ],
          [
              -114.5922,
              46.60716
          ],
          [
              -114.59222,
              46.60721
          ],
          [
              -114.59228,
              46.60733
          ],
          [
              -114.59239,
              46.60748
          ],
          [
              -114.59244,
              46.60753
          ],
          [
              -114.59258,
              46.60764
          ],
          [
              -114.59308,
              46.608
          ],
          [
              -114.59333,
              46.6082
          ],
          [
              -114.59344,
              46.60831
          ],
          [
              -114.59355,
              46.60846
          ],
          [
              -114.59363,
              46.6086
          ],
          [
              -114.59372,
              46.60881
          ],
          [
              -114.59374,
              46.60891
          ],
          [
              -114.59374,
              46.60899
          ],
          [
              -114.59373,
              46.60913
          ],
          [
              -114.59373,
              46.6092
          ],
          [
              -114.59371,
              46.60929
          ],
          [
              -114.5937,
              46.60931
          ],
          [
              -114.59367,
              46.6094
          ],
          [
              -114.59361,
              46.60954
          ],
          [
              -114.5935,
              46.60976
          ],
          [
              -114.59334,
              46.61002
          ],
          [
              -114.59287,
              46.61069
          ],
          [
              -114.59266,
              46.61092
          ],
          [
              -114.59262,
              46.61094
          ],
          [
              -114.59254,
              46.61101
          ],
          [
              -114.59239,
              46.61112
          ],
          [
              -114.59184,
              46.61145
          ],
          [
              -114.59172,
              46.61153
          ],
          [
              -114.59158,
              46.61164
          ],
          [
              -114.59153,
              46.61169
          ],
          [
              -114.59148,
              46.61175
          ],
          [
              -114.59139,
              46.61188
          ],
          [
              -114.59136,
              46.61195
          ],
          [
              -114.59132,
              46.61209
          ],
          [
              -114.59132,
              46.61232
          ],
          [
              -114.59133,
              46.6124
          ],
          [
              -114.5914,
              46.61274
          ],
          [
              -114.5914,
              46.61281
          ],
          [
              -114.59141,
              46.61287
          ],
          [
              -114.59141,
              46.613
          ],
          [
              -114.5914,
              46.61306
          ],
          [
              -114.5914,
              46.61313
          ],
          [
              -114.59136,
              46.61329
          ],
          [
              -114.59132,
              46.61339
          ],
          [
              -114.59132,
              46.61341
          ],
          [
              -114.59126,
              46.61353
          ],
          [
              -114.59125,
              46.61356
          ],
          [
              -114.59121,
              46.61364
          ],
          [
              -114.5911,
              46.6138
          ],
          [
              -114.59092,
              46.61401
          ],
          [
              -114.59076,
              46.61415
          ],
          [
              -114.59026,
              46.61452
          ],
          [
              -114.59018,
              46.6146
          ],
          [
              -114.59003,
              46.61479
          ],
          [
              -114.58997,
              46.6149
          ],
          [
              -114.58997,
              46.61492
          ],
          [
              -114.58994,
              46.61497
          ],
          [
              -114.58992,
              46.61504
          ],
          [
              -114.5899,
              46.61514
          ],
          [
              -114.5899,
              46.61528
          ],
          [
              -114.58991,
              46.61534
          ],
          [
              -114.58994,
              46.61541
          ],
          [
              -114.58999,
              46.61557
          ],
          [
              -114.5901,
              46.61577
          ],
          [
              -114.59021,
              46.61593
          ],
          [
              -114.59036,
              46.61621
          ],
          [
              -114.59041,
              46.61637
          ],
          [
              -114.59041,
              46.61642
          ],
          [
              -114.59042,
              46.61644
          ],
          [
              -114.59042,
              46.61663
          ],
          [
              -114.59041,
              46.61666
          ],
          [
              -114.5904,
              46.61678
          ],
          [
              -114.59034,
              46.61709
          ],
          [
              -114.59027,
              46.61735
          ],
          [
              -114.59023,
              46.61754
          ],
          [
              -114.58984,
              46.61882
          ],
          [
              -114.5897,
              46.61938
          ],
          [
              -114.58962,
              46.61981
          ],
          [
              -114.58952,
              46.62064
          ],
          [
              -114.58946,
              46.62096
          ],
          [
              -114.5894,
              46.62122
          ],
          [
              -114.58931,
              46.62155
          ],
          [
              -114.58915,
              46.62207
          ],
          [
              -114.58894,
              46.62261
          ],
          [
              -114.58878,
              46.62296
          ],
          [
              -114.5871,
              46.62637
          ],
          [
              -114.58707,
              46.62644
          ],
          [
              -114.58699,
              46.62657
          ],
          [
              -114.58671,
              46.6271
          ],
          [
              -114.58609,
              46.62816
          ],
          [
              -114.58587,
              46.62856
          ],
          [
              -114.58567,
              46.62888
          ],
          [
              -114.58565,
              46.62892
          ],
          [
              -114.58526,
              46.62958
          ],
          [
              -114.58496,
              46.63003
          ],
          [
              -114.58496,
              46.63004
          ],
          [
              -114.58476,
              46.6303
          ],
          [
              -114.58464,
              46.63044
          ],
          [
              -114.58444,
              46.63064
          ],
          [
              -114.58408,
              46.63093
          ],
          [
              -114.58384,
              46.6311
          ],
          [
              -114.58374,
              46.63118
          ],
          [
              -114.58361,
              46.63126
          ],
          [
              -114.58319,
              46.63148
          ],
          [
              -114.58286,
              46.63163
          ],
          [
              -114.58218,
              46.63187
          ],
          [
              -114.58161,
              46.63204
          ],
          [
              -114.58139,
              46.63213
          ],
          [
              -114.58138,
              46.63213
          ],
          [
              -114.58106,
              46.63227
          ],
          [
              -114.58096,
              46.63233
          ],
          [
              -114.58077,
              46.63246
          ],
          [
              -114.5806,
              46.63255
          ],
          [
              -114.58054,
              46.63259
          ],
          [
              -114.58045,
              46.63266
          ],
          [
              -114.58021,
              46.63289
          ],
          [
              -114.58003,
              46.63311
          ],
          [
              -114.57996,
              46.63322
          ],
          [
              -114.57992,
              46.63327
          ],
          [
              -114.57984,
              46.63343
          ],
          [
              -114.57981,
              46.63351
          ],
          [
              -114.5798,
              46.63352
          ],
          [
              -114.57971,
              46.63381
          ],
          [
              -114.57968,
              46.63406
          ],
          [
              -114.5797,
              46.63432
          ],
          [
              -114.57974,
              46.63449
          ],
          [
              -114.57981,
              46.6347
          ],
          [
              -114.57987,
              46.63483
          ],
          [
              -114.57988,
              46.63486
          ],
          [
              -114.58003,
              46.63514
          ]
      ]
			},
			id: "86cd5e29-e6eb-4279-b976-7e5546c42965",
		}
	],
};

export default NorthwestPassage;
