import React, { useEffect, useRef } from "react";
import { IconSearch } from '../../images/icons';

const SearchInput = (props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.searchQuery === null) {
      inputRef.current.value = '';
    }
  }, [props.searchQuery]);

  const handleClick = () => {
    props.updateQuery(inputRef.current.value);
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div className="search-box">
      <button onClick={handleClick}><IconSearch /></button>
      <input
        ref={inputRef}
        onKeyPress={handleKeyPress}
        placeholder="Looking for something?"
      />
    </div>
  );
}

export default SearchInput;