const LostGoldTrailsLoop = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "Lost Gold Trails Loop",
				description: "Follow the Lost Gold Trails Loop through rolling terrain in eastern Idaho. This byway runs along an early gold rush route, historic sites like the Heritage Hall Museum, the gateway to the Nez Perce Historic Trail and Beaver Canyon. When the gold near Spencer disappeared, opals took over, and you can still dig for opals in this area today. During the spring and fall, this route often features wild game grazing along its length.",
				link: "https://visitidaho.org/things-to-do/road-trips/lost-gold-trails-loop-2/",
				route: [
					"Dubois", 
					"Spencer", 
					"Idmon"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
					[
					  -112.20967,
					  44.17298
					],
					[
					  -112.20707,
					  44.17296
					],
					[
					  -112.20674,
					  44.17297
					],
					[
					  -112.20655,
					  44.17299
					],
					[
					  -112.20635,
					  44.17302
					],
					[
					  -112.20609,
					  44.17307
					],
					[
					  -112.20607,
					  44.17307
					],
					[
					  -112.20584,
					  44.17313
					],
					[
					  -112.20512,
					  44.1734
					],
					[
					  -112.20503,
					  44.17344
					],
					[
					  -112.20494,
					  44.17349
					],
					[
					  -112.20479,
					  44.17359
					],
					[
					  -112.20283,
					  44.17507
					],
					[
					  -112.19602,
					  44.18003
					],
					[
					  -112.1954,
					  44.18046
					],
					[
					  -112.19515,
					  44.18058
					],
					[
					  -112.19477,
					  44.18074
					],
					[
					  -112.19403,
					  44.18099
					],
					[
					  -112.18954,
					  44.18237
					],
					[
					  -112.18931,
					  44.18245
					],
					[
					  -112.1877,
					  44.18294
					],
					[
					  -112.18747,
					  44.18302
					],
					[
					  -112.18298,
					  44.18441
					],
					[
					  -112.18275,
					  44.18449
					],
					[
					  -112.18273,
					  44.1845
					],
					[
					  -112.17838,
					  44.18578
					],
					[
					  -112.17815,
					  44.18584
					],
					[
					  -112.17059,
					  44.18803
					],
					[
					  -112.16988,
					  44.18819
					],
					[
					  -112.16968,
					  44.18821
					],
					[
					  -112.14753,
					  44.18921
					],
					[
					  -112.14698,
					  44.18925
					],
					[
					  -112.14673,
					  44.18926
					],
					[
					  -112.14573,
					  44.18933
					],
					[
					  -112.13115,
					  44.18998
					],
					[
					  -112.13076,
					  44.19003
					],
					[
					  -112.13053,
					  44.19008
					],
					[
					  -112.13015,
					  44.19021
					],
					[
					  -112.12774,
					  44.19111
					],
					[
					  -112.11901,
					  44.19424
					],
					[
					  -112.11862,
					  44.19439
					],
					[
					  -112.1157,
					  44.19542
					],
					[
					  -112.11566,
					  44.19543
					],
					[
					  -112.1147,
					  44.19579
					],
					[
					  -112.11294,
					  44.19642
					],
					[
					  -112.11283,
					  44.19645
					],
					[
					  -112.11201,
					  44.19673
					],
					[
					  -112.1102,
					  44.19739
					],
					[
					  -112.10898,
					  44.1978
					],
					[
					  -112.10792,
					  44.19819
					],
					[
					  -112.10789,
					  44.19821
					],
					[
					  -112.10729,
					  44.19842
					],
					[
					  -112.10537,
					  44.19914
					],
					[
					  -112.10435,
					  44.19949
					],
					[
					  -112.10319,
					  44.19992
					],
					[
					  -112.0954,
					  44.2027
					],
					[
					  -112.09517,
					  44.20277
					],
					[
					  -112.08622,
					  44.20603
					],
					[
					  -112.086,
					  44.2061
					],
					[
					  -112.08316,
					  44.20709
					],
					[
					  -112.07193,
					  44.2111
					],
					[
					  -112.07082,
					  44.21152
					],
					[
					  -112.07075,
					  44.21154
					],
					[
					  -112.06946,
					  44.21199
					],
					[
					  -112.06328,
					  44.21424
					],
					[
					  -112.06105,
					  44.21502
					],
					[
					  -112.05949,
					  44.21559
					],
					[
					  -112.05358,
					  44.21767
					],
					[
					  -112.05106,
					  44.21859
					],
					[
					  -112.04749,
					  44.21983
					],
					[
					  -112.04743,
					  44.21986
					],
					[
					  -112.0472,
					  44.21994
					],
					[
					  -112.04698,
					  44.22003
					],
					[
					  -112.04665,
					  44.22021
					],
					[
					  -112.04641,
					  44.22038
					],
					[
					  -112.04638,
					  44.22041
					],
					[
					  -112.0418,
					  44.22442
					],
					[
					  -112.04165,
					  44.22456
					],
					[
					  -112.03974,
					  44.22623
					],
					[
					  -112.03958,
					  44.22636
					],
					[
					  -112.03783,
					  44.2279
					],
					[
					  -112.03767,
					  44.22803
					],
					[
					  -112.03369,
					  44.23151
					],
					[
					  -112.03038,
					  44.23445
					],
					[
					  -112.02175,
					  44.24194
					],
					[
					  -112.0216,
					  44.24208
					],
					[
					  -112.02056,
					  44.24299
					],
					[
					  -112.02039,
					  44.24319
					],
					[
					  -112.02024,
					  44.24345
					],
					[
					  -112.02022,
					  44.24348
					],
					[
					  -112.0202,
					  44.24353
					],
					[
					  -112.02007,
					  44.24381
					],
					[
					  -112.01985,
					  44.2448
					],
					[
					  -112.01969,
					  44.24569
					],
					[
					  -112.01965,
					  44.24587
					],
					[
					  -112.01807,
					  44.25448
					],
					[
					  -112.01786,
					  44.2558
					],
					[
					  -112.01783,
					  44.25591
					],
					[
					  -112.01772,
					  44.25641
					],
					[
					  -112.01758,
					  44.25684
					],
					[
					  -112.0175,
					  44.25696
					],
					[
					  -112.01731,
					  44.25729
					],
					[
					  -112.017,
					  44.2576
					],
					[
					  -112.01684,
					  44.25775
					],
					[
					  -112.01659,
					  44.25794
					],
					[
					  -112.01608,
					  44.25826
					],
					[
					  -112.01549,
					  44.25858
					],
					[
					  -112.01512,
					  44.25876
					],
					[
					  -112.01279,
					  44.26003
					],
					[
					  -112.01256,
					  44.26017
					],
					[
					  -112.01132,
					  44.26087
					],
					[
					  -112.01129,
					  44.26088
					],
					[
					  -112.01112,
					  44.26098
					],
					[
					  -112.01016,
					  44.26149
					],
					[
					  -112.01012,
					  44.26152
					],
					[
					  -112.00874,
					  44.26229
					],
					[
					  -112.00873,
					  44.26229
					],
					[
					  -112.00713,
					  44.26316
					],
					[
					  -112.00349,
					  44.2652
					],
					[
					  -112.00264,
					  44.26566
					],
					[
					  -112.00122,
					  44.26646
					],
					[
					  -112.00078,
					  44.26669
					],
					[
					  -111.98997,
					  44.2727
					],
					[
					  -111.98848,
					  44.27349
					],
					[
					  -111.98724,
					  44.27419
					],
					[
					  -111.98609,
					  44.27474
					],
					[
					  -111.97692,
					  44.27863
					],
					[
					  -111.97607,
					  44.27895
					],
					[
					  -111.97554,
					  44.27913
					],
					[
					  -111.97531,
					  44.2792
					],
					[
					  -111.97076,
					  44.28073
					],
					[
					  -111.96949,
					  44.28123
					],
					[
					  -111.96817,
					  44.28185
					],
					[
					  -111.95168,
					  44.28997
					],
					[
					  -111.95142,
					  44.29009
					],
					[
					  -111.93976,
					  44.2958
					],
					[
					  -111.93876,
					  44.29631
					],
					[
					  -111.93355,
					  44.29883
					],
					[
					  -111.9323,
					  44.29946
					],
					[
					  -111.93148,
					  44.29981
					],
					[
					  -111.93089,
					  44.30002
					],
					[
					  -111.93048,
					  44.30013
					],
					[
					  -111.92493,
					  44.30182
					],
					[
					  -111.92485,
					  44.30184
					],
					[
					  -111.92173,
					  44.3028
					],
					[
					  -111.92126,
					  44.30296
					],
					[
					  -111.9211,
					  44.30303
					],
					[
					  -111.92054,
					  44.30335
					],
					[
					  -111.92022,
					  44.30357
					],
					[
					  -111.91966,
					  44.30402
					],
					[
					  -111.91919,
					  44.30436
					],
					[
					  -111.91865,
					  44.3048
					],
					[
					  -111.91843,
					  44.30502
					],
					[
					  -111.91821,
					  44.30527
					],
					[
					  -111.91799,
					  44.30554
					],
					[
					  -111.91782,
					  44.30577
					],
					[
					  -111.91762,
					  44.3063
					],
					[
					  -111.91723,
					  44.30715
					],
					[
					  -111.91447,
					  44.31361
					],
					[
					  -111.91388,
					  44.31481
					],
					[
					  -111.91199,
					  44.31887
					],
					[
					  -111.91183,
					  44.31932
					],
					[
					  -111.91175,
					  44.31964
					],
					[
					  -111.91172,
					  44.31995
					],
					[
					  -111.91176,
					  44.32195
					],
					[
					  -111.91176,
					  44.32577
					],
					[
					  -111.91179,
					  44.32681
					],
					[
					  -111.91181,
					  44.32825
					],
					[
					  -111.91182,
					  44.32844
					],
					[
					  -111.91184,
					  44.33527
					],
					[
					  -111.91182,
					  44.3356
					],
					[
					  -111.91187,
					  44.36063
					],
					[
					  -111.91164,
					  44.36064
					],
					[
					  -111.91187,
					  44.36063
					],
					[
					  -111.91836,
					  44.36063
					],
					[
					  -111.91881,
					  44.36064
					],
					[
					  -111.92163,
					  44.36061
					],
					[
					  -111.92438,
					  44.36061
					],
					[
					  -111.92683,
					  44.36058
					],
					[
					  -111.92744,
					  44.36059
					],
					[
					  -111.93201,
					  44.36056
					],
					[
					  -111.9393,
					  44.36057
					],
					[
					  -111.93956,
					  44.36056
					],
					[
					  -111.94665,
					  44.36055
					],
					[
					  -111.94723,
					  44.36052
					],
					[
					  -111.94753,
					  44.36044
					],
					[
					  -111.94758,
					  44.36042
					],
					[
					  -111.94826,
					  44.36019
					],
					[
					  -111.94864,
					  44.36005
					],
					[
					  -111.94871,
					  44.36003
					],
					[
					  -111.95027,
					  44.35945
					],
					[
					  -111.9506,
					  44.35931
					],
					[
					  -111.95071,
					  44.35927
					],
					[
					  -111.95114,
					  44.35909
					],
					[
					  -111.95201,
					  44.35868
					],
					[
					  -111.95329,
					  44.35816
					],
					[
					  -111.9533,
					  44.35816
					],
					[
					  -111.95546,
					  44.35735
					],
					[
					  -111.95714,
					  44.35668
					],
					[
					  -111.96134,
					  44.35482
					],
					[
					  -111.96155,
					  44.35472
					],
					[
					  -111.96979,
					  44.35107
					],
					[
					  -111.97185,
					  44.35018
					],
					[
					  -111.9732,
					  44.34957
					],
					[
					  -111.97388,
					  44.34919
					],
					[
					  -111.97444,
					  44.34891
					],
					[
					  -111.9756,
					  44.34856
					],
					[
					  -111.97604,
					  44.34845
					],
					[
					  -111.97678,
					  44.34839
					],
					[
					  -111.982,
					  44.34827
					],
					[
					  -111.98366,
					  44.34826
					],
					[
					  -111.98692,
					  44.34828
					],
					[
					  -111.98788,
					  44.34818
					],
					[
					  -111.98911,
					  44.34802
					],
					[
					  -111.98992,
					  44.3479
					],
					[
					  -111.99091,
					  44.34768
					],
					[
					  -111.99097,
					  44.34766
					],
					[
					  -111.99795,
					  44.34593
					],
					[
					  -111.99936,
					  44.34561
					],
					[
					  -112.00004,
					  44.3456
					],
					[
					  -112.00039,
					  44.34561
					],
					[
					  -112.00074,
					  44.34565
					],
					[
					  -112.00128,
					  44.34576
					],
					[
					  -112.00156,
					  44.34584
					],
					[
					  -112.00761,
					  44.34778
					],
					[
					  -112.00854,
					  44.3481
					],
					[
					  -112.01183,
					  44.3491
					],
					[
					  -112.01227,
					  44.34922
					],
					[
					  -112.0123,
					  44.34922
					],
					[
					  -112.013,
					  44.34936
					],
					[
					  -112.01326,
					  44.34942
					],
					[
					  -112.01423,
					  44.34954
					],
					[
					  -112.01582,
					  44.34969
					],
					[
					  -112.01615,
					  44.34971
					],
					[
					  -112.01865,
					  44.34993
					],
					[
					  -112.01882,
					  44.34995
					],
					[
					  -112.0205,
					  44.35002
					],
					[
					  -112.021,
					  44.35002
					],
					[
					  -112.02216,
					  44.35005
					],
					[
					  -112.03129,
					  44.35042
					],
					[
					  -112.03341,
					  44.35048
					],
					[
					  -112.03372,
					  44.35048
					],
					[
					  -112.03704,
					  44.3506
					],
					[
					  -112.03717,
					  44.3506
					],
					[
					  -112.03863,
					  44.35068
					],
					[
					  -112.04397,
					  44.35109
					],
					[
					  -112.0445,
					  44.35115
					],
					[
					  -112.0453,
					  44.3512
					],
					[
					  -112.04603,
					  44.35128
					],
					[
					  -112.04629,
					  44.3513
					],
					[
					  -112.04655,
					  44.35133
					],
					[
					  -112.0473,
					  44.35137
					],
					[
					  -112.04875,
					  44.35148
					],
					[
					  -112.0498,
					  44.35152
					],
					[
					  -112.05025,
					  44.35152
					],
					[
					  -112.0516,
					  44.35145
					],
					[
					  -112.05243,
					  44.35137
					],
					[
					  -112.05304,
					  44.35127
					],
					[
					  -112.05305,
					  44.35127
					],
					[
					  -112.05329,
					  44.35124
					],
					[
					  -112.05339,
					  44.35122
					],
					[
					  -112.05359,
					  44.3512
					],
					[
					  -112.05416,
					  44.35111
					],
					[
					  -112.05472,
					  44.35104
					],
					[
					  -112.05507,
					  44.35103
					],
					[
					  -112.05534,
					  44.35104
					],
					[
					  -112.05565,
					  44.35108
					],
					[
					  -112.05595,
					  44.35116
					],
					[
					  -112.056,
					  44.35117
					],
					[
					  -112.05799,
					  44.35177
					],
					[
					  -112.05862,
					  44.35193
					],
					[
					  -112.05908,
					  44.35202
					],
					[
					  -112.05919,
					  44.35203
					],
					[
					  -112.06042,
					  44.35221
					],
					[
					  -112.06132,
					  44.35232
					],
					[
					  -112.0617,
					  44.35234
					],
					[
					  -112.06255,
					  44.3523
					],
					[
					  -112.06699,
					  44.35164
					],
					[
					  -112.06774,
					  44.35155
					],
					[
					  -112.06832,
					  44.35153
					],
					[
					  -112.06878,
					  44.35154
					],
					[
					  -112.06916,
					  44.35156
					],
					[
					  -112.07171,
					  44.35186
					],
					[
					  -112.07258,
					  44.35193
					],
					[
					  -112.07281,
					  44.35193
					],
					[
					  -112.07298,
					  44.35192
					],
					[
					  -112.07337,
					  44.35191
					],
					[
					  -112.07373,
					  44.35188
					],
					[
					  -112.07636,
					  44.35146
					],
					[
					  -112.07643,
					  44.35144
					],
					[
					  -112.08125,
					  44.35065
					],
					[
					  -112.08133,
					  44.35063
					],
					[
					  -112.08988,
					  44.34927
					],
					[
					  -112.09118,
					  44.34911
					],
					[
					  -112.09139,
					  44.3491
					],
					[
					  -112.09252,
					  44.349
					],
					[
					  -112.09297,
					  44.34898
					],
					[
					  -112.09327,
					  44.34898
					],
					[
					  -112.09353,
					  44.34899
					],
					[
					  -112.09788,
					  44.34948
					],
					[
					  -112.09864,
					  44.34955
					],
					[
					  -112.09936,
					  44.34959
					],
					[
					  -112.1051,
					  44.34962
					],
					[
					  -112.11144,
					  44.3496
					],
					[
					  -112.11686,
					  44.34963
					],
					[
					  -112.11967,
					  44.34953
					],
					[
					  -112.12249,
					  44.34949
					],
					[
					  -112.12491,
					  44.34949
					],
					[
					  -112.12686,
					  44.34955
					],
					[
					  -112.12934,
					  44.34966
					],
					[
					  -112.12981,
					  44.34969
					],
					[
					  -112.13,
					  44.34971
					],
					[
					  -112.1308,
					  44.34976
					],
					[
					  -112.13308,
					  44.34984
					],
					[
					  -112.13323,
					  44.34985
					],
					[
					  -112.13549,
					  44.34988
					],
					[
					  -112.14062,
					  44.34986
					],
					[
					  -112.14148,
					  44.34987
					],
					[
					  -112.14354,
					  44.34985
					],
					[
					  -112.14381,
					  44.34983
					],
					[
					  -112.14422,
					  44.34978
					],
					[
					  -112.14438,
					  44.34975
					],
					[
					  -112.1446,
					  44.34972
					],
					[
					  -112.14489,
					  44.34966
					],
					[
					  -112.14578,
					  44.34952
					],
					[
					  -112.1466,
					  44.34942
					],
					[
					  -112.14662,
					  44.34941
					],
					[
					  -112.15086,
					  44.34892
					],
					[
					  -112.15106,
					  44.34889
					],
					[
					  -112.15111,
					  44.34889
					],
					[
					  -112.15434,
					  44.34851
					],
					[
					  -112.15508,
					  44.34844
					],
					[
					  -112.15532,
					  44.34841
					],
					[
					  -112.15533,
					  44.34841
					],
					[
					  -112.15558,
					  44.34838
					],
					[
					  -112.15565,
					  44.34838
					],
					[
					  -112.15722,
					  44.34823
					],
					[
					  -112.15733,
					  44.34823
					],
					[
					  -112.15858,
					  44.34813
					],
					[
					  -112.1593,
					  44.34805
					],
					[
					  -112.1603,
					  44.34797
					],
					[
					  -112.16117,
					  44.34787
					],
					[
					  -112.16154,
					  44.34781
					],
					[
					  -112.16156,
					  44.3478
					],
					[
					  -112.16177,
					  44.34775
					],
					[
					  -112.16189,
					  44.34771
					],
					[
					  -112.16226,
					  44.34765
					],
					[
					  -112.16267,
					  44.34762
					],
					[
					  -112.16326,
					  44.3476
					],
					[
					  -112.16416,
					  44.34761
					],
					[
					  -112.16448,
					  44.34764
					],
					[
					  -112.16451,
					  44.34765
					],
					[
					  -112.16478,
					  44.3477
					],
					[
					  -112.16546,
					  44.3479
					],
					[
					  -112.16568,
					  44.34798
					],
					[
					  -112.16578,
					  44.34801
					],
					[
					  -112.16657,
					  44.34831
					],
					[
					  -112.16683,
					  44.34842
					],
					[
					  -112.16881,
					  44.34919
					],
					[
					  -112.16893,
					  44.34923
					],
					[
					  -112.1703,
					  44.34981
					],
					[
					  -112.17261,
					  44.35074
					],
					[
					  -112.17308,
					  44.35094
					],
					[
					  -112.17389,
					  44.35133
					],
					[
					  -112.17459,
					  44.35175
					],
					[
					  -112.17513,
					  44.35209
					],
					[
					  -112.17522,
					  44.35214
					],
					[
					  -112.17578,
					  44.35254
					],
					[
					  -112.176,
					  44.35271
					],
					[
					  -112.17663,
					  44.35324
					],
					[
					  -112.17733,
					  44.35397
					],
					[
					  -112.17759,
					  44.35421
					],
					[
					  -112.17772,
					  44.35431
					],
					[
					  -112.17778,
					  44.35433
					],
					[
					  -112.17793,
					  44.35441
					],
					[
					  -112.17808,
					  44.35446
					],
					[
					  -112.17827,
					  44.35451
					],
					[
					  -112.17845,
					  44.35453
					],
					[
					  -112.17857,
					  44.35456
					],
					[
					  -112.17872,
					  44.35458
					],
					[
					  -112.17914,
					  44.35468
					],
					[
					  -112.17932,
					  44.35474
					],
					[
					  -112.17947,
					  44.3548
					],
					[
					  -112.17951,
					  44.35481
					],
					[
					  -112.1799,
					  44.35504
					],
					[
					  -112.17993,
					  44.35505
					],
					[
					  -112.18019,
					  44.3552
					],
					[
					  -112.18021,
					  44.35522
					],
					[
					  -112.18049,
					  44.3554
					],
					[
					  -112.18069,
					  44.35555
					],
					[
					  -112.1808,
					  44.35566
					],
					[
					  -112.18094,
					  44.35582
					],
					[
					  -112.18117,
					  44.35613
					],
					[
					  -112.18133,
					  44.35637
					],
					[
					  -112.18141,
					  44.35644
					],
					[
					  -112.18145,
					  44.35648
					],
					[
					  -112.18151,
					  44.35652
					],
					[
					  -112.1816,
					  44.35656
					],
					[
					  -112.18168,
					  44.35662
					],
					[
					  -112.18187,
					  44.35671
					],
					[
					  -112.18229,
					  44.35688
					],
					[
					  -112.18256,
					  44.35697
					],
					[
					  -112.18271,
					  44.35705
					],
					[
					  -112.18299,
					  44.35703
					],
					[
					  -112.18304,
					  44.35703
					],
					[
					  -112.18328,
					  44.35698
					],
					[
					  -112.18367,
					  44.35688
					],
					[
					  -112.1839,
					  44.35733
					],
					[
					  -112.18391,
					  44.35734
					],
					[
					  -112.18403,
					  44.35765
					],
					[
					  -112.18415,
					  44.35784
					],
					[
					  -112.18582,
					  44.35734
					],
					[
					  -112.18605,
					  44.35728
					],
					[
					  -112.18606,
					  44.35728
					],
					[
					  -112.18629,
					  44.35722
					],
					[
					  -112.1863,
					  44.35722
					],
					[
					  -112.18651,
					  44.35716
					],
					[
					  -112.18654,
					  44.35716
					],
					[
					  -112.18676,
					  44.3571
					],
					[
					  -112.18678,
					  44.35709
					],
					[
					  -112.18689,
					  44.35706
					],
					[
					  -112.187,
					  44.35704
					],
					[
					  -112.18702,
					  44.35703
					],
					[
					  -112.18723,
					  44.35698
					],
					[
					  -112.18741,
					  44.35693
					],
					[
					  -112.1868,
					  44.35641
					],
					[
					  -112.1868,
					  44.3564
					],
					[
					  -112.18678,
					  44.35639
					],
					[
					  -112.18671,
					  44.35632
					],
					[
					  -112.18668,
					  44.3563
					],
					[
					  -112.1865,
					  44.35612
					],
					[
					  -112.18629,
					  44.35588
					],
					[
					  -112.18606,
					  44.35557
					],
					[
					  -112.18572,
					  44.35508
					],
					[
					  -112.18561,
					  44.35502
					],
					[
					  -112.18524,
					  44.35445
					],
					[
					  -112.18523,
					  44.35444
					],
					[
					  -112.18508,
					  44.3542
					],
					[
					  -112.18499,
					  44.35408
					],
					[
					  -112.18395,
					  44.35246
					],
					[
					  -112.18386,
					  44.35233
					],
					[
					  -112.18363,
					  44.35196
					],
					[
					  -112.18338,
					  44.35159
					],
					[
					  -112.18219,
					  44.34974
					],
					[
					  -112.18217,
					  44.34972
					],
					[
					  -112.18209,
					  44.34959
					],
					[
					  -112.18209,
					  44.34958
					],
					[
					  -112.18198,
					  44.34942
					],
					[
					  -112.18194,
					  44.34935
					],
					[
					  -112.18187,
					  44.34925
					],
					[
					  -112.18133,
					  44.3484
					],
					[
					  -112.1813,
					  44.34836
					],
					[
					  -112.18113,
					  44.3481
					],
					[
					  -112.18106,
					  44.34798
					],
					[
					  -112.18097,
					  44.34785
					],
					[
					  -112.1809,
					  44.34773
					],
					[
					  -112.17996,
					  44.34628
					],
					[
					  -112.17993,
					  44.34624
					],
					[
					  -112.1787,
					  44.34432
					],
					[
					  -112.17867,
					  44.34425
					],
					[
					  -112.1786,
					  44.34415
					],
					[
					  -112.17837,
					  44.34373
					],
					[
					  -112.17832,
					  44.34362
					],
					[
					  -112.1783,
					  44.34359
					],
					[
					  -112.17806,
					  44.3431
					],
					[
					  -112.17801,
					  44.34297
					],
					[
					  -112.17789,
					  44.34271
					],
					[
					  -112.17774,
					  44.34231
					],
					[
					  -112.17773,
					  44.3423
					],
					[
					  -112.17769,
					  44.34218
					],
					[
					  -112.17768,
					  44.34216
					],
					[
					  -112.17759,
					  44.34192
					],
					[
					  -112.17758,
					  44.3419
					],
					[
					  -112.17754,
					  44.34178
					],
					[
					  -112.17754,
					  44.34176
					],
					[
					  -112.1775,
					  44.34165
					],
					[
					  -112.17749,
					  44.34163
					],
					[
					  -112.17745,
					  44.34152
					],
					[
					  -112.17744,
					  44.3415
					],
					[
					  -112.17741,
					  44.34141
					],
					[
					  -112.1774,
					  44.34139
					],
					[
					  -112.1774,
					  44.34137
					],
					[
					  -112.17736,
					  44.34125
					],
					[
					  -112.17735,
					  44.34123
					],
					[
					  -112.17734,
					  44.34123
					],
					[
					  -112.17731,
					  44.34112
					],
					[
					  -112.1773,
					  44.3411
					],
					[
					  -112.17716,
					  44.34072
					],
					[
					  -112.17712,
					  44.34059
					],
					[
					  -112.17707,
					  44.34046
					],
					[
					  -112.17706,
					  44.34044
					],
					[
					  -112.17692,
					  44.34006
					],
					[
					  -112.17687,
					  44.3399
					],
					[
					  -112.17673,
					  44.33953
					],
					[
					  -112.17673,
					  44.3395
					],
					[
					  -112.17669,
					  44.33939
					],
					[
					  -112.17668,
					  44.33937
					],
					[
					  -112.1763,
					  44.33833
					],
					[
					  -112.17626,
					  44.33819
					],
					[
					  -112.17621,
					  44.33806
					],
					[
					  -112.17612,
					  44.33779
					],
					[
					  -112.17607,
					  44.33766
					],
					[
					  -112.17603,
					  44.33753
					],
					[
					  -112.17588,
					  44.33713
					],
					[
					  -112.17583,
					  44.33698
					],
					[
					  -112.17578,
					  44.33686
					],
					[
					  -112.17534,
					  44.33567
					],
					[
					  -112.1753,
					  44.33554
					],
					[
					  -112.17525,
					  44.3354
					],
					[
					  -112.17521,
					  44.33527
					],
					[
					  -112.17516,
					  44.33514
					],
					[
					  -112.17512,
					  44.335
					],
					[
					  -112.17497,
					  44.33459
					],
					[
					  -112.17497,
					  44.33457
					],
					[
					  -112.17487,
					  44.3343
					],
					[
					  -112.17484,
					  44.3342
					],
					[
					  -112.17479,
					  44.33406
					],
					[
					  -112.17478,
					  44.33404
					],
					[
					  -112.17474,
					  44.33393
					],
					[
					  -112.17474,
					  44.3339
					],
					[
					  -112.17473,
					  44.33387
					],
					[
					  -112.1747,
					  44.3338
					],
					[
					  -112.1747,
					  44.33377
					],
					[
					  -112.17462,
					  44.3335
					],
					[
					  -112.1746,
					  44.33339
					],
					[
					  -112.17459,
					  44.33337
					],
					[
					  -112.17457,
					  44.33326
					],
					[
					  -112.17457,
					  44.33323
					],
					[
					  -112.17455,
					  44.33312
					],
					[
					  -112.17454,
					  44.3331
					],
					[
					  -112.17452,
					  44.33296
					],
					[
					  -112.17452,
					  44.33293
					],
					[
					  -112.17448,
					  44.33251
					],
					[
					  -112.17447,
					  44.33204
					],
					[
					  -112.17449,
					  44.33176
					],
					[
					  -112.17449,
					  44.33173
					],
					[
					  -112.17451,
					  44.33149
					],
					[
					  -112.17452,
					  44.33147
					],
					[
					  -112.17458,
					  44.33108
					],
					[
					  -112.17462,
					  44.33095
					],
					[
					  -112.17468,
					  44.33068
					],
					[
					  -112.17469,
					  44.33067
					],
					[
					  -112.17472,
					  44.33055
					],
					[
					  -112.17473,
					  44.33053
					],
					[
					  -112.17477,
					  44.33042
					],
					[
					  -112.17477,
					  44.3304
					],
					[
					  -112.17483,
					  44.33026
					],
					[
					  -112.17487,
					  44.33015
					],
					[
					  -112.17487,
					  44.33014
					],
					[
					  -112.17488,
					  44.3301
					],
					[
					  -112.17498,
					  44.32989
					],
					[
					  -112.17498,
					  44.32988
					],
					[
					  -112.17503,
					  44.32976
					],
					[
					  -112.17504,
					  44.32975
					],
					[
					  -112.17512,
					  44.32959
					],
					[
					  -112.17516,
					  44.3295
					],
					[
					  -112.17518,
					  44.32948
					],
					[
					  -112.17523,
					  44.32938
					],
					[
					  -112.17531,
					  44.32925
					],
					[
					  -112.17532,
					  44.32923
					],
					[
					  -112.17546,
					  44.329
					],
					[
					  -112.17548,
					  44.32898
					],
					[
					  -112.17555,
					  44.32888
					],
					[
					  -112.17556,
					  44.32886
					],
					[
					  -112.17563,
					  44.32876
					],
					[
					  -112.17564,
					  44.32874
					],
					[
					  -112.17572,
					  44.32864
					],
					[
					  -112.17573,
					  44.32862
					],
					[
					  -112.17574,
					  44.32861
					],
					[
					  -112.17581,
					  44.32852
					],
					[
					  -112.17583,
					  44.3285
					],
					[
					  -112.17591,
					  44.3284
					],
					[
					  -112.17592,
					  44.32838
					],
					[
					  -112.17599,
					  44.3283
					],
					[
					  -112.176,
					  44.32828
					],
					[
					  -112.17602,
					  44.32826
					],
					[
					  -112.1761,
					  44.32816
					],
					[
					  -112.17613,
					  44.32814
					],
					[
					  -112.17642,
					  44.32782
					],
					[
					  -112.17645,
					  44.3278
					],
					[
					  -112.17665,
					  44.3276
					],
					[
					  -112.17668,
					  44.32758
					],
					[
					  -112.17677,
					  44.32749
					],
					[
					  -112.1768,
					  44.32747
					],
					[
					  -112.17701,
					  44.32728
					],
					[
					  -112.17702,
					  44.32728
					],
					[
					  -112.17704,
					  44.32726
					],
					[
					  -112.17714,
					  44.32718
					],
					[
					  -112.17716,
					  44.32716
					],
					[
					  -112.17726,
					  44.32708
					],
					[
					  -112.17729,
					  44.32705
					],
					[
					  -112.17739,
					  44.32697
					],
					[
					  -112.17741,
					  44.32695
					],
					[
					  -112.17751,
					  44.32687
					],
					[
					  -112.17754,
					  44.32684
					],
					[
					  -112.17764,
					  44.32676
					],
					[
					  -112.17766,
					  44.32674
					],
					[
					  -112.17776,
					  44.32666
					],
					[
					  -112.17779,
					  44.32664
					],
					[
					  -112.17791,
					  44.32653
					],
					[
					  -112.17801,
					  44.32645
					],
					[
					  -112.17803,
					  44.32643
					],
					[
					  -112.17813,
					  44.32635
					],
					[
					  -112.17816,
					  44.32633
					],
					[
					  -112.17828,
					  44.32622
					],
					[
					  -112.17838,
					  44.32614
					],
					[
					  -112.1784,
					  44.32612
					],
					[
					  -112.17851,
					  44.32604
					],
					[
					  -112.17863,
					  44.32593
					],
					[
					  -112.17865,
					  44.32592
					],
					[
					  -112.17876,
					  44.32583
					],
					[
					  -112.17888,
					  44.32572
					],
					[
					  -112.1789,
					  44.32571
					],
					[
					  -112.17901,
					  44.32562
					],
					[
					  -112.17903,
					  44.3256
					],
					[
					  -112.17913,
					  44.32552
					],
					[
					  -112.17915,
					  44.3255
					],
					[
					  -112.17926,
					  44.32541
					],
					[
					  -112.17928,
					  44.32539
					],
					[
					  -112.17938,
					  44.32531
					],
					[
					  -112.17941,
					  44.32529
					],
					[
					  -112.17951,
					  44.3252
					],
					[
					  -112.17953,
					  44.32519
					],
					[
					  -112.17965,
					  44.32508
					],
					[
					  -112.17976,
					  44.32499
					],
					[
					  -112.17978,
					  44.32498
					],
					[
					  -112.17988,
					  44.32489
					],
					[
					  -112.1799,
					  44.32488
					],
					[
					  -112.18001,
					  44.32479
					],
					[
					  -112.18013,
					  44.32468
					],
					[
					  -112.18015,
					  44.32467
					],
					[
					  -112.18026,
					  44.32458
					],
					[
					  -112.18029,
					  44.32455
					],
					[
					  -112.18038,
					  44.32448
					],
					[
					  -112.1804,
					  44.32446
					],
					[
					  -112.18051,
					  44.32437
					],
					[
					  -112.18053,
					  44.32435
					],
					[
					  -112.18063,
					  44.32427
					],
					[
					  -112.18075,
					  44.32416
					],
					[
					  -112.18078,
					  44.32414
					],
					[
					  -112.18088,
					  44.32406
					],
					[
					  -112.1809,
					  44.32404
					],
					[
					  -112.181,
					  44.32396
					],
					[
					  -112.18103,
					  44.32394
					],
					[
					  -112.18115,
					  44.32383
					],
					[
					  -112.18126,
					  44.32375
					],
					[
					  -112.1814,
					  44.32362
					],
					[
					  -112.1815,
					  44.32354
					],
					[
					  -112.18153,
					  44.32352
					],
					[
					  -112.18163,
					  44.32344
					],
					[
					  -112.18175,
					  44.32333
					],
					[
					  -112.18177,
					  44.32332
					],
					[
					  -112.18177,
					  44.32331
					],
					[
					  -112.18188,
					  44.32323
					],
					[
					  -112.18193,
					  44.32318
					],
					[
					  -112.182,
					  44.32312
					],
					[
					  -112.18203,
					  44.3231
					],
					[
					  -112.18213,
					  44.32302
					],
					[
					  -112.18215,
					  44.323
					],
					[
					  -112.18225,
					  44.32292
					],
					[
					  -112.18227,
					  44.3229
					],
					[
					  -112.18238,
					  44.32281
					],
					[
					  -112.1824,
					  44.32279
					],
					[
					  -112.1825,
					  44.32271
					],
					[
					  -112.18253,
					  44.32269
					],
					[
					  -112.18265,
					  44.32258
					],
					[
					  -112.18275,
					  44.3225
					],
					[
					  -112.18277,
					  44.32248
					],
					[
					  -112.18287,
					  44.3224
					],
					[
					  -112.1829,
					  44.32238
					],
					[
					  -112.183,
					  44.32229
					],
					[
					  -112.18303,
					  44.32227
					],
					[
					  -112.18313,
					  44.32219
					],
					[
					  -112.18315,
					  44.32217
					],
					[
					  -112.18325,
					  44.32209
					],
					[
					  -112.18327,
					  44.32206
					],
					[
					  -112.18337,
					  44.32198
					],
					[
					  -112.1834,
					  44.32196
					],
					[
					  -112.1835,
					  44.32188
					],
					[
					  -112.18352,
					  44.32186
					],
					[
					  -112.18362,
					  44.32178
					],
					[
					  -112.18365,
					  44.32175
					],
					[
					  -112.18375,
					  44.32167
					],
					[
					  -112.18377,
					  44.32165
					],
					[
					  -112.18387,
					  44.32157
					],
					[
					  -112.1839,
					  44.32155
					],
					[
					  -112.18399,
					  44.32147
					],
					[
					  -112.18402,
					  44.32145
					],
					[
					  -112.18414,
					  44.32135
					],
					[
					  -112.18427,
					  44.32123
					],
					[
					  -112.18437,
					  44.32115
					],
					[
					  -112.18439,
					  44.32113
					],
					[
					  -112.18449,
					  44.32105
					],
					[
					  -112.18452,
					  44.32103
					],
					[
					  -112.18462,
					  44.32095
					],
					[
					  -112.18464,
					  44.32092
					],
					[
					  -112.18475,
					  44.32084
					],
					[
					  -112.18477,
					  44.32082
					],
					[
					  -112.18487,
					  44.32074
					],
					[
					  -112.18499,
					  44.32063
					],
					[
					  -112.18502,
					  44.32061
					],
					[
					  -112.18512,
					  44.32053
					],
					[
					  -112.18524,
					  44.32042
					],
					[
					  -112.18527,
					  44.3204
					],
					[
					  -112.18537,
					  44.32032
					],
					[
					  -112.18539,
					  44.3203
					],
					[
					  -112.18549,
					  44.32022
					],
					[
					  -112.18552,
					  44.3202
					],
					[
					  -112.18564,
					  44.32009
					],
					[
					  -112.18574,
					  44.32001
					],
					[
					  -112.18577,
					  44.31999
					],
					[
					  -112.18586,
					  44.3199
					],
					[
					  -112.18589,
					  44.31988
					],
					[
					  -112.18599,
					  44.3198
					],
					[
					  -112.18602,
					  44.31978
					],
					[
					  -112.18614,
					  44.31967
					],
					[
					  -112.18624,
					  44.31959
					],
					[
					  -112.18626,
					  44.31957
					],
					[
					  -112.18639,
					  44.31947
					],
					[
					  -112.18836,
					  44.31782
					],
					[
					  -112.18849,
					  44.31772
					],
					[
					  -112.18912,
					  44.31719
					],
					[
					  -112.18937,
					  44.31699
					],
					[
					  -112.19268,
					  44.31422
					],
					[
					  -112.19285,
					  44.31409
					],
					[
					  -112.19473,
					  44.31252
					],
					[
					  -112.19497,
					  44.31231
					],
					[
					  -112.19584,
					  44.31159
					],
					[
					  -112.19627,
					  44.31122
					],
					[
					  -112.19635,
					  44.31116
					],
					[
					  -112.19643,
					  44.31108
					],
					[
					  -112.1967,
					  44.31085
					],
					[
					  -112.19704,
					  44.31054
					],
					[
					  -112.19714,
					  44.31044
					],
					[
					  -112.19792,
					  44.30971
					],
					[
					  -112.19809,
					  44.30953
					],
					[
					  -112.19812,
					  44.30951
					],
					[
					  -112.19887,
					  44.30874
					],
					[
					  -112.19895,
					  44.30865
					],
					[
					  -112.19907,
					  44.30853
					],
					[
					  -112.19948,
					  44.30807
					],
					[
					  -112.19956,
					  44.30799
					],
					[
					  -112.19973,
					  44.3078
					],
					[
					  -112.20047,
					  44.30691
					],
					[
					  -112.20056,
					  44.30679
					],
					[
					  -112.20057,
					  44.30679
					],
					[
					  -112.2008,
					  44.30649
					],
					[
					  -112.20081,
					  44.30648
					],
					[
					  -112.20131,
					  44.30583
					],
					[
					  -112.20134,
					  44.30578
					],
					[
					  -112.20141,
					  44.3057
					],
					[
					  -112.20266,
					  44.30405
					],
					[
					  -112.20279,
					  44.30389
					],
					[
					  -112.20288,
					  44.30376
					],
					[
					  -112.20315,
					  44.3034
					],
					[
					  -112.20325,
					  44.30328
					],
					[
					  -112.20452,
					  44.3016
					],
					[
					  -112.20462,
					  44.30148
					],
					[
					  -112.20499,
					  44.30099
					],
					[
					  -112.20502,
					  44.30094
					],
					[
					  -112.20516,
					  44.30077
					],
					[
					  -112.20579,
					  44.29993
					],
					[
					  -112.20589,
					  44.29981
					],
					[
					  -112.20607,
					  44.29956
					],
					[
					  -112.20611,
					  44.29952
					],
					[
					  -112.2067,
					  44.29873
					],
					[
					  -112.20679,
					  44.2986
					],
					[
					  -112.20706,
					  44.29824
					],
					[
					  -112.20714,
					  44.29811
					],
					[
					  -112.20723,
					  44.29799
					],
					[
					  -112.20738,
					  44.29776
					],
					[
					  -112.2074,
					  44.29774
					],
					[
					  -112.20796,
					  44.29688
					],
					[
					  -112.20819,
					  44.2965
					],
					[
					  -112.20827,
					  44.29638
					],
					[
					  -112.20834,
					  44.29625
					],
					[
					  -112.20849,
					  44.296
					],
					[
					  -112.20912,
					  44.29483
					],
					[
					  -112.20918,
					  44.2947
					],
					[
					  -112.20925,
					  44.29458
					],
					[
					  -112.20931,
					  44.29444
					],
					[
					  -112.20938,
					  44.29431
					],
					[
					  -112.2098,
					  44.29341
					],
					[
					  -112.20985,
					  44.29328
					],
					[
					  -112.2103,
					  44.29223
					],
					[
					  -112.21042,
					  44.29197
					],
					[
					  -112.21047,
					  44.29183
					],
					[
					  -112.21053,
					  44.29171
					],
					[
					  -112.21056,
					  44.29163
					],
					[
					  -112.21076,
					  44.29118
					],
					[
					  -112.21081,
					  44.29105
					],
					[
					  -112.21093,
					  44.29079
					],
					[
					  -112.21126,
					  44.29
					],
					[
					  -112.21138,
					  44.28974
					],
					[
					  -112.21145,
					  44.28956
					],
					[
					  -112.21149,
					  44.28948
					],
					[
					  -112.2116,
					  44.28921
					],
					[
					  -112.21166,
					  44.28908
					],
					[
					  -112.21171,
					  44.28895
					],
					[
					  -112.21403,
					  44.28348
					],
					[
					  -112.21404,
					  44.28347
					],
					[
					  -112.2141,
					  44.28331
					],
					[
					  -112.2143,
					  44.28291
					],
					[
					  -112.21458,
					  44.28226
					],
					[
					  -112.2147,
					  44.282
					],
					[
					  -112.21492,
					  44.28147
					],
					[
					  -112.21508,
					  44.28112
					],
					[
					  -112.21537,
					  44.28044
					],
					[
					  -112.21541,
					  44.28033
					],
					[
					  -112.21633,
					  44.2782
					],
					[
					  -112.21644,
					  44.27792
					],
					[
					  -112.21821,
					  44.27375
					],
					[
					  -112.21869,
					  44.27272
					],
					[
					  -112.21884,
					  44.27236
					],
					[
					  -112.21885,
					  44.27233
					],
					[
					  -112.21902,
					  44.27192
					],
					[
					  -112.21919,
					  44.27155
					],
					[
					  -112.21925,
					  44.27139
					],
					[
					  -112.21928,
					  44.27133
					],
					[
					  -112.22063,
					  44.26817
					],
					[
					  -112.22129,
					  44.2667
					],
					[
					  -112.22218,
					  44.26456
					],
					[
					  -112.22226,
					  44.26439
					],
					[
					  -112.22233,
					  44.26421
					],
					[
					  -112.22434,
					  44.25951
					],
					[
					  -112.22439,
					  44.25938
					],
					[
					  -112.22451,
					  44.25912
					],
					[
					  -112.22456,
					  44.25899
					],
					[
					  -112.22468,
					  44.25872
					],
					[
					  -112.22473,
					  44.25859
					],
					[
					  -112.22485,
					  44.25833
					],
					[
					  -112.2249,
					  44.2582
					],
					[
					  -112.22496,
					  44.25807
					],
					[
					  -112.22501,
					  44.25793
					],
					[
					  -112.22519,
					  44.25754
					],
					[
					  -112.22524,
					  44.25741
					],
					[
					  -112.2253,
					  44.25728
					],
					[
					  -112.22535,
					  44.25715
					],
					[
					  -112.22547,
					  44.25688
					],
					[
					  -112.22552,
					  44.25675
					],
					[
					  -112.22564,
					  44.25649
					],
					[
					  -112.22569,
					  44.25635
					],
					[
					  -112.22586,
					  44.25596
					],
					[
					  -112.22591,
					  44.25583
					],
					[
					  -112.22614,
					  44.25531
					],
					[
					  -112.22619,
					  44.25517
					],
					[
					  -112.22625,
					  44.25504
					],
					[
					  -112.22635,
					  44.25479
					],
					[
					  -112.22638,
					  44.2547
					],
					[
					  -112.22641,
					  44.25464
					],
					[
					  -112.22645,
					  44.25452
					],
					[
					  -112.2267,
					  44.25386
					],
					[
					  -112.22674,
					  44.25372
					],
					[
					  -112.22679,
					  44.25359
					],
					[
					  -112.22683,
					  44.25346
					],
					[
					  -112.22687,
					  44.25335
					],
					[
					  -112.22692,
					  44.25319
					],
					[
					  -112.22695,
					  44.25312
					],
					[
					  -112.22717,
					  44.25239
					],
					[
					  -112.2272,
					  44.25226
					],
					[
					  -112.22728,
					  44.25199
					],
					[
					  -112.22732,
					  44.25182
					],
					[
					  -112.22735,
					  44.25172
					],
					[
					  -112.22738,
					  44.25159
					],
					[
					  -112.22738,
					  44.25157
					],
					[
					  -112.22748,
					  44.25118
					],
					[
					  -112.2275,
					  44.25106
					],
					[
					  -112.22754,
					  44.25091
					],
					[
					  -112.22757,
					  44.25077
					],
					[
					  -112.22759,
					  44.25065
					],
					[
					  -112.22761,
					  44.25057
					],
					[
					  -112.22767,
					  44.25024
					],
					[
					  -112.22773,
					  44.24997
					],
					[
					  -112.22775,
					  44.24983
					],
					[
					  -112.22778,
					  44.2497
					],
					[
					  -112.2278,
					  44.24956
					],
					[
					  -112.22786,
					  44.24929
					],
					[
					  -112.22788,
					  44.24915
					],
					[
					  -112.22791,
					  44.24902
					],
					[
					  -112.22793,
					  44.24888
					],
					[
					  -112.22799,
					  44.24861
					],
					[
					  -112.22801,
					  44.24848
					],
					[
					  -112.22804,
					  44.24834
					],
					[
					  -112.22806,
					  44.2482
					],
					[
					  -112.22809,
					  44.24807
					],
					[
					  -112.22811,
					  44.24794
					],
					[
					  -112.22817,
					  44.24766
					],
					[
					  -112.22819,
					  44.24752
					],
					[
					  -112.22822,
					  44.24738
					],
					[
					  -112.22824,
					  44.24724
					],
					[
					  -112.2283,
					  44.24699
					],
					[
					  -112.22832,
					  44.24684
					],
					[
					  -112.2284,
					  44.24642
					],
					[
					  -112.22843,
					  44.24629
					],
					[
					  -112.22845,
					  44.24615
					],
					[
					  -112.22848,
					  44.24602
					],
					[
					  -112.2285,
					  44.24588
					],
					[
					  -112.22861,
					  44.24533
					],
					[
					  -112.22868,
					  44.24493
					],
					[
					  -112.22871,
					  44.2448
					],
					[
					  -112.22896,
					  44.24344
					],
					[
					  -112.22899,
					  44.24331
					],
					[
					  -112.22901,
					  44.24317
					],
					[
					  -112.22904,
					  44.24303
					],
					[
					  -112.22906,
					  44.2429
					],
					[
					  -112.22912,
					  44.24262
					],
					[
					  -112.22914,
					  44.24249
					],
					[
					  -112.22922,
					  44.24208
					],
					[
					  -112.22926,
					  44.24181
					],
					[
					  -112.22932,
					  44.24154
					],
					[
					  -112.22939,
					  44.24113
					],
					[
					  -112.22942,
					  44.241
					],
					[
					  -112.22944,
					  44.24086
					],
					[
					  -112.22947,
					  44.24073
					],
					[
					  -112.22949,
					  44.24059
					],
					[
					  -112.22954,
					  44.24037
					],
					[
					  -112.22954,
					  44.24032
					],
					[
					  -112.22957,
					  44.24019
					],
					[
					  -112.22959,
					  44.24005
					],
					[
					  -112.22963,
					  44.23989
					],
					[
					  -112.22967,
					  44.23964
					],
					[
					  -112.22977,
					  44.23912
					],
					[
					  -112.22978,
					  44.23904
					],
					[
					  -112.22998,
					  44.23801
					],
					[
					  -112.23005,
					  44.2376
					],
					[
					  -112.23016,
					  44.23706
					],
					[
					  -112.23018,
					  44.23693
					],
					[
					  -112.23021,
					  44.23679
					],
					[
					  -112.23023,
					  44.23665
					],
					[
					  -112.2303,
					  44.23633
					],
					[
					  -112.23031,
					  44.23624
					],
					[
					  -112.23034,
					  44.23611
					],
					[
					  -112.23036,
					  44.23597
					],
					[
					  -112.23039,
					  44.23584
					],
					[
					  -112.23041,
					  44.2357
					],
					[
					  -112.23044,
					  44.23557
					],
					[
					  -112.23046,
					  44.23543
					],
					[
					  -112.23049,
					  44.2353
					],
					[
					  -112.23051,
					  44.23516
					],
					[
					  -112.23062,
					  44.23462
					],
					[
					  -112.23064,
					  44.23448
					],
					[
					  -112.23067,
					  44.23434
					],
					[
					  -112.23069,
					  44.2342
					],
					[
					  -112.23069,
					  44.23419
					],
					[
					  -112.23072,
					  44.23407
					],
					[
					  -112.23074,
					  44.23393
					],
					[
					  -112.23077,
					  44.2338
					],
					[
					  -112.23084,
					  44.2334
					],
					[
					  -112.2309,
					  44.23312
					],
					[
					  -112.23092,
					  44.23299
					],
					[
					  -112.23095,
					  44.23284
					],
					[
					  -112.23098,
					  44.23265
					],
					[
					  -112.23108,
					  44.23217
					],
					[
					  -112.23235,
					  44.22539
					],
					[
					  -112.23238,
					  44.22527
					],
					[
					  -112.23244,
					  44.2249
					],
					[
					  -112.23254,
					  44.22442
					],
					[
					  -112.23273,
					  44.22336
					],
					[
					  -112.23279,
					  44.22307
					],
					[
					  -112.23287,
					  44.2226
					],
					[
					  -112.233,
					  44.22193
					],
					[
					  -112.23301,
					  44.22186
					],
					[
					  -112.23302,
					  44.22183
					],
					[
					  -112.23331,
					  44.22027
					],
					[
					  -112.23334,
					  44.22014
					],
					[
					  -112.23337,
					  44.21994
					],
					[
					  -112.23358,
					  44.21887
					],
					[
					  -112.2336,
					  44.21872
					],
					[
					  -112.23364,
					  44.21856
					],
					[
					  -112.23376,
					  44.21792
					],
					[
					  -112.23378,
					  44.21778
					],
					[
					  -112.23385,
					  44.2174
					],
					[
					  -112.23391,
					  44.21713
					],
					[
					  -112.23393,
					  44.21699
					],
					[
					  -112.23412,
					  44.216
					],
					[
					  -112.23413,
					  44.21591
					],
					[
					  -112.23419,
					  44.21563
					],
					[
					  -112.23426,
					  44.2152
					],
					[
					  -112.23427,
					  44.21516
					],
					[
					  -112.23428,
					  44.21509
					],
					[
					  -112.23429,
					  44.21507
					],
					[
					  -112.23429,
					  44.21506
					],
					[
					  -112.2343,
					  44.21499
					],
					[
					  -112.23437,
					  44.21465
					],
					[
					  -112.23437,
					  44.21464
					],
					[
					  -112.23446,
					  44.21418
					],
					[
					  -112.2345,
					  44.21393
					],
					[
					  -112.23454,
					  44.21375
					],
					[
					  -112.23462,
					  44.2133
					],
					[
					  -112.23464,
					  44.21322
					],
					[
					  -112.23465,
					  44.21315
					],
					[
					  -112.23469,
					  44.21297
					],
					[
					  -112.23472,
					  44.21277
					],
					[
					  -112.23487,
					  44.21197
					],
					[
					  -112.23488,
					  44.21196
					],
					[
					  -112.23491,
					  44.21179
					],
					[
					  -112.23491,
					  44.21177
					],
					[
					  -112.23501,
					  44.21126
					],
					[
					  -112.23501,
					  44.21124
					],
					[
					  -112.23503,
					  44.21112
					],
					[
					  -112.23508,
					  44.2109
					],
					[
					  -112.23514,
					  44.21055
					],
					[
					  -112.23518,
					  44.21037
					],
					[
					  -112.23518,
					  44.21035
					],
					[
					  -112.23527,
					  44.20989
					],
					[
					  -112.23531,
					  44.20964
					],
					[
					  -112.23538,
					  44.2093
					],
					[
					  -112.2354,
					  44.20917
					],
					[
					  -112.23562,
					  44.20802
					],
					[
					  -112.23564,
					  44.20787
					],
					[
					  -112.23615,
					  44.20511
					],
					[
					  -112.23616,
					  44.20507
					],
					[
					  -112.23636,
					  44.20399
					],
					[
					  -112.23643,
					  44.20366
					],
					[
					  -112.23716,
					  44.19977
					],
					[
					  -112.23717,
					  44.19975
					],
					[
					  -112.23799,
					  44.19539
					],
					[
					  -112.23803,
					  44.19514
					],
					[
					  -112.23862,
					  44.19199
					],
					[
					  -112.23868,
					  44.19171
					],
					[
					  -112.23946,
					  44.1876
					],
					[
					  -112.23947,
					  44.18751
					],
					[
					  -112.23957,
					  44.187
					],
					[
					  -112.23959,
					  44.18686
					],
					[
					  -112.23962,
					  44.18673
					],
					[
					  -112.24005,
					  44.18442
					],
					[
					  -112.24007,
					  44.18428
					],
					[
					  -112.24012,
					  44.18401
					],
					[
					  -112.24015,
					  44.18388
					],
					[
					  -112.24017,
					  44.18374
					],
					[
					  -112.2402,
					  44.18361
					],
					[
					  -112.24024,
					  44.18334
					],
					[
					  -112.24027,
					  44.1832
					],
					[
					  -112.24041,
					  44.18223
					],
					[
					  -112.24042,
					  44.1821
					],
					[
					  -112.24046,
					  44.18182
					],
					[
					  -112.24049,
					  44.1814
					],
					[
					  -112.24051,
					  44.18127
					],
					[
					  -112.24054,
					  44.18086
					],
					[
					  -112.24055,
					  44.18063
					],
					[
					  -112.24062,
					  44.18057
					],
					[
					  -112.24062,
					  44.18052
					],
					[
					  -112.24064,
					  44.18041
					],
					[
					  -112.24075,
					  44.17908
					],
					[
					  -112.24078,
					  44.17881
					],
					[
					  -112.24078,
					  44.17878
					],
					[
					  -112.24079,
					  44.17868
					],
					[
					  -112.2408,
					  44.17863
					],
					[
					  -112.24081,
					  44.1786
					],
					[
					  -112.24081,
					  44.17856
					],
					[
					  -112.24082,
					  44.17854
					],
					[
					  -112.24082,
					  44.17852
					],
					[
					  -112.24085,
					  44.17841
					],
					[
					  -112.24088,
					  44.17835
					],
					[
					  -112.24089,
					  44.17831
					],
					[
					  -112.24091,
					  44.17826
					],
					[
					  -112.24108,
					  44.17796
					],
					[
					  -112.2414,
					  44.17757
					],
					[
					  -112.24147,
					  44.1775
					],
					[
					  -112.24167,
					  44.17723
					],
					[
					  -112.2417,
					  44.17717
					],
					[
					  -112.24171,
					  44.17712
					],
					[
					  -112.24173,
					  44.17707
					],
					[
					  -112.24176,
					  44.17694
					],
					[
					  -112.24179,
					  44.17671
					],
					[
					  -112.24182,
					  44.17638
					],
					[
					  -112.24153,
					  44.17638
					],
					[
					  -112.24127,
					  44.17637
					],
					[
					  -112.24055,
					  44.17637
					],
					[
					  -112.24023,
					  44.17636
					],
					[
					  -112.23993,
					  44.17636
					],
					[
					  -112.23971,
					  44.17635
					],
					[
					  -112.23921,
					  44.17635
					],
					[
					  -112.23896,
					  44.17634
					],
					[
					  -112.23749,
					  44.17634
					],
					[
					  -112.22907,
					  44.17624
					],
					[
					  -112.22871,
					  44.17622
					],
					[
					  -112.2286,
					  44.1762
					],
					[
					  -112.22859,
					  44.1762
					],
					[
					  -112.22844,
					  44.17616
					],
					[
					  -112.22831,
					  44.17609
					],
					[
					  -112.22823,
					  44.17604
					],
					[
					  -112.22811,
					  44.17595
					],
					[
					  -112.22797,
					  44.17576
					],
					[
					  -112.22787,
					  44.17526
					],
					[
					  -112.22781,
					  44.17503
					],
					[
					  -112.22765,
					  44.17422
					],
					[
					  -112.22754,
					  44.17353
					],
					[
					  -112.22746,
					  44.1733
					],
					[
					  -112.22744,
					  44.17327
					],
					[
					  -112.22739,
					  44.17324
					],
					[
					  -112.22609,
					  44.1733
					],
					[
					  -112.2257,
					  44.17331
					],
					[
					  -112.22457,
					  44.17326
					],
					[
					  -112.22357,
					  44.17324
					],
					[
					  -112.22332,
					  44.17322
					],
					[
					  -112.22247,
					  44.17322
					],
					[
					  -112.21559,
					  44.17309
					],
					[
					  -112.21438,
					  44.17309
					],
					[
					  -112.21053,
					  44.17301
					],
					[
					  -112.20967,
					  44.17298
					]
				  ],
			},
			id: "76b96db6-3cf7-4337-89a4-d4cea422766a",
		},
		// {
		// 	type: "Feature",
		// 	properties: {
		// 	  name: "Idmon",
		// 	  description: null,
		// 	  tessellate: -1
		// 	},
		// 	geometry: {
		// 	  type: "Point",
		// 	  coordinates: [
		// 		-111.911638,
		// 		44.3606354
		// 	  ]
		// 	},
		// 	id: "d852f13d-13ce-41f7-be08-c57743f2b850"
		//   },
		//   {
		// 	type: "Feature",
		// 	properties: {
		// 	  name: "Spencer, ID",
		// 	  description: null,
		// 	  tessellate: -1
		// 	},
		// 	geometry: {
		// 	  type: "Point",
		// 	  coordinates: [
		// 		-112.183667,
		// 		44.3568848
		// 	  ]
		// 	},
		// 	id: "fdeb5412-7fef-49e2-8821-de6d6bdd6581"
		//   },
		//   {
		// 	type: "Feature",
		// 	properties: {
		// 	  name: "Dubois, ID",
		// 	  description: null,
		// 	  tessellate: -1
		// 	},
		// 	geometry: {
		// 	  type: "Point",
		// 	  coordinates: [
		// 		-112.2417272,
		// 		44.1763802
		// 	  ]
		// 	},
		// 	id: "13ed635e-6d96-4f49-bd86-c7fa26feb7d6"
		//   },
	],
};

export default LostGoldTrailsLoop;
