export const vitaDataLayer = (event, action, value) => {
    const eventData = {
        event,
        event_action: action,
        event_value: value
    };
    console.log('dataLayer', eventData);

    // Pushing data to the dataLayer
    window.dataLayer.push(eventData);
};
