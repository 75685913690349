import React from "react";
import Logo from "../../images/Logo";
import { IconClose } from "../../images/icons";
import {
	getAuth,
	GoogleAuthProvider,
	FacebookAuthProvider,
	signInWithPopup,
	onAuthStateChanged,
	signOut,
	TwitterAuthProvider,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	updateProfile,
	sendEmailVerification,
	sendPasswordResetEmail
} from "firebase/auth";
import app from "../../auth/firebase";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setActiveUser, setUserLogOutState } from "../../store/slices/userReducer";
//import store from "../../store/store";
import { updatePopups } from '../../store/actions/popupsActions';
import { startTutorial } from "../../store/actions/tutorialActions";
import store from "../../store/store";


import FacebookLoginSvg from '../../images/social-logins/facebook';
import GoogleLoginSvg from '../../images/social-logins/google';
import TwitterLoginSvg from '../../images/social-logins/twitter';

function LoginPopup(props) {
	const { closePopup } = props;
	const dispatch = useDispatch();
	const userName = useSelector((state) => state.user.userName);

	const auth = getAuth(app);
	const googleProvider = new GoogleAuthProvider();
	const facebookProvider = new FacebookAuthProvider();
	const twitterProvider = new TwitterAuthProvider();
	const [showLoginButtons, setShowLoginButtons] = useState(false);
	const [showEmailLogin, setShowEmailLogin] = useState(false);
	const [showLoginForm, setShowLoginForm] = useState(false);
	const [showRegisterForm, setShowRegisterForm] = useState(false);
	const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);

	const [form, setForm] = useState({
		username: '',
		email: '',
		password: ''
	});

	useEffect(() => {
		onAuthStateChanged(auth, (userAuth) => {
			if (userAuth) {
				dispatch(setActiveUser({
					userName: userAuth.displayName,
					userEmail: userAuth.email,
					userID: userAuth.uid
				}));
			}
		})
	}, []);

	const handleTwitterSignIn = () => {
		signInWithPopup(auth, twitterProvider)
			.then((result) => {
				dispatch(setActiveUser({
					userName: result.user.displayName,
					userEmail: result.user.email,
					userID: result.user.uid
				}));
			})
			.catch((error) => console.log(error.message));
	};

	const handleFacebookSignIn = () => {
		signInWithPopup(auth, facebookProvider)
			.then((result) => {
				dispatch(setActiveUser({
					userName: result.user.displayName,
					userEmail: result.user.email,
					userID: result.user.uid
				}));
			})
			.catch((error) => console.log(error.message));
	};

	const handleGoogleSignIn = () => {
		signInWithPopup(auth, googleProvider)
			.then((result) => {
				dispatch(setActiveUser({
					userName: result.user.displayName,
					userEmail: result.user.email,
					userID: result.user.uid
				}));
			})
			.catch((error) => console.log(error.message));
	};

	const toggleEmailLogin = () => {
		setShowEmailLogin(true);
		setShowRegisterForm(true);
		setShowLoginForm(false);
		setShowForgotPasswordForm(false);
	}

	const toggleRegisterForm = () => {
		setShowRegisterForm(true);
		setShowLoginForm(false);
		setShowForgotPasswordForm(false);
	}

	const toggleLoginForm = () => {
		setShowRegisterForm(false);
		setShowLoginForm(true);
		setShowForgotPasswordForm(false);
	}

	const toggleForgotPassword = () => {
		setShowRegisterForm(false);
		if (showForgotPasswordForm) {
			setShowLoginForm(true);
			setShowForgotPasswordForm(false);
		} else {
			setShowLoginForm(false);
			setShowForgotPasswordForm(true);
		}
	}

	const handleLoginSubmit = (e) => {
		e.preventDefault();
		loginWithEmail(form);
	}

	const loginWithEmail = ({ email, password }) => {
		signInWithEmailAndPassword(auth, email, password)
			.then((result) => {
				dispatch(setActiveUser({
					userName: result.user.displayName,
					userEmail: result.user.email,
					userID: result.user.uid
				}));
			});
	}

	const handleRegisterSubmit = (e) => {
		e.preventDefault();
		registerWithEmail(form);
	}

	const registerWithEmail = ({ username, email, password }) => {
		createUserWithEmailAndPassword(auth, email, password)
			.then(async (result) => {
				await updateProfile(auth.currentUser, {
					displayName: username
				});
				sendEmailVerification(auth.currentUser);
				dispatch(setActiveUser({
					userName: username,
					userEmail: result.user.email,
					userID: result.user.uid
				}));
			});
	}

	const handleForgotPasswordSubmit = async (e) => {
		e.preventDefault();
		await sendPasswordReset(form.email);
	}

	const sendPasswordReset = async (email) => {
		try {
			await sendPasswordResetEmail(auth, email);
			alert("Password reset link sent!");
		} catch (err) {
			console.error(err);
			alert(err.message);
		}
	};

	const handleSignOut = () => {
		signOut(auth)
			.then(() => {
				dispatch(setUserLogOutState());
			})
			.catch((error) => alert(error.message));
	};

	const toggleButtons = () => {
		setShowLoginButtons(!showLoginButtons);
		setShowEmailLogin(false);
	}

	const toggleGettingStarted = () => {
		store.dispatch(updatePopups({ display: true, type: 'getting-started' }));
	}

	const toggleTutorial = () => {
        dispatch(startTutorial({ active: true}));
	}

	useEffect(() => {
		if (userName !== null) {
			setShowLoginButtons(false);
			setShowEmailLogin(false);
		}
	}, [userName]);

	/*
	const toggleGetStarted = () => {
		store.dispatch(updatePopups({ display: true, type: 'activities' }));
	}
	*/

	return (
		<div className="popup">
			<div className="popup-inner login-popup">
				<div className="popup-header no-bg">
					<a onClick={closePopup} className="popup-close">
						<IconClose />
					</a>
				</div>
				<div className="login popup-content">
					<Logo />

					{!showEmailLogin ?
						<>
							<h2>Travel Assistant</h2>
							{userName !== null ?
								<p>Welcome back, {userName}! Ready to start your Idaho itinerary?</p>
								:
								<p>Sign in with Google, Facebook or Twitter to save your Idaho itineraries for later.</p>
							}
							<div className="get-started-options">
								{showLoginButtons ?
									<>
										<a className="login-btn" onClick={handleGoogleSignIn}><GoogleLoginSvg /></a>
										<a className="login-btn" onClick={handleFacebookSignIn}><FacebookLoginSvg /></a>
										<a className="login-btn" onClick={handleTwitterSignIn}><TwitterLoginSvg /></a>
										<a className="btn btn-green" onClick={toggleEmailLogin}>Email Sign In/Register</a>
										<hr />
										<a onClick={toggleButtons}>Back</a>
									</>
									:
									<>
										{!userName &&
											<>
												<a className="btn btn-orange" onClick={toggleButtons}>Sign In</a>
												<hr />
											</>
										}
										<a onClick={toggleTutorial} className="btn btn-green">View Tutorial</a>
										<a onClick={toggleGettingStarted} className="btn btn-white">Get Started</a>
										{userName !== null &&
											<>
												<hr />
												<a onClick={handleSignOut}>Sign Out</a>
											</>
										}
									</>
								}
							</div>
						</>
						:
						<div className="email-signup">
							{showRegisterForm &&
								<>
									<h2>Register</h2>
									<p>Sign up with your email to save your Idaho itineraries for later.</p>
									<form onSubmit={handleRegisterSubmit}>
										<div className="field-group">
											<label htmlFor="username" className="sr-only">Your name</label>
											<input className="input-block" type="text" placeholder="Your name" required
												onChange={(e) => setForm({ ...form, username: e.target.value })} />
										</div>
										<div className="field-group">
											<label htmlFor="email" className="sr-only">Email</label>
											<input
												id="mail"
												className="input-block" type="text"
												placeholder="Email address"
												required
												onChange={(e) => setForm({ ...form, email: e.target.value })} />
										</div>
										<div className="field-group">
											<label htmlFor="password" className="sr-only">Password</label>
											<input
												className="input-block" type="password" placeholder="Password"
												required
												onChange={(e) => setForm({ ...form, password: e.target.value })} />
										</div>
										<button className="btn btn-block btn-green" type="submit">
											Register
										</button>
									</form>
									<a onClick={toggleLoginForm}>Already registered? Sign In</a>
								</>
							}

							{showLoginForm &&
								<>
									<h2>Sign In</h2>
									<p>Sign in with your email to save your Idaho itineraries for later.</p>
									<form onSubmit={handleLoginSubmit} >
										<div className="field-group">
											<label htmlFor="email" className="sr-only">Email</label>
											<input
												id="mail"
												className="input-block" type="text"
												placeholder="Email address"
												required
												onChange={(e) => setForm({ ...form, email: e.target.value })} />
										</div>
										<div className="field-group">
											<label htmlFor="password" className="sr-only">Password</label>
											<input
												className="input-block" type="password" placeholder="Password"
												required
												onChange={(e) => setForm({ ...form, password: e.target.value })} />
										</div>
										<button className="btn btn-block btn-green" type="submit">
											Sign In
										</button>
									</form>
									<div className="link-row">
										<a onClick={toggleForgotPassword}>Forgot password?</a>
										<a onClick={toggleRegisterForm}>Register</a>
									</div>
								</>
							}

							{showForgotPasswordForm &&
								<>
									<h2>Forgot Password?</h2>
									<p>Enter your email below to reset your password.</p>
									<form onSubmit={handleForgotPasswordSubmit} >
										<div className="field-group">
											<label htmlFor="email" className="sr-only">Email</label>
											<input id="mail"
												type="text"
												className="input-block"
												placeholder="Email address"
												required
												onChange={(e) => setForm({ ...form, email: e.target.value })} />
										</div>
										<button className="btn btn-block btn-green" type="submit">
											Reset Password
										</button>
									</form>
									<a onClick={toggleForgotPassword}>Cancel</a>
								</>
							}
							<hr />
							<a onClick={toggleButtons}>Back</a>
						</div>
					}
				</div>
			</div>
		</div >
	);

}

export default LoginPopup