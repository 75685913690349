import React from "react";

export default class IconArrowDown extends React.Component {
	render() {
		return (
			<>
				<svg xmlns="http://www.w3.org/2000/svg" width="16.615" height="9.499" viewBox="0 0 16.615 9.499">
					<path d="M14.5,17.886,20.779,11.6a1.187,1.187,0,1,1,1.677,1.682L15.338,20.4a1.185,1.185,0,0,1-1.637.035L6.534,13.286A1.187,1.187,0,1,1,8.21,11.6Z" transform="translate(-6.188 -11.251)"/>
				</svg>
			</>
		);
	}
}
