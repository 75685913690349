import React from 'react';
import { useSelector } from 'react-redux';
import store from "../../store/store";
import { addToCart, clearCart } from '../../store/actions/cartActions';
import { updatePopups } from "../../store/actions/popupsActions";
import { updateItineraryName, updateItineraryId, updateItineraryStatus } from "../../store/actions/itineraryActions";
import { setLastItem } from "../../store/actions/lastItemActions";
import { IconPlus } from "../../images/icons";
import { useSearchParams } from 'react-router-dom';
import { updateActivities } from '../../store/actions/activitiesActions';

const ItineraryCards = (props) => {
    const cart = useSelector((state) => state.cart);
    const itineraryStatus = useSelector((state) => state.itinerary.itineraryStatus);
    const maxImages = 3;
    const [searchParams, setSearchParams] = useSearchParams();

    const checkCurrentItinerary = () => {
        if (cart.cartItems && cart.cartItems.length > 0 && itineraryStatus !== 'saved') {
            store.dispatch(updatePopups({ display: true, type: 'create-itinerary' }));
        } else {
            store.dispatch(updateActivities([]));
            store.dispatch(clearCart());
            localStorage.removeItem("itinerary");
            props.toggleDashboard();
        }
    }

    const loadItinerary = (itin) => {

        if (cart.cartItems && cart.cartItems.length > 0 && itineraryStatus !== 'saved') {
            store.dispatch(updatePopups({ display: true, type: 'load-itinerary', data: itin }));
            props.clearFilters();
        } else {
            store.dispatch(clearCart());
            setSearchParams({});

            const setMarkers = itin.markers.map((marker) => {
                store.dispatch(addToCart(marker));
            });

            store.dispatch(setLastItem({
                dynamicText: itin.name,
                message: 'loadItinerary',
                icon: true,
                type: 'success'
            }));

            props.toggleDashboard();
            return Promise.all(setMarkers).then(() => {
                store.dispatch(updateItineraryId(itin.docId));
                store.dispatch(updateItineraryName(itin.name));
                store.dispatch(updateItineraryStatus('saved'));
            });
        }
    }

    return (
        <div className="account-itineraries">
            <a onClick={checkCurrentItinerary} className="create-new-itinerary" aria-label="Create an Itinerary" title="Create an Itinerary">
                <h4>
                    <span className="icon-plus">
                        <IconPlus />
                    </span>
                    Create an Itinerary
                </h4>
            </a>

            {(props.itins && props.itins.length > 0) &&
                props.itins.map((itin, i) => (
                    <a key={i} onClick={() => loadItinerary(itin)} className={'itinerary images-' + itin.markers.length} aria-label={`View itinerary ` + itin.name} title={`View itinerary ` + itin.name}>
                        <h4>{itin.name}</h4>
                        {itin.markers.map((marker, i) => (
                            i < maxImages &&
                            <div key={i} className="itinerary-image">
                                <img src={marker.image} />
                            </div>
                        ))}
                    </a>
                ))
            }
        </div>
    )
}

export default ItineraryCards;