const CityOfRocks = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				name: "City of Rocks Backcountry Scenic Byway",
				description: "The City of Rocks Backcountry Byway weaves through the Albion Mountain Range and rural landscapes peppered with towering granite spires. Beginning in Albion on Idaho 77, the byway passes by Pomerelle Mountain Resort and Lake Cleveland Recreation Area as it heads south. Attracting rock climbers, campers and hikers, the City of Rocks National Reserve and the neighboring Castle Rocks State Park feature enchanting geologic wonders.",
				link: "https://visitidaho.org/things-to-do/road-trips/city-of-rocks-backcountry-byway/",
				route: [
					"Albion", 
					"Almo", 
					"Oakley"
				],
				tessellate: 1,
				shape: "Line",
				category: "default",
			},
			geometry: {
				type: "LineString",
				coordinates: [
					[-113.57817, 42.41255, 0],
					[-113.57826, 42.41049, 0],
					[-113.57834, 42.40782, 0],
					[-113.57834, 42.40748, 0],
					[-113.57833, 42.40738, 0],
					[-113.57826, 42.40714, 0],
					[-113.57812, 42.40684, 0],
					[-113.57804, 42.4067, 0],
					[-113.57794, 42.40656, 0],
					[-113.57783, 42.40643, 0],
					[-113.57759, 42.40619, 0],
					[-113.56825, 42.3994, 0],
					[-113.56822, 42.39937, 0],
					[-113.55086, 42.38674, 0],
					[-113.55064, 42.38659, 0],
					[-113.54442, 42.38203, 0],
					[-113.54441, 42.38203, 0],
					[-113.53435, 42.37469, 0],
					[-113.53261, 42.37345, 0],
					[-113.53244, 42.37332, 0],
					[-113.53179, 42.37286, 0],
					[-113.5313, 42.37249, 0],
					[-113.53109, 42.37228, 0],
					[-113.53086, 42.37201, 0],
					[-113.53061, 42.37163, 0],
					[-113.53052, 42.37145, 0],
					[-113.53044, 42.37125, 0],
					[-113.53033, 42.3708, 0],
					[-113.52892, 42.35494, 0],
					[-113.52874, 42.35296, 0],
					[-113.5287, 42.35274, 0],
					[-113.52862, 42.35252, 0],
					[-113.52848, 42.35223, 0],
					[-113.52837, 42.35207, 0],
					[-113.52829, 42.35198, 0],
					[-113.52827, 42.35195, 0],
					[-113.52802, 42.3517, 0],
					[-113.5278, 42.35153, 0],
					[-113.52768, 42.35146, 0],
					[-113.52767, 42.35145, 0],
					[-113.52736, 42.35127, 0],
					[-113.5272, 42.3512, 0],
					[-113.52706, 42.35115, 0],
					[-113.52684, 42.35109, 0],
					[-113.52673, 42.35105, 0],
					[-113.52549, 42.35071, 0],
					[-113.52547, 42.3507, 0],
					[-113.52532, 42.35066, 0],
					[-113.52525, 42.35063, 0],
					[-113.5249, 42.35051, 0],
					[-113.52466, 42.35041, 0],
					[-113.52461, 42.35038, 0],
					[-113.52451, 42.35033, 0],
					[-113.52366, 42.34982, 0],
					[-113.52001, 42.34745, 0],
					[-113.51986, 42.34736, 0],
					[-113.5195, 42.3472, 0],
					[-113.51942, 42.34717, 0],
					[-113.51941, 42.34716, 0],
					[-113.51924, 42.34711, 0],
					[-113.51895, 42.34704, 0],
					[-113.51871, 42.347, 0],
					[-113.51839, 42.34697, 0],
					[-113.51755, 42.34696, 0],
					[-113.51703, 42.34693, 0],
					[-113.51664, 42.34687, 0],
					[-113.5163, 42.34679, 0],
					[-113.51594, 42.34665, 0],
					[-113.51559, 42.34648, 0],
					[-113.51527, 42.34626, 0],
					[-113.51509, 42.34611, 0],
					[-113.51481, 42.3458, 0],
					[-113.5145, 42.34533, 0],
					[-113.51444, 42.34525, 0],
					[-113.51423, 42.34493, 0],
					[-113.51421, 42.34491, 0],
					[-113.51381, 42.34431, 0],
					[-113.51379, 42.34429, 0],
					[-113.51367, 42.34409, 0],
					[-113.51348, 42.34382, 0],
					[-113.51347, 42.3438, 0],
					[-113.51335, 42.34362, 0],
					[-113.5132, 42.34342, 0],
					[-113.5116, 42.34107, 0],
					[-113.51137, 42.34075, 0],
					[-113.51127, 42.34059, 0],
					[-113.5106, 42.3396, 0],
					[-113.51035, 42.3392, 0],
					[-113.50999, 42.33869, 0],
					[-113.50995, 42.33862, 0],
					[-113.50969, 42.33828, 0],
					[-113.50957, 42.33814, 0],
					[-113.50939, 42.33795, 0],
					[-113.50914, 42.33771, 0],
					[-113.50892, 42.33752, 0],
					[-113.50868, 42.33733, 0],
					[-113.50841, 42.33714, 0],
					[-113.50537, 42.3352, 0],
					[-113.50523, 42.33512, 0],
					[-113.50163, 42.33283, 0],
					[-113.50156, 42.33278, 0],
					[-113.50054, 42.33214, 0],
					[-113.50004, 42.33178, 0],
					[-113.49963, 42.33141, 0],
					[-113.49889, 42.33057, 0],
					[-113.49831, 42.32985, 0],
					[-113.49809, 42.32956, 0],
					[-113.49803, 42.32946, 0],
					[-113.49802, 42.32946, 0],
					[-113.49799, 42.32939, 0],
					[-113.49791, 42.32925, 0],
					[-113.49782, 42.32904, 0],
					[-113.49775, 42.32885, 0],
					[-113.49767, 42.3286, 0],
					[-113.49762, 42.32837, 0],
					[-113.49759, 42.3281, 0],
					[-113.49757, 42.3278, 0],
					[-113.49762, 42.32439, 0],
					[-113.4976, 42.32354, 0],
					[-113.49755, 42.32316, 0],
					[-113.49735, 42.32226, 0],
					[-113.49665, 42.31958, 0],
					[-113.4966, 42.31928, 0],
					[-113.49659, 42.31915, 0],
					[-113.49659, 42.31902, 0],
					[-113.49662, 42.31862, 0],
					[-113.49684, 42.31735, 0],
					[-113.49708, 42.31579, 0],
					[-113.49709, 42.31569, 0],
					[-113.49709, 42.31521, 0],
					[-113.49705, 42.31488, 0],
					[-113.49696, 42.31444, 0],
					[-113.49683, 42.31392, 0],
					[-113.49677, 42.3136, 0],
					[-113.49675, 42.31344, 0],
					[-113.49673, 42.31296, 0],
					[-113.49679, 42.31162, 0],
					[-113.4969, 42.31075, 0],
					[-113.49697, 42.31036, 0],
					[-113.49697, 42.31033, 0],
					[-113.49704, 42.31, 0],
					[-113.49704, 42.30998, 0],
					[-113.49714, 42.30947, 0],
					[-113.4972, 42.30925, 0],
					[-113.49727, 42.30893, 0],
					[-113.49741, 42.30845, 0],
					[-113.49754, 42.30806, 0],
					[-113.49759, 42.30788, 0],
					[-113.49901, 42.30369, 0],
					[-113.49935, 42.30274, 0],
					[-113.49987, 42.30138, 0],
					[-113.49992, 42.30127, 0],
					[-113.50008, 42.30096, 0],
					[-113.50035, 42.30055, 0],
					[-113.50132, 42.29926, 0],
					[-113.5014, 42.29914, 0],
					[-113.5015, 42.29895, 0],
					[-113.50158, 42.29873, 0],
					[-113.5016, 42.29863, 0],
					[-113.50163, 42.29841, 0],
					[-113.50164, 42.29811, 0],
					[-113.50165, 42.29146, 0],
					[-113.50162, 42.29065, 0],
					[-113.50162, 42.29039, 0],
					[-113.50132, 42.28145, 0],
					[-113.50132, 42.28121, 0],
					[-113.50129, 42.28072, 0],
					[-113.50942, 42.2792, 0],
					[-113.50959, 42.27916, 0],
					[-113.51027, 42.27904, 0],
					[-113.5103, 42.27903, 0],
					[-113.51053, 42.27898, 0],
					[-113.51073, 42.27895, 0],
					[-113.51123, 42.27884, 0],
					[-113.51189, 42.27864, 0],
					[-113.51217, 42.27853, 0],
					[-113.51255, 42.27835, 0],
					[-113.51289, 42.27817, 0],
					[-113.51325, 42.27793, 0],
					[-113.51356, 42.27769, 0],
					[-113.51688, 42.27475, 0],
					[-113.51688, 42.27474, 0],
					[-113.51732, 42.27436, 0],
					[-113.51747, 42.27422, 0],
					[-113.51765, 42.27408, 0],
					[-113.51797, 42.27386, 0],
					[-113.51856, 42.27353, 0],
					[-113.51875, 42.27344, 0],
					[-113.51883, 42.27341, 0],
					[-113.51894, 42.27336, 0],
					[-113.51941, 42.2732, 0],
					[-113.52299, 42.27209, 0],
					[-113.52307, 42.27206, 0],
					[-113.52502, 42.27145, 0],
					[-113.52585, 42.27115, 0],
					[-113.52659, 42.27084, 0],
					[-113.52741, 42.27046, 0],
					[-113.52746, 42.27043, 0],
					[-113.52816, 42.27007, 0],
					[-113.52915, 42.26949, 0],
					[-113.52945, 42.2693, 0],
					[-113.53092, 42.2683, 0],
					[-113.53096, 42.26828, 0],
					[-113.53186, 42.26766, 0],
					[-113.53202, 42.26756, 0],
					[-113.53241, 42.26728, 0],
					[-113.53277, 42.267, 0],
					[-113.53346, 42.26637, 0],
					[-113.53391, 42.26591, 0],
					[-113.53412, 42.26571, 0],
					[-113.53433, 42.26548, 0],
					[-113.53455, 42.26527, 0],
					[-113.53521, 42.2646, 0],
					[-113.53542, 42.2644, 0],
					[-113.53564, 42.26417, 0],
					[-113.53697, 42.26288, 0],
					[-113.53761, 42.26233, 0],
					[-113.53811, 42.26196, 0],
					[-113.53861, 42.26163, 0],
					[-113.53933, 42.26122, 0],
					[-113.54614, 42.25771, 0],
					[-113.54935, 42.25598, 0],
					[-113.55003, 42.25569, 0],
					[-113.55044, 42.25556, 0],
					[-113.55223, 42.25507, 0],
					[-113.55263, 42.25493, 0],
					[-113.55283, 42.25484, 0],
					[-113.55301, 42.25477, 0],
					[-113.55347, 42.25452, 0],
					[-113.55383, 42.25429, 0],
					[-113.55398, 42.25418, 0],
					[-113.55425, 42.25396, 0],
					[-113.55465, 42.25355, 0],
					[-113.55475, 42.25343, 0],
					[-113.55493, 42.25317, 0],
					[-113.55513, 42.25282, 0],
					[-113.55526, 42.25254, 0],
					[-113.55531, 42.25238, 0],
					[-113.55533, 42.25235, 0],
					[-113.55576, 42.25066, 0],
					[-113.55583, 42.25043, 0],
					[-113.55593, 42.25002, 0],
					[-113.556, 42.24979, 0],
					[-113.55619, 42.24931, 0],
					[-113.55645, 42.24883, 0],
					[-113.55655, 42.24869, 0],
					[-113.55664, 42.24855, 0],
					[-113.5569, 42.24822, 0],
					[-113.55724, 42.24786, 0],
					[-113.55742, 42.2477, 0],
					[-113.55748, 42.24764, 0],
					[-113.55772, 42.24743, 0],
					[-113.55773, 42.24743, 0],
					[-113.55916, 42.24618, 0],
					[-113.55944, 42.24595, 0],
					[-113.55964, 42.24577, 0],
					[-113.5598, 42.24564, 0],
					[-113.55989, 42.24555, 0],
					[-113.55998, 42.24547, 0],
					[-113.56003, 42.2454, 0],
					[-113.56007, 42.24536, 0],
					[-113.56008, 42.24534, 0],
					[-113.56013, 42.24528, 0],
					[-113.56017, 42.2452, 0],
					[-113.5602, 42.24515, 0],
					[-113.56025, 42.24501, 0],
					[-113.56027, 42.24492, 0],
					[-113.56028, 42.24485, 0],
					[-113.56028, 42.24463, 0],
					[-113.56025, 42.24375, 0],
					[-113.56025, 42.24341, 0],
					[-113.55987, 42.2312, 0],
					[-113.55987, 42.23079, 0],
					[-113.55988, 42.23061, 0],
					[-113.55993, 42.23023, 0],
					[-113.55997, 42.23004, 0],
					[-113.56002, 42.22985, 0],
					[-113.56013, 42.22951, 0],
					[-113.56018, 42.22938, 0],
					[-113.56098, 42.22758, 0],
					[-113.56107, 42.22734, 0],
					[-113.56114, 42.22707, 0],
					[-113.56121, 42.22672, 0],
					[-113.56121, 42.2267, 0],
					[-113.56124, 42.22644, 0],
					[-113.56125, 42.22622, 0],
					[-113.56145, 42.22381, 0],
					[-113.56153, 42.22245, 0],
					[-113.56159, 42.20992, 0],
					[-113.56157, 42.20911, 0],
					[-113.56159, 42.20738, 0],
					[-113.56161, 42.207, 0],
					[-113.56165, 42.2068, 0],
					[-113.56173, 42.2065, 0],
					[-113.56182, 42.20625, 0],
					[-113.56209, 42.20573, 0],
					[-113.56224, 42.20552, 0],
					[-113.5626, 42.20509, 0],
					[-113.56387, 42.20369, 0],
					[-113.564, 42.20352, 0],
					[-113.56418, 42.20323, 0],
					[-113.56429, 42.20302, 0],
					[-113.56442, 42.20271, 0],
					[-113.56449, 42.20252, 0],
					[-113.56458, 42.20211, 0],
					[-113.5646, 42.20176, 0],
					[-113.56461, 42.19687, 0],
					[-113.56462, 42.19659, 0],
					[-113.56461, 42.19256, 0],
					[-113.56462, 42.19236, 0],
					[-113.56461, 42.1883, 0],
					[-113.56462, 42.18787, 0],
					[-113.56461, 42.17968, 0],
					[-113.56462, 42.17932, 0],
					[-113.5647, 42.17871, 0],
					[-113.56475, 42.17844, 0],
					[-113.56477, 42.17837, 0],
					[-113.56482, 42.17813, 0],
					[-113.56501, 42.17754, 0],
					[-113.5664, 42.17375, 0],
					[-113.56643, 42.17368, 0],
					[-113.56665, 42.17304, 0],
					[-113.56946, 42.1654, 0],
					[-113.5697, 42.16493, 0],
					[-113.56998, 42.16444, 0],
					[-113.57099, 42.16298, 0],
					[-113.57151, 42.16219, 0],
					[-113.5744, 42.15798, 0],
					[-113.57469, 42.15748, 0],
					[-113.57483, 42.15721, 0],
					[-113.57494, 42.15696, 0],
					[-113.57513, 42.15644, 0],
					[-113.57715, 42.15005, 0],
					[-113.5773, 42.14961, 0],
					[-113.57737, 42.14945, 0],
					[-113.57752, 42.14917, 0],
					[-113.57763, 42.14899, 0],
					[-113.57775, 42.14882, 0],
					[-113.57804, 42.14848, 0],
					[-113.57821, 42.1483, 0],
					[-113.57855, 42.148, 0],
					[-113.5789, 42.14767, 0],
					[-113.57924, 42.14738, 0],
					[-113.57974, 42.14692, 0],
					[-113.57999, 42.14664, 0],
					[-113.58017, 42.14641, 0],
					[-113.58025, 42.14629, 0],
					[-113.58039, 42.14605, 0],
					[-113.58047, 42.14589, 0],
					[-113.58053, 42.14575, 0],
					[-113.58058, 42.1456, 0],
					[-113.58063, 42.14542, 0],
					[-113.5807, 42.1451, 0],
					[-113.58117, 42.14239, 0],
					[-113.58122, 42.1422, 0],
					[-113.58126, 42.14209, 0],
					[-113.58134, 42.14191, 0],
					[-113.58143, 42.14173, 0],
					[-113.58168, 42.1413, 0],
					[-113.58192, 42.14097, 0],
					[-113.58243, 42.14034, 0],
					[-113.58254, 42.14022, 0],
					[-113.58323, 42.13937, 0],
					[-113.58432, 42.13809, 0],
					[-113.58474, 42.13767, 0],
					[-113.58606, 42.13641, 0],
					[-113.58625, 42.13624, 0],
					[-113.58641, 42.13608, 0],
					[-113.58682, 42.13573, 0],
					[-113.5872, 42.13547, 0],
					[-113.58757, 42.13526, 0],
					[-113.58778, 42.13516, 0],
					[-113.58827, 42.13496, 0],
					[-113.62124, 42.12354, 0],
					[-113.62184, 42.12332, 0],
					[-113.62204, 42.12323, 0],
					[-113.62233, 42.12306, 0],
					[-113.62244, 42.12298, 0],
					[-113.62265, 42.12281, 0],
					[-113.62274, 42.12272, 0],
					[-113.6228, 42.12265, 0],
					[-113.6229, 42.12255, 0],
					[-113.62306, 42.12233, 0],
					[-113.6232, 42.12206, 0],
					[-113.62329, 42.12176, 0],
					[-113.62331, 42.12167, 0],
					[-113.62334, 42.12147, 0],
					[-113.62338, 42.11916, 0],
					[-113.62341, 42.11838, 0],
					[-113.62345, 42.11798, 0],
					[-113.62349, 42.1177, 0],
					[-113.62362, 42.11707, 0],
					[-113.62387, 42.11615, 0],
					[-113.62406, 42.11553, 0],
					[-113.62416, 42.11524, 0],
					[-113.62436, 42.11473, 0],
					[-113.62446, 42.1145, 0],
					[-113.62474, 42.11392, 0],
					[-113.62762, 42.10876, 0],
					[-113.62799, 42.10815, 0],
					[-113.62799, 42.10814, 0],
					[-113.62862, 42.10724, 0],
					[-113.62868, 42.10717, 0],
					[-113.62958, 42.10599, 0],
					[-113.62959, 42.10598, 0],
					[-113.63005, 42.10537, 0],
					[-113.63022, 42.10516, 0],
					[-113.63065, 42.10458, 0],
					[-113.63068, 42.10453, 0],
					[-113.63122, 42.10379, 0],
					[-113.63136, 42.10357, 0],
					[-113.63145, 42.10345, 0],
					[-113.63326, 42.1007, 0],
					[-113.63338, 42.10049, 0],
					[-113.63342, 42.10041, 0],
					[-113.63348, 42.10025, 0],
					[-113.63352, 42.10012, 0],
					[-113.63354, 42.1, 0],
					[-113.63356, 42.09979, 0],
					[-113.63356, 42.09963, 0],
					[-113.63355, 42.09943, 0],
					[-113.63354, 42.09938, 0],
					[-113.63344, 42.09762, 0],
					[-113.63343, 42.09755, 0],
					[-113.63338, 42.09671, 0],
					[-113.63336, 42.09664, 0],
					[-113.63335, 42.09657, 0],
					[-113.6333, 42.09646, 0],
					[-113.63319, 42.09615, 0],
					[-113.63311, 42.09584, 0],
					[-113.63308, 42.09564, 0],
					[-113.63305, 42.09519, 0],
					[-113.63287, 42.08553, 0],
					[-113.64796, 42.08545, 0],
					[-113.64822, 42.08543, 0],
					[-113.64846, 42.08537, 0],
					[-113.65009, 42.08458, 0],
					[-113.65033, 42.08444, 0],
					[-113.65085, 42.08431, 0],
					[-113.65172, 42.08425, 0],
					[-113.65664, 42.08404, 0],
					[-113.66225, 42.08398, 0],
					[-113.66582, 42.08405, 0],
					[-113.66828, 42.08398, 0],
					[-113.67038, 42.08384, 0],
					[-113.67103, 42.08377, 0],
					[-113.67179, 42.08364, 0],
					[-113.67215, 42.08353, 0],
					[-113.6728, 42.08325, 0],
					[-113.67433, 42.08251, 0],
					[-113.67453, 42.08238, 0],
					[-113.67463, 42.08228, 0],
					[-113.6749, 42.08198, 0],
					[-113.67497, 42.08188, 0],
					[-113.67515, 42.08156, 0],
					[-113.67523, 42.08123, 0],
					[-113.67535, 42.08088, 0],
					[-113.6759, 42.07999, 0],
					[-113.67604, 42.07978, 0],
					[-113.67629, 42.07944, 0],
					[-113.67635, 42.07937, 0],
					[-113.67638, 42.07935, 0],
					[-113.67644, 42.07932, 0],
					[-113.6769, 42.07917, 0],
					[-113.67749, 42.07904, 0],
					[-113.67785, 42.0789, 0],
					[-113.67887, 42.07843, 0],
					[-113.67937, 42.07817, 0],
					[-113.67996, 42.07772, 0],
					[-113.68133, 42.07655, 0],
					[-113.68147, 42.0764, 0],
					[-113.68154, 42.07631, 0],
					[-113.68184, 42.07569, 0],
					[-113.6822, 42.07519, 0],
					[-113.68244, 42.07495, 0],
					[-113.68263, 42.07485, 0],
					[-113.6831, 42.07473, 0],
					[-113.6875, 42.07383, 0],
					[-113.68819, 42.07371, 0],
					[-113.68853, 42.0737, 0],
					[-113.69093, 42.07379, 0],
					[-113.69116, 42.07378, 0],
					[-113.69157, 42.07372, 0],
					[-113.69245, 42.07353, 0],
					[-113.69299, 42.07347, 0],
					[-113.6933, 42.07347, 0],
					[-113.69346, 42.07348, 0],
					[-113.69442, 42.0735, 0],
					[-113.69531, 42.07347, 0],
					[-113.69634, 42.0735, 0],
					[-113.69645, 42.07347, 0],
					[-113.69647, 42.07346, 0],
					[-113.69667, 42.07316, 0],
					[-113.69749, 42.07185, 0],
					[-113.69751, 42.07181, 0],
					[-113.69826, 42.0708, 0],
					[-113.69926, 42.0695, 0],
					[-113.69947, 42.06921, 0],
					[-113.70063, 42.06777, 0],
					[-113.70083, 42.06759, 0],
					[-113.70092, 42.06752, 0],
					[-113.70107, 42.06739, 0],
					[-113.70114, 42.06734, 0],
					[-113.70117, 42.06733, 0],
					[-113.70119, 42.06732, 0],
					[-113.70123, 42.06731, 0],
					[-113.70154, 42.0673, 0],
					[-113.70218, 42.06722, 0],
					[-113.70225, 42.06722, 0],
					[-113.70302, 42.06715, 0],
					[-113.70328, 42.06707, 0],
					[-113.70414, 42.0667, 0],
					[-113.70431, 42.06666, 0],
					[-113.70441, 42.06665, 0],
					[-113.70451, 42.06665, 0],
					[-113.70468, 42.06669, 0],
					[-113.70481, 42.06674, 0],
					[-113.70494, 42.06681, 0],
					[-113.70522, 42.06694, 0],
					[-113.70557, 42.06707, 0],
					[-113.70558, 42.06707, 0],
					[-113.70575, 42.06712, 0],
					[-113.70595, 42.06716, 0],
					[-113.70666, 42.06724, 0],
					[-113.70675, 42.06726, 0],
					[-113.7068, 42.06726, 0],
					[-113.7069, 42.06729, 0],
					[-113.70699, 42.06731, 0],
					[-113.70744, 42.06752, 0],
					[-113.70773, 42.06768, 0],
					[-113.70786, 42.06778, 0],
					[-113.70794, 42.06785, 0],
					[-113.70829, 42.06819, 0],
					[-113.70836, 42.06825, 0],
					[-113.70861, 42.06839, 0],
					[-113.70875, 42.06849, 0],
					[-113.70884, 42.06859, 0],
					[-113.70893, 42.06877, 0],
					[-113.709, 42.06924, 0],
					[-113.70906, 42.06947, 0],
					[-113.70908, 42.06951, 0],
					[-113.70909, 42.06955, 0],
					[-113.70913, 42.06961, 0],
					[-113.70934, 42.06984, 0],
					[-113.70959, 42.07016, 0],
					[-113.70978, 42.07036, 0],
					[-113.71012, 42.07059, 0],
					[-113.71017, 42.07065, 0],
					[-113.71132, 42.07181, 0],
					[-113.71173, 42.07212, 0],
					[-113.71196, 42.07228, 0],
					[-113.71224, 42.07244, 0],
					[-113.71236, 42.07246, 0],
					[-113.71257, 42.07248, 0],
					[-113.71344, 42.0725, 0],
					[-113.71384, 42.07248, 0],
					[-113.71411, 42.07248, 0],
					[-113.71441, 42.07252, 0],
					[-113.71488, 42.0726, 0],
					[-113.71517, 42.07259, 0],
					[-113.71549, 42.07266, 0],
					[-113.71564, 42.07272, 0],
					[-113.71611, 42.07296, 0],
					[-113.71747, 42.07352, 0],
					[-113.7183, 42.07384, 0],
					[-113.7185, 42.0739, 0],
					[-113.71868, 42.07393, 0],
					[-113.71873, 42.07395, 0],
					[-113.7191, 42.07395, 0],
					[-113.71932, 42.07396, 0],
					[-113.71937, 42.07397, 0],
					[-113.71949, 42.07401, 0],
					[-113.71978, 42.07416, 0],
					[-113.72004, 42.07437, 0],
					[-113.72015, 42.07451, 0],
					[-113.72023, 42.07471, 0],
					[-113.72028, 42.07476, 0],
					[-113.72034, 42.07484, 0],
					[-113.72069, 42.07517, 0],
					[-113.72145, 42.07576, 0],
					[-113.72171, 42.07598, 0],
					[-113.72178, 42.07603, 0],
					[-113.72194, 42.07616, 0],
					[-113.72229, 42.07638, 0],
					[-113.72237, 42.07648, 0],
					[-113.72245, 42.07664, 0],
					[-113.72251, 42.07684, 0],
					[-113.72262, 42.07716, 0],
					[-113.72269, 42.07741, 0],
					[-113.72281, 42.07758, 0],
					[-113.72312, 42.07792, 0],
					[-113.72344, 42.07843, 0],
					[-113.72354, 42.07853, 0],
					[-113.72373, 42.07867, 0],
					[-113.72384, 42.0788, 0],
					[-113.72394, 42.079, 0],
					[-113.72408, 42.07957, 0],
					[-113.72426, 42.07988, 0],
					[-113.72447, 42.08012, 0],
					[-113.7248, 42.08036, 0],
					[-113.72535, 42.08072, 0],
					[-113.72554, 42.08097, 0],
					[-113.72572, 42.08125, 0],
					[-113.72582, 42.08144, 0],
					[-113.72584, 42.08161, 0],
					[-113.72582, 42.08173, 0],
					[-113.72562, 42.08213, 0],
					[-113.7256, 42.08221, 0],
					[-113.72559, 42.0823, 0],
					[-113.72563, 42.08244, 0],
					[-113.7258, 42.08275, 0],
					[-113.72599, 42.08297, 0],
					[-113.72628, 42.08324, 0],
					[-113.72631, 42.08329, 0],
					[-113.72634, 42.08336, 0],
					[-113.72634, 42.08348, 0],
					[-113.72631, 42.08363, 0],
					[-113.72626, 42.08375, 0],
					[-113.72621, 42.08402, 0],
					[-113.7261, 42.08435, 0],
					[-113.72595, 42.08458, 0],
					[-113.72592, 42.08467, 0],
					[-113.72592, 42.08469, 0],
					[-113.72591, 42.08473, 0],
					[-113.72592, 42.0848, 0],
					[-113.72593, 42.08484, 0],
					[-113.72596, 42.08489, 0],
					[-113.72604, 42.08495, 0],
					[-113.72625, 42.08505, 0],
					[-113.72654, 42.08514, 0],
					[-113.72677, 42.08523, 0],
					[-113.72704, 42.08536, 0],
					[-113.72716, 42.08543, 0],
					[-113.72729, 42.08553, 0],
					[-113.72739, 42.08566, 0],
					[-113.72747, 42.08578, 0],
					[-113.72753, 42.08589, 0],
					[-113.72757, 42.08599, 0],
					[-113.72761, 42.08617, 0],
					[-113.72765, 42.08631, 0],
					[-113.7277, 42.08638, 0],
					[-113.72779, 42.08649, 0],
					[-113.72785, 42.0866, 0],
					[-113.72792, 42.08681, 0],
					[-113.72801, 42.08724, 0],
					[-113.72802, 42.08745, 0],
					[-113.72802, 42.08763, 0],
					[-113.72799, 42.08781, 0],
					[-113.72786, 42.08829, 0],
					[-113.72786, 42.08834, 0],
					[-113.72785, 42.08841, 0],
					[-113.72786, 42.08848, 0],
					[-113.72791, 42.08857, 0],
					[-113.72794, 42.08861, 0],
					[-113.728, 42.08867, 0],
					[-113.72811, 42.08876, 0],
					[-113.72829, 42.08885, 0],
					[-113.7287, 42.08908, 0],
					[-113.72871, 42.08908, 0],
					[-113.72895, 42.08915, 0],
					[-113.72908, 42.08917, 0],
					[-113.72925, 42.08915, 0],
					[-113.72964, 42.08907, 0],
					[-113.72999, 42.08898, 0],
					[-113.7307, 42.08872, 0],
					[-113.73117, 42.08857, 0],
					[-113.73137, 42.08856, 0],
					[-113.7315, 42.08854, 0],
					[-113.73181, 42.08856, 0],
					[-113.73259, 42.08866, 0],
					[-113.73282, 42.08867, 0],
					[-113.73339, 42.08859, 0],
					[-113.73357, 42.08854, 0],
					[-113.73394, 42.0884, 0],
					[-113.73423, 42.08825, 0],
					[-113.73457, 42.08812, 0],
					[-113.7348, 42.08805, 0],
					[-113.73496, 42.08801, 0],
					[-113.73569, 42.08769, 0],
					[-113.7359, 42.08762, 0],
					[-113.73611, 42.0876, 0],
					[-113.73628, 42.08762, 0],
					[-113.73648, 42.08766, 0],
					[-113.73731, 42.08772, 0],
					[-113.73831, 42.08785, 0],
					[-113.7392, 42.08799, 0],
					[-113.73921, 42.08799, 0],
					[-113.73961, 42.08805, 0],
					[-113.73984, 42.08804, 0],
					[-113.74027, 42.08795, 0],
					[-113.7408, 42.08779, 0],
					[-113.74284, 42.08712, 0],
					[-113.74302, 42.08708, 0],
					[-113.74334, 42.08707, 0],
					[-113.74354, 42.08709, 0],
					[-113.7436, 42.08709, 0],
					[-113.74391, 42.08712, 0],
					[-113.74399, 42.08712, 0],
					[-113.74408, 42.08711, 0],
					[-113.74437, 42.08701, 0],
					[-113.74466, 42.08689, 0],
					[-113.74496, 42.08678, 0],
					[-113.74607, 42.08628, 0],
					[-113.74683, 42.08588, 0],
					[-113.74778, 42.08552, 0],
					[-113.74813, 42.08534, 0],
					[-113.74836, 42.08517, 0],
					[-113.74859, 42.08505, 0],
					[-113.74883, 42.08498, 0],
					[-113.74919, 42.08489, 0],
					[-113.74949, 42.08483, 0],
					[-113.74977, 42.08483, 0],
					[-113.75068, 42.08489, 0],
					[-113.75093, 42.08487, 0],
					[-113.7514, 42.0848, 0],
					[-113.75303, 42.0846, 0],
					[-113.75338, 42.08451, 0],
					[-113.75386, 42.0843, 0],
					[-113.7542, 42.08423, 0],
					[-113.75476, 42.08418, 0],
					[-113.75551, 42.08419, 0],
					[-113.75612, 42.08423, 0],
					[-113.75648, 42.08432, 0],
					[-113.75744, 42.0846, 0],
					[-113.75819, 42.08489, 0],
					[-113.75865, 42.08512, 0],
					[-113.7615, 42.08686, 0],
					[-113.76193, 42.0871, 0],
					[-113.76288, 42.08751, 0],
					[-113.76517, 42.08825, 0],
					[-113.76559, 42.0884, 0],
					[-113.76563, 42.08841, 0],
					[-113.76631, 42.08887, 0],
					[-113.76672, 42.08908, 0],
					[-113.7671, 42.08926, 0],
					[-113.76749, 42.08941, 0],
					[-113.76777, 42.0895, 0],
					[-113.76802, 42.08952, 0],
					[-113.76861, 42.08963, 0],
					[-113.76918, 42.0898, 0],
					[-113.76963, 42.08992, 0],
					[-113.76962, 42.09056, 0],
					[-113.76964, 42.09099, 0],
					[-113.76962, 42.09269, 0],
					[-113.76959, 42.09334, 0],
					[-113.76959, 42.09372, 0],
					[-113.7696, 42.09388, 0],
					[-113.76961, 42.0939, 0],
					[-113.76963, 42.09413, 0],
					[-113.76963, 42.09424, 0],
					[-113.76962, 42.09437, 0],
					[-113.76962, 42.09448, 0],
					[-113.76958, 42.09478, 0],
					[-113.76958, 42.0948, 0],
					[-113.76956, 42.09498, 0],
					[-113.76956, 42.09514, 0],
					[-113.76955, 42.09525, 0],
					[-113.76954, 42.0953, 0],
					[-113.76955, 42.09612, 0],
					[-113.76957, 42.09627, 0],
					[-113.7696, 42.09639, 0],
					[-113.76962, 42.09655, 0],
					[-113.76968, 42.09678, 0],
					[-113.76968, 42.09689, 0],
					[-113.76966, 42.09707, 0],
					[-113.76966, 42.09711, 0],
					[-113.76961, 42.09753, 0],
					[-113.76959, 42.09782, 0],
					[-113.76959, 42.09788, 0],
					[-113.76958, 42.09799, 0],
					[-113.76961, 42.10018, 0],
					[-113.76963, 42.10032, 0],
					[-113.76967, 42.10043, 0],
					[-113.76972, 42.10051, 0],
					[-113.76973, 42.10052, 0],
					[-113.76978, 42.1006, 0],
					[-113.76985, 42.10066, 0],
					[-113.77003, 42.10078, 0],
					[-113.77009, 42.10083, 0],
					[-113.77041, 42.10101, 0],
					[-113.77053, 42.10107, 0],
					[-113.77056, 42.10109, 0],
					[-113.77077, 42.1012, 0],
					[-113.77115, 42.10138, 0],
					[-113.77159, 42.10161, 0],
					[-113.77233, 42.10209, 0],
					[-113.77246, 42.1022, 0],
					[-113.77271, 42.10244, 0],
					[-113.7728, 42.10255, 0],
					[-113.77286, 42.10261, 0],
					[-113.77291, 42.10267, 0],
					[-113.77298, 42.10277, 0],
					[-113.7731, 42.10302, 0],
					[-113.77317, 42.10324, 0],
					[-113.77321, 42.10345, 0],
					[-113.77322, 42.10354, 0],
					[-113.77324, 42.10364, 0],
					[-113.77329, 42.10398, 0],
					[-113.77335, 42.10425, 0],
					[-113.77338, 42.10443, 0],
					[-113.77338, 42.10445, 0],
					[-113.77343, 42.10477, 0],
					[-113.77349, 42.10505, 0],
					[-113.77354, 42.10541, 0],
					[-113.77359, 42.10558, 0],
					[-113.77368, 42.10583, 0],
					[-113.77379, 42.10605, 0],
					[-113.77382, 42.10613, 0],
					[-113.77387, 42.10622, 0],
					[-113.77394, 42.10632, 0],
					[-113.774, 42.10642, 0],
					[-113.77418, 42.10668, 0],
					[-113.77423, 42.10674, 0],
					[-113.77433, 42.10688, 0],
					[-113.77442, 42.10696, 0],
					[-113.77459, 42.10713, 0],
					[-113.77486, 42.10733, 0],
					[-113.77635, 42.10826, 0],
					[-113.7773, 42.10888, 0],
					[-113.7777, 42.1091, 0],
					[-113.77794, 42.10925, 0],
					[-113.77805, 42.10931, 0],
					[-113.77818, 42.10943, 0],
					[-113.77822, 42.1095, 0],
					[-113.77828, 42.10963, 0],
					[-113.77828, 42.10977, 0],
					[-113.77827, 42.10982, 0],
					[-113.77819, 42.10997, 0],
					[-113.77812, 42.11008, 0],
					[-113.77799, 42.11025, 0],
					[-113.77785, 42.11041, 0],
					[-113.77763, 42.11069, 0],
					[-113.77759, 42.11076, 0],
					[-113.77757, 42.11078, 0],
					[-113.77745, 42.11097, 0],
					[-113.77732, 42.11122, 0],
					[-113.77723, 42.11146, 0],
					[-113.7772, 42.11158, 0],
					[-113.77718, 42.11163, 0],
					[-113.77701, 42.11227, 0],
					[-113.77679, 42.11277, 0],
					[-113.77675, 42.1129, 0],
					[-113.77675, 42.11308, 0],
					[-113.77676, 42.11314, 0],
					[-113.77678, 42.11322, 0],
					[-113.77679, 42.11328, 0],
					[-113.77682, 42.11335, 0],
					[-113.77694, 42.11354, 0],
					[-113.77706, 42.11377, 0],
					[-113.77706, 42.11379, 0],
					[-113.77712, 42.11392, 0],
					[-113.77722, 42.11419, 0],
					[-113.77729, 42.11433, 0],
					[-113.7774, 42.11451, 0],
					[-113.77754, 42.11469, 0],
					[-113.77906, 42.11624, 0],
					[-113.78241, 42.11996, 0],
					[-113.783, 42.12065, 0],
					[-113.78314, 42.12083, 0],
					[-113.7832, 42.12092, 0],
					[-113.78328, 42.12109, 0],
					[-113.78331, 42.12126, 0],
					[-113.78331, 42.12132, 0],
					[-113.78329, 42.12141, 0],
					[-113.78329, 42.12142, 0],
					[-113.78328, 42.12148, 0],
					[-113.78325, 42.12155, 0],
					[-113.78308, 42.1221, 0],
					[-113.78308, 42.12227, 0],
					[-113.78309, 42.12235, 0],
					[-113.78309, 42.12243, 0],
					[-113.78313, 42.12263, 0],
					[-113.78318, 42.1228, 0],
					[-113.78322, 42.12297, 0],
					[-113.78324, 42.12302, 0],
					[-113.78327, 42.12315, 0],
					[-113.78334, 42.12332, 0],
					[-113.78335, 42.12336, 0],
					[-113.78342, 42.12355, 0],
					[-113.78357, 42.12386, 0],
					[-113.78385, 42.12438, 0],
					[-113.784, 42.12469, 0],
					[-113.78404, 42.12481, 0],
					[-113.78408, 42.12496, 0],
					[-113.78408, 42.12503, 0],
					[-113.78409, 42.1251, 0],
					[-113.78408, 42.12526, 0],
					[-113.78408, 42.12539, 0],
					[-113.78407, 42.12549, 0],
					[-113.7841, 42.12569, 0],
					[-113.78419, 42.12591, 0],
					[-113.78424, 42.12597, 0],
					[-113.78429, 42.12605, 0],
					[-113.78471, 42.12642, 0],
					[-113.78475, 42.12647, 0],
					[-113.78481, 42.12652, 0],
					[-113.78517, 42.12696, 0],
					[-113.78541, 42.12722, 0],
					[-113.78562, 42.12747, 0],
					[-113.78572, 42.12756, 0],
					[-113.78609, 42.12785, 0],
					[-113.78618, 42.12789, 0],
					[-113.78619, 42.1279, 0],
					[-113.78689, 42.12822, 0],
					[-113.78724, 42.12835, 0],
					[-113.78728, 42.12836, 0],
					[-113.78751, 42.12844, 0],
					[-113.78836, 42.12877, 0],
					[-113.78875, 42.12897, 0],
					[-113.78912, 42.12919, 0],
					[-113.78939, 42.12939, 0],
					[-113.78956, 42.12954, 0],
					[-113.78972, 42.12973, 0],
					[-113.78978, 42.12981, 0],
					[-113.7899, 42.13002, 0],
					[-113.78998, 42.13019, 0],
					[-113.78999, 42.13023, 0],
					[-113.79018, 42.13079, 0],
					[-113.79029, 42.13116, 0],
					[-113.79039, 42.13139, 0],
					[-113.79044, 42.13149, 0],
					[-113.79058, 42.1317, 0],
					[-113.79072, 42.13184, 0],
					[-113.79132, 42.13232, 0],
					[-113.79155, 42.13248, 0],
					[-113.7917, 42.13263, 0],
					[-113.79178, 42.13273, 0],
					[-113.79181, 42.13276, 0],
					[-113.79184, 42.1328, 0],
					[-113.79249, 42.13421, 0],
					[-113.79307, 42.13541, 0],
					[-113.79312, 42.13554, 0],
					[-113.79334, 42.13604, 0],
					[-113.79356, 42.13662, 0],
					[-113.79364, 42.13686, 0],
					[-113.79367, 42.13697, 0],
					[-113.79369, 42.13708, 0],
					[-113.79372, 42.13718, 0],
					[-113.79372, 42.13727, 0],
					[-113.79375, 42.13751, 0],
					[-113.79376, 42.13752, 0],
					[-113.79377, 42.13765, 0],
					[-113.79382, 42.13803, 0],
					[-113.79382, 42.13843, 0],
					[-113.7938, 42.13859, 0],
					[-113.79376, 42.13881, 0],
					[-113.79373, 42.13904, 0],
					[-113.79373, 42.13926, 0],
					[-113.79375, 42.13944, 0],
					[-113.79383, 42.13962, 0],
					[-113.79392, 42.1398, 0],
					[-113.79416, 42.14009, 0],
					[-113.79449, 42.14038, 0],
					[-113.79535, 42.14099, 0],
					[-113.79749, 42.14242, 0],
					[-113.79764, 42.14254, 0],
					[-113.79802, 42.14295, 0],
					[-113.79834, 42.14338, 0],
					[-113.79962, 42.14492, 0],
					[-113.79968, 42.14502, 0],
					[-113.79972, 42.1451, 0],
					[-113.79976, 42.14521, 0],
					[-113.79977, 42.14525, 0],
					[-113.7998, 42.14532, 0],
					[-113.79982, 42.14542, 0],
					[-113.79983, 42.14554, 0],
					[-113.79981, 42.14567, 0],
					[-113.7998, 42.14578, 0],
					[-113.7998, 42.14582, 0],
					[-113.79975, 42.14618, 0],
					[-113.7997, 42.14682, 0],
					[-113.7997, 42.14686, 0],
					[-113.79959, 42.14829, 0],
					[-113.79958, 42.14864, 0],
					[-113.79959, 42.14876, 0],
					[-113.79963, 42.14906, 0],
					[-113.79988, 42.14979, 0],
					[-113.79994, 42.1499, 0],
					[-113.80027, 42.15067, 0],
					[-113.80038, 42.15096, 0],
					[-113.80044, 42.15116, 0],
					[-113.80049, 42.15137, 0],
					[-113.80073, 42.15287, 0],
					[-113.80073, 42.1529, 0],
					[-113.80089, 42.15378, 0],
					[-113.80134, 42.15683, 0],
					[-113.80147, 42.15749, 0],
					[-113.80161, 42.15794, 0],
					[-113.80165, 42.15804, 0],
					[-113.80167, 42.15807, 0],
					[-113.80178, 42.15829, 0],
					[-113.80184, 42.15839, 0],
					[-113.8019, 42.15847, 0],
					[-113.80208, 42.15874, 0],
					[-113.80405, 42.16118, 0],
					[-113.80462, 42.16181, 0],
					[-113.8059, 42.16283, 0],
					[-113.80663, 42.16337, 0],
					[-113.8068, 42.16351, 0],
					[-113.80761, 42.16412, 0],
					[-113.80764, 42.16415, 0],
					[-113.80806, 42.16449, 0],
					[-113.80849, 42.16492, 0],
					[-113.80878, 42.16531, 0],
					[-113.80912, 42.16581, 0],
					[-113.80972, 42.16685, 0],
					[-113.81069, 42.16846, 0],
					[-113.81175, 42.17014, 0],
					[-113.81203, 42.17056, 0],
					[-113.81433, 42.17332, 0],
					[-113.81469, 42.17373, 0],
					[-113.81503, 42.17407, 0],
					[-113.81853, 42.17729, 0],
					[-113.81913, 42.17788, 0],
					[-113.81943, 42.17828, 0],
					[-113.81946, 42.17834, 0],
					[-113.81961, 42.17859, 0],
					[-113.81977, 42.17902, 0],
					[-113.81995, 42.17955, 0],
					[-113.82048, 42.18136, 0],
					[-113.82096, 42.18315, 0],
					[-113.82117, 42.18378, 0],
					[-113.82137, 42.18425, 0],
					[-113.82154, 42.18453, 0],
					[-113.82173, 42.1848, 0],
					[-113.82193, 42.18503, 0],
					[-113.82197, 42.18507, 0],
					[-113.8226, 42.18561, 0],
					[-113.82334, 42.1862, 0],
					[-113.82358, 42.18641, 0],
					[-113.82367, 42.18648, 0],
					[-113.82432, 42.18705, 0],
					[-113.82455, 42.1873, 0],
					[-113.82474, 42.18754, 0],
					[-113.82485, 42.1877, 0],
					[-113.82494, 42.18786, 0],
					[-113.82507, 42.18812, 0],
					[-113.82522, 42.18839, 0],
					[-113.82537, 42.18863, 0],
					[-113.82558, 42.18892, 0],
					[-113.82605, 42.18939, 0],
					[-113.8289, 42.1919, 0],
					[-113.82921, 42.19222, 0],
					[-113.82934, 42.19239, 0],
					[-113.82944, 42.19256, 0],
					[-113.82958, 42.19288, 0],
					[-113.82965, 42.19302, 0],
					[-113.82978, 42.19339, 0],
					[-113.83037, 42.19533, 0],
					[-113.83064, 42.19612, 0],
					[-113.83088, 42.1966, 0],
					[-113.8311, 42.19684, 0],
					[-113.83143, 42.19714, 0],
					[-113.83324, 42.19861, 0],
					[-113.83354, 42.19884, 0],
					[-113.83403, 42.19924, 0],
					[-113.83433, 42.19947, 0],
					[-113.83546, 42.2004, 0],
					[-113.83578, 42.20073, 0],
					[-113.83596, 42.20094, 0],
					[-113.83616, 42.20122, 0],
					[-113.8364, 42.20168, 0],
					[-113.83641, 42.20171, 0],
					[-113.83886, 42.20692, 0],
					[-113.83887, 42.20695, 0],
					[-113.84017, 42.20969, 0],
					[-113.84025, 42.20984, 0],
					[-113.84025, 42.20985, 0],
					[-113.84389, 42.21761, 0],
					[-113.84428, 42.21839, 0],
					[-113.84474, 42.21917, 0],
					[-113.84613, 42.22126, 0],
					[-113.84614, 42.22126, 0],
					[-113.85228, 42.23037, 0],
					[-113.85285, 42.23126, 0],
					[-113.85864, 42.23984, 0],
					[-113.85893, 42.24023, 0],
					[-113.85931, 42.24068, 0],
					[-113.8595, 42.24087, 0],
					[-113.85985, 42.24118, 0],
					[-113.86004, 42.24132, 0],
					[-113.86008, 42.24134, 0],
					[-113.86055, 42.24167, 0],
					[-113.86088, 42.24187, 0],
					[-113.86155, 42.24221, 0],
					[-113.86198, 42.24238, 0],
					[-113.86244, 42.24252, 0],
					[-113.86338, 42.24278, 0],
					[-113.8639, 42.24289, 0],
					[-113.86513, 42.24292, 0],
					[-113.87227, 42.24299, 0],
					[-113.87283, 42.24301, 0],
					[-113.87853, 42.24304, 0],
					[-113.87865, 42.24305, 0],
					[-113.88206, 42.24307, 0],
				],
			},
			id: "76b96db6-3cf7-4337-89a4-d4cea422766a",
		},
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Albion, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-113.57817, 42.41255, 0],
		// 	},
		// 	id: "6dace047-1a4a-4374-afad-3a2e2a04010c",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Almo, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-113.63354, 42.1, 0],
		// 	},
		// 	id: "b1d3eed2-bb83-425e-85a2-6eca9b8fd2ff",
		// },
		// {
		// 	type: "Feature",
		// 	properties: {
		// 		shape: "Circle",
		// 		radius: 2000,
		// 		name: "Oakley, Idaho",
		// 		category: "default",
		// 	},
		// 	geometry: {
		// 		type: "Point",
		// 		coordinates: [-113.88206, 42.24307, 0],
		// 	},
		// 	id: "ad468a46-03eb-4e9a-a0ac-c6ede8dd3144",
		// },
	],
};

export default CityOfRocks;
