import { createReducer } from "@reduxjs/toolkit";
import { updateItineraryName, updateItineraryId, updateItineraryStatus } from "../actions/itineraryActions";

const initialState = {
    itineraryName: '',
    itineraryId: '',
    itineraryStatus: ''
}

const itineraryReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(updateItineraryName, (state, action) => {
            state.itineraryName = action.payload;
        })
        .addCase(updateItineraryId, (state, action) => {
            localStorage.setItem("itinerary", action.payload);
            state.itineraryId = action.payload;
        })
        .addCase(updateItineraryStatus, (state, action) => {
            state.itineraryStatus = action.payload;
        })
});

export default itineraryReducer;