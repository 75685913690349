//REDUX-RTK
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

//REACT
import {
	PDFDownloadLink,
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Link,
	Image
} from "@react-pdf/renderer";
import QRCode from "qrcode";

//LOCAL DATA
import { IconExport } from "../../images/icons";
import Logo from '../../images/pdf-svg/logo';
import IconLegend from '../../images/pdf-svg/legend';
import IconScenicByways from "../../images/pdf-svg/scenic-bways";
import IconBikingRecreation from "../../images/pdf-svg/biking-recreation";
import IconRoadSideAttractions from "../../images/pdf-svg/roadside-attractions";
import IconOutdoorRecreation from "../../images/pdf-svg/outdoor-recreation";
import IconWildlife from "../../images/pdf-svg/wildlife";
import IconWinter from "../../images/pdf-svg/winter";
import IconNationalMonumentsHistoricSites from "../../images/pdf-svg/national-monuments-historical-sites";
import IconWaterRecreation from "../../images/pdf-svg/water-recreation";
import IconStateParks from "../../images/pdf-svg/state-parks";
import IconArtsCulture from '../../images/pdf-svg/arts-culture';
import IconCulinary from '../../images/pdf-svg/culinary';

const ExportPDF = (props) => {
	const { routes, cart, mapImage, allPoints, startingPoint, startAddress, finishAddress } = props;
	const itinerary = useSelector((state) => state.itinerary);
	const summary = useSelector((state) => state.routes.summary);
	const directions = useSelector((state) => state.routes.directions);	
    const travelTips = useSelector((state) => state.recommendations.travelTips);
	const ttcart = useSelector((state) => state.ttcart);
    const maxLength = props.maxLength ? props.maxLength : 4;

	const getGoogleMapsUrl = (startPoint, allPointsArray) => {
		const baseUrl = 'https://www.google.com/maps/dir/';
		
		const startPointUrl = startPoint;
		const waypointsUrl = allPointsArray.map(point => point.split(',').reverse().join(',')).join('/');
	  
		return `${baseUrl}${startPointUrl}/${waypointsUrl}`;
	};
	  
	const timeConvert = (d) => {
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor((d % 3600) / 60);

		var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
		var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes") : "";
		return hDisplay + mDisplay;
	};

	const metersToMiles = (m) => Math.trunc(m * 0.000621371192);
	const ExportAsPDF = () => {
		//replace with the saved itinerary url later
		//const QRCodeURL = itinerary.itineraryId ? window.location.origin + '/itinerary/' + itinerary.itineraryId : window.location.href;
		const QRCodeURL = getGoogleMapsUrl(startingPoint, allPoints);
		//console.log(QRCodeURL);
		const QRCodeImage = QRCode.toDataURL(QRCodeURL, { type: "jpeg" });
		let cartIndex = 0;

		const styles = StyleSheet.create({
			page: {
				backgroundColor: "#fff",
				color: "#000",
				fontSize: "12pt",
				paddingBottom: 60,
			},
			header: {
				backgroundColor: "#407f82",
				color: "#fff",
				display: "flex",
				flexDirection: "row",
				alignItems: 'center',
				justifyContent: "space-between",
				marginBottom: 30,
				padding: '10px 30px',
				textAlign: "center"
			},
			headerLink: {
				color: "#fff",
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				textDecoration: "none",
			},
			footer: {
				borderTop: "2px solid #ddd",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: 40,
				fontSize: "10pt",
				position: "absolute",
				bottom: 0,
				opacity: 0.5,
				padding: 10,
				textAlign: "center",
				width: "100%",
			},
			mapFlexRow: {
				display: "flex",
				flexDirection: "row",
				margin: "0 30px"
			},
			mapWrapper: {
				flexBasis: "65%",
				marginRight: 30,
			},
			legendWrapper: {
				flexBasis: "35%"
			},
			legendContent: {
				backgroundColor: "#d9e9ea",
				padding: 15
			},
			iconFlexRow: {
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				marginLeft: -5,
				paddingRight:5
			},
			iconFlexRowText: {
				marginLeft: 8
			},
			headingText: {
				fontSize: "16pt",
				fontWeight: "bold",
				marginBottom: 10,
			},
			qrCodeLink: {
				backgroundColor: "#f2f2f2",
				fontSize: "10pt",
				fontStyle: "italic",
				marginTop: 20,
				padding: 10,
				textAlign: "center",
				textDecoration: "none",
			},
			qrCodeImage: {
				margin: "0 auto 10px",
				width: "100%",
				height: "auto",
			},
			directions: {
				margin: 30
			},
			directionStep: {
				marginBottom: 0
			},
			directionInfo: {
				fontSize: "10pt",
				marginTop: 0,
				marginBottom: 7
			},
			directionWaypoint: {
				backgroundColor: "#d9e9ea",
				padding: "15px 15px 10px",
				margin: "10px 0 15px"
			},
			recommendations: {
				margin: "0 30px"
			},
			travelTip: {
				color: "#407f82",
				margin: "15px 0 0"
			}
		});

		return (
			<Document
				title={
					itinerary.itineraryName && itinerary.itineraryName !== ""
						? itinerary.itineraryName
						: "Visit Idaho Travel Assistant Itinerary Directions"
				}
			>
				<Page size="A4" style={styles.page}>
					<View fixed style={styles.header}>
						<Link src="https://vita.visitidaho.org/" style={styles.headerLink}>
							<Logo />
						</Link>
						<Text>{itinerary.itineraryName && itinerary.itineraryName !== "" ? itinerary.itineraryName : "Itinerary Route"}</Text>
					</View>

					<View style={styles.mapFlexRow}>
						<Text style={{ paddingBottom:10 }}>
							Step-by-step driving directions begin on page two.
						</Text>
					</View>

					<View style={styles.mapFlexRow}>
						<View style={styles.mapWrapper}>
							<View style={{ backgroundColor: "#f2f2f2", padding: 10 }}>
								{mapImage && <Image
								    style={{ width: "100%", height: "500px", objectFit: "cover"}}
									allowDangerousPaths
									src={mapImage}
									level={"H"}
								/>}
							</View>
						</View>
						<View style={styles.legendWrapper}>
							<View style={styles.legendContent}>
								<Text style={styles.headingText}>Legend</Text>
								<View style={styles.iconFlexRow}>
									<IconArtsCulture />
									<Text style={styles.iconFlexRowText}>Arts & Culture</Text>
								</View>
								<View style={styles.iconFlexRow}>
									<IconBikingRecreation />
									<Text style={styles.iconFlexRowText}>Biking Recreation</Text>
								</View>
								<View style={styles.iconFlexRow}>
									<IconCulinary />
									<Text style={styles.iconFlexRowText}>Culinary</Text>
								</View>
								<View style={styles.iconFlexRow}>
									<IconNationalMonumentsHistoricSites />
									<Text style={styles.iconFlexRowText}>Historical Sites</Text>
								</View>
								<View style={styles.iconFlexRow}>
									<IconOutdoorRecreation />
									<Text style={styles.iconFlexRowText}>Outdoor Recreation</Text>
								</View>
								<View style={styles.iconFlexRow}>
									<IconRoadSideAttractions />
									<Text style={styles.iconFlexRowText}>Roadside Attractions</Text>
								</View>
								<View style={styles.iconFlexRow}>
									<IconScenicByways />
									<Text style={styles.iconFlexRowText}>Scenic Byways</Text>
								</View>
								<View style={styles.iconFlexRow}>
									<IconStateParks />
									<Text style={styles.iconFlexRowText}>State Parks</Text>
								</View>
								<View style={styles.iconFlexRow}>
									<IconWaterRecreation />
									<Text style={styles.iconFlexRowText}>Water Recreation</Text>
								</View>
								<View style={styles.iconFlexRow}>
									<IconWildlife />
									<Text style={styles.iconFlexRowText}>Wildlife</Text>
								</View>
								<View style={styles.iconFlexRow}>
									<IconWinter />
									<Text style={styles.iconFlexRowText}>Winter</Text>
								</View>
							</View>
							<Link src={QRCodeURL} style={styles.qrCodeLink}>
								{QRCodeImage && <Image
									style={styles.qrCodeImage}
									allowDangerousPaths
									src={QRCodeImage}
									size={250}
									bgColor={"#fffff"}
									fgColor={"#000"}
									level={"H"}
									includeMargin={true}
								/>}
								<Text style={{ color: "#b7562d" }}>
									Scan or tap to open itinerary in Google Maps.
								</Text>
							</Link>
						</View>
					</View>
					<View style={styles.iconFlexRow}>
						<Text style={{marginBottom:'100px'}}></Text>
					</View>
					{(directions.length > 0) && 
						<View style={styles.directions}>
							<Text style={styles.headingText}>Directions</Text>
							<Text>Starting from: {startAddress}</Text>
							<Text>
								Distance: {metersToMiles(summary.totalDistance)} miles
							</Text>
							<Text
								style={{
									borderBottom: "2px solid #f2f2f2",
									marginBottom: 5,
									paddingBottom: 5,
								}}
							>
								Est. Travel Time: {timeConvert(summary.totalTime)}
							</Text>
							<View>
								<View style={[styles.directionWaypoint, styles.iconFlexRow]}>
									<IconLegend />
									<Text style={styles.iconFlexRowText}>First stop: {cart[0].title}</Text>
								</View>
								{directions.map((item, index) => {
									let type = item.type;
									if (type === 'arrive') {
										let destination = cart[cartIndex]?.title || finishAddress;
										//let destination2 = cart[cartIndex + 1].title;
										cartIndex++;
										return (
											<>
												<View key={index+'a'} wrap={false} style={styles.directionWaypoint}>
													<View style={[styles.iconFlexRow, { marginBottom: 8 }]}>
														<IconLegend />
														<Text style={styles.iconFlexRowText}>You've arrived at {destination}</Text>
													</View>
													{/* <Text>Next Stop: {destination2}</Text> */}
												</View>
											</>
										)
									}
									// if (type === 'DestinationReached') {
									// 	let destination = cart[cartIndex].title;
									// 	cartIndex++;
									// 	return (
									// 		<>
									// 			<Text key={index} style={styles.directionWaypoint}>You've arrived at {destination}</Text>
									// 		</>
									// 	)
									// }
									return (
										<>
											<Text key={index+'b'} style={styles.directionStep}>
												{index + 1}. {item.text}
											</Text>
											<Text key={index+'c'} style={styles.directionInfo}>
												{item.distance > 0 ? item.distance + ((item.distance == 1) ? ' mile.' : ' miles.') : 'Less than 1 mile.'}
											</Text>
										</>
									);
								})}
							</View>
						</View>
					}
					{(ttcart && ttcart.cartItems.length > 0) &&
						<View style={styles.recommendations}>						
							<Text style={styles.headingText}>Your Travel Tips</Text>
							<Text>Read related stories for more Idaho vacation inspiration.</Text>
							{ttcart.cartItems.map((item, index) => {
								return (
									<>
										{index < maxLength &&
											<Link key={`recommendation-${index}`} src={`https://visitidaho.org/?p=${item.id}`} style={styles.travelTip}>{item.title}</Link>
										}
									</>
								)
							})}
						</View>
					}
					{/* <View fixed style={styles.footer}>
						<Text>FOOTER</Text>
					</View> */}
				</Page>
			</Document>
		);
	};

	return (
		<>
			{(itinerary && mapImage) ?
				<PDFDownloadLink
					document={<ExportAsPDF />}
					fileName={`${itinerary.itineraryName && itinerary.itineraryName !== ""
						? itinerary.itineraryName
						: "itinerary-directions"
						}.pdf`}
				>
					{({ blob, url, loading, error }) => (
						<>
							<IconExport width="18" /> <span>{loading ? 'Updating...' : 'Export'}</span>
						</>
					)}
				</PDFDownloadLink>
			: 
			<>
				<IconExport width="18" /> <span>Updating...</span>
			</>}
		</>
	);
}

export default ExportPDF;
