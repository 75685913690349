const sToHMTimeConvert = (seconds) => {
    let s = Number(seconds);
    const h = parseInt(s / 3600);
    s = s % 3600;
    const m = parseInt(s / 60);
    s = s % 60;
    const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " minute" : " minutes") : "";
    return hDisplay + mDisplay;
}

const metersToMiles = (m) => Math.trunc(m * 0.000621371192)

const getMapBoxUrl = (optimizedCheck, startPoint, allPoints) => {
    return optimizedCheck === true
        ? `https://api.mapbox.com/optimized-trips/v1/mapbox/driving/${startPoint}${allPoints}?access_token=pk.eyJ1IjoidmlzaXRpZGFobyIsImEiOiJjbDlveHd3dDkwZWNyM3dsYjJtemF5b3d6In0.Mz0XqoMjxh3PlAeXFGvkfw&roundtrip=false&source=first&destination=last&steps=true&overview=full`
        : `https://api.mapbox.com/directions/v5/mapbox/driving/${startPoint}${allPoints}?access_token=pk.eyJ1IjoidmlzaXRpZGFobyIsImEiOiJjbDlveHd3dDkwZWNyM3dsYjJtemF5b3d6In0.Mz0XqoMjxh3PlAeXFGvkfw&steps=true&overview=full`;
};

export {
    sToHMTimeConvert,
    metersToMiles,
    getMapBoxUrl
}