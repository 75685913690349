const OwyheeUplands = {
    type: "FeatureCollection",
    features: [{
        type: "Feature",
        properties: {
            name: "Owyhee Uplands Backcountry Byway",
            "description": "Take a trip on the Owyhee Uplands Backcountry Byway and embark on a one-of-a-kind desert adventure. Journey through picturesque desert landscapes and juniper woodlands, all while taking in the breathtaking views of the Owyhee Mountains. Plan a little detour around the byway to visit Bruneau Dunes State Park or Silver City-one of Idaho's fascinating ghost towns.",
            "link": "https://visitidaho.org/things-to-do/road-trips/owyhee-uplands-backcountry-byway/",
            "route": [
                "Grand View", 
                "Jordan Valley, Oregon"
            ],
            "shape": "Line"
        },
        geometry: {
            type: "LineString",
            coordinates: [
                [-116.09716, 42.98332, 0],
                [-116.09609, 42.98318, 0],
                [-116.09488, 42.98297, 0],
                [-116.09094, 42.98212, 0],
                [-116.09084, 42.98209, 0],
                [-116.09017, 42.98194, 0],
                [-116.0896, 42.98177, 0],
                [-116.08917, 42.98162, 0],
                [-116.08855, 42.98137, 0],
                [-116.08802, 42.98111, 0],
                [-116.08785, 42.98101, 0],
                [-116.08764, 42.9809, 0],
                [-116.08709, 42.98056, 0],
                [-116.08641, 42.98011, 0],
                [-116.08493, 42.97923, 0],
                [-116.08335, 42.97823, 0],
                [-116.08287, 42.97795, 0],
                [-116.08161, 42.97714, 0],
                [-116.08104, 42.97671, 0],
                [-116.08065, 42.97638, 0],
                [-116.07995, 42.97572, 0],
                [-116.07938, 42.97508, 0],
                [-116.07887, 42.97443, 0],
                [-116.07871, 42.97421, 0],
                [-116.0784, 42.97373, 0],
                [-116.07798, 42.97285, 0],
                [-116.07786, 42.97257, 0],
                [-116.07768, 42.97204, 0],
                [-116.07754, 42.97155, 0],
                [-116.07739, 42.97087, 0],
                [-116.0773, 42.97012, 0],
                [-116.07728, 42.96984, 0],
                [-116.07727, 42.96942, 0],
                [-116.07729, 42.969, 0],
                [-116.0773, 42.96795, 0],
                [-116.07716, 42.96104, 0],
                [-116.07708, 42.96065, 0],
                [-116.07711, 42.9606, 0],
                [-116.07712, 42.96056, 0],
                [-116.07712, 42.96054, 0],
                [-116.07713, 42.96048, 0],
                [-116.07715, 42.95982, 0],
                [-116.07711, 42.95839, 0],
                [-116.07704, 42.95171, 0],
                [-116.07695, 42.94737, 0],
                [-116.07693, 42.94436, 0],
                [-116.07689, 42.94336, 0],
                [-116.0769, 42.94184, 0],
                [-116.07683, 42.94157, 0],
                [-116.07685, 42.93785, 0],
                [-116.07686, 42.93767, 0],
                [-116.07686, 42.93659, 0],
                [-116.07687, 42.93641, 0],
                [-116.07707, 42.90371, 0],
                [-116.07705, 42.89652, 0],
                [-116.07708, 42.89608, 0],
                [-116.07713, 42.89577, 0],
                [-116.0772, 42.8955, 0],
                [-116.08111, 42.88274, 0],
                [-116.08125, 42.88245, 0],
                [-116.08136, 42.88226, 0],
                [-116.08291, 42.88, 0],
                [-116.08333, 42.87934, 0],
                [-116.08374, 42.87861, 0],
                [-116.08394, 42.87818, 0],
                [-116.08409, 42.87779, 0],
                [-116.08422, 42.8774, 0],
                [-116.08423, 42.87738, 0],
                [-116.08435, 42.87682, 0],
                [-116.0844, 42.87651, 0],
                [-116.08444, 42.87613, 0],
                [-116.08446, 42.87563, 0],
                [-116.08445, 42.87534, 0],
                [-116.08443, 42.8751, 0],
                [-116.08438, 42.87472, 0],
                [-116.08438, 42.87467, 0],
                [-116.08431, 42.87427, 0],
                [-116.0842, 42.87388, 0],
                [-116.08355, 42.87126, 0],
                [-116.08319, 42.87004, 0],
                [-116.08316, 42.86978, 0],
                [-116.08319, 42.86961, 0],
                [-116.08323, 42.86944, 0],
                [-116.08409, 42.86697, 0],
                [-116.08428, 42.86649, 0],
                [-116.0846, 42.86583, 0],
                [-116.08462, 42.86577, 0],
                [-116.08659, 42.86179, 0],
                [-116.08662, 42.86171, 0],
                [-116.08984, 42.85511, 0],
                [-116.08984, 42.8551, 0],
                [-116.08993, 42.85494, 0],
                [-116.09086, 42.85308, 0],
                [-116.09142, 42.85189, 0],
                [-116.09158, 42.8515, 0],
                [-116.09174, 42.85117, 0],
                [-116.09186, 42.85097, 0],
                [-116.09212, 42.85063, 0],
                [-116.09232, 42.85042, 0],
                [-116.09254, 42.85022, 0],
                [-116.09282, 42.85, 0],
                [-116.09335, 42.84965, 0],
                [-116.10348, 42.84368, 0],
                [-116.1035, 42.84368, 0],
                [-116.10519, 42.8427, 0],
                [-116.10522, 42.84269, 0],
                [-116.10582, 42.8424, 0],
                [-116.10584, 42.8424, 0],
                [-116.10612, 42.84229, 0],
                [-116.10675, 42.84213, 0],
                [-116.1073, 42.84204, 0],
                [-116.10772, 42.84201, 0],
                [-116.1157, 42.84113, 0],
                [-116.11582, 42.84111, 0],
                [-116.11606, 42.84109, 0],
                [-116.11707, 42.84096, 0],
                [-116.11758, 42.84084, 0],
                [-116.11779, 42.84077, 0],
                [-116.11799, 42.84068, 0],
                [-116.11822, 42.84056, 0],
                [-116.11843, 42.84043, 0],
                [-116.11863, 42.84027, 0],
                [-116.11886, 42.84006, 0],
                [-116.12125, 42.83748, 0],
                [-116.12162, 42.83713, 0],
                [-116.12192, 42.83691, 0],
                [-116.12277, 42.83633, 0],
                [-116.12337, 42.83595, 0],
                [-116.12347, 42.8359, 0],
                [-116.12374, 42.83573, 0],
                [-116.12426, 42.83547, 0],
                [-116.12427, 42.83547, 0],
                [-116.13336, 42.83132, 0],
                [-116.13583, 42.83023, 0],
                [-116.14396, 42.8265, 0],
                [-116.15099, 42.82362, 0],
                [-116.15159, 42.82344, 0],
                [-116.15818, 42.82196, 0],
                [-116.1586, 42.82183, 0],
                [-116.16168, 42.82063, 0],
                [-116.16237, 42.82034, 0],
                [-116.16286, 42.82008, 0],
                [-116.16325, 42.81985, 0],
                [-116.16326, 42.81985, 0],
                [-116.16352, 42.8197, 0],
                [-116.16375, 42.81959, 0],
                [-116.16458, 42.81923, 0],
                [-116.16514, 42.81901, 0],
                [-116.16864, 42.81774, 0],
                [-116.16909, 42.81759, 0],
                [-116.16943, 42.81743, 0],
                [-116.16964, 42.81727, 0],
                [-116.17016, 42.81676, 0],
                [-116.17034, 42.81655, 0],
                [-116.17035, 42.81655, 0],
                [-116.17076, 42.81608, 0],
                [-116.17116, 42.81566, 0],
                [-116.17127, 42.81556, 0],
                [-116.17148, 42.8154, 0],
                [-116.17187, 42.81514, 0],
                [-116.17226, 42.8149, 0],
                [-116.17244, 42.81482, 0],
                [-116.17249, 42.81479, 0],
                [-116.17296, 42.81461, 0],
                [-116.17314, 42.81456, 0],
                [-116.1735, 42.8145, 0],
                [-116.17369, 42.81448, 0],
                [-116.1739, 42.81448, 0],
                [-116.17433, 42.8145, 0],
                [-116.17501, 42.81458, 0],
                [-116.17705, 42.81469, 0],
                [-116.17732, 42.81469, 0],
                [-116.17767, 42.81466, 0],
                [-116.17772, 42.81466, 0],
                [-116.17833, 42.81457, 0],
                [-116.17888, 42.81441, 0],
                [-116.17926, 42.81427, 0],
                [-116.17954, 42.81415, 0],
                [-116.18015, 42.81385, 0],
                [-116.18036, 42.81376, 0],
                [-116.18452, 42.81171, 0],
                [-116.1849, 42.81154, 0],
                [-116.18531, 42.81139, 0],
                [-116.18561, 42.8113, 0],
                [-116.18596, 42.81122, 0],
                [-116.18618, 42.81118, 0],
                [-116.18665, 42.81112, 0],
                [-116.18889, 42.81095, 0],
                [-116.18914, 42.81092, 0],
                [-116.18921, 42.81092, 0],
                [-116.18954, 42.81087, 0],
                [-116.18985, 42.8108, 0],
                [-116.19054, 42.81061, 0],
                [-116.2275, 42.79878, 0],
                [-116.22774, 42.79869, 0],
                [-116.22796, 42.79863, 0],
                [-116.22831, 42.79851, 0],
                [-116.22861, 42.79838, 0],
                [-116.22899, 42.79819, 0],
                [-116.22918, 42.798, 0],
                [-116.23553, 42.79099, 0],
                [-116.24012, 42.78605, 0],
                [-116.24042, 42.78568, 0],
                [-116.2421, 42.78386, 0],
                [-116.24212, 42.78383, 0],
                [-116.24526, 42.78045, 0],
                [-116.24567, 42.77997, 0],
                [-116.24569, 42.77996, 0],
                [-116.24586, 42.77978, 0],
                [-116.24609, 42.77956, 0],
                [-116.24651, 42.77922, 0],
                [-116.24852, 42.77799, 0],
                [-116.24852, 42.77798, 0],
                [-116.24868, 42.7779, 0],
                [-116.24909, 42.77766, 0],
                [-116.24962, 42.77742, 0],
                [-116.25253, 42.77628, 0],
                [-116.25396, 42.77576, 0],
                [-116.27478, 42.76774, 0],
                [-116.27575, 42.76739, 0],
                [-116.27614, 42.76728, 0],
                [-116.27786, 42.76701, 0],
                [-116.27808, 42.76696, 0],
                [-116.27856, 42.76682, 0],
                [-116.27874, 42.76674, 0],
                [-116.27896, 42.76666, 0],
                [-116.27951, 42.76643, 0],
                [-116.27952, 42.76642, 0],
                [-116.28036, 42.76607, 0],
                [-116.28137, 42.76569, 0],
                [-116.28173, 42.76554, 0],
                [-116.28179, 42.76552, 0],
                [-116.28198, 42.76544, 0],
                [-116.282, 42.76542, 0],
                [-116.28221, 42.76531, 0],
                [-116.28232, 42.76523, 0],
                [-116.28235, 42.76518, 0],
                [-116.28276, 42.76469, 0],
                [-116.28288, 42.76458, 0],
                [-116.28293, 42.76452, 0],
                [-116.28318, 42.76429, 0],
                [-116.2835, 42.76402, 0],
                [-116.28381, 42.76379, 0],
                [-116.28409, 42.76356, 0],
                [-116.28449, 42.76327, 0],
                [-116.28468, 42.76316, 0],
                [-116.28527, 42.76289, 0],
                [-116.28556, 42.76277, 0],
                [-116.28597, 42.76257, 0],
                [-116.28633, 42.76237, 0],
                [-116.28693, 42.76201, 0],
                [-116.28705, 42.76195, 0],
                [-116.28788, 42.76147, 0],
                [-116.2883, 42.76121, 0],
                [-116.28962, 42.76052, 0],
                [-116.29153, 42.75969, 0],
                [-116.29275, 42.75921, 0],
                [-116.29293, 42.75911, 0],
                [-116.29308, 42.75897, 0],
                [-116.2937, 42.75852, 0],
                [-116.29397, 42.75838, 0],
                [-116.29463, 42.75807, 0],
                [-116.29502, 42.75791, 0],
                [-116.29537, 42.75779, 0],
                [-116.29565, 42.75771, 0],
                [-116.29622, 42.7575, 0],
                [-116.29718, 42.75707, 0],
                [-116.29744, 42.75692, 0],
                [-116.29763, 42.75677, 0],
                [-116.29781, 42.7566, 0],
                [-116.29792, 42.75648, 0],
                [-116.29802, 42.75639, 0],
                [-116.29816, 42.75628, 0],
                [-116.29829, 42.75619, 0],
                [-116.29844, 42.75611, 0],
                [-116.29969, 42.75557, 0],
                [-116.30119, 42.75496, 0],
                [-116.30218, 42.75449, 0],
                [-116.30267, 42.75423, 0],
                [-116.30308, 42.75403, 0],
                [-116.3032, 42.75398, 0],
                [-116.30394, 42.75361, 0],
                [-116.30427, 42.75347, 0],
                [-116.30526, 42.75314, 0],
                [-116.30551, 42.75302, 0],
                [-116.30602, 42.75275, 0],
                [-116.30611, 42.75269, 0],
                [-116.3064, 42.75252, 0],
                [-116.3064, 42.75251, 0],
                [-116.30675, 42.7523, 0],
                [-116.30677, 42.75228, 0],
                [-116.30748, 42.75179, 0],
                [-116.3078, 42.75154, 0],
                [-116.30798, 42.75137, 0],
                [-116.30813, 42.75121, 0],
                [-116.30821, 42.75111, 0],
                [-116.30826, 42.75102, 0],
                [-116.30835, 42.75081, 0],
                [-116.30836, 42.7508, 0],
                [-116.30854, 42.75025, 0],
                [-116.30861, 42.75007, 0],
                [-116.30869, 42.74991, 0],
                [-116.30888, 42.74958, 0],
                [-116.30902, 42.7494, 0],
                [-116.30926, 42.74915, 0],
                [-116.30932, 42.74906, 0],
                [-116.30935, 42.749, 0],
                [-116.30936, 42.74899, 0],
                [-116.30938, 42.74894, 0],
                [-116.30941, 42.74882, 0],
                [-116.30942, 42.74872, 0],
                [-116.30943, 42.74823, 0],
                [-116.30949, 42.74778, 0],
                [-116.30957, 42.74756, 0],
                [-116.3097, 42.74725, 0],
                [-116.30985, 42.74697, 0],
                [-116.31004, 42.74671, 0],
                [-116.31021, 42.74652, 0],
                [-116.31025, 42.74649, 0],
                [-116.31034, 42.7464, 0],
                [-116.31069, 42.74613, 0],
                [-116.31079, 42.74603, 0],
                [-116.31086, 42.74593, 0],
                [-116.31093, 42.7458, 0],
                [-116.311, 42.74558, 0],
                [-116.31102, 42.74543, 0],
                [-116.311, 42.74523, 0],
                [-116.31096, 42.74442, 0],
                [-116.31097, 42.74405, 0],
                [-116.31098, 42.74394, 0],
                [-116.31095, 42.74367, 0],
                [-116.31084, 42.74328, 0],
                [-116.31084, 42.74326, 0],
                [-116.31083, 42.74319, 0],
                [-116.31083, 42.74308, 0],
                [-116.31089, 42.74286, 0],
                [-116.311, 42.74261, 0],
                [-116.31131, 42.74205, 0],
                [-116.31159, 42.7416, 0],
                [-116.31162, 42.74157, 0],
                [-116.31249, 42.74043, 0],
                [-116.31289, 42.73998, 0],
                [-116.31296, 42.73992, 0],
                [-116.31304, 42.73987, 0],
                [-116.31323, 42.73978, 0],
                [-116.3134, 42.73973, 0],
                [-116.3136, 42.73969, 0],
                [-116.31378, 42.73967, 0],
                [-116.31416, 42.73965, 0],
                [-116.31504, 42.73966, 0],
                [-116.31515, 42.73967, 0],
                [-116.31564, 42.73968, 0],
                [-116.31601, 42.73964, 0],
                [-116.31771, 42.73939, 0],
                [-116.31835, 42.73934, 0],
                [-116.3185, 42.7393, 0],
                [-116.31866, 42.73924, 0],
                [-116.31935, 42.73873, 0],
                [-116.31943, 42.73866, 0],
                [-116.31949, 42.73859, 0],
                [-116.31953, 42.73851, 0],
                [-116.31961, 42.73829, 0],
                [-116.31968, 42.73803, 0],
                [-116.31979, 42.7377, 0],
                [-116.31984, 42.7376, 0],
                [-116.31993, 42.73746, 0],
                [-116.31999, 42.73738, 0],
                [-116.32, 42.73735, 0],
                [-116.32009, 42.73726, 0],
                [-116.32076, 42.73652, 0],
                [-116.32104, 42.73624, 0],
                [-116.3211, 42.7362, 0],
                [-116.3211, 42.73619, 0],
                [-116.32116, 42.73614, 0],
                [-116.32125, 42.7361, 0],
                [-116.32131, 42.73606, 0],
                [-116.32383, 42.73485, 0],
                [-116.32436, 42.73463, 0],
                [-116.32466, 42.73452, 0],
                [-116.32486, 42.73446, 0],
                [-116.32527, 42.73437, 0],
                [-116.32536, 42.73436, 0],
                [-116.3259, 42.73436, 0],
                [-116.32615, 42.73434, 0],
                [-116.32655, 42.73427, 0],
                [-116.32724, 42.73409, 0],
                [-116.32865, 42.73352, 0],
                [-116.32873, 42.73348, 0],
                [-116.32882, 42.73345, 0],
                [-116.3289, 42.73341, 0],
                [-116.32891, 42.7334, 0],
                [-116.329, 42.73335, 0],
                [-116.32909, 42.73328, 0],
                [-116.32913, 42.73323, 0],
                [-116.32919, 42.73313, 0],
                [-116.32924, 42.73301, 0],
                [-116.32928, 42.73263, 0],
                [-116.32927, 42.73242, 0],
                [-116.32929, 42.73223, 0],
                [-116.32933, 42.73206, 0],
                [-116.32939, 42.73191, 0],
                [-116.3295, 42.73174, 0],
                [-116.3296, 42.73161, 0],
                [-116.32965, 42.73157, 0],
                [-116.3298, 42.73141, 0],
                [-116.33025, 42.731, 0],
                [-116.3304, 42.73085, 0],
                [-116.33045, 42.73078, 0],
                [-116.33052, 42.7307, 0],
                [-116.33058, 42.73059, 0],
                [-116.33072, 42.73037, 0],
                [-116.33087, 42.73021, 0],
                [-116.33113, 42.73001, 0],
                [-116.33126, 42.72995, 0],
                [-116.33138, 42.72991, 0],
                [-116.33156, 42.72987, 0],
                [-116.33166, 42.72984, 0],
                [-116.33184, 42.7298, 0],
                [-116.3323, 42.72967, 0],
                [-116.33254, 42.72957, 0],
                [-116.33282, 42.72938, 0],
                [-116.33287, 42.72932, 0],
                [-116.33294, 42.72925, 0],
                [-116.33371, 42.72824, 0],
                [-116.33373, 42.7282, 0],
                [-116.33393, 42.72793, 0],
                [-116.33414, 42.72768, 0],
                [-116.33427, 42.72755, 0],
                [-116.33441, 42.72744, 0],
                [-116.33458, 42.72733, 0],
                [-116.33473, 42.72726, 0],
                [-116.33497, 42.72718, 0],
                [-116.33525, 42.72712, 0],
                [-116.33549, 42.72708, 0],
                [-116.33596, 42.72704, 0],
                [-116.3361, 42.72704, 0],
                [-116.33644, 42.72707, 0],
                [-116.33666, 42.72711, 0],
                [-116.33693, 42.72718, 0],
                [-116.33694, 42.72718, 0],
                [-116.33765, 42.72737, 0],
                [-116.33823, 42.72747, 0],
                [-116.3386, 42.72751, 0],
                [-116.33877, 42.72751, 0],
                [-116.34009, 42.7274, 0],
                [-116.34075, 42.72726, 0],
                [-116.34105, 42.72716, 0],
                [-116.34145, 42.727, 0],
                [-116.34215, 42.72669, 0],
                [-116.34218, 42.72667, 0],
                [-116.3426, 42.72649, 0],
                [-116.34333, 42.72621, 0],
                [-116.34347, 42.72617, 0],
                [-116.34355, 42.72616, 0],
                [-116.34363, 42.72614, 0],
                [-116.34371, 42.72613, 0],
                [-116.34383, 42.72613, 0],
                [-116.34389, 42.72612, 0],
                [-116.34544, 42.72618, 0],
                [-116.34665, 42.72615, 0],
                [-116.34675, 42.72613, 0],
                [-116.34702, 42.7261, 0],
                [-116.34737, 42.726, 0],
                [-116.34754, 42.72592, 0],
                [-116.34811, 42.72559, 0],
                [-116.34813, 42.72557, 0],
                [-116.3507, 42.7239, 0],
                [-116.35079, 42.72385, 0],
                [-116.35094, 42.72375, 0],
                [-116.3511, 42.72366, 0],
                [-116.35199, 42.72326, 0],
                [-116.35272, 42.72296, 0],
                [-116.35371, 42.72262, 0],
                [-116.35493, 42.72228, 0],
                [-116.35534, 42.72214, 0],
                [-116.35553, 42.72206, 0],
                [-116.35569, 42.72197, 0],
                [-116.35581, 42.72188, 0],
                [-116.35608, 42.7216, 0],
                [-116.3562, 42.72142, 0],
                [-116.35651, 42.72087, 0],
                [-116.35673, 42.72041, 0],
                [-116.35675, 42.72028, 0],
                [-116.35674, 42.72021, 0],
                [-116.35673, 42.71995, 0],
                [-116.3567, 42.71958, 0],
                [-116.35671, 42.71942, 0],
                [-116.35675, 42.71932, 0],
                [-116.35679, 42.71926, 0],
                [-116.35691, 42.71916, 0],
                [-116.35701, 42.7191, 0],
                [-116.35717, 42.71902, 0],
                [-116.35765, 42.71881, 0],
                [-116.35789, 42.71865, 0],
                [-116.35807, 42.71851, 0],
                [-116.3589, 42.71741, 0],
                [-116.35915, 42.71704, 0],
                [-116.35945, 42.71663, 0],
                [-116.35957, 42.71645, 0],
                [-116.35961, 42.7164, 0],
                [-116.35984, 42.71597, 0],
                [-116.3599, 42.71581, 0],
                [-116.36, 42.71543, 0],
                [-116.36001, 42.71537, 0],
                [-116.36008, 42.71517, 0],
                [-116.36022, 42.71485, 0],
                [-116.36032, 42.71473, 0],
                [-116.36044, 42.71464, 0],
                [-116.36154, 42.71417, 0],
                [-116.36212, 42.71397, 0],
                [-116.36338, 42.7136, 0],
                [-116.36394, 42.71347, 0],
                [-116.36424, 42.71343, 0],
                [-116.36437, 42.71343, 0],
                [-116.36574, 42.71351, 0],
                [-116.3668, 42.71351, 0],
                [-116.36687, 42.7135, 0],
                [-116.36692, 42.7135, 0],
                [-116.36708, 42.71347, 0],
                [-116.36731, 42.71341, 0],
                [-116.36886, 42.71279, 0],
                [-116.36949, 42.71258, 0],
                [-116.36957, 42.71256, 0],
                [-116.36996, 42.71249, 0],
                [-116.37018, 42.71241, 0],
                [-116.37024, 42.71238, 0],
                [-116.37039, 42.71226, 0],
                [-116.37046, 42.71218, 0],
                [-116.37058, 42.71202, 0],
                [-116.37061, 42.71195, 0],
                [-116.37066, 42.71177, 0],
                [-116.37062, 42.71135, 0],
                [-116.3705, 42.71055, 0],
                [-116.3705, 42.7105, 0],
                [-116.37046, 42.71025, 0],
                [-116.37045, 42.71013, 0],
                [-116.37045, 42.71001, 0],
                [-116.37047, 42.70984, 0],
                [-116.37054, 42.70966, 0],
                [-116.37071, 42.70941, 0],
                [-116.37087, 42.70922, 0],
                [-116.37091, 42.70916, 0],
                [-116.37099, 42.70906, 0],
                [-116.37111, 42.70889, 0],
                [-116.37128, 42.7086, 0],
                [-116.37148, 42.70819, 0],
                [-116.37221, 42.70698, 0],
                [-116.37248, 42.70641, 0],
                [-116.37258, 42.70623, 0],
                [-116.37263, 42.70617, 0],
                [-116.37269, 42.70608, 0],
                [-116.37273, 42.70603, 0],
                [-116.37295, 42.70586, 0],
                [-116.37306, 42.70579, 0],
                [-116.37327, 42.70569, 0],
                [-116.37361, 42.70557, 0],
                [-116.37405, 42.70544, 0],
                [-116.37407, 42.70544, 0],
                [-116.37484, 42.70525, 0],
                [-116.37572, 42.70507, 0],
                [-116.37639, 42.70491, 0],
                [-116.37963, 42.70422, 0],
                [-116.38257, 42.70366, 0],
                [-116.38271, 42.70364, 0],
                [-116.38297, 42.70364, 0],
                [-116.38418, 42.70381, 0],
                [-116.38431, 42.70382, 0],
                [-116.3845, 42.70382, 0],
                [-116.38463, 42.70383, 0],
                [-116.38687, 42.70381, 0],
                [-116.38978, 42.70385, 0],
                [-116.3904, 42.70383, 0],
                [-116.3905, 42.70382, 0],
                [-116.39058, 42.70382, 0],
                [-116.39059, 42.70381, 0],
                [-116.39071, 42.70379, 0],
                [-116.39077, 42.70377, 0],
                [-116.39091, 42.70371, 0],
                [-116.39228, 42.70298, 0],
                [-116.39238, 42.70291, 0],
                [-116.39253, 42.70278, 0],
                [-116.39326, 42.70193, 0],
                [-116.39411, 42.70085, 0],
                [-116.39428, 42.70066, 0],
                [-116.39449, 42.70048, 0],
                [-116.39466, 42.70037, 0],
                [-116.39477, 42.70031, 0],
                [-116.39515, 42.70016, 0],
                [-116.39656, 42.69973, 0],
                [-116.39946, 42.69892, 0],
                [-116.40136, 42.69834, 0],
                [-116.40176, 42.69819, 0],
                [-116.40195, 42.69809, 0],
                [-116.40225, 42.69791, 0],
                [-116.40232, 42.69785, 0],
                [-116.40249, 42.69766, 0],
                [-116.40261, 42.69751, 0],
                [-116.4027, 42.69735, 0],
                [-116.40274, 42.69721, 0],
                [-116.40276, 42.69703, 0],
                [-116.40277, 42.69698, 0],
                [-116.40274, 42.69678, 0],
                [-116.40274, 42.69674, 0],
                [-116.40269, 42.69661, 0],
                [-116.40266, 42.69655, 0],
                [-116.40242, 42.69625, 0],
                [-116.40206, 42.69583, 0],
                [-116.40205, 42.69581, 0],
                [-116.40189, 42.69563, 0],
                [-116.40179, 42.69549, 0],
                [-116.40163, 42.6952, 0],
                [-116.40156, 42.69502, 0],
                [-116.40154, 42.69488, 0],
                [-116.40154, 42.69466, 0],
                [-116.40161, 42.69442, 0],
                [-116.40168, 42.69424, 0],
                [-116.40178, 42.69406, 0],
                [-116.40214, 42.69364, 0],
                [-116.40239, 42.69344, 0],
                [-116.40522, 42.69168, 0],
                [-116.40657, 42.69087, 0],
                [-116.41312, 42.68673, 0],
                [-116.41321, 42.68668, 0],
                [-116.41328, 42.68663, 0],
                [-116.41346, 42.68654, 0],
                [-116.4135, 42.68653, 0],
                [-116.41363, 42.68647, 0],
                [-116.41376, 42.68642, 0],
                [-116.41437, 42.68622, 0],
                [-116.41468, 42.68614, 0],
                [-116.41492, 42.6861, 0],
                [-116.41506, 42.68609, 0],
                [-116.41535, 42.68605, 0],
                [-116.41554, 42.68601, 0],
                [-116.41579, 42.68597, 0],
                [-116.41604, 42.68591, 0],
                [-116.41611, 42.6859, 0],
                [-116.41723, 42.68566, 0],
                [-116.41954, 42.68507, 0],
                [-116.42013, 42.68495, 0],
                [-116.42037, 42.68492, 0],
                [-116.42161, 42.68482, 0],
                [-116.42248, 42.68469, 0],
                [-116.42335, 42.68451, 0],
                [-116.42337, 42.6845, 0],
                [-116.42363, 42.68443, 0],
                [-116.42375, 42.68438, 0],
                [-116.42416, 42.68413, 0],
                [-116.42448, 42.68391, 0],
                [-116.42472, 42.68376, 0],
                [-116.42493, 42.68367, 0],
                [-116.4256, 42.68335, 0],
                [-116.42744, 42.68255, 0],
                [-116.42795, 42.68236, 0],
                [-116.42993, 42.68175, 0],
                [-116.43034, 42.6816, 0],
                [-116.43045, 42.68157, 0],
                [-116.43098, 42.68134, 0],
                [-116.43154, 42.68106, 0],
                [-116.43175, 42.68093, 0],
                [-116.43177, 42.68091, 0],
                [-116.43188, 42.68083, 0],
                [-116.43213, 42.68059, 0],
                [-116.43224, 42.68047, 0],
                [-116.43237, 42.68035, 0],
                [-116.43257, 42.6802, 0],
                [-116.43278, 42.68008, 0],
                [-116.43361, 42.67951, 0],
                [-116.43363, 42.67949, 0],
                [-116.43376, 42.67939, 0],
                [-116.43395, 42.67922, 0],
                [-116.43422, 42.67892, 0],
                [-116.43446, 42.67863, 0],
                [-116.43461, 42.67847, 0],
                [-116.43461, 42.67846, 0],
                [-116.43469, 42.6784, 0],
                [-116.43473, 42.67836, 0],
                [-116.43479, 42.67833, 0],
                [-116.43488, 42.6783, 0],
                [-116.43524, 42.67821, 0],
                [-116.43565, 42.67813, 0],
                [-116.43605, 42.67802, 0],
                [-116.43622, 42.67795, 0],
                [-116.4366, 42.67775, 0],
                [-116.43677, 42.67764, 0],
                [-116.43699, 42.67745, 0],
                [-116.43704, 42.67739, 0],
                [-116.43705, 42.67737, 0],
                [-116.43708, 42.67734, 0],
                [-116.4371, 42.6773, 0],
                [-116.43724, 42.67691, 0],
                [-116.43733, 42.67672, 0],
                [-116.43742, 42.67656, 0],
                [-116.43752, 42.67641, 0],
                [-116.43779, 42.67605, 0],
                [-116.43792, 42.67584, 0],
                [-116.43795, 42.6758, 0],
                [-116.43797, 42.67576, 0],
                [-116.43805, 42.67555, 0],
                [-116.43809, 42.67536, 0],
                [-116.43811, 42.67521, 0],
                [-116.43811, 42.67513, 0],
                [-116.43812, 42.67501, 0],
                [-116.43812, 42.67491, 0],
                [-116.43813, 42.67479, 0],
                [-116.43828, 42.67386, 0],
                [-116.43868, 42.67222, 0],
                [-116.43872, 42.67194, 0],
                [-116.43873, 42.67191, 0],
                [-116.43878, 42.6714, 0],
                [-116.43878, 42.67123, 0],
                [-116.43874, 42.67096, 0],
                [-116.43871, 42.67087, 0],
                [-116.43871, 42.67086, 0],
                [-116.43864, 42.67066, 0],
                [-116.43858, 42.67052, 0],
                [-116.4385, 42.67029, 0],
                [-116.43848, 42.67021, 0],
                [-116.43844, 42.66992, 0],
                [-116.43843, 42.66965, 0],
                [-116.43894, 42.66883, 0],
                [-116.43937, 42.66819, 0],
                [-116.43952, 42.6679, 0],
                [-116.43954, 42.66785, 0],
                [-116.4396, 42.66773, 0],
                [-116.43968, 42.66751, 0],
                [-116.4402, 42.6652, 0],
                [-116.44021, 42.6652, 0],
                [-116.44029, 42.66487, 0],
                [-116.44029, 42.66485, 0],
                [-116.44044, 42.66425, 0],
                [-116.44086, 42.66302, 0],
                [-116.44116, 42.66249, 0],
                [-116.44132, 42.66226, 0],
                [-116.44166, 42.66191, 0],
                [-116.44193, 42.66161, 0],
                [-116.44277, 42.66073, 0],
                [-116.44282, 42.66067, 0],
                [-116.44998, 42.65307, 0],
                [-116.45098, 42.65193, 0],
                [-116.45134, 42.65156, 0],
                [-116.45171, 42.65121, 0],
                [-116.45197, 42.651, 0],
                [-116.45222, 42.65084, 0],
                [-116.45258, 42.65067, 0],
                [-116.45278, 42.6506, 0],
                [-116.45314, 42.65053, 0],
                [-116.45367, 42.65045, 0],
                [-116.45482, 42.65019, 0],
                [-116.45514, 42.65009, 0],
                [-116.45544, 42.64998, 0],
                [-116.45593, 42.64975, 0],
                [-116.45719, 42.64911, 0],
                [-116.45731, 42.64904, 0],
                [-116.45786, 42.64877, 0],
                [-116.45944, 42.64812, 0],
                [-116.45985, 42.64799, 0],
                [-116.46036, 42.64785, 0],
                [-116.46193, 42.6476, 0],
                [-116.46207, 42.64757, 0],
                [-116.46217, 42.64754, 0],
                [-116.46252, 42.64734, 0],
                [-116.46261, 42.64727, 0],
                [-116.46268, 42.6472, 0],
                [-116.46278, 42.64707, 0],
                [-116.46288, 42.64692, 0],
                [-116.46358, 42.64565, 0],
                [-116.46361, 42.64558, 0],
                [-116.46479, 42.64341, 0],
                [-116.46526, 42.64261, 0],
                [-116.46548, 42.64219, 0],
                [-116.46555, 42.64208, 0],
                [-116.46598, 42.64127, 0],
                [-116.46599, 42.64124, 0],
                [-116.4661, 42.64106, 0],
                [-116.46623, 42.6409, 0],
                [-116.46664, 42.64045, 0],
                [-116.46862, 42.63866, 0],
                [-116.47004, 42.63732, 0],
                [-116.47059, 42.63685, 0],
                [-116.47105, 42.6365, 0],
                [-116.47143, 42.63625, 0],
                [-116.47182, 42.63602, 0],
                [-116.47215, 42.63586, 0],
                [-116.47564, 42.6344, 0],
                [-116.4757, 42.63439, 0],
                [-116.4778, 42.63375, 0],
                [-116.47803, 42.63371, 0],
                [-116.47829, 42.63364, 0],
                [-116.47845, 42.63357, 0],
                [-116.4786, 42.63349, 0],
                [-116.47871, 42.6334, 0],
                [-116.47891, 42.6332, 0],
                [-116.47908, 42.63307, 0],
                [-116.47926, 42.63296, 0],
                [-116.47943, 42.63288, 0],
                [-116.48005, 42.63268, 0],
                [-116.4803, 42.63261, 0],
                [-116.48044, 42.63256, 0],
                [-116.48052, 42.63252, 0],
                [-116.48073, 42.63245, 0],
                [-116.48084, 42.63239, 0],
                [-116.48101, 42.63226, 0],
                [-116.48112, 42.63216, 0],
                [-116.4814, 42.63177, 0],
                [-116.48152, 42.63162, 0],
                [-116.48153, 42.63162, 0],
                [-116.48187, 42.63129, 0],
                [-116.48199, 42.6312, 0],
                [-116.48208, 42.63116, 0],
                [-116.48249, 42.63103, 0],
                [-116.48284, 42.6309, 0],
                [-116.48314, 42.63077, 0],
                [-116.48316, 42.63077, 0],
                [-116.48333, 42.63073, 0],
                [-116.48369, 42.63068, 0],
                [-116.48412, 42.63067, 0],
                [-116.48437, 42.63062, 0],
                [-116.48452, 42.63057, 0],
                [-116.48472, 42.63048, 0],
                [-116.48484, 42.63041, 0],
                [-116.48497, 42.63032, 0],
                [-116.48515, 42.63015, 0],
                [-116.48541, 42.62982, 0],
                [-116.48589, 42.62892, 0],
                [-116.48605, 42.62866, 0],
                [-116.48624, 42.6285, 0],
                [-116.48662, 42.62825, 0],
                [-116.48705, 42.62791, 0],
                [-116.48744, 42.62764, 0],
                [-116.48781, 42.62741, 0],
                [-116.48831, 42.62716, 0],
                [-116.48837, 42.62714, 0],
                [-116.4885, 42.62708, 0],
                [-116.48872, 42.627, 0],
                [-116.4961, 42.62499, 0],
                [-116.49669, 42.62485, 0],
                [-116.49692, 42.62483, 0],
                [-116.49728, 42.62485, 0],
                [-116.49752, 42.62489, 0],
                [-116.49762, 42.62492, 0],
                [-116.49768, 42.62493, 0],
                [-116.49826, 42.62511, 0],
                [-116.4983, 42.62513, 0],
                [-116.49944, 42.62555, 0],
                [-116.49965, 42.62561, 0],
                [-116.49992, 42.62566, 0],
                [-116.50011, 42.62567, 0],
                [-116.50031, 42.62567, 0],
                [-116.5005, 42.62565, 0],
                [-116.50088, 42.62556, 0],
                [-116.50104, 42.62551, 0],
                [-116.50116, 42.62545, 0],
                [-116.50117, 42.62544, 0],
                [-116.50128, 42.62538, 0],
                [-116.50151, 42.6252, 0],
                [-116.5016, 42.62511, 0],
                [-116.50172, 42.62489, 0],
                [-116.50176, 42.62479, 0],
                [-116.50185, 42.62446, 0],
                [-116.50186, 42.62433, 0],
                [-116.50187, 42.62428, 0],
                [-116.50186, 42.62401, 0],
                [-116.50182, 42.62384, 0],
                [-116.50179, 42.62364, 0],
                [-116.50174, 42.62344, 0],
                [-116.50173, 42.62343, 0],
                [-116.50169, 42.62323, 0],
                [-116.5017, 42.62266, 0],
                [-116.50168, 42.62255, 0],
                [-116.50167, 42.62245, 0],
                [-116.50157, 42.62197, 0],
                [-116.50157, 42.62192, 0],
                [-116.50161, 42.62181, 0],
                [-116.50167, 42.62171, 0],
                [-116.5017, 42.62167, 0],
                [-116.50171, 42.62167, 0],
                [-116.50175, 42.62162, 0],
                [-116.50197, 42.6215, 0],
                [-116.50213, 42.62145, 0],
                [-116.50226, 42.62142, 0],
                [-116.50233, 42.62142, 0],
                [-116.50251, 42.6214, 0],
                [-116.50274, 42.62139, 0],
                [-116.50333, 42.62139, 0],
                [-116.5048, 42.62135, 0],
                [-116.50529, 42.62135, 0],
                [-116.5053, 42.62134, 0],
                [-116.50556, 42.62133, 0],
                [-116.50573, 42.62131, 0],
                [-116.50577, 42.6213, 0],
                [-116.50584, 42.62129, 0],
                [-116.50604, 42.62123, 0],
                [-116.50615, 42.62117, 0],
                [-116.50622, 42.62112, 0],
                [-116.5063, 42.62105, 0],
                [-116.50642, 42.62091, 0],
                [-116.50656, 42.62071, 0],
                [-116.50661, 42.62062, 0],
                [-116.50666, 42.62055, 0],
                [-116.50677, 42.62028, 0],
                [-116.50704, 42.61952, 0],
                [-116.50709, 42.61941, 0],
                [-116.50723, 42.6192, 0],
                [-116.5073, 42.61911, 0],
                [-116.5075, 42.6189, 0],
                [-116.50782, 42.61867, 0],
                [-116.50809, 42.61853, 0],
                [-116.50812, 42.61852, 0],
                [-116.50829, 42.61844, 0],
                [-116.50912, 42.61812, 0],
                [-116.51007, 42.61778, 0],
                [-116.51108, 42.6175, 0],
                [-116.51194, 42.61734, 0],
                [-116.51324, 42.61717, 0],
                [-116.51353, 42.61709, 0],
                [-116.51926, 42.61457, 0],
                [-116.52028, 42.61415, 0],
                [-116.52069, 42.61395, 0],
                [-116.52093, 42.61382, 0],
                [-116.52094, 42.61382, 0],
                [-116.52199, 42.61324, 0],
                [-116.52247, 42.61295, 0],
                [-116.52248, 42.61295, 0],
                [-116.52498, 42.6114, 0],
                [-116.52526, 42.61121, 0],
                [-116.52574, 42.61095, 0],
                [-116.52592, 42.61084, 0],
                [-116.52608, 42.61076, 0],
                [-116.52656, 42.61059, 0],
                [-116.52704, 42.61048, 0],
                [-116.52706, 42.61047, 0],
                [-116.52762, 42.61034, 0],
                [-116.52821, 42.61023, 0],
                [-116.52822, 42.61023, 0],
                [-116.52876, 42.6101, 0],
                [-116.52895, 42.61004, 0],
                [-116.52927, 42.6099, 0],
                [-116.53001, 42.60947, 0],
                [-116.53026, 42.60928, 0],
                [-116.53034, 42.60923, 0],
                [-116.53051, 42.60907, 0],
                [-116.53058, 42.60899, 0],
                [-116.53072, 42.60878, 0],
                [-116.53084, 42.60853, 0],
                [-116.53092, 42.60816, 0],
                [-116.53094, 42.60803, 0],
                [-116.53101, 42.6078, 0],
                [-116.53101, 42.60779, 0],
                [-116.53115, 42.60754, 0],
                [-116.5312, 42.60747, 0],
                [-116.53131, 42.60737, 0],
                [-116.53296, 42.60608, 0],
                [-116.53301, 42.60606, 0],
                [-116.53308, 42.60601, 0],
                [-116.53345, 42.60586, 0],
                [-116.53356, 42.60583, 0],
                [-116.53367, 42.60579, 0],
                [-116.5337, 42.60579, 0],
                [-116.5338, 42.60577, 0],
                [-116.53424, 42.60571, 0],
                [-116.53503, 42.6057, 0],
                [-116.53989, 42.6059, 0],
                [-116.54028, 42.6059, 0],
                [-116.54259, 42.60556, 0],
                [-116.54279, 42.60554, 0],
                [-116.54326, 42.60554, 0],
                [-116.5435, 42.60559, 0],
                [-116.54479, 42.60601, 0],
                [-116.54541, 42.60624, 0],
                [-116.54587, 42.60639, 0],
                [-116.54601, 42.60641, 0],
                [-116.5461, 42.60643, 0],
                [-116.54639, 42.60646, 0],
                [-116.54673, 42.60646, 0],
                [-116.54696, 42.60644, 0],
                [-116.5474, 42.60637, 0],
                [-116.5491, 42.60602, 0],
                [-116.54948, 42.6059, 0],
                [-116.5496, 42.60585, 0],
                [-116.5497, 42.6058, 0],
                [-116.54972, 42.60578, 0],
                [-116.54984, 42.60571, 0],
                [-116.55004, 42.60554, 0],
                [-116.55014, 42.60544, 0],
                [-116.55019, 42.60538, 0],
                [-116.55038, 42.6051, 0],
                [-116.5505, 42.60485, 0],
                [-116.55059, 42.6047, 0],
                [-116.55092, 42.60425, 0],
                [-116.551, 42.60416, 0],
                [-116.55107, 42.6041, 0],
                [-116.55114, 42.60403, 0],
                [-116.55135, 42.60389, 0],
                [-116.55152, 42.60381, 0],
                [-116.55165, 42.60377, 0],
                [-116.55227, 42.60367, 0],
                [-116.5525, 42.60365, 0],
                [-116.55564, 42.60355, 0],
                [-116.55588, 42.60351, 0],
                [-116.55604, 42.60347, 0],
                [-116.55617, 42.60342, 0],
                [-116.55639, 42.60332, 0],
                [-116.55658, 42.6032, 0],
                [-116.55661, 42.60316, 0],
                [-116.55668, 42.60311, 0],
                [-116.55677, 42.603, 0],
                [-116.55691, 42.60272, 0],
                [-116.55694, 42.60262, 0],
                [-116.55695, 42.60252, 0],
                [-116.55695, 42.60228, 0],
                [-116.55691, 42.60187, 0],
                [-116.55668, 42.60036, 0],
                [-116.55664, 42.60001, 0],
                [-116.55664, 42.59973, 0],
                [-116.55665, 42.59961, 0],
                [-116.55668, 42.59946, 0],
                [-116.55686, 42.59894, 0],
                [-116.55811, 42.59589, 0],
                [-116.55855, 42.59488, 0],
                [-116.55907, 42.5936, 0],
                [-116.5592, 42.59324, 0],
                [-116.55944, 42.59284, 0],
                [-116.55951, 42.59276, 0],
                [-116.5597, 42.59259, 0],
                [-116.55977, 42.59254, 0],
                [-116.56004, 42.59239, 0],
                [-116.56016, 42.59234, 0],
                [-116.56016, 42.59233, 0],
                [-116.56026, 42.59229, 0],
                [-116.56053, 42.59222, 0],
                [-116.56077, 42.59218, 0],
                [-116.56091, 42.59217, 0],
                [-116.56119, 42.59217, 0],
                [-116.56134, 42.59218, 0],
                [-116.56192, 42.59229, 0],
                [-116.56307, 42.59255, 0],
                [-116.5634, 42.59259, 0],
                [-116.56347, 42.59259, 0],
                [-116.56355, 42.5926, 0],
                [-116.56372, 42.5926, 0],
                [-116.56409, 42.59257, 0],
                [-116.56429, 42.59253, 0],
                [-116.56454, 42.59246, 0],
                [-116.56465, 42.59242, 0],
                [-116.56481, 42.59234, 0],
                [-116.56541, 42.59194, 0],
                [-116.56572, 42.59169, 0],
                [-116.56607, 42.59136, 0],
                [-116.56663, 42.5908, 0],
                [-116.56736, 42.59012, 0],
                [-116.56738, 42.59011, 0],
                [-116.56834, 42.58921, 0],
                [-116.56836, 42.5892, 0],
                [-116.56842, 42.58913, 0],
                [-116.5695, 42.5882, 0],
                [-116.56958, 42.58814, 0],
                [-116.56975, 42.58803, 0],
                [-116.56993, 42.58793, 0],
                [-116.57008, 42.58786, 0],
                [-116.57017, 42.58781, 0],
                [-116.57024, 42.58779, 0],
                [-116.57056, 42.58766, 0],
                [-116.57162, 42.58738, 0],
                [-116.57184, 42.58731, 0],
                [-116.57256, 42.58712, 0],
                [-116.57363, 42.58691, 0],
                [-116.57385, 42.58685, 0],
                [-116.574, 42.5868, 0],
                [-116.57426, 42.58668, 0],
                [-116.57456, 42.58648, 0],
                [-116.57473, 42.58634, 0],
                [-116.57494, 42.58609, 0],
                [-116.57509, 42.5858, 0],
                [-116.57535, 42.58518, 0],
                [-116.57563, 42.58462, 0],
                [-116.5757, 42.5845, 0],
                [-116.576, 42.58421, 0],
                [-116.57624, 42.58406, 0],
                [-116.57647, 42.58396, 0],
                [-116.57695, 42.58382, 0],
                [-116.57716, 42.58378, 0],
                [-116.57743, 42.58376, 0],
                [-116.5776, 42.58377, 0],
                [-116.57787, 42.5838, 0],
                [-116.57813, 42.58385, 0],
                [-116.57959, 42.58423, 0],
                [-116.58012, 42.58435, 0],
                [-116.59635, 42.58842, 0],
                [-116.59887, 42.58901, 0],
                [-116.59948, 42.58909, 0],
                [-116.59987, 42.58912, 0],
                [-116.60274, 42.58916, 0],
                [-116.6033, 42.58915, 0],
                [-116.60365, 42.58912, 0],
                [-116.60389, 42.58907, 0],
                [-116.60445, 42.5889, 0],
                [-116.60733, 42.5877, 0],
                [-116.60757, 42.58764, 0],
                [-116.60783, 42.58762, 0],
                [-116.60806, 42.58762, 0],
                [-116.60833, 42.58764, 0],
                [-116.61283, 42.58847, 0],
                [-116.61303, 42.58849, 0],
                [-116.61336, 42.58849, 0],
                [-116.61368, 42.58847, 0],
                [-116.61407, 42.58843, 0],
                [-116.61502, 42.58826, 0],
                [-116.61511, 42.58825, 0],
                [-116.61566, 42.58814, 0],
                [-116.61592, 42.58806, 0],
                [-116.61611, 42.58799, 0],
                [-116.61634, 42.58789, 0],
                [-116.61665, 42.58771, 0],
                [-116.61679, 42.58761, 0],
                [-116.61696, 42.58746, 0],
                [-116.61701, 42.5874, 0],
                [-116.61704, 42.58735, 0],
                [-116.61715, 42.58721, 0],
                [-116.61776, 42.58627, 0],
                [-116.61815, 42.58561, 0],
                [-116.61819, 42.58553, 0],
                [-116.61855, 42.58499, 0],
                [-116.61862, 42.58491, 0],
                [-116.61887, 42.58469, 0],
                [-116.61907, 42.58457, 0],
                [-116.61908, 42.58457, 0],
                [-116.61921, 42.58451, 0],
                [-116.61941, 42.58446, 0],
                [-116.61949, 42.58445, 0],
                [-116.61953, 42.58444, 0],
                [-116.61958, 42.58444, 0],
                [-116.61967, 42.58443, 0],
                [-116.62011, 42.58443, 0],
                [-116.62332, 42.58462, 0],
                [-116.62394, 42.58471, 0],
                [-116.62428, 42.58478, 0],
                [-116.62499, 42.58501, 0],
                [-116.625, 42.58502, 0],
                [-116.62527, 42.58512, 0],
                [-116.62569, 42.58532, 0],
                [-116.62654, 42.58586, 0],
                [-116.6268, 42.58606, 0],
                [-116.62704, 42.58629, 0],
                [-116.62764, 42.58705, 0],
                [-116.62791, 42.58733, 0],
                [-116.628, 42.58741, 0],
                [-116.62821, 42.58755, 0],
                [-116.62834, 42.58762, 0],
                [-116.6287, 42.58778, 0],
                [-116.62874, 42.58779, 0],
                [-116.62897, 42.58787, 0],
                [-116.62935, 42.58797, 0],
                [-116.63032, 42.58829, 0],
                [-116.63099, 42.58844, 0],
                [-116.63126, 42.58847, 0],
                [-116.63193, 42.58851, 0],
                [-116.63208, 42.58851, 0],
                [-116.6327, 42.58846, 0],
                [-116.63282, 42.58844, 0],
                [-116.63299, 42.58843, 0],
                [-116.63347, 42.58836, 0],
                [-116.63396, 42.58824, 0],
                [-116.63433, 42.58813, 0],
                [-116.63514, 42.58781, 0],
                [-116.63652, 42.58721, 0],
                [-116.6369, 42.58707, 0],
                [-116.63706, 42.58702, 0],
                [-116.63731, 42.58696, 0],
                [-116.63791, 42.58685, 0],
                [-116.638, 42.58684, 0],
                [-116.63808, 42.58684, 0],
                [-116.63816, 42.58683, 0],
                [-116.63829, 42.58684, 0],
                [-116.63871, 42.58685, 0],
                [-116.64054, 42.58701, 0],
                [-116.6432, 42.5872, 0],
                [-116.64338, 42.5872, 0],
                [-116.64536, 42.58699, 0],
                [-116.64559, 42.58698, 0],
                [-116.64645, 42.587, 0],
                [-116.64773, 42.58708, 0],
                [-116.65344, 42.58731, 0],
                [-116.65486, 42.58739, 0],
                [-116.65506, 42.58742, 0],
                [-116.6552, 42.58746, 0],
                [-116.65623, 42.58782, 0],
                [-116.65767, 42.58839, 0],
                [-116.65787, 42.58843, 0],
                [-116.65795, 42.58844, 0],
                [-116.65802, 42.58844, 0],
                [-116.65814, 42.5884, 0],
                [-116.65827, 42.58832, 0],
                [-116.65837, 42.58824, 0],
                [-116.65933, 42.58721, 0],
                [-116.66009, 42.58643, 0],
                [-116.66052, 42.58607, 0],
                [-116.66078, 42.5859, 0],
                [-116.66194, 42.58529, 0],
                [-116.66239, 42.5851, 0],
                [-116.6626, 42.58503, 0],
                [-116.66346, 42.58484, 0],
                [-116.66439, 42.58461, 0],
                [-116.66462, 42.58453, 0],
                [-116.66481, 42.58442, 0],
                [-116.6654, 42.58401, 0],
                [-116.66567, 42.58377, 0],
                [-116.66574, 42.58366, 0],
                [-116.66575, 42.58362, 0],
                [-116.66577, 42.58344, 0],
                [-116.66574, 42.58326, 0],
                [-116.66559, 42.58292, 0],
                [-116.66512, 42.582, 0],
                [-116.665, 42.5818, 0],
                [-116.66492, 42.58162, 0],
                [-116.6649, 42.58156, 0],
                [-116.6649, 42.58149, 0],
                [-116.66491, 42.58143, 0],
                [-116.66493, 42.58138, 0],
                [-116.66499, 42.58127, 0],
                [-116.66508, 42.58115, 0],
                [-116.66524, 42.58102, 0],
                [-116.66541, 42.58097, 0],
                [-116.66563, 42.58092, 0],
                [-116.66588, 42.58088, 0],
                [-116.66607, 42.58086, 0],
                [-116.66621, 42.58086, 0],
                [-116.66638, 42.58089, 0],
                [-116.66733, 42.58114, 0],
                [-116.66779, 42.58129, 0],
                [-116.66813, 42.58138, 0],
                [-116.66834, 42.5814, 0],
                [-116.67009, 42.5813, 0],
                [-116.67024, 42.58131, 0],
                [-116.67035, 42.58133, 0],
                [-116.67046, 42.58137, 0],
                [-116.67061, 42.58144, 0],
                [-116.67177, 42.58214, 0],
                [-116.67209, 42.58228, 0],
                [-116.67219, 42.5823, 0],
                [-116.67226, 42.5823, 0],
                [-116.67233, 42.58231, 0],
                [-116.67248, 42.58228, 0],
                [-116.67442, 42.58159, 0],
                [-116.67443, 42.58159, 0],
                [-116.67506, 42.58137, 0],
                [-116.67524, 42.58134, 0],
                [-116.6754, 42.58133, 0],
                [-116.67557, 42.58133, 0],
                [-116.6762, 42.58136, 0],
                [-116.6765, 42.58141, 0],
                [-116.67655, 42.58143, 0],
                [-116.67663, 42.58148, 0],
                [-116.67666, 42.58151, 0],
                [-116.67668, 42.58158, 0],
                [-116.67671, 42.58184, 0],
                [-116.67671, 42.5819, 0],
                [-116.67673, 42.58199, 0],
                [-116.67673, 42.58201, 0],
                [-116.67674, 42.58202, 0],
                [-116.67674, 42.58204, 0],
                [-116.67704, 42.58249, 0],
                [-116.67706, 42.58251, 0],
                [-116.67713, 42.58261, 0],
                [-116.67718, 42.58266, 0],
                [-116.67738, 42.58278, 0],
                [-116.67787, 42.58296, 0],
                [-116.67791, 42.58297, 0],
                [-116.67805, 42.58302, 0],
                [-116.6781, 42.58303, 0],
                [-116.67813, 42.58304, 0],
                [-116.67828, 42.58304, 0],
                [-116.67832, 42.58303, 0],
                [-116.67834, 42.58303, 0],
                [-116.67876, 42.58282, 0],
                [-116.67885, 42.58281, 0],
                [-116.67894, 42.58281, 0],
                [-116.67905, 42.58284, 0],
                [-116.67913, 42.58288, 0],
                [-116.67917, 42.58292, 0],
                [-116.67999, 42.58355, 0],
                [-116.68008, 42.58359, 0],
                [-116.68017, 42.58361, 0],
                [-116.68027, 42.58364, 0],
                [-116.68034, 42.58364, 0],
                [-116.68043, 42.58363, 0],
                [-116.68063, 42.58358, 0],
                [-116.68088, 42.58344, 0],
                [-116.68089, 42.58342, 0],
                [-116.68101, 42.58333, 0],
                [-116.6813, 42.58302, 0],
                [-116.68169, 42.58256, 0],
                [-116.68175, 42.58251, 0],
                [-116.68186, 42.58245, 0],
                [-116.68192, 42.58243, 0],
                [-116.682, 42.58239, 0],
                [-116.68292, 42.58213, 0],
                [-116.68305, 42.5821, 0],
                [-116.68373, 42.5819, 0],
                [-116.68375, 42.5819, 0],
                [-116.6839, 42.58183, 0],
                [-116.68396, 42.58179, 0],
                [-116.68402, 42.58174, 0],
                [-116.68413, 42.58163, 0],
                [-116.68433, 42.5814, 0],
                [-116.68439, 42.58132, 0],
                [-116.6845, 42.58123, 0],
                [-116.68466, 42.58114, 0],
                [-116.68471, 42.58112, 0],
                [-116.68483, 42.58106, 0],
                [-116.68547, 42.5808, 0],
                [-116.68585, 42.58066, 0],
                [-116.68629, 42.58055, 0],
                [-116.68651, 42.58052, 0],
                [-116.68755, 42.58042, 0],
                [-116.68773, 42.58038, 0],
                [-116.68847, 42.58017, 0],
                [-116.68857, 42.58015, 0],
                [-116.68862, 42.58013, 0],
                [-116.68872, 42.58013, 0],
                [-116.68876, 42.58014, 0],
                [-116.68881, 42.58014, 0],
                [-116.68892, 42.58018, 0],
                [-116.68986, 42.58076, 0],
                [-116.69107, 42.58147, 0],
                [-116.69121, 42.58153, 0],
                [-116.69128, 42.58155, 0],
                [-116.69136, 42.58156, 0],
                [-116.69153, 42.5816, 0],
                [-116.69221, 42.58166, 0],
                [-116.69231, 42.58168, 0],
                [-116.69245, 42.58172, 0],
                [-116.69253, 42.58176, 0],
                [-116.69254, 42.58177, 0],
                [-116.69284, 42.58197, 0],
                [-116.6929, 42.582, 0],
                [-116.69297, 42.58202, 0],
                [-116.69316, 42.58204, 0],
                [-116.69325, 42.58204, 0],
                [-116.69338, 42.58202, 0],
                [-116.69352, 42.58198, 0],
                [-116.69359, 42.58194, 0],
                [-116.6936, 42.58194, 0],
                [-116.69384, 42.58178, 0],
                [-116.694, 42.58164, 0],
                [-116.69406, 42.58155, 0],
                [-116.69417, 42.58132, 0],
                [-116.69426, 42.58108, 0],
                [-116.69431, 42.58103, 0],
                [-116.69443, 42.58089, 0],
                [-116.69473, 42.58069, 0],
                [-116.69501, 42.58054, 0],
                [-116.6954, 42.58035, 0],
                [-116.69584, 42.58016, 0],
                [-116.69613, 42.58001, 0],
                [-116.69658, 42.57981, 0],
                [-116.69693, 42.57962, 0],
                [-116.69726, 42.5794, 0],
                [-116.69756, 42.57918, 0],
                [-116.6977, 42.57909, 0],
                [-116.6985, 42.57871, 0],
                [-116.69865, 42.57863, 0],
                [-116.69875, 42.57856, 0],
                [-116.69878, 42.57853, 0],
                [-116.6988, 42.57844, 0],
                [-116.69874, 42.57782, 0],
                [-116.69875, 42.57778, 0],
                [-116.69875, 42.57774, 0],
                [-116.6988, 42.57767, 0],
                [-116.69886, 42.57764, 0],
                [-116.69906, 42.57758, 0],
                [-116.70034, 42.57742, 0],
                [-116.70046, 42.57739, 0],
                [-116.70052, 42.57736, 0],
                [-116.70056, 42.57735, 0],
                [-116.70066, 42.57727, 0],
                [-116.70095, 42.57701, 0],
                [-116.70096, 42.57701, 0],
                [-116.70156, 42.57644, 0],
                [-116.70222, 42.57577, 0],
                [-116.70232, 42.57569, 0],
                [-116.70237, 42.57566, 0],
                [-116.70245, 42.57563, 0],
                [-116.7025, 42.57562, 0],
                [-116.70261, 42.57562, 0],
                [-116.70267, 42.57563, 0],
                [-116.70273, 42.57563, 0],
                [-116.70275, 42.57564, 0],
                [-116.70311, 42.57574, 0],
                [-116.70361, 42.57594, 0],
                [-116.70368, 42.57595, 0],
                [-116.70372, 42.57597, 0],
                [-116.70381, 42.57597, 0],
                [-116.70388, 42.57596, 0],
                [-116.70394, 42.57594, 0],
                [-116.704, 42.57591, 0],
                [-116.70403, 42.57589, 0],
                [-116.70409, 42.57571, 0],
                [-116.70415, 42.57538, 0],
                [-116.70417, 42.57534, 0],
                [-116.7042, 42.57531, 0],
                [-116.70423, 42.57529, 0],
                [-116.70428, 42.57528, 0],
                [-116.70432, 42.57528, 0],
                [-116.70438, 42.57527, 0],
                [-116.70458, 42.57531, 0],
                [-116.70508, 42.57544, 0],
                [-116.7051, 42.57545, 0],
                [-116.70795, 42.57609, 0],
                [-116.70802, 42.5761, 0],
                [-116.70806, 42.57611, 0],
                [-116.7081, 42.5761, 0],
                [-116.70817, 42.57609, 0],
                [-116.70858, 42.57599, 0],
                [-116.70921, 42.57578, 0],
                [-116.70925, 42.57576, 0],
                [-116.71476, 42.57382, 0],
                [-116.7149, 42.57375, 0],
                [-116.71496, 42.57371, 0],
                [-116.71498, 42.57368, 0],
                [-116.715, 42.57363, 0],
                [-116.71512, 42.57342, 0],
                [-116.71532, 42.57283, 0],
                [-116.71542, 42.57258, 0],
                [-116.71545, 42.57254, 0],
                [-116.71551, 42.57248, 0],
                [-116.71807, 42.57065, 0],
                [-116.71818, 42.57061, 0],
                [-116.71866, 42.57055, 0],
                [-116.71999, 42.57044, 0],
                [-116.72062, 42.57037, 0],
                [-116.72118, 42.57033, 0],
                [-116.72123, 42.57034, 0],
                [-116.72128, 42.57034, 0],
                [-116.72139, 42.57036, 0],
                [-116.72145, 42.57038, 0],
                [-116.72165, 42.57047, 0],
                [-116.72188, 42.57059, 0],
                [-116.72247, 42.57098, 0],
                [-116.72276, 42.57121, 0],
                [-116.72378, 42.57194, 0],
                [-116.72454, 42.57244, 0],
                [-116.72455, 42.57244, 0],
                [-116.7253, 42.57291, 0],
                [-116.72569, 42.57309, 0],
                [-116.7257, 42.57309, 0],
                [-116.72601, 42.5732, 0],
                [-116.72615, 42.57324, 0],
                [-116.72616, 42.57324, 0],
                [-116.72627, 42.57326, 0],
                [-116.72659, 42.57329, 0],
                [-116.72673, 42.57329, 0],
                [-116.72767, 42.57316, 0],
                [-116.73017, 42.57287, 0],
                [-116.73112, 42.57274, 0],
                [-116.73422, 42.5726, 0],
                [-116.73586, 42.57249, 0],
                [-116.73603, 42.57245, 0],
                [-116.73804, 42.57183, 0],
                [-116.73911, 42.57147, 0],
                [-116.73993, 42.57124, 0],
                [-116.74008, 42.57118, 0],
                [-116.74477, 42.56971, 0],
                [-116.74507, 42.56958, 0],
                [-116.74583, 42.5692, 0],
                [-116.74654, 42.56881, 0],
                [-116.74676, 42.56865, 0],
                [-116.74703, 42.56842, 0],
                [-116.74732, 42.56814, 0],
                [-116.74773, 42.56778, 0],
                [-116.74789, 42.56767, 0],
                [-116.74803, 42.56759, 0],
                [-116.74836, 42.56746, 0],
                [-116.74911, 42.56728, 0],
                [-116.75002, 42.56721, 0],
                [-116.75129, 42.56707, 0],
                [-116.75228, 42.56701, 0],
                [-116.75254, 42.56701, 0],
                [-116.75277, 42.56695, 0],
                [-116.75286, 42.56691, 0],
                [-116.75293, 42.56687, 0],
                [-116.75293, 42.56686, 0],
                [-116.75298, 42.56682, 0],
                [-116.75302, 42.56678, 0],
                [-116.75308, 42.56667, 0],
                [-116.7531, 42.56661, 0],
                [-116.75312, 42.56648, 0],
                [-116.7531, 42.56622, 0],
                [-116.75311, 42.56607, 0],
                [-116.75313, 42.566, 0],
                [-116.75313, 42.56598, 0],
                [-116.75319, 42.56584, 0],
                [-116.75325, 42.56573, 0],
                [-116.7535, 42.56536, 0],
                [-116.7536, 42.56519, 0],
                [-116.75366, 42.56503, 0],
                [-116.7537, 42.56489, 0],
                [-116.75373, 42.56474, 0],
                [-116.75375, 42.56459, 0],
                [-116.75377, 42.56345, 0],
                [-116.75378, 42.56335, 0],
                [-116.7538, 42.56326, 0],
                [-116.75385, 42.56314, 0],
                [-116.75395, 42.56299, 0],
                [-116.75406, 42.56289, 0],
                [-116.75463, 42.56231, 0],
                [-116.75492, 42.56199, 0],
                [-116.75515, 42.56166, 0],
                [-116.75532, 42.56138, 0],
                [-116.75555, 42.56095, 0],
                [-116.75561, 42.56082, 0],
                [-116.75568, 42.5606, 0],
                [-116.75569, 42.56051, 0],
                [-116.75569, 42.56045, 0],
                [-116.75568, 42.56038, 0],
                [-116.75563, 42.56025, 0],
                [-116.75561, 42.56021, 0],
                [-116.75552, 42.56007, 0],
                [-116.75495, 42.55938, 0],
                [-116.75486, 42.55926, 0],
                [-116.75482, 42.55918, 0],
                [-116.7548, 42.5591, 0],
                [-116.7548, 42.55903, 0],
                [-116.75484, 42.55894, 0],
                [-116.75487, 42.5589, 0],
                [-116.75497, 42.55881, 0],
                [-116.75553, 42.55838, 0],
                [-116.75564, 42.55824, 0],
                [-116.75573, 42.55803, 0],
                [-116.75573, 42.55802, 0],
                [-116.7558, 42.55787, 0],
                [-116.75592, 42.55775, 0],
                [-116.75596, 42.55772, 0],
                [-116.756, 42.55768, 0],
                [-116.75771, 42.55675, 0],
                [-116.75778, 42.55672, 0],
                [-116.75793, 42.55667, 0],
                [-116.75819, 42.5566, 0],
                [-116.75893, 42.55643, 0],
                [-116.75913, 42.55637, 0],
                [-116.75919, 42.55634, 0],
                [-116.75926, 42.55629, 0],
                [-116.75928, 42.55627, 0],
                [-116.7593, 42.55624, 0],
                [-116.75994, 42.55552, 0],
                [-116.76059, 42.55475, 0],
                [-116.7606, 42.55473, 0],
                [-116.76068, 42.55466, 0],
                [-116.76085, 42.55449, 0],
                [-116.76102, 42.55438, 0],
                [-116.76194, 42.55393, 0],
                [-116.76236, 42.5537, 0],
                [-116.76264, 42.55348, 0],
                [-116.76277, 42.55335, 0],
                [-116.7629, 42.5532, 0],
                [-116.76307, 42.55297, 0],
                [-116.76324, 42.55265, 0],
                [-116.76336, 42.55236, 0],
                [-116.76355, 42.55207, 0],
                [-116.76359, 42.55203, 0],
                [-116.76367, 42.55198, 0],
                [-116.76387, 42.55193, 0],
                [-116.76402, 42.55193, 0],
                [-116.7644, 42.55196, 0],
                [-116.76453, 42.55196, 0],
                [-116.76467, 42.55193, 0],
                [-116.76476, 42.5519, 0],
                [-116.7649, 42.55183, 0],
                [-116.76499, 42.55177, 0],
                [-116.76509, 42.55169, 0],
                [-116.7651, 42.55167, 0],
                [-116.76516, 42.55162, 0],
                [-116.76523, 42.55152, 0],
                [-116.76528, 42.55143, 0],
                [-116.76548, 42.55101, 0],
                [-116.76619, 42.54967, 0],
                [-116.76621, 42.54964, 0],
                [-116.76704, 42.54787, 0],
                [-116.76712, 42.54772, 0],
                [-116.76717, 42.54766, 0],
                [-116.76721, 42.54762, 0],
                [-116.76726, 42.54756, 0],
                [-116.76741, 42.54743, 0],
                [-116.7675, 42.54737, 0],
                [-116.7677, 42.54727, 0],
                [-116.76919, 42.54666, 0],
                [-116.77074, 42.54598, 0],
                [-116.7711, 42.54579, 0],
                [-116.77116, 42.54573, 0],
                [-116.77118, 42.5457, 0],
                [-116.7712, 42.54564, 0],
                [-116.7715, 42.54498, 0],
                [-116.77157, 42.54479, 0],
                [-116.77166, 42.54459, 0],
                [-116.77181, 42.54419, 0],
                [-116.772, 42.54381, 0],
                [-116.77213, 42.54363, 0],
                [-116.77223, 42.54353, 0],
                [-116.7723, 42.54348, 0],
                [-116.7723, 42.54347, 0],
                [-116.77268, 42.54325, 0],
                [-116.77276, 42.54321, 0],
                [-116.77329, 42.54287, 0],
                [-116.77463, 42.54213, 0],
                [-116.77495, 42.54201, 0],
                [-116.77512, 42.54199, 0],
                [-116.77517, 42.542, 0],
                [-116.77524, 42.542, 0],
                [-116.7753, 42.54201, 0],
                [-116.77531, 42.54202, 0],
                [-116.77578, 42.54212, 0],
                [-116.77764, 42.54261, 0],
                [-116.77793, 42.54267, 0],
                [-116.7832, 42.54341, 0],
                [-116.78336, 42.54344, 0],
                [-116.78382, 42.54348, 0],
                [-116.78389, 42.54348, 0],
                [-116.78395, 42.54347, 0],
                [-116.78402, 42.54347, 0],
                [-116.7841, 42.54346, 0],
                [-116.78437, 42.54338, 0],
                [-116.78475, 42.54322, 0],
                [-116.78481, 42.5432, 0],
                [-116.78487, 42.54317, 0],
                [-116.78494, 42.54315, 0],
                [-116.78499, 42.54313, 0],
                [-116.7851, 42.54311, 0],
                [-116.78516, 42.54311, 0],
                [-116.78527, 42.5431, 0],
                [-116.78862, 42.54317, 0],
                [-116.78953, 42.54308, 0],
                [-116.78998, 42.54306, 0],
                [-116.79125, 42.54294, 0],
                [-116.792, 42.54291, 0],
                [-116.79222, 42.54291, 0],
                [-116.79244, 42.54295, 0],
                [-116.79309, 42.54315, 0],
                [-116.79376, 42.5434, 0],
                [-116.79411, 42.54349, 0],
                [-116.79413, 42.54349, 0],
                [-116.79533, 42.54364, 0],
                [-116.79537, 42.54364, 0],
                [-116.79582, 42.54368, 0],
                [-116.79596, 42.5437, 0],
                [-116.79606, 42.5437, 0],
                [-116.79616, 42.54369, 0],
                [-116.79627, 42.54369, 0],
                [-116.79633, 42.54368, 0],
                [-116.79639, 42.54368, 0],
                [-116.79645, 42.54366, 0],
                [-116.79654, 42.54364, 0],
                [-116.79655, 42.54364, 0],
                [-116.79708, 42.54342, 0],
                [-116.79738, 42.54328, 0],
                [-116.79795, 42.54294, 0],
                [-116.799, 42.54235, 0],
                [-116.79911, 42.54228, 0],
                [-116.79912, 42.54228, 0],
                [-116.79931, 42.54213, 0],
                [-116.79935, 42.54207, 0],
                [-116.79943, 42.54198, 0],
                [-116.79959, 42.54176, 0],
                [-116.79975, 42.54158, 0],
                [-116.7999, 42.54146, 0],
                [-116.79997, 42.54142, 0],
                [-116.79999, 42.5414, 0],
                [-116.80025, 42.54129, 0],
                [-116.8004, 42.54126, 0],
                [-116.80045, 42.54124, 0],
                [-116.80131, 42.54107, 0],
                [-116.80325, 42.54075, 0],
                [-116.80417, 42.54057, 0],
                [-116.80467, 42.54044, 0],
                [-116.80883, 42.53901, 0],
                [-116.80889, 42.53898, 0],
                [-116.80957, 42.53875, 0],
                [-116.81005, 42.53862, 0],
                [-116.81304, 42.538, 0],
                [-116.81307, 42.53799, 0],
                [-116.81722, 42.53713, 0],
                [-116.81763, 42.53701, 0],
                [-116.81782, 42.53694, 0],
                [-116.81846, 42.53664, 0],
                [-116.81885, 42.53643, 0],
                [-116.81916, 42.53629, 0],
                [-116.8196, 42.53614, 0],
                [-116.81981, 42.53608, 0],
                [-116.82028, 42.53591, 0],
                [-116.82068, 42.53571, 0],
                [-116.82087, 42.5356, 0],
                [-116.82088, 42.53559, 0],
                [-116.82098, 42.53552, 0],
                [-116.8216, 42.53497, 0],
                [-116.82197, 42.53459, 0],
                [-116.82211, 42.53443, 0],
                [-116.82224, 42.53426, 0],
                [-116.82258, 42.53366, 0],
                [-116.82279, 42.53332, 0],
                [-116.8228, 42.53331, 0],
                [-116.82334, 42.53232, 0],
                [-116.82355, 42.53199, 0],
                [-116.82361, 42.53191, 0],
                [-116.8239, 42.53166, 0],
                [-116.82484, 42.53104, 0],
                [-116.8253, 42.53071, 0],
                [-116.82542, 42.5306, 0],
                [-116.82549, 42.53052, 0],
                [-116.82597, 42.52989, 0],
                [-116.8261, 42.52974, 0],
                [-116.82637, 42.52937, 0],
                [-116.8264, 42.52934, 0],
                [-116.8271, 42.5284, 0],
                [-116.82748, 42.52794, 0],
                [-116.82784, 42.5276, 0],
                [-116.82795, 42.52752, 0],
                [-116.82815, 42.52735, 0],
                [-116.82911, 42.52668, 0],
                [-116.82921, 42.5266, 0],
                [-116.82946, 42.52646, 0],
                [-116.82951, 42.52644, 0],
                [-116.82962, 42.52642, 0],
                [-116.82967, 42.52642, 0],
                [-116.82982, 42.52645, 0],
                [-116.83001, 42.52651, 0],
                [-116.83118, 42.52681, 0],
                [-116.83121, 42.52681, 0],
                [-116.83159, 42.52688, 0],
                [-116.83236, 42.52705, 0],
                [-116.8333, 42.52723, 0],
                [-116.8335, 42.52725, 0],
                [-116.83354, 42.52725, 0],
                [-116.83361, 42.52726, 0],
                [-116.8338, 42.52726, 0],
                [-116.83402, 42.52723, 0],
                [-116.83403, 42.52722, 0],
                [-116.83543, 42.52686, 0],
                [-116.83552, 42.52683, 0],
                [-116.83694, 42.5261, 0],
                [-116.83698, 42.52607, 0],
                [-116.8373, 42.52592, 0],
                [-116.83888, 42.52512, 0],
                [-116.83933, 42.52486, 0],
                [-116.83958, 42.52474, 0],
                [-116.83967, 42.52469, 0],
                [-116.83976, 42.52467, 0],
                [-116.83982, 42.52467, 0],
                [-116.8399, 42.52466, 0],
                [-116.84005, 42.52466, 0],
                [-116.84011, 42.52467, 0],
                [-116.84023, 42.52468, 0],
                [-116.84129, 42.52484, 0],
                [-116.84132, 42.52484, 0],
                [-116.84211, 42.52496, 0],
                [-116.84236, 42.52498, 0],
                [-116.8425, 42.52498, 0],
                [-116.84281, 42.52494, 0],
                [-116.84286, 42.52492, 0],
                [-116.84292, 42.52491, 0],
                [-116.84325, 42.52481, 0],
                [-116.84354, 42.52475, 0],
                [-116.84391, 42.52472, 0],
                [-116.84433, 42.52471, 0],
                [-116.84471, 42.52468, 0],
                [-116.84487, 42.52465, 0],
                [-116.84493, 42.52463, 0],
                [-116.84509, 42.52454, 0],
                [-116.84515, 42.52448, 0],
                [-116.84518, 42.52444, 0],
                [-116.84523, 42.52435, 0],
                [-116.84524, 42.52431, 0],
                [-116.84524, 42.52429, 0],
                [-116.84525, 42.52423, 0],
                [-116.84525, 42.52392, 0],
                [-116.84523, 42.52356, 0],
                [-116.84522, 42.52305, 0],
                [-116.84512, 42.52107, 0],
                [-116.84513, 42.52039, 0],
                [-116.84514, 42.52028, 0],
                [-116.84517, 42.52012, 0],
                [-116.84528, 42.51993, 0],
                [-116.84534, 42.51985, 0],
                [-116.84546, 42.51975, 0],
                [-116.84551, 42.51973, 0],
                [-116.84556, 42.51969, 0],
                [-116.84584, 42.51959, 0],
                [-116.84585, 42.51958, 0],
                [-116.84601, 42.51954, 0],
                [-116.84642, 42.51939, 0],
                [-116.8467, 42.51926, 0],
                [-116.84674, 42.51925, 0],
                [-116.84795, 42.5187, 0],
                [-116.84817, 42.51861, 0],
                [-116.84913, 42.51816, 0],
                [-116.84967, 42.51794, 0],
                [-116.84983, 42.51786, 0],
                [-116.84986, 42.51784, 0],
                [-116.84992, 42.51779, 0],
                [-116.84998, 42.51775, 0],
                [-116.85018, 42.51755, 0],
                [-116.85026, 42.51745, 0],
                [-116.85038, 42.51727, 0],
                [-116.85047, 42.51711, 0],
                [-116.85059, 42.51692, 0],
                [-116.85072, 42.51665, 0],
                [-116.85088, 42.51627, 0],
                [-116.85097, 42.516, 0],
                [-116.85101, 42.51584, 0],
                [-116.85166, 42.5126, 0],
                [-116.85173, 42.51234, 0],
                [-116.85176, 42.51227, 0],
                [-116.85177, 42.51223, 0],
                [-116.8518, 42.51219, 0],
                [-116.85184, 42.51212, 0],
                [-116.85202, 42.51188, 0],
                [-116.85326, 42.51052, 0],
                [-116.85353, 42.51026, 0],
                [-116.85359, 42.51021, 0],
                [-116.85372, 42.51013, 0],
                [-116.85395, 42.51004, 0],
                [-116.8542, 42.51, 0],
                [-116.85623, 42.51007, 0],
                [-116.86157, 42.51017, 0],
                [-116.86172, 42.51015, 0],
                [-116.86225, 42.51004, 0],
                [-116.86291, 42.50984, 0],
                [-116.8635, 42.50968, 0],
                [-116.86405, 42.50956, 0],
                [-116.86433, 42.50953, 0],
                [-116.86471, 42.50953, 0],
                [-116.86486, 42.50956, 0],
                [-116.86502, 42.50961, 0],
                [-116.86557, 42.50985, 0],
                [-116.86592, 42.51005, 0],
                [-116.86617, 42.51021, 0],
                [-116.86699, 42.51067, 0],
                [-116.86729, 42.51082, 0],
                [-116.86787, 42.51107, 0],
                [-116.86812, 42.51116, 0],
                [-116.86813, 42.51117, 0],
                [-116.86837, 42.51127, 0],
                [-116.86861, 42.51135, 0],
                [-116.86914, 42.51156, 0],
                [-116.8692, 42.51159, 0],
                [-116.8705, 42.51209, 0],
                [-116.87096, 42.51222, 0],
                [-116.87107, 42.51224, 0],
                [-116.87131, 42.5123, 0],
                [-116.877, 42.51338, 0],
                [-116.87707, 42.5134, 0],
                [-116.87709, 42.51341, 0],
                [-116.87713, 42.51342, 0],
                [-116.87726, 42.51347, 0],
                [-116.8773, 42.5135, 0],
                [-116.87731, 42.5135, 0],
                [-116.87738, 42.51355, 0],
                [-116.87762, 42.51377, 0],
                [-116.87764, 42.51378, 0],
                [-116.87916, 42.51498, 0],
                [-116.87928, 42.51506, 0],
                [-116.87932, 42.51508, 0],
                [-116.88001, 42.51529, 0],
                [-116.88097, 42.51554, 0],
                [-116.88248, 42.51599, 0],
                [-116.88335, 42.51631, 0],
                [-116.88346, 42.51633, 0],
                [-116.88377, 42.51642, 0],
                [-116.88398, 42.51645, 0],
                [-116.89228, 42.51701, 0],
                [-116.89268, 42.51705, 0],
                [-116.89316, 42.51712, 0],
                [-116.89567, 42.51768, 0],
                [-116.89605, 42.5178, 0],
                [-116.89629, 42.5179, 0],
                [-116.89692, 42.51822, 0],
                [-116.89711, 42.51829, 0],
                [-116.89735, 42.51836, 0],
                [-116.89756, 42.51838, 0],
                [-116.89778, 42.51838, 0],
                [-116.89782, 42.51837, 0],
                [-116.89795, 42.51836, 0],
                [-116.89809, 42.51832, 0],
                [-116.89835, 42.51822, 0],
                [-116.89894, 42.51785, 0],
                [-116.899, 42.51782, 0],
                [-116.89914, 42.51777, 0],
                [-116.89924, 42.51775, 0],
                [-116.89941, 42.51774, 0],
                [-116.89961, 42.51776, 0],
                [-116.89975, 42.51779, 0],
                [-116.8998, 42.51779, 0],
                [-116.90013, 42.51791, 0],
                [-116.90026, 42.518, 0],
                [-116.90055, 42.51829, 0],
                [-116.90072, 42.51854, 0],
                [-116.90146, 42.51981, 0],
                [-116.90167, 42.52009, 0],
                [-116.90175, 42.52017, 0],
                [-116.90235, 42.52085, 0],
                [-116.90249, 42.52103, 0],
                [-116.90378, 42.52283, 0],
                [-116.90401, 42.52318, 0],
                [-116.90417, 42.52363, 0],
                [-116.90418, 42.52368, 0],
                [-116.90442, 42.52438, 0],
                [-116.90448, 42.52462, 0],
                [-116.90448, 42.52479, 0],
                [-116.90447, 42.52485, 0],
                [-116.90441, 42.52506, 0],
                [-116.90421, 42.52557, 0],
                [-116.90419, 42.52564, 0],
                [-116.90417, 42.52568, 0],
                [-116.90417, 42.52579, 0],
                [-116.90418, 42.52585, 0],
                [-116.9042, 42.5259, 0],
                [-116.90427, 42.52601, 0],
                [-116.90445, 42.5262, 0],
                [-116.90483, 42.52652, 0],
                [-116.90484, 42.52652, 0],
                [-116.90548, 42.52705, 0],
                [-116.90597, 42.52742, 0],
                [-116.90618, 42.52755, 0],
                [-116.90774, 42.52864, 0],
                [-116.90778, 42.52866, 0],
                [-116.90789, 42.52874, 0],
                [-116.90797, 42.52881, 0],
                [-116.9081, 42.52902, 0],
                [-116.90819, 42.52931, 0],
                [-116.90841, 42.52985, 0],
                [-116.90857, 42.53015, 0],
                [-116.90865, 42.53026, 0],
                [-116.90872, 42.53033, 0],
                [-116.91125, 42.53233, 0],
                [-116.91139, 42.53245, 0],
                [-116.91161, 42.53267, 0],
                [-116.91164, 42.53271, 0],
                [-116.9117, 42.53277, 0],
                [-116.91197, 42.53314, 0],
                [-116.91243, 42.53372, 0],
                [-116.91292, 42.53426, 0],
                [-116.91305, 42.53436, 0],
                [-116.91323, 42.53447, 0],
                [-116.91357, 42.5346, 0],
                [-116.91522, 42.53507, 0],
                [-116.91608, 42.53534, 0],
                [-116.91648, 42.5355, 0],
                [-116.91666, 42.53562, 0],
                [-116.91681, 42.53577, 0],
                [-116.91697, 42.53596, 0],
                [-116.917, 42.53602, 0],
                [-116.91702, 42.53612, 0],
                [-116.91657, 42.53807, 0],
                [-116.91656, 42.53815, 0],
                [-116.91656, 42.53827, 0],
                [-116.91658, 42.53839, 0],
                [-116.91668, 42.53856, 0],
                [-116.9169, 42.53883, 0],
                [-116.91703, 42.53893, 0],
                [-116.91752, 42.53927, 0],
                [-116.91759, 42.53931, 0],
                [-116.91764, 42.53935, 0],
                [-116.91776, 42.53941, 0],
                [-116.91824, 42.53958, 0],
                [-116.91843, 42.53962, 0],
                [-116.91849, 42.53962, 0],
                [-116.91859, 42.53963, 0],
                [-116.91872, 42.53963, 0],
                [-116.91891, 42.5396, 0],
                [-116.91908, 42.5396, 0],
                [-116.91915, 42.53961, 0],
                [-116.9193, 42.53965, 0],
                [-116.9194, 42.5397, 0],
                [-116.91943, 42.53971, 0],
                [-116.91954, 42.53977, 0],
                [-116.91974, 42.53993, 0],
                [-116.92045, 42.54057, 0],
                [-116.92054, 42.54063, 0],
                [-116.92088, 42.54081, 0],
                [-116.92091, 42.54082, 0],
                [-116.92139, 42.54104, 0],
                [-116.92155, 42.5411, 0],
                [-116.92174, 42.54113, 0],
                [-116.92188, 42.54113, 0],
                [-116.92217, 42.5411, 0],
                [-116.92236, 42.5411, 0],
                [-116.9225, 42.54111, 0],
                [-116.92274, 42.54115, 0],
                [-116.92356, 42.54147, 0],
                [-116.92385, 42.54162, 0],
                [-116.92455, 42.54206, 0],
                [-116.92521, 42.54245, 0],
                [-116.92546, 42.54263, 0],
                [-116.92554, 42.54268, 0],
                [-116.92573, 42.54282, 0],
                [-116.92604, 42.54313, 0],
                [-116.92621, 42.54335, 0],
                [-116.9264, 42.54365, 0],
                [-116.92643, 42.54373, 0],
                [-116.92652, 42.54408, 0],
                [-116.92658, 42.54421, 0],
                [-116.92666, 42.54432, 0],
                [-116.9267, 42.54434, 0],
                [-116.92671, 42.54435, 0],
                [-116.92687, 42.54445, 0],
                [-116.92811, 42.54505, 0],
                [-116.92876, 42.54533, 0],
                [-116.92985, 42.54571, 0],
                [-116.92986, 42.54571, 0],
                [-116.93074, 42.54603, 0],
                [-116.93227, 42.54655, 0],
                [-116.93229, 42.54656, 0],
                [-116.93321, 42.5469, 0],
                [-116.93425, 42.54725, 0],
                [-116.93438, 42.54728, 0],
                [-116.93451, 42.5473, 0],
                [-116.93453, 42.5473, 0],
                [-116.93652, 42.54758, 0],
                [-116.93715, 42.54764, 0],
                [-116.93728, 42.54766, 0],
                [-116.93744, 42.54766, 0],
                [-116.93776, 42.54763, 0],
                [-116.93868, 42.54745, 0],
                [-116.93916, 42.54739, 0],
                [-116.94044, 42.5474, 0],
                [-116.941, 42.54742, 0],
                [-116.94112, 42.54741, 0],
                [-116.94118, 42.54741, 0],
                [-116.94124, 42.54739, 0],
                [-116.94125, 42.54739, 0],
                [-116.94147, 42.54732, 0],
                [-116.94184, 42.54715, 0],
                [-116.94209, 42.54702, 0],
                [-116.94217, 42.54697, 0],
                [-116.9424, 42.54686, 0],
                [-116.94248, 42.54685, 0],
                [-116.9426, 42.54685, 0],
                [-116.94266, 42.54687, 0],
                [-116.94278, 42.54693, 0],
                [-116.94286, 42.54701, 0],
                [-116.94288, 42.54705, 0],
                [-116.94289, 42.54712, 0],
                [-116.94287, 42.54719, 0],
                [-116.94285, 42.54724, 0],
                [-116.94281, 42.5473, 0],
                [-116.94249, 42.54769, 0],
                [-116.94247, 42.54771, 0],
                [-116.94215, 42.54814, 0],
                [-116.94174, 42.54865, 0],
                [-116.94156, 42.54895, 0],
                [-116.94151, 42.54914, 0],
                [-116.94148, 42.54951, 0],
                [-116.9415, 42.54963, 0],
                [-116.94157, 42.54981, 0],
                [-116.94169, 42.55007, 0],
                [-116.94213, 42.55089, 0],
                [-116.94285, 42.55202, 0],
                [-116.94307, 42.55239, 0],
                [-116.94341, 42.55318, 0],
                [-116.94352, 42.5535, 0],
                [-116.94352, 42.55352, 0],
                [-116.94378, 42.55449, 0],
                [-116.94386, 42.55473, 0],
                [-116.94388, 42.55475, 0],
                [-116.94404, 42.55509, 0],
                [-116.94413, 42.55525, 0],
                [-116.94435, 42.55554, 0],
                [-116.94478, 42.55599, 0],
                [-116.94511, 42.55624, 0],
                [-116.94545, 42.55642, 0],
                [-116.94583, 42.55664, 0],
                [-116.94607, 42.55675, 0],
                [-116.94622, 42.5568, 0],
                [-116.94637, 42.55684, 0],
                [-116.9465, 42.55686, 0],
                [-116.9468, 42.55688, 0],
                [-116.94737, 42.55687, 0],
                [-116.94889, 42.55679, 0],
                [-116.94902, 42.55677, 0],
                [-116.94947, 42.55665, 0],
                [-116.94984, 42.55653, 0],
                [-116.9503, 42.55641, 0],
                [-116.95173, 42.55615, 0],
                [-116.95316, 42.55594, 0],
                [-116.95421, 42.55584, 0],
                [-116.95486, 42.5558, 0],
                [-116.95488, 42.55579, 0],
                [-116.95639, 42.55565, 0],
                [-116.95773, 42.55548, 0],
                [-116.95833, 42.55538, 0],
                [-116.95846, 42.55534, 0],
                [-116.95858, 42.55529, 0],
                [-116.95907, 42.55504, 0],
                [-116.95976, 42.55466, 0],
                [-116.96047, 42.55431, 0],
                [-116.96322, 42.55286, 0],
                [-116.96569, 42.55149, 0],
                [-116.96584, 42.55139, 0],
                [-116.96611, 42.55114, 0],
                [-116.9663, 42.55088, 0],
                [-116.96633, 42.55085, 0],
                [-116.96668, 42.55039, 0],
                [-116.96715, 42.5499, 0],
                [-116.96769, 42.54926, 0],
                [-116.96777, 42.54918, 0],
                [-116.96854, 42.54826, 0],
                [-116.96878, 42.54802, 0],
                [-116.96888, 42.54793, 0],
                [-116.96896, 42.54788, 0],
                [-116.9691, 42.54786, 0],
                [-116.96922, 42.54788, 0],
                [-116.9693, 42.5479, 0],
                [-116.9694, 42.54795, 0],
                [-116.96957, 42.54808, 0],
                [-116.9723, 42.55049, 0],
                [-116.97273, 42.55083, 0],
                [-116.97305, 42.55111, 0],
                [-116.97312, 42.55119, 0],
                [-116.97322, 42.55129, 0],
                [-116.97328, 42.55142, 0],
                [-116.97328, 42.55143, 0],
                [-116.97339, 42.55177, 0],
                [-116.97345, 42.552, 0],
                [-116.97355, 42.55248, 0],
                [-116.97359, 42.55279, 0],
                [-116.97359, 42.55284, 0],
                [-116.9736, 42.55292, 0],
                [-116.9736, 42.55305, 0],
                [-116.9735, 42.5546, 0],
                [-116.97342, 42.55513, 0],
                [-116.97342, 42.55528, 0],
                [-116.97345, 42.5554, 0],
                [-116.97352, 42.55559, 0],
                [-116.97358, 42.55567, 0],
                [-116.97359, 42.55569, 0],
                [-116.97366, 42.55576, 0],
                [-116.97418, 42.55634, 0],
                [-116.98109, 42.56349, 0],
                [-116.98178, 42.56429, 0],
                [-116.98199, 42.56458, 0],
                [-116.98258, 42.56529, 0],
                [-116.98522, 42.56869, 0],
                [-116.9871, 42.57104, 0],
                [-116.98739, 42.57137, 0],
                [-116.9878, 42.57188, 0],
                [-116.98827, 42.57254, 0],
                [-116.98871, 42.57308, 0],
                [-116.9889, 42.57335, 0],
                [-116.9894, 42.57398, 0],
                [-116.98944, 42.57402, 0],
                [-116.98976, 42.57443, 0],
                [-116.99001, 42.57472, 0],
                [-116.99024, 42.57507, 0],
                [-116.99028, 42.57515, 0],
                [-116.99035, 42.57537, 0],
                [-116.99042, 42.57565, 0],
                [-116.9905, 42.57604, 0],
                [-116.9906, 42.57666, 0],
                [-116.99082, 42.57778, 0],
                [-116.99087, 42.57798, 0],
                [-116.99099, 42.57865, 0],
                [-116.991, 42.57867, 0],
                [-116.99182, 42.58295, 0],
                [-116.99182, 42.58296, 0],
                [-116.99197, 42.58382, 0],
                [-116.99218, 42.58483, 0],
                [-116.99225, 42.58529, 0],
                [-116.99231, 42.58544, 0],
                [-116.99248, 42.58577, 0],
                [-116.99248, 42.58578, 0],
                [-116.99256, 42.58588, 0],
                [-116.99269, 42.58608, 0],
                [-116.9927, 42.5861, 0],
                [-116.99302, 42.58658, 0],
                [-116.99334, 42.58711, 0],
                [-116.99372, 42.58767, 0],
                [-116.99396, 42.5881, 0],
                [-116.994, 42.58824, 0],
                [-116.994, 42.58831, 0],
                [-116.99398, 42.58838, 0],
                [-116.99394, 42.58845, 0],
                [-116.99384, 42.58855, 0],
                [-116.99384, 42.58856, 0],
                [-116.99377, 42.58861, 0],
                [-116.99356, 42.58871, 0],
                [-116.99334, 42.58879, 0],
                [-116.99326, 42.5888, 0],
                [-116.99321, 42.5888, 0],
                [-116.99315, 42.58881, 0],
                [-116.99308, 42.58881, 0],
                [-116.99301, 42.5888, 0],
                [-116.99296, 42.5888, 0],
                [-116.99289, 42.58878, 0],
                [-116.99273, 42.58872, 0],
                [-116.99219, 42.58848, 0],
                [-116.99211, 42.58845, 0],
                [-116.99173, 42.58828, 0],
                [-116.99169, 42.58827, 0],
                [-116.99168, 42.58826, 0],
                [-116.99163, 42.58825, 0],
                [-116.9915, 42.58825, 0],
                [-116.99145, 42.58826, 0],
                [-116.99132, 42.58833, 0],
                [-116.99126, 42.58837, 0],
                [-116.99039, 42.5891, 0],
                [-116.98999, 42.58949, 0],
                [-116.98984, 42.5896, 0],
                [-116.98979, 42.58962, 0],
                [-116.98975, 42.58965, 0],
                [-116.98961, 42.58969, 0],
                [-116.98953, 42.5897, 0],
                [-116.98767, 42.58969, 0],
                [-116.9876, 42.58968, 0],
                [-116.98723, 42.5897, 0],
                [-116.98685, 42.5897, 0],
                [-116.9866, 42.58973, 0],
                [-116.98642, 42.58977, 0],
                [-116.98629, 42.58981, 0],
                [-116.98627, 42.58981, 0],
                [-116.98612, 42.58986, 0],
                [-116.98591, 42.58996, 0],
                [-116.98582, 42.59002, 0],
                [-116.98562, 42.59023, 0],
                [-116.98546, 42.59048, 0],
                [-116.98542, 42.59059, 0],
                [-116.9854, 42.59069, 0],
                [-116.9854, 42.59088, 0],
                [-116.98543, 42.59125, 0],
                [-116.98547, 42.59149, 0],
                [-116.9855, 42.59159, 0],
                [-116.98556, 42.5917, 0],
                [-116.98561, 42.59176, 0],
                [-116.98566, 42.59181, 0],
                [-116.98582, 42.59191, 0],
                [-116.98592, 42.59195, 0],
                [-116.98632, 42.59206, 0],
                [-116.98692, 42.59219, 0],
                [-116.98716, 42.59227, 0],
                [-116.98721, 42.5923, 0],
                [-116.98723, 42.59232, 0],
                [-116.98724, 42.59234, 0],
                [-116.98725, 42.59235, 0],
                [-116.98726, 42.59237, 0],
                [-116.98726, 42.59245, 0],
                [-116.98723, 42.59249, 0],
                [-116.98721, 42.59251, 0],
                [-116.98711, 42.59256, 0],
                [-116.98707, 42.59257, 0],
                [-116.98699, 42.59258, 0],
                [-116.98697, 42.59259, 0],
                [-116.98678, 42.59263, 0],
                [-116.98649, 42.59265, 0],
                [-116.98611, 42.59263, 0],
                [-116.98582, 42.59256, 0],
                [-116.9857, 42.59251, 0],
                [-116.9856, 42.59243, 0],
                [-116.98544, 42.59228, 0],
                [-116.98529, 42.59216, 0],
                [-116.98517, 42.59208, 0],
                [-116.98512, 42.59204, 0],
                [-116.98483, 42.59188, 0],
                [-116.98471, 42.59179, 0],
                [-116.98468, 42.59178, 0],
                [-116.98465, 42.59174, 0],
                [-116.98462, 42.59171, 0],
                [-116.98456, 42.5916, 0],
                [-116.98455, 42.59157, 0],
                [-116.98444, 42.59133, 0],
                [-116.98438, 42.59123, 0],
                [-116.98432, 42.59116, 0],
                [-116.98409, 42.59094, 0],
                [-116.98401, 42.59088, 0],
                [-116.98388, 42.59081, 0],
                [-116.98367, 42.59066, 0],
                [-116.9833, 42.59035, 0],
                [-116.98316, 42.59026, 0],
                [-116.9831, 42.59024, 0],
                [-116.98306, 42.59022, 0],
                [-116.98301, 42.59021, 0],
                [-116.98286, 42.59021, 0],
                [-116.98279, 42.59022, 0],
                [-116.98255, 42.59028, 0],
                [-116.98223, 42.59039, 0],
                [-116.9821, 42.59042, 0],
                [-116.98206, 42.59042, 0],
                [-116.98199, 42.59043, 0],
                [-116.98094, 42.59044, 0],
                [-116.98085, 42.59045, 0],
                [-116.98079, 42.59045, 0],
                [-116.9807, 42.59047, 0],
                [-116.98061, 42.59051, 0],
                [-116.98058, 42.59053, 0],
                [-116.98049, 42.59061, 0],
                [-116.98048, 42.59066, 0],
                [-116.98047, 42.59069, 0],
                [-116.98048, 42.59072, 0],
                [-116.98052, 42.59078, 0],
                [-116.98056, 42.59079, 0],
                [-116.98059, 42.59081, 0],
                [-116.98066, 42.59083, 0],
                [-116.98073, 42.59084, 0],
                [-116.98111, 42.59083, 0],
                [-116.98168, 42.59078, 0],
                [-116.98193, 42.59077, 0],
                [-116.98209, 42.59079, 0],
                [-116.98228, 42.59083, 0],
                [-116.98262, 42.59097, 0],
                [-116.98319, 42.59131, 0],
                [-116.98337, 42.59144, 0],
                [-116.98362, 42.59166, 0],
                [-116.98369, 42.59176, 0],
                [-116.98375, 42.59186, 0],
                [-116.98379, 42.59196, 0],
                [-116.98379, 42.59202, 0],
                [-116.98377, 42.59206, 0],
                [-116.98375, 42.59208, 0],
                [-116.98371, 42.5921, 0],
                [-116.98369, 42.59212, 0],
                [-116.98367, 42.59212, 0],
                [-116.98365, 42.59213, 0],
                [-116.98362, 42.59214, 0],
                [-116.9836, 42.59215, 0],
                [-116.98353, 42.59215, 0],
                [-116.98344, 42.59213, 0],
                [-116.98342, 42.59213, 0],
                [-116.98323, 42.59204, 0],
                [-116.98318, 42.592, 0],
                [-116.98303, 42.59185, 0],
                [-116.98283, 42.59168, 0],
                [-116.98267, 42.59156, 0],
                [-116.9825, 42.59147, 0],
                [-116.98213, 42.59136, 0],
                [-116.98212, 42.59136, 0],
                [-116.98207, 42.59135, 0],
                [-116.98194, 42.59134, 0],
                [-116.98159, 42.59134, 0],
                [-116.98149, 42.59137, 0],
                [-116.98142, 42.59142, 0],
                [-116.98139, 42.59146, 0],
                [-116.98136, 42.59149, 0],
                [-116.98133, 42.59155, 0],
                [-116.98118, 42.59195, 0],
                [-116.98114, 42.59204, 0],
                [-116.98107, 42.59235, 0],
                [-116.98104, 42.59264, 0],
                [-116.98104, 42.59272, 0],
                [-116.98108, 42.5929, 0],
                [-116.98111, 42.59297, 0],
                [-116.98119, 42.5931, 0],
                [-116.98146, 42.59344, 0],
                [-116.98153, 42.59354, 0],
                [-116.9818, 42.59381, 0],
                [-116.9819, 42.59389, 0],
                [-116.98199, 42.59398, 0],
                [-116.98223, 42.59415, 0],
                [-116.98224, 42.59416, 0],
                [-116.98257, 42.59435, 0],
                [-116.98298, 42.59452, 0],
                [-116.98337, 42.59466, 0],
                [-116.98383, 42.59485, 0],
                [-116.9843, 42.59501, 0],
                [-116.98436, 42.59502, 0],
                [-116.98462, 42.59509, 0],
                [-116.98522, 42.59522, 0],
                [-116.98605, 42.59534, 0],
                [-116.98626, 42.59539, 0],
                [-116.98652, 42.59548, 0],
                [-116.98697, 42.59569, 0],
                [-116.98721, 42.59579, 0],
                [-116.98726, 42.5958, 0],
                [-116.9873, 42.59582, 0],
                [-116.98732, 42.59582, 0],
                [-116.98758, 42.59586, 0],
                [-116.98816, 42.59588, 0],
                [-116.98824, 42.5959, 0],
                [-116.98827, 42.5959, 0],
                [-116.98828, 42.59591, 0],
                [-116.98835, 42.59593, 0],
                [-116.98844, 42.59598, 0],
                [-116.9885, 42.59603, 0],
                [-116.98855, 42.5961, 0],
                [-116.98863, 42.59623, 0],
                [-116.9887, 42.59638, 0],
                [-116.98883, 42.5966, 0],
                [-116.98889, 42.59666, 0],
                [-116.98892, 42.5967, 0],
                [-116.98902, 42.59678, 0],
                [-116.98925, 42.59692, 0],
                [-116.98949, 42.597, 0],
                [-116.9898, 42.59702, 0],
                [-116.99068, 42.59699, 0],
                [-116.99091, 42.597, 0],
                [-116.99112, 42.59703, 0],
                [-116.99144, 42.59711, 0],
                [-116.99163, 42.59719, 0],
                [-116.99185, 42.59733, 0],
                [-116.99196, 42.59743, 0],
                [-116.99206, 42.59755, 0],
                [-116.99214, 42.59778, 0],
                [-116.99215, 42.59792, 0],
                [-116.99214, 42.59801, 0],
                [-116.99211, 42.59816, 0],
                [-116.99204, 42.59835, 0],
                [-116.99193, 42.59861, 0],
                [-116.99181, 42.5988, 0],
                [-116.99161, 42.59899, 0],
                [-116.99141, 42.59912, 0],
                [-116.9898, 42.60008, 0],
                [-116.98954, 42.60021, 0],
                [-116.98949, 42.60024, 0],
                [-116.98916, 42.60035, 0],
                [-116.98765, 42.60075, 0],
                [-116.9875, 42.6008, 0],
                [-116.98733, 42.60087, 0],
                [-116.98705, 42.60103, 0],
                [-116.98687, 42.60118, 0],
                [-116.9867, 42.60135, 0],
                [-116.98661, 42.60148, 0],
                [-116.98657, 42.60157, 0],
                [-116.98654, 42.60166, 0],
                [-116.98652, 42.60174, 0],
                [-116.98649, 42.60236, 0],
                [-116.98658, 42.6059, 0],
                [-116.98658, 42.60667, 0],
                [-116.9866, 42.60701, 0],
                [-116.9866, 42.60732, 0],
                [-116.98661, 42.6074, 0],
                [-116.98664, 42.61, 0],
                [-116.98668, 42.61109, 0],
                [-116.98668, 42.61198, 0],
                [-116.98674, 42.61382, 0],
                [-116.98683, 42.61981, 0],
                [-116.98682, 42.62001, 0],
                [-116.98679, 42.62025, 0],
                [-116.98673, 42.62053, 0],
                [-116.98657, 42.62107, 0],
                [-116.9865, 42.62146, 0],
                [-116.98647, 42.62185, 0],
                [-116.98662, 42.62315, 0],
                [-116.98665, 42.62351, 0],
                [-116.98671, 42.62396, 0],
                [-116.98674, 42.62439, 0],
                [-116.98674, 42.62455, 0],
                [-116.98672, 42.62489, 0],
                [-116.98671, 42.62492, 0],
                [-116.9867, 42.62513, 0],
                [-116.98664, 42.62566, 0],
                [-116.98659, 42.62597, 0],
                [-116.98617, 42.62929, 0],
                [-116.98613, 42.62953, 0],
                [-116.98608, 42.62974, 0],
                [-116.98608, 42.62975, 0],
                [-116.98584, 42.63058, 0],
                [-116.98582, 42.63063, 0],
                [-116.98532, 42.63239, 0],
                [-116.98527, 42.63253, 0],
                [-116.98488, 42.63387, 0],
                [-116.98456, 42.63485, 0],
                [-116.98416, 42.6357, 0],
                [-116.98396, 42.63608, 0],
                [-116.98376, 42.63639, 0],
                [-116.98358, 42.63661, 0],
                [-116.98301, 42.63716, 0],
                [-116.98266, 42.63747, 0],
                [-116.9814, 42.63865, 0],
                [-116.98125, 42.63878, 0],
                [-116.9812, 42.63888, 0],
                [-116.98118, 42.63895, 0],
                [-116.98117, 42.63897, 0],
                [-116.98116, 42.63904, 0],
                [-116.98116, 42.63911, 0],
                [-116.98117, 42.63915, 0],
                [-116.98118, 42.63923, 0],
                [-116.98126, 42.63949, 0],
                [-116.98241, 42.64158, 0],
                [-116.98254, 42.64179, 0],
                [-116.98267, 42.64197, 0],
                [-116.98284, 42.64215, 0],
                [-116.98298, 42.64226, 0],
                [-116.98317, 42.64237, 0],
                [-116.98336, 42.64246, 0],
                [-116.98359, 42.64254, 0],
                [-116.98366, 42.64257, 0],
                [-116.98389, 42.64262, 0],
                [-116.98428, 42.64264, 0],
                [-116.99165, 42.64245, 0],
                [-116.9921, 42.64242, 0],
                [-116.99224, 42.64242, 0],
                [-116.99236, 42.64243, 0],
                [-116.99253, 42.64246, 0],
                [-116.99271, 42.64251, 0],
                [-116.99293, 42.64259, 0],
                [-116.99312, 42.6427, 0],
                [-116.9932, 42.64277, 0],
                [-116.99336, 42.64293, 0],
                [-116.99346, 42.6431, 0],
                [-116.99354, 42.64329, 0],
                [-116.9937, 42.64392, 0],
                [-116.99385, 42.64468, 0],
                [-116.99411, 42.64574, 0],
                [-116.99432, 42.64681, 0],
                [-116.99434, 42.64688, 0],
                [-116.99443, 42.64734, 0],
                [-116.99466, 42.64804, 0],
                [-116.99513, 42.65232, 0],
                [-116.99508, 42.65278, 0],
                [-116.99509, 42.65303, 0],
                [-116.9951, 42.65306, 0],
                [-116.99512, 42.65325, 0],
                [-116.99521, 42.65354, 0],
                [-116.9953, 42.65391, 0],
                [-116.99534, 42.65434, 0],
                [-116.99544, 42.65502, 0],
                [-116.99547, 42.65514, 0],
                [-116.99551, 42.6552, 0],
                [-116.99554, 42.65526, 0],
                [-116.99559, 42.65532, 0],
                [-116.9959, 42.65551, 0],
                [-116.99606, 42.65557, 0],
                [-116.99719, 42.65611, 0],
                [-116.9973, 42.65615, 0],
                [-116.99742, 42.65618, 0],
                [-116.99763, 42.65609, 0],
                [-116.99793, 42.65586, 0],
                [-116.99806, 42.65579, 0],
                [-116.99826, 42.6557, 0],
                [-116.99845, 42.65563, 0],
                [-116.99859, 42.65559, 0],
                [-116.99862, 42.65559, 0],
                [-116.99877, 42.65556, 0],
                [-116.99903, 42.65554, 0],
                [-116.99931, 42.65556, 0],
                [-116.99955, 42.6556, 0],
                [-116.99958, 42.65561, 0],
                [-116.99973, 42.65564, 0],
                [-116.99993, 42.6557, 0],
                [-117.00003, 42.65575, 0],
                [-117.00006, 42.65576, 0],
                [-117.0003, 42.65591, 0],
                [-117.00044, 42.65598, 0],
                [-117.00058, 42.65602, 0],
                [-117.0007, 42.65604, 0],
                [-117.00081, 42.65605, 0],
                [-117.00094, 42.65609, 0],
                [-117.00108, 42.65615, 0],
                [-117.00146, 42.65634, 0],
                [-117.00156, 42.65644, 0],
                [-117.0018, 42.65664, 0],
                [-117.00189, 42.6567, 0],
                [-117.00216, 42.65681, 0],
                [-117.00218, 42.65681, 0],
                [-117.00231, 42.65685, 0],
                [-117.00259, 42.65692, 0],
                [-117.00264, 42.65692, 0],
                [-117.00275, 42.65694, 0],
                [-117.00292, 42.65695, 0],
                [-117.00298, 42.65697, 0],
                [-117.00331, 42.65717, 0],
                [-117.00342, 42.65725, 0],
                [-117.00348, 42.65728, 0],
                [-117.00365, 42.65741, 0],
                [-117.00369, 42.65746, 0],
                [-117.00373, 42.65754, 0],
                [-117.00376, 42.65762, 0],
                [-117.00379, 42.65785, 0],
                [-117.00388, 42.65806, 0],
                [-117.004, 42.6582, 0],
                [-117.004, 42.65821, 0],
                [-117.00426, 42.65848, 0],
                [-117.00453, 42.65874, 0],
                [-117.00456, 42.65879, 0],
                [-117.00458, 42.65881, 0],
                [-117.00462, 42.65891, 0],
                [-117.00464, 42.65901, 0],
                [-117.00465, 42.65912, 0],
                [-117.00464, 42.65914, 0],
                [-117.00464, 42.65936, 0],
                [-117.00463, 42.65953, 0],
                [-117.00465, 42.65968, 0],
                [-117.00465, 42.6597, 0],
                [-117.00467, 42.65983, 0],
                [-117.0048, 42.66025, 0],
                [-117.00488, 42.66042, 0],
                [-117.00497, 42.66057, 0],
                [-117.00502, 42.66068, 0],
                [-117.00501, 42.66105, 0],
                [-117.00499, 42.66124, 0],
                [-117.00489, 42.66151, 0],
                [-117.00474, 42.66168, 0],
                [-117.00452, 42.66184, 0],
                [-117.00419, 42.66205, 0],
                [-117.00379, 42.66233, 0],
                [-117.00373, 42.66239, 0],
                [-117.00368, 42.66246, 0],
                [-117.00365, 42.66259, 0],
                [-117.00365, 42.66267, 0],
                [-117.00368, 42.66275, 0],
                [-117.00381, 42.6629, 0],
                [-117.00407, 42.66328, 0],
                [-117.00425, 42.66362, 0],
                [-117.00432, 42.66373, 0],
                [-117.00441, 42.66382, 0],
                [-117.00453, 42.6639, 0],
                [-117.00488, 42.6641, 0],
                [-117.00493, 42.66415, 0],
                [-117.00495, 42.66424, 0],
                [-117.00499, 42.6645, 0],
                [-117.00502, 42.66496, 0],
                [-117.00527, 42.66541, 0],
                [-117.00528, 42.66542, 0],
                [-117.00543, 42.66562, 0],
                [-117.0057, 42.66583, 0],
                [-117.00604, 42.66621, 0],
                [-117.00629, 42.66658, 0],
                [-117.00648, 42.66689, 0],
                [-117.00668, 42.66719, 0],
                [-117.00684, 42.66733, 0],
                [-117.00702, 42.66744, 0],
                [-117.00745, 42.66766, 0],
                [-117.00781, 42.66782, 0],
                [-117.00816, 42.66802, 0],
                [-117.00862, 42.66831, 0],
                [-117.00888, 42.66845, 0],
                [-117.00914, 42.66856, 0],
                [-117.00954, 42.6687, 0],
                [-117.01014, 42.66887, 0],
                [-117.01058, 42.66896, 0],
                [-117.01062, 42.66896, 0],
                [-117.01079, 42.669, 0],
                [-117.01098, 42.66906, 0],
                [-117.01103, 42.66909, 0],
                [-117.01118, 42.66916, 0],
                [-117.01147, 42.66933, 0],
                [-117.01165, 42.66942, 0],
                [-117.01177, 42.66946, 0],
                [-117.01186, 42.66948, 0],
                [-117.01208, 42.66951, 0],
                [-117.01232, 42.66957, 0],
                [-117.01277, 42.66971, 0],
                [-117.01278, 42.66971, 0],
                [-117.01354, 42.67001, 0],
                [-117.01445, 42.67053, 0],
                [-117.01484, 42.67083, 0],
                [-117.01563, 42.67161, 0],
                [-117.01597, 42.67199, 0],
                [-117.01624, 42.67222, 0],
                [-117.01635, 42.67234, 0],
                [-117.01635, 42.67235, 0],
                [-117.01672, 42.67256, 0],
                [-117.01718, 42.67275, 0],
                [-117.01824, 42.67328, 0],
                [-117.01863, 42.67357, 0],
                [-117.01939, 42.67421, 0],
                [-117.0195, 42.67429, 0],
                [-117.02046, 42.67506, 0],
                [-117.02071, 42.67529, 0],
                [-117.02113, 42.67572, 0],
                [-117.02202, 42.67671, 0],
                [-117.02227, 42.67695, 0],
                [-117.02309, 42.67792, 0],
                [-117.02377, 42.67867, 0],
                [-117.02425, 42.67925, 0],
                [-117.02441, 42.67942, 0],
                [-117.02491, 42.68002, 0],
                [-117.02521, 42.6803, 0],
                [-117.02555, 42.68064, 0],
                [-117.02592, 42.68112, 0],
                [-117.02598, 42.68122, 0],
                [-117.02599, 42.68123, 0],
                [-117.02605, 42.68135, 0],
                [-117.02614, 42.68156, 0],
                [-117.02642, 42.68209, 0],
                [-117.02653, 42.68226, 0],
                [-117.02662, 42.68238, 0],
                [-117.02728, 42.68314, 0],
                [-117.02736, 42.68327, 0],
                [-117.02739, 42.68335, 0],
                [-117.0274, 42.68342, 0],
                [-117.0274, 42.68356, 0],
                [-117.02739, 42.68373, 0],
                [-117.02742, 42.68493, 0],
                [-117.02748, 42.68521, 0],
                [-117.0275, 42.68528, 0],
                [-117.02751, 42.68529, 0],
                [-117.02756, 42.68539, 0],
                [-117.02768, 42.68554, 0],
                [-117.02952, 42.68731, 0],
                [-117.02969, 42.68743, 0],
                [-117.02989, 42.68759, 0],
                [-117.03, 42.68771, 0],
                [-117.03009, 42.68784, 0],
                [-117.03015, 42.68798, 0],
                [-117.03045, 42.68908, 0],
                [-117.03065, 42.68961, 0],
                [-117.03073, 42.68974, 0],
                [-117.03084, 42.68987, 0],
                [-117.03098, 42.68999, 0],
                [-117.03116, 42.69012, 0],
                [-117.03167, 42.69036, 0],
                [-117.03221, 42.69058, 0],
                [-117.03228, 42.69062, 0],
                [-117.03243, 42.69069, 0],
                [-117.03255, 42.69076, 0],
                [-117.03292, 42.69108, 0],
                [-117.03341, 42.69155, 0],
                [-117.03369, 42.69185, 0],
                [-117.03395, 42.6921, 0],
                [-117.03437, 42.69259, 0],
                [-117.03443, 42.69267, 0],
                [-117.03449, 42.69282, 0],
                [-117.03455, 42.69306, 0],
                [-117.03478, 42.69454, 0],
                [-117.0349, 42.69552, 0],
                [-117.03528, 42.69728, 0],
                [-117.03544, 42.69765, 0],
                [-117.03549, 42.69779, 0],
                [-117.03562, 42.69844, 0],
                [-117.03562, 42.69849, 0],
                [-117.03569, 42.69889, 0],
                [-117.03572, 42.69917, 0],
                [-117.03583, 42.69971, 0],
                [-117.03584, 42.69972, 0],
                [-117.03597, 42.70041, 0],
                [-117.03601, 42.70099, 0],
                [-117.036, 42.70144, 0],
                [-117.03603, 42.70164, 0],
                [-117.0361, 42.70178, 0],
                [-117.03668, 42.70244, 0],
                [-117.03679, 42.70258, 0],
                [-117.0369, 42.70266, 0],
                [-117.03774, 42.70313, 0],
                [-117.03793, 42.70329, 0],
                [-117.03809, 42.70341, 0],
                [-117.03855, 42.70389, 0],
                [-117.03922, 42.70443, 0],
                [-117.03947, 42.7046, 0],
                [-117.03965, 42.70478, 0],
                [-117.03978, 42.705, 0],
                [-117.03987, 42.70521, 0],
                [-117.04165, 42.70827, 0],
                [-117.04183, 42.70862, 0],
                [-117.04192, 42.70886, 0],
                [-117.04204, 42.70931, 0],
                [-117.04208, 42.70967, 0],
                [-117.04208, 42.71003, 0],
                [-117.0419, 42.71132, 0],
                [-117.04182, 42.71218, 0],
                [-117.04168, 42.71319, 0],
                [-117.0417, 42.71331, 0],
                [-117.04177, 42.71342, 0],
                [-117.04178, 42.71343, 0],
                [-117.04194, 42.71372, 0],
                [-117.0424, 42.71488, 0],
                [-117.0425, 42.71521, 0],
                [-117.04251, 42.71527, 0],
                [-117.0425, 42.71534, 0],
                [-117.04249, 42.71537, 0],
                [-117.04248, 42.71542, 0],
                [-117.04246, 42.71546, 0],
                [-117.0424, 42.71554, 0],
                [-117.0423, 42.71564, 0],
                [-117.04201, 42.71584, 0],
                [-117.04185, 42.71592, 0],
                [-117.0418, 42.71597, 0],
                [-117.04177, 42.71602, 0],
                [-117.04176, 42.71605, 0],
                [-117.04176, 42.71609, 0],
                [-117.0418, 42.71616, 0],
                [-117.04336, 42.71789, 0],
                [-117.04357, 42.71816, 0],
                [-117.04363, 42.71826, 0],
                [-117.04369, 42.71841, 0],
                [-117.04375, 42.71853, 0],
                [-117.04396, 42.71941, 0],
                [-117.04422, 42.72038, 0],
                [-117.04422, 42.72059, 0],
                [-117.04415, 42.72077, 0],
                [-117.04352, 42.72157, 0],
                [-117.04297, 42.72237, 0],
                [-117.04285, 42.72253, 0],
                [-117.04152, 42.72446, 0],
                [-117.04145, 42.72458, 0],
                [-117.04143, 42.72463, 0],
                [-117.04139, 42.72481, 0],
                [-117.04138, 42.72484, 0],
                [-117.04157, 42.72633, 0],
                [-117.04157, 42.72686, 0],
                [-117.04146, 42.7273, 0],
                [-117.03931, 42.73383, 0],
                [-117.03917, 42.73438, 0],
                [-117.03907, 42.73489, 0],
                [-117.03817, 42.7433, 0],
                [-117.03816, 42.74335, 0],
                [-117.03791, 42.74641, 0],
                [-117.03786, 42.75, 0],
                [-117.03785, 42.75018, 0],
                [-117.03779, 42.75382, 0],
                [-117.03776, 42.75461, 0],
                [-117.03773, 42.75757, 0],
                [-117.03766, 42.75822, 0],
                [-117.03754, 42.75866, 0],
                [-117.03738, 42.75897, 0],
                [-117.03693, 42.75963, 0],
                [-117.03505, 42.76254, 0],
                [-117.03489, 42.76284, 0],
                [-117.03482, 42.76312, 0],
                [-117.03478, 42.76333, 0],
                [-117.0346, 42.76558, 0],
                [-117.03452, 42.76595, 0],
                [-117.03452, 42.76596, 0],
                [-117.03445, 42.76621, 0],
                [-117.03429, 42.76656, 0],
                [-117.03394, 42.76751, 0],
                [-117.03362, 42.76859, 0],
                [-117.03358, 42.76875, 0],
                [-117.03356, 42.76886, 0],
                [-117.03341, 42.76938, 0],
                [-117.0333, 42.7696, 0],
                [-117.03315, 42.76986, 0],
                [-117.03301, 42.77, 0],
                [-117.03291, 42.77007, 0],
                [-117.03266, 42.77027, 0],
                [-117.02932, 42.7724, 0],
                [-117.02785, 42.77338, 0],
                [-117.0277, 42.77347, 0],
                [-117.02758, 42.77359, 0],
                [-117.02752, 42.77369, 0],
                [-117.0275, 42.77381, 0],
                [-117.02751, 42.77403, 0],
                [-117.02813, 42.78018, 0],
                [-117.02831, 42.78149, 0],
                [-117.02835, 42.78159, 0],
                [-117.02852, 42.78184, 0],
                [-117.02879, 42.78212, 0],
                [-117.02899, 42.78235, 0],
                [-117.02905, 42.78241, 0],
                [-117.02923, 42.78262, 0],
                [-117.02935, 42.78282, 0],
                [-117.02944, 42.783, 0],
                [-117.02954, 42.7833, 0],
                [-117.03001, 42.78571, 0],
                [-117.03061, 42.78835, 0],
                [-117.03066, 42.78864, 0],
                [-117.03068, 42.78881, 0],
                [-117.03066, 42.78895, 0],
                [-117.03061, 42.78909, 0],
                [-117.03048, 42.78932, 0],
                [-117.03003, 42.79001, 0],
                [-117.02945, 42.79085, 0],
                [-117.02941, 42.79096, 0],
                [-117.0294, 42.7911, 0],
                [-117.0295, 42.79133, 0],
                [-117.03092, 42.79387, 0],
                [-117.03099, 42.79401, 0],
                [-117.03104, 42.79414, 0],
                [-117.03106, 42.79429, 0],
                [-117.03109, 42.79598, 0],
                [-117.0312, 42.79794, 0],
                [-117.0313, 42.79925, 0],
                [-117.03129, 42.7994, 0],
                [-117.03127, 42.79956, 0],
                [-117.03123, 42.79971, 0],
                [-117.03109, 42.79989, 0],
                [-117.03081, 42.80009, 0],
                [-117.02831, 42.8017, 0],
                [-117.02794, 42.80201, 0],
                [-117.02772, 42.80225, 0],
                [-117.02763, 42.80238, 0],
                [-117.02747, 42.80266, 0],
                [-117.02733, 42.80281, 0],
                [-117.02727, 42.803, 0],
                [-117.02716, 42.80346, 0],
                [-117.02706, 42.80405, 0],
                [-117.02528, 42.81312, 0],
                [-117.02516, 42.81352, 0],
                [-117.0251, 42.81369, 0],
                [-117.02463, 42.81534, 0],
                [-117.02457, 42.81561, 0],
                [-117.02457, 42.81563, 0],
                [-117.02454, 42.81577, 0],
                [-117.02453, 42.81593, 0],
                [-117.02452, 42.82211, 0],
                [-117.02446, 42.82678, 0],
                [-117.02443, 42.82701, 0],
                [-117.02441, 42.82709, 0],
                [-117.02427, 42.82744, 0],
                [-117.02414, 42.82772, 0],
                [-117.02404, 42.8279, 0],
                [-117.02395, 42.82823, 0],
                [-117.02389, 42.8288, 0],
                [-117.02374, 42.83336, 0],
                [-117.02374, 42.83372, 0],
                [-117.02375, 42.83393, 0],
                [-117.02377, 42.83404, 0],
                [-117.02377, 42.83406, 0],
                [-117.02407, 42.83528, 0],
                [-117.02423, 42.83618, 0],
                [-117.02425, 42.83653, 0],
                [-117.02425, 42.83671, 0],
                [-117.02424, 42.83671, 0],
                [-117.02423, 42.83689, 0],
                [-117.02419, 42.83725, 0],
                [-117.02412, 42.83772, 0],
                [-117.024, 42.83827, 0],
                [-117.02398, 42.83832, 0],
                [-117.02392, 42.83857, 0],
                [-117.02368, 42.83938, 0],
                [-117.02368, 42.8394, 0],
                [-117.02356, 42.83985, 0],
                [-117.02332, 42.84064, 0],
                [-117.02324, 42.84096, 0],
                [-117.02306, 42.8415, 0],
                [-117.02274, 42.84235, 0],
                [-117.02273, 42.84239, 0],
                [-117.02255, 42.84285, 0],
                [-117.0224, 42.84318, 0],
                [-117.02229, 42.84335, 0],
                [-117.02222, 42.84344, 0],
                [-117.02213, 42.84354, 0],
                [-117.02198, 42.84366, 0],
                [-117.02184, 42.84376, 0],
                [-117.02153, 42.84392, 0],
                [-117.02044, 42.84432, 0],
                [-117.0202, 42.84444, 0],
                [-117.02014, 42.84449, 0],
                [-117.02009, 42.84452, 0],
                [-117.02006, 42.84455, 0],
                [-117.01987, 42.84479, 0],
                [-117.01975, 42.84491, 0],
                [-117.01965, 42.84506, 0],
                [-117.01963, 42.84516, 0],
                [-117.01963, 42.84524, 0],
                [-117.01968, 42.8454, 0],
                [-117.01982, 42.84566, 0],
                [-117.01998, 42.8459, 0],
                [-117.02004, 42.84602, 0],
                [-117.0201, 42.84617, 0],
                [-117.02014, 42.84633, 0],
                [-117.02015, 42.84642, 0],
                [-117.02014, 42.84646, 0],
                [-117.02012, 42.84659, 0],
                [-117.02009, 42.84671, 0],
                [-117.02007, 42.8469, 0],
                [-117.02007, 42.847, 0],
                [-117.02006, 42.84712, 0],
                [-117.02001, 42.84738, 0],
                [-117.01997, 42.84751, 0],
                [-117.01991, 42.84764, 0],
                [-117.01972, 42.84799, 0],
                [-117.01956, 42.84837, 0],
                [-117.01938, 42.84886, 0],
                [-117.01805, 42.85213, 0],
                [-117.01712, 42.85474, 0],
                [-117.01706, 42.85494, 0],
                [-117.01669, 42.85599, 0],
                [-117.01626, 42.85733, 0],
                [-117.01541, 42.85983, 0],
                [-117.01455, 42.86248, 0],
                [-117.01372, 42.86534, 0],
                [-117.01366, 42.86551, 0],
                [-117.01366, 42.86552, 0],
                [-117.01357, 42.86585, 0],
                [-117.01343, 42.86628, 0],
                [-117.01314, 42.86727, 0],
                [-117.01286, 42.86815, 0],
                [-117.01286, 42.86817, 0],
                [-117.01265, 42.86885, 0],
                [-117.01262, 42.86893, 0],
                [-117.01244, 42.86955, 0],
                [-117.01242, 42.86964, 0],
                [-117.01202, 42.87095, 0],
                [-117.01201, 42.87096, 0],
                [-117.01153, 42.87258, 0],
                [-117.01148, 42.87271, 0],
                [-117.01111, 42.87393, 0],
                [-117.01111, 42.87394, 0],
                [-117.00988, 42.87798, 0],
                [-117.00983, 42.87818, 0],
                [-117.00925, 42.88002, 0],
                [-117.0091, 42.88055, 0],
                [-117.00904, 42.88073, 0],
                [-117.00904, 42.88074, 0],
                [-117.00879, 42.88159, 0],
                [-117.00878, 42.8816, 0],
                [-117.00832, 42.88298, 0],
                [-117.00818, 42.88346, 0],
                [-117.0081, 42.88379, 0],
                [-117.00809, 42.88393, 0],
                [-117.00809, 42.88408, 0],
                [-117.0083, 42.88552, 0],
                [-117.00834, 42.88587, 0],
                [-117.00838, 42.88609, 0],
                [-117.0084, 42.88644, 0],
                [-117.0084, 42.88662, 0],
                [-117.00835, 42.88694, 0],
                [-117.00833, 42.88703, 0],
                [-117.00806, 42.88776, 0],
                [-117.00795, 42.88811, 0],
                [-117.00784, 42.88899, 0],
                [-117.00777, 42.88942, 0],
                [-117.00777, 42.88944, 0],
                [-117.00768, 42.88998, 0],
                [-117.0076, 42.89057, 0],
                [-117.00737, 42.89155, 0],
                [-117.00703, 42.89274, 0],
                [-117.00701, 42.89278, 0],
                [-117.00695, 42.89294, 0],
                [-117.00685, 42.89317, 0],
                [-117.00662, 42.8936, 0],
                [-117.00661, 42.89363, 0],
                [-117.00655, 42.89375, 0],
                [-117.00644, 42.89408, 0],
                [-117.00633, 42.89469, 0],
                [-117.00633, 42.89475, 0],
                [-117.00629, 42.89489, 0],
                [-117.00625, 42.89507, 0],
                [-117.00614, 42.89537, 0],
                [-117.00562, 42.8966, 0],
                [-117.0052, 42.89752, 0],
                [-117.00441, 42.89883, 0],
                [-117.00392, 42.89957, 0],
                [-117.00356, 42.90018, 0],
                [-117.00319, 42.90076, 0],
                [-117.00278, 42.90148, 0],
                [-117.0027, 42.90165, 0],
                [-117.00209, 42.90316, 0],
                [-117.00185, 42.90385, 0],
                [-117.00183, 42.90393, 0],
                [-117.00154, 42.90475, 0],
                [-117.00148, 42.90487, 0],
                [-117.00142, 42.90497, 0],
                [-117.00129, 42.90515, 0],
                [-117.00017, 42.90651, 0],
                [-116.99974, 42.9071, 0],
                [-116.99905, 42.90799, 0],
                [-116.99775, 42.90982, 0],
                [-116.99723, 42.91051, 0],
                [-116.99701, 42.91084, 0],
                [-116.99695, 42.91096, 0],
                [-116.99672, 42.91134, 0],
                [-116.99659, 42.91151, 0],
                [-116.99604, 42.91216, 0],
                [-116.99585, 42.91246, 0],
                [-116.99566, 42.9128, 0],
                [-116.99553, 42.91298, 0],
                [-116.99548, 42.91302, 0],
                [-116.99543, 42.91307, 0],
                [-116.9953, 42.91316, 0],
                [-116.99445, 42.9135, 0],
                [-116.99443, 42.9135, 0],
                [-116.9942, 42.91361, 0],
                [-116.9941, 42.91371, 0],
                [-116.99406, 42.91376, 0],
                [-116.99403, 42.91387, 0],
                [-116.9941, 42.91405, 0],
                [-116.99453, 42.91452, 0],
                [-116.99466, 42.91468, 0],
                [-116.99482, 42.91493, 0],
                [-116.99491, 42.91511, 0],
                [-116.99497, 42.91531, 0],
                [-116.995, 42.91544, 0],
                [-116.99511, 42.91582, 0],
                [-116.99521, 42.91609, 0],
                [-116.99525, 42.91618, 0],
                [-116.99526, 42.91619, 0],
                [-116.99543, 42.91644, 0],
                [-116.99563, 42.91667, 0],
                [-116.99591, 42.91704, 0],
                [-116.99601, 42.91722, 0],
                [-116.99613, 42.91748, 0],
                [-116.9964, 42.91818, 0],
                [-116.99645, 42.91835, 0],
                [-116.99654, 42.91858, 0],
                [-116.99661, 42.91888, 0],
                [-116.99675, 42.91938, 0],
                [-116.99676, 42.9194, 0],
                [-116.9968, 42.91957, 0],
                [-116.99683, 42.91977, 0],
                [-116.99683, 42.92018, 0],
                [-116.9968, 42.9203, 0],
                [-116.99669, 42.92089, 0],
                [-116.99671, 42.92115, 0],
                [-116.99675, 42.92131, 0],
                [-116.99683, 42.92154, 0],
                [-116.99698, 42.9219, 0],
                [-116.99708, 42.92217, 0],
                [-116.99711, 42.92234, 0],
                [-116.99712, 42.92246, 0],
                [-116.99712, 42.92273, 0],
                [-116.99709, 42.92291, 0],
                [-116.99685, 42.92383, 0],
                [-116.99683, 42.92388, 0],
                [-116.99679, 42.92401, 0],
                [-116.99676, 42.92421, 0],
                [-116.99676, 42.92436, 0],
                [-116.99678, 42.92452, 0],
                [-116.99679, 42.92456, 0],
                [-116.99679, 42.9246, 0],
                [-116.99685, 42.92478, 0],
                [-116.99703, 42.92502, 0],
                [-116.99716, 42.92514, 0],
                [-116.99731, 42.92526, 0],
                [-116.99914, 42.92639, 0],
                [-116.99959, 42.92669, 0],
                [-116.99983, 42.92689, 0],
                [-116.99991, 42.92702, 0],
                [-117.00001, 42.92725, 0],
                [-117.00004, 42.92737, 0],
                [-117.00005, 42.92758, 0],
                [-117, 42.92823, 0],
                [-116.99997, 42.92842, 0],
                [-116.99987, 42.92977, 0],
                [-116.99985, 42.93032, 0],
                [-116.99975, 42.9319, 0],
                [-116.99973, 42.93252, 0],
                [-116.99978, 42.93297, 0],
                [-116.99989, 42.93354, 0],
                [-116.99993, 42.93383, 0],
                [-116.99994, 42.93386, 0],
                [-117.00004, 42.93457, 0],
                [-117.00001, 42.93529, 0],
                [-116.99997, 42.93575, 0],
                [-116.99996, 42.93598, 0],
                [-116.99983, 42.93685, 0],
                [-116.99979, 42.93727, 0],
                [-116.99976, 42.93777, 0],
                [-116.99977, 42.93803, 0],
                [-116.99979, 42.93822, 0],
                [-116.99985, 42.93852, 0],
                [-116.9999, 42.93869, 0],
                [-117.00001, 42.93899, 0],
                [-117.00035, 42.9395, 0],
                [-117.00037, 42.93952, 0],
                [-117.0007, 42.94, 0],
                [-117.00072, 42.94002, 0],
                [-117.00081, 42.94017, 0],
                [-117.00216, 42.94217, 0],
                [-117.00249, 42.94269, 0],
                [-117.00283, 42.94319, 0],
                [-117.00338, 42.94406, 0],
                [-117.00365, 42.94443, 0],
                [-117.00383, 42.94471, 0],
                [-117.00388, 42.94477, 0],
                [-117.00442, 42.94561, 0],
                [-117.00467, 42.94607, 0],
                [-117.00474, 42.94627, 0],
                [-117.00478, 42.94644, 0],
                [-117.00487, 42.947, 0],
                [-117.005, 42.94753, 0],
                [-117.00502, 42.94766, 0],
                [-117.00507, 42.94786, 0],
                [-117.00526, 42.94838, 0],
                [-117.00526, 42.9484, 0],
                [-117.00547, 42.9489, 0],
                [-117.00547, 42.94891, 0],
                [-117.00556, 42.94909, 0],
                [-117.00568, 42.94927, 0],
                [-117.00594, 42.94972, 0],
                [-117.00613, 42.95012, 0],
                [-117.00624, 42.95045, 0],
                [-117.00631, 42.95075, 0],
                [-117.0063, 42.95093, 0],
                [-117.0063, 42.95116, 0],
                [-117.00616, 42.95282, 0],
                [-117.00608, 42.95407, 0],
                [-117.00602, 42.95433, 0],
                [-117.00596, 42.95451, 0],
                [-117.0059, 42.95461, 0],
                [-117.00575, 42.9548, 0],
                [-117.00551, 42.95501, 0],
                [-117.00535, 42.9551, 0],
                [-117.0053, 42.95514, 0],
                [-117.0048, 42.9554, 0],
                [-117.00463, 42.95551, 0],
                [-117.00453, 42.95559, 0],
                [-117.00452, 42.95559, 0],
                [-117.0044, 42.95574, 0],
                [-117.00435, 42.95583, 0],
                [-117.00409, 42.95637, 0],
                [-117.00386, 42.95672, 0],
                [-117.00384, 42.95674, 0],
                [-117.00352, 42.95716, 0],
                [-117.00342, 42.95733, 0],
                [-117.00339, 42.95745, 0],
                [-117.00339, 42.95759, 0],
                [-117.00342, 42.95765, 0],
                [-117.00345, 42.95775, 0],
                [-117.0035, 42.95785, 0],
                [-117.00357, 42.95795, 0],
                [-117.00362, 42.95801, 0],
                [-117.00371, 42.95808, 0],
                [-117.00393, 42.95821, 0],
                [-117.00407, 42.95826, 0],
                [-117.00429, 42.95832, 0],
                [-117.00437, 42.95835, 0],
                [-117.00572, 42.95873, 0],
                [-117.00584, 42.95879, 0],
                [-117.00587, 42.95881, 0],
                [-117.00593, 42.95884, 0],
                [-117.00604, 42.95894, 0],
                [-117.00605, 42.95894, 0],
                [-117.00613, 42.95904, 0],
                [-117.00623, 42.95919, 0],
                [-117.00628, 42.95933, 0],
                [-117.00631, 42.95944, 0],
                [-117.00632, 42.95951, 0],
                [-117.00634, 42.95982, 0],
                [-117.00633, 42.96015, 0],
                [-117.00634, 42.96029, 0],
                [-117.00634, 42.96046, 0],
                [-117.00637, 42.96058, 0],
                [-117.00645, 42.96071, 0],
                [-117.00655, 42.96082, 0],
                [-117.00667, 42.96092, 0],
                [-117.007, 42.96109, 0],
                [-117.00719, 42.96114, 0],
                [-117.00744, 42.96119, 0],
                [-117.00784, 42.96123, 0],
                [-117.00831, 42.96125, 0],
                [-117.00895, 42.96124, 0],
                [-117.00929, 42.9612, 0],
                [-117.00936, 42.9612, 0],
                [-117.0102, 42.96106, 0],
                [-117.01466, 42.9601, 0],
                [-117.01631, 42.95962, 0],
                [-117.01658, 42.95956, 0],
                [-117.01712, 42.9595, 0],
                [-117.01769, 42.95946, 0],
                [-117.01808, 42.95951, 0],
                [-117.01878, 42.95967, 0],
                [-117.0194, 42.95977, 0],
                [-117.01965, 42.95976, 0],
                [-117.02002, 42.95971, 0],
                [-117.02026, 42.95966, 0],
                [-117.0215, 42.95928, 0],
                [-117.02164, 42.95925, 0],
                [-117.02178, 42.95924, 0],
                [-117.02198, 42.95925, 0],
                [-117.02271, 42.95933, 0],
                [-117.02342, 42.95943, 0],
                [-117.02358, 42.95946, 0],
                [-117.02389, 42.95955, 0],
                [-117.02427, 42.95971, 0],
                [-117.02476, 42.95998, 0],
                [-117.02579, 42.96065, 0],
                [-117.02619, 42.96093, 0],
                [-117.02654, 42.96122, 0],
                [-117.02674, 42.96141, 0],
                [-117.02722, 42.96199, 0],
                [-117.0274, 42.96226, 0],
                [-117.02779, 42.96296, 0],
                [-117.02797, 42.96341, 0],
                [-117.02809, 42.96386, 0],
                [-117.02817, 42.96444, 0],
                [-117.02821, 42.96496, 0],
                [-117.02827, 42.96546, 0],
                [-117.02829, 42.96588, 0],
                [-117.02841, 42.96708, 0],
                [-117.02846, 42.96741, 0],
                [-117.02856, 42.96766, 0],
                [-117.02867, 42.96786, 0],
                [-117.0288, 42.96807, 0],
                [-117.02887, 42.96816, 0],
                [-117.02907, 42.96837, 0],
                [-117.02929, 42.96853, 0],
                [-117.02955, 42.96869, 0],
                [-117.02972, 42.96877, 0],
                [-117.03008, 42.96891, 0],
                [-117.03027, 42.96896, 0],
                [-117.03051, 42.96901, 0],
                [-117.03089, 42.96906, 0],
                [-117.03148, 42.96907, 0],
                [-117.03546, 42.96882, 0],
                [-117.03581, 42.96882, 0],
                [-117.03591, 42.96883, 0],
                [-117.03598, 42.96883, 0],
                [-117.03621, 42.96887, 0],
                [-117.03621, 42.96888, 0],
                [-117.03634, 42.96891, 0],
                [-117.03657, 42.96898, 0],
                [-117.0477, 42.973, 0],
                [-117.04821, 42.97317, 0],
                [-117.04859, 42.97327, 0],
                [-117.04919, 42.97338, 0],
                [-117.04924, 42.97338, 0],
                [-117.05209, 42.97377, 0],
                [-117.053, 42.97383, 0],
                [-117.053, 42.97383, 0],
                [-117.0532, 42.973903],
                [-117.053327, 42.97403, 0]
            ]
        },
        id: "6f428293-11f0-4549-b985-58e783c641cd"
    }, 
    // {
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-116.09716, 42.98332, 0]
    //     },
    //     properties: {
    //         name: "Grand View, Idaho",
    //         "shape": "Marker",
    //         "category": "default"
    //     },
    //     id: "6d924025-efe3-48a9-9c19-f92546af57a1"
    // }, {
    //     type: "Feature",
    //     geometry: {
    //         type: "Point",
    //         coordinates: [-117.053327, 42.97403, 0]
    //     },
    //     properties: {
    //         name: "Jordan Valley, Oregeon",
    //         "shape": "Marker",
    //         "category": "default"
    //     },
    //     id: "3bf3bbdd-3cbc-42fe-b6a4-c0880fd0501a"
    // }
    ]
};

export default OwyheeUplands;