import React from "react";

function IconRoute() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 217.205 217.205">
                <path d="M167.631,101.102H49.574c-16.216,0-29.408-13.199-29.408-29.422,0-16.211,13.192-29.399,29.408-29.399H123.36301c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H49.574c-24.486,0-44.408,19.917-44.408,44.399,0,24.494,19.922,44.422,44.408,44.422h118.057c16.216,0,29.408,13.19901,29.408,29.423,0,16.211-13.192,29.399-29.408,29.399H93.205c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h74.42601c24.48599,0,44.408-19.91701,44.408-44.399-.00002-24.495-19.92201-44.423-44.40802-44.423Z" />
                <path d="M48.516,130.00101c-17.407,0-31.568,14.162-31.568,31.56799,0,26.86501,25.192,52.367,26.265,53.439,1.407,1.407,3.314,2.19701,5.304,2.19701,1.989,0,3.897-.78999,5.304-2.19701,1.072-1.073,26.263-26.57401,26.263-53.439-.002-17.40601-14.162-31.56799-31.568-31.56799Zm0,68.35599c-6.477-7.995-16.568-22.713-16.568-36.78799,0-9.136,7.433-16.56799,16.568-16.56799s16.566,7.433,16.566,16.56799c0,14.075-10.091,28.793-16.566,36.78799Z" />
                <path d="M168.05299,87.202c1.91901,0,3.838-.732,5.302-2.195,1.073-1.072,26.278-26.573,26.278-53.44,0-17.406-14.16699-31.56701-31.58-31.56701-17.407,0-31.56801,14.161-31.56801,31.566,0,26.866,25.192,52.367,26.26601,53.439,1.46301,1.465,3.382,2.19701,5.302,2.19701Zm0-72.202c9.14301,0,16.58,7.432,16.58,16.566,0,14.076-10.10001,28.796-16.57899,36.79-6.476-7.994-16.569-22.713-16.569-36.79-.00101-9.134,7.43201-16.566,16.56799-16.566Z" />
            </svg>
        </>
    );
}

export default IconRoute;
