import { Svg, G, Path } from "@react-pdf/renderer";
import React from "react";

const IconScenicByways = () => {
	return (
		<>
			<Svg width="28" viewBox="0 0 36 37">
				<G id="scenic-byway" transform="translate(9.234 7.315)">
					<Path fill="#000" d="M5.718,11.18033H4.54433c-0.14131-0.00173-0.25727,0.11143-0.259,0.25274
						c-0.00003,0.00209-0.00003,0.00417,0,0.00626v2.49278c-0.00173,0.14131,0.11143,0.25727,0.25274,0.259
						c0.00209,0.00002,0.00417,0.00002,0.00626,0H5.718c0.14131,0.00173,0.25727-0.11143,0.259-0.25274
						c0.00003-0.00209,0.00003-0.00417,0-0.00626v-2.49278C5.97332,11.29784,5.85949,11.18401,5.718,11.18033z M5.459,13.67311H4.80333
						v-1.97633H5.459V13.67311z"/>
					<Path fill="#000" d="M5.718,7.57844H4.54433c-0.14131-0.00173-0.25727,0.11143-0.259,0.25274
						c-0.00003,0.00209-0.00003,0.00417,0,0.00626v2.49278c-0.00173,0.14131,0.11143,0.25727,0.25274,0.259
						c0.00209,0.00003,0.00417,0.00003,0.00626,0H5.718c0.14131,0.00173,0.25727-0.11143,0.259-0.25274
						c0.00003-0.00209,0.00003-0.00417,0-0.00626V7.83744C5.97332,7.69595,5.85949,7.58212,5.718,7.57844z M5.459,10.07122H4.80333
						V8.09644H5.459V10.07122z"/>
					<Path fill="#000" d="M7.482,3.95244C7.44733,3.85258,7.35435,3.7847,7.24867,3.78211H5.88756
						c-0.0472-0.04125-0.10765-0.06416-0.17033-0.06456C5.58708,3.71542,5.47741,3.81423,5.466,3.94389
						C5.45699,3.96893,5.45149,3.99511,5.44967,4.02167C5.45114,4.04676,5.45665,4.07145,5.466,4.09478v2.07511H4.81111V3.97656
						c0.00173-0.14131-0.11143-0.25727-0.25274-0.259c-0.00209-0.00003-0.00417-0.00003-0.00626,0
						C4.48916,3.71646,4.42819,3.73957,4.38178,3.78211H2.96544c-0.10678-0.00075-0.20151,0.0684-0.23333,0.17033l-4.34,13.59789
						c-0.02196,0.0741-0.01,0.15412,0.03267,0.21856c0.04484,0.06247,0.11757,0.09883,0.19444,0.09722h5.768
						c0.01647,0.00133,0.03301-0.00134,0.04822-0.00778c0.0306,0.01326,0.06397,0.01886,0.09722,0.01633
						c0.14131,0.00173,0.25727-0.11143,0.259-0.25274c0.00003-0.00208,0.00003-0.00417,0-0.00626v-2.016h0.65567v1.94444
						c-0.00935,0.02333-0.01486,0.04802-0.01633,0.07311c0.00162,0.08642,0.04742,0.16598,0.12133,0.21078
						c0.04498,0.03306,0.09976,0.05005,0.15556,0.04822c0.02665-0.00108,0.05294-0.0066,0.07778-0.01633H11.619
						c0.07658,0.00031,0.14875-0.03577,0.19444-0.09722c0.04688-0.06275,0.05915-0.14484,0.03267-0.21856L7.482,3.95244z
						M5.97622,17.38078v-2.02222c0.00173-0.14131-0.11143-0.25727-0.25274-0.259c-0.00209-0.00002-0.00417-0.00002-0.00626,0H4.54278
						c-0.14131-0.00173-0.25727,0.11143-0.259,0.25274c-0.00003,0.00209-0.00003,0.00417,0,0.00626v2.02222h-5.32467L3.14278,4.26822
						h1.14178v2.16067c-0.00044,0.14132,0.11378,0.25624,0.2551,0.25668c0.00104,0,0.00208,0,0.00312-0.00001h1.17444
						c0.14131,0.00173,0.25727-0.11143,0.259-0.25274c0.00003-0.00209,0.00003-0.00417,0-0.00626V4.26822h1.10056l4.22489,13.11256
						H5.97622z"/>
					<Path fill="#000" d="M-1.42978,13.78667l2.77667-7.87578c0.05759-0.13094-0.00186-0.28377-0.1328-0.34136
						c-0.13094-0.05759-0.28377,0.00186-0.34136,0.1328C0.86837,5.71224,0.86464,5.7224,0.86156,5.73278l-2.78444,7.88433
						c-0.04757,0.13461,0.02169,0.28248,0.15556,0.33211c0.02871,0.0093,0.05852,0.0148,0.08867,0.01633
						C-1.56668,13.96317-1.46773,13.89205-1.42978,13.78667z"/>
					<Path fill="#000" d="M1.46045,5.09422c0.02871,0.0093,0.05852,0.0148,0.08867,0.01633
						c0.10885-0.00098,0.20563-0.06953,0.24267-0.17189l0.23333-0.66344C2.06589,4.13812,1.9878,3.99391,1.85069,3.95314
						c-0.12511-0.03721-0.25836,0.0245-0.31091,0.14397L1.30644,4.76056C1.25526,4.89182,1.32019,5.03973,1.45146,5.09091
						C1.45443,5.09207,1.45743,5.09317,1.46045,5.09422z"/>
					<Path  fill="#000" d="M12.23345,13.61633l-2.77667-7.875C9.40674,5.6071,9.25735,5.53885,9.12311,5.58889
						C8.98888,5.63893,8.92062,5.78832,8.97067,5.92255l2.77667,7.87578c0.03755,0.10175,0.13421,0.1696,0.24267,0.17033
						c0.03014-0.00154,0.05995-0.00703,0.08867-0.01633c0.13387-0.04963,0.20313-0.1975,0.15556-0.33211L12.23345,13.61633z"/>
					<Path fill="#000" d="M8.51878,4.93867C8.55633,5.04042,8.65299,5.10826,8.76145,5.109
						C8.79159,5.10746,8.8214,5.10197,8.85011,5.09267c0.13326-0.04575,0.2042-0.19087,0.15845-0.32413
						C9.00764,4.76586,9.00667,4.7632,9.00567,4.76056L8.77234,4.09711C8.73156,3.96,8.58735,3.88191,8.45025,3.92269
						s-0.2152,0.18498-0.17442,0.32209C8.27891,4.25515,8.28265,4.26532,8.287,4.27522l0.23333,0.66344H8.51878z"/>
				</G>
			</Svg>
		</>
	);
}

export default IconScenicByways;