import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch} from "react-redux";
import { useSearchParams } from "react-router-dom";
import Popup from "./Popups";
import Cart from "./NavBar/Cart";
import AccountMenu from "./NavBar/AccountMenu";
import Alert from "./Alert/Alert";
import SidePanel from "./SidePanel/SidePanel";
import Dashboard from "./Dashboard/Controller";
import Map from "./MapLeaflet";
import SearchInput from "./Search/SearchInput";

import { Walktour } from "walktour";

//ICONS
import LogoWhite from "../images/logo-white";
import {
  IconArrowLeft,
  IconArrowRight,
  IconSearch,
  IconTutorial,
} from "../images/icons";

//REDUX-RTK
import { updatePopups } from "../store/actions/popupsActions";

import { addToCart, removeFromCart } from "../store/actions/cartActions";
import { addToTTCart, removeFromTTCart } from "../store/actions/travelTipCartActions";
import { getMarkers } from "./Markers/markers";
import {  groupedByways, activities } from "../data/filters-data";
import { updateActivities } from "../store/actions/activitiesActions";
import { updateByways } from "../store/actions/bywaysActions";
import FilterDropdown from "./NavBar/bywayDropdown";
import { endTutorial, updateStep } from "../store/actions/tutorialActions";
import getRecommendedTravelTips from "./Recommended/getRecommendedTravelTips";

const Main = () => {
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const activitiesSelectRef = useRef(null);
  const [showClearAll, setShowClearAll] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [tourRef, setTourRef] = useState(null);
  const [showMap, setShowMap] = useState(true);
  const [query, setQuery] = useState(null);
  const [logoUrl, setLogoUrl] = useState(window.location.href);
  const [searchParams] = useSearchParams();
  const markers = useSelector((state) => state.markers.markers);
  const recommendedTravelTips = useSelector((state) => state.recommendations.travelTips);
  const showPopup = useSelector((state) => state.popup);
  const userName = useSelector((state) => state.user.userName);
  const cart = useSelector((state) => state.cart.cartItems);
  const ttcart = useSelector((state) => state.ttcart.cartItems);
  const showTutorial = useSelector((state) => state.tutorial.active);
  const tutorialStep = useSelector((state) => state.tutorial.currentStep);
  const [tutorialCartItem, setTutorialCartItem] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const selectedActivities = useSelector((state) => state.activities.selectedActivities);
  const selectedByways = useSelector((state) => state.byways.selectedByways);

  //map vars to pass via forward ref
  const northEastBounds = L.latLng(54.100971058261564, -122.50336811441568);
  const southWestBounds = L.latLng(38.3800071147504, -106.61713826558358);
  const mapBounds = L.latLngBounds(southWestBounds, northEastBounds);
  const mapZoom = 6;

  useEffect(() => {
    dispatch(getMarkers());
    dispatch(getRecommendedTravelTips());
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener on component unmount
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    
  }, []);

  useEffect(() => {
    setLogoUrl(window.location.href);
  }, [cart]);

  useEffect(() => {
    if (userName !== null) {
      return;
    }
    setShowCart(false);
    setShowAccount(false);
    setShowDashboard(false);
    setShowMap(true);
  }, [userName]);
  
  useEffect(() => {
    if(firstLoad === false){
      const iParams = searchParams.getAll("i");
      const tParams = searchParams.getAll("t");
      
      // Check if both markers and recommendedTravelTips are loaded
      if (markers.length > 0 && recommendedTravelTips.length > 0) {
        // Add items to the cart based on 'i' parameter
        markers.flat().forEach((marker) => {
          iParams.forEach((i) => {
            if (i == marker.id) {
              dispatch(addToCart(marker));
            }
          });
        });
    
        // Add items to the TTCart based on 't' parameter
        recommendedTravelTips.flat().forEach((ttip) => {
          tParams.forEach((t) => {
            if (t == ttip.id) {
              dispatch(addToTTCart(ttip));
            }
          });
        });
        setFirstLoad(true);
      }
    }
  }, [markers, recommendedTravelTips]);

  // Function to update the URL based on the cart and ttcart values
  const updateURL = () => {
    // Add new 'i' parameters based on the cart
    cart.forEach(item => {
      searchParams.set('i', item.toString());
    });

    // Add new 't' parameters based on the ttcart
    ttcart.forEach(titem => {
      searchParams.set('t', titem.toString());
    });

  };

  useEffect(() => {
    // Listen for changes in the cart and ttcart values
    updateURL();
  }, [cart, ttcart]);


  // useEffect(() => {
  //   // if (activitiesSelectRef) {
  //   //   //activitiesSelectRef.current.selectAll();
  //   //   //setShowClearAll(true);
  //   // }
  
  //   const iParams = searchParams.getAll("i");
  //   if (iParams.length > 0) {
  //     dispatch(updatePopups({ display: false, type: null }));
  //     if (markers.length > 0) {
  //       markers.flat().map((marker) => {
  //         iParams.map((i) => {
  //           i == marker.id && dispatch(addToCart(marker));
  //         });
  //       });
  //     }
  //   }
  //   setCartLoaded(true);

  // }, [markers]);
  
  // useEffect(() => {
  //   const tParams = searchParams.getAll("t");
  //   if (tParams.length > 0) {
  //     if (recommendedTravelTips.length > 0) {
  //       recommendedTravelTips.flat().map((ttip) => {
  //         tParams.map((i) => {
  //           i == ttip.id && dispatch(addToTTCart(ttip));
  //         });
  //       });
  //     }
  //   }
  //   setTipsLoaded(true);

  // }, [recommendedTravelTips]);

  const toggleMap = () => {
    setShowCart(false);
    setShowAccount(false);
    setShowDashboard(false);
    setShowMap(true);
  };

  const toggleDashboard = () => {
    setShowSearchBar(false);
    setShowCart(false);
    setShowAccount(false);
    setShowDashboard(true);
    setShowMap(false);
  };

  const toggleAccount = () => {
    setShowSearchBar(false);
    setShowCart(false);
    setShowAccount(true);
    setShowDashboard(false);
    setShowMap(false);
    setShowSidePanel(false);
  };

  const toggleCart = () => {
    setShowCart(!showCart);
    setShowSidePanel(false);
    setShowSearchBar(false);
  };

  const toggleSidePanel = () => {
    setShowCart(false);
    setShowSidePanel(!showSidePanel);
  };

  const toggleSearchBar = () => {
    setShowAccount(false);
    setShowCart(false);
    setShowDashboard(false);
    setShowMap(true);
    setShowSearchBar(!showSearchBar);
  };

  const toggleFilters = () => {
    setShowCart(false);
    setShowSidePanel(false);
    setShowFilterDropdown(false);
    dispatch(updatePopups({ display: true, type: 'activities', tab: 'userguide' }));
  };

  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const handleResize = () => {
    if (mapRef.current) {
        setTimeout(() => mapRef.current.invalidateSize(), 300);
    }
  };

  const clearAllFilters = () => {
    setShowSidePanel(false);
    setShowCart(false);
    setShowFilterDropdown(false);
    //console.log('cleared filters');
    //activitiesSelectRef.current.deselectAll();
    dispatch(updateActivities([]));
    dispatch(updateByways([]));
    //bywaysSelectRef.current.deselectAll();
    //regionsSelectRef.current.selectAll();
    setShowClearAll(false);
  };

  useEffect(() => {
    //console.log(selectedActivities.length);
    //console.log(selectedByways.length);
    if (selectedActivities.length || selectedByways.length) {
      setShowClearAll(true);
    } else {
      setShowClearAll(false);
    }
  }, [selectedActivities, selectedByways]);

  const updateQuery = (e) => {
    let newQuery = e;
    if (e.trim().length < 3) return false;
    if (query !== newQuery) {
      setQuery(newQuery);
    }
    dispatch(updatePopups({ display: true, type: "search" }));
  };

  useEffect(() => {
    if (document.getElementById("tidio-chat")) {
      document.getElementById("tidio-chat-code").remove();
      document.getElementById("tidio-chat").remove();
    }

  }, [showMap]);

  useEffect(() => {
    if (showTutorial === true) {
      dispatch(updatePopups({ display: false }));
      setShowCart(false);
      setShowAccount(false);
      setShowDashboard(false);
      setShowMap(true);
      //mapRef.current.setView(mapBounds.getCenter(), mapZoom);
    }
  }, [showTutorial]);

  useEffect(() => {
    let controlledTimeout = 0;
    //0 is opening state on the map / not able to do anything about that...

    if (tutorialStep === 0) {
        //let randoAct = Math.floor(Math.random() * activities.length);
        //dispatch(updateActivities(activities[randoAct].value.split(',')));
    }

    if (tutorialStep === 1) {
      // do nothing (next)
      // closes byway (prev)
      setShowSidePanel(false);
    }

    if (tutorialStep === 2) {
      // opens activities (next)
      // closes byway (prev)
      let randoAct = Math.floor(Math.random() * activities.length);
      dispatch(updateActivities(activities[randoAct].value.split(',')));

      dispatch(updatePopups({ display: false }));
      dispatch(updatePopups({ display: true, type: 'activities', tab: 'activities' }));
        
      //bywaysSelectRef.current.close();
    }

    if (tutorialStep === 3) {
      //close activities & regions, open byways
      //activitiesSelectRef.current.close();
      
      dispatch(updatePopups({ display: false }));
      dispatch(updatePopups({ display: true, type: 'activities', tab: 'byways' }));
      //bywaysSelectRef.current.toggleList();
      //regionsSelectRef.current.close();
    }

    if (tutorialStep === 4) {
      dispatch(updatePopups({ display: false }));
      setShowSidePanel(false);
    }

    if (tutorialStep === 5) {
      //close regions dropdown & turn off side panel
      //regionsSelectRef.current.close();
      setShowSidePanel(true);
    }

    if (tutorialStep === 6) {
      setShowSidePanel(false);
      // Check if 'leaflet-popup-content-wrapper' exists
      let popupContentWrapperExists = document.getElementsByClassName("leaflet-popup-content-wrapper").length > 0;
    
      // If 'leaflet-popup-content-wrapper' doesn't exists, click on the 'leaflet-marker-icon'
      if (popupContentWrapperExists) {
        document.getElementsByClassName("leaflet-marker-icon")[0].click();
      }
    
    }

    if (tutorialStep === 7) {
      controlledTimeout = 100;
      
      // Check if 'leaflet-popup-content-wrapper' exists
      let popupContentWrapperExists = document.getElementsByClassName("leaflet-popup-content-wrapper").length > 0;
    
      // If 'leaflet-popup-content-wrapper' doesn't exists, click on the 'leaflet-marker-icon'
      if (!popupContentWrapperExists) {
        let leafletIcons = document.getElementsByClassName("leaflet-div-icon");
        if (leafletIcons) {
          let randoAct = Math.floor(Math.random() * leafletIcons.length);
          leafletIcons[randoAct].click();
        }
      }
    
      setShowSidePanel(false);
    }
    

    if (tutorialStep === 8) {
      controlledTimeout = 100;
      //if the user does not have any items in cart, add one for the tutorial.
      if (!cart.length) {
        let leafletPopup = document.querySelector('.leaflet-popup .add-to-cart');
        if (leafletPopup) {
          leafletPopup.click();
          setTutorialCartItem(true);
        }
      }
    }

    if (tutorialStep === 9) {
      controlledTimeout = 100;
        setShowCart(false);
    }

    if (tutorialStep === 10) {
      controlledTimeout = 100;
        setShowCart(false);
    }

    if (tutorialStep === 11) {
        controlledTimeout = 100;
        setShowMap(true);
        setShowCart(!showCart);
        setShowSidePanel(false);
        setShowSearchBar(false);
      }

      if (tutorialStep === 12) {
        setShowMap(false);
        setShowCart(false);
        toggleDashboard();
      }

      if (tutorialStep === 13) {
        setShowCart(false);
        toggleDashboard();
      }

      if (tutorialStep === 14) {
        controlledTimeout = 100;
        let mapDiv = document.querySelector('.dashboard-wrapper .itinerary-map');
        if (mapDiv) {
          mapDiv.scrollIntoView();
        }
      }

      if (tutorialStep === 15) {
        controlledTimeout = 100;
        let itineraryWrapper = document.querySelector('.dashboard-wrapper .recommended.tt');
        if (itineraryWrapper) {
          itineraryWrapper.scrollIntoView();
        }
      }
    
    if (!tourRef) return;
    setTimeout(() => {
      tourRef?.goToStep(tutorialStep);
    }, controlledTimeout);

  }, [tutorialStep]);

     
  const selectActivities = () => {
    dispatch(updatePopups({ display: true, type: 'activities', data : 'Activities'}));
  }

  return (
    <>
      <div
        id="travel-assistant"
        className={showTutorial ? "tutorial-active" : ""}
      >
        <SidePanel
          map={mapRef.current && mapRef.current}
          header={<h1 className="sidebar-title">Activities</h1>}
          closeSidePanel={toggleSidePanel.bind(this)}
          className={showSidePanel ? "show" : ""}
        />

        <div
          className={`main-content ${showMap ? "view-map" : "view-dashboard"}`}
        >
          <div className="filterBar">
            {showMap && (
              <div className={`filterSearch ${showSearchBar ? "show" : ""}`}>
                <SearchInput searchQuery={query} updateQuery={updateQuery} />
              </div>
            )}

            <a href={logoUrl} className="vi-logo">
              <LogoWhite />
            </a>

            <div className={`filterOptions ${showMap ? "active" : ""}`}>
              {/* <DropdownMultiple
                arrowDownIcon={<IconArrowDown />}
                arrowUpIcon={<IconArrowUp />}
                name="activities"
                title="Activities"
                titleSingular="Activity"
                list={activities}
                ref={activitiesSelectRef}
                onChange={() => {
                  setActivities();
                }}
              />
            */}
            <div className="dd-wrapper">
                <button type="button" className="dd-header " onClick={() => {selectActivities()}}>
                    Select Activities
                </button>
            </div>
              <FilterDropdown 
                groupedOptions={groupedByways} 
                showFilterDropdown={showFilterDropdown} 
                toggleFilterDropdown={toggleFilterDropdown.bind(this)} />

              {showClearAll && (
                <a
                  onClick={() => {
                    clearAllFilters();
                  }}
                  className="clearAll"
                  title="Clear all filters"
                  aria-label="Clear all filters"
                >
                  Clear All
                </a>
              )}
            </div>
            <div className="navLinks">
              <div
                className="search-toggle"
                onClick={toggleSearchBar.bind(this)}
              >
                <IconSearch />
              </div>
              <Cart
                showCart={showCart}
                toggleCart={toggleCart.bind(this)}
                showDashboard={toggleDashboard.bind(this)}
              />
              <AccountMenu toggleAccount={toggleAccount.bind(this)} />
            </div>
          </div>

          <div className="map-wrapper">
            {showMap ? (
              <Map
                ref={mapRef}
                mapZoom={mapZoom}
                mapBounds={mapBounds}
                showDashboard={showDashboard}
                showAccount={showAccount}
              />
            ) : (
              <Dashboard
                showAccount={showAccount}
                passedFunctions={{
                  toggleMap: toggleMap.bind(this),
                  clearFilters: clearAllFilters.bind(this),
                }}
              />
            )}

            {showMap ? (
              <div className="map-buttons">
                <div className={`list-view ${showSidePanel ? "open" : ""}`}>
                  <a
                    onClick={toggleSidePanel.bind(this)}
                    className="listview-btn btn btn-small btn-white-shadow"
                    title="Open list view"
                    aria-label="Open list view"
                  >
                    {showSidePanel ? <IconArrowLeft /> : <IconArrowRight />}
                    {showSidePanel ? "" : "List View"}
                  </a>
                </div>
                <div className="helper-btns">
                  {/* <MapLegend
                    showLegend={showLegend}
                    toggleLegend={toggleLegend.bind(this)}
                  /> */}
                  <a
                    onClick={toggleFilters.bind(this)}
                    title="Open Guide"
                    aria-label="Open Guide"
                    className={`map-tutorial`}
                  >
                    <IconTutorial /> <span>Guide/Tutorial</span>
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {showPopup.display && (
          <Popup
            toggleDashboard={toggleMap.bind(this)}
            searchQuery={query}
          />
        )}

        <Alert />
      </div>

      <Walktour
        isOpen={showTutorial}
        customOpenFunc={(e) => {
            dispatch(updatePopups({ display: false }));
        }}
        customCloseFunc={(e) => {
          dispatch(endTutorial({ active: false }));
          setShowLegend(false);
          setShowCart(false);
          dispatch(updatePopups({ display: false }));

          //remove tutorial cart item if needed
          if (tutorialCartItem) {
            let lastCartItem = cart.slice(-1);
            dispatch(removeFromCart(lastCartItem[0].id));
            setTutorialCartItem(false);
          }
          
          // Close any open activity cards on the map
          let popupExists = document.querySelector(".leaflet-popup .leaflet-popup-close-button");
          if (popupExists) {
            popupExists.click();
          }

          setShowMap(true);

          e.goToStep(1);
          e.close();
        }}
        initialStepIndex={tutorialStep}
        customPrevFunc={(e) => {
          if (!tourRef) {
            setTourRef(e);
          }
          dispatch(updateStep(e.stepIndex - 1));
        }}
        customNextFunc={(e) => {
          if (!tourRef) {
            setTourRef(e);
          }
          dispatch(updateStep(e.stepIndex + 1));
        }}
        nextLabel={"Next"}
        prevLabel={"Back"}
        closeLabel={"Close"}
        transition={"all ease-in-out 0.3s"}
        movingTarget={false}
        disableMaskInteraction={true}
        allowForeignTarget={false}
        disableAutoScroll={false}
        steps={[
          {
            selector: ".stateGeo",
            description: "Move the map to locate points of interest. Zoom in and out to refine your results.",
          }, //0
          {
            selector: ".filterOptions",
            description: "Customize the map view by using the filters.",
          }, //1
          {
            selector: ".activities-grid",
            description: "Choose the activities you're interested in. They will turn orange when selected.",
          }, //2
          {
            selector: ".userguide-grid",
            description: "Discover scenic byway routes.",
          }, //3
          {
            selector: ".listview-btn",
            description:
              "Toggle the List View to see a list of points of interest.",
            updateInterval: 100,
          }, //4
          {
            selector: ".side-panel",
            description:
              "The List View is a filtered list of points of interest currently displayed on the map.",
            maskPadding: 0,
            updateInterval: 100,
          }, //5
          {
            selector: ".leaflet-marker-icon .active",
            description: "Select a point of interest to learn more.",
            movingTarget: true,
            updateInterval: 100,
          }, //6
          {
            selector: ".leaflet-popup-content-wrapper .add-to-cart",
            description:
              "Toggle the + and - icon on the right of the image to add or remove the point of interest from your itinerary.",
              movingTarget: true,
              updateInterval: 100,
          }, //7
          {
            selector: ".leaflet-popup-content-wrapper h5",
            description:
              "Select the name to learn more about the point of interest.",
          }, //8
          {
            selector: ".leaflet-popup-close-button",
            description:
              "Select the X in the top right corner to close the pop-up.",
          }, //9
          {
            selector: ".cart-wrapper",
            description:
              "Once you're done adding items to your itinerary, select My Itinerary.",
          }, //10
          {
            selector: ".btn-review",
            description:
              "If you're happy with the items in your itinerary, select Review and Save, or select My Itinerary to return to the map.",
          }, //11
          {
            selector: ".cart-header",
            description:
              "Add your starting and ending points to finalize the itinerary route.",
          }, //12
          {
            selector: ".edit-cart",
            description:
              "Select Manually Edit Route Order to change the order or remove points of interest. Drag and drop the points of interest to rearrange them, or select the - to remove them from your itinerary. Select Save Edits & Update Route to apply your changes.",
          }, //13
          {
            selector: ".itinerary-map",
            description:
              "Review the itinerary route, export it, save it for later or check out suggested activities to round out your itinerary.",
              movingTarget: true,
          }, //14
          {
            selector: ".recommended.tt h3",
            description:
              "Read these relevant travel tips—Visit Idaho's blog posts—as you plan your adventure. Please note that travel tips cannot be added to your itinerary.",
            movingTarget: true,
            updateInterval: 100,
          }, //15
          //{ selector: ".my-itinerary", description: "Add travel tips to your itinerary to reference as you plan your adventure. Only activities will show up in your route." }, //18
          {
            selector: ".return-to-map-btn",
            description:
              "Select Return to Map to go back to the map page and continue adding to your itinerary.",
          }, //16
          {
            selector: ".accountMenu",
            description:
              "Select My Account to view your saved itineraries or create a new itinerary.",
          }, //18
          {
            selector: ".nonexistingelement",
            description:
              "Ready to begin building your itinerary? Click close to return to the map.",
          }, //17

        ]}
      />
    </>
  );
}
export default Main;