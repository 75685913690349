import { IconClose } from "../../images/icons";
import {
	getAuth,
	GoogleAuthProvider,
	signInWithPopup,
	onAuthStateChanged,
	signOut,
} from "firebase/auth";
import app from "../../auth/firebase";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setActiveUser, setUserLogOutState } from "../../store/slices/userReducer";
import { updatePopups } from '../../store/actions/popupsActions';

function LoginPopupSave(props) {

	const dispatch = useDispatch();
	const userName = useSelector((state) => state.user.userName);

	const auth = getAuth(app);
	const googleProvider = new GoogleAuthProvider();

	useEffect(() => {
		onAuthStateChanged(auth, (userAuth) => {
			if (userAuth) {
				dispatch(setActiveUser({
					userName: userAuth.displayName,
					userEmail: userAuth.email,
					userID: userAuth.uid
				}));
			}
		})
	}, []);

	const handleGoogleSignIn = () => {
		signInWithPopup(auth, googleProvider)
			.then((result) => {
				dispatch(setActiveUser({
					userName: result.user.displayName,
					userEmail: result.user.email,
					userID: result.user.uid
				}));
				dispatch(updatePopups({ display: true, type: 'save-itinerary' }));
			})
			.catch((error) => console.log(error.message));
	};

	const handleSignOut = () => {
		signOut(auth)
			.then(() => {
				dispatch(setUserLogOutState());
			})
			.catch((error) => alert(error.message));
	};

	return (
		<div className="popup">
			<div className="popup-inner login-popup">
				<div className="popup-header">
					{!userName && <h2>Please sign in to save your itinerary</h2>}
					<a onClick={props.closePopup} className="popup-close">
						<IconClose />
					</a>
				</div>
				<div className="popup-content">
					<div>
						{userName ? (
							<a className="btn btn-green" onClick={handleSignOut}>Sign Out</a>
						) : (
							<a className="btn btn-green" onClick={handleGoogleSignIn}>Google Sign In/Register</a>
						)}
					</div>
				</div>
			</div>
		</div>
	);

}

export default LoginPopupSave