import React from "react";

export default class IconLegend extends React.Component {
	render() {
		return (
			<>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.812 46.874">
					<path d="M23.906,3A16.394,16.394,0,0,0,7.5,19.406c0,12.3,16.406,30.468,16.406,30.468S40.312,31.71,40.312,19.406A16.394,16.394,0,0,0,23.906,3Zm0,22.265a5.859,5.859,0,1,1,5.859-5.859A5.861,5.861,0,0,1,23.906,25.265Z" transform="translate(-7.5 -3)"/>
		  		</svg>
			</>
		);
	}
}
