import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addToTTCart, removeFromTTCart } from "../../store/actions/travelTipCartActions";
import { setLastItem } from "../../store/actions/lastItemActions";
import { IconPlus, IconMinus } from "../../images/icons";

const TravelTipCard = ({ item }) => {
  const [inCart, setInCart] = useState(false);
  const ttcart = useSelector((state) => state.ttcart.cartItems);
  console.log(ttcart);
  const dispatch = useDispatch();
  const url = `https://visitidaho.org/?p=${item.id}`;

  const toggleItemInCart = (item) => {
    if (!inCart) {
      console.log(item.id);
      const cartCount = ttcart ? ttcart.length : 0;
      if (cartCount <= 9) {
        dispatch(addToTTCart(item));
        dispatch(
          setLastItem({
            dynamicText: item.title,
            message: "addToTTCart",
            icon: true,
            type: "success",
          })
        );
        setInCart(true);
      }
    } else {
      dispatch(removeFromTTCart(item.id));
      dispatch(
        setLastItem({
          dynamicText: item.title,
          message: "removeFromTTCart",
          icon: false,
          type: "warning",
        })
      );
      setInCart(false);
    }
  };

  useEffect(() => {
    setInCart(ttcart.some((storeItem) => item.id === storeItem.id));
  }, [ttcart, item.id]);

  return (
    <article className="tt-card-wrapper">
      <div className="card-image">
        <figure className="card-figure">
          <img src={item?.image} alt={item?.title} />
        </figure>
          <a
            onClick={() => toggleItemInCart(item)}
            className={`add-to-cart ${inCart ? "active" : ""}`}
            title="Add to Itinerary"
          >
            {inCart ? <IconMinus /> : <IconPlus />}
          </a>
      </div>
        <div className="card-content">
          <h5>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {item?.title}
            </a>
          </h5>
        </div>
    </article>
  );
};

TravelTipCard.defaultProps = {
  showExcerpt: true,
};

export default TravelTipCard;