import { useEffect, useState } from "react";
import { useSelector, useDispatch} from "react-redux";
import { IconClose } from "../../images/icons";
import { updateActivities } from "../../store/actions/activitiesActions";
import { updateByways } from "../../store/actions/bywaysActions";
import { updatePopups } from "../../store/actions/popupsActions";
import { activities } from "../../data/filters-data";
import { getIconHtmlByCategoryLabel } from "../Markers/markers";
import ScenicByways from "./SubComponents/ScenicByways";
import UserGuide from "./SubComponents/UserGuide";

const ActivityPopup = (props) => {
	
	const [showClearAll, setShowClearAll] = useState(false);
	const { title = "Select the Activities you're interested in", tab = "activities"} = props;
  	const [tabTitle, setTabTitle] = useState(title);
	const dispatch = useDispatch();
  	const storeActivities = useSelector(
    	(state) => state.activities.selectedActivities
  	);
	const selectedActivities = useSelector((state) => state.activities.selectedActivities);
	const selectedByways = useSelector((state) => state.byways.selectedByways);

  useEffect(() => {
    if (tab === "activities") {
		setTabTitle("Choose one or more activities you're interested in to start building your itinerary.");
    } else if (tab === "byways") {
		setTabTitle("Plan your trip along any of the 31 scenic byways that wind through Idaho's seven regions to explore history, outdoor adventure and culture.");
    } else if (tab === "userguide") {
		setTabTitle("Build your Idaho itinerary with the Visit Idaho Travel Assistant. While this interactive map doesn't include all of the places to see, eat, drink and explore in Idaho, it showcases favorite activities and scenic byways throughout the state to help you customize your adventure.");
    }
  }, [tab]);

  const handleTabClick = (tab) => {
    dispatch(updatePopups({	display: true, type: "activities", tab: tab }));
  };

  const setActivityActive = (selectedActivity) => {
	//console.log(selectedActivities);
	const selectedValues = selectedActivity.split(',');
	const updatedActivities = selectedValues.reduce((acc, activity) => {
	  if (storeActivities.includes(activity)) {
		acc = acc.filter((item) => item !== activity);
	  } else {
		acc.push(activity);
	  }
	  return acc;
	}, [...storeActivities]);
  
	dispatch(updateActivities(updatedActivities));
  }

  const clearAllFilters = () => {
    dispatch(updateActivities([]));
    dispatch(updateByways([]));
    setShowClearAll(false);
  };

  useEffect(() => {
    if (selectedActivities.length || selectedByways.length) {
      setShowClearAll(true);
    }
  }, [selectedActivities, selectedByways]);
  
  return (
    <>
      <div className="popup">
        <div className="popup-inner">
			<div className="popup-header">
				<div className="popup-tabs">
					<button
					className={tab === "activities" ? "active" : ""}
					style={{fontFamily: "'europa', sans-serif"}}
					onClick={() => handleTabClick("activities")}
					>Activities</button>

					<button
					className={tab === "byways" ? "active" : ""}
					style={{fontFamily: "'europa', sans-serif"}}
					onClick={() => handleTabClick("byways")}
					>Scenic Byways</button>

					<button
					className={tab === "userguide" ? "active" : ""}
					style={{fontFamily: "'europa', sans-serif"}}
					onClick={() => handleTabClick("userguide")}
					>User Guide</button>
				</div>

				<a onClick={props.closePopup} className="popup-close">
					<IconClose />
				</a>
			</div>

			<div className="popup-content">
				{tab === "activities" && (
				<>
					<p style={{padding:"0"}}>{tabTitle}</p>
					<div className="activities-grid">
					{activities && activities.sort((a, b) => (a.label > b.label) ? 1 : -1).map((activity, i) => {
						return (
							<a
							key={i}
							onClick={() => setActivityActive(activity.value)}
							className={`activity-icon ${
								activity.value.split(",").every((v) => storeActivities.includes(v))
								? "active"
								: ""
							}`}
							>
							{getIconHtmlByCategoryLabel(activity.label)}
							<div>
								<h2>{activity.label}</h2>
								<p>{activity.value.split(',').sort((a, b) => (a.label > b.label) ? 1 : -1).join(', ')}</p>
							</div>
							</a>
						);
					})}
					</div>
				</>
	
				)}
				{tab === "byways" && (
				<>
					<p style={{padding:"0"}}>{tabTitle}</p>
					<div className="userguide-grid">
						<ScenicByways />
					</div>
				</>
				)}
				{tab === "userguide" && (
				<>
					<p style={{padding:"0"}}>{tabTitle}</p>
					<div className="userguide-grid">
						<UserGuide />
					</div>
				</>
				)}
			</div>

			{tab !== "userguide" && 
			<div className="popup-footer">
				<a onClick={props.closePopup} className="btn btn-white">
					View on Map
				</a>
				{showClearAll && (
					<a onClick={() => { clearAllFilters(); }} className="clearAll" title="Clear all filters" aria-label="Clear all filters">Clear All</a>
				)}
			</div>
			}

		</div>
      </div>
    </>
  );
};

export default ActivityPopup;
