const faq = [
    {
        question: "What's the weather like in Idaho?",
        answer: ['Idaho is a big state (83,643.12 square miles, to be exact), so the weather varies. <a href="https://visitidaho.org/regions/north/" target="_blank">Northern</a>, <a href="https://visitidaho.org/regions/east/" target="_blank">eastern</a>, <a href="https://visitidaho.org/regions/north-central/" target="_blank">north central</a> and <a href="https://visitidaho.org/regions/central/" target="_blank">central Idaho</a> receive heavy snowfall each winter, whereas parts of the <a href="https://visitidaho.org/regions/southwest/" target="_blank">southwest</a>, <a href="https://visitidaho.org/regions/southeast/" target="_blank">southeast</a> and <a href="https://visitidaho.org/regions/south-central/" target="_blank">south central</a> regions tend to experience milder conditions. The state averages 18 inches of rain, 47 inches of snow and 197 sunny days each year. The average temperature reaches 85° F in July and dips to 17° F in January.']
    },
    {
        question: "What's the weather like in Boise?",
        answer: ['In Boise, the capital city of Idaho, the high desert environment means warm, dry days and cool evenings in the summer months. The average temperature reaches 93° F in July and dips to 22° F in January. The city averages 20 inches of snow annually.']
    },
    {
        question: "What's the best way to travel to Idaho?",
        answer: [
            'Idaho has six commercial airports: Boise Airport in Boise, Friedman Memorial Airport in Hailey, Idaho Falls Regional Airport in Idaho Falls, Lewiston–Nez Perce County Airport in Lewiston, Pocatello Regional Airport in Pocatello and Magic Valley Regional Airport in Twin Falls. Dozens of U.S. cities offer <a href="https://visitidaho.org/nonstop-flights/" target="_blank">nonstop flights</a> to Boise.',
            'With 31 scenic byways throughout the state, plenty of beautiful routes await visitors who prefer to <a href="https://visitidaho.org/things-to-do/scenic-byways/" target="_blank">drive to Idaho</a> via Washington, Oregon, Nevada, Utah, Wyoming, Montana or Canada.']
    }
];

export default faq;