import * as React from "react";
import store from "../../store/store";
import { clearCart } from '../../store/actions/cartActions';
import { updatePopups } from "../../store/actions/popupsActions";
import { IconClose } from "../../images/icons";

function CreateItineraryPopup(props) {
	const toggleSaveItinerary = () => {
		store.dispatch(updatePopups({ display: true, type: 'save-itinerary' }));
	}
	const resetCart = () => {
		store.dispatch(clearCart());
		localStorage.removeItem("itinerary");
		props.toggleDashboard();
		props.closePopup();
	}
	return (
		<>
			<div className="popup">
				<div className="popup-inner login-popup">
					<div className="popup-header no-bg">
						<a onClick={props.closePopup} className="popup-close">
							<IconClose />
						</a>
					</div>
					<div className="popup-content">
						<h2>You have unsaved items in your cart.</h2>
						<p>What would you like to do?</p>
						<br />
						<a onClick={toggleSaveItinerary} className="btn btn-block btn-white" aria-label="Save Itinerary" title="Save Itinerary">Save/Update Itinerary</a>
						<br />
						<a onClick={resetCart} className="btn btn-block btn-green" aria-label="Start New Itinerary" title="Start New Itinerary">Start New Itinerary</a>
					</div>
				</div>
			</div>
		</>
	);
}

export default CreateItineraryPopup;
