import React from "react";

export default class IconArrowLeft extends React.Component {
	render() {
		return (
			<>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="10.474"
					height="18.318"
					viewBox="0 0 10.474 18.318"
				>
					<path
						d="M14.4,15.352l6.932-6.926a1.3,1.3,0,0,0,0-1.849,1.32,1.32,0,0,0-1.854,0l-7.853,7.848a1.307,1.307,0,0,0-.038,1.805l7.886,7.9a1.309,1.309,0,1,0,1.854-1.849Z"
						transform="translate(-11.246 -6.196)"
					/>
				</svg>
			</>
		);
	}
}
